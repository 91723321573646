import {
  isNumber,
  isValidInterface,
} from '@leon-hub/guards';
import type { FieldGuards } from '@leon-hub/types';

import type {
  GetCountersResponse,
} from 'web/src/modules/sportline/types/rest';

export const isGetCountersResponseFieldGuards: FieldGuards<GetCountersResponse> = {
  hideClosed: isNumber,
  zeroMarginMarkets: isNumber,
};

export function isGetCountersResponse(value: unknown): value is GetCountersResponse {
  return isValidInterface<GetCountersResponse>(value, isGetCountersResponseFieldGuards);
}
