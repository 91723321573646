import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import { useI18n } from 'web/src/modules/i18n/composables';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import type {
  BonusCodesOverviewEmits,
  BonusCodesOverviewProps,
} from 'web/src/modules/affiliates/components/BonusCodesOverview/types';

interface BonusCodesOverviewComposable {
  counterText: ComputedRef<string>;
  buttonDisabled: ComputedRef<boolean>;
  buttonKind: ComputedRef<ButtonKind>;
  buttonLabel: ComputedRef<string>;
  emitButtonClick(): void;
  emitHelp(): void;
}

export default function useBonusCodesOverview(
  props: BonusCodesOverviewProps,
  emits: BonusCodesOverviewEmits,
): BonusCodesOverviewComposable {
  const { $translate } = useI18n();

  const counterText = computed(() => `${props.currentCount}/${props.maxCount}`);
  const codesLimitReached = computed(() => {
    if (props.currentCount && props.maxCount) {
      return props.currentCount >= props.maxCount;
    }
    return false;
  });

  const buttonDisabled = computed(() => {
    if (props.buttonIsLink) {
      return false;
    }
    return codesLimitReached.value;
  });

  const buttonKind = computed<ButtonKind>(() => {
    if (!props.buttonIsLink) {
      return ButtonKind.PRIMARY;
    }
    return codesLimitReached.value ? ButtonKind.SECONDARY : ButtonKind.PRIMARY;
  });

  const buttonLabel = computed(() => {
    const add = props.addButtonText || $translate('WEB2_ADD_BONUS_CODE').value;
    const list = $translate('WEB2_BONUS_CODES_LIST').value;
    if (!props.buttonIsLink) {
      return add;
    }
    return codesLimitReached.value ? list : add;
  });

  function emitButtonClick(): void {
    emits('button-click');
  }

  function emitHelp(): void {
    emits('help');
  }

  return {
    counterText,
    buttonDisabled,
    buttonKind,
    buttonLabel,
    emitButtonClick,
    emitHelp,
  };
}
