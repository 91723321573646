import type { DisplayedTimeLeft, ValueOfTimeLeft } from 'web/src/utils/countdown';
import type { I18nFunctionReactive } from 'web/src/modules/i18n/types';

export default function getSeconds(
  timeLeft: ValueOfTimeLeft | null,
  displayedTimeLeft: DisplayedTimeLeft | null,
  $t: I18nFunctionReactive,
): string {
  if (!timeLeft || !displayedTimeLeft) {
    return '';
  }

  const {
    days,
    hours,
    minutes,
    seconds,
  } = timeLeft;

  if (days <= 0
    && hours <= 0
    && minutes >= 0
    && seconds > 0) {
    return `${String(seconds)} ${$t('WEB2_SECONDS_SHORT').value}.`;
  }

  return '';
}
