import {
  isLeagueIdEquals,
  isLeagueNavigationParameterEquals,
} from 'web/src/modules/sportline/utils';
import type { SportsTreeLeagueElement } from 'web/src/modules/sportline/types/rest';

type FindGuard = (element?: SportsTreeLeagueElement) => boolean;

export function leagueElementNavigationEqualsTo(
  parameter?: string | null,
  extraCondition?: FindGuard,
): FindGuard {
  if (!parameter) {
    return (element) => (extraCondition ? extraCondition(element) : false);
  }

  return (element) => isLeagueNavigationParameterEquals(element?.league, parameter)
    || (extraCondition ? extraCondition(element) : false);
}

export function leagueElementEqualsTo(
  leagueId?: string | null,
  extraCondition?: FindGuard,
): FindGuard {
  if (!leagueId) {
    return (element) => (extraCondition ? extraCondition(element) : false);
  }

  return (element) => isLeagueIdEquals(element?.league.id, leagueId)
    || (extraCondition ? extraCondition(element) : false);
}
