import {
  isRegionIdEquals,
  isRegionNavigationParameterEquals,
} from 'web/src/modules/sportline/utils';
import type { SportsTreeRegionElement } from 'web/src/modules/sportline/types/rest';

type FindGuard = (element?: SportsTreeRegionElement) => boolean;

export function regionElementNavigationEqualsTo(
  parameter?: string | null,
  extraCondition?: FindGuard,
): FindGuard {
  if (!parameter) {
    return (element) => (extraCondition ? extraCondition(element) : false);
  }

  return (element) => isRegionNavigationParameterEquals(element?.region, parameter)
    || (extraCondition ? extraCondition(element) : false);
}

export function regionElementEqualsTo(
  regionId?: string | null,
  extraCondition?: FindGuard,
): FindGuard {
  if (!regionId) {
    return (element) => (extraCondition ? extraCondition(element) : false);
  }

  return (element) => isRegionIdEquals(element?.region, regionId)
    || (extraCondition ? extraCondition(element) : false);
}
