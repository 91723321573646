export enum ReCaptchaType {
  CHALLENGE_CHECKBOX = 'CHALLENGE_CHECKBOX',
  CHALLENGE_INVISIBLE = 'CHALLENGE_INVISIBLE',
  SCORE = 'SCORE',
}

export enum CaptchaRequesterStrategy {
  RESTORE_PASSWORD = 'RESTORE_PASSWORD',
  REGISTRATION = 'REGISTRATION',
  LOGIN = 'LOGIN',
  DEFAULT = 'DEFAULT',
}

export enum ReCaptchaTheme {
  DARK = 'dark',
  LIGHT = 'light',
}

// NB! Do not change case of enum values
export enum ReCaptchaSize {
  COMPACT = 'compact',
  NORMAL = 'normal',
  INVISIBLE = 'invisible',
}

export enum ChallengeState {
  CHALLENGE_IS_CLOSED = 'challenge-is-closed',
  CHALLENGE_IS_OPENED = 'challenge-is-opened',
}
