import LiveChatApiClient from './LiveChatApiClient';

let chatApiClient: LiveChatApiClient | undefined;

export default function useLiveChatApiClient(): LiveChatApiClient {
  if (!chatApiClient) {
    chatApiClient = new LiveChatApiClient();
  }

  return chatApiClient;
}
