import type { Theme } from '@leon-hub/api-sdk';

import type { AnalyticsComposable } from 'web/src/modules/analytics/composables/useAnalytics';

import type { TrackingPayload, GetTrackingSlipEntriesPayload } from '../types';
import getTrackingSlipEntries from './getTrackingSlipEntries';
import logPlaceBetResultSuccess from './logPlaceBetResultSuccess';
import betPlaceSuccessYMTrigger from './betPlaceSuccessYMTrigger';
import trackSportBet from './trackSportBet';

export default function makeBetResultLog(
  getTrackingSlipEntriesPayload: GetTrackingSlipEntriesPayload,
  additionalTrackData: Pick<TrackingPayload,
    'liveEventsIds'
    | 'slipType'
    | 'priceChangePolicy'
    | 'currency'
    | 'login'>,
  {
    theme, analyticsService, useDefaultBet, amountBet, currentValue,
  }: {
    theme: Theme;
    analyticsService: AnalyticsComposable;
    useDefaultBet: boolean;
    amountBet: number | false;
    currentValue: number;
  },
): void {
  const trackingPayload: TrackingPayload = {
    slipEntries: getTrackingSlipEntries(getTrackingSlipEntriesPayload),
    ...additionalTrackData,
  };
  logPlaceBetResultSuccess(trackingPayload);
  betPlaceSuccessYMTrigger(trackingPayload, {
    theme, analyticsService, useDefaultBet, amountBet, currentValue,
  });

  if (process.env.VUE_APP_PLATFORM_CORDOVA) {
    trackSportBet(trackingPayload);
  }
}
