<script lang="ts" setup>
import { toRef } from 'vue';

import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import { useLiveMatchProgress } from 'web/src/modules/sportline/composables/match-progress';
import { useLiveStatus } from 'web/src/modules/sportline/composables/live-progress';
import type { SportlineEvent } from 'web/src/modules/sportline/types';
import { vTimer } from 'web/src/modules/sportline/directives';

interface HeadlineLiveTimeInfoProps {
  sportlineEvent: SportlineEvent;
}

const props = defineProps<HeadlineLiveTimeInfoProps>();
const sportlineEvent = toRef(props, 'sportlineEvent');

const settingsStore = useSportlineSettingsStore();
const isTimeReversedIndicatorAvailable = toRef(settingsStore, 'isTimeReversedIndicatorAvailable');

const {
  matchProgress,
} = useLiveMatchProgress({ sportEvent: sportlineEvent });
const {
  gameScoreProgress,
  additionalTime,
  isStaticLiveProgress,
  isTimeReversed,
  staticDirectTimeProgressLabel,
  staticReversedTimeProgressLabel,
  mainTimeDirectiveDirection,
  additionalTimeDirectiveDirection,
  mainTimeTotalSeconds,
  mainTimeProgress,
  mainTimeProgressShortFormat,
  additionalTimeTotalSeconds,
  additionalTimeProgress,
} = useLiveStatus(matchProgress);
</script>

<template>
  <span v-auto-id="'HeadlineLiveTimeInfo'">
    <span
      v-if="gameScoreProgress"
      key="progress"
    >
      {{ gameScoreProgress }}
    </span>
    <template v-else-if="isStaticLiveProgress">
      <template v-if="isTimeReversedIndicatorAvailable && isTimeReversed">
        <span
          v-if="staticReversedTimeProgressLabel"
          key="progress"
        >
          {{ staticReversedTimeProgressLabel }}
        </span>
      </template>
      <span
        v-else-if="staticDirectTimeProgressLabel"
        key="progress-direct"
      >
        {{ staticDirectTimeProgressLabel }}
      </span>
    </template>
    <span
      v-else
      key="progress-inactive"
    >
      <template v-if="!additionalTime">
        <span
          v-timer:[mainTimeDirectiveDirection]="mainTimeTotalSeconds"
        >{{ mainTimeProgress }}</span>
      </template>
      <template v-else>
        {{ mainTimeProgressShortFormat }}
        +<span
          v-timer:[additionalTimeDirectiveDirection]="additionalTimeTotalSeconds"
        >{{ additionalTimeProgress }}</span>
      </template>
    </span>
  </span>
</template>
