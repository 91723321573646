import { computed } from 'vue';
import type { ComputedRef } from 'vue';

import { assert, isOptionalString } from '@leon-hub/guards';

import type { FormPaymentsInputType } from 'web/src/components/FormPaymentsInput/components/FormPaymentsInput/enums';
import type {
  FormWrapperWidgetProperties,
} from 'web/src/components/Form/types';
import type { FormPaymentsInputProps } from 'web/src/components/FormPaymentsInput/components/FormPaymentsInput/types';

interface UseFormPaymentsDisabledInput {
  componentProps: ComputedRef<FormPaymentsInputProps>;
}

export default function useFormPaymentsDisabledInput(
  props: FormWrapperWidgetProperties,
  inputType: FormPaymentsInputType,
): UseFormPaymentsDisabledInput {
  const componentProps = computed<FormPaymentsInputProps>(
    () => {
      const { value } = props;
      assert(isOptionalString(value), `Unexpected value type: ${typeof value}`);

      return {
        value: value || '',
        inputType,
      };
    },
  );
  return {
    componentProps,
  };
}
