/**
 * https://confluence.leoncorp.net/pages/viewpage.action?spaceKey=SportCatalog&title=PROJ-471+Requirements
 * */

interface Payload {
  winValue: number;
  stake: number;
  boostPercent: number; // eg 0.05
}

export function getWinBoostValue({ winValue, stake, boostPercent }: Payload): number {
  return (winValue - stake) * boostPercent;
}
