import type { VListItemProps } from '../../types';
import convertCommonClassesToCssModule from '../../../utils/convertCommonClassesToCssModule';

export default function getClassObject(props: VListItemProps): Dictionary<boolean> {
  return convertCommonClassesToCssModule({
    'list-item': true,
    'list-item--no-hover': !!props.noHover,
    'list-item--have-switcher': !!props.haveSwitcher,
    'list-item--active': !!props.isActive,
    'list-item--big-margin': !!props.isBigMargin,
  });
}
