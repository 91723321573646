import type { FieldGuards } from '@leon-hub/types';
import {
  isNumber,
  isString,
  isBoolean,
  isUndefined,
  bindIsArrayOf,
  isValidInterface,
} from '@leon-hub/guards';

import type { CoreSportEventResponse } from 'web/src/modules/sportline/types/rest';

import { isSportEventMatchPhase } from '../core';
import { isBetlineType } from './isBetlineType';
import { isCoreLeagueResponse } from './isCoreLeagueResponse';
import { isLiveStatusResponse } from './isLiveStatusResponse';
import { isCoreMarketResponse } from './isCoreMarketResponse';
import { isBetlineSportEventStatus } from './isBetlineSportEventStatus';
import { isBetlineSportWidgetType } from './isBetlineSportWidgetType';
import { isCoreLeagueReferenceResponse } from './isCoreLeagueReferenceResponse';
import { isCoreCompetitorResponse } from './isCoreCompetitorResponse';

const isCoreSportEventResponseFieldGuards: FieldGuards<CoreSportEventResponse> = {
  id: isNumber,
  name: isString,
  nameDefault: isString,
  competitors: bindIsArrayOf(isCoreCompetitorResponse),
  kickoff: isNumber,
  lastUpdated: isNumber,
  league: [isCoreLeagueReferenceResponse, isCoreLeagueResponse],
  betline: isBetlineType,
  url: isString,
  open: [isUndefined, isBoolean],
  status: [isUndefined, isBetlineSportEventStatus],
  matchPhase: [isUndefined, isSportEventMatchPhase],
  marketsCount: [isUndefined, isNumber],
  runnersCount: [isUndefined, isNumber],
  liveStatus: [isUndefined, isLiveStatusResponse],
  markets: [isUndefined, bindIsArrayOf(isCoreMarketResponse)],
  widgetId: [isUndefined, isString],
  widgetType: [isUndefined, isBetlineSportWidgetType],
  widgetVirtual: [isUndefined, isBoolean],
  hasMarketWithZeroMargin: [isUndefined, isBoolean],
};

export function isCoreSportEventResponse(value: unknown, silent = false): value is CoreSportEventResponse {
  return isValidInterface<CoreSportEventResponse>(value, isCoreSportEventResponseFieldGuards, { silent });
}
