import type { Ref } from 'vue';
import { toRef, computed } from 'vue';
import type { RouteLocationRaw } from 'vue-router';

import { useI18n } from 'web/src/modules/i18n/composables';
import { useSportlineBreadcrumbsStore } from 'web/src/modules/sportline/submodules/breadcrumbs/store';
import type { Region } from 'web/src/modules/sportline/types';
import type { SportsTreeRegionElement } from 'web/src/modules/sportline/types/rest';
import { resolveRegionPageLink } from 'web/src/modules/sportline/submodules/navigation/store/utils';

interface UseSportlineBreadcrumbsRegionItemProps {
  region?: Ref<Maybe<Region> | undefined>;
}

interface UseSportlineBreadcrumbsRegionItemComposable {
  isShown: Ref<boolean>;
  title: Ref<string>;
  regionElement: Ref<Maybe<SportsTreeRegionElement>>;
  relatedRegions: Ref<SportsTreeRegionElement[]>;
  pageLocation: Ref<Optional<RouteLocationRaw>>;
}

export function useSportlineBreadcrumbsRegionItem(
  props: UseSportlineBreadcrumbsRegionItemProps,
): UseSportlineBreadcrumbsRegionItemComposable {
  const defaultRegion = toRef(props.region);

  const { $translate } = useI18n();
  const sportlineBreadcrumbsStore = useSportlineBreadcrumbsStore();
  const regionElement = toRef(sportlineBreadcrumbsStore, 'regionElement');
  const relatedRegions = toRef(sportlineBreadcrumbsStore, 'relatedRegions');

  const region = computed(() => (defaultRegion.value ?? regionElement.value?.region ?? null));

  const isShown = computed(() => (
    !!region.value || relatedRegions.value.length > 0
  ));
  const title = computed(() => (
    region.value?.name || $translate('JSP_SPORT_NAV_DEFAULT_REGION').value
  ));
  const pageLocation = computed<Optional<RouteLocationRaw>>(() => (
    region.value ? resolveRegionPageLink(region.value.navigationParameters) : undefined
  ));

  return {
    isShown,
    title,
    regionElement,
    relatedRegions,
    pageLocation,
  };
}
