<script lang="ts" setup>
import ReferralProgramPopupHeader
  from 'web/src/modules/referral-program/components/ReferralProgramPopupHeader/ReferralProgramPopupHeader.vue';
import useReferralProgramPopup
  from 'web/src/modules/referral-program/views/ReferralProgramPopup/composables/useReferralProgramPopup';
import ReferralProgramPopupReward
  from 'web/src/modules/referral-program/components/ReferralProgramPopupReward/ReferralProgramPopupReward.vue';
import ReferralProgramPopupListSteps
  from 'web/src/modules/referral-program/components/ReferralProgramPopupListSteps/ReferralProgramPopupListSteps.vue';

const {
  isReferral,
  toggleOpen,
  headerProps,
  bodyProps,
  blockRef,
  blockStyle,
  isOpen,
  isReferrerRequirementSatisfied,
  handleClose,
  isVisible,
  handleRedirect,
} = useReferralProgramPopup();
</script>

<template>
  <div v-auto-id="'ReferralProgramPopup'"
    v-if="isReferral && isVisible"
    :class="{
      [$style['referral-program-popup']] : true,
      [$style['referral-program-popup--open']] : !isReferrerRequirementSatisfied && isOpen,
    }"
  >
    <ReferralProgramPopupHeader
      v-bind="headerProps"
      @click="toggleOpen"
      @close="handleClose"
      @redirect="handleRedirect"
    />
    <div
      v-if="!isReferrerRequirementSatisfied"
      :class="$style['referral-program-popup__bottom-container']"
      :style="blockStyle"
    >
      <div
        ref="blockRef"
        :class="$style['referral-program-popup__body']"
      >
        <div :class="$style['referral-program-popup__body-column']">
          <ReferralProgramPopupListSteps
            v-if="bodyProps.completed.length"
            :title="$t('WEB2_REFFERAL_PROGRAM_POPUP_DONE')"
            satisfied
            :list="bodyProps.completed"
          />
          <ReferralProgramPopupListSteps
            v-if="bodyProps.current.length"
            :title="$t('WEB2_REFFERAL_PROGRAM_POPUP_CURRENT')"
            :list="bodyProps.current"
            is-rounded
          />
        </div>
        <div :class="$style['referral-program-popup__body-column']">
          <ReferralProgramPopupReward
            v-bind="bodyProps.rewards"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>
@include for-layout using ($isDesktop) {
  .referral-program-popup {
    $self: &;

    display: flex;
    flex-direction: column;
    width: if($isDesktop, 100%, calc(100% - 16px));
    margin: if($isDesktop, 8px 0, 8px);
    background-color: #ffc13c;
    border-radius: 5px;

    &__bottom-container {
      overflow: hidden;
      transition: height 0.4s ease-out;
    }

    &--open {
      #{$self}__header-arrow {
        transition: 0.4s ease-out;
        transform: rotate(180deg);
      }

      #{$self}__bottom-container {
        transition: height 0.6s ease-out;
      }
    }

    &__body {
      display: flex;
      flex-direction: column;
      padding: if($isDesktop, 0 16px 16px, 0 8px 8px);
      border-top: 1px solid rgba(0, 0, 0, 0.1);

      @if $isDesktop {
        flex-direction: row;
        gap: 32px;

        &-column {
          width: 50%;
        }
      }

      &-column {
        width: 100%;
      }
    }
  }
}
</style>
