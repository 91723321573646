import arrayEquals from 'web/src/utils/array/arrayEquals';
import { BetlineSelectionTag } from 'web/src/modules/sportline/enums/rest';
import { MarketTypeSign } from 'web/src/modules/sportline/enums';

const signMapping: Record<string, BetlineSelectionTag[]> = {
  [MarketTypeSign.HomeDrawAway]: [BetlineSelectionTag.HOME, BetlineSelectionTag.DRAW, BetlineSelectionTag.AWAY],
  [MarketTypeSign.HomeAway]: [BetlineSelectionTag.HOME, BetlineSelectionTag.AWAY],
  [MarketTypeSign.YesNo]: [BetlineSelectionTag.YES, BetlineSelectionTag.NO],
  [MarketTypeSign.UnderOver]: [BetlineSelectionTag.UNDER, BetlineSelectionTag.OVER],
  [MarketTypeSign.OddEven]: [BetlineSelectionTag.ODD, BetlineSelectionTag.EVEN],
};

export default function extractSignBySelectionTags(tags: BetlineSelectionTag[]): MarketTypeSign {
  const signKey = Object.keys(signMapping)
    .find((key) => arrayEquals(tags, signMapping[key]));

  return signKey as MarketTypeSign || MarketTypeSign.Default;
}
