import type {
  CoreSportResponse,
  CoreSportReferenceResponse,
} from 'web/src/modules/sportline/types/rest';
import { isFullCoreSportResponse } from 'web/src/modules/sportline/guards/rest';

export function assertFullCoreSportResponse(
  response: CoreSportResponse | CoreSportReferenceResponse,
): CoreSportResponse {
  if (!isFullCoreSportResponse(response)) {
    throw new Error('Sport assert failed');
  }

  return response;
}
