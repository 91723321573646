import type { Ref, ComputedRef } from 'vue';
import { computed, ref } from 'vue';

import { useIntersectionObserver } from '@leon-hub/vue-utils';

import type {
  VLoadMoreObserverEmits,
  VLoadMoreObserverProps,
} from 'web/src/components/LoadMoreObserver/VLoadMoreObserver/types';
import { useI18n } from 'web/src/modules/i18n/composables';

export interface useVLoadMoreObserverComposable {
  collision: Ref<HTMLElement | undefined>;
  buttonText: ComputedRef<string>;
  showFallbackButton: boolean;
}

export default function useVLoadMoreObserver(
  props: VLoadMoreObserverProps,
  emit: VLoadMoreObserverEmits,
): useVLoadMoreObserverComposable {
  const { $translate } = useI18n();

  const collision = ref<HTMLElement>();

  const buttonText = computed(() => props.fallbackButtonText || $translate('WEB2_LOAD_MORE').value);

  const showFallbackButton = typeof IntersectionObserver === 'undefined';

  useIntersectionObserver(collision, (isIntersecting) => {
    if (isIntersecting) {
      emit('load-more');
    }
  }, { threshold: 1 });

  return {
    collision,
    buttonText,
    showFallbackButton,
  };
}
