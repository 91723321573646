<script lang="ts" setup>
import { VIcon } from '@components/v-icon';

import CustomerHistoryListItemLayout
  // eslint-disable-next-line max-len
  from 'web/src/modules/profile/submodules/customer-history/components/CustomerHistoryListItemLayout/CustomerHistoryListItemLayout.vue';
import { defaultCurrency } from 'web/src/modules/money/consts';
import { TestKeysEnum } from 'web/src/modules/profile/submodules/customer-history/types';

import type { CustomerHistoryListItemBetProps, CustomerHistoryListItemBetEmits } from './types';
import { useCustomerHistoryListItemBet } from './composables';

const props = withDefaults(defineProps<CustomerHistoryListItemBetProps>(), {
  currency: defaultCurrency,
});
const emit = defineEmits<CustomerHistoryListItemBetEmits>();

const {
  isLive,
  isCombi,
  isCalculated,
  iconProperties,
  predictionLabel,
  statusProperties,
  dateProperties,
  eventDescription,
  betPrice,
  debitProperties,
  creditValue,
  predictionChoice,
} = useCustomerHistoryListItemBet(props);
</script>

<template>
  <CustomerHistoryListItemLayout v-auto-id="'CustomerHistoryListItemBet'" @click="emit('item-click', item)">
    <template #metaLeft>
      <span
        :class="{
          [$style['bet-list-item__status']]: true,
          [$style['bet-list-item__status--positive']]: isLive,
        }"
        :data-test-key="TestKeysEnum.STATUS"
      >{{ statusProperties }}</span>
    </template>
    <template #metaRight>
      <span
        :class="$style['bet-list-item__date']"
        :data-test-key="TestKeysEnum.DATE"
      >{{ dateProperties }}</span>
    </template>
    <template #descriptionLeft>
      <span
        v-if="!!eventDescription[0]"
        :class="$style['bet-list-item__event-description']"
        :data-test-key="TestKeysEnum.EVENT_DESCRIPTION"
      >{{ eventDescription[0] }}</span>
      <span
        v-if="!!eventDescription[1]"
        :class="$style['bet-list-item__event-description']"
        :data-test-key="TestKeysEnum.EVENT_DESCRIPTION"
      >{{ eventDescription[1] }}</span>
      <span
        v-if="isCombi"
        :class="$style['bet-list-item__prediction-label']"
        :data-test-key="TestKeysEnum.MARKET_NAME"
      >{{ predictionLabel }}</span>
    </template>
    <template #descriptionRight>
      <span
        :class="$style['bet-list-item__coefficient']"
        :data-test-key="TestKeysEnum.ODDS"
      >{{ betPrice }}</span>
      <span
        :class="$style['bet-list-item__debit']"
        :data-test-key="TestKeysEnum.DEBIT"
      >{{ debitProperties }}</span>
    </template>
    <template
      v-if="!isCombi"
      #footerLeft
    >
      <div :class="$style['bet-list-item__prediction']">
        <span
          :class="$style['bet-list-item__prediction-label']"
          :data-test-key="TestKeysEnum.MARKET_NAME"
        >{{ predictionLabel }}</span>
        <span v-if="predictionChoice">&nbsp;-&nbsp;</span>
        <span
          v-if="predictionChoice"
          :class="$style['bet-list-item__prediction-choice']"
          :data-test-key="TestKeysEnum.RUNNER_NAME"
        >{{ predictionChoice }}</span>
      </div>
    </template>
    <template #footerRight>
      <div
        v-if="!!item.cashoutAmount"
        :class="$style['bet-list-item__cash-out']"
        :data-test-key="TestKeysEnum.CASH_OUT_AVAILABLE"
        @click.stop="emit('cash-out', item)"
      >
        <VIcon v-bind="iconProperties" />
      </div>
      <span
        v-else-if="isCalculated"
        :class="{
          [$style['bet-list-item__credit']]: true,
          [$style['bet-list-item__credit--positive']]: item.credit > 0,
        }"
        :data-test-key="TestKeysEnum.CREDIT"
      >{{ creditValue }}</span>
    </template>
  </CustomerHistoryListItemLayout>
</template>

<style lang="scss" module>
.bet-list-item {
  &__status {
    @include medium\12\16;

    display: block;
    width: 100%;
    overflow: hidden;
    color: var(--TextSecondary);
    text-overflow: ellipsis;
    white-space: nowrap;

    &--positive {
      color: var(--BrandDefault);
    }
  }

  &__date {
    @include regular\12\16;

    color: var(--TextSecondary);
  }

  &__event-description {
    @include medium\14\20\025;

    display: block;
    overflow: hidden;
    color: var(--TextDefault);
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__coefficient {
    @include medium\14\20\025;

    color: var(--TextDefault);
  }

  &__credit,
  &__debit {
    @include regular\14\20\025;

    color: var(--TextPrimary);

    &--positive {
      color: var(--BrandDefault);
    }
  }

  &__credit {
    @include medium\14\16\025;
  }

  &__prediction {
    @include regular\14\16\025;

    &-label {
      @include regular\14\16\025;

      color: var(--TextSecondary);
    }

    &-choice {
      @include regular\14\16\025;

      color: var(--BrandDefault);
      white-space: nowrap;
    }
  }

  &__cash-out {
    position: relative;
    color: var(--AlertDefault);

    // extend click area
    &:after {
      position: absolute;
      top: -14px;
      right: -14px;
      bottom: -14px;
      left: -14px;
      display: block;
      content: '';
    }
  }
}
</style>
