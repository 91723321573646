import { defineStore } from 'pinia';
import { ref } from 'vue';

const useHeaderStore = defineStore('header', () => {
  const height = ref(0);

  function setHeight(value: number): void {
    height.value = value;
  }

  return {
    height,
    setHeight,
  };
});

export default useHeaderStore;
