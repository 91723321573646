import type { GameStartMethod } from '../enums';

export default class EgsRouterParametersHandler {
  private static selectedGameMethod?: GameStartMethod;

  private static selectedGroup?: string;

  static setSelectedGameMethod(method: GameStartMethod | undefined): void {
    EgsRouterParametersHandler.selectedGameMethod = method;
  }

  static getSelectedGameMethod(): GameStartMethod | undefined {
    return EgsRouterParametersHandler.selectedGameMethod;
  }

  static setSelectedGroup(method: string | undefined): void {
    EgsRouterParametersHandler.selectedGroup = method;
  }

  static getSelectedGroup(): string | undefined {
    return EgsRouterParametersHandler.selectedGroup;
  }
}
