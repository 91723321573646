import { defineStore } from 'pinia';
import { ref } from 'vue';

import { getBanners } from '@leon-hub/api-sdk';

import { useSyncState } from 'web/src/modules/core/store/composables';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';

import type { BannerGroup } from './types';

const useBannersStore = defineStore('banners', () => {
  const api = useGraphqlClient();

  const bannerGroups = ref<BannerGroup[]>([]);

  const setBannerGroups = (value: BannerGroup[]): void => {
    bannerGroups.value = value;
  };

  async function loadBanners(): Promise<void> {
    const result = await getBanners(api,
      (node) => node.queries.banners.getBanners,
      undefined,
      { silent: true });

    setBannerGroups(result.bannerGroups);
  }

  useSyncState(loadBanners, 'banners');

  return {
    bannerGroups,
    loadBanners,
    setBannerGroups,
  };
});

export default useBannersStore;
