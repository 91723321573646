import $styles from '../styles/common.module.scss';

export function convertCommonClassToCssModule(className: string, value = true): string {
  const resultClass = $styles[className];
  if (process.env.NODE_ENV === 'development' && !resultClass && value) {
    // eslint-disable-next-line no-console
    console.warn('Unable to find module class for', className);
  }
  return resultClass || className;
}

export default function convertCommonClassesToCssModule(classes: Record<string, boolean>): Dictionary<boolean> {
  return Object.keys(classes).reduce((acc, key) => ({
    ...acc,
    [convertCommonClassToCssModule(key, classes[key])]: classes[key],
  }), {});
}
