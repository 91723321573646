<script lang="ts" setup>
import { computed, toRef } from 'vue';

import { IconDirection, IconName, IconSize } from '@leon-hub/icons';

import { VIcon } from '@components/v-icon';

import { useRunnersSettings, useMainMarketSettings } from 'web/src/modules/sportline/composables/settings';
import { useSportlineEventType } from 'web/src/modules/sportline/composables/sportline-event';
import {
  useMarketColumns,
  useSportlineEventLink,
} from 'web/src/modules/sportline/composables/list';
import { useInjectSelectedColumn } from 'web/src/modules/sportline/composables/select-main-market';
import { useMarketRunners, useSlipCaption } from 'web/src/modules/sportline/views/runner/composables';
import type { SportlineFragmentBasisKey } from 'web/src/modules/sportline/types/list';
import type {
  Sport,
  Market,
  SportlineEvent,
} from 'web/src/modules/sportline/types';
import InListRunner from 'web/src/modules/sportline/views/runner/InListRunner.vue';

import {
  useMoreCaption,
  useSportlineExpandableEventsListItem,
} from './composables';
import MainMarketContainer from './components/MainMarketContainer.vue';

interface SportlineEventMainMarketProps {
  sportlineEvent: SportlineEvent;
  sport: Sport;
  activeEventId?: Maybe<string>;
  isExtended?: boolean;
  isExtendedSportEventAllowed?: boolean;
  basisKey: SportlineFragmentBasisKey;
}

interface SportlineEventMainMarketEmits {
  (e: 'click'): void;
  (e: 'extend-element'): void;
  (e: 'collapse-element'): void;
}

const props = defineProps<SportlineEventMainMarketProps>();
const emit = defineEmits<SportlineEventMainMarketEmits>();

const sportEvent = toRef(props, 'sportlineEvent');
const sport = toRef(props, 'sport');
const basisKey = toRef(props, 'basisKey');

const sportEventId = computed<string>(() => sportEvent.value.id);
const activeEventId = toRef(props, 'activeEventId', null);
const markets = computed<Market[]>(() => sportEvent.value.markets);

const { isUsedMoreRunnersCount } = useRunnersSettings();
const { doUseFullAlternativeMarketsList } = useMainMarketSettings();
const {
  isOutright,
  sportlineType,
} = useSportlineEventType({ sportEvent });
const {
  href,
  onLinkClick,
} = useSportlineEventLink({
  sportEvent,
  activeEventId,
}, {
  onClick(): void { emit('click'); },
});
const {
  selectedColumnId,
  selectedColumnSize,
  columns,
} = useInjectSelectedColumn({ basisKey });
const {
  marketColumnsCount,
  actualMarketType,
  actualMarketTypePriority,
  actualMarket,
} = useMarketColumns({
  sport,
  selectedColumnSize,
  selectedColumnId,
  columns,
  markets,
  doUseFullAlternativeMarketsList,
});
const {
  runnersPlaces,
} = useMarketRunners({
  sport,
  market: actualMarket,
  marketType: actualMarketType,
});
const { slipCaption } = useSlipCaption({ sportlineEvent: sportEvent });
const {
  hasPrimaryMarket,
  hasMore: isMoreRunnersExist,
  moreCaption,
} = useMoreCaption({
  sportEvent,
  actualMarket,
  isUsedMoreRunnersCount,
});
const {
  isExpandButtonAvailable,
  isCollapseButtonAvailable,
  isLockedMoreAvailable,
  onMoreRunnersClick,
  onLessRunnersClick,
} = useSportlineExpandableEventsListItem({
  eventId: sportEventId,
  isMoreRunnersExist,
  hasPrimaryMarket,
  isExtended: toRef(props, 'isExtended', false),
  isExtendedSportEventAllowed: toRef(props, 'isExtendedSportEventAllowed', false),
}, {
  onLinkClick,
  extendElementOpen(): void { emit('extend-element'); },
  extendElementClose(): void { emit('collapse-element'); },
});
</script>

<template>
  <MainMarketContainer v-auto-id="'SportlineEventMainMarket'"
    :actual-market="actualMarket"
    :actual-market-type="actualMarketType"
    :actual-market-type-priority="actualMarketTypePriority"
    :runners-places-count="runnersPlaces.length"
  >
    <template v-if="hasPrimaryMarket">
      <InListRunner
        v-for="(runner, index) in runnersPlaces"
        :key="runner?.id ?? index"
        :runner="runner"
        :market="actualMarket"
        :slip-caption="slipCaption"
        :sport-event-id="sportEventId"
        :sportline-type="sportlineType"
        :is-outright-event="isOutright"
      />
    </template>

    <a
      v-if="isExpandButtonAvailable"
      :href="href"
      :class="{
        [$style['sportline-event-more-indicator']]: true,
        [$style['sportline-event-more-indicator--clickable']]: !hasPrimaryMarket,
        [$style['sportline-event-without-market-indicator']]: !hasPrimaryMarket,
        [$style['sportline-event-without-market-indicator--2-columns']]: !hasPrimaryMarket && marketColumnsCount === 2,
        [$style['sportline-event-without-market-indicator--3-columns']]: !hasPrimaryMarket && marketColumnsCount === 3,
      }"
      @click.prevent.stop="onMoreRunnersClick"
    >
      <span :class="$style['sportline-event-more-indicator__more-caption']">
        {{ moreCaption }}
      </span>
      <VIcon
        :name="IconName.CHEVRON"
        :size="IconSize.SIZE_16"
        :direction="hasPrimaryMarket
          ? ('1' ? IconDirection.SOUTH : IconDirection.RIGHT)
          : IconDirection.RIGHT"
        :class="$style['sportline-event-more-indicator__chevron']"
      />
    </a>
    <div
      v-else-if="'1' && isCollapseButtonAvailable"
      :class="{
        [$style['sportline-event-more-indicator']]: true,
        [$style['sportline-event-without-market-indicator']]: !hasPrimaryMarket,
        [$style['sportline-event-without-market-indicator__2-columns']]: !hasPrimaryMarket && marketColumnsCount === 2,
        [$style['sportline-event-without-market-indicator__3-columns']]: !hasPrimaryMarket && marketColumnsCount === 3,
        [$style['close-extended-event-button__holder']]: true,
      }"
      @click.prevent.stop="onLessRunnersClick"
    >
      <div :class="$style['close-extended-event-button']">
        <VIcon
          :name="IconName.CROSS"
          :size="IconSize.SIZE_16"
        />
      </div>
    </div>
    <div
      v-else-if="isLockedMoreAvailable"
      :class="{
        [$style['sportline-event-more-indicator']]: true,
        [$style['sportline-event-more-indicator--extended']]: isExtended,
        [$style['sportline-event-without-market-indicator']]: !hasPrimaryMarket,
        [$style['sportline-event-without-market-indicator--2-columns']]: !hasPrimaryMarket && marketColumnsCount === 2,
        [$style['sportline-event-without-market-indicator--3-columns']]: !hasPrimaryMarket && marketColumnsCount === 3,
      }"
    >
      <VIcon
        v-if="!hasPrimaryMarket"
        :name="IconName.LOCK"
        :size="IconSize.SIZE_24"
        :class="$style['sportline-event-more-indicator__lock-icon']"
      />
    </div>
  </MainMarketContainer>
</template>

<style module lang="scss">
@import '~web/src/modules/sportline/styles/sport-event-list-columns';

@include for-layout using ($isDesktop) {
  .sportline-event-more-indicator {
    @include medium\14\16\025;

    position: relative;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: flex-end;
    width: get($runnerColumnWidth, more);
    height: 100%;
    padding-right: 8px;
    color: var(--TextDefault);
    text-align: center;
    text-decoration: none;
    user-select: none;
    background-color: transparent;
    border-radius: 5px;

    &--extended {
      @if $isDesktop {
        justify-content: flex-end;
        padding-right: 8px;
      }

      @include for-hover {
        &:hover {
          background-color: transparent;
        }
      }
    }

    &--clickable {
      cursor: pointer;

      @include for-hover {
        &:hover {
          background-color: var(--Layer2);
        }
      }
    }

    &__chevron {
      fill: var(--TextDefault);
    }

    &__lock-icon {
      fill: var(--TextSecondary);
    }

    &__more-caption {
      text-decoration: underline;
    }

    @include for-phone-middle-down {
      min-height: 32px;
    }
  }

  .close-extended-event-button {
    $closeButton: '.close-extended-event-button';

    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    color: var(--TextDefault);
    background-color: var(--Layer2);
    border-radius: 50%;

    @include for-hover {
      &__holder:hover {
        cursor: pointer;

        & #{$closeButton} {
          color: var(--Layer1);
          background-color: var(--TextDefault);
        }
      }
    }
  }

  .sportline-event-without-market-indicator {
    flex-shrink: 0;
    justify-content: center;
    text-decoration: underline;
    border-radius: 5px;

    &--2-columns {
      @include columnsWidth($isDesktop, 2, true);
    }

    &--3-columns {
      @include columnsWidth($isDesktop, 3, true);
    }
  }
}
</style>
