import type { DefinedModule } from '@leon-hub/define-module';

import { AppModuleError } from '../entities';
import { useAsyncWrapper } from './useAsyncWrapper';
import type {
  InnerModuleApi,
  DefinedAppModuleArgs,
  AsyncEsModuleCallback,
  DefinedAppModuleResult,
  DefaultDefinedAppModule,
  DefinedModuleLoaderOptions,
  ModuleWrapper,
  OuterModuleApi,
} from '../types';

type NormalizeUndefined<T extends OuterModuleApi | void> = T extends void ? OuterModuleApi : T;

function loadModule<
  DEFINED_MODULE extends DefinedModule<DefinedAppModuleArgs, DefinedAppModuleResult>,
>(
  getApi: () => InnerModuleApi,
  asyncModule: AsyncEsModuleCallback<'useModule'>,
  condition: boolean,
  options?: DefinedModuleLoaderOptions<DEFINED_MODULE>,
): () => NormalizeUndefined<Awaited<ReturnType<DEFINED_MODULE>>> {
  type RESULT = NormalizeUndefined<Awaited<ReturnType<DEFINED_MODULE>>>;
  if (condition) {
    const api = getApi();
    if (options?.preload) {
      asyncModule().catch((err) => {
        api.bus.emit('log:error', new AppModuleError({
          message: 'Unable to preload async app module',
          cause: err,
        }));
      });
    }
    return () => useAsyncWrapper(
      asyncModule,
      getApi,
      options?.args,
    ) as RESULT;
  }
  return () => useAsyncWrapper(undefined, getApi, options?.args) as RESULT;
}

export const createAppModuleLoaderFactory = (
  getApi: () => InnerModuleApi,
): <
    DEFINED_MODULE extends DefaultDefinedAppModule,
  >(
  asyncModule: AsyncEsModuleCallback<'useModule', DEFINED_MODULE>,
  condition: boolean,
  options?: DefinedModuleLoaderOptions<DEFINED_MODULE>,
  ) => typeof ModuleWrapper<DEFINED_MODULE> => loadModule.bind(null, getApi) as typeof ModuleWrapper;
