import type {
  NavigationGuardNext,
  RouteLocationNormalized,
} from 'vue-router';
import { toRef } from 'vue';

import { RouteName } from '@leon-hub/routing-config-names';
import { stopVpn } from '@leon-hub/cordova';
import {
  assert,
  isString,
} from '@leon-hub/guards';
import { ApiError } from '@leon-hub/api';

import {
  EgsRouterParametersHandler,
  isDemoMethod,
} from 'web/src/modules/egs/submodules/navigation/utils';
import type { AppVueRouter } from 'web/src/modules/core/services/router/types';
import { AbstractPrefetch } from 'web/src/modules/core/services/router/prefetch';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { useCasinoGameStore } from 'web/src/modules/casino/submodules/game/store';
import { useCasinoGameAnalytics } from 'web/src/modules/casino/composables';

export default class CasinoGamePagePrefetch extends AbstractPrefetch {
  // eslint-disable-next-line class-methods-use-this
  async prefetch(
    router: AppVueRouter,
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ): Promise<void> {
    const gamesStore = useCasinoGameStore();
    const activeGame = toRef(gamesStore, 'activeGame');

    const {
      groupUrl,
      gameUrl,
    } = to.params;

    assert(isString(groupUrl));
    assert(isString(gameUrl));

    const gameStartMethod = EgsRouterParametersHandler.getSelectedGameMethod();
    EgsRouterParametersHandler.setSelectedGameMethod(undefined);

    if (process.env.VUE_APP_PLATFORM_CORDOVA) {
      await stopVpn();
    }

    try {
      await gamesStore.setActiveUrl(groupUrl, gameUrl);
    } catch (error) {
      if (!(error instanceof ApiError) || error.code.toString() !== 'EGS_GAME_UNAVAILABLE') {
        throw error;
      }
    }

    const { isLoggedIn } = useIsLoggedIn();

    if (!activeGame.value) {
      next({
        name: RouteName.ERROR_NOT_FOUND_404,
      });
      return;
    }

    if (from.fullPath !== to.fullPath) {
      gamesStore.setDemoStartMode(isDemoMethod(activeGame.value, isLoggedIn.value, gameStartMethod));
    }

    if (process.env.VUE_APP_LAYOUT_DESKTOP) {
      useCasinoGameAnalytics().sendOpenGameMetrika(gamesStore.isDemo);
    }

    next();
  }
}
