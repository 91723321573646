import type { MarketStatus, PriceChangePolicy } from '@leon-hub/api-sdk';

import isPriceChangedMatters from '../submodules/slip-info/utils/isPriceChangedMatters';
import isUnavailableEntryStatus from './isUnavailableEntryStatus';

interface Payload {
  marketStatus?: MarketStatus | null;
  originalOdds?: number;
  currentOdds: number;
  priceChangePolicy: PriceChangePolicy | null;
}

export default function isEntryAvailableForBetting({
  marketStatus, originalOdds, currentOdds, priceChangePolicy,
}: Payload): boolean {
  if (isUnavailableEntryStatus(marketStatus)) {
    return false;
  }
  return !isPriceChangedMatters({
    priceChangePolicy,
    currentOdds,
    originalOdds,
  });
}
