import { defineStore } from 'pinia';
import type { Ref, WatchStopHandle } from 'vue';
import {
  ref,
  watch,
} from 'vue';

import type { ModalHeaderType } from 'web/src/components/Modal/ModalWindowHeader/types';

export const useThemeColorStore = defineStore('theme-color', () => {
  const customThemeColor = ref<ModalHeaderType>();

  let watcher: WatchStopHandle | undefined;

  function startThemeColorWatcher(type: Ref<ModalHeaderType | undefined>): void {
    if (watcher) {
      throw new Error('Custom theme color change handler already registered!');
    }

    watcher = watch(type, (newValue) => {
      customThemeColor.value = newValue;
    }, { immediate: true });
  }

  function stopThemeColorWatcher(): void {
    if (watcher) {
      watcher();
      watcher = undefined;
    }

    customThemeColor.value = undefined;
  }

  return {
    customThemeColor,
    startThemeColorWatcher,
    stopThemeColorWatcher,
  };
});
