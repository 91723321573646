import { isLocalStorageAvailable, localStorageManager } from '@leon-hub/local-storage';

export const enum DEBUG_KEY {
  GENERAL = 'DEBUG',
  POST_MESSAGE = 'DEBUG_POST_MESSAGE',
  ADVANCED_HTTP_REQUESTS_DISABLED = 'ADVANCED_HTTP_REQUESTS_DISABLED',
}

export function isDebugEnabled(debugKey = DEBUG_KEY.GENERAL): boolean {
  return isLocalStorageAvailable() ? Boolean(localStorageManager.getItem(debugKey)) : false;
}

export function enableDebug(debugKey = DEBUG_KEY.GENERAL): void {
  if (isLocalStorageAvailable()) {
    localStorageManager.setItem(debugKey, '1');
  }
}

export function disableDebug(debugKey = DEBUG_KEY.GENERAL): void {
  if (isLocalStorageAvailable()) {
    localStorageManager.removeItem(debugKey);
  }
}
