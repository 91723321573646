import type { Ref } from 'vue';
import { ref } from 'vue';

import { usePendingToFavoriteStoreComposable } from 'web/src/modules/sportline/submodules/favorites/store/composables';
import type { LeagueId } from 'web/src/modules/sportline/types';
import type { AddToCustomerLeaguesParameters } from 'web/src/modules/sportline/submodules/favorites/types';
import { isAddToCustomerLeaguesParametersEquals } from 'web/src/modules/sportline/submodules/favorites/utils';

interface UsePendingToFavoriteLeaguesStoreProps {
  customerLeaguesIds: Ref<Maybe<LeagueId[]>>;
}

interface UsePendingToFavoriteLeaguesStoreComposable {
  pendingToFavoriteLeaguesList: Ref<AddToCustomerLeaguesParameters[]>;
  togglePendingToFavoriteLeaguesList(parameters: AddToCustomerLeaguesParameters): void;
  setupPendingLeagues(): AddToCustomerLeaguesParameters[];
  clearPendingLeagues(): void;

  preparedToAdd: Ref<string[]>;
  preparedToRemove: Ref<string[]>;
  addLeagueIdToTogglePrepared(id: LeagueId): () => void;
}

export function usePendingToFavoriteLeaguesStoreComposable(
  props: UsePendingToFavoriteLeaguesStoreProps,
): UsePendingToFavoriteLeaguesStoreComposable {
  const { customerLeaguesIds } = props;

  const {
    preparedToAdd,
    preparedToRemove,
    setPrepareToAddState,
    setPreparedState: setPreparedStateInternal,
  } = usePendingToFavoriteStoreComposable();

  /**
   * Pending list is a list of ids which will be added after login
   */
  const pendingToFavoriteLeaguesList = ref<AddToCustomerLeaguesParameters[]>([]);

  function togglePendingToFavoriteLeaguesList(parameters: AddToCustomerLeaguesParameters): void {
    const pendingList = pendingToFavoriteLeaguesList.value;
    const alreadyPending = pendingList
      .some((pending) => isAddToCustomerLeaguesParametersEquals(pending, parameters));

    if (alreadyPending) {
      pendingToFavoriteLeaguesList.value = [...pendingList
        .filter((pending) => !isAddToCustomerLeaguesParametersEquals(pending, parameters))];

      return;
    }

    pendingToFavoriteLeaguesList.value = [...pendingList, parameters];
  }

  function setupPendingLeagues(): AddToCustomerLeaguesParameters[] {
    const currentList: string[] = customerLeaguesIds.value ?? [];
    const pendingList = pendingToFavoriteLeaguesList.value
      .filter(({ leagueId }) => !currentList.includes(leagueId));

    for (const pending of pendingList) {
      setPrepareToAddState({ id: pending.leagueId, state: true });
    }

    return pendingList;
  }

  function clearPendingLeagues(): void {
    for (const pending of pendingToFavoriteLeaguesList.value) {
      setPrepareToAddState({ id: pending.leagueId, state: false });
    }

    pendingToFavoriteLeaguesList.value = [];
  }

  function addLeagueIdToTogglePrepared(id: LeagueId): () => void {
    const isFavorite = (customerLeaguesIds.value ?? []).includes(id);
    setPreparedStateInternal(isFavorite, { id, state: true });
    return () => { setPreparedStateInternal(isFavorite, { id, state: false }); };
  }

  return {
    preparedToAdd,
    preparedToRemove,
    pendingToFavoriteLeaguesList,
    addLeagueIdToTogglePrepared,
    togglePendingToFavoriteLeaguesList,
    setupPendingLeagues,
    clearPendingLeagues,
  };
}
