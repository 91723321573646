import { toRef } from 'vue';

import type { BaseError } from '@leon-hub/errors';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';
import { assert } from '@leon-hub/guards';
import type { CustomerRegistrationType } from '@leon-hub/api-sdk';
import { captchaChallengeWasShownKey, CaptchaRequesterStrategy } from '@leon-hub/module-captcha';

import { useAnalytics } from 'web/src/modules/analytics/composables';
import registrationTypeFormat from 'web/src/modules/registration/utils/registrationTypeFormat';
import type { RegistrationMetric } from 'web/src/modules/registration/types/RegistrationMetric';
import useRegistrationIdleRedirectStore from 'web/src/modules/registration/store/useRegistrationIdleRedirectStore';
import { LoginMethod } from 'web/src/modules/user/store/enums';
import { useCaptchaStore } from 'web/src/modules/captcha/store';
import { useCustomerDataStore } from 'web/src/modules/customer/store';

export interface RegistrationAnalytics {
  registrationError(error: BaseError, registrationType?: string): void;
  registrationSuccess(registrationMetrics: RegistrationMetric): void;
  registrationSubmit(): void;
  setMetrikaBonusCode({ registrationType, bonusCode } : { registrationType: CustomerRegistrationType; bonusCode: string }): void;
}

export default function useRegistrationAnalytics(): RegistrationAnalytics {
  const analytics = useAnalytics();
  const idleStore = useRegistrationIdleRedirectStore();

  const customerData = useCustomerDataStore();
  const countryName = toRef(customerData, 'countryName');
  const vipStatus = toRef(customerData, 'vipStatus');

  const { captchaSettingsForStrategy } = useCaptchaStore();
  const captchaSettings = captchaSettingsForStrategy(CaptchaRequesterStrategy.REGISTRATION);

  function registrationError(error: BaseError, registrationType?: string): void {
    if (process.env.VUE_APP_PRODUCT_LEONRU) {
      analytics.push(AnalyticsEvent.Z_REGISTRATION, {
        registration: {
          error: {
            message: error.message,
            code: error.code.toString(),
          },
        },
      });
    } else {
      assert(registrationType);
      analytics.push(AnalyticsEvent.Z_LI_REGISTRATION_ERROR, {
        registration: {
          [registrationType]: {
            error: {
              message: error.message,
              code: error.code.toString(),
            },
          },
        },
      });
    }
    analytics.push(AnalyticsEvent.Z_LOGGED_IN, {
      login: {
        error: {
          message: error.message,
          code: `${error.code}`,
        },
      },
    });
  }

  function registrationSuccess(registrationMetrics: RegistrationMetric): void {
    analytics.registrationSuccess(registrationMetrics);

    if (process.env.VUE_APP_FEATURE_TSUPIS_ENABLED
      || process.env.VUE_APP_FEATURE_SIMPLIFIED_REGISTRATION_ENABLED
      || process.env.VUE_APP_FEATURE_STEP_REGISTRATION_ENABLED
    ) {
      analytics.push(AnalyticsEvent.Z_DURATION_REGISTRATION, {
        registration: {
          durationRegistration: {
            [idleStore.getDurationRegistrationInSeconds()]: registrationMetrics.userId,
          },
        },
      });
    }

    const loginTypeKey = process.env.VUE_APP_FEATURE_TSUPIS_ENABLED
      ? 'fullRegistration'
      : registrationTypeFormat(registrationMetrics?.registrationType ?? null);

    analytics.push(AnalyticsEvent.Z_LOGGED_IN, {
      login: {
        ok: {
          [loginTypeKey]: {
            captchaChallengeWasShown: window.sessionStorage.getItem(captchaChallengeWasShownKey),
            captcha: captchaSettings.value.captchaType,
            loginType: LoginMethod.DEFAULT,
          },
        },
      },
    });

    analytics.yandexZInit({
      userId: registrationMetrics.userId || '',
      customerType: vipStatus.value,
      country: countryName.value,
      currentTheme: registrationMetrics.theme,
    });
  }

  function setMetrikaBonusCode({ registrationType, bonusCode }
  : { registrationType: CustomerRegistrationType; bonusCode: string }): void {
    analytics.push(AnalyticsEvent.Z_BONUS_CODE, {
      registration: {
        [registrationTypeFormat(registrationType)]: {
          bonusCodeDetails: {
            bonusCode: bonusCode || null,
          },
        },
      },
    });
  }

  function registrationSubmit() {
    analytics.clickMap({ registration: 'registrationPage' });
  }

  return {
    registrationError,
    registrationSubmit,
    registrationSuccess,
    setMetrikaBonusCode,
  };
}
