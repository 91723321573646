export { default as LayoutSizes } from './LayoutSizes';

// eslint-disable-next-line import/prefer-default-export
export const enum ModalDesktopPreset {
  SmallDesktopModal = 'SmallDesktopModal',
  DepositDetailsDesktopModal = 'DepositDetailsDesktopModal',
  PrivacyPolicyModal = 'PrivacyPolicyModal',
  LargeDesktopModal = 'LargeDesktopModal',
  SearchDesktopModal = 'SearchDesktopModal',
  ProviderSlotDesktopModal = 'ProviderSlotDesktopModal',
  ProfileDesktopModal = 'ProfileDesktopModal',
  EuroBattlePassModal = 'EuroBattlePassModal',
}

export const enum ModalSelector {
  BODY = 'body',
  DESKTOP_INNER_MODAL = '#desktop-modal',
  APP = '#app',
}

export enum FeedbackApiErrorCode {
  EMAIL_ALREADY_EXISTS = 'EMAIL_ALREADY_EXISTS',
}
