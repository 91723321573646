import type { Ref } from 'vue';
import { toRef } from 'vue';

import { FormControlType } from '@leon-hub/form-utils';

import { useSiteConfigStore } from 'web/src/modules/core/store';
import type { FormUiSchemaDefaultFields } from 'web/src/components/Form/types';
import { CustomCustomerFieldType } from 'web/src/modules/registration/enums';

export interface RecaptchaV3PropsComposable {
  isRegistrationCaptchaV3Enabled: Ref<boolean>;
  configuredCaptchaV3UiSchema: Ref<FormUiSchemaDefaultFields>;
}

export interface RecaptchaV3Props {
  action?: string;
}

export function useRecaptchaV3Props(props?: RecaptchaV3Props): RecaptchaV3PropsComposable {
  if (process.env.VUE_APP_FEATURE_RECAPTCHA_V3_ENABLED) {
    const siteConfigStore = useSiteConfigStore();
    const isRegistrationCaptchaV3Enabled = toRef(() => siteConfigStore.captcha?.recaptchaV3?.registrationEnabled ?? false);
    return {
      isRegistrationCaptchaV3Enabled,
      configuredCaptchaV3UiSchema: toRef<() => FormUiSchemaDefaultFields>(() => ({
        [CustomCustomerFieldType.CAPTCHA_TOKEN_V3]: {
          options: {
            ...(props ?? {}),
          },
          hidden: true,
          widget: FormControlType.CaptchaV3,
        },
      })),
    };
  }

  return {
    isRegistrationCaptchaV3Enabled: toRef(() => false),
    configuredCaptchaV3UiSchema: toRef(() => ({})),
  };
}
