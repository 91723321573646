import type { SportsTreeSportElement } from 'web/src/modules/sportline/types/rest';
import type {
  CustomerLeagueListElement,
  CustomerSportListElement,
} from 'web/src/modules/sportline/submodules/favorites/types';

export default function getFavoriteLeaguesFromSportList(
  sportList: SportsTreeSportElement[] | Readonly<SportsTreeSportElement[]>,
  customerLeaguesIds: string[] | Readonly<string[]>,
): CustomerSportListElement[] {
  return (sportList as SportsTreeSportElement[]).map<CustomerSportListElement>((sportElement) => {
    const leaguesList: CustomerLeagueListElement[] = (sportElement.regions || [])
      .flatMap((regionElement) => {
        const leagues = (regionElement.leagues || [])
          .filter((leagueElement) => customerLeaguesIds.includes(leagueElement.league.id));

        return leagues.map<CustomerLeagueListElement>((leagueElement) => ({
          league: leagueElement.league,
          region: regionElement.region,
          counters: leagueElement.counters,
        }));
      });

    return {
      sport: sportElement.sport,
      leaguesCount: sportElement.leaguesCount,
      leagues: leaguesList || [],
    };
  })
    .filter((sportElement: CustomerSportListElement) => sportElement.leagues.length > 0);
}
