import type {
  LiveChatObject,
} from '../services/api/types';
import { LiveChatStatus } from '../enums';

export default class DefaultLiveChatObject implements LiveChatObject {
  public question = '';

  public status = LiveChatStatus.ENDED;

  public started = Date.now();

  public conversation = [];

  public typing = false;

  public hasSurvey = false;

  public isInputDisabled = false;

  public isSurveyVisible = false;

  public actions = [];

  public surveyMessage = '';

  public surveyRating = 0;

  constructor(status?: number) {
    if (status) {
      this.status = status;
    }
  }
}
