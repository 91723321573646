enum SocialType {
  OK = 'ok',
  VK = 'vk',
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
  TELEGRAM = 'telegram',
  RSS = 'rss',
}

export default SocialType;
