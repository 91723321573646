import { localStorageManager } from '@leon-hub/local-storage';

const deviceCustomerLoginKey = 'deviceCustomerLogin';

// TODO: create unified mechanism for sync storage data
const DeviceCustomerLoginStorage = {
  setLogin(value: string): void {
    localStorageManager.setItem(deviceCustomerLoginKey, value);
  },
  remove(): void {
    localStorageManager.removeItem(deviceCustomerLoginKey);
  },
  getLogin(): string | null {
    return localStorageManager.getItem(deviceCustomerLoginKey);
  },
};

export default DeviceCustomerLoginStorage;
