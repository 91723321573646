import type { Router, RouteLocationNormalized } from 'vue-router';

import type { LobbyGameType } from '@leon-hub/api-sdk';

import navigateTo from 'web/src/utils/router/navigateTo';

import getLobbyLocation from './getLobbyLocation';

export default function navigateToLobbyPage(
  router: Router,
  route: RouteLocationNormalized,
  groupUrl?: string,
  categoryUrl?: string,
  lobbyType?: Maybe<LobbyGameType>,
): void {
  navigateTo(router, getLobbyLocation(route, groupUrl, categoryUrl, lobbyType));
}
