import type {
  DropListSelectOption,
  DropListMenuOptionGroup,
} from '../types';

/** quick and cheap check for options type. For full check use guards */

export function isPlainOptions(options?: DropListSelectOption[] | DropListMenuOptionGroup[]): boolean {
  if (!options?.length) {
    return true;
  }
  const firstItem = options[0];
  return !('options' in firstItem);
}
