import { useRouter } from 'vue-router';

import { BusEvent, useEventsBus } from '@leon-hub/event-bus';

import type { EgsGroup } from 'web/src/modules/egs/types';
import { getLobbyLocationByGroup } from 'web/src/modules/casino/utils';

import type {
  CasinoGroupsListEmits,
} from '../types';

export interface UseCasinoGroupsListComposable {
  onClick(group: EgsGroup): void;
  onSelectedLoaded(el: HTMLElement): void;
}

export default function useCasinoGroupsList(
  emit: CasinoGroupsListEmits,
): UseCasinoGroupsListComposable {
  const router = useRouter();
  const bus = useEventsBus();

  function onClick(group: EgsGroup): void {
    emit('click-group', group);
    bus.emit(BusEvent.LAYOUT_CONTENT_SCROLL_TOP, {});
    void router.push(getLobbyLocationByGroup(group));
  }

  function onSelectedLoaded(el: HTMLElement): void {
    emit('selected-loaded', el);
  }

  return {
    onClick,
    onSelectedLoaded,
  };
}
