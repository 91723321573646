export enum InputEventType {
  INPUT = 'input',
  CHANGE = 'change',
  FOCUS = 'focus',
  BLUR = 'blur',
  SUBMIT = 'submit',
}

export enum AdditionalInputEventType {
  MOUSEDOWN = 'mousedown',
  KEYDOWN = 'keydown',
  MOUSEOVER = 'mouseover',
  MOUSELEAVE = 'mouseleave',
  PASTE = 'paste',
}

export enum CustomInputEventType {
  FILLED = 'filled',
  CLEAR = 'clear',
  ICON_PREFIX_CLICK = 'icon-prefix-click',
  ICON_SUFFIX_CLICK = 'icon-suffix-click',
}
