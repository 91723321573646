import type { Ref } from 'vue';
import { toRef } from 'vue';

import { useCurrencyStore } from '../store';

export interface UseCurrency {
  currency: Ref<string>;
}

export default function useCurrency(): UseCurrency {
  const currency = toRef(useCurrencyStore(), 'currency');

  return {
    currency,
  };
}
