import { computed } from 'vue';
import type { Ref } from 'vue';

import type { MarketType, Market } from 'web/src/modules/sportline/types';

interface CreateShownMarketNameRefProps {
  actualMarket: Ref<Maybe<Market>>;
  actualMarketType: Ref<Maybe<MarketType>>;
  actualMarketTypePriority: Ref<number>;
}

export function createShownMarketNameRef(props: CreateShownMarketNameRefProps): Ref<Maybe<string>> {
  const {
    actualMarket,
    actualMarketType,
    actualMarketTypePriority,
  } = props;
  return computed(() => (
    !!actualMarket.value && actualMarketType.value && actualMarketTypePriority.value > 0
      ? actualMarketType.value.name
      : null
  ));
}
