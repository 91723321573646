import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import { BetSlipResultState, TopLevelTabs } from '../../../enums';
import type { SlipLayoutEmits, SlipLayoutProps } from '../types';

interface UseSlipLayoutCommon {
  selectTopTab(id: TopLevelTabs): void;
  showMyBetsTab: ComputedRef<boolean>;
  isPendingResultState: ComputedRef<boolean>;
}

export default function useSlipLayoutCommon(
  props: SlipLayoutProps,
  emit: SlipLayoutEmits,
): UseSlipLayoutCommon {
  const selectTopTab = (id: TopLevelTabs): void => {
    emit('select-top-tab', id);
  };

  const showMyBetsTab = computed<boolean>(() => !!props.isLogged && props.activeTopTabId === TopLevelTabs.MY_BETS);

  const isPendingResultState = computed<boolean>(() => props.resultState === BetSlipResultState.PENDING || props.resultState === BetSlipResultState.WAIT_FOR_RETRY);

  return {
    selectTopTab,
    showMyBetsTab,
    isPendingResultState,
  };
}
