import { computed } from 'vue';
import type { ComputedRef } from 'vue';

import {
  assert,
  isOptionalString,
} from '@leon-hub/guards';

import type { InputWrappedProps } from 'web/src/components/Input';
import type { TextInputTypes } from 'web/src/components/Input/enums';

import type {
  FormWrapperWidgetProperties,
} from '../types';
import getWrapperHintProperties from '../utils/getWrapperHintProperties';

interface UseCommonFormWidgetProps {
  componentProps: ComputedRef<InputWrappedProps>;
}

export default function useCommonFormWidgetProps<T>(
  props: FormWrapperWidgetProperties<T>,
  inputType?: TextInputTypes,
): UseCommonFormWidgetProps {
  const componentProps = computed<InputWrappedProps>(() => {
    const {
      name,
      value,
      options,
      title,
      error,
      disabled,
      hintMessage,
      autofocus,
    } = props;

    assert(isOptionalString(value), `Unexpected value type: ${typeof value} at field ${name}`);

    return {
      name,
      value,
      label: title,
      disabled,
      ...(inputType ? { type: inputType } : {}),
      ...(options ?? {}),
      ...getWrapperHintProperties({ options, error, hintMessage }),
      autofocus,
    };
  });
  return {
    componentProps,
  };
}
