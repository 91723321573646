import type { Ref } from 'vue';
import { computed, toRef } from 'vue';

import { useLogErrorOnceForValue } from 'web/src/modules/sportline/composables/utils';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import type {
  GetSportResponse,
  GetRegionResponse,
  GetLeagueResponse,
} from 'web/src/modules/sportline/types/rest';
import type {
  SportElement,
  RegionElement,
  LeagueElement,
} from 'web/src/modules/sportline/types';
import type { SplitEntityResponse } from 'web/src/modules/sportline/utils/rest/pre-build';
import { extractBuildSportOptions, splitGetLeagueResponse } from 'web/src/modules/sportline/utils/rest/pre-build';
import {
  parseLeagueResponse,
  parseRegionResponse,
  parseSportResponse,
} from 'web/src/modules/sportline/utils/rest/build';
import { createSportEventsCounters, reduceListWithSportEventsCounters } from 'web/src/modules/sportline/utils';

interface UseGetSportlineEntityFactoryProps {
  rawSportResponse?: Ref<Maybe<GetSportResponse> | false>;
  rawRegionResponse?: Ref<Maybe<GetRegionResponse> | false>;
  rawLeagueResponse?: Ref<Maybe<GetLeagueResponse> | false>;
}

interface UseGetSportlineEntityFactoryComposable {
  sportElement: Ref<Maybe<SportElement>>;
  regionElement: Ref<Maybe<RegionElement>>;
  leagueElement: Ref<Maybe<LeagueElement>>;
}

export function useGetSportlineEntityFactory(
  props: UseGetSportlineEntityFactoryProps,
): UseGetSportlineEntityFactoryComposable {
  const { rawLeagueResponse } = props;

  const sportlineSettingsStore = useSportlineSettingsStore();
  const parseSportlineSettings = toRef(sportlineSettingsStore, 'parseSportlineSettings');

  const buildSportOptions = computed(() => extractBuildSportOptions(parseSportlineSettings.value));
  const adaptedResponses = computed<Maybe<SplitEntityResponse>>(() => {
    if (rawLeagueResponse?.value) { return splitGetLeagueResponse(rawLeagueResponse.value); }
    return null;
  });
  const { logError } = useLogErrorOnceForValue(adaptedResponses);

  const leagueElement = computed<Maybe<LeagueElement>>(() => {
    try {
      if (!adaptedResponses.value?.sportResponse) { return null; }
      if (!adaptedResponses.value?.regionResponse) { return null; }
      if (!adaptedResponses.value?.leagueResponse) { return null; }

      const league = parseLeagueResponse({
        sportResponse: adaptedResponses.value.sportResponse,
        regionResponse: adaptedResponses.value.regionResponse,
        leagueResponse: adaptedResponses.value.leagueResponse,
        ...buildSportOptions.value,
      });
      const { inplay, prematch, outright } = adaptedResponses.value.leagueResponse;

      return {
        key: league.league.key,
        league: league.league,
        marketTypes: [],
        sportEvents: [],
        outrightEvents: [],
        counters: createSportEventsCounters(inplay, prematch, 0, outright),
      };
    } catch (error) {
      logError(error);
      return null;
    }
  });

  const regionElement = computed<Maybe<RegionElement>>(() => {
    try {
      if (!adaptedResponses.value?.sportResponse) { return null; }
      if (!adaptedResponses.value?.regionResponse) { return null; }

      const region = parseRegionResponse({
        sportResponse: adaptedResponses.value.sportResponse,
        regionResponse: adaptedResponses.value.regionResponse,
        ...buildSportOptions.value,
      });
      const leagues = leagueElement.value ? [leagueElement.value] : [];

      return {
        key: region.region.id,
        region: region.region,
        marketTypes: [],
        leagues,
        counters: reduceListWithSportEventsCounters(leagues),
      };
    } catch (error) {
      logError(error);
      return null;
    }
  });

  const sportElement = computed<Maybe<SportElement>>(() => {
    try {
      if (!adaptedResponses.value?.sportResponse) { return null; }

      const sport = parseSportResponse(adaptedResponses.value?.sportResponse, buildSportOptions.value);
      const regions = regionElement.value ? [regionElement.value] : [];

      return {
        key: sport.sport.id,
        sport: sport.sport,
        regions,
        marketTypes: [],
        marketsColumns: sport.marketsColumns,
        counters: reduceListWithSportEventsCounters(regions),
      };
    } catch (error) {
      logError(error);
      return null;
    }
  });

  return {
    sportElement,
    regionElement,
    leagueElement,
  };
}
