import {
  assert, isArray, isString, isArrayOfStrings,
} from '@leon-hub/guards';

export function isArrayAsString(input: string): boolean {
  // start with [ and " or ' and end with ]
  const isWrappedByBrackets = /^[[]['|"]/g.test(input) && input.endsWith(']');
  if (!isWrappedByBrackets) {
    return false;
  }
  const singleQuotesCount = input.replace(/[^']/g, '').length;
  const doubleQuotesCount = input.replace(/[^"]/g, '').length;
  return !!(singleQuotesCount || doubleQuotesCount) // any kind of quotes should exist
    && ((!singleQuotesCount || singleQuotesCount % 2 === 0) // none or even
      || (!doubleQuotesCount || doubleQuotesCount % 2 === 0)); // none or even
}

export function getArrayFromString(value: string): string[] {
  let input = value;
  if (isArrayAsString(input)) {
    input = input.slice(1, -1);
  }
  const asArray = input
    .replaceAll(' ', '')
    .split(',')
    .filter((item) => !!item)
    .map((item) => {
      if (/^("\S*")$/g.test(item) || /^('\S*')/g.test(item)) {
        return item.slice(1, -1);
      }
      return item;
    });
  if (!isArray(asArray)) {
    throw new Error('Unexpected value passed into forbiddenSymbols option');
  }
  assert(isArrayOfStrings(asArray), `Can not to convert string to array: ${input}`);
  return asArray;
}

export default function getStringArrayOption(value: unknown): string[] {
  if (isString(value)) {
    return getArrayFromString(value);
  }
  if (isArray(value)) {
    assert(isArrayOfStrings(value), 'getStringArrayOption: value is not isArrayOfStrings');
    return value;
  }
  return [];
}
