import { CordovaWebSocket } from '@leon-hub/cordova';
import { assert } from '@leon-hub/guards';
import { Deferred, Timer } from '@leon-hub/utils';

import { createDebugCallback } from '../utils/debug';

const whileDebug = createDebugCallback('SocketWrapper');

interface SocketData {
  id: number;
  deferred: Deferred<WebSocket>;
  timeout: number;
}

function isSocketOpen(socket: WebSocket): boolean {
  return socket.readyState === WebSocket.OPEN;
}

export default class SocketWrapper {
  private socket: WebSocket | null = null;

  private socketCounter = 0;

  private sendCounter = 0;

  private perSocketMap = new WeakMap<WebSocket, SocketData>();

  createSocket(): WebSocket {
    if (process.env.VUE_APP_PLATFORM_CORDOVA) {
      const options = { timeout: this.options.openTimeout };
      return new CordovaWebSocket(this.options.url, options);
    }
    return new WebSocket(this.options.url);
  }

  constructor(private options: {
    url: string;
    openTimeout: number;
  }) {}

  private getSocketData(socket: WebSocket): SocketData {
    let socketData = this.perSocketMap.get(socket);
    if (!socketData) {
      this.socketCounter += 1;
      socketData = {
        id: this.socketCounter,
        deferred: new Deferred<WebSocket>(),
        timeout: 0,
      };
      this.perSocketMap.set(socket, socketData);
    }
    return socketData;
  }

  async connect(): Promise<WebSocket> {
    if (this.socket) {
      return this.getSocketData(this.socket).deferred.promise;
    }
    const socket = this.createSocket();
    const { deferred, id } = this.getSocketData(socket);

    whileDebug((log) => log(
      `[socket#${id}] initializing new connection with state ${socket.readyState}`,
    ));

    deferred.promise.then(() => {
      whileDebug((log) => log(
        `[socket#${id}] deferred connection promise has been resolved`,
      ));
    });

    socket.addEventListener('open', () => {
      whileDebug((log) => log(`[socket#${id}] received open event`));
      deferred.resolve(socket);
    });

    socket.addEventListener('error', (event: Event) => {
      // eslint-disable-next-line no-console
      whileDebug((log) => log(
        `[socket#${id}] received error event %O`, event,
      ));
      // eslint-disable-next-line no-console
      console.warn('Socket error event occurred', event);
      deferred.reject(new Error('Unexpected socket error'));
      void this.detach('WebSocket received an error event');
    });

    socket.addEventListener('close', (event: CloseEvent) => {
      whileDebug((log) => log(
        `[socket#${id}] received close event: %s %s`,
        event.code,
        event.reason,
      ));
      if (this.socket !== socket) {
        whileDebug((log) => {
          log(
            `[socket#${id}] redundant, skipping detach`,
          );
        });
        return;
      }
      void this.detach();
    });

    this.socket = socket;
    deferred.promise.then(() => {
      this.refreshTimeout();
    });

    return deferred.promise;
  }

  refreshTimeout(): void {
    const { socket } = this;
    assert(socket, 'Expected socket to be defined');
    if (socket) {
      this.resetTimeout(socket, () => {
        whileDebug((log) => {
          const { id } = this.getSocketData(socket);
          log(`[socket#${id}] timeout for socket`);
        });
        void this.detach();
      });
    }
  }

  private resetTimeout(socket: WebSocket, callback?: () => void): void {
    const socketDate = this.getSocketData(socket);
    whileDebug((log) => {
      log(`[socket#${socketDate.id}] resetting socket timeout`);
    });
    Timer.clearTimeout(socketDate.timeout);
    if (callback) {
      socketDate.timeout = Timer.setTimeout(callback, this.options.openTimeout);
    }
  }

  async send<T extends { type: string; payload: object }>(data: T): Promise<void> {
    this.sendCounter += 1;
    const { sendCounter } = this;
    whileDebug((log) => log(
      `[send#${sendCounter}] about to send data: ${JSON.stringify(data)}`,
    ));
    const socket = await this.connect();
    whileDebug((log) => log(
      `[send#${sendCounter}] connection is ready`,
    ));
    socket.send(JSON.stringify(data));
    whileDebug((log) => log(
      `[send#${sendCounter}] data has been sent`,
    ));
    this.refreshTimeout();
  }

  async detach(reason?: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const { socket } = this;
      if (socket) {
        const { deferred, id, timeout } = this.getSocketData(socket);
        whileDebug((log) => log(
          `[socket#${id}] detaching..`,
        ));
        this.socket = null;

        Timer.clearTimeout(timeout);
        const { readyState } = socket;
        if (readyState !== WebSocket.CLOSED && readyState !== WebSocket.CLOSING) {
          whileDebug((log) => log(
            `[socket#${id}] closing socket with state ${readyState}`,
          ));
          socket.close();
        }
        if (reason) {
          deferred.promise.catch(reject);
          deferred.reject(new Error(reason));
        }
      } else {
        whileDebug((log) => log(
          '[socket#null] no socket to detach',
        ));
      }
      resolve();
    });
  }

  isSocketOpen(): boolean {
    whileDebug((log) => {
      const id = this.socket ? this.getSocketData(this.socket).id : null;
      return log(
        `[socket#${id}] status: ${this.socket ? this.socket.readyState : 'no socket'}`,
      );
    });
    return !!(this.socket && isSocketOpen(this.socket));
  }
}
