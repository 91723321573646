enum PromoRouteName {
  PROMOTIONS = 'promotions',
  PROMOTION_DETAILS = 'promotion-details',
  BONUS_WALLET = 'bonus-wallet',
  TOURNAMENTS = 'tournaments',
  TOURNAMENT_DETAILS = 'tournament-details',
}

export default PromoRouteName;
const promoRouteNames = Object.values(PromoRouteName);

export function isPromoRouteName(value: unknown): value is PromoRouteName {
  return promoRouteNames.includes(value as PromoRouteName);
}
