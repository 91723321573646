export function getElementPosition(
  element: HTMLElement,
  parent?: Maybe<HTMLElement>,
): { top: number; left: number } {
  const box = element.getBoundingClientRect();

  const parentEl = parent ?? document.documentElement ?? document.body;

  const scrollTop = parentEl.scrollTop ?? window.scrollY;
  const scrollLeft = parentEl.scrollLeft ?? window.scrollX;

  const clientTop = parentEl.clientTop ?? 0;
  const clientLeft = parentEl.clientLeft ?? 0;

  const top = box.top + scrollTop - clientTop;
  const left = box.left + scrollLeft - clientLeft;

  return { top: Math.round(top), left: Math.round(left) };
}
