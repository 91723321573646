export const enum Currency {
  /** Euro */
  EUR = 'EUR',
  /** Russian Ruble */
  RUB = 'RUB',
  /** US Dollar */
  USD = 'USD',
  /** Ukrainian Hryvnia */
  UAH = 'UAH',
  /** Kazakhstani Tenge */
  KZT = 'KZT',
  /** Polish Zloty */
  PLN = 'PLN',
  /** British Pound Sterling */
  GBP = 'GBP',
  /** Swedish Krona */
  SEK = 'SEK',
  /** Norwegian Krone */
  NOK = 'NOK',
  /** Swiss Franc */
  CHF = 'CHF',
  /** Belarusian Ruble */
  BYN = 'BYN',
  /** Danish Krone */
  DKK = 'DKK',
  /** Turkish Lira */
  TRY = 'TRY',
  /** Argentine Peso */
  ARS = 'ARS',
  /** Mexican Peso */
  MXN = 'MXN',
  /** Brazilian Real */
  BRL = 'BRL',
  /** Chilean Peso */
  CLP = 'CLP',
  /** Uruguayan Peso */
  UYU = 'UYU',
  /** Peruvian Nuevo Sol */
  PEN = 'PEN',
  /** Colombian Peso */
  COP = 'COP',
  /** Czech Republic Koruna */
  CZK = 'CZK',
  /** Bolivar Fuerte */
  BSF = 'BSF',
  /** Chinese Yuan */
  CNY = 'RMB',
  /** Hong Kong Dollar */
  HKD = 'HKD',
  /** New Taiwan dollar */
  TWD = 'TWD',
  /** Indian rupee */
  INR = 'INR',
  /** Japanese yen */
  JPY = 'JPY',
  /** Azerbaijan Manat */
  AZN = 'AZN',
  /** Uzbekistan Sum */
  UZS = 'UZS',
  /** Canadian dollar */
  CAD = 'CAD',
  /** Tajikistanian Somoni */
  TJS = 'TJS',
  /** Kyrgyzstanian Som */
  KGS = 'KGS',
  /** Turkmenistan New Manat */
  TMT = 'TMT',
  /** Moldovan Leu */
  MDL = 'MDL',
  /** Australian Dollar */
  AUD = 'AUD',
  /** New Zealand Dollar */
  NZD = 'NZD',
  /** Vietnamese đồng */
  VND = 'VND',
  /** Thai baht */
  THB = 'THB',

  /** Bitcoin */
  BTC = 'BTC',
  /** Litecoin */
  LTC = 'LTC',
  /** Ethereum */
  ETH = 'ETH',

  /** Is used for Leons */
  KHQ = 'KHQ',
  LCC = 'LCC',

  /** Tanzanian shilling */
  TZS = 'TZS',
  USDT = 'USDT',
  USDC = 'USDC',
}
