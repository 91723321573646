import { ComponentKey, ComponentStatus } from '../types';
import type { ComponentResult } from '../types';
import AbstractFingerprintComponent from '../AbstractFingerprintComponent';

export default class TimezoneComponent extends AbstractFingerprintComponent {
  constructor() {
    super(ComponentKey.Timezone);
  }

  getComponentResult(): Promise<ComponentResult> {
    if (window.Intl && window.Intl.DateTimeFormat) {
      return this.result([new window.Intl.DateTimeFormat().resolvedOptions().timeZone]);
    }

    return this.result([ComponentStatus.NotAvailable]);
  }
}
