import type { RouteLocationRaw } from 'vue-router';

import type { AppVueRouter } from 'web/src/modules/core/services/router/types';

export default async function navigateToLocationsWithCallbacks(
  $router: AppVueRouter,
  location: RouteLocationRaw,
  cbs?: {
    beforeNavigation?: Function;
    onAbort?: Function;
  },
): Promise<void> {
  if ($router.canNavigateTo(location)) {
    if (cbs?.beforeNavigation) {
      await cbs?.beforeNavigation();
    }

    await $router.push(location);
  } else if (cbs?.onAbort) {
    cbs?.onAbort();
  }
}
