<script lang="ts" setup>
import { ButtonHeight } from '@leon-hub/module-buttons';

import VButton from 'web/src/components/Button/VButton/VButton.vue';

import type {
  ProfileBonusesCardButtonControllerEmits,
  ProfileBonusesCardButtonControllerProps,
} from './types';

withDefaults(defineProps<ProfileBonusesCardButtonControllerProps>(), {
  buttons: () => ([]),
});

const emit = defineEmits<ProfileBonusesCardButtonControllerEmits>();
</script>

<template>
  <div v-auto-id="'ProfileBonusesCardButtonController'"
    :class="{
      [$style['profile-bonuses-card-buttons']]: true,
      [$style['profile-bonuses-card-buttons--has-columns']]: hasColumns
    }"
  >
    <VButton
      v-for="(button, index) in buttons"
      v-bind="button"
      :key="`${index}`"
      :height="null ? ButtonHeight.MEDIUM : undefined"
      @click="emit('click', button.id)"
    />
  </div>
</template>

<style lang="scss" module>
.profile-bonuses-card-buttons {
  $gap: 8px;

  display: grid;
  grid-row-gap: $gap * 2;
  padding: $gap $gap * 2;
  background-color: var(--Layer1);

  &--has-columns {
    grid-template-columns: repeat(2, calc(50% - #{$gap}));
    grid-column-gap: $gap * 2;
  }
}
</style>
