<script lang="ts" setup>
import { useRouter } from 'vue-router';
import type { RouteLocationRaw } from 'vue-router';

import { BusEvent, useEventsBus } from '@leon-hub/event-bus';

import routerLinkClickGuard from 'web/src/components/Link/utils/routerLinkClickGuard';

import { useVRouterLink } from './composables';
import type { VRouterLinkProps, VRouterLinkEmits } from './types';

const props = withDefaults(defineProps<VRouterLinkProps>(), {});
const emit = defineEmits<VRouterLinkEmits>();
const { href, target } = useVRouterLink(props);
const router = useRouter();
const bus = useEventsBus();

function clickHandler(mouseEvent: MouseEvent): void {
  if (!props.disabled) {
    if (routerLinkClickGuard(mouseEvent)) {
      emitBeforeNavigate(mouseEvent, target.value);
      if (props.replace) {
        void router.replace(target.value);
      } else if (router.currentRoute.value.fullPath === target.value.fullPath) {
        bus.emit(BusEvent.LAYOUT_CONTENT_SCROLL_TOP, { smooth: true });
      } else {
        void router.push(target.value, {
          saveScrollPosition: props.saveScrollPosition,
        });
      }
    }

    emitClick(mouseEvent);
  }
}

function emitBeforeNavigate(originalEvent: MouseEvent, to: RouteLocationRaw): void {
  emit('before-navigate', { originalEvent, to });
}

function emitClick(event: MouseEvent): void {
  emit('click', event);
}

function emitMouseEnter(event: MouseEvent): void {
  emit('mouseenter', event);
}

function emitMouseLeave(event: MouseEvent): void {
  emit('mouseleave', event);
}
</script>

<template>
  <a v-auto-id="'VRouterLink'"
    :href="href"
    :class="{
      [$style['router-link--decoration-hidden']]: isHiddenDecoration,
    }"
    @click="clickHandler"
    @mouseenter="emitMouseEnter"
    @mouseleave="emitMouseLeave"
  >
    <slot />
  </a>
</template>

<style lang="scss" module>
.router-link {
  &--decoration-hidden {
    text-decoration: none;
  }
}
</style>
