import {
  isBoolean,
  isNumber,
  isString,
  isUndefined,
  isValidInterface,
  isOptionalString,
  isNull,
  isArrayOf,
  isArrayOfStrings,
  isObjectValues,
} from '@leon-hub/guards';

import type {
  FastTrackAuthentication,
  FastTrackBaseEndpointsResponse,
  FastTrackBaseResponse,
  FastTrackDeleteMessageResponse,
  FastTrackDoLoginResponseData,
  FastTrackMarkMessageAsReadResponse,
  FastTrackMessage,
  FastTrackMessageDisplayType,
  FastTrackMessageEvent,
  FastTrackUser,
} from './types';
import {
  fastTrackMessageDisplayType,
  fastTrackMessageEvents,
} from './types';

export function isFastTrackBaseEndpointsResponse(argument: unknown): argument is FastTrackBaseEndpointsResponse {
  return isValidInterface<FastTrackBaseEndpointsResponse>(argument, {
    pusherKey: isString,
    fusionUrl: isString,
    pusherRegion: isString,
  });
}

function isFastTrackUser(argument: unknown): argument is FastTrackUser {
  return isValidInterface<FastTrackUser>(argument, {
    UserId: isNumber,
  });
}

function isFastTrackMessageEvent(argument: unknown): argument is FastTrackMessageEvent {
  return fastTrackMessageEvents.includes(argument as FastTrackMessageEvent);
}

function isFastTrackMessageDisplayType(argument: unknown): argument is FastTrackMessageDisplayType {
  return fastTrackMessageDisplayType.includes(argument as FastTrackMessageDisplayType);
}

export function isFastTrackMessage(argument: unknown): argument is FastTrackMessage {
  return isValidInterface<FastTrackMessage>(argument, {
    MessageId: isNumber,
    UserId: isNumber,
    Event: isFastTrackMessageEvent,
    Title: isString,
    Message: isString,
    PreviewText: isOptionalString,
    FooterText: isOptionalString,
    Data: [
      isUndefined,
      (value): value is Record<string, string> => isObjectValues([isString], value),
    ],
    CTAButtonLink: isOptionalString,
    CTAButtonText: isOptionalString,
    CTAButton2Link: isOptionalString,
    CTAButton2Text: isOptionalString,
    ImageUrl: isOptionalString,
    IsRead: isBoolean,
    DisplayType: isFastTrackMessageDisplayType,
    Date: isString,
    Expires: isOptionalString,
  });
}

export function isFastTrackMarkMessageAsReadResponse(
  argument: unknown,
): argument is FastTrackMarkMessageAsReadResponse {
  return isValidInterface<FastTrackMarkMessageAsReadResponse>(argument, {
    Data: [isUndefined, isBoolean, isNull],
    Success: isBoolean,
    Errors: [isNull, isArrayOfStrings],
  });
}

export function isFastTrackDeleteMessageResponse(
  argument: unknown,
): argument is FastTrackDeleteMessageResponse {
  return isValidInterface<FastTrackDeleteMessageResponse>(argument, {
    Data: [isUndefined, isBoolean, isNull],
    Success: isBoolean,
    Errors: [isNull, isArrayOfStrings],
  });
}

function isFastTrackAuthentication(argument: unknown): argument is FastTrackAuthentication {
  return isValidInterface<FastTrackAuthentication>(argument, {
    AuthToken: isString,
  });
}

function isFastTrackDoLoginResponseData(argument: unknown): argument is FastTrackDoLoginResponseData {
  return isValidInterface<FastTrackDoLoginResponseData>(argument, {
    User: isFastTrackUser,
    Authentication: isFastTrackAuthentication,
  });
}

export function isFastTrackDoLoginResponse(
  argument: unknown,
): argument is FastTrackBaseResponse<FastTrackDoLoginResponseData> {
  return isValidInterface<FastTrackBaseResponse<FastTrackDoLoginResponseData>>(argument, {
    Data: [isUndefined, isFastTrackDoLoginResponseData],
    Success: isBoolean,
    Errors: [isNull, isArrayOfStrings],
  });
}

export function isFastTrackMessagesResponse(
  argument: unknown,
): argument is FastTrackBaseResponse<FastTrackMessage[]> {
  return isValidInterface<FastTrackBaseResponse<FastTrackMessage[]>>(argument, {
    Data: [
      isUndefined,
      (value): value is FastTrackMessage[] => isArrayOf(isFastTrackMessage, value),
    ],
    Success: isBoolean,
    Errors: [isNull, isArrayOfStrings],
  });
}
