import { PostMessageEvent } from '@leon-hub/postmessage-bus';

import type {
  SlipMappingKey,
  VirtualSportConfig,
  VirtualSportState,
  VirtualSportChangedBets,
} from 'web/src/modules/framed-app/components/VirtualSportFramedWidget/types';

export const VirtualSportWidgetPostMessageBusInitiator = 'VirtualSportFramedWidget';

export default class VirtualSportWidgetPostMessageEvent extends PostMessageEvent {
  static readonly syncConfig = new PostMessageEvent<VirtualSportConfig>('widget-sync-config');

  static readonly stateChanged = new PostMessageEvent<VirtualSportState>('widget-state-changed');

  static readonly betsChanged = new PostMessageEvent<VirtualSportChangedBets>('widget-bets-changed');

  static readonly slipSelectedMappingsChanged = new PostMessageEvent<{
    mappings: SlipMappingKey[];
  }>('slip-selected-mappings-changed');

  static readonly clearBet = new PostMessageEvent<{ mapping: SlipMappingKey }>('widget-clear-bet');

  static readonly clearAllBets = new PostMessageEvent<{}>('widget-clear-all-bets');
}
