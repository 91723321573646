import { localStorageManager } from '@leon-hub/local-storage';

import getNewUri from 'web/src/modules/core/services/router/utils/getNewUri';
import { REFRESHED_AFTER_LOGIN } from 'web/src/modules/i18n/consts';

export function reloadPageWithCurrentLang(replaced: string, current: string): void {
  if (process.env.VUE_APP_PLATFORM_CORDOVA) {
    localStorageManager.setItem(REFRESHED_AFTER_LOGIN, '1');
    window.location.reload();
  } else {
    window.location.href = getNewUri(replaced, current);
  }
}
