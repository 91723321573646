/* eslint-disable */

import type { Maybe, Without } from '@leon-hub/types';
function print (value: unknown): string { return value as string; }

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  Long: number,
};

export enum AccountType {
  MAIN = 'MAIN',
  CBC = 'CBC',
  BETTING = 'BETTING',
  LOCKED = 'LOCKED'
}

export type AchievementEvent = {
  eventType: AchievementEventType,
};

export enum AchievementEventType {
  REWARD_REQUESTED = 'REWARD_REQUESTED',
  REWARD_RECEIVED = 'REWARD_RECEIVED',
  REWARD_REJECTED = 'REWARD_REJECTED',
  REWARD_EXPIRED = 'REWARD_EXPIRED',
  UNKNOWN = 'UNKNOWN'
}

export type AchievementRewardEvent = AchievementEvent & {
  eventType: AchievementEventType,
  rewardId: Scalars['Long'],
};

export type AlcrNotification = FrontNotification & {
  id: Scalars['String'],
  timestamp: Scalars['String'],
  type: FrontNotificationType,
  isDelivered: Scalars['Boolean'],
  delay: Scalars['Int'],
  restrictedRoutes: Array<Scalars['String']>,
  title: Scalars['String'],
  message: Scalars['String'],
  ctaButtonLink?: Maybe<Scalars['String']>,
  ctaButtonText?: Maybe<Scalars['String']>,
  imageUrl?: Maybe<Scalars['String']>,
  displayType: DisplayType,
  iconName?: Maybe<Scalars['String']>,
  buttonAction?: Maybe<ButtonAction>,
};

export type AuditInfo = {
  staffId: Scalars['Long'],
  staffLogin: Scalars['String'],
  performedBy: Scalars['String'],
  ip: Scalars['String'],
};

export type BetCountLimit = {
  amount: Scalars['Float'],
  currency: Scalars['String'],
  count: Scalars['Long'],
  channel: Scalars['String'],
  internalGameId: Scalars['String'],
  walletTypeId: Scalars['Int'],
  freespinTargetWalletTypeId: Scalars['Int'],
};

export enum BetEventType {
  LIVE = 'LIVE',
  PREMATCH = 'PREMATCH',
  ANY = 'ANY',
  UNKNOWN = 'UNKNOWN'
}

export type BetLimit = {
  amount: Scalars['Float'],
  currency: Scalars['String'],
  channel: Scalars['String'],
  internalGameId: Scalars['String'],
  walletTypeId: Scalars['Int'],
  freespinTargetWalletTypeId: Scalars['Int'],
};

export enum BetOperationType {
  BET = 'BET',
  WIN = 'WIN',
  LOSE = 'LOSE',
  ROLLBACK = 'ROLLBACK',
  ROLLBACK_WIN = 'ROLLBACK_WIN'
}

export enum BetType {
  SINGLE = 'SINGLE',
  EXPRESS = 'EXPRESS',
  SYSTEM = 'SYSTEM',
  ANY = 'ANY',
  UNKNOWN = 'UNKNOWN'
}

export type BonusActivated = {
  id: Scalars['Long'],
  campaignId: Scalars['Int'],
  walletTypeId: Scalars['Int'],
  amount: Scalars['Float'],
  amountSystem: Scalars['Float'],
  currencyCode: Scalars['String'],
  depositTransferAmount?: Maybe<Scalars['Float']>,
  bonusType: BonusType,
  createdAt: Scalars['Long'],
};

export type BonusActivationNotification = FrontNotification & {
  id: Scalars['String'],
  timestamp: Scalars['String'],
  type: FrontNotificationType,
  isDelivered: Scalars['Boolean'],
  delay: Scalars['Int'],
  restrictedRoutes: Array<Scalars['String']>,
  depositId?: Maybe<Scalars['Long']>,
  amount?: Maybe<Scalars['Float']>,
  depositAmount?: Maybe<Scalars['Float']>,
  depositTransferAmount?: Maybe<Scalars['Float']>,
  currency?: Maybe<Scalars['String']>,
  actionUrl?: Maybe<Scalars['String']>,
  promoName?: Maybe<Scalars['String']>,
  categoryId?: Maybe<Scalars['String']>,
};

export enum BonusAmountFormula {
  FIXED_AMOUNT = 'FIXED_AMOUNT',
  DEPOSIT_PERCENT = 'DEPOSIT_PERCENT',
  BET_PERCENT = 'BET_PERCENT',
  UNKNOWN = 'UNKNOWN'
}

export type BonusBalance = {
  date: Scalars['Long'],
  availableBalance: Scalars['Float'],
  remainingAmountNumber?: Maybe<Scalars['Float']>,
  progress?: Maybe<Scalars['Float']>,
  progressPercentString?: Maybe<Scalars['String']>,
};

export type BonusCashbackNotification = FrontNotification & {
  id: Scalars['String'],
  timestamp: Scalars['String'],
  type: FrontNotificationType,
  isDelivered: Scalars['Boolean'],
  delay: Scalars['Int'],
  restrictedRoutes: Array<Scalars['String']>,
  message: Scalars['String'],
};

export type BonusClosed = {
  id: Scalars['Long'],
  campaignId: Scalars['Int'],
  withdrawalAmount: Scalars['Float'],
  withdrawalAmountSystem: Scalars['Float'],
  currencyCode: Scalars['String'],
  closeReason: BonusCloseReason,
  createdAt: Scalars['Long'],
};

export enum BonusCloseReason {
  SUCCESSFUL = 'SUCCESSFUL',
  NO_BALANCE = 'NO_BALANCE',
  REFUSED = 'REFUSED',
  EXPIRED = 'EXPIRED',
  FREESPIN_LOST = 'FREESPIN_LOST',
  ERROR = 'ERROR',
  UNKNOWN = 'UNKNOWN'
}

export type BonusCreated = {
  id: Scalars['Long'],
  campaignId: Scalars['Int'],
  walletTypeId: Scalars['Int'],
  currencyCode: Scalars['String'],
  bonusType: BonusType,
  bonusNumber: Scalars['Int'],
  bonusCount: Scalars['Int'],
  betOperationType: BetOperationType,
  bonusFormula: Scalars['String'],
  maxBonusAmount?: Maybe<Scalars['Long']>,
  createdAt: Scalars['Long'],
  bonusRequirements: Array<BonusCreatedRequirement>,
};

export type BonusCreatedRequirement = {
  type: Scalars['String'],
  requiredValue?: Maybe<Scalars['String']>,
  satisfyBefore?: Maybe<Scalars['Long']>,
};

export type BonusEligibilityRules = RefProgramNotificationPayload & {
  type: RefProgramNotificationType,
  currency?: Maybe<Scalars['String']>,
  rewards: Array<ProgramDepositReward>,
  referralRequirements: Array<ProgramRequirement>,
};

export type BonusExpiredCashbackNotification = FrontNotification & {
  id: Scalars['String'],
  timestamp: Scalars['String'],
  type: FrontNotificationType,
  isDelivered: Scalars['Boolean'],
  delay: Scalars['Int'],
  restrictedRoutes: Array<Scalars['String']>,
  message: Scalars['String'],
};

export type BonusGameLastRoundFeedbackNotification = FrontNotification & {
  id: Scalars['String'],
  timestamp: Scalars['String'],
  type: FrontNotificationType,
  isDelivered: Scalars['Boolean'],
  delay: Scalars['Int'],
  restrictedRoutes: Array<Scalars['String']>,
  message: Scalars['String'],
  campaignId: Scalars['Int'],
};

export type BonusGameRewardNotification = FrontNotification & {
  id: Scalars['String'],
  timestamp: Scalars['String'],
  type: FrontNotificationType,
  isDelivered: Scalars['Boolean'],
  delay: Scalars['Int'],
  restrictedRoutes: Array<Scalars['String']>,
  roundId: Scalars['Long'],
  dayNumber: Scalars['Int'],
  additionalRound: Scalars['Boolean'],
  rewardType: RewardType,
  bonusCampaignId: Scalars['Int'],
  bonusAmount?: Maybe<Scalars['Long']>,
  bonusCurrency?: Maybe<Scalars['String']>,
  freespinGameIds?: Maybe<Array<Scalars['String']>>,
  freespinCount?: Maybe<Scalars['Int']>,
  bonusPointMultiplier?: Maybe<Scalars['Float']>,
};

export type BonusGameRound = {
  id: Scalars['Long'],
  startDate: Scalars['Long'],
  endDate: Scalars['Long'],
  additional: Scalars['Boolean'],
};

export type BonusRequirement = {
  id: Scalars['Long'],
  campaignId: Scalars['Int'],
  satisfied: Scalars['Boolean'],
  requirementType: Scalars['String'],
  changeType: BonusRequirementChangeType,
  requiredValue?: Maybe<Scalars['String']>,
  currentValue?: Maybe<Scalars['String']>,
  createdAt?: Maybe<Scalars['String']>,
};

export enum BonusRequirementChangeType {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  UNKNOWN = 'UNKNOWN'
}

export enum BonusType {
  BONUS = 'BONUS',
  FREESPIN = 'FREESPIN',
  UNKNOWN = 'UNKNOWN'
}

export enum ButtonAction {
  CLAIM_CASHBACK = 'CLAIM_CASHBACK',
  CLOSE = 'CLOSE'
}

export type CalculatedCashback = {
  campaignId: Scalars['Long'],
  roundId: Scalars['Long'],
  currency: Scalars['String'],
  amount: Scalars['Float'],
};

export enum CampaignType {
  EGS = 'EGS',
  SPORT = 'SPORT',
  UNKNOWN = 'UNKNOWN'
}

export enum CreatedBonusType {
  BONUS = 'BONUS',
  SIMPLE_FREEBET = 'SIMPLE_FREEBET',
  COMPLEX_FREEBET = 'COMPLEX_FREEBET',
  UNKNOWN = 'UNKNOWN'
}

export type CreatedTicketChatEvent = TicketChatEvent & {
  eventType: TicketChatEventType,
  date: Scalars['String'],
  pipeId: Scalars['Long'],
};

export type CryptoDepositStatusNotification = FrontNotification & {
  id: Scalars['String'],
  timestamp: Scalars['String'],
  type: FrontNotificationType,
  isDelivered: Scalars['Boolean'],
  delay: Scalars['Int'],
  restrictedRoutes: Array<Scalars['String']>,
  status: Scalars['String'],
  cardOrderId?: Maybe<Scalars['Long']>,
  amount?: Maybe<Scalars['Float']>,
  amountStr?: Maybe<Scalars['String']>,
  currency?: Maybe<Scalars['String']>,
  initialTimestamp: Scalars['String'],
};

export type DefaultAchievementEvent = AchievementEvent & {
  eventType: AchievementEventType,
};

export type DefaultFrontNotification = FrontNotification & {
  id: Scalars['String'],
  timestamp: Scalars['String'],
  type: FrontNotificationType,
  isDelivered: Scalars['Boolean'],
  delay: Scalars['Int'],
  restrictedRoutes: Array<Scalars['String']>,
};

export type DefaultTicketChatEvent = TicketChatEvent & {
  eventType: TicketChatEventType,
  date: Scalars['String'],
};

export type Deposit = {
  operationType: DepositOperationType,
  firstDeposit: Scalars['Boolean'],
  paymentSystemAbbrev: Scalars['String'],
  systemAmount: Scalars['Float'],
  paymentId: Scalars['Long'],
};

export type DepositNotification = FrontNotification & {
  id: Scalars['String'],
  timestamp: Scalars['String'],
  type: FrontNotificationType,
  isDelivered: Scalars['Boolean'],
  delay: Scalars['Int'],
  restrictedRoutes: Array<Scalars['String']>,
  operationId: Scalars['String'],
  depositId: Scalars['Long'],
  operationType: DepositOperationType,
  accountId: Scalars['Long'],
  amount?: Maybe<Scalars['Float']>,
  customerAccountTxId: Scalars['Long'],
  currency?: Maybe<Scalars['String']>,
  amountSystem: Scalars['Float'],
  date: Scalars['String'],
  paymentSystemTimestamp?: Maybe<Scalars['String']>,
  paymentSystem: Scalars['String'],
  paymentChannel: Scalars['String'],
  paymentSystemAbbrev: Scalars['String'],
  firstDeposit?: Maybe<Scalars['Boolean']>,
  bonusCode?: Maybe<Scalars['String']>,
  frontendTypeId: Scalars['Int'],
  purse?: Maybe<Scalars['String']>,
  purseType: Scalars['String'],
  cardOrderId?: Maybe<Scalars['Long']>,
  shopId?: Maybe<Scalars['String']>,
  tsupisRequestId?: Maybe<Scalars['String']>,
  auditInfo: AuditInfo,
};

export enum DepositOperationType {
  DEPOSIT = 'DEPOSIT',
  DEPOSIT_CANCEL = 'DEPOSIT_CANCEL'
}

export enum DisplayType {
  PUSH = 'PUSH',
  SILENT = 'SILENT',
  SNACKBAR = 'SNACKBAR'
}

export type FinanceTransaction = {
  date: Scalars['Long'],
  availableBalance: Scalars['Float'],
  walletTypeId: AccountType,
};

export type FomoBonusCreated = FrontNotification & {
  id: Scalars['String'],
  timestamp: Scalars['String'],
  type: FrontNotificationType,
  isDelivered: Scalars['Boolean'],
  delay: Scalars['Int'],
  restrictedRoutes: Array<Scalars['String']>,
  message: Scalars['String'],
  bonusCampaignName?: Maybe<Scalars['String']>,
  bonusCampaignId: Scalars['Int'],
  bonusId: Scalars['Int'],
  categoryId?: Maybe<Scalars['String']>,
  actionUrl?: Maybe<Scalars['String']>,
  terms?: Maybe<Scalars['String']>,
  availableBefore: Scalars['Long'],
  availableBeforeStr: Scalars['String'],
};

export type FreeBetActivated = {
  id: Scalars['Long'],
  campaignId: Scalars['Int'],
  odds: Scalars['Float'],
  createdAt: Scalars['Long'],
};

export type FreeBetCreated = {
  id: Scalars['Long'],
  campaignId: Scalars['Int'],
  amount: Scalars['Float'],
  amountSystem: Scalars['Float'],
  currencyCode: Scalars['String'],
  transferType: FreeBetTransferType,
  expiredAt: Scalars['Long'],
  freebetType: FreeBetType,
  freebetPercent?: Maybe<Scalars['Float']>,
  maxFreebetAmount?: Maybe<Scalars['Float']>,
  reactivated: Scalars['Boolean'],
  createdAt: Scalars['Long'],
};

export type FreeBetResult = {
  id: Scalars['Long'],
  campaignId: Scalars['Int'],
  result: FreeBetResultType,
  createdAt: Scalars['Long'],
  autoTransfer: Scalars['Boolean'],
  transferAmount: Scalars['Float'],
  transferAmountSystem: Scalars['Float'],
};

export enum FreeBetResultType {
  WON = 'WON',
  LOST = 'LOST',
  LOST_EXPIRED = 'LOST_EXPIRED',
  LOST_REFUSED = 'LOST_REFUSED',
  UNKNOWN = 'UNKNOWN'
}

export enum FreeBetTransferType {
  WIN = 'WIN',
  BONUS = 'BONUS',
  WIN_PLUS_BONUS = 'WIN_PLUS_BONUS',
  UNKNOWN = 'UNKNOWN'
}

export enum FreeBetType {
  AMOUNT = 'AMOUNT',
  PERCENT = 'PERCENT',
  FIXED_AMOUNT = 'FIXED_AMOUNT',
  UNKNOWN = 'UNKNOWN'
}

export type FreeSpinCreated = {
  id: Scalars['Long'],
  campaignId: Scalars['Int'],
  externalCampaignId: Scalars['String'],
  provider: Scalars['String'],
  gameIds: Array<Scalars['String']>,
  freespinCount: Scalars['Int'],
  freespinValue?: Maybe<Scalars['Float']>,
  expiredAt: Scalars['Long'],
  createdAt: Scalars['Long'],
};

export type FreeSpinResult = {
  id: Scalars['Long'],
  campaignId: Scalars['Int'],
  result: FreeSpinResultType,
  amount: Scalars['Float'],
  createdAt: Scalars['Long'],
  freespinType: FreeSpinType,
  currencyCode: Scalars['String'],
  freespinId: Scalars['Long'],
  freespinTargetWalletType: Scalars['Int'],
};

export enum FreeSpinResultType {
  WIN = 'WIN',
  LOSE = 'LOSE',
  LOST_REFUSED = 'LOST_REFUSED',
  LOST_EXPIRED = 'LOST_EXPIRED',
  UNKNOWN = 'UNKNOWN'
}

export enum FreeSpinType {
  FLEXIBLE = 'FLEXIBLE',
  PROVIDER = 'PROVIDER',
  UNKNOWN = 'UNKNOWN'
}

export type FreeSpinUpdated = {
  id: Scalars['Long'],
  campaignId: Scalars['Int'],
  freespinCount: Scalars['Int'],
  createdAt: Scalars['Long'],
};

export type FrontNotification = {
  id: Scalars['String'],
  timestamp: Scalars['String'],
  type: FrontNotificationType,
  isDelivered: Scalars['Boolean'],
  delay: Scalars['Int'],
  restrictedRoutes: Array<Scalars['String']>,
};

export enum FrontNotificationType {
  CBC_BONUS_ACTIVATED = 'CBC_BONUS_ACTIVATED',
  ALCR_NOTIFICATION = 'ALCR_NOTIFICATION',
  VIDEO_VERIFICATION = 'VIDEO_VERIFICATION',
  ON_SITE = 'ON_SITE',
  REFPRG = 'REFPRG',
  UNKNOWN = 'UNKNOWN',
  PLAY_TIME_ENDED = 'PLAY_TIME_ENDED',
  BONUS_GAME_REWARD = 'BONUS_GAME_REWARD',
  BONUS_GAME_LAST_ROUND_FEEDBACK = 'BONUS_GAME_LAST_ROUND_FEEDBACK',
  BONUS_EXPIRED_CASHBACK = 'BONUS_EXPIRED_CASHBACK',
  BONUS_CASHBACK = 'BONUS_CASHBACK',
  SUSPENDED_CASHBACK = 'SUSPENDED_CASHBACK',
  RESUMED_CASHBACK = 'RESUMED_CASHBACK',
  CRYPTO_DEPOSIT_STATUS = 'CRYPTO_DEPOSIT_STATUS',
  BS_DEPOSIT = 'BS_DEPOSIT',
  FOMO_BONUS_CREATED = 'FOMO_BONUS_CREATED'
}

export enum IdentificationLevel {
  NONE = 'NONE',
  SIMPLE = 'SIMPLE',
  FULL = 'FULL',
  UNKNOWN = 'UNKNOWN'
}

export enum InternalSportBonusType {
  BONUS = 'BONUS',
  FREEBET = 'FREEBET',
  UNKNOWN = 'UNKNOWN'
}

export type LastWinEvent = {
  amount: Scalars['Float'],
};

export type LeonShopOffer = {
  offerId: Scalars['Long'],
  offerStatus: OfferStatus,
  createdAt: Scalars['Long'],
  imageUrl: Scalars['String'],
  names: Array<StringEntry>,
  descriptions: Array<StringEntry>,
  price: Scalars['Float'],
  campaignId?: Maybe<Scalars['Int']>,
  campaignType?: Maybe<CampaignType>,
  deleted: Scalars['Boolean'],
};

export type LeonShopOrder = {
  orderId: Scalars['Long'],
  orderStatus: OrderStatus,
  createdAt: Scalars['Long'],
  bonusId?: Maybe<Scalars['Long']>,
  offerId: Scalars['Long'],
  campaignId: Scalars['Int'],
  campaignType: CampaignType,
  price: Scalars['Float'],
};


export type LoyaltyCustomerLevel = {
  type?: Maybe<LoyaltyCustomerLevelType>,
  currentPoints: Scalars['Float'],
  pointsUntilNextLevel?: Maybe<Scalars['Float']>,
  multiplier: Scalars['Float'],
  currentLevelId: Scalars['Int'],
};

export enum LoyaltyCustomerLevelType {
  BALANCE_CHANGE = 'BALANCE_CHANGE',
  CUSTOMER_LEVEL_UPGRADE = 'CUSTOMER_LEVEL_UPGRADE',
  DEFAULT_LEVEL_INIT = 'DEFAULT_LEVEL_INIT',
  CUSTOMER_LEVEL_RECALCULATE = 'CUSTOMER_LEVEL_RECALCULATE',
  UNKNOWN = 'UNKNOWN'
}

export enum OfferStatus {
  UPCOMING = 'UPCOMING',
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
  UNKNOWN = 'UNKNOWN'
}

export enum OrderStatus {
  CREATED = 'CREATED',
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
  CANCELLED = 'CANCELLED',
  UNKNOWN = 'UNKNOWN'
}

export type PlayTimeTimerEndedNotification = FrontNotification & {
  id: Scalars['String'],
  timestamp: Scalars['String'],
  type: FrontNotificationType,
  isDelivered: Scalars['Boolean'],
  delay: Scalars['Int'],
  restrictedRoutes: Array<Scalars['String']>,
  hours: Scalars['Int'],
  minutes: Scalars['Int'],
  overallProfit?: Maybe<Scalars['Float']>,
  currency?: Maybe<Scalars['String']>,
};

export type ProgramAvailable = RefProgramNotificationPayload & {
  type: RefProgramNotificationType,
  referrerId: Scalars['Long'],
  programId: Scalars['Int'],
};

export type ProgramDepositReward = {
  depositAmount: Scalars['Float'],
  rewardAmount: ProgramRewardAmount,
};

export type ProgramRequirement = {
  type: ProgramRequirementType,
  satisfied: Scalars['Boolean'],
  requiredValue?: Maybe<Scalars['Float']>,
  currency: Scalars['String'],
};

export enum ProgramRequirementType {
  REGISTRATION = 'REGISTRATION',
  DEPOSIT = 'DEPOSIT',
  BET = 'BET',
  CONFIRMATION = 'CONFIRMATION',
  IDENTIFICATION = 'IDENTIFICATION'
}

export type ProgramReward = {
  count: Scalars['Int'],
  nominalValue?: Maybe<Scalars['Float']>,
  amount: Scalars['Float'],
  rewardType?: Maybe<ProgramRewardType>,
};

export type ProgramRewardAmount = {
  playerReward: Array<ProgramReward>,
  referralReward: Array<ProgramReward>,
};

export enum ProgramRewardType {
  FREESPIN = 'FREESPIN',
  FREEBET = 'FREEBET'
}

export type ProgramUnavailable = RefProgramNotificationPayload & {
  type: RefProgramNotificationType,
  referrerId: Scalars['Long'],
  available: Scalars['Boolean'],
};

export type ReferralRequirementSatisfied = RefProgramNotificationPayload & {
  type: RefProgramNotificationType,
  referrerId: Scalars['Long'],
  referralId: Scalars['Long'],
  satisfiedRequirement: ProgramRequirement,
  programId: Scalars['Int'],
};

export type ReferralRewardAssigned = RefProgramNotificationPayload & {
  type: RefProgramNotificationType,
  referrerId: Scalars['Long'],
  referralId: Scalars['Long'],
  programId: Scalars['Int'],
  reward: ProgramReward,
};

export type ReferralRewardAssignedReferrerNotificationDto = RefProgramNotificationPayload & {
  type: RefProgramNotificationType,
  referrerId: Scalars['Long'],
  referralId: Scalars['Long'],
  programId: Scalars['Int'],
  reward: ProgramReward,
};

export type ReferrerRequirementSatisfied = RefProgramNotificationPayload & {
  type: RefProgramNotificationType,
  referrerId: Scalars['Long'],
  satisfiedRequirement: ProgramRequirement,
  programId: Scalars['Int'],
};

export type ReferrerRewardAssigned = RefProgramNotificationPayload & {
  type: RefProgramNotificationType,
  referrerId: Scalars['Long'],
  programId: Scalars['Int'],
  reward: ProgramReward,
};

export type RefProgramNotification = FrontNotification & {
  id: Scalars['String'],
  timestamp: Scalars['String'],
  type: FrontNotificationType,
  isDelivered: Scalars['Boolean'],
  delay: Scalars['Int'],
  restrictedRoutes: Array<Scalars['String']>,
  payload: RefProgramNotificationPayload,
};

export type RefProgramNotificationPayload = {
  type: RefProgramNotificationType,
};

export enum RefProgramNotificationType {
  PROGRAM_AVAILABLE = 'PROGRAM_AVAILABLE',
  PROGRAM_UNAVAILABLE = 'PROGRAM_UNAVAILABLE',
  REFERRER_REQUIREMENT_SATISFIED = 'REFERRER_REQUIREMENT_SATISFIED',
  REFERRER_REWARD_ASSIGNED = 'REFERRER_REWARD_ASSIGNED',
  REFERRAL_REQUIREMENT_SATISFIED = 'REFERRAL_REQUIREMENT_SATISFIED',
  REFERRAL_REWARD_ASSIGNED = 'REFERRAL_REWARD_ASSIGNED',
  REFERRAL_REWARD_ASSIGNED_REFERRER_NOTIFICATION = 'REFERRAL_REWARD_ASSIGNED_REFERRER_NOTIFICATION',
  BONUS_ELIGIBILITY_RULES = 'BONUS_ELIGIBILITY_RULES'
}

export type RefusedCashback = {
  campaignId: Scalars['Long'],
  roundId: Scalars['Long'],
};

export type ResumedCashbackNotification = FrontNotification & {
  id: Scalars['String'],
  timestamp: Scalars['String'],
  type: FrontNotificationType,
  isDelivered: Scalars['Boolean'],
  delay: Scalars['Int'],
  restrictedRoutes: Array<Scalars['String']>,
  message: Scalars['String'],
};

export enum RewardType {
  FREEBET = 'FREEBET',
  FREESPIN = 'FREESPIN',
  BONUS = 'BONUS',
  MULTIPLIER = 'MULTIPLIER',
  SUPER_PRIZE = 'SUPER_PRIZE',
  EMPTY = 'EMPTY'
}

export type RootQuery = {
  test: Scalars['String'],
};

export type RootSubscription = {
  test: Scalars['String'],
  testFetchingErrors: Scalars['String'],
  testYes: Scalars['String'],
  testYes2: Scalars['String'],
  onBalanceChange: FinanceTransaction,
  onWagerCreated: WagerCreated,
  onWagerUpdated: WagerUpdated,
  onWagerResult: WagerResult,
  onBonusCreated: BonusCreated,
  onBonusRequirement: BonusRequirement,
  onBonusActivated: BonusActivated,
  onBonusClosed: BonusClosed,
  onFreeSpinCreated: FreeSpinCreated,
  onFreeSpinUpdated: FreeSpinUpdated,
  onFreeSpinResult: FreeSpinResult,
  onSportBonusCreated: SportBonusCreated,
  onSportBonusRequirement: SportBonusRequirement,
  onSportBonusRequirementChange: SportBonusRequirementChange,
  onSportBonusActivated: SportBonusActivated,
  onSportBonusClosed: SportBonusClosed,
  onSportBonusWagerCreated: SportBonusWagerCreated,
  onSportBonusWagerUpdated: SportBonusWagerUpdated,
  onSportBonusWagerResult: SportBonusWagerResult,
  onFreeBetCreated: FreeBetCreated,
  onFreeBetActivated: FreeBetActivated,
  onFreeBetResult: FreeBetResult,
  onBonusBalanceChange: BonusBalance,
  onDeposit: Deposit,
  onLoyaltyCustomerLevel: LoyaltyCustomerLevel,
  onLeonShopOrder: LeonShopOrder,
  onLeonShopOffer: LeonShopOffer,
  onFrontNotification: FrontNotification,
  onTicketChatEvent: TicketChatEvent,
  onUpdatedCashback: UpdatedCashback,
  onCalculatedCashback: CalculatedCashback,
  onRefusedCashback: RefusedCashback,
  onBonusGameRoundEvent: BonusGameRound,
  onFfsBetLimit: BetLimit,
  onFfsBetCountLimit: BetCountLimit,
  onLastWinEvent: LastWinEvent,
  onAchievementEvent: AchievementEvent,
};

export type SportBonusActivated = {
  id: Scalars['Long'],
  bonusType: SportBonusActivatedType,
  campaignId: Scalars['Int'],
  bonusAmount: Scalars['Float'],
  bonusAmountSystem: Scalars['Float'],
  bonusNumber: Scalars['Int'],
  bonusCount: Scalars['Int'],
  currencyCode: Scalars['String'],
  createdAt: Scalars['Long'],
};

export enum SportBonusActivatedType {
  BONUS = 'BONUS',
  SIMPLE_FREEBET = 'SIMPLE_FREEBET',
  COMPLEX_FREEBET = 'COMPLEX_FREEBET',
  UNKNOWN = 'UNKNOWN'
}

export type SportBonusClosed = {
  id: Scalars['Long'],
  campaignId: Scalars['Int'],
  withdrawalAmount: Scalars['Float'],
  withdrawalAmountSystem: Scalars['Float'],
  currencyCode: Scalars['String'],
  closeReason: SportBonusCloseReason,
  createdAt: Scalars['Long'],
};

export enum SportBonusCloseReason {
  SUCCESSFUL = 'SUCCESSFUL',
  REFUSED = 'REFUSED',
  EXPIRED = 'EXPIRED',
  ERROR = 'ERROR',
  DELETED = 'DELETED',
  UNKNOWN = 'UNKNOWN'
}

export enum SportBonusConfirmationType {
  SIMPLE = 'SIMPLE',
  BONUS_CODE = 'BONUS_CODE',
  UNKNOWN = 'UNKNOWN'
}

export type SportBonusCreated = {
  id: Scalars['Long'],
  campaignId: Scalars['Int'],
  bonusType: CreatedBonusType,
  internalBonusType?: Maybe<InternalSportBonusType>,
  bonusFormula?: Maybe<Scalars['String']>,
  amountFormula?: Maybe<BonusAmountFormula>,
  bonusNumber: Scalars['Int'],
  bonusCount: Scalars['Int'],
  currencyCode: Scalars['String'],
  createdAt: Scalars['Long'],
  wagerRequired: Scalars['Boolean'],
  bonusRequirements: Array<BonusCreatedRequirement>,
};

export enum SportBonusOperationType {
  PLACE = 'PLACE',
  VOID = 'VOID',
  CANCEL_VOID = 'CANCEL_VOID',
  WIN = 'WIN',
  CANCEL_WIN = 'CANCEL_WIN',
  LOSE = 'LOSE',
  CANCEL_LOSS = 'CANCEL_LOSS',
  CASH_OUT = 'CASH_OUT',
  CANCEL_CASH_OUT = 'CANCEL_CASH_OUT',
  DEPOSIT = 'DEPOSIT',
  DEPOSIT_CANCEL = 'DEPOSIT_CANCEL',
  UNKNOWN = 'UNKNOWN'
}

export type SportBonusRequirement = {
  id: Scalars['Long'],
  campaignId: Scalars['Int'],
  satisfied: Scalars['Boolean'],
  requirementType: SportBonusRequirementType,
  confirmationType?: Maybe<SportBonusConfirmationType>,
  bonusCode?: Maybe<Scalars['String']>,
  amount?: Maybe<Scalars['Float']>,
  currencyCode?: Maybe<Scalars['String']>,
  currentValue?: Maybe<Scalars['String']>,
  operationType?: Maybe<SportBonusOperationType>,
  firstDeposit?: Maybe<Scalars['Boolean']>,
  betId?: Maybe<Scalars['Long']>,
  operationOdds?: Maybe<Scalars['Float']>,
  betType?: Maybe<BetType>,
  betEventType?: Maybe<BetEventType>,
  identificationLevel?: Maybe<IdentificationLevel>,
  createdAt: Scalars['Long'],
  manual: Scalars['Boolean'],
};

export type SportBonusRequirementChange = {
  id: Scalars['Long'],
  campaignId: Scalars['Int'],
  operation: SportBonusRequirementChangeOperationType,
  requirementType: SportBonusRequirementType,
  requiredValue?: Maybe<Scalars['String']>,
  currentValue?: Maybe<Scalars['String']>,
  createdAt: Scalars['Long'],
};

export enum SportBonusRequirementChangeOperationType {
  ADDED = 'ADDED',
  DELETED = 'DELETED',
  UNKNOWN = 'UNKNOWN'
}

export enum SportBonusRequirementType {
  DEPOSIT = 'DEPOSIT',
  BET_COUNT = 'BET_COUNT',
  REGISTRATION = 'REGISTRATION',
  IDENTIFICATION = 'IDENTIFICATION',
  CONFIRMATION = 'CONFIRMATION',
  INSTANT_BONUS_CREATE = 'INSTANT_BONUS_CREATE',
  START_CAMPAIGN = 'START_CAMPAIGN',
  BONUS_CODE_CONFIRMATION = 'BONUS_CODE_CONFIRMATION',
  PARTICIPANT_ADDED = 'PARTICIPANT_ADDED',
  UNKNOWN = 'UNKNOWN'
}

export type SportBonusWagerCreated = {
  id: Scalars['Long'],
  campaignId: Scalars['Int'],
  requiredWagerBetCount?: Maybe<Scalars['Int']>,
  requiredWagerDepositSum?: Maybe<Scalars['Float']>,
  requiredWagerAmount?: Maybe<Scalars['Float']>,
  wagerExpiredAt: Scalars['Long'],
  currencyCode: Scalars['String'],
  createdAt: Scalars['Long'],
};

export type SportBonusWagerResult = {
  id: Scalars['Long'],
  campaignId: Scalars['Int'],
  wagerResult: SportBonusWagerResultType,
  createdAt: Scalars['Long'],
  autoTransfer: Scalars['Boolean'],
};

export enum SportBonusWagerResultType {
  SUCCESSFUL = 'SUCCESSFUL',
  CANCELED_EXPIRED = 'CANCELED_EXPIRED',
  CANCELED_BY_ERROR = 'CANCELED_BY_ERROR',
  CANCELED_REFUSED = 'CANCELED_REFUSED',
  UNKNOWN = 'UNKNOWN'
}

export type SportBonusWagerUpdated = {
  id: Scalars['Long'],
  campaignId: Scalars['Int'],
  currentWagerBetCount?: Maybe<Scalars['Int']>,
  currentWagerDepositSum?: Maybe<Scalars['Float']>,
  currentWagerAmount?: Maybe<Scalars['Float']>,
  currentWagerAmountSystem?: Maybe<Scalars['Float']>,
  currencyCode: Scalars['String'],
  betId?: Maybe<Scalars['Long']>,
  operationType?: Maybe<SportBonusOperationType>,
  createdAt: Scalars['Long'],
};

export type StringEntry = {
  key?: Maybe<Scalars['String']>,
  value?: Maybe<Scalars['String']>,
};

export type SuspendedCashbackNotification = FrontNotification & {
  id: Scalars['String'],
  timestamp: Scalars['String'],
  type: FrontNotificationType,
  isDelivered: Scalars['Boolean'],
  delay: Scalars['Int'],
  restrictedRoutes: Array<Scalars['String']>,
  message: Scalars['String'],
};

export type TicketChatEvent = {
  eventType: TicketChatEventType,
  date: Scalars['String'],
};

export enum TicketChatEventType {
  CREATE = 'CREATE',
  UNKNOWN = 'UNKNOWN'
}

export type UpdatedCashback = {
  campaignId: Scalars['Long'],
  roundId: Scalars['Long'],
  currency: Scalars['String'],
  amount: Scalars['Float'],
};

export type VideoVerificationNotification = FrontNotification & {
  id: Scalars['String'],
  timestamp: Scalars['String'],
  type: FrontNotificationType,
  isDelivered: Scalars['Boolean'],
  delay: Scalars['Int'],
  restrictedRoutes: Array<Scalars['String']>,
  status: VideoVerificationStatus,
  message: Scalars['String'],
};

export enum VideoVerificationStatus {
  NONE = 'NONE',
  PENDING = 'PENDING',
  IGNORED = 'IGNORED',
  INVITED = 'INVITED',
  NO_ANSWER = 'NO_ANSWER',
  SCHEDULED = 'SCHEDULED',
  ON_REVIEW = 'ON_REVIEW',
  PASSED = 'PASSED',
  NOT_PASSED = 'NOT_PASSED',
  REFUSED = 'REFUSED',
  MISSED = 'MISSED',
  ACCEPT_IGNORE = 'ACCEPT_IGNORE',
  ACCEPT_REFUSAL = 'ACCEPT_REFUSAL',
  UNKNOWN = 'UNKNOWN'
}

export type WagerCreated = {
  id: Scalars['Long'],
  campaignId: Scalars['Int'],
  requiredWagerAmount: Scalars['Float'],
  maxBonusReleaseLimit: Scalars['Float'],
  wagerExpiredAt: Scalars['Long'],
  wagerSourceWalletType: WalletType,
  createdAt: Scalars['Long'],
};

export type WagerResult = {
  id: Scalars['Long'],
  campaignId: Scalars['Int'],
  resultType: WagerResultType,
  createdAt: Scalars['Long'],
};

export enum WagerResultType {
  COMPLETED = 'COMPLETED',
  REFUSED = 'REFUSED',
  EMPTY_BALANCE = 'EMPTY_BALANCE',
  EXPIRED = 'EXPIRED'
}

export type WagerUpdated = {
  id: Scalars['Long'],
  campaignId: Scalars['Int'],
  currentWagerAmount: Scalars['Float'],
  currencyCode: Scalars['String'],
  createdAt: Scalars['Long'],
};

export enum WalletType {
  MAIN = 'MAIN',
  CBC = 'CBC'
}

export type onAchievementEventSubscriptionVariables = {};


export type onAchievementEventSubscription = { onAchievementEvent: Pick<AchievementRewardEvent, 'rewardId' | 'eventType'> | Pick<DefaultAchievementEvent, 'eventType'> };

export type onBalanceChangeSubscriptionVariables = {};


export type onBalanceChangeSubscription = { onBalanceChange: Pick<FinanceTransaction, 'date' | 'walletTypeId' | 'availableBalance'> };

export type onBonusBalanceChangeSubscriptionVariables = {};


export type onBonusBalanceChangeSubscription = { onBonusBalanceChange: Pick<BonusBalance, 'date' | 'availableBalance' | 'remainingAmountNumber' | 'progress' | 'progressPercentString'> };

export type onBonusGameRoundEventSubscriptionVariables = {};


export type onBonusGameRoundEventSubscription = { onBonusGameRoundEvent: Pick<BonusGameRound, 'startDate' | 'endDate' | 'id' | 'additional'> };

export type onCalculatedCashbackSubscriptionVariables = {};


export type onCalculatedCashbackSubscription = { onCalculatedCashback: Pick<CalculatedCashback, 'campaignId' | 'currency' | 'amount' | 'roundId'> };

export type onDepositSubscriptionVariables = {};


export type onDepositSubscription = { onDeposit: Pick<Deposit, 'firstDeposit' | 'operationType' | 'paymentId' | 'paymentSystemAbbrev' | 'systemAmount'> };

export type onFfsBetCountLimitSubscriptionVariables = {};


export type onFfsBetCountLimitSubscription = { onFfsBetCountLimit: Pick<BetCountLimit, 'amount' | 'currency' | 'channel' | 'internalGameId' | 'walletTypeId' | 'freespinTargetWalletTypeId'> };

export type onFfsBetLimitSubscriptionVariables = {};


export type onFfsBetLimitSubscription = { onFfsBetLimit: Pick<BetLimit, 'amount' | 'currency' | 'channel' | 'internalGameId' | 'walletTypeId' | 'freespinTargetWalletTypeId'> };

export type onFreeSpinResultSubscriptionVariables = {};


export type onFreeSpinResultSubscription = { onFreeSpinResult: Pick<FreeSpinResult, 'id' | 'campaignId' | 'result' | 'amount' | 'createdAt' | 'freespinType' | 'freespinId' | 'freespinTargetWalletType'> };

export type onFrontNotificationSubscriptionVariables = {};


export type onFrontNotificationSubscription = { onFrontNotification: Pick<PlayTimeTimerEndedNotification, 'hours' | 'minutes' | 'overallProfit' | 'currency' | 'id' | 'type' | 'timestamp' | 'isDelivered' | 'delay' | 'restrictedRoutes'> | Pick<FomoBonusCreated, 'message' | 'bonusCampaignName' | 'bonusCampaignId' | 'bonusId' | 'categoryId' | 'actionUrl' | 'terms' | 'availableBefore' | 'availableBeforeStr' | 'id' | 'type' | 'timestamp' | 'isDelivered' | 'delay' | 'restrictedRoutes'> | Pick<BonusActivationNotification, 'amount' | 'depositAmount' | 'depositTransferAmount' | 'currency' | 'actionUrl' | 'categoryId' | 'promoName' | 'id' | 'type' | 'timestamp' | 'isDelivered' | 'delay' | 'restrictedRoutes'> | Pick<DefaultFrontNotification, 'id' | 'type' | 'timestamp' | 'isDelivered' | 'delay' | 'restrictedRoutes'> | Pick<AlcrNotification, 'title' | 'message' | 'ctaButtonLink' | 'ctaButtonText' | 'imageUrl' | 'displayType' | 'iconName' | 'buttonAction' | 'id' | 'type' | 'timestamp' | 'isDelivered' | 'delay' | 'restrictedRoutes'> | (
    Pick<RefProgramNotification, 'id' | 'type' | 'timestamp' | 'isDelivered' | 'delay' | 'restrictedRoutes'>
    & { payload: Pick<ProgramAvailable, 'programId' | 'referrerId' | 'type'> | Pick<ProgramUnavailable, 'type'> | Pick<ReferralRequirementSatisfied, 'type'> | (
      Pick<ReferralRewardAssigned, 'referrerId' | 'referralId' | 'programId' | 'type'>
      & { reward: Pick<ProgramReward, 'count' | 'nominalValue' | 'amount' | 'rewardType'> }
    ) | (
      Pick<ReferralRewardAssignedReferrerNotificationDto, 'programId' | 'type'>
      & { reward: Pick<ProgramReward, 'count' | 'nominalValue' | 'amount' | 'rewardType'> }
    ) | Pick<ReferrerRequirementSatisfied, 'type'> | Pick<ReferrerRewardAssigned, 'type'> | (
      Pick<BonusEligibilityRules, 'currency' | 'type'>
      & { rewards: Array<(
        Pick<ProgramDepositReward, 'depositAmount'>
        & { rewardAmount: { playerReward: Array<Pick<ProgramReward, 'count' | 'nominalValue' | 'amount' | 'rewardType'>>, referralReward: Array<Pick<ProgramReward, 'count' | 'nominalValue' | 'amount' | 'rewardType'>> } }
      )>, referralRequirements: Array<Pick<ProgramRequirement, 'type' | 'satisfied' | 'requiredValue' | 'currency'>> }
    ) }
  ) | Pick<BonusGameRewardNotification, 'id' | 'type' | 'timestamp' | 'isDelivered' | 'delay' | 'restrictedRoutes'> | Pick<BonusGameLastRoundFeedbackNotification, 'id' | 'type' | 'timestamp' | 'isDelivered' | 'delay' | 'restrictedRoutes'> | Pick<BonusExpiredCashbackNotification, 'id' | 'type' | 'timestamp' | 'isDelivered' | 'delay' | 'restrictedRoutes'> | Pick<BonusCashbackNotification, 'id' | 'type' | 'timestamp' | 'isDelivered' | 'delay' | 'restrictedRoutes'> | Pick<SuspendedCashbackNotification, 'id' | 'type' | 'timestamp' | 'isDelivered' | 'delay' | 'restrictedRoutes'> | Pick<ResumedCashbackNotification, 'id' | 'type' | 'timestamp' | 'isDelivered' | 'delay' | 'restrictedRoutes'> | Pick<CryptoDepositStatusNotification, 'amountStr' | 'currency' | 'initialTimestamp' | 'delay' | 'id' | 'type' | 'timestamp' | 'isDelivered' | 'restrictedRoutes'> | Pick<DepositNotification, 'amount' | 'currency' | 'date' | 'delay' | 'id' | 'type' | 'timestamp' | 'isDelivered' | 'restrictedRoutes'> | Pick<VideoVerificationNotification, 'status' | 'message' | 'id' | 'type' | 'timestamp' | 'isDelivered' | 'delay' | 'restrictedRoutes'> };

export type onLoyaltyCustomerLevelSubscriptionVariables = {};


export type onLoyaltyCustomerLevelSubscription = { onLoyaltyCustomerLevel: Pick<LoyaltyCustomerLevel, 'type' | 'currentLevelId' | 'currentPoints' | 'multiplier' | 'pointsUntilNextLevel'> };

export type onRefusedCashbackSubscriptionVariables = {};


export type onRefusedCashbackSubscription = { onRefusedCashback: Pick<RefusedCashback, 'campaignId' | 'roundId'> };

export type onTicketChatEventSubscriptionVariables = {};


export type onTicketChatEventSubscription = { onTicketChatEvent: Pick<CreatedTicketChatEvent, 'eventType' | 'date'> | Pick<DefaultTicketChatEvent, 'eventType' | 'date'> };

export type onUpdatedCashbackSubscriptionVariables = {};


export type onUpdatedCashbackSubscription = { onUpdatedCashback: Pick<UpdatedCashback, 'campaignId' | 'amount' | 'currency' | 'roundId'> };

export type onWagerCreatedSubscriptionVariables = {};


export type onWagerCreatedSubscription = { onWagerCreated: Pick<WagerCreated, 'wagerSourceWalletType'> };

export type onWagerResultSubscriptionVariables = {};


export type onWagerResultSubscription = { onWagerResult: Pick<WagerResult, 'resultType'> };

export type onWagerUpdatedSubscriptionVariables = {};


export type onWagerUpdatedSubscription = { onWagerUpdated: Pick<WagerUpdated, 'currentWagerAmount' | 'currencyCode' | 'campaignId'> };


export const onAchievementEventDocument = `
    subscription onAchievementEvent {
  onAchievementEvent {
    eventType
    ... on AchievementRewardEvent {
      rewardId
    }
  }
}
    `;
export const onBalanceChangeDocument = `
    subscription onBalanceChange {
  onBalanceChange {
    date
    walletTypeId
    availableBalance
  }
}
    `;
export const onBonusBalanceChangeDocument = `
    subscription onBonusBalanceChange {
  onBonusBalanceChange {
    date
    availableBalance
    remainingAmountNumber
    progress
    progressPercentString
  }
}
    `;
export const onBonusGameRoundEventDocument = `
    subscription onBonusGameRoundEvent {
  onBonusGameRoundEvent {
    startDate
    endDate
    id
    additional
  }
}
    `;
export const onCalculatedCashbackDocument = `
    subscription onCalculatedCashback {
  onCalculatedCashback {
    campaignId
    currency
    amount
    roundId
  }
}
    `;
export const onDepositDocument = `
    subscription onDeposit {
  onDeposit {
    firstDeposit
    operationType
    paymentId
    paymentSystemAbbrev
    systemAmount
  }
}
    `;
export const onFfsBetCountLimitDocument = `
    subscription onFfsBetCountLimit {
  onFfsBetCountLimit {
    amount
    currency
    channel
    internalGameId
    walletTypeId
    freespinTargetWalletTypeId
  }
}
    `;
export const onFfsBetLimitDocument = `
    subscription onFfsBetLimit {
  onFfsBetLimit {
    amount
    currency
    channel
    internalGameId
    walletTypeId
    freespinTargetWalletTypeId
  }
}
    `;
export const onFreeSpinResultDocument = `
    subscription onFreeSpinResult {
  onFreeSpinResult {
    id
    campaignId
    result
    amount
    createdAt
    freespinType
    freespinId
    freespinTargetWalletType
  }
}
    `;
export const onFrontNotificationDocument = `
    subscription onFrontNotification {
  onFrontNotification {
    id
    type
    timestamp
    isDelivered
    delay
    restrictedRoutes
    ... on BonusActivationNotification {
      amount
      depositAmount
      depositTransferAmount
      currency
      actionUrl
      categoryId
      promoName
    }
    ... on AlcrNotification {
      title
      message
      ctaButtonLink
      ctaButtonText
      imageUrl
      displayType
      iconName
      buttonAction
    }
    ... on VideoVerificationNotification {
      status
      message
    }
    ... on RefProgramNotification {
      payload {
        type
        ... on ProgramAvailable {
          programId
          referrerId
        }
        ... on BonusEligibilityRules {
          currency
          rewards {
            depositAmount
            rewardAmount {
              playerReward {
                count
                nominalValue
                amount
                rewardType
              }
              referralReward {
                count
                nominalValue
                amount
                rewardType
              }
            }
          }
          referralRequirements {
            type
            satisfied
            requiredValue
            currency
          }
        }
        ... on ReferralRewardAssigned {
          referrerId
          referralId
          programId
          reward {
            count
            nominalValue
            amount
            rewardType
          }
        }
        ... on ReferralRewardAssignedReferrerNotificationDto {
          programId
          reward {
            count
            nominalValue
            amount
            rewardType
          }
        }
      }
    }
    ... on PlayTimeTimerEndedNotification {
      hours
      minutes
      overallProfit
      currency
    }
    ... on CryptoDepositStatusNotification {
      amountStr
      currency
      initialTimestamp
      delay
    }
    ... on DepositNotification {
      amount
      currency
      date
      delay
    }
    ... on FomoBonusCreated {
      message
      bonusCampaignName
      bonusCampaignId
      bonusId
      categoryId
      actionUrl
      terms
      availableBefore
      availableBeforeStr
    }
  }
}
    `;
export const onLoyaltyCustomerLevelDocument = `
    subscription onLoyaltyCustomerLevel {
  onLoyaltyCustomerLevel {
    type
    currentLevelId
    currentPoints
    multiplier
    pointsUntilNextLevel
  }
}
    `;
export const onRefusedCashbackDocument = `
    subscription onRefusedCashback {
  onRefusedCashback {
    campaignId
    roundId
  }
}
    `;
export const onTicketChatEventDocument = `
    subscription onTicketChatEvent {
  onTicketChatEvent {
    eventType
    date
  }
}
    `;
export const onUpdatedCashbackDocument = `
    subscription onUpdatedCashback {
  onUpdatedCashback {
    campaignId
    amount
    currency
    roundId
  }
}
    `;
export const onWagerCreatedDocument = `
    subscription onWagerCreated {
  onWagerCreated {
    wagerSourceWalletType
  }
}
    `;
export const onWagerResultDocument = `
    subscription onWagerResult {
  onWagerResult {
    resultType
  }
}
    `;
export const onWagerUpdatedDocument = `
    subscription onWagerUpdated {
  onWagerUpdated {
    currentWagerAmount
    currencyCode
    campaignId
  }
}
    `;
export function getWsSdk() {
  return {
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
  };
}

type WebSocketSdkMethod<MESSAGE, VARIABLES> = {
    variables?: VARIABLES,
    onMessage: (message: MESSAGE) => void;
}

export type WebSocketSdk = {
  onAchievementEvent: WebSocketSdkMethod<onAchievementEventSubscription, onAchievementEventSubscriptionVariables>,
onBalanceChange: WebSocketSdkMethod<onBalanceChangeSubscription, onBalanceChangeSubscriptionVariables>,
onBonusBalanceChange: WebSocketSdkMethod<onBonusBalanceChangeSubscription, onBonusBalanceChangeSubscriptionVariables>,
onBonusGameRoundEvent: WebSocketSdkMethod<onBonusGameRoundEventSubscription, onBonusGameRoundEventSubscriptionVariables>,
onCalculatedCashback: WebSocketSdkMethod<onCalculatedCashbackSubscription, onCalculatedCashbackSubscriptionVariables>,
onDeposit: WebSocketSdkMethod<onDepositSubscription, onDepositSubscriptionVariables>,
onFfsBetCountLimit: WebSocketSdkMethod<onFfsBetCountLimitSubscription, onFfsBetCountLimitSubscriptionVariables>,
onFfsBetLimit: WebSocketSdkMethod<onFfsBetLimitSubscription, onFfsBetLimitSubscriptionVariables>,
onFreeSpinResult: WebSocketSdkMethod<onFreeSpinResultSubscription, onFreeSpinResultSubscriptionVariables>,
onFrontNotification: WebSocketSdkMethod<onFrontNotificationSubscription, onFrontNotificationSubscriptionVariables>,
onLoyaltyCustomerLevel: WebSocketSdkMethod<onLoyaltyCustomerLevelSubscription, onLoyaltyCustomerLevelSubscriptionVariables>,
onRefusedCashback: WebSocketSdkMethod<onRefusedCashbackSubscription, onRefusedCashbackSubscriptionVariables>,
onTicketChatEvent: WebSocketSdkMethod<onTicketChatEventSubscription, onTicketChatEventSubscriptionVariables>,
onUpdatedCashback: WebSocketSdkMethod<onUpdatedCashbackSubscription, onUpdatedCashbackSubscriptionVariables>,
onWagerCreated: WebSocketSdkMethod<onWagerCreatedSubscription, onWagerCreatedSubscriptionVariables>,
onWagerResult: WebSocketSdkMethod<onWagerResultSubscription, onWagerResultSubscriptionVariables>,
onWagerUpdated: WebSocketSdkMethod<onWagerUpdatedSubscription, onWagerUpdatedSubscriptionVariables>,

};

export const webSocketDocuments = {
  onAchievementEvent: print(onAchievementEventDocument),
onBalanceChange: print(onBalanceChangeDocument),
onBonusBalanceChange: print(onBonusBalanceChangeDocument),
onBonusGameRoundEvent: print(onBonusGameRoundEventDocument),
onCalculatedCashback: print(onCalculatedCashbackDocument),
onDeposit: print(onDepositDocument),
onFfsBetCountLimit: print(onFfsBetCountLimitDocument),
onFfsBetLimit: print(onFfsBetLimitDocument),
onFreeSpinResult: print(onFreeSpinResultDocument),
onFrontNotification: print(onFrontNotificationDocument),
onLoyaltyCustomerLevel: print(onLoyaltyCustomerLevelDocument),
onRefusedCashback: print(onRefusedCashbackDocument),
onTicketChatEvent: print(onTicketChatEventDocument),
onUpdatedCashback: print(onUpdatedCashbackDocument),
onWagerCreated: print(onWagerCreatedDocument),
onWagerResult: print(onWagerResultDocument),
onWagerUpdated: print(onWagerUpdatedDocument),

};
