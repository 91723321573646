import type { Ref } from 'vue';
import { toRef } from 'vue';

import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import type { BuildSportOptions } from 'web/src/modules/sportline/types/rest';

export function useParseSportlineSettingsRef(): Ref<BuildSportOptions> {
  return toRef(() => useSportlineSettingsStore().parseSportlineSettings);
}

export function useIsZeroMarginEnabledRef(): Ref<boolean> {
  return toRef(() => useSportlineSettingsStore().isZeroMarginEnabled);
}

export function useIsStreamsEnabledRef(): Ref<boolean> {
  return toRef(() => useSportlineSettingsStore().isLiveStreamEnabled);
}

export function useIsSportDateTimeFiltersEnabledRef(): Ref<boolean> {
  return toRef(() => useSportlineSettingsStore().isSportFiltersEnabled);
}

export function useIsMarketTypesSelectionEnabledRef(): Ref<boolean> {
  return toRef(() => useSportlineSettingsStore().isMarketTypesSelectionEnabled);
}
