import { IconName, IconSize } from '@leon-hub/icons';

import type { VIconProps } from '@components/v-icon';

import type { VHintBlockProps } from '../../types/VHintBlockProps';

export default function getPropsForIcon(
  props: Pick<VHintBlockProps, 'icon'>,
): VIconProps {
  const defaults: VIconProps = {
    name: IconName.INFO_OUTLINE,
    size: IconSize.SIZE_24,
  };

  if (props.icon) {
    return {
      ...defaults,
      name: props.icon,
    };
  }

  return defaults;
}
