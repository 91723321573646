import type { Ref } from 'vue';
import { computed } from 'vue';

import type {
  RecycleScrollerResourcesListState,
  RecycleScrollerHiddenSize,
  SizeByDirection,
} from '../types';

export function createHiddenSize(
  visibleItemsState: RecycleScrollerResourcesListState,
  sizes: Ref<SizeByDirection[]>,
): RecycleScrollerHiddenSize {
  return computed(() => {
    const { start = 0, end = 0 } = visibleItemsState.value;
    return {
      start: sizes.value.slice(0, start).reduce((result, size) => result + size, 0),
      end: sizes.value.slice(end).reduce((result, size) => result + size, 0),
    };
  });
}
