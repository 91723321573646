import {
  computed,
  toRef,
} from 'vue';
import type { Ref } from 'vue';

import type {
  CasinoLoyaltyProgramCurrentLevelCardProperties,
  CasinoLoyaltyProgramLevelGameProperties,
} from 'web/src/modules/casino-loyalty-program/store/types';
import useCasinoLoyaltyStore from 'web/src/modules/casino-loyalty-program/store/useCasinoLoyaltyStore';

interface CasinoSidebarBonusComposable {
  loyaltyProgress: Ref<CasinoLoyaltyProgramLevelGameProperties>;
  loyaltyPoints: Ref<number>;
  loyaltyCard: Ref<CasinoLoyaltyProgramCurrentLevelCardProperties>;
  pointsText: Ref<string>;
}

export default function useCasinoSidebarLoyaltyStatus(): CasinoSidebarBonusComposable {
  const casinoLoyaltyStore = useCasinoLoyaltyStore();

  const loyaltyProgress = toRef(casinoLoyaltyStore, 'levelGameProperties');
  const loyaltyPoints = toRef(casinoLoyaltyStore, 'currentPoints');
  const loyaltyCard = toRef(casinoLoyaltyStore, 'currentLevelCardProperties');
  const secondaryText = toRef(casinoLoyaltyStore, 'secondaryText');

  const pointsText = computed(() => (
    process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED ? secondaryText.value : `${loyaltyPoints.value} L`
  ));

  return {
    loyaltyProgress,
    loyaltyPoints,
    loyaltyCard,
    pointsText,
  };
}
