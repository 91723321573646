import type { VButtonEmits } from '../types';

interface UseButtonEmits {
  click(event: MouseEvent): void;
  keydown(event: KeyboardEvent): void;
  mousedown(event: MouseEvent): void;
  focus(event: FocusEvent): void;
  blur(event: FocusEvent): void;
}

export function useButtonEmits(emit: VButtonEmits): UseButtonEmits {
  const click = (event: MouseEvent): void => {
    emit('click', event);
  };

  const keydown = (event: KeyboardEvent): void => {
    emit('keydown', event);
  };

  const mousedown = (event: MouseEvent): void => {
    emit('mousedown', event);
  };

  const focus = (event: FocusEvent): void => {
    emit('focus', event);
  };

  const blur = (event: FocusEvent): void => {
    emit('blur', event);
  };

  return {
    click,
    keydown,
    mousedown,
    focus,
    blur,
  };
}
