import { RouteName } from '@leon-hub/routing-config-names';

import type { AppVueRouter } from 'web/src/modules/core/services/router/types';
import { ModalDesktopPreset } from 'web/src/modules/core/enums';

export default function createVerifyEmailRoutes(router: AppVueRouter): void {
  router.addModalRoute({
    name: RouteName.VERIFY_EMAIL_STATUS_PAGE,
    path: '/verify-email',
    modalPreset: process.env.VUE_APP_LAYOUT_DESKTOP ? ModalDesktopPreset.SmallDesktopModal : undefined,
    component: () => import('web/src/modules/verify-email/views/VerifyEmailRouteComponent'),
  });

  router.addModalRoute({
    name: RouteName.VERIFY_EMAIL,
    path: '/verify-email/:token',
    modalPreset: process.env.VUE_APP_LAYOUT_DESKTOP ? ModalDesktopPreset.SmallDesktopModal : undefined,
    component: () => import('web/src/modules/verify-email/views/VerifyEmailRouteComponent'),
  });
}
