import { toRef } from 'vue';
import type { Ref } from 'vue';

import type { IPriceChangePolicy } from '@leon-hub/api-sdk';

import type { SavePriceChangePolicyPayload } from '../types';
import useCustomerDataStore from '../store/useCustomerDataStore';

interface UsePriceChangePolicy {
  priceChangePolicy: Ref<IPriceChangePolicy>;
  totalHandicapPriceChangePolicy: Ref<IPriceChangePolicy>;
  savePriceChangePolicy: (payload?: SavePriceChangePolicyPayload) => Promise<void>;
}

export default function usePriceChangePolicy(): UsePriceChangePolicy {
  const customerDataStore = useCustomerDataStore();
  const priceChangePolicy = toRef(customerDataStore, 'priceChangePolicy');
  const totalHandicapPriceChangePolicy = toRef(customerDataStore, 'totalHandicapPriceChangePolicy');
  const { savePriceChangePolicy } = customerDataStore;
  return {
    priceChangePolicy,
    totalHandicapPriceChangePolicy,
    savePriceChangePolicy,
  };
}
