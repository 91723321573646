import { whenDomReady } from '@leon-hub/dom-ready';

declare global {
  interface Window { TelegramWebviewProxy: unknown }
}

export const isTelegramInAppBrowser = async (): Promise<boolean> => {
  await whenDomReady();
  if (process.env.VUE_APP_PLATFORM_WEB) {
    return typeof window.TelegramWebviewProxy !== 'undefined';
  }
  return false;
};
