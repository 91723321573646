<script setup lang="ts">
import { toRef } from 'vue';

import {
  useFavoriteRegionFilterTabs,
} from 'web/src/modules/sportline/submodules/favorites/components/FavoriteRegionsFilter/composables';
import { useSportlineSportsStore } from 'web/src/modules/sportline/submodules/sports/store';
import type {
  SportlineFavoriteRegionsFilterEmits,
  SportlineFavoriteRegionsFilterProps,
} from 'web/src/modules/sportline/submodules/favorites/components/FavoriteRegionsFilter/types';
import SportlineFavoriteRegionsFilter from 'web/src/modules/sportline/submodules/favorites/components/FavoriteRegionsFilter/SportlineFavoriteRegionsFilter.vue';

const props = defineProps<SportlineFavoriteRegionsFilterProps>();
const emit = defineEmits<SportlineFavoriteRegionsFilterEmits>();

const selectedRegionFamily = toRef(props, 'selectedRegionFamily', null);
const favoriteEventsList = toRef(useSportlineSportsStore(), 'filteredByDateTimeFavoriteEventsList');

function selectRegionFamily(value: Maybe<string>): void {
  emit('select-region-family', value);
}

const {
  activeTabIndex,
  favoriteRegionsTabs,
} = useFavoriteRegionFilterTabs({ selectedRegionFamily }, { selectRegionFamily }, favoriteEventsList);
</script>

<template>
  <SportlineFavoriteRegionsFilter v-auto-id="'SportsFavoriteRegionsFilter'"
    :active-tab-index="activeTabIndex"
    :favorite-regions-tabs="favoriteRegionsTabs"
    :selected-region-family="selectedRegionFamily"
  />
</template>
