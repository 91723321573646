/* eslint-disable class-methods-use-this */
import { toRef } from 'vue';
import type { RouteLocationNormalized, NavigationGuardNext, RouteLocationRaw } from 'vue-router';

import { useSportlineEventStatisticStore } from 'web/src/modules/sportline/submodules/event-statistic/store';
import { useRedirectBySportsTree } from 'web/src/modules/sportline/submodules/redirects/composables';
import { useSportlineEventDetailsStore } from 'web/src/modules/sportline/submodules/event-details/store';
import { useSnackbarsStore } from 'web/src/modules/snackbars/store';
import { useSportlineEventDetailsAnalytics } from 'web/src/modules/sportline/submodules/event-details/composables/analytics';
import type { AppVueRouter } from 'web/src/modules/core/services/router/types';
import {
  GetEventSubscriptionAction,
} from 'web/src/modules/sportline/submodules/event-details/utils/requests-counter/enums';
import { getSportlinePageNavigationParameters } from 'web/src/modules/sportline/utils/navigation';

import BaseSportEventDetailsPageNavigationService from './BaseSportEventDetailsPageNavigationService';
import { useSportEventDetailsNavigationBackButton } from './useSportEventDetailsNavigationBackButton';

/**
 * Handle navigation for user
 * Check missing event and redirect
 */
export default class DefaultSportEventDetailsPageNavigationService
  extends BaseSportEventDetailsPageNavigationService {
  prefetch(
    router: AppVueRouter,
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ): void {
    super.prefetch(router, to, from, (superResult?: unknown) => {
      if (superResult !== undefined) {
        next(superResult as RouteLocationRaw);
        return;
      }

      useSportEventDetailsNavigationBackButton().activateBackButton();
      // do not await handle
      this.handleRedirectsForEmptyPage(router, to);
      void useSportlineEventDetailsStore().initialRequests();
      next();
    });
  }

  protected handleRedirectsForEmptyPage(router: AppVueRouter, to: RouteLocationNormalized): void {
    const { sportEventId } = getSportlinePageNavigationParameters(to);
    const { showConnectionError } = useSnackbarsStore();

    if (!sportEventId) { return; }

    const sportlineEventDetailsStore = useSportlineEventDetailsStore();
    const sportlineEvent = toRef(sportlineEventDetailsStore, 'sportEventDetails');
    const sportlineEventStatisticStore = useSportlineEventStatisticStore();
    const postMatchStatistics = toRef(sportlineEventStatisticStore, 'postMatchStatistics');

    const analytics = useSportlineEventDetailsAnalytics();

    sportlineEventDetailsStore.createOnInitBehaviour(sportEventId, {
      [GetEventSubscriptionAction.ShowError](): void {
        void showConnectionError();
      },
      [GetEventSubscriptionAction.Redirect](): void {
        useRedirectBySportsTree({
          router,
          code: 301,
          allowedTargets: {
            sport: true,
            region: true,
            league: true,
          },
          canShowRedirectedInfoTargets: {
            league: true,
          },
        }).make();
      },
      [GetEventSubscriptionAction.Finish](): void {
        if (sportlineEvent.value) {
          analytics.sendVisitPageAnalytics(sportlineEvent.value);
          return;
        }

        // eslint-disable-next-line no-useless-return
        if (!postMatchStatistics.value) { return; }

        analytics.sendVisitStatisticsPageAnalytics(postMatchStatistics.value);
      },
    });
  }
}
