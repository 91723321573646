/* eslint-disable arrow-body-style */
// Any value validator, used to describe field and pass any value.
import {
  isBoolean,
  isArray,
  isUndefined,
  isObject,
  isString,
  isOptionalArray,
  isNumber,
  isArrayOfStrings,
  assert,
} from '@leon-hub/guards';

import type {
  JSONSchema,
  JsonSchemaOrBoolean,
  JsonSchemaOrBooleanArray,
  OptionalJsonSchemaItems,
  JsonSchemaOrBooleanObject,
  JsonSchemaDependencies,
  OptionalJsonSchemaOrBoolean,
  Validator,
  FormSchema,
} from './types';

function isUnknown(argument: unknown): argument is unknown {
  return true;
}

const isJsonSchemaOrBoolean = (argument: unknown): argument is (JSONSchema | boolean) => {
  // Recursive call must be suppressed.
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  return !!(isBoolean(argument) || isJsonSchema(argument));
};

const isJsonSchemaOrBooleanArray = (argument: unknown): argument is JsonSchemaOrBooleanArray => {
  if (!isArray(argument)) return false;
  // Recursive call must be suppressed.
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  return argument.every((element) => isJsonSchemaOrBoolean(element));
};

const isJsonSchemaItems = (argument: unknown): argument is JsonSchemaOrBoolean | JsonSchemaOrBoolean[] => {
  return !!(isJsonSchemaOrBoolean(argument) || isJsonSchemaOrBooleanArray(argument));
};

const isOptionalJsonSchemaItems = (argument: unknown): argument is OptionalJsonSchemaItems => {
  return !!(isUndefined(argument) || isJsonSchemaItems(argument));
};

const isOptionalJsonSchemaOrBoolean = (argument: unknown): argument is OptionalJsonSchemaOrBoolean => {
  return !!(isUndefined(argument) || isJsonSchemaOrBoolean(argument));
};

const isJsonSchemaDependencies = (argument: unknown): argument is JsonSchemaDependencies => {
  if (isObject(argument)) {
    const items = Object.values(argument);
    // Recursive call must be suppressed.
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return items.every((item) => isBoolean(item) || isArrayOfStrings(item) || isJsonSchema(item));
  }
  if (isArray(argument)) {
    return argument.every((element) => isString(element));
  }
  return false;
};

export const isJsonSchemaOrBooleanObject = (argument: unknown): argument is JsonSchemaOrBooleanObject => {
  if (!isObject(argument)) return false;
  // Recursive call must be suppressed.
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  return Object.values(argument).every((element) => isJsonSchemaOrBoolean(element));
};

const baseJsonSchemaValidationRules = (): Record<string, Validator[]> => ({
  $comment: [isString, isUndefined],
  $ref: [isString, isUndefined],
  $id: [isString, isUndefined],
  $schema: [isString, isUndefined],
  additionalItems: [isUndefined, isJsonSchemaOrBoolean],
  additionalProperties: [isUndefined, isJsonSchemaOrBoolean],
  allOf: [isUndefined, isJsonSchemaOrBooleanArray],
  anyOf: [isUndefined, isJsonSchemaOrBooleanArray],
  const: [isUnknown],
  contains: [isUndefined, isJsonSchemaOrBoolean],
  contentEncoding: [isString, isUndefined],
  contentMediaType: [isString, isUndefined],

  default: [isUnknown],
  definitions: [isUndefined, isJsonSchemaOrBooleanObject],
  dependencies: [isUndefined, isJsonSchemaDependencies],
  description: [isString, isUndefined],
  enum: [isOptionalArray],
  examples: [isArray, isUndefined],
  exclusiveMaximum: [isNumber, isUndefined],

  exclusiveMinimum: [isNumber, isUndefined],
  format: [isString, isUndefined],
  items: [isOptionalJsonSchemaItems],
  maximum: [isNumber, isUndefined],
  maxItems: [isNumber, isUndefined],
  maxLength: [isNumber, isUndefined],
  maxProperties: [isNumber, isUndefined],
  minimum: [isNumber, isUndefined],
  minLength: [isNumber, isUndefined],
  minItems: [isNumber, isUndefined],
  minProperties: [isNumber, isUndefined],
  multipleOf: [isNumber, isUndefined],
  not: [isUndefined, isJsonSchemaOrBoolean],
  oneOf: [isUndefined, isJsonSchemaOrBooleanArray],
  pattern: [isString, isUndefined],

  patternProperties: [isUndefined, isJsonSchemaOrBooleanObject],
  properties: [isUndefined, isJsonSchemaOrBooleanObject],
  propertyNames: [isUndefined, isJsonSchemaOrBooleanObject],
  readOnly: [isBoolean, isUndefined],
  required: [isArrayOfStrings, isUndefined],
  then: [isOptionalJsonSchemaOrBoolean],
  title: [isString, isUndefined],
  type: [isString, isArrayOfStrings, isUndefined],
  uniqueItems: [isBoolean, isUndefined],
  writeOnly: [isBoolean, isUndefined],
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  else: [isUndefined, isBoolean, isJsonSchema],
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  if: [isUndefined, isBoolean, isJsonSchema],
});

const isJsonSchema = (schema: unknown): schema is JSONSchema => {
  if (!isObject(schema)) {
    return false;
  }
  if (Object.keys(schema).length === 0) {
    return true;
  }

  const rules = {
    ...baseJsonSchemaValidationRules(),
  };

  const argumentKeys = Object.keys(schema);
  const rulesKeys = Object.keys(rules);
  const unknownKeys = [...rulesKeys, ...argumentKeys].filter((key) => !rulesKeys.includes(key));

  if (unknownKeys.length > 0) {
    // eslint-disable-next-line no-console
    console.warn('Unexpected JsonSchema fields:', ...unknownKeys);
    return false;
  }

  return rulesKeys.every((key) => {
    const validators = rules[key];
    const result = validators.some((validator) => validator(schema[key]));
    if (!result) {
      // eslint-disable-next-line no-console
      console.warn('Invalid JsonSchema field:', key, schema[key]);
    }
    return result;
  });
};

const isFormSchema = (schema: unknown): schema is FormSchema => {
  if (!isObject(schema)) {
    return false;
  }

  const rules: Record<string, Validator[]> = {
    ...baseJsonSchemaValidationRules(),
    properties: [isJsonSchemaOrBooleanObject],
  };

  const argumentKeys = Object.keys(schema);
  const rulesKeys = Object.keys(rules);
  const unknownKeys = [...rulesKeys, ...argumentKeys]
    .filter((key) => !rulesKeys.includes(key));

  assert(unknownKeys.length === 0, `Unexpected FormSchemasJSON fields: ${JSON.stringify(unknownKeys)}`);

  return rulesKeys.every((key: string) => {
    const validators = rules[key];
    const result = validators.some((validator) => validator(schema[key]));
    if (!result) {
      // eslint-disable-next-line no-console
      console.warn('Invalid FormSchemasJSON field:', key, schema[key]);
    }
    return result;
  });
};

export {
  isJsonSchema,
  isFormSchema,
};
