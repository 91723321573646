import type {
  NavigationGuardNext,
  RouteLocationNormalized,
} from 'vue-router';

import type { BaseRouteNameType } from '@leon-hub/routing-config-names';
import { isString } from '@leon-hub/guards';
import { RouteName } from '@leon-hub/routing-config-names';
import { HomePageType } from '@leon-hub/api-sdk';

import { AbstractPrefetch } from 'web/src/modules/core/services/router/prefetch';
import type { AppVueRouter } from 'web/src/modules/core/services/router/types';
import { useRootNavigationStore, useSiteConfigStore } from 'web/src/modules/core/store';
import { useSportlineHome } from 'web/src/modules/home/submodules/sportline/useSportlineHome';

function getDefaultRouteName(): BaseRouteNameType {
  if (process.env.VUE_APP_FEATURE_CASINO_ENABLED) {
    const { homePageType } = useSiteConfigStore();

    return homePageType === HomePageType.SPORTS
      ? RouteName.HOME
      : RouteName.SPORTLINE_TOP_EVENTS;
  }

  return RouteName.HOME;
}

export default class HomePagePrefetch extends AbstractPrefetch {
  // eslint-disable-next-line class-methods-use-this
  prefetch(
    router: AppVueRouter,
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ): Promise<void> {
    const {
      isReady,
      initialRequests,
      syncBackgroundRequests,
    } = useSportlineHome();

    const navigationStore = useRootNavigationStore();
    const targetRouteName = isString(to.name) ? to.name as BaseRouteNameType : getDefaultRouteName();

    if (!isReady.value) {
      navigationStore.setOnlyFirstRouteContentLoading(targetRouteName);
      void initialRequests().then(() => {
        navigationStore.setRouteContentLoaded(targetRouteName);
      });
    } else {
      void syncBackgroundRequests();
      navigationStore.setRouteContentLoaded(targetRouteName);
    }

    next();
    return Promise.resolve();
  }
}
