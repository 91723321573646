import type { RouteLocationNormalized, NavigationGuardNext } from 'vue-router';

import { isString } from '@leon-hub/guards';
import { RouteName } from '@leon-hub/routing-config-names';

import { useSportlineEventStatisticStore } from 'web/src/modules/sportline/submodules/event-statistic/store';
import { useSportlineEventDetailsStore } from 'web/src/modules/sportline/submodules/event-details/store';
import { useSportlineBreadcrumbsStore } from 'web/src/modules/sportline/submodules/breadcrumbs/store';
import type { SportlinePageIdentifier as SportEventsPageIdentifier } from 'web/src/modules/sportline/types/navigation';
import type {
  SportEventDetailsPageNavigationService,
} from 'web/src/modules/sportline/submodules/event-details/types';
import { isValidSportEventsPageIdentifier } from 'web/src/modules/sportline/guards';
import { getSportPageNavigationParameters } from 'web/src/modules/sportline/utils';
import type { AppVueRouter } from 'web/src/modules/core/services/router/types';
import { useIsDevIP } from 'web/src/modules/core/composables/root';
import { useSportlineWidgetsStore } from 'web/src/modules/sportline/submodules/widgets/store';

/**
 * Default details page navigation logic
 * Load data and handle navigation parameters
 */
export default class BaseSportEventDetailsPageNavigationService implements SportEventDetailsPageNavigationService {
  // eslint-disable-next-line class-methods-use-this
  prefetch(
    router: AppVueRouter,
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ): void {
    const navigationParameters: SportEventsPageIdentifier = getSportPageNavigationParameters(to);

    if (!isValidSportEventsPageIdentifier(navigationParameters)) {
      next({ name: RouteName.ERROR_NOT_FOUND_404 });
      return;
    }

    const { isDevIP } = useIsDevIP();

    const statisticId = isDevIP.value && isString(to.query?.statisticId)
      ? to.query?.statisticId
      : undefined;

    useSportlineEventDetailsStore().setPageIdentifier({ ...navigationParameters, statisticId });
    useSportlineEventStatisticStore().setPageIdentifier({ ...navigationParameters, statisticId });
    useSportlineBreadcrumbsStore().setPageIdentifier({ ...navigationParameters, statisticId });
    void useSportlineWidgetsStore().fetchSportlineConfigs();

    next();
  }
}
