import type { SlipListItem } from '../types';
import { useSlipRootStore } from '../submodules/slip-root/store';

interface UseSlipRunnerClick {
  onSlipRunnerClick(item: SlipListItem | null): void;
}

export default function useSlipRunnerClick(): UseSlipRunnerClick {
  const { handleRunnerClick } = useSlipRootStore();

  return {
    onSlipRunnerClick: handleRunnerClick,
  };
}
