enum NavigationGroup {
  TOP_NAVIGATION = 'TOP_NAVIGATION',
  TOP_SHOWCASE_NAVIGATION = 'TOP_SHOWCASE_NAVIGATION',
  TOP_GAMES_NAVIGATION = 'TOP_GAMES_NAVIGATION',
  BOTTOM_NAVIGATION = 'BOTTOM_NAVIGATION',
  BOTTOM_NAVIGATION_SHORT = 'BOTTOM_NAVIGATION_SHORT',
  SIDEBAR_LEFT_NAVIGATION = 'SIDEBAR_LEFT_NAVIGATION',
  SIDEBAR_LEFT_TAB_NAVIGATION = 'SIDEBAR_LEFT_TAB_NAVIGATION',
  SIDEBAR_RIGHT_NAVIGATION = 'SIDEBAR_RIGHT_NAVIGATION',
  PROFILE_MENU_NAVIGATION = 'PROFILE_MENU_NAVIGATION',
  PROFILE_NAVIGATION = 'PROFILE_NAVIGATION',
  GENERIC_INFO_NAVIGATION = 'GENERIC_INFO_NAVIGATION',
  BOOTSTRAP_NAVIGATION = 'BOOTSTRAP_NAVIGATION',
  SETTINGS_NAVIGATION = 'SETTINGS_NAVIGATION',
  AFFILIATE_PROGRAM_NAVIGATION = 'AFFILIATE_PROGRAM_NAVIGATION',
  AFFILIATE_PROGRAM_FOOTER_NAVIGATION = 'AFFILIATE_PROGRAM_FOOTER_NAVIGATION',
  PROFILE_SETTINGS_NAVIGATION = 'PROFILE_SETTINGS_NAVIGATION',
  TELEGRAM_BOT_NAVIGATION = 'TELEGRAM_BOT_NAVIGATION',
  ERROR_404_NAVIGATION = 'ERROR_404_NAVIGATION',
  PROFILE_SIDEBAR_MENU = 'PROFILE_SIDEBAR_MENU',
  RESPONSIBLE_GAMBLING_NAVIGATION = 'RESPONSIBLE_GAMBLING_NAVIGATION',
  SIDEBAR_LEFT_TOP_NAVIGATION = 'SIDEBAR_LEFT_TOP_NAVIGATION',
  SIDEBAR_LEFT_MIDDLE_NAVIGATION = 'SIDEBAR_LEFT_MIDDLE_NAVIGATION',
  DEVTOOLS_NAVIGATION = 'DEVTOOLS_NAVIGATION',
  PROMOTIONS_NAVIGATION = 'PROMOTIONS_NAVIGATION',
  PROMOTION_CATEGORIES_NAVIGATION = 'PROMOTION_CATEGORIES_NAVIGATION',
  PROMOTIONS_SINGLE_CATEGORY_NAVIGATION = 'PROMOTIONS_SINGLE_CATEGORY_NAVIGATION',
  PROMOTION_SINGLE_CATEGORY_CATEGORIES_NAVIGATION = 'PROMOTION_SINGLE_CATEGORY_CATEGORIES_NAVIGATION',
  TOURNAMENTS_SINGLE_CATEGORY_NAVIGATION = 'TOURNAMENTS_SINGLE_CATEGORY_NAVIGATION',
  TOURNAMENTS_SINGLE_CATEGORY_CATEGORIES_NAVIGATION = 'TOURNAMENTS_SINGLE_CATEGORY_CATEGORIES_NAVIGATION',
}

export default NavigationGroup;

const navigationGroups: readonly NavigationGroup[] = Object.freeze(
  Object.values(NavigationGroup) as NavigationGroup[],
);

export function isNavigationGroup(value: unknown): value is NavigationGroup {
  return navigationGroups.includes(value as NavigationGroup);
}
