import { isArray } from '@leon-hub/guards';

import type { TopLeagueListElement } from 'web/src/modules/sportline/types';
import type {
  SportsTreeLeagueElement,
  SportsTreeRegionElement,
  SportsTreeSportElement,
} from 'web/src/modules/sportline/types/rest';

type ExtractTopLeaguesGuard = (
  leagueElement: SportsTreeLeagueElement,
  regionElement: SportsTreeRegionElement,
  sportElement: SportsTreeSportElement,
) => boolean;

// eslint-disable-next-line sonarjs/cognitive-complexity
export function extractTopLeaguesByCondition(
  listElement?: Maybe<SportsTreeSportElement | SportsTreeSportElement[]>,
  condition?: ExtractTopLeaguesGuard,
  options?: { maxCount?: number },
): TopLeagueListElement[] {
  if (!listElement) { return []; }

  const maxCount = options?.maxCount;
  const keepLeagueGuard = condition ?? (() => true);
  const listElements = isArray(listElement) ? listElement : [listElement];

  return listElements.flatMap((sportElement: SportsTreeSportElement): TopLeagueListElement[] => {
    const result: TopLeagueListElement[] = [];
    const { sport } = sportElement;

    if (!sport) { return result; }

    for (const regionElement of (sportElement?.regions ?? [])) {
      for (const leagueElement of (regionElement?.leagues ?? [])) {
        if (!keepLeagueGuard(leagueElement, regionElement, sportElement)) { continue; }

        result.push({
          league: leagueElement.league,
          region: regionElement.region,
          sport,
          counters: leagueElement.counters,
        });

        if (maxCount && result.length >= maxCount) { break; }
      }
    }

    return result;
  });
}
