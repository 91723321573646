import type { RouteLocationNormalized } from 'vue-router';

import { RouteName } from '@leon-hub/routing-config-names';
import { getQueryManager } from '@leon-hub/query-manager';

import type { RouterHistoryState } from 'web/src/modules/core/types';
import { useRouterStore } from 'web/src/modules/core/store';
import type { AppVueRouter } from 'web/src/modules/core/services/router/types';
import { doRootSettingsRequest } from 'web/src/modules/core/store/utils';

import processPhoneHistoryState from './processPhoneHistoryState';
import processDesktopHistoryState from './processDesktopHistoryState';

let lastHistoryState: RouterHistoryState | undefined;

export default function processAfterRoute(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  router: AppVueRouter,
): void {
  if (from.name && !router.isPopStateDetected() && from.redirectedFrom?.fullPath !== to.fullPath) {
    const headers = getQueryManager(to.fullPath).getRootHeaderParameters();

    if (Object.keys(headers).length > 0) {
      void doRootSettingsRequest(headers);
    }
  }

  if (!from.name) {
    window.history.replaceState({
      ...window.history.state,
      meta: undefined,
    }, '');
  }

  if (process.env.VUE_APP_LAYOUT_PHONE) {
    processPhoneHistoryState(to, from, router, lastHistoryState);
  }

  if (process.env.VUE_APP_LAYOUT_DESKTOP) {
    processDesktopHistoryState(to, router, lastHistoryState);
  }

  if (router.doSaveScrollPosition()) {
    // eslint-disable-next-line no-param-reassign
    to.meta.doSaveScrollPosition = true;
  }

  window.history.replaceState({
    ...window.history.state,
    historyLength: window.history.length,
  }, '');

  lastHistoryState = window.history.state;
  router.processAfterEach();

  const {
    setBackButtonInTopBar,
    setCurrentRoute,
    setCurrentRouteIndex,
    setLastNoModalRouteIndex,
    setPreviousRouteName,
    setPreviousRouteUrl,
  } = useRouterStore();
  setBackButtonInTopBar(!!window.history.state?.meta?.isBackButton);
  setCurrentRoute(to);
  setCurrentRouteIndex(window.history.state?.realPosition || window.history.state?.position || 0);

  if (!window.history.state?.meta?.isModalRoute) {
    setLastNoModalRouteIndex(window.history.state?.position || 0);
  }

  if (window.history.state?.back) {
    setPreviousRouteUrl(window.history.state.back);
    setPreviousRouteName(router.resolve(window.history.state.back).name ?? RouteName.HOME);
  } else {
    setPreviousRouteUrl(undefined);
    setPreviousRouteName(undefined);
  }
}
