import type { Ref } from 'vue';
import {
  computed, inject, ref, watch,
} from 'vue';

import type { FadeCarouselState } from 'web/src/components/FadeCarousel/types';

import type { VFadeCarouselPointsPaginationProps } from '../types';

interface useVFadeCarouselPointsPaginationComposable {
  carouselOptions: FadeCarouselState;
  moveToNextSlide?: () => void;
  moveToPreviousSlide?: () => void;
  moveToSlide?: (index: number) => void;
  paginationWrapper: Ref<HTMLElement | undefined>;
  isLimitedViewEnabled: Ref<boolean>;
}

export default function useVFadeCarouselPointsPagination(props: VFadeCarouselPointsPaginationProps): useVFadeCarouselPointsPaginationComposable {
  const carouselOptions = inject<FadeCarouselState>('carouselOptions', {
    activeSlideIndex: 0,
    slidesCount: 0,
    isDragInProgress: false,
  });
  const moveToNextSlide = inject<() => void>('moveToNextSlide');
  const moveToPreviousSlide = inject<() => void>('moveToPreviousSlide');
  const moveToSlide = inject<(index: number) => void>('moveToSlide');

  const paginationWrapper = ref<HTMLElement | undefined>();

  const isLimitedViewEnabled = computed<boolean>(
    () => (
      carouselOptions.slidesCount > 3
      && !!props?.isLimitedView
      && !process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED
    ),
  );

  const scrollLeftActive = computed<number>(() => {
    if (carouselOptions.activeSlideIndex < 3 || !isLimitedViewEnabled.value) {
      return 0;
    }
    return (carouselOptions.activeSlideIndex - 2) * 20;
  });

  watch(scrollLeftActive, (value) => {
    if (isLimitedViewEnabled.value) {
      paginationWrapper.value?.scrollTo({
        left: value,
        behavior: 'smooth',
      });
    }
  });

  return {
    carouselOptions,
    moveToNextSlide,
    moveToPreviousSlide,
    moveToSlide,
    paginationWrapper,
    isLimitedViewEnabled,
  };
}
