import type { Ref } from 'vue';
import { computed } from 'vue';

import type {
  AddToCustomerLeaguesParameters,
} from 'web/src/modules/sportline/submodules/favorites/types';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';

import useCustomerFavoritesService from './useCustomerFavoritesService';

interface UseFavoriteLeagueDataComposable {
  isOnlyAddToPendingFavoriteLeagues: Ref<boolean>;
  pendingToFavoriteLeaguesList: Ref<readonly AddToCustomerLeaguesParameters[]>;
  changeFavoriteLeagueState(data: AddToCustomerLeaguesParameters): Promise<void>;
}

export function useFavoriteLeagueData(): UseFavoriteLeagueDataComposable {
  const {
    pendingToFavoriteLeaguesList,
    changeFavoriteLeagueState,
  } = useCustomerFavoritesService();
  const { isLoggedIn } = useIsLoggedIn();

  return {
    isOnlyAddToPendingFavoriteLeagues: computed(() => !isLoggedIn.value),
    pendingToFavoriteLeaguesList,
    changeFavoriteLeagueState,
  };
}
