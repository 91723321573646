import { assert } from '@leon-hub/guards';

export const getTargetValue = (event: InputEvent | KeyboardEvent): string => {
  const { target } = event;

  if (target instanceof HTMLDivElement) {
    return target.textContent || '';
  }

  assert(target instanceof HTMLInputElement, 'Expected target to be an instance of HTMLInputElement');

  return target.value;
};
