<script setup lang="ts">
import { IconName } from '@leon-hub/icons';
import type { FocusableInputRef } from '@leon-hub/focus';
import { useAutofocus } from '@leon-hub/focus';

import { VIcon } from '@components/v-icon';

import InputButton from '../InputButton';
import InputHint from '../InputHint';
import type { MultilineTextInputProperties, MultilineTextInputEmits, MultilineTextInputSlots } from '../../types';
import useMultilineTextInput from '../MultilineTextInput/useMultilineTextInput';
import InputBorderWrapper from '../InputBorderWrapper';
import getInputIconSize from '../../utils/getInputIconSize';

const props = withDefaults(defineProps<MultilineTextInputProperties>(), {
  name: 'MultilineTextInput',
});

const emits = defineEmits<MultilineTextInputEmits>();

defineOptions({
  inheritAttrs: false,
});

defineSlots<MultilineTextInputSlots>();

const { focusable, focus } = useAutofocus(props);

const {
  uniqueId,
  hintProps,
  hasError,
  rows,
  inputValue,
  isFocus,
  isHover,
  onMouseOver,
  onMouseLeave,
  onFocus,
  onBlur,
  onChange,
  onInput,
  emitIconSuffixClick,
  setRowsOnMount,
} = useMultilineTextInput(props, emits, focusable);

defineExpose<FocusableInputRef>({
  focus,
});

// ok not to be reactive
const iconSize = getInputIconSize();
</script>

<template>
  <div v-auto-id="'AutoheightTextInput'" :class="$style['autoheight-text-input']">
    <InputBorderWrapper
      :is-focus="isFocus"
      :is-disabled="disabled"
      :is-hover="isHover"
      :has-error="hasError"
      :class="$style['autoheight-text-input__wrapper']"
    >
      <label
        :for="uniqueId"
        :class="{
          [$style['autoheight-text-input__label']]: true,
          [$style['autoheight-text-input__icon-padding']]: !!suffixIconName,
        }"
      >
        {{ label }}
      </label>
      <div
        v-if="readonly"
        :class="$style['autoheight-text-input__input']"
      >
        {{ inputValue }}
      </div>
      <textarea
        v-else
        :id="uniqueId"
        ref="focusable"
        :autofocus="process.env.VUE_APP_OS_IOS ? undefined : autofocus"
        :value="inputValue"
        :name="name"
        :disabled="disabled"
        :placeholder="placeholder"
        :readonly="readonly"
        :rows="rows"
        :maxlength="maxLength"
        :class="{
          [$style['autoheight-text-input__input']]: true,
          [$style['autoheight-text-input__icon-padding']]: !!suffixIconName,
        }"
        @input.stop="onInput"
        @change.stop="onChange"
        @focus.stop="onFocus"
        @blur.stop="onBlur"
        @mouseover="onMouseOver"
        @mouseleave="onMouseLeave"
        @vue:mounted="setRowsOnMount"
      />
      <div
        v-if="!!suffixIconName || disabled"
        :class="{
          [$style['autoheight-text-input__icon']]: true,
          [$style['autoheight-text-input__icon--inert']]: !clickableIcon || disabled
        }"
      >
        <template v-if="disabled">
          <VIcon
            v-if="disabled"
            :size="iconSize"
            :name="null ? IconName.SLOTT_LOCK : IconName.LOCK"
            :class="$style['autoheight-text-input__lock']"
          />
        </template>
        <template v-else>
          <slot
            name="iconSuffix"
          >
            <InputButton
              v-if="clickableIcon"
              :is-large="isLarge"
              :icon-name="suffixIconName"
              @click="emitIconSuffixClick"
            />
            <VIcon
              v-else
              :size="iconSize"
              :name="suffixIconName"
            />
          </slot>
        </template>
      </div>
    </InputBorderWrapper>
    <InputHint
      v-if="!isHintHidden"
      v-bind="hintProps"
    />
  </div>
</template>

<style module lang="scss">
  .autoheight-text-input {
    @import '../../common';

    display: flex;
    flex-flow: column wrap;
    flex-grow: 1;

    &__wrapper {
      @include autoheightTextInputBorder;
    }

    &__icon {
      @include autoheightTextInputIcon;

      display: flex;
      flex: none;
      align-items: center;
      justify-content: center;

      &--inert {
        pointer-events: none;
      }
    }

    &__label {
      @include autoheightTextInputLabel;

      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__input {
      @include inputCommon;
      @include customScrollbar;
      @include autoheightTextInputTextArea;

      display: block;
      resize: none;

      &::placeholder {
        color: $inputPlaceholderDefaultColor;
      }

      &:focus {
        &::placeholder {
          color: $inputPlaceholderFocusColor;
        }
      }
    }

    &__icon-padding {
      @include autoheightTextInputIconPadding;
    }
  }
</style>
