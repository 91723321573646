import {
  IconSize,
} from '@leon-hub/icons';

import type { VIconProps } from '@components/v-icon';

import type { VButtonProps } from '../../types';

export default function getIconPropsForButton(
  props: Pick<VButtonProps, 'isLoading' | 'iconName' | 'iconSize'>,
): VIconProps | {} {
  if (props.isLoading) {
    return process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED ? {
      kind: 'custom',
    } : {};
  }

  return {
    name: props.iconName,
    size: props.iconSize ?? IconSize.SIZE_16,
  } as VIconProps;
}
