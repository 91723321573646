import type { SportlineBackground } from 'web/src/modules/sportline/types';
import { SportBackgroundColorDark, SportBackgroundColorLight } from 'web/src/modules/sportline/constants';

export function getSportFamilyBackground(family?: Maybe<string>): Maybe<SportlineBackground> {
  const sportFamily = family?.toLowerCase() || null;
  if (!sportFamily) { return null; }
  return {
    dark: SportBackgroundColorDark[sportFamily],
    light: SportBackgroundColorLight[sportFamily],
  };
}
