import { normalizeError } from '@leon-hub/errors';

import type { ClaimToUpdateSportEventRunnerOddChanged } from 'web/src/modules/slip/types/сlaimToUpdate';
import type {
  CoreSportEventResponse,
  GetSportEventsResponse,
} from 'web/src/modules/sportline/types/rest';
import {
  applyEventsRunnersOddsChanges,
  isSportEventResponseIncludesChanges,
} from 'web/src/modules/sportline/utils/rest/claimToUpdate';
import { ApplyEventRunnersOddsChangesError } from 'web/src/modules/sportline/errors/core';

export default function createApplyRunnersOddsChanges(
  getResponse: () => Maybe<GetSportEventsResponse | CoreSportEventResponse>,
  setResponse: (response: GetSportEventsResponse | CoreSportEventResponse) => void,
): (changes: ClaimToUpdateSportEventRunnerOddChanged[]) => void {
  return function applyOddsChanges(changes: ClaimToUpdateSportEventRunnerOddChanged[]): void {
    try {
      const rawResponse = getResponse();
      if (!rawResponse) { return; }
      const hasChanges = isSportEventResponseIncludesChanges(rawResponse, changes);
      if (!hasChanges) { return; }
      setResponse(applyEventsRunnersOddsChanges(rawResponse, changes));
    } catch (rawError) {
      throw new ApplyEventRunnersOddsChangesError({ originalError: normalizeError(rawError) });
    }
  };
}
