import type {
  RouteLocationNormalized,
  NavigationGuardNext,
  RouteLocationRaw,
} from 'vue-router';

import { RouteName } from '@leon-hub/routing-config-names';

import { useSportlineSportsStore } from 'web/src/modules/sportline/submodules/sports/store';
import { useRootNavigationStore } from 'web/src/modules/core/store';
import { usePrerenderSportsPageNavigation } from 'web/src/modules/sportline/submodules/sports/composables/redirects';
import type { AppVueRouter } from 'web/src/modules/core/services/router/types';

import BaseSportsPageNavigationService from './BaseSportsPageNavigationService';

export default class PrerenderSportsPageNavigationService extends BaseSportsPageNavigationService {
  prefetch(
    router: AppVueRouter,
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ): void {
    super.prefetch(router, to, from, (superResult?: unknown) => {
      if (superResult !== undefined) {
        next((superResult as RouteLocationRaw));
        return;
      }

      const { setRouteContentLoaded } = useRootNavigationStore();
      const {
        initialRequests,
        awaitStateIsLoaded,
      } = useSportlineSportsStore();
      const {
        proceedRedirects,
      } = usePrerenderSportsPageNavigation({ router, to, from });

      const dateTimeFilter = this.getDateTimeFilter(to);

      void initialRequests({ silent: false, dateTimeFilter }).then(() => {
        setRouteContentLoaded(RouteName.SPORTLINE_PREMATCH_EVENTS);
      });

      awaitStateIsLoaded().then(() => proceedRedirects(next), next);
    });
  }
}
