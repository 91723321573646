import { getCurrenciesByCountry } from '@leon-hub/api-sdk';
import type { GetCurrenciesByCountryReturnType } from '@leon-hub/api-sdk';

import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';

export default function getCurrenciesFromApi(countryCode: string): Promise<GetCurrenciesByCountryReturnType> {
  const api = useGraphqlClient();
  return getCurrenciesByCountry(
    api,
    (node) => node.queries.registration.getCurrenciesByCountry,
    { options: { countryCode } },
    { silent: false },
  );
}
