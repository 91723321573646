<script lang="ts" setup>
import type { RouteLocationRaw } from 'vue-router';

import { useVLinkPrevented } from './composables';

interface VLinkPreventedProps {
  to: RouteLocationRaw;
}

interface VLinkPreventedEmits {
  (e: 'click', event: MouseEvent): void;
}

const props = withDefaults(defineProps<VLinkPreventedProps>(), {});
const emit = defineEmits<VLinkPreventedEmits>();

const { href } = useVLinkPrevented(props);

function clickHandler(mouseEvent: MouseEvent): void {
  if (mouseEvent && !mouseEvent.defaultPrevented && mouseEvent.preventDefault) {
    mouseEvent.preventDefault();
  }

  emit('click', mouseEvent);
}
</script>

<template>
  <a v-auto-id="'VLinkPrevented'"
    :href="href"
    @click.prevent="clickHandler"
  >
    <slot />
  </a>
</template>
