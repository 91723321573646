import type { RouteLocationNormalized, NavigationGuardNext } from 'vue-router';

import { useIntercomEvents } from 'web/src/modules/intercom/composables/index';
import useSupport from 'web/src/modules/support/composables/useSupport';

interface IntercomBasePrefetch {
  prefetch: (from: RouteLocationNormalized, next: NavigationGuardNext) => boolean;
}

export default function useBaseIntercom(): IntercomBasePrefetch {
  function prefetch(
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ): boolean {
    if (process.env.VUE_APP_FEATURE_INTERCOM_ENABLED) {
      const { openIntercom } = useIntercomEvents();
      const { isChatMode, isIntercomAvailable } = useSupport();

      if (isChatMode.value && isIntercomAvailable.value && process.env.VUE_APP_FEATURE_INTERCOM_ENABLED) {
        openIntercom();
        next({
          ...from,
          replace: true,
        });

        return false;
      }
    }
    return true;
  }

  return {
    prefetch,
  };
}
