import type { RouteLocationRaw } from 'vue-router';

import {
  isString,
  isOptionalString,
  isNull,
  isUndefined,
  isObject,
} from '@leon-hub/guards';

import type { HintLink } from '../types/HintLink';

function isRawLocation(value: unknown): value is RouteLocationRaw {
  return isString(value) || (isObject(value) && (isString(value.path) || isString(value.name)));
}

export default function isHintLink(value: unknown): value is HintLink {
  if (!isObject(value)) {
    return false;
  }
  return isRawLocation(value.href) && isOptionalString(value.target) && isString(value.text);
}

export function isOptionalHintLinkOrNull(value: unknown): value is Maybe<HintLink> | undefined {
  return isNull(value) || isUndefined(value) || isHintLink(value);
}
