import { getSiteConfigSettings } from '@leon-hub/api-sdk';

import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';

import type { SiteConfigDocument } from '../types';

export default function fetchSiteConfigSettings({ silent }: {
  silent?: boolean;
} = {}): Promise<Partial<SiteConfigDocument>> {
  return getSiteConfigSettings(useGraphqlClient(),
    (node) => node.queries.system.siteConfig,
    {},
    { silent });
}
