import { reactive } from 'vue';

type OpenRegionsIds = Record<string, boolean>;

export interface UseSaveOpenRegionStateComposable {
  isRegionOpen(id: string): boolean;
  setOpenRegionState(id: string, state: boolean): void;
}

export function useSaveOpenRegionStateStoreComposable(): UseSaveOpenRegionStateComposable {
  const openRegionsIds = reactive<OpenRegionsIds>({});

  function isRegionOpen(id: string): boolean {
    return openRegionsIds[id] ?? false;
  }

  function setOpenRegionState(id: string, state: boolean): void {
    openRegionsIds[id] = state;
  }

  return {
    isRegionOpen,
    setOpenRegionState,
  };
}
