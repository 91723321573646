import { ref, computed } from 'vue';

import { isNumber } from '@leon-hub/guards';

import type {
  FilterTransitionId,
  FilterTransitionProps,
  FilterTransitionEmits,
  FilterTransitionComposable,
} from 'web/src/modules/sportline/components/FilterTransition/types';

export default function useFilterTransition(
  props: FilterTransitionProps,
  emits: FilterTransitionEmits,
): FilterTransitionComposable {
  const activeTransitionId = ref<FilterTransitionId>(null);
  const nextTransitionId = ref<FilterTransitionId>(null);
  const isFadeOutTransition = ref(false);
  const isTransitionEnabled = ref(false);
  const isTransitionActive = ref(false);

  const transitionName = computed(() => (!props.disabled ? 'fade' : 'none'));
  const transitionDuration = computed(() => (!props.disabled
    ? { enter: props.fadeInDuration, leave: props.fadeOutDuration }
    : 0));
  const transitionStyles = computed(() => {
    let duration = transitionDuration.value;

    if (!isNumber(duration)) {
      duration = isFadeOutTransition.value ? duration.leave : duration.enter;
    }

    return {
      'transition-duration': `${duration / 1000}s`,
    };
  });

  function setActiveTransitionId(id: FilterTransitionId) {
    activeTransitionId.value = id;
    emits.setActiveTransitionId(id);
  }

  function transitionStart(id: FilterTransitionId): void {
    if (props.disabled) { return; }

    if (!isTransitionEnabled.value) {
      return;
    }

    isTransitionActive.value = true;
    setActiveTransitionId(id);
    isFadeOutTransition.value = true;
  }

  function transitionComplete(): void {
    if (props.disabled) { return; }

    isTransitionActive.value = false;
    setActiveTransitionId(null);
    nextTransitionId.value = null;
  }

  function beforeFadeIn(id: FilterTransitionId): void {
    nextTransitionId.value = id;
  }

  function fadeOutComplete(): void {
    if (props.disabled) { return; }

    isFadeOutTransition.value = false;
    setActiveTransitionId(nextTransitionId.value);
    nextTransitionId.value = null;
  }

  function getActiveTransitionId(): Maybe<string> {
    return activeTransitionId.value;
  }

  function isActive(): boolean {
    return isTransitionActive.value;
  }

  function enable(): void {
    isTransitionEnabled.value = true;
  }

  function disable(): void {
    isTransitionEnabled.value = false;
  }

  return {
    transitionName,
    transitionDuration,
    transitionStyles,

    activeTransitionId,
    nextTransitionId,
    isFadeOutTransition,
    isTransitionEnabled,
    isTransitionActive,

    transitionStart,
    transitionComplete,
    beforeFadeIn,
    fadeOutComplete,

    getActiveTransitionId,
    isActive,
    enable,
    disable,
  };
}
