import { assert, isNumber } from '@leon-hub/guards';

import type { ChangeInputValueResult } from '../types';
import type { MaskOptions } from '../../types';
import handleMaskedValueChange from './handleMaskedValueChange';

interface Payload {
  event: ClipboardEvent;
  currentValue: string;
  maskOptions: MaskOptions;
}

export default function handleMaskedValuePaste({ event, currentValue, maskOptions }: Payload): ChangeInputValueResult {
  event.preventDefault();
  const { target, clipboardData } = event;
  assert(target instanceof HTMLInputElement);
  const { selectionStart, selectionEnd } = target;
  assert(isNumber(selectionStart) && isNumber(selectionEnd));
  const data = clipboardData?.getData('text') ?? '';
  return handleMaskedValueChange({
    target,
    selectionStart,
    selectionEnd,
    data,
    currentValue,
    maskOptions,
  });
}
