<script lang="ts" setup>
import { toRefs, defineAsyncComponent } from 'vue';

import type { PostMatchStatisticHeadlineProps } from '../types';
import { usePostMatchStatisticHeadline } from '../composables';

const props = defineProps<PostMatchStatisticHeadlineProps>();

const PostMatchInfo = '1'
  // eslint-disable-next-line max-len
  ? defineAsyncComponent(() => import('web/src/modules/sportline/submodules/event-details/views/headline-info/default/wide/PostMatchInfo.vue'))
  // eslint-disable-next-line max-len
  : defineAsyncComponent(() => import('web/src/modules/sportline/submodules/event-details/views/headline-info/default/swiper/PostMatchInfo.vue'));

const {
  kickoff,
  competitors,
  finalScore,
  penaltyScore,
  backgroundColor,
} = usePostMatchStatisticHeadline(toRefs(props));
</script>

<template>
  <div v-auto-id="'DefaultPostMatchStatisticHeadline'"
    :class="$style['post-match-statistic-headline']"
    :style="{ backgroundColor }"
  >
    <PostMatchInfo
      v-if="competitors"
      :competitors="competitors"
      :total-score="finalScore"
      :penalty-score="penaltyScore"
      :kickoff="kickoff"
    />
  </div>
</template>

<style module lang="scss">
.post-match-statistic-headline {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: auto;
  overflow: hidden;
  color: var(--White);
  border-radius: 5px;
}
</style>
