<script setup lang="ts">
import {
  defineAsyncComponent,
  onMounted,
} from 'vue';

import VEmpty from 'web/src/components/Empty/components/VEmpty';
import { startAppSw } from 'web/src/modules/core/apps/main/startAppSw';

import { usePostponedAppController } from './composables';

const PushModalComponent = '1'
  // eslint-disable-next-line max-len
  ? defineAsyncComponent(() => import('web/src/modules/push-notifications/components/WebPushModal/WebPushModalRouteComponent.vue'))
  : VEmpty;

const { isModalVisible } = usePostponedAppController();

onMounted(() => {
  if ('1' && !process.env.VUE_APP_PRERENDER) {
    void startAppSw();
  }
});
</script>

<template>
  <PushModalComponent v-auto-id="'PostponedAppController'" v-if="isModalVisible" />
</template>
