<script lang="ts" setup>
import { Tag } from '@leon-hub/tags';

import VDynamicContent from 'web/src/components/DynamicContent/VDynamicContent/VDynamicContent.vue';
import VImage from 'web/src/components/Image/VImage/VImage.vue';
import VBadge from 'web/src/components/Badge/VBadge/VBadge.vue';
import type {
  CasinoSearchItemEmits,
  CasinoSearchItemProps,
} from 'web/src/modules/search/submodules/casino/components/CasinoSearchItem/types';
import { useCasinoSearchItem } from 'web/src/modules/search/submodules/casino/components/CasinoSearchItem/composables';
import { getHighlightedText } from 'web/src/modules/search/submodules/casino/components/CasinoSearchItem/composables/utils';

const props = withDefaults(defineProps<CasinoSearchItemProps>(), {
  name: '',
  group: '',
  searchText: '',
  href: '',
  ntBadge: '',
  categories: () => ([]),
});

const emits = defineEmits<CasinoSearchItemEmits>();

const {
  onClick,
  imageProps,
  ntBadgeProperties,
  formattedName,
  formattedGroup,
  formattedCategories,
} = useCasinoSearchItem(props, emits);
</script>

<template>
  <!-- //NOSONAR -->
  <li v-auto-id="'CasinoSearchItem'" :class="$style['slots-search-item']">
    <a
      :class="$style['slots-search-item__link']"
      :href="href"
      @click.prevent="onClick"
    >
      <div :class="$style['slots-search-item__image-holder']">
        <VImage
          v-bind="imageProps"
          :class="$style['slots-search-item__image']"
        />
        <VBadge
          v-if="ntBadge"
          v-bind="ntBadgeProperties"
          :class="$style['slots-search-item__nt-badge']"
        />
      </div>
      <div :class="$style['slots-search-item__info']">
        <VDynamicContent
          :content="formattedName"
          :tag="Tag.DIV"
          :class="$style['slots-search-item__name']"
        />
        <div :class="$style['slots-search-item__categories']">
          <VDynamicContent
            :content="formattedGroup"
            :tag="Tag.SPAN"
            :class="$style['slots-search-item__categories-item--group']"
          />
          <VDynamicContent
            v-for="category in formattedCategories"
            :key="category"
            :content="getHighlightedText(`&nbsp;•&nbsp;${category}`, searchText)"
            :tag="Tag.SPAN"
          />
        </div>
      </div>
    </a>
  </li>
</template>

<style lang="scss" module>
@include for-layout using($isDesktop) {
  .slots-search-item {
    list-style: none;

    &__link {
      display: flex;
      align-items: center;
      padding: 8px;
      text-decoration: none;
      background: var(--Layer1);
      border-radius: $casinoSearchItemImageRadius;

      @if $isDesktop {
        &:hover {
          background: var(--Highlight);
        }
      }
    }

    &__image-holder {
      position: relative;
      align-self: flex-start;
      width: $casinoSearchItemImageSize;
      min-width: $casinoSearchItemImageSize;
      height: $casinoSearchItemImageSize;
    }

    &__nt-badge {
      position: absolute;
      top: 5px;
      right: 5px;
    }

    &__image {
      width: 100%;
      height: 100%;
      overflow: hidden;
      border-radius: $casinoSearchItemImageRadius;
    }

    &__info {
      padding-left: 16px;
    }

    &__name {
      @include casinoSearchItemNameFont;

      margin-bottom: 4px;
      color: $casinoSearchItemNameColor;
    }

    &__categories {
      @include casinoSearchItemCategoriesFont;

      display: flex;
      flex-wrap: wrap;
      color: $casinoSearchItemCategoriesColor;
    }

    &__categories-item {
      &--group {
        color: $casinoSearchItemGroupColor;
      }
    }
  }
}
</style>
