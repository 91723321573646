import { LobbyItemType } from '@leon-hub/api-sdk';

import type {
  LobbyItemSdk,
  LobbySportlineTopMatchItem,
} from 'web/src/modules/lobby/types';

export default function isLobbySportlineTopMatchItem(input: LobbyItemSdk): input is LobbySportlineTopMatchItem {
  return input.typeId === LobbyItemType.SPORTLINE_TOP_MATCHES;
}
