import type { Ref } from 'vue';
import { computed } from 'vue';

interface UseOptimisticFavoritesEventsProps {
  preparedToAdd: Ref<string[]>;
  preparedToRemove: Ref<string[]>;
  pendingToFavoritesList: Ref<string[]>;
  favoritesIdsList: Ref<Maybe<string[]>>;
}

interface UseOptimisticFavoritesEventsComposable {
  optimisticFavoritesSportEventsIdsList: Ref<string[]>;
}

export function useOptimisticFavoritesEvents(
  props: UseOptimisticFavoritesEventsProps,
): UseOptimisticFavoritesEventsComposable {
  const {
    preparedToAdd,
    preparedToRemove,
    pendingToFavoritesList,
    favoritesIdsList,
  } = props;

  const optimisticFavoritesSportEventsIdsList = computed<string[]>(() => {
    const toRemove = preparedToRemove.value;

    return [
      ...(favoritesIdsList.value || []),
      ...preparedToAdd.value,
      ...pendingToFavoritesList.value,
    ]
      .filter((id) => !toRemove.includes(id));
  });

  return {
    optimisticFavoritesSportEventsIdsList,
  };
}
