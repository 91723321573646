import { onActivated, onBeforeUnmount, onDeactivated } from 'vue';
import debounce from 'lodash/debounce';
import type {
  DebounceSettingsLeading, DebouncedFunc, DebounceSettings, DebouncedFuncLeading,
} from 'lodash';

import type { Optional } from '@leon-hub/types';

type UnknownFunction = (...args: unknown[]) => unknown;

export function useDebounce<
  T extends UnknownFunction,
>(func: T, wait?: number, options?: DebounceSettingsLeading): Awaited<DebouncedFuncLeading<T>>;

export function useDebounce<
  T extends UnknownFunction,
>(func: T, wait?: number, options?: DebounceSettings): Awaited<DebouncedFunc<T>>;

export function useDebounce<
  T extends UnknownFunction,
>(
  func: T,
  wait?: number,
  options?: DebounceSettings | DebounceSettingsLeading,
): Awaited<DebouncedFuncLeading<T> | DebouncedFunc<T>> {
  let debouncedFn: Optional<DebouncedFuncLeading<T> | DebouncedFunc<T>> = debounce(func, wait, options);
  const cancel: () => void = () => debouncedFn?.cancel();
  const flush: () => Optional<ReturnType<T>> = () => debouncedFn?.flush();

  function pause() {
    cancel();
    debouncedFn = undefined;
  }

  function resume() {
    // debounced func was cancelled during onDeactivated hook, so we need to debounce it once again.
    debouncedFn = debounce(func, wait, options);
  }

  onBeforeUnmount(pause);
  onDeactivated(pause);
  onActivated(resume);

  const wrapper = function debouncedWrapper(this: ThisType<T>, ...args: Parameters<T>) {
    if (debouncedFn) {
      return debouncedFn.apply(this, args);
    }
    return undefined;
  } as DebouncedFuncLeading<T> | DebouncedFunc<T>;

  wrapper.cancel = cancel;
  wrapper.flush = flush;

  return wrapper;
}
