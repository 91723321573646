import { isLocalStorageAvailable } from '@leon-hub/local-storage';

import { getValidationControlKey } from './getValidationControlKey';

const key = getValidationControlKey();

export function disableFormValidation(): void {
  if (isLocalStorageAvailable()) {
    localStorage.setItem(key, '1');
  }
}
