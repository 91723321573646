import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import { IconName, IconSize } from '@leon-hub/icons';

import type { VIconProps } from '@components/v-icon';

import type {
  CasinoProviderSelectEmits,
  CasinoProviderSelectProps,
} from 'web/src/modules/casino/submodules/lobby/components/CasinoProviderSelect/types';

interface CasinoProviderSelectComposable {
  iconProperties: ComputedRef<VIconProps>;
  emitClick: (evt: MouseEvent) => void;
}

export default function useCasinoProviderSelect(
  props: CasinoProviderSelectProps,
  emits: CasinoProviderSelectEmits,
): CasinoProviderSelectComposable {
  const iconProperties = computed<VIconProps>(() => {
    const name = IconName.FILTER;
    if (process.env.VUE_APP_SKIN_TWIN && props.hasSelectedGroup) {
      return {
        size: IconSize.SIZE_16,
        name,
      };
    }
    if (props.inSidebar) {
      return {
        size: IconSize.SIZE_20,
        name,
      };
    }
    return {
      size: IconSize.SIZE_24,
      name,
    };
  });

  function emitClick(evt: MouseEvent): void {
    emits('click', evt);
  }

  return {
    iconProperties,
    emitClick,
  };
}
