export const EMPTY_PLACEHOLDER = '_____';

export const DYNAMIC_KEYS = [
  'WEB2_UPLOAD_FILE_ERROR_ERROR',
  'WEB2_UPLOAD_FILE_ERROR_SIZE_LIMIT',
  'WEB2_UPLOAD_FILE_ERROR_MAX_FILES_LIMIT',
  'WEB2_UPLOAD_FILE_ERROR_INVALID_FORMAT',
  'WEB2_UPLOAD_FILE_ERROR_INVALID_FORMAT_SIMPLEID',
  'WEB2_UPLOAD_FILE_ERROR_TERMINATE',
  'WEB2_UPLOAD_FILE_ERROR_FILES_LIMIT_AT_ONCE',
  'WEB2_CORDOVA_THEME_AUTO',
  'WEB2_CORDOVA_THEME_DARK',
  'WEB2_CORDOVA_THEME_LIGHT',
  'WEB2_FILE_READER_ERROR',
  'WEB2_SHOW_MORE',
  'WEB2_SHOW_LESS',
];

export const REFRESHED_AFTER_LOGIN = 'refreshedAfterLogin';
