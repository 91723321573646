import type { VSelectOption } from 'web/src/components/Select';

type Options<T = {}> = T & {
  label: string;
  value: string;
};

export default function searchSelectOptions(input: Options[], searchValue = ''): VSelectOption[] {
  // return input;
  const lowerSearch = searchValue.toLowerCase();
  const regexp = new RegExp(`(${lowerSearch})`, 'gmi');
  const searchMath = ({ label }: Options) => label.toLowerCase().search(regexp);
  return input
    .filter(({ label }) => label.toLowerCase().includes(lowerSearch))
    .sort((a, b) => {
      if (searchMath(a) < searchMath(b)) {
        return -1;
      }
      if (searchMath(a) > searchMath(b)) {
        return 1;
      }
      return 0;
    });
}
