<script lang="ts" setup>
import LobbySwiper from 'web/src/modules/lobby/components/LobbySwiper/LobbySwiper.vue';
import LobbySectionHeader from 'web/src/modules/lobby/components/LobbySectionHeader/LobbySectionHeader.vue';
import LobbySectionSwiperNavigation
  from 'web/src/modules/lobby/components/LobbySectionSwiperNavigation/LobbySectionSwiperNavigation.vue';
import type { LobbySectionBaseEmits } from 'web/src/modules/lobby/types';
import { useLobbySwiperSection } from 'web/src/modules/lobby/components/LobbySwiperSection/composables';

import type {
  LobbySwiperSectionProps,
  LobbySwiperSectionRef,
  LobbySwiperSectionSlots,
} from './types';

const props = defineProps<LobbySwiperSectionProps>();
const emits = defineEmits<LobbySectionBaseEmits>();

const {
  swiper,
  lobbySwiperProps,
  headerProps,
  isHeaderVisible,
  emitActionClick,
  emitClickLobbyItem,
  emitSwiperScroll,
  resetScrollPosition,
} = useLobbySwiperSection(props, emits);

defineExpose<LobbySwiperSectionRef>({
  resetScrollPosition,
});

defineSlots<LobbySwiperSectionSlots>();
</script>

<template>
  <LobbySwiper v-auto-id="'LobbySwiperSection'"
    ref="swiper"
    v-bind="lobbySwiperProps"
    :class="{
      [$style['lobby-swiper-section']]: true,
      [$style['lobby-swiper-section--no-background']]: noBackground,
      [$style[`lobby-swiper-section--${lobbyItemType.toLowerCase()}`]]: true,
      [$style[`lobby-swiper-section--background__${background}`]]: !noBackground && !!background,
    }"
    @swiper-scroll="emitSwiperScroll"
    @click-lobby-item="emitClickLobbyItem"
  >
    <template
      v-if="isHeaderVisible"
      #header
    >
      <LobbySectionHeader
        v-bind="headerProps"
        :no-background="!!'1' && noBackground && !isModal"
        :class="$style['lobby-swiper-section__header']"
        @action-click="emitActionClick"
      >
        <template
          v-if="$slots.headerIcon"
          #icon
        >
          <slot name="headerIcon" />
        </template>
        <template
          v-if="'1'"
          #navigation
        >
          <LobbySectionSwiperNavigation
            :is-modal="isModal"
          />
        </template>
      </LobbySectionHeader>
    </template>
  </LobbySwiper>
</template>

<style lang="scss" module>
.lobby-swiper-section {
  padding: $lobbySwiperSectionPaddingSmall;
  border: $lobbySwiperBorder;
  border-radius: $lobbySwiperSectionBorderRadius;

  @include screen-desktop-large-min {
    padding: $lobbySwiperSectionPaddingBig;
  }

  &--background {
    @each $type, $bgValue in $lobbySwiperBackgrounds {
      &__#{$type} {
        background: $bgValue;
      }
    }
  }

  &--no-background {
    padding: 0;
    margin-right: -16px;
    margin-left: -16px;
    background: transparent;
    border: none;
    border-radius: 0;

    @include screen-desktop-small-min {
      margin-right: 0;
      margin-left: 0;
    }

    @include screen-desktop-large-min {
      padding: 0;
    }
  }
}
</style>
