<script setup lang="ts">
import { toRef } from 'vue';

import { useCustomerDataStore } from 'web/src/modules/customer/store';

const customerData = useCustomerDataStore();

const fullName = toRef(customerData, 'fullName');
const login = toRef(customerData, 'login');
</script>

<template>
  <h1
    v-if="null"
    :class="$style['personal_info__title']"
  >
    {{ fullName }}
  </h1>
  <div
    v-if="null && !null"
    :class="$style['personal_info__subtitle']"
  >
    {{ `${$t('WEB2_ACCOUNT')}: ${login}` }}
  </div>
  <span
    v-if="'1'"
    :title="fullName"
    :class="$style['personal_info__title']"
  >
    {{ fullName }}
  </span>
  <span
    v-if="'1' && !null"
    :class="$style['personal_info__subtitle']"
    @click="$copyToClipboard({
      data: login,
      isProfileModalTooltip: !!'1',
    })"
  >
    {{ `${$t('WEB2_ACCOUNT')}: ${login}` }}
  </span>
</template>

<style lang="scss" module>
.personal_info__title {
  @include personalInfoTitleFont;

  margin: $personalInfoTitleMargin;
  overflow: hidden;
  color: var(--TextDefault);
  text-overflow: ellipsis;
  white-space: nowrap;

  @include is-app-layout-desktop {
    max-width: 155px;
  }
}

.personal_info__subtitle {
  @include medium\12\16;

  color: var(--TextSecondary);

  @include is-app-layout-desktop {
    cursor: pointer;
  }
}
</style>
