import { CustomerConfig } from '@leon-hub/api-sdk';

import { OddsType } from '../enums';

export function getStringValueFromOddTypeConfig(customerConfigOddsType: CustomerConfig = CustomerConfig.IS_ODDS_TYPE_CLASSIC): OddsType {
  switch (customerConfigOddsType) {
    case CustomerConfig.IS_ODDS_TYPE_AMERICAN:
      return OddsType.AMERICA;
    case CustomerConfig.IS_ODDS_TYPE_CLASSIC:
    default:
      return OddsType.CLASSIC;
  }
}
