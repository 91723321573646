import { IconName, IconSize } from '@leon-hub/icons';

import type { VIconProps } from '@components/v-icon';

export interface ReferralProgramPopupRewardComposable {
  iconProps: VIconProps;
}
export default function useReferralProgramPopupReward(): ReferralProgramPopupRewardComposable {
  const iconProps: VIconProps = {
    size: IconSize.SIZE_40,
    name: IconName.PROMOS,
  };

  return {
    iconProps,
  };
}
