import DateTime from 'web/src/utils/DateTime';

export default class DateTimeInterval {
  protected static getNow(): number {
    return Date.now();
  }

  static fromNowPlusHours(count = 1): DateTimeInterval {
    const now = DateTimeInterval.getNow();
    const oneHour = (60 * 60 * 1000);

    return new DateTimeInterval(now, now + oneHour * count);
  }

  static fromNowUntilTheEndOfTheDay(): DateTimeInterval {
    const now = DateTimeInterval.getNow();
    const from = new Date(now);
    const end = new Date(now);

    end.setHours(23, 59, 59, 999);

    return new DateTimeInterval(from.getTime(), end.getTime());
  }

  static today(): DateTimeInterval {
    const now = DateTimeInterval.getNow();
    const from = new Date(now);
    const end = new Date(now);

    from.setHours(0, 0, 0, 0);
    end.setHours(23, 59, 59, 999);

    return new DateTimeInterval(from.getTime(), end.getTime());
  }

  static tomorrow(): DateTimeInterval {
    const todayInterval = DateTimeInterval.today();
    const oneDay = (60 * 60 * 24 * 1000);

    return new DateTimeInterval(
      todayInterval.from.toTimestamp() + oneDay,
      todayInterval.to.toTimestamp() + oneDay,
    );
  }

  static afterTomorrow(): DateTimeInterval {
    const todayInterval = DateTimeInterval.today();
    const oneDay = (60 * 60 * 24 * 1000);

    return new DateTimeInterval(
      todayInterval.from.toTimestamp() + oneDay * 2,
      todayInterval.to.toTimestamp() + oneDay * 2,
    );
  }

  readonly from: DateTime;

  readonly to: DateTime;

  constructor(from: number | string | DateTime, to: number | string | DateTime) {
    this.from = typeof from === 'number' || typeof from === 'string'
      ? DateTime.withTimeStamp(from)
      : from;

    this.to = typeof to === 'number' || typeof to === 'string'
      ? DateTime.withTimeStamp(to)
      : to;
  }

  toObject(): { from: number; to: number } {
    return {
      from: this.from.toTimestamp(),
      to: this.to.toTimestamp(),
    };
  }
}
