import { LimitName } from 'web/src/modules/profile/submodules/responsible-gambling/enums';

export function getLimitPeriod(limit: LimitName): string {
  switch (limit) {
    case LimitName.DAILY:
      return 'dailyLimit';
    case LimitName.WEEKLY:
      return 'weeklyLimit';
    default:
      return 'monthlyLimit';
  }
}
