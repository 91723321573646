import { CordovaWebSocket } from '@leon-hub/cordova';
import { assert } from '@leon-hub/guards';
import { logger } from '@leon-hub/logging';
import { Timer } from '@leon-hub/utils';
import { isHttps, getLocationHost } from '@leon-hub/service-locator-env';

import {
  isWebSocketData,
  isWSSupported,
} from '../helpers';
import { WebSocketMessageType } from '../enums';
import type {
  WebSocketData,
  WebSocketDataConnectionAck,
  WebSocketDataConnectionError,
  WebSocketDataMessageComplete,
  WebSocketDataMessageData,
  WebSocketDataMessageError,
  WebSocketHolderOptions,
  WsSubscription,
  WsSubscriptionInput,
} from '../types';

export default class WebSocketHolder {
  private socket: WebSocket | null = null;

  private subscriptions: Record<string, WsSubscription> = {};

  private options: WebSocketHolderOptions;

  private isConnectionAck = false;

  private keepAliveDate = 0;

  private keepAliveInterval = 0;

  private serverKeepAliveTimeout = 20_000;

  private sessionExpiresAt = 0;

  private isReconnected = false;

  private isDisconnected = false;

  private openSocketTimeout = 10_000;

  private checkOpenedConnectionTimer = 0;

  private readonly host: string;

  private readonly isHttps: boolean;

  constructor(options: WebSocketHolderOptions) {
    this.host = getLocationHost();
    this.isHttps = isHttps();

    this.options = options;
    this.connect();
  }

  connect(): void {
    if (!isWSSupported()) {
      return;
    }

    this.initConnection();
  }

  private initConnection(): void {
    if (!this.socket) {
      try {
        const wsUrl = `ws${this.isHttps ? 's' : ''}://${this.host}${process.env.VUE_APP_WEB_SOCKET_PUBLIC_URL}`;

        if (process.env.VUE_APP_PLATFORM_CORDOVA) {
          const options = { timeout: this.openSocketTimeout };
          this.socket = new CordovaWebSocket(wsUrl, options);
        } else {
          this.socket = new WebSocket(wsUrl);
        }

        this.checkOpenedConnectionTimer = Timer.setTimeout(() => {
          assert(this.socket);
          if (this.socket.readyState === WebSocket.CONNECTING) {
            this.reconnect();
          }
          this.checkOpenedConnectionTimer = 0;
        }, this.openSocketTimeout);

        const { socket } = this;
        assert(socket);
        socket.addEventListener('open', () => {
          this.sendEvent({
            type: WebSocketMessageType.GQL_CONNECTION_INIT,
            payload: {},
          });
        });

        socket.addEventListener('message', this.onSocketMessage.bind(this));

        socket.addEventListener('close', (event) => {
          if (event.code !== 1000) {
            logger.warn(`[WebSocket] connection closed: ${JSON.stringify(event)}`);
            this.reconnect();
          }
        });

        socket.addEventListener('error', (event) => {
          logger.warn(`[WebSocket] connection error: ${JSON.stringify(event)}`);
          this.isReconnected = false;
          this.isConnectionAck = false;
          this.reconnect();
        });
      } catch (error) {
        logger.error('[WebSocket] connection error', error);
        this.reconnect();
      }
    }
  }

  subscribe({
    id,
    query,
    variables,
    onMessage,
    onError,
    onComplete,
  }: WsSubscriptionInput): void {
    if (!isWSSupported()) {
      return;
    }

    if (this.subscriptions[id]) {
      return;
    }

    const subscription: WsSubscription = {
      id,
      isSubscribed: false,
      query,
      variables,
      onMessage,
      onError,
      onComplete,
    };

    this.subscriptions[id] = subscription;
    this.applySubscription(subscription);
  }

  unsubscribe({ id }: { id: string }): void {
    if (this.subscriptions[id]) {
      if (this.subscriptions[id].isSubscribed) {
        this.sendEvent({
          id,
          type: WebSocketMessageType.GQL_STOP,
        });
      }

      delete this.subscriptions[id];
    }
  }

  async disconnect(): Promise<void> {
    if (this.isDisconnected) {
      return;
    }
    if (this.socket) {
      if (this.isConnectionAck) {
        for (const subscription of Object.values(this.subscriptions)) {
          assert(subscription);
          if (subscription.isSubscribed) {
            this.sendEvent({
              id: subscription.id,
              type: WebSocketMessageType.GQL_STOP,
            });
            // eslint-disable-next-line no-param-reassign
            subscription.isSubscribed = false;
          }
        }

        this.sendEvent({
          type: WebSocketMessageType.GQL_CONNECTION_TERMINATE,
        });
      }

      this.socket?.close();
      await this.waitSocketClose();
      this.socket = null;
    }
    this.clearCheckOpenedConnectionTimer();
    this.stopKeepAliveCheck();
    this.keepAliveDate = 0;
    this.sessionExpiresAt = 0;
    this.options.onDisconnect();
    this.isDisconnected = true;
  }

  private clearCheckOpenedConnectionTimer() {
    if (this.checkOpenedConnectionTimer) {
      Timer.clearTimeout(this.checkOpenedConnectionTimer);
      this.checkOpenedConnectionTimer = 0;
    }
  }

  private sendEvent(data: WebSocketData) {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(JSON.stringify(data));
      if (data.type === WebSocketMessageType.GQL_CONNECTION_TERMINATE) {
        this.socket.close();
        this.socket = null;
      }
    }
  }

  private waitSocketClose() {
    return new Promise<void>((resolve) => {
      // eslint-disable-next-line unicorn/consistent-function-scoping
      const isConnectionClosed = () => !this.socket || this.socket.readyState === WebSocket.CLOSED;

      if (isConnectionClosed()) {
        resolve();
        return;
      }

      const waitIsConnectionClosed = () => {
        Timer.setTimeout(() => {
          if (isConnectionClosed()) {
            resolve();
          } else {
            waitIsConnectionClosed();
          }
        }, 5);
      };

      waitIsConnectionClosed();
    });
  }

  private onSocketMessage(event: MessageEvent<string>) {
    const data = JSON.parse(event.data);
    assert(isWebSocketData(data), 'Unexpected socket message data');
    switch (data.type) {
      case WebSocketMessageType.GQL_CONNECTION_ACK:
        this.onConnectionAck(data);
        break;
      case WebSocketMessageType.GQL_CONNECTION_KEEP_ALIVE:
        this.onKeepAlive();
        break;
      case WebSocketMessageType.GQL_DATA:
        this.onMessageData(data);
        break;
      case WebSocketMessageType.GQL_ERROR:
        this.onMessageError(data);
        break;
      case WebSocketMessageType.GQL_COMPLETE:
        this.onMessageComplete(data);
        break;
      case WebSocketMessageType.GQL_CONNECTION_ERROR:
        this.onServerConnectionError(data);
        break;
      case WebSocketMessageType.GQL_RECONNECT:
        void this.reconnect();
        break;
      default:
        break;
    }
  }

  private startKeepAliveCheck() {
    this.stopKeepAliveCheck();
    this.keepAliveInterval = Timer.setInterval(this.pingKeepAlive.bind(this), 1000);
  }

  private stopKeepAliveCheck() {
    if (this.keepAliveInterval) {
      Timer.clearInterval(this.keepAliveInterval);
      this.keepAliveInterval = 0;
    }
  }

  private pingKeepAlive() {
    const serverKANotReceived = this.keepAliveDate > 0 && this.keepAliveDate < Date.now() - this.serverKeepAliveTimeout;
    const clientKAExpired = Date.now() > this.sessionExpiresAt;

    if (serverKANotReceived || clientKAExpired) {
      this.reconnect();
    }
  }

  private onKeepAlive() {
    this.keepAliveDate = Date.now();
  }

  private onConnectionAck(data: WebSocketDataConnectionAck) {
    this.sessionExpiresAt = new Date(data.payload.expiresAt).getTime();
    this.isConnectionAck = true;
    this.clearCheckOpenedConnectionTimer();
    for (const subscription of Object.values(this.subscriptions)) {
      this.applySubscription(subscription);
    }
    this.startKeepAliveCheck();
    this.options.onConnectionAck();
  }

  private onMessageData(data: WebSocketDataMessageData) {
    if (this.subscriptions[data.id]) {
      const subscription = this.subscriptions[data.id];
      assert(subscription);
      if (subscription.onMessage) {
        subscription.onMessage(data.payload.data);
      }
    }
  }

  private onMessageError(data: WebSocketDataMessageError) {
    if (this.subscriptions[data.id]) {
      const subscription = this.subscriptions[data.id];
      assert(subscription);
      if (subscription.onError) {
        subscription.onError(new Error(data.payload.message));
      }
    }
  }

  private onMessageComplete(data: WebSocketDataMessageComplete) {
    if (this.subscriptions[data.id]) {
      const subscription = this.subscriptions[data.id];
      assert(subscription);
      if (subscription.onComplete) {
        subscription.onComplete();
      }
    }
  }

  private onServerConnectionError(data: WebSocketDataConnectionError) {
    logger.error('[WebSocket] server connection error', data.payload);
    this.reconnect();
  }

  private applySubscription(subscription: WsSubscription) {
    if (this.isConnectionAck) {
      assert(this.socket);
      // eslint-disable-next-line no-param-reassign
      subscription.isSubscribed = true;
      this.sendEvent({
        id: subscription.id,
        type: WebSocketMessageType.GQL_START,
        payload: {
          query: subscription.query,
          variables: subscription.variables,
        },
      });
    }
  }

  async reconnect(): Promise<void> {
    if (this.isReconnected) {
      return;
    }

    if (!this.isConnectionAck) {
      await this.disconnect();
    }
    this.options.onReconnect();
    this.isReconnected = true;
  }

  isAckReceived(): boolean {
    return this.isConnectionAck;
  }
}
