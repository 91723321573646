import {
  isOptionalString,
  isObject,
} from '@leon-hub/guards';

import type { InputHintProps } from '../types/InputHintProps';
import { isOptionalHintLinkOrNull } from './isHintLink';

export default function isInputHintProps(value: unknown): value is InputHintProps {
  if (!isObject(value)) {
    return false;
  }
  return isOptionalString(value.error)
    && isOptionalString(value.errorRight)
    && isOptionalString(value.hint)
    && isOptionalString(value.hintRight)
    && isOptionalHintLinkOrNull(value.hintLink)
    && isOptionalHintLinkOrNull(value.hintLinkRight);
}
