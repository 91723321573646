<script setup lang="ts">
import { toRef, computed } from 'vue';

import type { SportlineEvent } from 'web/src/modules/sportline/types';
import { ProgressKind, ProgressHeight } from 'web/src/components/LinearProgress/VLinearProgress/enums';
import VLinearProgress from 'web/src/components/LinearProgress/VLinearProgress/VLinearProgress.vue';
import { filterMainPeriods } from 'web/src/modules/sportline/utils/timeline';

import { getMatchPercentageByTimes } from './utils';

interface SportlineLiveEventProgressProps {
  sportlineEvent: SportlineEvent;
  progressKind?: ProgressKind;
}

const props = defineProps<SportlineLiveEventProgressProps>();

const sportlineEvent = toRef(props, 'sportlineEvent');
const progressKind = toRef(props, 'progressKind', ProgressKind.WHITE);

const periods = computed(() => filterMainPeriods(getMatchPercentageByTimes({
  sportlineEvent: sportlineEvent.value,
})));
</script>

<template>
  <div v-auto-id="'SportlineLiveEventProgress'" :class="$style['sportline-live-event-progress']">
    <VLinearProgress
      v-for="period in periods"
      :key="`${period.type}_${period.number}`"
      :height="ProgressHeight.TINY_EXTRA"
      :kind="progressKind"
      :value="period.percentage"
      :class="[$style['sportline-live-event-progress__bar']]"
    />
  </div>
</template>

<style lang="scss" module>
@include for-layout using ($isDesktop) {
  .sportline-live-event-progress {
    display: flex;
    gap: 8px;
    justify-content: space-between;

    &__bar {
      position: relative;
      flex-basis: 100%;
      height: 2px;
    }
  }
}
</style>
