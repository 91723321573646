import type { Ref } from 'vue';
import { computed, toRef } from 'vue';
import { useRouter } from 'vue-router';

import { RouteName } from '@leon-hub/routing-config-names';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';

import type { ReferralTopBarProps } from 'web/src/modules/referral-program/layouts/types';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useReferralProgramStore } from 'web/src/modules/referral-program/store';
import { ReferralProgramViewMode } from 'web/src/modules/referral-program/types/ReferralProgramDocuments';
import { useAnalytics } from 'web/src/modules/analytics/composables';

export interface ReferralTopBarComposable {
  computedTitle: Ref<string>;
  viewMode: Ref<ReferralProgramViewMode>;
  showPrefix: Ref<boolean>;
  onBackClick: () => void;
  onClose: () => void;
  slotName: Ref<Maybe<string>>;
  goToTerms(): void;
}

export default function useReferralTopBar(props: ReferralTopBarProps): ReferralTopBarComposable {
  const analytics = useAnalytics();
  const { $translate } = useI18n();
  const referralStore = useReferralProgramStore();
  const router = useRouter();
  const viewMode = toRef(referralStore, 'viewMode');
  const isAccessGranted = toRef(referralStore, 'isAccessGranted');

  function onBackClick(): void {
    if (viewMode.value === ReferralProgramViewMode.DETAILS) {
      referralStore.setViewMode(ReferralProgramViewMode.LIST);
    // eslint-disable-next-line max-len
    } else if (viewMode.value === ReferralProgramViewMode.LIST || viewMode.value === ReferralProgramViewMode.LIST_BONUSES) {
      referralStore.setViewMode(ReferralProgramViewMode.MAIN);
    } else {
      router.back();
    }
  }

  const slotName = computed(() => {
    if (process.env.VUE_APP_LAYOUT_PHONE && !isAccessGranted.value) {
      return 'suffix';
    }
    if (process.env.VUE_APP_LAYOUT_DESKTOP && !isAccessGranted.value) {
      return 'prefix';
    }
    return null;
  });

  function goToTerms(): void {
    void router.push({ name: RouteName.REFERRAL_PROGRAM_INFO });
    analytics.push(AnalyticsEvent.Z_REFER_FRIEND, {
      referFriendProgram: {
        noAccessPage: {
          info: 'infoButton',
        },
      },
    });
  }

  function onClose(): void {
    if (process.env.VUE_APP_LAYOUT_PHONE) {
      router.back();
      return;
    }
    void router.closeModal();
  }

  const showPrefix = computed(() => {
    if (process.env.VUE_APP_LAYOUT_DESKTOP) {
      return viewMode.value !== ReferralProgramViewMode.MAIN;
    }
    return true;
  });

  return {
    viewMode,
    onBackClick,
    onClose,
    showPrefix,
    slotName,
    goToTerms,
    computedTitle: computed(() => {
      if (viewMode.value === ReferralProgramViewMode.LIST || viewMode.value === ReferralProgramViewMode.LIST_BONUSES) {
        return $translate('WEB2_REFERRAL_INVITED_FRIENDS').value;
      }
      if (viewMode.value === ReferralProgramViewMode.DETAILS) {
        return '';
      }
      return props.title;
    }),
  };
}
