import { Storage } from '@leon-hub/storage';

export class LexisNexisSessionStorage extends Storage<string | undefined> {
  async getSessionId(): Promise<string | undefined> {
    const record = await this.get();
    return record.exists ? record.value : undefined;
  }

  async setSessionId(value: string, options: { expires?: number } = {}): Promise<void> {
    return this.set(value, options);
  }
}
