<script lang="ts" setup>
import { IconName, IconSize } from '@leon-hub/icons';

import { VIcon } from '@components/v-icon';

import type { OddsListEmits, OddsListProps } from 'web/src/modules/sportline/submodules/odds/components/OddsList/types';

defineProps<OddsListProps>();
const emit = defineEmits<OddsListEmits>();
</script>

<template>
  <ul v-auto-id="'OddsList'" :class="$style['odds-selector']">
    <li
      v-for="item in odds"
      :key="item.id"
      :class="{
        [$style['odds-selector__item']]: true,
        [$style['odds-selector__item-active']]: item.oddType === odd,
      }"
      @click="emit('click', item.oddType)"
    >
      <span
        :class="$style['odds-selector__item-text']"
      >
        {{ item.label }}
      </span>
      <span
        v-if="item.oddType === odd"
        :class="$style['odds-selector__icon-container']"
      >
        <VIcon
          class="odds-selector__icon-selected"
          :name="IconName.CHECK_FILLED"
          :size="IconSize.SIZE_16"
        />
      </span>
    </li>
  </ul>
</template>

<style lang="scss" module>
@include for-layout using($isDesktop) {
  .odds-selector {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 0;
    margin: 0;
    list-style: none;

    &__item {
      $item: &;

      position: relative;
      display: flex;
      flex-direction: row;
      gap: 0;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 44px;
      padding: 0 12px;
      color: var(--TextPrimary);
      cursor: pointer;
      background-color: var(--Layer2);
      border: none;
      border-radius: 5px;

      &:hover:not(#{$item}-active) {
        color: var(--TextDefault);
        background-color: var(--LanguageItemHoverColor);
      }

      &-active {
        color: var(--TextDefault);
        cursor: default;
        background-color: var(--Highlight);
        border: 1px solid transparent;
      }

      &-text {
        @include medium\14\20\025;

        display: inline-flex;
        flex-grow: 1;
        padding: 0 14px 0 8px;
        user-select: none;
      }
    }

    &__icon-selected {
      color: var(--TextDefault);
    }

    &__icon-container {
      position: relative;
    }
  }
}
</style>
