import type { Ref } from 'vue';
import { computed } from 'vue';

import type { Sport, Region, League } from 'web/src/modules/sportline/types';
import type { SportEventDetails } from 'web/src/modules/sportline/submodules/event-details/types';
import type { CheckMaybe } from 'web/src/modules/sportline/types/utils';

interface UseSportlineEventDetailsPartsProps<Event extends Maybe<SportEventDetails>> {
  sportEventDetails: Ref<Event>;
}

interface UseSportlineEventDetailsPartsComposable<Event extends Maybe<SportEventDetails>> {
  sport: Ref<CheckMaybe<Event, Sport>>;
  region: Ref<CheckMaybe<Event, Region>>;
  league: Ref<CheckMaybe<Event, League>>;
}

/**
 * Extract sport, region and league from SportlineEventDetails
 * Return Maybe<Sport> for Maybe<SportEventDetails> or just Sport for SportEventDetails
 */
export function useSportlineEventDetailsParts<Event extends Maybe<SportEventDetails>>(
  props: UseSportlineEventDetailsPartsProps<Event>,
): UseSportlineEventDetailsPartsComposable<Event> {
  const { sportEventDetails } = props;

  const sport = computed(() => (
    sportEventDetails.value ? sportEventDetails.value.sport : null
  ) as CheckMaybe<Event, Sport>);

  const region = computed(() => (
    sportEventDetails.value ? sportEventDetails.value.region : null
  ) as CheckMaybe<Event, Region>);

  const league = computed(() => (
    sportEventDetails.value ? sportEventDetails.value.league : null
  ) as CheckMaybe<Event, League>);

  return {
    sport,
    region,
    league,
  };
}
