enum BaseRouteName {
  HOME = 'home',
  DEV_DASHBOARD = 'dev',
  DEFAULT = 'default',
  FULL = 'full',
  FULL_WITH_TOP_BAR = 'full-with-top-bar',
  DESKTOP_DEFAULT = 'desktop-default',
  DESKTOP_LEFT_COL = 'desktop-left-col',
  DESKTOP_RIGHT_COL = 'desktop-right-col',
  PAGES = 'pages',
  FORMS = 'forms',
  LOGIN = 'login',
  FLOW_LOGIN = 'flow-login',
  THEME_SELECT_PAGE = 'theme-select',
  THEME_SELECT_MODAL = 'theme-select-modal',
  PUSH_NOTIFICATIONS_ONBOARDING = 'push-notifications-onboarding',
  PUSH_NOTIFICATIONS_BETS_ONBOARDING = 'push-notifications-bets-onboarding',
  START_SCREEN_BONUS = 'start-screen-bonus',
  RESTORE_PASSWORD = 'restore-password',
  RESTORE_PIN = 'restore-pin',
  RESTORE_PIN_PROFILE = 'restore-pin-profile',
  PIN_RESET = 'reset-pin-code',
  RESTORE_PASSWORD_APP = 'restore-password-app',
  RESTORE_PASSWORD_ENTER_NEW_ONE = 'restore-password-enter-new-one',
  IDENTIFICATION = 'customer-identification',
  IDENTIFICATION_COMPLETE = 'identification-complete',
  PRIVACY_POLICY = 'privacy-policy',
  WITHDRAWALS = 'withdrawals',
  WITHDRAWALS_DETAILS = 'withdrawals-details',
  NUMBER_CHECK = 'number-check',
  DEPOSITS = 'deposits',
  DEPOSITS_DETAILS = 'deposits-details',
  WITHDRAWALOK = 'withdrawalok',
  WITHDRAWALOK2 = 'withdrawalok2',
  DEPOSITSOK = 'depositok',
  DEPOSITSOK2 = 'depositok2',
  DEPOSITSFAIL = 'depositsfail',
  DEPOSITSFAIL2 = 'depositsfail2',
  DEPOSITSPROCESS = 'depositsprocess',
  DEPOSITSPROCESS2 = 'depositsprocess2',
  DEPOSITS_OVERVIEW = 'deposits-overview',
  WHAT_IS_CVV_CVC_CODE = 'what-is-cvv-cvc-code',
  SUPPORT = 'support',
  FEEDBACK = 'feedback',
  FEEDBACK_LEGACY = 'feedback-legacy',
  FEEDBACK_RATING = 'feedback-rating',
  CALLBACK = 'callback',
  RESPONSIBLE_GAMBLING_BETTING_LIMITS = 'responsible-gambling-betting-limits',
  RESPONSIBLE_GAMBLING_COMPREHENSIVE_SELF_EXCLUSION = 'responsible-gambling-comprehensive-self-exclusion',
  RESPONSIBLE_GAMBLING_DEPOSIT_LIMITS = 'responsible-gambling-deposit-limits',
  RESPONSIBLE_GAMBLING_INFO = 'responsible-gambling-info',
  RESPONSIBLE_GAMBLING_LIMITS = 'responsible-gambling-limits',
  RESPONSIBLE_GAMBLING_OPTIONS = 'responsible-gambling-options',
  RESPONSIBLE_GAMBLING_PARTIAL_RESTRICTION = 'responsible-gambling-partial-restriction',
  RESPONSIBLE_GAMBLING_SELF_EXCLUSION = 'responsible-gambling-self-exclusion',
  RESPONSIBLE_GAMBLING_TEST = 'responsible-gambling-test',
  RESPONSIBLE_GAMBLING_QUIZ = 'responsible-gambling-quiz',
  RESPONSIBLE_GAMBLING_TIME_IN_GAME = 'responsible-gambling-time-in-game',
  RESPONSIBLE_GAMBLING_TIME_IN_GAME_INFO = 'responsible-gambling-time-in-game-info',
  RESPONSIBLE_GAMBLING_SESSION_LIMIT = 'responsible-gambling-session-limit',
  RESPONSIBLE_GAMBLING_DEPOSIT_LIMIT = 'responsible-gambling-deposit-limit',
  RESPONSIBLE_GAMBLING_DEPOSIT_LIMIT_INFO = 'responsible-gambling-deposit-limit-info',
  RESPONSIBLE_GAMBLING_BET_LIMIT = 'responsible-gambling-bet-limit',
  RESPONSIBLE_GAMBLING_BET_LIMIT_INFO = 'responsible-gambling-bet-limit-info',
  RESPONSIBLE_GAMBLING_BREAK = 'responsible-gambling-break',
  RESPONSIBLE_GAMBLING_EXCLUSION = 'responsible-gambling-exclusion',
  RESPONSIBLE_GAMBLING_EXCLUSION_INFO = 'responsible-gambling-exclusion-info',
  CUSTOMER_NOTIFICATIONS = 'customer-notifications',
  CUSTOMER_NOTIFICATION_DETAILS = 'customer-notification-details',
  CUSTOMER_IDENTIFICATION_STATUS = 'customer-identification-status',
  FAQ = 'faq',
  FAQ_DETAILS = 'faq-details',
  FAQ_CATEGORY = 'faq-category',
  FAQ_CATEGORY_ITEM = 'faq-category-item',
  PROFILE = 'profile',
  CUSTOMER_SETTINGS = 'customer-settings',
  CUSTOMER_VERIFICATION = 'customer-verification',
  CUSTOMER_VERIFICATION_SELECT_TIME = 'customer-verification-select-time',
  CUSTOMER_VERIFICATION_CALL = 'customer-verification-call',
  HELP = 'help',
  SEARCH = 'search',
  TWO_FACTOR_AUTHENTICATION = 'two-factor-authentication',
  TWO_FACTOR_AUTHENTICATION_REGENERATE = 'two-factor-authentication-regenerate',
  TWO_FACTOR_AUTHENTICATION_LOGIN = 'two-factor-authentication-login',
  ERROR_NOT_FOUND_404 = 'not-found',
  ERROR_FORBIDDEN_403 = 'forbidden',
  ERROR_SERVICE_UNAVAILABLE_503 = 'service-unavailable',
  REDIRECT_SEO = 'redirect-seo',
  HISTORY = 'history',
  PASSWORD_CHANGE = 'password-change',
  CUSTOMER_SUBSCRIPTIONS = 'customer-subscriptions',
  IFRAME_WIDGETS = 'iframe-widgets',
  CUSTOMER_PERSONAL_DATA = 'customer-personal-data',
  CUSTOMER_TRANSACTIONS_HISTORY = 'customer-transactions-history',
  CUSTOMER_HISTORY_TRANSACTION_DETAILS = 'customer-history-transaction-details',
  BONUS_WITHDRAWALS = 'bonus-withdrawals',
  BONUS_CODE_ENTER = 'bonus-code-enter',
  BONUSES = 'bonuses',
  FAST_GAMES = 'fastgames',
  FAST_GAMES_ITEM = 'fastgames-item',
  LOYALTY_PROGRAM = 'loyalty-program',
  SHOP_ITEM_DETAILS = 'shop-item-details',
  VERIFY_EMAIL = 'verify-email',
  VERIFY_EMAIL_STATUS_PAGE = 'verify-email-status',
  ONBOARDING = 'onboarding',
  PARTNERS = 'partners',
  PARTNER_DETAILS = 'partner-details',
  FORTUNE_WHEEL = 'fortune-wheel',
  REFERRAL_PROGRAM = 'referral-program',
  REFERRAL_PROGRAM_INFO = 'referral-program-info',
  REFERRAL_REGISTRATION = 'referral-registration',

  // AFFILIATE
  AFFILIATE_PROFILE = 'affiliate-profile',
  AFFILIATE_HISTORY_OPERATIONS = 'affiliate-history-operations',
  AFFILIATE_HISTORY_OPERATIONS_DETAILS = 'affiliate-history-operations-details',
  AFFILIATE_REGISTRATIONS = 'affiliate-registrations',
  AFFILIATE_BONUS_CODES = 'affiliate-bonus-codes',
  AFFILIATE_BONUS_CODES_HELP = 'how-to-use-bonus-codes',
  AFFILIATE_PROGRAM = 'affiliate-program',
  AFFILIATE_PROGRAM_RULES = 'affiliate-program-rules',
  AFFILIATE_LINK_HELP = 'how-to-use-affiliate-link',
  AFFILIATE_PROFILE_MIGRATED = 'affiliate-profile-migrated',

  // CMS
  CMS = 'cms',
  CMS_SPORT_BETTING_RULES = 'SPORT_BETTING_RULES_WEB2',
  CMS_PROMO_TERMS = 'PROMO_TERMS_WEB2',
  CMS_DATA_PROCESSING_AGREEMENT = 'DATA_PROCESSING_AGREEMENT_WEB2',
  CMS_BETTING_PARTICIPATION_AGREEMENT = 'BETTING_PARTICIPATION_AGREEMENT_WEB2',
  CMS_VERIFICATION_REQUIREMENTS = 'CMS_VERIFICATION_REQUIREMENTS',

  // From SCG
  ABOUT_COMPANY_WEB2 = 'ABOUT_COMPANY_WEB2',
  AFFILIATE_PROGRAM_RULES_WEB2 = 'AFFILIATE_PROGRAM_RULES_WEB2',
  BETTING_TERMS_WEB2 = 'BETTING_TERMS_WEB2',
  BONUS_PROGRAM_CONDITIONS_WEB2 = 'BONUS_PROGRAM_CONDITIONS_WEB2',
  CMS_BONUS_WALLET_ATTACHMENTS = 'CMS_BONUS_WALLET_ATTACHMENTS',
  CMS_HOW_BONUS_WALLET_WORKS = 'CMS_HOW_BONUS_WALLET_WORKS',
  CMS_N_PLUS_M = 'CMS_N_PLUS_M',
  RESPONSIBLE_GAMBLING_WEB2 = 'RESPONSIBLE_GAMBLING_WEB2',
  RESPONSIBLE_GAMBLING_WEB2_MODAL = 'RESPONSIBLE_GAMBLING_WEB2_MODAL',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  SPORT_BETTING_RULES_WEB2 = 'SPORT_BETTING_RULES_WEB2',
  TELEGRAM_BOT = 'telegram-bot',

  // LEONRU
  CMS_HOW_TO_BET = 'HOW_TO_BET_WEB2',
  CMS_LEGAL_DOCUMENTS = 'LEGAL_DOCUMENTS_WEB2',

  // SLOTT
  CMS_SECURITY_AND_PRIVACY = 'SECURITY_AND_PRIVACY_WEB2',

  CONTACTS = 'contacts',

  // ACCESS OPTIONS
  ACCESS_OPTIONS = 'access-options',
  ACCESS_OPTIONS_PERSONAL_LINK = 'access-options-personal-link',
  ACCESS_OPTIONS_ANDROID_APP = 'access-options-android-app',
  ACCESS_OPTIONS_TELEGRAM_CHANNEL = 'access-options-telegram-channel',
  ACCESS_OPTIONS_ADD_BOOKMARK = 'access-options-add-bookmark',
  ACCESS_OPTIONS_CALLBACK = 'access-options-callback',
  WEBSITE_ACCESS = 'website-access',

  // RULES
  RULES = 'rules',

  // DEV
  DEVTOOLS = 'dev-tools',

  // Full Registration
  REGISTRATION = 'registration',

  UNTRUSTED_DEVICE = 'untrusted-device',

  // Sportradar widgets
  SPORTRADAR_STATISTICS_WIDGET = 'sportradar-statistics-widget',
  SPORTRADAR_LIVESCORE_WIDGET = 'sportradar-livescore-widget',

  // Landings
  ANDROID = 'android',
  COUPONS = 'my-coupons',
  EURO_2024 = 'euro-2024',
  EURO_2024_BATTLE_PASS = 'euro-2024-battle-pass',
  EURO_2024_BONUS_INFO = 'euro-2024-bonus-info',
  VIP = 'vip',
  VIP_EXPRESS = 'vip-express',
  BONUS_CALENDAR = 'bonus-calendar',
  BONUS_CALENDAR_PRIZES = 'bonus-calendar-prizes',

  // Language
  LANGUAGE = 'language',

  // Pin code
  PIN_CODE = 'pin-code',
  PIN_CODE_PROFILE = 'pin-code-profile',

  // REFERRAL PROGRAM
  REFERRAL_PROGRAM_AGREEMENT = 'referral-program-agreement',
}

export default BaseRouteName;
