import type {
  CoreRegionResponse,
  CoreRegionReferenceResponse,
} from 'web/src/modules/sportline/types/rest';
import { isFullCoreRegionResponse } from 'web/src/modules/sportline/guards/rest';

export function assertFullCoreRegionResponse(
  response: CoreRegionResponse | CoreRegionReferenceResponse,
): CoreRegionResponse {
  if (!isFullCoreRegionResponse(response)) {
    throw new Error('Region assert failed');
  }

  return response;
}
