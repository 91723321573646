import { getCordovaAppConfig } from '@leon-hub/cordova';

export default function resolveApi3Url(baseUrl = ''): string | undefined {
  if (process.env.VUE_APP_PLATFORM === 'cordova') {
    // must be initialized in cordova/src/config.js
    const { appOrigin, api3PublicUrl } = getCordovaAppConfig();
    return `${baseUrl || appOrigin}${api3PublicUrl}`;
  }

  if (process.env.VUE_APP_RENDERING === 'ssr') {
    return process.env.VUE_APP_API_3_INTERNAL_URL;
  }

  return process.env.VUE_APP_API_3_PUBLIC_URL;
}
