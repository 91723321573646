<script lang="ts" setup>
import type {
  CasinoSearchTabsSwitcherEmits,
  CasinoSearchTabsSwitcherProps,
} from 'web/src/modules/search/submodules/casino/components/CasinoSearchTabsSwitcher/types';
import {
  useCasinoSearchTabsSwitcher,
} from 'web/src/modules/search/submodules/casino/components/CasinoSearchTabsSwitcher/composables';
import VSwitcher from 'web/src/components/Switcher/VSwitcher/VSwitcher.vue';

defineProps<CasinoSearchTabsSwitcherProps>();
const emits = defineEmits<CasinoSearchTabsSwitcherEmits>();

const {
  switcherOptions,
  emitChange,
} = useCasinoSearchTabsSwitcher(emits);
</script>

<template>
  <VSwitcher v-auto-id="'CasinoSearchTabsSwitcher'"
    :options="switcherOptions"
    :active-id="activeTab"
    @change="emitChange"
  />
</template>
