<script lang="ts" setup>
import {
  ButtonHeight,
  ButtonKind,
} from '@leon-hub/module-buttons';
import { IconName } from '@leon-hub/icons';

import VButton from 'web/src/components/Button/VButton/VButton.vue';
import type {
  LobbySectionSwiperNavigationButtonsEmits,
  LobbySectionSwiperNavigationButtonsProps,
} from 'web/src/modules/lobby/components/LobbySectionSwiperNavigationButtons/types';
import SButton from 'web/src/components/Button/SButton/SButton.vue';

defineProps<LobbySectionSwiperNavigationButtonsProps>();
const emits = defineEmits<LobbySectionSwiperNavigationButtonsEmits>();
</script>

<template>
  <div v-auto-id="'LobbySectionSwiperNavigationButtons'"
    v-show="showPreviousButton || showNextButton"
    :class="$style['lobby-section-swiper-navigation']"
  >
    <template v-if="null">
      <SButton
        kind="tertiary-secondary"
        :size="'1' && !isModal ? 'medium' : 'small'"
        :icon-name="null ? IconName.SLOTT_ANGLE_SMALL_LEFT : IconName.EXPAND_LEFT"
        :disabled="!showPreviousButton"
        :class="$style['lobby-section-swiper-navigation__button']"
        @click="emits('click-previous')"
      />
      <SButton
        kind="tertiary-secondary"
        :size="'1' && !isModal ? 'medium' : 'small'"
        :icon-name="null ? IconName.SLOTT_ANGLE_SMALL_RIGHT : IconName.EXPAND_RIGHT"
        :disabled="!showNextButton"
        :class="$style['lobby-section-swiper-navigation__button']"
        @click="emits('click-next')"
      />
    </template>
    <template v-else>
      <VButton
        :kind="ButtonKind.BASE"
        :height="ButtonHeight.MEDIUM"
        :icon-name="null ? IconName.SLOTT_ANGLE_SMALL_LEFT : IconName.EXPAND_LEFT"
        :disabled="!showPreviousButton"
        :class="$style['lobby-section-swiper-navigation__button']"
        @click="emits('click-previous')"
      />
      <VButton
        :kind="ButtonKind.BASE"
        :height="ButtonHeight.MEDIUM"
        :icon-name="IconName.EXPAND_RIGHT"
        :disabled="!showNextButton"
        :class="$style['lobby-section-swiper-navigation__button']"
        @click="emits('click-next')"
      />
    </template>
  </div>
</template>

<style lang="scss" module>
.lobby-section-swiper-navigation {
  display: flex;
  margin-left: 10px;

  &__button {
    &:last-child {
      margin-left: 4px;
    }
  }
}
</style>
