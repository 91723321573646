<script lang="ts" setup>
import { onBeforeUnmount } from 'vue';

import type { VCoreDropdownEmits, VCoreDropdownProps, VCoreDropdownSlots } from '../types';
import { useVCoreDropdown } from '../composables';
import {
  DropdownPreferredDirection,
} from '../enums/DropdownPreferredDirection';

const props = withDefaults(defineProps<VCoreDropdownProps>(), {
  dropDirection: DropdownPreferredDirection.AUTO,
});
const emit = defineEmits<VCoreDropdownEmits>();

const {
  dropdownContainer,
  style,
  onAppear,
  onDisappear,
  onContentRedraw,
  stylesIsReady,
  maxHeight,
  dropToTop,
} = useVCoreDropdown(props, emit);

onBeforeUnmount(onDisappear);

defineSlots<VCoreDropdownSlots>();
</script>

<template>
  <Teleport v-auto-id="'VCoreDropdown'"
    to="body"
  >
    <div
      ref="dropdownContainer"
      :style="!isModal ? style : undefined"
      :class="{
        [$style['dropdown']]: true,
        [$style['dropdown--hidden']]: !stylesIsReady,
        [$style['dropdown--modal']]: isModal,
        [$style['dropdown--behind-modals']]: isBehindModals,
      }"
    >
      <slot
        :max-height="maxHeight"
        :direction-top="dropToTop"
        :mounted="onAppear"
        :on-content-redraw="onContentRedraw"
      />
    </div>
  </Teleport>
</template>

<style lang="scss" module>
.dropdown {
  @include z-index(popper);

  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;

  &--hidden {
    visibility: hidden;
  }

  &--modal {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    padding: calc(16px + var(--StatusbarHeight)) 16px 16px;
  }

  &--behind-modals {
    @include coreDropdownZIndex;
  }
}
</style>
