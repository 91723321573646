import type { ComputedRef } from 'vue';
import { computed } from 'vue';
import { useRouter } from 'vue-router';

import { CasinoIconName, IconName } from '@leon-hub/icons';
import { RouteName, CasinoRouteName } from '@leon-hub/routing-config-names';
import type BaseRouteName from '@leon-hub/routing-config-names/src/BaseRouteName';

import type { CasinoLoyaltyProgramListProps, CasinoLoyaltyProgramListType } from 'web/src/modules/casino-loyalty-program/components/CasinoLoyaltyProgramList/types';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useFormatMoney } from 'web/src/modules/money/composables';
import { ListType } from 'web/src/components/List/enums';
import { useEgsNavigation } from 'web/src/modules/egs/submodules/navigation/composable';

export interface CasinoLoyaltyProgramListComposable {
  list: ComputedRef<CasinoLoyaltyProgramListType[]>;
  clickEvent: (to: BaseRouteName) => void;
  listType: ComputedRef<ListType>;
}

export default function useCasinoLoyaltyProgramList(props: CasinoLoyaltyProgramListProps):
CasinoLoyaltyProgramListComposable {
  const { $translate } = useI18n();
  const $formatMoney = useFormatMoney();
  const router = useRouter();
  const { navigateToGamePageById } = useEgsNavigation(router);
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const list: ComputedRef<CasinoLoyaltyProgramListType[]> = computed(() => {
    const result: CasinoLoyaltyProgramListType[] = [];

    if (!process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED && props.multiplier) {
      result.push({
        icon: CasinoIconName.CASINO_ROULETTE,
        label: $translate('WEB2_LOYALTY_PROGRAM_MULTIPLIER_TEXT').value,
        value: `${props.multiplier}x`,
      });
    }

    if (props.bonusAmount) {
      result.push({
        icon: process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED
          ? IconName.SLOTT_SACK_DOLLAR : IconName.COIN_USD,
        label: $translate('WEB2_LOYALTY_PROGRAM_BONUS_FOR_ACCOUNT_TEXT').value,
        value: $formatMoney(props.bonusAmount),
      });
    }

    if (props.freespin) {
      const buttonLabel = process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED
        ? $translate('WEB2_LOYALTY_PROGRAM_BUTTON_PLAY').value : $translate('WEB2_LOYALTY_PROGRAM_BONUS_OPEN_BTN').value;

      result.push({
        icon: process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED
          ? IconName.SLOTT_BULLSEYE : CasinoIconName.CASINO_SEVENS,
        label: $translate('WEB2_BONUSES_FREESPIN').value,
        value: `${props.freespin} ${$translate('WEB2_BONUSES_FREESPIN_COUNT').value}`,
        to: process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED ? undefined : RouteName.BONUSES,
        buttonLabel: props.freespinAvailable && props.freespinGameId ? buttonLabel : '',
        badgeLabel: props.freespinSpent ? $translate('WEB2_LOYALTY_PROGRAM_BONUS_RECEIVED').value : '',
      });
    }

    if (props.bonusPointsCount) {
      result.push({
        icon: process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED
          ? IconName.SLOTT_CROWN : IconName.LEONS,
        label: $translate('WEB2_LOYALTY_PROGRAM_BONUS_POINTS_TEXT').value,
        value: $formatMoney(props.bonusPointsCount, { currency: 'L' }),
      });
    }
    return result;
  });

  const listType = computed(() => {
    if (list.value.length > 1) {
      return ListType.GROUP;
    }
    return ListType.SEPARATE;
  });
  const clickEvent = (to: BaseRouteName) => {
    if (process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED && props.freespinGameId) {
      navigateToGamePageById(props.freespinGameId).catch(() => {
        void router.push({ name: CasinoRouteName.CASINO_LOBBY });
      });
    } else if (to) {
      void router.push({ name: to });
    }
  };
  return {
    list,
    clickEvent,
    listType,
  };
}
