<script lang="ts" setup>
import { onBeforeUnmount, onMounted } from 'vue';

import { VLazyIframe } from 'web/src/components/Iframe';
import { VLoaderDelayed } from 'web/src/components/Loader';

import { usePaymentsIframe } from './composables';
import type { PaymentsIframeProps } from './types';

const props = withDefaults(defineProps<PaymentsIframeProps>(), {
  redirectUrl: '',
  openIn: null,
  redirectMethod: null,
  redirectCharset: '',
  redirectFormParams: null,
  isDeposit: true,
  mobileRedirectUrl: '/',
});

const {
  showIframe,
  iframeName,
  iframeMounted,
  mounted,
  beforeUnmount,
} = usePaymentsIframe(props);

onMounted(mounted);
onBeforeUnmount(beforeUnmount);
</script>

<template>
  <VLazyIframe
    v-if="showIframe"
    :src="redirectUrl"
    :name="iframeName"
    :class="$style['payments-iframe']"
    full-size
    hide-full-height-in-url
    @vue:mounted="iframeMounted"
  />
  <VLoaderDelayed
    v-else
    :class="$style['payments-iframe-loader']"
    :hint="$t('WEB2_PLEASE_WAIT_TEXT')"
  />
</template>

<style lang="scss" module>
@include for-layout using($isDesktop) {
  .payments-iframe {
    width: 100%;
    height: if($isDesktop, auto, calc(100dvh - 60px));
    margin: 0;
    border: 0;

    &-loader {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: 140px;
    }
  }
}
</style>
