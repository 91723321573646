<script lang="ts" setup>
import { toRef } from 'vue';

import type { RegionsSegmentPageListGroup } from 'web/src/modules/sportline/submodules/segment/types';
import type { ChangeFilterPayload } from 'web/src/modules/sportline/submodules/segment/composables/types';
import type { SportlineFilterSlots } from 'web/src/modules/sportline/components/navigation-filter/types';
import SportlineFilter from 'web/src/modules/sportline/components/navigation-filter/default/SportlineFilter.vue';

import { useSportlineCybersportFilter } from '../useSportlineCybersportFilter';
import type { SportlineCybersportFilterProps, SportlineCybersportFilterEmits } from '../types';

const props = withDefaults(defineProps<SportlineCybersportFilterProps>(), {
  totalCount: 0,
  allGroup: null,
  streamGroup: null,
  favoriteGroup: null,
});
const emit = defineEmits<SportlineCybersportFilterEmits>();
defineSlots<SportlineFilterSlots>();

const {
  items,
} = useSportlineCybersportFilter({
  totalCount: toRef(props, 'totalCount'),
  allGroup: toRef(props, 'allGroup'),
  streamGroup: toRef(props, 'streamGroup'),
  favoriteGroup: toRef(props, 'favoriteGroup'),
  groups: toRef(props, 'groups'),
}, {
  changeFilter(payload: Maybe<ChangeFilterPayload<RegionsSegmentPageListGroup>>): void {
    emit('change-filter', payload);
  },
});
</script>

<template>
  <SportlineFilter v-auto-id="'SportlineCybersportFilter'" :items="items">
    <template #afterItem="{ item }">
      <slot
        name="afterItem"
        :item="item"
      />
    </template>
  </SportlineFilter>
</template>
