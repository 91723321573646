import type { RequestOptionsPriority } from '@leon-hub/api-sdk';
import { assert } from '@leon-hub/guards';
import type { Optional } from '@leon-hub/types';
import { getConflictingKeys } from '@leon-hub/utils';

import type GqlBatchedSubRequest from './GqlBatchedSubRequest';

function sortGqlSubRequests(a: GqlBatchedSubRequest, b: GqlBatchedSubRequest): number {
  // return b.getPriority() - a.getPriority()
  //       || Number(b.mutation) - Number(a.mutation)
  //       || a.created - b.created
  //       || 0;

  const aPriority = a.getPriority();
  const bPriority = b.getPriority();

  if (aPriority > bPriority) {
    return -1;
  }
  if (bPriority > aPriority) {
    return 1;
  }

  if (a.mutation && !b.mutation) {
    return -1;
  }
  if (!a.mutation && b.mutation) {
    return 1;
  }

  if (a.created < b.created) {
    return -1;
  }
  if (b.created < a.created) {
    return 1;
  }

  return 0;
}

export default class GqlBatchedRequest {
  mutations: GqlBatchedSubRequest[] = [];

  queries: GqlBatchedSubRequest[] = [];

  private created: number = Date.now();

  public readonly id = GqlBatchedRequest.createId();

  public isLocked = false;

  private static autoIncrement = 0;

  private static createId(): number {
    GqlBatchedRequest.autoIncrement += 1;
    return GqlBatchedRequest.autoIncrement;
  }

  private timeout: Optional<number>;

  public find({ id }: { id: string }): GqlBatchedSubRequest | undefined {
    return this.mutations.find((item) => item.id === id)
      ?? this.queries.find((item) => item.id === id);
  }

  private getPromises() {
    return this.getGqlSubRequests().map(({ deferred }) => deferred.promise);
  }

  public whenFulfilled(): Promise<void> {
    return Promise.allSettled(this.getPromises()).then();
  }

  public getGqlSubRequests(): GqlBatchedSubRequest[] {
    return [
      ...this.mutations,
      ...this.queries,
    ];
  }

  /**
   * Get sorted list of requests.
   * 1) Sort by priority
   * 2) Sort by query type (mutation or not)
   * 3) Sort by created.
   */
  public getSortedGqlSubRequests(): GqlBatchedSubRequest[] {
    return this.getGqlSubRequests().sort(sortGqlSubRequests);
  }

  /**
   * Сheck if all sub-requests have same priority
   */
  public hasUniformPriority(priority: RequestOptionsPriority): boolean {
    return this.getGqlSubRequests().every((subRequest) => subRequest.getPriority() === priority);
  }

  private update({
    queries,
    mutations,
  }: {
    queries?: GqlBatchedSubRequest[];
    mutations?: GqlBatchedSubRequest[];
  }) {
    this.queries = queries ?? this.queries;
    this.mutations = mutations ?? this.mutations;
  }

  /**
   * @param {number} [size=Infinity] max size of new batch.
   * @param {function} [filter] filter batch items
   */
  public getBatch(
    {
      size = Infinity,
      doExtractBatch = false,
      filter = () => true,
    }: {
      size: number;
      doExtractBatch: boolean;
      filter: (gqlSubRequest: GqlBatchedSubRequest) => boolean;
    },
  ): GqlBatchedRequest {
    const usedHeaders: Record<string, string> = {};
    let batchTimeout = 0;
    const gqlSubRequests = this.getSortedGqlSubRequests()
      .reduce<GqlBatchedSubRequest[]>((accumulator, gqlSubRequest) => {
        batchTimeout = Math.max(gqlSubRequest.getTimeout() ?? 0, batchTimeout);

        if (accumulator.length >= size) {
          return accumulator;
        }

        if (!filter(gqlSubRequest)) {
          return accumulator;
        }

        const headers = gqlSubRequest.getHeaders();
        const conflictingKeys = getConflictingKeys([usedHeaders, headers]);
        if (process.env.VUE_APP_ENV_DEV && conflictingKeys.length) {
          // eslint-disable-next-line no-console
          console.warn(
            `Skipping request <${gqlSubRequest.getName()}> for current batch because of conflicting header keys:`,
            ...conflictingKeys,
          );
        }
        Object.assign(usedHeaders, headers);

        // send subRequest with conflicting header in next batch
        if (conflictingKeys.length === 0) {
          accumulator.push(gqlSubRequest);
        }

        return accumulator;
      }, []);

    if (doExtractBatch) {
      this.update({
        mutations: this.mutations.filter((gqlSubRequest) => !gqlSubRequests.includes(gqlSubRequest)),
        queries: this.queries.filter((gqlSubRequest) => !gqlSubRequests.includes(gqlSubRequest)),
      });
    }

    const batch = new GqlBatchedRequest();
    for (const gqlSubRequest of gqlSubRequests) {
      batch.addSubRequest(gqlSubRequest);
    }

    if (batchTimeout) {
      batch.setTimeout(batchTimeout);
    }

    return batch;
  }

  public getOldestItem(): GqlBatchedSubRequest {
    const item = this.getSortedGqlSubRequests()[0];
    assert(item, 'Check batch size before calling');
    return item;
  }

  public getNewestItem(): GqlBatchedSubRequest {
    const item = this.getSortedGqlSubRequests().pop();
    assert(item, 'Check batch size before calling');
    return item;
  }

  public getMinCreated(): number {
    const item = this.getSortedGqlSubRequests()[0];
    assert(item);
    return item.created;
  }

  public getMaxCreated(): number {
    const item = this.getSortedGqlSubRequests().pop();
    assert(item);
    return item.created;
  }

  public addSubRequest<T>(gqlSubRequest: GqlBatchedSubRequest<T>): void {
    assert(!this.isLocked,
      `Batched graphql request is locked for sub-request instance <${gqlSubRequest.getName()}>`);
    assert(
      !this.getGqlSubRequests().includes(gqlSubRequest as GqlBatchedSubRequest),
      `Batched graphql request already contains same sub-request instance <${gqlSubRequest.getName()}>`,
    );

    if (gqlSubRequest.mutation) {
      this.update({
        mutations: [...this.mutations, gqlSubRequest as GqlBatchedSubRequest],
      });
    } else {
      this.update({
        queries: [...this.queries, gqlSubRequest as GqlBatchedSubRequest],
      });
    }
  }

  public findCached(gqlSubRequest: GqlBatchedSubRequest): GqlBatchedSubRequest | undefined {
    if (gqlSubRequest.mutation) {
      return this.mutations.find((cachedItem) => cachedItem.getCacheKey() === gqlSubRequest.getCacheKey());
    }
    return this.queries.find((cachedItem) => cachedItem.getCacheKey() === gqlSubRequest.getCacheKey());
  }

  public size(): number {
    return this.queries.length + this.mutations.length;
  }

  public getHeaders(): Record<string, string> {
    return this.getSortedGqlSubRequests().reduce<Record<string, string>>((accumulator, gqlSubRequest) => {
      const headers = gqlSubRequest.getHeaders();
      if (process.env.VUE_APP_ENV_DEV) {
        const conflictingHeaders = getConflictingKeys([accumulator, headers]);
        if (conflictingHeaders.length) {
          // eslint-disable-next-line no-console
          console.warn(
            `Batch has conflicting headers within request <${gqlSubRequest.getName()}>:`,
            ...conflictingHeaders,
          );
        }
      }

      return {
        ...accumulator,
        ...headers,
      };
    }, {});
  }

  setTimeout(timeout?: number): void {
    this.timeout = timeout;
  }

  getTimeout(): Optional<number> {
    return this.timeout;
  }
}
