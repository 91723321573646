<script lang="ts" setup>
import { useConnectionError } from './composables';
import BaseErrorContent from '../BaseErrorContent/BaseErrorContent.vue';

const {
  errorProps,
} = useConnectionError();
</script>

<template>
  <BaseErrorContent v-auto-id="'ConnectionError'"
    v-bind="errorProps"
  />
</template>
