<script lang="ts" setup>
import type { IconNameType } from '@leon-hub/icons';
import { IconName, IconSize, SlottIconName } from '@leon-hub/icons';

import { VIcon } from '@components/v-icon';
import type { VIconProps } from '@components/v-icon';

import {
  convertCommonClassToCssModule as toModule,
} from 'web/src/components/List/utils/convertCommonClassesToCssModule';
import PaymentsLogo from 'web/src/modules/payments/components/PaymentsLogo/PaymentsLogo.vue';
import { VCheckbox } from 'web/src/components/Checkbox';
import { useVListItemExtended } from 'web/src/components/List/VListItemExtended/composables';
import type ListItemExtendedIconBackground
  from 'web/src/components/List/VListItemExtended/enums/ListItemExtendedIconBackground';

import { ListItemExtendedKind, type ListItemExtendedSubtitleColor } from './enums';

const props = withDefaults(defineProps<VListItemExtendedProps>(), {
  kind: ListItemExtendedKind.ICON,
  iconName: IconName.STAR,
  // eslint-disable-next-line vue/no-boolean-default
  haveExpand: true,
});

const emit = defineEmits<VListItemExtendedEmits>();

interface VListItemExtendedProps {
  haveExpand?: boolean;
  iconName?: IconNameType;
  iconBackground?: ListItemExtendedIconBackground;
  isChecked?: boolean;
  isDisabled?: boolean;
  kind?: ListItemExtendedKind;
  logoName?: Maybe<string>;
  logoSrc?: string;
  subTitleFirst?: string;
  subTitleFirstColor?: ListItemExtendedSubtitleColor;
  subTitleSecond?: string;
  title?: Maybe<string>;
  hideUnavailableMessage?: boolean;
}

interface VListItemExtendedEmits {
  (e: 'click'): void;
  (e: 'click-checkbox'): void;
}

function emitClickCheckbox(): void {
  emit('click-checkbox');
}

function emitClick(): void {
  emit('click');
}

const {
  classObject,
  computedIconProperties,
  hasIcon,
  hasLogo,
  hasCheckbox,
  hasSubTitleContent,
  isLogoDisabled,
  haveSuffix,
} = useVListItemExtended(props);

const expandLeftIconProperties: Readonly<VIconProps> = {
  name: null ? SlottIconName.SLOTT_ANGLE_SMALL_RIGHT : IconName.EXPAND_RIGHT,
  size: null ? IconSize.SIZE_20 : IconSize.SIZE_16,
};

const lockIconProperties: Readonly<VIconProps> = {
  name: IconName.LOCK,
  size: IconSize.SIZE_24,
};

function onClick(): void {
  if (hasCheckbox.value) {
    emitClickCheckbox();
    return;
  }

  emitClick();
}
</script>

<template>
  <li v-auto-id="'VListItemExtended'"
    :class="classObject"
    @click.prevent="onClick"
  >
    <div
      :class="toModule('list-item-extended__inner')"
    >
      <div
        :class="toModule('list-item-extended__prefix')"
      >
        <div
          v-if="hasIcon"
          :class="toModule('list-item-extended__prefix-icon')"
        >
          <VIcon v-bind="computedIconProperties" />
        </div>
        <div
          v-else-if="hasLogo"
          :class="toModule('list-item-extended__prefix-logo')"
        >
          <VIcon
            v-if="isDisabled"
            v-bind="lockIconProperties"
            :class="toModule('list-item-extended__prefix-lock')"
          />
          <PaymentsLogo
            :alt="logoName || ''"
            :src="logoSrc"
            :class="toModule('list-item-extended__prefix-logo-img')"
          />
        </div>
        <VCheckbox
          v-else-if="hasCheckbox"
          :id="`${title}_checkbox`"
          :checked="isChecked"
          :class="toModule('list-item-extended__prefix-checkbox')"
        />
      </div>
      <div
        :class="toModule('list-item-extended__default')"
      >
        <span
          :class="toModule('list-item-extended__title')"
        >{{ title }}</span>
        <div
          v-if="hasSubTitleContent"
          :class="toModule('list-item-extended__subtitle-content')"
        >
          <span
            v-if="!!subTitleFirst && (!isLogoDisabled || hideUnavailableMessage)"
            :class="toModule('list-item-extended__subtitle')"
          >
            {{ subTitleFirst }}
          </span>
          <span
            v-if="!!subTitleSecond && !isLogoDisabled"
            :class="toModule('list-item-extended__subtitle')"
          >
            {{ subTitleSecond }}
          </span>
          <span
            v-else-if="isLogoDisabled && !hideUnavailableMessage"
            :class="toModule('list-item-extended__subtitle--locked')"
          >
            {{ $t('WEB2_SERVICE_UNAVAILABLE') }}
          </span>
        </div>
      </div>
      <div
        v-if="haveSuffix"
        :class="toModule('list-item-extended__suffix')"
      >
        <VIcon
          v-bind="expandLeftIconProperties"
        />
      </div>
    </div>
  </li>
</template>
