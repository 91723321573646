import { RecycleScrollerDirection } from 'web/src/components/RecycleScroller/enums';

export const defaultOverscanValue = 5;

export const offsetKeys = {
  [RecycleScrollerDirection.HORIZONTAL]: 'scrollLeft',
  [RecycleScrollerDirection.VERTICAL]: 'scrollTop',
} as const;

export const viewCapacityKeys = {
  [RecycleScrollerDirection.HORIZONTAL]: 'clientWidth',
  [RecycleScrollerDirection.VERTICAL]: 'clientHeight',
} as const;

export const elementPositionKeys = {
  [RecycleScrollerDirection.HORIZONTAL]: 'left',
  [RecycleScrollerDirection.VERTICAL]: 'top',
} as const;
