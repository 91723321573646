import type { NavigationItemIcon } from '@leon-hub/navigation-config';

import type { VIconProps } from '@components/v-icon';

import type { ShowCaseNavigationIconItem } from 'web/src/modules/home/submodules/showcase/types';
import { ShowcaseNavigationItemType } from 'web/src/modules/home/submodules/showcase/enums';

interface ShowcaseNavigationIconItemsComposable {
  createIconNavigationItem(icon: NavigationItemIcon): ShowCaseNavigationIconItem;
}

export default function useShowcaseNavigationIconItemsComposable(): ShowcaseNavigationIconItemsComposable {
  function createIconNavigationItem(icon: NavigationItemIcon): ShowCaseNavigationIconItem {
    const props: VIconProps = {
      name: icon.name,
      size: icon.size,
    };
    return {
      is: ShowcaseNavigationItemType.Icon,
      props,
    };
  }

  return {
    createIconNavigationItem,
  };
}
