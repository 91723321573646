import { Deferred } from '@leon-hub/utils';
import type { Optional } from '@leon-hub/types';

import type {
  DefinedAppModuleResult,
  AsyncEsModuleCallback,
  InnerModuleApi,
  OuterModuleApi,
} from '../types';

export function useAsyncWrapper(
  asyncEsModuleCallback: undefined,
  getApi: () => InnerModuleApi,
  useArgs?: () => unknown[],
): OuterModuleApi;

export function useAsyncWrapper(
  asyncEsModuleCallback: AsyncEsModuleCallback<'useModule'>,
  getApi: () => InnerModuleApi,
  useArgs?: () => unknown[],
): OuterModuleApi;
export function useAsyncWrapper(
  asyncEsModuleCallback: Optional<AsyncEsModuleCallback<'useModule'>>,
  getApi: () => InnerModuleApi,
  useArgs?: () => unknown[],
): OuterModuleApi {
  const d = new Deferred<DefinedAppModuleResult>();

  const cache = {} as {
    [K in keyof OuterModuleApi]: (...rest: Parameters<OuterModuleApi[K]>) => Promise<ReturnType<OuterModuleApi[K]>>
  };

  function wrapGetter(key: keyof OuterModuleApi) {
    if (!cache[key]) {
      // eslint-disable-next-line func-names
      cache[key] = async function (
        ...rest: Parameters<OuterModuleApi[keyof OuterModuleApi]>
      ): Promise<ReturnType<OuterModuleApi[keyof OuterModuleApi]>> {
        const outerApi = await d.promise;
        if (typeof outerApi === 'undefined') {
          throw new TypeError(`Unable to get property '${key}' of undefined`);
        }
        return outerApi[key].apply(this, rest);
      };
    }
    return cache[key];
  }

  if (typeof asyncEsModuleCallback !== 'undefined') {
    const asyncEsModule = asyncEsModuleCallback();
    const calculatedArgs: unknown[] = useArgs ? useArgs() : [];
    asyncEsModule.then(({ useModule }) => {
      const result = useModule.call(null, getApi(), ...calculatedArgs);
      if (result instanceof Promise) {
        result.then(d.resolve, d.reject);
      } else {
        d.resolve(result);
      }
    });
  }

  return new Proxy({}, {
    get(target, key) {
      if (typeof key === 'string') {
        return wrapGetter(key);
      }
      throw new Error(`Unexpected module key: ${String(key)}`);
    },
  }) as OuterModuleApi;
}
