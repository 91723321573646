import type {
  ProfileBonusesFreespinControllerProps,
} from 'web/src/modules/profile/submodules/bonuses/controllers/ProfileBonusesFreespinController/types';
import { getBonusBalance } from 'web/src/modules/profile/submodules/bonuses/utils';

export default function getBalance(
  props: Pick<ProfileBonusesFreespinControllerProps, 'bonus'>,
): number {
  return getBonusBalance(props.bonus);
}
