enum CircleSize {
  MINI = 'mini',
  DEFAULT = 'default',
  MEDIUM = 'medium',
  BIG = 'big',
  SIZE_32 = 'size-32',
  SIZE_40 = 'size-40',
  SIZE_42 = 'size-42',
  SIZE_44 = 'size-44',
  SIZE_80 = 'size-80',
  SIZE_92 = 'size-92',
  SIZE_94 = 'size-94',
  SIZE_112 = 'size-112',
  SIZE_152 = 'size-152',
  SIZE_180 = 'size-180',
  SIZE_218 = 'size-218',
  SIZE_S = 'size-s',
  SIZE_M = 'size-m',
  SIZE_L = 'size-l',
}

export default CircleSize;
