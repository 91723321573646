import type { Ref } from 'vue';
import { toRef } from 'vue';

import { useAccumulatorBoostStore } from '../submodules/accumulator-boost/store';

interface UseAccumulatorBoost {
  isAccumulatorBoostApplicable: Ref<boolean>;
  currentMultiplier: Ref<number>;
  nextMultiplier: Ref<number>;
  maxMultiplier: Ref<number>;
  itemsToNextBoost: Ref<number>;
  requiredOdds: Ref<string>;
  isWinBoost: Ref<boolean>;
  boostedWinValue: Ref<number | null>;
  winBoostValue: Ref<number | null>;
}

export default function useAccumulatorBoost(): UseAccumulatorBoost {
  const accumulatorBoostStore = useAccumulatorBoostStore();

  const isAccumulatorBoostApplicable = toRef(accumulatorBoostStore, 'isAccumulatorBoostApplicable');
  const currentMultiplier = toRef(accumulatorBoostStore, 'currentAccumulatorBoostMultiplier');
  const nextMultiplier = toRef(accumulatorBoostStore, 'nextAccumulatorBoostMultiplier');
  const maxMultiplier = toRef(accumulatorBoostStore, 'maxAccumulatorBoostMultiplier');
  const requiredOdds = toRef(accumulatorBoostStore, 'formattedAccumulatorBoostMinOdds');
  const itemsToNextBoost = toRef(accumulatorBoostStore, 'itemsToReachNextBoost');
  const isWinBoost = toRef(accumulatorBoostStore, 'isWinBoost');
  const boostedWinValue = toRef(accumulatorBoostStore, 'boostedWinValue');
  const winBoostValue = toRef(accumulatorBoostStore, 'winBoostValue');

  return {
    isAccumulatorBoostApplicable,
    currentMultiplier,
    nextMultiplier,
    maxMultiplier,
    requiredOdds,
    itemsToNextBoost,
    isWinBoost,
    boostedWinValue,
    winBoostValue,
  };
}
