function formatExpirationDate(value: string): string {
  let result = value;

  if (value.length > 5) {
    const month = result.slice(0, 2);
    const year = result.slice(-2);

    result = `${month}/${year}`;
  }

  return result;
}

export default formatExpirationDate;
