import { computed } from 'vue';
import type { ComputedRef } from 'vue';

import { assert, isOptionalBoolean } from '@leon-hub/guards';

import type { VSwitchProps } from 'web/src/components/Switch/VSwitch/types';

import type {
  FormWrapperWidgetProperties,
} from '../../types';

interface UseFormSwitch {
  componentProps: ComputedRef<VSwitchProps>;
}

export default function useFormSwitch(props: FormWrapperWidgetProperties): UseFormSwitch {
  const componentProps = computed<VSwitchProps>(() => {
    const {
      name,
      disabled,
      value,
      title,
      hintMessage,
      autofocus,
    } = props;
    assert(isOptionalBoolean(value));

    return {
      name,
      checked: value,
      label: title,
      disabled,
      hint: hintMessage,
      autofocus,
    };
  });
  return {
    componentProps,
  };
}
