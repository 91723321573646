<script lang="ts" setup>
import LobbyBlock from 'web/src/modules/lobby/components/LobbyBlock/LazyLobbyBlock';
import type {
  CasinoSearchLobbyEmits,
  CasinoSearchLobbyProps,
} from 'web/src/modules/search/submodules/casino/views/CasinoSearchLobby/types';
import { useCasinoSearchLobby } from 'web/src/modules/search/submodules/casino/views/CasinoSearchLobby/composables';

const props = defineProps<CasinoSearchLobbyProps>();
const emits = defineEmits<CasinoSearchLobbyEmits>();

const {
  lobbyType,
  skeletons,
  emitGameClick,
} = useCasinoSearchLobby(props, emits);
</script>

<template>
  <LobbyBlock v-auto-id="'CasinoSearchLobby'"
    test-el="casino-search-lobby"
    :lobby-type="lobbyType"
    :is-sport="isSport"
    :should-replace-game-routes="shouldReplaceGameRoutes"
    :skeletons="skeletons"
    is-modal
    no-background
    @game-click="emitGameClick"
  />
</template>
