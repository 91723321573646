import type GqlBatchedRequest from '../GqlBatchedRequest';
import type GqlBatchedSubRequest from '../GqlBatchedSubRequest';
import type ServerApiSubResponseResultNode from '../types/ServerApiSubResponseResultNode';
import type { ApiErrorOptions } from '../../errors/ApiError';
import { ApiError } from '../../errors/ApiError';

export interface GqlApiErrorOptions extends ApiErrorOptions {
  batch?: GqlBatchedRequest;
  subRequest?: GqlBatchedSubRequest;
  responseItem?: ServerApiSubResponseResultNode;
}

export default class GqlApiError extends ApiError {
  protected readonly batch: GqlApiErrorOptions['batch'];

  protected readonly subRequest: GqlApiErrorOptions['subRequest'];

  protected readonly responseItem: GqlApiErrorOptions['responseItem'];

  constructor(options?: GqlApiErrorOptions) {
    super({
      ...options,
      code: options?.code,
    });

    this.batch = options?.batch;
    this.subRequest = options?.subRequest;
    this.responseItem = options?.responseItem;
  }
}
