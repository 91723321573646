import { bindAll } from 'lodash';
import noop from 'lodash/noop';

import type { BaseLogger } from '@leon-hub/base-logger';
import type { BaseError } from '@leon-hub/errors';
import { AbstractError, LogLevel } from '@leon-hub/errors';
import { getLocationHref } from '@leon-hub/service-locator-env';

import type {
  LogData, LoggerOptions, SaveLogInput,
} from '../types';
import { isLogData } from '../guards';
import { consoleLog } from '../utils';
import { NameSpacedLogger } from './NameSpacedLogger';

export class Logger implements BaseLogger {
  private loaded = false;

  private options: LoggerOptions = {
    appName: '',
    appVersion: '',
    remoteSaveActionLogLevel: LogLevel.DEBUG,
  };

  public constructor(options?: LoggerOptions) {
    bindAll(this);
    if (options) {
      this.init(options);
    }
  }

  public init(options: LoggerOptions): void {
    this.options = options;
    this.loaded = true;
  }

  public setRemoteSaveAction(remoteSaveAction: (input: SaveLogInput) => Promise<void>): void {
    this.options.remoteSaveAction = remoteSaveAction;
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  public sendLog(
    level: LogLevel,
    input: LogData | Error | BaseError | string,
    payload?: unknown,
    error?: Error,
    namespace?: string[],
  ): void {
    if (process.env.NODE_ENV === 'test') {
      return;
    }

    if (!this.loaded) {
      // eslint-disable-next-line no-console
      console.warn('LOGGING CALLED WITH DEFAULT PARAMETERS! To setup logger please call logger.init() action!');
    }

    let message = '';
    let trace = '';
    let payloadForRemoteLog = '';
    let payloadForLocalLog = payload;
    const namespacePrefix = namespace && namespace.length > 0
      // eslint-disable-next-line prefer-template
      ? namespace.map((item) => `[${item}]`).join('') + ' '
      : '';

    if (typeof input === 'string') {
      message = input;

      if (payload) {
        if (payload instanceof Error) {
          trace = payload.stack || '';
          if (payload instanceof AbstractError) {
            payloadForRemoteLog = JSON.stringify(payload);
          } else {
            message += `\n${payload}`;
          }
        } else {
          payloadForRemoteLog = JSON.stringify(payload);
          payloadForLocalLog = payload;
        }
      }

      if (error) {
        trace = error.stack || '';
        message += `\n${error}`;
      }
    } else if (input instanceof AbstractError) {
      const {
        stack,
        message: errorMessage,
        ...sendToLog
      } = input.toJSON();
      message = errorMessage;
      trace = input.stack ?? '';
      payloadForRemoteLog = JSON.stringify(sendToLog);
      payloadForLocalLog = payloadForLocalLog || input.payload;
    } else if (input instanceof Error) {
      message = `${input}`;
      trace = input.stack || '';
      payloadForRemoteLog = JSON.stringify(payload || input);
    } else if (isLogData(input)) {
      message = input.message;
      trace = input.stacktrace;
      payloadForRemoteLog = input.payload || '';
      payloadForLocalLog = payloadForLocalLog || input.payload;
    }

    message = `${namespacePrefix}${message}`;

    if (this.options.remoteSaveAction !== undefined
      && Logger.isLoggableLevel(level, this.options.remoteSaveActionLogLevel)) {
      const url = getLocationHref();

      const remoteInput: SaveLogInput = {
        appName: this.options.appName,
        appVersion: this.options.appVersion,
        level,
        line: 0,
        column: 0,
        error: message,
        file: '',
        url,
        ts: 0,
        stacktrace: trace || '',
        payload: payloadForRemoteLog || '',
      };

      // eslint-disable-next-line no-console
      this.options.remoteSaveAction(remoteInput).catch(noop);
    }

    if (!this.options.remoteSaveAction
      || (this.options.isConsoleOutputAllowed?.())) {
      consoleLog(level, message, trace, payloadForLocalLog);
    }
  }

  private static isLoggableLevel(level1: LogLevel, level2: LogLevel | undefined): boolean {
    if (!level2) {
      return true;
    }

    switch (level2) {
      case LogLevel.DEBUG:
        return true;

      case LogLevel.INFO:
        return level1 === LogLevel.INFO
          || level1 === LogLevel.WARN
          || level1 === LogLevel.ERROR;

      case LogLevel.WARN:
        return level1 === LogLevel.WARN
          || level1 === LogLevel.ERROR;

      case LogLevel.ERROR:
        return level1 === LogLevel.ERROR;

      default:
        return false;
    }
  }

  public info(input: LogData): void;
  public info(message: string | Error | BaseError, payload?: unknown): void;
  public info(message: string, error?: Error): void;
  public info(message: string, payload?: unknown, error?: Error): void;
  public info(input: string | LogData | Error | BaseError, payload?: unknown, error?: Error): void {
    this.sendLog(LogLevel.INFO, input, payload, error);
  }

  public debug(input: LogData): void;
  public debug(message: string | Error | BaseError, payload?: unknown): void;
  public debug(message: string, error?: Error): void;
  public debug(message: string, payload?: unknown, error?: Error): void;
  public debug(input: string | LogData | Error | BaseError, payload?: unknown, error?: Error): void {
    this.sendLog(LogLevel.DEBUG, input, payload, error);
  }

  public warn(input: LogData): void;
  public warn(message: string | Error, payload?: unknown): void;
  public warn(message: string, error?: Error): void;
  public warn(message: string, payload?: unknown, error?: Error): void;
  public warn(input: string | LogData | Error | BaseError, payload?: unknown, error?: Error): void {
    this.sendLog(LogLevel.WARN, input, payload, error);
  }

  public error(input: LogData): void;
  public error(message: string | Error | BaseError, payload?: unknown): void;
  public error(message: string, error?: Error): void;
  public error(message: string, payload?: unknown, error?: Error): void;
  public error(input: string | LogData | Error | BaseError, payload?: unknown, error?: Error): void {
    this.sendLog(LogLevel.ERROR, input, payload, error);
  }

  public createNamespace(namespace?: string): NameSpacedLogger {
    return new NameSpacedLogger(this, namespace ? [namespace] : []);
  }
}
