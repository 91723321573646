import type { ToRefs } from 'vue';
import {
  computed,
  toRef,
} from 'vue';

import type { SiteConfigDocument } from 'web/src/modules/core/store/types';
import { useSiteConfigStore } from 'web/src/modules/core/store';

export type UsePaymentsConfig = ToRefs<SiteConfigDocument['payments']>;

export default function usePaymentsConfig(): UsePaymentsConfig {
  const siteConfigStore = useSiteConfigStore();
  const payments = toRef(siteConfigStore, 'payments');

  return {
    paymentsListsReloadTimeout: computed(() => payments.value?.paymentsListsReloadTimeout || 300_000),
    lowBalanceAmounts: computed(() => payments.value?.lowBalanceAmounts ?? []),
    isInteracEnabled: computed(() => !!payments.value?.isInteracEnabled),
    isNoMethodMessageEnabled: computed(() => !!payments.value?.isNoMethodMessageEnabled),
    isExpirationValidationDisabled: computed(() => !!payments.value?.isExpirationValidationDisabled),
    balanceButtonCurrencies: computed(() => payments.value?.balanceButtonCurrencies ?? []),
    depositMetricsRequestDelay: computed(() => payments.value?.depositMetricsRequestDelay || 0),
    isCcTokensEnabled: computed(() => !!payments.value?.isCcTokensEnabled),
    isPaymentsMinAmountSuggestionEnabled: computed(() => !!payments.value?.isPaymentsMinAmountSuggestionEnabled),
    isPaymentsFastSumEnabled: computed(() => !!payments.value?.isPaymentsFastSumEnabled),
    isPaymentsFastSumMaxEnabled: computed(() => !!payments.value?.isPaymentsFastSumMaxEnabled),
  };
}
