import type { Ref } from 'vue';
import { watch } from 'vue';

import { Deferred } from '@leon-hub/utils';

/**
 * Use a ref to be defined or any other condition.
 */
export function useRef<T extends Ref, U = T['value']>(ref: T, condition: (ref: U) => boolean = (value: U) => !!value): Promise<void> {
  if (condition(ref.value)) {
    return Promise.resolve();
  }
  const deferred = new Deferred();
  const stop = watch(
    ref, (value) => {
      if (condition(value)) {
        stop();
        deferred.resolve();
      }
    },
  );
  return deferred.promise;
}
