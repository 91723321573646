import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { assert } from '@leon-hub/guards';
import {
  doActivateAffiliateProgram,
  doCreateBonusCode, getAffiliatedCustomers, getBonusCodes,
  getGeneralStats,
} from '@leon-hub/api-sdk';
import type {
  AffiliateGeneralStatsResponse, getGeneralStatsQueryVariables, BonusCodesResponse,
  ActivateAffiliateProgramRequest, getAffiliatedCustomersQuery,
} from '@leon-hub/api-sdk';
import { normalizeError } from '@leon-hub/errors';
import { logger } from '@leon-hub/logging';

import type {
  AffiliatedCustomersDocument, AffiliateOperation,
  AffiliateOperationsHistory,
  AffiliateStats, BonusCode,
  GeneralStatsOptions,
  AffiliateCustomerFilterOption,
} from 'web/src/modules/affiliates/store/types';
import type { VSelectOption } from 'web/src/components/Select';
import { dateRangeFilterOptionsList } from 'web/src/modules/affiliates/store/utils';
import { DateRangeDefaultDay } from 'web/src/modules/affiliates/store/enums';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import { useUserStore } from 'web/src/modules/user/store';

const useAffiliateStore = defineStore('affiliate', () => {
  const api = useGraphqlClient();
  const userStore = useUserStore();

  /** state */
  const affiliatedCustomers = ref<AffiliatedCustomersDocument>();
  const lastRegisteredAffiliatedCustomers = ref<AffiliatedCustomersDocument>();
  const isAffiliatedCustomersLoading = ref<boolean>(false);
  const stats = ref<AffiliateStats>();
  const operationsHistory = ref<AffiliateOperationsHistory>();
  const profileOperations = ref<AffiliateOperation[]>([]);
  const selectedOperation = ref<AffiliateOperation>();
  const selectedOperationsFilterRef = ref<VSelectOption>();
  const bonusCodesLoaded = ref<boolean>(false);
  const bonusCodes = ref<BonusCode[]>([]);
  const maxBonusCodesCount = ref<number>(100);
  const addBonusCodeError = ref<string>('');
  const lastAddedBonusCode = ref<string>('');
  const codeAddedSuccessfully = ref<boolean>(false);

  /** getters */
  const bonusCodesCount = computed(() => bonusCodes.value.length);
  const dateRangeFilterOptions = computed(() => dateRangeFilterOptionsList());
  const defaultDateRangeFilterOption = computed(() => {
    const daysRange = DateRangeDefaultDay.ThirtyDays;
    const defaultRangeFilterOption = dateRangeFilterOptions.value
      .find((os: VSelectOption) => os.value === daysRange as string);
    assert(defaultRangeFilterOption, `desired object is not found with params: daysRange ${daysRange}`);

    return defaultRangeFilterOption;
  });
  const selectedOperationsFilter = computed<VSelectOption>(() => selectedOperationsFilterRef.value ?? defaultDateRangeFilterOption.value);

  function setAffiliatedCustomers(payload: AffiliatedCustomersDocument): void {
    affiliatedCustomers.value = payload;
  }

  function setLastRegisteredAffiliatedCustomers(payload: AffiliatedCustomersDocument): void {
    lastRegisteredAffiliatedCustomers.value = payload.slice(0, 5);
  }

  function setAffiliateCustomersLoader(isLoading: boolean): void {
    isAffiliatedCustomersLoading.value = isLoading;
  }

  function setStats(statsValue: AffiliateStats): void {
    stats.value = statsValue;
  }

  function setOperationsHistory(operationsHistoryValue: AffiliateOperationsHistory): void {
    operationsHistory.value = operationsHistoryValue;
  }

  function setProfileOperations(operations: AffiliateOperation[]): void {
    profileOperations.value = operations;
  }

  function setSelectedOperation(operationToSelected?: AffiliateOperation): void {
    selectedOperation.value = operationToSelected;
  }

  function setSelectedOperationsFilter(filter?: VSelectOption): void {
    selectedOperationsFilterRef.value = filter;
  }

  function setBonusCodeLoaded(isLoaded: boolean): void {
    bonusCodesLoaded.value = isLoaded;
  }

  function setBonusCodes(bonusCodesValue: BonusCode[] = []): void {
    bonusCodes.value = bonusCodesValue;
  }

  function setMaxBonusCodesCount(count: number): void {
    maxBonusCodesCount.value = count;
  }

  function setAddBonusCodeError(errorText = ''): void {
    addBonusCodeError.value = errorText;
  }

  function setLastAddedBonusCode(code: string): void {
    lastAddedBonusCode.value = code;
  }

  function setCodeAddedSuccessfully(isAdded: boolean): void {
    codeAddedSuccessfully.value = isAdded;
  }

  async function fetchGeneralStats({ page = 0, range = '1' }: GeneralStatsOptions): Promise<Omit<AffiliateGeneralStatsResponse, 'anchorIgnore'>> {
    const queryVariables: getGeneralStatsQueryVariables = {
      options: {
        page,
        range,
      },
    };

    return getGeneralStats(
      api,
      (node) => node.queries.affiliate.getGeneralStats,
      queryVariables,
    );
  }

  async function getProfileGeneralStats({ page = 0, range = '1' }: GeneralStatsOptions): Promise<void> {
    const result = await fetchGeneralStats({ page, range });

    setStats(result.stats);
    setProfileOperations(result.operations);
  }

  async function getOperationsHistory({ page = 0, range = '1' }: GeneralStatsOptions): Promise<void> {
    const result = await fetchGeneralStats({ page, range });

    setOperationsHistory({
      operations: result.operations,
      totalCount: result.totalCount,
    });
  }

  async function doActivation(payload: Pick<ActivateAffiliateProgramRequest, 'termsAccepted'>): Promise<void> {
    const response = await doActivateAffiliateProgram(
      api,
      (node) => node.mutations.affiliate.activateAffiliateProgram,
      {
        options: {
          ...payload,
        },
      },
    );
    if (response.result === 'OK') {
      await userStore.loadCustomerData(true);
    }
  }

  function requestBonusCodes(): Promise<BonusCodesResponse> {
    return getBonusCodes(
      api,
      (node) => node.queries.affiliate.getBonusCodes,
      {},
    );
  }

  async function getBonusCodesHandler(): Promise<void> {
    const response = await requestBonusCodes();
    setBonusCodeLoaded(true);
    if (response) {
      setBonusCodes(response.bonusCodes);
      setMaxBonusCodesCount(response.maxCount);
    }
  }

  async function createBonusCode(bonusCode: string): Promise<boolean> {
    setAddBonusCodeError('');
    setLastAddedBonusCode(bonusCode);
    try {
      const result = await doCreateBonusCode(
        api,
        (node) => node.mutations.affiliate.createBonusCode,
        {
          options: {
            bonusCode,
          },
        },
      );
      setCodeAddedSuccessfully(true);
      return !!result;
    } catch (rawError) {
      const error = normalizeError(rawError);

      setAddBonusCodeError(error.message);
      return false;
    }
  }

  function clearSelectedOperation(): void {
    setSelectedOperation(undefined);
  }

  async function fetchAffiliateCustomers(options: AffiliateCustomerFilterOption): Promise<void> {
    try {
      setAffiliateCustomersLoader(true);
      const data = await getAffiliatedCustomers(api,
        (node: getAffiliatedCustomersQuery) => node.queries.affiliate.getAffiliatedCustomers,
        {
          options,
        });
      setAffiliatedCustomers(data.affiliatedCustomers);

      if (!lastRegisteredAffiliatedCustomers.value?.length) {
        setLastRegisteredAffiliatedCustomers(data.affiliatedCustomers);
      }

      setAffiliateCustomersLoader(false);
    } catch (error) {
      setAffiliateCustomersLoader(false);
      logger.error('fetchAffiliateCustomers', error);
    }
  }

  return {
    stats,
    bonusCodes,
    bonusCodesCount,
    maxBonusCodesCount,
    bonusCodesLoaded,
    isAffiliatedCustomersLoading,
    affiliatedCustomers,
    lastRegisteredAffiliatedCustomers,
    selectedOperation,
    operationsHistory,
    dateRangeFilterOptions,
    selectedOperationsFilter,
    lastAddedBonusCode,
    getProfileGeneralStats,
    addBonusCodeError,
    getBonusCodesHandler,
    fetchAffiliateCustomers,
    clearSelectedOperation,
    getOperationsHistory,
    setSelectedOperationsFilter,
    setSelectedOperation,
    doActivation,
    createBonusCode,
  };
});

export default useAffiliateStore;
