import type { Ref, ComputedRef, MaybeRef } from 'vue';
import { toRef, inject, computed } from 'vue';

import type {
  ExpandedSportlineBlockMap,
  SetExpandedStateMethod,
  ExpandableSportlineBlockKey,
  SetExpandedStatePayload,
} from '../types';
import { ProvideExpandedBlockMapKey, ProvideSetExpandedBlockStateKey } from '../constants';
import { applyExpandedStatePayload, getIsExpandedFromMap } from '../utils';

interface UseInjectIsExpandedSportlineBlockProps {
  expandKey: MaybeRef<Maybe<ExpandableSportlineBlockKey>>;
  isDefaultExpanded?: MaybeRef<boolean>;
}

interface UseInjectIsExpandedSportlineBlockComposable {
  isExpanded: ComputedRef<boolean>;
  setExpandedState(value?: boolean): void;
}

export function useInjectIsExpandedSportlineBlock(
  props: UseInjectIsExpandedSportlineBlockProps,
): UseInjectIsExpandedSportlineBlockComposable {
  const expandKey: Ref<Maybe<ExpandableSportlineBlockKey>> = toRef(props.expandKey);
  const isDefaultExpanded: Ref<boolean> = toRef(props.isDefaultExpanded ?? true);

  const expandedMap = toRef(inject<ExpandedSportlineBlockMap>(ProvideExpandedBlockMapKey, {}));
  const injectedSetExpandedState = inject<SetExpandedStateMethod>(ProvideSetExpandedBlockStateKey, (payload: SetExpandedStatePayload) => {
    expandedMap.value = applyExpandedStatePayload(payload, expandedMap.value);
  });

  const isExpanded = computed<boolean>(() => getIsExpandedFromMap(expandedMap, expandKey, isDefaultExpanded));

  function setExpandedState(value?: boolean): void {
    if (!expandKey.value) { return; }
    injectedSetExpandedState({
      expandKey: expandKey.value,
      value: !!value,
    });
  }

  return {
    isExpanded,
    setExpandedState,
  };
}
