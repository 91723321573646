<script lang="ts" setup>
import { toRef } from 'vue';

import type { BetlineLeagueTopPlayers } from 'web/src/modules/sportline/types/rest';
import vPopperHint from 'web/src/components/Popper/directives/popperHint';
import VList from 'web/src/components/List/VList/VList.vue';
import VListItemAccordion from 'web/src/components/List/VListItemAccordion/VListItemAccordion.vue';
import ExpandButton from 'web/src/modules/sportline/submodules/core-statistic/components/ExpandButton.vue';
import StatisticColoredLabel
  from 'web/src/modules/sportline/submodules/core-statistic/components/StatisticColoredLabel.vue';
import StatisticTeamLogo
  from 'web/src/modules/sportline/submodules/event-statistic/components/StatisticTeamLogo.vue';
import StatisticPlayerLogo
  from 'web/src/modules/sportline/submodules/event-statistic/components/StatisticPlayerLogo.vue';
import {
  StatisticColoredLabelSize,
  StatisticColoredLabelType,
} from 'web/src/modules/sportline/submodules/core-statistic/enums';

import { useLeagueTopPlayers, TopPlayerListType } from './useLeagueTopPlayers';

interface LeagueTopPlayersProps {
  leagueTopPlayers: BetlineLeagueTopPlayers;
}

const props = defineProps<LeagueTopPlayersProps>();

const {
  canExpand,
  isExpanded,
  lists,
} = useLeagueTopPlayers({
  leagueTopPlayers: toRef(props, 'leagueTopPlayers'),
});
</script>

<template>
  <VList
    v-if="lists.length > 0"
    independent-items
  >
    <VListItemAccordion
      id="league-top-players"
      :title="$t('WEB2_STATISTICS_TOP_PLAYERS')"
      is-title-centered
      open
    >
      <template #content>
        <div
          :class="{
            [$style['league-top-players__content']]: true,
            [$style['league-top-players__content--one-column']]: lists.length < 2,
          }"
        >
          <div
            v-for="list in lists"
            :key="list.type"
            :class="$style['players-list']"
          >
            <div :class="$style['players-list-title']">
              <div>
                <template v-if="list.type === TopPlayerListType.SCORERS">
                  {{ $t('WEB2_STATS_SCORERS') }}
                </template>
                <template v-else-if="list.type === TopPlayerListType.ASSISTS">
                  {{ $t('WEB2_STATS_ASSISTANT') }}
                </template>
              </div>

              <div
                v-popper-hint="$t('WEB2_STATS_PLAYER_TOTAL_GAMES')"
              >
                <StatisticColoredLabel
                  :class="$style['player-stat']"
                  :label="$t('WEB2_STATS_PLAYER_TOTAL_GAMES_SHORT')"
                  :size="StatisticColoredLabelSize.DEFAULT"
                  :type="StatisticColoredLabelType.TRANSPARENT"
                />
              </div>

              <div
                v-if="list.type === TopPlayerListType.SCORERS"
                v-popper-hint="$t('WEB2_STATS_PLAYER_TOTAL_GOALS')"
              >
                <StatisticColoredLabel
                  :class="$style['player-stat']"
                  :label="$t('WEB2_STATS_PLAYER_TOTAL_GOALS_SHORT')"
                  :size="StatisticColoredLabelSize.DEFAULT"
                  :type="StatisticColoredLabelType.TRANSPARENT"
                />
              </div>
              <div
                v-else-if="list.type === TopPlayerListType.ASSISTS"
                v-popper-hint="$t('WEB2_STATS_PLAYER_TOTAL_ASSISTS')"
              >
                <StatisticColoredLabel
                  :class="$style['player-stat']"
                  :label="$t('WEB2_STATS_PLAYER_TOTAL_ASSISTS_SHORT')"
                  :size="StatisticColoredLabelSize.DEFAULT"
                  :type="StatisticColoredLabelType.TRANSPARENT"
                />
              </div>
              <div v-else />
            </div>

            <div :class="$style['players-list__items']">
              <div
                v-for="(player, idx) in list.list"
                :key="idx"
                :class="{
                  [$style['players-list-item']]: true,
                  [$style['players-list-item--the-best']]: idx === 0
                }"
              >
                <div>
                  <span :class="$style['player-ranking']">{{ player.ranking }}.</span>
                </div>

                <div :class="$style['players-list-item__logos']">
                  <div
                    :class="{
                      [$style['player-logos']]: true,
                      [$style['player-logos--the-best']]: idx === 0
                    }"
                  >
                    <StatisticPlayerLogo
                      v-if="player.player"
                      :class="[
                        $style['player-logos__logo'],
                        $style['player-logos__logo--player'],
                      ]"
                      :player="player.player"
                    />
                    <StatisticTeamLogo
                      v-if="player.team"
                      :class="[
                        $style['player-logos__logo'],
                        $style['player-logos__logo--team'],
                      ]"
                      :team="player.team"
                    />
                  </div>
                </div>

                <div :class="$style['players-list-item__identity']">
                  <span
                    :class="$style['player-name']"
                    :title="player.name ?? undefined"
                  >{{ player.name }}</span>
                  <span
                    v-if="idx === 0"
                    :class="$style['player-position']"
                    :title="(player.positionFull || player.positionShort) ?? undefined"
                  >{{ player.positionFull || player.positionShort }}</span>
                </div>

                <div>
                  <StatisticColoredLabel
                    :class="$style['player-stat']"
                    :label="String(player.matchesPlayed || '')"
                    :size="StatisticColoredLabelSize.DEFAULT"
                    :type="StatisticColoredLabelType.TRANSPARENT"
                  />
                </div>

                <div>
                  <StatisticColoredLabel
                    :class="$style['player-stat']"
                    :label="player.value || ''"
                    :size="StatisticColoredLabelSize.DEFAULT"
                    :type="StatisticColoredLabelType.DEFAULT"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <ExpandButton
          v-if="canExpand"
          v-model:is-expanded="isExpanded"
          :opened-title="$t('WEB2_MATCH_STATISTIC_HIDE')"
          :closed-title="$t('WEB2_MATCH_STATISTIC_SHOW')"
          :class="$style['league-top-players__expand-button']"
        />
      </template>
    </VListItemAccordion>
  </VList>
  <div v-else />
</template>

<style module lang="scss">
.league-top-players {
  &__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;

    &--one-column {
      grid-template-columns: 1fr;
    }

    @include for-phone-only {
      grid-template-columns: 1fr;
    }
  }

  &__expand-button {
    grid-column: 1 / -1;
    margin-top: 8px;
  }
}

.players-list {
  padding: 8px;
  background-color: var(--Layer2);
  border-radius: 5px;

  &__items {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1px;
    background-color: var(--Layer1);
  }
}

.players-list-title {
  @include medium\14\16\025;

  display: grid;
  grid-template-columns: 1fr 32px 32px;
  gap: 8px;
  padding: 8px 8px 8px 0;
}

.players-list-item {
  display: grid;
  grid-template-columns: 16px 36px 1fr 32px 32px;
  gap: 8px;
  padding: 8px;
  line-height: 20px;
  background-color: var(--Layer2);

  &__logos,
  &__identity {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &--the-best {
    line-height: 32px;
  }
}

.player-ranking {
  @include medium\12\16;

  color: var(--TextSecondary);
}

.player-logos {
  $logos: &;

  display: flex;
  flex-direction: row;
  align-items: center;
  height: 20px;
  line-height: 20px;

  &--the-best {
    height: 32px;
    margin-left: -6px;
    line-height: 32px;

    & #{$logos}__logo {
      margin: 0 -10px;
      line-height: 32px;

      &:first-child {
        width: 32px;
        height: 32px;
        margin: 0;
      }
    }
  }

  &__logo {
    z-index: z-index(base);
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 -2px;
    line-height: 20px;
    border-radius: 50%;

    &--player {
      background-color: var(--BrandText);
    }

    &--team {
      background-color: var(--Layer2);
    }

    &:first-child {
      z-index: z-index(base) + 1;
      margin: 0 -2px 0 0;
    }
  }
}

.player-name {
  @include medium\13\16;

  overflow: hidden;
  color: var(--TextPrimary);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.player-position {
  @include regular\12\12;

  color: var(--TextSecondary);
}

.player-stat {
  width: 100%;
}
</style>
