import type {
  CoreSportlineFetchOptions,
  GetLeagueResponse,
} from 'web/src/modules/sportline/types/rest';
import type { SportlineApiService } from 'web/src/modules/sportline/types/services';
import { getLeague } from 'web/src/modules/sportline/utils/api';

import useSportlineApiPending from './useSportlineApiPending';
import type { UseSportlineApiLoadTreeEntitiesProps } from './types';
import type { ApiPending, ApiPendingParams } from './useSportlineApiPending';

export function useSportlineApiLoadLeague(
  props: UseSportlineApiLoadTreeEntitiesProps,
): Pick<SportlineApiService, 'loadLeague'> {
  const {
    tryRequestWithCTag,
    sportsRestApiFlags,
    isLiveOutrightsEnabled,
  } = props;

  const sportsApiPendings = useSportlineApiPending<GetLeagueResponse>();

  function loadLeague({ id, silent }: CoreSportlineFetchOptions & { id: string }): Promise<GetLeagueResponse> {
    const pendingFilter: ApiPendingParams = { leagueId: id, betlines: [] };
    const apiPendingIndex = sportsApiPendings.getApiPendingIndexForFilter(pendingFilter);

    if (apiPendingIndex > -1) {
      return sportsApiPendings.getApiPendingByIndex(apiPendingIndex).request;
    }

    const request = new Promise<GetLeagueResponse>((resolve, reject) => {
      tryRequestWithCTag((ctag) => getLeague({
        ctag,
        leagueId: id,
        silent,
        flags: [...sportsRestApiFlags.value],
        LMCE: isLiveOutrightsEnabled.value,
      })).then((result) => {
        sportsApiPendings.removeApiPendingForFilter(pendingFilter);
        resolve(result);
        return result;
      }).catch((error) => {
        sportsApiPendings.removeApiPendingForFilter(pendingFilter);
        reject(error);
      });
    });
    const apiPending: ApiPending<GetLeagueResponse> = {
      ...pendingFilter,
      request,
    };

    sportsApiPendings.addApiPending(apiPending);

    return apiPending.request;
  }

  return { loadLeague };
}
