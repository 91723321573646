import { ComponentKey } from '../types';
import type { ComponentResult } from '../types';
import AbstractFingerprintComponent from '../AbstractFingerprintComponent';

export default class TimezoneOffsetComponent extends AbstractFingerprintComponent {
  constructor() {
    super(ComponentKey.TimezoneOffset);
  }

  getComponentResult(): Promise<ComponentResult> {
    return this.result([new Date().getTimezoneOffset()]);
  }
}
