import type { Ref } from 'vue';
import {
  ref,
} from 'vue';

import { useIntersectionObserver } from '@leon-hub/vue-utils';

export interface ModalWindowHeaderComposable {
  pageHeader: Ref<HTMLElement | undefined>;
  isSticky: Ref<boolean>;
}

export default function useModalWindowHeader(): ModalWindowHeaderComposable {
  const pageHeader = ref<HTMLElement>();
  const isSticky = ref(false);

  if (process.env.VUE_APP_LAYOUT_PHONE) {
    useIntersectionObserver(pageHeader, handleIntersection, {
      rootMargin: '-1px 0px 0px 0px',
      threshold: 1,
    });
  }

  function handleIntersection(isIntersecting: boolean): void {
    isSticky.value = !isIntersecting;
  }

  return {
    pageHeader,
    isSticky,
  };
}
