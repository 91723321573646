import { BusEvent, useEventsBus } from '@leon-hub/event-bus';

interface UseScrollIntoView {
  scrollIntoView(element: HTMLElement): void;
}

export default function useScrollIntoView(): UseScrollIntoView {
  const bus = useEventsBus();

  const scrollIntoView = (element: HTMLElement): void => {
    if (process.env.VUE_APP_LAYOUT_PHONE) {
      element.scrollIntoView({
        block: 'center',
        inline: 'center',
      });
    } else {
      bus.emit(BusEvent.MODAL_SCROLL_TO_ELEMENT, { element, onlyIfNeeded: true });
    }
  };

  return {
    scrollIntoView,
  };
}
