enum BannerLocation {
  TOP = 'top',
  BOTTOM = 'bottom',
  RIGHT = 'right',
  CUSTOM = 'custom',
  TOP_LEFT = 'top_left',
  TOP_RIGHT = 'top_right',
  TOP_BIG = 'top_big',
  TOP_SMALL = 'top_small',
  PROMO_BOTTOM_BANNERS = 'promo_bottom_banners',
  TOURNAMENTS_BOTTOM_BANNERS = 'tournaments_bottom_banners',
  TOP_CUSTOM_HOME_PAGE = 'top_custom_home_page',
}

export default BannerLocation;
