import type {
  GetSportEventsChangesResponse,
  GetSportEventsResponse,
} from 'web/src/modules/sportline/types/rest';

// eslint-disable-next-line import/prefer-default-export
export function normalizeEventsChangesResponseToDefaultResponse(
  response?: Maybe<GetSportEventsChangesResponse | GetSportEventsResponse>,
): Maybe<GetSportEventsResponse> {
  if (!response) { return null; }
  return 'events' in response
    ? response
    : {
      enabled: response.enabled,
      events: response.data,
      vtag: response.vtag,
      totalCount: response.data.length,
    };
}
