<script lang="ts" setup>
import { computed, toRef, useSlots } from 'vue';

import { useSportlineEventType } from 'web/src/modules/sportline/composables/sportline-event';
import { SportlineType } from 'web/src/modules/sportline/enums';
import hasSlot from 'web/src/utils/vue/hasSlot';

import type { OutrightInfoProps } from '../../types';
import { useOutrightInfoHeadline } from '../../composables';
import HeadlineLiveTimeInfo from '../HeadlineLiveTimeInfo.vue';

const props = defineProps<OutrightInfoProps>();
const sportlineEvent = toRef(props, 'sportlineEvent');
const backgroundColor = toRef(props, 'backgroundColor');
const sport = toRef(props, 'sport', null);
const leagueRepresentationData = toRef(props, 'leagueRepresentationData', null);
const slots = useSlots();
const hasPrimaryMarket = computed(() => (hasSlot(slots, 'primary-market')));

const {
  sportlineType,
} = useSportlineEventType({ sportEvent: sportlineEvent });
const {
  championshipName,
  outrightName,
  date,
  time,
} = useOutrightInfoHeadline({ sport, sportlineEvent, leagueRepresentationData });
</script>

<template>
  <div v-auto-id="'OutrightInfo'" :class="$style['outright-headline-info']">
    <div :class="$style['outright-headline-info__main-content']">
      <div :class="$style['outright-headline-info__championship']">
        {{ championshipName }}
      </div>

      <h2
        :class="{
          [$style['outright-headline-info__primary-market-name']]: true,
          [$style['outright-headline-info__primary-market-name--colored']]: !!backgroundColor,
        }"
      >
        {{ outrightName }}
      </h2>

      <div :class="$style['outright-headline-date']">
        <template v-if="sportlineType === SportlineType.Live">
          <div :class="$style['outright-headline-date__live-indicator']">
            <span :class="$style['sport-event-badge']">Live</span>
          </div>
          <HeadlineLiveTimeInfo
            :class="$style['outright-headline-date__live-time']"
            :sportline-event="sportlineEvent"
          />
        </template>
        <template v-else>
          <span :class="$style['outright-headline-date__kickoff-date']">{{ date }}</span>
          <span :class="$style['outright-headline-date__kickoff-time']">{{ time }}</span>
        </template>
      </div>

      <div
        v-if="hasPrimaryMarket"
        :class="$style['outright-headline-info__primary-market']"
      >
        <slot name="primary-market" />
      </div>
    </div>
  </div>
</template>

<style module lang="scss">
@import 'web/src/modules/sportline/styles/sport-event-badge';
@import 'web/src/modules/sportline/submodules/event-details/styles/headline';
</style>
