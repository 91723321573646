import type { SportlineEventPeriod } from 'web/src/modules/sportline/types';
import { SportFamily, SportlineEventPeriodType } from 'web/src/modules/sportline/enums';

/**
 * @deprecated @TODO remove. This logic is used for statistics
 */
const WholeMatchTime = {
  [SportFamily.Soccer as string]: 90,
};

export function getWholeMatchTimeForSportFamily(family: SportFamily | string): Maybe<number> {
  return WholeMatchTime[family] || null;
}

export function getMatchPercentageForTime(family: SportFamily | string, time: number): Maybe<number> {
  const wholeMatchTime = WholeMatchTime[family];
  if (!wholeMatchTime) { return null; }
  const percentage = (100 / wholeMatchTime) * time;
  return Math.round(percentage * 10) / 10;
}

export function filterMainPeriods<Period extends SportlineEventPeriod>(periods: Period[]): Period[] {
  return periods.filter((period) => (period.type === SportlineEventPeriodType.Main));
}
