import type {
  AddToCustomerLeaguesParameters,
} from 'web/src/modules/sportline/submodules/favorites/types';

export default function isAddToCustomerLeaguesParametersEquals(
  first: AddToCustomerLeaguesParameters,
  second: AddToCustomerLeaguesParameters,
): boolean {
  return first.leagueId === second.leagueId && first.sportId === second.sportId;
}
