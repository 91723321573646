import { defineStore } from 'pinia';
import {
  ref,
} from 'vue';

import { nextAnimationFrame } from '@leon-hub/html-utils';
import {
  loadGoogleFontsUrls,
} from '@leon-hub/fonts';

export const useFontsStore = defineStore('fonts', () => {
  const isFontsLoaded = ref<boolean>(false);

  async function loadFonts(): Promise<void> {
    if (!process.env.VUE_APP_PRERENDER) {
      try {
        await loadGoogleFontsUrls();
      } finally {
        await nextAnimationFrame();
        isFontsLoaded.value = true;
      }
    } else {
      isFontsLoaded.value = true;
    }
  }

  return {
    isFontsLoaded,
    loadFonts,
  };
});
