<script lang="ts" setup>
import { VIcon } from '@components/v-icon';

import VBadge from 'web/src/components/Badge/VBadge/VBadge.vue';
import { useDataTestUnit } from 'web/src/testing/directives';

import { useProfileBonusesCardDescriptionColumn }
  from './composables';

const props = withDefaults(defineProps<ProfileBonusesCardDescriptionColumnProps>(), {
  title: '',
  value: '',
  badgeCount: 0,
});
const { vDataTestUnit } = useDataTestUnit();
interface ProfileBonusesCardDescriptionColumnProps {
  title?: string;
  value?: string;
  badgeCount?: number;
}

const {
  iconProps,
  badgeProps,
} = useProfileBonusesCardDescriptionColumn(props);
</script>

<template>
  <div v-auto-id="'ProfileBonusesCardDescriptionColumn'" :class="$style['bonus-card-description-column']">
    <div :class="$style['bonus-card-description-column__title']">
      {{ title }}
    </div>
    <div :class="$style['bonus-card-description-column__value']">
      <span>
        {{ value }}
      </span>
      <template v-if="badgeCount > 1">
        <VIcon
          v-data-test-unit="{ el: 'bonus-card-description-column-icon' }"
          v-bind="iconProps"
          :class="$style['bonus-card-description-column__icon']"
        />
        <VBadge
          v-data-test-unit="{ el: 'bonus-card-description-column-badge' }"
          v-bind="badgeProps"
          :class="$style['bonus-card-description-column__badge']"
        />
      </template>
    </div>
  </div>
</template>

<style lang="scss" module>
.bonus-card-description-column {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;

  &__title {
    @include regular\13\16;

    margin-bottom: 4px;
    overflow: hidden;
    color: var(--TextSecondary);
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__value {
    @include medium\16\20\025;

    display: flex;
    align-items: center;
    color: var(--TextDefault);
  }

  &__icon {
    margin: 0 2px;
  }
}
</style>
