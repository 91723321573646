import type { Ref } from 'vue';
import { toRef } from 'vue';

import useRootStore from 'web/src/modules/core/store/useRootStore';

export interface UseIsDevIP {
  isDevIP: Ref<boolean>;
}

const useIsDevIP = (): UseIsDevIP => {
  const isDevIP = toRef(useRootStore(), 'isDevIP');

  return {
    isDevIP,
  };
};

export default useIsDevIP;
