export enum SocialIconName {
  SOCIAL_BAIDU = 'social-baidu',
  SOCIAL_BUFFER = 'social-buffer',
  SOCIAL_EVERNOTE = 'social-evernote',
  SOCIAL_FACEBOOK = 'social-facebook',
  SOCIAL_FLIPBOARD = 'social-flipboard',
  SOCIAL_HACKERNEWS = 'social-hackernews',
  SOCIAL_INSTAPAPER = 'social-instapaper',
  SOCIAL_INSTAGRAM_FILLED = 'social-instagram-filled',
  SOCIAL_LINE = 'social-line',
  SOCIAL_LINKEDIN = 'social-linkedin',
  SOCIAL_MAIL = 'social-mail',
  SOCIAL_MESSENGER = 'social-messenger',
  SOCIAL_ODNOKLASSNIKI = 'social-odnoklassniki',
  SOCIAL_PINTEREST = 'social-pinterest',
  SOCIAL_POCKET = 'social-pocket',
  SOCIAL_QUORA = 'social-quora',
  SOCIAL_REDDIT = 'social-reddit',
  SOCIAL_SKYPE = 'social-skype',
  SOCIAL_SMS = 'social-sms',
  SOCIAL_STUMBLEUPON = 'social-stumbleupon',
  SOCIAL_TELEGRAM = 'social-telegram',
  SOCIAL_TUMBLR = 'social-tumblr',
  SOCIAL_TWITTER = 'social-twitter',
  SOCIAL_VIBER = 'social-viber',
  SOCIAL_VK = 'social-vk',
  SOCIAL_WEIBO = 'social-weibo',
  SOCIAL_WHATSAPP = 'social-whatsapp',
  SOCIAL_WHATSAPP_FILLED = 'social-whatsapp-filled',
  SOCIAL_WORDPRESS = 'social-wordpress',
  SOCIAL_XING = 'social-xing',
  SOCIAL_YAMMER = 'social-yammer',
}
