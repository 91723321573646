import type { DefaultRouteConfig } from 'web/src/modules/core/services/router/types';

export default function getCasinoLobbyRoutePage({
  name,
  path,
  meta,
  seoController,
  access,
}: Pick<DefaultRouteConfig, 'name' | 'path' | 'meta' | 'seoController' | 'access'>): DefaultRouteConfig {
  return {
    name,
    path,
    meta: {
      ...meta,
      isEgsLobbyPage: true,
    },
    access,
    prefetch: () => import('../prefetch/CasinoLobbyPagePrefetch'),
    component: () => import('../pages/CasinoLobbyRoutePage/CasinoLobbyRoutePage.vue'),
    leftSideBar: process.env.VUE_APP_LAYOUT_DESKTOP
      ? () => import('../views/CasinoSidebarRouteComponent/CasinoSidebarRouteComponent.vue')
      : undefined,
    isRightSidebarHidden: true,
    isLeftSidebarToggleHidden: true,
    navigation: process.env.VUE_APP_LAYOUT_PHONE && !process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED
      // eslint-disable-next-line max-len
      ? () => import('web/src/modules/casino/submodules/navigation/views/CasinoNavigationRouteComponent/CasinoNavigationRouteComponent.vue')
      : undefined,
    seoController,
    // eslint-disable-next-line max-len
    topAuthorizedSlot: () => import('web/src/modules/referral-program/views/ReferralProgramPopup/ReferralProgramPopup.vue'),
  };
}
