import { LobbyItemType } from '@leon-hub/api-sdk';

import type {
  LobbyItemSdk,
  LobbyAllTopWinnersItem,
} from 'web/src/modules/lobby/types';

export function isLobbyAllTopWinnersItem(input: LobbyItemSdk): input is LobbyAllTopWinnersItem {
  return input.typeId === LobbyItemType.ALL_TOP_WINNERS;
}
