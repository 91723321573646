import { defineStore } from 'pinia';
import { watch } from 'vue';

import type { WebSocketSubscription, WebSocketGraphQLMethod } from '@leon-hub/websocket';
import { GraphQLWebSocketService } from '@leon-hub/websocket';

import useIsLoggedIn from 'web/src/modules/auth/composables/useIsLoggedIn';
import useWebSocketsConfig from 'web/src/modules/core/composables/site-config/useWebSocketsConfig';

const useWebsocketSubscriptionsStore = defineStore('websocket-subscriptions', () => {
  const webSocketService = new GraphQLWebSocketService();

  const { isLoggedIn } = useIsLoggedIn();
  const { isEnabled } = useWebSocketsConfig();

  let subscriptions: WebSocketSubscription<WebSocketGraphQLMethod>[] = [];

  function isSubscriptionAllowed<T extends WebSocketGraphQLMethod>(subscription: WebSocketSubscription<T>) {
    return subscription.isAllowed({
      isLoggedIn: isLoggedIn.value,
    });
  }

  function subscribeSubscription<T extends WebSocketGraphQLMethod>(subscription: WebSocketSubscription<T>) {
    if (!webSocketService.isSubscribed(subscription)) {
      webSocketService.subscribe(subscription);
    }
  }

  function unsubscribeSubscription<T extends WebSocketGraphQLMethod>(subscription: WebSocketSubscription<T>) {
    if (webSocketService.isSubscribed(subscription)) {
      webSocketService.unsubscribe(subscription);
    }
  }

  function subscribeWs<T extends WebSocketGraphQLMethod>(subscription: WebSocketSubscription<T>): void {
    if (isSubscriptionAllowed(subscription)) {
      subscribeSubscription(subscription);
    } else {
      unsubscribeSubscription(subscription);
    }
  }

  function subscribe<T extends WebSocketGraphQLMethod>(input: WebSocketSubscription<T>): void {
    subscriptions.push(input);
    subscribeWs(input);
  }

  function unsubscribe<T extends WebSocketGraphQLMethod>(input: WebSocketSubscription<T>): void {
    unsubscribeSubscription(input);
    subscriptions = subscriptions.filter((subscription) => subscription.method !== input.method);
  }

  function init(): void {
    watch(isLoggedIn, (newValue) => {
      for (const subscription of subscriptions) {
        subscribeWs(subscription);

        if (subscription.isPollingMustBeCalledOnLogin() && newValue) {
          subscription.callPollingRequest();
        }
      }
    }, {
      immediate: true,
    });

    watch(isEnabled, (newValue) => {
      webSocketService.setEnabled(newValue);
    }, {
      immediate: true,
    });
  }

  init();

  return {
    subscribe,
    unsubscribe,
  };
});

export default useWebsocketSubscriptionsStore;
