<script setup lang="ts">
import { onMounted, onBeforeUnmount } from 'vue';

import { SlipTypeId } from '@leon-hub/api-sdk';
import { IconName } from '@leon-hub/icons';

import { VIcon } from '@components/v-icon';

import { VFastMoneyChoice } from 'web/src/components/FastMoneyChoice';
import VTabsButton from 'web/src/components/Tabs/VTabsButton/VTabsButton.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { StakeInput } from 'web/src/components/Input';
import BetSlipKeyboard from 'web/src/modules/slip/components/BetSlipKeyboard/components/BetSlipKeyboard.vue';

import { SlipListBettingItem } from '../SlipListItem';
import useBetSlipMain from './composables/useBetSlipMain';
import type { BetSlipMainEmits, BetSlipMainProps } from './types';

// eslint-disable-next-line vue/define-macros-order
const props = withDefaults(defineProps<BetSlipMainProps>(), {
  betMode: SlipTypeId.SINGLE,
  betEvents: () => [],
  fastBetsItems: () => [],
  stakeInputValidationTimeout: 2000,
  currencySymbol: '$',
});

const emits = defineEmits<BetSlipMainEmits>();

const {
  betModeTabs,
  showDeleteAnimation,
  selectBetMode,
  onEventDeleteClick,
  onEventBankerClick,
  showInputBlock,
  onTraceClick,
  onStakeInput,
  onStakeInputFocus,
  onStakeInputBlur,
  onEventAcceptChanges,
  onAppear,
  onDisappear,
  onBackspaceInput,
  onNumberInput,
  onDecimalInput,
  selectFastBet,
  onEditFastBets,
} = useBetSlipMain(props, emits);

onMounted(onAppear);
onBeforeUnmount(onDisappear);
</script>

<template>
  <div v-auto-id="'BetSlipMain'"
    :class="{
      [$style['bet-slip-main']]: true,
      [$style['bet-slip-main--desktop']]: !!'1',
    }"
  >
    <header
      :class="$style['bet-slip-main__header']"
      role="tablist"
    >
      <VTabsButton
        v-for="{id, label, disabled} in betModeTabs"
        :id="id"
        :key="id"
        :disabled="disabled"
        :active="id === betMode"
        is-bordered
        @click="selectBetMode(id)"
      >
        {{ label }}
      </VTabsButton>
    </header>
    <div :class="$style['bet-slip-main__content']">
      <ul :class="$style['bet-slip-main__list']">
        <li
          v-for="({
            id,
            minStake,
            maxStake,
            maxAvailableBet,
            needToAcceptChanges,
            stakeValue,
            errorMessage,
            ...eventProps
          }, index) in betEvents"
          :key="id"
          :class="$style['bet-slip-main__row']"
        >
          <SlipListBettingItem
            v-bind="eventProps"
            :bet-mode="betMode"
            :bankers-available="bankersAvailable"
            :need-to-accept-changes="needToAcceptChanges"
            :show-delete-animation="showDeleteAnimation"
            :error-message-hidden="hideInputErrorsId === id"
            :error-message="errorMessage"
            @delete-click="onEventDeleteClick(id)"
            @banker-click="onEventBankerClick(id)"
          >
            <template
              v-if="!isHidden"
              #control
            >
              <template v-if="needToAcceptChanges && focusedItemId !== id">
                <VButton
                  :label="$t('WEB2_SLIP_ACCEPT')"
                  :kind="ButtonKind.CANCEL"
                  :is-uppercase="false"
                  @click="onEventAcceptChanges(id)"
                />
              </template>
              <template v-else-if="showStakeInput">
                <div :class="$style['bet-slip-main__stake-input-wrapper']">
                  <StakeInput
                    :is-focused="focusedItemId === id"
                    :value="stakeValue"
                    :currency-symbol="currencySymbol"
                    :have-error="!!errorMessage"
                    @focus="onStakeInputFocus(id)"
                    @blur="onStakeInputBlur(id)"
                    @input="(value) => onStakeInput(value, id)"
                  />
                </div>
              </template>
            </template>
          </SlipListBettingItem>
          <div
            v-if="showInputBlock && lastFocusedItemId === id"
            :class="$style['bet-slip-main__singles-input']"
          >
            <VFastMoneyChoice
              v-if="isFastBetsEnabled"
              :current-value="Number(stakeValue)"
              :show-edit-button="isLoggedIn"
              :max-enabled="maxFastBetEnabled"
              :max-available-value="maxStake"
              :min-available-value="minStake"
              :max-value="maxAvailableBet"
              :items="fastBetsItems"
              @edit-click="onEditFastBets"
              @select-value="(value) => selectFastBet(value, id)"
            />
            <BetSlipKeyboard
              v-if="!'1'"
              scroll-to-keyboard
              @hide-keyboard="onStakeInputBlur(id)"
              @backspace="onBackspaceInput(id)"
              @number-input="(number) => onNumberInput(number, id)"
              @decimal="onDecimalInput(id)"
            />
          </div>
          <VIcon
            v-if="showTraceButton && isMultiSinglesMode"
            :name="IconName.SEARCH"
            title="DEVIP trace"
            :class="$style['bet-slip-main__trace']"
            data-test-id="trace-button"
            @click="onTraceClick(index)"
          />
        </li>
      </ul>
    </div>
    <VIcon
      v-if="showTraceButton && !isMultiSinglesMode"
      :name="IconName.SEARCH"
      title="DEVIP trace"
      :class="$style['bet-slip-main__trace']"
      data-test-id="trace-button"
      @click="onTraceClick(0)"
    />
  </div>
</template>

<style lang="scss" module>
.bet-slip-main {
  position: relative;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  overflow: hidden;

  &--desktop {
    flex: initial;
  }

  &__header {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    height: 44px;
  }

  &__list {
    flex: none;
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__row {
    position: relative;
    padding: 0;
    margin: 0;

    &:last-child {
      border-bottom: 1px solid var(--Layer0);
    }
  }

  &__trace {
    @include z-index(absolute-item);

    position: absolute;
    right: 2px;
    bottom: 4px;
    z-index: 99;
    flex: none;
    width: 24px;
    height: 24px;
    padding: 3px;
    color: var(--AlertDefault);
    cursor: help;
    background: var(--Layer1);
    border: 1px solid var(--AlertDefault);
    border-radius: 50%;
  }

  &__singles-input {
    border-top: 1px solid var(--Layer0);
  }

  &__stake-input-wrapper {
    width: 136px;
  }
}
</style>
