import type { Ref } from 'vue';
import { computed } from 'vue';

import type {
  BetlineMatchStatisticsMatch,
  BetlineSportEventMatchStatistics,
} from 'web/src/modules/sportline/types/rest';

interface UseLastGamesMatchStatisticProps {
  betlineMatchStatistics: Ref<Maybe<BetlineSportEventMatchStatistics>>;
}

interface UseLastGamesMatchStatisticComposable {
  hostMatches: Ref<BetlineMatchStatisticsMatch[]>;
  guestMatches: Ref<BetlineMatchStatisticsMatch[]>;
}

export function useLastGamesMatchStatistic(
  props: UseLastGamesMatchStatisticProps,
): UseLastGamesMatchStatisticComposable {
  const hostMatches = computed<BetlineMatchStatisticsMatch[]>(
    () => [...(props.betlineMatchStatistics.value?.homeMatches ?? [])],
  );
  const guestMatches = computed<BetlineMatchStatisticsMatch[]>(
    () => [...(props.betlineMatchStatistics.value?.awayMatches ?? [])],
  );

  return {
    hostMatches,
    guestMatches,
  };
}
