<script lang="ts" setup>
import { useVDynamicContentLink } from './composables';

interface VDynamicContentLinkProps {
  href?: string;
  target?: string;
  rel?: string;
  isCmsContent?: boolean;
  useEmitter?: boolean;
}

interface VDynamicContentLinkEmits {
  (e: 'click-link', value: { href: string }): void;
}

const props = withDefaults(defineProps<VDynamicContentLinkProps>(), {
  href: '',
});
const emit = defineEmits<VDynamicContentLinkEmits>();
const {
  handleClick,
  computedRel,
} = useVDynamicContentLink(props, emit);
</script>

<template>
  <a v-auto-id="'VDynamicContentLink'"
    :href="href"
    :rel="computedRel"
    :target="target"
    @click="handleClick($event)"
  >
    <slot />
  </a>
</template>
