<script lang="ts" setup>
import { useRouter } from 'vue-router';

import { RouteName } from '@leon-hub/routing-config-names';
import {
  IconName,
  IconSize,
} from '@leon-hub/icons';

import CasinoLoyaltyProgramIcon
  from 'web/src/modules/casino-loyalty-program/components/CasinoLoyaltyProgramIcon/CasinoLoyaltyProgramIcon.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import {
  ButtonHeight,
  ButtonKind,
} from 'web/src/components/Button/VButton/enums';
import { CasinoLoyaltyProgramIconSizes } from 'web/src/modules/casino-loyalty-program/store/types';

import type {
  CasinoGameSideBarAnonymousEmits,
  CasinoGameSideBarAnonymousProps,
} from './types';

withDefaults(defineProps<CasinoGameSideBarAnonymousProps>(), {});
const emit = defineEmits<CasinoGameSideBarAnonymousEmits>();

const router = useRouter();
const imageDefaultSrc = require('web/src/assets/images/beginner.png');

function onClose(): void {
  emit('click-close');
}

function onClick(): void {
  void router.push({ name: RouteName.REGISTRATION });
}
</script>

<template>
  <div v-auto-id="'CasinoGameSideBarAnonymous'"
    :class="$style['casino-game-sidebar-anonymous']"
  >
    <VButton
      v-if="isOverGame"
      :class="$style['casino-game-sidebar-anonymous__button-close']"
      :icon-name="IconName.CROSS"
      :icon-size="IconSize.SIZE_24"
      :kind="ButtonKind.TRANSPARENT"
      @click="onClose"
    />
    <CasinoLoyaltyProgramIcon
      :size="CasinoLoyaltyProgramIconSizes.SIZE_180"
      :image-src="imageDefaultSrc"
      :class="$style['casino-game-sidebar-anonymous__icon']"
    />
    <div :class="$style['casino-game-sidebar-anonymous__heading']">
      {{ $t('WEB2_GAME_SIDEBAR_UNLOGGED_TITLE') }}
    </div>
    <div :class="$style['casino-game-sidebar-anonymous__text']">
      {{ $t('WEB2_GAME_SIDEBAR_UNLOGGED_TEXT') }}
    </div>
    <VButton
      :label="$t('WEB2_SIGNIN_AND_CLAIM')"
      :height="ButtonHeight.SMALL"
      :class="$style['casino-game-sidebar-anonymous__button']"
      @click.prevent="onClick"
    />
  </div>
</template>

<style lang="scss" module>
.casino-game-sidebar-anonymous {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 16px;

  &__button-close {
    position: absolute;
    top: 0;
    right: 0;
  }

  &__icon {
    margin-bottom: 16px;
  }

  &__heading,
  &__text {
    color: var(--TextDefault);
    text-align: center;
  }

  &__heading {
    @include medium\24\28;

    margin-bottom: 8px;
  }

  &__text {
    @include medium\14\16\025;
  }

  &__button {
    margin-top: 16px;
  }
}
</style>
