import { EditionValueLayoutPhone } from '@leon-hub/environment-editions';

import { useCookies } from 'web/src/modules/core/composables';
import {
  useRootStore,
  useSiteConfigStore,
} from 'web/src/modules/core/store';

export default async function forcePhoneLayout(): Promise<void> {
  if (
    process.env.VUE_APP_PLATFORM_WEB
    && process.env.VUE_APP_LAYOUT_DESKTOP
    && !process.env.VUE_APP_PRERENDER
    && useSiteConfigStore().isForcedLayoutEnabled
    && window.screen.width < useRootStore().desktopScreenMinWidth
  ) {
    await useCookies().set({ name: 'forcedLayout', value: EditionValueLayoutPhone });
    window.location.reload();
  }
}
