import type { Ref } from 'vue';
import { ref, nextTick } from 'vue';

import type { VSwiperRef } from 'web/src/components/Swiper/VSwiper/types';

interface FavoriteRegionFilterSwiperProps {
  selectedIndex: Ref<number>;
}

export interface FavoriteRegionFilterSwiperComposable {
  swiper: Ref<VSwiperRef | undefined>;
  slideToActive(smooth: boolean): Promise<void>;
}

export function useFavoriteRegionFilterSwiper(
  { selectedIndex }: FavoriteRegionFilterSwiperProps,
): FavoriteRegionFilterSwiperComposable {
  const swiper = ref<VSwiperRef>();

  return {
    swiper,
    async slideToActive(smooth = false): Promise<void> {
      await nextTick();

      swiper.value?.slideToSlide(selectedIndex.value, {
        isCentered: true,
        smooth,
        speed: 300,
      });
    },
  };
}
