import type { FieldMappingKeys } from '../types';

export const defaultMappingKeys: FieldMappingKeys = {
  default: 'defaultValue',
  disabled: 'disabled',
  title: 'title',
  hidden: 'hidden',
  widget: 'widget',
  labels: 'labels',
  options: 'options',
  hintMessage: 'hintMessage',
};
