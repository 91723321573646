import type { Without } from '@leon-hub/types';
import type { AbstractErrorOptions } from '@leon-hub/errors';
import { AbstractError } from '@leon-hub/errors';

import { AppErrorCode } from './AppErrorCode';

interface AppErrorOptions extends Without<AbstractErrorOptions, 'code'> {
  code?: AppErrorCode;
}

export class AppError extends AbstractError {
  constructor(options?: AppErrorOptions) {
    super({
      ...options,
      code: options?.code ?? AppErrorCode.APP_UNEXPECTED_ERROR,
    });
  }
}
