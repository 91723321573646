import type { Ref } from 'vue';
import { ref, toRef } from 'vue';

import ResendSmsStopwatch from 'web/src/utils/phone-validation/ResendSmsStopwatch';
import { useSiteConfigStore } from 'web/src/modules/core/store';

interface CountdownTimerManagerComposable {
  resendSmsTimeOut: Ref<number>;
  resumeResendSmsTimeout(): void;
  restartResendSmsTimeout(): void;
  pauseResendSmsTimeout(): void;
  isTimerRestarted(): boolean;
}

export function useCountdownTimerManager(): CountdownTimerManagerComposable {
  const siteConfigStore = useSiteConfigStore();
  const retrySmsCodeTimer = toRef(siteConfigStore, 'smsResendCodeTimer');
  const resendSmsTimeOut = ref(0);

  const resendSmsStopwatch = new ResendSmsStopwatch((time: number) => {
    setResendSmsTimeOut(time);
  }, 'registrationSmsTimeOut');

  function setResendSmsTimeOut(timeOut: number): void {
    resendSmsTimeOut.value = timeOut;
  }

  function resumeResendSmsTimeout(): void {
    const savedResendTime = resendSmsStopwatch.getCurrentTimeFromLocalStorage();

    if (savedResendTime) {
      void resendSmsStopwatch.run(savedResendTime);
    }
  }

  function restartResendSmsTimeout(): void {
    resendSmsStopwatch.clear();
    void resendSmsStopwatch.run(retrySmsCodeTimer.value);
  }

  function pauseResendSmsTimeout(): void {
    void resendSmsStopwatch.pause();
  }

  function isTimerRestarted(): boolean {
    const savedResendTime = resendSmsStopwatch.getCurrentTimeFromLocalStorage();
    if (savedResendTime) {
      void resendSmsStopwatch.run(savedResendTime);
      return false;
    }
    void resendSmsStopwatch.run(retrySmsCodeTimer.value);
    return true;
  }

  return {
    resendSmsTimeOut,
    resumeResendSmsTimeout,
    restartResendSmsTimeout,
    pauseResendSmsTimeout,
    isTimerRestarted,
  };
}
