import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import type { LandingStylePreset } from 'web/src/modules/landings/system/types';
import { LandingStyleAnimation } from 'web/src/modules/landings/system/types';

export interface PresetClassComposable {
  presetClass: ComputedRef<string>;
  animationClass: ComputedRef<string>;
}

export default function useGlobalLandingClass(
  preset: LandingStylePreset,
  animation: LandingStyleAnimation,
): PresetClassComposable {
  import(`./system/presets/${preset}.scss`);

  if (animation !== LandingStyleAnimation.NO_ANIMATION) {
    import(`./system/animations/${animation}.scss`);
  }

  const presetClass = computed(() => `landing--${preset.toLowerCase()}`);
  const animationClass = computed(() => (
    animation !== LandingStyleAnimation.NO_ANIMATION ? `landing--${animation.toLowerCase()}` : ''
  ));

  return {
    presetClass,
    animationClass,
  };
}
