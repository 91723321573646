import type {
  GetLobbyResponse,
  EgsGame,
} from 'web/src/modules/egs/types';
import type {
  ParsedLobbyItems,
  LobbyItemUnit,
} from 'web/src/modules/lobby/types';
import type { SportEventDetails } from 'web/src/modules/sportline/submodules/event-details/types';
import {
  filterCorruptedEvents,
  logCorruptedEventsIds,
} from 'web/src/modules/sportline/utils/response';
import type { DetailsAdapterOptions } from 'web/src/modules/sportline/utils/rest';
import {
  DetailsAdapter,
} from 'web/src/modules/sportline/utils/rest';

import isLobbyGamesItem from './isLobbyGamesItem';
import isLobbyGroupsItem from './isLobbyGroupsItem';
import isLobbyPromotionsItem from './isLobbyPromotionsItem';
import isLobbyTopWinnersItem from './isLobbyTopWinnersItem';
import isLobbyBannersItem from './isLobbyBannersItem';
import isLobbySportlineTopMatchItem from './isLobbySportlineTopMatchItem';
import { isLobbyAllTopWinnersItem } from './isLobbyAllTopWinnersItem';

export interface ParseLobbyOptions {
  sportEventDetails?: DetailsAdapterOptions;
}

// eslint-disable-next-line sonarjs/cognitive-complexity,max-len
export default function parseLobbyItems(response: GetLobbyResponse, options: ParseLobbyOptions = {}): ParsedLobbyItems {
  const games: EgsGame[] = [];
  const items: LobbyItemUnit[] = [];

  for (const lobbyItem of response.items) {
    if (isLobbyGamesItem(lobbyItem)) {
      games.push(...lobbyItem.games);

      items.push(lobbyItem);
    }

    if (isLobbyTopWinnersItem(lobbyItem)) {
      for (const { egsGame } of lobbyItem.winners) {
        games.push(egsGame);
      }

      items.push(lobbyItem);
    }

    if (isLobbyGroupsItem(lobbyItem)
      || isLobbyPromotionsItem(lobbyItem)
      || isLobbyBannersItem(lobbyItem)
      || isLobbyAllTopWinnersItem(lobbyItem)
    ) {
      items.push(lobbyItem);
    }

    if (isLobbySportlineTopMatchItem(lobbyItem)) {
      const events: SportEventDetails[] = [];
      if (lobbyItem.events && Array.isArray(lobbyItem.events)) {
        for (const event of filterCorruptedEvents(lobbyItem.events, logCorruptedEventsIds)) {
          events.push(
            (new DetailsAdapter(event, options.sportEventDetails))
              .sportEventDetails,
          );
        }
      }

      items.push({
        ...lobbyItem,
        events,
      });
    }
  }

  return {
    games,
    items,
  };
}
