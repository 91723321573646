let counter = 0;
const registry = new WeakMap<Function, number>();

/**
 * Get function unique id.
 */
export function getFunctionId(callback: Function): number {
  let id = registry.get(callback);
  if (id !== undefined) {
    return id;
  }
  counter += 1;
  id = counter;
  registry.set(callback, id);
  return id;
}
