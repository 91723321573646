interface Payload {
  stake: number;
  winValue: number;
  taxPercent: number;
}

export function getNettWinning({ stake, winValue, taxPercent }: Payload): number {
  /**
   * https://confluence.leoncorp.net/display/SportCatalog/PROJ-453+Functional+Requirements
   * Max return = (Winnings – Stake)*0.9 + Stake
   * where payout is a normal payout according to existing settlement mechanisms.
   * */
  if (!taxPercent) {
    return winValue;
  }
  return (winValue - stake) * (1 - (taxPercent / 100)) + stake;
}
