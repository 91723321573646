import { ref } from 'vue';

import { IconName } from '@leon-hub/icons';

import type { ModalWindowHeaderProps } from 'web/src/components/Modal/ModalWindowHeader/types';
import type { I18nFunctionReactive } from 'web/src/modules/i18n/types';
import { useDateLocalizers } from 'web/src/modules/i18n/composables/useDateLocalizers';

export default function getHeaderProperties(
  $t: I18nFunctionReactive,
): ModalWindowHeaderProps {
  return {
    title: process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED
      ? $t('WEB2_LOYALTY_PROGRAM_CURRENT_MONTH_REWARDS', ref({
        currentMonth: useDateLocalizers().toMediumLocaleDate(new Date(), 'MMMM'),
      })).value : $t('WEB2_LOYALTY_PROGRAM_CASINO_LEVEL').value,
    prefixIconName: process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED
      ? IconName.ANGLE_SMALL_LEFT : IconName.ARROW_LEFT,
    suffixIconName: !process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED && process.env.VUE_APP_LAYOUT_DESKTOP
      ? IconName.CROSS : undefined,
  };
}
