import { RouteName } from '@leon-hub/routing-config-names';

import { AppModule } from 'web/src/modules/core/plugins/AppModule';
import type { AppVueRouter } from 'web/src/modules/core/services/router/types';
import { ModalDesktopPreset } from 'web/src/modules/core/enums';

import { addAccountHistoryRoutes } from './submodules/account-history/AccountHistoryRoutes';

const achievementsTitle = '{{$t(\'WEB2_ACHIEVEMENTS_TITLE\')}}';

export class ProfileModule extends AppModule {
  /* eslint-disable max-len */

  // eslint-disable-next-line class-methods-use-this,sonarjs/cognitive-complexity
  addRoutes(router: AppVueRouter): void {
    let topBar;
    if (process.env.VUE_APP_LAYOUT_PHONE && !process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED) {
      topBar = () => import(
        'web/src/modules/profile/layouts/UserProfileTopBarPhoneRouteComponent/UserProfileTopBarPhoneRouteComponent.vue'
      );
    } else if (process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED) {
      topBar = () => import(
        'web/src/modules/core/views/SProfileTopBarRouteComponent/SProfileTopBarRouteComponent.vue'
      );
    } else {
      topBar = undefined;
    }
    router.addProfileRoute({
      name: RouteName.PROFILE,
      path: '/profile',
      component: () => import('web/src/modules/profile/pages/UserProfileRoutePage/UserProfileRoutePage.vue'),
      title: '{{$t(\'JSPACC_ACC_TLT\')}}',
      topBar,
      isTabBarVisible: true,
      topBarType: process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED ? 'secondary' : undefined,
    });

    router.addProfileRoute({
      name: RouteName.CUSTOMER_SETTINGS,
      path: '/profile/settings',
      component: () => import('web/src/modules/profile/pages/UserSettingsRoutePage/UserSettingsRoutePage.vue'),
      title: '{{$t(\'JSPNAV_USTAT_SETTINGS\')}}',
    });

    router.addProfileRoute({
      name: RouteName.CUSTOMER_VERIFICATION,
      path: '/profile/settings/verify/:type?',
      component: () => import('web/src/modules/profile/submodules/verification/pages/VerificationRoutePage.vue'),
      prefetch: () => import('web/src/modules/profile/submodules/verification/prefetch/VerificationPrefetch'),
      topBar: () => import(
        'web/src/modules/profile/submodules/verification/components/CustomerVerificationTopBarRouteComponent.vue'
      ),
    });

    router.addProfileRoute({
      name: RouteName.CUSTOMER_VERIFICATION_SELECT_TIME,
      path: '/profile/settings/verify/select-time',
      component: () => import(
        'web/src/modules/profile/submodules/verification/components/Video/CustomerVerificationSelectTimeRoute.vue'
      ),
      title: '{{$t(\'WEB2_VERIFY_PAGE_TITLE\')}}',
    });

    router.addModalRoute({
      name: RouteName.CUSTOMER_VERIFICATION_CALL,
      path: '/profile/settings/verify/call',
      component: () => import(
        'web/src/modules/profile/submodules/verification/components/Video/CustomerVerificationVideoCall.vue'
      ),
      topBar: () => import(
        'web/src/modules/profile/submodules/verification/components/CustomerVerificationVideoCallTopBar.vue'
      ),
      title: '{{$t(\'WEB2_VERIFY_PAGE_TITLE\')}}',
      modalPreset: ModalDesktopPreset.LargeDesktopModal,
    });

    router.addProfileRoute({
      name: RouteName.CUSTOMER_PERSONAL_DATA,
      path: '/profile/settings/personal-data',
      component: () => import('web/src/modules/profile/pages/PersonalDataRoutePage/PersonalDataRoutePage.vue'),
      title: '{{$t(\'WEB2_PERSONAL_DATA\')}}',
    });

    router.addProfileRoute({
      name: RouteName.PASSWORD_CHANGE,
      path: '/profile/settings/password-change',
      component: () => import('web/src/modules/profile/pages/PasswordChangeRoutePage/PasswordChangeRoutePage.vue'),
      title: '{{$t(\'JS_PASSCHANGE_MOBILE\')}}',
    });

    router.addProfileRoute({
      name: RouteName.CUSTOMER_SUBSCRIPTIONS,
      path: '/profile/settings/subscriptions',
      component: () => import(
        'web/src/modules/profile/pages/CustomerSubscriptionsRoutePage/CustomerSubscriptionsRoutePage.vue'
      ),
      title: '{{$t(\'WEB2_MY_SUBSCRIPTIONS\')}}',
    });

    router.addProfileRoute({
      name: RouteName.CMS_VERIFICATION_REQUIREMENTS,
      path: '/customer-verification-requirements',
      component: () => import('web/src/modules/cms/components/VCmsContent/VCmsContent.vue'),
      props: {
        cmsKey: 'WEB2_VERIFICATION_REQUIREMENTS',
      },
      title: '{{$t(\'WEB2_VERIFY_REQUIREMENTS_TITLE\')}}',
    });

    if (process.env.VUE_APP_FEATURE_ACHIEVEMENTS_ENABLED) {
      router.addProfileRoute({
        name: RouteName.ACHIEVEMENTS,
        path: '/profile/achievements',
        component: () => import('web/src/modules/profile/submodules/achievements/pages/AchievementsRoutePage/AchievementsRoutePage.vue'),
        topBar: () => import(
          'web/src/modules/profile/submodules/achievements/views/AchievementsTopBarRouteComponent/AchievementsTopBarRouteComponent.vue'
        ),
        prefetch: () => import('web/src/modules/profile/submodules/achievements/prefetch/AchievementsRoutePagePrefetch'),
        title: achievementsTitle,
      });

      router.addProfileRoute({
        name: RouteName.AVATAR,
        path: '/profile/avatar',
        prefetch: () => import('web/src/modules/profile/submodules/achievements/prefetch/AvatarRoutePagePrefetch'),
        component: () => import('web/src/modules/profile/submodules/achievements/pages/AvatarRoutePage/AvatarRoutePage.vue'),
        title: '{{$t(\'WEB2_AVARAR_TITLE\')}}',
      });

      router.addProfileRoute({
        name: RouteName.ACHIEVEMENTS_SHARE,
        path: '/profile/achievements/share/:achievementId?',
        component: () => import('web/src/modules/profile/submodules/achievements/pages/AchievementsShareRoutePage/AchievementsShareRoutePage.vue'),
        prefetch: () => import('web/src/modules/profile/submodules/achievements/prefetch/AchievementsRoutePagePrefetch'),
        topBar: () => import('web/src/modules/profile/submodules/achievements/pages/AchievementsShareRoutePage/AchievementsShareTopBarRouteComponent/AchievementsShareTopBarRouteComponent.vue'),
        title: '{{$t(\'WEB2_ACHIEVEMENTS_SHARE_TITLE\')}}',
      });

      router.addModalRoute({
        name: RouteName.ACHIEVEMENTS_PUBLIC_PROFILE,
        modalPreset: process.env.VUE_APP_LAYOUT_DESKTOP
          ? ModalDesktopPreset.SmallDesktopModal
          : undefined,
        path: '/achievements/public/:id/:achievementId?',
        component: () => import('web/src/modules/profile/submodules/achievements/pages/AchievementsPublicProfileRoutePage/AchievementsPublicProfileRoutePage.vue'),
        topBar: () => import('web/src/modules/profile/submodules/achievements/views/AchievementsPublicProfileTopBarRouteComponent/AchievementsPublicProfileTopBarRouteComponent.vue'),
      });

      router.addProfileRoute({
        name: RouteName.ACHIEVEMENTS_NOTIFICATION,
        path: '/profile/achievements/notification',
        component: () => import('web/src/modules/profile/submodules/achievements/pages/AchievementsNotificationRoutePage/AchievementsNotificationRoutePage.vue'),
        prefetch: () => import('web/src/modules/profile/submodules/achievements/prefetch/AchievementsNotificationRoutePagePrefetch'),
        topBar: () => import('web/src/modules/profile/submodules/achievements/pages/AchievementsNotificationRoutePage/AchievementsNotificationTopBarRouteComponent/AchievementsNotificationTopBarRouteComponent.vue'),
      });

      router.addProfileRoute({
        name: RouteName.ACHIEVEMENTS_LIST,
        path: '/profile/achievements/list',
        topBar: () => import('web/src/modules/profile/submodules/achievements/pages/AchievementsListRoutePage/AchievementsListTopBarRouteComponent/AchievementsListTopBarRouteComponent.vue'),
        prefetch: () => import('web/src/modules/profile/submodules/achievements/prefetch/AchievementsRoutePagePrefetch'),
        component: () => import('web/src/modules/profile/submodules/achievements/pages/AchievementsListRoutePage/AchievementsListRoutePage.vue'),
        title: achievementsTitle,
      });

      router.addProfileRoute({
        name: RouteName.ACHIEVEMENTS_FILTER,
        path: '/profile/achievements/list/filter',
        topBar: () => import('web/src/modules/profile/submodules/achievements/pages/AchievementsFilterRoutePage/AchievementsListFilterTopBarRouteComponent/AchievementsListFilterTopBarRouteComponent.vue'),
        prefetch: () => import('web/src/modules/profile/submodules/achievements/prefetch/AchievementsRoutePagePrefetch'),
        component: () => import('web/src/modules/profile/submodules/achievements/pages/AchievementsFilterRoutePage/AchievementsFilterRoutePage.vue'),
        title: '{{$t(\'WEB2_ACHIEVEMENTS_FILTER_TITLE\')}}',
      });

      router.addProfileRoute({
        name: RouteName.ACHIEVEMENTS_DETAILS,
        path: '/profile/achievements/list/:id',
        topBar: () => import('web/src/modules/profile/submodules/achievements/pages/AchievementsDetailsRoutePage/AchievementsDetailsTopBarRouteComponent/AchievementsDetailsTopBarRouteComponent.vue'),
        prefetch: () => import('web/src/modules/profile/submodules/achievements/prefetch/AchievementsRoutePagePrefetch'),
        component: () => import('web/src/modules/profile/submodules/achievements/pages/AchievementsDetailsRoutePage/AchievementsDetailsRoutePage.vue'),
        title: achievementsTitle,
      });

      router.addProfileRoute({
        name: RouteName.PRIVACY,
        path: '/privacy',
        component: () => import('web/src/modules/profile/submodules/privacy/pages/PrivacyRoutePage.vue'),
        prefetch: () => import('web/src/modules/profile/submodules/achievements/prefetch/AchievementsRoutePagePrefetch'),
        title: '{{$t(\'WEB2_PRIVACY\')}}',
      });
    }

    if (process.env.VUE_APP_FEATURE_ACCOUNT_HISTORY_ENABLED) {
      addAccountHistoryRoutes(router);
    }
  }
}
