import type {
  Ref,
  WatchStopHandle,
} from 'vue';
import {
  ref,
  watch,
  computed,
  nextTick,
} from 'vue';

import type { SportFamily } from '@leon-hub/api-sdk';
import { useIntersectionObserver } from '@leon-hub/vue-utils';

import { useSportlineEventMarketsPriority } from 'web/src/modules/sportline/composables/markets';
import type {
  Market,
  SportlineEvent,
} from 'web/src/modules/sportline/types';
import type { MarketsGridFilterId, MarketsGridFilter } from 'web/src/modules/sportline/submodules/markets-grid/types';
import type { DetailsLayoutType } from 'web/src/modules/sportline/views/markets-list/enums';
import { getNonEmptyMarketsGridFilters } from 'web/src/modules/sportline/submodules/markets-grid/utils';

import useMarketsListResizableLayout from './useMarketsListResizableLayout';
import useMarketsListAnalytics from './useMarketsListAnalytics';
import useMarketsListOpenStateStorage from './useMarketsListOpenStateStorage';
import { useMarketsListTabsControl } from './useMarketsListTabsControl';
import { useMarketsListGroups } from './useMarketsListGroups';
import type {
  MarketGroup,
  MarketListToolbarReference,
} from '../types';

export interface MarketsListProps {
  toolbar: Ref<Maybe<MarketListToolbarReference>>;
  marketHolder: Ref<Optional<HTMLDivElement>>;
  columnOne: Ref<Optional<HTMLDivElement>>;
  columnTwo: Ref<Optional<HTMLDivElement>>;

  sportEvent: Ref<Maybe<SportlineEvent>>;
  representationSportFamily: Ref<Maybe<string | SportFamily>>;
  isLoaded: Ref<boolean>;
  defaultLayoutType: Ref<Maybe<DetailsLayoutType>>;
  isListItem: Ref<boolean>;
  isMarketGroupsTabsEnabled: Ref<boolean>;
  defaultMarketsLimit: Ref<number>;
  hasStatistic: Ref<boolean>;
  isPrimaryMarketFiltrationDisabled: Ref<boolean>;
}

interface MarketsListComposable {
  isReadyToRender: Ref<boolean>;
  hasAnyGroups: Ref<boolean>;
  isDisplayOneColumnLayout: Ref<boolean>;
  doShowTabs: Ref<boolean>;
  doShowInlineStatisticSwitch: Ref<boolean>;
  isStatisticSelected: Ref<boolean>;
  isDisplayStatistic: Ref<boolean>;
  isMoreMarketButtonVisible: Ref<boolean>;
  gridFilters: Ref<MarketsGridFilter[]>;
  displayedMarketGroupTabId: Ref<MarketsGridFilterId>;
  filteredGroups: Ref<MarketGroup[]>;
  oddGroups: Ref<MarketGroup[]>;
  evenGroups: Ref<MarketGroup[]>;
  onMarketTabClicked(value: MarketsGridFilterId): Promise<void>;
  onMoreMarketsClick(): Promise<void>;
  onStatisticSelectedChanged(value: boolean): void;
  onMarketGroupToggle(groupKey: string): void;
  startWatchers(): void;
  stopWatchers(): void;
  setIsActivated(value: boolean): void;
}

export function useMarketsList(props: MarketsListProps): MarketsListComposable {
  const {
    toolbar,
    marketHolder,
    columnOne,
    columnTwo,

    sportEvent,
    representationSportFamily,
    isLoaded,
    defaultLayoutType,
    isListItem,
    isMarketGroupsTabsEnabled,
    defaultMarketsLimit,
    hasStatistic,
    isPrimaryMarketFiltrationDisabled,
  } = props;

  const {
    markets,
    secondaryMarkets,
  } = useSportlineEventMarketsPriority({
    sportEvent,
    isPrimaryMarketFiltrationDisabled,
  });
  const {
    closedMarketGroups,
    toggleMarketGroupClosedStateInTheStorage,
  } = useMarketsListOpenStateStorage({
    representationSportFamily,
  });

  let marketGroupsWatcher: Maybe<WatchStopHandle> = null;
  let doShowTabsWatcher: Maybe<WatchStopHandle> = null;
  let isLoadedWatcher: Maybe<WatchStopHandle> = null;

  const isActivated = ref<boolean>(false);
  const tabsHasBeenShown = ref<boolean>(false);

  const isOutright = computed<boolean>(() => !!sportEvent.value?.isOutright);
  const allMarketsList = computed<Market[]>(() => (process.env.VUE_APP_LAYOUT_DESKTOP && !isListItem?.value
    ? secondaryMarkets.value
    : markets.value));
  const isEmptyMarketsList = computed<boolean>(() => allMarketsList.value.length === 0);
  const isOnlyOneMarket = computed(() => allMarketsList.value.length <= 1);
  const gridFilters = computed<MarketsGridFilter[]>(() => {
    if (!isMarketGroupsTabsEnabled?.value) { return []; }
    return getNonEmptyMarketsGridFilters(sportEvent.value?.marketGroups, allMarketsList.value);
  });

  const {
    allowedToSelectLayoutTypes,
    isDisplayOneColumnLayout,
    recalculateColumnsLayout,
  } = useMarketsListResizableLayout({
    isListItem,
    isOutright,
    isEmptyMarketsList,
    defaultLayoutType,
    isOnlyOneMarket,
    marketHolder,
    columnOne,
    columnTwo,
  });
  const {
    isAllTabSelected,
    isStatisticSelected,
    activeGridFilter,
    isSelectedTabExistInMarketGroups,
    doShowInlineStatisticSwitch,
    displayedMarketGroupTabId,
    resetSelectedTabId,
    recalculateActiveTabId,
    setSelectedTabId,
  } = useMarketsListTabsControl({
    gridFilters,
    hasStatistic,
    allowedToSelectLayoutTypes,
  });

  const isReadyToRender = ref(false);

  useIntersectionObserver(marketHolder, (isIntersecting: boolean) => {
    if (isIntersecting) {
      isReadyToRender.value = true;
      void nextTick().then(recalculateColumnsLayout);
    }
  }, {
    rootMargin: '0px',
    threshold: 0.1,
  }, true);

  const {
    isMoreMarketsClicked,
    isMoreMarketButtonVisible,
    filteredGroups,
    oddGroups,
    evenGroups,
    hasAnyGroups,
  } = useMarketsListGroups({
    allMarketsList,
    isAllTabSelected,
    activeGridFilter,
    closedMarketGroups,
    defaultMarketsLimit,
  });

  const isDisplayStatistic = computed<boolean>(
    () => !!hasStatistic?.value && (isStatisticSelected.value || isEmptyMarketsList.value),
  );
  const doShowTabs = computed<boolean>(() => {
    if (isEmptyMarketsList.value) { return false; }
    if (tabsHasBeenShown.value) { return true; }
    if (hasStatistic?.value) { return true; }
    return process.env.VUE_APP_LAYOUT_PHONE
      ? gridFilters.value.length > 0
      : (!isEmptyMarketsList.value || gridFilters.value.length > 0);
  });

  const {
    startAnalyticsWatchers,
    stopAnalyticsWatchers,
  } = useMarketsListAnalytics({
    isDisplayStatistic,
  });

  async function onMarketTabClicked(value: MarketsGridFilterId): Promise<void> {
    setSelectedTabId(value);
    await nextTick();
    recalculateColumnsLayout();
  }

  async function onMoreMarketsClick(): Promise<void> {
    isMoreMarketsClicked.value = true;
    await nextTick();
    recalculateColumnsLayout();
  }

  function onStatisticSelectedChanged(value: boolean): void {
    isStatisticSelected.value = value;
  }

  function onMarketGroupToggle(groupKey: string): void {
    toggleMarketGroupClosedStateInTheStorage(groupKey);
  }

  function startWatchers(): void {
    // @TODO check watch isSelectedTabExists
    marketGroupsWatcher = watch(gridFilters, async () => {
      if (isSelectedTabExistInMarketGroups.value) { return; }

      resetSelectedTabId();

      await nextTick();
      toolbar.value?.slideToActiveTab();
    }, { deep: true, immediate: true });

    doShowTabsWatcher = watch(doShowTabs, (newValue: boolean) => {
      if (newValue) { tabsHasBeenShown.value = true; }
    }, { immediate: true });

    isLoadedWatcher = watch(isLoaded, async (newValue: boolean) => {
      if (newValue) { recalculateActiveTabId(); }
      await nextTick();
      recalculateColumnsLayout();
    }, { immediate: true });

    startAnalyticsWatchers();
  }

  function stopWatchers(): void {
    marketGroupsWatcher?.();
    marketGroupsWatcher = null;

    doShowTabsWatcher?.();
    doShowTabsWatcher = null;

    isLoadedWatcher?.();
    isLoadedWatcher = null;

    stopAnalyticsWatchers();
  }

  function setIsActivated(value: boolean): void {
    isActivated.value = value;
    void nextTick().then(recalculateColumnsLayout);
  }

  return {
    isReadyToRender,
    hasAnyGroups,
    isDisplayOneColumnLayout,
    doShowTabs,
    doShowInlineStatisticSwitch,
    isStatisticSelected,
    isDisplayStatistic,
    isMoreMarketButtonVisible,
    gridFilters,
    displayedMarketGroupTabId,
    filteredGroups,
    oddGroups,
    evenGroups,
    onMarketTabClicked,
    onMoreMarketsClick,
    onStatisticSelectedChanged,
    onMarketGroupToggle,
    startWatchers,
    stopWatchers,
    setIsActivated,
  };
}
