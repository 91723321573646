import type { Ref } from 'vue';
import { toRef } from 'vue';

import { useSportlineEventId } from 'web/src/modules/sportline/composables/sportline-event';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import { useSportlineEventDetailsStore } from 'web/src/modules/sportline/submodules/event-details/store';
import type { StreamUrl, StreamErrorDescription } from 'web/src/modules/sportline/submodules/streams/types';
import type { SportlineEventId } from 'web/src/modules/sportline/types';

interface UseSportlineEventStreamComposable {
  isFloatingModeEnabled: Ref<boolean>;
  isStreamAvailable: Ref<boolean>;
  isStreamPreviewOnly: Ref<boolean>;
  doShowStreamPreviewInfo: Ref<boolean>;
  streamUrl: Ref<StreamUrl>;
  streamError: Ref<Maybe<StreamErrorDescription>>;
  sportlineEventId: Ref<Maybe<SportlineEventId>>;
  reloadStream(): Promise<void>;
  releaseStream(): void;
  onStreamStarted(): void;
}

export function useSportlineEventStream(): UseSportlineEventStreamComposable {
  const sportlineSettingsStore = useSportlineSettingsStore();
  const isFloatingModeEnabled = toRef(() => sportlineSettingsStore.isLiveStreamWidgetFloatingEnabled);

  const sportlineEventDetailsStore = useSportlineEventDetailsStore();
  const sportlineEvent = toRef(() => sportlineEventDetailsStore.sportEventDetails);
  const isStreamAvailable = toRef(() => sportlineEventDetailsStore.isStreamAvailable);
  const isStreamPreviewOnly = toRef(() => sportlineEventDetailsStore.isStreamPreviewOnly);
  const doShowStreamPreviewInfo = toRef(() => sportlineEventDetailsStore.doShowStreamPreviewInfo);
  const streamUrl = toRef(() => sportlineEventDetailsStore.streamUrl);
  const streamError = toRef(() => sportlineEventDetailsStore.streamError);
  const { reloadStream, onStreamStarted, releaseStream } = sportlineEventDetailsStore;

  const sportlineEventId = useSportlineEventId(sportlineEvent);

  return {
    isFloatingModeEnabled,
    isStreamAvailable,
    isStreamPreviewOnly,
    doShowStreamPreviewInfo,
    streamUrl,
    streamError,
    sportlineEventId,
    reloadStream,
    onStreamStarted,
    releaseStream,
  };
}
