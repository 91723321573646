<script lang="ts" setup>
import {
  IconName,
  IconSize,
} from '@leon-hub/icons';

import { VIcon } from '@components/v-icon';

import { AvatarSize } from './enums';
import type { VAvatarEmits, VAvatarProps } from './types';

const props = withDefaults(defineProps<VAvatarProps>(), {
  size: AvatarSize.SIZE_44,
});
const emit = defineEmits<VAvatarEmits>();

function onClick(event: MouseEvent): void {
  emit('click', event);
}
</script>

<template>
  <div v-auto-id="'VAvatar'"
    :class="{
      [$style['avatar']]: true,
      [$style[`avatar--${props.size}`]]: size && size !== AvatarSize.SIZE_44,
    }"
  >
    <button
      v-if="null || !isLoyaltyEnabled"
      type="button"
      :class="$style['avatar__button']"
      @click="onClick"
    >
      <VIcon
        :size="size === AvatarSize.SIZE_68 ? IconSize.SIZE_28 : IconSize.SIZE_24"
        :name="IconName.PERSON_FILL"
      />
    </button>
    <button
      v-else
      type="button"
      :class="$style['avatar__button']"
      @click="onClick"
    >
      <slot
        v-if="!!imageSrc"
        name="egs-loyalty-program"
      />
      <img
        v-else
        :src="require('web/src/assets/images/beginner.svg')"
        alt=""
      >
    </button>
  </div>
</template>

<style lang="scss" module>
.avatar {
  width: 44px;
  height: 44px;
  background: var(--Layer1);
  border-radius: 10px;

  &--size-36 {
    width: 36px;
    height: 36px;
  }

  &--size-68 {
    width: 68px;
    height: 68px;
  }

  &__button {
    @include button-reset;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 0;
    color: var(--TextDefault);
  }
}
</style>
