import type { Ref } from 'vue';
import { computed, toRef } from 'vue';

import { useTheme } from 'web/src/modules/theme/composables';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import type { Sport } from 'web/src/modules/sportline/types';
import type { SportEventDetails } from 'web/src/modules/sportline/submodules/event-details/types';
import type { SportSegmentRepresentationSetting } from 'web/src/modules/sportline/types/settings';
import { SportFamily } from 'web/src/modules/sportline/enums';
import { SportBackgroundColorDark, SportBackgroundColorLight } from 'web/src/modules/sportline/constants';

interface UseHeadlineCybersportLayoutProps {
  sportlineEvent: Ref<SportEventDetails>;
}

interface UseHeadlineCybersportLayoutComposable {
  backgroundStyles: Ref<Record<string, Optional<string>>>;
  streamAspectRatio: Ref<number>;
  backgroundColor: Ref<Optional<string>>;
}

const defaultStreamAspectRatio = 9 / 16;

export function useHeadlineCybersportLayout(
  props: UseHeadlineCybersportLayoutProps,
): UseHeadlineCybersportLayoutComposable {
  const { sportlineEvent } = props;

  const { isLight } = useTheme();
  const sportlineSettingsStore = useSportlineSettingsStore();
  const sportSegmentRepresentationSettings = toRef(
    sportlineSettingsStore,
    'sportSegmentRepresentationSettings',
  );

  const sport = computed<Sport>(() => sportlineEvent.value.sport);
  const sportSegmentRepresentationSetting = computed<Maybe<SportSegmentRepresentationSetting>>(() => (
    sportSegmentRepresentationSettings.value[sport.value.segment.id] ?? null
  ));

  const backgroundColor = computed<Optional<string>>(() => (
    isLight.value
      ? SportBackgroundColorLight[SportFamily.ESport]
      : SportBackgroundColorDark[SportFamily.ESport]
  ));

  const backgroundStyles = computed<Record<string, Optional<string>>>(() => {
    const url = sportSegmentRepresentationSetting.value?.backgroundUrl;
    return url
      ? { 'background-image': `url('${url}')` }
      : { 'background-color': backgroundColor.value };
  });
  const streamAspectRatio = computed<number>(() => (
    sportSegmentRepresentationSetting.value?.streamAspectRatio ?? defaultStreamAspectRatio
  ));

  return {
    backgroundStyles,
    streamAspectRatio,
    backgroundColor,
  };
}
