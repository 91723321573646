<script lang="ts" setup>
import {
  LobbyGridType,
  LobbyItemType,
  LobbyItemWidget,
  LobbyTitleType,
} from '@leon-hub/api-sdk';

import LobbySectionContainer
  from 'web/src/modules/lobby/components/LobbySectionContainer/LobbySectionContainer.vue';

import type {
  LobbyBlockEmits,
  LobbyBlockProps,
} from './types';
import {
  useLobbyBlock,
  useLobbySyncState,
} from './composables';

const props = withDefaults(defineProps<LobbyBlockProps>(), {
  testEl: 'egs-lobby',
  skeletons: () => ([{
    typeId: LobbyItemType.GAMES_CATEGORY,
    gridType: LobbyGridType.DEFAULT,
    widgetId: LobbyItemWidget.SWIPE_LIST,
    titleType: LobbyTitleType.DEFAULT,
    swiperRowsNumber: 1,
    isSwiperBlock: false,
  }]),
  excludeGameIds: () => ([]),
});

const emit = defineEmits<LobbyBlockEmits>();

const {
  isLoadingTransition,
  isContentLoading,
  skeletonItems,
  lobbySectionProps,
  lobbyItems,
  onSwiperScroll,
  onSectionScroll,
  onTopWinnersFilterClick,
  onClickLobbyItem,
  onActionClick,
  emitDeleteItems,
} = useLobbyBlock(props, emit);

useLobbySyncState(lobbyItems);
</script>

<template>
  <div v-auto-id="'LobbyBlock'"
    :class="{
      [$style['lobby-block-wrapper']]: isFullHeight,
    }"
  >
    <Transition
      name="fade"
      :enter-active-class="$style['fade-enter-active']"
      :enter-from-class="$style['fade-enter-from']"
      :leave-active-class="$style['fade-leave-active']"
      :leave-to-class="$style['fade-leave-to']"
    >
      <div
        v-if="!isLoadingTransition"
        v-data-test="{ el: testEl, loading: isContentLoading }"
        :class="{
          [$style['lobby-block']]: true,
          [$style['lobby-block--modal']]: isModal,
        }"
      >
        <template v-if="isContentLoading">
          <LobbySectionContainer
            v-for="(lobbySection, index) in skeletonItems"
            :key="index"
            v-bind="lobbySection"
          />
        </template>
        <template v-else>
          <LobbySectionContainer
            v-for="(lobbySection, index) in lobbySectionProps"
            :key="index"
            ref="container"
            v-bind="lobbySection"
            @swiper-scroll="onSwiperScroll($event, lobbySection.lobbyItem)"
            @section-scroll="onSectionScroll($event, lobbySection.lobbyItem)"
            @top-winners-filter-click="onTopWinnersFilterClick($event, lobbySection.lobbyItem)"
            @click-lobby-item="onClickLobbyItem($event, lobbySection.lobbyItem)"
            @action-click="onActionClick(lobbySection.lobbyItem)"
            @delete-items="emitDeleteItems"
          />
        </template>
      </div>
    </Transition>
  </div>
</template>

<style lang="scss" module>
.lobby-block-wrapper {
  min-height: 100vh;
}

.lobby-block {
  display: flex;
  flex-direction: column;
  gap: $lobbyBlockGapSmall;
  padding-bottom: $lobbyBlockPaddingBottom;

  @include screen-desktop-large-min {
    gap: $lobbyBlockGapBig;
  }

  &--modal {
    gap: $lobbyBlockModalGapPhone;

    @include screen-desktop-small-min {
      gap: $lobbyBlockModalGapDesktop;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
