<script lang="ts" setup>
import { toRef } from 'vue';

import type {
  BetlineMatchStatisticsMatch,
  BetlineMatchStatisticsTeam,
} from 'web/src/modules/sportline/types/rest';
import MatchStatisticResultIndicator
  from 'web/src/modules/sportline/submodules/event-details/components/statistic/MatchStatisticResultIndicator.vue';
import StatisticTeamLogo from 'web/src/modules/sportline/submodules/event-statistic/components/StatisticTeamLogo.vue';

import { useSportEventMatchStatistic } from './useSportEventMatchStatistic';

interface SportEventMatchStatisticProps {
  match: BetlineMatchStatisticsMatch;
  displayedForTeam?: Maybe<BetlineMatchStatisticsTeam>;
  sportFamily: Maybe<string>;
}

const props = withDefaults(defineProps<SportEventMatchStatisticProps>(), {
  displayedForTeam: null,
});

const {
  isLive,
  host,
  guest,
  resultScore,
  isHostWinner,
  isGuestWinner,
  setsScore,
  date,
} = useSportEventMatchStatistic({
  match: toRef(props, 'match'),
  sportFamily: toRef(props, 'sportFamily'),
});
</script>

<template>
  <div v-auto-id="'SportEventMatchStatistic'" :class="$style['sport-event-match-statistic']">
    <div :class="$style['sport-event-match-statistic__left']">
      <div :class="$style['sport-event-match-statistic__match-info']">
        <div :class="$style['sport-event-match-statistic__meta']">
          <span
            :class="{
              [$style['sport-event-match-statistic__date']]: true,
              [$style['sport-event-match-statistic__date--live']]: isLive,
            }"
          >{{ date }}</span>
          <span
            v-if="isLive && match.gameScore"
            :class="$style['sport-event-match-statistic__current-score']"
          >{{ match.gameScore }}</span>
        </div>

        <div :class="$style['sport-event-match-statistic__teams']">
          <template v-if="host && guest">
            <div
              :class="{
                [$style['sport-event-match-statistic__team']]: true,
                [$style['sport-event-match-statistic__team--live']]: isLive,
                [$style['sport-event-match-statistic__team--winner']]: !isLive && isHostWinner,
              }"
            >
              <StatisticTeamLogo
                :class="$style['sport-event-match-statistic__team-logo']"
                :team="host"
              />
              <span :class="$style['sport-event-match-statistic__team-name']">{{ host.name }}</span>
            </div>
            <div
              :class="{
                [$style['sport-event-match-statistic__team']]: true,
                [$style['sport-event-match-statistic__team--live']]: isLive,
                [$style['sport-event-match-statistic__team--winner']]: !isLive && isGuestWinner,
              }"
            >
              <StatisticTeamLogo
                :class="$style['sport-event-match-statistic__team-logo']"
                :team="guest"
              />
              <span :class="$style['sport-event-match-statistic__team-name']">{{ guest.name }}</span>
            </div>
          </template>
        </div>
      </div>

      <div :class="$style['sport-event-match-statistic__scores-holder']">
        <div
          v-for="(setScore, index) in setsScore"
          :key="`${index}_${setScore[0]}_${setScore[1]}}`"
          :class="$style['sport-event-match-statistic__scores']"
        >
          <div :class="$style['sport-event-match-statistic__score']">
            {{ setScore[0] }}
          </div>
          <div :class="$style['sport-event-match-statistic__score']">
            {{ setScore[1] }}
          </div>
        </div>

        <div :class="$style['sport-event-match-statistic__scores']">
          <div
            :class="{
              [$style['sport-event-match-statistic__score']]: true,
              [$style['sport-event-match-statistic__score--live']]: isLive,
              [$style['sport-event-match-statistic__score--winner']]: !isLive && isHostWinner,
            }"
          >
            {{ resultScore[0] }}
          </div>
          <div
            :class="{
              [$style['sport-event-match-statistic__score']]: true,
              [$style['sport-event-match-statistic__score--live']]: isLive,
              [$style['sport-event-match-statistic__score--winner']]: !isLive && isGuestWinner,
            }"
          >
            {{ resultScore[1] }}
          </div>
        </div>
      </div>
    </div>

    <div :class="$style['sport-event-match-statistic__right']">
      <MatchStatisticResultIndicator
        :match="match"
        :displayed-for-team="displayedForTeam"
      />
    </div>
  </div>
</template>

<style lang="scss" module>
.sport-event-match-statistic {
  display: flex;
  place-content: center space-between;
  padding: 8px;
  background-color: var(--Layer2);
  border-radius: 0;

  &:not(:last-child) {
    margin-bottom: 1px;
  }

  &:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  &:last-child {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  &__left {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 20px;
  }

  &__match-info {
    display: flex;
  }

  &__meta {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  &__date {
    @include medium\12\16;

    color: var(--TextSecondary);

    &--live {
      color: var(--TextDefault);
    }
  }

  &__current-score {
    @include medium\12\16;

    padding-top: 6px;
    color: var(--BrandDefault);
  }

  &__teams {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-left: 16px;
  }

  &__team {
    display: flex;
    justify-content: flex-start;
    color: var(--TextSecondary);

    &--live,
    &--winner {
      color: var(--TextDefault);
    }

    &:first-child {
      padding-bottom: 6px;
    }
  }

  &__team-logo {
    width: 16px;
    height: 16px;
  }

  &__team-name {
    @include medium\13\16;

    padding-left: 8px;
    line-height: 16px;
  }

  &__scores-holder {
    display: flex;
    flex-direction: row;
    gap: 8px;
    padding-right: 16px;
  }

  &__scores {
    @include bold\13\16\-07;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    color: var(--TextSecondary);
    text-align: right;
  }

  &__score {
    width: 16px;
    overflow: hidden;

    &:first-child {
      padding-bottom: 6px;
    }

    &--live {
      color: var(--BrandDefault);
    }

    &--winner {
      color: var(--TextDefault);
    }
  }
}
</style>
