import type {
  CoreSportResponse,
  CoreRegionResponse,
  CoreLeagueResponse,
  CoreSportReferenceResponse,
  CoreRegionReferenceResponse,
  CoreLeagueReferenceResponse,
} from 'web/src/modules/sportline/types/rest';
import {
  assertFullCoreLeagueResponse,
  assertFullCoreRegionResponse,
  assertFullCoreSportResponse,
} from 'web/src/modules/sportline/utils/rest/assert';

/**
 * Use to fill responses by references
 * A reference has structure { id: string }
 */
export default class ResponseMappings {
  protected sportsResponses = <Record<string, CoreSportResponse>>{};

  protected regionResponses = <Record<string, CoreRegionResponse>>{};

  protected leaguesResponses = <Record<string, CoreLeagueResponse>>{};

  getSportResponseOrSetNew(response: CoreSportResponse | CoreSportReferenceResponse): CoreSportResponse {
    if (this.sportsResponses[response.id]) {
      return this.sportsResponses[response.id];
    }

    this.sportsResponses[response.id] = assertFullCoreSportResponse(response);

    return this.sportsResponses[response.id];
  }

  getRegionResponseOrSetNew(response: CoreRegionResponse | CoreRegionReferenceResponse): CoreRegionResponse {
    if (this.regionResponses[response.id]) {
      return this.regionResponses[response.id];
    }

    this.regionResponses[response.id] = assertFullCoreRegionResponse(response);

    return this.regionResponses[response.id];
  }

  getLeagueResponseOrSetNew(response: CoreLeagueResponse | CoreLeagueReferenceResponse): CoreLeagueResponse {
    if (this.leaguesResponses[response.id]) {
      return this.leaguesResponses[response.id];
    }

    this.leaguesResponses[response.id] = assertFullCoreLeagueResponse(response);

    return this.leaguesResponses[response.id];
  }
}
