import type { Plugin } from 'vue';
import {
  ref,
  onBeforeMount,
  onBeforeUnmount,
  getCurrentInstance,
} from 'vue';

import { isFunction } from '@leon-hub/guards';

import useSeoMetaStorage from 'web/src/modules/seo/store/useSeoMetaStorage';
import type { SeoMetaParametersConfig } from 'web/src/modules/seo/types';

import { isDifferentMetaParameters } from './SeoMetaParameters';

interface VueComputedMetaParameters {
  metaParameters?: SeoMetaParametersConfig;
}

export const computedMetaParametersKey = 'metaParameters';

export default {
  install(app): void {
    app.mixin({
      beforeCreate() {
        const { $options } = this;
        const {
          metaParameters,
        } = $options;

        if (isFunction(metaParameters)) {
          $options.computed = $options.computed || {};
          $options.computed[computedMetaParametersKey] = function computeMetaParameters() {
            return metaParameters.call(this);
          };

          onBeforeMount(() => {
            const uid = getCurrentInstance()?.uid;
            if (!uid) { throw new Error('Component not initialized'); }
            const parameter = ref<SeoMetaParametersConfig>({});
            useSeoMetaStorage().setMetaParameter(uid, parameter);
            this.$watch('metaParameters', (
              newValue?: VueComputedMetaParameters['metaParameters'],
              oldValue?: VueComputedMetaParameters['metaParameters'],
            ) => {
              if (!isDifferentMetaParameters(newValue, oldValue)) { return; }
              parameter.value = newValue ?? {};
            }, { immediate: true });
          });
          onBeforeUnmount(() => {
            const uid = getCurrentInstance()?.uid;
            if (!uid) { throw new Error('Component not initialized'); }
            useSeoMetaStorage().setMetaParameter(uid, null);
          });
        }
      },
    });
  },
} as Plugin;
