import type { Ref } from 'vue';
import { ref, computed, watch } from 'vue';

import { HistoryFilter } from '@leon-hub/api-sdk';

import type { VSwiperRef } from 'web/src/components/Swiper/VSwiper/types';
import type { TabsItem } from 'web/src/components/Tabs';
import { useI18n } from 'web/src/modules/i18n/composables';

import type { CustomerHistoryFilterTabsProps } from '../types';

export interface CustomerHistoryFilterTabsComposable {
  swiper: Ref<VSwiperRef | null>;
  tabs: Ref<TabsItem[]>;
  slideToActive: (smooth: boolean) => void;
}

// eslint-disable-next-line max-len
export default function useCustomerHistoryFilterTabs(props: CustomerHistoryFilterTabsProps): CustomerHistoryFilterTabsComposable {
  const { $translate } = useI18n();
  const swiper = ref<VSwiperRef | null>(null);

  const tabs = computed<TabsItem[]>(() => {
    // TODO better fetch list from backend
    const tabsArray: TabsItem[] = [
      {
        id: HistoryFilter.ALL,
        label: $translate('WEB2_ALL').value,
      },
      {
        id: HistoryFilter.ALL_BONUS,
        label: $translate('WEB2_BONUSES').value,
      },
      {
        id: HistoryFilter.ALL_DEPOSITS,
        label: $translate('WEB2_DEPOSITS').value,
      },
      {
        id: HistoryFilter.ALL_WITHDRAWALS,
        label: $translate('WEB2_PAYMENTS').value,
      },
    ];

    if (process.env.VUE_APP_FEATURE_SPORTLINE_ENABLED) {
      tabsArray.splice(1, 0, {
        id: HistoryFilter.ALL_BETS,
        label: $translate('WEB2_BETS').value,
      });
    }

    if (process.env.VUE_APP_FEATURE_CASINO_ENABLED) {
      tabsArray.splice(2, 0, {
        id: HistoryFilter.ALL_EGS,
        label: $translate('WEB2_ALL_EGS').value,
      });
    }

    if (props.isLeonShopEnabled) {
      tabsArray.splice(2, 0, {
        id: HistoryFilter.LEONSHOP,
        label: $translate('WEB2_SHOP').value,
      });
    }

    return tabsArray;
  });

  const activeTabIndex = computed<number>(
    () => tabs.value.findIndex((item) => (item.id as HistoryFilter) === props.selectedHistoryFilter),
  );

  function slideToActive(smooth = false): void {
    if (swiper.value) {
      swiper.value?.slideToSlide(activeTabIndex.value, {
        smooth,
        speed: 300,
        isCentered: true,
      });
    }
  }

  watch(() => props.selectedHistoryFilter, () => {
    slideToActive(true);
  });

  return {
    swiper,
    tabs,
    slideToActive,
  };
}
