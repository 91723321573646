import { defineStore } from 'pinia';
import { type WatchStopHandle, toRef } from 'vue';
import { shallowRef, triggerRef, watch } from 'vue';

import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { useAuthStore } from 'web/src/modules/auth/store';

export type GlobalSyncStateCallback = () => void;

export type LoggedInSyncStateCallback = () => void;

export const useModulesSyncStore = defineStore('modules-sync', () => {
  const authStore = useAuthStore();
  const isLoadedFromRoot = toRef(() => authStore.isLoadedFromRoot);

  const { isLoggedIn } = useIsLoggedIn();

  const syncStateTriggerRef = shallowRef(true);

  function forceSyncStateAll(): void {
    triggerRef(syncStateTriggerRef);
  }

  function watchGlobalSyncState(callback: GlobalSyncStateCallback): WatchStopHandle {
    return watch(syncStateTriggerRef, callback);
  }

  function watchLoggedInSyncState(callback: LoggedInSyncStateCallback): WatchStopHandle {
    return watch(isLoggedIn, () => {
      // prevent double requests on init for auth customer
      if (!isLoadedFromRoot.value) { return; }
      void callback();
    });
  }

  return {
    forceSyncStateAll,
    watchGlobalSyncState,
    watchLoggedInSyncState,
  };
});
