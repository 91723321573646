import type { Ref } from 'vue';
import { computed } from 'vue';
import { useRouter } from 'vue-router';

import { RouteName } from '@leon-hub/routing-config-names';

import { isAddToCustomerLeaguesParametersEquals } from '../utils';
import { useFavoriteLeagueData } from './useFavoriteLeagueData';
import { useIsFavoriteLeague } from './useIsFavoriteLeague';

interface LeagueFavoriteIndicatorProps {
  sportId: Ref<Optional<string>>;
  leagueId: Ref<Optional<string>>;
}

interface LeagueFavoriteIndicatorComposable {
  isFavorite: Ref<boolean>;
  isOnlyPending: Ref<boolean>;
  hasFavoriteButton: Ref<boolean>;
  toggleLeagueFavoriteState: () => Promise<void>;
}

export function useLeagueFavoriteIndicator(
  props: Readonly<LeagueFavoriteIndicatorProps>,
): LeagueFavoriteIndicatorComposable {
  const {
    sportId,
    leagueId,
  } = props;

  const {
    changeFavoriteLeagueState,
    pendingToFavoriteLeaguesList,
    isOnlyAddToPendingFavoriteLeagues,
  } = useFavoriteLeagueData();
  const isFavorite = useIsFavoriteLeague(leagueId);
  const router = useRouter();

  async function toggleLeagueFavoriteState(): Promise<void> {
    // Use variables instead of .value because of typechecking problem
    const sId = sportId.value;
    const lId = leagueId.value;

    if (!sId || !lId) { return; }

    const options = { sportId: sId, leagueId: lId };

    if (!isOnlyAddToPendingFavoriteLeagues.value) {
      // for logged-in customers just add to favorites
      await changeFavoriteLeagueState(options);
      return;
    }

    const pendingList = pendingToFavoriteLeaguesList.value;
    const isRemovingFromPending = pendingList
      .some((pending) => isAddToCustomerLeaguesParametersEquals(pending, options));

    await changeFavoriteLeagueState(options);

    // show login only for fist added league
    if (pendingList.length === 0 && !isRemovingFromPending) {
      void router.push({ name: RouteName.LOGIN });
    }
  }

  const hasFavoriteButton = computed<boolean>(() => !!sportId.value && !!leagueId.value);

  return {
    isFavorite,
    isOnlyPending: isOnlyAddToPendingFavoriteLeagues,
    hasFavoriteButton,
    toggleLeagueFavoriteState,
  };
}
