import { watch } from 'vue';

import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { runDeviceIdRoutine } from 'web/src/modules/auth/utils';
import { startLoginDeviceRoutineEventType, useAppEmitter } from 'web/src/modules/emitter';

export const setupEmitter = (): void => {
  useAppEmitter().on(startLoginDeviceRoutineEventType, (): void => {
    const { isLoggedIn } = useIsLoggedIn();
    if (isLoggedIn.value) {
      void runDeviceIdRoutine();
    } else {
      watch(isLoggedIn, () => {
        void runDeviceIdRoutine();
      }, { once: true });
    }
  });
};
