<script lang="ts" setup>
import type { DropListSelectProps } from 'web/src/components/DropListSelect/types';
import { DropListSelect } from 'web/src/components/DropListSelect';

import { useFormDropListSelect } from './useFormDropListSelect';
import type {
  FormWrapperWidgetProperties,
  BaseFormWidgetEmits,
} from '../../types';

const props = defineProps<FormWrapperWidgetProperties<DropListSelectProps>>();
const emit = defineEmits<BaseFormWidgetEmits>();

const {
  componentProps, emitChange, emitFocus, emitBlur,
} = useFormDropListSelect(props, emit);
</script>

<template>
  <DropListSelect v-auto-id="'FormDropListSelect'"
    v-bind="componentProps"
    @item-click="emitChange"
    @focus="emitFocus"
    @blur="emitBlur"
  />
</template>
