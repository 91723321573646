export function getNextPreselectedIndex(isMovingForward: boolean, currentIndex: number, lastIndex: number): number {
  let nextIndex: number;
  if (currentIndex < 0) {
    if (isMovingForward) {
      nextIndex = 0;
    } else {
      nextIndex = lastIndex;
    }
  } else {
    nextIndex = isMovingForward ? currentIndex + 1 : currentIndex - 1;
  }
  return nextIndex;
}
