import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import { IconName, IconSize } from '@leon-hub/icons';
import { EgsAvailabilityStatus } from '@leon-hub/api-sdk';

import type { VIconProps } from '@components/v-icon';

import type { EgsGameInfoOverlayProps } from 'web/src/modules/egs/components/EgsGameInfoOverlay/types';
import { useI18n } from 'web/src/modules/i18n/composables';
import { getSeats, getSeatsText } from 'web/src/modules/egs/components/EgsGameInfoOverlay/composables/utils';
import type { VBadgeProps } from 'web/src/components/Badge/VBadge/types';
import { BadgeKind } from 'web/src/components/Badge/VBadge/enums';
import { getBetRange } from 'web/src/modules/lobby/components/LobbyEgsGamesItemImage/composables/utils';

interface EgsGameInfoOverlayComposable {
  isSeatsHidden: ComputedRef<boolean>;
  seatsText: ComputedRef<string>;
  iconProperties: ComputedRef<VIconProps>;
  betRange: ComputedRef<string | undefined>;
  status: ComputedRef<EgsAvailabilityStatus | undefined>;
  ntBadgeProperties: ComputedRef<VBadgeProps>;
}

export default function useEgsGameInfoOverlay(props: EgsGameInfoOverlayProps): EgsGameInfoOverlayComposable {
  const { $translate } = useI18n();

  const iconProperties = computed<VIconProps>(() => ({
    name: IconName.PERSON_FILL,
    size: props.isTopPosition ? IconSize.SIZE_16 : IconSize.SIZE_12,
  }));
  const status = computed<EgsAvailabilityStatus | undefined>(() => props.availability?.status);
  const seats = computed(() => getSeats(props, $translate));
  const seatsText = computed(() => getSeatsText(status.value, seats.value, $translate));
  // eslint-disable-next-line max-len
  const isSeatsHidden = computed(() => !props.availability || (status.value === EgsAvailabilityStatus.AVAILABLE && !seats.value));
  const betRange = computed(() => getBetRange(props.limits));

  const ntBadgeProperties = computed<VBadgeProps>(() => ({
    kind: BadgeKind.SQUARE_ERROR,
    label: props.badge ?? '',
  }));

  return {
    isSeatsHidden,
    betRange,
    seatsText,
    iconProperties,
    status,
    ntBadgeProperties,
  };
}
