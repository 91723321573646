import { RouteName } from '@leon-hub/routing-config-names';

import type { AppVueRouter } from 'web/src/modules/core/services/router/types';

export default function createTransactionsRoute(router: AppVueRouter): void {
  router.addProfileRoute({
    name: RouteName.CUSTOMER_TRANSACTIONS_HISTORY,
    path: '/customer/history',
    // eslint-disable-next-line max-len
    component: () => import('web/src/modules/profile/submodules/customer-history/pages/CustomerHistoryListRouteComponent/CustomerHistoryListRouteComponent.vue'),
    title: '{{$t(\'WEB2_TRANSACTION_HISTORY\')}}',
    topBarProps: {
      hidePrefix: !!process.env.VUE_APP_LAYOUT_DESKTOP && !process.env.VUE_APP_FEATURE_DESKTOP_PROFILE_AS_PHONE_ENABLED,
    },
    topBarType: process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED ? 'secondary' : undefined,
  });

  router.addProfileRoute({
    name: RouteName.CUSTOMER_HISTORY_TRANSACTION_DETAILS,
    path: '/customer/history/transaction/:group/:id/:accountType?',
    // eslint-disable-next-line max-len
    component: () => import('web/src/modules/profile/submodules/customer-history/pages/CustomerHistoryDetailsRouteComponent/CustomerHistoryDetailsRouteComponent.vue'),
    // eslint-disable-next-line max-len
    topBar: () => import('web/src/modules/profile/submodules/customer-history/layouts/CustomerHistoryTopBarRouteComponent/CustomerHistoryTopBarRouteComponent.vue'),
  });
}
