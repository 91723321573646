import type {
  Market,
  Runner,
  MarketSpecifier,
} from 'web/src/modules/sportline/types';
import type {
  CoreMarketResponse,
  BuildSportOptions,
} from 'web/src/modules/sportline/types/rest';
import type { SportEventStatus } from 'web/src/modules/sportline/enums';
import { extractMarketStatus } from 'web/src/modules/sportline/utils/rest/pre-build';
import { BetlineMarketTypeTag } from 'web/src/modules/sportline/enums/rest';
import { SportEventMarketTypeTag } from 'web/src/modules/sportline/enums';

import type { CreateRunnerProperties } from './createRunner';
import { createRunner } from './createRunner';
import createMarketType from './createMarketType';

const marketTypeTagMap = {
  [BetlineMarketTypeTag.REGULAR]: SportEventMarketTypeTag.REGULAR,
  [BetlineMarketTypeTag.HANDICAP]: SportEventMarketTypeTag.HANDICAP,
  [BetlineMarketTypeTag.TOTAL]: SportEventMarketTypeTag.TOTAL,
};

export function createMarket(
  marketResponse: CoreMarketResponse,
  properties: { eventId: string; eventStatus: SportEventStatus },
  options: BuildSportOptions,
): Market {
  const { eventId, eventStatus } = properties;
  const marketId = String(marketResponse.id);
  const marketStatus = extractMarketStatus(eventStatus, marketResponse);
  const { handicap, specifiers: rawSpecifiers } = marketResponse;
  const createRunnerProperties: CreateRunnerProperties = {
    eventId,
    marketId,
    handicap,
    eventStatus,
    marketStatus,
  };
  const runners: Runner[] = marketResponse.runners
    .map((runnerResponse) => createRunner(runnerResponse, createRunnerProperties));

  const specifiers = rawSpecifiers
    ? Object.keys(rawSpecifiers).map((key): MarketSpecifier => ({ id: key, value: rawSpecifiers[key] }))
    : undefined;

  return {
    eventId,
    id: marketId,
    name: marketResponse.name,
    type: createMarketType(marketResponse),
    isPrimary: marketResponse.primary,
    marketTypeId: marketResponse.marketTypeId,
    typeTag: marketResponse.typeTag ? marketTypeTagMap[marketResponse.typeTag] : undefined,
    status: marketStatus,
    runners,
    columns: marketResponse.cols,
    handicap,
    hasZeroMargin: options.canUseZeroMargin && (marketResponse.hasZeroMargin ?? false),
    specifiers,
  };
}

export function createMarkets(
  markets: CoreMarketResponse[],
  properties: { eventId: string; eventStatus: SportEventStatus },
  options: BuildSportOptions,
): Market[] {
  return (markets || [])
    .map((marketResponse: CoreMarketResponse) => createMarket(marketResponse, properties, options));
}
