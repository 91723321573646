import { defineStore } from 'pinia';
import { computed, ref, toRef } from 'vue';

import { useSiteConfigStore } from 'web/src/modules/core/store';

import { getStorage, normalizeUrl } from '../utils';
import type { UseShieldIntegrationStore } from '../types';

export const useShieldIntegrationStore = defineStore('shield-integration', (): UseShieldIntegrationStore => {
  const sessionId = ref<string | undefined>(undefined);
  const sdkUrl = computed<string | undefined>(() => {
    const { helmetAPIUrl } = useSiteConfigStore();
    return helmetAPIUrl ? normalizeUrl(helmetAPIUrl) : undefined;
  });
  function setSessionId(id: string): void {
    getStorage().set({ session_id: id })
      // eslint-disable-next-line no-console
      .catch((err) => console.error(err));
    sessionId.value = id;
  }

  return {
    isEnabled: toRef(() => useSiteConfigStore().isShieldEnabled),
    sdkUrl,
    setSessionId,
    sessionId: computed({
      get: () => sessionId.value,
      set: setSessionId,
    }),
  };
});
