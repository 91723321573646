<script lang="ts" setup>
import { useVTimer } from './composables';

interface VTimerProps {
  text?: string;
  seconds?: number;
}

const props = withDefaults(defineProps<VTimerProps>(), {
  text: '',
  seconds: 0,
});
const { timeLeft } = useVTimer(props);
</script>

<template>
  <div v-auto-id="'VTimer'" :class="$style['timer']">
    <span
      v-if="text"
      :class="$style['timer__label']"
    >
      {{ text }}
    </span>
    <span :class="$style['timer__time-left']">
      {{ timeLeft }}
    </span>
  </div>
</template>

<style lang="scss" module>
.timer {
  display: flex;
  flex-direction: column;
  text-align: center;

  &__label {
    @include smsNumberCheckLabelFont;

    color: $smsCheckAgainLabelColor;
  }

  &__time-left {
    @include smsNumberCountdownLabelFont;

    color: $smsNumberCountdownLabelColor;
  }
}
</style>
