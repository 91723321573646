import type {
  RouteLocationNormalized,
  NavigationGuardNext,
  RouteLocationRaw,
} from 'vue-router';
import { toRef } from 'vue';

import { RouteName } from '@leon-hub/routing-config-names';

import type { SportEventsLeaguePageNavigationService } from 'web/src/modules/sportline/submodules/league/types';
import useLeaguePageStore from 'web/src/modules/sportline/submodules/league/store/useLeaguePageStore';
import type { AppVueRouter } from 'web/src/modules/core/services/router/types';
import { useRootNavigationStore } from 'web/src/modules/core/store';
import { isLeaguePageRouteName } from 'web/src/modules/sportline/submodules/navigation/guards';

import { useBaseLeaguePageNavigationGuard } from './useBaseLeaguePageNavigationGuard';

export function usePrerenderLeaguePageNavigationGuard(): SportEventsLeaguePageNavigationService {
  const leaguePageStore = useLeaguePageStore();
  const canDisplayAnything = toRef(leaguePageStore, 'canDisplayAnything');
  const {
    initialRequests,
    awaitStateIsLoaded,
    setConfigForLeagueLoading,
  } = leaguePageStore;

  const rootNavigationStore = useRootNavigationStore();
  const { setRouteContentLoaded } = rootNavigationStore;
  const baseNavigationGuard = useBaseLeaguePageNavigationGuard();
  const loadLeagueConfig = toRef(baseNavigationGuard, 'loadLeagueConfig');
  const {
    clearLoadLeagueConfig,
    prefetch: basePrefetch,
    getRedirectLocation,
  } = baseNavigationGuard;

  function prefetch(
    router: AppVueRouter,
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ): void {
    basePrefetch(router, to, from, (superResult?: unknown) => {
      if (superResult !== undefined) {
        next((superResult as RouteLocationRaw));
        return;
      }

      void awaitStateIsLoaded().then(() => {
        const loadingRouteName = isLeaguePageRouteName(to.name) ? to.name : RouteName.SPORT_LEAGUE;
        setRouteContentLoaded(loadingRouteName);

        if (!canDisplayAnything.value) {
          // @TODO split 301 and 302 redirects for just empty events and corrupted leagues
          next(router.resolve302location({ name: RouteName.HOME }));
          return;
        }

        const redirectTo = getRedirectLocation(router, to);

        if (redirectTo) {
          next(redirectTo);
          return;
        }

        next();
      });

      setConfigForLeagueLoading({ ...loadLeagueConfig.value });
      clearLoadLeagueConfig();
      void initialRequests();
    });
  }

  return { prefetch };
}
