<script lang="ts" setup>
import {
  ref,
  toRef,
  computed,
} from 'vue';

import { ButtonKind } from '@leon-hub/module-buttons';

import { VIcon } from '@components/v-icon';

import { useSportlineLayoutSettings } from 'web/src/modules/sportline/composables/settings';
import type { MarketsGridFilter, MarketsGridFilterId } from 'web/src/modules/sportline/submodules/markets-grid/types';
import type { MarketsListTabsReference } from 'web/src/modules/sportline/views/markets-list/types';
import type { VSwiperRef } from 'web/src/components/Swiper/VSwiper/types';
import VTabsButton from 'web/src/components/Tabs/VTabsButton/VTabsButton.vue';
import VSwiper from 'web/src/components/Swiper/VSwiper/VSwiper';
import VSwiperSlide from 'web/src/components/Swiper/VSwiperSlide/VSwiperSlide.vue';
import SwiperNavigationButtons from 'web/src/components/Swiper/Navigation/SwiperNavigationButtons/SwiperNavigationButtons.vue';
import vDataTestUnit from 'web/src/testing/directives/vDataTestUnit';

import { unitTestElement } from '../constants';
import { useMarketsTabs } from './composables/useMarketsTabs';

interface MarketsListTabsProps {
  hasStatistic?: boolean;
  hasAllTab?: boolean;
  gridFilters: MarketsGridFilter[];
  activeTabId?: MarketsGridFilterId;
}

interface MarketsListTabsEmits {
  (e: 'tab-click', payload: string): void;
}

const props = defineProps<MarketsListTabsProps>();
const emit = defineEmits<MarketsListTabsEmits>();

const hasStatistic = toRef(() => props.hasStatistic);
const hasAllTab = toRef(() => props.hasAllTab);
const gridFilters = toRef(() => props.gridFilters);
const activeTabId = toRef(() => props.activeTabId);

const tabsRef = ref<VSwiperRef>();

const {
  tabs,
  slideToActive,
  onTabClick,
} = useMarketsTabs({
  hasStatistic,
  hasAllTab,
  gridFilters,
  activeTabId,
  tabsRef,
}, emit);

const { isBetSwipeEventDetailsLayoutEnabled } = useSportlineLayoutSettings();
const isButtonLikeTabs = computed(() => null && isBetSwipeEventDetailsLayoutEnabled.value);

defineExpose<MarketsListTabsReference>({
  slideToActive,
});
</script>

<template>
  <VSwiper v-auto-id="'MarketsTabs'"
    ref="tabsRef"
    :class="$style['markets-tabs']"
  >
    <VSwiperSlide
      v-for="item in tabs"
      :key="item.id"
      :class="$style['markets-tabs__slide']"
    >
      <VTabsButton
        :id="item.id"
        v-data-test-unit="{ el: unitTestElement.tabButton, id: item.id }"
        :class="$style['markets-tabs__button']"
        :active="item.id === activeTabId"
        :is-bordered="!isButtonLikeTabs"
        :type="item.type"
        :version="null && isButtonLikeTabs ? 'bet-swipe' : 'default'"
        @click="onTabClick(item.id)"
      >
        <VIcon
          v-if="item.icon"
          v-bind="item.icon"
        />
        <template v-else>
          {{ item.label }}
        </template>
      </VTabsButton>
    </VSwiperSlide>
    <template
      v-if="'1'"
      #pagination-footer
    >
      <SwiperNavigationButtons :button-kind="ButtonKind.BASE" />
    </template>
  </VSwiper>
</template>

<style lang="scss" module>
@include for-layout using ($isDesktop) {
  .markets-tabs {
    height: auto;

    @if $isDesktop {
      flex-shrink: 1;
      margin-right: 10px;
    }

    @if $isEnvFeatureTwinlikeStyle {
      &__slide:not(:last-child) {
        margin-right: 4px;
      }

      &__button {
        padding: 0 16px;
      }
    }
  }
}
</style>
