import type { AbstractErrorOptions } from '@leon-hub/errors';
import { AppError, AppErrorCode } from '@leon-hub/app-errors';

import { useI18n } from 'web/src/modules/i18n/composables';

export default class CaptchaServiceError extends AppError {
  constructor(options: Without<AbstractErrorOptions, 'code'> = {}) {
    super({
      ...options,
      code: AppErrorCode.APP_UNEXPECTED_ERROR,
      silent: false,
      message: useI18n().$translate('WEB2_CAPTCHA_SERVICE_ERROR').value,
    });
  }
}
