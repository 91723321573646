export const enum ModalHeight {
  DEFAULT = 'default',
  TINY = 'tiny',
  SMALL_MINUS = 'small-minus',
  SMALL = 'small',
  NORMAL = 'normal',
  MEDIUM = 'medium',
  EXTRA = 'extra',
  LARGE = 'large',
  MAX = 'max',
}
