<script lang="ts" setup>
import { IconName, IconSize } from '@leon-hub/icons';

import { VIcon } from '@components/v-icon';

import type {
  ReferralProgramBlockEmit,
  ReferralProgramBlockProps,
} from 'web/src/modules/referral-program/components/ReferralProgramBlock/types';

withDefaults(defineProps<ReferralProgramBlockProps>(), {
  title: '',
  value: '',
  iconName: IconName.PEOPLE,
  iconSize: IconSize.SIZE_32,
});

const emit = defineEmits<ReferralProgramBlockEmit>();

function handleClick() {
  emit('click');
}
</script>

<template>
  <div v-auto-id="'VReferralProgramBlock'"
    :class="{
      [$style['referral-program-block']]: true,
      [$style['referral-program-block--clickable']]: isClickable,
    }"
    @click="handleClick"
  >
    <div :class="$style['referral-program-block__labels']">
      <span :class="$style['referral-program-block__title']">{{ title }}</span>
      <span :class="$style['referral-program-block__value']">{{ value }}</span>
    </div>
    <VIcon
      :class="$style['referral-program-block__icon']"
      :name="iconName"
      :size="iconSize"
    />
  </div>
</template>

<style lang="scss" module>
.referral-program-block {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 56px;
  padding: 8px 12px;
  background-color: var(--Layer1);
  border-radius: 5px;

  &--clickable {
    cursor: pointer;
  }

  &__labels {
    display: flex;
    flex-direction: column;
  }

  &__title {
    @include regular\12\16;

    color: var(--TextPrimary);
  }

  &__value {
    @include medium\18\24;

    color: var(--TextDefault);
  }

  &__icon {
    fill: var(--TextSecondary);
  }
}
</style>
