<script lang="ts" setup>
import { computed, onMounted } from 'vue';

import { TargetParamHistory } from '@leon-hub/yandex-metrika';
import { useLocalStorageManager } from '@leon-hub/local-storage';

import type { BaseModalEmits } from 'web/src/modules/dialogs/types';
import PaymentsWithdrawalsType, { PaymentsNDFLType } from 'web/src/modules/payments/types/PaymentsWithdrawalsTypes';
import { TextSlotPlaceholder } from 'web/src/components/TextSlotPlaceholder';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import { useBaseModalActions } from 'web/src/modules/dialogs/composables';
import type { ConfirmModalProps } from 'web/src/modules/dialogs/views/ConfirmModal/types';
import { DialogAction, DialogComponent } from 'web/src/modules/dialogs/enums';

import ConfirmModal from '../ConfirmModal/ConfirmModal.vue';
import type { ConfirmWithdrawalModalProps } from './types';

const props = withDefaults(defineProps<ConfirmWithdrawalModalProps>(), {});
const emit = defineEmits<BaseModalEmits>();

const { onClose, onButtonClick } = useBaseModalActions(emit);

const localStorageManager = useLocalStorageManager();
const analytics = useAnalytics();

const showPaymentAmount = computed(() => props.modal.type === PaymentsWithdrawalsType.INCOME_TAX);
const showBalance = computed(() => props.modal.type === PaymentsWithdrawalsType.DEFAULT);

function onHistoryClick(): void {
  analytics.clickMap({
    history: TargetParamHistory.REQUEST_WITHDRAWAL,
  });

  emit('button-click', {
    action: DialogAction.ADDITIONAL_BUTTON,
  });

  onClose();
}

const confirmModalProps = computed<ConfirmModalProps>(() => {
  const {
    priority, ...modalProps
  } = props.modal;

  return {
    modal: {
      ...modalProps,
      priority,
      modalComponent: DialogComponent.ConfirmModal,
      confirmMessage: undefined,
    },
    isAlert: props.isAlert,
  };
});

onMounted(() => {
  if (showPaymentAmount.value) {
    localStorageManager.removeItem(PaymentsNDFLType.NETTO_AMOUNT);
    localStorageManager.removeItem(PaymentsNDFLType.BRUTTO_AMOUNT);
    localStorageManager.removeItem(PaymentsNDFLType.TAX_AMOUNT);
  }
});
</script>

<template>
  <ConfirmModal v-auto-id="'ConfirmWithdrawalModal'"
    v-bind="confirmModalProps"
    :class="$style['withdrawal-modal--confirm']"
    @close="onClose"
    @button-click="onButtonClick"
  >
    <TextSlotPlaceholder
      v-if="showPaymentAmount"
      :text="$t('WEB2_WTHDRAWAL_MODAL_PAYMENT_AMOUNT')"
      :class="[
        $style['withdrawal-modal__label'],
        $style['withdrawal-modal__label-payment-amount']
      ]"
    >
      <template #amount>
        <span
          :class="$style['withdrawal-modal__label-payment-amount-bold']"
        >
          {{ modal.amount }}
        </span>
      </template>
    </TextSlotPlaceholder>
    <span
      :class="[
        $style['withdrawal-modal__label'],
        $style['withdrawal-modal__label-cancel']
      ]"
    >
      {{ $t('WEB2_WTHDRAWAL_MODAL_OTMENIT') }}
    </span>
    <span
      :class="[
        $style['withdrawal-modal__label'],
        $style['withdrawal-modal__label-history']
      ]"
      @click="onHistoryClick"
    >
      {{ $t('WEB2_WITHDRAWAL_MODAL_ISTORII') }}
    </span>

    <template v-if="showBalance">
      <span
        :class="[
          $style['withdrawal-modal__label'],
          $style['withdrawal-modal__label-available-balance']
        ]"
      >
        {{ $t('WEB2_WITHDRAWAL_MODAL_DOSTUPNI') }}
      </span>
      <span
        :class="[
          $style['withdrawal-modal__label'],
          $style['withdrawal-modal__label-balance']
        ]"
      >
        {{ modal.amount }}
      </span>
    </template>
  </ConfirmModal>
</template>

<style lang="scss" module>
.withdrawal-modal {
  &__label {
    @include withdrawalModalLabel;

    display: block;
    margin: 0 0 8px;

    &-payment-amount {
      @include regular\14\24\025;

      margin: 0 0 20px;

      &-bold {
        @include medium\14\24\025;

        display: inline-block;
        color: var(--TextDefault);
      }

      &-percent {
        display: inline-block;
      }
    }
  }

  &__label-history {
    margin-bottom: 0;
    text-decoration: underline;
    cursor: pointer;

    @include for-hover {
      &:hover {
        color: var(--TextDefault);
      }
    }
  }

  &__label-cancel {
    margin-bottom: 0;
  }

  &__label-available-balance {
    margin-top: 20px;
  }

  &__label-balance {
    @include withdrawalModalBalance;

    margin: 8px 0 0;
  }
}
</style>
