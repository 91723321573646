import type { LobbyBlockProps } from 'web/src/modules/lobby/components/LobbyBlock/types';
import type { LobbyItemUnit } from 'web/src/modules/lobby/types';
import type { AppVueRouter } from 'web/src/modules/core/services/router/types';
import type { LobbySectionContainerProps } from 'web/src/modules/lobby/components/LobbySectionContainer/types';
import { getLobbyActionHref } from 'web/src/modules/lobby/components/LobbyBlock/composables/utils/getLobbyActionHref';

export default function getLobbySectionProps(
  props: LobbyBlockProps,
  isLoadingLobby: boolean,
  lobbyItems: LobbyItemUnit[],
  router: AppVueRouter,
  lazyLoadPagesCounter: number,
): LobbySectionContainerProps[] {
  if (isLoadingLobby) {
    return [];
  }

  return lobbyItems.map((lobbyItem) => ({
    lobbyItem,
    isModal: props.isModal,
    noBackground: props.noBackground,
    jackpotRequestInterval: 15_000,
    actionHref: getLobbyActionHref(router, lobbyItem),
    isHeaderWithHorizontalMargin: props.isHeaderWithHorizontalMargin,
    lazyLoadPagesCounter,
    isFiltersAllowed: props.isFiltersAllowed,
    isCustomHomepage: props.isCustomHomepage,
  }));
}
