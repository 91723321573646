import { FormFocusType } from '@leon-hub/form-utils';

import type { FormUiSchema } from 'web/src/components/Form';

function getDeprecatedFormFocusOnMountPreset(uiSchema: FormUiSchema): string | null {
  const preset = uiSchema?.focus;
  if (preset && preset.type === FormFocusType.OnMount) {
    return preset.field;
  }
  return null;
}

function getActualAutofocusField(uiSchema: FormUiSchema): string | undefined {
  let result: string | undefined;
  if (uiSchema.fields) {
    const keys = Object.keys(uiSchema.fields);
    for (const key of keys) {
      const field = uiSchema.fields[key];
      if (field && field.options && 'autofocus' in field.options && field.options.autofocus) {
        return key;
      }
    }
  }
  return result;
}

export default function getDefaultAutofocus(uiSchema: FormUiSchema): string | undefined {
  const oldSettings = getDeprecatedFormFocusOnMountPreset(uiSchema);
  if (oldSettings) {
    return oldSettings;
  }
  return getActualAutofocusField(uiSchema);
}
