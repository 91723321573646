<script lang="ts" setup>
import { ref, useSlots } from 'vue';

import {
  IconName,
} from '@leon-hub/icons';

import { VJumbotron, JumbotronFooterButton } from 'web/src/components/Jumbotron';
import VDynamicContent from 'web/src/components/DynamicContent/VDynamicContent/VDynamicContent.vue';
import type { ModalRef } from 'web/src/components/Modal/types';
import SButton from 'web/src/components/Button/SButton/SButton.vue';

import { useModalWindow } from './composables';
import ModalContentWrapper from '../ModalContentWrapper/ModalContentWrapper.vue';
import ModalWindowHeader from '../ModalWindowHeader/ModalWindowHeader.vue';
import type {
  ModalWindowProps,
  ModalWindowEmits,
  ModalWindowSlots,
} from './types';

const props = withDefaults(defineProps<ModalWindowProps>(), {
  // eslint-disable-next-line vue/no-boolean-default
  isCloseAllowed: true,
  // eslint-disable-next-line vue/no-boolean-default
  isFullHeightCentered: true,
});
const emit = defineEmits<ModalWindowEmits>();

const slots = useSlots();

const contentWrapper = ref<ModalRef>();

const {
  headerProperties,
  isHeaderHidden,
  jumbotronProperties,
  titleText,
} = useModalWindow(props, slots);

function scrollTop(): void {
  contentWrapper.value?.scrollTop();
}

function scrollToElement(element: HTMLElement | null): void {
  contentWrapper.value?.scrollToElement(element);
}

function scrollUpdate(): void {
  contentWrapper.value?.scrollUpdate();
}

defineExpose<ModalRef>({
  scrollTop,
  scrollToElement,
  scrollUpdate,
});

defineSlots<ModalWindowSlots>();
</script>

<template>
  <div v-auto-id="'ModalWindow'"
    :class="{
      [$style['modal-window']]: true,
      [$style['modal-window--alert']]: isAlert,
      [$style['modal-window--with-inner']]: hasInnerModal,
      [$style['modal-window--inner-modal']]: isInnerModal,
      [$style['modal-window--full-height']]: isFullHeight,
      [$style['modal-window--full-height-centered']]: isFullHeight && isFullHeightCentered,
      [$style['modal-window--slot']]: $slots.default,
      [$style['modal-window--full-screen']]: isFullScreen,
    }"
  >
    <slot name="header">
      <ModalWindowHeader
        v-if="!isHeaderHidden"
        v-bind="headerProperties"
        :class="$style['modal-window__header']"
        @prefix-click="emit('close')"
        @suffix-click="emit('close')"
      />
    </slot>
    <div
      :class="{
        [$style['modal-window__content']]: true,
        [$style['modal-window__content--has-native-scroll']]: !isScrollBarEnabled,
        [$style['modal-window__content--no-scroll']]: hasNoScroll,
      }"
    >
      <ModalContentWrapper
        ref="contentWrapper"
        :class="$style['modal-window__wrapper']"
        :is-scroll-bar-enabled="isScrollBarEnabled"
        :modal-key="modalKey"
        :use-scroll-listener="useScrollListener"
        :is-inner-modal="isInnerModal"
        @scroll-reached-bottom="emit('scroll-reached-bottom')"
      >
        <slot>
          <div
            :class="{
              [$style['modal-window__inner']]: true,
              [$style['modal-window__inner--alert']]: isAlert,
              [$style['modal-window__inner--alert-no-title']]: isAlert && !titleText,
              [$style['modal-window__inner--full-height']]: isFullHeight,
              [$style['modal-window__inner--full-height-centered']]: isFullHeight && isFullHeightCentered,
              [$style['modal-window__inner--profile']]: isProfileLayout,
              [$style['modal-window__inner--small-width']]: isSmallWidthContent,
              [$style['modal-window__inner--no-header']]: isHeaderHidden && !$slots.header,
            }"
          >
            <slot name="inner-content">
              <VJumbotron
                v-bind="jumbotronProperties"
                :class="$style['modal-window__jumbotron']"
              >
                <template
                  v-if="$slots.content"
                  #default
                >
                  <slot name="content" />
                </template>
                <template
                  v-if="$slots.icon"
                  #icon
                >
                  <slot name="icon" />
                </template>
                <template
                  v-if="$slots.footer || buttons?.length"
                  #footer
                >
                  <slot name="footer">
                    <JumbotronFooterButton
                      v-for="(button, index) in buttons"
                      v-bind="button"
                      :key="index"
                      v-data-test="{ el: 'modal-button'}"
                      full-width
                      @click="emit('button-click', index)"
                    >
                      <VDynamicContent :content="button.label" />
                    </JumbotronFooterButton>
                  </slot>
                </template>
              </VJumbotron>
            </slot>
          </div>
        </slot>
      </ModalContentWrapper>
    </div>
    <div
      v-if="null && '1' && isAlert"
      :class="$style['modal-window__close']"
    >
      <SButton
        :icon-name="IconName.CROSS"
        kind="quaternary-secondary"
        size="xsmall"
        @click="emit('close')"
      />
    </div>
  </div>
</template>

<style module lang="scss">
$windowMaxHeight: 520px;

.modal-window {
  $self: &;

  position: relative;
  display: flex;
  flex-direction: column;
  background-color: $modalWindowShadowBackground;
  box-shadow: $modalWindowShadow;

  &__header {
    border-top-left-radius: $modalWindowRadius;
    border-top-right-radius: $modalWindowRadius;
  }

  &__inner {
    padding: $modalWindowInnerPaddingPhone;

    &--no-header {
      padding-top: 16px;
    }

    &--alert {
      padding: 16px 32px 32px;

      @include is-app-layout-desktop {
        padding: 16px 32px 32px;
      }
    }

    &--full-height {
      flex: 1;
      padding: $modalWindowInnerFullHeightPadding;

      &-centered {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: -$titleBarHeight;

        > #{$self}__jumbotron {
          padding-top: 0;
        }
      }

      @include is-app-layout-desktop {
        padding: $modalWindowInnerFullHeightPaddingDesktop;

        &-centered {
          padding-top: 16px;
          padding-bottom: 16px;

          @media (max-height: $windowMaxHeight) {
            margin-top: 0;
          }
        }
      }
    }

    &--small-width {
      max-width: 403px;
      margin: 0 auto;
    }

    &--profile {
      padding: $modalWindowInnerProfilePadding;
    }

    @include is-app-layout-desktop {
      padding: $modalWindowInnerPaddingDesktop;

      &--alert {
        padding: 16px 32px 32px;
      }

      &--alert-no-title {
        padding-top: 0;
      }

      &--profile {
        padding: $modalWindowInnerProfilePaddingDesktop;
      }
    }
  }

  &__close {
    position: absolute;
    bottom: 100%;
    left: 100%;
    z-index: z-index(base);
    pointer-events: all;
  }

  &--alert {
    background-color: var(--Layer0);
    border-radius: $modalWindowRadius;
  }

  &--full-screen {
    backdrop-filter: blur(10px);
    border-radius: 0;
    box-shadow: none;
  }

  &__content {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: auto;

    .modal-header--no-title + & {
      border-top-left-radius: $modalWindowRadius;
      border-top-right-radius: $modalWindowRadius;
    }
  }

  &__content--no-scroll {
    overflow: hidden;

    #{$self}__wrapper {
      height: 100%;
    }
  }

  &__jumbotron {
    width: 100%;
  }

  &--full-height {
    @include modal-window-styles-border-radius;

    width: 100%;
    background-color: var(--Layer0);

    &:not(#{$self}--slot) {
      &#{$self}--full-height-centered {
        justify-content: center;
      }
    }

    &--slot {
      display: block;
    }

    #{$self}__wrapper {
      display: flex;
      flex: 1;
      flex-direction: column;
    }
  }

  &--with-inner {
    background-color: rgba(0, 0, 0, 0.3);
    background-clip: padding-box;
  }

  &--inner-modal {
    border-top-left-radius: $modalWindowRadius;
    border-top-right-radius: $modalWindowRadius;
    box-shadow: none;

    @media (max-height: $windowMaxHeight) {
      &#{$self}--full-height-centered {
        min-height: 100%;
      }
    }
  }

  @include is-app-layout-phone {
    border-top-left-radius: $modalWindowRadius;
    border-top-right-radius: $modalWindowRadius;
  }
}

@include for-os-windows {
  @include modal-window-styles-for-os-windows;

  .modal-window__content--has-native-scroll {
    scrollbar-color: var(--Highlight) var(--Layer0);
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: $scrollWidth;
    }

    &::-webkit-scrollbar-track {
      background-color: var(--Layer0);
    }

    &::-webkit-scrollbar-thumb {
      min-height: 30px;
      background-color: var(--Highlight);
      border: 4px solid var(--Layer0);
      border-radius: 8px;
    }
  }
}

@include for-os-ios {
  .modal-window {
    > .modal-window__content {
      background: initial;
    }

    &__content {
      border-radius: 0;
    }
  }
}
</style>
