<script lang="ts" setup>
import { useVAmountAnimator } from 'web/src/components/AmountAnimator/VAmountAnimator/composables';

import type { VAmountAnimatorProps, VAmountAnimatorSlots } from '../types';

const props = withDefaults(defineProps<VAmountAnimatorProps>(), {
  duration: 15_000,
  timeRange: () => ({
    min: 900,
    max: 900,
  }),
  isInfinite: true,
  stepRange: () => ({
    min: 0.01,
    max: 0.1,
  }),
});

const {
  animatedValue,
} = useVAmountAnimator(props);

defineSlots<VAmountAnimatorSlots>();
</script>

<template>
  <slot
    :animated-value="animatedValue"
  />
</template>
