import EventsQueue from './queues/events';
import ParametersQueue from './queues/parameters';
import debug from '../services/debug';
import loadAppendScript from '../services/loadAppendScript';
import type { Events } from '../constants';
import { defaultOptions } from '../constants';
import type {
  YandexMetrika, MetricsToolConfigParsed, Options, ParametersData, Instance,
} from '../types';

let INSTANCE: YandexMetrika | null = null;
let OPTIONS: Options = defaultOptions;

const mergeOptions = (options: Options): Options => ({ ...defaultOptions, ...options });

const setDefaultOptions = (options: Options): void => {
  OPTIONS = { ...OPTIONS, ...options };
};

const triggerEvent = (eventName: Events, parameters = {}): void => {
  if (INSTANCE) {
    if (OPTIONS.developMode) {
      debug.info(`trigger event ${eventName}:`, parameters);
    } else {
      INSTANCE.reachGoal(eventName, parameters);
    }
  } else if (OPTIONS.collectQueues) {
    EventsQueue.addEvent({ eventName, parameters });
  }
};

const triggerParameters = (parameters: ParametersData): void => {
  if (INSTANCE) {
    if (OPTIONS.developMode) {
      debug.info('trigger params:', parameters);
    } else {
      INSTANCE.params(parameters);
    }
  } else if (OPTIONS.collectQueues) {
    ParametersQueue.addParameter(parameters);
  }
};

const createYandexMetrika = async (sourceUrl: string, props: MetricsToolConfigParsed): Promise<YandexMetrika> => {
  await loadAppendScript(sourceUrl);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const YaMetrika = window.Ya.Metrika2;
  return new YaMetrika(props);
};

const register = async (config: MetricsToolConfigParsed, options: Options = {}): Promise<void> => {
  if (INSTANCE) {
    debug.error('instance already exist');
  }

  OPTIONS = mergeOptions(options);
  const { sourceUrl = defaultOptions.sourceUrl } = OPTIONS;
  INSTANCE = await createYandexMetrika(sourceUrl, config);

  if (OPTIONS.developMode) {
    debug.info('registration success');
  }

  if (OPTIONS.collectQueues) {
    EventsQueue.run(triggerEvent);
    ParametersQueue.run(triggerParameters);
  }
};

const unregister = (): void => {
  EventsQueue.clear();
  ParametersQueue.clear();
  INSTANCE = null;
};

export default function getInstanceClient(): Instance {
  return {
    register,
    unregister,
    triggerEvent,
    setDefaultOptions,
    triggerParams: triggerParameters,
    getEvents: EventsQueue.getEvents,
    getParams: ParametersQueue.getParameters,
  };
}
