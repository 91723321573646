import type {
  VirtualSportFilterOption,
  VirtualSportSelectedFilter,
} from 'web/src/modules/sportline/submodules/virtual-sport/types';

export default function isSelectedVirtualSportFilterOption(
  option?: Maybe<VirtualSportFilterOption>,
  filter?: Maybe<VirtualSportSelectedFilter>,
): boolean {
  return !!option
    && !!filter
    && option.navigationParameters.urlName.toLowerCase() === filter.urlName?.toLowerCase();
}
