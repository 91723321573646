import { defineStore } from 'pinia';
import {
  computed,
  toRef,
  watch,
} from 'vue';

import { logger } from '@leon-hub/logging';
import { SlipTypeId } from '@leon-hub/api-sdk';
import { BusEvent, useEventsBus } from '@leon-hub/event-bus';

import { useCashoutStore } from 'web/src/modules/cashout/store';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { useBalance } from 'web/src/modules/customer/store/composables';
import useIsIdentifiedUser from 'web/src/modules/identification/composables/useIsIdentifiedUser';

import type { SlipListItem } from '../../../types';
import {
  getEventFromSlipEntryId,
  getSlipEntryId,
} from '../../../utils';
import {
  AddSlipEntriesError,
  BetSlipResultState,
  BetSlipWarningTypes,
  LeaveSlipResultChoice,
  TopLevelTabs,
  BetSlipMainButtonMode,
} from '../../../enums';
import type { BetSlipSummaryStatus } from '../../../enums';
import { useSlipViewSettingsStore } from '../../slip-view-settings/store';
import { usePlaceBetStore } from '../../place-bet/store';
import { useSlipSelectedEntriesStore } from '../../slipSelectedEntries/store';
import { usePendingBetsStore } from '../../pending-bets/store';
import { useSlipInfoStore } from '../../slip-info/store';
import { useStakeInputStore } from '../../stake-input/store';
import { useFreebetStore } from '../../freebet/store';
import { useMultiSinglesStore } from '../../multi-singles/store';
import {
  getSummaryStatus,
  getMainButtonMode,
} from '../utils';

const useSlipRootStore = defineStore('slip-root-store', () => {
  const bus = useEventsBus();

  const { isLoggedIn } = useIsLoggedIn();

  const { balance } = useBalance();

  const { isIdentifiedUser } = useIsIdentifiedUser();

  const multiSinglesStore = useMultiSinglesStore();

  const { resetMultiSinglesFocusOnClose } = multiSinglesStore;

  const isMultiSinglesEnabled = toRef(multiSinglesStore, 'isMultiSinglesEnabled');

  const freebetStore = useFreebetStore();

  const { clearFreeBet } = freebetStore;

  const freeBetSwitchChecked = toRef(freebetStore, 'freeBetSwitchChecked');
  const currentFreeBet = toRef(freebetStore, 'currentFreeBet');
  const selectedFreeBetId = toRef(freebetStore, 'selectedFreeBetId');

  const stakeInputStore = useStakeInputStore();

  const { setStakeInputIsFocused, fixStakeRange } = stakeInputStore;

  const cashoutStore = useCashoutStore();

  const { closeSlipOnCashout } = cashoutStore;

  const cashoutInProgress = toRef(cashoutStore, 'slipCashoutInProgress');

  const slipInfoStore = useSlipInfoStore();

  const {
    removeBetEvents,
    setClearBetListScheduled,
    setBetMode,
    addToSlip,
    removeBetFromList,
    removeBetClick,
    onAddingToSlipError,
    acceptSlipChanges,
    matchSlipType,
  } = slipInfoStore;

  const clearBetListScheduled = toRef(slipInfoStore, 'clearBetListScheduled');
  const isPendingChanges = toRef(slipInfoStore, 'isPendingChanges');
  const betMode = toRef(slipInfoStore, 'betMode');
  const hasLimitError = toRef(slipInfoStore, 'hasLimitError');
  const maxSlipSize = toRef(slipInfoStore, 'maxSlipSize');
  const slipEventsCount = toRef(slipInfoStore, 'slipEventsCount');
  const isBetModeMatchesSlipTypes = toRef(slipInfoStore, 'isBetModeMatchesSlipTypes');
  const mustAcceptChanges = toRef(slipInfoStore, 'mustAcceptChanges');
  const multiSinglesTotalPrice = toRef(slipInfoStore, 'multiSinglesTotalPrice');
  const multiSinglesAvailableForBet = toRef(slipInfoStore, 'multiSinglesAvailableForBet');
  const isMultiSinglesMode = toRef(slipInfoStore, 'isMultiSinglesMode');
  const selectedStakeValue = toRef(slipInfoStore, 'selectedStakeValue');
  const maxStake = toRef(slipInfoStore, 'maxStake');
  const minStake = toRef(slipInfoStore, 'minStake');
  const marketsChanged = toRef(slipInfoStore, 'marketsChanged');
  const pricesChanged = toRef(slipInfoStore, 'pricesChanged');
  const entriesHasChanged = toRef(slipInfoStore, 'entriesHasChanged');
  const isSyncInProgress = toRef(slipInfoStore, 'isSyncInProgress');

  const placeBetStore = usePlaceBetStore();

  const {
    setResultState,
    resetSlipResultState,
    resetResultErrorState,
    setLeaveBetResultChoice,
    placeBet,
  } = placeBetStore;

  const resultState = toRef(placeBetStore, 'resultState');
  const leaveBetResultChoice = toRef(placeBetStore, 'leaveBetResultChoice');
  const slipUnsettledSingles = toRef(placeBetStore, 'slipUnsettledSingles');

  const slipViewSettingsStore = useSlipViewSettingsStore();

  const {
    setIsFullyVisibleOnMobile,
    stopLeaveEnterAnimation,
    handleSlipVisibilityChange,
    selectTopTab,
    setSlipFullHeight,
    setSlipSettingsVisibility,
    setClearBetListWarnVisibility,
    hideSlipFromBookingCodeOverlay,
  } = slipViewSettingsStore;

  const isHiddenOnMobile = toRef(slipViewSettingsStore, 'isHiddenOnMobile');

  const selectedEntriesIds = toRef(useSlipSelectedEntriesStore(), 'selectedEntriesIds');

  const pendingBetsCount = toRef(usePendingBetsStore(), 'pendingBetsCount');

  // getters

  const isPendingState = computed<boolean>(() => resultState.value === BetSlipResultState.PENDING || resultState.value === BetSlipResultState.WAIT_FOR_RETRY
    || isPendingChanges.value);

  const notEnoughBalance = computed<boolean>(() => {
    if (!isLoggedIn.value || freeBetSwitchChecked.value) {
      return false;
    }
    const stake = isMultiSinglesMode.value ? multiSinglesTotalPrice.value : selectedStakeValue.value;
    return stake > balance.value;
  });

  const stakeAboveMaximum = computed<boolean>(() => !!maxStake.value && selectedStakeValue.value > maxStake.value);

  const stakeBelowMinimum = computed<boolean>(() => !!minStake.value && selectedStakeValue.value < minStake.value);

  const stakeIsOutOfRange = computed<boolean>(() => stakeAboveMaximum.value || stakeBelowMinimum.value);

  const summaryStatus = computed<BetSlipSummaryStatus>(() => getSummaryStatus({
    resultState: resultState.value,
    stakeAboveMaximum: stakeAboveMaximum.value,
    notEnoughBalance: notEnoughBalance.value,
    stakeBelowMinimum: stakeBelowMinimum.value,
    marketsChanged: marketsChanged.value,
    entriesChanged: entriesHasChanged.value,
    pricesChanged: pricesChanged.value,
    hasLimitError: hasLimitError.value,
    isFreeBet: !!currentFreeBet.value,
  }));

  const mainButtonMode = computed<BetSlipMainButtonMode>(() => getMainButtonMode({
    resultState: resultState.value,
    isPendingChanges: isPendingChanges.value,
    isLoggedIn: isLoggedIn.value,
    notEnoughBalance: notEnoughBalance.value,
    mustAcceptChanges: mustAcceptChanges.value,
    stakeIsOutOfRange: stakeIsOutOfRange.value,
    isBetModeMatchesSlipTypes: isBetModeMatchesSlipTypes.value,
    identificationRequired: !isIdentifiedUser.value,
    isFreeBet: !!currentFreeBet.value,
  }));

  const placeBetButtonDisabled = computed<boolean>(() => {
    const readyToPlaceBet = mainButtonMode.value === BetSlipMainButtonMode.READY_TO_PLACE_BET;
    const emptyFreeBet = freeBetSwitchChecked.value && !selectedFreeBetId.value;
    if (emptyFreeBet && readyToPlaceBet) {
      return true;
    }
    if (mainButtonMode.value === BetSlipMainButtonMode.UNABLE_TO_APPLY_FREEBET) {
      return true;
    }
    if (readyToPlaceBet && isMultiSinglesMode.value
      && (!multiSinglesAvailableForBet.value.length || !multiSinglesTotalPrice.value)) {
      return true;
    }
    return (
      readyToPlaceBet
        || mainButtonMode.value === BetSlipMainButtonMode.MUST_ACCEPT_CHANGES)
      && isSyncInProgress.value;
  });

  // actions

  const switchToMyBets = (): void => {
    if (resultState.value === BetSlipResultState.SUCCESS) {
      removeBetEvents();
    }
    setResultState(BetSlipResultState.INITIAL);
    if (isHiddenOnMobile.value) {
      handleSlipVisibilityChange(true);
    }
    selectTopTab(TopLevelTabs.MY_BETS);
  };

  const handleDisplayAnimationEnd = (isVisible = false): void => {
    stopLeaveEnterAnimation();
    setIsFullyVisibleOnMobile(isVisible);
    hideSlipFromBookingCodeOverlay();
    if (!isVisible) {
      if (resultState.value === BetSlipResultState.SUCCESS) {
        resetSlipResultState();
      }
      if (resultState.value === BetSlipResultState.ERROR) {
        resetResultErrorState();
      }
      if (clearBetListScheduled.value) {
        removeBetEvents({
          leaveBetResultChoice: leaveBetResultChoice.value,
          slipUnsettledSingles: slipUnsettledSingles.value,
        });
        setLeaveBetResultChoice(LeaveSlipResultChoice.CLEAR_ALL);
        setClearBetListScheduled(false);
      }
      setSlipSettingsVisibility(false);
      setClearBetListWarnVisibility(false);
      setStakeInputIsFocused(false);
      resetMultiSinglesFocusOnClose();
    }
  };

  const handleSlipClose = (): void => {
    if (process.env.VUE_APP_LAYOUT_DESKTOP) {
      handleDisplayAnimationEnd(false);
      return;
    }
    if (resultState.value === BetSlipResultState.PENDING) {
      return;
    }
    setSlipFullHeight(false);
    handleSlipVisibilityChange(false);
    if (cashoutInProgress.value) {
      closeSlipOnCashout();
    }
    /** @see handleDisplayAnimationEnd */
  };

  const clearBetList = (): void => {
    logger.info('The betslip has been cleared');
    if (process.env.VUE_APP_LAYOUT_DESKTOP) {
      removeBetEvents();
      setClearBetListWarnVisibility(false);
      return;
    }
    setClearBetListScheduled(true);
    handleSlipClose();
  };

  const handleRunnerClick = (item: SlipListItem | null): void => {
    // close shared slip overlay if visible
    hideSlipFromBookingCodeOverlay();

    if (isPendingState.value || !item) {
      return;
    }
    if (resultState.value === BetSlipResultState.SUCCESS) {
      resetSlipResultState();
    }

    const id = getSlipEntryId(item);

    const isAlreadyInSlip = selectedEntriesIds.value.includes(id);
    if (isAlreadyInSlip) {
      void removeBetClick(id);
      return;
    }
    const event = getEventFromSlipEntryId(id);
    const selectedEntryOfSameEvent = selectedEntriesIds.value.find((identifier) => identifier.startsWith(`${event}`));
    if (selectedEntryOfSameEvent) {
      if (isMultiSinglesEnabled.value && betMode.value !== SlipTypeId.SINGLE) {
        setBetMode(SlipTypeId.SINGLE);
      }
      if (!isMultiSinglesEnabled.value) {
        // replace selected runner
        removeBetFromList(selectedEntryOfSameEvent);
      }
    }

    if (hasLimitError.value) {
      onAddingToSlipError({ message: '', entriesError: AddSlipEntriesError.LIMIT });
      return;
    }

    if (maxSlipSize.value && slipEventsCount.value >= maxSlipSize.value) {
      bus.emit(BusEvent.SHOW_SLIP_MODAL_WARNING, { type: BetSlipWarningTypes.SLIP_LIMIT_REACHED });
      return;
    }
    addToSlip(item);
  };

  const handleMainButtonClick = (): void => {
    if (!isBetModeMatchesSlipTypes.value) {
      handleSlipClose();
      return;
    }
    if (mustAcceptChanges.value) {
      void acceptSlipChanges();
      return;
    }
    if (stakeIsOutOfRange.value) {
      fixStakeRange({
        maxStake: maxStake.value,
        minStake: minStake.value,
        stakeBelowMinimum: stakeBelowMinimum.value,
      });
      return;
    }
    void placeBet();
  };

  const openSlipContextRelatively = (): void => {
    if (!slipEventsCount.value && pendingBetsCount.value) {
      selectTopTab(TopLevelTabs.MY_BETS);
    } else {
      matchSlipType();
    }
    handleSlipVisibilityChange(true);
  };

  const handleUserLogout = (): void => {
    setStakeInputIsFocused(false);
    resetSlipResultState(false);
    selectTopTab(TopLevelTabs.SLIP);
    setSlipSettingsVisibility(false);
    clearFreeBet();
  };

  watch(isLoggedIn, (newValue) => {
    if (!newValue) {
      handleUserLogout();
    }
  });

  bus.on(BusEvent.CLAIM_TO_SLIP_CLOSE, () => {
    handleSlipClose();
  });

  return {
    switchToMyBets,
    handleSlipClose,
    clearBetList,
    handleRunnerClick,
    handleMainButtonClick,
    handleDisplayAnimationEnd,
    openSlipContextRelatively,
    placeBetButtonDisabled,
    mainButtonMode,
    summaryStatus,
  };
});

export default useSlipRootStore;
