<script lang="ts" setup>
import { IconName, IconSize } from '@leon-hub/icons';

import type { VButtonProps } from 'web/src/components/Button/VButton/types';
import type { ButtonKind } from 'web/src/components/Button/VButton/enums';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import SwiperNavigationButton from 'web/src/components/Swiper/Navigation/SwiperNavigationButton/SwiperNavigationButton.vue';

interface SwiperNavigationButtonBaseProps {
  isRounded?: boolean;
  isPreviousAllowed?: boolean;
  isNextAllowed?: boolean;
  buttonKind?: ButtonKind;
}

interface SwiperNavigationButtonBaseEmits {
  (e: 'click-previous'): void;
  (e: 'click-next'): void;
}

defineProps<SwiperNavigationButtonBaseProps>();

const emit = defineEmits<SwiperNavigationButtonBaseEmits>();

const previousButtonProperties: VButtonProps = {
  iconName: null ? IconName.SLOTT_ANGLE_SMALL_LEFT : IconName.EXPAND_LEFT,
  iconSize: IconSize.SIZE_24,
};

const nextButtonProperties: VButtonProps = {
  iconName: null ? IconName.SLOTT_ANGLE_SMALL_RIGHT : IconName.EXPAND_RIGHT,
  iconSize: IconSize.SIZE_24,
};

function emitClickPrevious(): void {
  emit('click-previous');
}

function emitClickNext(): void {
  emit('click-next');
}
</script>

<template>
  <div v-auto-id="'SwiperNavigationButtonsBase'"
    :class="$style['swiper-navigation-buttons']"
  >
    <div
      v-if="isPreviousAllowed"
      :class="[
        $style['swiper-navigation-buttons__btn'],
        $style['swiper-navigation-buttons__btn--prev']
      ] "
    >
      <VButton
        v-if="buttonKind"
        v-bind="previousButtonProperties"
        :kind="buttonKind"
        @click="emitClickPrevious"
      />
      <SwiperNavigationButton
        v-else
        :is-rounded="isRounded"
        @click="emitClickPrevious"
      />
    </div>
    <div
      v-if="isNextAllowed"
      :class="[
        $style['swiper-navigation-buttons__btn'],
        $style['swiper-navigation-buttons__btn--next']
      ] "
    >
      <VButton
        v-if="buttonKind"
        v-bind="nextButtonProperties"
        :kind="buttonKind"
        @click="emitClickNext"
      />
      <SwiperNavigationButton
        v-else
        :is-rounded="isRounded"
        is-next
        @click="emitClickNext"
      />
    </div>
  </div>
</template>

<style lang="scss" module>
.swiper-navigation-buttons {
  @include z-index(base);

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.25s $animationEaseInOut;

  // TODO: find better way of hover detecting
  :global(.swiper:hover) & {
    opacity: 1;
  }

  &__btn {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    pointer-events: all;
    user-select: none;

    &--prev {
      left: 0;
    }

    &--next {
      right: 0;
    }
  }
}
</style>
