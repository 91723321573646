<script setup lang="ts">
import type {
  InputHintProps,
} from '../../types';
import useInputHint from './useInputHint';
import {
  dataTestHint as vDataTestHint,
  dataTestHintRight as vDataTestHintRight,
  dataTestHintLink as vDataTestHintLink,
} from '../../directives';

const props = withDefaults(defineProps<InputHintProps>(), {
  error: '',
  errorRight: '',
  hint: '',
  hintRight: '',
  hintLink: null,
  hintLinkRight: null,
});
const {
  hintErrorText,
  hintErrorTextRight,
  hintLinkComponent,
  hintLinkRightComponent,
  hintLinkLeftProperties,
  hintLinkRightProperties,
} = useInputHint(props);
</script>

<template>
  <div v-auto-id="'InputHint'"
    :class="$style['wrapper-hint']"
  >
    <slot>
      <div :class="$style['wrapper-hint__wrapper']">
        <div
          v-data-test-hint
          :data-test-error="!!error || !!$slots.errorLeft"
          :class="{
            [$style['wrapper-hint__text']]: true,
            [$style['wrapper-hint__text--left']]: true,
            [$style['wrapper-hint__text--error']]: !!error || !!$slots.errorLeft,
          }"
        >
          <slot name="hintLeft">
            <template v-if="hintLink && !error">
              <component
                :is="hintLinkComponent"
                v-data-test-hint-link
                :class="$style['wrapper-hint__link']"
                v-bind="hintLinkLeftProperties"
              >
                {{ hintLink.text }}
              </component>
            </template>
            <template v-else>
              <slot name="errorLeft">
                {{ hintErrorText }}
              </slot>
            </template>
          </slot>
        </div>
        <div
          v-if="hintLinkRight || hintErrorTextRight"
          v-data-test-hint-right
          :data-test-error="!!errorRight"
          :class="{
            [$style['wrapper-hint__text']]: true,
            [$style['wrapper-hint__text--right']]: true,
            [$style['wrapper-hint__text--error']]: !!errorRight,
          }"
        >
          <slot name="hintRight">
            <template v-if="hintLinkRight && !errorRight">
              <component
                :is="hintLinkRightComponent"
                v-data-test-hint-link
                :class="$style['wrapper-hint__link']"
                v-bind="hintLinkRightProperties"
              >
                {{ hintLinkRight.text }}
              </component>
            </template>
            <template v-else>
              {{ hintErrorTextRight }}
            </template>
          </slot>
        </div>
      </div>
    </slot>
  </div>
</template>

<style lang="scss" module>
.wrapper-hint {
  @import '../../common';

  display: block;
  width: 100%;
  margin: 4px 0 10px;

  &__wrapper {
    display: flex;
    justify-content: space-between;
    padding: $inputHintPadding;
  }

  &__text {
    @include inputHint;

    &--error {
      color: $inputErrorHintColor;
    }

    &--left {
      text-align: left;
    }

    &--right {
      margin-left: 16px;
      text-align: right;
    }
  }

  &__link {
    @include inputHintLinkFont;

    color: $inputHintLink;
    text-decoration: $inputHintLinkDecoration;

    @include for-hover {
      &:hover {
        color: $inputHintLinkHover;
        text-decoration: $inputHintLinkHoverDecoration;
      }
    }
  }
}
</style>
