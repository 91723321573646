import { useRouter } from 'vue-router';
import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import type {
  CasinoSearchSectionEmits,
  CasinoSearchSectionProps,
} from 'web/src/modules/search/submodules/casino/components/CasinoSearchSection/types';
import type { EgsGame } from 'web/src/modules/egs/types';
import type { CasinoSearchItemProps } from 'web/src/modules/search/submodules/casino/components/CasinoSearchItem/types';
import { useEgsNavigation } from 'web/src/modules/egs/submodules/navigation/composable';

interface CasinoSearchSectionComposable {
  hasGroups: ComputedRef<boolean>;
  hasGames: ComputedRef<boolean>;
  emitItemClick: (game: EgsGame) => void;
  emitLoadMore: () => void;
  getItemProperties: (game: EgsGame) => CasinoSearchItemProps;
}

export default function useCasinoSearchSection(
  props: CasinoSearchSectionProps,
  emits: CasinoSearchSectionEmits,
): CasinoSearchSectionComposable {
  const router = useRouter();
  const { getEgsGameHref } = useEgsNavigation(router);

  const hasGames = computed(() => props.games.length > 0);
  const hasGroups = computed(() => props.groups.length > 0);

  function getItemProperties(game: EgsGame): CasinoSearchItemProps {
    return {
      name: game.name,
      group: game.group.name,
      categories: game.categories.map((category) => category.name),
      href: getEgsGameHref(game),
      searchText: props.searchText,
      image: game.images.squareImage ?? game.images.preview ?? undefined,
      ntBadge: game.ntBadge || undefined,
    };
  }

  function emitItemClick(game: EgsGame): void {
    emits('item-click', game);
  }

  function emitLoadMore(): void {
    emits('load-more');
  }

  return {
    hasGroups,
    hasGames,
    emitItemClick,
    emitLoadMore,
    getItemProperties,
  };
}
