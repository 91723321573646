import { isNumber } from '@leon-hub/guards';

import ServerDateTimeInterval from 'web/src/utils/ServerDateTimeInterval';
import { SportFilterKickoffPeriod } from 'web/src/modules/sportline/enums';
import type { DateTimeRange, EmptyDateTimeRange } from 'web/src/modules/sportline/types';

const KickoffPeriodUtils = {
  floorSeconds(range: DateTimeRange): DateTimeRange {
    const { from, to } = range;

    if (!from || !to) return range;

    return {
      from: from - (from % 1000),
      to: to - (to % 1000),
    };
  },

  dateTimeRangeFromKickoffPeriod(
    kickoffPeriod: SportFilterKickoffPeriod | number,
    floorSeconds = true,
  ): DateTimeRange {
    const defaultInterval: EmptyDateTimeRange = { from: undefined, to: undefined };
    let result;

    if (isNumber(kickoffPeriod)) {
      return ServerDateTimeInterval.fromNowPlusHours(kickoffPeriod).toObject();
    }

    switch (kickoffPeriod) {
      case SportFilterKickoffPeriod.ALL: {
        result = defaultInterval;
        break;
      }

      case SportFilterKickoffPeriod.ONEHOUR: {
        result = ServerDateTimeInterval.fromNowPlusHours(1).toObject();
        break;
      }

      case SportFilterKickoffPeriod.TWOHOURS: {
        result = ServerDateTimeInterval.fromNowPlusHours(2).toObject();
        break;
      }

      case SportFilterKickoffPeriod.THREEHOURS: {
        result = ServerDateTimeInterval.fromNowPlusHours(3).toObject();
        break;
      }

      case SportFilterKickoffPeriod.SIXHOURS: {
        result = ServerDateTimeInterval.fromNowPlusHours(6).toObject();
        break;
      }

      case SportFilterKickoffPeriod.TWELVEHOURS: {
        result = ServerDateTimeInterval.fromNowPlusHours(12).toObject();
        break;
      }

      case SportFilterKickoffPeriod.TWENTYFOURHOURS: {
        result = ServerDateTimeInterval.fromNowPlusHours(24).toObject();
        break;
      }

      case SportFilterKickoffPeriod.TODAY: {
        result = ServerDateTimeInterval.today().toObject();
        break;
      }

      case SportFilterKickoffPeriod.TOMORROW: {
        result = ServerDateTimeInterval.tomorrow().toObject();
        break;
      }

      case SportFilterKickoffPeriod.AFTERTOMORROW: {
        result = ServerDateTimeInterval.afterTomorrow().toObject();
        break;
      }

      default: {
        result = defaultInterval;
        break;
      }
    }

    return floorSeconds
      ? KickoffPeriodUtils.floorSeconds(result)
      : result;
  },

  optionalRelativeDateTimeRangeInMinutes(
    kickoffPeriod: SportFilterKickoffPeriod | number,
    floorSeconds = true,
  ): DateTimeRange {
    const defaultInterval: EmptyDateTimeRange = { from: undefined, to: undefined };

    if (isNumber(kickoffPeriod)) {
      return { from: 0, to: kickoffPeriod * 60 };
    }

    switch (kickoffPeriod) {
      case SportFilterKickoffPeriod.ALL:
        return defaultInterval;

      case SportFilterKickoffPeriod.ONEHOUR:
        return { from: 0, to: 60 };

      case SportFilterKickoffPeriod.TWOHOURS:
        return { from: 0, to: 2 * 60 };

      case SportFilterKickoffPeriod.THREEHOURS:
        return { from: 0, to: 3 * 60 };

      case SportFilterKickoffPeriod.SIXHOURS:
        return { from: 0, to: 6 * 60 };

      case SportFilterKickoffPeriod.TWELVEHOURS:
        return { from: 0, to: 12 * 60 };

      case SportFilterKickoffPeriod.TWENTYFOURHOURS:
        return { from: 0, to: 24 * 60 };

      case SportFilterKickoffPeriod.TODAY:
      case SportFilterKickoffPeriod.TOMORROW:
      case SportFilterKickoffPeriod.AFTERTOMORROW: {
        return KickoffPeriodUtils.dateTimeRangeFromKickoffPeriod(kickoffPeriod, floorSeconds);
      }

      default:
        return defaultInterval;
    }
  },
};

export default KickoffPeriodUtils;
