import { defineStore } from 'pinia';
import {
  computed,
  ref,
} from 'vue';

import type { DesktopModal } from 'web/src/modules/core/types';

import type { DesktopModalCloseCallback } from './types/desktopModalStore';

export const useDesktopModalStore = defineStore('desktop-modal', () => {
  const desktopModal = ref<DesktopModal>();
  const isProfileLayout = computed(() => !!desktopModal.value?.isProfile);
  const hasDesktopModal = computed(() => !!desktopModal.value);
  const closeModalEvents = ref<DesktopModalCloseCallback[]>([]);

  function setDesktopModal(value: DesktopModal | undefined): void {
    desktopModal.value = value;
  }

  function addCloseEventListener(callback: DesktopModalCloseCallback): void {
    closeModalEvents.value.push(callback);
  }

  function removeCloseEventListener(callback: DesktopModalCloseCallback): void {
    closeModalEvents.value = closeModalEvents.value.filter((c) => c !== callback);
  }

  return {
    desktopModal,
    hasDesktopModal,
    isProfileLayout,
    closeModalEvents,
    addCloseEventListener,
    removeCloseEventListener,
    setDesktopModal,
  };
});
