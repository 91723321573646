/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
import { debounce } from 'lodash';
import type { Class } from 'utility-types';
// @ts-ignore
import type LRU from 'lru';

import type { GqlRequestMiddleware, GqlMiddlewareClient } from '@leon-hub/api-types';
import { getBuiltinConfig } from '@leon-hub/service-locator-env';
import { doFetch } from '@leon-hub/fetch-client';
import type { FetchResponse } from '@leon-hub/fetch-client';
import { logger } from '@leon-hub/logging';
import {
  assert,
  isArrayOf,
  isArrayOfStrings,
  isNumber,
  isObject,
  isOptionalString,
  isString,
  isValidObject,
} from '@leon-hub/guards';
import type {
  AbstractDocumentResponse,
  RequestOptions,
  ApiMethod,
} from '@leon-hub/api-sdk';
import {
  AccessDeniedRemoteApiExceptionCode,
  G2SVRequiredExceptionCode,
  InvalidCodeExceptionCode,
  PreviousRequestHasNotExpiredExceptionCode,
  RemoteApiErrorExceptionCode,
  RequestOptionsPriority,
  SessionExceptionCode,
} from '@leon-hub/api-sdk';
import { normalizeError } from '@leon-hub/errors';

import { GqlApiPreviousRequestHasNotExpired } from './errors/GqlApiPreviousRequestHasNotExpired';
import type {
  BaseClientOptions,

  BatchQueueRequestItem,
  ClientSettings,
  DataReturnType,
  ServerApiResponseBaseError,
  ServerApiResponseDefaultError,
  ServerApiSubResponseError,
} from '../types';
import GqlApiPromotionNotFoundError from './errors/GqlApiPromotionNotFoundError';
import GqlApiBatchUnknownHashError from './errors/GqlApiBatchUnknownHashError';
import GqlApiBatchHashingDisabledError from './errors/GqlApiBatchHashingDisabledError';
import type GraphqlClient from './GraphqlClient';
import BaseClient from '../BaseClient';
import getBaseHeaders from '../getBaseHeaders';
import type ServerApiSubResponseResultNode from './types/ServerApiSubResponseResultNode';
import getResponseDescription from './utils/getResponseDescription';
import GqlApiG2svRequiredError from './errors/GqlApiG2svRequiredError';
import { ApiServiceUnavailableError } from '../errors/ApiServiceUnavailableError';
import { ApiTechnicalError } from '../errors/ApiTechnicalError';
import { ApiConnectionError } from '../errors/ApiConnectionError';
import type { ApiError } from '../errors/ApiError';
import GqlApiBatchedSubRequestClientError from './errors/GqlApiBatchedSubRequestClientError';
import { GqlApiAccessDeniedError } from './errors/GqlApiAccessDeniedError';
import getRequestDescription from './utils/getRequestDescription';
import resolveApi1Url from '../../helpers/resolveApi1Url';
import GqlApiResponseErrorCode from './errors/GqlApiResponseErrorCode';
import GqlApiBatchedSubRequestError from './errors/GqlApiBatchedSubRequestError';
import GqlApiErrorCode from './errors/GqlApiErrorCode';
import GqlApiBatchMaxSizeError from './errors/GqlApiBatchMaxSizeError';
import GqlApiBatchError from './errors/GqlApiBatchError';
import GqlBatchedRequest from './GqlBatchedRequest';
import GqlBatchedSubRequest from './GqlBatchedSubRequest';
import type GqlQueryVariables from './GqlQueryVariables';
import { getDefaultSettings } from '../../settings';
import GqlApiCaptchaRequiredError from './errors/GqlApiCaptchaRequiredError';
import convertToGqlApiError from './errors/convertToGqlApiError';
import { ApiErrorCode } from '../errors/ApiErrorCode';
import { ApiIpBlockedError } from '../errors/ApiIpBlockedError';
import { GqlApiServiceSuspendedError } from './errors/GqlApiServiceSuspendedError';
import { GqlApiCustomerHistoryLimitExceededError } from './errors/GqlApiCustomerHistoryLimitExceededError';
import type { GraphqlClientRequestOptions } from './types';
import { getUrl, cleanGqlFragmentDuplication } from './utils';

function isServerApiResponseBaseError(errorData: unknown): errorData is ServerApiResponseBaseError {
  return isObject(errorData)
    && isString(errorData.errorCode)
    && isOptionalString(errorData.message);
}

function isServerApiErrorResponse(errorData: unknown): errorData is ServerApiResponseDefaultError {
  return isServerApiResponseBaseError(errorData);
}

function createLogNamespace(namespace: string) {
  return logger.createNamespace(namespace);
}

/**
 * @TODO: Change strategy from white list to black list. Potentially any new error can stop app loading on startup.
 * @Deprecated
 */
function isRetryableApiError(error: ApiError, isMutation: boolean): boolean {
  if (error instanceof ApiConnectionError
    || error instanceof GqlApiBatchUnknownHashError
    || error instanceof GqlApiBatchHashingDisabledError) {
    return true;
  }

  return !isMutation
    && (error instanceof ApiTechnicalError
      || error instanceof ApiServiceUnavailableError);
}

interface ServerApiResponseInvalidBatchSizeError extends ServerApiResponseBaseError {
  errorCode: 'INVALID_REQUESTS_NUMBER';
  maxSize: number;
  message: string;
}

function isIpBlockedError(
  errorData: unknown,
): boolean {
  return isServerApiErrorResponse(errorData)
    && ApiErrorCode.API_IP_BLOCKED_ERROR.equals(errorData.errorCode);
}

function isServerApiUnknownHashError(error: unknown): error is GqlApiBatchUnknownHashError {
  return isServerApiErrorResponse(error)
    && ApiErrorCode.API_UNKNOWN_HASH.equals(error.errorCode)
    && isString(error.requestId);
}

function isServerApiHashingDisabled(error: unknown): error is GqlApiBatchHashingDisabledError {
  return isServerApiErrorResponse(error)
    && ApiErrorCode.API_REQUEST_HASHING_IS_DISABLED.equals(error.errorCode);
}

function isServerApiResponseInvalidBatchSizeError(
  errorData: unknown,
): errorData is ServerApiResponseInvalidBatchSizeError {
  return isServerApiErrorResponse(errorData)
    && errorData.errorCode === 'INVALID_REQUESTS_NUMBER'
    && isNumber(errorData.maxSize);
}

// TODO: solve this problem with another approach after LEONWEB-3829, LEONAPI-560
function isServerApiSubResponseError(item: unknown): item is ServerApiSubResponseError {
  return isObject(item)
    && isOptionalString(item.message)
    && (item.path === undefined || isArrayOfStrings(item.path))
    && (isObject(item.extensions)
      && isString(item.extensions.errorCode)
      && isOptionalString(item.extensions.classification)
      && isOptionalString(item.extensions.message)
    )
    && (item.locations === undefined
      || (Array.isArray(item.locations) && item.locations.every((loc) => isValidObject(loc, {
        line: [isNumber],
        column: [isNumber],
      }))));
}

function isServerApiFailedSubResponse(value: unknown): value is ServerApiFailedSubResponse {
  return isObject(value)
    // TODO: ValidationError will skip data field, but it's required by interface.
    && (value.data === null || value.data === undefined)
    && Array.isArray(value.errors)
    && isArrayOf(isServerApiSubResponseError, value.errors);
}

function isServerApiSubResponseMutationResultNode(item: unknown): item is ServerApiSubResponseResultNode {
  return isObject(item)
    && item.data !== undefined;
}

function isServerApiSubResponseQueryResultNode(item: unknown): item is ServerApiSubResponseResultNode {
  return isObject(item)
    && item.data !== undefined
    && isNumber(item.ts);
}

function isServerApiSubResponseResultNode(value: unknown): value is ServerApiSubResponseResultNode {
  return isServerApiSubResponseQueryResultNode(value)
    || isServerApiSubResponseMutationResultNode(value);
}

interface ServerApiFailedSubResponse {
  data: null;
  errors: ServerApiSubResponseError[];
}

interface ServerApiOkSubResponse {
  data: Record<string, unknown>;
}

type ServerApiBaseSubResponse = ServerApiOkSubResponse | ServerApiFailedSubResponse;

type ServerApiBatchedResponse = Partial<Record<string, ServerApiBaseSubResponse>>;

function getCommonHeaders(): Record<string, string> {
  return {
    'content-type': 'application/json',
  };
}

function isServerApiOkSubResponse(value: unknown): value is ServerApiOkSubResponse {
  return isObject(value) && isObject(value.data);
}

function isServerApiSubResponse(value: unknown): value is ServerApiBaseSubResponse {
  return isServerApiOkSubResponse(value) || isServerApiFailedSubResponse(value);
}

function isServerApiBatchedResponse(argument: unknown): argument is ServerApiBatchedResponse {
  return isObject(argument)
    && Object.entries(argument).every(([, item]) => isServerApiSubResponse(item));
}

export default class GqlBatchedClient extends BaseClient implements GraphqlClient, GqlMiddlewareClient {
  private logger = createLogNamespace('BatchedGraphqlClient');

  private readonly responseNodeCache: LRU<AbstractDocumentResponse> | null = null;

  private settings: ClientSettings = getDefaultSettings();

  private readonly defaultMaxAccumTime: number = 40;

  private readonly defaultMinAccumTime: number = 20;

  private requestNumber = 1;

  private maxMutationsPerBatch = 1;

  private abortSignals = new WeakMap<GqlBatchedRequest, AbortController>();

  public setupSettings(settings: ClientSettings): void {
    this.settings = {
      ...this.settings,
      ...settings,
    };
  }

  public setMaxMutationsPerBatch(count: number): void {
    this.maxMutationsPerBatch = count;
  }

  public setMaxBatchQueueSize(size: number): void {
    this.settings.maxBatchQueueSize = size;
    this.debug('max batch queue size has been changed to %s', size);
    this.updateTimer();
  }

  private isQueryHashingStateEnabled = true;

  private isQueryHashingEnabled(): boolean {
    return this.isQueryHashingStateEnabled
      && getBuiltinConfig().isQueryHashingEnabled;
  }

  private toggleQueryHashingState(state: boolean): void {
    this.isQueryHashingStateEnabled = state;
  }

  private getMaxAccumTime(): number {
    return this.settings.maxAccumTime ?? this.defaultMaxAccumTime;
  }

  private getMinAccumTime(): number {
    return this.settings.minAccumTime ?? this.defaultMinAccumTime;
  }

  private get maxBatchQueueSize(): number {
    return this.settings.maxBatchQueueSize ?? 0;
  }

  private get requestTimeout(): number {
    return this.settings.requestTimeout || 15 * 1000 + 1000; // 15 sec server timeout + 1000ms network timeout
  }

  private get maxRequestRetriesCount(): number {
    return this.settings.maxRequestRetriesCount || 1;
  }

  private logError(error: Error) {
    if (!this.settings.silentErrors) {
      this.logger.error(error);
    }
  }

  private pendingBatch = new GqlBatchedRequest();

  // sentBatches structure:
  // Array of:
  // * single optional batch with at least one mutation(s)
  // * zero to infinite "query only" batches.
  private sentBatches: GqlBatchedRequest[] = [];

  getSentBatchesCount():number {
    return this.sentBatches.length;
  }

  public constructor(options: Partial<BaseClientOptions> = {}) {
    super({
      baseUrl: resolveApi1Url(),
      method: 'POST',
      ...options,
      headers: {
        ...getCommonHeaders(),
        ...getBaseHeaders(),
        ...options?.headers,
      },
    });
  }

  private getAllBatches(): Readonly<GqlBatchedRequest[]> {
    return [
      this.pendingBatch,
      ...this.sentBatches,
    ];
  }

  private findSameGqlSubRequest(item: GqlBatchedSubRequest): undefined | GqlBatchedSubRequest {
    const allBatches = this.getAllBatches();
    for (let index = 0, { length } = allBatches; index < length; index += 1) {
      const cachedItem = allBatches[index].findCached(item);
      if (cachedItem) {
        return cachedItem;
      }
    }
    return undefined;
  }

  private nextCheckTimer: ReturnType<typeof setTimeout> | undefined;

  /**
   * Update timer immediately, and throttle next calls for 10ms(on each call), but 20ms max, then call again.
   * @private
   */
  private updateTimer = debounce(() => {
    if (!this.isMutationInProcess()) {
      if (this.nextCheckTimer) {
        clearInterval(this.nextCheckTimer);
        this.nextCheckTimer = undefined;
      }
      const nextBatchSize = this.getNextBatch().size();
      if (nextBatchSize === 0) {
        return;
      }

      const { maxBatchQueueSize } = this;
      assert(nextBatchSize <= maxBatchQueueSize);
      const isFilledBatch = nextBatchSize === maxBatchQueueSize;

      const now = Date.now();
      const nextCallTimestamp = isFilledBatch ? now : Math.max(
        now,
        // Batch default close time.
        Math.min(
          this.pendingBatch.getNewestItem().created + this.getMaxAccumTime(),
          this.pendingBatch.getOldestItem().created + this.getMinAccumTime(),
        ),
      );
      const timeout = nextCallTimestamp - now;
      this.nextCheckTimer = setTimeout(() => {
        this.nextCheckTimer = undefined;
        // eslint-disable-next-line promise/catch-or-return
        this.handlePendingBatch().then((): void => {
          const hasTimer = !!this.nextCheckTimer;
          // eslint-disable-next-line promise/always-return
          if (
            !hasTimer
            // Next batch is pending.
            && this.getNextBatch().size() > 0
          ) {
            this.updateTimer();
          }
        }, (error) => {
          this.logError(convertToGqlApiError(error, 'handlePendingBatch error'));
        });
      }, timeout);
    }
  }, 5, { maxWait: 10 });

  private async handlePendingBatch(): Promise<void> {
    this.debug('prepare to handle next batch');
    const nextBatch = this.getNextBatch({ doExtractBatch: true });
    this.debug(`next batch size=${nextBatch.size()}`);
    if (nextBatch.size() > 0) {
      this.sentBatches.push(nextBatch);
      try {
        await this.doBatchedRequest(nextBatch);
      } finally {
        this.removeBatchFromSent(nextBatch);
      }
    } else {
      this.debug('unable to handle empty pending batch');
    }
  }

  private removeBatchFromSent(batch: GqlBatchedRequest) {
    this.sentBatches = this.sentBatches.filter((sentBatch) => sentBatch !== batch);
  }

  private middlewares = new Map<ApiMethod, Set<GqlRequestMiddleware<ApiMethod>>>();

  addMiddleware(method: ApiMethod, middleware: GqlRequestMiddleware<ApiMethod>): () => void {
    const set = this.middlewares.get(method) ?? new Set();
    this.middlewares.set(method, set.add(middleware));
    return () => { this.middlewares.get(method)?.delete(middleware); };
  }

  private applyMiddlewares({ method, variables }: { method: ApiMethod; variables: GqlQueryVariables }) {
    let context = { method, variables };
    if (context.variables) {
      for (const middleware of this.middlewares.get(method) ?? []) {
        try {
          const result = middleware(context);
          if (result !== undefined) {
            const resultVariables = result.variables;
            if (resultVariables) {
              context = {
                variables: resultVariables,
                method,
              };
            }
          }
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error('Unable to apply middleware', normalizeError(err), method);
          // Allow to continue without failed feature.
        }
      }
    }
    return context;
  }

  async requestGraphql<Node, ResponseData extends DataReturnType<AR>, AR extends AbstractDocumentResponse>(
    query: string,
    variables: GqlQueryVariables,
    callback: (node: Node) => AR,
    { cacheTTL = this.defaultCacheTTL, ...requestOptions }: RequestOptions,
    method: ApiMethod,
  ): Promise<ResponseData> {
    const context = this.applyMiddlewares({ method, variables });
    const subRequest = new GqlBatchedSubRequest<Node, AbstractDocumentResponse>({
      content: cleanGqlFragmentDuplication(query),
      options: context.variables.options,
      resolver: callback,
      id: requestOptions?.id,
      silent: requestOptions?.silent,
      headers: requestOptions?.headers,
      priority: requestOptions?.priority ?? RequestOptionsPriority.NORMAL,
      timeout: requestOptions?.timeout,
      retry: requestOptions?.retry,
      group: requestOptions?.group,
    });

    if (cacheTTL) {
      const cacheItem = await this.getCache(subRequest.getCacheKey());
      if (cacheItem) return cacheItem as ResponseData;
    }

    // if same request is already active, do not create new request.
    const sameGqlSubRequest = this.findSameGqlSubRequest(subRequest as GqlBatchedSubRequest);
    if (sameGqlSubRequest) {
      return sameGqlSubRequest.promise as Promise<ResponseData>;
    }
    // if cache exists, then set cached `ts`, so server can tell client to reuse cached response.
    const cachedResponseNode = this.getFromCache(subRequest.getCacheKey());
    if (cachedResponseNode) {
      subRequest.setCacheTimestamp(cachedResponseNode.ts);
    }

    this.pendingBatch.addSubRequest(subRequest);
    this.updateTimer();

    try {
      const result = await subRequest.deferred.promise;
      if (cacheTTL) {
        void this.setCache(subRequest.getCacheKey(), result, cacheTTL);
      }
      return result as ResponseData;
    } catch (error) {
      let apiError = convertToGqlApiError(error, this.bootstrapTranslations.WEB2_TECHNICAL_ERROR);
      const hasError = this.errorsCache.has(apiError);
      if (hasError) {
        apiError = apiError.clone();
      } else {
        this.errorsCache.add(apiError);
      }

      apiError.setOperationName(subRequest.getOperationName());

      throw Object.assign(apiError, { silent: subRequest.silent });
    }
  }

  private errorsCache = new WeakSet<ApiError>();

  private saveToCache(cacheKey: string, node: AbstractDocumentResponse) {
    return this.responseNodeCache?.set(cacheKey, JSON.parse(JSON.stringify(node)) as AbstractDocumentResponse);
  }

  private removeCache(cacheKey: string): void {
    this.responseNodeCache?.remove(cacheKey);
  }

  private getFromCache(cacheKey: string): AbstractDocumentResponse | undefined {
    return this.responseNodeCache?.get(cacheKey);
  }

  private getRequestData(batch: GqlBatchedRequest): BatchQueueRequestItem[] {
    const requests: BatchQueueRequestItem[] = [];
    for (const item of [...batch.mutations, ...batch.queries]) {
      let ts = item.getCacheTimestamp();
      const cacheItem = this.getFromCache(item.getCacheKey());
      if (cacheItem?.ts) {
        ts = cacheItem.ts;
      }

      const requestItem: BatchQueueRequestItem = {
        id: item.id,
        query: this.isQueryHashingEnabled() ? undefined : item.getContent(),
        qKey: this.isQueryHashingEnabled() ? item.getContentHash() : undefined,
        operationName: item.getOperationName(),
        variables: {
          options: item.mutation
            ? item.options
            : {
              ...item.options,
              // Client cache timestamp, to tell server.
              ts,
            },
        },
      };

      requests.push(requestItem);
    }

    return requests;
  }

  private failBatchedRequest(
    batch: GqlBatchedRequest,
    batchApiError: ApiError,
  ) {
    for (const gqlSubRequest of batch.getSortedGqlSubRequests()) {
      // Handle failed batch in the end of process queue.
      // TODO: required to rm batch with response from sentBatches.
      void Promise.resolve().then(() => {
        this.handleFailedBatchedSubRequest(
          gqlSubRequest,
          batchApiError,
        );
      });
    }
  }

  // TODO: replace to service
  // eslint-disable-next-line consistent-return,sonarjs/cognitive-complexity
  public async doBatchedRequest(
    batch: GqlBatchedRequest,
  ): Promise<void> {
    const requestData = this.getRequestData(batch);
    try {
      const controller = new AbortController();
      this.abortSignals.set(batch, controller);
      const initOptions: RequestInit = {};
      if (batch.hasUniformPriority(RequestOptionsPriority.LOW)) {
        initOptions.priority = 'low';
      }
      if (batch.hasUniformPriority(RequestOptionsPriority.HIGH)) {
        initOptions.priority = 'high';
      }
      const responseData = await this.request<ServerApiBatchedResponse | ServerApiResponseDefaultError>({
        abortController: controller,
        endpoint: '/',
        data: requestData,
        headers: batch.getHeaders(),
        timeout: batch.getTimeout(),
        query: {
          ops: batch.getGqlSubRequests().map((req) => req.getOperationName()).join(','),
        },
        initOptions,
      });
      if (isServerApiBatchedResponse(responseData)) {
        try {
          this.handleResponseData(batch, responseData);
        } catch (error) {
          this.failBatchedRequest(batch, convertToGqlApiError(error, this.bootstrapTranslations.WEB2_TECHNICAL_ERROR));
        }
      } else if (isServerApiErrorResponse(responseData)) {
        if (isServerApiHashingDisabled(responseData) || isServerApiUnknownHashError(responseData)) {
          this.toggleQueryHashingState(false);
          const err = isServerApiUnknownHashError(responseData) ? new GqlApiBatchUnknownHashError({
            requestId: responseData.requestId,
            batch,
          }) : new GqlApiBatchHashingDisabledError({ message: responseData.message });
          this.failBatchedRequest(
            batch,
            err,
          );
        } else if (isServerApiResponseInvalidBatchSizeError(responseData)) {
          this.setMaxBatchQueueSize(responseData.maxSize);
          this.failBatchedRequest(
            batch,
            new GqlApiBatchMaxSizeError({
              batchMaxSize: this.maxBatchQueueSize,
              batch,
            }),
          );
        } else if (isIpBlockedError(responseData)) {
          this.failBatchedRequest(
            batch,
            new ApiIpBlockedError(),
          );
        } else {
          this.failBatchedRequest(
            batch,
            new GqlApiBatchError({
              code: GqlApiErrorCode.API_TECHNICAL_ERROR,
              batch,
            }),
          );
        }
      } else {
        this.failBatchedRequest(
          batch,
          new GqlApiBatchError({ batch }),
        );
      }
    } catch (error) {
      this.removeBatchFromSent(batch);
      this.failBatchedRequest(batch, convertToGqlApiError(error, this.bootstrapTranslations.WEB2_TECHNICAL_ERROR));
    } finally {
      this.abortSignals.delete(batch);
    }
  }

  private handleBatchedSubRequest(
    subRequest: GqlBatchedSubRequest,
    batch: GqlBatchedRequest,
    responseItem: ServerApiSubResponseResultNode,
  ) {
    let responseNode: AbstractDocumentResponse;
    let responseNodeTs = 0;
    try {
      responseNode = subRequest.resolver(responseItem.data);
      responseNodeTs = responseNode.ts;

      assert(isServerApiSubResponseResultNode(responseNode));
    } catch (rawError) {
      this.handleFailedBatchedSubRequest(subRequest, new GqlApiBatchedSubRequestClientError({
        batch,
        subRequest,
        responseItem,
        originalError: normalizeError(rawError),
      }));

      return;
    }

    if (!subRequest.mutation) {
      const isCacheUsed = responseNodeTs > 0;
      const cacheKey = subRequest.getCacheKey();
      const isCacheRequiredByServer = isCacheUsed
        && responseNode.data === null
        && subRequest.getCacheTimestamp() === responseNodeTs;
      if (isCacheRequiredByServer) {
        const cachedNode = this.getFromCache(cacheKey);
        if (!cachedNode) {
          // Cache miss.
          this.removeCache(cacheKey);
          // Repeat request without client cache ts to force server to generate new resp.
          subRequest.resetCacheTimestamp();
          this.pendingBatch.addSubRequest(subRequest);
          this.updateTimer();
          return;
        }
        // Cache hit. Update response with data copy.
        Object.assign(responseNode, {
          data: JSON.parse(JSON.stringify(cachedNode.data)),
        });
      } else if (isCacheUsed) {
        this.saveToCache(cacheKey, responseNode);
      }
    }
    subRequest.deferred.resolve(responseNode.data);
  }

  private handleResponseData(
    batch: GqlBatchedRequest,
    subRequestResponses: ServerApiBatchedResponse,
  ) {
    const sortedList = batch.getSortedGqlSubRequests();
    sortedList.reduce<number>((accumulator, subRequest) => {
      const subRequestResponse = subRequestResponses[subRequest.id];
      if (isServerApiFailedSubResponse(subRequestResponse)) {
        const { errors: [error] } = subRequestResponse;

        assert(error.extensions, 'Unable to get gql error extensions');
        assert(error, 'Unable to handle sub request error');
        // TODO: refactor after error types will be generated within sdk ()
        if (error.extensions?.classification === 'ValidationError') {
          // TODO: server bug(no error code), remove assert.
          assert(error.extensions.errorCode, 'errorCode is required');
          Object.assign(error.extensions, {
            errorCode: GqlApiResponseErrorCode.GQL_API_SERVICE_VALIDATION_ERROR,
          });
        }

        const errorCode = error.extensions.errorCode || GqlApiResponseErrorCode.API_TECHNICAL_ERROR;
        assert(isString(errorCode), 'Expected errorCode to be as string');
        const message = error.extensions.message || this.bootstrapTranslations.WEB2_TECHNICAL_ERROR;
        assert(isString(message), 'Expected errorMessage to be as string');

        if (errorCode === InvalidCodeExceptionCode.INVALID_CODE) {
          assert(isNumber(error.extensions.code), 'Expected code to be as number');

          if ([502, 503].includes(error.extensions.code)) {
            this.handleFailedBatchedSubRequest(subRequest, new ApiServiceUnavailableError());
          } else {
            this.handleFailedBatchedSubRequest(subRequest, new ApiTechnicalError());
          }

          return accumulator;
        }

        let GqlApiErrorConstructor: Class<GqlApiBatchedSubRequestError>;
        switch (errorCode) {
          case G2SVRequiredExceptionCode.G2SV_REQUIRED:
            GqlApiErrorConstructor = GqlApiG2svRequiredError;
            break;

          case RemoteApiErrorExceptionCode.INVALID_CAPTCHA:
          case RemoteApiErrorExceptionCode.CAPTCHA_NEEDED:
            GqlApiErrorConstructor = GqlApiCaptchaRequiredError;
            break;

          case RemoteApiErrorExceptionCode.SERVICE_SUSPENDED:
            GqlApiErrorConstructor = GqlApiServiceSuspendedError;
            break;

          case RemoteApiErrorExceptionCode.PROMOTION_NOT_FOUND:
            GqlApiErrorConstructor = GqlApiPromotionNotFoundError;
            break;

          case RemoteApiErrorExceptionCode.CUSTOMER_HISTORY_LIMIT_EXCEEDED:
            GqlApiErrorConstructor = GqlApiCustomerHistoryLimitExceededError;
            break;

          case AccessDeniedRemoteApiExceptionCode.ACCESS_DENIED:
          case SessionExceptionCode.SESSION:
            GqlApiErrorConstructor = GqlApiAccessDeniedError;
            break;

          case PreviousRequestHasNotExpiredExceptionCode.PREVIOUS_REQUEST_HAS_NOT_EXPIRED:
            GqlApiErrorConstructor = GqlApiPreviousRequestHasNotExpired;
            break;

          default:
            GqlApiErrorConstructor = GqlApiBatchedSubRequestError;
            break;
        }

        const apiError = new GqlApiErrorConstructor({
          message,
          batch,
          subRequest,
          // TODO: Refactor after LEONAPI-552
          extensions: {
            ...error.extensions,
            errorCode,
            message,
          },
        });

        this.handleFailedBatchedSubRequest(subRequest, apiError);
      } else if (isServerApiSubResponseResultNode(subRequestResponse)) {
        this.handleBatchedSubRequest(
          subRequest,
          batch,
          subRequestResponse,
        );
        return accumulator + 1;
      } else {
        this.handleFailedBatchedSubRequest(subRequest, new GqlApiBatchError({
          batch,
          subRequest,
        }));
      }
      return accumulator;
    }, 0);
  }

  async getResponse(
    url: string,
    options: RequestInit,
    $return: { request: Request | undefined } = { request: undefined },
    controller?: AbortController,
    timeout?: number,
  ): Promise<FetchResponse> {
    return doFetch(
      url,
      options,
      timeout ?? this.requestTimeout,
      (originalError, request) => new ApiConnectionError({ request, originalError }),
      controller,
      (request) => {
        Object.assign($return, { request });
      },
    );
  }

  async request<T>(options: GraphqlClientRequestOptions): Promise<T> {
    const {
      endpoint,
      data,
      headers,
      abortController,
      timeout,
      query = {},
      initOptions = {},
    } = options;
    assert(data, 'Unable to start request without data.');
    const fullUrl = `${this.getOrigin()}${this.getBaseUrl()}${endpoint}`
      .replace(/\/+$/, '');

    const requestInit: RequestInit = {
      body: null,
      headers: this.getHeaders(headers),
      method: this.getDefaultMethod(),
      credentials: this.getCredentials(),
      ...initOptions,
    };

    try {
      requestInit.body = JSON.stringify(data);
      const $return: { request: Request | undefined } = { request: undefined };
      const url = getUrl(fullUrl, query);
      const response = await this.getResponse(url, requestInit, $return, abortController, timeout);
      const { request } = $return;

      const clonedResponse = process.env.VUE_APP_PRERENDER ? response.clone() : undefined;

      try {
        // Allow to handle outside success or failed batch with error data.
        // eslint-disable-next-line @typescript-eslint/return-await
        return await response.json();
      } catch (error) {
        assert(error instanceof SyntaxError, 'Unable to parse response body');
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        const apiError = [502, 503].includes(response.status)
          ? new ApiServiceUnavailableError({ request })
          : new ApiTechnicalError({ originalError: error, request });

        if (request) {
          apiError.addLogMetaData('request', Object.assign(response, {
            toJSON: () => getRequestDescription(request),
          }));
        }
        apiError.addLogMetaData('response', Object.assign(response, {
          toJSON: () => getResponseDescription(response),
        }));

        if (process.env.VUE_APP_PRERENDER) {
          // eslint-disable-next-line no-console
          console.info(`[Graphql] Parse response error - status: ${response.status}`, error);

          try {
            const responseText = await clonedResponse?.text();
            // eslint-disable-next-line no-console
            console.info(`[Graphql] Response text ${responseText}`);
          } catch (bodyParseError) {
            // eslint-disable-next-line no-console
            console.info('[Graphql] Body parse error', bodyParseError);
          }
        }

        return Promise.reject(apiError);
      }
    } catch (error) {
      if (process.env.VUE_APP_PRERENDER) {
        // eslint-disable-next-line no-console
        console.info('[Graphql] Request error', error);
      }

      throw convertToGqlApiError(error, this.bootstrapTranslations.WEB2_TECHNICAL_ERROR);
    }
  }

  private abortBatch(batch: GqlBatchedRequest) {
    const controller = this.abortSignals.get(batch);
    const batchName = `batch#${batch.id}`;
    assert(controller, `Unable to stop request for ${batchName}`);
    controller.abort();
  }

  public clearQueue(): void {
    for (const batch of this.sentBatches) {
      this.abortBatch(batch);
    }
    this.pendingBatch = new GqlBatchedRequest();
  }

  public setAccumTime(n: number): void {
    assert(n >= 0 && Number.isFinite(n));
    this.settings.maxAccumTime = n;
  }

  public resetAccumTime(): void {
    this.setAccumTime(this.defaultMaxAccumTime);
  }

  private isMutationInProcess(): boolean {
    return this.sentBatches.slice(0).some((batch) => batch.mutations.length > 0);
  }

  private async whenAllSentMutationsProcessed(): Promise<void> {
    await Promise.all(
      this.sentBatches
        .filter((batch) => batch.mutations.length > 0)
        .map((batch) => batch.whenFulfilled()),
    );
  }

  private getNextBatch(options?: { size?: number; doExtractBatch?: boolean }): GqlBatchedRequest {
    const doExtractBatch = options?.doExtractBatch ?? false;
    const size = options?.size ?? this.maxBatchQueueSize;

    const firstSentBatch = this.sentBatches[0];
    assert(
      !this.isMutationInProcess(),
      'Expected mutations only within first batch',
    );

    const sentBatchHasMutation = !!firstSentBatch?.mutations.length;
    let { maxMutationsPerBatch: leftMutations } = this;
    let firstGqlRequestInQueue: GqlBatchedSubRequest | undefined;
    return this.pendingBatch.getBatch({
      size,
      doExtractBatch,
      filter: (gqlRequest) => {
        if (gqlRequest.mutation) {
          // Block next batch with mutation if mutation is already processing by server.
          // TODO: add option to send non-blocking requests
          if (sentBatchHasMutation || leftMutations <= 0) return false;
          leftMutations -= 1;
        }
        if (!firstGqlRequestInQueue) {
          firstGqlRequestInQueue = gqlRequest;
          return true;
        }
        return firstGqlRequestInQueue.getGroup() === gqlRequest.getGroup();
      },
    });
  }

  /**
   * Flush pending requests. In parallel mode, we handle all pending batches at once.
   * IN serial mode, we handle available batches one by one.
   *
   * @param inParallel
   */
  public async flush({ inParallel = false } = {}): Promise<void> {
    this.debug('flush');
    // send flushable requests first even if in the process other requests are added
    const gqlRequests = this.pendingBatch.getGqlSubRequests();
    for (const gqlRequest of gqlRequests) {
      gqlRequest.setPriority(RequestOptionsPriority.HIGH);
    }

    await this.whenAllSentMutationsProcessed();

    if (inParallel) {
      const promises: Promise<void>[] = [];
      do {
        promises.push(this.handlePendingBatch());
      } while (this.getNextBatch({ doExtractBatch: false }).size() > 0);
      await Promise.allSettled(promises);
      return Promise.resolve();
    }
    await Promise.allSettled([this.handlePendingBatch()]);

    const nextBatch = this.getNextBatch({ doExtractBatch: false });
    return nextBatch.size() > 0 ? this.flush() : Promise.resolve();
  }

  private handleFailedBatchedSubRequest(
    item: GqlBatchedSubRequest,
    error: ApiError,
  ) {
    const failedAttempts = item.getFailedAttempts();
    const maxRequestRetriesCount = item.getRetry() ?? this.maxRequestRetriesCount;
    if (isRetryableApiError(error, item.mutation) && failedAttempts < maxRequestRetriesCount) {
      item.incrementFailedAttempts();
      this.pendingBatch.addSubRequest(item);
      this.updateTimer();
    } else {
      // Reset cache for failed request.
      this.removeCache(item.getCacheKey());

      error.addLogMetaData('retries', failedAttempts);
      item.deferred.reject(error);
    }
  }
}

export { GqlBatchedClient };
