import { localeStorageKey, Locale } from "@leon-hub/locale";
const fallBackLocales = {
  ru: "ru_RU",
  ru_KZ: "ru_RU",
  ru_UA: "ru_RU",
  ru_UZ: "ru_RU",
  de: "de_DE",
  pt: "pt_PT",
  pt_BR: "pt_PT",
  es: "es_ES",
  es_PE: "es_ES",
  es_MX: "es_ES",
  es_CL: "es_ES",
  fr: "fr_FR",
  fr_CA: "fr_FR",
  vi: "vi_VN",
  tr: "tr_TR",
  th: "th_TH",
  el: "el_GR"
};
function getInitLocale() {
  var _a;
  if (process.env.VUE_APP_RENDERING_SSR) {
    return "";
  }
  const locale = ((_a = window.localStorage) == null ? void 0 : _a.getItem(localeStorageKey)) || window.navigator.language.replace("-", "_");
  if (fallBackLocales[locale]) {
    return fallBackLocales[locale];
  }
  if (locale.includes("_")) {
    return locale;
  }
  return "";
}
const deTranslations = /* @__PURE__ */ Object.freeze({
  WEB2_ERROR: "Fehler",
  WEB2_SESSION_EXPIRED: "Sitzung abgelaufen",
  CONNECTION_ERROR: "Verbindungsfehler. Überprüfen Sie bitte Ihre Internetverbindung und versuchen Sie es erneut.",
  WEB2_TECHNICAL_ERROR: "Es ist ein technischer Fehler aufgetreten. Bitte lade die Seite neu oder versuche es später noch einmal.",
  JSP_PCL_FBOT_CLOSE: "Schließen",
  JS_CAPTION_ATTENTION: "Fehler",
  WEB2_DO_LOGIN: "Anmelden",
  JSPNAV_USTAT_REGISTER: "Registrieren",
  WEB2_ERROR_503_TITLE_V2: "SLOTT wird gewartet",
  WEB2_ERROR_503_TEXT1_V2: "Wir führen derzeit eine planmäßige Systemwartung durch.",
  WEB2_ERROR_503_TEXT2_V2: "Bitte versuche es später noch einmal.",
  WEB2_ERROR_503_TEXT3_V2: "Wir entschuldigen uns für die Unannehmlichkeiten.",
  WEB2_ERROR_503_BTN_UPDATE_V2: "Aktualisieren",
  WEB2_HELP_EMAIL: /* @__PURE__ */ (() => process.env.VUE_APP_INFO_EMAIL)(),
  WEB2_ERROR_BTN_GO_HOME: "Zurück zur Startseite",
  WEB2_ERROR_BTN_SUPPORT: "Unterstützung",
  WEB2_ERROR_TEXT_SUPPORT: "Benötigst du Hilfe? Wende dich an uns – wir sind für dich da!",
  WEB2_ERROR_404_TITLE: "Ups! Seite nicht gefunden",
  WEB2_ERROR_404_MSG: "Möglicherweise wurde die Seite entfernt oder existiert nicht.",
  WEB2_ERROR_403_TITLE: "Zugriff verweigert",
  WEB2_ERROR_403_MSG: "Du hast anscheinend keine Berechtigung, diese Seite anzuzeigen. Gehen wir zurück zur Startseite.",
  WEB2_CONNECTION_ERROR_CAPTION: "Verbindungsfehler",
  WEB2_CONNECTION_ERROR_DESCRIPTION: "Bitte überprüfe deine Internetverbindung und versuche es erneut.",
  WEB2_CONNECTION_ERROR_BTN_RETRY: "Erneut versuchen",
  WEB2_MOBILE_LOADING_APK_TITLE: "Aktualisiere die App",
  WEB2_MOBILE_LOADING_APK_MESSAGE: "Bitte warte, bis das Update abgeschlossen ist.",
  WEB2_MOBILE_UPDATE_APK_TITLE: "App-Update verfügbar",
  WEB2_MOBILE_UPDATE_APK_MESSAGE: "Bitte aktualisiere die App, um sie weiterhin zu verwenden",
  WEB2_MOBILE_UPDATE_APK_BTN_UPDATE: "Aktualisierung",
  WEB2_MOBILE_UPDATE_APK_BTN_POSTPONE: "Später",
  WEB2_IP_BLOCKED_ERROR: "Dieser Dienst ist in deinem Land nicht verfügbar.",
  WEB2_UPDATE_IOS_TITLE: "iOS Update Required",
  WEB2_UPDATE_IOS_MESSAGE: "Ihre aktuelle iOS Version wird nicht unterstützt. Bitte aktualisieren Sie Ihr Gerät, um von den neuesten Verbesserungen zu profitieren.",
  WEB2_UPDATE_IOS_BUTTON: "Update iOS now",
  WEB2_UPDATE_ANDROID_TITLE: "Android Update Required",
  WEB2_UPDATE_ANDROID_MESSAGE: "Ihre derzeitige Android-Version wird nicht unterstützt. Bitte aktualisieren Sie Ihr Gerät, um von den neuesten Verbesserungen zu profitieren.",
  WEB2_UPDATE_ANDROID_BUTTON: "Update Android now"
});
const ruTranslations$2 = /* @__PURE__ */ Object.freeze({
  WEB2_ERROR: "Ошибка",
  WEB2_SESSION_EXPIRED: "Сессия истекла",
  CONNECTION_ERROR: "Ошибка соединения. Пожалуйста, проверьте соединение с интернетом и повторите попытку.",
  WEB2_TECHNICAL_ERROR: "Произошла техническая ошибка. Обновите страницу или повторите попытку позже.",
  JSP_PCL_FBOT_CLOSE: "Закрыть",
  JS_CAPTION_ATTENTION: "Внимание",
  WEB2_DO_LOGIN: "Войти",
  JSPNAV_USTAT_REGISTER: "Регистрация",
  WEB2_ERROR_503_TITLE_V2: "Сайт БК Леон временно недоступен",
  WEB2_ERROR_503_TEXT1_V2: "Проводятся технические работы по обновлению сайта.",
  WEB2_ERROR_503_TEXT2_V2: "Совсем скоро мы снова будем с вами.",
  WEB2_ERROR_503_TEXT3_V2: "Приносим извинения за неудобства.",
  WEB2_ERROR_503_BTN_UPDATE_V2: "Обновить",
  WEB2_HELP_EMAIL: /* @__PURE__ */ (() => process.env.VUE_APP_INFO_EMAIL_RU)(),
  WEB2_ERROR_BTN_GO_HOME: "На главную",
  WEB2_ERROR_BTN_SUPPORT: "Служба поддержки",
  WEB2_ERROR_TEXT_SUPPORT: "Возникли трудности?<br> Мы всегда рады помочь",
  WEB2_ERROR_404_TITLE: "Страница не найдена",
  WEB2_ERROR_404_MSG: "Возможно, ее не существует или она была удалена.",
  WEB2_ERROR_403_TITLE: "Доступ запрещен",
  WEB2_ERROR_403_MSG: "Доступ к этой странице ограничен. Пожалуйста, вернитесь на Главную.",
  WEB2_CONNECTION_ERROR_CAPTION: "Ошибка соединения",
  WEB2_CONNECTION_ERROR_DESCRIPTION: "Произошла ошибка при подключении к серверу. Проверьте ваше интернет-соединение и повторите попытку.",
  WEB2_CONNECTION_ERROR_BTN_RETRY: "Повторить",
  WEB2_MOBILE_LOADING_APK_TITLE: "Идет обновление",
  WEB2_MOBILE_LOADING_APK_MESSAGE: "Пожалуйста, дождитесь полной загрузки обновлений.",
  WEB2_MOBILE_UPDATE_APK_TITLE: "Обновить приложение?",
  WEB2_MOBILE_UPDATE_APK_MESSAGE: "В нашем приложении появились обновления. Установите их, чтобы продолжить игру.",
  WEB2_MOBILE_UPDATE_APK_BTN_UPDATE: "Обновить",
  WEB2_MOBILE_UPDATE_APK_BTN_POSTPONE: "Не сейчас",
  WEB2_IP_BLOCKED_ERROR: "Сервис недоступен в вашей стране.",
  WEB2_UPDATE_IOS_TITLE: "Обновите iOS",
  WEB2_UPDATE_IOS_MESSAGE: "Текущая версия iOS не поддерживается. Пожалуйста, обновите ваше устройство, чтобы получить доступ ко всем функциям сайта.",
  WEB2_UPDATE_IOS_BUTTON: "Обновить",
  WEB2_UPDATE_ANDROID_TITLE: "Обновите Android",
  WEB2_UPDATE_ANDROID_MESSAGE: "Текущая версия Android не поддерживается. Пожалуйста, обновите ваше устройство, чтобы получить доступ ко всем функциям сайта.",
  WEB2_UPDATE_ANDROID_BUTTON: "Обновить"
});
const i18nBootstrapTranslations$2 = {
  DEFAULT: /* @__PURE__ */ Object.freeze({
    WEB2_ERROR: "Error",
    WEB2_SESSION_EXPIRED: "Session expired",
    CONNECTION_ERROR: "Connection error. Please check your internet connection and try again.",
    WEB2_TECHNICAL_ERROR: "A technical error has occurred. Please reload the page or try again later.",
    JSP_PCL_FBOT_CLOSE: "Close",
    JS_CAPTION_ATTENTION: "Attention",
    WEB2_DO_LOGIN: "Log In",
    JSPNAV_USTAT_REGISTER: "Sign Up",
    WEB2_ERROR_503_TITLE_V2: "Our website is temporarily unavailable",
    WEB2_ERROR_503_TEXT1_V2: "We are currently undergoing scheduled system maintenance.",
    WEB2_ERROR_503_TEXT2_V2: "Please try again soon.",
    WEB2_ERROR_503_TEXT3_V2: "We apologise for the inconvenience.",
    WEB2_ERROR_503_BTN_UPDATE_V2: "Refresh",
    WEB2_HELP_EMAIL: /* @__PURE__ */ (() => process.env.VUE_APP_INFO_EMAIL)(),
    WEB2_ERROR_BTN_GO_HOME: "Back to main page",
    WEB2_ERROR_BTN_SUPPORT: "Support",
    WEB2_ERROR_TEXT_SUPPORT: "Have questions? We are always happy to help",
    WEB2_ERROR_404_TITLE: "Page not found",
    WEB2_ERROR_404_MSG: "The page you are looking for does not exist. It might have been moved or deleted.",
    WEB2_ERROR_403_TITLE: "Access forbidden",
    WEB2_ERROR_403_MSG: "The page you're trying to access has restricted access. Please go back to the homepage.",
    WEB2_CONNECTION_ERROR_CAPTION: "Connection error",
    WEB2_CONNECTION_ERROR_DESCRIPTION: "We were unable to connect to the server. Please check your internet connection and try again.",
    WEB2_CONNECTION_ERROR_BTN_RETRY: "Retry",
    WEB2_MOBILE_LOADING_APK_TITLE: "Updating the app",
    WEB2_MOBILE_LOADING_APK_MESSAGE: "Please wait until the update is complete.",
    WEB2_MOBILE_UPDATE_APK_TITLE: "App update available",
    WEB2_MOBILE_UPDATE_APK_MESSAGE: "Please update to the latest version to continue using the app.",
    WEB2_MOBILE_UPDATE_APK_BTN_UPDATE: "Update",
    WEB2_MOBILE_UPDATE_APK_BTN_POSTPONE: "Later",
    WEB2_IP_BLOCKED_ERROR: "This service is not available in your country.",
    WEB2_UPDATE_IOS_TITLE: "iOS Update Required",
    WEB2_UPDATE_IOS_MESSAGE: "Your current iOS version is not supported. To access the latest improvements, please update your device.",
    WEB2_UPDATE_IOS_BUTTON: "Update iOS now",
    WEB2_UPDATE_ANDROID_TITLE: "Android Update Required",
    WEB2_UPDATE_ANDROID_MESSAGE: "Your current Android version is not supported. To access the latest improvements, please update your device.",
    WEB2_UPDATE_ANDROID_BUTTON: "Update Android now"
  }),
  [/* @__PURE__ */ (() => Locale.FR_FR)()]: /* @__PURE__ */ Object.freeze({
    WEB2_ERROR: "Erreur",
    WEB2_SESSION_EXPIRED: "La session a expiré",
    CONNECTION_ERROR: "Erreur de connexion. Veuillez vérifier votre connexion Internet et réessayer.",
    WEB2_TECHNICAL_ERROR: "Une erreur technique s'est produite. Veuillez recharger la page ou réessayer plus tard.",
    JSP_PCL_FBOT_CLOSE: "Fermer",
    JS_CAPTION_ATTENTION: "Erreur",
    WEB2_DO_LOGIN: "Se connecter",
    JSPNAV_USTAT_REGISTER: "S'inscrire",
    WEB2_ERROR_503_TITLE_V2: "Notre site est momentanément indisponible",
    WEB2_ERROR_503_TEXT1_V2: "Nous procédons actuellement à une maintenance planifiée du système.",
    WEB2_ERROR_503_TEXT2_V2: "Veuillez réessayer plus tard.",
    WEB2_ERROR_503_TEXT3_V2: "Nous nous excusons pour la gêne occasionnée.",
    WEB2_ERROR_503_BTN_UPDATE_V2: "Rafraîchir",
    WEB2_HELP_EMAIL: /* @__PURE__ */ (() => process.env.VUE_APP_INFO_EMAIL)(),
    WEB2_ERROR_BTN_GO_HOME: "Retour à la page d'accueil",
    WEB2_ERROR_BTN_SUPPORT: "Support",
    WEB2_ERROR_TEXT_SUPPORT: "Des questions? Nous sommes toujours heureux d'aider",
    WEB2_ERROR_404_TITLE: "Page non trouvée",
    WEB2_ERROR_404_MSG: "La page que vous recherchez n'existe pas. Elle a peut-être été déplacée ou supprimée.",
    WEB2_ERROR_403_TITLE: "Accès interdit",
    WEB2_ERROR_403_MSG: "La page à laquelle vous essayez d'accéder a un accès restreint. Veuillez revenir à la page d'accueil.",
    WEB2_CONNECTION_ERROR_CAPTION: "Erreur de connexion",
    WEB2_CONNECTION_ERROR_DESCRIPTION: "Nous n'avons pas pu nous connecter au serveur. S'il vous plaît, vérifiez votre connexion à internet et réessayez.",
    WEB2_CONNECTION_ERROR_BTN_RETRY: "Réessayer",
    WEB2_MOBILE_LOADING_APK_TITLE: "Mise à jour de l'application",
    WEB2_MOBILE_LOADING_APK_MESSAGE: "Veuillez patienter jusqu'à ce que la mise à jour soit terminée.",
    WEB2_MOBILE_UPDATE_APK_TITLE: "Mise à jour de l'application disponible",
    WEB2_MOBILE_UPDATE_APK_MESSAGE: "Veuillez télécharger la dernière version pour continuer à utiliser l'application.",
    WEB2_MOBILE_UPDATE_APK_BTN_UPDATE: "Mettre à jour",
    WEB2_MOBILE_UPDATE_APK_BTN_POSTPONE: "Plus tard",
    WEB2_IP_BLOCKED_ERROR: "Ce service n'est pas disponible dans votre pays.",
    WEB2_UPDATE_IOS_TITLE: "Mise à jour iOS requise",
    WEB2_UPDATE_IOS_MESSAGE: "Votre version iOS actuelle n'est pas prise en charge. Pour accéder aux dernières améliorations, veuillez mettre à jour votre appareil.",
    WEB2_UPDATE_IOS_BUTTON: "Mettre à jour iOS maintenant",
    WEB2_UPDATE_ANDROID_TITLE: "Mise à jour Android requise",
    WEB2_UPDATE_ANDROID_MESSAGE: "Votre version actuelle d'Android n'est pas prise en charge. Pour accéder aux dernières améliorations, veuillez mettre à jour votre appareil.",
    WEB2_UPDATE_ANDROID_BUTTON: "Mettre à jour Android maintenant"
  }),
  [/* @__PURE__ */ (() => Locale.RU_RU)()]: /* @__PURE__ */ Object.freeze({
    WEB2_ERROR: "Ошибка",
    WEB2_SESSION_EXPIRED: "Сессия истекла",
    CONNECTION_ERROR: "Ошибка соединения. Пожалуйста, проверьте соединение с интернетом и повторите попытку.",
    WEB2_TECHNICAL_ERROR: "Произошла техническая ошибка. Обновите страницу или повторите попытку позже.",
    JSP_PCL_FBOT_CLOSE: "Закрыть",
    JS_CAPTION_ATTENTION: "Ошибка",
    WEB2_DO_LOGIN: "Войти",
    JSPNAV_USTAT_REGISTER: "Регистрация",
    WEB2_ERROR_503_TITLE_V2: "Сайт Slott временно недоступен",
    WEB2_ERROR_503_TEXT1_V2: "Проводятся технические работы по обновлению сайта.",
    WEB2_ERROR_503_TEXT2_V2: "Совсем скоро мы снова будем с вами.",
    WEB2_ERROR_503_TEXT3_V2: "Приносим извинения за неудобства.",
    WEB2_ERROR_503_BTN_UPDATE_V2: "Обновить",
    WEB2_HELP_EMAIL: /* @__PURE__ */ (() => process.env.VUE_APP_INFO_EMAIL)(),
    WEB2_ERROR_BTN_GO_HOME: "На главную",
    WEB2_ERROR_BTN_SUPPORT: "Помощь",
    WEB2_ERROR_TEXT_SUPPORT: "Возникли трудности? Мы всегда рады помочь",
    WEB2_ERROR_404_TITLE: "Страница не найдена",
    WEB2_ERROR_404_MSG: "Возможно, ее не существует, или она была удалена.",
    WEB2_ERROR_403_TITLE: "Доступ запрещен",
    WEB2_ERROR_403_MSG: "Доступ к этой странице ограничен. Пожалуйста, вернитесь на Главную.",
    WEB2_CONNECTION_ERROR_CAPTION: "Ошибка соединения",
    WEB2_CONNECTION_ERROR_DESCRIPTION: "Произошла ошибка при подключении к серверу. Проверьте ваше интернет-соединение и повторите попытку.",
    WEB2_CONNECTION_ERROR_BTN_RETRY: "Повторить",
    WEB2_MOBILE_LOADING_APK_TITLE: "Идет обновление",
    WEB2_MOBILE_LOADING_APK_MESSAGE: "Пожалуйста, дождитесь полной загрузки обновлений.",
    WEB2_MOBILE_UPDATE_APK_TITLE: "Обновить приложение?",
    WEB2_MOBILE_UPDATE_APK_MESSAGE: "В нашем приложении появились обновления. Установите их, чтобы продолжить игру.",
    WEB2_MOBILE_UPDATE_APK_BTN_UPDATE: "Обновить",
    WEB2_MOBILE_UPDATE_APK_BTN_POSTPONE: "Не сейчас",
    WEB2_IP_BLOCKED_ERROR: "Сервис недоступен в вашей стране.",
    WEB2_UPDATE_IOS_TITLE: "Обновите iOS",
    WEB2_UPDATE_IOS_MESSAGE: "Текущая версия iOS не поддерживается. Пожалуйста, обновите ваше устройство, чтобы получить доступ ко всем функциям сайта.",
    WEB2_UPDATE_IOS_BUTTON: "Обновить",
    WEB2_UPDATE_ANDROID_TITLE: "Обновите Android",
    WEB2_UPDATE_ANDROID_MESSAGE: "Текущая версия Android не поддерживается. Пожалуйста, обновите ваше устройство, чтобы получить доступ ко всем функциям сайта.",
    WEB2_UPDATE_ANDROID_BUTTON: "Обновить"
  }),
  [/* @__PURE__ */ (() => Locale.PT_PT)()]: /* @__PURE__ */ Object.freeze({
    WEB2_ERROR: "Erro",
    WEB2_SESSION_EXPIRED: "Sessão expirada",
    CONNECTION_ERROR: "Erro de conexão. Por favor, verifique sua conexão com a internet e tente novamente.",
    WEB2_TECHNICAL_ERROR: "Ocorreu um erro técnico. Por favor, recarregue a página ou tenta novamente mais tarde.",
    JSP_PCL_FBOT_CLOSE: "Encerrar",
    JS_CAPTION_ATTENTION: "Erro",
    WEB2_DO_LOGIN: "Entrar",
    JSPNAV_USTAT_REGISTER: "Registar",
    WEB2_ERROR_503_TITLE_V2: "O NOSSO SITE ESTÁ TEMPORARIAMENTE INDISPONÍVEL",
    WEB2_ERROR_503_TEXT1_V2: "Neste momento, estamos a realizar uma manutenção agendada do sistema.",
    WEB2_ERROR_503_TEXT2_V2: "Pedimos desculpa pela inconveniência.",
    WEB2_ERROR_503_TEXT3_V2: "Voltamos brevemente!",
    WEB2_ERROR_503_BTN_UPDATE_V2: "Recarregar",
    WEB2_HELP_EMAIL: /* @__PURE__ */ (() => process.env.VUE_APP_INFO_EMAIL)(),
    WEB2_ERROR_BTN_GO_HOME: "Voltar à página principal",
    WEB2_ERROR_BTN_SUPPORT: "Apoio ao Cliente",
    WEB2_ERROR_TEXT_SUPPORT: "Tem dúvidas? Envie-nos um e-mail!",
    WEB2_ERROR_404_TITLE: "Página não encontrada",
    WEB2_ERROR_404_MSG: "A página que procura já não existe.",
    WEB2_ERROR_403_TITLE: "Acesso proibido",
    WEB2_ERROR_403_MSG: "A página que está a tentar aceder é de acesso restrito. Por favor, volte para a página principal",
    WEB2_CONNECTION_ERROR_CAPTION: "Erro de conexão",
    WEB2_CONNECTION_ERROR_DESCRIPTION: "Não conseguimos conectar ao servidor. Por favor, verifique a sua conexão de internet e tente novamente",
    WEB2_CONNECTION_ERROR_BTN_RETRY: "Tente novamente",
    WEB2_MOBILE_LOADING_APK_TITLE: "Atualizar a app",
    WEB2_MOBILE_LOADING_APK_MESSAGE: "Por favor, aguarde até a atualização estar completa",
    WEB2_MOBILE_UPDATE_APK_TITLE: "Atualização de app disponível",
    WEB2_MOBILE_UPDATE_APK_MESSAGE: "Por favor, atualize a app para a versão mais recente para continuar a utilizar.",
    WEB2_MOBILE_UPDATE_APK_BTN_UPDATE: "Atualização",
    WEB2_MOBILE_UPDATE_APK_BTN_POSTPONE: "Mais tarde",
    WEB2_IP_BLOCKED_ERROR: "Serviço indisponível no seu país.",
    WEB2_UPDATE_IOS_TITLE: "Atualização de iOS necessária",
    WEB2_UPDATE_IOS_MESSAGE: "A sua versão atual de iOS não é compatível. Para ter acesso às melhorias mais recentes, atualize o seu dispositivo.",
    WEB2_UPDATE_IOS_BUTTON: "Atualize o iOS agora",
    WEB2_UPDATE_ANDROID_TITLE: "Atualização de Android necessária",
    WEB2_UPDATE_ANDROID_MESSAGE: "A sua versão atual de Android não é compatível. Para ter acesso às melhorias mais recentes, atualize o seu dispositivo.",
    WEB2_UPDATE_ANDROID_BUTTON: "Atualize o Android agora"
  }),
  [/* @__PURE__ */ (() => Locale.IT_IT)()]: /* @__PURE__ */ Object.freeze({
    WEB2_ERROR: "Errore",
    WEB2_SESSION_EXPIRED: "Sessione scaduta",
    CONNECTION_ERROR: "Errore di connessione. Per favore controlla la tua connessione internet e riprova.",
    WEB2_TECHNICAL_ERROR: "Si è verificato un errore tecnico. Per favore ricarica la pagina o riprova più tardi.",
    JSP_PCL_FBOT_CLOSE: "Chiudi",
    JS_CAPTION_ATTENTION: "Attenzione",
    WEB2_DO_LOGIN: "Accedi",
    JSPNAV_USTAT_REGISTER: "Registrati",
    WEB2_ERROR_503_TITLE_V2: "Il nostro sito web è temporaneamente non disponibile",
    WEB2_ERROR_503_TEXT1_V2: "Attualmente è in corso una manutenzione programmata del sistema.",
    WEB2_ERROR_503_TEXT2_V2: "Si prega di riprovare a breve.",
    WEB2_ERROR_503_TEXT3_V2: "Ci scusiamo per l'inconveniente.",
    WEB2_ERROR_503_BTN_UPDATE_V2: "Aggiorna",
    WEB2_HELP_EMAIL: /* @__PURE__ */ (() => process.env.VUE_APP_INFO_EMAIL)(),
    WEB2_ERROR_BTN_GO_HOME: "Torna alla pagina principale",
    WEB2_ERROR_BTN_SUPPORT: "Assistenza",
    WEB2_ERROR_TEXT_SUPPORT: "Hai domande? Siamo sempre felici di aiutarti",
    WEB2_ERROR_404_TITLE: "Pagina non trovata",
    WEB2_ERROR_404_MSG: "La pagina che stai cercando non esiste.",
    WEB2_ERROR_403_TITLE: "Accesso vietato",
    WEB2_ERROR_403_MSG: "L'accesso alla pagina a cui stai tentando di accedere è limitato. Ti invitiamo a tornare alla homepage.",
    WEB2_CONNECTION_ERROR_CAPTION: "Errore di connessione",
    WEB2_CONNECTION_ERROR_DESCRIPTION: "Siamo spiacenti, questo servizio non è al momento disponibile. Per favore riprova più tardi.",
    WEB2_CONNECTION_ERROR_BTN_RETRY: "Riprova",
    WEB2_MOBILE_LOADING_APK_TITLE: "Aggiornamento dell'app",
    WEB2_MOBILE_LOADING_APK_MESSAGE: "Per favore attendi il completamento dell'aggiornamento.",
    WEB2_MOBILE_UPDATE_APK_TITLE: "Aggiornamento dell'app disponibile",
    WEB2_MOBILE_UPDATE_APK_MESSAGE: "Per favore aggiorna l'app all'ultima versione per continuare a usarla.",
    WEB2_MOBILE_UPDATE_APK_BTN_UPDATE: "Aggiorna",
    WEB2_MOBILE_UPDATE_APK_BTN_POSTPONE: "Più tardi",
    WEB2_IP_BLOCKED_ERROR: "Questo servizio non è disponibile nel tuo Paese",
    WEB2_UPDATE_IOS_TITLE: "Aggiornamento iOS richiesto",
    WEB2_UPDATE_IOS_MESSAGE: "La versione corrente di iOS non è supportata. Per accedere agli ultimi miglioramenti, per favore aggiorna il tuo dispositivo.",
    WEB2_UPDATE_IOS_BUTTON: "Aggiorna iOS ora",
    WEB2_UPDATE_ANDROID_TITLE: "Aggiornamento Android richiesto",
    WEB2_UPDATE_ANDROID_MESSAGE: "La versione corrente del tuo Android non è supportata. Per accedere agli ultimi miglioramenti, per favore aggiorna il tuo dispositivo.",
    WEB2_UPDATE_ANDROID_BUTTON: "Aggiorna Android ora"
  })
};
const i18nBootstrapTranslations$1 = {
  DEFAULT: /* @__PURE__ */ Object.freeze({
    WEB2_ERROR: "Error",
    WEB2_SESSION_EXPIRED: "Session expired",
    CONNECTION_ERROR: "Connection error. Please check your internet connection and try again.",
    WEB2_TECHNICAL_ERROR: "A technical error has occurred. Please reload the page or try again later.",
    JSP_PCL_FBOT_CLOSE: "Close",
    JS_CAPTION_ATTENTION: "Attention",
    WEB2_DO_LOGIN: "Log In",
    JSPNAV_USTAT_REGISTER: "Sign Up",
    WEB2_ERROR_503_TITLE_V2: "Our website is temporarily unavailable",
    WEB2_ERROR_503_TEXT1_V2: "We are currently undergoing scheduled system maintenance.",
    WEB2_ERROR_503_TEXT2_V2: "Please try again soon.",
    WEB2_ERROR_503_TEXT3_V2: "We apologise for the inconvenience.",
    WEB2_ERROR_503_BTN_UPDATE_V2: "Refresh",
    WEB2_HELP_EMAIL: /* @__PURE__ */ (() => process.env.VUE_APP_INFO_EMAIL)(),
    WEB2_ERROR_BTN_GO_HOME: "Back to main page",
    WEB2_ERROR_BTN_SUPPORT: "Support",
    WEB2_ERROR_TEXT_SUPPORT: "Have questions? We are always happy to help",
    WEB2_ERROR_404_TITLE: "Page not found",
    WEB2_ERROR_404_MSG: "The page you are looking for does not exist. It might have been moved or deleted.",
    WEB2_ERROR_403_TITLE: "Access forbidden",
    WEB2_ERROR_403_MSG: "The page you're trying to access has restricted access. Please go back to the homepage.",
    WEB2_CONNECTION_ERROR_CAPTION: "Connection error",
    WEB2_CONNECTION_ERROR_DESCRIPTION: "We were unable to connect to the server. Please check your internet connection and try again.",
    WEB2_CONNECTION_ERROR_BTN_RETRY: "Retry",
    WEB2_MOBILE_LOADING_APK_TITLE: "Updating the app",
    WEB2_MOBILE_LOADING_APK_MESSAGE: "Please wait until the update is complete.",
    WEB2_MOBILE_UPDATE_APK_TITLE: "App update available",
    WEB2_MOBILE_UPDATE_APK_MESSAGE: "Please update to the latest version to continue using the app.",
    WEB2_MOBILE_UPDATE_APK_BTN_UPDATE: "Update",
    WEB2_MOBILE_UPDATE_APK_BTN_POSTPONE: "Later",
    WEB2_IP_BLOCKED_ERROR: "This service is not available in your country.",
    WEB2_UPDATE_IOS_TITLE: "iOS Update Required",
    WEB2_UPDATE_IOS_MESSAGE: "Your current iOS version is not supported. To access the latest improvements, please update your device.",
    WEB2_UPDATE_IOS_BUTTON: "Update iOS now",
    WEB2_UPDATE_ANDROID_TITLE: "Android Update Required",
    WEB2_UPDATE_ANDROID_MESSAGE: "Your current Android version is not supported. To access the latest improvements, please update your device.",
    WEB2_UPDATE_ANDROID_BUTTON: "Update Android now"
  }),
  [/* @__PURE__ */ (() => Locale.SW_TZ)()]: /* @__PURE__ */ Object.freeze({
    WEB2_ERROR: "Tahadhari",
    WEB2_SESSION_EXPIRED: "Muda umekwisha",
    CONNECTION_ERROR: "Hitilafu ya muunganisho. Tafadhali angalia muunganisho wako wa intaneti na ujaribu tena.",
    WEB2_TECHNICAL_ERROR: "Hitilafu ya kiufundi imetokea. Tafadhali pakia upya ukurasa au ujaribu tena baadaye.",
    JSP_PCL_FBOT_CLOSE: "Funga",
    JS_CAPTION_ATTENTION: "Tahadhari",
    WEB2_DO_LOGIN: "Ingia",
    JSPNAV_USTAT_REGISTER: "Jisajili",
    WEB2_ERROR_503_TITLE_V2: "Tovuti yetu haipatikani kwa sasa",
    WEB2_ERROR_503_TEXT1_V2: "Kwa sasa tupo kwenye maboresho ya kawaida ya mfumo.",
    WEB2_ERROR_503_TEXT2_V2: "Tafadhali jaribu ndani ya muda mfupi",
    WEB2_ERROR_503_TEXT3_V2: "Tunaomba radhi kwa usumbufu.",
    WEB2_ERROR_503_BTN_UPDATE_V2: "Rejesha",
    WEB2_HELP_EMAIL: /* @__PURE__ */ (() => process.env.VUE_APP_INFO_EMAIL)(),
    WEB2_ERROR_BTN_GO_HOME: "Rudi kwenye ukurasa mkuu",
    WEB2_ERROR_BTN_SUPPORT: "Msaada",
    WEB2_ERROR_TEXT_SUPPORT: "Una swali? Tutafurahi kukusaidia",
    WEB2_ERROR_404_TITLE: "Ukurasa haupatikani",
    WEB2_ERROR_404_MSG: "Ukurasa unaoangalia haupo",
    WEB2_ERROR_403_TITLE: "Ufikiaji umezuiwa",
    WEB2_ERROR_403_MSG: "Ukurasa unaojaribu kuufikia umezuiwa. Tafadhali rudi nyuma kwenye ukurasa wa mwanzo",
    WEB2_CONNECTION_ERROR_CAPTION: "Hitilafu kwenye kuunganishwa ",
    WEB2_CONNECTION_ERROR_DESCRIPTION: "Tumeshindwa kukuunganisha. Tafadhali angalia mtandao wako na ujaribu tena.",
    WEB2_CONNECTION_ERROR_BTN_RETRY: "Jaribu upya",
    WEB2_MOBILE_LOADING_APK_TITLE: "Boresha app",
    WEB2_MOBILE_LOADING_APK_MESSAGE: "Tafadhali subiri hadi maboresho yatakapokamilika",
    WEB2_MOBILE_UPDATE_APK_TITLE: "Marekebisho yanapatikana",
    WEB2_MOBILE_UPDATE_APK_MESSAGE: "Tafadhali pata toleo jipya zaidi ili kuendelea kutumia app. ",
    WEB2_MOBILE_UPDATE_APK_BTN_UPDATE: "Boresha",
    WEB2_MOBILE_UPDATE_APK_BTN_POSTPONE: "Baadaye",
    WEB2_IP_BLOCKED_ERROR: "Huduma hii haipatikani kwenye nchi yako",
    WEB2_UPDATE_IOS_TITLE: "Maboresho ya iOS yanahitajika",
    WEB2_UPDATE_IOS_MESSAGE: "Toleo lako la sasa la iOS halitumiki. Ili kufikia maboresho ya hivi punde, tafadhali rekebisha kifaa chako.",
    WEB2_UPDATE_IOS_BUTTON: "Boresha iOS sasa",
    WEB2_UPDATE_ANDROID_TITLE: "Maboresho ya Android yanahitajika",
    WEB2_UPDATE_ANDROID_MESSAGE: "Toleo lako la sasa la Android halitumiki. Ili kufikia maboresho ya hivi punde, tafadhali rekebisha kifaa chako.",
    WEB2_UPDATE_ANDROID_BUTTON: "Boresha Android sasa"
  })
};
const ruTranslations$1 = /* @__PURE__ */ Object.freeze({
  WEB2_ERROR: "Ошибка",
  WEB2_SESSION_EXPIRED: "Сессия истекла",
  CONNECTION_ERROR: "Ошибка соединения. Пожалуйста, проверьте соединение с интернетом и повторите попытку.",
  WEB2_TECHNICAL_ERROR: "Произошла техническая ошибка. Обновите страницу или повторите попытку позже.",
  JSP_PCL_FBOT_CLOSE: "Закрыть",
  JS_CAPTION_ATTENTION: "Внимание",
  WEB2_DO_LOGIN: "Войти",
  JSPNAV_USTAT_REGISTER: "Регистрация",
  WEB2_ERROR_503_TITLE_V2: "Сайт Твин временно недоступен",
  WEB2_ERROR_503_TEXT1_V2: "Проводятся технические работы по обновлению сайта.",
  WEB2_ERROR_503_TEXT2_V2: "Совсем скоро мы снова будем с вами.",
  WEB2_ERROR_503_TEXT3_V2: "Приносим извинения за неудобства.",
  WEB2_ERROR_503_BTN_UPDATE_V2: "Обновить",
  WEB2_HELP_EMAIL: /* @__PURE__ */ (() => process.env.VUE_APP_INFO_EMAIL_RU)(),
  WEB2_ERROR_BTN_GO_HOME: "На главную",
  WEB2_ERROR_BTN_SUPPORT: "Служба поддержки",
  WEB2_ERROR_TEXT_SUPPORT: "Возникли трудности?<br> Мы всегда рады помочь",
  WEB2_ERROR_404_TITLE: "Страница не найдена",
  WEB2_ERROR_404_MSG: "Возможно, ее не существует или она была удалена.",
  WEB2_ERROR_403_TITLE: "Доступ запрещен",
  WEB2_ERROR_403_MSG: "Доступ к этой странице ограничен. Пожалуйста, вернитесь на Главную.",
  WEB2_CONNECTION_ERROR_CAPTION: "Ошибка соединения",
  WEB2_CONNECTION_ERROR_DESCRIPTION: "Произошла ошибка при подключении к серверу. Проверьте ваше интернет-соединение и повторите попытку.",
  WEB2_CONNECTION_ERROR_BTN_RETRY: "Повторить",
  WEB2_MOBILE_LOADING_APK_TITLE: "Идет обновление",
  WEB2_MOBILE_LOADING_APK_MESSAGE: "Пожалуйста, дождитесь полной загрузки обновлений.",
  WEB2_MOBILE_UPDATE_APK_TITLE: "Обновить приложение?",
  WEB2_MOBILE_UPDATE_APK_MESSAGE: "В нашем приложении появились обновления. Установите их, чтобы продолжить игру.",
  WEB2_MOBILE_UPDATE_APK_BTN_UPDATE: "Обновить",
  WEB2_MOBILE_UPDATE_APK_BTN_POSTPONE: "Не сейчас",
  WEB2_IP_BLOCKED_ERROR: "Сервис недоступен в вашей стране.",
  WEB2_UPDATE_IOS_TITLE: "Обновите iOS",
  WEB2_UPDATE_IOS_MESSAGE: "Текущая версия iOS не поддерживается. Пожалуйста, обновите ваше устройство, чтобы получить доступ ко всем функциям сайта.",
  WEB2_UPDATE_IOS_BUTTON: "Обновить",
  WEB2_UPDATE_ANDROID_TITLE: "Обновите Android",
  WEB2_UPDATE_ANDROID_MESSAGE: "Текущая версия Android не поддерживается. Пожалуйста, обновите ваше устройство, чтобы получить доступ ко всем функциям сайта.",
  WEB2_UPDATE_ANDROID_BUTTON: "Обновить"
});
const ruTranslations = /* @__PURE__ */ Object.freeze({
  WEB2_ERROR: "Ошибка",
  WEB2_SESSION_EXPIRED: "Сессия истекла",
  CONNECTION_ERROR: "Ошибка соединения. Пожалуйста, проверьте соединение с интернетом и повторите попытку.",
  WEB2_TECHNICAL_ERROR: "Произошла техническая ошибка. Обновите страницу или повторите попытку позже.",
  JSP_PCL_FBOT_CLOSE: "Закрыть",
  JS_CAPTION_ATTENTION: "Внимание",
  WEB2_DO_LOGIN: "Войти",
  JSPNAV_USTAT_REGISTER: "Регистрация",
  WEB2_ERROR_503_TITLE_V2: "Сайт БК Леон временно недоступен",
  WEB2_ERROR_503_TEXT1_V2: "Проводятся технические работы по обновлению сайта.",
  WEB2_ERROR_503_TEXT2_V2: "Совсем скоро мы снова будем с вами.",
  WEB2_ERROR_503_TEXT3_V2: "Приносим извинения за неудобства.",
  WEB2_ERROR_503_BTN_UPDATE_V2: "Обновить",
  WEB2_HELP_EMAIL: /* @__PURE__ */ (() => process.env.VUE_APP_INFO_EMAIL_RU)(),
  WEB2_ERROR_BTN_GO_HOME: "На главную",
  WEB2_ERROR_BTN_SUPPORT: "Служба поддержки",
  WEB2_ERROR_TEXT_SUPPORT: "Возникли трудности?<br> Мы всегда рады помочь",
  WEB2_ERROR_404_TITLE: "Страница не найдена",
  WEB2_ERROR_404_MSG: "Возможно, ее не существует или она была удалена.",
  WEB2_ERROR_403_TITLE: "Доступ запрещен",
  WEB2_ERROR_403_MSG: "Доступ к этой странице ограничен. Пожалуйста, вернитесь на Главную.",
  WEB2_CONNECTION_ERROR_CAPTION: "Ошибка соединения",
  WEB2_CONNECTION_ERROR_DESCRIPTION: "Произошла ошибка при подключении к серверу. Проверьте ваше интернет-соединение и повторите попытку.",
  WEB2_CONNECTION_ERROR_BTN_RETRY: "Повторить",
  WEB2_MOBILE_LOADING_APK_TITLE: "Идет обновление",
  WEB2_MOBILE_LOADING_APK_MESSAGE: "Пожалуйста, дождитесь полной загрузки обновлений.",
  WEB2_MOBILE_UPDATE_APK_TITLE: "Обновить приложение?",
  WEB2_MOBILE_UPDATE_APK_MESSAGE: "В нашем приложении появились обновления. Установите их, чтобы продолжить игру.",
  WEB2_MOBILE_UPDATE_APK_BTN_UPDATE: "Обновить",
  WEB2_MOBILE_UPDATE_APK_BTN_POSTPONE: "Не сейчас",
  WEB2_IP_BLOCKED_ERROR: "Сервис недоступен в вашей стране.",
  WEB2_UPDATE_IOS_TITLE: "Обновите iOS",
  WEB2_UPDATE_IOS_MESSAGE: "Текущая версия iOS не поддерживается. Пожалуйста, обновите ваше устройство, чтобы получить доступ ко всем функциям сайта.",
  WEB2_UPDATE_IOS_BUTTON: "Обновить",
  WEB2_UPDATE_ANDROID_TITLE: "Обновите Android",
  WEB2_UPDATE_ANDROID_MESSAGE: "Текущая версия Android не поддерживается. Пожалуйста, обновите ваше устройство, чтобы получить доступ ко всем функциям сайта.",
  WEB2_UPDATE_ANDROID_BUTTON: "Обновить"
});
let i18nBootstrapTranslations;
if (/* @__PURE__ */ (() => process.env.VUE_APP_PRODUCT_LEONRU)()) {
  i18nBootstrapTranslations = {
    DEFAULT: ruTranslations$2
  };
} else if (/* @__PURE__ */ (() => process.env.VUE_APP_PRODUCT_SLOTT)()) {
  i18nBootstrapTranslations = /* @__PURE__ */ (() => ({
    ...i18nBootstrapTranslations$2
  }))();
} else if (/* @__PURE__ */ (() => process.env.VUE_APP_PRODUCT_SUNNY)()) {
  i18nBootstrapTranslations = /* @__PURE__ */ (() => ({
    ...i18nBootstrapTranslations$1
  }))();
} else {
  i18nBootstrapTranslations = {
    DEFAULT: /* @__PURE__ */ Object.freeze({
      WEB2_ERROR: "Error",
      WEB2_SESSION_EXPIRED: "Session expired",
      CONNECTION_ERROR: "Connection error. Please check your internet connection and try again.",
      WEB2_TECHNICAL_ERROR: "A technical error has occurred. Please reload the page or try again later.",
      JSP_PCL_FBOT_CLOSE: "Close",
      JS_CAPTION_ATTENTION: "Attention",
      WEB2_DO_LOGIN: "Log In",
      JSPNAV_USTAT_REGISTER: "Sign Up",
      WEB2_ERROR_503_TITLE_V2: "Our website is temporarily unavailable",
      WEB2_ERROR_503_TEXT1_V2: "We are currently undergoing scheduled system maintenance.",
      WEB2_ERROR_503_TEXT2_V2: "Please try again soon.",
      WEB2_ERROR_503_TEXT3_V2: "We apologise for the inconvenience.",
      WEB2_ERROR_503_BTN_UPDATE_V2: "Refresh",
      WEB2_HELP_EMAIL: /* @__PURE__ */ (() => process.env.VUE_APP_INFO_EMAIL)(),
      WEB2_ERROR_BTN_GO_HOME: "Back to main page",
      WEB2_ERROR_BTN_SUPPORT: "Support",
      WEB2_ERROR_TEXT_SUPPORT: "Have questions? We are always happy to help",
      WEB2_ERROR_404_TITLE: "Page not found",
      WEB2_ERROR_404_MSG: "The page you are looking for does not exist. It might have been moved or deleted.",
      WEB2_ERROR_403_TITLE: "Access forbidden",
      WEB2_ERROR_403_MSG: "The page you're trying to access has restricted access. Please go back to the homepage.",
      WEB2_CONNECTION_ERROR_CAPTION: "Connection error",
      WEB2_CONNECTION_ERROR_DESCRIPTION: "We were unable to connect to the server. Please check your internet connection and try again.",
      WEB2_CONNECTION_ERROR_BTN_RETRY: "Retry",
      WEB2_MOBILE_LOADING_APK_TITLE: "Updating the app",
      WEB2_MOBILE_LOADING_APK_MESSAGE: "Please wait until the update is complete.",
      WEB2_MOBILE_UPDATE_APK_TITLE: "App update available",
      WEB2_MOBILE_UPDATE_APK_MESSAGE: "Please update to the latest version to continue using the app.",
      WEB2_MOBILE_UPDATE_APK_BTN_UPDATE: "Update",
      WEB2_MOBILE_UPDATE_APK_BTN_POSTPONE: "Later",
      WEB2_IP_BLOCKED_ERROR: "This service is not available in your country.",
      WEB2_UPDATE_IOS_TITLE: "iOS Update Required",
      WEB2_UPDATE_IOS_MESSAGE: "Your current iOS version is not supported. To access the latest improvements, please update your device.",
      WEB2_UPDATE_IOS_BUTTON: "Update iOS now",
      WEB2_UPDATE_ANDROID_TITLE: "Android Update Required",
      WEB2_UPDATE_ANDROID_MESSAGE: "Your current Android version is not supported. To access the latest improvements, please update your device.",
      WEB2_UPDATE_ANDROID_BUTTON: "Update Android now"
    }),
    [/* @__PURE__ */ (() => Locale.DE_DE)()]: deTranslations,
    [/* @__PURE__ */ (() => Locale.PT_PT)()]: /* @__PURE__ */ Object.freeze({
      WEB2_ERROR: "Erro",
      WEB2_SESSION_EXPIRED: "Sessão expirada",
      CONNECTION_ERROR: "Erro na ligação. Por favor verifique se está ligado à internet e tente de novo.",
      WEB2_TECHNICAL_ERROR: "Ocorreu um erro técnico. Por favor recarregue a página ou tenta novamente mais tarde.",
      JSP_PCL_FBOT_CLOSE: "Encerrar",
      JS_CAPTION_ATTENTION: "Atenção",
      WEB2_DO_LOGIN: "Aceder",
      JSPNAV_USTAT_REGISTER: "Registo",
      WEB2_ERROR_503_TITLE_V2: "O nosso website está temporariamente indisponível",
      WEB2_ERROR_503_TEXT1_V2: "Estamos neste momento a realizar uma manutenção agendada do sistema.",
      WEB2_ERROR_503_TEXT2_V2: "Por favor volte a tentar brevemente.",
      WEB2_ERROR_503_TEXT3_V2: "Pedimos desculpa pela inconveniência",
      WEB2_ERROR_503_BTN_UPDATE_V2: "Recarregar",
      WEB2_HELP_EMAIL: /* @__PURE__ */ (() => process.env.VUE_APP_INFO_EMAIL)(),
      WEB2_ERROR_BTN_GO_HOME: "Voltar à página principal",
      WEB2_ERROR_BTN_SUPPORT: "Suporte",
      WEB2_ERROR_TEXT_SUPPORT: "Tem dúvidas? Estamos sempre dispostos a ajuda-lo",
      WEB2_ERROR_404_TITLE: "Página não encontrada",
      WEB2_ERROR_404_MSG: "A página que está à procura não existe. Pode ter sido removida ou apagada.",
      WEB2_ERROR_403_TITLE: "Acesso proibido",
      WEB2_ERROR_403_MSG: "A página que está a tentar aceder é de acesso restrito. Pro favor volte para a página principal",
      WEB2_CONNECTION_ERROR_CAPTION: "Erro de conexão",
      WEB2_CONNECTION_ERROR_DESCRIPTION: "Não conseguimos conetar ao servidor. Por favor verifique a sua conexao de internet e tente novamente",
      WEB2_CONNECTION_ERROR_BTN_RETRY: "Tente novamente",
      WEB2_MOBILE_LOADING_APK_TITLE: "Atualizar a app",
      WEB2_MOBILE_LOADING_APK_MESSAGE: "Por favor, aguarde até a atualização estar completa",
      WEB2_MOBILE_UPDATE_APK_TITLE: "Atualização de app disponível",
      WEB2_MOBILE_UPDATE_APK_MESSAGE: "Por favor atualize a app para a versão mais recente para continuar a usar.",
      WEB2_MOBILE_UPDATE_APK_BTN_UPDATE: "Atualização",
      WEB2_MOBILE_UPDATE_APK_BTN_POSTPONE: "Mais tarde",
      WEB2_IP_BLOCKED_ERROR: "Serviço indisponível no seu país.",
      WEB2_UPDATE_IOS_TITLE: "Atualização de iOS necessária",
      WEB2_UPDATE_IOS_MESSAGE: "A sua versão atual de iOS não é compatível. Para ter acesso às melhorias mais recentes, atualize o seu dispositivo.",
      WEB2_UPDATE_IOS_BUTTON: "Atualize o iOS agora",
      WEB2_UPDATE_ANDROID_TITLE: "Atualização de Android necessária",
      WEB2_UPDATE_ANDROID_MESSAGE: "A sua versão atual de Android não é compatível. Para ter acesso às melhorias mais recentes, atualize o seu dispositivo.",
      WEB2_UPDATE_ANDROID_BUTTON: "Atualize o Android agora"
    }),
    [/* @__PURE__ */ (() => Locale.HI_IN)()]: /* @__PURE__ */ Object.freeze({
      WEB2_ERROR: "त्रुटि",
      WEB2_SESSION_EXPIRED: "सेशन एक्सपायर",
      CONNECTION_ERROR: "संपर्क त्रुटि. अपने इंटरनेट कनेक्शन की जाँच करें और पुन: प्रयास करें।",
      WEB2_TECHNICAL_ERROR: "एक तकनीकी त्रुटि हुई है। कृपया पृष्ठ को पुनः लोड करें या बाद में पुन: प्रयास करें।",
      JSP_PCL_FBOT_CLOSE: "बंद करे",
      JS_CAPTION_ATTENTION: "ध्यान दें",
      WEB2_DO_LOGIN: "लॉग इन करें",
      JSPNAV_USTAT_REGISTER: "साइन अप करें",
      WEB2_ERROR_503_TITLE_V2: "हमारी वेबसाइट अस्थायी रूप से अनुपलब्ध है",
      WEB2_ERROR_503_TEXT1_V2: "अभी हम नियमित सिस्टम रखरखाव कर रहे हैं।",
      WEB2_ERROR_503_TEXT2_V2: "कृपया शीघ्र ही पुन: प्रयास करें।",
      WEB2_ERROR_503_TEXT3_V2: "असुविधा के लिए हमें खेद है।",
      WEB2_ERROR_503_BTN_UPDATE_V2: "रिफ्रेश",
      WEB2_HELP_EMAIL: /* @__PURE__ */ (() => process.env.VUE_APP_INFO_EMAIL)(),
      WEB2_ERROR_BTN_GO_HOME: "मुख्य पृष्ठ पर वापस जाएं",
      WEB2_ERROR_BTN_SUPPORT: "सहायता",
      WEB2_ERROR_TEXT_SUPPORT: "कोई सवाल? हमें हमेशा मदद करके खुशी होती हैं",
      WEB2_ERROR_404_TITLE: "पृष्ठ नहीं मिला",
      WEB2_ERROR_404_MSG: "आप जिस पेज की तलाश कर रहे हैं वह मौजूद नहीं है।",
      WEB2_ERROR_403_TITLE: "प्रवेश निषिद्ध",
      WEB2_ERROR_403_MSG: "आप जिस पृष्ठ तक पहुँचने का प्रयास कर रहे हैं, उसकी पहुँच प्रतिबंधित है। कृपया होमपेज पर वापस जाएं।",
      WEB2_CONNECTION_ERROR_CAPTION: "संपर्क त्रुटि",
      WEB2_CONNECTION_ERROR_DESCRIPTION: "हम सर्वर से कनेक्ट करने में असमर्थ थे. अपने इंटरनेट कनेक्शन की जाँच करें और पुन: प्रयास करें।",
      WEB2_CONNECTION_ERROR_BTN_RETRY: "पुन: प्रयास करें",
      WEB2_MOBILE_LOADING_APK_TITLE: "ऐप अपडेट हो रहा है",
      WEB2_MOBILE_LOADING_APK_MESSAGE: "कृपया अपडेट पूरा होने तक प्रतीक्षा करें",
      WEB2_MOBILE_UPDATE_APK_TITLE: "ऐप अपडेट उपलब्ध",
      WEB2_MOBILE_UPDATE_APK_MESSAGE: "ऐप का उपयोग जारी रखने के लिए कृपया नए वर्ज़न में अपडेट करें।",
      WEB2_MOBILE_UPDATE_APK_BTN_UPDATE: "अपडेट करें",
      WEB2_MOBILE_UPDATE_APK_BTN_POSTPONE: "बाद में",
      WEB2_IP_BLOCKED_ERROR: "This service is not available in your country.",
      WEB2_UPDATE_IOS_TITLE: "iOS Update Required",
      WEB2_UPDATE_IOS_MESSAGE: "Your current iOS version is not supported. To access the latest improvements, please update your device.",
      WEB2_UPDATE_IOS_BUTTON: "Update iOS now",
      WEB2_UPDATE_ANDROID_TITLE: "Android Update Required",
      WEB2_UPDATE_ANDROID_MESSAGE: "Your current Android version is not supported. To access the latest improvements, please update your device.",
      WEB2_UPDATE_ANDROID_BUTTON: "Update Android now"
    }),
    [/* @__PURE__ */ (() => Locale.FR_CA)()]: /* @__PURE__ */ Object.freeze({
      WEB2_ERROR: "Erreur",
      WEB2_SESSION_EXPIRED: "La session a expiré",
      CONNECTION_ERROR: "Erreur de connexion. S'il vous plaît, vérifiez votre connexion à internet et réessayez.",
      WEB2_TECHNICAL_ERROR: "Une erreur technique s'est produite. Veuillez recharger la page ou réessayer plus tard.",
      JSP_PCL_FBOT_CLOSE: "Fermer",
      JS_CAPTION_ATTENTION: "Attention",
      WEB2_DO_LOGIN: "Se connecter",
      JSPNAV_USTAT_REGISTER: "S'inscrire",
      WEB2_ERROR_503_TITLE_V2: "Notre site est momentanément indisponible",
      WEB2_ERROR_503_TEXT1_V2: "Nous procédons actuellement à une maintenance planifiée du système.",
      WEB2_ERROR_503_TEXT2_V2: "Veuillez réessayer bientôt.",
      WEB2_ERROR_503_TEXT3_V2: "Nous nous excusons pour la gêne occasionnée.",
      WEB2_ERROR_503_BTN_UPDATE_V2: "Rafraîchir",
      WEB2_HELP_EMAIL: /* @__PURE__ */ (() => process.env.VUE_APP_INFO_EMAIL)(),
      WEB2_ERROR_BTN_GO_HOME: "Retour à la page d'accueil",
      WEB2_ERROR_BTN_SUPPORT: "Support",
      WEB2_ERROR_TEXT_SUPPORT: "Des questions? Nous sommes toujours heureux d'aider",
      WEB2_ERROR_404_TITLE: "Page non trouvée",
      WEB2_ERROR_404_MSG: "La page que vous recherchez n'existe pas. Elle a peut-être été déplacée ou supprimée.",
      WEB2_ERROR_403_TITLE: "Accès interdit",
      WEB2_ERROR_403_MSG: "La page à laquelle vous essayez d'accéder a un accès restreint. Veuillez revenir à la page d'accueil.",
      WEB2_CONNECTION_ERROR_CAPTION: "Erreur de connexion",
      WEB2_CONNECTION_ERROR_DESCRIPTION: "Nous n'avons pas pu nous connecter au serveur. S'il vous plaît, vérifiez votre connexion à internet et réessayez.",
      WEB2_CONNECTION_ERROR_BTN_RETRY: "Réessayer",
      WEB2_MOBILE_LOADING_APK_TITLE: "Mise à jour de l'application",
      WEB2_MOBILE_LOADING_APK_MESSAGE: "Veuillez patienter jusqu'à ce que la mise à jour soit terminée.",
      WEB2_MOBILE_UPDATE_APK_TITLE: "Mise à jour de l'application disponible",
      WEB2_MOBILE_UPDATE_APK_MESSAGE: "Veuillez télécharger la dernière version pour continuer à utiliser l'application.",
      WEB2_MOBILE_UPDATE_APK_BTN_UPDATE: "Mettre à jour",
      WEB2_MOBILE_UPDATE_APK_BTN_POSTPONE: "Plus tard",
      WEB2_IP_BLOCKED_ERROR: "Ce service n'est pas disponible dans votre pays.",
      WEB2_UPDATE_IOS_TITLE: "Mise à jour iOS requise",
      WEB2_UPDATE_IOS_MESSAGE: "Votre version iOS actuelle n'est pas prise en charge. Pour accéder aux dernières améliorations, veuillez mettre à jour votre appareil.",
      WEB2_UPDATE_IOS_BUTTON: "Mettre à jour iOS maintenant",
      WEB2_UPDATE_ANDROID_TITLE: "Mise à jour Android requise",
      WEB2_UPDATE_ANDROID_MESSAGE: "Votre version actuelle d'Android n'est pas prise en charge. Pour accéder aux dernières améliorations, veuillez mettre à jour votre appareil.",
      WEB2_UPDATE_ANDROID_BUTTON: "Mettre à jour Android maintenant"
    }),
    [/* @__PURE__ */ (() => Locale.UZ_UZ)()]: /* @__PURE__ */ Object.freeze({
      WEB2_ERROR: "Xato",
      WEB2_SESSION_EXPIRED: "Sessiya muddati tugadi",
      CONNECTION_ERROR: "Serverga ulanishda xatolik yuz berdi. Internetga ulanishni tekshiring va qayta urinib ko'ring.",
      WEB2_TECHNICAL_ERROR: "Texnik xatolik yuz berdi. Sahifani yangilang yoki keyinroq qayta urinib ko‘ring.",
      JSP_PCL_FBOT_CLOSE: "Yopish",
      JS_CAPTION_ATTENTION: "Diqqat",
      WEB2_DO_LOGIN: "Kirish",
      JSPNAV_USTAT_REGISTER: "Ro'yxatdan o'tish",
      WEB2_ERROR_503_TITLE_V2: "Leon veb-sayti vaqtincha ishlamaydi",
      WEB2_ERROR_503_TEXT1_V2: "Saytni yangilash bo'yicha texnik ishlar olib borilmoqda.",
      WEB2_ERROR_503_TEXT2_V2: "Tez orada yana siz bilan bo'lamiz.",
      WEB2_ERROR_503_TEXT3_V2: "Noqulaylik uchun uzr so'raymiz.",
      WEB2_ERROR_503_BTN_UPDATE_V2: "Yangilash",
      WEB2_HELP_EMAIL: /* @__PURE__ */ (() => process.env.VUE_APP_INFO_EMAIL)(),
      WEB2_ERROR_BTN_GO_HOME: "Asosiyga",
      WEB2_ERROR_BTN_SUPPORT: "Qo'llab-quvvatlash xizmati",
      WEB2_ERROR_TEXT_SUPPORT: "Qiyinchiliklar tug'uldimi? Biz har doim yordam berishdan xursandmiz",
      WEB2_ERROR_404_TITLE: "Sahifa topilmadi",
      WEB2_ERROR_404_MSG: "Sahifa yo'q yoki o'chirilgan bo'lishi mumkin.",
      WEB2_ERROR_403_TITLE: "Kirish taqiqlangan",
      WEB2_ERROR_403_MSG: "Bu sahifaga kirish cheklangan. Iltimos, asosiy sahifaga qayting.",
      WEB2_CONNECTION_ERROR_CAPTION: "Ulanish xatosi",
      WEB2_CONNECTION_ERROR_DESCRIPTION: "Serverga ulanishda xatolik yuz berdi. Internetga ulanishni tekshiring va qayta urinib ko'ring.",
      WEB2_CONNECTION_ERROR_BTN_RETRY: "Takrorlash",
      WEB2_MOBILE_LOADING_APK_TITLE: "Yangilanmoqda",
      WEB2_MOBILE_LOADING_APK_MESSAGE: "Iltimos, yangilanishlar to'liq yuklanishini kuting.",
      WEB2_MOBILE_UPDATE_APK_TITLE: "Ilovani yangiladizmi?",
      WEB2_MOBILE_UPDATE_APK_MESSAGE: "Bizning ilovamiz yangilandi. O'ynashni davom ettirish uchun ularni yuklang.",
      WEB2_MOBILE_UPDATE_APK_BTN_UPDATE: "Yangilash",
      WEB2_MOBILE_UPDATE_APK_BTN_POSTPONE: "Hozir emas",
      WEB2_IP_BLOCKED_ERROR: "Mamlakatingizda ushbu xizmatdan foydalanish cheklangan.",
      WEB2_UPDATE_IOS_TITLE: "iOS Update Required",
      WEB2_UPDATE_IOS_MESSAGE: "Your current iOS version is not supported. To access the latest improvements, please update your device.",
      WEB2_UPDATE_IOS_BUTTON: "Update iOS now",
      WEB2_UPDATE_ANDROID_TITLE: "Android Update Required",
      WEB2_UPDATE_ANDROID_MESSAGE: "Your current Android version is not supported. To access the latest improvements, please update your device.",
      WEB2_UPDATE_ANDROID_BUTTON: "Update Android now"
    }),
    [/* @__PURE__ */ (() => Locale.UC_UZ)()]: /* @__PURE__ */ Object.freeze({
      WEB2_ERROR: "Хато",
      WEB2_SESSION_EXPIRED: "Cеccия муддати тугади",
      CONNECTION_ERROR: "Cерверга уланишда хатолик йуз берди. Интернетга уланишни текшириш ва қайта уриниб кўринг.",
      WEB2_TECHNICAL_ERROR: "Техник хатолик йуз берди. Cаҳифани янгиланг ёки кейинроқ қайта уриниб кўринг.",
      JSP_PCL_FBOT_CLOSE: "Ёпиш",
      JS_CAPTION_ATTENTION: "Диққат",
      WEB2_DO_LOGIN: "Кириш",
      JSPNAV_USTAT_REGISTER: "Рўйхатдан ўтиш",
      WEB2_ERROR_503_TITLE_V2: "Леон веб-cайти вақтинча ишламайди",
      WEB2_ERROR_503_TEXT1_V2: "Cайтни янгилаш бўйича техник ишлар олиб борилмоқда.",
      WEB2_ERROR_503_TEXT2_V2: "Тез орада яна cиз билан бўламиз.",
      WEB2_ERROR_503_TEXT3_V2: "Ноқулайлик ушун узр cўраймиз.",
      WEB2_ERROR_503_BTN_UPDATE_V2: "Янгилаш",
      WEB2_HELP_EMAIL: /* @__PURE__ */ (() => process.env.VUE_APP_INFO_EMAIL)(),
      WEB2_ERROR_BTN_GO_HOME: "Аcоcийга",
      WEB2_ERROR_BTN_SUPPORT: "Қўллаб-қувватлаш хизмати",
      WEB2_ERROR_TEXT_SUPPORT: "Қийинчиликлар туғулдими? Биз ҳар доим ёрдам беришдан хурcандмиз",
      WEB2_ERROR_404_TITLE: "Cаҳифа топилмади",
      WEB2_ERROR_404_MSG: "Cаҳифа йуқ ёки ўчирилган бўлиши мумкин.",
      WEB2_ERROR_403_TITLE: "Кириш тақиқланган",
      WEB2_ERROR_403_MSG: "Бу cаҳифага кириш чекланган. Илтимоc, аcоcий cаҳифага қайтинг.",
      WEB2_CONNECTION_ERROR_CAPTION: "Уланиш хатоcи",
      WEB2_CONNECTION_ERROR_DESCRIPTION: "Cерверга уланишда хатолик йуз берди. Интернетга уланишни текшириш ва қайта уриниб кўринг.",
      WEB2_CONNECTION_ERROR_BTN_RETRY: "Такрорлаш",
      WEB2_MOBILE_LOADING_APK_TITLE: "Янгиланмоқда",
      WEB2_MOBILE_LOADING_APK_MESSAGE: "Илтимоc, янгиланишлар тўлиқ йукланишини кутинг.",
      WEB2_MOBILE_UPDATE_APK_TITLE: "Иловани янгиладизми?",
      WEB2_MOBILE_UPDATE_APK_MESSAGE: "Бизнинг иловамиз янгиланди. Ўйначни давом эттириш учун уларни йукланг.",
      WEB2_MOBILE_UPDATE_APK_BTN_UPDATE: "Янгилаш",
      WEB2_MOBILE_UPDATE_APK_BTN_POSTPONE: "Ҳозир эмаc",
      WEB2_IP_BLOCKED_ERROR: "Мамлакатингизда ушбу хизматдан фойдаланиш чекланган.",
      WEB2_UPDATE_IOS_TITLE: "iOS Update Required",
      WEB2_UPDATE_IOS_MESSAGE: "Your current iOS version is not supported. To access the latest improvements, please update your device.",
      WEB2_UPDATE_IOS_BUTTON: "Update iOS now",
      WEB2_UPDATE_ANDROID_TITLE: "Android Update Required",
      WEB2_UPDATE_ANDROID_MESSAGE: "Your current Android version is not supported. To access the latest improvements, please update your device.",
      WEB2_UPDATE_ANDROID_BUTTON: "Update Android now"
    }),
    [/* @__PURE__ */ (() => Locale.AZ_AZ)()]: /* @__PURE__ */ Object.freeze({
      WEB2_ERROR: "Xəta",
      WEB2_SESSION_EXPIRED: "Sessiyanın vaxtı bitib",
      CONNECTION_ERROR: "Serverə qoşularkən xəta baş verdi. İnternet şəbəkənizi yoxlayın və yenidən cəhd edin.",
      WEB2_TECHNICAL_ERROR: "Texniki xəta baş verdi. Səhifəni yeniləyin və daha sonra yenidən cəhd edin.",
      JSP_PCL_FBOT_CLOSE: "Bağla",
      JS_CAPTION_ATTENTION: "Diqqət",
      WEB2_DO_LOGIN: "Daxil olun",
      JSPNAV_USTAT_REGISTER: "Qeydiyyat",
      WEB2_ERROR_503_TITLE_V2: "Leon Bukmeyker Kontorunun saytına müvəqqəti olaraq giriş məhdudiyyəti var",
      WEB2_ERROR_503_TEXT1_V2: "Saytın yenilənməsi üçün texniki işlər aparılır.",
      WEB2_ERROR_503_TEXT2_V2: "Tezliklə biz sizinlə yenidən görüşəcəyik.",
      WEB2_ERROR_503_TEXT3_V2: "Müvəqqəti narahatçılığa görə üzr istəyirik.",
      WEB2_ERROR_503_BTN_UPDATE_V2: "Yeniləyin",
      WEB2_HELP_EMAIL: /* @__PURE__ */ (() => process.env.VUE_APP_INFO_EMAIL)(),
      WEB2_ERROR_BTN_GO_HOME: "Əsas səhifə",
      WEB2_ERROR_BTN_SUPPORT: "Müştəri xidməti",
      WEB2_ERROR_TEXT_SUPPORT: "Çətinlik var? Hər zaman kömək etməyə məmnun olarıq",
      WEB2_ERROR_404_TITLE: "Səhifə tapılmadı",
      WEB2_ERROR_404_MSG: "Səhifə mövcud deyil və ya ləğv olunub.",
      WEB2_ERROR_403_TITLE: "Giriş qadağandır",
      WEB2_ERROR_403_MSG: "Bu səhifəyə giriş məhdudiyyəti var. Xahiş edirik, əsas səhifəyə qayıdın.",
      WEB2_CONNECTION_ERROR_CAPTION: "Şəbəkə xətası",
      WEB2_CONNECTION_ERROR_DESCRIPTION: "Serverə qoşularkən xəta baş verdi. İnternet şəbəkənizi yoxlayın və yenidən cəhd edin.",
      WEB2_CONNECTION_ERROR_BTN_RETRY: "Təkrar edin",
      WEB2_MOBILE_LOADING_APK_TITLE: "Yeniləmə davam edir",
      WEB2_MOBILE_LOADING_APK_MESSAGE: "Xahiş edirik yeniləmə bitənə qədər gözləyin.",
      WEB2_MOBILE_UPDATE_APK_TITLE: "Tətbiq yenilənsin?",
      WEB2_MOBILE_UPDATE_APK_MESSAGE: "Bizim tətbiqdə yeniliklər var. Oyuna davam etmək üçün, yenilikləri quraşdırın.",
      WEB2_MOBILE_UPDATE_APK_BTN_UPDATE: "Yeniləyin",
      WEB2_MOBILE_UPDATE_APK_BTN_POSTPONE: "İndi yox",
      WEB2_IP_BLOCKED_ERROR: "Ölkənizdə servisdən istifadə məhdudlaşdırılıb.",
      WEB2_UPDATE_IOS_TITLE: "iOS Update Required",
      WEB2_UPDATE_IOS_MESSAGE: "Your current iOS version is not supported. To access the latest improvements, please update your device.",
      WEB2_UPDATE_IOS_BUTTON: "Update iOS now",
      WEB2_UPDATE_ANDROID_TITLE: "Android Update Required",
      WEB2_UPDATE_ANDROID_MESSAGE: "Your current Android version is not supported. To access the latest improvements, please update your device.",
      WEB2_UPDATE_ANDROID_BUTTON: "Update Android now"
    }),
    [/* @__PURE__ */ (() => Locale.EL_GR)()]: /* @__PURE__ */ Object.freeze({
      WEB2_ERROR: "Λάθος",
      WEB2_SESSION_EXPIRED: "Η συνεδρία έληξε",
      CONNECTION_ERROR: "Δεν μπορέσαμε να συνδεθούμε στον διακομιστή. Ελέγξτε τη σύνδεσή σας στο Διαδίκτυο και δοκιμάστε ξανά.",
      WEB2_TECHNICAL_ERROR: "Παρουσιάστηκε τεχνικό σφάλμα. Επαναλάβετε τη φόρτωση της σελίδας ή δοκιμάστε ξανά αργότερα.",
      JSP_PCL_FBOT_CLOSE: "Κλείσιμο",
      JS_CAPTION_ATTENTION: "Προσοχή",
      WEB2_DO_LOGIN: "Σύνδεση",
      JSPNAV_USTAT_REGISTER: "Εγγραφή",
      WEB2_ERROR_503_TITLE_V2: "Ο ιστότοπός μας δεν είναι προσωρινά διαθέσιμος",
      WEB2_ERROR_503_TEXT1_V2: "Αυτή τη στιγμή πραγματοποιούμε προγραμματισμένη συντήρηση του συστήματος.",
      WEB2_ERROR_503_TEXT2_V2: "Δοκιμάστε ξανά σύντομα.",
      WEB2_ERROR_503_TEXT3_V2: "Ζητούμε συγγνώμη για την ταλαιπωρία.",
      WEB2_ERROR_503_BTN_UPDATE_V2: "Ανανέωση",
      WEB2_HELP_EMAIL: /* @__PURE__ */ (() => process.env.VUE_APP_INFO_EMAIL)(),
      WEB2_ERROR_BTN_GO_HOME: "Επιστροφή στην κεντρική σελίδα",
      WEB2_ERROR_BTN_SUPPORT: "Υποστήριξη",
      WEB2_ERROR_TEXT_SUPPORT: "Έχετε ερωτήσεις; Είμαστε πάντα στην ευχάριστη θέση να βοηθήσουμε",
      WEB2_ERROR_404_TITLE: "Η σελίδα δεν βρέθηκε",
      WEB2_ERROR_404_MSG: "Η σελίδα που ψάχνετε δεν υπάρχει.",
      WEB2_ERROR_403_TITLE: "Η πρόσβαση απαγορεύεται",
      WEB2_ERROR_403_MSG: "Η σελίδα στην οποία προσπαθείτε να αποκτήσετε πρόσβαση έχει περιορισμένη πρόσβαση. Επιστρέψτε στην αρχική σελίδα.",
      WEB2_CONNECTION_ERROR_CAPTION: "Σφάλμα σύνδεσης",
      WEB2_CONNECTION_ERROR_DESCRIPTION: "Δεν μπορέσαμε να συνδεθούμε στον διακομιστή. Ελέγξτε τη σύνδεσή σας στο Διαδίκτυο και δοκιμάστε ξανά.",
      WEB2_CONNECTION_ERROR_BTN_RETRY: "Ξαναδοκιμάσετε",
      WEB2_MOBILE_LOADING_APK_TITLE: "Ενημέρωση της εφαρμογής",
      WEB2_MOBILE_LOADING_APK_MESSAGE: "Περιμένετε μέχρι να ολοκληρωθεί η ενημέρωση.",
      WEB2_MOBILE_UPDATE_APK_TITLE: "Διατίθεται ενημέρωση εφαρμογής?",
      WEB2_MOBILE_UPDATE_APK_MESSAGE: "Ενημερώστε στην πιο πρόσφατη έκδοση για να συνεχίσετε να χρησιμοποιείτε την εφαρμογή.",
      WEB2_MOBILE_UPDATE_APK_BTN_UPDATE: "Ενημέρωση",
      WEB2_MOBILE_UPDATE_APK_BTN_POSTPONE: "Αργότερα",
      WEB2_IP_BLOCKED_ERROR: "Αυτή η υπηρεσία δεν είναι διαθέσιμη στη χώρα σας.",
      WEB2_UPDATE_IOS_TITLE: "Απαιτείται Ενημέρωση iOS",
      WEB2_UPDATE_IOS_MESSAGE: "Η τρέχουσα έκδοση του iOS σας δεν υποστηρίζεται. Για να αποκτήσετε πρόσβαση στις τελευταίες βελτιώσεις, παρακαλούμε ενημερώστε τη συσκευή σας.",
      WEB2_UPDATE_IOS_BUTTON: "Ενημερώστε το iOS τώρα",
      WEB2_UPDATE_ANDROID_TITLE: "Απαιτείται Ενημέρωση Android",
      WEB2_UPDATE_ANDROID_MESSAGE: "Η τρέχουσα έκδοση Android δεν υποστηρίζεται. Για να αποκτήσετε πρόσβαση στις τελευταίες βελτιώσεις, παρακαλούμε ενημερώστε τη συσκευή σας.",
      WEB2_UPDATE_ANDROID_BUTTON: "Ενημέρωση Android τώρα"
    }),
    [/* @__PURE__ */ (() => Locale.ES_PE)()]: /* @__PURE__ */ Object.freeze({
      WEB2_ERROR: "Error",
      WEB2_SESSION_EXPIRED: "Sesión expirada",
      CONNECTION_ERROR: "Error de conexión. Comprueba tu conexión a Internet e inténtalo de nuevo.",
      WEB2_TECHNICAL_ERROR: "Ha ocurrido un error técnico. Vuelve a cargar la página o vuelve a intentarlo más tarde.",
      JSP_PCL_FBOT_CLOSE: "Cerca",
      JS_CAPTION_ATTENTION: "Atención",
      WEB2_DO_LOGIN: "Iniciar sesión",
      JSPNAV_USTAT_REGISTER: "Inscribirse",
      WEB2_ERROR_503_TITLE_V2: "Nuestro sitio web está temporalmente fuera de servicio",
      WEB2_ERROR_503_TEXT1_V2: "Actualmente estamos realizando un mantenimiento programado del sistema.",
      WEB2_ERROR_503_TEXT2_V2: "Vuelva a intentarlo pronto.",
      WEB2_ERROR_503_TEXT3_V2: "Pedimos disculpas por las molestias.",
      WEB2_ERROR_503_BTN_UPDATE_V2: "Actualizar",
      WEB2_HELP_EMAIL: /* @__PURE__ */ (() => process.env.VUE_APP_INFO_EMAIL)(),
      WEB2_ERROR_BTN_GO_HOME: "Volver a la página principal",
      WEB2_ERROR_BTN_SUPPORT: "Apoyo",
      WEB2_ERROR_TEXT_SUPPORT: "¿Tiene preguntas? Siempre estamos felices de ayudar",
      WEB2_ERROR_404_TITLE: "Página no encontrada",
      WEB2_ERROR_404_MSG: "La página que buscas no existe.",
      WEB2_ERROR_403_TITLE: "Acceso Prohibido",
      WEB2_ERROR_403_MSG: "La página a la que intenta acceder tiene acceso restringido. Vuelva a la página de inicio.",
      WEB2_CONNECTION_ERROR_CAPTION: "Error de conexión",
      WEB2_CONNECTION_ERROR_DESCRIPTION: "No pudimos conectarnos al servidor. Comprueba tu conexión a Internet e inténtalo de nuevo.",
      WEB2_CONNECTION_ERROR_BTN_RETRY: "Reintentar",
      WEB2_MOBILE_LOADING_APK_TITLE: "Actualización de la aplicación",
      WEB2_MOBILE_LOADING_APK_MESSAGE: "Espere hasta que se complete la actualización.",
      WEB2_MOBILE_UPDATE_APK_TITLE: "Actualización de la aplicación disponible",
      WEB2_MOBILE_UPDATE_APK_MESSAGE: "Actualice a la última versión para continuar usando la aplicación.",
      WEB2_MOBILE_UPDATE_APK_BTN_UPDATE: "Actualizar",
      WEB2_MOBILE_UPDATE_APK_BTN_POSTPONE: "Luego",
      WEB2_IP_BLOCKED_ERROR: "Este servicio no está disponible en su país.",
      WEB2_UPDATE_IOS_TITLE: "Se Requiere Actualización de iOS",
      WEB2_UPDATE_IOS_MESSAGE: "Tu versión actual de iOS no es compatible. Para acceder a las últimas mejoras, actualiza tu dispositivo.",
      WEB2_UPDATE_IOS_BUTTON: "Actualizar iOS ahora",
      WEB2_UPDATE_ANDROID_TITLE: "Se Requiere Actualización de Android",
      WEB2_UPDATE_ANDROID_MESSAGE: "Tu versión actual de Android no es compatible. Para acceder a las últimas mejoras, actualiza tu dispositivo.",
      WEB2_UPDATE_ANDROID_BUTTON: "Actualizar Android ahora"
    }),
    [/* @__PURE__ */ (() => Locale.ES_ES)()]: /* @__PURE__ */ Object.freeze({
      WEB2_ERROR: "Error",
      WEB2_SESSION_EXPIRED: "Sesión expirada",
      CONNECTION_ERROR: "Error de conexión. Por favor, comprueba tu conexión a Internet e inténtalo de nuevo.",
      WEB2_TECHNICAL_ERROR: "Ha ocurrido un error técnico. Vuelve a cargar la página o vuelve a intentarlo más tarde.",
      JSP_PCL_FBOT_CLOSE: "Cerca",
      JS_CAPTION_ATTENTION: "Atención",
      WEB2_DO_LOGIN: "Iniciar sesión",
      JSPNAV_USTAT_REGISTER: "Registrarse",
      WEB2_ERROR_503_TITLE_V2: "Nuestro sitio web no está disponible temporalmente",
      WEB2_ERROR_503_TEXT1_V2: "Actualmente estamos realizando un mantenimiento programado del sistema.",
      WEB2_ERROR_503_TEXT2_V2: "Por favor, inténtalo de nuevo pronto.",
      WEB2_ERROR_503_TEXT3_V2: "Te pedimos disculpas por las molestias.",
      WEB2_ERROR_503_BTN_UPDATE_V2: "Actualiza",
      WEB2_HELP_EMAIL: /* @__PURE__ */ (() => process.env.VUE_APP_INFO_EMAIL)(),
      WEB2_ERROR_BTN_GO_HOME: "Volver a la página principal",
      WEB2_ERROR_BTN_SUPPORT: "Ayuda",
      WEB2_ERROR_TEXT_SUPPORT: "¿Tienes preguntas? Siempre estamos dispuestos a ayudar",
      WEB2_ERROR_404_TITLE: "Página no encontrada",
      WEB2_ERROR_404_MSG: "La página que buscas no existe.",
      WEB2_ERROR_403_TITLE: "Acceso Prohibido",
      WEB2_ERROR_403_MSG: "La página a la que intentas acceder tiene el acceso restringido. Por favor, vuelve a la página principal.",
      WEB2_CONNECTION_ERROR_CAPTION: "Error de conexión",
      WEB2_CONNECTION_ERROR_DESCRIPTION: "No hemos podido conectar con el servidor. Por favor, comprueba tu conexión a Internet e inténtalo de nuevo.",
      WEB2_CONNECTION_ERROR_BTN_RETRY: "Vuelve a intentar",
      WEB2_MOBILE_LOADING_APK_TITLE: "Actualizar la app",
      WEB2_MOBILE_LOADING_APK_MESSAGE: "Por favor, espera hasta que se complete la actualización.",
      WEB2_MOBILE_UPDATE_APK_TITLE: "Actualización de la aplicación disponible",
      WEB2_MOBILE_UPDATE_APK_MESSAGE: "Por favor, actualiza a la última versión para seguir utilizando la app.",
      WEB2_MOBILE_UPDATE_APK_BTN_UPDATE: "Actualizar",
      WEB2_MOBILE_UPDATE_APK_BTN_POSTPONE: "Más tarde",
      WEB2_IP_BLOCKED_ERROR: "Este servicio no está disponible en tu país.",
      WEB2_UPDATE_IOS_TITLE: "Se Requiere Actualización de iOS",
      WEB2_UPDATE_IOS_MESSAGE: "Tu versión actual de iOS no es compatible. Para acceder a las últimas mejoras, actualiza tu dispositivo.",
      WEB2_UPDATE_IOS_BUTTON: "Actualizar iOS ahora",
      WEB2_UPDATE_ANDROID_TITLE: "Se Requiere Actualización de Android",
      WEB2_UPDATE_ANDROID_MESSAGE: "Tu versión actual de Android no es compatible. Para acceder a las últimas mejoras, actualiza tu dispositivo.",
      WEB2_UPDATE_ANDROID_BUTTON: "Actualizar Android ahora"
    }),
    [/* @__PURE__ */ (() => Locale.ES_CL)()]: /* @__PURE__ */ Object.freeze({
      WEB2_ERROR: "Error",
      WEB2_SESSION_EXPIRED: "Sesión expirada",
      CONNECTION_ERROR: "Error de conexión. Por favor, comprueba tu conexión a Internet e inténtalo de nuevo.",
      WEB2_TECHNICAL_ERROR: "Ha ocurrido un error técnico. Vuelve a cargar la página o vuelve a intentarlo más tarde.",
      JSP_PCL_FBOT_CLOSE: "Cerca",
      JS_CAPTION_ATTENTION: "Atención",
      WEB2_DO_LOGIN: "Iniciar sesión",
      JSPNAV_USTAT_REGISTER: "Registrarse",
      WEB2_ERROR_503_TITLE_V2: "Nuestro sitio web no está disponible temporalmente",
      WEB2_ERROR_503_TEXT1_V2: "Actualmente estamos realizando un mantenimiento programado del sistema.",
      WEB2_ERROR_503_TEXT2_V2: "Por favor, inténtalo de nuevo pronto.",
      WEB2_ERROR_503_TEXT3_V2: "Te pedimos disculpas por las molestias.",
      WEB2_ERROR_503_BTN_UPDATE_V2: "Actualiza",
      WEB2_HELP_EMAIL: /* @__PURE__ */ (() => process.env.VUE_APP_INFO_EMAIL)(),
      WEB2_ERROR_BTN_GO_HOME: "Volver a la página principal",
      WEB2_ERROR_BTN_SUPPORT: "Ayuda",
      WEB2_ERROR_TEXT_SUPPORT: "¿Tienes preguntas? Siempre estamos dispuestos a ayudar",
      WEB2_ERROR_404_TITLE: "Página no encontrada",
      WEB2_ERROR_404_MSG: "La página que buscas no existe.",
      WEB2_ERROR_403_TITLE: "Acceso Prohibido",
      WEB2_ERROR_403_MSG: "La página a la que intentas acceder tiene el acceso restringido. Por favor, vuelve a la página principal.",
      WEB2_CONNECTION_ERROR_CAPTION: "Error de conexión",
      WEB2_CONNECTION_ERROR_DESCRIPTION: "No hemos podido conectar con el servidor. Por favor, comprueba tu conexión a Internet e inténtalo de nuevo.",
      WEB2_CONNECTION_ERROR_BTN_RETRY: "Vuelve a intentar",
      WEB2_MOBILE_LOADING_APK_TITLE: "Actualizar la app",
      WEB2_MOBILE_LOADING_APK_MESSAGE: "Por favor, espera hasta que se complete la actualización.",
      WEB2_MOBILE_UPDATE_APK_TITLE: "Actualización de la aplicación disponible",
      WEB2_MOBILE_UPDATE_APK_MESSAGE: "Por favor, actualiza a la última versión para seguir utilizando la app.",
      WEB2_MOBILE_UPDATE_APK_BTN_UPDATE: "Actualizar",
      WEB2_MOBILE_UPDATE_APK_BTN_POSTPONE: "Más tarde",
      WEB2_IP_BLOCKED_ERROR: "Este servicio no está disponible en tu país.",
      WEB2_UPDATE_IOS_TITLE: "Se Requiere Actualización de iOS",
      WEB2_UPDATE_IOS_MESSAGE: "Tu versión actual de iOS no es compatible. Para acceder a las últimas mejoras, actualiza tu dispositivo.",
      WEB2_UPDATE_IOS_BUTTON: "Actualizar iOS ahora",
      WEB2_UPDATE_ANDROID_TITLE: "Se Requiere Actualización de Android",
      WEB2_UPDATE_ANDROID_MESSAGE: "Tu versión actual de Android no es compatible. Para acceder a las últimas mejoras, actualiza tu dispositivo.",
      WEB2_UPDATE_ANDROID_BUTTON: "Actualizar Android ahora"
    }),
    [/* @__PURE__ */ (() => Locale.ES_MX)()]: /* @__PURE__ */ Object.freeze({
      WEB2_ERROR: "Error",
      WEB2_SESSION_EXPIRED: "Sesión expirada",
      CONNECTION_ERROR: "Error de conexión. Por favor, comprueba tu conexión a Internet e inténtalo de nuevo.",
      WEB2_TECHNICAL_ERROR: "Ha ocurrido un error técnico. Vuelve a cargar la página o vuelve a intentarlo más tarde.",
      JSP_PCL_FBOT_CLOSE: "Cerca",
      JS_CAPTION_ATTENTION: "Atención",
      WEB2_DO_LOGIN: "Iniciar sesión",
      JSPNAV_USTAT_REGISTER: "Registrarse",
      WEB2_ERROR_503_TITLE_V2: "Nuestro sitio web no está disponible temporalmente",
      WEB2_ERROR_503_TEXT1_V2: "Actualmente estamos realizando un mantenimiento programado del sistema.",
      WEB2_ERROR_503_TEXT2_V2: "Por favor, inténtalo de nuevo pronto.",
      WEB2_ERROR_503_TEXT3_V2: "Te pedimos disculpas por las molestias.",
      WEB2_ERROR_503_BTN_UPDATE_V2: "Actualiza",
      WEB2_HELP_EMAIL: /* @__PURE__ */ (() => process.env.VUE_APP_INFO_EMAIL)(),
      WEB2_ERROR_BTN_GO_HOME: "Volver a la página principal",
      WEB2_ERROR_BTN_SUPPORT: "Ayuda",
      WEB2_ERROR_TEXT_SUPPORT: "¿Tienes preguntas? Siempre estamos dispuestos a ayudar",
      WEB2_ERROR_404_TITLE: "Página no encontrada",
      WEB2_ERROR_404_MSG: "La página que buscas no existe.",
      WEB2_ERROR_403_TITLE: "Acceso Prohibido",
      WEB2_ERROR_403_MSG: "La página a la que intentas acceder tiene el acceso restringido. Por favor, vuelve a la página principal.",
      WEB2_CONNECTION_ERROR_CAPTION: "Error de conexión",
      WEB2_CONNECTION_ERROR_DESCRIPTION: "No hemos podido conectar con el servidor. Por favor, comprueba tu conexión a Internet e inténtalo de nuevo.",
      WEB2_CONNECTION_ERROR_BTN_RETRY: "Vuelve a intentar",
      WEB2_MOBILE_LOADING_APK_TITLE: "Actualizar la app",
      WEB2_MOBILE_LOADING_APK_MESSAGE: "Por favor, espera hasta que se complete la actualización.",
      WEB2_MOBILE_UPDATE_APK_TITLE: "Actualización de la aplicación disponible",
      WEB2_MOBILE_UPDATE_APK_MESSAGE: "Por favor, actualiza a la última versión para seguir utilizando la app.",
      WEB2_MOBILE_UPDATE_APK_BTN_UPDATE: "Actualizar",
      WEB2_MOBILE_UPDATE_APK_BTN_POSTPONE: "Más tarde",
      WEB2_IP_BLOCKED_ERROR: "Este servicio no está disponible en tu país.",
      WEB2_UPDATE_IOS_TITLE: "Se Requiere Actualización de iOS",
      WEB2_UPDATE_IOS_MESSAGE: "Tu versión actual de iOS no es compatible. Para acceder a las últimas mejoras, actualiza tu dispositivo.",
      WEB2_UPDATE_IOS_BUTTON: "Actualizar iOS ahora",
      WEB2_UPDATE_ANDROID_TITLE: "Se Requiere Actualización de Android",
      WEB2_UPDATE_ANDROID_MESSAGE: "Tu versión actual de Android no es compatible. Para acceder a las últimas mejoras, actualiza tu dispositivo.",
      WEB2_UPDATE_ANDROID_BUTTON: "Actualizar Android ahora"
    }),
    [/* @__PURE__ */ (() => Locale.FR_FR)()]: /* @__PURE__ */ Object.freeze({
      WEB2_ERROR: "Erreur",
      WEB2_SESSION_EXPIRED: "La session a expiré",
      CONNECTION_ERROR: "Erreur de connexion. S'il vous plaît, vérifiez votre connexion à internet et réessayez.",
      WEB2_TECHNICAL_ERROR: "Une erreur technique s'est produite. Veuillez recharger la page ou réessayer plus tard.",
      JSP_PCL_FBOT_CLOSE: "Fermer",
      JS_CAPTION_ATTENTION: "Attention",
      WEB2_DO_LOGIN: "Se connecter",
      JSPNAV_USTAT_REGISTER: "S'inscrire",
      WEB2_ERROR_503_TITLE_V2: "Notre site est momentanément indisponible",
      WEB2_ERROR_503_TEXT1_V2: "Nous procédons actuellement à une maintenance planifiée du système.",
      WEB2_ERROR_503_TEXT2_V2: "Veuillez réessayer bientôt.",
      WEB2_ERROR_503_TEXT3_V2: "Nous nous excusons pour la gêne occasionnée.",
      WEB2_ERROR_503_BTN_UPDATE_V2: "Rafraîchir",
      WEB2_HELP_EMAIL: /* @__PURE__ */ (() => process.env.VUE_APP_INFO_EMAIL)(),
      WEB2_ERROR_BTN_GO_HOME: "Retour à la page d'accueil",
      WEB2_ERROR_BTN_SUPPORT: "Support",
      WEB2_ERROR_TEXT_SUPPORT: "Des questions? Nous sommes toujours heureux d'aider",
      WEB2_ERROR_404_TITLE: "Page non trouvée",
      WEB2_ERROR_404_MSG: "La page que vous recherchez n'existe pas. Elle a peut-être été déplacée ou supprimée.",
      WEB2_ERROR_403_TITLE: "Accès interdit",
      WEB2_ERROR_403_MSG: "La page à laquelle vous essayez d'accéder a un accès restreint. Veuillez revenir à la page d'accueil.",
      WEB2_CONNECTION_ERROR_CAPTION: "Erreur de connexion",
      WEB2_CONNECTION_ERROR_DESCRIPTION: "Nous n'avons pas pu nous connecter au serveur. S'il vous plaît, vérifiez votre connexion à internet et réessayez.",
      WEB2_CONNECTION_ERROR_BTN_RETRY: "Réessayer",
      WEB2_MOBILE_LOADING_APK_TITLE: "Mise à jour de l'application",
      WEB2_MOBILE_LOADING_APK_MESSAGE: "Veuillez patienter jusqu'à ce que la mise à jour soit terminée.",
      WEB2_MOBILE_UPDATE_APK_TITLE: "Mise à jour de l'application disponible",
      WEB2_MOBILE_UPDATE_APK_MESSAGE: "Veuillez télécharger la dernière version pour continuer à utiliser l'application.",
      WEB2_MOBILE_UPDATE_APK_BTN_UPDATE: "Mettre à jour",
      WEB2_MOBILE_UPDATE_APK_BTN_POSTPONE: "Plus tard",
      WEB2_IP_BLOCKED_ERROR: "Ce service n'est pas disponible dans votre pays.",
      WEB2_UPDATE_IOS_TITLE: "Mise à jour iOS requise",
      WEB2_UPDATE_IOS_MESSAGE: "Votre version iOS actuelle n'est pas prise en charge. Pour accéder aux dernières améliorations, veuillez mettre à jour votre appareil.",
      WEB2_UPDATE_IOS_BUTTON: "Mettre à jour iOS maintenant",
      WEB2_UPDATE_ANDROID_TITLE: "Mise à jour Android requise",
      WEB2_UPDATE_ANDROID_MESSAGE: "Votre version actuelle d'Android n'est pas prise en charge. Pour accéder aux dernières améliorations, veuillez mettre à jour votre appareil.",
      WEB2_UPDATE_ANDROID_BUTTON: "Mettre à jour Android maintenant"
    }),
    [/* @__PURE__ */ (() => Locale.TR_TR)()]: /* @__PURE__ */ Object.freeze({
      WEB2_ERROR: "Hata",
      WEB2_SESSION_EXPIRED: "Oturum süresi sona erdi",
      CONNECTION_ERROR: "Sunucuya bağlanamadık. Lütfen internet bağlantınızı kontrol edin ve tekrar deneyin.",
      WEB2_TECHNICAL_ERROR: "Teknik bir hata oluştu. Lütfen sayfayı yeniden yükleyin veya daha sonra tekrar deneyin.",
      JSP_PCL_FBOT_CLOSE: "Kapat",
      JS_CAPTION_ATTENTION: "Dikkat",
      WEB2_DO_LOGIN: "Giriş Yap",
      JSPNAV_USTAT_REGISTER: "Kayıt Ol",
      WEB2_ERROR_503_TITLE_V2: "Web sitemiz geçici olarak hizmet dışıdır",
      WEB2_ERROR_503_TEXT1_V2: "Şu anda planlı bir sistem bakımından geçiyoruz.",
      WEB2_ERROR_503_TEXT2_V2: "Lütfen yakın zamanda tekrar deneyin.",
      WEB2_ERROR_503_TEXT3_V2: "Verdiğimiz rahatsızlıktan dolayı özür dileriz.",
      WEB2_ERROR_503_BTN_UPDATE_V2: "Yenile",
      WEB2_HELP_EMAIL: /* @__PURE__ */ (() => process.env.VUE_APP_INFO_EMAIL)(),
      WEB2_ERROR_BTN_GO_HOME: "Anasayfa'ya geri dön",
      WEB2_ERROR_BTN_SUPPORT: "Destek",
      WEB2_ERROR_TEXT_SUPPORT: "Sorularınız mı var? Yardımcı olmaktan mutluluk duyarız",
      WEB2_ERROR_404_TITLE: "Sayfa bulunamadı",
      WEB2_ERROR_404_MSG: "Aradığınız sayfa mevcut değil.",
      WEB2_ERROR_403_TITLE: "Erişim Yasağı",
      WEB2_ERROR_403_MSG: "Giriş yapmaya çalıştığınız sayfaya erişim kısıtlanmıştır. Lütfen ana sayfaya geri dönün.",
      WEB2_CONNECTION_ERROR_CAPTION: "Bağlantı hatası",
      WEB2_CONNECTION_ERROR_DESCRIPTION: "Sunucuya bağlanamadık. Lütfen internet bağlantınızı kontrol edin ve tekrar deneyin.",
      WEB2_CONNECTION_ERROR_BTN_RETRY: "Tekrar Dene",
      WEB2_MOBILE_LOADING_APK_TITLE: "Uygulamayı güncelleme",
      WEB2_MOBILE_LOADING_APK_MESSAGE: "Lütfen güncelleme tamamlanana kadar bekleyin.",
      WEB2_MOBILE_UPDATE_APK_TITLE: "Uygulama güncellemesi hazır",
      WEB2_MOBILE_UPDATE_APK_MESSAGE: "Uygulamayı kullanmaya devam etmek için lütfen en son sürüme güncelleyin.",
      WEB2_MOBILE_UPDATE_APK_BTN_UPDATE: "Güncelle",
      WEB2_MOBILE_UPDATE_APK_BTN_POSTPONE: "Sonra",
      WEB2_IP_BLOCKED_ERROR: "Bu hizmet ülkenizde kullanılamıyor.",
      WEB2_UPDATE_IOS_TITLE: "iOS Güncellemesi Gerekli",
      WEB2_UPDATE_IOS_MESSAGE: "Mevcut iOS sürümünüz desteklenmiyor. En son geliştirmelere erişmek için lütfen cihazınızı güncelleyin.",
      WEB2_UPDATE_IOS_BUTTON: "iOS'u şimdi güncelleyin",
      WEB2_UPDATE_ANDROID_TITLE: "Android Güncellemesi Gerekli",
      WEB2_UPDATE_ANDROID_MESSAGE: "Mevcut Android sürümünüz desteklenmiyor. En son geliştirmelere erişmek için lütfen cihazınızı güncelleyin.",
      WEB2_UPDATE_ANDROID_BUTTON: "Android'i şimdi güncelleyin"
    }),
    [/* @__PURE__ */ (() => Locale.VI_VN)()]: /* @__PURE__ */ Object.freeze({
      WEB2_ERROR: "Lỗi",
      WEB2_SESSION_EXPIRED: "Phiên hết hạn",
      CONNECTION_ERROR: "Chúng tôi không thể kết nối với máy chủ. Vui lòng kiểm tra kết nối internet của bạn và thử lại.",
      WEB2_TECHNICAL_ERROR: "Đã xảy ra lỗi kỹ thuật. Vui lòng tải lại trang hoặc thử lại sau.",
      JSP_PCL_FBOT_CLOSE: "Đóng",
      JS_CAPTION_ATTENTION: "Chú ý",
      WEB2_DO_LOGIN: "Đăng nhập",
      JSPNAV_USTAT_REGISTER: "Đăng ký",
      WEB2_ERROR_503_TITLE_V2: "Trang web của chúng tôi tạm thời không khả dụng",
      WEB2_ERROR_503_TEXT1_V2: "Chúng tôi hiện đang tiến hành bảo trì hệ thống theo lịch trình.",
      WEB2_ERROR_503_TEXT2_V2: "Vui lòng thử lại sau.",
      WEB2_ERROR_503_TEXT3_V2: "Chúng tôi rất xin lỗi vì sự bất tiện này.",
      WEB2_ERROR_503_BTN_UPDATE_V2: "Làm mới",
      WEB2_HELP_EMAIL: /* @__PURE__ */ (() => process.env.VUE_APP_INFO_EMAIL)(),
      WEB2_ERROR_BTN_GO_HOME: "Anasayfa'ya geri dön",
      WEB2_ERROR_BTN_SUPPORT: "Quay lại trang chủ",
      WEB2_ERROR_TEXT_SUPPORT: "Bạn có thắc mắc? Chúng tôi luôn sẵn lòng giúp đỡ",
      WEB2_ERROR_404_TITLE: "Không tìm thấy trang",
      WEB2_ERROR_404_MSG: "Trang bạn đang tìm kiếm không tồn tại.",
      WEB2_ERROR_403_TITLE: "Truy cập bị cấm",
      WEB2_ERROR_403_MSG: "Trang bạn đang vào đã bị hạn chế quyền truy cập. Vui lòng quay lại trang chủ.",
      WEB2_CONNECTION_ERROR_CAPTION: "Lỗi kết nối",
      WEB2_CONNECTION_ERROR_DESCRIPTION: "Chúng tôi không thể kết nối với máy chủ. Vui lòng kiểm tra kết nối internet của bạn và thử lại.",
      WEB2_CONNECTION_ERROR_BTN_RETRY: "Thử lại",
      WEB2_MOBILE_LOADING_APK_TITLE: "Cập nhật ứng dụng",
      WEB2_MOBILE_LOADING_APK_MESSAGE: "Vui lòng đợi cho đến khi cập nhật hoàn tất.",
      WEB2_MOBILE_UPDATE_APK_TITLE: "Cập nhật ứng dụng có sẵn",
      WEB2_MOBILE_UPDATE_APK_MESSAGE: "Vui lòng cập nhật lên phiên bản mới nhất để tiếp tục sử dụng ứng dụng.",
      WEB2_MOBILE_UPDATE_APK_BTN_UPDATE: "Cập nhật",
      WEB2_MOBILE_UPDATE_APK_BTN_POSTPONE: "Sau",
      WEB2_IP_BLOCKED_ERROR: "Dịch vụ này không khả dụng ở quốc gia của bạn.",
      WEB2_UPDATE_IOS_TITLE: "Yêu cầu Cập nhật iOS",
      WEB2_UPDATE_IOS_MESSAGE: "Phiên bản iOS hiện tại của bạn không được hỗ trợ. Để sử dụng những cải tiến mới nhất, vui lòng cập nhật thiết bị của bạn.",
      WEB2_UPDATE_IOS_BUTTON: "Cập nhật iOS ngay",
      WEB2_UPDATE_ANDROID_TITLE: "Yêu cầu Cập nhật Android",
      WEB2_UPDATE_ANDROID_MESSAGE: "Phiên bản Android hiện tại của bạn không được hỗ trợ. Để sử dụng những cải tiến mới nhất, vui lòng cập nhật thiết bị của bạn.",
      WEB2_UPDATE_ANDROID_BUTTON: "Cập nhật Android ngay"
    }),
    [/* @__PURE__ */ (() => Locale.TH_TH)()]: /* @__PURE__ */ Object.freeze({
      WEB2_ERROR: "ข้อผิดพลาด",
      WEB2_SESSION_EXPIRED: "หมดเวลาใช้งานแล้ว",
      CONNECTION_ERROR: "เราไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้ กรุณาตรวจสอบการเชื่อมต่อของคุณและลองอีกครั้ง.",
      WEB2_TECHNICAL_ERROR: "เกิดข้อผิดพลาดทางเทคนิค กรุณาโหลดหน้านี้ใหม่ หรือลองอีกครั้งในภายหลัง",
      JSP_PCL_FBOT_CLOSE: "ปิด",
      JS_CAPTION_ATTENTION: "โปรดทราบ",
      WEB2_DO_LOGIN: "เข้าระบบ",
      JSPNAV_USTAT_REGISTER: "สมัครสมาชิก",
      WEB2_ERROR_503_TITLE_V2: "เว็บไซต์ของเราไม่พร้อมให้บริการได้ชั่วคราว",
      WEB2_ERROR_503_TEXT1_V2: "ขณะนี้เรากำลังทำการบำรุงรักษาระบบตามกำหนดการ",
      WEB2_ERROR_503_TEXT2_V2: "กรุณาลองอีกครั้งในเร็วๆนี้",
      WEB2_ERROR_503_TEXT3_V2: "เราขออภัยในความไม่สะดวก",
      WEB2_ERROR_503_BTN_UPDATE_V2: "รีเฟรช",
      WEB2_HELP_EMAIL: /* @__PURE__ */ (() => process.env.VUE_APP_INFO_EMAIL)(),
      WEB2_ERROR_BTN_GO_HOME: "กลับไปยังหน้าหลัก",
      WEB2_ERROR_BTN_SUPPORT: "สนับสนุน",
      WEB2_ERROR_TEXT_SUPPORT: "มีคำถามใช่ไหม เราพร้อมให้ความช่วยเหลือ",
      WEB2_ERROR_404_TITLE: "ไม่พบหน้านี้",
      WEB2_ERROR_404_MSG: "ไม่มีหน้าที่คุณหา",
      WEB2_ERROR_403_TITLE: "ห้ามการเข้าถึง",
      WEB2_ERROR_403_MSG: "หน้าที่คุณพยายามเข้าถึงจำกัดการเข้าถึง กรุณากลับไปยังหน้าหลัก",
      WEB2_CONNECTION_ERROR_CAPTION: "การเชื่อมต่อขัดข้อง",
      WEB2_CONNECTION_ERROR_DESCRIPTION: "เราไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้ กรุณาตรวจสอบการเชื่อมต่อของคุณและลองอีกครั้ง",
      WEB2_CONNECTION_ERROR_BTN_RETRY: "ลองใหม่",
      WEB2_MOBILE_LOADING_APK_TITLE: "กำลังอัพเดทแอป",
      WEB2_MOBILE_LOADING_APK_MESSAGE: "กรุณารอจะกว่าการอัพเดทจะเสร็จสมบูรณ์",
      WEB2_MOBILE_UPDATE_APK_TITLE: "มีการอัพเดทแอปให้บริการ",
      WEB2_MOBILE_UPDATE_APK_MESSAGE: "กรุณาอัดเดทเวอร์ชั่นล่าสุดเพื่อใช้งานแอปต่อ",
      WEB2_MOBILE_UPDATE_APK_BTN_UPDATE: "อัพเดท",
      WEB2_MOBILE_UPDATE_APK_BTN_POSTPONE: "ภายหลัง",
      WEB2_IP_BLOCKED_ERROR: "ไม่มีบริการนี้ให้บริการในประเทศของคุณ",
      WEB2_UPDATE_IOS_TITLE: "ต้องอัพเดท iOS",
      WEB2_UPDATE_IOS_MESSAGE: "ไม่รองรับเวอร์ชั่น iOS ปัจจุบันของคุณ หากต้องการเข้าถึงการปรับปรุงล่าสุด กรุณาอัพเดทอุปกรณ์ของคุณ",
      WEB2_UPDATE_IOS_BUTTON: "อัพเดท iOS ตอนนี้",
      WEB2_UPDATE_ANDROID_TITLE: "ต้องอัพเดท Android",
      WEB2_UPDATE_ANDROID_MESSAGE: "ไม่รองรับเวอร์ชั่น Android ปัจจุบันของคุณ หากต้องการเข้าถึงการปรับปรุงล่าสุด กรุณาอัพเดทอุปกรณ์ของคุณ",
      WEB2_UPDATE_ANDROID_BUTTON: "อัพเดท Android ตอนนี้"
    })
  };
  if (/* @__PURE__ */ (() => process.env.VUE_APP_PRODUCT_LI)()) {
    if (/* @__PURE__ */ (() => process.env.VUE_APP_SKIN_TWIN)() || /* @__PURE__ */ (() => process.env.VUE_APP_SKIN_TWINASO)()) {
      i18nBootstrapTranslations.ru_RU = ruTranslations$1;
    } else {
      i18nBootstrapTranslations.ru_RU = ruTranslations;
    }
  }
}
function getBootstrapTranslations() {
  if (process.env.VUE_APP_PRODUCT_LEONRU) {
    return i18nBootstrapTranslations.DEFAULT;
  }
  if (process.env.VUE_APP_PRODUCT_LI && process.env.VUE_APP_SKIN_SLOTT) {
    return i18nBootstrapTranslations.DEFAULT;
  }
  const locale = getInitLocale() || "DEFAULT";
  return i18nBootstrapTranslations[locale] || i18nBootstrapTranslations.DEFAULT;
}
function isBootstrapTranslationKey(key) {
  return Object.prototype.hasOwnProperty.call(getBootstrapTranslations(), key);
}
export {
  getBootstrapTranslations,
  getInitLocale,
  isBootstrapTranslationKey
};
