import { defineStore } from 'pinia';
import {
  ref,
  toRef,
  watch,
} from 'vue';

import { isNumber } from '@leon-hub/guards';
import { AccountType } from '@leon-hub/websocket/src/sdk-ws';
import { logger } from '@leon-hub/logging';

import cordovaTrackDepositQualified
  from 'web/src/modules/customer/utils/cordovaTrackDepositQualified';
import useCustomerFinanceApi from 'web/src/modules/customer/composables/useCustomerFinanceApi';
import { useWebSockets } from 'web/src/modules/core/composables';
import type { DepositMetrics, CbcBalance } from 'web/src/modules/customer/types';
import useAppsflyer from 'web/src/modules/cordova/composables/useAppsflyer';
import type { DepositMetric, GoogleMetric } from 'web/src/modules/analytics/services/types';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import useCustomerDataStore from 'web/src/modules/customer/store/useCustomerDataStore';
import { useCurrencyStore } from 'web/src/modules/money/store';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import { useThemeStore } from 'web/src/modules/theme/store';
import { useWebSocketsConfig } from 'web/src/modules/core/composables/site-config';

import useCustomerFinanceStore from './useCustomerFinanceStore';

const useCustomerBalanceUpdateStore = defineStore('customer-balance-update', () => {
  const { setBalance } = useCustomerFinanceStore();
  const { isLoggedIn } = useIsLoggedIn();
  const { getBalance } = useCustomerFinanceApi();
  const { subscribeAuthorized } = useWebSockets();
  const { isBalanceEnabled } = useWebSocketsConfig();
  const analytics = useAnalytics();
  const customerData = useCustomerDataStore();
  const currencyStore = useCurrencyStore();
  const currency = toRef(currencyStore, 'currency');
  const socketPollingTimeout = ref(5000);

  const themeStore = useThemeStore();
  const theme = toRef(themeStore, 'theme');
  const firstTheme = toRef(themeStore, 'firstTheme');

  const cbcBalanceFromPolling = ref<CbcBalance>({
    balance: 0,
    lastModifiedAt: 0,
  });
  const cashbackFromPolling = ref(0);

  function sendMetrics(depositMetrics: DepositMetrics): void {
    if (depositMetrics.length) {
      const {
        login, country, vipStatus, customerBetType, shortComments,
      } = customerData;

      for (const metric of depositMetrics) {
        if (process.env.VUE_APP_PLATFORM_CORDOVA) {
          void useAppsflyer().trackDeposit({
            login,
            isFirstDeposit: metric.firstDeposit,
            method: metric.paymentSystemId,
            price: metric.systemAmount,
            country,
          });
        }

        const zetaDepositMetrics: DepositMetric & GoogleMetric = {
          systemAmount: metric.systemAmount,
          paymentId: metric.paymentId,
          paymentSystemId: metric.paymentSystemId.toLowerCase(),
          firstDeposit: metric.firstDeposit,
          userId: login,
          currency: currency.value,
          firstTheme: firstTheme.value,
          theme: theme.value,
          shortComments: shortComments.length ? shortComments[0] : undefined,
          country,
          vipStatus,
          customerBetType,
        };

        analytics.makeDeposit(zetaDepositMetrics);
      }
    }
  }

  async function loadData(silent?: boolean): Promise<void> {
    if (!isLoggedIn.value) {
      return;
    }

    const response = await getBalance(silent);

    setBalance(response.balance, response.lastModifiedAt);
    currencyStore.setCurrency(response.currency);

    sendMetrics(response.depositMetrics);

    cbcBalanceFromPolling.value = {
      balance: response.cbcBalance,
      lastModifiedAt: response.lastModifiedAt,
    };

    if (isNumber(response.cashbackBalance)) {
      cashbackFromPolling.value = response.cashbackBalance;
    }
  }

  function subscribeOnBalanceUpdate(): void {
    subscribeAuthorized({
      method: 'onBalanceChange',
      onMessage: (data) => {
        const {
          walletTypeId,
          availableBalance,
          date,
        } = data.onBalanceChange;

        switch (walletTypeId) {
          case AccountType.MAIN:
            setBalance(availableBalance, date);
            break;
          case AccountType.CBC:
            cbcBalanceFromPolling.value = {
              balance: data.onBalanceChange.availableBalance,
              lastModifiedAt: data.onBalanceChange.date,
            };
            break;
          case AccountType.BETTING:
            break;
          default:
            logger.error(`Unsupported balance type: ${data.onBalanceChange.walletTypeId}`, data);
            break;
        }
      },
      isEnabled: isBalanceEnabled,
      polling: {
        timeout: socketPollingTimeout,
        callback: () => loadData(),
      },
    });
  }

  function init(): void {
    subscribeOnBalanceUpdate();

    if (process.env.VUE_APP_PLATFORM_CORDOVA) {
      const isAppsFlyerDepositQualified = toRef(customerData, 'isAppsFlyerDepositQualified');

      watch(isAppsFlyerDepositQualified, (newValue) => {
        if (newValue) {
          void cordovaTrackDepositQualified({
            login: customerData.login,
            isAppsFlyerDepositQualified: newValue,
          });
        }
      }, {
        immediate: true,
      });
    }
  }

  void init();

  return {
    cbcBalanceFromPolling,
    cashbackFromPolling,
    loadData,
  };
});

export default useCustomerBalanceUpdateStore;
