import type { Ref } from 'vue';

import useTheHeaderPhone from './useTheHeaderPhone';
import useTheHeaderDesktop from './useTheHeaderDesktop';

export interface UseTheHeaderComposable {
  headerStyles: Ref<Record<string, string>>;
  isCalculated: Ref<boolean>;
}

const useTheHeader: (content: Ref<HTMLElement | undefined>) => UseTheHeaderComposable = process.env.VUE_APP_LAYOUT_PHONE
  ? useTheHeaderPhone
  : useTheHeaderDesktop;

export default useTheHeader;
