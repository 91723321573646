<script lang="ts" setup>
import SwiperNavigationButtonsBase
  from 'web/src/components/Swiper/Navigation/SwiperNavigationButtonsBase/SwiperNavigationButtonsBase.vue';

import { useVSwiperBannerNavigationButtons } from './composables';

const {
  navigationProperties,
  onNextClick,
  onPreviousClick,
} = useVSwiperBannerNavigationButtons();
</script>

<template>
  <SwiperNavigationButtonsBase v-auto-id="'VSwiperBannerNavigationButtons'"
    v-bind="navigationProperties"
    @click-previous="onPreviousClick"
    @click-next="onNextClick"
  />
</template>
