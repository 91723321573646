import type { FastMoneyChoiceOption } from 'web/src/components/FastMoneyChoice';
import type { FormatMoneyFunc } from 'web/src/modules/money/types';

export default function suggestedAmountsToFastChoices(
  suggestedAmounts: number[] | null,
  formatMoney: FormatMoneyFunc,
) : FastMoneyChoiceOption[] | null {
  if (!suggestedAmounts) return null;
  return suggestedAmounts.map((item) => ({ label: formatMoney(item), value: item }));
}
