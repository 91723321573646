import type ApplicationRestClient from 'web/src/modules/core/services/api/ApplicationRestClient';

import { isCreditCardTokenizationData } from './guards';
import type { CreditCardTokenizationService, CreditCardTokenizationData, CreditCardTokenizationRequest } from './types';

export default function createCreditCardTokenizationApi(client: ApplicationRestClient): CreditCardTokenizationService {
  return {
    async tokenize({ pan }: CreditCardTokenizationRequest) {
      return client.request<CreditCardTokenizationData>({
        id: 'cards/tokenize',
        endpoint: '/v1/cards/tokenize',
        data: { pan },
        silent: false,
        guard: isCreditCardTokenizationData,
      });
    },
  };
}
