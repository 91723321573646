export enum FormErrorKeyword {
  Required = 'required',
  Pattern = 'pattern',
  MinLength = 'minLength',
  MaxLength = 'maxLength',
  If = 'if',
  MinItems = 'minItems',
  MaxItems = 'maxItems',
  Format = 'format',
  InvalidDate = 'InvalidDate',
  DateUnderMinYear = 'DateUnderMinYear',
  IsNotAnAdultStart = 'IsNotAnAdultStart',
  IsNotAnAdultEnd = 'IsNotAnAdultEnd',
  IsFileWrongFormat = 'IsFileWrongFormat',
  IsFileAlreadyExists = 'IsFileAlreadyExists',
  Type = 'type',
  Enum = 'enum',
  ForbiddenSymbol = 'ForbiddenSymbol',
}
