import { doSaveBetSlipByBetId } from '@leon-hub/api-sdk';
import type { BetSlipSaveByBetRequest } from '@leon-hub/api-sdk';

import type ApplicationGqlClient from 'web/src/modules/core/services/api/ApplicationGqlClient';

import type { DataForSharing } from '../types';

export function requestSavePlacedBet(input: BetSlipSaveByBetRequest, apiClient: ApplicationGqlClient): Promise<DataForSharing> {
  return doSaveBetSlipByBetId(apiClient, (node) => node.mutations.betSlip.saveBetSlipByBet, {
    options: input,
  });
}
