import { ScriptError } from '@leon-hub/app-errors/src/ScriptError';

async function loadAppendScript(sourceUrl: string): Promise<void> {
  return new Promise((resolve, reject) => {
    if (!window.yandex_metrika_callbacks2) {
      window.yandex_metrika_callbacks2 = [];
    }

    window.yandex_metrika_callbacks2.push(() => {
      resolve();
    });

    const head = document.head || document.querySelectorAll('head')[0];
    const script = document.createElement('script');

    script.src = sourceUrl;
    // script.async = true;
    script.defer = true;
    // script.addEventListener('load', () => resolve());
    script.addEventListener('error', (error) => reject(
      new ScriptError({
        filename: error.filename ?? (error.target as HTMLScriptElement)?.src,
        message: error.message ?? 'Possible cause: blocked by an ad blocker',
      }),
    ));

    head.append(script);
  });
}

export default loadAppendScript;
