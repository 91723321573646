<script setup lang="ts">
import { computed } from 'vue';

import { useVList } from 'web/src/components/List/VList/composables';
import { ListType } from 'web/src/components/List/enums';
import getClassObject from 'web/src/components/List/VList/utils/getClassObject';

interface VListProps {
  listType?: ListType;
  independentItems?: boolean;
}

const props = withDefaults(defineProps<VListProps>(), {
  listType: ListType.SEPARATE,
});

useVList(props);
const classObject = computed(() => getClassObject(props));
</script>

<template>
  <ul v-auto-id="'VList'" :class="classObject">
    <slot />
  </ul>
</template>
