import { ApiError } from '@leon-hub/api';

export function getPasswordErrorTitle(
  rawError: unknown,
): string {
  if (rawError instanceof ApiError && rawError.code.equals('COMMON_PASSWORD')) {
    return 'JS_CAPTION_ATTENTION';
  }

  return 'WEB2_MODALTITLE_ATTENTION';
}
