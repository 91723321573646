<script lang="ts" setup>
import { onBeforeUnmount } from 'vue';

import { Tag } from '@leon-hub/tags';

import { TextSlotPlaceholder } from 'web/src/components/TextSlotPlaceholder';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { VForm } from 'web/src/components/Form';
import SButton from 'web/src/components/Button/SButton/SButton.vue';

import type { ResponsibleGamblingFormProps, ResponsibleGamblingFormEmits } from './types';
import { useResponsibleGamblingForm } from './composables';

const props = withDefaults(defineProps<ResponsibleGamblingFormProps>(), {
});
const emit = defineEmits<ResponsibleGamblingFormEmits>();

const {
  form,
  timeName,
  dynamicUiSchema,
  buttonProperties,
  handleSubmit,
  handleInput,
  handleButtonClick,
  beforeUnmount,
} = useResponsibleGamblingForm(props, emit);

onBeforeUnmount(beforeUnmount);
</script>

<template>
  <div v-auto-id="'ResponsibleGamblingForm'" :class="$style['responsible-gambling-form__wrapper']">
    <div :class="$style['responsible-gambling-form__form']">
      <VForm
        ref="form"
        v-data-test="{
          el: 'core-custom-input',
          name: limitName,
        }"
        :schema="schema"
        :ui-schema="dynamicUiSchema"
        :is-pending="loading"
        @change="handleInput"
        @submit="handleSubmit"
        @input="handleInput"
      />
    </div>
    <div :class="$style['responsible-gambling-form__button']">
      <TextSlotPlaceholder
        v-if="timeName"
        :class="$style['responsible-gambling-form__time']"
        :text="$t('WEB2_YOU_CAN_CHANGE')"
        :tag="Tag.DIV"
      >
        <template #hours>
          <span :class="$style['responsible-gambling-form__time-accent']">
            {{ timeName.hours || '' }}
          </span>
        </template>

        <template #minutes>
          <span :class="$style['responsible-gambling-form__time-accent']">
            {{ timeName.minutes || '' }}
          </span>
        </template>
      </TextSlotPlaceholder>
      <template v-else>
        <SButton
          v-if="null"
          v-bind="buttonProperties"
          kind="primary"
          size="medium"
          @click="handleButtonClick"
        />
        <VButton
          v-else
          v-bind="buttonProperties"
          @click="handleButtonClick"
        />
      </template>
    </div>
  </div>
</template>

<style lang="scss" module>
.responsible-gambling-form {
  &__wrapper {
    display: flex;
    column-gap: 12px;
    align-items: flex-start;
    width: 100%;
  }

  &__form {
    flex-grow: 1;
    max-width: calc(100% - 140px);
  }

  &__time {
    @include timeFont;

    color: var(--TextPrimary);

    &-accent {
      @include timeFont;

      color: var(--TextDefault);
    }
  }

  &__button {
    display: inline-flex;
    align-items: center;
    min-width: 128px;
    max-width: 128px;
    height: 44px;
    margin-top: $buttonMarginTop;
  }
}
</style>
