export enum LabelKind {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  TAB = 'tab',
  NUMBER = 'number',
}

export enum MaxLabelLength {
  LONG_STRING = 75,
  MEDIUM_STRING = 35,
  SHORT_STRING = 25,
}

export interface LandingLabelProps {
  content: string;
  kind?: LabelKind;
  isInverse?: boolean;
  isActive?: boolean;
  maxLengthLetters?: MaxLabelLength;
}
