import { getIconName, IconName } from '@leon-hub/icons';

import type { VIconProps } from '@components/v-icon';

import type { CasinoCategoryTab } from 'web/src/modules/casino/submodules/lobby/types';

interface CasinoCategoryTabsComposable {
  getIconProperties: (item: CasinoCategoryTab) => VIconProps;
}

export default function useCasinoCategoryTabs(): CasinoCategoryTabsComposable {
  function getIconProperties(item: CasinoCategoryTab): VIconProps {
    return {
      name: !item.iconUrl && !item.id.includes('id-') ? getIconName({
        prefix: 'casino',
        name: item.id,
      }) : IconName.CROSS,
    };
  }

  return {
    getIconProperties,
  };
}
