import type { Ref, WatchStopHandle } from 'vue';
import {
  computed,
  toRef,
  watch,
} from 'vue';
import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';

import type { BaseRouteNameType } from '@leon-hub/routing-config-names';

import useLeaguePageStore from 'web/src/modules/sportline/submodules/league/store/useLeaguePageStore';
import type {
  SportEventsLeagueSeoMetaInfo,
} from 'web/src/modules/sportline/submodules/league/types';
import type { SportDateTimeFilter } from 'web/src/modules/sportline/submodules/navigation/store/types';
import type {
  LeagueElement,
  SportElement,
  OutrightSportlineEvent,
  SportEventSeoMetaInfo,
} from 'web/src/modules/sportline/types';
import type { LeaguePageSectionId } from 'web/src/modules/sportline/submodules/league/enums';
import { isDifferentSportDateTimeFilter } from 'web/src/modules/sportline/submodules/navigation/store/guards';
import {
  getSportEventSeoMetaInfo,
} from 'web/src/modules/sportline/utils';
import { resolveSportsPageLink } from 'web/src/modules/sportline/submodules/navigation/store/utils';
import { useSportlineNavigationStore } from 'web/src/modules/sportline/submodules/navigation/store';
import { isLeaguePageRouteName } from 'web/src/modules/sportline/submodules/navigation/guards';

interface UseLeagueRoutePageComposable {
  dateTimeFilter: Ref<Maybe<SportDateTimeFilter>>;
  isReady: Ref<boolean>;
  canDisplayAnything: Ref<boolean>;
  hasEvents: Ref<boolean>;
  isEventsTabAvailable: Ref<boolean>;
  isStatisticTabAvailable: Ref<boolean>;
  isPromotionsTabAvailable: Ref<boolean>;
  sportElement: Ref<Maybe<SportElement>>;
  outrightEvents: Ref<SportElement<OutrightSportlineEvent>[]>;
  isMarketTypesSelectionEnabled: Ref<boolean>;
  isLeagueStatisticEnabled: Ref<boolean>;
  regionName: Ref<string>;
  leagueId: Ref<string>;
  leagueName: Ref<string>;
  sportId: Ref<string>;
  isEventsListEmpty: Ref<boolean>;
  metaParameters: Ref<SportEventsLeagueSeoMetaInfo>;
  activeSectionId: Ref<LeaguePageSectionId>;
  clickBack(): void;
  onActivate(): void;
  onDeactivate(): void;
  selectSection(value: { id: LeaguePageSectionId }): void;
}

export default function useLeagueRoutePage(): UseLeagueRoutePageComposable {
  const router = useRouter();
  const leaguePageStore = useLeaguePageStore();
  const sportlineNavigationStore = useSportlineNavigationStore();

  const {
    setSportlineReloadFiltersLocation,
    setSportlineNavigationBetweenPagesFilter,
  } = sportlineNavigationStore;
  const sportlineReloadFiltersLocation = toRef(sportlineNavigationStore, 'sportlineReloadFiltersLocation');
  // eslint-disable-next-line max-len
  const sportlineNavigationBetweenPagesFilter = toRef(sportlineNavigationStore, 'sportlineNavigationBetweenPagesFilter');

  let unwatchSidebarUpdateFilters: Maybe<WatchStopHandle> = null;

  const {
    activeSectionId,
    isEventsTabAvailable,
    isStatisticTabAvailable,
    isPromotionsTabAvailable,
    canDisplayAnything,
    isMarketTypesSelectionEnabled,
    isLeagueStatisticEnabled,
    dateTimeFilter,
    isReady,
    hasEvents,
    sportElement,
    headerDataSportElement,
    regionElement,
    headerDataRegionElement,
    leagueElement,
    headerDataLeagueElement,
    outrightEvents,
    leagueData,

    isEventsListEmpty,
  } = storeToRefs(leaguePageStore);
  const {
    selectSection,
  } = leaguePageStore;

  const regionName = computed<string>(() => (
    headerDataRegionElement.value?.region.name
    ?? regionElement.value?.region.name
    ?? leagueData.value?.country?.name
    ?? ''
  ));
  const leagueId = computed<string>(() => (
    headerDataLeagueElement.value?.league.id
    ?? leagueElement.value?.league.id
    ?? ''
  ));
  const leagueName = computed<string>(() => (
    headerDataLeagueElement.value?.league.name
    ?? leagueElement.value?.league.name
    ?? ''
  ));
  const sportId = computed<string>(() => (
    headerDataSportElement.value?.sport.id
    ?? sportElement.value?.sport.id
    ?? ''
  ));

  const metaParameters = computed<SportEventsLeagueSeoMetaInfo | {}>(() => {
    if (!isReady.value) {
      return {};
    }

    const sportElementValue = sportElement.value;
    const regionElementValue = regionElement.value;
    const leagueElementValue = leagueElement.value;

    if (!sportElementValue || !regionElementValue || !leagueElementValue) {
      return {};
    }

    return {
      sport: sportElementValue.sport.name || '',
      sportFamily: sportElementValue.sport.navigationParameters.urlName || '',
      sportEmoji: sportElementValue.sport.representation.emoji || '',
      region: regionElementValue.region.name || '',
      league: leagueElementValue.league.name || '',
      sportEvents: {
        value: regionElementValue.leagues.flatMap((regionLeagueElement: LeagueElement) => regionLeagueElement
          .sportEvents.map((sportEventElement) => getSportEventSeoMetaInfo({
            sportEvent: sportEventElement.sportEvent,
            sport: sportElementValue.sport,
            region: regionElementValue.region,
            league: leagueElementValue.league,
            vm: { $router: router },
          }))),
        merge(oldValue: SportEventSeoMetaInfo[], newValue: SportEventSeoMetaInfo[]) {
          return [...oldValue, ...newValue];
        },
      },
    };
  });

  function clickBack(): void {
    const targetLocation = resolveSportsPageLink(sportElement.value?.sport.navigationParameters ?? {});
    router.back(targetLocation);
  }

  function onActivate(): void {
    leaguePageStore.setBackgroundUpdateEnabled(true);
    unwatchSidebarUpdateFilters = unwatchSidebarUpdateFilters ?? watch(
      sportlineReloadFiltersLocation,
      (to: BaseRouteNameType) => {
        if (!isLeaguePageRouteName(to)) { return; }
        const filter = sportlineNavigationBetweenPagesFilter.value;
        const loadConfig = leaguePageStore.configForLeagueLoading;
        const currentFilter = loadConfig?.filter;

        setSportlineReloadFiltersLocation(null);
        setSportlineNavigationBetweenPagesFilter(null);
        if (!loadConfig || !isDifferentSportDateTimeFilter(filter, currentFilter)) { return; }
        // we just change the layout but don't reload the league
        leaguePageStore.setConfigForLeagueLoading({
          ...loadConfig,
          filter: filter ? { ...filter } : undefined,
        });
      },
      { immediate: false },
    );
  }

  function onDeactivate(): void {
    unwatchSidebarUpdateFilters?.();
    unwatchSidebarUpdateFilters = null;

    leaguePageStore.setBackgroundUpdateEnabled(false);
    // clear event info after leave page (need for leave page watch in store)
    leaguePageStore.setPageIdentifier({ sportFamily: '' });
  }

  return {
    dateTimeFilter,
    isReady,
    canDisplayAnything,
    hasEvents,
    sportElement,
    outrightEvents,
    isMarketTypesSelectionEnabled,
    isLeagueStatisticEnabled,
    isEventsTabAvailable,
    isStatisticTabAvailable,
    isPromotionsTabAvailable,
    regionName,
    leagueId,
    leagueName,
    sportId,
    isEventsListEmpty,
    metaParameters,
    activeSectionId,
    clickBack,
    onActivate,
    onDeactivate,
    selectSection,
  };
}
