import type { Ref } from 'vue';
import {
  computed, nextTick, ref, watchEffect,
} from 'vue';

import { IconName } from '@leon-hub/icons';

import { useI18n } from 'web/src/modules/i18n/composables';
import type { DefaultModalProps } from 'web/src/components/Modal/DefaultModal/types';
import HighLighter from 'web/src/utils/HighLighter';
import type { VButtonProps } from 'web/src/components/Button/VButton/types';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import useDebouncedRef from 'web/src/utils/vue/useDebouncedRef';
import type { ModalRef } from 'web/src/components/Modal/types';

import type { VCountriesSelectorProps, ValidPrefixValue } from '../types';

export interface VCountriesSelectorComposable {
  popularCountries: Ref<ValidPrefixValue[]>;
  unPopularCountries: Ref<ValidPrefixValue[]>;
  visibleUnpopularCountries: Ref<ValidPrefixValue[]>;
  selectedModalProperties: Ref<DefaultModalProps>;
  searchText: Ref<string>;
  searchTextValue: Ref<string>;
  foundCountries: Ref<ValidPrefixValue[]>;
  showMoreButtonProperties: Ref<VButtonProps>;
  isShowUnPopularCountriesList: Ref<boolean>;
  modal: Ref<ModalRef | undefined>;
  toggleUnPopularCountriesList(): void;
  renderMoveUnpopularCountries(): void;
  setSearchText(value: string): void;
}

export default function useVCountriesSelector(props: VCountriesSelectorProps): VCountriesSelectorComposable {
  const { $translate } = useI18n();
  const paginationPage = ref(1);
  const searchText = useDebouncedRef('', 300);
  const isShowUnPopularCountriesList = ref(false);
  const modal = ref<ModalRef>();

  const visibleUnpopularCountries = ref<ValidPrefixValue[]>([]);

  const formattedCountries = computed(
    () => (props.countriesList ?? []).map((country) => ({
      ...country,
      phonePrefix: `+${country.phonePrefix}`,
    })),
  );

  const popularCountries = computed(
    () => formattedCountries.value.filter((country) => country.popular),
  );

  const unPopularCountries = computed(
    () => formattedCountries.value.filter((country) => !country.popular),
  );

  const foundCountries = computed(
    () => {
      const regexp = /[$.@|]/g;

      if (searchText.value && !regexp.test(searchText.value)) {
        return formattedCountries.value.filter((country) => isHighlighted(country));
      }

      return [];
    },
  );

  const searchTextValue = computed(() => {
    if (searchText.value === '+') {
      // just '+' === whole list, ignore
      return '';
    }
    return searchText.value.trim();
  });

  function isHighlighted(item: ValidPrefixValue): boolean {
    return HighLighter.isHighlighted(item.name || '', searchTextValue.value)
      || HighLighter.isHighlighted(item.phonePrefix || '', searchTextValue.value);
  }

  const selectedModalProperties = computed(
    () => ({
      ...props.modalProperties,
      isCentered: false,
      isFullHeightCentered: false,
      isCloseAsBack: true,
      isScrollBarEnabled: true,
      isFullHeight: !process.env.VUE_APP_LAYOUT_DESKTOP,
      isAlert: !!process.env.VUE_APP_LAYOUT_DESKTOP,
      topBarText: $translate('JSPACC_REG_DEALING_CODE').value,
      useScrollListener: true,
    }),
  );

  const showMoreButtonProperties = computed<VButtonProps>(() => ({
    kind: ButtonKind.TRANSPARENT,
    label: isShowUnPopularCountriesList.value ? $translate('WEB2_LABEL_HIDE').value : $translate('WEB2_SHOW_MORE').value,
    iconName: isShowUnPopularCountriesList.value ? IconName.EXPAND_UP : IconName.EXPAND_DOWN,
    iconRight: true,
    fullWidth: true,
    isUppercase: false,
  }));

  function toggleUnPopularCountriesList(): void {
    isShowUnPopularCountriesList.value = !isShowUnPopularCountriesList.value;
  }

  function renderMoveUnpopularCountries(): void {
    if (isShowUnPopularCountriesList.value
      && visibleUnpopularCountries.value.length < unPopularCountries.value.length) {
      paginationPage.value += 1;
    }
  }

  watchEffect(() => {
    const countCountries = paginationPage.value * (props.lazyRenderCount || 30);
    visibleUnpopularCountries.value = unPopularCountries.value.slice(0, countCountries);

    if (process.env.VUE_APP_LAYOUT_DESKTOP) {
      void nextTick().then(() => {
        modal.value?.scrollUpdate();
      });
    }
  });

  function setSearchText(value: string): void {
    searchText.value = value;
  }

  return {
    popularCountries,
    unPopularCountries,
    visibleUnpopularCountries,
    selectedModalProperties,
    searchText,
    searchTextValue,
    foundCountries,
    showMoreButtonProperties,
    modal,
    isShowUnPopularCountriesList,
    toggleUnPopularCountriesList,
    renderMoveUnpopularCountries,
    setSearchText,
  };
}
