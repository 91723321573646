import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import type {
  LobbyItemEmits,
  LobbyItemProps,
  LobbyItemTestType,
} from 'web/src/modules/lobby/components/LobbyItem/types';
import { GameStartMethod } from 'web/src/modules/egs/submodules/navigation/enums';

import {
  getTestType,
} from './utils';

export interface LobbyItemComposable {
  testType: ComputedRef<LobbyItemTestType>;
  onClick: () => void;
  onClickPlay: () => void;
  onClickDemo: () => void;
}

export default function useLobbyItem(
  props: LobbyItemProps,
  emits: LobbyItemEmits,
): LobbyItemComposable {
  const testType = computed<LobbyItemTestType>(() => getTestType(props));

  function onClickPlay(): void {
    emitClickLobbyItem(GameStartMethod.REAL);
  }

  function onClickDemo(): void {
    emitClickLobbyItem(GameStartMethod.DEMO);
  }

  function onClick(): void {
    emitClickLobbyItem();
  }

  function emitClickLobbyItem(selectedMethod?: GameStartMethod): void {
    emits('click-lobby-item', {
      item: props.item,
      selectedMethod,
    });
  }

  return {
    testType,
    onClick,
    onClickPlay,
    onClickDemo,
  };
}
