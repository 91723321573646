import {
  onComponentActivated,
  onComponentDeactivated,
} from '@leon-hub/vue-utils';

import { useDesktopModalStore } from 'web/src/modules/core/store';
import type { DesktopModalCloseCallback } from 'web/src/modules/core/store/types/desktopModalStore';

export function useDesktopModalCloseEvent(callback: DesktopModalCloseCallback): void {
  if (!process.env.VUE_APP_LAYOUT_DESKTOP) {
    throw new Error('Only for DESKTOP layout');
  }

  const { addCloseEventListener, removeCloseEventListener } = useDesktopModalStore();

  onComponentActivated(() => {
    addCloseEventListener(callback);
  });

  onComponentDeactivated(() => {
    removeCloseEventListener(callback);
  });
}
