import type { Ref } from 'vue';
import { ref } from 'vue';

import { BusEvent, useBusSafeSubscribe } from '@leon-hub/event-bus';

import type { VScrollbarRef } from '@components/v-scrollbar';

export interface UseDesktopModalScrollComposable {
  scrollbar: Ref<VScrollbarRef | undefined>;
  recomposeScroll(): void;
}

export default function useDesktopModalScroll(): UseDesktopModalScrollComposable {
  const scrollbar = ref<VScrollbarRef>();
  useBusSafeSubscribe(BusEvent.MODAL_SCROLL_TO_ELEMENT, ({ element, onlyIfNeeded }) => {
    if (scrollbar.value) {
      scrollbar.value.scrollToElement(element, {
        onlyIfNeeded,
      });
    } else {
      element?.scrollIntoView();
    }
  });

  function recomposeScroll(): void {
    void scrollbar.value?.recompose();
  }

  return {
    scrollbar,
    recomposeScroll,
  };
}
