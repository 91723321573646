enum TextInputTypes {
  TEXT = 'text',
  NUMBER = 'number',
  TEL = 'tel',
  HIDDEN = 'hidden',
  EMAIL = 'email',
  PASSWORD = 'password',
}

export default TextInputTypes;
