import type { RouteLocationRaw } from 'vue-router';

import { RouteName } from '@leon-hub/routing-config-names';

import type { CustomerBonusType } from 'web/src/modules/profile/submodules/bonuses/types';

const getBonusTermsLink = (bonus: CustomerBonusType): RouteLocationRaw | undefined => {
  if (!bonus.categoryId || !bonus.actionUrl) {
    if (bonus.termsKey) {
      return {
        name: RouteName.CMS_PROMO_TERMS,
        params: {
          cmsKey: bonus.termsKey,
        },
      };
    }

    return undefined;
  }

  return {
    name: RouteName.PROMOTION_DETAILS,
    params: {
      categoryId: bonus.categoryId,
      actionUrl: bonus.actionUrl,
    },
    query: {
      scrollToTerms: 'true',
    },
  };
};

export default getBonusTermsLink;
