<script lang="ts" setup>
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import HeaderLogo from 'web/src/modules/core/components/HeaderLogo/HeaderLogo.vue';
import VBrand from 'web/src/components/Brand/VBrand/VBrand.vue';
import VESportBrand from 'web/src/components/Brand/VBrand/VESportBrand.vue';
import HeaderRegistration from 'web/src/modules/core/components/HeaderRegistration/HeaderRegistration.vue';
import HeaderProfile from 'web/src/modules/core/components/HeaderProfile/HeaderProfile.vue';
import HeaderSearchButton from 'web/src/modules/core/components/HeaderSearchButton/HeaderSearchButton.vue';
import BackButton from 'web/src/modules/core/views/BackButton/BackButton.vue';
import HeaderNavigation from 'web/src/modules/core/views/HeaderNavigation/HeaderNavigation.vue';
import ThemeSwitcher from 'web/src/modules/core/views/ThemeSwitcher/ThemeSwitcher.vue';
import LanguageSwitcher from 'web/src/modules/i18n/components/LanguageSelector/components/LanguageSwitcherButton/LanguageSwitcherButton.vue';

import {
  useHeaderBarRouteComponent,
  useHeaderBarBackButton,
  useHeaderBarCustomBrand,
} from './composables';

const {
  menuOverflowHidden,
  menuButtonProperties,
  toggleSidebar,
  brandName,
  isLoggedIn,
  topNavigationItems,
  formattedBalance,
  isPromotionsHighlightLinkEnabled,
  isLandingFestivalEnabled,
  handleSportlineNavigation,
  setMenuOverflowVisible,
  searchButtonProps,
  onMouseOverSearch,
  searchClick,
  isLanguageSwitcherAvailable,
  currentLanguageFlag,
  openLanguage,
  isThemeSwitcherAvailable,
  isSidebarToggleVisible,
  toggleTheme,
  isSimplifiedLogo,
  hasCustomTheme,
} = useHeaderBarRouteComponent();
const {
  doShowBackButton,
} = useHeaderBarBackButton();
const {
  doUseESportBrand,
} = useHeaderBarCustomBrand();
</script>

<template>
  <div v-auto-id="'HeaderBarRouteComponent'" :class="$style['header-bar']">
    <div :class="$style['header-bar__inner']">
      <div
        :class="{
          [$style['header-bar__left']]: true,
          [$style['header-bar__left--overflow-hidden']]: menuOverflowHidden
        }"
      >
        <div
          v-if="!null && isSidebarToggleVisible"
          :class="$style['header-bar__menu-button']"
        >
          <BackButton
            v-if="null && doShowBackButton"
          />
          <VButton
            v-else
            v-bind="menuButtonProperties"
            @click="toggleSidebar"
          />
        </div>
        <template v-if="'1' && !null">
          <HeaderLogo>
            <VESportBrand
              v-if="'1' && doUseESportBrand"
              :alt="brandName"
              :is-simplified-logo="isSimplifiedLogo"
              is-header
            />
            <VBrand
              v-else
              :alt="brandName"
              :is-simplified-logo="isSimplifiedLogo"
              is-header
            />
          </HeaderLogo>
          <HeaderNavigation
            v-if="'1'"
            :class="$style['header-bar__menu']"
            :navigation-items="topNavigationItems"
            :balance-value="formattedBalance"
            :is-logged="isLoggedIn"
            :is-promotions-highlighted="isPromotionsHighlightLinkEnabled"
            :is-landing-festival-enabled="isLandingFestivalEnabled"
            :menu-overflow-hidden="menuOverflowHidden"
            @sportline-navigation="handleSportlineNavigation"
            @set-parent-overflow-visible="setMenuOverflowVisible"
          />
        </template>
        <template v-else>
          <HeaderLogo>
            <VESportBrand
              v-if="'1' && doUseESportBrand"
              :alt="brandName"
              :is-simplified-logo="isSimplifiedLogo"
              is-header
            />
            <VBrand
              v-else
              :alt="brandName"
              :is-simplified-logo="isSimplifiedLogo"
              is-header
            />
          </HeaderLogo>
          <div
            v-if="null"
            :class="$style['header-bar__search-btn']"
          >
            <HeaderSearchButton />
          </div>
        </template>
      </div>
      <div :class="$style['header-bar__right']">
        <div
          v-if="'1' && !null"
          :class="$style['header-bar__info']"
        >
          <VButton
            v-collect:click-counter.throttle="{ search: 'headerSearch' }"
            v-bind="searchButtonProps"
            :title="$t('JSP_PLACEHOLDER_SEARCH')"
            :class="$style['header-bar__search']"
            @mouseover="onMouseOverSearch"
            @click="searchClick"
          />
          <LanguageSwitcher
            v-if="'1'
              && !null
              && isLanguageSwitcherAvailable
              && !isLoggedIn"
            :class="$style['header-bar__lang']"
            no-background
            is-header
            :name="currentLanguageFlag"
            @open-language="openLanguage"
          />
          <div
            v-if="'1' && !hasCustomTheme && isThemeSwitcherAvailable && !isLoggedIn"
            :class="$style['header-bar__theme-switcher']"
          >
            <ThemeSwitcher
              @change="toggleTheme"
            />
          </div>
        </div>

        <HeaderProfile v-if="isLoggedIn" />
        <HeaderRegistration
          v-else
          :class="$style['header-bar__registration']"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>
@include for-layout using ($isDesktop) {
  .header-bar {
    width: 100%;
    background-color: if($isEnvFeatureDarkHeader, $headerBarDarkBackgroundColor, $headerBarDefaultBackgroundColor);
    box-shadow: if($isEnvFeatureDarkHeader, none, $headerBarDefaultBoxShadow);

    @if $isDesktop {
      height: $headerHeightDesktopSmall;

      @include screen-desktop-large-min {
        height: $headerHeightDesktopBig;
      }
    } @else {
      height: calc($headerHeightPhone + var(--StatusbarHeight));
      padding-top: var(--StatusbarHeight);
    }

    &__inner {
      display: flex;
      align-items: center;
      height: 100%;

      @if $isDesktop {
        justify-content: flex-start;
        min-width: 700px;
        max-width: $layoutDesktopMaxWidth;
        padding: $headerBarPaddingDesktopSmall;
        margin: 0 auto;

        @include screen-desktop-large-min {
          padding: $headerBarPaddingDesktopBig;
        }
      } @else {
        justify-content: space-between;
        padding: $headerBarPaddingPhone;
      }
    }

    &__left {
      flex: 1;
      overflow: visible;

      &--overflow-hidden {
        overflow: hidden;
      }
    }

    &__left,
    &__right {
      display: flex;
      align-items: center;
    }

    &__right {
      flex: none;
    }

    &__menu-button {
      margin-right: 8px;

      @if not $isDesktop {
        margin: 0;
      }

      @media (min-width: 1151px) {
        display: none;
      }

      .app__content--full-width & {
        display: none;
      }
    }

    &__info {
      @if $isDesktop {
        position: relative;
        display: flex;
        align-items: center;
      }
    }

    &__lang {
      margin: 0 8px;
    }

    &__theme-switcher {
      padding-right: 8px;
      padding-left: 8px;
    }

    &__search {
      margin: 0 10px;
    }

    &__search-btn {
      display: none;

      @if $isDesktop {
        @include screen-desktop-medium-min {
          display: block;
        }
      }
    }

    &__registration {
      @if $isDesktop {
        @include screen-desktop-small-min {
          margin-left: 16px;
        }
      }
    }
  }
}
</style>
