import type { TextInputProps } from 'web/src/components/Input';
import { TextInputTypes } from 'web/src/components/Input/enums';

import { tfaCodePattern } from './tfaCodePattern';

export const tfaCodeFieldOptions: Partial<TextInputProps> = {
  autocomplete: 'off',
  type: TextInputTypes.TEL,
  regexp: tfaCodePattern,
};
