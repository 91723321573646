import type { ComputedRef, Ref } from 'vue';
import { computed, ref } from 'vue';

import { IconName, IconSize } from '@leon-hub/icons';

import type { VIconProps } from '@components/v-icon';

import type { VButtonProps } from 'web/src/components/Button/VButton/types';
import { ButtonHeight, ButtonKind } from 'web/src/components/Button/VButton/enums';

import getShortenFileName from './utils/getShortenFileName';
import type { VFileInputPlaceholderProps } from '../types';

export interface VFileInputPlaceholderComposable {
  hasPreviewImage: Ref<boolean>;
  iconProperties: ComputedRef<VIconProps>;
  previewButtonProps: Readonly<VButtonProps>;
  fileNameText: ComputedRef<string>;
  isImageFileLayout: ComputedRef<boolean>;
  onImagePreview: () => void;
  onImagePreviewHide: () => void;
}

export default function useVFileInputPlaceholder(props: VFileInputPlaceholderProps): VFileInputPlaceholderComposable {
  const hasPreviewImage = ref(false);

  const iconProperties = computed<VIconProps>(() => ({
    name: props.hasError ? IconName.ATTENTION_OUTLINE : IconName.CHECK_OUTLINE,
    size: IconSize.SIZE_40,
  }));

  const previewButtonProps: Readonly<VButtonProps> = {
    iconName: IconName.ZOOM_IN,
    height: ButtonHeight.MEDIUM,
    kind: ButtonKind.BASE,
    rounded: true,
  };

  const fileNameText = computed<string>(() => getShortenFileName(props.name));
  const isImageFileLayout = computed<boolean>(() => !!(props.isImageFile && !props.hasError));

  function onImagePreview(): void {
    hasPreviewImage.value = true;
  }

  function onImagePreviewHide(): void {
    hasPreviewImage.value = false;
  }

  return {
    hasPreviewImage,
    iconProperties,
    previewButtonProps,
    fileNameText,
    isImageFileLayout,
    onImagePreview,
    onImagePreviewHide,
  };
}
