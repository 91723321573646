<script setup lang="ts">
import { computed } from 'vue';

import { VIcon } from '@components/v-icon';

import HighLighter from 'web/src/utils/HighLighter';
import VImage from 'web/src/components/Image/VImage/VImage.vue';
import VDynamicContent from 'web/src/components/DynamicContent/VDynamicContent/VDynamicContent.vue';
import { VCheckboxIcon } from 'web/src/components/Checkbox';
import SBadge from 'web/src/components/Badge/SBadge/SBadge.vue';
import { BadgeKind } from 'web/src/components/Badge/VBadge/enums';
import LBadge from 'web/src/components/Badge/LBadge/LBadge.vue';

import type { DropListOptionProps } from '../types';
import { buttonIconSize } from '../constants/staticProps';

interface Emits {
  (e: 'click'): void;
}

const props = withDefaults(defineProps<DropListOptionProps>(), {
  label: '',
  value: '',
  searchQuery: '',
});
defineEmits<Emits>();

const highlightedLabel = computed(() => (
  HighLighter.highlight(props.label || '', props.searchQuery || '', 'highlighted-text', true)
));
</script>

<template>
  <button v-auto-id="'DropListOption'"
    type="button"
    :class="{
      [$style['drop-list-option']]: true,
      [$style['drop-list-option--multiselect']]: isMultiselectMode,
      [$style['drop-list-option--preselected']]: isPreselected,
      [$style['drop-list-option--active']]: isActive,
      [$style['drop-list-option--filter-enabled']]: !disableHoverFilter,
      [$style['drop-list-option--background']]: withBackground,
    }"
    @click="$emit('click')"
  >
    <span
      v-if="isMultiselectMode"
      :class="$style['drop-list-option__checkbox']"
    >
      <VCheckboxIcon
        :checked="isActive"
        :is-hover="isPreselected"
      />
    </span>
    <span
      v-if="icon || imageSrc"
      :class="$style['drop-list-option__icon']"
    >
      <VImage
        v-if="imageSrc"
        :src="imageSrc"
        :class="$style['drop-list-option__img']"
      />
      <VIcon
        v-else-if="icon"
        :name="icon"
        :size="buttonIconSize"
      />
    </span>
    <span :class="$style['drop-list-option__label']">
      <VDynamicContent
        :content="highlightedLabel"
      />
    </span>
    <span
      v-if="rightText || badge"
      :class="$style['drop-list-option__right']"
    >
      <template v-if="rightText">
        {{ rightText }}
      </template>
      <template v-if="badge">
        <SBadge
          v-if="null"
          :label="badge"
          type="primary"
          is-counter
        />
        <LBadge
          v-else
          :label="badge"
          :kind="BadgeKind.SQUARE_ERROR"
        />
      </template>
    </span>
  </button>
</template>

<style module lang="scss">
.drop-list-option {
  $self: &;

  @include dropListOption;
  @include medium\14\20\025;

  display: flex;
  align-items: center;
  width: 100%;
  text-align: left;
  cursor: pointer;
  background: none;
  border: none;

  &--preselected {
    @include dropListOptionPreselected;
  }

  &--active {
    @include dropListOptionActive;
  }

  &--background {
    @include dropListOptionBackground;
  }

  &__checkbox {
    @include dropListOptionCheckbox;
  }

  &__icon {
    @include dropListOptionIcon;

    display: flex;
    flex: 0 0 auto;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    color: $inputValueErrorColorVar;
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &--filter-enabled {
    &#{$self}--preselected #{$self}__img,
    &#{$self}--active #{$self}__img {
      filter: invert(14%) sepia(95%) saturate(6678%) hue-rotate(265deg) brightness(85%) contrast(130%);
    }
  }

  &__label {
    flex: 1 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__right {
    flex: 0 0 auto;
    margin-left: 8px;
  }
}
</style>
