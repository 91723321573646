<script lang="ts" setup>
import { computed, toRef } from 'vue';

import { IconSize } from '@leon-hub/icons';

import { SportIcon } from '@components/sport-icon';

import { useSportBackgroundColor } from 'web/src/modules/sportline/composables/sport';
import { useSportlineEventCompetitors } from 'web/src/modules/sportline/composables/sportline-event';
import type { Sport, SportlineEvent } from 'web/src/modules/sportline/types';
import { colorlessSportIconColor } from 'web/src/modules/sportline/constants';

interface DoubleEventLogoProps {
  sportlineEvent?: Maybe<SportlineEvent>;
  sport?: Maybe<Sport>;
  type?: 'default' | 'bordered';
  isColorful?: boolean;
}

const props = defineProps<DoubleEventLogoProps>();

const type = toRef(props, 'type', 'default');
const sportlineEvent = toRef(props, 'sportlineEvent', null);
const sport = toRef(props, 'sport', null);
const isColorful = toRef(props, 'isColorful', false);

const { backgroundColor } = useSportBackgroundColor({ sport });
const { host, guest } = useSportlineEventCompetitors({ sportlineEvent });

const sportBackgroundColor = computed(() => (
  isColorful.value
    ? (backgroundColor.value ?? 'var(--Black)')
    : colorlessSportIconColor
));
</script>

<template>
  <div v-auto-id="'DoubleEventLogo'" :class="$style['sportline-event-logos-container']">
    <div :class="$style['sportline-event-logos']">
      <div
        :class="{
          [$style['sportline-event-logos__item']]: true,
          [$style[`sportline-event-logos__item--${type}-type`]]: true,
        }"
      >
        <div :class="$style['sportline-event-logos__logo-container']">
          <img
            v-if="host && host.logo"
            :src="host.logo"
            :alt="host.name"
            :class="$style['sportline-event-logos__logo']"
          >
          <SportIcon
            v-else-if="sport"
            :size="IconSize.SIZE_16"
            :style="{ fill: sportBackgroundColor }"
            :sport-name="sport.representation.icon"
          />
        </div>
      </div>
      <div
        :class="{
          [$style['sportline-event-logos__item']]: true,
          [$style[`sportline-event-logos__item--${type}-type`]]: true,
          [$style['sportline-event-logos__item--second']]: true,
        }"
      >
        <div :class="$style['sportline-event-logos__logo-container']">
          <img
            v-if="guest && guest.logo"
            :src="guest.logo"
            :alt="guest.name"
            :class="$style['sportline-event-logos__logo']"
          >
          <SportIcon
            v-else-if="sport"
            :size="IconSize.SIZE_16"
            :style="{ fill: sportBackgroundColor }"
            :sport-name="sport.representation.icon"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style module lang="scss">
.sportline-event-logos {
  display: flex;

  &-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__item {
    display: inline-block;
    width: 32px;
    height: 32px;
    background-color: var(--White);
    border-radius: 50%;

    &--default-type {
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15), 0 0 6px rgba(0, 0, 0, 0.1);
    }

    &--bordered-type {
      border: 1px solid var(--Layer2);

      &:first-child {
        @include z-index(above-base);
      }
    }

    &--second {
      margin-left: -8px;
    }
  }

  &__logo {
    width: 24px;
    height: 24px;
    border-radius: 50%;

    &-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
