<script setup lang="ts">
import { IconName } from '@leon-hub/icons';
import type { FocusableInputRef } from '@leon-hub/focus';
import { useAutofocus } from '@leon-hub/focus';

import { VIcon } from '@components/v-icon';

import InputButton from 'web/src/components/Input/components/InputButton/InputButton.vue';

import InputWrapper from '../InputWrapper';
import InputBorderWrapper from '../InputBorderWrapper';
import { useHintParentProps } from '../../composables';
import useFileSingleStateless from './useFileSingleStateless';
import type { FileSingleInputStatelessEmits, FileSingleInputStatelessProps } from '../../types';
import { resolveAcceptValue } from '../../utils';
import getInputIconSize from '../../utils/getInputIconSize';

const props = withDefaults(defineProps<FileSingleInputStatelessProps>(), {
  name: '',
  fileName: '',
  inputId: '',
});

const emits = defineEmits<FileSingleInputStatelessEmits>();

const { focusable, focus } = useAutofocus(props);

const {
  isHover,
  isFocus,
  isEmpty,
  resetIndex,
  formattedFileName,
  fileExtension,
  onMouseenter,
  onMouseleave,
  onFocus,
  onBlur,
  onClear,
  onClick,
  onChange,
} = useFileSingleStateless(props, emits, focusable);

const { hintProperties, hasError } = useHintParentProps(props);

const acceptValue = resolveAcceptValue();

// ok not to be reactive
const iconSize = getInputIconSize(props.isLarge);

defineExpose<FocusableInputRef>({
  focus,
});
</script>

<template>
  <InputWrapper v-auto-id="'FileSingleInputStateless'"
    v-data-test="{
      el: 'file-input',
      name: name,
    }"
    v-bind="hintProperties"
    :input-id="inputId"
    :label="label"
    :disabled="disabled"
    :is-focus="isFocus"
    :is-empty="isEmpty"
    :is-large="isLarge"
    :clickable-suffix="!isEmpty"
    :class="{
      [$style['file-single-input']]: true,
      [$style['file-single-input--error']]: hasError,
      [$style['file-single-input--focus']]: isFocus,
      [$style['file-single-input--empty']]: isEmpty,
      [$style['file-single-input--large']]: isLarge,
    }"
  >
    <template #input>
      <InputBorderWrapper
        :is-focus="isFocus"
        :is-disabled="disabled"
        :is-hover="isHover"
        :has-error="hasError"
        :class="$style['file-single-input__input-border-wrapper']"
      >
        <input
          :id="inputId"
          ref="focusable"
          :key="resetIndex"
          type="file"
          :name="name"
          :disabled="disabled"
          :accept="acceptValue"
          :class="$style['file-single-input__input']"
          @focus="onFocus"
          @blur="onBlur"
          @change="onChange"
          @input.stop.prevent
        >
        <label
          :for="inputId"
          :class="[
            $style['file-single-input__file-label'],
            $style['file-single-input__file-label--icon-suffix'],
          ]"
          @mouseenter="onMouseenter"
          @mouseleave="onMouseleave"
          @click="onClick"
        >
          <span :class="$style['file-single-input__file-name']">
            {{ formattedFileName || placeholder }}
          </span>
          <span :class="$style['file-single-input__file-extension']">
            {{ fileExtension }}
          </span>
        </label>
      </InputBorderWrapper>
    </template>
    <template #iconSuffix>
      <VIcon
        v-if="isEmpty"
        :is-large="isLarge"
        :size="iconSize"
        :name="null ? IconName.SLOTT_CLIP : IconName.CLIP"
      />
      <InputButton
        v-else
        :is-large="isLarge"
        :icon-name="null ? IconName.SLOTT_TRASH : IconName.BIN"
        @click="onClear"
      />
    </template>
  </InputWrapper>
</template>

<style lang="scss" module>
.file-single-input {
  @import '../../common';

  $self: &;

  &__input {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    overflow: hidden;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    border: 0;
    opacity: 0;

    &:active,
    &:focus {
      position: absolute;
    }

    &:disabled {
      opacity: 0;
    }
  }

  &__input-border-wrapper {
    position: relative;

    // due to absolute position of input
    // size of InputBorderWrapper
    height: $inputHeight;

    #{$self}--large & {
      height: $inputHeightLarge;
    }
  }

  &__file-label {
    @include z-index(base);
    @include inputCommon;

    position: absolute;
    display: flex;
    align-items: center;
    height: 100%;
    overflow: hidden;

    &--icon-suffix {
      padding-right: $inputIconWidth;

      #{$self}--large & {
        padding-right: $inputIconWidthLarge;
      }
    }

    #{$self}--error:not(#{$self}--focus) & {
      color: $inputValueErrorColor;
    }

    // placeholder
    #{$self}--empty & {
      color: $inputPlaceholderDefaultColor;
    }

    // placeholder with error
    #{$self}--empty#{$self}--error:not(#{$self}--focus) & {
      color: $inputPlaceholderErrorColor;
    }
  }

  &--large &__file-label {
    @include inputLarge;
  }

  &__file-name {
    flex: 0 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__file-extension {
    flex: none;
  }
}
</style>
