import { useRouter } from 'vue-router';

import { PostMessageBus } from '@leon-hub/postmessage-bus';
import { logger } from '@leon-hub/logging';
import { RouteName } from '@leon-hub/routing-config-names';

import { isIframe } from 'web/src/utils/browser';
import PaymentsPostMessageEvent from 'web/src/modules/payments/utils/PaymentsPostMessageEvent';

import type { PaymentsDepositsOkFailControllerProps } from '../types';

export interface PaymentsDepositsOkFailControllerComposable {
  beforeMount: () => void;
  beforeUnmount: () => void;
  onClick: () => void;
}

// eslint-disable-next-line max-len
export default function usePaymentsDepositsOkFailController(props: PaymentsDepositsOkFailControllerProps): PaymentsDepositsOkFailControllerComposable {
  const router = useRouter();

  let postMessageBus: PostMessageBus | null = null;

  function beforeMount(): void {
    try {
      postMessageBus = new PostMessageBus({
        target: window.parent,
        targetOrigin: '*',
        initiator: 'PaymentsDepositsOkFailController',
      });
    } catch (error) {
      logger.error(`Couldn't create postmessage bus: ${error}`);
    }
  }

  function beforeUnmount(): void {
    if (postMessageBus) {
      postMessageBus.dispose();
    }
  }

  function onClick(): void {
    if (props.closeWindow && !process.env.VUE_APP_BROWSER_SAFARI) {
      window.close();
    } else if (isIframe() && postMessageBus) {
      postMessageBus.emit(PaymentsPostMessageEvent.depositok, { status: true });
    } else if (process.env.VUE_APP_LAYOUT_DESKTOP) {
      void router.closeModal();
    } else {
      void router.push({ name: RouteName.HOME });
    }
  }

  return {
    beforeMount,
    beforeUnmount,
    onClick,
  };
}
