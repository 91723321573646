/* eslint-disable class-methods-use-this */
import { CasinoRouteName } from '@leon-hub/routing-config-names';

import { AppModule } from 'web/src/modules/core/plugins/AppModule';
import type {
  AppVueRouter,
  BaseTopBarRouteConfig,
  BlankRouteConfig,
  DefaultRouteConfig,
} from 'web/src/modules/core/services/router/types';

function getCasinoGameDefaultRoute({
  name,
  path,
}: Pick<DefaultRouteConfig, 'name' | 'path'>): BlankRouteConfig {
  return {
    name,
    path,
    prefetch: () => import('./prefetch/CasinoGamePagePrefetch'),
    component: () => import('./pages/CasinoGameRoutePage/CasinoGameRoutePage.vue'),
  };
}

function getCasinoGameTopBarRoute(options: Pick<BaseTopBarRouteConfig, 'name' | 'path'>): BaseTopBarRouteConfig {
  if (!process.env.VUE_APP_LAYOUT_PHONE) {
    throw new Error('Only for PHONE layout');
  }

  return {
    ...getCasinoGameDefaultRoute(options),
    topBar: () => import('./views/CasinoGameTopBarRouteComponent/CasinoGameTopBarRouteComponent.vue'),
    meta: {
      transitionTo: 'overlay-in',
      transitionFrom: 'overlay-out',
    },
  };
}

function addCasinoGameRoute(router: AppVueRouter, options: Pick<BaseTopBarRouteConfig, 'name' | 'path'>): void {
  if (process.env.VUE_APP_LAYOUT_DESKTOP) {
    router.addDefaultRoute(getCasinoGameDefaultRoute(options));
  }

  if (process.env.VUE_APP_LAYOUT_PHONE) {
    router.addModalRoute(getCasinoGameTopBarRoute(options));
  }
}

export class CasinoGameModule extends AppModule {
  addRoutes(router: AppVueRouter): void {
    addCasinoGameRoute(router, {
      name: CasinoRouteName.CASINO_GAME,
      path: '/casino/:groupUrl/play/:gameUrl',
    });

    addCasinoGameRoute(router, {
      name: CasinoRouteName.CASINO_LIVE_GAME,
      path: '/live-casino/:groupUrl/play/:gameUrl',
    });

    router.addPrefetchRoute({
      name: 'old games',
      path: '/slots/game/:id',
      prefetch: () => import('./prefetch/CasinoSlotsGamesPrefetchOld'),
    });

    router.addPrefetchRoute({
      name: 'old games v2',
      path: '/slots/:groupUrl/play/:gameUrl',
      prefetch: () => import('./prefetch/CasinoSlotsGamePrefetchOldV2'),
    });

    router.addBlankRoute({
      name: CasinoRouteName.GAME_EXIT,
      path: '/game-exit',
      component: () => import('./pages/CasinoExitRoutePage/CasinoExitRoutePage.vue'),
    });
  }
}
