/* eslint-disable unicorn/prefer-add-event-listener */
import { normalizeError } from '@leon-hub/errors';

import FileReaderError from './errors/FileReaderError';

export enum DataType {
  TEXT = 'TEXT',
  BASE64 = 'BASE64',
}

export function readFileContent<T>(file: File, dataType?: DataType): Promise<T | null> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    const loadCallback = () => {
      if (file.name === 'b5c0cf02-9d4c-4408-8b87-23378a834f7c.png') { // for test purposes
        reject(
          new FileReaderError({
            originalError: normalizeError(new Error('Test FileReader error')),
          }),
        );

        return;
      }

      resolve(reader.result as unknown as T);
    };
    const abortCallback = () => reject(new FileReaderError());
    const errorCallback = () => reject(
      new FileReaderError({
        originalError: normalizeError(reader.error || new Error('Unknown FileReader error')),
      }),
    );

    if (process.env.VUE_APP_PLATFORM_WEB) {
      reader.addEventListener('load', loadCallback);
      reader.addEventListener('abort', abortCallback);
      reader.addEventListener('error', errorCallback);
    }

    if (process.env.VUE_APP_PLATFORM_CORDOVA) {
      reader.onload = loadCallback;
      reader.onabort = abortCallback;
      reader.onerror = errorCallback;
    }

    switch (dataType) {
      case DataType.BASE64:
        reader.readAsDataURL(file);
        break;

      case DataType.TEXT:
      default:
        reader.readAsText(file);
        break;
    }
  });
}

export default {
  DataType,
  readFileContent,
};
