import { logger } from '@leon-hub/logging';
import { Timer } from '@leon-hub/utils';
import { assert } from '@leon-hub/guards';
import { onWindowVisibilityChanged } from '@leon-hub/browser-composables';

import type {
  WebSocketGraphQLMethod,
} from '../types';
import type WebSocketSubscription from './WebSocketSubscription';
import { isWSSupported } from '../helpers';
import WebSocketHolder from './WebSocketHolder';

export default class GraphQLWebSocketService {
  private isEnabled = isWSSupported();

  private socket: WebSocketHolder | null = null;

  private subscriptions: Record<string, WebSocketSubscription<WebSocketGraphQLMethod>> = {};

  private sockets: WebSocketHolder[] = [];

  private connectionDate = 0;

  private reconnectTimeout = 5000;

  private connectionTimer = 0;

  constructor() {
    window.addEventListener('beforeunload', () => {
      void this.disconnect();
      this.checkConnections();
    });

    onWindowVisibilityChanged((isVisible) => {
      if (isVisible) {
        void this.callPollingRequests();
        void this.reconnectInternal(false);
      }
    }).addVisibilityChangeEventListener();
  }

  protected isSupported(): boolean {
    return this.isEnabled;
  }

  private async initConnection(reconnect = false, force = false): Promise<void> {
    if (!this.hasWsEnabledSubscription()) {
      return;
    }

    if (this.connectionTimer) {
      Timer.clearTimeout(this.connectionTimer);
      this.connectionTimer = 0;
      return;
    }

    const diffDate = Date.now() - this.connectionDate;

    if (force || (diffDate > this.reconnectTimeout)) {
      await this.initSocket(reconnect);
    } else {
      this.connectionTimer = Timer.setTimeout(() => {
        void this.initSocket(reconnect);
        this.connectionTimer = 0;
      }, this.reconnectTimeout - diffDate);
    }
  }

  private initSocket(reconnect = false): Promise<void> {
    return new Promise<void>((resolve) => {
      if (!this.socket || reconnect) {
        for (const socket of this.sockets) {
          if (!socket.isAckReceived()) {
            void socket.disconnect();
          }
        }

        this.connectionDate = Date.now();
        const newSocket = new WebSocketHolder({
          onError: () => {
            this.checkConnections();
          },
          onReconnect: () => {
            void this.reconnectInternal(false);
          },
          onConnectionAck: async () => {
            await this.socket?.disconnect();
            this.socket = newSocket;
            assert(newSocket);
            for (const subscription of Object.values(this.subscriptions)) {
              if (subscription.isWebSocketsEnabled()) {
                newSocket.subscribe(subscription.getWsSubscriptionInput());
              }
            }
            this.checkConnections();
            resolve();
          },
          onDisconnect: () => {
            this.sockets = this.sockets.filter((socket) => socket !== newSocket);
            this.checkConnections();
            resolve();
          },
        });
        this.sockets.push(newSocket);
      } else {
        resolve();
      }
    });
  }

  private checkConnections(): void {
    if (this.sockets.some((socket) => socket.isAckReceived())) {
      this.stopAllPollingRequests();
    } else {
      this.startAllPollingRequests();
    }
  }

  connect(): Promise<void> {
    return this.initConnection(true, true);
  }

  setEnabled(isEnabled: boolean): void {
    this.isEnabled = isEnabled;
    if (!this.isEnabled) {
      void this.disconnect();
      for (const subscription of Object.values(this.subscriptions)) {
        subscription.startPollingRequest();
      }
    } else {
      for (const subscription of Object.values(this.subscriptions)) {
        this.initSubscription(subscription);
      }
    }
  }

  subscribe<T extends WebSocketGraphQLMethod>(subscription: WebSocketSubscription<T>): void {
    if (this.isSubscribed(subscription)) {
      logger.warn(`[WebSocket] Already subscribed to event ${subscription.method}. Please unsubscribe first.`);
      return;
    }

    this.subscriptions[subscription.method] = subscription;

    this.initSubscription(subscription);

    if (this.socket?.isAckReceived()) {
      subscription.stopPollingRequest();
    }
  }

  private initSubscription<T extends WebSocketGraphQLMethod>(subscription: WebSocketSubscription<T>): void {
    if (this.isSupported() && subscription.isWebSocketsEnabled()) {
      if (!this.sockets.length) {
        void this.connect();
      }

      for (const socket of this.sockets) {
        socket.subscribe(subscription.getWsSubscriptionInput());
      }
    } else {
      subscription.startPollingRequest();
    }
  }

  private hasWsEnabledSubscription(): boolean {
    return !!Object.values(this.subscriptions).some((subscription) => subscription.isWebSocketsEnabled());
  }

  unsubscribe<T extends WebSocketGraphQLMethod>(subscription: WebSocketSubscription<T>): void {
    for (const socket of this.sockets) {
      socket.unsubscribe({ id: subscription.method });
    }

    subscription.stopPollingRequest();
    if (this.subscriptions[subscription.method]) {
      delete this.subscriptions[subscription.method];
    }

    if (!this.hasWsEnabledSubscription()) {
      void this.disconnect();
    }
  }

  async disconnect(): Promise<void> {
    if (this.connectionTimer) {
      Timer.clearTimeout(this.connectionTimer);
      this.connectionTimer = 0;
    }

    await Promise.all(
      this.sockets.map((socket) => socket.disconnect()),
    );
  }

  reconnectInternal(force: boolean): Promise<void> {
    return this.initConnection(true, force);
  }

  reconnect(): Promise<void> {
    return this.reconnectInternal(true);
  }

  isSubscribed<T extends WebSocketGraphQLMethod>(subscription: WebSocketSubscription<T>): boolean {
    return !!this.subscriptions[subscription.method];
  }

  private stopAllPollingRequests() {
    for (const subscription of Object.values(this.subscriptions)) {
      if (subscription.isWebSocketsEnabled()) {
        subscription.stopPollingRequest();
      }
    }
  }

  private startAllPollingRequests() {
    for (const subscription of Object.values(this.subscriptions)) {
      subscription.startPollingRequest();
    }
  }

  private callPollingRequests(): void {
    for (const subscription of Object.values(this.subscriptions)) {
      subscription.callPollingRequest();
    }
  }
}
