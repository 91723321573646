<script lang="ts" setup>
import type {
  RouteLocationNamedRaw,
} from 'vue-router';

import { IconName, IconSize } from '@leon-hub/icons';

import { VIcon } from '@components/v-icon';

import VBadge from 'web/src/components/Badge/VBadge/VBadge.vue';
import VCover from 'web/src/components/Cover/components/VCover.vue';
import { VSidebar } from 'web/src/components/Sidebar';
import VRouterLink from 'web/src/components/Link/VRouterLink/VRouterLink.vue';
import type { MenuLevelClickPayload, SidebarMenu } from 'web/src/components/SidebarMenu/types';
import type { TwoStateIndicatorConfig } from 'web/src/components/_shared';
import type TheSidebarMenuOptions from 'web/src/components/SidebarMenu/TheSidebarMenu/types/TheSidebarMenuOptions';
import { useTheSidebarMenu } from 'web/src/components/SidebarMenu/TheSidebarMenu/composables';
import { isValidLocation } from 'web/src/components/SidebarMenu/utils';

interface TheSidebarMenuProps {
  menu: SidebarMenu;
  replaceRoutes?: boolean;
  noMargins?: boolean;
}

interface TheSidebarMenuEmits {
  (e: 'before-navigate', data: { event: MouseEvent; to:RouteLocationNamedRaw; payload: MenuLevelClickPayload }): void;
  (e: 'level-click', data: { event: MouseEvent; payload: MenuLevelClickPayload }): void;
}

const props = defineProps<TheSidebarMenuProps>();

const emit = defineEmits<TheSidebarMenuEmits>();

const levelIndicatorProperties: Readonly<TwoStateIndicatorConfig> = [
  {
    size: IconSize.SIZE_16,
    name: IconName.EXPAND_DOWN,
  },
  {
    size: IconSize.SIZE_16,
    name: IconName.EXPAND_UP,
  },
];

const defaultL1Class = 'menu-level-1-spoiler__header-icon';
const defaultL1InnerClass = 'menu-level-1-spoiler__header-inner';

const options: TheSidebarMenuOptions = {
  defaultL1Class,
  defaultL1InnerClass,
};

const {
  emitMenuLevelClick,
  emitBeforeNavigateClick,
  doLevelClick,
  beforeLevelOneClick,
} = useTheSidebarMenu(props, emit, options);

// TODO: vue3 bug? lost data-v- attributes
</script>

<template>
  <VSidebar v-auto-id="'TheSidebarMenu'"
    :class="{
      [$style['sidebar--menu']]: true,
    }"
  >
    <slot name="top" />
    <VCover
      v-for="firstLevelElement in menu"
      :key="firstLevelElement.key"
      :opened="firstLevelElement.active"
    >
      <template #default="scopeLvl1">
        <div
          v-data-test="{el: 'sidebar-el-l1', item: firstLevelElement.id }"
          :class="{
            [$style['menu-level-1-spoiler']]: true,
            [$style['menu-level-1-spoiler--sidebar']]: true,
            [$style['menu-level-1-spoiler--open']]: scopeLvl1.isOpen && firstLevelElement.canOpen,
            [$style['menu-level-1-spoiler--space-bottom']]:
              firstLevelElement.props && !!firstLevelElement.props.addBottomPadding
          }"
        >
          <div
            :class="{
              [$style['menu-level-1-spoiler__header']]: true,
              [$style['menu-level-1-spoiler__header--open']]: scopeLvl1.isOpen
            }"
          >
            <div
              :class="$style[defaultL1InnerClass]"
              @click="emitMenuLevelClick($event, { level: 1, id: firstLevelElement.id})"
            >
              <slot name="icon">
                <div
                  v-if="firstLevelElement.icon"
                  :class="$style[defaultL1Class]"
                  :style="firstLevelElement.props && firstLevelElement.props.iconStyle
                    ? firstLevelElement.props.iconStyle
                    : {}
                  "
                >
                  <VIcon
                    :class="$style['menu-level-1-spoiler__header-icon-svg']"
                    :name="firstLevelElement.icon.name"
                  />
                </div>
              </slot>
              <a
                v-if="firstLevelElement.externalUrl"
                :class="{
                  [$style['menu-level-1-spoiler__title']]: true,
                  [$style['menu-level-1-spoiler__title--open']]: scopeLvl1.isOpen,
                  [$style['menu-level-1-spoiler__title--has-icon']]: firstLevelElement.icon
                }"
                :href="firstLevelElement.externalUrl"
                target="_blank"
              >
                {{ firstLevelElement.name }}
              </a>
              <VRouterLink
                v-else-if="firstLevelElement.location && isValidLocation(firstLevelElement.location)"
                :replace="replaceRoutes"
                :class="{
                  [$style['menu-level-1-spoiler__title']]: true,
                  [$style['menu-level-1-spoiler__title--open']]: scopeLvl1.isOpen,
                  [$style['menu-level-1-spoiler__title--has-icon']]: firstLevelElement.icon
                }"
                :to="firstLevelElement.location"
                @before-navigate="emitBeforeNavigateClick(
                  $event.originalEvent,
                  $event.to,
                  { level: 1, id: firstLevelElement.id },
                )"
                @click="beforeLevelOneClick($event, scopeLvl1.open, firstLevelElement.location)"
              >
                {{ firstLevelElement.name }}
              </VRouterLink>
              <div
                v-else
                :class="{
                  [$style['menu-level-1-spoiler__title']]: true,
                  [$style['menu-level-1-spoiler__title--open']]: scopeLvl1.isOpen,
                  [$style['menu-level-1-spoiler__title--has-icon']]: firstLevelElement.icon
                }"
                @click="scopeLvl1.isOpen ? () => {} : scopeLvl1.toggle"
              >
                {{ firstLevelElement.name }}
              </div>
            </div>
            <VBadge
              v-if="firstLevelElement.badge"
              :class="$style['menu-level-1-spoiler__badge']"
              :label="firstLevelElement.badge"
            />
            <span
              v-if="firstLevelElement.suffixText"
              :class="$style['menu-level-1-spoiler__suffix-text']"
            >
              {{ firstLevelElement.suffixText }}
            </span>
            <span
              v-if="firstLevelElement.canOpen"
              :class="$style['menu-level-1-spoiler__indicator']"
              @click="scopeLvl1.toggle"
            >
              <VIcon v-bind="scopeLvl1.isOpen ? levelIndicatorProperties[1] : levelIndicatorProperties[0]" />
            </span>
            <span
              v-else-if="!!firstLevelElement.suffix"
              :class="$style['menu-level-1-spoiler__suffix']"
            >
              <VIcon v-bind="firstLevelElement.suffix" />
            </span>
            <span
              v-else
              :class="[
                $style['menu-level-1-spoiler__indicator'],
                $style['menu-level-1-spoiler__indicator--empty'],
              ]"
            />
          </div>

          <div
            v-if="firstLevelElement.canOpen && scopeLvl1.isLoaded"
            v-show="scopeLvl1.isOpen"
            :class="$style['menu-level-1-spoiler__content']"
          >
            <VCover
              v-for="secondLevelElement in firstLevelElement.menu"
              :key="secondLevelElement.key"
              :opened="secondLevelElement.active"
            >
              <template #default="scopeLvl2">
                <div
                  :class="{
                    [$style['menu-level-2-spoiler']]: true,
                    [$style['menu-level-2-spoiler--open']]: scopeLvl2.isOpen
                  }"
                >
                  <div
                    :class="{
                      [$style['menu-level-2-spoiler__header']]: true,
                      [$style['menu-level-2-spoiler__header--open']]: scopeLvl2.isOpen
                    }"
                  >
                    <a
                      v-if="secondLevelElement.externalUrl"
                      :class="{
                        [$style['menu-level-2-spoiler__title']]: true,
                        [$style['menu-level-2-spoiler__title--open']]: scopeLvl2.isOpen
                      }"
                      :href="secondLevelElement.externalUrl"
                      target="_blank"
                    >
                      {{ secondLevelElement.name }}
                    </a>
                    <VRouterLink
                      v-else-if="secondLevelElement.location"
                      :replace="replaceRoutes"
                      :class="{
                        [$style['menu-level-2-spoiler__title']]: true,
                        [$style['menu-level-2-spoiler__title--open']]: scopeLvl2.isOpen
                      }"
                      :to="secondLevelElement.location"
                      @before-navigate="emitBeforeNavigateClick(
                        $event.originalEvent,
                        $event.to,
                        { level: 2, id: secondLevelElement.id },
                      )"
                      @click="beforeLevelOneClick(
                        $event,
                        scopeLvl2.open,
                        secondLevelElement.location,
                        { level:2, id: secondLevelElement.id },
                      )"
                    >
                      {{ secondLevelElement.name }}
                    </VRouterLink>
                    <div
                      v-else
                      :class="{
                        [$style['menu-level-2-spoiler__title']]: true,
                        [$style['menu-level-2-spoiler__title--open']]: scopeLvl2.isOpen
                      }"
                      @click="doLevelClick($event, scopeLvl2.toggle, { level:2, id: secondLevelElement.id})"
                    >
                      {{ secondLevelElement.name }}
                    </div>

                    <span
                      v-if="!!secondLevelElement.menu.length"
                      :class="$style['menu-level-2-spoiler__indicator']"
                      @click="scopeLvl2.toggle"
                    >
                      <VIcon v-bind="scopeLvl2.isOpen ? levelIndicatorProperties[1] : levelIndicatorProperties[0]" />
                    </span>
                    <span
                      v-else-if="!!secondLevelElement.suffix"
                      :class="$style['menu-level-2-spoiler__suffix']"
                    >
                      <VIcon v-bind="secondLevelElement.suffix" />
                    </span>
                  </div>

                  <div
                    v-if="scopeLvl2.isLoaded && !!secondLevelElement.menu.length"
                    v-show="scopeLvl2.isOpen"
                    :class="$style['menu-level-2-spoiler__content']"
                  >
                    <ul
                      :class="{
                        [$style['menu-level-3-spoiler']]: true,
                        [$style['menu-level-3-spoiler--open']]: scopeLvl2.isOpen
                      }"
                    >
                      <li
                        v-for="thirdLevelElement in secondLevelElement.menu"
                        :key="thirdLevelElement.id"
                        :class="$style['menu-level-3-item']"
                      >
                        <a
                          v-if="thirdLevelElement.externalUrl"
                          :class="{
                            [$style['menu-level-3-item__link']]: true,
                            [$style['menu-level-3-item--active']]: thirdLevelElement.active ,
                          }"
                          :href="thirdLevelElement.externalUrl"
                          target="_blank"
                        >
                          {{ thirdLevelElement.name }}
                        </a>
                        <VRouterLink
                          v-else
                          :replace="replaceRoutes"
                          :class="{
                            [$style['menu-level-3-item__link']]: true,
                            [$style['menu-level-3-item--active']]: thirdLevelElement.active
                          }"
                          :to="thirdLevelElement.location"
                          @before-navigate="emitBeforeNavigateClick(
                            $event.originalEvent,
                            $event.to,
                            { level: 3, id: thirdLevelElement.id },
                          )"
                        >
                          <span
                            :class="{
                              [$style['menu-level-3-item__name']]: true,
                              [$style['menu-level-3-item__name--active']]: thirdLevelElement.active
                            }"
                            @click="emitMenuLevelClick($event, { level: 3, id: thirdLevelElement.id })"
                          >
                            {{ thirdLevelElement.name }}
                          </span>
                        </VRouterLink>
                        <span
                          v-if="!!thirdLevelElement.suffix"
                          :class="$style['menu-level-3-spoiler__suffix']"
                        >
                          <VIcon v-bind="thirdLevelElement.suffix" />
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </template>
            </VCover>
          </div>
        </div>
      </template>
    </VCover>
    <slot name="bottom" />
  </VSidebar>
</template>

<style lang="scss" module>
@include for-layout using($isDesktop) {
  .sidebar--menu {
    margin: 0 0 8px;

    &--no-margins {
      margin: 0;
    }
  }

  .menu-level-1-spoiler {
    user-select: none;

    &__flag {
      width: 22px;
      height: 16px;
      margin-left: 8px;
      background-color: lightblue;
      background-image: url('~web/src/assets/logotypes/common/ruFlag.svg');
      border-radius: 2px;
    }

    &__cover {
      margin: if($isDesktop, 0 0 4px, 0 -4px 4px);
      margin-bottom: 4px;
      overflow: hidden;

      @if $isDesktop {
        padding: 0 8px;
      }
    }

    &--open {
      margin-bottom: 8px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &__header {
      display: flex;
      align-items: center;
      text-decoration: none;
      cursor: pointer;
      border-radius: 5px;
      box-shadow: none;

      &-inner {
        display: flex;
        flex: 1;
        align-items: center;
        height: 100%;
      }

      &-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 28px;
        height: 28px;
        margin: 0 8px;
        border-radius: 5px;

        &-svg {
          fill: var(--DBrandText);
        }
      }
    }

    &__title {
      @include medium\14\16\025;

      display: inline-block;
      flex: 1;
      padding: 14px 12px;
      overflow: hidden;
      color: var(--TextDefault);
      text-decoration: none;
      text-overflow: ellipsis;
      vertical-align: top;

      &--open {
        color: var(--Layer1);
      }

      &--has-icon {
        padding-left: 0;
      }
    }

    &__link-wrapper {
      width: 100%;
    }

    &__indicator {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 14px;

      &--empty {
        padding: 20px 12px 20px 0;
      }
    }

    &__suffix {
      display: flex;
      align-self: flex-start;
      justify-content: center;
      min-width: 32px;
      margin: 12px 0;
    }

    &--default {
      .menu-level-1-spoiler__header {
        padding: 0 4px;
        color: var(--TextDefault);
        background-color: var(--Layer1);

        &--open {
          color: var(--Layer1);
          border-radius: 5px 5px 0 0;
        }
      }

      .menu-level-1-spoiler__title {
        padding: 14px 0 14px 8px;
      }
    }

    &--sidebar {
      margin-bottom: 4px;
      border-radius: 5px;

      &:hover {
        background-color: var(--Layer1);
      }

      .menu-level-1-spoiler__header {
        &--open {
          color: var(--TextDefault);
          background-color: var(--Layer1);
          border-radius: 5px;

          & .menu-level-1-spoiler__title,
          & .menu-level-1-spoiler__indicator {
            color: var(--TextDefault);
          }
        }
      }

      .menu-level-1-spoiler__title {
        @include medium\14\16\025;

        color: var(--TextPrimary);

        &--open {
          color: var(--TextDefault);
        }
      }

      .menu-level-1-spoiler__indicator {
        height: 44px;
        color: var(--TextPrimary);

        &--empty {
          display: none;
        }
      }

      &.menu-level-1-spoiler--open {
        overflow: hidden;
        background-color: var(--Layer1);
        border-radius: 5px;
      }
    }

    &--space-bottom {
      margin-bottom: 16px;
    }

    &__badge {
      margin-right: 12px;
    }

    &__progress {
      width: 32px;
      height: 32px;
      margin-right: 8px;
    }

    &__suffix-text {
      @include regular\14\16\025;

      margin: 0 12px 0 10px;
      color: var(--TextPrimary);
    }
  }

  .menu-level-2-spoiler {
    margin: 0 4px 4px;
    user-select: none;
    border-radius: 5px;

    &__header {
      display: flex;
      align-items: center;
      color: var(--TextDefault);
      cursor: pointer;
      border-radius: 5px;
      box-shadow: none;

      &:hover {
        @if $isDesktop {
          color: var(--TextDefault);
          background-color: var(--Layer2);
        }
      }

      &--open {
        background-color: var(--Layer2);

        & .menu-level-2-spoiler__title {
          color: var(--TextDefault);
        }

        & .menu-level-2-spoiler__indicator {
          color: var(--TextDefault);
        }
      }
    }

    &__title {
      @include medium\14\16\025;

      display: inline-block;
      flex: 1;
      padding: 12px 8px 12px 12px;
      overflow: hidden;
      color: var(--TextPrimary);
      text-decoration: none;
      text-overflow: ellipsis;
      vertical-align: top;

      &:hover {
        color: var(--TextPrimary);
      }

      &--open {
        & + .menu-level-2-spoiler__indicator {
          color: var(--TextDefault);
        }
      }
    }

    &__indicator {
      display: flex;
      align-items: center;
      height: 40px;
      padding-left: 16px;
      margin-right: 10px;
      color: var(--TextPrimary);
    }

    &__suffix {
      display: flex;
      align-self: flex-start;
      padding: 12px 12px 12px 0;
    }

    &--open {
      background-color: var(--Layer2);
    }

    &__content {
      padding-bottom: 4px;
    }
  }

  .menu-level-3-spoiler {
    padding: 4px 4px 0;
    margin: 0;
    font-size: 12px;
    line-height: 16px;
    list-style: none;

    & .menu-level-3-item:hover {
      border-radius: 5px;

      @if $isDesktop {
        background-color: var(--Highlight);
      }
    }

    &__indicator {
      display: flex;
      align-items: center;
      width: 40px;
      height: 40px;
      padding-left: 16px;
      color: var(--TextPrimary);
    }
  }

  .menu-level-3-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 4px;
    color: var(--TextPrimary);

    &:last-child {
      margin-bottom: 0;
    }

    &--active {
      @include medium\14\16\025;

      position: relative;
      color: var(--TextDefault);
      background-color: var(--Highlight);
      border-radius: 5px;
    }

    &__name {
      @include medium\14\16\025;

      display: inline-block;
      flex: 1;
      align-items: center;
      padding: 12px;
      overflow: hidden;
      color: var(--TextPrimary);
      text-overflow: ellipsis;

      &--active {
        color: var(--TextDefault);
      }
    }

    &__link {
      display: flex;
      flex: 1;
      width: 100%;
      height: 100%;
      text-decoration: none;
    }
  }
}
</style>
