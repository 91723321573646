import type { BuiltinConfig } from '@leon-hub/builtin-config';
import { createServiceLocatorKey } from '@leon-hub/service-locator';

export const HTTPS = createServiceLocatorKey(('HTTPS')).withType<boolean>();
export const LOCATION_HREF = createServiceLocatorKey(('LOCATION_HREF')).withType<string>();
export const LOCATION_ORIGIN = createServiceLocatorKey(('LOCATION_ORIGIN')).withType<string>();
export const LOCATION_HOST = createServiceLocatorKey(('LOCATION_HOST')).withType<string>();
export const LOCATION_PATHNAME = createServiceLocatorKey(('LOCATION_PATHNAME')).withType<string>();
export const LOCATION_HASH = createServiceLocatorKey(('LOCATION_HASH')).withType<string>();
export const LOCATION_SEARCH = createServiceLocatorKey(('LOCATION_SEARCH')).withType<string>();
export const REFERRER = createServiceLocatorKey(('REFERRER')).withType<string>();
export const COOKIES = createServiceLocatorKey(('COOKIES')).withType<string>();
export const USER_AGENT = createServiceLocatorKey(('USER_AGENT')).withType<string>();
export const BUILTIN_CONFIG = createServiceLocatorKey(('BUILTIN_CONFIG')).withType<BuiltinConfig>();
export const QUERY_PARAMS = createServiceLocatorKey(
  'QUERY_PARAMS',
).withType<Readonly<Partial<Record<string, string>>>>();
export const ONLINE_STATUS = createServiceLocatorKey(('ONLINE_STATUS')).withType<boolean>();
