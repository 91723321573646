import FastTrackApiClient from './FastTrackApiClient';

let ftApiClient: FastTrackApiClient | undefined;

export default function useFastTrackApiClient(): FastTrackApiClient {
  if (!ftApiClient) {
    ftApiClient = new FastTrackApiClient();
  }

  return ftApiClient;
}
