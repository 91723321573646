import type { LeaderBoardProps } from 'web/src/modules/promotions/components/LeaderBoard/types';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import type { VButtonProps } from 'web/src/components/Button/VButton/types';
import type { I18nFunctionReactive } from 'web/src/modules/i18n/types';

export default function getShowMoreButtonProperties(
  props: Pick<LeaderBoardProps, 'endOfList' | 'isLoading'>,
  $t: I18nFunctionReactive,
): VButtonProps {
  return {
    fullWidth: true,
    disabled: props.endOfList,
    label: props.isLoading ? $t('JS_LOADING_M').value : $t('SLOTS_V2_SHOW_MORE_DESKTOP').value,
    isLoading: props.isLoading,
    kind: ButtonKind.BASE,
  };
}
