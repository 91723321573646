import { bannerVersionV3 } from '@leon-hub/api-sdk';

import type { VHeadlineBannerProps } from 'web/src/components/HeadlineBanner/VHeadlineBanner/types';

export default function getBannerWrapperStyles(
  props: VHeadlineBannerProps,
  backgroundHeight: number,
  isFrontImageAvailable: boolean,
  hasContentBlock: boolean,
): Record<string, string | undefined> {
  const styles: Record<string, string | undefined> = {
    backgroundColor: props.backgroundColor,
  };

  if (backgroundHeight > 0 && !isFrontImageAvailable) {
    if (!!process.env.VUE_APP_FEATURE_BANNERS_UI_V3_ENABLED && props.bannerVersion === bannerVersionV3 && hasContentBlock) {
      styles.height = 'auto';
    } else {
      styles.height = `${backgroundHeight}px`;
    }
  }

  return styles;
}
