import type { Ref } from 'vue';
import { computed } from 'vue';

import type { Web2Image } from '@leon-hub/api-sdk';

import { useSportlineBackgroundColor } from 'web/src/modules/sportline/composables/representation';
import type { Sport } from 'web/src/modules/sportline/types';
import type { BetlineLeagueRepresentationData } from 'web/src/modules/sportline/submodules/core-statistic/types';

interface UseLeagueRepresentationProps {
  sport: Ref<Maybe<Sport>>;
  leagueRepresentationData: Ref<Maybe<BetlineLeagueRepresentationData>>;
}

interface UseLeagueRepresentationComposable {
  backgroundColor: Ref<Maybe<string>>;
  backgroundImage: Ref<Maybe<Web2Image>>;
}

export function useLeagueRepresentation(
  props: UseLeagueRepresentationProps,
): UseLeagueRepresentationComposable {
  const { sport, leagueRepresentationData } = props;

  const background = computed(() => leagueRepresentationData.value?.background
    ?? sport.value?.representation.background
    ?? null);
  const backgroundImage = computed(() => leagueRepresentationData.value?.backgroundImage
    ?? null);

  const { backgroundColor } = useSportlineBackgroundColor({ background });

  return {
    backgroundColor,
    backgroundImage,
  };
}
