import type { FreeBet } from '@leon-hub/api-sdk';

import type { BatchedSlipInfoDocument } from '../types';

export default function getFreeBetsFromBatchedSlipInfo({ slipEntries }: BatchedSlipInfoDocument): FreeBet[] {
  if (slipEntries.length === 1) {
    return [...slipEntries[0].freeBets];
  }
  // ignore for multisingles
  return [];
}
