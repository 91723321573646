import { AbstractErrorCode } from '@leon-hub/errors';
import {
  UnknownRequestHashExceptionCode,
  RequestHashingDisabledExceptionCode,
  AccessDeniedRemoteApiExceptionCode,
  PreviousRequestHasNotExpiredExceptionCode,
} from '@leon-hub/api-sdk';

export class ApiErrorCode extends AbstractErrorCode {
  static API_UNEXPECTED_ERROR = new ApiErrorCode('UNEXPECTED_ERROR');

  static API_TECHNICAL_ERROR = new ApiErrorCode('TECHNICAL_ERROR');

  static API_SERVICE_UNAVAILABLE_ERROR_503 = new ApiErrorCode('SERVICE_UNAVAILABLE_ERROR_503');

  static API_CONNECTION_ERROR = new ApiErrorCode('CONNECTION_ERROR');

  static API_REQUEST_ABORTED_ERROR = new ApiErrorCode('REQUEST_ABORTED');

  static API_IP_BLOCKED_ERROR = new ApiErrorCode('IP_BLOCKED');

  static API_EVENT_MISSING_ERROR = new ApiErrorCode('MISSING');

  static API_URL_NOT_FOUND = new ApiErrorCode('API_URL_NOT_FOUND');

  static API_UNKNOWN_HASH = new ApiErrorCode(
    UnknownRequestHashExceptionCode.UNKNOWN_REQUEST_HASH,
  );

  static API_REQUEST_HASHING_IS_DISABLED = new ApiErrorCode(
    RequestHashingDisabledExceptionCode.REQUEST_HASHING_IS_DISABLED,
  );

  static API_ACCESS_DENIED = new ApiErrorCode(
    AccessDeniedRemoteApiExceptionCode.ACCESS_DENIED,
  );

  static API_PREVIOUS_REQUEST_HAS_NOT_EXPIRED = new ApiErrorCode(
    PreviousRequestHasNotExpiredExceptionCode.PREVIOUS_REQUEST_HAS_NOT_EXPIRED,
  );
}
