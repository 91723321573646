<script lang="ts" setup>
import { IconName, IconSize } from '@leon-hub/icons';

import type { VIconProps } from '@components/v-icon';
import { VIcon } from '@components/v-icon';

import { IncidentType } from 'web/src/modules/sportline/submodules/core-statistic/enums';
import type { TimelineIncident } from 'web/src/modules/sportline/submodules/core-statistic/types';

interface StatisticTimelineIncidentIconProps {
  incident: TimelineIncident;
}

defineProps<StatisticTimelineIncidentIconProps>();

const cardIconProperties: VIconProps = {
  name: IconName.SOCCER_CARD,
  size: IconSize.SIZE_16,
};
const goalIconProperties: VIconProps = {
  name: IconName.SPORT_SOCCER,
  size: IconSize.SIZE_16,
};
const substitutionIconProperties: VIconProps = {
  name: IconName.GENERATE_CODE,
  size: IconSize.SIZE_16,
};
</script>

<template>
  <VIcon
    v-if="incident.type === IncidentType.CARD_RED"
    :class="[
      $style['statistic-timeline-incident-icon'],
      $style['statistic-timeline-incident-icon--card-red']
    ]"
    v-bind="cardIconProperties"
  />
  <VIcon
    v-else-if="incident.type === IncidentType.CARD_YELLOW"
    :class="[
      $style['statistic-timeline-incident-icon'],
      $style['statistic-timeline-incident-icon--card-yellow']
    ]"
    v-bind="cardIconProperties"
  />
  <VIcon
    v-else-if="incident.type === IncidentType.GOAL"
    :class="[
      $style['statistic-timeline-incident-icon'],
      $style['statistic-timeline-incident-icon--goal']
    ]"
    v-bind="goalIconProperties"
  />
  <VIcon
    v-else-if="incident.type === IncidentType.OWN_GOAL"
    :class="[
      $style['statistic-timeline-incident-icon'],
      $style['statistic-timeline-incident-icon--own-goal']
    ]"
    v-bind="goalIconProperties"
  />
  <VIcon
    v-else-if="incident.type === IncidentType.DISABLED_GOAL"
    :class="[
      $style['statistic-timeline-incident-icon'],
      $style['statistic-timeline-incident-icon--disabled-goal']
    ]"
    v-bind="goalIconProperties"
  />
  <VIcon
    v-else-if="incident.type === IncidentType.SUBSTITUTION"
    :class="[
      $style['statistic-timeline-incident-icon'],
      $style['statistic-timeline-incident-icon--substitution']
    ]"
    v-bind="substitutionIconProperties"
  />
  <div v-else />
</template>

<style lang="scss" module>
.statistic-timeline-incident-icon {
  &--card-yellow {
    fill: var(--AlertDefault);
  }

  &--card-red {
    fill: var(--ErrorDefault);
  }

  &--goal {
    fill: var(--TextSecondary);
  }

  &--own-goal {
    fill: var(--ErrorDefault);
  }

  &--disabled-goal {
    fill: var(--ErrorDefault);
  }

  &--substitution {
    fill: var(--TextSecondary);
  }
}
</style>
