<script lang="ts" setup>
import CasinoGroupsList from 'web/src/modules/casino/components/CasinoGroupsList/CasinoGroupsList.vue';
import SearchSubHeader from 'web/src/modules/search/components/SearchSubHeader/SearchSubHeader.vue';
import VLoadMoreObserver from 'web/src/components/LoadMoreObserver/VLoadMoreObserver/VLoadMoreObserver.vue';
import CasinoSearchItem from 'web/src/modules/search/submodules/casino/components/CasinoSearchItem/CasinoSearchItem.vue';
import type {
  CasinoSearchSectionEmits,
  CasinoSearchSectionProps,
} from 'web/src/modules/search/submodules/casino/components/CasinoSearchSection/types';
import { useCasinoSearchSection } from 'web/src/modules/search/submodules/casino/components/CasinoSearchSection/composables';
import LobbySectionHeader from 'web/src/modules/lobby/components/LobbySectionHeader/LobbySectionHeader.vue';

const props = withDefaults(defineProps<CasinoSearchSectionProps>(), {
  searchText: '',
  games: () => ([]),
  groups: () => ([]),
});

const emits = defineEmits<CasinoSearchSectionEmits>();

const {
  hasGroups,
  hasGames,
  emitItemClick,
  emitLoadMore,
  getItemProperties,
} = useCasinoSearchSection(props, emits);
</script>

<template>
  <section v-auto-id="'CasinoSearchSection'" :class="$style['casino-search-section']">
    <div v-if="hasGroups">
      <LobbySectionHeader
        v-if="null"
        :title="$t('WEB2_SEARCH_PROVIDERS')"
        is-modal
        no-background
        :icon-url="require('./assets/icon-providers.svg')"
      />
      <SearchSubHeader
        v-else
        :text="$t('WEB2_SEARCH_PROVIDERS')"
      />
      <CasinoGroupsList
        :groups="groups"
        is-search-modal
      />
    </div>
    <div v-if="hasGames">
      <LobbySectionHeader
        v-if="null"
        :title="$t('WEB2_SEARCH_GAMES')"
        is-modal
        no-background
        :icon-url="require('./assets/icon-games.svg')"
      />
      <SearchSubHeader
        v-else
        :text="$t('WEB2_SEARCH_GAMES')"
      />
      <ul :class="$style['casino-search-section__list']">
        <CasinoSearchItem
          v-for="game in games"
          v-bind="getItemProperties(game)"
          :key="game.id"
          :class="$style['casino-search-section__list-item']"
          @click="emitItemClick(game)"
        />
      </ul>
      <VLoadMoreObserver
        v-if="isLoadMoreAvailable"
        @load-more="emitLoadMore"
      />
    </div>
  </section>
</template>

<style lang="scss" module>
.casino-search-section {
  display: flex;
  flex-direction: column;
  gap: $casinoSearchSectionGap;

  &__list {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__list-item {
    margin-bottom: $casinoSearchSectionGamesItemGap;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
