<script lang="ts" setup>
import type { RouteLocationRaw } from 'vue-router';

import { VIcon } from '@components/v-icon';

import VRouterLink from 'web/src/components/Link/VRouterLink/VRouterLink.vue';

import { useProfileBonusesCardFooter } from './composables';

interface ProfileBonusesCardFooterProps {
  termsLink?: RouteLocationRaw;
  stepNumber?: number;
  stepCount?: number;
}

const props = defineProps<ProfileBonusesCardFooterProps>();

const {
  iconProps,
  handleTermsLinkClick,
  stepNumberString,
  stepCountString,
} = useProfileBonusesCardFooter(props);
</script>

<template>
  <div v-auto-id="'ProfileBonusesCardFooter'"
    :class="{
      [$style['bonus-card-footer']]: true,
      [$style['bonus-card-footer__has-link']]: termsLink
    }"
  >
    <VRouterLink
      v-if="termsLink"
      :class="$style['bonus-card-footer__link']"
      :to="termsLink"
      @click="handleTermsLinkClick"
    >
      <VIcon
        v-bind="iconProps"
        :class="$style['bonus-card-footer__icon']"
      />
      <span>{{ $t('WEB2_BONUS_TERMS') }}</span>
    </VRouterLink>
    <div
      v-if="Number(stepCount) > 1"
      :class="$style['bonus-card-footer__step']"
    >
      <span>{{ $t('WEB2_BONUS_STEP') }} {{ stepNumberString }}/{{ stepCountString }}</span>
    </div>
  </div>
</template>

<style lang="scss" module>
.bonus-card-footer {
  display: flex;
  justify-content: flex-end;
  padding: 8px 16px 16px;
  background-color: var(--Layer1);
  border-radius: $bonusCardFooterBorderRadius;

  &__has-link {
    justify-content: space-between;
  }

  &__link {
    @include regular\13\16;

    display: flex;
    align-items: center;
    overflow: hidden;
    color: var(--TextSecondary);
    text-decoration: underline;
    text-overflow: ellipsis;

    @include for-hover {
      &:hover {
        color: var(--TextDefault);
        cursor: pointer;
      }
    }
  }

  &__icon {
    flex-shrink: 0;
    margin-right: 4px;
  }

  &__step {
    @include regular\13\16;

    color: var(--TextSecondary);
    text-align: right;
    white-space: nowrap;
  }
}
</style>
