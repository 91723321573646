import type { I18nFunctionReactive } from 'web/src/modules/i18n/types';
import type {
  ProfileBonusesFreespinControllerProps,
} from 'web/src/modules/profile/submodules/bonuses/controllers/ProfileBonusesFreespinController/types';
import type { ProfileBonusesCardDescriptionColumnsType } from 'web/src/modules/profile/submodules/bonuses/types';
import type { FormatMoneyFunc } from 'web/src/modules/money/types';

import getUsedCount from './getUsedCount';
import getCount from './getCount';
import getBalance from './getBalance';

export default function getBalanceProperties(
  props: Pick<ProfileBonusesFreespinControllerProps, 'bonus'>,
  $t: I18nFunctionReactive,
  $formatMoney: FormatMoneyFunc,
): ProfileBonusesCardDescriptionColumnsType {
  return {
    titleLeft: $t('WEB2_BONUS_BALANCE_FREESPIN_TITLE').value,
    valueLeft: $formatMoney(getBalance(props)),
    titleRight: $t('WEB2_BONUSES_AMOUNT_TITLE').value,
    valueRight: `${getUsedCount(props).toString()}/${getCount(props).toString()}`,
  };
}
