import { getRoutingConfigs } from '@leon-hub/api-sdk';

import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';

import type { GetRoutingConfigsDocument } from '../types';

export default function fetchRoutingConfigs({ silent }: { silent?: boolean } = {}): Promise<GetRoutingConfigsDocument> {
  const apiClient = useGraphqlClient();
  return getRoutingConfigs(
    apiClient,
    (node) => node.queries.system.getRouting,
    {},
    { silent },
  );
}
