import type {
  NavigationGuardNext,
  RouteLocationNormalized,
} from 'vue-router';

import { RouteName } from '@leon-hub/routing-config-names';

import { AbstractPrefetch } from 'web/src/modules/core/services/router/prefetch';
import type { AppVueRouter } from 'web/src/modules/core/services/router/types';
import { useRootNavigationStore } from 'web/src/modules/core/store';
import { useCasinoHome } from 'web/src/modules/home/submodules/casino/useCasinoHome';

export default class CasinoHomePagePrefetch extends AbstractPrefetch {
  // eslint-disable-next-line class-methods-use-this
  prefetch(
    router: AppVueRouter,
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ): Promise<void> {
    const {
      isReady,
      initialRequests,
      syncBackgroundRequests,
    } = useCasinoHome();

    const navigationStore = useRootNavigationStore();

    if (!isReady.value) {
      navigationStore.setOnlyFirstRouteContentLoading(RouteName.HOME);
      initialRequests().then(() => {
        navigationStore.setRouteContentLoaded(RouteName.HOME);
      }).catch((error) => {
        throw error;
      });
    } else {
      void syncBackgroundRequests();
      navigationStore.setRouteContentLoaded(RouteName.HOME);
    }

    next();
    return Promise.resolve();
  }
}
