/* eslint-disable @typescript-eslint/no-redeclare */

/** types to separate expanded events on different pages */
export const ExtendedEventBlock = {
  Default: 'Default',
  Top: 'Top',
  Live: 'Live',
  Cybersport: 'Cybersport',
  League: 'League',
  Region: 'Region',
  Breadcrumbs: 'Breadcrumbs',
  RelatedEvents: 'RelatedEvents',
  Favorites: 'Favorites',
  ZeroMargin: 'ZeroMargin',
} as const;

export type ExtendedEventBlock = typeof ExtendedEventBlock[keyof typeof ExtendedEventBlock];
