import debug from 'debug';

import { IndexedDb } from '@leon-hub/indexed-db';
import { memoize } from '@leon-hub/utils';
import type { BootstrapTranslations } from '@leon-hub/bootstrap-translations';
import { getBootstrapTranslations } from '@leon-hub/bootstrap-translations';
import { getLocationOrigin, getCookies } from '@leon-hub/service-locator-env';

import type { BaseClientRequestOptions, BaseClientOptions } from './types';
import type { ApiConnectionError } from './errors/ApiConnectionError';
import type { ApiRequestAbortedError } from './errors/ApiRequestAbortedError';

const defaultCustomHeaders: Record<string, string> = {};
const DEFAULT_BASE_URL = '';

type ErrorResolver = (error: ApiConnectionError | ApiRequestAbortedError) => Promise<void>;

export default abstract class BaseClient {
  protected origin: string;

  protected baseUrl: string;

  protected readonly method: 'GET' | 'POST';

  protected headers: Partial<Record<string, string>>;

  protected credentials: RequestCredentials;

  protected debug = debug('client');

  private customHeaders: Record<string, string> = {};

  protected connectionErrorResolver: (ErrorResolver | null) = null;

  private cookies = '';

  setConnectionErrorResolver(value: ErrorResolver): void {
    this.connectionErrorResolver = value;
  }

  public constructor(options: BaseClientOptions) {
    this.baseUrl = options.baseUrl ?? DEFAULT_BASE_URL;

    this.origin = options.origin ?? getLocationOrigin().replace(/\/+$/, '');

    if (this.baseUrl.startsWith('http')) {
      this.origin = '';
    }
    this.method = options.method;
    this.headers = options.headers ?? {};
    this.credentials = options.credentials ?? 'include';
    this.cookies = getCookies();
  }

  // eslint-disable-next-line rulesdir/class-method-use-this-regex,class-methods-use-this
  protected get bootstrapTranslations(): BootstrapTranslations {
    return getBootstrapTranslations();
  }

  protected getCredentials(): RequestCredentials {
    return this.credentials;
  }

  public getBaseUrl(): string {
    return this.baseUrl;
  }

  public setBaseUrl(baseUrl: string): void {
    this.baseUrl = baseUrl.replace(/\/+$/, '');
  }

  protected getOrigin(): string {
    return this.origin;
  }

  public getCustomHeaders(): Record<string, string> {
    return this.customHeaders;
  }

  public getCustomHeader(): Record<string, string> {
    return this.customHeaders;
  }

  public setCustomHeaders(headers: Record<string, string>): void {
    this.customHeaders = {
      ...this.customHeaders,
      ...headers,
    };
  }

  public getHeaders = memoize((customHeaders: Record<string, string> = defaultCustomHeaders): Record<string, string> => {
    const allHeaders = {
      ...this.headers,
      ...this.customHeaders,
      ...customHeaders,
    };

    if (this.cookies) {
      allHeaders.cookie = this.cookies;
    }

    return Object.keys(allHeaders)
      .filter((key, index, list) => key && allHeaders[key] && list.indexOf(key) === index)
      .reduce<Record<string, string>>((accumulator, key) => {
        const value = allHeaders[key];
        return value ? ({
          ...accumulator,
          [key.toLowerCase()]: value,
        }) : accumulator;
      }, {});
  });

  protected getDefaultMethod(): string {
    return this.method;
  }

  abstract request<T>(options: BaseClientRequestOptions): Promise<T>;

  public setHeaders(headers: Record<string, string>): void {
    Object.assign(this.headers, headers);
  }

  protected defaultCacheTTL = 0;

  setDefaultCacheTtl(ttl: number): void {
    this.defaultCacheTTL = ttl;
  }

  private getCacheDb = memoize(() => new IndexedDb<{ expires: number; value: unknown }>({ dbName: 'cache', storeName: 'api' }));

  protected async setCache(cacheKey: string, value: unknown, ttl: number): Promise<void> {
    const isRationalNumber = Number.isFinite(ttl) && ttl > 0;
    return this.getCacheDb()
      .setItem(cacheKey, {
        expires: isRationalNumber
          ? Date.now() + ttl
          : 0,
        value,
      });
  }

  protected async getCache(cacheKey: string): Promise<unknown> {
    const cacheItem = await this.getCacheDb().getItem(cacheKey);
    if (!cacheItem || (Date.now() > (cacheItem.expires || Infinity))) {
      return undefined;
    }
    return cacheItem.value;
  }
}
