import { computed } from 'vue';

import type { InputHintProps, UseHintParentPropsOutput } from '../types';

type UseHintParentPropsInput<T = {}> = T & InputHintProps;

const useHintParentProps = (props: UseHintParentPropsInput): UseHintParentPropsOutput => {
  const hintProperties = computed<InputHintProps>(() => ({
    error: props.error,
    errorRight: props.errorRight,
    hint: props.hint,
    hintLink: props.hintLink,
    hintRight: props.hintRight,
    hintLinkRight: props.hintLinkRight,
  }));
  const hasError = computed<boolean>(() => !!(props.error || props.errorRight));
  return {
    hintProperties,
    hasError,
  };
};

export default useHintParentProps;
