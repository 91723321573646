import type { BatchMakeBetRequest, MakeBatchSlipReturnType } from '@leon-hub/api-sdk';
import { makeBatchSlip } from '@leon-hub/api-sdk';

import type ApplicationGqlClient from 'web/src/modules/core/services/api/ApplicationGqlClient';

export default function requestPlaceBet(apiClient: ApplicationGqlClient, requestPayload: BatchMakeBetRequest): Promise<MakeBatchSlipReturnType> {
  return makeBatchSlip(apiClient,
    (node) => node.mutations.betSlip.makeBatchSlip,
    { options: requestPayload });
}
