import {
  useRootStore,
  useRouterStore,
  useNavigationStore,
  useSiteConfigStore,
  useApiSettingsStore,
  useSyncDateTimeStore,
} from 'web/src/modules/core/store';
import { useI18nStore } from 'web/src/modules/i18n/store';

import type { PrefetchConsumer } from './types/PrefetchConsumer';

export const prefetchConsumer: PrefetchConsumer = {
  root: (state): void => useRootStore().handleData(state),
  api: (state): void => useApiSettingsStore().handleApiSettings(state),
  siteConfig: (state): void => useSiteConfigStore().handleSiteConfigSettings(state),
  routing: (state): void => useRouterStore().handleAppRouteConfigs(state),
  navigation: (state): void => useNavigationStore().handleDefaultNavigationConfig(state),
  i18n: (state): void => useI18nStore().updateKeKeys(state),
  serverTime: (time): void => useSyncDateTimeStore().setServerTime(time),
};

export type PrefetchConsumerKey = keyof PrefetchConsumer;
