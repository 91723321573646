import { isNumber } from '@leon-hub/guards';

import { SportlineType } from 'web/src/modules/sportline/enums';

export function isSportlineType(value: unknown): value is SportlineType {
  return isNumber(value) && (
    value === SportlineType.Live
    || value === SportlineType.Prematch
  );
}

export function isLiveSportlineType(value: unknown): value is SportlineType.Live {
  return isNumber(value) && value === SportlineType.Live;
}

export function isPrematchSportlineType(value: unknown): value is SportlineType.Prematch {
  return isNumber(value) && value === SportlineType.Prematch;
}
