import type { Debugger } from 'debug';
import type {
  EffectScope,
} from 'vue';
import {
  reactive,
  watchEffect,
  effectScope,
  getCurrentScope,
  toRef,
} from 'vue';

import { normalizeError } from '@leon-hub/errors';
import { assert } from '@leon-hub/guards';
import { Deferred, getResolvedDeferred, voidify } from '@leon-hub/utils';

import { getFunctionId } from 'web/src/modules/lexis-nexis-integration/utils/getFunctionId';

import type {
  OnCleanUp,
  AwaitableWatchEffect,
  OptionalWatchStopHandler,
  WatchCustomEffectOptions,
  WatchCustomEffectControls,
  ContinueEffectCallback,
  ContinueEffect,
} from './types';
import { createAsyncEffect, normalizeEffect } from './helpers';
import { log as logger } from '../log';
import { rejection } from './constants';

export function watchCustomEffect(
  effectCallback: AwaitableWatchEffect,
  options: WatchCustomEffectOptions = {},
): WatchCustomEffectControls {
  const effects: Set<AwaitableWatchEffect> = reactive(new Set([effectCallback]));
  const scope = effectScope(!!options.detached);
  const condition = options.condition ?? toRef(true);
  const log = logger.extend(`effect:${options.id ?? getFunctionId(effectCallback)}`);
  log.enabled = true;

  let subScope: EffectScope;
  let subScopeStopHandler: OptionalWatchStopHandler;
  let runningEffect: Deferred<unknown>;
  function resetRunningEffect() {
    runningEffect = getResolvedDeferred<unknown>(undefined);
  }
  resetRunningEffect();

  const stopSubWatchEffect = (): void => {
    if (subScopeStopHandler) {
      log('teardown sub scope..');
      subScopeStopHandler();
      subScopeStopHandler = undefined;
    }
  };

  const composedEffect = (onCleanup: OnCleanUp): void => {
    const isEnabled = condition.value;
    log(`conditional effect called (condition=${isEnabled})`);
    if (isEnabled) {
      if (!subScopeStopHandler) {
        log('creating sub-scope');
        subScope = effectScope();
        const asyncEffect = createAsyncEffect({
          scope: subScope,
          effects,
          onCleanup,
        });
        log('running effect on sub-scope');
        subScopeStopHandler = () => {}; // allows inner sub-effects to detect live state.
        subScopeStopHandler = subScope.run(() => watchEffect(voidify(asyncEffect), options));
      }
    } else {
      stopSubWatchEffect();
    }
  };

  /**
   * Extend main effect with sub effects.
   */
  const addEffect = (asyncEffectCallback: AwaitableWatchEffect) => {
    log('add');
    const currentScope = getCurrentScope();
    const isSubScopeActive = currentScope === subScope;
    const noActiveScope = currentScope === undefined;
    assert(noActiveScope || !isSubScopeActive, 'Unable to change effect while running same effect');
    effects.add(asyncEffectCallback);
  };

  /**
   * Run async code with effect scope.
   */
  const continueEffect = async (debugLog: Debugger, callback: ContinueEffectCallback) => {
    debugLog('init');
    const deferred = new Deferred();
    const runner = () => run({
      log: debugLog, callback, deferred, scope,
    });

    if (getCurrentScope() === subScope) {
      return runner();
    }

    try {
      while (!runningEffect.finished) {
        log('waiting');
        const activePromise = runningEffect.promise;
        // eslint-disable-next-line no-await-in-loop
        await activePromise;
      }
      log('resume');
    } catch {
      throw new Error('Unable to continue failed effect');
    }
    return runner();
  };

  const controls: WatchCustomEffectControls = {
    stop: () => {
      log('stop effect');
      stopSubWatchEffect();
      scope.stop();
      runningEffect.reject(rejection);
    },
    addEffect,
    continueEffect: ((...rest: Parameters<ContinueEffect>) => {
      const [callback] = rest;
      const prefix = `continue-effect:${getFunctionId(callback)}`;
      const debugLog = log.extend(prefix);
      debugLog.enabled = true;
      if (!subScopeStopHandler) {
        debugLog('cancel');
        return {
          dead: true,
          value: undefined,
        };
      }
      return continueEffect(debugLog, ...rest);
    }) as ContinueEffect,
  };
  log('creating..');
  scope.run(() => {
    watchEffect(composedEffect, options);
  });
  return controls;
}

async function run({
  log, scope, callback, deferred,
}: { log: Debugger; scope: EffectScope; callback: AwaitableWatchEffect; deferred: Deferred<void> }) {
  try {
    log('before run');

    const result = scope.run(normalizeEffect(callback));
    const value = result instanceof Promise ? await result : result;
    const dead = !result;
    return { dead, value };
  } catch (err) {
    const normalizedError = normalizeError(err);
    deferred.reject(rejection);
    throw normalizedError;
  } finally {
    log('after run');
    deferred.resolve();
  }
}
