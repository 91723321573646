import { EgsGameType } from '@leon-hub/api-sdk';

import type { EgsGame } from 'web/src/modules/egs/types';
import { EgsRouterParametersHandler, getEgsGameRouteLocation, isDemoMethod } from 'web/src/modules/egs/submodules/navigation/utils';
import type { GameStartMethod } from 'web/src/modules/egs/submodules/navigation/enums';
import navigateTo from 'web/src/utils/router/navigateTo';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import type { AppVueRouter } from 'web/src/modules/core/services/router/types';
import { useEgsGamesStore } from 'web/src/modules/egs/submodules/games/store';

export interface EgsNavigationComposable {
  getEgsGameHref: (game: EgsGame) => string;
  navigateToGamePage: (game: EgsGame, selectedMethod?: GameStartMethod, replace?: boolean) => Promise<void>;
  navigateToGamePageById: (gameId: string, selectedMethod?: GameStartMethod, replace?: boolean) => Promise<void>;
  isDemo: (game: EgsGame, selectedMethod?: GameStartMethod) => boolean;
}

export default function useEgsNavigation(router: AppVueRouter): EgsNavigationComposable {
  const {
    isLoggedIn,
  } = useIsLoggedIn();

  const {
    getGameById,
    loadGameById,
  } = useEgsGamesStore();

  function getEgsGameHref(game: EgsGame): string {
    return router.resolve(getEgsGameRouteLocation(game)).href;
  }

  async function navigateToGamePage(game: EgsGame, selectedMethod?: GameStartMethod, replace = false): Promise<void> {
    if (game.typeId !== EgsGameType.FASTGAMES) {
      EgsRouterParametersHandler.setSelectedGameMethod(selectedMethod);
    }

    if (process.env.VUE_APP_LAYOUT_DESKTOP) {
      await router.closeModal();
    }

    navigateTo(router, getEgsGameRouteLocation(game), replace);
  }

  function isDemo(game: EgsGame, selectedMethod?: GameStartMethod): boolean {
    return isDemoMethod(game, isLoggedIn.value, selectedMethod);
  }

  // eslint-disable-next-line max-len
  async function navigateToGamePageById(gameId: string, selectedMethod?: GameStartMethod, replace = false): Promise<void> {
    let game = getGameById(gameId);
    if (!game) {
      game = await loadGameById(gameId);
    }
    if (game) {
      void navigateToGamePage(game, selectedMethod, replace);
    }
  }

  return {
    getEgsGameHref,
    navigateToGamePage,
    navigateToGamePageById,
    isDemo,
  };
}
