import type { Ref } from 'vue';
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';

import { Timer } from '@leon-hub/utils';
import { useWindowResize, useWindowVisibilityChanged } from '@leon-hub/browser-composables';

import type { VSwiperRef, VSwiperScrollEvent } from 'web/src/components/Swiper/VSwiper/types';
import {
  getNextSlideIndexOffset,
} from 'web/src/modules/banners/components/VSwiperBannerNavigation/utils/utils';
import { useAbstractBanner } from 'web/src/modules/banners/composables/useAbstractBanner';
import type { Banner, BannerGroup } from 'web/src/modules/banners/store/types';

import type { SwiperBannerEmits, SwiperBannerProps } from '../types';

export interface SwiperBannerComposable {
  swiper: Ref<null | VSwiperRef>;
  banners: Ref<BannerGroup['banners']>;
  emitBannerItemClick: (path: string, banner: Banner) => void;
  isGroupBannersDev: Ref<Optional<BannerGroup['isNT']>>;
  activeIndex: Ref<number>;
  bannersKey: Ref<string>;
  isMouseOver: Ref<boolean>;
  emitSlideChanged: (event: VSwiperScrollEvent) => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  onActivatedAction: () => void;
  onDeactivatedAction: () => void;
}

export default function useSwiperBanner(props: SwiperBannerProps, emit: SwiperBannerEmits): SwiperBannerComposable {
  const route = useRoute();

  const {
    banners,
    autoplayTimeout,
    isGroupBannersDev,
    emitBannerItemClick,
  } = useAbstractBanner(props, emit);

  const swiper = ref<null | VSwiperRef>(null);
  const activeIndex = ref(0);
  const isMouseOver = ref(false);
  let timeout = 0;

  const bannersKey = computed<string>(() => `${banners.value.length}${String(route.name)}`);

  function onResize({ deltaX }: { deltaX: number }): void {
    if (Math.abs(deltaX) > 0) {
      swiper.value?.slideToSlide(swiper.value.getActiveSlideIndex());
    }
  }

  function emitSlideChanged(event: VSwiperScrollEvent) {
    activeIndex.value = event.activeSlide;
    emit('slide-changed', event.activeSlide);
  }

  function stopTimer(): void {
    if (timeout) {
      Timer.clearTimeout(timeout);
      timeout = 0;
    }
  }

  function startTimer(): void {
    stopTimer();
    if (autoplayTimeout.value && !isMouseOver.value) {
      timeout = Timer.setTimeout(() => {
        slideToNextSlide();
        startTimer();
      }, autoplayTimeout.value);
    }
  }

  function onWindowVisibilityChanged(isVisible: boolean): void {
    if (!isVisible) {
      stopTimer();
    } else {
      startTimer();
    }
  }

  function onMouseEnter(): void {
    isMouseOver.value = true;
    stopTimer();
  }

  function onMouseLeave(): void {
    isMouseOver.value = false;
    startTimer();
  }

  function slideToNextSlide(): void {
    if (swiper.value) {
      const activeIndexCurrent = swiper.value.getActiveSlideIndex();
      swiper.value.slideToNextSlide(getNextSlideIndexOffset(activeIndexCurrent, banners.value.length));
    }
  }

  function onActivatedAction(): void {
    startTimer();
  }

  function onDeactivatedAction(): void {
    stopTimer();
  }

  useWindowResize(onResize);
  useWindowVisibilityChanged(onWindowVisibilityChanged);

  return {
    swiper,
    banners,
    emitBannerItemClick,
    isGroupBannersDev,
    activeIndex,
    bannersKey,
    isMouseOver,
    emitSlideChanged,
    onMouseEnter,
    onMouseLeave,
    onActivatedAction,
    onDeactivatedAction,
  };
}
