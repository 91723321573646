import { computed, ref, watch } from 'vue';

import { InputEventType } from 'web/src/components/Input/enums';
import createUniqueId from 'web/src/utils/createUniqueId';

import type { UseInputCore, UseInputCoreProps } from './types';
import type { BaseInputEmits, PhoneInputEmits, VInputInputEvent } from '../types';

export default function useInputCore(
  props: UseInputCoreProps,
  emit: BaseInputEmits | PhoneInputEmits,
  watchValueProp = true,
): UseInputCore {
  const uniqueId = computed<string>(() => createUniqueId(props.name ?? 'input'));

  const inputValue = ref<string>(props.value ?? '');

  const isEmpty = computed<boolean>(() => inputValue.value === '');

  const isFocus = ref<boolean>(false);
  const isHover = ref<boolean>(false);

  const showClearButton = computed<boolean>(
    () => isFocus.value && !isEmpty.value && !props.readonly,
  );

  const setInputValue = (value?: string): void => {
    inputValue.value = value ?? '';
  };

  watch(() => props.value, (value) => {
    if (!watchValueProp) {
      return;
    }
    if (value !== inputValue.value) {
      setInputValue(value);
    }
  });

  const eventToEmit = computed<VInputInputEvent>(() => ({
    target: {
      value: inputValue.value,
      name: props.name ?? '',
    },
  }));

  const onFocus = (): void => {
    isFocus.value = true;
    emit(InputEventType.FOCUS, eventToEmit.value);
  };

  const onBlur = (): void => {
    isFocus.value = false;
    emit(InputEventType.BLUR, eventToEmit.value);
  };

  const onMouseOver = (): void => {
    isHover.value = true;
  };

  const onMouseLeave = (): void => {
    isHover.value = false;
  };

  const emitChange = (): void => {
    emit(InputEventType.CHANGE, eventToEmit.value);
  };

  const emitInput = (): void => {
    emit(InputEventType.INPUT, eventToEmit.value);
  };

  const clearValue = () => {
    setInputValue('');
    emitChange();
  };

  return {
    uniqueId,
    isEmpty,
    isFocus,
    isHover,
    inputValue,
    showClearButton,
    clearValue,
    onMouseOver,
    onMouseLeave,
    onFocus,
    onBlur,
    emitChange,
    emitInput,
    setInputValue,
  };
}
