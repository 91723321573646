import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import { assert, isOptionalBoolean, isOptionalString } from '@leon-hub/guards';

import { isCheckboxProps } from '@components/checkbox';

import type { VCheckboxProps } from 'web/src/components/Checkbox/VCheckbox/types';
import type { TermsCheckboxProps } from 'web/src/components/TermsSpecialCheckboxes/types';

import type {
  FormWrapperWidgetProperties,
} from '../types';

interface UseFormCheckbox {
  componentProps: ComputedRef<VCheckboxProps>;
}

export default function useFormCheckbox(
  props: FormWrapperWidgetProperties<VCheckboxProps | TermsCheckboxProps>,
  label?: string,
): UseFormCheckbox {
  const componentProps = computed<VCheckboxProps | TermsCheckboxProps>(() => {
    const {
      name,
      value,
      disabled,
      error,
      options,
      autofocus,
    } = props;
    assert(isOptionalBoolean(value));
    assert(isOptionalString(error));
    assert(isCheckboxProps(options));
    return {
      name,
      error,
      label,
      ...options,
      checked: value,
      disabled,
      autofocus,
    };
  });
  return {
    componentProps,
  };
}
