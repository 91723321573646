import { CasinoIconName } from '@leon-hub/icons';

interface getIconComposable {
  getIcon(item: string | undefined): CasinoIconName;
}

export function useGetIcon(): getIconComposable {
  function getIcon(item: string): CasinoIconName {
    if (item === 'LOBBY') {
      return CasinoIconName.CASINO_LOBBY;
    }
    if (item === 'ALL') {
      return CasinoIconName.CASINO_ALL;
    }
    return CasinoIconName.CASINO_FAVORITES;
  }

  return {
    getIcon,
  };
}
