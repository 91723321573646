import type { TopLeagueListElement } from 'web/src/modules/sportline/types';
import type { CustomerLeagueListElement } from 'web/src/modules/sportline/submodules/favorites/types';
import { resolveLeaguePageLink } from 'web/src/modules/sportline/submodules/navigation/store/utils';

import type { ActiveTopLeagueListElement } from '../types';

export function prepareTopLeagueListElementsForFilterTab(
  leagues: (TopLeagueListElement | CustomerLeagueListElement)[],
): ActiveTopLeagueListElement[] {
  return leagues.flatMap(({ league, region }) => ({
    league,
    region,
    pageLocation: resolveLeaguePageLink(league.navigationParameters),
  }));
}
