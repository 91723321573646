import { getNavigationConfig } from '@leon-hub/api-sdk';
import { assert } from '@leon-hub/guards';
import type NavigationConfig from '@leon-hub/navigation-config/src/types/NavigationConfig';
import { isNavigationConfig } from '@leon-hub/navigation-config/src/types/NavigationConfig';

import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';

export async function fetchDefaultNavigationConfig(silent = false): Promise<NavigationConfig> {
  const result = await getNavigationConfig(useGraphqlClient(),
    (node) => node.queries.system.getNavigation,
    {},
    { silent });

  const parsedNavigationConfig = JSON.parse(result.navigation);
  assert(isNavigationConfig(parsedNavigationConfig));
  return parsedNavigationConfig;
}
