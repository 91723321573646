import { getBootstrapTranslations } from '@leon-hub/bootstrap-translations';
import { LogLevel } from '@leon-hub/logging';

import { ApiErrorCode } from './ApiErrorCode';
import type { ApiErrorOptions } from './ApiError';
import { ApiError } from './ApiError';

export class ApiConnectionError extends ApiError {
  constructor(options?: ApiErrorOptions) {
    super({
      ...options,
      logLevel: LogLevel.WARN,
      message: options?.message ?? getBootstrapTranslations().WEB2_CONNECTION_ERROR_DESCRIPTION,
      code: ApiErrorCode.API_CONNECTION_ERROR,
    });
  }
}
