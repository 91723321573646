import type { Ref } from 'vue';
import {
  toRef,
} from 'vue';

import { useCasinoGameStore } from 'web/src/modules/casino/submodules/game/store';
import type { EgsGame } from 'web/src/modules/egs/types';

export interface CasinoActiveGameComposable {
  activeGame: Ref<EgsGame | undefined>;
}

export default function useCasinoActiveGame(): CasinoActiveGameComposable {
  const gamesStore = useCasinoGameStore();
  const activeGame = toRef(gamesStore, 'activeGame');

  return {
    activeGame,
  };
}
