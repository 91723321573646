import { SportFilterKickoffPeriod } from '@leon-hub/api-sdk';

import type { DateTimeRange } from 'web/src/modules/sportline/types';
import type { SportDateTimeFilter } from 'web/src/modules/sportline/submodules/navigation/store/types';
import KickoffPeriodUtils from 'web/src/modules/sportline/utils/core/KickoffPeriodUtils';

function buildDateTimeRage(from?: Maybe<number>, to?: Maybe<number>): DateTimeRange {
  return from && to
    ? { from, to }
    : { from: undefined, to: undefined };
}

export function getDateTimeRangeForFilter(filter?: Maybe<SportDateTimeFilter>): Maybe<DateTimeRange> {
  if (!filter) { return null; }

  return filter.activeKickoffPeriod === SportFilterKickoffPeriod.CUSTOM
    ? buildDateTimeRage(filter.customDateFrom, filter.customDateTo)
    : KickoffPeriodUtils.dateTimeRangeFromKickoffPeriod(filter.activeKickoffPeriod);
}
