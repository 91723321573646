import {
  defineStore,
} from 'pinia';
import {
  computed,
  ref,
  toRef,
} from 'vue';
import type { RouteLocationNormalized } from 'vue-router';

import type AppRouteConfig from '@leon-hub/routing-config/src/types/AppRouteConfig';
import { HomePageType } from '@leon-hub/api-sdk';
import CustomerRouteAccessRole from '@leon-hub/routing-config/src/types/CustomerRouteAccessRole';

import type { GetRoutingConfigsDocument } from 'web/src/modules/core/store/types';

import { useSiteConfigStore } from './useSiteConfigStore';
import { fetchRoutingConfigs } from './utils';
import type { RoutingSeoData } from '../types';

// eslint-disable-next-line sonarjs/cognitive-complexity
const useRouterStore = defineStore('router', () => {
  // State
  const seoConfigs = ref<Record<string, RoutingSeoData>>({});
  const isBackButtonInTopBar = ref(false);
  const currentRoute = ref<RouteLocationNormalized | null>(null);
  const previousRoute = ref<RouteLocationNormalized | null>(null);
  const currentRouteIndex = ref(0);
  const lastNoModalRouteIndex = ref(0);
  const realCurrentRouteName = ref<RouteLocationNormalized['name']>('');
  const previousRouteName = ref<RouteLocationNormalized['name'] | undefined>(undefined);
  const nextRouteName = ref<RouteLocationNormalized['name'] | undefined>(undefined);
  const previousRouteUrl = ref<string | undefined>(undefined);
  const lastAnonymousRouteIndex = ref(0);
  const lastAuthorizedRouteIndex = ref(0);
  const isPopStateDetected = ref(false);

  // Getters
  const siteConfigStore = useSiteConfigStore();
  const homePageType = toRef(siteConfigStore, 'homePageType');
  const isSportHomePageType = computed(() => (
    !!process.env.VUE_APP_FEATURE_SPORTLINE_ENABLED && homePageType.value === HomePageType.SPORTS
  ));
  const isEGSHomePageType = computed(() => (
    !!process.env.VUE_APP_FEATURE_CASINO_ENABLED
    && (homePageType.value === HomePageType.SLOTS || homePageType.value === HomePageType.LIVE_GAMES)
  ));
  const currentRouteName = computed(() => currentRoute.value?.name);
  const isCurrentRouteHasTopBar = computed(() => !!currentRoute.value?.meta.hasTopBar);

  // Actions
  async function updateAppRouteConfigs(silent = false): Promise<void> {
    handleAppRouteConfigs(await fetchRoutingConfigs({ silent }));
  }

  function handleAppRouteConfigs(result: GetRoutingConfigsDocument): void {
    const { routing } = result;
    const config = JSON.parse(routing) as AppRouteConfig[];
    seoConfigs.value = config.reduce((accumulator: Record<string, RoutingSeoData>, item) => {
      accumulator[item.name] = {
        path: item.path,
        metaInfo: item.metaInfo,
        seoKey: item.seoKey,
        seoData: item.seoData,
      };

      return accumulator;
    }, {});
  }

  function setBackButtonInTopBar(value: boolean): void {
    isBackButtonInTopBar.value = value;
  }

  function updateIndexes(to: RouteLocationNormalized): void {
    if (!to.meta.access || to.meta.access === CustomerRouteAccessRole.ANONYMOUS) {
      setLastAnonymousRouteIndex(window.history.state?.position || 0);
    }

    if (!to.meta.access || to.meta.access === CustomerRouteAccessRole.AUTHORIZED) {
      setLastAuthorizedRouteIndex(window.history.state?.position || 0);
    }
  }

  function setCurrentRoute(to: RouteLocationNormalized): void {
    previousRoute.value = currentRoute.value;
    currentRoute.value = to;
    updateIndexes(to);
  }

  function setCurrentRouteIndex(value: number): void {
    currentRouteIndex.value = value;
  }

  function setLastNoModalRouteIndex(value: number): void {
    lastNoModalRouteIndex.value = value;
  }

  function setLastAnonymousRouteIndex(value: number): void {
    lastAnonymousRouteIndex.value = value;
  }

  function setLastAuthorizedRouteIndex(value: number): void {
    lastAuthorizedRouteIndex.value = value;
  }

  function setRealCurrentRouteName(value: RouteLocationNormalized['name']): void {
    realCurrentRouteName.value = value;
  }

  function setPreviousRouteName(value: RouteLocationNormalized['name'] | undefined): void {
    previousRouteName.value = value;
  }

  function setPreviousRouteUrl(value: string | undefined): void {
    previousRouteUrl.value = value;
  }

  function getModalRouteHistoryDelta(): number {
    if (window.history.length <= 1) {
      return 0;
    }

    const delta = currentRouteIndex.value - lastNoModalRouteIndex.value;

    return delta > 0 ? -delta : 0;
  }

  function getAuthorizedRouteHistoryDelta(): number {
    if (process.env.VUE_APP_LAYOUT_DESKTOP && lastAuthorizedRouteIndex.value === 0) {
      return 0;
    }

    const delta = currentRouteIndex.value - lastAuthorizedRouteIndex.value;

    return delta > 0 ? -delta : 0;
  }

  function getAnonymousRouteHistoryDelta(): number {
    if (lastAnonymousRouteIndex.value === 0) {
      return 0;
    }

    const delta = currentRouteIndex.value - lastAnonymousRouteIndex.value;

    return delta > 0 ? -delta : 0;
  }

  function getLoginRedirectRoute(): string | undefined {
    return window.history.state?.toRoute || window.history.state?.back;
  }

  function resetPopState(): void {
    isPopStateDetected.value = false;
  }

  function setNextRouteName(value: RouteLocationNormalized['name'] | undefined): void {
    nextRouteName.value = value;
  }

  function registerPopState(): void {
    if (!process.env.VUE_APP_RENDERING_SSR) {
      window.addEventListener('popstate', () => {
        isPopStateDetected.value = true;
      });
    }
  }

  if (!process.env.VUE_APP_RENDERING_SSR) {
    registerPopState();
  }

  return {
    currentRoute,
    previousRoute,
    seoConfigs,
    isBackButtonInTopBar,
    isEGSHomePageType,
    isSportHomePageType,
    currentRouteName,
    realCurrentRouteName,
    previousRouteName,
    previousRouteUrl,
    isPopStateDetected,
    isCurrentRouteHasTopBar,
    nextRouteName,
    updateAppRouteConfigs,
    handleAppRouteConfigs,
    setBackButtonInTopBar,
    setCurrentRouteIndex,
    setCurrentRoute,
    setLastNoModalRouteIndex,
    getModalRouteHistoryDelta,
    setRealCurrentRouteName,
    getLoginRedirectRoute,
    setPreviousRouteName,
    setPreviousRouteUrl,
    getAuthorizedRouteHistoryDelta,
    getAnonymousRouteHistoryDelta,
    resetPopState,
    setNextRouteName,
  };
});

export default useRouterStore;
