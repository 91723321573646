/* eslint-disable @typescript-eslint/no-redeclare */
export const UpcomingLabelType = {
  Timer: 'timer',
  StaticLabel: 'static-label',
} as const;
export type UpcomingLabelType = typeof UpcomingLabelType[keyof typeof UpcomingLabelType];

export const TodayLabelType = {
  None: 'none',
  Inline: 'inline',
} as const;
export type TodayLabelType = typeof TodayLabelType[keyof typeof TodayLabelType];
