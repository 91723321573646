<script setup lang="ts">
import { defineAsyncComponent, onBeforeMount, onUnmounted } from 'vue';

import { useFavoritesEventsStore } from 'web/src/modules/sportline/submodules/favorites/store/events';
import { useSeoMetaParameters } from 'web/src/modules/seo/composables';
import { VLoaderMaskedDelayed } from 'web/src/components/Loader';
import SportEventDetailsCordovaContent from 'web/src/modules/sportline/submodules/cordova/views/SportEventDetailsCordovaContent.vue';
import SportlineEventDetailsContent from 'web/src/modules/sportline/submodules/event-details/views/SportlineEventDetailsContent.vue';

import { useSportlineEventDetailsRoutePage } from './useSportlineEventDetailsRoutePage';

// @TODO extend SportEventDetailsCordovaContent and SportEventDetailsContent from one component
const SportEventDetailsContent = null
  ? SportEventDetailsCordovaContent
  : SportlineEventDetailsContent;

const SimilarEventsSection = defineAsyncComponent(
  () => import('web/src/modules/sportline/submodules/event-details/views/similar-events/SimilarEventsSection.vue'),
);

const {
  isLoaded,
  isReadyToDisplayContent,
  metaParameters,
  clearDataOnLeavingPage,
} = useSportlineEventDetailsRoutePage();
useSeoMetaParameters(metaParameters);
const {
  initialRequests: initFavoriteSportEvents,
} = useFavoritesEventsStore();

onBeforeMount(() => {
  void initFavoriteSportEvents();
});
onUnmounted(() => {
  clearDataOnLeavingPage();
});
</script>

<template>
  <div v-auto-id="'SportlineEventDetailsRoutePage'"
    class="component-wrapper"
    :class="$style['component-wrapper']"
  >
    <div :class="$style['sport-event-details']">
      <template v-if="isReadyToDisplayContent">
        <SportEventDetailsContent
          v-data-test="{ el: 'sport-event-details-content' }"
        />

        <SimilarEventsSection
          v-if="isLoaded"
          :class="$style['sport-event-details__related']"
        />
      </template>
      <VLoaderMaskedDelayed
        v-else
        key="page-loader"
        :delay="500"
      />
    </div>
  </div>
</template>

<style module lang="scss">
@include for-layout using ($isDesktop) {
  .component-wrapper,
  .sport-event-details {
    display: if($isDesktop, block, flex);
    flex-direction: column;
  }

  .sport-event-details {
    flex: 1 0 100%;

    @if $isDesktop {
      height: 100%;
    }

    &__related {
      flex: 1 0 100%;
    }
  }
}
</style>
