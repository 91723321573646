import RestClient from '@leon-hub/api/src/client/rest/RestClient';
import type { RestClientRequestOptions, BaseClientOptions } from '@leon-hub/api/src/client/types';
import { ApiConnectionError } from '@leon-hub/api';
import { logger } from '@leon-hub/logging';

export default class ApplicationRestClient extends RestClient {
  public constructor(options: BaseClientOptions) {
    super({
      ...options,
      origin: options.origin ?? (process.env.VUE_APP_PRERENDER ? '' : undefined),
    });
  }

  async request<T>(options: RestClientRequestOptions): Promise<T> {
    try {
      return await super.request(options);
    } catch (error) {
      if (this.connectionErrorResolver && error instanceof ApiConnectionError && !error.silent) {
        try {
          logger.debug(`Connection error Rest client: ${options.endpoint}`);
          await this.connectionErrorResolver(error);
          return this.request(options);
        } catch {}
      }

      throw error;
    }
  }
}
