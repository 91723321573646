import type { Ref } from 'vue';
import { ref, computed } from 'vue';

import useMatchStatisticsTeams
  from 'web/src/modules/sportline/submodules/event-statistic/composables/useMatchStatisticsTeams';
import type {
  BetlinePostMatchStatistics,
  BetlineMatchStatisticsTeam,
} from 'web/src/modules/sportline/types/rest';
import type { TimelineMark } from 'web/src/modules/sportline/submodules/core-statistic/types';
import { SportFamily } from 'web/src/modules/sportline/enums';
import {
  filterEmptyMark,
  filterSameTypeMarks,
  parseCardToMark,
  parseGoalToMark,
  parseScoreToMark,
  parseSubstitutionToMark,
  sortMarksByAsc,
} from 'web/src/modules/sportline/submodules/core-statistic/utils';

interface UsePostMatchStatisticIncidentsListProps {
  postMatchStatistic: Ref<BetlinePostMatchStatistics>;
}

interface UsePostMatchStatisticIncidentsListComposable {
  isOpen: Ref<boolean>;
  teams: Ref<Maybe<[BetlineMatchStatisticsTeam, BetlineMatchStatisticsTeam]>>;
  marks: Ref<TimelineMark[]>;
}

export default function usePostMatchStatisticIncidentsList(
  props: UsePostMatchStatisticIncidentsListProps,
): UsePostMatchStatisticIncidentsListComposable {
  const { postMatchStatistic } = props;

  const isOpen = ref(false);

  const { teams } = useMatchStatisticsTeams(postMatchStatistic);

  const marks = computed<TimelineMark[]>(() => {
    const sportFamily = SportFamily.Soccer;
    const breakMarks: TimelineMark[] = postMatchStatistic.value.scores
      .reduce<TimelineMark[]>((result, score) => [...result, ...parseScoreToMark(score, { sportFamily })], [])
      .filter<TimelineMark>(filterSameTypeMarks);
    const cardMarks: TimelineMark[] = postMatchStatistic.value.cards
      .map<Maybe<TimelineMark>>((card) => parseCardToMark(card, { sportFamily }))
      .filter<TimelineMark>(filterEmptyMark);
    const goalsMarks: TimelineMark[] = postMatchStatistic.value.goals
      .map<Maybe<TimelineMark>>((goal) => parseGoalToMark(goal, { sportFamily }))
      .filter<TimelineMark>(filterEmptyMark);
    const substitutionsMarks: TimelineMark[] = postMatchStatistic.value.substitutions
      .map<Maybe<TimelineMark>>((substitution) => parseSubstitutionToMark(substitution, { sportFamily }))
      .filter<TimelineMark>(filterEmptyMark);

    return sortMarksByAsc([...cardMarks, ...goalsMarks, ...substitutionsMarks, ...breakMarks]);
  });

  return {
    isOpen,
    teams,
    marks,
  };
}
