import type { VCircularProgressProps } from 'web/src/components/CircularProgress/VCircularProgress/types';

import { CircleColor, CircleFilterShadows } from '../../enums';

export default function getFilterShadowColorMatrix(
  props: Pick<VCircularProgressProps, 'circleColor'>,
):CircleFilterShadows {
  switch (props.circleColor) {
    case CircleColor.YELLOW:
      return CircleFilterShadows.YELLOW;
    case CircleColor.PURPLE:
      return CircleFilterShadows.PURPLE;
    default:
      return CircleFilterShadows.DEFAULT;
  }
}
