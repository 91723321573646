<script setup lang="ts">
import {
  onBeforeMount,
  onActivated,
  onMounted,
  onDeactivated,
  onUnmounted,
} from 'vue';

import { useSportlineTopEventsSettings } from 'web/src/modules/sportline/composables/settings';
import { useSportlineHome } from 'web/src/modules/home/submodules/sportline/useSportlineHome';
import { useFavoritesEventsStore } from 'web/src/modules/sportline/submodules/favorites/store/events';
import { SportlineHomeBlockPriority } from 'web/src/modules/home/submodules/sportline/enums';
import ShowcaseNavigationRouteComponent
  from 'web/src/modules/home/submodules/showcase/ShowcaseNavigationRouteComponent.vue';
import HomePromotionsBlock from 'web/src/modules/home/submodules/promotions/views/HomePromotionsBlock.vue';
import SportlineEventsBlock
  from 'web/src/modules/home/submodules/sportline/views/sportline-element/SportlineEventsBlock.vue';
import MixedSportlineEventsBlock
  from 'web/src/modules/home/submodules/sportline/views/sportline-element/MixedSportlineEventsBlock.vue';
import { useKeepAlive } from 'web/src/modules/core/composables/keep-alive';

const { isTopEventsEnabled } = useSportlineTopEventsSettings();

const { setBackgroundUpdateEnabled } = useSportlineHome();
const favoritesEventsStore = useFavoritesEventsStore();

onBeforeMount(() => { void favoritesEventsStore.initialRequests(); });
onActivated(() => { void setBackgroundUpdateEnabled(true); });
onMounted(() => { void setBackgroundUpdateEnabled(true); });
onDeactivated(() => { void setBackgroundUpdateEnabled(false); });
onUnmounted(() => { void setBackgroundUpdateEnabled(false); });

useKeepAlive();
</script>

<template>
  <div v-auto-id="'HomeRoutePage'" class="component-wrapper">
    <ShowcaseNavigationRouteComponent />
    <template v-if="isTopEventsEnabled">
      <MixedSportlineEventsBlock />
      <HomePromotionsBlock />
    </template>
    <template v-else>
      <SportlineEventsBlock :priority="SportlineHomeBlockPriority.Live" />
      <HomePromotionsBlock />
      <SportlineEventsBlock :priority="SportlineHomeBlockPriority.Prematch" />
    </template>
  </div>
</template>
