import type { Ref } from 'vue';
import { ref, computed } from 'vue';

import type { BackgroundUpdateStopwatch } from 'web/src/utils/store';

interface BackgroundRequestsProps<Treads extends string[] = []> {
  lastUpdate: BackgroundUpdateStopwatch<Treads>;
  updateInterval: Ref<number>;
  onEnableChanged?(value: boolean): void;
}

export type Payloads<Treads extends string[]> = {
  [tread in Treads[number]]: Record<string, unknown>;
};

export interface BackgroundRequestsComposable<Treads extends string[] = []> {
  isBackgroundUpdateEnabled: Ref<boolean>;
  initialRequests(payloads?: Payloads<Treads>): Promise<void>;
  syncState(options?: {
    silent?: boolean;
    groups?: { [tread in Treads[number]]?: string };
  }, payloads?: Payloads<Treads>): Promise<void>;
  syncBackgroundRequests(options: {
    force?: boolean;
    silent?: boolean;
    groups?: { [tread in Treads[number]]?: string };
  }, payloads?: Payloads<Treads>): Promise<void>;
  setBackgroundUpdateEnabled(value: boolean, payloads?: Payloads<Treads>): Promise<void>;
}

/** Method for external request for background sync state (like prefetch to kept alive page) */
export default function useBackgroundRequestsLifeCycle<Treads extends string[] = []>(
  props: BackgroundRequestsProps<Treads>,
): BackgroundRequestsComposable<Treads> {
  const isBackgroundUpdateEnabled = ref<boolean>(false);

  async function syncBackgroundRequests({ silent, force, groups }: {
    force?: boolean;
    silent?: boolean;
    groups?: { [tread in Treads[number]]?: string };
  }, payloads?: Payloads<Treads>): Promise<void> {
    const syncs = [];

    for (const tread of props.lastUpdate.syncActionsNames) {
      const payload = payloads?.[tread as Treads[number]] ?? {};
      const group = groups?.[tread as Treads[number]];

      if (force) {
        if (group) {
          syncs.push(props.lastUpdate.forceCallGroupSyncAction(tread, group, { silent, ...payload }));
        } else {
          syncs.push(props.lastUpdate.forceCallSyncAction(tread, { silent, ...payload }));
        }
        continue;
      }

      if (group) {
        // eslint-disable-next-line max-len
        syncs.push(props.lastUpdate.callGroupSyncAction(tread, group, props.updateInterval.value, { silent, ...payload }));
      } else {
        syncs.push(props.lastUpdate.callSyncAction(tread, props.updateInterval.value, { silent, ...payload }));
      }
    }

    const results = await Promise.allSettled(syncs);
    for (const result of results) {
      if (result.status === 'rejected') {
        // manual reject promises to throw errors
        void Promise.reject(result.reason);
      }
    }
  }

  async function setBackgroundUpdateEnabled(value: boolean, payloads?: Payloads<Treads>): Promise<void> {
    isBackgroundUpdateEnabled.value = value;

    if (value) {
      await syncBackgroundRequests({ silent: true }, payloads);
    }

    props.onEnableChanged?.(value);
  }

  async function syncState(options: {
    silent?: boolean;
    groups?: { [tread in Treads[number]]?: string };
  }, payloads?: Payloads<Treads>): Promise<void> {
    if (isBackgroundUpdateEnabled.value) {
      await syncBackgroundRequests({ ...options, force: true }, payloads);
    }
  }

  async function initialRequests(payloads?: Payloads<Treads>): Promise<void> {
    await syncBackgroundRequests({ silent: true, force: true }, payloads);
    isBackgroundUpdateEnabled.value = true;
  }

  return {
    // export only getter for ref
    isBackgroundUpdateEnabled: computed(() => isBackgroundUpdateEnabled.value),
    initialRequests,
    syncState,
    syncBackgroundRequests,
    setBackgroundUpdateEnabled,
  };
}
