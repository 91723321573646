import type { BonusCampaignType } from '@leon-hub/api-sdk';
import { getCustomerBonuses } from '@leon-hub/api-sdk';
import type { CustomerBonusesQueryResult } from '@leon-hub/api-types';

import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';

// eslint-disable-next-line max-len
export default function fetchCustomerBonuses(bonusCampaignType?: Optional<BonusCampaignType>): Promise<CustomerBonusesQueryResult> {
  const apiClient = useGraphqlClient();

  return getCustomerBonuses(
    apiClient,
    (node) => node.queries.bonuses.getCustomerBonuses,
    {
      options: { bonusCampaignType },
    },
  );
}
