import type { Ref } from 'vue';
import {
  computed,
  provide,
  toRef,
} from 'vue';
import type { RouteRecordName } from 'vue-router';
import { useRouter } from 'vue-router';

import { BusEvent, useEventsBus } from '@leon-hub/event-bus';

import type { VerticalScrollStatus } from '@components/v-scrollbar';

import { ModalProvidable } from 'web/src/modules/dialogs/enums';
import {
  useDesktopModalStore,
  useRouterStore,
} from 'web/src/modules/core/store';
import type { DesktopModal } from 'web/src/modules/core/types';
import { ModalDesktopPreset } from 'web/src/modules/core/enums';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { useDialogsStore } from 'web/src/modules/dialogs/store';
import type { ModalWidth, ModalHeight } from 'web/src/modules/core/components/DesktopModal/enums';

import {
  getModalHeight,
  getModalWidth,
  getModalPreset,
} from './utils';

export interface UseDesktopModalComposable {
  desktopModal: Ref<DesktopModal | undefined>;
  showSideBar: Ref<boolean>;
  hasInnerModal: Ref<boolean>;
  isNoMinHeightLimit: Ref<boolean>;
  modalWidth: Ref<ModalWidth>;
  modalHeight: Ref<ModalHeight>;
  modalPreset: Ref<ModalDesktopPreset | undefined>;
  currentRouteName: Ref<RouteRecordName | null | undefined>;
  onOverlayClicked(): void;
  onInnerScroll(status: VerticalScrollStatus): void;
}

export default function useDesktopModal(): UseDesktopModalComposable {
  const dialogsStore = useDialogsStore();
  const routerStore = useRouterStore();
  const currentDialog = toRef(dialogsStore, 'currentDialog');
  const hasInnerDialog = toRef(dialogsStore, 'hasInnerDialog');
  const bus = useEventsBus();
  const currentRouteName = toRef(routerStore, 'currentRouteName');

  const desktopModal = toRef(useDesktopModalStore(), 'desktopModal');
  const { isLoggedIn } = useIsLoggedIn();
  const router = useRouter();

  const hasInnerModal = computed(() => !!currentDialog.value || hasInnerDialog.value);
  const modalPreset = computed(() => getModalPreset({
    preset: desktopModal.value?.preset,
    isLoggedIn: isLoggedIn.value,
  }));
  const modalHeight = computed(() => getModalHeight(modalPreset.value));
  const modalWidth = computed(() => getModalWidth(modalPreset.value));
  const showSideBar = computed(() => !!desktopModal.value?.isProfile && isLoggedIn.value);
  const isNoMinHeightLimit = computed<boolean>(() => !!desktopModal.value?.isNoMinHeightLimit);

  provide(ModalProvidable.modalContext, {
    insideModal: true,
  });

  function onOverlayClicked(): void {
    if (modalPreset.value === ModalDesktopPreset.SearchDesktopModal) {
      void router.closeModal();
    }
  }

  function onInnerScroll(status: VerticalScrollStatus) {
    bus.emit(BusEvent.MODAL_CONTENT_SCROLL, { ...status });
  }

  return {
    isNoMinHeightLimit,
    modalWidth,
    modalHeight,
    modalPreset,
    hasInnerModal,
    desktopModal,
    showSideBar,
    currentRouteName,
    onOverlayClicked,
    onInnerScroll,
  };
}
