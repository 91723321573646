import type { Ref, ComputedRef } from 'vue';
import { computed, ref } from 'vue';

import type { ComponentIntersectiveInstance } from '@leon-hub/vue-utils';
import { assert } from '@leon-hub/guards';
import { Timer } from '@leon-hub/utils';
import { nextAnimationFrame } from '@leon-hub/html-utils';
import type { Focusable } from '@leon-hub/focus';

import type { VScrollbarRef } from '@components/v-scrollbar';

import { isDropListOptionElementRef } from '../guards';
import type {
  DropListSelectProps,
  DropListSelectOption,
} from '../types';
import { getNextPreselectedIndex } from '../utils';
import { Keys } from '../constants/keys';

/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */

interface UseDropListEventHandlers {
  setOptionRefs(option: unknown, groupIndex: number, index: number): void;
  scrollbarRef: Ref<VScrollbarRef | undefined>;
  onOptionHover(value: string): void;
  onInputKeydown(event: KeyboardEvent): void;
  onDropdownOptionKeydown(event: KeyboardEvent): void;
  primaryButtonRef: Ref<Focusable | undefined>;
  secondaryButtonRef: Ref<Focusable | undefined>;
  onActionButtonKeydown(event: KeyboardEvent): void;
  onPrimaryButtonFocus(): void;
  onSecondaryButtonFocus(): void;
  onPrimaryButtonBlur(): void;
  onSecondaryButtonBlur(): void;
}

interface Options {
  preselectedId: Ref<string | null>;
  setPreselected(value: string): void;
  allFlatOptions: ComputedRef<DropListSelectOption[]>;
  focusOnSearch(): void;
  focusOnDropdownButton(): void;
  onOpen(): void;
  onClose(): void;
  onPreselect(): void;
  isDropdownShown: Ref<boolean>;
  lastRenderedCount: Ref<number>;
  increasePaginationCount(): Promise<void>;
}

export function useDropListEventHandlers(
  props: DropListSelectProps,
  {
    setPreselected,
    preselectedId,
    allFlatOptions,
    focusOnSearch,
    focusOnDropdownButton,
    onOpen,
    onClose,
    onPreselect,
    isDropdownShown,
    lastRenderedCount,
    increasePaginationCount,
  }: Options,
): UseDropListEventHandlers {
  const optionRefs = ref<ComponentIntersectiveInstance[][]>([[]]);

  const setOptionRefs = (option: unknown, groupIndex: number, index: number): void => {
    if (option) {
      assert(isDropListOptionElementRef(option));
      if (!optionRefs.value[groupIndex]) {
        optionRefs.value[groupIndex] = [];
      }
      optionRefs.value[groupIndex][index] = option;
    }
  };

  const flatOptionRefs = computed<ComponentIntersectiveInstance[]>(() => optionRefs.value.flat());

  const scrollbarRef = ref<VScrollbarRef | undefined>();

  const primaryButtonRef = ref<Focusable | undefined>();
  const secondaryButtonRef = ref<Focusable | undefined>();

  const isPrimaryButtonFocused = ref<boolean>(false);
  const isSecondaryButtonFocused = ref<boolean>(false);

  const onPrimaryButtonFocus = (): void => {
    setPreselected('');
    isPrimaryButtonFocused.value = true;
  };
  const onSecondaryButtonFocus = (): void => {
    setPreselected('');
    isSecondaryButtonFocused.value = true;
  };

  const onPrimaryButtonBlur = (): void => {
    isPrimaryButtonFocused.value = true;
  };
  const onSecondaryButtonBlur = (): void => {
    isSecondaryButtonFocused.value = false;
  };

  let isScrollingIntoView = false;

  let scrollTimer = 0;

  const onOptionHover = (value: string): void => {
    if (!isScrollingIntoView) {
      setPreselected(value);
    }
  };

  const scrollToPreselected = (index: number, oldIndex: number): void => {
    if (index < 0) {
      return;
    }
    Timer.clearTimeout(scrollTimer);
    const safeIndex = flatOptionRefs.value[index] ? index : 0;
    const button = flatOptionRefs.value[safeIndex];
    isScrollingIntoView = true;
    scrollTimer = Timer.setTimeout(async () => {
      scrollbarRef.value?.scrollToElement(button, {
        onlyIfNeeded: true,
        alignToTop: oldIndex > safeIndex,
      });
      await nextAnimationFrame();
      isScrollingIntoView = false;
    }, 0);
  };

  const focusOnFirstButton = (): void => {
    if (primaryButtonRef.value) {
      primaryButtonRef.value.focus();
    } else if (secondaryButtonRef.value) {
      secondaryButtonRef.value.focus();
    }
  };

  const setNextPreselected = (isMovingForward = true): void => {
    const currentIndex = preselectedId.value ? allFlatOptions.value.findIndex((item) => item.value === preselectedId.value) : -1;
    if (currentIndex === -1 && !isMovingForward) {
      return;
    }
    const lastIndex = allFlatOptions.value.length - 1;
    const nextIndex = getNextPreselectedIndex(isMovingForward, currentIndex, lastIndex);
    if (nextIndex + 1 === lastRenderedCount.value) {
      void increasePaginationCount();
    }
    if (nextIndex === -1) {
      if (props.searchEnabled) {
        focusOnSearch();
      } else {
        scrollbarRef.value?.scrollTop();
      }
      return;
    }
    if (nextIndex > lastIndex) {
      if (props.primaryButtonLabel || props.secondaryButtonLabel) {
        focusOnFirstButton();
      } else {
        scrollbarRef.value?.scrollDown();
      }
      return;
    }
    const nextPreselectedId = allFlatOptions.value[nextIndex]?.value ?? '';
    setPreselected(nextPreselectedId);
    scrollToPreselected(nextIndex, currentIndex);
  };

  const onInputKeydown = (event: KeyboardEvent): void => {
    if (event.code === Keys.ArrowDown || event.code === Keys.Tab) {
      event.preventDefault();
      setNextPreselected(true);
      focusOnDropdownButton();
      return;
    }
    if (event.code === Keys.Enter) {
      event.preventDefault();
      return;
    }
    if (event.code === Keys.Escape) {
      onClose();
    }
  };

  const onDropdownOptionKeydown = (event: KeyboardEvent): void => {
    const { code } = event;
    if (isDropdownShown.value) {
      event.preventDefault();
      if (code === Keys.Enter || code === Keys.Space) {
        onPreselect();
      }
      if (code === Keys.ArrowUp || code === Keys.ArrowDown || code === Keys.Tab) {
        const isMovingForward = code !== Keys.ArrowUp;
        setNextPreselected(isMovingForward);
      }
      if (code === Keys.Escape) {
        onClose();
      }
    } else if (code === Keys.ArrowUp || code === Keys.ArrowDown) {
      event.preventDefault();
      onOpen();
    }
  };

  const onActionButtonKeydown = (event: KeyboardEvent): void => {
    const { code } = event;
    if (code === Keys.Tab
      || code === Keys.ArrowUp
      || code === Keys.ArrowLeft
      || code === Keys.ArrowRight
      || code === Keys.ArrowDown
    ) {
      event.preventDefault();
    } else {
      return;
    }
    if ((isPrimaryButtonFocused.value && secondaryButtonRef.value) && (code === Keys.Tab || code === Keys.ArrowDown || code === Keys.ArrowRight)) {
      secondaryButtonRef.value.focus();
      return;
    }
    if (isSecondaryButtonFocused.value && primaryButtonRef.value && (code === Keys.ArrowLeft || code === Keys.ArrowUp)) {
      primaryButtonRef.value.focus();
      return;
    }
    if (code === Keys.ArrowUp) {
      focusOnDropdownButton();
      setPreselected(allFlatOptions.value[allFlatOptions.value.length - 1].value);
    }
    if (code === Keys.Tab) {
      focusOnDropdownButton();
    }
  };

  return {
    setOptionRefs,
    scrollbarRef,
    onOptionHover,
    onInputKeydown,
    onDropdownOptionKeydown,
    primaryButtonRef,
    secondaryButtonRef,
    onActionButtonKeydown,
    onPrimaryButtonFocus,
    onPrimaryButtonBlur,
    onSecondaryButtonFocus,
    onSecondaryButtonBlur,
  };
}
