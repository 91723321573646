const INCORRECT_LENGTH = 'incorrect pathFragments length';

export function mergeString<V>(dataPathFragments: string[], result: Map<string, V>, value: V): Map<string, V> {
  if (dataPathFragments.length !== 1) {
    throw new Error(INCORRECT_LENGTH);
  }

  const field = dataPathFragments[0];

  if (result.has(field)) {
    return result;
  }

  result.set(field, value);

  return result;
}

export function mergeObjectArray<V extends { [key: string]: T }, T>(
  dataPathFragments: string[],
  result: Map<string, (V | null)[]>,
  value: T,
): Map<string, (V | null)[]> {
  if (dataPathFragments.length !== 3) {
    throw new Error(INCORRECT_LENGTH);
  }

  const field = dataPathFragments[0];

  const errorList: (V | null)[] = result.get(field) || [];
  const newErrorList: (V | null)[] = errorList.map<V | null>((errorObject) => {
    if (errorObject) {
      return { ...errorObject };
    }
    return null;
  });
  const pathIndex = parseInt(dataPathFragments[1], 10);
  const errorObject: { [key: string]: T } = newErrorList[pathIndex] || {};

  if (pathIndex > 0) {
    for (let index = 0, range = pathIndex; index < range; index += 1) {
      if (typeof newErrorList[index] === 'undefined') {
        newErrorList[index] = null;
      }
    }
  }

  newErrorList[pathIndex] = {
    ...errorObject,
    [dataPathFragments[2]]: typeof value !== 'undefined' ? value : null,
  } as (V | null);

  result.set(field, newErrorList);

  return result;
}

export function mergeObject<S extends { [key: string]: V }, V>(
  dataPathFragments: string[],
  result: Map<string, S>,
  value: V,
): Map<string, S> {
  if (dataPathFragments.length !== 2) {
    throw new Error(INCORRECT_LENGTH);
  }

  const field = dataPathFragments[0];

  const currentValue: S = result.get(field) || {} as S;
  const newValue: { [key: string]: V } = {
    [dataPathFragments[1]]: value,
  };

  result.set(field, { ...currentValue, ...newValue });

  return result;
}

export function mergeArray<V>(
  dataPathFragments: string[],
  result: Map<string, (V | null)[]>,
  value: V,
): Map<string, (V | null)[]> {
  if (dataPathFragments.length !== 2) {
    throw new Error(INCORRECT_LENGTH);
  }

  const field = dataPathFragments[0];

  const currentValue: (V | null)[] | undefined = result.get(field);
  const newValue = currentValue ? [...currentValue] : [];
  const pathIndex = parseInt(dataPathFragments[1], 10);

  if (pathIndex > 0) {
    for (let index = 0, range = pathIndex; index < range; index += 1) {
      if (typeof newValue[index] === 'undefined') {
        newValue[index] = null;
      }
    }
  }

  newValue[pathIndex] = typeof value !== 'undefined' ? value : null;

  result.set(field, newValue);

  return result;
}
