import { toRef } from 'vue';
import type { Ref } from 'vue';

import { useHeaderStore } from 'web/src/modules/core/store';
import { useSportlineEventDetailsStore } from 'web/src/modules/sportline/submodules/event-details/store';
import type { SportlineEventDetails } from 'web/src/modules/sportline/types/details';

interface UseMiniBoardStateComposable {
  isMiniBoardShown: Ref<boolean>;
  isMiniBoardAllowed: Ref<boolean>;
  sportEventDetails: Ref<Maybe<SportlineEventDetails>>;
  headerHeight: Ref<number>;
}

export function useMiniBoardState(): UseMiniBoardStateComposable {
  const sportlineEventDetailsStore = useSportlineEventDetailsStore();

  const isMiniBoardShown = toRef(() => sportlineEventDetailsStore.isMiniBoardShown);
  const isMiniBoardAllowed = toRef(() => sportlineEventDetailsStore.isMiniBoardAllowed);
  const sportEventDetails = toRef(() => sportlineEventDetailsStore.sportEventDetails);

  const headerStore = useHeaderStore();
  const headerHeight = toRef(() => headerStore.height);

  return {
    isMiniBoardShown,
    isMiniBoardAllowed,
    sportEventDetails,
    headerHeight,
  };
}
