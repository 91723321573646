import { SlipStatus } from '@leon-hub/api-sdk';

import type { BatchedSlipInfoDocument } from 'web/src/modules/slip/submodules/slip-info/types';

export function clearStatusOnBatchedSlipInfo(currentSlipInfo: BatchedSlipInfoDocument): BatchedSlipInfoDocument {
  return {
    ...currentSlipInfo,
    slipEntries: currentSlipInfo.slipEntries.map((slipEntry) => ({
      ...slipEntry,
      status: SlipStatus.OK,
      message: '',
    })),
  };
}
