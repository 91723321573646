const pad = (time: number, digits = 2): string => (`00${time}`).slice(-digits);

// eslint-disable-next-line import/prefer-default-export
export const millisecondsToPrettyTime = (milliseconds: number): string => {
  const ms = milliseconds % 1000;
  const seconds = ((milliseconds - ms) / 1000) % 60;
  const minutes = ((milliseconds - seconds) / 60) % 60;

  return `${pad(minutes)}:${pad(seconds)}.${pad(ms, 3)}`;
};
