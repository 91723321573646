import type { BatchedSlipInfoDocument, SlipEntryId, SlipInfoArray } from '../../slip-info/types';
import getSlipEntryId from '../../../utils/getSlipEntryId';

interface Payload {
  id: SlipEntryId;
  currentSlipInfo: BatchedSlipInfoDocument;
}

export default function clearMultiSingleMarketStatusById({
  id, currentSlipInfo,
}: Payload): SlipInfoArray {
  const updatedEntries: SlipInfoArray = [];
  for (const entry of currentSlipInfo.slipEntries) {
    const firstEntry = entry.entries[0];
    if (firstEntry) {
      const isTargetEntry = getSlipEntryId(firstEntry) === id;
      updatedEntries.push({
        ...entry,
        entries: [{
          ...firstEntry,
          marketStatus: isTargetEntry ? null : firstEntry.marketStatus,
        }],
      });
    }
  }
  return updatedEntries;
}
