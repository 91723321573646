export enum LobbyGridLayoutType {
  BIG_SMALL_LARGE = 'big-small-large',
  BIG_SMALL_SMALL = 'big-small-small',
  SQUARE_ROW = 'square-row',
  COLUMN_1 = 'column-1',
  COLUMN_2 = 'column-2',
  COLUMN_3 = 'column-3',
  COLUMN_4 = 'column-4',
  COLUMN_GAME_DEFAULT = 'column-game-default',
  COLUMN_GAME_LARGE = 'column-game-large',
  COLUMN_GAME_SMALL = 'column-game-small',
  COLUMN_SQUARE_GAME_DEFAULT = 'column-square-game-default',
  COLUMN_SQUARE_GAME_LARGE = 'column-square-game-large',
  COLUMN_SQUARE_GAME_SMALL = 'column-square-game-small',
  COLUMN_PROMOTION = 'column-promotion',
  COLUMN_TOP_WINNER = 'column-top-winner',
  COLUMN_JACKPOT = 'column-jackpot',
  COLUMN_GROUP = 'column-group',
  COLUMN_BANNER = 'column-banner',
  COLUMN_SPORTLINE_TOP_MATCH = 'column-sportline-top-match',
  COLUMN_MODAL_GAME_DEFAULT = 'column-modal-game-default',
  COLUMN_MODAL_GAME_SMALL = 'column-modal-game-small',
  COLUMN_MODAL_GAME_SQUARE_SMALL = 'column-modal-game-square-small',
  COLUMN_NO_BG_GAME_DEFAULT = 'column-no-bg-game-default',
  COLUMN_NO_BG_GAME_SMALL = 'column-no-bg-game-small',
  COLUMN_NO_BG_GAME_LARGE = 'column-no-bg-game-large',
  COLUMN_NO_BG_GAME_SQUARE_SMALL = 'column-no-bg-game-square-small',
}

export enum LobbyItemLayoutType {
  GAME_DEFAULT = 'game-default',
  GAME_JACKPOT = 'game-jackpot',
  GAME_BIG_ROW = 'game-big-row',
  GAME_SQUARE = 'game-square',
  PROMOTION = 'promotion',
  BANNER = 'banner',
  SPORTLINE_TOP_MATCH = 'sportline-top-match',
  GROUP = 'group',
  TOP_WINNER_BIG = 'top-winner-big',
  TOP_WINNER_SMALL = 'top-winner-small',
}
