import type { RouteLocationNormalizedLoaded } from 'vue-router';

import { logger } from '@leon-hub/logging';
import {
  assert,
  isString,
} from '@leon-hub/guards';

import type { RoutingSeoData } from 'web/src/modules/core/types';

const SEO_KEY_PLACEHOLDERS_REGEX = /(?:{)(.*?)(?:})/g;

export default function getCmsKeys(
  routeName: RouteLocationNormalizedLoaded['name'],
  routeParams: RouteLocationNormalizedLoaded['params'],
  seoConfigs?: Record<string, RoutingSeoData>,
): string[] {
  if (!routeName || !seoConfigs) {
    return [];
  }

  assert(isString(routeName));

  const seoConfig = seoConfigs[routeName];

  let seoKeys: string[] = [];
  if (seoConfig?.seoData?.keys && seoConfig.seoData.keys.length) {
    seoKeys.push(...seoConfig.seoData.keys);
  } else if (seoConfig?.seoData?.key) {
    seoKeys.push(seoConfig?.seoData?.key);
  } else if (seoConfig?.seoKey) {
    seoKeys.push(seoConfig.seoKey);
  }

  if (!seoKeys.length) {
    return [];
  }

  const normalizedParams: { [key: string]: string } = {};
  for (const key of Object.keys(routeParams)) {
    const value = routeParams[key];
    assert(isString(value));

    normalizedParams[key.toLowerCase()] = value;
  }

  seoKeys = seoKeys.map((seoKey) => {
    let cmsKey = seoKey;
    let placeHolder;
    while ((placeHolder = SEO_KEY_PLACEHOLDERS_REGEX.exec(seoKey)) != null) {
      const placeHolderValue = normalizedParams[placeHolder[1].toLowerCase()];
      cmsKey = placeHolderValue
        ? cmsKey.replace(`${placeHolder[0]}`, placeHolderValue.toUpperCase())
        : cmsKey.replace(new RegExp(`_?${placeHolder[0]}`), '');
    }

    if (/[{}]/g.test(cmsKey)) {
      logger.error(`Invalid cmsKey=${cmsKey}`);
      return '';
    }

    return cmsKey;
  });

  return seoKeys.filter((value) => !!value);
}
