import type { IntercomCustomAttributes } from '@leon-hub/cordova';

import type { IntercomSettings } from 'web/src/modules/intercom/composables/types';
import type { IntercomUpdateOptions } from 'web/src/modules/intercom/composables/types/IntercomUpdateOptions';

interface Intercom {
  (e: 'update', options?: IntercomUpdateOptions): void;
  (e: 'show'): void;
  (e: 'showSpace', showSpaceName: string): void;
  (e: 'shutdown'): void;
  (e: 'boot', settings?: IntercomSettings): void;
  (e: 'onHide', callback: () => void): void;
  (e: 'onShow', callback: () => void): void;
  (e: 'trackEvent', eventName: string, customAttributes?: IntercomCustomAttributes): void;
  (e: 'onUnreadCountChange', callback: (unreadCount: number) => void): void;
}

declare global {
  interface Window {
    Intercom?: Intercom;
  }
}

export const getIntercom = (): undefined | Intercom => window.Intercom;
