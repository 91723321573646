import type { Ref, MaybeRef } from 'vue';
import { computed, toRef } from 'vue';

import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import { SportlineType } from 'web/src/modules/sportline/enums';

import { checkMatchStreamIndicator } from '../utils';
import { useMatchStreamAccess } from './useMatchStreamAccess';

interface StreamAvailableIndicatorProps {
  sportEventId: MaybeRef<Optional<Maybe<string>>>;
  sportlineType: MaybeRef<Optional<Maybe<SportlineType>>>;
}

interface StreamAvailableIndicatorComposable {
  isStreamIndicatorAvailable: Ref<boolean>;
}

export function useStreamAvailableIndicator(
  props: StreamAvailableIndicatorProps,
): StreamAvailableIndicatorComposable {
  const sportlineEventId = toRef(props.sportEventId);
  const sportlineType = toRef(props.sportlineType);

  const isStreamsEnabled = toRef(() => useSportlineSettingsStore().isLiveStreamEnabled);
  const matchStreamAccess = useMatchStreamAccess();

  const isStreamIndicatorAvailable = computed<boolean>(() => (
    isStreamsEnabled.value
    && !!sportlineEventId.value
    && checkMatchStreamIndicator(matchStreamAccess.value, {
      id: sportlineEventId.value,
      type: sportlineType.value ?? SportlineType.Live,
    })
  ));

  return { isStreamIndicatorAvailable };
}
