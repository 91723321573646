import { useDoRegistration } from '@leon-hub/api-sdk';
import type { UseApiMethod } from '@leon-hub/api-types';

import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import { useShieldIntegration } from 'web/src/modules/shield-integration';

export function useRegistrationHook(): UseApiMethod {
  if (process.env.VUE_APP_FEATURE_SHIELD_INTEGRATION_ENABLED) {
    const { load, sessionId } = useShieldIntegration();
    // eslint-disable-next-line no-console
    void load().catch((exception: unknown) => console.error(exception));
    // Subscribe on registration request to add variables.
    return useDoRegistration(useGraphqlClient(), (context) => {
      const { simpleRegistration } = context.variables.options;
      if (simpleRegistration) {
        return {
          ...context,
          variables: {
            ...context.variables,
            options: {
              ...context.variables.options,
              simpleRegistration: {
                ...simpleRegistration,
                helmetDeviceId: sessionId.value,
              },
            },
          },
        };
      }
      return context;
    });
  }
  return { stop() {} };
}
