import type { BuildSportOptions } from 'web/src/modules/sportline/types/rest';

export default function extractBuildSportOptions(options?: Maybe<Partial<BuildSportOptions>>): BuildSportOptions {
  if (!options) {
    return {
      sportSegmentMapping: null,
      sportFamilyMapping: null,
      sportRepresentationSettings: null,
      canUseZeroMargin: false,
    };
  }

  return {
    sportSegmentMapping: options.sportSegmentMapping ?? null,
    sportFamilyMapping: options.sportFamilyMapping ?? null,
    sportRepresentationSettings: options.sportRepresentationSettings ?? null,
    canUseZeroMargin: options.canUseZeroMargin ?? false,
  };
}
