import type { Ref } from 'vue';
import {
  computed, onMounted, onUnmounted, ref, watch,
} from 'vue';
import { useRouter } from 'vue-router';

import type { OpenIn } from '@leon-hub/api-sdk';
import { PaymentType } from '@leon-hub/api-sdk';
import { RouteName } from '@leon-hub/routing-config-names';

import type { PaymentSystemEmits, PaymentSystemProps } from 'web/src/modules/payments/components/PaymentSystem/types';
import { useTheme } from 'web/src/modules/theme/composables';
import type { PaymentsLogoProperties } from 'web/src/modules/payments/types';
import { PaymentSystemName } from 'web/src/modules/payments/types';
import type { FormOutput } from 'web/src/components/Form';
import type { PaymentFormDefaultField } from 'web/src/modules/payments/types/documentTypes';
import type { PaymentsHintBlockProps } from 'web/src/modules/payments/components/PaymentsHintBlock/types';
import { PaymentFormFieldName, PaymentsFormStep, PaymentsSubmitAction } from 'web/src/modules/payments/enums';
import { getPaymentSystemLogoSrc } from 'web/src/modules/payments/utils/getPaymentSystemLogoSrc';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import type { VFormRef } from 'web/src/components/Form/components/VForm/types';
import { useFormatMoney } from 'web/src/modules/money/composables';
import { useI18nStore } from 'web/src/modules/i18n/store';

interface PaymentSystemComposable {
  showPaymentsLogo: Ref<boolean>;
  paymentsLogoProperties: Ref<PaymentsLogoProperties>;
  isDeposit: Ref<boolean>;
  labelPropertiesEnrollmentTime: Ref<string>;
  isDeptsupisstreetterminal: Ref<boolean>;
  paymentsHintBlockProperties: Ref<PaymentsHintBlockProps>;
  hasInitiateModal: Ref<boolean>;
  onSubmit(data: FormOutput<PaymentFormDefaultField, PaymentFormFieldName>, action: PaymentsSubmitAction): void;
  onInput(data: FormOutput<PaymentFormDefaultField, PaymentFormFieldName>): void;
  onChange(data: FormOutput<PaymentFormDefaultField, PaymentFormFieldName>): void;
  pushToMainPaymentPage(): void;
  openIn: Ref<Maybe<Optional<OpenIn>>>;
  onDisabledClick(data: FormOutput<PaymentFormDefaultField, PaymentFormFieldName>): void;
  onIconSuffixClick(): void;
  manualSubmit(): void;
  isYMHide: Ref<boolean>;
  paymentForm: Ref<VFormRef | undefined>;
  isCryptoWithdrawal: Ref<boolean>;
  minAMount: Ref<string>;
}

const desktopScrollSelector = '#desktop-modal .scrollbar';

// eslint-disable-next-line max-len
export function usePaymentSystem(props: PaymentSystemProps, emits: PaymentSystemEmits): PaymentSystemComposable {
  const { isDark } = useTheme();
  const router = useRouter();
  const analytics = useAnalytics();
  const formatMoney = useFormatMoney();
  const { $t } = useI18nStore();

  const paymentForm = ref<VFormRef>();

  const hasInitiateModal = ref(props.initialModalProperties !== null);

  let styleAttribute: Maybe<Optional<string>>;

  function onSubmit(data: FormOutput<PaymentFormDefaultField, PaymentFormFieldName>): void {
    if (!data.errors && !data.customErrors) {
      emits('submit', data, PaymentsSubmitAction.SUBMIT);
    }
  }

  onMounted(() => {
    // workaround for desktop modal
    if (process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED && process.env.VUE_APP_LAYOUT_DESKTOP) {
      styleAttribute = document.querySelector(desktopScrollSelector)?.getAttribute('style');
      document.querySelector(desktopScrollSelector)?.setAttribute('style', '');
    }
  });

  onUnmounted(() => {
    // workaround for desktop modal
    if (process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED && process.env.VUE_APP_LAYOUT_DESKTOP && styleAttribute) {
      document.querySelector(desktopScrollSelector)?.setAttribute('style', styleAttribute);
    }
  });

  function manualSubmit(): void {
    paymentForm.value?.submit();
  }
  function onInput(data: FormOutput<PaymentFormDefaultField, PaymentFormFieldName>) {
    emits('input', data);
  }

  function onChange(data: FormOutput<PaymentFormDefaultField, PaymentFormFieldName>) {
    emits('change', data);
  }

  const isDeposit = computed(
    () => props.paymentSystem?.paymentType === PaymentType.DEPOSIT,
  );

  const isCryptoWithdrawal = computed(() => !!(!isDeposit.value && props.paymentSystem?.isCrypto));

  const labelPropertiesEnrollmentTime = computed(() => props.paymentSystem?.enrollmentTime || '');

  const isDeptsupisstreetterminal = computed(
    () => props.paymentSystem?.id === PaymentSystemName.DEPTSUPISSTEREETTERMINAL,
  );

  const isP2p = computed(() => {
    if (props.paymentSystem && 'isP2p' in props.paymentSystem) {
      return props.paymentSystem?.isP2p;
    }

    return false;
  });

  const isP2pSecondStep = computed(() => props.formStep === PaymentsFormStep.SECOND && isP2p.value);

  const minAmountFormatted = computed(() => (props.paymentSystem?.minAmount
    ? formatMoney(props.paymentSystem?.minAmount.amount, { currency: props.paymentSystem?.minAmount.currency })
    : null));

  const minAMount = computed(() => $t('WEB2_PAYMENTS_MINIMAL_WITHDRAWAL', ref({
    sum: minAmountFormatted.value || '0',
  })).value);

  const paymentsHintBlockProperties = computed<PaymentsHintBlockProps>(() => ({
    showAdditionalInformation: (props.formStep === PaymentsFormStep.INITIAL && !isP2p.value) || isP2pSecondStep.value,
    isTerminal: props.paymentSystem?.isTerminal ?? false,
    message: props.paymentSystem?.description ?? '',
    open: !!props.paymentSystem?.description && !!props.paymentSystem?.isTerminal,
    showOtherMethodButton: props.formStep === PaymentsFormStep.INITIAL
      || props.formStep === PaymentsFormStep.NEW_CARD
      || props.formStep === PaymentsFormStep.SECOND,
    isDeptsupisstreetterminal: isDeptsupisstreetterminal.value,
    showNoSpoiler: props.paymentSystem?.showAdditionalInfo,
  }));

  function pushToMainPaymentPage(): void {
    const methodName = isDeposit.value ? 'depositMethod' : 'withdrawalMethod';
    analytics.clickMap({
      [methodName]: 'chooseAnotherMethod',
    });
    const whereToPush = isDeposit.value ? RouteName.DEPOSITS : RouteName.WITHDRAWALS;
    if (props.previousRouteName === whereToPush) {
      router.back();
    } else {
      void router.replace({ name: whereToPush });
    }
  }

  const openIn = computed(() => {
    if (props.paymentSystem && 'openIn' in props.paymentSystem) {
      return props.paymentSystem.openIn;
    }
    return null;
  });

  function onDisabledClick(data: FormOutput<PaymentFormDefaultField, PaymentFormFieldName>): void {
    if (props.initialModalProperties && data.formData[PaymentFormFieldName.STEP] === PaymentsFormStep.SECOND) {
      hasInitiateModal.value = true;
    }
    emits('disabled-click', data);
  }

  function onIconSuffixClick(): void {
    if (props.initialModalProperties) {
      hasInitiateModal.value = true;
    }
  }

  const isYMHide = computed(
    () => props.formStep === PaymentsFormStep.NEW_CARD || props.formStep === PaymentsFormStep.CVC,
  );

  watch(() => props.initialModalProperties, (newVal, oldVal) => {
    if (oldVal === null && newVal !== null) {
      hasInitiateModal.value = true;
    }
  });

  return {
    showPaymentsLogo: computed(() => props.formStep !== PaymentsFormStep.CVC
        && props.formStep !== PaymentsFormStep.NEW_CARD
        && !isP2pSecondStep.value),
    paymentsLogoProperties: computed(() => getPaymentSystemLogoSrc(props.paymentSystem, isDark.value)),
    isDeposit,
    labelPropertiesEnrollmentTime,
    isDeptsupisstreetterminal,
    paymentsHintBlockProperties,
    hasInitiateModal,
    onSubmit,
    onInput,
    onChange,
    pushToMainPaymentPage,
    openIn,
    onDisabledClick,
    onIconSuffixClick,
    manualSubmit,
    isYMHide,
    paymentForm,
    isCryptoWithdrawal,
    minAMount,
  };
}
