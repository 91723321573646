<script lang="ts" setup>
import { toRef } from 'vue';

import type { BetlineLeaguePlayoffRoundBlock } from 'web/src/modules/sportline/types/rest';
import StatisticTeamLogo from 'web/src/modules/sportline/submodules/event-statistic/components/StatisticTeamLogo.vue';
import { PopperApplyTarget, PopperPosition } from 'web/src/components/Popper/VPopper/enums';
import VPopper from 'web/src/components/Popper/VPopper/VPopper.vue';

import { useLeaguePlayoffMatchesBlock } from './useLeaguePlayoffMatchesBlock';
import LeaguePlayoffMatchesBlockDetalis from './LeaguePlayoffMatchesBlockDetalis.vue';

interface LeaguePlayoffMatchesBlockProps {
  block: BetlineLeaguePlayoffRoundBlock;
}

const props = defineProps<LeaguePlayoffMatchesBlockProps>();
const block = toRef(props, 'block');

const {
  matchScores,
  participants,
} = useLeaguePlayoffMatchesBlock({ block });

const popperModifiers = [{
  name: 'offset',
  options: {
    offset: ({ popper }: { popper: { height: number } }) => [-1, -popper.height + 1],
  },
}];
</script>

<template>
  <div v-auto-id="'LeaguePlayoffMatchesBlock'" :class="$style['league-playoff-matches-block']">
    <VPopper
      :position="PopperPosition.TOP_START"
      :has-offset="false"
      :apply-target="PopperApplyTarget.Body"
      is-full-width
      :modifiers="popperModifiers"
    >
      <template #reference>
        <div :class="$style['league-playoff-matches-block__matches']">
          <div
            v-for="(participant, participantIndex) in participants"
            :key="participantIndex"
            :class="{
              [$style['league-playoff-matches-block__match match-block-team']]: true,
              [$style['league-playoff-matches-block__match--winner']]: participant.winner,
            }"
          >
            <template v-if="participant.team">
              <div :class="$style['match-block-team__identity']">
                <StatisticTeamLogo
                  :class="$style['match-block-team__logo']"
                  :team="participant.team"
                />

                <div :class="$style['match-block-team__name']">
                  {{ participant.team.name }}
                </div>
              </div>

              <div
                v-for="(scores, scoresKey) in matchScores"
                :key="scoresKey"
                :class="$style['match-block-team__result-score']"
              >
                {{ participant.team.id ? (scores[participant.team.id] || '-') : '-' }}
              </div>
            </template>
          </div>
        </div>
      </template>

      <template #default="{ shown, hide }">
        <LeaguePlayoffMatchesBlockDetalis
          v-if="shown"
          :block="block"
          @require-close="hide"
        />
        <div v-else />
      </template>
    </VPopper>
  </div>
</template>

<style lang="scss" module>
.league-playoff-matches-block {
  // separator color
  background-color: var(--Highlight);
  border: 1px solid var(--Highlight);
  border-radius: 5px;

  &__matches {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1px;
  }

  &__match {
    @include medium\14\16\025;

    color: var(--TextSecondary);
    background-color: var(--Layer2);

    &--winner {
      color: var(--TextDefault);
    }
  }
}

.match-block-team {
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px;
  overflow: hidden;

  &__identity {
    display: flex;
    flex-grow: 1;
    align-items: center;
    overflow: hidden;
  }

  &__logo {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    overflow: hidden;
  }

  &__name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__result-score {
    flex: 0 0 20px;
    width: 20px;
    overflow: hidden;
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
