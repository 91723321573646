/* eslint-disable sonarjs/prefer-single-boolean-return */
import { AbstractError } from '@leon-hub/errors';
import { InvalidCodeExceptionCode } from '@leon-hub/api-sdk';

import { isCommonWhitelistedError } from './isCommonWhitelistedError';

export function isSentryLoggableError(error: unknown): boolean {
  if (isCommonWhitelistedError(error)) {
    return false;
  }

  if (error instanceof AbstractError
      && error.code.equals(InvalidCodeExceptionCode.INVALID_CODE.toString())) {
    return false;
  }

  return true;
}
