<script lang="ts" setup>
import { TextInput } from 'web/src/components/Input';
import { CustomInputEventType, TextInputTypes } from 'web/src/components/Input/enums';

import { useBaseFormWidgetEvents, useCommonFormWidgetProps } from '../../composables';
import type {
  FormWrapperWidgetProperties,
  FormEmitsWithIconEvents,
} from '../../types';

const props = defineProps<FormWrapperWidgetProperties>();
const emit = defineEmits<FormEmitsWithIconEvents>();

const {
  emitChange,
  emitInput,
  emitFocus,
  emitBlur,
} = useBaseFormWidgetEvents(emit);

const emitIconPrefixClick = (): void => {
  emit(CustomInputEventType.ICON_PREFIX_CLICK, props.name);
};

const emitIconSuffixClick = (): void => {
  emit(CustomInputEventType.ICON_SUFFIX_CLICK, props.name);
};

const { componentProps } = useCommonFormWidgetProps(props, TextInputTypes.TEXT);
</script>

<template>
  <TextInput v-auto-id="'FormText'"
    v-bind="componentProps"
    @input="emitInput"
    @change="emitChange"
    @focus="emitFocus"
    @blur="emitBlur"
    @icon-prefix-click="emitIconPrefixClick"
    @icon-suffix-click="emitIconSuffixClick"
  />
</template>
