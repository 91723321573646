import type {
  ClaimToUpdateSportEventRunnerOddChanged,
  ClaimToUpdateSportEventStatusChanged,
} from 'web/src/modules/slip/types/сlaimToUpdate';

export default function isMatchedChange(
  change: ClaimToUpdateSportEventStatusChanged | ClaimToUpdateSportEventRunnerOddChanged,
  { eventId, marketId, runnerId }: {
    eventId?: number;
    marketId?: number;
    runnerId?: number;
    event?: never;
    market?: never;
    runner?: never;
  },
): boolean {
  const isMatchedEvent = !eventId || (eventId === change.event);
  const isMatchedMarket = !marketId || (marketId === change.market);
  const isMatchedRunner = 'runner' in change
    ? !runnerId || (runnerId === change.runner)
    : true;

  return isMatchedEvent && isMatchedMarket && isMatchedRunner;
}
