import type { VHeadlineBannerProps } from 'web/src/components/HeadlineBanner/VHeadlineBanner/types';

export default function getBannerImageStyles(
  props: VHeadlineBannerProps,
  bgImageWidth: string,
): Record<string, string> {
  const styles: Record<string, string> = {};

  if (props.imageHeight) {
    styles.height = props.imageHeight;
  }

  if (props.isBigHeight && bgImageWidth) {
    styles.width = bgImageWidth;
  }

  return styles;
}
