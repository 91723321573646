<script lang="ts" setup>
import { VLoader as VLoaderInner } from '@components/loader';

import { VLoaderDelayed } from 'web/src/components/Loader';

withDefaults(defineProps<VPreloaderProps>(), {
  hint: '',
});

const VLoader = null
  ? VLoaderInner
  : VLoaderDelayed;

interface VPreloaderProps {
  hint?: string;
}
</script>

<template>
  <div v-auto-id="'VPreloader'" :class="$style['preloader']">
    <VLoader
      :hint="hint"
    />
  </div>
</template>

<style lang="scss" module>
.preloader {
  @include z-index(preloader);

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgbaColor(colordtlayer0, 0.7);
}
</style>
