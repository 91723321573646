export enum LegacyIconName {
  /** @deprecated */
  ARROW = 'arrow',
  /** @deprecated */
  ARROW_DROPDOWN = 'arrow-dropdown',
  /** @deprecated */
  BELL_THICK = 'bell-thick',
  /** @deprecated */
  BETGAMES = 'betgames',
  /** @deprecated */
  BRIGHTNESS_HIGH = 'brightness-high',
  /** @deprecated */
  BRIGHTNESS_LOW = 'brightness-low',
  /** @deprecated */
  BRIGHTNESS_MEDIUM = 'brightness-medium',
  /** @deprecated */
  BROOM = 'broom',
  /** @deprecated */
  BRUSH = 'brush',
  /** @deprecated */
  CALENDAR = 'calendar',
  /** @deprecated */
  CHEVRON = 'chevron',
  /** @deprecated */
  CHERRY = 'cherry',
  /** @deprecated */
  CLIPBOARD_ARROW_DOWN = 'clipboard-arrow-down',
  /** @deprecated */
  CLIPBOARD_ARROW_LEFT = 'clipboard-arrow-left',
  /** @deprecated */
  CLIPBOARD_BANG = 'clipboard-bang',
  /** @deprecated */
  CLIPBOARD_PERSON = 'clipboard-person',
  /** @deprecated */
  COG_OUTLINE = 'cog-outline',
  /** @deprecated */
  COIN_DOLLAR = 'coin-dollar',
  /** @deprecated */
  COIN_DOLLAR_PADDED = 'coin-dollar-padded',
  /** @deprecated */
  COIN_DOLLAR_OUTLINE = 'coin-dollar-outline',
  /** @deprecated */
  COIN_L_OUTLINE = 'coin-l-outline',
  /** @deprecated */
  COINS_PLUS = 'coins-plus',
  /** @deprecated */
  COLLAPSED = 'collapsed',
  /** @deprecated */
  CONNECTION_LOST_QUESTION = 'connection-lost-question',
  /** @deprecated */
  CONNECTION_LOST_WIFI = 'connection-lost-wifi',
  /** @deprecated */
  CREDIT_CARD = 'credit-card',
  /** @deprecated */
  CROSS_OUTLINE = 'cross-outline',
  /** @deprecated */
  CROSS_PADDED = 'cross-padded',
  /** @deprecated */
  CROSS_FILLED = 'cross-filled',
  /** @deprecated */
  CUP_OUTLINE = 'cup-outline',
  /** @deprecated */
  CVV = 'cvv',
  /** @deprecated */
  DATE = 'date',
  /** @deprecated */
  DEALER = 'dealer',
  /** @deprecated */
  DIAMOND = 'diamond',
  /** @deprecated */
  DOCUMENT_CHECK = 'document-check',
  /** @deprecated */
  DOWNLOAD = 'download',
  /** @deprecated */
  EQUALIZER = 'equalizer',
  /** @deprecated */
  FIELD_ACTIVE = 'field-active',
  /** @deprecated */
  FILE_DOCUMENT = 'file-document',
  /** @deprecated */
  FILE_MEDIA = 'file-media',
  /** @deprecated */
  FINGER_PRESS = 'finger-press',
  /** @deprecated */
  FREE_BET = 'free-bet',
  /** @deprecated */
  GAMEPAD = 'gamepad',
  /** @deprecated */
  GAMEPAD_OUTLINE = 'gamepad-outline',
  /** @deprecated */
  GENERATE_CODE = 'generate-code',
  /** @deprecated */
  GIFT = 'gift',
  /** @deprecated */
  GIFT_OUTLINE = 'gift-outline',
  /** @deprecated */
  HISTORY_OUTLINE = 'history-outline',
  /** @deprecated */
  HISTORY_REWIND = 'history-rewind',
  /** @deprecated */
  INFO_OUTLINE_ROUNDED = 'info-outline-rounded',
  /** @deprecated */
  INSTAGRAM_CIRCLE = 'instagram-circle',
  /** @deprecated */
  L_BONUS = 'l-bonus',
  /** @deprecated */
  LIMIT_CHIP = 'limit-chip',
  /** @deprecated */
  LIMIT_COINS = 'limit-coins',
  /** @deprecated */
  LIMIT_DIGIT = 'limit-digit',
  /** @deprecated */
  LIMIT_DOLLAR = 'limit-dollar',
  /** @deprecated */
  MINIMIZE = 'minimize',
  /** @deprecated */
  MINUS = 'minus',
  /** @deprecated */
  MOON = 'moon',
  /** @deprecated */
  PERSON_ADD = 'person-add',
  /** @deprecated */
  PERSON_ARROW = 'person-arrow',
  /** @deprecated */
  PERSON_BOX = 'person-box',
  /** @deprecated */
  PERSON_FILLED = 'person-filled',
  /** @deprecated */
  PHONE_IN_TALK = 'phone_in_talk',
  /** @deprecated */
  PLATE = 'plate',
  /** @deprecated */
  PLATE_STAR = 'plate-star',
  /** @deprecated */
  PLATE_STAR_INVERTED = 'plate-star-inverted',
  /** @deprecated */
  PRELOADING = 'preloading',
  /** @deprecated */
  QUESTION = 'question',
  /** @deprecated */
  QUESTION_BUBBLE = 'question-bubble',
  /** @deprecated */
  QUESTION_BUBBLE_THICK = 'question-bubble-thick',
  /** @deprecated */
  RELOAD = 'reload',
  /** @deprecated */
  REVERT = 'revert',
  /** @deprecated */
  REVERT_OUTLINE = 'revert-outline',
  /** @deprecated */
  RSS_CIRCLE = 'rss-circle',
  /** @deprecated */
  RUB = 'rub',
  /** @deprecated */
  STAR_HALF = 'star-half',
  /** @deprecated */
  STREAM_LIVE = 'stream-live',
  /** @deprecated */
  SUPPORT_BUBBLE = 'support-bubble',
  /** @deprecated */
  SUPPORT_CIRCLE = 'support-circle',
  /** @deprecated */
  SUPPORT_IMPORTANT_OUTLINE = 'support-important-outline',
  /** @deprecated */
  SUPPORT_OUTLINE = 'support_outline',
  /** @deprecated */
  TRIANGLE = 'triangle',
  /** @deprecated */
  TV = 'tv',
}
