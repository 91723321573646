import { RouteName } from '@leon-hub/routing-config-names';

import type { AppVueRouter } from 'web/src/modules/core/services/router/types';

export default function createLoyaltyProgramRoutes(router: AppVueRouter): void {
  router.addProfileRoute({
    name: RouteName.LOYALTY_PROGRAM,
    path: '/profile/loyalty-program',
    // eslint-disable-next-line max-len
    component: () => import('web/src/modules/bonuses/pages/BonusLoyaltyProgramRoutePage/BonusLoyaltyProgramRoutePage.vue'),
    title: '{{$t(\'WEB2_LOYALTY_PROGRAM\')}}',
    topBarProps: {
      hidePrefix: !!process.env.VUE_APP_LAYOUT_DESKTOP,
    },
    prefetch: () => import('web/src/modules/bonuses/prefetch/BonusLoyaltyProgramPagePrefetch'),
  });

  router.addProfileRoute({
    name: RouteName.SHOP_ITEM_DETAILS,
    path: '/shop/:itemId',
    // eslint-disable-next-line max-len
    component: () => import('web/src/modules/bonuses/pages/BonusLoyaltyShopDetailsRoutePage/BonusLoyaltyShopDetailsRoutePage.vue'),
    props: true,
    title: '{{$t(\'WEB2_LOYALTY_PROGRAM\')}}',
    topBar: () => import('web/src/modules/bonuses/layouts/BonusLoyaltyShopDetailsTopBar.vue'),
  });
}
