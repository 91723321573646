import { isObject } from '@leon-hub/guards';

import type {
  SeoMetaParameterValue,
  SeoMetaParameterConfig,
  SeoMetaParameter,
  SeoMetaParametersConfig,
} from 'web/src/modules/seo/types';

function isSeoMetaParametersConfig<
  V = SeoMetaParameterValue,
>(value: unknown): value is SeoMetaParameterConfig<V> {
  return isObject(value) && ('value' in value);
}

// @TODO custom compare for objects
function isEqualSeoMetaParameterValue(
  oldValue?: SeoMetaParameterValue,
  newValue?:SeoMetaParameterValue,
): boolean {
  return oldValue === newValue;
}

/** @deprecated */
// eslint-disable-next-line import/prefer-default-export
export function isDifferentMetaParameters(source?: SeoMetaParametersConfig, target?: SeoMetaParametersConfig): boolean {
  if (source === target) {
    return false;
  }

  if (!source || !target) {
    return true;
  }

  if (Object.keys(source).length !== Object.keys(target).length) {
    return true;
  }

  return Object.keys(source).some((key) => {
    const sourceParameter: SeoMetaParameter = source[key];
    const targetParameter: SeoMetaParameter = target[key];
    const sourceValue: SeoMetaParameterValue = isSeoMetaParametersConfig(sourceParameter)
      ? sourceParameter.value
      : sourceParameter;
    const targetValue: SeoMetaParameterValue = isSeoMetaParametersConfig(targetParameter)
      ? targetParameter.value
      : targetParameter;

    return !isEqualSeoMetaParameterValue(sourceValue, targetValue);
  });
}
