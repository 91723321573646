<script lang="ts" setup>
import { VIcon } from '@components/v-icon';

import type {
  CasinoProviderSelectEmits,
  CasinoProviderSelectProps,
} from 'web/src/modules/casino/submodules/lobby/components/CasinoProviderSelect/types';
import { useCasinoProviderSelect } from 'web/src/modules/casino/submodules/lobby/components/CasinoProviderSelect/composables';

const props = withDefaults(defineProps<CasinoProviderSelectProps>(), {
  title: '',
});
const emits = defineEmits<CasinoProviderSelectEmits>();

const {
  iconProperties,
  emitClick,
} = useCasinoProviderSelect(props, emits);
</script>

<template>
  <div v-auto-id="'CasinoProviderSelect'"
    v-data-test="{ el: 'casino-button-provider-select' }"
    :class="{
      [$style['casino-provider-select']]: true,
      [$style['casino-provider-select--in-sidebar']]: inSidebar,
    }"
    @click="emitClick"
  >
    {{ title }}
    <div
      :class="{
        [$style['casino-provider-select__button']]: true,
        [$style['casino-provider-select__button--selected']]: hasSelectedGroup,
      }"
    >
      <VIcon v-bind="iconProperties" />
    </div>
  </div>
</template>

<style lang="scss" module>
@include for-layout using($isDesktop) {
  .casino-provider-select {
    @include medium\14\20\025;

    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    height: $casinoProviderSelectHeight;
    user-select: none;
    background-color: var(--Layer1);
    border-radius: $casinoProviderSelectRadius;

    @if $isDesktop {
      padding: 4px 0 4px 16px;
      white-space: nowrap;
      cursor: pointer;
    } @else {
      padding-left: 16px;
    }

    &:hover {
      @if $isDesktop {
        color: var(--TextDefault);
        background-color: var(--Highlight);
      }
    }

    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 44px;
      height: 44px;
      margin-right: $casinoProviderSelectButtonMarginRight;
      margin-left: 4px;
      border-radius: $casinoProviderSelectButtonBorderRadius;

      &--selected {
        width: $casinoProviderSelectButtonSelectedSize;
        height: $casinoProviderSelectButtonSelectedSize;
        color: $casinoProviderSelectButtonSelectedColor;
        background-color: $casinoProviderSelectButtonSelectedBackgroundColor;
      }
    }

    &--in-sidebar {
      @if $isDesktop {
        flex-direction: row-reverse;
        gap: 8px;
        justify-content: flex-end;
        padding: 0;
        color: var(--TextPrimary);

        .casino-provider-select__button {
          margin: 0;
        }
      }
    }
  }
}
</style>
