<script lang="ts" setup>
import { toRef } from 'vue';

import type {
  VirtualSportFilterOptions,
  VirtualSportSelectedFilter,
} from 'web/src/modules/sportline/submodules/virtual-sport/types';
import type { SportlineFilterSlots } from 'web/src/modules/sportline/components/navigation-filter/types';
import SportlineFilter from 'web/src/modules/sportline/components/navigation-filter/default/SportlineFilter.vue';

import { useSportlineVirtualSportFilter } from './useSportlineVirtualSportFilter';

interface UseSportlineVirtualSportFilterProps {
  options: VirtualSportFilterOptions;
  filter?: Maybe<VirtualSportSelectedFilter>;
}

interface UseSportlineVirtualSportFilterEmits {
  (e: 'change-filter', to: VirtualSportSelectedFilter): void;
}

const props = defineProps<UseSportlineVirtualSportFilterProps>();
const emit = defineEmits<UseSportlineVirtualSportFilterEmits>();
defineSlots<SportlineFilterSlots>();

const {
  items,
} = useSportlineVirtualSportFilter({
  options: toRef(props, 'options'),
  filter: toRef(props, 'filter', null),
}, {
  changeFilter(to: VirtualSportSelectedFilter): void {
    emit('change-filter', to);
  },
});
</script>

<template>
  <SportlineFilter v-auto-id="'SportlineVirtualSportFilter'" :items="items">
    <template #afterItem="{ item }">
      <slot
        name="afterItem"
        :item="item"
      />
    </template>
  </SportlineFilter>
</template>
