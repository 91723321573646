<script lang="ts" setup>
import { toRef } from 'vue';

import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import { BannerType } from 'web/src/components/HeadlineBanner/VHeadlineBanner/enums';
import { BannerCategory, BannerLocation } from 'web/src/modules/banners/enums';
import { UpcomingLabelType } from 'web/src/modules/sportline/views/EventBlock/enums';
import { ExtendedEventBlock } from 'web/src/modules/sportline/submodules/extended-event/enums';
import { createSportlineFragmentBasisKeyValue } from 'web/src/modules/sportline/composables/list/utils';
import VLazyRender from 'web/src/components/LazyRender/VLazyRender/VLazyRender.vue';
import MainBannerSectionRouteComponent
  from 'web/src/modules/banners/components/MainBannerSectionRouteComponent/MainBannerSectionRouteComponent.vue';
import SportlineComingHeader from 'web/src/modules/sportline/components/header/SportlineComingHeader.vue';
import SportlineSportBlock from 'web/src/modules/sportline/views/SportBlock/SportlineSportBlock.vue';
import SportlineLiveNoEvents from 'web/src/modules/sportline/submodules/live/components/SportlineLiveNoEvents.vue';

import type { LiveSportTabProps } from './types';
import { useLiveSportTab } from './useLiveSportTab';

interface LiveDefaultTabProps extends LiveSportTabProps {
  canExpandElements?: boolean;
}

const props = defineProps<LiveDefaultTabProps>();
const group = toRef(props, 'group');
const canExpandElements = toRef(props, 'canExpandElements', false);
const canShowPrematch = toRef(props, 'canShowPrematch', false);
const isOpen = toRef(props, 'isOpen', false);

const settingsStore = useSportlineSettingsStore();
const isMarketTypesSelectionEnabled = toRef(settingsStore, 'isMarketTypesSelectionEnabled');

const {
  isExpandAllByDefault,
  liveGroup,
  prematchGroup,
} = useLiveSportTab({ group });
</script>

<template>
  <div v-auto-id="'LiveDefaultTab'">
    <template v-if="liveGroup">
      <SportlineSportBlock
        v-show="isOpen"
        :key="`${liveGroup.key}-live`"
        :sport-element="liveGroup.liveElement"
        :is-market-types-selection-enabled="isMarketTypesSelectionEnabled"
        :expand-key="canExpandElements ? liveGroup.expandKey : null"
        :is-default-expanded="isExpandAllByDefault || group.isOpenByDefault"
        :can-add-to-favorite="!!'1'"
        :upcoming-label-type="UpcomingLabelType.StaticLabel"
        :extended-block-type="ExtendedEventBlock.Live"
        :basis-key="createSportlineFragmentBasisKeyValue({ sportId: liveGroup.liveElement.sport.id, salt: 'live' })"
      />
    </template>

    <SportlineLiveNoEvents
      v-if="canShowPrematch && group.isEmpty"
      :key="`${group.key}-empty`"
      :sport-id="group.filterElement?.sport.id"
    />

    <MainBannerSectionRouteComponent
      v-if="isOpen"
      :key="`${group.key}-banner`"
      is-static
      :location="BannerLocation.CUSTOM"
      :banner-type="BannerType.INLINE_BANNER"
      :custom-location-id="group.transitionId"
      :banner-category="BannerCategory.SUPER_LIVE"
    />

    <VLazyRender v-if="canShowPrematch && prematchGroup">
      <SportlineComingHeader
        v-if="prematchGroup.doShowTitleComponent"
        :key="`${group.key}-before-prematch`"
      />

      <SportlineSportBlock
        :key="`${prematchGroup.key}-coming`"
        v-data-test="{ el: 'prematchGroup-coming' }"
        :sport-element="prematchGroup.prematchElement"
        :is-coming-headline="!prematchGroup.showComingName"
        :is-market-types-selection-enabled="isMarketTypesSelectionEnabled"
        :can-add-to-favorite="!!'1'"
        :upcoming-label-type="UpcomingLabelType.Timer"
        :extended-block-type="ExtendedEventBlock.Live"
        :basis-key="createSportlineFragmentBasisKeyValue({ sportId: prematchGroup.prematchElement.sport.id, salt: 'prematch' })"
      />
    </VLazyRender>
  </div>
</template>
