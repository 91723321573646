import type { FieldGuards } from '@leon-hub/types';
import { isString, isValidInterface } from '@leon-hub/guards';

import type { GetCTagResponse } from 'web/src/modules/sportline/types/rest';

export const isGetCTagResponseGuards: FieldGuards<GetCTagResponse> = {
  ctag: isString,
};

export function isGetCTagResponse(value: unknown): value is GetCTagResponse {
  return isValidInterface<GetCTagResponse>(value, isGetCTagResponseGuards);
}
