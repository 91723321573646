import type { Ref } from 'vue';
import { computed } from 'vue';

import {
  IconName,
} from '@leon-hub/icons';

import { useI18n } from 'web/src/modules/i18n/composables';

import type { BaseErrorContentProps } from '../../BaseErrorContent/types';

export interface UseConnectionErrorComposable {
  errorProps: Ref<BaseErrorContentProps>;
}

export default function useConnectionError(): UseConnectionErrorComposable {
  const { $translate } = useI18n();

  const errorProps = computed<BaseErrorContentProps>(() => ({
    title: $translate('WEB2_CONNECTION_ERROR_CAPTION').value,
    text: $translate('WEB2_CONNECTION_ERROR_DESCRIPTION').value,
    iconName: process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED ? IconName.SLOTT_WIFI_SLASH : IconName.CONNECTION_LOST,
    isEmailAsLabel: true,
  }));

  return {
    errorProps,
  };
}
