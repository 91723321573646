import { defineStore } from 'pinia';
import {
  computed,
  ref,
  toRef,
  watch,
} from 'vue';

import type {
  DoMarkAsClickedQueryVariables,
  DoMarkAsDeliveredQueryVariables,
  DoMarkNotificationQueryVariables,
  FrontNotificationsRequest,
  PaginationCursorItem,
} from '@leon-hub/api-sdk';
import {
  DisplayType,
  FrontNotificationType,

  doDeleteNotification,
  doMarkAsClicked,
  doMarkAsDelivered,
  doMarkNotification,
  getNotifications as getNotif,
  RequestOptionsPriority,
} from '@leon-hub/api-sdk';
import { RefProgramNotificationType } from '@leon-hub/websocket/src/sdk-ws';

import type {
  CustomerNotificationCheckedMessage,
  CustomerNotificationMessage,
} from 'web/src/modules/customer-notifications/types';
import { CustomerNotificationMessageSource } from 'web/src/modules/customer-notifications/enums';
import promisesInBatch from 'web/src/utils/promisesInBatch';
import { useWebSockets } from 'web/src/modules/core/composables';
import createCustomerMessageFromFrontNotification from 'web/src/modules/front-notifications/helpers/createCustomerMessageFromFrontNotification';
import { useWebSocketsConfig } from 'web/src/modules/core/composables/site-config';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import useRouterStore from 'web/src/modules/core/store/useRouterStore';

import type {
  AlcrFrontNotification,
  CbcFrontNotification,
  FrontNotification,
  FrontNotificationArray,
  PlayTimeTimerEndedNotification,
  RefFrontNotification,
  VideoFrontNotification,
} from '../types';

const onSiteNotificationTypes = [
  FrontNotificationType.ALCR_NOTIFICATION,
  FrontNotificationType.ON_SITE,
];

const useFrontNotificationsStore = defineStore(
  'front-notifications', () => {
    const { isLoggedIn } = useIsLoggedIn();
    const api = useGraphqlClient();
    const { subscribeAuthorized } = useWebSockets();
    const { isSocketStickyBonusStatus } = useWebSocketsConfig();
    const siteConfig = useSiteConfigStore();
    const routerStore = useRouterStore();
    const currentRouteName = toRef(() => routerStore.currentRouteName);

    const isVideoVerificationEnabled = toRef(() => siteConfig.isVideoVerificationFeatureEnabled);

    const frontNotifications = ref<FrontNotificationArray>();
    const pollingInterval = ref(30_000);

    const filteredFrontNotifications = computed<FrontNotificationArray>(() => frontNotifications.value?.filter(
      (item) => !(item.restrictedRoutes.includes(String(currentRouteName.value))),
    ) ?? []);

    const cbcFrontNotification = computed<Maybe<CbcFrontNotification>>(
      () => frontNotifications.value?.find(
        (item) => item.type === FrontNotificationType.CBC_BONUS_ACTIVATED,
      ) ?? null,
    );

    const refFrontNotification = computed(
      () => {
        const ar: RefFrontNotification[] = [];
        filteredFrontNotifications.value?.forEach(
          (item) => {
            if (item.type === FrontNotificationType.REFPRG && isRefFrontNotification(item)) {
              ar.push(item);
            }
          },
        );
        return ar ?? null;
      },
    );

    const playTimeEndNotification = computed(
      () => (filteredFrontNotifications.value?.find(
        (item) => item.type === FrontNotificationType.PLAY_TIME_ENDED,
      ) as PlayTimeTimerEndedNotification),
    );

    const onSiteNotifications = computed<AlcrFrontNotification[]>(
      () => ((filteredFrontNotifications.value ?? []).filter(
        (item) => onSiteNotificationTypes.includes(item.type),
      ) as AlcrFrontNotification[]).filter((n) => n.displayType !== DisplayType.SNACKBAR),
    );

    const videoVerificationNotifications = computed(
      () => (filteredFrontNotifications.value?.filter(
        (item) => item.type === FrontNotificationType.VIDEO_VERIFICATION,
      ) as VideoFrontNotification[]) || [],
    );

    const isVideoVerificationAlert = computed(
      () => !!videoVerificationNotifications.value.length && isVideoVerificationEnabled.value,
    );

    const preparedCustomerMessages = computed(
      () => (!onSiteNotifications.value
        ? undefined
        : onSiteNotifications.value.map(
          (message) => createCustomerMessageFromFrontNotification(message),
        )
      ),
    );

    const unreadCustomerMessagesCounter = computed(
      () => onSiteNotifications.value?.filter((message) => !message.isRead).length || 0,
    );

    const unreadRefMessagesCounter = computed(
      // eslint-disable-next-line max-len
      () => refFrontNotification.value?.filter((message) => {
        const type = message.payload.type as RefProgramNotificationType;
        return (type === RefProgramNotificationType.BONUS_ELIGIBILITY_RULES || type === RefProgramNotificationType.REFERRAL_REWARD_ASSIGNED_REFERRER_NOTIFICATION)
        && !message.isRead ? 1 : 0;
      }).length || 0,
    );

    function setFrontNotifications(): void {
      if (!frontNotifications.value) {
        frontNotifications.value = [];
      }
    }

    function removeFrontNotification(itemId: string): void {
      if (frontNotifications.value) {
        frontNotifications.value = frontNotifications.value.filter((item) => item.id !== itemId);
      }
    }

    function resetFrontNotification(): void {
      frontNotifications.value = undefined;
    }

    function updateFrontNotification(item: FrontNotification): void {
      const notifications = frontNotifications.value
        ? [...frontNotifications.value] : [];

      const idx = notifications.findIndex((i: FrontNotification) => i.id === item.id);

      if (idx > -1) {
        notifications[idx] = { ...notifications[idx], ...item };
      } else {
        notifications.unshift(item);
      }

      frontNotifications.value = notifications;
    }

    function markAsRead(itemId: string): void {
      const message = (frontNotifications.value ?? []).find((item) => item.id === itemId);
      if (message) {
        Object.assign(message, {
          isRead: true,
        });
      }
    }

    async function getUnreadNotifications(silent = false): Promise<void> {
      await getNotifications({ unread: true, silent });
    }

    async function getAllCustomerNotifications(silent = false): Promise<void> {
      await getNotifications({ unread: false, silent });
    }

    async function markAsDelivered(options: DoMarkAsDeliveredQueryVariables['options']): Promise<void> {
      await doMarkAsDelivered(api,
        (node) => node.mutations.frontNotifications.markAsDelivered,
        {
          options,
        });
    }

    async function markNotification(options: DoMarkNotificationQueryVariables['options']): Promise<void> {
      await doMarkNotification(api,
        (node) => node.mutations.frontNotifications.markAsRead,
        {
          options,
        });

      const stateMessage = (frontNotifications.value ?? []).find(({ id }) => id === options.id);

      const removeAfterMarkList: (FrontNotificationType | undefined)[] = [
        FrontNotificationType.CBC_BONUS_ACTIVATED,
        FrontNotificationType.VIDEO_VERIFICATION,
        FrontNotificationType.BS_DEPOSIT,
        FrontNotificationType.CRYPTO_DEPOSIT_STATUS,
        FrontNotificationType.FOMO_BONUS_CREATED,
      ];

      if (removeAfterMarkList.includes(stateMessage?.type)) {
        removeFrontNotification(options.id);
      } else {
        markAsRead(options.id);
      }
    }

    async function markAsClicked(options: DoMarkAsClickedQueryVariables['options']): Promise<void> {
      const stateMessage = (frontNotifications.value ?? []).find(({ id }) => id === options.id);

      if (!stateMessage?.isClicked) {
        await doMarkAsClicked(api,
          (node) => node.mutations.frontNotifications.markAsClicked,
          {
            options,
          });
      }

      if (stateMessage?.type === FrontNotificationType.CBC_BONUS_ACTIVATED) {
        removeFrontNotification(options.id);
      }
    }

    async function deleteNotification(id: string): Promise<void> {
      await doDeleteNotification(api,
        (node) => node.mutations.frontNotifications.delete,
        {
          options: {
            id,
          },
        });
    }

    async function deleteMessage(message: CustomerNotificationMessage): Promise<void> {
      if (message.source === CustomerNotificationMessageSource.ALCR && frontNotifications.value) {
        const stateMessage = frontNotifications.value.find(({ id }) => id === message.id);

        if (stateMessage) {
          removeFrontNotification(message.id);

          try {
            await deleteNotification(message.id);
          } catch {
            updateFrontNotification(stateMessage);
          }
        }
      } else {
        throw new Error('You cannot call delete message on not ALCR message type');
      }
    }

    function isRefFrontNotification(notification: FrontNotification): notification is RefFrontNotification {
      return 'payload' in notification;
    }

    async function deleteMultiple<T extends FrontNotification>(
      { messages }: { messages: T[] | undefined },
    ): Promise<CustomerNotificationCheckedMessage[]> {
      const failedMessages: T[] = [];

      if (messages && messages.length > 0) {
        for (const message of messages) {
          removeFrontNotification(message.id);
        }

        await promisesInBatch(messages, (message) => deleteNotification(message.id).catch(() => {
          failedMessages.push(message);
        }));

        if (failedMessages.length > 0) {
          for (const message of failedMessages) {
            updateFrontNotification(message);
          }
        }
      }

      return failedMessages.map((item) => ({
        id: item.id,
        source: CustomerNotificationMessageSource.ALCR,
      }));
    }

    async function fetchNotifications(
      input: FrontNotificationsRequest & { silent?: boolean },
    ): Promise<PaginationCursorItem | null> {
      const { silent, ...options } = input;

      const response = await getNotif(
        api,
        (node) => node.queries.frontNotifications.getNotifications,
        {
          options,
        },
        {
          silent,
          priority: silent ? RequestOptionsPriority.LOW : undefined,
        },
      );

      setFrontNotifications();

      for (const item of response.items) {
        if (item.type !== FrontNotificationType.CBC_BONUS_ACTIVATED || !item.isRead) {
          updateFrontNotification(item);
        }

        if (!item.isDelivered) {
          void markAsDelivered({ id: item.id });
        }
      }

      return response.next;
    }

    async function getNotifications({
      unread,
      silent,
    }: {
      unread: boolean;
      silent?: boolean;
    }): Promise<void> {
      let next: PaginationCursorItem | null = {
        limit: 100,
        offset: 0,
      };

      do {
        // eslint-disable-next-line no-await-in-loop
        next = await fetchNotifications({
          unread,
          types: [
            ...onSiteNotificationTypes,
            FrontNotificationType.CBC_BONUS_ACTIVATED,
            FrontNotificationType.VIDEO_VERIFICATION,
            FrontNotificationType.REFPRG,
            FrontNotificationType.FOMO_BONUS_CREATED,
            ...(process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED
              ? [FrontNotificationType.BONUS_EXPIRED_CASHBACK,
                FrontNotificationType.BONUS_CASHBACK,
                FrontNotificationType.SUSPENDED_CASHBACK,
                FrontNotificationType.RESUMED_CASHBACK,
              ]
              : []),
          ],
          limit: next.limit,
          offset: next.offset,
          silent,
        });
      } while (next);
    }

    function subscribeOnFrontNotification(): void {
      subscribeAuthorized({
        method: 'onFrontNotification',
        onMessage: (data) => {
          updateFrontNotification({
            ...data.onFrontNotification,
            isClicked: false,
            isRead: false,
          });
          // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
          if (!data.onFrontNotification.isDelivered && data.onFrontNotification.type !== FrontNotificationType.PLAY_TIME_ENDED) {
            void markAsDelivered({ id: data.onFrontNotification.id });
          }
        },
        isEnabled: isSocketStickyBonusStatus,
        polling: {
          timeout: pollingInterval,
          callback: () => getUnreadNotifications(true),
          callOnLogin: true,
        },
      });
    }

    subscribeOnFrontNotification();

    watch(isLoggedIn, (newValue) => {
      if (!newValue) {
        resetFrontNotification();
      }
    });

    return {
      playTimeEndNotification,
      frNotification: frontNotifications,
      frontNotifications: filteredFrontNotifications,
      cbcFrontNotification,
      refFrontNotification,
      videoVerificationNotifications,
      isVideoVerificationAlert,
      preparedCustomerMessages,
      unreadCustomerMessagesCounter,
      unreadRefMessagesCounter,
      onSiteNotifications,
      markNotification,
      deleteMessage,
      getNotifications,
      deleteMultiple,
      markAsClicked,
      getUnreadNotifications,
      getAllCustomerNotifications,
      removeFrontNotification,
    };
  },
);

export default useFrontNotificationsStore;
