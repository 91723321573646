import type { ComputedRef, Ref } from 'vue';
import {
  computed, ref, toRef,
} from 'vue';
import { useRouter } from 'vue-router';

import { IconName } from '@leon-hub/icons';
import { RouteName } from '@leon-hub/routing-config-names';

import { useReferralProgramStore } from 'web/src/modules/referral-program/store';
import { useFormatMoney } from 'web/src/modules/money/composables';
import type {
  ReferralProgramInfoListItem,
  ReferralProgramInfoPageNominal,
} from 'web/src/modules/referral-program/pages/ReferralProgramInfoPage/types';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import {
  useI18n,
  useI18nLocale,
} from 'web/src/modules/i18n/composables';

import getRewardTitle from '../../../utils/referralProgramPopup/getRewardTitle';

export interface ReferralProgramInfoPageComposable {
  itemList: Ref<ReferralProgramInfoListItem[]>;
  isLoading: Ref<boolean>;
  nominals: Ref<ReferralProgramInfoPageNominal[]>;
  rewardMatrix: ComputedRef<{
    col1: string;
    col2: string[];
    col3: string[];
  }[]>;
  runOnMounted(): Promise<void>;
  onClickTerms(): void;
}

export default function useReferralProgramInfoPage(): ReferralProgramInfoPageComposable {
  const referralProgramStore = useReferralProgramStore();
  const { $translate } = useI18n();
  const { locale } = useI18nLocale();
  const formatMoney = useFormatMoney();
  const router = useRouter();
  const siteConfigStore = useSiteConfigStore();
  const isProfileReferralProgramEnabled = toRef(siteConfigStore, 'isProfileReferralProgramEnabled');
  const availability = toRef(referralProgramStore, 'availability');

  const programDetails = toRef(referralProgramStore, 'programDetails');

  const isLoading = ref(true);

  const rewardMatrix = computed(() => programDetails.value?.rewardOptions?.rewards.map((item) => (
    {
      col1: $translate('WEB2_REFFERAL_PROGRAM_DEPOSIT_FROM', ref({ count: formatMoney(item.depositAmount) })).value,
      col2: item.rewardAmount.playerReward.map((reward) => getRewardTitle(reward, $translate, locale.value)),
      col3: item.rewardAmount.referralReward.map((reward) => getRewardTitle(reward, $translate, locale.value)),
    }
  )) ?? []);

  const nominals = computed(() => {
    const result: ReferralProgramInfoPageNominal[] = [];
    if (programDetails.value?.rewardOptions?.freespinValue) {
      result.push({
        title: $translate('REFERRAL_PROGRAM_INFO_LABEL_FREESPIN').value,
        value: formatMoney(programDetails.value?.rewardOptions?.freespinValue),
      });
    }
    if (programDetails.value?.rewardOptions?.freebetValue) {
      result.push({
        title: $translate('REFERRAL_PROGRAM_INFO_LABEL_FREEBET').value,
        value: formatMoney(programDetails.value?.rewardOptions?.freebetValue),
      });
    }
    return result;
  });

  const itemList = computed(() => ([
    {
      icon: IconName.SHARE,
      label: $translate('REFERRAL_PROGRAM_INFO_STEP_1').value,
      table: null,
    },
    {
      icon: IconName.LIKE,
      label: $translate('REFERRAL_PROGRAM_INFO_STEP_2').value,
      table: null,
    },
    {
      icon: IconName.COIN,
      label: $translate('REFERRAL_PROGRAM_INFO_STEP_3').value,
      table: null,
    },
    {
      icon: IconName.PROMOS,
      label: $translate('REFERRAL_PROGRAM_INFO_STEP_4').value,
      table: {
        header: [
          $translate('REFERRAL_PROGRAM_INFO_COLUMN_TITLE_1').value,
          $translate('REFERRAL_PROGRAM_INFO_COLUMN_TITLE_2').value,
          $translate('REFERRAL_PROGRAM_INFO_COLUMN_TITLE_3').value,
        ],
        body: rewardMatrix.value,
      },
    },
  ]));

  const runOnMounted = async () => {
    await referralProgramStore.getRefDetails();
    await referralProgramStore.getAvailability();
    if (!availability.value?.available || !isProfileReferralProgramEnabled.value) {
      void router.push({ name: RouteName.ERROR_FORBIDDEN_403 });
    }
    isLoading.value = false;
  };

  const onClickTerms = () => {
    void router.push({
      name: RouteName.CMS_PROMO_TERMS,
      params: { cmsKey: 'FRIEND_LEON_RULES' },
    });
  };

  return {
    itemList,
    isLoading,
    nominals,
    runOnMounted,
    rewardMatrix,
    onClickTerms,
  };
}
