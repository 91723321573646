<script setup lang="ts">
import { onMounted, ref } from 'vue';

import { ButtonKind } from '@leon-hub/module-buttons';
import type { FocusableInputRef } from '@leon-hub/focus';

import PaymentsInput from 'web/src/modules/payments/form-widgets/FastSum/components/PaymentsInput/PaymentsInput.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import type { VInputInputEvent } from 'web/src/components/Input';
import type {
  ResponsibleGamblingBetLimitEditStepEmits,
  ResponsibleGamblingBetLimitEditStepProps,
} from 'web/src/modules/profile/submodules/responsible-gambling/steps/ResponsibleGamblingEditStep/types';
import { StepName } from 'web/src/modules/profile/submodules/responsible-gambling/enums';
import { useCurrency } from 'web/src/modules/money/composables';
import SButton from 'web/src/components/Button/SButton/SButton.vue';

const props = withDefaults(defineProps<ResponsibleGamblingBetLimitEditStepProps>(), {});

const emits = defineEmits<ResponsibleGamblingBetLimitEditStepEmits>();

const { currency } = useCurrency();

const result = ref(String(props.minAmount));

const inputRef = ref<FocusableInputRef>();

const handleSubmit = () => {
  emits('submit', result.value);
};

const handleBack = () => {
  emits('step-change', StepName.INFO);
};

const handleChange = (e: VInputInputEvent) => {
  result.value = e.target.value;
};

onMounted(() => {
  void inputRef.value?.focus();
});
</script>

<template>
  <section v-auto-id="'ResponsibleGamblingEditStep'" :class="$style['rg-bet-limit-edit']">
    <div :class="$style['rg-bet-limit-edit__info']">
      {{ description }}
    </div>

    <form
      @submit.stop.prevent="handleSubmit"
    >
      <PaymentsInput
        ref="inputRef"
        :currency="currency"
        :balance="balance"
        :bonus="false"
        :allow-input-suggested-amounts-only="false"
        :suggested-amounts="[]"
        :min-amount="minAmount"
        :disabled="loading"
        :placeholder="placeholder"
        hint-right=" "
        deposit
        @change="handleChange"
      />
    </form>

    <div
      v-if="null"
      :class="$style['rg-bet-limit-edit__buttons']"
    >
      <SButton
        full-width
        size="large"
        :disabled="Number(result) < minAmount"
        :is-loading="loading"
        @click="handleSubmit"
      >
        {{ $t('WEB2_RG_BUTTON_SET') }}
      </SButton>

      <SButton
        full-width
        kind="secondary-outline"
        size="large"
        :disabled="loading"
        @click="handleBack"
      >
        {{ $t('WEB2_RG_BUTTON_CANCEL') }}
      </SButton>
    </div>

    <div
      v-else
      :class="$style['rg-bet-limit-edit__buttons']"
    >
      <VButton
        full-width
        :disabled="Number(result) < minAmount"
        :is-loading="loading"
        @click="handleSubmit"
      >
        {{ $t('WEB2_RG_BUTTON_SET') }}
      </VButton>

      <VButton
        full-width
        :kind="ButtonKind.TRANSPARENT"
        :disabled="loading"
        @click="handleBack"
      >
        {{ $t('WEB2_RG_BUTTON_CANCEL') }}
      </VButton>
    </div>
  </section>
</template>

<style module lang="scss">
.rg-bet-limit-edit {
  @include rgEdit;

  display: flex;
  flex-direction: column;
  row-gap: 16px;
  width: 100%;
  padding: $rgEditPaddingMobile;

  &__info {
    @include rgInfo;

    text-align: center;
  }

  &__buttons {
    @include rgButtons;

    display: flex;
    flex-direction: column;

    @include is-app-layout-desktop {
      @include rgButtonsDesktop;
    }
  }

  @include is-app-layout-desktop {
    padding: $rgEditPaddingDesktop;
  }
}
</style>
