import type { VNode } from 'vue';

import {
  isObject,
  isString,
} from '@leon-hub/guards';

import type { SwiperSlideRef } from 'web/src/components/Swiper/VSwiperSlide/types';

export type SwiperSlideVNode = VNode<HTMLElement> & {
  component?: {
    exposed?: SwiperSlideRef;
  };
};

export default function isSwiperSlideVNode(argument: unknown): argument is SwiperSlideVNode {
  return isObject(argument)
    && isObject(argument.type)
    && isString(argument.type.name)
    && (
      argument.type.name === 'VSwiperSlide'
      || argument.type.name === 'VSwiperIntersectingSlide'
    );
}
