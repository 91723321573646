<script lang="ts" setup>
import { onBeforeMount, onBeforeUnmount } from 'vue';

import { VLoaderDelayed } from 'web/src/components/Loader';
import VLoadMoreObserver from 'web/src/components/LoadMoreObserver/VLoadMoreObserver/VLoadMoreObserver.vue';
import CustomerHistoryListItem
  // eslint-disable-next-line max-len
  from 'web/src/modules/profile/submodules/customer-history/components/CustomerHistoryListItem/CustomerHistoryListItem.vue';
import CustomerHistoryList
  from 'web/src/modules/profile/submodules/customer-history/components/CustomerHistoryList/CustomerHistoryList.vue';
import CustomerHistoryFilterTabs
  // eslint-disable-next-line max-len
  from 'web/src/modules/profile/submodules/customer-history/components/CustomerHistoryFilterTabs/CustomerHistoryFilterTabs.vue';
import ModalPortal from 'web/src/components/Modal/ModalPortal/ModalPortal.vue';
import CustomerHistoryFilterSelect
  // eslint-disable-next-line max-len
  from 'web/src/modules/profile/submodules/customer-history/components/CustomerHistoryFilterSelect/CustomerHistoryFilterSelect.vue';
import CustomerHistoryListEmpty
  // eslint-disable-next-line max-len
  from 'web/src/modules/profile/submodules/customer-history/components/CustomerHistoryListEmpty/CustomerHistoryListEmpty.vue';
import { CashoutModal } from 'web/src/modules/cashout/views';

import type { CustomerHistoryListRouteComponentProps } from './types';
import { useCustomerHistoryListRouteComponent } from './composables';

const props = withDefaults(defineProps<CustomerHistoryListRouteComponentProps>(), {
  isLazyLoadingEnabled: true,
  filterParamsFromProps: null,
});

const {
  selectedHistoryFilter,
  isCashOutModal,
  isLeonShopEnabled,
  isInitialLoader,
  portalSelector,
  historyFilterOptions,
  historyItems,
  isLastPage,
  currency,
  isLazyLoader,
  currentCashOutItem,
  onCashOutConfirm,
  handleBetCashOut,
  handleHistoryItemClick,
  handleCancelWithdrawal,
  onLoadMore,
  onTabClick,
  closeCashOutModal,
  onFilterChange,
  beforeMount,
  beforeUnmount,
} = useCustomerHistoryListRouteComponent(props);

onBeforeMount(beforeMount);
onBeforeUnmount(beforeUnmount);
</script>

<template>
  <div v-auto-id="'CustomerHistoryListRouteComponent'" :class="$style['history-list-route-component']">
    <div :class="$style['history-list-route-component__inner']">
      <CustomerHistoryFilterTabs
        v-if="!isHiddenTabs"
        :selected-history-filter="selectedHistoryFilter"
        :is-leon-shop-enabled="isLeonShopEnabled"
        @tab-click="onTabClick"
      />
      <div
        v-if="!isHiddenSelectFilter"
        :class="$style['history-list-route-component__filter-select']"
      >
        <CustomerHistoryFilterSelect
          :history-filter-options="historyFilterOptions"
          :selected-history-filter="selectedHistoryFilter"
          @filter-change="onFilterChange"
        />
      </div>
      <div
        v-if="!isInitialLoader"
        :class="$style['history-list-route-component__container']"
      >
        <template v-if="historyItems && !!historyItems.length">
          <CustomerHistoryList
            :class="$style['history-list-route-component__list']"
            separate
          >
            <CustomerHistoryListItem
              v-for="item in historyItems"
              :key="item.rootTxId"
              :item="item"
              :currency="currency"
              @item-click="handleHistoryItemClick"
              @cash-out="handleBetCashOut"
              @cancel-withdrawal="handleCancelWithdrawal"
            />
          </CustomerHistoryList>
          <template v-if="isLazyLoadingEnabled && !isLastPage">
            <VLoaderDelayed
              v-if="isLazyLoader"
              :class="$style['history-list-route-component__loader']"
            />
            <VLoadMoreObserver
              v-else
              @load-more="onLoadMore"
            />
          </template>
        </template>
        <CustomerHistoryListEmpty
          v-else
          :class="$style['history-list-route-component__list-empty']"
          :filter="selectedHistoryFilter"
          :is-button-hidden="isHiddenTabs"
        />
      </div>
      <VLoaderDelayed
        v-else-if="isInitialLoader"
        :class="$style['history-list-route-component__loader']"
      />
      <ModalPortal :selector="portalSelector">
        <CashoutModal
          v-if="isCashOutModal"
          :transaction="currentCashOutItem"
          @confirm="onCashOutConfirm"
          @cancel="closeCashOutModal"
        />
      </ModalPortal>
    </div>
  </div>
</template>

<style lang="scss" module>
.history-list-route-component {
  &__inner {
    position: relative;
  }

  &__container {
    padding: 0;
    overflow: hidden;
    border-radius: 5px;
  }

  &__table {
    display: table;
    width: 100%;
    overflow: hidden;
    border-spacing: 0;
    border-collapse: separate;

    > thead,
    > tbody {
      border-spacing: 0;
      border-collapse: separate;
    }
  }

  &__list {
    margin: 0 8px 8px;

    @include is-app-layout-desktop {
      margin: 0 24px 14px;
    }
  }

  &__filter-select {
    @include z-index(modal-top-bar);

    position: sticky;
    top: 0;
    background-color: var(--Layer0);

    &:not(:empty) {
      margin-bottom: 8px;
      margin-left: 4px;

      @include is-app-layout-desktop {
        margin-right: 16px;
        margin-left: 16px;
      }
    }

    @include is-app-layout-phone {
      top: $phoneTopBarHeight;
    }
  }

  &__list-empty {
    padding: $historyListRouteComponentlistEmptyPadding;
  }

  &__loader {
    margin: 10px auto;
  }
}
</style>
