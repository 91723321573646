import type { ItemOptionsId } from 'web/src/components/RecycleScroller/types';
import type { LeagueElement, Sport, SportlineEventElement } from 'web/src/modules/sportline/types';

export function getSportVirtualElementId(sport: Sport): ItemOptionsId {
  return `sport_${sport.id}`;
}

export function getLeagueVirtualElementId(leagueElement: LeagueElement): ItemOptionsId {
  return `league_${leagueElement.league.id}`;
}

export function getEventVirtualElementId(sportlineEvent: SportlineEventElement): ItemOptionsId {
  return `event_${sportlineEvent.sportEvent.id}`;
}
