<script setup lang="ts">
import type { FileMultipleInputProps, FileMultipleEmits } from '../../types';
import { defaultFileTypes } from '../../utils/defaultFileTypes';
import FileSingleInputStateless from '../FileSingleInputStateless';
import { useMultipleFileInput } from '../../composables';
import useErrorsArray from './useErrorsArray';

const props = withDefaults(defineProps<FileMultipleInputProps>(), {
  name: 'fileMultiple',
  error: () => [],
  errorRight: () => [],
  fileTypes: () => defaultFileTypes,
});

const emits = defineEmits<FileMultipleEmits>();

const {
  uniqueId,
  filesRecords,
  removeByIndex,
  onChange,
  onReplace,
  onBlur,
  onFocus,
  addMoreAvailable,
} = useMultipleFileInput(props, emits);

const { anySingleError, anySingleErrorRight } = useErrorsArray(props);
</script>

<template>
  <fieldset v-auto-id="'FileMultipleInput'" :class="$style['file-multiple-input']">
    <FileSingleInputStateless
      v-for="(value, index) in filesRecords"
      :key="index"
      :input-id="`${uniqueId}-${index}`"
      :name="value.name"
      :file-name="value.name"
      :error="error?.[index]"
      :error-right="errorRight?.[index]"
      :hint="hint"
      :hint-right="hintRight"
      :hint-link="hintLink"
      :hint-link-right="hintLinkRight"
      :is-large="isLarge"
      @change="onReplace($event, index)"
      @clear="removeByIndex(index)"
      @focus="onFocus(index)"
      @blur="onBlur(index)"
    />
    <FileSingleInputStateless
      v-if="addMoreAvailable"
      :autofocus="autofocus"
      :input-id="uniqueId"
      :placeholder="placeholder"
      :error="filesRecords.length ? '' : anySingleError"
      :error-right="filesRecords.length ? '' : anySingleErrorRight"
      :label="label"
      :hint="hint"
      :name="name"
      :is-large="isLarge"
      @change="onChange"
      @blur="onBlur(filesRecords.length)"
      @focus="onFocus(filesRecords.length)"
    />
  </fieldset>
</template>

<style module lang="scss">
.file-multiple-input {
  padding: 0;
  margin: 0;
  border: none;
}
</style>
