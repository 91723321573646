<script setup lang="ts">
import { usePromotionCountdown } from 'web/src/modules/promotions/components/PromotionCountdown/composable';
import type {
  PromotionCountdownEmits,
  PromotionCountdownProps,
} from 'web/src/modules/promotions/components/PromotionCountdown/types';

const props = defineProps<PromotionCountdownProps>();
const emit = defineEmits<PromotionCountdownEmits>();
const {
  counterTitle,
  timeName,
  timeNameAlternative,
  isHourShown,
  isMinutesShown,
  timeLeft,
  isShown,
} = usePromotionCountdown(props, emit);
</script>

<template>
  <div v-auto-id="'PromotionCountdown'"
    v-if="isShown"
    :class="{
      [$style['promotion-countdown']]: true,
      [$style[`promotion-countdown--kind-${kind}`]]: kind,
    }"
  >
    <div :class="$style['promotion-countdown__inner']">
      <div
        v-if="!hiddenTitle"
        :class="$style['promotion-countdown__title']"
      >
        {{ counterTitle }}
      </div>
      <div :class="$style['promotion-countdown__counters']">
        <template v-if="timeLeft.days > 0">
          <div :class="$style['promotion-countdown__counters-item']">
            <div
              v-data-test="{ el: 'promotion-countdown__counters-item-value'}"
              :class="$style['promotion-countdown__counters-item-value']"
            >
              {{ timeLeft.days }}
            </div>
            <div
              v-data-test="{ el: 'promotion-countdown__counters-item-label'}"
              :class="$style['promotion-countdown__counters-item-label']"
            >
              {{ isAltTimeName ? (timeNameAlternative ? timeNameAlternative.days : '') : (timeName ? timeName.days : '') }}
            </div>
          </div>
          <div
            v-data-test="{ el: 'promotion-countdown__counters-separator'}"
            :class="$style['promotion-countdown__counters-separator']"
          >
            :
          </div>
        </template>
        <template v-if="isHourShown">
          <div :class="$style['promotion-countdown__counters-item']">
            <div
              v-data-test="{ el: 'promotion-countdown__counters-item-value'}"
              :class="$style['promotion-countdown__counters-item-value']"
            >
              {{ timeLeft.hours < 10 ? `0${timeLeft.hours}` : timeLeft.hours }}
            </div>
            <div
              v-data-test="{ el: 'promotion-countdown__counters-item-label'}"
              :class="$style['promotion-countdown__counters-item-label']"
            >
              {{ isAltTimeName ? (timeNameAlternative ? timeNameAlternative.hours : '') : (timeName ? timeName.hours : '') }}
            </div>
          </div>
          <div
            v-data-test="{ el: 'promotion-countdown__counters-separator'}"
            :class="$style['promotion-countdown__counters-separator']"
          >
            :
          </div>
        </template>
        <template v-if="isMinutesShown">
          <div :class="$style['promotion-countdown__counters-item']">
            <div
              v-data-test="{ el: 'promotion-countdown__counters-item-value'}"
              :class="$style['promotion-countdown__counters-item-value']"
            >
              {{ timeLeft.minutes < 10 ? `0${timeLeft.minutes}` : timeLeft.minutes }}
            </div>
            <div
              v-data-test="{ el: 'promotion-countdown__counters-item-label'}"
              :class="$style['promotion-countdown__counters-item-label']"
            >
              {{ isAltTimeName ? (timeNameAlternative ? timeNameAlternative.minutes : '') : (timeName ? timeName.minutes : '') }}
            </div>
          </div>
          <div
            v-data-test="{ el: 'promotion-countdown__counters-separator'}"
            :class="$style['promotion-countdown__counters-separator']"
          >
            :
          </div>
        </template>
        <div :class="$style['promotion-countdown__counters-item']">
          <div
            v-data-test="{ el: 'promotion-countdown__counters-item-value'}"
            :class="$style['promotion-countdown__counters-item-value']"
          >
            {{ timeLeft.seconds < 10 ? `0${timeLeft.seconds}` : timeLeft.seconds }}
          </div>
          <div
            v-data-test="{ el: 'promotion-countdown__counters-item-label'}"
            :class="$style['promotion-countdown__counters-item-label']"
          >
            {{ isAltTimeName ? (timeNameAlternative ? timeNameAlternative.seconds : '') : (timeName ? timeName.seconds : '') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>
.promotion-countdown {
  $self: &;

  &__inner {
    display: flex;
    flex-direction: column;
    gap: $promotionCountDownInnerGap;
    padding: $promotionCountDownInnerPadding;
    background-color: $promotionCountDownInnerBackgroundColor;
    border-radius: $promotionCountDownInnerBorderRadius;
  }

  &__title {
    @include promotionCountDownTitle;

    color: $promotionCountDownTitleColor;
    text-align: center;
  }

  &__counters {
    display: flex;
    justify-content: center;

    &-item {
      display: flex;
      flex-direction: $promotionCountDownItemDirection;
      gap: $promotionCountDownItemGap;
      text-align: center;

      &-value {
        @include promotionCountDownItemValue;

        color: $promotionCountDownItemValueColor;
      }

      &-label {
        @include promotionCountDownItemLabel;

        color: $promotionCountDownItemLabelColor;
        text-transform: $promotionCountDownItemLabelTextTransform;
        letter-spacing: $promotionCountDownItemLabelLetterSpacing;
      }
    }

    &-separator {
      @include promotionCountDownSeparator;

      margin: $promotionCountDownSeparatorMargin;
      color: $promotionCountDownSeparatorColor;
    }
  }

  &--kind {
    @each $kind, $kindProps in $promotionCountdownKindProps {
      &-#{$kind} {
        #{$self}__inner {
          background-color: get($kindProps, 'backgroundColor');
        }

        #{$self}__title,
        #{$self}__counters-item-value,
        #{$self}__counters-item-label, {
          color: get($kindProps, 'color');
        }

        #{$self}__counters-separator {
          color: get($kindProps, 'colorSeparator');
        }
      }
    }
  }
}
</style>
