import type { Ref, MaybeRef } from 'vue';
import { toRef, computed } from 'vue';

import type { SportlineEventId } from 'web/src/modules/sportline/types';

import useCustomerFavoritesService from './useCustomerFavoritesService';

export function useIsFavoriteEvent(eventId?: MaybeRef<Optional<Maybe<SportlineEventId>>>): Ref<boolean> {
  const id = toRef(eventId);
  const { customerOptimisticFavoriteSportEventsIds } = useCustomerFavoritesService();
  return computed(() => !!id.value && customerOptimisticFavoriteSportEventsIds.value.includes(id.value));
}
