import type { Ref } from 'vue';

import { BusEvent, useEventsBus } from '@leon-hub/event-bus';

import type { CatalogClickPayload } from 'web/src/modules/sportline/types';

export interface SportlineCatalogClickProps {
  eventId?: Ref<Optional<Maybe<string>>>;
  sportId?: Ref<Optional<Maybe<string>>>;
  regionId?: Ref<Optional<Maybe<string>>>;
  leagueId?: Ref<Optional<Maybe<string>>>;
}

export interface SportlineCatalogClickComposable {
  onCatalogClick(event: MouseEvent): void;
}

export function useSportlineCatalogClick(props: SportlineCatalogClickProps): SportlineCatalogClickComposable {
  const {
    eventId,
    sportId,
    regionId,
    leagueId,
  } = props;

  const bus = useEventsBus();

  function getFinalPayload(): CatalogClickPayload {
    const result: CatalogClickPayload = {};
    if (eventId?.value) { result.eventId = eventId.value; }
    if (sportId?.value) { result.eventId = sportId.value; }
    if (regionId?.value) { result.eventId = regionId.value; }
    if (leagueId?.value) { result.eventId = leagueId.value; }
    return result;
  }

  function onCatalogClick(event: MouseEvent): void {
    const payload = getFinalPayload();
    bus.emit(BusEvent.CATALOG_CLICK, { event, payload });
  }

  return { onCatalogClick };
}
