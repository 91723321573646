import type { Ref, ComputedRef } from 'vue';
import { computed, ref, nextTick } from 'vue';

import { assert } from '@leon-hub/guards';
import type { IconNameType } from '@leon-hub/icons';
import { Timer } from '@leon-hub/utils';
import type { FocusableInputRef, Focusable } from '@leon-hub/focus';

import type { VScrollbarRef } from '@components/v-scrollbar';
import { useDropdownButton } from '@components/core-dropdown';

import type { VInputInputEvent } from 'web/src/components/Input';
import searchSelectOptions from 'web/src/utils/search/searchSelectOptions';

import {
  isDropListMenuOptionGroupArray,
  isDropListMenuOptionArray,
} from '../guards';
import {
  getOptionGroupsLimitedByCount,
  isPlainOptions,
  searchInGroups,
} from '../utils';
import type {
  DropListSelectProps,
  DropListSelectEmits,
  DropListMenuOptionGroup,
  DropListSelectOption,
  DropListSelectDropdownProps,
  DropListSelectModalProps,
} from '../types';
import { useDropListSearchQuery } from './useDropListSearchQuery';
import { useDropListSelected } from './useDropListSelected';
import { useDropListEventHandlers } from './useDropListEventHandlers';

interface UseDropListSelect {
  isSelected(value: string): boolean;
  // search
  searchQuery: Ref<string>;
  onSearchInput(event: VInputInputEvent): void;
  clearSearchQuery(): void;
  searchInputRef: Ref<FocusableInputRef | undefined>;
  // options
  optionGroups: ComputedRef<DropListMenuOptionGroup[]>;
  plainOptions: ComputedRef<DropListSelectOption[]>;
  isEmptySearch: ComputedRef<boolean>;
  setOptionRefs(option: unknown, groupIndex: number, index: number): void;
  isSimpleList: ComputedRef<boolean>;
  // ui computed properties
  buttonIconName: ComputedRef<IconNameType | null>;
  buttonImgSrc: ComputedRef<string | null>;
  isButtonImageShown: ComputedRef<boolean>;
  selectedText: ComputedRef<string>;
  selectedSecondaryText: ComputedRef<string>;
  selectedSecondaryBadge: ComputedRef<string>;
  isButtonsBlockShown: ComputedRef<boolean>;
  // preselected
  preselectedId: Ref<string | null>;
  clearPreselected(): void;
  // dropdown
  isDropdownShown: Ref<boolean>;
  onOpen(): void;
  onClose(): void;
  toggleOpen(): void;
  focusOnDropdownButton(): void;
  dropdownButton: Ref<HTMLButtonElement | undefined>;
  // handlers
  scrollbarRef: Ref<VScrollbarRef | undefined>;
  primaryButtonRef: Ref<Focusable | undefined>;
  secondaryButtonRef: Ref<Focusable | undefined>;
  onItemClick(value: string): void;
  onPreselect(): void;
  onOptionHover(value: string): void;
  onInputKeydown(event: KeyboardEvent): void;
  onDropdownOptionKeydown(event: KeyboardEvent): void;
  onPrimaryButtonClick(): void;
  onSecondaryButtonClick(): void;
  onActionButtonKeydown(event: KeyboardEvent): void;
  onPrimaryButtonFocus(): void;
  onSecondaryButtonFocus(): void;
  onPrimaryButtonBlur(): void;
  onSecondaryButtonBlur(): void;
  onFocus(): void;
  onBlur(): void;
  // container props
  dropContainerProps: ComputedRef<DropListSelectDropdownProps | DropListSelectModalProps>;
  // pagination
  increasePaginationCount(callback?: Function): Promise<void>;
  renderComplete: Ref<boolean>;
  isRenderingMore: Ref<boolean>;
  // recalculate scroll
  onDropdownUpdate(): Promise<void>;
}

export function useDropListSelect(props: DropListSelectProps, emit: DropListSelectEmits): UseDropListSelect {
  const paginationCount = ref<number>(1);

  const resetPagination = (): void => {
    paginationCount.value = 1;
  };

  // search
  const {
    searchQuery,
    searchInputRef,
    isFilterSearch,
    onSearchInput,
    focusOnSearch,
    clearSearchQuery,
  } = useDropListSearchQuery(props, emit, resetPagination);

  const isSimpleList = computed<boolean>(() => isPlainOptions(props.options));

  const optionGroups = computed<DropListMenuOptionGroup[]>(() => {
    /** type-safe options, DropListMenuOptionGroup[] */
    if (isSimpleList.value) {
      return [];
    }
    assert(isDropListMenuOptionGroupArray(props.options));
    if (isFilterSearch.value) {
      return searchInGroups(props.options, searchQuery.value);
    }
    return props.options;
  });

  const plainOptions = computed<DropListSelectOption[]>(() => {
    /** type-safe options, DropListMenuOption[] */
    if (!isSimpleList.value) {
      return [];
    }
    assert(isDropListMenuOptionArray(props.options));
    if (isFilterSearch.value) {
      return searchSelectOptions(props.options, searchQuery.value);
    }
    return props.options;
  });

  const allFlatOptions = computed<DropListSelectOption[]>(() => {
    if (isSimpleList.value) {
      return plainOptions.value;
    }
    return optionGroups.value.flatMap((group) => group.options);
  });

  // selected

  const {
    selectedId,
    selectedText,
    selectedSecondaryText,
    selectedSecondaryBadge,
    selectedOption,
    multipleSelectedValues,
    setSingleSelected,
    toggleMultipleSelected,
    isSelected,
  } = useDropListSelected(props, allFlatOptions);

  // options

  const isEmptySearch = computed<boolean>(() => {
    if (!searchQuery.value.length) {
      return false;
    }
    return !props.isLoadingOptions && (!optionGroups.value.length && !plainOptions.value.length);
  });

  // ui computed properties

  const buttonIconName = computed<IconNameType | null>(() => {
    if (props.showSelectedIcon && selectedOption.value) {
      return selectedOption.value?.icon ?? null;
    }
    return props.placeholderIcon ?? null;
  });

  const buttonImgSrc = computed<string | null>(() => {
    if (props.showSelectedIcon && selectedOption.value) {
      return selectedOption.value?.imageSrc ?? null;
    }
    return props.placeholderImg ?? null;
  });

  const isButtonImageShown = computed<boolean>(() => !!(buttonIconName.value ?? buttonImgSrc.value));

  const isButtonsBlockShown = computed<boolean>(() => !!(props.primaryButtonLabel || props.secondaryButtonLabel));

  // preselected

  const preselectedId = ref<string | null>(null);

  const setPreselected = (value: string) => {
    preselectedId.value = value;
  };

  const clearPreselected = () => {
    preselectedId.value = '';
  };

  // cache for cansel button emit

  /** cache for old multiple selected state, for secondary (cancel) button emit */
  const multipleSelectedPreviousState = ref<string[]>([]);

  const setMultipleSelectedPreviousState = (state: string[]) => {
    multipleSelectedPreviousState.value = state;
  };

  // focus

  const haveFocusInside = ref<boolean>(false);

  const setFocusInside = (isFocused: boolean): void => {
    haveFocusInside.value = isFocused;
  };

  const onFocus = (): void => {
    if (haveFocusInside.value) {
      return;
    }
    emit('focus');
  };

  const onBlur = (): void => {
    if (haveFocusInside.value) {
      return;
    }
    emit('blur');
  };

  // computed options and pagination

  const renderCount = computed<number>(() => {
    if (!props.itemsToRender) {
      return allFlatOptions.value.length;
    }
    return props.itemsToRender * paginationCount.value;
  });

  const isRenderingMore = ref<boolean>(false);

  const renderComplete = computed<boolean>(() => !props.itemsToRender
    || renderCount.value >= allFlatOptions.value.length);

  const computedPlainOptions = computed<DropListSelectOption[]>(() => {
    if (!isSimpleList.value) {
      return [];
    }
    if (!props.itemsToRender) {
      return plainOptions.value;
    }
    return plainOptions.value.slice(0, renderCount.value);
  });

  const computedOptionGroups = computed<DropListMenuOptionGroup[]>(() => {
    if (isSimpleList.value) {
      return [];
    }
    if (!props.itemsToRender || renderCount.value >= allFlatOptions.value.length) {
      return optionGroups.value;
    }
    return getOptionGroupsLimitedByCount(optionGroups.value, renderCount.value);
  });

  // dropdown properties

  let isClosing = false;

  const setClosedTimeout = (): void => {
    isClosing = true;
    Timer.setTimeout(() => {
      isClosing = false;
    }, 300);
  };

  const {
    isDropdownShown,
    closeDropdown,
    openDropdown,
    focusOnDropdownButton,
    dropdownProps,
    dropdownButton,
  } = useDropdownButton({});

  const onOpen = (): void => {
    if (isDropdownShown.value || isClosing) {
      return;
    }
    if (props.isMultiselectMode) {
      setMultipleSelectedPreviousState(multipleSelectedValues.value);
      setPreselected('');
    } else {
      setPreselected(selectedId.value ?? '');
    }
    openDropdown();
    setFocusInside(true);
    focusOnDropdownButton();
  };

  const onClose = (): void => {
    // todo: compare event target?
    setClosedTimeout();
    void closeDropdown().then(() => {
      clearSearchQuery();
      clearPreselected();
      resetPagination();
      focusOnDropdownButton();
      setFocusInside(false);
    });
  };

  const toggleOpen = (): void => (isDropdownShown.value ? onClose() : onOpen());

  // handlers

  const onPrimaryButtonClick = (): void => {
    emit('primary-button-click', multipleSelectedValues.value);
    onClose();
  };

  const onSecondaryButtonClick = (): void => {
    emit('secondary-button-click', multipleSelectedPreviousState.value);
    onClose();
  };

  const onItemClick = (value: string): void => {
    emit('item-click', value);
    if (props.isMultiselectMode) {
      toggleMultipleSelected(value);
      return;
    }
    setSingleSelected(value);
    onClose();
  };

  const onPreselect = (): void => {
    if (preselectedId.value) {
      onItemClick(preselectedId.value);
    } else {
      onClose();
    }
  };

  const increasePaginationCount = async (): Promise<void> => {
    if (!renderComplete.value && !isRenderingMore.value) {
      isRenderingMore.value = true;
      paginationCount.value += 1;
      await nextTick();
      isRenderingMore.value = false;
    }
  };

  const {
    setOptionRefs,
    scrollbarRef,
    primaryButtonRef,
    secondaryButtonRef,
    onOptionHover,
    onInputKeydown,
    onDropdownOptionKeydown,
    onActionButtonKeydown,
    onPrimaryButtonFocus,
    onPrimaryButtonBlur,
    onSecondaryButtonFocus,
    onSecondaryButtonBlur,
  } = useDropListEventHandlers(props, {
    setPreselected,
    preselectedId,
    allFlatOptions,
    focusOnSearch,
    focusOnDropdownButton,
    onOpen,
    onClose,
    onPreselect,
    isDropdownShown,
    increasePaginationCount,
    lastRenderedCount: renderCount,
  });

  const dropContainerProps = computed<DropListSelectDropdownProps | DropListSelectModalProps>(() => {
    if (props.modalDropdown) {
      return {
        label: props.label,
        isFooterShown: isButtonsBlockShown.value,
        searchEnabled: props.searchEnabled,
      };
    }
    return {
      dropdownProps: dropdownProps.value,
      isBehindModals: props.isBehindModals,
      searchEnabled: props.searchEnabled,
      dropDirection: props.dropDirection,
      isFooterShown: isButtonsBlockShown.value,
    };
  });

  // recalculate scroll
  const onDropdownUpdate = async (): Promise<void> => {
    await nextTick();
    scrollbarRef.value?.scrollUpdate();
  };

  return {
    isSelected,
    // search
    searchQuery,
    searchInputRef,
    onSearchInput,
    clearSearchQuery,
    // options
    isSimpleList,
    optionGroups: computedOptionGroups,
    plainOptions: computedPlainOptions,
    setOptionRefs,
    isEmptySearch,
    // ui computed properties
    buttonIconName,
    buttonImgSrc,
    isButtonImageShown,
    selectedText,
    selectedSecondaryText,
    selectedSecondaryBadge,
    isButtonsBlockShown,
    // preselected
    preselectedId,
    clearPreselected,
    // dropdown
    isDropdownShown,
    toggleOpen,
    onOpen,
    onClose,
    focusOnDropdownButton,
    dropdownButton,
    // handlers
    scrollbarRef,
    primaryButtonRef,
    secondaryButtonRef,
    onPrimaryButtonClick,
    onSecondaryButtonClick,
    onItemClick,
    onOptionHover,
    onPreselect,
    onInputKeydown,
    onDropdownOptionKeydown,
    onActionButtonKeydown,
    onPrimaryButtonFocus,
    onPrimaryButtonBlur,
    onSecondaryButtonFocus,
    onSecondaryButtonBlur,
    onFocus,
    onBlur,
    dropContainerProps,
    // pagination
    increasePaginationCount,
    renderComplete,
    isRenderingMore,
    // recalculate scroll
    onDropdownUpdate,
  };
}
