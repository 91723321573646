<script setup lang="ts">
import { defineAsyncComponent } from 'vue';

import vDataTestUnit from 'web/src/testing/directives/vDataTestUnit';
import { VEmpty } from 'web/src/components/Empty';
import type { AuthorizedSnackbarsProps } from 'web/src/modules/core/components/AuthorizedSnackbars/types';

import { useAuthorizedSnackbars } from './composables';

defineProps<AuthorizedSnackbarsProps>();

const TheDowntimeAlert = defineAsyncComponent(
  () => import('web/src/modules/downtime/components/TheDowntimeAlert/TheDowntimeAlert.vue'),
);

const VideoVerificationAlert = defineAsyncComponent(
  () => import('web/src/modules/front-notifications/components/VideoVerificationAlert/VideoVerificationAlert.vue'),
);

const IdentificationStatusNotification = null ? defineAsyncComponent(
  () => import('web/src/modules/identification/submodules/status/components/IdentificationStatusNotification/IdentificationStatusNotification.vue'),
) : VEmpty;

const FomoBonusNotification = defineAsyncComponent(
  () => import('web/src/modules/front-notifications/components/FomoBonusNotification/FomoBonusNotification.vue'),
);

const {
  isDowntimeAlertVisible,
  isIdentificationStatusAlertVisible,
  isVideoVerificationAlert,
  isFomoBonusAlert,
} = useAuthorizedSnackbars();
</script>

<template>
  <template v-if="showLayout">
    <TheDowntimeAlert
      v-if="isDowntimeAlertVisible"
    />
    <IdentificationStatusNotification
      v-if="null && isIdentificationStatusAlertVisible"
      v-data-test-unit="{el: 'ident'}"
    />
    <VideoVerificationAlert v-if="isVideoVerificationAlert" />
  </template>

  <FomoBonusNotification v-if="isFomoBonusAlert" />
</template>
