export enum RegistrationFieldName {
  PHONE = 'phone',
  EMAIL = 'email',
  PASSWORD = 'password',
  BONUS_CODE = 'bonusCode',
  SMS_CODE = 'code',
  STEP = 'step',
  MODAL = 'modal',
  STEP_MODIFIER = 'resendSmsStepModifier',
}
