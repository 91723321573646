import type {
  GetElementSize,
  VirtualListSportHeadlineElement,
} from 'web/src/modules/sportline/views/virtual-list-renderer/types';
import { VirtualListRendererElementSize } from 'web/src/modules/sportline/views/virtual-list-renderer/constants';

import { getSportVirtualElementId } from './getElementId';

export function createGetSportHeadlineElementSize(): GetElementSize<VirtualListSportHeadlineElement> {
  return (element) => ({
    id: getSportVirtualElementId(element.sport),
    height: VirtualListRendererElementSize.SportHeadlineHeight
    + VirtualListRendererElementSize.SportHeadlineGap,
  });
}
