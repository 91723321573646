import { logger } from '@leon-hub/logging';
import { assert } from '@leon-hub/guards';

import type {
  BetInfoItem,
  BetInfoObject,
  SlipEntry,
  SlipEntryId,
} from '../types';
import { getSlipEntryId } from '../../../utils';
import { updateBetsInfoItemBySlipEntry } from './updateBetsInfoItemBySlipEntry';
import { entryIsTotalHandicap } from './entryIsTotalHandicap';
import isSlipEntryArray from '../../../guards/isSlipEntryArray';

interface CheckTotalHandicapReplacementPayload {
  oldEntries: SlipEntry[];
  oldEntryCache: SlipEntry[];
  updatedEntries: SlipEntry[];
  selectedEntriesIds: SlipEntryId[];
  betsInfo: BetInfoObject;
  autoAcceptChanges: boolean;
}

interface CheckTotalHandicapReplacementOutput {
  betsInfo: BetInfoObject;
  slipEntryCache: SlipEntry[];
  selectedEntriesIds: SlipEntryId[];
}

interface HandleReplacedEntryPayload extends Pick<CheckTotalHandicapReplacementPayload, 'oldEntries' | 'oldEntryCache' | 'betsInfo' | 'autoAcceptChanges'> {
  index: number;
  updatedEntry: SlipEntry;
  replacedAt: number;
}

export function handleReplacedEntry({
  index,
  updatedEntry,
  oldEntries,
  oldEntryCache,
  betsInfo,
  autoAcceptChanges,
  replacedAt,
}: HandleReplacedEntryPayload): {
    betsInfoItem: BetInfoItem;
    /** index to reset clear related slip cache item */
    indexToCleanCache: number;
  } {
  let indexToCleanCache = -1;
  const itemIsCached = !oldEntries.length || index > oldEntries.length - 1;
  let oldEntry: SlipEntry;
  if (itemIsCached) {
    const indexForCache = index - oldEntries.length;
    oldEntry = oldEntryCache[indexForCache];
    /** need to clear related items from cache */
    indexToCleanCache = indexForCache;
  } else {
    oldEntry = oldEntries[index];
  }
  if (!oldEntry) {
    throw new Error('checkTotalHandicapReplacement: no matched oldEntry');
  }
  const oldBetsInfoItem = betsInfo[getSlipEntryId(oldEntry)];
  if (!oldBetsInfoItem) {
    throw new Error('checkTotalHandicapReplacement: no matched oldBetsInfoItem');
  }

  const updatedBetsInfoItem = updateBetsInfoItemBySlipEntry({
    sourceItem: oldBetsInfoItem,
    entry: updatedEntry,
  });
  if (autoAcceptChanges) {
    updatedBetsInfoItem.replacedAt = replacedAt;
  } else {
    updatedBetsInfoItem.oldRunnerName = oldEntry.runnerName ?? oldBetsInfoItem.runnerName;
  }
  return {
    indexToCleanCache,
    betsInfoItem: updatedBetsInfoItem,
  };
}

export function checkTotalHandicapReplacement({
  oldEntries, oldEntryCache, updatedEntries, selectedEntriesIds, betsInfo, autoAcceptChanges,
}: CheckTotalHandicapReplacementPayload): CheckTotalHandicapReplacementOutput | null {
  if (oldEntries.length + oldEntryCache.length !== updatedEntries.length) {
    return null;
  }
  const selectedEntriesSet = new Set<SlipEntryId>([...selectedEntriesIds]);
  const changedEntries: SlipEntry[] = updatedEntries.filter((entry) => {
    const id = getSlipEntryId(entry);
    return !selectedEntriesSet.has(id);
  });
  if (!changedEntries.length || !changedEntries.every((entry) => entryIsTotalHandicap(entry))) {
    logger.info('checkTotalHandicapReplacement: no changed items or not of them are total handicap items');
    return null;
  }

  const updatedBetsInfo: BetInfoObject = {};
  const updatesSelectedEntriesIds: SlipEntryId[] = [];
  const temporarySlipEntryCache: (SlipEntry | null)[] = [...oldEntryCache];
  const replacedAt = Date.now();
  for (let i = 0; i < updatedEntries.length; i += 1) {
    const updatedEntry = updatedEntries[i];
    const id = getSlipEntryId(updatedEntry);
    updatesSelectedEntriesIds.push(id);
    if (selectedEntriesSet.has(id)) {
      /** entry was not replaced */
      const oldBetsInfoItem = betsInfo[id];
      updatedBetsInfo[id] = updateBetsInfoItemBySlipEntry({
        sourceItem: oldBetsInfoItem,
        entry: updatedEntry,
      });
    } else {
      /** this is replaced entry */
      const replaceEntryResult = handleReplacedEntry({
        index: i,
        updatedEntry,
        oldEntries,
        oldEntryCache,
        betsInfo,
        autoAcceptChanges,
        replacedAt,
      });
      if (replaceEntryResult.indexToCleanCache > -1) {
        /** need to clear related items from cache */
        temporarySlipEntryCache[replaceEntryResult.indexToCleanCache] = null;
      }
      updatedBetsInfo[id] = replaceEntryResult.betsInfoItem;
    }
  }
  const slipEntryCache = temporarySlipEntryCache.filter((item) => item !== null);
  assert(isSlipEntryArray(slipEntryCache), 'slipEntryCache is not of a type SlipEntry[]');
  return {
    betsInfo: updatedBetsInfo,
    selectedEntriesIds: updatesSelectedEntriesIds,
    slipEntryCache,
  };
}
