import type { RouteLocationNormalized } from 'vue-router';

import { CasinoRouteName } from '@leon-hub/routing-config-names/src/CasinoRouteName';

export default function isCasinoGameRouteName(routeName?: RouteLocationNormalized['name']): boolean {
  return !!routeName && ([
    CasinoRouteName.CASINO_LIVE_GAME,
    CasinoRouteName.CASINO_GAME,
  ] as string[]).includes(String(routeName));
}
