<script lang="ts" setup>
import { toRef } from 'vue';

import type {
  RecycleScrollerProps,
  RecycleScrollerSlots,
  RecycleScrollerRef,
} from './types';
import { defaultOverscanValue } from './constants';
import { useOnScrollListeners } from './composables';
import { useVerticalRecycleScroller } from './useRecycleScroller';

const props = defineProps<RecycleScrollerProps>();
defineSlots<RecycleScrollerSlots>();

const options = toRef(props, 'options');
const overscan = toRef(props, 'overscan', defaultOverscanValue);
const disableOwnScroll = toRef(props, 'disableOwnScroll', false);

const {
  list,
  containerProps,
  wrapperProps,
  scrollTo,
  scrollBy,
} = useVerticalRecycleScroller({ options, overscan });

const {
  containerRef,
  listenerTargetRef,
  onScroll,
} = containerProps;

defineExpose<RecycleScrollerRef>({ scrollTo, scrollBy });
useOnScrollListeners({
  containerRef,
  listenerTargetRef,
  disableOwnScroll,
  onScroll,
});
</script>

<template>
  <div v-auto-id="'VRecycleScrollerVertical'"
    ref="containerRef"
    :class="{ [$style['vertical-scrollable']]: !disableOwnScroll }"
  >
    <div :style="wrapperProps.style">
      <div
        v-for="item in list"
        :key="item.index"
        :style="item.style"
      >
        <slot
          name="item"
          :item="item"
          :option="options[item.index]"
        />
      </div>
    </div>
  </div>
</template>

<style module lang="scss">
.vertical-scrollable {
  overflow-y: auto;
}
</style>
