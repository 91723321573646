import type { SimpleRegistrationInput } from '@leon-hub/api-sdk';
import { FormType, CustomerFieldType } from '@leon-hub/api-sdk';
import { getAppsFlyerUID, getFullAuthData } from '@leon-hub/cordova';

import { mapRegistrationFormData } from 'web/src/modules/registration/utils';
import { useFormDefaultFormPhoneValue } from 'web/src/modules/phone/composables';
import { isPartialFormPhoneValue } from 'web/src/components/Form/guards';
import type { FormData } from 'web/src/components/Form/types';
import { useDeviceFingerprints } from 'web/src/modules/identity/composables';
import type {
  CoreRegistrationFormService as RegistrationFormService,
  PrepareRegistrationInputOptions,
  RegistrationForm,
} from 'web/src/modules/registration/types';

export default class CoreRegistrationFormService implements RegistrationFormService {
  // eslint-disable-next-line class-methods-use-this
  normalizeFormData(form: RegistrationForm): Maybe<RegistrationForm> {
    if (!form?.uiFormSchema?.fields) {
      return null;
    }

    const { parsePhone } = useFormDefaultFormPhoneValue();

    return {
      ...form,
      uiFormSchema: {
        ...form.uiFormSchema,
        fields: form.uiFormSchema.fields.map((field) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
          if (field.id === CustomerFieldType.PHONE_INPUT
            && isPartialFormPhoneValue(field.defaultValue)
            && field.defaultValue?.prefix === '') {
            return {
              ...field,
              defaultValue: parsePhone(field.defaultValue?.suffix ?? ''),
            };
          }

          if (field.type === FormType.BOOLEAN && field.defaultValue) {
            return {
              ...field,
              defaultValue: field.defaultValue === 'true',
            };
          }

          return field;
        }),
      },
    };
  }

  // eslint-disable-next-line class-methods-use-this
  async prepareRegistrationInput(
    formData: FormData,
    options: PrepareRegistrationInputOptions,
  ): Promise<SimpleRegistrationInput> {
    let fingerprintData: Pick<SimpleRegistrationInput,
      'fingerprint' | 'fpjsVisitorId' | 'cordovaDeviceUUID' | 'appGUID'> = {};
    let appsflyerId = '';
    if (process.env.VUE_APP_PLATFORM_CORDOVA) {
      appsflyerId = await getAppsFlyerUID();
    }

    if (options.addFingerprint) {
      let authData;

      const {
        deviceFingerprint,
        deviceFingerprintJs,
      } = await useDeviceFingerprints().getDeviceFingerprints();

      if (process.env.VUE_APP_PLATFORM_CORDOVA) {
        authData = await getFullAuthData();
      }

      fingerprintData = {
        ...fingerprintData,
        fingerprint: deviceFingerprint,
        fpjsVisitorId: deviceFingerprintJs,
        cordovaDeviceUUID: authData ? authData.cordovaDeviceUUID : '',
        appGUID: authData ? authData.appGUID : '',
      };
    }

    return {
      ...fingerprintData,
      appsflyerId,
      registrationType: options.registrationType,
      schemaId: options.schemaId,
      formParams: [...mapRegistrationFormData(formData), ...(options.extraFormParams ?? [])],
    };
  }
}
