import { isObject } from '@leon-hub/guards';

export function isFullResponse<R, Reference>(value: unknown): value is Extract<R, Reference> {
  return isObject(value) && ('id' in value) && Object.keys(value).length > 1;
}

export function isResponseReference<
  Reference extends { id: number | string } = { id: number },
>(value: unknown): value is (Reference & { [key: string]: never }) {
  return isObject(value) && Object.keys(value).length === 1 && ('id' in value);
}
