import { computed } from 'vue';
import type { Ref } from 'vue';

import type { SportlineEventWidgetInfo, LiveWidget } from 'web/src/modules/sportline/types';

interface UseSportlineEventLiveWidgetProps {
  sportlineEvent: Ref<Maybe<SportlineEventWidgetInfo | {} | undefined>>;
}

interface UseSportlineEventLiveWidgetComposable {
  liveWidget: Ref<Maybe<LiveWidget>>;
}

export function useSportlineEventLiveWidget(
  props: UseSportlineEventLiveWidgetProps,
): UseSportlineEventLiveWidgetComposable {
  const { sportlineEvent } = props;
  return {
    liveWidget: computed<Maybe<LiveWidget>>(() => (
      sportlineEvent.value && 'liveWidget' in sportlineEvent.value
        ? (sportlineEvent.value?.liveWidget ?? null)
        : null
    )),
  };
}
