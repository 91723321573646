export enum FramedEnvironmentParameter {
  AutoHeight = 'auto-height',
  FullHeight = 'full-height',
}

export enum IframeWidget {
  Captcha = 'captcha',
  SportradarLiveMatchTracker = 'sportradarLiveMatchTracker',
  SportradarLiveMatchTrackerLegacy = 'liveWidget',
  SportradarStatistics = 'sportradarStatistics',
  SportradarLiveScore = 'sportradarLiveScore',
  LiveStream = 'liveStream',
  LSports = 'lsports',
  LSportsLegacy = 'lsportsWidget',
  Betgenius = 'betgenius',
  BetgeniusLegacy = 'geniusSportsPitchWidget',
  VirtualSport = 'virtualSport',
  EgsGame = 'egsGame',
  BetGames = 'betgames',
  ReCaptchaV3 = 'recaptchaV3',
  TwitterTimeline = 'twitterTimeline',
  PrerenderEgsGame = 'play-game',
}
