<script lang="ts" setup>
import type { VPhotoPlaceholderProps } from 'web/src/components/PhotoPlaceholder/VPhotoPlaceholder/types';
import VPhotoPlaceholder from 'web/src/components/PhotoPlaceholder/VPhotoPlaceholder/VPhotoPlaceholder.vue';
import type { InputEventType } from 'web/src/components/Input/enums';
import type { VInputFileEvent } from 'web/src/components/Input';

import { useVPhotoInput } from './composables';

interface VPhotoInputProps {
  id: string;
  name: string;
  placeholder?: VPhotoPlaceholderProps;
  onPlaceholderClick?: () => void;
}

interface VPhotoInputEmits {
  (e: InputEventType.CHANGE, value: VInputFileEvent): void;
}

const props = withDefaults(defineProps<VPhotoInputProps>(), {
  placeholder: () => ({}),
});
const emit = defineEmits<VPhotoInputEmits>();
const { onChange } = useVPhotoInput(props, emit);
const bemClassName = 'photo-input';
</script>

<template>
  <div v-auto-id="'VPhotoInput'" :class="$style[bemClassName]">
    <input
      :id="id"
      :class="$style[`${bemClassName}__file`]"
      type="file"
      name="name"
      accept="image/*"
      @change.stop="onChange"
      @input.stop
    >
    <label
      v-if="onPlaceholderClick"
      :class="$style[`${bemClassName}__label`]"
      @click="onPlaceholderClick"
    >
      <VPhotoPlaceholder v-bind="placeholder" />
    </label>
    <label
      v-else
      :for="id"
      :class="$style[`${bemClassName}__label`]"
    >
      <VPhotoPlaceholder v-bind="placeholder" />
    </label>
  </div>
</template>

<style lang="scss" module>
.photo-input {
  display: flex;

  &__file {
    display: none;
  }

  &__label {
    margin: 0 auto;
    cursor: pointer;
  }
}
</style>
