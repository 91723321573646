import type { ComputedRef, Ref } from 'vue';
import {
  onMounted, computed, ref, toRef,
} from 'vue';
import { useRouter } from 'vue-router';

import type { ICasinoRouteName } from '@leon-hub/routing-config-names/src/CasinoRouteName';
import { CasinoRouteName } from '@leon-hub/routing-config-names/src/CasinoRouteName';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';

import type { DefaultModalProps } from 'web/src/components/Modal/DefaultModal/types';
import { useFormatMoney } from 'web/src/modules/money/composables';
import type {
  BonusLoyaltyProgramBalanceProps,
} from 'web/src/modules/bonuses/components/BonusLoyaltyProgramBalance/types';
import type { ModalWindowHeaderProps } from 'web/src/components/Modal/ModalWindowHeader/types';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import {
  CasinoLoyaltyProgramIconSizes,
  CasinoLoyaltyProgramLevelStatus,
} from 'web/src/modules/casino-loyalty-program/store/types';
import type {
  CasinoLoyaltyProgramIconProperties,
  CasinoLoyaltyProgramListProperties,
  CasinoLoyaltyProgramProgressProperties,
  CasinoLevelSettingWithStatus,
  CasinoLoyaltyProgramCurrentLevelCardProperties,
  CasinoLoyaltyLevelSetting,
} from 'web/src/modules/casino-loyalty-program/store/types';
import { ModalSelector } from 'web/src/modules/core/enums';
import { useI18nStore } from 'web/src/modules/i18n/store';
import { useI18n } from 'web/src/modules/i18n/composables';
import {
  getRemainingPoints,
  getCurrentPointsToString,
  getProgressProps,
  isLastLevel,
  levelSettingsById,
} from 'web/src/modules/casino-loyalty-program/store/utils';
import useCasinoLoyaltyStore from 'web/src/modules/casino-loyalty-program/store/useCasinoLoyaltyStore';

import type { CasinoLoyaltyProgramRoutePageProps } from '../types';
import {
  getBalanceProps,
  getIconProps,
  getTitleProps,
  getModalProps,
  getHeaderProperties,
  getProgramListProperties,
  getDescriptionText,
} from './utils';

export interface CasinoLoyaltyProgramRoutePageComposable {
  selectedId: Ref<number>;
  clickCounter: () => void;
  showLevelDescription: (id: number) => void;
  currentPointsToString: ComputedRef<string>;
  remainingPoints: ComputedRef<string>;
  progressProps: (id: number) => CasinoLoyaltyProgramProgressProperties;
  nextLevelId: ComputedRef<number | undefined>;
  programListProperties: (id: number) => CasinoLoyaltyProgramListProperties;
  currentPoints: ComputedRef<number>;
  casinoLoyaltyProgramInfoRouteName: ICasinoRouteName;
  modalSelector: ModalSelector;
  modalProps: ComputedRef<DefaultModalProps>;
  onClose: () => void;
  headerProperties: ComputedRef<ModalWindowHeaderProps>;
  onCloseAll: () => void;
  iconProps: ComputedRef<CasinoLoyaltyProgramIconProperties>;
  modalIconSize: ComputedRef<CasinoLoyaltyProgramIconSizes>;
  titleProps: ComputedRef<Dictionary<string | undefined>>;
  balanceProps: ComputedRef<BonusLoyaltyProgramBalanceProps>;
  descriptionText: (id: number) => string;
  progressText: (id: number) => string;
  locale: Ref<string>;
  currentLevelCardProperties: ComputedRef<CasinoLoyaltyProgramCurrentLevelCardProperties>;
  currentLevelId: ComputedRef<number>;
  levelSettingsWithStatus: ComputedRef<CasinoLevelSettingWithStatus[]>;
  progress: Ref<number>;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export default function useCasinoLoyaltyProgramRoutePage(
  props: CasinoLoyaltyProgramRoutePageProps,
): CasinoLoyaltyProgramRoutePageComposable {
  const locale = toRef(useI18nStore(), 'locale');
  const formatMoney = useFormatMoney();
  const router = useRouter();
  const { $translate } = useI18n();
  const analytics = useAnalytics();

  const casinoLoyaltyStore = useCasinoLoyaltyStore();

  const modalSelector = process.env.VUE_APP_LAYOUT_DESKTOP
    ? ModalSelector.DESKTOP_INNER_MODAL
    : ModalSelector.BODY;

  const casinoLoyaltyProgramInfoRouteName = CasinoRouteName.CASINO_LOYALTY_PROGRAM_INFO;

  const selectedId = ref(0);

  const currentPoints = computed<number>(() => casinoLoyaltyStore.currentPoints);
  const name = computed<string>(() => casinoLoyaltyStore.name);
  const currentLevelCardProperties = computed(() => casinoLoyaltyStore.currentLevelCardProperties);
  const currentLevelId = computed(() => casinoLoyaltyStore.currentLevelId);
  const levelSettingsWithStatus = computed(() => casinoLoyaltyStore.levelSettingsWithStatus);
  const nextLevelData = computed<
    CasinoLoyaltyLevelSetting | null
  >(() => casinoLoyaltyStore.nextLevelData);
  const nextLevelId = computed<number | undefined>(() => casinoLoyaltyStore.nextLevelData?.id);
  const level = computed(() => levelSettingsById(selectedId.value, levelSettingsWithStatus.value));
  const levelSettings = computed(() => casinoLoyaltyStore.levelSettings);

  const modalProps = computed<
    DefaultModalProps
  >(() => getModalProps(level.value?.settingsBackground));

  const iconProps = computed<
    CasinoLoyaltyProgramIconProperties
  >(() => getIconProps(level.value?.imageUrl, level.value?.status));

  const titleProps = computed<
    Dictionary<string | undefined>
  >(() => getTitleProps(level.value?.name, level.value?.status));

  const balanceProps = computed<
    BonusLoyaltyProgramBalanceProps
  >(() => getBalanceProps(level.value?.status, level.value?.requiredPoints, currentPoints.value, $translate, formatMoney));

  const headerProperties = computed<
    ModalWindowHeaderProps
  >(() => getHeaderProperties($translate));

  const remainingPoints = computed<
    string
  >(() => getRemainingPoints(nextLevelData.value?.requiredPoints, formatMoney));

  const currentPointsToString = computed<
    string
  >(() => getCurrentPointsToString(currentPoints.value, formatMoney));

  const progressProps = (id: number): CasinoLoyaltyProgramProgressProperties => {
    const selectedLevel = levelSettingsById(id, levelSettingsWithStatus.value);

    return getProgressProps(selectedLevel?.progress, selectedLevel?.imageUrl, selectedLevel?.status);
  };

  const programListProperties = (id: number): CasinoLoyaltyProgramListProperties => {
    const selectedLevel = levelSettingsById(id, levelSettingsWithStatus.value);

    return getProgramListProperties(selectedLevel);
  };

  const descriptionText = (id: number): string => {
    const selectedLevel = levelSettingsById(id, levelSettingsWithStatus.value);
    const selectedLevelStatus = selectedLevel?.status;
    const isLastLevelValue = isLastLevel(id, levelSettings.value);

    return getDescriptionText(name.value, selectedLevel, selectedLevelStatus, isLastLevelValue, $translate, formatMoney);
  };

  const progressText = (id: number): string => {
    const selectedLevel = levelSettingsById(id, levelSettingsWithStatus.value);
    const selectedLevelRequiredPoints = selectedLevel?.requiredPoints;
    const isLevelInProgress = selectedLevel?.status === CasinoLoyaltyProgramLevelStatus.PROCESS;

    const levelPoints = isLevelInProgress ? currentPoints.value : selectedLevelRequiredPoints;

    return isLevelInProgress
      ? `${levelPoints} / ${selectedLevelRequiredPoints} XP`
      : `${selectedLevelRequiredPoints} XP`;
  };

  const modalIconSize = computed(() => (level.value?.progress && level.value?.progress <= 100
    ? CasinoLoyaltyProgramIconSizes.SIZE_160 : CasinoLoyaltyProgramIconSizes.SIZE_180));

  const progress = computed(() => level.value?.progress || 0);
  const onClose = (): void => {
    selectedId.value = 0;
    void router.replace({
      name: CasinoRouteName.CASINO_LOYALTY_PROGRAM,
    });
  };

  const onCloseAll = (): void => {
    if (process.env.VUE_APP_LAYOUT_PHONE) {
      onClose();
    } else {
      void router.closeModal();
    }
  };

  const showLevelDescription = (id: number): void => {
    selectedId.value = id;

    void router.push({
      name: CasinoRouteName.CASINO_LOYALTY_PROGRAM_ID,
      params: {
        id: String(id),
      },
    });
  };

  const clickCounter = (): void => {
    analytics.push(AnalyticsEvent.CLICK_MAP, { clickCounter: { loyaltyProgram: 'nDaysLeft' } });
  };

  onMounted(async () => {
    await casinoLoyaltyStore.fetchCasinoLoyaltyStatus();
    selectedId.value = parseInt(props.id || '0', 10);
  });

  return {
    selectedId,
    clickCounter,
    showLevelDescription,
    currentPointsToString,
    remainingPoints,
    progressProps,
    nextLevelId,
    programListProperties,
    currentPoints,
    casinoLoyaltyProgramInfoRouteName,
    modalSelector,
    modalProps,
    onClose,
    headerProperties,
    onCloseAll,
    iconProps,
    modalIconSize,
    titleProps,
    balanceProps,
    descriptionText,
    progressText,
    locale,
    currentLevelCardProperties,
    currentLevelId,
    levelSettingsWithStatus,
    progress,
  };
}
