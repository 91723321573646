export const enum Keys {
  Tab = 'Tab',
  Space = 'Space',
  ArrowDown = 'ArrowDown',
  ArrowUp = 'ArrowUp',
  ArrowLeft = 'ArrowLeft',
  ArrowRight = 'ArrowRight',
  Backspace = 'Backspace',
  Enter = 'Enter',
  Escape = 'Escape',
}
