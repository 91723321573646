<script lang="ts" setup>
import { ref } from 'vue';

import { useVBrand } from './composables';
import type { VBrandProps } from './types';
import { prepareUseLogoProps } from './composables/utils';

interface VBrandEmits {
  (e: 'click', event: MouseEvent): void;
}

const props = withDefaults(defineProps<VBrandProps>(), {
  alt: '',
});
const emit = defineEmits<VBrandEmits>();

const {
  logoUrl,
} = useVBrand({
  ...prepareUseLogoProps(props),
  customBrand: ref('esport'),
});

function onClick(event: MouseEvent): void {
  emit('click', event);
}
</script>

<template>
  <img v-auto-id="'VESportBrand'"
    :src="logoUrl"
    :alt="alt"
    :class="{
      [$style['brand']]: true,
    }"
    @click="onClick"
  >
</template>

<style lang="scss" module>
.brand {
  width: 24px;
  height: 24px;

  @include screen-desktop-small-min {
    width: 32px;
    height: 32px;
  }

  @include screen-desktop-large-min {
    width: 40px;
    height: 40px;
  }
}
</style>
