import { toRef, watch } from 'vue';

import useRootStore from 'web/src/modules/core/store/useRootStore';
import { useSyncState } from 'web/src/modules/core/submodules/sync-state';
import type {
  SyncStateCallback,
  SyncStateTimerName,
  SyncStateOptions,
  SyncStateComposable,
} from 'web/src/modules/core/submodules/sync-state/types';

type SyncSportlineOptions = Omit<SyncStateOptions, 'disableLoginLogoutHandlers'>;

/**
 * Sync state based on timer and ctag instead of is login state
 * @see LEONWEB-8926, LEONWEB-14562
 */
export function useSyncSportline(
  callback: SyncStateCallback,
  timerName: SyncStateTimerName,
  options: SyncSportlineOptions = {},
): SyncStateComposable {
  const rootStore = useRootStore();

  const cTag = toRef(() => rootStore.cTag);

  const syncState = useSyncState(callback, timerName, {
    ...options,
    disableLoginLogoutHandlers: true,
  });
  const { isSyncAvailable } = syncState;

  watch(cTag, () => {
    if (!isSyncAvailable.value) { return; }
    void callback();
  });

  return syncState;
}

/**
 * Should be used for graphql requests (in this case we can just sync state as usual)
 */
export function useSyncSportlineWithoutCTag(
  callback: SyncStateCallback,
  timerName: SyncStateTimerName,
  options: SyncStateOptions = {},
): SyncStateComposable {
  return useSyncState(callback, timerName, options);
}
