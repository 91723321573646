import type { SportlineApiService } from 'web/src/modules/sportline/types/services';

import createSportlineApiService from './createSportlineApiService';

let service: SportlineApiService | undefined;
export function useSportlineApiService(): SportlineApiService {
  if (!service) {
    service = createSportlineApiService();
  }

  return service;
}
