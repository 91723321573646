import type { Ref } from 'vue';
import { computed } from 'vue';

import type {
  BetlineLeaguePlayoffRoundBlock,
  BetlineLeaguePlayoffParticipant,
} from 'web/src/modules/sportline/types/rest';
import { splitScoreValue } from 'web/src/modules/sportline/submodules/core-statistic/utils';
import DateTime, { TimeFormats } from 'web/src/utils/DateTime';

interface MatchHistoryItem {
  date: string;
  names: string[];
  scores: string[];
}

interface UseLeaguePlayoffMatchesBlockProps {
  block: Ref<BetlineLeaguePlayoffRoundBlock>;
}

interface UseLeaguePlayoffMatchesBlockComposable {
  matchScores: Ref<Dictionary<string>[]>;
  matchesHistory: Ref<MatchHistoryItem[]>;
  participants: Ref<BetlineLeaguePlayoffParticipant[]>;
}

export function useLeaguePlayoffMatchesBlock(
  props: UseLeaguePlayoffMatchesBlockProps,
): UseLeaguePlayoffMatchesBlockComposable {
  const { block } = props;

  const matchScores = computed<Dictionary<string>[]>(
    () => block.value.matches
      .map((match) => {
        const score = splitScoreValue(match.resultScore);
        return match.teams.reduce((result, team, index) => {
          if (!team.id) { return result; }
          return {
            ...result,
            [team.id]: score[index] || '',
          };
        }, {} as Dictionary<string>);
      })
      .filter((scores) => Object.values(scores).some((score) => !!score)),
  );
  const participants = computed<BetlineLeaguePlayoffParticipant[]>(
    () => (block.value.participants || []).map((participant) => ({ ...participant })),
  );

  const matchesHistory = computed<MatchHistoryItem[]>(
    () => (block.value.matches || []).map((match) => ({
      date: match.matchDate ? DateTime.formatTimeStamp(match.matchDate, TimeFormats.dayMonthShortYear) : '',
      names: match.teams.map((team) => (team.name || '').slice(0, 3)),
      scores: splitScoreValue(match.resultScore),
    })),
  );

  return {
    matchScores,
    matchesHistory,
    participants,
  };
}
