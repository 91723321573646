import { assert, isNumber } from '@leon-hub/guards';

import type { MaskOptions } from '../../types';
import handleMaskedValueChange from './handleMaskedValueChange';
import type { ChangeInputValueResult } from '../types';

interface Payload {
  event: KeyboardEvent;
  currentValue: string;
  maskOptions: MaskOptions;
}

export default function handleMaskedKeydown({ event, currentValue, maskOptions }: Payload): ChangeInputValueResult {
  const { target, key } = event;
  const isNumericKey = /^\d$/i.test(key); // assume that masked input value is number

  assert(target instanceof HTMLInputElement);
  let beforeSelectionStart = target.selectionStart;
  const beforeSelectionEnd = target.selectionEnd;

  assert(isNumber(beforeSelectionStart));
  assert(isNumber(beforeSelectionEnd));

  const isSelected = beforeSelectionStart !== beforeSelectionEnd;
  const isRemoving = (event.key === 'Delete' || event.key === 'Backspace');

  const isRemovingSelected = isRemoving && isSelected;
  const isReplacingSelected = !isRemoving && isSelected && isNumericKey;

  const data = isReplacingSelected ? event.key : null;

  if (isRemoving && !isSelected) {
    beforeSelectionStart = event.key === 'Backspace' ? beforeSelectionStart - 1 : beforeSelectionStart;
  }

  if (isRemovingSelected || isReplacingSelected || isRemoving) {
    event.preventDefault();
    return handleMaskedValueChange({
      target,
      selectionStart: beforeSelectionStart,
      selectionEnd: beforeSelectionEnd,
      data,
      currentValue,
      maskOptions,
    });
  }
  return null;
}
