import type { DisplayedTimeLeft, ValueOfTimeLeft } from 'web/src/utils/countdown';
import type { I18nFunctionReactive } from 'web/src/modules/i18n/types';

export default function getHours(
  timeLeft: ValueOfTimeLeft | null,
  displayedTimeLeft: DisplayedTimeLeft | null,
  $t: I18nFunctionReactive,
  includeDaysLeft?: boolean,
): string {
  if (!timeLeft || !displayedTimeLeft) {
    return '';
  }

  const { days, hours } = timeLeft;

  if (days <= 0 && hours <= 0) {
    return '';
  }

  if (includeDaysLeft) {
    const hoursAndDays = (days * 24) + hours;

    return `${String(hoursAndDays)} ${$t('WEB2_HOURS_EXTRA_SHORT').value}.`;
  }

  return `${String(hours)} ${$t('WEB2_HOURS_EXTRA_SHORT').value}.`;
}
