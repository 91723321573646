/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
import type { Ref } from 'vue';
import { computed, ref } from 'vue';

import type { MarketsGridFilterId, MarketsGridFilter } from 'web/src/modules/sportline/submodules/markets-grid/types';
import type { DetailsLayoutType } from 'web/src/modules/sportline/views/markets-list/enums';
import { MarketsGridFilterCustomId } from 'web/src/modules/sportline/submodules/markets-grid/enums';

export interface MarketsListTabsControlProps {
  hasStatistic: Ref<boolean>;
  gridFilters: Ref<MarketsGridFilter[]>;
  allowedToSelectLayoutTypes: Ref<DetailsLayoutType[]>;
}

export interface MarketsListTabsControlComposable {
  isStatisticSelected: Ref<boolean>;
  isAllTabSelected: Ref<boolean>;
  isSelectedTabExistInMarketGroups: Ref<boolean>;
  activeGridFilter: Ref<Maybe<MarketsGridFilter>>;
  doShowInlineStatisticSwitch: Ref<boolean>;
  displayedMarketGroupTabId: Ref<MarketsGridFilterId>;
  recalculateActiveTabId(): void;
  resetSelectedTabId(): void;
  setSelectedTabId(value: MarketsGridFilterId): void;
}

export function useMarketsListTabsControl(
  props: MarketsListTabsControlProps,
): MarketsListTabsControlComposable {
  const {
    hasStatistic,
    gridFilters,
    allowedToSelectLayoutTypes,
  } = props;

  const isStatisticSelected = ref<boolean>(false);
  const internalMarketGroupTabId = ref<MarketsGridFilterId>(MarketsGridFilterCustomId.All);

  const activeGridFilter = computed<Maybe<MarketsGridFilter>>(() => (
    gridFilters.value.find((group) => group.id === internalMarketGroupTabId.value) ?? null
  ));

  const doShowInlineStatisticSwitch = computed<boolean>(() => allowedToSelectLayoutTypes.value.length > 1);
  const isAllTabSelected = computed<boolean>(() => internalMarketGroupTabId.value === MarketsGridFilterCustomId.All);
  // displayed market group
  const displayedMarketGroupTabId = computed<MarketsGridFilterId>(() => {
    if (doShowInlineStatisticSwitch.value) {
      // show real active tab for exist separate switch
      return internalMarketGroupTabId.value;
    }

    // fake selected tab without real switch
    return (isStatisticSelected.value && hasStatistic.value)
      ? MarketsGridFilterCustomId.Statistic
      : internalMarketGroupTabId.value;
  });
  const isSelectedTabExistInMarketGroups = computed<boolean>(() => {
    if (internalMarketGroupTabId.value === MarketsGridFilterCustomId.All) { return true; }
    if (internalMarketGroupTabId.value === MarketsGridFilterCustomId.Statistic) {
      // uniq case for empty markets groups
      // used for do not change isStatisticSelected after user turned off it
      return true;
    }
    return gridFilters.value.some((group) => group.id === internalMarketGroupTabId.value);
  });

  function recalculateActiveTabId(): void {
    if (internalMarketGroupTabId.value === MarketsGridFilterCustomId.All) {
      internalMarketGroupTabId.value = gridFilters.value.length > 0 && !process.env.VUE_APP_PRERENDER
        ? gridFilters.value[0].id
        : MarketsGridFilterCustomId.All;
    }
  }

  function resetSelectedTabId(): void {
    internalMarketGroupTabId.value = MarketsGridFilterCustomId.All;
  }

  function setSelectedTabId(value: MarketsGridFilterId): void {
    if (value === MarketsGridFilterCustomId.Statistic) {
      isStatisticSelected.value = true;
    } else {
      internalMarketGroupTabId.value = value;
      if (!doShowInlineStatisticSwitch.value) {
        isStatisticSelected.value = false;
      }
    }
  }

  return {
    isAllTabSelected,
    activeGridFilter,
    isStatisticSelected,
    isSelectedTabExistInMarketGroups,
    doShowInlineStatisticSwitch,
    displayedMarketGroupTabId,
    recalculateActiveTabId,
    resetSelectedTabId,
    setSelectedTabId,
  };
}
