import type { Ref } from 'vue';
import {
  computed, inject, onActivated, onBeforeUnmount, onMounted, ref,
} from 'vue';

import { VSwiperProvidableKeys } from 'web/src/components/Swiper/VSwiper/enums';
import type { VSwiperState } from 'web/src/components/Swiper/VSwiper/types';

export interface useVSwiperSlideComposable {
  slide: Ref<HTMLDivElement | undefined>;
  isOverflowVisible: Ref<boolean>;
  isVisible: Ref<boolean>;
  setVisibility: (value: boolean) => void;
  getOffsetWidth: () => number;
  getOffsetLeft: () => number;
}

export default function useVSwiperSlide(): useVSwiperSlideComposable {
  const isVisible = ref(true);
  const swiperState = inject<VSwiperState>(VSwiperProvidableKeys.State);
  const onSlideToggle = inject<() => void>(VSwiperProvidableKeys.OnSlideToggle);
  const slide = ref<HTMLDivElement>();

  const isOverflowVisible = computed<boolean>(() => (
    !!process.env.VUE_APP_LAYOUT_DESKTOP
    && isVisible.value
    && !!swiperState?.isSwiping.value
  ));

  onMounted(() => {
    onSlideToggle?.();
  });

  onActivated(() => {
    onSlideToggle?.();
  });

  onBeforeUnmount(() => {
    onSlideToggle?.();
  });

  function setVisibility(value: boolean): void {
    isVisible.value = value;
  }

  function getOffsetWidth(): number {
    return slide?.value?.offsetWidth || 0;
  }

  function getOffsetLeft(): number {
    return slide?.value?.offsetLeft || 0;
  }

  return {
    isVisible,
    slide,
    isOverflowVisible,
    setVisibility,
    getOffsetWidth,
    getOffsetLeft,
  };
}
