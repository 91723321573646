import { RouteName } from '@leon-hub/routing-config-names';

import { AppModule } from 'web/src/modules/core/plugins/AppModule';
import type { AppVueRouter } from 'web/src/modules/core/services/router/types';
import { ModalDesktopPreset } from 'web/src/modules/core/enums';
import { useThemeStore } from 'web/src/modules/theme/store';

export class ThemeModule extends AppModule {
  // eslint-disable-next-line class-methods-use-this
  addRoutes(router: AppVueRouter): void {
    if (useThemeStore().isThemeFooterSwitcherEnabled) {
      router.addModalRoute({
        name: RouteName.THEME_SELECT_MODAL,
        path: '/theme',
        title: '{{$t(\'WEB2_THEME_SELECTION\')}}',
        component: () => import('web/src/modules/theme/views/ThemeRoutePage.vue'),
        prefetch: () => import('./prefetch/ThemePagePrefetch'),
        modalPreset: process.env.VUE_APP_LAYOUT_DESKTOP ? ModalDesktopPreset.SmallDesktopModal : undefined,
      });
    }
  }
}
