import type { Ref } from 'vue';

import { Timer } from '@leon-hub/utils';
import { BusEvent, useEventsBus } from '@leon-hub/event-bus';

import HtmlUtils from 'web/src/utils/HtmlUtils';

interface ScrollElementIntoViewProps {
  container: Ref<Optional<HTMLElement>>;
}

interface ScrollElementIntoViewComposable {
  startScrollIntoView(): void;
  scrollIntoViewAndStop(): void;
  stopScrollIntoView(): void;
}

export function useScrollElementIntoView(
  props: ScrollElementIntoViewProps,
): ScrollElementIntoViewComposable {
  const bus = useEventsBus();

  let checkViewPortInterval: Maybe<number> = null;

  function clearCheckViewPortInterval(): void {
    if (!checkViewPortInterval) { return; }
    Timer.clearInterval(checkViewPortInterval);
    checkViewPortInterval = null;
  }

  function scrollContainerIntoView(): void {
    const containerElement = props.container?.value;

    // @TODO remake isViewPort to vue-observe-visibility
    if (!!containerElement && !HtmlUtils.inViewPort(containerElement)) {
      bus.emit(BusEvent.LAYOUT_CONTENT_SET_SCROLL, {
        scrollTop: containerElement.offsetTop,
        smooth: false,
      });
    }
  }

  function startScrollIntoView(): void {
    if (checkViewPortInterval) { return; }
    checkViewPortInterval = Timer.setInterval(scrollContainerIntoView, 10);
  }

  function scrollIntoViewAndStop(): void {
    scrollContainerIntoView();
    clearCheckViewPortInterval();
  }

  function stopScrollIntoView(): void {
    clearCheckViewPortInterval();
  }

  return {
    startScrollIntoView,
    scrollIntoViewAndStop,
    stopScrollIntoView,
  };
}
