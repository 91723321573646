import type { Ref, MaybeRef } from 'vue';
import { toRef, ref, computed } from 'vue';

import type { SportId } from 'web/src/modules/sportline/types';
import type { SportlineFragmentBasisKey } from 'web/src/modules/sportline/types/list';

export function createSportlineFragmentBasisKeyValue({ sportId, salt }: {
  sportId?: Maybe<SportId>;
  salt?: Maybe<string>;
}): SportlineFragmentBasisKey {
  return [sportId, salt].filter((value) => !!value).join('_') ?? '';
}

export function createSportlineFragmentBasisKey(props: {
  sportId?: MaybeRef<Maybe<SportId>>;
  salt?: MaybeRef<Maybe<string>>;
}): Ref<SportlineFragmentBasisKey> {
  const sportId: Ref<Maybe<SportId>> = props.sportId ? toRef(props.sportId) : ref(null);
  const salt: Ref<Maybe<string>> = props.salt ? toRef(props.salt) : ref(null);

  return computed(() => createSportlineFragmentBasisKeyValue({ salt: salt.value, sportId: sportId.value }));
}
