<script lang="ts" setup>
import { VForm } from 'web/src/components/Form';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { VLoaderDelayed } from 'web/src/components/Loader';

import completionRegistrationWidgets from '../../constants/completionRegistrationWidgets';
import type { RegistrationCompletionRoutePageProps, RegistrationCompletionRoutePageEmits } from './types';
import { useRegistrationCompletionRouteComponent } from './composables';

const componentProps = defineProps<RegistrationCompletionRoutePageProps>();
const emit = defineEmits<RegistrationCompletionRoutePageEmits>();

const {
  schema,
  uiSchema,
  form,
  descriptionText,
  isAsoTerms,
  isExAllowedCountry,
  isLoading,
  isPending,
  customErrors,
  onFormMounted,
  validateForm,
  goHome,
  onSubmit,
  onChange,
} = useRegistrationCompletionRouteComponent(componentProps, emit);
</script>

<template>
  <div v-auto-id="'RegistrationCompletionRoutePage'"
    :class="{
      [$style['registration-completion']]: true,
      [$style['registration-completion-padding']]: !manualCustomerAction && !isAsoTerms,
      [$style['registration-completion-aso']]: isAsoTerms,
    }"
  >
    <VLoaderDelayed
      v-if="isLoading || !schema || !uiSchema"
    />
    <template v-else>
      <div
        v-if="!isAsoTerms"
        :class="$style['registration-completion__description']"
      >
        {{ descriptionText }}
      </div>
      <VButton
        v-if="isExAllowedCountry"
        :label="$t('WEB2_REGISTRATION_RESTRICTED_COUNTRY_CONTINUE_BUTTON')"
        :kind="ButtonKind.PRIMARY"
        :class="$style['registration-completion__go-home']"
        @click="goHome"
      />
      <VForm
        v-else
        ref="form"
        :schema="schema"
        :ui-schema="uiSchema"
        :custom-errors="customErrors"
        :is-pending="isPending"
        @submit="onSubmit"
        @change="onChange"
        @vue:mounted="onFormMounted"
        @filled="validateForm"
      >
        <template
          v-for="(component, slotName) in completionRegistrationWidgets"
          :key="slotName"
          #[slotName]="{ props, events, ref }"
        >
          <component
            :is="component"
            :ref="ref"
            v-bind="props"
            v-on="events"
          />
        </template>
      </VForm>
    </template>
  </div>
</template>

<style lang="scss" module>
@include for-layout using($isDesktop) {
  .registration-completion {
    min-height: 100px;

    &-padding {
      padding: if($isDesktop, $mainContainerPaddingBigDesktop, $mainContainerPaddingBig);
      margin-top: 16px;
    }

    &-aso {
      padding: if($isDesktop, $mainContainerPaddingSmallDesktop, $mainContainerPaddingSmall);
    }

    &__go-home {
      width: 100%;
      margin-top: 20px;
    }

    &__hint-block {
      margin-top: 20px;
      text-align: left;
    }

    &__description {
      @include regular\14\24\025;

      margin-bottom: 16px;
      color: var(--TextPrimary);
      text-align: center;
    }
  }
}
</style>
