<script lang="ts" setup>
import { defaultCurrency } from 'web/src/modules/money/consts';

import { useCustomerHistoryListItem } from './composables';
import type {
  CustomerHistoryListItemEmits,
  CustomerHistoryListItemProps,
} from './types';

const props = withDefaults(defineProps<CustomerHistoryListItemProps>(), {
  currency: defaultCurrency,
});

const emits = defineEmits<CustomerHistoryListItemEmits>();

const {
  component,
  emitItemClick,
  emitCashOut,
  emitCancelWithdrawal,
} = useCustomerHistoryListItem(props, emits);
</script>

<template>
  <component v-auto-id="'CustomerHistoryListItem'"
    :is="component"
    v-data-test="{ el: 'transaction-history-item', type: item.objectType }"
    :currency="currency"
    :item="item"
    @item-click="emitItemClick"
    @cash-out="emitCashOut"
    @cancel-withdrawal="emitCancelWithdrawal"
  />
</template>
