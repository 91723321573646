import type { Ref } from 'vue';
import { computed } from 'vue';

import type {
  SportlineEvent,
  SportEventMatchProgress,
} from 'web/src/modules/sportline/types';
import { isLiveSportlineEvent } from 'web/src/modules/sportline/utils/sportlineEvent/sportlineTypeCheck';

interface UseLiveMatchProgressProps {
  sportEvent: Ref<Maybe<SportlineEvent> | undefined>;
}

interface UseLiveMatchProgressComposable {
  isLive: Ref<boolean>;
  matchProgress: Ref<Maybe<SportEventMatchProgress>>;
}

export function useLiveMatchProgress(
  props: UseLiveMatchProgressProps,
): UseLiveMatchProgressComposable {
  const { sportEvent } = props;
  const isLive = computed(() => isLiveSportlineEvent(sportEvent.value));
  const matchProgress = computed<Maybe<SportEventMatchProgress>>(() => (isLiveSportlineEvent(sportEvent.value)
    ? sportEvent.value.matchProgress
    : null));

  return {
    isLive,
    matchProgress,
  };
}
