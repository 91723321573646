import { once } from 'lodash';

import { Timer, voidify } from '@leon-hub/utils';
import { EditionValuePlatformCordova, EditionValueRenderingCsr } from '@leon-hub/environment-editions';
import { getLocationHref } from '@leon-hub/service-locator-env';
import { normalizeError } from '@leon-hub/errors';
import {

  isObject,
  isString,
} from '@leon-hub/guards';

import { useIcons } from '../composables';
import {
  SlottIconName,
  AlertIconName,
  VirtualSportIconName,
  SlottCasinoIconName,
  SocialIconName,
  SlottSportIconName,
  CurrenciesIconName,
  CasinoIconName,
  LegacyIconName,
  SystemIconName,
  SportIconName,
  EsportIconName,
  SlottSocialIconName,
  CurrenciesSlottIconName,
} from '../enums';
import { getAlertIcon } from './getAlertIcon';

type CallbackLoader = (icon: string) => Promise<unknown>;

function isDataUrl(value: string) {
  return value.startsWith('data:');
}

function isAbsoluteUrl(value: string) {
  return /^(https?:)?\/\/\w/.test(value);
}

function normalizeImagePath(appUrl: string, imageRawSource: string): string {
  if ((process.env.VUE_APP_RENDERING !== EditionValueRenderingCsr && !process.env.VUE_APP_STORYBOOK)
    || process.env.VUE_APP_PLATFORM === EditionValuePlatformCordova) {
    return imageRawSource;
  }
  return isAbsoluteUrl(imageRawSource) || isDataUrl(imageRawSource)
    ? imageRawSource
    : `${appUrl === '' ? '' : new URL(appUrl).origin}/${imageRawSource.replace(/^\/+/, '')}`;
}

const importCallback = (icons: Map<string, string | undefined>, esModule: unknown, iconName: string) => {
  if (isObject(esModule) && isObject(esModule.default)) {
    const { url } = esModule.default;
    if (typeof url === 'string') {
      icons.set(iconName, url);
      return;
    }
  }

  if (isObject(esModule) && isString(esModule.default)) {
    icons.set(iconName, esModule.default);
    return;
  }

  if (isString(esModule)) {
    icons.set(iconName, esModule);
    return;
  }

  icons.set(iconName, `#icon-${iconName}`);
};

function createOnError(icon: string): (err: Error) => void {
  return function onIconLoadError(err: Error): void {
    const message = `Unable to load '${icon}' icon due to error: ${err.message}`;
    if (document.readyState === 'complete') {
      // eslint-disable-next-line no-console
      console.warn(message);
    } else {
      // eslint-disable-next-line no-console
      document.addEventListener('DOMContentLoaded', () => console.warn(message));
    }
  };
}

export const loadIcons = once(async (): Promise<void> => {
  const { icons } = useIcons();

  async function handleIcon(iconName: string, loader: CallbackLoader): Promise<void> {
    try {
      const value = await loader(iconName);
      importCallback(icons, value, iconName);
    } catch (err) {
      createOnError(iconName)(normalizeError(err));
    }
  }

  async function registerIcons(loader: (id: string) => Promise<string>, iconMap: Record<string, string>) {
    await Promise.all(Object.values(iconMap).map((id) => handleIcon(id, loader)));
  }

  function applyVirtualSportsIcons(promises: Promise<unknown>[]) {
    promises.push(
      registerIcons((iconName: string) => import(`../assets/icons/virtual-sport/${iconName}.svg`), VirtualSportIconName),
    );
  }

  function applyCasinoIcons(promises: Promise<unknown>[]): void {
    promises.push(
      registerIcons((iconName: string) => import(`../assets/icons/casino/${iconName}.svg`), CasinoIconName),
    );
  }

  function applySlottIcons(promises: Promise<unknown>[]): void {
    promises.push(
      registerIcons((iconName: string) => import(`../assets/icons/slott/system/${iconName}.svg`), SlottIconName),
    );

    promises.push(
      registerIcons((iconName: string) => import(`../assets/icons/slott/casino/${iconName}.svg`), SlottCasinoIconName),
    );

    promises.push(
      registerIcons((iconName: string) => import(`../assets/icons/slott/sport/${iconName}.svg`), SlottSportIconName),
    );

    promises.push(
      registerIcons((iconName: string) => import(`../assets/icons/slott/social/${iconName}.svg`), SlottSocialIconName),
    );

    promises.push(
      registerIcons((iconName: string) => import(`../assets/icons/slott/currency/${iconName}.svg`), CurrenciesSlottIconName),
    );
  }

  function loadConnectionErrorSlottIcons(): void {
    Timer.setTimeout(() => {
      requestIdleCallback(voidify(async () => {
        try {
          const iconPaths = await Promise.all([
            normalizeImagePath(getLocationHref(), getAlertIcon(AlertIconName.Wifi)),
          ]);
          await Promise.all(iconPaths.map((iconPath) => loadAsHiddenImg(iconPath)));
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error('Unable to preload icon', err);
        }
      }));
    }, 500);
  }

  function loadAsHiddenImg(src: string): void {
    const img = document.createElement('img');
    img.src = src;
    img.style.position = 'absolute';
    img.style.visibility = 'hidden';
    img.style.left = '-10000px';
    function clean() {
      if (img.parentNode) {
        img.remove();
      }
    }
    img.addEventListener('load', clean);
    img.addEventListener('remove', clean);
    document.body.appendChild(img);
  }

  const promises: Promise<void>[] = [
    registerIcons((iconName: string) => import(`../assets/icons/legacy/${iconName}.svg`), LegacyIconName),
    registerIcons((iconName: string) => import(`../assets/icons/system/${iconName}.svg`), SystemIconName),
    registerIcons((iconName: string) => import(`../assets/icons/sport/${iconName}.svg`), SportIconName),
    registerIcons((iconName: string) => import(`../assets/icons/esport/${iconName}.svg`), EsportIconName),
    registerIcons((iconName: string) => import(`../assets/icons/currency/${iconName}.svg`), CurrenciesIconName),
    registerIcons((iconName: string) => import(`../assets/icons/social/${iconName}.svg`), SocialIconName),
  ];

  if (process.env.VUE_APP_FEATURE_CASINO_ENABLED) {
    applyCasinoIcons(promises);
  }

  if (process.env.VUE_APP_PRODUCT_SLOTT) {
    applySlottIcons(promises);
  }

  if (process.env.VUE_APP_FEATURE_VIRTUAL_SPORT_ENABLED) {
    applyVirtualSportsIcons(promises);
  }

  if (process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED) {
    void loadConnectionErrorSlottIcons();
  }

  await Promise.allSettled(promises);
});
