import type { CustomerLimitsUpdatableAt } from '@leon-hub/api-sdk';
import { CustomerLimitsRange } from '@leon-hub/api-sdk';

import type { CustomerLimitsDataDocument } from 'web/src/modules/responsible-gambling/types';

export enum ResponsibleGamblingLimitsToRange {
  dailyLimit = CustomerLimitsRange.DAILY,
  monthlyLimit = CustomerLimitsRange.MONTHLY,
  weeklyLimit = CustomerLimitsRange.WEEKLY,
}

export enum ResponsibleGamblingLimitsType {
  DEPOSIT = 'deposit',
  BET = 'bet',
  SELF_EXCLUSION = 'self-exclusion',
}

export interface ResponsibleGamblingDataToSend {
  type: CustomerLimitsRange[keyof CustomerLimitsRange];
  limit: string | null;
}

export function ResponsibleGamblingLimitsMapper(
  limitName: keyof CustomerLimitsDataDocument,
): keyof CustomerLimitsUpdatableAt | null {
  let result = '';
  switch (limitName) {
    case 'dailyLimit': result = 'dailyUpdatableAt' as keyof CustomerLimitsUpdatableAt; break;
    case 'monthlyLimit': result = 'monthlyUpdatableAt' as keyof CustomerLimitsUpdatableAt; break;
    case 'weeklyLimit': result = 'weeklyUpdatableAt' as keyof CustomerLimitsUpdatableAt; break;
    default: break;
  }
  return result as keyof CustomerLimitsUpdatableAt || null;
}

export interface ResponsibleGamblingDepositLimitsData {
  limitPeriod: keyof CustomerLimitsDataDocument;
  $event: ResponsibleGamblingDataToSend;
}
