import type {
  NavigationGuardNext,
  RouteLocationNormalized,
} from 'vue-router';

import { RouteName } from '@leon-hub/routing-config-names';
import {
  assert,
  isOptionalString,
  isUndefined,
} from '@leon-hub/guards';

import { AbstractPrefetch } from 'web/src/modules/core/services/router/prefetch';
import type { AppVueRouter } from 'web/src/modules/core/services/router/types';
import { CustomerCompletionAction } from 'web/src/modules/registration-completion/enums';
import { useRegistrationCompletionStore } from 'web/src/modules/registration-completion/store';

export default class RegistrationCompletionPrefetch extends AbstractPrefetch {
  // eslint-disable-next-line class-methods-use-this
  async prefetch(
    router: AppVueRouter,
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ): Promise<void> {
    if (
      (to.params.customerAction === CustomerCompletionAction.Withdrawal
      || to.params.customerAction === CustomerCompletionAction.Deposit
      )
      && isUndefined(to.params.paymentSystemId)
    ) {
      next({
        name: RouteName.HOME,
      });
      return;
    }

    const { customerAction, paymentSystemId } = to.params;
    assert(isOptionalString(customerAction));
    assert(isOptionalString(paymentSystemId));

    await useRegistrationCompletionStore().fetchIncompleteRegistrationFields({
      customerAction,
      paymentSystemId,
    });

    next();
  }
}
