import { defineStore } from 'pinia';
import type { Ref } from 'vue';
import {
  computed,
  ref,
  watch,
} from 'vue';

import type { ILobbyType } from '@leon-hub/api-sdk';

import {
  useEgsApi,
} from 'web/src/modules/egs/composables';
import { useLobbyType } from 'web/src/modules/lobby/composables';
import type { EgsCategory } from 'web/src/modules/egs/types';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';

const useEgsCategoriesStore = defineStore('egs-categories', () => {
  const { lobbyType: currentLobbyType } = useLobbyType();
  const { getCategories } = useEgsApi();
  const { isLoggedIn } = useIsLoggedIn();

  const categoriesByType: Record<string, Ref<EgsCategory[]>> = {};
  const categoriesByGroupCache: Record<string, Ref<EgsCategory[]>> = {};
  const isLoading = ref(false);

  function createKey(type: ILobbyType, groupId?: Maybe<string>): string {
    return `${type}-${groupId || ''}`;
  }

  function checkCategoriesExists(type: ILobbyType, groupId?: string): boolean {
    const key = createKey(type, groupId);

    if (!categoriesByType[key]) {
      categoriesByType[key] = ref([]);
      return false;
    }

    return true;
  }

  function getCategoriesByGroupIdAndType(lobbyType: ILobbyType, groupId?: string): Ref<EgsCategory[]> {
    const innerType = lobbyType || currentLobbyType.value;

    checkCategoriesExists(innerType, groupId);

    const key = createKey(innerType, groupId);

    if (!categoriesByGroupCache[key]) {
      categoriesByGroupCache[key] = computed(() => categoriesByType[key].value);
    }

    return categoriesByGroupCache[key];
  }

  function getCurrentGroupsCategories(groupId?: string): Ref<EgsCategory[]> {
    return computed(() => getCategoriesByGroupIdAndType(currentLobbyType.value, groupId).value);
  }

  function setCategories(type: ILobbyType, groupId: string, categories: EgsCategory[]): void {
    checkCategoriesExists(type, groupId);
    const key = createKey(type, groupId);

    categoriesByType[key].value = categories;
  }

  async function loadCategories(groupId?: string, lobbyType?: ILobbyType, silent = false): Promise<void> {
    const innerGroupId = groupId || '';

    const type = lobbyType ?? currentLobbyType.value;

    if (!silent) {
      isLoading.value = true;
    }

    try {
      const categories = await getCategories({
        type,
        group: groupId,
      }, silent);

      setCategories(type, innerGroupId, categories);
    } finally {
      isLoading.value = false;
    }
  }

  watch(isLoggedIn, () => {
    for (const category of Object.values(categoriesByGroupCache)) {
      category.value = [];
    }

    for (const category of Object.values(categoriesByType)) {
      category.value = [];
    }
  });

  return {
    isLoading,
    loadCategories,
    getCurrentGroupsCategories,
    getCategoriesByGroupIdAndType,
  };
});

export default useEgsCategoriesStore;
