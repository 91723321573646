import { getBetlineLeagueTopPlayers as getBetlineLeagueTopPlayersByLeagueId } from '@leon-hub/api-sdk';
import { RequestGroupScore24 } from '@leon-hub/api';

import type { BetlineLeagueTopPlayersResponse } from 'web/src/modules/sportline/types/rest';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';

export function getBetlineLeagueTopPlayers(
  options: { leagueId: number },
  { silent }: { silent?: boolean },
): Promise<BetlineLeagueTopPlayersResponse> {
  const apiClient = useGraphqlClient();
  return getBetlineLeagueTopPlayersByLeagueId(apiClient,
    (node) => node.queries.betLine.getLeagueTopPlayersByLeagueId,
    { options },
    {
      silent,
      group: RequestGroupScore24,
    });
}
