import type { VFeedbackCompositionProps } from 'web/src/components/FeedbackComposition/VFeedbackComposition/types';

export default function getIsSubmitButtonDisabled(
  props: VFeedbackCompositionProps,
  emailValue: string,
  comment: string,
  isEmailFieldError: boolean,
): boolean {
  if (process.env.VUE_APP_FEATURE_FEEDBACK_EMAIL_REQUEST_ENABLED) {
    if (props.savedErrorDuplicateEmail && props.savedErrorDuplicateEmail === emailValue) {
      return true;
    }
    if (comment.length && props.haveValidEmailValue) {
      return false;
    }
    return !(comment.length && !props.haveValidEmailValue && emailValue && !isEmailFieldError);
  }

  return !comment.length;
}
