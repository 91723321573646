<script lang="ts" setup>
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import VBadge from 'web/src/components/Badge/VBadge/VBadge.vue';
import { useVInformer } from 'web/src/components/Informer/VInformer/composables';

interface VInformerProps {
  label?: string;
  value?: number;
  number?: number;
  currency?: string;
  showCancelButton?: boolean;
  wrap?:boolean;
}

interface VInformerEmits {
  (e: 'click'): void;
}

const props = withDefaults(defineProps<VInformerProps>(), {
  label: '',
  value: 0,
  number: 0,
});

const emits = defineEmits<VInformerEmits>();

const {
  cancelButtonProperties,
  formattedValue,
  badgeKind,
} = useVInformer(props);

function emitClick(): void {
  emits('click');
}
</script>

<template>
  <div v-auto-id="'VInformer'"
    :class="$style['informer']"
    @click="emitClick"
  >
    <div :class="$style['informer__label']">
      <slot name="label">
        <span>{{ label }}</span>
      </slot>
    </div>
    <div
      :class="{
        [$style['informer__value']]: true,
        [$style['informer__value--wrap']]: wrap
      }"
    >
      <slot
        name="value"
        :formatted-value="formattedValue"
      >
        <span :class="$style['informer__money']">
          {{ formattedValue }}
        </span>
        <VBadge
          v-if="number > 0"
          :label="`${number}`"
          :kind="badgeKind"
          :class="$style['informer__counter-badge']"
        />
        <VButton
          v-if="showCancelButton"
          v-bind="cancelButtonProperties"
        />
      </slot>
    </div>
  </div>
</template>

<style lang="scss" module>
.informer {
  $self: &;

  &__label {
    @include regular\12\16;

    display: flex;
    color: var(--TextSecondary);
    white-space: nowrap;
  }

  &__value {
    @include medium\16\20\025;

    display: flex;
    align-items: center;
    color: var(--TextDefault);

    svg:first-of-type {
      margin-left: 4px;
    }

    &--wrap {
      flex-wrap: wrap;
    }
  }

  &__money {
    margin-right: 8px;
  }

  &__counter-badge {
    margin: 0 0 0 -4px;
  }

  &__cancel-button {
    @include medium\11\16\-02\caps;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    padding: 0 6px;
    color: var(--BrandDefault);
    background-color: var(--OpacityBrandDefault);
    border-radius: 4px;
  }

  &:hover {
    #{$self}__value,
    #{$self}__label {
      cursor: pointer;
    }

    #{$self}__label {
      color: var(--TextDefault);
    }
  }
}
</style>
