import {
  bindIsArrayOf,
  isValidInterface,
} from '@leon-hub/guards';
import type { FieldGuards } from '@leon-hub/types';

import type {
  GetSportsSportResponse,
} from 'web/src/modules/sportline/types/rest';

import { isCoreSportResponseFieldGuards } from './isCoreSportResponse';
import { isGetSportsRegionResponse } from './isGetSportsRegionResponse';

export const isGetSportsSportResponseFieldGuards: FieldGuards<GetSportsSportResponse> = {
  ...isCoreSportResponseFieldGuards,
  regions: bindIsArrayOf(isGetSportsRegionResponse),
};

export function isGetSportsSportResponse(value: unknown): value is GetSportsSportResponse {
  return isValidInterface<GetSportsSportResponse>(value, isGetSportsSportResponseFieldGuards);
}
