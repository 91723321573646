import { defineStore } from 'pinia';
import {
  computed,
  toRef,
} from 'vue';

import type { MetricsToolConfigParsed } from '@leon-hub/yandex-metrika';
import { MetricsToolType, ZetaDcpConfigType } from '@leon-hub/api-sdk';

import { useSiteConfigStore } from 'web/src/modules/core/store';

import {
  findMetricsToolsByType,
  findZetaDspConfigParamsByType,
  parseMetricsToolConfig,
} from './utils';

const useMetricsStore = defineStore('metrics', () => {
  const siteConfigStore = useSiteConfigStore();
  const metricsToolsData = toRef(siteConfigStore, 'metricsToolsBlock');
  const zetaDSPisEnabled = toRef(siteConfigStore, 'zetaDSPisEnabled');
  const zetaDSPMainType = toRef(siteConfigStore, 'zetaDSPMainType');
  const zetaDSPConfig = toRef(siteConfigStore, 'zetaDSPConfig');
  const sportradarCounterId = toRef(siteConfigStore, 'sportradarCounterId');
  const sportradar = toRef(siteConfigStore, 'sportradar');

  const yandexMetrikaConfig = computed<MetricsToolConfigParsed | undefined>(() => {
    const config = findMetricsToolsByType(MetricsToolType.YANDEX, metricsToolsData.value?.metricsToolsData);

    return config
      ? {
        id: Number(config.counterId),
        ...parseMetricsToolConfig(config.config),
      }
      : undefined;
  });

  const hasYandexMetrikaConfig = computed<boolean>(() => !!yandexMetrikaConfig.value);

  const googleCounterId = computed(() => {
    const config = findMetricsToolsByType(MetricsToolType.GOOGLE_ANALYTICS, metricsToolsData.value?.metricsToolsData);

    return config?.counterId;
  });

  const zetaDspAll = computed(() => findZetaDspConfigParamsByType(ZetaDcpConfigType.ALL, zetaDSPConfig.value));
  const zetaDspDeposit = computed(() => findZetaDspConfigParamsByType(ZetaDcpConfigType.DEPOSIT, zetaDSPConfig.value));
  // eslint-disable-next-line max-len
  const zetaDspFTD = computed(() => findZetaDspConfigParamsByType(ZetaDcpConfigType.FIRST_TIME_DEPOSIT, zetaDSPConfig.value));
  // eslint-disable-next-line max-len
  const zetaDspRegistration = computed(() => findZetaDspConfigParamsByType(ZetaDcpConfigType.REGISTRATION, zetaDSPConfig.value));
  const zetaDspLanding = computed(() => findZetaDspConfigParamsByType(ZetaDcpConfigType.LANDING, zetaDSPConfig.value));

  const sportradarPixelManager = computed(() => sportradar.value?.pixelManager);

  return {
    zetaDSPisEnabled,
    zetaDSPMainType,
    yandexMetrikaConfig,
    hasYandexMetrikaConfig,
    sportradarCounterId,
    googleCounterId,
    zetaDspAll,
    zetaDspDeposit,
    zetaDspRegistration,
    zetaDspFTD,
    zetaDspLanding,
    sportradarPixelManager,
  };
});

export default useMetricsStore;
