import type {
  SportEventDetails,
} from 'web/src/modules/sportline/submodules/event-details/types';
import type {
  SportElement,
  RegionElement,
  LeagueElement,
  SportlineEventElement, OutrightSportlineEvent,
} from 'web/src/modules/sportline/types';
import type {
  BuildSportOptions,
  ParseLiveStatusOptions,
  CoreSportEventResponse,
  BuildSportEventOptions,
} from 'web/src/modules/sportline/types/rest';
import {
  reduceListWithSportEventsCounters,
  addCountersToCounters,
  getSportEventElementsListCounters,
} from 'web/src/modules/sportline/utils';
import {
  assertFullCoreLeagueResponse,
  assertFullCoreRegionResponse,
  assertFullCoreSportResponse,
} from 'web/src/modules/sportline/utils/rest/assert';
import {
  extractBuildSportOptions,
} from 'web/src/modules/sportline/utils/rest/pre-build';
import {
  parseRegionResponse,
  parseSportResponse,
  parseLeagueResponse,
  createSport,
  createSportEvent,
  createEventStatusBasedOnBetlineStatus,
} from 'web/src/modules/sportline/utils/rest/build';

export type DetailsAdapterOptions = BuildSportOptions & ParseLiveStatusOptions;

// @TODO share create elements with SportlineFactory
export function buildSportlineSportElement(
  response: CoreSportEventResponse,
  options: DetailsAdapterOptions = extractBuildSportOptions(),
): SportElement {
  const leagueResponse = assertFullCoreLeagueResponse(response.league);
  const regionResponse = assertFullCoreRegionResponse(leagueResponse.region);
  const sportResponse = assertFullCoreSportResponse(leagueResponse.sport);

  const buildOptions: BuildSportEventOptions & ParseLiveStatusOptions = {
    ...options,
    sportResponse,
    regionResponse,
    leagueResponse,
  };
  const rawSport = parseSportResponse(sportResponse, buildOptions);
  const rawRegion = parseRegionResponse({
    ...buildOptions,
    sportResponse,
    regionResponse,
  });
  const rawLeague = parseLeagueResponse({
    ...buildOptions,
    sportResponse,
    regionResponse,
    leagueResponse,
  });
  const sportlineEvent = createSportEvent(response, {
    ...buildOptions,
    sportResponse,
    regionResponse,
    leagueResponse,
    useFullProgress: rawSport.sport.representation.useFullProgress,
  });
  const marketTypes = sportlineEvent.markets.map(({ type }) => type);

  // @TODO check status logic for details page
  const sportlineEventElement: SportlineEventElement = {
    sportEvent: {
      ...sportlineEvent,
      status: createEventStatusBasedOnBetlineStatus(response),
    },
  };

  const sportEvents = sportlineEventElement.sportEvent.isOutright ? [] : [sportlineEventElement];
  const outrightEvents = sportlineEventElement.sportEvent.isOutright ? [
    sportlineEventElement as SportlineEventElement<OutrightSportlineEvent>,
  ] : [];

  const leagueElement: LeagueElement = {
    key: `1_${rawLeague.league.id}`,
    league: rawLeague.league,
    marketTypes,
    sportEvents,
    outrightEvents,
    counters: addCountersToCounters(
      getSportEventElementsListCounters(sportEvents),
      getSportEventElementsListCounters(outrightEvents),
    ),
  };

  const regionElement: RegionElement = {
    key: `1_${rawRegion.region.id}`,
    region: rawRegion.region,
    marketTypes,
    leagues: [leagueElement],
    counters: reduceListWithSportEventsCounters([leagueElement]),
  };

  return {
    key: rawSport.sport.id,
    sport: rawSport.sport,
    regions: [regionElement],
    marketTypes,
    marketsColumns: rawSport.marketsColumns,
    counters: reduceListWithSportEventsCounters([regionElement]),
  };
}

/**
 * @TODO Split file
 */
export class DetailsAdapter {
  constructor(
    private readonly response: CoreSportEventResponse,
    private readonly options: DetailsAdapterOptions = extractBuildSportOptions(),
  ) {}

  get sportEventDetails(): SportEventDetails {
    const { response, options } = this;

    const leagueResponse = assertFullCoreLeagueResponse(response.league);
    const regionResponse = assertFullCoreRegionResponse(leagueResponse.region);
    const sportResponse = assertFullCoreSportResponse(leagueResponse.sport);

    const buildOptions: BuildSportEventOptions & ParseLiveStatusOptions = {
      ...options,
      sportResponse,
      regionResponse,
      leagueResponse,
    };
    const sport = createSport({ ...options, sport: sportResponse });
    const { region } = parseRegionResponse(buildOptions);
    const { league } = parseLeagueResponse(buildOptions);
    const sportlineEvent = createSportEvent(response, {
      ...buildOptions,
      useFullProgress: sport.representation.useFullProgress,
    });

    return {
      ...sportlineEvent,
      status: createEventStatusBasedOnBetlineStatus(response),
      sport,
      league,
      region,
    };
  }
}
