import type { RouteLocationNormalized } from 'vue-router';

import type { Banner, BannerGroup } from 'web/src/modules/banners/store/types';
import { BannerLocation } from 'web/src/modules/banners/enums';

export function isGroupAllowedByRoute(
  displayRoutes: BannerGroup['displayRoutes'],
  route: RouteLocationNormalized,
): boolean {
  return displayRoutes.some((displayRoute) => (
    displayRoute.name === 'VIRTUAL_ROUTE_ALL_PAGES'
    || (
      displayRoute.name === route.name
      && displayRoute.parameters.every((parameter) => (
        parameter.availableValues.includes('*')
        || (
          !route.params?.[parameter.name]
          && parameter.availableValues.includes('')
        )
        || (
          route.params?.[parameter.name]
          && parameter.availableValues.includes(String(route.params[parameter.name]))
        )
      ))
    )
  ));
}

export function isGroupAllowed(
  group: Pick<BannerGroup, 'banners' | 'displayRoutes'> & { route: RouteLocationNormalized },
): boolean {
  return group.banners.length > 0 && isGroupAllowedByRoute(group.displayRoutes, group.route);
}

export function filterGroupsByLocation(
  groups: readonly BannerGroup[],
  { location, customLocationId }: { location: BannerLocation; customLocationId?: string },
): BannerGroup[] {
  return groups.map((group: BannerGroup): BannerGroup => ({
    ...group,
    banners: group.banners
      // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
      .filter((banner: Banner) => (banner.location === BannerLocation.CUSTOM
        ? (banner.customLocationId === customLocationId)
        // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
        : (banner.location === location))),
  }));
}

export function filterGroupsByRoute(
  groups: readonly BannerGroup[],
  route: RouteLocationNormalized,
): BannerGroup[] {
  return groups.filter((group) => isGroupAllowed({
    banners: group.banners,
    displayRoutes: group.displayRoutes,
    route,
  }));
}

export function getFilteredBannerGroups(
  bannerGroups: readonly BannerGroup[],
  { location, route, customLocationId }: {
    location: BannerLocation;
    route: RouteLocationNormalized;
    customLocationId?: string;
  },
): BannerGroup[] {
  const filteredByLocation = filterGroupsByLocation(bannerGroups, { location, customLocationId });
  const filteredByRoute = filterGroupsByRoute(filteredByLocation, route);
  return filteredByRoute.sort((a, b) => b.weight - a.weight);
}
