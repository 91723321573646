export function getNextSlideIndexOffset(activeIndex: number, slidesCounter: number): number {
  let indexOffset = activeIndex === slidesCounter - 3 ? 2 : 1;
  const clientWidth = window.innerWidth || document.documentElement.clientWidth;

  if (clientWidth < 1280) {
    indexOffset = activeIndex === slidesCounter - 2 ? 2 : 1;
  }

  if (clientWidth < 1024) {
    indexOffset = 1;
  }

  return indexOffset;
}

export function getPreviousSlideIndexOffset(activeIndex: number, slidesCounter: number): number {
  let indexOffset = activeIndex === slidesCounter - 1 ? 2 : 1;
  const clientWidth = window.innerWidth || document.documentElement.clientWidth;

  if (clientWidth < 1280) {
    indexOffset = activeIndex === 0 ? 2 : 1;
  }

  if (clientWidth < 1024) {
    indexOffset = 1;
  }

  return indexOffset;
}
