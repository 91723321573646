import { InputEventType } from 'web/src/components/Input/enums';
import { checkboxEventToDataEvent } from 'web/src/components/Form/utils';

import type { BaseFormWidgetEmits } from '../types';

interface UseFormCheckboxEvents {
  emitChange: (event: Event) => void;
  emitInput: (event: Event) => void;
  emitFocus: (event: Event) => void;
  emitBlur: (event: Event) => void;
}

const useFormCheckboxEvents = (emit: BaseFormWidgetEmits, name: string): UseFormCheckboxEvents => {
  const emitInput = (event: Event): void => {
    emit(InputEventType.INPUT, checkboxEventToDataEvent(event, name));
  };

  const emitChange = (event: Event): void => {
    emit(InputEventType.CHANGE, checkboxEventToDataEvent(event, name));
  };

  const emitFocus = (event: Event): void => {
    emit(InputEventType.FOCUS, checkboxEventToDataEvent(event, name));
  };

  const emitBlur = (event: Event): void => {
    emit(InputEventType.BLUR, checkboxEventToDataEvent(event, name));
  };

  return {
    emitChange,
    emitInput,
    emitFocus,
    emitBlur,
  };
};

export default useFormCheckboxEvents;
