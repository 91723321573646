import { defineStore } from 'pinia';
import {
  computed,
  ref,
  toRef,
  watch,
} from 'vue';

import type { PendingBet } from '@leon-hub/api-sdk';
import { Timer } from '@leon-hub/utils';
import { assert, isNumber } from '@leon-hub/guards';

import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useFormatMoney } from 'web/src/modules/money/composables';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { BetCashoutMode } from 'web/src/modules/cashout/enums';
import { useOddsSettings } from 'web/src/modules/sportline/composables/settings';

import { TopLevelTabs } from '../../../enums';
import type { SlipListHistoryItemData } from '../../../components/SlipListItem/types';
import isPendingBetsArray from '../../../guards/isPendingBetsArray';
import { getPendingBetsFromApi, mapPendingBetToSlipListHistoryItem } from '../utils';
import type { RequestPendingBetsPayload, UpdateOnCashoutOptions, PendingBetsDocument } from '../types';
import useSlipViewSettingsStore from '../../slip-view-settings/store/useSlipViewSettingsStore';

const usePendingBetsStore = defineStore('pending-bets', () => {
  const siteConfigStore = useSiteConfigStore();

  const slipViewSettingsStore = useSlipViewSettingsStore();

  const activeTopTabId = toRef(slipViewSettingsStore, 'activeTopTabId');

  const formatMoney = useFormatMoney();

  const { $translate } = useI18n();

  const { isLoggedIn } = useIsLoggedIn();

  const apiClient = useGraphqlClient();

  const { currentOddsType } = useOddsSettings();

  const slipBlock = toRef(siteConfigStore, 'slipBlock');

  const sportLineBlock = toRef(siteConfigStore, 'sportLineBlock');

  const pendingBetsUpdateInterval = computed<number>(
    () => sportLineBlock.value?.pendingBetsUpdateInterval || 10 * 60 * 1000,
  );

  const myBetsPaginationCount = computed<number>(() => slipBlock.value?.pendingBetsPaginationCount || 15);

  const pendingBets = ref<PendingBet[]>([]);

  const pendingBetsCount = ref<number>(0);

  const isLoaded = ref<boolean>(false);

  const isLoadingMore = ref<boolean>(false);

  let pendingBetsUpdateIntervalId = 0;

  const setPendingBets = (value: PendingBet[]): void => {
    pendingBets.value = value;
  };

  const setPendingBetsCount = (count: number): void => {
    pendingBetsCount.value = count;
  };

  const setIsLoaded = (isNowLoaded: boolean): void => {
    isLoaded.value = isNowLoaded;
  };

  const setIsLoadingMore = (isLoading: boolean): void => {
    isLoadingMore.value = isLoading;
  };

  const isMyBetsViewActive = computed<boolean>(
    () => activeTopTabId.value === TopLevelTabs.MY_BETS,
  );

  const myBets = computed<SlipListHistoryItemData[]>(() => {
    if (!pendingBets.value?.length) return [];
    const expressTitle = $translate('JAVA_ACCHITEM_COMBI').value;
    const systemTitle = $translate('JAVA_ACCHITEM_SYSTEM').value;

    return pendingBets.value.map((pendingBet) => mapPendingBetToSlipListHistoryItem({
      pendingBet,
      systemTitle,
      expressTitle,
      formatMoney,
    }));
  });

  const requestPendingBets = (payload?: RequestPendingBetsPayload): Promise<PendingBetsDocument> => {
    const options = {
      count: payload?.count || myBetsPaginationCount.value,
      from: payload?.from || 0,
      splitEventName: true,
    };
    return getPendingBetsFromApi(apiClient, options, payload?.silent);
  };

  const loadPendingBets = async (isBackgroundUpdate = false): Promise<void> => {
    if (isLoggedIn.value) {
      let options: RequestPendingBetsPayload = {
        silent: isBackgroundUpdate,
      };
      if (!isBackgroundUpdate) {
        Timer.clearInterval(pendingBetsUpdateIntervalId);
      }
      if (isBackgroundUpdate && isMyBetsViewActive.value) {
        options = { ...options, count: pendingBets.value.length };
      }
      const response = await requestPendingBets(options);
      setIsLoaded(true);
      if (!response) {
        return;
      }
      const { bets, totalCount } = response;
      if (bets) {
        assert(isPendingBetsArray(bets), 'unexpected pending bets');
        setPendingBets(bets);
      }
      if (isNumber(totalCount)) {
        setPendingBetsCount(totalCount);
      }
      if (!isBackgroundUpdate && totalCount) {
        // call itself with timing
        pendingBetsUpdateIntervalId = Timer.setInterval(() => {
          void loadPendingBets(true);
        }, pendingBetsUpdateInterval.value);
      }
    }
  };

  const clearPendingBets = (): void => {
    setPendingBets([]);
    setPendingBetsCount(0);
    setIsLoaded(false);
    setIsLoadingMore(false);
  };

  const loadMorePendingBets = async (): Promise<void> => {
    const loadedCount = pendingBets.value.length;
    if (loadedCount >= pendingBetsCount.value) {
      return;
    }
    setIsLoadingMore(true);
    const response = await requestPendingBets({ from: loadedCount });
    setIsLoadingMore(false);
    if (!response) return;
    const { bets, totalCount } = response;
    if (bets) {
      assert(isPendingBetsArray(bets));
      setPendingBets([...pendingBets.value, ...bets]);
    }
    if (isNumber(totalCount)) {
      setPendingBetsCount(totalCount);
    }
  };

  const updatePendingBetsOnCashout = (options: UpdateOnCashoutOptions): void => {
    if (!options?.betId || !pendingBets.value.length) return;
    const { result, betId } = options;
    let updatedList: PendingBet[];
    if (result === BetCashoutMode.SUCCESS) {
      updatedList = pendingBets.value.filter((item) => item.betId !== betId);
      setPendingBetsCount(pendingBetsCount.value - 1);
    } else {
      updatedList = [...pendingBets.value];
      const matchedIndex = updatedList.findIndex((item) => item.betId === betId);
      if (matchedIndex > -1) {
        updatedList[matchedIndex] = {
          ...updatedList[matchedIndex],
          isEnabledForCashout: false,
        };
      }
    }
    setPendingBets(updatedList);
  };

  /** former init */
  void loadPendingBets();

  watch(isLoggedIn, (newValue) => {
    if (newValue) {
      void loadPendingBets();
    } else {
      clearPendingBets();
    }
  });

  watch(currentOddsType, () => {
    // reload pending bets with current oddsType
    if (isLoaded.value && pendingBetsCount.value) {
      clearPendingBets();
      void loadPendingBets();
    }
  });

  return {
    myBets,
    myBetsPaginationCount,
    isLoaded,
    pendingBetsCount,
    isLoadingMore,
    setIsLoaded,
    loadPendingBets,
    clearPendingBets,
    loadMorePendingBets,
    updatePendingBetsOnCashout,
  };
});

export default usePendingBetsStore;
