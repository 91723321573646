<script lang="ts" setup>
import { Tag } from '@leon-hub/tags';
import { IconSize } from '@leon-hub/icons';

import BadgeKind from 'web/src/components/Badge/VBadge/enums/BadgeKind';
import ButtonKind from 'web/src/components/Button/VButton/enums/ButtonKind';
import { useModalWindowHeader } from 'web/src/components/Modal/ModalWindowHeader/composables';
import VBadge from 'web/src/components/Badge/VBadge/VBadge.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import VDynamicContent from 'web/src/components/DynamicContent/VDynamicContent/VDynamicContent.vue';
import ModalWindowHeaderPrefixAbstract
  from 'web/src/components/Modal/ModalWindowHeader/components/ModalWindowHeaderPrefixAbstract/ModalWindowHeaderPrefixAbstract.vue';
import SNavigationButton from 'web/src/components/Button/SNavigationButton/SNavigationButton.vue';
import SButton from 'web/src/components/Button/SButton/SButton.vue';

import type { ModalWindowHeaderEmits, ModalWindowHeaderProps } from './types';

const props = withDefaults(defineProps<ModalWindowHeaderProps>(), {
  title: '',
  titleTag: Tag.DIV,
  badgeKind: BadgeKind.DEFAULT,
  type: 'default',
  isCloseButtonOutside: false,
});

const emit = defineEmits<ModalWindowHeaderEmits>();

const { pageHeader, isSticky } = useModalWindowHeader();

function onMouseDown(event: MouseEvent): void {
  if (props.isDragAllowed) {
    emit('drag-start', event);
  }
}
</script>

<template>
  <div v-auto-id="'ModalWindowHeader'"
    ref="pageHeader"
    :class="{
      [$style['modal-header']]: true,
      [$style['modal-header--draggable']]: isDragAllowed,
      [$style['modal-header--no-title']]: !title && !$slots.title,
      [$style['modal-header--avatar']]: isAvatar,
      [$style['modal-header--empty']]: !title && !suffixIconName
        && !props.prefixIconName && !$slots.title && !$slots.suffix,
      [$style['modal-header--stickied']]: isSticky,
      [$style[`modal-header--type__${type}`]]: type,
    }"
  >
    <div :class="$style['modal-header__prefix']">
      <slot name="prefix">
        <ModalWindowHeaderPrefixAbstract
          :prefix-icon-name="prefixIconName"
          :class="$style['modal-header__prefix-abstract']"
          @prefix-click="emit('prefix-click')"
        />
      </slot>
    </div>
    <slot name="title">
      <div
        :class="$style['modal-header__content']"
        @mousedown="onMouseDown"
      >
        <VDynamicContent
          v-data-test="{ el: 'modal-header__title' }"
          :tag="titleTag"
          :content="title"
          :class="$style['modal-header__title']"
        />
        <VBadge
          v-if="badge"
          :label="badge"
          :kind="badgeKind"
          :is-counter="isBadgeCounter"
          :class="$style['modal-header__badge']"
        />
      </div>
    </slot>
    <div :class="$style['modal-header__suffix']">
      <slot name="suffix">
        <template v-if="null">
          <SNavigationButton
            v-if="suffixIconName"
            kind="primary"
            :class="{ [$style['modal-header__close--slott']]: isCloseButtonOutside }"
            @click="emit('suffix-click')"
          />
        </template>
        <template v-else-if="suffixIconName">
          <SButton
            v-if="null"
            v-data-test="{ el: 'modal-suffix-button' }"
            kind="quaternary-secondary"
            :icon-size="IconSize.SIZE_24"
            :icon-name="suffixIconName"
            @click="emit('suffix-click')"
          />
          <VButton
            v-else
            v-data-test="{ el: 'modal-suffix-button' }"
            :kind="ButtonKind.NAV"
            :icon-size="IconSize.SIZE_24"
            :icon-name="suffixIconName"
            @click="emit('suffix-click')"
          />
        </template>
      </slot>
    </div>
  </div>
</template>

<style module lang="scss">
.modal-header {
  $self: &;

  display: flex;
  width: 100%;
  min-height: calc($modalHeaderHeight + var(--StatusbarHeight));
  padding-top: var(--StatusbarHeight);

  &__title {
    @include modalHeaderTitleFont;

    margin: 0;
    text-align: center;
  }

  &__content {
    z-index: 1;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    padding: $modalHeaderPadding;
  }

  &__badge {
    margin-left: 8px;
  }

  &__prefix,
  &__suffix {
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: $modalHeaderPrefixJustifyContent;
    min-width: $modalHeaderPrefixMinWidth;
    text-align: center;
  }

  &__close {
    &--slott {
      @include is-app-layout-desktop {
        position: relative;
        bottom: 40px;
        left: 50px;
      }
    }
  }

  &__prefix-abstract {
    margin: auto;

    @include for-hover {
      &:hover {
        cursor: pointer;
      }
    }
  }

  &--draggable {
    @include is-app-layout-desktop {
      #{$self}__content {
        cursor: move;
        user-select: none;
      }
    }
  }

  &--no-title {
    min-height: calc($modalHeaderHeightNoTitle + var(--StatusbarHeight));
  }

  &--empty {
    min-height: var(--StatusbarHeight);

    #{$self}__prefix,
    #{$self}__suffix {
      margin-top: 0;
    }

    #{$self}__content {
      padding: 0;
    }
  }

  &--avatar {
    min-height: calc(68px + var(--StatusbarHeight));

    #{$self}__suffix {
      width: 52px;
      padding-right: 8px;
    }

    #{$self}__prefix {
      margin-right: 8px;
      margin-bottom: 8px;
    }
  }

  &--stickied {
    box-shadow: $modalHeaderBoxShadow;

    &.modal-header--no-title {
      box-shadow: none;
    }
  }

  &--type {
    @each $type, $typeProps in $modalHeaderTypes {
      &__#{$type} {
        color: get($typeProps, 'color');
        background-color: get($typeProps, 'backgroundColor');

        .modal-header__title {
          color: get($typeProps, 'color');
        }
      }
    }
  }
}
</style>
