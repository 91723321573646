<script lang="ts" setup>
import { computed, toRef } from 'vue';

import type { RecycleScrollerItemOptions } from 'web/src/components/RecycleScroller/types';
import type { ExtendedEventBlock } from 'web/src/modules/sportline/submodules/extended-event/enums';
import type { ExtendedEventsIds } from 'web/src/modules/sportline/submodules/extended-event/store/types';
import { VRecycleScrollerVertical } from 'web/src/components/RecycleScroller';
import type { OnResizeEventElementPayload } from 'web/src/modules/sportline/views/EventBlock/types';

import type { VirtualListElement } from './types';
import type { ExpandedEventsHeightMap } from './utils';
import {
  isEventElement,
  isLeagueHeadlineElement,
  isSportHeadlineElement,
} from './guards';
import {
  getRecycleScrollerCssVars,
  createGetEventElementSize,
  createGetLeagueHeadlineElementSize,
  createGetSportHeadlineElementSize,
} from './utils';
import VirtualListRendererElement from './VirtualListRendererElement.vue';

interface VirtualListProps {
  elements: VirtualListElement[];
  expandedIds: ExtendedEventsIds;
  expandedHeights: ExpandedEventsHeightMap;
  extendedBlockType?: ExtendedEventBlock;
  isMarketTypesSelectionEnabled?: boolean;
  overscan?: number;
}

interface VirtualListEmits {
  (e: 'event-resize', payload: OnResizeEventElementPayload): void;
}

const props = defineProps<VirtualListProps>();
const emit = defineEmits<VirtualListEmits>();

const elements = toRef(() => props.elements);
const expandedIds = toRef(() => props.expandedIds);
const expandedHeights = toRef(() => props.expandedHeights);

const getEventElementSize = createGetEventElementSize({ expandedIds, expandedHeights });
const getLeagueHeadlineElementSize = createGetLeagueHeadlineElementSize();
const getSportHeadlineElementSize = createGetSportHeadlineElementSize();

const options = computed<RecycleScrollerItemOptions[]>(() => (
  elements.value.map((element) => {
    if (isEventElement(element)) { return getEventElementSize(element); }
    if (isLeagueHeadlineElement(element)) { return getLeagueHeadlineElementSize(element); }
    if (isSportHeadlineElement(element)) { return getSportHeadlineElementSize(element); }
    return { height: 0 };
  })
));
const cssVars = getRecycleScrollerCssVars();
</script>

<template>
  <VRecycleScrollerVertical v-auto-id="'VirtualList'"
    :options="options"
    disable-own-scroll
    :style="cssVars"
    :overscan="overscan"
  >
    <template #item="{ item }">
      <VirtualListRendererElement
        :element="elements[item.index]"
        :can-add-event-to-favorite="!!'1'"
        :extended-block-type="extendedBlockType"
        :is-market-types-selection-enabled="isMarketTypesSelectionEnabled"
        @event-resize="emit('event-resize', $event)"
      />
    </template>
  </VRecycleScrollerVertical>
</template>
