import type { Ref } from 'vue';
import { computed } from 'vue';

import { TransactionGroup } from '@leon-hub/api-sdk';

import { defaultDateTimeFormat } from 'web/src/modules/profile/submodules/customer-history/utils';
import { useFormatMoney } from 'web/src/modules/money/composables';

import type { CustomerHistoryListItemEgsProps } from '../types';

export interface CustomerHistoryListItemEgsComposable {
  id: Ref<string>;
  date: Ref<string>;
  typeKind: Ref<string>;
  isCreditPositive: Ref<boolean>;
  eventDescription: Ref<string>;
  debit: Ref<string>;
  credit: Ref<string>;
}

// eslint-disable-next-line max-len
export default function useCustomerHistoryListItemEgs(props: CustomerHistoryListItemEgsProps): CustomerHistoryListItemEgsComposable {
  const $formatMoney = useFormatMoney();

  const id = computed<string>(() => `(ID: ${props.item.objectId})`);
  const date = computed<string>(() => defaultDateTimeFormat(props.item.date));
  const typeKind = computed<string>(() => props.item.txTypeKind || '');
  const isCreditPositive = computed<boolean>(() => props.item.credit > 0);
  const eventDescription = computed<string>(() => props.item.txTypeName);

  const debit = computed<string>(() => {
    if (props.item.objectType === TransactionGroup.LEONSHOP) {
      return props.item.statusName;
    }
    return $formatMoney(props.item.debit || 0, { currency: props.currency });
  });

  const credit = computed<string>(() => {
    const baseString = `${$formatMoney(props.item.credit, { currency: props.currency })}`;
    if (props.item.objectType === TransactionGroup.LEONSHOP) {
      return baseString;
    }
    return `+${baseString}`;
  });

  return {
    id,
    date,
    typeKind,
    isCreditPositive,
    eventDescription,
    debit,
    credit,
  };
}
