<script lang="ts" setup>
import { toRef } from 'vue';

import { FilledLogoBackgroundSize } from 'web/src/modules/sportline/components/SportlineLogo/enums';
import { LeaguePageSectionId } from 'web/src/modules/sportline/submodules/league/enums';
import PageContentHeadlineLayout from 'web/src/modules/sportline/components/PageContentHeadlineLayout/PageContentHeadlineLayout.vue';
import FilledSportlineLogoWithBackground from 'web/src/modules/sportline/components/SportlineLogo/FilledSportlineLogoWithBackground.vue';
import VSwitcher from 'web/src/components/Switcher/VSwitcher/VSwitcher.vue';
import LeagueProgressBar from 'web/src/modules/sportline/submodules/league/components/LeagueProgressBar/LeagueProgressBar.vue';
import LeaguePageHeadlineInfoLayout from 'web/src/modules/sportline/submodules/league/components/Headline/default/LeaguePageHeadlineInfoLayout.vue';

import type { LeaguePageHeadlineProps, LeaguePageHeadlineEmit } from '../types';
import { useLeaguePageHeadline } from '../composables/useLeaguePageHeadline';
import { useLeaguePageHeadlineData } from '../composables/useLeaguePageHeadlineData';

const props = defineProps<LeaguePageHeadlineProps>();
const emit = defineEmits<LeaguePageHeadlineEmit>();

const hasMatches = toRef(props, 'hasMatches', false);
const hasPromotions = toRef(props, 'hasPromotions', false);
const hasStatistic = toRef(props, 'hasStatistic', false);
const activeSectionId = toRef(props, 'activeSectionId', LeaguePageSectionId.EventsList);

const {
  sport,
  region,
  league,
  leagueData,
  leagueRepresentationData,
} = useLeaguePageHeadlineData();
const {
  backgroundColor,
  backgroundImage,
  switcherOptions,
  switcherProperties,
  title,
  subtitle,
  logoUrl,
  sportIconName,
  hasTimeline,
} = useLeaguePageHeadline({
  sport,
  league,
  leagueData,
  leagueRepresentationData,
  hasMatches,
  hasPromotions,
  hasStatistic,
  activeSectionId,
});

function onTabSwitched(id: LeaguePageSectionId): void {
  emit('active-section-id-switched', { id });
}
</script>

<template>
  <PageContentHeadlineLayout v-auto-id="'LeaguePageHeadline'"
    :class="$style['headline-layout']"
    :background-color="backgroundColor"
    :background-image="backgroundImage"
  >
    <template #content>
      <div :class="$style['headline-layout__content']">
        <div
          v-if="switcherOptions.length > 1"
          :class="$style['headline-layout-tabs']"
        >
          <VSwitcher
            v-data-test="{ el: 'league-page-headline-slide-switcher' }"
            v-bind="switcherProperties"
            :active-id="activeSectionId"
            :options="switcherOptions"
            @change="onTabSwitched"
          />
        </div>

        <LeaguePageHeadlineInfoLayout
          :title="title"
          :subtitle="subtitle"
        >
          <template
            v-if="logoUrl || sportIconName"
            #logo
          >
            <FilledSportlineLogoWithBackground
              :class="$style['headline-layout__logo']"
              :src="logoUrl"
              :alt="title"
              :country-code="region?.representation.icon"
              :sport-segment-id="sport?.segment.id"
              :sport-icon-name="sportIconName"
              :fill-color="backgroundColor"
              :height="FilledLogoBackgroundSize.FULL_SIZE"
              borderless-flag
            />
          </template>

          <template
            v-if="hasTimeline"
            #progress
          >
            <LeagueProgressBar
              :current-season-start="leagueData ? leagueData.currentSeasonStart : null"
              :current-season-end="leagueData ? leagueData.currentSeasonEnd : null"
              :background-color="backgroundColor"
            />
          </template>
        </LeaguePageHeadlineInfoLayout>
      </div>
    </template>
  </PageContentHeadlineLayout>
</template>

<style module lang="scss">
// max headline content width
$maxContentWidth: 480px;

.headline-layout {
  padding: 8px;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  &__logo {
    // @TODO find a way to prevent passing paddings with media from the parent
    padding: 14px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15), 0 0 6px rgba(0, 0, 0, 0.1);

    @media (max-width: $maxContentWidth) {
      padding: 8px;
    }
  }
}

.headline-layout-tabs {
  flex-basis: 36px;
  width: 100%;
  padding-bottom: 8px;
}
</style>
