<script lang="ts" setup>
import { toRef } from 'vue';

import type { BetlineLeagueMatchesStatistic } from 'web/src/modules/sportline/types/rest';
import VList from 'web/src/components/List/VList/VList.vue';
import VListItemAccordion from 'web/src/components/List/VListItemAccordion/VListItemAccordion.vue';
import {
  StatisticChartBarType,
} from 'web/src/modules/sportline/submodules/core-statistic/enums';
import StatisticChartBar from 'web/src/modules/sportline/submodules/core-statistic/components/StatisticChartBar.vue';

import LeagueMatchesStatisticBlock from './LeagueMatchesStatisticBlock.vue';
import { useLeagueMatchesStatistic } from './useLeagueMatchesStatistic';

interface LeagueMatchesStatisticProps {
  leagueMatchesStatistic: BetlineLeagueMatchesStatistic;
}

const props = defineProps<LeagueMatchesStatisticProps>();

const {
  leagueWinsStatistic,
  leagueWinsStatisticLabels,
  firstGoalTimeAverage,
  goalsTotal,
  goalsAverage,
  timeToGoal,
} = useLeagueMatchesStatistic({
  leagueMatchesStatistic: toRef(props, 'leagueMatchesStatistic'),
});
</script>

<template>
  <VList v-auto-id="'LeagueMatchesStatistic'" independent-items>
    <VListItemAccordion
      id="league-matches-statistic"
      :title="$t('WEB2_STATISTICS_OVERVIEW')"
      is-title-centered
      open
    >
      <template #content>
        <div :class="$style['league-matches-statistic-content']">
          <div :class="$style['matches-total-wins']">
            <div
              :class="[
                $style['matches-total-wins__labels'],
                $style['statistic-chart-labels']
              ]"
            >
              <div :class="$style['matches-total-wins__label-holder']">
                <span
                  :class="[
                    $style['statistic-chart-label-indicator'],
                    $style['statistic-chart-label-indicator--home']
                  ]"
                />
                <span
                  :class="[
                    $style['matches-total-wins__label'],
                    $style['matches-total-wins__label--primary']
                  ]"
                >
                  {{ $t('WEB2_STATISTICS_HOME_TEAM') }}
                </span>
                <span :class="$style['matches-total-wins__label']">
                  {{ leagueWinsStatisticLabels.home }}
                </span>
              </div>

              <div :class="$style['matches-total-wins__label-holder']">
                <span
                  :class="[
                    $style['statistic-chart-label-indicator'],
                    $style['statistic-chart-label-indicator--draw']
                  ]"
                />
                <span
                  :class="[
                    $style['matches-total-wins__label'],
                    $style['matches-total-wins__label--primary']
                  ]"
                >
                  {{ $t('WEB2_STATISTICS_DRAWS') }}
                </span>
                <span :class="$style['matches-total-wins__label']">
                  {{ leagueWinsStatisticLabels.draw }}
                </span>
              </div>

              <div :class="$style['matches-total-wins__label-holder']">
                <span
                  :class="[
                    $style['statistic-chart-label-indicator'],
                    $style['statistic-chart-label-indicator--away']
                  ]"
                />
                <span
                  :class="[
                    $style['matches-total-wins__label'],
                    $style['matches-total-wins__label--primary']
                  ]"
                >
                  {{ $t('WEB2_STATISTICS_AWAY_TEAM') }}
                </span>
                <span :class="$style['matches-total-wins__label']">
                  {{ leagueWinsStatisticLabels.away }}
                </span>
              </div>
            </div>

            <StatisticChartBar
              :type="StatisticChartBarType.LABELED"
              :to-fixed="1"
              :home-percentage="leagueWinsStatistic.homePercentage"
              :draw-percentage="leagueWinsStatistic.drawsPercentage"
              :away-percentage="leagueWinsStatistic.awayPercentage"
            />
          </div>

          <LeagueMatchesStatisticBlock
            :title="$t('WEB2_STATISTICS_TOTAL_GOALS')"
            :home-value="goalsTotal.homeValue"
            :overall-value="goalsTotal.overallValue"
            :away-value="goalsTotal.awayValue"
            :home-label="goalsTotal.homeLabel"
            :overall-label="goalsTotal.overallLabel"
            :away-label="goalsTotal.awayLabel"
            do-use-progress-bar
          />

          <LeagueMatchesStatisticBlock
            :title="$t('WEB2_STATISTICS_AVERAGE_MATCH_GOALS')"
            :home-value="goalsAverage.homeValue"
            :overall-value="goalsAverage.overallValue"
            :away-value="goalsAverage.awayValue"
            :home-label="goalsAverage.homeLabel"
            :overall-label="goalsAverage.overallLabel"
            :away-label="goalsAverage.awayLabel"
            do-use-progress-bar
          />

          <LeagueMatchesStatisticBlock
            :title="$t('WEB2_STATISTICS_FIRST_GOAL_AVERAGE_MIN')"
            :home-value="firstGoalTimeAverage.homeValue"
            :overall-value="firstGoalTimeAverage.overallValue"
            :away-value="firstGoalTimeAverage.awayValue"
            :home-label="firstGoalTimeAverage.homeLabel"
            :overall-label="firstGoalTimeAverage.overallLabel"
            :away-label="firstGoalTimeAverage.awayLabel"
          />

          <LeagueMatchesStatisticBlock
            :title="$t('WEB2_STATISTICS_GOAL_AVERAGE_TIME')"
            :home-value="timeToGoal.homeValue"
            :overall-value="timeToGoal.overallValue"
            :away-value="timeToGoal.awayValue"
            :home-label="timeToGoal.homeLabel"
            :overall-label="timeToGoal.overallLabel"
            :away-label="timeToGoal.awayLabel"
          />
        </div>
      </template>
    </VListItemAccordion>
  </VList>
</template>

<style lang="scss" module>
@import '~web/src/modules/sportline/submodules/core-statistic/components/styles/statisticChartLabels';
@import '~web/src/modules/sportline/submodules/core-statistic/components/styles/statisticChartLabelIndicator';

.league-matches-statistic-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;

  @include for-phone-only {
    grid-template-columns: 1fr;
  }
}

.matches-total-wins {
  display: grid;
  grid-template-columns: 1fr;
  grid-column: 1 / -1;
  gap: 12px;
  padding: 12px 8px;
  background-color: var(--Layer2);
  border-radius: 5px;

  &__label-holder {
    display: grid;
    grid-template-columns: auto 1fr;
    row-gap: 4px;

    & > :first-child {
      @include medium\14\16\025;

      grid-row: 1 / 3;
      margin-right: 4px;
    }
  }

  &__label {
    @include medium\12\12;

    color: var(--TextPrimary);

    &--primary {
      @include medium\14\16\025;

      color: var(--TextDefault);
    }
  }

  @include for-phone-only {
    grid-column: 1;
  }
}
</style>
