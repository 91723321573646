<script lang="ts" setup>
import { onBeforeMount, toRef } from 'vue';

import VLazyImage from 'web/src/components/Image/VLazyImage/VLazyImage.vue';
import type { VImageRef } from 'web/src/components/Image/types';

import type {
  VImageEmits,
  VImageProps,
} from './types';
import { useVImage } from './composables';

const props = defineProps<VImageProps>();
const emit = defineEmits<VImageEmits>();

const src = toRef(props, 'src');
const x1 = toRef(props, 'x1');
const x2 = toRef(props, 'x2');
const x3 = toRef(props, 'x3');
const x1webp = toRef(props, 'x1webp');
const x2webp = toRef(props, 'x2webp');
const x3webp = toRef(props, 'x3webp');
const objectFit = toRef(props, 'objectFit');
const objectPosition = toRef(props, 'objectPosition');

const {
  lazyImage,
  generatedSrc,
  prepareImageStyles,
  forceShowImage,
  created,
} = useVImage({
  src,
  x1,
  x2,
  x3,
  x1webp,
  x2webp,
  x3webp,
  objectFit,
  objectPosition,
});

function onError(): void {
  if (!process.env.VUE_APP_PRERENDER) {
    emit('error');
  }
}

function emitClick(event: MouseEvent) {
  emit('click', event);
}

function emitImageLoaded(): void {
  emit('image-loaded');
}

function emitLazyImageMounted(): void {
  emit('lazy-image-mounted');
}

defineExpose<VImageRef>({
  forceShowImage,
});

onBeforeMount(created);
</script>

<template>
  <VLazyImage
    v-if="generatedSrc && isLazy && !process.env.VUE_APP_PRERENDER"
    ref="lazyImage"
    :src="generatedSrc"
    :show-loader="showLoader"
    :alt="alt"
    :object-fit="objectFit"
    :object-position="objectPosition"
    :force-hide-image="forceHideImage"
    :transparent-loader-bg="transparentLoader"
    :light-loader="lightLoader"
    :is-gradient="isGradient"
    :is-relative="isRelative"
    @vue:mounted="emitLazyImageMounted"
    @error="onError"
    @image-loaded="emitImageLoaded"
    @click="emitClick"
  />
  <img
    v-else-if="generatedSrc && !forceHideImage"
    :src="generatedSrc"
    :style="prepareImageStyles"
    :alt="alt"
    @error="onError"
    @load="emitImageLoaded"
    @click="emitClick"
  >
</template>
