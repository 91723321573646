import type { Ref } from 'vue';
import { computed, toRef } from 'vue';

import usePaymentsMaxValueFastChoiceDeposit
  // eslint-disable-next-line max-len
  from 'web/src/modules/payments/form-widgets/FastSum/components/PaymentsFastSumInput/composables/usePaymentsMaxValueFastChoiceDeposit';
import { useBalance } from 'web/src/modules/customer/store/composables';
import type {
  PaymentsFastSumInputProps,
} from 'web/src/modules/payments/form-widgets/FastSum/components/PaymentsFastSumInput/types';

export interface PaymentsMaxValueFastChoiceComposable {
  maxValueFastChoice: Ref<number>;
}
export default function usePaymentsMaxValueFastChoice(
  props: PaymentsFastSumInputProps,
): PaymentsMaxValueFastChoiceComposable {
  const isDeposit = toRef(props, 'isDeposit');
  const maxAmount = toRef(props, 'maxAmount');
  const { balance } = useBalance();
  const { maxValueFastChoiceDeposit } = usePaymentsMaxValueFastChoiceDeposit(props);

  const maxValueFastChoice = computed(() => {
    if (isDeposit.value) {
      return maxValueFastChoiceDeposit.value;
    }

    if (maxAmount.value) {
      return balance.value < maxAmount.value ? balance.value : maxAmount.value;
    }
    return balance.value;
  });

  return {
    maxValueFastChoice,
  };
}
