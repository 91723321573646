import { BusEvent, useEventsBus } from '@leon-hub/event-bus';

import { useErrorHandler } from 'web/src/modules/core/composables/errors';
import type { AppVueRouter } from 'web/src/modules/core/services/router/types';

export default function setupAppBusEvents(router: AppVueRouter): void {
  const bus = useEventsBus();
  const { handleError } = useErrorHandler(router);

  // without bus.off(BusEvent.REDIRECT)
  bus.on(BusEvent.REDIRECT, ({ to, replace }) => {
    if (replace) {
      void router.replace(to);
    } else {
      void router.push(to);
    }
  });

  // without bus.off(BusEvent.API_ERROR)
  bus.on(BusEvent.API_ERROR, ({ error }) => {
    handleError(error);
  });
}
