import {
  onActivated,
  onBeforeMount,
  onBeforeUnmount,
  onDeactivated,
} from 'vue';
import noop from 'lodash/noop';

import type {
  BusEventType,
  Callback,
} from '@leon-hub/event-bus/src/types';

import type BusEvent from './BusEvent';
import useEventsBus from './useEventsBus';

export interface Controls {
  subscribe: () => void;
  unsubscribe: () => void;
}

export interface Options {
  onBeforeMountDisabled?: boolean;
  onDeactivatedDisabled?: boolean;
}

function getOptions(options: boolean | Options): Options {
  if (typeof options === 'object') return options;
  return {
    onDeactivatedDisabled: false,
    onBeforeMountDisabled: options,
  };
}

/**
 * Safely subscribes to an event when the component is ready to handle event callback.
 * Auto-sync with component life cycle.
 */
export default function useBusSafeSubscribe<E extends BusEvent, P extends BusEventType<E>>(
  event: E | E[],
  callback: Callback<P>,
  skipDeactivation?: boolean,
): Controls;
export default function useBusSafeSubscribe<E extends BusEvent, P extends BusEventType<E>>(
  event: E | E[],
  callback: Callback<P>,
  options?: Options,
): Controls;
export default function useBusSafeSubscribe<E extends BusEvent, P extends BusEventType<E>>(
  event: E | E[],
  callback: Callback<P>,
  skipDeactivationOrOptions: boolean | Options = false,
): Controls {
  /**
   * Skip subscription during Server-Side Rendering (SSR)
   * as the subsequent Vue lifecycle hooks won't be called on the server.
   */
  if (process.env.VUE_APP_RENDERING_SSR) return { subscribe: noop, unsubscribe: noop };

  const {
    onBeforeMountDisabled,
    onDeactivatedDisabled,
  } = getOptions(skipDeactivationOrOptions);

  const bus = useEventsBus();
  let isActive = false;

  const subscribe = () => {
    if (!isActive) {
      bus.on(event, callback);
      isActive = true;
    }
  };

  const unsubscribe = () => {
    if (isActive) {
      bus.off(event, callback);
      isActive = false;
    }
  };

  /**
   * Register lifecycle hooks.
   * @see https://vuejs.org/api/composition-api-lifecycle.html
   */
  if (!onBeforeMountDisabled) {
    onBeforeMount(subscribe);
  }
  onActivated(subscribe);
  onBeforeUnmount(unsubscribe);
  if (!onDeactivatedDisabled) {
    onDeactivated(unsubscribe);
  }

  return {
    subscribe,
    unsubscribe,
  };
}
