import type { SlipEntryInput } from '@leon-hub/api-sdk';

import type { SlipEntry } from '../../slip-info/types';

export function getSlipEntryInput(item: SlipEntry): SlipEntryInput {
  return {
    ts: item.ts,
    event: item.event,
    market: item.market,
    runner: item.runner,
    odds: item.odds,
    banker: item.banker,
    marketStatus: item.marketStatus,
    betline: item.betline,
    zeroMargin: item.zeroMargin,
  };
}

export function getMakeBetEntryItems(input: SlipEntry[]): SlipEntryInput [] {
  return input.map(getSlipEntryInput);
}
