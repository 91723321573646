import type { EgsGame } from 'web/src/modules/egs/types';
import type { CasinoActiveGameEventOptions } from 'web/src/modules/analytics/services/types';

export default function mapActiveGameToAnalytics(game: EgsGame): CasinoActiveGameEventOptions {
  const { categories, images } = game;

  return {
    game: {
      id: game.id,
      name: game.name,
      category: categories[0]?.nameEn || '',
      image: images.preview?.src || '',
      provider: {
        game: game.name,
        id: game.providerId,
      },
    },
  };
}
