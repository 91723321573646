<script setup lang="ts">
import { computed, toRef } from 'vue';
import { useRouter } from 'vue-router';

import { IconSize } from '@leon-hub/icons';

import type { VIconProps } from '@components/v-icon';
import { VIcon } from '@components/v-icon';

import type { HomeHeadingProps } from 'web/src/modules/home/components/heading/types';
import { ButtonHeight, ButtonKind } from 'web/src/components/Button/VButton/enums';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import VRouterLink from 'web/src/components/Link/VRouterLink/VRouterLink.vue';

const props = withDefaults(defineProps<HomeHeadingProps>(), {
  title: '',
  buttonText: '',
});
const secondaryButton = toRef(props, 'secondaryButton', null);

const router = useRouter();

const buttonIconProperties: VIconProps = '1'
  ? { size: IconSize.SIZE_16 }
  : { size: IconSize.SIZE_12 };

const secondaryButtonIconProperties = computed<VIconProps>(() => ({
  ...buttonIconProperties,
  ...(secondaryButton.value?.icon ?? {}),
}));

function secondaryButtonClick(): void {
  const target = secondaryButton.value?.to;
  if (!target) { return; }
  void router.push(target);
}
</script>

<template>
  <VRouterLink v-auto-id="'HomeHeading'"
    :class="$style['home-heading']"
    :to="to ?? {}"
  >
    <span :class="$style['home-heading__title']">
      {{ title }}
    </span>

    <template v-if="secondaryButton">
      <VButton
        :kind="ButtonKind.BASE"
        :height="ButtonHeight.MEDIUM"
        :class="[
          $style['home-heading__button'],
          $style['home-heading__button--secondary'],
        ]"
        :label="secondaryButton.text"
        :icon="secondaryButtonIconProperties"
        :is-uppercase="false"
        @click.prevent="secondaryButtonClick"
      >
        <VIcon
          v-bind="secondaryButtonIconProperties"
          :class="$style['home-heading__button-icon']"
        />
        <span :class="$style['home-heading__button-label']">
          {{ secondaryButton.text }}
        </span>
      </VButton>
    </template>

    <VButton
      v-data-test="{ el: 'home-heading-button' }"
      :kind="ButtonKind.BASE"
      :height="ButtonHeight.MEDIUM"
      :class="$style['home-heading__button']"
      :label="buttonText ?? undefined"
      :is-uppercase="false"
    />
  </VRouterLink>
</template>

<style module lang="scss">
.home-heading {
  $self: &;

  display: flex;
  flex-grow: 0;
  align-items: center;
  height: 56px;
  padding: 0 0 0 16px;
  margin: 0;
  text-decoration: none;

  &__title {
    @include medium\18\20;

    display: block;
    flex: 1;
    color: var(--TextDefault);
  }

  &__button {
    @include medium\13\16;

    height: 40px;
    color: var(--TextPrimary);
    background-color: var(--Button);
    border-radius: 5px;

    &--secondary {
      margin-right: 4px;
    }

    &-icon {
      margin-right: 6px;
      color: var(--TextPrimary);
    }
  }
}
</style>
