import type { Ref } from 'vue';
import { computed } from 'vue';

import type { CoreSportlineEvent } from 'web/src/modules/sportline/types';
import type { MarketGroup } from 'web/src/modules/sportline/views/markets-list/types';
import { isSportlineEventSuspended } from 'web/src/modules/sportline/utils/sportlineEvent';
import { getMarketsGridCellOpenStateKey } from 'web/src/modules/sportline/submodules/markets-grid/utils';

interface MarketsListMarketGroupProps {
  group: Ref<MarketGroup>;
  sportEvent: Ref<Maybe<Pick<CoreSportlineEvent, 'status'>>>;
}

interface MarketsListMarketGroupEmits {
  (e: 'market-group-toggle', groupKey: string): void;
}

interface MarketsListMarketGroupComposable {
  isSportEventSuspended: Ref<boolean>;
  onMarketGroupToggle(group: MarketGroup): void;
}

export default function useMarketsListMarketGroup(
  props: MarketsListMarketGroupProps,
  emit: MarketsListMarketGroupEmits,
): MarketsListMarketGroupComposable {
  const isSportEventSuspended = computed<boolean>(() => isSportlineEventSuspended(props.sportEvent.value));

  function onMarketGroupToggle(group: MarketGroup): void {
    const groupKey = getMarketsGridCellOpenStateKey(group);
    emit('market-group-toggle', groupKey);
  }

  return {
    isSportEventSuspended,
    onMarketGroupToggle,
  };
}
