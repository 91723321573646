import { whenClientActivityIdle } from '@leon-hub/idle';
import { addBreadcrumb } from '@leon-hub/sentry';
import { voidify } from '@leon-hub/utils';

type StopFn = () => void;

export function trackIdleEvents(): StopFn {
  const interval = 10_000;
  let idleStartTime: undefined | number;
  let isRunning = true;
  queueMicrotask(voidify(async () => {
    while (isRunning) {
      // eslint-disable-next-line no-await-in-loop
      const remains = await whenClientActivityIdle({ interval, eager: true });
      if (idleStartTime && remains) {
        const idleTime = Date.now() - idleStartTime;
        idleStartTime = undefined;
        addBreadcrumb({
          category: 'user-lifecycle',
          message: 'User became active',
          level: 'info',
          data: { idleTime },
        });
      } else if (!idleStartTime && !remains) {
        idleStartTime = Date.now();
        addBreadcrumb({
          category: 'user-lifecycle',
          message: 'User became idle',
          level: 'info',
          data: { interval },
        });
      }
    }
  }));
  return () => {
    isRunning = false;
  };
}
