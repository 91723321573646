const getTestingKeyName = () => '__TEST_KEY__';
const TEST_VALUE = '1';

let cache: boolean | undefined;
export function isLocalStorageAvailable(): boolean {
  if (process.env.VUE_APP_RENDERING_SSR || process.env.NODE_ENV === 'test') {
    return false;
  }

  if (navigator && navigator.cookieEnabled === false) {
    return false;
  }

  if (cache !== undefined) return cache;

  const { localStorage } = globalThis;
  try {
    if (!localStorage) {
      cache = false;
      return cache;
    }

    const key = getTestingKeyName();
    localStorage.setItem(key, TEST_VALUE);
    cache = localStorage.getItem(key) === TEST_VALUE;
    return cache;
  } catch {
    // empty
  } finally {
    try {
      localStorage.removeItem(getTestingKeyName());
    } catch {
      // empty
    }
  }

  return false;
}
