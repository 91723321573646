<script setup lang="ts">
import { SlipTypeId } from '@leon-hub/api-sdk';
import { IconName, IconSize } from '@leon-hub/icons';

import { VIcon } from '@components/v-icon';
import { VSpinner, VSpinnerSize } from '@components/spinner';

import { StakeInput } from 'web/src/components/Input';
import VSwitch from 'web/src/components/Switch/VSwitch/VSwitch.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { ButtonHeight, ButtonKind } from 'web/src/components/Button/VButton/enums';

import { BetSlipSummaryStatus } from '../../enums';
import { BetSlipSelect } from '../BetSlipSelect';
import useBetSlipSummary from './composables/useBetSlipSummary';
import type { BetSlipSummaryEmits, BetSlipSummaryProps } from './types';

const props = withDefaults(defineProps<BetSlipSummaryProps>(), {
  betMode: SlipTypeId.SINGLE,
  freeBetOptions: () => [],
  selectedFreeBet: '',
  freeBetTermKey: '',
  status: BetSlipSummaryStatus.DEFAULT,
  currencySymbol: '$',
  balance: '$0',
  systemOptions: () => [],
  sameStakeForSingles: true,
  isVisible: true,
});

const emits = defineEmits<BetSlipSummaryEmits>();

const {
  canShowFreeBetSelector,
  computedWarningMessage,
  errorLabel,
  freebetTermsRouteParams,
  inputError,
  isSystemMode,
  mustShowBalanceMessage,
  mustShowMessage,
  mustShowPrizeMessage,
  showAmountValue,
  showWrongModeMessage,
  wrongModeMessage,
  isPcpSwitchCollapsed,
  isTotalHandicapSwitchCollapsed,
  emitStakeInput,
  emitStakeFocus,
  emitStakeBlur,
  onAllowPriceChanges,
  emitToggleSameStakeForSingles,
  onFreeBetSwitchClick,
  emitFreeBetSwitch,
  emitFreebetSelect,
  emitSystemSelect,
  emitClearClick,
  emitSettingsClick,
  onAllowTotalHandicapChanges,
} = useBetSlipSummary(props, emits);
</script>

<template>
  <div v-auto-id="'BetSlipSummary'"
    :class="{
      [$style['bet-slip-summary']]: true,
      [$style['bet-slip-summary--wrong-mode']]: wrongBetMode,
      [$style['bet-slip-summary--phone']]: !'1',
      [$style['bet-slip-summary--desktop']]: !!'1',
    }"
  >
    <div :class="$style['bet-slip-summary__controls']">
      <div :class="$style['bet-slip-summary__contols-group']">
        <VButton
          :kind="ButtonKind.SECONDARY"
          :height="ButtonHeight.TINY"
          @click="emitClearClick"
        >
          <template #default>
            <VIcon
              :name="IconName.BIN"
              :size="IconSize.SIZE_16"
              :class="$style['bet-slip-summary__colored-icon']"
            />{{ $t('WEB2_SLIP_REMOVE_ALL_EVENTS') }}
          </template>
        </VButton>
        <VButton
          v-if="shareEnabled"
          :kind="ButtonKind.SECONDARY"
          :height="ButtonHeight.TINY"
          @click="$emit('share-slip')"
        >
          <template #default>
            <VIcon
              :name="IconName.SHARE"
              :size="IconSize.SIZE_16"
              :class="$style['bet-slip-summary__colored-icon']"
            />{{ $t('WEB2_SHARE') }}
          </template>
        </VButton>
      </div>
      <VButton
        v-if="isLoggedIn"
        :title="$t('WEB2_SETTINGS')"
        :kind="ButtonKind.SECONDARY"
        :height="ButtonHeight.TINY"
        :icon-name="IconName.COG"
        @click="emitSettingsClick"
      />
    </div>
    <template v-if="isLoggedIn">
      <div
        :class="{
          [$style['bet-slip-summary__collapsible']]: true,
          [$style['bet-slip-summary__collapsible--collapsed']]: isPcpSwitchCollapsed,
        }"
      >
        <VSwitch
          :class="$style['bet-slip-summary__switch']"
          :checked="!denyPriceChanges"
          :label="$t('JS_PRICE_CHANGE_POLICY_CHECKBOX')"
          @animation-completed="onAllowPriceChanges"
        />
      </div>
      <div
        v-if="isHandicapInSelection"
        :class="{
          [$style['bet-slip-summary__collapsible']]: true,
          [$style['bet-slip-summary__collapsible--collapsed']]: isTotalHandicapSwitchCollapsed,
        }"
      >
        <VSwitch
          :class="$style['bet-slip-summary__switch']"
          :checked="!denyTotalHandicapChanges"
          :label="$t('WEB2_SLIP_AUTCONFIRM_TOTAL_HANDICAP_SWITCH')"
          @animation-completed="onAllowTotalHandicapChanges"
        />
      </div>
    </template>
    <VSwitch
      v-if="isMultiSingles"
      :class="$style['bet-slip-summary__switch']"
      :checked="sameStakeForSingles"
      :label="$t('WEB2_SLIP_SAME_AMOUNT_FOR_SINGLES')"
      @change="emitToggleSameStakeForSingles"
    />
    <div :class="$style['bet-slip-summary__content']">
      <template v-if="!showWrongModeMessage">
        <div
          v-if="canShowFreeBetSelector"
          :class="$style['bet-slip-summary__freebet-switch']"
          @click="onFreeBetSwitchClick"
        >
          <VSwitch
            :checked="freeBetSwitchChecked"
            :disabled="freeBetSwitchDisabled"
            :label="$t('WEB2_USE_FREEBET')"
            @change="emitFreeBetSwitch"
          />
        </div>
        <div
          v-show="showAmountValue"
          :class="$style['bet-slip-summary__input']"
        >
          <div :class="$style['bet-slip-summary__amount']">
            <div
              v-if="betMode === SlipTypeId.EXPRESS"
              :class="{
                [$style['bet-slip-summary__total']]: true,
                [$style['bet-slip-summary__total--wide']]: freeBetSwitchChecked,
                [$style['bet-slip-summary__total--boosted']]: !!totalOddsWithoutBoost,
              }"
            >
              <span :class="$style['bet-slip-summary__total-label']">
                {{ $t('WEB2_SLIP_ODDS_LABEL') }}:
              </span>
              <span :class="$style['bet-slip-summary__total-coefficient']">
                <template v-if="totalOddsWithoutBoost">
                  <span :class="$style['bet-slip-summary__total-inactive']">
                    {{ totalOddsWithoutBoost }}
                  </span>&nbsp;</template><span
                  :class="{
                    [$style['bet-slip-summary__total-value']]: true,
                    [$style['bet-slip-summary__total-value--boosted']]: !!totalOddsWithoutBoost,
                  }"
                >
                  {{ totalOdds }}
                </span>
              </span>
            </div>
            <div
              v-if="isSystemMode"
              :class="$style['bet-slip-summary__system-select']"
            >
              <BetSlipSelect
                name="slipSystemSelect"
                :options="systemOptions"
                :selected-value="selectedSystemValue"
                @change="emitSystemSelect"
              />
            </div>
            <StakeInput
              v-if="!freeBetSwitchChecked"
              :value="stakeValue"
              :have-error="inputError"
              :is-focused="inputFocused"
              :currency-symbol="currencySymbol"
              :bet-mode="betMode"
              @focus="emitStakeFocus"
              @blur="emitStakeBlur"
              @input="emitStakeInput"
            />
          </div>
          <div
            v-if="freeBetSwitchChecked && !isSystemMode"
            :class="$style['bet-slip-summary__amount']"
          >
            <BetSlipSelect
              name="slipFreebetSelect"
              is-freebet
              :selected-value="selectedFreeBet"
              :options="freeBetOptions"
              @change="emitFreebetSelect"
            />
          </div>
          <div
            v-if="errorLabel"
            :class="$style['bet-slip-summary__error-label']"
          >
            {{ errorLabel }}
          </div>
          <template v-if="!freeBetSwitchChecked">
            <div
              :class="$style['bet-slip-summary__fast-bets']"
              data-test-id="control-fast-bets"
            >
              <slot name="fast-bets" />
            </div>
            <template v-if="null">
              <div :class="$style['bet-slip-summary__keyboard']">
                <slot name="keyboard" />
              </div>
            </template>
          </template>
        </div>
        <router-link
          v-if="freeBetSwitchChecked"
          :to="freebetTermsRouteParams"
          :class="$style['bet-slip-summary__freebet-terms-link']"
          data-test-id="freebet-terms-link"
        >
          {{ $t('WEB2_FREEBET_TERMS') }}
        </router-link>
        <div :class="$style['bet-slip-summary__footer']">
          <div :class="$style['bet-slip-summary__button']">
            <slot name="button" />
          </div>
          <template v-if="taxAmount && mustShowPrizeMessage">
            <div
              :class="{
                [$style['bet-slip-summary__message']]: true,
                [$style['bet-slip-summary__message--tax']]: true,
                [$style['bet-slip-summary__message--hidden']]: isEnteringAnimationPlaying,
              }"
            >
              <span
                :class="[
                  $style['bet-slip-summary__message-label'],
                  $style['bet-slip-summary__message-label--tax'],
                ]"
              >
                {{ $t('WEB2_BET_DETAILS_TAX', { amount: taxPercent ?? '' }) }}
              </span>
              <span :class="$style['bet-slip-summary__message-right']">
                {{ taxAmount }}
              </span>
            </div>
          </template>
          <div
            :class="{
              [$style['bet-slip-summary__message']]: true,
              [$style['bet-slip-summary__message--hidden']]: isEnteringAnimationPlaying,
              [$style['bet-slip-summary__message--centered']]: mustShowMessage || isSyncInProgress,
            }"
          >
            <div
              v-if="isSyncInProgress"
              :class="$style['bet-slip-summary__sync']"
            >
              <VSpinner
                :size="VSpinnerSize.SMALL"
                :class="$style['bet-slip-summary__sync-icon']"
              /> {{ $t('WEB2_SYNCING_DATA') }}
            </div>
            <template v-else-if="mustShowBalanceMessage">
              <span
                :class="$style['bet-slip-summary__message-label']"
              >
                {{ $t('JSP_FNGAMES_BALANCE_TEXT') }}:
              </span>
              <span :class="$style['bet-slip-summary__message-right']">
                <span
                  :class="[
                    $style['bet-slip-summary__message-amount'],
                    $style['bet-slip-summary__message-amount--balance'],
                  ]"
                >
                  {{ balance }}
                </span>
              </span>
            </template>
            <template v-else-if="mustShowPrizeMessage">
              <span
                :class="$style['bet-slip-summary__message-label']"
              >
                {{ $t('JSPBET_BETDET_MAX_WIN') }}:
              </span>
              <span :class="$style['bet-slip-summary__message-right']">
                <template v-if="maxPrizeWithoutBoost">
                  <span
                    :class="[
                      $style['bet-slip-summary__message-amount'],
                      $style['bet-slip-summary__message-amount--prize-wthout-boost'],
                    ]"
                  >
                    {{ maxPrizeWithoutBoost }}
                  </span>
                </template>
                <span
                  :class="[
                    $style['bet-slip-summary__message-amount'],
                    $style['bet-slip-summary__message-amount--prize'],
                  ]"
                >
                  &nbsp;{{ maxPrize }}
                </span>
              </span>
            </template>
            <template v-else-if="mustShowMessage">
              <span :class="$style['bet-slip-summary__warning-message']">
                {{ computedWarningMessage }}
              </span>
            </template>
          </div>
        </div>
      </template>
      <template v-else>
        <div :class="$style['bet-slip-summary__wrong-mode-message']">
          {{ wrongModeMessage }}
        </div>
        <div v-if="null">
          <slot name="button" />
        </div>
      </template>
    </div>
  </div>
</template>

<style lang="scss" module>
@keyframes show-error {
  0% {
    transform: scaleY(0);
  }

  100% {
    transform: scaleY(1);
  }
}

.bet-slip-summary {
  $sideGap: 16px;
  $self: &;

  margin: -1px 0 0;
  box-shadow: inset 0 1px var(--Layer0);

  &__controls {
    display: flex;
    justify-content: space-between;
    padding: 10px $sideGap 0;
  }

  &__contols-group {
    display: flex;
    gap: 12px;
  }

  &__colored-icon {
    margin-right: 4px;
    color: var(--BrandDefault);
  }

  &--wrong-mode {
    box-shadow: none;
  }

  &--wrong-mode#{$self}--phone {
    padding-bottom: 24px;
  }

  &__switch {
    align-items: flex-start;
    padding: 10px $sideGap;
  }

  &__collapsible {
    max-height: 60px;
    overflow: hidden;
    opacity: 1;
    transition: 0.3s ease-out;
    transition-property: opacity, max-height;

    &--collapsed {
      max-height: 0;
      opacity: 0;
    }
  }

  &__content {
    padding: 0 $sideGap $sideGap;
  }

  &__total {
    @include regular\12\12;

    flex: 1 0 80px;
    padding: 0 $sideGap;
    margin-right: 8px;
    color: var(--TextSecondary);
    text-align: center;
    white-space: nowrap;
    background: var(--Layer2);
    border-radius: 5px;

    &--boosted {
      flex-basis: 140px;
    }

    &--wide {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: space-between;
      height: 44px;
      padding: 0 8px;
      margin: 0 0 8px;
    }
  }

  &__total-label {
    display: block;
    margin: 4px;
  }

  &__total-coefficient {
    @include medium\16\20\025;

    display: block;
    vertical-align: baseline;
  }

  &__total-inactive {
    @include regular\11\20\025;

    text-decoration: line-through;
  }

  &__total-value {
    white-space: nowrap;

    &--boosted {
      color: var(--BrandHighlight);
    }
  }

  &__input {
    @include z-index(base);

    position: relative;

    &:first-child {
      padding-top: $sideGap - 2px;
    }
  }

  &__amount {
    display: flex;
  }

  &__system-select {
    position: relative;
    flex: 0;
    margin-right: 8px;
  }

  &__error-label {
    @include regular\12\16;

    height: 28px;
    padding: 4px 0 8px;
    color: var(--ErrorText);
    text-align: right;
    transform: scaleY(0);
    transform-origin: top;
    animation: show-error 0.15s ease forwards;
  }

  &__fast-bets,
  &__keyboard {
    margin: #{0 } - $sideGap;
  }

  &__button {
    padding-top: 8px;
  }

  &__footer {
    padding: 0;
  }

  &__message {
    @include regular\14\16\025;

    display: flex;
    justify-content: space-between;
    min-height: 16px;
    margin: 8px 0 0;
    color: var(--TextDefault);

    &--centered {
      display: block;
      text-align: center;
    }

    &--hidden {
      // to avoid animation lag from, prop comes from BetSlipMobileContainer
      visibility: hidden;
    }

    &--tax {
      color: var(--TextPrimary);
    }
  }

  &__message-label {
    margin-right: 20px;
    color: var(--TextDefault);
    text-align: left;

    &--tax {
      color: var(--TextPrimary);
    }
  }

  &__message-right {
    display: block;
    flex: 0 1 auto;
    text-align: right;
  }

  &__message-amount {
    font-weight: 500;

    &--balance {
      color: var(--AlertDefault);
    }

    &--prize {
      color: var(--BrandHighlight);
    }

    &--prize-wthout-boost {
      font-weight: 400;
      color: var(--TextSecondary);
      text-decoration: line-through;
    }
  }

  &__warning-message {
    @include regular\13\16;

    display: inline-block;
    letter-spacing: 0;
    vertical-align: top;
  }

  &__sync {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__sync-icon {
    margin-right: 5px;
    color: var(--BrandDefault);
  }

  &__wrong-mode-message {
    @include regular\13\16;

    padding: $sideGap;
    text-align: center;
  }

  &--desktop &__wrong-mode-message {
    padding: $sideGap 0 0;
  }

  &--phone &__wrong-mode-message {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 112px;
  }

  &__freebet-switch {
    padding: 12px 0 16px;
  }

  &__freebet-terms-link {
    @include regular\14\16\025;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 44px;
    padding: 14px 0;
    color: var(--TextPrimary);
    text-align: center;
    text-decoration: underline;
    cursor: pointer;
    user-select: none;

    &:hover {
      color: var(--TextDefault);
    }
  }
}
</style>
