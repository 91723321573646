import type { Market, MarketSpecifierId, MarketSpecifierValue } from 'web/src/modules/sportline/types';

export function getMarketsSpecifiersKey(markets: Pick<Market, 'specifiers'>[]): string {
  const values: Record<MarketSpecifierId, Set<MarketSpecifierValue>> = {};

  for (const market of markets) {
    if (!market.specifiers) { continue; }
    for (const specifier of market.specifiers) {
      values[specifier.id] = values[specifier.id] ?? new Set<MarketSpecifierValue>([]);
      values[specifier.id].add(specifier.value);
    }
  }

  return Object.keys(values).map((id) => `${id}=${[...values[id].values()].join(',')}`).join(';');
}
