import createDebug from 'debug';
import type { Debugger } from 'debug';

export const debug: Debugger = createDebug('@leon-hub/websocket');

type DebugCallback = (cb: (logger: Debugger) => void) => void;

export function createDebugCallback(name?: string): DebugCallback {
  const logger = name ? debug.extend(name) : debug;
  return function whileDebug(cb: (logger: Debugger) => void): void {
    if (logger.enabled) {
      cb(logger);
    }
  };
}
