import {
  assert, isArray, isNumber, isObject, isUndefined,
} from '@leon-hub/guards';
import { isFile } from '@leon-hub/utils';
import { logger } from '@leon-hub/logging';

import type {
  FormErrors,
  FormExternalErrors,
  FormDataValue,
  FormDataMap,
  FormSchema,
  FormPartialErrors,
} from 'web/src/components/Form/types';

import { isFormTypeObject, isFormTypeArray } from './formSchemaUtils';

export const getValueForExternalError = (formDataMap: FormDataMap, field: string): FormDataValue => {
  const value = formDataMap.get(field);
  assert(!isUndefined(value));
  return isFile(value) ? value.name : value;
};

const addErrorsToObjectValue = (
  currentErrors: FormErrors,
  parentKey: string,
  childKey: string,
  errorMessage: string,
): FormErrors => {
  const updatedErrors: FormErrors = { ...currentErrors };
  if (!updatedErrors[parentKey]) {
    updatedErrors[parentKey] = {};
  }
  const currentErrorsObject = updatedErrors[parentKey];
  assert(isObject(currentErrorsObject));
  updatedErrors[parentKey] = {
    ...currentErrorsObject,
    [childKey]: errorMessage,
  };
  return updatedErrors;
};

const addErrorsToArrayValue = (
  currentErrors: FormErrors,
  parentKey: string,
  childKey: string,
  errorMessage: string,
) => {
  const updatedErrors: FormErrors = { ...currentErrors };
  if (!updatedErrors[parentKey]) {
    updatedErrors[parentKey] = [];
  }
  const childKeyAsIndex = parseInt(childKey, 10);
  if (!isNumber(childKeyAsIndex)) {
    logger.error('Unexpected value when parsing customErrors for array', { dataPath: `${parentKey}/${childKey}` });
  }
  const currentErrorsArray = updatedErrors[parentKey] as FormPartialErrors;
  assert(isArray(currentErrorsArray));
  let currentLength = currentErrorsArray.length;
  while (currentLength < childKeyAsIndex) {
    currentErrorsArray.push(null);
    currentLength += 1;
  }
  currentErrorsArray[childKeyAsIndex] = errorMessage;
  return updatedErrors;
};

const getRelatedErrorMessage = (
  currentValue: FormDataValue,
  externalErrors: FormExternalErrors,
  errorKey: string,
): string | null => {
  const currentErrors = externalErrors[errorKey];
  const currentErrorsMap: Map<FormDataValue, string> = new Map();
  for (const { value, message } of currentErrors) {
    currentErrorsMap.set(value, message);
  }
  const relatedErrorMessage = currentErrorsMap.get(currentValue);
  return relatedErrorMessage || null;
};

export const getExternalErrorsRelatedToCurrentData = (
  formDataMap: FormDataMap,
  formSchema: FormSchema,
  externalErrors: FormExternalErrors,
  ignoredFields: Set<string>,
  // eslint-disable-next-line sonarjs/cognitive-complexity
): FormErrors => {
  const externalErrorsKeys = Object.keys(externalErrors);
  let result: FormErrors = {};
  for (const errorKey of externalErrorsKeys) {
    if (formDataMap.has(errorKey)) {
      const [parentKey, childKey] = errorKey.split('/');
      if (!ignoredFields.has(parentKey)) {
        const relatedSchema = formSchema.properties[parentKey];
        const currentValue = getValueForExternalError(formDataMap, errorKey);
        const relatedErrorMessage = getRelatedErrorMessage(currentValue, externalErrors, errorKey);

        if (relatedErrorMessage) {
          if (isFormTypeObject(relatedSchema)) {
            result = addErrorsToObjectValue(result, parentKey, childKey, relatedErrorMessage);
          } else if (isFormTypeArray(relatedSchema)) {
            result = addErrorsToArrayValue(result, parentKey, childKey, relatedErrorMessage);
          } else {
            result[parentKey] = relatedErrorMessage;
          }
        }
      }
    }
  }
  return result;
};
