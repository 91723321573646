import type { IconNameType, VirtualSportIconName } from '@leon-hub/icons';
import { isIconName, isVirtualSportIconName } from '@leon-hub/icons';
import { isString } from '@leon-hub/guards';

const ICON_PREFIX = process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED ? 'slott-sport-' : 'sport-';

export default function getIconNameBySportName(value: unknown): VirtualSportIconName | IconNameType | undefined {
  if (isString(value)) {
    const val = value.toLowerCase();
    const iconName = val.startsWith(ICON_PREFIX) ? val : `${ICON_PREFIX}${val}`;

    if (process.env.VUE_APP_FEATURE_VIRTUAL_SPORT_ENABLED && isVirtualSportIconName(iconName)) {
      return iconName;
    }
    return isIconName(iconName) ? iconName : undefined;
  }

  return undefined;
}
