import { isString } from '@leon-hub/guards';

import type {
  SpintaxSeoMetaData,
  SpintaxSeoMetaDataRouteLocation,
} from 'web/src/modules/seo/types';

interface UseSpintaxSeoMetaDataCacheComposable {
  getCacheForRoute(route: SpintaxSeoMetaDataRouteLocation): Maybe<SpintaxSeoMetaData>;
  setCacheForRoute(route: SpintaxSeoMetaDataRouteLocation, value: Maybe<SpintaxSeoMetaData>): void;
}

function getChacheKeyForRoute(route: SpintaxSeoMetaDataRouteLocation): Maybe<string> {
  const { name } = route;
  const params = route.params || {};
  if (!isString(name)) { return null; }
  return [name, ...Object.keys(params || {}).map((key) => `${key}=${params[key]}`)].join('&');
}

/**
 * @see LEONWEB-10420
 * This logic is not used yet.
 * Will be fully implemented or removed after clear requirements
 */
export function useSpintaxSeoMetaDataCache(): UseSpintaxSeoMetaDataCacheComposable {
  const cache: Dictionary<SpintaxSeoMetaData> = {};

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function isCacheAllowedForRoute(route: SpintaxSeoMetaDataRouteLocation): boolean {
    // for now any cache is disabled
    return false;
  }

  function getCacheForRoute(route: SpintaxSeoMetaDataRouteLocation): Maybe<SpintaxSeoMetaData> {
    if (!isCacheAllowedForRoute(route)) { return null; }

    const key = getChacheKeyForRoute(route);

    if (!key) { return null; }

    return cache[key];
  }

  function clearCacheForKey(key: string): void {
    if (cache[key]) {
      delete cache[key];
    }
  }

  function setCacheForRoute(route: SpintaxSeoMetaDataRouteLocation, value: Maybe<SpintaxSeoMetaData>): void {
    const key = getChacheKeyForRoute(route);

    if (!key) { return; }

    if (!isCacheAllowedForRoute(route) || !value) {
      clearCacheForKey(key);
      return;
    }

    cache[key] = value;
  }

  return {
    getCacheForRoute,
    setCacheForRoute,
  };
}
