import type { InputFileTypeRecords } from 'web/src/components/Input';
import { InputFileTypes } from 'web/src/components/Input/enums';

import { getFileExtension } from './getFileExtension';

export const isFileImage = (allowedTypes: InputFileTypeRecords, file: File): boolean => {
  if (Object.keys(allowedTypes).length === 0) {
    return false;
  }

  const extension = getFileExtension(file);

  if (!extension) {
    return false;
  }

  const fileType = allowedTypes[extension];
  if (!fileType) {
    return false;
  }

  return fileType.type === InputFileTypes.Image;
};
