<script lang="ts" setup>
import { onBeforeUnmount, onMounted } from 'vue';

import { useVCountdown } from 'web/src/components/Countdown/VCountdown/composable';
import { CountdownType } from 'web/src/components/Countdown/VCountdown/enum';

interface VCountdownProps {
  expirationDate?: number;
  title?: string;
  isShortNames?: boolean;
  countdownType?: CountdownType;
}

interface VCountdownEmits {
  (e: 'countdown-end'): void;
  (e: 'countdown-change', timeLabel: string): void;
}

const props = withDefaults(defineProps<VCountdownProps>(), {
  countdownType: CountdownType.DAY_HOURS_MINUTES_SECONDS,
});

const emit = defineEmits<VCountdownEmits>();

const {
  updateNow, clearNow, clearTimer, timeLabel,
} = useVCountdown(props, emit);

onMounted(() => {
  updateNow(true);
});

onBeforeUnmount(() => {
  clearNow();
  clearTimer();
});
</script>

<template>
  {{ title }} {{ timeLabel }}
</template>
