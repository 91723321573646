import type BaseSportsPageNavigationService from './BaseSportsPageNavigationService';
import PrerenderSportsPageNavigationService from './PrerenderSportsPageNavigationService';
import DefaultSportsPageNavigationService from './DefaultSportsPageNavigationService';

let service: BaseSportsPageNavigationService | undefined;

export function useSportsPageNavigationService(): BaseSportsPageNavigationService {
  if (!service) {
    service = process.env.VUE_APP_PRERENDER
      ? new PrerenderSportsPageNavigationService()
      : new DefaultSportsPageNavigationService();
  }

  return service;
}
