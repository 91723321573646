import type {
  NavigationGuardNext,
  RouteLocationNormalized,
} from 'vue-router';

import type { AppVueRouter } from 'web/src/modules/core/services/router/types';
import { AbstractPrefetch } from 'web/src/modules/core/services/router/prefetch';
import { useCmsStore } from 'web/src/modules/cms/store';

export default class CasinoBetgamesPagePrefetch extends AbstractPrefetch {
  // eslint-disable-next-line class-methods-use-this
  async prefetch(
    router: AppVueRouter,
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ): Promise<void> {
    try {
      await useCmsStore().fetchCmsContentTranslation({
        key: 'WEB2_BETGAMES_DESCRIPTION',
        silent: true,
      });
    } catch {
      // empty
    }

    next();
  }
}
