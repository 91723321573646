import { defineStore } from 'pinia';
import {
  computed,
  ref,
  watch,
  toRef,
} from 'vue';

import type { ApiError } from '@leon-hub/api';
import type { EncashBonusData, doActivateBonusCodeQuery } from '@leon-hub/api-sdk';
import {
  accountTypeBetting,
  BonusCategory,
  doActivateBonusCode,
  doEncashBonus,
  getAvailableBonusesForTransfer,
  getBonuses,
  getBonusWagers,
  getEncashBonusData,
  withdrawBonusCBC,
} from '@leon-hub/api-sdk';
import { assert } from '@leon-hub/guards';
import GqlApiBatchedSubRequestError from '@leon-hub/api/src/client/graphql/errors/GqlApiBatchedSubRequestError';
import { logger } from '@leon-hub/logging';

import { BalanceMode } from 'web/src/modules/bonuses/store/enums';
import type {
  ActivateBonusCodeDocument,
  BonusItemSdk,
  LoadingState,
  PaymentBonusArray,
  BonusLoading,
  UpdateBonusDataProperties,
  CustomerWalletFrozenDocuments,
  BonusWagersResponseDocument,
} from 'web/src/modules/bonuses/store/types/types';
import {
  BonusLoadingKey, isBonus,
  isBonusLoadingKey,
} from 'web/src/modules/bonuses/store/types/types';
import { useWebSockets } from 'web/src/modules/core/composables';
import { useWebSocketsConfig } from 'web/src/modules/core/composables/site-config';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import DateTime from 'web/src/utils/DateTime';
import {
  useCustomerBalanceUpdateStore,
} from 'web/src/modules/customer/store';
import type { CbcBalance } from 'web/src/modules/customer/types';
import type { WagerCreatedData } from 'web/src/modules/bonuses/store/types';
import { DEPOSIT_BONUS_DEFAULT_VALUE, DEPOSIT_BONUS_NONE_SELECTED } from 'web/src/modules/payments/constants';
import type { FormExternalErrorsValue } from 'web/src/components/Form/types';

const useBonusStore = defineStore('bonus-store', () => {
  const cbcBalanceFromPolling = toRef(useCustomerBalanceUpdateStore(), 'cbcBalanceFromPolling');

  const max = ref(0);
  const min = ref(0);
  const oneCoin = ref(0);
  const msg = ref('');
  const bonusError = ref<ApiError | null>(null);
  const bonusWalletTransferAmount = ref(0);
  const bonusWalletTotalBonusAmount = ref(0);
  const bonusWalletCurrencyCode = ref('RUB');
  const bonusWalletFrozen = ref(0);
  const activateBonusCodeError = ref<FormExternalErrorsValue>({ message: '', value: '' });
  const activateBonusCodeSuccess = ref<ActivateBonusCodeDocument | null>(null);
  const bonusList = ref<BonusItemSdk[]>([]);
  const isLoading = ref<LoadingState>({
    bonusList: false,
    bonusCBC: false,
  });
  const cbcLastModifiedAt = ref(0);
  const isChoosingBonusBeforeDeposit = ref(false);
  const isPaymentBonusesLoading = ref(false);
  const paymentBonuses = ref<PaymentBonusArray>([]);
  const selectedBonus = ref(DEPOSIT_BONUS_DEFAULT_VALUE);
  const isBonusChooserShownBeforeDeposits = ref(false);
  const wagerCreatedData = ref<WagerCreatedData>();

  const { subscribeAuthorized } = useWebSockets();
  const { isCbcBonusWithdrawalAmountEnabled } = useWebSocketsConfig();
  const { isLoggedIn } = useIsLoggedIn();
  const apiClient = useGraphqlClient();

  const TIMEZONE_SECOND_OFFSET = 60; // (sec)
  const ZONE_OFFSET = DateTime.getTimezoneOffset() * TIMEZONE_SECOND_OFFSET;

  // mutations
  const setIsBonusChooserShownBeforeDeposits = (value: boolean) => {
    isBonusChooserShownBeforeDeposits.value = value;
  };

  const setBonusSelection = (bonus = DEPOSIT_BONUS_NONE_SELECTED) => {
    selectedBonus.value = bonus;
  };
  const setChoosingBonusBeforeDeposit = (value: boolean) => {
    isChoosingBonusBeforeDeposit.value = value;
  };
  const setEncashBonusData = (data: EncashBonusData) => {
    const {
      max: maxData,
      min: minData,
      oneCoin: oneCoinData,
    } = data;
    max.value = maxData || 0;
    min.value = minData || 0;
    oneCoin.value = oneCoinData || 0;
  };
  const updateBonusData = (data: UpdateBonusDataProperties) => {
    const foundIndex = bonusList.value.findIndex((bonus) => bonus.category === data.bonusCategory);
    const foundBonus = bonusList.value[foundIndex];
    if (!foundBonus) {
      return;
    }
    bonusList.value[foundIndex] = {
      ...foundBonus,
      ...data.newProperties,
    };
    bonusList.value = [...bonusList.value];
  };
  const setLoading = ({ key, status }: BonusLoading) => {
    if (isBonusLoadingKey(key)) {
      if (key === BonusLoadingKey.BONUS_LIST) {
        isLoading.value.bonusList = status;
      } else if (key === BonusLoadingKey.BONUS_CBC) {
        isLoading.value.bonusCBC = status;
      }
    } else {
    // eslint-disable-next-line no-console
      console.warn(`Unable to set loading status. Unknown key: ${key}`);
    }
  };
  const setPaymentBonuses = (value: PaymentBonusArray) => {
    paymentBonuses.value = value.filter(
      (item) => item.imgUrl || item?.image?.src,
    );
  };

  // getters
  const isNoneSelected = computed(() => selectedBonus.value === DEPOSIT_BONUS_NONE_SELECTED);

  const bonusCbc = computed(() => {
    const bonus = bonusList.value.find((item) => item.category === BonusCategory.CBC);
    if (!bonus) {
      return undefined;
    }
    assert(isBonus(bonus), 'not bonus type');
    return bonus;
  });

  const requiredAmountNumberCBC = computed(() => {
    if (!bonusCbc.value) {
      return 0;
    }
    assert(isBonus(bonusCbc.value), 'not bonus type');
    const { requiredAmountNumber } = bonusCbc.value;
    return requiredAmountNumber;
  });

  const balanceMode = computed(() => {
    if (!bonusCbc.value || (bonusCbc.value?.progress === 1 && bonusCbc.value?.walletBalanceNumber === 0)) {
      return BalanceMode.Single;
    }
    return BalanceMode.Multiple;
  });

  // actions
  const apiRequestDecorator = ({ key, request }: { key: BonusLoadingKey; request: Function }) => {
    setLoading({ key, status: true });
    return request()
      .catch((error: ApiError) => {
        bonusError.value = error;
      })
      .finally(() => {
        setLoading({ key, status: false });
      });
  };
  const fetchEncashBonusData = async () => {
    if (isLoggedIn.value) {
      const data = await getEncashBonusData(apiClient,
        (node) => node.queries.finance.encashBonusData);
      setEncashBonusData(data);
    }
  };
  const encashBonus = async (amount: number) => {
    const data = await doEncashBonus(apiClient,
      (node) => node.mutations.finance.encashBonus,
      {
        options: {
          amount,
        },
      });
    if (data.result === 'OK') {
      msg.value = data.msg || '';
    }
  };

  const getBonusWalletTransferAmount = async (silent?: boolean) => {
    if (isLoggedIn.value) {
      const result = await getAvailableBonusesForTransfer(apiClient,
        (node) => node.queries.bonuses.getAvailableBonusesForTransfer,
        undefined,
        { silent });
      if (result) {
        bonusWalletTransferAmount.value = result.totalTransferAmount;
        bonusWalletTotalBonusAmount.value = result.totalBonusAmount;
        bonusWalletCurrencyCode.value = result.currencyCode;

        const bonusWalletFrozenData: CustomerWalletFrozenDocuments | undefined = result.frozen.find(
          (item: CustomerWalletFrozenDocuments) => item.type === accountTypeBetting,
        );
        if (bonusWalletFrozenData) {
          bonusWalletFrozen.value = bonusWalletFrozenData.balance;
        }
      }
    }
  };

  const activateBonusCode = async (bonusCode: string) => {
    try {
      activateBonusCodeError.value = { message: '', value: '' };
      activateBonusCodeSuccess.value = null;

      activateBonusCodeSuccess.value = await doActivateBonusCode(apiClient,
        (node: doActivateBonusCodeQuery) => node.mutations.bonuses.joinByBonusCode,
        {
          options: {
            bonusCode,
          },
        });
    } catch (rawError) {
      if (rawError instanceof GqlApiBatchedSubRequestError) {
        activateBonusCodeError.value = { message: rawError.extensions.message ?? '', value: bonusCode };
      } else {
        throw rawError;
      }
    }
  };

  const fetchBonusWagers = async (): Promise<void> => {
    if (isLoggedIn.value) {
      const request = () => getBonusWagers(apiClient,
        (node) => node.queries.bonuses.getBonusWagers,
        { options: { zoneOffset: ZONE_OFFSET } }).then((result: BonusWagersResponseDocument) => {
        if (result) {
          bonusList.value = [...result.bonuses];
        }
      });
      await apiRequestDecorator({ key: BonusLoadingKey.BONUS_LIST, request });
    }
  };

  const doWithdrawBonusCBC = async () => {
    // eslint-disable-next-line unicorn/consistent-function-scoping
    const request = () => withdrawBonusCBC(apiClient, (node) => node.mutations.bonuses.withdrawBonus);
    await apiRequestDecorator({ key: BonusLoadingKey.BONUS_CBC, request });
  };

  const updateCbcBonus = ({ balance, lastModifiedAt }: CbcBalance) => {
    if (lastModifiedAt > cbcLastModifiedAt.value) {
      updateBonusData({
        bonusCategory: BonusCategory.CBC,
        newProperties: { walletBalanceNumber: balance },
      });
      cbcLastModifiedAt.value = lastModifiedAt;
    }
  };

  const getPaymentBonuses = async () => {
    if (isLoggedIn.value) {
      isPaymentBonusesLoading.value = true;
      try {
        const response = await getBonuses(apiClient, (node) => node.queries.bonuses.getBonuses);
        setPaymentBonuses(response.bonuses);
        isBonusChooserShownBeforeDeposits.value = response.isBonusChooserShownBeforeDeposits;
      } catch (error) {
        logger.info(`api.getBonuses: ${error}`);
        setPaymentBonuses([]);
      }
      isPaymentBonusesLoading.value = false;
    }
  };

  const subscribeOnWagerUpdated = () => {
    subscribeAuthorized({
      method: 'onWagerUpdated',
      onMessage: (data) => {
        if (data?.onWagerUpdated?.currentWagerAmount) {
          updateBonusData({
            bonusCategory: BonusCategory.CBC,
            newProperties: {
              amountNumber: data.onWagerUpdated.currentWagerAmount,
              progress: data.onWagerUpdated.currentWagerAmount / requiredAmountNumberCBC.value,
            },
          });
        }
      },
      isEnabled: isCbcBonusWithdrawalAmountEnabled,
    });
  };

  function subscribeOnWagerCreated(): void {
    subscribeAuthorized({
      method: 'onWagerCreated',
      onMessage: (data) => {
        wagerCreatedData.value = {
          wagerSourceWalletType: data.onWagerCreated.wagerSourceWalletType,
          changedAt: Date.now(),
        };

        void fetchBonusWagers();
      },
      isEnabled: isCbcBonusWithdrawalAmountEnabled,
    });
  }

  // on init
  subscribeOnWagerUpdated();
  subscribeOnWagerCreated();

  watch(cbcBalanceFromPolling, (newValue) => {
    updateCbcBonus(newValue);
  }, {
    immediate: true,
    deep: true,
  });

  watch(isLoggedIn, (newValue) => {
    if (!newValue) {
      wagerCreatedData.value = undefined;
      setBonusSelection(DEPOSIT_BONUS_NONE_SELECTED);
    }
  });

  return {
    fetchEncashBonusData,
    encashBonus,
    getBonusWalletTransferAmount,
    fetchBonusWagers,
    activateBonusCode,
    getPaymentBonuses,
    setChoosingBonusBeforeDeposit,
    doWithdrawBonusCBC,
    setBonusSelection,
    setIsBonusChooserShownBeforeDeposits,
    wagerCreatedData,
    min,
    oneCoin,
    activateBonusCodeSuccess,
    activateBonusCodeError,
    paymentBonuses,
    isBonusChooserShownBeforeDeposits,
    isChoosingBonusBeforeDeposit,
    bonusCbc,
    balanceMode,
    selectedBonus,
    isNoneSelected,
  };
});

export default useBonusStore;
