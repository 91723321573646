import type {
  NavigationGuardNext,
  RouteLocationNormalized,
} from 'vue-router';

import { RouteName } from '@leon-hub/routing-config-names';

import { AbstractPrefetch } from 'web/src/modules/core/services/router/prefetch';
import type { AppVueRouter } from 'web/src/modules/core/services/router/types';
import { useSportlineWidgetsStore } from 'web/src/modules/sportline/submodules/widgets/store';
import { useSiteConfigStore } from 'web/src/modules/core/store';

export default class SportradarLiveScoreWidgetPrefetch extends AbstractPrefetch {
  // eslint-disable-next-line class-methods-use-this
  async prefetch(
    router: AppVueRouter,
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ): Promise<void> {
    if (!useSiteConfigStore().isSportradarLiveScoreEnabled) {
      next({ name: RouteName.ERROR_NOT_FOUND_404 });
      return;
    }

    await useSportlineWidgetsStore().fetchSportradarLiveScoreConfig();
    next();
  }
}
