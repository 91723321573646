import { logger } from '@leon-hub/logging';

import type {
  GetHeadlineMatchesResponse,
} from 'web/src/modules/sportline/types/rest';
import { assertUnknownGetHeadlineEventsResponse } from 'web/src/modules/sportline/asserts/rest';
import {
  filterCorruptedEventsInResponse,
  logCorruptedEventsIds,
} from 'web/src/modules/sportline/utils/response';
import useSportlineApi from 'web/src/modules/sportline/services/api/useSportlineApi';
import type { HeadlineMatchesInput } from 'web/src/modules/sportline/services/api/types';

export default async function getHeadlineMatches(
  input: HeadlineMatchesInput,
): Promise<GetHeadlineMatchesResponse> {
  const apiClient = useSportlineApi();
  const response = await apiClient.headlineMatches(input);
  try {
    assertUnknownGetHeadlineEventsResponse(response);
    // it is really GetHeadlineMatchesResponse after filtration
    return {
      ...response,
      events: response.events
        ? filterCorruptedEventsInResponse(response.events, logCorruptedEventsIds)
        : response.events,
      live: response.live
        ? filterCorruptedEventsInResponse(response.live, logCorruptedEventsIds)
        : response.live,
      prematch: response.prematch
        ? filterCorruptedEventsInResponse(response.prematch, logCorruptedEventsIds)
        : response.prematch,
    } as GetHeadlineMatchesResponse;
  } catch (error) {
    logger.error('apiClient getHeadlineMatches response corrupted', error);
    // always return response for user
    return { total: 0, events: { totalCount: 0, events: [] } };
  }
}
