import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import {
  disableCustomerMode,
  enableCustomerMode, getAuthenticatorUriQRCode, resetDisposableKeys,
  getAuthenticatorUri as getAuthenticatorUriApi,
} from '@leon-hub/api-sdk';

import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import { useUserStore } from 'web/src/modules/user/store';

const useTwoFactorAuthenticationStore = defineStore('two-factor-authentication', () => {
  /** state */
  const secret = ref<string>('');
  const reservedKeys = ref<number[]>([]);
  const g2svLink = ref<string>('');
  const qrCode = ref<string>('');
  const currentStepTfa = ref<number>(1);

  const { loadCustomerData } = useUserStore();
  const api = useGraphqlClient();

  /** getters */
  const stringifyReservedKeys = computed<string[]>(() => reservedKeys.value.map(
    (code) => code.toString().replace(/(\d{4})/g, '$1 ').trim(),
  ));

  /** mutations */
  const setSecret = (value: string): void => {
    secret.value = value;
  };

  const setReservedKeys = (value: number[]): void => {
    reservedKeys.value = value;
  };

  const setLink = (value: string): void => {
    g2svLink.value = value;
  };

  const setQRCode = (value: string): void => {
    qrCode.value = value;
  };

  const updateAppData = async (): Promise<void> => {
    await loadCustomerData();
  };

  const enableTFA = async (payload: { secret: string; code: string }): Promise<void> => {
    const response = await enableCustomerMode(api, (node) => node.mutations.g2sv.enableCustomerMode, {
      options: payload,
    });
    const { disposableKeys } = response;
    if (response) {
      setReservedKeys([...disposableKeys]);
    }
  };

  const disableTFA = async (value: string): Promise<void> => {
    const response = await disableCustomerMode(api, (node) => node.mutations.g2sv.disableCustomerMode, {
      options: {
        code: value,
      },
    });
    const { result } = response;
    if (result) {
      await updateAppData();
    }
  };

  const regenerateTFA = async (value: string): Promise<void> => {
    const response = await resetDisposableKeys(api, (node) => node.mutations.g2sv.resetDisposableKeys, {
      options: {
        code: value,
      },
    });
    const { disposableKeys } = response;
    if (response) {
      setReservedKeys([...disposableKeys]);
    }
  };

  const getAuthenticatorUri = async (): Promise<void> => {
    setQRCode('');
    const response = await getAuthenticatorUriApi(
      api,
      (node) => node.queries.g2sv.getAuthenticatorUri,
    );
    const { authenticatorUri, secret: secretResponse } = response;
    setLink(authenticatorUri);
    setSecret(secretResponse);
  };

  const getQRCode = async (options: { imageSize: number; secret: string }): Promise<void> => {
    const response = await getAuthenticatorUriQRCode(api,
      (node) => node.queries.g2sv.getQRCode,
      { options });
    const { qrCode: qrCodeResponse } = response;
    setQRCode(qrCodeResponse);
  };

  const resetReservedKeys = (): void => {
    setReservedKeys([]);
  };

  function setCurrentStepTfaPlus() {
    currentStepTfa.value += 1;
  }

  function setCurrentStepTfaMinus() {
    currentStepTfa.value -= 1;
  }

  function setCurrentStepTfaInit(): void {
    currentStepTfa.value = 1;
  }

  return {
    secret,
    reservedKeys,
    g2svLink,
    qrCode,
    stringifyReservedKeys,
    currentStepTfa,
    setSecret,
    setReservedKeys,
    setLink,
    setQRCode,
    enableTFA,
    disableTFA,
    regenerateTFA,
    getAuthenticatorUri,
    getQRCode,
    resetReservedKeys,
    updateAppData,
    setCurrentStepTfaPlus,
    setCurrentStepTfaMinus,
    setCurrentStepTfaInit,
  };
});

export default useTwoFactorAuthenticationStore;
