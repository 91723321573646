<script setup lang="ts">
import TextInput from '../TextInput';
import type { BonusCodeInputProps, BaseInputEmits } from '../../types';
import { useBaseInputEvents } from '../../composables';
import useBonusCodeInput from './useBonusCodeInput';

const props = withDefaults(defineProps<BonusCodeInputProps>(), {
  name: 'bonus-code',
});
const emits = defineEmits<BaseInputEmits>();

defineOptions({
  inheritAttrs: false,
});

const { isBonusCodeVisible, makeVisible } = useBonusCodeInput(props);

const {
  emitInput,
  emitBlur,
  emitFocus,
  emitChange,
} = useBaseInputEvents(emits);
</script>

<template>
  <div v-auto-id="'BonusCodeInput'" :class="$style['bonus-code-input']">
    <div
      v-if="!isBonusCodeVisible"
      role="button"
      :class="$style['bonus-code-input__wrapper']"
      @click="makeVisible"
    >
      <span :class="$style['bonus-code-input__link']">{{ $t('WEB2_REG_BONUS_CODE_LINK_LABEL') }}</span>
      <span :class="$style['bonus-code-input__link-comment']">({{ $t('JSPACC_REG_BCODE_IFHAVE') }})</span>
    </div>
    <TextInput
      v-if="isBonusCodeVisible"
      :autofocus="autofocus"
      :name="name"
      :value="value"
      :disabled="disabled"
      :placeholder="placeholder"
      :label="label"
      :error="error"
      :error-right="errorRight"
      :hint="hint"
      :hint-right="hintRight"
      :hint-link="hintLink"
      :hint-link-right="hintLinkRight"
      :is-large="isLarge"
      @input="emitInput"
      @change="emitChange"
      @focus="emitFocus"
      @blur="emitBlur"
    />
  </div>
</template>

<style lang="scss" module>
.bonus-code-input {
  &__link {
    z-index: z-index(base);
    padding-right: 3px;
    color: $bonusCodeInputLinkColor;
    text-decoration: $bonusCodeInputLinkDecoration;
    cursor: pointer;
  }

  &__link:hover {
    color: $bonusCodeInputLinkHoverColor;
  }

  &__link-comment {
    color: $bonusCodeInputCommentColor;
  }

  &__wrapper {
    @include bonusCodeInputFont;

    position: relative;
    display: flex;
    align-items: center;
    min-height: 44px;
    cursor: pointer;
  }
}
</style>
