import type { RouteLocationNormalized } from 'vue-router';

import { RouteName } from '@leon-hub/routing-config-names';
import {
  assert,
  isOptionalString,
} from '@leon-hub/guards';

import {
  AllGamesGroupUrl,
} from 'web/src/modules/egs/constants';

export function getLobbyGroupUrlFromRoute(route: Pick<RouteLocationNormalized, 'name' | 'params'>): string | undefined {
  const { groupUrl } = route.params;
  assert(isOptionalString(groupUrl));

  if (process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED && route.name !== RouteName.HOME && !groupUrl) {
    return AllGamesGroupUrl;
  }

  return groupUrl;
}
