<script lang="ts" setup>
import type {
  EgsGameJackpotListItemEmits,
  EgsGameJackpotListItemProps,
} from 'web/src/modules/egs/submodules/jackpots/components/EgsGameJackpotListItem/types';
import VImage from 'web/src/components/Image/VImage/VImage.vue';
import EgsJackpotAnimatedAmount from 'web/src/modules/egs/submodules/jackpots/components/EgsJackpotAnimatedAmount/EgsJackpotAnimatedAmount.vue';
import { ObjectFitOption } from 'web/src/components/Image/VImage/enums';

defineProps<EgsGameJackpotListItemProps>();
const emit = defineEmits<EgsGameJackpotListItemEmits>();
</script>

<template>
  <a v-auto-id="'EgsGameJackpotListItem'"
    v-data-test="{ el: 'egs-game-jackpot-list-item'}"
    :href="href"
    :class="$style['egs-game-jackpot-list-item']"
    @click.prevent="emit('click')"
  >
    <div :class="$style['egs-game-jackpot-list-item__container']">
      <VImage
        v-if="item.images.jackpot"
        v-bind="item.images.jackpot"
        show-loader
        is-lazy
        :object-fit="ObjectFitOption.COVER"
        :class="$style['egs-game-jackpot-list-item__img']"
      />
      <div :class="$style['egs-game-jackpot-list-item__counter-container']">
        <EgsJackpotAnimatedAmount
          v-if="item.jackpot"
          v-bind="item.jackpot.amount"
          has-background
          :jackpot-animation-duration="animationDuration"
        />
      </div>
    </div>
    <div :class="$style['egs-game-jackpot-list-item__label']">
      {{ item.name }}
    </div>
  </a>
</template>

<style lang="scss" module>
@include for-layout using($isDesktop) {
  .egs-game-jackpot-list-item {
    $self: &;

    display: flex;
    flex-direction: column;
    gap: 8px;
    width: if($isDesktop, 320px, 307px);
    text-decoration: none;
    cursor: pointer;

    &__container {
      position: relative;
      width: 100%;
      height: if($isDesktop, 160px, 153.5px);
      overflow: hidden;
      border-radius: 5px;
    }

    &__counter-container {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      text-align: center;
    }

    &__label {
      @include medium\14\16\025;
      @include lineLimit(1);

      color: var(--TextDefault);
      text-align: center;
    }

    &__img {
      user-select: none;
      -webkit-user-drag: none;
      transition: transform 0.5s ease;
      transform: scale(1);
    }

    &:hover {
      .egs-game-jackpot-list-item__label {
        color: var(--BrandDefault);
      }

      .egs-game-jackpot-list-item__img {
        transition: transform 0.5s ease;
        transform: scale(1.05);
      }
    }
  }
}
</style>
