import type { AppVueRouter } from 'web/src/modules/core/services/router/types';
import { useSnackbarsStore } from 'web/src/modules/snackbars/store';

export default function createSnackBarNavigationGuard(router: AppVueRouter): void {
  const { setConnectionLostRoute } = useSnackbarsStore();

  router.beforeEach((to) => {
    setConnectionLostRoute(to.fullPath);
  });

  router.afterEach(() => {
    setConnectionLostRoute('');
  });
}
