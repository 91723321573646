import type { Ref } from 'vue';
import {
  toRef,
} from 'vue';

import type { VSnackBarProps } from 'web/src/components/SnackBar/VSnackBar/types';
import { useSnackbarsStore } from 'web/src/modules/snackbars/store';
import { CONNECTION_LOST_SNACKBAR_ID } from 'web/src/modules/snackbars/constants';

export interface UseSnackbarHolderComposable {
  className: Ref<string[]>;
  hasSnackbars: Ref<boolean>;
  snackbarProps: Ref<VSnackBarProps>;
  snackbarId: Ref<string | undefined>;
  onAccept(): Promise<void>;
  onCancel(): Promise<void>;
}

export default function useSnackbarHolder(): UseSnackbarHolderComposable {
  const snackbarsStore = useSnackbarsStore();
  const snackbars = toRef(() => snackbarsStore.snackbars);
  const snackbar = toRef(() => snackbars.value[0]);
  const hasSnackbars = toRef(() => snackbars.value.length > 0);
  const snackbarProps = toRef<() => VSnackBarProps>(() => {
    const src = snackbar.value?.image;
    return ({
      type: snackbar.value?.type,
      title: snackbar.value?.title,
      text: snackbar.value?.text || '',
      buttonLabel: !snackbar.value?.accept?.isDone ? snackbar.value?.accept?.label : undefined,
      isDone: snackbar.value?.accept?.isDone,
      iconName: snackbar.value?.iconName,
      hasClose: !!snackbar.value?.cancel,
      duration: snackbar.value?.duration,
      isMaxZIndex: snackbar.value?.id === CONNECTION_LOST_SNACKBAR_ID,
      image: src ? { src } : undefined,
    });
  });
  const snackbarId = toRef(() => snackbar.value?.id);
  const className = toRef(() => {
    const baseClass = 'snackbar';
    const classNames = [baseClass];

    if (snackbarId.value) {
      classNames.push(`${baseClass}--${snackbarId.value}`);
    }

    return classNames;
  });

  function onClose(): Promise<void> {
    return snackbarsStore.hide(snackbar.value?.id);
  }

  async function onAccept(): Promise<void> {
    await snackbar.value?.accept?.action?.();
    await onClose();
  }

  async function onCancel(): Promise<void> {
    await snackbar.value?.cancel?.action?.();
    await onClose();
  }

  return {
    className,
    hasSnackbars,
    snackbarProps,
    snackbarId,
    onAccept,
    onCancel,
  };
}
