import type { Plugin } from 'vue';

export const EnvironmentPlugin: Plugin = {
  install(app): void {
    app.mixin({
      computed: {
        process: () => ({
          env: process.env,
        }),
      },
    });
  },
};
