import { defineStore } from 'pinia';
import { computed, toRef } from 'vue';

import { useSiteConfigStore } from 'web/src/modules/core/store';

import {
  useSportradarLiveScoreFrameConfigStoreComposable,
  useSportradarStatisticsConfigStoreComposable,
  useSportradarLiveMatchTrackerConfigStoreComposable,
  useLSportsConfig,
  useBetgeniusConfig,
} from './composable';

export const useSportlineWidgetsStore = defineStore('sportline-widgets', () => {
  const siteConfigStore = useSiteConfigStore();
  const widgetsBlock = toRef(() => siteConfigStore.widgetsBlock);

  const iframeUrl = computed(() => widgetsBlock.value?.iframeUrl);

  const {
    fetchConfig: fetchSportradarLiveScoreConfig,
    configFinal: sportradarLiveScoreFrameConfigFinal,
  } = useSportradarLiveScoreFrameConfigStoreComposable({ iframeUrl });

  const {
    fetchConfig: fetchSportradarStatisticsConfig,
    configFinal: sportradarStatisticsConfigFinal,
  } = useSportradarStatisticsConfigStoreComposable({ iframeUrl });

  const {
    fetchConfig: fetchSportradarLiveMatchTracker,
    config: sportradarLiveMatchTrackerConfig,
    configFinal: sportradarLiveMatchTrackerConfigFinal,
  } = useSportradarLiveMatchTrackerConfigStoreComposable({ iframeUrl });

  const {
    fetchConfig: fetchLSportsWidgetConfig,
    config: lsportsConfig,
    configFinal: lsportsConfigFinal,
    getIsActiveWidgetRef: isActiveLSportWidgetSport,
  } = useLSportsConfig({ iframeUrl });

  const {
    fetchConfig: fetchBetgeniusWidgetConfig,
    config: betgeniusConfig,
    configFinal: betgeniusConfigFinal,
  } = useBetgeniusConfig({ iframeUrl });

  async function fetchSportlineConfigs(): Promise<void> {
    const promises: Promise<void>[] = [];

    if (!sportradarLiveMatchTrackerConfig.value) {
      promises.push(fetchSportradarLiveMatchTracker());
    }

    if (!lsportsConfig.value) {
      promises.push(fetchLSportsWidgetConfig());
    }

    if (!betgeniusConfig.value) {
      promises.push(fetchBetgeniusWidgetConfig());
    }

    await Promise.all(promises);
  }

  return {
    fetchSportlineConfigs,
    fetchSportradarStatisticsConfig,
    fetchSportradarLiveScoreConfig,
    isActiveLSportWidgetSport,
    sportradarLiveScoreFrameConfigFinal,
    sportradarStatisticsConfigFinal,
    betgeniusConfigFinal,
    sportradarLiveMatchTrackerConfigFinal,
    lsportsConfigFinal,
  };
});
