import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import {
  LobbyGridType,
  LobbyItemType,
  LobbyItemWidget,
} from '@leon-hub/api-sdk';
import { assert } from '@leon-hub/guards';

import type { LobbyGridEmits } from 'web/src/modules/lobby/components/LobbyGrid/types';
import type {
  LobbyGridBaseProps,
  LobbyItemClickData,
} from 'web/src/modules/lobby/types';
import type { LobbyItemProps } from 'web/src/modules/lobby/components/LobbyItem/types';
import {
  LobbyGridLayoutType,
  LobbyItemLayoutType,
} from 'web/src/modules/lobby/enums';
import {
  isEgsGame,
  isEgsGroup,
  isPromotion,
} from 'web/src/modules/lobby/utils';
import type LobbyGridItem from 'web/src/modules/lobby/types/LobbyGridItem';

import {
  getModalGamesLobbyGridType,
  getNoBackgroundGamesLobbyGridType,
} from './utils';

export interface LobbyGridComposable {
  getItemKey: (item: LobbyGridItem, index: number) => string;
  gridType: ComputedRef<LobbyGridLayoutType>;
  getLobbyItemProps: (item: LobbyGridItem, index: number) => LobbyItemProps;
  emitClickLobbyItem: (data: LobbyItemClickData) => void;
}

export default function useLobbyGrid(
  props: LobbyGridBaseProps,
  emits: LobbyGridEmits,
): LobbyGridComposable {
  const bigSmallRowsGamesGridType = computed<LobbyGridLayoutType>(() => {
    if (process.env.VUE_APP_LAYOUT_DESKTOP) {
      return props.isModal
        ? LobbyGridLayoutType.BIG_SMALL_SMALL
        : LobbyGridLayoutType.BIG_SMALL_LARGE;
    }

    return LobbyGridLayoutType.BIG_SMALL_SMALL;
  });

  const jackpotWinnerGridType = computed<LobbyGridLayoutType>(() => {
    if (process.env.VUE_APP_LAYOUT_DESKTOP) {
      return props.isModal
        ? LobbyGridLayoutType.COLUMN_1
        : LobbyGridLayoutType.COLUMN_JACKPOT;
    }

    return LobbyGridLayoutType.COLUMN_JACKPOT;
  });

  const squareGamesGridType = computed<LobbyGridLayoutType>(() => {
    if (process.env.VUE_APP_LAYOUT_DESKTOP) {
      const modalGridType = getModalGamesLobbyGridType(props);

      if (modalGridType) {
        return modalGridType;
      }
    }

    if (props.lobbyGridType === LobbyGridType.SMALL) {
      return LobbyGridLayoutType.COLUMN_SQUARE_GAME_SMALL;
    }

    if (props.lobbyGridType === LobbyGridType.LARGE) {
      return LobbyGridLayoutType.COLUMN_SQUARE_GAME_LARGE;
    }

    return LobbyGridLayoutType.COLUMN_SQUARE_GAME_DEFAULT;
  });

  const defaultGamesGridType = computed<LobbyGridLayoutType>(() => {
    if (process.env.VUE_APP_LAYOUT_DESKTOP) {
      const modalGridType = getModalGamesLobbyGridType(props);

      if (modalGridType) {
        return modalGridType;
      }
    }

    const noBackgroundGridType = getNoBackgroundGamesLobbyGridType(props);
    if (noBackgroundGridType) {
      return noBackgroundGridType;
    }

    if (props.lobbyGridType === LobbyGridType.SMALL) {
      return LobbyGridLayoutType.COLUMN_GAME_SMALL;
    }

    if (props.lobbyGridType === LobbyGridType.LARGE) {
      return LobbyGridLayoutType.COLUMN_GAME_LARGE;
    }

    return LobbyGridLayoutType.COLUMN_GAME_DEFAULT;
  });

  const gamesGridType = computed<LobbyGridLayoutType>(() => {
    switch (props.widgetId) {
      case LobbyItemWidget.BIG_SMALL_ROWS_LIST:
        return bigSmallRowsGamesGridType.value;
      case LobbyItemWidget.SQUARE_ROWS_LIST:
        return LobbyGridLayoutType.SQUARE_ROW;
      case LobbyItemWidget.JACKPOT_LIST:
      case LobbyItemWidget.JACKPOT_SWIPE_LIST:
        return jackpotWinnerGridType.value;
      case LobbyItemWidget.SQUARE_LIST:
      case LobbyItemWidget.SQUARE_SWIPE_LIST:
        return squareGamesGridType.value;
      case LobbyItemWidget.LIST:
      case LobbyItemWidget.SWIPE_LIST:
      default:
        return defaultGamesGridType.value;
    }
  });

  const promoGridType = computed<LobbyGridLayoutType>(() => {
    if (process.env.VUE_APP_LAYOUT_DESKTOP) {
      return props.isModal
        ? LobbyGridLayoutType.COLUMN_1
        : LobbyGridLayoutType.COLUMN_PROMOTION;
    }

    return LobbyGridLayoutType.COLUMN_PROMOTION;
  });

  const bannerGridType = computed<LobbyGridLayoutType>(() => {
    if (process.env.VUE_APP_LAYOUT_DESKTOP) {
      return props.isModal
        ? LobbyGridLayoutType.COLUMN_1
        : LobbyGridLayoutType.COLUMN_2;
    }

    return LobbyGridLayoutType.COLUMN_BANNER;
  });

  const groupGridType = computed<LobbyGridLayoutType>(() => {
    if (process.env.VUE_APP_LAYOUT_DESKTOP) {
      return props.isModal
        ? LobbyGridLayoutType.COLUMN_3
        : LobbyGridLayoutType.COLUMN_GROUP;
    }

    return LobbyGridLayoutType.COLUMN_GROUP;
  });

  const topWinnerGridType = computed<LobbyGridLayoutType>(() => {
    if (process.env.VUE_APP_LAYOUT_DESKTOP) {
      return props.isModal
        ? LobbyGridLayoutType.COLUMN_1
        : LobbyGridLayoutType.COLUMN_TOP_WINNER;
    }

    return LobbyGridLayoutType.COLUMN_TOP_WINNER;
  });

  const sportlineTopMatchGridType = computed<LobbyGridLayoutType>(() => {
    if (process.env.VUE_APP_LAYOUT_DESKTOP) {
      return props.isModal
        ? LobbyGridLayoutType.COLUMN_1
        : LobbyGridLayoutType.COLUMN_SPORTLINE_TOP_MATCH;
    }

    return LobbyGridLayoutType.COLUMN_SPORTLINE_TOP_MATCH;
  });

  const gridType = computed<LobbyGridLayoutType>(() => {
    switch (props.lobbyItemType) {
      case LobbyItemType.PROMOTIONS:
        return promoGridType.value;
      case LobbyItemType.BANNERS:
        return bannerGridType.value;
      case LobbyItemType.SPORTLINE_TOP_MATCHES:
        return sportlineTopMatchGridType.value;
      case LobbyItemType.TOP_WINNERS:
        return topWinnerGridType.value;
      case LobbyItemType.JACKPOTS:
        return jackpotWinnerGridType.value;
      case LobbyItemType.GROUPS:
        return groupGridType.value;
      case LobbyItemType.GAMES_CATEGORY:
      default:
        return gamesGridType.value;
    }
  });

  function getGamesLobbyItemLayoutType(index: number): LobbyItemLayoutType {
    switch (gridType.value) {
      case LobbyGridLayoutType.BIG_SMALL_LARGE:
        return index < 2
          ? LobbyItemLayoutType.GAME_BIG_ROW
          : LobbyItemLayoutType.GAME_SQUARE;
      case LobbyGridLayoutType.BIG_SMALL_SMALL:
        return index % 3 === 0
          ? LobbyItemLayoutType.GAME_BIG_ROW
          : LobbyItemLayoutType.GAME_SQUARE;
      case LobbyGridLayoutType.SQUARE_ROW:
      case LobbyGridLayoutType.COLUMN_SQUARE_GAME_DEFAULT:
      case LobbyGridLayoutType.COLUMN_SQUARE_GAME_SMALL:
      case LobbyGridLayoutType.COLUMN_SQUARE_GAME_LARGE:
      case LobbyGridLayoutType.COLUMN_MODAL_GAME_SQUARE_SMALL:
      case LobbyGridLayoutType.COLUMN_NO_BG_GAME_SQUARE_SMALL:
        return LobbyItemLayoutType.GAME_SQUARE;
      case LobbyGridLayoutType.COLUMN_JACKPOT:
        return LobbyItemLayoutType.GAME_JACKPOT;
      default:
        return LobbyItemLayoutType.GAME_DEFAULT;
    }
  }

  function getItemKey(item: LobbyGridItem, index: number): string {
    if (isEgsGame(item, props.lobbyItemType) || isEgsGroup(item, props.lobbyItemType)) {
      return item.id;
    }

    if (isPromotion(item, props.lobbyItemType)) {
      return item.url;
    }

    return String(index);
  }

  function getLobbyItemProps(item: LobbyGridItem, index: number): LobbyItemProps {
    return {
      item,
      lobbyItemType: props.lobbyItemType,
      widgetId: props.widgetId,
      layoutType: getLobbyItemLayoutType(index),
      jackpotAnimationDuration: props.jackpotAnimationDuration,
      isNameVisible: props.isItemNameVisible,
      isEditMode: props.isEditMode,
      isRemoved: isItemRemoved(item),
    };
  }

  function isItemRemoved(item?: LobbyGridItem): boolean {
    if (!item) {
      return false;
    }

    if (props.lobbyItemType === LobbyItemType.GAMES_CATEGORY) {
      assert(isEgsGame(item, props.lobbyItemType));

      return props.gameIdsToRemove ? props.gameIdsToRemove.includes(item.id) : false;
    }

    return false;
  }

  function getLobbyItemLayoutType(index: number): LobbyItemLayoutType {
    switch (props.lobbyItemType) {
      case LobbyItemType.GAMES_CATEGORY:
        return getGamesLobbyItemLayoutType(index);
      case LobbyItemType.PROMOTIONS:
        return LobbyItemLayoutType.PROMOTION;
      case LobbyItemType.BANNERS:
        return LobbyItemLayoutType.BANNER;
      case LobbyItemType.SPORTLINE_TOP_MATCHES:
        return LobbyItemLayoutType.SPORTLINE_TOP_MATCH;
      case LobbyItemType.GROUPS:
        return LobbyItemLayoutType.GROUP;
      case LobbyItemType.TOP_WINNERS:
        return props.widgetId === LobbyItemWidget.SWIPE_LIST
          ? LobbyItemLayoutType.TOP_WINNER_SMALL
          : LobbyItemLayoutType.TOP_WINNER_BIG;
      default:
        return LobbyItemLayoutType.GAME_DEFAULT;
    }
  }

  function emitClickLobbyItem(data: LobbyItemClickData): void {
    emits('click-lobby-item', data);
  }

  return {
    getItemKey,
    gridType,
    getLobbyItemProps,
    emitClickLobbyItem,
  };
}
