import {
  isOptionalString,
  isObject,
  isOptionalArray,
  isOptionalNumber,
  isUndefined,
} from '@leon-hub/guards';

import type { FileMultipleInputProps } from '../types';

export default function isFileMultipleInputProps(value: unknown): value is FileMultipleInputProps {
  if (!isObject(value)) {
    return false;
  }
  return isOptionalString(value.name)
    && isOptionalString(value.label)
    && isOptionalString(value.placeholder)
    && isOptionalArray(value.error)
    && isOptionalArray(value.errorRight)
    && isOptionalNumber(value.max)
    && (isUndefined(value.fileTypes) || isObject(value.fileTypes));
}
