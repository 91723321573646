export default class RegistrationRouterParametersHolder {
  private static inputEmail?: string;

  static setInputEmail(method: string | undefined): void {
    RegistrationRouterParametersHolder.inputEmail = method;
  }

  static getInputEmail(): string | undefined {
    return RegistrationRouterParametersHolder.inputEmail;
  }
}
