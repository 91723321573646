import type { DirectiveBinding } from 'vue';

import useTimeBus from 'web/src/modules/core/composables/time-bus/useTimeBus';

type UpdateTimeMethod = (dateNow: number) => void;

type Unsubscribe = () => void;

interface UseLiveProgressTimerControllerProps {
  updateEvery?: number;
  isBindingChanged(value: DirectiveBinding): boolean;
  updated(element: HTMLElement, binding: DirectiveBinding, passedTime: number): void;
}

interface UseLiveProgressTimerControllerComposable {
  getSubscriptionsCount(): number;
  hasSubscriptions(): boolean;
  beforeElementMounted(element: HTMLElement, binding: DirectiveBinding): void;
  elementUpdated(element: HTMLElement, binding: DirectiveBinding): void;
  elementUnmounted(element: HTMLElement): void;
}

/**
 * Composable to create directive
 */
export function useLiveProgressTimerController(
  props: UseLiveProgressTimerControllerProps,
): UseLiveProgressTimerControllerComposable {
  const { updateEvery, updated, isBindingChanged } = props;

  const {
    now,
    start,
    stop,
    subscribe,
    unsubscribe,
    getSubscriptionsCount,
  } = useTimeBus(updateEvery ?? 1000);
  const subscriptionMap = new WeakMap();

  function hasSubscriptions(): boolean {
    return getSubscriptionsCount() > 0;
  }

  function remove(callback: UpdateTimeMethod): void {
    unsubscribe(callback);
    const isLastSubscription = !hasSubscriptions();
    if (isLastSubscription) { stop(); }
  }

  function add(callback: UpdateTimeMethod): Unsubscribe {
    const isFirstSubscription = !hasSubscriptions();
    subscribe(callback);
    if (isFirstSubscription) { start(); }
    return () => { remove(callback); };
  }

  function setSubscription(element: HTMLElement, binding: DirectiveBinding): void {
    let unsubscribeForElement = subscriptionMap.get(element);
    unsubscribeForElement?.();
    const createdAt = now.value;
    unsubscribeForElement = add((updatedAt: number) => updated(element, binding, updatedAt - createdAt));
    subscriptionMap.set(element, unsubscribeForElement);
  }

  return {
    getSubscriptionsCount,
    hasSubscriptions,
    beforeElementMounted(element: HTMLElement, binding: DirectiveBinding): void {
      updated(element, binding, 0);
      setSubscription(element, binding);
    },
    elementUpdated(element: HTMLElement, binding: DirectiveBinding): void {
      if (!isBindingChanged(binding)) { return; }
      updated(element, binding, 0);
      setSubscription(element, binding);
    },
    elementUnmounted(element: HTMLElement): void {
      const unsubscribeForElement = subscriptionMap.get(element);
      unsubscribeForElement?.();
      subscriptionMap.delete(element);
    },
  };
}
