<script lang="ts" setup>
import { ObserveVisibility } from 'vue3-observe-visibility';

import { useVSightObserver } from './composables';

const {
  isVisible,
  observeVisibilityOptions,
} = useVSightObserver();

const vObserveVisibility = ObserveVisibility;

defineSlots<{
  default(props: { isVisible: boolean }): void;
}>();
</script>

<template>
  <div v-auto-id="'VSightObserver'" v-observe-visibility="observeVisibilityOptions">
    <slot :is-visible="isVisible" />
  </div>
</template>
