import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import { useI18n } from 'web/src/modules/i18n/composables';

import { TopLevelTabs } from '../../../enums';
import getSlipEventCountLabel from '../../../utils/getSlipEventCountLabel';
import type { BetSlipTabsProps, BetSlipTabsItem } from '../types';

interface UseBetSlipTabs {
  tabsItems: ComputedRef<BetSlipTabsItem[]>;
}

export default function useBetSlipTabs(props: BetSlipTabsProps): UseBetSlipTabs {
  const { $translate } = useI18n();

  const tabsItems = computed<BetSlipTabsItem[]>(() => {
    const tabs: BetSlipTabsItem[] = [
      {
        id: TopLevelTabs.SLIP,
        label: $translate('TABS_BETSLIP').value,
        count: getSlipEventCountLabel(props.slipSize),
        isActive: props.activeTabId === TopLevelTabs.SLIP,
      },
    ];
    if (props.isLogged) {
      tabs.push({
        id: TopLevelTabs.MY_BETS,
        label: $translate('WEB2_SLIP_MY_BETS').value,
        count: getSlipEventCountLabel(props.myBetsCount),
        isActive: props.activeTabId === TopLevelTabs.MY_BETS,
      });
    }
    return tabs;
  });

  return {
    tabsItems,
  };
}
