import { ref, computed, toRef } from 'vue';
import { defineStore } from 'pinia';

import { Timer } from '@leon-hub/utils';

import { useSiteConfigStore } from 'web/src/modules/core/store';

import type { KeyBoardInputPayload, SafeSetStakeValuePayload } from '../types';
import { KeyboardInputType } from '../../../enums';
import {
  getSafeStakeValueString,
  getSafeStakeValue,
  getModifiedStakeInputValue,
  getKeyboardPayloadFromEvent,
} from '../utils';

const useStakeInputStore = defineStore('stake-input-store', () => {
  const stakeInputValue = ref<string>('0');

  /** to clear value on manual input start */
  const stakeValueIsPredefined = ref<boolean>(true);

  /** needs for metrika */
  const selectedFastMoneyValue = ref<number | null>(null);

  const isStakeInputFocused = ref<boolean>(false);

  const sportLineBlock = toRef(useSiteConfigStore(), 'sportLineBlock');

  const isReadyToDisplayErrors = ref<boolean>(true);

  const stakeInputValidationTimeout = computed<number>(() => sportLineBlock.value?.stakeInputValidationTimeout || 2000);

  let readyToDisplayInputErrorsTimeoutId = 0;

  const handleStakeInputEdited = (timeout?: number): void => {
    isReadyToDisplayErrors.value = false;
    Timer.clearTimeout(readyToDisplayInputErrorsTimeoutId);
    readyToDisplayInputErrorsTimeoutId = Timer.setTimeout(() => {
      isReadyToDisplayErrors.value = true;
    }, timeout || stakeInputValidationTimeout.value);
  };

  const setStakeInputValue = (value = '0'): void => {
    stakeInputValue.value = value;
  };

  const setStakeValueIsPredefined = (isPredefined: boolean): void => {
    stakeValueIsPredefined.value = isPredefined;
  };

  const setSelectedFastMoneyValue = (value: number | null): void => {
    selectedFastMoneyValue.value = value;
  };

  const setStakeInputIsFocused = (isFocused: boolean): void => {
    isStakeInputFocused.value = isFocused;
  };

  const stakeInputFocus = (): void => {
    handleStakeInputEdited();
    setStakeInputIsFocused(true);
    if (stakeValueIsPredefined.value) {
      setStakeInputValue('');
      setSelectedFastMoneyValue(null);
    }
  };

  const stakeInputBlur = (): void => {
    setStakeInputIsFocused(false);
    if (!isReadyToDisplayErrors.value) {
      handleStakeInputEdited(200);
    }
    if (!stakeInputValue.value) {
      setStakeValueIsPredefined(true);
      setStakeInputValue('0');
      setSelectedFastMoneyValue(null);
    }
  };

  const safeSetStakeValue = (payload: SafeSetStakeValuePayload): void => {
    const safeValue = getSafeStakeValueString(payload);
    setStakeInputValue(safeValue);
    setStakeValueIsPredefined(true);
    if (selectedFastMoneyValue.value && selectedFastMoneyValue.value !== Number(safeValue)) {
      // selected fastMoney value was overwritten on fixing stake
      setSelectedFastMoneyValue(null);
    }
  };

  const handleStakeInput = (value: string): void => {
    handleStakeInputEdited();
    setStakeValueIsPredefined(false);
    setStakeInputValue(value);
    setSelectedFastMoneyValue(null);
  };

  const handleStakeKeyboardInput = (payload: KeyBoardInputPayload): void => {
    const inputValue = getModifiedStakeInputValue(stakeInputValue.value, payload);
    handleStakeInput(inputValue);
  };

  const fixStakeRange = (payload: {
    maxStake: number | null;
    minStake: number | null;
    stakeBelowMinimum: boolean;
  }): void => {
    const { maxStake, minStake, stakeBelowMinimum } = payload;
    if (stakeBelowMinimum && minStake) {
      setStakeInputValue(`${minStake}`);
    } else if (maxStake) {
      setStakeInputValue(`${maxStake}`);
    }
    setSelectedFastMoneyValue(null);
    setStakeValueIsPredefined(true);
  };

  const selectFastBet = (value: number): void => {
    setSelectedFastMoneyValue(value);
    setStakeValueIsPredefined(true);
    setStakeInputValue(`${value}`);
  };

  const setValueFromFreebet = (freeBetAmount: string): void => {
    setStakeInputValue(freeBetAmount);
    setSelectedFastMoneyValue(null);
  };

  const safeStakeValue = computed<number>(() => getSafeStakeValue(stakeInputValue.value));

  const isMobileKeyboardShown = computed<boolean>(() => {
    if (process.env.VUE_APP_LAYOUT_DESKTOP) {
      return false;
    }
    return isStakeInputFocused.value;
  });

  const hideKeyboard = (): void => {
    isReadyToDisplayErrors.value = true;
    stakeInputBlur();
  };

  const handleKeyboardStakeInput = (event: KeyboardEvent): void => {
    const payload = getKeyboardPayloadFromEvent(event, isStakeInputFocused.value, stakeValueIsPredefined.value);
    if (payload) {
      handleStakeKeyboardInput(payload);
    }
  };

  const backspace = (): void => {
    handleStakeKeyboardInput({ action: KeyboardInputType.BACKSPACE });
  };

  const numberInput = (value: string): void => {
    handleStakeKeyboardInput({
      action: KeyboardInputType.NUMBER,
      value,
      removeCurrentValue: stakeValueIsPredefined.value,
    });
  };

  const decimalSeparatorClick = (): void => {
    handleStakeKeyboardInput({
      action: KeyboardInputType.DECIMAL,
      removeCurrentValue: stakeValueIsPredefined.value,
    });
  };

  return {
    stakeInputValue,
    safeStakeValue,
    selectedFastMoneyValue,
    isStakeInputFocused,
    isMobileKeyboardShown,
    isReadyToDisplayErrors,
    stakeInputValidationTimeout,
    stakeInputFocus,
    stakeInputBlur,
    safeSetStakeValue,
    handleStakeInput,
    fixStakeRange,
    selectFastBet,
    setStakeInputIsFocused,
    setSelectedFastMoneyValue,
    setValueFromFreebet,
    hideKeyboard,
    handleKeyboardStakeInput,
    backspace,
    numberInput,
    decimalSeparatorClick,
  };
});

export default useStakeInputStore;
