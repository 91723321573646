import { defineStore } from 'pinia';
import {
  computed,
  toRef,
} from 'vue';

import type { NavigationItem } from '@leon-hub/navigation-config';
import { NavigationGroup } from '@leon-hub/navigation-config';
import { RouteName } from '@leon-hub/routing-config-names';

import { useCustomerDataStore } from 'web/src/modules/customer/store';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useUserStore } from 'web/src/modules/user/store';
import { useFilterNavigationItems } from 'web/src/modules/core/store/composables';
import useVerification from 'web/src/modules/profile/submodules/verification/composables/useVerification';
import { useAchievementsStore } from 'web/src/modules/profile/submodules/achievements/store';

const useUserNavigationStore = defineStore('user-navigation', () => {
  const siteConfigStore = useSiteConfigStore();
  const achievementStore = useAchievementsStore();

  const isSubscriptionBlockEnabled = toRef(siteConfigStore, 'isSubscriptionBlockEnabled');
  const isAchievementEnabled = toRef(() => siteConfigStore.isAchievementEnabled);
  const isAchievementAvailable = toRef(() => achievementStore.availability);

  const customerDataStore = useCustomerDataStore();
  const { isVerificationNavigationItemEnabled } = useVerification();
  const g2svFeatureStatus = toRef(customerDataStore, 'g2svFeatureStatus');

  const userStore = useUserStore();
  const isBonusTermsAccepted = toRef(userStore, 'isBonusTermsAccepted');

  const { getNavigationItems } = useFilterNavigationItems();

  function filterProfileItems(items: readonly NavigationItem[]): NavigationItem[] {
    return items.filter((item) => {
      if (item.props?.checkAchievementsAvailability && (!isAchievementEnabled.value || !isAchievementAvailable.value?.available)) {
        return false;
      }
      if (item.props?.isLeonShop && !isBonusTermsAccepted.value) {
        return false;
      }

      switch (item.routeName) {
        case RouteName.CUSTOMER_VERIFICATION:
          return isVerificationNavigationItemEnabled.value;
        case RouteName.TWO_FACTOR_AUTHENTICATION:
          return g2svFeatureStatus.value;
        case RouteName.CUSTOMER_SUBSCRIPTIONS:
          return isSubscriptionBlockEnabled.value;
        default:
          return true;
      }
    });
  }

  function getFilteredUserProfileNavigationWithChildren(group: NavigationGroup): readonly NavigationItem[] {
    return getNavigationItems(group)
      .filter((item) => item.children && item.children.length > 0)
      .map((item) => ({
        ...item,
        children: filterProfileItems(item?.children ?? []),
      }));
  }

  const profileSidebarNavigation = computed<readonly NavigationItem[]>(() => (
    filterProfileItems(getNavigationItems(NavigationGroup.PROFILE_NAVIGATION))
  ));

  const userPhoneProfileNavigation = computed<readonly NavigationItem[]>(
    () => getFilteredUserProfileNavigationWithChildren(NavigationGroup.PROFILE_NAVIGATION),
  );

  const userProfileSettingsNavigation = computed<readonly NavigationItem[]>(
    () => getFilteredUserProfileNavigationWithChildren(NavigationGroup.PROFILE_SETTINGS_NAVIGATION),
  );

  if (process.env.VUE_APP_FEATURE_ACHIEVEMENTS_ENABLED) {
    void achievementStore.fetchAvailability();
  }

  return {
    profileSidebarNavigation,
    userPhoneProfileNavigation,
    userProfileSettingsNavigation,
  };
});

export default useUserNavigationStore;
