import type { Ref } from 'vue';
import type { RouteLocationNormalized, NavigationGuardNext, RouteLocationRaw } from 'vue-router';
import { toRef, computed } from 'vue';

import { RouteName } from '@leon-hub/routing-config-names';
import { isString } from '@leon-hub/guards';

import { useSportlineNavigationStore } from 'web/src/modules/sportline/submodules/navigation/store';
import { useIsDevIP } from 'web/src/modules/core/composables/root';
import useLeaguePageStore from 'web/src/modules/sportline/submodules/league/store/useLeaguePageStore';
import { useSportlineBreadcrumbsStore } from 'web/src/modules/sportline/submodules/breadcrumbs/store';
import type { AppVueRouter } from 'web/src/modules/core/services/router/types';
import type { ConfigForLeagueLoading } from 'web/src/modules/sportline/submodules/league/types';
import { isValidSportEventsPageIdentifier } from 'web/src/modules/sportline/guards';
import { getSportPageNavigationParameters } from 'web/src/modules/sportline/utils';
import { resolveLeaguePageLink } from 'web/src/modules/sportline/submodules/navigation/store/utils';

export interface UseBaseLeaguePageNavigationGuardComposable {
  loadLeagueConfig: Ref<ConfigForLeagueLoading>;
  clearLoadLeagueConfig(): void;
  prefetch(
    router: AppVueRouter,
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ): void;
  getRedirectLocation(router: AppVueRouter, currentLocation: RouteLocationNormalized): Maybe<RouteLocationRaw>;
}

export function useBaseLeaguePageNavigationGuard(): UseBaseLeaguePageNavigationGuardComposable {
  const { isDevIP } = useIsDevIP();

  const navigationStore = useSportlineNavigationStore();
  const filter = toRef(navigationStore, 'sportlineNavigationBetweenPagesFilter');
  const { setSportlineNavigationBetweenPagesFilter } = navigationStore;

  const leaguePageStore = useLeaguePageStore();
  const leagueNavigationParameters = toRef(leaguePageStore, 'leagueNavigationParameters');
  const { setPageIdentifier } = leaguePageStore;

  const sportlineBreadcrumbsStore = useSportlineBreadcrumbsStore();
  const { setPageIdentifier: setBreadcrumbsPageIdentifier } = sportlineBreadcrumbsStore;

  const loadLeagueConfig = computed<ConfigForLeagueLoading>(() => ({
    filter: filter.value ?? undefined,
  }));

  function clearLoadLeagueConfig(): void {
    setSportlineNavigationBetweenPagesFilter(null);
  }

  function prefetch(
    router: AppVueRouter,
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ): void {
    const navigationParameters = getSportPageNavigationParameters(to);

    if (!isValidSportEventsPageIdentifier(navigationParameters)) {
      next({ name: RouteName.ERROR_NOT_FOUND_404 });
      return;
    }

    const statisticId = isDevIP.value && isString(to.query?.statisticId)
      ? to.query?.statisticId
      : undefined;

    setPageIdentifier({ ...navigationParameters, statisticId });
    setBreadcrumbsPageIdentifier({ ...navigationParameters, statisticId });

    next();
  }

  function getRedirectLocation(
    router: AppVueRouter,
    currentLocation: RouteLocationNormalized,
  ): Maybe<RouteLocationRaw> {
    const target = leagueNavigationParameters.value;

    if (!target) { return null; }

    const currentTarget = getSportPageNavigationParameters(currentLocation);

    if (currentTarget.leagueId !== target.id) {
      // Is it an error?
      return null;
    }

    if (
      currentTarget.leagueUrlName === target.urlName
      && currentTarget.regionUrlName === target.regionUrlName
      && currentTarget.sportFamily === target.sportFamily
    ) {
      // alright
      return null;
    }

    return router.resolve301location(resolveLeaguePageLink(target));
  }

  return {
    loadLeagueConfig,
    clearLoadLeagueConfig,
    prefetch,
    getRedirectLocation,
  };
}
