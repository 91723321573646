import type { RouteLocationRaw } from 'vue-router';

import type { ScrollToElementOptions } from '@leon-hub/scrollbar-types';

import type { BusEventOptions } from './types/types';

export default class BusEvent<P extends BusEventOptions = BusEventOptions> {
  declare Payload: P;

  static readonly LAYOUT_CONTENT_SCROLL = new BusEvent<{
    scrollTop: number;
    offsetHeight: number;
    scrollHeight: number;
  }>('LAYOUT_CONTENT_SCROLL');

  static readonly LAYOUT_CONTENT_SET_SCROLL = new BusEvent<{
    scrollTop: number;
    smooth?: boolean;
  }>('LAYOUT_CONTENT_SET_SCROLL');

  static readonly LAYOUT_CONTENT_SCROLL_TOP = new BusEvent<{ smooth?: boolean }>('LAYOUT_CONTENT_SCROLL_TOP');

  static readonly MODAL_CONTENT_SCROLL_TOP = new BusEvent<{}>('MODAL_CONTENT_SCROLL_TOP');

  static readonly MODAL_CONTENT_SCROLL = new BusEvent<{
    offset: number;
    limit: number;
  }>('MODAL_CONTENT_SCROLL');

  static readonly LAYOUT_CONTENT_SCROLL_DISABLE = new BusEvent<{ reason: string }>('LAYOUT_CONTENT_SCROLL_DISABLE');

  static readonly LAYOUT_CONTENT_SCROLL_ENABLE = new BusEvent<{ reason: string }>('LAYOUT_CONTENT_SCROLL_ENABLE');

  static readonly OPEN_CHAT = new BusEvent<{ betId?: string }>('OPEN_CHAT');

  static readonly SIDE_MENU_TOGGLE = new BusEvent<{}>('SIDE_MENU_TOGGLE');

  static readonly SIDE_MENU_SOFT_CLOSE = new BusEvent<{}>('SIDE_MENU_SOFT_CLOSE');

  static readonly SPORT_EVENTS_STATUS_CHANGED = new BusEvent<{
    events: { event: number; market: number; runner: number; marketStatus: string | null }[];
    source: 'slip' | 'line';
  }>('SPORT_EVENTS_STATUS_CHANGED');

  static readonly SPORT_EVENTS_RUNNERS_CHANGED = new BusEvent<{
    events: { event: number; market: number; runner: number; odds: number }[];
    source: 'slip' | 'line';
  }>('SPORT_EVENTS_RUNNERS_CHANGED');

  static readonly NEW_BET_HAS_BEEN_PLACED = new BusEvent<{}>('NEW_BET_HAS_BEEN_PLACED');

  static readonly SHOW_SLIP_MODAL_WARNING = new BusEvent<{
    type: string; caption?: string; message?: string;
  }>('SHOW_SLIP_MODAL_WARNING');

  static readonly CLAIM_TO_SLIP_CLOSE = new BusEvent<{}>('CLAIM_TO_SLIP_CLOSE');

  static readonly SLIP_SELECTION_CHANGE = new BusEvent<{
    selected: { event: number; market: number; runner: number }[];
  }>('SLIP_SELECTION_CHANGE');

  static readonly SLIP_ENTRY_DELETE_CLICK = new BusEvent<{}>('SLIP_ENTRY_DELETE_CLICK');

  static readonly API_ERROR = new BusEvent<{ error: Error; event?: Event }>('API_ERROR');

  static readonly CAPTCHA_VERIFICATION = new BusEvent<{
    captchaType?: string; // sdk type CaptchaType
  }>('CAPTCHA_VERIFICATION');

  static readonly CAPTCHA_SERVICE_ERROR = new BusEvent<{
    error: Error;
  }>('CAPTCHA_SERVICE_ERROR');

  static readonly CAPTCHA_CHALLENGE_IS_CLOSED = new BusEvent<{}>('CAPTCHA_CHALLENGE_IS_CLOSED');

  static readonly CATALOG_CLICK = new BusEvent<{
    event: MouseEvent;
    payload: {
      eventId?: string;
      sportId?: string;
      leagueId?: string;
    };
  }>('CATALOG_CLICK');

  static readonly SCROLL_TO_ELEMENT_ID = new BusEvent<{
    id: string; inModal?: boolean; fromCmsContent?: boolean; smooth?: boolean;
  }>('SCROLL_TO_ELEMENT_ID');

  static readonly MODAL_SCROLL_TO_ELEMENT = new BusEvent<{
    element: HTMLElement | null;
    onlyIfNeeded?: boolean;
  }>('MODAL_SCROLL_TO_ELEMENT');

  static readonly QA_SCROLLBAR_SCROLL_TO = new BusEvent<{
    scrollbar: string;
    el: HTMLElement | null;
    options?: ScrollToElementOptions;
  }>('QA_SCROLLBAR_SCROLL_TO');

  static readonly REDIRECT = new BusEvent<{
    to: RouteLocationRaw;
    replace?: boolean;
  }>('REDIRECT');

  static readonly DEPOSIT_BONUS_WIDGET_OPEN_LIST = new BusEvent<{}>('DEPOSIT_BONUS_WIDGET_OPEN_LIST');

  protected readonly key: string;

  protected constructor(key: string) {
    this.key = key;
  }

  toString(): string {
    return this.key;
  }
}
