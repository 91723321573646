import { RouteName } from '@leon-hub/routing-config-names';

import type { AppVueRouter } from 'web/src/modules/core/services/router/types';
import type { RoutingSeoData } from 'web/src/modules/core/types';

import createPartnersRoutes from './partners';
import createPromotionsRoutes from './promotions';
import createCmsRoutes from './cms';
import createTFARoutes from './tfa';
import createResponsibleGamblingRoutes from './responsible-gambling';
import createLoyaltyProgramRoutes from './loyalty-program';
import createTransactionsRoute from './transactions';
import createRestorePasswordRoutes from './restore-password';
import createVerifyEmailRoutes from './verify-email';

interface RouterOptions {
  seoConfigs: Record<string, RoutingSeoData>;
}

export default function createRoutes(router: AppVueRouter, {
  seoConfigs,
}: RouterOptions): void {
  router.setSeoConfigs(seoConfigs);

  if (process.env.VUE_APP_FEATURE_SPONSORED_CLUBS_ENABLED) {
    createPartnersRoutes(router);
  }

  createPromotionsRoutes(router);
  createCmsRoutes(router);
  createTFARoutes(router);
  createResponsibleGamblingRoutes(router);
  createLoyaltyProgramRoutes(router);
  createTransactionsRoute(router);
  createRestorePasswordRoutes(router);
  createVerifyEmailRoutes(router);

  // Should be last one
  router.addRoute({
    name: Symbol('not-found-routes'),
    path: '/:pathMatch(.*)*',
    redirect: {
      name: RouteName.ERROR_NOT_FOUND_404,
      replace: true,
    },
  });
}
