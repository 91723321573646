import { assert } from '@leon-hub/guards';

import type { BetInfoObject } from 'web/src/modules/slip/submodules/slip-info/types';

import isSlipEntryId from '../../../guards/isSlipEntryId';

export function clearOldRunnerInfo(source: BetInfoObject): BetInfoObject {
  const result: BetInfoObject = {};
  for (const id of Object.keys(source)) {
    assert(isSlipEntryId(id), 'id is not SlipEntryId');
    result[id] = {
      ...source[id],
      oldRunnerName: undefined,
    };
  }
  return result;
}
