<script setup lang="ts">
import LButton from 'web/src/components/Button/LButton/LButton.vue';
import SButton from 'web/src/components/Button/SButton/SButton.vue';
import usePromotionRefuseButton
  from 'web/src/modules/promotions/components/PromotionRefuseButton/composables/usePromotionRefuseButton';

const {
  isRefuseButtonAvailable,
  refuseButtonProperties,
  refuseButtonLoading,
  onRefuseClick,
} = usePromotionRefuseButton();
</script>

<template>
  <template v-auto-id="'PromotionsRefuseButton'" v-if="isRefuseButtonAvailable">
    <SButton
      v-if="null"
      :class="$style['promotion__how-does-it-work-footer-button']"
      :label="$t('WEB2_BONUSES_REFUSE_BUTTON')"
      :is-loading="refuseButtonLoading"
      full-width
      size="small"
      kind="quaternary-secondary"
      @click="onRefuseClick"
    />
    <LButton
      v-else
      v-bind="refuseButtonProperties"
      :class="$style['promotion__refuse-btn']"
      @click="onRefuseClick"
    />
    <div
      v-if="null"
      :class="$style['promotion__how-does-it-work-footer-text']"
    >
      {{ $t('WEB2_PROMOTION_RESET_PROGRESS_TEXT') }}
    </div>
  </template>
</template>

<style lang="scss" module>
@import '../../styles/promotion';
</style>
