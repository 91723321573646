import type { BannerGroup } from '../types';

interface Payload {
  bannerGroups: BannerGroup[];
  groupId: string;
  bannerId: string;
}

export function deleteSingleBannerInGroups({
  bannerGroups,
  groupId,
  bannerId,
}: Payload): BannerGroup[] {
  const matchedGroupIndex = bannerGroups.findIndex((group) => group.id === groupId);
  if (matchedGroupIndex < 0) {
    return bannerGroups;
  }
  let matchedBannersGroup = [...bannerGroups[matchedGroupIndex].banners];
  const matchedBannersIndex = matchedBannersGroup.findIndex((banner) => banner.bannerId === bannerId);
  if (matchedBannersIndex < 0) {
    return bannerGroups;
  }
  matchedBannersGroup = [...matchedBannersGroup.slice(0, matchedBannersIndex), ...matchedBannersGroup.slice(matchedBannersIndex + 1)];

  const result: BannerGroup[] = [...bannerGroups];
  result[matchedGroupIndex].banners = matchedBannersGroup;

  return result;
}
