import type { Plugin } from 'vue';

import { requestIdleCallback } from '@leon-hub/utils';

import { useAnalytics } from 'web/src/modules/analytics/composables';

import CollectClickPlugin from './directives/CollectClickPlugin';
import {
  initYM,
  initSportRadar,
  initGoogleAnalytics,
  initZetaDSP,
} from './utils';

declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    $ymUtils: {
      phoneCall(): void;
    };
  }
}

const initTimeout = process.env.VUE_APP_LAYOUT_DESKTOP ? 4000 : 6000;

const AnalyticsPlugin: Plugin = {
  install(app) {
    // TODO: Remove from container - Plugins must not depend on container
    // eslint-disable-next-line no-param-reassign
    app.config.globalProperties.$ymUtils = {
      phoneCall(): void {
        useAnalytics().phoneCall();
      },
    };
    app.use(CollectClickPlugin);

    const initYandexMetrika = () => {
      initYM({
        initTimeout,
      });
    };

    const initZetaDSPTag = () => {
      initZetaDSP();
    };

    const initGM = () => {
      initGoogleAnalytics();
    };

    if (process.env.VUE_APP_PLATFORM === 'cordova') {
      initYandexMetrika();
      initGM();
    } else {
      requestIdleCallback(initYandexMetrika);
      requestIdleCallback(initZetaDSPTag, { timeout: initTimeout });
      requestIdleCallback(initSportRadar(), { timeout: initTimeout });
      requestIdleCallback(initGM, { timeout: initTimeout });
    }
  },
};

export default AnalyticsPlugin;
