import { RequestOptionsPriority } from '@leon-hub/api-sdk';

import type {
  CoreSportEventResponse,
  GetCountersResponse,
  GetCTagResponse,
  GetLeagueResponse,
  GetRegionResponse,
  GetSportResponse,
  GetSportsResponse,
  UnknownGetEventsChangesResponse,
  UnknownGetEventsResponse,
} from 'web/src/modules/sportline/types/rest';
import {
  isCoreSportEventResponse,
  isGetCountersResponse,
  isGetCTagResponse,
  isGetLeagueResponse,
  isGetRegionResponse,
  isGetSportResponse,
  isGetSportsResponse,
} from 'web/src/modules/sportline/guards/rest';
import useBaseApiClient from 'web/src/modules/core/services/api/useBaseApiClient';

import type {
  GetChangesInput,
  GetCTagInput,
  GetEventInput,
  GetEventsInput,
  GetLeagueInput,
  GetRegionInput,
  GetRelatedEventsInput,
  GetSportInput,
  GetSportsInput,
  HeadlineMatchesChangesInput,
  HeadlineMatchesInput,
  SearchEventsInput,
} from './types';

function isResponseUnknownGuard(value: unknown): value is unknown {
  // LEONWEB-10281 it used to skip rest api guards, and we'll filter the response later
  return true;
}

function serializeFlags(flags?: string[]): string | undefined {
  return flags?.length ? flags.join(',') : undefined;
}

function getLiveOutrightOptions({ LMCE }: { LMCE?: boolean }): { LMCE?: boolean } {
  return LMCE ? { LMCE } : {};
}

export interface SportlineApi {
  getCTag(input: GetCTagInput): Promise<GetCTagResponse>;
  getEvent(input: GetEventInput): Promise<CoreSportEventResponse>;
  getEvents(input: GetEventsInput): Promise<UnknownGetEventsResponse>;
  getChanges(input: GetChangesInput): Promise<UnknownGetEventsChangesResponse>;
  getCounters(input: GetEventsInput): Promise<GetCountersResponse>;
  getSports(input: GetSportsInput): Promise<GetSportsResponse>;
  getRelatedEvents(input: GetRelatedEventsInput): Promise<UnknownGetEventsResponse>;
  searchEvents(input: SearchEventsInput): Promise<UnknownGetEventsResponse>;
  headlineMatches(input: HeadlineMatchesInput): Promise<UnknownGetEventsResponse>;
  headlineMatchesChanges(input: HeadlineMatchesChangesInput): Promise<UnknownGetEventsChangesResponse>;
  getSport(input: GetSportInput): Promise<GetSportResponse>;
  getRegion(input: GetRegionInput): Promise<GetRegionResponse>;
  getLeague(input: GetLeagueInput): Promise<GetLeagueResponse>;
}

export default function createSportlineApi(): SportlineApi {
  const client = useBaseApiClient();

  return {
    async getCTag(input: GetCTagInput): Promise<GetCTagResponse> {
      return client.request<GetCTagResponse>({
        silent: input.silent,
        priority: input.silent ? RequestOptionsPriority.LOW : undefined,
        id: 'sport/get-ctag',
        endpoint: '/betline/ctag',
        query: {
          ...(process.env.VUE_APP_PLATFORM_CORDOVA ? { cors: '1' } : {}),
        },
        guard: isGetCTagResponse,
      });
    },

    async getEvent(input: GetEventInput) {
      return client.request<CoreSportEventResponse>({
        silent: input.silent,
        priority: input.silent ? RequestOptionsPriority.LOW : undefined,
        id: 'sport/get-event',
        endpoint: `/betline/event/${input.betline}`,
        query: {
          ctag: input.ctag,
          eventId: input.eventId,
          flags: serializeFlags(input.flags),
          ...getLiveOutrightOptions(input),
          ...(process.env.VUE_APP_PLATFORM_CORDOVA ? { cors: '1' } : {}),
        },
        guard: isCoreSportEventResponse,
      });
    },

    async getEvents(input: GetEventsInput) {
      return client.request<UnknownGetEventsResponse>({
        silent: input.silent,
        priority: input.silent ? RequestOptionsPriority.LOW : undefined,
        id: 'sport/get-events',
        endpoint: `/betline/events/${input.betline}`,
        query: {
          ctag: input.ctag,
          curLang: input.curLang,
          from: input.from,
          to: input.to,
          region_id: input.regionId,
          league_id: input.leagueId,
          sport_id: input.sportId,
          family: input.sportFamily,
          limit: input.limit,
          hideClosed: input.hideClosed,
          flags: serializeFlags(input.flags),
          favorite: input.favorite,
          favoriteLeagues: input.favoriteLeagues,
          login: input.login,
          zeroMarginMarkets: input.zeroMarginMarkets,
          order: input.order,
          ...getLiveOutrightOptions(input),
          ...(process.env.VUE_APP_PLATFORM_CORDOVA ? { cors: '1' } : {}),
        },
        guard: isResponseUnknownGuard,
      });
    },

    async getChanges(input: GetChangesInput) {
      return client.request<UnknownGetEventsChangesResponse>({
        silent: input.silent,
        priority: input.silent ? RequestOptionsPriority.LOW : undefined,
        id: 'sport/get-changes',
        endpoint: `/betline/changes/${input.betline}`,
        query: {
          ctag: input.ctag,
          vtag: input.vtag,
          curLang: input.curLang,
          from: input.from,
          to: input.to,
          region_id: input.regionId,
          league_id: input.leagueId,
          sport_id: input.sportId,
          family: input.sportFamily,
          limit: input.limit,
          hideClosed: input.hideClosed,
          flags: serializeFlags(input.flags),
          favorite: input.favorite,
          favoriteLeagues: input.favoriteLeagues,
          login: input.login,
          zeroMarginMarkets: input.zeroMarginMarkets,
          order: input.order,
          ...getLiveOutrightOptions(input),
          ...(process.env.VUE_APP_PLATFORM_CORDOVA ? { cors: '1' } : {}),
        },
        guard: isResponseUnknownGuard,
      });
    },

    async getCounters(input: GetEventsInput) {
      return client.request<GetCountersResponse>({
        silent: input.silent,
        priority: input.silent ? RequestOptionsPriority.LOW : undefined,
        id: 'sport/get-counters',
        endpoint: `/betline/count/${input.betline}`,
        query: {
          ctag: input.ctag,
          curLang: input.curLang,
          from: input.from,
          to: input.to,
          region_id: input.regionId,
          league_id: input.leagueId,
          sport_id: input.sportId,
          family: input.sportFamily,
          limit: input.limit,
          hideClosed: input.hideClosed,
          flags: serializeFlags(input.flags),
          favorite: input.favorite,
          favoriteLeagues: input.favoriteLeagues,
          login: input.login,
          zeroMarginMarkets: input.zeroMarginMarkets,
          order: input.order,
          ...getLiveOutrightOptions(input),
          ...(process.env.VUE_APP_PLATFORM_CORDOVA ? { cors: '1' } : {}),
        },
        guard: isGetCountersResponse,
      });
    },

    async getSports(input: GetSportsInput) {
      return client.request<GetSportsResponse>({
        id: 'sport/get-sports',
        priority: input.silent ? RequestOptionsPriority.LOW : undefined,
        endpoint: '/betline/sports',
        silent: input.silent,
        query: {
          ctag: input.ctag,
          curLang: input.curLang,
          from: input.from,
          to: input.to,
          flags: serializeFlags(input.flags),
          betlines: serializeFlags(input.betlines),
          ...getLiveOutrightOptions(input),
          ...(process.env.VUE_APP_PLATFORM_CORDOVA ? { cors: '1' } : {}),
        },
        guard: isGetSportsResponse,
      });
    },

    async getRelatedEvents(input: GetRelatedEventsInput) {
      return client.request<UnknownGetEventsResponse>({
        silent: input.silent,
        priority: input.silent ? RequestOptionsPriority.LOW : undefined,
        id: 'sport/get-related-events',
        endpoint: `/betline/related/${input.betline}`,
        query: {
          eventId: input.eventId,
          ctag: input.ctag,
          vtag: input.vtag,
          hideClosed: input.hideClosed,
          flags: serializeFlags(input.flags),
          ...getLiveOutrightOptions(input),
          ...(process.env.VUE_APP_PLATFORM_CORDOVA ? { cors: '1' } : {}),
        },
        guard: isResponseUnknownGuard,
      });
    },

    async searchEvents(input: SearchEventsInput) {
      return client.request<UnknownGetEventsResponse>({
        id: 'sport/search',
        endpoint: '/betline/search',
        query: {
          q: input.query,
          curLang: input.curLang,
          flags: serializeFlags(input.flags),
          ...(process.env.VUE_APP_PLATFORM_CORDOVA ? { cors: '1' } : {}),
        },
        silent: true,
        guard: isResponseUnknownGuard,
      });
    },

    async headlineMatches(input: HeadlineMatchesInput) {
      return client.request<UnknownGetEventsResponse>({
        silent: input.silent,
        priority: input.silent ? RequestOptionsPriority.LOW : undefined,
        id: 'sport/betline-headline-matches',
        endpoint: '/betline/headline-matches',
        query: {
          ctag: input.ctag,
          hideClosed: input.hideClosed,
          curLang: input.curLang,
          flags: serializeFlags(input.flags),
          merged: input.merged,
          ...getLiveOutrightOptions(input),
          ...(process.env.VUE_APP_PLATFORM_CORDOVA ? { cors: '1' } : {}),
        },
        guard: isResponseUnknownGuard,
      });
    },

    async headlineMatchesChanges(input: HeadlineMatchesChangesInput) {
      return client.request<UnknownGetEventsChangesResponse>({
        id: 'sport/betline-headline-matches-changes',
        endpoint: '/betline/headline-matches/changes',
        silent: input.silent,
        priority: input.silent ? RequestOptionsPriority.LOW : undefined,
        query: {
          ctag: input.ctag,
          inplayHeadlineVtag: input.inplayHeadlineVtag,
          prematchHeadlineVtag: input.prematchHeadlineVtag,
          allVtag: input.allVtag,
          hideClosed: input.hideClosed,
          curLang: input.curLang,
          flags: input.flagsString ?? serializeFlags(input.flags),
          ...getLiveOutrightOptions(input),
          ...(process.env.VUE_APP_PLATFORM_CORDOVA ? { cors: '1' } : {}),
        },
        guard: isResponseUnknownGuard,
      });
    },

    async getSport(input: GetSportInput): Promise<GetSportResponse> {
      return client.request<GetSportResponse>({
        id: 'sport/get-sport',
        endpoint: `/betline/sport/${input.sportId}`,
        silent: input.silent,
        priority: input.silent ? RequestOptionsPriority.LOW : undefined,
        query: {
          ctag: input.ctag,
          curLang: input.curLang,
          flags: serializeFlags(input.flags),
          ...getLiveOutrightOptions(input),
          ...(process.env.VUE_APP_PLATFORM_CORDOVA ? { cors: '1' } : {}),
        },
        guard: isGetSportResponse,
      });
    },

    async getRegion(input: GetRegionInput): Promise<GetRegionResponse> {
      return client.request<GetRegionResponse>({
        id: 'sport/get-region',
        endpoint: `/betline/region/${input.regionId}`,
        silent: input.silent,
        priority: input.silent ? RequestOptionsPriority.LOW : undefined,
        query: {
          ctag: input.ctag,
          curLang: input.curLang,
          flags: serializeFlags(input.flags),
          ...getLiveOutrightOptions(input),
          ...(process.env.VUE_APP_PLATFORM_CORDOVA ? { cors: '1' } : {}),
        },
        guard: isGetRegionResponse,
      });
    },

    async getLeague(input: GetLeagueInput): Promise<GetLeagueResponse> {
      return client.request<GetLeagueResponse>({
        id: 'sport/get-league',
        endpoint: `/betline/league/${input.leagueId}`,
        silent: input.silent,
        priority: input.silent ? RequestOptionsPriority.LOW : undefined,
        query: {
          ctag: input.ctag,
          curLang: input.curLang,
          flags: serializeFlags(input.flags),
          ...getLiveOutrightOptions(input),
          ...(process.env.VUE_APP_PLATFORM_CORDOVA ? { cors: '1' } : {}),
        },
        guard: isGetLeagueResponse,
      });
    },
  };
}
