import type { ComputedRef, Ref } from 'vue';
import { ref, computed } from 'vue';

import type { AnyIconName } from '@leon-hub/icons';
import { IconName } from '@leon-hub/icons';

import { useFormatMoney } from 'web/src/modules/money/composables';

import type { CashoutButtonProps } from '../types';

interface UseCashoutButton {
  formattedPriceCashout: ComputedRef<string>;
  iconArrowCashout: ComputedRef<AnyIconName>;
  isShowIconArrow: ComputedRef<boolean>;
  isHover: Ref<boolean>;
  onPointerIn(): void;
  onPointerOut(): void;
}

export function useCashoutButton(props: CashoutButtonProps): UseCashoutButton {
  const formatMoney = useFormatMoney();

  const formattedPriceCashout = computed<string>(() => formatMoney(props.cashoutAmount ?? 0));

  const iconArrowCashout = computed<AnyIconName>(
    () => (Number(props?.cashoutAmount) > Number(props?.stakeAmount)
      ? IconName.ARROW_UPWARD
      : IconName.ARROW_DOWNWARD),
  );

  const isShowIconArrow = computed<boolean>(
    () => (Number(props?.cashoutAmount) !== Number(props?.stakeAmount)),
  );

  const isHover = ref<boolean>(false);

  const onPointerIn = (): void => {
    isHover.value = true;
  };

  const onPointerOut = (): void => {
    isHover.value = false;
  };

  return {
    formattedPriceCashout,
    iconArrowCashout,
    isShowIconArrow,
    isHover,
    onPointerIn,
    onPointerOut,
  };
}
