<script lang="ts" setup>
import { toRef } from 'vue';

import { IconName, IconSize } from '@leon-hub/icons';

import { VIcon } from '@components/v-icon';

import { useSportlineEventId, useSportlineEventTypeRef } from 'web/src/modules/sportline/composables/sportline-event';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import { useLiveMatchProgress } from 'web/src/modules/sportline/composables/match-progress';
import { useLiveStatus } from 'web/src/modules/sportline/composables/live-progress';
import { useStreamAvailableIndicator } from 'web/src/modules/sportline/submodules/streams/composables';
import type { SportlineEventMetaInfoProps } from 'web/src/modules/sportline/views/EventBlock/types';
import SportlineEventGameProgress
  from 'web/src/modules/sportline/components/game-progress/SportlineEventGameProgress.vue';

interface SportlineLiveMetaInfoProps extends SportlineEventMetaInfoProps {
  doShowLiveIndicator?: boolean;
}

const props = defineProps<SportlineLiveMetaInfoProps>();

const sportlineEvent = toRef(() => props.sportlineEvent);
const doShowLiveIndicator = toRef(() => props.doShowLiveIndicator);
const sportlineEventId = useSportlineEventId(sportlineEvent);
const sportlineType = useSportlineEventTypeRef(sportlineEvent);

const settingsStore = useSportlineSettingsStore();
const isTimeReversedIndicatorAvailable = toRef(() => settingsStore.isTimeReversedIndicatorAvailable);

const {
  matchProgress,
} = useLiveMatchProgress({ sportEvent: sportlineEvent });
const {
  stage,
  scoreDetailsLine,
} = useLiveStatus(matchProgress);
const {
  isStreamIndicatorAvailable,
} = useStreamAvailableIndicator({ sportEventId: sportlineEventId, sportlineType });
</script>

<template>
  <div v-auto-id="'SportlineEventLiveMetaInfo'" :class="$style['sportline-event-meta-info']">
    <div :class="$style['sportline-event-meta-info__left-column']">
      <span
        v-if="doShowLiveIndicator"
        :class="$style['sport-event-badge']"
      >Live</span>

      <SportlineEventGameProgress
        :match-progress="matchProgress"
        :is-time-reversed-indicator-available="isTimeReversedIndicatorAvailable"
      />

      <span
        v-if="stage"
        :class="$style['sportline-event-live-stage']"
      >{{ stage }}</span>

      <span
        v-if="'1' && scoreDetailsLine"
        :class="$style['sportline-event-live-score-details']"
      >({{ scoreDetailsLine }})</span>
    </div>

    <div :class="$style['sportline-event-meta-info__right-column']">
      <VIcon
        v-if="isStreamIndicatorAvailable"
        :name="IconName.STREAM"
        :size="IconSize.SIZE_12"
        :class="$style['sportline-event-stream']"
      />
    </div>
  </div>
</template>

<style module lang="scss">
@import '~web/src/modules/sportline/styles/sport-event-badge';
@import '~web/src/modules/sportline/views/EventBlock/styles/sportline-event-meta-info';
@import '~web/src/modules/sportline/views/EventBlock/styles/sportline-event-live-meta-info';
</style>
