import { defineStore } from 'pinia';
import {
  computed,
  ref,
} from 'vue';
import type { RouteLocationNamedRaw } from 'vue-router';

import { CasinoRouteName } from '@leon-hub/routing-config-names/src/CasinoRouteName';

import { isLiveGameType } from 'web/src/modules/casino/utils';
import { useActiveGameData } from 'web/src/modules/egs/store/composables';

const useCasinoGameStore = defineStore('casino-game', () => {
  const startGameAfterLogin = ref(false);
  const isDemo = ref(false);
  const isGamePreviewMode = ref(!process.env.VUE_APP_LAYOUT_DESKTOP);
  const isLoyaltySidebarOpened = ref(true);

  const {
    activeGame,
    startGameData,
    isStartGameLoading,
    startGame,
    clearStartGameData,
    resetActiveGame,
    reloadActiveGame,
    setActiveUrl,
    loadGameById,
  } = useActiveGameData();

  const activeGameBackToLobbyLocation = computed<RouteLocationNamedRaw>(() => {
    const params = {};
    if (!activeGame.value) {
      return {
        name: CasinoRouteName.CASINO_LOBBY,
        params,
      };
    }

    return {
      name: isLiveGameType(activeGame.value.typeId)
        ? CasinoRouteName.CASINO_LIVE_LOBBY
        : CasinoRouteName.CASINO_LOBBY,
      params,
    };
  });

  function setStartGameAfterLogin(value: boolean): void {
    startGameAfterLogin.value = value;
  }

  function setDemoStartMode(value: boolean): void {
    isDemo.value = value;
  }

  function setIsGamePreview(value: boolean): void {
    if (!process.env.VUE_APP_LAYOUT_DESKTOP) {
      isGamePreviewMode.value = value;
    }
  }

  function setLoyaltySidebarOpened(value: boolean): void {
    isLoyaltySidebarOpened.value = value;
  }

  function toggleSidebarOpened(): void {
    isLoyaltySidebarOpened.value = !isLoyaltySidebarOpened.value;
  }

  return {
    isDemo,
    activeGame,
    startGameData,
    isStartGameLoading,
    isGamePreviewMode,
    startGameAfterLogin,
    isLoyaltySidebarOpened,
    activeGameBackToLobbyLocation,
    setActiveUrl,
    reloadActiveGame,
    loadGameById,
    resetActiveGame,
    startGame,
    clearStartGameData,
    setStartGameAfterLogin,
    setDemoStartMode,
    setIsGamePreview,
    setLoyaltySidebarOpened,
    toggleSidebarOpened,
  };
});

export default useCasinoGameStore;
