import { isUndefined } from '@leon-hub/guards';

import type {
  SportEvent,
  SportElement,
  DateTimeRange,
} from 'web/src/modules/sportline/types';
import type { SportlineType } from 'web/src/modules/sportline/enums';
import { filterSportsListEvents } from 'web/src/modules/sportline/utils';

export function filterSportEventsListByFilter(fullList: Maybe<SportElement<SportEvent>[]>, {
  dateTimeRange,
  sportlineType,
}: {
  dateTimeRange: DateTimeRange;
  sportlineType?: SportlineType | string | number;
}): SportElement<SportEvent>[] {
  return filterSportsListEvents(fullList || [], (item) => (
    (isUndefined(dateTimeRange.from) || item.sportEvent.kickoff >= dateTimeRange.from)
    && (isUndefined(dateTimeRange.to) || item.sportEvent.kickoff <= dateTimeRange.to)
    && (isUndefined(sportlineType) || item.sportEvent.type === sportlineType)
  ));
}
