import type { ComponentPublicInstance } from 'vue';

import type { Without } from '@leon-hub/types';
import type { AbstractErrorOptions } from '@leon-hub/errors';
import { AbstractError } from '@leon-hub/errors';

import { AppErrorCode } from './AppErrorCode';

interface AppErrorOptions extends Without<AbstractErrorOptions, 'code'> {
  code?: AppErrorCode;
}

export class ComponentError extends AbstractError {
  constructor(options?: AppErrorOptions & { prefix?: string; info: string; vm: null | ComponentPublicInstance }) {
    super({
      ...options,
      message: options?.prefix
        ? `${options.prefix}: ${options.originalError?.message ?? options?.message ?? 'Component Error'}`
        : options?.message ?? 'Component Error',
      code: new AppErrorCode('COMPONENT_ERROR'),
    });
    if (!options) return;
    const vm = options.vm ?? null;
    if (vm?.$props) {
      this.addLogMetaData('props', vm.$props);
      this.addLogMetaData('info', options.info);
      this.addLogMetaData('name', vm.$options.name);
    }
  }
}
