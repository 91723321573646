import {
  computed,
  ref,
} from 'vue';
import type { Ref } from 'vue';

import { useEgsGamesStore } from 'web/src/modules/egs/submodules/games/store';
import { useEgsApi } from 'web/src/modules/egs/composables';
import type {
  EgsGame,
  StartGameData,
  StartGameRequest,
} from 'web/src/modules/egs/types';
import { useLobbyStore } from 'web/src/modules/lobby/store';

export interface UseActiveGameDataComposable {
  activeGame: Ref<EgsGame | undefined>;
  startGameData: Ref<StartGameData | undefined>;
  isStartGameLoading: Ref<boolean>;
  startGame(options: StartGameRequest, isVpn?: boolean): Promise<void>;
  setActiveUrl(groupUrl: string, gameUrl: string): Promise<void>;
  resetActiveGame(): void;
  clearStartGameData(): void;
  reloadActiveGame(): Promise<void>;
  loadGameById(id: string): Promise<void>;
  getGameById(id: string): Promise<EgsGame | undefined>;
}

export default function useActiveGameData(): UseActiveGameDataComposable {
  const { setVisitedGame } = useLobbyStore();
  const { updateGames, getGameByUrl } = useEgsGamesStore();

  const startGameData = ref<StartGameData>();
  const isStartGameLoading = ref(false);
  const activeGroupUrl = ref('');
  const activeGameUrl = ref('');
  const activeGame = computed(() => getGameByUrl(activeGroupUrl.value, activeGameUrl.value).value);

  const {
    getGameByUrl: getGameByUrlApi,
    startGame: startGameApi,
    getGameById,
  } = useEgsApi();

  async function reloadGame(groupUrl: string, gameUrl: string): Promise<void> {
    const game = await getGameByUrlApi({
      gameUrl,
      groupUrl,
    });

    updateGames([game]);
  }

  async function setActiveUrl(groupUrl: string, gameUrl: string): Promise<void> {
    activeGroupUrl.value = groupUrl;
    activeGameUrl.value = gameUrl;

    if (!activeGame.value) {
      await reloadGame(groupUrl, gameUrl);
    }
  }

  function resetActiveGame(): void {
    activeGameUrl.value = '';
    activeGroupUrl.value = '';
  }

  async function startGame(options: StartGameRequest, isVpn = false): Promise<void> {
    try {
      isStartGameLoading.value = true;

      const data = await startGameApi(options, isVpn);

      if (options.id === activeGame.value?.id) {
        startGameData.value = data;
        setVisitedGame(activeGame.value);
      }
    } finally {
      isStartGameLoading.value = false;
    }
  }

  async function reloadActiveGame(): Promise<void> {
    if (activeGame.value) {
      await reloadGame(activeGame.value.group.url, activeGame.value.url);
    }
  }

  function clearStartGameData(): void {
    startGameData.value = undefined;
  }

  async function loadGameById(id: string): Promise<void> {
    const game = await getGameById(id);
    if (game) {
      updateGames([game]);
      await setActiveUrl(game.group.url, game.url);
    }
  }

  return {
    activeGame,
    startGameData,
    isStartGameLoading,
    reloadActiveGame,
    startGame,
    getGameById,
    setActiveUrl,
    resetActiveGame,
    clearStartGameData,
    loadGameById,
  };
}
