import type BaseSportEventDetailsPageNavigationService from './BaseSportEventDetailsPageNavigationService';
import PrerenderSportEventDetailsPageNavigationService from './PrerenderSportEventDetailsPageNavigationService';
import DefaultSportEventDetailsPageNavigationService from './DefaultSportEventDetailsPageNavigationService';

let service: BaseSportEventDetailsPageNavigationService | undefined;

export function useSportlineSportEventDetailsNavigationService(): BaseSportEventDetailsPageNavigationService {
  if (!service) {
    service = process.env.VUE_APP_PRERENDER
      ? new PrerenderSportEventDetailsPageNavigationService()
      : new DefaultSportEventDetailsPageNavigationService();
  }

  return service;
}
