<script lang="ts" setup>
import { RouteName } from '@leon-hub/routing-config-names';

import { useReferralProgramRegistration } from 'web/src/modules/referral-program/views/ReferralProgramRegistration/composables';
import ReferralProgramRegistrationBody
  // eslint-disable-next-line max-len
  from 'web/src/modules/referral-program/components/ReferralProgramRegistrationBody/ReferralProgramRegistrationBody.vue';

const {
  rewards,
  handleClick,
} = useReferralProgramRegistration();
</script>

<template>
  <div v-auto-id="'ReferralProgramRegistration'" :class="$style['referral-program-reg']">
    <ReferralProgramRegistrationBody
      v-if="rewards"
      v-bind="rewards"
      @click="handleClick"
    />
    <router-link
      v-if="null"
      :to="{name: RouteName.CMS_PROMO_TERMS, params: { cmsKey: 'FRIEND_LEON_RULES' } }"
      :class="$style['referral-program-reg__terms']"
    >
      {{ $t('WEB2_REFERRAL_TERMS_BUTTON') }}
    </router-link>
  </div>
</template>

<style lang="scss" module>
.referral-program-reg {
  width: 100%;
  padding: $referralProgramRegPaddingPhone;
  margin-top: $referralProgramRegMarginTop;

  &__terms {
    @include regular\14\24\025;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 44px;
    margin: 0 auto;
    color: var(--TextPrimary);
    text-decoration: underline;
    cursor: pointer;

    @include for-hover {
      &:hover {
        color: var(--BrandHighlight);
      }
    }
  }

  @include is-app-layout-desktop {
    padding: $referralProgramRegPaddingDesktop;
  }
}
</style>
