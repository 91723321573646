import type { ComputedRef, Ref } from 'vue';
import { computed, ref, toRef } from 'vue';

import { Timer } from '@leon-hub/utils';
import type { FormSchema } from '@leon-hub/form-utils';
import {
  AlertIconName, IconName, IconSize,
} from '@leon-hub/icons';
import { FormControlType } from '@leon-hub/form-utils';

import type { ResendEmailOutput } from 'web/src/modules/restore-password/store/types';
import type { FormOutput, FormUiSchema } from 'web/src/components/Form';
import { RestorePasswordFieldName } from 'web/src/modules/restore-password/enums';
import { useFormsStore } from 'web/src/modules/forms/store';
import { CaptchaRequesterStrategy } from 'web/src/modules/captcha/store/enums';
import { getJumbotronProps, JumbotronIconKind } from 'web/src/components/Jumbotron';
import type { VJumbotronProps } from 'web/src/components/Jumbotron/VJumbotron/types';
import type {
  RestorePasswordByEmailInfoEmits,
  RestorePasswordByEmailInfoProps,
} from 'web/src/modules/restore-password/components/RestorePasswordByEmailInfo/types';
import { useI18n } from 'web/src/modules/i18n/composables';
import type { VFormRef } from 'web/src/components/Form/components/VForm/types';
import { useRestorePasswordStore } from 'web/src/modules/restore-password/store';

export interface RestorePasswordByEmailInfoComposable {
  resendForm: Ref<VFormRef | undefined>;
  iconProperties: VJumbotronProps;
  tooltipLabel: Ref<string | null>;
  currentEmail: ComputedRef<string>;
  formSchema: ComputedRef<FormSchema>;
  uiSchema: Ref<FormUiSchema>;
  emitSubmit: (formData: ResendEmailOutput) => void;
  emitInput: (payload: FormOutput) => void;
  emitSuccess: () => void;
  resendEmail: () => void;
  displaySuccessTooltip: () => void;
}

export default function useRestorePasswordByEmailInfo(props: RestorePasswordByEmailInfoProps, emits: RestorePasswordByEmailInfoEmits): RestorePasswordByEmailInfoComposable {
  const { $translate } = useI18n();
  const restorePasswordStore = useRestorePasswordStore();

  const restoreBySendLinkToEmail = toRef(useFormsStore(), 'restoreBySendLinkToEmail');
  const captchaSettings = toRef(restorePasswordStore, 'captchaSettings');

  const resendForm = ref<VFormRef>();
  const tooltipLabel = ref<string | null>(null);
  const iconProperties: VJumbotronProps = {
    ...getJumbotronProps({
      alertIcon: AlertIconName.Email,
      iconSize: IconSize.SIZE_80,
      icon: process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED
        ? IconName.SLOTT_CHECK_CIRCLE
        : IconName.CHECK_OUTLINE,
    }),
    iconKind: JumbotronIconKind.SUCCESS,
  };

  const currentEmail = computed<string>(() => restorePasswordStore.currentEmail || '');
  // reuse send link to mail form
  // eslint-disable-next-line rulesdir/class-method-use-this-regex
  const formSchema = computed<FormSchema>(() => restoreBySendLinkToEmail.value ?? {
    properties: {},
  });
  // we need full uiSchema to create captcha flow elements in form
  const uiSchema = computed<FormUiSchema>(() => ({
    submitButton: null,
    fields: {
      // we will use an email from the store but fill the field for a form validation
      [RestorePasswordFieldName.EMAIL]: {
        hidden: true,
        widget: FormControlType.Email,
        default: currentEmail.value,
      },
      [RestorePasswordFieldName.CAPTCHA_TOKEN]: {
        hidden: true,
        widget: FormControlType.Captcha,
        options: {
          captchaRequesterStrategy: CaptchaRequesterStrategy.RESTORE_PASSWORD,
          captchaEnabled: captchaSettings.value.isEnabledToShow,
          reCaptchaTheme: captchaSettings.value.theme,
          isEnforced: captchaSettings.value.isEnforced,
        },
      },
    },
  }));

  function displaySuccessTooltip(): void {
    if (tooltipLabel.value !== null) {
      return;
    }

    tooltipLabel.value = $translate('WEB2_REQUEST_SUBMITTED').value;
    Timer.setTimeout(() => {
      tooltipLabel.value = null;
    }, 1500);
  }

  function resendEmail(): void {
    if (props.isPin) {
      emitSuccess();
    } else {
      resendForm.value?.submit();
    }
  }

  function emitSubmit(formData: ResendEmailOutput): void {
    emits('form-submit', {
      formOutput: formData, onSuccess: displaySuccessTooltip,
    });
  }

  function emitInput(payload: FormOutput): void {
    emits('form-input', payload);
  }

  function emitSuccess(): void {
    emits('success', { onSuccess: displaySuccessTooltip });
  }
  return {
    iconProperties,
    resendForm,
    tooltipLabel,
    currentEmail,
    formSchema,
    uiSchema,
    resendEmail,
    displaySuccessTooltip,
    emitSubmit,
    emitInput,
    emitSuccess,
  };
}
