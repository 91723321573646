import type {
  ClaimToUpdateSportEventRunnerOddChanged,
} from 'web/src/modules/slip/types/сlaimToUpdate';
import type {
  CoreMarketRunnerResponse,
} from 'web/src/modules/sportline/types/rest';
import formatOdd from 'web/src/utils/formatOdd';

import isMatchedChange from './isMatchedChange';

export default function applyRunnerOddsChange(
  { eventId, marketId, runner }: {
    eventId?: number;
    marketId?: number;
    runner: CoreMarketRunnerResponse;
  },
  changes: ClaimToUpdateSportEventRunnerOddChanged[],
): CoreMarketRunnerResponse {
  const actualChange = changes
    .find((change) => isMatchedChange(change, {
      eventId,
      marketId,
      runnerId: runner.id,
    }));

  if (!actualChange) { return runner; }

  return {
    ...runner,
    price: actualChange.odds,
    priceStr: formatOdd(actualChange.odds),
  };
}
