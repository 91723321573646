import { getLocationOrigin } from '@leon-hub/service-locator-env';

export enum CameraAccessMode {
  ASK,
  ALLOWED,
  DENIED,
}

export default class MediaUtils {
  private stream?: MediaStream;

  public static async hasCamera(): Promise<boolean> {
    if (process.env.VUE_APP_RENDERING_SSR) {
      return false;
    }

    if (typeof navigator.mediaDevices?.enumerateDevices === 'undefined') {
      return false;
    }

    const devices = await navigator.mediaDevices.enumerateDevices();

    return devices.some((device) => device.kind === 'videoinput');
  }

  public static isSupportedByBrowser(): boolean {
    return !!navigator.mediaDevices && typeof MediaRecorder !== 'undefined' && !!MediaRecorder;
  }

  public static async isSupported(): Promise<boolean> {
    if (!MediaUtils.isSupportedByBrowser()) {
      return false;
    }

    return MediaUtils.hasCamera();
  }

  public async getStream(): Promise<MediaStream | undefined> {
    if (process.env.VUE_APP_RENDERING_SSR) {
      return undefined;
    }

    if (this.stream) {
      return this.stream;
    }

    this.stream = await navigator.mediaDevices.getUserMedia({
      video: true,
    });

    return this.stream;
  }

  public stopStream(): void {
    if (this.stream) {
      for (const track of this.stream.getTracks()) {
        track.stop();
      }
      this.stream = undefined;
    }
  }

  public static async playSound(name: string): Promise<void> {
    try {
      const audio = new Audio(`${getLocationOrigin()}/sounds/${name}`);

      if (audio) {
        await audio.play();
      }
    } catch {
      // empty
    }
  }
}
