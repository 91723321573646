import { toRef } from 'vue';

import useRootStore from 'web/src/modules/core/store/useRootStore';
import { useErrorsConverter } from 'web/src/modules/errors/composables';
import { SportlineRestErrorCode } from 'web/src/modules/sportline/errors/rest';
import { getCTag } from 'web/src/modules/sportline/utils/api';

import type { RequestWithCTag, TryRequestWithCTagMethod } from './types';

export interface CTagComposable {
  tryRequestWithCTag: TryRequestWithCTagMethod;
}

export function useCTagController(): CTagComposable {
  const rootStore = useRootStore();
  const errorConverter = useErrorsConverter();

  const cTag = toRef(() => rootStore.cTag);

  let reloadCTagRequest: Maybe<Promise<void>> = null;

  function reloadCTag(): Promise<void> {
    if (reloadCTagRequest) { return reloadCTagRequest; }

    reloadCTagRequest = new Promise((resolve, reject) => {
      getCTag({ silent: true })
        .then((result) => {
          rootStore.setCTag(result.ctag);
          resolve();
        })
        .catch(reject)
        .finally(() => {
          reloadCTagRequest = null;
        });
    });

    return reloadCTagRequest;
  }

  function isInvalidCTagError(rawError: unknown): boolean {
    const error = errorConverter.convertToBaseError(rawError);
    return error.code.equals(SportlineRestErrorCode.INVALID_CTAG);
  }

  function tryRequestWithCTag<Response>(method: RequestWithCTag<Response>, maxReloadTries = 1): Promise<Response> {
    return new Promise<Response>((resolve, reject) => {
      method(cTag.value).then(resolve).catch(async (rawError) => {
        if (!isInvalidCTagError(rawError)) {
          reject(rawError);
          return;
        }

        if (maxReloadTries <= 0) {
          reject(rawError);
          return;
        }

        await reloadCTag();
        tryRequestWithCTag(method, maxReloadTries - 1).then(resolve).catch(reject);
      });
    });
  }

  return {
    tryRequestWithCTag,
  };
}
