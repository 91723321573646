import type { Ref } from 'vue';
import { computed } from 'vue';

import type { SiteConfigDocument } from 'web/src/modules/core/store/types';

interface UseSportlineStatisticsSettingsStoreProps {
  sportlineBlock: Ref<Optional<SiteConfigDocument['sportLine']>>;
}

interface UseSportlineStatisticsSettingsStoreComposable {
  isSportEventStatisticEnabled: Ref<boolean>;
  isLeagueHeaderDataRequestEnabled: Ref<boolean>;
  isLeagueStatisticEnabled: Ref<boolean>;
}

export function useSportlineStatisticsSettingsStoreComposable(
  props: UseSportlineStatisticsSettingsStoreProps,
): UseSportlineStatisticsSettingsStoreComposable {
  const { sportlineBlock } = props;

  return {
    isSportEventStatisticEnabled: computed<boolean>(() => !!sportlineBlock.value?.sportEventStatisticsEnabled),
    isLeagueHeaderDataRequestEnabled: computed<boolean>(() => !!sportlineBlock.value?.leagueHeaderDataRequestEnabled),
    isLeagueStatisticEnabled: computed<boolean>(() => !!sportlineBlock.value?.sportLeagueStatisticsEnabled),
  };
}
