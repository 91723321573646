<script setup lang="ts">
import { BetCashOut } from 'web/src/components/BetCashOut/components';

import { useCashoutView } from '../composables';

const {
  coreComponentProps,
  mainButtonClick,
  cancelClick,
} = useCashoutView();
</script>

<template>
  <BetCashOut v-auto-id="'CashoutView'"
    v-bind="coreComponentProps"
    @main-button-click="mainButtonClick"
    @cancel="cancelClick"
  />
</template>
