<script lang="ts" setup>
import {
  useSlots,
  ref,
} from 'vue';

import VCountdown from 'web/src/components/Countdown/VCountdown/VCountdown.vue';
import { useI18n } from 'web/src/modules/i18n/composables';

interface ProfileBonusesCardTimerProps {
  expirationDate?: number;
  isShortNames?: boolean;
}

interface ProfileBonusesCardTimerEmits {
  (e: 'timer-end'): void;
}

const props = defineProps<ProfileBonusesCardTimerProps>();

const emit = defineEmits<ProfileBonusesCardTimerEmits>();

const slots = useSlots();

const { $translate } = useI18n();

const bonusExpiredTranslation = `${$translate('WEB2_BONUS_EXPIRES').value}: `;
const timeLabel = ref<string>();
function setTimeLabel(value: string):void {
  timeLabel.value = value;
}
</script>

<template>
  <div v-auto-id="'ProfileBonusesCardTimer'"
    :class="{
      [$style['bonus-card-timer']]: true,
      [$style['bonus-card-timer--with-progress']]: slots.progress
    }"
    :title="timeLabel"
  >
    <span :class="$style['bonus-card-timer__date']">
      <template v-if="props.expirationDate">
        <VCountdown
          :expiration-date="props.expirationDate"
          :title="bonusExpiredTranslation"
          :is-short-names="props.isShortNames || Boolean(slots.progress)"
          @countdown-end="emit('timer-end');"
          @countdown-change="setTimeLabel"
        />
      </template>
    </span>
    <div
      v-if="slots.progress"
      :class="$style['bonus-card-timer__progress']"
    >
      <slot name="progress" />
    </div>
  </div>
</template>

<style lang="scss" module>
.bonus-card-timer {
  @include regular\13\20;

  display: flex;
  justify-content: space-between;
  padding: 4px 16px 8px;
  background-color: var(--Layer1);

  &--with-progress {
    padding: 0;
  }

  &__date {
    overflow: hidden;
    color: var(--TextSecondary);
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__progress {
    white-space: nowrap;
  }
}
</style>
