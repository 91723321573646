<script lang="ts" setup>
import { computed } from 'vue';

import type { Web2Image } from '@leon-hub/api-sdk';
import {
  IconName,
  IconSize,
  getAlertIcon,
} from '@leon-hub/icons';
import type { IconNameType } from '@leon-hub/icons';

import { VJumbotron, JumbotronFooterButton } from 'web/src/components/Jumbotron';
import type { CasinoGameIframeErrorEmits } from 'web/src/modules/casino/components/CasinoGameIframeError/types';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import type { EgsGameErrorData } from 'web/src/modules/casino/types';
import { AlertIconName } from 'web/src/components/Icon/AlertIcon/types';

const props = defineProps<EgsGameErrorData>();
const emits = defineEmits<CasinoGameIframeErrorEmits>();

const icon = computed<IconNameType | undefined>(() => {
  if (null) {
    return undefined;
  }

  switch (props.type) {
    case 'unavailable':
      return IconName.GAME_ERROR;
    case 'provider-block':
    case 'game-block':
      return IconName.LOCATION_RESTRICTED;
    default:
      return undefined;
  }
});

const image = computed<Web2Image | undefined>(() => {
  if (!null) {
    return undefined;
  }

  let src: string | undefined;
  if (props.type === 'unavailable') {
    src = getAlertIcon(AlertIconName.Fail);
  } else if (['provider-block', 'game-block'].includes(props.type)) {
    src = getAlertIcon(AlertIconName.Stop2);
  }

  return src ? { src } : undefined;
});
</script>

<template>
  <div v-auto-id="'CasinoGameIframeError'"
    :class="{
      [$style['casino-game-iframe-error']]: true,
    }"
  >
    <div :class="$style['casino-game-iframe-error__inner']">
      <noindex>
        <VJumbotron
          :text="text"
          :heading="title"
          :icon="icon"
          :icon-size="null ? undefined: IconSize.SIZE_80"
          :image="image"
        >
          <template
            v-if="button"
            #footer
          >
            <JumbotronFooterButton
              :kind="null ? 'primary' : ButtonKind.PRIMARY"
              full-width
              :label="button.text"
              @click.prevent="emits('click', button.action)"
            />
          </template>
        </VJumbotron>
      </noindex>
    </div>
  </div>
</template>

<style lang="scss" module>
@include for-layout using($isDesktop) {
  .casino-game-iframe-error {
    width: 100%;
    height: 100%;
    background-color: $casinoGameIframeErrorBackground;

    &__inner {
      position: absolute;
      top: 50%;
      left: 50%;
      width: if($isDesktop, 320px, 100%);
      padding-bottom: 64px;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
