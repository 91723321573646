import { defineStore } from 'pinia';
import { ref } from 'vue';

import type { BaseRouteNameType } from '@leon-hub/routing-config-names';

import { SportlineNavigationSection } from 'web/src/modules/sportline/submodules/navigation/enums';

import type { SportDateTimeFilter } from './types';
import { normalizeSportlineNavigationBetweenPagesFilter } from './utils';

export const useSportlineNavigationStore = defineStore('sportline-navigation', () => {
  const sportlineReloadFiltersLocation = ref<BaseRouteNameType | null>(null);
  const sportlineNavigationBetweenPagesFilter = ref<SportDateTimeFilter | null>(null);

  const sportlineNavigationSection = ref(SportlineNavigationSection.Default);

  function setSportlineReloadFiltersLocation(value: BaseRouteNameType | null): void {
    sportlineReloadFiltersLocation.value = value;
  }

  function setSportlineNavigationBetweenPagesFilter(filter?: SportDateTimeFilter | null): void {
    const normalizedFilter = normalizeSportlineNavigationBetweenPagesFilter({
      period: filter?.activeKickoffPeriod,
      from: filter?.customDateFrom ? String(filter.customDateFrom) : undefined,
      to: filter?.customDateTo ? String(filter.customDateTo) : undefined,
    });
    sportlineNavigationBetweenPagesFilter.value = normalizedFilter ?? null;
  }

  function setSportlineNavigationSection(section?: Maybe<SportlineNavigationSection>): void {
    sportlineNavigationSection.value = section ?? SportlineNavigationSection.Default;
  }

  return {
    sportlineReloadFiltersLocation,
    sportlineNavigationBetweenPagesFilter,
    setSportlineReloadFiltersLocation,
    setSportlineNavigationBetweenPagesFilter,
    sportlineNavigationSection,
    setSportlineNavigationSection,
  };
});
