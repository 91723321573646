<script lang="ts" setup>
import CustomerHistoryListItemLayout
  // eslint-disable-next-line max-len
  from 'web/src/modules/profile/submodules/customer-history/components/CustomerHistoryListItemLayout/CustomerHistoryListItemLayout.vue';
import { TestKeysEnum } from 'web/src/modules/profile/submodules/customer-history/types';
import { defaultCurrency } from 'web/src/modules/money/consts';

import type { CustomerHistoryListItemEgsProps, CustomerHistoryListItemEgsEmits } from './types';
import { useCustomerHistoryListItemEgs } from './composables';

const props = withDefaults(defineProps<CustomerHistoryListItemEgsProps>(), {
  currency: defaultCurrency,
});
const emit = defineEmits<CustomerHistoryListItemEgsEmits>();

const {
  id,
  date,
  typeKind,
  isCreditPositive,
  eventDescription,
  debit,
  credit,
} = useCustomerHistoryListItemEgs(props);
</script>

<template>
  <CustomerHistoryListItemLayout v-auto-id="'CustomerHistoryListItemEgs'" @click="emit('item-click', item)">
    <template #metaLeft>
      <span
        :class="$style['egs-list-item__type-kind']"
        :data-test-key="TestKeysEnum.TYPE_KIND"
      >{{ typeKind }}</span>
    </template>
    <template #metaRight>
      <span
        :class="$style['egs-list-item__date']"
        :data-test-key="TestKeysEnum.DATE"
      >{{ date }}</span>
    </template>
    <template #descriptionLeft>
      <span
        :class="$style['egs-list-item__event-description']"
        :data-test-key="TestKeysEnum.EVENT_DESCRIPTION"
      >{{ eventDescription }}</span>
    </template>
    <template #descriptionRight>
      <span
        :class="$style['egs-list-item__debit']"
        :data-test-key="TestKeysEnum.DEBIT"
      >{{ debit }}</span>
    </template>
    <template #footerLeft>
      <span
        :class="$style['egs-list-item__id']"
        :data-test-key="TestKeysEnum.ID"
      >{{ id }}</span>
    </template>
    <template #footerRight>
      <span
        v-if="isCreditPositive"
        :class="{
          [$style['egs-list-item__credit']]: true,
          [$style['egs-list-item__credit--positive']]: true,
        }"
        :data-test-key="TestKeysEnum.CREDIT"
      >{{ credit }}</span>
    </template>
  </CustomerHistoryListItemLayout>
</template>

<style lang="scss" module>
.egs-list-item {
  &__type-kind {
    @include medium\12\16;

    display: block;
    width: 100%;
    height: 16px;
    overflow: hidden;
    color: var(--TextSecondary);
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__date {
    @include regular\12\16;

    color: var(--TextSecondary);
  }

  &__event-description {
    @include medium\14\20\025;

    display: block;
    overflow: hidden;
    color: var(--TextDefault);
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__debit {
    @include regular\14\20\025;

    color: var(--TextPrimary);
  }

  &__credit {
    @include medium\14\16\025;

    color: var(--TextPrimary);

    &--positive {
      color: var(--BrandDefault);
    }
  }

  &__id {
    @include regular\13\16;

    color: var(--TextSecondary);
  }
}
</style>
