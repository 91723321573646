import { isString } from '@leon-hub/guards';

import type { SportFamily } from 'web/src/modules/sportline/enums';
import { CustomFilter } from 'web/src/modules/sportline/enums';
import type { LiveCustomFilter } from 'web/src/modules/sportline/submodules/live/types';

const customFilterValues = [
  CustomFilter.Favorites,
  CustomFilter.AllEvents,
  CustomFilter.Stream,
].map((v) => v.toLowerCase());

export function isLiveFilterSportFamily(
  value: unknown,
  allowedSportFamily: string[],
): value is SportFamily | LiveCustomFilter {
  return isString(value)
    && (allowedSportFamily.includes(value) || customFilterValues.includes(value.toLowerCase()));
}

export function isOptionalLiveFilterSportFamily(
  value: unknown,
  allowedSportFamily: string[],
): value is (SportFamily | LiveCustomFilter | undefined) {
  return !value || isLiveFilterSportFamily(value, allowedSportFamily);
}
