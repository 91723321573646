import type { Ref } from 'vue';
import { useHead } from '@unhead/vue';

export function useHtmlClassList(className: Ref<string[]>): void {
  useHead({
    htmlAttrs: {
      class: className,
    },
  });
}
