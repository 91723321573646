/* eslint-disable class-methods-use-this */
import { RouteName } from '@leon-hub/routing-config-names';

import type { AppVueRouter } from 'web/src/modules/core/services/router/types';
import { AppModule } from 'web/src/modules/core/plugins/AppModule';
import {
  getSportlineNavigationRouteComponent,
  getSportlineNavigationLoaderRouteComponent,
} from 'web/src/modules/sportline/submodules/navigation/SportlineNavigationSubmodule';

export class CybersportModule extends AppModule {
  addRoutes(router: AppVueRouter): void {
    // shared components
    const navigation = getSportlineNavigationRouteComponent();
    const contentLoader = getSportlineNavigationLoaderRouteComponent();

    const leftSideBar = process.env.VUE_APP_LAYOUT_DESKTOP
      ? () => import('./sidebar/CybersportSidebarRouteComponent.vue')
      : undefined;

    router.addDefaultRoute({
      name: RouteName.SPORTLINE_CYBERSPORT,
      path: '/cybersport/:region?',
      prefetch: () => import('./prefetch/CybersportPagePrefetch'),
      component: () => import('./views/CybersportRoutePage.vue'),
      seoController: () => import('./seo/CybersportPageSeoController'),
      navigation,
      leftSideBar,
      contentLoader,
    });
  }
}
