import type { Ref } from 'vue';
import { toRef } from 'vue';

import { useCustomerFinanceStore } from '../index';

export interface UseBalance {
  balance: Ref<number>;
}

export default function useBalance(): UseBalance {
  const balance = toRef(useCustomerFinanceStore(), 'balance');

  return {
    balance,
  };
}
