import {
  computed, ref, toRef,
} from 'vue';
import { defineStore } from 'pinia';

import { Timer } from '@leon-hub/utils';

import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { useFormatMoney } from 'web/src/modules/money/composables';

import { getModifiedStakeInputValue } from '../../stake-input/utils';
import type {
  SlipEntry,
  SlipEntryId,
} from '../../slip-info/types';
import type {
  MultisingleInputPayload,
  MultiSinglesMetaInfo,
  SinglesKeyBoardInputPayload,
} from '../types';
import { useStakeInputStore } from '../../stake-input/store';
import {
  isFocusedIdBecameUnavailable,
  modifyMetaInfoRecordOnFocus,
  modifyMetaInfoRecordOnBlur,
  getUpdatedMultiSinglesStakeValue,
} from '../utils';

const useMultiSinglesStore = defineStore('multi-singles-store', () => {
  const siteConfigStore = useSiteConfigStore();

  const customerDataStore = useCustomerDataStore();

  const stakeInputValidationTimeout = toRef(useStakeInputStore(), 'stakeInputValidationTimeout');

  const { isLoggedIn } = useIsLoggedIn();

  const formatMoney = useFormatMoney();

  const sameStakeForSingleByDefault = toRef(customerDataStore, 'sameStakeForSingleByDefault');

  const slipBlock = toRef(siteConfigStore, 'slipBlock');

  const localSameStakeForSingles = ref<boolean>(false);

  const isMultiSinglesEnabled = computed<boolean>(() => slipBlock.value?.multiSinglesEnabled ?? false);

  const multiSinglesMetaInfo = ref<MultiSinglesMetaInfo>({});

  const setMultiSinglesMetaInfo = (updatedInfo: MultiSinglesMetaInfo): void => {
    multiSinglesMetaInfo.value = updatedInfo;
  };

  let removeFocusedTimeoutId = 0;

  let hideInputErrorTimeoutId = 0;

  // used to display error on last modified input
  const lastModifiedId = ref<SlipEntryId | null>(null);

  // used to show fast bets block on desktop
  const lastFocusedItemId = ref<SlipEntryId | null>(null);

  // pass prop to component
  const focusedItemId = ref<SlipEntryId | null>(null);

  const setLocalSameStakeForSingles = (isSame: boolean): void => {
    localSameStakeForSingles.value = isSame;
    if (isSame) {
      lastFocusedItemId.value = null;
      focusedItemId.value = null;
    }
  };

  // show error on edit with delay
  const hideInputErrorsId = ref<SlipEntryId | null>(null);

  const setLastModifiedId = (value: SlipEntryId | null): void => {
    lastModifiedId.value = value;
  };

  const sameStakeForSingles = computed<boolean>(() => {
    if (!isLoggedIn.value) {
      return localSameStakeForSingles.value;
    }
    return sameStakeForSingleByDefault.value;
  });

  const handleStakeInputEdited = (id: SlipEntryId): void => {
    hideInputErrorsId.value = id;
    Timer.clearTimeout(hideInputErrorTimeoutId);
    hideInputErrorTimeoutId = Timer.setTimeout(() => {
      hideInputErrorsId.value = null;
    }, stakeInputValidationTimeout.value || 0);
  };

  const updateStakeValueInMultiSingles = ({ value, id, isFastBetValue }: MultisingleInputPayload): void => {
    handleStakeInputEdited(id);
    if (isFastBetValue) {
      hideInputErrorsId.value = null;
    }
    const updatedMetaInfo = getUpdatedMultiSinglesStakeValue({
      value,
      id,
      source: multiSinglesMetaInfo.value,
      isFastBetValue,
      formatMoney,
    });
    setLastModifiedId(id);
    setMultiSinglesMetaInfo(updatedMetaInfo);
  };

  const onMultiSinglesInputBlur = (id: SlipEntryId): void => {
    focusedItemId.value = null;
    hideInputErrorsId.value = null;
    removeFocusedTimeoutId = Timer.setTimeout(() => {
      lastFocusedItemId.value = null;
    }, 200);
    const updatedMetaInfo = modifyMetaInfoRecordOnBlur(id, multiSinglesMetaInfo.value);
    if (updatedMetaInfo) {
      setMultiSinglesMetaInfo(updatedMetaInfo);
    }
  };

  const onMultiSinglesInputFocus = (id: SlipEntryId): void => {
    Timer.clearTimeout(removeFocusedTimeoutId);
    lastFocusedItemId.value = id;
    focusedItemId.value = id;
    const updatedMetaInfo = modifyMetaInfoRecordOnFocus(id, multiSinglesMetaInfo.value);
    if (updatedMetaInfo) {
      setMultiSinglesMetaInfo(updatedMetaInfo);
    }
  };

  const handleSinglesMobileInput = ({ value, id, action }: SinglesKeyBoardInputPayload): void => {
    handleStakeInputEdited(id);
    const matchedRecord = multiSinglesMetaInfo.value[id];
    if (!matchedRecord) {
      return;
    }
    const updatedValue = getModifiedStakeInputValue(matchedRecord.stakeValue, {
      action,
      value,
      removeCurrentValue: matchedRecord.clearValueOnFocus,
    });
    updateStakeValueInMultiSingles({ value: updatedValue, id });
  };

  const clearFocusOnUnavailableEvents = (changedEvents: SlipEntry[]): void => {
    if (focusedItemId.value && isFocusedIdBecameUnavailable(focusedItemId.value, changedEvents)) {
      onMultiSinglesInputBlur(focusedItemId.value);
    }
  };

  const resetMultiSinglesFocusOnClose = (): void => {
    if (focusedItemId.value) {
      onMultiSinglesInputBlur(focusedItemId.value);
    }
  };

  return {
    isMultiSinglesEnabled,
    sameStakeForSingles,
    lastModifiedId,
    multiSinglesMetaInfo,
    lastFocusedItemId,
    focusedItemId,
    hideInputErrorsId,
    setLocalSameStakeForSingles,
    setMultiSinglesMetaInfo,
    setLastModifiedMultiSingleId: setLastModifiedId,
    updateStakeValueInMultiSingles,
    onMultiSinglesInputFocus,
    onMultiSinglesInputBlur,
    handleSinglesMobileInput,
    clearFocusOnUnavailableEvents,
    resetMultiSinglesFocusOnClose,
  };
});

export default useMultiSinglesStore;
