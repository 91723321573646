import type { Ref } from 'vue';
import { ref } from 'vue';

interface UseSwiperManualActiveSlideComposable {
  manualActiveSlide: Ref<Optional<number>>;
  manualChangeSlideActiveState(index: number, isActive: boolean): void;
}

export function useSwiperManualActiveSlide(): UseSwiperManualActiveSlideComposable {
  const manualActiveSlide = ref<Optional<number>>(undefined);

  function manualChangeSlideActiveState(index: number, isActive = true): void {
    if (isActive) {
      manualActiveSlide.value = index;
    } else if (manualActiveSlide.value === index) {
      manualActiveSlide.value = undefined;
    }
  }

  return {
    manualActiveSlide,
    manualChangeSlideActiveState,
  };
}
