import type { VButtonProps } from 'web/src/components/Button/VButton/types';
import { ButtonHeight, ButtonKind } from 'web/src/components/Button/VButton/enums';

export default function getButtonProps(label: string): VButtonProps {
  return {
    label,
    kind: ButtonKind.CANCEL,
    height: ButtonHeight.TINY_EXTRA,
  };
}
