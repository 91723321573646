<script lang="ts" setup>
import {
  IconName,
  IconSize,
} from '@leon-hub/icons';

import { SportIcon } from '@components/sport-icon';
import { VIcon } from '@components/v-icon';

import VImage from 'web/src/components/Image/VImage/VImage.vue';
import PrimaryMarket from 'web/src/modules/sportline/views/primary-market/PrimaryMarket.vue';
import { ObjectFitOption } from 'web/src/components/Image/VImage/enums';
import VBadge from 'web/src/components/Badge/VBadge/VBadge.vue';
import { BadgeKind } from 'web/src/components/Badge/VBadge/enums';
import VSkeletonImage from 'web/src/components/Skeleton/VSkeletonImage/VSkeletonImage.vue';
import SportlineEventGameProgress from 'web/src/modules/sportline/components/game-progress/SportlineEventGameProgress.vue';
import { useTheme } from 'web/src/modules/theme/composables';

import type { LobbySportTopMatchItemProps } from './types';
import { useLobbySportTopMatchItem } from './composables';

const props = defineProps<LobbySportTopMatchItemProps>();

const sportIconSize = '1'
  ? IconSize.SIZE_56
  : IconSize.SIZE_20;

const liveBadge = require('web/src/assets/images/live-event-badge.svg');

const {
  matchProgress,
  isTimeReversedIndicatorAvailable,
  totalScore,
  stage,
  isLiveSportEvent,
  isNoMarketsBlockedBtnEnabled,
  homeTeam,
  awayTeam,
  backgroundColor,
  date,
  time,
  primaryMarket,
  scoreDetailsLine,
  sportlineEventLocation,
  redirectToSportlineItem,
} = useLobbySportTopMatchItem(props);

const { isDark } = useTheme();
</script>

<template>
  <component v-auto-id="'LobbySportTopMatchItem'"
    :is="sportlineEventLocation ? 'a' : 'div'"
    :href="sportlineEventLocation?.href"
    :class="$style['lobby-sport-top-match']"
    @click.prevent="redirectToSportlineItem"
  >
    <VSkeletonImage v-if="!sportEventDetails" />
    <template v-else>
      <div :class="$style['lobby-sport-top-match__bg']">
        <VImage
          v-if="isDark && sportEventDetails.sport.representation.backgroundImage"
          v-bind="sportEventDetails.sport.representation.backgroundImage"
          :object-fit="ObjectFitOption.COVER"
          :class="$style['lobby-sport-top-match__bg-cover']"
        />
        <div
          v-else
          :class="$style['lobby-sport-top-match__bg-image']"
        />
      </div>
      <div
        :class="$style['lobby-sport-top-match__informer']"
      >
        <div
          :class="$style['lobby-sport-top-match__top']"
        >
          <SportIcon
            :sport-name="sportEventDetails.sport.representation.family"
            :style="{ fill: backgroundColor }"
            :size="'1' ? IconSize.SIZE_14 : IconSize.SIZE_12"
            :class="$style['lobby-sport-top-match__top-icon']"
          />
          <div
            :class="$style['lobby-sport-top-match__top-region']"
          >
            {{ sportEventDetails.region.name }}
          </div>
          <div
            :class="$style['lobby-sport-top-match__top-league']"
          >
            {{ sportEventDetails.league.name }}
          </div>
          <img
            v-if="isLiveSportEvent"
            :class="$style['lobby-sport-top-match__top-live-badge']"
            :src="liveBadge"
            alt="live"
          >
        </div>
        <div
          :class="$style['lobby-sport-top-match__info']"
        >
          <div :class="$style['lobby-sport-top-match__player']">
            <div :class="$style['lobby-sport-top-match__player-img-wrapper']">
              <VImage
                v-if="homeTeam?.logo"
                :src="homeTeam.logo"
                :object-fit="ObjectFitOption.COVER"
                is-lazy
              />
              <SportIcon
                v-else
                :sport-name="sportEventDetails.sport.representation.family"
                :style="{ fill: backgroundColor }"
                :size="sportIconSize"
              />
            </div>
            <div :class="$style['lobby-sport-top-match__player-name']">
              {{ homeTeam?.name }}
            </div>
          </div>
          <div :class="$style['lobby-sport-top-match__timeline']">
            <div
              v-if="sportEventDetails.hasZeroMargin"
              :class="$style['lobby-sport-top-match__zero-margin']"
            >
              <VBadge
                :kind="BadgeKind.SQUARE_INVERT"
                :label="$t('WEB2_SPORT_EVENTS_IMPROVED_ODDS')"
              />
            </div>
            <template v-if="isLiveSportEvent">
              <div :class="$style['lobby-sport-top-match__score']">
                <span>{{ totalScore.host }}</span>
                <span>:</span>
                <span>{{ totalScore.guest }}</span>
              </div>
              <div
                v-if="'1' && scoreDetailsLine"
                :class="$style['lobby-sport-top-match__live-details']"
              >
                ({{ scoreDetailsLine }})
              </div>
              <div :class="$style['lobby-sport-top-match__stage']">
                <span :class="$style['lobby-sport-top-match__stage-text']">{{ stage }}</span>
                <SportlineEventGameProgress
                  :match-progress="matchProgress"
                  :font-size="'1' ? 12 : 10"
                  :is-time-reversed-indicator-available="isTimeReversedIndicatorAvailable"
                />
              </div>
              <div
                v-if="!'1' && scoreDetailsLine"
                :class="$style['lobby-sport-top-match__live-details']"
              >
                ({{ scoreDetailsLine }})
              </div>
            </template>
            <template v-else>
              <div :class="$style['lobby-sport-top-match__date']">
                {{ date }}
              </div>

              <div :class="$style['lobby-sport-top-match__time']">
                {{ time }}
              </div>
            </template>
          </div>
          <div :class="$style['lobby-sport-top-match__player']">
            <div :class="$style['lobby-sport-top-match__player-img-wrapper']">
              <VImage
                v-if="awayTeam?.logo"
                :src="awayTeam.logo"
                :object-fit="ObjectFitOption.COVER"
                is-lazy
              />
              <SportIcon
                v-else
                :sport-name="sportEventDetails.sport.representation.family"
                :style="{ fill: backgroundColor }"
                :size="sportIconSize"
              />
            </div>
            <div :class="$style['lobby-sport-top-match__player-name']">
              {{ awayTeam?.name }}
            </div>
          </div>
        </div>

        <PrimaryMarket
          v-if="primaryMarket"
          :market="primaryMarket"
          :sport-event-details="sportEventDetails"
          @click-runner="redirectToSportlineItem"
        />
        <div
          v-else-if="isNoMarketsBlockedBtnEnabled"
          :class="$style['lobby-sport-top-match__blocked-btn']"
          @click.stop.prevent
        >
          <VIcon
            :name="IconName.LOCK"
            :size="IconSize.SIZE_24"
          />
        </div>
      </div>
    </template>
  </component>
</template>

<style lang="scss" module>
@include for-layout using($isDesktop) {
  .lobby-sport-top-match {
    position: relative;
    min-width: if($isDesktop, 307px, 196px);
    height: if($isDesktop, 264px, 196px);
    overflow: hidden;
    border-radius: var(--BorderRadius);
    box-shadow: 0 8px 16px var(--ModalShadow);
    transition: transform 0.5s ease;

    @if $isDesktop {
      &:hover {
        transform: scale(1.02);
      }
    }

    &__bg {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    &__bg-image {
      width: 100%;
      height: 100%;
      background: var(--CutomHomePageTopMatchBgGradient);
    }

    &__bg-cover {
      width: 100%;
      height: 100%;
    }

    &__informer {
      position: absolute;
      top: if($isDesktop, 24px, 16px);
      right: if($isDesktop, 18.5px, 16px);
      left: if($isDesktop, 18.5px, 16px);
    }

    &__info {
      display: flex;
      gap: 2px;
      padding: 2px 0;
      margin-bottom: if($isDesktop, 24px, 16px);
    }

    &__player {
      display: flex;
      flex-direction: column;
      gap: if($isDesktop, 16px, 8px);
      align-items: center;
      width: if($isDesktop, 130px, 81px);
    }

    &__player-img-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: if($isDesktop, 60px, 32px);
      height: if($isDesktop, 60px, 32px);
      overflow: hidden;
      background: var(--White);
      border: 2px solid var(--White);
      border-radius: 50%;
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.15);
    }

    &__player-name {
      display: -webkit-box;
      height: 32px;
      overflow: hidden;
      color: var(--TextDefault);
      text-align: center;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      @if $isDesktop {
        @include medium\14\16\025;
      } @else {
        @include medium\13\16;
      }
    }

    &__timeline {
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      overflow: hidden;
    }

    &__date {
      @include regular\13\16;

      color: var(--TextSecondary);
      text-transform: lowercase;
    }

    &__time {
      @include medium\24\28;

      color: var(--TextDefault);
    }

    &__top {
      display: flex;
      margin-bottom: if($isDesktop, 24px, 16px);
    }

    &__top-icon {
      margin: if($isDesktop, 1px, 2px);
    }

    &__top-region {
      margin-left: 4px;
      color: var(--TextPrimary);

      @if $isDesktop {
        @include regular\13\16;
      } @else {
        @include regular\12\16;
      }
    }

    &__top-league {
      flex: 1;
      margin-left: 4px;
      overflow: hidden;
      color: var(--TextDefault);
      text-overflow: ellipsis;
      white-space: nowrap;

      @if $isDesktop {
        @include medium\13\16;
      } @else {
        @include medium\12\16;
      }
    }

    &__top-live-badge {
      height: if($isDesktop, 16px, 12px);
      margin: if($isDesktop, 0 0 0 4px, 2px 0 2px 4px);
    }

    &__zero-margin {
      margin-bottom: if($isDesktop, 8px, 4px);
    }

    &__score {
      @include medium\24\28;

      display: flex;
      gap: 4px;
      align-items: center;
      color: var(--TextDefault);
    }

    &__stage {
      display: flex;
      gap: 4px;
      max-width: 100%;
      color: var(--TextPrimary);

      @if $isDesktop {
        @include regular\12\16;
      } @else {
        @include regular\10\12;
      }
    }

    &__stage-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__live-details {
      display: -webkit-box;
      max-width: 100%;
      overflow: hidden;
      color: var(--TextPrimary);
      text-align: center;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      @if $isDesktop {
        @include regular\13\16;
      } @else {
        @include regular\10\12;
      }
    }

    &__blocked-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: if($isDesktop, 320px, 275px);
      height: 44px;
      margin: 0 auto;
      color: var(--TextSecondary);
      cursor: default;
      background-color: var(--CutomHomePageRunnerBg);
      border-radius: var(--BorderRadius);
      transition: transform 0.1s;

      &:active {
        transform: scale(0.96);
      }
    }
  }
}
</style>
