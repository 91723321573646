import type {
  BetlineMatchStatisticsMatch,
  BetlineMatchStatisticsTeam,
} from 'web/src/modules/sportline/types/rest';

import { getWinnerByType } from './getWinnerByType';

export function isTeamWinMatch(
  team?: Maybe<Readonly<BetlineMatchStatisticsTeam>>,
  match?: Maybe<Readonly<BetlineMatchStatisticsMatch>>,
): boolean {
  if (!team || !match) { return false; }
  const winner = getWinnerByType(match.teams, match.winner);
  return !!winner && winner?.id === team.id;
}
