import { InputFileTypes } from '../enums';

export const getFileType = (fileName: string): InputFileTypes => {
  if (/.(jpg|png|jpeg)$/i.test(fileName)) {
    return InputFileTypes.Image;
  }

  if (/.(mp4|mov|3gp|3gpp|webm|m4v)$/i.test(fileName)) {
    return InputFileTypes.Video;
  }

  if (/.(pdf)$/i.test(fileName)) {
    return InputFileTypes.Pdf;
  }

  return InputFileTypes.Other;
};
