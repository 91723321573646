import type {
  SportEvent,
  SportElement,
} from 'web/src/modules/sportline/types';

export default function sportLineToSportMap<
  SE extends SportEvent = SportEvent,
>(list: SportElement<SE>[]): Map<string, SportElement<SE>> {
  return list.reduce((result, listElement) => {
    const sportKey = listElement.sport.navigationParameters.urlName;

    if (!result.has(sportKey)) {
      result.set(sportKey, listElement);
    } else {
      result.get(sportKey)?.regions.push(...listElement.regions);
    }
    return result;
  }, new Map<string, SportElement<SE>>());
}
