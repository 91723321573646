<script setup lang="ts">
import { toRef } from 'vue';

import { useNeedRedirectFromTab } from 'web/src/modules/sportline/components/SportlineTab/composables';
import type { SportlineTabProps, SportlineTabEmits } from 'web/src/modules/sportline/components/SportlineTab/types';
import { ExtendedEventBlock } from 'web/src/modules/sportline/submodules/extended-event/enums';
import { VLoaderMaskedDelayed } from 'web/src/components/Loader';
import SportlineSportBlock from 'web/src/modules/sportline/views/SportBlock/SportlineSportBlock.vue';
import SportNoResults from 'web/src/modules/sportline/components/NoEvents/SportNoResults.vue';
import { createSportlineFragmentBasisKey } from 'web/src/modules/sportline/composables/list/utils';

import { useSportsZeroMarginTab } from '../composables/tabs/useSportsZeroMarginTab';

const props = defineProps<SportlineTabProps>();
const emit = defineEmits<SportlineTabEmits>();

const isActive = toRef(props, 'isActive');

const {
  isMarketTypesSelectionEnabled,
  isReadyToDisplayContent,
  zeroMarginEventsList,
  hasElementsForCurrentFilter,
  isNeedRedirectFromTab,
} = useSportsZeroMarginTab({ isActive });
useNeedRedirectFromTab({ isNeedRedirectFromTab }, {
  emitRedirectFromTab() { emit('redirect-from-tab'); },
});
const basisKey = createSportlineFragmentBasisKey({ salt: 'zero-margin' });
</script>

<template>
  <div v-auto-id="'SportsZeroMarginTab'">
    <VLoaderMaskedDelayed v-if="!isReadyToDisplayContent" />
    <template v-else-if="hasElementsForCurrentFilter">
      <SportlineSportBlock
        v-for="sportElement in zeroMarginEventsList"
        :key="sportElement.sport.id"
        :sport-element="sportElement"
        :is-market-types-selection-enabled="isMarketTypesSelectionEnabled"
        :can-add-to-favorite="!!'1'"
        :extended-block-type="ExtendedEventBlock.ZeroMargin"
        :basis-key="basisKey"
      />
    </template>
    <SportNoResults
      v-else
      can-reset-filter
      @reset="emit('reset-filter')"
    />
  </div>
</template>
