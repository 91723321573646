import type {
  Plugin,
} from 'vue';
import {
  unref,
} from 'vue';
import { storeToRefs } from 'pinia';

import { useI18nStore } from 'web/src/modules/i18n/store';
import type { I18nFunction } from 'web/src/modules/i18n/types';

declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    $t: I18nFunction;
    $lang: string;
    $locale: string;
  }
}

const I18nPlugin: Plugin = {
  install(app) {
    const i18nStore = useI18nStore();
    const { lang, locale } = storeToRefs(i18nStore);
    const { getTranslationFunction } = i18nStore;

    // eslint-disable-next-line no-param-reassign
    app.config.globalProperties.$t = getTranslationFunction();

    Object.defineProperty(app.config.globalProperties, '$lang', {
      enumerable: true,
      get: () => unref(lang),
    });

    Object.defineProperty(app.config.globalProperties, '$locale', {
      enumerable: true,
      get: () => unref(locale),
    });
  },
};

export default I18nPlugin;
