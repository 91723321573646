import type { ProgramDetailsGetDataDocument } from 'web/src/modules/referral-program/types/ReferralProgramDocuments';

export default function sortByRegistrationDate(
  programDetails: ProgramDetailsGetDataDocument,
): ProgramDetailsGetDataDocument {
  return {
    ...programDetails,
    referrals: programDetails.referrals.sort((a, b) => b.registrationDate - a.registrationDate),
  };
}
