import { computed } from 'vue';
import type { ComputedRef } from 'vue';

import { FormSubmitMode } from '@leon-hub/form-utils';

import {
  getSubmitMode,
  haveEmptyRequiredFields,
  isSubmitButtonDisabled,
} from './utils/isFormReadyUtils';
import type { UseIsFormReadyOutput, UseIsFormReadyProps } from './types';

function useIsFormReady({
  validationDisabled, schema, uiSchema, formDataMap, hasAnyErrors,
}: UseIsFormReadyProps): UseIsFormReadyOutput {
  const submitMode: ComputedRef<FormSubmitMode> = computed(() => getSubmitMode(uiSchema.value));

  const isFormReady: ComputedRef<boolean> = computed(() => {
    if (validationDisabled) {
      return true;
    }
    const isSubmitButtonEnabled = !isSubmitButtonDisabled(uiSchema.value);

    if (submitMode.value === FormSubmitMode.AlwaysEnabled) {
      return isSubmitButtonEnabled;
    }

    if (submitMode.value === FormSubmitMode.DisabledByErrors) {
      const hasErrors = hasAnyErrors.value || haveEmptyRequiredFields(schema.value, uiSchema.value, formDataMap.value);

      return hasErrors ? !hasErrors : isSubmitButtonEnabled;
    }

    throw new Error('incorrect submit mode');
  });

  return {
    isFormReady,
  };
}

export default useIsFormReady;
