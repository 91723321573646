import type { SwiperNavigationButtonsProps } from 'web/src/components/Swiper/Navigation/types';

import type { VSwiperState } from '../../../VSwiper/types';

export default function getNavigationProps(
  props: SwiperNavigationButtonsProps,
  swiperState?: VSwiperState,
): SwiperNavigationButtonsProps {
  return {
    isRounded: false,
    isPreviousAllowed: swiperState?.isPreviousButtonAllowed.value,
    isNextAllowed: swiperState?.isNextButtonAllowed.value,
    buttonKind: props.buttonKind,
  };
}
