export enum AlertIconName {
  Alert = 'alert',
  Bonus = 'bonus',
  Begging = 'begging',
  Binoculars = 'binoculars',
  Clock = 'clock',
  Congratulation = 'congratulation',
  Document = 'document',
  Email = 'email',
  Error = 'error',
  Fail = 'fail',
  Info = 'info',
  Lock = 'lock',
  NoNotifications = 'no-notifications',
  Present = 'present',
  Present2 = 'present-2',
  QuestionMark = 'question-mark',
  Reading = 'reading',
  Rest = 'rest',
  Sandglass = 'sandglass',
  Success = 'success',
  ThumbsUp = 'thumbs-up',
  VideoCall = 'videocall',
  Space = 'space',
  Stop1 = 'stop-1',
  Stop2 = 'stop-2',
  Stop3 = 'stop-3',
  Stop4 = 'stop-4',
  TechnicalWork = 'technical-work',
  Wifi = 'wi-fi',
  Touchid = 'touchid',
  Shield = 'shield',
  IdCard = 'id-card',
  Share = 'share',
  Withdrawal = 'withdrawal',
  GeoTag = 'geotag',
}
