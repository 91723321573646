<script lang="ts" setup>
// disable rule for directives (for some reason it doesn't find them )
/* eslint-disable vue/no-undef-properties */
import {
  ref,
  toRef,
  onActivated,
  onDeactivated,
} from 'vue';

import { useDoShowMatchProgress } from 'web/src/modules/sportline/submodules/event-details/components/progress-bar/composables';
import type { LiveSportEventDetails } from 'web/src/modules/sportline/submodules/event-details/types';
import { ProgressKind } from 'web/src/components/LinearProgress/VLinearProgress/enums';
import SportlineLiveEventProgress from 'web/src/modules/sportline/submodules/event-details/components/progress-bar/SportlineLiveEventProgress.vue';
import PowerPlayBadge from 'web/src/modules/sportline/submodules/event-details/components/power-play/PowerPlayBadge.vue';
import { vTimer } from 'web/src/modules/sportline/directives';

import { useEventDetailsMiniScoreBoard } from './useEventDetailsMiniScoreBoard';

interface EventDetailsMiniScoreBoardProps {
  sportEventDetails: LiveSportEventDetails;
}

interface EventDetailsMiniScoreBoardEmits {
  (e: 'click', event: Event): void;
}

const props = defineProps<EventDetailsMiniScoreBoardProps>();
const emit = defineEmits<EventDetailsMiniScoreBoardEmits>();

const sportlineEvent = toRef(props, 'sportEventDetails');

const isActive = ref(true);

const {
  isTimeReversedIndicatorAvailable,
  totalScore,
  stage,
  gameScoreProgress,
  isStaticLiveProgress,
  isTimeReversed,
  staticReversedTimeProgressLabel,
  staticDirectTimeProgressLabel,
  additionalTime,
  mainTimeTotalSeconds,
  mainTimeProgress,
  mainTimeProgressShortFormat,
  additionalTimeTotalSeconds,
  additionalTimeProgress,
  scoreDetailsLine,
  mainTimeDirectiveDirection,
  additionalTimeDirectiveDirection,
} = useEventDetailsMiniScoreBoard({
  sportEvent: sportlineEvent,
});
const { doShowTimeline } = useDoShowMatchProgress({ sportlineEvent });

function emitClick(event: Event): void {
  emit('click', event);
}

onActivated(() => {
  isActive.value = true;
});

onDeactivated(() => {
  isActive.value = false;
});
</script>

<template>
  <div v-auto-id="'EventDetailsMiniScoreBoard'"
    v-data-test="{ el: 'mini-score-board' }"
    :class="$style['sport-event-details-score']"
    @click="emitClick"
  >
    <div :class="$style['mini-score-board-top']">
      <div
        v-data-test="{ el: 'mini-score-board__team--home' }"
        :class="[
          $style['mini-score-board-top__side'],
          $style['scoreboard-team'],
          $style['scoreboard-team--host'],
        ]"
      >
        <div
          v-data-test="{ el: 'mini-score-board__team-name' }"
          :class="$style['scoreboard-team__label']"
        >
          {{ sportEventDetails.competitors[0]?.name }}
        </div>

        <PowerPlayBadge
          v-if="sportEventDetails.incidentStatistics.host.powerPlay"
          v-data-test="{ el: 'mini-score-board__team-power-play' }"
          :class="$style['team-power-play']"
          :count="sportEventDetails.incidentStatistics.host.powerPlay"
        />

        <span
          v-data-test="{ el: 'mini-score-board__team-score' }"
          :class="$style['scoreboard-team__score']"
        >{{ totalScore.host }}</span>
      </div>

      <div :class="$style['mini-score-board-top__divider']" />

      <div
        v-data-test="{ el: 'mini-score-board__team--away' }"
        :class="[
          $style['mini-score-board-top__side'],
          $style['scoreboard-team'],
          $style['scoreboard-team--guest'],
        ]"
      >
        <span
          v-data-test="{ el: 'mini-score-board__team-score' }"
          :class="$style['scoreboard-team__score']"
        >{{ totalScore.guest }}</span>

        <PowerPlayBadge
          v-if="sportEventDetails.incidentStatistics.guest.powerPlay"
          v-data-test="{ el: 'mini-score-board__team-power-play' }"
          :class="$style['team-power-play']"
          :count="sportEventDetails.incidentStatistics.guest.powerPlay"
        />

        <div
          v-data-test="{ el: 'mini-score-board__team-name' }"
          :class="$style['scoreboard-team__label']"
        >
          {{ sportEventDetails.competitors[1]?.name }}
        </div>
      </div>
    </div>

    <div
      v-data-test="{ el: 'mini-score-board__stage-info' }"
      :class="$style['mini-score-board-bottom']"
    >
      <span :class="$style['mini-score-board-bottom__period']">
        {{ stage }}
        <span
          v-if="gameScoreProgress"
          key="progress"
        >
          {{ gameScoreProgress }}
        </span>
        <template v-else-if="isStaticLiveProgress">
          <template v-if="isTimeReversedIndicatorAvailable && isTimeReversed">
            <span
              v-if="staticReversedTimeProgressLabel"
              key="progress"
              :class="$style['sport-event-details-score__reversed-time-indicator']"
            >
              {{ staticReversedTimeProgressLabel }}
            </span>
          </template>
          <span
            v-else-if="staticDirectTimeProgressLabel"
            key="progress-direct"
          >
            {{ staticDirectTimeProgressLabel }}
          </span>
        </template>
        <span
          v-else
          key="progress-inactive"
        >
          <template v-if="isActive">
            <template v-if="!additionalTime">
              <span
                v-timer:[mainTimeDirectiveDirection]="mainTimeTotalSeconds"
              >{{ mainTimeProgress }}</span>
            </template>
            <template v-else>
              {{ mainTimeProgressShortFormat }}
              +<span
                v-timer:[additionalTimeDirectiveDirection]="additionalTimeTotalSeconds"
              >{{ additionalTimeProgress }}</span>
            </template>
          </template>
        </span>
      </span>

      <span
        v-if="scoreDetailsLine"
        :class="$style['mini-score-board-bottom__label']"
      >
        {{ `(${scoreDetailsLine})` }}
      </span>
    </div>

    <SportlineLiveEventProgress
      v-if="doShowTimeline"
      v-data-test="{ el: 'mini-score-board__progress' }"
      :class="$style['live-match-info-progress-bar']"
      :sportline-event="sportEventDetails"
      :progress-kind="ProgressKind.DEFAULT"
    />
    <div v-else />
  </div>
</template>

<style module lang="scss">
@include for-layout using ($isDesktop) {
  .sport-event-details-score {
    z-index: z-index(score-mini-board);
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;
    padding: 6px 0 0;
    cursor: pointer;
    background-color: var(--Layer1);
    filter: var(--FloatingTopBarShadowFilter);
    border-top: 1px solid var(--Layer0);
    opacity: 1;
  }

  .mini-score-board-top {
    display: flex;
    gap: 8px;
    width: 100%;
    padding: 0 16px;

    &__side {
      flex: 1;
    }

    &__divider {
      position: relative;
      flex: 1px 0 0;
      width: 1px;
      height: 16px;
      margin-top: 1px;
      background-color: var(--Invert);
      opacity: 0.3;
    }
  }

  .mini-score-board-bottom {
    display: flex;
    justify-content: center;
    line-height: 12px;
    color: var(--TextPrimary);
    opacity: 0.8;

    &__period {
      @include regular\10\12;

      padding-right: 3px;
      color: var(--TextPrimary);
    }

    &__label {
      @include regular\10\12;

      display: inline-block;
    }
  }

  .live-match-info-progress-bar {
    width: if($isDesktop, 300px, 100%);
    height: 2px;
    margin: 0 auto;
  }

  .scoreboard-team {
    display: flex;
    gap: 4px;
    align-items: center;
    width: calc(50% - 8.5px);
    line-height: 16px;

    &--host {
      justify-content: flex-end;

      & .team-power-play {
        margin-right: 4px;
      }
    }

    &--guest {
      justify-content: flex-start;

      & .team-power-play {
        margin-left: 4px;
      }
    }

    &__label {
      @include regular\14\20\025;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__score {
      @include medium\16\20\025;

      color: var(--BrandDefault);
    }
  }
}
</style>
