import { defineStore } from 'pinia';
import {
  computed,
  ref,
  toRef,
} from 'vue';

import { BannerType, getBanner } from '@leon-hub/api-sdk';
import { Timer } from '@leon-hub/utils';

import type { SportEventDetails } from 'web/src/modules/sportline/submodules/event-details/types';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import { DetailsAdapter } from 'web/src/modules/sportline/utils/rest';
import type { Market } from 'web/src/modules/sportline/types';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';

import useBannersStore from './useBannersStore';
import type { Banner, BannerGroup } from './types';
import {
  mapBannerEvent,
  updateSingleBannerInGroups,
  deleteSingleBannerInGroups,
} from './utils';

/**
 * banner.bannerId and banner.id are different fields.
 * banner.id is depends on images hash or randomly generated, bannerId is generated by BD.
 * What's why we need to relay on banner.bannerId to request updates
 * */

const useSportBannersStore = defineStore('sport-banners', () => {
  const api = useGraphqlClient();

  const sportlineSettingsStore = useSportlineSettingsStore();
  const parseSportlineSettings = toRef(sportlineSettingsStore, 'parseSportlineSettings');
  const sportLineUpdateInterval = toRef(sportlineSettingsStore, 'sportLineUpdateInterval');

  const bannersStore = useBannersStore();

  const { setBannerGroups } = bannersStore;
  const bannerGroups = toRef(bannersStore, 'bannerGroups');

  const currentBannerGroupId = ref<string | null>(null);

  const setCurrentBannerGroupId = (value: string | null): void => {
    currentBannerGroupId.value = value;
  };

  const currentHeadlineBannerGroup = computed<BannerGroup | null>(() => {
    if (!currentBannerGroupId.value) {
      return null;
    }
    return bannerGroups.value.find((group) => group.id === currentBannerGroupId.value) ?? null;
  });

  const bannersWithSportEvent = computed<Banner[]>(() => {
    if (!currentHeadlineBannerGroup.value?.banners) {
      return [];
    }
    return currentHeadlineBannerGroup.value.banners.filter((banner) => banner.bannerType === BannerType.SPORT && banner.event);
  });

  const currentSportBannerId = ref<string | null>(null);

  const setCurrentSportBannerId = (bannerId: string | null): void => {
    currentSportBannerId.value = bannerId;
  };

  const currentSportBanner = computed<Banner | null>(() => {
    if (!currentSportBannerId.value) {
      return null;
    }
    return bannersWithSportEvent.value?.find((banner) => banner.bannerId === currentSportBannerId.value) ?? null;
  });

  const updateSingleHeadlineBanner = (banner: Banner): void => {
    if (!currentBannerGroupId.value || !bannerGroups.value) {
      return;
    }
    const updatedGroups = updateSingleBannerInGroups({
      groupId: currentBannerGroupId.value,
      bannerGroups: bannerGroups.value,
      updatedBanner: banner,
    });
    setBannerGroups(updatedGroups);
  };

  const removeSingleHeadlineBanner = (bannerId: string): void => {
    if (!currentBannerGroupId.value || !bannerGroups.value) {
      return;
    }
    const clearedBannerGroups = deleteSingleBannerInGroups({
      groupId: currentBannerGroupId.value,
      bannerGroups: bannerGroups.value,
      bannerId,
    });
    setBannerGroups(clearedBannerGroups);
  };

  async function updateHeadlineBanner(bannerId: string): Promise<void> {
    if (!currentBannerGroupId.value) {
      return;
    }
    const updateResult = await getBanner(api,
      (node) => node.queries.banners.getBanner,
      {
        options: {
          groupId: currentBannerGroupId.value,
          id: bannerId,
        },
      },
      { silent: true });
    if (updateResult?.banner) {
      updateSingleHeadlineBanner(updateResult.banner);
    } else if (updateResult?.banner === null) {
      // sport event is not available, hide banner
      removeSingleHeadlineBanner(bannerId);
    }
  }

  const getDetailsForCurrentSlide = async (): Promise<void> => {
    const eventId = currentSportBanner.value?.event?.id;
    if (!currentSportBannerId.value || !eventId) {
      return;
    }
    await updateHeadlineBanner(currentSportBannerId.value);
  };

  const currentEventDetails = computed<SportEventDetails | null>(() => {
    if (!currentSportBanner.value?.event) {
      return null;
    }
    return (new DetailsAdapter(mapBannerEvent(currentSportBanner.value.event), parseSportlineSettings.value))
      .sportEventDetails;
  });

  const currentMarket = computed<Market | null>(() => {
    if (!currentEventDetails.value) {
      return null;
    }
    return currentEventDetails.value?.markets[0] ?? null;
  });

  let updateInterval: number | null = null;

  const startUpdate = (): void => {
    updateInterval = Timer.setInterval((() => {
      void getDetailsForCurrentSlide();
    }), sportLineUpdateInterval.value);
  };

  const stopUpdate = (): void => {
    Timer.clearInterval(updateInterval ?? 0);
    updateInterval = null;
  };

  const activateSlide = (bannerId: string, groupId: string): void => {
    setCurrentSportBannerId(bannerId);
    setCurrentBannerGroupId(groupId);
    void getDetailsForCurrentSlide();
    startUpdate();
  };

  const deactivateSlide = (bannerId: string): void => {
    if (bannerId !== currentSportBannerId.value) {
      return;
    }
    stopUpdate();
    setCurrentSportBannerId(null);
    setCurrentBannerGroupId(null);
  };

  return {
    activateSlide,
    deactivateSlide,
    currentEventDetails,
    currentMarket,
  };
});

export default useSportBannersStore;
