import type { ComputedRef, Ref } from 'vue';
import { computed, ref, toRef } from 'vue';
import { useRouter } from 'vue-router';

import { Timer } from '@leon-hub/utils';
import { RouteName } from '@leon-hub/routing-config-names';
import type { SlipTypeId } from '@leon-hub/api-sdk';
import { helpButtonModeHelp } from '@leon-hub/api-sdk';

import { useAnalytics } from 'web/src/modules/analytics/composables';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { useCashoutStore } from 'web/src/modules/cashout/store';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useFastBetsValues } from 'web/src/modules/fast-bets/composables';
import { useCurrencyStore } from 'web/src/modules/money/store';
import { useSiteConfigStore } from 'web/src/modules/core/store';

import { usePlaceBetStore } from '../submodules/place-bet/store';
import { usePendingBetsStore } from '../submodules/pending-bets/store';
import useSlipUserSettings from '../submodules/slip-user-settings/store/useSlipUserSettings';
import type {
  MultisingleInputPayload,
  SinglesKeyBoardInputPayload,
} from '../submodules/multi-singles/types';
import type { SlipEntryId } from '../submodules/slip-info/types';
import type { SlipClosingType } from '../components/SlipContainer/enums';
import {
  BetSlipResultState,
  SettingsTabs,
  TopLevelTabs,
} from '../enums';
import type { LeaveSlipResultChoice } from '../enums';
import type { SlipContainerMobileProps } from '../components/SlipContainer/types';
import type { SlipLayoutProps } from '../components/SlipLayout/types';
import type { BetSlipResultProps } from '../components/BetSlipResult/types';
import type { BetSlipMainProps } from '../components/BetSlipMain/types';
import { useSlipRootStore } from '../submodules/slip-root/store';
import { useSlipViewSettingsStore } from '../submodules/slip-view-settings/store';
import { useStakeInputStore } from '../submodules/stake-input/store';
import { useSlipInfoStore } from '../submodules/slip-info/store';
import { useMultiSinglesStore } from '../submodules/multi-singles/store';

interface UseBetSlipRoot {
  // BetSlipContainer related
  containerProps: ComputedRef<SlipContainerMobileProps>;
  onDisplayAnimationEnd: () => void;
  onManualClose: () => void;
  // BetSlipView related
  slipLayoutProps: ComputedRef<SlipLayoutProps>;
  isClearBetListWarnVisible: Ref<boolean>;
  selectTopTab: (id: TopLevelTabs) => void;
  onClearBetsCancel: () => void;
  onClearBetsConfirm: () => void;
  handleInnerScroll: () => void;
  showSlipSettings: () => void;
  // loader and empty placeholder
  isRestoringSlipFromLS: Ref<boolean>;
  isEmpty: ComputedRef<boolean>;
  isEmptySlipButtonHidden: ComputedRef<boolean>;
  emptySlipButtonLabel: ComputedRef<string>;
  emptySlipButtonClick: () => void;
  // BetSlipMain
  betSlipMainProps: ComputedRef<BetSlipMainProps>;
  onSelectBetMode: (mode: SlipTypeId) => void;
  onEventDeleteClick: (id: SlipEntryId) => Promise<void>;
  onBankerClick: (id: SlipEntryId) => Promise<void>;
  onSingleStakeInput: (payload: MultisingleInputPayload) => void;
  onMultiSinglesInputFocus: (id: SlipEntryId) => void;
  onMultiSinglesInputBlur: (id: SlipEntryId) => void;
  onAcceptSinglePriceChanges: (id: SlipEntryId) => Promise<void>;
  editFastBets: () => void;
  handleSinglesMobileInput: (payload: SinglesKeyBoardInputPayload) => void;
  // SlipResult
  resultViewProps: ComputedRef<BetSlipResultProps>;
  switchToMyBets: () => void;
  handleSlipResultLeave: (closeChoice: LeaveSlipResultChoice) => void;
  handleLimitsClick: () => void;
  resetResultErrorState: () => void;
  // life-cycle
  onDisappear: () => void;
}

export default function useBetSlipRoot(): UseBetSlipRoot {
  const { $translate } = useI18n();

  const router = useRouter();

  const slipRootStore = useSlipRootStore();

  const slipViewSettingsStore = useSlipViewSettingsStore();

  const slipSettingsStore = useSlipUserSettings();

  const pendingBetsStore = usePendingBetsStore();

  const { isLoggedIn } = useIsLoggedIn();

  const analytics = useAnalytics();

  const cashoutStore = useCashoutStore();

  const stakeInputStore = useStakeInputStore();

  const slipInfoStore = useSlipInfoStore();

  const {
    handleDisplayAnimationEnd,
    handleSlipClose,
    clearBetList,
    switchToMyBets,
  } = slipRootStore;

  const {
    setUpdateLockStatus,
    acceptSinglePriceChanges,
    selectBetMode,
    removeBetClick,
    toggleBanker,
  } = slipInfoStore;

  const multiSinglesStore = useMultiSinglesStore();

  const {
    updateStakeValueInMultiSingles,
    onMultiSinglesInputFocus,
    onMultiSinglesInputBlur,
    handleSinglesMobileInput,
  } = multiSinglesStore;

  const placeBetStore = usePlaceBetStore();

  const { handleSlipResultLeave, resetResultErrorState, onLimitsButtonClick } = placeBetStore;

  const resultState = toRef(placeBetStore, 'resultState');
  const placeBetErrorText = toRef(placeBetStore, 'placeBetErrorText');
  const limitsExceeded = toRef(placeBetStore, 'limitsExceeded');
  const totalEntriesInSlip = toRef(placeBetStore, 'totalEntriesInSlip');
  const totalBetsAccepted = toRef(placeBetStore, 'totalBetsAccepted');

  // container

  const isHiddenOnMobile = toRef(slipViewSettingsStore, 'isHiddenOnMobile');
  const isFullHeight = toRef(slipViewSettingsStore, 'isFullHeight');
  const activeTopTabId = toRef(slipViewSettingsStore, 'activeTopTabId');

  /** prop to keep height of mobile slip if user changing tabs. Can't relay on betMode coz
     * it will lead to another bugs */
  const lastManuallySelectedTab = ref<string>('');

  const safeActiveTopTab = computed<TopLevelTabs>(() => {
    if (!isLoggedIn.value) {
      return TopLevelTabs.SLIP;
    }
    return activeTopTabId.value;
  });

  const isCloseUnavailable = computed<boolean>(
    () => resultState.value === BetSlipResultState.PENDING || resultState.value === BetSlipResultState.WAIT_FOR_RETRY,
  );

  const isCurrentlyScrolling = ref<boolean>(false);

  const setCurrentlyScrolling = (isScrolling: boolean): void => {
    isCurrentlyScrolling.value = isScrolling;
  };

  let scrollingTimeoutId = 0;

  const containerProps = computed<SlipContainerMobileProps>(() => {
    if (process.env.VUE_APP_LAYOUT_DESKTOP) {
      return {};
    }
    const selectedContentTab = safeActiveTopTab.value === TopLevelTabs.MY_BETS
      ? safeActiveTopTab.value
      : `${safeActiveTopTab.value}/${lastManuallySelectedTab.value}`;
    return {
      fullHeight: isFullHeight.value,
      isHidden: isHiddenOnMobile.value,
      closeDisabled: isCloseUnavailable.value,
      isCurrentlyScrolling: isCurrentlyScrolling.value,
      selectedContentTab,
    };
  });

  const onManualClose = (closingType?: SlipClosingType | null): void => {
    handleSlipClose();
    if (!process.env.VUE_APP_LAYOUT_DESKTOP && closingType) {
      analytics.clickMap({ closeBetSlip: closingType });
    }
  };

  // BetSlipView related

  const isSettingsVisible = toRef(slipViewSettingsStore, 'isSettingsVisible');
  const isClearBetListWarnVisible = toRef(slipViewSettingsStore, 'isClearBetListWarnVisible');
  const activeSettingsTabId = toRef(slipSettingsStore, 'activeSettingsTabId');
  const pendingBetsCount = toRef(pendingBetsStore, 'pendingBetsCount');
  const slipCashoutInProgress = toRef(cashoutStore, 'slipCashoutInProgress');
  const isMobileKeyboardShown = toRef(stakeInputStore, 'isMobileKeyboardShown');

  const displayedSlipEventsCount = toRef(slipInfoStore, 'displayedSlipEventsCount');

  const supportBlock = toRef(useSiteConfigStore(), 'supportBlock');

  const isDesktopHelpButtonEnabled = computed<boolean>(() => supportBlock.value?.helpButtonMode === helpButtonModeHelp);

  const slipLayoutProps = computed<SlipLayoutProps>(() => ({
    isLogged: isLoggedIn.value,
    activeTopTabId: safeActiveTopTab.value,
    isSettingsVisible: isSettingsVisible.value,
    slipSize: displayedSlipEventsCount.value,
    myBetsCount: pendingBetsCount.value,
    resultState: resultState.value,
    activeSettingsTabId: activeSettingsTabId.value,
    cashoutInProgress: slipCashoutInProgress.value,
    showMobileKeyboard: isMobileKeyboardShown.value,
    showHelpButton: process.env.VUE_APP_LAYOUT_DESKTOP ? isDesktopHelpButtonEnabled.value : false,
    clearBetListWarnVisible: isClearBetListWarnVisible.value,
  }));

  const {
    selectTopTab,
    showSlipSettings,
    setClearBetListWarnVisibility,
  } = slipViewSettingsStore;

  const onClearBetsCancel = (): void => {
    setClearBetListWarnVisibility(false);
  };

  const handleInnerScroll = (): void => {
    Timer.clearTimeout(scrollingTimeoutId);
    setCurrentlyScrolling(true);
    setUpdateLockStatus(true);
    scrollingTimeoutId = Timer.setTimeout(() => {
      setCurrentlyScrolling(false);
      setUpdateLockStatus(false);
    }, 300);
  };

  const {
    selectSettingsTab,
  } = slipSettingsStore;

  // loader and empty placeholder

  const isRestoringSlipFromLS = toRef(slipInfoStore, 'restoringSlipFromLS');
  const slipEventsCount = toRef(slipInfoStore, 'slipEventsCount');

  const isEmpty = computed<boolean>(() => !isRestoringSlipFromLS.value && !slipEventsCount.value);

  const isEmptySlipButtonHidden = computed<boolean>(() => {
    if (!process.env.VUE_APP_FEATURE_MULTIPLE_LOCALES_ENABLED) {
      return false;
    }
    return !isLoggedIn.value;
  });

  const emptySlipButtonLabel = computed<string>(() => (isLoggedIn.value
    ? $translate('JS_SLIP_BET_SETTINGS').value
    : $translate('JSPNAV_BETSLIP_HOWTOPLACE_BET').value
  ));

  const emptySlipButtonClick = (): void => {
    if (!isLoggedIn.value) {
      handleSlipClose();
      void router.push({ name: RouteName.CMS_HOW_TO_BET });
    } else {
      showSlipSettings();
    }
  };

  // BetSlipMain

  const currencyStore = useCurrencyStore();

  const currencySymbol = toRef(currencyStore, 'formattedCurrency');

  const { fastBetsOptions, fastBetsShowMax, isFastBetsEnabled } = useFastBetsValues();

  const stakeInputValidationTimeout = toRef(stakeInputStore, 'stakeInputValidationTimeout');

  const betEvents = toRef(slipInfoStore, 'betEvents');
  const singleModeAvailable = toRef(slipInfoStore, 'singleModeAvailable');
  const expressModeAvailable = toRef(slipInfoStore, 'expressModeAvailable');
  const systemModeAvailable = toRef(slipInfoStore, 'systemModeAvailable');
  const bankersAvailable = toRef(slipInfoStore, 'bankersAvailable');
  const isMultiSinglesMode = toRef(slipInfoStore, 'isMultiSinglesMode');
  const betMode = toRef(slipInfoStore, 'betMode');

  const sameStakeForSingles = toRef(multiSinglesStore, 'sameStakeForSingles');
  const hideInputErrorsId = toRef(multiSinglesStore, 'hideInputErrorsId');
  const lastFocusedItemId = toRef(multiSinglesStore, 'lastFocusedItemId');
  const focusedItemId = toRef(multiSinglesStore, 'focusedItemId');

  const betSlipMainProps = computed<BetSlipMainProps>(() => ({
    isHidden: process.env.VUE_APP_LAYOUT_DESKTOP ? false : isHiddenOnMobile.value,
    betMode: betMode.value,
    singleModeDisabled: !singleModeAvailable.value,
    expressModeDisabled: !expressModeAvailable.value,
    systemModeDisabled: !systemModeAvailable.value,
    betEvents: betEvents.value,
    bankersAvailable: bankersAvailable.value,
    fastBetsItems: fastBetsOptions.value,
    isLoggedIn: isLoggedIn.value,
    maxFastBetEnabled: fastBetsShowMax.value,
    stakeInputValidationTimeout: stakeInputValidationTimeout.value,
    sameStakeForSingles: sameStakeForSingles.value,
    isMultiSinglesMode: isMultiSinglesMode.value,
    isFastBetsEnabled: isFastBetsEnabled.value,
    currencySymbol: currencySymbol.value,
    showStakeInput: isMultiSinglesMode.value && !sameStakeForSingles.value,
    hideInputErrorsId: hideInputErrorsId.value ?? undefined,
    lastFocusedItemId: lastFocusedItemId.value ?? undefined,
    focusedItemId: focusedItemId.value ?? undefined,
  }));

  const onSelectBetMode = (mode: SlipTypeId): void => {
    void selectBetMode(mode);
    lastManuallySelectedTab.value = mode;
  };

  const editFastBets = (): void => {
    selectSettingsTab(SettingsTabs.FAST_BETS);
    showSlipSettings();
  };

  // SlipResult

  const resultViewProps = computed<BetSlipResultProps>(() => ({
    mode: resultState.value,
    errorMessage: placeBetErrorText.value,
    limitsExceeded: limitsExceeded.value,
    totalBetsPlaced: totalEntriesInSlip.value,
    totalBetsAccepted: totalBetsAccepted.value,
    isMultiSinglesMode: isMultiSinglesMode.value,
  }));

  const handleLimitsClick = (): void => {
    onLimitsButtonClick();
    void router.push({ name: RouteName.RESPONSIBLE_GAMBLING_BETTING_LIMITS });
  };

  // life-cycle related

  const onDisappear = (): void => {
    Timer.clearTimeout(scrollingTimeoutId);
    isCurrentlyScrolling.value = false;
  };

  return {
    // BetSlipContainer related
    containerProps,
    onDisplayAnimationEnd: handleDisplayAnimationEnd,
    onManualClose,
    // SlipLayout related
    slipLayoutProps,
    isClearBetListWarnVisible,
    selectTopTab,
    showSlipSettings,
    onClearBetsCancel,
    onClearBetsConfirm: clearBetList,
    handleInnerScroll,
    // loader and empty placeholder
    isRestoringSlipFromLS,
    isEmpty,
    isEmptySlipButtonHidden,
    emptySlipButtonLabel,
    emptySlipButtonClick,
    // BetSlipMain
    betSlipMainProps,
    onSelectBetMode,
    onEventDeleteClick: removeBetClick,
    onBankerClick: toggleBanker,
    onSingleStakeInput: updateStakeValueInMultiSingles,
    onMultiSinglesInputFocus,
    onMultiSinglesInputBlur,
    onAcceptSinglePriceChanges: acceptSinglePriceChanges,
    editFastBets,
    handleSinglesMobileInput,
    // SlipResult
    resultViewProps,
    switchToMyBets,
    handleSlipResultLeave,
    handleLimitsClick,
    resetResultErrorState,
    // life-cycle related
    onDisappear,
  };
}
