import type { Ref } from 'vue';
import {
  computed,
  toRef,
} from 'vue';

import { useCustomerNotificationsStore } from 'web/src/modules/customer-notifications/store';
import getOptionalString from 'web/src/utils/getOptionalString';

export interface CustomerNotificationsUnreadCounterComposable {
  unreadCounter: Ref<string | undefined>;
}

export default function useCustomerNotificationsUnreadCounter(): CustomerNotificationsUnreadCounterComposable {
  const unreadTotalCounter = toRef(useCustomerNotificationsStore(), 'unreadTotalCounter');
  const unreadCounter = computed(() => getOptionalString(unreadTotalCounter.value));

  return {
    unreadCounter,
  };
}
