import type { Ref } from 'vue';
import { toRef } from 'vue';

import type { SportlineSettingsStore } from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';

export interface UseExtendedEventSettingsComposable {
  extendedEventDetailsMarketLimit: Ref<SportlineSettingsStore['listMarketsDropDownLimit']>;
  isMarketGroupsTabsEnabled: Ref<SportlineSettingsStore['isMarketGroupsTabsEnabled']>;
  parseSportlineSettings: Ref<SportlineSettingsStore['parseSportlineSettings']>;
}

export function useExtendedEventSettings(): UseExtendedEventSettingsComposable {
  const settingsStore = useSportlineSettingsStore();

  const extendedEventDetailsMarketLimit = toRef(settingsStore, 'listMarketsDropDownLimit');
  const isMarketGroupsTabsEnabled = toRef(settingsStore, 'isMarketGroupsTabsEnabled');
  const parseSportlineSettings = toRef(settingsStore, 'parseSportlineSettings');

  return {
    extendedEventDetailsMarketLimit,
    isMarketGroupsTabsEnabled,
    parseSportlineSettings,
  };
}
