export enum LandingStylePreset {
  PRESET_1 = 'preset-1',
  PRESET_2 = 'preset-2',
  PRESET_3 = 'preset-3',
}

export enum LandingStyleAnimation {
  NO_ANIMATION = 'no-animation',
  ANIMATION_1 = 'animation-1',
}

export enum OrientationImage {
  SQUARE = 'square',
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal',
}
