<script setup lang="ts">
import { SingleSelect } from 'web/src/components/Select';
import type { BaseInputEmits } from 'web/src/components/Input/types';
import { VLoaderDelayed } from 'web/src/components/Loader';

import { useCurrencySelect } from './composables';
import type { CurrencySelectProps } from './types';

const props = withDefaults(defineProps<CurrencySelectProps>(), {
  loaderDelay: 400,
});

const emits = defineEmits<BaseInputEmits>();

const {
  selectProps,
  isLoading,
  emitFocus,
  emitChange,
  emitBlur,
} = useCurrencySelect(props, emits);
</script>

<template>
  <SingleSelect v-auto-id="'CurrencySelect'"
    v-bind="selectProps"
    @change="emitChange"
    @focus="emitFocus"
    @blur="emitBlur"
  >
    <template #iconPrefix>
      <VLoaderDelayed
        v-if="isLoading"
        :delay="loaderDelay"
        :class="$style['currency-select-loader']"
      />
    </template>
  </SingleSelect>
</template>

<style lang="scss" module>
.currency-select-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
