import { EditionKey } from '@leon-hub/environment-editions';
import type { Conditions } from '@leon-hub/environment-client';

import { useRootStore } from 'web/src/modules/core/store';

export function useConditionValue<T extends keyof Conditions>(name: T): string | undefined {
  switch (name) {
    case EditionKey.PRODUCT:
      return process.env.VUE_APP_PRODUCT;
    case EditionKey.RENDERING:
      return process.env.VUE_APP_RENDERING;
    case 'version':
      return process.env.VUE_APP_VERSION;
    case EditionKey.PLATFORM:
      return process.env.VUE_APP_PLATFORM;
    case EditionKey.MODERNITY:
      return process.env.VUE_APP_MODERNITY;
    case EditionKey.ENV:
      return process.env.VUE_APP_ENV;
    case EditionKey.SKIN:
      return process.env.VUE_APP_SKIN;
    case EditionKey.STYLE:
      return process.env.VUE_APP_STYLE;
    case EditionKey.OS:
      return process.env.VUE_APP_OS;
    case EditionKey.LAYOUT:
      return process.env.VUE_APP_LAYOUT;
    case EditionKey.BROWSER:
      return process.env.VUE_APP_BROWSER;
    case EditionKey.THEME:
      return process.env.VUE_APP_THEME;
    case 'directLink':
      return useRootStore().isDirectLink ? 'true' : undefined;
    case 'inFrame':
      if (!process.env.VUE_APP_RENDERING_SSR) {
        try {
          if (window.self !== window.top) {
            return '1';
          }
        } catch {
          // empty
        }
      }
      return undefined;
    default:
      throw new Error(`Undefined env variable: ${name}`);
  }
}
