<script setup lang="ts">
import { IconName, IconSize } from '@leon-hub/icons';

import { VIcon } from '@components/v-icon';

import type { WinBoostInfoProps } from './types';
import { useWinBoostInfo } from './composables/useWinBoostInfo';

const props = defineProps<WinBoostInfoProps>();

const {
  onInfoClick,
  currentBoostText,
  nextBoostText,
  currentBonus,
} = useWinBoostInfo(props);
</script>

<template>
  <div v-auto-id="'WinBoostInfo'" :class="$style['win-boost-info']">
    <button
      type="button"
      :class="$style['win-boost-info__help']"
      :title="$t('WEB2_CBC_PROMO_MORE')"
      @click="onInfoClick"
    >
      <VIcon
        :name="IconName.INFO_OUTLINE"
        :size="IconSize.SIZE_16"
      />
    </button>
    <div :class="$style['win-boost-info__main']">
      <p
        v-if="currentBoost"
        :class="$style['win-boost-info__line']"
      >
        {{ currentBoostText }}
      </p>
      <p
        v-if="nextBoost && currentBoost !== nextBoost"
        :class="[
          $style['win-boost-info__line'],
          $style['win-boost-info__line--primary'],
        ]"
      >
        {{ nextBoostText }}
      </p>
    </div>
    <div :class="$style['win-boost-info__right']">
      <div
        v-if="currentBonus"
        :class="$style['win-boost-info__bonus']"
      >
        {{ currentBonus }}
      </div>
    </div>
  </div>
</template>

<style module lang="scss">
  .win-boost-info {
    display: flex;
    flex: none;
    align-items: stretch;
    overflow: hidden;
    background: var(--OpacityBrandDefault);
    border: solid var(--Layer0);
    border-width: 8px 0;

    &__help {
      display: flex;
      flex: none;
      align-items: center;
      justify-content: center;
      width: 32px;
      color: var(--TextSecondary);
      cursor: help;
      background: none;
      border: none;
    }

    &__main {
      @include regular\12\16;

      display: flex;
      flex: 1;
      flex-direction: column;
      padding: 8px 0;
      color: var(--TextDefault);
    }

    &__line {
      padding: 0;
      margin: 0;

      &--primary {
        color: var(--TextPrimary);
      }
    }

    &__right {
      display: flex;
      flex: none;
      align-items: center;
      justify-content: center;
      padding: 0 12px;
    }

    &__bonus {
      @include medium\13\16;

      padding: 4px;
      color: var(--BrandHighlight);
      white-space: nowrap;
      background: var(--OpacityBrandDefault);
      border-radius: 5px;
    }
  }
</style>
