import { getBalance as getBalanceSdk } from '@leon-hub/api-sdk';

import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';

import type { GetBalanceDocument } from '../types';

export interface UseCustomerFinanaceApi {
  getBalance(silent?: boolean): Promise<GetBalanceDocument>;
}

export default function useCustomerFinanceApi(): UseCustomerFinanaceApi {
  const apiClient = useGraphqlClient();

  function getBalance(silent?: boolean): Promise<GetBalanceDocument> {
    return getBalanceSdk(apiClient,
      (node) => node.queries.finance.getBalance,
      undefined,
      { silent });
  }

  return {
    getBalance,
  };
}
