import type { Ref } from 'vue';
import { computed, watch } from 'vue';

import { IconName, IconSize } from '@leon-hub/icons';

import { useI18n } from 'web/src/modules/i18n/composables';
import type { MarketsGridFilter, MarketsGridFilterId } from 'web/src/modules/sportline/submodules/markets-grid/types';
import type { TabsItem } from 'web/src/components/Tabs/types';
import type { VSwiperRef } from 'web/src/components/Swiper/VSwiper/types';
import { TabsType } from 'web/src/components/Tabs/VTabs/enums';
import { MarketsGridFilterCustomId } from 'web/src/modules/sportline/submodules/markets-grid/enums';
import { MarketGroupStyleType } from 'web/src/modules/sportline/enums';

interface MarketsListTabsProps {
  hasStatistic?: Ref<Optional<boolean>>;
  hasAllTab?: Ref<Optional<boolean>>;
  gridFilters: Ref<MarketsGridFilter[]>;
  activeTabId: Ref<Optional<MarketsGridFilterId>>;
  tabsRef: Ref<VSwiperRef | undefined>;
}

interface MarketsListTabsEmit {
  (e: 'tab-click', payload: MarketsGridFilterId): void;
}

interface MarketsListTabsComposable {
  activeTabIndex: Ref<number>;
  tabs: Ref<TabsItem[]>;
  slideToActive(smooth?: boolean): void;
  onTabClick(value: string): void;
}

export function useMarketsTabs(
  props: MarketsListTabsProps,
  emit: MarketsListTabsEmit,
): MarketsListTabsComposable {
  const { gridFilters } = props;

  const { $translate } = useI18n();

  const basicTabsList = computed<TabsItem[]>(() => (props.hasAllTab?.value
    ? [{
      id: MarketsGridFilterCustomId.All,
      label: $translate('WEB_ALL_MARKETS').value,
    }]
    : []));
  const statisticTabs = computed<TabsItem[]>(() => (props.hasStatistic?.value
    ? [{
      id: MarketsGridFilterCustomId.Statistic,
      icon: {
        name: IconName.STATISTIC,
        size: IconSize.SIZE_18,
      },
    }]
    : []));
  const tabs = computed<TabsItem[]>(() => [
    ...statisticTabs.value,
    ...basicTabsList.value,
    ...gridFilters.value.map((item) => ({
      id: item.id,
      label: item.name,
      type: item.style?.type === MarketGroupStyleType.PROMO ? TabsType.PROMO : undefined,
    })),
  ]);
  const activeTabIndex = computed(() => tabs.value.findIndex((tab) => tab.id === props.activeTabId.value));

  function slideToActive(smooth = false): void {
    props.tabsRef.value?.slideToSlide(activeTabIndex.value, {
      smooth,
      speed: 300,
      isCentered: true,
    });
  }

  function onTabClick(value: string): void {
    emit('tab-click', value as MarketsGridFilterId);
  }

  watch(props.activeTabId, () => { slideToActive(true); });

  return {
    activeTabIndex,
    tabs,
    slideToActive,
    onTabClick,
  };
}
