import { defineStore } from 'pinia';
import type { Ref } from 'vue';
import {
  computed,
  ref,
} from 'vue';

import {
  getBootstrapTranslations,
  isBootstrapTranslationKey,
  getInitLocale,
} from '@leon-hub/bootstrap-translations';
import { Timer } from '@leon-hub/utils';
import { Locale, localeStorageKey } from '@leon-hub/locale';
import { useLocalStorageManager } from '@leon-hub/local-storage';
import { isDebugEnabled } from '@leon-hub/debug';

import type { TranslationDocument } from 'web/src/modules/i18n/store/types';
import { primaryKeys } from 'web/src/modules/i18n/store/primaryKeys';
import { useSyncState } from 'web/src/modules/core/submodules/sync-state';

import { fetchTranslations, doChangeLanguage, setLanguageHeader } from './utils';
import { EMPTY_PLACEHOLDER } from '../consts';

function processParameters(translate: string, parameters: Record<string, string>): string {
  return Object.keys(parameters)
    .reduce((accumulator: string, item: string) => accumulator.replace(new RegExp(`{${item}}`, 'g'), parameters[item]),
      translate);
}

const useI18nStore = defineStore('i18n', () => {
  const localStorageManager = useLocalStorageManager();

  const translations: Record<string, Ref<string>> = {};
  const locale = ref(
    localStorageManager.getItem(localeStorageKey)
    || getInitLocale()
    || Locale.RU_RU,
  );

  const currentUrlPrefix = ref('');
  const replacedUrlPrefix = ref('');

  const lang = computed(() => locale.value.split('_')[0]);

  async function fetchKeys(keys: string[], silent = false): Promise<void> {
    updateKeKeys(await fetchTranslations(keys, silent));
  }

  function updateKeKeys(items: readonly TranslationDocument[]): void {
    if (items.length > 0) {
      for (const translation of items) {
        if (!translations[translation.key]) {
          translations[translation.key] = ref(translation.value);
        } else {
          translations[translation.key].value = translation.value;
        }
      }
    }
  }

  function loadPrimaryKeys(silent = false): Promise<void> {
    return fetchKeys(primaryKeys, silent);
  }

  let pendingTranslations: string[] = [];
  let pendingFetchTimeoutId = 0;

  function clearPendingFetchTimeout(): void {
    if (pendingFetchTimeoutId) {
      Timer.clearTimeout(pendingFetchTimeoutId);
      pendingFetchTimeoutId = 0;
    }
  }

  function addPendingKey(key: string): void {
    if (primaryKeys.includes(key)) {
      return;
    }

    pendingTranslations.push(key);

    clearPendingFetchTimeout();
    pendingFetchTimeoutId = Timer.setTimeout(() => {
      const keys = [...pendingTranslations];

      void fetchKeys(keys, true).then(() => {
        pendingTranslations = pendingTranslations.filter((item) => !keys.includes(item));
      });
    }, 40);
  }

  function $t(
    key: string,
    parameters?: Ref<Record<string, string>>,
  ): Ref<string> {
    if (!translations[key]) {
      const emptyPlaceholder = isDebugEnabled() ? key : EMPTY_PLACEHOLDER;

      const defaultTranslationValue = isBootstrapTranslationKey(key)
        ? (getBootstrapTranslations()[key] || emptyPlaceholder)
        : emptyPlaceholder;

      translations[key] = ref(defaultTranslationValue);
      addPendingKey(key);
    }

    if (!parameters) {
      return translations[key];
    }

    return computed(() => processParameters(translations[key].value, parameters.value));
  }

  function getTranslationFunction() {
    return (key: string, parameters?: Record<string, string>) => (
      $t(key, parameters ? ref(parameters) : undefined).value
    );
  }

  function setLocale(value: string): void {
    locale.value = value;
    setLanguageHeader(value);
    localStorageManager.setItem(localeStorageKey, value);
  }

  async function changeLanguage(languageTag: string): Promise<void> {
    const result = await doChangeLanguage(languageTag);
    if (result.currentLanguageTag) {
      setLocale(result.currentLanguageTag);
      currentUrlPrefix.value = result.urlLocale || '';
    }
  }

  function setUrlPrefixes({
    currentPrefix,
    replacedPrefix,
  }: {
    currentPrefix: string;
    replacedPrefix: string;
  }): void {
    currentUrlPrefix.value = currentPrefix;
    replacedUrlPrefix.value = replacedPrefix;
  }

  useSyncState((silent) => loadPrimaryKeys(silent), 'i18n');

  return {
    locale,
    lang,
    currentUrlPrefix,
    replacedUrlPrefix,
    updateKeKeys,
    loadPrimaryKeys,
    $t,
    getTranslationFunction,
    setLocale,
    setUrlPrefixes,
    changeLanguage,
  };
});

export default useI18nStore;
