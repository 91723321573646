import type { FileMultipleEvent } from 'web/src/components/Input';
import { InputEventType } from 'web/src/components/Input/enums';
import type { FileFormWidgetsEmits } from 'web/src/components/Form/types';

import { fileMultipleEventToFormDataEvent } from '../utils';

interface UseFormFileMultipleEvents {
  emitChange: (event: FileMultipleEvent) => void;
  emitFocus: (event: FileMultipleEvent) => void;
  emitBlur: (event: FileMultipleEvent) => void;
}

const useFormFileMultipleEvents = (emit: FileFormWidgetsEmits): UseFormFileMultipleEvents => {
  const emitChange = (event: FileMultipleEvent): void => {
    emit(InputEventType.CHANGE, fileMultipleEventToFormDataEvent(event, true));
  };

  const emitFocus = (event: FileMultipleEvent): void => {
    emit(InputEventType.FOCUS, fileMultipleEventToFormDataEvent(event, true));
  };

  const emitBlur = (event: FileMultipleEvent): void => {
    emit(InputEventType.BLUR, fileMultipleEventToFormDataEvent(event, true));
  };

  return {
    emitChange,
    emitFocus,
    emitBlur,
  };
};

export default useFormFileMultipleEvents;
