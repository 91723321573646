<script lang="ts" setup>
import DefaultTopBarRouteComponent
  from 'web/src/modules/core/views/DefaultTopBarRouteComponent/DefaultTopBarRouteComponent.vue';
import SimpleBrandTopBarRouteComponent
  from 'web/src/modules/core/views/SimpleBrandTopBarRouteComponent/SimpleBrandTopBarRouteComponent.vue';
import {
  useRegistrationCompletionTopBarRouteComponent,
} from 'web/src/modules/registration-completion/views/RegistrationCompletionTopBarRouteComponent/composables';

const {
  topBarTitle,
  isAsoTerms,
  showPrefix,
  onClose,
  onBack,
} = useRegistrationCompletionTopBarRouteComponent();
</script>

<template>
  <SimpleBrandTopBarRouteComponent
    v-if="isAsoTerms"
    :hide-prefix="!showPrefix"
    only-emit-events
    @back="onBack"
    @close="onClose"
  />
  <DefaultTopBarRouteComponent
    v-else
    :title="topBarTitle"
    :hide-prefix="!showPrefix"
    only-emit-events
    @back="onBack"
    @close="onClose"
  />
</template>
