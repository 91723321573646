import { ListType } from 'web/src/components/List/enums';
import type { VListProps } from 'web/src/components/List/VList/types';
import convertCommonClassesToCssModule from 'web/src/components/List/utils/convertCommonClassesToCssModule';

export default function getClassObject(props: VListProps): Dictionary<boolean> {
  return convertCommonClassesToCssModule({
    list: true,
    [`list--${props.listType}`]: props.listType !== ListType.SEPARATE,
  });
}
