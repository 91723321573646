import {
  isObject,
  isString,
} from '@leon-hub/guards';

/**
 * Something from siteconfig
 */
interface CoreMetaProperty {
  [key: string]: string | undefined;
  content?: string;
}

export interface MetaPropertyWithName extends CoreMetaProperty {
  name: string;
}

interface ScriptMetaProperty extends CoreMetaProperty {
  type: string;
}

interface ScriptMetaPropertyTemplate extends ScriptMetaProperty {
  template: string;
}

type TitleMetaProperty = CoreMetaProperty;

interface MetaInfo {
  title?: string | TitleMetaProperty[];
  meta?: (MetaPropertyWithName | Record<string, string>)[];
  script?: (ScriptMetaProperty | ScriptMetaPropertyTemplate)[];
}

export function isMetaPropertyWithName(value: unknown): value is MetaPropertyWithName {
  if (isCoreMetaProperty(value)) {
    return typeof value.name === 'string';
  }
  return false;
}

export function isScriptMetaProperty(value: unknown): value is ScriptMetaProperty {
  return isCoreMetaProperty(value)
    && isString(value.type);
}

function isCoreMetaProperty(value: unknown): value is CoreMetaProperty {
  return isObject(value)
    && Object.values(value)
      .every((val) => val === undefined || typeof val === 'string');
}

export function isTitleMetaProperty(value: unknown): value is TitleMetaProperty {
  return isCoreMetaProperty(value);
}

export {
  ScriptMetaProperty,
  ScriptMetaPropertyTemplate,
};

export default MetaInfo;
