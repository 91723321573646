<script lang="ts" setup>
import {
  IconName,
  IconSize,
} from '@leon-hub/icons';

import { VIcon } from '@components/v-icon';

import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { ButtonHeight, ButtonKind } from 'web/src/components/Button/VButton/enums';
import VLinearProgress from 'web/src/components/LinearProgress/VLinearProgress/VLinearProgress.vue';
import { ProgressHeight, ProgressKind } from 'web/src/components/LinearProgress/VLinearProgress/enums';

import type { CasinoGameBonusProps, CasinoGameBonusEmits } from '../types';
import { useCasinoGameBonus } from '../composables';

const props = defineProps<CasinoGameBonusProps>();
const emit = defineEmits<CasinoGameBonusEmits>();

const {
  bonusTitle,
  balance,
  isBonusActive,
  isProgressComplete,
  progress,
  expiringThrough,
  amount,
  requiredAmount,
  onClickBonusDetails,
  onClickWithdrawalBonus,
} = useCasinoGameBonus(props, emit);
</script>

<template>
  <div v-auto-id="'CasinoGameBonusBar'"
    v-if="!!item && (item.progress < 1 || (item.progress >= 1 && item.walletBalanceNumber !== 0))"
    :class="$style['bonus-casino-slots-bar']"
  >
    <div>
      <div :class="$style['bonus-casino-slots-bar__bonus-title']">
        {{ bonusTitle }}
      </div>
      <div :class="$style['bonus-casino-slots-bar__informer-inline-wrapper']">
        <div :class="$style['bonus-casino-slots-bar__balance-label']">
          {{ $t('JSPACC_ACC_BALANCE') }}
        </div>
        <div :class="$style['bonus-casino-slots-bar__balance']">
          {{ balance }}
        </div>
        <div
          v-if="isBonusActive"
          @click="onClickBonusDetails"
        >
          <VIcon
            :name="IconName.INFO_OUTLINE"
            :size="IconSize.SIZE_16"
            :class="$style['bonus-casino-slots-bar__info-icon']"
          />
        </div>
      </div>
    </div>
    <div v-if="!isProgressComplete">
      <div :class="$style['bonus-casino-slots-bar__progress']">
        <VLinearProgress
          :height="ProgressHeight.TINY"
          :kind="ProgressKind.WHITE_BG"
          :value="progress"
          :class="$style['bonus-casino-slots-bar__progress-line']"
        />
      </div>
      <div :class="$style['bonus-casino-slots-bar__info']">
        <div :class="$style['bonus-casino-slots-bar__info-date-text']">
          {{ `${$t('WEB2_REMAINING')}: ${expiringThrough}` }}
        </div>
        <div :class="$style['bonus-casino-slots-bar__info-text']">
          <span :class="$style['bonus-casino-slots-bar__info-text--current']">{{ amount }}</span>
          <span :class="$style['bonus-casino-slots-bar__info-text--divider']">/</span>
          <span>{{ requiredAmount }}</span>
        </div>
      </div>
    </div>
    <div
      v-if="isProgressComplete"
      :class="$style['bonus-casino-slots-bar__button']"
    >
      <VButton
        :height="ButtonHeight.SMALL"
        :kind="ButtonKind.PRIMARY"
        full-width
        :label="$t('WEB2_GRAB')"
        @click="onClickWithdrawalBonus"
      />
    </div>
  </div>
</template>

<style lang="scss" module>
.bonus-casino-slots-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  border-radius: 0 0 5px 5px;

  &__bonus-title {
    @include medium\16\20\025;

    margin-bottom: 4px;
    overflow: hidden;
    color: var(--TextDefault);
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__informer-inline-wrapper {
    display: inline-flex;
    align-items: center;
  }

  &__balance-label {
    @include regular\13\20;

    margin-right: 4px;
    color: var(--TextSecondary);
  }

  &__balance {
    @include medium\16\20\025;

    color: var(--TextDefault);
  }

  &__progress {
    margin-bottom: 14px;
    white-space: nowrap;

    &-line {
      width: 300px;
    }
  }

  &__info {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: space-between;

    &-date-text {
      @include regular\12\12;

      color: var(--TextSecondary);
    }

    &-text {
      @include regular\12\16;

      color: var(--TextSecondary);

      &--current {
        @include medium\12\16;

        color: var(--TextDefault);
      }

      &--divider {
        padding: 0 3px;
      }
    }
  }

  &__button {
    width: 160px;
  }

  &__info-icon {
    margin-left: 4px;
    cursor: pointer;
  }
}
</style>
