import { computed, toRef } from 'vue';

import { BusEvent, useBusSafeSubscribe } from '@leon-hub/event-bus';

import type { ShowSlipModalWarningPayload } from 'web/src/modules/slip/submodules/slip-info/types';
import { BetSlipWarningTypes } from 'web/src/modules/slip/enums';
import type { ShowDialogOptions, EventResolvers } from 'web/src/modules/dialogs/types';
import { DialogAction, PresetName } from 'web/src/modules/dialogs/enums';
import { ModalWidth } from 'web/src/components/Modal/enums';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useDialogs } from 'web/src/modules/dialogs/composables';

import { useSlipInfoStore } from '../submodules/slip-info/store';
import { useFreebetStore } from '../submodules/freebet/store';

/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */

export function useSlipWarningDialog(): void {
  const { $translate } = useI18n();

  const { showDialog } = useDialogs();

  const slipInfoStore = useSlipInfoStore();

  const {
    cancelSlipAdding,
    syncAddedSlipItems,
  } = slipInfoStore;

  const maxSlipSize = toRef(slipInfoStore, 'maxSlipSize');

  const selectedFreeBetTermKey = toRef(useFreebetStore(), 'selectedFreeBetTermKey');

  const freeBetValidationMessageParams = computed<Record<string, string>>(
    () => ({ cmsKey: selectedFreeBetTermKey.value }),
  );

  const maxSlipSizeMessageTranslateParams = computed<Record<string, string>>(() => ({ count: `${maxSlipSize.value}` }));

  const showFreebetValidationErrorDialog = (): void => {
    showDialog({
      presetName: PresetName.ALERT_WARNING,
      options: {
        title: $translate('WEB2_FREE_BET_IS_NOT_ACCEPTED').value,
        confirmMessage: $translate('WEB2_FREEBET_NOT_ACCEPTED', freeBetValidationMessageParams).value,
        dataTestId: 'free-bet-is-not-accepted',
      },
    });
  };

  const getDialogOptions = ({ type, caption, message }: ShowSlipModalWarningPayload): {
    modalProperties: ShowDialogOptions;
    modalResolvers: EventResolvers;
  } => {
    const modalProperties: ShowDialogOptions = {
      presetName: PresetName.ALERT_WARNING,
      options: {},
      id: 'slipWarning',
    };
    let modalResolvers: EventResolvers = {};
    switch (type) {
      case BetSlipWarningTypes.SLIP_LIMIT_REACHED: {
        modalProperties.options = {
          title: $translate('WEB2_SLIP_ENTRY_LIMIT_REACHED_CAPTION').value,
          confirmMessage: $translate('WEB2_SLIP_ENTRY_LIMIT_REACHED_DESCRIPTION', maxSlipSizeMessageTranslateParams).value,
        };
        break;
      }
      case BetSlipWarningTypes.MARKET_STATUS_LIMIT: {
        modalProperties.options = {
          title: $translate('WEB2_MARKET_LIMIT_CAPTION').value,
          confirmMessage: $translate('WEB2_MARKET_LIMIT_DESCRIPTION').value,
        };
        break;
      }
      case BetSlipWarningTypes.MARKET_STATUS_DUPLICATE: {
        modalProperties.options = {
          title: $translate('WEB2_SLIP_UNABLE_TO_ADD').value,
          confirmMessage: message || '',
        };
        break;
      }
      case BetSlipWarningTypes.CONNECTION_ERROR: {
        modalProperties.presetName = PresetName.CONFIRM;
        modalProperties.options = {
          width: ModalWidth.SMALL,
          title: $translate('WEB2_CONNECTION_ERROR_CAPTION').value,
          confirmMessage: $translate('WEB2_CONNECTION_ERROR_DESCRIPTION').value,
          buttons: [
            {
              label: $translate('WEB2_CONNECTION_ERROR_RETRY').value,
            },
            {
              label: $translate('WEB2_CLOSE_CUSTOMER_NOTIFICATION').value,
            },
          ],
        };
        modalResolvers = {
          [DialogAction.CONFIRM]: () => { void syncAddedSlipItems(); },
          [DialogAction.MODAL_CLOSE]: cancelSlipAdding,
        };
        break;
      }
      case BetSlipWarningTypes.GENERIC_WARNING: {
        modalProperties.options = {
          title: caption || $translate('WEB2_SLIP_UNABLE_TO_ADD').value,
          confirmMessage: message || '',
        };
        break;
      }
      default:
        break;
    }
    return {
      modalProperties,
      modalResolvers,
    };
  };

  const showSlipModalWarning = (payload: ShowSlipModalWarningPayload): void => {
    if (!Object.values(BetSlipWarningTypes).includes(payload.type as BetSlipWarningTypes)) {
      return;
    }
    if (payload.type === BetSlipWarningTypes.FREEBET_VALIDATION_ERROR) {
      showFreebetValidationErrorDialog();
      return;
    }
    const {
      modalProperties,
      modalResolvers,
    } = getDialogOptions(payload);
    showDialog(modalProperties).subscribe(modalResolvers);
  };

  useBusSafeSubscribe(BusEvent.SHOW_SLIP_MODAL_WARNING, (data) => {
    showSlipModalWarning(data);
  });
}
