import { setElementAttributes } from 'web/src/utils/directives/dataTest';

export const dataTestLabel = {
  beforeMount: (element: Element): void => setElementAttributes(element, { el: 'control-label' }),
};

export const dataTestHint = {
  beforeMount: (element: Element): void => setElementAttributes(element, { el: 'control-hint' }),
};

export const dataTestHintRight = {
  beforeMount: (element: Element): void => setElementAttributes(element, { el: 'control-hint-right' }),
};

export const dataTestHintLink = {
  beforeMount: (element: Element): void => setElementAttributes(element, { el: 'control-hint-link' }),
};
