<script lang="ts" setup>
import { useSimpleApp } from './composables';

useSimpleApp();
</script>

<template>
  <SimpleAppDynamicComponent />

  <div
    v-if="null"
    class="cordova-app-loaded"
  />

  <metainfo />
</template>
