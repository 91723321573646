import { useLocalStorageManager } from '@leon-hub/local-storage';

interface UseSaveToLocalStorageComposable {
  saveValueToLocalStorage(key: string, value?: Maybe<string | number | boolean>): void;
  getValueFromLocalStorage(key: string): Maybe<string>;
}

export function useSaveToLocalStorage(): UseSaveToLocalStorageComposable {
  const localStorageManager = useLocalStorageManager();

  function saveValueToLocalStorage(key: string, value?: Maybe<string | number>): void {
    if (!value) {
      localStorageManager.removeItem(key);
    } else {
      localStorageManager.setItem(key, String(value));
    }
  }

  function getValueFromLocalStorage(key: string): Maybe<string> {
    return localStorageManager.getItem(key);
  }

  return {
    saveValueToLocalStorage,
    getValueFromLocalStorage,
  };
}
