<script lang="ts" setup>
import { VIcon } from '@components/v-icon';

import { ProgressHeight, ProgressKind } from './enums';
import { useVLinearProgress } from './composables';

interface VLinearProgressProps {
  value?: number;
  of?: number;
  height?: ProgressHeight;
  kind?: ProgressKind;
  noShadow?: boolean;
}

const props = withDefaults(defineProps<VLinearProgressProps>(), {
  value: 0,
  of: 100,
  height: ProgressHeight.DEFAULT,
  kind: ProgressKind.DEFAULT,
});

const {
  iconProperties,
  percentage,
  barStyleObject
  ,
} = useVLinearProgress(props);
</script>

<template>
  <div v-auto-id="'VLinearProgress'"
    :class="{
      [$style['linear-progress']]: true,
      [$style[`linear-progress--${kind}`]]: kind !== ProgressKind.DEFAULT,
      [$style[`linear-progress--${height}`]]: height !== ProgressHeight.DEFAULT,
      [$style[`linear-progress--no-shadow`]]: noShadow,
    }"
  >
    <div
      :class="$style['linear-progress__bg']"
    >
      <div
        :class="$style['linear-progress__bar']"
        :style="barStyleObject"
      />
      <span
        v-if="(percentage === 100) && height === ProgressHeight.DEFAULT"
        :class="$style['linear-progress__check']"
      >
        <VIcon v-bind="iconProperties" />
      </span>
    </div>
  </div>
</template>

<style lang="scss" module>
@include for-layout using($isDesktop) {
  .linear-progress {
    position: relative;
    padding: if($isDesktop, 12px 0, 8px 0);

    &__bg {
      display: flex;
      width: 100%;
      height: if($isDesktop, 24px, 16px);
      background-color: $linearProgressBgBackground;
      border-radius: 40px;
    }

    &__bar {
      background-color: $linearProgressBarBackground;
      border-radius: inherit;
      box-shadow: $linearProgressBarShadow;
      transition: width 0.5s $animationEaseInOut;
    }

    &__check {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: if($isDesktop, 48px, 32px);
      height: if($isDesktop, 48px, 32px);
      background-color: $linearProgressCheckBackground;
      border-radius: 50%;
    }

    &--tiny {
      padding: 0;

      .linear-progress__bg {
        height: 6px;
        border-radius: $linearProgressTinyBgPadding;
      }
    }

    &--size-4 {
      padding: 0;

      .linear-progress__bg {
        height: 4px;
        border-radius: $linearProgressS4BgRadius;
      }
    }

    &--tiny-extra {
      padding: 0;

      .linear-progress__bg {
        height: 2px;
        border-radius: $linearProgressTinyExtraBgRadius;
        box-shadow: none;
      }
    }

    &--white-bg {
      .linear-progress__bg {
        background-color: $linearProgressWhiteBgBgBackground;
      }
    }

    &--white {
      .linear-progress__bg {
        background-color: $linearProgressWhiteBgBackground;
      }

      .linear-progress__bar {
        background-color: $linearProgressWhiteBarBackground;
        box-shadow: $linearProgressWhiteBarShadow;
      }

      &.linear-progress--tiny-extra {
        .linear-progress__bar {
          box-shadow: none;
        }
      }
    }

    &--black {
      .linear-progress__bar {
        background-color: $linearProgressBlackBarBackground;
        box-shadow: $linearProgressBlackBarShadow;
      }
    }

    &--highlight {
      .linear-progress__bg {
        background-color: $linearProgressHighlightBgBackground;
      }

      .linear-progress__bar {
        background-color: $linearProgressHighlightBarBackground;
        box-shadow: $linearProgressHighlightBarShadow;
      }
    }

    &--yellow {
      .linear-progress__bar {
        background-color: $linearProgressYellowBarBackground;
        box-shadow: $linearProgressYellowBarShadow;
      }
    }

    &--mint {
      .linear-progress__bar {
        background-color: $linearProgressMintBarBackground;
        box-shadow: $linearProgressMintBarShadow;
      }
    }

    &--error {
      .linear-progress__bar {
        background-color: $linearProgressErrorBarBackground;
        box-shadow: $linearProgressErrorBarShadow;
      }
    }

    &--no-shadow {
      .linear-progress__bar {
        box-shadow: none;
      }
    }
  }
}
</style>
