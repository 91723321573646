export const enum ShowcaseNavigationItemType {
  Icon = 'Icon',
  Image = 'Image',
}

export enum ShowcaseNavigationItemId {
  ZERO_MARGIN = 'showcase-zero-margin',
  HIGHEST_ODDS = 'showcase-highest-odds',
  SPORTLINE_FAVORITE_EVENTS = 'showcase-sportline-favorite',
}

export enum ShowcaseNavigationItemName {
  ACTIONS = 'actions',
  BETGAMES = 'betgames',
  CHERRY = 'cherry',
  CYBERSPORT = 'cybersport',
  FASTGAMES = 'fastgames',
  LANDING_EURO = 'landing-euro',
  LIVE = 'live',
  ROULETTE = 'roulette',
  TV = 'tv',
  CRICKET = 'cricket',
  FOOTBALL = 'football',
  TENNIS = 'tennis',
  ZERO_MARGIN = 'zero-margin',
}
