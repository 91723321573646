import type { Ref } from 'vue';
import { ref, computed } from 'vue';

import { logger } from '@leon-hub/logging';
import { normalizeError } from '@leon-hub/errors';

import { useSportlineApiService } from 'web/src/modules/sportline/services';
import { useParseSportlineSettingsRef } from 'web/src/modules/sportline/composables/settings';
import { useBroadcastSelected } from 'web/src/modules/sportline/composables/broadcast';
import { useSportlineEventsChangesListeners } from 'web/src/modules/sportline/store/composables';
import { useSyncSportline } from 'web/src/modules/sportline/submodules/sync-sportline';
import { useRelatedEventsSportlineUpdateTimeout } from 'web/src/modules/sportline/submodules/update-timeout';
import useBackgroundRequestsLifeCycle from 'web/src/utils/store/composables/useBackgroundRequestsLifeCycle';
import type {
  SportElement,
  SportlineEvent,
  SportlineEventElement,
} from 'web/src/modules/sportline/types';
import type { SportlinePageIdentifier as SportEventsPageIdentifier } from 'web/src/modules/sportline/types/navigation';
import type {
  GetSportEventsResponse,
  CoreSportlineFetchOptions,
  GetSportEventsChangesResponse,
} from 'web/src/modules/sportline/types/rest';
import type { SportlineEventDetails } from 'web/src/modules/sportline/submodules/event-details/types';
import { SportlineFactory } from 'web/src/modules/sportline/utils/rest';
import { flatMapSportElementsList } from 'web/src/modules/sportline/utils';
import { normalizeEventsChangesResponseToDefaultResponse } from 'web/src/modules/sportline/utils/response';
import { BackgroundUpdateStopwatch } from 'web/src/utils/store';

interface UseRelatedEventsStoreComposableProps {
  pageIdentifier: Ref<SportEventsPageIdentifier>;
  sportEventDetails: Ref<Maybe<SportlineEventDetails>>;
  sportEventDetailsId: Ref<Maybe<string>>;
}

interface UseRelatedEventsStoreComposable {
  relatedSportListElement: Ref<Maybe<SportElement>>;
  leagueEventsList: Ref<SportlineEventElement[]>;
  setBackgroundUpdateEnabled(value: boolean): void;
  onInit(): void;
}

export function useRelatedEventsStoreComposable(
  props: UseRelatedEventsStoreComposableProps,
): UseRelatedEventsStoreComposable {
  const {
    pageIdentifier,
    sportEventDetails,
    sportEventDetailsId,
  } = props;

  const { timeout: relatedEventsUpdateInterval } = useRelatedEventsSportlineUpdateTimeout();

  const parseSportlineSettings = useParseSportlineSettingsRef();
  const apiService = useSportlineApiService();
  const broadcastSelected = useBroadcastSelected();

  const rawRelatedEventsResponse = ref<Maybe<GetSportEventsResponse>>(null);
  const rawLeagueEventsResponse = ref<Maybe<GetSportEventsResponse> | false>(null);

  const leagueId = computed(() => sportEventDetails.value?.league.id);

  function setRawRelatedEventsResponse(response: GetSportEventsResponse | GetSportEventsChangesResponse | null): void {
    rawRelatedEventsResponse.value = normalizeEventsChangesResponseToDefaultResponse(response);
  }

  async function fetchRelatedEvents({ silent }: CoreSportlineFetchOptions): Promise<void> {
    try {
      const eventId = sportEventDetailsId.value ?? pageIdentifier.value.sportEventId;

      if (!eventId) {
        setRawRelatedEventsResponse(null);
        return;
      }

      const response = await apiService.loadRelatedEvents({
        eventId,
        vTag: rawRelatedEventsResponse.value?.vtag,
        silent,
      });

      if (response) {
        setRawRelatedEventsResponse(response);
        broadcastSelected.updateDataInStorageByResponse({ response });
      } else {
        setRawRelatedEventsResponse(null);
      }
    } catch (rawError) {
      setRawRelatedEventsResponse(null);
      logger.error(normalizeError(rawError));
    }
  }

  async function fetchLeagueEvents({ silent }: CoreSportlineFetchOptions): Promise<void> {
    try {
      if (!leagueId.value) { return; }

      const response = await apiService.loadLeagueEvents({
        leagueId: leagueId.value,
        // here could be "false" value in rawEventsResponse, nullish-coalescing is not working here
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        vTag: (rawLeagueEventsResponse.value || null)?.vtag,
        silent,
      });

      rawLeagueEventsResponse.value = normalizeEventsChangesResponseToDefaultResponse(response);
      broadcastSelected.updateDataInStorageByResponse({ response });
    } catch (error: unknown) {
      rawLeagueEventsResponse.value = false;
      throw error;
    }
  }

  /** Related list element for related list block */
  const relatedSportListElement = computed<Maybe<SportElement>>(() => {
    const relatedSportsList = rawRelatedEventsResponse.value
      ? (new SportlineFactory<SportlineEvent>(rawRelatedEventsResponse.value, parseSportlineSettings.value))
        .build()
      : null;

    return relatedSportsList?.[0] ?? null;
  });

  /**
   * League events for breadcrumbs
   * @hint Maybe it must be calculated in the view composable
   */
  const leagueEventsList = computed<SportlineEventElement[]>(() => {
    const rawList = rawLeagueEventsResponse.value;

    const sportElements = rawList
      ? (new SportlineFactory<SportlineEvent>(rawList, parseSportlineSettings.value)).build()
      : null;

    return flatMapSportElementsList(sportElements ?? []).map(({ eventElement }) => eventElement);
  });

  const {
    onInit: onSportlineEventsChangesListenersInit,
  } = useSportlineEventsChangesListeners({
    setResponse(response: GetSportEventsResponse): void {
      rawRelatedEventsResponse.value = response;
    },
    getResponse(): Maybe<GetSportEventsResponse> {
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      return rawRelatedEventsResponse.value || null;
    },
  });

  async function loadRelatedEvents(options: CoreSportlineFetchOptions): Promise<void> {
    await Promise.all([
      fetchRelatedEvents(options),
      fetchLeagueEvents(options),
    ]);
  }

  const lastUpdate = new BackgroundUpdateStopwatch<['relatedEvents']>({
    relatedEvents: async ({ silent, skip }: CoreSportlineFetchOptions & { skip?: boolean }) => {
      // skip updates for set background update (reloaded by initial requests)
      if (skip) { return; }

      await loadRelatedEvents({ silent });
      lastUpdate.update('relatedEvents');
    },
  });

  const {
    isBackgroundUpdateEnabled,
    syncState,
    setBackgroundUpdateEnabled,
  } = useBackgroundRequestsLifeCycle({
    lastUpdate,
    updateInterval: relatedEventsUpdateInterval,
  });

  useSyncSportline((silent) => syncState({ silent }), relatedEventsUpdateInterval, {
    condition: isBackgroundUpdateEnabled,
  });

  function onInit(): void {
    onSportlineEventsChangesListenersInit();
  }

  return {
    relatedSportListElement,
    leagueEventsList,
    setBackgroundUpdateEnabled(value: boolean): void {
      void setBackgroundUpdateEnabled(value);
    },
    onInit,
  };
}
