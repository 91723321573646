import {
  computed,
} from 'vue';
import type { Ref } from 'vue';

import type { VListItemProps } from '../types';
import getClassObject from './utils/getClassObject';

export interface VListItemComposable {
  classObject: Ref<Dictionary<boolean>>;
}

export default function useVListItem(props: VListItemProps): VListItemComposable {
  return {
    classObject: computed(() => getClassObject(props)),
  };
}
