<script lang="ts" setup>
import { VIframe } from 'web/src/components/Iframe';
import type {
  CasinoBetgamesIframeWidgetEmits,
  CasinoBetgamesIframeWidgetProps,
} from 'web/src/modules/casino/submodules/betgames/components/CasinoBetgamesIframeWidget/types';
import {
  useCasinoBetgamesIframeWidget,
} from 'web/src/modules/casino/submodules/betgames/components/CasinoBetgamesIframeWidget/composables';

const props = defineProps<CasinoBetgamesIframeWidgetProps>();
const emits = defineEmits<CasinoBetgamesIframeWidgetEmits>();

const {
  iframeName,
  iframeHeight,
  gameUrl,
  iframeMounted,
  emitLoad,
} = useCasinoBetgamesIframeWidget(props, emits);
</script>

<template>
  <VIframe v-auto-id="'CasinoBetgamesIframeWidget'"
    v-if="gameUrl"
    :name="iframeName"
    :src="gameUrl"
    allow="autoplay; fullscreen; encrypted-media"
    full-width
    full-height
    hide-title
    :style="{
      height: `${iframeHeight}px`,
    }"
    @vue:mounted="iframeMounted"
    @load="emitLoad"
  />
</template>
