import type {
  GetSportEventsChangesResponse,
  GetSportEventsResponse,
} from 'web/src/modules/sportline/types/rest';
import { BetlineType } from 'web/src/modules/sportline/enums/rest';
import {
  BetlineReplaceResponse,
  SportEventsResponseChangeUtils,
} from 'web/src/modules/sportline/utils/rest';

function filterEventsResponseByTypes(response: GetSportEventsResponse, filter: BetlineType[]): GetSportEventsResponse {
  const events = response.events.filter((event) => filter.includes(event.betline));

  return {
    ...response,
    totalCount: events.length,
    events,
  };
}

export function normalizeComingResponse({ response }: {
  response: Maybe<GetSportEventsResponse | GetSportEventsChangesResponse>;
}): Maybe<Readonly<GetSportEventsResponse>> {
  const updated = response
    ? BetlineReplaceResponse.unknownResponseToSportEventsResponse(response)
    : response;
  const normalized = SportEventsResponseChangeUtils.normalizeDuplicatesResponse(updated);
  // filter outright events
  const coming = normalized
    ? filterEventsResponseByTypes(normalized, [BetlineType.Prematch])
    : null;

  return coming ? Object.freeze(coming) : null;
}
