import GqlApiBatchError from './GqlApiBatchError';
import GqlApiErrorCode from './GqlApiErrorCode';
import type { GqlApiErrorOptions } from './GqlApiError';

export interface GqlApiBatchMaxSizeErrorOptions extends GqlApiErrorOptions {
  batchMaxSize: number;
}

export default class GqlApiBatchMaxSizeError extends GqlApiBatchError {
  public batchMaxSize: number;

  constructor(options: GqlApiBatchMaxSizeErrorOptions) {
    super({
      ...options,
      code: GqlApiErrorCode.API_TECHNICAL_ERROR,
    });

    this.batchMaxSize = options.batchMaxSize;
  }
}
