import type { RouteLocationRaw, RouteLocationNormalized, NavigationGuardNext } from 'vue-router';

import { RouteName } from '@leon-hub/routing-config-names';

import { useSportlineEventDetailsStore } from 'web/src/modules/sportline/submodules/event-details/store';
import { useSportlineEventStatisticStore } from 'web/src/modules/sportline/submodules/event-statistic/store';
import type { AppVueRouter } from 'web/src/modules/core/services/router/types';
import { getSportlinePageNavigationParameters } from 'web/src/modules/sportline/utils/navigation';

import BaseSportEventDetailsPageNavigationService from './BaseSportEventDetailsPageNavigationService';

/**
 * Handle navigation for prerender (search bots)
 * Check missing event and redirect
 */
export default class PrerenderSportEventDetailsPageNavigationService
  extends BaseSportEventDetailsPageNavigationService {
  prefetch(
    router: AppVueRouter,
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ): void {
    super.prefetch(router, to, from, (superResult?: unknown) => {
      if (superResult !== undefined) {
        next((superResult as RouteLocationRaw));
        return;
      }

      void this.loadPageData(router, to, from, next);
    });
  }

  /* Method for tests */
  // eslint-disable-next-line class-methods-use-this
  getIsReadyToDisplay(): boolean {
    const detailsStore = useSportlineEventDetailsStore();
    return detailsStore.isReadyToDisplayContent;
  }

  // eslint-disable-next-line class-methods-use-this
  protected async loadPageData(
    router: AppVueRouter,
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ): Promise<void> {
    const detailsStore = useSportlineEventDetailsStore();

    const { sportEventId } = getSportlinePageNavigationParameters(to);

    if (sportEventId) {
      // default navigation behaviour registration
      detailsStore.createOnInitBehaviour(sportEventId, {});
    }

    await detailsStore.initialRequests();

    if (detailsStore.sportEventDetails) {
      next();
      return;
    }

    const statisticStore = useSportlineEventStatisticStore();

    if (statisticStore.hasPostMatchStatistics) {
      next();
      return;
    }

    // use direct redirect to home but not RedirectByBreadcrumbs (made in LEONWEB-6332)
    next(router.resolve301location({ name: RouteName.HOME }));
  }
}
