import { copyToClipboard } from '@leon-hub/cordova';

export default async function copyTextToClipboard(text = ''): Promise<void> {
  if (process.env.VUE_APP_PLATFORM_CORDOVA) {
    await copyToClipboard(text);
    return;
  }

  try {
    await navigator.clipboard.writeText(text);
  } catch {
    const temporaryInput = document.createElement('textarea');
    temporaryInput.style.position = 'fixed';
    temporaryInput.style.top = '0';
    temporaryInput.style.left = '0';
    document.body.append(temporaryInput);
    temporaryInput.setRangeText(text);
    temporaryInput.select();
    temporaryInput.setSelectionRange(0, 99_999);
    document.execCommand('copy');
    temporaryInput.remove();
  }
}
