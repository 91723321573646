import { ComponentKey, ComponentStatus } from '../../types';
import type { ComponentResult } from '../../types';
import AbstractFingerprintComponent from '../../AbstractFingerprintComponent';
import { isCanvasSupported } from './utils';

// Component source: https://www.browserleaks.com/canvas#how-does-it-work

export default class CanvasComponent extends AbstractFingerprintComponent {
  constructor() {
    super(ComponentKey.Canvas);
  }

  getComponentResult(): Promise<ComponentResult> {
    if (isCanvasSupported()) {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');

      if (context) {
        const sample = 'how-does-it-work';
        context.textBaseline = 'top';
        context.font = '14px \'Arial\'';
        context.textBaseline = 'alphabetic';
        context.fillStyle = '#f60';
        context.fillRect(125, 1, 62, 20);
        context.fillStyle = '#069';
        context.fillText(sample, 2, 15);
        context.fillStyle = 'rgba(102, 204, 0, 0.7)';
        context.fillText(sample, 4, 17);

        return this.result([canvas.toDataURL()]);
      }

      return this.result(ComponentStatus.NotAvailable);
    }

    return this.result([ComponentStatus.NotAvailable]);
  }
}
