import { IconSize } from '@leon-hub/icons';

import type { VIconProps } from '@components/v-icon';

import type { BonusLoyaltyProgramBalanceProps } from 'web/src/modules/bonuses/components/BonusLoyaltyProgramBalance/types';

export default function getIconProps(
  props: Pick<BonusLoyaltyProgramBalanceProps, 'iconName' | 'isSmall'>,
): VIconProps {
  return {
    name: props.iconName,
    size: props.isSmall ? IconSize.SIZE_12 : IconSize.SIZE_20,
  };
}
