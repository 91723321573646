/* eslint-disable no-param-reassign */
import type { App } from 'vue';
import { createPinia } from 'pinia';
import { createHead } from '@unhead/vue';

import { EnvironmentPlugin } from '@leon-hub/plugins';

import { useQueryManager } from 'web/src/modules/core/composables';
import VueMetaParametersPlugin from 'web/src/plugins/VueMetaParametersPlugin';
import ExternalAppConfigPlugin from 'web/src/plugins/AppPlugins/ExternalAppConfigPlugin';
import { dataTest } from 'web/src/utils/directives/dataTest';
import RouteNamePlugin from 'web/src/plugins/RouteNamePlugin';
import { ThemePlugin } from 'web/src/modules/theme/plugins';
import CmsUtilsPlugin from 'web/src/plugins/CmsUtilsPlugin';
import { AnalyticsPlugin } from 'web/src/modules/analytics/plugins';
import { I18nPlugin } from 'web/src/modules/i18n/plugins';
import { ModalsPlugin, CopyToClipboardPlugin } from 'web/src/modules/dialogs/plugins';
import { vCordovaDocumentLink } from 'web/src/modules/cordova/directives';
import { createStoreThemeGetter } from 'web/src/modules/theme/utils';
import autoResize from 'web/src/utils/directives/autoResize';

function applyBootstrapUrlConfiguration(app: App): void {
  const queryManager = useQueryManager();

  // Enable vue dev-tools performance tab.
  app.config.performance = Boolean(queryManager.getParameter('vue-performance', true));
}

export function configureVueAppEssentials(app: App): App {
  app.config.compilerOptions.whitespace = 'condense';
  app.config.compilerOptions.isCustomElement = (tag) => ['noindex'].includes(tag);

  if (!process.env.VUE_APP_BUNDLER_NUXT) {
    // Setup pinia store first
    const pinia = createPinia();
    app.use(pinia);
  }

  app.use(EnvironmentPlugin);

  if (!process.env.VUE_APP_PLATFORM_CORDOVA) {
    app.use(VueMetaParametersPlugin);
  } else {
    ExternalAppConfigPlugin.install();
    app.directive('cordova-document-link', vCordovaDocumentLink);
  }

  applyBootstrapUrlConfiguration(app);

  app.directive('data-test', dataTest);
  app.directive('auto-resize', autoResize);

  if (!process.env.VUE_APP_BUNDLER_NUXT) {
    app.use(createHead());
  }
  app.use(I18nPlugin);
  app.use(ModalsPlugin);
  app.use(RouteNamePlugin);
  app.use(CopyToClipboardPlugin);
  app.use(CmsUtilsPlugin);
  app.use(ThemePlugin, createStoreThemeGetter());
  app.use(AnalyticsPlugin);

  return app;
}
