import type { Ref } from 'vue';
import {
  computed,
  toRef,
} from 'vue';

import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
import { useCountryStore } from 'web/src/modules/country/store';

export interface CountryComposable {
  country: Ref<string>;
}

export default function useCountry(): CountryComposable {
  const { isLoggedIn } = useIsLoggedIn();
  const customerCountry = toRef(useCustomerDataStore(), 'country');
  const defaultCountryCode = toRef(useCountryStore(), 'countryCode');

  const country = computed(() => (
    isLoggedIn.value && customerCountry.value
      ? customerCountry.value
      : defaultCountryCode.value
  ));

  return {
    country,
  };
}
