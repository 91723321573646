import type { MultiSinglesMetaInfo } from '../types';
import type { SlipEntryId } from '../../../types';

export default function modifyMetaInfoRecordOnBlur(
  id: SlipEntryId,
  currentMetaInfo: MultiSinglesMetaInfo,
): MultiSinglesMetaInfo | null {
  const matchedItem = currentMetaInfo[id];
  if (!matchedItem || matchedItem.stakeValue) {
    return null;
  }
  const updatedItem = {
    ...matchedItem,
    stakeValue: '0',
    clearValueOnFocus: true,
  };
  const copy = { ...currentMetaInfo };
  copy[id] = updatedItem;
  return copy;
}
