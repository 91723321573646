<script lang="ts" setup>
import { useSlots } from 'vue';

import type { FocusableInputRef } from '@leon-hub/focus';
import { useAutofocus } from '@leon-hub/focus';

import { useVSwitch } from './composables';
import { SwitchKind } from './enums';
import type { VSwitchProps, VSwitchEmits } from './types';

const props = withDefaults(defineProps<VSwitchProps>(), {
  label: '',
  kind: SwitchKind.DEFAULT,
});

const emit = defineEmits<VSwitchEmits>();
const slots = useSlots();
const {
  showLabel,
  emitFocus,
  emitBlur,
  onClick,
  onChange,
  onInput,
  onTransitionEnd,
} = useVSwitch(props, emit, slots);

const { focusable, focus } = useAutofocus(props);

defineExpose<FocusableInputRef>({ focus });
</script>

<template>
  <label v-auto-id="'VSwitch'"
    :class="{
      [$style['switch']]: true,
      [$style['switch--disabled']]: disabled,
      [$style['switch--hidden']]: hidden,
      [$style[`switch--${props.kind}`]]: true,
      [$style['switch--checked']]: checked,
      [$style['switch--theme-dark']]: props.kind === SwitchKind.THEME,
      [$style['switch--column-change']]: columnChange,
    }"
    data-test-id="switch-root"
    @click="onClick"
  >
    <span
      v-if="showLabel"
      :class="$style['switch__text']"
    >
      <span
        :class="$style['switch__title']"
        data-test-id="switch-label"
      >
        <slot>{{ label }}</slot>
      </span>
    </span>
    <span
      :class="$style['switch__icon']"
      data-test-id="switch-icon"
    >
      <input
        ref="focusable"
        data-test-id="switch-input"
        :autofocus="process.env.VUE_APP_OS_IOS ? undefined : autofocus"
        :checked="checked"
        :disabled="disabled"
        :readonly="readonly"
        :class="$style['switch__input']"
        type="checkbox"
        @change.stop="onChange"
        @input.stop="onInput"
        @blur.stop="emitBlur"
        @focus.stop="emitFocus"
      >
      <slot name="icon" />
      <span
        :class="{
          [$style['switch__bg']]: true,
          [$style['switch__bg--inner']]: !checked
        }"
      />
      <span
        :class="$style['switch__switcher']"
        @transitionend="onTransitionEnd"
      />
    </span>
  </label>
</template>

<style lang="scss" module>
.switch {
  display: flex;
  flex-shrink: 0;
  align-items: flex-start;
  justify-content: space-between;
  overflow: hidden;

  // font size styles here to make it editable by extra class on component
  color: $switchColor;

  @include for-hover {
    &:hover {
      .switch__input:not(:disabled):checked ~ .switch__bg {
        background-color: $switchDisabledCheckedHoverBgBackgroundColor;
      }

      .switch__input:not(:disabled) ~ .switch__bg {
        box-shadow: $switchDisabledHoverBgBoxShadow;
      }
    }
  }

  &__title,
  &__text {
    display: block;
  }

  &__text {
    display: flex;
    flex-direction: column;
  }

  &__title {
    @include switchTitle;

    padding: 2px 16px 0 0;
    cursor: pointer;
    user-select: none;
  }

  &__icon {
    position: relative;
    display: flex;
    flex-shrink: 0;
    width: 40px;
    height: 24px;
    cursor: pointer;
    border-radius: 12px;

    /* overflow: hidden; */
  }

  &--hidden {
    display: none;
  }

  &--disabled &__icon {
    cursor: default;
  }

  &--disabled &__bg {
    background-color: $switchDisabledBgBackgroundColor;
  }

  &--theme &__bg {
    position: relative;
    background-color: var(--LHighlight);

    &:before {
      position: absolute;
      top: 6px;
      left: 24px;
      display: block;
      width: 12px;
      height: 12px;
      content: '';
      background-image: url('~web/src/assets/images/sunSw.svg');
      background-size: contain;
    }

    &:after {
      position: absolute;
      top: 6px;
      left: 4px;
      display: block;
      width: 12px;
      height: 12px;
      content: '';
      background-image: url('~web/src/assets/images/moonSw.svg');
      background-size: contain;
    }
  }

  &--disabled &__title {
    opacity: 0.3;
  }

  &__input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    opacity: 0;

    &:not(:disabled):checked {
      ~ .switch__bg {
        background-color: $switchCheckedBgBackgroundColor;
        border: none;
      }

      ~ .switch__switcher {
        transform: translateX(16px);
      }
    }

    &:disabled {
      ~ .switch__bg,
      ~ .switch__switcher {
        cursor: default;
      }

      ~ .switch__switcher {
        background-color: $switchDisabledSwitcherBackgroundColor;
      }
    }

    &:checked:disabled {
      ~ .switch__bg {
        background-color: $switchCheckedDisabledBgBackgroundColor;
      }

      ~ .switch__switcher {
        background-color: $switchCheckedDisabledSwitcherBackgroundColor;
        border: $switchCheckedDisabledSwitcherBorder;
        transition: transform 0.3s ease-out;
        transform: translateX(16px);
      }
    }

    &:checked {
      ~ .switch__switcher {
        border: none;
      }
    }
  }

  &__bg {
    width: 100%;
    height: 100%;
    pointer-events: none;
    background-color: $switchBgBackgroundColor;
    border-radius: 12px;
    transition: background-color 0.7s ease-out;
  }

  &__switcher {
    position: absolute;
    top: 2px;
    left: 2px;
    display: block;
    width: 20px;
    height: 20px;
    background-color: $switchSwitcherBackgroundColor;
    border: $switchSwitcherBorder;
    border-radius: 50%;
    box-shadow: $switchSwitcherBoxShadow;
    transition: transform 0.3s ease-out;
    transform: translateX(0);

    &:before {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 60px;
      height: 24px;
      content: '';
      transform: translate(-50%, -50%);
    }

    &:after {
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      width: 28px;
      height: 28px;
      content: '';
      border-radius: 50%;
      opacity: 0.6;
      transform: translate(-50%, -50%) scale(0);
    }
  }

  &--disabled &__switcher {
    border: $switchDisabledSwitcherBorder;
  }

  &--column-change {
    position: relative;
    width: 84px;
    height: 44px;

    .switch__bg {
      width: 84px;
      height: 44px;
      border-radius: 7px;
    }

    .switch__icon {
      width: 84px;
      height: auto;
    }

    .switch__switcher {
      background-color: transparent;

      &:before {
        top: 0;
        left: 0;
        width: 40px;
        height: 40px;
        border-radius: 5px;
        transform: translate(0, 0);
      }
    }

    .switch__input:not(:disabled):checked ~ .switch__switcher {
      transform: translateX(40px);
    }
  }
}
</style>
