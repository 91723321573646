import { defineStore } from 'pinia';
import { ref } from 'vue';

import { assert } from '@leon-hub/guards';
import type {
  CustomerLimitsRange,
  CustomerLimitsUpdatableAt,
  SelfExclusion,
} from '@leon-hub/api-sdk';
import {
  doSelfExclusion as excludeSelf,
  doSelfExclusionFlex as excludeSelfFlex,
  getBetLimits,
  getDepositLimits,
  getSelfExclusion,
  PinCodePlace,
  PinCodeRequiredExceptionCode,
  responsibleGamingLimitTypeBetLimit,
  SelfExclusionTimeUnitType,
  setBetLimits as setSdkBetLimits,
  setDepositLimits as setSdkDepositLimits,
} from '@leon-hub/api-sdk';
import type { BaseRouteNameType } from '@leon-hub/routing-config-names';
import { RouteName } from '@leon-hub/routing-config-names';

import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import { useErrorsConverter } from 'web/src/modules/errors/composables';
import type { ResponsibleGamblingDepositLimitsData } from 'web/src/modules/responsible-gambling/pages/types';
import type { CustomerLimitsDataDocument, Limits } from 'web/src/modules/responsible-gambling/types';
import { usePinCodeStore } from 'web/src/modules/pin-code/store';
import { isCustomerLimitsRange } from 'web/src/modules/responsible-gambling/guards/isCustomerLimitsRange';
import { canNotSelfExcludeCode } from 'web/src/modules/responsible-gambling/constants';
import { useCustomerDataStore } from 'web/src/modules/customer/store';

const useResponsibleGamblingStore = defineStore('responsible-gambling', () => {
  const api = useGraphqlClient();
  const { convertToBaseError } = useErrorsConverter();
  const { setResponsibleGamingLimits } = useCustomerDataStore();

  const betLimits = ref<CustomerLimitsDataDocument | null>(null);
  const betLimitsLoading = ref<Limits<boolean>>({
    dailyLimit: false,
    weeklyLimit: false,
    monthlyLimit: false,
    dailyPercent: false,
    dailyLeft: false,
    dailyEndAt: false,
    weeklyPercent: false,
    weeklyLeft: false,
    weeklyEndAt: false,
    monthlyPercent: false,
    monthlyLeft: false,
    monthlyEndAt: false,
  });
  const betLimitsUpdatableAt = ref<CustomerLimitsUpdatableAt | null>(null);
  const depositLimits = ref<CustomerLimitsDataDocument | null>(null);
  const depositLimitsLoading = ref<Limits<boolean>>({
    dailyLimit: false,
    weeklyLimit: false,
    monthlyLimit: false,
    dailyPercent: false,
    dailyLeft: false,
    dailyEndAt: false,
    weeklyPercent: false,
    weeklyLeft: false,
    weeklyEndAt: false,
    monthlyPercent: false,
    monthlyLeft: false,
    monthlyEndAt: false,
  });
  const depositLimitsUpdatableAt = ref<CustomerLimitsUpdatableAt | null>(null);
  const selfExclusion = ref<SelfExclusion | null>(null);
  const depositLimitsData = ref<Maybe<ResponsibleGamblingDepositLimitsData>>(null);
  const selfExclusionId = ref<Maybe<string>>(null);
  const selfExclusionMonths = ref<Maybe<number>>(null);
  const selfExclusionRouteBack = ref<Maybe<BaseRouteNameType>>(null);

  // Mutations:
  function setBetLimits(payload: { limits: CustomerLimitsDataDocument; updatableAt: CustomerLimitsUpdatableAt }): void {
    betLimits.value = payload.limits;
    betLimitsUpdatableAt.value = payload.updatableAt;
  }

  // eslint-disable-next-line max-len
  function setDepositLimits(payload: {
    limits: CustomerLimitsDataDocument;
    updatableAt: CustomerLimitsUpdatableAt;
  }): void {
    depositLimits.value = payload.limits;
    depositLimitsUpdatableAt.value = payload.updatableAt;
  }

  function setSelfExclusion(value: SelfExclusion): void {
    selfExclusion.value = value;
  }

  function setDepositLimitsLoader(payload: { limitPeriod: keyof CustomerLimitsDataDocument; value: boolean }): void {
    depositLimitsLoading.value[payload.limitPeriod] = payload.value;
  }

  function setBetLimitsLoader(payload: { limitPeriod: keyof CustomerLimitsDataDocument; value: boolean }): void {
    betLimitsLoading.value[payload.limitPeriod] = payload.value;
  }

  function setDepositLimitsData(value: Maybe<ResponsibleGamblingDepositLimitsData>): void {
    depositLimitsData.value = value;
  }

  function setSelfExclusionId(value: Maybe<string>): void {
    selfExclusionId.value = value;
  }

  function setSelfExclusionMonths(value: Maybe<number>): void {
    selfExclusionMonths.value = value;
  }

  function setSelfExclusionRouteBack(value: Maybe<BaseRouteNameType>): void {
    selfExclusionRouteBack.value = value;
  }

  // Actions:
  async function loadBetLimits(): Promise<void> {
    const response = await getBetLimits(api, (node) => node.queries.limits.getBetLimits);
    if (response?.limits && response.limitsUpdatableAt) {
      setBetLimits({ limits: response.limits, updatableAt: response.limitsUpdatableAt });
    }
  }

  async function loadDepositLimits(): Promise<void> {
    const response = await getDepositLimits(api, (node) => node.queries.limits.getDepositLimits);
    if (response?.limits && response.limitsUpdatableAt) {
      setDepositLimits({ limits: response.limits, updatableAt: response.limitsUpdatableAt });
    }
  }

  async function loadSelfExclusion(): Promise<void> {
    const response = await getSelfExclusion(api,
      (node) => node.queries.limits.getDepositLimits);
    if (response?.selfExclusion) {
      setSelfExclusion(response.selfExclusion);
    }
  }

  function pinCodeCheck(originalError: unknown, route: BaseRouteNameType): void {
    const error = convertToBaseError(originalError);
    const errorCode = error.code.toString() as PinCodeRequiredExceptionCode;
    if (errorCode === PinCodeRequiredExceptionCode.PIN_CODE_VERIFICATION_NEEDED) {
      usePinCodeStore().setRouteFrom({ name: route });
      usePinCodeStore().setPinCodePlace(PinCodePlace.SELF_EXCLUSION);
      usePinCodeStore().setStepWithLS('VERIFY');

      throw new Error(errorCode);
    }
  }

  function canNotExcludeCodeCheck(originalError: unknown): void {
    const error = convertToBaseError(originalError);

    if (error.message === canNotSelfExcludeCode) {
      throw new Error(error.message);
    }
  }

  // TODO: renamed setDepositLimits -> setDepositLimitsAction
  async function setDepositLimitsAction(payload: {
    type: CustomerLimitsRange[keyof CustomerLimitsRange];
    limitPeriod: keyof CustomerLimitsDataDocument;
    limit: string | null;
  }): Promise<void> {
    setDepositLimitsLoader({ limitPeriod: payload.limitPeriod, value: true });
    assert(isCustomerLimitsRange(payload.type), 'payload.type is not an CustomerLimitsRange value');
    const response = await setSdkDepositLimits(api,
      (node) => node.mutations.limits.setDepositLimits,
      { options: { type: payload.type, limit: payload.limit } })
      .catch((originalError) => {
        pinCodeCheck(originalError, RouteName.RESPONSIBLE_GAMBLING_DEPOSIT_LIMITS);
        throw originalError;
      })
      .finally(() => {
        setDepositLimitsLoader({ limitPeriod: payload.limitPeriod, value: false });
      });

    if (response && response.result && response.result === 'OK') {
      setDepositLimits({ limits: response.limits, updatableAt: response.limitsUpdatableAt });
    }
  }

  // TODO: renamed setBetLimits -> setBetLimitsAction
  async function setBetLimitsAction(payload: {
    type: CustomerLimitsRange[keyof CustomerLimitsRange];
    limitPeriod: keyof CustomerLimitsDataDocument;
    limit: string | null;
  }): Promise<void> {
    setBetLimitsLoader({ limitPeriod: payload.limitPeriod, value: true });
    assert(isCustomerLimitsRange(payload.type), 'payload.type is not an CustomerLimitsRange value');
    const response = await setSdkBetLimits(
      api,
      (node) => node.mutations.limits.setBetLimits,
      { options: { type: payload.type, limit: payload.limit } },
    )
      .catch((originalError) => {
        pinCodeCheck(originalError, RouteName.RESPONSIBLE_GAMBLING_BETTING_LIMITS);
        throw originalError;
      })
      .finally(() => {
        setBetLimitsLoader({ limitPeriod: payload.limitPeriod, value: false });
      });

    if (response?.result === 'OK') {
      setBetLimits({ limits: response.limits, updatableAt: response.limitsUpdatableAt });
      setResponsibleGamingLimits(responsibleGamingLimitTypeBetLimit, true);
    }
  }

  async function doSelfExclusion(months: number): Promise<void> {
    const response = await excludeSelf(
      api,
      (node) => node.mutations.limits.doSelfExclusion,
      {
        options: {
          months,
        },
      },
    )
      .catch((originalError) => {
        pinCodeCheck(originalError,
          process.env.VUE_APP_PRODUCT_LEONRU
            ? RouteName.RESPONSIBLE_GAMBLING_SELF_EXCLUSION
            : (selfExclusionRouteBack.value ?? RouteName.RESPONSIBLE_GAMBLING_EXCLUSION));
      });

    if (response && response.selfExclusion) {
      setSelfExclusion(response.selfExclusion);
    }
  }

  async function doSelfExclusionFlex(months: number): Promise<void> {
    const response = await excludeSelfFlex(
      api,
      (node) => node.mutations.limits.doSelfExclusionFlex,
      {
        options: {
          timeUnitType: SelfExclusionTimeUnitType.MONTHS,
          timeUnitAmount: months,
        },
      },
    )
      // eslint-disable-next-line sonarjs/no-identical-functions
      .catch((originalError) => {
        pinCodeCheck(originalError,
          process.env.VUE_APP_PRODUCT_LEONRU
            ? RouteName.RESPONSIBLE_GAMBLING_SELF_EXCLUSION
            : (selfExclusionRouteBack.value ?? RouteName.RESPONSIBLE_GAMBLING_EXCLUSION));
        canNotExcludeCodeCheck(originalError);
      });

    if (response?.selfExclusion) {
      setSelfExclusion(response.selfExclusion);
    }
  }

  return {
    // State:
    betLimits,
    betLimitsLoading,
    betLimitsUpdatableAt,
    depositLimits,
    depositLimitsLoading,
    depositLimitsUpdatableAt,
    selfExclusion,
    depositLimitsData,
    selfExclusionId,
    selfExclusionMonths,
    // Mutations:
    setBetLimits,
    setDepositLimits,
    setSelfExclusion,
    setDepositLimitsData,
    setSelfExclusionId,
    setSelfExclusionMonths,
    setSelfExclusionRouteBack,
    // Actions:
    loadBetLimits,
    loadDepositLimits,
    loadSelfExclusion,
    setDepositLimitsAction,
    setBetLimitsAction,
    doSelfExclusion,
    doSelfExclusionFlex,
  };
});

export default useResponsibleGamblingStore;
