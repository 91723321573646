import type { Ref } from 'vue';
import { computed } from 'vue';

import type { BetlineMatchStatisticsTeam } from 'web/src/modules/sportline/types/rest';

interface UseMatchStatisticsTeamsComposable {
  teams: Ref<Maybe<[BetlineMatchStatisticsTeam, BetlineMatchStatisticsTeam]>>;
}

export default function useMatchStatisticsTeams(
  matchStatistics: Ref<Maybe<{ teams: Maybe<Readonly<BetlineMatchStatisticsTeam[]>> }>>,
): UseMatchStatisticsTeamsComposable {
  const teams = computed<Maybe<[BetlineMatchStatisticsTeam, BetlineMatchStatisticsTeam]>>(() => {
    const rawTeams = matchStatistics.value?.teams || null;
    return rawTeams && rawTeams.length > 1 ? [rawTeams[0], rawTeams[1]] : null;
  });

  return {
    teams,
  };
}
