<script setup lang="ts">
import { onMounted } from 'vue';

import type { RecaptchaV3Props } from 'web/src/modules/captcha/composables/useRecaptchaV3Props';

import type { CaptchaVerificationEmits } from '../types/CaptchaVerificationEmits';
import { useRecaptchaV3 } from '../../composables/useRecaptchaV3';

const props = defineProps<RecaptchaV3Props>();
const emit = defineEmits<CaptchaVerificationEmits>();

const { getToken } = useRecaptchaV3();

const doVerification = async (): Promise<void> => {
  const token = await getToken(props.action);
  if (token) {
    emit('verify', token);
  }
};

onMounted(doVerification);

defineOptions({
  inheritAttrs: false,
});
</script>

<template>
  <div v-auto-id="'VRecaptchaV3'" v-data-test="{el: 'VRecaptchaV3'}" />
</template>
