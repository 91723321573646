import type { RegistrationStage } from 'web/src/modules/registration/enums';
import { CommonRegistrationState } from 'web/src/modules/registration/types';

export interface SimpleRegistrationRouteComponentProps {
  stage: RegistrationStage;
}

export class SimpleRegistrationState extends CommonRegistrationState {
    static EMAIL_REGISTRATION_DONE = 'EMAIL_REGISTRATION_DONE';
}
