/* eslint-disable class-methods-use-this */
import type { RouteLocationNormalized } from 'vue-router';

import type { AppVueRouter } from 'web/src/modules/core/services/router/types';
import type { GetRouteSeoConfig } from 'web/src/modules/core/services/router/seo';
import { AbstractPageSeoController } from 'web/src/modules/core/services/router/seo';
import useSeoMetaStorage from 'web/src/modules/seo/store/useSeoMetaStorage';

export default class DefaultPageSeoController extends AbstractPageSeoController {
  getSeoConfig(
    router: AppVueRouter,
    from: RouteLocationNormalized,
    to: RouteLocationNormalized,
  ): Promise<GetRouteSeoConfig> {
    return Promise.resolve(to);
  }

  loadSeoMetaDataFor(
    route: GetRouteSeoConfig,
  ): void {
    useSeoMetaStorage().updateSpintaxMetaData(route);
  }
}
