<script lang="ts" setup>
import { toRef, onBeforeUnmount } from 'vue';

import type {
  SportradarLiveMatchTrackerWidgetProps,
  BaseSportlineEventDetailsWidgetEmits,
} from 'web/src/modules/sportline/submodules/widgets/types';
import { VLazyIframe as VIframe } from 'web/src/components/Iframe';
import { VLoaderDelayed } from 'web/src/components/Loader';
import VJumbotron from 'web/src/components/Jumbotron/VJumbotron/VJumbotron.vue';

import useSportradarLiveMatchTrackerWidget from './useSportradarLiveMatchTrackerWidget';
import WidgetWrapper from './WidgetWrapper.vue';

const props = defineProps<SportradarLiveMatchTrackerWidgetProps>();
const emit = defineEmits<BaseSportlineEventDetailsWidgetEmits>();

const sportEventDetails = toRef(() => props.sportEventDetails);
const isFloatingModeEnabled = toRef(() => props.isFloatingModeEnabled);
const isFloatingModeAllowed = toRef(() => props.isFloatingModeAllowed);
const aspectRatio = toRef(() => props.aspectRatio);
const isActive = toRef(() => props.isActive);

const {
  widgetLoaded,
  readyToLoading,
  showFailureMessage,
  iframeWidgetSrc,
  iframeName,
  additionalQueryStringParameters,
  onIframeMounted,
  releaseMessageBus,
} = useSportradarLiveMatchTrackerWidget({
  sportEventDetails,
  isActive,
}, {
  onSizeChanged(size): void {
    emit('size-changed', size);
  },
});

onBeforeUnmount(() => {
  releaseMessageBus();
});
</script>

<template>
  <div v-auto-id="'SportradarLiveMatchTrackerWidget'" :class="$style['live-widget-slide']">
    <WidgetWrapper
      :class="$style['live-widget-slide__component']"
      :is-floating-mode-allowed="isFloatingModeAllowed"
      :is-floating-mode-enabled="isFloatingModeEnabled && widgetLoaded"
    >
      <VLoaderDelayed
        v-if="!readyToLoading || !widgetLoaded || !iframeWidgetSrc"
        :class="$style['live-widget-slide__loader']"
      />
      <VJumbotron
        v-if="showFailureMessage"
        :heading="$t('WEB2_FAILED_TO_LOAD_LIVEWIDGET')"
      />
      <VIframe
        v-if="readyToLoading && iframeWidgetSrc"
        v-show="widgetLoaded"
        :key="iframeWidgetSrc"
        :src="iframeWidgetSrc"
        :height-aspect-ratio="aspectRatio"
        :full-height="!!'1'"
        :iframe-class="$style['live-widget-slide__iframe']"
        full-width
        :name="iframeName"
        :additional-query-string-parameters="additionalQueryStringParameters"
        @vue:mounted="onIframeMounted"
      />
    </WidgetWrapper>
  </div>
</template>

<style module lang="scss">
@include for-layout using ($isDesktop) {
  .live-widget-slide {
    display: flex;
    align-items: baseline;
    justify-content: center;
    width: 100%;
    height: 100%;

    &__component {
      min-height: 204px; // actual LMT content's height is about 201.4 (325px / ~1.613) but iframe fits to 100% (this value)

      @if $isDesktop {
        flex: none;
        width: 100%;
        height: 100%;
      } @else {
        flex: 1 0 auto;
      }
    }

    &__loader {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      min-height: 200px;
    }

    &__iframe {
      min-height: 280px;
      max-height: 350px;
    }
  }
}
</style>
