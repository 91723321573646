import { computed } from 'vue';

import { ProgramRewardType } from '@leon-hub/api-sdk';

import type { I18nFunctionReactive } from 'web/src/modules/i18n/types';
import type { FormatMoneyFunc } from 'web/src/modules/money/types';
import type { ExtendedReferral } from 'web/src/modules/referral-program/types/ReferralProgramDocuments';
import type {
  ReferralProgramSelectBonusProps,
} from 'web/src/modules/referral-program/components/ReferralProgramSelectBonus/types';

export default function getRewardProps(
  reward: ExtendedReferral['playerRewardOptions'][0],
  $t: I18nFunctionReactive,
  formatMoney: FormatMoneyFunc,
  withoutLabel?: boolean,
): ReferralProgramSelectBonusProps {
  const rewardAmountTParams = computed(() => ({
    amount: formatMoney(reward.nominalValue || 0),
  }));

  const rewardCountTParams = computed(() => ({
    count: `${reward.count || 0}`,
  }));

  if (reward.rewardType === ProgramRewardType.FREESPIN) {
    const infoParams = computed(() => ({
      game: reward.game?.name || '',
      freespinProvider: String(reward.freespinProviderName || ''),
      wagerMultiplier: String(reward.wagerMultiplier || ''),
    }));

    return {
      type: ProgramRewardType.FREESPIN,
      title: $t('WEB2_REFERRAL_FREESPIN', rewardAmountTParams).value,
      label: withoutLabel ? rewardCountTParams.value.count : $t('WEB2_REFERRAL_COUNT', rewardCountTParams).value,
      info: $t('WEB2_REFPROG_FREESPIN_REWARD_MESSAGE', infoParams).value,
    };
  }

  if (reward.rewardType === ProgramRewardType.FREEBET) {
    const infoParams = computed(() => ({
      freebetMinOdds: String(reward.freebetMinOdds || ''),
      freebetMaxOdds: String(reward.freebetMaxOdds || ''),
      wagerMultiplier: String(reward.wagerMultiplier || ''),
    }));

    return {
      type: ProgramRewardType.FREEBET,
      title: $t('WEB2_REFERRAL_FREEBETS', rewardAmountTParams).value,
      label: withoutLabel ? rewardCountTParams.value.count : $t('WEB2_REFERRAL_COUNT', rewardCountTParams).value,
      info: $t('WEB2_REFPROG_FREEBET_REWARD_MESSAGE', infoParams).value,
    };
  }

  return {};
}
