import type { BatchedSlipInfoDocument, SlipEntryId, SlipInfoArray } from '../types';
import getSlipEntryId from '../../../utils/getSlipEntryId';

export default function getSinglesClearedFromBetById(input: BatchedSlipInfoDocument, id: SlipEntryId): SlipInfoArray {
  const { slipEntries } = input;
  const cleared: SlipInfoArray = slipEntries.filter(({ entries }) => {
    const first = entries[0];
    return first && getSlipEntryId(first) !== id;
  });
  return cleared;
}
