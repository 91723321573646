import { computed, ref } from 'vue';
import type { ComputedRef, Ref } from 'vue';

import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';

import { useAnalytics } from 'web/src/modules/analytics/composables';
import { useCurrency } from 'web/src/modules/money/composables';
import usePaymentsMaxValueFastChoice
  from 'web/src/modules/payments/form-widgets/FastSum/components/PaymentsFastSumInput/composables/usePaymentsMaxValueFastChoice';

import type { PaymentsFastSumInputProps, PaymentsFastSumInputEmits } from '../types';

interface PaymentsFastSumInputComposable {
  fastChoiceValue: ComputedRef<number>;

  onFastSumInputMounted(): void;

  loading: Ref<boolean>;

  handleInput(value: number | string): void;

  sendPredefinedSumDepositAnalytics(value: number): void;
}

export default function usePaymentsFastSumInput(
  props: PaymentsFastSumInputProps,
  emit: PaymentsFastSumInputEmits,
): PaymentsFastSumInputComposable {
  const { currency } = useCurrency();
  const analytics = useAnalytics();
  const { maxValueFastChoice } = usePaymentsMaxValueFastChoice(props);

  const selectedChoice: Ref<string | number> = ref('0');
  const loading = ref(true);

  const fastChoiceValue = computed(() => parseFloat(selectedChoice.value as string));

  function onFastSumInputMounted(): void {
    loading.value = false;
  }

  function sendPredefinedSumDepositAnalytics(value: number): void {
    if (props.isDeposit) {
      analytics.push(AnalyticsEvent.Z_PREDEFINED_SUM_DEPOSIT, {
        deposits: {
          predefinedSum: {
            [currency.value]: maxValueFastChoice.value === value ? 'MAX' : value,
          },
        },
      });
    }
  }

  function handleInput(value: number | string): void {
    selectedChoice.value = value;
    emit('input', {
      target: {
        name: props.name || '',
        value: `${value}`,
      },
    });
  }

  return {
    fastChoiceValue,
    onFastSumInputMounted,
    loading,
    handleInput,
    sendPredefinedSumDepositAnalytics,
  };
}
