<script lang="ts" setup>
import { IconName } from '@leon-hub/icons';

import { VIcon } from '@components/v-icon';

import PromotionDetailsDescription
  from 'web/src/modules/promotions/components/PromotionDetailsDescription/PromotionDetailsDescription.vue';
import VStatus from 'web/src/components/Status/VStatus/VStatus.vue';
import PromotionRate from 'web/src/modules/promotions/components/PromotionRate/PromotionRate.vue';

import { usePromotionDetailsHeader } from './composables';
import type { PromotionDetailsHeaderProps } from './types';

const props = defineProps<PromotionDetailsHeaderProps>();

const { periodProperties } = usePromotionDetailsHeader(props);
</script>

<template>
  <div v-auto-id="'PromotionDetailsHeader'"
    :class="{
      [$style['promotion-details-title']]: true,
      [$style['promotion-details-title--archived']]: promotion.isArchived,
    }"
  >
    <div :class="$style['promotion-details-title__wrapper']">
      <div :class="$style['promotion-details-title__wrapper-top']">
        <div
          v-if="promotion.startDateNumber && promotion.endDateNumber"
          :class="{
            [$style['promotion-details-title__period']]: true,
            [$style['promotion-details-title__period--archived']]: promotion.isArchived,
          }"
        >
          <VIcon
            v-if="null && promotion.isArchived"
            :name="IconName.SLOTT_CHECK"
          />
          {{ periodProperties }}
        </div>
        <div
          v-if="null && null && !promotion.isArchived"
          :class="$style['promotion-details-title__rate-wrapper']"
        >
          <PromotionRate />
        </div>
      </div>
      <div :class="$style['promotion-details-title__wrapper-middle']">
        <h1
          :class="{
            [$style['promotions-shared__title']]: true,
            [$style['promotions-shared__title--big']]: !null,
            [$style['promotion-details-title__name']]: true,
          }"
        >
          {{ promotion.name }}
        </h1>
        <PromotionDetailsDescription
          v-if="promotion.description"
          :description="promotion.description"
        />
      </div>

      <VStatus
        v-if="!null && promotion.isArchived"
        :text="$t('JSP_BONUSPROMO_ARCHIVED')"
        :class="$style['promotion-details-title__status']"
      />
    </div>
  </div>
</template>

<style lang="scss" module>
@import 'web/src/modules/promotions/styles/promotions-shared';

.promotion-details-title {
  $self: &;

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: $promotionDetailsTitleWrapper;

    @include is-app-layout-phone {
      @include promotionDetailsWrapperMobile;
    }
  }

  &__wrapper-top {
    @include promotionDetailsWrapperTop;
  }

  &__rate-wrapper {
    position: relative;
  }

  &__wrapper-middle {
    @include is-app-layout-phone {
      @include promotionDetailsWrapperMiddle;
    }
  }

  &__period {
    @include promotionDetailsPeriod;

    padding: $promotionDetailsPeriodPaddings;
    color: $promotionDetailsPeriodColor;
    background-color: $promotionDetailsPeriodBackgroundColor;
    border: $promotionDetailsPeriodBorder;
    border-radius: $promotionDetailsPeriodBorderRadius;

    &--archived {
      @include promotionDetailsPeriodArchived;
    }
  }

  &__name {
    @include promotionDetailsTitleMarginBottom;

    text-align: $promotionDetailsTitleAlign;
  }

  &__pre-text {
    margin-bottom: 24px;
    font-size: 14px;
    line-height: 20px;
    color: var(--TextPrimary);
  }

  &__status {
    margin-top: 24px;
    margin-right: auto;
    margin-left: auto;
  }
}
</style>
