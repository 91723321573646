import type { LeaderBoardProps } from 'web/src/modules/promotions/components/LeaderBoard/types';

export default function getCustomerPositionIndex(
  props: Pick<LeaderBoardProps, 'list' | 'customerPosition'>,
): Optional<number> {
  if (!props.customerPosition || props.list.length <= 0) {
    return undefined;
  }
  const index = props.list.findIndex((item) => item.place === props.customerPosition?.place);
  return index > -1 ? index : undefined;
}
