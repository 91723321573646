<script lang="ts" setup>
import { IconName } from '@leon-hub/icons';

import vDataTestUnit from 'web/src/testing/directives/vDataTestUnit';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import PaymentBonusItem from 'web/src/modules/payments/components/PaymentBonusItem/PaymentBonusItem.vue';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';

import { usePaymentBonuses } from './composables';
import type { PaymentBonusesProps, PaymentBonusesEmits } from './types';

const props = defineProps<PaymentBonusesProps>();
const emit = defineEmits<PaymentBonusesEmits>();

const {
  selectedBonus,
  emitBonusSelection,
  handleSelectionComplete,
  onNoDepositClick,
} = usePaymentBonuses(props, emit);
</script>

<template>
  <div v-auto-id="'PaymentBonuses'"
    :class="{
      [$style['payments-bonuses']]: true,
      [$style['payments-bonuses--modal']]: isModal,
    }"
  >
    <VButton
      v-data-test-unit="{ el: 'no-bonus-btn' }"
      :kind="ButtonKind.BASE"
      :icon-name="IconName.PROMOS_NO"
      full-width
      @click="onNoDepositClick"
    >
      {{ $t('WEB2_WITHOUT_BONUS') }}
    </VButton>
    <div :class="$style['payments-bonuses__title']">
      {{ $t('WEB2_BONUSES_AVAILABLE') }}
    </div>
    <PaymentBonusItem
      v-for="item in bonusList"
      :key="item.campaignId"
      v-data-test-unit="{ el: 'bonus-item' }"
      :bonus-item="item"
      :is-selected="item.campaignId === selectedBonus"
      @select="emitBonusSelection"
      @select-complete="handleSelectionComplete"
    />
  </div>
</template>

<style lang="scss" module>
@include for-layout using($isDesktop) {
  .payments-bonuses {
    width: 100%;

    &--modal {
      padding: 0;
    }

    > button {
      margin-bottom: 12px;
    }

    &__title {
      @include medium\13\16;

      padding: if($isDesktop, 16px 32px, 16px 0 12px 8px);
      color: var(--TextSecondary);
    }
  }
}
</style>
