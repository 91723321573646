import {
  getNewUri,
  prepareRedirectUri,
} from 'web/src/modules/core/services/router/utils';

import type { LanguagePrefixNavigationGuardOptions } from './types';

export default function checkPrefix(currentRedirectUri: string, {
  currentUrlPrefix,
  replacedUrlPrefix,
}: LanguagePrefixNavigationGuardOptions): string {
  let newRedirectUri = currentRedirectUri;

  if (currentUrlPrefix !== replacedUrlPrefix) {
    const { pathname, search } = window.location;
    const uri = `${pathname}${search}`;
    const newUri = getNewUri(replacedUrlPrefix, currentUrlPrefix);

    if (newUri !== uri) {
      newRedirectUri = prepareRedirectUri(newUri);

      if (!process.env.VUE_APP_PRERENDER) {
        window.history.replaceState(
          null,
          '',
          newUri,
        );
      }
    }
  }

  return newRedirectUri;
}
