import type { AbstractErrorOptions } from '@leon-hub/errors';
import { ApiErrorCode } from '@leon-hub/api';
import { AppError } from '@leon-hub/app-errors';

import type { CoreSportEventResponse } from 'web/src/modules/sportline/types/rest';

export interface SliceEventsResponseErrorOptions extends Without<AbstractErrorOptions, 'code'> {
  payload: {
    eventsErrors: {
      event: CoreSportEventResponse;
      originalError?: Error;
    }[];
    limits: {
      sports?: Maybe<number>;
      eventsInSport?: Maybe<number>;
      regions?: Maybe<number>;
      eventsInRegion?: Maybe<number>;
    };
  };
}

export class SliceEventsResponseError extends AppError {
  constructor(options: SliceEventsResponseErrorOptions) {
    super({
      ...options,
      code: ApiErrorCode.API_UNEXPECTED_ERROR,
      message: `Sportline: ${options?.message || 'Unexpected slice response error'}`,
    });
  }
}
