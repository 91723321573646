import {
  assert,
} from '@leon-hub/guards';

import type { InputHintProps } from 'web/src/components/Input/types';
import { isOptionalHintLinkOrNull } from 'web/src/components/Input/guards/isHintLink';

type HintLinkFields = Pick<InputHintProps, 'hintLink' | 'hintLinkRight'>;

function getHintLinkFields(options: HintLinkFields = {}): HintLinkFields {
  const { hintLink, hintLinkRight } = options;

  assert(isOptionalHintLinkOrNull(hintLink), `Unexpected hintLink value: ${JSON.stringify(hintLink)}`);

  assert(isOptionalHintLinkOrNull(hintLinkRight),
    `Unexpected hintLinkRight value: ${JSON.stringify(hintLinkRight)}`);

  return {
    hintLink,
    hintLinkRight,
  };
}

export default getHintLinkFields;
