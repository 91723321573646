export enum Tag {
  DIV = 'div',
  SPAN = 'span',
  INPUT = 'input',
  BUTTON = 'button',
  A = 'a',
  SVG = 'svg',
  I = 'i',
  LABEL = 'label',
  UL = 'ul',
  OL = 'ol',
  LI = 'li',
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6',
  P = 'p',
  SECTION = 'section',
  ARTICLE = 'article',
  TEXTAREA = 'textarea',
  DL = 'dl',
  DT = 'dt',
  DD = 'dd',
  ROUTER_LINK = 'router-link',
}

export default Tag;
