import type { Ref } from 'vue';
import {
  computed,
  nextTick,
  ref,
  toRef,
  watch,
} from 'vue';
import { useRouter } from 'vue-router';

import { CasinoRouteName } from '@leon-hub/routing-config-names/src/CasinoRouteName';
import {
  LobbyGameType,

  lobbyTypeLiveCasino,
  lobbyTypeSlots,
} from '@leon-hub/api-sdk';
import { useDebounce } from '@leon-hub/debounce';

import type { EgsGroup } from 'web/src/modules/egs/types';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useEgsGroupsStore } from 'web/src/modules/egs/submodules/groups/store';
import {
  filterBlockedGroups,
} from 'web/src/modules/egs/utils';
import { useEgsApi } from 'web/src/modules/egs/composables';

export interface LobbySearchGroupsComposable {
  searchText: Ref<string>;
  isSearchInProgress: Ref<boolean>;
  isNoSearchResults: Ref<boolean>;
  list: Ref<EgsGroup[]>;
  allGroups: Ref<EgsGroup[]>;
  onSearchInput(value: string): void;
  onSearchClear(): void;
}

export default function useLobbySearchGroups(allGroupsId = ''): LobbySearchGroupsComposable {
  const searchGroups = ref<EgsGroup[] | null>(null);
  const searchText = ref<string>('');

  const { $translate } = useI18n();
  const router = useRouter();
  const configStore = useSiteConfigStore();
  const searchMinCharsSize = toRef(configStore, 'searchMinCharsSize');
  const currentGroups = toRef(useEgsGroupsStore(), 'currentGroups');

  const isSearchResult = computed(() => searchText.value.length >= searchMinCharsSize.value);
  const isSearchInProgress = computed(() => isSearchResult.value && searchGroups.value === null);
  const isNoSearchResults = computed(() => isSearchResult.value && !!searchGroups.value && searchGroups.value.length === 0);
  const isLive = computed(() => (
    router.getName() === CasinoRouteName.CASINO_LIVE_GROUPS || router.getName() === CasinoRouteName.CASINO_LIVE_LOBBY
  ));

  const allGroups = computed<EgsGroup[]>(() => [
    {
      id: allGroupsId,
      name: $translate('WEB2_ALL_PROVIDERS').value,
      url: allGroupsId,
      iconUrls: {},
      type: isLive.value
        ? LobbyGameType.LIVE
        : LobbyGameType.SLOTS,
    },
    ...filterBlockedGroups(currentGroups.value),
  ]);

  const list = computed<EgsGroup[]>(() => {
    if (isSearchResult.value) {
      return filterBlockedGroups(searchGroups.value);
    }

    return allGroups.value;
  });

  function onSearchInput(value: string): void {
    searchText.value = value;

    if (!value) {
      searchGroups.value = null;
    }
  }

  function onSearchClear(): void {
    onSearchInput('');
  }

  async function doSearch(): Promise<void> {
    searchGroups.value = null;
    await nextTick();
    if (!isSearchResult.value) {
      return;
    }

    const { getGroups } = useEgsApi();
    searchGroups.value = await getGroups({
      type: isLive.value ? lobbyTypeLiveCasino : lobbyTypeSlots,
      groupName: searchText.value,
    });
  }

  watch(searchText, useDebounce(doSearch, 500));

  return {
    searchText,
    isSearchInProgress,
    isNoSearchResults,
    list,
    allGroups,
    onSearchInput,
    onSearchClear,
  };
}
