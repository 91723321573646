import type { CoreMarketRunnerResponse } from 'web/src/modules/sportline/types/rest';
import { SportEventStatus } from 'web/src/modules/sportline/enums';

export default function extractRunnerStatus(
  runnerResponse: CoreMarketRunnerResponse,
  { eventStatus, marketStatus }: {
    eventStatus: SportEventStatus;
    marketStatus: SportEventStatus;
  },
): SportEventStatus {
  if (eventStatus === SportEventStatus.Suspended || marketStatus === SportEventStatus.Suspended) {
    return SportEventStatus.Suspended;
  }

  return runnerResponse.open ? SportEventStatus.Open : SportEventStatus.Suspended;
}
