import type { Ref } from 'vue';
import {
  computed,
  toRef,
} from 'vue';

import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useTheme } from 'web/src/modules/theme/composables';
import type { FooterLogoType } from 'web/src/modules/core/views/FooterLicense/types';

export interface FooterFollowUsComposable {
  logos: Ref<FooterLogoType[]>;
  getLogo(logo: FooterLogoType): string;
}

export default function useFooterFollowUs(): FooterFollowUsComposable {
  const { isDark } = useTheme();

  const scgStore = useSiteConfigStore();
  const footerLogotypesBlock = toRef(scgStore, 'footerLogotypesBlock');

  const logos = computed<FooterLogoType[]>(() => footerLogotypesBlock.value?.socialNetworkLogotypesByLocale ?? []);

  function getLogo(logo: FooterLogoType): string {
    return isDark.value ? logo.iconDark : logo.iconLight;
  }

  return {
    logos,
    getLogo,
  };
}
