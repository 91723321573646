import type { Ref } from 'vue';
import {
  ref,
  toRef,
  computed,
} from 'vue';
import { useRouter } from 'vue-router';

import { RouteName } from '@leon-hub/routing-config-names';

import { useI18n } from 'web/src/modules/i18n/composables';
import { useVirtualSportStore } from 'web/src/modules/sportline/submodules/virtual-sport/store';
import type {
  SlipMappingKey,
} from 'web/src/modules/framed-app/components/VirtualSportFramedWidget/types';
import type {
  VirtualSportWidgetConfig,
  VirtualSportSelectedFilter,
  VirtualSportFilterOptions,
  VirtualSportFilterOption,
} from 'web/src/modules/sportline/submodules/virtual-sport/types';
import { framedVirtualSportIdBySport } from 'web/src/modules/framed-app/components/VirtualSportFramedWidget/constants';
import { isVirtualSportTournament } from 'web/src/modules/sportline/submodules/virtual-sport/guards';

interface UseVirtualSportRoutePageComposable {
  title: Ref<string>;
  internalWidgetLoaded: Ref<boolean>;
  widgetConfig: Ref<VirtualSportWidgetConfig>;
  selectedFilter: Ref<VirtualSportSelectedFilter>;
  filterOptions: Ref<VirtualSportFilterOptions>;
  selectedOption: Ref<Maybe<VirtualSportFilterOption>>;
  selectedBetsMappings: Ref<SlipMappingKey[]>;
  onChangeSelectedSportFilter(filter: VirtualSportSelectedFilter): void;
  onWidgetLoaded(value: boolean): void;
}

export function useVirtualSportRoutePage(): UseVirtualSportRoutePageComposable {
  const router = useRouter();
  const { $translate } = useI18n();
  const virtualSportStore = useVirtualSportStore();

  const widgetConfig = toRef(virtualSportStore, 'widgetConfig');
  const selectedFilter = toRef(virtualSportStore, 'selectedFilter');
  const filterOptions = toRef(virtualSportStore, 'filterOptions');
  const selectedOption = toRef(virtualSportStore, 'selectedOption');
  const selectedBetsMappings = toRef(virtualSportStore, 'selectedBetsMappings');

  const internalWidgetLoaded = ref(false);

  const genericTitle = $translate('JSP_VIRTUAL_SPORTS_TTL');
  const title = computed<string>(() => {
    const selectedSport = selectedOption.value?.sport;
    const framedVirtualSportId = selectedSport && framedVirtualSportIdBySport[selectedSport];
    const displayConfig = isVirtualSportTournament(framedVirtualSportId)
      ? selectedOption.value?.displayConfig
      : undefined;

    return displayConfig?.label
      ? `${genericTitle.value} - ${displayConfig.label}`
      : genericTitle.value;
  });

  function onChangeSelectedSportFilter(filter: VirtualSportSelectedFilter): void {
    const sport = filter.urlName?.toLowerCase();

    if (sport !== selectedFilter.value.urlName?.toLowerCase()) {
      internalWidgetLoaded.value = false;
    }

    void router.push302({
      name: RouteName.SPORTLINE_VIRTUAL_SPORT,
      params: { sport },
    });
  }

  function onWidgetLoaded(value: boolean): void {
    internalWidgetLoaded.value = value;
  }

  return {
    title,
    internalWidgetLoaded,
    widgetConfig,
    selectedFilter,
    filterOptions,
    selectedOption,
    selectedBetsMappings,
    onChangeSelectedSportFilter,
    onWidgetLoaded,
  };
}
