import { ScriptElement } from '@leon-hub/utils';

import type { TwitterTimelineScriptOptions, CreateTwitterTimelineOptions, TwitterScript } from '../types';

export class TwitterTimelineScript {
  protected scriptElement: ScriptElement | undefined;

  constructor(protected options: TwitterTimelineScriptOptions) {}

  async addScript(): Promise<void> {
    // eslint-disable-next-line no-console
    console.log('Prepare timeline script element');
    this.scriptElement = new ScriptElement({ name: 'twitter-loader', src: 'https://platform.twitter.com/widgets.js' });
    await this.scriptElement.addScript();
    // eslint-disable-next-line no-console
    console.log('Timeline script element has been added');
  }

  async createTimeline(element: HTMLElement): Promise<void> {
    const url = this.options.href;

    const twitterScript = await this.getTwitterScript();

    await twitterScript.ready();
    // eslint-disable-next-line no-console
    console.log('Ready to create timeline');

    let resolveOnRenderedPromise = () => {};
    const onRendered = new Promise<void>((resolve) => { resolveOnRenderedPromise = resolve; });
    twitterScript.events.bind('rendered', () => {
      // eslint-disable-next-line no-console
      console.log('Timeline has been rendered');
      resolveOnRenderedPromise();
    });

    await twitterScript.widgets.createTimeline({ sourceType: 'url', url }, element, this.getFinalOptions());
    // eslint-disable-next-line no-console
    console.log('Timeline has been created');

    await onRendered;
  }

  protected async getTwitterScript(): Promise<TwitterScript> {
    if (!window.twttr) { await this.addScript(); }
    if (!window.twttr) { throw new Error('Twitter script is not found'); }
    return window.twttr;
  }

  protected getFinalOptions(): CreateTwitterTimelineOptions {
    const {
      theme, width, height, lang,
    } = this.options;
    const options: CreateTwitterTimelineOptions = {};
    if (theme) { options.theme = theme; }
    if (width) { options.width = width; }
    if (height) { options.height = height; }
    if (lang) { options.lang = lang; }
    return options;
  }
}
