import type { Ref } from 'vue';
import { computed, toRef } from 'vue';

import { useSportlineEventStatisticStore } from 'web/src/modules/sportline/submodules/event-statistic/store';
import useMatchStatisticsTeams
  from 'web/src/modules/sportline/submodules/event-statistic/composables/useMatchStatisticsTeams';
import type {
  BetlinePostMatchStatistics,
  BetlineLeagueStanding,
  BetlineMatchStatisticsTeam,
  BetlineMatchStatisticsMatch,
} from 'web/src/modules/sportline/types/rest';
import type { TeamPlayTotalStatistic } from 'web/src/modules/sportline/submodules/core-statistic/types';
import type { TeamsConfrontationStatisticBlockData } from 'web/src/modules/sportline/submodules/event-statistic/types';

import { useLeagueStandings } from './composables/useLeagueStandings';
import { useH2hMatchStatistic } from './composables/useH2hMatchStatistic';
import { useLastGamesMatchStatistic } from './composables/useLastGamesMatchStatistic';

interface UseSportlineEventPostMatchStatisticComposable {
  postMatchStatistic: Ref<Maybe<BetlinePostMatchStatistics>>;
  confrontationBlocks: Ref<TeamsConfrontationStatisticBlockData[]>;
  statisticSportFamily: Ref<Maybe<string>>;
  leagueStandings: Ref<BetlineLeagueStanding[]>;
  teams: Ref<Maybe<[BetlineMatchStatisticsTeam, BetlineMatchStatisticsTeam]>>;
  h2hMatchStatistic: Ref<Maybe<TeamPlayTotalStatistic>>;
  joinMatches: Ref<BetlineMatchStatisticsMatch[]>;
  hostMatches: Ref<BetlineMatchStatisticsMatch[]>;
  guestMatches: Ref<BetlineMatchStatisticsMatch[]>;
  canDisplayH2hStatistic: Ref<boolean>;
  canDisplayLastGamesResultsStatistic: Ref<boolean>;
  canDisplayTournamentTableStatistic: Ref<boolean>;
  canShowConfrontationStatistic: Ref<boolean>;
  canShowRightDisclaimer: Ref<boolean>;
  canShowLeftDisclaimer: Ref<boolean>;
}

export default function useSportlineEventPostMatchStatistic(): UseSportlineEventPostMatchStatisticComposable {
  const sportlineEventStatisticStore = useSportlineEventStatisticStore();

  const betlineMatchStatistics = toRef(sportlineEventStatisticStore, 'betlineMatchStatistics');
  const betlineLeagueStandings = toRef(sportlineEventStatisticStore, 'betlineLeagueStandings');
  const betlinePostMatchStatistics = toRef(sportlineEventStatisticStore, 'betlinePostMatchStatistics');
  const confrontationBlocks = toRef(sportlineEventStatisticStore, 'confrontationBlocks');
  const statisticSportFamily = toRef(sportlineEventStatisticStore, 'statisticSportFamily');

  const postMatchStatistic = computed<Maybe<BetlinePostMatchStatistics>>(
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    () => betlinePostMatchStatistics.value || null,
  );
  const { leagueStandings } = useLeagueStandings({ betlineLeagueStandings });
  const { h2hMatchStatistic, joinMatches } = useH2hMatchStatistic({ betlineMatchStatistics });
  const { hostMatches, guestMatches } = useLastGamesMatchStatistic({ betlineMatchStatistics });
  const { teams } = useMatchStatisticsTeams(betlineMatchStatistics);

  const canDisplayH2hStatistic = computed<boolean>(() => !!teams.value
    && (joinMatches.value.length > 0 || !!h2hMatchStatistic.value));
  const canDisplayLastGamesResultsStatistic = computed<boolean>(() => !!teams.value
    && (hostMatches.value.length > 0 || guestMatches.value.length > 0));
  const canDisplayTournamentTableStatistic = computed<boolean>(() => !!teams.value
    && leagueStandings.value.length > 0);
  const canShowConfrontationStatistic = computed<boolean>(() => confrontationBlocks.value.length > 0);
  const canShowRightDisclaimer = computed<boolean>(() => (
    canDisplayH2hStatistic.value
    || canDisplayLastGamesResultsStatistic.value
    || canDisplayTournamentTableStatistic.value
  ));
  const canShowLeftDisclaimer = computed<boolean>(() => (
    !canDisplayH2hStatistic.value
    && !canDisplayLastGamesResultsStatistic.value
    && !canDisplayTournamentTableStatistic.value
    && canShowConfrontationStatistic.value
  ));

  return {
    postMatchStatistic,
    confrontationBlocks,
    statisticSportFamily,
    leagueStandings,
    teams,
    h2hMatchStatistic,
    joinMatches,
    hostMatches,
    guestMatches,
    canDisplayH2hStatistic,
    canDisplayLastGamesResultsStatistic,
    canDisplayTournamentTableStatistic,
    canShowConfrontationStatistic,
    canShowRightDisclaimer,
    canShowLeftDisclaimer,
  };
}
