<script lang="ts" setup>
import {
  IconName,
  IconSize,
} from '@leon-hub/icons';

import { VIcon } from '@components/v-icon';

import { Tag } from 'web/src/components/_shared';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import SButton from 'web/src/components/Button/SButton/SButton.vue';

import { useVBalance } from './composables';
import type { VBalanceProps, VBalanceEmits } from './types';

const props = withDefaults(defineProps<VBalanceProps>(), {
  tag: Tag.DIV,
});

const emit = defineEmits<VBalanceEmits>();

const {
  balanceValue,
  currencyIconProps,
  mainTag,
} = useVBalance(props);

function emitTopUp(): void {
  emit('top-up');
}

function emitClickButtonGift(): void {
  emit('click-button-gift');
}
</script>

<template>
  <component v-auto-id="'VBalance'"
    :is="tag"
    ref="mainTag"
    :class="{
      [$style['balance']]: true,
      [$style['balance--low']]: isLowBalance,
      [$style['balance--is-deposit-hidden']]: isBalanceHidden,
      [$style['balance--is-header']]: isHeader && '1',
      [$style['balance--game']]: isEgsGamePage,
    }"
    @click="emitTopUp"
  >
    <div
      v-if="isSumOfBalancesEnabled && !isBalanceHidden"
      :class="$style['balance__gift']"
      @click.stop="emitClickButtonGift"
    >
      <VIcon
        :name="IconName.PROMOS"
        :size="IconSize.SIZE_16"
        :class="$style['balance__gift-icon']"
      />
    </div>
    <div
      v-if="!isBalanceHidden"
      :class="$style['balance__text']"
    >
      {{ balanceValue }}
    </div>
    <SButton
      v-if="null"
      kind="primary"
      :size="'1' ? 'small' : 'xsmall'"
      :label="'1' ? $t('WEB2_HEADER_DEPOSIT_BTN') : undefined"
      :icon-name="IconName.SLOTT_PLUS_1"
      @click.stop="emitTopUp"
    />
    <VButton
      v-else
      v-bind="currencyIconProps"
      :class="$style['balance__button']"
      @click.stop="emitTopUp"
    />
  </component>
</template>

<style lang="scss" module>
.balance {
  display: flex;
  align-items: center;
  padding: 2px;
  color: $balanceColor;
  border-radius: $balanceBorderRadius;

  @include for-hover {
    cursor: pointer;

    &:hover {
      @include hover;
    }
  }

  &__text {
    @include balance-font;

    padding: 0 8px 0 10px;
  }

  &__button {
    min-width: 32px;
  }

  &__gift {
    @include gift;

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    @include for-hover {
      &:hover {
        background-color: var(--BrandHighlight);

        .balance__gift-icon {
          color: var(--BrandText);
        }
      }
    }

    &-icon {
      color: var(--BrandHighlight);
    }

    @include is-app-layout-phone {
      &:after {
        position: absolute;
        top: 0;
        right: 74%;
        bottom: 0;
        left: 0;
        content: '';
      }
    }
  }

  &--low {
    color: var(--ErrorText);
  }

  &--is-deposit-hidden {
    padding: 0;
  }

  &--is-header {
    color: if($isEnvFeatureTwinlikeStyle, var(--TextDefault), var(--DTextDefault));

    @include for-hover {
      &:hover {
        .balance__gift {
          background-color: if($isEnvFeatureTwinlikeStyle, var(--OpacityLayer2), var(--DOpacityLayer2));

          &:hover {
            background-color: if($isEnvFeatureTwinlikeStyle, var(--BrandHighlight), var(--DBrandHighlight));

            .balance__gift-icon {
              color: if($isEnvFeatureTwinlikeStyle, var(--BrandText), var(--DBrandText));
            }
          }
        }

        .balance__button {
          background-color: if($isEnvFeatureTwinlikeStyle, var(--BrandHighlight), var(--DBrandHighlight));
        }
      }
    }
  }

  &--game {
    background-color: var(--ColorBackground2);
  }
}
</style>
