import type { Ref } from 'vue';
import {
  computed,
  onBeforeMount, toRef,
} from 'vue';
import { useRouter } from 'vue-router';

import type { PaymentCategory } from '@leon-hub/api-sdk';
import { PaymentType } from '@leon-hub/api-sdk';
import { RouteName } from '@leon-hub/routing-config-names';

import useDepositStore from 'web/src/modules/payments/submodules/deposits/store/useDepositStore';
import useWithdrawalStore from 'web/src/modules/payments/submodules/withdrawals/store/useWithdrawalStore';
import type { DropListSelectOption } from 'web/src/components/DropListSelect/types';
import type {
  PaymentsCryptoCurrencySelectProps,
  PaymentsCryptoCurrencySelectEmits,
} from 'web/src/modules/payments/components/PaymentsCryptoCurrencySelect/types';

export interface PaymentsCryptoCurrencySelectComposable {
  selectedCurrency: Ref<string | undefined>;
  currencyDropdownOptions: Ref<DropListSelectOption[]>;
  selectPaymentMethod: (id: string) => void;
}

export default function usePaymentsCryptoCurrencySelect(
  props: PaymentsCryptoCurrencySelectProps,
  emit: PaymentsCryptoCurrencySelectEmits,
): PaymentsCryptoCurrencySelectComposable {
  const router = useRouter();
  const depositStore = useDepositStore();
  const withdrawalStore = useWithdrawalStore();

  const depositCategories = toRef(depositStore, 'depositCategories');
  const withdrawalCategories = toRef(withdrawalStore, 'withdrawalCategories');

  const selectedCurrency = computed<string | undefined>(() => props.paymentSystem?.paymentSystemId ?? undefined);

  const list = computed<Maybe<PaymentCategory>>(() => {
    if (props.type === PaymentType.DEPOSIT) {
      return depositCategories.value.find((category) => category.isFolded) ?? null;
    }

    if (props.type === PaymentType.WITHDRAWAL) {
      return withdrawalCategories.value.find((category) => category.isFolded) ?? null;
    }

    return null;
  });

  const currencyDropdownOptions = computed<DropListSelectOption[]>(() => (
    (list.value?.paymentSystems?.filter((payment) => (!payment.disabled)).map<DropListSelectOption>((payment) => ({
      value: payment.id || '',
      label: payment.name || '',
      imageSrc: payment.logoSrc || undefined,
    })) ?? [])));

  const selectPaymentMethod = (id: string) => {
    const system = list.value?.paymentSystems.find((item) => item.id === id);

    if (system) {
      void router.replace({
        name: props.type === PaymentType.DEPOSIT ? RouteName.DEPOSITS_DETAILS : RouteName.WITHDRAWALS_DETAILS,
        params: {
          name: system.id,
          systemName: system.name,
        },
        query: {
          campaignId: router.getQuery('campaignId'),
          sum: router.getQuery('sum'),
        },
      });

      emit('on-select', system.id);
    }
  };

  onBeforeMount(async () => {
    // eslint-disable-next-line default-case
    switch (props.type) {
      case PaymentType.DEPOSIT:
        if (!depositCategories.value.length) {
          await depositStore.loadDepositSystems();
        }
        break;
      case PaymentType.WITHDRAWAL:
        if (!withdrawalCategories.value.length) {
          await withdrawalStore.loadWithdrawalSystems();
        }
        break;
    }
  });

  return {
    selectedCurrency,
    currencyDropdownOptions,
    selectPaymentMethod,
  };
}
