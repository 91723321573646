import type { FooterPartner } from 'web/src/modules/core/types';

export default function getTsupisLogos(): FooterPartner[] {
  if (process.env.VUE_APP_FEATURE_TSUPIS_DISABLED) {
    return [];
  }

  return [{
    id: '10',
    name: 'Tsupis Logo',
    width: 126,
    height: 24,
  },
  {
    id: '11',
    name: 'Tsupis Wallet',
    width: 94,
    height: 24,
  }];
}
