import { normalizeError } from '@leon-hub/errors';

import { ApiError } from '../../errors/ApiError';
import GqlApiError from './GqlApiError';

interface ConvertToGqlApiError {
  (error: unknown, message?: string): ApiError;
  setDefaultErrorMessage(message: string): void;
}

let defaultErrorMessage: string;
const convertToGqlApiError = Object.assign(
  ((error: unknown, message = defaultErrorMessage): ApiError => {
    if (error instanceof ApiError) {
      return error;
    }

    return new GqlApiError({
      message,
      originalError: normalizeError(error),
    });
  }),
  {
    setDefaultErrorMessage: (message: string) => {
      defaultErrorMessage = message;
    },
  },
) as ConvertToGqlApiError;

export default convertToGqlApiError;
