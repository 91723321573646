import type {
  CoreRegionResponse,
  CoreRegionReferenceResponse,
} from 'web/src/modules/sportline/types/rest';

import { isFullResponse } from './isResponseReference';

export function isFullCoreRegionResponse(
  response: CoreRegionResponse | CoreRegionReferenceResponse,
): response is CoreRegionResponse {
  return isFullResponse<CoreRegionResponse, CoreRegionReferenceResponse>(response);
}
