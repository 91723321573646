import {
  onComponentActivated,
  onComponentDeactivated,
} from '@leon-hub/vue-utils';

export function useWindowEvent<K extends keyof WindowEventMap>(
  event: K,
  callback: (this: Window, ev: WindowEventMap[K]) => void,
): void {
  let hasActiveListener = false;

  function addEventListener(): void {
    if (!hasActiveListener) {
      window.addEventListener(event, callback);
      hasActiveListener = true;
    }
  }

  function removeEventListener(): void {
    if (hasActiveListener) {
      window.removeEventListener(event, callback);
      hasActiveListener = false;
    }
  }

  onComponentActivated(addEventListener);
  onComponentDeactivated(removeEventListener);
}
