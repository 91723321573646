import { ref, computed, toRef } from 'vue';
import { defineStore } from 'pinia';

import type { FreeBet } from '@leon-hub/api-sdk';
import { SlipTypeId } from '@leon-hub/api-sdk';

import { useFormatMoney } from 'web/src/modules/money/composables';

import { useSlipSelectedEntriesStore } from '../../slipSelectedEntries/store';
import { useStakeInputStore } from '../../stake-input/store';
import type { FreeBetOption } from '../types';
import type { SafeSetStakeValuePayload } from '../../../types';

const useFreebetStore = defineStore('freebet-store', () => {
  const formatMoney = useFormatMoney();

  const slipSelectedEntriesStore = useSlipSelectedEntriesStore();

  const selectedEntriesIds = toRef(slipSelectedEntriesStore, 'selectedEntriesIds');

  const stakeInputStore = useStakeInputStore();

  const safeStakeValue = toRef(stakeInputStore, 'safeStakeValue');

  const { safeSetStakeValue, setValueFromFreebet } = stakeInputStore;

  const freeBets = ref<Record<string, FreeBet>>({});

  const lastStakeValue = ref<string>('0');

  const saveLastStakeValue = (): void => {
    lastStakeValue.value = `${safeStakeValue.value}`;
  };

  const updateFreeBets = (updatedFreeBets: FreeBet[] | null): void => {
    if (updatedFreeBets === null) {
      freeBets.value = {};
      return;
    }
    freeBets.value = {
      ...freeBets.value,
      ...updatedFreeBets.reduce<Record<string, FreeBet>>(
        (accumulator, item) => ({
          ...accumulator,
          [item.freeBetId]: item,
        }),
        {},
      ),
    };
  };

  const selectedFreeBetId = ref<string>('');

  const setSelectedFreeBetId = (id: string): void => {
    selectedFreeBetId.value = id;
  };

  const freeBetSwitchChecked = ref<boolean>(false);

  const setFreeBetSwitchChecked = (checked: boolean): void => {
    freeBetSwitchChecked.value = checked;
  };

  const freeBetOptions = computed<FreeBetOption[]>(() => Object.keys(freeBets.value).map((freeBetId) => {
    const relatedItem = freeBets.value[freeBetId];
    return {
      label: relatedItem.name,
      value: `${freeBetId}`,
      freeBetAmount: formatMoney(relatedItem.amount),
    };
  }));

  const currentFreeBet = computed<FreeBet | null>(() => {
    if (!selectedFreeBetId.value) {
      return null;
    }
    return freeBets.value[selectedFreeBetId.value] || null;
  });

  const showFreeBet = computed<boolean>(() => Object.keys(freeBets.value).length > 0);

  const selectedFreeBetAmount = computed<string>(() => {
    if (!selectedFreeBetId.value) {
      return lastStakeValue.value;
    }
    return `${freeBets.value[selectedFreeBetId.value].amount ?? 0}`;
  });

  const selectedFreeBetTermKey = computed<string>(() => {
    if (currentFreeBet.value) {
      return currentFreeBet.value.promoActionTermsPage;
    }
    if (Object.keys(freeBets.value).length === 1) {
      const key = Object.keys(freeBets.value)[0];
      return freeBets.value[key].promoActionTermsPage;
    }
    return '';
  });

  const disabledFreeBetSwitch = computed<boolean>(() => {
    const freeBetsArray = Object.values(freeBets.value);
    return Boolean(!freeBetSwitchChecked.value
        && (showFreeBet.value !== Boolean(freeBetsArray.length)
          || freeBetsArray.every((freeBet) => !freeBet.oddsValid)));
  });

  const setSelectedFreeBet = (id: string): void => {
    if (!selectedFreeBetId.value) {
      saveLastStakeValue();
    }
    setSelectedFreeBetId(id);
    setValueFromFreebet(selectedFreeBetAmount.value);
  };

  const clearFreeBet = (): void => {
    setFreeBetSwitchChecked(false);
    setSelectedFreeBetId('');
    updateFreeBets(null);
  };

  const checkIfNeedToCancelFreebet = (betMode: SlipTypeId, safeStakePayload: SafeSetStakeValuePayload): void => {
    if (freeBetSwitchChecked.value
      && (betMode === SlipTypeId.SYSTEM || (betMode === SlipTypeId.SINGLE && selectedEntriesIds.value.length > 1))) {
      setFreeBetSwitchChecked(false);
      setSelectedFreeBetId('');
      safeSetStakeValue(safeStakePayload);
    }
  };

  const onChangeFreeBetSwitch = (isChecked: boolean): void => {
    setFreeBetSwitchChecked(isChecked);
    if (isChecked && freeBetOptions.value.length === 1) {
      setSelectedFreeBet(freeBetOptions.value[0].value);
    }
    if (!isChecked) {
      setSelectedFreeBet('');
    }
  };

  return {
    selectedFreeBetId,
    freeBetSwitchChecked,
    freeBetOptions,
    currentFreeBet,
    showFreeBet,
    selectedFreeBetTermKey,
    disabledFreeBetSwitch,
    setSelectedFreeBet,
    clearFreeBet,
    checkIfNeedToCancelFreebet,
    onChangeFreeBetSwitch,
    setFreeBetSwitchChecked,
    updateFreeBets,
  };
});

export default useFreebetStore;
