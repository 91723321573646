import { IconName, IconSize } from '@leon-hub/icons';

import { CustomFilter } from 'web/src/modules/sportline/enums';
import type { SportsTreeSportElement } from 'web/src/modules/sportline/types/rest';
import { resolveSportsPageLink } from 'web/src/modules/sportline/submodules/navigation/store/utils';
import { extractZeroMarginTopLeagues, sortTopLeagueElementsASC } from 'web/src/modules/sportline/utils';

import type { FilterTab } from '../types';
import { prepareTopLeagueListElementsForFilterTab } from './prepareTopLeagueListElementsForFilterTab';

export function createFilterTabForZeroMargin(
  sportElements: SportsTreeSportElement[],
  isActive = false,
): Maybe<FilterTab> {
  const zeroMarginLeagues = extractZeroMarginTopLeagues(sportElements);
  const leagues = prepareTopLeagueListElementsForFilterTab(zeroMarginLeagues);

  if (!leagues.length) { return null; }

  return {
    key: CustomFilter.ZeroMargin,
    filterKey: CustomFilter.ZeroMargin,
    isActive,
    leagues: leagues.sort(sortTopLeagueElementsASC),
    totalCount: zeroMarginLeagues.length,
    allLeaguesLocation: resolveSportsPageLink({ urlName: CustomFilter.ZeroMargin }),
    tabItem: {
      id: CustomFilter.ZeroMargin,
      icon: {
        name: IconName.SPORTLINE_HIGHEST_ODDS,
        size: IconSize.SIZE_16,
      },
    },
  };
}
