import { getPixelRatio } from '@leon-hub/utils';

import { ComponentKey, ComponentStatus } from '../types';
import type { ComponentResult } from '../types';
import AbstractFingerprintComponent from '../AbstractFingerprintComponent';

export default class PixelRatioComponent extends AbstractFingerprintComponent {
  constructor() {
    super(ComponentKey.PixelRatio);
  }

  getComponentResult(): Promise<ComponentResult> {
    return this.result([getPixelRatio() ?? ComponentStatus.NotAvailable]);
  }
}
