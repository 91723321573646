import { ref } from 'vue';
import type { Ref } from 'vue';

import { assert } from '@leon-hub/guards';

import type { VInputInputEvent } from 'web/src/components/Input';

interface UseDropdownSearchInput {
  dropdownSearchInput: Ref<HTMLInputElement | undefined>;
  searchQuery: Ref<string>;
  handleSearchInput: (event: InputEvent | VInputInputEvent) => void;
  clearSearchQuery: () => void;
  focusOnDropdownInput: () => void;
}

export default function useDropdownSearchInput(): UseDropdownSearchInput {
  const dropdownSearchInput = ref<HTMLInputElement | undefined>();

  const searchQuery = ref<string>('');

  const clearSearchQuery = (): void => {
    searchQuery.value = '';
  };

  const handleSearchInput = (event: InputEvent | VInputInputEvent): void => {
    const { target } = event;
    assert(target && 'value' in target);
    searchQuery.value = target.value;
  };

  const focusOnDropdownInput = () => {
    dropdownSearchInput.value?.focus();
  };

  return {
    dropdownSearchInput,
    searchQuery,
    clearSearchQuery,
    handleSearchInput,
    focusOnDropdownInput,
  };
}
