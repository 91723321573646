import { EgsAvailabilityStatus } from '@leon-hub/api-sdk';

import type { I18nFunctionReactive } from 'web/src/modules/i18n/types';

export default function getSeatsText(
  status: EgsAvailabilityStatus | undefined,
  seats: string,
  $t: I18nFunctionReactive,
): string {
  switch (status) {
    case EgsAvailabilityStatus.NOT_AVAILABLE:
      return seats || $t('WEB2_EGS_GAME_NOT_AVAILABLE').value;
    case EgsAvailabilityStatus.BET_BEHIND:
      return $t('WEB2_EGS_GAME_BET_BEHIND').value;
    case EgsAvailabilityStatus.AVAILABLE:
      return seats;
    default:
      return '';
  }
}
