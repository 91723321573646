<script lang="ts" setup>
import { useSwiperNavigation } from 'web/src/components/Swiper/Navigation/composables';
import CasinoLoyaltyProgramSwiperNavigationButtons
  // eslint-disable-next-line max-len
  from 'web/src/modules/casino-loyalty-program/components/CasinoLoyaltyProgramSwiper/CasinoLoyaltyProgramSwiperNavigationButtons.vue';

const { swiperState, slideToPreviousSlide, slideToNextSlide } = useSwiperNavigation();
</script>

<template>
  <CasinoLoyaltyProgramSwiperNavigationButtons v-auto-id="'CasinoLoyaltyProgramSwiperNavigation'"
    :show-previous-button="swiperState?.isPreviousButtonAllowed.value"
    :show-next-button="swiperState?.isNextButtonAllowed.value"
    @click-previous="slideToPreviousSlide"
    @click-next="slideToNextSlide"
  />
</template>
