import type { Ref } from 'vue';
import { computed } from 'vue';

import { prepareSiteConfigHtml } from '@leon-hub/html-utils';

import HighLighter from 'web/src/utils/HighLighter';

import type { VListItemCountriesProps } from '../types';

export interface VListItemCountriesComposable {
  isSelected: Ref<boolean>;
  getHighlightedText(text: string): string;
}

export default function useVListItemCountries(props: VListItemCountriesProps): VListItemCountriesComposable {
  const isSelected = computed(() => props.code === props.selectedItemCode);
  function getHighlightedText(text: string): string {
    return HighLighter.highlight(
      prepareSiteConfigHtml(text),
      props.searchText || '',
      'highlighted-text',
    );
  }
  return {
    isSelected,
    getHighlightedText,
  };
}
