import type { Ref } from 'vue';
import { computed } from 'vue';
import { useRouter } from 'vue-router';

import type {
  RegionElement,
  SportElement,
  SportEventSeoMetaInfo,
} from 'web/src/modules/sportline/types';
import type {
  SegmentPageListGroup,
  SegmentSeoMetaInfo,
} from 'web/src/modules/sportline/submodules/segment/types';
import {
  extractSportEventSeoMetaInfo,
} from 'web/src/modules/sportline/utils';
import { createGetHref } from 'web/src/modules/core/utils';

interface SegmentPageSeoMetaProps {
  isReady: Ref<boolean>;
  activeSportElement: Ref<Maybe<SportElement>>;
  activeRegionElement: Ref<Maybe<RegionElement>>;
  groupsForDisplay: Ref<SegmentPageListGroup[]>;
  canShowLiveForGroup(group: SegmentPageListGroup): boolean;
  canShowPrematchForGroup(group: SegmentPageListGroup): boolean;
}

interface SegmentPageSeoMetaComposable {
  seoMetaInfo: Ref<SegmentSeoMetaInfo>;
}

export function useSegmentPageSeoMeta(
  props: SegmentPageSeoMetaProps,
): SegmentPageSeoMetaComposable {
  const router = useRouter();
  const getHref = createGetHref(router);

  const {
    isReady,
    activeSportElement,
    activeRegionElement,
    groupsForDisplay,
    canShowLiveForGroup,
    canShowPrematchForGroup,
  } = props;
  const seoMetaInfo = computed<SegmentSeoMetaInfo>(() => {
    if (!isReady.value) { return {}; }

    const sport = activeSportElement.value?.sport;
    const region = activeRegionElement.value?.region;

    return {
      sport: sport?.name || '',
      sportFamily: sport?.navigationParameters.urlName || '',
      sportEmoji: sport?.representation.emoji || '',
      region: region?.name || '',
      regionUrlName: region?.navigationParameters.urlName || '',
      regionFamily: region?.representation.icon || '',
      sportEvents: {
        value: ((groupsForDisplay.value))
          .flatMap((group) => [
            ...(canShowLiveForGroup(group)
              ? extractSportEventSeoMetaInfo(group.liveElement, {
                sport,
                vm: { $router: { getHref } },
              })
              : []),
            ...(canShowPrematchForGroup(group)
              ? extractSportEventSeoMetaInfo(group.prematchElement, {
                sport,
                vm: { $router: { getHref } },
              })
              : []),
          ])
          .filter((sportEventMeta) => !!sportEventMeta),
        merge(oldValue: SportEventSeoMetaInfo[], newValue: SportEventSeoMetaInfo[]) {
          return [...oldValue, ...newValue];
        },
      },
    };
  });

  return {
    seoMetaInfo,
  };
}
