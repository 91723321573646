import type { FastBetsSettings, OkResponse } from '@leon-hub/api-sdk';
import { doSaveFastBets } from '@leon-hub/api-sdk';

import type ApplicationGqlClient from 'web/src/modules/core/services/api/ApplicationGqlClient';

export default function requestFastBetValuesSave(api: ApplicationGqlClient, fastBetsSettings: FastBetsSettings)
  : Promise<Pick<OkResponse, 'result'>> {
  return doSaveFastBets(
    api,
    (node) => node.mutations.customer.saveFastBets,
    { options: fastBetsSettings.values },
  );
}
