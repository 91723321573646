<script lang="ts" setup>
import VAnimatedTrack from '../VAnimatedTrack/VAnimatedTrack.vue';

interface VLinearLoaderProps {
  hint?: string;
}

const props = withDefaults(defineProps<VLinearLoaderProps>(), {
  hint: '',
});
</script>

<template>
  <div v-auto-id="'VLinearLoader'"
    :class="{
      [$style['linear-loader']]: true,
      [$style['linear-loader--with-hint']]: !!props.hint,
    }"
  >
    <span
      v-if="hint"
      :class="$style['linear-loader__hint']"
    >{{ hint }}</span>
    <VAnimatedTrack />
  </div>
</template>

<style lang="scss" module>
.linear-loader {
  margin: 0 auto;
  color: $linearLoaderTextColor;

  &--with-hint {
    width: auto;
    height: auto;
    padding: 0;
  }

  &__hint {
    @include linearLoaderTextFont;

    display: block;
    margin-top: 0;
    margin-bottom: 13px;
    text-align: center;
  }
}
</style>
