import type { Ref } from 'vue';
import { computed } from 'vue';

import type { SportEventDetailsSlideId } from 'web/src/modules/sportline/submodules/event-details/types';
import { HeadlineSlideName } from 'web/src/modules/sportline/submodules/event-details/enums';

interface UseWidgetSwitcherProps {
  activeSlideId: Ref<Optional<HeadlineSlideName>>;
  slideIds: Ref<SportEventDetailsSlideId[]>;
  emitSetActiveSlide(value: HeadlineSlideName): void;
}

interface UseWidgetSwitcherComposable {
  isStreamAvailable: Ref<boolean>;
  isLiveWidgetAvailable: Ref<boolean>;
  setActiveSlide(value: HeadlineSlideName): void;
}

export function useWidgetSwitcher(
  props: UseWidgetSwitcherProps,
): UseWidgetSwitcherComposable {
  const { slideIds, activeSlideId, emitSetActiveSlide } = props;

  const isStreamAvailable = computed<boolean>(() => !!slideIds.value.find(({ id }) => id === HeadlineSlideName.LiveStream));
  const isLiveWidgetAvailable = computed<boolean>(() => !!slideIds.value.find(({ id }) => id === HeadlineSlideName.LiveWidget));

  function setActiveSlide(value: HeadlineSlideName): void {
    if (activeSlideId.value === value) {
      emitSetActiveSlide(HeadlineSlideName.Info);
      return;
    }

    emitSetActiveSlide(value);
  }

  return {
    isStreamAvailable,
    isLiveWidgetAvailable,
    setActiveSlide,
  };
}
