import type { Ref, WatchStopHandle } from 'vue';
import {
  computed,
  ref,
  watch,
  toRef,
} from 'vue';

import type { MaybeComputedElementRef } from '../refs';
import { tryOnMounted, unrefElement } from '../refs';
import { useControllableResizeObserver as useResizeObserver } from '../resize-observer';

export interface ElementSize {
  width: number;
  height: number;
}

export interface ElementSizeComposable {
  width: Ref<number>;
  height: Ref<number>;
  start(): void;
  stop(): void;
}

function getWindow(): Window | undefined {
  const isClient = typeof window !== 'undefined' && typeof document !== 'undefined';
  return isClient ? window : undefined;
}

function getBoxSize(entry: ResizeObserverEntry, box: string): readonly ResizeObserverSize[] {
  if (box === 'border-box') {
    return entry.borderBoxSize;
  }

  return box === 'content-box'
    ? entry.contentBoxSize
    : entry.devicePixelContentBoxSize;
}

/**
 * Reactive size of an HTML element.
 * @see https://vueuse.org/useElementSize
 * @see https://github.com/vueuse/vueuse/blob/main/packages/core/useElementSize/index.ts
 */
export function useElementSize(
  target: MaybeComputedElementRef,
  initialSize: ElementSize = { width: 0, height: 0 },
  options: ResizeObserverOptions = {},
): ElementSizeComposable {
  const { box = 'content-box' } = options;
  const isSVG = computed(() => unrefElement(target)?.namespaceURI?.includes('svg'));
  const width = ref(initialSize.width);
  const height = ref(initialSize.height);
  const targetRef = toRef(target);

  const { stop: stopResizeObserver, start: startResizeObserver } = useResizeObserver(
    targetRef,
    ([entry]) => {
      const boxSize = getBoxSize(entry, box);

      if (getWindow() && isSVG.value) {
        const $elem = unrefElement(target);
        if ($elem) {
          const rect = $elem.getBoundingClientRect();
          width.value = rect.width;
          height.value = rect.height;
        }
      } else if (boxSize) {
        const formatBoxSize = Array.isArray(boxSize) ? boxSize : [boxSize];
        width.value = formatBoxSize.reduce((acc, { inlineSize }) => acc + inlineSize, 0);
        height.value = formatBoxSize.reduce((acc, { blockSize }) => acc + blockSize, 0);
      } else {
        // fallback
        width.value = entry.contentRect.width;
        height.value = entry.contentRect.height;
      }
    },
    options,
  );

  let stopElementChangeWatch: WatchStopHandle | undefined;

  function startElementChangeWatch(): void {
    stopElementChangeWatch = stopElementChangeWatch ?? watch(
      () => unrefElement(target),
      (ele) => {
        width.value = ele ? initialSize.width : 0;
        height.value = ele ? initialSize.height : 0;
      },
    );
  }

  function stop(): void {
    stopResizeObserver();
    stopElementChangeWatch?.();
    stopElementChangeWatch = undefined;
  }

  function start(): void {
    startResizeObserver();
    startElementChangeWatch();
  }

  tryOnMounted(() => {
    const ele = unrefElement(target);

    if (ele) {
      width.value = 'offsetWidth' in ele ? ele.offsetWidth : initialSize.width;
      height.value = 'offsetHeight' in ele ? ele.offsetHeight : initialSize.height;
    }

    start();
  });

  return {
    width,
    height,
    start,
    stop,
  };
}
