import type { Ref } from 'vue';
import { computed } from 'vue';

import { IconSize } from '@leon-hub/icons';

import type { RegionsSegmentPageListGroup } from 'web/src/modules/sportline/submodules/segment/types';
import type { ChangeFilterPayload } from 'web/src/modules/sportline/submodules/segment/composables/types';
import type {
  SportlineFilterItem,
} from 'web/src/modules/sportline/components/navigation-filter/types';
import type {
  SportlineNavigationCommonIcon,
} from 'web/src/modules/sportline/components/navigation-indicator/types';
import { SportlineNavigationItemType } from 'web/src/modules/sportline/enums/navigation';
import { isCyberSportDiscipline } from 'web/src/modules/sportline/guards';
import { RegionFamilyToIconMapping, RegionFamilyToIconMappingDefault } from 'web/src/modules/sportline/constants';
import { useSportlineSegmentFilter } from 'web/src/modules/sportline/submodules/segment/components/Filter/composables';

import { resolveOptionLocation } from './utils';

interface UseSportlineCybersportFilterProps {
  totalCount: Ref<number>;
  allGroup: Ref<Maybe<RegionsSegmentPageListGroup>>;
  streamGroup: Ref<Maybe<RegionsSegmentPageListGroup>>;
  favoriteGroup: Ref<Maybe<RegionsSegmentPageListGroup>>;
  groups: Ref<RegionsSegmentPageListGroup[]>;
}

interface UseSportlineCybersportFilterEmit {
  changeFilter(payload: Maybe<ChangeFilterPayload<RegionsSegmentPageListGroup>>): void;
}

interface UseSportlineCybersportFilterComposable {
  items: Ref<SportlineFilterItem[]>;
}

export function useSportlineCybersportFilter(
  props: UseSportlineCybersportFilterProps,
  emit: UseSportlineCybersportFilterEmit,
): UseSportlineCybersportFilterComposable {
  const {
    totalCount,
    allGroup,
    streamGroup,
    favoriteGroup,
    groups,
  } = props;

  function emitChangeFilter(payload: Maybe<ChangeFilterPayload<RegionsSegmentPageListGroup>>): void {
    emit.changeFilter(payload);
  }

  const {
    allFilterItem,
    favoriteFilterItem,
    streamFilterItem,
  } = useSportlineSegmentFilter({
    totalCount,
    allGroup,
    streamGroup,
    favoriteGroup,
    emitChangeFilter,
    resolveOptionLocation,
  });

  const items = computed<SportlineFilterItem[]>(() => {
    const list: SportlineFilterItem[] = [];
    if (allFilterItem.value) { list.push(allFilterItem.value); }
    if (favoriteFilterItem.value) { list.push(favoriteFilterItem.value); }
    if (streamFilterItem.value) { list.push(streamFilterItem.value); }

    for (const group of groups.value) {
      const region = group.filterElement?.region;
      if (!region) { continue; }
      const to = resolveOptionLocation(group);
      const iconName = region.representation.icon;
      const icon: SportlineNavigationCommonIcon = {
        type: SportlineNavigationItemType.Icon,
        props: {
          name: isCyberSportDiscipline(iconName)
            ? RegionFamilyToIconMapping[iconName]
            : RegionFamilyToIconMappingDefault,
          size: IconSize.SIZE_16,
        },
      };

      const filterItem: SportlineFilterItem = {
        key: group.key,
        icon,
        title: region.name || '',
        isActive: group.isActive,
        to,
        onClick: () => emitChangeFilter({ group, to }),
        dataTest: region.name || undefined,
        transitionId: group.transitionId,
      };

      list.push(filterItem);
    }

    return list;
  });

  return { items };
}
