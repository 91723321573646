import type {
  LobbyItemUnit,
  LobbySportlineTopMatchItem,
} from 'web/src/modules/lobby/types';
import isLobbySportlineTopMatchItem from 'web/src/modules/lobby/utils/isLobbySportlineTopMatchItem';

export function getLobbyTopMatchesItems(lobbyItems: LobbyItemUnit[]): LobbySportlineTopMatchItem[] {
  return lobbyItems.filter((item) => (
    isLobbySportlineTopMatchItem(item)
  )) as LobbySportlineTopMatchItem[];
}
