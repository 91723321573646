import type { Ref } from 'vue';
import {
  onBeforeMount,
  ref,
  toRef,
  computed,
} from 'vue';

import type { JackpotBannerInfoProps } from 'web/src/modules/banners/components/JackpotBannerInfo/types';
import { getCurrencySymbol } from 'web/src/modules/money/utils';
import { useJackpotBannerStore } from 'web/src/modules/banners/store';
import { useComponentSyncState } from 'web/src/modules/core/composables';
import { EgsJackpotsSyncStateTimerName } from 'web/src/modules/egs/constants';

export interface UseJackpotBannerInfoComposable {
  amountMax: Ref<number>;
  amountMaxCurrency: Ref<string>;
  amountMajor: Ref<number>;
  amountMajorCurrency: Ref<string>;
  amountMinor: Ref<number>;
  amountMinorCurrency: Ref<string>;
}

export default function useJackpotBannerInfo(
  props: JackpotBannerInfoProps,
): UseJackpotBannerInfoComposable {
  const jackpotBannerStore = useJackpotBannerStore();

  const { getBannerJackpotsState } = jackpotBannerStore;

  const jackpots = toRef(() => jackpotBannerStore.jackpots);

  const amountMax = ref(0);
  const amountMaxCurrency = ref('');
  const amountMajor = ref(0);
  const amountMajorCurrency = ref('');
  const amountMinor = ref(0);
  const amountMinorCurrency = ref('');

  const currentJackpotBannerDetails = computed(
    () => jackpots.value?.find((item) => item.id === props.content?.id),
  );

  async function updateAmount() {
    if (props.content) {
      const { id, providerId } = props.content;

      await getBannerJackpotsState(id, providerId);

      const currentJackpots = currentJackpotBannerDetails.value?.jackpots ?? [];

      amountMax.value = currentJackpots[0]?.amount.value || 0;
      amountMajor.value = currentJackpots[1]?.amount.value || 0;
      amountMinor.value = currentJackpots[2]?.amount.value || 0;

      amountMaxCurrency.value = getCurrencySymbol(currentJackpots[0]?.amount.currency || '');
      amountMajorCurrency.value = getCurrencySymbol(currentJackpots[1]?.amount.currency || '');
      amountMinorCurrency.value = getCurrencySymbol(currentJackpots[2]?.amount.currency || '');
    }
  }

  onBeforeMount(async () => {
    await updateAmount();
  });

  useComponentSyncState(updateAmount, EgsJackpotsSyncStateTimerName);

  return {
    amountMax,
    amountMaxCurrency,
    amountMajor,
    amountMajorCurrency,
    amountMinor,
    amountMinorCurrency,
  };
}
