enum AccountHistoryRouteName {
  ACCOUNT_HISTORY_ALL = 'account-history-all',

  // EGS Routes
  ACCOUNT_HISTORY_EGS = 'account-history-egs',
  ACCOUNT_HISTORY_EGS_ROUND = 'account-history-egs-round',
  ACCOUNT_HISTORY_ROUND_DETAILS = 'account-history-round-details',

  // Marketing Routes
  ACCOUNT_HISTORY_MARKETING = 'account-history-marketing',
  ACCOUNT_HISTORY_MARKETING_DETAILS = 'account-history-marketing-details',

  // Payment Routes
  ACCOUNT_HISTORY_PAYMENT = 'account-history-payment',
  ACCOUNT_HISTORY_PAYMENT_DETAILS = 'account-history-payment-details',

  // Sportsbook Routes
  ACCOUNT_HISTORY_SPORTSBOOK = 'account-history-sportsbook',
  ACCOUNT_HISTORY_SPORTSBOOK_DETAILS = 'account-history-sportsbook-details',

  // Vsport Routes
  ACCOUNT_HISTORY_VSPORT = 'account-history-vsport',
  ACCOUNT_HISTORY_VSPORT_DETAILS = 'account-history-vsport-details',
}

export default AccountHistoryRouteName;
const accountHistoryRouteNames = Object.values(AccountHistoryRouteName);

export function isAccountHistoryRouteName(value: unknown): value is AccountHistoryRouteName {
  return accountHistoryRouteNames.includes(value as AccountHistoryRouteName);
}
