import { getCordovaAppConfig } from '@leon-hub/cordova';
import { isDebugEnabled } from '@leon-hub/debug';
import { EditionValueRenderingPrerender } from '@leon-hub/environment-editions';
import {
  getLocationHost,
  getLocationPath,
  getQueryParams,
  getReferer,
} from '@leon-hub/service-locator-env';
import { getPrerenderIp, QueryParameterName } from '@leon-hub/query-manager';

export const languageHeaderName = 'X-APP-LANGUAGE';
export const themeHeaderName = 'X-APP-THEME';

export default function getBaseHeaders(headers: Record<string, string> = {}): Partial<Record<string, string>> {
  const baseHeaders = {
    // Builtin headers
    'X-APP-PLATFORM': process.env.VUE_APP_PLATFORM,
    'X-APP-RENDERING': process.env.VUE_APP_RENDERING,
    'X-APP-MODERNITY': process.env.VUE_APP_MODERNITY,
    'X-APP-ENV': process.env.VUE_APP_ENV,
    'X-APP-SKIN': process.env.VUE_APP_SKIN,
    'X-APP-LAYOUT': process.env.VUE_APP_LAYOUT,
    'X-APP-OS': process.env.VUE_APP_OS,
    'X-APP-BROWSER': process.env.VUE_APP_BROWSER,
    'X-APP-VERSION': process.env.VUE_APP_VERSION,
    ...headers,
  };

  if (process.env.VUE_APP_PLATFORM_WEB) {
    Object.assign(baseHeaders, {
      'X-REQUESTED-URI': getLocationPath(),
    });

    if (process.env.VUE_APP_PRERENDER) {
      const prerenderHeaders = {
        'X-USER-HOST': getLocationHost(),
        'X-USER-IP': getPrerenderIp(),
        'X-APP-RENDERING': EditionValueRenderingPrerender,
      };

      Object.assign(baseHeaders, prerenderHeaders);
    }

    const runtimePlatform = getQueryParams()[QueryParameterName.PLATFORM];
    if (runtimePlatform) {
      Object.assign(baseHeaders, {
        'X-APP-PLATFORM': runtimePlatform,
      });
    }
  }

  if (process.env.VUE_APP_RENDERING_SSR) {
    const ssrHeaders = {
      'X-USER-HOST': getLocationHost(),
    };

    Object.assign(baseHeaders, ssrHeaders);
  }

  if (process.env.VUE_APP_PLATFORM_CORDOVA) {
    const { apkVersion, appBuildId, appStore } = getCordovaAppConfig();
    const cordovaHeaders = {
      'X-APP-APK-VERSION': apkVersion,
      'X-APP-BUILD-ID': appBuildId,
      'X-APP-STORE': appStore,
    };

    Object.assign(baseHeaders, cordovaHeaders);
  } else {
    Object.assign(baseHeaders, {
      'X-APP-REFERRER': getReferer(),
    });
  }

  if (isDebugEnabled()) {
    Object.assign(baseHeaders, {
      'X-APP-DEBUG': '1',
    });
  }

  return baseHeaders;
}
