<script lang="ts" setup>
import { computed, toRef } from 'vue';

import { LeaguePageSectionId } from 'web/src/modules/sportline/submodules/league/enums';

interface EmptyLeaguePageContentInfoProps {
  sectionId: LeaguePageSectionId;
  isLeagueStatisticEnabled?: boolean;
}

const props = defineProps<EmptyLeaguePageContentInfoProps>();
const sectionId = toRef(props, 'sectionId');
const isLeagueStatisticEnabled = toRef(props, 'isLeagueStatisticEnabled');

const isEmptyEventsList = computed(() => sectionId.value === LeaguePageSectionId.None && !isLeagueStatisticEnabled.value);
const isEmptyStatistics = computed(() => sectionId.value === LeaguePageSectionId.None && isLeagueStatisticEnabled.value);
</script>

<template>
  <div
    v-if="sectionId === LeaguePageSectionId.EventsList || isEmptyEventsList"
    :class="$style['empty-content__holder']"
  >
    <div :class="$style['empty-content']">
      <div :class="$style['empty-content__title']">
        {{ $t('JS_EVENTS_NOT_FOUND') }}
      </div>
    </div>
  </div>
  <div
    v-else-if="sectionId === LeaguePageSectionId.Statistic || isEmptyStatistics"
    :class="$style['empty-content__holder']"
  >
    <div :class="$style['empty-content']">
      <div :class="$style['empty-content__title']">
        {{ $t('WEB2_STATISTICS_NOT_FOUND_TITLE') }}
      </div>
      <div :class="$style['empty-content__description']">
        {{ $t('WEB2_STATISTICS_NOT_FOUND_DESCRIPTION') }}
      </div>
    </div>
  </div>
  <div
    v-else
    :class="$style['empty-content__holder']"
  />
</template>

<style module lang="scss">
.empty-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 32px;
  text-align: center;

  &__holder {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  &__title {
    @include medium\18\28;

    color: var(--TextDefault);
  }

  &__description {
    @include regular\14\24\025;

    color: var(--TextPrimary);
  }
}
</style>
