<script setup lang="ts">
import { computed } from 'vue';

import { IconName, IconSize } from '@leon-hub/icons';
import { ButtonKind } from '@leon-hub/module-buttons';

import { VIcon } from '@components/v-icon';

import type {
  ModalWindowHeaderPrefixAbstract,
} from 'web/src/components/Modal/ModalWindowHeader/components/ModalWindowHeaderPrefixAbstract/types';
import type { ModalWindowHeaderEmits } from 'web/src/components/Modal/ModalWindowHeader/types';
import LButton from 'web/src/components/Button/LButton/LButton.vue';

const props = defineProps<ModalWindowHeaderPrefixAbstract>();
const emit = defineEmits<ModalWindowHeaderEmits>();

const prefixIconNameOverride = computed(() => {
  if (null && props.prefixIconName === IconName.ARROW_LEFT) {
    return IconName.ANGLE_SMALL_LEFT;
  }
  if (null && props.prefixIconName === IconName.CROSS) {
    return IconName.SLOTT_CROSS;
  }
  return props.prefixIconName;
});
</script>

<template>
  <VIcon
    v-if="null && prefixIconName"
    v-data-test="{ el: 'modal-prefix-button' }"
    :name="prefixIconNameOverride"
    :size="IconSize.SIZE_32"
    @click="emit('prefix-click')"
  />
  <LButton
    v-else-if="prefixIconName"
    v-data-test="{ el: 'modal-prefix-button' }"
    :icon-size="IconSize.SIZE_24"
    :kind="ButtonKind.NAV"
    :icon-name="prefixIconName"
    class="modal-header__prefix-button"
    @click="emit('prefix-click')"
  />
</template>
