import type {
  NavigationGuardNext,
  RouteLocationNormalized,
} from 'vue-router';

import { RouteName } from '@leon-hub/routing-config-names';
import { CasinoRouteName } from '@leon-hub/routing-config-names/src/CasinoRouteName';
import {
  HomePageType,
} from '@leon-hub/api-sdk';

import { useSiteConfigStore } from 'web/src/modules/core/store';
import type { AppVueRouter } from 'web/src/modules/core/services/router/types';
import { AbstractPrefetch } from 'web/src/modules/core/services/router/prefetch';
import useEgsBasePrefetch from 'web/src/modules/egs/composables/useEgsBasePrefetch';

export default class CasinoLobbyPagePrefetch extends AbstractPrefetch {
  // eslint-disable-next-line class-methods-use-this
  private shouldRedirectToHome(route: RouteLocationNormalized): boolean {
    if (process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED) {
      return false;
    }

    const { homePageType } = useSiteConfigStore();

    return ((
      homePageType === HomePageType.LIVE_GAMES
        && route.name === CasinoRouteName.CASINO_LIVE_LOBBY
    ) || (
      homePageType === HomePageType.SLOTS
        && route.name === CasinoRouteName.CASINO_LOBBY
    ))
      && !route.params.groupUrl
      && !route.params.categoryUrl;
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  async prefetch(
    router: AppVueRouter,
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ): Promise<void> {
    if (this.shouldRedirectToHome(to)) {
      next(router.resolve301location({
        name: RouteName.HOME,
      }));
      return;
    }

    const input = await useEgsBasePrefetch().prefetch(to, next);

    if (!input) {
      return;
    }

    next();
  }
}
