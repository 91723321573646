<script lang="ts" setup>
import { ref, computed } from 'vue';

import { sleep } from '@leon-hub/utils';

import { useSwiperNavigationPoints } from './useSwiperNavigationPoints';
import type { SwiperNavigationPointsProps } from './types';

const props = withDefaults(defineProps<SwiperNavigationPointsProps>(), {
  type: 'default',
});

const {
  slidesCount,
  slideToSlide,
  isPointSelected,
} = useSwiperNavigationPoints(props);

const isSmoothSlideAvailable = ref(false);
const isSmoothSlideAvailableFinal = computed(() => !props.isSmoothSlideDisabled && isSmoothSlideAvailable.value);

async function onMounted(): Promise<void> {
  await sleep(100);
  isSmoothSlideAvailable.value = true;
}
</script>

<template>
  <div v-auto-id="'SwiperNavigationPoints'"
    v-if="!isSingleSlideHidden || slidesCount > 1"
    :class="{
      [$style['swiper-navigation-points']]: true,
      [$style[`swiper-navigation-points--${type}`]]: true,
    }"
    @vue:mounted="onMounted"
  >
    <div
      v-for="index in slidesCount"
      :key="index"
      :class="{
        [$style['swiper-navigation-points__item']]: true,
        [$style[`swiper-navigation-points__item--${type}`]]: true,
        [$style[`swiper-navigation-points__item--${type}-transition`]]: isSmoothSlideAvailableFinal,
        [$style[`swiper-navigation-points__item--${type}-selected`]]: isPointSelected(index),
      }"
      @click="slideToSlide(index - 1, { smooth: true })"
    />
  </div>
</template>

<style module lang="scss">
.swiper-navigation-points {
  display: flex;

  &--default {
    @include pointsNavigationDefault;
  }

  &--linear {
    @include pointsNavigationLinear;
  }

  &--landing {
    @include pointsNavigationLanding;
  }

  &__item {
    @include for-hover {
      &:hover {
        cursor: pointer;
      }
    }

    &--default {
      @include pointsNavigationDefaultItem;
    }

    &--default-transition {
      @include pointsNavigationDefaultItemTransition;
    }

    &--default-selected {
      @include pointsNavigationDefaultItemSelected;
    }

    &--linear {
      @include pointsNavigationLinearItem;

      @include for-hover {
        &:hover {
          cursor: auto;
        }
      }
    }

    &--linear-transition {
      @include pointsNavigationLinearItemTransition;
    }

    &--linear-selected {
      @include pointsNavigationLinearItemSelected;
    }

    &--landing {
      @include pointsNavigationLandingItem;
    }

    &--landing-transition {
      @include pointsNavigationLandingItemTransition;
    }

    &--landing-selected {
      @include pointsNavigationLandingSelectedItem;
    }
  }
}
</style>
