import { ref, toRef, watch } from 'vue';

import { assert } from '@leon-hub/guards';

import { useLexisNexisIntegrationStore } from '../store';
import { getLexisNexisSessionId as getSessionId } from '../api';
import {
  runApiProfile,
} from '../utils';
import type { UseLexisNexisIntegrationComposable } from '../types';

function useLexisNexisIntegrationMock(): UseLexisNexisIntegrationComposable {
  return {
    isEnabled: ref(false), whenProfiledDuringRuntime: async () => {}, runApiProfile() { }, getSessionId: async () => Promise.resolve(''),
  };
}

export const useLexisNexisIntegration = (): UseLexisNexisIntegrationComposable => {
  if (process.env.VUE_APP_FEATURE_LEXIS_NEXIS_INTEGRATION_DISABLED) {
    // eslint-disable-next-line no-console
    console.warn('Lexis-Nexis integration is disabled');
    return useLexisNexisIntegrationMock();
  }
  const store = useLexisNexisIntegrationStore();
  return {
    isEnabled: toRef(() => store.isEnabled),
    whenProfiledDuringRuntime: () => new Promise((resolve) => {
      const stop = watch(() => store.isProfiled, (isProfiled) => {
        if (isProfiled) {
          stop(); resolve();
        }
      });
    }),
    runApiProfile,
    async getSessionId(): Promise<string> {
      assert(store.customerLogin);
      return getSessionId({ login: store.customerLogin });
    },
  };
};
