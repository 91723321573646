export const enum SpecialCheckboxesDataTest {
  AcceptTermsElement = 'accept-terms-element',
  AcceptTermsExpandToggle = 'accept-terms-expand-toggle',
  AcceptTermsText = 'accept-terms-text',
  AcceptTermsCheckbox = 'accept-terms-checkbox',
  AcceptTermsLinkToAgreement = 'accept-terms-link-to-agreement',
  AcceptTermsLinkToExtraAgreement = 'accept-terms-link-to-extra-agreement',
  AcceptTermsLinkToOffer = 'accept-terms-link-to-offer',
  AcceptTermsLinkToExtraOffer = 'accept-terms-link-to-extra-offer',
}
