import type { Ref } from 'vue';
import { toRef, computed } from 'vue';

import { useSportlineSportsStore } from 'web/src/modules/sportline/submodules/sports/store';
import type { SportsTreeSportElement } from 'web/src/modules/sportline/types/rest';
import type { TopLeagueListElement } from 'web/src/modules/sportline/types';
import type { SportDateTimeFilter } from 'web/src/modules/sportline/submodules/navigation/store/types';
import type { UseSaveOpenRegionStateComposable } from 'web/src/modules/sportline/submodules/sports-list/store/composables';
import { SportSegmentId } from 'web/src/modules/sportline/enums';
import { sortTopLeagueElementsASC } from 'web/src/modules/sportline/utils';
import { extractTopLeagues } from 'web/src/modules/sportline/utils/core';

interface UseSportsSportTabProps {
  sportId: Ref<string>;
}

interface UseSportsSportTabComposable extends UseSaveOpenRegionStateComposable {
  sportElement: Ref<Maybe<SportsTreeSportElement>>;
  topLeagues: Ref<TopLeagueListElement[]>;
  isCyberSportPage: Ref<boolean>;
  hasElementsForCurrentFilter: Ref<boolean>;
  currentDateTimeFilter: Ref<SportDateTimeFilter>;
}

export function useSportsSportTab(
  props: UseSportsSportTabProps,
): UseSportsSportTabComposable {
  const { sportId } = props;

  const sportlineSportsStore = useSportlineSportsStore();
  const actualSportsList = toRef(sportlineSportsStore, 'actualSportsList');
  const currentDateTimeFilter = toRef(sportlineSportsStore, 'sportsListFilter');
  const { setOpenRegionState, isRegionOpen } = sportlineSportsStore;

  const sportElement = computed<Maybe<SportsTreeSportElement>>(() => (
    actualSportsList.value?.find((element) => (element.sport.id === sportId.value)) ?? null
  ));
  const topLeagues = computed<TopLeagueListElement[]>(() => (
    extractTopLeagues(sportElement.value).sort(sortTopLeagueElementsASC)
  ));
  const isCyberSportPage = computed<boolean>(() => (
    sportElement.value?.sport.segment.id === SportSegmentId.CyberSport
  ));
  const hasElementsForCurrentFilter = computed<boolean>(() => (
    !!sportElement.value?.regions?.length
  ));

  return {
    sportElement,
    topLeagues,
    isCyberSportPage,
    hasElementsForCurrentFilter,
    currentDateTimeFilter,
    setOpenRegionState,
    isRegionOpen,
  };
}
