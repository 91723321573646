import { assert } from '@leon-hub/guards';

interface Payload {
  event: InputEvent;
  currentValue: string;
  regexp: RegExp;
}

export default function handleRegexpLimitedInput({ event, currentValue, regexp }: Payload): string {
  const { target } = event;
  assert(target instanceof HTMLInputElement);
  const nextValue = target.value;
  if (regexp.test(nextValue)) {
    return nextValue;
  }
  target.value = currentValue;
  return currentValue;
}
