import type { EventData, ReachGoal } from '../../types';

let EVENTS_QUEUE: EventData[] = [];

const addEvent = (event: EventData): number => EVENTS_QUEUE.push(event);

const getEvents = (): EventData[] => [...EVENTS_QUEUE];

const clear = (): void => {
  EVENTS_QUEUE = [];
};

const run = (callback: ReachGoal): void => {
  for (const { eventName, parameters } of EVENTS_QUEUE) {
    callback(eventName, parameters);
  }
  clear();
};

export default {
  addEvent,
  getEvents,
  run,
  clear,
};
