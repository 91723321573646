<script lang="ts" setup>
import VHeadlineBanner from 'web/src/components/HeadlineBanner/VHeadlineBanner/VHeadlineBanner.vue';
import type {
  LobbyPromotionItemEmits,
  LobbyPromotionItemProps,
} from 'web/src/modules/lobby/components/LobbyPromotionItem/types';
import { useLobbyPromotionItem } from 'web/src/modules/lobby/components/LobbyPromotionItem/composables';
import LobbyPromotionTitlePanel from 'web/src/modules/lobby/components/LobbyPromotionTitlePanel/LobbyPromotionTitlePanel.vue';
import VSkeletonImage from 'web/src/components/Skeleton/VSkeletonImage/VSkeletonImage.vue';

const props = defineProps<LobbyPromotionItemProps>();
const emit = defineEmits<LobbyPromotionItemEmits>();

const {
  backgroundString,
} = useLobbyPromotionItem(props);

function onClick(event: MouseEvent): void {
  if (props.item) {
    emit('click', event);
  }
}
</script>

<template>
  <component v-auto-id="'LobbyPromotionItem'"
    :is="item?.url ? 'a' : 'div'"
    :href="item?.url"
    :class="$style['lobby-promotion-item']"
    @click.stop.prevent="onClick"
  >
    <template v-if="item">
      <VHeadlineBanner
        :class="$style['lobby-promotion-item__image-container']"
        :main-image="item.image"
        :background-image="item.bgImage || undefined"
        :background-color="item.bgColor || undefined"
        is-internal-link
        :url="item.url"
        is-div-container
        :aspect-ratio="1.49"
        fix-height-img
        no-min-height
      />
      <div
        :class="$style['lobby-promotion-item__title-block']"
      >
        <LobbyPromotionTitlePanel
          :title="item.headline || undefined"
          :description="item.secondaryText || undefined"
          :style="{ background: backgroundString }"
        />
      </div>
    </template>
    <template v-else>
      <VSkeletonImage />
    </template>
  </component>
</template>

<style lang="scss" module>
@include for-layout using($isDesktop) {
  .lobby-promotion-item {
    position: relative;
    display: block;
    height: 240px;
    overflow: hidden;
    isolation: isolate;
    border-radius: $lobbyPromotionItemRadius;

    @if $isDesktop {
      &:hover {
        .lobby-promotion-item__image-container {
          transform: scale(1.05);
        }
      }
    }

    &__image-container {
      height: 100%;
      transition: transform 0.5s ease;
    }

    &__title-block {
      @include z-index(absolute-item);

      position: absolute;
      bottom: 0;
      width: 100%;
      text-decoration: none;
    }
  }
}
</style>
