<script setup lang="ts">
import type { FocusableInputRef } from '@leon-hub/focus';
import { useAutofocus } from '@leon-hub/focus';

import InputButton from 'web/src/components/Input/components/InputButton/InputButton.vue';

import type {
  CurrencyInputProps,
  BaseInputEmits,
} from '../../types';
import { useNumberStringInput } from '../../composables';
import InputWrapper from '../InputWrapper';
import InputBorderWrapper from '../InputBorderWrapper';
import TextInputTypes from '../../enums/TextInputTypes';

const props = withDefaults(defineProps<CurrencyInputProps>(), {
  name: 'currency',
  maximumFractionDigits: 2,
});

const emits = defineEmits<BaseInputEmits>();

defineOptions({
  inheritAttrs: false,
});

const {
  uniqueId,
  isEmpty,
  isFocus,
  isHover,
  inputValue,
  showClearButton,
  onClear,
  onBlur,
  onFocus,
  onMouseOver,
  onMouseLeave,
  onChange,
  onInput,
  onPaste,
} = useNumberStringInput(props, emits);

const { focus, focusable } = useAutofocus(props);

defineExpose<FocusableInputRef>({
  focus,
});
</script>

<template>
  <InputWrapper v-auto-id="'CurrencyInput'"
    v-data-test="{
      el: 'currency-input',
      name: name,
    }"
    :class="{
      [$style['currency-input']]: true,
      [$style['currency-input--large']]: isLarge,
    }"
    :input-id="uniqueId"
    :label="label"
    :disabled="disabled"
    :is-focus="isFocus"
    :is-empty="isEmpty"
    :hint="hint"
    :hint-right="hintRight"
    :hint-link="hintLink"
    :hint-link-right="hintLinkRight"
    :error="error"
    :error-right="errorRight"
    :is-large="isLarge"
    :clickable-suffix="showClearButton"
  >
    <template #input>
      <InputBorderWrapper
        :is-focus="isFocus"
        :is-disabled="disabled"
        :is-hover="isHover"
        :has-error="!!(error || errorRight)"
      >
        <div
          :class="{
            [$style['currency-input__container']]: true,
            [$style['currency-input__container--error']]: !!(error || errorRight),
            [$style['currency-input__container--disabled']]: disabled,
            [$style['currency-input__container--icon-suffix']]: showClearButton,
          }"
          @click="focus()"
          @mouseover="onMouseOver"
          @mouseleave="onMouseLeave"
        >
          <template
            v-if="currency"
          >
            <span
              v-show="inputValue"
              :class="[
                $style['currency-input__currency'],
                $style['currency-input__currency--left'],
              ]"
            >{{ currency }}</span>
          </template>
          <div
            :class="{
              [$style['currency-input__content']]: true,
              [$style['currency-input__content--empty']]: !value,
            }"
          >
            <input
              :id="uniqueId"
              ref="focusable"
              :autofocus="process.env.VUE_APP_OS_IOS ? undefined : autofocus"
              :type="TextInputTypes.TEL"
              :disabled="disabled"
              :autocomplete="autocomplete"
              :name="name"
              :value="inputValue"
              :placeholder="placeholder"
              :class="$style['currency-input__input']"
              @input="onInput"
              @change="onChange"
              @focus="onFocus"
              @blur="onBlur"
              @paste="onPaste"
            >
            <div
              v-show="!!value"
              :class="$style['currency-input__width-holder']"
              aria-hidden="true"
            >
              {{ value }}
            </div>
          </div>
          <template
            v-if="currencyRight"
          >
            <span
              v-show="inputValue"
              :class="[
                $style['currency-input__currency'],
                $style['currency-input__currency--right'],
              ]"
            >{{ currencyRight }}</span>
          </template>
        </div>
      </InputBorderWrapper>
    </template>
    <template #iconSuffix>
      <InputButton
        v-show="showClearButton"
        v-data-test="{ el: 'clear-icon-input-button', visible: !showClearButton }"
        :title="$t('WEB2_CLEAR')"
        :is-large="isLarge"
        @click="onClear"
      />
    </template>
  </InputWrapper>
</template>

<style module lang="scss">
.currency-input {
  @import '../../common';

  $self: &;

  &__currency {
    pointer-events: none;

    &--left {
      margin-right: 5px;
    }

    &--right {
      margin-left: 5px;
    }
  }

  &__container {
    @include inputCommon;

    position: relative;
    display: flex;
    align-items: center;
    overflow: hidden;
    cursor: text;

    &--error {
      color: $inputValueErrorColor;

      #{$self}__placeholder {
        color: $inputPlaceholderErrorColor;
      }
    }

    &--disabled {
      color: $inputValueDisabledColor;
      cursor: default;
    }

    &--icon-suffix {
      padding-right: $inputIconWidth;
    }
  }

  &--large &__container {
    @include inputLarge;
  }

  &--large &__container--icon-suffix {
    padding-right: $inputIconWidthLarge;
  }

  &__content {
    @include currencyInputMinHeight;

    position: relative;
    display: inline-flex;
    flex: 0 1 auto;
    overflow: hidden;

    &--empty {
      flex: 1;
    }
  }

  &__input,
  &__width-holder {
    @include currencyInputWithHolder;

    padding: 0;
    background-color: transparent;
  }

  &__input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 100%;
    color: $inputValueDefaultColor;
    border: none;

    &::placeholder {
      color: $inputPlaceholderDefaultColor;
    }
  }

  &__width-holder {
    position: relative;
    top: -100%;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    white-space: nowrap;
  }
}
</style>
