import type { RemoteApiErrorException } from '@leon-hub/api-sdk';

import GqlApiBatchedSubRequestError from './GqlApiBatchedSubRequestError';

export default class GqlApiCaptchaRequiredError extends GqlApiBatchedSubRequestError {
  extensions: RemoteApiErrorException;

  constructor(options: { extensions: RemoteApiErrorException }) {
    const { extensions } = options;

    super({
      ...options,
    });

    this.extensions = extensions;
  }
}
