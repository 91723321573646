import type {
  Ref,
  ComputedRef,
} from 'vue';
import { computed } from 'vue';

import { IconName, IconSize } from '@leon-hub/icons';

import type { VIconProps } from '@components/v-icon';

import { getStylesProperties } from 'web/src/components/HowToHintBlock/VHowToHintBlock/composables/utils';

import type { VHowToHintBlockProps } from '../types';

export interface VHowToHintBlockComposable {
  iconStepOneProps: Ref<VIconProps> | {};
  iconStepTwoProps: Ref<VIconProps> | {};
  iconArrowStepProps: Ref<VIconProps> | {};
  styleProperties: ComputedRef<Dictionary<string>>;
}

export default function useVHowToHintBlock(props: VHowToHintBlockProps): VHowToHintBlockComposable {
  const iconStepOneProps: VIconProps = {
    name: IconName.MONEY_OFF,
    size: IconSize.SIZE_16,
  };

  const iconStepTwoProps: VIconProps = {
    name: IconName.MONEY_ON,
    size: IconSize.SIZE_16,
  };

  const iconArrowStepProps: VIconProps = {
    name: IconName.ARROW_DOWNWARD,
    size: IconSize.SIZE_16,
  };

  const styleProperties = computed(() => getStylesProperties(props));

  return {
    iconStepOneProps,
    iconStepTwoProps,
    iconArrowStepProps,
    styleProperties,
  };
}
