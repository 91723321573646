import checkPrefix from './checkPrefix';
import checkTrailingSlash from './checkTrailingSlash';
import type { LanguagePrefixNavigationGuardOptions } from './types';

export default function checkLanguagePrefix(options: LanguagePrefixNavigationGuardOptions): string {
  let redirectUri = '';

  if (process.env.VUE_APP_PLATFORM_WEB) {
    if (process.env.VUE_APP_FEATURE_CUSTOM_BASE_URL_ENABLED) {
      redirectUri = checkPrefix(redirectUri, options);
    }

    redirectUri = checkTrailingSlash(redirectUri, options.currentUrlPrefix);
  }

  return redirectUri;
}
