import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import type { VReCaptchaBadgeProps } from 'web/src/modules/captcha/components/VReCaptchaBadge/types';
import { useI18n } from 'web/src/modules/i18n/composables';

interface useVReCaptchaBadgeComposable {
  termsLink: ComputedRef<string | undefined>;
  privacyLink: ComputedRef<string | undefined>;
}

export default function useVReCaptchaBadge(
  props: VReCaptchaBadgeProps,
): useVReCaptchaBadgeComposable {
  useI18n();
  const termsLink = computed(() => props.termsLinkTemplate?.replace('{lang}', props.lang));
  const privacyLink = computed(() => props.privacyLinkTemplate?.replace('{lang}', props.lang));

  return {
    termsLink,
    privacyLink,
  };
}
