/* eslint-disable no-underscore-dangle,no-param-reassign */
import type { Plugin } from 'vue';

import useCopyToClipboard from 'web/src/modules/dialogs/composables/useCopyToClipboard';
import type { Tooltip } from 'web/src/modules/dialogs/store/types';

declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    $copyToClipboard: (tooltipModal: Tooltip) => Promise<void>;
  }
}

const CopyToClipboardPlugin: Plugin = {
  install(app): void {
    const { copy } = useCopyToClipboard();

    // eslint-disable-next-line max-len,@typescript-eslint/no-misused-promises
    app.config.globalProperties.$copyToClipboard = copy;
  },
};

export default CopyToClipboardPlugin;
