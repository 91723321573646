import type { Sport, SportSegment } from 'web/src/modules/sportline/types';
import type {
  CoreSportResponse,
  BuildSportOptions,
  ParseSportResponseOptions,
} from 'web/src/modules/sportline/types/rest';
import type { SportRepresentationSettings } from 'web/src/modules/sportline/types/settings';
import {
  buildSportNavigationParameters,
} from 'web/src/modules/sportline/utils/rest/navigation-parameters';
import {
  sportSegments,
  SportBackgroundColorDark,
  SportBackgroundColorLight,
} from 'web/src/modules/sportline/constants';
import {
  getSportFamilyEmoji,
  isSportFamilyEquals,
} from 'web/src/modules/sportline/utils';

import { findSportRepresentationSetting } from '../pre-build';

function getIsLiveTimerEnabled(
  sportRepresentationSettings?: Maybe<SportRepresentationSettings>,
  sport?: Maybe<CoreSportResponse>,
): boolean {
  if (!sportRepresentationSettings || !sport) { return false; }
  const item = findSportRepresentationSetting(sportRepresentationSettings, sport);
  return !!item?.useFullProgress;
}

export default function createSport(options: ParseSportResponseOptions & Required<BuildSportOptions>): Sport {
  const {
    sport,
    sportFamilyMapping,
    sportRepresentationSettings,
  } = options;

  const id = String(sport.id);
  const { name, background } = sport;
  const family = sport.family.toLowerCase();
  const navigationParameters = buildSportNavigationParameters({
    sportResponse: sport,
    sportFamilyMapping,
  });
  const weight = sport.weight || 0;

  const segmentKey = Object.keys(options.sportSegmentMapping ?? {})
    .find((key: string | number) => isSportFamilyEquals(String(key), family));
  const segment: SportSegment = segmentKey
    ? (options.sportSegmentMapping?.[segmentKey] ?? sportSegments.default)
    : sportSegments.default;

  return {
    id,
    name,
    family: navigationParameters.urlName,
    segment,
    representation: {
      icon: family,
      family,
      emoji: getSportFamilyEmoji(family),
      useFullProgress: getIsLiveTimerEnabled(sportRepresentationSettings, sport),
      weight,
      background: {
        dark: SportBackgroundColorDark[family],
        light: SportBackgroundColorLight[family],
      },
      backgroundImage: background,
    },
    navigationParameters,
  };
}
