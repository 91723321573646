<script lang="ts" setup>
import PromotionCashbackWidget from 'web/src/modules/promotions/components/PromotionCashbackWidget/PromotionsCashbackWidget.vue';

import { useCasinoGameCashbackContainer } from './composables';

const {
  isVisible,
  cashbackAmount,
  timestamp,
  onBalanceClick,
  hideWidget,
} = useCasinoGameCashbackContainer();
</script>

<template>
  <PromotionCashbackWidget v-auto-id="'CasinoGameCashbackContainer'"
    v-if="isVisible"
    :cashback-amount="cashbackAmount"
    :timestamp="timestamp"
    @click="onBalanceClick"
    @send="hideWidget"
  />
</template>
