import type { Ref } from 'vue';
import { ref } from 'vue';

import type { ComponentIntersectiveInstance } from '@leon-hub/vue-utils';
import type { ScrollToElementOptions } from '@leon-hub/scrollbar-types';

import type {
  VScrollbarRef,
} from '../../types';

export interface VScrollbarComposable {
  element: Ref<VScrollbarRef | undefined>;
  scrollTop(): void;
  scrollDown(): void;
  scrollTo(top: number): void;
  scrollRight(): void;
  scrollToElement(el: HTMLElement | ComponentIntersectiveInstance | null, options?: ScrollToElementOptions): void;
  scrollUpdate(): void;
  recompose(): Promise<void>;
}

export default function useVScrollbar(): VScrollbarComposable {
  const element = ref<VScrollbarRef>();
  function scrollTop(): void {
    element.value?.scrollTop();
  }

  function scrollDown(): void {
    element.value?.scrollDown();
  }

  function scrollTo(top: number): void {
    element.value?.scrollTo(top);
  }

  function scrollRight(): void {
    element.value?.scrollRight();
  }

  async function recompose(): Promise<void> {
    await element.value?.recompose();
  }

  function scrollToElement(target: HTMLElement | ComponentIntersectiveInstance | null, options?: ScrollToElementOptions): void {
    if (target) {
      const targetElement = target instanceof HTMLElement ? target : target.$el;
      if (targetElement) {
        element.value?.scrollToElement(targetElement, options);
      }
    }
  }

  function scrollUpdate(): void {
    if (process.env.VUE_APP_LAYOUT_DESKTOP && !process.env.VUE_APP_OS_IOS && !process.env.VUE_APP_OS_ANDROID) {
      element.value?.scrollUpdate?.();
    }
  }
  return {
    element,
    scrollTop,
    scrollDown,
    scrollTo,
    scrollRight,
    scrollToElement,
    scrollUpdate,
    recompose,
  };
}
