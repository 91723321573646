import type { SportMarketGroup, MarketSpecifier } from 'web/src/modules/sportline/types';

import type { MarketsGridFilter, MarketsGridFilterId, MarketsGridCellId } from '../types';

export function getFilterIdForMarketGroup(group: { id: MarketsGridCellId }, specifier?: MarketSpecifier): MarketsGridFilterId {
  return `${group.id}|${specifier?.id ?? ''}=${specifier?.value ?? ''}`;
}

export function getFilterForMarketGroup(group: SportMarketGroup, specifier?: MarketSpecifier): MarketsGridFilter {
  const {
    name,
    style,
    marketTypeIds,
  } = group;

  const result: MarketsGridFilter = {
    id: getFilterIdForMarketGroup(group, specifier),
    name,
    marketTypeIds,
  };

  if (style) {
    result.style = style;
  }

  if (specifier) {
    result.specifier = specifier;
    result.name = name.replace(new RegExp(`{${specifier.id}}`, 'g'), String(specifier.value ?? ''));
  }

  return result;
}
