import { RequirementType } from '@leon-hub/api-sdk';

import type { CustomerBonusType } from 'web/src/modules/profile/submodules/bonuses/types';

const isComplexBonus = (bonus: CustomerBonusType): boolean => {
  const { requirements } = bonus;

  return !!requirements.find(({ type }) => type === RequirementType.COMPLEX);
};

export default isComplexBonus;
