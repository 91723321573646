import { defineStore } from 'pinia';
import {
  computed,
  ref,
} from 'vue';

import type { FormSchema } from '@leon-hub/form-utils';
import type {
  IValidationSchema,
} from '@leon-hub/api-sdk';
import {
  getFormSchema,
  validationSchemaChangePassword,
  validationSchemaCustomerLimits,
  validationSchemaRestorePasswordByEmail,
  validationSchemaRestorePasswordByPhone,
  validationSchemaRestorePasswordSendLinkToEmail,
  validationSchemaRestorePasswordSendSmsToPhone,
} from '@leon-hub/api-sdk';

import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';

const useFormsStore = defineStore('forms', () => {
  const api = useGraphqlClient();

  const schemas = ref<Record<string, FormSchema>>({});

  // eslint-disable-next-line max-len
  const restoreBySendSms = computed<FormSchema | undefined>(() => schemas.value[validationSchemaRestorePasswordSendSmsToPhone]);
  // eslint-disable-next-line max-len
  const restoreBySendLinkToEmail = computed<FormSchema | undefined>(() => schemas.value[validationSchemaRestorePasswordSendLinkToEmail]);
  // eslint-disable-next-line max-len
  const restoreByEmail = computed<FormSchema | undefined>(() => schemas.value[validationSchemaRestorePasswordByEmail]);
  // eslint-disable-next-line max-len
  const restoreByPhone = computed<FormSchema | undefined>(() => schemas.value[validationSchemaRestorePasswordByPhone]);
  // eslint-disable-next-line max-len
  const customerLimitsSchema = computed<FormSchema | undefined>(() => schemas.value[validationSchemaCustomerLimits]);
  // eslint-disable-next-line max-len
  const changePasswordSchema = computed<FormSchema | undefined>(() => schemas.value[validationSchemaChangePassword]);

  async function fetchSchema(key: IValidationSchema): Promise<void> {
    const result = await getFormSchema(api,
      (node) => node.queries.system.schemaContent,
      { options: { schema: key } });

    if (result) {
      schemas.value = {
        ...schemas.value,
        [key]: JSON.parse(result.content),
      };
    }
  }

  return {
    restoreBySendSms,
    restoreBySendLinkToEmail,
    restoreByEmail,
    restoreByPhone,
    customerLimitsSchema,
    changePasswordSchema,
    fetchSchema,
  };
});

export default useFormsStore;
