import { useThemeStore } from 'web/src/modules/theme/store';
import { useCordovaAppStore } from 'web/src/modules/cordova/store';
import { useCordovaIntercomStore } from 'web/src/modules/intercom/store/useCordovaIntercomStore';

export default function initStores(): void {
  useThemeStore();
  if (process.env.VUE_APP_PLATFORM_CORDOVA) {
    useCordovaAppStore();
    useCordovaIntercomStore();
  }
}
