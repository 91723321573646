import type { Ref } from 'vue';
import { toRef } from 'vue';

import type { ITheme } from '@leon-hub/api-sdk';

import { useThemeStore } from '../store';

export interface UseThemeComposable {
  theme: Ref<ITheme>;
  isDark: Ref<boolean>;
  isLight: Ref<boolean>;
  isAuto: Ref<boolean>;
}

export default function useTheme(): UseThemeComposable {
  const themeStore = useThemeStore();
  const isLight = toRef(themeStore, 'isLight');
  const isDark = toRef(themeStore, 'isDark');
  const isAuto = toRef(themeStore, 'isAuto');
  const theme = toRef(themeStore, 'theme');

  return {
    isLight,
    isDark,
    theme,
    isAuto,
  };
}
