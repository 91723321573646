/* eslint-disable class-methods-use-this */
import { RouteName } from '@leon-hub/routing-config-names';
import { SportFilterKickoffPeriod } from '@leon-hub/api-sdk';

import { useSportlineLayoutSettings } from 'web/src/modules/sportline/composables/settings';
import type { AppVueRouter } from 'web/src/modules/core/services/router/types';
import { AppModule } from 'web/src/modules/core/plugins/AppModule';
import {
  getSportlineNavigationLoaderRouteComponent,
  getSportlineNavigationRouteComponent,
} from 'web/src/modules/sportline/submodules/navigation/SportlineNavigationSubmodule';
import {
  getSportlineNavigationLeftSidebarRouteComponent,
} from 'web/src/modules/sportline/submodules/sidebar/SportlineSidebarSubmodule';

export class SportlineSportsModule extends AppModule {
  addRoutes(router: AppVueRouter): void {
    const { isBetSwipeSportsLayoutEnabled } = useSportlineLayoutSettings();

    const navigation = getSportlineNavigationRouteComponent();
    const leftSideBar = getSportlineNavigationLeftSidebarRouteComponent();
    const contentLoader = getSportlineNavigationLoaderRouteComponent();

    const sportsPrefetch = () => import('./prefetch/SportsPagePrefetch');
    const sportsRoutePage = process.env.VUE_APP_FEATURE_SPORTLINE_BET_SWIPE_LAYOUT_ENABLED && isBetSwipeSportsLayoutEnabled.value
      ? () => import('./views/BetSwipeSportsRoutePage.vue')
      : () => import('./views/SportsRoutePage.vue');

    router.addDefaultRoute({
      name: RouteName.SPORTLINE_PREMATCH_EVENTS,
      path: '/bets/:family?',
      prefetch: sportsPrefetch,
      component: sportsRoutePage,
      topAuthorizedSlot: () => import('web/src/modules/referral-program/views/ReferralProgramPopup/ReferralProgramPopup.vue'),
      navigation,
      leftSideBar,
      contentLoader,
    });

    router.addDefaultRoute({
      name: RouteName.SPORTLINE_PREMATCH_TODAY,
      path: '/today/:family?',
      prefetch: sportsPrefetch,
      component: sportsRoutePage,
      navigation,
      leftSideBar,
      contentLoader,
      props: { dateFilter: SportFilterKickoffPeriod.TODAY },
      meta: { dateFilter: SportFilterKickoffPeriod.TODAY },
    });
  }
}
