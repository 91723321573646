import type { RouteLocationRaw } from 'vue-router';

import { isString } from '@leon-hub/guards';

import type { SportListFilterOption } from 'web/src/modules/sportline/types/list';
import { resolveSportsPageLink } from 'web/src/modules/sportline/submodules/navigation/store/utils';

export function resolveOptionLocation(option?: SportListFilterOption | string): RouteLocationRaw {
  return resolveSportsPageLink({
    urlName: (!option || isString(option))
      ? option
      : option.sport.navigationParameters.urlName,
  });
}
