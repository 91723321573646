<script lang="ts" setup>
import StatisticLeagueStandingBlock
  from 'web/src/modules/sportline/submodules/core-statistic/components/StatisticLeagueStandingBlock.vue';
import StatisticDisclaimer from 'web/src/modules/sportline/submodules/core-statistic/components/StatisticDisclaimer.vue';

import PostMatchStatisticHeadline from './post-match-statistic/PostMatchStatisticHeadline.vue';
import PostMatchStatisticResults from '../components/post-match-statistic/PostMatchStatisticResults.vue';
import TeamsConfrontationStatistic from '../components/statistic/TeamsConfrontationStatistic.vue';
import H2hMatchStatisticBlock from '../components/statistic/H2hMatchStatisticBlock.vue';
import LastGamesResultsMatchStatisticBlock from '../components/statistic/LastGamesResultsMatchStatisticBlock.vue';
import useSportlineEventPostMatchStatistic from './useSportlineEventPostMatchStatistic';

interface SportlineEventPostMatchStatisticProps {
  isDisplayHeadline?: boolean;
}

defineProps<SportlineEventPostMatchStatisticProps>();

const {
  postMatchStatistic,
  confrontationBlocks,
  statisticSportFamily,
  leagueStandings,
  teams,
  h2hMatchStatistic,
  joinMatches,
  hostMatches,
  guestMatches,
  canDisplayH2hStatistic,
  canDisplayLastGamesResultsStatistic,
  canDisplayTournamentTableStatistic,
  canShowConfrontationStatistic,
  canShowRightDisclaimer,
  canShowLeftDisclaimer,
} = useSportlineEventPostMatchStatistic();
</script>

<template>
  <div v-auto-id="'SportlineEventPostMatchStatistic'" :class="$style['post-match-statistic']">
    <PostMatchStatisticHeadline
      v-if="isDisplayHeadline && postMatchStatistic"
      :class="$style['post-match-statistic__headline']"
      :post-match-statistic="postMatchStatistic"
    />

    <div :class="$style['post-match-statistic-heading']">
      <div :class="$style['post-match-statistic-heading__left']">
        <div :class="$style['post-match-statistic-heading__title']">
          <span :class="$style['post-match-statistic-heading__label']">{{ $t('WEB2_MATCH_STATISTIC') }}</span>
        </div>
      </div>
    </div>

    <PostMatchStatisticResults
      v-if="postMatchStatistic"
      :post-match-statistic="postMatchStatistic"
    />

    <div :class="$style['post-match-statistic-columns']">
      <div :class="$style['post-match-statistic-columns__left']">
        <TeamsConfrontationStatistic
          v-if="canShowConfrontationStatistic"
          :confrontation-blocks="confrontationBlocks"
          :teams="teams"
          :sport-family="statisticSportFamily"
        />

        <StatisticDisclaimer
          v-if="canShowLeftDisclaimer"
        />
      </div>

      <div :class="$style['post-match-statistic-columns__right']">
        <H2hMatchStatisticBlock
          v-if="canDisplayH2hStatistic"
          :teams="teams"
          :join-matches="joinMatches"
          :h2h-match-statistic="h2hMatchStatistic"
          :sport-family="statisticSportFamily"
        />

        <LastGamesResultsMatchStatisticBlock
          v-if="canDisplayLastGamesResultsStatistic"
          :teams="teams"
          :host-matches="hostMatches"
          :guest-matches="guestMatches"
          :sport-family="statisticSportFamily"
        />

        <StatisticLeagueStandingBlock
          v-if="canDisplayTournamentTableStatistic"
          :class="$style['post-match-statistic__league-standings']"
          :teams="teams"
          :league-standings="leagueStandings"
        />

        <StatisticDisclaimer
          v-if="canShowRightDisclaimer"
        />
      </div>
    </div>
  </div>
</template>

<style module lang="scss">
@import '~web/src/modules/sportline/styles/list-primary-headline';
@include list-primary-headline('.post-match-statistic-heading');

@include for-layout using ($isDesktop) {
  .post-match-statistic {
    padding: if($isDesktop, 0, 0 8px);

    &__headline {
      margin-bottom: if($isDesktop, 8px, 0);
    }

    &__league-standings {
      margin: 8px 0 0;
    }
  }

  .post-match-statistic-columns {
    display: flex;
    flex-direction: column;
    gap: 8px;

    @include for-desktop-up {
      flex-direction: row;

      &__right,
      &__left {
        flex-basis: 50%;
      }
    }
  }
}
</style>
