import type { Ref } from 'vue';
import {
  computed, onMounted, ref, shallowRef,
} from 'vue';

import type {
  AllowedAmount,
  BankCard,
  HttpMethod,
  PaymentActionButton,
  RawEntryInput,
  StringEntry,
  SubmitPaymentResponse,
  SuggestedAmount,
} from '@leon-hub/api-sdk';
import { OpenIn, PaymentType } from '@leon-hub/api-sdk';
import { assert } from '@leon-hub/guards';
import type { FormSchema } from '@leon-hub/form-utils';
import { isFormSchema, FormControlType, FormFieldTouchedStrategy } from '@leon-hub/form-utils';
import { IconName, IconSize } from '@leon-hub/icons';
import { localStorageManager } from '@leon-hub/local-storage';
import type { AppError } from '@leon-hub/app-errors';

import type {
  CurrentPaymentSystem,
  CurrentPaymentSystemData,
  PaymentsProcedureStatus,
  PaymentsSubmitPayload,
  UiFormSchemaDocument,
} from 'web/src/modules/payments/types/documentTypes';
import { useIsAppInstalled } from 'web/src/modules/payments/submodules/deposits/composables/useIsAppInstalled';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useCurrency, useFormatMoney } from 'web/src/modules/money/composables';
import type { ConfirmDialog } from 'web/src/modules/dialogs/types';
import { DialogAction } from 'web/src/modules/dialogs/enums';
import { JsonSchemaAdapter, getUiFieldRawOptions } from 'web/src/utils/jsonSchemaAdapter';
import isValueInRange from 'web/src/modules/payments/utils/isValueInRange';
import { useBalance } from 'web/src/modules/customer/store/composables';
import getSubmitPaymentPayload from 'web/src/modules/payments/utils/getSubmitPaymentPayload';
import type { PaymentsCardTypes } from 'web/src/modules/payments/enums';
import {
  PaymentFormFieldName,
  PaymentsCardField,
  PaymentsFormStep,
  PaymentsNDFLType,
  PaymentsStatus,
  PaymentsSubmitAction,
} from 'web/src/modules/payments/enums';
import { isPaymentsFormStep } from 'web/src/modules/payments/guards/isPaymentsFormStep';
import { isPaymentsCardTypes } from 'web/src/modules/payments/guards/isPaymentsCardTypes';
import type {
  FormExternalErrors,
  FormUiSchemaSubmitButton,
  FormValidatorErrorPattern,
} from 'web/src/components/Form/types';
import { PAYMENTS_UNDEFINED_CREDIT_CARD } from 'web/src/modules/payments/constants';
import usePaymentError from 'web/src/modules/payments/composables/usePaymentError';
import { useCardExpirationError } from 'web/src/modules/payments/composables/useCardExpirationError';
import type CustomerFormError from 'web/src/utils/forms/CustomerFormError';
import { usePaymentsConfig } from 'web/src/modules/core/composables/site-config';
import { useFormDefaultFormPhoneValue } from 'web/src/modules/phone/composables';
import isValueInSuggestedAmounts
  from 'web/src/modules/payments/form-widgets/FastSum/components/PaymentsInput/helpers/isValueInSuggestedAmounts';
import { useFormPhoneErrorPatterns } from 'web/src/components/Form/composables';

interface NextStep {
  formStep: PaymentsFormStep;
  uiFormSchema: Maybe<UiFormSchemaDocument>;
  formValidationSchema: Maybe<string>;
}

interface PaymentSystemComposable<T extends PaymentType> {
  currentPaymentSystem: Ref<CurrentPaymentSystem<T>>;
  paymentSystemData: Ref<CurrentPaymentSystemData<T>>;
  isAdditionalRegistrationNeeded: Ref<boolean>;
  uiFormSchema: Ref<Maybe<UiFormSchemaDocument>>;
  creditCards: Ref<BankCard[]>;
  submitPaymentResponse: Ref<Maybe<SubmitPaymentResponse>>;

  setSubmitResponse(response: Maybe<SubmitPaymentResponse>): void;

  nextSteps: Ref<Maybe<NextStep[]>>;
  paymentsProcedureStatus: Ref<PaymentsProcedureStatus>;
  paymentMessage: Ref<string>;
  redirectUrl: Ref<string>;
  name: Ref<string>;
  redirectMethod: Ref<Optional<Maybe<HttpMethod>>>;
  htmlContent: Ref<string>;
  htmlContentProperties: Ref<Optional<SubmitPaymentResponse['htmlContentProperties']>>;
  redirectCharset: Ref<string>;
  redirectFormParams: Ref<Maybe<Record<string, unknown>>>;
  formStep: Ref<Maybe<PaymentsFormStep>>;
  jsonSchema: Ref<FormSchema>;
  fastSumOptions: Ref<Record<string, unknown>>;
  uiFormSchamAdapted: Ref<Maybe<JsonSchemaAdapter>>;
  initialModalProperties: Ref<Maybe<ConfirmDialog>>;
  submitButton: Ref<Partial<FormUiSchemaSubmitButton>>;
  amount: Ref<string>;
  amountAsNumber: Ref<number>;
  amountAsMoney: Ref<string>;
  minAmount: Ref<Maybe<number>>;
  cancel: Ref<Maybe<Optional<PaymentActionButton>>>;
  creditCardToken: Ref<string>;
  creditCardType: Ref<Maybe<PaymentsCardTypes>>;
  validatorErrorPatterns: Ref<FormValidatorErrorPattern>;
  isFormPending: Ref<boolean>;
  isFormButtonDisabled: Ref<boolean>;
  cardExpirationDate: Ref<string>;
  paymentSystemId: Ref<Maybe<Optional<string>>>;
  submittedAmount: Ref<number>;
  submittedAmountAsMoney: Ref<string>;
  customErrors: Ref<Optional<FormExternalErrors>>;
  isCurrentValueFromSuggestedAmounts: Ref<boolean>;
  cardHolder: Ref<string>;

  setNextSteps(response: SubmitPaymentResponse): void;

  setSelectedBonusModalStatus(value: boolean): void;

  clearCurrentStep(): void;

  clearNDFLLocalStorageData(): void;

  getPaymentSubmitCancelPayload(): Maybe<PaymentsSubmitPayload>;

  clearSubmitResponse(keepLocalStorageData?: boolean): void;

  setSubmittedAmount(): void;

  handlePaymentError(value: AppError | CustomerFormError, customHandler?: () => boolean): void;

  setOpenIn(value: string): void;
}

export function usePaymentSystemData<T extends PaymentType>(): PaymentSystemComposable<T> {
  const { $translate } = useI18n();
  const { currency } = useCurrency();
  const formatMoney = useFormatMoney();
  const { balance } = useBalance();
  const { parsePhoneNumberByUIField } = useFormDefaultFormPhoneValue();
  const { phoneErrorPatterns } = useFormPhoneErrorPatterns();

  const currentPaymentSystem: Ref<CurrentPaymentSystem<T>> = shallowRef<CurrentPaymentSystem<T>>(null);
  const submitPaymentResponse = shallowRef<Maybe<SubmitPaymentResponse>>(null);
  const amount = ref('');
  const cardHolder = ref('');
  const creditCardToken = ref('');
  const isFormPending = ref(false);
  const cardExpirationDate = ref('');
  const submittedAmount = ref(0);

  const { isExpirationValidationDisabled, isPaymentsFastSumEnabled } = usePaymentsConfig();

  const paymentsProcedureStatus = ref<PaymentsProcedureStatus>({
    action: null,
    status: null,
  });

  const nextSteps = ref<Maybe<NextStep[]>>(null);

  const { isInstalled, checkIfAppInstalled } = useIsAppInstalled();

  const { customErrors: paymentErrors, handlePaymentError } = usePaymentError();

  const cardExpirationErrors = computed(() => {
    if (isExpirationValidationDisabled.value) {
      return {};
    }
    return useCardExpirationError(
      PaymentFormFieldName.EXPIRATION,
      cardExpirationDate,
    ).cardExpirationErrors.value;
  });

  onMounted(() => {
    void checkIfAppInstalled('ru.sberbankmobile');
  });

  const paymentSystemData = computed(() => currentPaymentSystem.value?.paymentSystemData ?? null);

  const isDeposit = computed(() => paymentSystemData.value?.paymentType === PaymentType.DEPOSIT);

  const name = computed(
    () => paymentSystemData.value?.name ?? '',
  );

  const paymentId = computed(() => paymentSystemData.value?.id);

  const creditCards = computed<BankCard[]>(
    () => paymentSystemData.value?.bankcards ?? [],
  );

  const allowSuggestedAmountsOnly = computed(
    () => paymentSystemData.value?.allowInputSuggestedAmountsOnly ?? false,
  );

  const minAmount = computed(() => paymentSystemData.value?.minAmount?.amount ?? 1);

  const maxAmount = computed(
    () => paymentSystemData.value?.maxAmount?.amount ?? (isDeposit.value ? 0 : balance.value),
  );

  const amountAsNumber = computed(() => parseFloat(amount.value));

  const amountAsMoney = computed(() => formatMoney(amountAsNumber.value));

  const amountSubmitButton = computed(() => formatMoney(Number(amount.value)));

  const isAmountInRange = computed(() => isValueInRange(
    amountAsNumber.value, minAmount.value, maxAmount.value,
  ));

  const isAdditionalRegistrationNeeded = computed(
    () => currentPaymentSystem.value?.additionalRegistrationNeeded ?? false,
  );

  const paymentSystemId = computed(() => paymentSystemData.value?.paymentSystemId);

  const submittedAmountAsMoney = computed(() => formatMoney(submittedAmount.value));

  const isInalidCardHolder = computed(() => {
    if (cardHolder.value) {
      return !/^((?:[A-Za-z-.]+ ?){1,})$/.test(cardHolder.value);
    }
    return false;
  });

  const setSubmittedAmount = () => {
    submittedAmount.value = amountAsNumber.value;
  };

  function setSelectedBonusModalStatus(value: boolean): void {
    currentPaymentSystem.value = {
      ...currentPaymentSystem.value,
      hideNoSelectedBonusModal: value,
    };
  }

  function setOpenIn(value: string): void {
    let openIn: OpenIn | null = null;
    switch (value) {
      case 'same_window':
        openIn = OpenIn.SAME_WINDOW;
        break;
      case 'new_window':
        openIn = OpenIn.NEW_WINDOW;
        break;
      case 'iframe':
        openIn = OpenIn.IFRAME;
        break;
      default:
        break;
    }

    if (openIn) {
      currentPaymentSystem.value = {
        ...currentPaymentSystem.value,
        paymentSystemData: {
          ...(currentPaymentSystem?.value?.paymentSystemData ?? {}),
          openIn,
        },
      };
    }
  }

  function clearLocalStorageItem(key: string): void {
    const storageValue = localStorageManager.getItem(key);
    if (storageValue) {
      localStorageManager.removeItem(key);
    }
  }

  function clearNDFLLocalStorageData(): void {
    clearLocalStorageItem(PaymentsNDFLType.NETTO_AMOUNT);
    clearLocalStorageItem(PaymentsNDFLType.BRUTTO_AMOUNT);
    clearLocalStorageItem(PaymentsNDFLType.TAX_AMOUNT);
  }

  function setSubmitResponse(response: Maybe<SubmitPaymentResponse>, keepLocalStorageData = false): void {
    submitPaymentResponse.value = response;
    if (response?.nettoAmount) {
      localStorageManager.setItem(PaymentsNDFLType.NETTO_AMOUNT, `${response.nettoAmount}`);
    } else if (!keepLocalStorageData) {
      clearLocalStorageItem(PaymentsNDFLType.NETTO_AMOUNT);
    }
    if (response?.bruttoAmount) {
      localStorageManager.setItem(PaymentsNDFLType.BRUTTO_AMOUNT, `${response.bruttoAmount}`);
    } else if (!keepLocalStorageData) {
      clearLocalStorageItem(PaymentsNDFLType.BRUTTO_AMOUNT);
    }
    if (response?.taxAmount) {
      localStorageManager.setItem(PaymentsNDFLType.TAX_AMOUNT, `${response.taxAmount}`);
    } else if (!keepLocalStorageData) {
      clearLocalStorageItem(PaymentsNDFLType.TAX_AMOUNT);
    }
    setNextSteps(response);
  }

  const redirectUrl = computed(() => submitPaymentResponse.value?.redirectUrl ?? '');

  const htmlContent = computed(() => submitPaymentResponse.value?.htmlContent ?? '');

  const htmlContentProperties = computed(() => submitPaymentResponse.value?.htmlContentProperties);

  const redirectCharset = computed(() => submitPaymentResponse.value?.redirectCharset ?? '');

  const redirectFormParams = computed<Maybe<Record<string, unknown>>>(() => submitPaymentResponse.value?.redirectFormParams as Maybe<Record<string, unknown>>);

  const redirectMethod = computed(() => submitPaymentResponse.value?.redirectMethod);

  const cancel = computed(() => submitPaymentResponse.value?.cancel);

  const creditCardType = computed(() => {
    let cardType;

    if (creditCardToken.value && creditCardToken.value !== PAYMENTS_UNDEFINED_CREDIT_CARD) {
      cardType = creditCards.value.find((creditCard) => creditCard.token === creditCardToken.value)?.type;
    }

    return isPaymentsCardTypes(cardType) ? cardType : null;
  });

  function getPaymentSubmitCancelPayload(): Maybe<PaymentsSubmitPayload> {
    if (cancel.value?.formParams?.length && cancel.value?.schemaId) {
      return getSubmitPaymentPayload({
        paymentSystemId: paymentId.value || '',
        schemaId: paymentSystemData.value?.schemaId || '',
        formParams: [...cancel.value.formParams] as RawEntryInput[],
        action: PaymentsSubmitAction.CANCEL,
      });
    }
    return null;
  }

  function setNextSteps(response: Maybe<SubmitPaymentResponse>) {
    const depositFormStep = isPaymentsFormStep(response?.formStep) ? response?.formStep : null;
    if (depositFormStep && response?.formValidationSchema && response.uiFormSchema && !response.redirectFormParams) {
      if (nextSteps.value === null) {
        nextSteps.value = [{
          formStep: depositFormStep,
          uiFormSchema: response.uiFormSchema,
          formValidationSchema: response.formValidationSchema,
        }];
      } else {
        nextSteps.value.push({
          formStep: depositFormStep,
          uiFormSchema: response.uiFormSchema,
          formValidationSchema: response.formValidationSchema,
        });
      }
    } else {
      nextSteps.value = null;
    }
  }

  function clearCurrentStep(): void {
    if (nextSteps.value === null) {
      return;
    }
    nextSteps.value.pop();
    if (nextSteps.value.length === 0) {
      nextSteps.value = null;
    }
  }

  const lastStep = computed(() => {
    if (nextSteps.value === null) {
      return null;
    }
    return nextSteps.value[nextSteps.value.length - 1];
  });

  const formStep = computed<Maybe<PaymentsFormStep>>(
    () => {
      const step = lastStep.value !== null
        ? lastStep.value.formStep
        : paymentSystemData.value?.formStep;
      return isPaymentsFormStep(step) ? step : null;
    },
  );

  const jsonSchema = computed<FormSchema>(() => {
    const schema = JSON.parse(lastStep.value !== null
      ? lastStep.value.formValidationSchema ?? ''
      : paymentSystemData.value?.formValidationSchema ?? '');
    assert(isFormSchema(schema), 'jsonSchema should be of type FormSchema');
    return schema;
  });

  const currentPaymentSystemSuggestedAmounts = computed(() => {
    if (paymentSystemData.value?.suggestedAmounts?.length) {
      const suggestedAmounts = paymentSystemData.value?.suggestedAmounts.find(
        (customAmounts: SuggestedAmount) => (customAmounts.currency === currency.value),
      );
      if (suggestedAmounts?.amounts.length) {
        return [...suggestedAmounts.amounts];
      }
    }
    return [];
  });

  const isCurrentValueFromSuggestedAmounts = computed(() => (
    allowSuggestedAmountsOnly.value && isPaymentsFastSumEnabled.value
      ? isValueInSuggestedAmounts(currentPaymentSystemSuggestedAmounts.value, amountAsNumber.value)
      : true));

  const isFormButtonDisabled = computed(() => {
    const isInitialStepAndAmountNotInRange = formStep.value === PaymentsFormStep.INITIAL
      && !isAmountInRange.value;
    const isWithdrawalAndAmountExceedsBalance = amountAsNumber.value > balance.value && !isDeposit.value;

    return isInitialStepAndAmountNotInRange
      || isWithdrawalAndAmountExceedsBalance
      || !isCurrentValueFromSuggestedAmounts.value
      || isInalidCardHolder.value;
  });

  const currentPaymentSystemAllowedIncrement = computed(() => {
    if (paymentSystemData.value?.allowedIncrements?.length) {
      const allowedIncrements = (currentPaymentSystem.value?.paymentSystemData?.allowedIncrements ?? []).find(
        (customAmount: AllowedAmount) => (customAmount.currency === currency.value),
      );
      if (allowedIncrements?.amount) {
        return allowedIncrements.amount;
      }
    }
    return 0;
  });

  const uiFormSchema = computed<Maybe<UiFormSchemaDocument>>(() => {
    // eslint-disable-next-line max-len
    const uiSchema = lastStep.value !== null ? lastStep.value.uiFormSchema : paymentSystemData.value?.uiFormSchema ?? null;
    // eslint-disable-next-line sonarjs/no-collapsible-if
    if (process.env.VUE_APP_PLATFORM_CORDOVA && process.env.VUE_APP_FEATURE_TSUPIS_ENABLED) {
      // eslint-disable-next-line max-len
      if (uiSchema && paymentId.value && paymentId.value === 'depetsupissberpay' && formStep.value === PaymentsFormStep.INITIAL) {
        return {
          ...uiSchema,
          fields: uiSchema.fields.map((item) => {
            if (item.id === 'sberpay') {
              return {
                ...item,
                defaultValue: `${isInstalled.value}`,
              };
            }
            return item;
          }),
        };
      }
    }
    return uiSchema;
  });

  const initialModalProperties = computed<Maybe<ConfirmDialog>>(() => {
    function isKeyExist(array: StringEntry[], keys: string[]): boolean {
      return keys.every((key) => array.some((item) => item.key === key && !!item.value));
    }

    const uiSchemaFields = uiFormSchema.value?.fields ?? null;
    if (uiSchemaFields) {
      const modalProperties = uiSchemaFields.find((item) => {
        if (item.options.length && item.options) {
          return isKeyExist(item.options, ['hintEnterIdButton', 'hintMessage', 'hintTitle']);
        }
        return false;
      });
      if (modalProperties) {
        const options = getUiFieldRawOptions(modalProperties.options);
        return {
          confirmMessage: `${options?.hintMessage ?? ''}`,
          title: `${options?.hintTitle ?? ''}`,
          buttons: [
            {
              label: `${options?.hintEnterIdButton ?? ''}`,
              action: DialogAction.CONFIRM,
            },
          ],
        };
      }
    }
    return null;
  });

  const fastSumOptions = computed(() => ({
    minAmount: minAmount.value ?? 1,
    suggestedAmounts: currentPaymentSystemSuggestedAmounts.value,
    allowedIncrement: currentPaymentSystemAllowedIncrement.value,
    allowInputSuggestedAmountsOnly: allowSuggestedAmountsOnly.value,
  }));

  const uiFormSchamAdapted = computed<Maybe<JsonSchemaAdapter>>(() => {
    if (uiFormSchema.value) {
      return new JsonSchemaAdapter(uiFormSchema.value, jsonSchema.value)
        .addPropertiesByWidget(FormControlType.FastSum, {
          default: amount.value,
        })
        .addPropertiesById(PaymentsCardField.CARD_HOLDER, {
          widget: FormControlType.CardName,
        })
        .addOptionsByWidget(FormControlType.Text, {
          ...initialModalProperties.value !== null ? {
            suffixIconName: IconName.QUESTION,
            clickableIcon: true,
          } : {},
        })
        .addOptionsByWidget(FormControlType.FastSum, {
          amount: amount.value,
          minAmount: minAmount.value ?? null,
          maxAmount: maxAmount.value ?? null,
          defaultInputValue: paymentSystemData.value?.defaultInputValue,
          suggestedAmounts: currentPaymentSystemSuggestedAmounts.value,
          allowedIncrement: currentPaymentSystemAllowedIncrement.value,
          allowInputSuggestedAmountsOnly: allowSuggestedAmountsOnly.value,
        })
        .addPropertiesByWidget(FormControlType.CardExpirationDate, {
          touchedStrategy: FormFieldTouchedStrategy.Change,
          shortWidth: true,
        })
        .addPropertiesByWidget(FormControlType.CardCvv, {
          shortWidth: true,
        })
        .addPropertiesByWidget(FormControlType.CreditCardSelector, {
          default: creditCardToken.value,
        })
        .addPropertiesByWidgetGroup([
          FormControlType.Phone,
          FormControlType.PhoneInput,
          FormControlType.PhoneCountriesSelectorInput,
        ], (field) => ({
          default: parsePhoneNumberByUIField(field),
        }));
    }
    return null;
  });

  const paymentMessage = computed(() => {
    const {
      msg,
      uiFormSchema: uiSchema,
    } = submitPaymentResponse.value ?? {};

    if (msg && uiSchema === null && !redirectUrl.value) {
      return msg;
    }
    if (isDeposit.value
      && paymentsProcedureStatus.value.status === PaymentsStatus.OK
      && submitPaymentResponse.value?.formStep === null
      && submitPaymentResponse.value?.redirectUrl === null
      && redirectMethod.value === null
      && submitPaymentResponse.value?.redirectCharset === null
      && submitPaymentResponse.value?.redirectFormParams === null) {
      return $translate('JSP_CCGATEWAY_PAYMENT_SUCCESS_DESC').value;
    }
    return '';
  });

  const buttonLabelText = computed(() => {
    if (process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED) {
      return isDeposit.value ? $translate('JS_ADD_FUNDS').value : $translate('WEB2_WITHDRAW').value;
    }

    return isDeposit.value ? $translate('JS_ADD_FUNDS').value.toUpperCase() : $translate('WEB2_WITHDRAW').value.toUpperCase();
  });

  const submitPendingButtonLabel = computed(() => (process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED
    ? $translate('WEB2_PROCESSING').value
    : $translate('WEB2_PROCESSING').value.toUpperCase()));

  const submitButtonLabel = computed(() => {
    if (isFormPending.value) {
      return submitPendingButtonLabel.value;
    }

    if ((amount.value || (formStep.value !== PaymentsFormStep.INITIAL && amount.value)) && isAmountInRange.value) {
      if (isDeposit.value) {
        return process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED
          ? `${$translate('WEB2_REPLENISH').value} ${amountSubmitButton.value}`
          : `${$translate('WEB2_REPLENISH').value.toUpperCase()} ${amountSubmitButton.value}`;
      }
      if (amountAsNumber.value <= balance.value) {
        return process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED
          ? `${$translate('WEB2_WITHDRAW').value} ${amountSubmitButton.value}`
          : `${$translate('WEB2_WITHDRAW').value.toUpperCase()} ${amountSubmitButton.value}`;
      }
    }

    return buttonLabelText.value;
  });

  const submitButton = computed<Partial<FormUiSchemaSubmitButton>>(() => ({
    label: submitButtonLabel.value,
    iconName: process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED ? IconName.SLOTT_SHIELD_CHECK : IconName.SHIELD,
    iconSize: IconSize.SIZE_16,
    fullWidth: true,
    isUppercase: false,
    disabled: isFormButtonDisabled.value,
  }));

  const validatorErrorPatterns = computed<FormValidatorErrorPattern>(() => ({
    byPattern: {
      [FormControlType.Text]: {
        '^([a-zA-Z]+[\\s-\\.]*)+$': $translate('WEB2_PAYINPLAY_PATTERN_ERROR').value,
        '^([a-zA-Z]+[-\\s\\.]*)+$': $translate('WEB2_PAYMIFINITYBANKACCOUNT_PATTERN_ERROR').value,
        '^[a-zA-Z-\'& ]+$': $translate('WEB2_PAYINPLAY_PATTERN_ERROR').value,
        '^[0-9]{12}$': $translate('WEB2_PAYMENTS_DOCUMENT_NUMBER_PATTERN_ERROR').value,
        '^\\d+$': $translate('WEB2_PAYMENTS_ACCOUNT_PATTERN_ERROR').value,
        '^[a-zA-Z]{4}\\d{7}$': $translate('WEB2_PAYMENTS_CODE_PATTERN_ERROR').value,
      },
    },
    byWidget: phoneErrorPatterns.value,
  }));

  function clearSubmitResponse(keepLocalStorageData = false): void {
    setSubmitResponse(null, keepLocalStorageData);
  }

  const customErrors = computed<FormExternalErrors>(() => ({
    ...paymentErrors.value,
    ...cardExpirationErrors.value,
  }));

  return {
    setSubmitResponse,
    submitPaymentResponse,
    currentPaymentSystem,
    paymentSystemData,
    isAdditionalRegistrationNeeded,
    uiFormSchema,
    creditCards,
    name,
    nextSteps,
    paymentsProcedureStatus,
    paymentMessage,
    redirectUrl,
    redirectMethod,
    formStep,
    jsonSchema,
    fastSumOptions,
    uiFormSchamAdapted,
    initialModalProperties,
    submitButton,
    amount,
    amountAsNumber,
    amountAsMoney,
    minAmount,
    htmlContent,
    htmlContentProperties,
    redirectCharset,
    redirectFormParams,
    cancel,
    creditCardToken,
    creditCardType,
    validatorErrorPatterns,
    isFormPending,
    isFormButtonDisabled,
    cardExpirationDate,
    paymentSystemId,
    submittedAmount,
    submittedAmountAsMoney,
    customErrors,
    isCurrentValueFromSuggestedAmounts,
    cardHolder,
    setSelectedBonusModalStatus,
    setNextSteps,
    clearCurrentStep,
    clearNDFLLocalStorageData,
    getPaymentSubmitCancelPayload,
    clearSubmitResponse,
    setSubmittedAmount,
    handlePaymentError,
    setOpenIn,
  };
}
