<script lang="ts" setup>
import { computed } from 'vue';

interface ExpandButtonProps {
  isExpanded: boolean;
  openedTitle?: string;
  closedTitle?: string;
}

interface ExpandButtonEmits {
  (e: 'update:isExpanded', newValue: boolean): void;
}

const props = defineProps<ExpandButtonProps>();
const emit = defineEmits<ExpandButtonEmits>();

const title = computed<string>(() => (props.isExpanded
  ? (props.openedTitle || '')
  : (props.closedTitle || '')));

function toggleExpandState(): void {
  emit('update:isExpanded', !props.isExpanded);
}
</script>

<template>
  <div v-auto-id="'ExpandButton'"
    :class="$style['statistic-expand-button']"
    @click="toggleExpandState"
  >
    {{ title }}
  </div>
</template>

<style lang="scss" module>
.statistic-expand-button {
  @include medium\13\16;

  display: flex;
  justify-content: center;
  padding: 8px;
  color: var(--TextDefault);
  cursor: pointer;
  background-color: var(--Layer2);
  border-radius: 5px;
}
</style>
