import type { FieldGuards } from '@leon-hub/types';
import {
  isBoolean,
  isNumber,
  isUndefined,
  isValidInterface,
} from '@leon-hub/guards';

import type { CoreLeagueResponse } from 'web/src/modules/sportline/types/rest';

import { isCoreSportResponse } from './isCoreSportResponse';
import { isCoreSportReferenceResponse } from './isCoreSportReferenceResponse';
import { isMinimalWorkingLeagueResponseFieldGuards } from './isMinimalWorkingLeagueResponse';

export const isCoreLeagueResponseFieldGuards: FieldGuards<CoreLeagueResponse> = {
  id: isMinimalWorkingLeagueResponseFieldGuards.id,
  region: isMinimalWorkingLeagueResponseFieldGuards.region,
  name: isMinimalWorkingLeagueResponseFieldGuards.name,
  nameDefault: isMinimalWorkingLeagueResponseFieldGuards.nameDefault,
  sport: [isCoreSportReferenceResponse, isCoreSportResponse],
  prematch: [isNumber, isUndefined],
  inplay: [isNumber, isUndefined],
  outright: [isNumber, isUndefined],
  top: [isBoolean, isUndefined],
  topOrder: [isNumber, isUndefined],
  weight: [isNumber, isUndefined],
  url: isMinimalWorkingLeagueResponseFieldGuards.url,
  hasZeroMarginEvents: [isBoolean, isUndefined],
};

export function isCoreLeagueResponse(value: unknown): value is CoreLeagueResponse {
  return isValidInterface<CoreLeagueResponse>(value, isCoreLeagueResponseFieldGuards);
}
