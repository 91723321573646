<script lang="ts" setup>
import {
  toRef,
  onMounted,
  onActivated,
  onUnmounted,
  onDeactivated,
} from 'vue';

import { useNeedRedirectFromTab } from 'web/src/modules/sportline/components/SportlineTab/composables';
import { createSportlineFragmentBasisKeyValue } from 'web/src/modules/sportline/composables/list/utils';
import type { SportElement } from 'web/src/modules/sportline/types';
import type {
  SportlineTabProps,
  SportlineTabEmits,
} from 'web/src/modules/sportline/components/SportlineTab/types';
import { ExtendedEventBlock } from 'web/src/modules/sportline/submodules/extended-event/enums';
import { VLoaderMaskedDelayed } from 'web/src/components/Loader';
import SportlineSportBlock from 'web/src/modules/sportline/views/SportBlock/SportlineSportBlock.vue';
import SportNoResults from 'web/src/modules/sportline/components/NoEvents/SportNoResults.vue';

import { useSportlineFavoritesTabList } from '../useSportlineFavoritesTabList';

interface SportlineFavoritesTabListProps extends SportlineTabProps {
  favoriteEventsList: SportElement[];
  defaultExpandSportKey: string;
  defaultExpandLeagueKey: string;
  isSomeFilterSelected?: boolean;
  selectedRegionFamily?: Maybe<string>;
}

const props = defineProps<SportlineFavoritesTabListProps>();
const emit = defineEmits<SportlineTabEmits>();

const isActive = toRef(props, 'isActive', false);
const isSomeFilterSelected = toRef(props, 'isSomeFilterSelected', false);
const selectedRegionFamily = toRef(props, 'selectedRegionFamily', null);
const defaultExpandSportKey = toRef(props, 'defaultExpandSportKey');
const defaultExpandLeagueKey = toRef(props, 'defaultExpandLeagueKey');

function emitResetFilter(): void {
  emit('reset-filter');
}

function emitRedirectFromTab(): void {
  emit('redirect-from-tab');
}

const {
  isLoaded,
  isNeedRedirectFromTab,
  isMarketTypesSelectionEnabled,
  onTabActivated,
  onTabDeactivated,
} = useSportlineFavoritesTabList({ isActive, selectedRegionFamily });
useNeedRedirectFromTab({ isNeedRedirectFromTab }, { emitRedirectFromTab });

onMounted(onTabActivated);
onActivated(onTabActivated);
onUnmounted(onTabDeactivated);
onDeactivated(onTabDeactivated);
</script>

<template>
  <div v-auto-id="'SportlineFavoritesTabList'">
    <VLoaderMaskedDelayed v-if="!isLoaded" />

    <template v-else-if="favoriteEventsList.length > 0">
      <SportlineSportBlock
        v-for="sportElement in favoriteEventsList"
        :key="sportElement.sport.id"
        :sport-element="sportElement"
        :is-market-types-selection-enabled="isMarketTypesSelectionEnabled"
        :collapse-league-key="defaultExpandLeagueKey"
        :expand-key="`${defaultExpandSportKey}-${sportElement.sport.representation.family}`"
        :extended-block-type="ExtendedEventBlock.Favorites"
        :basis-key="createSportlineFragmentBasisKeyValue({ sportId: sportElement.sport.id })"
        is-default-expanded
        is-show-add-league-to-favorite
        is-display-outright-events-enabled
        is-league-default-expanded
        can-add-to-favorite
        can-add-outrights-to-favorite
      />
    </template>

    <SportNoResults
      v-else-if="isActive"
      :class="$style['no-results']"
      :sportline-is-empty="!isSomeFilterSelected"
      can-reset-filter
      @reset="emitResetFilter"
    />
  </div>
</template>

<style module lang="scss">
.no-results {
  width: 375px;
  padding: 32px;
}
</style>
