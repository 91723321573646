import type {
  CoreMarketRunnerResponse,
} from 'web/src/modules/sportline/types/rest';
import type {
  ClaimToUpdateSportEventStatusChanged,
} from 'web/src/modules/slip/types/сlaimToUpdate';

import isMatchedChange from './isMatchedChange';

export default function applyRunnerStatusChanges(
  { eventId, marketId, runner }: {
    eventId?: number;
    marketId?: number;
    runner: CoreMarketRunnerResponse;
  },
  changes: ClaimToUpdateSportEventStatusChanged[],
): CoreMarketRunnerResponse {
  const actualChange = changes
    .find((change) => isMatchedChange(change, {
      eventId,
      marketId,
      runnerId: runner.id,
    }));

  if (!actualChange) { return runner; }

  return {
    ...runner,
    open: actualChange.marketStatus === null,
  };
}
