import type { Ref, ComputedRef } from 'vue';
import { computed, ref } from 'vue';

import { assert, isString } from '@leon-hub/guards';

import type { VFileInputPlaceholderProps } from 'web/src/components/PhotoPlaceholder/VFileInputPlaceholder/types';
import { getFileType } from 'web/src/components/Input/utils';

import type { FileMultipleDragAndDropInputProps, FileMultipleEmits } from '../../types';
import { InputFileTypes } from '../../enums';
import useMultipleFileInput from '../../composables/useMultipleFileInput';
import type { UseMultipleFileInput } from '../../composables/types';

interface UseFileMultipleDragAndDropInput extends Omit<UseMultipleFileInput, 'handleFileList'> {
  errorList: ComputedRef<Array<string | null | object>>;
  placeholderPropsList: ComputedRef<VFileInputPlaceholderProps[]>;
  onLabelKeydown: (event: KeyboardEvent) => void;
  isInDropZone: Ref<boolean>;
  onDragenter: (event: DragEvent) => void;
  onDragleave: (event: DragEvent) => void;
  onDragover: (event: DragEvent) => void;
  onDrop: (event: DragEvent) => void;
}

export default function useFileMultipleDragAndDropInput(
  props: FileMultipleDragAndDropInputProps,
  emits: FileMultipleEmits,
): UseFileMultipleDragAndDropInput {
  const {
    uniqueId,
    filesRecords,
    acceptedFileTypes,
    removeByIndex,
    onChange,
    onReplace,
    onBlur,
    onFocus,
    addMoreAvailable,
    handleFileList,
  } = useMultipleFileInput(props, emits);

  const errorList = computed<Array<string | null | object>>(() => (props.formErrors || [])
    .filter((item) => !!item && isString(item)));

  const placeholderPropsList = computed<VFileInputPlaceholderProps[]>(() => filesRecords.value
    .map(({ name, base64String }, index) => {
      const fileType = getFileType(name);
      const isImageFile = fileType === InputFileTypes.Image;
      return {
        imageSrc: isImageFile ? base64String : '',
        hasError: !!(props.formErrors && props.formErrors[index]),
        name,
        isImageFile,
      };
    }));

  const onLabelKeydown = (event: KeyboardEvent): void => {
    const { target } = event;

    assert(target instanceof HTMLElement, 'element must be HTMLElement');

    target.click();
  };

  const isInDropZone = ref<boolean>(false);

  const onDragenter = (event: DragEvent): void => {
    event.preventDefault();
    isInDropZone.value = true;
  };

  const onDragleave = (event: DragEvent): void => {
    event.preventDefault();
    isInDropZone.value = false;
  };

  const onDragover = (event: DragEvent): void => {
    event.preventDefault();
  };

  const onDrop = (event: DragEvent): void => {
    event.preventDefault();
    isInDropZone.value = false;
    const dataTransferFiles = event.dataTransfer?.files;
    if (!dataTransferFiles) {
      return;
    }
    handleFileList(dataTransferFiles);
  };

  return {
    errorList,
    placeholderPropsList,
    uniqueId,
    acceptedFileTypes,
    onLabelKeydown,
    filesRecords,
    isInDropZone,
    removeByIndex,
    onChange,
    onReplace,
    onBlur,
    onFocus,
    onDragenter,
    onDragleave,
    onDragover,
    onDrop,
    addMoreAvailable,
  };
}
