import { defineStore } from 'pinia';
import { ref } from 'vue';

import { Timer } from '@leon-hub/utils';

import type { Tooltip } from 'web/src/modules/dialogs/store/types';

const useTooltipStore = defineStore('tooltip', () => {
  const tooltip = ref<Tooltip>();

  let timer = 0;

  function showTooltip(value: Tooltip): void {
    tooltip.value = value;

    timer = Timer.setTimeout(closeTooltip, value.duration || 1500);
  }

  function closeTooltip(): void {
    if (timer > 0) {
      Timer.clearTimeout(timer);
      timer = 0;
    }

    tooltip.value = undefined;
  }

  return {
    tooltip,
    showTooltip,
  };
});

export default useTooltipStore;
