import { RouteName } from '@leon-hub/routing-config-names';

import type { AppVueRouter } from 'web/src/modules/core/services/router/types';
import { useIsDevIP } from 'web/src/modules/core/composables/root';

export default function createDevtoolsNavigationGuard(router: AppVueRouter): void {
  const { isDevIP } = useIsDevIP();

  router.beforeEach((to, from, next) => {
    if (to.name === RouteName.DEVTOOLS && !isDevIP.value) {
      router.next(next, {
        name: RouteName.ERROR_NOT_FOUND_404,
      });

      return;
    }

    next();
  });
}
