import type { G2SVRequiredException } from '@leon-hub/api-sdk';

import GqlApiBatchedSubRequestError from './GqlApiBatchedSubRequestError';

export default class GqlApiG2svRequiredError extends GqlApiBatchedSubRequestError {
  extensions: G2SVRequiredException;

  constructor(options: { extensions: G2SVRequiredException }) {
    const { extensions } = options;

    super({
      ...options,
    });

    this.extensions = extensions;
  }
}
