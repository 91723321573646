import { ModalWidth } from 'web/src/components/Modal/enums';
import type { DefaultModalProps } from 'web/src/components/Modal/DefaultModal/types';

export default function getModalProps(
  customBackground: string | undefined,
): DefaultModalProps {
  return {
    width: ModalWidth.DEFAULT,
    isCentered: false,
    isOverlayCloseAllowed: !process.env.VUE_APP_LAYOUT_DESKTOP,
    isFullHeight: true,
    isFullHeightCentered: false,
    isOverlayVisible: false,
    customBackground: process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED ? undefined : customBackground,
  };
}
