import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import { useI18n } from 'web/src/modules/i18n/composables';

import type { BookingCodeLoadResultProps, BookingCodeLoadResultEmits } from '../types';

interface UseBookingCodeLoadResult {
  buttonText: ComputedRef<string>;
  logoUrl: ComputedRef<string>;
  onButtonClick(): void;
}

export function useBookingCodeLoadResult(props: BookingCodeLoadResultProps, emit: BookingCodeLoadResultEmits): UseBookingCodeLoadResult {
  const { $translate } = useI18n();

  const logoUrl = computed<string>(() => {
    let name = 'mascot';
    if (props.isLightTheme) {
      name += '--light';
    }

    return require(`web/src/assets/mascot/${process.env.VUE_APP_PRODUCT}/${process.env.VUE_APP_SKIN}/${name}.svg`);
  });

  const buttonText = computed<string>(() => {
    if (props.isAllEventsExpired) {
      return props.isLoggedIn
        ? $translate('WEB2_BOOKING_CODE_TO_HOMEPAGE').value
        : $translate('WEB2_DO_LOGIN').value;
    }
    return $translate('WEB2_BOOKING_CODE_TO_SLIP').value;
  });

  const onButtonClick = (): void => emit('button-click');

  return {
    logoUrl,
    buttonText,
    onButtonClick,
  };
}
