export enum LandingChevronKind {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

export enum LandingChevronDecor {
  NONE = 'none',
  STAR = 'star',
  CIRCLE = 'circle',
  DOUBLE_CIRCLE = 'double-circle',
}

export interface LandingChevronProps {
  label?: string;
  kind?: LandingChevronKind;
  decoration?: LandingChevronDecor;
}
