import { ref } from 'vue';

import { localeDateTimeSpecMonthShortNames } from './mocks';
import type { $T, MonthsRefs } from '../types';

export function getMonthShortNames($t: $T): MonthsRefs {
  return [
    process.env.NODE_ENV === 'test' ? ref(localeDateTimeSpecMonthShortNames[0]) : $t('WEB2_MONTH_JANUARY_SHORT'),
    process.env.NODE_ENV === 'test' ? ref(localeDateTimeSpecMonthShortNames[0]) : $t('WEB2_MONTH_FEBRUARY_SHORT'),
    process.env.NODE_ENV === 'test' ? ref(localeDateTimeSpecMonthShortNames[0]) : $t('WEB2_MONTH_MARCH_SHORT'),
    process.env.NODE_ENV === 'test' ? ref(localeDateTimeSpecMonthShortNames[0]) : $t('WEB2_MONTH_APRIL_SHORT'),
    process.env.NODE_ENV === 'test' ? ref(localeDateTimeSpecMonthShortNames[0]) : $t('WEB2_MONTH_MAY_SHORT'),
    process.env.NODE_ENV === 'test' ? ref(localeDateTimeSpecMonthShortNames[0]) : $t('WEB2_MONTH_JUNE_SHORT'),
    process.env.NODE_ENV === 'test' ? ref(localeDateTimeSpecMonthShortNames[0]) : $t('WEB2_MONTH_JULY_SHORT'),
    process.env.NODE_ENV === 'test' ? ref(localeDateTimeSpecMonthShortNames[0]) : $t('WEB2_MONTH_AUGUST_SHORT'),
    process.env.NODE_ENV === 'test' ? ref(localeDateTimeSpecMonthShortNames[0]) : $t('WEB2_MONTH_SEPTEMBER_SHORT'),
    process.env.NODE_ENV === 'test' ? ref(localeDateTimeSpecMonthShortNames[0]) : $t('WEB2_MONTH_OCTOBER_SHORT'),
    process.env.NODE_ENV === 'test' ? ref(localeDateTimeSpecMonthShortNames[0]) : $t('WEB2_MONTH_NOVEMBER_SHORT'),
    process.env.NODE_ENV === 'test' ? ref(localeDateTimeSpecMonthShortNames[0]) : $t('WEB2_MONTH_DECEMBER_SHORT'),
  ] as const;
}
