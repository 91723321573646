import { toRef } from 'vue';

import { useSiteConfigStore } from 'web/src/modules/core/store';
import { FlagValue } from 'web/src/modules/i18n/components/LanguageSelector/enums';

interface IsGlobeIconComposable {
  isGlobeIcon: (value: string) => boolean;
}
export default function useIsGlobeIcon(): IsGlobeIconComposable {
  const isLanguageSelectorFlagsEnabled = toRef(useSiteConfigStore(), 'isLanguageSelectorFlagsEnabled');

  function isGlobeIcon(value: string): boolean {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
    return value === FlagValue.TECH || !isLanguageSelectorFlagsEnabled.value;
  }
  return {
    isGlobeIcon,
  };
}
