<script lang="ts" setup>
import { IconName, CasinoIconName } from '@leon-hub/icons';

import CasinoLoyaltyProgramIcon
  from 'web/src/modules/casino-loyalty-program/components/CasinoLoyaltyProgramIcon/CasinoLoyaltyProgramIcon.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { CasinoLoyaltyProgramIconSizes } from 'web/src/modules/casino-loyalty-program/store/types';
import { ButtonHeight, ButtonKind } from 'web/src/components/Button/VButton/enums';
import { useCasinoLoyaltyProgramCurrentLevelCard } from 'web/src/modules/casino-loyalty-program/components/CasinoLoyaltyProgramCurrentLevelCard/composables';
import BonusLoyaltyProgramBalance
  from 'web/src/modules/bonuses/components/BonusLoyaltyProgramBalance/BonusLoyaltyProgramBalance.vue';

import type { CasinoLoyaltyProgramCurrentLevelCardEmits, CasinoLoyaltyProgramCurrentLevelCardProps } from './types';

const props = withDefaults(defineProps<CasinoLoyaltyProgramCurrentLevelCardProps>(), {
  currentValue: 0,
  total: 0,
  imageSrc: '',
  levelTitle: '',
  nextLevelTitle: '',
  multiplier: '0',
  backgroundColor: '',
  image: null,
});
const emits = defineEmits<CasinoLoyaltyProgramCurrentLevelCardEmits>();

const {
  currentBalance,
  remainingPoints,
  nextLevel,
  multiplierLabel,
  onClick,
  onGiftClick,
  clickOnBalance,
} = useCasinoLoyaltyProgramCurrentLevelCard(props, emits);
</script>

<template>
  <div v-auto-id="'CasinoLoyaltyProgramCurrentLevelCard'"
    :class="{
      [$style['casino-loyalty-program-current-level-card']]: true,
      [$style['casino-loyalty-program-current-level-card--init-level']]: initLevel
    }"
    :style="{
      background: backgroundColor,
    }"
    @click="onClick"
  >
    <VButton
      v-if="!initLevel"
      :class="$style['casino-loyalty-program-current-level-card__button']"
      :kind="ButtonKind.PRIMARY"
      :height="ButtonHeight.SMALL"
      :icon-name="IconName.PROMOS"
      rounded
      is-absolute-position
      @click.stop="onGiftClick"
    />
    <CasinoLoyaltyProgramIcon
      :class="$style['casino-loyalty-program-current-level-card__icon']"
      :image-src="imageSrc"
      :size="CasinoLoyaltyProgramIconSizes.SIZE_132"
      :is-inactive="initLevel"
      gradient-bg
      :image="image"
    />
    <div :class="$style['casino-loyalty-program-current-level-card__title']">
      {{ levelTitle }}
    </div>
    <div :class="$style['casino-loyalty-program-current-level-card__points-info']">
      <span :class="$style['casino-loyalty-program-current-level-card__current']"> {{ currentBalance }}</span>
      /
      <span>{{ remainingPoints }} {{ nextLevel }}</span>
    </div>
    <BonusLoyaltyProgramBalance
      :icon-name="CasinoIconName.CASINO_ROULETTE"
      :text="`${multiplierLabel} ${multiplier}`"
      @click="clickOnBalance"
    />
  </div>
</template>

<style lang="scss" module>
.casino-loyalty-program-current-level-card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  background: radial-gradient(98.83% 180.3% at 0.72% 1.17%, rgba(132, 154, 234, 0.3) 0%, rgba(57, 39, 123, 0.3) 100%);
  border-radius: 5px;

  @include for-hover {
    &:hover:not(&--init-level) {
      cursor: pointer;
    }
  }

  &__button {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  &__progress {
    margin-bottom: 16px;
  }

  &__icon {
    margin-bottom: 4px;
  }

  &__title {
    @include medium\24\28;

    width: 100%;
    margin-bottom: 8px;
    overflow: hidden;
    color: var(--BrandText);
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__points-info {
    @include regular\13\20;

    margin-bottom: 8px;
    color: var(--BrandText);
  }

  &__current {
    @include medium\13\20;
  }
}
</style>
