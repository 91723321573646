import { useRouter } from 'vue-router';
import { toRef, computed, ref } from 'vue';
import type { Ref } from 'vue';

import { RouteName } from '@leon-hub/routing-config-names';

interface UseHeaderBarCustomBrandComposable {
  doUseESportBrand: Ref<boolean>;
}

export function useHeaderBarCustomBrand(): UseHeaderBarCustomBrandComposable {
  const router = useRouter();
  const currentRoute = toRef(router, 'currentRoute');

  return {
    doUseESportBrand: process.env.VUE_APP_FEATURE_LOGO_ESPORT_ENABLED
      ? computed(() => currentRoute.value.name === RouteName.SPORTLINE_CYBERSPORT)
      : ref(false),
  };
}
