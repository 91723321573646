import type {
  LeagueElement,
  MarketType,
  OutrightSportlineEvent,
  RegionElement,
  SportElement,
  SportlineEvent,
  SportlineEventElement,
} from 'web/src/modules/sportline/types';
import type {
  CoreLeagueResponse,
  CoreRegionResponse,
  CoreSportEventResponse,
  CoreSportResponse,
  RawLeague,
  RawRegion,
  RawSport,
} from 'web/src/modules/sportline/types/rest';
import { SportlineType } from 'web/src/modules/sportline/enums';
import {
  addCountersToCounters,
  getSportEventElementsListCounters,
  reduceListWithSportEventsCounters,
} from 'web/src/modules/sportline/utils';
import {
  createSportEvent,
  parseLeagueResponse,
  parseRegionResponse,
  parseSportResponse,
} from 'web/src/modules/sportline/utils/rest/build';

import { AbstractLineFactory } from './AbstractLineFactory';

interface PreparedLeague {
  number: number;
  league: RawLeague;
  sportEvents: SportlineEvent[];
  outrightEvents: OutrightSportlineEvent[];
}

interface PreparedRegion {
  number: number;
  region: RawRegion;
  leagues: PreparedLeague[];
}

interface PreparedSport {
  sport: RawSport;
  regions: PreparedRegion[];
}

/**
 * Used to prepare standard sportline with events
 * like live, cybersport, league and region pages
 */
export class SportlineFactory<T extends SportlineEvent>
  extends AbstractLineFactory<SportElement<T>, PreparedSport[]> {
  protected result: PreparedSport[] = [];

  protected processEventResponse({
    sportResponse,
    regionResponse,
    leagueResponse,
    eventResponse,
  }: {
    sportResponse: CoreSportResponse;
    regionResponse: CoreRegionResponse;
    leagueResponse: CoreLeagueResponse;
    eventResponse: CoreSportEventResponse;
  }): void {
    // sports only one will be grouped by id
    const sportId = String(sportResponse.id);
    let preparedSport = this.result.find((prepared) => prepared.sport.sport.id === sportId);
    if (!preparedSport) {
      const sport = parseSportResponse(sportResponse, this.buildSportOptions);
      preparedSport = { sport, regions: [] };
      this.result.push(preparedSport);
    }

    const regionId = String(regionResponse.id);
    let preparedRegion = this.factoryOptions?.groupByRegion
      ? preparedSport.regions.find((prepared) => prepared.region.region.id === regionId)
      : preparedSport.regions[preparedSport.regions.length - 1];
    if (preparedRegion?.region.region.id !== regionId) {
      const number = preparedSport.regions
        .filter((prepared) => prepared.region.region.id === regionId).length + 1;
      const region = parseRegionResponse({
        sportResponse,
        regionResponse,
        ...this.buildSportOptions,
      });
      preparedRegion = {
        number,
        region,
        leagues: [],
      };
      preparedSport.regions.push(preparedRegion);
    }

    const leagueId = String(leagueResponse.id);
    let preparedLeague = preparedRegion.leagues[preparedRegion.leagues.length - 1];
    if (preparedLeague?.league.league.id !== leagueId) {
      const number = preparedRegion.leagues
        .filter((prepared) => prepared.league.league.id === leagueId).length + 1;
      const league = parseLeagueResponse({
        sportResponse,
        regionResponse,
        leagueResponse,
        ...this.buildSportOptions,
      });
      preparedLeague = {
        number,
        league,
        sportEvents: [],
        outrightEvents: [],
      };
      preparedRegion.leagues.push(preparedLeague);
    }

    const sportlineEvent = createSportEvent(eventResponse, {
      sportResponse,
      regionResponse,
      leagueResponse,
      ...this.buildSportOptions,
      useFullProgress: preparedSport.sport.sport.representation.useFullProgress,
    });

    if (sportlineEvent.isOutright) {
      if (this.factoryOptions?.keepLiveOutrightsInList && sportlineEvent.type === SportlineType.Live) {
        preparedLeague.sportEvents.push(sportlineEvent);
        return;
      }

      preparedLeague.outrightEvents.push(sportlineEvent as OutrightSportlineEvent);
      return;
    }

    preparedLeague.sportEvents.push(sportlineEvent);
  }

  build(): SportElement<T>[] {
    return this.parseResponse().map((sportInfo): SportElement<T> => {
      const sportMarketTypes = new Map<string, MarketType>([]);
      const regions: RegionElement<T>[] = sportInfo.regions.map((regionInfo) => {
        const regionMarketTypes = new Map<string, MarketType>([]);
        const leagues: LeagueElement<T>[] = regionInfo.leagues.map((leagueInfo) => {
          const leagueMarketTypes = new Map<string, MarketType>([]);
          // eslint-disable-next-line max-len
          const processSportEvents = <SE extends SportlineEvent = T>(sportEvents: SE[]): SportlineEventElement<SE>[] => sportEvents
            .map((sportEvent: SportlineEvent) => {
              for (const market of sportEvent.markets) {
                sportMarketTypes.set(market.type.id, market.type);
                regionMarketTypes.set(market.type.id, market.type);
                leagueMarketTypes.set(market.type.id, market.type);
              }
              return { sportEvent } as SportlineEventElement<SE>;
            });
          const sportEvents = processSportEvents(leagueInfo.sportEvents);
          const outrightEvents = processSportEvents(leagueInfo.outrightEvents);

          return {
            key: `${leagueInfo.number}_${leagueInfo.league.league.id}`,
            league: leagueInfo.league.league,
            marketTypes: [...leagueMarketTypes.values()],
            sportEvents,
            outrightEvents,
            counters: addCountersToCounters(
              getSportEventElementsListCounters(sportEvents),
              getSportEventElementsListCounters(outrightEvents),
            ),
          } as LeagueElement<T>;
        });

        return {
          key: `${regionInfo.number}_${regionInfo.region.region.id}`,
          region: regionInfo.region.region,
          marketTypes: [...regionMarketTypes.values()],
          leagues,
          counters: reduceListWithSportEventsCounters(leagues),
        };
      });

      return {
        key: sportInfo.sport.sport.id,
        sport: sportInfo.sport.sport,
        regions,
        marketTypes: [...sportMarketTypes.values()],
        marketsColumns: sportInfo.sport.marketsColumns,
        counters: reduceListWithSportEventsCounters(regions),
      };
    });
  }
}
