import {
  assert, isString, isObject,
} from '@leon-hub/guards';
import { FormControlType } from '@leon-hub/form-utils';

import { isPartialFormPhoneValue, isFormWidget } from 'web/src/components/Form/guards';

import type { UiFieldParsedOptions } from '../types';

function modifyFieldPropertyTitleToLabel(properties: UiFieldParsedOptions): UiFieldParsedOptions {
  const { title } = properties;
  assert(isString(title));
  let options = properties.options ?? {};
  assert(isObject(options));
  if (title) {
    options = {
      label: title,
      ...options,
    };
  }
  return {
    ...properties,
    ...(Object.keys(options).length ? { options } : {}),
  };
}

function modifyFieldPropertiesForSelect(properties: UiFieldParsedOptions): UiFieldParsedOptions {
  const shouldReplaceDefaultValue = properties.default && properties.default === '-';
  return shouldReplaceDefaultValue ? {
    ...properties,
    default: '',
  } : properties;
}

function modifyFieldPropertiesForPhone(properties: UiFieldParsedOptions): UiFieldParsedOptions {
  const defaultValue = properties.default ?? { prefix: '', suffix: '' };
  assert(isPartialFormPhoneValue(defaultValue), 'valid phone fields must be present');
  return {
    ...properties,
    default: defaultValue,
  };
}

/** modify properties by widget type */
export default function modifyFieldPropertiesByWidget(properties: UiFieldParsedOptions): UiFieldParsedOptions {
  const { widget } = properties;
  assert(isFormWidget(widget));
  switch (widget) {
    case FormControlType.Date:
    case FormControlType.DropListSelect:
      return modifyFieldPropertyTitleToLabel(properties);
    case FormControlType.Select:
    case FormControlType.SelectWithSearch:
      return modifyFieldPropertiesForSelect(properties);
    case FormControlType.Phone:
    case FormControlType.PhoneInput:
    case FormControlType.PhoneCountriesSelectorInput:
      return modifyFieldPropertiesForPhone(properties);
    default:
      return properties;
  }
}
