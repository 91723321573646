import type { Ref, MaybeRef } from 'vue';
import { toRef, computed } from 'vue';

import type { LeagueId } from 'web/src/modules/sportline/types';

import useCustomerFavoritesService from './useCustomerFavoritesService';

export function useIsFavoriteLeague(leagueId?: MaybeRef<Optional<Maybe<LeagueId>>>): Ref<boolean> {
  const id = toRef(leagueId);
  const { customerOptimisticFavoriteLeaguesIds } = useCustomerFavoritesService();
  return computed(() => !!id.value && customerOptimisticFavoriteLeaguesIds.value.includes(id.value));
}
