import {
  defineStore,
} from 'pinia';
import {
  computed,
  ref,
  toRef,
  readonly,
} from 'vue';

import { QueryParameterName } from '@leon-hub/query-manager';
import type { SaveLogInput } from '@leon-hub/api-sdk';
import { LogLevel } from '@leon-hub/api-sdk';
import { assert, isArray } from '@leon-hub/guards';
import { getBuiltinConfig, getLocationHref, isOnline } from '@leon-hub/service-locator-env';
import { Locale } from '@leon-hub/locale';
import { useLocalStorageManager } from '@leon-hub/local-storage';
import {
  requestIdleCallback,
} from '@leon-hub/utils';

import { useCountryStore } from 'web/src/modules/country/store';
import { useAuthStore } from 'web/src/modules/auth/store';
import { useI18nStore } from 'web/src/modules/i18n/store';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import useBaseApiClient from 'web/src/modules/core/services/api/useBaseApiClient';
import setApiHeaders from 'web/src/modules/core/utils/setApiHeaders';

import { useSyncState } from './composables';
import { apiKeys } from './enums/apiKeys';
import type {
  RootSettingsDocument,
  Language,
  AvailableLanguagesInfo,
} from './types/rootStore';
import getLanguageFlagCode from './utils/getLanguageFlagCode';
import type { RootSettingInternationalReturnValue } from './utils';
import { makeRootSettingsRequest } from './utils';
import makeSaveLogRequest from './utils/makeSaveLogRequest';
import getAppAdHeader from './utils/getAppAdHeader';
import { useFontsStore } from './useFontsStore';

const useRootStore = defineStore('root', () => {
  const api = useGraphqlClient();
  const baseRestClient = useBaseApiClient();
  const localStorage = useLocalStorageManager();
  const { setLoggedIn } = useAuthStore();
  const I18nStore = useI18nStore();
  const { setUrlPrefixes, setLocale } = I18nStore;
  const locale = toRef(I18nStore, 'locale');
  const isFontsLoaded = toRef(useFontsStore(), 'isFontsLoaded');
  const { setInitialCountryModuleData } = useCountryStore();

  const initUrl = ref(getLocationHref());

  // state
  const cTag = ref<string>('');

  const slipDefaultAmount = ref<number>(0);

  const imageCdnUrl = ref<string>('');

  const defaultUICurrency = ref<string>('');

  const ssoServerUrl = ref<string>('');

  const isDevIP = ref<boolean>(getBuiltinConfig().isNT);

  const isAppMainContentLoadedInner = ref<boolean>(false);

  // former state.availableLanguages
  const availableLanguagesInfo = ref<AvailableLanguagesInfo>([]);

  const formatMoneyLocale = ref<string>('');

  const isContentSelectionDisabled = ref<boolean>(false);

  const desktopScreenMinWidth = ref<number>(0);

  const isAppMainContentLoaded = computed<boolean>(() => (
    isAppMainContentLoadedInner.value && isFontsLoaded.value
  ));

  // @see LEONWEB-6203
  const runtimePlatform = ref<null | string>(
    (new URL(getLocationHref())).searchParams.get(QueryParameterName.PLATFORM),
  );

  // indicates that route was opened as a direct link, e.g. leon.bet/connection-error
  const isDirectLink = ref<boolean>(true);

  // getters
  /** TODO: nice to have separate store and hooks for languages */

  const currentLanguageName = computed<string>(
    () => availableLanguagesInfo.value.find((lang) => lang.code === locale.value)?.name || '',
  );

  const availableLanguages = computed<Language[]>(() => availableLanguagesInfo.value.map((lang) => ({
    text: lang.name,
    value: getLanguageFlagCode(lang.code),
    selected: lang.code === locale.value,
    prefix: lang.prefix || undefined,
    code: lang.code,
    flagCode: lang.flag || getLanguageFlagCode(lang.code),
  })));

  const currentLanguageFlag = computed(() => (
    availableLanguages.value.find((language) => language.code === locale.value)?.flagCode || ''
  ));

  const isLanguageSwitcherAvailable = computed<boolean>(
    () => availableLanguages.value.length > 1,
  );

  // mutations
  const setDirectLinkState = (value: boolean) => {
    isDirectLink.value = value;
  };

  const setCTag = (value: string): void => {
    cTag.value = value;
  };

  const setSlipDefaultAmount = (value: number): void => {
    slipDefaultAmount.value = value;
  };

  const setImageCdnUrl = (value: string): void => {
    imageCdnUrl.value = value;
  };

  const setDefaultUICurrency = (value: string): void => {
    defaultUICurrency.value = value;
  };

  const setSsoUrl = (value: string | undefined | null): void => {
    ssoServerUrl.value = value || '';
  };

  const setDevIP = (value: boolean): void => {
    isDevIP.value = value;
  };

  const setAppMainContentLoaded = (): void => {
    isAppMainContentLoadedInner.value = true;
  };

  // kinda private
  const setAvailableLanguagesInfo = (info: AvailableLanguagesInfo): void => {
    availableLanguagesInfo.value = info;
  };

  // kinda private
  const setFormatMoneyLocale = (value: string | undefined | null): void => {
    formatMoneyLocale.value = value || '';
  };

  // kinda private
  const setDesktopScreenMinWidth = (value: number): void => {
    desktopScreenMinWidth.value = value ?? 0;
  };

  const setIsContentSelectionDisabled = (value: boolean): void => {
    isContentSelectionDisabled.value = value;
  };

  function handleData(response: RootSettingsDocument): void {
    setCTag(response.ctag);
    setSlipDefaultAmount(response.slipDefaultAmount ?? 0);
    setImageCdnUrl(response.imageCdnUrl);
    setSsoUrl(response.ssoServerUrl);
    setDevIP(response.isNT);
    setFormatMoneyLocale(response.formatMoneyLocale);
    setDesktopScreenMinWidth(response.desktopScreenMinWidth);

    function handleRootSettingsInternational(internationalResponse: RootSettingInternationalReturnValue) {
      if (internationalResponse.countryCode && internationalResponse.countriesData?.length) {
        setInitialCountryModuleData({
          countryCode: internationalResponse.countryCode,
          countriesData: internationalResponse.countriesData,
        });
      }

      setUrlPrefixes({
        currentPrefix: internationalResponse.urlLocale || '',
        replacedPrefix: internationalResponse.replacedUrlPart || '',
      });
      if (internationalResponse.currentLanguageTag) {
        assert(
          internationalResponse.currentLanguageTag.includes('_'),
          `language tag has wrong format: ${internationalResponse.currentLanguageTag}`,
        );

        setLocale(internationalResponse.currentLanguageTag);
      }
      assert(isArray(internationalResponse.availableLanguages), 'availableLanguages must be an array');
      setAvailableLanguagesInfo(internationalResponse.availableLanguages || []);
    }

    if (process.env.VUE_APP_FEATURE_MULTIPLE_LOCALES_ENABLED) {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
      handleRootSettingsInternational(response as RootSettingInternationalReturnValue);
    } else {
      setLocale(Locale.RU_RU);
    }
    if (response.defaultUICurrency) {
      setDefaultUICurrency(response.defaultUICurrency);
    }

    setLoggedIn(response.isCustomerLoggedIn, true);
  }

  // actions
  async function fetchData(silent?: boolean) {
    return handleData(await makeRootSettingsRequest(silent));
  }

  async function log(input: SaveLogInput): Promise<void> {
    const isLoggable = isOnline() && (isDevIP.value || (input.level !== LogLevel.DEBUG));

    if (isLoggable) {
      requestIdleCallback(() => {
        makeSaveLogRequest(input);
      });
    }

    return Promise.resolve();
  }

  function setInitialApiSettings(): void {
    const clientApi1Url = api.getBaseUrl();
    const clientApi2Url = baseRestClient.getBaseUrl();
    const clientApi3Url = baseRestClient.getBaseUrl();

    const savedApi1Url = localStorage.getItem(apiKeys.api1Url);
    const savedApi2Url = localStorage.getItem(apiKeys.api2Url);
    const savedApi3Url = localStorage.getItem(apiKeys.api3Url);

    if (savedApi1Url && clientApi1Url !== savedApi1Url) {
      api.setBaseUrl(savedApi1Url);
    }

    if (savedApi2Url && clientApi2Url !== savedApi2Url) {
      baseRestClient.setBaseUrl(savedApi2Url);
    }

    if (savedApi3Url && clientApi3Url !== savedApi3Url) {
      baseRestClient.setBaseUrl(savedApi3Url);
    }
  }

  function setAppAdHeader(value: string): void {
    setApiHeaders({ 'x-app-ad': value });
  }

  function updateApiSettings(apiUrlSettings: { api1Url: string; api2Url: string; api3Url: string }): void {
    api.setBaseUrl(apiUrlSettings.api1Url);
    baseRestClient.setBaseUrl(apiUrlSettings.api2Url);
    baseRestClient.setBaseUrl(apiUrlSettings.api3Url);

    localStorage.setItem(apiKeys.api1Url, apiUrlSettings.api1Url);
    localStorage.setItem(apiKeys.api2Url, apiUrlSettings.api2Url);
    localStorage.setItem(apiKeys.api3Url, apiUrlSettings.api3Url);
  }

  // sync state

  useSyncState(async (silent) => {
    await fetchData(silent);
  }, 'root');

  // former init

  function initRootStore(): void {
    if (process.env.VUE_APP_PLATFORM_WEB) {
      const appAdHeader = getAppAdHeader();
      if (appAdHeader) {
        setAppAdHeader(appAdHeader);
      }
    }

    setInitialApiSettings();
  }

  void initRootStore();

  return {
    // state/getters
    cTag,
    slipDefaultAmount,
    imageCdnUrl,
    defaultUICurrency,
    ssoServerUrl,
    isDevIP,
    isAppMainContentLoaded,
    formatMoneyLocale,
    isContentSelectionDisabled,
    desktopScreenMinWidth,
    currentLanguageName,
    currentLanguageFlag,
    availableLanguages,
    isLanguageSwitcherAvailable,
    initUrl,
    isDirectLink: readonly(isDirectLink),
    runtimePlatform: readonly(runtimePlatform),
    // former mutations, exported only for tests (is it reasonable?)
    setCTag,
    setImageCdnUrl,
    setDefaultUICurrency,
    setSsoUrl,
    setDevIP,
    // actions/mutations
    setDirectLinkState,
    setIsContentSelectionDisabled,
    fetchData,
    handleData,
    log,
    setAppAdHeader,
    updateApiSettings,
    setAppMainContentLoaded,
  };
});

export default useRootStore;
