import type { Ref } from 'vue';
import {
  ref,
  toRef,
  watch,
  computed,
} from 'vue';

import { replace } from '@leon-hub/utils';

import { useSportlineWidgetsStore } from 'web/src/modules/sportline/submodules/widgets/store';
import type { LiveWidget } from 'web/src/modules/sportline/types';
import type { WidgetLocale } from 'web/src/modules/sportline/submodules/widgets/store/types';
import { IframeWidget } from 'web/src/modules/framed-app/enums';
import { BetgeniusFramedWidgetProperties } from 'web/src/modules/sportline/submodules/widgets/enums';
import { useFramedWidgetUrl } from 'web/src/modules/widgets/composables/useFramedWidgetUrl';

interface UseBetgeniusWidgetProps {
  isActive: Ref<boolean>;
  liveWidget: Ref<LiveWidget>;
  locale: Ref<string>;
}

interface UseBetgeniusWidgetComposable {
  readyToLoading: Ref<boolean>;
  iframeWidgetSrc: Ref<string | undefined>;
  additionalQueryStringParameters: Ref<Record<string, string>[]>;
}

function getWidgetLocale(widgetLocales: WidgetLocale[], locale: string): string {
  const customerLocale = locale.replace('_', '-').toLowerCase();

  return widgetLocales.find((value) => value.locale === customerLocale)?.locale
    ?? widgetLocales.find((value) => value.default)?.locale
    ?? '';
}

export function useBetgeniusWidget(
  props: UseBetgeniusWidgetProps,
): UseBetgeniusWidgetComposable {
  const {
    isActive,
    liveWidget,
    locale,
  } = props;

  const betgeniusWidgetConfig = toRef(useSportlineWidgetsStore(), 'betgeniusConfigFinal');

  const readyToLoading = ref<boolean>(false);

  const iframeWidgetSrc = useFramedWidgetUrl(IframeWidget.Betgenius);
  const additionalQueryStringParameters = computed<Record<string, string>[]>(() => {
    const config = betgeniusWidgetConfig.value;
    const url = config?.active && config?.productionUrl
      ? replace(
        config.productionUrl,
        {
          fixtureId: liveWidget.value.id,
          locale: getWidgetLocale(config.widgetLocales, config.customLang || locale.value),
        },
      )
      : null;
    return url ? [{ [BetgeniusFramedWidgetProperties.EncodedUrl]: encodeURIComponent(url) }] : [];
  });

  watch(isActive, (value: boolean) => {
    if (!value) { return; }
    readyToLoading.value = true;
  }, { immediate: true });

  return {
    readyToLoading,
    iframeWidgetSrc,
    additionalQueryStringParameters,
  };
}
