import type {
  getReferralQuery, getDetailsQuery, getRewardsQuery, getAvailabilityQuery, getSiteConfigSettingsQuery, doRewardingQueryVariables,
} from '@leon-hub/api-sdk';

// eslint-disable-next-line max-len
export type ReferralDataGetDataDocument = Exclude<getReferralQuery['queries']['refProgram']['getReferral']['data'], null>;
// eslint-disable-next-line max-len
export type ProgramDetailsGetDataDocument = Exclude<getDetailsQuery['queries']['refProgram']['getDetails']['data'], null>;
// eslint-disable-next-line max-len
export type RewardsGetDataDocument = Exclude<getRewardsQuery['queries']['refProgram']['getRewards']['data'], null>;
// eslint-disable-next-line max-len
export type AvailabilityGetDataDocument = Exclude<getAvailabilityQuery['queries']['refProgram']['getAvailability']['data'], null>;

export type Referral = ProgramDetailsGetDataDocument['referrals'][0];

export type Requirement = Referral['requirements'][0];

// eslint-disable-next-line max-len
export type SocialSharingNetworksGetDataDocument = Exclude<getSiteConfigSettingsQuery['queries']['system']['siteConfig']['data'], null>;
export type SocialSharingNetworks = SocialSharingNetworksGetDataDocument['profile']['referralProgramSocialItems'];
export type RewardOptions = doRewardingQueryVariables['options'];

export interface ExtendedRequirement extends Requirement {
  label: string;
}

export interface ExtendedReferral extends Referral {
  initials: string;
  date: string;
  longDate: string;
  formattedAmount: string;
  amount: number;
  name: string;
  isIncognito: boolean;
  currentStep?: number;
  totalSteps?: number;
}
export enum ReferralProgramViewMode {
  MAIN = 'MAIN',
  LIST = 'LIST',
  LIST_BONUSES = 'LIST_BONUSES',
  DETAILS = 'DETAILS',
}
