import type { Ref } from 'vue';
import { computed, ref } from 'vue';

import type {
  SportsSegmentPageListGroup,
  LiveSportSegmentPageListGroup,
  PrematchSportSegmentPageListGroup,
} from 'web/src/modules/sportline/submodules/segment/types';

interface UseLiveSportTabProps {
  group: Ref<SportsSegmentPageListGroup>;
}

interface UseLiveSportTabComposable {
  isExpandAllByDefault: Ref<boolean>;
  liveGroup: Ref<Maybe<LiveSportSegmentPageListGroup>>;
  prematchGroup: Ref<Maybe<PrematchSportSegmentPageListGroup>>;
}

export function useLiveSportTab(
  props: UseLiveSportTabProps,
): UseLiveSportTabComposable {
  const { group } = props;

  // LEONWEB-2140
  const isExpandAllByDefault = ref(!!process.env.VUE_APP_PRERENDER);

  const liveGroup = computed<Maybe<LiveSportSegmentPageListGroup>>(() => (
    !!group.value && !group.value.isEmpty && !!group.value.liveElement
      ? group.value as LiveSportSegmentPageListGroup
      : null
  ));

  const prematchGroup = computed<Maybe<PrematchSportSegmentPageListGroup>>(() => (
    !!group.value && !group.value.isEmptyComing && !!group.value.prematchElement
      ? group.value as PrematchSportSegmentPageListGroup
      : null
  ));

  return {
    isExpandAllByDefault,
    liveGroup,
    prematchGroup,
  };
}
