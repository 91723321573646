import type { Ref } from 'vue';
import { useRouter } from 'vue-router';
import {
  computed, ref, toRef,
} from 'vue';

import { RouteName } from '@leon-hub/routing-config-names';
import { IconName } from '@leon-hub/icons';
import { PaymentType } from '@leon-hub/api-sdk';

import {
  useIdentificationPhase,
  useIdentificationService,
} from 'web/src/modules/identification';
import { IdentificationPageLoadParameters, IdentificationPhase } from 'web/src/modules/identification/enums';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import StringUtils from 'web/src/utils/StringUtils';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
import { useI18n } from 'web/src/modules/i18n/composables';
import { PaymentsIdentificationButtonName } from 'web/src/modules/payments/enums';
import type { PaymentsIdentificationStatusProperties } from 'web/src/modules/payments/types/documentTypes';

import useRouterStore from '../../core/store/useRouterStore';

export interface PaymentsIdentificationComposable {
  identificationStatusProperties: Ref<PaymentsIdentificationStatusProperties>;
  onIdentificationStatusButtonClick(id: PaymentsIdentificationButtonName): void;
  isIdentifiedUser: Ref<boolean>;
  showIdentificationStatus: Ref<boolean>;
  waitForGettingIdentificationState(): Promise<void>;
  finishCustomerIdentification(): Promise<void>;
  showIdentificationStatusModal(): Promise<void>;
  onBeforeDetailsPageMounted(): Promise<void>;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export default function usePaymentsIdentification(paymentType: PaymentType): PaymentsIdentificationComposable {
  const { $translate } = useI18n();
  const routerStore = useRouterStore();
  const previousRouteName = toRef(() => routerStore.previousRouteName);

  if (!process.env.VUE_APP_FEATURE_IDENT_ENABLED) {
    return {
      isIdentifiedUser: ref(true),
      showIdentificationStatus: ref(false),
      identificationStatusProperties: computed(() => ({
        buttonsProperties: [
          {
            id: PaymentsIdentificationButtonName.RETRY,
            label: $translate('JS_MOBILE_IDENT_HUB_ESIA_RETRY_BTN').value,
            kind: ButtonKind.PRIMARY,
            fullWidth: true,
          },
        ],
      })),
      onIdentificationStatusButtonClick: () => {},
      waitForGettingIdentificationState: () => Promise.resolve(),
      finishCustomerIdentification: () => Promise.resolve(),
      showIdentificationStatusModal: () => Promise.resolve(),
      onBeforeDetailsPageMounted: () => Promise.resolve(),
    };
  }

  const router = useRouter();
  const {
    setIdentificationLoadPageParameters,
    waitForGettingIdentificationState,
    finishCustomerIdentification,
    showIdentificationStatusModal,
  } = useIdentificationService();

  const paymentTypeToRouteName = {
    [PaymentType.DEPOSIT]: RouteName.DEPOSITS,
    [PaymentType.WITHDRAWAL]: RouteName.WITHDRAWALS,
  };

  const { identificationPhase, isIdentifiedUser } = useIdentificationPhase();
  const customerDataStore = useCustomerDataStore();

  const phoneDecodedString = toRef(() => customerDataStore.phoneDecodedString);

  const mobile = computed(() => ({
    mobile: StringUtils.useUnbreakableCharacters(phoneDecodedString.value),
  }));

  const blockedTitle = $translate('WEB2_TSUPIS_LOCKED_CUSTOMER_ERROR_TITLE', mobile);

  const identificationStatusProperties = computed(() => {
    switch (identificationPhase.value) {
      case IdentificationPhase.InProgress:
        return {
          buttonsProperties: [
            {
              id: PaymentsIdentificationButtonName.CHECK_STATUS,
              label: $translate('WEB2_IDENTIFICATION_CHECK_STATUS_BUTTON').value,
              kind: ButtonKind.PRIMARY,
              fullWidth: true,
            },
          ],
          title: $translate('WEB2_IDENTIFICATION_IN_PROGRESS_MODAL_TITLE').value,
          text: $translate('WEB2_IDENTIFICATION_IN_PORGRESS_FOR_DEPOSITS_MODAL').value,
        };

      case IdentificationPhase.UnregisteredWalletId:
        return {
          buttonsProperties: [{
            id: PaymentsIdentificationButtonName.RETRY,
            label: $translate('JS_MOBILE_IDENT_HUB_ESIA_RETRY_BTN').value,
            kind: ButtonKind.PRIMARY,
            fullWidth: true,
            iconName: IconName.SHIELD,
          }],
          title: $translate('WEB2_UNREGISTERED_WALLET_ID_ERROR_TITLE').value,
          text: $translate('WEB2_UNREGISTERED_WALLET_ID_ERROR_MESSAGE').value,
        };

      case IdentificationPhase.Blocked: {
        return {
          title: blockedTitle.value,
          cmsKey: 'WEB2_TSUPIS_LOCKED_CUSTOMER_ERROR_DESCRIPTION',
          buttonsProperties: [{
            id: PaymentsIdentificationButtonName.CLOSE,
            label: $translate('WEB2_CLOSE').value,
            kind: ButtonKind.PRIMARY,
            fullWidth: true,
          }],
        };
      }

      case IdentificationPhase.RemovedAccount: {
        return {
          title: $translate('WEB2_TSUPIS_ACCOUNT_REMOVED_ERROR_TITLE').value,
          text: $translate('WEB2_TSUPIS_ACCOUNT_REMOVED_ERROR_TO_SUPPORT_DESCRIPTION').value,
          buttonsProperties: [{
            id: PaymentsIdentificationButtonName.TO_SUPPORT,
            label: $translate('WEB2_ERROR_BTN_SUPPORT').value,
            kind: ButtonKind.PRIMARY,
            fullWidth: true,
          }],
        };
      }

      case IdentificationPhase.PassportExpired: {
        return {
          buttonsProperties: [{
            id: PaymentsIdentificationButtonName.RETRY,
            label: $translate('JS_MOBILE_IDENT_HUB_ESIA_RETRY_BTN').value,
            kind: ButtonKind.PRIMARY,
            fullWidth: true,
            iconName: IconName.SHIELD,
          }],
          title: $translate('WEB2_IDENTIFICATION_PASSPORT_EXPIRED_ERROR_TITLE').value,
          text: $translate('WEB2_IDENTIFICATION_PASSPORT_EXPIRED_ERROR_DESCRIPTION').value,
        };
      }

      default:
        return {
          buttonsProperties: [{
            id: PaymentsIdentificationButtonName.RETRY,
            label: $translate('JS_MOBILE_IDENT_HUB_ESIA_RETRY_BTN').value,
            kind: ButtonKind.PRIMARY,
            fullWidth: true,
            iconName: IconName.SHIELD,
          }],
          title: $translate('JS_U_NOT_IDENTIFIED').value,
          text: $translate('WEB2_IDENTIFICATION_NEEDED_FOR_DEPOSITS').value,
        };
    }
  });

  function onIdentificationStatusButtonClick(id: PaymentsIdentificationButtonName): void {
    if (id === PaymentsIdentificationButtonName.CLOSE) {
      if (process.env.VUE_APP_LAYOUT_DESKTOP) {
        void router.closeModal();
      } else {
        router.back();
      }
      return;
    }

    if (id === PaymentsIdentificationButtonName.TO_SUPPORT) {
      void router.push({ name: RouteName.SUPPORT });
      return;
    }

    setIdentificationLoadPageParameters([IdentificationPageLoadParameters.SkipInfo]);
    void router.push({ name: RouteName.IDENTIFICATION });
  }

  async function onBeforeDetailsPageMounted(): Promise<void> {
    await waitForGettingIdentificationState();
    if (!isIdentifiedUser.value) {
      await (
        process.env.VUE_APP_LAYOUT_DESKTOP
            && previousRouteName.value !== paymentTypeToRouteName[paymentType]
          ? showIdentificationStatusModal()
          : router.back());
    }
  }

  return {
    identificationStatusProperties,
    onIdentificationStatusButtonClick,
    showIdentificationStatus: computed(() => !isIdentifiedUser.value),
    isIdentifiedUser,
    waitForGettingIdentificationState,
    finishCustomerIdentification,
    showIdentificationStatusModal,
    onBeforeDetailsPageMounted,
  };
}
