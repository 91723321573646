import type { Ref } from 'vue';
import { computed } from 'vue';

import type { SportEventMoreCount } from 'web/src/modules/sportline/types';

interface UseMoreCaptionCountProps {
  sportlineEvent: Ref<{ moreCount: SportEventMoreCount }>;
  isUsedMoreRunnersCount?: Ref<boolean>;
}

interface UseMoreCaptionCountComposable {
  totalCount: Ref<number>;
}

export function useMoreCaptionCount(
  props: UseMoreCaptionCountProps,
): UseMoreCaptionCountComposable {
  const { sportlineEvent, isUsedMoreRunnersCount } = props;

  const moreCount = computed<SportEventMoreCount>(() => sportlineEvent.value.moreCount);
  const totalCount = computed(() => (isUsedMoreRunnersCount?.value
    ? (moreCount.value?.runners || 0)
    : (moreCount.value?.markets || 0)));

  return { totalCount };
}
