import { addBreadcrumb } from '@leon-hub/sentry';

export function addPageLifeCycleBreadcrumb(eventName: string, additionalData: Record<string, string | number | boolean | undefined> = {}): void {
  addBreadcrumb({
    category: 'page-lifecycle',
    message: `${eventName} event triggered`,
    level: 'info',
    data: {
      ...additionalData,
    },
  });
}
