import { Locale, isLocale } from '@leon-hub/locale';

export default class LanguageMicroSettings {
  public static plural(words: string[], number: number, locale: string): string {
    if (isLocale(locale, Locale.RU_RU) || locale.includes('ru')) {
      return getRussianPluralForm(words, number);
    }

    if (isLocale(locale, Locale.EN_US) || isLocale(locale, Locale.EN_IN) || isLocale(locale, Locale.EN_GB)) {
      return getEnglishPluralForm(words, number);
    }

    return getDefaultPluralForm(words, number);
  }
}

function getRussianPluralForm(words: string[], number: number): string {
  let n = Math.abs(number) % 100;
  if (n >= 5 && n <= 20) {
    return words[2];
  }
  n %= 10;
  if (n === 1) {
    return words[0];
  }
  if (n >= 2 && n <= 4) {
    return words[1];
  }
  return words[2];
}

function getEnglishPluralForm(words: string[], number: number): string {
  if (words.length === 4) {
    let n = Math.abs(number) % 100;
    if (n >= 11 && n <= 13) {
      return words[3];
    }
    n = number % 10;
    if (n === 1) {
      return words[0];
    }
    if (n === 2) {
      return words[1];
    }
    if (n === 3) {
      return words[2];
    }
    return words[3];
  }
  return number === 1 ? words[0] : words[1];
}

function getDefaultPluralForm(words: string[], number: number): string {
  if (number === 1) {
    return words[0];
  }
  return words[1];
}
