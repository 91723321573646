<script lang="ts" setup>
import { inject } from 'vue';

import SwiperNavigationButtonsBase
  from 'web/src/components/Swiper/Navigation/SwiperNavigationButtonsBase/SwiperNavigationButtonsBase.vue';

const moveToNextSlide = inject<() => void>('moveToNextSlide');
const moveToPreviousSlide = inject<() => void>('moveToPreviousSlide');
</script>

<template>
  <SwiperNavigationButtonsBase v-auto-id="'VFadeCarouselNavigationButtons'"
    is-rounded
    is-next-allowed
    is-previous-allowed
    @click-previous="moveToPreviousSlide"
    @click-next="moveToNextSlide"
  />
</template>
