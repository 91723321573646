<script lang="ts" setup>
import { computed } from 'vue';

import {
  IconDirection,
  IconName,
  IconSize,
} from '@leon-hub/icons';

import type { VIconProps } from '@components/v-icon';
import { VIcon } from '@components/v-icon';

interface ArrowIconProps {
  direction?: IconDirection;
  size?: IconSize;
}

interface ArrowIconEmits {
  (e: 'click', event: PointerEvent): void;
}

const props = withDefaults(defineProps<ArrowIconProps>(), {
  direction: IconDirection.WEST,
  size: IconSize.SIZE_20,
});
const emit = defineEmits<ArrowIconEmits>();

const iconProperties = computed<VIconProps>(() => ({
  name: IconName.ARROW,
  direction: props.direction,
  size: props.size,
}));

function onClick(event: PointerEvent): void {
  emit('click', event);
}
</script>

<template>
  <VIcon v-auto-id="'ArrowIcon'"
    v-bind="iconProperties"
    @click="onClick"
  />
</template>
