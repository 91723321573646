import type {
  CasinoLevelSettingWithStatus,
  CasinoLoyaltyProgramListProperties,
} from 'web/src/modules/casino-loyalty-program/store/types';

export default function getProgramListProperties(
  selectedLevel: Optional<Pick<CasinoLevelSettingWithStatus,
    'bonusAmount' |
    'multiplier' |
    'freespinCount' |
    'bonusPointsCount' |
    'status' |
    'freespinAvailable' |
    'freespinSpent' |
    'freespinGameId' |
    'freespinProvider'
  >>,
): CasinoLoyaltyProgramListProperties {
  return {
    bonusAmount: selectedLevel?.bonusAmount || 0,
    multiplier: selectedLevel?.multiplier || 0,
    freespin: selectedLevel?.freespinCount || 0,
    bonusPointsCount: selectedLevel?.bonusPointsCount || 0,
    status: selectedLevel?.status,
    freespinAvailable: selectedLevel?.freespinAvailable,
    freespinSpent: selectedLevel?.freespinSpent,
    freespinGameId: selectedLevel?.freespinGameId || undefined,
    freespinProvider: selectedLevel?.freespinProvider || undefined,
  };
}
