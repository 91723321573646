import {
  isString, isOptionalString, isObject, isUndefined, isOptionalBoolean,
} from '@leon-hub/guards';

import type { FormWrapperWidgetProperties } from '../types';
import isFormDataValue from './isFormDataValue';

export function isFormWrapperWidgetProperties(value: unknown): value is FormWrapperWidgetProperties {
  if (!isObject(value)) {
    return false;
  }
  return isString(value.name)
    && isOptionalString(value.title)
    && isOptionalString(value.hintMessage)
    && isOptionalBoolean(value.disabled)
    && isOptionalBoolean(value.autofocus)
    && (isUndefined(value.value) || isFormDataValue(value.value))
    && (isUndefined(value.extraProperties) || isObject(value.extraProperties))
    && (isUndefined(value.options) || isObject(value.options));
}

export function isFormWrapperWidgetPropertiesByType<T>(
  value: unknown, optionsGuard: (options: unknown) => options is T,
): value is FormWrapperWidgetProperties<T> {
  return isFormWrapperWidgetProperties(value) && optionsGuard(value.options);
}
