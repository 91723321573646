import {
  CustomerHistoryFilterType,
  HistoryFilter,
} from '@leon-hub/api-sdk';

import type { RequiredCustomerHistoryRequest } from 'web/src/modules/profile/submodules/customer-history/types';

// eslint-disable-next-line import/prefer-default-export
export const DEFAULT_ORDER_FILTER: RequiredCustomerHistoryRequest = Object.freeze({
  filter: HistoryFilter.ALL,
  dateType: CustomerHistoryFilterType.HOUR,
  dateRange: 24,
  page: 0,
  rows: 25,
});
