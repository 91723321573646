import type { Ref } from 'vue';
import { computed } from 'vue';

import type { BetlineLeagueStanding } from 'web/src/modules/sportline/types/rest';

interface UseLeagueStandingsProps {
  betlineLeagueStandings: Ref<Maybe<BetlineLeagueStanding[]>>;
}

interface UseLeagueStandingsComposable {
  leagueStandings: Ref<BetlineLeagueStanding[]>;
}

export function useLeagueStandings(
  props: UseLeagueStandingsProps,
): UseLeagueStandingsComposable {
  const leagueStandings = computed<BetlineLeagueStanding[]>(() => props.betlineLeagueStandings.value ?? []);

  return {
    leagueStandings,
  };
}
