import type { Ref } from 'vue';
import { computed, toRef } from 'vue';

import { RegistrationStage } from 'web/src/modules/registration/enums';
import { useSimplifiedRegistrationStore } from 'web/src/modules/registration/submodules/simplified-registration/store';
import { useRegistrationComplete } from 'web/src/modules/registration/store/composables/useRegistrationComplete';

interface useRegistrationProgressComposable {
  isNotFinished: Ref<boolean>;
}

export function useRegistrationProgress(): useRegistrationProgressComposable {
  const registrationComplete = useRegistrationComplete();
  if (process.env.VUE_APP_FEATURE_STEP_REGISTRATION_ENABLED) {
    // REGISTRATION STORE AND PROGRESS FOR SLOTT
    const isRegistrationComplete = toRef(registrationComplete, 'value');

    return {
      isNotFinished: computed(() => !isRegistrationComplete.value),
    };
  }
  // REGISTRATION STORE AND PROGRESS FOR LEON
  const store = useSimplifiedRegistrationStore();
  const registrationStage = toRef(store, 'stage');
  return {
    isNotFinished: computed(() => registrationStage.value !== RegistrationStage.Success && registrationStage.value !== RegistrationStage.ChangePasswordSuccess),
  };
}
