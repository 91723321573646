import {
  isBoolean,
  isNumber,
  isString,
  assert,
} from '@leon-hub/guards';

function toString(value: string, defaultValue: string) {
  return isString(value) ? value : defaultValue;
}

function toNumber(value: string, defaultValue: number) {
  const numberValue = Number(value);
  if (Number.isFinite(numberValue) && String(numberValue) === value) {
    return numberValue;
  }
  return defaultValue;
}

function toBoolean(value: string, defaultValue: boolean) {
  if (value === 'true') {
    return true;
  }
  if (value === 'false') {
    return false;
  }
  return defaultValue;
}

function getQueryParameter<R extends string | boolean | number>(
  searchParameters: URLSearchParams,
  key: string,
  type: 'string' | 'number' | 'boolean',
  defaultValue: R,
): string | boolean | number | null {
  const value = searchParameters.get(key);
  if (isString(value)) {
    if (type === 'string') {
      assert(isString(defaultValue));
      return toString(value, defaultValue);
    }
    if (type === 'number') {
      assert(isNumber(defaultValue));
      return toNumber(value, defaultValue);
    }
    if (type === 'boolean') {
      assert(isBoolean(defaultValue));
      return toBoolean(value, defaultValue);
    }
  }
  return defaultValue;
}

export default getQueryParameter;
