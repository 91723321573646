import type { VScrollbarProps } from '../types';

export default function getTestDataAttrs(
  elementName: string,
  props: VScrollbarProps,
): Record<string, string> {
  const base: Record<string, string> = {
    el: elementName,
  };
  if (props.testEl) {
    base.source = props.testEl;
  }
  return base;
}
