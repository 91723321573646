import type { Ref } from 'vue';
import {
  computed,
  ref,
} from 'vue';
import { useRoute } from 'vue-router';

import useLayoutDesktopInfo from './useLayoutDesktopInfo';

export interface UseLayoutInfo {
  isFullWidth: Ref<boolean>;
  isLanding: Ref<boolean>;
  isBlank: Ref<boolean>;
}

export default function useLayoutInfo(): UseLayoutInfo {
  const currentRoute = useRoute();

  let isFullWidth: Ref<boolean>;
  if (process.env.VUE_APP_LAYOUT_DESKTOP) {
    isFullWidth = useLayoutDesktopInfo().isFullWidth;
  } else {
    isFullWidth = ref(false);
  }

  const isLanding = computed(() => !!currentRoute.meta.isLanding);
  const isBlank = computed(() => !!currentRoute.meta.isBlank);

  return {
    isFullWidth,
    isLanding,
    isBlank,
  };
}
