import type { BetInfoObject, SlipEntry } from '../types';
import getSlipEntryId from '../../../utils/getSlipEntryId';

interface Output {
  updatedEntries: SlipEntry[];
  updatedBetsInfo: BetInfoObject;
}

export default function getItemsForUpdatedPrice(entries: SlipEntry[], betsInfo: BetInfoObject): Output {
  const updatedBetsInfo = { ...betsInfo };
  const updatedEntries = entries.map((item) => {
    const id = getSlipEntryId(item);
    updatedBetsInfo[id] = {
      ...updatedBetsInfo[id],
      originalOdds: item.odds,
    };
    return {
      ...item,
      marketStatus: null,
    };
  });
  return {
    updatedEntries,
    updatedBetsInfo,
  };
}
