import { defineStore } from 'pinia';
import type { Ref } from 'vue';
import { readonly, ref } from 'vue';

interface UseRegistrationGeneratedPassword {
  value: Ref<string>;
  set(password: string): void;
}

export const useRegistrationGeneratedPassword = defineStore('registration-generated-password', (): UseRegistrationGeneratedPassword => {
  const generatedPassword = ref('');
  return {
    value: readonly(generatedPassword),
    set(password: string): void {
      generatedPassword.value = password;
    },
  };
});
