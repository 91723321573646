import { SlipStatus } from '@leon-hub/api-sdk';

import type { BatchedSlipInfoDocument } from '../types';
import isEntriesHasLimitDuplicates from './isEntriesHasLimitDuplicates';

export default function shouldForceSingleMode(batchedSlipInfo: BatchedSlipInfoDocument): boolean {
  const { slipEntries } = batchedSlipInfo;
  if (slipEntries.length > 1) {
    return false;
  }
  const { status, entries } = slipEntries[0];
  switch (status) {
    case SlipStatus.OK:
      return false;
    case SlipStatus.DUPLICATE_COMPETITOR:
    case SlipStatus.DUPLICATE_LEAGUE:
    case SlipStatus.DUPLICATE_EVENT:
    case SlipStatus.DUPLICATE_SPORT:
    case SlipStatus.SINGLE_BETTING_ONLY:
    case SlipStatus.INCOMPATIBLE_BETLINES:
    case SlipStatus.INCOMPATIBLE_LEAGUES:
    case SlipStatus.INCOMPATIBLE_SPORTS:
      return true;
    default:
      return isEntriesHasLimitDuplicates(entries);
  }
}
