import { defineStore } from 'pinia';

import { useLocalStorageManager } from '@leon-hub/local-storage';

import { useCookies } from 'web/src/modules/core/composables';
import { BiometricAuthStorageKey } from 'web/src/modules/biometric-auth/store/enums';

import useInitAppConfigStore from './useInitAppConfigStore';
import { StorageKeys } from './enums';

const storageKeysToSave: string[] = process.env.VUE_APP_PLATFORM_CORDOVA
  ? Object.values(BiometricAuthStorageKey)
  : [];

const useStorageManagerStore = defineStore('storage-manager', () => {
  const { themeConfig } = useInitAppConfigStore();
  const cookieManager = useCookies();
  const localStorageManager = useLocalStorageManager();

  const initValues: Record<string, string> = {};

  if (themeConfig.current !== undefined) {
    initValues[StorageKeys.THEME] = themeConfig.current;
  }

  if (themeConfig.first !== undefined) {
    initValues[StorageKeys.FIRST_THEME] = themeConfig.first;
  }

  async function set(key: string, value: string): Promise<void> {
    if (initValues[key] !== value) {
      initValues[key] = value;

      localStorageManager.setItem(key, value);

      await cookieManager.set({
        name: key,
        value,
      });
    }
  }

  async function get(key: string): Promise<string> {
    if (initValues[key] !== undefined) {
      return initValues[key];
    }

    const item = process.env.VUE_APP_PLATFORM_CORDOVA
      ? localStorageManager.getItem(key) || ''
      : await cookieManager.get(key) || '';

    initValues[key] = item;

    return item;
  }

  function setTheme(value: string): Promise<void> {
    return set(StorageKeys.THEME, value);
  }

  async function getTheme(): Promise<string> {
    const theme = await get(StorageKeys.THEME);
    return theme.toUpperCase();
  }

  function setFirstTheme(value: string): Promise<void> {
    return set(StorageKeys.FIRST_THEME, value);
  }

  async function getFirstTheme(): Promise<string> {
    const firstTheme = await get(StorageKeys.FIRST_THEME);
    return firstTheme.toUpperCase();
  }

  function setIsAutoTheme(value: boolean): Promise<void> {
    return set(StorageKeys.IS_AUTO_THEME, value.toString());
  }

  async function getIsAutoTheme(): Promise<boolean> {
    return await get(StorageKeys.IS_AUTO_THEME) === 'true';
  }

  function clearCache(): void {
    localStorageManager.clear(storageKeysToSave);
  }

  return {
    getTheme,
    setTheme,
    getFirstTheme,
    setFirstTheme,
    getIsAutoTheme,
    setIsAutoTheme,
    clearCache,
    // @deprecated
    get,
    // @deprecated
    set,
  };
});

export default useStorageManagerStore;
