import type { Ref } from 'vue';
import { ref } from 'vue';

import { getClientDimension, getIsPortraitOrientation } from '@leon-hub/utils';

import { useWindowResize } from './useWindowResize';

export interface ClientDimension {
  clientHeight: number;
  clientWidth: number;
}

export interface ClientDimensionSafeSubscribeComposable {
  clientDimension: Ref<ClientDimension>;
  isPortraitOrientation: Ref<boolean>;
}

export function useDocumentDimensionSafeSubscribe({
  debounce,
  onWindowResize,
}: {
  debounce: number | null;
  onWindowResize?(): void;
} = { debounce: 100 }): ClientDimensionSafeSubscribeComposable {
  const clientDimension = ref<ClientDimension>({
    clientHeight: 0,
    clientWidth: 0,
  });
  const isPortraitOrientation = ref<boolean>(false);

  function recalculate(): void {
    clientDimension.value = getClientDimension();
    isPortraitOrientation.value = getIsPortraitOrientation();
    onWindowResize?.();
  }

  useWindowResize(recalculate, { debounce });

  recalculate();

  return {
    clientDimension,
    isPortraitOrientation,
  };
}
