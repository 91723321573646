import type { Ref } from 'vue';
import { computed } from 'vue';

import {
  IconDirection,
  IconSize,
  isPointingIcon,
  useIcons,
} from '@leon-hub/icons';

import type { VIconProps } from '../types';

export interface VIconComposable {
  iconPath: Ref<string | undefined>;
  hasSize: Ref<boolean>;
  hasDirection: Ref<boolean>;
  isLoading: Ref<boolean>;
}

export default function useVIcon(props: VIconProps): VIconComposable {
  const { icons } = useIcons();
  const iconPath = computed(() => props.name && icons.get(props.name.toLowerCase()));
  const hasDirection = computed(() => props.direction !== IconDirection.EAST && isPointingIcon(props.name));
  const hasSize = computed(() => !!props.size && props.size !== IconSize.SIZE_20 && props.size !== IconSize.DEFAULT);
  const isLoading = computed(() => icons.size === 0);

  return {
    iconPath,
    hasSize,
    hasDirection,
    isLoading,
  };
}
