import type { Ref } from 'vue';
import { ref } from 'vue';

import { updatePreparedToActionList } from 'web/src/modules/sportline/submodules/favorites/utils';

export interface ChangePendingStatePayload { id: string; state: boolean }

export interface UsePendingToFavoriteStoreComposableComposable {
  preparedToAdd: Ref<string[]>;
  preparedToRemove: Ref<string[]>;
  setPrepareToAddState({ id, state }: ChangePendingStatePayload): void;
  setPrepareToRemoveState({ id, state }: ChangePendingStatePayload): void;
  setPreparedState(isFavorite: boolean, { id, state }: ChangePendingStatePayload): void;
}

export function usePendingToFavoriteStoreComposable(): UsePendingToFavoriteStoreComposableComposable {
  const preparedToAdd = ref<string[]>([]);
  const preparedToRemove = ref<string[]>([]);

  function setPrepareToAddState({ id, state }: ChangePendingStatePayload): void {
    const currentList = preparedToAdd.value;
    const prepared = updatePreparedToActionList({ id, state, currentList });
    if (prepared.isChanged) { preparedToAdd.value = prepared.list; }
  }

  function setPrepareToRemoveState({ id, state }: ChangePendingStatePayload): void {
    const currentList = preparedToRemove.value;
    const prepared = updatePreparedToActionList({ id, state, currentList });
    if (prepared.isChanged) { preparedToRemove.value = prepared.list; }
  }

  function setPreparedState(isFavorite: boolean, { id, state }: ChangePendingStatePayload): void {
    if (isFavorite) {
      setPrepareToRemoveState({ id, state });
    } else {
      setPrepareToAddState({ id, state });
    }
  }

  return {
    preparedToAdd,
    preparedToRemove,
    setPrepareToAddState,
    setPrepareToRemoveState,
    setPreparedState,
  };
}
