<script setup lang="ts">
import FileSingleInputStateless from '../FileSingleInputStateless';
import type { FileSingleInputProps, FileSingleInputEmits } from '../../types';
import { useHintParentProps } from '../../composables';
import useFileSingleInput from './useFileSingleInput';

const props = withDefaults(defineProps<FileSingleInputProps>(), {
  name: 'file-single',
});

const emits = defineEmits<FileSingleInputEmits>();

const {
  uniqueId,
  inputFileName,
  onFocus,
  onBlur,
  onClear,
  onChange,
} = useFileSingleInput(props, emits);
const { hintProperties } = useHintParentProps(props);
</script>

<template>
  <FileSingleInputStateless v-auto-id="'FileSingleInput'"
    :autofocus="autofocus"
    :input-id="uniqueId"
    v-bind="hintProperties"
    :name="name"
    :file-name="inputFileName"
    :label="label"
    :disabled="disabled"
    :placeholder="placeholder"
    :is-large="isLarge"
    @focus="onFocus"
    @blur="onBlur"
    @change="onChange"
    @clear="onClear"
  />
</template>
