import type { Ref } from 'vue';
import { ref, computed } from 'vue';

import type { BetlineSportEventMatchStatistics, CoreSportlineFetchOptions } from 'web/src/modules/sportline/types/rest';
import { getBetlineMatchEventStatistics } from 'web/src/modules/sportline/utils/api';
import { isStatisticNotFoundError } from 'web/src/modules/sportline/utils/statistic';
import { isBetlineSportEventMatchStatisticsEmpty } from 'web/src/modules/sportline/submodules/event-statistic/utils';

interface UseSportlineMatchStatisticsStoreComposableProps {
  expectedStatisticId: Ref<Maybe<number>>;
}

interface UseSportlineMatchStatisticsStoreComposableComposable {
  betlineMatchStatistics: Ref<Maybe<BetlineSportEventMatchStatistics>>;
  hasMatchStatistic: Ref<boolean>;
  setBetlineMatchStatistics(statistic: Maybe<BetlineSportEventMatchStatistics>): void;
  fetchH2hMatchStatistics({ silent }: CoreSportlineFetchOptions): Promise<void>;
}

export default function useSportlineMatchStatisticsStoreComposable(
  props: UseSportlineMatchStatisticsStoreComposableProps,
): UseSportlineMatchStatisticsStoreComposableComposable {
  const betlineMatchStatistics = ref<Maybe<BetlineSportEventMatchStatistics>>(null);

  const hasMatchStatistic = computed<boolean>(() => {
    const statisticId = betlineMatchStatistics.value?.id;
    return !!statisticId && statisticId === String(props.expectedStatisticId.value);
  });

  function setBetlineMatchStatistics(statistic: Maybe<BetlineSportEventMatchStatistics>): void {
    betlineMatchStatistics.value = !isBetlineSportEventMatchStatisticsEmpty(statistic)
      ? statistic
      : null;
  }

  async function fetchH2hMatchStatistics({ silent }: CoreSportlineFetchOptions): Promise<void> {
    try {
      const eventId = props.expectedStatisticId.value;
      if (!eventId) { return; }
      setBetlineMatchStatistics(
        await getBetlineMatchEventStatistics({ eventId }, { silent }),
      );
    } catch (error: unknown) {
      if (!isStatisticNotFoundError(error)) { throw error; }
      // we tried but got empty statistic for this ID
      setBetlineMatchStatistics(null);
    }
  }

  return {
    betlineMatchStatistics,
    hasMatchStatistic,
    setBetlineMatchStatistics,
    fetchH2hMatchStatistics,
  };
}
