import { useAnalytics } from 'web/src/modules/analytics/composables';
import { useThemeStore } from 'web/src/modules/theme/store';

import type { DoLoginOptions, CustomerDataDocument } from '../types';

const analyticsLogLogin = (options: DoLoginOptions, customerData: CustomerDataDocument | null): void => {
  const analytics = useAnalytics();
  analytics.loginSuccess({
    loginMethod: options.loginMethod,
    captchaType: options.payload.captchaType,
    loginTab: options.payload.loginType ?? 'default',
    userId: customerData?.customerLogin ?? '',
    country: customerData?.country ?? '',
    vipStatus: customerData?.vipStatus ?? 0,
    customerBetType: customerData?.customerBetType,
    shortComments: customerData?.shortComments.length ? customerData?.shortComments[0] : undefined,
    event: 'login',
    customer_status: 'logged_in',
    full_url: window.location.href,
    vs_lw: customerData?.isVip ?? false,
  });

  analytics.yandexZInit({
    userId: customerData?.customerLogin ?? '',
    customerType: customerData?.vipStatus,
    country: customerData?.countryName,
    currentTheme: useThemeStore().theme,
  });
};

export default analyticsLogLogin;
