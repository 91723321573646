import type { RouteLocationRaw } from 'vue-router';

import type { NavigationItem } from '@leon-hub/navigation-config';
import {
  isString,
  assert,
  isObject,
} from '@leon-hub/guards';
import { IconName, IconSize } from '@leon-hub/icons';

import type {
  SidebarMenu, SidebarMenuL1, SidebarMenuL2, SidebarMenuL3,
} from 'web/src/components/SidebarMenu/types';

// eslint-disable-next-line import/prefer-default-export
export function convertNavigationToSidebarMenu<T extends NavigationItem>(
  items: readonly T[],
  l1Mapper: Partial<Record<keyof SidebarMenuL1, Function>> = {},
  l2Mapper: Partial<Record<keyof SidebarMenuL2, Function>> = {},
  l3Mapper: Partial<Record<keyof SidebarMenuL3, Function>> = {},
): SidebarMenu {
  const convertNavigationToSidebarMenuL3 = (
    itemsL3: NavigationItem[],
    parent: NavigationItem,
    grandParent: NavigationItem,
  ): SidebarMenuL3[] => itemsL3.map((itemL3: NavigationItem): SidebarMenuL3 => {
    const { caption: captionL3 } = itemL3;
    assert(isString(captionL3), `NavigationItem has not caption field on L3 for ${itemL3.id}`);
    return {
      active: l3Mapper.active !== undefined ? l3Mapper.active(itemL3, parent, grandParent) : false,
      id: itemL3.id,
      key: `level3-spoiler-${grandParent.id}-${parent.id}-${itemL3.id}`,
      location: l3Mapper.location !== undefined ? l3Mapper.location(itemL3, parent, grandParent) : null,
      externalUrl: itemL3.url,
      name: captionL3,
      suffix: itemL3.props?.isHot ? {
        size: IconSize.SIZE_16,
        name: IconName.FIRE,
      } : undefined,
    };
  });

  const convertNavigationToSidebarMenuL2 = (
    itemsL2: NavigationItem[],
    parent: NavigationItem,
  ): SidebarMenuL2[] => itemsL2.map((itemL2: NavigationItem): SidebarMenuL2 => {
    const { caption: captionL2, children: childrenL2 = [] } = itemL2;
    assert(isString(captionL2), `NavigationItem has not caption field on L2 for ${itemL2.id}`);
    return {
      key: `level2-spoiler-${parent.id}-${itemL2.id}`,
      active: l2Mapper.active !== undefined ? l2Mapper.active(itemL2, parent) : false,
      location: l2Mapper.location !== undefined ? l2Mapper.location(itemL2, parent) : null,
      externalUrl: itemL2.url,
      name: captionL2,
      suffix: itemL2.props?.isHot ? {
        size: IconSize.SIZE_16,
        name: IconName.FIRE,
      } : undefined,
      menu: convertNavigationToSidebarMenuL3(childrenL2, itemL2, parent),
    };
  });

  return items.map((item) => {
    const {
      id, caption, children = [], props,
    } = item;
    assert(isString(caption), `NavigationItem has not caption field on L1 for ${id}`);
    const menuL1: SidebarMenuL1 = {
      active: l1Mapper.active !== undefined ? l1Mapper.active(item) : false,
      canOpen: l1Mapper.canOpen !== undefined ? l1Mapper.canOpen() : !!children.length,
      id,
      key: `level1-spoiler-${id}`,
      location: l1Mapper.location !== undefined ? l1Mapper.location(item) : null,
      externalUrl: item.url,
      name: caption,
      suffix: item.props?.isHot ? {
        size: IconSize.SIZE_16,
        name: IconName.FIRE,
      } : undefined,
      menu: convertNavigationToSidebarMenuL2(children, item),
      props,
    };
    if (item.icon) {
      Object.assign(menuL1, { icon: { ...item.icon } });
    }
    if (item.badge || l1Mapper.badge) {
      menuL1.badge = l1Mapper.badge !== undefined ? l1Mapper.badge(item) : item.badge;
    }
    if (item.progress) {
      menuL1.progress = l1Mapper.progress !== undefined ? l1Mapper.progress(item) : item.progress;
    }
    if (item.suffixText) {
      menuL1.suffixText = l1Mapper.suffixText !== undefined ? l1Mapper.suffixText(item) : item.suffixText;
    }
    return menuL1;
  });
}

export function isValidLocation(location: RouteLocationRaw): boolean {
  return !!location && (!isObject(location) || !!location.name);
}
