import type { ComputedRef, Ref } from 'vue';
import {
  computed, onMounted, onUnmounted, ref, toRef, watch,
} from 'vue';
import { useRouter } from 'vue-router';

import { ProgramRewardType, ProgramParticipationStatus } from '@leon-hub/api-sdk';
import { AlertIconName, IconName } from '@leon-hub/icons';
import { RouteName } from '@leon-hub/routing-config-names';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';

import { useI18n } from 'web/src/modules/i18n/composables';
import { useDateLocalizers } from 'web/src/modules/i18n/composables/useDateLocalizers';
import { useI18nStore } from 'web/src/modules/i18n/store';
import { useReferralProgramStore } from 'web/src/modules/referral-program/store';
import type {
  ExtendedReferral,
  ExtendedRequirement,
  SocialSharingNetworks,
  ProgramDetailsGetDataDocument,
} from 'web/src/modules/referral-program/types/ReferralProgramDocuments';
import {
  ReferralProgramViewMode,
} from 'web/src/modules/referral-program/types/ReferralProgramDocuments';
import getFirstLetter from 'web/src/modules/referral-program/utils/getFirstLetter';
import DateTime from 'web/src/utils/DateTime';
import { useFormatMoney } from 'web/src/modules/money/composables';
import type { VJumbotronProps } from 'web/src/components/Jumbotron/VJumbotron/types';
import getRewardProps from 'web/src/modules/referral-program/utils/getRewardProps';
import type {
  ReferralProgramSelectBonusProps,
} from 'web/src/modules/referral-program/components/ReferralProgramSelectBonus/types';
import { OnboardingIdsEnum } from 'web/src/modules/onboarding/pages/enums';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
import type { StepList } from 'web/src/modules/referral-program/components/ReferralProgramPopupListSteps/types';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import useShareModal from 'web/src/components/SocialSharing/VSocialSharing/composables/useShareModal';
import isIncognito from 'web/src/modules/referral-program/utils/isIncognito';
import { ProfileBonusesActiveTab } from 'web/src/modules/profile/submodules/bonuses/enums';
import type { ReferralProgramBlockProps } from 'web/src/modules/referral-program/components/ReferralProgramBlock/types';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import { getImageOrIcon } from 'web/src/modules/icons';

import getRequirementsTitle from '../../../utils/referralProgramPopup/getRequirementsTitle';
import getRequirementsRoute from '../../../utils/referralProgramPopup/getRequirementsRoute';
import getRewardTitle from '../../../utils/referralProgramPopup/getRewardTitle';

export interface ReferralProgramRoutePageComposable {
  referralsCount: Ref<string>;
  totalReward: Ref<string>;
  pendingReferralCount: Ref<number>;
  completedReferrals: Ref<ExtendedReferral[]>;
  pendingReferrals: Ref<ExtendedReferral[]>;
  availableReferrals: Ref<ExtendedReferral[]>;
  referrals: Ref<ExtendedReferral[]>;

  pushToOnboardingPage(newValue: ProgramDetailsGetDataDocument): void;

  isContentLoading: Ref<boolean>;
  viewMode: Ref<ReferralProgramViewMode>;
  selectedReferral: Ref<ExtendedReferral | null>;
  selectedColorId: Ref<number>;
  setViewMode: (mode: ReferralProgramViewMode) => void;
  jumbotronProps: Ref<VJumbotronProps>;
  requirements: Ref<ExtendedRequirement[]>;
  isConditionsSatisfied: Ref<boolean>;
  bonuses: Ref<ReferralProgramSelectBonusProps[]>;
  isAccessGranted: Ref<boolean>;
  playerRequirementsCompleted: Ref<StepList[]>;
  playerRequirementsCurrent: Ref<StepList[]>;
  isSelectedReferralCompleted: Ref<boolean>;
  referralUrl: Ref<string>;
  socialSharingNetworks: Ref<SocialSharingNetworks>;

  handleShowShareModal(isSendAnalytic?: boolean): void;

  closeShareModal(): void;

  goToTerms(param?: string): void;

  goToBonuses(): void;

  sendAnalyticOnOpen(): void;

  isShareModal: Ref<boolean>;

  clickOnReward(type: ProgramRewardType | undefined, referralId: number): Promise<void>;

  referralFiendsProps: ComputedRef<ReferralProgramBlockProps>;
  referralBonusProps: ComputedRef<ReferralProgramBlockProps>;
  handleBlockClick: (value: ReferralProgramViewMode) => void;
  isBonusSelected: Ref<boolean>;
  selectedBonus: Ref<Maybe<ProgramRewardType>>;
  changeSelectedBonus: (value: Maybe<ProgramRewardType>) => void;
}

export default function useReferralProgramRoutePage(): ReferralProgramRoutePageComposable {
  const { $translate } = useI18n();
  const i18nStore = useI18nStore();
  const router = useRouter();
  const referralStore = useReferralProgramStore();
  const customerDataStore = useCustomerDataStore();
  const siteConfigStore = useSiteConfigStore();
  const formatMoney = useFormatMoney();
  const analytics = useAnalytics();

  const isConfirmed = toRef(referralStore, 'isConfirmed');
  const availability = toRef(referralStore, 'availability');
  const programDetails = toRef(referralStore, 'programDetails');
  const viewMode = toRef(referralStore, 'viewMode');
  const customerConfig = toRef(customerDataStore, 'customerConfig');
  const isAccessGranted = toRef(referralStore, 'isAccessGranted');
  const homePageType = toRef(siteConfigStore, 'homePageType');

  const selectedBonus = ref<Maybe<ProgramRewardType>>(null);
  const isContentLoading = ref(true);
  const selectedReferral = ref<ExtendedReferral | null>(null);
  const selectedColorId = ref<number>(0);
  const isBonusSelected = ref<boolean>(false);

  const socialSharingNetworks = computed(() => siteConfigStore.referralProgramSocialItems || []);

  const totalReward = toRef(referralStore, 'totalReward');

  const changeSelectedBonus = (value: Maybe<ProgramRewardType>) => {
    selectedBonus.value = value;
  };

  const referrals = computed<ExtendedReferral[]>(() => (programDetails.value?.referrals ?? []).map((referral) => ({
    ...referral,
    initials: `${getFirstLetter(referral.firstName)}${getFirstLetter(referral.lastName)}`,
    isIncognito: isIncognito(referral),
    name: isIncognito(referral)
      ? $translate('WEB2_REFERRAL_PROGRAM_INCOGNITO').value
      : `${referral.firstName} ${getFirstLetter(referral.lastName)}.`,
    date: DateTime.withTimeStamp(referral.registrationDate).toFullDate(),
    longDate: useDateLocalizers().toFullLocaleDate(referral.registrationDate),
    formattedAmount: formatMoney(referral.playerReward?.amount || referral.playerRewardOptions[0]?.amount || 0),
    amount: referral.playerReward?.amount || referral.playerRewardOptions[0]?.amount || 0,
  })));

  const completedReferrals = computed(() => referrals.value.filter(
    (referral) => referral.status === ProgramParticipationStatus.COMPLETED,
  ));

  const availableReferrals = computed(() => referrals.value.filter(
    (referral) => referral.status === ProgramParticipationStatus.AVAILABLE,
  ));

  const pendingReferrals = computed(() => referrals.value.filter(
    (referral) => referral.status === ProgramParticipationStatus.PENDING,
  ).map((referral) => ({
    ...referral,
    currentStep: referral.requirements.filter((requirement) => requirement.satisfied).length,
    totalSteps: referral.requirements.length,
  })));

  const pendingReferralCount = computed(() => pendingReferrals.value.length);

  const referralsCount = computed(() => `${availability.value?.referralCount || 0}`);

  const bonuses = computed<ReferralProgramSelectBonusProps[]>(
    () => (selectedReferral.value?.playerRewardOptions ?? []).map((bonus) => ({
      ...getRewardProps(bonus, $translate, formatMoney, !!process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED),
    })),
  );

  const playerRequirementsCompleted = computed<StepList[]>(
    () => (programDetails.value?.playerRequirements ?? [])
      .filter((requirement) => requirement.satisfied)
      .map((requirement) => ({
        ...requirement,
        label: getRequirementsTitle(requirement, $translate, formatMoney),
      })),
  );

  const playerRequirementsCurrent = computed<StepList[]>(
    () => (programDetails.value?.playerRequirements ?? [])
      .filter((requirement) => !requirement.satisfied)
      .map((requirement) => ({
        ...requirement,
        label: getRequirementsTitle(requirement, $translate, formatMoney),
        to: getRequirementsRoute(requirement.type, homePageType.value),
      })),
  );

  const requirements = computed<ExtendedRequirement[]>(() => (
    selectedReferral.value?.requirements ?? []).map((requirement) => ({
    ...requirement,
    label: getRequirementsTitle(requirement, $translate, formatMoney),
  })));

  const isConditionsSatisfied = computed(
    () => requirements.value.every((requirement) => requirement.satisfied),
  );

  const playerRewardTParams = computed(() => {
    const playerReward = selectedReferral.value?.playerReward;

    return {
      count: playerReward ? getRewardTitle(playerReward, $translate, i18nStore.locale) : '0',
      amount: formatMoney(playerReward?.nominalValue || 0),
    };
  });

  const rewardCountTParams = computed(() => ({
    count: selectedReferral.value?.referralReward ? getRewardTitle(
      selectedReferral.value?.referralReward,
      $translate,
      i18nStore.locale,
    ) : '0',
  }));

  const availableAmountTParams = computed(() => ({
    value: formatMoney((selectedReferral.value?.playerRewardOptions ?? [])[0]?.amount || 0),
  }));

  const isSelectedReferralCompleted = computed(
    () => selectedReferral.value?.status === ProgramParticipationStatus.COMPLETED,
  );

  const jumbotronProps = computed<VJumbotronProps>(() => {
    const selectedStatus = selectedReferral.value?.status ?? ProgramParticipationStatus.PENDING;
    switch (selectedStatus) {
      case ProgramParticipationStatus.COMPLETED:
        return {
          icon: !process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED ? IconName.PROMOS : undefined,
          image: process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED
            ? getImageOrIcon({ alertIcon: AlertIconName.Present2 }).image : undefined,
          heading: $translate('WEB2_REFERRAL_COMPLETED_HEADING', playerRewardTParams).value,
          text: $translate('WEB2_REFERRAL_COMPLETED_TEXT', rewardCountTParams).value,
        };
      case ProgramParticipationStatus.AVAILABLE:
        return {
          heading: $translate('WEB2_REFFERAL_PROGRAM_BONUS').value,
          text: $translate('WEB2_REFERRAL_AVAILABLE_TEXT', availableAmountTParams).value,
        };
      default:
        return {
          heading: $translate('WEB2_REFERRAL_PENDING_HEADING').value,
          text: $translate('WEB2_REFERRAL_PENDING_TEXT').value,
        };
    }
  });

  const referralUrl = computed(() => programDetails.value?.referralUrl || '');

  const referralFiendsProps = computed<ReferralProgramBlockProps>(() => ({
    title: $translate('WEB2_REFERRAL_FRIENDS').value,
    iconName: process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED ? IconName.SLOTT_USERS : IconName.PEOPLE,
    value: referralsCount.value,
    isClickable: true,
    color: 'brand',
  }));

  const referralBonusProps = computed<ReferralProgramBlockProps>(() => ({
    title: $translate('WEB2_REFERRAL_MY_BONUS').value,
    value: totalReward.value,
    iconName: process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED ? IconName.SLOTT_GIFT2 : IconName.PROMOS,
    isClickable: true,
    color: 'additional',
  }));

  function pushToOnboardingPage(newValue: ProgramDetailsGetDataDocument): void {
    isConfirmed.value = newValue.confirmed;
    if (!isConfirmed.value) {
      void router.replace({
        name: RouteName.ONBOARDING,
        params: { id: OnboardingIdsEnum.REFERRAL_PROGRAM },
      });
    } else if (customerConfig.value) {
      isContentLoading.value = false;
    }
  }

  function sendAnalyticOnOpen(): void {
    let openSection = isAccessGranted.value ? 'main' : 'noAccessPage';

    if (!isConfirmed.value && !isAccessGranted.value) {
      openSection = 'accept';
    }

    analytics.push(AnalyticsEvent.Z_REFER_FRIEND, {
      referFriendProgram: {
        openSection,
      },
    });
  }

  function goToTerms(param: string): void {
    void router.push({ name: RouteName.REFERRAL_PROGRAM_INFO });

    const analyticKey = viewMode.value === ReferralProgramViewMode.MAIN ? 'mainPage' : 'noAccessPage';

    analytics.push(AnalyticsEvent.Z_REFER_FRIEND, {
      referFriendProgram: {
        [analyticKey]: {
          info: param,
        },
      },
    });
  }

  function goToBonuses(): void {
    const rewardType = selectedReferral.value?.playerReward?.rewardType;
    let category = null;

    if (rewardType) {
      category = rewardType === ProgramRewardType.FREEBET
        ? ProfileBonusesActiveTab.SPORT
        : ProfileBonusesActiveTab.GAME;
    }

    void router.push({
      name: RouteName.BONUSES,
      query: {
        category,
      },
    });
  }

  watch(programDetails, (newValue) => {
    if (newValue) {
      pushToOnboardingPage(newValue);
    }
  }, {
    immediate: true,
  });

  const {
    isShareModal,
    showShareModal,
    closeShareModal,
  } = useShareModal();

  async function clickOnReward(type: ProgramRewardType | undefined, referralId: number): Promise<void> {
    if (type) {
      await referralStore.rewarding({ type, referralId });
      const refId = selectedReferral.value?.customerId;
      selectedReferral.value = referrals.value.find((item) => item.customerId === refId) ?? null;
      isBonusSelected.value = true;
    }
  }

  const handleShowShareModal = (isSendAnalytic?: boolean) => {
    if (isSendAnalytic) {
      analytics.push(AnalyticsEvent.Z_REFER_FRIEND, {
        referFriendProgram: {
          mainPage: {
            shareLink: 'shareLinkButton',
          },
        },
      });
    }
    void showShareModal({ title: $translate('WEB2_SHARE_LINK_WINDOW_TITLE').value, url: referralUrl.value });
  };

  const handleBlockClick = (value: ReferralProgramViewMode) => {
    if (referrals.value.length === 0) {
      handleShowShareModal();
    } else if (availableReferrals.value.length) {
      referralStore.setViewMode(value);
    } else {
      referralStore.setViewMode(ReferralProgramViewMode.LIST);
    }

    const analyticKey = value === ReferralProgramViewMode.LIST_BONUSES ? 'myBonus' : 'friends';
    analytics.push(AnalyticsEvent.Z_REFER_FRIEND, {
      referFriendProgram: {
        mainPage: {
          [analyticKey]: referrals.value.length === 0 ? 'shareLink' : 'friendsList',
        },
      },
    });
  };

  onMounted(async () => {
    await referralStore.getRefDetails();
    await referralStore.getAvailability();
    await referralStore.setProgramReferral();

    sendAnalyticOnOpen();
  });

  onUnmounted(() => {
    selectedReferral.value = null;
    selectedColorId.value = 0;
    referralStore.setViewMode(ReferralProgramViewMode.MAIN);
  });

  return {
    referralsCount,
    referrals,
    completedReferrals,
    availableReferrals,
    pendingReferrals,
    viewMode,
    totalReward,
    jumbotronProps,
    pendingReferralCount,
    selectedReferral,
    selectedColorId,
    setViewMode: referralStore.setViewMode,
    requirements,
    isConditionsSatisfied,
    bonuses,
    isContentLoading,
    pushToOnboardingPage,
    isAccessGranted,
    playerRequirementsCompleted,
    playerRequirementsCurrent,
    isSelectedReferralCompleted,
    referralUrl,
    socialSharingNetworks,
    goToTerms,
    goToBonuses,
    handleShowShareModal,
    closeShareModal,
    isShareModal,
    clickOnReward,
    referralFiendsProps,
    referralBonusProps,
    isBonusSelected,
    handleBlockClick,
    sendAnalyticOnOpen,
    selectedBonus,
    changeSelectedBonus,
  };
}
