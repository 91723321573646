import differenceWith from 'lodash/differenceWith';

export type ArrayEqualsCompare<T1, T2> = (a: T1, b: T2) => boolean;

function defaultCompare(a: unknown, b: unknown): boolean {
  return a === b;
}

export default function arrayEquals<T1 = unknown, T2 = T1>(
  a: ReadonlyArray<T2>,
  b: ReadonlyArray<T1>,
  compare: ArrayEqualsCompare<T1, T2> = defaultCompare,
): boolean {
  return (b.length === a.length)
    && differenceWith<T1, T2>(b, a, compare).length === 0;
}
