/* eslint-disable no-underscore-dangle */
import {
  isNumber, isObject,
} from '@leon-hub/guards';
import {
  ApiIpBlockedError,
  ApiServiceUnavailableError,
  GqlApiAccessDeniedError,
  GqlApiCustomerHistoryLimitExceededError,
  GqlApiPreviousRequestHasNotExpired,
} from '@leon-hub/api';
import { AbstractError, LogLevel } from '@leon-hub/errors';
import { AppIgnoredError } from '@leon-hub/app-errors';

enum NavigationFailureType {
  redirected = 2,
  aborted = 4,
  cancelled = 8,
  duplicated = 16,
}

export function isCommonWhitelistedError(error: unknown): boolean {
  return !!(isObject(error)
      && ((error instanceof AbstractError
          && (error.logLevel === LogLevel.DEBUG
              || error.silent
              || error instanceof ApiServiceUnavailableError
              || error instanceof GqlApiAccessDeniedError
              || error instanceof GqlApiPreviousRequestHasNotExpired
              || error instanceof GqlApiCustomerHistoryLimitExceededError
              || error instanceof ApiIpBlockedError
              || error instanceof AppIgnoredError
          ))
          || (error instanceof Error
                    && (error.message.includes('This browser doesn\'t support the API\'s required to use the Firebase')
                        || error.message.includes('[webpack-dev-server]')
                        || !!(error.stack && error.stack.includes('googletagmanager'))))
          || (error._isRouter
                  && isNumber(error.type)
                  && [
                    NavigationFailureType.redirected,
                    NavigationFailureType.duplicated,
                    NavigationFailureType.cancelled,
                  ].includes(error.type)
          )
          || error._isWhiteListed
      )
  );
}
