import { doAddLoginDevice } from '@leon-hub/api-sdk';
import { normalizeError } from '@leon-hub/errors';
import { logger } from '@leon-hub/logging';
import { keys } from '@leon-hub/utils';

import { DeviceFingerprintType } from 'web/src/modules/identity/enums';
import { useIdentityStore } from 'web/src/modules/identity/store';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import { useLexisNexisIntegration } from 'web/src/modules/lexis-nexis-integration';
import { useShieldIntegration } from 'web/src/modules/shield-integration';

async function getFingerPrintId(): Promise<string | undefined> {
  if (process.env.VUE_APP_PLATFORM_CORDOVA) {
    return undefined;
  }

  const { getDeviceFingerprint, getFingerPrintFromStorage } = useIdentityStore();
  try {
    if (!await getFingerPrintFromStorage(DeviceFingerprintType.DEVICE_FINGERPRINT_JS)) {
      return await getDeviceFingerprint(
        DeviceFingerprintType.DEVICE_FINGERPRINT_JS,
      ) || undefined;
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Unable to get device fingerprint', err);
  }

  return undefined;
}

const getOptionalLexisNexisSessionId = async (): Promise<string | undefined> => {
  let result: string | undefined;
  if (process.env.VUE_APP_FEATURE_LEXIS_NEXIS_INTEGRATION_ENABLED) {
    const { whenProfiledDuringRuntime, getSessionId, isEnabled } = useLexisNexisIntegration();
    if (isEnabled.value) {
      await whenProfiledDuringRuntime();
      result = await getSessionId();
    }
  }
  return result;
};

async function getShieldSessionId(): Promise<string | undefined> {
  if (process.env.VUE_APP_FEATURE_SHIELD_INTEGRATION_ENABLED) {
    const { load, sessionId } = useShieldIntegration();
    await load();
    return sessionId.value;
  }
  return undefined;
}

function getValues<Key extends string, Result extends Record<Key, string | undefined>>(
  record: Record<Key, PromiseSettledResult<string | undefined>>,
): Result {
  return keys(record).reduce((acc, id) => {
    const settled = record[id];
    if (settled.status === 'rejected') {
      logger.error(`Unable to get ${id}`, normalizeError(settled.reason));
      return acc;
    }
    return {
      ...acc,
      [id]: settled.value,
    };
  }, {} as Result);
}

async function getOptions({ skipLexis }: { skipLexis?: boolean }) {
  const [
    fpjsVisitorId, helmetDeviceId, lexGuardSessionId,
  ] = await Promise.allSettled([
    getFingerPrintId(),
    getShieldSessionId(),
    skipLexis ? undefined : getOptionalLexisNexisSessionId(),
  ]);

  return getValues({
    fpjsVisitorId,
    helmetDeviceId,
    lexGuardSessionId,
  });
}

/**
 * Save different device/user identification data.
 */
const addLoginDevice = async ({ skipLexis } : { skipLexis?: boolean } = {}): Promise<void> => {
  const {
    fpjsVisitorId,
    helmetDeviceId,
    lexGuardSessionId,
  } = await getOptions({ skipLexis });

  if (fpjsVisitorId || helmetDeviceId || lexGuardSessionId) {
    await doAddLoginDevice(useGraphqlClient(),
      (node) => node.mutations.customer.addLoginDevice,
      {
        options: {
          fpjsVisitorId,
          helmetDeviceId,
          lexGuardSessionId,
        },
      },
      { silent: true });
  }
};

export default addLoginDevice;
