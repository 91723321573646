import type { Ref } from 'vue';
import { toRef, ref, computed } from 'vue';

import { isUndefined } from '@leon-hub/guards';

import { useSportlineApiService } from 'web/src/modules/sportline/services';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import useSportsTreeStore from 'web/src/modules/sportline/store/useSportsTreeStore';
import type { SportDateTimeFilter } from 'web/src/modules/sportline/submodules/navigation/store/types';
import type { SportsTreeSportElement } from 'web/src/modules/sportline/types/rest';
import type { SportEventsSportsDateTimeFilter } from 'web/src/modules/sportline/types';
import { SportlineType } from 'web/src/modules/sportline/enums';
import { BetlineType } from 'web/src/modules/sportline/enums/rest';
import { SportsListAdapter } from 'web/src/modules/sportline/utils/rest';

import { useSportsListDateFilterStoreComposable } from './useSportsListDateFilter';

interface UseSportsListStoreProps {
  updateInterval: Ref<number>;
}

interface UseSportsListStoreComposable {
  sportsListFilter: Ref<SportDateTimeFilter>;
  fullSportsList: Ref<SportsTreeSportElement[]>;
  actualSportsList: Ref<Maybe<SportsTreeSportElement[]>>;
  isReady: Ref<boolean>;
  reloadAllSportsLists(silent?: boolean): Promise<void>;
  setSportsListFilter(value?: Maybe<SportEventsSportsDateTimeFilter>): Promise<void>;
}

export function useSportsListStoreComposable(
  props: UseSportsListStoreProps,
): UseSportsListStoreComposable {
  const { updateInterval } = props;

  const settingsStore = useSportlineSettingsStore();
  const parseSportlineSettings = toRef(settingsStore, 'parseSportlineSettings');

  const apiService = useSportlineApiService();
  const sportsTreeStore = useSportsTreeStore();

  const {
    dateTimeFilter: sportsListFilter,
    dateTimeFilterInterval: filterDateTimeInterval,
    setDateTimeFilter: applySportsListFilter,
  } = useSportsListDateFilterStoreComposable();

  const rawFullSportsList = toRef(sportsTreeStore, 'fullSportsList');
  const sportsListInternal = ref<Maybe<SportsTreeSportElement[]>>(null);

  const fullSportsList = computed(() => rawFullSportsList.value ?? []);

  const actualSportsList = computed<Maybe<SportsTreeSportElement[]>>(() => {
    const interval = filterDateTimeInterval.value;
    const { sportlineType } = sportsListFilter.value;
    const hasDateTimeFilter = !isUndefined(interval.from) && !isUndefined(interval.to);
    const hasActiveFilter = hasDateTimeFilter || !isUndefined(sportlineType);

    return hasActiveFilter
      ? sportsListInternal.value
      : rawFullSportsList.value;
  });

  const isReady = computed<boolean>(() => (actualSportsList.value !== null));

  /**
   * @TODO remove extra request for same filters
   * Reload sports list with active filters for bottom block
   */
  async function reloadSportsForNavigationElements(silent?: boolean): Promise<void> {
    const interval = filterDateTimeInterval.value;
    const { sportlineType } = sportsListFilter.value;
    const hasDateTimeFilter = !isUndefined(interval.from) && !isUndefined(interval.to);
    const hasActiveFilter = hasDateTimeFilter || !isUndefined(sportlineType);

    // will be used full sports list
    if (!hasActiveFilter) { return; }

    const result = await apiService.loadSportsTree({
      dateTime: hasDateTimeFilter ? interval : undefined,
      silent,
      betlines: sportlineType === SportlineType.Live
        ? [BetlineType.Live]
        : [BetlineType.Live, BetlineType.Prematch],
    });
    const list: SportsTreeSportElement[] = result
      ? (new SportsListAdapter(
        result,
        parseSportlineSettings.value,
      )).getSports()
      : [];

    sportsListInternal.value = list ?? null;
  }

  /**
   * Reload sports list without filters for top block
   */
  async function reloadFullSportsList(silent?: boolean): Promise<void> {
    await sportsTreeStore.reloadFullSportsList({
      silent,
      invalidateAfterInterval: updateInterval.value,
    });
  }

  /**
   * Reload both full and filtered lists
   */
  async function reloadAllSportsLists(silent?: boolean): Promise<void> {
    await Promise.all([
      reloadSportsForNavigationElements(silent),
      reloadFullSportsList(silent),
    ]);
  }

  async function setSportsListFilter(value?: Maybe<SportEventsSportsDateTimeFilter>): Promise<void> {
    applySportsListFilter(value);
    await reloadSportsForNavigationElements();
  }

  return {
    sportsListFilter,
    fullSportsList,
    actualSportsList,
    isReady,
    reloadAllSportsLists,
    setSportsListFilter,
  };
}
