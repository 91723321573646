import { getCordovaAppConfig } from '@leon-hub/cordova';

export default function resolveApi2Url(baseUrl = ''): string | undefined {
  if (process.env.VUE_APP_PLATFORM === 'cordova') {
    // must be initialized in cordova/src/config.js
    const { appOrigin, api2PublicUrl } = getCordovaAppConfig();
    return `${baseUrl || appOrigin}${api2PublicUrl}`;
  }

  if (process.env.VUE_APP_RENDERING === 'ssr') {
    return process.env.VUE_APP_API_2_INTERNAL_URL;
  }

  return process.env.VUE_APP_API_2_PUBLIC_URL;
}
