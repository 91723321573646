<script lang="ts" setup>
import { useVCaptchaVerificationWatcher } from './composables';
import type { CaptchaVerificationEmits } from '../types/CaptchaVerificationEmits';
import type { VCaptchaVerificationWatcherProps } from './types';

const props = defineProps<VCaptchaVerificationWatcherProps>();

const emits = defineEmits<CaptchaVerificationEmits>();

defineOptions({
  inheritAttrs: false,
});

useVCaptchaVerificationWatcher(props, emits);
</script>

<template>
  <div v-auto-id="'VCaptchaVerificationWatcher'" />
</template>
