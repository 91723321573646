import type { AccumulatorCoefficientBoostSettings } from '@leon-hub/api-sdk';

import getAccumulatorBoostPercentage from './getAccumulatorBoostPercentage';

interface Payload {
  sortedSettings: AccumulatorCoefficientBoostSettings[];
  isWinBoost: boolean;
}

interface Output {
  maxBoost: number;
  minBoost: number;
  minCount: number;
  maxCount: number;
}

export function getAccumulatorBoostValues({ sortedSettings, isWinBoost }: Payload): Output {
  const minSettingRecord = sortedSettings[0];
  const maxSettingRecord = sortedSettings[sortedSettings.length - 1];

  const minCount = minSettingRecord.count ?? 0;
  const maxCount = maxSettingRecord.count ?? 0;
  const maxBoostValue = maxSettingRecord.boost ?? 0;
  const minBoostValue = minSettingRecord.boost ?? 0;

  return {
    maxBoost: isWinBoost ? maxBoostValue : getAccumulatorBoostPercentage(maxBoostValue),
    minBoost: isWinBoost ? minBoostValue : getAccumulatorBoostPercentage(minBoostValue),
    minCount,
    maxCount,
  };
}
