<script setup lang="ts">
import { dataTestLabel as vDataTestLabel } from '../../directives';
import type { BaseInputEmits, PaymentInputProps } from '../../types';
import { InputMode, TextInputTypes } from '../../enums';
import InputWrapper from '../InputWrapper';
import InputBorderWrapper from '../InputBorderWrapper';
import TextFlatInput from '../TextFlatInput';
import usePaymentInput from './usePaymentInput';
import InputButton from '../InputButton/InputButton.vue';

const props = withDefaults(defineProps<PaymentInputProps>(), {
  name: 'number-input',
  value: '',
});

const emits = defineEmits<BaseInputEmits>();

defineOptions({
  inheritAttrs: false,
});

const {
  uniqueId,
  isFocus,
  isEmpty,
  isHover,
  showClearButton,
  onBlur,
  onChange,
  onInput,
  onFocus,
  onClear,
  onPaste,
  onMouseOver,
  onMouseLeave,
  inputMaxLength,
  setFocusPosition,
  displayedValue,
} = usePaymentInput(props, emits);
</script>

<template>
  <InputWrapper v-auto-id="'PaymentInput'"
    v-data-test="{
      el: 'payment-input',
      name: name,
    }"
    :class="$style['payment-input']"
    :input-id="uniqueId"
    :label="label"
    :is-label-hidden="isLabelHidden"
    :is-hint-hidden="isHintHidden"
    :disabled="disabled"
    :is-focus="isFocus"
    :is-empty="isEmpty"
    :hint="hint"
    :hint-right="hintRight"
    :hint-link="hintLink"
    :hint-link-right="hintLinkRight"
    :error="error"
    :error-right="errorRight"
    :is-large="isLarge"
    :clickable-suffix="showClearButton"
  >
    <template #label>
      <label
        v-if="uniqueId && !isLabelHidden"
        v-data-test-label
        :for="uniqueId"
        :class="$style['payment-input__label']"
        @mousedown.prevent
      >
        {{ label }}
      </label>
    </template>
    <template #input>
      <InputBorderWrapper
        :is-focus="isFocus"
        :is-disabled="disabled"
        :is-hover="isHover"
        :has-error="!!(error || errorRight)"
      >
        <TextFlatInput
          :autofocus="autofocus"
          :name="name"
          :input-id="uniqueId"
          :type="TextInputTypes.TEL"
          :input-mode="InputMode.DECIMAL"
          :value="displayedValue"
          :has-error="!!(error || errorRight)"
          :disabled="disabled"
          :has-icon-suffix="showClearButton"
          :max-length="inputMaxLength"
          :is-large="isLarge"
          is-payment-field
          @input="onInput"
          @change="onChange"
          @focus="onFocus"
          @blur="onBlur"
          @paste="onPaste"
          @mousedown="setFocusPosition"
          @mouseover="onMouseOver"
          @mouseleave="onMouseLeave"
        />
        <div :class="$style['payment-input__placeholder']">
          {{ placeholder }}
        </div>
      </InputBorderWrapper>
    </template>
    <template #iconSuffix>
      <InputButton
        v-show="showClearButton"
        v-data-test="{ el: 'clear-icon-input-button', visible: !showClearButton }"
        :title="$t('WEB2_CLEAR')"
        @click="onClear"
      />
    </template>
  </InputWrapper>
</template>

<style lang="scss" module>
@import '../../common';

.payment-input {
  &__placeholder {
    @include paymentInputPlaceholderFont;

    position: absolute;
    top: $inputAbsolutePositionOffsetTop;
    left: $inputAbsolutePositionOffsetSide;
    color: $inputValueDefaultColor;
    pointer-events: none;

    &--error:not(&--focus) {
      color: $inputValueErrorColor;
    }
  }

  &__label {
    @include inputLabel;
    @include paymentInputFontLabel;
  }
}
</style>
