import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import createUniqueId from 'web/src/utils/createUniqueId';

import type { SuggestEmailInputProps } from '../../types';

interface UseSuggestEmailInput {
  getEmailValue: (value?: string) => string;
  listId: ComputedRef<string>;
}

export default function useSuggestEmailInput(props: SuggestEmailInputProps): UseSuggestEmailInput {
  const listId = computed<string>(() => createUniqueId(`${props.name}-list`));

  const getEmailValue = (value = ''): string => (value.includes('@') ? value.split('@')[0] : '');

  return {
    listId,
    getEmailValue,
  };
}
