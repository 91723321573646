import { addLeadingZero } from '@leon-hub/utils';

export enum TimeFormats {
  hoursMinutes = 'hh:mm',
  hoursMinutesSeconds = 'hh:mm:ss',
  day = 'dd',
  monthShortLocal = 'MMM',
  dayMonth = 'dd.MM',
  dayMonthShortYear = 'dd.MM.yy',
  dayMonthYear = 'dd.MM.yyyy',
  dayMonthYearHoursMinutes = 'dd.MM.yyyy hh:mm',
  dayMonthHoursMinutes = 'dd.MM hh:mm',
  minutesSeconds = 'nn:ss',
  inputDate = 'yyyy-MM-dd',
  inputDateTimeLocal = 'yyyy-MM-ddThh:mm',
}

export enum InputDateFormats {
  dayMonthYear = 'dd.MM.yyyy',
  inputDate = 'yyyy-MM-dd',
}

function toDate(timestamp: number | string | Date): Date {
  return typeof timestamp === 'number' || typeof timestamp === 'string'
    ? new Date(timestamp)
    : timestamp;
}

export function isToday(timestamp: number | string | Date): boolean {
  const date = toDate(timestamp);
  const today = new Date();
  return date.setHours(0, 0, 0, 0) === today.setHours(0, 0, 0, 0);
}

export default class DateTime {
  private dateNormalization: Record<InputDateFormats, { from: RegExp; to: string }> = {
    [InputDateFormats.dayMonthYear]: {
      from: /^(\d{2})\.(\d{2})\.(\d{4})$/,
      to: '$3-$2-$1',
    },
    [InputDateFormats.inputDate]: {
      from: /^/,
      to: '',
    },
  };

  private static getDayMonthYearLocalized(locale: string, day: string, month: string, year: string) {
    switch (locale.toLowerCase()) {
      case 'de_de':
      case 'ru_ru':
      case 'ru_kz':
        return `${day}.${month}.${year}`;
      case 'en_ca':
      case 'en_nz':
      case 'fr_ca':
        return `${year}-${month}-${day}`;
      case 'en_us':
      case 'el_gr':
      case 'en_in':
      case 'en_au':
      case 'en_se':
      case 'pt_pt':
      case 'br_br':
      case 'fr_fr':
      case 'es_cl':
      case 'es_pe':
      case 'th_th':
      case 'vn_vn':
      case 'tr_tr':
      case 'it_it':
      case 'sw_tz':
      default:
        return `${day}/${month}/${year}`;
    }
  }

  private static getHoursAndMinutes = (date: Date) => {
    const hh = date.getHours();
    const mm = date.getMinutes();

    return `${addLeadingZero(hh, 2)}:${addLeadingZero(mm, 2)}`;
  };

  private static getHoursMinutesSeconds = (date: Date) => {
    const hh = date.getHours();
    const mm = date.getMinutes();
    const ss = date.getSeconds();

    return `${addLeadingZero(hh, 2)}:${addLeadingZero(mm, 2)}:${addLeadingZero(ss, 2)}`;
  };

  private static getPrettyDay(date: Date) {
    const day = date.getDate();

    return (day < 10 ? `0${day}` : `${day}`);
  }

  private static getPrettyMonth(date: Date) {
    const mm = date.getMonth() + 1;

    return addLeadingZero(mm, 2);
  }

  private static getDay(date: Date) {
    return `${DateTime.getPrettyDay(date)}`;
  }

  private static getShortMonthLocalized(date: Date, locale?: string) {
    return `${date.toLocaleString(locale?.replace(/_/g, '-'), { month: 'short' })}`;
  }

  private static getDayAndMonth(date: Date) {
    return `${DateTime.getPrettyDay(date)}.${DateTime.getPrettyMonth(date)}`;
  }

  private static getDayMonthYear(date: Date, locale?: string) {
    const dayMonth = DateTime.getDayAndMonth(date);
    const day = DateTime.getPrettyDay(date);
    const month = DateTime.getPrettyMonth(date);
    const year = date.getFullYear();
    return locale ? DateTime.getDayMonthYearLocalized(locale, day, month, year.toString()) : `${dayMonth}.${year}`;
  }

  private static getDayMonthShortYear(date: Date, locale?: string) {
    const dayMonth = DateTime.getDayAndMonth(date);
    const day = DateTime.getPrettyDay(date);
    const month = DateTime.getPrettyMonth(date);
    const year = date.getFullYear();
    const shortYear = year.toString().slice(2, 4);
    return locale ? DateTime.getDayMonthYearLocalized(locale, day, month, shortYear.toString()) : `${dayMonth}.${shortYear}`;
  }

  private static getDayMonthYearHoursMinutes(date: Date) {
    const dayMonth = DateTime.getDayAndMonth(date);
    const year = date.getFullYear();
    const time = DateTime.getHoursAndMinutes(date);
    return `${dayMonth}.${year}\u00A0${time}`;
  }

  private static getDayMonthHoursMinutes(date: Date) {
    const dayMonth = DateTime.getDayAndMonth(date);
    const time = DateTime.getHoursAndMinutes(date);
    return `${dayMonth}\u00A0${time}`;
  }

  private static getMinutesSeconds(date: Date) {
    const mm = date.getMinutes();
    const ss = date.getSeconds();

    return `${addLeadingZero(mm, 2)}:${addLeadingZero(ss, 2)}`;
  }

  private static getInputDateTimeLocal(date: Date): string {
    const day = DateTime.getPrettyDay(date);
    const month = DateTime.getPrettyMonth(date);
    const year = date.getFullYear();
    const time = DateTime.getHoursAndMinutes(date);

    return `${year}-${month}-${day}T${time}`;
  }

  private static getInputDate(date: Date): string {
    const day = DateTime.getPrettyDay(date);
    const month = DateTime.getPrettyMonth(date);
    const year = date.getFullYear();

    return `${year}-${month}-${day}`;
  }

  public static getTimezoneOffset(timestamp = 0): number {
    const date = new Date(timestamp);

    return date.getTimezoneOffset();
  }

  public static formatTimeStamp(timestamp: number, format: TimeFormats, locale?: string): string {
    const date = new Date(timestamp);

    switch (format) {
      case TimeFormats.hoursMinutes:
        return DateTime.getHoursAndMinutes(date);
      case TimeFormats.hoursMinutesSeconds:
        return DateTime.getHoursMinutesSeconds(date);
      case TimeFormats.dayMonth:
        return DateTime.getDayAndMonth(date);
      case TimeFormats.dayMonthYear:
        return DateTime.getDayMonthYear(date, locale);
      case TimeFormats.dayMonthShortYear:
        return DateTime.getDayMonthShortYear(date, locale);
      case TimeFormats.dayMonthYearHoursMinutes:
        return DateTime.getDayMonthYearHoursMinutes(date);
      case TimeFormats.dayMonthHoursMinutes:
        return DateTime.getDayMonthHoursMinutes(date);
      case TimeFormats.minutesSeconds:
        return DateTime.getMinutesSeconds(date);
      case TimeFormats.inputDate:
        return DateTime.getInputDate(date);
      case TimeFormats.inputDateTimeLocal:
        return DateTime.getInputDateTimeLocal(date);
      case TimeFormats.day:
        return DateTime.getDay(date);
      case TimeFormats.monthShortLocal:
        return DateTime.getShortMonthLocalized(date, locale);
      default:
        throw new Error(`Unexpected format: "${format}"`);
    }
  }

  public static roundToDayStart(timestamp: number | string | Date): number {
    const date = toDate(timestamp);

    date.setHours(0, 0, 0, 0);

    return date.getTime();
  }

  public static roundToDayEnd(timestamp: number | string | Date): number {
    const date = toDate(timestamp);

    date.setHours(23, 59, 59, 999);

    return date.getTime();
  }

  public static withTimeStamp(timestamp: number | string): DateTime {
    return new DateTime(timestamp);
  }

  public static toTimestamp(date: Date | number | string): number {
    const dateInstance = typeof date === 'string' || typeof date === 'number'
      ? new Date(date)
      : date;

    return Number(dateInstance);
  }

  public static timestampSecondsToDate(timestampSeconds: number, toUTC = false): Date {
    if (toUTC) {
      const unixStartDate = new Date(Date.UTC(1970, 0, 1));
      unixStartDate.setUTCSeconds(timestampSeconds);
      return unixStartDate;
    }
    const date = new Date(1970, 0, 1);
    date.setSeconds(timestampSeconds);
    return date;
  }

  public static now(): DateTime {
    return new DateTime(Date.now());
  }

  private getTimestamp(date: string, format: InputDateFormats): number {
    const { from, to } = this.dateNormalization[format];

    return (new Date(date.replace(from, to))).getTime();
  }

  private readonly timestamp: number;

  constructor(time: number | string, format?: InputDateFormats) {
    if (typeof time === 'number') {
      this.timestamp = time;
    } else if (format) {
      this.timestamp = this.getTimestamp(time, format);
    } else {
      this.timestamp = parseInt(time, 10);
    }

    if (Number.isNaN(this.timestamp)) {
      throw new TypeError(`Unexpected timestamp: "${time}"`);
    }
  }

  toISOString(): string {
    return (new Date(this.timestamp)).toISOString();
  }

  toTimestamp(): number {
    return this.timestamp;
  }

  toTime(): string {
    return DateTime.formatTimeStamp(this.timestamp, TimeFormats.hoursMinutes);
  }

  toDate(): string {
    return DateTime.formatTimeStamp(this.timestamp, TimeFormats.dayMonth);
  }

  toFullDate(): string {
    return DateTime.formatTimeStamp(this.timestamp, TimeFormats.dayMonthYear);
  }

  toDateTime(): string {
    return DateTime.formatTimeStamp(this.timestamp, TimeFormats.dayMonthYearHoursMinutes);
  }

  toShortDateTime(): string {
    return DateTime.formatTimeStamp(this.timestamp, TimeFormats.dayMonthHoursMinutes);
  }

  toInputDate(): string {
    return DateTime.formatTimeStamp(this.timestamp, TimeFormats.inputDate);
  }

  toInputDateTimeLocal(): string {
    return DateTime.formatTimeStamp(this.timestamp, TimeFormats.inputDateTimeLocal);
  }
}
