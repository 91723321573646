import type { WebSocketGraphQLMethod } from '@leon-hub/websocket';
import {
  onComponentActivated,
  onComponentDeactivated,
} from '@leon-hub/vue-utils';
import { WebSocketAccessRole } from '@leon-hub/websocket';

import type { WebSocketSubscriptionOptions, WebSocketNoAccessOptions } from 'web/src/modules/core/composables/web-sockets/types';

import type { WebSocketsSubscribeResult } from './useWebSockets';
import useWebSockets from './useWebSockets';

export function useLifecycleWebSockets<T extends WebSocketGraphQLMethod>(
  options: WebSocketSubscriptionOptions<T>,
): void {
  const websockets = useWebSockets();
  let socketSubscribeResult: WebSocketsSubscribeResult | undefined;

  onComponentActivated(() => {
    socketSubscribeResult = websockets.subscribe(options);
  });

  onComponentDeactivated(() => {
    socketSubscribeResult?.unsubscribe();
    socketSubscribeResult = undefined;
  });
}

export function useAuthorizedLifecycleWebSockets<T extends WebSocketGraphQLMethod>(
  options: WebSocketNoAccessOptions<T>,
): void {
  useLifecycleWebSockets({
    ...options,
    access: WebSocketAccessRole.AUTHORIZED,
  });
}
