export interface AnimationCallback {
  (amount: number, isFinished: boolean): void;
}

export type AmountAnimatorOptions = RequiredField<Pick<VAmountAnimatorProps, 'timeRange' | 'duration'>, 'timeRange' | 'duration'> & {
  callback: AnimationCallback;
  values: AnimationValue;
};

export interface AnimationValue {
  from: number;
  to: number;
}

export interface VAmountAnimatorRange {
  min: number;
  max: number;
}

export interface VAmountAnimatorProps {
  amount: number;
  duration?: number;
  timeRange?: VAmountAnimatorRange;
  stepRange?: VAmountAnimatorRange;
  isInfinite?: boolean;
  isHidden?: boolean;
}

export enum JackpotAnimatedAmountKind {
  MAX = 'max',
  MAJOR = 'major',
  MINOR = 'minor',
}

export interface JackpotAnimatedAmountProps extends VAmountAnimatorProps {
  currency: string;
  kind: JackpotAnimatedAmountKind;
}

export type SightAmountAnimatorProps = VAmountAnimatorProps;

export interface VAmountAnimatorSlots {
  default(props: { animatedValue: number }): void;
}
