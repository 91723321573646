<script lang="ts" setup>
import { toRef } from 'vue';

import { IconDirection, IconName, IconSize } from '@leon-hub/icons';

import { VIcon } from '@components/v-icon';

import { useExpandableHeadline } from 'web/src/modules/sportline/composables/list';
import type { SportlineFragmentBasisKey } from 'web/src/modules/sportline/types/list';

import type { BasePrimaryHeadlineProps, BasePrimaryHeadlineEmits } from './types';
import { defaultHeadlineBackgrounColor } from './constants';
import MarketsDropDown from './MarketsDropDown.vue';

interface PrimaryHeadlineProps extends BasePrimaryHeadlineProps {
  title?: Maybe<string>;
  backgroundColor?: string;
  basisKey: SportlineFragmentBasisKey;
}

interface PrimaryHeadlineEmits extends BasePrimaryHeadlineEmits {
  (e: 'catalog-click', event: MouseEvent): void;
}

const props = defineProps<PrimaryHeadlineProps>();
const emit = defineEmits<PrimaryHeadlineEmits>();

const title = toRef(props, 'title', null);
const backgroundColor = toRef(props, 'backgroundColor', defaultHeadlineBackgrounColor);
const hideEventsCounter = toRef(props, 'hideEventsCounter', false);
const isMarketTypesSelectionEnabled = toRef(props, 'isMarketTypesSelectionEnabled', false);
const countEvents = toRef(props, 'countEvents', 0);
const expandKey = toRef(props, 'expandKey', null);
const isExpanded = toRef(props, 'isExpanded', false);

const {
  isExpandable,
  eventsCountLabel,
  isShowEventsCounters,
  toggleExpandState,
} = useExpandableHeadline({ expandKey, isExpanded, countEvents }, emit);

function onCatalogClick(event: MouseEvent): void {
  emit('catalog-click', event);
}
</script>

<template>
  <div v-auto-id="'PrimaryHeadline'" :class="$style['sport-event-list-sport-headline']">
    <div
      :class="{
        [$style['sport-event-list-sport-headline__left']]: true,
        [$style['sport-event-list-sport-headline__left--expandable']]: isExpandable,
      }"
      @click="toggleExpandState"
    >
      <template v-if="title">
        <VIcon
          v-if="isExpandable"
          :class="$style['sport-event-list-sport-headline__expand-icon']"
          :style="{ fill: backgroundColor }"
          :direction="isExpanded ? IconDirection.NORTH : IconDirection.SOUTH"
          :size="IconSize.SIZE_24"
          :name="IconName.CHEVRON"
        />

        <div
          :class="$style['sport-event-list-sport-headline__title']"
          @click="onCatalogClick"
        >
          <span :class="$style['sport-event-list-sport-headline__label']">{{ title }}</span>
        </div>
      </template>
    </div>

    <div :class="$style['sport-event-list-sport-headline__right']">
      <MarketsDropDown
        v-if="isMarketTypesSelectionEnabled && (!isExpandable || isExpanded)"
        :basis-key="basisKey"
      />
      <span v-else-if="!hideEventsCounter && (!isExpandable || isShowEventsCounters)">
        {{ countEvents }} {{ eventsCountLabel }}
      </span>
    </div>
  </div>
</template>

<style module lang="scss">
@import '~web/src/modules/sportline/styles/list-primary-headline';
@include list-primary-headline('.sport-event-list-sport-headline');
</style>
