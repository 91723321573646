<script setup lang="ts">
import { IconName, IconSize } from '@leon-hub/icons';

import { VIcon } from '@components/v-icon';

import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';

import { BetSlipResultState } from '../../enums';
import useBetSlipResult from './composables/useBetSlipResult';
import type { BetSlipResultEmits, BetSlipResultProps } from './types';

const props = withDefaults(defineProps<BetSlipResultProps>(), {
  mode: BetSlipResultState.PENDING,
  errorMessage: '',
  totalBetsPlaced: 0,
  totalBetsAccepted: 0,
});

const emits = defineEmits<BetSlipResultEmits>();

const {
  isContentVisible,
  isPartiallyAccepted,
  iconName,
  headingText,
  descriptionText,
  isCompleted,
  isSuccess,
  isLimitsButtonShown,
  mainButtonLabel,
  mainButtonIcon,
  isCloseAndClearButtonShown,
  isPartiallyAcceptedByLimits,
  shareButtonVisible,
  goToMyBets,
  onMainButtonClick,
  onSaveAllClick,
  onCloseAndClearClick,
  onLimitsClick,
  onShareClick,
} = useBetSlipResult(props, emits);
</script>

<template>
  <div v-auto-id="'BetSlipResult'"
    :class="{
      [$style['bet-slip-result']]: true,
      [$style['bet-slip-result--completed']]: isCompleted,
      [$style['bet-slip-result--desktop']]: !!'1',
    }"
  >
    <div
      v-if="isContentVisible"
      :class="$style['bet-slip-result__container']"
    >
      <div
        :class="{
          [$style['bet-slip-result__icon']]: true,
          [$style['bet-slip-result__icon--success']]: isSuccess,
        }"
      >
        <VIcon
          v-if="isCompleted && iconName"
          :size="IconSize.SIZE_60"
          :name="iconName"
        />
      </div>
      <p
        v-if="headingText"
        :class="$style['bet-slip-result__heading']"
      >
        {{ headingText }}
      </p>
      <div :class="$style['bet-slip-result__description']">
        <template v-if="isCompleted">
          {{ descriptionText }}
          <div
            v-if="isSuccess && !isPartiallyAcceptedByLimits"
            :class="$style['bet-slip-result__description-second']"
          >
            <button
              :class="$style['bet-slip-result__to-my-bets']"
              data-test-id="my-bets-button"
              type="button"
              @click="goToMyBets"
            >
              {{ $t('WEB2_SLIP_MY_BETS') }}
            </button>
          </div>
        </template>
      </div>
    </div>
    <div
      v-show="isLimitsButtonShown || isCompleted"
      :class="$style['bet-slip-result__buttons']"
    >
      <VButton
        v-if="isLimitsButtonShown"
        v-data-test="{
          el: 'bet-slip-button_result_limits',
        }"
        full-width
        :kind="ButtonKind.PRIMARY"
        :label="$t('WEB2_PLACE_BET_LIMITS_BUTTON')"
        :class="$style['bet-slip-result__limits-button']"
        @click="onLimitsClick"
      />
      <VButton
        v-data-test="{
          el: 'bet-slip-button_result',
          mode,
        }"
        :label="mainButtonLabel"
        :kind="isSuccess ? ButtonKind.PRIMARY : ButtonKind.SECONDARY"
        :icon-name="mainButtonIcon"
        full-width
        @click="onMainButtonClick"
      />
      <VButton
        v-if="shareButtonVisible"
        :label="$t('WEB2_SHARE')"
        full-width
        :kind="ButtonKind.SECONDARY"
        :icon-name="IconName.SHARE"
        :class="$style['bet-slip-result__button-top-margin']"
        @click="onShareClick"
      />
      <VButton
        v-if="isSuccess && !isPartiallyAcceptedByLimits"
        :label="$t('WEB2_SLIP_SAVE_BETS_ALL')"
        :kind="(shareButtonVisible || !isPartiallyAccepted) ? ButtonKind.TRANSPARENT : ButtonKind.SECONDARY"
        full-width
        :class="$style['bet-slip-result__button-top-margin']"
        @click="onSaveAllClick"
      />
      <VButton
        v-if="isCloseAndClearButtonShown"
        :label="isPartiallyAcceptedByLimits ? $t('WEB2_CLOSE') : $t('WEB2_SLIP_SAVE_BETS_CLEAR')"
        :kind="ButtonKind.TRANSPARENT"
        full-width
        :class="$style['bet-slip-result__close-clear-button']"
        @click="onCloseAndClearClick"
      />
    </div>
  </div>
</template>

<style lang="scss" module>
.bet-slip-result {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--Layer1);
  opacity: 0.95;
  transition: opacity 0.25s ease;

  &--completed {
    opacity: 1;
  }

  &--desktop {
    position: static;
    align-items: flex-start;
    width: 100%;
    padding-top: 46px;
    background: none;
    opacity: 1;
  }

  &__to-my-bets {
    display: block;
    width: 100%;
    padding: 16px 0;
    margin: -16px 0;
    color: var(--TextSecondary);
    text-align: center;
    text-decoration: underline;
    cursor: pointer;
    background-color: transparent;
    border: none;

    @include for-hover {
      &:hover {
        color: var(--TextDefault);
      }
    }
  }

  &__container {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0 20px;
    text-align: center;
  }

  &--desktop &__container {
    justify-content: flex-start;
  }

  &__icon {
    margin: 0 0 20px;
    color: var(--TextSecondary);

    &--success {
      color: var(--BrandDefault);
    }
  }

  &__heading {
    @include medium\18\28;

    padding: 0;
    margin: 0 0 8px;
    color: var(--TextDefault);
  }

  &__to-my-bets,
  &__description {
    @include regular\14\24\025;

    color: var(--TextPrimary);
  }

  &__description {
    padding: 0;
    margin: 0;
  }

  &__description-second {
    margin-top: 20px;
  }

  &__buttons {
    flex: none;
    align-self: stretch;
    padding: 16px 16px 40px;
  }

  &__limits-button {
    margin-bottom: 8px;
  }

  &__button-top-margin {
    margin-top: 8px;
  }

  &__close-clear-button {
    margin: 20px 0 0;
  }
}
</style>
