import type {
  Market,
  MarketTypeId,
  SportMarketGroup,
  MarketSpecifierId,
} from 'web/src/modules/sportline/types';
import { compareAsc } from 'web/src/utils/sort';

import type { MarketsGridFilter, MarketsGridFilterId } from '../types';
import { getFilterForMarketGroup } from './getFilterForMarketGroup';

export interface MinimalMarketsGridMarket {
  id: Market['id'];
  type: Pick<Market['type'], 'id'>;
  specifiers?: Market['specifiers'];
}

type MarketByTypeMap = Record<MarketTypeId, MinimalMarketsGridMarket[]>;

type MarketsGridFilterMap = Map<MarketsGridFilterId, MarketsGridFilter>;

function checkSimpleTypeFilter(group: SportMarketGroup, marketsByType: MarketByTypeMap, result: MarketsGridFilterMap): boolean {
  if (group.specifier) { return false; }

  if (!group.marketTypeIds.some((typeId) => !!marketsByType[typeId]?.length)) {
    return true;
  }

  const filter = getFilterForMarketGroup(group);

  if (!result.has(filter.id)) {
    result.set(filter.id, filter);
  }

  return true;
}

function createFiltersForSpecifierMap(
  group: SportMarketGroup,
  marketsInGroup: MinimalMarketsGridMarket[],
): Map<MarketSpecifierId, MarketsGridFilter[]> {
  const groupSpecifier = group.specifier;
  const filtersForSpecifierMap = new Map<MarketSpecifierId, MarketsGridFilter[]>();

  if (!groupSpecifier) {
    return filtersForSpecifierMap;
  }

  for (const market of marketsInGroup) {
    if (!market.specifiers?.length) {
      continue;
    }

    for (const specifier of market.specifiers) {
      if (specifier.id === groupSpecifier.id) {
        const filter = getFilterForMarketGroup(group, specifier);
        const filters = filtersForSpecifierMap.get(groupSpecifier.id) ?? [];
        filters.push(filter);
        filtersForSpecifierMap.set(groupSpecifier.id, filters);
      }
    }
  }

  return filtersForSpecifierMap;
}

function checkTypeWithSpecifiersFilter(group: SportMarketGroup, marketsByType: MarketByTypeMap, result: MarketsGridFilterMap): boolean {
  const groupSpecifier = group.specifier;

  if (!groupSpecifier) {
    return false;
  }

  const marketsInGroup = group.marketTypeIds.flatMap((typeId) => marketsByType[typeId] ?? []);

  if (!marketsInGroup.length) {
    return true;
  }

  const filtersForSpecifierMap = createFiltersForSpecifierMap(group, marketsInGroup);

  for (const filters of [...filtersForSpecifierMap.values()]) {
    // @see LEONWEB-14926 keep filters in the alphabetical order
    const orderedValues = filters.sort((a, b) => compareAsc(a.specifier!.value, b.specifier!.value));

    for (const filter of orderedValues) {
      if (!result.has(filter.id)) {
        result.set(filter.id, filter);
      }
    }
  }

  return true;
}

export function getNonEmptyMarketsGridFilters(
  marketGroups: SportMarketGroup[] = [],
  markets: MinimalMarketsGridMarket[] = [],
): MarketsGridFilter[] {
  const result: MarketsGridFilterMap = new Map();

  const marketsByType: MarketByTypeMap = {};

  for (const market of markets) {
    const typeId = market.type.id;
    if (!marketsByType[typeId]) { marketsByType[typeId] = []; }
    marketsByType[typeId].push(market);
  }

  for (const group of marketGroups) {
    void (checkSimpleTypeFilter(group, marketsByType, result) || checkTypeWithSpecifiersFilter(group, marketsByType, result));
  }

  return [...result.values()];
}
