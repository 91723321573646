import type { CombinedSlipEntryInfo } from '../types';

export default function getMultiSinglesCommonMinStake(availableItems: CombinedSlipEntryInfo[]): number {
  const limits = availableItems.map((entry) => entry.metaInfo?.minStake || 0);
  if (!limits.length) {
    return 0;
  }
  // yep, Math.max here
  return Math.max(...limits);
}
