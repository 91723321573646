import type { Ref } from 'vue';
import {
  computed,
  ref,
  watch,
  onBeforeUnmount,
} from 'vue';

import { assert } from '@leon-hub/guards';
import { Timer, setInputCursorAfterValue } from '@leon-hub/utils';

import { InputEventType } from 'web/src/components/Input/enums';

import {
  generateSmsInputValue,
  setInputCursorPositionOnClick,
  getMatchedSmsInputSubfield,
} from '../../utils';
import type { BaseInputEmits, SmsCodeInputProps, VInputInputEvent } from '../../types';

interface UseSmsCodeInputOutput {
  inputValue: Ref<string[]>;
  focusIndex: Ref<number>;
  hoverIndex: Ref<number>;
  onPaste: (event: ClipboardEvent, startIndex: number) => void;
  onFocus: (index: number) => void;
  onBlur: () => void;
  focusOnFirstSubfield: () => void;
  onKeyDown: (event: KeyboardEvent, index: number) => void;
  onInput: (event: InputEvent, index: number) => void;
  onMouseDown: (event: MouseEvent, index: number) => void;
  onMouseOver: (index: number) => void;
  onMouseLeave: () => void;
}

const useSmsCodeInput = (
  props: Required<Pick<SmsCodeInputProps, 'value' | 'size' | 'name'>>,
  emit: BaseInputEmits,
  inputId: string,
): UseSmsCodeInputOutput => {
  const inputValue = ref<string[]>(generateSmsInputValue(props.value, props.size));

  const focusIndex = ref<number>(-1);

  const hoverIndex = ref<number>(-1);

  const setFocusIndex = (index = -1): void => {
    focusIndex.value = index;
  };

  const onMouseOver = (index = -1): void => {
    hoverIndex.value = index;
  };

  const onMouseLeave = (): void => {
    hoverIndex.value = -1;
  };

  const isFocusInside = ref<boolean>(false);

  const regenerateInputValue = () => {
    inputValue.value = generateSmsInputValue(props.value, props.size);
  };

  watch(() => props.value, () => {
    regenerateInputValue();
  });

  watch(() => props.size, () => {
    regenerateInputValue();
  });

  const inputValueAsString = computed<string>(() => inputValue.value.map((item) => (item || ' ')).join(''));

  const valueToEmit = computed<VInputInputEvent>(() => ({
    target: {
      value: inputValueAsString.value,
      name: props.name,
    },
  }));

  const emitInput = (): void => {
    emit(InputEventType.INPUT, valueToEmit.value);
  };

  const emitChange = (): void => {
    emit(InputEventType.CHANGE, valueToEmit.value);
  };

  const emitFocus = (): void => {
    emit(InputEventType.FOCUS, valueToEmit.value);
  };

  const emitBlur = (): void => {
    emit(InputEventType.BLUR, valueToEmit.value);
  };

  const focusOnSubfield = (index = 0): void => {
    if (index < 0 || index > props.size - 1) {
      return;
    }
    const nextInput = getMatchedSmsInputSubfield(`${inputId}-${index}`);
    assert(nextInput, 'SmsInput do not exist');
    nextInput.focus();
    setFocusIndex(index);
    isFocusInside.value = true;
    setInputCursorAfterValue(nextInput);
  };

  const triggerExternalEvents = (currentElement: HTMLInputElement, nextIndex: number): void => {
    emitInput();
    if (!currentElement.value) {
      return;
    }
    focusOnSubfield(nextIndex);
    if (currentElement.value.includes('')) {
      return;
    }

    emitChange();
    emitBlur();
    setFocusIndex(-1);
  };

  const setInputValueByIndex = (index: number, updatedValue: string): void => {
    const storedValue = inputValueAsString.value;
    const start = storedValue.slice(0, index);
    const end = storedValue.slice(index + (updatedValue.length || 1));
    const merged = `${start}${updatedValue || ' '}${end}`;
    inputValue.value = generateSmsInputValue(merged, props.size);
  };

  const replaceValueByIndex = (index: number, updatedValue: string): void => {
    setInputValueByIndex(index, updatedValue);
    const matchedInputElement = getMatchedSmsInputSubfield(`${inputId}-${index}`);
    assert(matchedInputElement);
    matchedInputElement.value = updatedValue;
  };

  const handleInserting = (currentElement: HTMLInputElement, value: string, index: number): void => {
    const oldValue = inputValue.value[index] || '';
    let newValue = value;
    if (value.length > 1) {
      // remove oldValue, we don't know position of it
      newValue = value.replace(oldValue, '');
    }
    // remove not a number
    newValue = newValue.replace(/\D+/g, '');
    if (!newValue) {
      newValue = oldValue;
    }

    replaceValueByIndex(index, newValue);
    triggerExternalEvents(currentElement, index + 1);
  };

  const onPaste = (event: ClipboardEvent, startIndex = 0): void => {
    event.preventDefault();

    const { clipboardData } = event;
    if (!clipboardData) {
      return;
    }

    const data = clipboardData.getData('text');
    const rightBorder = Math.min(startIndex + data.length, props.size);

    for (
      let index = startIndex, stringIndex = 0;
      index < rightBorder;
      index += 1, stringIndex += 1
    ) {
      const input = getMatchedSmsInputSubfield(`${inputId}-${index}`);
      assert(input);
      handleInserting(input, data[stringIndex], index);
    }
  };

  const handleDeleting = (index: number): void => {
    const currentValue = inputValue.value[index];
    if (currentValue) {
      replaceValueByIndex(index, '');
      emitInput();
      return;
    }

    // we placed at the first input
    if (index === 0) {
      return;
    }

    const leftIndex = index - 1;
    replaceValueByIndex(leftIndex, '');
    emitInput();
    focusOnSubfield(leftIndex);
  };

  const onKeyDown = (event: KeyboardEvent, index: number) => {
    const { key } = event;
    switch (key) {
      case 'Backspace':
        event.preventDefault();
        handleDeleting(index);
        break;
      case 'ArrowLeft':
        event.preventDefault();
        focusOnSubfield(index - 1);
        break;
      case 'ArrowRight':
        event.preventDefault();
        focusOnSubfield(index + 1);
        break;
      default:
        break;
    }
  };

  const focusOnFirstSubfield = (): void => {
    focusOnSubfield(0);
  };

  let blurTimeoutId = 0;

  const clearBlurTimeout = () => {
    Timer.clearTimeout(blurTimeoutId);
  };

  onBeforeUnmount(clearBlurTimeout);

  const onFocus = (index: number) => {
    if (!isFocusInside.value) {
      emitFocus();
    }
    clearBlurTimeout();
    // eslint-disable-next-line sonarjs/no-collapsible-if
    if (!process.env.VUE_APP_LAYOUT_DESKTOP) {
      // if is mobile and value is empty
      if (!isFocusInside.value && inputValue.value.join('').trim() === '') {
        focusOnFirstSubfield();
        return;
      }
    }
    isFocusInside.value = true;
    setFocusIndex(index);
  };

  const onBlur = (): void => {
    setFocusIndex(-1);
    // next tick is not a solution for this case
    blurTimeoutId = Timer.setTimeout(() => {
      isFocusInside.value = false;
      emitBlur();
    }, 0);
  };

  const onInput = (event: InputEvent, index: number):void => {
    const { target } = event;

    assert(target instanceof HTMLInputElement, 'target should be of type HTMLInputElement');
    // we need it for android, since deleting/inserting value on android
    // generates: code: "", key: "Unidentified", keyCode: 229
    if (event.inputType === 'deleteContentBackward') {
      event.preventDefault();
      handleDeleting(index);
      return;
    }

    handleInserting(target, target.value, index);
  };

  const onMouseDown = (event: MouseEvent, index: number): void => {
    const hasFocus = focusIndex.value === index;
    setInputCursorPositionOnClick(event, hasFocus);
  };

  return {
    inputValue,
    focusIndex,
    hoverIndex,
    onPaste,
    onFocus,
    onBlur,
    onKeyDown,
    onInput,
    onMouseDown,
    focusOnFirstSubfield,
    onMouseOver,
    onMouseLeave,
  };
};

export default useSmsCodeInput;
