import type { Ref } from 'vue';
import {
  ref,
} from 'vue';

import type { BetlineFlag } from 'web/src/modules/sportline/enums/rest';
import { useGlobalSportlineEvents } from 'web/src/modules/sportline/composables/core';
import {
  useParseSportlineSettingsRef,
  useSportlineTopEventsSettings,
} from 'web/src/modules/sportline/composables/settings';
import type { BuildSportOptions } from 'web/src/modules/sportline/types/rest';
import { extractBuildSportOptions } from 'web/src/modules/sportline/utils/rest/pre-build';

export interface UseLobbySportlineEventsComposable {
  sportlineFlags: Ref<BetlineFlag[]>;
  parseSportlineSettings: Ref<BuildSportOptions>;
  onGlobalSportlineEventsInit(): void;
}

const emptyLobbySportlineComposable: UseLobbySportlineEventsComposable = {
  sportlineFlags: ref<BetlineFlag[]>([]),
  parseSportlineSettings: ref<BuildSportOptions>(extractBuildSportOptions()),
  onGlobalSportlineEventsInit: () => {},
};

export function useLobbySportlineEvents(): UseLobbySportlineEventsComposable {
  if (process.env.VUE_APP_FEATURE_SPORTLINE_DISABLED) {
    return emptyLobbySportlineComposable;
  }

  const {
    onInit: onGlobalSportlineEventsInit,
  } = useGlobalSportlineEvents();

  const {
    flags: sportlineFlags,
  } = useSportlineTopEventsSettings();

  return {
    sportlineFlags,
    parseSportlineSettings: useParseSportlineSettingsRef(),
    onGlobalSportlineEventsInit,
  };
}
