import type { Ref } from 'vue';
import { computed, toRef } from 'vue';

import { LoginType } from '@leon-hub/api-sdk';

import type { RestorePasswordType } from 'web/src/modules/restore-password/store/types';
import type { SwitcherOption, VSwitcherProps } from 'web/src/components/Switcher/VSwitcher/types';
import type { TabsItem } from 'web/src/components/Tabs';
import type { VTabsProps } from 'web/src/components/Tabs/VTabs/types';
import { SwitcherKind } from 'web/src/components/Switcher/VSwitcher/enums';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useRestorePasswordStore } from 'web/src/modules/restore-password/store';

export interface RestoreContainerComposable {
  isCaptchaEnabled: Ref<boolean>;
  tabsProperties: Ref<VTabsProps>;
  switchProperties: Ref<VSwitcherProps>;
  onTabClick: (tabName: RestorePasswordType) => void;
}

export default function useRestoreContainer(): RestoreContainerComposable {
  const { $translate } = useI18n();
  const restorePasswordStore = useRestorePasswordStore();

  const isCaptchaEnabled = toRef(restorePasswordStore, 'captchaEnabled');
  const isPhoneTabEnabled = toRef(restorePasswordStore, 'isPhoneTabEnabled');
  const isEmailTabEnabled = toRef(restorePasswordStore, 'isEmailTabEnabled');
  const activeTab = toRef(restorePasswordStore, 'activeTab');

  const tabsProperties = computed<VTabsProps>(() => {
    const items: TabsItem[] = [];

    if (isEmailTabEnabled.value) {
      items.push({ id: LoginType.EMAIL, label: $translate('RESTORE_PASSWORD_TAB_EMAIL').value });
    }
    if (isPhoneTabEnabled.value) {
      items.push({ id: LoginType.PHONE, label: $translate('RESTORE_PASSWORD_TAB_PHONE').value });
    }

    return {
      items,
      activeId: activeTab.value,
      fullWidth: true,
    };
  });

  const switchProperties = computed(() => {
    const options: SwitcherOption[] = [];

    if (isEmailTabEnabled.value) {
      options.push({ id: LoginType.EMAIL, label: $translate('RESTORE_PASSWORD_TAB_EMAIL').value });
    }
    if (isPhoneTabEnabled.value) {
      options.push({ id: LoginType.PHONE, label: $translate('RESTORE_PASSWORD_TAB_PHONE').value });
    }

    return {
      options,
      kind: SwitcherKind.PRIMARY,
      activeId: activeTab.value,
    };
  });

  function onTabClick(tabName: RestorePasswordType): void {
    restorePasswordStore.setActiveTab(tabName);
  }

  return {
    isCaptchaEnabled,
    tabsProperties,
    switchProperties,
    onTabClick,
  };
}
