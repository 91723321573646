<script lang="ts" setup>
import { IconName } from '@leon-hub/icons';

import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';

import type { VImagePreviewDesktopProps, VImagePreviewDesktopEmits } from './types';

defineProps<VImagePreviewDesktopProps>();

const emit = defineEmits<VImagePreviewDesktopEmits>();

function emitClose(): void {
  emit('close');
}
</script>

<template>
  <Teleport v-auto-id="'VImagePreviewDesktop'" to="body">
    <div
      :class="$style['image-preview-desktop']"
    >
      <div
        :class="$style['image-preview-desktop__overlay']"
        @click="emitClose"
      />
      <div :class="$style['image-preview-desktop__image-container']">
        <img
          :src="src"
          :class="$style['image-preview-desktop__image']"
          :alt="name || '...'"
        >
        <VButton
          :kind="ButtonKind.TRANSPARENT"
          :icon-name="IconName.CROSS"
          :class="$style['image-preview-desktop__close']"
          @click="emitClose"
        />
      </div>
    </div>
  </Teleport>
</template>

<style lang="scss" module>
.image-preview-desktop {
  position: fixed;
  z-index: z-index(image-preview);
  display: flex;
  align-items: center;
  justify-content: center;

  &,
  &__overlay {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &__overlay {
    position: absolute;
    background-color: rgba(23, 26, 28, 0.7);
    backdrop-filter: blur(5px);
  }

  &__image {
    width: auto;
    max-width: 100%;
    height: calc(100vh - 150px);
    max-height: 100%;
    object-fit: contain;
    image-orientation: from-image;
  }

  &__image-container {
    position: relative;
    max-width: calc(100% - 88px);
    max-height: 80%;
    background: var(--Layer0);
  }

  &__close {
    position: absolute;
    top: 0;
    right: -44px;
    color: var(--BrandText);
  }
}
</style>
