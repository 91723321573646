/* eslint-disable sonarjs/prefer-single-boolean-return */
import type { IgnoredError } from '@leon-hub/api-sdk';
import { IgnoredErrorType } from '@leon-hub/api-sdk';
import type { Maybe } from '@leon-hub/types';
import { isString } from '@leon-hub/guards';
import { isSentryLoggableError } from '@leon-hub/errors-whitelist';

export default function isLoggableError(
  error: unknown,
  ignoredErrors: Maybe<readonly IgnoredError[]>,
): boolean {
  return isSentryLoggableError(error) && !isIgnoredErrorMessage(error, ignoredErrors);
}

function isIgnoredErrorMessage(
  error: unknown,
  ignoredErrors: Maybe<readonly IgnoredError[]>,
): boolean {
  if (!ignoredErrors) {
    return false;
  }

  const errorCombined = getErrorMessage(error);
  if (!errorCombined) {
    return false;
  }

  for (const ignoredError of ignoredErrors) {
    const ignoredErrorMessage: string = ignoredError.error;
    if (ignoredError.type === IgnoredErrorType.TEXT
      && errorCombined.includes(ignoredErrorMessage)) {
      return true;
    }

    if (ignoredError.type === IgnoredErrorType.REGEXP) {
      const regexp = new RegExp(ignoredErrorMessage);
      if (regexp.test(errorCombined)) {
        return true;
      }
    }
  }

  return false;
}

function getErrorMessage(error: unknown) {
  let errorMessage = '';
  let errorStack = '';
  if (error instanceof Error) {
    if (error.message) {
      errorMessage = error.message;
    }

    if (error.stack) {
      errorStack = error.stack;
    }
  } else if (isString(error)) {
    errorMessage = String(error);
  }

  if (!errorMessage && !errorStack) {
    return '';
  }

  return `${errorMessage} ${errorStack}`;
}
