import type NavigationItem from '../types/NavigationItem';

export default function isNavigationItemActive<T extends NavigationItem>(
  navigationItem: T,
  routeName?: string | null | symbol,
): boolean {
  if (navigationItem.isActive !== undefined) {
    return navigationItem.isActive;
  }

  if (routeName === navigationItem.routeName) {
    return true;
  }

  if (routeName && navigationItem.navigatedFrom) {
    return navigationItem.navigatedFrom?.includes(String(routeName));
  }

  if (navigationItem.children?.length) {
    return navigationItem.children.some((navItemChild) => isNavigationItemActive(navItemChild, routeName));
  }

  return false;
}
