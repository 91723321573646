import { defineStore } from 'pinia';

import { useDialogs } from 'web/src/modules/dialogs/composables';
import { useSportlineApiService } from 'web/src/modules/sportline/services';
import type { SportlineEventDetails } from 'web/src/modules/sportline/types/details';

import {
  useExtendedEventBackgroundRequestsStoreComposable,
  useExtendedEventIdsStoreComposable,
  useExtendedEventSettings,
} from './composables';
import type {
  ExtendedEventsId,
  ChangeExtendedState,
  GetEventInfoRef,
  GetEventInfo,
  GetEventIdsListRef,
} from './types';
import { createFetchEventDetailsMethod, createEventDetailsRefsMap } from './utils';

interface ExtendedEventStore {
  createEventIsOpenedRef: GetEventInfoRef<boolean>;
  getEventIsOpened: GetEventInfo<boolean>;
  addEventIdToOpened: ChangeExtendedState;
  removeEventIdFromOpened: ChangeExtendedState;
  addEventIdToBackgroundRequests: ChangeExtendedState;
  removeEventIdFromBackgroundRequests: ChangeExtendedState;
  loadSportlineEventDetails(id: ExtendedEventsId, silent?: boolean): Promise<void>;
  createEventDetailsRef: GetEventInfoRef<Maybe<SportlineEventDetails>>;
  createOpenedEventsListRef: GetEventIdsListRef;
}

/**
 * Open markets in the events list
 */
export const useExtendedEventStore = defineStore('sportline-extended-event-store', (): ExtendedEventStore => {
  const { showDialog } = useDialogs();
  const apiService = useSportlineApiService();
  const { parseSportlineSettings } = useExtendedEventSettings();

  const {
    addEventId: addEventIdToOpened,
    removeEventId: removeEventIdFromOpened,
    createIsEventInListRef: createEventIsOpenedRef,
    getIsEventInList: getEventIsOpened,
    createListRef: createOpenedEventsListRef,
  } = useExtendedEventIdsStoreComposable({ maxInType: 1 });
  const {
    addEventId: addEventIdToBackgroundRequests,
    removeEventId: removeEventIdFromBackgroundRequests,
    fullEventsIds: backgroundRequestsEventsIds,
  } = useExtendedEventIdsStoreComposable();
  const {
    setEventDetailsData,
    createEventDetailsRef,
  } = createEventDetailsRefsMap();

  const fetchSportlineEventDetails = createFetchEventDetailsMethod(
    apiService,
    { parseSportlineSettings },
    { showDialog },
  );

  async function loadSportlineEventDetails(id: ExtendedEventsId, silent = false): Promise<void> {
    const sportlineEventDetails = await fetchSportlineEventDetails({ id, silent });
    if (!sportlineEventDetails) { return; }
    setEventDetailsData(id, sportlineEventDetails);
  }

  async function backgroundRequest(): Promise<void> {
    const requests = backgroundRequestsEventsIds.value.map((id) => loadSportlineEventDetails(id, true));
    await Promise.allSettled(requests);
  }

  useExtendedEventBackgroundRequestsStoreComposable({ eventsIds: backgroundRequestsEventsIds }, backgroundRequest);

  return {
    createEventIsOpenedRef,
    getEventIsOpened,
    createOpenedEventsListRef,
    addEventIdToOpened,
    removeEventIdFromOpened,
    addEventIdToBackgroundRequests,
    removeEventIdFromBackgroundRequests,
    createEventDetailsRef,
    loadSportlineEventDetails,
  };
});
