import type { ObjectEntry, StringEntry } from '@leon-hub/api-sdk';
import { assert, isString } from '@leon-hub/guards';

import { isValueList } from 'web/src/utils/jsonSchemaAdapter/utils/isStringEntryArray';

export default function getUiFieldRawOptions(fieldOptions: StringEntry[] = []): Dictionary<string | ObjectEntry[]> {
  const result: Record<string, string | ObjectEntry[]> = {};
  for (const item of fieldOptions) {
    assert(isString(item.key) && item.key.length > 0, 'key should be non empty string');
    if (item.valueList?.length) {
      result[item.key] = (item.valueList ?? []);
      assert(isValueList(result[item.key]));
    } else {
      result[item.key] = (item.value ?? '');
      assert(isString(result[item.key]));
    }
  }
  return result;
}
