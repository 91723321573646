import type { CoreSportEvent } from 'web/src/modules/sportline/types';
import { SportEventStatus } from 'web/src/modules/sportline/enums';

export function isSportlineEventSuspended(sportEvent: Maybe<Pick<CoreSportEvent, 'status'>>): boolean {
  if (!sportEvent) {
    return false;
  }

  return [
    SportEventStatus.Suspended,
    SportEventStatus.Closed,
  ].includes(sportEvent.status);
}
