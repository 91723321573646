import { toRef, computed, watch } from 'vue';
import { defineStore } from 'pinia';

import { useGlobalSportlineEvents } from 'web/src/modules/sportline/composables/core';
import { useSportlinePageIdentifier } from 'web/src/modules/sportline/store/composables';
import { useSportlineEventStatisticStore } from 'web/src/modules/sportline/submodules/event-statistic/store';
import { useSyncSportline } from 'web/src/modules/sportline/submodules/sync-sportline';
import { useEventDetailsSportlineUpdateTimeout } from 'web/src/modules/sportline/submodules/update-timeout';
import useAwaitConditionQueue from 'web/src/utils/store/composables/useAwaitConditionQueue';
import { useCustomerFavoritesService } from 'web/src/modules/sportline/submodules/favorites/composables';
import useBackgroundRequestsLifeCycle from 'web/src/utils/store/composables/useBackgroundRequestsLifeCycle';
import type { CoreSportlineFetchOptions } from 'web/src/modules/sportline/types/rest';
import type { Payloads } from 'web/src/utils/store/composables/useBackgroundRequestsLifeCycle';
import type { SportlinePageIdentifier as SportEventsPageIdentifier } from 'web/src/modules/sportline/types/navigation';
import { CustomerFavoritesIdsBackgroundUpdateKey } from 'web/src/modules/sportline/submodules/favorites/enums';
import { BackgroundUpdateStopwatch } from 'web/src/utils/store';

import {
  useSportEventWidgetConfig,
  useActiveSlideStoreComposable,
  useRelatedEventsStoreComposable,
  useMarketsLayoutTypeStoreComposable,
  useSportlineEventDetailsDataStoreComposable,
  useSportlineEventDetailsMiniBoardStoreComposable,
  useSportlineEventDetailsRequestStatusBehaviours,
} from './composables';
import {
  createOnInitBehaviourFactory,
  createStayingOnPageBehaviourFactory,
} from './utils';

export const useSportlineEventDetailsStore = defineStore('sportline-event-details', () => {
  const {
    pageIdentifier,
    setPageIdentifier,
  } = useSportlinePageIdentifier();
  const {
    onInit: onGlobalSportlineEventsInit,
  } = useGlobalSportlineEvents();

  const { timeout: eventDetailsUpdateInterval } = useEventDetailsSportlineUpdateTimeout();

  const sportlineEventStatisticStore = useSportlineEventStatisticStore();
  const isSportEventStatisticEnabled = toRef(sportlineEventStatisticStore, 'isSportEventStatisticEnabled');
  const isPostMatchStatisticLoaded = toRef(sportlineEventStatisticStore, 'isPostMatchStatisticLoaded');
  const hasPostMatchStatistics = toRef(sportlineEventStatisticStore, 'hasPostMatchStatistics');
  const { startLoadingPostMatchStatistics } = sportlineEventStatisticStore;

  const customerFavoritesService = useCustomerFavoritesService();
  const requestStatusBehaviours = useSportlineEventDetailsRequestStatusBehaviours();

  const {
    isLoaded,
    isShowingLoadingIndicator,
    rawSportEventDetails,
    sportEventDetailsPreview,
    sportEventDetails,
    sportEventDetailsId,
    sportEventDetailsLoadedId,
    sportElement,
    sport,
    region,
    league,
    setDetailsStatus,
    clearDetailsMarketsList,
    setRawSportEventDetails,
    fetchDetails,
    onInit: onEventDetailsDataInit,
    streamInfo,
  } = useSportlineEventDetailsDataStoreComposable({
    pageIdentifier,
    fireRequestStatus: requestStatusBehaviours.fireRequestStatus,
  }, {
    sportEventDetailsUpdated(value): void {
      sportlineEventStatisticStore.setCurrentLoadedMatchIdentifier(value);
    },
  });

  const {
    clearStreamInfo,
    reloadStream,
    ...publicStreamInterface
  } = streamInfo;

  const {
    relatedSportListElement,
    leagueEventsList,
    setBackgroundUpdateEnabled: setRelatedEventsBackgroundUpdateEnabled,
    onInit: onRelatedEventsInit,
  } = useRelatedEventsStoreComposable({
    pageIdentifier,
    sportEventDetails,
    sportEventDetailsId,
  });
  const miniBoardInterface = useSportlineEventDetailsMiniBoardStoreComposable({ sportlineEvent: sportEventDetails });
  const {
    sportEventWidgetConfig,
    liveWidgetType,
  } = useSportEventWidgetConfig({
    sportEventDetails,
  });
  const {
    defaultLayoutType,
    setDefaultLayoutType,
  } = useMarketsLayoutTypeStoreComposable();
  const {
    restoreSwiperSlideId,
    setInitialSwiperSlideId,
    ...activeSlideInterface
  } = useActiveSlideStoreComposable();

  const {
    awaitCondition: awaitStateIsLoaded,
  } = useAwaitConditionQueue({
    source: 'Sportline event details is loaded',
    value: isLoaded,
    condition: (value: boolean) => value,
  });
  const {
    awaitCondition: awaitLeavePage,
  } = useAwaitConditionQueue({
    source: 'Leave sportline event details page',
    value: pageIdentifier,
    condition: (value: SportEventsPageIdentifier) => !value.sportEventId,
  });
  const {
    awaitCondition: awaitPostMatchStatisticIsLoaded,
  } = useAwaitConditionQueue({
    source: 'Sportline event details statistic is loaded',
    value: isPostMatchStatisticLoaded,
    condition: (value: boolean) => value,
  });

  /** Return true if we have some content to display e.g. loaded event, preview or post statistic */
  const isReadyToDisplayContent = computed<boolean>(() => {
    // check rawSportEventDetails === false before isLoaded
    // because false event details means load missing event
    if (rawSportEventDetails.value === false) {
      return isSportEventStatisticEnabled.value
        ? isPostMatchStatisticLoaded.value
        : true;
    }

    if (isLoaded.value) {
      return true;
    }

    const preview = sportEventDetailsPreview.value;
    const targetId = pageIdentifier.value.sportEventId;

    return !!preview?.sportEvent && preview.sportEvent.id === targetId;
  });

  const lastUpdate = new BackgroundUpdateStopwatch<['eventDetails']>({
    eventDetails: async ({ silent, skip }: CoreSportlineFetchOptions & { skip?: boolean }) => {
      // skip updates for set background update (reloaded by initial requests)
      if (skip) { return; }

      if (rawSportEventDetails.value !== false) {
        // do not update event data after Missing status
        await fetchDetails({ silent });
      }

      lastUpdate.update('eventDetails');
    },
  });

  const {
    initialRequests: initialLifeCycleRequests,
    syncState,
    setBackgroundUpdateEnabled: setLifeCycleBackgroundUpdateEnabled,
  } = useBackgroundRequestsLifeCycle({
    lastUpdate,
    updateInterval: eventDetailsUpdateInterval,
    onEnableChanged(value: boolean): void {
      setRelatedEventsBackgroundUpdateEnabled(value);
      sportlineEventStatisticStore.setBackgroundUpdateEnabled(value);
      void customerFavoritesService.setIsBackgroundIdsUpdateAvailable(
        CustomerFavoritesIdsBackgroundUpdateKey.DETAILS,
        value,
      );
    },
  });

  function clearDetails(): void {
    setRawSportEventDetails(null);
    sportlineEventStatisticStore.clearStatistic();
    clearStreamInfo();
  }

  async function initialRequests(): Promise<void> {
    if (sportEventDetailsLoadedId.value === pageIdentifier.value.sportEventId) {
      // this event already loaded, will be used sync background
      return;
    }

    await Promise.all([
      initialLifeCycleRequests(),
      sportlineEventStatisticStore.initialRequests(),
    ]);
  }

  function setBackgroundUpdateEnabled(value: boolean): Promise<void> {
    // we will skip reloading for the initial set background requests true
    const skipUpdate = sportEventDetailsLoadedId.value !== pageIdentifier.value.sportEventId;
    const payload: Payloads<['eventDetails']> = {
      eventDetails: { skip: skipUpdate },
    };
    return setLifeCycleBackgroundUpdateEnabled(value, payload);
  }

  // on init
  onGlobalSportlineEventsInit();
  restoreSwiperSlideId();
  onEventDetailsDataInit();
  onRelatedEventsInit();
  useSyncSportline((silent) => syncState({ silent }), eventDetailsUpdateInterval);

  const createStayingOnPageBehaviour = createStayingOnPageBehaviourFactory(requestStatusBehaviours, {
    setDetailsStatus,
    clearDetailsMarketsList,
    loadSportMatchStatistics: startLoadingPostMatchStatistics,
  });
  const createOnInitBehaviour = createOnInitBehaviourFactory(requestStatusBehaviours, {
    createStayingOnPageBehaviour,
    awaitPostMatchStatisticIsLoaded,
    setRawSportEventDetails,
    hasPostMatchStatistics,
  });

  watch(pageIdentifier, (value, oldValue) => {
    void setBackgroundUpdateEnabled(!!value.sportEventId);
    setInitialSwiperSlideId();

    if (!value.sportEventId) {
      setRawSportEventDetails(null);
    }

    if (oldValue?.sportEventId) {
      requestStatusBehaviours.removeBehaviours(oldValue.sportEventId);
    }
  });

  return {
    ...publicStreamInterface,
    ...activeSlideInterface,
    ...miniBoardInterface,
    relatedSportListElement,
    leagueEventsList,
    pageIdentifier,
    setPageIdentifier,
    isLoaded,
    isShowingLoadingIndicator,
    sportEventDetailsPreview,
    sportEventDetails,
    sportElement,
    sport,
    region,
    league,
    sportEventWidgetConfig,
    reloadStream,
    defaultLayoutType,
    setDefaultLayoutType,
    awaitStateIsLoaded,
    awaitLeavePage,
    awaitPostMatchStatisticIsLoaded,
    clearDetails,
    isReadyToDisplayContent,
    liveWidgetType,
    setBackgroundUpdateEnabled,
    initialRequests,
    createOnInitBehaviour,
  };
});
