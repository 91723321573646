import { IntercomWidgetPadding } from 'web/src/modules/intercom/enums';

export default function getPadding(position: IntercomWidgetPadding): number {
  if (process.env.VUE_APP_LAYOUT_PHONE) {
    switch (position) {
      case IntercomWidgetPadding.HORIZONTAL:
        return 8;
      case IntercomWidgetPadding.VERTICAL:
        return 70;
      default:
        return 0;
    }
  } else {
    switch (position) {
      case IntercomWidgetPadding.HORIZONTAL:
        return 16;
      case IntercomWidgetPadding.VERTICAL:
        return 16;
      default:
        return 0;
    }
  }
}
