enum SwiperProvidableKeys {
  State = 'swiperState',
  SlideToNextVisibleSlide = 'slideToNextVisibleSlide',
  SlideToPreviousVisibleSlide = 'slideToPreviousVisibleSlide',
  OnSlideToggle = 'onSlideToggle',
  SlideToSlide = 'slideToSlide',
  SlideToNextSlide = 'slideToNextSlide',
  SlideToPreviousSlide = 'slideToPreviousSlide',
}

export default SwiperProvidableKeys;
