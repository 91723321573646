import type { Ref } from 'vue';
import {
  onUnmounted,
  computed, onBeforeUnmount, onMounted, toRef, watch,
} from 'vue';
import { useRouter } from 'vue-router';

import { CustomerFieldType, CustomerRegistrationType } from '@leon-hub/api-sdk';
import { assert, isString } from '@leon-hub/guards';
import { RouteName } from '@leon-hub/routing-config-names';
import { logger as loggerInstance } from '@leon-hub/logging';
import { AbstractError } from '@leon-hub/errors';

import { useRegistrationComplete } from 'web/src/modules/registration/store/composables/useRegistrationComplete';
import { isRegistrationApiErrorCode } from 'web/src/modules/registration/guards';
import {
  CustomCustomerFieldType,
  RegistrationApiErrorCode,
  RegistrationFieldName,
  RegistrationFormStep,
  RegistrationStage,
} from 'web/src/modules/registration/enums';
import { useSimplifiedRegistrationStore } from 'web/src/modules/registration/submodules/simplified-registration/store';
import type { FormOutput } from 'web/src/components/Form';
import { useDialogs } from 'web/src/modules/dialogs/composables';
import { PresetName } from 'web/src/modules/dialogs/enums';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import type {
  SimpleRegistrationRouteComponentProps,
} from 'web/src/modules/registration/submodules/simplified-registration/types';
import { SimpleRegistrationState } from 'web/src/modules/registration/submodules/simplified-registration/types';
import { registrationStageRouteMap } from 'web/src/modules/registration/utils/utils';
import { EmptyRegistrationTypeError, RegistrationFormCorruptedError } from 'web/src/modules/registration/errors';
import hasAnyFormErrors from 'web/src/modules/registration/utils/hasAnyFormErrors';
import { useExcludedCountryDialog } from 'web/src/modules/registration/store/composables/useExcludedCountryDialog';

const logger = loggerInstance.createNamespace('SimpleRegistration');

interface SimpleRegistrationRouteComposable {
  isFormLoaded: Ref<boolean>;
  registrationStage: Ref<RegistrationStage>;
  onInput(payload: FormOutput): void;
  onSubmitWrapped(data: FormOutput): Promise<void>;
  onChangePhone(data: FormOutput): Promise<void>;
  onCheckSmsCode(data: FormOutput): Promise<void>;
}

const isPhoneCheck = (stage: RegistrationStage): boolean => (stage === RegistrationStage.PhoneCheck || stage === RegistrationStage.PhoneCheckByCall);

const isRegErrorForPhoneCheck = (code: string, stage: RegistrationStage): boolean => {
  if (!isRegistrationApiErrorCode(code)) return false;
  if (code !== RegistrationApiErrorCode.REG) return false;
  return isPhoneCheck(stage);
};

export function useSimpleRegistrationRoute(props: SimpleRegistrationRouteComponentProps): SimpleRegistrationRouteComposable {
  const simpleRegStore = useSimplifiedRegistrationStore();
  const isFormLoaded = toRef(() => simpleRegStore.isFormLoaded);
  const stage = toRef(() => simpleRegStore.stage);
  const regState = toRef(() => simpleRegStore.regState);
  const registrationError = toRef(() => simpleRegStore.registrationError);
  const registrationErrorCode = toRef(() => simpleRegStore.registrationErrorCode);
  const registrationType = toRef(() => simpleRegStore.registrationType);
  const currentFormProperties = toRef(() => simpleRegStore.currentFormProperties);
  const isRegistrationComplete = toRef(useRegistrationComplete(), 'value');
  const phoneValidationFormParameters = toRef(() => simpleRegStore.lastRegFormParams);

  const isExcludedCountry = toRef(simpleRegStore, 'isExcludedCountry');
  useExcludedCountryDialog(isExcludedCountry);

  const router = useRouter();
  const { $translate } = useI18n();
  const { showDialog } = useDialogs();
  const analytics = useAnalytics();

  onBeforeUnmount(() => {
    if (stage.value === RegistrationStage.Success || stage.value === RegistrationStage.PhoneCheck) {
      void simpleRegStore.onLogoutReset();
    }
  });

  onUnmounted(() => {
    if (isPhoneCheck(stage.value)) {
      simpleRegStore.setInitialAvailableForm();
      simpleRegStore.goToStage({ stage: RegistrationStage.Main });
    }
  });

  const freeBetId = computed(() => (
    isString(router.currentRoute.value.query.freeBetId) ? router.currentRoute.value.query.freeBetId : null));

  onMounted(async () => {
    if (freeBetId.value) {
      await simpleRegStore.fetchFreeBetData(freeBetId.value);
    }
    analytics.registrationStart();
  });

  async function onSubmitWrapped(data: FormOutput): Promise<void> {
    if (hasAnyFormErrors(data)) {
      simpleRegStore.setFormPendingStatus(false);
      return;
    }

    await onSubmit(data);
  }

  async function onSubmit(data: FormOutput): Promise<void> {
    const { schemaId, regType } = getRegistrationOption();

    if (stage.value === RegistrationStage.Main) {
      simpleRegStore.resetCaptchaToken();
    }

    await simpleRegStore.onSubmitHandler(data, {
      registrationType: regType,
      schemaId,
      addFingerprint: true,
    });
    if (isRegistrationComplete.value) {
      simpleRegStore.setMetrikaRegistrationSuccess({
        registrationType: regType,
      });
    }
  }

  function onInput(payload: FormOutput): void {
    simpleRegStore.onInputHandler(payload);
  }

  async function onChangePhone(data: FormOutput): Promise<void> {
    const { schemaId, regType } = getRegistrationOption();

    simpleRegStore.resetCaptchaToken();
    await simpleRegStore.onSubmitHandler({
      ...data,
      formData: {
        ...data.formData,
        step: RegistrationFormStep.Initial,
        code: '',
      },
    }, {
      registrationType: regType,
      schemaId,
      addFingerprint: false,
      extraFormParams: phoneValidationFormParameters.value.filter((parameter) => parameter.key !== CustomerFieldType.PHONE_INPUT as string
        && parameter.key !== RegistrationFieldName.STEP as string && parameter.key !== CustomCustomerFieldType.CAPTCHA_TOKEN as string),
    });
  }

  async function onCheckSmsCode(data: FormOutput): Promise<void> {
    const { schemaId, regType } = getRegistrationOption();
    try {
      await simpleRegStore.onSubmitHandler(data, {
        registrationType: regType,
        schemaId,
        addFingerprint: true,
        extraFormParams: phoneValidationFormParameters.value,
      });

      if (isRegistrationComplete.value) {
        simpleRegStore.setMetrikaPhoneCheck('phoneCheck');
        simpleRegStore.setMetrikaRegistrationSuccess({
          registrationType: CustomerRegistrationType.SIMPLE_PHONE,
        });
      }
    } catch (error) {
      if (error instanceof AbstractError) {
        const value = data.formData[RegistrationFieldName.SMS_CODE];
        assert(
          isString(value),
          `Unexpected smsCode type: ${typeof value}`,
        );
        simpleRegStore.setRegFormCustomErrors({
          [RegistrationFieldName.SMS_CODE]: [{
            message: error.message,
            value,
          }],
        });
      }
    }
  }

  watch(stage, async (newStage: RegistrationStage, oldStage: RegistrationStage) => {
    if (newStage === props.stage) {
      return;
    }

    if (newStage === RegistrationStage.Main) {
      void simpleRegStore.setInitialAvailableForm();
    }

    const newRoute = registrationStageRouteMap[newStage];

    if (newRoute) {
      const anonymousRoutes = [
        RegistrationStage.Main,
        RegistrationStage.PhoneCheck,
        RegistrationStage.PhoneCheckByCall,
        RegistrationStage.ChangePhone,
      ];

      const doReplace = newStage === RegistrationStage.ChangePasswordSuccess
        || (anonymousRoutes.includes(oldStage) && !anonymousRoutes.includes(newStage));

      if (doReplace) {
        await router.replaceModal({ name: newRoute });
      } else {
        void router.push({ name: newRoute }, {
          cancelOnPopstate: true,
        });
      }
    }
  }, { immediate: true });

  watch(regState, (state: SimpleRegistrationState) => {
    switch (state) {
      case SimpleRegistrationState.ERROR_SERVER:
      case SimpleRegistrationState.FORM_ERROR: {
        showError(registrationError.value, registrationErrorCode.value);
        break;
      }
      case SimpleRegistrationState.ANONYM:
      case SimpleRegistrationState.FORM_READY: {
        break;
      }
      case SimpleRegistrationState.AUTHORIZED:
      case SimpleRegistrationState.EMAIL_REGISTRATION_DONE: {
        void router.replace({ name: RouteName.DEPOSITS });
        break;
      }
      default: {
        logger.error('Unexpected registration state', state);
        showError($translate('WEB2_ERROR_GENERAL_ALERT').value);
      }
    }
  }, { immediate: true });

  function showError(error: string, code = ''): void {
    if (!error.length) {
      return;
    }

    if (isRegErrorForPhoneCheck(code, stage.value)) {
      simpleRegStore.setRegState(SimpleRegistrationState.FORM_READY);
      simpleRegStore.setRegistrationError('');
      simpleRegStore.setRegistrationErrorCode('');
    } else {
      // eslint-disable-next-line promise/no-promise-in-callback
      showDialog({
        presetName: PresetName.ALERT_WARNING,
        options: {
          title: $translate('JS_CAPTION_ATTENTION').value,
          confirmMessage: error,
        },
        // eslint-disable-next-line promise/always-return
      }).promise.then(() => {
        void simpleRegStore.resetRegistrationState();
        return error;
      }).catch(() => logger.error('cannot show modal'));
    }
  }

  function getRegistrationOption(): { schemaId: string; regType: CustomerRegistrationType } {
    if (!registrationType.value) {
      throw new EmptyRegistrationTypeError({});
    }

    const schemaId = currentFormProperties.value?.schemaId;

    if (!schemaId) {
      throw new RegistrationFormCorruptedError({ message: 'schemaId is not found during full registration' });
    }

    return {
      schemaId,
      regType: registrationType.value,
    };
  }

  return {
    isFormLoaded,
    registrationStage: stage,
    onInput,
    onSubmitWrapped,
    onChangePhone,
    onCheckSmsCode,
  };
}
