import type { VCoreDropdownProps, CoreDropdownOptions } from '../types';
import getCoreDropdownProps from './getCoreDropdownProps';

const getCoreDropdownPropsFromElement = (element: HTMLElement, options: CoreDropdownOptions)
: VCoreDropdownProps => {
  const { x, y } = element.getBoundingClientRect();
  const { offsetHeight, offsetWidth } = element;
  return getCoreDropdownProps({
    x, y, height: offsetHeight, width: offsetWidth,
  }, options);
};

export default getCoreDropdownPropsFromElement;
