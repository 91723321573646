import type { SlipEntry, BetInfoItem } from '../types';
import { getSafeCompetitorsValue } from './getSafeCompetitorsValue';

interface UpdateBetsInfoItemBySlipEntryPayload {
  sourceItem: BetInfoItem;
  entry: SlipEntry;
}

export function updateBetsInfoItemBySlipEntry({ sourceItem, entry }: UpdateBetsInfoItemBySlipEntryPayload): BetInfoItem {
  return {
    // data from entry will overwrite data from runner
    competitors: getSafeCompetitorsValue(entry.competitors) ?? sourceItem.competitors,
    eventName: entry.eventName ?? sourceItem.eventName,
    runnerName: entry.runnerName ?? sourceItem.runnerName,
    marketName: entry.marketName ?? sourceItem.marketName,
    isPrimaryMarket: entry.isPrimaryMarket ?? sourceItem.isPrimaryMarket,
    // data from entry will used only if no data
    originalOdds: sourceItem.originalOdds ?? entry.odds,
    originalOddsStr: sourceItem.originalOddsStr ?? entry.oddsStr ?? undefined,
    oldRunnerName: sourceItem.oldRunnerName,
    replacedAt: sourceItem.replacedAt,
  };
}
