<script lang="ts" setup>
import { IconSize } from '@leon-hub/icons';
import type { NavigationItemIcon } from '@leon-hub/navigation-config';

import { VIcon } from '@components/v-icon';

import VImage from 'web/src/components/Image/VImage/VImage.vue';

interface HeaderNavigationButtonProps {
  href?: string;
  isActive?: boolean;
  label?: string;
  inverted?: boolean;
  icon?: NavigationItemIcon;
  iconCdnSrc?: string;
  isPromotionsHighlighted?: boolean;
  isLandingEuro?: boolean;
}

interface HeaderNavigationButtonEmits {
  (e: 'click'): void;
}

withDefaults(defineProps<HeaderNavigationButtonProps>(), {
  href: '',
  label: '',
});
const emit = defineEmits<HeaderNavigationButtonEmits>();

const iconSize = IconSize.SIZE_16;

function emitClick(): void {
  emit('click');
}
</script>

<template>
  <a v-auto-id="'HeaderNavigationButton'"
    :href="href"
    :class="{
      [$style['horizontal-navigation-button']]: true,
      [$style['horizontal-navigation-button--active']]: isActive,
      [$style['horizontal-navigation-button--inverted']]: inverted,
      [$style['horizontal-navigation-button--promo-highlighted']]: isPromotionsHighlighted,
      [$style['horizontal-navigation-button--landing']]: isLandingEuro,
      [$style['horizontal-navigation-button--with-image']]: !!iconCdnSrc,
    }"
    @click.prevent="emitClick"
  >
    <VIcon
      v-if="icon"
      :name="icon.name"
      :size="iconSize"
      :class="{
        [$style['horizontal-navigation-button__icon']]: true,
        [$style['horizontal-navigation-button__icon--landing']]: isLandingEuro,
      }"
    />
    <VImage
      v-else-if="!!iconCdnSrc"
      :src="iconCdnSrc"
      alt=""
      :class="{
        [$style['horizontal-navigation-button__icon']]: true,
        [$style['horizontal-navigation-button__icon--landing']]: isLandingEuro,
      }"
    />
    <span :class="$style['horizontal-navigation-button__label']">{{ label }}</span>
  </a>
</template>

<style lang="scss" module>
.horizontal-navigation-button {
  $self: &;

  @include medium\12\16\04\caps;

  position: relative;
  display: flex;
  column-gap: 4px;
  align-items: center;
  height: calc($headerHeightDesktopSmall - 2 * $headerNavigationButtonMarginTop);
  padding: 20px 12px;
  margin: $headerNavigationButtonMarginTop $headerNavigationButtonMarginRight;
  color: if($isEnvFeatureDarkHeader, var(--DTextPrimary), var(--TextPrimary));
  text-decoration: none;
  white-space: nowrap;
  appearance: none;
  user-select: none;
  border-radius: $headerNavigationButtonBorderRadius;

  @include screen-desktop-large-min {
    height: calc($headerHeightDesktopBig - 2 * $headerNavigationButtonMarginTop);
  }

  &--active {
    color: if($isEnvFeatureDarkHeader, var(--DTextDefault), var(--TextDefault));
    background-color: if($isEnvFeatureDarkHeader, var(--DLayer2), var(--Layer2));
  }

  &:hover {
    background-color: if($isEnvFeatureDarkHeader, var(--DHighlight), var(--Highlight));
  }

  &--inverted {
    @if $isEnvFeatureDarkHeader {
      color: var(--DTextPrimary);
      background-color: var(--DLayer2);
    } @else if $isEnvFeatureTwinlikeStyle {
      color: var(--TextPrimary);
      background-color: transparent;
    } @else {
      color: var(--TextPrimary);
      background-color: var(--Layer2);
    }

    &:hover {
      color: if($isEnvFeatureDarkHeader, var(--DTextDefault), var(--TextDefault));
      background-color: if($isEnvFeatureDarkHeader, var(--DHighlight), var(--Highlight));
    }
  }

  &--with-image {
    column-gap: 8px;
  }

  &--landing {
    color: $headerNavigationButtonLandingColor;

    @include for-hover {
      &:hover {
        color: $headerNavigationButtonLandingColor;
      }
    }
  }

  &--promo-highlighted {
    #{$self}__icon {
      fill: var(--PinkHighLight);
    }
  }

  &__icon {
    &--landing {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      fill: var(--DTextPrimary);
    }
  }

  &__badge {
    margin-left: 4px;
  }
}
</style>
