<script setup lang="ts">
import {
  computed,
  ref,
} from 'vue';

import { StartCodeType } from '@leon-hub/api-sdk';

import VIframe from 'web/src/components/Iframe/VIframe/VIframe.vue';
import { IframeWidget } from 'web/src/modules/framed-app/enums';
import type { VIframeRef } from 'web/src/components/Iframe/VIframe/types';

import type {
  EgsIframeEmits,
  EgsIframeProps,
  EgsGameIframeRef,
} from './types';
import EgsInjectedGameIframeWidget from '../EgsInjectedGameIframeWidget/EgsInjectedGameIframeWidget.vue';
import type { EgsInjectedGameIframeWidgetRef } from '../EgsInjectedGameIframeWidget/types';

const props = defineProps<EgsIframeProps>();
const emit = defineEmits<EgsIframeEmits>();

const iframeName = IframeWidget.EgsGame;
const isHtmlInjection = computed(() => props.startCodeType === StartCodeType.HTML_INJECTION);

function onFrameLoaded(): void {
  emit('load');
}

const iframeElement = ref<VIframeRef>();
const iframeInjectedElement = ref<EgsInjectedGameIframeWidgetRef>();

function postMessage(message: unknown, target = '*'): void {
  iframeElement.value?.postMessage(message, target);
  iframeInjectedElement.value?.postMessage(message, target);
}

defineExpose<EgsGameIframeRef>({
  postMessage,
});
</script>

<template>
  <VIframe
    v-if="!isHtmlInjection && gameUrl"
    ref="iframeElement"
    v-data-test="{ el: 'started-game-iframe' }"
    :name="iframeName"
    :src="gameUrl"
    is-dark
    full-width
    full-height
    hide-title
    :allow="allow"
    :timeout="timeout"
    :allowfullscreen="allowfullscreen"
    :flex-layout="flexLayout"
    @load="onFrameLoaded"
    @error="emit('error', $event)"
  />
  <EgsInjectedGameIframeWidget
    v-else-if="isHtmlInjection && gameUrl"
    ref="iframeInjectedElement"
    :game-html="gameUrl"
    :allow="allow"
    :allowfullscreen="allowfullscreen"
    :flex-layout="flexLayout"
    :timeout="timeout"
    @load="onFrameLoaded"
    @error="emit('error', $event)"
  />
</template>
