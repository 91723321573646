import type { SaveBetSlipConfigurationReturnType, SaveBetSlipConfigurationRequest } from '@leon-hub/api-sdk';
import { saveBetSlipConfiguration } from '@leon-hub/api-sdk';

import type ApplicationGqlClient from 'web/src/modules/core/services/api/ApplicationGqlClient';

export default function requestSaveBetSlipConfiguration(
  apiClient: ApplicationGqlClient,
  requestPayload: Omit<SaveBetSlipConfigurationRequest, 'ts'>,
): Promise<SaveBetSlipConfigurationReturnType> {
  return saveBetSlipConfiguration(apiClient, (node) => node.mutations.customer.slip
    .saveBetSlipConfiguration, {
    options: requestPayload,
  });
}
