enum PaymentsPage {
  DESKTOP_WITHDRAWALS = 'DESKTOP_WITHDRAWALS',
  DESKTOP_DEPOSITS = 'DESKTOP_DEPOSITS',
  DEPOSIT_DETAILS = 'DEPOSIT_DETAILS',
  WITHDRAWAL_DETAILS = 'WITHDRAWAL_DETAILS',
  DESKTOP_BONUS_EXCHANGE_DETAILS = 'DESKTOP_BONUS_EXCHANGE_DETAILS',
  MOBILE_WITHDRAWALS = 'MOBILE_WITHDRAWALS',
  MOBILE_DEPOSITS = 'MOBILE_DEPOSITS',
}

export default PaymentsPage;
