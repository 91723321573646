/* eslint-disable @brettz9/prefer-for-of */
import { isNullOrUndefined } from '@leon-hub/guards';

import { ComponentKey, ComponentStatus } from '../types';
import type { ComponentResult } from '../types';
import AbstractFingerprintComponent from '../AbstractFingerprintComponent';

// Component source: https://github.com/fingerprintjs/fingerprintjs2/blob/master/fingerprint2.js#L539

export default class PluginsComponent extends AbstractFingerprintComponent {
  constructor() {
    super(ComponentKey.Plugins);
  }

  getComponentResult(): Promise<ComponentResult> {
    const navigatorPlugins = window.navigator.plugins;
    if (isNullOrUndefined(navigatorPlugins)) {
      return this.result({
        key: this.key,
        value: ComponentStatus.NotAvailable,
      });
    }

    const plugins: Plugin[] = [];
    for (let index = 0, l = navigatorPlugins.length; index < l; index += 1) {
      if (navigatorPlugins[index]) {
        plugins.push(navigatorPlugins[index]);
      }
    }

    const value = plugins.map((plugin: Plugin) => {
      const mimeTypes: (string[])[] = [];

      for (let index = 0, l = plugin.length; index < l; index += 1) {
        if (plugin[index] instanceof MimeType) {
          mimeTypes.push([plugin[index].type, plugin[index].suffixes, plugin[index].description]);
        }
      }

      return [plugin.name, plugin.description, mimeTypes];
    });

    return this.result(value.length ? value : ComponentStatus.NoData);
  }
}
