import { defineStore } from 'pinia';
import { computed, ref, toRef } from 'vue';

import type { UIField } from '@leon-hub/api-sdk';
import {
  CustomerFieldType,
  InvalidPhoneExceptionCode,
  getCustomerProfileFields,
  updateCustomerProfileFields,
} from '@leon-hub/api-sdk';
import { assert, isObject } from '@leon-hub/guards';
import GqlApiBatchedSubRequestError from '@leon-hub/api/src/client/graphql/errors/GqlApiBatchedSubRequestError';
import GqlApiError from '@leon-hub/api/src/client/graphql/errors/GqlApiError';
import { ApiErrorCode } from '@leon-hub/api';
import { FormControlType } from '@leon-hub/form-utils';

import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import type { FormData } from 'web/src/components/Form/types';
import { useFormPhoneErrorPatterns } from 'web/src/components/Form/composables';
import { mapFormData } from 'web/src/components/Form/utils';
import CustomerFormError from 'web/src/utils/forms/CustomerFormError';
import updateFieldErrorByFormError from 'web/src/utils/forms/updateFieldErrorByFormError';
import extractCustomerFormErrors from 'web/src/utils/forms/extractCustomerFormErrors';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
import { useUserStore } from 'web/src/modules/user/store';
import { JsonSchemaAdapter, getUiFieldRawOptions } from 'web/src/utils/jsonSchemaAdapter';
import { useFormDefaultFormPhoneValue } from 'web/src/modules/phone/composables';
import { useI18n } from 'web/src/modules/i18n/composables';
import type { ProfileForm } from 'web/src/modules/profile/types';
import { isProfileForm, isUpdateIncompleteProfileFieldsResponse } from 'web/src/modules/profile/types';
import type { ProfileSchema } from 'web/src/modules/profile/pages/PersonalDataRoutePage/types';

const useSimpleRegistrantProfileStore = defineStore(
  'simple-registrant-profile', () => {
    const api = useGraphqlClient();
    const { $translate } = useI18n();

    const userStore = useUserStore();
    const customerDataStore = useCustomerDataStore();
    const { parsePhoneNumberByUIField } = useFormDefaultFormPhoneValue();
    const { phoneErrorPatterns } = useFormPhoneErrorPatterns();

    const emailSubscription = toRef(customerDataStore, 'emailSubscription');
    const country = toRef(customerDataStore, 'country');

    const form = ref<ProfileForm>(null);
    const disabledFields = ref<string[]>([]);

    function setProfileForm(value: ProfileForm): void {
      form.value = value;
      if (value === null) {
        disabledFields.value = [];
      }
    }

    function updateUiFields(fields: readonly UIField[]): void {
      if (form.value?.uiFormSchema.fields.length) {
        for (const field of fields) {
          const targetIndex: number = form.value.uiFormSchema.fields.findIndex((item) => item.id === field.id);
          if (targetIndex < 0) {
            continue;
          }
          if (form.value?.uiFormSchema.fields) {
            setProfileForm({
              ...form.value,
              uiFormSchema: {
                ...form.value.uiFormSchema,
                fields: [
                  ...form.value.uiFormSchema.fields.slice(0, targetIndex),
                  field,
                  ...form.value.uiFormSchema.fields.slice(targetIndex + 1),
                ],
              },
            });
          }
        }
      }
    }

    function disableUiFields(listOfFields: string[]): void {
      disabledFields.value = [...new Set([...disabledFields.value, ...listOfFields])];
      const updatedFields = (form.value?.uiFormSchema.fields ?? [])
        .filter((field) => listOfFields.includes(field.id))
        .map((field) => ({
          ...field,
          disabled: true,
          ...(process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED ? { hidden: true } : {}),
        }));
      updateUiFields(updatedFields);
    }

    function hiddenUiField(fieldId: string): void {
      const updatedFields = (form.value?.uiFormSchema.fields ?? [])
        .filter((field) => field.id === fieldId)
        .map((field) => ({ ...field, hidden: true }));
      updateUiFields(updatedFields);
    }

    async function getProfileFields(): Promise<void> {
      const profileForm = await getCustomerProfileFields(api,
        (node) => node.queries.registration.getCustomerProfileFields);

      assert(isProfileForm(profileForm), 'profileForm should be of type ProfileFields');
      setProfileForm(profileForm);
      hiddenUiField(CustomerFieldType.SUBSCRIBE);
      disableUiFields(profileForm.uiFormSchema.fields.filter((field) => field.disabled).map((field) => field.id));
    }

    async function updateCustomerData({ formData }: { formData: FormData }): Promise<void> {
      assert(isObject(form.value), 'form must be fetched');
      let updateResponse: unknown = null;

      const filteredFormData = Object.keys(formData)
        .filter((key) => !disabledFields.value.includes(key))
        .reduce((object, key) => ({
          ...object,
          [key]: formData[key],
        }), {});

      try {
        updateResponse = await updateCustomerProfileFields(api,
          (node) => node.mutations.registration.updateCustomerProfileFields,
          {
            options: {
              schemaId: form.value.schemaId,
              formParams: mapFormData({
                ...filteredFormData,
                [CustomerFieldType.SUBSCRIBE]: !!emailSubscription.value,
              }),
            },
          });
      } catch (error) {
        if (error instanceof GqlApiBatchedSubRequestError
        // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
        && error.extensions.errorCode === InvalidPhoneExceptionCode.INVALID_PHONE) {
          throw new CustomerFormError(
            updateFieldErrorByFormError(
              formData,
              CustomerFieldType.PHONE_INPUT,
              form.value.uiFormSchema,
              extractCustomerFormErrors(form.value.uiFormSchema, formData),
              error.message,
            ),
          );
        }
      }

      assert(
        isUpdateIncompleteProfileFieldsResponse(updateResponse),
        'updateIncompleteFieldsResponse should be of type UpdateIncompleteFieldsResponse',
      );
      const { registrationForm } = updateResponse;
      assert(isProfileForm(registrationForm), 'form should be of type ProfileFields');

      if (registrationForm.formError) {
        throw new GqlApiError({
          code: ApiErrorCode.API_UNEXPECTED_ERROR,
          message: registrationForm.formError,
        });
      }

      await userStore.loadUserData(true);

      if (registrationForm.formError || registrationForm.hasFieldsErrors) {
        throw new CustomerFormError(
          updateFieldErrorByFormError(
            formData,
            CustomerFieldType.PHONE_INPUT,
            registrationForm.uiFormSchema,
            extractCustomerFormErrors(registrationForm.uiFormSchema, formData),
          ),
        );
      }
      if (!process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED) {
        if (customerDataStore.customerData?.isRegistrationFinish) {
          setProfileForm(registrationForm);
          hiddenUiField(CustomerFieldType.SUBSCRIBE);
          disableUiFields([]);
        } else {
          updateUiFields(registrationForm.uiFormSchema.fields.filter((field) => field.disabled));

          const currentSetFields = form.value.uiFormSchema.order;
          const newSetFields = registrationForm.uiFormSchema.order;

          disableUiFields(currentSetFields.filter((x) => !newSetFields.includes(x)));
        }
      } else {
        setProfileForm(registrationForm);
        hiddenUiField(CustomerFieldType.SUBSCRIBE);
        disableUiFields(registrationForm.uiFormSchema.fields.filter((field) => field.disabled).map((field) => field.id));
      }
    }

    const profileSchema = computed<ProfileSchema>(() => {
      if (form.value) {
        const jsonSchema = JSON.parse(form.value.formValidationSchema);

        const jsonSchemaAdapter = new JsonSchemaAdapter(form.value.uiFormSchema, jsonSchema)
          .addPropertiesByWidget(FormControlType.AutocompleteAddress, (field) => ({
            default: String(field.defaultValue ?? ''),
            options: {
              ...(field.options ? getUiFieldRawOptions(field.options) : {}),
              countryCode: country.value,
            },
          }))
          .addPropertiesById(CustomerFieldType.PHONE_INPUT, (field: UIField) => {
            const defaultValue = parsePhoneNumberByUIField(field);
            return {
              default: defaultValue,
              options: {
                disablePlaceholder: defaultValue.suffix,
              },
            };
          });

        return {
          schema: jsonSchemaAdapter.getFormSchema(),
          uiSchema: {
            ...jsonSchemaAdapter.getFormUiSchema(),
            validatorErrorPatterns: {
              byWidget: phoneErrorPatterns.value,
            },
            submitButton: {
              label: $translate('JSPACC_SETT_SAVE').value,
            },
          },
        };
      }
      return null;
    });

    const isFormLoaded = computed(() => profileSchema.value !== null);

    return {
      setProfileForm,
      getProfileFields,
      updateCustomerData,
      profileSchema,
      isFormLoaded,
      form,
      disabledFields,
    };
  },
);

export default useSimpleRegistrantProfileStore;
