<script lang="ts" setup>
import { useSwiperNavigation } from 'web/src/components/Swiper/Navigation/composables';
// eslint-disable-next-line max-len
import LobbySectionSwiperNavigationButtons from 'web/src/modules/lobby/components/LobbySectionSwiperNavigationButtons/LobbySectionSwiperNavigationButtons.vue';

import type { LobbySectionSwiperNavigationProps } from './types';

defineProps<LobbySectionSwiperNavigationProps>();

const { swiperState, slideToPreviousVisibleSlide, slideToNextVisibleSlide } = useSwiperNavigation();
</script>

<template>
  <LobbySectionSwiperNavigationButtons v-auto-id="'LobbySectionSwiperNavigation'"
    :show-previous-button="swiperState?.isPreviousButtonAllowed.value"
    :show-next-button="swiperState?.isNextButtonAllowed.value"
    :is-modal="isModal"
    @click-previous="slideToPreviousVisibleSlide"
    @click-next="slideToNextVisibleSlide"
  />
</template>
