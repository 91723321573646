<script lang="ts" setup>
import VButton from 'web/src/components/Button/VButton/VButton.vue';

import LeaderBoardParticipant
  from '../LeaderBoardParticipant/LeaderBoardParticipant.vue';
import type { LeaderBoardProps, LeaderBoardEmits } from './types';
import { useLeaderBoard } from './composables';

const props = withDefaults(defineProps<LeaderBoardProps>(), {
  description: '',
  title: '',
});

const emit = defineEmits<LeaderBoardEmits>();

const {
  customerPositionIndex,
  participantMounted,
  secondPositionVisible,
  showMoreButtonProperties,
  handleLoadMore,
} = useLeaderBoard(props, emit);
</script>

<template>
  <div v-auto-id="'LeaderBoard'" :class="$style['leader-board']">
    <table :class="$style['leader-board__table']">
      <thead
        :class="$style['leader-board__header']"
      >
        <tr
          :class="$style['leader-board__row']"
        >
          <th
            scope="col"
            :class="{
              [$style['leader-board__cell']]: true,
              [$style['leader-board__cell--place']]: true,
            }"
          >
            {{ $t('JS_LEADERBOARD_ARCHIVE_PLACE') }}
          </th>
          <th
            scope="col"
            :class="{
              [$style['leader-board__cell']]: true,
              [$style['leader-board__cell--name']]: true,
            }"
          >
            {{ $t('JS_PROMOTIONS_TITLE_WINNERNAME') }}
          </th>
          <th
            scope="col"
            :class="{
              [$style['leader-board__cell']]: true,
              [$style['leader-board__cell--prize']]: true,
            }"
          >
            {{ $t('JS_PROMOTIONS_TITLE_PRIZE') }}
          </th>
          <th
            scope="col"
            :class="{
              [$style['leader-board__cell']]: true,
              [$style['leader-board__cell--score']]: true,
            }"
          >
            {{ $t('JS_PROMOTIONS_TITLE_SCORE') }}
          </th>
        </tr>
      </thead>
      <tbody
        v-if="list.length > 0"
        :class="$style['leader-board__tbody']"
      >
        <template v-for="(participant, index) in list">
          <template v-if="index !== customerPositionIndex">
            <LeaderBoardParticipant
              :key="index"
              :participant="participant"
              :active="false"
            />
          </template>
          <template v-if="index === customerPositionIndex">
            <LeaderBoardParticipant
              :key="index"
              :participant="participant"
              sticky
              active
              @mounted="participantMounted"
            />
          </template>
        </template>
        <LeaderBoardParticipant
          v-if="secondPositionVisible && customerPosition"
          :participant="customerPosition"
          sticky
          active
        />
      </tbody>
      <tfoot
        v-if="!endOfList"
        :class="$style['leader-board__tfoot']"
      >
        <VButton
          v-bind="showMoreButtonProperties"
          @click="handleLoadMore"
        />
      </tfoot>
    </table>
  </div>
</template>

<style lang="scss" module>
.leader-board {
  &__header {
    display: block;
    height: 36px;
    margin-bottom: 2px;
    background-color: var(--Layer1);
    border-radius: 5px 5px 0 0;

    @include is-app-layout-desktop {
      height: 40px;
    }
  }

  &__row {
    display: grid;
    grid-template-columns: $leaderBoardRowTemplateColumns;
    grid-column-gap: 8px;
    padding: 12px;
    border: 0;

    @include is-app-layout-desktop {
      grid-column-gap: 16px;
      padding: 16px;
    }
  }

  &__cell {
    @include regular\12\16;

    display: block;
    color: var(--TextSecondary);
    text-align: center;

    &--place {
      text-align: left;
    }

    &--score {
      text-align: right;
    }
  }

  &__tbody {
    position: relative;
    display: block;
    border-radius: 0 0 5px 5px;
  }

  &__table {
    z-index: 0;
    width: 100%;
    border-spacing: 0;
    border-collapse: separate;
  }

  &__tfoot {
    display: block;
    margin-top: 2px;
  }

  &__title {
    @include medium\24\28;

    margin: 0 16px 12px;
    color: var(--TextDefault);
    text-align: center;
  }

  &__description {
    @include regular\14\16\025;

    display: block;
    padding: 0 20px;
    margin-bottom: 16px;
    color: var(--TextSecondary);
    text-align: center;
  }

  &__loader {
    border-color: maroon;
  }
}
</style>
