import type { MatchStreamAccess } from 'web/src/modules/sportline/submodules/streams/types';
import type { SportlineEventId } from 'web/src/modules/sportline/types';
import { SportlineType } from 'web/src/modules/sportline/enums';

export function checkMatchStreamAccess(
  access: MatchStreamAccess[],
  event: { id: SportlineEventId; type: SportlineType },
): boolean {
  return access.some(({ sportlineTypes, eventId }) => eventId === event.id && sportlineTypes.includes(event.type));
}

export function checkMatchStreamIndicator(
  access: MatchStreamAccess[],
  event: { id: SportlineEventId; type: SportlineType },
): boolean {
  return access.some(({ eventId, isUpcoming }) => (
    eventId === event.id && (event.type === SportlineType.Live || (event.type === SportlineType.Prematch && isUpcoming))
  ));
}
