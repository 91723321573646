import { useInitialRequests } from 'web/src/modules/core/components/composables';
import fetchRegistrationForms from 'web/src/modules/registration/utils/fetchRegistrationForms';

export interface UseGuestHandlerComposable {
  onLogout(isInit?: boolean): Promise<void>;
}

export default function useGuestHandler(): UseGuestHandlerComposable {
  const { getInitRequests } = useInitialRequests();

  async function onLogout(isInit?: boolean): Promise<void> {
    const requests: Promise<void>[] = [];

    if (isInit) {
      requests.push(...getInitRequests());
    }

    if (process.env.VUE_APP_FEATURE_REGISTRATION_PREFETCH_DISABLED) {
      requests.push(fetchRegistrationForms());
    }

    await Promise.all(requests);
  }

  return {
    onLogout,
  };
}
