import { LobbyGameType } from '@leon-hub/api-sdk';
import { RouteName } from '@leon-hub/routing-config-names';
import { CasinoRouteName } from '@leon-hub/routing-config-names/src/CasinoRouteName';

export default function getLobbyRouteName(lobbyType?: Maybe<LobbyGameType>, isLiveEgsRoute = false): string {
  if (process.env.VUE_APP_PRODUCT_LEONRU) {
    return RouteName.HOME;
  }

  switch (lobbyType) {
    case LobbyGameType.LIVE:
      return CasinoRouteName.CASINO_LIVE_LOBBY;

    case LobbyGameType.SLOTS:
      return CasinoRouteName.CASINO_LOBBY;

    default:
      return isLiveEgsRoute ? CasinoRouteName.CASINO_LIVE_LOBBY : CasinoRouteName.CASINO_LOBBY;
  }
}
