import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import type { IconNameType } from '@leon-hub/icons';
import { IconName } from '@leon-hub/icons';

import { BetCashoutMode } from 'web/src/modules/cashout/enums';
import { getCurrencyIcon } from 'web/src/modules/money/utils';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useFormatMoney } from 'web/src/modules/money/composables';

import type { BetCashOutProps, BetCashOutEmits } from '../../types';

interface UseBetCashOut {
  isPending: ComputedRef<boolean>;
  isCompleted: ComputedRef<boolean>;
  isError: ComputedRef<boolean>;
  iconName: ComputedRef<IconNameType>;
  mainText: ComputedRef<string>;
  formattedPriceValue: ComputedRef<string>;
  formattedBetPriceValue: ComputedRef<string>;
  primaryButtonLabel: ComputedRef<string>;
  showCancel: ComputedRef<boolean>;
  showValue: ComputedRef<boolean>;
  isGreenPrice: ComputedRef<boolean>;
  emitMainButtonClick: () => void;
  emitCancel: () => void;
}

export default function useBetCashOut(
  props: BetCashOutProps,
  emit: BetCashOutEmits,
): UseBetCashOut {
  const { $translate } = useI18n();

  const formatMoney = useFormatMoney();

  const isPending = computed<boolean>(() => props.mode === BetCashoutMode.PENDING);

  const isCompleted = computed<boolean>(() => props.mode === BetCashoutMode.SUCCESS);

  const isError = computed<boolean>(() => props.mode === BetCashoutMode.ERROR);

  const showOldValue = computed<boolean>(() => !!props.oldPrice && !isCompleted.value);

  const iconName = computed<IconNameType>(() => {
    if (isCompleted.value) {
      return IconName.CHECK_OUTLINE;
    }
    if (isError.value) {
      return IconName.INFO_OUTLINE;
    }
    if (showOldValue.value) {
      return IconName.ATTENTION_OUTLINE;
    }
    return getCurrencyIcon(props.currency);
  });

  const mainText = computed<string>(() => {
    if (isCompleted.value) {
      return $translate('WEB2_BETDET_CASHOUT_SUCCESS').value;
    }
    if (isError.value) {
      return props.errorMessage ? props.errorMessage : $translate('JSPBET_BETDET_CASHOUT_IMPOSSIBLE').value;
    }
    if (showOldValue.value) {
      return $translate('WEB2_STAKE_CASHOUT_CHANGE').value;
    }
    return $translate('WEB2_BET_DETAILS_SELL_BET_FOR').value;
  });

  const formattedPriceValue = computed<string>(() => formatMoney(props.price ?? 0));

  const formattedBetPriceValue = computed<string>(() => formatMoney(Number(props.betPrice) ?? 0));

  const primaryButtonLabel = computed<string>(() => {
    if (isPending.value) {
      return $translate('WEB2_CASHOUT_IN_PROGRESS').value;
    }
    if (isCompleted.value) {
      return $translate('WEB2_CLOSE').value;
    }
    return $translate('WEB2_BET_DO_CAHSOUT').value;
  });

  const showCancel = computed<boolean>(() => !isCompleted.value);

  const showValue = computed<boolean>(() => !!props.price && !showOldValue.value && !props.betPrice && !isError.value);

  const isGreenPrice = computed<boolean>(
    () => (Number(props?.price) > Number(props?.betPrice)),
  );

  const emitMainButtonClick = (): void => {
    emit('main-button-click');
  };

  const emitCancel = (): void => {
    emit('cancel');
  };

  return {
    isPending,
    isCompleted,
    isError,
    iconName,
    mainText,
    formattedPriceValue,
    formattedBetPriceValue,
    primaryButtonLabel,
    showCancel,
    showValue,
    isGreenPrice,
    emitMainButtonClick,
    emitCancel,
  };
}
