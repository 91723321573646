<script lang="ts" setup>
import { computed, toRef } from 'vue';

import type { SportlineEventChampionshipMainInfoProps } from 'web/src/modules/sportline/views/EventBlock/types';

const props = defineProps<SportlineEventChampionshipMainInfoProps>();

const sportlineEvent = toRef(props, 'sportlineEvent');

const championshipName = computed(() => sportlineEvent.value.championshipName ?? '');
const outrightName = computed(() => sportlineEvent.value.outrightName ?? '');
</script>

<template>
  <div v-auto-id="'SportlineEventChampionshipMainInfo'" :class="$style['sportline-event-main-info']">
    <div :class="$style['sportline-event-main-info__left-column']">
      <div :class="$style['sportline-event-championship']">
        <div :class="$style['sportline-event-championship__name']">
          {{ championshipName }}
        </div>
        <div :class="$style['sportline-event-championship__market']">
          {{ outrightName }}
        </div>
      </div>
    </div>
  </div>
</template>

<style module lang="scss">
@import '~web/src/modules/sportline/views/EventBlock/styles/sportline-event-main-info';
@import '~web/src/modules/sportline/views/EventBlock/styles/sportline-event-championship';
</style>
