<script lang="ts" setup>
import {
  IconName,
  IconSize,
} from '@leon-hub/icons';
import { Tag } from '@leon-hub/tags';

import { VIcon } from '@components/v-icon';

import VListItem from 'web/src/components/List/VListItem/VListItem.vue';
import VDynamicContent from 'web/src/components/DynamicContent/VDynamicContent/VDynamicContent.vue';
import { VCountryFlag } from 'web/src/components/CountryFlag/VCountryFlag';
import VFlagIcon from 'web/src/components/CountryFlag/VFlagIcon/VFlagIcon.vue';
import SListItem from 'web/src/components/List/SListItem/SListItem.vue';

import type {
  VListItemCountriesEmits,
  VListItemCountriesProps,
} from './types';
import { useVListItemCountries } from './composables';

const props = withDefaults(defineProps<VListItemCountriesProps>(), {
  code: 'MT',
  prefix: '356',
});
const emit = defineEmits<VListItemCountriesEmits>();

const { isSelected, getHighlightedText } = useVListItemCountries(props);

function emitClick(): void {
  emit('click', {
    prefix: props.prefix,
    code: props.code,
  });
}
</script>

<template>
  <SListItem
    v-if="null"
    :is-active="isSelected"
    type="primary"
    :class="$style['countries-selector-item']"
    is-word-wrap
    @click="emitClick"
  >
    <template #icon>
      <VFlagIcon
        v-if="null"
        :country-code="code"
        :size="IconSize.SIZE_24"
      />
      <VCountryFlag
        v-else
        :name="code"
      />
    </template>
    <template #content>
      <div>
        <VDynamicContent
          :tag="Tag.SPAN"
          :class="$style['countries-selector-item__name']"
          :content="getHighlightedText(name ?? '')"
        />
        <span>&nbsp;</span>
        <VDynamicContent
          :tag="Tag.SPAN"
          :class="$style['countries-selector-item__code']"
          :content="getHighlightedText(prefix)"
        />
      </div>
    </template>
    <template
      v-if="isSelected"
      #suffix
    >
      <VIcon :name="IconName.SLOTT_CHECK_CIRCLE" />
    </template>
  </SListItem>
  <VListItem
    v-else
    :title="name"
    :icon-suffix-name="isSelected ? IconName.CHECK_FILLED : undefined"
    :is-active="isSelected"
    have-hover
    @click="emitClick"
  >
    <template #prefix>
      <div :class="$style['list-item__prefix-flag']">
        <VFlagIcon
          v-if="null"
          :country-code="code"
          :class="$style['list-item__prefix-flag-img']"
        />
        <VCountryFlag
          :name="code"
          :class="$style['list-item__prefix-flag-img']"
        />
      </div>
    </template>
    <template #inner>
      <div>
        <VDynamicContent
          :tag="Tag.SPAN"
          :class="$style['countries-selector-item__name']"
          :content="getHighlightedText(name || '')"
        />
        <span>&nbsp;</span>
        <VDynamicContent
          :tag="Tag.SPAN"
          :class="$style['countries-selector-item__code']"
          :content="getHighlightedText(prefix)"
        />
      </div>
    </template>
  </VListItem>
</template>

<style lang="scss" module>
.countries-selector-item {
  @include countriesSelectorItem;

  &__code {
    @include countriesSelectorItemCode;

    color: $listItemCountriesColor;
  }

  &__name {
    @include countriesSelectorItemName;
  }
}

.list-item__prefix-flag {
  display: flex;
  align-items: center;
  width: 100%;
  padding-right: 8px;
  padding-left: 8px;

  &-img {
    width: 100%;
    height: 16px;
  }
}
</style>
