import type { AchievementsRequest } from '@leon-hub/api-sdk';
import { getAchievements } from '@leon-hub/api-sdk';

import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import type { GetAchievementsDataDocument } from 'web/src/modules/profile/submodules/achievements/types/AchievementsDocuments';

export default function getAchievementsRequest(publicId?: string): Promise<GetAchievementsDataDocument> {
  const apiClient = useGraphqlClient();

  const options: AchievementsRequest = {};

  if (publicId) {
    options.publicId = publicId;
  }

  return getAchievements(
    apiClient,
    (node) => node.queries.achievements.getAchievements,
    { options },
  );
}
