import TimeLeft from './TimeLeft';

export interface ValueOfTimeLeft {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

export interface DisplayedTimeLeft {
  days: string;
  hours: string;
  minutes: string;
  seconds: string | false;
}

export interface DisplayedTimeLeftOptions {
  displaySecondsOn?: {
    maxMinutes?: number | false;
  };
}

/** @deprecated use useCountdown instead */
export default class Countdown {
  constructor(
    private value: number,
    private now: number = Date.now(),
  ) {}

  getTimeLeft(): ValueOfTimeLeft {
    const daysLeft = TimeLeft.days(this.value, this.now);
    const hoursLeft = TimeLeft.hours(this.value, this.now);
    const minutesLeft = TimeLeft.minutes(this.value, this.now);
    const secondsLeft = TimeLeft.seconds(this.value, this.now);

    return {
      days: +daysLeft,
      hours: +hoursLeft,
      minutes: +minutesLeft,
      seconds: +secondsLeft,
    };
  }

  getDisplayedTimeLeft(options: DisplayedTimeLeftOptions = {
    displaySecondsOn: {
      maxMinutes: 5,
    },
  }): DisplayedTimeLeft {
    const daysLeft = TimeLeft.days(this.value, this.now);
    const hoursLeft = TimeLeft.hours(this.value, this.now);
    const minutesLeft = TimeLeft.minutes(this.value, this.now);
    const secondsLeft = TimeLeft.seconds(this.value, this.now);

    const displaySecondsOnMaxMinutes = options.displaySecondsOn?.maxMinutes ?? false;
    const showSeconds = displaySecondsOnMaxMinutes === false
      ? true
      : (+daysLeft === 0)
        && (+hoursLeft === 0)
        && (+minutesLeft < displaySecondsOnMaxMinutes);

    return {
      days: daysLeft.toString(),
      hours: hoursLeft.toString(),
      minutes: minutesLeft.toString(),
      seconds: showSeconds ? secondsLeft.toString() : false,
    };
  }
}
