import type { Ref } from 'vue';
import {
  computed,
} from 'vue';

import { IconName, IconSize } from '@leon-hub/icons';

import type { VIconProps } from '@components/v-icon';

import type { VBadgeProps } from 'web/src/components/Badge/VBadge/types';
import { BadgeKind } from 'web/src/components/Badge/VBadge/enums';
import type {
  ProfileBonusesCardDescriptionColumnProps,
} from 'web/src/modules/profile/submodules/bonuses/components/ProfileBonusesCardDescriptionColumn/types';

export interface ProfileBonusesCardDescriptionColumnComposable {
  iconProps: Readonly<VIconProps>;
  badgeProps: Ref<VBadgeProps>;
}
export default function useProfileBonusesCardDescriptionColumn(
  props: Required<ProfileBonusesCardDescriptionColumnProps>,
): ProfileBonusesCardDescriptionColumnComposable {
  const iconProps: VIconProps = {
    name: IconName.CROSS,
    size: IconSize.SIZE_10,
  };

  const badgeProps = computed(() => (
    {
      label: props.badgeCount > 0 ? String(props.badgeCount) : '0',
      kind: BadgeKind.DEFAULT,
    } as VBadgeProps
  ));

  return {
    iconProps,
    badgeProps,
  };
}
