import type { TriggerParameters, ParametersData } from '../../types';

let PARAMS_QUEUE: ParametersData[] = [];

const addParameter = (parameters: ParametersData): number => PARAMS_QUEUE.push(parameters);

const getParameters = (): ParametersData[] => [...PARAMS_QUEUE];

const clear = (): void => {
  PARAMS_QUEUE = [];
};

const run = (callback: TriggerParameters): void => {
  for (const parameters of PARAMS_QUEUE) {
    callback(parameters);
  }
  clear();
};

export default {
  addParameter,
  getParameters,
  run,
  clear,
};
