import type { BatchedSlipInfoDocument } from '../types';

export default function getSlipInfoWithoutTrace(slipInfo: BatchedSlipInfoDocument | null): BatchedSlipInfoDocument | null {
  if (!slipInfo) {
    return null;
  }
  const { slipEntries, combiAvailable } = slipInfo;
  return ({
    slipEntries: slipEntries.map((entry) => ({
      ...entry,
      trace: null,
    })),
    combiAvailable,
  });
}
