import type { RouteLocationRaw } from 'vue-router';

import type { NavigationItem } from '@leon-hub/navigation-config';
import { RouteName } from '@leon-hub/routing-config-names';

import type { AppVueRouter } from 'web/src/modules/core/services/router/types';

export default function resolvePromotionLink(
  item: NavigationItem,
  router: AppVueRouter,
): RouteLocationRaw {
  const options = {
    params: { categoryId: String(item.props?.categoryId), actionUrl: item.id },
  };
  return router.resolve({ name: RouteName.PROMOTION_DETAILS, ...options });
}
