import { LobbyItemType } from '@leon-hub/api-sdk';

import type {
  LobbyItemSdk,
  LobbyGroupsItem,
} from 'web/src/modules/lobby/types';

export default function isLobbyGroupsItem(input: LobbyItemSdk): input is LobbyGroupsItem {
  return input.typeId === LobbyItemType.GROUPS;
}
