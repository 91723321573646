import type { SportEventsRegionPageNavigationService } from 'web/src/modules/sportline/submodules/region/types';
import {
  usePrerenderSportEventsRegionPageNavigationGuard,
} from 'web/src/modules/sportline/submodules/region/services/navigation/usePrerenderSportEventsRegionPageNavigationGuard';
import {
  useDefaultSportEventsRegionPageNavigationGuard,
} from 'web/src/modules/sportline/submodules/region/services/navigation/useDefaultSportEventsRegionPageNavigationGuard';

let service: SportEventsRegionPageNavigationService;

export function useSportlineRegionPageNavigationService(): SportEventsRegionPageNavigationService {
  if (!service) {
    service = process.env.VUE_APP_PRERENDER
      ? usePrerenderSportEventsRegionPageNavigationGuard()
      : useDefaultSportEventsRegionPageNavigationGuard();
  }

  return service;
}
