import {
  isString,
  assert,
  isFunction,
} from '@leon-hub/guards';
import { Deferred, Json } from '@leon-hub/utils';
import type Logger from '@leon-hub/logging';
import { logger as loggerInstance } from '@leon-hub/logging';

import CaptchaServiceError from 'web/src/modules/captcha/services/errors/CaptchaServiceError';
import type { ReCaptchaV2Instance, VReCaptchaOptions } from 'web/src/modules/captcha/components/VReCaptcha/types';
import RECAPTCHA_CHALLENGE_SELECTOR from 'web/src/modules/captcha/components/VReCaptcha/constants/reCaptchaChallengeSelector';
import { reCaptchaApiLoadedCallbackName } from 'web/src/modules/captcha/components/VReCaptcha/constants';
import type { ReCaptchaResponse } from 'web/src/modules/captcha/store/types';

import isGreCaptcha from './utils/isGreCaptcha';
import type ReCaptchaService from './RecaptchaService';

function assertExecution(instance: ReCaptchaV2Instance, method: keyof ReCaptchaV2Instance) {
  if (!isFunction(instance[method])) {
    throw new Error(`ReCAPTCHA instance doesn't have ${method} method`);
  }
}

interface ReCaptchaServiceOptions {
  scriptId?: string;
}

const getChallengeParentElement: () => HTMLElement | null = () => document
  ?.querySelector<HTMLElement>(RECAPTCHA_CHALLENGE_SELECTOR)
  ?.parentElement?.parentElement ?? null;

/**
 * ReCaptcha Manifestation
 * https://github.com/google/recaptcha/issues/231
 * https://github.com/google/recaptcha/issues/286
 */
export default class DefaultReCaptchaService implements ReCaptchaService {
  private deferredLoad: Deferred<null>;

  private deferredExecute: Deferred<ReCaptchaResponse>;

  static $instance: DefaultReCaptchaService | null = null;

  static getInstance(): DefaultReCaptchaService {
    if (DefaultReCaptchaService.$instance) {
      return DefaultReCaptchaService.$instance;
    }

    DefaultReCaptchaService.$instance = new DefaultReCaptchaService();

    return DefaultReCaptchaService.$instance;
  }

  private get scriptId(): string {
    return this.options?.scriptId ?? 'ReCaptchaService';
  }

  constructor(private readonly options?: ReCaptchaServiceOptions, private readonly logger: Logger = loggerInstance) {
    this.deferredLoad = new Deferred();
    this.deferredExecute = new Deferred();

    if (process.env.VUE_APP_RENDERING_CSR) {
      window[reCaptchaApiLoadedCallbackName] = this.notifyApiLoaded.bind(this);
    }
  }

  notifyApiLoaded(): void {
    this.deferredLoad.resolve(null);
  }

  notifyExecuting(response: ReCaptchaResponse): void {
    this.deferredExecute.resolve(response);
  }

  deferredExecutingReset(): void {
    this.deferredExecute = new Deferred();
  }

  loadRecaptchaScript(apiUrl: string): Promise<null> {
    let scriptElement: HTMLScriptElement | null = document.querySelector(`#${this.scriptId}`);

    if (scriptElement) {
      this.logger.info('ReCAPTCHA script has already been loaded');
    } else {
      scriptElement = document.createElement('script');
      scriptElement.id = this.scriptId;
      scriptElement.src = apiUrl;
      scriptElement.async = true;
      scriptElement.defer = true;
      scriptElement.addEventListener('error', (event) => {
        scriptElement?.parentElement?.removeChild(scriptElement);
        this.deferredLoad.reject(new Error(
          `ScriptElementError, ErrorEvent=${Json.stringify(event, { defaultValue: 'Unknown error event' })}`,
        ));
      });
      document.head.append(scriptElement);
    }

    return this.deferredLoad.promise;
  }

  async tryLoadRecaptchaScript(apiUrl: string, retryCount = 3, attempt = 1): Promise<null> {
    return this.loadRecaptchaScript(apiUrl)
      .catch((error) => {
        this.deferredLoad = new Deferred();
        if (attempt >= retryCount) {
          throw new CaptchaServiceError({
            log: `Unable to load captcha script at ${apiUrl} after ${retryCount} tries`,
            originalError: error,
          });
        }

        return this.tryLoadRecaptchaScript(apiUrl, retryCount, attempt + 1);
      });
  }

  wait(): Promise<null> {
    return this.deferredLoad.promise;
  }

  render(element: HTMLElement, options: VReCaptchaOptions, callback: Function): void {
    this.wait().then(() => {
      if (this.grecaptcha && isGreCaptcha(this.grecaptcha)) {
        assertExecution(this.grecaptcha, 'render');
        callback(this.grecaptcha?.render(element, options));
      }
    }).catch(this.executionErrorHandler.bind(this));
  }

  reset(): void {
    assert(this.deferredLoad.resolved, '[ReCaptchaService:reset] ReCAPTCHA has not been loaded.');
    this.wait().then(() => {
      if (this.grecaptcha && isGreCaptcha(this.grecaptcha)) {
        assertExecution(this.grecaptcha, 'reset');
        this.grecaptcha?.reset();
      }
    }).catch(this.executionErrorHandler.bind(this));
  }

  // eslint-disable-next-line rulesdir/class-method-use-this-regex,class-methods-use-this
  private get grecaptcha(): ReCaptchaV2Instance | null | undefined {
    const { grecaptcha } = window;
    return isGreCaptcha(grecaptcha) ? grecaptcha : null;
  }

  async execute(): Promise<string | null | undefined> {
    assert(this.deferredLoad.resolved, '[ReCaptchaService:execute] ReCAPTCHA has not been loaded.');
    return this.wait().then(() => {
      if (this.grecaptcha && isGreCaptcha(this.grecaptcha)) {
        assertExecution(this.grecaptcha, 'execute');
        return this.grecaptcha?.execute();
      }
      return undefined;
    }).catch(this.executionErrorHandler.bind(this));
  }

  checkRecaptchaLoad(): boolean {
    if (isGreCaptcha(this.grecaptcha)) {
      this.notifyApiLoaded();
      return true;
    }
    this.logger.warn('ReCAPTCHA has not been loaded');
    return false;
  }

  executionErrorHandler(error: Error): null {
    this.logger.error(error, 'ReCaptcha execution error occurred');
    return null;
  }

  async getResponse(): Promise<string | null | undefined> {
    assert(this.deferredLoad.resolved, '[ReCaptchaService:getResponse] ReCAPTCHA has not been loaded.');
    return this.wait().then(() => {
      if (this.grecaptcha && isGreCaptcha(this.grecaptcha)) {
        assertExecution(this.grecaptcha, 'getResponse');
        return this.grecaptcha?.getResponse();
      }
      return null;
    }).catch(this.executionErrorHandler.bind(this));
  }

  async invalidate(): Promise<ReCaptchaResponse> {
    const captchaResponse = await this.getResponse();

    if (isString(captchaResponse) && captchaResponse.length > 0) {
      return Promise.resolve({ captchaResponse });
    }

    await this.execute();
    const result = await this.deferredExecute.promise;
    this.deferredExecutingReset();
    return result;
  }

  // eslint-disable-next-line rulesdir/class-method-use-this-regex,class-methods-use-this
  isChallengeVisible(): boolean {
    const element: HTMLElement | null = getChallengeParentElement();

    if (element) {
      return window.getComputedStyle(element).visibility !== 'hidden';
    }

    return false;
  }
}
