import type { FreeBet } from '@leon-hub/api-sdk';

import { getFreebetMaxPrize } from '../../../utils';

interface Payload {
  totalOdds: number | null;
  selectedStakeValue: number;
  currentFreeBet: FreeBet | null;
}

export function getMaxPrize({
  totalOdds,
  selectedStakeValue,
  currentFreeBet,
}: Payload): number {
  if (!totalOdds || !selectedStakeValue) {
    return 0;
  }
  if (currentFreeBet) {
    return getFreebetMaxPrize(currentFreeBet, totalOdds);
  }
  return totalOdds * selectedStakeValue;
}
