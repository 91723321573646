import { useRoute } from 'vue-router';
import type { Ref } from 'vue';
import { computed } from 'vue';

import { RouteName } from '@leon-hub/routing-config-names';

import { isCasinoGameRouteName } from 'web/src/modules/casino/utils';

export interface UseLayoutDesktopInfo {
  hasLeftSideBar: Ref<boolean>;
  hasRightSideBar: Ref<boolean>;
  isFullWidth: Ref<boolean>;
}

export default function useLayoutDesktopInfo(): UseLayoutDesktopInfo {
  const currentRoute = useRoute();

  const hasLeftSideBar = computed(() => {
    if (process.env.VUE_APP_FEATURE_DESKTOP_SIDEBAR_LIKE_MOBILE_ENABLED) {
      return !isCasinoGameRouteName(currentRoute.name);
    }

    return !!currentRoute.meta.hasLeftSidebar;
  });
  const hasRightSideBar = computed(
    () => !!process.env.VUE_APP_FEATURE_SPORTLINE_ENABLED && (hasLeftSideBar.value || currentRoute.name === RouteName.SPORT_EVENT_DETAILS)
      && !currentRoute.meta.isRightSidebarHidden,
  );
  const isFullWidth = computed(() => !hasLeftSideBar.value && !hasRightSideBar.value);

  return {
    hasLeftSideBar,
    hasRightSideBar,
    isFullWidth,
  };
}
