import type { Ref } from 'vue';
import { computed } from 'vue';

import type { IconNameType } from '@leon-hub/icons';
import { IconName } from '@leon-hub/icons';

import type { VIconProps } from '@components/v-icon';

import type { VSocialProps } from 'web/src/components/Social/VSocial/types';

import { SocialType } from '../enums';

const mappedSocialIcons: Record<SocialType, IconNameType> = Object.freeze({
  facebook: IconName.FB_CIRCLE,
  instagram: IconName.INSTAGRAM_CIRCLE,
  ok: IconName.OK_CIRCLE,
  vk: IconName.VK_CIRCLE,
  rss: IconName.RSS_CIRCLE,
  telegram: IconName.TELEGRAM_CIRCLE,
});

export interface VSocialComposable {
  iconProps: Ref<VIconProps>;
}

export default function useVSocial(props: VSocialProps): VSocialComposable {
  const getIcon = computed(() => mappedSocialIcons[props.socialType || SocialType.FACEBOOK]);
  const iconProps = computed<VIconProps>(() => ({
    name: getIcon.value,
    size: props.size,
  }));

  return {
    iconProps,
  };
}
