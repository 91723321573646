<script setup lang="ts">
import { useCssModule } from 'vue';

import { IconName, IconSize } from '@leon-hub/icons';

import { VIcon } from '@components/v-icon';

import createDisplayValueChangeDirective from 'web/src/utils/directives/createDisplayValueChangeDirective';
import HighestOddsIcon from 'web/src/modules/sportline/submodules/highest-odds/components/HighestOddsIcon.vue';

import { OddChangeVariants } from '../enums';
import useSlipListBettingItem from '../composables/useSlipListBettingItem';
import type { SlipListBettingItemEmits, SlipListBettingItemProps } from '../types';

const props = defineProps<SlipListBettingItemProps>();
const emits = defineEmits<SlipListBettingItemEmits>();

const {
  showBankerButton,
  showErrorBlock,
  computedErrorMessage,
  isUnavailableState,
  isPaused,
  isBlocked,
  wrapperRef,
  isCollapseAnimationStarted,
  highlightedReplacedItem,
  emitBankerClick,
  onDeleteAnimationEnd,
  onDeleteButtonClick,
} = useSlipListBettingItem(props, emits);

const cssModule = useCssModule();

const oddsUpClassName = 'slip-list-item__odd--up';
const oddsDownClassName = 'slip-list-item__odd--down';

const vDirection = {
  updated: createDisplayValueChangeDirective({
    moveUp: cssModule[oddsUpClassName],
    moveDown: cssModule[oddsDownClassName],
    upDirection: OddChangeVariants.UP,
    downDirection: OddChangeVariants.DOWN,
    timeout: props.highlightTimeout,
  }),
};
</script>

<template>
  <div v-auto-id="'SlipListBettingItem'"
    ref="wrapperRef"
    :class="{
      [$style['slip-list-item']]: true,
      [$style['slip-list-item--with-remove']]: true,
      [$style[`slip-list-item--${status}`]]: isUnavailableState,
      [$style['slip-list-item--unavailable']]: isUnavailableState,
      [$style['slip-list-item--collapse-animation']]: isCollapseAnimationStarted,
      [$style['slip-list-item--must-accept']]: needToAcceptChanges,
    }"
    @animationend="onDeleteAnimationEnd"
  >
    <div :class="$style['slip-list-item__wrapper-inner']">
      <div :class="$style['slip-list-item__container']">
        <button
          type="button"
          data-test-id="remove-button"
          :class="$style['slip-list-item__remove']"
          :title="$t('WEB2_SLIP_REMOVE_ITEM')"
          @click="onDeleteButtonClick"
        >
          <VIcon
            :name="IconName.CROSS"
            :size="IconSize.SIZE_16"
          />
        </button>
        <div :class="$style['slip-list-item__main']">
          <div :class="$style['slip-list-item__columns']">
            <div
              :class="[
                $style['slip-list-item__column'],
                $style['slip-list-item__column--left'],
              ]"
            >
              <ul
                v-if="competitors?.length"
                :class="$style['slip-list-item__competitors']"
              >
                <li
                  v-for="team in competitors"
                  :key="team"
                  :class="$style['slip-list-item__competitors-member']"
                >
                  {{ team }}
                </li>
              </ul>
              <p
                v-else
                :class="$style['slip-list-item__caption']"
              >
                {{ eventName }}
              </p>
              <p :class="$style['slip-list-item__market-runner']">
                <span
                  v-if="isLive"
                  :class="$style['slip-list-item__live-badge']"
                >
                  LIVE
                </span>
                <template v-if="marketName">
                  <span>{{ marketName }}</span>
                  <span :class="$style['slip-list-item__dash']">-</span>
                </template>
                <span
                  v-if="oldRunnerName"
                  :class="$style['slip-list-item__old-runner']"
                >{{ oldRunnerName }}</span>
                <span
                  :class="{
                    [$style['slip-list-item__runner-name']]: true,
                    [$style['slip-list-item__runner-name--highlighted']]: highlightedReplacedItem,
                    [$style['slip-list-item__runner-name--separated']]: oldRunnerName,
                  }"
                >{{ runnerName }}</span>
              </p>
            </div>
            <div
              :class="[
                $style['slip-list-item__column'],
                $style['slip-list-item__column--right'],
              ]"
            >
              <div :class="$style['slip-list-item__odds-container']">
                <p
                  v-direction="originalOdd ? {} : { moveTo: oddChangeVariant, value: odd }"
                  :class="{
                    [$style['slip-list-item__odd']]: true,
                    [$style[oddsUpClassName]]: !!originalOdd && oddChangeVariant === OddChangeVariants.UP,
                    [$style[oddsDownClassName]]: !!originalOdd && oddChangeVariant === OddChangeVariants.DOWN,
                  }"
                >
                  <span
                    v-if="originalOdd"
                    :class="$style['slip-list-item__original-odd']"
                  >{{ originalOdd }}</span>
                  <span
                    :class="$style['slip-list-item__current-odd']"
                    :title="zeroMargin ? $t('WEB2_SPORT_BANNER_HIGHEST_ODDS') : undefined"
                  >
                    <HighestOddsIcon
                      v-if="zeroMargin"
                      :class="$style['slip-list-item__highest-odds']"
                      :icon-size="IconSize.SIZE_16"
                      highest-odds-icon-color="gradient"
                    />{{ odd }}
                  </span>
                </p>
              </div>
              <div
                v-if="!(isBlocked || isPaused)"
                :class="$style['slip-list-item__singles-control']"
              >
                <slot name="control" />
              </div>
              <div
                v-if="showBankerButton"
                :class="$style['slip-list-item__banker']"
              >
                <button
                  :class="{
                    [$style['slip-list-item-banker-button']]: true,
                    [$style['slip-list-item-banker-button--is-active']]: bankerActive,
                  }"
                  :disabled="bankerDisabled"
                  :title="bankerActive ? $t('WEB2_SLIP_BANKER_DISABLE') : $t('WEB2_SLIP_BANKER_ENABLE')"
                  type="button"
                  data-test-id="banker-button"
                  @click="emitBankerClick"
                >
                  <span :class="[$style['slip-list-item-banker-button__icon']]">
                    {{ $t('JS_SLIP_BET_EVENT_BANKER') }}
                  </span>
                </button>
              </div>
            </div>
          </div>
          <VIcon
            v-show="isPaused"
            :name="IconName.LOCK"
            :size="IconSize.SIZE_16"
            data-test-id="paused-icon"
            :class="$style['slip-list-item__icon-lock']"
          />
          <div
            v-show="showErrorBlock"
            data-test-id="blocked-message"
            :class="{
              [$style['slip-list-item__blocked-message']]: true,
              [$style['slip-list-item__blocked-message--stake']]: !isBlocked && errorMessage
            }"
          >
            {{ computedErrorMessage }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>
@import 'web/src/modules/slip/components/SlipListItem/style/slip-list-item';
</style>
