import type { ComputedRef, Ref } from 'vue';
import {
  watch,
  computed,
  ref,
} from 'vue';

import type { IconNameType } from '@leon-hub/icons';
import { IconName } from '@leon-hub/icons';

import type { CircleFilterShadows } from '../enums';
import type { VCircularProgressProps, CirclePositionProps } from '../types';
import getCircleStrokeWidth from './utils/getCircleStrokeWidth';
import getCirclePositionProps from './utils/getCirclePositionProps';
import getRelativePercentage from './utils/getRelativePercentage';
import getRelativeSafePercentage from './utils/getRelativeSafePercentage';
import getCircleLength from './utils/getCircleLength';
import getMatchedSizeValue from './utils/getMatchedSizeValue';
import getSafePercentage from './utils/getSafePercentage';
import getViewBox from './utils/getViewBox';
import getStrokeDasharray from './utils/getStrokeDasharray';
import getFilterId from './utils/getFilterId';
import getFilterShadowColorMatrix from './utils/getFilterShadowColorMatrix';

interface VCircularProgressComposable {
  progress: Ref<HTMLElement | undefined>;
  circularCircle: Ref<HTMLElement | undefined>;
  checkIcon: IconNameType;
  matchedSizeValue: ComputedRef<number>;
  circleStrokeWidth: ComputedRef<number>;
  circlePositionProps: ComputedRef<CirclePositionProps>;
  circleLength: ComputedRef<number>;
  viewBox: ComputedRef<string>;
  safePercentage: ComputedRef<number>;
  relativeSafePercentage: ComputedRef<number>;
  relativePercentage: ComputedRef<number>;
  strokeDasharray: ComputedRef<string>;
  filterId: number;
  filterShadowColorMatrix: CircleFilterShadows;
  onCircularProgressMounted: () => void;
}

export default function useVCircularProgress(props: VCircularProgressProps): VCircularProgressComposable {
  const progress = ref<HTMLElement>();
  const circularCircle = ref<HTMLElement>();
  const checkIcon = IconName.CHECK_ROUNDED;

  const matchedSizeValue = computed(() => getMatchedSizeValue(props));
  const circleStrokeWidth = computed(() => getCircleStrokeWidth(props));
  const circlePositionProps = computed(() => getCirclePositionProps(props));
  const circleLength = computed(() => getCircleLength(props));
  const viewBox = computed(() => getViewBox(props));
  const safePercentage = computed(() => getSafePercentage(props));
  const relativeSafePercentage = computed(() => getRelativeSafePercentage(props));
  const relativePercentage = computed(() => getRelativePercentage(props));
  const strokeDasharray = computed(() => getStrokeDasharray(props));
  const filterId = getFilterId();
  const filterShadowColorMatrix = getFilterShadowColorMatrix(props);

  function setCircleLengthVariable(): void {
    progress?.value?.style.setProperty('--circleLength', `${circleLength.value || 0}`);
  }

  function onCircularProgressMounted(): void {
    setCircleLengthVariable();
  }

  watch(() => props.circleSize, setCircleLengthVariable);

  return {
    progress,
    circularCircle,
    checkIcon,
    matchedSizeValue,
    circleStrokeWidth,
    circlePositionProps,
    circleLength,
    viewBox,
    safePercentage,
    relativeSafePercentage,
    relativePercentage,
    strokeDasharray,
    filterId,
    filterShadowColorMatrix,
    onCircularProgressMounted,
  };
}
