import type { App } from 'vue';

import { UnhandledRejection } from '@leon-hub/app-errors/src/UnhandledRejection';
import { ComponentWarning } from '@leon-hub/app-errors/src/ComponentWarning';
import { ComponentError } from '@leon-hub/app-errors';
import type { EventsBus } from '@leon-hub/event-bus';
import { logger } from '@leon-hub/logging';
import { isOpenSearchLoggableError, isSentryLoggableError } from '@leon-hub/errors-whitelist';
import type { ApiError } from '@leon-hub/api';
import {
  isObject,
} from '@leon-hub/guards';
import { BusEvent, useEventsBus } from '@leon-hub/event-bus';
import { SentryCordova } from '@leon-hub/cordova';

function isApiError(argument: unknown): argument is ApiError {
  return isObject(argument) && 'code' in argument && argument.name !== 'FirebaseError';
}

function handleApiError({ error, bus, event } : { error: Error; bus: EventsBus; event?: Event }) {
  bus.emit(BusEvent.API_ERROR, {
    error,
    event,
  });
}

function setupGlobalUnhandledRejectionError(bus: EventsBus) {
  if (!process.env.VUE_APP_RENDERING_SSR) {
    window.addEventListener('unhandledrejection', (event) => {
      try {
        if (event.defaultPrevented) {
          return;
        }

        event.preventDefault();
        if (isApiError(event.reason)) {
          handleApiError({ error: event.reason, bus, event });
          return;
        }

        if (isOpenSearchLoggableError(event.reason)) {
          logger.error(new UnhandledRejection({
            event,
          }));
        }
      } catch (error) {
        logger.error('Unhandled Promise Rejection processing error', {
          event, error,
        });
      }
    });
  }
}

export default function configureErrorHandler(app: App): App {
  const bus = useEventsBus();

  if (process.env.NODE_ENV === 'production') {
    setupGlobalUnhandledRejectionError(bus);

    // eslint-disable-next-line no-param-reassign
    app.config.errorHandler = (error: Error, vm, info) => {
      if (isApiError(error)) {
        handleApiError({ error, bus });
        return;
      }

      if (isOpenSearchLoggableError(error)) {
        const componentError = new ComponentError({
          prefix: '[Vue ERROR]: ',
          originalError: error,
          info,
          vm,
        });

        logger.error(componentError);
      }

      if (process.env.VUE_APP_PLATFORM_CORDOVA && isSentryLoggableError(error)) {
        SentryCordova.captureException(error);
      }
    };

    // eslint-disable-next-line no-param-reassign
    app.config.warnHandler = (message, vm, trace) => {
      const componentWarning = new ComponentWarning({
        message,
        trace,
        vm,
      });

      logger.warn(componentWarning);
    };
  }

  return app;
}
