import { Timer } from "@leon-hub/utils";
import { whenClientNetworkIdle } from "@leon-hub/idle";
import { nextAnimationFrame } from "@leon-hub/html-utils";
import { assert } from "@leon-hub/guards";
function combineGoogleFontUrls(urls) {
  let domain;
  const params = [];
  for (const url of urls) {
    const [urlDomain, urlParams] = url.split("?");
    if (domain === void 0) {
      domain = urlDomain;
    }
    assert(domain === urlDomain, "Unable to combine google fonts with different domains");
    params.push(urlParams);
  }
  return `${domain}?${params.join("&")}`;
}
const primaryFonts = /* @__PURE__ */ (() => process.env.VUE_APP_STYLE_SLOTT)() ? [
  "https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap",
  "https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap"
] : [
  "https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@700&&display=swap",
  "https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,400&display=swap"
];
const secondaryFonts = /* @__PURE__ */ (() => process.env.VUE_APP_STYLE_SLOTT)() ? [
  "https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@700&&display=swap",
  "https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,400&display=swap",
  "https://fonts.googleapis.com/css2?family=Mulish:wght@400;700;900&display=swap",
  "https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
] : [
  "https://fonts.googleapis.com/css2?family=Mulish:wght@400;700;900&display=swap",
  "https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
];
const primaryFontFaces = /* @__PURE__ */ (() => process.env.VUE_APP_STYLE_SLOTT)() ? ["Poppins", "Montserrat"] : ["Roboto"];
const secondaryFontFaces = ["Mulish", "Prompt"];
const fontUrls = /* @__PURE__ */ (() => [...primaryFonts, ...secondaryFonts])();
const fontFaces = /* @__PURE__ */ (() => [...primaryFontFaces, ...secondaryFontFaces])();
function getFinalGoogleFontsUrl() {
  return combineGoogleFontUrls(fontUrls);
}
async function loadGoogleFontsUrls() {
  return new Promise((resolve) => {
    let timeout = 0;
    const element = document.createElement("div");
    element.style.visibility = "hidden";
    element.style.height = "0px";
    element.innerHTML = "&nbsp;";
    const resolveLoadedFonts = async () => {
      if (timeout) {
        Timer.clearTimeout(timeout);
      }
      await nextAnimationFrame();
      await document.fonts.ready;
      element.remove();
      resolve();
    };
    const triggerFontsLoading = () => {
      if (fontFaces.length > 0) {
        for (const fontFace of fontFaces) {
          const spanElement = document.createElement("span");
          spanElement.style.fontFamily = fontFace;
          element.appendChild(spanElement);
        }
        document.body.appendChild(element);
      }
      void resolveLoadedFonts();
    };
    timeout = Timer.setTimeout(resolveLoadedFonts, 1e4);
    const style = document.createElement("style");
    style.innerHTML = "* { color: rgba(0,0,0,0) !important; }";
    document.head.appendChild(style);
    void whenClientNetworkIdle({
      // Cordova fonts loading during splashcreen
      timeout: process.env.VUE_APP_PLATFORM_CORDOVA ? 10 : 3e3,
      interval: process.env.VUE_APP_PLATFORM_CORDOVA ? 10 : 200
    }).then(() => {
      const link = document.createElement("link");
      link.rel = "stylesheet";
      link.type = "text/css";
      link.crossOrigin = "anonymous";
      link.href = getFinalGoogleFontsUrl();
      link.onload = () => {
        triggerFontsLoading();
        if (style.parentNode) {
          document.head.removeChild(style);
        }
      };
      link.onerror = () => {
        void resolveLoadedFonts();
        if (style.parentNode) {
          document.head.removeChild(style);
        }
      };
      document.head.appendChild(link);
    });
  });
}
export {
  combineGoogleFontUrls,
  getFinalGoogleFontsUrl,
  loadGoogleFontsUrls
};
