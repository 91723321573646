import { sleep } from '@leon-hub/utils';

import type { SlipMappingKey } from 'web/src/modules/framed-app/components/VirtualSportFramedWidget/types';
import type { VirtualSportSlipMapping } from 'web/src/modules/sportline/submodules/virtual-sport/types';

import type VirtualSportBetsMappingStorage from './VirtualSportBetsMappingStorage';

interface VirtualSportBetsMapperOptions {
  repeatOnError?: boolean;
  repeatOnErrorTimeout?: number;
}

type FetchVirtualSportSlipMappingAction = (
  bet: SlipMappingKey,
  options?: { silent?: boolean },
) => Promise<VirtualSportSlipMapping | undefined>;

export default class VirtualSportBetsMapper {
  constructor(
    private getStorage: () => VirtualSportBetsMappingStorage | undefined,
    private fetchVirtualSportSlipMapping: FetchVirtualSportSlipMappingAction,
    private options: VirtualSportBetsMapperOptions = {},
  ) {}

  async getSlipMappingByBet(bet: SlipMappingKey): Promise<VirtualSportSlipMapping | undefined> {
    const storage = this.getStorage();

    if (!storage) {
      return undefined;
    }

    let slipMapping = storage.getSlipMapping(bet);

    if (slipMapping) {
      return slipMapping;
    }

    const silent = !!this.options.repeatOnError;
    slipMapping = await this.fetchVirtualSportSlipMapping(bet, { silent });

    if (!slipMapping && this.options.repeatOnError) {
      await sleep(this.options.repeatOnErrorTimeout || 1000);
      slipMapping = await this.fetchVirtualSportSlipMapping(bet, { silent: false });
    }

    return storage.setSlipMapping(bet, slipMapping);
  }
}
