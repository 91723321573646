<script lang="ts" setup>
import {
  computed,
  onBeforeMount,
  ref,
} from 'vue';
import { useRouter } from 'vue-router';
import { useHead } from '@unhead/vue';

import { getLocationOrigin } from '@leon-hub/service-locator-env';
import {
  assert,
  isOptionalString,
} from '@leon-hub/guards';

const router = useRouter();

const code = ref('301');
const location = ref('/');

onBeforeMount(() => {
  const { redirectLocation, redirectCode } = window.history.state;
  /*@__PURE__*/ assert(/*@__PURE__*/ isOptionalString(redirectLocation));
  /*@__PURE__*/ assert(/*@__PURE__*/ isOptionalString(redirectCode));

  location.value = redirectLocation || '/';
  if (redirectCode && ['301', '302'].includes(redirectCode)) {
    code.value = redirectCode;
  }
  if (!process.env.VUE_APP_PRERENDER) {
    void router.push(location.value);
  }
});

const meta = computed(() => ({
  meta: [{
    name: 'prerender-status-code',
    content: code.value,
  }, {
    name: 'prerender-header',
    content: `Location: ${getLocationOrigin()}${location.value}`,
  }],
}));

useHead(meta);
</script>

<template>
  <div v-auto-id="'RedirectSeoRouteComponent'">Redirecting...</div>
</template>
