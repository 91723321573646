import { LobbyGameType } from '@leon-hub/api-sdk';

import type { EgsGame } from 'web/src/modules/egs/types';
import type {
  LobbyGamesItem,
} from 'web/src/modules/lobby/types';

export function isLobbyAndGameOfSameType(game: EgsGame, lobbyItem: LobbyGamesItem): boolean {
  return String(game.typeId) === String(lobbyItem.category.type) || !lobbyItem.category.type || lobbyItem.category.type === LobbyGameType.ALL;
}
