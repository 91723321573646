import {
  DisplayType,
} from '@leon-hub/api-sdk';

import type { CustomerNotificationMessage } from 'web/src/modules/customer-notifications/types';
import { CustomerNotificationMessageSource } from 'web/src/modules/customer-notifications/enums';
import type { AlcrFrontNotification } from 'web/src/modules/front-notifications/types';

// eslint-disable-next-line max-len
export default function createCustomerMessageFromFrontNotification(notification: AlcrFrontNotification): CustomerNotificationMessage {
  return {
    id: notification.id,
    key: `${notification.id}-${CustomerNotificationMessageSource.ALCR}`,
    title: notification.title,
    message: notification.message,
    createdAt: Date.parse(notification.timestamp),
    isRead: notification.isRead,
    isModal: notification.displayType !== DisplayType.SILENT,
    source: CustomerNotificationMessageSource.ALCR,
    image: notification.imageUrl ?? undefined,
    button: notification.ctaButtonText && notification.ctaButtonLink ? {
      text: notification.ctaButtonText,
      link: notification.ctaButtonLink,
    } : undefined,
  };
}
