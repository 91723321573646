import type { ComputedRef, Ref } from 'vue';
import { ref, toRef, computed } from 'vue';

import type { SlipTraceLines } from '../submodules/slip-info/types';
import { useSlipInfoStore } from '../submodules/slip-info/store';

interface UseSlipTrace {
  isTraceModalVisible: Ref<boolean>;
  currentTraceLines: ComputedRef<SlipTraceLines | null>;
  showTraceButton: ComputedRef<boolean>;
  showTraceModal(index: number): void;
  hideTraceModal(): void;
}

export function useSlipTrace(): UseSlipTrace {
  const activeTraceIndex = ref<number>(0);

  const isTraceModalVisible = ref<boolean>(false);

  const slipTraceLines = toRef(useSlipInfoStore(), 'slipTraceLines');

  const currentTraceLines = computed<SlipTraceLines | null>(
    () => slipTraceLines.value?.[activeTraceIndex.value] ?? null,
  );

  const showTraceModal = (index = 0): void => {
    activeTraceIndex.value = index;
    isTraceModalVisible.value = true;
  };

  const hideTraceModal = (): void => {
    isTraceModalVisible.value = false;
  };

  const showTraceButton = computed<boolean>(() => !!process.env.VUE_APP_LAYOUT_DESKTOP && !!slipTraceLines.value?.length);

  return {
    isTraceModalVisible,
    currentTraceLines,
    showTraceButton,
    showTraceModal,
    hideTraceModal,
  };
}
