import type { FormExternalErrors } from 'web/src/components/Form/types';

export default class CustomerFormError extends Error {
  constructor(public customErrors: FormExternalErrors) {
    super(
      Object.keys(customErrors)
        .map((key) => customErrors[key][0].message)
        .join('\n'),
    );
  }
}
