import type { BaseLogger } from '@leon-hub/base-logger';
import type { BaseError } from '@leon-hub/errors';
import { LogLevel } from '@leon-hub/errors';

import type { Logger } from './Logger';
import type { LogData } from '../types';

export class NameSpacedLogger implements BaseLogger {
  private readonly logger: Logger;

  private readonly namespace: string[];

  constructor(logger: Logger, namespace: string[]) {
    this.logger = logger;
    this.namespace = namespace;
  }

  public info(input: LogData): void;
  public info(message: string | Error, payload?: unknown): void;
  public info(message: string, error?: Error): void;
  public info(message: string, payload?: unknown, error?: Error): void;
  public info(input: LogData | Error | string, payload?: unknown, error?: Error): void {
    this.logger.sendLog(LogLevel.INFO, input, payload, error, this.namespace);
  }

  public debug(input: LogData): void;
  public debug(message: string | Error, payload?: unknown): void;
  public debug(message: string, error?: Error): void;
  public debug(message: string, payload?: unknown, error?: Error): void;
  public debug(input: LogData | Error | string, payload?: unknown, error?: Error): void {
    this.logger.sendLog(LogLevel.DEBUG, input, payload, error, this.namespace);
  }

  public warn(input: LogData): void;
  public warn(message: string | Error, payload?: unknown): void;
  public warn(message: string, error?: Error): void;
  public warn(message: string, payload?: unknown, error?: Error): void;
  public warn(input: LogData | Error | string, payload?: unknown, error?: Error): void {
    this.logger.sendLog(LogLevel.WARN, input, payload, error, this.namespace);
  }

  public error(input: LogData): void;
  public error(message: string | Error | BaseError, payload?: unknown): void;
  public error(message: string, error?: Error): void;
  public error(message: string, payload?: unknown, error?: Error): void;
  public error(input: LogData | Error | BaseError | string, payload?: unknown, error?: Error): void {
    this.logger.sendLog(LogLevel.ERROR, input, payload, error, this.namespace);
  }

  public createNamespace(namespace: string): NameSpacedLogger {
    return new NameSpacedLogger(this.logger, namespace ? [...this.namespace, namespace] : [...this.namespace]);
  }
}
