import { RouteName } from '@leon-hub/routing-config-names';

import { AppModule } from 'web/src/modules/core/plugins/AppModule';
import type { AppVueRouter } from 'web/src/modules/core/services/router/types';
import { ModalDesktopPreset } from 'web/src/modules/core/enums';

export class I18nModule extends AppModule {
  // eslint-disable-next-line class-methods-use-this,sonarjs/cognitive-complexity
  addRoutes(router: AppVueRouter): void {
    if (process.env.VUE_APP_FEATURE_MULTIPLE_LOCALES_ENABLED) {
      router.addModalRoute({
        name: RouteName.LANGUAGE,
        path: '/language',
        title: '{{$t(\'WEB2_LANGUAGE_SELECTION\')}}',
        component: () => import('web/src/modules/i18n/components/LanguageSelector/LanguageSelector.vue'),
        prefetch: () => import('./prefetch/LanguagePagePrefetch'),
        modalPreset: process.env.VUE_APP_LAYOUT_DESKTOP ? ModalDesktopPreset.SmallDesktopModal : undefined,
      });
    }
  }
}
