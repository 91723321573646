import type {
  SportEvent,
  SportElement,
  SportEventElement,
} from 'web/src/modules/sportline/types';

import { filterRegionsListEvents } from './filterRegionElementEvents';
import { reduceListWithSportEventsCounters } from '../getCounters';

export default function filterSportsListEvents<SE extends SportEvent = SportEvent>(
  sportElements: ReadonlyArray<SportElement<SE>>,
  filter: (sportEventElement: SportEventElement<SE>) => boolean,
): SportElement<SE>[] {
  return sportElements
    .map((sportElement) => {
      const regions = filterRegionsListEvents(sportElement.regions, filter);
      return {
        ...sportElement,
        regions,
        counters: reduceListWithSportEventsCounters(regions),
      };
    })
    .filter((sportElement) => sportElement.regions.length > 0);
}
