import type { RouteLocationNormalized } from 'vue-router';

import { RouteName } from '@leon-hub/routing-config-names';
import {
  assert,
  isOptionalString,
} from '@leon-hub/guards';

import { AllGamesCategoryUrl } from 'web/src/modules/egs/constants';

export function getLobbyCategoryUrlFromRoute(route: Pick<RouteLocationNormalized, 'name' | 'params'>): string | undefined {
  const { categoryUrl } = route.params;
  assert(isOptionalString(categoryUrl));

  if (process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED && route.name !== RouteName.HOME && !categoryUrl) {
    return AllGamesCategoryUrl;
  }

  return categoryUrl;
}
