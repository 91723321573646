import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import type { VInformerProps } from 'web/src/components/Informer/VInformer/types';
import { getButtonProps } from 'web/src/components/Informer/VInformer/composables/utils';
import type { VButtonProps } from 'web/src/components/Button/VButton/types';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useFormatMoney } from 'web/src/modules/money/composables';
import type { FormatMoneyOptions } from 'web/src/modules/money/types';
import { BadgeKind } from 'web/src/components/Badge/VBadge/enums';

interface useVInformerComposable {
  cancelButtonProperties: ComputedRef<VButtonProps>;
  formattedValue: ComputedRef<string>;
  badgeKind: BadgeKind.DEFAULT;
}

export default function useVInformer(props: VInformerProps): useVInformerComposable {
  const { $translate } = useI18n();
  const $formatMoney = useFormatMoney();
  const options: FormatMoneyOptions = {};
  const badgeKind = BadgeKind.DEFAULT;

  const cancelButtonProperties = computed(
    () => getButtonProps($translate('WEB2_CUSTOMER_HISTORY_CANCEL').value),
  );
  const formattedValue = computed(() => {
    if (props.currency) {
      options.currency = props.currency;
    }
    return `${$formatMoney(props.value, options)}`;
  });

  return {
    cancelButtonProperties,
    formattedValue,
    badgeKind,
  };
}
