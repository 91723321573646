<script setup lang="ts">
import { computed } from 'vue';

import type { JackpotAnimatedAmountProps } from 'web/src/components/AmountAnimator/types';
import JackpotAnimation from 'web/src/components/AmountAnimator/JackpotAnimation/JackpotAnimation.vue';
import { useVAmountAnimator } from 'web/src/components/AmountAnimator/VAmountAnimator/composables';
import JackpotAnimationItem from 'web/src/components/AmountAnimator/JackpotAnimation/JackpotAnimationItem.vue';

const props = withDefaults(defineProps<JackpotAnimatedAmountProps>(), {
  duration: 15_000,
  timeRange: () => ({
    min: 1000,
    max: 1000,
  }),
  isInfinite: true,
  stepRange: () => ({
    min: 0.01,
    max: 0.1,
  }),
});

const {
  animatedValue,
} = useVAmountAnimator(props);

const amounts = computed<string[]>(() => (animatedValue.value > 0 ? animatedValue.value.toLocaleString('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}).split('') : []));
</script>

<template>
  <div v-auto-id="'JackpotAnimatedAmount'"
    :class="{
      [$style['jackpot-animated-amount']]: true,
      [$style[`jackpot-animated-amount--${kind}`]]: kind,
    }"
  >
    <JackpotAnimationItem
      v-if="!!currency"
      :text="currency"
      :kind="kind"
    />
    <template
      v-for="(item, index) in amounts"
      :key="index"
    >
      <JackpotAnimationItem
        v-if="item === '.' || item === ','"
        :text="item"
        :kind="kind"
      />
      <JackpotAnimation
        v-else
        :amount="parseInt(item)"
        :kind="kind"
      />
    </template>
  </div>
</template>

<style module lang="scss">
.jackpot-animated-amount {
  position: relative;
  z-index: 10;
  display: inline-flex;
  flex-direction: row;
  padding: 8px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0) 100%), #0c002d;
  border-radius: 6px;
  box-shadow: 0 0 16px 0 #000 inset;

  &--major {
    padding: 6px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0) 100%), #000325;
    border-radius: 8px;
    box-shadow: 0 0 8px 0 #000 inset;
  }

  &--minor {
    padding: 6px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0) 100%), #1e000f;
    border-radius: 8px;
    box-shadow: 0 0 8px 0 #000 inset;
  }
}
</style>
