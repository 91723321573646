import { isFormSchema, FormControlType } from '@leon-hub/form-utils';
import { isBoolean, isNullOrUndefined, isString } from '@leon-hub/guards';
import { CustomerFieldType } from '@leon-hub/api-sdk';

import CoreRegistrationFormService
  from 'web/src/modules/registration/services/CoreRegistrationFormService';
import { getUiFieldRawOptions, JsonSchemaAdapter } from 'web/src/utils/jsonSchemaAdapter';
import type { FormData, FormUiSchema } from 'web/src/components/Form/types';
import { useCountryStore } from 'web/src/modules/country/store';
import { stringToBoolean } from 'web/src/components/Form/utils/stringToBoolean';
import type {
  GetRegistrationFormSchemaOptions,
  RegistrationForm,
  RegistrationFormSchema,
  RegistrationFormService,
} from 'web/src/modules/registration/types';
import getFormSelectorCountryCode from 'web/src/modules/registration/utils/getFormSelectorCountryCode';
import fillSubmittedData from 'web/src/modules/registration/utils/fillSubmittedData';
import { useFormDefaultFormPhoneValue } from 'web/src/modules/phone/composables';
import { simpleRegistrationFields } from 'web/src/modules/registration/constants';

import { getFilledPhoneValue } from '../utils/getFilledPhoneValue';

export default class SimpleRegistrationFormService
  extends CoreRegistrationFormService
  implements RegistrationFormService {
  // eslint-disable-next-line class-methods-use-this,sonarjs/cognitive-complexity
  getFormSchema(form: RegistrationForm, options: GetRegistrationFormSchemaOptions): RegistrationFormSchema {
    const jsonSchema = JSON.parse(form.formValidationSchema);

    if (!isFormSchema(jsonSchema)) {
      throw new Error('jsonSchema should be of type FormSchema');
    }

    const store = useCountryStore();
    const { geoIpCountryCode } = store;

    const { parsePhoneNumberByUIField } = useFormDefaultFormPhoneValue();

    const {
      bonusCode, formFilledFields = {}, formsEmailHintsEnabled, formsEmailHints,
    } = options;

    const jsonSchemaAdapter = new JsonSchemaAdapter(form.uiFormSchema, jsonSchema)
      .addPropertiesById(CustomerFieldType.COUNTRY_SELECT, () => ({
        ...(process.env.VUE_APP_FEATURE_SINGLE_REGION_PRODUCT_ENABLED ? {} : {
          default: getFormSelectorCountryCode(geoIpCountryCode, form.uiFormSchema),
        }),
      }))
      .addPropertiesById(CustomerFieldType.PHONE_INPUT, (field) => ({
        default: {
          ...parsePhoneNumberByUIField(field),
          ...getFilledPhoneValue(formFilledFields),
        },
      }))
      .addPropertiesByWidget(FormControlType.BonusCode, {
        widget: bonusCode && !process.env.VUE_APP_FEATURE_STEP_REGISTRATION_ENABLED ? FormControlType.Text : FormControlType.BonusCode,
        disabled: !isNullOrUndefined(bonusCode),
        default: bonusCode ?? '',
      })
      .addPropertiesByWidget(FormControlType.TermsAcceptionCheckbox, (field) => ({
        default: isString(field.defaultValue) ? stringToBoolean(field.defaultValue) : false,
      }))
      .addPropertiesByWidget(FormControlType.Email, (field) => (formsEmailHintsEnabled ? {
        widget: FormControlType.SuggestEmail,
        options: {
          suggestion: formsEmailHints,
          ...getUiFieldRawOptions(field.options),
        },
      } : { widget: FormControlType.Email }));

    const uiSchema = jsonSchemaAdapter.getFormUiSchema();

    for (const fieldName of Object.keys(formFilledFields)) {
      if (uiSchema.fields && fieldName in uiSchema.fields) {
        if (isString(formFilledFields[fieldName])) {
          jsonSchemaAdapter.addPropertiesById(fieldName, {
            default: String(formFilledFields[fieldName]),
          });
        }
        if (isBoolean(formFilledFields[fieldName])) {
          jsonSchemaAdapter.addPropertiesById(fieldName, {
            default: formFilledFields[fieldName],
          });
        }
      }
    }

    return {
      schema: jsonSchemaAdapter.getFormSchema(),
      uiSchema: jsonSchemaAdapter.getFormUiSchema(),
    };
  }

  // eslint-disable-next-line class-methods-use-this
  fillUiSubmittedData(
    formToFill: FormUiSchema,
    submittedData: Maybe<FormData>,
  ): FormUiSchema {
    const uiSchema = { ...formToFill };

    fillSubmittedData(uiSchema, submittedData, simpleRegistrationFields);

    return uiSchema;
  }
}
