import { toRef } from 'vue';

import { MobileAppOs } from 'web/src/modules/app-download/enums';
import type { DownloadBadgeProps } from 'web/src/modules/two-factor-authentication/components/DownloadBadge/types';
import type { FormUiSchemaFieldPieTimerInput } from 'web/src/components/Form';
import type { FormUiSchemaDefaultFields } from 'web/src/components/Form/types';

export enum TfaAppLink {
  GOOGLE_AUTHENTICATOR = 'otpauth://totp',
}

export enum BadgeImagePrefix {
  ANDROID = 'google-play-badge-',
  IOS_DARK = 'app-store-dark-',
  IOS_LIGHT = 'app-store-light-',
}

export const DownloadBadgeData: Record<MobileAppOs, DownloadBadgeProps> = {
  Android: {
    appType: MobileAppOs.Android,
    redirectUrl: 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1',
    imageSrc: 'https://play.google.com/intl/en_us/badges/static/images/badges/ru_badge_web_generic.png',
    imageAlt: 'Get it on Google Play',
    badgeImagePrefix: toRef(() => BadgeImagePrefix.ANDROID),
  },
  Ios: {
    appType: MobileAppOs.Ios,
    redirectUrl: 'https://apps.apple.com/ru/app/google-authenticator/id388497605',
    imageSrc: 'https://linkmaker.itunes.apple.com/ru-ru/badge-lrg.svg?releaseDate=2010-09-20&kind=iossoftware&bubble=ios_apps',
    imageAlt: 'Get it on App Store',
    badgeImagePrefix: toRef(() => BadgeImagePrefix.IOS_DARK),
  },
};

export type UiSchemaRequiredField = 'order' | 'focus' | 'submitButton' | 'fields' | 'validatorErrorPatterns';

export interface LoginUiSchema extends FormUiSchemaDefaultFields {
  code: FormUiSchemaFieldPieTimerInput;
}
