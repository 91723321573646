import type { VHeadlineBannerProps } from 'web/src/components/HeadlineBanner/VHeadlineBanner/types';

export default function getBannerTextStyles(
  props: VHeadlineBannerProps,
): Record<string, string> {
  const styles: Record<string, string> = {};

  if (props.textColor) {
    styles.color = props.textColor;
  }

  return styles;
}
