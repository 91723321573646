import type { SportsTreeSportElement } from 'web/src/modules/sportline/types/rest';
import type { TopLeagueListElement } from 'web/src/modules/sportline/types';

import { extractTopLeaguesByCondition } from './extractTopLeaguesByCondition';

export function extractTopLeagues(
  listElement?: Maybe<SportsTreeSportElement | SportsTreeSportElement[]>,
): TopLeagueListElement[] {
  return extractTopLeaguesByCondition(listElement, (leagueElement) => leagueElement.league.top);
}
