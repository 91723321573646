import type { RouteRecordName } from 'vue-router';

import type { NavigationItem } from '@leon-hub/navigation-config';
import { CategoryType } from '@leon-hub/api-sdk';
import { RouteName } from '@leon-hub/routing-config-names';

export default function isActiveCategory(
  item: NavigationItem,
  currentRouteName: RouteRecordName | null | undefined,
  categoryId: string | string[],
): boolean {
  if (item.props?.type === CategoryType.BONUS_WALLET) {
    return currentRouteName === RouteName.BONUS_WALLET;
  }

  const isChildren = !!item.children?.find((child) => child.id === categoryId);

  return isChildren || categoryId === item.id;
}
