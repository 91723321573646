import { BetInputErrorKind } from '../enums';

export default function getFastBetInputError(payload: {
  input: string;
  max?: number | null;
  min?: number | null;
  isRequired: boolean;
}): BetInputErrorKind {
  const {
    input, isRequired, min, max,
  } = payload;
  const parsed = Number.parseInt(input, 10);
  const haveWrongSymbols = !/^\d+$/g.test(input);
  if (!input && !isRequired) {
    return BetInputErrorKind.NONE;
  }
  if (Number.isNaN(parsed) || !Number.isFinite(parsed) || haveWrongSymbols) {
    return BetInputErrorKind.INVALID_INPUT;
  }
  if (min && parsed < min) {
    return BetInputErrorKind.BELOW_MINIMUM;
  }
  if (max && parsed > max) {
    return BetInputErrorKind.ABOVE_MAXIMUM;
  }
  return BetInputErrorKind.NONE;
}
