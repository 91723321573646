import type {
  GetEventStatusID,
  DoGetEventStatusAction,
  OnGetEventRequestBehaviour,
} from './types';
import {
  GetEventSubscriptionStatus,
  GetEventSubscriptionAction,
} from './enums';

export function createGetEventRequestOnPageBehaviour(
  id: GetEventStatusID,
  doAction: DoGetEventStatusAction,
): OnGetEventRequestBehaviour {
  function getAction(status: GetEventSubscriptionStatus, count: number): GetEventSubscriptionAction {
    switch (status) {
      case GetEventSubscriptionStatus.OK:
        // do nothing on get event data
        return GetEventSubscriptionAction.AwaitNext;

      case GetEventSubscriptionStatus.Missing:
        if (count >= 4) { return GetEventSubscriptionAction.Finish; }
        if (count >= 3) { return GetEventSubscriptionAction.Redirect; }
        // show error === show suspended info in this case
        return GetEventSubscriptionAction.ShowError;

      case GetEventSubscriptionStatus.Error: {
        // do nothing on connection error
        return GetEventSubscriptionAction.AwaitNext;
      }

      case GetEventSubscriptionStatus.Unsubscribe:
        return GetEventSubscriptionAction.Unsubscribe;

      case GetEventSubscriptionStatus.None:
      default:
        return GetEventSubscriptionAction.AwaitNext;
    }
  }

  async function onStatus(status: GetEventSubscriptionStatus, count: number): Promise<GetEventSubscriptionAction> {
    const action = getAction(status, count);
    await doAction(action);
    return action;
  }

  return {
    id,
    onStatus,
  };
}
