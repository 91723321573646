import type {
  ComputedRef,
  Ref,
} from 'vue';
import {
  watch,
  computed,
  nextTick,
  ref,
} from 'vue';
import { createPopper as createPopperHelper } from '@popperjs/core';

import { assert } from '@leon-hub/guards';

import type {
  PopperInstance,
  PopperModifier,
  PopperOptions,
  VPopperEmits,
} from 'web/src/components/Popper/VPopper/types';
import type { PopperPosition } from 'web/src/components/Popper/VPopper/enums';
import { PopperApplyTarget } from 'web/src/components/Popper/VPopper/enums';
import { isElementContains } from 'web/src/utils/html';

export interface UseVPopperProps {
  modifiers: Ref<PopperModifier[]>;
  position: Ref<Optional<PopperPosition>>;
  applyTarget: Ref<Optional<PopperApplyTarget>>;
  hasOffset: Ref<boolean>;
  isFullWidth: Ref<boolean>;
  isFullHeight: Ref<boolean>;
}

export interface VPopperComposable {
  popper: Ref<HTMLElement | undefined>;
  reference: Ref<HTMLElement | undefined>;
  content: Ref<HTMLElement | undefined>;
  showContent: Ref<boolean>;
  isBodyContentAvailable: Ref<boolean>;
  createPopper(): Promise<void>;
  destroyPopper(): void;
  toggle(): void;
  hide(): void;
  show(): void;
  focusReferenceElm(): void;
}

export function useVPopper(
  props: UseVPopperProps,
  emit: VPopperEmits,
): VPopperComposable {
  const {
    modifiers,
    position,
    applyTarget,
    hasOffset,
    isFullWidth,
  } = props;

  const popper = ref<HTMLElement>();
  const reference = ref<HTMLElement>();
  const content = ref<HTMLElement>();
  const showContent = ref(false);
  const isBodyContentAvailable = ref(false);

  let popperInstance: PopperInstance | null = null;
  const sameWidth: PopperModifier = {
    name: 'sameWidth',
    enabled: true,
    phase: 'beforeWrite',
    requires: ['computeStyles'],
    fn: ({ state }) => {
      const result = { ...state };
      result.styles.popper.width = `${state.rects.reference.width}px`;
      return result;
    },
  };

  const isAppendToBody = computed(() => applyTarget.value === PopperApplyTarget.Body);
  const popperOptions: ComputedRef<PopperOptions> = computed(() => ({
    placement: position.value,
    modifiers: [
      ...modifiers.value,
      ...(isFullWidth.value && isAppendToBody.value ? [sameWidth] : []),
      ...(hasOffset.value ? [{
        name: 'offset',
        options: {
          offset: [0, 4],
        },
      }] : []),
    ],
  }));

  async function createPopper(): Promise<void> {
    document.addEventListener('click', handleDocumentClick);

    isBodyContentAvailable.value = true;

    await nextTick();

    if (reference.value && content.value) {
      popperInstance = createPopperHelper(
        reference.value,
        content.value,
        popperOptions.value,
      );

      // LEONWEB-11692 force redraw popper after components ready to fix position
      await nextTick();
      popperInstance?.forceUpdate();
    }
  }

  function destroyPopper(): void {
    document.removeEventListener('click', handleDocumentClick);

    popperInstance?.destroy();
    popperInstance = null;

    isBodyContentAvailable.value = false;
  }

  function toggle(): void {
    showContent.value = !showContent.value;
  }

  function hide(): void {
    showContent.value = false;
  }

  function show(): void {
    showContent.value = true;
  }

  function focusReferenceElm(): void {
    if (reference.value) {
      const element = reference.value.firstElementChild;

      assert(element instanceof HTMLElement, 'element should be HTMLElement');

      element.focus();
    }
  }

  function handleDocumentClick(event: MouseEvent): void {
    const { target } = event;

    assert(target instanceof Element, 'target should be Element');

    if (isElementContains(target, popper.value) || isElementContains(target, content.value)) {
      return;
    }

    showContent.value = false;
  }

  function emitShow(): void {
    emit('show');
  }

  function emitHide(): void {
    emit('hide');
  }

  watch(() => popperOptions.value, (value: PopperOptions) => {
    popperInstance?.setOptions(value);
  }, {
    deep: true,
  });

  watch(showContent, (value: boolean) => {
    if (value) {
      emitShow();
      createPopper();
    } else {
      emitHide();
      destroyPopper();
    }
  });

  return {
    popper,
    reference,
    content,
    showContent,
    isBodyContentAvailable,
    createPopper,
    destroyPopper,
    toggle,
    show,
    hide,
    focusReferenceElm,
  };
}
