import { PostMessageEvent } from '@leon-hub/postmessage-bus';

import type { ReCaptchaFramedWrapperState } from 'web/src/modules/captcha/components/VReCaptcha/enums';
import type { ReCaptchaResponse } from 'web/src/modules/captcha/store/types';

export default class ReCaptchaPostMessageEvent extends PostMessageEvent {
  static readonly verify = new PostMessageEvent<ReCaptchaResponse>('captcha-verify');

  static readonly expired = new PostMessageEvent('captcha-expired');

  static readonly error = new PostMessageEvent('captcha-error');

  static readonly reset = new PostMessageEvent('captcha-reset');

  static readonly execute = new PostMessageEvent('captcha-execute');

  static readonly wrapperState = new PostMessageEvent<{ state: ReCaptchaFramedWrapperState }>('captcha-wrapper-state');
}
