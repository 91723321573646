import { assert, isNumber } from '@leon-hub/guards';

export default function handleNumberPaste(
  event: ClipboardEvent,
  currentValue = '',
  fractionDigits = 0,
  symbolLimitation?: number,
): string {
  const { target, clipboardData } = event;
  assert(target instanceof HTMLInputElement);
  const { selectionStart, selectionEnd } = target;
  assert(isNumber(selectionStart));
  assert(isNumber(selectionEnd));

  const valueBefore = currentValue.slice(0, selectionStart);
  const valueAfter = currentValue.slice(selectionEnd);

  const data = clipboardData?.getData('text') || '';

  const safeSymbolsFromBuffer = data
    .replaceAll(',', '.')
    .replaceAll(/[^\d.]/gm, '');

  let combined = [valueBefore, safeSymbolsFromBuffer, valueAfter]
    .join('').trim()
    .replace(/^[0.]+/, (match) => (match.endsWith('.') ? '.' : ''));

  if (fractionDigits === 0) {
    return combined.replaceAll('.', '').slice(0, symbolLimitation);
  }

  if (combined.startsWith('.')) {
    combined = `0${combined}`;
  }

  const [whole, ...fraction] = combined.split('.');
  const formattedFraction = fraction.join('').replaceAll('.', '').slice(0, fractionDigits);

  const head = !whole && formattedFraction ? '0' : whole;
  const tail = formattedFraction ? `.${formattedFraction}` : '';
  return [head, tail].join('').slice(0, symbolLimitation);
}
