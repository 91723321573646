import type { Ref } from 'vue';
import { ref } from 'vue';

import { normalizeError } from '@leon-hub/errors';
import { logger } from '@leon-hub/logging';

export interface ShareModalComposable {
  isShareModal: Ref<boolean>;
  showShareModal: ({ title, url }: { title: string; url: string }) => Promise<void>;
  closeShareModal: () => void;
}

export default function useShareModal(): ShareModalComposable {
  const isShareModal = ref(false);

  const showShareModal = async ({ title, url }: { title: string; url: string }) => {
    if (!process.env.VUE_APP_LAYOUT_DESKTOP && navigator.share) {
      try {
        await navigator.share({
          title,
          url,
        });
      } catch (rawError) {
        const error = normalizeError(rawError);

        logger.error(error);
      }
    } else {
      isShareModal.value = true;
    }
  };

  function closeShareModal(): void {
    isShareModal.value = false;
  }

  return {
    isShareModal,
    showShareModal,
    closeShareModal,
  };
}
