import { defineStore } from 'pinia';
import type { Ref } from 'vue';
import {
  ref,
} from 'vue';

import type { EgsGame } from 'web/src/modules/egs/types';
import { useEgsApi } from 'web/src/modules/egs/composables';

const useEgsGamesStore = defineStore('egs-games', () => {
  const api = useEgsApi();
  const games: Record<string, Ref<Writeable<EgsGame> | undefined>> = {};

  function createKey(
    groupUrl: string,
    gameUrl: string,
  ): string {
    return `${groupUrl}-${gameUrl}`;
  }

  function updateGames(items: ReadonlyArray<EgsGame>): void {
    for (const game of items) {
      const key = createKey(game.group.url, game.url);

      if (!games[key]) {
        games[key] = ref(game);
      } else {
        games[key].value = game;
      }
    }
  }

  function getGameByUrl(
    groupUrl: string,
    gameUrl: string,
  ): Ref<EgsGame | undefined> {
    const key = createKey(groupUrl, gameUrl);
    if (!games[key]) {
      games[key] = ref(undefined);
    }

    return games[key];
  }

  function getGameById(
    id: string,
  ): EgsGame | undefined {
    return Object.values(games).find((game) => game.value?.id === id)?.value;
  }

  function setFavorite(ids: string[], value: boolean): void {
    for (const id of ids) {
      const game = getGameById(id);
      if (game) {
        game.isFavorite = value;
      }
    }
  }

  async function loadGameById(id: string): Promise<EgsGame | undefined> {
    const game = await api.getGameById(id);
    if (game) {
      updateGames([game]);
    }

    return game;
  }

  return {
    updateGames,
    getGameByUrl,
    setFavorite,
    getGameById,
    loadGameById,
  };
});

export default useEgsGamesStore;
