import type { ComputedRef, Slots } from 'vue';
import { computed, ref } from 'vue';

import { IconName, IconSize } from '@leon-hub/icons';
import { Tag } from '@leon-hub/tags';

import type { VIconProps } from '@components/v-icon';

import type { VAccordionSharedState } from 'web/src/components/Accordion/types';
import type { TwoStateIndicatorConfig } from 'web/src/components/_shared';

import type { VAccordionItemProps } from '../types';
import type { VAccordionItemComposable } from './types';

// eslint-disable-next-line max-len,sonarjs/cognitive-complexity
export default function useVAccordionItem(props: VAccordionItemProps, slots: Slots, accordionSharedState: VAccordionSharedState): VAccordionItemComposable {
  const isIndependentlyOpen = ref(false);

  const noIconProperties: VIconProps = {
    size: IconSize.SIZE_16,
  };

  const isOpen: ComputedRef<boolean> = computed(() => {
    if (accordionSharedState.controlled) {
      return !!props.open;
    }
    if (accordionSharedState.independentItems) {
      return isIndependentlyOpen.value;
    }
    return accordionSharedState.openItem === props.id;
  });

  const computedIconProperties: ComputedRef<VIconProps | undefined> = computed(() => {
    if (props.icon) {
      return {
        name: props.icon,
        size: IconSize.SIZE_16,
      };
    }
    return undefined;
  });

  // Condition helpers
  const hasSuffixSlot: ComputedRef<boolean> = computed(() => !!slots.suffix);
  const hasPrefixSlot: ComputedRef<boolean> = computed(() => !!slots.prefix);
  const hasHeaderSlot: ComputedRef<boolean> = computed(() => !!slots.header);
  const hasComputedIcon: ComputedRef<boolean> = computed(() => !hasPrefixSlot.value && !!computedIconProperties.value);
  // eslint-disable-next-line max-len
  const hasEmptyIcon: ComputedRef<boolean> = computed(() => !hasPrefixSlot.value && !computedIconProperties.value && accordionSharedState.hasIcon);
  // eslint-disable-next-line max-len
  const hasPrefix: ComputedRef<boolean> = computed(() => hasPrefixSlot.value || hasComputedIcon.value || hasEmptyIcon.value);

  const rootTag: ComputedRef<Tag> = computed(() => {
    switch (accordionSharedState.parentTag) {
      case Tag.OL:
      case Tag.UL:
        return Tag.LI;
      case Tag.SECTION:
        return Tag.ARTICLE;
      case Tag.DL:
        return Tag.DIV;
      default:
        return props.tag ?? Tag.DIV;
    }
  });

  const headerTag: ComputedRef<Tag> = computed(() => (accordionSharedState.parentTag === Tag.DL ? Tag.DT : Tag.DIV));

  const contentTag: ComputedRef<Tag> = computed(() => (accordionSharedState.parentTag === Tag.DL ? Tag.DD : Tag.DIV));

  const iconOpen = process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED ? IconName.SLOTT_PLUS_SMALL : IconName.EXPAND_DOWN;
  const iconClose = process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED ? IconName.SLOTT_MINUS_SMALL : IconName.EXPAND_UP;
  const iconSize = process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED ? IconSize.SIZE_20 : IconSize.SIZE_16;

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const indicatorConfig: ComputedRef<TwoStateIndicatorConfig> = computed(() => {
    const indicatorClosed: VIconProps = {
      name: iconOpen,
      size: iconSize,
    };
    const indicatorOpen: VIconProps = {
      name: iconClose,
      size: iconSize,
    };
    const defaults: TwoStateIndicatorConfig = [
      indicatorClosed,
      indicatorOpen,
    ];
    if (Array.isArray(props.indicator) && props.indicator.length === 2) {
      return props.indicator;
    }
    if (!Array.isArray(props.indicator) && typeof props.indicator === 'object') {
      return [{
        ...props.indicator,
        name: iconOpen,
      }, {
        ...props.indicator,
        name: iconClose,
      }];
    }
    if (Array.isArray(accordionSharedState.indicators) && accordionSharedState.indicators.length === 2) {
      return accordionSharedState.indicators;
    }
    if (!Array.isArray(accordionSharedState.indicators)
      && typeof accordionSharedState.indicators === 'object') {
      return [{
        ...accordionSharedState.indicators,
        name: iconOpen,
      }, {
        ...accordionSharedState.indicators,
        name: iconClose,
      }];
    }
    return defaults;
  });

  const computedIndicatorProperties: ComputedRef<VIconProps | undefined> = computed(() => {
    if (!props.indicator || !accordionSharedState.indicators) {
      return undefined;
    }
    return isOpen.value
      ? indicatorConfig.value[1]
      : indicatorConfig.value[0];
  });

  function independentlyOpenToggle(): void {
    isIndependentlyOpen.value = !isIndependentlyOpen.value;
  }

  return {
    hasHeaderSlot,
    indicatorConfig,
    isIndependentlyOpen,
    noIconProperties,
    isOpen,
    hasSuffixSlot,
    hasPrefixSlot,
    hasComputedIcon,
    hasEmptyIcon,
    hasPrefix,
    rootTag,
    headerTag,
    contentTag,
    computedIconProperties,
    computedIndicatorProperties,
    independentlyOpenToggle,
  };
}
