<script lang="ts" setup>
import { defineAsyncComponent } from 'vue';

import { IconName, SlottIconName } from '@leon-hub/icons';
import { AnchorTarget, ButtonKind } from '@leon-hub/module-buttons';
import { Tag } from '@leon-hub/tags';

import VEmpty from 'web/src/components/Empty/components/VEmpty';
import VCmsContent from 'web/src/modules/cms/components/VCmsContent/VCmsContent.vue';
import type { RulesContentDefaultProps } from 'web/src/modules/rules/types';
import SButton from 'web/src/components/Button/SButton/SButton.vue';
import { useCmsContentToPrint, useRuleDownload } from 'web/src/modules/rules/composables';
import { ButtonHeight } from 'web/src/components/Button/VButton/enums';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { useI18n } from 'web/src/modules/i18n/composables';

const props = defineProps<RulesContentDefaultProps>();

const { fileHref } = useRuleDownload();
const { getBtrKeyToPrint } = useCmsContentToPrint();
const { $translate } = useI18n();

function onPrint(): void {
  if (props.termsTitle && props.cmsKey) {
    const captionValue = $translate(props.termsTitle).value;
    getBtrKeyToPrint(captionValue, props.cmsKey);
  }
}

const DesktopTitle = '1'
  ? defineAsyncComponent(() => import('web/src/modules/core/components/DesktopTitle/DesktopTitle.vue'))
  : VEmpty;
</script>

<template>
  <div v-auto-id="'RulesContentDefault'"
    :class="$style['rules-content-default']"
  >
    <div
      :class="$style['rules-content-default__header']"
    >
      <DesktopTitle
        v-if="'1'"
        :title="title"
      />
      <SButton
        v-if="null && isDownloadButtonEnabled && !null"
        :tag="Tag.A"
        :label="$t('WEB2_DOWNLOAD')"
        kind="tertiary-secondary"
        size="small"
        :icon-name="SlottIconName.SLOTT_DOCUMENT"
        :href="fileHref"
        :target="AnchorTarget.BLANK"
      />
      <VButton
        v-else-if="!null && !null"
        :is-uppercase="false"
        :kind="ButtonKind.BASE"
        :height="ButtonHeight.MEDIUM"
        rounded
        :icon-name="IconName.DOCUMENT"
        :label="$t('WEB2_PRINT')"
        @click="onPrint()"
      />
    </div>
    <VCmsContent :cms-key="cmsKey" />
  </div>
</template>

<style lang="scss" module>
@include for-layout using($isDesktop) {
  .rules-content-default {
    user-select: auto;

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @if not $isDesktop {
        margin: $rulesContentDefaultMobileMargin;
      }
    }
  }
}
</style>
