import type {
  SportlineEvent,
  SportlineEventPeriod,
} from 'web/src/modules/sportline/types';
import {
  SportlineType,
  SportEventMatchPhase,
  SportlineEventPeriodType,
} from 'web/src/modules/sportline/enums';
import { getSafePercentage } from 'web/src/modules/core/utils/math';

export interface SportlineEventPeriodInplay extends SportlineEventPeriod {
  percentage: number;
}

export function getMatchPercentageByTimes({ sportlineEvent }: {
  sportlineEvent: SportlineEvent;
}): SportlineEventPeriodInplay[] {
  if (sportlineEvent.type !== SportlineType.Live) { return []; }

  const periods = sportlineEvent.periods ?? [];

  if (!periods.length) { return []; }

  const wholeMatchTime = periods.reduce((result, period) => (
    period.type === SportlineEventPeriodType.Main
      ? (result + period.duration)
      : result
  ), 0);
  let matchTime = sportlineEvent.matchPhase === SportEventMatchPhase.BreakInPlay
    ? (wholeMatchTime / 2)
    : (sportlineEvent.matchProgress.mainTime?.minutes ?? 0);

  const periodsInplay: SportlineEventPeriodInplay[] = [];

  for (const period of periods) {
    if (matchTime >= period.duration) {
      matchTime -= period.duration;
      periodsInplay.push({ ...period, percentage: 100 });
      continue;
    }

    const periodTimeLeft = Math.max(matchTime, 0);
    matchTime -= periodTimeLeft;

    const percentage = Math.round(getSafePercentage(periodTimeLeft, period.duration));
    periodsInplay.push({ ...period, percentage });
  }

  return periodsInplay;
}
