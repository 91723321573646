export const getLargestKey = (storage: Storage): string | undefined => {
  let largestKey: string | undefined;
  let largestLength = 0;
  for (const key of Object.keys(storage)) {
    const keyLength = storage.getItem(key)?.length || 0;
    if (keyLength > largestLength) {
      largestLength = keyLength;
      largestKey = key;
    }
  }

  return largestKey;
};
