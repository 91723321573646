import { IconName, IconSize } from '@leon-hub/icons';

import type { CasinoLobbyNavigationEmits } from 'web/src/modules/casino/submodules/lobby/components/CasinoLobbyNavigation/types';
import type { VButtonProps } from 'web/src/components/Button/VButton/types';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';

interface CasinoLobbyNavigationComposable {
  searchButtonProperties: Readonly<VButtonProps>;
  emitClickGroups: () => void;
  emitClickSearch: () => void;
}

export default function useCasinoLobbyNavigation(
  emits: CasinoLobbyNavigationEmits,
): CasinoLobbyNavigationComposable {
  const searchButtonProperties: Readonly<VButtonProps> = {
    kind: ButtonKind.BASE,
    iconName: IconName.SEARCH,
    iconSize: IconSize.SIZE_24,
  };

  function emitClickGroups(): void {
    emits('click-groups');
  }

  function emitClickSearch(): void {
    emits('click-search');
  }

  return {
    searchButtonProperties,
    emitClickGroups,
    emitClickSearch,
  };
}
