<script lang="ts" setup>
import { onBeforeUnmount, onMounted } from 'vue';

import { useVSmsNumberCheck } from 'web/src/components/SmsNumberCheck/VSmsNumberCheck/composables';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import type { FormOutput, FormUiSchema } from 'web/src/components/Form';
import type { FormExternalErrors, FormSchema } from 'web/src/components/Form/types';
import VForm from 'web/src/components/Form/components/VForm/VForm.vue';
import SButton from 'web/src/components/Button/SButton/SButton.vue';
import VTimer from 'web/src/components/Timer/VTimer/VTimer.vue';

interface VSmsNumberCheckProps {
  phone: string;
  uiSchema: FormUiSchema;
  schema: FormSchema;
  isPending: boolean;
  customErrors?: FormExternalErrors;
  showButtonTime?: number | null;
  hintTimeout?: number;
  isNewTel?: boolean;
  isPin?: boolean;
  changePhoneLabel?: string;
  isTimer?: boolean;
  timeLeft?: number;
}

interface VSmsNumberCheckEmits {
  (e: 'show-hint-with-email-redirect', state: boolean): void;
  (e: 'set-retry-sms-code-time', time?: number): void;
  (e: 'retry-button-click', data: Optional<FormOutput>): void;
  (e: 'change-phone'): void;
  (e: 'submit', data: FormOutput): void;
  (e: 'input', data: FormOutput): void;
  (e: 'blur', data: FormOutput): void;
}

const props = defineProps<VSmsNumberCheckProps>();
const emits = defineEmits<VSmsNumberCheckEmits>();

const {
  form,
  formKey,
  timer,
  showButton,
  timerText,
  buttonProperties,
  pageDescription,
  onSubmit,
  emitInput,
  onClick,
  onComponentCreated,
  onComponentMounted,
  onComponentBeforeUnmount,
  onBlur,
} = useVSmsNumberCheck(props, emits);

onComponentCreated();

onMounted(onComponentMounted);
onBeforeUnmount(onComponentBeforeUnmount);
</script>

<template>
  <div v-auto-id="'VSmsNumberCheck'">
    <div :class="$style['sms__container']">
      <p
        v-data-test="{ el: 'sms-number-check-container-text' }"
        :class="$style['sms__container--text']"
      >
        <span>{{ pageDescription }}&nbsp;</span>
        <span
          v-if="!isPin"
          :class="$style['sms__container--important-text']"
        >
          {{ phone }}.</span>

        <slot name="phone" />
        <span v-if="!!changePhoneLabel">
          (<span
            :class="$style['sms__container--change']"
            @click="$emit('change-phone')"
          >{{ changePhoneLabel }}</span>)
        </span>
      </p>
    </div>

    <VForm
      ref="form"
      :key="formKey"
      :schema="schema"
      :ui-schema="uiSchema"
      :custom-errors="customErrors"
      :is-pending="isPending"
      @submit="onSubmit"
      @input="emitInput"
      @blur="onBlur"
    />
    <div
      v-if="!showButton"
      :class="$style['sms__sent-code']"
    >
      <span :class="$style['sms__sent-again']">
        {{ timerText }}
      </span>
      <span :class="$style['sms__countdown']">
        {{ timer }}
      </span>
    </div>
    <VTimer
      v-if="!showButtonTime && timeLeft"
      :class="$style['sms__sent-code']"
      :text="$t('WEB2_RESEND_SMS_TIME')"
      :seconds="timeLeft"
    />
    <div
      v-else-if="showButton"
      :class="$style['sms__resend']"
    >
      <SButton
        v-if="null"
        v-data-test="{ el: 'sms-number-check-resend-button' }"
        kind="tertiary-secondary"
        size="large"
        :label="$t('WEB2_RESEND_CODE')"
        full-width
        @click="onClick"
      />
      <VButton
        v-else
        v-data-test="{ el: 'sms-number-check-resend-button' }"
        v-bind="buttonProperties"
        @click="onClick"
      />
    </div>

    <slot name="content" />
  </div>
</template>

<style lang="scss" module>
.sms {
  &__resend {
    margin-top: 10px; // TODO registration - 20px by design
    text-align: center;
  }

  &__countdown {
    @include smsNumberCountdownLabelFont;

    display: block;
    color: var(--TextDefault);
    color: $smsNumberCountdownLabelColor;
  }

  &__sent-code {
    margin-top: 20px;
    text-align: center;
  }

  &__sent-again {
    @include smsNumberCheckLabelFont;

    color: $smsCheckAgainLabelColor;
  }

  &__container {
    @include regular\14\24\025;

    color: var(--TextPrimary);
    text-align: center;

    &--text {
      @include smsNumberCheckLabelFont;

      margin: 0 0 16px;
      color: $smsNumberCheckLabelColor;
    }

    &--change {
      @include smsNumberChangeLabelFont;

      color: var(--ColorContent2);
      color: $smsNumberChangeLabelColor;
      cursor: pointer;
    }

    &--link {
      text-decoration: underline;
    }

    &--important-text {
      color: var(--TextDefault);
      white-space: nowrap;
    }
  }
}
</style>
