<script lang="ts" setup>
import { IconName } from '@leon-hub/icons';

import { VScrollbar } from '@components/v-scrollbar';

import VListItemAccordion from 'web/src/components/List/VListItemAccordion/VListItemAccordion.vue';
import Balance from 'web/src/modules/profile/components/BalanceUser/BalanceUser.vue';
import VList from 'web/src/components/List/VList/VList.vue';
import VOverlay from 'web/src/components/Modal/VOverlay/VOverlay.vue';
import ModalPortal from 'web/src/components/Modal/ModalPortal/ModalPortal.vue';
import VCmsContent from 'web/src/modules/cms/components/VCmsContent/VCmsContent.vue';
import ModalWindowHeader from 'web/src/components/Modal/ModalWindowHeader/ModalWindowHeader.vue';
import RequestedAmount from 'web/src/modules/profile/components/RequestedAmount/RequestedAmount.vue';
import { ModalSelector } from 'web/src/modules/core/enums';
import ModalTransition from 'web/src/components/Modal/ModalPortal/enums/ModalTransition';

import { useBalanceModal } from './composables';
import type { BalanceModalEmits, BalanceModalProps } from './types';

defineProps<BalanceModalProps>();
const emit = defineEmits<BalanceModalEmits>();

const {
  listItemAccordionTitle,
  balanceMode,
  onClick,
  emitClose,
} = useBalanceModal(emit);
</script>

<template>
  <ModalPortal v-auto-id="'BalanceModal'"
    :selector="ModalSelector.BODY"
    :transition="ModalTransition.SLIDE_UP"
  >
    <div :class="$style['balance-modal']">
      <VOverlay />
      <div :class="$style['balance-modal__wrapper']">
        <ModalWindowHeader
          :class="$style['balance-modal__header']"
          :title="$t('WEB2_MY_BALANCE')"
          :suffix-icon-name="IconName.CROSS"
          :type="null ? 'secondary' : 'default'"
          is-close-button-outside
          @suffix-click="emitClose"
        />
        <div :class="$style['balance-modal__scrollbar']">
          <VScrollbar>
            <div :class="$style['balance-modal__inner']">
              <Balance
                :class="$style['balance-modal__balances']"
                :mode="balanceMode"
                :is-in-profile="false"
                @close="emitClose"
              />
              <RequestedAmount
                :class="$style['balance-modal__withdrawal-request']"
                @click="emitClose"
                @close="emitClose"
              />
              <VList independent-items>
                <VListItemAccordion
                  v-if="'1'"
                  id="1"
                  :open="isOpen"
                  :title="listItemAccordionTitle"
                >
                  <template #content>
                    <VCmsContent
                      cms-key="WEB2_CBC_BALANCE_DES"
                      :class="$style['cms-content']"
                      silent
                      loader
                      @click-link="onClick"
                    />
                  </template>
                </VListItemAccordion>
                <VListItemAccordion
                  id="2"
                  :title="$t('WEB2_WHAT_IS_MAIN_BALANCE')"
                >
                  <template #content>
                    <div :class="$style['content']">
                      {{ $t('WEB2_MAIN_BALANCE_DES') }}
                    </div>
                  </template>
                </VListItemAccordion>
              </VList>
            </div>
          </VScrollbar>
        </div>
      </div>
    </div>
  </ModalPortal>
</template>

<style lang="scss" module>
$marginTop: 58px;
$marginBottom: 16px;

.content {
  @include regular\14\20\025;

  margin-top: -8px;
  margin-right: 4px;
  margin-left: 4px;
}

.cms-content {
  @include regular\14\20\025;

  padding: 0 4px;

  @include is-app-layout-desktop {
    padding: 0 4px;
  }
}

@include for-layout using($isDesktop) {
  .balance-modal {
    @include z-index(modal);
    @include modal($isDesktop);

    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    &__wrapper {
      @include z-index(base);
      @include wrapper($isDesktop);

      position: relative;
      display: flex;
      flex-direction: column;
      max-height: calc(100vh - #{$marginTop} - #{$marginBottom});
      border-radius: $balanceModalBorderRadius;
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
      will-change: transform;
    }

    &__header {
      border-radius: $balanceModalBorderRadius $balanceModalBorderRadius 0 0;
    }

    &__inner {
      padding: if($isDesktop, $balanceModalPaddingDesktop, $balanceModalPadding);
    }

    &__scrollbar {
      display: flex;
      flex: 1;
      flex-direction: column;
      overflow: auto;
      background-color: $balanceModalBgColor;
      border-radius: 0 0 $balanceModalBorderRadius $balanceModalBorderRadius;
    }

    &__balances {
      margin-bottom: 8px;
    }

    &__withdrawal-request {
      margin-bottom: 16px;
    }
  }
}
</style>
