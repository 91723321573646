/**
 * @see https://github.com/olahol/scrollparent.js
 * @see https://github.com/Akryum/vue-virtual-scroller/blob/next/packages/vue-virtual-scroller/src/scrollparent.js
 * @see node_modules/vue-datepicker-next/index.es.js
 */

function getStyle(value: HTMLElement, prop: string): string {
  return getComputedStyle(value, null).getPropertyValue(prop);
}

function isScrollable(node: HTMLElement): boolean {
  const regex = /(auto|scroll)/;
  return regex.test(getStyle(node, 'overflow') + getStyle(node, 'overflow-y') + getStyle(node, 'overflow-x'));
}

export function getScrollParent(node: Maybe<HTMLElement>, until = document.body): Maybe<HTMLElement> {
  if (!node || node === until) {
    return null;
  }

  return isScrollable(node) ? node : getScrollParent(node.parentElement, until);
}

export function getScrollTarget(node: Maybe<HTMLElement>): Maybe<Element> {
  return getScrollParent(node, document.body)
    ?? document.scrollingElement
    ?? document.documentElement;
}

export function getListenerTarget(node: Maybe<HTMLElement>): Maybe<Element> {
  let target = getScrollTarget(node);

  // Fix global scroll target for Chrome and Safari
  if (document && (target === document.documentElement || target === document.body)) {
    target = window as unknown as Element;
  }

  return target;
}
