import type { FormSchema } from '../../../../types';
import { isFormTypeObject } from './formSchemaUtils';

export default function getObjectTypeFields(schema: FormSchema): string[] {
  const result: string[] = [];
  const properties = schema.properties ?? {};
  const propertyNames = Object.keys(properties);
  for (const name of propertyNames) {
    if (isFormTypeObject(properties[name])) {
      result.push(name);
    }
  }
  return result;
}
