<script lang="ts" setup>
import StatisticLeagueStandingBlock
  from 'web/src/modules/sportline/submodules/core-statistic/components/StatisticLeagueStandingBlock.vue';
import StatisticDisclaimer from 'web/src/modules/sportline/submodules/core-statistic/components/StatisticDisclaimer.vue';

import H2hMatchStatisticBlock from '../components/statistic/H2hMatchStatisticBlock.vue';
import LastGamesResultsMatchStatisticBlock from '../components/statistic/LastGamesResultsMatchStatisticBlock.vue';
import useSportEventStatistic from './useSportEventStatistic';

const {
  leagueStandings,
  teams,
  h2hMatchStatistic,
  joinMatches,
  hostMatches,
  guestMatches,
  statisticSportFamily,
} = useSportEventStatistic();
</script>

<template>
  <div v-auto-id="'SportEventStatistic'" :class="$style['sport-event-statistic']">
    <H2hMatchStatisticBlock
      v-if="teams && (joinMatches.length > 0 || h2hMatchStatistic)"
      :teams="teams"
      :join-matches="joinMatches"
      :h2h-match-statistic="h2hMatchStatistic"
      :sport-family="statisticSportFamily"
    />

    <LastGamesResultsMatchStatisticBlock
      v-if="teams && (hostMatches.length > 0 || guestMatches.length > 0)"
      :teams="teams"
      :host-matches="hostMatches"
      :guest-matches="guestMatches"
      :sport-family="statisticSportFamily"
    />

    <StatisticLeagueStandingBlock
      v-if="teams && leagueStandings.length > 0"
      :class="$style['sport-event-statistic__league-standings']"
      :teams="teams"
      :league-standings="leagueStandings"
    />

    <StatisticDisclaimer />
  </div>
</template>

<style lang="scss" module>
.sport-event-statistic {
  &__league-standings {
    margin: 8px 0 0;
  }
}
</style>
