import type {
  ComputedRef,
  Ref,
} from 'vue';
import {
  computed,
  nextTick,
  onBeforeUnmount,
  onMounted,
  ref,
  toRef,
  watch,
} from 'vue';
import { useRouter } from 'vue-router';

import isGeneralApiError from '@leon-hub/api/src/client/errors/isGeneralApiError';
import { StartCodeType } from '@leon-hub/api-sdk';
import { CasinoRouteName } from '@leon-hub/routing-config-names/src/CasinoRouteName';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika/src/constants/index';
import { BusEvent, useEventsBus } from '@leon-hub/event-bus';

import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { useCasinoBetgamesStore } from 'web/src/modules/casino/submodules/betgames/store';
import { useAppsflyer } from 'web/src/modules/cordova/composables';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
import useDialogs from 'web/src/modules/dialogs/composables/useDialogs';
import { useErrorsConverter } from 'web/src/modules/errors/composables';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import { AnalyticsModalType, DialogAction, PresetName } from 'web/src/modules/dialogs/enums';

interface CasinoBetgamesRoutePageComposable {
  wrapper: Ref<HTMLElement | undefined>;
  errorMessage: Ref<string>;
  isFrameLoaded: Ref<boolean>;
  isHtmlInjection: ComputedRef<boolean>;
  gameUrl: ComputedRef<string | undefined>;
  setFrameLoaded: () => void;
  onClose: () => void;
  onScrollTop: (scrollTop: number) => void;
}

export default function useCasinoBetgamesRoutePage(): CasinoBetgamesRoutePageComposable {
  let modalId = '';

  const wrapper = ref<HTMLElement>();
  const isFrameLoaded = ref(false);
  const errorMessage = ref('');
  const casinoBetgamesStore = useCasinoBetgamesStore();
  const router = useRouter();
  const analytics = useAnalytics();
  const bus = useEventsBus();
  const { convertToBaseError } = useErrorsConverter();

  const { isLoggedIn } = useIsLoggedIn();
  const { closeDialog, showDialog } = useDialogs();

  const startGameData = toRef(casinoBetgamesStore, 'startGameData');

  const gameUrl = computed(() => startGameData.value?.gameUrl);
  const isHtmlInjection = computed(() => startGameData.value?.startCode === StartCodeType.HTML_INJECTION);

  if (process.env.VUE_APP_PLATFORM_CORDOVA) {
    onMounted(() => {
      const { trackOpenGame } = useAppsflyer();

      void trackOpenGame({
        login: useCustomerDataStore().login,
        game: 'betgames',
        provider: '',
      });
    });
  }

  onBeforeUnmount(() => {
    casinoBetgamesStore.clearStartGameData();

    if (modalId) {
      closeDialog(modalId);
      modalId = '';
    }
  });

  watch(isLoggedIn, () => {
    void fetchData();
  }, { immediate: true });

  function setFrameLoaded(): void {
    isFrameLoaded.value = true;
  }

  function onScrollTop(scrollTop: number): void {
    bus.emit(BusEvent.LAYOUT_CONTENT_SET_SCROLL, {
      scrollTop: scrollTop > 0 && wrapper.value ? scrollTop + wrapper.value.offsetTop : scrollTop,
    });
  }

  function onClose(): void {
    router.back(CasinoRouteName.CASINO_LOBBY);
  }

  async function fetchData(): Promise<void> {
    isFrameLoaded.value = false;
    errorMessage.value = '';
    casinoBetgamesStore.clearStartGameData();
    await nextTick();

    try {
      await casinoBetgamesStore.startGame();
    } catch (rawError) {
      const error = convertToBaseError(rawError);
      if (isGeneralApiError(error)) {
        throw rawError;
      } else {
        showError(error.message);
      }
    }
  }

  function showError(message: string): void {
    if (process.env.VUE_APP_LAYOUT_DESKTOP) {
      analytics.push(AnalyticsEvent.MODAL_ERROR_MESSAGES, {
        [AnalyticsModalType.WARNING_EGS]: message,
      });
      errorMessage.value = message;
      return;
    }

    const { subscribe, id } = showDialog({
      presetName: PresetName.ALERT_WARNING,
      options: {
        title: '',
        confirmMessage: message,
        analyticsModalType: AnalyticsModalType.WARNING_EGS,
        dataTestId: 'bet-game-error',
      },
    });

    modalId = id;

    subscribe({
      [DialogAction.MODAL_CLOSE]: () => {
        onClose();
      },
    });
  }

  return {
    wrapper,
    errorMessage,
    isFrameLoaded,
    isHtmlInjection,
    gameUrl,
    setFrameLoaded,
    onClose,
    onScrollTop,
  };
}
