import type { Ref } from 'vue';
import { ref, computed } from 'vue';

import { useSaveToLocalStorage } from './useSaveToLocalStorage';

export interface DebugVirtualListRendererStoreProps {
  isDebugEnabled: Ref<boolean>;
}

export interface DebugVirtualListRendererStoreComposable {
  isVirtualListRendererEnabled: Ref<boolean>;
  setVirtualListRendererEnabled(value: boolean): void;
  saveVirtualListRendererEnabled(): void;
  loadVirtualListRendererEnabled(): void;
}

const LS_VIRTUAL_LIST_RENDERER_KEY = 'SPORTLINE_VIRTUAL_LIST_RENDERER';

export function useDebugVirtualListRendererStoreComposable(
  { isDebugEnabled }: DebugVirtualListRendererStoreProps,
): DebugVirtualListRendererStoreComposable {
  const { saveValueToLocalStorage, getValueFromLocalStorage } = useSaveToLocalStorage();

  const isVirtualListRendererEnabled = ref(false);

  return {
    isVirtualListRendererEnabled: computed(() => isDebugEnabled.value && isVirtualListRendererEnabled.value),
    setVirtualListRendererEnabled(value: boolean): void {
      isVirtualListRendererEnabled.value = value;
    },
    saveVirtualListRendererEnabled(): void {
      saveValueToLocalStorage(LS_VIRTUAL_LIST_RENDERER_KEY, isVirtualListRendererEnabled.value);
    },
    loadVirtualListRendererEnabled(): void {
      if (!isDebugEnabled.value) { return; }
      const rawData = getValueFromLocalStorage(LS_VIRTUAL_LIST_RENDERER_KEY);
      if (rawData) { isVirtualListRendererEnabled.value = rawData === 'true'; }
    },
  };
}
