import type { Ref } from 'vue';
import { ref } from 'vue';

import type { SportlinePageIdentifier as SportEventsPageIdentifier } from 'web/src/modules/sportline/types/navigation';

interface UseSportlinePageIdentifierComposable {
  pageIdentifier: Ref<SportEventsPageIdentifier>;
  setPageIdentifier(id: SportEventsPageIdentifier): void;
}

export function useSportlinePageIdentifier(): UseSportlinePageIdentifierComposable {
  const pageIdentifier = ref<SportEventsPageIdentifier>({ sportFamily: '' });

  function setPageIdentifier(id: SportEventsPageIdentifier): void {
    pageIdentifier.value = id;
  }

  return {
    pageIdentifier,
    setPageIdentifier,
  };
}
