import type { Ref } from 'vue';
import { computed } from 'vue';

import type { AddToCustomerLeaguesParameters } from 'web/src/modules/sportline/submodules/favorites/types';

interface UseOptimisticFavoritesLeaguesProps {
  preparedToAdd: Ref<string[]>;
  preparedToRemove: Ref<string[]>;
  pendingToFavoriteLeaguesList: Ref<AddToCustomerLeaguesParameters[]>;
  customerLeaguesIds: Ref<Maybe<string[]>>;
}

interface UseOptimisticFavoritesLeaguesComposable {
  optimisticCustomerLeaguesIds: Ref<string[]>;
}

export function useOptimisticFavoritesLeagues(
  props: UseOptimisticFavoritesLeaguesProps,
): UseOptimisticFavoritesLeaguesComposable {
  const {
    preparedToAdd,
    preparedToRemove,
    pendingToFavoriteLeaguesList,
    customerLeaguesIds,
  } = props;

  const optimisticCustomerLeaguesIds = computed<string[]>(() => {
    const pendingIds = pendingToFavoriteLeaguesList.value.map((pending) => pending.leagueId);

    return Array.from(new Set([
      ...(customerLeaguesIds.value ?? []),
      ...preparedToAdd.value,
      ...pendingIds,
    ]))
      .filter((id) => !preparedToRemove.value.includes(id));
  });

  return {
    optimisticCustomerLeaguesIds,
  };
}
