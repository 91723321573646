<script lang="ts" setup>
import { RouteName } from '@leon-hub/routing-config-names';

import { VIcon } from '@components/v-icon';
import { useTermsCheckbox, SpecialCheckboxesDataTest } from '@components/terms-special-checkboxes';

import { TextSlotPlaceholder } from 'web/src/components/TextSlotPlaceholder';
import { VCheckbox, useCheckboxEvents } from 'web/src/components/Checkbox';
import type { VCheckboxEmits } from 'web/src/components/Checkbox';

import type { TermsCheckboxProps } from '../types';

const props = withDefaults(defineProps<TermsCheckboxProps>(), {
  name: 'RegistrationTermsAcceptionCheckbox',
});
const emit = defineEmits<VCheckboxEmits>();

const {
  toggleExpand,
  isExpanded,
  checkboxProps,
  chevronProperties,
} = useTermsCheckbox(props);

const {
  onChange,
  onBlur,
  onFocus,
  onInput,
} = useCheckboxEvents(emit);
</script>

<template>
  <div v-auto-id="'RegistrationTermsAcceptionCheckbox'" :class="$style['terms-acception']">
    <div :class="$style['terms-acception__content']">
      <VCheckbox
        v-data-test="{ el: SpecialCheckboxesDataTest.AcceptTermsElement }"
        v-bind="checkboxProps"
        :class="$style['terms-acception__checkbox']"
        :input-data-test="{ el: SpecialCheckboxesDataTest.AcceptTermsCheckbox }"
        @change="onChange"
        @input="onInput"
        @focus="onFocus"
        @blur="onBlur"
      >
        <template #label>
          <span v-data-test="{ el: SpecialCheckboxesDataTest.AcceptTermsText, expanded: isExpanded }">
            <slot
              v-if="!isExpanded"
              name="short"
            >{{ $t('SIMPLIFIED_REG_AGREEDM_REG') }}</slot>
            <slot
              v-else
              name="full"
            >
              <TextSlotPlaceholder
                :text="$t('WEB2_FULL_REG_AGREEDM_INT')"
              >
                <template #agreement>
                  <router-link
                    v-data-test="{ el: SpecialCheckboxesDataTest.AcceptTermsLinkToAgreement }"
                    :to="{ name: RouteName.CMS_DATA_PROCESSING_AGREEMENT }"
                  >{{ $t('WEB2_FULL_REG_AGREEDM_AGREEMENT') }}</router-link>
                </template>
                <template #rules>
                  <router-link
                    v-data-test="{ el: SpecialCheckboxesDataTest.AcceptTermsLinkToOffer }"
                    :to="{ name: RouteName.CMS_BETTING_PARTICIPATION_AGREEMENT }"
                  >{{ $t('WEB2_FULL_REG_AGREEDM_RULES') }}</router-link>
                </template>
              </TextSlotPlaceholder>
            </slot>
          </span>
        </template>
      </VCheckbox>
    </div>
    <div
      v-if="expandable"
      v-data-test="{ el: SpecialCheckboxesDataTest.AcceptTermsExpandToggle }"
      :class="$style['terms-acception__button']"
      @click="toggleExpand"
    >
      <VIcon
        v-bind="chevronProperties"
        :class="$style['terms-acception__button-chevron']"
      />
    </div>
  </div>
</template>

<style lang="scss" module>
.terms-acception {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  &__content {
    flex: 1;
  }

  &__button {
    width: 24px;
    height: 24px;

    &-chevron {
      color: var(--TextSecondary);
      cursor: pointer;
    }
  }
}
</style>
