import { normalizeError } from '@leon-hub/errors';

import { useI18n } from 'web/src/modules/i18n/composables';
import { useErrorsConverter } from 'web/src/modules/errors/composables';
import type { UseErrorsConverterComposable } from 'web/src/modules/errors/composables/useErrorsConverter';
import { StreamError } from 'web/src/modules/sportline/submodules/streams/errors';

interface UseStreamErrorsConverterComposable extends UseErrorsConverterComposable {
  convertToStreamError(error: unknown, message?: string): StreamError;
}

export function useStreamErrorsConverter(): UseStreamErrorsConverterComposable {
  const { convertToBaseError } = useErrorsConverter();

  const { $translate } = useI18n();
  const defaultTitle = $translate('WEB2_STREAM_UNAVAILABLE_ERROR');

  function convertToStreamError(error: unknown, message?: string): StreamError {
    return new StreamError({
      defaultTitle: defaultTitle.value,
      originalError: normalizeError(error, message),
    });
  }

  return { convertToStreamError, convertToBaseError };
}
