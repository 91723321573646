import { createShieldApi } from './createShieldApi';

export const getSessionID = async (sdkUrl: string): Promise<string> => {
  const { api, stop } = await createShieldApi(sdkUrl);
  const deviceResult = await api.getDeviceResult({
    wpEndpoint: 'https://onoutput2.com',
  });
  stop();
  if (deviceResult.result) return deviceResult.result.session_id;
  // eslint-disable-next-line no-console
  throw new Error(`Unexpected api response:\n${JSON.stringify(deviceResult)}`);
};
