import { InputEventType } from 'web/src/components/Input/enums';

import type {
  NativeInputEmits,
} from '../types';

interface UseNativeInputEvents {
  emitChange: (event: InputEvent) => void;
  emitInput: (event: InputEvent) => void;
  emitFocus: (event: FocusEvent) => void;
  emitBlur: (event: FocusEvent) => void;
}

export default function useNativeInputEvents(emit: NativeInputEmits): UseNativeInputEvents {
  const emitInput = (event: InputEvent): void => {
    emit(InputEventType.INPUT, event);
  };

  const emitChange = (event: InputEvent): void => {
    emit(InputEventType.CHANGE, event);
  };

  const emitFocus = (event: FocusEvent): void => {
    emit(InputEventType.FOCUS, event);
  };

  const emitBlur = (event: FocusEvent): void => {
    emit(InputEventType.BLUR, event);
  };

  return {
    emitChange,
    emitInput,
    emitFocus,
    emitBlur,
  };
}
