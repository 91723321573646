import type { Ref } from 'vue';
import { computed } from 'vue';

import type { SportlineEvent } from 'web/src/modules/sportline/types';
import { SportlineType } from 'web/src/modules/sportline/enums';

interface UseDoShowMatchProgressProps {
  sportlineEvent: Ref<SportlineEvent>;
}

interface UseDoShowMatchProgressComposable {
  doShowTimeline: Ref<boolean>;
}

export function useDoShowMatchProgress(
  props: UseDoShowMatchProgressProps,
): UseDoShowMatchProgressComposable {
  const { sportlineEvent } = props;

  const doShowTimeline = computed(() => (
    sportlineEvent.value.type === SportlineType.Live && !!sportlineEvent.value.periods?.length
  ));

  return { doShowTimeline };
}
