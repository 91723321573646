import type { Ref } from 'vue';
import { computed, toRef } from 'vue';
import { useRouter } from 'vue-router';

import { RouteName } from '@leon-hub/routing-config-names';

import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import { SportlineType } from 'web/src/modules/sportline/enums';

import { useIsFavoriteEvent } from './useIsFavoriteEvent';
import useCustomerFavoritesService from './useCustomerFavoritesService';

interface FavoriteSportEventProps {
  sportlineEventId: Ref<Optional<Maybe<string>>>;
  sportlineEventType: Ref<Optional<Maybe<SportlineType>>>;
}

interface FavoriteSportEventComposable {
  isFavorite: Ref<boolean>;
  canFavorite: Ref<boolean>;
  emitChangeFavoriteState(): Promise<void>;
}

export function useFavoriteSportEventData(props: FavoriteSportEventProps): FavoriteSportEventComposable {
  const { sportlineEventId, sportlineEventType } = props;

  const router = useRouter();
  const { isLoggedIn } = useIsLoggedIn();
  const { toggleCustomerSportEvent } = useCustomerFavoritesService();
  const settingsStore = useSportlineSettingsStore();

  const isPrematchFavoriteEnabled = toRef(() => settingsStore.isPrematchFavoriteEnabled);

  const isFavorite = useIsFavoriteEvent(sportlineEventId);

  const canFavorite = computed<boolean>(() => (
    !!sportlineEventType.value
    && (sportlineEventType.value === SportlineType.Live || isPrematchFavoriteEnabled.value)
  ));

  async function emitChangeFavoriteState(): Promise<void> {
    if (!sportlineEventId.value) { return; }

    await toggleCustomerSportEvent({
      id: sportlineEventId.value,
      state: !isFavorite.value,
    });

    if (!isLoggedIn.value) {
      void router.push({ name: RouteName.LOGIN });
    }
  }

  return {
    isFavorite,
    canFavorite,
    emitChangeFavoriteState,
  };
}
