import type { RefuseBonusRequest, RefuseBonusResponse } from '@leon-hub/api-sdk';
import { doRefuseBonus } from '@leon-hub/api-sdk';

import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';

export default function doRefuseBonusApi(options: RefuseBonusRequest): Promise<RefuseBonusResponse> {
  const apiClient = useGraphqlClient();

  return doRefuseBonus(
    apiClient,
    (node) => node.mutations.bonuses.refuseBonus,
    { options },
  );
}
