import type { BatchedSlipInfoDocument, SlipEntryId } from '../types';
import getSlipEntryId from '../../../utils/getSlipEntryId';

export default function getSlipDataForUnsettledBets(
  { slipEntries, combiAvailable }: BatchedSlipInfoDocument,
  slipUnsettledSingles: SlipEntryId[],
): BatchedSlipInfoDocument {
  const updatedEntries = slipEntries.filter((item) => {
    const firstEntryId = getSlipEntryId(item.entries[0]);
    return slipUnsettledSingles.includes(firstEntryId);
  });
  return { slipEntries: updatedEntries, combiAvailable };
}
