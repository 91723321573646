export const enum SportlineNavigationItemType {
  Text = 'text',
  Icon = 'icon',
  SportIcon = 'sport-icon',
  Image = 'Image',
}

export const enum SportlineNavigationItemCustomStyle {
  Bundesliga = 'bundesliga',
  HighestOdds = 'highest-odds',
}
