import { toNumber } from 'vanilla-masker';

function getCounterForRemove(positionStart: number, positionEnd: number, hasData: boolean): number {
  const selectionDiff = positionEnd - positionStart;

  // if we have selection then remove that selection
  if (selectionDiff) {
    return selectionDiff;
  }

  // remove nothing, we are going only add elements
  if (hasData) {
    return 0;
  }

  // if we have no data than remove 1
  return 1;
}

function patternPositionToRawPosition(pattern: string[], position: number): number {
  const placeholderCount = pattern.reduce<number>((result, char, index) => {
    if (index >= position) {
      return result;
    }
    return char !== '9' ? result + 1 : result;
  }, 0);

  return position - placeholderCount;
}

function getMaxRawLength(pattern: string[]): number {
  return pattern.filter((char) => char === '9').length;
}

function createPatternIndexes(pattern: string[]): Record<number, number> {
  let currentIndex = 0;

  return pattern.reduce<Record<number, number>>((result, char, index) => {
    if (char !== '9') {
      return result;
    }

    // eslint-disable-next-line no-param-reassign
    result[currentIndex] = index + 1;
    currentIndex += 1;
    return result;
  }, {});
}

export default function createNewMaskedValue(input: {
  oldValue: string;
  positionStart: number;
  positionEnd: number;
  pattern: string;
  data: string | null;
}): { value: string; position: number } {
  const {
    oldValue, positionStart, positionEnd, pattern, data,
  } = input;

  const rawValue = toNumber(oldValue);
  const patternList = [...pattern];
  const newRawValue = [...rawValue];
  const hasSelection = positionStart !== positionEnd;
  const rawIndexPositionStart = patternPositionToRawPosition(patternList, positionStart);
  const rawIndexPositionEnd = hasSelection
    ? patternPositionToRawPosition(patternList, positionEnd)
    : rawIndexPositionStart;
  const counterRemove = getCounterForRemove(rawIndexPositionStart, rawIndexPositionEnd, data !== null);

  if (counterRemove) {
    newRawValue.splice(rawIndexPositionStart, counterRemove);
  }

  if (data === null) {
    return {
      position: positionStart,
      value: newRawValue.join(''),
    };
  }

  const filteredData = toNumber(data);

  // no value
  if (!filteredData) {
    return {
      position: positionStart,
      value: newRawValue.join(''),
    };
  }

  const maxRawLength = getMaxRawLength(patternList);
  const maxIndexPosition = maxRawLength - 1;
  const patternIndexes = createPatternIndexes(patternList);

  if (rawIndexPositionStart > maxIndexPosition) {
    return {
      position: patternIndexes[newRawValue.length - 1],
      value: newRawValue.join(''),
    };
  }

  newRawValue.splice(rawIndexPositionStart, 0, filteredData);

  if (newRawValue.length > maxRawLength) {
    newRawValue.splice(maxRawLength);
  }

  // rawIndexPositionStart includes position after value added (offset 1)
  // that is why when we add offset which is equal length of adding value we need to minus 1 symbol
  const positionIndex = rawIndexPositionStart + filteredData.length - 1;

  return {
    position: patternIndexes[positionIndex],
    value: newRawValue.join(''),
  };
}
