<script lang="ts" setup>
import VListItemExtended from 'web/src/components/List/VListItemExtended/VListItemExtended.vue';
import ListItemExtendedKind from 'web/src/components/List/VListItemExtended/enums/ListItemExtendedKind';
import { usePaymentsListItem } from 'web/src/modules/payments/components/PaymentsList/composables';

import type { PaymentsListItemProps, PaymentsListItemEmits } from './types';

const props = defineProps<PaymentsListItemProps>();
const emits = defineEmits<PaymentsListItemEmits>();

const {
  logoLocation,
  hintMessage,
} = usePaymentsListItem(props);
</script>

<template>
  <VListItemExtended v-auto-id="'PaymentsListItem'"
    :class="$style['payments-list-item']"
    :is-disabled="paymentSystem.disabled ?? false"
    :logo-name="paymentSystem.logoName"
    :logo-src="logoLocation"
    :title="paymentSystem.name"
    :sub-title-first="hintMessage"
    :test-key="paymentSystem.id"
    :kind="ListItemExtendedKind.LOGO"
    :hide-unavailable-message="hideUnavailableMessage"
    @click="emits('click')"
  />
</template>

<style lang="scss" module>
.payments-list-item {
  &:active,
  &:focus {
    transform: scaleX(0.99) scaleY(0.96);
  }
}
</style>
