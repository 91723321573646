import type { Ref } from 'vue';
import { ref } from 'vue';

import { useSyncSportlineWithoutCTag } from 'web/src/modules/sportline/submodules/sync-sportline';
import useBackgroundRequestsLifeCycle from 'web/src/utils/store/composables/useBackgroundRequestsLifeCycle';
import type {
  BetlineLeagueStanding,
  CoreSportlineFetchOptions,
  BetlineLeagueStandingsResponse,
} from 'web/src/modules/sportline/types/rest';
import type {
  LoadStatisticIdentifier,
} from 'web/src/modules/sportline/submodules/event-statistic/types';
import { BetlineType } from 'web/src/modules/sportline/enums/rest';
import { BackgroundUpdateStopwatch } from 'web/src/utils/store';
import { getBetlineLeagueStandings } from 'web/src/modules/sportline/utils/api';
import { isStatisticNotFoundError } from 'web/src/modules/sportline/utils/statistic';

interface UseSportlineLeagueStandingsStatisticsStoreComposableProps {
  isSportEventStatisticEnabled: Ref<boolean>;
  expectedStatisticId: Ref<Maybe<number>>;
  currentLoadedMatchIdentifier: Ref<Maybe<false | LoadStatisticIdentifier>>;
  sportlineEventStatisticUpdateInterval: Ref<number>;
}

interface UseSportlineLeagueStandingsStatisticsStoreComposableComposable {
  initialRequests(): Promise<void>;
  onInit(): void;
  setBackgroundUpdateEnabled(value: boolean): Promise<void>;
  syncBackgroundRequests(options: { force?: boolean; silent?: boolean }): Promise<void>;
  betlineLeagueStandings: Ref<Maybe<BetlineLeagueStanding[]>>;
  setBetlineLeagueStandings(statistic: Maybe<BetlineLeagueStandingsResponse>): void;
  isSyncStateWillBeSkipped(): boolean;
}

export default function useSportlineLeagueStandingsStatisticsStoreComposable(
  props: UseSportlineLeagueStandingsStatisticsStoreComposableProps,
): UseSportlineLeagueStandingsStatisticsStoreComposableComposable {
  const betlineLeagueStandings = ref<Maybe<BetlineLeagueStanding[]>>(null);

  const setBetlineLeagueStandings = function setBetlineLeagueStandings(
    statistic: Maybe<BetlineLeagueStandingsResponse>,
  ): void {
    betlineLeagueStandings.value = !!statistic && statistic.standings ? [...statistic.standings] : null;
  };
  const lastUpdate = new BackgroundUpdateStopwatch<['standingsStatistic']>({
    standingsStatistic: async ({ silent }: CoreSportlineFetchOptions) => {
      if (!props.isSportEventStatisticEnabled.value) { return; }

      try {
        const eventId = props.expectedStatisticId.value;
        if (!eventId) { return; }

        setBetlineLeagueStandings(
          await getBetlineLeagueStandings({ eventId }, { silent }),
        );
      } catch (error: unknown) {
        if (!isStatisticNotFoundError(error)) { throw error; }
        // we tried but got empty statistic for this ID
        setBetlineLeagueStandings(null);
      } finally {
        lastUpdate.update('standingsStatistic');
      }
    },
  });

  const {
    initialRequests,
    syncState,
    syncBackgroundRequests,
    setBackgroundUpdateEnabled,
  } = useBackgroundRequestsLifeCycle({
    lastUpdate,
    updateInterval: props.sportlineEventStatisticUpdateInterval,
  });

  function isSyncStateWillBeSkipped(): boolean {
    if (!props.isSportEventStatisticEnabled.value) { return true; }

    // standing displayed only for live events
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    const loadedSportEvent = props.currentLoadedMatchIdentifier.value || null;
    if (loadedSportEvent?.betline !== BetlineType.Live) { return true; }

    // sync standings only if we have one
    return !betlineLeagueStandings.value;
  }

  function onInit(): void {
    useSyncSportlineWithoutCTag(async (silent: boolean): Promise<void> => {
      if (isSyncStateWillBeSkipped()) { return; }
      await syncState({ silent });
    }, props.sportlineEventStatisticUpdateInterval);
  }

  return {
    initialRequests,
    onInit,
    syncBackgroundRequests,
    setBackgroundUpdateEnabled,
    betlineLeagueStandings,
    setBetlineLeagueStandings,
    isSyncStateWillBeSkipped,
  };
}
