<script lang="ts" setup>
import { ref } from 'vue';

import { LobbyItemType } from '@leon-hub/api-sdk';

import type {
  LobbySectionContainerEmits,
  LobbySectionContainerProps,
  LobbySectionContainerRef,
} from 'web/src/modules/lobby/components/LobbySectionContainer/types';
import { useLobbySectionContainer } from 'web/src/modules/lobby/components/LobbySectionContainer/composables';
import LobbySwiperSection from 'web/src/modules/lobby/components/LobbySwiperSection/LobbySwiperSection.vue';
import LobbyAllTopWinnersSection from 'web/src/modules/lobby/components/LobbyAllTopWinnersSection/LobbyAllTopWinnersSection.vue';
import LobbyGridSection from 'web/src/modules/lobby/components/LobbyGridSection/LobbyGridSection.vue';
import type { LobbySwiperSectionRef } from 'web/src/modules/lobby/components/LobbySwiperSection/types';

const props = withDefaults(defineProps<LobbySectionContainerProps>(), {
  lazyLoadPagesCounter: 0,
});
const emits = defineEmits<LobbySectionContainerEmits>();

const {
  isSwiper,
  typeId,
  lobbySectionId,
  isSkeletonItemsShown,
  lobbySwiperProps,
  lobbySectionBaseProps,
  lobbySwiperBackground,
  allTopWinnersSports,
  allTopWinnersEgs,
  allTopWinnersTab,
  emitActionClick,
  emitSwiperScroll,
  emitSectionScroll,
  emitTopWinnersFilterClick,
  emitClickLobbyItem,
  emitDeleteItems,
} = useLobbySectionContainer(props, emits);

const swiper = ref<LobbySwiperSectionRef>();

function resetScrollPosition(): void {
  swiper.value?.resetScrollPosition();
}
defineExpose<LobbySectionContainerRef>({
  resetScrollPosition,
});
</script>

<template>
  <LobbyAllTopWinnersSection
    v-if="typeId === LobbyItemType.ALL_TOP_WINNERS"
    v-data-test="{
      el: 'egs-lobby-section',
      'type-id': typeId,
      'section-id': lobbySectionId,
      'is-skeleton': isSkeletonItemsShown,
    }"
    :is-custom-homepage="isCustomHomepage"
    :class="$style['lobby-section--all-top-winners']"
    :header-icon="lobbySectionBaseProps.headerIcon"
    :header-title="lobbySectionBaseProps.headerName"
    :top-sports="allTopWinnersSports"
    :top-games="allTopWinnersEgs"
    :tab="allTopWinnersTab"
  />
  <LobbySwiperSection
    v-else-if="isSwiper"
    ref="swiper"
    v-data-test="{
      el: 'egs-lobby-section',
      'type-id': typeId,
      'section-id': lobbySectionId,
      'is-skeleton': isSkeletonItemsShown,
    }"
    v-bind="lobbySwiperProps"
    :background="lobbySwiperBackground"
    @action-click="emitActionClick"
    @swiper-scroll="emitSwiperScroll"
    @section-scroll="emitSectionScroll"
    @top-winners-filter-click="emitTopWinnersFilterClick"
    @click-lobby-item="emitClickLobbyItem"
    @delete-items="emitDeleteItems"
  />
  <LobbyGridSection
    v-else
    v-data-test="{
      el: 'egs-lobby-section',
      'type-id': typeId,
      'section-id': lobbySectionId,
      'is-skeleton': isSkeletonItemsShown,
    }"
    v-bind="lobbySectionBaseProps"
    @action-click="emitActionClick"
    @swiper-scroll="emitSwiperScroll"
    @section-scroll="emitSectionScroll"
    @top-winners-filter-click="emitTopWinnersFilterClick"
    @click-lobby-item="emitClickLobbyItem"
    @delete-items="emitDeleteItems"
  />
</template>

<style lang="scss" module>
.lobby-section {
  &--all-top-winners {
    width: 100%;
    max-width: 1201px;
    margin: 0 auto;
  }
}
</style>
