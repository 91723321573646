import { createPrerenderEnv } from './createPrerenderEnv';
import { createRuntimeEnvironment } from './createRuntimeEnvironment';

export function updateEnvironment({
  environment,
}: {
  environment: NodeJS.ProcessEnv;
}): void {
  Object.assign(
    environment,
    createPrerenderEnv(),
    createRuntimeEnvironment(),
  );
}
