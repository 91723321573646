import type { Ref } from 'vue';
import { computed } from 'vue';

import type {
  BetlineLeaguePlayoffRound,
  BetlineLeaguePlayoffRoundBlock,
} from 'web/src/modules/sportline/types/rest';

type BlockPair = [Maybe<BetlineLeaguePlayoffRoundBlock>]
| [Maybe<BetlineLeaguePlayoffRoundBlock>, Maybe<BetlineLeaguePlayoffRoundBlock>];

interface UseLeaguePlayoffRoundProps {
  round: Ref<BetlineLeaguePlayoffRound>;
}

interface UseLeaguePlayoffRoundComposable {
  blockPairs: Ref<BlockPair[]>;
}

export function useLeaguePlayoffRound(
  props: UseLeaguePlayoffRoundProps,
): UseLeaguePlayoffRoundComposable {
  const { round } = props;

  const blocks = computed(() => round.value.blocks || []);

  const blockPairs = computed<BlockPair[]>(() => {
    const result: BlockPair[] = [];
    const blocksList = blocks.value;

    if (blocksList.length <= 0) {
      return [];
    }

    if (blocksList.length === 1) {
      return [[blocksList[0] || null]];
    }

    for (let index = 0; index < blocksList.length; index += 2) {
      result.push([
        blocksList[index] || null,
        blocksList[index + 1] || null,
      ]);
    }

    return result;
  });

  return {
    blockPairs,
  };
}
