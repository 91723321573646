export * from './CounrtyCode';
export * from './CountryFlag';

export enum VCountryFlagSize {
  SIZE_0 = 'size-0',
  SIZE_10 = 'size-10',
  SIZE_12 = 'size-12',
  SIZE_16 = 'size-16',
  SIZE_18 = 'size-18',
  SIZE_20 = 'size-20',
  SIZE_22 = 'size-22',
  SIZE_24 = 'size-24',
  SIZE_26 = 'size-26',
  SIZE_28 = 'size-28',
  SIZE_40 = 'size-40',
  SIZE_54 = 'size-54',
  FULL_WIDTH = 'size-full-width',
}
