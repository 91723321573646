<script lang="ts" setup>
import VSnackBar from 'web/src/components/SnackBar/VSnackBar/VSnackBar.vue';

import { useSnackbarHolder } from './composables';
import type { SnackbarHolderProps } from './types';

defineProps<SnackbarHolderProps>();

const {
  hasSnackbars,
  snackbarProps,
  snackbarId,
  className,
  onAccept,
  onCancel,
} = useSnackbarHolder();
</script>

<template>
  <Teleport v-auto-id="'SnackbarHolder'"
    v-if="hasSnackbars"
    to="body"
    :disabled="!hasSnackbars"
  >
    <VSnackBar
      v-if="hasSnackbars"
      v-bind="snackbarProps"
      :key="snackbarId"
      :is-tab-bar-hidden="isTabbarHidden"
      :class="className"
      @click="onAccept"
      @click-close="onCancel"
    />
  </Teleport>
</template>
