<script lang="ts" setup>
import { toRef } from 'vue';

import InGroupRunner from 'web/src/modules/sportline/views/runner/InGroupRunner.vue';

import type { MarketsListGroupMarketProps } from '../../types';
import { useMarketsListMarket } from '../composables/useMarketsListMarket';

const props = defineProps<MarketsListGroupMarketProps>();

const group = toRef(props, 'group');
const sportEvent = toRef(props, 'sportlineEvent');

const {
  slipCaption,
  sportlineEventId,
  sportlineEventType,
  isDynamicColumns,
  flatRows,
} = useMarketsListMarket({ group, sportEvent });
</script>

<template>
  <div v-auto-id="'MarketsListMarket'" :class="$style['sport-event-details-item']">
    <div :class="{ [$style['sport-event-details-item__dynamic-columns']]: isDynamicColumns }">
      <div
        v-for="(row, rowIndex) in flatRows"
        :key="`${row.market.id}-${rowIndex}`"
        :class="{
          [$style['sport-event-details-item__grid']]: true,
          [$style['sport-event-details-item__grid--column-' + row.runners.length]]: true,
          [$style['sport-event-details-item__static-column']]: !isDynamicColumns,
          [$style['sport-event-details-item__dynamic-column']]: isDynamicColumns,
        }"
      >
        <div
          v-for="(runner, index) in row.runners"
          :key="runner ? `${runner.marketId}_${runner.id}` : `i${index}`"
          :class="$style['sport-event-details-item__runner-holder']"
        >
          <InGroupRunner
            :class="$style['sport-event-details-item__runner']"
            :runner="runner"
            :market="row.market"
            :slip-caption="slipCaption"
            :sport-event-id="sportlineEventId"
            :sportline-type="sportlineEventType"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>
$marketsQuantity: 20;

.sport-event-details-item {
  $self: &;

  padding: 4px 8px 8px;
  background-color: var(--Layer1);

  &__runner-holder {
    display: flex;
    height: auto;
  }

  &__runner {
    margin: 0;
  }

  &__dynamic-columns {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    &:after {
      flex: auto;
      content: '';
    }
  }

  &__dynamic-column {
    flex-basis: 100%;
    max-width: 100%;
  }

  &__static-column {
    &:last-child {
      border: 0;
    }
  }

  &__grid {
    display: grid;
    grid-column-gap: 4px;
    margin-top: 4px;

    &:last-child {
      margin-bottom: 0;
    }

    &--column {
      &-1 {
        grid-template-columns: 1fr;
      }

      &-2 {
        grid-template-columns: repeat(2, 1fr);
      }

      &-3 {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }

  @include is-app-layout-desktop {
    align-self: flex-start;
    margin: 0 0 8px;
    overflow: hidden;
    border-radius: 5px;
    break-inside: avoid;

    &__runner {
      margin: 0;
    }

    &__grid {
      background-color: transparent;
    }
  }
}
</style>
