import { useDialogsStore } from '../store';
import type { ShowDialogFunc } from '../types';

export interface DialogsComposable {
  showDialog: ShowDialogFunc;
  closeDialog(id: string): void;
  closeAllDialogs(): void;
}

export default function useDialogs(): DialogsComposable {
  const {
    showDialog,
    closeDialog,
    closeAllDialogs,
  } = useDialogsStore();

  return {
    showDialog,
    closeDialog,
    closeAllDialogs,
  };
}
