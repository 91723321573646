import {
  onComponentActivated,
  onComponentDeactivated,
} from '@leon-hub/vue-utils';

import { onWindowVisibilityChanged } from './onWindowVisibilityChanged';

type WindowVisibilityChangedCallback = (isVisible: boolean) => (void | Promise<void>);

export function useWindowVisibilityChanged(callback: WindowVisibilityChangedCallback): void {
  let hasActiveListener = false;

  const {
    addVisibilityChangeEventListener,
    removeVisibilityChangeEventListener,
  } = onWindowVisibilityChanged(callback);

  function addEventListener(): void {
    if (!hasActiveListener) {
      addVisibilityChangeEventListener();
      hasActiveListener = true;
    }
  }

  function removeEventListener(): void {
    if (hasActiveListener) {
      removeVisibilityChangeEventListener();
      hasActiveListener = false;
    }
  }

  onComponentActivated(addEventListener);
  onComponentDeactivated(removeEventListener);
}
