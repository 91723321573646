<script lang="ts" setup>
import type { OnResizeEventElementPayload } from 'web/src/modules/sportline/views/EventBlock/types';
import type { ExtendedEventBlock } from 'web/src/modules/sportline/submodules/extended-event/enums';

import type { VirtualListElement } from './types';
import {
  isEventElement,
  isLeagueHeadlineElement,
  isSportHeadlineElement,
  isNoEventsElement,
} from './guards';
import VirtualListRendererEventElement from './components/VirtualListRendererEventElement.vue';
import VirtualListRendererLeagueHeadline from './components/VirtualListRendererLeagueHeadline.vue';
import VirtualListRendererSportHeadline from './components/VirtualListRendererSportHeadline.vue';
import VirtualListRendererNoEventsElement from './components/VirtualListRendererNoEventsElement.vue';

interface VirtualListRendererElementProps {
  element: VirtualListElement;
  canAddEventToFavorite?: boolean;
  isMarketTypesSelectionEnabled?: boolean;
  extendedBlockType?: ExtendedEventBlock;
}

interface VirtualListRendererElementEmits {
  (e: 'event-resize', payload: OnResizeEventElementPayload): void;
}

defineProps<VirtualListRendererElementProps>();
const emit = defineEmits<VirtualListRendererElementEmits>();
</script>

<template>
  <VirtualListRendererEventElement
    v-if="isEventElement(element)"
    :element="element"
    :can-add-to-favorite="canAddEventToFavorite"
    :extended-block-type="extendedBlockType"
    @resize="emit('event-resize', $event)"
  />
  <VirtualListRendererLeagueHeadline
    v-else-if="isLeagueHeadlineElement(element)"
    :element="element"
  />
  <VirtualListRendererSportHeadline
    v-else-if="isSportHeadlineElement(element)"
    :element="element"
    :is-market-types-selection-enabled="isMarketTypesSelectionEnabled"
  />
  <VirtualListRendererNoEventsElement
    v-else-if="isNoEventsElement(element)"
    :element="element"
  />
  <div v-else />
</template>
