import type { CustomProperties } from '../types';

export default function addToPropertiesMap<KeyType = string>(
  currentMap: Map<KeyType, CustomProperties>,
  key: KeyType,
  properties: CustomProperties,
): Map<KeyType, CustomProperties> {
  currentMap.set(key, properties);
  return currentMap;
}
