import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import { KnownTraceTypes } from '../enums/KnownTraceTypes';
import type { BetSlipTraceContentProps, TraceContent } from '../types';
import {
  contentsIsArray, contentsIsString, contentsIsTraceLabelTooltip, contentsIsTraceContent,
} from '../utils';

interface UseBetSlipTraceContent {
  typeId: ComputedRef<KnownTraceTypes | string | undefined>;
  isList: ComputedRef<boolean>;
  isTable: ComputedRef<boolean>;
  listSource: ComputedRef<TraceContent[]>;
  rootTag: ComputedRef<string>;
  contentTag: ComputedRef<string>;
  formattedText: ComputedRef<string>;
  title: ComputedRef<string | undefined>;
}

/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */

export default function useBetSlipTraceContent(props: BetSlipTraceContentProps): UseBetSlipTraceContent {
  const typeId = computed<KnownTraceTypes | string | undefined>(() => {
    if (contentsIsTraceContent(props.contents)) {
      return props.contents.typeId;
    }
    return undefined;
  });

  const isList = computed<boolean>(
    () => ((typeId.value === KnownTraceTypes.LIST) || (!typeId.value && contentsIsArray(props.contents))),
  );

  const isTable = computed<boolean>(
    () => typeId.value === KnownTraceTypes.TABLE,
  );

  const listSource = computed<TraceContent[]>(() => {
    if (contentsIsString(props.contents)) {
      return [];
    }
    if (contentsIsArray(props.contents)) {
      return props.contents;
    }
    if (contentsIsArray(props.contents?.data)) {
      return props.contents?.data ?? [];
    }
    return [];
  });

  const rootTag = computed<string>(() => {
    if (isTable.value || isList.value) {
      return 'div';
    }
    return 'span';
  });

  const contentTag = computed<string>(() => {
    if (!typeId.value) {
      return '';
    }
    switch (typeId.value) {
      case KnownTraceTypes.TEXT:
        return 'span';
      case KnownTraceTypes.LABEL_WITH_TITLE:
        return 'abbr';
      case KnownTraceTypes.LABEL:
        return 'b';
      default:
        return 'code';
    }
  });

  const text = computed<string | undefined>(() => {
    if (contentsIsString(props.contents)) {
      return props.contents;
    }
    if (contentsIsArray(props.contents)) {
      return undefined;
    }
    if (contentsIsTraceLabelTooltip(props.contents?.data)) {
      return props.contents?.data?.label || '';
    }
    return props.contents?.data ? JSON.stringify(props.contents.data) : '';
  });

  const formattedText = computed<string>(() => {
    if (!text.value) {
      return '';
    }

    return text.value
      // replace arrow not working with v-text (-&gt);
      .replace('-&gt;', '→')
      // remove quotes at the start and end
      .replace(/^["']|["']$/gm, '');
  });

  const title = computed<string | undefined>(() => {
    if (contentsIsTraceContent(props.contents) && contentsIsTraceLabelTooltip(props.contents?.data)) {
      return props.contents?.data?.tooltip;
    }
    return undefined;
  });

  return {
    isList,
    isTable,
    listSource,
    rootTag,
    contentTag,
    formattedText,
    title,
    typeId,
  };
}
