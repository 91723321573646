import {
  AbstractError,
} from '@leon-hub/errors';
import type {
  AbstractErrorOptions,
} from '@leon-hub/errors';
import type { Without } from '@leon-hub/types';

import FileReaderErrorCode from './FileReaderErrorCode';

const fileReaderErrorCode = new FileReaderErrorCode();

export default class FileReaderError extends AbstractError {
  constructor(options?: Without<AbstractErrorOptions, 'code'>) {
    super({
      ...options,
      code: fileReaderErrorCode,
      message: 'BTR_WEB2_FILE_READER_ERROR', // TODO: move translation service into leon-hub
    });
  }
}
