<script setup lang="ts">
import { computed, toRef } from 'vue';

import { useSportlineEventDateTime } from 'web/src/modules/sportline/composables/sportline-event';
import type { SportlineEvent, Competitor } from 'web/src/modules/sportline/types';
import InfoCountdown from 'web/src/modules/sportline/submodules/event-details/components/info-countdown/InfoCountdown.vue';

import HeadlineTeam from './components/HeadlineTeam.vue';

interface PreMatchInfoDesktopProps {
  sportlineEvent: SportlineEvent;
  backgroundColor?: Maybe<string>;
}

const props = defineProps<PreMatchInfoDesktopProps>();

const sportlineEvent = toRef(props, 'sportlineEvent');

const competitors = computed<Competitor[]>(() => sportlineEvent.value?.competitors ?? []);
const hasLogos = computed<boolean>(() => (
  !!competitors.value[0]?.logo
  && !!competitors.value[1]?.logo
));
const { date, time, kickoff } = useSportlineEventDateTime({ sportlineEvent });
</script>

<template>
  <div v-auto-id="'PreMatchInfo'"
    :class="[
      $style['headline-info'],
      $style['headline-info--prematch'],
    ]"
  >
    <div :class="$style['headline-info__container']">
      <HeadlineTeam
        position="center"
        role="host"
        :name="competitors[0]?.name"
        :logo="hasLogos ? competitors[0]?.logo : undefined"
        :logo-meta="hasLogos ? competitors[0]?.logoMeta : undefined"
      />

      <div>
        <div :class="$style['headline-info__date']">
          <span :class="$style['headline-info__day']">{{ date }}</span>
          <br>
          <span :class="$style['headline-info__time']">{{ time }}</span>
        </div>

        <InfoCountdown
          :class="$style['headline-info__countdown']"
          :kickoff="kickoff"
        />

        <slot name="primary-market" />
      </div>

      <HeadlineTeam
        position="center"
        role="guest"
        :name="competitors[1]?.name"
        :logo="hasLogos ? competitors[1]?.logo : undefined"
        :logo-meta="hasLogos ? competitors[1]?.logoMeta : undefined"
      />
    </div>
  </div>
</template>

<style module lang="scss">
@import 'web/src/modules/sportline/submodules/event-details/styles/headline-desktop';
</style>
