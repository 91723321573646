import { ApiErrorCode } from '../../errors/ApiErrorCode';
import GqlApiBatchError from './GqlApiBatchError';
import type { GqlApiErrorOptions } from './GqlApiError';

export default class GqlApiBatchHashingDisabledError extends GqlApiBatchError {
  constructor(options: GqlApiErrorOptions) {
    super({
      ...options,
      code: ApiErrorCode.API_REQUEST_HASHING_IS_DISABLED,
    });
  }
}
