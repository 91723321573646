import type {
  CoreLeagueResponse,
  CoreLeagueReferenceResponse,
} from 'web/src/modules/sportline/types/rest';
import { isFullCoreLeagueResponse } from 'web/src/modules/sportline/guards/rest';

export function assertFullCoreLeagueResponse(
  response: CoreLeagueResponse | CoreLeagueReferenceResponse,
): CoreLeagueResponse {
  if (!isFullCoreLeagueResponse(response)) {
    throw new Error('League assert failed');
  }

  return response;
}
