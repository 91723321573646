<script setup lang="ts">
import type { FocusableInputRef } from '@leon-hub/focus';
import { useAutofocus } from '@leon-hub/focus';

import type { TextFlatInputProperties, TextFlatInputEmits } from '../../types';
import {
  useNativeInputEvents,
  useAdditionalInputEvents,
} from '../../composables';

const props = withDefaults(defineProps<TextFlatInputProperties>(), {
  name: '',
  type: 'text',
  accept: '',
  list: '',
});

const emits = defineEmits<TextFlatInputEmits>();

const {
  emitChange,
  emitInput,
  emitBlur,
  emitFocus,
} = useNativeInputEvents(emits);
const {
  emitMousedown,
  emitMouseleave,
  emitPaste,
  emitKeydown,
  emitMouseover,
} = useAdditionalInputEvents(emits);

const { focusable, focus } = useAutofocus(props);

defineExpose<FocusableInputRef>({
  focus,
});
</script>

<template>
  <input v-auto-id="'TextFlatInput'"
    :id="inputId"
    ref="focusable"
    :name="name"
    :class="{
      [$style['text-flat-input']]: true,
      [$style['text-flat-input--error']]: hasError,
      [$style['text-flat-input--vanilla-mask']]: hasVanillaMask,
      [$style['text-flat-input--empty']]: value === '',
      [$style['text-flat-input--modal']]: isFromModal,
      [$style['text-flat-input--icon-prefix']]: hasIconPrefix,
      [$style['text-flat-input--icon-suffix']]: hasIconSuffix,
      [$style['text-flat-input--large']]: isLarge,
      [$style['text-flat-input--no-padding']]: noPadding,
      [$style['text-flat-input--payment']]: isPaymentField,
      'ym-disable-keys': isYmDisableKeys,
    }"
    :autofocus="process.env.VUE_APP_OS_IOS ? undefined : autofocus"
    :value="value"
    :autocomplete="autocomplete"
    :disabled="disabled"
    :type="type"
    :placeholder="placeholder"
    :accept="accept"
    :inputmode="inputMode"
    :list="list"
    :maxlength="maxLength"
    :readonly="readonly"
    @input.stop="emitInput"
    @change.stop="emitChange"
    @focus.stop="emitFocus"
    @blur.stop="emitBlur"
    @mousedown="emitMousedown"
    @keydown="emitKeydown"
    @mouseover="emitMouseover"
    @mouseleave="emitMouseleave"
    @paste="emitPaste"
  >
</template>

<style lang="scss" module>
.text-flat-input {
  @import '../../common';

  $self: &;

  @include inputCommon;
  @include inputAutoFillFlat;

  &[type='number'] {
    appearance: textfield;
  }

  &[disabled],
  &:disabled {
    // area reserved for lock icon
    padding-right: $inputIconWidth;
  }

  &--no-padding {
    padding: 0;
  }

  &--large {
    @include inputLarge;
  }

  &--large:disabled,
  &--large[disabled] {
    padding-right: $inputIconWidthLarge;
  }

  &:-webkit-autofill {
    -webkit-text-fill-color: $inputValueDefaultColor;

    &:focus {
      -webkit-text-fill-color: $inputValueDefaultColor;
    }
  }

  &::placeholder {
    color: $inputPlaceholderDefaultColor;
  }

  &:focus {
    &::placeholder {
      color: $inputPlaceholderFocusColor;
    }
  }

  &--empty {
    &:-webkit-autofill {
      &::placeholder {
        -webkit-text-fill-color: $inputPlaceholderDefaultColor;
      }

      &:focus {
        &::placeholder {
          -webkit-text-fill-color: $inputPlaceholderDefaultColor;
        }
      }
    }
  }

  &--error {
    color: $inputValueErrorColor;

    &::placeholder {
      color: $inputPlaceholderErrorColor;
    }

    &:-webkit-autofill {
      &::placeholder {
        -webkit-text-fill-color: $inputPlaceholderErrorColor;
      }
    }
  }

  &--vanilla-mask {
    @include z-index(base);

    // otherwise mask will cover input
    position: relative;

    &:focus {
      &::placeholder {
        color: transparent;
      }
    }

    &:-webkit-autofill {
      &:focus {
        &::placeholder {
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }

  &--icon-prefix {
    padding-left: $inputIconWidth;
  }
  &--icon-prefix#{$self}--large {
    padding-left: $inputIconWidthLarge;
  }

  &--icon-suffix {
    padding-right: $inputIconWidth;
  }
  &--icon-suffix#{$self}--large {
    padding-right: $inputIconWidthLarge;
  }

  &--payment {
    @include paymentInputFont;

    text-align: right;
  }

  &:read-only {
    text-overflow: ellipsis;
  }
}
</style>
