enum LiveChatStatus {
  INCOMING = 1,
  IN_CHAT = 2,
  ENDED = 3,
  NO_ANSWER = 4,
  TIMEOUT = 5,
  MISSED = 6,
}

export default LiveChatStatus;
