import { computed, toRef } from 'vue';
import { useRouter } from 'vue-router';

import type { NavigationItem } from '@leon-hub/navigation-config';
import { RouteName, isBaseRouteName } from '@leon-hub/routing-config-names';
import type { IconNameType } from '@leon-hub/icons';
import { IconName } from '@leon-hub/icons';
import { Tag } from '@leon-hub/tags/src/Tag';
import {
  Events, TargetParamHistory, TargetParamNavigation, TargetParamWithdrawal,
} from '@leon-hub/yandex-metrika';
import { CasinoRouteName } from '@leon-hub/routing-config-names/src/CasinoRouteName';
import { assert } from '@leon-hub/guards';
import { isTag } from '@leon-hub/tags';
import { getNavigationItemRouteLocation } from '@leon-hub/navigation-config';

import getOptionalString from 'web/src/utils/getOptionalString';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import { useFormatMoney } from 'web/src/modules/money/composables';
import { useProfileBonusesStore } from 'web/src/modules/profile/submodules/bonuses/store';
import { useCustomerNotificationsUnreadCounter } from 'web/src/modules/customer-notifications/composables';
import useBonusLoyaltyStore from 'web/src/modules/bonuses/store/useBonusLoyaltyStore';
import { getEgsLoyaltyStore } from 'web/src/modules/core/components/AuthorizedAppController/composables/utils';
import type { UserProfileNavigation } from 'web/src/modules/profile/types';

interface ProfileNavListComposable {
  getIconSettings(item: NavigationItem): Optional<IconNameType>;
  getBadgeLabel(item: UserProfileNavigation): Optional<string>;
  checkHasNewNotifications(item: UserProfileNavigation): boolean;
  getCounterLabel(item: UserProfileNavigation): string;
  onClick(item: UserProfileNavigation): void;
  getBonusesValue(item: UserProfileNavigation): Optional<string>;
}

/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */

// eslint-disable-next-line sonarjs/cognitive-complexity
export default function useProfileNavList(): ProfileNavListComposable {
  const router = useRouter();
  const analytics = useAnalytics();
  const $formatMoney = useFormatMoney();
  const profileBonusesStore = useProfileBonusesStore();
  const bonusLoyaltyStore = useBonusLoyaltyStore();
  const egsLoyaltyStore = getEgsLoyaltyStore();

  const egsBonuses = toRef(profileBonusesStore, 'egsBonuses');
  const sportBonuses = toRef(profileBonusesStore, 'sportBonuses');
  const { unreadCounter } = useCustomerNotificationsUnreadCounter();
  const isParticipating = toRef(bonusLoyaltyStore, 'isParticipating');
  const amountNumber = toRef(bonusLoyaltyStore, 'amountNumber');

  const bonusesCount = computed(() => sportBonuses.value.length + egsBonuses.value.length);
  function getIconSettings(item: NavigationItem): Optional<IconNameType> {
    return item.icon?.name;
  }

  function getBonusesValue(item: UserProfileNavigation): Optional<string> {
    if (item?.routeName === RouteName.LOYALTY_PROGRAM) {
      return isParticipating.value
        ? $formatMoney(amountNumber.value || 0, { currency: 'L' })
        : '';
    }
    if (item?.routeName === RouteName.BONUSES) {
      return getOptionalString(bonusesCount.value);
    }

    return undefined;
  }

  function checkHasNewNotifications(item: UserProfileNavigation): boolean {
    const itemId = isBaseRouteName(item.id) ? item.id : undefined;
    return itemId === RouteName.CUSTOMER_NOTIFICATIONS && item?.suffix?.label !== '';
  }

  function getBadgeLabel(item: UserProfileNavigation): Optional<string> {
    if (item?.suffix && item?.suffix.component === 'VBadge') {
      return item.suffix.label;
    }

    if (item.icon?.name === IconName.BELL || (
      process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED
      && item.icon?.name === IconName.SLOTT_BELL_FILLED)) {
      return unreadCounter.value;
    }

    if (process.env.VUE_APP_FEATURE_CASINO_LOYALTY_PROGRAM_ENABLED
      && !process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED
      && item?.routeName === CasinoRouteName.CASINO_LOYALTY_PROGRAM) {
      assert(egsLoyaltyStore);
      const currentLevelData = toRef(egsLoyaltyStore, 'currentLevelData');
      return currentLevelData.value?.name;
    }

    if (
      process.env.VUE_APP_PRODUCT_LEONRU
      && item?.routeName === RouteName.LOYALTY_PROGRAM
      && !isParticipating.value
    ) {
      return '1';
    }

    return '';
  }

  function getCounterLabel(item: UserProfileNavigation): string {
    const tag = isTag(item?.suffix?.component) ? item.suffix?.component : undefined;
    if (tag === Tag.SPAN) {
      return item.suffix?.label ?? '';
    }
    return '';
  }

  function setMetrika(target: string, param: string): void {
    analytics.push(Events.CLICK_MAP, {
      clickCounter: { [target]: param },
    });
  }

  function onClick(item: UserProfileNavigation): void {
    switch (item.routeName) {
      case RouteName.WITHDRAWALS:
        setMetrika('withdrawal', TargetParamWithdrawal.USER_PROFILE);
        break;
      case RouteName.CUSTOMER_TRANSACTIONS_HISTORY:
        setMetrika('history', TargetParamHistory.USER_PROFILE);
        break;
      case RouteName.PROMOTIONS:
        setMetrika('promo', TargetParamNavigation.LINK_IN_PROFILE);
        break;
      default:
        break;
    }

    if (item.routeName) {
      void router.push(getNavigationItemRouteLocation(item, 'useProfileNavList'));
    } else if (item.url) {
      void router.push({ path: item.url });
    }
  }

  return {
    getIconSettings,
    getBadgeLabel,
    checkHasNewNotifications,
    getCounterLabel,
    onClick,
    getBonusesValue,
  };
}
