import type {
  NavigationGuardNext,
  RouteLocationNormalized,
} from 'vue-router';

import { AbstractPrefetch } from 'web/src/modules/core/services/router/prefetch';
import type { AppVueRouter } from 'web/src/modules/core/services/router/types';
import { useEgsGroupsStore } from 'web/src/modules/egs/submodules/groups/store';

export default class CasinoGroupsPagePrefetch extends AbstractPrefetch {
  // eslint-disable-next-line class-methods-use-this
  prefetch(
    router: AppVueRouter,
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ): Promise<void> {
    const { loadGroups } = useEgsGroupsStore();

    loadGroups().then();

    next();

    return Promise.resolve();
  }
}
