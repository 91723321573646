import CasRestApiClient from './CasRestApiClient';

let casApiClient: CasRestApiClient | undefined;

export default function useCasApiClient(): CasRestApiClient {
  if (!casApiClient) {
    casApiClient = new CasRestApiClient();
  }

  return casApiClient;
}
