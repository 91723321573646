import type { DropListSelectOption } from 'web/src/components/DropListSelect/types';

export function getMultipleSelectionText(options: DropListSelectOption[], selectedValues: Set<string>): string {
  if (!selectedValues.size) {
    return '';
  }
  const matchedOptionsMap: Dictionary<string> = {};
  for (const item of options) {
    if (selectedValues.has(item.value)) {
      matchedOptionsMap[item.value] = item.label;
    }
  }
  const sortedSelectedLabels: string[] = [];
  for (const value of selectedValues) {
    const matchedLabel = matchedOptionsMap[value];
    if (matchedLabel) {
      sortedSelectedLabels.push(matchedLabel);
    }
  }
  return sortedSelectedLabels.join(', ');
}
