<script setup lang="ts">
import { toRef } from 'vue';

import type { BetlineMatchStatisticsTeam } from 'web/src/modules/sportline/types/rest';
import type { TeamPlayTotalStatistic } from 'web/src/modules/sportline/submodules/core-statistic/types';
import { StatisticChartBarType } from 'web/src/modules/sportline/submodules/core-statistic/enums';
import StatisticChartBar
  from 'web/src/modules/sportline/submodules/core-statistic/components/StatisticChartBar.vue';
import MatchesStatisticCompetitors
  from 'web/src/modules/sportline/submodules/event-details/components/statistic/MatchesStatisticCompetitors.vue';

import { useJoinMatchesStatistic } from './useJoinMatchesStatistic';

interface JoinMatchesStatisticProps {
  playStatistic?: Maybe<TeamPlayTotalStatistic>;
  teams: [BetlineMatchStatisticsTeam, BetlineMatchStatisticsTeam];
}

const props = defineProps<JoinMatchesStatisticProps>();

const playStatistic = toRef(props, 'playStatistic', null);

const {
  hostGoalsLabel,
  hostWinsLabel,
  guestGoalsLabel,
  guestWinsLabel,
  totalMatchesLabel,
  totalDrawsLabel,
} = useJoinMatchesStatistic({ playStatistic });
</script>

<template>
  <div v-auto-id="'JoinMatchesStatistic'" :class="$style['join-matches-statistic']">
    <MatchesStatisticCompetitors
      :teams="teams"
    />

    <template v-if="playStatistic">
      <div
        :class="[
          $style['join-matches-statistic-goals__container'],
          $style['statistic-chart-labels'],
        ]"
      >
        <span
          v-data-test="{ el: 'join-matches-statistic__total__goals-home' }"
          :class="$style['join-matches-statistic-goals__label']"
        >
          {{ hostGoalsLabel }}
        </span>
        <span
          v-data-test="{ el: 'join-matches-statistic__total__matches' }"
          :class="$style['join-matches-statistic-goals__label']"
        >
          {{ totalMatchesLabel }}
        </span>
        <span
          v-data-test="{ el: 'join-matches-statistic__total__goals-away' }"
          :class="$style['join-matches-statistic-goals__label']"
        >
          {{ guestGoalsLabel }}
        </span>
      </div>

      <div
        v-data-test="{ el: 'join-matches-statistic__graph' }"
        :class="$style['join-matches-statistic-graph__container']"
      >
        <StatisticChartBar
          :type="StatisticChartBarType.LABELED"
          :to-fixed="1"
          :home-percentage="playStatistic.host.winsPercentage"
          :draw-percentage="playStatistic.drawsPercentage"
          :away-percentage="playStatistic.guest.winsPercentage"
        />
      </div>

      <div
        :class="[
          $style['join-matches-statistic-wins__container'],
          $style['statistic-chart-labels'],
        ]"
      >
        <span :class="$style['join-matches-statistic-wins__label']">
          <span
            v-data-test="{ el: 'join-matches-statistic__total__wins-home' }"
            :class="[
              $style['statistic-chart-label-indicator'],
              $style['statistic-chart-label-indicator--home'],
            ]"
          />&nbsp;{{ hostWinsLabel }}
        </span>
        <span :class="$style['join-matches-statistic-wins__label']">
          <span
            v-data-test="{ el: 'join-matches-statistic__total__draws' }"
            :class="[
              $style['statistic-chart-label-indicator'],
              $style['statistic-chart-label-indicator--draw'],
            ]"
          />&nbsp;{{ totalDrawsLabel }}
        </span>
        <span :class="$style['join-matches-statistic-wins__label']">
          <span
            v-data-test="{ el: 'join-matches-statistic__total__wins-away' }"
            :class="[
              $style['statistic-chart-label-indicator'],
              $style['statistic-chart-label-indicator--away'],
            ]"
          />&nbsp;{{ guestWinsLabel }}
        </span>
      </div>
    </template>
  </div>
</template>

<style module lang="scss">
@import 'web/src/modules/sportline/submodules/core-statistic/components/styles/statisticChartLabels';
@import 'web/src/modules/sportline/submodules/core-statistic/components/styles/statisticChartLabelIndicator';

.join-matches-statistic {
  padding: 4px;
  background-color: var(--Layer2);
  border-radius: 5px;
}

.join-matches-statistic-goals {
  &__container {
    padding: 12px 4px 4px;
  }

  // @TODO fix colors for number in label
  &__label {
    @include medium\12\12;

    color: var(--TextDefault);
  }
}

.join-matches-statistic-graph {
  &__container {
    padding: 8px 4px;
  }
}

.join-matches-statistic-wins {
  &__container {
    padding: 0 4px 8px;
  }

  // @TODO fix colors for number in label
  &__label {
    @include medium\12\12;

    color: var(--TextPrimary);
  }
}
</style>
