<script lang="ts" setup>
import { computed } from 'vue';

import type { VButtonEmits } from '@leon-hub/module-buttons';
import { Tag } from '@leon-hub/tags';
import { IconSize } from '@leon-hub/icons';

import { VIcon } from '@components/v-icon';

import VAnimationStarter from 'web/src/components/AnimationStarter/VAnimationStarter/VAnimationStarter.vue';

import type { LandingButtonProps, LandingButtonSlots } from './types';
import { buttonKind } from './types';

const props = withDefaults(defineProps<LandingButtonProps>(), {
  tag: Tag.BUTTON,
  kind: buttonKind.PRIMARY,
});

const emit = defineEmits<VButtonEmits>();

defineSlots<LandingButtonSlots>();

const computedAttributes = computed<Record<string, string | undefined>>(() => ({
  ...((props.dataAttributeName && props.dataAttributeValue) ? {
    [props.dataAttributeName]: props.dataAttributeValue,
  } : {}),
}));
</script>

<template>
  <VAnimationStarter v-auto-id="'LandingButton'"
    root-margin="0px 0px -50px 0px"
    :threshold="0.8"
    animated-class="anim-starter--animated"
  >
    <component
      v-bind="computedAttributes"
      :is="tag"
      :id="id"
      :class="{
        [$style['landing-button']]: true,
        [$style['landing-button--full']]: fullWidth,
        [$style[`landing-button--${kind}`]]: kind !== buttonKind.PRIMARY,
      }"
      :href="href"
      :disabled="isDisabled"
      @click="emit('click', $event)"
      @keydown="emit('keydown', $event)"
      @mousedown="emit('mousedown', $event)"
    >
      <slot name="default">
        <span
          v-if="iconName"
          :class="$style['landing-button__icon']"
        >
          <VIcon
            :name="iconName"
            :size="IconSize.SIZE_24"
          />
        </span>
        <span :class="$style['landing-button__label']">{{ label }}</span>
      </slot>
    </component>
  </VAnimationStarter>
</template>

<style lang="scss" module>
.landing-button {
  box-sizing: border-box;
  display: var(--landingButtonDisplay);
  gap: var(--landingButtonGap);
  align-items: center;
  justify-content: center;
  width: var(--landingButtonWidth);
  height: var(--landingButtonHeight);
  padding: var(--landingButtonPadding);
  color: var(--landingButtonColorPrimary);
  text-decoration: none;
  background: var(--landingButtonBackgroundPrimary);
  border: none;
  border-radius: var(--landingButtonRadius);
  transition: var(--animation-button-transition);
  transform: var(--animation-button-transform);

  @include for-hover {
    &:hover:not([disabled]) {
      cursor: pointer;
      background: var(--landingButtonBackgroundHoverPrimary);
    }
  }

  &__label {
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    font-family: var(--landingButtonFontFamily);
    font-size: var(--landingButtonFontSize);
    font-weight: var(--landingButtonFontWeight);
    line-height: var(--landingButtonLineHeight);

    @include landingDesktop {
      font-size: var(--landingButtonFontSizeDesk);
      line-height: var(--landingButtonLineHeightDesk);
    }
  }

  &--full {
    width: 100%;
  }

  &__icon {
    position: relative;
    width: 24px;
    height: 24px;
  }

  &:active:not([disabled]),
  &:focus-visible {
    box-shadow: var(--landingButtonActiveBoxShadowPrimary);
  }

  &:disabled {
    color: var(--landingButtonDisabledColorPrimary);
    cursor: auto;
    background: var(--landingButtonDisabledBackgroundPrimary);
    opacity: var(--landingButtonDisabledOpacityPrimary);
  }

  &--secondary {
    color: var(--landingButtonColorSecondary);
    background: var(--landingButtonBackgroundSecondary);

    @include for-hover {
      &:hover:not([disabled]) {
        background: var(--landingButtonBackgroundHoverSecondary);
      }

      &:active:not([disabled]),
      &:focus-visible {
        outline: none;
        box-shadow: var(--landingButtonActiveBoxShadowSecondary);
      }

      &:disabled {
        color: var(--landingButtonDisabledColorSecondary);
        background: var(--landingButtonDisabledBackgroundSecondary);
        opacity: var(--landingButtonDisabledOpacitySecondary);
      }
    }

    &__label {
      color: transparent;
      background: var(--landingButtonColorSecondary);
      background-clip: text;
    }
  }

  &--tertiary {
    color: var(--landingButtonColorTertiary);
    background: var(--landingButtonBackgroundTertiary);

    @include for-hover {
      &:hover:not([disabled]) {
        background: var(--landingButtonBackgroundHoverTertiary);
      }

      &:active:not([disabled]),
      &:focus-visible {
        background: var(--landingButtonActiveBackgroundTertiary);
        outline: none;
        box-shadow: var(--landingButtonActiveBoxShadowTertiary);
      }

      &:disabled {
        color: var(--landingButtonDisabledColorTertiary);
        background: var(--landingButtonDisabledBackgroundTertiary);
        opacity: var(--landingButtonDisabledOpacityTertiary);
      }
    }
  }

  &--quaternary {
    position: relative;
    color: var(--landingButtonColorQuaternary);
    background: var(--landingButtonBackgroundQuaternary);
    box-shadow: var(--landingButtonBoxShadowQuaternary);

    @include for-hover {
      &:hover:not([disabled]) {
        background: var(--landingButtonBackgroundQuaternaryHover);
        box-shadow: var(--landingButtonBoxShadowQuaternaryHover);
      }

      &:disabled {
        color: var(--landingButtonDisabledColorQuaternary);
        background: var(--landingButtonDisabledBackgroundQuaternary);
        opacity: var(--landingButtonDisabledOpacityQuaternary);
      }
    }

    &:active:not([disabled]),
    &:focus-visible {
      background: var(--landingButtonActiveBackgroundQuaternary);
      outline: none;
      box-shadow: var(--landingButtonActiveBoxShadowQuaternary);
    }
  }

  @include landingDesktop {
    width: var(--landingButtonWidthDesk);
    height: var(--landingButtonHeightDesk);
    padding: var(--landingButtonPaddingDesk);

    &--full {
      width: 100%;
    }
  }
}
</style>
