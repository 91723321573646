<script lang="ts" setup>
import { VJumbotron, JumbotronFooterButton } from 'web/src/components/Jumbotron';

import ErrorHeaderBar from '../ErrorHeaderBar/ErrorHeaderBar.vue';
import { useBaseErrorContent } from './composables';
import type { BaseErrorContentProps } from './types';

const props = withDefaults(defineProps<BaseErrorContentProps>(), {});

const {
  mainJumbotronProps,
  mainButtonProps,
  supportJumbotronProps,
  supportButtonProps,
  onMainClick,
  onSupportClick,
} = useBaseErrorContent(props);
</script>

<template>
  <ErrorHeaderBar />
  <div :class="$style['error-app']">
    <VJumbotron
      v-bind="mainJumbotronProps"
      :class="$style['error-app__info']"
    >
      <template
        v-if="!isMainButtonHidden"
        #footer
      >
        <JumbotronFooterButton
          v-bind="mainButtonProps"
          @click="onMainClick"
        />
      </template>
    </VJumbotron>
    <VJumbotron
      v-if="!isSupportHidden && !null"
      v-bind="supportJumbotronProps"
      :class="$style['error-app__support']"
    >
      <template #footer>
        <JumbotronFooterButton
          v-bind="supportButtonProps"
          @click="onSupportClick"
        />
      </template>
    </VJumbotron>
  </div>
</template>

<style lang="scss" module>
@include for-layout using($isDesktop) {
  .error-app {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: calc(100vh - $headerHeight);
    padding: 16px;

    @if $isDesktop {
      min-height: calc(100vh - $headerHeightDesktopSmall);

      @include screen-desktop-large-min {
        min-height: calc(100vh - $headerHeightDesktopBig);
      }
    }

    &__info,
    &__support {
      @if $isDesktop {
        max-width: 311px;
      }
    }

    &__support {
      margin-top: 64px;
    }
  }
}
</style>
