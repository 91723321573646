<script lang="ts" setup>
import {
  ref,
  nextTick,
  computed,
  useSlots,
  watch,
} from 'vue';

import { BusEvent, useEventsBus } from '@leon-hub/event-bus';

import { ModalSelector } from 'web/src/modules/core/enums';
import hasSlot from 'web/src/utils/vue/hasSlot';
import type ModalTransition from 'web/src/components/Modal/ModalPortal/enums/ModalTransition';

interface ModalPortalProps {
  selector: ModalSelector;
  transition?: ModalTransition;
}

const props = defineProps<ModalPortalProps>();

const bus = useEventsBus();
const isAnimationAvailable = ref(false);
const slots = useSlots();
let wasMounted = false;

function afterLeave(): void {
  isAnimationAvailable.value = false;
}

async function onComponentMounted(): Promise<void> {
  if (props.transition) {
    await nextTick();
    isAnimationAvailable.value = true;
  }

  if (props.selector === ModalSelector.BODY) {
    bus.emit(BusEvent.LAYOUT_CONTENT_SCROLL_DISABLE, { reason: 'modal' });
  }

  wasMounted = false;
}

function onComponentUnmounted(): void {
  isAnimationAvailable.value = false;
  if (props.selector === ModalSelector.BODY) {
    bus.emit(BusEvent.LAYOUT_CONTENT_SCROLL_ENABLE, { reason: 'modal' });
  }
}

const hasSlots = computed(() => hasSlot(slots, 'default'));

watch(hasSlots, (newValue) => {
  if (newValue && !wasMounted) {
    wasMounted = true;
    void onComponentMounted();
  }
}, { immediate: true });
</script>

<template>
  <Teleport v-auto-id="'ModalPortal'"
    v-if="hasSlots || isAnimationAvailable"
    :to="selector"
    :disabled="!hasSlots && !isAnimationAvailable"
    @vue:before-unmount="onComponentUnmounted"
  >
    <Transition
      v-if="transition"
      :enter-active-class="`${$style[`${transition}-enter-active`]} ${transition}-enter-active`"
      :enter-from-class="`${$style[`${transition}-enter-from`]} ${transition}-enter-from`"
      :leave-active-class="`${$style[`${transition}-leave-active`]} ${transition}-leave-active`"
      :leave-to-class="`${$style[`${transition}-leave-to`]} ${transition}-leave-to`"
      :name="transition"
      @after-leave="afterLeave"
      @leave-cancelled="afterLeave"
    >
      <slot
        v-if="isAnimationAvailable"
      />
    </Transition>
    <slot v-else />
  </Teleport>
</template>

<style lang="scss" module>
@import '../common';

@keyframes modal-slide-up-outer-leave {
  0% {
    opacity: 1;
  }

  75% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@include for-layout using($isDesktop) {
  @if not $isDesktop {
    .modal-slide-up-enter-active {
      animation-duration: $animationModalSlidesUpDuration;
    }

    .modal-slide-up-leave-active {
      animation-name: modal-slide-up-outer-leave;
      animation-duration: $animationModalSlidesUpLeaveDuration;
    }
  }
}
</style>
