/* eslint-disable import/no-relative-packages */
import type {
  AbstractApiMethod,
  GqlMiddlewareClient,
  GqlRequestMiddleware,
} from '@leon-hub/api-types';

import type { ApiMethod } from '../sdk/sdk';
import type { UseSdkMethod } from './types';

export function useApiMethod<T extends ApiMethod>(
  client: GqlMiddlewareClient,
  method: AbstractApiMethod,
  middleware: GqlRequestMiddleware<T>,
): UseSdkMethod {
  const stop = client.addMiddleware(method, middleware);

  return {
    stop,
  };
}
