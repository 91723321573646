import type { Ref } from 'vue';
import { computed, ref } from 'vue';

import { logger } from '@leon-hub/logging';
import { getSportradarLiveScoreConfiguration } from '@leon-hub/api-sdk';

import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';

import type {
  SportradarLiveScoreWidgetConfig,
  SportradarLiveScoreWidgetFrameConfig,
} from '../types';

interface UseSportradarLiveScoreFrameConfigProps {
  iframeUrl: Ref<Optional<string>>;
}

interface UseSportradarLiveScoreFrameConfigComposable {
  configFinal: Ref<Maybe<SportradarLiveScoreWidgetFrameConfig>>;
  fetchConfig(): Promise<void>;
}

export function useSportradarLiveScoreFrameConfigStoreComposable(
  props: UseSportradarLiveScoreFrameConfigProps,
): UseSportradarLiveScoreFrameConfigComposable {
  const { iframeUrl } = props;

  const api = useGraphqlClient();

  const config = ref<Maybe<SportradarLiveScoreWidgetConfig>>(null);

  const configFinal = computed<Maybe<SportradarLiveScoreWidgetFrameConfig>>(() => (
    config.value
      ? {
        ...config.value,
        iframeUrl: iframeUrl.value,
      }
      : null
  ));

  async function fetchConfig(): Promise<void> {
    try {
      if (config.value) { return; }

      config.value = await getSportradarLiveScoreConfiguration(
        api,
        (node) => node.queries.configContent.getSportRadarLiveScoreConfiguration,
        { options: {} },
      );
    } catch (error) {
      logger.error('An error occurred while updating Sportradar Live Score data', error);
    }
  }

  return {
    configFinal,
    fetchConfig,
  };
}
