import type { VInputInputEvent } from 'web/src/components/Input/types';

import type { VSearchInputEmits } from '../types';

export interface useVSearchInputComposable {
  onClear: () => void;
  onInput: (event: VInputInputEvent) => void;
}

export default function useVSearchInput(emit: VSearchInputEmits): useVSearchInputComposable {
  const onInput = (event: VInputInputEvent): void => {
    emit('input', event.target.value);
  };

  const onClear = (): void => {
    emit('clear', true);
  };

  return {
    onInput,
    onClear,
  };
}
