import type { Ref } from 'vue';
import { computed } from 'vue';

import type {
  RegionsSegmentPageListGroup,
  LiveRegionSegmentPageListGroup,
  PrematchRegionSegmentPageListGroup,
} from 'web/src/modules/sportline/submodules/segment/types';

interface UseCybersportRegionTabProps {
  group: Ref<RegionsSegmentPageListGroup>;
}

interface UseCybersportRegionTabComposable {
  liveGroup: Ref<Maybe<LiveRegionSegmentPageListGroup>>;
  prematchGroup: Ref<Maybe<PrematchRegionSegmentPageListGroup>>;
}

export function useCybersportRegionTab(
  props: UseCybersportRegionTabProps,
): UseCybersportRegionTabComposable {
  const { group } = props;

  const liveGroup = computed<Maybe<LiveRegionSegmentPageListGroup>>(() => (
    !!group.value && !group.value.isEmpty && !!group.value.liveElement
      ? group.value as LiveRegionSegmentPageListGroup
      : null
  ));

  const prematchGroup = computed<Maybe<PrematchRegionSegmentPageListGroup>>(() => (
    !!group.value && !group.value.isEmptyComing && !!group.value.prematchElement
      ? group.value as PrematchRegionSegmentPageListGroup
      : null
  ));

  return {
    liveGroup,
    prematchGroup,
  };
}
