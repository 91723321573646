<script lang="ts" setup>
import { toRef } from 'vue';

import VImage from 'web/src/components/Image/VImage/VImage.vue';
import {
  useBannerLocation,
  useBannerTexts,
} from 'web/src/modules/banners/components/composables';
import VSkeletonImage from 'web/src/components/Skeleton/VSkeletonImage/VSkeletonImage.vue';
import {
  ObjectFitOption,
  ObjectPosition,
} from 'web/src/components/Image/VImage/enums';
import CustomBannerButton from 'web/src/components/Button/CustomBannerButton/CustomBannerButton.vue';

import type { LobbyBannerItemProps } from './types';

const props = withDefaults(defineProps<LobbyBannerItemProps>(), {});

const bannerRef = toRef(props, 'item');

const {
  buttonText,
} = useBannerTexts(bannerRef);

const {
  href,
  onClick,
} = useBannerLocation(bannerRef);
</script>

<template>
  <div v-auto-id="'LobbyBannerItem'"
    :class="{
      [$style['lobby-banner']]: true,
    }"
  >
    <template v-if="item">
      <VImage
        v-bind="item.image"
        :object-fit="ObjectFitOption.COVER"
        :object-position="ObjectPosition.LEFT"
        :class="$style['lobby-banner__image']"
      />
      <div :class="$style['lobby-banner__informer']">
        <CustomBannerButton
          v-if="buttonText"
          :text="buttonText"
          :href="href"
          @click.stop.prevent="onClick"
        />
      </div>
    </template>
    <VSkeletonImage v-else />
  </div>
</template>

<style lang="scss" module>
@include for-layout using($isDesktop) {
  .lobby-banner {
    position: relative;
    width: 100%;
    min-width: 300px;
    height: if($isDesktop, 264px, 216px);
    overflow: hidden;
    border-radius: var(--BorderRadius);
    box-shadow: 0 8px 16px var(--ModalShadow);

    &__image {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      max-width: 100%;
      height: 100%;
    }

    &__informer {
      position: absolute;
      bottom: if($isDesktop, 40px, 32px);
      left: if($isDesktop, 32px, 16px);
      max-width: if($isDesktop, 364px, 100%);

      @if not ($isDesktop) {
        right: 24px;
      }
    }
  }
}
</style>
