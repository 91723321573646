import type { Ref } from 'vue';
import { computed, ref } from 'vue';

import { useI18n } from 'web/src/modules/i18n/composables';
import { usePluralFormLabelsCache } from 'web/src/modules/core/composables/language';
import type { BetlineLeagueMatchesStatistic } from 'web/src/modules/sportline/types/rest';

interface LeagueMatchesStatisticBlock {
  homeValue?: number;
  overallValue?: number;
  awayValue?: number;
  homeLabel?: string;
  overallLabel?: string;
  awayLabel?: string;
}

interface UseLeagueMatchesStatisticProps {
  leagueMatchesStatistic: Ref<BetlineLeagueMatchesStatistic>;
}

interface LeagueWinsStatistic {
  homeValue: number;
  homePercentage: number;
  awayValue: number;
  awayPercentage: number;
  drawsValue: number;
  drawsPercentage: number;
}

interface LeagueWinsStatisticLabels {
  home: string;
  draw: string;
  away: string;
}

interface UseLeagueMatchesStatisticComposable {
  matchesCount: Ref<number>;
  leagueWinsStatistic: Ref<LeagueWinsStatistic>;
  leagueWinsStatisticLabels: Ref<LeagueWinsStatisticLabels>;
  firstGoalTimeTotal: Ref<LeagueMatchesStatisticBlock>;
  firstGoalTimeAverage: Ref<LeagueMatchesStatisticBlock>;
  goalsTotal: Ref<LeagueMatchesStatisticBlock>;
  goalsAverage: Ref<LeagueMatchesStatisticBlock>;
  timeToGoal: Ref<LeagueMatchesStatisticBlock>;
}

function convertToLabel(value?: number): string {
  return String(value ?? '-');
}

function convertToAverageLabel(value?: number): string {
  if (value === undefined) { return '-'; }
  return value.toFixed(2);
}

function convertMinutesToLabel(value?: number): string {
  if (value === undefined) { return '-'; }
  return `${value.toFixed(1)}'`;
}

function convertSecondsToLabel(value?: number): string {
  if (value === undefined) { return '-'; }
  return `${(value / 60).toFixed(1)}'`;
}

export function useLeagueMatchesStatistic(
  props: UseLeagueMatchesStatisticProps,
): UseLeagueMatchesStatisticComposable {
  const { leagueMatchesStatistic } = props;

  const { $translate } = useI18n();

  const { getLabelFor: getLabelForWins } = usePluralFormLabelsCache([
    (count) => $translate('WEB2_MATCH_STATISTIC_MATCH_ONE', ref({ count: String(count) })),
    (count) => $translate('WEB2_MATCH_STATISTIC_MATCH_TWO', ref({ count: String(count) })),
    (count) => $translate('WEB2_MATCH_STATISTIC_MATCH_MANY', ref({ count: String(count) })),
  ]);

  const sum = computed(() => leagueMatchesStatistic.value.sum || {});
  const average = computed(() => leagueMatchesStatistic.value.average || {});
  const matchesCount = computed(() => sum.value.matchesCount_overall || 0);

  const leagueWinsStatistic = computed<LeagueWinsStatistic>(() => ({
    homeValue: (sum.value.win_home || 0),
    homePercentage: (average.value.win_home || 0) * 100,
    awayValue: (sum.value.win_away || 0),
    awayPercentage: (average.value.win_away || 0) * 100,
    drawsValue: matchesCount.value - (sum.value.win_overall || 0),
    drawsPercentage: (100 - (average.value.win_overall || 0) * 100),
  }));
  const leagueWinsStatisticLabels = computed<LeagueWinsStatisticLabels>(() => ({
    home: getLabelForWins(leagueWinsStatistic.value.homeValue).value,
    draw: getLabelForWins(leagueWinsStatistic.value.drawsValue).value,
    away: getLabelForWins(leagueWinsStatistic.value.awayValue).value,
  }));

  const firstGoalTimeTotal = computed<LeagueMatchesStatisticBlock>(() => ({
    homeValue: sum.value.firstGoalTime_home,
    overallValue: sum.value.firstGoalTime_overall,
    awayValue: sum.value.firstGoalTime_away,
    homeLabel: convertSecondsToLabel(sum.value.firstGoalTime_home),
    overallLabel: convertSecondsToLabel(sum.value.firstGoalTime_overall),
    awayLabel: convertSecondsToLabel(sum.value.firstGoalTime_away),
  }));
  const firstGoalTimeAverage = computed<LeagueMatchesStatisticBlock>(() => ({
    homeValue: average.value.firstGoalTime_home,
    overallValue: average.value.firstGoalTime_overall,
    awayValue: average.value.firstGoalTime_away,
    homeLabel: convertMinutesToLabel(average.value.firstGoalTime_home),
    overallLabel: convertMinutesToLabel(average.value.firstGoalTime_overall),
    awayLabel: convertMinutesToLabel(average.value.firstGoalTime_away),
  }));
  const goalsTotal = computed<LeagueMatchesStatisticBlock>(() => ({
    homeValue: sum.value.goals_home,
    overallValue: sum.value.goals_overall,
    awayValue: sum.value.goals_away,
    homeLabel: convertToLabel(sum.value.goals_home),
    overallLabel: convertToLabel(sum.value.goals_overall),
    awayLabel: convertToLabel(sum.value.goals_away),
  }));
  const goalsAverage = computed<LeagueMatchesStatisticBlock>(() => ({
    homeValue: average.value.goals_home,
    overallValue: average.value.goals_overall,
    awayValue: average.value.goals_away,
    homeLabel: convertToAverageLabel(average.value.goals_home),
    overallLabel: convertToAverageLabel(average.value.goals_overall),
    awayLabel: convertToAverageLabel(average.value.goals_away),
  }));
  const timeToGoal = computed<LeagueMatchesStatisticBlock>(() => ({
    homeValue: sum.value.timeToGoal_home,
    overallValue: sum.value.timeToGoal_overall,
    awayValue: sum.value.timeToGoal_away,
    homeLabel: convertMinutesToLabel(sum.value.timeToGoal_home),
    overallLabel: convertMinutesToLabel(sum.value.timeToGoal_overall),
    awayLabel: convertMinutesToLabel(sum.value.timeToGoal_away),
  }));

  return {
    matchesCount,
    leagueWinsStatistic,
    leagueWinsStatisticLabels,
    firstGoalTimeTotal,
    firstGoalTimeAverage,
    goalsTotal,
    goalsAverage,
    timeToGoal,
  };
}
