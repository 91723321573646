<script setup lang="ts">
import { computed } from 'vue';

import { AlertIconName, IconName } from '@leon-hub/icons';

import ConfirmModal from 'web/src/modules/dialogs/views/ConfirmModal/ConfirmModal.vue';
import type {
  PaymentsDepositsOtherSuccessModalProps,
  PaymentsDepositsOtherSuccessModalEmits,
} from 'web/src/modules/payments/components/PaymentsDepositsOtherSuccessModal/types';
import { DialogAction } from 'web/src/modules/dialogs/enums';
import type { ConfirmDialog } from 'web/src/modules/dialogs/types';
import { JumbotronIconKind } from 'web/src/components/Jumbotron';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { ModalWidth } from 'web/src/components/Modal/enums';
import { useI18n } from 'web/src/modules/i18n/composables';
import type { ConfirmModalProps } from 'web/src/modules/dialogs/views/ConfirmModal/types';

const props = defineProps<PaymentsDepositsOtherSuccessModalProps>();
const emit = defineEmits<PaymentsDepositsOtherSuccessModalEmits>();

const { $translate } = useI18n();

function onButtonClick({ action }: { action: DialogAction }): void {
  if (action === DialogAction.MODAL_CLOSE) {
    emit('close');
  }
}

const modalProperties = computed<ConfirmModalProps>(() => {
  const modalProps: Partial<ConfirmDialog> = {
    ...(null ? {
      alertIcon: AlertIconName.Success,
    } : {
      iconName: IconName.CHECK_OUTLINE,
    }),
    iconKind: JumbotronIconKind.SUCCESS,
    iconName: IconName.CHECK_OUTLINE,
    title: $translate('WEB2_YOUR_DEPOSIT_IS').value,
    isProfileLayout: !!'1' && props.isInProfile,
    buttons: [
      {
        action: DialogAction.MODAL_CLOSE,
        kind: null ? 'primary' : ButtonKind.BASE,
        label: $translate('JSP_PCL_FBOT_CLOSE').value,
      },
    ],
  };

  if (!props.isInProfile) {
    return {
      isAlert: true,
      modal: {
        ...modalProps,
        isCentered: false,
        width: ModalWidth.SMALL,
      },
    };
  }
  return {
    modal: {
      ...modalProps,
      isInnerModal: !!'1',
      fullHeight: true,
    },
  };
});
</script>

<template>
  <ConfirmModal v-auto-id="'PaymentsDepositsOtherSuccessModal'"
    v-bind="modalProperties"
    @button-click="onButtonClick"
    @close="emit('close')"
  >
    <div :class="$style['deposits-success__text']">
      {{ text }}
    </div>
  </ConfirmModal>
</template>

<style lang="scss" module>
.deposits-success {
  &__text {
    @include paymentModalOtherText;
  }
}
</style>
