import type { Ref } from 'vue';
import {
  nextTick,
  ref,
} from 'vue';

import { nextAnimationFrame } from '@leon-hub/html-utils';

export interface PostponedContentLoadComposable {
  isMainContentLoaded: Ref<boolean>;
  loadMainContent(): Promise<void>;
}

export function usePostponedContentLoad(): PostponedContentLoadComposable {
  const isMainContentLoaded = ref(false);

  async function loadMainContent(): Promise<void> {
    await nextTick();
    await nextAnimationFrame();
    isMainContentLoaded.value = true;
  }

  return {
    isMainContentLoaded,
    loadMainContent,
  };
}
