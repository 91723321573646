<script lang="ts" setup>
import { toRef } from 'vue';

import type { BetlineLeaguePlayoffRoundBlock } from 'web/src/modules/sportline/types/rest';
import StatisticTeamLogo from 'web/src/modules/sportline/submodules/event-statistic/components/StatisticTeamLogo.vue';

import { useLeaguePlayoffMatchesBlock } from './useLeaguePlayoffMatchesBlock';

interface LeaguePlayoffMatchesBlockDetailsProps {
  block: BetlineLeaguePlayoffRoundBlock;
}

interface LeaguePlayoffMatchesBlockDetailsEmits {
  (e: 'require-close'): void;
}

const props = defineProps<LeaguePlayoffMatchesBlockDetailsProps>();
const emit = defineEmits<LeaguePlayoffMatchesBlockDetailsEmits>();

const block = toRef(props, 'block');

const {
  matchScores,
  matchesHistory,
  participants,
} = useLeaguePlayoffMatchesBlock({ block });

function onBlockClick(): void {
  emit('require-close');
}
</script>

<template>
  <div v-auto-id="'LeaguePlayoffMatchesBlockDetalis'"
    :class="$style['match-block-details']"
    @click="onBlockClick"
  >
    <div>
      <div
        v-for="(participant, participantIndex) in participants"
        :key="participantIndex"
        :class="{
          [$style['match-block-details-team']]: true,
          [$style['match-block-details-team--winner']]: participant.winner,
        }"
      >
        <template v-if="participant.team">
          <div :class="$style['match-block-details-team__identity']">
            <StatisticTeamLogo
              :class="$style['match-block-details-team__logo']"
              :team="participant.team"
            />

            <div :class="$style['match-block-details-team__name']">
              {{ participant.team.name }}
            </div>
          </div>

          <div
            v-for="(scores, scoresKey) in matchScores"
            :key="scoresKey"
            :class="$style['match-block-details-team__result-score']"
          >
            {{ participant.team.id ? (scores[participant.team.id] || '-') : '-' }}
          </div>
        </template>
      </div>
    </div>

    <div :class="$style['match-block-details-team-history']">
      <div
        v-for="(item, itemIndex) in matchesHistory"
        :key="itemIndex"
        :class="$style['match-block-details-team-history__item']"
      >
        <div
          :class="[
            $style['match-block-details-team-history__item-content'],
            $style['match-block-details-team-history__date']
          ]"
        >
          {{ item.date }}
        </div>
        <div :class="$style['match-block-details-team-history__item-content']">
          <span
            v-for="(name, nameIndex) in item.names"
            :key="nameIndex"
            :class="$style['match-block-details-team-history__name']"
          >{{ name }}</span>
        </div>
        <div :class="$style['match-block-details-team-history__item-content']">
          <span
            v-for="(score, scoreIndex) in item.scores"
            :key="scoreIndex"
            :class="$style['match-block-details-team-history__score']"
          >{{ score || '&nbsp;' }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>
.match-block-details {
  width: 220px;
  cursor: pointer;
  background: var(--Highlight);
  border: 1px solid var(--Highlight);
  border-radius: 5px;
  box-shadow: 0 8px 16px var(--ModalShadow);
}

.match-block-details-team {
  @include medium\14\16\025;

  position: relative;
  display: flex;
  align-items: center;
  padding: 8px;
  overflow: hidden;
  color: var(--TextSecondary);
  background-color: var(--Highlight);

  &__identity {
    display: flex;
    flex-grow: 1;
    align-items: center;
    overflow: hidden;
  }

  &__logo {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    overflow: hidden;
  }

  &__name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__result-score {
    flex: 0 0 20px;
    width: 20px;
    overflow: hidden;
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &--winner {
    color: var(--TextDefault);
  }
}

.match-block-details-team-history {
  @include medium\13\16;

  display: grid;
  grid-template-columns: 1fr;
  gap: 1px;

  &__item {
    display: flex;
    justify-content: space-between;
    padding: 8px;
    background-color: var(--Layer2);
  }

  &__item-content {
    flex-basis: 100%;
    overflow: hidden;
    text-align: center;
    white-space: nowrap;

    &:first-child {
      flex-basis: 100px;
      text-align: left;
    }

    &:last-child {
      flex-basis: 80px;
      text-align: right;
    }
  }

  &__name {
    color: var(--TextDefault);
    text-transform: uppercase;
  }

  &__date,
  &__score {
    color: var(--TextSecondary);
  }

  &__name,
  &__score {
    &:not(&:first-child):before {
      content: ' - ';
    }
  }
}
</style>
