import { doChangeLanguage } from '@leon-hub/api-sdk';

import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';

import type { ChangeLanguageDocument } from '../types';

export default function changeLanguage(languageTag: string): Promise<ChangeLanguageDocument> {
  const apiClient = useGraphqlClient();

  return doChangeLanguage(apiClient, (node) => node.mutations.system.changeLanguage, {
    options: {
      languageTag,
    },
  });
}
