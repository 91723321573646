import type { Ref } from 'vue';
import { toRef, computed } from 'vue';

import { useSportlineSportsStore } from 'web/src/modules/sportline/submodules/sports/store';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import type { SportElement } from 'web/src/modules/sportline/types';

interface UseSportsZeroMarginTabProps {
  isActive: Ref<boolean>;
}

interface UseSportsZeroMarginTabComposable {
  isMarketTypesSelectionEnabled: Ref<boolean>;
  isReadyToDisplayContent: Ref<boolean>;
  zeroMarginEventsList: Ref<SportElement[]>;
  hasElementsForCurrentFilter: Ref<boolean>;
  isNeedRedirectFromTab: Ref<boolean>;
}

export function useSportsZeroMarginTab(
  props: UseSportsZeroMarginTabProps,
): UseSportsZeroMarginTabComposable {
  const { isActive } = props;

  const sportlineSettingsStore = useSportlineSettingsStore();
  const isMarketTypesSelectionEnabled = toRef(sportlineSettingsStore, 'isMarketTypesSelectionEnabled');

  const sportlineSportsStore = useSportlineSportsStore();
  const rawZeroMarginEventsList = toRef(sportlineSportsStore, 'rawZeroMarginEventsList');
  const isReadyToDisplayContent = toRef(sportlineSportsStore, 'isZeroMarginListLoaded');
  const zeroMarginEventsList = toRef(sportlineSportsStore, 'zeroMarginEventsList');

  const hasElementsForCurrentFilter = computed<boolean>(() => !!zeroMarginEventsList.value?.length);
  const isNeedRedirectFromTab = computed(() => (
    isActive.value && !rawZeroMarginEventsList.value?.length
  ));

  return {
    isMarketTypesSelectionEnabled,
    isReadyToDisplayContent,
    zeroMarginEventsList,
    hasElementsForCurrentFilter,
    isNeedRedirectFromTab,
  };
}
