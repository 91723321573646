import { useModulesSyncStore } from '../store/useModulesSyncStore';

interface UseForceSyncStateAllComposable {
  forceSyncStateAll(): void;
}

export function useForceSyncStateAll(
): UseForceSyncStateAllComposable {
  const { forceSyncStateAll } = useModulesSyncStore();
  return { forceSyncStateAll };
}
