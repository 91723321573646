import type {
  VirtualListElement,
  VirtualListEventElement,
  VirtualListLeagueHeadlineElement,
  VirtualListSportHeadlineElement,
  VirtualListNoEventsElement,
} from './types';
import { VirtualListElementType } from './enums';

export function isEventElement(element: VirtualListElement): element is VirtualListEventElement {
  return element.type === VirtualListElementType.Event;
}

export function isLeagueHeadlineElement(element: VirtualListElement): element is VirtualListLeagueHeadlineElement {
  return element.type === VirtualListElementType.LeagueHeadline;
}

export function isSportHeadlineElement(element: VirtualListElement): element is VirtualListSportHeadlineElement {
  return element.type === VirtualListElementType.SportHeadline;
}

export function isNoEventsElement(element: VirtualListElement): element is VirtualListNoEventsElement {
  return element.type === VirtualListElementType.NoEvents;
}
