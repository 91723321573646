import type { SportlinePageIdentifier as SportEventsPageIdentifier } from 'web/src/modules/sportline/types/navigation';

import isSportEventsPageIdentifier from './isSportEventsPageIdentifier';

export default function isValidSportEventsPageIdentifier(value: unknown): value is SportEventsPageIdentifier {
  if (!isSportEventsPageIdentifier(value)) {
    return false;
  }

  const {
    leagueId,
    regionId,
    sportEventId,
  } = value;

  const validRegionId = !regionId || !/\D/g.test(regionId);
  const validLeagueId = !leagueId || !/\D/g.test(leagueId);
  const validSportEventId = !sportEventId || !/\D/g.test(sportEventId);

  return validRegionId && validLeagueId && validSportEventId;
}
