import type { Ref } from 'vue';
import { computed } from 'vue';

import type { Sport, SportlineBackground } from 'web/src/modules/sportline/types';
import useSportlineBackgroundColor from 'web/src/modules/sportline/composables/representation/useSportlineBackgroundColor';

interface UseSportBackgroundColorProps {
  sport: Ref<Maybe<Sport | undefined>>;
}

interface UseSportBackgroundColorComposable {
  backgroundColor: Ref<Maybe<string>>;
}

export function useSportBackgroundColor(
  props: UseSportBackgroundColorProps,
): UseSportBackgroundColorComposable {
  const background = computed<Maybe<SportlineBackground>>(() => props.sport.value?.representation.background ?? null);
  const { backgroundColor } = useSportlineBackgroundColor({ background });

  return {
    backgroundColor,
  };
}
