import type { PhoneInput } from '@leon-hub/api-sdk';
import { LoginType } from '@leon-hub/api-sdk';

export function phoneToString(phone: Maybe<PhoneInput>): string {
  if (phone) {
    const { prefix, suffix } = phone;

    return ['+', prefix, suffix].join('');
  }

  return '';
}

export function getLoginType(value: string): LoginType {
  if (value.includes('@')) {
    return LoginType.EMAIL;
  }
  // fix it after refactoring login module on leonbets
  // optional +, prefix, suffix
  if (value.length >= 10) {
    return LoginType.PHONE;
  }

  if (process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED) {
    return LoginType.EMAIL;
  }

  return LoginType.ACCOUNT;
}
