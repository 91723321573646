import type { WatchStopHandle, Ref } from 'vue';
import { ref, watch, computed } from 'vue';

import type { MaybeComputedElementRef } from '../refs';
import { unrefElement } from '../refs';
import { onComponentDeactivated, onComponentActivated } from '../lifecycle';

type ResizeObserverCallbackFunc = (entries: ResizeObserverEntry[]) => (void | Promise<void>);

interface ResizeObserverComposable {
  isEnabled: Ref<boolean>;
  start(): void;
  stop(): void;
}

export function useControllableResizeObserver(
  element: MaybeComputedElementRef,
  callback: ResizeObserverCallbackFunc,
  options?: ResizeObserverOptions,
): ResizeObserverComposable {
  if (typeof ResizeObserver === 'undefined') {
    return {
      isEnabled: ref(false),
      start: () => {},
      stop: () => {},
    };
  }

  let observer: ResizeObserver | undefined;
  let stopWatch: WatchStopHandle | undefined;

  const isEnabled = ref(false);

  const target = computed(() => unrefElement(element));

  function disconnectObserver(): void {
    if (observer) {
      observer.disconnect();
      observer = undefined;
    }
  }

  function connectObserver(): void {
    if (!target.value) { return; }

    observer = new ResizeObserver((entries) => {
      void callback(entries);
    });

    observer.observe(target.value, options);
  }

  function start(): void {
    stopWatch = stopWatch ?? watch(target, (newValue) => {
      if (newValue) {
        connectObserver();
      } else {
        disconnectObserver();
      }
    }, { immediate: true });
    isEnabled.value = true;
  }

  function stop(): void {
    disconnectObserver();
    stopWatch?.();
    stopWatch = undefined;
    isEnabled.value = false;
  }

  return {
    isEnabled,
    start,
    stop,
  };
}

export function useLifecycleResizeObserver(
  element: MaybeComputedElementRef,
  callback: ResizeObserverCallbackFunc,
  options?: ResizeObserverOptions,
): void {
  const { start, stop } = useControllableResizeObserver(element, callback, options);
  onComponentActivated(start);
  onComponentDeactivated(stop);
}
