import { assert, isValidJsonSchema } from '@leon-hub/guards';

import type { FormValidatorErrorValue } from '../types';

interface ErrorFields {
  error?: string[];
  errorRight?: string[];
}

function isArrayWithStringsAndNullsOptional(value: unknown): value is string[] | undefined {
  if (value !== undefined) {
    return isValidJsonSchema(value, {
      type: 'array',
      items: {
        anyOf: [
          {
            type: 'string',
          },
          {
            type: 'null',
          },
        ],
      },
    });
  }

  return true;
}

function getErrorArrayFields(options?: Dictionary<unknown>, errorValue?: FormValidatorErrorValue): ErrorFields {
  const passedErrorValue = options?.error || errorValue;
  if (!passedErrorValue) {
    return {};
  }
  const isRightHint = !!options?.isHintRight;
  const computedErrorValue = typeof passedErrorValue === 'string' ? [passedErrorValue] : passedErrorValue;
  assert(isArrayWithStringsAndNullsOptional(computedErrorValue), `Unexpected error type: ${typeof computedErrorValue}`);

  return {
    [isRightHint ? 'errorRight' : 'error']: computedErrorValue,
  };
}

export default getErrorArrayFields;
