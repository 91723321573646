import { assert } from '@leon-hub/guards';

import getOnlyDigitsFromString from './getOnlyDigitsFromString';

export default function handleUnmaskedPhoneInput(
  event: InputEvent,
  currentValue: string,
  maxSymbols?: number,
): string {
  const { target } = event;
  assert(target instanceof HTMLInputElement);
  const eventValue = target.value;
  const valueIsValid = getOnlyDigitsFromString(eventValue) === eventValue;
  if (!valueIsValid || (maxSymbols && eventValue.length > maxSymbols)) {
    target.value = currentValue;
    return currentValue;
  }
  return eventValue;
}
