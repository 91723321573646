import isMatchedArrays from 'web/src/utils/array/isMatchedArrays';

import type { SlipEntry, SlipEntryId } from '../types';
import getSlipEntryId from '../../../utils/getSlipEntryId';

export default function isSlipEntriesMatchSelected(slipEntries: SlipEntry[], selectedEntriesIds: SlipEntryId[])
  : boolean {
  if (!slipEntries?.length) {
    return false;
  }
  const entriesIds = slipEntries.map((entry) => getSlipEntryId(entry));
  return isMatchedArrays(entriesIds, selectedEntriesIds);
}
