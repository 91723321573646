import { isOptionalString } from '@leon-hub/guards';

import { LexisNexisSessionStorage } from '../services/LexisNexisSessionStorage';
import {
  LEXIS_NEXIS_STORAGE_NAMESPACE_SESSION,
} from '../constants';

export const getLexisNexisSessionStorage = (customerLogin: string): LexisNexisSessionStorage => new LexisNexisSessionStorage({
  id: `lex-nex:${customerLogin}:${LEXIS_NEXIS_STORAGE_NAMESPACE_SESSION}`,
  guard: isOptionalString,
});
