import { watch, toRef } from 'vue';

import useRootStore from '../../store/useRootStore';

interface Options {
  onlyIfNotLoaded?: boolean;
}

interface UseOnAppContentLoadedCallback {
  performOnLoad(callback: Function, options?: Options): void;
}

export default function useOnAppContentLoadedCallback(): UseOnAppContentLoadedCallback {
  const isAppMainContentLoaded = toRef(useRootStore(), 'isAppMainContentLoaded');

  function performOnLoad(callback: Function, { onlyIfNotLoaded }: Options = {}): void {
    if (isAppMainContentLoaded.value) {
      if (!onlyIfNotLoaded) {
        callback();
      }
      return;
    }

    const watcher = watch(isAppMainContentLoaded, (isLoaded) => {
      if (isLoaded) {
        callback();
        watcher();
      }
    });
  }

  return {
    performOnLoad,
  };
}
