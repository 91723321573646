import type { DisplayedTimeLeft, ValueOfTimeLeft } from 'web/src/utils/countdown';
import type { I18nFunctionReactive } from 'web/src/modules/i18n/types';

export default function getDays(
  timeLeft: ValueOfTimeLeft | null,
  displayedTimeLeft: DisplayedTimeLeft | null,
  $t: I18nFunctionReactive,
): string {
  if (!timeLeft || !displayedTimeLeft) {
    return '';
  }

  const { days } = timeLeft;

  if (days <= 0) {
    return '';
  }

  return `${String(days)} ${$t('WEB2_DAYS_EXTRA_SHORT').value}.`;
}
