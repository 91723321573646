<script lang="ts" setup>
import { useVBrand } from './composables';
import type { VBrandProps } from './types';
import { prepareUseLogoProps } from './composables/utils';

interface VBrandEmits {
  (e: 'click', event: MouseEvent): void;
}

const props = withDefaults(defineProps<VBrandProps>(), {
  alt: '',
});
const emit = defineEmits<VBrandEmits>();

const {
  logoUrl,
} = useVBrand(prepareUseLogoProps(props));

function onClick(event: MouseEvent): void {
  emit('click', event);
}
</script>

<template>
  <img v-auto-id="'VBrand'"
    :src="logoUrl"
    :alt="alt"
    :class="{
      [$style['brand']]: true,
      [$style['brand--short']]: isSimplifiedLogo,
      [$style['brand--fix-size']]: isFixSize,
    }"
    @click="onClick"
  >
</template>

<style lang="scss" module>
.brand {
  width: $brandLogoWidthFullPhone;
  height: $brandLogoHeightFullPhone;

  @include screen-desktop-small-min {
    width: $brandLogoWidthFullDesktopSmall;
    height: $brandLogoHeightFullDesktopSmall;
  }

  @include screen-desktop-large-min {
    width: $brandLogoWidthFullDesktopLarge;
    height: $brandLogoHeightFullDesktopLarge;
  }

  &--short {
    width: $brandLogoWidthShortPhone;
    height: $brandLogoHeightShortPhone;

    @include screen-desktop-small-min {
      width: $brandLogoWidthShortDesktopSmall;
      height: $brandLogoHeightShortDesktopSmall;
    }

    @include screen-desktop-large-min {
      width: $brandLogoWidthShortDesktopLarge;
      height: $brandLogoHeightShortDesktopLarge;
    }
  }

  &--fix-size {
    width: $brandLogoWidthFixSize;
    height: $brandLogoHeightFixSize;

    @include screen-desktop-small-min {
      width: $brandLogoWidthFixSize;
      height: $brandLogoHeightFixSize;
    }

    @include screen-desktop-large-min {
      width: $brandLogoWidthFixSize;
      height: $brandLogoHeightFixSize;
    }
  }
}
</style>
