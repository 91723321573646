import type { Ref } from 'vue';
import { toRef, onUpdated } from 'vue';

import type { PaymentBonusArray } from 'web/src/modules/bonuses/store/types/types';
import { DEPOSIT_BONUS_NONE_SELECTED } from 'web/src/modules/payments/constants';
import useBonusStore from 'web/src/modules/bonuses/store/useBonusStore';

interface PaymentsBonusesComposable {
  depositBonusList: Ref<PaymentBonusArray>;
  selectedBonus: Ref<number>;
  isNoneSelected: Ref<boolean>;
  isPaymentBonuses: Ref<boolean>;
  isChoosingBonusBeforeDeposit: Ref<boolean>;
}
export default function usePaymentsBonuses(): PaymentsBonusesComposable {
  const bonusStore = useBonusStore();

  const depositBonusList = toRef(() => bonusStore.paymentBonuses ?? []);
  const isPaymentBonuses = toRef(() => depositBonusList.value.length > 0);
  const selectedBonus = toRef(() => bonusStore.selectedBonus);
  const isNoneSelected = toRef(() => bonusStore.isNoneSelected);
  const isChoosingBonusBeforeDeposit = toRef(() => bonusStore.isChoosingBonusBeforeDeposit);

  function updated() {
    if (!depositBonusList.value.length) bonusStore.setBonusSelection(DEPOSIT_BONUS_NONE_SELECTED);
  }

  onUpdated(updated);

  return {
    depositBonusList,
    isPaymentBonuses,
    selectedBonus,
    isNoneSelected,
    isChoosingBonusBeforeDeposit,
  };
}
