import type { RouteRecordName } from 'vue-router';

import type { NavigationItem } from '@leon-hub/navigation-config';
import { IconName, IconSize } from '@leon-hub/icons';

import type { SidebarMenu, SidebarMenuL1 } from 'web/src/components/SidebarMenu/types';
import { PromotionItemIds, PromotionItemType } from 'web/src/modules/promotions/types';
import type { AppVueRouter } from 'web/src/modules/core/services/router/types';

import isActiveCategory from './isActiveCategory';
import isActiveSubCategory from './isActiveSubCategory';
import mapBadges from './mapBadges';
import resolveCategoryLink from './resolveCategoryLink';
import resolveSubCategoryLink from './resolveSubCategoryLink';

export default function getMenu(
  promotionCategoriesNavigationItems: NavigationItem[],
  router: AppVueRouter,
  currentRouteName: RouteRecordName | null | undefined,
  actionUrl: string | string[],
  categoryId: string | string[],
  participationNumber: number,
): SidebarMenu {
  return promotionCategoriesNavigationItems.map((category): SidebarMenuL1 => {
    const L1Key = `level1-spoiler-${category.id}`;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
    const canOpen = category.id !== PromotionItemIds.BONUS_WALLET && category.type !== PromotionItemType.PROMOTION
            && !!category.children?.length;

    return {
      key: L1Key,
      id: category.id,
      location: resolveCategoryLink(category, router),
      name: category.caption || '',
      canOpen,
      active: isActiveCategory(category, currentRouteName, categoryId),
      menu: category.children?.map((subcategory) => {
        const L2Key = `level2-spoiler-${subcategory.id}`;

        return {
          key: L2Key,
          id: subcategory.id,
          location: resolveSubCategoryLink(subcategory, router),
          name: subcategory.caption || '',
          active: isActiveSubCategory(subcategory, actionUrl, categoryId),
          suffix: subcategory.props && subcategory.props.isHot ? {
            size: IconSize.SIZE_16,
            name: IconName.FIRE,
          } : undefined,
          menu: [],
        };
      }) ?? [],
      badge: mapBadges(category, participationNumber),
    };
  });
}
