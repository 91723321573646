<script lang="ts" setup>
import { computed } from 'vue';

import { IconSize } from '@leon-hub/icons';

import type { VIconProps } from '@components/v-icon';
import { VIcon } from '@components/v-icon';

import { getIconNameBySportName } from './utils';
import type { SportIconProps } from './types';

interface SportIconEmits {
  (e: 'click', event: PointerEvent): void;
}

const props = withDefaults(defineProps<SportIconProps>(), {
  size: IconSize.SIZE_20,
});
const emit = defineEmits<SportIconEmits>();

const iconProperties = computed<VIconProps>(() => ({
  name: getIconNameBySportName(props.sportName),
  size: props.size,
}));

function onClick(event: PointerEvent): void {
  emit('click', event);
}
</script>

<template>
  <VIcon v-auto-id="'SportIcon'"
    v-bind="iconProperties"
    @click="onClick"
  />
</template>
