import { MarketStatus } from '@leon-hub/api-sdk';

import type { SlipEntry } from '../types';

export default function getOnlyAvailableSlipEntries(source: SlipEntry[]): SlipEntry[] {
  if (!source || !Array.isArray(source)) {
    return [];
  }
  return source.filter((entry) => (
    entry && (
      !entry.marketStatus
      || entry.marketStatus === MarketStatus.PRICE_DOWN
      || entry.marketStatus === MarketStatus.PRICE_UP)
  ));
}
