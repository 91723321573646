import { computed } from 'vue';
import type { Ref } from 'vue';

import type {
  FormErrors,
} from '../../../types';
import {
  getExternalErrorsRelatedToCurrentData,
} from './utils/formExternalErrorsUtils';
import type {
  UseExternalErrorsProps,
  UseExternalErrorsOutput,
} from './types';

export default function useExternalErrors(
  {
    customErrors, uiSchema, validationDisabled, formDataMap, schema,
  }: UseExternalErrorsProps,
): UseExternalErrorsOutput {
  // LEONWEB-6322 do not validate hidden/disabled fields
  const ignoredFields: Ref<Set<string>> = computed(() => {
    const allFields = uiSchema.value.fields ?? {};
    const result = new Set<string>();
    for (const fieldName of Object.keys(allFields)) {
      const currentField = allFields[fieldName];
      if (currentField.hidden || currentField.disabled) {
        result.add(fieldName);
      }
    }
    return result;
  });

  const externalErrors: Ref<FormErrors> = computed(
    () => {
      if (validationDisabled) {
        return {};
      }
      return getExternalErrorsRelatedToCurrentData(
        formDataMap.value,
        schema.value,
        customErrors.value,
        ignoredFields.value,
      );
    },
  );

  return {
    externalErrors,
  };
}
