<script lang="ts" setup>
import { computed } from 'vue';

import ConfirmModal from 'web/src/modules/dialogs/views/ConfirmModal/ConfirmModal.vue';
import type {
  BaseModalEmits,
  ConfirmDialog,
} from 'web/src/modules/dialogs/types';
import { useBaseModalActions } from 'web/src/modules/dialogs/composables';
import { DialogComponent } from 'web/src/modules/dialogs/enums';

import type { BonusWithdrawalModalProps } from './types';

const props = withDefaults(defineProps<BonusWithdrawalModalProps>(), {});
const emit = defineEmits<BaseModalEmits>();

const { onClose, onButtonClick } = useBaseModalActions(emit);

const confirmModalProps = computed<ConfirmDialog>(() => {
  const {
    money, ...modalProps
  } = props.modal;

  return {
    ...modalProps,
    modalComponent: DialogComponent.ConfirmModal,
  };
});
</script>

<template>
  <ConfirmModal v-auto-id="'BonusWithdrawalModal'"
    :modal="confirmModalProps"
    :is-alert="isAlert"
    :class="$style['bonus-withdrawals']"
    @close="onClose"
    @button-click="onButtonClick"
  >
    <div :class="$style['bonus-withdrawals__money']">
      {{ modal.money }}
    </div>
  </ConfirmModal>
</template>

<style lang="scss" module>
.bonus-withdrawals {
  &__money {
    @include medium\18\20;

    color: var(--BrandDefault);
  }
}
</style>
