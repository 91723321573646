<script setup lang="ts">
import { toRef } from 'vue';

import type { SportlineTabEmits } from 'web/src/modules/sportline/components/SportlineTab/types';
import LeaguesList from 'web/src/modules/sportline/submodules/sports-list/views/leagues-list/LeaguesList.vue';
import RegionSpoiler from 'web/src/modules/sportline/submodules/sports-list/views/regions-list/RegionSpoiler.vue';
import SportNoResults from 'web/src/modules/sportline/components/NoEvents/SportNoResults.vue';

import { useSportsSportTab } from '../composables/tabs/useSportsSportTab';

interface SportsSportTabProps {
  sportId: string;
  isChildRoute?: boolean;
}

const props = defineProps<SportsSportTabProps>();
const emit = defineEmits<SportlineTabEmits>();

const sportId = toRef(props, 'sportId');
const isChildRoute = toRef(props, 'isChildRoute', false);

const {
  sportElement,
  topLeagues,
  isCyberSportPage,
  hasElementsForCurrentFilter,
  currentDateTimeFilter,
  setOpenRegionState,
  isRegionOpen,
} = useSportsSportTab({ sportId });
// @see LEONWEB-5037 do not redirect from empty sports tabs
</script>

<template>
  <div v-auto-id="'SportsSportTab'">
    <LeaguesList
      v-if="sportElement && topLeagues.length > 0"
      :sport="sportElement.sport"
      :leagues="topLeagues"
      :date-time-filter="currentDateTimeFilter"
      is-top
    />

    <template v-if="sportElement && hasElementsForCurrentFilter">
      <div :class="$style['sport-header']">
        {{ isCyberSportPage ? $t('WEB2_ESPORT_GAMES_ALL_TITLE') : $t('WEB2_SPORTS_REGIONS_ALL_TITLE') }}
      </div>

      <div :class="$style['regions']">
        <RegionSpoiler
          v-for="regionElement in sportElement.regions"
          :key="`level1-spoiler-${regionElement.region.id}-${isChildRoute}`"
          :sport-element="sportElement"
          :region-element="regionElement"
          :is-open="!!process.env.VUE_APP_PRERENDER || isRegionOpen(regionElement.region.id)"
          :date-time-filter="currentDateTimeFilter"
          :is-cyber-sport-page="isCyberSportPage"
          :is-child-route="isChildRoute"
          @change-open-state="setOpenRegionState(regionElement.region.id, $event)"
        />
      </div>
    </template>
    <SportNoResults
      v-else
      can-reset-filter
      @reset="emit('reset-filter')"
    />
  </div>
</template>

<style module lang="scss">
.sport-header {
  @include medium\18\20;

  padding: 18px 0 18px 8px;
  color: var(--TextDefault);
  background-color: var(--Layer0);
}

.regions {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: stretch;
}
</style>
