import { AbstractError, type AbstractErrorOptions } from '@leon-hub/errors';

import { SandboxApiErrorCode } from './SandboxApiErrorCode';

export class SandboxApiError extends AbstractError {
  constructor(options: Omit<AbstractErrorOptions, 'code'>) {
    super({
      ...options,
      code: new SandboxApiErrorCode('SANDBOX_API_ERROR_CODE'),
    });
  }
}
