import type { CombinedSlipEntryInfo } from '../types';

export default function getMultiSinglesCommonMaxStake(availableItems: CombinedSlipEntryInfo[]): number {
  const limits = availableItems.map((entry) => entry.metaInfo?.maxStake || 0);
  if (!limits.length) {
    return 0;
  }
  // yep, Math.min here
  return Math.min(...limits);
}
