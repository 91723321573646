import type { ComputedRef } from 'vue';

import type { VReCaptchaProperties, VReCaptchaOptions } from 'web/src/modules/captcha/components/VReCaptcha/types';
import type { ReCaptchaSize } from 'web/src/modules/captcha/store/enums';

export default function getSettledReCaptchaOptions(
  props: VReCaptchaProperties,
  size: ComputedRef<ReCaptchaSize>,
): VReCaptchaOptions {
  return {
    sitekey: props.siteKey ? props.siteKey : '',
    size: size.value,
    theme: props.theme,
  };
}
