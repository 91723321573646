import { LobbyItemType } from '@leon-hub/api-sdk';

import type {
  LobbyItemSdk,
  LobbyTopWinnersItemLoading,
} from 'web/src/modules/lobby/types';

export default function isLobbyTopWinnersItem(input: LobbyItemSdk): input is LobbyTopWinnersItemLoading {
  return input.typeId === LobbyItemType.TOP_WINNERS;
}
