import { SportFilterKickoffPeriod } from '@leon-hub/api-sdk';

import type { SportDateTimeFilter } from 'web/src/modules/sportline/submodules/navigation/store/types';

export const defaultListFilter: SportDateTimeFilter = {
  activeKickoffPeriod: SportFilterKickoffPeriod.ALL,
};

// @TODO move to vars
export const colorlessSportIconColor = '#BBBBBB';

export * from './core';
export * from './RegionFamilyToIconMapping';
