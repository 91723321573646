import type { JSONSchema } from '@leon-hub/form-utils';

/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */

export enum FormSchemaTypes {
  String = 'string',
  Number = 'number',
  Integer = 'integer',
  Object = 'object',
  Array = 'array',
  Boolean = 'boolean',
  Null = 'null',
}

export const isFormTypeString = (property: JSONSchema): boolean => `${property?.type}`
  .toLowerCase() === FormSchemaTypes.String;

export const isFormTypeNumber = (property: JSONSchema): boolean => `${property?.type}`
  .toLowerCase() === FormSchemaTypes.Number;

export const isFormTypeBoolean = (property: JSONSchema): boolean => `${property?.type}`
  .toLowerCase() === FormSchemaTypes.Boolean;

export const isFormTypeArray = (property: JSONSchema): boolean => `${property?.type}`
  .toLowerCase() === FormSchemaTypes.Array;

export const isFormTypeObject = (property: JSONSchema): boolean => `${property?.type}`
  .toLowerCase() === FormSchemaTypes.Object;

export const isFormPrimitiveType = (property: JSONSchema): boolean => {
  const propertyType = `${property.type}`.toLowerCase();
  switch (propertyType) {
    case FormSchemaTypes.Array:
    case FormSchemaTypes.Object:
      return false;
    default:
      return true;
  }
};
