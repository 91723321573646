import { toRef } from 'vue';

import type {
  NavigationGroup,
  NavigationItem,
} from '@leon-hub/navigation-config';
import { setNavigationBreadcrumbs } from '@leon-hub/navigation-config';

import useRouterStore from '../useRouterStore';
import useNavigationStore from '../useNavigationStore';

export interface UseFilterNavigationItemsComposable {
  getNavigationItems(group: NavigationGroup): NavigationItem[];
}

export default function useFilterNavigationItems(): UseFilterNavigationItemsComposable {
  const navigationStore = useNavigationStore();
  const navigationConfig = toRef(navigationStore, 'navigationConfig');

  const routerStore = useRouterStore();
  const realCurrentRouteName = toRef(routerStore, 'realCurrentRouteName');

  function getNavigationItems(group: NavigationGroup): NavigationItem[] {
    const items: NavigationItem[] = navigationConfig.value[group] ?? [];
    return realCurrentRouteName.value ? setNavigationBreadcrumbs(
      items,
      realCurrentRouteName.value,
    ) : items;
  }

  return {
    getNavigationItems,
  };
}
