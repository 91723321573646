import type { Ref } from 'vue';
import { ref, toRef, computed } from 'vue';

import { SportsbookUIVersion } from '@leon-hub/api-sdk';

import { useSiteConfigStore } from 'web/src/modules/core/store';

interface UseSportlineLayoutSettingsStoreComposable {
  isBetSwipeTopEventsLayoutEnabled: Ref<boolean>;
  isBetSwipeNavigationLayoutEnabled: Ref<boolean>;
  isBetSwipeLeagueLayoutEnabled: Ref<boolean>;
  isBetSwipeRegionLayoutEnabled: Ref<boolean>;
  isBetSwipeSportsLayoutEnabled: Ref<boolean>;
  isBetSwipeEventDetailsLayoutEnabled: Ref<boolean>;
  isBetSwipeLiveLayoutEnabled: Ref<boolean>;
}

export function useSportlineLayoutSettingsStoreComposable(): UseSportlineLayoutSettingsStoreComposable {
  if (process.env.VUE_APP_FEATURE_SPORTLINE_BET_SWIPE_LAYOUT_DISABLED) {
    return {
      isBetSwipeTopEventsLayoutEnabled: ref(false),
      isBetSwipeNavigationLayoutEnabled: ref(false),
      isBetSwipeLeagueLayoutEnabled: ref(false),
      isBetSwipeRegionLayoutEnabled: ref(false),
      isBetSwipeSportsLayoutEnabled: ref(false),
      isBetSwipeEventDetailsLayoutEnabled: ref(false),
      isBetSwipeLiveLayoutEnabled: ref(false),
    };
  }

  const siteConfigStore = useSiteConfigStore();
  const sportsbookUIVersion = toRef(siteConfigStore, 'sportsbookUIVersion');

  return {
    isBetSwipeTopEventsLayoutEnabled: computed(() => sportsbookUIVersion.value?.sportsbookUITopPageVersion === SportsbookUIVersion.V1),
    isBetSwipeNavigationLayoutEnabled: computed(() => sportsbookUIVersion.value?.sportsbookUINavigationVersion === SportsbookUIVersion.V1),
    isBetSwipeLeagueLayoutEnabled: computed(() => sportsbookUIVersion.value?.sportsbookUILeaguePageVersion === SportsbookUIVersion.V1),
    isBetSwipeRegionLayoutEnabled: computed(() => sportsbookUIVersion.value?.sportsbookUIRegionPageVersion === SportsbookUIVersion.V1),
    isBetSwipeSportsLayoutEnabled: computed(() => sportsbookUIVersion.value?.sportsbookUISportsPageVersion === SportsbookUIVersion.V1),
    isBetSwipeEventDetailsLayoutEnabled: computed(() => sportsbookUIVersion.value?.sportsbookUIEventDetailsPageVersion === SportsbookUIVersion.V1),
    isBetSwipeLiveLayoutEnabled: computed(() => sportsbookUIVersion.value?.sportsbookUILivePageVersion === SportsbookUIVersion.V1),
  };
}
