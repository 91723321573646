import createLogger from 'debug';

import { getUuid } from '@leon-hub/utils';
import {
  isObject,
  isString,
  isUndefined,
  assert,
  isFunction,
} from '@leon-hub/guards';
import { normalizeError } from '@leon-hub/errors';
import { isDebugEnabled, DEBUG_KEY } from '@leon-hub/debug';

import type PostMessageEvent from './PostMessageEvent';

type Callback<P> = P extends void ? (payload: P) => void : (payload: P) => void;
type ExtractEventPayloadType<P> = P extends PostMessageEvent<infer T> ? T : never;

interface PostMessageHandlerOptions {
  target: MessageEventSource;
  targetOrigin: string;
  initiator: string;
  skipSourceCheck?: boolean;
  allowMessagesFromDomain?: string;
  isP2P?: true | undefined;
}

interface CasAuthPostMessageData {
  id: string;
  type: string;
  isSendWithPostMessageBus: boolean;
  payload?: unknown;
}

interface RegularPostMessageData {
  eventName: string;
  clientId: string;
  initiator: string;
  payload?: unknown;
  isSendWithPostMessageBus?: true;
}

type PostMessageData = CasAuthPostMessageData | RegularPostMessageData;

function isRegularPostMessageData(value: unknown): value is RegularPostMessageData {
  return isObject(value)
    && isString(value.eventName)
    && isString(value.clientId)
    && isString(value.initiator)
    && (isUndefined(value.isSendWithPostMessageBus) || value.isSendWithPostMessageBus === true);
}

function isCasAuthPostMessageData(value: unknown): value is CasAuthPostMessageData {
  return isObject(value)
    && isString(value.id)
    && isString(value.type)
    && value.isSendWithPostMessageBus === true;
}

function isPostMessageData(value: unknown): value is PostMessageData {
  return isRegularPostMessageData(value)
    || isCasAuthPostMessageData(value);
}

function isWindowProxy(window: MessageEventSource): window is WindowProxy {
  return isFunction(window.postMessage);
}

const currentClientId = getUuid();

export interface PostMessageDOMEvent extends MessageEvent {
  readonly data: PostMessageData;
  readonly message: string;
}

function makeMessage(event: string, payload: unknown, from: string): PostMessageData {
  return {
    isSendWithPostMessageBus: true,
    eventName: event,
    initiator: from,
    clientId: currentClientId,
    payload,
  };
}

/**
 * From the following set WindowProxy | MessagePort | ServiceWorker
 * only WindowProxy is currently implemented.
 */
class PostMessageBus {
  private readonly logger = createLogger('post-message-bus');

  private readonly target: MessageEventSource;

  private readonly targetOrigin: string;

  private readonly boundEventCallback: (event: Event) => void;

  private readonly isParent: boolean = window.parent === window;

  private readonly allowMessagesFromDomain: string | null;

  private readonly options: PostMessageHandlerOptions;

  private readonly events: Record<string, Record<string, Callback<unknown>[]>> = {};

  private readonly skipSourceCheck: boolean = false;

  private readonly debug = isDebugEnabled(DEBUG_KEY.POST_MESSAGE);

  private get logPrefix(): string {
    return `[${this.isParent ? 'parent' : 'child'} in target origin: ${this.targetOrigin}]`;
  }

  public constructor(options: PostMessageHandlerOptions) {
    assert(options.initiator.length > 0, 'options.initiator should be informative about component name');

    const parentOptions = this.isParent
      ? {
        target: window.parent,
        targetOrigin: '*',
      }
      : {};

    this.options = {
      ...parentOptions,
      ...options,
    };

    this.allowMessagesFromDomain = options.allowMessagesFromDomain || null;
    this.skipSourceCheck = options.skipSourceCheck ?? false;

    this.targetOrigin = this.options.targetOrigin;

    assert(isObject(this.options.target), 'Target must be an instance of Window');
    this.target = this.options.target;
    this.boundEventCallback = this.handleMessage.bind(this);
    window.addEventListener('message', this.boundEventCallback, false);
    this.log(`new post message bus created by ${this.options.initiator} initiator`);
  }

  public dispose(): void {
    window.removeEventListener('message', this.boundEventCallback);
    this.log(`post message bus for ${this.options.initiator} initiator disposed`);
  }

  public on<E extends PostMessageEvent, P extends ExtractEventPayloadType<E>>(event: E, callback: Callback<P>): void {
    const eventName = event.toString();
    this.originEvents[eventName] = this.originEvents[eventName] || [];
    this.originEvents[eventName].push(callback);
    this.log(`
       starting event listener
       for event "${eventName}"
       at ${new Date(Date.now()).toISOString()}`);
  }

  private get originEvents(): Record<string, Callback<unknown>[]> {
    if (!this.events[this.targetOrigin]) {
      this.events[this.targetOrigin] = {};
    }
    return this.events[this.targetOrigin];
  }

  public emit<E extends PostMessageEvent, P extends ExtractEventPayloadType<E>>(
    event: E,
    payload?: P extends Record<string, unknown> ? P : P,
  ): boolean {
    if (isWindowProxy(this.target)) {
      try {
        if (this.isParent) {
          this.log(`
             emit event "${event.toString()}"
             with payload: ${payload ? JSON.stringify(payload) : 'none'}
             at ${new Date(Date.now()).toISOString()}`);
          this.target.postMessage(makeMessage(event.toString(), payload, this.options.initiator), this.targetOrigin);
          return true;
        }

        this.log(`
           emit event "${event.toString()}"
           with payload: ${payload ? JSON.stringify(payload) : 'none'}
           at ${new Date(Date.now()).toISOString()}`);
        this.target.postMessage(makeMessage(event.toString(), payload, this.options.initiator), '*');
        return true;
      } catch (rawError) {
        const error = normalizeError(rawError);

        const reassignedError = new Error(
          `Error while emitting postMessage. eventName: ${event.toString()} ${error.message}`,
        );
        reassignedError.stack = error.stack;
        // eslint-disable-next-line no-console
        console.error(reassignedError);
      }
    }
    return false;
  }

  protected checkEvent(event: MessageEvent): boolean {
    const { data } = event;
    const { isSendWithPostMessageBus } = data;

    if (!isSendWithPostMessageBus) {
      return false;
    }
    if (this.allowMessagesFromDomain !== null) {
      return new URL(event.origin).hostname === new URL(this.allowMessagesFromDomain).hostname;
    }
    return this.checkEventOrigin(event.origin, event.source);
  }

  private checkEventOrigin(eventOrigin: string, eventSource: MessageEventSource | null): boolean {
    // sandbox and cordova
    if (this.skipSourceCheck && (
      eventOrigin === 'null'
      || eventOrigin === 'file://'
      || window.location.protocol === 'file:')
    ) {
      return true;
    }

    if (this.skipSourceCheck) {
      return true;
    }

    if (this.isParent) {
      if (this.targetOrigin === '*') {
        // return eventSource === this.target; //TODO: bug: window is empty on desktop mode
        return true;
      }
      return (new URL(eventOrigin).hostname === new URL(this.targetOrigin).hostname)
        // check if window references matches
        && eventSource === this.target;
    }

    return eventSource === this.target;
  }

  public off<E extends PostMessageEvent<unknown>>(
    event: E,
    callback: Function,
  ): boolean {
    const listeners = this.originEvents[event.toString()];
    if (!listeners) return false;

    for (let index = 0; index < listeners.length; index += 1) {
      if (listeners[index] === callback) {
        listeners.splice(index, 1);
        return true;
      }
    }

    return false;
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  private handleMessage(event: unknown): void {
    assert(event instanceof MessageEvent);
    if (isPostMessageData(event.data)) {
      const eventName = isRegularPostMessageData(event.data) ? event.data.eventName : event.data.type;

      if (this.checkEvent(event)) {
        const { payload } = event.data;
        const originEvent = this.originEvents[eventName];

        if (isUndefined(originEvent)) {
          return;
        }

        if (isRegularPostMessageData(event.data)) {
          const { clientId } = event.data;
          const sameContext = clientId === currentClientId;

          if (!this.options.isP2P && sameContext) {
            return;
          }
        }

        for (let index = 0; index < originEvent.length; index += 1) {
          const originEventCallback = this.originEvents[eventName][index];

          try {
            originEventCallback(payload);
          } catch (rawError) {
            const error = normalizeError(rawError);

            const reassignedError = new Error(`Error happened inside originEventCallback. ${error.message}`);
            reassignedError.stack = error.stack;
            // eslint-disable-next-line no-console
            console.warn(reassignedError);
          }
        }
      }
    }
  }

  private log(...messages: string[]) {
    if (this.debug) {
      this.logger.log(this.logPrefix, ...messages);
    }
  }
}

export default PostMessageBus;
