import type { Ref } from 'vue';
import { computed, ref } from 'vue';

import type { PaymentType } from '@leon-hub/api-sdk';

import type {
  PaymentCategoryItem, PaymentSystem,
  CurrentPaymentSystemCategories, PaymentCategoriesDocument,
} from 'web/src/modules/payments/types/documentTypes';

interface PaymentsCategoriesComposable<T extends PaymentType> {
  setCategories(data: PaymentCategoriesDocument): void;
  paymentSystems: Ref<Record<string, CurrentPaymentSystemCategories<T>>>;
  categories: Ref<PaymentCategoriesDocument>;
}

export function usePaymentsCategories<T extends PaymentType>(): PaymentsCategoriesComposable<T> {
  const paymentSystems = ref<Record<string, PaymentSystem>>({});
  const data = ref<PaymentCategoriesDocument>([]);

  function setCategories(categories: PaymentCategoriesDocument) {
    data.value = categories;
  }

  const paymentCategories = computed(() => {
    const fetchedCategories: PaymentCategoryItem[] = [];
    for (const [index, category] of data.value.entries()) {
      if (category.name) {
        const cat: PaymentCategoryItem = {
          id: index,
          name: category.name,
          isFolded: !!('isFolded' in category && category.isFolded), // TODO: It's better to add the property isFolded in WithdrawalCategoriesDocument as well
          payments: [],
        };
        for (const system of category.paymentSystems) {
          if (system.id) {
            paymentSystems.value[system.id] = system;
            cat.payments.push(system.id);
          }
        }
        fetchedCategories.push(cat);
      }
    }
    return fetchedCategories;
  });

  // eslint-disable-next-line max-len
  const categories = computed<PaymentCategoriesDocument>(() => paymentCategories.value.map((category) => ({
    id: category.id,
    name: category.name,
    isFolded: category.isFolded,
    paymentSystems: category.payments.map((payment) => paymentSystems.value[payment]),
  }
  )));

  return {
    paymentSystems,
    categories,
    setCategories,
  };
}
