import type { Ref } from 'vue';
import { ref } from 'vue';

import { useI18n } from 'web/src/modules/i18n/composables';
import useSafeTimeBus from 'web/src/modules/core/composables/time-bus/useSafeTimeBus';
import useCountdown from 'web/src/modules/core/utils/countdown/useCountdown';
import type { DisplayedTimeLeft } from 'web/src/utils/countdown';

interface UseInfoCountdownProps {
  kickoff: Ref<number>;
}

interface UseInfoCountdownComposable {
  displayedTimeLeft: Ref<Optional<DisplayedTimeLeft>>;
  daysLabel: Ref<string>;
  hoursLabel: Ref<string>;
  minutesLabel: Ref<string>;
  secondsLabel: Ref<string>;
}

export function useInfoCountdown(
  props: UseInfoCountdownProps,
): UseInfoCountdownComposable {
  const { kickoff } = props;

  const { $translate } = useI18n();
  const { now } = useSafeTimeBus(1000);
  const useLeadingZero = ref(true);
  const { displayedTimeLeft } = useCountdown(kickoff, now, { useLeadingZero });

  const emptyTimeLabel = ref({ count: '' });
  const daysLabel = $translate('WEB2_PLURAL_DAYS_MANY', emptyTimeLabel);
  const hoursLabel = $translate('WEB2_PLURAL_HOURS_MANY', emptyTimeLabel);
  const minutesLabel = $translate('WEB2_PLURAL_MINUTES_MANY', emptyTimeLabel);
  const secondsLabel = $translate('WEB2_PLURAL_SECONDS_MANY', emptyTimeLabel);

  return {
    displayedTimeLeft,
    daysLabel,
    hoursLabel,
    minutesLabel,
    secondsLabel,
  };
}
