import {
  computed,
} from 'vue';
import type {
  Ref,
} from 'vue';

import type { VIconProps } from '@components/v-icon';

import type { VStepDescriptionProps } from 'web/src/components/StepDescription/VStepDescription/types';
import { getIconProperties } from 'web/src/components/StepDescription/VStepDescription/composables/utils';

export interface VStepDescriptionComposable {
  iconProperties: Ref<VIconProps | null>;
}

export default function useVStepDescription(props: VStepDescriptionProps): VStepDescriptionComposable {
  const iconProperties = computed(() => getIconProperties(props));

  return {
    iconProperties,
  };
}
