import { ModalDesktopPreset } from 'web/src/modules/core/enums';

import { ModalWidth } from '../../enums';

export default function getModalWidth(preset?: ModalDesktopPreset): ModalWidth {
  switch (preset) {
    case ModalDesktopPreset.SearchDesktopModal:
      return ModalWidth.MEDIUM;
    case ModalDesktopPreset.EuroBattlePassModal:
      return ModalWidth.MEDIUM_PLUS;
    case ModalDesktopPreset.LargeDesktopModal:
    case ModalDesktopPreset.ProviderSlotDesktopModal:
      return ModalWidth.LARGE;
    case ModalDesktopPreset.ProfileDesktopModal:
      return !process.env.VUE_APP_FEATURE_DESKTOP_PROFILE_AS_PHONE_ENABLED ? ModalWidth.BIG : ModalWidth.DEFAULT;
    default:
      return ModalWidth.DEFAULT;
  }
}
