import type { Ref } from 'vue';
import { computed } from 'vue';

import type {
  Score,
  CompetitorsRolesInfo,
  SportEventMatchProgress,
  SportEventMatchProgressTime,
} from 'web/src/modules/sportline/types';
import {
  MatchProgressTimeDirection,
} from 'web/src/modules/sportline/enums';
import { isStaticMatchProgressTime } from 'web/src/modules/sportline/utils';
import {
  scoreToPretty,
  progressTimeToPretty,
  scoreDetailsToString,
  progressTimeToTotalSeconds,
} from 'web/src/modules/sportline/utils/live-progress';
import { useI18n } from 'web/src/modules/i18n/composables';

interface LiveStatusComposable {
  gameScoreProgress: Ref<Optional<string>>;
  totalScore: Ref<Score<string>>;
  innings: Ref<CompetitorsRolesInfo<boolean>>;
  additionalTime: Ref<Optional<SportEventMatchProgressTime>>;
  isStaticLiveProgress: Ref<boolean>;
  isTimeReversed: Ref<boolean>;
  staticDirectTimeProgressLabel: Ref<Optional<string>>;
  staticReversedTimeProgressLabel: Ref<Optional<string>>;
  mainTimeDirectiveDirection: Ref<MatchProgressTimeDirection>;
  additionalTimeDirectiveDirection: Ref<MatchProgressTimeDirection>;
  mainTimeTotalSeconds: Ref<number>;
  mainTimeProgress: Ref<string>;
  mainTimeProgressShortFormat: Ref<string>;
  additionalTimeTotalSeconds: Ref<number>;
  additionalTimeProgress: Ref<string>;
  stage: Ref<string>;
  scoreDetailsLine: Ref<string | undefined>;
}

export function useLiveStatus(
  matchProgress: Ref<Maybe<SportEventMatchProgress> | undefined>,
): LiveStatusComposable {
  const { $translate } = useI18n();

  const minuteLabel = $translate('JSP_SPORT_EVENT_MINUTE');

  const gameScoreProgress = computed<Optional<string>>(() => (
    scoreToPretty(matchProgress.value?.currentSetScore)
  ));
  const totalScore = computed<Score<string>>(() => (matchProgress.value?.totalScore ?? { host: '', guest: '' }));
  const innings = computed(() => (matchProgress.value?.innings ?? { host: false, guest: false }));
  const mainTime = computed(() => matchProgress.value?.mainTime);
  const additionalTime = computed(() => matchProgress.value?.additionalTime);
  const currentTimeMinutes = computed(() => mainTime.value?.minutes ?? additionalTime.value?.minutes);
  const timeDirection = computed(() => matchProgress.value?.timeDirection ?? MatchProgressTimeDirection.None);
  const isStaticLiveProgress = computed(() => isStaticMatchProgressTime(timeDirection.value));
  const isTimeReversed = computed(() => timeDirection.value === MatchProgressTimeDirection.Reversed
    || timeDirection.value === MatchProgressTimeDirection.ReversedNone);
  const staticDirectTimeProgressLabel = computed(() => {
    const time = mainTime.value;
    const additional = additionalTime.value;
    const mainTimeMinutes = time?.minutes ? `${time.minutes + 1}` : '';
    const additionalTimeMinutes = additional?.minutes ? `+${additional.minutes}` : '';
    const timeProgress = mainTimeMinutes
      // eslint-disable-next-line sonarjs/no-nested-template-literals
      ? `${mainTimeMinutes}${additionalTimeMinutes ? ` ${additionalTimeMinutes}` : ''}`
      : (additionalTimeMinutes || undefined);

    return timeProgress
      ? `${timeProgress} ${minuteLabel.value}`
      : undefined;
  });

  const timeLeftParams = computed(() => ({
    min: String(currentTimeMinutes.value),
  }));
  const timeLastMinuteLabel = $translate('WEB2_REVERSED_TIME_LAST_MINUTE');
  const timeLeftLabel = $translate('WEB2_REVERSED_TIME_LEFT', timeLeftParams);
  const staticReversedTimeProgressLabel = computed(() => {
    const min = currentTimeMinutes.value;
    if (min === undefined) { return undefined; }
    return min < 1 ? timeLastMinuteLabel.value : timeLeftLabel.value;
  });
  const mainTimeDirectiveDirection = computed(() => (additionalTime.value
    ? MatchProgressTimeDirection.None
    : timeDirection.value));
  const additionalTimeDirectiveDirection = computed(() => (additionalTime.value
    ? timeDirection.value
    : MatchProgressTimeDirection.None));
  const mainTimeTotalSeconds = computed(() => progressTimeToTotalSeconds(mainTime.value));
  const mainTimeProgress = computed(() => progressTimeToPretty(mainTime.value));
  const mainTimeProgressShortFormat = computed(() => progressTimeToPretty(mainTime.value, true));
  const additionalTimeTotalSeconds = computed(() => progressTimeToTotalSeconds(additionalTime.value));
  const additionalTimeProgress = computed(() => progressTimeToPretty(additionalTime.value));
  const stage = computed(() => matchProgress.value?.stage || '');
  const scoreDetailsLine = computed(() => {
    const score = matchProgress.value?.totalScoreDetails;
    return score
      ? scoreDetailsToString(score)
      : undefined;
  });

  return {
    gameScoreProgress,
    totalScore,
    innings,
    additionalTime,
    isStaticLiveProgress,
    isTimeReversed,
    staticDirectTimeProgressLabel,
    staticReversedTimeProgressLabel,
    mainTimeDirectiveDirection,
    additionalTimeDirectiveDirection,
    mainTimeTotalSeconds,
    mainTimeProgress,
    mainTimeProgressShortFormat,
    additionalTimeTotalSeconds,
    additionalTimeProgress,
    stage,
    scoreDetailsLine,
  };
}
