import { defineStore } from 'pinia';
import {
  computed,
  ref,
} from 'vue';

import { assert } from '@leon-hub/guards';

import type { CountryData, InitialCountryData } from './types';
import isCountriesData from './guards/isCountriesData';

const useCountryStore = defineStore('country', () => {
  // State
  const countriesData = ref<CountryData[]>([
    {
      code: process.env.VUE_APP_DEFAULT_COUNTRY_CODE ?? '',
      name: '',
      phonePrefix: Number(process.env.VUE_APP_DEFAULT_PHONE_PREFIX ?? '0'),
      popular: false,
    },
  ]);
  const countryCode = ref(process.env.VUE_APP_DEFAULT_COUNTRY_CODE ?? '');
  const geoIpCountryCode = ref(process.env.VUE_APP_DEFAULT_COUNTRY_CODE ?? '');
  // Getters
  const computedCountryCode = computed<string>(() => {
    const countryData: CountryData | undefined = countriesData.value.find(
      (country) => country.code === countryCode.value,
    );

    if (countryData === undefined) {
      return countriesData.value[0].code;
    }

    return countryCode.value;
  });
  const currentCustomerCountryData = computed<CountryData>(() => {
    const countryData: CountryData | undefined = countriesData.value.find(
      (country) => country.code === countryCode.value,
    );

    if (countryData === undefined) {
      return countriesData.value[0];
    }

    return countryData;
  });
  // Actions/Mutations
  const setInitialCountryModuleData = (initialData: InitialCountryData): void => {
    assert(isCountriesData(initialData.countriesData), 'countriesData should have proper structure');
    assert(initialData.countryCode.length > 0, 'countryCode length should be more than 0');

    countriesData.value = [...initialData.countriesData];
    countryCode.value = initialData.countryCode;
    geoIpCountryCode.value = initialData.countryCode;
  };

  const setCountryCode = (code: string): void => {
    countryCode.value = code;
  };

  return {
    // State
    countriesData,
    geoIpCountryCode,
    // Getters
    countryCode: computedCountryCode,
    currentCustomerCountryData,
    // Actions
    setInitialCountryModuleData,
    setCountryCode,
  };
});

export default useCountryStore;
