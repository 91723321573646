import type { Ref } from 'vue';
import { computed, toRef } from 'vue';

import { useI18n } from 'web/src/modules/i18n/composables';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import { useSportlineEventDetailsStore } from 'web/src/modules/sportline/submodules/event-details/store';
import { useSportlineEventStatisticStore } from 'web/src/modules/sportline/submodules/event-statistic/store';
import type {
  League,
  Region,
  Sport,
  SportElement,
  SportlineEventElement,
} from 'web/src/modules/sportline/types';
import type { SportEventDetails } from 'web/src/modules/sportline/submodules/event-details/types';
import type { DetailsLayoutType } from 'web/src/modules/sportline/views/markets-list/enums';
import { SportEventStatus } from 'web/src/modules/sportline/enums';
import { isSportlineEventSuspended as checkIsSportlineEventSuspended } from 'web/src/modules/sportline/utils/sportlineEvent';
import {
  useSportlineEventDetailsParts,
} from 'web/src/modules/sportline/submodules/event-details/composables/event-details';

interface UseSportlineEventDetailsContentComposable {
  isMarketGroupsTabsEnabled: Ref<boolean>;

  isLoaded: Ref<boolean>;
  isShowingLoadingIndicator: Ref<boolean>;
  sportlineEvent: Ref<Maybe<SportEventDetails>>;
  relatedSportListElement: Ref<Maybe<SportElement>>;
  leagueEventsList: Ref<SportlineEventElement[]>;
  defaultLayoutType: Ref<Maybe<DetailsLayoutType>>;

  hasStatistic: Ref<boolean>;
  hasPostMatchStatistic: Ref<boolean>;

  sport: Ref<Maybe<Sport>>;
  region: Ref<Maybe<Region>>;
  league: Ref<Maybe<League>>;

  activeEventId: Ref<Optional<string>>;
  isSportlineEventExist: Ref<boolean>;
  isSportlineEventClosed: Ref<boolean>;
  isSportlineEventSuspended: Ref<boolean>;
  isRelatedEventsAvailable: Ref<boolean>;

  suspendedLabel: Ref<string>;

  onHeadlineVisibilityChanged(state: boolean): void;
  onLayoutTypeChanged(value: DetailsLayoutType): void;
}

export function useSportlineEventDetailsContent(): UseSportlineEventDetailsContentComposable {
  const { $translate } = useI18n();

  const sportlineSettingsStore = useSportlineSettingsStore();
  const isMarketGroupsTabsEnabled = toRef(sportlineSettingsStore, 'isMarketGroupsTabsEnabled');

  const sportlineEventDetailsStore = useSportlineEventDetailsStore();
  const isLoaded = toRef(sportlineEventDetailsStore, 'isLoaded');
  const isShowingLoadingIndicator = toRef(sportlineEventDetailsStore, 'isShowingLoadingIndicator');
  const sportlineEvent = toRef(sportlineEventDetailsStore, 'sportEventDetails');
  const relatedSportListElement = toRef(sportlineEventDetailsStore, 'relatedSportListElement');
  const leagueEventsList = toRef(sportlineEventDetailsStore, 'leagueEventsList');
  const defaultLayoutType = toRef(sportlineEventDetailsStore, 'defaultLayoutType');

  const sportlineEventStatisticStore = useSportlineEventStatisticStore();
  const hasStatistic = toRef(sportlineEventStatisticStore, 'hasMatchStatistic');
  const hasPostMatchStatistic = toRef(sportlineEventStatisticStore, 'hasPostMatchStatistics');

  const { sport, region, league } = useSportlineEventDetailsParts({ sportEventDetails: sportlineEvent });

  const activeEventId = computed<Optional<string>>(() => sportlineEvent.value?.id);
  const isSportlineEventExist = computed<boolean>(() => !!sportlineEvent.value);
  const isSportlineEventClosed = computed<boolean>(() => sportlineEvent.value?.status === SportEventStatus.Closed);
  const isSportlineEventSuspended = computed<boolean>(() => checkIsSportlineEventSuspended(sportlineEvent.value));

  const isRelatedEventsAvailable = computed<boolean>(() => !!relatedSportListElement.value);

  const eventIsOverLabel = $translate('JSP_SPORT_EVENT_FINISHED');
  const eventIsSuspendedLabel = $translate('JSP_SPORT_EVENT_PAUSE');
  const suspendedLabel = computed<string>(() => (
    isSportlineEventClosed.value
      ? eventIsOverLabel.value
      : eventIsSuspendedLabel.value
  ));

  function onHeadlineVisibilityChanged(state: boolean): void {
    sportlineEventDetailsStore.setIsMiniBoardShown(!state);
  }

  function onLayoutTypeChanged(value: DetailsLayoutType): void {
    sportlineEventDetailsStore.setDefaultLayoutType(value);
  }

  return {
    isMarketGroupsTabsEnabled,

    isLoaded,
    isShowingLoadingIndicator,
    sportlineEvent,
    relatedSportListElement,
    leagueEventsList,
    defaultLayoutType,

    hasStatistic,
    hasPostMatchStatistic,

    sport,
    region,
    league,
    activeEventId,
    isSportlineEventExist,
    isSportlineEventClosed,
    isSportlineEventSuspended,
    isRelatedEventsAvailable,

    suspendedLabel,

    onHeadlineVisibilityChanged,
    onLayoutTypeChanged,
  };
}
