import type { Ref } from 'vue';

import LanguageMicroSettings from 'web/src/utils/LanguageMicroSettings';
import { useI18nStore } from 'web/src/modules/i18n/store';

export interface LocalizedTimeStringComposable {
  getTimeString(h: Ref<Record<string, string>>, m: Ref<Record<string, string>>): {
    hours: string;
    minutes: string;
  };
}

export default function useLocalizedTimeString(): LocalizedTimeStringComposable {
  const i18nStore = useI18nStore();
  const { $t } = i18nStore;

  function getTimeString(h: Ref<Record<string, string>>, m: Ref<Record<string, string>>): {
    hours: string;
    minutes: string;
  } {
    return {
      hours: LanguageMicroSettings.plural([
        $t('WEB2_PLURAL_HOURS_ONE_GENITIVE', h).value,
        $t('WEB2_PLURAL_HOURS_TWO', h).value,
        $t('WEB2_PLURAL_HOURS_MANY', h).value,
      ], Number(h.value.count), i18nStore.locale),
      minutes: LanguageMicroSettings.plural([
        $t('WEB2_PLURAL_MINUTES_ONE_GENITIVE', m).value,
        $t('WEB2_PLURAL_MINUTES_TWO', m).value,
        $t('WEB2_PLURAL_MINUTES_MANY', m).value,
      ], Number(m.value.count), i18nStore.locale),
    };
  }

  return {
    getTimeString,
  };
}
