import type { Ref } from 'vue';
import { customRef } from 'vue';

import { Timer } from '@leon-hub/utils';

export default function useDebouncedRef<T>(value: T, delay = 200): Ref<T> {
  let timeout = 0;
  return customRef<T>((track, trigger) => ({
    get() {
      track();
      return value;
    },
    set(newValue) {
      Timer.clearTimeout(timeout);
      timeout = Timer.setTimeout(() => {
        // eslint-disable-next-line no-param-reassign
        value = newValue;
        trigger();
      }, delay);
    },
  }));
}
