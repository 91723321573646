<script lang="ts" setup>
import type { IconNameType, OptionalAnyIconName } from '@leon-hub/icons';

import { VIcon } from '@components/v-icon';

import {
  convertCommonClassToCssModule as toModule,
} from 'web/src/components/List/utils/convertCommonClassesToCssModule';
import { SwitchKind } from 'web/src/components/Switch/VSwitch/enums';
import VBadge from 'web/src/components/Badge/VBadge/VBadge.vue';
import VSwitch from 'web/src/components/Switch/VSwitch/VSwitch.vue';
import VCircularProgress from 'web/src/components/CircularProgress/VCircularProgress/VCircularProgress.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { ListItemSuffixAlign } from 'web/src/components/List/VListItem/enums';
import type { VListItemInnerExpose, VListItemInnerSlots } from 'web/src/components/List/VListItemInner/types';

import useVListItemInner from './composables/useVListItemInner';

interface VListItemInnerProps {
  badgeLabel?: string;
  buttonLabel?: string;
  counter?: string;
  haveExpand?: boolean;
  haveProgress?: boolean;
  haveSwitcher?: boolean;
  href?: string;
  iconColored?: boolean;
  iconCustomStyles?: string;
  iconName?: OptionalAnyIconName;
  iconSuffixName?: IconNameType;
  isNew?: boolean;
  isSwitchChecked?: boolean;
  isSwitchDisabled?: boolean;
  isTitleCentered?: boolean;
  noWrap?: boolean;
  progressValue?: number;
  suffixAlign?: ListItemSuffixAlign;
  suffixText?: string;
  switchKind?: SwitchKind;
  title?: string;
}

const props = withDefaults(defineProps<VListItemInnerProps>(), {
  suffixAlign: ListItemSuffixAlign.CENTER,
  switchKind: SwitchKind.DEFAULT,
  progressValue: 0,
});

const emit = defineEmits<VListItemInnerEmits>();

interface VListItemInnerEmits {
  (e: 'click', event: MouseEvent): void;
  (e: 'change-switcher', value: boolean): void;
  (e: 'click-switcher', event: Event): void;
  (e: 'click-prefix'): void;
  (e: 'click-default'): void;
  (e: 'click-suffix'): void;
  (e: 'click-button'): void;
}

function emitClick(event: MouseEvent): void {
  emit('click', event);
}
function emitChangeSwitcher(value: boolean): void {
  emit('change-switcher', value);
}
function emitClickSwitcher(event: Event): void {
  emit('click-switcher', event);
}
function emitClickPrefix(): void {
  emit('click-prefix');
}
function emitClickDefault(): void {
  emit('click-default');
}
function emitClickSuffix(): void {
  emit('click-suffix');
}
function emitClickButton(): void {
  emit('click-button');
}

const {
  tag,
  badgeProperties,
  switchProperties,
  computedIconProperties,
  progressProperties,
  haveSuffix,
  havePrefix,
  classObject,
  iconSuffixProperties,
  buttonProperties,
  listItemInnerRef,
  scrollIntoView,
} = useVListItemInner(props);

defineExpose<VListItemInnerExpose>({
  scrollIntoView,
});

defineSlots<VListItemInnerSlots>();
</script>

<template>
  <component v-auto-id="'VListItemInner'"
    :is="tag"
    ref="listItemInnerRef"
    :class="classObject"
    :href="tag === 'a' ? href : null"
    @click="emitClick"
  >
    <div
      v-if="havePrefix"
      :class="{
        [toModule('list-item__prefix')]: true,
        [toModule('list-item__prefix--colored')]: iconColored
      }"
      @click="emitClickPrefix"
    >
      <slot name="prefix">
        <div
          v-if="!!iconName"
          :class="{
            [toModule('list-item__prefix-icon')] : true,
            [toModule('list-item__prefix-icon--new')] : isNew,
            [toModule('list-item__prefix-icon--colored')]: iconColored,
            [toModule(`list-item__prefix-icon--${iconName}`)]: iconColored && iconName
          }"
          :style="iconCustomStyles"
        >
          <VIcon v-bind="computedIconProperties" />
        </div>
      </slot>
    </div>
    <div
      :class="[toModule('list-item__default')]"
      @click="emitClickDefault"
    >
      <span
        v-if="!!title && !$slots.inner"
        v-data-test="{ el: 'list-item-title' }"
      >
        {{ title }}
      </span>
      <slot
        v-else
        name="inner"
      />
    </div>
    <div
      v-if="haveSuffix"
      :class="[
        toModule(`list-item__suffix`),
        toModule(`list-item__suffix--${suffixAlign}`)
      ]"
      @click="emitClickSuffix"
    >
      <VSwitch
        v-if="haveSwitcher"
        v-bind="switchProperties"
        @click="emitClickSwitcher"
        @change="emitChangeSwitcher"
      />
      <template v-else>
        <div
          v-if="!!counter || !!suffixText"
          :class="toModule('list-item__suffix-text')"
        >
          {{ counter || suffixText }}
        </div>
        <VBadge
          v-if="!!badgeLabel"
          v-bind="badgeProperties"
          :class="toModule('list-item__suffix-badge')"
        />
        <VCircularProgress
          v-else-if="haveProgress || progressValue > 0"
          v-bind="progressProperties"
          :class="toModule('list-item__suffix-progress')"
        />
        <VIcon
          v-if="haveExpand || !!iconSuffixName || haveProgress || progressValue > 0"
          v-bind="iconSuffixProperties"
          :class="toModule('list-item__suffix-icon')"
        />
      </template>
      <VButton
        v-if="!!buttonLabel"
        v-bind="buttonProperties"
        :class="toModule('list-item__suffix-button')"
        @click="emitClickButton"
      />
    </div>
  </component>
</template>
