import type { Ref } from 'vue';
import {
  computed,
} from 'vue';

import { CasinoIconName, IconName } from '@leon-hub/icons';

import type { VBadgeProps } from 'web/src/components/Badge/VBadge/types';

import type { ProfileBonusesCardHeaderProps } from '../types';

export interface ProfileBonusesCardHeaderComposable {
  badgeProps: Ref<VBadgeProps>;
  hasBadge: Ref<boolean>;
}

export default function useProfileBonusesCardHeader(
  props: ProfileBonusesCardHeaderProps,
): ProfileBonusesCardHeaderComposable {
  const badgeProps = computed(() => (
    {
      label: props.badgeText,
      iconName: process.env.VUE_APP_FEATURE_CASINO_ENABLED ? CasinoIconName.CASINO_CHERRY : IconName.CROSS,
    } as VBadgeProps
  ));

  const hasBadge = computed(() => !!props.badgeText);

  return {
    badgeProps,
    hasBadge,
  };
}
