<script lang="ts" setup>
import { onComponentActivated } from '@leon-hub/vue-utils';

import type { BaseSportlineEventDetailsWidgetEmits } from 'web/src/modules/sportline/submodules/widgets/types';
import StreamWidget from 'web/src/modules/sportline/submodules/widgets/views/StreamWidget.vue';
import NoActiveStreamWidget from 'web/src/modules/sportline/submodules/widgets/components/NoActiveStreamWidget.vue';

import { useSportlineEventStream } from './useSportlineEventStream';

interface SportlineEventStreamProps {
  isActive?: boolean;
  isFloatingModeAllowed?: boolean;
}

defineProps<SportlineEventStreamProps>();
const emit = defineEmits<BaseSportlineEventDetailsWidgetEmits>();

const {
  isFloatingModeEnabled,
  isStreamAvailable,
  doShowStreamPreviewInfo,
  streamUrl,
  streamError,
  sportlineEventId,
  reloadStream,
  onStreamStarted,
  releaseStream,
} = useSportlineEventStream();

onComponentActivated(() => {
  emit('size-changed', { height: '100% ' });
});
</script>

<template>
  <StreamWidget
    v-if="isStreamAvailable"
    :is-active="isActive"
    :sportline-event-id="sportlineEventId"
    :stream-url="streamUrl"
    :stream-error="streamError"
    :is-floating-mode-allowed="isFloatingModeAllowed"
    :is-floating-mode-enabled="isFloatingModeEnabled"
    :do-show-stream-preview-info="doShowStreamPreviewInfo"
    @stream-setup="reloadStream"
    @stream-started="onStreamStarted"
    @stream-release="releaseStream"
  />
  <NoActiveStreamWidget
    v-else-if="isActive"
  />
</template>
