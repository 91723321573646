<script lang="ts" setup>
import { onActivated, onDeactivated } from 'vue';

import { VIcon } from '@components/v-icon';

import VImage from 'web/src/components/Image/VImage/VImage.vue';
import VRouterLink from 'web/src/components/Link/VRouterLink/VRouterLink.vue';
import VSwiperSlide from 'web/src/components/Swiper/VSwiperSlide/VSwiperSlide.vue';
import VSwiper from 'web/src/components/Swiper/VSwiper/VSwiper';
import SwiperNavigationButtons from 'web/src/components/Swiper/Navigation/SwiperNavigationButtons/SwiperNavigationButtons.vue';
import { ShowcaseNavigationItemType } from 'web/src/modules/home/submodules/showcase/enums';

import type { ShowcaseNavigationRouteComponentProps } from './types';
import useShowcaseNavigationComposable from './composables/useShowcaseNavigationComposable';

defineProps<ShowcaseNavigationRouteComponentProps>();

const {
  items,
  handleNavigationItemClickAnalytics,
  resumeSyncState,
  pauseSyncState,
} = useShowcaseNavigationComposable();

onActivated(resumeSyncState);
onDeactivated(pauseSyncState);
</script>

<template>
  <nav v-auto-id="'ShowcaseNavigationRouteComponent'"
    v-show="items.length > 0"
    :class="{
      [$style['showcase-navigation']]: true,
      [$style['showcase-navigation--custom']]: isCustomHomepage,
    }"
  >
    <VSwiper is-scroll-snap-enabled>
      <VSwiperSlide
        v-for="{
          key,
          icon,
          location,
          className,
          name,
          isHighlighted,
          isLandingEuroRu,
          routerLinkThemeStyle,
        } in items"
        :key="key"
        :class="{
          [$style['showcase-navigation__slide']]: true,
          [$style['showcase-navigation__slide--custom']]: isCustomHomepage,
        }"
      >
        <VRouterLink
          :style="routerLinkThemeStyle"
          :class="{
            [$style['showcase-navigation__item']]: true,
            [$style[`showcase-navigation__item--${className}`]]: !!className,
            [$style['showcase-navigation__item--custom']]: isCustomHomepage,
            [$style['showcase-navigation__item--stripe-animated']]: isHighlighted,
            [$style['showcase-navigation__item--landing']]: isLandingEuroRu,
          } "
          :to="location"
          :draggable="false"
          @click="handleNavigationItemClickAnalytics(className || '')"
        >
          <VIcon
            v-if="!!icon && icon.is === ShowcaseNavigationItemType.Icon"
            :name="icon.props.name"
            :size="icon.props.size"
            :class="$style['showcase-navigation__item-icon']"
            :draggable="false"
          />
          <VImage
            v-else-if="!!icon && icon.is === ShowcaseNavigationItemType.Image"
            :src="icon.props.src"
            :alt="icon.props.alt"
            :class="$style['showcase-navigation__item-icon']"
            :draggable="false"
            :style="icon.props.style"
          />
          <div
            v-else
            :class="$style['showcase-navigation__item-icon']"
          />
          <span
            v-if="name"
            :class="$style['showcase-navigation__item-name']"
          >{{ name }}</span>
        </VRouterLink>
      </VSwiperSlide>
      <template
        v-if="'1'"
        #pagination-footer
      >
        <SwiperNavigationButtons />
      </template>
    </VSwiper>
  </nav>
</template>

<style module lang="scss">
@include for-layout using($isDesktop) {
  .showcase-navigation {
    position: relative;
    display: inline-flex;
    flex-flow: row nowrap;
    flex-shrink: 0;
    justify-content: left;
    width: 100%;
    padding: if($isDesktop, 0 0 8px 0, 8px 0);
    overflow-x: auto;
    background-color: transparent;
    -ms-overflow-style: none;

    &--custom {
      padding: 8px 0 24px;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    &__slide {
      padding-left: 4px;

      @if $isDesktop {
        flex-grow: 1;
        max-width: $showcaseNavigationMaxWidth;
      }

      &:first-child {
        padding-left: if($isDesktop, 0, 8px);
      }

      &:last-child {
        padding-right: if($isDesktop, 0, 8px);
      }

      &--custom {
        padding-left: 12px;

        @if $isDesktop {
          max-width: 100%;

          &:first-child {
            padding-left: 8px;
          }

          &:last-child {
            padding-right: 8px;
          }
        }
      }
    }

    &__item {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      min-width: 80px;
      height: 56px;
      text-align: center;
      text-decoration: none;
      white-space: nowrap;
      cursor: pointer;
      user-select: none;
      background-color: var(--Layer1);
      border-radius: 5px;

      @if $isDesktop {
        flex-direction: column;
        width: 100%;
        height: 56px;
        border-radius: 5px;

        &:after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          display: none;
          content: '';
          background-color: rgba(255, 255, 255, 0.1);
          border-radius: 5px;
        }

        &:hover {
          background-color: var(--Layer1);
          fill: var(--Layer1);

          .showcase-navigation__item-icon {
            fill: var(--Layer1);
          }

          .showcase-navigation__item-name {
            color: var(--BrandText);
          }

          &:after {
            display: block;
          }
        }

        &[data-icon='color-live-1']:hover:after {
          display: block;
        }
      }

      &--custom {
        @if not $isDesktop {
          gap: 6px;
          width: 119.3px;
          height: 56px;
          padding: 8px 6px;

          .showcase-navigation__item-icon {
            margin: 0;
          }
        }
      }

      &--stripe-animated {
        position: relative;
        overflow: hidden;

        &:before {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          width: 60px;
          content: '';
          background-color: var(--ShowcaseItemStripeBgColor);
          transform: translateX(-100px) skew(-30deg);
          animation: stripe-animation 2s ease-in-out infinite;
        }
      }
    }

    &__item-icon {
      z-index: z-index(navigation-bar);
      min-width: 20px;
      min-height: 20px;
      margin: 6px 0;
      line-height: 20px;
      color: var(--TextPrimary);
      user-select: none;
      fill: var(--TextPrimary);
    }

    &__item-name {
      @include medium\11\16\-02;

      z-index: z-index(navigation-bar);
      display: block;
      width: 80px;
      padding: 0 4px;
      overflow: hidden;
      color: var(--ShowcaseNameColor);
      text-overflow: ellipsis;

      @if $isDesktop {
        margin-bottom: 2px;
      }
    }

    @keyframes stripe-animation {
      60%,
      100% {
        transform: translateX(80px) skew(-30deg);
      }

      0% {
        transform: translateX(-100px) skew(-30deg);
      }

      50% {
        transform: translateX(80px) skew(-30deg);
      }
    }
  }
}

@include for-theme using($isLight) {
  @if $isLight {
    .showcase-navigation__item {
      &--betgames {
        background: linear-gradient(360deg, #f28519 29620.98%, #eba41b 57755.97%);
      }

      &--live {
        background: linear-gradient(180deg, #19b567 0%, #169c59 100%);
      }

      &--cherry {
        background: linear-gradient(180deg, #ca3b41 0%, #cd3f47 100%);
      }

      &--roulette {
        background: linear-gradient(180deg, #888277 0%, #786c62 100%);
      }

      &--fastgames {
        background: linear-gradient(180deg, #e69f5d 0%, #df9148 100%);
      }

      &--tv {
        background: linear-gradient(180deg, #0a84ff 0%, #0d6ce8 100%);
      }

      &--cybersport {
        background: linear-gradient(180deg, #9f6deb 0%, #b22bef 100%);
      }

      &--actions {
        background: linear-gradient(180deg, #f9a 0%, #ff6680 100%);
      }

      &--boxing {
        background: linear-gradient(180deg, #c9383d 0%, #c12b2d 100%);
      }

      &--racing {
        background: linear-gradient(180deg, #e6a05e 0%, #df9249 100%);
      }

      &--bridge {
        background:
          linear-gradient(180deg, #ea4956 0%, #d12e79 100%),
          linear-gradient(180deg, #19b567 0%, #169c59 100%);
      }

      &--darts {
        background:
          linear-gradient(180deg, #8d69f3 0%, #7538f7 100%),
          linear-gradient(180deg, #19b567 0%, #169c59 100%);
      }

      &--shootout {
        background: linear-gradient(180deg, #5784b9 0%, #2f5787 100%);
      }

      &--zero-margin {
        background: linear-gradient(180deg, #39f 0%, #0080ff 100%);
      }

      &--highest-odds {
        background: linear-gradient(180deg, #f40 0%, #e80036 100%);
      }

      &--favorite-events {
        background: linear-gradient(180deg, #dfb200 0%, #bb9500 100%);
      }

      &--cricket {
        background: linear-gradient(180deg, #56932a 0%, #4a8023 100%);
      }

      &--soccer {
        background: linear-gradient(180deg, #389444 0%, #2d7837 100%);
      }

      &--soccer2 {
        background: linear-gradient(180deg, #609845 0%, #428c36 100%);
      }

      &--tennis {
        background: linear-gradient(180deg, #d66f40 0%, #d45c24 100%);
      }

      &--kabaddi {
        background: linear-gradient(180deg, #db76f0 0%, #8d3d9f 100%);
      }

      &--cybersoccer {
        background: linear-gradient(180deg, #00d0dd 0%, #0088c3 100%);
      }

      &--backgammon {
        background: linear-gradient(180deg, #ec5e3b 0%, #cb4f31 100%);
      }

      &--landing-euro {
        background: $showcaseNavigationItemBackgroundLight;

        &:hover {
          background-color: $showcaseNavigationItemBackgroundLight;
        }
      }

      &--virtual-cricket {
        background: linear-gradient(180deg, #ff4d4a 0%, #e53a37 100%);
      }

      &--bonus-calendar {
        background: linear-gradient(180deg, #fec70b 0%, #bf970f 100%);
      }
    }
  }

  @if not $isLight {
    .showcase-navigation__item {
      &--landing-euro {
        background: $showcaseNavigationItemBackgroundDark;

        &:hover {
          background-color: $showcaseNavigationItemBackgroundDark;
        }
      }
    }
  }
}
</style>
