<script lang="ts" setup>
import { VScrollbar } from '@components/v-scrollbar';

import { useModalContentWrapper } from 'web/src/components/Modal/ModalContentWrapper/composables';
import type { ModalRef } from 'web/src/components/Modal/types';

interface ModalContenWrapperProps {
  isScrollBarEnabled?: boolean;
  modalKey?: string;
  useScrollListener?: boolean;
  isInnerModal?: boolean;
}

interface ModalContentWrapperEmits {
  (e: 'scroll-reached-bottom'): void;
}

const props = withDefaults(defineProps<ModalContenWrapperProps>(), {
  modalKey: 'modal',
});

const emit = defineEmits<ModalContentWrapperEmits>();

const {
  content,
  scrollbar,
} = useModalContentWrapper(props);

function scrollTop(): void {
  if (props.isScrollBarEnabled) {
    scrollbar.value?.scrollTop();
  } else {
    content.value?.scrollIntoView(true);
  }
}

function scrollUpdate(): void {
  scrollbar.value?.scrollUpdate();
}

function scrollToElement(element: HTMLElement | null): void {
  if (props.isScrollBarEnabled) {
    scrollbar.value?.scrollToElement(element);
  } else if (element) {
    element.scrollIntoView();
  }
}

defineExpose<ModalRef>({
  scrollTop,
  scrollUpdate,
  scrollToElement,
});
</script>

<template>
  <VScrollbar
    v-if="isScrollBarEnabled"
    ref="scrollbar"
    :key="modalKey"
    :test-el="isInnerModal ? 'inner-modal' : 'modal'"
    flex-fill
    :use-scroll-listener="useScrollListener"
    @reached-bottom="emit('scroll-reached-bottom')"
  >
    <slot />
  </VScrollbar>
  <div
    v-else
    ref="content"
  >
    <slot />
  </div>
</template>
