import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import type {
  CasinoSearchItemEmits,
  CasinoSearchItemProps,
} from 'web/src/modules/search/submodules/casino/components/CasinoSearchItem/types';
import { BadgeKind } from 'web/src/components/Badge/VBadge/enums';
import type { VBadgeProps } from 'web/src/components/Badge/VBadge/types';
import { ObjectFitOption } from 'web/src/components/Image/VImage/enums';
import { getHighlightedText } from 'web/src/modules/search/submodules/casino/components/CasinoSearchItem/composables/utils';
import type { VImageProps } from 'web/src/components/Image/VImage/types';

interface CasinoSearchItemComposable {
  onClick: () => void;
  imageProps: ComputedRef<VImageProps>;
  ntBadgeProperties: ComputedRef<VBadgeProps>;
  formattedName: ComputedRef<string>;
  formattedGroup: ComputedRef<string>;
  formattedCategories: ComputedRef<string[]>;
}

const noimageSrc = require(`web/src/assets/images/${process.env.VUE_APP_PRODUCT}/${process.env.VUE_APP_SKIN}/noimage-square.png`);

export default function useCasinoSearchItem(
  props: CasinoSearchItemProps,
  emits: CasinoSearchItemEmits,
): CasinoSearchItemComposable {
  const formattedName = computed(() => getHighlightedText(props.name, props.searchText));
  const formattedGroup = computed(() => getHighlightedText(props.group, props.searchText));
  const ntBadgeProperties = computed<VBadgeProps>(() => ({
    kind: BadgeKind.SQUARE_ERROR,
    label: props.ntBadge,
  }));
  const formattedCategories = computed(() => props.categories?.filter((item) => item !== props.group && !!item));
  const imageProps = computed<VImageProps>(() => ({
    isLazy: true,
    showLoader: true,
    objectFit: ObjectFitOption.COVER,
    ...(props.image ?? {
      src: noimageSrc,
    }),
  }));

  function onClick(): void {
    emits('click');
  }

  return {
    onClick,
    imageProps,
    ntBadgeProperties,
    formattedName,
    formattedGroup,
    formattedCategories,
  };
}
