import {
  computed,
} from 'vue';
import type {
  Slots,
  Ref,
  ComputedRef,
} from 'vue';

import type { VIconProps } from '@components/v-icon';

import { ButtonHeight, ButtonKind } from 'web/src/components/Button/VButton/enums';
import hasSlot from 'web/src/utils/vue/hasSlot';

import type { VButtonProps } from '../types';
import {
  getIconPropsForButton,
  isButtonDisabled,
  getIconClassNamesForButton,
} from './utils';

export interface VButtonComposable {
  iconProps: Ref<VIconProps | {}>;
  isDisabledState: Ref<boolean | undefined>;
  iconClassNames: Ref<string[]>;
  isAnimation: ComputedRef<boolean>;
  isSpecialHeight: ComputedRef<boolean>;
  isSpecialKind: ComputedRef<boolean>;
  isIconLeft: ComputedRef<boolean>;
}

function hasIcon(props: VButtonProps): boolean {
  return !!props.iconName;
}

export default function useVButton(
  props: VButtonProps,
  slots: Slots,
  isAnimated: Ref<boolean>,
): VButtonComposable {
  const iconProps = computed(() => getIconPropsForButton(props));
  const isDisabledState = computed(() => isButtonDisabled(props));
  const iconClassNames = computed(() => getIconClassNamesForButton(props, hasSlot(slots, 'default')));
  const isAnimation = computed(() => !!(process.env.VUE_APP_LAYOUT_PHONE
    ? props.hasAnimation && isAnimated.value
    : props.hasAnimation));
  const isSpecialHeight = computed(() => !!props.height && props.height !== ButtonHeight.LARGE);
  const isSpecialKind = computed(() => !!props.kind && props.kind !== ButtonKind.PRIMARY);
  const isIconLeft = computed(() => (!!props.label || hasSlot(slots, 'default')) && hasIcon(props) && !props.iconRight);

  return {
    isDisabledState,
    iconProps,
    iconClassNames,
    isAnimation,
    isSpecialHeight,
    isSpecialKind,
    isIconLeft,
  };
}
