<script lang="ts" setup>
import type {
  RestorePasswordByEmailInfoEmits,
  RestorePasswordByEmailInfoProps,
} from 'web/src/modules/restore-password/components/RestorePasswordByEmailInfo/types';
import { VJumbotron } from 'web/src/components/Jumbotron';
import RestorePasswordContentBlock
  from 'web/src/modules/restore-password/components/RestorePasswordContentBlock/RestorePasswordContentBlock.vue';
import { VForm } from 'web/src/components/Form';
import VTooltip from 'web/src/modules/dialogs/components/VTooltip/VTooltip.vue';

import useRestorePasswordByEmailInfo from './composables/useRestorePasswordByEmailInfo';

const props = defineProps<RestorePasswordByEmailInfoProps>();
const emits = defineEmits<RestorePasswordByEmailInfoEmits>();

const {
  resendForm,
  iconProperties,
  tooltipLabel,
  currentEmail,
  formSchema,
  uiSchema,
  emitSubmit,
  emitInput,
  resendEmail,
} = useRestorePasswordByEmailInfo(props, emits);
</script>

<template>
  <VJumbotron v-auto-id="'RestorePasswordByEmailInfo'"
    v-bind="iconProperties"
    :heading="$t('RESTORE_PASSWORD_APPLICATION_SENT')"
    :class="$style['restore']"
  >
    <RestorePasswordContentBlock
      :restore-title="isPin ? $t('WEB2_RESTORE_PIN_LETTER_SENT') : $t('RESTORE_PASSWORD_LETTER_SENT', { email: currentEmail })"
      @click="resendEmail"
    />
    <VForm
      ref="resendForm"
      :class="$style['restore__resend-form']"
      :schema="formSchema"
      :ui-schema="uiSchema"
      @submit="emitSubmit"
      @input="emitInput"
    />

    <VTooltip
      v-if="tooltipLabel"
      :class="$style['restore__tooltip']"
      :text="tooltipLabel"
    />
  </VJumbotron>
</template>

<style lang="scss" module>
.restore {
  width: 100%;
  padding: 16px;
  color: var(--BrandDefault);

  &__resend-form {
    display: none;
  }

  &__tooltip {
    top: $restoreTooltipTop;
  }

  @include is-app-layout-desktop {
    padding: $restorePaddingDesktop;
  }
}
</style>
