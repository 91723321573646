import type { ComputedRef } from 'vue';
import { computed, ref } from 'vue';

import { useTheme } from 'web/src/modules/theme/composables';

import type { VLogoLoaderProps } from '../types';

export interface VLogoLoaderComposable {
  visibilityChanged: (value: boolean) => void;
  iconPath: ComputedRef<string>;
}

export default function useVLogoLoader(props: VLogoLoaderProps): VLogoLoaderComposable {
  const isVisible = ref(false);

  const { isLight } = useTheme();

  function visibilityChanged(value: boolean): void {
    isVisible.value = value;
  }

  const iconPath = computed(() => {
    let name = 'mascot';

    if (!props?.isAnimationDisabled && isVisible.value) {
      name += '-animated';
    }

    if (isLight.value || props.isFixedLightLogo) {
      name += '--light';
    }

    if (isLight.value && props.isGradient) {
      name += '-gradient';
    }

    return require(`web/src/assets/mascot/${process.env.VUE_APP_PRODUCT}/${process.env.VUE_APP_SKIN}/${name}.svg`);
  });

  return {
    iconPath,
    visibilityChanged,
  };
}
