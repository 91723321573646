import type { Ref } from 'vue';
import { toRef, computed, ref } from 'vue';

import { BusEvent, useEventsBus, useBusSafeSubscribe } from '@leon-hub/event-bus';
import { useWindowResize } from '@leon-hub/browser-composables';

import { useI18n } from 'web/src/modules/i18n/composables';
import { useSportlineEventDetailsStore } from 'web/src/modules/sportline/submodules/event-details/store';
import { LayoutSizes } from 'web/src/modules/core/enums';
import type { SportlineEventElement } from 'web/src/modules/sportline/types';
import type { SportEventDetails } from 'web/src/modules/sportline/submodules/event-details/types';

interface UseSportlineEventBreadcrumbsComposable {
  isShown: Ref<boolean>;
  title: Ref<string>;
  sportEventDetails: Ref<Maybe<SportEventDetails>>;
  sportEventDetailsId: Ref<Optional<string>>;
  leagueEventsList: Ref<SportlineEventElement[]>;
  isSidebarOpen: Ref<boolean>;
  softCloseSidebar(): void;
  toggleSidebar(): void;
}

/**
 * Specific breadcrumbs data for details page
 */
export function useSportlineEventBreadcrumbs(): UseSportlineEventBreadcrumbsComposable {
  const eventsBus = useEventsBus();
  const { $translate } = useI18n();
  const sportlineEventDetailsStore = useSportlineEventDetailsStore();
  const sportEventDetails = toRef(sportlineEventDetailsStore, 'sportEventDetails');
  const rawLeagueEventsList = toRef(sportlineEventDetailsStore, 'leagueEventsList');

  const isSidebarOpen = ref(false);

  const sportEventDetailsId = computed<Optional<string>>(() => sportEventDetails.value?.id);
  const leagueEventsList = computed(() => rawLeagueEventsList.value.filter(({ sportEvent }) => (sportEvent.id !== sportEventDetailsId.value)));
  const isShown = computed<boolean>(() => (
    !!sportEventDetails.value || leagueEventsList.value.length > 0
  ));
  const title = computed<string>(() => (
    sportEventDetails.value ? sportEventDetails.value.name : $translate('JSP_EVENTS_SIMILAR').value
  ));

  useWindowResize(() => {
    if (document.documentElement.clientWidth > +LayoutSizes.ShowLeftSidebarBreakpoint) {
      isSidebarOpen.value = false;
    }
  }, { debounce: 100 });
  useBusSafeSubscribe(BusEvent.SIDE_MENU_TOGGLE, () => {
    isSidebarOpen.value = !isSidebarOpen.value;
  });

  return {
    isShown,
    title,
    sportEventDetails,
    sportEventDetailsId,
    leagueEventsList,
    isSidebarOpen,
    softCloseSidebar(): void {
      if (!isSidebarOpen.value) { return; }
      eventsBus.emit(BusEvent.SIDE_MENU_SOFT_CLOSE, {});
    },
    toggleSidebar(): void {
      eventsBus.emit(BusEvent.SIDE_MENU_TOGGLE, {});
    },
  };
}
