import type { MarketsColumn, MarketType } from 'web/src/modules/sportline/types';

import type { MarketColumnsInfo } from './types';

export function getMarketColumnsFromMarketColumnsInfo(element?: Maybe<MarketColumnsInfo>): MarketsColumn[] {
  if (!element || !('marketsColumns' in element)) { return []; }
  return element.marketsColumns ?? [];
}

export function getMarketTypesFromMarketColumnsInfo(element?: Maybe<MarketColumnsInfo>): MarketType[] {
  if (!element || !('marketTypes' in element)) { return []; }
  return element.marketTypes ?? [];
}
