import type { Ref } from 'vue';
import { computed } from 'vue';

import {
  useSportsListStoreComposable,
  useSaveOpenRegionStateStoreComposable,
} from 'web/src/modules/sportline/submodules/sports-list/store/composables';
import { useSyncSportline } from 'web/src/modules/sportline/submodules/sync-sportline';
import { useCybersportRegionsSportlineUpdateTimeout } from 'web/src/modules/sportline/submodules/update-timeout';
import useBackgroundRequestsLifeCycle from 'web/src/utils/store/composables/useBackgroundRequestsLifeCycle';
import type { UseSaveOpenRegionStateComposable } from 'web/src/modules/sportline/submodules/sports-list/store/composables';
import type { SportElement, SportEventsSportsDateTimeFilter } from 'web/src/modules/sportline/types';
import type { SportsTreeSportElement, CoreSportlineFetchOptions } from 'web/src/modules/sportline/types/rest';
import type { SportDateTimeFilter } from 'web/src/modules/sportline/submodules/navigation/store/types';
import { BackgroundUpdateStopwatch } from 'web/src/utils/store';

interface UseCybersportAllRegionsStoreProps {
  sportElement: Ref<Maybe<SportElement>>;
}

interface UseCybersportAllRegionsStoreComposable extends UseSaveOpenRegionStateComposable {
  isReady: Ref<boolean>;
  sportsTreeElement: Ref<Maybe<SportsTreeSportElement>>;
  sportsTreeDateTimeFilter: Ref<SportDateTimeFilter>;
  initialRequests(): Promise<void>;
  setSportsTreeDateTimeFilter(value?: Maybe<SportEventsSportsDateTimeFilter>): Promise<void>;
  setBackgroundUpdateEnabled(value: boolean): Promise<void>;
  reloadSportsTreeForFilter(filter: Maybe<SportEventsSportsDateTimeFilter>): Promise<void>;
}

export function useCybersportAllRegionsStoreComposable(
  props: UseCybersportAllRegionsStoreProps,
): UseCybersportAllRegionsStoreComposable {
  const { sportElement } = props;

  const { timeout: cybersportRegionsUpdateInterval } = useCybersportRegionsSportlineUpdateTimeout();

  const {
    sportsListFilter: sportsTreeDateTimeFilter,
    actualSportsList: sportsTree,
    isReady,
    reloadAllSportsLists,
    setSportsListFilter: setSportsTreeDateTimeFilter,
  } = useSportsListStoreComposable({ updateInterval: cybersportRegionsUpdateInterval });

  const saveOpenRegionState = useSaveOpenRegionStateStoreComposable();

  const sportsTreeElement = computed<Maybe<SportsTreeSportElement>>(() => (
    sportsTree.value?.find((treeElement) => (treeElement.sport.id === sportElement.value?.sport.id))
    ?? null
  ));

  const lastUpdate = new BackgroundUpdateStopwatch<['sportsTree']>({
    sportsTree: async ({ silent }: CoreSportlineFetchOptions) => {
      await reloadAllSportsLists(silent);
      lastUpdate.update('sportsTree');
    },
  });

  async function reloadSportsTreeForFilter(filter: Maybe<SportEventsSportsDateTimeFilter>): Promise<void> {
    await setSportsTreeDateTimeFilter(filter);
  }

  const {
    syncState,
    initialRequests,
    setBackgroundUpdateEnabled,
  } = useBackgroundRequestsLifeCycle({ lastUpdate, updateInterval: cybersportRegionsUpdateInterval });

  const {
    resumeSyncState,
  } = useSyncSportline((silent) => syncState({ silent }), cybersportRegionsUpdateInterval, { suspendedByDefault: true });

  return {
    ...saveOpenRegionState,
    isReady,
    sportsTreeElement,
    sportsTreeDateTimeFilter,
    setSportsTreeDateTimeFilter,
    setBackgroundUpdateEnabled,
    reloadSportsTreeForFilter,
    initialRequests(): Promise<void> {
      void initialRequests();
      resumeSyncState();
      return Promise.resolve();
    },
  };
}
