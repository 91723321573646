import type { Ref } from 'vue';
import {
  computed,
  toRef,
} from 'vue';

import { useDowntimeAlertStore } from 'web/src/modules/downtime/store';
import { useFrontNotificationsStore } from 'web/src/modules/front-notifications/store';
import useFomoBonusNotification
  from 'web/src/modules/front-notifications/components/FomoBonusNotification/composables/useFomoBonusNotification';

export interface UseAuthorizedSnackbarsComposable {
  isDowntimeAlertVisible: Ref<boolean>;
  isIdentificationStatusAlertVisible: Ref<boolean>;
  isVideoVerificationAlert: Ref<boolean>;
  isFomoBonusAlert: Ref<boolean>;
}

export default function useAuthorizedSnackbars(): UseAuthorizedSnackbarsComposable {
  const downtimeAlertModule = useDowntimeAlertStore();
  const frontNotificationModule = useFrontNotificationsStore();
  const isDowntimeAlertVisible = toRef(downtimeAlertModule, 'isAlertVisible');
  const isVideoVerificationAlert = toRef(frontNotificationModule, 'isVideoVerificationAlert');

  const fomoNotification = useFomoBonusNotification();
  const isFomoBonusAlert = toRef(fomoNotification, 'isFomoBonusAlert');

  const isIdentificationStatusAlertVisible = computed(() => !isDowntimeAlertVisible.value);

  return {
    isDowntimeAlertVisible,
    isIdentificationStatusAlertVisible,
    isVideoVerificationAlert,
    isFomoBonusAlert,
  };
}
