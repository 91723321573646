import type { SportEventInfo } from 'web/src/modules/sportline/types';

export default function isSportEventInfoChanged(
  oldValue?: Maybe<SportEventInfo>,
  newValue?: Maybe<SportEventInfo>,
): boolean {
  if (!oldValue || !newValue) { return true; }

  // @TODO navigationParameters changed
  // @TODO competitors changed
  // @TODO changes for specific types

  return oldValue.id !== newValue.id
    || oldValue.type !== newValue.type
    || oldValue.name !== newValue.name
    || oldValue.status !== newValue.status
    || oldValue.kickoff !== newValue.kickoff
    || oldValue.lastUpdated !== newValue.lastUpdated
    || oldValue.matchPhase !== newValue.matchPhase
    || oldValue.hasZeroMargin !== newValue.hasZeroMargin;
}
