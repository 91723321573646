import { BetType } from '@leon-hub/api-sdk';

import type { RequiredCustomerHistoryRequest, CurrentScoreType } from 'web/src/modules/profile/submodules/customer-history/types';
import DateTime from 'web/src/utils/DateTime';

export default function getCustomerFilterId(options: RequiredCustomerHistoryRequest): string {
  return `${options.filter}_${options.dateRange}_${options.dateType}_${options.rows}`;
}

export function currentScore(score: string | null | undefined): CurrentScoreType {
  if (score) {
    const scoreSplit = score.split(':');
    return {
      home: scoreSplit[0],
      away: scoreSplit[1],
    };
  }
  return {
    home: '',
    away: '',
  };
}

export function formatInnings(score = '', placeholder = '*', replaceString = '\u2022'): string {
  if (score && score.includes(placeholder)) {
    return score.replace(placeholder, replaceString);
  }
  return score;
}

export function defaultDateTimeFormat(timeStamp: number | null | undefined): string {
  if (timeStamp) {
    return DateTime.withTimeStamp(timeStamp).toDateTime();
  }
  return '';
}

export function numberToDouble(coefficient: number | string | undefined | null): string {
  if (coefficient) {
    return `${Number(coefficient).toFixed(2)}`;
  }
  return '';
}

export function getBetEventDescription(
  betType: BetType,
  $t: Function,
  betEventParts: Maybe<string>[] = [],
): Maybe<string>[] {
  switch (betType) {
    case BetType.EXPRESS:
      return [$t('WEB2_TRANSACTION_HISTORY_EXPRESS_BET').value];
    case BetType.SYSTEM:
      return [$t('WEB2_TRANSACTION_HISTORY_SYSTEM_BET').value];
    default:
      return betEventParts;
  }
}
