import { defineStore } from 'pinia';
import { ref } from 'vue';

import type { NavigationItem } from '@leon-hub/navigation-config';
import { getRules } from '@leon-hub/api-sdk';

import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import type { RuleDocument } from 'web/src/modules/rules/types';

const useRulesStore = defineStore('rules', () => {
  /** State */
  const navigation = ref<NavigationItem[]>([]);

  /** Actions */
  async function getRulesNavigation(silent?: boolean): Promise<void> {
    const { rules } = await getRules(useGraphqlClient(),
      (node) => node.queries.rules.getRules,
      {},
      { silent });
    setRulesNavigation(convertRulesToNavigationItems(rules));
  }

  function setRulesNavigation(navItems: NavigationItem[] = []): void {
    navigation.value = navItems;
  }

  function convertRuleToNavigationItem(rule: RuleDocument): NavigationItem {
    const { children } = rule;
    const subChildren = children.map<NavigationItem>((item) => ({
      id: item.id,
      caption: item.caption,
      isPrintEnabled: item.isPrintEnabled,
      children: item?.children ? item.children.map<NavigationItem>((ruleItem) => ({
        id: ruleItem.id,
        caption: ruleItem.caption,
        isPrintEnabled: ruleItem.isPrintEnabled,
        props: {
          contentKey: ruleItem.contentPageKey,
        },
      })) : [],
    }));
    return {
      id: rule.id,
      caption: rule.caption,
      isPrintEnabled: rule.isPrintEnabled,
      children: subChildren,
    };
  }

  function convertRulesToNavigationItems(rules: RuleDocument[]): NavigationItem[] {
    return rules.map((item) => convertRuleToNavigationItem(item));
  }

  return {
    navigation,
    getRulesNavigation,
  };
});

export default useRulesStore;
