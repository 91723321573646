import CreditCardTokenizationApiClient from './CreditCardTokenizationApiClient';

let tokenizationApiClient: CreditCardTokenizationApiClient | undefined;

export default function useCreditCardTokenizationApiClient(): CreditCardTokenizationApiClient {
  if (!tokenizationApiClient) {
    tokenizationApiClient = new CreditCardTokenizationApiClient();
  }

  return tokenizationApiClient;
}
