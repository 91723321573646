import type { Ref } from 'vue';
import { computed } from 'vue';

import { IconSize } from '@leon-hub/icons';

import { useSportlineSegmentFilter } from 'web/src/modules/sportline/submodules/segment/components/Filter/composables';
import type { SportsSegmentPageListGroup } from 'web/src/modules/sportline/submodules/segment/types';
import type { ChangeFilterPayload } from 'web/src/modules/sportline/submodules/segment/composables/types';
import type {
  SportlineFilterItem,
} from 'web/src/modules/sportline/components/navigation-filter/types';
import type {
  SportlineNavigationSportIcon,
} from 'web/src/modules/sportline/components/navigation-indicator/types';
import { SportlineNavigationItemType } from 'web/src/modules/sportline/enums/navigation';

import { resolveOptionLocation } from './utils';

interface UseSportlineLiveFilterProps {
  totalCount: Ref<number>;
  allGroup: Ref<Maybe<SportsSegmentPageListGroup>>;
  streamGroup: Ref<Maybe<SportsSegmentPageListGroup>>;
  favoriteGroup: Ref<Maybe<SportsSegmentPageListGroup>>;
  groups: Ref<SportsSegmentPageListGroup[]>;
}

interface UseSportlineLiveFilterEmit {
  changeFilter(payload: Maybe<ChangeFilterPayload<SportsSegmentPageListGroup>>): void;
}

interface UseSportlineLiveFilterComposable {
  items: Ref<SportlineFilterItem[]>;
}

export function useSportlineLiveFilter(
  props: UseSportlineLiveFilterProps,
  emit: UseSportlineLiveFilterEmit,
): UseSportlineLiveFilterComposable {
  const {
    totalCount,
    allGroup,
    streamGroup,
    favoriteGroup,
    groups,
  } = props;

  function emitChangeFilter(payload: Maybe<ChangeFilterPayload<SportsSegmentPageListGroup>>): void {
    emit.changeFilter(payload);
  }

  const {
    allFilterItem,
    favoriteFilterItem,
    streamFilterItem,
  } = useSportlineSegmentFilter({
    totalCount,
    allGroup,
    streamGroup,
    favoriteGroup,
    emitChangeFilter,
    resolveOptionLocation,
  });

  const items = computed<SportlineFilterItem[]>(() => {
    const list: SportlineFilterItem[] = [];
    if (allFilterItem.value) { list.push(allFilterItem.value); }
    if (favoriteFilterItem.value) { list.push(favoriteFilterItem.value); }
    if (streamFilterItem.value) { list.push(streamFilterItem.value); }

    for (const group of groups.value) {
      const sport = group.filterElement?.sport;
      if (!sport) { continue; }
      const to = resolveOptionLocation(group);
      const icon: SportlineNavigationSportIcon = {
        type: SportlineNavigationItemType.SportIcon,
        props: { sportName: sport.representation.family, size: IconSize.SIZE_16 },
      };

      const filterItem: SportlineFilterItem = {
        key: group.key,
        icon,
        title: sport.name,
        isActive: group.isActive,
        to,
        onClick: () => emitChangeFilter({ group, to }),
        dataTest: sport.name,
        transitionId: group.transitionId,
      };

      list.push(filterItem);
    }

    return list;
  });

  return {
    items,
  };
}
