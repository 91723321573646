import type { AchievementRequest } from '@leon-hub/api-sdk';
import { getAchievement } from '@leon-hub/api-sdk';

import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import type {
  GetAchievementDataDocument,
} from 'web/src/modules/profile/submodules/achievements/types/AchievementsDocuments';

export default function getAchievementRequest(id: number, publicId?: string): Promise<GetAchievementDataDocument> {
  const apiClient = useGraphqlClient();

  const options: AchievementRequest = {
    id,
  };

  if (publicId) {
    options.publicId = publicId;
  }

  return getAchievement(
    apiClient,
    (node) => node.queries.achievements.getAchievement,
    {
      options,
    },
  );
}
