import { ComponentKey, ComponentStatus } from '../types';
import type { ComponentResult } from '../types';
import AbstractFingerprintComponent from '../AbstractFingerprintComponent';

// Component source: https://github.com/rickmacgillis/audio-fingerprint

declare global {
  interface Window {
    webkitOfflineAudioContext: OfflineAudioContext;
  }
}

export default class AudioComponent extends AbstractFingerprintComponent {
  constructor() {
    super(ComponentKey.Audio);
  }

  getComponentResult(): Promise<ComponentResult> {
    // eslint-disable-next-line consistent-return
    return new Promise((resolve): void => {
      try {
        const AudioContext = window.OfflineAudioContext || window.webkitOfflineAudioContext;

        if (!AudioContext) {
          resolve(this.result(ComponentStatus.NotAvailable));
        }

        let context : OfflineAudioContext | null = new AudioContext(1, 44_100, 44_100);

        const oscillator = context.createOscillator();
        oscillator.type = 'triangle';
        oscillator.frequency.setValueAtTime(10_000, context.currentTime);

        const compressor: DynamicsCompressorNode = context.createDynamicsCompressor();
        compressor.threshold.setValueAtTime(-50, context.currentTime);
        compressor.knee.setValueAtTime(40, context.currentTime);
        compressor.ratio.setValueAtTime(12, context.currentTime);
        compressor.attack.setValueAtTime(0, context.currentTime);
        compressor.release.setValueAtTime(0.25, context.currentTime);

        oscillator.connect(compressor);
        compressor.connect(context.destination);
        oscillator.start(0);
        context.startRendering();

        const audioTimeoutId = setTimeout(() => {
          context = null;
          return resolve(this.result(ComponentStatus.Timeout));
        }, 1000);

        context.oncomplete = (event) => {
          let fingerprint;
          try {
            clearTimeout(audioTimeoutId);
            fingerprint = event.renderedBuffer.getChannelData(0)
              .slice(4500, 5000)
              .reduce((accumulator: number, value: number) => accumulator + Math.abs(value), 0)
              .toString();
            oscillator.disconnect();
            compressor.disconnect();
          } catch {
            return resolve(this.result(ComponentStatus.Error));
          }

          return resolve(this.result([fingerprint]));
        };
      } catch {
        resolve(this.result(ComponentStatus.Error));
      }
    });
  }
}
