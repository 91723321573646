import { RouteName } from '@leon-hub/routing-config-names';
import CustomerRouteAccessRole from '@leon-hub/routing-config/src/types/CustomerRouteAccessRole';

import { AppModule } from 'web/src/modules/core/plugins/AppModule';
import type { AppVueRouter } from 'web/src/modules/core/services/router/types';
import { ModalDesktopPreset } from 'web/src/modules/core/enums';

export class LoginModule extends AppModule {
  // eslint-disable-next-line class-methods-use-this
  addRoutes(router: AppVueRouter): void {
    router.addModalRoute({
      name: RouteName.LOGIN,
      path: '/login',
      access: CustomerRouteAccessRole.ANONYMOUS,
      modalPreset: process.env.VUE_APP_LAYOUT_DESKTOP ? ModalDesktopPreset.SmallDesktopModal : undefined,
      title: process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED
        ? undefined
        : '{{$t(\'THE_SITE_ENTRANCE\')}}',
      component: () => import('web/src/modules/login/pages/LoginRoutePage/LoginRoutePage.vue'),
      prefetch: () => import('./prefetch/LoginModulePrefetch'),
    });

    if (process.env.VUE_APP_PLATFORM_CORDOVA) {
      router.addModalRoute({
        name: RouteName.FLOW_LOGIN,
        path: '/flow-login',
        access: CustomerRouteAccessRole.ANONYMOUS,
        // eslint-disable-next-line max-len
        topBar: process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED
          ? undefined
          : () => import('web/src/modules/core/views/SimpleBrandTopBarRouteComponent/SimpleBrandTopBarRouteComponent.vue'),
        component: () => import('web/src/modules/login/pages/LoginRoutePage/LoginRoutePage.vue'),
        prefetch: () => import('./prefetch/LoginModulePrefetch'),
        props: {
          isFlowLogin: true,
        },
      });
    }
  }
}
