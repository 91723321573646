import type { AppVueRouter } from 'web/src/modules/core/services/router/types';
import { useDateLocalizers } from 'web/src/modules/i18n/composables/useDateLocalizers';
import DateTime from 'web/src/utils/DateTime';
import { resolveSportEventsDetailsPageLink } from 'web/src/modules/sportline/submodules/navigation/store/utils';
import type {
  Sport,
  Region,
  League,
  SportEvent,
  SportElement,
  RegionElement,
  SportEventSeoMetaInfo,
} from 'web/src/modules/sportline/types';

export interface GetSportEventSeoMetaInfoData {
  sportEvent: SportEvent;
  sport: Sport;
  region: Region;
  league: League;
}

export type GetSportEventSeoMetaInfoOptions = {
  /** @deprecated do not use */
  vm: { $router: { getHref: AppVueRouter['getHref'] } };
} | { url: string };

type GetMetaOptions = GetSportEventSeoMetaInfoData & GetSportEventSeoMetaInfoOptions;

export function getSportEventSeoMetaInfo(options?: GetMetaOptions): SportEventSeoMetaInfo | {} {
  if (!options) {
    return {};
  }

  const {
    sportEvent,
    sport,
    region,
    league,
  } = options;

  const coreData: SportEventSeoMetaInfo = {
    name: sportEvent.name,
    kickoff: sportEvent.kickoff.toString(),
    time: DateTime.withTimeStamp(sportEvent.kickoff).toTime(),
    date: useDateLocalizers().toFullLocaleDate(sportEvent.kickoff),
    startDateISO: (new DateTime(sportEvent.kickoff)).toISOString(),
    sport: sport.name,
    sportFamily: sport.navigationParameters.urlName,
    sportEmoji: sport.representation.emoji,
    region: region.name,
    league: league.name,
    url: 'url' in options
      ? options.url
      : options.vm.$router.getHref(resolveSportEventsDetailsPageLink(sportEvent.navigationParameters)).absolute,
  };

  if (sportEvent.isOutright) {
    return coreData;
  }

  return {
    ...coreData,
    home: sportEvent.competitors[0]?.name || '',
    away: sportEvent.competitors[1]?.name || '',
    logoHome: sportEvent.competitors[0]?.logo || '',
    logoAway: sportEvent.competitors[1]?.logo || '',
  };
}

export function extractSportEventSeoMetaInfoFromRegionElement(
  regionElement: Optional<Maybe<RegionElement>>,
  options: GetSportEventSeoMetaInfoOptions & { sport: Sport },
): (SportEventSeoMetaInfo | {})[] {
  if (!regionElement) { return []; }

  return regionElement.leagues.flatMap((leagueElement) => leagueElement
    .sportEvents.map((sportEventElement) => getSportEventSeoMetaInfo({
      ...options,
      sportEvent: sportEventElement.sportEvent,
      region: regionElement.region,
      league: leagueElement.league,
    })));
}

export function extractSportEventSeoMetaInfoFromSportElement(
  sportElement: Optional<Maybe<SportElement>>,
  options: GetSportEventSeoMetaInfoOptions,
): (SportEventSeoMetaInfo | {})[] {
  if (!sportElement) { return []; }

  return sportElement.regions.flatMap((regionElement) => extractSportEventSeoMetaInfoFromRegionElement(regionElement, {
    ...options,
    sport: sportElement.sport,
  }));
}

export function extractSportEventSeoMetaInfo(
  element: Optional<Maybe<SportElement | RegionElement>>,
  options: GetSportEventSeoMetaInfoOptions & { sport?: Sport },
): (SportEventSeoMetaInfo | {})[] {
  if (!element) {
    return [];
  }

  if ('regions' in element) {
    return extractSportEventSeoMetaInfoFromSportElement(element, options);
  }

  const { sport } = options;

  if (sport) {
    return extractSportEventSeoMetaInfoFromRegionElement(element, { ...options, sport });
  }

  return [];
}
