import type {
  NavigationGuardNext,
  RouteLocationNormalized,
} from 'vue-router';

import type { AppVueRouter } from 'web/src/modules/core/services/router/types';

export default abstract class AbstractPrefetch {
  abstract prefetch(
    router: AppVueRouter,
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ): Promise<void> | void;
}
