import { computed } from 'vue';
import type { ComputedRef } from 'vue';

import { assert } from '@leon-hub/guards';

import type { PhoneInputProps } from 'web/src/components/Input';
import isPhoneInputProps from 'web/src/components/Input/guards/isPhoneInputProps';
import isPartialFormPhoneValue from 'web/src/components/Form/guards/isPartialFormPhoneValue';

import getHintFields from '../../utils/getHintFields';
import type {
  FormWrapperWidgetProperties,
  FormPhoneValue,
} from '../../types';

interface UseFormPhone {
  componentProps: ComputedRef<PhoneInputProps>;
}

export default function useFormPhone(props: FormWrapperWidgetProperties<PhoneInputProps>): UseFormPhone {
  const componentProps = computed<PhoneInputProps>(() => {
    const {
      name,
      value,
      options,
      title,
      disabled,
      hintMessage,
      autofocus,
    } = props;

    const fieldData = value || {};
    assert(isPartialFormPhoneValue(fieldData), 'unexpected phoneValue in formData');
    const { prefix, suffix, countryCode } = fieldData;
    // errors come for that field as {prefix: error, suffix: error}, but component need a string
    const errorList = Object.values(props.error as Partial<FormPhoneValue> || {});
    assert(isPhoneInputProps(options), 'Unexpected options for PhoneInput');

    return {
      name,
      label: title,
      disabled,
      value: suffix,
      // for phone it is fine to show first error
      error: errorList.length ? errorList[0] : '',
      ...options,
      // prefix and countryCode is valid options, override from formData if it exists
      ...prefix ? { prefix } : {},
      ...countryCode ? { countryCode } : {},
      ...getHintFields(options, hintMessage),
      autofocus,
    };
  });
  return {
    componentProps,
  };
}
