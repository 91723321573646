<script lang="ts" setup>
import { toRef } from 'vue';

import { IconName, IconSize } from '@leon-hub/icons';

import { VIcon } from '@components/v-icon';

import { useVirtualSportDebugStore } from 'web/src/modules/sportline/submodules/virtual-sport/store';

const debugVirtualSportStore = useVirtualSportDebugStore();
const debugBetDelay = toRef(debugVirtualSportStore, 'betDelay');
const delayInProgress = toRef(debugVirtualSportStore, 'delayInProgress');
</script>

<template>
  <div v-auto-id="'DebugIndicator'"
    v-if="debugBetDelay"
    :class="$style['debug-indicator']"
    :title="`Bet delay: ${debugBetDelay}ms`"
  >
    <div
      v-if="delayInProgress"
      :class="$style['debug-indicator-progress']"
      :style="{ 'animation-duration': `${debugBetDelay}ms` }"
    />
    <VIcon
      v-else
      :name="IconName.ATTENTION_OUTLINE"
      :size="IconSize.SIZE_20"
    />
  </div>
</template>

<style module lang="scss">
.debug-indicator {
  @include z-index(absolute-item);

  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  color: var(--AlertDefault);
}

.debug-indicator-progress {
  position: relative;
  width: 16px;
  height: 16px;
  overflow: hidden;
  background-color: transparent;
  border: 2px solid var(--AlertDefault);
  border-radius: 50%;

  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: '';
    background: var(--AlertDefault);
    transform: translateX(-100%);
    animation-name: delay-progress;
    animation-duration: inherit;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
}

@keyframes delay-progress {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}
</style>
