<script setup lang="ts">
import { IconName, IconSize } from '@leon-hub/icons';

import { VIcon } from '@components/v-icon';

import { JumbotronFooterButton, JumbotronIconKind, VJumbotron } from 'web/src/components/Jumbotron';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { BetCashoutMode } from 'web/src/modules/cashout/enums';
import { VLoaderDelayed } from 'web/src/components/Loader';

import type { BetCashOutEmits, BetCashOutProps } from '../../types';
import useBetCashOut from './useBetCashOut';

const props = withDefaults(defineProps<BetCashOutProps>(), {
  price: 0,
  currency: 'USD',
  mode: BetCashoutMode.DEFAULT,
  errorMessage: '',
  betName: '',
});

const emits = defineEmits<BetCashOutEmits>();

const {
  isPending,
  isCompleted,
  isError,
  iconName,
  mainText,
  formattedPriceValue,
  formattedBetPriceValue,
  primaryButtonLabel,
  showCancel,
  showValue,
  isGreenPrice,
  emitMainButtonClick,
  emitCancel,
} = useBetCashOut(props, emits);
</script>

<template>
  <div v-auto-id="'BetCashOut'" :class="$style['bet-cash-out']">
    <VLoaderDelayed
      v-if="!isLoaded"
    />
    <VJumbotron
      v-else
      :icon="iconName"
      :text="mainText"
      :icon-kind="isCompleted ? JumbotronIconKind.SUCCESS : undefined"
      :class="$style['bet-cash-out__jumbotron']"
    >
      <div
        v-if="isPending"
        :class="$style['bet-cash-out__pending']"
      />
      <template v-if="!isError">
        <div
          v-if="props.betName"
          :class="$style['bet-cash-out__row']"
        >
          <span :class="$style['bet-cash-out__name']">{{ props.betName }}</span>
        </div>
        <div
          v-if="showValue"
          :class="$style['bet-cash-out__row']"
        >
          <span>{{ formattedPriceValue }}</span>
        </div>
        <div
          v-else-if="props.betPrice"
          :class="$style['bet-cash-out__row']"
        >
          <template v-if="!isCompleted">
            <span>{{ formattedBetPriceValue }}</span>
            <VIcon
              :class="$style['bet-cash-out__price-arrow']"
              :name="IconName.ARROW"
              :size="IconSize.SIZE_16"
            />
          </template>
          <span :class="{[$style['bet-cash-out__green']]: isGreenPrice}">{{ formattedPriceValue }}</span>
        </div>
      </template>
      <template #footer>
        <JumbotronFooterButton
          v-if="!isError"
          :label="primaryButtonLabel"
          :kind="ButtonKind.PRIMARY"
          :is-loading="isPending"
          full-width
          @click="emitMainButtonClick"
        />
        <JumbotronFooterButton
          v-if="showCancel"
          :label="$t('JSPACC_SETT_CANCEL')"
          :kind="ButtonKind.TRANSPARENT"
          :disabled="isPending"
          full-width
          :class="$style['bet-cash-out__cancel-button']"
          @click="emitCancel"
        />
      </template>
    </VJumbotron>
  </div>
</template>

<style module lang="scss">
.bet-cash-out {
  &,
  &__jumbotron {
    position: relative;
    display: block;
    width: 100%;
  }

  &--overlay {
    padding: 16px;
  }

  &__row {
    @include medium\18\20;

    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--TextDefault);
  }

  &__green {
    color: var(--BrandDefault);
  }

  &__price-arrow {
    margin: 0 4px;
    color: var(--TextSecondary);
  }

  &__cancel-button {
    margin: 20px 0 0;
  }

  &__name {
    @include medium\16\20\025;

    padding-bottom: 8px;
    color: var(--TextPrimary);
  }

  &__pending {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: var(--Layer1);
    border-radius: 5px 5px 0 0;
    opacity: 0.7;
  }
}
</style>
