import {
  computed,
} from 'vue';

import type { VInputInputEvent } from 'web/src/components/Input';
import { InputEventType } from 'web/src/components/Input/enums';

import type {
  DropdownSelectEmits, CommonDataSelectProperties,
} from '../types';

interface UseDropdownEvents {
  onFocus: () => void;
  onBlur: () => void;
  onChange: (value: string) => void;
}

export default function useDropdownEvents(
  props: CommonDataSelectProperties,
  emit: DropdownSelectEmits,
  closeCallback: () => void | Promise<void>,
  clearSearchQueryCallback: () => void,
): UseDropdownEvents {
  const inputEvent = computed<VInputInputEvent>(() => ({
    target: {
      name: props.name || '',
      value: props.selectedValue || '',
    },
  }));

  const onChange = (value: string) => {
    clearSearchQueryCallback();
    closeCallback();
    if (value === props.selectedValue) {
      return;
    }
    emit(InputEventType.CHANGE, {
      target: {
        value,
        name: props.name || '',
      },
    });
  };

  const onFocus = (): void => {
    emit(InputEventType.FOCUS, inputEvent.value);
  };

  const onBlur = (): void => {
    emit(InputEventType.BLUR, inputEvent.value);
  };

  return {
    onFocus,
    onChange,
    onBlur,
  };
}
