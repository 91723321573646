import type PaymentsCreditCardBeautifierOptions from 'web/src/modules/payments/form-widgets/CreditCardSelector/types';

export default class PaymentsCreditCardBeautifier {
  private readonly options: Required<PaymentsCreditCardBeautifierOptions> = {
    tokenStar: String.fromCodePoint(8226), // •
    maxNumberOfTokenStars: 4,
    maxNumbersLength: 4,
  };

  public static SEPARATOR = '_';

  constructor(
    private type: string,
    private pan: string,
    options?: PaymentsCreditCardBeautifierOptions,
  ) {
    if (options) {
      this.options = { ...this.options, ...options };
    }
  }

  public typeToString(): string {
    return this.getCreditCardType();
  }

  public panToString(): string {
    return this.getCreditCardToken();
  }

  public getName(): string {
    return `${this.typeToString()} ${this.panToString()}`;
  }

  private getCreditCardToken(): string {
    const lastStarIndex = this.pan.lastIndexOf('*');
    let lastNumbers = this.pan.slice(lastStarIndex + 1);
    if (lastNumbers.length > this.options.maxNumbersLength) {
      lastNumbers = lastNumbers.slice(lastNumbers.length - this.options.maxNumbersLength);
    }
    return `${this.options.tokenStar.repeat(this.options.maxNumberOfTokenStars)} ${lastNumbers}`;
  }

  private getCreditCardType(): string {
    let newType = '';
    switch (this.type) {
      case 'MASTER_CARD_NEW':
      case 'MASTER_CARD':
        newType = 'MasterCard';
        break;
      case 'JCB':
        newType = 'JCB';
        break;
      case 'MAESTRO':
      case 'DISCOVER':
      case 'VISA_ELECTRON':
      case 'VISA':
      case 'AMERICAN_EXPRESS':
      case 'CHINA_UNIONPAY':
      case 'DINERS_CLUB_CARTE_BLANCHE':
      case 'DINERS_CLUB_ENROUTE':
      case 'DINERS_CLUB_INTERNATIONAL':
      case 'INTER_PAYMENT_TM':
      case 'INSTA_PAYMENT':
      case 'LASER':
      case 'DANKORT':
      case 'SOLO':
      case 'SWITCH':
      case 'UATP':
        newType = this.toLowerNoDash();
        break;
      default:
        break;
    }
    return newType;
  }

  private toLowerNoDash(): string {
    const arrayOfStrings: string[] = this.type.split(PaymentsCreditCardBeautifier.SEPARATOR);
    const uppercased = arrayOfStrings.map((item) => item.charAt(0).toUpperCase() + item.slice(1).toLowerCase());
    return uppercased.join(' ');
  }
}
