export type FastTrackBaseEndpointsOptions = {
  endpoint: string;
};

export type FastTrackBaseEndpointsResponse = {
  fusionUrl: string;
  pusherKey: string;
  pusherRegion: string;
};

export type FastTrackDoLoginOptions = {
  fusionUrl: string;
  sessionId: string;
};

export type FastTrackBaseResponse<T> = {
  Data?: T;
  Success: boolean;
  Errors: null | string[];
};

export type FastTrackUser = {
  UserId: number;
};

export type FastTrackAuthentication = {
  AuthToken: string;
};

export type FastTrackDoLoginResponseData = {
  User: FastTrackUser;
  Authentication: FastTrackAuthentication;
};

export type FastTrackDoLoginResponse = FastTrackBaseResponse<FastTrackDoLoginResponseData>;

export type FastTrackGetMessagesOptions = {
  fusionUrl: string;
  authToken: string;
  unreadOnly?: boolean;
  inboxOnly?: boolean;
};

export const fastTrackMessageEvents = Object.freeze(['shoutout', 'message', 'inbox'] as const);
export type FastTrackMessageEvent = typeof fastTrackMessageEvents[number];
export const fastTrackMessageDisplayType = Object.freeze(['push', 'silent', ''] as const);
export type FastTrackMessageDisplayType = typeof fastTrackMessageDisplayType[number];

export type FastTrackMessage = {
  MessageId: number;
  UserId: number;
  Event: FastTrackMessageEvent;
  Title: string;
  Message: string;
  PreviewText?: string;
  FooterText?: string;
  Data?: Record<string, string>;
  CTAButtonLink?: string;
  CTAButtonText?: string;
  CTAButton2Link?: string;
  CTAButton2Text?: string;
  ImageUrl?: string;
  IsRead: boolean;
  DisplayType: FastTrackMessageDisplayType;
  Date: string;
  Expires?: string;
};

export type FastTrackGetMessagesResponse = FastTrackBaseResponse<FastTrackMessage[]>;

export type FastTrackMarkMessageAsReadOptions = {
  fusionUrl: string;
  authToken: string;
  messageId: number;
};

export type FastTrackDeleteMessageOptions = {
  fusionUrl: string;
  authToken: string;
  messageId: number;
};

export type FastTrackMarkMessageAsReadResponse = FastTrackBaseResponse<boolean | undefined | null>;
export type FastTrackDeleteMessageResponse = FastTrackBaseResponse<boolean | undefined | null>;
