import { assert, isEnumOfType } from '@leon-hub/guards';

import type { Region } from 'web/src/modules/sportline/types';
import { CountryCode } from 'web/src/components/CountryFlag/VCountryFlag/enums';

/**
 * It just forces set the representation icon's type to CountryCode
 */
export function getRegionCountryCode(region?: Maybe<Region>): Maybe<CountryCode> {
  const icon = region?.representation.icon;
  if (!icon) { return null; }
  assert(isEnumOfType(CountryCode)(icon));
  return icon;
}
