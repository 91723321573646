import { assert } from '@leon-hub/guards';
import { logger } from '@leon-hub/logging';
import { isDebugEnabled } from '@leon-hub/debug';

import { useRootStore } from 'web/src/modules/core/store';

export default function initLogger(): void {
  const version = process.env.VUE_APP_VERSION;
  const name = process.env.VUE_APP_PACKAGE_NAME;
  assert(version, 'Expected name to be defined');
  assert(name, 'Expected name to be defined');
  logger.init({
    appName: name,
    appVersion: version,
    get remoteSaveAction() { return process.env.NODE_ENV === 'production' ? useRootStore().log : undefined; },
    isConsoleOutputAllowed: () => (process.env.NODE_ENV === 'development' || useRootStore().isDevIP) && !isDebugEnabled(),
  });
}
