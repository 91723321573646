import type { Ref } from 'vue';
import { ref } from 'vue';

import type { FocusableElement } from '../types';
import { isFocusable } from '../guards/isFocusable';

interface UseFocusableElement {
  focus: () => void;
  focusable: Ref<FocusableElement | undefined>;
}

export const useFocusableElement = (): UseFocusableElement => {
  const element = ref<FocusableElement>();
  const focusOnElement = (): void => {
    if (!process.env.VUE_APP_OS_IOS && isFocusable(element.value)) {
      element.value.focus();
    }
  };
  return {
    focus: focusOnElement,
    focusable: element,
  };
};
