/* eslint-disable class-methods-use-this */
import type { WatchStopHandle } from 'vue';
import { watch, toRef, nextTick } from 'vue';
import type { RouteLocationNormalized } from 'vue-router';

import { isArray } from '@leon-hub/guards';
import { Timer } from '@leon-hub/utils';

import type { AppVueRouter } from 'web/src/modules/core/services/router/types';
import type { GetRouteSeoConfig } from 'web/src/modules/core/services/router/seo';
import DefaultPageSeoController from 'web/src/modules/seo/DefaultPageSeoController';
import { useSportlineCybersportStore } from 'web/src/modules/sportline/submodules/cybersport/store';

export default class CybersportPageSeoController extends DefaultPageSeoController {
  getSeoConfig(
    router: AppVueRouter,
    from: RouteLocationNormalized,
    to: RouteLocationNormalized,
  ): Promise<GetRouteSeoConfig> {
    return new Promise<GetRouteSeoConfig>((resolve, reject) => {
      const cybersportStore = useSportlineCybersportStore();
      const activeRegionElement = toRef(cybersportStore, 'activeRegionElement');

      let unwatch: Optional<WatchStopHandle> = watch(activeRegionElement, async (value) => {
        const regionId = value?.region.id;
        const regionName = (value?.region.navigationParameters.urlName || '').toLowerCase();
        const routeRegionParam = to.params?.region || '';
        const routeRegionName = (isArray(routeRegionParam)
          ? routeRegionParam.join(',')
          : routeRegionParam).toLowerCase();

        if (regionName === routeRegionName) {
          // await fix error while immediate fired callback
          await nextTick();
          unwatch?.();
          unwatch = undefined;

          const params = regionId
            ? { ...(to.params || {}), id: regionId }
            : to.params;

          resolve({ ...to, params });
        }
      }, { immediate: true });

      Timer.setTimeout(() => {
        unwatch?.();
        unwatch = undefined;
        reject(new Error('CybersportPageSeoController: await active region element timeout'));
      }, 5000);
    });
  }
}
