import type { ComputedRef } from 'vue';
import { computed, inject } from 'vue';
import { useRouter } from 'vue-router';

import { normalizeError } from '@leon-hub/errors';
import { logger } from '@leon-hub/logging';
import { useEventsBus, BusEvent } from '@leon-hub/event-bus';

import getInternalPath from 'web/src/utils/url/getInternalPath';
import isSameOrigin from 'web/src/utils/url/isSameOrigin';
import { setHashWithoutRouter } from 'web/src/utils/url/hash';
import { ModalProvidable } from 'web/src/modules/dialogs/enums';

import type { VDynamicContentLinkProps, VDynamicContentLinkEmits } from '../types';

export interface VDynamicContentLinkComposable {
  computedRel: ComputedRef<string | undefined>;
  handleClick: (event: Event) => void;
}

export default function useVDynamicContentLink(
  props: VDynamicContentLinkProps,
  emit: VDynamicContentLinkEmits,
): VDynamicContentLinkComposable {
  const bus = useEventsBus();
  const router = useRouter();
  const modalContext = inject(ModalProvidable.modalContext, {
    insideModal: false,
  });

  const isHashLink = computed<boolean>(() => !!props?.href?.startsWith('#'));
  const internalPath = computed<string | null>(() => getInternalPath(props?.href || '', router));
  const insideModal = computed(() => modalContext.insideModal);
  const computedRel = computed(() => {
    if (isSameOrigin(props?.href || '')) {
      return props?.rel || undefined;
    }

    // eslint-disable-next-line sonarjs/no-nested-template-literals
    return `${props.rel ? `${props.rel} ` : ''}noopener noreferrer`;
  });

  const hashValue = computed<string>(() => {
    if (isHashLink.value) {
      return props?.href?.split('#')[1] || '';
    }
    try {
      const { hash } = new URL(props?.href || '');
      return hash.slice(1);
    } catch (rawError) {
      const error = normalizeError(rawError);

      logger.error(error);
      return '';
    }
  });

  const shouldHandleClick = computed<boolean>(() => Boolean(
    !process.env.VUE_APP_RENDERING_SSR
    && (!props.target || props.target === '_self')
    && (isHashLink.value || internalPath.value),
  ));

  function handleClick(event: Event): void {
    if (props.useEmitter) {
      event.preventDefault();
      emit('click-link', {
        href: props?.href || '',
      });
      return;
    }

    if (!shouldHandleClick.value) {
      return;
    }
    event.preventDefault();
    if (isHashLink.value) {
      bus.emit(
        BusEvent.SCROLL_TO_ELEMENT_ID,
        { id: hashValue.value, inModal: insideModal.value, fromCmsContent: props.isCmsContent },
      );
      if (!insideModal.value) {
        setHashWithoutRouter(hashValue.value);
      }
    }
    if (internalPath.value) {
      void router.push({ path: internalPath.value, hash: hashValue.value });
    }
  }

  return {
    handleClick,
    computedRel,
  };
}
