import type { CoreSportResponse } from 'web/src/modules/sportline/types/rest';
import type { SportRepresentationSetting, SportRepresentationSettings } from 'web/src/modules/sportline/types/settings';
import { isSportFamilyEquals, isSportIdEquals } from 'web/src/modules/sportline/utils';

export function findSportRepresentationSetting(
  sportRepresentationSettings?: Maybe<SportRepresentationSettings>,
  sport?: Pick<CoreSportResponse, 'id' | 'family'>,
): Maybe<SportRepresentationSetting> {
  if (!sportRepresentationSettings) { return null; }

  const id = sport?.id ? String(sport?.id) : undefined;
  const family = sport?.family;

  const settingsKey = Object.keys(sportRepresentationSettings)
    .find((key: string | number) => (
      (!!family && isSportFamilyEquals(String(key), family))
      || (!!id && isSportIdEquals(String(key), id))
    ));

  if (!settingsKey) { return null; }

  return sportRepresentationSettings[settingsKey as keyof typeof sportRepresentationSettings];
}
