import {
  isString,
  isUndefined,
  isValidInterface,
} from '@leon-hub/guards';

import type { SportlinePageIdentifier as SportEventsPageIdentifier } from 'web/src/modules/sportline/types/navigation';

export default function isSportEventsPageIdentifier(value: unknown): value is SportEventsPageIdentifier {
  return isValidInterface<SportEventsPageIdentifier>(value, {
    sportFamily: [isString],
    regionId: [isUndefined, isString],
    regionUrlName: [isUndefined, isString],
    leagueId: [isUndefined, isString],
    leagueUrlName: [isUndefined, isString],
    sportEventId: [isUndefined, isString],
    sportEventUrlName: [isUndefined, isString],
  });
}
