<script lang="ts" setup>
import type { CustomerHistoryListItemDetailsEmits, CustomerHistoryListItemDetailsProps, CustomerHistoryListItemDetailsSlots } from './types';

defineProps<CustomerHistoryListItemDetailsProps>();

const emit = defineEmits<CustomerHistoryListItemDetailsEmits>();

defineSlots<CustomerHistoryListItemDetailsSlots>();

function emitClick(event: Event): void {
  emit('click', event);
}
</script>

<template>
  <li v-auto-id="'CustomerHistoryListItemDetails'"
    :class="{
      [$style['history-item']]: true,
      [$style['history-item--hover']]: hover,
    }"
    @click="emitClick"
  >
    <slot name="default">
      <div :class="$style['history-item__left']">
        <slot name="left">
          <span>
            {{ label }}
          </span>
          <span
            v-if="winBoost"
            :class="$style['history-item__percent']"
          >{{ winBoost }}</span>
        </slot>
      </div>
      <span
        :class="{
          [$style['history-item__right']]: true,
          [$style['history-item__right--column']]: unBoostedPrice,
        }"
      >
        <slot name="right">
          <span
            v-if="unBoostedPrice"
            :class="$style['history-item__back-price']"
          >
            {{ unBoostedPrice }}
          </span>
          <span
            v-if="unBoostedOdds"
            :class="$style['history-item__back-coefficient']"
          >
            {{ unBoostedOdds }}
          </span>
          <span
            :class="{
              [$style['history-item__positive']]: highlight,
              [$style['history-item__negative']]: highlightRed,
            }"
          >
            {{ value }}
          </span>
          <span
            v-if="oddsBoost"
            :class="$style['history-item__percent']"
          >{{ oddsBoost }}</span>
          <slot name="icon" />
        </slot>
      </span>
    </slot>
  </li>
</template>

<style lang="scss" module>
.history-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: $historyItemPadding;
  cursor: default;
  background-color: $historyItemBackgroundColor;

  &--hover {
    cursor: pointer;
  }

  &__left,
  &__right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__left {
    @include regular\14\20\025;

    color: var(--TextPrimary);
    text-align: left;
  }

  &__right {
    @include medium\14\20\025;

    color: var(--TextDefault);
    text-align: right;

    &--green {
      color: var(--BrandDefault);
    }

    &--column {
      flex-direction: column;
      align-items: end;
    }
  }

  &__back-price,
  &__back-coefficient {
    @include regular\13\20;

    color: var(--TextSecondary);
    text-decoration: line-through;
  }

  &__back-coefficient {
    padding-right: 8px;
  }

  &__percent {
    @include medium\14\20\025;

    height: 20px;
    padding: 0 4px;
    margin-left: 4px;
    color: var(--BrandDefault);
    text-align: center;
    background: var(--BrandDefaultOpacity02);
    border-radius: 4px;
  }

  &__number {
    display: inline-flex;
    align-items: center;
  }

  &__positive {
    color: var(--BrandDefault);
  }

  &__negative {
    color: var(--ErrorText);
  }
}
</style>
