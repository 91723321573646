<script lang="ts" setup>
import {
  LobbyItemType,
  LobbyItemWidget,
} from '@leon-hub/api-sdk';

import { useLobbyItem } from 'web/src/modules/lobby/components/LobbyItem/composables';
import type {
  LobbyItemEmits,
  LobbyItemProps,
} from 'web/src/modules/lobby/components/LobbyItem/types';
import LobbyEgsGamesItem from 'web/src/modules/lobby/components/LobbyEgsGamesItem/LobbyEgsGamesItem.vue';
import { LobbyItemLayoutType } from 'web/src/modules/lobby/enums';
import type {
  EgsGame,
  EgsGroup,
  EgsTopWinner,
} from 'web/src/modules/egs/types';
import LobbyEgsGroupItem from 'web/src/modules/lobby/components/LobbyEgsGroupItem/LobbyEgsGroupItem.vue';
import LobbyEgsTopWinnerItem from 'web/src/modules/lobby/components/LobbyEgsTopWinnerItem/LobbyEgsTopWinnerItem.vue';
import { isSwiperWidget } from 'web/src/modules/lobby/utils';
import type {
  LobbyBanner,
  LobbyPromotion,
} from 'web/src/modules/lobby/types';
import LobbyPromotionItem from 'web/src/modules/lobby/components/LobbyPromotionItem/LobbyPromotionItem.vue';
import LobbyEgsTopWinnerSimpleItem from 'web/src/modules/lobby/components/LobbyEgsTopWinnerSimpleItem/LobbyEgsTopWinnerSimpleItem.vue';
import LobbyPromotionItemV2 from 'web/src/modules/lobby/components/LobbyPromotionItemV2/LobbyPromotionItemV2.vue';
import LobbySportTopMatchItem from 'web/src/modules/lobby/components/LobbySportTopMatchItem/LobbySportTopMatchItem.vue';
import type { SportEventDetails } from 'web/src/modules/sportline/submodules/event-details/types';
import LobbyBannerItem from 'web/src/modules/lobby/components/LobbyBannerItem/LobbyBannerItem.vue';

const props = defineProps<LobbyItemProps>();
const emits = defineEmits<LobbyItemEmits>();

const {
  testType,
  onClick,
  onClickPlay,
  onClickDemo,
} = useLobbyItem(props, emits);

function isEgsGame(item: unknown, typeId: LobbyItemType): item is EgsGame {
  return typeId === LobbyItemType.GAMES_CATEGORY;
}

function isEgsGroups(item: unknown, typeId: LobbyItemType): item is EgsGroup {
  return typeId === LobbyItemType.GROUPS;
}

function isEgsTopWinners(item: unknown, typeId: LobbyItemType): item is EgsTopWinner {
  return typeId === LobbyItemType.TOP_WINNERS;
}

function isLobbyPromotion(item: unknown, typeId: LobbyItemType): item is LobbyPromotion {
  return typeId === LobbyItemType.PROMOTIONS;
}

function isLobbySportItem(item: unknown, typeId: LobbyItemType): item is SportEventDetails {
  return typeId === LobbyItemType.SPORTLINE_TOP_MATCHES;
}

function isLobbyBannerItem(item: unknown, typeId: LobbyItemType): item is LobbyBanner {
  return typeId === LobbyItemType.BANNERS;
}
</script>

<template>
  <LobbyEgsGamesItem
    v-if="isEgsGame(item, lobbyItemType)"
    v-data-test="{ el: 'egs-lobby-item', type: testType }"
    :item="item"
    :is-description-hidden="!'1' && layoutType === LobbyItemLayoutType.GAME_SQUARE"
    :is-panel-visible="widgetId === LobbyItemWidget.BIG_SMALL_ROWS_LIST || widgetId === LobbyItemWidget.SQUARE_ROWS_LIST"
    :is-square-image="layoutType === LobbyItemLayoutType.GAME_SQUARE"
    :is-jackpot="widgetId === LobbyItemWidget.JACKPOT_LIST || widgetId === LobbyItemWidget.JACKPOT_SWIPE_LIST"
    :jackpot-animation-duration="jackpotAnimationDuration"
    :is-edit-mode="isEditMode"
    :is-removed="isRemoved"
    :has-image-force-load="hasImageForceLoad"
    :is-name-visible="isNameVisible"
    @click="onClick"
    @click-demo="onClickDemo"
    @click-play="onClickPlay"
  />
  <LobbyEgsGroupItem
    v-else-if="isEgsGroups(item, lobbyItemType)"
    v-data-test="{ el: 'egs-lobby-item', type: testType }"
    :item="item"
    is-big
    @click="onClick"
  />
  <template
    v-else-if="isEgsTopWinners(item, lobbyItemType)"
  >
    <LobbyEgsTopWinnerSimpleItem
      v-if="null"
      v-data-test="{ el: 'egs-lobby-item', type: testType }"
      :item="item"
      @click="onClick"
    />
    <LobbyEgsTopWinnerItem
      v-else
      v-data-test="{ el: 'egs-lobby-item', type: testType }"
      :item="item"
      :is-small="isSwiperWidget(widgetId)"
      @click="onClick"
    />
  </template>
  <template
    v-else-if="isLobbyPromotion(item, lobbyItemType)"
  >
    <LobbyPromotionItemV2
      v-if="null"
      v-data-test="{ el: 'egs-lobby-item', type: testType }"
      :item="item"
      @click="onClick"
    />
    <LobbyPromotionItem
      v-else
      v-data-test="{ el: 'egs-lobby-item', type: testType }"
      :item="item"
      @click="onClick"
    />
  </template>
  <LobbySportTopMatchItem
    v-else-if="isLobbySportItem(item, lobbyItemType)"
    :sport-event-details="item"
  />
  <LobbyBannerItem
    v-else-if="isLobbyBannerItem(item, lobbyItemType)"
    :item="item"
  />
</template>
