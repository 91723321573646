<script lang="ts" setup>
import { PasswordInput } from 'web/src/components/Input';

import { useBaseFormWidgetEvents } from '../../composables';
import useFormPassword from './useFormPassword';
import type {
  FormWrapperWidgetProperties,
  BaseFormWidgetEmits,
} from '../../types';

const props = defineProps<FormWrapperWidgetProperties>();
const emit = defineEmits<BaseFormWidgetEmits>();

const {
  emitChange,
  emitInput,
  emitFocus,
  emitBlur,
} = useBaseFormWidgetEvents(emit);

const { componentProps } = useFormPassword(props);
</script>

<template>
  <PasswordInput v-auto-id="'FormPassword'"
    v-bind="componentProps"
    @input="emitInput"
    @change="emitChange"
    @focus="emitFocus"
    @blur="emitBlur"
  />
</template>
