import type { AbstractErrorOptions } from '@leon-hub/errors';
import { ApiErrorCode } from '@leon-hub/api';
import { AppError } from '@leon-hub/app-errors';

type ApplyEventRunnersOddsChangesErrorOptions = Without<AbstractErrorOptions, 'code'>;

export class ApplyEventRunnersOddsChangesError extends AppError {
  constructor(options: ApplyEventRunnersOddsChangesErrorOptions) {
    super({
      silent: true,
      ...options,
      code: ApiErrorCode.API_UNEXPECTED_ERROR,
      message: `Sportline: ${options?.message || 'Unexpected apply odds changes error'}`,
    });
  }
}
