import type { CombinedSlipEntryInfo } from '../types';

interface Payload {
  availableItems: CombinedSlipEntryInfo[];
  sameStakeForSingles: boolean;
  commonStakeValue: number;
}

export default function getMultiSinglesTotalPrice({
  availableItems,
  sameStakeForSingles,
  commonStakeValue,
}: Payload): number {
  const sum = availableItems.reduce((accumulator, currentEntry) => {
    if (sameStakeForSingles) {
      return accumulator + commonStakeValue;
    }
    return accumulator + Number(currentEntry.metaInfo?.stakeValue || 0);
  }, 0);
  return Math.floor(sum * 100) / 100;
}
