import type { Ref } from 'vue';

export type PluralFormFactory = (number: number) => Ref<string>;

export type PluralFormFactories = [PluralFormFactory, PluralFormFactory, PluralFormFactory];

/**
 * @see web/src/utils/LanguageMicroSettings.ts
 *
 * Select one of factories to create Ref with translation
 */
export function selectPluralForm(
  factories: PluralFormFactories,
  number: number,
  locale: string,
): Ref<string> {
  if (locale === 'ru_RU') {
    let n = Math.abs(number);
    n %= 100;
    if (n >= 5 && n <= 20) {
      return factories[2](number);
    }
    n %= 10;
    if (n === 1) {
      return factories[0](number);
    }
    if (n >= 2 && n <= 4) {
      return factories[1](number);
    }
    return factories[2](number);
  }

  if (number === 1) {
    return factories[0](number);
  }

  return factories[2](number);
}
