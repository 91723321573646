import type { AddressApi } from './createAddressApi';
import createAddressApi from './createAddressApi';

let addressApi: AddressApi | undefined;

export default function useAddressApi(): AddressApi {
  if (!addressApi) {
    addressApi = createAddressApi();
  }

  return addressApi;
}
