import type { Ref, WatchStopHandle } from 'vue';
import { ref, watch } from 'vue';

import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';

import useAnalytics from 'web/src/modules/analytics/composables/useAnalytics';

interface MarketsListAnalyticsProps {
  isDisplayStatistic: Ref<boolean>;
}

interface MarketsListAnalyticsComposable {
  timeStartedWatchStatistics: Ref<Maybe<number>>;
  startAnalyticsWatchers(): void;
  stopAnalyticsWatchers(): void;
}

export default function useMarketsListAnalytics(props: MarketsListAnalyticsProps): MarketsListAnalyticsComposable {
  const analytics = useAnalytics();

  let isDisplayStatisticWatcher: Maybe<WatchStopHandle> = null;
  const timeStartedWatchStatistics = ref<Maybe<number>>(null);

  function sendAnalytic(): void {
    if (timeStartedWatchStatistics.value) {
      const seconds = Math.ceil((Date.now() - timeStartedWatchStatistics.value) / 1000);
      analytics.push(AnalyticsEvent.Z_STATISTICS_BUTTON, {
        statisticsButtonScores24: {
          timeOnStatisticsSection: seconds,
        },
      });
      timeStartedWatchStatistics.value = null;
    }
  }

  function startAnalyticsWatchers(): void {
    if (process.env.VUE_APP_LAYOUT_PHONE) {
      isDisplayStatisticWatcher = watch(props.isDisplayStatistic, (value) => {
        if (value) {
          timeStartedWatchStatistics.value = Date.now();
        } else {
          sendAnalytic();
        }
      }, {
        // should start immediate if display turned on by default
        immediate: props.isDisplayStatistic.value,
      });
    }
  }

  function stopAnalyticsWatchers(): void {
    isDisplayStatisticWatcher?.();
    isDisplayStatisticWatcher = null;

    if (process.env.VUE_APP_LAYOUT_PHONE) {
      sendAnalytic();
    }
  }

  return {
    timeStartedWatchStatistics,
    startAnalyticsWatchers,
    stopAnalyticsWatchers,
  };
}
