import { ComponentKey } from '../types';
import type { ComponentResult } from '../types';
import AbstractFingerprintComponent from '../AbstractFingerprintComponent';

export default class ScreenResolutionComponent extends AbstractFingerprintComponent {
  constructor() {
    super(ComponentKey.ScreenResolution);
  }

  getComponentResult(): Promise<ComponentResult> {
    return this.result([window.screen.width, window.screen.height]);
  }
}
