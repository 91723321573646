import type {
  ProfileBonusesFreespinControllerProps,
} from 'web/src/modules/profile/submodules/bonuses/controllers/ProfileBonusesFreespinController/types';
import type { Layout } from 'web/src/modules/profile/submodules/bonuses/types';
import { getBonusTermsLink } from 'web/src/modules/profile/submodules/bonuses/utils';

import getTotalCount from './getTotalCount';

export default function getLayoutProperties(
  props: Pick<ProfileBonusesFreespinControllerProps, 'bonus'>,
): Layout {
  return {
    bonusTitle: props.bonus?.campaignName || '',
    stepCount: props.bonus?.stepCount,
    stepNumber: props.bonus?.stepNumber,
    termsLink: getBonusTermsLink(props.bonus),
    bonusBadgeText: getTotalCount(props),
  };
}
