/** This enum is used for define css classes */
export const enum HeadlineWideLayoutType {
  Live = 'live',
  PreMatch = 'prematch',
  Outright = 'outright',
  Default = 'default',
}

/** This enum is used for define css classes */
export const enum HeadlineWideLayoutName {
  Default = 'default',
  Low = 'low',
  Large = 'large',
  Auto = 'auto',
}
