export class IndexedDb<T = unknown> {
  db: IDBDatabase | null = null;

  dbName: string;

  storeName: string;

  constructor({ dbName, storeName }: { dbName: string; storeName: string }) {
    if (typeof indexedDB === 'undefined') {
      throw new Error('Not supported');
    }
    this.dbName = dbName;
    this.storeName = storeName;
  }

  private async init(): Promise<IDBDatabase> {
    if (this.db) return this.db;
    return new Promise<IDBDatabase>((resolve, reject) => {
      const request = indexedDB.open(this.dbName, 1);
      request.onerror = () => reject(request.error);
      request.onsuccess = () => {
        this.db = request.result;
        resolve(this.db);
      };
      request.onupgradeneeded = () => {
        const db = request.result;
        db.createObjectStore(this.storeName, { keyPath: 'key' });
      };
    });
  }

  async setItem(key: string, value: T): Promise<void> {
    const db = await this.init();
    return new Promise<void>((resolve, reject) => {
      const transaction = db.transaction([this.storeName], 'readwrite');
      const store = transaction.objectStore(this.storeName);
      const request = store.put({ key, value });
      request.onsuccess = () => resolve();
      request.onerror = () => reject(request.error);
    });
  }

  async getItem<U extends T | undefined>(key: string): Promise<U> {
    const db = await this.init();
    return new Promise<U>((resolve, reject) => {
      const transaction = db.transaction([this.storeName], 'readonly');
      const store = transaction.objectStore(this.storeName);
      const request = store.get(key) as IDBRequest<U>;
      function isStorageStructure(value: unknown): value is { value: U } {
        return typeof value === 'object' && value !== null && 'value' in value;
      }
      request.onsuccess = () => {
        const { result } = request;
        if (!result) {
          resolve(undefined as U);
        } else if (isStorageStructure(result)) {
          resolve(result.value);
        } else {
          reject(new Error('Invalid storage item', { cause: request.result }));
        }
      };
      request.onerror = () => reject(request.error);
    });
  }

  async deleteItem(key: string): Promise<void> {
    const db = await this.init();
    return new Promise<void>((resolve, reject) => {
      const transaction = db.transaction([this.storeName], 'readwrite');
      const store = transaction.objectStore(this.storeName);
      const request = store.delete(key);
      request.onsuccess = () => resolve();
      request.onerror = () => reject(request.error);
    });
  }

  async clear(): Promise<void> {
    const db = await this.init();
    return new Promise<void>((resolve, reject) => {
      const transaction = db.transaction([this.storeName], 'readwrite');
      const store = transaction.objectStore(this.storeName);
      const request = store.clear();
      request.onsuccess = () => resolve();
      request.onerror = () => reject(request.error);
    });
  }
}
