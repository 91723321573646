<script lang="ts" setup>
import { IconSize } from '@leon-hub/icons';

import { VIcon } from '@components/v-icon';

import PinButton from '../PinButton/PinButton.vue';
import type { PinButtonsEmits, PinButtonsProps } from './types';
import { usePinButtons } from './composables';

const props = withDefaults(defineProps<PinButtonsProps>(), {});

const emits = defineEmits<PinButtonsEmits>();

const {
  activeButton,
  pinButtonsList,
  onKeyDown,
  onKeyUp,
} = usePinButtons(props, emits);

function onClick(value: string): void {
  emits('click', value);
}
</script>

<template>
  <div v-auto-id="'PinButtons'" :class="$style['pin-buttons']">
    <div :class="$style['pin-buttons__wrapper']">
      <PinButton
        v-for="button in pinButtonsList"
        :key="button.value"
        :value="button.value"
        :is-filled="button.isFilled"
        :is-active="button.value === activeButton || button.isActive"
        @click="onClick(button.value)"
        @touchstart="onKeyUp"
        @touchend="onKeyDown"
      >
        {{ button.title }}

        <VIcon
          v-if="button.iconName"
          :name="button.iconName"
          :size="IconSize.SIZE_24"
        />
      </PinButton>
    </div>
  </div>
</template>

<style lang="scss" module>
.pin-buttons {
  display: inline-flex;
  justify-content: center;
  width: 100%;

  &__wrapper {
    display: inline-flex;
    flex-wrap: wrap;
    grid-gap: 16px;
    width: 100%;
    max-width: 272px;
  }
}
</style>
