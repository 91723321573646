<script lang="ts" setup>
import { IconName, IconSize } from '@leon-hub/icons';
import { ProgramParticipationStatus } from '@leon-hub/api-sdk';

import { VIcon } from '@components/v-icon';
import type { VIconProps } from '@components/v-icon';

import ReferralProgramMemberItem
  from 'web/src/modules/referral-program/components/ReferralProgramMemberList/ReferralProgramMemberItem.vue';
import type {
  ReferralProgramMemberEmits,
  ReferralProgramMemberListProps,
} from 'web/src/modules/referral-program/components/ReferralProgramMemberList/types';
import type { ExtendedReferral } from 'web/src/modules/referral-program/types/ReferralProgramDocuments';

import { useReferralProgramMemberList } from './composables';

const props = defineProps<ReferralProgramMemberListProps>();

const emits = defineEmits<ReferralProgramMemberEmits>();

const {
  isAnimated,
  isOpen,
  blockStyle,
  memberList,
  toggleOpen,
  setBlockRef,
} = useReferralProgramMemberList(props);

const toggleIcon: VIconProps = {
  name: IconName.EXPAND_UP,
  size: IconSize.SIZE_16,
};

function onReferralClick(item: ExtendedReferral, colorId: number): void {
  emits('click', item, colorId);
}
</script>

<template>
  <div v-auto-id="'ReferralProgramMemberList'" :class="$style['referral-program-member-list']">
    <template v-for="data in memberList">
      <div
        v-if="data.list.length"
        :key="data.name"
        :class="{
          [$style['referral-program-member-list__item']] : true,
          [$style['referral-program-member-list__item--flexible']] : true,
          [$style['referral-program-member-list__item--open']] : isOpen[data.name],
          [$style['referral-program-member-list__item--animated']] : isAnimated,
        }"
        @click="toggleOpen(data.name)"
      >
        <p :class="$style['referral-program-member-list__item-title']">
          <span>{{ data.title }}</span>
          <VIcon
            :class="$style['referral-program-member-list__item-toggle']"
            v-bind="toggleIcon"
          />
        </p>

        <div
          :style="{ height: blockStyle[data.name] }"
          :class="$style['referral-program-member-list__item-container']"
        >
          <div
            :ref="setBlockRef(data.name)"
            :class="$style['referral-program-member-list__item-body']"
          >
            <ReferralProgramMemberItem
              v-for="(item, index) in data.list"
              :key="item.customerId"
              :color-id="(index % 10) + 1"
              :status="item.status || ProgramParticipationStatus.PENDING"
              :formatted-amount="item.formattedAmount"
              :amount="item.amount"
              :date="item.date"
              :referral-member-name="item.name"
              :player-reward="item.playerReward"
              :is-incognito="item.isIncognito"
              :total-steps="item.status === ProgramParticipationStatus.PENDING ? item.totalSteps : undefined"
              :current-step="item.status === ProgramParticipationStatus.PENDING ? item.currentStep : undefined"
              :initials="item.initials"
              @click.stop="onReferralClick(item, (index % 10) + 1)"
            />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<style lang="scss" module>
.referral-program-member-list {
  $self: &;

  padding: 0 0 64px;

  &__item {
    &-body {
      @include bodyStyles;

      display: flex;
      flex-direction: column;
      overflow: hidden;
      border-radius: var(--BorderRadius);
    }

    &-title {
      @include titleStyles;

      display: flex;
      justify-content: space-between;
      padding: 16px 8px 12px;
      margin: 0;
      color: var(--TextSecondary);
    }

    &--flexible {
      #{$self}__item-title {
        @include is-app-layout-desktop {
          cursor: pointer;
        }
      }

      #{$self}__item-container {
        overflow: hidden;
      }
    }

    #{$self}__item-toggle {
      transform: rotate(180deg);
    }

    &--open {
      #{$self}__item-toggle {
        transform: rotate(0deg);
      }
    }

    &--animated {
      &#{$self}__item--flexible {
        #{$self}__item-container {
          transition: height 0.4s ease-out;
        }
      }

      #{$self}__item-toggle {
        transition: 0.4s ease-out;
      }

      &#{$self}__item--open {
        #{$self}__item-toggle {
          transition: 0.6s ease-out;
        }

        #{$self}__item-container {
          transition: height 0.6s ease-out;
        }
      }
    }
  }
}
</style>
