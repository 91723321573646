import type { ComputedRef, Ref } from 'vue';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';

import { BusEvent, useEventsBus } from '@leon-hub/event-bus';
import { RouteName } from '@leon-hub/routing-config-names';

import { BetSlipResultState, TopLevelTabs } from '../../../enums';
import type { SlipLayoutProps } from '../types';

interface UseSlipLayoutDesktop {
  onKeydown(event: KeyboardEvent): void;
  goToHelp(): void;
  scrollDisabled: Ref<boolean>;
  showResultOverlay: ComputedRef<boolean>;
  showBannerBlock: ComputedRef<boolean>;
}

export default function useSlipLayoutDesktop(props: SlipLayoutProps): UseSlipLayoutDesktop {
  const bus = useEventsBus();
  const router = useRouter();

  const showResultOverlay = computed<boolean>(() => props.showBookingOverlay
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    || (props.resultState === BetSlipResultState.SUCCESS || props.resultState === BetSlipResultState.ERROR));

  const scrollDisabled = ref<boolean>(false);

  const showBannerBlock = computed<boolean>(() => !props.isSettingsVisible && props.activeTopTabId === TopLevelTabs.SLIP);

  const disableScroll = (): void => {
    scrollDisabled.value = true;
  };

  const enableScroll = (): void => {
    scrollDisabled.value = false;
  };

  const onKeydown = (event: KeyboardEvent): void => {
    // TODO LEONWEB-10763
    if (scrollDisabled.value && (event.key === 'ArrowDown' || event.key === 'ArrowUp')) {
      event.preventDefault();
    }
  };

  const goToHelp = (): void => {
    void router.push({ name: RouteName.SUPPORT });
  };

  bus.on(BusEvent.LAYOUT_CONTENT_SCROLL_DISABLE, disableScroll);
  bus.on(BusEvent.LAYOUT_CONTENT_SCROLL_ENABLE, enableScroll);

  return {
    onKeydown,
    goToHelp,
    scrollDisabled,
    showResultOverlay,
    showBannerBlock,
  };
}
