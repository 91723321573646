import {
  toRef,
  watch,
} from 'vue';

import GoogleService from 'web/src/modules/analytics/services/googleService';
import { useMetricsStore } from 'web/src/modules/analytics/store';

export default function initGoogleAnalytics(): void {
  const metricsStore = useMetricsStore();
  const googleCounterId = toRef(metricsStore, 'googleCounterId');

  watch(googleCounterId, (newValue) => {
    if (newValue) {
      GoogleService
        .getInstance()
        .setCounterId(newValue);
    }
  }, {
    immediate: true,
  });
}
