import { PostMessageEvent } from '@leon-hub/postmessage-bus';

import { IframeMessageEvent } from '../VIframe/enums';

export default class IframePostMessageEvent extends PostMessageEvent {
  static readonly iframeApplicationReady = new PostMessageEvent(IframeMessageEvent.IframeApplicationReady);

  static readonly iframeDocumentResize = new PostMessageEvent<{ height: number }>(
    IframeMessageEvent.IframeDocumentResize,
  );
}
