import { computed } from 'vue';

import type { CasinoLevelSettingWithStatus } from 'web/src/modules/casino-loyalty-program/store/types';
import {
  CasinoLoyaltyProgramLevelStatus,
} from 'web/src/modules/casino-loyalty-program/store/types';
import type { I18nFunctionReactive } from 'web/src/modules/i18n/types';
import type { FormatMoneyFunc } from 'web/src/modules/money/types';

export default function getDescriptionText(
  name: string,
  selectedLevel: Optional<CasinoLevelSettingWithStatus>,
  selectedLevelStatus: string | undefined,
  isLastLevel: boolean,
  $t: I18nFunctionReactive,
  formatMoney: FormatMoneyFunc,
): string {
  const baseParams = computed(() => ({
    bonusValue: formatMoney(selectedLevel?.bonusAmount || 0),
    multiplier: `${selectedLevel?.multiplier}x`,
    freespinValue: `${selectedLevel?.freespinCount}`,
  }));

  if (selectedLevelStatus === CasinoLoyaltyProgramLevelStatus.SUCCESS) {
    if (isLastLevel) {
      return $t('WEB2_LOYALTY_PROGRAM_COMPLETED_FINAL_LEVEL_DESCR_V2', baseParams).value;
    }
    return $t('WEB2_LOYALTY_PROGRAM_COMPLETED_LEVEL_DESCR_V2', baseParams).value;
  }

  const params = computed(() => ({
    ...baseParams.value,
    pointsValue: formatMoney(selectedLevel?.requiredPoints || 0, { hideCurrency: true }),
    levelEGS: selectedLevel?.name || name,
  }));

  if (selectedLevelStatus === CasinoLoyaltyProgramLevelStatus.PROCESS) {
    return $t('WEB2_LOYALTY_PROGRAM_NOT_COMPLETED_LEVEL_DESCR_V2', params).value;
  }

  return $t('WEB2_LOYALTY_PROGRAM_LOCKED_LEVEL_DESCR_V2', params).value;
}
