import {
  isBoolean,
  isEnumOfType,
  isObject,
  isOptionalString,
  isValidInterface,
} from '@leon-hub/guards';

import { CaptchaRequesterStrategy, ReCaptchaTheme } from 'web/src/modules/captcha/store/enums';

import type { VCaptchaProperties } from '../types';

export const isVCaptchaProperties = (value: unknown): value is VCaptchaProperties => isObject(value)
  && isValidInterface<VCaptchaProperties>(value, {
    value: isOptionalString,
    captchaRequesterStrategy: isEnumOfType(CaptchaRequesterStrategy),
    captchaEnabled: isBoolean,
    reCaptchaTheme: isEnumOfType(ReCaptchaTheme),
    isEnforced: isBoolean,
  });
