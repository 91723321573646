import { toRef } from 'vue';
import type { RouteLocationNamedRaw } from 'vue-router';
import {
  useRouter,
} from 'vue-router';

import { RouteName } from '@leon-hub/routing-config-names';

import { useCustomerDataStore } from 'web/src/modules/customer/store';
import { useIdentificationPhase } from 'web/src/modules/identification';
import PaymentsDepositsNavigationParametersHolder from 'web/src/modules/core/composables/navigation/PaymentsDepositsNavigationParametersHolder';

export interface PaymentsDepositsNavigationComposable {
  pushToDepositsPage: (options?: {
    query?: Record<string, string>;
    needToReplace?: boolean;
  }) => void;
}

export default function usePaymentsDepositsNavigation(): PaymentsDepositsNavigationComposable {
  const router = useRouter();
  const customerDataStore = useCustomerDataStore();
  const lastDepositedSystemId = toRef(customerDataStore, 'lastDepositedSystemId');

  function pushToDepositsPage(options?: {
    query?: Record<string, string>;
    needToReplace?: boolean;
  }): void {
    const isIdentOrIdentFeatureNotEnabled = process.env.VUE_APP_FEATURE_IDENT_ENABLED
      ? useIdentificationPhase().isIdentifiedUser.value
      : true;

    const query = options?.query ?? {};

    let routeParams: RouteLocationNamedRaw = {
      name: RouteName.DEPOSITS,
      query,
    };

    if (!process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED && lastDepositedSystemId.value && isIdentOrIdentFeatureNotEnabled) {
      if (process.env.VUE_APP_LAYOUT_PHONE) {
        routeParams = {
          name: RouteName.DEPOSITS_DETAILS,
          params: {
            name: lastDepositedSystemId.value,
          },
          query,
        };
      } else {
        PaymentsDepositsNavigationParametersHolder.setLastDepositedId(lastDepositedSystemId.value);
      }
    }

    if (options?.needToReplace) {
      void router.replace(routeParams);
    } else {
      void router.push(routeParams);
    }
  }

  return {
    pushToDepositsPage,
  };
}
