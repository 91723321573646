import type {
  CurrentPaymentSystemDataDocument,
  PaymentSystemDataDocument,
  PaymentSystemLogoSrcReturnTypes,
} from 'web/src/modules/payments/types/documentTypes';

export const getPaymentSystemLogoSrc = (
  paymentSystem: CurrentPaymentSystemDataDocument | PaymentSystemDataDocument | null,
  isDark?: boolean,
): PaymentSystemLogoSrcReturnTypes => {
  const themeName = isDark ? 'dark' : 'light';
  let src = '';

  if (paymentSystem?.logoSrcThemed) {
    src = `${paymentSystem?.logoSrcThemed
      .replace(/^\//, '')
      .replace('{theme}', themeName)}`;
  }

  return {
    alt: paymentSystem?.logoName || '',
    src,
  };
};
