import { isString } from '@leon-hub/guards';

import type {
  CustomFilter,
  SportFamily,
} from 'web/src/modules/sportline/enums';

type SportFamilyForFilter = SportFamily | CustomFilter;

export default function isOptionalFilterSportFamily(
  value: unknown,
  allowedSportFamily: string[],
  customFilterValues: string[] = [],
): value is (SportFamilyForFilter | undefined) {
  if (!value) {
    return true;
  }

  if (!isString(value)) {
    return false;
  }

  const activeFilter = value.toLowerCase();
  return [
    ...allowedSportFamily,
    ...customFilterValues,
  ].map((filterValue) => filterValue.toLowerCase()).includes(activeFilter);
}
