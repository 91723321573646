import type { IMarketTypeTag } from '@leon-hub/api-sdk';
import { marketTypeTagHandicap, marketTypeTagRegular, marketTypeTagTotal } from '@leon-hub/api-sdk';

import { SportEventMarketTypeTag } from 'web/src/modules/sportline/enums';

const marketTypeMap: Record<SportEventMarketTypeTag, IMarketTypeTag> = {
  [SportEventMarketTypeTag.HANDICAP]: marketTypeTagHandicap,
  [SportEventMarketTypeTag.TOTAL]: marketTypeTagTotal,
  [SportEventMarketTypeTag.REGULAR]: marketTypeTagRegular,
};

export function sportEventMarketTypeToApi(input?: SportEventMarketTypeTag): IMarketTypeTag | undefined {
  if (!input) {
    return undefined;
  }
  return marketTypeMap[input];
}
