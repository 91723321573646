enum ProgressKind {
  DEFAULT = 'default',
  HIGHLIGHT = 'highlight',
  WHITE = 'white',
  BLACK = 'black',
  YELLOW = 'yellow',
  WHITE_BG = 'white-bg',
  MINT = 'mint',
  ERROR = 'error',
}

export default ProgressKind;
