import type { DropListMenuOptionGroup } from 'web/src/components/DropListSelect/types';

export function getOptionGroupsLimitedByCount(input: DropListMenuOptionGroup[], count: number): DropListMenuOptionGroup[] {
  let restCount = count;
  const result: DropListMenuOptionGroup[] = [];
  for (const group of input) {
    if (restCount <= 0) {
      break;
    }
    const groupItemsCount = group.options.length;
    if (groupItemsCount <= restCount) {
      result.push(group);
      restCount -= groupItemsCount;
    } else {
      const tempGroup: DropListMenuOptionGroup = {
        caption: group.caption,
        options: group.options.slice(0, restCount),
      };
      restCount = 0;
      result.push(tempGroup);
    }
  }
  return result;
}
