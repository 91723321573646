import { assert } from '@leon-hub/guards';

import isBuiltinConfig from './guards/isBuiltinConfig';
import type BuiltinConfig from './types/BuiltinConfig';
import { defaultConfig } from './defaultConfig';

declare global {
  interface Window {
    initConfig?: unknown;
  }
}

export const getBuiltinConfig = (): BuiltinConfig => {
  const config = typeof window === 'undefined' ? defaultConfig : window.initConfig || defaultConfig;
  assert(isBuiltinConfig(config), `Invalid builtin config: ${JSON.stringify(config)}`);
  return config;
};
