import type { Market } from 'web/src/modules/sportline/types';

import type { MarketsGridCell } from '../types';
import { getMarketsGridCellId } from './getMarketsGridCellId';

export function createEmptyMarketsGridCell(market?: Market): MarketsGridCell {
  return {
    id: getMarketsGridCellId(market),
    title: market?.name ?? '',
    markets: [],
    isOpened: false,
    hasZeroMargin: false,
  };
}
