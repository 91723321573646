import { assert, isString } from '@leon-hub/guards';

export const getStyleNumberValue = (
  computedStyle: CSSStyleDeclaration,
  property: keyof CSSStyleDeclaration,
): number => {
  const styleNumberValue = computedStyle[property];
  assert(isString(styleNumberValue));
  return parseInt(styleNumberValue, 10) || 0;
};
