import type { UIField } from '@leon-hub/api-sdk';
import { assert, isFunction } from '@leon-hub/guards';

import { isFormWidget } from 'web/src/components/Form/guards';

import type { PropertiesMapByWidget, PropertiesMapById, UiFieldRawOptions } from '../types';

export default function getRelatedUiFieldCustomProperties(
  field: UIField,
  propertiesMapByWidget: PropertiesMapByWidget,
  propertiesMapById: PropertiesMapById,
): UiFieldRawOptions {
  const { id, widget } = field;
  assert(
    isFormWidget(widget),
    'field.widget should be of type FormControlType',
  );
  const propertiesByWidget = propertiesMapByWidget.get(widget) ?? {};
  const propertiesById = propertiesMapById.get(id) ?? {};
  const mergedProperties = {
    ...(isFunction(propertiesByWidget) ? propertiesByWidget(field) : propertiesByWidget),
    ...(isFunction(propertiesById) ? propertiesById(field) : propertiesById),
  };
  const result: UiFieldRawOptions = {};
  const allKeys = Object.keys(mergedProperties);
  for (const key of allKeys) {
    const relatedValue = mergedProperties[key];
    result[key] = isFunction(relatedValue) ? relatedValue(field) : relatedValue;
  }
  return result;
}
