import type { Ref } from 'vue';
import {
  computed,
  toRef,
  watch,
} from 'vue';
import { useRoute } from 'vue-router';

import { lobbyTypeSlotsGamePage, lobbyTypeLiveGamePage } from '@leon-hub/api-sdk';

import { useLobbyStore } from 'web/src/modules/lobby/store';
import { useCasinoGameStore } from 'web/src/modules/casino/submodules/game/store';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { isLiveEgsRoute } from 'web/src/modules/egs/utils';
import { isCasinoGameRouteName } from 'web/src/modules/casino/utils';
import type { LobbyBlockProps } from 'web/src/modules/lobby/components/LobbyBlock/types';

export interface CasinoGameListContainerComposable {
  lobbyBlockProps: Ref<LobbyBlockProps>;
}

export default function useCasinoGameListContainer(): CasinoGameListContainerComposable {
  const { loadLobbyItems } = useLobbyStore();
  const gameStore = useCasinoGameStore();
  const scgStore = useSiteConfigStore();
  const egsSkeletons = toRef(scgStore, 'egsSkeletons');
  const activeGame = toRef(gameStore, 'activeGame');

  const route = useRoute();
  const lobbyType = computed(() => (isLiveEgsRoute(route)
    ? lobbyTypeLiveGamePage
    : lobbyTypeSlotsGamePage));

  const skeletons = computed(() => {
    if (!egsSkeletons.value) {
      return [];
    }

    return isLiveEgsRoute(route)
      ? egsSkeletons.value.liveGamePage
      : egsSkeletons.value.slotsGamePage;
  });

  const excludeGameIds = computed(() => (activeGame.value ? [activeGame.value.id] : []));

  const lobbyBlockProps = computed<LobbyBlockProps>(() => ({
    lobbyType: lobbyType.value,
    shouldReplaceGameRoutes: true,
    excludeGameIds: excludeGameIds.value,
    skeletons: skeletons.value,
  }));

  watch(() => route.fullPath, () => {
    if (isCasinoGameRouteName(route.name)) {
      void loadLobbyItems({
        type: lobbyType.value,
        excludeGameIds: excludeGameIds.value,
      });
    }
  });

  return {
    lobbyBlockProps,
  };
}
