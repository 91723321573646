import type { Ref } from 'vue';
import {
  computed,
  onBeforeUnmount,
  onMounted,
  ref,
  toRef,
  watch,
} from 'vue';
import {
  useRoute,
  useRouter,
} from 'vue-router';

import {
  startCodeTypeRedirect,
  GameOpenMethod,
} from '@leon-hub/api-sdk';
import type { StartCodeType } from '@leon-hub/api-sdk';
import {
  CasinoRouteName,
  RouteName,
} from '@leon-hub/routing-config-names';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';
import {
  assert,
  isObject,
} from '@leon-hub/guards';
import { sleep } from '@leon-hub/utils';
import isGeneralApiError from '@leon-hub/api/src/client/errors/isGeneralApiError';
import { stopVpn } from '@leon-hub/cordova';
import { logger } from '@leon-hub/logging';

import type {
  EgsGame,
  FreespinInfoData,
  StartGameData,
} from 'web/src/modules/egs/types';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useEgsFavoriteGamesStore } from 'web/src/modules/egs/submodules/favorite-games/store';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import { useCasinoGameAnalytics } from 'web/src/modules/casino/composables';
import {
  isCasinoGameRouteName,
  isCasinoLobbyRouteName,
} from 'web/src/modules/casino/utils';
import {
  useAppFullscreenStore,
  useRouterStore,
  useSiteConfigStore,
} from 'web/src/modules/core/store';
import { useCasinoAnalyticsStore } from 'web/src/modules/casino/submodules/analytics';
import { mapActiveGameToAnalytics } from 'web/src/modules/casino/submodules/game/utils';
import { CasinoGameCloseMethod } from 'web/src/modules/casino/submodules/game/enums';
import { useCasinoGameStore } from 'web/src/modules/casino/submodules/game/store';
import { useCasinoActiveGame } from 'web/src/modules/casino/submodules/game/composables';
import IFrameTimeoutError from 'web/src/modules/errors/errors/IFrameTimeoutError';
import type {
  EgsGameErrorButtonAction,
  EgsGameErrorData,
} from 'web/src/modules/casino/types';
import { isLiveEgsRoute } from 'web/src/modules/egs/utils';

import type { FetchStartError } from './types';
import type { CasinoGameSeoParameters } from '../types';
import { startVpnGame } from './utils';
import useOpenGameInNewWindow from './useOpenGameInNewWindow';
import useCasinoGameExitUrls from './useCasinoGameExitUrls';
import useCasinoGameStartPostMessage from './useCasinoGameStartPostMessage';
import useCasinoGameError from './useCasinoGameError';
import { usePrerenderWidgetUrl } from './usePrerenderWidgetUrl';

export interface CasinoGameRoutePageComposable {
  isGamePreviewMode: Ref<boolean>;
  closeUrl: Ref<string>;
  iframeLoadTimeout: Ref<number>;
  metaParameters: Ref<CasinoGameSeoParameters>;
  activeGame: Ref<EgsGame | undefined>;
  isStartGameLoading: Ref<boolean>;
  isDemo: Ref<boolean>;
  isLoading: Ref<boolean>;
  isIframeLoaded: Ref<boolean>;
  showBonusReminder: Ref<boolean>;
  isLoyaltySwitcherEnabled: Ref<boolean>;
  isLoyaltySidebarOpened: Ref<boolean>;
  gameUrl: Ref<string | undefined>;
  hideLimitModalOnGamePage: Ref<boolean | undefined>;
  maxBetLimit: Ref<StartGameData['maxBetLimit'] | undefined>;
  freespinInfo: Ref<FreespinInfoData>;
  gameStartCode: Ref<StartCodeType | undefined>;
  onToggleFavorite(): void;
  onClose(mode?: string): void;
  onPlay(isDemo?: boolean): void;
  restartGame(): void;
  onIframeLoaded(): void;
  onClickSwitch(isRealPlay?: boolean): void;
  onClickChat(): void;
  onToggleFullscreen(): void;
  onGoToLogin(): void;
  onRealPlay(): void;
  toggleSidebarOpened(): void;
  onErrorClick(action: EgsGameErrorButtonAction): void;
  onIframeError(error: unknown): void;
  errorData: Ref<EgsGameErrorData | undefined>;
  handleFreespinDialog: () => void;
  isReminderModalVisible: Ref<boolean>;
}

function isFetchStartError(error: unknown): error is Error & { code: string } {
  return isObject(error) && !!error.code;
}

export default function useCasinoGameRoutePage(): CasinoGameRoutePageComposable {
  const { $translate } = useI18n();
  const { isLoggedIn } = useIsLoggedIn();
  const router = useRouter();
  const route = useRoute();
  const analytics = useAnalytics();

  const fullscreenStore = useAppFullscreenStore();
  const { setFullscreenMode } = fullscreenStore;

  const routerStore = useRouterStore();
  const previousRouteName = toRef(routerStore, 'previousRouteName');

  const gamesStore = useCasinoGameStore();
  const isGamePreviewMode = toRef(gamesStore, 'isGamePreviewMode');
  const startGameData = toRef(gamesStore, 'startGameData');
  const isDemoState = toRef(gamesStore, 'isDemo');
  const isStartGameLoadingState = toRef(gamesStore, 'isStartGameLoading');
  const isLoyaltySidebarOpened = toRef(gamesStore, 'isLoyaltySidebarOpened');
  const startGameAfterLogin = toRef(gamesStore, 'startGameAfterLogin');
  const isAppInFullscreenMode = toRef(fullscreenStore, 'isAppInFullscreenMode');
  const {
    backToLobbyLocation,
    closeUrl,
    getBankingUrl,
    getExitUrl,
  } = useCasinoGameExitUrls();
  const { activeGame } = useCasinoActiveGame();
  const hideLimitModalOnGamePage = computed(() => startGameData.value?.hideLimitModalOnGamePage);
  const showBonusMaxBetLimitPopup = computed(() => startGameData.value?.showBonusMaxBetLimitPopup);
  const maxBetLimit = computed(() => startGameData.value?.maxBetLimit);
  const freespinInfo = computed(() => startGameData.value?.freeSpinInfo);
  const prerenderWidgetUrl = usePrerenderWidgetUrl(activeGame);
  const gameUrl = computed(() => (
    process.env.VUE_APP_PRERENDER
      ? prerenderWidgetUrl.value
      : startGameData.value?.gameUrl
  ));
  const gameStartCode = computed(() => (
    process.env.VUE_APP_PRERENDER
      ? startCodeTypeRedirect
      : startGameData.value?.startCode
  ));

  // eslint-disable-next-line max-len
  const showBonusReminder = computed(() => !hideLimitModalOnGamePage.value && !!maxBetLimit.value && !freespinInfo.value && !!showBonusMaxBetLimitPopup.value);
  const isLoading = computed(() => !activeGame.value || !gameUrl.value || isStartGameLoadingState.value);
  const isDemoUnavailable = computed(() => !isDemoState.value && !isLoggedIn.value);

  const scgStore = useSiteConfigStore();
  const scgExternalGames = toRef(scgStore, 'externalGames');
  const isLoyaltySwitcherEnabled = toRef(scgStore, 'isEgsLoyaltyEnabled');

  const { toggleFavoriteGame } = useEgsFavoriteGamesStore();
  const { sendOpenGameLobbyMetrika } = useCasinoAnalyticsStore();
  const { sendOpenGameMetrika } = useCasinoGameAnalytics();
  const {
    setDemoStartMode,
    setStartGameAfterLogin,
    setIsGamePreview,
    startGame,
    setLoyaltySidebarOpened,
    toggleSidebarOpened,
    resetActiveGame,
    clearStartGameData,
    reloadActiveGame,
  } = gamesStore;
  const { openGameInNewWindow } = useOpenGameInNewWindow();

  const metaParameters = computed<CasinoGameSeoParameters>(() => ({
    name: activeGame.value?.name,
    group: activeGame.value?.group.name,
    description: activeGame.value?.description,
    gameType: activeGame.value?.typeId,
    metaDescription: activeGame.value?.metaDescription,
  }));

  const isStartGameLoading = ref(false);
  const isIframeLoaded = ref(false);
  const isReminderModalVisible = ref(showBonusReminder.value);
  const startGameError = ref<FetchStartError>();
  const reloadCounter = ref(0);

  const iframeLoadTimeout = computed<number>(() => scgExternalGames.value?.gameLoadTimeout ?? 0);

  function goToLoginPage(): void {
    void router.push({ name: RouteName.LOGIN });
  }

  function onToggleFavorite(): void {
    if (activeGame.value) {
      void toggleFavoriteGame(activeGame.value);
    }

    if (!isLoggedIn.value) {
      goToLoginPage();
    }
  }

  function onClose(mode?: string): void {
    setFullscreenMode(false);
    router.forceBack(backToLobbyLocation.value);
    if (mode === CasinoGameCloseMethod.SWIPE) {
      analytics.push(AnalyticsEvent.Z_CLOSE_PREVIEW_GAME, {
        closePreviewGame: CasinoGameCloseMethod.SWIPE,
      });
    }
  }

  function openGameFromLobbyMetrics(isDemo?: boolean): void {
    if (!isDemo && isCasinoLobbyRouteName(previousRouteName.value)) {
      sendOpenGameLobbyMetrika();
    }
  }

  function onGoToLogin(): void {
    if (isAppInFullscreenMode.value) {
      onToggleFullscreen();
    }
    setStartGameAfterLogin(true);
    goToLoginPage();
  }

  function sendAnalyticStartGameEvent(): void {
    if (activeGame.value) {
      analytics.pushEgsGameEvent(mapActiveGameToAnalytics(activeGame.value));
    }
  }

  function checkIsCurrentGame(id: string): boolean {
    return isCasinoGameRouteName(route.name) && id === activeGame.value?.id;
  }

  async function handleStartError(id: string, isDemo: boolean, retryCounter: number, error: unknown): Promise<void> {
    if (!checkIsCurrentGame(id)) {
      return;
    }

    assert(isFetchStartError(error));
    const { code } = error;

    if (process.env.VUE_APP_PLATFORM_CORDOVA && code.toString() === 'VPN_ALLOWED_ONLY') {
      if (retryCounter >= 2) {
        showGameUnavailableError();
        return;
      }

      await sleep(2000);
      await startIframeGame(id, isDemo, retryCounter + 1);
      return;
    }

    if (isGeneralApiError(error)) {
      throw error;
    } else {
      startGameError.value = {
        message: error.message,
        code: code.toString(),
      };
    }
  }

  async function startIframeGame(id: string, isDemo: boolean, retryCounter = 0): Promise<void> {
    if (!activeGame.value || isGamePreviewMode.value || activeGame.value.blockType) {
      return;
    }

    if (!isLoggedIn.value && !isDemo) {
      return;
    }

    isIframeLoaded.value = false;
    try {
      await startGame({
        id,
        isDemo,
        bankingUrl: getBankingUrl(),
        exitUrl: getExitUrl(),
      }, !!process.env.VUE_APP_PLATFORM_CORDOVA && activeGame.value.isVpnAllowed);
    } catch (error) {
      await handleStartError(id, isDemo, retryCounter, error);
      return;
    }

    if (checkIsCurrentGame(id) && !gameUrl.value) {
      startGameError.value = {
        code: 'GAME_NOT_FOUND',
        message: $translate('WEB2_CASINO_GAME_NOT_FOUND').value,
      };
    }
  }

  function showGameUnavailableError(): void {
    startGameError.value = {
      code: 'GAME_UNAVAILABLE',
      message: $translate('WEB2_GAME_UNAVAILABLE').value,
    };
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  async function play(isDemo = false): Promise<void> {
    startGameError.value = undefined;

    if (!activeGame.value || activeGame.value.isUnavailable) {
      return;
    }

    if (!isLoggedIn.value && !isDemo) {
      if (process.env.VUE_APP_LAYOUT_PHONE || activeGame.value.hasDemo) {
        onGoToLogin();
      }
      return;
    }

    if (activeGame.value.openMethod === GameOpenMethod.IFRAME) {
      setDemoStartMode(isDemo);
      setIsGamePreview(false);
    }

    if (process.env.VUE_APP_PLATFORM_CORDOVA && activeGame.value.isVpnAllowed) {
      isIframeLoaded.value = false;
      isStartGameLoading.value = false;

      try {
        await startVpnGame(activeGame.value.id);
        isStartGameLoading.value = false;
      } catch {
        showGameUnavailableError();
        isStartGameLoading.value = false;
        setIsGamePreview(true);

        return;
      }
    }

    // Player closed window while waiting for VPN connection
    if (process.env.VUE_APP_PRERENDER || !activeGame.value) {
      return;
    }

    if (activeGame.value.openMethod === GameOpenMethod.WINDOW) {
      openGameInNewWindow(activeGame.value.id, isDemo);
    } else {
      void startIframeGame(activeGame.value.id, isDemo);
    }

    sendAnalyticStartGameEvent();
  }

  function onPlay(isDemo = false): void {
    openGameFromLobbyMetrics(isDemo);
    sendOpenGameMetrika(isDemo);
    setDemoStartMode(isDemo);
    void play(isDemo);
  }

  function restartGame(): void {
    if (process.env.VUE_APP_LAYOUT_DESKTOP || !isGamePreviewMode.value) {
      void play(isDemoState.value);
    }
  }

  function onIframeLoaded(): void {
    isIframeLoaded.value = true;
  }

  function onClickSwitch(isRealGame = false): void {
    if (isRealGame && !isLoggedIn.value) {
      onGoToLogin();
      return;
    }

    if (isLoading.value) {
      return;
    }

    void play(!isRealGame);
  }

  function onClickChat(): void {
    if (isLoggedIn.value) {
      void router.push({ name: RouteName.SUPPORT });
    } else {
      onGoToLogin();
    }
  }

  function onToggleFullscreen(): void {
    setFullscreenMode(!isAppInFullscreenMode.value);
    setLoyaltySidebarOpened(false);
  }

  function onRealPlay(): void {
    void play(false);
  }

  function onErrorClick(action: EgsGameErrorButtonAction): void {
    switch (action) {
      case 'login':
        goToLoginPage();
        break;
      case 'refresh':
        reloadCounter.value += 1;
        restartGame();
        break;
      case 'providers':
        void router.push({
          name: isLiveEgsRoute(route) ? CasinoRouteName.CASINO_LIVE_GROUPS : CasinoRouteName.CASINO_GROUPS,
        });
        break;
      case 'close':
        onClose();
        break;
      default:
        break;
    }
  }

  onBeforeUnmount(() => {
    setFullscreenMode(false);
    if (!isCasinoGameRouteName(route.name)) {
      resetActiveGame();
    }

    clearStartGameData();
  });

  useCasinoGameStartPostMessage(onClose, restartGame);

  function setDefaultGameMode(): void {
    const isGameStartMode = !!process.env.VUE_APP_LAYOUT_DESKTOP;

    setIsGamePreview(!isGameStartMode);
  }

  async function onRouteChanged(): Promise<void> {
    if (!isCasinoGameRouteName(route.name)) {
      return;
    }

    if (process.env.VUE_APP_LAYOUT_DESKTOP
      && !isDemoState.value
      && !isLoggedIn.value
      && activeGame.value?.hasDemo
    ) {
      setDemoStartMode(true);
      onGoToLogin();
    }

    if (process.env.VUE_APP_LAYOUT_PHONE && startGameAfterLogin.value && !isLoggedIn.value) {
      setStartGameAfterLogin(false);
    }

    isIframeLoaded.value = false;
    isStartGameLoading.value = false;
    startGameError.value = undefined;
    reloadCounter.value = 0;
    clearStartGameData();
    setDefaultGameMode();

    if (process.env.VUE_APP_PLATFORM_CORDOVA) {
      await stopVpn();
    }

    if (startGameAfterLogin.value || !isGamePreviewMode.value) {
      void play(isDemoState.value);
      if (process.env.VUE_APP_LAYOUT_PHONE) {
        setStartGameAfterLogin(false);
      }
    }
  }
  watch(() => route.fullPath, onRouteChanged, { immediate: true });

  async function onLogin(): Promise<void> {
    await reloadActiveGame();

    if (startGameAfterLogin.value && isLoggedIn.value) {
      setDemoStartMode(false);
      setStartGameAfterLogin(false);
    }

    if (!isDemoState.value) {
      await play(false);
    }
  }

  function onLogout(): void {
    if (!isDemoState.value) {
      onClose();
    }
  }

  function onIframeError(error: unknown): void {
    if (!isIframeLoaded.value) {
      startGameError.value = {
        code: 'GAME_UNAVAILABLE',
        message: $translate('WEB2_GAMES_GENERAL_ERROR_MESSAGE').value,
      };

      // Send to opensearch logs
      let msg = 'Error in response from EGS game provider resource';
      if (error instanceof IFrameTimeoutError) {
        msg = 'Timeout while waiting for a response from the EGS game provider resource';
      } else if (error instanceof Error) {
        msg = `Error in response from EGS game provider resource ${error.message}`;
      }
      logger.warn(msg);
    }
  }

  watch(isLoggedIn, (newValue) => {
    if (newValue) {
      void onLogin();
    } else {
      onLogout();
    }
  });

  onMounted(() => {
    if (freespinInfo.value) {
      isReminderModalVisible.value = false;
    }
  });

  watch(showBonusReminder, (newValue) => {
    isReminderModalVisible.value = newValue;
  });

  function handleFreespinDialog(): void {
    isReminderModalVisible.value = false;
  }

  const { errorData } = useCasinoGameError(
    activeGame,
    isDemoUnavailable,
    startGameError,
    reloadCounter,
  );

  return {
    errorData,
    isGamePreviewMode,
    metaParameters,
    activeGame,
    isStartGameLoading,
    isLoading,
    isIframeLoaded,
    isDemo: isDemoState,
    gameUrl,
    gameStartCode,
    showBonusReminder,
    closeUrl,
    isLoyaltySwitcherEnabled,
    isLoyaltySidebarOpened,
    hideLimitModalOnGamePage,
    maxBetLimit,
    iframeLoadTimeout,
    onIframeLoaded,
    onToggleFavorite,
    onClose,
    onPlay,
    restartGame,
    onClickSwitch,
    onClickChat,
    onToggleFullscreen,
    onGoToLogin,
    onRealPlay,
    toggleSidebarOpened,
    onErrorClick,
    handleFreespinDialog,
    onIframeError,
    isReminderModalVisible,
    freespinInfo,
  };
}
