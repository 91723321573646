import type {
  Ref,
} from 'vue';
import {
  computed,
  nextTick,
  onBeforeMount,
  ref,
  toRef,
} from 'vue';

import { LobbyGameType } from '@leon-hub/api-sdk';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika/src/constants/index';
import { nextAnimationFrame } from '@leon-hub/html-utils';

import type { EgsGroup } from 'web/src/modules/egs/types';
import { getGroupByUrl } from 'web/src/modules/egs/utils';
import { useEgsGroupsStore } from 'web/src/modules/egs/submodules/groups/store';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import { EgsRouterParametersHandler } from 'web/src/modules/egs/submodules/navigation/utils';
import { useLobbySearchGroups } from 'web/src/modules/lobby/composables';
import { useScrollIntoView } from 'web/src/modules/core/composables/scroll';

interface CasinoGroupsRoutePageComposable {
  list: Ref<EgsGroup[]>;
  selectedId: Ref<string>;
  searchText: Ref<string>;
  isSearchInProgress: Ref<boolean>;
  isNoSearchResults: Ref<boolean>;
  onSearchInput: (value: string) => void;
  onProviderSelect: (egsGroup: EgsGroup) => void;
  onSelectedItemLoaded: (element: HTMLElement) => Promise<void>;
  onSearchClear: () => void;
}

export default function useCasinoGroupsRoutePage(): CasinoGroupsRoutePageComposable {
  const selectedGroupUrl = ref<string>();

  const analytics = useAnalytics();
  const { scrollIntoView } = useScrollIntoView();
  const currentGroups = toRef(useEgsGroupsStore(), 'currentGroups');

  // eslint-disable-next-line max-len
  const group = computed(() => (selectedGroupUrl.value ? getGroupByUrl(currentGroups.value, selectedGroupUrl.value) : undefined));
  const selectedId = computed(() => group.value?.id || '');

  onBeforeMount(() => {
    selectedGroupUrl.value = EgsRouterParametersHandler.getSelectedGroup();
    EgsRouterParametersHandler.setSelectedGroup(undefined);
  });

  const {
    searchText,
    isSearchInProgress,
    isNoSearchResults,
    list,
    onSearchInput,
    onSearchClear,
  } = useLobbySearchGroups();

  function onProviderSelect(egsGroup: EgsGroup): void {
    analytics.push(AnalyticsEvent.Z_SEARCH_BY_PROVIDER, {
      searchDetails: {
        providers: {
          [egsGroup?.type === LobbyGameType.LIVE
            ? 'live' : 'slots']: egsGroup?.name || 'all',
        },
      },
    });
  }

  async function onSelectedItemLoaded(element: HTMLElement): Promise<void> {
    await nextTick();
    await nextAnimationFrame();
    await nextTick();

    scrollIntoView(element);
  }

  return {
    list,
    selectedId,
    searchText,
    isSearchInProgress,
    isNoSearchResults,
    onSearchInput,
    onProviderSelect,
    onSelectedItemLoaded,
    onSearchClear,
  };
}
