import { LogLevel } from '@leon-hub/errors';

import { Logger } from './services';
import type { LoggerOptions } from './types';
import type { SaveLogInput } from './types/SaveLogInput';
import { consoleLogWithTime } from './utils';

const logger = new Logger();

export default Logger;

export {
  logger,
  LogLevel,
  consoleLogWithTime,
};

export type {
  LoggerOptions,
  SaveLogInput,
};
