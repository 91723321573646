enum BadgeKind {
  DEFAULT = 'default',
  SQUARE = 'square',
  SQUARE_WHITE = 'square-white',
  SQUARE_HIGHLIGHT = 'square-highlight',
  SQUARE_LIGHT = 'square-light',
  SQUARE_ERROR = 'square-error',
  SQUARE_INVERT = 'square-invert',
  CHAT = 'chat',
  WHITE = 'white',
}

export default BadgeKind;
