<script lang="ts" setup>
import { TextInput } from 'web/src/components/Input';

import useCPFNumberEvents from './useCPFNumberEvents';
import type {
  FormWrapperWidgetProperties,
  BaseFormWidgetEmits,
} from '../../../types';
import useFormCPFNumber from './useFormCPFNumber';

const props = defineProps<FormWrapperWidgetProperties>();
const emit = defineEmits<BaseFormWidgetEmits>();

const {
  emitChange,
  emitInput,
  emitFocus,
  emitBlur,
} = useCPFNumberEvents(emit);

const { componentProps } = useFormCPFNumber(props);
</script>

<template>
  <TextInput v-auto-id="'FormCPFNumber'"
    v-bind="componentProps"
    @input="emitInput"
    @change="emitChange"
    @focus="emitFocus"
    @blur="emitBlur"
  />
</template>
