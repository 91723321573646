export const enum PinButtonEvent {
  VALUE_0 = '0',
  VALUE_00 = '00',
  VALUE_1 = '1',
  VALUE_2 = '2',
  VALUE_3 = '3',
  VALUE_4 = '4',
  VALUE_5 = '5',
  VALUE_6 = '6',
  VALUE_7 = '7',
  VALUE_8 = '8',
  VALUE_9 = '9',
  VISIBILITY = 'VISIBILITY',
  BACKSPACE = 'BACKSPACE',
}
