<script lang="ts" setup>
import { computed, toRef } from 'vue';

import { useCasinoTopStore } from 'web/src/modules/casino/submodules/top/useCasinoTopStore';
import { CasinoHomeBlockPriority } from 'web/src/modules/home/submodules/casino/enums';
import CasinoHomeJackpotsBlock from 'web/src/modules/home/submodules/casino/components/CasinoHomeJackpotsBlock/CasinoHomeJackpotsBlock.vue';
import HomePromotionsBlock from 'web/src/modules/home/submodules/promotions/views/HomePromotionsBlock.vue';

import type { CasinoHomePromoBlockProps } from './types';

const props = withDefaults(defineProps<CasinoHomePromoBlockProps>(), {
  priority: CasinoHomeBlockPriority.Jackpots,
});

const hasJackpots = toRef(useCasinoTopStore(), 'hasJackpots');

const isPromotionsBlock = computed(() => (
  (props.priority === CasinoHomeBlockPriority.Promotions && hasJackpots.value)
  || (props.priority === CasinoHomeBlockPriority.Jackpots && !hasJackpots.value)
));

const isJackpotsBlock = computed(() => (
  props.priority === CasinoHomeBlockPriority.Jackpots && hasJackpots.value
));
</script>

<template>
  <HomePromotionsBlock v-if="isPromotionsBlock" />
  <CasinoHomeJackpotsBlock v-else-if="isJackpotsBlock" />
</template>
