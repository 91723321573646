<script lang="ts" setup>
import { toRef } from 'vue';

import { IconName } from '@leon-hub/icons';

import { VIcon } from '@components/v-icon';

import { useFavoriteSportEventData } from 'web/src/modules/sportline/submodules/favorites/composables';
import { useVFavoriteToggle } from 'web/src/components/FavoriteToggle/VFavoriteToggle/composables';
import { FavoriteIconColor } from 'web/src/components/FavoriteToggle/VFavoriteToggle/enums';
import type { SportlineType } from 'web/src/modules/sportline/enums';

interface SportlineEventFavoriteToggleProps {
  sportlineEventId?: Maybe<string>;
  sportlineEventType?: Maybe<SportlineType>;
  isColored?: boolean;
}

const props = defineProps<SportlineEventFavoriteToggleProps>();

const sportlineEventId = toRef(props, 'sportlineEventId');
const sportlineEventType = toRef(props, 'sportlineEventType');

const {
  isFavorite,
  canFavorite,
  emitChangeFavoriteState,
} = useFavoriteSportEventData({
  sportlineEventId,
  sportlineEventType,
});
const {
  isToggled,
  onClick,
  onMouseLeave,
} = useVFavoriteToggle(() => { void emitChangeFavoriteState(); });
</script>

<template>
  <div v-auto-id="'SportlineEventFavoriteToggle'"
    v-if="canFavorite"
    :class="{
      [$style['favorite-toggle']]: true,
      [$style['favorite-toggle--colored']]: isColored,
      [$style['favorite-toggle--no-default-color']]: true,
      [$style['favorite-toggle--favorite']]: !!isFavorite,
      [$style['favorite-toggle--toggled']]: isToggled,
    }"
    @click.stop.prevent="onClick"
    @mouseleave="onMouseLeave"
  >
    <VIcon
      :name="IconName.STAR_OUTLINE"
      :class="$style['favorite-toggle__inactive-icon']"
    />
    <VIcon
      :name="IconName.STAR"
      :class="[
        $style[`favorite-toggle__active-icon-name-${IconName.STAR}-${FavoriteIconColor.BRAND_DEFAULT}`],
        $style['favorite-toggle__active-icon'],
      ]"
    />
  </div>
</template>

<style module lang="scss">
@import 'web/src/components/FavoriteToggle/VFavoriteToggle/styles/favorite-toggle';
</style>
