import { SportFilterKickoffPeriod } from '@leon-hub/api-sdk';

import type { SportDateTimeFilter } from 'web/src/modules/sportline/submodules/navigation/store/types';
import { SportlineType } from 'web/src/modules/sportline/enums';

// It could be an object but for the typechecking better present it as a nullish type
export function isEmptyFilter(filter?: Maybe<SportDateTimeFilter>): filter is undefined | null {
  const {
    activeKickoffPeriod,
    ...otherFields
  } = filter ?? {};
  const otherFieldsEmpty = Object.values(otherFields).every((value) => !value);

  return otherFieldsEmpty
    && (!activeKickoffPeriod || activeKickoffPeriod === SportFilterKickoffPeriod.ALL);
}

function getActiveKickoffPeriodKey(activeKickoffPeriod: SportFilterKickoffPeriod): string {
  if (
    activeKickoffPeriod === SportFilterKickoffPeriod.ALL
    || activeKickoffPeriod === SportFilterKickoffPeriod.CUSTOM
  ) { return ''; }
  return activeKickoffPeriod;
}

function getCustomDateKey(date: Optional<Maybe<number>>, label: string): string {
  return date ? `${label}_${date}` : '';
}

function getSportlineTypeKey(sportlineType?: string | number): string {
  switch (sportlineType) {
    case SportlineType.Live: return 'LIVE';
    case SportlineType.Prematch: return 'PREMATCH';
    default: return '';
  }
}

export function getFilterKey(filter?: Maybe<SportDateTimeFilter>): string {
  if (isEmptyFilter(filter)) { return ''; }

  return [
    getSportlineTypeKey(filter.sportlineType),
    getActiveKickoffPeriodKey(filter.activeKickoffPeriod),
    getCustomDateKey(filter.customDateFrom, 'FROM'),
    getCustomDateKey(filter.customDateTo, 'TO'),
  ].filter((value) => !!value).join('_');
}

export function getLeagueElementKeyForFilter(
  baseKey: string,
  filter?: Maybe<SportDateTimeFilter>,
): string {
  const filterKey = getFilterKey(filter);
  return filterKey ? `${baseKey}_${filterKey}` : baseKey;
}
