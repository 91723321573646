import type { Ref } from 'vue';
import { watch, ref } from 'vue';

import type { VCheckboxProps, VCheckboxEmits } from '../types';
import useCheckboxEvents from './useCheckboxEvents';

export interface VCheckboxComposable {
  isHover: Ref<boolean>;
  isFocus: Ref<boolean>;
  isChecked: Ref<boolean>;
  onMouseEnter(): void;
  onMouseLeave(): void;
  onChange(event: Event): void;
  onInput(event: Event): void;
  onFocus(event: FocusEvent): void;
  onBlur(event: FocusEvent): void;
}

export default function useVCheckbox(props: VCheckboxProps, emit: VCheckboxEmits): VCheckboxComposable {
  const isHover = ref<boolean>(false);
  const isChecked = ref<boolean>(!!props.checked);
  const isFocus = ref<boolean>(false);

  const onMouseEnter = (): void => {
    isHover.value = true;
  };

  const onMouseLeave = (): void => {
    isHover.value = false;
  };

  const {
    onInput,
    onChange,
    onFocus,
    onBlur,
  } = useCheckboxEvents(emit);

  const handleFocus = (event: FocusEvent) => {
    isFocus.value = true;
    onFocus(event);
  };

  const handleBlur = (event: FocusEvent) => {
    isFocus.value = false;
    onBlur(event);
  };

  watch(() => props.checked, (value) => {
    isChecked.value = !!value;
  });

  return {
    isHover,
    isFocus,
    isChecked,
    onMouseEnter,
    onMouseLeave,
    onInput,
    onChange,
    onFocus: handleFocus,
    onBlur: handleBlur,
  };
}
