import type { Ref } from 'vue';
import {
  onMounted,
  ref,
  toRef,
} from 'vue';

import useWebPushNotificationsStore from 'web/src/modules/push-notifications/store/useWebPushNotificationsStore';
import useIntercomStore from 'web/src/modules/intercom/store/useIntercomStore';
import useSupport from 'web/src/modules/support/composables/useSupport';
import { useRef } from 'web/src/utils/vue/useRef';

export interface UsePostponedAppController {
  isModalVisible: Ref<boolean>;
}

export default function usePostponedAppController(): UsePostponedAppController {
  let isModalVisible = ref(false);

  if (process.env.VUE_APP_PLATFORM_WEB) {
    const webPushNotificationsStore = useWebPushNotificationsStore();
    isModalVisible = toRef(webPushNotificationsStore, 'isModalVisible');

    onMounted(() => {
      void webPushNotificationsStore.showModal();
    });
  }

  if (process.env.VUE_APP_FEATURE_INTERCOM_ENABLED) {
    const { isIntercomAvailable } = useSupport();
    onMounted(() => {
      // when intercom becomes available.
      void useRef(isIntercomAvailable).then(() => {
        void useIntercomStore().initIntercom();
      });
    });
  }

  return {
    isModalVisible,
  };
}
