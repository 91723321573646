import type { DefinedModule, DefinitionCallback } from '@leon-hub/define-module';
import { defineModule } from '@leon-hub/define-module';
import { normalizeError } from '@leon-hub/errors';

import type { OuterModuleApi, InnerModuleApi } from '../types';
import { AppModuleError } from '../entities';

type DefineAppModuleArguments = [InnerModuleApi, ...unknown[]];

export function defineAppModule<
  ARGS extends DefineAppModuleArguments,
  RESULT extends OuterModuleApi,
>(
  name: string,
  cb: DefinitionCallback<ARGS, RESULT>,
): DefinedModule<ARGS, RESULT>;
export function defineAppModule<
  ARGS extends DefineAppModuleArguments,
  RESULT extends Promise<OuterModuleApi>,
>(
  name: string,
  cb: DefinitionCallback<ARGS, RESULT>,
): DefinedModule<ARGS, RESULT>;
export function defineAppModule<
  ARGS extends DefineAppModuleArguments,
  RESULT extends undefined,
>(
  name: string,
  cb: DefinitionCallback<ARGS, RESULT>,
): DefinedModule<ARGS, RESULT>;
export function defineAppModule<
  ARGS extends DefineAppModuleArguments,
  RESULT extends Promise<OuterModuleApi> | OuterModuleApi,
>(
  name: string,
  cb: DefinitionCallback<ARGS, RESULT>,
): DefinedModule<ARGS, RESULT> {
  return defineModule((...rest) => {
    const options: InnerModuleApi = rest[0];
    try {
      return cb(...[
        options,
        ...rest.slice(1),
      ] as ARGS);
    } catch (err: unknown) {
      throw new AppModuleError({
        message: `Unable to initialize "${name}" module`,
        cause: normalizeError(err),
      });
    }
  });
}
