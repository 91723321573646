import type {
  ObjectEntry, StringEntry, UIField, UIFormSchema,
} from '@leon-hub/api-sdk';
import type { FormSchema } from '@leon-hub/form-utils';
import { assert, isArrayOfStrings } from '@leon-hub/guards';
import { isFormSchema } from '@leon-hub/form-utils';

import type { FormUiSchema, FormUiSchemaField, FormWidget } from 'web/src/components/Form/types';

import { defaultMappingKeys } from './constants/defaultMappingKeys';
import type {
  FieldMappingKeys,
  CustomProperties,
  PropertiesMapByWidget,
  PropertiesMapById,
  UiFieldRawOptions,
} from './types';
import {
  addToPropertiesMap,
  updatePropertiesMap,
  getUiFieldRawOptions,
  getRelatedUiFieldCustomProperties,
  mapCommonFieldProperties,
  getUiSchemaField,
} from './utils';

export default class JsonSchemaAdapter {
  constructor(
    private readonly apiUiSchema: UIFormSchema,
    private readonly JSONSchema: FormSchema,
    private readonly mappingKeys: FieldMappingKeys = defaultMappingKeys,
  ) {}

  /** props to create FormUiSchemaField object related on FormWidget(FormControlType) */
  private customPropertiesByWidget: PropertiesMapByWidget = new
  Map<FormWidget, CustomProperties>();

  /** props to create FormUiSchemaField[options] object related on FormWidget(FormControlType) */
  private customOptionsByWidget: PropertiesMapByWidget = new
  Map<FormWidget, CustomProperties>();

  /** props to create FormUiSchemaField object related on field id */
  private customPropertiesById: PropertiesMapById = new
  Map<string, CustomProperties>();

  /** props to create FormUiSchemaField[options] object related on field id */
  private customOptionsById: PropertiesMapById = new
  Map<string, CustomProperties>();

  /** will set properties for FormUiSchemaField object to all widgets related to first argument */
  addPropertiesByWidget(widget: FormWidget, properties: CustomProperties): this {
    this.customPropertiesByWidget = addToPropertiesMap<FormWidget>(this.customPropertiesByWidget, widget, properties);
    return this;
  }

  /** will set properties for FormUiSchemaField object to all widgets in passed array
   * useful to pass same props to similar components what can be passed by different enum values and can't relay on ID
   * like Phone/PhoneInput/PhoneCountriesSelectorInput or Password/PasswordValidator
   * */
  addPropertiesByWidgetGroup(widgetGroup: FormWidget[], properties: CustomProperties): this {
    for (const widget of widgetGroup) {
      this.customPropertiesByWidget = addToPropertiesMap<FormWidget>(this.customPropertiesByWidget, widget, properties);
    }
    return this;
  }

  /** will update properties for FormUiSchemaField object to all widgets related to first argument */
  public updatePropertiesByWidget(widget: FormWidget, properties: CustomProperties): this {
    this.customPropertiesByWidget = updatePropertiesMap<FormWidget>(this.customPropertiesByWidget, widget, properties);
    return this;
  }

  /** will set properties for FormUiSchemaField[options] object to all widgets related to first argument */
  public addOptionsByWidget(widget: FormWidget, options: CustomProperties): this {
    this.customOptionsByWidget = addToPropertiesMap<FormWidget>(this.customOptionsByWidget, widget, options);
    return this;
  }

  /** will update properties for FormUiSchemaField[options] object to all widgets related to first argument */
  public updateOptionsByWidget(widget: FormWidget, options: CustomProperties): this {
    this.customOptionsByWidget = updatePropertiesMap<FormWidget>(this.customOptionsByWidget, widget, options);
    return this;
  }

  /** will set properties for FormUiSchemaField object to field with related ID */
  public addPropertiesById(id: string, properties: CustomProperties): this {
    this.customPropertiesById = addToPropertiesMap<string>(this.customPropertiesById, id, properties);
    return this;
  }

  /** will set properties for FormUiSchemaField[options] object to field with related ID */
  public addOptionsById(id: string, options: CustomProperties): this {
    this.customOptionsById = addToPropertiesMap<string>(this.customOptionsById, id, options);
    return this;
  }

  /** @deprecated use getUiFieldRawOptions directly instead */
  public static mapToObject(list: StringEntry[] = []): Dictionary<string | ObjectEntry[]> {
    return getUiFieldRawOptions(list);
  }

  private getRelatedCustomProperties(field: UIField): UiFieldRawOptions {
    return getRelatedUiFieldCustomProperties(field, this.customPropertiesByWidget, this.customPropertiesById);
  }

  private getRelatedCustomFieldOptions(field: UIField): UiFieldRawOptions {
    return getRelatedUiFieldCustomProperties(field, this.customOptionsByWidget, this.customOptionsById);
  }

  private getCommonFieldProperties(field: UIField): UiFieldRawOptions {
    return mapCommonFieldProperties(field, this.mappingKeys);
  }

  private mapToFormUiSchemaField(field: UIField): FormUiSchemaField {
    const commonProperties = this.getCommonFieldProperties(field);
    const customProperties = this.getRelatedCustomProperties(field);
    const customOptions = this.getRelatedCustomFieldOptions(field);
    return getUiSchemaField(commonProperties, customProperties, customOptions);
  }

  public getFormUiSchema(): FormUiSchema {
    const fields: Record<string, FormUiSchemaField> = {};
    for (const field of this.apiUiSchema.fields) {
      fields[field.id] = this.mapToFormUiSchemaField(field);
    }
    let order: string[] | null = null;
    if (this.apiUiSchema.order) {
      assert(isArrayOfStrings(this.apiUiSchema.order), 'uiFormSchema.order should be array of strings');
      order = this.apiUiSchema.order;
    }
    return {
      fields,
      ...(order ? { order } : {}),
    };
  }

  public getFormSchema(): FormSchema {
    assert(isFormSchema(this.JSONSchema), 'uiSchema should be of type FormUiSchema');
    return this.JSONSchema;
  }
}
