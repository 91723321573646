import { serializeHeaders } from '@leon-hub/utils';
import type { FetchResponse } from '@leon-hub/fetch-client';

import getObjectDescription from './getObjectDescription';

export default function getResponseDescription(response: FetchResponse): string {
  return getObjectDescription({
    url: response.url,
    status: response.status,
    statusText: response.statusText,
    headers: serializeHeaders([...response.headers.entries()]),
  }, { prefix: 'response ' });
}
