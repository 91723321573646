import type { AppVueRouter } from 'web/src/modules/core/services/router/types';
import { useNavigationBackButtonStore } from 'web/src/modules/core/store/useNavigationBackButtonStore';

export function createHeaderBackButtonNavigationGuard(router: AppVueRouter): void {
  const { setNavigationBackLocationGetter } = useNavigationBackButtonStore();

  router.beforeEach(() => {
    setNavigationBackLocationGetter(null);
  });
}
