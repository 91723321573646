import {
  QueryParameterGroupName,
} from '@leon-hub/query-manager';

import { useQueryManager } from 'web/src/modules/core/composables';
import type { AppVueRouter } from 'web/src/modules/core/services/router/types';
import { useRootStore } from 'web/src/modules/core/store';

export default function createQueryNavigationGuard(router: AppVueRouter): void {
  const queryManager = useQueryManager();
  const rootStore = useRootStore();

  router.beforeEach((to, from, next) => {
    if (from.name && rootStore.isDirectLink) {
      rootStore.setDirectLinkState(false);
    }

    // Apply sticky query parameters within app routing.
    const query = queryManager.getParameters({
      booleanAsString: true,
      group: [
        QueryParameterGroupName.APP,
        QueryParameterGroupName.TESTING,
        QueryParameterGroupName.BONUS,
      ],
    });

    if (Object.keys(query).length) {
      const queryUpdate = { ...to.query, ...query };

      if (JSON.stringify(to.query) !== JSON.stringify(queryUpdate)) {
        router.next(next, {
          name: to.name || undefined,
          params: to.params,
          query: queryUpdate,
        });
        return;
      }
    }

    next();
  });
}
