import type { Ref } from 'vue';
import {
  computed,
  isRef,
  ref,
  toRef,
} from 'vue';

import type { ITheme } from '@leon-hub/api-sdk';
import { getQueryUrlPart } from '@leon-hub/utils';

import type { IframeWidget } from 'web/src/modules/framed-app/enums';
import { useSiteConfigStore } from 'web/src/modules/core/store';

import { getDefaultWidgetUrl } from '../utils/getDefaultWidgetUrl';

export function useFramedWidgetUrl(
  widget: IframeWidget | Ref<IframeWidget>,
  theme?: Ref<ITheme>,
  queryParameters?: Ref<Record<string, string> | undefined>,
): Ref<string | undefined> {
  const siteConfigStore = useSiteConfigStore();
  const iframeWidgetUrl = toRef(() => siteConfigStore.widgetIframeUrl);
  const widgetName = isRef(widget) ? widget : ref(widget);
  const themeRef = isRef(theme) ? theme : ref(undefined);

  if (process.env.VUE_APP_BUNDLER_VITE) {
    const themePart = computed(() => (themeRef.value ? `/${themeRef.value.toLowerCase()}` : ''));
    const widgetAppPathPart = process.env.NODE_ENV === 'development' ? '/index.framed.html' : (iframeWidgetUrl.value ?? '/iframe-widgets');

    return computed(() => `${widgetAppPathPart}${themePart.value}/${widgetName.value}${getQueryUrlPart(queryParameters?.value)}`);
  }

  return computed(() => (iframeWidgetUrl.value ? getDefaultWidgetUrl(
    widgetName.value,
    iframeWidgetUrl.value,
    themeRef.value,
    queryParameters?.value,
  ) : undefined));
}
