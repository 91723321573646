import type {
  NavigationGuardNext,
  RouteLocationNormalized,
} from 'vue-router';
import { toRef } from 'vue';

import { RouteName, CasinoRouteName } from '@leon-hub/routing-config-names';
import { EgsGameType } from '@leon-hub/api-sdk';

import type { AppVueRouter } from 'web/src/modules/core/services/router/types';
import { AbstractPrefetch } from 'web/src/modules/core/services/router/prefetch';

import { useCasinoGameStore } from '../store';

export default class CasinoSlotsGamesPrefetchOld extends AbstractPrefetch {
  // eslint-disable-next-line class-methods-use-this
  async prefetch(
    router: AppVueRouter,
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ): Promise<void> {
    const { id } = to.params;
    const gamesStore = useCasinoGameStore();
    const activeGame = toRef(gamesStore, 'activeGame');

    try {
      await gamesStore.loadGameById(String(id));
    } catch {}

    if (!activeGame.value) {
      next({
        name: RouteName.ERROR_NOT_FOUND_404,
      });
      return;
    }

    const { url, group, typeId } = activeGame.value;

    const toRouteName = typeId === EgsGameType.LIVE
      ? CasinoRouteName.CASINO_LIVE_GAME
      : CasinoRouteName.CASINO_GAME;

    next(router.resolve301location({
      name: toRouteName,
      params: {
        groupUrl: group.url,
        gameUrl: url,
      },
    }));
  }
}
