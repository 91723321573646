import type { Ref } from 'vue';
import { computed, toRef } from 'vue';

import { useModuleTimeout } from 'web/src/modules/core/store/composables';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import {
  useLeaguePlayoffStoreComposable,
  useLeagueStandingsStoreComposable,
  useLeagueTopPlayersStoreComposable,
  useLeagueMatchesStatisticStoreComposable,
} from 'web/src/modules/sportline/submodules/league-statistic/store/composables';
import { useSyncSportlineWithoutCTag } from 'web/src/modules/sportline/submodules/sync-sportline';
import type {
  BetlineLeagueStanding,
  CoreSportlineFetchOptions,
  BetlineLeagueMatchesStatistic,
  BetlineLeagueMatchesResults,
  BetlineLeaguePlayoff,
  BetlineLeagueTopPlayers,
} from 'web/src/modules/sportline/types/rest';
import { BackgroundUpdateStopwatch } from 'web/src/utils/store';
import useBackgroundRequestsLifeCycle from 'web/src/utils/store/composables/useBackgroundRequestsLifeCycle';

interface UseLeagueStatisticStoreComposableProps {
  expectedId: Ref<Maybe<number>>;
}

interface UseLeagueStatisticStoreComposable {
  isReady: Ref<boolean>;
  isLoading: Ref<boolean>;
  isEmpty: Ref<boolean>;
  leagueStandings: Ref<Maybe<BetlineLeagueStanding[]>>;
  leagueMatchesStatistic: Ref<Maybe<BetlineLeagueMatchesStatistic>>;
  leagueMatchesResults: Ref<Maybe<BetlineLeagueMatchesResults>>;
  leaguePlayoff: Ref<Maybe<BetlineLeaguePlayoff>>;
  leagueTopPlayers: Ref<Maybe<BetlineLeagueTopPlayers>>;
  onInit(): void;
  initialRequests(): Promise<void>;
  syncBackgroundRequests(options: { force?: boolean; silent?: boolean }): Promise<void>;
  setBackgroundUpdateEnabled(value: boolean): Promise<void>;
  clear(): void;
}

export function useLeagueStatisticStoreComposable(
  props: UseLeagueStatisticStoreComposableProps,
): UseLeagueStatisticStoreComposable {
  const { expectedId } = props;

  const sportlineSettingsStore = useSportlineSettingsStore();

  const isEnabled = toRef(sportlineSettingsStore, 'isLeagueStatisticEnabled');
  const {
    timeout: updateInterval,
  } = useModuleTimeout('sportline-league-statistic', toRef(sportlineSettingsStore, 'sportLinePrematchUpdateInterval'));

  const {
    isReady: isStandingsReady,
    isLoading: isStandingsLoading,
    isEmpty: isStandingsEmpty,
    leagueStandings,
    fetchLeagueStandings,
    clear: clearLeagueStandings,
  } = useLeagueStandingsStoreComposable({ expectedId, isEnabled });
  const {
    isReady: isMatchesStatisticReady,
    isLoading: isMatchesStatisticLoading,
    isEmpty: isMatchesStatisticEmpty,
    leagueMatchesStatistic,
    leagueMatchesResults,
    fetchLeagueMatchesStatistic,
    clear: clearLeagueMatchesStatistic,
  } = useLeagueMatchesStatisticStoreComposable({ expectedId, isEnabled });
  const {
    isReady: isPlayoffReady,
    isLoading: isPlayoffLoading,
    isEmpty: isPlayoffEmpty,
    leaguePlayoff,
    fetchLeagueLayoff,
    clear: clearLeagueLayoff,
  } = useLeaguePlayoffStoreComposable({ expectedId, isEnabled });
  const {
    isReady: isTopPlayersReady,
    isLoading: isTopPlayersLoading,
    isEmpty: isTopPlayersEmpty,
    topPlayers: leagueTopPlayers,
    fetchLeagueTopPlayers,
    clear: clearLeagueTopPlayers,
  } = useLeagueTopPlayersStoreComposable({ expectedId, isEnabled });

  const isReady = computed<boolean>(
    () => isStandingsReady.value
    && isMatchesStatisticReady.value
    && isPlayoffReady.value
    && isTopPlayersReady.value,
  );
  const isLoading = computed<boolean>(() => (
    isStandingsLoading.value
    || isMatchesStatisticLoading.value
    || isPlayoffLoading.value
    || isTopPlayersLoading.value
  ));
  const isEmpty = computed(() => (
    isStandingsEmpty.value
    && isMatchesStatisticEmpty.value
    && isPlayoffEmpty.value
    && isTopPlayersEmpty.value
  ));

  const lastUpdate = new BackgroundUpdateStopwatch<
    ['leagueMatchesStatistic' | 'leagueStandings' | 'leaguePlayoff' | 'topPlayers']
  >({
    leagueStandings: async ({ silent }: CoreSportlineFetchOptions) => {
      await fetchLeagueStandings({ silent });
    },
    leagueMatchesStatistic: async ({ silent }: CoreSportlineFetchOptions) => {
      await fetchLeagueMatchesStatistic({ silent });
    },
    leaguePlayoff: async ({ silent }: CoreSportlineFetchOptions) => {
      await fetchLeagueLayoff({ silent });
    },
    topPlayers: async ({ silent }: CoreSportlineFetchOptions) => {
      await fetchLeagueTopPlayers({ silent });
    },
  });

  const {
    initialRequests,
    syncState,
    syncBackgroundRequests,
    setBackgroundUpdateEnabled,
  } = useBackgroundRequestsLifeCycle({
    lastUpdate,
    updateInterval,
  });

  function onInit(): void {
    useSyncSportlineWithoutCTag(async (silent: boolean): Promise<void> => {
      await syncState({ silent });
    }, updateInterval);
  }

  function clear(): void {
    clearLeagueStandings();
    clearLeagueMatchesStatistic();
    clearLeagueLayoff();
    clearLeagueTopPlayers();
  }

  return {
    isReady,
    isLoading,
    isEmpty,
    leagueStandings,
    leagueMatchesStatistic,
    leagueMatchesResults,
    leaguePlayoff,
    leagueTopPlayers,
    onInit,
    initialRequests,
    syncBackgroundRequests,
    setBackgroundUpdateEnabled,
    clear,
  };
}
