<script lang="ts" setup>
import { toRefs } from 'vue';

import { IconName } from '@leon-hub/icons';

import type { VIconProps } from '@components/v-icon';

import { FavoriteIconColor } from 'web/src/components/FavoriteToggle/VFavoriteToggle/enums';
import VFavoriteToggle from 'web/src/components/FavoriteToggle/VFavoriteToggle/VFavoriteToggle.vue';
import { useLeagueFavoriteIndicator } from 'web/src/modules/sportline/submodules/favorites/composables';

interface FavoriteLeagueIndicatorProps {
  sportId: string;
  leagueId: string;
}

const props = defineProps<FavoriteLeagueIndicatorProps>();

const inactiveFavoriteIcon: VIconProps = { name: IconName.STAR_OUTLINE };
const activeFavoriteIcon: VIconProps = { name: IconName.STAR };

const {
  isFavorite,
  isOnlyPending,
  toggleLeagueFavoriteState,
} = useLeagueFavoriteIndicator(toRefs(props));
</script>

<template>
  <VFavoriteToggle v-auto-id="'FavoriteLeagueIndicator'"
    :key="`${sportId}_${leagueId}_${String(isFavorite)}`"
    :active-icon="activeFavoriteIcon"
    :active-icon-color="FavoriteIconColor.TEXT_DEFAULT"
    :inactive-icon="inactiveFavoriteIcon"
    :is-favorite="isFavorite"
    :is-only-pending="isOnlyPending"
    :class="$style['favorite-league-indicator']"
    @click.prevent="toggleLeagueFavoriteState"
  />
</template>

<style lang="scss" module>
.favorite-league-indicator {
  height: 100%;
}
</style>
