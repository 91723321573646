<script lang="ts" setup>
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';

import VCmsContent from 'web/src/modules/cms/components/VCmsContent/VCmsContent.vue';
import { useAnalytics } from 'web/src/modules/analytics/composables';

const analytics = useAnalytics();

const onLoyaltyBlockClick = (sectionName: string) => {
  analytics.push(AnalyticsEvent.Z_LOYALTY_PROGRAM_INFO, {
    loyaltyProgram: {
      programInfo: {
        clickSubject: sectionName,
      },
    },
  });
};
</script>

<template>
  <div v-auto-id="'CasinoLoyaltyProgramInfoRoutePage'"
    :class="$style['loyalty-program-info']"
  >
    <VCmsContent
      cms-key="WEB2_LOYALTY_PROGRAM_INFO_V2"
      @loyalty-block-click="onLoyaltyBlockClick"
    />
  </div>
</template>

<style lang="scss" module>
@include for-layout using($isDesktop) {
  .loyalty-program-info {
    @if $isDesktop {
      padding: 16px 24px 0;
    }
  }
}
</style>
