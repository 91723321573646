import type { ComputedRef, Ref } from 'vue';
import {
  computed,
  ref,
  watch,
} from 'vue';
import { useRoute } from 'vue-router';

import { Timer } from '@leon-hub/utils';
import { bannerVersionV3 } from '@leon-hub/api-sdk';

import type { FadeCarouselRef } from 'web/src/components/FadeCarousel/VFadeCarousel/types';
import { getReadySlides } from 'web/src/modules/banners/components/FadeCarouselBanner/utils/getReadySlides';
import type { UseAbstractBannerComposable } from 'web/src/modules/banners/composables/useAbstractBanner';
import { useAbstractBanner } from 'web/src/modules/banners/composables/useAbstractBanner';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { PaginationLocation } from 'web/src/components/FadeCarousel/enums';

import type { FadeCarouselBannerEmits, FadeCarouselBannerProps } from '../types';

export interface FadeCarouselBannerComposable extends Partial<UseAbstractBannerComposable> {
  fadeCarousel: Ref<FadeCarouselRef | undefined>;
  readySlides: Ref<number[]>;
  nextVisibleIndex: Ref<number>;
  activeSlideIndex: Ref<number>;
  onSlideChanged: (index: number) => void;
  emitSlideChanged: () => void;
  beforeMount: () => void;
  paginationLocation: Ref<PaginationLocation>;
}

export default function useFadeCarouselBanner(props: FadeCarouselBannerProps, emit: FadeCarouselBannerEmits): FadeCarouselBannerComposable {
  const route = useRoute();

  const {
    banners,
    linkTitle,
    autoplayTimeout,
    isGroupBannersDev,
    emitBannerItemClick,
  } = useAbstractBanner(props, emit);
  const { isLoggedIn } = useIsLoggedIn();

  const fadeCarousel = ref<FadeCarouselRef>();
  const activeSlideIndex = ref(0);
  const nextVisibleIndex = ref(0);
  let visibilityTimeout = 0;
  const readySlides = ref<number[]>([]);
  const bannersLength = computed(() => banners.value.length);
  const paginationLocation: ComputedRef<PaginationLocation> = computed(() => (banners.value.find((banner) => banner.bannerVersion === bannerVersionV3)
    ? PaginationLocation.RIGHT
    : PaginationLocation.CENTER));

  function goToFirstBanner(): void {
    fadeCarousel.value?.moveToSlide(0);
  }

  function showNextImage(): void {
    if (visibilityTimeout) {
      Timer.clearTimeout(visibilityTimeout);
      visibilityTimeout = 0;
    }

    if (autoplayTimeout) {
      const timeout = autoplayTimeout.value > 3000 ? autoplayTimeout.value - 3000 : 1;

      visibilityTimeout = Timer.setTimeout(() => {
        if (banners.value?.[activeSlideIndex.value + 1]) {
          nextVisibleIndex.value = activeSlideIndex.value + 1;
        }
      }, timeout);
    }
    readySlides.value = getReadySlides(banners.value.length, activeSlideIndex.value);
  }

  function beforeMount(): void {
    showNextImage();
  }

  function emitSlideChanged() {
    emit('slide-changed', activeSlideIndex.value);
  }

  function onSlideChanged(index: number): void {
    activeSlideIndex.value = index;
    showNextImage();
    emitSlideChanged();
  }

  watch(isLoggedIn, goToFirstBanner);
  watch(linkTitle, goToFirstBanner);
  watch(bannersLength, goToFirstBanner);
  watch(() => route.path, goToFirstBanner);

  return {
    fadeCarousel,
    autoplayTimeout,
    banners,
    readySlides,
    nextVisibleIndex,
    isGroupBannersDev,
    activeSlideIndex,
    onSlideChanged,
    emitBannerItemClick,
    emitSlideChanged,
    beforeMount,
    paginationLocation,
  };
}
