<script lang="ts" setup>
import { toRef, computed } from 'vue';

import { useInfoSlideType } from 'web/src/modules/sportline/submodules/event-details/composables/headline';
import type { SportEventDetails } from 'web/src/modules/sportline/submodules/event-details/types';
import { HeadlineSlideType } from 'web/src/modules/sportline/submodules/event-details/enums';
import { isLiveSportlineEvent } from 'web/src/modules/sportline/utils/sportlineEvent/sportlineTypeCheck';

import OutrightInfo from './default/wide/OutrightInfo.vue';
import LiveMatchInfo from './default/wide/LiveMatchInfo.vue';
import PreMatchInfo from './default/wide/PreMatchInfo.vue';

interface WideInfoProps {
  sportlineEvent: SportEventDetails;
  backgroundColor?: Maybe<string>;
}

interface WideInfoSlots {
  'primary-market'(props: { hasTimeline?: boolean; hasCountdown?: boolean }): void;
}

const props = defineProps<WideInfoProps>();
defineSlots<WideInfoSlots>();

const sportlineEvent = toRef(props, 'sportlineEvent');
const backgroundColor = toRef(props, 'backgroundColor', null);

const sport = computed(() => sportlineEvent.value.sport);

const { infoSlideType } = useInfoSlideType({ sportlineEvent });
</script>

<template>
  <OutrightInfo
    v-if="infoSlideType === HeadlineSlideType.OutrightInfo"
    :sportline-event="sportlineEvent"
    :background-color="backgroundColor"
  >
    <template
      v-if="$slots['primary-market']"
      #primary-market
    >
      <slot name="primary-market" />
    </template>
  </OutrightInfo>
  <LiveMatchInfo
    v-else-if="infoSlideType === HeadlineSlideType.LiveInfo && isLiveSportlineEvent(sportlineEvent)"
    :sportline-event="sportlineEvent"
    :sport="sport"
    :background-color="backgroundColor"
  >
    <template
      v-if="$slots['primary-market']"
      #primary-market="{ hasTimeline }"
    >
      <slot
        name="primary-market"
        :has-timeline="hasTimeline"
      />
    </template>
  </LiveMatchInfo>
  <PreMatchInfo
    v-else-if="infoSlideType === HeadlineSlideType.PreMatchInfo"
    :sportline-event="sportlineEvent"
    :background-color="backgroundColor"
  >
    <template
      v-if="$slots['primary-market']"
      #primary-market
    >
      <slot
        name="primary-market"
        :has-countdown="true"
      />
    </template>
  </PreMatchInfo>
</template>
