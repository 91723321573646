<script setup lang="ts">
import { useSportlineTopEventsSettings } from 'web/src/modules/sportline/composables/settings';
import { useMixedHeadlineEventsList } from 'web/src/modules/sportline/submodules/top/composables';
import SportlineHomeSportElement from 'web/src/modules/home/submodules/sportline/views/SportlineHomeSportElement.vue';

import { useHomeSportlineElementSeoMeta } from './composables';

const {
  isTopEventsLogosEnabled,
  isMarketTypesSelectionEnabled,
} = useSportlineTopEventsSettings();

const {
  totalCount,
  headlineElements,
} = useMixedHeadlineEventsList();

useHomeSportlineElementSeoMeta({ sportElements: headlineElements });

const expandFirstCount = 5;
// @see LEONWEB-2140
const isExpandAllByDefault = !!process.env.VUE_APP_PRERENDER;
</script>

<template>
  <div v-auto-id="'MixedSportlineEventsBlock'"
    v-if="totalCount > 0"
    :class="$style['sport-events-block']"
  >
    <SportlineHomeSportElement
      v-for="(headlineElement, index) in headlineElements"
      :key="headlineElement.key"
      :sport-element="headlineElement"
      :is-market-types-selection-enabled="isMarketTypesSelectionEnabled"
      :expand-key="`home-${headlineElement.sport.representation.family}`"
      :is-default-expanded="isExpandAllByDefault || index < expandFirstCount"
      :can-show-logos="isTopEventsLogosEnabled"
      is-shown-live-indicator
      is-shown-leagues-headline
    />
  </div>
</template>

<style module lang="scss">
@include for-layout using ($isDesktop) {
  .sport-events-block {
    padding: if($isDesktop, 0, 0 8px);
    margin-bottom: 8px;
  }
}
</style>
