import {
  isObject,
  isOptionalString,
  isString,
} from '@leon-hub/guards';

import type { LogData } from '../types';

export function isLogData(log: unknown): log is LogData {
  return isObject(log)
    && isString(log.message)
    && isString(log.stacktrace)
    && isOptionalString(log.payload);
}
