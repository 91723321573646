import type { AbstractErrorOptions } from '@leon-hub/errors';
import { AbstractError } from '@leon-hub/errors';
import type { Without } from '@leon-hub/types';
import type { FetchResponse } from '@leon-hub/fetch-client';

import { ApiErrorCode } from './ApiErrorCode';

export interface ApiErrorOptions extends Without<AbstractErrorOptions, 'code'> {
  code?: ApiErrorCode;
  responseCode?: number;
  request?: Request;
  response?: FetchResponse;
  operationName?: string;
}

export class ApiError extends AbstractError {
  protected readonly responseCode: ApiErrorOptions['responseCode'];

  protected readonly request: ApiErrorOptions['request'];

  protected readonly response: ApiErrorOptions['response'];

  protected operationName: ApiErrorOptions['operationName'];

  constructor(options?: ApiErrorOptions) {
    super({
      ...options,
      code: options?.code ?? ApiErrorCode.API_UNEXPECTED_ERROR,
    });

    this.responseCode = options?.responseCode ?? 0;
    this.request = options?.request;
    this.response = options?.response;
    this.operationName = options?.operationName;
  }

  public setOperationName(operationName: string | undefined): void {
    this.operationName = operationName;
  }

  public getOperationName(): string | undefined {
    return this.operationName;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  protected getSerializable() {
    return {
      ...super.getSerializable(),
      responseCode: this.responseCode,
      request: this.request,
      response: this.response,
      operationName: this.operationName,
    };
  }
}
