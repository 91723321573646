import { watch, toRef } from 'vue';

import type { MetricsToolConfigParsed } from '@leon-hub/yandex-metrika';
import {
  Events,
  getYMInstance,
} from '@leon-hub/yandex-metrika';
import {
  Json,
  Timer,
} from '@leon-hub/utils';
import { assert } from '@leon-hub/guards';
import { logger } from '@leon-hub/logging';

import { useTheme } from 'web/src/modules/theme/composables';
import { useMetricsStore } from 'web/src/modules/analytics/store';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
import { useErrorsConverter } from 'web/src/modules/errors/composables';
import { useAnalytics } from 'web/src/modules/analytics/composables';

async function registerYandexMetrika(config: MetricsToolConfigParsed | undefined): Promise<void> {
  const YM = getYMInstance();
  if (config) {
    try {
      await YM.register(config, {
        collectQueues: true,
      });
    } catch (rawError) {
      const errorMessage = `YandexMetrika registration error: config=${Json.stringify(config)}`;
      logger.warn(errorMessage, useErrorsConverter().convertToBaseError(rawError));
    }
  }
}

export default function initYM({
  initTimeout,
}: {
  initTimeout: number;
}): void {
  const metricsStore = useMetricsStore();
  const hasYandexMetrikaConfig = toRef(metricsStore, 'hasYandexMetrikaConfig');
  const yandexMetrikaConfig = toRef(metricsStore, 'yandexMetrikaConfig');

  // TODO: create different tools for authorized and guest users
  const customerDataStore = useCustomerDataStore();
  const analytics = useAnalytics();
  const login = toRef(customerDataStore, 'login');
  const countryName = toRef(customerDataStore, 'countryName');
  const vipStatus = toRef(customerDataStore, 'vipStatus');

  const YM = getYMInstance();

  const { theme } = useTheme();

  let timeoutTimer: number;

  watch(hasYandexMetrikaConfig, (newValue) => {
    YM.setDefaultOptions({
      collectQueues: newValue,
    });

    if (newValue) {
      timeoutTimer = Timer.setTimeout(() => {
        void registerYandexMetrika(yandexMetrikaConfig.value);

        const platform = process.env.VUE_APP_PLATFORM;
        const layout = process.env.VUE_APP_LAYOUT;
        const version = process.env.VUE_APP_VERSION;
        assert(platform, 'VUE_APP_PLATFORM env must be present');
        assert(layout, 'VUE_APP_LAYOUT env must be present');

        if (login.value) {
          const currentTheme = theme.value;
          analytics.push(Events.Z_INIT, {
            userId: login.value,
            country: countryName.value,
            customerType: vipStatus.value || 'normal',
            type: {
              [platform]: `${layout}`,
            },
            webVersion: version,
            ...(currentTheme ? { mode: currentTheme } : {}),
          });
        } else {
          analytics.push(Events.Z_INIT, {
            type: {
              [platform]: `${layout}`,
            },
            webVersion: version,
          });
        }
      }, initTimeout);
    } else {
      if (timeoutTimer) {
        clearTimeout(timeoutTimer);
      }

      YM.setDefaultOptions({
        collectQueues: false,
      });
      YM.unregister();
    }
  }, {
    immediate: true,
  });
}
