import type { IconNameType } from '@leon-hub/icons';
import { IconName } from '@leon-hub/icons';

import type { SwiperNavigationButtonProps } from 'web/src/components/Swiper/Navigation/SwiperNavigationButton/types';

const rightArrow = process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED ? IconName.SLOTT_ANGLE_SMALL_RIGHT : IconName.EXPAND_RIGHT;
const leftArrow = process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED ? IconName.SLOTT_ANGLE_SMALL_LEFT : IconName.EXPAND_LEFT;
export default function getIconName(props: SwiperNavigationButtonProps): IconNameType {
  return props.isNext ? rightArrow : leftArrow;
}
