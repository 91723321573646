<script lang="ts" setup>
import type { Ref } from 'vue';
import {
  inject,
  provide,
  ref,
  toRef,
  onMounted,
} from 'vue';

import { FormProvidable } from '@leon-hub/form-utils';
import type { FormProvidableContext } from '@leon-hub/form-utils';

import type { VScrollbarDesktopProvidableContext } from '@components/v-scrollbar';
import { ScrollbarProvidableContext } from '@components/v-scrollbar';

import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { useIsDevIP } from 'web/src/modules/core/composables/root';
import { useOnAppContentLoadedCallback } from 'web/src/modules/core/composables/content';

import widgetList from '../../utils/widgetList';
import type {
  FormUiSchema,
} from '../../types';
import { isFormValidationDisabled } from './utils';
import {
  useErrorPatterns,
  useForm,
  useFormAutofocus,
  useFormEmits,
} from './composables';
import type { VFormRef, VFormEmits, VFormProps } from './types';

const props = withDefaults(defineProps<VFormProps>(), {
  customErrors: () => ({}),
});
const emit = defineEmits<VFormEmits>();

const customFormContext: Ref<FormProvidableContext> = ref({
  isFromModal: props.isFromModal ?? false,
});

provide(FormProvidable.FormContext, customFormContext);

const customScrollbarContext: Ref<VScrollbarDesktopProvidableContext> = inject(ScrollbarProvidableContext.ScrollbarContext, ref({
  isReady: true,
  scrollTop: 0,
}));

const { isDevIP } = useIsDevIP();

const validationDisabled = isDevIP.value ? isFormValidationDisabled() : false;

const { errorPatterns } = useErrorPatterns();

const uiSchemaRef: Ref<FormUiSchema> = toRef(props, 'uiSchema');

const {
  focusedElementId,
  focusStart,
  focus,
  focusByIndex,
  focusByName,
} = useFormAutofocus(uiSchemaRef);

const {
  handleBlur,
  handleInput,
  handleFocus,
  handleSubmit,
  reset,
  restoreFromSnapShot,
  getSnapshot,
  getCurrentOutput,
  showValidationErrors,
  setLinesRef,
  fields,
  captchaProps,
  captchaWidgetList,
  buttonProps,
} = useForm({
  schema: toRef(props, 'schema'),
  uiSchema: uiSchemaRef,
  customErrors: toRef(props, 'customErrors'),
  isPending: toRef(props, 'isPending'),
  focusOnError: toRef(props, 'focusOnError'),
  validationDisabled,
  errorPatterns,
  focusByName,
});

const { performOnLoad } = useOnAppContentLoadedCallback();

onMounted(() => {
  /** on app start, internal autofocus on field can be triggered before
   * content is visible over skeleton, so this call to focus again after content is loaded */
  performOnLoad(focusStart, { onlyIfNotLoaded: true });
});

const {
  onChange,
  onInput,
  onFocus,
  onBlur,
  onSubmit,
  onFilled,
  onIconPrefixClick,
  onIconSuffixClick,
  onDisabledButtonClick,
} = useFormEmits(emit, {
  handleInput,
  handleFocus,
  handleBlur,
  handleSubmit,
  getCurrentOutput,
});

defineExpose<VFormRef>({
  submit: onSubmit,
  reset,
  focus,
  getSnapshot,
  restoreFromSnapShot,
  focusByName,
  focusByIndex,
  getCurrentOutput,
  showValidationErrors,
});
</script>

<template>
  <form v-auto-id="'VForm'"
    :class="$style['form']"
    @submit.stop.prevent="onSubmit"
  >
    <div :class="$style['form__fields']">
      <template
        v-for="({widget, id, widgetProps, fieldProps}) in fields"
        :key="id"
      >
        <template v-if="fieldProps.hidden" />
        <div
          v-else
          :ref="(el) => setLinesRef(el, id)"
          :class="{
            [$style['form__control']]: true,
            [$style['form__control-short']]: fieldProps.shortWidth
          }"
        >
          <component
            v-bind="widgetProps"
            :is="widgetList[widget]"
            v-if="widgetList[widget]"
            :autofocus="customScrollbarContext.isReady && focusedElementId === id"
            @input="onInput"
            @focus="onFocus"
            @change="onChange"
            @blur="onBlur"
            @filled="onFilled"
            @icon-prefix-click="onIconPrefixClick"
            @icon-suffix-click="onIconSuffixClick"
          />
          <slot
            v-else
            :name="widget"
            :props="{ ...widgetProps, autofocus: customScrollbarContext.isReady && focusedElementId === id }"
            :events="{
              input: onInput,
              focus: onFocus,
              change: onChange,
              blur: onBlur,
              filled: onFilled,
              'icon-prefix-click': onIconPrefixClick,
              'icon-suffix-click': onIconSuffixClick,
            }"
          />
        </div>
      </template>
    </div>
    <div
      v-for="({type}, index) in captchaWidgetList"
      :key="type"
      :data-test-id="`captcha-container-${index}`"
    >
      <component
        :is="widgetList[type]"
        v-if="widgetList[type]"
        v-bind="captchaProps[index] || {}"
        @input="onInput"
      />
    </div>
    <div
      v-if="isPending"
      :class="$style['form__mask']"
    />
    <div
      v-if="buttonProps"
      :class="$style['form__footer']"
    >
      <VButton
        v-data-test="{el: buttonProps?.id}"
        v-bind="buttonProps"
        @click="onSubmit"
      />
      <div
        v-if="buttonProps.disabled"
        :class="$style['form__button-mask']"
        @click="onDisabledButtonClick"
      />
    </div>
    <input
      v-else
      type="submit"
      hidden
    >
  </form>
</template>

<style lang="scss" module>
.form {
  &__footer {
    position: relative;
    margin: $formFooterMargin;
  }

  &__mask {
    @include z-index(modal);

    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--Layer0);
    opacity: 0.7;
  }

  &__button-mask {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }

  &__fields {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__control {
    flex: 0 0 100%;
    max-width: 100%;

    &:only-child {
      margin-bottom: 6px;
    }

    &-short {
      flex: 0 0 calc(50% - 8px);
    }
  }
}
</style>
