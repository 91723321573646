import type { Ref } from 'vue';
import { computed, ref } from 'vue';

import { useI18n } from 'web/src/modules/i18n/composables';
import { useSportlineCatalogClick } from 'web/src/modules/sportline/composables/catalog';
import { useSportBackgroundColor } from 'web/src/modules/sportline/composables/sport';
import useSportlineCacheStorage from 'web/src/modules/sportline/store/useSportlineCacheStorage';
import type { Sport } from 'web/src/modules/sportline/types';
import type { SportlineCatalogClickComposable } from 'web/src/modules/sportline/composables/catalog';

import { defaultHeadlineBackgrounColor } from './constants';

interface UseSportHeadlineProps {
  sportId: Ref<string>;
  customTitle?: Ref<Maybe<string>>;
  isComing: Ref<boolean>;
}

interface UseSportHeadlineComposable extends SportlineCatalogClickComposable {
  backgroundColor: Ref<string>;
  title: Ref<Maybe<string>>;
}

export function useSportHeadline(
  props: UseSportHeadlineProps,
): UseSportHeadlineComposable {
  const { sportId, isComing } = props;
  const customTitle = props.customTitle ?? ref<Maybe<string>>(null);

  const { $translate } = useI18n();
  const cacheStore = useSportlineCacheStorage();
  const sportlineCatalogClick = useSportlineCatalogClick({ sportId });

  const sportCache = computed(() => cacheStore.getSportCache(sportId.value).value);
  const sport = computed<Maybe<Sport>>(() => sportCache.value?.sport ?? null);

  const { backgroundColor: defaultBackgroundColor } = useSportBackgroundColor({ sport });
  const backgroundColor = computed<string>(() => defaultBackgroundColor.value ?? defaultHeadlineBackgrounColor);

  const title = computed<Maybe<string>>(() => {
    if (customTitle.value) { return customTitle.value; }
    if (isComing.value) { return $translate('WEB2_COMING_EVENTS').value; }
    if (sport.value) { return sport.value.name; }
    return null;
  });

  return {
    ...sportlineCatalogClick,
    backgroundColor,
    title,
  };
}
