import type { Ref } from 'vue';
import { computed } from 'vue';

import { useSportlineEventCompetitors } from 'web/src/modules/sportline/composables/sportline-event';
import type { SportlineEvent } from 'web/src/modules/sportline/types';

interface UseSportlineEventInfoProps {
  sportlineEvent: Ref<SportlineEvent>;
}

interface UseSportlineEventInfoComposable {
  sportlineEventId: Ref<string>;
  hostName: Ref<string>;
  guestName: Ref<string>;
}

export function useSportlineEventInfo(
  props: UseSportlineEventInfoProps,
): UseSportlineEventInfoComposable {
  const { sportlineEvent } = props;

  const { host, guest } = useSportlineEventCompetitors({ sportlineEvent });

  const sportlineEventId = computed<string>(() => sportlineEvent.value.id);
  const hostName = computed<string>(() => (host.value?.name ?? ''));
  const guestName = computed<string>(() => (guest.value?.name ?? ''));

  return {
    sportlineEventId,
    hostName,
    guestName,
  };
}
