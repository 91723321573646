import { toRef } from 'vue';

import type GqlApiBatchedSubRequestError from '@leon-hub/api/src/client/graphql/errors/GqlApiBatchedSubRequestError';

import { DialogPriority, PresetName } from 'web/src/modules/dialogs/enums';
import { useDialogs } from 'web/src/modules/dialogs/composables';
import { useI18n } from 'web/src/modules/i18n/composables';
import type ShowDialogFunc from 'web/src/modules/dialogs/types/ShowDialogFunc';
import { tfaCodesPresetOptions } from 'web/src/modules/two-factor-authentication/utils';
import { useTwoFactorAuthenticationStore } from 'web/src/modules/two-factor-authentication/store';
import type { ConfirmDialog } from 'web/src/modules/dialogs/types';

export interface TfaDialogsComposable {
  showTfaErrorDialog: (error: GqlApiBatchedSubRequestError, title?: string) => ReturnType<ShowDialogFunc>;
  showTfaSuccessDialog: (options: ConfirmDialog) => ReturnType<ShowDialogFunc>;
}

export default function useTfaDialogs(): TfaDialogsComposable {
  const { showDialog } = useDialogs();
  const { $translate } = useI18n();
  const stringifyReservedKeys = toRef(useTwoFactorAuthenticationStore(), 'stringifyReservedKeys');

  function showTfaErrorDialog(
    error: GqlApiBatchedSubRequestError,
    title?: string,
  ): ReturnType<ShowDialogFunc> {
    return showDialog({
      presetName: PresetName.ALERT_WARNING,
      options: {
        priority: DialogPriority.error,
        title: title ?? $translate('JS_CAPTION_ATTENTION').value,
        confirmMessage: error.message,
        dataTestId: 'tfa-error',
      },
    });
  }

  function showTfaSuccessDialog(options: ConfirmDialog): ReturnType<ShowDialogFunc> {
    return showDialog({
      presetName: PresetName.CONFIRM,
      options: {
        ...tfaCodesPresetOptions,
        codes: stringifyReservedKeys.value,
        buttons: [
          {
            label: $translate('WEB2_MODAL_COPY').value,
          },
          {
            label: $translate('WEB2_CLOSE_CUSTOMER_NOTIFICATION').value,
          },
        ],
        dataTestId: 'tfa-success',
        ...options,
      },
    });
  }

  return {
    showTfaErrorDialog,
    showTfaSuccessDialog,
  };
}
