<script lang="ts" setup>
import { VIcon } from '@components/v-icon';

import CasinoCategoryList from 'web/src/modules/casino/submodules/lobby/components/CasinoCategoryList/CasinoCategoryList.vue';
import CasinoCategoryListItem
  from 'web/src/modules/casino/submodules/lobby/components/CasinoCategoryListItem/CasinoCategoryListItem.vue';
import VImage from 'web/src/components/Image/VImage/VImage.vue';
import type { CasinoCategoryTabsProps } from 'web/src/modules/casino/submodules/lobby/components/CasinoCategoryTabs/types';
import { useCasinoCategoryTabs } from 'web/src/modules/casino/submodules/lobby/components/CasinoCategoryTabs/composables';

defineProps<CasinoCategoryTabsProps>();

const { getIconProperties } = useCasinoCategoryTabs();
</script>

<template>
  <CasinoCategoryList v-auto-id="'CasinoCategoryTabs'"
    v-data-test="{ el: 'casino-category-list', loading: isLoading }"
    :list="list"
    :class="$style['casino-category-tabs']"
  >
    <template #item="{ item }">
      <CasinoCategoryListItem
        :label="item.name"
        :is-active="item.isActive"
        :is-loading="isLoading"
        :location="item.location"
        :class="$style['casino-category-tabs__item']"
      >
        <template #icon>
          <VImage
            v-if="item.iconUrl"
            :src="item.iconUrl"
            :class="{
              [$style['casino-category-tabs__image']]: true,
              [$style['casino-category-tabs__image--active']]: item.isActive,
            }"
          />
          <VIcon
            v-else
            v-bind="getIconProperties(item)"
          />
        </template>
      </CasinoCategoryListItem>
    </template>
  </CasinoCategoryList>
</template>

<style lang="scss" module>
.casino-category-tabs {
  $self: &;

  &__image {
    @include casinoCategoryIconFilter;

    width: 20px;
    height: 20px;

    #{$self}__item:hover & {
      @include casinoCategoryIconHover;
    }

    &--active {
      @include casinoCategoryIconFilterActive;

      #{$self}__item:hover & {
        @include casinoCategoryIconFilterActive;
      }
    }
  }
}
</style>
