import { isNumber } from '@leon-hub/guards';
import { QueryParameterName } from '@leon-hub/query-manager';
import { getBuiltinConfig, getQueryParams } from '@leon-hub/service-locator-env';

import type ApplicationRestClient from 'web/src/modules/core/services/api/ApplicationRestClient';

export interface CoreApi {
  syncServerTime(): Promise<number>;
}

let canUseConfig = !process.env.VUE_APP_PLATFORM_CORDOVA;

const initialTimeExpiry = 10_000;
export default function createCoreApi(client: ApplicationRestClient): CoreApi {
  return {
    async syncServerTime() {
      // TODO: mv condition to function.
      if (getQueryParams()[QueryParameterName.TEST_CASE] === 'performance') {
        return Date.now();
      }
      if (canUseConfig) {
        const { docParseTime, time } = getBuiltinConfig();
        if (time && docParseTime) {
          const now = Date.now();
          if (time > now - initialTimeExpiry) {
            const timeFromParseToNow = now - docParseTime;
            return time + timeFromParseToNow;
          }
        }
        canUseConfig = false;
      }
      return client.request<number>({
        id: 'time/sync',
        endpoint: '/time',
        guard: isNumber,
        silent: true,
      });
    },
  };
}
