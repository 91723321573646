import { PostMessageEvent } from '@leon-hub/postmessage-bus';

export const RecaptchaV3PostMessageBusInitiator = 'RecaptchaV3';

export default class RecaptchaV3PostMessageEvent extends PostMessageEvent {
  static readonly init = new PostMessageEvent<{ siteKey: string; action: string | undefined }>('recaptcha-v3-init');

  static readonly mounted = new PostMessageEvent<{}>('recaptcha-v3-mounted');

  static readonly sendToken = new PostMessageEvent<{ token: string }>('recaptcha-v3-send-token');
}
