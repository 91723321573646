<script lang="ts" setup>
import type { PinButtonEmits, PinButtonProps } from './types';
import { PinButtonEvent } from '../../enums';

withDefaults(defineProps<PinButtonProps>(), {
  isFilled: true,
  value: PinButtonEvent.VALUE_0,
});

const emit = defineEmits<PinButtonEmits>();
function onClick(): void {
  emit('click');
}
</script>

<template>
  <button v-auto-id="'PinButton'"
    v-data-test="{ el: `pin-button-${value.toLowerCase()}` }"
    type="button"
    :class="{
      [$style['pin-button']]: true,
      [$style['pin-button--filled']]: isFilled,
      [$style['pin-button--active']]: isActive,
    }"
    @click="onClick"
  >
    <slot />
  </button>
</template>

<style lang="scss" module>
.pin-button {
  $self: &;

  width: 80px;
  height: 80px;
  color: $pinButtonIconColor;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: none;
  border-radius: $pinButtonDefault;

  &--filled {
    @include pinButtonLabel;

    color: $pinButtonFilledColor;
    background-color: $pinButtonFilledBackground;

    &:active,
    &#{$self}--active {
      background-color: $pinButtonFilledActiveBackground;
    }
  }

  &:active,
  &#{$self}--active {
    color: $pinButtonFilledActiveColor;
  }
}
</style>
