import type { Ref } from 'vue';
import { computed } from 'vue';

import type {
  ScrollableElementScrollOffset,
  SizeByDirection,
  VisibleItemsOffset,
} from '../../types';

interface InternalOffset {
  index: number;
  value: number;
}

function calculateInternalOffset(
  scrollOffset: number,
  sizes: SizeByDirection[],
): InternalOffset {
  let sum = 0;
  let offset = 0;

  for (const [index, size] of sizes.entries()) {
    if (sum <= scrollOffset) {
      offset = index;
    }

    if (sum + size > scrollOffset) {
      break;
    }

    sum += size;
  }

  return {
    index: offset,
    value: sum,
  };
}

/**
 * basically, it returns start index for render list
 */
export function createVisibleItemsOffset(
  scrollOffset: ScrollableElementScrollOffset,
  sizes: Ref<SizeByDirection[]>,
): VisibleItemsOffset {
  const internalOffset = computed<InternalOffset>(() => calculateInternalOffset(scrollOffset.value, sizes.value));

  return {
    index: computed(() => internalOffset.value.index),
    value: computed(() => internalOffset.value.value),
  };
}
