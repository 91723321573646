import RouteName from './RouteName';
import { isCasinoRouteName } from './CasinoRouteName';

export default function isEGSRoute(routeName: unknown, isEGSHomePageType: boolean): boolean {
  if (process.env.VUE_APP_FEATURE_CASINO_DISABLED) {
    return false;
  }

  return isCasinoRouteName(routeName)
    || (isEGSHomePageType && routeName === RouteName.HOME);
}
