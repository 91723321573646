import { computed } from 'vue';
import type { ComputedRef } from 'vue';

import { assert, isOptionalString, isString } from '@leon-hub/guards';
import { safeParseInt } from '@leon-hub/utils';

import type { SmsCodeInputProps } from 'web/src/components/Input';
import isSmsCodeInputProps from 'web/src/components/Input/guards/isSmsCodeInputProps';

import getErrorFields from '../../utils/getErrorFields';
import getHintLinkFields from '../../utils/getHintLinkFields';
import type {
  FormWrapperWidgetProperties,
} from '../../types';

interface UseFormSmsCode {
  componentProps: ComputedRef<SmsCodeInputProps>;
}

export default function useFormSmsCode(
  props: FormWrapperWidgetProperties<SmsCodeInputProps>,
): UseFormSmsCode {
  const componentProps = computed<SmsCodeInputProps>(() => {
    const {
      name,
      hintMessage,
      value,
      options,
      error,
      disabled,
      autofocus,
    } = props;
    if (options?.size) {
      options.size = isString(options?.size) ? safeParseInt(options.size) ?? 6 : options.size;
    }
    assert(isOptionalString(value));
    assert(isSmsCodeInputProps(options), 'Unexpected options for SmsCode');

    return {
      name,
      value,
      disabled,
      ...options,
      ...getErrorFields(options, error),
      ...getHintLinkFields(options),
      hintRight: hintMessage,
      autofocus,
    };
  });
  return {
    componentProps,
  };
}
