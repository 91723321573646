import { assert, isNullOrUndefined, isNumber } from '@leon-hub/guards';

import type { MaskOptions } from '../../types';
import type { ChangeInputValueResult } from '../types';
import handleMaskedValueChange from './handleMaskedValueChange';

interface Payload {
  event: InputEvent;
  currentValue: string;
  maskOptions: MaskOptions;
}

export default function handleMaskedInput({ event, maskOptions, currentValue }: Payload): ChangeInputValueResult {
  const { target, data } = event;
  assert(target instanceof HTMLInputElement);
  const isChromeAutofill = isNullOrUndefined(data);
  let beforeSelectionStart = target.selectionStart;
  assert(isNumber(beforeSelectionStart));
  if (isChromeAutofill) {
    // also cut event. Need to pass empty string as empty value
    beforeSelectionStart = 0;
    // if we add value then cursor positionStart is always after added value,
    // but we need the start cursor position, that is why we remove length of adding data (always 1)
  } else if (data !== null) {
    beforeSelectionStart -= 1;
  }

  return handleMaskedValueChange({
    target,
    selectionStart: beforeSelectionStart,
    selectionEnd: beforeSelectionStart,
    data: isChromeAutofill ? target.value : data,
    currentValue: isChromeAutofill ? '' : currentValue,
    maskOptions,
  });
}
