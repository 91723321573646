<script lang="ts" setup>
import {
  ButtonHeight,
  ButtonKind,
} from '@leon-hub/module-buttons';
import { IconName } from '@leon-hub/icons';

import LobbyEgsNoGames from 'web/src/modules/lobby/components/LobbyEgsNoGames/LobbyEgsNoGames.vue';
import LobbyGrid from 'web/src/modules/lobby/components/LobbyGrid/LobbyGrid.vue';
import LobbySectionHeader from 'web/src/modules/lobby/components/LobbySectionHeader/LobbySectionHeader.vue';
import VTabs from 'web/src/components/Tabs/VTabs/VTabs.vue';
import { useLobbyGridSection } from 'web/src/modules/lobby/components/LobbyGridSection/composables';
import type {
  LobbySectionBaseEmits,
  LobbySectionBaseProps,
} from 'web/src/modules/lobby/types';
import SButton from 'web/src/components/Button/SButton/SButton.vue';
import VLinearProgress from 'web/src/components/LinearProgress/VLinearProgress/VLinearProgress.vue';
import { ProgressHeight } from 'web/src/components/LinearProgress/VLinearProgress/enums';
import LobbyFilters from 'web/src/modules/lobby/components/LobbyFilters/LobbyFilters.vue';
import LButton from 'web/src/components/Button/LButton/LButton.vue';

const props = defineProps<LobbySectionBaseProps>();
const emits = defineEmits<LobbySectionBaseEmits>();

const {
  isEdit,
  wrapper,
  lobbyGridBaseProps,
  headerProps,
  isSmallHeader,
  isEditBtnEnabled,
  isHeaderVisible,
  tabsProps,
  tabs,
  noGamesDesc,
  noGamesTitle,
  totalCounter,
  totalText,
  currentCounter,
  emitActionClick,
  onEditClick,
  emitTopWinnersFilterClick,
  onClickLobbyItem,
  emitScrollTop,
} = useLobbyGridSection(props, emits);
</script>

<template>
  <div v-auto-id="'LobbyGridSection'"
    ref="wrapper"
    :class="[
      [$style['lobby-grid-section']],
      [$style[`lobby-grid-section--widget-${widgetId}`]],
      [$style[`lobby-grid-section--item-type-${lobbyItemType}`]],
    ]"
  >
    <template v-if="isHeaderVisible">
      <LobbySectionHeader
        v-bind="headerProps"
        is-grid-layout
        :class="{
          [$style['lobby-grid-section__header']]: true,
          [$style['lobby-grid-section__header--small']]: isSmallHeader,
        }"
        @action-click="emitActionClick"
      >
        <template
          v-if="null && isFiltersAllowed && !isEditBtnEnabled"
          #filters
        >
          <LobbyFilters />
        </template>
        <template
          v-if="isEditBtnEnabled"
          #action
        >
          <SButton
            v-if="null"
            :label="isEdit ? $t('WEB2_DONE') : $t('WEB2_CHANGE')"
            :kind="isEdit ? 'primary' : 'tertiary-primary'"
            :size="null ? 'small' : 'medium'"
            :icon-name="isEdit ? undefined : IconName.SLOTT_PENCIL"
            @click="onEditClick"
          />
          <LButton
            v-else
            :label="isEdit ? $t('WEB2_DONE') : $t('WEB2_CHANGE')"
            :kind="isEdit ? ButtonKind.PRIMARY : ButtonKind.BASE"
            :height="ButtonHeight.MEDIUM"
            :is-uppercase="false"
            @click="onEditClick"
          />
        </template>
      </LobbySectionHeader>
    </template>
    <template
      v-if="topWinnersFilter"
    >
      <VTabs
        v-if="!showSkeleton"
        v-bind="tabsProps"
        :class="$style['lobby-grid-section__tabs']"
        is-bordered
        @tab-click="emitTopWinnersFilterClick"
      />
      <div
        v-else
        :class="{
          [$style['lobby-grid-section__tabs']]: true,
          [$style['lobby-grid-section__tabs--skeleton']]: true,
        }"
      >
        <div
          v-for="item in tabs.length"
          :key="item"
          :class="$style['lobby-grid-section__skeleton-tab']"
        />
      </div>
    </template>
    <LobbyEgsNoGames
      v-if="!lobbyItems.length && !showSkeleton"
      :text="noGamesDesc"
      :heading="noGamesTitle"
      :is-button-hidden="isEditable"
      :class="{
        [$style['lobby-grid-section__no-games']]: true,
        [$style['lobby-grid-section__no-games--filter']]: topWinnersFilter,
      }"
    />
    <LobbyGrid
      v-bind="lobbyGridBaseProps"
      :class="$style['lobby-grid-section__grid']"
      @click-lobby-item="onClickLobbyItem"
    />
    <div
      v-if="!'1' && !showSkeleton"
      :class="$style['lobby-grid-section__more']"
    >
      <div
        v-if="totalCounter && !isEditBtnEnabled"
        :class="$style['lobby-grid-section__more-progress-container']"
      >
        <VLinearProgress
          :value="currentCounter"
          :of="totalCounter"
          :height="ProgressHeight.TINY_EXTRA"
        />
        <div
          :class="$style['lobby-grid-section__more-progress-text']"
        >
          {{ totalText }}
        </div>
      </div>
      <SButton
        v-if="hasNext && isLoadMoreButtonVisible"
        kind="tertiary-secondary"
        size="medium"
        :label="$t('WEB2_EGS_LOAD_MORE_BTN')"
        :is-loading="isMoreLoading"
        @click="emitScrollTop"
      />
    </div>
  </div>
</template>

<style lang="scss" module>
@import 'web/src/components/Skeleton/styles/skeleton';

@include for-layout using($isDesktop) {
  .lobby-grid-section {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    &__grid {
      margin: $lobbyGridMarginPhone;

      @include screen-desktop-small-min {
        margin: $lobbyGridMarginDesktopSmall;
      }

      @include screen-desktop-large-min {
        margin: $lobbyGridMarginDesktopBig;
      }
    }

    &__tabs {
      margin: -8px 0 8px;

      &--skeleton {
        display: flex;
        align-items: center;
        height: 44px;
      }
    }

    &__skeleton-tab {
      @include skeletonFont(40px, 20px, 7px, var(--TextDefault));

      margin: 0 16px;
    }

    &__no-games {
      margin-top: -8px;

      &--filter {
        margin-top: 16px;
      }
    }

    &--widget {
      &-SQUARE_ROWS_LIST {
        padding-bottom: if($isDesktop, 16px, 8px);
      }
    }

    &--item-type {
      &-BANNERS {
        margin-bottom: if($isDesktop, -16px, 0);

        .lobby-grid-section__grid {
          margin: if($isDesktop, 0, 0 8px);
        }
      }
    }

    &__more {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: $lobbyGridMoreMarginTopSmall;

      @include screen-desktop-large-min {
        margin-top: $lobbyGridMoreMarginTopBig;
      }
    }

    &__more-progress-container {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 240px;
      margin: 16px 0;
    }

    &__more-progress-text {
      @include font-body-b6;

      text-align: center;
    }
  }
}
</style>
