import {
  isString, isArray, isObject, isBoolean, isNumber,
} from '@leon-hub/guards';
import {
  isFile,
} from '@leon-hub/utils';

import type { FormDataValue } from '../types';

const isFormDataValue = (value: unknown): value is FormDataValue => isString(value)
  || isObject(value)
  || isFile(value)
  || isArray(value)
  || isBoolean(value)
  || isNumber(value);

export default isFormDataValue;
