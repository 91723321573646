import type {
  EventsChangesFetchOptions,
  GetSportEventsChangesResponse,
  GetSportEventsResponse, LiveEventsFetchOptions,
} from 'web/src/modules/sportline/types/rest';
import type { UseSportlineApiLoadEventsProps } from 'web/src/modules/sportline/services/composables/types';
import { BetlineType } from 'web/src/modules/sportline/enums/rest';

import { useSportlinePendingApi } from './useSportlinePendingApi';
import type { PendingGetEventsInput } from './useSportlinePendingApi';

interface UseSportlineApiLoadLiveEventsComposable {
  loadLiveEvents(
    options: LiveEventsFetchOptions & EventsChangesFetchOptions,
  ): Promise<Maybe<GetSportEventsResponse | GetSportEventsChangesResponse>>;
}

function getBetlineType({ isExtraLiveEnabled, doAddUpcoming }: {
  isExtraLiveEnabled?: boolean;
  doAddUpcoming?: boolean;
}): BetlineType {
  if (doAddUpcoming) {
    return isExtraLiveEnabled ? BetlineType.UpcomingExtraLive : BetlineType.UpcomingLive;
  }

  return isExtraLiveEnabled ? BetlineType.ExtraLive : BetlineType.Live;
}

export function useSportlineApiLoadLiveEvents(
  props: UseSportlineApiLoadEventsProps,
): UseSportlineApiLoadLiveEventsComposable {
  const {
    tryRequestWithCTag,
    defaultRestApiFlags,
    isLiveOutrightsEnabled,
    isExtraLiveEnabled,
  } = props;

  const {
    loadEvents: loadLiveEvents,
  } = useSportlinePendingApi<LiveEventsFetchOptions>({
    tryRequestWithCTag,
    getEventsInput: ({
      silent, regionId, sportFamily, doAddUpcoming,
    }): PendingGetEventsInput => ({
      betline: getBetlineType({
        isExtraLiveEnabled: isExtraLiveEnabled.value,
        doAddUpcoming,
      }),
      regionId,
      sportFamily,
      silent,
      hideClosed: true,
      flags: [...defaultRestApiFlags.value],
      LMCE: isLiveOutrightsEnabled.value,
    }),
    getPendingParams: ({
      vTag, regionId, sportFamily, doAddUpcoming,
    }) => ({
      vTag,
      regionId,
      sportFamily,
      betlines: [getBetlineType({
        isExtraLiveEnabled: isExtraLiveEnabled.value,
        doAddUpcoming,
      })],
    }),
  });

  return { loadLiveEvents };
}
