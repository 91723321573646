import type { Ref } from 'vue';
import { computed, toRef } from 'vue';

import { useDateLocalizers } from 'web/src/modules/i18n/composables/useDateLocalizers';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import DateTime from 'web/src/utils/DateTime';

interface UsePostMatchHeadlineInfoProps {
  kickoff: Ref<Maybe<number> | undefined>;
}

interface UsePostMatchHeadlineInfoComposable {
  date: Ref<Maybe<string>>;
  time: Ref<Maybe<string>>;
}

export function usePostMatchHeadlineInfo(
  props: UsePostMatchHeadlineInfoProps,
): UsePostMatchHeadlineInfoComposable {
  const { kickoff } = props;

  const settingsStore = useSportlineSettingsStore();
  const datePattern = toRef(settingsStore, 'sportEventDetailsDatePattern');
  const { toFullLocaleDate } = useDateLocalizers();

  const date = computed<Maybe<string>>(() => (
    kickoff.value ? toFullLocaleDate(kickoff.value, datePattern.value) : null
  ));
  const time = computed<Maybe<string>>(() => (
    kickoff.value ? DateTime.withTimeStamp(kickoff.value).toTime() : null
  ));

  return {
    date,
    time,
  };
}
