import type { Ref } from 'vue';
import { ref } from 'vue';

import type { BreadcrumbId } from 'web/src/modules/sportline/submodules/breadcrumbs/enums';

interface UseActiveBreadcrumbComposable {
  activeId: Ref<Maybe<BreadcrumbId>>;
  toggleId(id: Maybe<BreadcrumbId>): void;
}

export function useActiveBreadcrumb(): UseActiveBreadcrumbComposable {
  const activeId = ref<Maybe<BreadcrumbId>>(null);

  function toggleId(id: Maybe<BreadcrumbId>): void {
    activeId.value = activeId.value !== id ? id : null;
  }

  return {
    activeId,
    toggleId,
  };
}
