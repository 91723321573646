import type { Ref } from 'vue';
import { ref, watch } from 'vue';

import type { BonusCodeInputProps } from '../../types';

interface UseBonusCodeInput {
  isBonusCodeVisible: Ref<boolean>;
  makeVisible(): void;
}

export default function useBonusCodeInput(
  props: BonusCodeInputProps,
): UseBonusCodeInput {
  const isBonusCodeVisible = ref<boolean>(!!props.value?.length || !!props.autofocus);

  const makeVisible = (relatedProp?: string | boolean) => {
    if (relatedProp) {
      isBonusCodeVisible.value = true;
    }
  };

  watch(() => props.value, makeVisible);
  watch(() => props.autofocus, makeVisible);

  return {
    isBonusCodeVisible,
    makeVisible,
  };
}
