import type { I18nFunctionReactive } from 'web/src/modules/i18n/types';
import { ButtonActionType } from 'web/src/modules/profile/submodules/bonuses/enums';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import type { VButtonPropsWithId } from 'web/src/modules/profile/submodules/bonuses/types';

export default function getPlayButton(
  isGameDetailsLoading: boolean,
  $t: I18nFunctionReactive,
): VButtonPropsWithId {
  return {
    id: ButtonActionType.PLAY,
    label: $t('WEB2_BONUSES_PLAY_BUTTON').value,
    kind: ButtonKind.PRIMARY,
    isLoading: isGameDetailsLoading,
  };
}
