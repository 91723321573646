import type {
  Ref,
} from 'vue';
import {
  computed,
} from 'vue';

import type { EgsJackpotAmountProps } from 'web/src/modules/egs/submodules/jackpots/components/EgsJackpotAmount/types';
import {
  getCurrencySymbol,
  shouldReduceMoneyAmount,
} from 'web/src/modules/money/utils';

interface EgsJackpotAmountComposable {
  currencySymbol: Ref<string>;
  priceItems: Ref<string[]>;
}

export default function useEgsJackpotAmount(
  props: EgsJackpotAmountProps,
): EgsJackpotAmountComposable {
  const currencySymbol = computed<string>(() => getCurrencySymbol(props.currency));

  const priceItems = computed<string[]>(() => {
    if (shouldReduceMoneyAmount(props.value, props.currency, 100_000_000)) {
      return `${Math.ceil(props.value / 1000).toFixed()}K`.split('');
    }

    return props.value.toFixed(2).split('');
  });

  return {
    currencySymbol,
    priceItems,
  };
}
