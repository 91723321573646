import type { UIField, UIFormSchema } from '@leon-hub/api-sdk';

import type { FormExternalErrorsValue, FormExternalErrors, FormData } from 'web/src/components/Form/types';

const extractCustomerFormErrors = (
  uiFormSchema: UIFormSchema,
  formData: FormData,
): FormExternalErrors => uiFormSchema
  .fields
  .filter((field) => field.error != null)
  .reduce<FormExternalErrors>((accumulator, field: UIField) => {
    const customFormCustomError: FormExternalErrorsValue = {
      message: field.error || 'Error',
      value: String(formData[field.id] || field.defaultValue),
    };
    return {
      ...accumulator,
      [field.id]: [customFormCustomError],
    };
  }, {});

export default extractCustomerFormErrors;
