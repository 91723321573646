import type { VButtonProps } from '../../types';

const baseClass = 'button__icon';
export default function getIconClassNamesForButton(
  props: Pick<VButtonProps, 'label' | 'iconRight' | 'isLoading'>,
  hasDefaultSlot: boolean,
): string[] {
  const classNames = [baseClass];

  if (props.isLoading) {
    classNames.push(`${baseClass}--loader`);
  }

  if ((props.label || hasDefaultSlot) && !props.iconRight) {
    classNames.push(`${baseClass}--left`);
  }

  if (props.iconRight) {
    classNames.push(`${baseClass}--right`);
  }

  return classNames;
}
