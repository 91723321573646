import type { Ref } from 'vue';
import { computed } from 'vue';
import { useRouter } from 'vue-router';

import type { BonusRequirement } from '@leon-hub/api-sdk';
import { SportBonusState } from '@leon-hub/api-sdk';
import { RouteName } from '@leon-hub/routing-config-names';

import type { PromotionTermsProps } from 'web/src/modules/promotions/components/PromotionTerms/types';
import { useI18n } from 'web/src/modules/i18n/composables';

interface PromotionTermsComposable {
  requirementsData: Ref<{
    title: string;
    items: BonusRequirement[] | undefined;
    percentage: number;
  }>;
  showTerms: Ref<boolean>;
  onReadMoreClick(): void;
}

export default function usePromotionTerms(
  props: PromotionTermsProps,
): PromotionTermsComposable {
  const { $translate } = useI18n();
  const router = useRouter();

  const requirementsData = computed(() => {
    if (props.sportBonus) {
      const {
        bonusProgress, bonusRequirements, wagerProgress, wager,
      } = props.sportBonus;
      if (bonusProgress === 100 && wager?.requirements.length) {
        return {
          title: $translate('WEB2_WAGERING_CONDITIONS').value,
          items: wager?.requirements,
          percentage: wagerProgress || 0,
        };
      }
      return {
        title: $translate('WEB2_CONDITIONS_OF_OBTAINING').value,
        items: bonusRequirements,
        percentage: bonusProgress || 0,
      };
    }

    return {
      title: '',
      items: undefined,
      percentage: 0,
    };
  });

  const showTerms = computed(() => (
    props.sportBonus?.state !== SportBonusState.CLOSED && !!requirementsData.value.items?.length
  ));

  const onReadMoreClick = () => {
    if (props.termsKey) {
      void router.push({
        name: RouteName.CMS_PROMO_TERMS,
        params: {
          cmsKey: props.termsKey,
        },
      });
    }
  };

  return {
    requirementsData,
    showTerms,
    onReadMoreClick,
  };
}
