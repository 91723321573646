<script lang="ts" setup>
import { ref } from 'vue';

import type { PromotionScrollableElement } from 'web/src/modules/promotions/types';

withDefaults(defineProps<{
  subheader?: string;
}>(), {
  subheader: '',
});

const wrapper = ref<HTMLDivElement>();

function getOffsetTop(): number {
  return wrapper.value?.offsetTop || 0;
}

function scrollIntoView(options?: boolean | ScrollIntoViewOptions): void {
  wrapper.value?.scrollIntoView(options);
}

defineExpose<PromotionScrollableElement>({
  getOffsetTop,
  scrollIntoView,
});
</script>

<template>
  <div v-auto-id="'LeaderBoardWrapper'"
    ref="wrapper"
    :class="$style['leader-board-wrapper']"
  >
    <h2 :class="$style['leader-board-wrapper__header']">
      {{ $t('JSPNAV_LEADERBOARD_TTL') }}
    </h2>
    <h3
      v-if="subheader"
      :class="$style['leader-board-wrapper__sub-header']"
    >
      {{ subheader }}
    </h3>
    <slot />
  </div>
</template>

<style lang="scss" module>
.leader-board-wrapper {
  @include leaderBoardWrapper;

  &__header {
    @include leaderBoardWrapperHeader;
  }

  &__sub-header {
    @include leaderBoardWrapperSubHeader;
  }
}
</style>
