import type { CombinedSlipEntryInfo, SlipEntry } from '../types';

export default function getSlipEntryFromCombinedInfo({
  ts,
  event,
  market,
  runner,
  odds,
  banker,
  marketStatus,
  betline,
  zeroMargin,
  competitors,
  runnerTags,
  marketTypeIdentifier,
  marketTypeTag,
}: CombinedSlipEntryInfo): SlipEntry {
  return {
    ts,
    event,
    market,
    runner,
    odds,
    banker,
    marketStatus,
    betline,
    zeroMargin,
    competitors,
    runnerTags,
    marketTypeIdentifier,
    marketTypeTag,
  };
}
