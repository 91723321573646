import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import useBaseApiClient from 'web/src/modules/core/services/api/useBaseApiClient';

export default function setApiHeaders(value: Record<string, string>): void {
  const gqlApi = useGraphqlClient();
  const restClient = useBaseApiClient();

  gqlApi.setCustomHeaders(value);
  restClient.setCustomHeaders(value);
}
