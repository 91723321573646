<script setup lang="ts">
import { IconSize } from '@leon-hub/icons';

import VSocial from 'web/src/components/Social/VSocial/VSocial.vue';
import type { PromotionDetailsSocialsProps } from 'web/src/modules/promotions/components/PromotionDetailsSocials/types';
import type { SocialType } from 'web/src/components/Social/VSocial/enums';

defineProps<PromotionDetailsSocialsProps>();

function getSocialType(type?: Maybe<string>): SocialType | undefined {
  return type ? type as SocialType : undefined;
}
</script>

<template>
  <div v-auto-id="'PromotionDetailsSocials'"
    :class="$style['promotion-socials']"
  >
    <VSocial
      v-for="(item, index) in promotion.socialLinks"
      :key="index"
      :class="$style['promotion-socials__icon']"
      :social-type="getSocialType(item.name)"
      :url="`${item.url}`"
      :size="IconSize.SIZE_32"
    />
  </div>
</template>

<style lang="scss" module>
.promotion-socials {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &__icon {
    margin-right: 8px;
    color: var(--TextSecondary);

    &:hover {
      color: var(--TextDefault);
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
