import { PostMessageEvent } from '@leon-hub/postmessage-bus';

import type { SportradarLiveMatchTrackerInitConfig, SportradarLiveMatchTrackerResized } from '../types';

export const PostMessageBusInitiator = 'SportradarLiveMatchTrackerFramedWidget';

export default class SportradarLiveMatchTrackerPostMessageEvent extends PostMessageEvent {
  static readonly mounted = new PostMessageEvent('sir-widget-mounted');

  static readonly init = new PostMessageEvent<SportradarLiveMatchTrackerInitConfig>('sir-widget-init');

  static readonly loaded = new PostMessageEvent('sir-widget-loaded');

  static readonly widgetLoadTimeout = new PostMessageEvent('sir-widget-load-timeout');

  static readonly widgetLoadError = new PostMessageEvent('sir-widget-load-error');

  static readonly widgetResized = new PostMessageEvent<SportradarLiveMatchTrackerResized>('sir-widget-resized');
}
