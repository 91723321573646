import type { MultiSinglesMetaInfoRecord } from '../types';

export default function isMultiSinglesMetaInfoRecordStakeValid({ stakeValue, minStake, maxStake }: MultiSinglesMetaInfoRecord)
  : boolean {
  const numericStake = Number(stakeValue);
  if (minStake && numericStake < minStake) {
    return false;
  }
  if (maxStake && numericStake > maxStake) {
    return false;
  }
  return numericStake > 0;
}
