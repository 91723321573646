import type { CoreSportEventResponse } from 'web/src/modules/sportline/types/rest';
import { BetlineType } from 'web/src/modules/sportline/enums/rest';

export function isLiveEventResponse(response: CoreSportEventResponse): boolean {
  return !!response.liveStatus && response.betline === BetlineType.Live;
}

export function isOutrightEventResponse(response: CoreSportEventResponse): boolean {
  return response.betline === BetlineType.Outright
    || response.competitors.length !== 2;
}
