<script lang="ts" setup>
import {
  ref,
  toRef,
  nextTick,
  onMounted,
  onBeforeUnmount,
} from 'vue';

import type {
  VPopperRef,
  VPopperProps,
  VPopperEmits,
} from './types';
import { PopperPosition, PopperApplyTarget } from './enums';
import { useVPopper } from './composables';

const props = defineProps<VPopperProps>();
const emit = defineEmits<VPopperEmits>();

const {
  popper,
  reference,
  content,
  showContent,
  isBodyContentAvailable,
  destroyPopper,
  toggle,
  hide,
  show,
  focusReferenceElm,
} = useVPopper({
  modifiers: toRef(props, 'modifiers', []),
  position: toRef(props, 'position', PopperPosition.BOTTOM),
  applyTarget: toRef(props, 'applyTarget'),
  hasOffset: toRef(props, 'hasOffset', true),
  isFullWidth: toRef(props, 'isFullWidth', false),
  isFullHeight: toRef(props, 'isFullHeight', false),
}, emit);

const isMounted = ref(false);
onMounted(async () => {
  // We have to await full layout mount before select components except body
  await nextTick();
  isMounted.value = true;
});
onBeforeUnmount(destroyPopper);

defineExpose<VPopperRef>({
  show,
  hide,
  focusReferenceElm,
});
</script>

<template>
  <div v-auto-id="'VPopper'"
    ref="popper"
    :class="$style['popper']"
  >
    <div
      ref="reference"
      :class="$style['popper__reference']"
      @click="toggle"
    >
      <slot name="reference" />
    </div>
    <div
      v-if="!applyTarget"
      v-show="showContent"
      ref="content"
      :class="{
        [$style['popper__content']]: true,
        [$style['popper__content--full-width']]: isFullWidth,
        [$style['popper__content--full-height']]: isFullHeight,
      }"
    >
      <slot
        :hide="hide"
        :shown="showContent"
      />
    </div>
    <template v-else-if="applyTarget === PopperApplyTarget.Body || isMounted">
      <Teleport
        :to="applyTarget"
        :disabled="!isBodyContentAvailable"
      >
        <div
          v-show="showContent"
          ref="content"
          :class="{
            [$style['popper__content']]: true,
            [$style['popper__content--full-width']]: isFullWidth,
            [$style['popper__content--full-height']]: isFullHeight,
          }"
        >
          <slot
            :hide="hide"
            :shown="showContent"
          />
        </div>
      </Teleport>
    </template>
    <div v-else />
  </div>
</template>

<style lang="scss" module>
.popper {
  &__reference {
    cursor: pointer;
  }

  &__content {
    z-index: z-index(popper);

    &--full-width {
      width: 100%;
    }

    &--full-height {
      height: 100%;
    }
  }
}
</style>
