import type { VirtualSportApi } from './createVirtualSportApi';
import createVirtualSportApi from './createVirtualSportApi';

let virtualSportApi: VirtualSportApi | undefined;

export function useVirtualSportApi(): VirtualSportApi {
  if (!virtualSportApi) {
    virtualSportApi = createVirtualSportApi();
  }

  return virtualSportApi;
}
