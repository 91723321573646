import type { Ref } from 'vue';
import { computed, toRef } from 'vue';

import { useSiteConfigStore } from 'web/src/modules/core/store';

interface UseSlipShareDialogRoute {
  isShareRouteEnabled: Ref<boolean>;
}

export function useSlipShareDialogRoute(): UseSlipShareDialogRoute {
  const siteConfigStore = useSiteConfigStore();

  const slipBlock = toRef(siteConfigStore, 'slipBlock');

  const isShareRouteEnabled = computed<boolean>(() => !!(slipBlock.value?.sharedBetSlipBookingCodeEnabled
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    || slipBlock.value?.sharedBetSlipLinkEnabled));

  return {
    isShareRouteEnabled,
  };
}
