export enum DialogPriority {
  default = 1,
  notification = 2,
  error = 3,
}

export enum AnalyticsModalType {
  WARNING = 'warning',
  WARNING_EGS = 'warningEGS',
}

export enum DialogComponent {
  ConfirmModal = 'ConfirmModal',
  CountDownModal = 'CountDownModal',
  ConfirmWithdrawalModal = 'ConfirmWithdrawalModal',
  ConfirmDepositModal = 'ConfirmDepositModal',
  BonusWithdrawalModal = 'BonusWithdrawalModal',
  CaptchaModal = 'CaptchaModal',
  TfaCodesModal = 'TfaCodesModal',
  CmsContentModal = 'CmsContentModal',
}

export const enum PresetName {
  ALERT_SUCCESS = 'ALERT_SUCCESS',
  ALERT_SUCCESS_NO_BUTTONS = 'ALERT_SUCCESS_NO_BUTTONS',
  ALERT_WARNING = 'ALERT_WARNING',
  ALERT_ERROR = 'ALERT_ERROR',
  CONFIRM = 'CONFIRM',
  CAPTCHA = 'CAPTCHA',
  CMS_MODAL = 'CMS_MODAL',
}

export const enum DialogAction {
  MODAL_CLOSE = 'modalClose',
  MODAL_DESTROY = 'modalDestroy',
  CONFIRM = 'confirm',
  SECONDARY_CLICK = 'secondaryClick',
  ADDITIONAL_BUTTON = 'additionalButton',
  MODAL_COUNTDOWN = 'modalCountDown',
}

export const enum ModalProvidable {
  modalContext = 'modalContext',
}
