import type { Ref } from 'vue';
import { computed } from 'vue';

import { useSyncSportline } from 'web/src/modules/sportline/submodules/sync-sportline';
import { useExtendedEventSportlineUpdateTimeout } from 'web/src/modules/sportline/submodules/update-timeout';

import type { ExtendedEventsIds } from '../types';

interface UseExtendedEventBackgroundRequestsProps {
  eventsIds: Ref<ExtendedEventsIds>;
}

interface UseExtendedEventBackgroundRequestsComposable {
  resume(): void;
  pause(): void;
}

export function useExtendedEventBackgroundRequestsStoreComposable(
  props: UseExtendedEventBackgroundRequestsProps,
  request: () => Promise<void>,
): UseExtendedEventBackgroundRequestsComposable {
  const { eventsIds } = props;

  const { timeout: extendedEventUpdateInterval } = useExtendedEventSportlineUpdateTimeout();

  const isAvailable = computed<boolean>(() => (eventsIds.value.length > 0));

  const {
    resumeSyncState: resume,
    pauseSyncState: pause,
  } = useSyncSportline(request, extendedEventUpdateInterval, { condition: isAvailable });

  return { resume, pause };
}
