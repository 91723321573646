import type { Directive } from 'vue';

import { assert } from '@leon-hub/guards';

const createTestAttribute = (attributes: Record<string, string>): Record<string, string> => {
  if (process.env.NODE_ENV !== 'test') {
    return {};
  }

  assert(attributes.el?.length > 0, '"el" key with value must be provided');

  return Object.keys(attributes).reduce((result, key) => {
    const name = key === 'el' ? 'data-unit-test-el' : `data-unit-test-attr-${key}`;

    // eslint-disable-next-line no-param-reassign
    result[name] = attributes[key];

    return result;
  }, {} as Record<string, string>);
};

type BindingValues = Record<string, string>;

export const setElementAttributes = (element: Element, values: BindingValues): void => {
  if (process.env.NODE_ENV !== 'test') {
    return;
  }

  const attributes = createTestAttribute(values);
  for (const key of Object.keys(attributes)) {
    element.setAttribute(key, attributes[key]);
  }
};

const vDataTestUnit: Directive = {
  beforeMount: (element, binding) => setElementAttributes(element, binding.value),
  updated: (element, binding) => setElementAttributes(element, binding.value),
  beforeUnmount: (element, binding) => setElementAttributes(element, binding.value),
};

export default vDataTestUnit;
