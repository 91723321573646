export function isIframe(): boolean {
  try {
    return window.self !== window.top;
  } catch {
    return false;
  }
}

export function isCookiesDisabled(): boolean {
  return !process.env.VUE_APP_RENDERING_SSR && navigator && !navigator.cookieEnabled;
}
