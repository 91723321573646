export const enum ProfileButtonsTab {
  SPORTS = '0',
  EGS = '1',
}

export const enum ProfileBonusesActiveTab {
  SPORT = 'sport',
  GAME = 'game',
}

export const enum ButtonActionType {
  PLAY = 'PLAY',
  REFUSE = 'REFUSE',
  DEPOSIT = 'DEPOSIT',
  IDENTIFICATION = 'IDENTIFICATION',
  COLLECT = 'COLLECT',
  CONFIRMATION = 'CONFIRMATION',
  BONUS_CODE_CONFIRMATION = 'BONUS_CODE_CONFIRMATION',
  DEPOSIT_BONUS_CODE = 'DEPOSIT_BONUS_CODE',
  COMPLEX = 'COMPLEX',
}

export const enum InfoStatusType {
  COMPLETE_ACTIVE_COMPAIGN = 'completeActiveCampaign',
  FREEBET_IN_PLAY = 'freebetInPlay',
}

export const enum ProfileBonusesComplexType {
  DEFAULT = 'DEFAULT',
  FREEBET = 'FREEBET',
  FREESPIN = 'FREESPIN',
}
