import type { Ref } from 'vue';
import { ref } from 'vue';

import type { SidebarFilterTabId } from 'web/src/modules/sportline/submodules/sidebar/types';

interface UseInnerActiveTabIdComposable {
  innerActiveTabId: Ref<Maybe<SidebarFilterTabId>>;
  setInnerActiveTab(value: Maybe<SidebarFilterTabId>): void;
}

export function useInnerActiveTabId(): UseInnerActiveTabIdComposable {
  const innerActiveTabId = ref<Maybe<SidebarFilterTabId>>(null);

  return {
    innerActiveTabId,
    setInnerActiveTab(value: Maybe<SidebarFilterTabId>): void {
      innerActiveTabId.value = value;
    },
  };
}
