<script lang="ts" setup>
import { toRef } from 'vue';

import { IconSize } from '@leon-hub/icons';

import type {
  SportlineFilterRef,
  SportlineFilterProps,
  SportlineFilterSlots,
} from 'web/src/modules/sportline/components/navigation-filter/types';
import { SportlineNavigationItemCustomStyle } from 'web/src/modules/sportline/enums/navigation';
import VSwiper from 'web/src/components/Swiper/VSwiper/VSwiper';
import VSwiperSlide from 'web/src/components/Swiper/VSwiperSlide/VSwiperSlide.vue';
import SwiperNavigationButtons
  from 'web/src/components/Swiper/Navigation/SwiperNavigationButtons/SwiperNavigationButtons.vue';
import VLinkPrevented from 'web/src/components/Link/VLinkPrevented/VLinkPrevented.vue';
import SportlineNavigationIndicator from 'web/src/modules/sportline/components/navigation-indicator/SportlineNavigationIndicator.vue';

import { useSportlineFilter } from '../composables/useSportlineFilter';

const props = defineProps<SportlineFilterProps>();
defineSlots<SportlineFilterSlots>();

const items = toRef(props, 'items');

const {
  swiper,
  slideToActive,
  itemsWithIdentifier,
} = useSportlineFilter({ items });

defineExpose<SportlineFilterRef>({ slideToActive });
</script>

<template>
  <div v-auto-id="'SportlineFilter'" :class="{ [$style['sportline-filter']]: true }">
    <VSwiper
      ref="swiper"
      is-scroll-snap-enabled
      @vue:mounted="slideToActive(false)"
    >
      <VSwiperSlide
        v-for="(item, index) in itemsWithIdentifier"
        :key="item.key"
        :class="{ [$style['sportline-filter__slide']]: true }"
        @click="item.onClick(item, index)"
      >
        <VLinkPrevented
          v-data-test="{ el: item.dataTest, ['is-active']: item.isActive }"
          :to="item.to"
          :class="{
            [$style['sportline-filter__item']]: true,
            [$style['sportline-filter__item--active']]: item.isActive,
            [$style['sportline-filter__item--default']]: !item.customStyle,
            [$style['sportline-filter__item--bundesliga']]: item.customStyle === SportlineNavigationItemCustomStyle.Bundesliga,
            [$style['sportline-filter__item--highest-odds']]: item.customStyle === SportlineNavigationItemCustomStyle.HighestOdds,
          }"
          :title="item.title"
        >
          <SportlineNavigationIndicator
            v-if="item.icon"
            :icon="item.icon"
            :size="item.customStyle === SportlineNavigationItemCustomStyle.Bundesliga ? IconSize.SIZE_28 : IconSize.SIZE_20"
            :custom-filter="item.customFilter"
            :class="$style['sportline-filter__item-icon']"
          />

          <span
            :class="{
              [$style['sportline-filter__item-title']]: true,
              [$style['sportline-filter__item-title--default']]: !item.customStyle,
              [$style['sportline-filter__item-title--bundesliga']]: item.customStyle === SportlineNavigationItemCustomStyle.Bundesliga,
              [$style['sportline-filter__item-title--highest-odds']]: item.customStyle === SportlineNavigationItemCustomStyle.HighestOdds,
            }"
          >{{ item.title }}</span>

          <slot
            name="afterItem"
            :item="item"
          />
        </VLinkPrevented>
      </VSwiperSlide>
      <template
        v-if="'1'"
        #pagination-footer
      >
        <SwiperNavigationButtons />
      </template>
    </VSwiper>
  </div>
</template>

<style lang="scss" module>
@include for-layout using ($isDesktop) {
  .sportline-filter {
    // "for-layout" breaks & link
    $filter: '.sportline-filter';
    $item: '.sportline-filter__item';
    $defaultItemBackground: var(--Layer1);
    $defaultItemHoverBackground: if($isEnvFeatureTwinlikeStyle, var(--BrandDefault), var(--TextDefault));
    $defaultItemActiveBackground: if($isEnvFeatureTwinlikeStyle, var(--BrandDefault), var(--TextDefault));
    $defaultItemTextColor: var(--TextPrimary);
    $defaultItemHoverTextColor: if($isEnvFeatureTwinlikeStyle, var(--BrandText), var(--Layer1));
    $defaultItemActiveTextColor: if($isEnvFeatureTwinlikeStyle, var(--BrandText), var(--Layer1));

    position: relative;
    display: inline-flex;
    flex-flow: row nowrap;
    flex-shrink: 0;
    justify-content: left;
    width: 100%;
    padding: if($isDesktop, 0 0 8px 0, 8px 0);
    overflow-x: auto;
    background-color: transparent;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }

    &__slide {
      padding-left: 4px;

      &:first-child {
        padding-left: if($isDesktop, 0, 8px);
      }

      &:last-child {
        padding-right: if($isDesktop, 0, 8px);
      }
    }

    &__item {
      position: relative;
      display: flex;
      flex-direction: if($isEnvFeatureTwinlikeStyle, row, column);
      align-items: center;
      min-width: if($isDesktop, 72px, 68px);
      height: if($isEnvFeatureTwinlikeStyle, 44px, 52px);
      text-align: center;
      text-decoration: none;
      white-space: nowrap;
      cursor: pointer;
      user-select: none;
      border-radius: if($isEnvFeatureTwinlikeStyle, 22px, 5px);

      @if $isEnvFeatureTwinlikeStyle {
        width: auto;
        padding: 0 16px;
      } @else {
        width: if($isDesktop, 72px, 68px);
      }

      &--default,
      &--highest-odds {
        background-color: $defaultItemBackground;

        @if $isDesktop {
          &:hover {
            background-color: $defaultItemHoverBackground;

            & #{$filter}__item-icon,
            & #{$filter}__item-title {
              color: $defaultItemHoverTextColor;
              fill: $defaultItemHoverTextColor;
            }
          }
        }

        // keep colors in the parent component to handle the hovered icon's color
        & #{$filter}__item-icon,
        & #{$filter}__item-title {
          color: $defaultItemTextColor;
          fill: $defaultItemTextColor;
        }

        &#{$item}--active {
          background-color: $defaultItemActiveBackground;

          & #{$filter}__item-icon,
          & #{$filter}__item-title {
            color: $defaultItemActiveTextColor;
            fill: $defaultItemActiveTextColor;
          }
        }
      }

      &--bundesliga {
        background-color: var(--BundesligaBranded);

        @if $isDesktop {
          &:hover {
            background-color: var(--BundesligaBrandedHover);
          }
        }

        & #{$filter}__item-title {
          color: var(--BundesligaBrandedText);
        }

        & #{$filter}__item-icon {
          margin: 4px 0 0;
          fill: var(--BundesligaBrandedText);
        }
      }
    }

    &__item-icon {
      min-width: 20px;
      min-height: 20px;
      margin: if($isEnvFeatureTwinlikeStyle, 0 8px 0 0, 8px 0 4px);
      line-height: 20px;
    }

    &__item-title {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;

      @if $isDesktop {
        margin-bottom: 2px;
      }

      @if $isEnvFeatureTwinlikeStyle {
        @include medium\13\16;

        width: auto;
        padding: 0;
      } @else {
        &--default,
        &--bundesliga {
          @include medium\11\16\-02;

          width: 60px;
        }

        &--highest-odds {
          @include medium\11\16\-06;

          width: 68px;
        }
      }
    }
  }
}
</style>
