import { computed } from 'vue';
import type { ComputedRef } from 'vue';

import { assert, isOptionalString } from '@leon-hub/guards';

import type { SingleSelectProperties, SelectWithSearchProps } from 'web/src/components/Select';
import isSingleSelectProperties from 'web/src/components/Select/guards/isSingleSelectProperties';

import type {
  FormWrapperWidgetProperties,
} from '../types';
import getWrapperHintProperties from '../utils/getWrapperHintProperties';

interface UseFormSelect {
  componentProps: ComputedRef<SingleSelectProperties | SelectWithSearchProps>;
}

export default function useFormSelect(
  props: FormWrapperWidgetProperties<SingleSelectProperties | SelectWithSearchProps>,
): UseFormSelect {
  const componentProps = computed<SingleSelectProperties>(() => {
    const {
      name,
      title,
      extraProperties,
      value,
      error,
      hintMessage,
      disabled,
      options,
      autofocus,
    } = props;

    const values = extraProperties?.enum ?? [];
    assert(isOptionalString(value), `Unexpected Select value type: ${typeof value}`);
    assert(isSingleSelectProperties(options), 'Unexpected options for SingleSelect');
    const selectedValue = value || '';
    const isSingleValuePreselected = selectedValue && values.length === 1 && values[0] === selectedValue;

    const uiLabels = extraProperties?.labels;
    const selectOptions = values.map((currentValue) => ({
      label: uiLabels ? uiLabels[currentValue] : currentValue,
      value: currentValue,
    }));

    return {
      options: selectOptions,
      selectedValue,
      placeholder: title,
      disabled: isSingleValuePreselected || disabled,
      ...options,
      ...getWrapperHintProperties({ options, error, hintMessage }),
      name,
      autofocus,
    };
  });
  return {
    componentProps,
  };
}
