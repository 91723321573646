import type { ComputedRef, Component } from 'vue';
import { computed } from 'vue';

import { TransactionGroup } from '@leon-hub/api-sdk';

import CustomerHistoryListItemBet from 'web/src/modules/profile/submodules/customer-history/components/CustomerHistoryListItemBet/CustomerHistoryListItemBet.vue';
import CustomerHistoryListItemPayment
  // eslint-disable-next-line max-len
  from 'web/src/modules/profile/submodules/customer-history/components/CustomerHistoryListItemPayment/CustomerHistoryListItemPayment.vue';
import CustomerHistoryListItemEgs from 'web/src/modules/profile/submodules/customer-history/components/CustomerHistoryListItemEgs/CustomerHistoryListItemEgs.vue';
import type { HistoryListItem } from 'web/src/modules/profile/submodules/customer-history/types';

import type {
  CustomerHistoryListItemEmits,
  CustomerHistoryListItemProps,
} from '../types';

export interface CustomerHistoryListItemComposable {
  component: ComputedRef<Component>;
  emitItemClick: (value: HistoryListItem) => void;
  emitCashOut: (value: HistoryListItem) => void;
  emitCancelWithdrawal: (value: HistoryListItem) => void;
}

export default function useCustomerHistoryListItem(
  props: CustomerHistoryListItemProps,
  emits: CustomerHistoryListItemEmits,
): CustomerHistoryListItemComposable {
  const component = computed<Component>(() => {
    switch (props.item.objectType) {
      case TransactionGroup.BET:
        return CustomerHistoryListItemBet;
      case TransactionGroup.PAYMENT:
        return CustomerHistoryListItemPayment;
      default:
        return CustomerHistoryListItemEgs;
    }
  });

  function emitItemClick(value: HistoryListItem): void {
    emits('item-click', value);
  }

  function emitCashOut(value: HistoryListItem): void {
    emits('cash-out', value);
  }

  function emitCancelWithdrawal(value: HistoryListItem): void {
    emits('cancel-withdrawal', value);
  }

  return {
    component,
    emitItemClick,
    emitCashOut,
    emitCancelWithdrawal,
  };
}
