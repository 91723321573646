import { IconName } from '@leon-hub/icons';

import type { I18nFunctionReactive } from 'web/src/modules/i18n/types';
import { ButtonActionType } from 'web/src/modules/profile/submodules/bonuses/enums';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import type { VButtonPropsWithId } from 'web/src/modules/profile/submodules/bonuses/types';

export default function getCollectButton(
  $t: I18nFunctionReactive,
): VButtonPropsWithId {
  return {
    id: ButtonActionType.COLLECT,
    label: $t('WEB2_BONUSES_COLLECT_BUTTON').value,
    kind: ButtonKind.PRIMARY,
    iconName: IconName.CHECK_FILLED,
    iconRight: true,
  };
}
