import { LimitName } from 'web/src/modules/profile/submodules/responsible-gambling/enums';
import type { I18nFunctionReactive } from 'web/src/modules/i18n/types';

export function getSwitcherOptions($translate: I18nFunctionReactive): {
  id: LimitName;
  label: string;
}[] {
  return [
    {
      id: LimitName.DAILY,
      label: $translate('WEB2_RG_SWITCHER_DAILY').value,
    },
    {
      id: LimitName.WEEKLY,
      label: $translate('WEB2_RG_SWITCHER_WEEKLY').value,
    },
    {
      id: LimitName.MONTHLY,
      label: $translate('WEB2_RG_SWITCHER_MONTHLY').value,
    },
  ];
}
