import type { Ref } from 'vue';
import { computed } from 'vue';

import {
  useCustomerFavoritesService,
  useSportlineFavoritesStoreComposable,
} from 'web/src/modules/sportline/submodules/favorites/composables';
import type {
  GetSportEventsResponse,
} from 'web/src/modules/sportline/types/rest';
import type {
  SportElement,
  SportEvent,
} from 'web/src/modules/sportline/types';
import type {
  SportlineType,
} from 'web/src/modules/sportline/enums';
import type {
  CustomerFavoritesIdsBackgroundUpdateKey,
} from 'web/src/modules/sportline/submodules/favorites/enums';
import {
  filterSportsListEvents,
} from 'web/src/modules/sportline/utils';
import { SportEventsResponseChangeUtils } from 'web/src/modules/sportline/utils/rest';

interface SegmentStoreFavoritesProps {
  backgroundFavoritesId: CustomerFavoritesIdsBackgroundUpdateKey;
  filterEventsListBySegmentId?: string;
  filterEventsListBySportlineType?: SportlineType;
  eventsResponseForOptimisticCheck: Ref<Maybe<GetSportEventsResponse>>;
}

interface SegmentStoreFavoritesComposable {
  hasFavoritesOptimistic: Ref<boolean>;
  favoriteSelectedRegionFamily: Ref<Maybe<string>>;
  favoriteEventsFullList: Ref<SportElement<SportEvent>[]>;
  favoriteEventsListForDisplay: Ref<SportElement<SportEvent>[]>;
  customerOptimisticFavoriteSportEventsIds: Ref<ReadonlyArray<string>>;
  customerOptimisticFavoriteLeaguesIds: Ref<ReadonlyArray<string>>;
  onInit(): void;
  initialRequests(): Promise<void>;
  setFavoriteSelectedRegionFamily(regionFamily: Maybe<string>): void;
  setBackgroundUpdateEnabled(value: boolean): Promise<void>;
}

export default function useSegmentStoreFavoritesComposable(
  props: SegmentStoreFavoritesProps,
): SegmentStoreFavoritesComposable {
  const {
    backgroundFavoritesId,
    filterEventsListBySegmentId,
    filterEventsListBySportlineType,
  } = props;
  const {
    customerOptimisticFavoriteSportEventsIds,
    customerOptimisticFavoriteLeaguesIds,
  } = useCustomerFavoritesService();

  const {
    favoriteEventsFullList,
    favoriteSelectedRegionFamily,
    favoriteEventsListForDisplay,
    onInit,
    initialRequests,
    setBackgroundUpdateEnabled,
    setFavoriteSelectedRegionFamily,
  } = useSportlineFavoritesStoreComposable({
    backgroundFavoritesId,
    prepareEventsList(fullList: SportElement<SportEvent>[]): SportElement<SportEvent>[] {
      if (filterEventsListBySegmentId !== undefined) {
        return fullList.filter((element) => element.sport.segment.id === filterEventsListBySegmentId);
      }

      if (filterEventsListBySportlineType !== undefined) {
        return filterSportsListEvents(
          fullList, (item) => item.sportEvent.type === filterEventsListBySportlineType,
        );
      }

      return fullList;
    },
  });

  const hasFavoritesOptimistic = computed<boolean>(() => {
    const eventsIds = customerOptimisticFavoriteSportEventsIds.value;
    const leaguesIds = customerOptimisticFavoriteLeaguesIds.value;

    return SportEventsResponseChangeUtils
      .isSomeIdsInList(props.eventsResponseForOptimisticCheck.value, { eventsIds, leaguesIds });
  });

  return {
    hasFavoritesOptimistic,
    favoriteSelectedRegionFamily,
    favoriteEventsFullList,
    favoriteEventsListForDisplay,
    customerOptimisticFavoriteSportEventsIds,
    customerOptimisticFavoriteLeaguesIds,
    onInit,
    initialRequests,
    setFavoriteSelectedRegionFamily,
    setBackgroundUpdateEnabled,
  };
}
