import throttle from 'lodash/throttle';
import type { Directive, Plugin } from 'vue';

import { logger } from '@leon-hub/logging';
import {
  isFunction,
  assert,
  isNullOrUndefined,
} from '@leon-hub/guards';
import {
  Json,
  requestIdleCallback,
} from '@leon-hub/utils';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';

import { useAnalytics } from 'web/src/modules/analytics/composables';

enum CollectMethod {
  ClickCounter = 'click-counter',
}

enum CollectMethodGroup {
  ClickCounter = 'clickCounter',
}

type DirectiveAnalyticsEvent = { name: AnalyticsEvent; payload: Record<string, unknown> };

const directiveName = 'collectClickDirective';
const empiricalThrottleTimeMs = 150;
const listeners = new Map<string, Function>();
const datasetCollectKey = 'collectKey';

const isEventHandler = (value: unknown): value is EventListenerOrEventListenerObject => isFunction(value);

const mapToAnalyticsEvent = (method: CollectMethod, payload: Record<string, unknown>): DirectiveAnalyticsEvent => {
  let groupName: string | null = null;
  let eventName: AnalyticsEvent | null = null;

  if (method === CollectMethod.ClickCounter) {
    groupName = CollectMethodGroup.ClickCounter;
    eventName = AnalyticsEvent.CLICK_MAP;
  }

  assert(groupName && eventName, 'Wrong collect method.');

  return {
    name: eventName,
    payload: {
      [groupName]: {
        ...payload,
      },
    },
  };
};

const attachListener = (element: HTMLElement, handler: (this: HTMLElement, event: Event) => void) => {
  const listenerKey = element.dataset[datasetCollectKey];
  assert(typeof listenerKey !== 'undefined', `[${directiveName}] dataset listenerKey should be exist.`);
  listeners.set(listenerKey, handler);
  element.addEventListener('click', handler);
};

const detachListener = (element: HTMLElement) => {
  const key = element.dataset[datasetCollectKey];
  assert(typeof key !== 'undefined', `[${directiveName}:detachListener] dataset key should be present.`);
  const handler = listeners.get(key);
  assert(
    isEventHandler(handler),
    `[${directiveName}:detachListener] provided handler should be of type EventHandler.`,
  );
  element.removeEventListener('click', handler);
};

function getDirective(): Directive {
  return {
    beforeMount(element, binding): void {
      if (isNullOrUndefined(element)) {
        return;
      }
      const elementToListen = element;
      assert(
        // eslint-disable-next-line unicorn/prefer-includes
        Object.values(CollectMethod).some((item) => item === binding.arg),
        `[${directiveName}] an argument should be one of CollectMethod.`,
      );

      const eventData = mapToAnalyticsEvent(CollectMethod.ClickCounter, binding.value);

      const eventDataKey = Json.stringify(eventData, {
        defaultValue: null,
      });
      assert(!isNullOrUndefined(eventDataKey), 'eventDataKey should have valid value.');
      elementToListen.dataset[datasetCollectKey] = eventDataKey;

      const handler = () => (
        requestIdleCallback.bind(
          null,
          useAnalytics().push.bind(null, eventData.name, eventData.payload),
        )
      )();

      attachListener(
        elementToListen,
        binding?.modifiers.throttle ? throttle(handler, empiricalThrottleTimeMs) : handler,
      );
    },
    beforeUnmount(element) {
      if (element) {
        detachListener(element);
      } else {
        logger.warn(`[${directiveName}:unbind] HTMLElement should be exist.`);
      }
    },
  };
}

const CollectClickPlugin: Plugin = {
  install(app) {
    app.directive('collect', getDirective());
  },
};

export default CollectClickPlugin;
