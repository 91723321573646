<script lang="ts" setup>
import { useCssModule } from 'vue';

import { IconName, IconSize } from '@leon-hub/icons';

import { VIcon } from '@components/v-icon';

import { useIsSportlineType } from 'web/src/modules/sportline/composables/core';
import createDisplayValueChangeDirective from 'web/src/utils/directives/createDisplayValueChangeDirective';

import type { RunnerProps } from './types';
import { runnerComponentPropsToRefs, useRunner } from './composables';
import { runnerDataTestElement } from './constants';

const props = defineProps<RunnerProps>();

const cssModule = useCssModule();

const vDirection = {
  updated: createDisplayValueChangeDirective({
    moveUp: cssModule['sportline-group-market-runner--bet-up'],
    moveDown: cssModule['sportline-group-market-runner--bet-down'],
    autoDirection: true,
  }),
};

const runnerProps = runnerComponentPropsToRefs(props);
const {
  value,
  price,
  name,
  isEmpty,
  isLocked,
  isSelected,
  addToSlip,
} = useRunner(runnerProps);
const { isLive, isPrematch } = useIsSportlineType(runnerProps.sportlineType);
</script>

<template>
  <button v-auto-id="'InGroupRunner'"
    v-data-test="{
      el: runnerDataTestElement.runner,
      locked: isLocked,
      selected: isSelected,
      live: isLive,
      prematch: isPrematch,
    }"
    v-direction="{ value: price }"
    type="button"
    :class="{
      [$style['sportline-group-market-runner']]: true,
      [$style['sportline-group-market-runner--selected']]: isSelected,
      [$style['sportline-group-market-runner--bet-up']]: isPermanentBetUp,
      [$style['sportline-group-market-runner--bet-down']]: isPermanentBetDown,
    }"
    :disabled="isLocked && !isSelected"
    @click="addToSlip({ event: $event })"
  >
    <span
      v-data-test="{ el: runnerDataTestElement.name }"
      :class="[
        $style['sportline-group-market-runner__coefficient'],
        $style['sportline-group-market-runner__coefficient--left'],
      ]"
    >{{ name }}</span>

    <span
      v-data-test="{ el: runnerDataTestElement.price }"
      :class="[
        $style['sportline-group-market-runner__coefficient'],
        $style['sportline-group-market-runner__coefficient--right'],
      ]"
    >
      {{ value }}
    </span>

    <span
      v-if="isLocked && !isEmpty"
      v-data-test="{ el: runnerDataTestElement.mask }"
      :class="$style['sportline-group-market-runner__locked-mask']"
    >
      <VIcon
        v-data-test="{ el: runnerDataTestElement.lockIcon }"
        :name="IconName.LOCK"
        :size="IconSize.SIZE_12"
        :class="$style['sportline-group-market-runner__locked-icon']"
      />
    </span>
  </button>
</template>

<style lang="scss" module>
@import '~web/src/modules/sportline/views/runner/mixins';

@include for-layout using ($isDesktop) {
  .sportline-group-market-runner {
    $runner: '.sportline-group-market-runner';

    @include runnerDefaultColors;
    @include runnerBase;
    @include runnerOnClickScale;

    gap: 8px;
    justify-content: space-between;
    width: 100%;
    height: auto;
    padding: 14px 12px;
    overflow: hidden;
    line-height: 16px;
    border: 0;

    @include for-hover {
      &:hover:not([disabled]) {
        background-color: $sportlineRunnerBackgroundColorActive;

        #{$runner}__coefficient {
          &--left,
          &--right {
            color: $sportlineRunnerColorActive;
          }
        }
      }
    }

    &--selected {
      background-color: $sportlineRunnerBackgroundColorActive;

      #{$runner}__coefficient {
        &--left,
        &--right {
          color: $sportlineRunnerColorActive;
        }
      }
    }

    &--bet-up {
      @include runnerBetUp;
    }

    &--bet-down {
      @include runnerBetDown;
    }

    &__coefficient {
      display: flex;
      align-items: center;

      &--left {
        @include regular\14\16\025;

        display: block;
        flex: 0 1 auto;
        justify-content: flex-start;
        overflow: hidden;
        color: var(--TextPrimary);
        text-align: left;
        word-break: break-word;
      }

      &--right {
        @include medium\14\16\025;

        flex: none;
        justify-content: flex-end;
        overflow: hidden;
        color: var(--TextDefault);
        letter-spacing: 0;
      }
    }

    &__locked-icon {
      color: var(--TextDefault);
      fill: var(--TextDefault);
    }

    &__locked-mask {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(var(--Layer2Base), 0.8);
    }
  }
}
</style>
