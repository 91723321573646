<script lang="ts">
import { defineComponent, h, useCssModule } from 'vue';

export default defineComponent({
  name: 'InputBorderWrapper',
  props: {
    isFocus: {
      type: Boolean,
    },
    isDisabled: {
      type: Boolean,
    },
    isHover: {
      type: Boolean,
    },
    hasError: {
      type: Boolean,
    },
    isFromModal: {
      type: Boolean,
    },
  },
  setup(props, { slots }) {
    const $style = useCssModule();
    const rootClass = 'input-border-wrapper';

    return () => {
      const cssClasses: string[] = [];

      if ($style) {
        cssClasses.push($style[rootClass]);

        if (props.isFocus) {
          cssClasses.push($style[`${rootClass}--focus`]);
        }

        if (props.isDisabled) {
          cssClasses.push($style[`${rootClass}--disabled`]);
        }

        if (props.isHover) {
          cssClasses.push($style[`${rootClass}--hover`]);
        }

        if (props.hasError) {
          cssClasses.push($style[`${rootClass}--error`]);
        }

        if (props.isFromModal) {
          cssClasses.push($style[`${rootClass}--modal`]);
        }
      }

      return h(
        'div',
        {
          class: cssClasses,
        },
        slots.default?.(),
      );
    };
  },
});
</script>

<style lang="scss" module>
.input-border-wrapper {
  @import '../../common';

  $self: &;

  @include inputBorderDefault;

  position: relative;
  background-color: $inputBgColorDefault;
  border-radius: $inputBorderRadius;
  transition: box-shadow 150ms $animationEaseInOut;

  &--disabled {
    background-color: $inputBgColorDisabled;
  }

  &--hover {
    @include for-hover {
      @include inputBorderHover;

      background-color: $inputBgColorHover;
    }
  }

  &--modal {
    background-color: $inputFromModalBgColorDefault;
  }

  &--modal#{$self}--disabled {
    background-color: $inputFromModalBgColorDisabled;
  }

  &--modal#{$self}--hover {
    @include for-hover {
      background-color: $inputFromModalBgColorHover;
    }
  }

  &--error {
    @include inputBorderError;

    background-color: $inputBgColorError;
  }

  &--focus {
    @include inputBorderFocus;
  }

  &--modal#{$self}--focus {
    background-color: $inputFromModalBgColorFocus;
  }
}
</style>
