<script lang="ts" setup>
import CasinoLoyaltyProgramCard
  from 'web/src/modules/casino-loyalty-program/components/CasinoLoyaltyProgramCard/CasinoLoyaltyProgramCard.vue';
import VSwiperSlide from 'web/src/components/Swiper/VSwiperSlide/VSwiperSlide.vue';
import CasinoLoyaltyProgramSwiperNavigation
  // eslint-disable-next-line max-len
  from 'web/src/modules/casino-loyalty-program/components/CasinoLoyaltyProgramSwiper/CasinoLoyaltyProgramSwiperNavigation.vue';
import VSwiper from 'web/src/components/Swiper/VSwiper/VSwiper';

import type { CasinoLoyaltyProgramSwiperProps, CasinoLoyaltyProgramSwiperEmits } from './types';
import { useCasinoLoyaltyProgramSwiper } from './composables';

const props = defineProps<CasinoLoyaltyProgramSwiperProps>();

const emits = defineEmits<CasinoLoyaltyProgramSwiperEmits>();

const {
  swiper,
  emitClickCard,
} = useCasinoLoyaltyProgramSwiper(props, emits);
</script>

<template>
  <div v-auto-id="'CasinoLoyaltyProgramSwiper'"
    :class="{
      [$style['casino-loyalty-program-swiper']]: true,
      [$style['casino-loyalty-program-swiper__with-horizontal-padding']]: !noPadding,
      [$style['casino-loyalty-program-swiper__without-horizontal-padding']]: noPadding,
    }"
  >
    <VSwiper
      ref="swiper"
      :class="$style['casino-loyalty-program-swiper__list']"
    >
      <template #pagination-header>
        <div :class="$style['casino-loyalty-program-swiper__header']">
          <div
            :class="{
              [$style['casino-loyalty-program-swiper__header-title']]: true,
              [$style['casino-loyalty-program-swiper__header-title__with-padding']]: !noHeaderTitlePadding,
              [$style['casino-loyalty-program-swiper__header-title__without-padding']]: noHeaderTitlePadding,
            }"
          >
            {{ $t('WEB2_LOYALTY_PROGRAM_LEVELS_TITLE') }}
          </div>
          <CasinoLoyaltyProgramSwiperNavigation v-if="hasNavigation" />
        </div>
      </template>
      <VSwiperSlide
        v-for="(item, index) in cards"
        :key="index"
        :class="$style['casino-loyalty-program-swiper__item']"
      >
        <CasinoLoyaltyProgramCard
          :title="item.name"
          :image-src="item.imageUrl"
          :level-status="item.status"
          :current-value="currentPoints"
          :total="item.requiredPoints"
          :progress="item.progress"
          :card-size="cardSize"
          :image="item.image"
          @click="emitClickCard(item)"
        />
      </VSwiperSlide>
    </VSwiper>
  </div>
</template>

<style lang="scss" module>
.casino-loyalty-program-swiper {
  padding-top: 0;
  padding-bottom: 16px;

  &__with-horizontal-padding {
    padding-right: 8px;
    padding-left: 8px;
  }

  &__without-horizontal-padding {
    padding-right: 0;
    padding-left: 0;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 56px;

    &-title {
      @include medium\18\20;

      color: var(--TextDefault);

      &__with-padding {
        padding-left: 8px;
      }

      &__without-padding {
        padding: 0;
      }
    }
  }

  &__item {
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
