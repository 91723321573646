import type { Ref, MaybeRef } from 'vue';
import { computed, reactive, toRef } from 'vue';

import type { Optional } from '@leon-hub/types';

import type {
  ExtendedEventsId,
  ExtendedEventsIds,
  ChangeExtendedState,
  GetEventInfoRef,
  GetEventInfo,
  GetEventIdsListRef,
} from '../types';
import { ExtendedEventBlock } from '../../enums';

interface UseExtendedEventIdsStoreProps {
  maxInType?: MaybeRef<number>;
}

interface UseExtendedEventIdsStoreComposable {
  fullEventsIds: Ref<ExtendedEventsIds>;
  addEventId: ChangeExtendedState;
  removeEventId: ChangeExtendedState;
  createIsEventInListRef: GetEventInfoRef<boolean>;
  createListRef: GetEventIdsListRef;
  getIsEventInList: GetEventInfo<boolean>;
}

type EventIdsByTypeMap = Partial<Record<ExtendedEventBlock, ExtendedEventsIds>>;

function getUniqElements(ids: ExtendedEventsIds): ExtendedEventsIds {
  return Array.from((new Set([...ids])));
}

export function useExtendedEventIdsStoreComposable(props?: UseExtendedEventIdsStoreProps): UseExtendedEventIdsStoreComposable {
  const maxInType = toRef(props?.maxInType);
  const eventsIds = reactive<EventIdsByTypeMap>({});

  const fullEventsIds = computed(() => getUniqElements(Object.values(eventsIds).flatMap((ids) => ids)));

  function addEventId(id: ExtendedEventsId, type = ExtendedEventBlock.Default): void {
    const currentList = eventsIds[type] ?? [];

    let newList = getUniqElements([...currentList, id]);
    if (maxInType.value && newList.length > maxInType.value) {
      const diff = newList.length - maxInType.value;
      newList = newList.slice(diff);
    }

    eventsIds[type] = newList;
  }

  function removeEventId(id: ExtendedEventsId, type = ExtendedEventBlock.Default): void {
    const currentList = eventsIds[type] ?? [];
    eventsIds[type] = currentList.filter((value) => (value !== id));
  }

  function createListRef(type: Ref<Optional<ExtendedEventBlock>>): Ref<ExtendedEventsId[]> {
    return computed(() => (type.value ? getUniqElements((eventsIds[type.value] ?? [])) : []));
  }

  function getIsEventInList(id: ExtendedEventsId, type: ExtendedEventBlock): boolean {
    return (eventsIds[type ?? ExtendedEventBlock.Default] ?? []).includes(id);
  }

  function createIsEventInListRef(id: Ref<ExtendedEventsId>, type: Ref<Optional<ExtendedEventBlock>>): Ref<boolean> {
    return computed(() => (eventsIds[type.value ?? ExtendedEventBlock.Default] ?? []).includes(id.value));
  }

  return {
    fullEventsIds,
    addEventId,
    removeEventId,
    createListRef,
    createIsEventInListRef,
    getIsEventInList,
  };
}
