import type { BatchMakeBetEntry, PriceChangePolicy } from '@leon-hub/api-sdk';

import type { BatchedSlipInfoDocument, CombinedSlipEntryInfo } from '../../slip-info/types';
import {
  getAllBatchedEntries,
  getSinglesAvailableForBetting,
  getSlipEntryFromCombinedInfo,
} from '../../slip-info/utils';
import { getMakeBetEntryItems } from './getMakeBetEntryItems';

export interface Payload {
  stakeValue: number;
  isMultiSinglesMode: boolean;
  combinedSlipEntriesForSingles: CombinedSlipEntryInfo[];
  batchedSlipInfo: BatchedSlipInfoDocument;
  priceChangePolicy: PriceChangePolicy | null;
  autoAcceptTotalHandicapChanges: boolean;
  sameStakeForSingles?: boolean;
  freeBetId?: number | null;
}

export default function getBatchMakeBetEntries({
  combinedSlipEntriesForSingles,
  stakeValue,
  batchedSlipInfo,
  priceChangePolicy,
  autoAcceptTotalHandicapChanges,
  isMultiSinglesMode,
  sameStakeForSingles,
  freeBetId,
}: Payload): BatchMakeBetEntry[] {
  if (isMultiSinglesMode) {
    const availableSlipEntries = getSinglesAvailableForBetting({
      input: combinedSlipEntriesForSingles,
      priceChangePolicy,
      autoAcceptTotalHandicapChanges,
      sameStake: sameStakeForSingles ?? false,
    });
    return availableSlipEntries.map((entry) => {
      const stake = sameStakeForSingles ? stakeValue : (Number(entry.metaInfo?.stakeValue || 0));
      return {
        stake,
        slipEntries: getMakeBetEntryItems([getSlipEntryFromCombinedInfo(entry)]),
      };
    });
  }
  return [{
    stake: stakeValue,
    slipEntries: getMakeBetEntryItems(getAllBatchedEntries(batchedSlipInfo.slipEntries)),
    freeBetId,
  }];
}
