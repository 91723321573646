import { isError, isUndefined } from '@leon-hub/guards';
import { CaptchaType } from '@leon-hub/api-sdk';
import { Json } from '@leon-hub/utils';
import { verifyAndroidRecaptcha as verifyAndroidRecaptchaCordova } from '@leon-hub/cordova';
import { normalizeError } from '@leon-hub/errors';
import { BusEvent, useEventsBus, useBusSafeSubscribe } from '@leon-hub/event-bus';

import { DialogAction, PresetName } from 'web/src/modules/dialogs/enums';
import type { DialogActionPayload } from 'web/src/modules/dialogs/types';
import CaptchaServiceError from 'web/src/modules/captcha/services/errors/CaptchaServiceError';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useDialogs } from 'web/src/modules/dialogs/composables';
import type { CaptchaToken, ReCaptchaResponse } from 'web/src/modules/captcha/store/types';
import { isCaptchaToken, isReCaptchaResponse } from 'web/src/modules/captcha/store/utils';
import { captchaChallengeWasShownKey } from 'web/src/modules/captcha/store/constants';
import { useCaptchaStore } from 'web/src/modules/captcha/store';
import { useErrorsConverter } from 'web/src/modules/errors/composables';

import type { CaptchaVerificationEmits } from '../../types/CaptchaVerificationEmits';
import type {
  VCaptchaVerificationWatcherProps,
} from '../types';

export default function useVCaptchaVerificationWatcher(
  props: VCaptchaVerificationWatcherProps,
  emit: CaptchaVerificationEmits,
): void {
  const bus = useEventsBus();
  const { getSiteKeyByCaptchaType } = useCaptchaStore();
  const { $translate } = useI18n();
  const { showDialog } = useDialogs();

  useBusSafeSubscribe(BusEvent.CAPTCHA_VERIFICATION, ({ captchaType }: { captchaType: CaptchaType }) => {
    if (process.env.VUE_APP_PLATFORM_CORDOVA && captchaType === CaptchaType.ANDROID_RECAPTCHA) {
      verifyAndroidRecaptcha();
    } else {
      verifyCaptchaCallback(captchaType);
    }
  });

  function verifyCaptchaCallback(captchaType: CaptchaType): void {
    const { subscribe } = showDialog({
      presetName: PresetName.CAPTCHA,
      options: {
        title: $translate('WEB2_CAPTCHA_CHECK_MODAL_CAPTION').value,
        fullHeight: false,
        captchaRequesterStrategy: props.captchaRequesterStrategy,
        captchaType,
        dataTestId: 'verify-captcha-callback',
      },
    });

    subscribe({
      [DialogAction.MODAL_CLOSE]: (payload: DialogActionPayload<ReCaptchaResponse>) => {
        if (isReCaptchaResponse(payload.value)) {
          if (isUndefined(payload.value)) {
            bus.emit(BusEvent.CAPTCHA_CHALLENGE_IS_CLOSED, {});
          } else if (isError(payload.value.captchaResponse)) {
            bus.emit(BusEvent.CAPTCHA_SERVICE_ERROR, { error: payload.value.captchaResponse });
          } else {
            emitVerify(payload.value.captchaResponse);
          }
        } else {
          bus.emit(BusEvent.CAPTCHA_SERVICE_ERROR, {
            error: useErrorsConverter().convertToBaseError(new CaptchaServiceError({
              log: `[TheCaptchaVerificationWatcher]: unknown response=${Json.stringify(payload.value)}`,
              payload: {
                captchaType,
              },
            })),
          });
        }
      },
    });
  }

  function verifyAndroidRecaptcha() {
    if (!process.env.VUE_APP_PLATFORM_CORDOVA) {
      return;
    }

    window.sessionStorage.setItem(captchaChallengeWasShownKey, String(true));
    verifyAndroidRecaptchaCordova(
      getSiteKeyByCaptchaType(CaptchaType.ANDROID_RECAPTCHA).value,
      (captchaToken) => {
        if (isCaptchaToken(captchaToken)) {
          emitVerify(captchaToken);
        } else {
          bus.emit(BusEvent.CAPTCHA_SERVICE_ERROR, {
            error: useErrorsConverter().convertToBaseError(new CaptchaServiceError({
              log: '[TheCaptchaVerificationWatcher]: unknown response',
              payload: {
                captchaType: CaptchaType.ANDROID_RECAPTCHA,
                captchaToken,
              },
            })),
          });
        }
      },
      (error: unknown) => {
        bus.emit(BusEvent.CAPTCHA_SERVICE_ERROR, {
          error: useErrorsConverter().convertToBaseError(new CaptchaServiceError({
            originalError: normalizeError(error),
            payload: {
              captchaType: CaptchaType.ANDROID_RECAPTCHA,
            },
          })),
        });
      },
    );
  }

  function emitVerify(captchaToken: CaptchaToken): void {
    emit('verify', captchaToken);
  }
}
