<script lang="ts" setup>
import { onBeforeMount, provide } from 'vue';

import { Tag } from '@leon-hub/tags';

import type { IndicatorProperty } from 'web/src/components/_shared';

import { useVAccordion } from './composables';

interface VAccordionProps {
  tag?: Tag;
  panels?: boolean;
  indicators?: IndicatorProperty;
  independentItems?: boolean;
  controlled?: boolean;
}

const props = withDefaults(defineProps<VAccordionProps>(), {
  tag: Tag.SECTION,
  indicators: true,
});

const {
  isList,
  accordionSharedState,
  beforeComponentMount,
} = useVAccordion(props);

onBeforeMount(beforeComponentMount);

provide('accordionSharedState', accordionSharedState);
</script>

<template>
  <component v-auto-id="'VAccordion'"
    :is="tag"
    :class="{
      [$style['accordion']]: true,
      [$style['accordion--list']]: isList,
    }"
  >
    <slot />
  </component>
</template>

<style lang="scss" module>
.accordion {
  padding: 0;
  margin: 0;

  &--list {
    list-style: none;
  }
}
</style>
