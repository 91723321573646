import type { AppError } from '@leon-hub/app-errors';
import { RouteName } from '@leon-hub/routing-config-names';

import { useDialogs } from 'web/src/modules/dialogs/composables';
import { DialogAction, PresetName } from 'web/src/modules/dialogs/enums';
import type { AppVueRouter } from 'web/src/modules/core/services/router/types';

export interface LoadingErrorComposable {
  showLoadingError(value: AppError): void;
}

export default function useLoadingError(router: AppVueRouter): LoadingErrorComposable {
  const { showDialog } = useDialogs();
  function showLoadingError(value: AppError, closeCallback?: () => void): void {
    const callback = closeCallback ?? (() => {
      void router.push({ name: RouteName.HOME });
    });
    showDialog({
      presetName: PresetName.ALERT_ERROR,
      options: {
        confirmMessage: value.message,
        dataTestId: 'loading-error',
      },
    }).subscribe({
      [DialogAction.MODAL_CLOSE]: callback,
    });
  }

  return {
    showLoadingError,
  };
}
