import {
  getBetlineLeagueStatus,
  getLeagueStatisticsByLeagueId as getBetlineLeagueStatisticsByLeagueId,
} from '@leon-hub/api-sdk';
import { RequestGroupScore24 } from '@leon-hub/api';

import type {
  BetlineLeagueStatusResponse,
  BetlineGetLeagueStatisticResponse,
} from 'web/src/modules/sportline/types/rest';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';

export function getBetlineLeagueStatistics(
  options: { leagueId: number },
  { silent }: { silent?: boolean },
): Promise<BetlineGetLeagueStatisticResponse> {
  const apiClient = useGraphqlClient();
  return getBetlineLeagueStatisticsByLeagueId(apiClient,
    (node) => node.queries.betLine.getLeagueStatisticsByLeagueId,
    { options },
    {
      silent,
      group: RequestGroupScore24,
    });
}

export function getBetlineLeagueStatisticStatus(
  options: { leagueId: number },
  { silent }: { silent?: boolean },
): Promise<BetlineLeagueStatusResponse> {
  const apiClient = useGraphqlClient();
  return getBetlineLeagueStatus(apiClient,
    (node) => node.queries.betLine.getLeagueStatus,
    { options },
    {
      silent,
      group: RequestGroupScore24,
    });
}
