<script setup lang="ts">
import { toRef } from 'vue';

import { BusEvent, useEventsBus } from '@leon-hub/event-bus';

import { useSportlineNavigationStore } from 'web/src/modules/sportline/submodules/navigation/store';
import type { SportDateTimeFilter } from 'web/src/modules/sportline/submodules/navigation/store/types';
import type { Sport, TopLeagueListElement } from 'web/src/modules/sportline/types';
import type { SportsTreeLeagueElement } from 'web/src/modules/sportline/types/rest';
import type { CustomerLeagueListElement } from 'web/src/modules/sportline/submodules/favorites/types';
import VList from 'web/src/components/List/VList/VList.vue';
import { ListType } from 'web/src/components/List/enums';

import LeaguesListItem from './LeaguesListItem.vue';

type LeagueElement = TopLeagueListElement | SportsTreeLeagueElement | CustomerLeagueListElement;

interface LeaguesListProps {
  sport: Sport;
  leagues: LeagueElement[];
  isTop?: boolean;
  dateTimeFilter: SportDateTimeFilter;
}

const props = defineProps<LeaguesListProps>();

const currentFilter = toRef(props, 'dateTimeFilter');

const bus = useEventsBus();
const { setSportlineNavigationBetweenPagesFilter } = useSportlineNavigationStore();

function onSidebarLinkClick({ event, leagueId }: { event: MouseEvent; leagueId: string }): void {
  setSportlineNavigationBetweenPagesFilter(currentFilter.value);
  bus.emit(BusEvent.CATALOG_CLICK, { event, payload: { leagueId } });
}
</script>

<template>
  <div v-auto-id="'LeaguesList'"
    :class="{
      [$style['leagues-list']]: true,
      [$style['leagues-list--top']]: isTop,
    }"
  >
    <span
      v-if="isTop"
      :class="$style['leagues-list__title']"
    >{{ $t('WEB2_TOP_LEAGUES_CAPITALIZE') }}</span>

    <VList
      :class="{
        [$style['leagues-list__list']]: true,
        [$style['leagues-list__list--top']]: isTop,
      }"
      :list-type="ListType.GROUP"
    >
      <LeaguesListItem
        v-for="leagueElement in leagues"
        :key="`league-${leagueElement.league.id}`"
        :sport-id="sport.id"
        :league-element="leagueElement"
        :is-region-name-displayed="isTop"
        @click="onSidebarLinkClick"
      />
    </VList>
  </div>
</template>

<style module lang="scss">
.leagues-list {
  &--top {
    margin-bottom: 12px;

    @include for-phone-only {
      margin-bottom: 0;
    }
  }

  &__list {
    overflow: hidden;
    background-color: transparent;
    border-radius: 5px;

    &--top {
      overflow: hidden;
      border-radius: 5px;
    }
  }

  &__title {
    @include medium\18\20;

    display: flex;
    align-items: center;
    height: 56px;
    padding-left: 8px;
  }
}
</style>
