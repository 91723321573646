export function hexToRGB(hex: string, alpha: string): string {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }

  return `rgb(${r}, ${g}, ${b})`;
}

export function rgbaColor(color: Optional<Maybe<string>>, alpha = '0.15'): string {
  if (!color) {
    return '';
  }

  if (color.startsWith('rgb')) {
    if (color.startsWith('rgba')) {
      return color;
    }
    return `rgba${color.slice(3)}`.replace(/\)$/, `, ${alpha})`);
  }

  return hexToRGB(color, alpha);
}
