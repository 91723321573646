<script setup lang="ts">
import { inject, useSlots } from 'vue';

import { FormProvidable } from '@leon-hub/form-utils';
import type { FormProvidableContext } from '@leon-hub/form-utils';
import type { FocusableInputRef } from '@leon-hub/focus';
import { useFocusableElement } from '@leon-hub/focus';

import { VIcon } from '@components/v-icon';

import InputButton from '../InputButton/InputButton.vue';
import InputWrapper from '../InputWrapper';
import InputBorderWrapper from '../InputBorderWrapper';
import TextFlatInput from '../TextFlatInput';
import type { TextInputEmits, TextInputProps, TextInputSlots } from '../../types';
import useTextInput from './useTextInput';
import { TextInputTypes } from '../../enums';
import getInputIconSize from '../../utils/getInputIconSize';

const props = withDefaults(defineProps<TextInputProps>(), {
  name: 'text-input',
  value: '',
  type: TextInputTypes.TEXT,
  list: '',
});
const emits = defineEmits<TextInputEmits>();

defineSlots<TextInputSlots>();

defineOptions({
  inheritAttrs: false,
});

const slots = useSlots();

const customFormContext: FormProvidableContext = inject(FormProvidable.FormContext, { isFromModal: false });

const {
  uniqueId,
  hintProperties,
  hasError,
  isFocus,
  isEmpty,
  isHover,
  hasIconSuffix,
  inputType,
  inputMode,
  isMaskedPlaceholderHidden,
  showClearButton,
  inputValue,
  maskedValue,
  formattedValue,
  inputMaxLength,
  clickablePrefixIcon,
  clickableSuffixIcon,
  onMouseOver,
  onMouseLeave,
  onFocus,
  onBlur,
  onClear,
  emitIconPrefixClick,
  emitIconSuffixClick,
  onKeyDown,
  onPaste,
  onInput,
  onChange,
} = useTextInput(props, emits, slots);

const { focus, focusable } = useFocusableElement();

// ok not to be reactive
const iconSize = getInputIconSize(props.isLarge);

defineExpose<FocusableInputRef>({
  focus,
});
</script>

<template>
  <InputWrapper v-auto-id="'TextInput'"
    v-data-test="{
      el: 'text-input',
      name: name,
    }"
    v-bind="hintProperties"
    :input-id="uniqueId"
    :label="label"
    :disabled="disabled"
    :is-focus="isFocus"
    :is-empty="isEmpty"
    :is-hint-hidden="isHintHidden"
    :is-label-hidden="isLabelHidden"
    :is-large="isLarge"
    :clickable-prefix="clickablePrefixIcon"
    :clickable-suffix="clickableSuffixIcon"
  >
    <template #iconPrefix>
      <slot name="iconPrefix">
        <template v-if="!!prefixIconName">
          <InputButton
            v-if="clickableIcon"
            :is-large="isLarge"
            :icon-name="prefixIconName"
            @click="emitIconPrefixClick"
          />
          <VIcon
            v-else
            :size="iconSize"
            :name="prefixIconName"
          />
        </template>
      </slot>
    </template>
    <template #input>
      <InputBorderWrapper
        :is-focus="isFocus"
        :is-disabled="disabled"
        :is-hover="isHover"
        :has-error="hasError"
        :is-from-modal="customFormContext.isFromModal"
      >
        <TextFlatInput
          ref="focusable"
          :autofocus="autofocus"
          :name="name"
          :input-id="uniqueId"
          :type="inputType"
          :input-mode="inputMode"
          :value="formattedValue"
          :has-error="hasError"
          :disabled="disabled"
          :has-vanilla-mask="!!mask"
          :autocomplete="autocomplete"
          :placeholder="placeholder"
          :is-from-modal="customFormContext.isFromModal"
          :is-ym-disable-keys="isYmDisableKeys"
          :list="list"
          :max-length="inputMaxLength"
          :has-icon-prefix="!!prefixIconName || !!$slots.iconPrefix"
          :has-icon-suffix="hasIconSuffix || !!$slots.iconSuffix"
          :readonly="readonly"
          :is-large="isLarge"
          @mouseover="onMouseOver"
          @mouseleave="onMouseLeave"
          @focus="onFocus"
          @blur="onBlur"
          @keydown="onKeyDown"
          @change="onChange"
          @input="onInput"
          @paste="onPaste"
        />
      </InputBorderWrapper>
      <span
        v-if="!!mask"
        :class="{
          [$style['text-input__masked-placeholder']]: true,
          [$style['text-input__masked-placeholder--hidden']]: isMaskedPlaceholderHidden,
          [$style['text-input__masked-placeholder--icon-suffix']]: hasIconSuffix,
          [$style['text-input__masked-placeholder--icon-prefix']]: !!prefixIconName,
          [$style['text-input__masked-placeholder--large']]: isLarge,
        }"
      >
        {{ maskedValue }}
      </span>
    </template>
    <template #iconSuffix>
      <slot
        name="iconSuffix"
        :is-focus="isFocus"
        :is-empty="isEmpty"
      >
        <InputButton
          v-show="showClearButton"
          v-data-test="{ el: 'clear-icon-input-button', visible: !showClearButton }"
          :is-large="isLarge"
          :title="$t('WEB2_CLEAR')"
          @click="onClear"
        />
        <template v-if="!!suffixIconName && !showClearButton">
          <InputButton
            v-if="clickableIcon"
            :is-large="isLarge"
            :icon-name="suffixIconName"
            @click="emitIconSuffixClick"
          />
          <VIcon
            v-else
            :size="iconSize"
            :name="suffixIconName"
          />
        </template>
      </slot>
    </template>
    <template #hint>
      <slot
        name="hint"
        :is-focus="isFocus"
        :value="inputValue"
      />
    </template>
    <template #dropdown>
      <slot
        name="dropdown"
        :is-focus="isFocus"
        :value="inputValue"
      />
    </template>
  </InputWrapper>
</template>

<style lang="scss" module>
.text-input {
  @import '../../common';

  &__masked-placeholder {
    $placeholder: &;

    @include inputMask;

    &--hidden {
      @include z-index(hidden);

      opacity: 0;
    }

    &--large {
      @include inputMaskLarge;
    }

    &--icon-suffix {
      right: $inputIconWidth;
    }
    &--large#{$placeholder}--icon-suffix {
      right: $inputIconWidthLarge;
    }

    &--icon-prefix {
      left: $inputIconWidth;
    }
    &--large#{$placeholder}--icon-prefix {
      left: $inputIconWidthLarge;
    }
  }
}
</style>
