import type { BetInfoObject, BatchedSlipInfoDocument } from '../types';
import getAllBatchedEntries from './getAllBatchedEntries';
import { getSlipEntryId } from '../../../utils';
import { getSafeCompetitorsValue } from './getSafeCompetitorsValue';

interface Payload {
  oldBetsInfo: BetInfoObject;
  runnerReplaceEnabled: boolean;
  actualSlipInfo: BatchedSlipInfoDocument;
}

export function syncBetsInfoAfterSlipRestore({
  actualSlipInfo, oldBetsInfo, runnerReplaceEnabled,
}: Payload): BetInfoObject {
  const allEntries = getAllBatchedEntries(actualSlipInfo.slipEntries);
  const result: BetInfoObject = {};
  for (const entry of allEntries) {
    const id = getSlipEntryId(entry);
    const matchedBetsInfo = oldBetsInfo[id];
    if (matchedBetsInfo) {
      result[id] = matchedBetsInfo;
    } else if (!runnerReplaceEnabled) {
      throw new Error('Actual data is not matches to saved betsInfoObject');
    } else {
      if (!entry.eventName || !entry.marketName || !entry.runnerName) {
        throw new Error('Enable to set BetInfoItem from slipEntry because some fields are empty');
      }
      result[id] = {
        competitors: getSafeCompetitorsValue(entry.competitors),
        eventName: entry.eventName,
        runnerName: entry.runnerName,
        marketName: entry.marketName,
        isPrimaryMarket: entry.isPrimaryMarket ?? false,
        originalOdds: entry.odds,
        originalOddsStr: entry.oddsStr ?? undefined,
      };
    }
  }
  return result;
}
