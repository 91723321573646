import { toRef } from 'vue';
import { useRouter } from 'vue-router';

import { RouteName } from '@leon-hub/routing-config-names';

import { useRouterStore } from 'web/src/modules/core/store';
import usePaymentsStore from 'web/src/modules/payments/store/usePaymentsStore';

export interface DepositsNavigationComposable {
  setPreviousPageUrl(): void;
}

export default function useDepositsNavigation(): DepositsNavigationComposable {
  const router = useRouter();
  const paymentsStore = usePaymentsStore();
  const routerStore = useRouterStore();

  const previousRouteUrl = toRef(() => routerStore.previousRouteUrl);

  function setPreviousPageUrl(): void {
    if (process.env.VUE_APP_LAYOUT_PHONE
      && !router.getPrevHistoryUrl()?.includes(RouteName.DEPOSITS)
      && previousRouteUrl.value) {
      paymentsStore.setPreviousPageUrl(previousRouteUrl.value);
    }
  }

  return {
    setPreviousPageUrl,
  };
}
