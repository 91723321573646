export enum CurrenciesIconName {
  COIN_AZN = 'coin-azn',
  COIN_BRL = 'coin-brl',
  COIN_BSF = 'coin-bsf',
  COIN_BTC = 'coin-btc',
  COIN_BYN = 'coin-byn',
  COIN_CNY = 'coin-cny',
  COIN_CZK = 'coin-czk',
  COIN_DKK = 'coin-dkk',
  COIN_ETH = 'coin-eth',
  COIN_EUR = 'coin-eur',
  COIN_GBP = 'coin-gbp',
  COIN_INR = 'coin-inr',
  COIN_JPY = 'coin-jpy',
  COIN_L = 'coin-l',
  COIN_KGS = 'coin-kgs',
  COIN_KZT = 'coin-kzt',
  COIN_LTC = 'coin-ltc',
  COIN_MDL = 'coin-mdl',
  COIN_PEN = 'coin-pen',
  COIN_PLN = 'coin-pln',
  // COIN_RUBLE = 'coin-ruble',
  COIN_RUB = 'coin-rub',
  COIN_TJS = 'coin-tjs',
  COIN_TMT = 'coin-tmt',
  COIN_TRY = 'coin-try',
  COIN_TWD = 'coin-twd',
  COIN_UAH = 'coin-uah',
  COIN_USD = 'coin-usd',
  COIN_UZS = 'coin-uzs',
  COIN_AUD = 'coin-aud',
  COIN_NZD = 'coin-nzd',
  COIN_VND = 'coin-vnd',
  COIN_THB = 'coin-thb',
}

export default CurrenciesIconName;
