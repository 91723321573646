import { assert, isNumber, isObject } from '@leon-hub/guards';
import { localStorageManager } from '@leon-hub/local-storage';
import type { FieldGuard } from '@leon-hub/types';

import { KEY_VALUE, KEY_CHANGED, KEY_EXPIRES } from './constants';
import { log } from './utils';

function isParsedStorageItem<T>(item: unknown): item is StorageItem<T> {
  assert(isObject(item));
  assert(KEY_VALUE in item);
  assert(isNumber(item[KEY_CHANGED]));
  assert(!(KEY_EXPIRES in item) || isNumber(item[KEY_EXPIRES]));
  return true;
}

export interface StorageOptions<T> {
  id: string;
  guard: FieldGuard<T>;
}

interface StorageItem<T> { [KEY_VALUE]: T; [KEY_CHANGED]: number; [KEY_EXPIRES]?: number }

export class Storage<T> {
  private cache: T | undefined = undefined;

  constructor(private options: StorageOptions<T>) {
    try {
      const result = this.getItem();
      if (result.exists) {
        this.setCache(result.value);
      }
    } catch {}
  }

  private getItem(): { exists: false } | { exists: true; value: StorageItem<T>[typeof KEY_VALUE] } {
    if (this.cache) return { exists: true, value: this.cache };
    const { id } = this.options;
    const rawValue = localStorageManager.getItem(id);
    if (typeof rawValue === 'string') {
      try {
        const storageItem: unknown = JSON.parse(rawValue);
        if (isParsedStorageItem(storageItem)) {
          const { [KEY_VALUE]: value } = storageItem;
          if (this.options.guard(value)) {
            return { value, exists: true };
          }
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.warn(`Unable to parse "${id}" storage value: ${rawValue}`, err);
      }
    }
    return {
      exists: false,
    };
  }

  get(): Promise<{ exists: false } | { exists: true; value: StorageItem<T>[typeof KEY_VALUE] }> {
    return Promise.resolve(this.getItem());
  }

  set(value: T, options: { expires?: number } = {}): Promise<void> {
    const { expires } = options;
    const { id } = this.options;
    log(`writing "${id}" storage value: %o using options: %o`, value, options);
    assert(this.options.guard(value), `Invalid "${id}" storage value: ${value}`);
    localStorageManager.setItem(id, JSON.stringify({ [KEY_VALUE]: value, [KEY_CHANGED]: Date.now(), [KEY_EXPIRES]: expires } as StorageItem<T>));
    this.setCache(value);
    return Promise.resolve();
  }

  clear(): Promise<void> {
    this.cache = undefined;
    localStorageManager.removeItem(this.options.id);
    return Promise.resolve();
  }

  private setCache(value: T): void {
    this.cache = value;
  }
}
