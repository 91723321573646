<script lang="ts" setup>
import { computed, toRef } from 'vue';

import { IconName } from '@leon-hub/icons';

import { VIcon } from '@components/v-icon';
import { SportIcon } from '@components/sport-icon';

import VCountryFlag from 'web/src/components/CountryFlag/VCountryFlag/VCountryFlag.vue';
import { mapIconToFlagSize } from 'web/src/components/CountryFlag/VCountryFlag/utils';
import { getCyberSportIconName } from 'web/src/modules/sportline/utils/theme';

import type { FilledSportlineLogoProps } from './types';
import { useRegionLogoProps } from './composables';

const props = defineProps<FilledSportlineLogoProps>();

const size = toRef(props, 'size');
const flagSize = toRef(props, 'flagSize');

const finalFlagSize = computed(() => flagSize.value ?? mapIconToFlagSize(size.value));

const {
  countryCode,
  isCyberSport,
} = useRegionLogoProps(props);
</script>

<template>
  <img v-auto-id="'FilledSportlineLogo'"
    v-if="src"
    :alt="alt"
    :src="src"
    :class="$style['filled-sportline-logo-image']"
  >
  <VIcon
    v-else-if="isCyberSport && countryCode"
    :name="getCyberSportIconName({ icon: countryCode })"
    :class="$style['filled-sportline-logo-icon']"
  />
  <VCountryFlag
    v-else-if="countryCode"
    :class="{
      [$style['filled-sportline-logo-icon']]: true,
      [$style['filled-sportline-logo-flag']]: true,
      [$style['filled-sportline-logo-flag--with-border']]: !borderlessFlag,
    }"
    :size="finalFlagSize"
    :code="countryCode"
    :style="{ fill: fillColor }"
  />
  <SportIcon
    v-else-if="sportIconName"
    :class="$style['filled-sportline-logo-icon']"
    :size="size"
    :sport-name="sportIconName"
    :style="{ fill: fillColor }"
  />
  <VIcon
    v-else
    :class="$style['filled-sportline-logo-icon']"
    :name="IconName.BRAND_LOGO"
    :size="size"
    :style="{ fill: fillColor }"
  />
</template>

<style module lang="scss">
.filled-sportline-logo-image {
  max-width: 100%;
  max-height: 100%;
}

.filled-sportline-logo-flag {
  box-sizing: content-box;
  overflow: hidden;

  &--with-border {
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 5px;
  }
}

.filled-sportline-logo-icon {
  fill: var(--DLayer1);
}
</style>
