import type { Ref } from 'vue';
import {
  computed,
  onBeforeMount,
  onBeforeUnmount, toRef,
  watch,
} from 'vue';
import {
  useRoute,
  useRouter,
} from 'vue-router';

import { Timer } from '@leon-hub/utils';
import {
  assert,
  isString,
} from '@leon-hub/guards';
import { RouteName } from '@leon-hub/routing-config-names';
import { PromoActionType } from '@leon-hub/api-sdk';

import { usePromotionDetailsCore } from 'web/src/modules/promotions/submodules/details/composables';
import { getPromoDetailsUrlForGame } from 'web/src/modules/casino/utils';
import { useCasinoActiveGame } from 'web/src/modules/casino/submodules/game/composables';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import type { PromotionMergedDetailsDocument, PromotionScrollableElement } from 'web/src/modules/promotions/types';
import { useCashbackStore } from 'web/src/modules/promotions/store';
import type { LeaderBoardProps } from 'web/src/modules/promotions/components/LeaderBoard/types';

const updateInterval = 1000 * 60 * 2;

export interface CasinoGameLeaderBoardComposable {
  board: Ref<PromotionScrollableElement | undefined>;
  isBlockParticipationButton: Ref<boolean | undefined>;
  currentPromotion: Ref<PromotionMergedDetailsDocument>;
  promotionButton: Ref<PromotionMergedDetailsDocument['actionButton']>;
  isDoingActionButton: Ref<boolean>;
  haveLeaderBoard: Ref<boolean>;
  currentPromoLeaderboardDate: Ref<string>;
  leaderBoardProperties: Ref<LeaderBoardProps | undefined>;
  goToLoginPage(): void;
  goToDetails(): void;
  doButtonAction(): Promise<void>;
  scrollToLeaderBoard(): void;
  goToCustomLink(): void;
  goToPlay(): void;
  loadMore(id?: number): Promise<void>;
}

export default function useCasinoGameLeaderBoard(): CasinoGameLeaderBoardComposable {
  const { isLoggedIn } = useIsLoggedIn();
  const route = useRoute();
  const router = useRouter();
  const { activeGame } = useCasinoActiveGame();
  const isCashbackActive = toRef(useCashbackStore(), 'isCashbackActive');

  const {
    board,
    haveLeaderBoard,
    currentPromotion,
    currentPromoLeaderboardDate,
    actionUrl,
    getLeaderBoard,
    leaderBoard,
    loadPromotionDetails,
    setOpenedActiveUrl,
    isDoingActionButton,
    promotionButton,
    leaderBoardProperties,
    doButtonAction,
    scrollToLeaderBoard,
    goToCustomLink,
    loadMore,
    goToPlay,
  } = usePromotionDetailsCore();
  const isBlockParticipationButton = computed(() => currentPromotion.value?.isBlockParticipationButton);

  async function refreshLeaderBoard(pageSize: number): Promise<void> {
    if (actionUrl.value) {
      await getLeaderBoard({
        actionUrl: actionUrl.value, id: actionUrl.value, pageSize, isUpdateRequest: true,
      });
    }
  }

  let updateLeaderBoardTimerId = 0;

  function stopUpdateLeaderBoard(): void {
    if (updateLeaderBoardTimerId) {
      Timer.clearTimeout(updateLeaderBoardTimerId);
      updateLeaderBoardTimerId = 0;
    }
  }

  function startUpdateLeaderBoard(): void {
    stopUpdateLeaderBoard();

    updateLeaderBoardTimerId = Timer.setTimeout(() => {
      void refreshLeaderBoard(leaderBoard.value.length)
        .finally(startUpdateLeaderBoard);
    }, updateInterval);
  }

  async function initialDataRequest(): Promise<void> {
    stopUpdateLeaderBoard();
    let url;
    if (route.params.actionUrl) {
      url = route.params.actionUrl;
    } else if (activeGame.value) {
      url = await getPromoDetailsUrlForGame(activeGame.value);
    }
    actionUrl.value = String(url);

    if (url) {
      assert(isString(url));
      await Promise.all([
        loadPromotionDetails(url),
        getLeaderBoard({ actionUrl: url, id: url }),
      ]);
      startUpdateLeaderBoard();
    }

    if (!isCashbackActive.value) {
      setOpenedActiveUrl(url || '');
    }
  }

  function goToLoginPage(): void {
    void router.push({ name: RouteName.LOGIN });
  }

  function goToDetails(): void {
    if (currentPromotion.value && actionUrl.value) {
      void router.push({
        name: RouteName.PROMOTION_DETAILS,
        params: {
          categoryId: PromoActionType.CBC,
          actionUrl: actionUrl.value,
        },
      });
    }
  }

  onBeforeMount(initialDataRequest);
  onBeforeUnmount(stopUpdateLeaderBoard);
  watch(isLoggedIn, initialDataRequest);

  return {
    board,
    isBlockParticipationButton,
    currentPromotion,
    currentPromoLeaderboardDate,
    promotionButton,
    isDoingActionButton,
    haveLeaderBoard,
    leaderBoardProperties,
    goToLoginPage,
    goToDetails,
    doButtonAction,
    scrollToLeaderBoard,
    goToCustomLink,
    loadMore,
    goToPlay,
  };
}
