<script lang="ts" setup>
import { VIcon } from '@components/v-icon';

import VImage from 'web/src/components/Image/VImage/VImage.vue';
import VRouterLink from 'web/src/components/Link/VRouterLink/VRouterLink.vue';
import { useGetIcon } from 'web/src/modules/casino/submodules/lobby/composables';

import type { CasinoSidebarNavigationProps } from './types';

withDefaults(defineProps<CasinoSidebarNavigationProps>(), {});

const { getIcon } = useGetIcon();
</script>

<template>
  <div v-auto-id="'CasinoSidebarNavigationBlock'" :class="$style['casino-sidebar-navigation-block']">
    <template v-if="!isLoading">
      <VRouterLink
        v-for="item in list"
        :key="item.id"
        :class="{
          [$style['casino-sidebar-navigation-block__item']]: true,
          [$style['casino-sidebar-navigation-block__item--active']]: item.isActive,
        }"
        :to="item.location"
      >
        <div v-if="item.iconUrl">
          <VImage
            :class="$style['casino-sidebar-navigation-block__image']"
            :src="item.iconUrl"
          />
        </div>
        <VIcon
          v-else
          :name="getIcon(item.id)"
        />
        <span>{{ item.name }}</span>
      </VRouterLink>
    </template>
    <div
      v-for="item in list"
      v-else
      :key="item.id"
      :class="$style['casino-sidebar-navigation-block__loading-item']"
    >
      <div
        class="skeleton-loading"
        :class="$style['casino-sidebar-navigation-block__loading-icon']"
      />
      <div
        class="skeleton-loading"
        :class="$style['casino-sidebar-navigation-block__loading-text']"
      />
    </div>
  </div>
</template>

<style lang="scss" module>
.casino-sidebar-navigation-block {
  $self: &;

  display: flex;
  flex-direction: column;
  gap: $casinoSidebarNavigationGap;
  padding: $casinoSidebarNavigationPadding;
  background-color: $casinoSidebarNavigationBackgroundColor;
  border-radius: var(--BorderRadius);

  &__item {
    @include medium\14\16\025;

    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 44px;
    padding: 0 16px;
    color: var(--TextPrimary);
    text-decoration: none;
    cursor: pointer;
    border-radius: $casinoSidebarNavigationItemRadius;

    &:hover {
      @include casinoCategoryItemHover;

      cursor: pointer;

      #{$self}__image {
        @include casinoCategoryIconHover;
      }
    }

    &--active,
    &--active:hover {
      @include casinoCategoryItemActive;
    }
  }

  &__image {
    @include casinoCategoryIconFilter;

    #{$self}__item--active & {
      @include casinoCategoryIconFilterActive;
    }

    #{$self}__item--active:hover & {
      @include casinoCategoryIconFilterActive;
    }
  }

  &__loading {
    &-item {
      display: flex;
      gap: 16px;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      height: 44px;
      padding: 0 16px;
    }

    &-icon {
      width: 20px;
      height: 20px;
      background-color: var(--Layer2);
      border-radius: 50%;
    }

    &-text {
      width: 60px;
      height: 8px;
      background-color: var(--Layer2);
      border-radius: 20px;
    }
  }
}
</style>
