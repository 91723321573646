import type { Ref } from 'vue';
import { computed, toRef } from 'vue';

import { useCustomerFavoritesService } from 'web/src/modules/sportline/submodules/favorites/composables';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';

interface UseSportlineFavoritesTabListProps {
  isActive: Ref<boolean>;
  selectedRegionFamily: Ref<Maybe<string>>;
}

interface UseSportlineFavoritesTabListComposable {
  isLoaded: Ref<boolean>;
  isNeedRedirectFromTab: Ref<boolean>;
  isMarketTypesSelectionEnabled: Ref<boolean>;
  onTabActivated(): void;
  onTabDeactivated(): void;
}

export function useSportlineFavoritesTabList(
  props: UseSportlineFavoritesTabListProps,
): UseSportlineFavoritesTabListComposable {
  const { isActive, selectedRegionFamily } = props;

  const {
    isFavoritesLoaded: isLoaded,
    hasFavoritesOptimistic,
    setFavoriteEventsBackgroundUpdateEnabled,
  } = useCustomerFavoritesService();
  const sportlineSettingsStore = useSportlineSettingsStore();

  const isMarketTypesSelectionEnabled = toRef(sportlineSettingsStore, 'isMarketTypesSelectionEnabled');

  const isNeedRedirectFromTab = computed(() => (
    isActive.value
    && !hasFavoritesOptimistic.value
    && selectedRegionFamily.value === null
  ));

  function onTabActivated(): void {
    void setFavoriteEventsBackgroundUpdateEnabled(true);
  }

  function onTabDeactivated(): void {
    void setFavoriteEventsBackgroundUpdateEnabled(false);
  }

  return {
    isLoaded,
    isNeedRedirectFromTab,
    isMarketTypesSelectionEnabled,
    onTabActivated,
    onTabDeactivated,
  };
}
