import type {
  League,
} from 'web/src/modules/sportline/types';

export function isLeagueChanged(oldValue?: League | null, newValue?: League | null): boolean {
  if (!oldValue || !newValue) {
    return true;
  }

  return oldValue.id !== newValue.id
    || oldValue.key !== newValue.key
    || oldValue.name !== newValue.name
    || oldValue.top !== newValue.top
    || oldValue.topOrder !== newValue.topOrder
    || oldValue.weight !== newValue.weight;
}
