import { useStepRegistrationStore } from 'web/src/modules/registration/submodules/step-registration/store';
import { useSimplifiedRegistrationStore } from 'web/src/modules/registration/submodules/simplified-registration/store';
import { useTsupisRegistrationStore } from 'web/src/modules/registration/submodules/tsupis-registration/store/useTsupisRegistrationStore';

type RegistrationStore = ReturnType<typeof useStepRegistrationStore> | ReturnType<typeof useSimplifiedRegistrationStore> | ReturnType<typeof useTsupisRegistrationStore>;

export function useRegistrationStore(): RegistrationStore {
  if (process.env.VUE_APP_FEATURE_STEP_REGISTRATION_ENABLED) {
    return useStepRegistrationStore();
  }
  if (process.env.VUE_APP_FEATURE_SIMPLIFIED_REGISTRATION_ENABLED) {
    return useSimplifiedRegistrationStore();
  }

  return useTsupisRegistrationStore();
}
