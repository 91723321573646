import {
  isArrayOf,
  isObject,
  isString,
  isUndefined,
} from '@leon-hub/guards';

import type { SpintaxSeoMetaData } from 'web/src/modules/seo/types';

function isStringDictionary(value: unknown): value is Dictionary<string> {
  return isObject(value) && Object.values(value).every(isString);
}

export function isSpintaxSeoMetaData(value: unknown): value is SpintaxSeoMetaData {
  return isObject(value)
    && (isUndefined(value.title) || isArrayOf(isStringDictionary, value.title))
    && (isUndefined(value.meta) || isArrayOf(isStringDictionary, value.meta));
}
