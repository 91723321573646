import type {
  VirtualSportFilterOption,
  VirtualSportFilterOptions,
  VirtualSportSelectedFilter,
} from 'web/src/modules/sportline/submodules/virtual-sport/types';

import isSelectedVirtualSportFilterOption from './isSelectedVirtualSportFilterOption';

export default function findSelectedVirtualSportFilterOption(
  options?: Maybe<VirtualSportFilterOptions>,
  filter?: Maybe<VirtualSportSelectedFilter>,
): Maybe<VirtualSportFilterOption> {
  return options?.sports.find((option) => isSelectedVirtualSportFilterOption(option, filter)) || null;
}
