import { RequestOptionsPriority } from '@leon-hub/api-sdk';

import useBaseApiClient from 'web/src/modules/core/services/api/useBaseApiClient';

import type { ResolveVirtualIdsResponse, ResolveVirtualIdsInput } from './types';
import { isResolveVirtualIdsResponse } from './guards';

export interface VirtualSportApi {
  resolveVirtualIds(input: ResolveVirtualIdsInput): Promise<ResolveVirtualIdsResponse>;
}

export default function createVirtualSportApi(): VirtualSportApi {
  const client = useBaseApiClient();

  return {
    async resolveVirtualIds(input: ResolveVirtualIdsInput) {
      return client.request<ResolveVirtualIdsResponse>({
        id: 'betline/resolve-virtual-ids',
        endpoint: '/betline/resolve-virtual-ids',
        silent: input.silent,
        priority: input.silent ? RequestOptionsPriority.LOW : undefined,
        query: {
          marketId: encodeURIComponent(input.marketId),
          matchId: encodeURIComponent(input.matchId),
          odds: input.odds !== undefined ? encodeURIComponent(input.odds) : undefined,
          outcomeId: encodeURIComponent(input.outcomeId),
          // @TODO marketSpecifier send undefined after api fix
          marketSpecifier: input.marketSpecifier !== undefined ? encodeURIComponent(input.marketSpecifier) : '',
          tournament: input.sport !== undefined ? encodeURIComponent(input.sport) : undefined,
        },
        guard: isResolveVirtualIdsResponse,
      });
    },
  };
}
