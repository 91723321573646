import type { Ref } from 'vue';
import { ref } from 'vue';

interface UseVFavoriteToggleEmits {
  (e: 'click', event: MouseEvent): void;
}

interface UseVFavoriteToggleComposable {
  onClick(event: MouseEvent): void;
  onMouseLeave(): void;
  isToggled: Ref<boolean>;
}

export function useVFavoriteToggle(
  emit: UseVFavoriteToggleEmits,
): UseVFavoriteToggleComposable {
  const isToggled = ref(false);

  function onClick(event: MouseEvent): void {
    isToggled.value = true;
    emit('click', event);
  }

  function onMouseLeave(): void {
    isToggled.value = false;
  }

  return {
    onClick,
    onMouseLeave,
    isToggled,
  };
}
