import type { Ref } from 'vue';
import {
  computed,
  nextTick,
  onBeforeUnmount,
  ref,
  watch,
} from 'vue';

import { Theme } from '@leon-hub/api-sdk';
import { PostMessageBus } from '@leon-hub/postmessage-bus';

import { useSportlineWidgetsStore } from 'web/src/modules/sportline/submodules/widgets/store';
import type { SportradarStatisticsWidgetFrameConfig } from 'web/src/modules/sportline/submodules/widgets/store/types';
import { useTheme } from 'web/src/modules/theme/composables';
import { SportradarFramedWidgetProperties } from 'web/src/modules/sportline/submodules/widgets/enums';
import { IframeWidget } from 'web/src/modules/framed-app/enums';
import {
  SportradarLiveScorePostMessageBusInitiator,
  SportradarLiveScorePostMessageEvent,
} from 'web/src/modules/framed-app/components/SportradarLiveScoreFramedWidget/utils/SportradarLiveScorePostMessageEvent';
import {
  SportradarStatisticPostMessageBusInitiator,
  SportradarStatisticPostMessageEvent,
} from 'web/src/modules/framed-app/components/SportradarStatisticsFramedWidget/utils/SportradarStatisticPostMessageEvent';
import type { VIframeRef } from 'web/src/components/Iframe/VIframe/types';
import { requireContentWindowByIFrameName } from 'web/src/components/Iframe/VIframe/utils';
import { useFramedWidgetUrl } from 'web/src/modules/widgets/composables/useFramedWidgetUrl';

import type { SportradarWidgetRoutePageProps } from '../types';

export interface UseSportradarWidgetRoutePageComposable {
  frame: Ref<VIframeRef | undefined>;
  internalWidgetLoaded: Ref<boolean>;
  isIframeVisible: Ref<boolean>;
  iframeWidgetSrc: Ref<string | undefined>;
  iframeName: Ref<IframeWidget>;
  additionalQueryStringParameters: Ref<Record<string, string>[]>;
  initPostMessageBus(): void;
}

export function useSportradarWidgetRoutePage(props: SportradarWidgetRoutePageProps): UseSportradarWidgetRoutePageComposable {
  const { theme } = useTheme();
  const widgetsStore = useSportlineWidgetsStore();

  const internalWidgetLoaded = ref(false);
  const isIframeVisible = ref(true);
  const errorMessage = 'Unknown sportradar widget';

  const widgetsConfig = computed<Maybe<SportradarStatisticsWidgetFrameConfig>>(() => {
    switch (props.type) {
      case 'livescore':
        return widgetsStore.sportradarLiveScoreFrameConfigFinal;
      case 'statistics':
        return widgetsStore.sportradarStatisticsConfigFinal;
      default:
        throw new Error(errorMessage);
    }
  });

  function composeWidgetUrl(widgetUrl: string, customLang?: Maybe<string>): string {
    const widgetLang = customLang || 'en';
    let fullWidgetUrl = widgetUrl;

    if (process.env.VUE_APP_LAYOUT_PHONE) {
      fullWidgetUrl += 'mobile';
    }

    if (theme.value === Theme.LIGHT) {
      fullWidgetUrl += 'light';
    }

    fullWidgetUrl += `/${widgetLang}`;

    return fullWidgetUrl;
  }

  const additionalQueryStringParameters = computed<Record<string, string>[]>(() => {
    let widgetUrl = '';
    if (widgetsConfig.value?.productionUrl) {
      widgetUrl = composeWidgetUrl(widgetsConfig.value?.productionUrl, widgetsConfig.value?.customLang);
    }

    return [
      {
        [SportradarFramedWidgetProperties.ENCODED_URL]: encodeURIComponent(widgetUrl),
      },
    ];
  });

  const iframeName = computed<IframeWidget>(() => {
    switch (props.type) {
      case 'livescore':
        return IframeWidget.SportradarLiveScore;
      case 'statistics':
        return IframeWidget.SportradarStatistics;
      default:
        throw new Error(errorMessage);
    }
  });

  const iframeWidgetSrc = useFramedWidgetUrl(iframeName);

  function getInitiatorByWidget(widget: IframeWidget): string {
    switch (widget) {
      case IframeWidget.SportradarLiveScore:
        return SportradarLiveScorePostMessageBusInitiator;
      case IframeWidget.SportradarStatistics:
        return SportradarStatisticPostMessageBusInitiator;
      default:
        throw new Error(errorMessage);
    }
  }

  let postMessageBus: PostMessageBus | undefined;

  const frame = ref<VIframeRef>();

  function onHeightChanged({ height }: { height: number }) {
    frame.value?.resizeIframe(height + 10);
  }

  function onIframeLoaded(): void {
    internalWidgetLoaded.value = true;
  }

  function initPostMessageBus(): void {
    internalWidgetLoaded.value = false;
    postMessageBus?.dispose();

    const iframeContentWindow = requireContentWindowByIFrameName(iframeName.value);
    postMessageBus = new PostMessageBus({
      target: iframeContentWindow,
      targetOrigin: '*',
      initiator: getInitiatorByWidget(iframeName.value),
    });

    switch (props.type) {
      case 'livescore':
        postMessageBus.on(SportradarLiveScorePostMessageEvent.heightChanged, onHeightChanged);
        postMessageBus.on(SportradarLiveScorePostMessageEvent.onLoad, onIframeLoaded);
        break;
      case 'statistics':
        postMessageBus.on(SportradarStatisticPostMessageEvent.heightChanged, onHeightChanged);
        postMessageBus.on(SportradarStatisticPostMessageEvent.onLoad, onIframeLoaded);
        break;
      default:
        throw new Error(errorMessage);
    }
  }

  watch(iframeName, async () => {
    isIframeVisible.value = false;
    await nextTick();
    isIframeVisible.value = true;
  });

  onBeforeUnmount(() => {
    postMessageBus?.dispose();
    postMessageBus = undefined;
  });

  return {
    frame,
    internalWidgetLoaded,
    isIframeVisible,
    iframeWidgetSrc,
    iframeName,
    additionalQueryStringParameters,
    initPostMessageBus,
  };
}
