<script lang="ts" setup>
import { useRoute, useRouter } from 'vue-router';

import casinoExitRoutePageBeforeEnter from './before-enter/CasinoExitRoutePageBeforeEnter';

const route = useRoute();
const router = useRouter();

const url = route.query.url as string || '/';

if (!window.top || window.top === window) {
  void router.replace(url);
}

defineOptions({
  name: 'CasinoExitRoutePage',
  beforeRouteEnter: casinoExitRoutePageBeforeEnter,
});
</script>

<template>
  <div v-auto-id="'CasinoExitRoutePage'" />
</template>
