<script lang="ts" setup>
import { useVFadeCarouselSlide } from 'web/src/components/FadeCarousel/VFadeCarouselSlide/composables';

interface VFadeCarouselSlideProps {
  currentSlideIndex: number;
}

const props = defineProps<VFadeCarouselSlideProps>();

const {
  isSlideShown,
  isSlideVisible,
} = useVFadeCarouselSlide(props);
</script>

<template>
  <div v-auto-id="'VFadeCarouselSlide'"
    v-data-test="{ el: 'carousel-slide', visible: isSlideVisible }"
    class="fade-carousel-slide"
    :class="{
      [$style['fade-carousel-slide']]: true,
      [$style['fade-carousel-slide--active']]: isSlideVisible,
    }"
  >
    <slot
      :is-slide-shown="isSlideShown"
    />
  </div>
</template>

<style module lang="scss">
.fade-carousel-slide {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  user-select: none;
  opacity: 0;
  transition: opacity 0.4s ease-in-out 0s;
  -webkit-user-drag: none;

  &--active {
    position: relative;
    z-index: 1;
    opacity: 1;
  }
}
</style>
