import { SportFamily } from 'web/src/modules/sportline/enums';

export const SportBackgroundColorDark = {
  [<string>SportFamily.ESport]: '#2A1E41',
  [<string>SportFamily.Cricket]: '#41701F',
  [<string>SportFamily.AustralianRules]: '#2D782D',
  [<string>SportFamily.Rugby]: '#339955',
  [<string>SportFamily.RugbyLeague]: '#339955',
  [<string>SportFamily.RugbyUnion]: '#339955',
  [<string>SportFamily.Baseball]: '#1C6427',
  [<string>SportFamily.AmericanFootball]: '#46782D',
  [<string>SportFamily.Volleyball]: '#BF6A40',
  [<string>SportFamily.Tennis]: '#9B5231',
  [<string>SportFamily.Soccer]: '#2D7837',
  [<string>SportFamily.TableTennis]: '#083E6C',
  [<string>SportFamily.Squash]: '#BF6A40',
  [<string>SportFamily.Snooker]: '#2D8668',
  [<string>SportFamily.WaterPolo]: '#337799',
  [<string>SportFamily.Futsal]: '#3A86AC',
  [<string>SportFamily.Floorball]: '#2D8686',
  [<string>SportFamily.Bandy]: '#2D6886',
  [<string>SportFamily.Darts]: '#BF4040',
  [<string>SportFamily.Badminton]: '#2D8686',
  [<string>SportFamily.Boxing]: '#7B3636',
  [<string>SportFamily.MMA]: '#7B3636',
  [<string>SportFamily.Motorsport]: '#AC3A3A',
  [<string>SportFamily.WinterSports]: '#3A5FAC',
  [<string>SportFamily.BeachVolleyball]: '#AC7C3A',
  [<string>SportFamily.Handball]: '#3A86AC',
  [<string>SportFamily.Basketball]: '#7E5F3A',
  [<string>SportFamily.IceHockey]: '#2E4A65',
  [<string>SportFamily.Baseball]: '#1C6427',
  [<string>SportFamily.BeachSoccer]: '#AC7C3A',
  [<string>SportFamily.Curling]: '#333399',
  [<string>SportFamily.Golf]: '#4A862D',
  [<string>SportFamily.Basketball3x3]: '#7E5F3A',
  [<string>SportFamily.AlpineSkiing]: '#3E6274',
  [<string>SportFamily.Biathlon]: '#175F82',
  [<string>SportFamily.CrossCountrySkiing]: '#213268',
  [<string>SportFamily.SkiJumping]: '#364E7D',
  // [<string>SportFamily.Bowling]: '#B87E2E', // Bowling
  [<string>SportFamily.Cycling]: '#433AAC',
  [<string>SportFamily.Equestrian]: '#884A2A',
  [<string>SportFamily.FieldHockey]: '#279B75',
  [<string>SportFamily.FormulaE]: '#AC3A3A',
  [<string>SportFamily.GaelicFootball]: '#2D782D',
  [<string>SportFamily.GaelicHurling]: '#1C6427',
  // [<string>SportFamily.Lacrosse]: '#4A862D', // Lacrosse
  [<string>SportFamily.Pesapallo]: '#8F513D',
  [<string>SportFamily.Sailing]: '#3A86AC',
  [<string>SportFamily.Bowls]: '#4A862D',
};
