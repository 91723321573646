<script lang="ts" setup>
import { computed } from 'vue';

import type { BalanceValueProps } from './types';

const props = withDefaults(defineProps<BalanceValueProps>(), {
  balanceLabel: '',
  type: 'balance',
});

const valueStringFormatted = computed(() => props.value.replace(/[.,\s]/g, ''));
</script>

<template>
  <div v-auto-id="'BalanceValue'" :class="$style['balance-value-wrapper']">
    <template v-if="null">
      <div :class="$style['balance-caption']">
        {{ balanceLabel }}
      </div>
      <div
        :class="{
          [$style['balance-value']]: true,
          [$style[`balance-value--type__${type}`]]: !!type,
          [$style['balance-value--fit-to-width']]: valueStringFormatted.length > 7,
        }"
        role="note"
        :title="balanceLabel"
        tabindex="0"
      >
        <span>{{ value }}</span>
      </div>
    </template>
    <template v-else>
      <div
        :class="{
          [$style['balance-value']]: true,
          [$style[`balance-value--type__${type}`]]: !!type,
        }"
        role="note"
        :title="balanceLabel"
        tabindex="0"
      >
        {{ value }}
      </div>
      <div :class="$style['balance-caption']">
        {{ caption }}
      </div>
    </template>
  </div>
</template>

<style lang="scss" module>
.balance-value-wrapper {
  @include balanceWrapper;
}

.balance-value {
  @include balanceValue;

  white-space: nowrap;

  &--type {
    @each $type, $typeProps in $balanceTypeProps {
      &__#{$type} {
        color: get($typeProps, 'color');
      }
    }
  }

  &--fit-to-width {
    @include balanceFitToWidth;
  }
}

.balance-caption {
  @include balanceCaption;

  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@keyframes apply-text-ratio {
  from {
    margin-block-end: -1lh;
    transform: scale(0);
  }
}
</style>
