<script lang="ts" setup>
import {
  computed,
  ref,
} from 'vue';

import { Tag } from '@leon-hub/tags';

import VButton from 'web/src/components/Button/VButton/VButton.vue';
import type {
  LobbySectionHeaderEmits,
  LobbySectionHeaderProps,
  LobbySectionHeaderSlots,
} from 'web/src/modules/lobby/components/LobbySectionHeader/types';
import {
  ButtonHeight,
  ButtonKind,
} from 'web/src/components/Button/VButton/enums';
import SButton from 'web/src/components/Button/SButton/SButton.vue';

const props = defineProps<LobbySectionHeaderProps>();
const emits = defineEmits<LobbySectionHeaderEmits>();
defineSlots<LobbySectionHeaderSlots>();

const isIconLoaded = ref(false);

function loadIcon(): void {
  isIconLoaded.value = true;
}

const showSkeleton = computed(() => (
  props.isLoading || (
    props.iconUrl && !isIconLoaded.value
  )
));
</script>

<template>
  <div v-auto-id="'LobbySectionHeader'"
    :class="{
      [$style['lobby-section-header']]: true,
      [$style['lobby-section-header--modal']]: isModal,
      [$style['lobby-section-header--no-background']]: noBackground,
      [$style['lobby-section-header--small']]: isSmall,
      [$style['lobby-section-header--grid-layout']]: isGridLayout,
      [$style['lobby-section-header--custom-homepage']]: isCustomHomepage,
    }"
  >
    <div
      v-data-test="{ el: 'egs-lobby-section-header-title' }"
      :class="{
        [$style['lobby-section-header__title']]: true,
        [$style['lobby-section-header__title--with-horizontal-margin']]: isHeaderWithHorizontalMargin,
      }"
    >
      <slot name="icon">
        <img
          v-if="iconUrl"
          :src="iconUrl"
          :alt="title"
          :class="$style['lobby-section-header__icon']"
          @load="loadIcon"
          @error="loadIcon"
        >
        <div
          v-else-if="null"
          :class="$style['lobby-section-header__icon']"
        />
      </slot>
      <div
        v-if="showSkeleton"
        :class="$style['lobby-section-header__skeleton']"
      />
      <span v-else>{{ title }}</span>
    </div>
    <slot
      name="filters"
    >
      <div :class="$style['lobby-section-header__controls']">
        <slot name="action">
          <template v-if="actionLabel">
            <SButton
              v-if="null"
              :label="actionLabel"
              :tag="actionHref ? Tag.A : undefined"
              :href="actionHref"
              kind="tertiary-secondary"
              :size="'1' && !isModal ? 'medium' : 'small'"
              @click.prevent="emits('action-click')"
            />
            <VButton
              v-else
              v-data-test="{ el: 'egs-lobby-section-header-button' }"
              :is-uppercase="false"
              :kind="ButtonKind.BASE"
              :height="ButtonHeight.MEDIUM"
              :label="actionLabel"
              :tag="actionHref ? Tag.A : undefined"
              :href="actionHref"
              @click.prevent="emits('action-click')"
            />
          </template>
        </slot>
        <slot name="navigation" />
      </div>
    </slot>
  </div>
</template>

<style lang="scss" module>
@import 'web/src/components/Skeleton/styles/skeleton';

@include for-layout using($isDesktop) {
  .lobby-section-header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: $lobbySectionHeaderPaddingDefault;

    @if $isDesktop {
      padding: $lobbySectionHeaderPaddingDesktop;
    }

    @include screen-desktop-large-min {
      padding-right: $lobbySectionHeaderPaddingSidesBig;
      padding-bottom: $lobbySectionHeaderPaddingBottomBig;
      padding-left: $lobbySectionHeaderPaddingSidesBig;
    }

    &--grid-layout {
      padding: $lobbySectionHeaderPaddingGrid;
    }

    &__title {
      display: flex;
      gap: $lobbySectionHeaderIconMarginRightPhone;
      align-items: center;
      height: $lobbySectionHeaderSizeSmall;
      margin: $lobbySectionHeaderTitleMargin;
      color: $lobbySectionHeaderTitleColor;

      @if $isDesktop {
        @include lobbySectionHeaderTitleDesktop;
      } @else {
        @include lobbySectionHeaderTitleMobile;
      }

      @include screen-desktop-small-min {
        gap: $lobbySectionHeaderIconMarginRightDesktop;
        height: $lobbySectionHeaderSizeBig;
      }
    }

    &__skeleton {
      @include skeletonFont(120px, $lobbySectionHeaderIconSizeSmall, 10px, $lobbySectionHeaderTitleColor);

      @include screen-desktop-small-min {
        @include skeletonFont(120px, $lobbySectionHeaderIconSizeBig, 10px, $lobbySectionHeaderTitleColor);
      }
    }

    &__icon {
      width: $lobbySectionHeaderIconSizeSmall;
      height: $lobbySectionHeaderIconSizeSmall;
      border-radius: $lobbySectionHeaderIconRadiusSmall;

      @include screen-desktop-small-min {
        width: $lobbySectionHeaderIconSizeBig;
        height: $lobbySectionHeaderIconSizeBig;
        border-radius: $lobbySectionHeaderIconRadiusBig;
      }
    }

    &__controls {
      display: flex;
      flex-direction: $lobbySwiperSectionActionsDirection;
      gap: $lobbySwiperSectionActionsGap;
      margin-right: $lobbySectionHeaderControlsMarginRightPhone;
      margin-left: auto;

      @include screen-desktop-small-min {
        margin-right: 0;
      }
    }

    &--search {
      @if $isDesktop {
        .lobby-section-header__controls {
          margin-right: 16px;
        }

        .lobby-section-header__title {
          margin-left: 0;
        }
      }
    }

    &--small {
      padding: 4px 0 0;

      .lobby-section-header__title {
        @include medium\13\16;

        margin: 0;
        color: var(--TextSecondary);

        @if not $isDesktop {
          margin-left: 8px;
        }
      }

      .lobby-section-header__skeleton {
        @include skeletonFont(120px, 40px, 7px, var(--TextSecondary));
      }
    }

    &--modal {
      padding: $lobbySectionHeaderPaddingModal;

      @include screen-desktop-small-min {
        padding: $lobbySectionHeaderPaddingModalDesktop;
      }

      .lobby-section-header__controls {
        gap: $lobbySwiperSectionActionsGapModal;
      }

      .lobby-section-header__title {
        @include lobbySectionHeaderTitleMobile;

        gap: $lobbySectionHeaderIconMarginRightPhone;
        height: $lobbySectionHeaderSizeSmall;
        margin-left: 0;

        @include screen-desktop-small-min {
          gap: $lobbySectionHeaderIconMarginRightPhone;
        }
      }

      .lobby-section-header__title--with-horizontal-margin {
        margin-left: 8px;
      }

      .lobby-section-header__icon {
        width: $lobbySectionHeaderIconSizeSmall;
        height: $lobbySectionHeaderIconSizeSmall;
        border-radius: $lobbySectionHeaderIconRadiusSmall;

        @include screen-desktop-small-min {
          width: $lobbySectionHeaderIconSizeSmall;
          height: $lobbySectionHeaderIconSizeSmall;
          border-radius: $lobbySectionHeaderIconRadiusSmall;
        }
      }
    }

    &--no-background {
      padding: 0 0 16px;

      @include screen-desktop-small-min {
        padding: $lobbySectionHeaderPaddingModalDesktop;
      }
    }

    &--custom-homepage {
      padding: if($isDesktop, 48px 0 8px, 24px 0 16px);

      @if $isDesktop {
        .lobby-section-header__title {
          @include lobbySectionHeaderTitleDesktopCustomHomepage;
        }
      }
    }
  }
}
</style>
