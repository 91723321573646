import { ComponentKey, ComponentStatus } from '../types';
import type { ComponentResult } from '../types';
import AbstractFingerprintComponent from '../AbstractFingerprintComponent';

export default class PlatformComponent extends AbstractFingerprintComponent {
  constructor() {
    super(ComponentKey.Platform);
  }

  getComponentResult(): Promise<ComponentResult> {
    return this.result([
      window.navigator.platform ? window.navigator.platform : ComponentStatus.NotAvailable,
    ]);
  }
}
