import { normalizeError } from '@leon-hub/errors';
import { logger } from '@leon-hub/logging';
import { promiseTimeout } from '@leon-hub/utils';

import { DEFAULT_TIMEOUT } from './constants';
import type {
  ContinueEffectCallback,
  WatchEffectFactory,
} from './types';

export const createAsyncEffect: WatchEffectFactory = ({ scope, effects }) => {
  let isStopped = false;
  return async (onCleanup) => {
    onCleanup(() => {
      isStopped = true;
    });

    for (const runSubEffect of effects) {
      // Skip sub-effects if the main effect is stopped.
      if (isStopped) return;
      try {
        const result = scope.run(() => runSubEffect(onCleanup));
        if (result instanceof Promise) {
          const promise: Promise<void> = Number.isFinite(DEFAULT_TIMEOUT) ? promiseTimeout({
            promise: result,
            timeout: DEFAULT_TIMEOUT,
          }) : result;
          // Await each sub-effect in series to avoid race conditions.
          // eslint-disable-next-line no-await-in-loop
          await promise;
        }
      } catch (err) {
        if (isStopped) return;
        const normalizedError = normalizeError(err);
        normalizedError.message = `Unable to run sub effect (${runSubEffect.name ?? '<unnamed>'}): ${normalizedError.message}`;
        // eslint-disable-next-line no-console
        console.error(normalizedError.message, runSubEffect);
        logger.error(normalizedError);
        break;
      }
    }
  };
};

const cachedWrappers = new WeakSet<ContinueEffectCallback<Promise<unknown>>>();
function isNormalizedEffect(callback: Function): callback is ContinueEffectCallback<Promise<unknown>> {
  return cachedWrappers.has(callback as ContinueEffectCallback<Promise<unknown>>);
}
export function normalizeEffect<
  Return,
  EffectCallback extends ContinueEffectCallback<Return>,
>(callback: EffectCallback): ContinueEffectCallback<Promise<Return>> {
  if (isNormalizedEffect(callback)) return callback as ContinueEffectCallback<Promise<Return>>;
  const wrappedEffect = (...rest: Parameters<EffectCallback>): Promise<Return> => Promise.resolve(callback(...rest));
  cachedWrappers.add(wrappedEffect);
  return wrappedEffect;
}
