import type { Directive } from 'vue';

const createTestAttribute = (attributes?: Record<string, string>): Record<string, string> => {
  if (!attributes?.el) {
    return {};
  }

  return Object.keys(attributes).reduce((result, key) => {
    const name = key === 'el' ? 'data-test-el' : `data-test-attr-${key}`;

    // eslint-disable-next-line no-param-reassign
    result[name] = attributes[key];

    return result;
  }, {} as Record<string, string>);
};

type BindingValues = Record<string, string>;

export const setElementAttributes = (element: Element, values: BindingValues): void => {
  const attributes = createTestAttribute(values);
  for (const key of Object.keys(attributes)) {
    try {
      element.setAttribute(key, attributes[key]);
    } catch {}
  }
};

export const dataTest: Directive = {
  beforeMount: (element, binding) => setElementAttributes(element, binding.value),
  updated: (element, binding) => setElementAttributes(element, binding.value),
  beforeUnmount: (element, binding) => setElementAttributes(element, binding.value),
};
