<script lang="ts" setup>
import { computed } from 'vue';

import type { SportlinePostMatchStatistics } from 'web/src/modules/sportline/types/statistics';
import { buildPostMatchStatisticsFromSportlineEvent } from 'web/src/modules/sportline/submodules/event-statistic/utils';

import { useSportlineEventDetailsHeadlineData } from './composables';
import PostMatchStatisticHeadline from '../post-match-statistic/PostMatchStatisticHeadline.vue';

const {
  sportlineEvent,
  sport,
} = useSportlineEventDetailsHeadlineData();

const postMatchStatistic = computed<Maybe<SportlinePostMatchStatistics>>(() => buildPostMatchStatisticsFromSportlineEvent({
  sportlineEvent: sportlineEvent.value,
  sport: sport.value,
}));
</script>

<template>
  <PostMatchStatisticHeadline
    v-if="postMatchStatistic"
    :post-match-statistic="postMatchStatistic"
  />
  <div v-else />
</template>
