import {
  isNumber,
  isObject,
} from '@leon-hub/guards';

import type { ResolveVirtualIdsResponse } from './types';

export function isResolveVirtualIdsResponse(value: unknown): value is ResolveVirtualIdsResponse {
  return isObject(value)
    && isNumber(value.eId)
    && isNumber(value.mId)
    && isNumber(value.rId);
}
