<script lang="ts" setup>
import { computed } from 'vue';

import ConfirmModal from 'web/src/modules/dialogs/views/ConfirmModal/ConfirmModal.vue';
import type { BaseModalEmits } from 'web/src/modules/dialogs/types';
import { useBaseModalActions } from 'web/src/modules/dialogs/composables';
import { DialogComponent } from 'web/src/modules/dialogs/enums';
import type { ConfirmModalProps } from 'web/src/modules/dialogs/views/ConfirmModal/types';

import type { TfaCodesModalProps } from './types';

const props = withDefaults(defineProps<TfaCodesModalProps>(), {});
const emit = defineEmits<BaseModalEmits>();

const { onClose, onButtonClick } = useBaseModalActions(emit);

const confirmModalProps = computed<ConfirmModalProps>(() => {
  const {
    codes, ...modalProps
  } = props.modal;

  return {
    modal: {
      ...modalProps,
      modalComponent: DialogComponent.ConfirmModal,
    },
    isAlert: props.isAlert,
  };
});
</script>

<template>
  <ConfirmModal v-auto-id="'TfaCodesModal'"
    v-bind="confirmModalProps"
    is-scroll-bar-enabled
    @close="onClose"
    @button-click="onButtonClick"
  >
    <div
      v-for="code in modal.codes"
      :key="code"
      :class="$style['tfa-code']"
    >
      {{ code }}
    </div>
  </ConfirmModal>
</template>

<style lang="scss" module>
.tfa-code {
  @include tfaCode;
}
</style>
