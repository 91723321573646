<script setup lang="ts">
import { toRef, toValue } from 'vue';

import type {
  PaymentCategoriesDocument,
  PaymentSystem,
} from 'web/src/modules/payments/types/documentTypes';
import VList from 'web/src/components/List/VList/VList.vue';
import PaymentsListItem from 'web/src/modules/payments/components/PaymentsList/PaymentsListItem.vue';
import SPaymentsListItem from 'web/src/modules/payments/components/PaymentsList/components/SPaymentsListItem/SPaymentsListItem.vue';
import { useCurrency } from 'web/src/modules/money/composables';
import { useCustomerDataStore } from 'web/src/modules/customer/store';

interface PaymentsListProps {
  categories: PaymentCategoriesDocument;
  enrollmentTimeInHint?: boolean;
  isWithdrawals?: boolean;
}

interface PaymentsListEmits {
  (e: 'click', system: PaymentSystem): void;
}

defineProps<PaymentsListProps>();
const emits = defineEmits<PaymentsListEmits>();
const currency = toValue(() => useCurrency().currency);
const isEkycRequestable = toRef(() => useCustomerDataStore().isEkycRequestable);
</script>

<template>
  <VList v-auto-id="'PaymentsList'" :class="$style['payments-list']">
    <template
      v-for="(category) in categories"
      :key="category.name"
    >
      <li
        :class="$style['payments-list__category-title']"
      >
        {{ category.name }}
      </li>
      <template v-if="null && ('isFolded' in category && category.isFolded)">
        <SPaymentsListItem
          :group-icon="require('web/src/assets/logotypes/slott/payment-crypto.svg')"
          :group-title="$t('WEB2_PAYMENTS_CRYPTO_GROUP_NAME')"
          :group-description="$t('WEB2_PAYMENTS_CRYPTO_GROUP_DESCRIPTION', { currency })"
          :payment-system="category.paymentSystems[0]"
          :enrollment-time-in-hint="enrollmentTimeInHint"
          :is-withdrawals="isWithdrawals"
          @click="emits('click', category.paymentSystems[0])"
        />
      </template>
      <template v-else>
        <template
          v-for="(system, index) in category.paymentSystems"
        >
          <SPaymentsListItem
            v-if="null"
            :key="`s-${system.id}-${index}`"
            :payment-system="system"
            :enrollment-time-in-hint="enrollmentTimeInHint"
            :is-withdrawals="isWithdrawals"
            @click="emits('click', system)"
          />
          <PaymentsListItem
            v-else
            :id="system.id"
            :key="`${system.id}-${index}`"
            :class="$style['payments-list-item']"
            :payment-system="system"
            :enrollment-time-in-hint="enrollmentTimeInHint"
            :is-withdrawals="isWithdrawals"
            :hide-unavailable-message="isEkycRequestable"
            @click="emits('click', system)"
          />
        </template>
      </template>
    </template>
  </VList>
</template>

<style lang="scss" module>
.payments-list {
  display: flex;
  flex-direction: column;
  gap: $paymentsListGap;

  &__category {
    &-title {
      @include payments-list-title-font;

      display: flex;
      align-items: center;
      padding: $paymentsListTitlePadding;
      color: $paymentsListTitleColor;

      .payments-list-item + & {
        margin-top: $paymentsListTitleMarginTop;
      }
    }
  }
}
</style>
