<script lang="ts" setup>
import { VIcon } from '@components/v-icon';

import VRenderlessSpoiler from 'web/src/components/Spoiler/components/VRenderlessSpoiler.vue';
import type { LeaderBoardSpoilerProps, LeaderBoardSpoilerEmits } from 'web/src/modules/promotions/components/LeaderBoardSpoiler/types';
import useLeaderBoardSpoiler
  from 'web/src/modules/promotions/components/LeaderBoardSpoiler/composables/useLeaderBoardSpoiler';

withDefaults(defineProps<LeaderBoardSpoilerProps>(), {
  title: '',
  opened: false,
});

const emit = defineEmits<LeaderBoardSpoilerEmits>();
const { indicatorProperties } = useLeaderBoardSpoiler();
</script>

<template>
  <VRenderlessSpoiler v-auto-id="'LeaderBoardSpoiler'" :opened="opened">
    <template #default="{ isOpen }">
      <div :class="$style['leader-board-spoiler']">
        <div
          :class="$style['leader-board-spoiler__header']"
          @click="emit('open')"
        >
          <span :class="$style['leader-board-spoiler__title']">{{ title }}</span>
          <span :class="$style['leader-board-spoiler__indicator']">
            <VIcon v-bind="isOpen ? indicatorProperties[1] : indicatorProperties[0]" />
          </span>
        </div>
        <div
          v-show="isOpen"
          :class="$style['leader-board-spoiler__content']"
        >
          <slot />
        </div>
      </div>
    </template>
  </VRenderlessSpoiler>
</template>

<style lang="scss" module>
.leader-board-spoiler {
  overflow: hidden;
  user-select: none;
  border-radius: 5px;

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 44px;
    color: var(--colorPrimaryL50);
    cursor: pointer;
    background-color: var(--Layer1);
    box-shadow: none;

    &--open {
      color: var(--colorWhite);
    }
  }

  &__title {
    @include medium\14\20\025;

    display: inline-block;
    margin-left: 12px;
    overflow: hidden;
    color: var(--TextPrimary);
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 13px;
    color: var(--TextDefault);
  }

  &__content {
    position: relative;

    :deep(.leader-board__header) {
      margin-top: 2px;
      border-radius: 0;
    }
  }
}
</style>
