<script lang="ts" setup>
import { computed } from 'vue';

import { useSwiperNavigation } from 'web/src/components/Swiper/Navigation/composables';
import SwiperNavigationButtonsBase
  from 'web/src/components/Swiper/Navigation/SwiperNavigationButtonsBase/SwiperNavigationButtonsBase.vue';
import type { ButtonKind } from 'web/src/components/Button/VButton/enums';

import getNavigationProps from './utils/getNavigationProps';

interface SwiperNavigationButtonsProps {
  isRounded?: boolean;
  isPreviousAllowed?: boolean;
  isNextAllowed?: boolean;
  buttonKind?: ButtonKind;
}

const props = defineProps<SwiperNavigationButtonsProps>();
const { swiperState, slideToPreviousSlide, slideToNextSlide } = useSwiperNavigation();
const navigationProperties = computed(() => getNavigationProps(props, swiperState));
</script>

<template>
  <SwiperNavigationButtonsBase v-auto-id="'SwiperNavigationButtons'"
    v-bind="navigationProperties"
    @click-previous="slideToPreviousSlide"
    @click-next="slideToNextSlide"
  />
</template>
