import type { Ref } from 'vue';
import {
  ref,
  computed,
  watch,
  nextTick,
} from 'vue';

import { getLocationHref } from '@leon-hub/service-locator-env';
import { PostMessageBus } from '@leon-hub/postmessage-bus';
import {
  stringifyQuery,
  Timer,
} from '@leon-hub/utils';
import { useDebounce } from '@leon-hub/debounce';
import {
  onComponentActivated,
  onComponentDeactivated,
} from '@leon-hub/vue-utils';

import IframePostMessageEvent from 'web/src/components/Iframe/types/IframePostMessageEvent';
import { FramedEnvironmentParameter } from 'web/src/modules/framed-app/enums';
import IFrameTimeoutError from 'web/src/modules/errors/errors/IFrameTimeoutError';

import { IframeMessageEvent } from '../enums';

export interface UseVIframeProps {
  iframeElement: Ref<Optional<HTMLIFrameElement>>;
  src: Ref<string>;
  name: Ref<string>;
  timeout: Ref<number>;
  additionalQueryStringParameters: Ref<Record<string, string>[]>;
  isHideFullHeightInUrl: Ref<boolean>;
  isFullSize: Ref<boolean>;
  heightAspectRatio: Ref<Optional<number>>;
  isAutoHeight: Ref<boolean>;
  isHideTitle: Ref<boolean>;
}

export interface UseVIframeEmits {
  // @see IframeMessageEvent.WindowIframeLoad
  (e: 'window-iframe-load'): void;
  // @see IframeMessageEvent.IframeApplicationReady
  (e: 'iframe-application-ready'): void;
  (e: 'load'): void;
  (e: 'error', error: unknown): void;
}

export interface UseVIframeComposable {
  loading: Ref<boolean>;
  iframeVisible: Ref<boolean>;
  reCreateIframeKey: Ref<number>;
  height: Ref<number>;
  styleObject: Ref<Dictionary<Optional<string>>>;
  title: Ref<string | undefined>;
  parametersDecoratedSrc: Ref<string>;
  onLoad(): void;
  beforeMount(): Promise<void>;
  beforeUnmount(): void;
  resizeIframe(value: number): void;
  postMessage(message: unknown, target: string): void;
  onError(error: unknown): void;
}

export default function useVIframe(props: UseVIframeProps, emit: UseVIframeEmits): UseVIframeComposable {
  let lastContentView: Optional<MessageEventSource>;
  let postMessageBus: Maybe<Readonly<PostMessageBus>> = null;

  const loading = ref(true);
  const iframeVisible = ref(false);
  const reCreateIframeKey = ref(0);
  const height = ref(0);

  const {
    timeout,
    iframeElement,
    src,
    name,
    additionalQueryStringParameters,
    isHideFullHeightInUrl,
    isFullSize,
    heightAspectRatio,
    isAutoHeight,
    isHideTitle,
  } = props;

  let timer = 0;

  const styleObject = computed<Dictionary<Optional<string>>>(() => (heightAspectRatio.value
    ? { 'padding-top': `calc(100% / ${heightAspectRatio.value})` }
    : {}));
  const title = computed<string | undefined>(() => (isHideTitle.value ? undefined : name.value));

  function onIframeApplicationReady(): void {
    if (isAutoHeight.value) {
      postMessageBus?.on(IframePostMessageEvent.iframeDocumentResize, (value) => {
        resizeIframe(value.height);
      });
    }

    emit(IframeMessageEvent.IframeApplicationReady);
  }

  function emitWindowIframeLoad(): void {
    iframeVisible.value = true;
    emit(IframeMessageEvent.WindowIframeLoad);
  }

  function onLoad() {
    loading.value = false;
    emit('load');
  }

  function createPostMessageBus(): void {
    if (!iframeElement.value?.contentWindow) { return; }

    if (lastContentView !== iframeElement.value.contentWindow) {
      iframeElement.value.addEventListener('load', emitWindowIframeLoad);
      lastContentView = iframeElement.value.contentWindow;
    }

    postMessageBus?.dispose();
    postMessageBus = new PostMessageBus({
      target: iframeElement.value.contentWindow,
      targetOrigin: '*',
      initiator: 'VIframe',
    });
    postMessageBus.on(IframePostMessageEvent.iframeApplicationReady, onIframeApplicationReady);
  }

  async function beforeMount(): Promise<void> {
    await nextTick();
    createPostMessageBus();
  }

  function resizeIframe(value: number): void {
    height.value = value;
  }

  function beforeUnmount() {
    postMessageBus?.dispose();
    lastContentView = undefined;
  }

  const isLocalSrc = computed(() => {
    if (process.env.VUE_APP_PLATFORM_CORDOVA || src.value.startsWith('/')) {
      return true;
    }

    if (process.env.VUE_APP_RENDERING_CSR) {
      const sourceOrigin = new URL(src.value).origin;
      const appHref = getLocationHref();
      return appHref === sourceOrigin || appHref.startsWith(`${sourceOrigin}/`);
    }

    return false;
  });

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const parametersDecoratedSrc = computed(() => {
    const extraQuery: Record<string, string> = {};
    const hasQueryStart = src.value.includes('?');
    const search = hasQueryStart ? src.value.replace(/^[^?]+\?/, '') : '';
    const hasQuery = search.length > 0;

    if (isLocalSrc.value) {
      if (isAutoHeight.value) {
        extraQuery[FramedEnvironmentParameter.AutoHeight] = '1';
      }

      if (isFullSize.value && !isHideFullHeightInUrl.value) {
        extraQuery[FramedEnvironmentParameter.FullHeight] = '1';
      }
    }

    if (additionalQueryStringParameters.value?.length) {
      for (const item of additionalQueryStringParameters.value) {
        const key = Object.keys(item)[0];

        if (item[key]) {
          extraQuery[key] = item[key];
        }
      }
    }

    return Object.keys(extraQuery).length
      ? `${src.value}${hasQuery ? '&' : '?'}${stringifyQuery(extraQuery)}`
      : src.value;
  });

  const reCreateIframe = useDebounce(async () => {
    reCreateIframeKey.value += 1;
    await nextTick();
    createPostMessageBus();
  }, 20);

  watch(src, reCreateIframe);

  function postMessage(message: unknown, target = '*'): void {
    iframeElement.value?.contentWindow?.postMessage(message, target);
  }

  function onError(error: unknown): void {
    stopTimer();
    emit('error', error);
  }

  function stopTimer(): void {
    if (timer) {
      Timer.clearTimeout(timer);
      timer = 0;
    }
  }

  onComponentActivated(() => {
    if (timeout.value > 0) {
      timer = Timer.setTimeout(() => {
        onError(new IFrameTimeoutError());
      }, timeout.value);
    }
  });

  onComponentDeactivated(stopTimer);

  return {
    loading,
    reCreateIframeKey,
    styleObject,
    iframeVisible,
    title,
    height,
    parametersDecoratedSrc,
    onLoad,
    beforeMount,
    beforeUnmount,
    resizeIframe,
    postMessage,
    onError,
  };
}
