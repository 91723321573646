import { logger } from '@leon-hub/logging';
import { openDocument } from '@leon-hub/cordova';

export default function openGoogleAuthenticator(url: string): void {
  if (process.env.VUE_APP_LAYOUT === 'desktop') {
    return;
  }

  try {
    if (process.env.VUE_APP_PLATFORM === 'cordova') {
      openDocument(url);
    } else if (window) {
      window.location.replace(url);
    }
  } catch {
    logger.error('No g2sv application installed');
  }
}
