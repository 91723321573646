import type { CasinoLoyaltyProgramIconProperties } from 'web/src/modules/casino-loyalty-program/store/types';
import {
  CasinoLoyaltyProgramLevelStatus,
} from 'web/src/modules/casino-loyalty-program/store/types';

export default function getIconProps(
  imageUrl: string | undefined,
  status: string | undefined,
): CasinoLoyaltyProgramIconProperties {
  return {
    imageSrc: imageUrl,
    isInactive: status !== CasinoLoyaltyProgramLevelStatus.SUCCESS,
  };
}
