import { InputEventType } from '../../enums';
import type { BaseInputEmits, VInputInputEvent, MaskOptions } from '../../types';

interface UseCardExpirationDateInput {
  maskOptions: MaskOptions;
  onChange: (event: VInputInputEvent) => void;
  onInput: (event: VInputInputEvent) => void;
  onFocus: (event: VInputInputEvent) => void;
  onBlur: (event: VInputInputEvent) => void;
}

export default function useCardExpirationDateInput(emit: BaseInputEmits): UseCardExpirationDateInput {
  const maskOptions: MaskOptions = {
    pattern: '99/99',
    placeholder: '0',
  };

  const formatEventValue = (originalEvent: VInputInputEvent): VInputInputEvent => {
    const { name, value } = originalEvent.target;
    const formattedValue = value.replace(/^(\d{2})/, '$1/');
    return {
      target: {
        name,
        value: formattedValue,
      },
    };
  };

  const onFocus = (event: VInputInputEvent): void => {
    emit(InputEventType.FOCUS, formatEventValue(event));
  };

  const onBlur = (event: VInputInputEvent): void => {
    emit(InputEventType.BLUR, formatEventValue(event));
  };

  const onChange = (event: VInputInputEvent): void => {
    emit(InputEventType.CHANGE, formatEventValue(event));
  };

  const onInput = (event: VInputInputEvent): void => {
    emit(InputEventType.INPUT, formatEventValue(event));
  };

  return {
    maskOptions,
    onFocus,
    onBlur,
    onInput,
    onChange,
  };
}
