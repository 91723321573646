import { assert } from '@leon-hub/guards';

import {
  isValidNumberInput,
  shouldReplaceZero,
} from '../../utils';

export default function handleNumberInput(
  event: InputEvent,
  currentValue = '',
  fractionDigits = 0,
  symbolLimitation?: number,
): string {
  const { target } = event;
  assert(target instanceof HTMLInputElement);
  let eventValue = target.value;
  const haveComma = eventValue.includes(',');
  if (haveComma) {
    eventValue = eventValue.replaceAll(',', '.');
  }

  if (shouldReplaceZero(currentValue, eventValue)) {
    eventValue = eventValue.slice(1);
  }
  const isInputValid = isValidNumberInput(eventValue, fractionDigits);
  if (!isInputValid || (symbolLimitation && eventValue.length > symbolLimitation)) {
    target.value = currentValue;
  }
  if (isInputValid) {
    const nextValue = eventValue.slice(0, symbolLimitation);
    if (haveComma) {
      target.value = nextValue;
    }
    return nextValue;
  }
  return currentValue;
}
