import type { Ref } from 'vue';
import { computed, toRef } from 'vue';

import type { FaqV2Category } from '@leon-hub/api-sdk';
import {
  helpButtonModeExtChat,
  helpButtonModeHelp,
  helpChatTypeIntercom, helpChatTypeLivechat,
} from '@leon-hub/api-sdk';

import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { useSupportFaqStore } from 'web/src/modules/support/submodules/faq/store';

interface useSupportComposable {
  faqEnabled: Ref<boolean>;
  callbackEnabled: Ref<boolean>;
  categoryByUrlId(urlId: string): FaqV2Category | undefined;
  isHelpMode: Ref<boolean>;
  isChatMode: Ref<boolean>;
  isIntercomAvailable: Ref<boolean>;
  isLiveChatAvailable: Ref<boolean>;
  isIntercomOptionsListFlow: Ref<boolean>;
  instagramUrl: Readonly<Ref<string | null | undefined>>;
  whatsappUrl: Readonly<Ref<string | null | undefined>>;
}

export default function useSupport(): useSupportComposable {
  const siteConfigStore = useSiteConfigStore();
  const supportBlock = toRef(siteConfigStore, 'supportBlock');
  const { isLoggedIn } = useIsLoggedIn();
  const supportFaqStore = useSupportFaqStore();
  const faqs = toRef(supportFaqStore, 'faqs');

  // Help modes
  const helpMode = computed(() => supportBlock.value?.helpButtonMode ?? helpButtonModeHelp);
  const isHelpMode = computed(() => helpMode.value === helpButtonModeHelp);
  const isChatMode = computed(() => helpMode.value === helpButtonModeExtChat);

  // Chats types
  const chatType = computed(() => supportBlock.value?.helpChatType);
  const isIntercomAvailable = computed(() => !!process.env.VUE_APP_FEATURE_INTERCOM_ENABLED
      && chatType.value === helpChatTypeIntercom);
  const isLiveChatAvailable = computed(() => !!process.env.VUE_APP_FEATURE_LIVECHAT_ENABLED
      && chatType.value === helpChatTypeLivechat);

  const isIntercomOptionsListFlow = computed(() => isHelpMode.value && isIntercomAvailable.value);

  const callbackEnabled = computed(() => isLoggedIn.value
      && !!supportBlock.value?.callbackEnabled
      && !!supportBlock.value?.isCallCenterWorking);

  const faqEnabled = computed(() => supportBlock.value?.faqEnabled ?? false);

  // Social links
  const instagramUrl = toRef(() => supportBlock.value?.helpInstagramUrl);
  const whatsappUrl = toRef(() => supportBlock.value?.helpWhatsappUrl);

  function categoryByUrlId(urlId: string): FaqV2Category | undefined {
    return faqs.value.find(
      (category) => category.urlId === urlId,
    );
  }

  return {
    categoryByUrlId,
    faqEnabled,
    callbackEnabled,
    isHelpMode,
    isChatMode,
    isIntercomAvailable,
    isLiveChatAvailable,
    isIntercomOptionsListFlow,
    instagramUrl,
    whatsappUrl,
  };
}
