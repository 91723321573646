import { assert, isArray, isObject } from '@leon-hub/guards';
import { FormSubmitMode, FormControlType } from '@leon-hub/form-utils';

import { getSubmitButtonProperties, getUiSchemaField } from 'web/src/components/Form/utils/uiSchema';
import type { FormUiSchema, FormDataMap, FormSchema } from 'web/src/components/Form/types';

import { isFormTypeObject } from './formSchemaUtils';

export const isMultipleFields = (uiSchema: FormUiSchema): boolean => {
  const list = uiSchema.order ? uiSchema.order : Object.keys(uiSchema.fields ?? {});
  if (!list.length) {
    return false;
  }
  const count = list.reduce((accumulator: number, field: string) => {
    const fieldProps = uiSchema?.fields?.[field];
    assert(isObject(fieldProps), `Form: unable to map over uiSchema fields correctly for field: ${field}`);
    // https://jira.leoncorp.net/browse/LEONWEB-1336
    if (fieldProps.hidden || fieldProps.disabled
      || fieldProps.isIgnoredByDisableCounter || fieldProps.widget === FormControlType.Hidden) {
      return accumulator;
    }
    return accumulator + 1;
  }, 0);

  return count > 1;
};

export const getSubmitMode = (uiSchema: FormUiSchema): FormSubmitMode => (isMultipleFields(uiSchema)
  ? FormSubmitMode.AlwaysEnabled
  : FormSubmitMode.DisabledByErrors);

export const isSubmitButtonDisabled = (uiSchema: FormUiSchema): boolean => Boolean(getSubmitButtonProperties(uiSchema)
  .disabled);

export const haveEmptyRequiredFields = (
  schema: FormSchema,
  uiSchema: FormUiSchema,
  formDataMap: FormDataMap,
): boolean => {
  const { required, properties } = schema;
  if (!required || !isArray(required)) {
    return false;
  }
  const flatRequired: string[] = [];
  for (const fieldName of required) {
    const matchedField = properties[fieldName];
    const uiField = getUiSchemaField(uiSchema, fieldName);
    if (!uiField.hidden) {
      if (isFormTypeObject(matchedField) && isArray(matchedField.required)) {
        for (const subfield of matchedField.required) {
          flatRequired.push(`${fieldName}/${subfield}`);
        }
      } else {
        flatRequired.push(fieldName);
      }
    }
  }
  return flatRequired.some((fieldName) => !(formDataMap.get(fieldName)));
};
