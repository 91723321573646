import type { Ref } from 'vue';
import { ref } from 'vue';

import { logger } from '@leon-hub/logging';
import { normalizeError } from '@leon-hub/errors';
import { useLocalStorageManager } from '@leon-hub/local-storage';

import { HeadlineSlideName } from 'web/src/modules/sportline/submodules/event-details/enums';

interface UseActiveSlideStoreComposableComposable {
  defaultSwiperSlideId: Ref<HeadlineSlideName>;
  restoreSwiperSlideId(): void;
  setInitialSwiperSlideId(): void;
  saveSwiperSlideId(slideId: HeadlineSlideName): void;
}

const LocalStorageWidgetPositionKey = 'sport-events-details-widget-position';

export function useActiveSlideStoreComposable(): UseActiveSlideStoreComposableComposable {
  const localStorageManager = useLocalStorageManager();

  const defaultSwiperSlideId = ref(process.env.VUE_APP_LAYOUT_DESKTOP ? HeadlineSlideName.LiveStream : HeadlineSlideName.Info);

  function setSwiperSlideId(value: HeadlineSlideName): void {
    defaultSwiperSlideId.value = value;
  }

  function restoreSwiperSlideId(): void {
    if (!process.env.VUE_APP_LAYOUT_DESKTOP) {
      // @see LEONWEB-14943 do not use saved data on mobile version
      setSwiperSlideId(HeadlineSlideName.Info);
      return;
    }

    try {
      const positionName = localStorageManager.getItem(LocalStorageWidgetPositionKey) as HeadlineSlideName;

      if (positionName) {
        setSwiperSlideId(positionName);
      }
    } catch (rawError) {
      const error = normalizeError(rawError);

      logger.error(error);
    }
  }

  function saveSwiperSlideId(slideId: HeadlineSlideName): void {
    setSwiperSlideId(slideId);

    try {
      if (slideId) {
        localStorageManager.setItem(LocalStorageWidgetPositionKey, slideId);
      } else {
        localStorageManager.removeItem(LocalStorageWidgetPositionKey);
      }
    } catch (rawError) {
      const error = normalizeError(rawError);

      logger.error(error);
    }
  }

  function setInitialSwiperSlideId(): void {
    // @see LEONWEB-14943 reset slide to info after leaving the page
    if (process.env.VUE_APP_LAYOUT_DESKTOP) { return; }
    setSwiperSlideId(HeadlineSlideName.Info);
  }

  return {
    defaultSwiperSlideId,
    restoreSwiperSlideId,
    setInitialSwiperSlideId,
    saveSwiperSlideId,
  };
}
