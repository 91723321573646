import type {
  GetRootSettingsReturnType,
  GetRootSettingsInternationalReturnType,
} from '@leon-hub/api-sdk';
import {
  getRootSettingsInternational,
  getRootSettings,
} from '@leon-hub/api-sdk';

import { useQueryManager } from 'web/src/modules/core/composables';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';

export type RootSettingInternationalReturnValue = Exclude<GetRootSettingsInternationalReturnType, null>;
export type RootSettingReturnValue = Exclude<GetRootSettingsReturnType, null>;
export type RootSettingCommonValue = RootSettingInternationalReturnValue | RootSettingReturnValue;

export function makeRootSettingsRequest(silent?: boolean): Promise<RootSettingCommonValue> {
  const queryManager = useQueryManager();

  const headers = !silent ? queryManager.getRootHeaderParameters() : undefined;

  return doRootSettingsRequest(
    headers,
    silent,
  );
}

export function doRootSettingsRequest(
  headers?: Record<string, string>,
  silent?: boolean,
): Promise<RootSettingCommonValue> {
  const apiClient = useGraphqlClient();

  if (process.env.VUE_APP_FEATURE_MULTIPLE_LOCALES_ENABLED) {
    return getRootSettingsInternational(
      apiClient,
      (node) => node.queries.system.rootSettings,
      { options: {} },
      {
        headers,
        silent,
      },
    );
  }

  return getRootSettings(
    apiClient,
    (node) => node.queries.system.rootSettings,
    { options: {} },
    {
      headers,
      silent,
    },
  );
}
