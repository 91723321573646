import { isString } from '@leon-hub/guards';

import type { DropListSelectProps } from '../types';

export function getDefaultSelectedValue(props: DropListSelectProps): string {
  if (props.isMultiselectMode || !props.selected) {
    return '';
  }
  return isString(props.selected) ? props.selected : '';
}
