import { defineStore } from 'pinia';
import {
  computed,
  ref,
  toRef,
  watch,
} from 'vue';

import {
  doReadCustomerNotification,
  getCustomerNotifications,
  getCustomerNotificationsCount,
  removeCustomerMessage,
  RequestOptionsPriority,
} from '@leon-hub/api-sdk';
import { Timer } from '@leon-hub/utils';
import { useLocalStorageManager } from '@leon-hub/local-storage';
import { RefProgramNotificationType } from '@leon-hub/websocket/src/sdk-ws';

import type {
  CustomerNotificationCheckedMessage,
  CustomerNotificationMessage,
} from 'web/src/modules/customer-notifications/types';
import { useFastTrackStore } from 'web/src/modules/fast-track/store';
import { useFrontNotificationsStore } from 'web/src/modules/front-notifications/store';
import { CustomerNotificationMessageSource } from 'web/src/modules/customer-notifications/enums';
import {
  useDesktopModalStore,
  useSiteConfigStore,
} from 'web/src/modules/core/store';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import promisesInBatch from 'web/src/utils/promisesInBatch';
import DateTime from 'web/src/utils/DateTime';
import { useI18n } from 'web/src/modules/i18n/composables';
import type { RefTrackCustomerNotificationMessage } from 'web/src/modules/customer-notifications/types/CustomerNotificationMessage';

import { CUSTOMER_NOTIFICATIONS_POSTPONED_STORAGE_KEY } from './constants';
import { createMessageFromCms } from './utils';
import type { CustomerNotificationDocument } from './types';

const useCustomerNotificationsStore = defineStore('customer-notifications', () => {
  const localStorageManager = useLocalStorageManager();
  const apiClient = useGraphqlClient();
  const { isLoggedIn } = useIsLoggedIn();
  const { $translate } = useI18n();

  const postponedModalsShowTimeout = toRef(useSiteConfigStore(), 'postponedModalsShowTimeout');

  const fastTrackStore = useFastTrackStore();
  const fastTrackPreparedMessages = toRef(fastTrackStore, 'preparedMessages');
  const fastTrackMessages = toRef(fastTrackStore, 'messages');
  const fastTrackUnreadCounter = toRef(fastTrackStore, 'unreadCounter');

  const frontNotificationsStore = useFrontNotificationsStore();
  const frontPreparedMessages = toRef(frontNotificationsStore, 'preparedCustomerMessages');
  const frontMessages = toRef(frontNotificationsStore, 'onSiteNotifications');
  const frontUnreadCounter = toRef(frontNotificationsStore, 'unreadCustomerMessagesCounter');

  const refUnreadCounter = toRef(frontNotificationsStore, 'unreadRefMessagesCounter');
  const refFrontNotification = toRef(frontNotificationsStore, 'refFrontNotification');

  const desktopModal = toRef(useDesktopModalStore(), 'desktopModal');

  const unreadCounter = ref(0);
  const messages = ref<CustomerNotificationDocument[]>();
  const isPostponedModalsAllowed = ref(false);
  const isEditing = ref(false);
  const isLoading = ref(false);
  const checkedMessages = ref<CustomerNotificationCheckedMessage[]>([]);

  const preparedMessages = computed<CustomerNotificationMessage[] | undefined>(() => (
    !messages.value ? undefined : messages.value.map((message) => createMessageFromCms(message))
  ));

  const refFrontNotificationMessage = computed<RefTrackCustomerNotificationMessage[]>(() => {
    const result: RefTrackCustomerNotificationMessage[] = [];

    refFrontNotification.value?.forEach((item) => {
      const itemType: RefProgramNotificationType = item?.payload.type;

      // eslint-disable-next-line default-case
      switch (itemType) {
        case RefProgramNotificationType.BONUS_ELIGIBILITY_RULES:
          result.push({
            key: 'someKey',
            message: 'someMessage',
            isModal: false,
            ...item,
            title: $translate('WEB2_REFERRAL_PROGRAM_NOTIFICATION_TITLE').value,
            createdAt: DateTime.toTimestamp(item.timestamp || 0),
            source: CustomerNotificationMessageSource.REF,
          });
          break;
        case RefProgramNotificationType.REFERRAL_REWARD_ASSIGNED_REFERRER_NOTIFICATION:
          result.push({
            key: item.id,
            message: $translate('WEB2_REFERRAL_REWARD_ASSIGNED_REFERRER_NOTIFICATION_MESSAGE').value,
            isModal: false,
            ...item,
            title: $translate('WEB2_REFERRAL_REWARD_ASSIGNED_REFERRER_NOTIFICATION_TITLE').value,
            createdAt: DateTime.toTimestamp(item.timestamp || 0),
            source: CustomerNotificationMessageSource.TEXT,
            button: {
              text: $translate('WEB2_REFERRAL_REWARD_ASSIGNED_REFERRER_NOTIFICATION_BUTTON').value,
              link: '/referral-program',
              fullWidth: true,
            },
          });
          break;
      }
    });

    return result;
  });

  const allMessages = computed<CustomerNotificationMessage[] | undefined>(() => (
    !preparedMessages.value
    || !fastTrackPreparedMessages.value
    || !frontPreparedMessages.value
      ? undefined
      : [
        ...preparedMessages.value,
        ...frontPreparedMessages.value,
        ...fastTrackPreparedMessages.value,
        ...refFrontNotificationMessage.value,
      ].sort((a, b) => b.createdAt - a.createdAt)
  ));

  const modalMessages = computed<CustomerNotificationMessage[]>(() => [
    ...(fastTrackPreparedMessages.value ?? []),
    ...(frontPreparedMessages.value ?? []),
    ...(refFrontNotificationMessage.value ?? []),
  ].filter((item) => item.isModal && !item.isRead)
    .sort((a, b) => b.createdAt - a.createdAt));

  const checkedCmsMessages = computed<CustomerNotificationDocument[]>(() => (
    messages.value?.filter(({ id }) => checkedMessages.value.some((item) => (
      item.id === id && item.source === CustomerNotificationMessageSource.CMS
    ))) ?? []
  ));

  const unreadTotalCounter = computed(() => (
    unreadCounter.value + fastTrackUnreadCounter.value + frontUnreadCounter.value + refUnreadCounter.value
  ));

  const isCustomerModalsAllowed = computed(() => (
    !desktopModal.value && isPostponedModalsAllowed.value
  ));

  const latestUnreadMessage = computed(() => (
    allMessages.value?.find((item) => !item.isRead && !item.isModal)
  ));

  function calculateUnreadCount(): void {
    unreadCounter.value = messages.value?.filter((item) => item.status === 0).length || 0;
  }

  function toggleCheck(message: CustomerNotificationCheckedMessage): void {
    const checkedMessage = checkedMessages.value.find(
      (item) => item.id === message.id && item.source === message.source,
    );

    if (checkedMessage) {
      checkedMessages.value = checkedMessages.value.filter((item) => (
        item.id !== message.id || item.source !== message.source
      ));
    } else {
      checkedMessages.value.push(message);
    }
  }

  async function loadMessages(): Promise<void> {
    if (isLoggedIn.value) {
      isLoading.value = true;
      const { allOperatorMessages } = await getCustomerNotifications(apiClient,
        (node) => node.queries.customer.listOperatorMessages,
        { options: {} });
      messages.value = allOperatorMessages;
      calculateUnreadCount();

      if (!allOperatorMessages.length) {
        stopEditing();
      }

      await fastTrackStore.fetchMessages();
      await frontNotificationsStore.getAllCustomerNotifications();
      isLoading.value = false;
    }
  }

  async function loadUnreadCount(silent?: boolean): Promise<void> {
    if (isLoggedIn.value) {
      const response = await getCustomerNotificationsCount(apiClient,
        (node) => node.queries.customer.countUnreadMessages,
        { options: {} },
        {
          silent,
          priority: RequestOptionsPriority.LOW,
        });
      if (response) {
        unreadCounter.value = response.unreadMessagesCount;
      }
    }
  }

  function setStatusRead(id: number | string): void {
    const message = messages.value?.find((item) => item.id === id);
    if (message) {
      message.status = 1;
    }
  }

  function hideModal(): void {
    // eslint-disable-next-line max-len
    localStorageManager.setItem(CUSTOMER_NOTIFICATIONS_POSTPONED_STORAGE_KEY, unreadCounter.value.toString());
  }

  function resetModal(): void {
    localStorageManager.removeItem(CUSTOMER_NOTIFICATIONS_POSTPONED_STORAGE_KEY);
  }

  function recalculateUnreadCounter(): void {
    calculateUnreadCount();
    if (unreadCounter.value > 0) {
      hideModal();
    } else {
      resetModal();
    }
  }

  async function setRead(message: CustomerNotificationMessage): Promise<void> {
    if (message.source === CustomerNotificationMessageSource.CMS) {
      await doReadCustomerNotification(apiClient,
        (node) => node.mutations.customer.updateMessageStatus,
        { options: { messageId: message.id.toString() } });

      setStatusRead(message.id);
    } else if (message.source === CustomerNotificationMessageSource.FAST_TRACK) {
      await fastTrackStore.setMessageAsRead(message);
    } else if (
      message.source === CustomerNotificationMessageSource.ALCR
        || message.source === CustomerNotificationMessageSource.REF || message.source === CustomerNotificationMessageSource.TEXT
    ) {
      await frontNotificationsStore.markNotification({
        id: message.id,
      });
    }

    recalculateUnreadCounter();
  }

  function setClicked(message: CustomerNotificationMessage): void {
    if (message.source === CustomerNotificationMessageSource.ALCR) {
      void frontNotificationsStore.markAsClicked({
        id: message.id,
      });
    }
  }

  async function remove(id: number): Promise<ReturnType<typeof removeCustomerMessage>> {
    return removeCustomerMessage(
      apiClient,
      (node) => node.mutations.customer.messages.deleteMessage,
      {
        options: {
          messageId: id,
        },
      },
    );
  }

  async function deleteMultiple<T extends CustomerNotificationDocument>(
    options: { messages: T[] | undefined; isAllDeleting?: boolean },
  ): Promise<CustomerNotificationCheckedMessage[]> {
    const failedMessages: T[] = [];
    if (options.messages?.length) {
      let result: CustomerNotificationDocument[] = [];
      if (!options.isAllDeleting) {
        const currentMessages = messages.value;
        if (currentMessages?.length) {
          const removableIds = options.messages.map(({ id }) => id);
          result = currentMessages.filter(({ id }) => !removableIds.includes(id));
        }
      }
      messages.value = result;
      recalculateUnreadCounter();

      await promisesInBatch(options.messages, (message) => remove(message.id).catch(() => {
        failedMessages.push(message);
      }));

      if (failedMessages.length > 0) {
        messages.value = options.isAllDeleting
          ? failedMessages
          : [...(messages.value || []), ...failedMessages];

        recalculateUnreadCounter();
      }
    }

    return failedMessages.map((item) => ({
      id: item.id,
      source: CustomerNotificationMessageSource.CMS,
    }));
  }

  async function deleteChecked(): Promise<CustomerNotificationCheckedMessage[]> {
    const fastTrackCheckedMessages = fastTrackMessages.value
      ?.filter(({ MessageId }) => checkedMessages.value.some((item) => (
        item.id === MessageId && item.source === CustomerNotificationMessageSource.FAST_TRACK
      ))) ?? [];

    const alcrCheckedMessages = frontMessages.value
      ?.filter(({ id }) => checkedMessages.value.some((item) => (
        item.id === id && item.source === CustomerNotificationMessageSource.ALCR
      ))) ?? [];

    const refCheckedMessages = refFrontNotification.value
      ?.filter(({ id }) => checkedMessages.value.some((item) => item.id === id)) ?? [];

    const innerMessages = [...checkedCmsMessages.value];

    checkedMessages.value = [];

    const results = await Promise.allSettled([
      fastTrackStore.deleteMultiple({ messagesArray: fastTrackCheckedMessages }),
      frontNotificationsStore.deleteMultiple({ messages: alcrCheckedMessages }),
      frontNotificationsStore.deleteMultiple({ messages: refCheckedMessages }),
      deleteMultiple({ messages: innerMessages }),
    ]);

    return results.flatMap((v) => (
      v.status === 'fulfilled'
        ? v.value
        : []
    ));
  }

  async function deleteAll(): Promise<CustomerNotificationCheckedMessage[]> {
    const results = await Promise.allSettled([
      fastTrackStore.deleteMultiple({
        messagesArray: fastTrackMessages.value,
        isAllDeleting: true,
      }),
      frontNotificationsStore.deleteMultiple({
        messages: frontMessages.value,
      }),
      frontNotificationsStore.deleteMultiple({
        messages: refFrontNotification.value,
      }),
      deleteMultiple({
        messages: messages.value,
        isAllDeleting: true,
      }),
    ]);

    // eslint-disable-next-line sonarjs/no-identical-functions
    return results.flatMap((v) => (
      v.status === 'fulfilled'
        ? v.value
        : []
    ));
  }

  function stopEditing(): void {
    isEditing.value = false;
  }

  function getPostponedUnreadCounter(): number {
    return Number(localStorageManager.getItem(CUSTOMER_NOTIFICATIONS_POSTPONED_STORAGE_KEY) || 0);
  }

  let postponedModalsTimeout = 0;
  function initPostponedModalsShow(): void {
    if (postponedModalsTimeout) {
      Timer.clearTimeout(postponedModalsTimeout);
      postponedModalsTimeout = 0;
    }

    if (isLoggedIn.value) {
      postponedModalsTimeout = Timer.setTimeout(() => {
        isPostponedModalsAllowed.value = true;
        postponedModalsTimeout = 0;
      }, postponedModalsShowTimeout.value);
    } else {
      isPostponedModalsAllowed.value = false;
    }
  }

  function setEditing(value: boolean): void {
    isEditing.value = value;
  }

  function setChecked(message: CustomerNotificationCheckedMessage): void {
    checkedMessages.value = [message];
  }

  function clearChecked(): void {
    checkedMessages.value = [];
  }

  watch(isLoggedIn, (newValue) => {
    if (newValue) {
      void loadUnreadCount();
      resetModal();
      initPostponedModalsShow();
    } else {
      resetModal();
      initPostponedModalsShow();
      unreadCounter.value = 0;
    }
  }, {
    immediate: true,
  });

  return {
    isEditing,
    allMessages,
    modalMessages,
    unreadTotalCounter,
    unreadCounter,
    isCustomerModalsAllowed,
    latestUnreadMessage,
    checkedMessages,
    refFrontNotificationMessage,
    isLoading,
    setClicked,
    setRead,
    getPostponedUnreadCounter,
    hideModal,
    loadMessages,
    setEditing,
    stopEditing,
    deleteAll,
    deleteChecked,
    toggleCheck,
    setChecked,
    clearChecked,
  };
});

export default useCustomerNotificationsStore;
