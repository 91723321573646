import { getCordovaAppConfig } from '@leon-hub/cordova';

export default function resolveApi1Url(baseUrl = ''): string | undefined {
  if (process.env.NODE_ENV === 'test') return '';
  if (process.env.VUE_APP_PLATFORM_CORDOVA) {
    const { appOrigin, api1PublicUrl } = getCordovaAppConfig();
    return `${baseUrl || appOrigin}${api1PublicUrl}`;
  }

  if (process.env.VUE_APP_RENDERING_SSR) {
    return process.env.VUE_APP_API_1_INTERNAL_URL;
  }

  return process.env.VUE_APP_API_1_PUBLIC_URL;
}
