import type { Ref } from 'vue';

import { useSyncSportline } from 'web/src/modules/sportline/submodules/sync-sportline';
import useBackgroundRequestsLifeCycle from 'web/src/utils/store/composables/useBackgroundRequestsLifeCycle';
import useCustomerFavoritesService from 'web/src/modules/sportline/submodules/favorites/composables/useCustomerFavoritesService';
import type { CoreSportlineFetchOptions } from 'web/src/modules/sportline/types/rest';
import { CustomerFavoritesIdsBackgroundUpdateKey } from 'web/src/modules/sportline/submodules/favorites/enums';
import { BackgroundUpdateStopwatch } from 'web/src/utils/store';

interface SportlineSidebarSyncBackgroundRequestsProps {
  updateInterval: Ref<number>;
  reloadList(silent?: boolean): Promise<void>;
}

export interface SportlineSidebarSyncBackgroundRequestsComposable {
  onInit(): void;
  setBackgroundUpdateEnabled(value: boolean): Promise<void>;
}

export function useSportlineSidebarSyncBackgroundRequestsComposable(
  props: SportlineSidebarSyncBackgroundRequestsProps,
): SportlineSidebarSyncBackgroundRequestsComposable {
  const customerFavoritesService = useCustomerFavoritesService();

  const lastUpdate = new BackgroundUpdateStopwatch<['sportsList']>({
    sportsList: async ({ silent }: CoreSportlineFetchOptions) => {
      await props.reloadList(silent);
      lastUpdate.update('sportsList');
    },
  });

  const {
    initialRequests,
    syncState,
    setBackgroundUpdateEnabled,
  } = useBackgroundRequestsLifeCycle({
    lastUpdate,
    updateInterval: props.updateInterval,
    onEnableChanged(value: boolean): void {
      void customerFavoritesService.setIsBackgroundIdsUpdateAvailable(
        CustomerFavoritesIdsBackgroundUpdateKey.SIDEBAR,
        value,
      );
    },
  });

  function onInit(): void {
    void initialRequests();
    useSyncSportline((silent) => syncState({ silent }), props.updateInterval);
  }

  return {
    onInit,
    setBackgroundUpdateEnabled,
  };
}
