import type { LeagueRouteLocationParameters } from 'web/src/modules/sportline/submodules/navigation/store/types';
import type { League } from 'web/src/modules/sportline/types';
import type {
  BuildSportOptions,
  CoreLeagueResponse,
  CoreRegionResponse,
  CoreSportResponse,
  RawLeague,
} from 'web/src/modules/sportline/types/rest';
import {
  buildLeagueNavigationParameters,
} from 'web/src/modules/sportline/utils/rest/navigation-parameters';

export default function parseLeagueResponse(
  options: BuildSportOptions & {
    sportResponse: CoreSportResponse;
    regionResponse: CoreRegionResponse;
    leagueResponse: CoreLeagueResponse;
  },
): RawLeague {
  const {
    sportResponse,
    leagueResponse,
  } = options;
  const id = String(leagueResponse.id);
  const navigationParameters: LeagueRouteLocationParameters = buildLeagueNavigationParameters(options);
  const league: League = {
    id,
    key: `${sportResponse.id}_${leagueResponse.id}`,
    name: leagueResponse.name,
    top: leagueResponse.top ?? false,
    topOrder: leagueResponse.topOrder,
    weight: leagueResponse.weight,
    representation: {
      logo: leagueResponse.logoUrl,
      logoSource: leagueResponse.logoSource,
      background: leagueResponse.background?.src,
    },
    navigationParameters,
    hasZeroMargin: options.canUseZeroMargin && (leagueResponse.hasZeroMarginEvents ?? false),
  };

  return {
    league,
  };
}
