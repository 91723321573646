import type { DisplayedTimeLeft, ValueOfTimeLeft } from 'web/src/utils/countdown';
import type { I18nFunctionReactive } from 'web/src/modules/i18n/types';

export default function getMinutes(
  timeLeft: ValueOfTimeLeft | null,
  displayedTimeLeft: DisplayedTimeLeft | null,
  isShortNames: boolean | undefined,
  $t: I18nFunctionReactive,
): string {
  if (!timeLeft || !displayedTimeLeft) {
    return '';
  }

  const {
    days,
    hours,
    minutes,
  } = timeLeft;

  if (days <= 0
    && hours <= 0
    && minutes <= 0) {
    return '';
  }

  const text = (isShortNames)
    ? $t('WEB2_MINUTES_EXTRA_SHORT').value
    : $t('WEB2_MINUTES_SHORT').value;

  return `${String(minutes)} ${text}.`;
}
