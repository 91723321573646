<script setup lang="ts">
import { IconName } from '@leon-hub/icons';

import { VIcon } from '@components/v-icon';

import InputHint from '../InputHint';
import {
  dataTestLabel as vDataTestLabel,
} from '../../directives';
import type { InputWrapperProps, InputWrapperSlots } from '../../types';
import { useHintParentProps } from '../../composables';
import getInputIconSize from '../../utils/getInputIconSize';

const props = withDefaults(defineProps<InputWrapperProps>(), {
  inputId: '',
  label: '',
});

const {
  hintProperties,
} = useHintParentProps(props);

// ok not to be reactive
const iconSize = getInputIconSize(props.isLarge);

defineSlots<InputWrapperSlots>();
</script>

<template>
  <div v-auto-id="'InputWrapper'"
    :class="{
      [$style['input-wrapper']]: true,
      [$style['input-wrapper--empty']]: isEmpty,
      [$style['input-wrapper--error']]: !!error || !!errorRight,
      [$style['input-wrapper--focus']]: isFocus,
      [$style['input-wrapper--disabled']]: disabled,
      [$style['input-wrapper--large']]: isLarge,
    }"
  >
    <slot name="label">
      <label
        v-if="inputId && !isLabelHidden"
        v-data-test-label
        :for="inputId"
        :class="$style['input-wrapper__label']"
        @mousedown.prevent
      >
        {{ label }}
      </label>
    </slot>
    <div :class="$style['input-wrapper__field-container']">
      <div
        :class="{
          [$style['input-wrapper__icon']]: true,
          [$style['input-wrapper__icon--prefix']]: true,
          [$style['input-wrapper__icon--inert']]: !clickablePrefix,
        }"
      >
        <slot name="iconPrefix" />
      </div>
      <div :class="$style['input-wrapper__field']">
        <slot name="input" />
      </div>
      <div
        :class="{
          [$style['input-wrapper__icon']]: true,
          [$style['input-wrapper__icon--suffix']]: true,
          [$style['input-wrapper__icon--inert']]: !clickableSuffix,
        }"
      >
        <VIcon
          v-if="disabled"
          :size="iconSize"
          :name="null ? IconName.SLOTT_LOCK : IconName.LOCK"
          :class="$style['input-wrapper__lock']"
        />
        <slot
          v-else
          name="iconSuffix"
        />
      </div>
    </div>
    <InputHint
      v-if="!isHintHidden"
      v-bind="hintProperties"
    >
      <slot name="hint" />
      <template #hintLeft>
        <slot name="hintLeft" />
      </template>
      <template #hintRight>
        <slot name="hintRight" />
      </template>
    </InputHint>
    <slot name="dropdown" />
  </div>
</template>

<style lang="scss" module>
.input-wrapper {
  @import '../../common';

  $self: &;

  @mixin setIconColors($prefixColor, $suffixColor) {
    #{$self}__icon {
      &--prefix {
        color: $prefixColor;
      }

      &--suffix {
        color: $suffixColor;
      }
    }
  }

  // https://jira.leoncorp.net/browse/LEONWEB-7388
  @include setIconColors($inputValueDefaultColor, $inputPlaceholderDefaultColor);

  position: relative;
  display: flex;
  flex-flow: column wrap;
  flex-grow: 1;

  &:last-child {
    margin-bottom: 0;
  }

  &--disabled {
    @include setIconColors($inputValueDisabledColor, var(--Highlight));
  }

  &--error:not(&--focus) {
    @include setIconColors($inputPlaceholderErrorColor, $inputPlaceholderErrorColor);
  }

  &--empty:not(&--error, &--focus, &--disabled) {
    @include setIconColors($inputPlaceholderDefaultColor, $inputPlaceholderDefaultColor);
  }

  &__field-container {
    position: relative;
    width: 100%;
  }

  &__field {
    @include nullTransformHack;
  }

  &__label {
    @include inputLabel;
  }

  &__icon {
    @include z-index(absolute-item); // for fix issue with input type file.

    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: $inputIconWidth;
    height: $inputHeight;

    &:empty {
      display: none;
    }

    &--suffix {
      right: 0;
    }

    &--prefix {
      left: 0;
    }

    &--inert {
      pointer-events: none;
    }
  }

  &--large &__icon {
    min-width: $inputIconWidthLarge;
    height: $inputHeightLarge;
  }

  &__lock {
    color: $inputLockIconColor;
  }
}
</style>
