<script setup lang="ts">
import { computed, ref } from 'vue';

import ModalPortal from 'web/src/components/Modal/ModalPortal/ModalPortal.vue';
import type {
  PaymentsModalsControllerEmits,
  PaymentsModalsControllerProps,
} from 'web/src/modules/payments/components/PaymnetsModalsController/types';
import { ModalSelector } from 'web/src/modules/core/enums';
import PaymentsDepositsOtherSuccessModal from 'web/src/modules/payments/components/PaymentsDepositsOtherSuccessModal/PaymentsDepositsOtherSuccessModal.vue';
import PaymentsWithdrawalCancelSuccessModal from 'web/src/modules/payments/components/PaymentsWithdrawalCancelSuccessModal/PaymentsWithdrawalCancelSuccessModal.vue';
import PaymentsWithdrawalCancelModal from 'web/src/modules/payments/components/PaymentsWithdrawalCancelModal/PaymentsWithdrawalCancelModal.vue';
import { PaymentsModalType } from 'web/src/modules/payments/enums';

const props = withDefaults(defineProps<PaymentsModalsControllerProps>(), {
  modalText: '',
  secondaryText: '',
});
const emit = defineEmits<PaymentsModalsControllerEmits>();

const isModalShown = ref(true);

const modalSelector = computed(() => (('1' && props.isInProfile)
  ? ModalSelector.DESKTOP_INNER_MODAL
  : ModalSelector.BODY));

const modalComponentName = computed(() => {
  switch (props.modalType) {
    case PaymentsModalType.DEPOSITS_OTHER_SUCCESS_MODAL:
      return PaymentsDepositsOtherSuccessModal;
    case PaymentsModalType.WITHDRAWAL_CANCEL_SUCCESS_MODAL:
      return PaymentsWithdrawalCancelSuccessModal;
    case PaymentsModalType.WITHDRAWAL_CANCEL_MODAL:
      return PaymentsWithdrawalCancelModal;
    default: break;
  }
  return '';
});
function onModalClose(): void {
  isModalShown.value = false;
  emit('close');
}
</script>

<template>
  <ModalPortal v-auto-id="'PaymentsModalsController'"
    v-if="modalType"
    :selector="modalSelector"
  >
    <component
      :is="modalComponentName"
      v-if="isModalShown"
      :text="modalText"
      :secondary-text="secondaryText"
      :is-in-profile="isInProfile"
      @close="onModalClose"
      @confirm-click="emit('confirm-click')"
    />
  </ModalPortal>
</template>
