import type { Ref } from 'vue';
import type { RouteLocationRaw } from 'vue-router';
import { computed } from 'vue';
import { useRouter } from 'vue-router';

import { useSeoMetaParameters } from 'web/src/modules/seo/composables';
import type { SeoMetaParametersConfig } from 'web/src/modules/seo/types';
import type { SportEventsNavigationSeoMetaInfo } from 'web/src/modules/sportline/submodules/navigation/store/types';
import type {
  SportsTreeSportElement,
  SportsTreeRegionElement,
  SportsTreeLeagueElement,
} from 'web/src/modules/sportline/types/rest';

interface UseSportlineBreadcrumbsMetaParametersProps {
  sportElement: Ref<Maybe<SportsTreeSportElement>>;
  sportPageLocation: Ref<Optional<RouteLocationRaw>>;
  regionElement: Ref<Maybe<SportsTreeRegionElement>>;
  regionPageLocation: Ref<Optional<RouteLocationRaw>>;
  leagueElement: Ref<Maybe<SportsTreeLeagueElement>>;
  leaguePageLocation: Ref<Optional<RouteLocationRaw>>;
}

export function useSportlineBreadcrumbsMetaParameters(
  props: UseSportlineBreadcrumbsMetaParametersProps,
): void {
  const {
    sportElement,
    sportPageLocation,
    regionElement,
    regionPageLocation,
    leagueElement,
    leaguePageLocation,
  } = props;

  const router = useRouter();

  const metaParameters = computed<SportEventsNavigationSeoMetaInfo | {}>(() => ({
    sport: sportElement.value?.sport.name,
    sportEmoji: sportElement.value?.sport?.representation.emoji,
    sportUrl: sportPageLocation.value ? router.getHref(sportPageLocation.value).absolute : undefined,
    region: regionElement.value?.region.name,
    regionUrl: regionPageLocation.value ? router.getHref(regionPageLocation.value).absolute : undefined,
    league: leagueElement.value?.league.name,
    leagueUrl: leaguePageLocation.value ? router.getHref(leaguePageLocation.value).absolute : undefined,
  }));

  useSeoMetaParameters(metaParameters as Ref<SeoMetaParametersConfig>);
}
