import type { Ref } from 'vue';
import { computed } from 'vue';

import { getSafePercentage } from 'web/src/modules/core/utils/math';

interface UseChartBarWidthProps {
  toFixed: Ref<Maybe<number>>;
  homePercentage: Ref<number>;
  drawPercentage: Ref<number>;
  awayPercentage: Ref<number>;
}

interface UseChartBarWidthComposable {
  isEmpty: Ref<boolean>;
  homeChartWidth: Ref<number>;
  drawsChartWidth: Ref<number>;
  awayChartWidth: Ref<number>;
  homePercentageLabel: Ref<Maybe<string>>;
  drawPercentageLabel: Ref<Maybe<string>>;
  awayPercentageLabel: Ref<Maybe<string>>;
}

function toFixedPercentage(value: Ref<number>, toFixed: Ref<Maybe<number>>): string {
  return `${toFixed.value ? value.value.toFixed(toFixed.value) : value.value}%`;
}

export function useChartBarWidth(
  props: UseChartBarWidthProps,
): UseChartBarWidthComposable {
  const {
    toFixed,
    homePercentage,
    drawPercentage,
    awayPercentage,
  } = props;

  const isEmpty = computed<boolean>(() => (
    !homePercentage.value
    && !drawPercentage.value
    && !awayPercentage.value
  ));
  // Do use relative width in case of the total sum less than 100%
  const totalPercentage = computed<number>(
    () => homePercentage.value + drawPercentage.value + awayPercentage.value,
  );
  const homeChartWidth = computed<number>(
    () => (isEmpty.value ? 50 : getSafePercentage(homePercentage.value, totalPercentage.value)),
  );
  const drawsChartWidth = computed<number>(
    () => getSafePercentage(drawPercentage.value, totalPercentage.value),
  );
  const awayChartWidth = computed<number>(
    () => (isEmpty.value ? 50 : getSafePercentage(awayPercentage.value, totalPercentage.value)),
  );
  const homePercentageLabel = computed<Maybe<string>>(
    () => (homePercentage.value && homePercentage.value > 0
      ? toFixedPercentage(homePercentage, toFixed)
      : null),
  );
  const drawPercentageLabel = computed<Maybe<string>>(
    () => (drawPercentage.value && drawPercentage.value > 0
      ? toFixedPercentage(drawPercentage, toFixed)
      : null),
  );
  const awayPercentageLabel = computed<Maybe<string>>(
    () => (awayPercentage.value && awayPercentage.value > 0
      ? toFixedPercentage(awayPercentage, toFixed)
      : null),
  );

  return {
    isEmpty,
    homeChartWidth,
    drawsChartWidth,
    awayChartWidth,
    homePercentageLabel,
    drawPercentageLabel,
    awayPercentageLabel,
  };
}
