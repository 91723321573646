import type { ComputedRef, Slots } from 'vue';
import { computed } from 'vue';

import { assert } from '@leon-hub/guards';

import { InputEventType } from 'web/src/components/Input/enums';

import type { VSwitchProps, VSwitchEmits } from '../types';

export interface VSwitchComposable {
  showLabel: ComputedRef<boolean>;
  onClick: (event: Event) => void;
  onChange: (event: Event) => void;
  onInput: (event: Event) => void;
  emitFocus: () => void;
  emitBlur: () => void;
  onTransitionEnd: () => void;
}

export default function useVSwitch(props: VSwitchProps, emit: VSwitchEmits, slots: Slots): VSwitchComposable {
  const showLabel = computed(() => Boolean(props.label || slots?.default));

  function onClick(event: Event) {
    if (props.readonly || props.disabled) {
      event.preventDefault();
    }
    emit('click', event);
  }

  function onChange(event: Event) {
    assert(event.target instanceof HTMLInputElement, 'event.target should be HTMLInputElement');
    const { checked } = event.target;
    emit(InputEventType.CHANGE, checked);
  }

  function onInput(event: Event) {
    assert(event.target instanceof HTMLInputElement, 'event.target should be HTMLInputElement');
    const { checked } = event.target;
    emit(InputEventType.INPUT, checked);
  }

  function emitFocus() {
    emit(InputEventType.FOCUS, !!props.checked);
  }

  function emitBlur() {
    emit(InputEventType.BLUR, !!props.checked);
  }

  function onTransitionEnd() {
    emit('animation-completed', !!props.checked);
  }

  return {
    showLabel,
    emitFocus,
    emitBlur,
    onClick,
    onChange,
    onInput,
    onTransitionEnd,
  };
}
