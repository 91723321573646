import type { NavigationFailure } from 'vue-router';

import type { BaseRouteNameType } from '@leon-hub/routing-config-names';

import type { AppVueRouter } from 'web/src/modules/core/services/router/types';

function createAppRouterInterface(router: AppVueRouter): Readonly<AppRouterInterface> {
  return Object.freeze<AppRouterInterface>({
    push(route: BaseRouteNameType, parameters: Record<string, string>): Promise<NavigationFailure | void | undefined> {
      return new Promise((resolve, reject) => {
        router.push({
          name: route,
          params: parameters,
        })
          .then(resolve)
          .catch(reject);
      });
    },
    replace(route: BaseRouteNameType, parameters: Record<string, string>) {
      return new Promise((resolve, reject) => {
        router.replace({
          name: route,
          params: parameters,
        })
          .then(resolve)
          .catch(reject);
      });
    },
    back(): void {
      router.back();
    },
    forward(): void {
      router.forward();
    },
  });
}

export default function initAppRouterInterface(router: AppVueRouter): void {
  window.mainApp.router = createAppRouterInterface(router);
}
