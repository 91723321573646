import { MarketStatus } from '@leon-hub/api-sdk';

import type { SlipEntry } from '../types';

export default function isEntriesHasLimitStatus(entries?: SlipEntry[]): boolean {
  if (!entries || !Array.isArray(entries)) {
    return false;
  }
  return entries
    .some((entry) => entry?.marketStatus === MarketStatus.LIMIT);
}
