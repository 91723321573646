import { AppError } from '@leon-hub/app-errors';

import { BetlineFlag, BetlineType } from 'web/src/modules/sportline/enums/rest';
import { SportlineRestErrorCode } from 'web/src/modules/sportline/errors/rest';
import type {
  CoreSportEventResponse,
  CoreSportlineFetchOptions,
} from 'web/src/modules/sportline/types/rest';
import type { UseSportlineApiLoadEventsProps } from 'web/src/modules/sportline/services/composables/types';
import { getEvent } from 'web/src/modules/sportline/utils/api';
import { useErrorsConverter } from 'web/src/modules/errors/composables';

interface UseSportlineApiLoadEventDetailsComposable {
  getEventDetails({ id, silent }: CoreSportlineFetchOptions & {
    id: string;
  }): Promise<Maybe<CoreSportEventResponse>>;
}

export function useSportlineApiLoadEventDetails(
  props: UseSportlineApiLoadEventsProps,
): UseSportlineApiLoadEventDetailsComposable {
  const {
    tryRequestWithCTag,
    defaultRestApiFlags,
    isLiveOutrightsEnabled,
  } = props;

  const errorConverter = useErrorsConverter();

  async function getEventDetails({ id, silent }: CoreSportlineFetchOptions & {
    id: string;
  }): Promise<Maybe<CoreSportEventResponse>> {
    try {
      if (process.env.NODE_ENV === 'development') {
        const isMissingEvent = (window as unknown as { missing?: boolean }).missing;

        if (isMissingEvent) {
          // eslint-disable-next-line no-console
          console.log('Enabled local override getEvent: missing event');
          throw new AppError({ code: SportlineRestErrorCode.MISSING_EVENT });
        }
      }

      return await tryRequestWithCTag((ctag) => getEvent({
        eventId: id,
        betline: BetlineType.All,
        ctag,
        silent,
        flags: [
          ...defaultRestApiFlags.value,
          BetlineFlag.WithSportMarketGroupsV2,
          BetlineFlag.OutrightExtendedMarketLayout,
        ],
        LMCE: isLiveOutrightsEnabled.value,
      }));
    } catch (rawError) {
      const error = errorConverter.convertToBaseError(rawError);

      if (error.code.equals(SportlineRestErrorCode.MISSING_EVENT)) {
        return null;
      }

      throw rawError;
    }
  }

  return {
    getEventDetails,
  };
}
