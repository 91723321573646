import type { SharedSlipEntry } from '@leon-hub/api-sdk';
import { assert, isString } from '@leon-hub/guards';

import type { BetInfoItem, CompetitorsTuple } from 'web/src/modules/slip/submodules/slip-info/types';
import isCompetitorsTuple from 'web/src/modules/slip/guards/isCompetitorsTuple';

export function getBetsInfoFromSharedSlipEntry(input: SharedSlipEntry): BetInfoItem {
  const haveValidCompetitors = isCompetitorsTuple(input.competitors);
  const getCompetitors = (): CompetitorsTuple | undefined => {
    if (haveValidCompetitors) {
      assert(isCompetitorsTuple(input.competitors), 'getBetsInfoFromSharedSlipEntry: Unexpected competitors value');
      return input.competitors;
    }
    return undefined;
  };
  const getEventName = (): string | undefined => {
    if (haveValidCompetitors) {
      return undefined;
    }
    assert(isString(input.eventName), 'getBetsInfoFromSharedSlipEntry: unexpected eventName value');
    return input.eventName;
  };
  return {
    originalOdds: input.odds,
    originalOddsStr: input.oddsStr ?? undefined,
    competitors: getCompetitors(),
    eventName: getEventName(),
    // will be null at runner/market names for closed market status
    runnerName: input.runnerName ?? '-',
    marketName: input.marketName ?? '-',
    isPrimaryMarket: input.primaryMarket ?? undefined,
  };
}
