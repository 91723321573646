import type { Ref } from 'vue';
import {
  computed, nextTick, onMounted, onUpdated,
} from 'vue';

import type { LeaderBoardParticipantProps, LeaderBoardParticipantEmits } from '../types';

export interface LeaderBoardParticipantComposable {
  score: Ref<string>;
}

export default function useLeaderBoardParticipant(
  props: LeaderBoardParticipantProps,
  emit: LeaderBoardParticipantEmits,
): LeaderBoardParticipantComposable {
  const score = computed(() => (props.participant?.score || 0).toFixed(2).toString());

  onMounted(async () => {
    await nextTick();
    emit('mounted');
  });

  onUpdated(() => {
    emit('mounted');
  });

  return {
    score,
  };
}
