<script lang="ts" setup>
import { HistoryFilter } from '@leon-hub/api-sdk';

import VSwiperSlide from 'web/src/components/Swiper/VSwiperSlide/VSwiperSlide.vue';
import VSwiper from 'web/src/components/Swiper/VSwiper/VSwiper';
import VTabsButton from 'web/src/components/Tabs/VTabsButton/VTabsButton.vue';
import VSwiperNavigationButtons
  from 'web/src/modules/banners/components/VSwiperBannerNavigation/VSwiperBannerNavigationButtons.vue';
import STabsButton from 'web/src/components/Tabs/STabsButton/STabsButton.vue';

import type { CustomerHistoryFilterTabsProps, CustomerHistoryFilterTabsEmits } from './types';
import { useCustomerHistoryFilterTabs } from './composables';

const props = withDefaults(defineProps<CustomerHistoryFilterTabsProps>(), {
  selectedHistoryFilter: HistoryFilter.ALL,
});
const emit = defineEmits<CustomerHistoryFilterTabsEmits>();

const {
  swiper,
  tabs,
  slideToActive,
} = useCustomerHistoryFilterTabs(props);
</script>

<template>
  <div v-auto-id="'CustomerHistoryFilterTabs'"
    :class="{[$style['history-filter-tabs__wrapper']]: null}"
  >
    <VSwiper
      ref="swiper"
      :class="$style['history-filter-tabs']"
      :wrapper-class="{
        [$style['history-filter-tabs__with-gap']]: true,
        [$style['history-filter-tabs__swiper']]: null,
      }"
      is-scroll-snap-enabled
      @vue:mounted="slideToActive"
    >
      <VSwiperSlide
        v-for="item in tabs"
        :key="item.id"
      >
        <STabsButton
          v-if="null"
          :id="item.id"
          kind="tertiary"
          size="large"
          :active="item.id === selectedHistoryFilter"
          is-bordered
          @click="emit('tab-click', item.id)"
        >
          {{ item.label }}
        </STabsButton>
        <VTabsButton
          v-else
          :id="item.id"
          :active="item.id === selectedHistoryFilter"
          is-bordered
          @click="emit('tab-click', item.id)"
        >
          {{ item.label }}
        </VTabsButton>
      </VSwiperSlide>
      <template
        v-if="!null && '1'"
        #pagination-footer
      >
        <VSwiperNavigationButtons button-kind="base" />
      </template>
    </VSwiper>
  </div>
</template>

<style lang="scss" module>
.history-filter-tabs {
  position: relative;

  &__with-gap {
    gap: $historyTabsFiltersGap;
  }

  &__wrapper {
    padding: 0 0 16px;
    background-color: var(--ColorBackground3);
  }

  &__swiper {
    padding: 0 16px;
  }

  @include is-app-layout-desktop {
    top: auto;
    background-color: transparent;
  }
}
</style>
