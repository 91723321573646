import type { Ref } from 'vue';
import { computed } from 'vue';

import type { SportEventDetails } from 'web/src/modules/sportline/submodules/event-details/types';
import { useSportBackgroundColor } from 'web/src/modules/sportline/composables/sport';

interface UseSportlineEventSportProps {
  sportlineEvent: Ref<Maybe<SportEventDetails> | undefined>;
}

export interface SportEventDetailsSportBaseComposable {
  sport: Ref<Maybe<SportEventDetails['sport']> | undefined>;
  backgroundColor: Ref<Maybe<string>>;
  isDefaultSport: Ref<boolean>;
}

/*
 * Extract sport from SportEventDetails object
 */
export function useSportlineEventSport(
  props: UseSportlineEventSportProps,
): SportEventDetailsSportBaseComposable {
  const sport = computed(() => props.sportlineEvent.value?.sport);
  const { backgroundColor } = useSportBackgroundColor({
    sport,
  });

  const isDefaultSport = computed(() => !backgroundColor.value);

  return {
    sport,
    backgroundColor,
    isDefaultSport,
  };
}
