import { toRef } from 'vue';

import { BusEvent, useEventsBus } from '@leon-hub/event-bus';

import useSportlineCacheStorage from 'web/src/modules/sportline/store/useSportlineCacheStorage';
import { useGlobalSportlineEvents } from 'web/src/modules/sportline/composables/core';
import type {
  GetSportEventsResponse,
  GetSportEventsChangesResponse,
} from 'web/src/modules/sportline/types/rest';
import type {
  BroadcastListElement,
  BroadcastRunnerChangedPayload,
  BroadcastStatusChangedPayload,
} from 'web/src/modules/sportline/types/sportline-broadcast';
import {
  getBroadcastListElementFromCache,
} from 'web/src/modules/sportline/utils/broadcast-selected';
import {
  convertSportEventStatusToMarketStatus,
} from 'web/src/modules/sportline/utils';

export interface UseBroadcastSelectedComposable {
  removeEventsDataInStorage(eventIds: string[]): void;
  updateDataInStorageByResponse(data: {
    response: Maybe<GetSportEventsResponse | GetSportEventsChangesResponse> | false;
  }): void;
}

export function useBroadcastSelected(): UseBroadcastSelectedComposable {
  const bus = useEventsBus();
  const cacheStore = useSportlineCacheStorage();
  const {
    updateDataInStorageByResponse: updateDataInCacheStorageByResponse,
    getSportEventCache,
    getMarketCache,
    clearInvalidCache,
    invalidateMarketCache,
    invalidateEventCache,
  } = cacheStore;
  const globalSportlineEvents = useGlobalSportlineEvents();
  const broadcastEventInfoSubscriptions = toRef(globalSportlineEvents, 'broadcastEventInfoSubscriptions');

  /**
   * Prepare list before update state
   */
  function prepareBroadcastList(): BroadcastListElement[] {
    const broadcastSubscriptions = broadcastEventInfoSubscriptions.value;
    const result: BroadcastListElement[] = [];

    for (const { key } of broadcastSubscriptions) {
      const eventCache = getSportEventCache(String(key.event));
      const eventMarketsCache = getMarketCache(String(key.event));
      const broadcast = getBroadcastListElementFromCache(key, eventCache?.value, eventMarketsCache?.value);
      if (broadcast) { result.push(broadcast); }
    }

    return result;
  }

  function checkBroadcastChanges(broadcastList: BroadcastListElement[]): void {
    const statusChangedList: BroadcastStatusChangedPayload[] = [];
    const runnersChangedList: BroadcastRunnerChangedPayload[] = [];

    for (const oldBroadcast of broadcastList) {
      const { key } = oldBroadcast;
      const eventCache = getSportEventCache(String(key.event));
      const eventMarketsCache = getMarketCache(String(key.event));
      const broadcast = getBroadcastListElementFromCache(key, eventCache?.value, eventMarketsCache?.value);

      if (!broadcast) { continue; }

      if (oldBroadcast.runner.status !== broadcast.runner.status) {
        statusChangedList.push({
          event: broadcast.key.event,
          market: broadcast.key.market,
          runner: broadcast.key.runner,
          marketStatus: convertSportEventStatusToMarketStatus(broadcast.runner.status),
        });
      }

      if (oldBroadcast.runner.price !== broadcast.runner.price) {
        runnersChangedList.push({
          event: broadcast.key.event,
          market: broadcast.key.market,
          runner: broadcast.key.runner,
          odds: broadcast.runner.price,
        });
      }
    }

    if (statusChangedList.length > 0) {
      bus.emit(BusEvent.SPORT_EVENTS_STATUS_CHANGED, { events: statusChangedList, source: 'line' });
    }

    if (runnersChangedList.length > 0) {
      bus.emit(BusEvent.SPORT_EVENTS_RUNNERS_CHANGED, { events: runnersChangedList, source: 'line' });
    }
  }

  function updateDataInStorageByResponse({ response }: {
    response: Maybe<GetSportEventsResponse | GetSportEventsChangesResponse> | false;
  }): void {
    if (response) {
      const broadcastList = prepareBroadcastList();

      updateDataInCacheStorageByResponse({ response });

      if (broadcastList.length > 0) {
        checkBroadcastChanges(broadcastList);
      }
    }

    clearInvalidCache();
  }

  /**
   * Remove event we definitely know are ended
   * @param {string[]} eventIds
   */
  function removeEventsDataInStorage(eventIds: string[]): void {
    for (const id of eventIds) {
      invalidateEventCache(id);
      invalidateMarketCache(id);
    }

    clearInvalidCache();
  }

  return {
    updateDataInStorageByResponse,
    removeEventsDataInStorage,
  };
}
