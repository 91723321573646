import { QueryParameterName } from '@leon-hub/query-manager';
import { getQueryParams } from '@leon-hub/service-locator-env';

import DefaultRestApiClient from './DefaultRestApiClient';

let baseApiClient: DefaultRestApiClient | undefined;

export default function useBaseApiClient(): DefaultRestApiClient {
  if (!baseApiClient) {
    baseApiClient = new DefaultRestApiClient();
  }

  if (getQueryParams()[QueryParameterName.TEST_CASE] === 'performance') {
    baseApiClient.setDefaultCacheTtl(Infinity);
  }

  return baseApiClient;
}
