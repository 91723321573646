export enum CaptchaFramedWidgetProperties {
  CaptchaRequesterStrategy = 'captchaRequesterStrategy',
  SiteKey = 'siteKey',
  CaptchaType = 'captchaType',
  Theme = 'theme',
  AutoExecute = 'AutoExecute',
  Lang = 'lang',
  ApiUrl = 'apiUrl',
  Version = 'version',
}
