<script lang="ts" setup>
import { Tag } from '@leon-hub/tags';

import ModalWindowHeader from 'web/src/components/Modal/ModalWindowHeader/ModalWindowHeader.vue';

import type {
  DefaultTopBarRouteComponentProps,
  DefaultTopBarRouteComponentEmits,
} from './types';
import { useDefaultTopBarRouteComponent } from './composables';

const props = withDefaults(defineProps<DefaultTopBarRouteComponentProps>(), {});
const emit = defineEmits<DefaultTopBarRouteComponentEmits>();

const {
  prefixIconName,
  suffixIconName,
  onPrefixClick,
  close,
} = useDefaultTopBarRouteComponent(props, emit);
</script>

<template>
  <ModalWindowHeader v-auto-id="'DefaultTopBarRouteComponent'"
    :title="title"
    :title-tag="Tag.H1"
    :badge="badge"
    :badge-kind="badgeKind"
    :prefix-icon-name="prefixIconName"
    :suffix-icon-name="suffixIconName"
    :is-avatar="isAvatar"
    :is-badge-counter="isBadgeCounter"
    :type="type"
    @prefix-click="onPrefixClick"
    @suffix-click="close"
  >
    <template #prefix>
      <slot name="prefix" />
    </template>
    <template #suffix>
      <slot
        name="suffix"
        :go-back-or-close="onPrefixClick"
      />
    </template>
    <template #title>
      <slot name="title" />
    </template>
  </ModalWindowHeader>
</template>
