import {
  computed,
  ref,
  toRef,
} from 'vue';
import { defineStore } from 'pinia';

import {
  getEgsGames,
  lobbyTypeSlots,
  RequestOptionsPriority,
} from '@leon-hub/api-sdk';

import { useSiteConfigStore } from 'web/src/modules/core/store';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import type { EgsGame } from 'web/src/modules/egs/types';

const DEFAULT_LIMIT = 50;

export const useCasinoTopStore = defineStore('casino-top', () => {
  const apiClient = useGraphqlClient();
  const siteConfigStore = useSiteConfigStore();
  const sportLineBlock = toRef(siteConfigStore, 'sportLineBlock');

  const jackpots = ref<Maybe<EgsGame[]>>(null);

  const isReady = computed<boolean>(() => jackpots.value !== null);
  const topEventsJackpotsSectionEnabled = computed<boolean>(() => (
    !!sportLineBlock.value?.topEventsJackpotsSectionEnabled
  ));
  const hasJackpots = computed<boolean>(() => (
    !!jackpots.value
    && jackpots.value.length > 0
    && topEventsJackpotsSectionEnabled.value
  ));

  function setJackpots(lobbyItems: EgsGame[]): void {
    jackpots.value = lobbyItems.map((item) => ({ ...item } as EgsGame));
  }

  async function updateJackpots(): Promise<void> {
    try {
      const result = await getEgsGames(apiClient,
        (node) => node.queries.externalGames.getGames,
        {
          options: { type: lobbyTypeSlots, limit: DEFAULT_LIMIT, isJackpot: true },
        },
        { silent: true, priority: RequestOptionsPriority.LOW });

      setJackpots(result.games);
    } catch (error) {
      if (jackpots.value === null) {
        setJackpots([]);
      }

      throw error;
    }
  }

  return {
    isReady,
    jackpots,
    hasJackpots,
    updateJackpots,
  };
});
