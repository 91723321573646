import type { Ref } from 'vue';
import { computed } from 'vue';

import type { SportlineEvent } from 'web/src/modules/sportline/types';
import { SportlineType } from 'web/src/modules/sportline/enums';
import { HeadlineSlideType } from 'web/src/modules/sportline/submodules/event-details/enums';

interface UseInfoSlideTypeProps {
  sportlineEvent: Ref<Maybe<SportlineEvent>>;
}

interface UseInfoSlideTypeComposable {
  infoSlideType: Ref<Maybe<HeadlineSlideType>>;
}

export function useInfoSlideType(
  props: UseInfoSlideTypeProps,
): UseInfoSlideTypeComposable {
  const { sportlineEvent } = props;

  const infoSlideType = computed<Maybe<HeadlineSlideType>>(() => {
    if (!sportlineEvent.value) { return null; }

    const { type, isOutright } = sportlineEvent.value;

    switch (true) {
      case (isOutright): return HeadlineSlideType.OutrightInfo;
      case (type === SportlineType.Live): return HeadlineSlideType.LiveInfo;
      case (type === SportlineType.Prematch): return HeadlineSlideType.PreMatchInfo;
      default: return null;
    }
  });

  return {
    infoSlideType,
  };
}
