import { ref, computed, watch } from 'vue';
import type {
  ComputedRef,
  Ref,
} from 'vue';

import { assert } from '@leon-hub/guards';

import { useScrollIntoView } from 'web/src/modules/core/composables/scroll';
import doAfterScrollVisibilityCheck from 'web/src/utils/doAfterScrollVisibilityCheck';

import type { FormErrors, StatelessFormField } from '../../../types';
import type { UseFocusOnError, TemplateRef } from './types/UseFocusOnError';

export default function useFocusOnError(
  fields: ComputedRef<StatelessFormField[]>,
  externalErrors: Ref<FormErrors>,
  focusOnError: Ref<boolean | undefined>,
  focusByName: (name: string) => void,
): UseFocusOnError {
  const { scrollIntoView } = useScrollIntoView();

  const isFocusedOnError = ref<boolean>(false);

  const resetFocusOnError = (): void => {
    isFocusedOnError.value = false;
  };

  const lines = ref<Record<string, HTMLDivElement>>({});

  const setLinesRef = (templateRef: TemplateRef, id: string): void => {
    if (templateRef) {
      assert(templateRef instanceof HTMLDivElement);
      lines.value[id] = templateRef;
    }
  };

  const firstFieldWithErrorId = computed<string | null>(() => {
    const result = null;
    if (!focusOnError.value) {
      return result;
    }
    for (const field of fields.value) {
      if (field.widgetProps.error) {
        return field.id;
      }
    }
    return result;
  });

  const scrollToFirstError = (): void => {
    if (firstFieldWithErrorId.value) {
      const element = lines.value[firstFieldWithErrorId.value];
      const baseCallback = () => {
        focusByName(firstFieldWithErrorId.value ?? '');
        isFocusedOnError.value = true;
      };
      doAfterScrollVisibilityCheck(element, {
        callbackIfHidden: () => {
          scrollIntoView(element);
          baseCallback();
        },
        callbackIfVisible: () => {
          baseCallback();
        },
        observerOptions: {
          threshold: 1.0,
        },
      });
    }
  };

  watch(externalErrors, () => {
    if (!focusOnError.value || isFocusedOnError.value) {
      return;
    }
    scrollToFirstError();
  });

  return {
    setLinesRef,
    scrollToFirstError,
    resetFocusOnError,
  };
}
