import type {
  ProfileBonusesFreespinControllerProps,
} from 'web/src/modules/profile/submodules/bonuses/controllers/ProfileBonusesFreespinController/types';

export default function getTotalCount(
  props: Pick<ProfileBonusesFreespinControllerProps, 'bonus'>,
): string {
  const totalCount = props.bonus?.freespin?.totalCount;

  return totalCount ? totalCount.toString() : '';
}
