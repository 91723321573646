import { IconName, IconSize } from '@leon-hub/icons';

import type { VIconProps } from '@components/v-icon';

export interface VSocialSharingCopyLinkComposable {
  iconCopyProps: VIconProps;
}

export default function useVSocialSharingCopyLink(): VSocialSharingCopyLinkComposable {
  const iconCopyProps: VIconProps = {
    name: process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED ? IconName.SLOTT_DUPLICATE : IconName.FILES,
    size: process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED ? IconSize.SIZE_24 : IconSize.SIZE_16,
  };

  return {
    iconCopyProps,
  };
}
