import {
  isNumber,
  isValidInterface,
} from '@leon-hub/guards';
import type { FieldGuards } from '@leon-hub/types';

import type { CoreRegionReferenceResponse } from 'web/src/modules/sportline/types/rest';

export const isCoreRegionReferenceResponseFieldGuards: FieldGuards<CoreRegionReferenceResponse> = {
  id: isNumber,
};

export function isCoreRegionReferenceResponse(value: unknown): value is CoreRegionReferenceResponse {
  return isValidInterface<CoreRegionReferenceResponse>(value, isCoreRegionReferenceResponseFieldGuards)
    && Object.keys(value).length === 1;
}
