export interface DecodeValueProperties {
  symbol?: DecodeSymbol;
  regex?: string;
}

export enum DecodeSymbol {
  DEFAULT = '*',
  BULL = '•',
  ZERO = '0',
}

export class DecodeValue {
  private readonly value: string;

  private readonly regex: string = `[亜哀挨愛曖悪握圧扱宛嵐安案暗以衣位囲医依委威為畏胃尉異移萎偉椅彙意違維慰遺緯
                            域育一壱逸茨芋引印因咽姻員院淫陰飲隠韻右宇羽雨唄鬱畝浦運雲永泳英映栄営詠影鋭衛易疫益液駅
                            悦越謁閲円延沿炎怨宴媛援園煙猿遠鉛塩]+`;

  private readonly regexp = new RegExp(this.regex, 'g');

  private readonly symbol: string = DecodeSymbol.DEFAULT;

  constructor(value: string, props?: DecodeValueProperties) {
    this.value = value;
    if (props?.symbol) this.symbol = props.symbol;
    if (props?.regex) this.regex = props.regex;
  }

  public toDecodedString(): string {
    return this.value.replace(this.regexp, (foundSymbols) => {
      let symbolsCount = foundSymbols.length;
      let result = '';

      while (symbolsCount) {
        result += this.symbol;
        symbolsCount -= 1;
      }

      return result;
    });
  }
}
