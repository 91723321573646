/* eslint-disable no-console */
const prefix = '[yandex-metrika]';

const info = (message = '', parameters?: unknown): void => {
  /* eslint-disable-next-line no-console */
  console.info(`${prefix} ${message}`, parameters || '');
};

const error = (message = '', parameters = {}): void => {
  /* eslint-disable-next-line no-console */
  console.error(`${prefix} ${message}`, parameters);
};

const silent = (...parameters: unknown[]): void => {
  /* eslint-disable-next-line no-console */
  if (process.env.VUE_APP_RENDERING_CSR) {
    console.info(prefix, parameters);
  }
};

export default { info, error, silent };
