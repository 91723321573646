import type { I18nFunctionReactive } from 'web/src/modules/i18n/types';
import type { PaymentsInputProps } from 'web/src/modules/payments/form-widgets/FastSum/components/PaymentsInput/types';

export default function getPlaceholderText(
  props: Pick<PaymentsInputProps, 'deposit' | 'bonus' | 'placeholder'>,
  $t: I18nFunctionReactive,
): string {
  if (props.placeholder) {
    return props.placeholder;
  }

  let text = $t('WEB2_WITHDRAW').value;

  if (props.deposit) {
    text = $t('WEB2_REPLENISH').value;
  } else if (props.bonus) {
    text = $t('WEB2_BONUS_EXCHANGE_LABEL').value;
  }
  return text;
}
