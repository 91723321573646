import { Theme } from '@leon-hub/api-sdk';

import type { Sport } from 'web/src/modules/sportline/types';

/** @deprecated use useSportBackgroundColor instead */
export function getBackgroundColorFromSportRepresentationBackground(
  theme: Theme,
  background: Sport['representation']['background'],
): Optional<string> {
  if (theme === Theme.LIGHT && background?.light) {
    return background.light;
  }

  return background?.dark;
}

/** @deprecated use useSportBackgroundColor instead */
export function getSportBackgroundColor(theme: Theme, sport: Sport | undefined): string | undefined {
  return getBackgroundColorFromSportRepresentationBackground(theme, sport?.representation.background);
}
