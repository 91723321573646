import {
  ref,
  watch,
  computed,
} from 'vue';

import { Timer } from '@leon-hub/utils';
import { isString } from '@leon-hub/guards';

import { useModuleTimeout } from 'web/src/modules/core/store/composables/useModuleTimeout';

import { useModulesSyncStore } from '../store/useModulesSyncStore';
import type {
  SyncStateComposable,
  SyncStateCallback,
  SyncStateTimerName,
  SyncStateOptions,
} from '../types';

// eslint-disable-next-line sonarjs/cognitive-complexity
export function useSyncState(
  callback: SyncStateCallback,
  timerName: SyncStateTimerName,
  {
    suspendedByDefault,
    disableLoginLogoutHandlers,
    condition,
  }: SyncStateOptions = {},
): SyncStateComposable {
  if (!timerName) {
    throw new Error('Please provide timerName to use syncState');
  }

  if (process.env.NODE_ENV === 'test' && !process.env.IS_SYNC_STATE_TEST) {
    return {
      pauseSyncState() {},
      resumeSyncState() {},
      timeout: ref(0),
      isSyncAvailable: ref(false),
    };
  }

  const { watchGlobalSyncState, watchLoggedInSyncState } = useModulesSyncStore();

  const { timeout } = isString(timerName)
    ? useModuleTimeout(timerName)
    : useModuleTimeout(null, timerName);

  const isStopped = ref(suspendedByDefault ?? false);
  const conditionValue = condition ?? ref(true);

  const isSyncAvailable = computed<boolean>(() => !isStopped.value && !!conditionValue.value && timeout.value > 0);

  let timerId = 0;

  function stopSyncState(): void {
    if (timerId) {
      Timer.clearTimeout(timerId);
      timerId = 0;
    }
  }

  function startSyncState(): void {
    stopSyncState();

    if (isSyncAvailable.value) {
      timerId = Timer.setTimeout(() => {
        void callback(true).finally(() => {
          startSyncState();
        });
      }, timeout.value);
    }
  }

  function pauseSyncState(): void {
    if (!isStopped.value) {
      isStopped.value = true;
      stopSyncState();
    }
  }

  function resumeSyncState(): void {
    if (isStopped.value) {
      isStopped.value = false;
      startSyncState();
    }
  }

  watch(timeout, startSyncState);
  watch(isSyncAvailable, (newValue) => {
    if (newValue) {
      startSyncState();
    } else {
      stopSyncState();
    }
  }, { immediate: true });

  watchGlobalSyncState(() => { void callback(true); });

  if (!disableLoginLogoutHandlers) {
    watchLoggedInSyncState(() => { void callback(); });
  }

  return {
    timeout,
    isSyncAvailable,
    pauseSyncState,
    resumeSyncState,
  };
}
