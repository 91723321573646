<script lang="ts" setup>
import { IconDirection, IconSize } from '@leon-hub/icons';

import { ArrowIcon } from '@components/arrow-icon';
import type { VIconProps } from '@components/v-icon';

import type {
  PromotionDetailsHowToReceiveBonusProps,
} from 'web/src/modules/promotions/components/PromotionDetailsHowToReceiveBonus/types';
import VDynamicContent from 'web/src/components/DynamicContent/VDynamicContent/VDynamicContent.vue';

withDefaults(defineProps<PromotionDetailsHowToReceiveBonusProps>(), { title: '' });

const iconProperties: VIconProps = {
  size: IconSize.SIZE_16,
  direction: IconDirection.SOUTH,
};
</script>

<template>
  <div v-auto-id="'PromotionDetailsHowToReceiveBonus'" v-if="title && items.length">
    <h2
      :class="{
        [$style['promotions-shared__title']]: true,
        [$style['how-to-receive-bonus__title']]: true,
      }"
    >
      {{ title }}
    </h2>
    <div :class="$style['steps-hint']">
      <div
        v-for="(item, index) in items"
        :key="index"
        :class="$style['steps-hint__panel']"
      >
        <div :class="$style['steps-hint__image-holder']">
          <img
            :class="$style['steps-hint__image']"
            :src="'https://'+item.bonusItemImage"
            alt=""
          >
        </div>
        <h2
          :class="$style['steps-hint__heading']"
        >
          {{ item.bonusItemTitle }}
        </h2>
        <VDynamicContent
          :class="$style['steps-hint__text']"
          :content="item.bonusItemDescription || undefined"
        />
        <div :class="$style['steps-hint__arrow']">
          <ArrowIcon
            v-bind="iconProperties"
            :class="$style['steps-hint__arrow-icon']"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>
@import 'web/src/modules/promotions/styles/promotions-shared';

.how-to-receive-bonus {
  &__title {
    margin-bottom: 16px;
  }
}

.steps-hint {
  $self: &;

  &__panel {
    position: relative;
    padding: 32px 12px;
    margin-bottom: 8px;
    text-align: center;
    background-color: var(--Layer1);
    border-radius: 5px;

    &:last-of-type {
      #{$self}__arrow {
        display: none;
      }

      @include is-app-layout-desktop {
        margin-bottom: 0;
      }
    }

    @include is-app-layout-desktop {
      padding-right: 16px;
      padding-left: 16px;
    }
  }

  &__image-holder {
    width: 60px;
    height: 60px;
    margin: 0 auto 16px;
    overflow: hidden;
    border-radius: 12px;

    @include is-app-layout-desktop {
      border-radius: 8px;
    }
  }

  &__image {
    display: flex;
    align-self: start;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__heading {
    @include medium\16\20\025;

    margin: 0 0 8px;
    color: var(--TextDefault);
  }

  &__text {
    @include regular\14\24\025;

    color: var(--TextPrimary);
  }

  &__arrow {
    @include z-index(base);

    position: absolute;
    bottom: -24px;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    color: var(--TextDefault);
    background-color: var(--Layer2);
    border-radius: 50%;
    transform: translateX(-50%);

    &-icon {
      fill: currentcolor;
    }
  }
}
</style>
