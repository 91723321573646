import { Deferred, delay } from '@leon-hub/utils';

import { isTelegramInAppBrowser } from './isTelegramInAppBrowser';
import { getFrameWindow } from './getFrameWindow';

// eslint-disable-next-line sonarjs/cognitive-complexity
async function getListeningFrameWindow(iframe: HTMLIFrameElement): Promise<Window> {
  if (await isTelegramInAppBrowser()) {
    await delay(1000);
  }
  let { contentWindow } = iframe;
  if (!contentWindow) {
    let resolved = false;
    let interval: ReturnType<typeof setInterval>;
    contentWindow = await Promise.race<Window>([
      new Promise((resolve) => {
        interval = setInterval(() => {
          if (!resolved && iframe.contentWindow) {
            resolve(iframe.contentWindow);
          }
        }, 100);
      }),
      new Promise((resolve) => {
        iframe.addEventListener('load', () => {
          if (!resolved) {
            resolve(getFrameWindow(iframe));
          }
        });
      }),
    ]).finally(() => {
      resolved = true;
      if (interval) clearInterval(interval);
    });
  }
  const { addEventListener, removeEventListener } = contentWindow;
  await new Promise<void>((resolve) => {
    function onMessage({ data }: MessageEvent): void {
      if (data === 'listening') {
        removeEventListener('message', onMessage);
        resolve();
      }
    }
    addEventListener('message', onMessage);
  });
  return contentWindow;
}

// eslint-disable-next-line
export async function connectWindow(iframe: HTMLIFrameElement, options: {
  id: string;
}): Promise<void> {
  const deferred = new Deferred<void>();
  const contentWindow = await getListeningFrameWindow(iframe);
  const { addEventListener, removeEventListener } = contentWindow;
  addEventListener('message', function onMessage({ data }: MessageEvent): void {
    if (data === 'connected') {
      removeEventListener('message', onMessage);
      deferred.resolve();
    }
  });
  contentWindow.postMessage('connect', '*');

  return deferred.promise;
}
