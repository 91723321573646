import { assert } from '@leon-hub/guards';

import getContentWindowByIFrameName from './getContentWindowByIFrameName';

function requireContentWindowByIFrameName(iframeName: string): Window {
  const contentWindow = getContentWindowByIFrameName(iframeName);
  assert(contentWindow, `Unable to get contentWindow from ${iframeName} iframe`);
  return contentWindow;
}

export default requireContentWindowByIFrameName;
