import type { ComputedRef, Ref } from 'vue';
import { computed, ref } from 'vue';

import type { BaseError } from '@leon-hub/errors';

import { ModalSelector } from 'web/src/modules/core/enums';
import type { ConfirmDialog } from 'web/src/modules/dialogs/types';
import { DialogAction } from 'web/src/modules/dialogs/enums';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useErrorsConverter } from 'web/src/modules/errors/composables';
import { useDialogs } from 'web/src/modules/dialogs/composables';

import { CurrentModal } from '../enums';
import type { WithdrawalContainerEmits, WithdrawalContainerProps } from '../types';
import { getModalBonusProperties, getModalConfirmProperties, getModalErrorHandlerProperties } from './utils';

export interface WithdrawalContainerComposable {
  modalSelector: ModalSelector;
  currentModal: Ref<CurrentModal>;
  modalConfirmProperties: ComputedRef<Partial<ConfirmDialog> >;
  modalBonusProperties: ComputedRef<Partial<ConfirmDialog>>;
  onClickModal: ({ action }: { action: DialogAction }) => Promise<void>;
  onCloseModal: () => void;
  withdrawBonus: (withdrawalFunction: Function) => void;
}

export default function useWithdrawalContainer(
  props: WithdrawalContainerProps,
  emits: WithdrawalContainerEmits,
): WithdrawalContainerComposable {
  const { $translate } = useI18n();
  const { showDialog } = useDialogs();
  const { convertToBaseError } = useErrorsConverter();

  const modalSelector = ModalSelector.BODY;

  const currentModal = ref<CurrentModal>(CurrentModal.NONE);
  let withdrawalFunction: Function | undefined;

  const modalConfirmProperties = computed<Partial<ConfirmDialog>>(() => getModalConfirmProperties(props, $translate));
  const modalBonusProperties = computed<Partial<ConfirmDialog>>(() => getModalBonusProperties($translate));

  const onCloseModal = (): void => {
    currentModal.value = CurrentModal.NONE;
    emits('modal-close');
  };

  const withdrawBonus = (func: Function): void => {
    withdrawalFunction = func;
    currentModal.value = CurrentModal.CONFIRM;
  };

  const withdrawErrorHandler = (error: BaseError): void => {
    currentModal.value = CurrentModal.NONE;
    void showDialog(getModalErrorHandlerProperties(error.message, $translate)).promise.then(onCloseModal);
  };

  const withdrawBonusHandler = async (): Promise<void> => {
    try {
      if (withdrawalFunction) {
        await Promise.resolve(withdrawalFunction());
      }
      currentModal.value = CurrentModal.BONUS;
    } catch (rawError) {
      withdrawErrorHandler(convertToBaseError(rawError));
    }
  };

  const onClickModal = async ({ action }: { action: DialogAction }): Promise<void> => {
    if (currentModal.value === CurrentModal.CONFIRM) {
      switch (action) {
        case DialogAction.MODAL_CLOSE:
          currentModal.value = CurrentModal.NONE;
          break;
        case DialogAction.CONFIRM:
          await withdrawBonusHandler();
          currentModal.value = CurrentModal.BONUS;
          break;
        default:
          break;
      }
    }

    if (action === DialogAction.MODAL_CLOSE && currentModal.value === CurrentModal.BONUS) {
      onCloseModal();
    }
  };

  return {
    modalSelector,
    currentModal,
    modalConfirmProperties,
    modalBonusProperties,
    onClickModal,
    onCloseModal,
    withdrawBonus,
  };
}
