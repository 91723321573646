export default class PaymentsDepositsNavigationParametersHolder {
  private static lastDepositedId?: string;

  static setLastDepositedId(method: string | undefined): void {
    PaymentsDepositsNavigationParametersHolder.lastDepositedId = method;
  }

  static getLastDepositedId(): string | undefined {
    return PaymentsDepositsNavigationParametersHolder.lastDepositedId;
  }
}
