import type { Market, MarketSpecifier } from 'web/src/modules/sportline/types';

import type { MarketsGridCell, MarketsGridFilter, MarketsGridCellOpenStateKey } from '../types';
import { getMarketsGridCellId } from './getMarketsGridCellId';
import { getMarketsGridCellOpenStateKey } from './getMarketsGridCellOpenStateKey';
import { createEmptyMarketsGridCell } from './createEmptyMarketsGridCell';

interface GroupMarketsIntoGridCellsOptions {
  doShowAll: boolean;
  filter: Maybe<MarketsGridFilter>;
  markets: Market[];
  collapsedCellKeys: MarketsGridCellOpenStateKey[];
}

// @TODO optimizations and tests (@see useMarketsListGroups tests)
// eslint-disable-next-line sonarjs/cognitive-complexity
export function groupMarketsIntoGridCells(options: GroupMarketsIntoGridCellsOptions): MarketsGridCell[] {
  const {
    doShowAll,
    filter,
    markets,
    collapsedCellKeys,
  } = options;

  const result: MarketsGridCell[] = [];
  let lastMarketGroup: MarketsGridCell | null = null;
  const activeMarketTypesIds = new Set(filter?.marketTypeIds ?? []);

  function isEqualToActiveMarketSpecifier(specifier?: MarketSpecifier): boolean {
    const activeMarketSpecifier = filter?.specifier;
    if (!activeMarketSpecifier) { return true; }
    if (!specifier) { return false; }
    return specifier.id === activeMarketSpecifier.id && specifier.value === activeMarketSpecifier.value;
  }

  for (const market of markets) {
    const isMarketVisible = doShowAll || activeMarketTypesIds.has(market.type.id);
    if (!isMarketVisible) { continue; }

    const isSpecifierAvailable = !filter?.specifier || market.specifiers?.some(isEqualToActiveMarketSpecifier);
    if (!isSpecifierAvailable) { continue; }

    const id = getMarketsGridCellId(market);

    if (!lastMarketGroup || lastMarketGroup.id !== id) {
      if (lastMarketGroup) {
        result.push(lastMarketGroup);
      }

      lastMarketGroup = createEmptyMarketsGridCell(market);
      const groupKey = getMarketsGridCellOpenStateKey(lastMarketGroup);

      lastMarketGroup.isOpened = !collapsedCellKeys.includes(groupKey);
    }

    lastMarketGroup.hasZeroMargin = lastMarketGroup.hasZeroMargin || market.hasZeroMargin;
    lastMarketGroup.markets.push(market);
  }

  if (lastMarketGroup) {
    result.push(lastMarketGroup);
  }

  return result;
}
