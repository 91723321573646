import { logger } from '@leon-hub/logging';
import { isNullOrUndefined } from '@leon-hub/guards';

import { getLargestKey } from '../utils/getLargestKey';
import { isQuotaExceededError } from '../utils/isQuotaExceededError';
import type { UniversalStorageManager } from '../types';

export class LocalStorageManager implements UniversalStorageManager {
  private readonly storage: Storage;

  private triedToOverwrite = false;

  constructor() {
    this.storage = window.localStorage;
  }

  key(index: number): string | null {
    return this.storage.key(index);
  }

  clear(saveKeys?: string[]): void {
    const savedKeys: Record<string, string> = {};

    if (saveKeys?.length) {
      for (const key of saveKeys) {
        const value = localStorage.getItem(key);

        if (!isNullOrUndefined(value)) {
          savedKeys[key] = value;
        }
      }
    }

    this.storage.clear();

    for (const key of Object.keys(savedKeys)) {
      this.storage.setItem(key, savedKeys[key]);
    }
  }

  getItem(key: string): string | null {
    return this.storage.getItem(key);
  }

  setItem = (key: string, value: string): void => {
    try {
      this.storage.setItem(key, value);
    } catch (error) {
      if (isQuotaExceededError(error) && !this.triedToOverwrite) {
        const largestKey = getLargestKey(this.storage);
        if (largestKey !== undefined) {
          const largestValue = this.storage.getItem(largestKey)?.slice(0, 2000) ?? '<cannot get largest key value>';
          logger.error(`localStorageManager: largestKey=${largestKey}, value=${largestValue}`);
          this.removeItem(largestKey);
        }
        this.triedToOverwrite = true;
        this.setItem(key, value);
      } else {
        logger.error('localStorageManager: unable to save the value', { [key]: value, error });
      }
    }
  };

  removeItem = (key: string): void => {
    this.storage.removeItem(key);
  };

  get length(): number {
    return this.storage.length;
  }
}
