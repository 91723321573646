import { ComponentKey } from '../types';
import type { ComponentResult } from '../types';
import AbstractFingerprintComponent from '../AbstractFingerprintComponent';

export default class UserAgentComponent extends AbstractFingerprintComponent {
  constructor() {
    super(ComponentKey.UserAgent);
  }

  async getComponentResult(): Promise<ComponentResult> {
    return this.result([window.navigator.userAgent]);
  }
}
