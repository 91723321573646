import createLogger from 'debug';

import { AppEmitter } from '@leon-hub/app-emitter';
import {
  logger,
} from '@leon-hub/logging';
import { normalizeError } from '@leon-hub/errors';

import type { BusEventOptions } from './types/types';
import type {
  BusEventType,
  Callback,
  QueueItem,
} from './types';
import type BusEvent from './BusEvent';

type AppEvents = [
  [string, unknown[]],
];

export default class EventsBus {
  private logPrefix = '[event-bus]';

  private initialized = false;

  private readonly appEmitter = new AppEmitter<AppEvents>();

  private queue: QueueItem<BusEvent>[] = [];

  public get debug(): boolean {
    return this.logger.enabled;
  }

  private readonly logger = createLogger('events-bus');

  initialize(): void {
    if (!this.initialized) {
      this.log('initialization');

      this.initialized = true;
      this.runQueue();
    }
  }

  getEmitter(): typeof this.appEmitter {
    return this.appEmitter;
  }

  public off<E extends BusEvent, P extends BusEventType<E>>(event?: E | E[], callback?: Callback<P>): void {
    let events: E[] | void = [];
    if (event) {
      events = Array.isArray(event) ? event : [event];
    }

    if (this.initialized) {
      if (events.length) {
        this.removeEventsByEvent(events, callback);
      } else {
        throw new Error('Removing all events is not allowed');
      }
    } else {
      this.addQueue({ type: 'off', value: [events, callback] });
    }
  }

  public on<E extends BusEvent, P extends BusEventType<E>>(event: E | E[], callback: Callback<P>): void {
    const events: E[] = Array.isArray(event) ? event : [event];
    if (this.initialized) {
      for (const item of events) {
        this.log(`starting event listener for event "${item}" at ${new Date(Date.now()).toISOString()}`);
        this.appEmitter.on(String(item), callback);
      }
    } else {
      this.addQueue({ type: 'on', value: [events, callback] });
    }
  }

  public once<E extends BusEvent, P extends BusEventType<E>>(event: E | E[], callback: Callback<P>): void {
    const events: E[] = Array.isArray(event) ? event : [event];
    if (this.initialized) {
      for (const item of events) {
        this.log(`starting one time listener for event "${item}" at ${new Date(Date.now()).toISOString()}`);
        this.appEmitter.once(`${item}`, callback);
      }
    } else {
      this.addQueue({ type: 'once', value: [events, callback] });
    }
  }

  public emit<
    E extends BusEvent,
    P extends BusEventType<E>,
  >(event: E, payload: P extends Record<string, unknown> ? P : never): void {
    if (this.initialized) {
      // dont call JSON.stringify without debug
      if (this.debug) {
        this.log(
          `emitting event "${event}" with payload:
          ${JSON.stringify(payload)} at ${new Date(Date.now()).toISOString()}`,
        );
      }
      this.appEmitter.emit(`${event}`, payload);
    } else {
      this.addQueue({ type: 'emit', value: [event, payload] });
    }
  }

  private removeEventsByEvent(events: BusEvent<BusEventOptions>[], callback?: Function) {
    try {
      for (const item of events) {
        this.log(`removing event listener for the event "${item}" at ${new Date(Date.now()).toISOString()}`);
        this.appEmitter.off(String(item), callback);
      }
    } catch (rawError) {
      const error = normalizeError(rawError);
      logger.error(new Error(`${this.logPrefix}, Can't unsubscribe ${error.message}`));
    }
  }

  private runQueue() {
    const order = ['on', 'once', 'off', 'emit'];
    const orderedQueue = this.queue
      .sort((a, b) => order.indexOf(a.type) - order.indexOf(b.type));
    for (const item of orderedQueue) {
      if (item.type === 'on') this.on(...item.value);
      if (item.type === 'once') this.once(...item.value);
      if (item.type === 'off') this.off(...item.value);
      if (item.type === 'emit') this.emit(...item.value);
    }
    this.queue = [];
  }

  private addQueue(item: QueueItem<BusEvent>) {
    const items = item.value[0];
    if (items) {
      const events = Array.isArray(items) ? items : [items];
      for (const event of events) {
        this.log(`delay "${item.type}" handler for "${event}" event at ${new Date(Date.now()).toISOString()}`);
      }
    } else {
      this.log(`delay "${item.type}" handler at ${new Date(Date.now()).toISOString()}`);
    }
    this.queue.push(item);
  }

  private log(...messages: string[]) {
    if (this.debug) {
      this.logger.log(this.logPrefix, ...messages);
    }
  }
}
