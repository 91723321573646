import { defineStore } from 'pinia';
import type { Ref } from 'vue';
import { computed, ref } from 'vue';

import type { OnboardingItem, FetchOnboardingType } from 'web/src/modules/onboarding/types';
import fetchOnboarding from 'web/src/modules/onboarding/utils/fetchOnboarding';
import type { VSwiperRef } from 'web/src/components/Swiper/VSwiper/types';

const useOnboardingStore = defineStore('onboarding', () => {
  const onboardingItems: Ref<Record<string, OnboardingItem>> = ref({});
  const onboardingLength = computed(() => Object.keys(onboardingItems).length);
  const swiper = ref<VSwiperRef>();
  const isLastSlide = ref(false);

  async function getOnboarding({ onboardingId, withSlides = false }: FetchOnboardingType): Promise<void> {
    const { items } = await fetchOnboarding({ onboardingId, withSlides });
    for (const item of items) {
      if (!onboardingItems.value[item.id] || !onboardingItems.value[item.id].slides.length) {
        onboardingItems.value[item.id] = item;
      }
    }
  }

  return {
    onboardingLength,
    getOnboarding,
    onboardingItems,
    swiper,
    isLastSlide,
  };
});

export default useOnboardingStore;
