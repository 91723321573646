import type {
  VirtualSportFilterOption,
  VirtualSportFilterOptions,
} from 'web/src/modules/sportline/submodules/virtual-sport/types';

export default function findSelectedByDefaultVirtualSportFilterOption(
  options?: Maybe<VirtualSportFilterOptions>,
): Maybe<VirtualSportFilterOption> {
  return options?.sports.find((option) => option.selectedByDefault) || null;
}
