import type { Ref } from 'vue';
import { toRef, computed } from 'vue';

import { RouteName } from '@leon-hub/routing-config-names';

import { useModuleTimeout } from 'web/src/modules/core/store/composables';
import { useSportSegmentsSettings } from 'web/src/modules/sportline/store/composables';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';

import { UpdateSportlineTimeoutName } from '../enums';

interface UseUpdateSportlineTimeoutProps {
  name: UpdateSportlineTimeoutName;
}

interface UseUpdateSportlineTimeoutComposable {
  timeout: Ref<number>;
}

/** @see mobile.sportline.update.interval */
export function useDefaultSportlineUpdateTimeout(): Ref<number> {
  return toRef(useSportlineSettingsStore(), 'sportLineUpdateInterval');
}

/** @see web2.sportline.sidebar.sports.update.interval */
export function useSidebarSportsUpdateTimeout(): UseUpdateSportlineTimeoutComposable {
  const defaultTimeout = useDefaultSportlineUpdateTimeout();
  const settingTimeout = toRef(useSportlineSettingsStore(), 'sportLineSidebarSportsUpdateInterval');
  const finalTimeout = computed(() => settingTimeout.value ?? defaultTimeout.value);
  return useModuleTimeout(UpdateSportlineTimeoutName.SidebarSports, finalTimeout);
}

/** @see web2.sportline.live.events.update.interval */
export function useAllLiveLivePageUpdateInterval(): UseUpdateSportlineTimeoutComposable {
  const sportSegmentsSettings = useSportSegmentsSettings(RouteName.SPORTLINE_LIVE_EVENTS);
  return useModuleTimeout(
    UpdateSportlineTimeoutName.AllLiveTab,
    toRef(() => sportSegmentsSettings.value.liveUpdateInterval),
  );
}

/** @see web2.sportline.live.events.update.interval */
export function useAllLiveCybersportPageUpdateInterval(): UseUpdateSportlineTimeoutComposable {
  const sportSegmentsSettings = useSportSegmentsSettings(RouteName.SPORTLINE_CYBERSPORT);
  return useModuleTimeout(
    UpdateSportlineTimeoutName.AllLiveTab,
    toRef(() => sportSegmentsSettings.value.liveUpdateInterval),
  );
}

/** @see web2.sportline.filter.activated.sport.update.interval */
export function useSelectedLiveLivePageUpdateInterval(): UseUpdateSportlineTimeoutComposable {
  const sportSegmentsSettings = useSportSegmentsSettings(RouteName.SPORTLINE_LIVE_EVENTS);
  return useModuleTimeout(
    UpdateSportlineTimeoutName.SelectedLiveTab,
    toRef(() => sportSegmentsSettings.value.liveActiveEntityUpdateInterval),
  );
}

/** @see web2.sportline.filter.activated.sport.update.interval */
export function useSelectedCybersportLivePageUpdateInterval(): UseUpdateSportlineTimeoutComposable {
  const sportSegmentsSettings = useSportSegmentsSettings(RouteName.SPORTLINE_CYBERSPORT);
  return useModuleTimeout(
    UpdateSportlineTimeoutName.SelectedLiveTab,
    toRef(() => sportSegmentsSettings.value.liveActiveEntityUpdateInterval),
  );
}

/** @see web2.sportline.prematch.events.update.interval */
export function useComingLiveLivePageUpdateInterval(): UseUpdateSportlineTimeoutComposable {
  const sportSegmentsSettings = useSportSegmentsSettings(RouteName.SPORTLINE_LIVE_EVENTS);
  return useModuleTimeout(
    UpdateSportlineTimeoutName.ComingLive,
    toRef(() => sportSegmentsSettings.value.comingUpdateInterval),
  );
}

/** @see web2.sportline.prematch.events.update.interval */
export function useComingLiveCybersportPageUpdateInterval(): UseUpdateSportlineTimeoutComposable {
  const sportSegmentsSettings = useSportSegmentsSettings(RouteName.SPORTLINE_CYBERSPORT);
  return useModuleTimeout(
    UpdateSportlineTimeoutName.ComingLive,
    toRef(() => sportSegmentsSettings.value.comingUpdateInterval),
  );
}

export function useSportlineUpdateTimeout({ name }: UseUpdateSportlineTimeoutProps): UseUpdateSportlineTimeoutComposable {
  return useModuleTimeout(name, useDefaultSportlineUpdateTimeout());
}

export function useCountSportlineUpdateTimeout(): UseUpdateSportlineTimeoutComposable {
  return useSportlineUpdateTimeout({ name: UpdateSportlineTimeoutName.Count });
}

export function useZeroMarginSportlineUpdateTimeout(): UseUpdateSportlineTimeoutComposable {
  return useSportlineUpdateTimeout({ name: UpdateSportlineTimeoutName.ZeroMargin });
}

export function useTopSportlineUpdateTimeout(): UseUpdateSportlineTimeoutComposable {
  return useSportlineUpdateTimeout({ name: UpdateSportlineTimeoutName.Top });
}

export function useTopZeroMarginSportlineUpdateTimeout(): UseUpdateSportlineTimeoutComposable {
  return useSportlineUpdateTimeout({ name: UpdateSportlineTimeoutName.TopZeroMargin });
}

export function useTopLeaguesSportlineUpdateTimeout(): UseUpdateSportlineTimeoutComposable {
  return useSportlineUpdateTimeout({ name: UpdateSportlineTimeoutName.TopLeagues });
}

export function useEventSubscriptionSportlineUpdateTimeout(): UseUpdateSportlineTimeoutComposable {
  return useSportlineUpdateTimeout({ name: UpdateSportlineTimeoutName.EventSubscription });
}

export function useStreamEventsIdsSportlineUpdateTimeout(): UseUpdateSportlineTimeoutComposable {
  return useSportlineUpdateTimeout({ name: UpdateSportlineTimeoutName.StreamEventsIds });
}

export function useSportsSportlineUpdateTimeout(): UseUpdateSportlineTimeoutComposable {
  return useSportlineUpdateTimeout({ name: UpdateSportlineTimeoutName.Sports });
}

export function useNavigationSportsSportlineUpdateTimeout(): UseUpdateSportlineTimeoutComposable {
  return useSportlineUpdateTimeout({ name: UpdateSportlineTimeoutName.NavigationSports });
}

export function useExtendedEventSportlineUpdateTimeout(): UseUpdateSportlineTimeoutComposable {
  return useSportlineUpdateTimeout({ name: UpdateSportlineTimeoutName.ExtendedEvent });
}

export function useFavoriteLeaguesSportlineUpdateTimeout(): UseUpdateSportlineTimeoutComposable {
  return useSportlineUpdateTimeout({ name: UpdateSportlineTimeoutName.FavoriteLeagues });
}

export function useFavoriteEventsSportlineUpdateTimeout(): UseUpdateSportlineTimeoutComposable {
  return useSportlineUpdateTimeout({ name: UpdateSportlineTimeoutName.FavoriteEvents });
}

export function useRegionEventsSportlineUpdateTimeout(): UseUpdateSportlineTimeoutComposable {
  return useSportlineUpdateTimeout({ name: UpdateSportlineTimeoutName.RegionEvents });
}

export function useLeagueEventsSportlineUpdateTimeout(): UseUpdateSportlineTimeoutComposable {
  return useSportlineUpdateTimeout({ name: UpdateSportlineTimeoutName.LeagueEvents });
}

export function useEventDetailsSportlineUpdateTimeout(): UseUpdateSportlineTimeoutComposable {
  return useSportlineUpdateTimeout({ name: UpdateSportlineTimeoutName.EventDetails });
}

export function useRelatedEventsSportlineUpdateTimeout(): UseUpdateSportlineTimeoutComposable {
  return useSportlineUpdateTimeout({ name: UpdateSportlineTimeoutName.EventRelatedEvents });
}

export function useCybersportRegionsSportlineUpdateTimeout(): UseUpdateSportlineTimeoutComposable {
  return useSportlineUpdateTimeout({ name: UpdateSportlineTimeoutName.CybersportRegions });
}
