import noop from 'lodash/noop';

import type { SaveLogInput } from '@leon-hub/api-sdk';
import { doSaveLog, RequestOptionsPriority } from '@leon-hub/api-sdk';

import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';

const makeSaveLogRequest = (input: SaveLogInput): void => {
  const apiClient = useGraphqlClient();
  void doSaveLog(apiClient, (node) => node.mutations.logging.saveLog, {
    options: input,
  }, {
    silent: true,
    priority: RequestOptionsPriority.LOW,
  // do not log errors for saveLog operation
  }).catch(noop);
};

export default makeSaveLogRequest;
