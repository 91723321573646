import type { WithdrawBonusCBCReturnType } from '@leon-hub/api-sdk';
import { withdrawBonusCBC } from '@leon-hub/api-sdk';

import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';

export default function doWithdrawBonusCBC(): Promise<WithdrawBonusCBCReturnType> {
  const apiClient = useGraphqlClient();

  return withdrawBonusCBC(
    apiClient,
    (node) => node.mutations.bonuses.withdrawBonus,
  );
}
