<script lang="ts" setup>
import { IconName, IconSize } from '@leon-hub/icons';

import type { VIconProps } from '@components/v-icon';
import { VIcon } from '@components/v-icon';

import type {
  BetlineStatisticsTeam,
} from 'web/src/modules/sportline/types/rest';

interface StatisticTeamLogoProps {
  team: BetlineStatisticsTeam;
}

defineProps<StatisticTeamLogoProps>();

const iconProperties: VIconProps = {
  name: IconName.BRAND_LOGO,
  size: IconSize.SIZE_16,
};
</script>

<template>
  <img v-auto-id="'StatisticTeamLogo'"
    v-if="team.logo"
    :alt="team.name ?? undefined"
    :src="team.logo"
  >
  <VIcon
    v-else
    v-bind="iconProperties"
  />
</template>
