import { computed, toRef } from 'vue';
import { useRouter } from 'vue-router';

import { useSportlineSportsStore } from 'web/src/modules/sportline/submodules/sports/store';
import { useSeoMetaParameters } from 'web/src/modules/seo/composables';
import type { SportElement, SportEventSeoMetaInfo } from 'web/src/modules/sportline/types';
import type { SportEventsSportsSeoMetaInfo } from 'web/src/modules/sportline/submodules/sports/types';
import { CustomFilter } from 'web/src/modules/sportline/enums';
import { getSportEventSeoMetaInfo } from 'web/src/modules/sportline/utils';

export function useSportsRoutePageMetaInfo(): void {
  const router = useRouter();

  const sportlineSportsStore = useSportlineSportsStore();
  const selectedSportFamily = toRef(sportlineSportsStore, 'selectedSportFamily');
  const isZeroMarginEventsTabSelected = toRef(sportlineSportsStore, 'isZeroMarginEventsTabSelected');
  const zeroMarginEventsList = toRef(sportlineSportsStore, 'zeroMarginEventsList');
  const activeListFilterSportElement = toRef(sportlineSportsStore, 'activeListFilterSportElement');

  const vm = { $router: router };
  const zeroMarginMetaParameters = computed<SportEventsSportsSeoMetaInfo>(() => ({
    sport: null,
    sportFamily: CustomFilter.ZeroMargin.toLowerCase(),
    sportEmoji: null,
    sportEvents: {
      value: zeroMarginEventsList.value?.flatMap(({ sport, regions }: SportElement) => regions
        .flatMap(({ region, leagues }) => leagues
          .flatMap(({ league, sportEvents }) => sportEvents
            .map(({ sportEvent }) => getSportEventSeoMetaInfo({
              sportEvent, sport, region, league, vm,
            })))))
        .filter((sportEventMeta: object): sportEventMeta is SportEventSeoMetaInfo => ('name' in sportEventMeta)),
      merge(oldValue: SportEventSeoMetaInfo[], newValue: SportEventSeoMetaInfo[]) {
        return [...oldValue, ...newValue];
      },
    },
  }));

  const metaParameters = computed<SportEventsSportsSeoMetaInfo>(() => {
    if (!selectedSportFamily.value) {
      return { sport: null, sportFamily: null, sportEmoji: null };
    }

    if (isZeroMarginEventsTabSelected.value) {
      return zeroMarginMetaParameters.value;
    }

    const sportElement = activeListFilterSportElement.value;

    return {
      sport: sportElement?.sport.name,
      sportFamily: sportElement?.sport.navigationParameters.urlName,
      sportEmoji: sportElement?.sport.representation.emoji,
    };
  });

  useSeoMetaParameters(metaParameters);
}
