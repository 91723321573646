import type {
  NavigationGuardNext,
  RouteLocationNormalized,
} from 'vue-router';

import { RouteName } from '@leon-hub/routing-config-names';
import {
  assert,
  isOptionalString,
} from '@leon-hub/guards';

import { useVirtualSportStore } from 'web/src/modules/sportline/submodules/virtual-sport/store';
import { useRootNavigationStore } from 'web/src/modules/core/store';
import type { AppVueRouter } from 'web/src/modules/core/services/router/types';
import type { VirtualSportSelectedFilter } from 'web/src/modules/sportline/submodules/virtual-sport/types';
import { findSelectedVirtualSportFilterOption } from 'web/src/modules/sportline/submodules/virtual-sport/utils';
import { AbstractPrefetch } from 'web/src/modules/core/services/router/prefetch';

export default class VirtualSportPagePrefetch extends AbstractPrefetch {
  // eslint-disable-next-line class-methods-use-this
  prefetch(
    router: AppVueRouter,
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ): Promise<void> {
    const virtualSportStore = useVirtualSportStore();

    if (!virtualSportStore.isVirtualSportAvailable) {
      next({ name: RouteName.ERROR_NOT_FOUND_404 });
      return Promise.resolve();
    }

    const navigationStore = useRootNavigationStore();

    assert(isOptionalString(to.params.sport));

    const filter: VirtualSportSelectedFilter = { urlName: to.params.sport };

    navigationStore.setOnlyFirstRouteContentLoading(RouteName.SPORTLINE_VIRTUAL_SPORT);

    if (filter.urlName) {
      // eslint-disable-next-line max-len
      const optionForNavigation = findSelectedVirtualSportFilterOption(virtualSportStore.filterOptions, filter);

      if (!optionForNavigation) {
        next({ name: RouteName.ERROR_NOT_FOUND_404 });
        return Promise.resolve();
      }
    }

    virtualSportStore.setListFilter(filter);
    navigationStore.setRouteContentLoaded(RouteName.SPORTLINE_VIRTUAL_SPORT);

    next();
    return Promise.resolve();
  }
}
