import type { Ref } from 'vue';
import { toRef } from 'vue';

import type { BuildSportOptions } from 'web/src/modules/sportline/types/rest';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';

interface SportlineSidebarSettingsComposable {
  isSportFiltersEnabled: Ref<boolean>;
  maxTopLeaguesInTab: Ref<number>;
  maxTopLeaguesTabsInSidebar: Ref<number>;
  parseSportlineSettings: Ref<BuildSportOptions>;
  doUseRelativeTimeForSportline: Ref<boolean>;
}

export function useSportlineSidebarSettingsComposable(): SportlineSidebarSettingsComposable {
  const settingsStore = useSportlineSettingsStore();

  const isSportFiltersEnabled = toRef(settingsStore, 'isSportFiltersEnabled');
  const maxTopLeaguesInTab = toRef(settingsStore, 'maxTopLeaguesInTab');
  const maxTopLeaguesTabsInSidebar = toRef(settingsStore, 'maxTopLeaguesTabsInSidebar');
  const parseSportlineSettings = toRef(settingsStore, 'parseSportlineSettings');
  const doUseRelativeTimeForSportline = toRef(settingsStore, 'doUseRelativeTimeForSportline');

  return {
    isSportFiltersEnabled,
    maxTopLeaguesInTab,
    maxTopLeaguesTabsInSidebar,
    parseSportlineSettings,
    doUseRelativeTimeForSportline,
  };
}
