import { toRef } from 'vue';
import type { Ref } from 'vue';

import useCustomerDataStore from '../store/useCustomerDataStore';

export interface CustomerLoginComposable {
  login: Ref<string>;
}

export default function useCustomerLogin(): CustomerLoginComposable {
  const customerDataStore = useCustomerDataStore();
  const login = toRef(customerDataStore, 'login');

  return {
    login,
  };
}
