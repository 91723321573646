import { isString } from '@leon-hub/guards';

import type { League } from 'web/src/modules/sportline/types';

export default function isLeagueNavigationParameterEquals(
  league?: Maybe<string | League>,
  parameter?: Maybe<string>,
): boolean {
  if (!league || !parameter) {
    return false;
  }

  if (isString(league)) {
    return league === parameter;
  }

  return parameter === league.navigationParameters.id || parameter === league.navigationParameters.urlName;
}
