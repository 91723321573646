import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika/src/constants';
import type { NavigationItem } from '@leon-hub/navigation-config';
import { getYMInstance } from '@leon-hub/yandex-metrika';
import { RouteName } from '@leon-hub/routing-config-names';
import BaseRouteName from '@leon-hub/routing-config-names/src/BaseRouteName';
import {
  TargetParamEnter,
  TargetParamNavigation,
} from '@leon-hub/yandex-metrika/src/constants/targetParams';
import { assert } from '@leon-hub/guards';

import type { Dialog } from 'web/src/modules/dialogs/types';
import type {
  CasinoActiveGameEventOptions,
  DepositMetric,
  SportEventMetric,
  YandexMetrikaInitOptions,
  YandexMetrikaOnLoginOptions,
  SportEventOptions,
  GoogleMetric,
} from 'web/src/modules/analytics/services/types';
import type { Market } from 'web/src/modules/sportline/types';
import GoogleService from 'web/src/modules/analytics/services/googleService';
import ZetaDspService from 'web/src/modules/analytics/services/zetaDspService';
import { captchaChallengeWasShownKey } from 'web/src/modules/captcha/store/constants';
import SportRadarService from 'web/src/modules/analytics/services/sportRadarService';
import registrationTypeFormat from 'web/src/modules/registration/utils/registrationTypeFormat';
import type { RegistrationMetric } from 'web/src/modules/registration/types/RegistrationMetric';

export interface AnalyticsComposable {
  push(event: AnalyticsEvent, data: Record<string, unknown>): void;
  pushGTM(event: AnalyticsEvent, data: Record<string, unknown>): void;
  sendModalErrorMessage(dialog: Dialog): void;
  phoneCall(): void;
  clickMap(payload: Record<string, unknown>): void;
  navigationItemClick(
    navigationItem: NavigationItem,
    isLogin?: boolean,
    component?: string,
  ): void;
  makeDeposit(depositMetrics: DepositMetric & GoogleMetric): void;
  registrationStart(): void;
  registrationSuccess(registrationMetrics: RegistrationMetric): void;
  loginSuccess(options: YandexMetrikaOnLoginOptions & GoogleMetric): void;
  yandexZInit(initOptions: YandexMetrikaInitOptions): void;
  visitSportEvent(options: SportEventMetric & { markets: Market[] }): void;
  pushEgsGameEvent(options: CasinoActiveGameEventOptions): void;
}

export default function useAnalytics(): AnalyticsComposable {
  function push(event: AnalyticsEvent, data: Record<string, unknown>): void {
    getYMInstance().triggerEvent(event, data);
  }

  function clickMap(payload: Record<string, unknown>): void {
    push(AnalyticsEvent.CLICK_MAP, {
      clickCounter: payload,
    });
  }

  function pushGTM(event: AnalyticsEvent, data: Record<string, unknown>): void {
    GoogleService.getInstance().pushEvent(event, data);
  }

  return {
    push,
    clickMap,
    pushGTM,
    sendModalErrorMessage(dialog: Dialog): void {
      if (dialog.analyticsModalType) {
        const { analyticsModalType, confirmMessage } = dialog;
        push(AnalyticsEvent.MODAL_ERROR_MESSAGES, {
          [String(analyticsModalType)]: confirmMessage,
        });
      }
    },

    phoneCall(): void {
      push(AnalyticsEvent.Z_OPEN_SUPPORT_PAGE, {
        supportDetails: {
          callSupport: {
            clickPhoneNumber: true,
          },
        },
      });
    },

    // eslint-disable-next-line sonarjs/cognitive-complexity
    navigationItemClick(
      navigationItem: NavigationItem,
      isLogin?: boolean,
      component = 'default',
    ): void {
      const enum ServerRouteName {
        AFFILIATE_PROGRAM_WEB2 = 'AFFILIATE_PROGRAM_WEB2',
        DEPOSITS = 'deposits',
      }

      const enum ServerNavigationIds {
        SideBarLeftDeposits = 'sidebar-left-deposits',
        GenericPromotions = 'generic-promotions',
        TopPromotions = 'top-promotions',
        GenericVip = 'generic-vip',
        TopVip = 'top-vip',
        SidebarLeftTabItemLandingVip = 'sidebar-left-tab-item-landing-vip',
      }

      const enum Component {
        BaseMenu = 'BaseMenu',
      }

      // TODO: switch case
      if (navigationItem.routeName === RouteName.PROMOTIONS && component === String(Component.BaseMenu)) {
        clickMap({
          promo: 'sideMenuPromo',
        });
      }

      if (navigationItem.routeName === ServerRouteName.AFFILIATE_PROGRAM_WEB2
        || navigationItem.routeName === BaseRouteName.AFFILIATE_PROGRAM) {
        clickMap({
          affiliateProgramLink: true,
        });
      }
      // eslint-disable-next-line max-len
      if (navigationItem.routeName === RouteName.DEPOSITS && navigationItem.id === String(ServerNavigationIds.SideBarLeftDeposits)) {
        clickMap({
          deposit: 'sideMenuDeposit',
        });
      } else if (navigationItem.routeName === RouteName.DEPOSITS) {
        clickMap({
          deposit: 'bottomLowBalance',
        });
      }

      if (navigationItem.routeName === RouteName.DEPOSITS && !isLogin
        && navigationItem.id !== String(ServerNavigationIds.SideBarLeftDeposits)) {
        clickMap({
          enter: TargetParamEnter.BOTTOM_LOW_BALANCE,
        });
      }

      if (navigationItem.routeName === RouteName.PROFILE && !isLogin) {
        clickMap({
          enter: TargetParamEnter.BOTTOM_LOW_PROFILE,
        });
      }

      if (navigationItem.routeName === RouteName.PROMOTIONS
        && navigationItem.id === String(ServerNavigationIds.GenericPromotions)) {
        clickMap({
          promo: 'footer',
        });
      }

      if (navigationItem.routeName === RouteName.PROMOTIONS
        && navigationItem.id === String(ServerNavigationIds.TopPromotions)) {
        clickMap({
          promo: 'tab',
        });
      }

      if (process.env.VUE_APP_FEATURE_CASINO_ENABLED) {
        switch (navigationItem.props?.metrics) {
          case TargetParamNavigation.BUTTON_LOW_SLOTS:
            clickMap({ SLOTS: TargetParamNavigation.BUTTON_LOW_SLOTS });
            break;
          case TargetParamNavigation.SIDE_MENU_SLOTS:
            clickMap({ SLOTS: TargetParamNavigation.SIDE_MENU_SLOTS });
            break;
          case TargetParamNavigation.HEADER_SLOTS:
            clickMap({ SLOTS: TargetParamNavigation.HEADER_SLOTS });
            break;
          default:
            break;
        }
      }

      if (process.env.VUE_APP_FEATURE_SPORTLINE_ENABLED) {
        switch (navigationItem.props?.metrics) {
          case TargetParamNavigation.SIDE_MENU_LIVE:
            clickMap({ LIVE: TargetParamNavigation.SIDE_MENU_LIVE });
            break;
          case TargetParamNavigation.HEADER_LIVE:
            clickMap({ LIVE: TargetParamNavigation.HEADER_LIVE });
            break;
          default: break;
        }
      }

      if (process.env.VUE_APP_FEATURE_VIRTUAL_SPORT_ENABLED) {
        switch (navigationItem.props?.metrics) {
          case TargetParamNavigation.SIDE_MENU_VIRTUAL_SPORTS:
            clickMap({ virtualSports: TargetParamNavigation.SIDE_MENU_VIRTUAL_SPORTS });
            break;
          case TargetParamNavigation.HEADER_VIRTUAL_SPORTS:
            clickMap({ virtualSports: TargetParamNavigation.HEADER_VIRTUAL_SPORTS });
            break;
          default: break;
        }
      }

      if (navigationItem.routeName === RouteName.VIP) {
        if (navigationItem.id === String(ServerNavigationIds.GenericVip)) {
          push(AnalyticsEvent.Z_CLICK_TO_OPEN_VIP, {
            vip_program: {
              click_to_open: 'footer',
            },
          });
        }

        if (navigationItem.id === String(ServerNavigationIds.TopVip)) {
          push(AnalyticsEvent.Z_CLICK_TO_OPEN_VIP, {
            vip_program: {
              click_to_open: 'header',
            },
          });
        }

        if (navigationItem.id === String(ServerNavigationIds.SidebarLeftTabItemLandingVip)) {
          push(AnalyticsEvent.Z_CLICK_TO_OPEN_VIP, {
            vip_program: {
              click_to_open: 'sidebar',
            },
          });
        }
      }
    },

    makeDeposit(depositMetrics: DepositMetric & GoogleMetric): void {
      push(AnalyticsEvent.Z_MAKE_DEPOSITE, {
        deposits: {
          ok: {
            paymentSystemId: `dep${depositMetrics.paymentSystemId.toLowerCase()}`,
            firstDeposit: depositMetrics.firstDeposit,
          },
        },
      });
      const {
        systemAmount,
        paymentId,
        userId,
        firstTheme,
        theme,
      } = depositMetrics;
      const googleMetricsData = {
        ...depositMetrics,
        amount: systemAmount,
        transactionId: paymentId,
      };
      if (depositMetrics.firstDeposit) {
        GoogleService.getInstance().pushEvent('firstDeposit', googleMetricsData);
        push(AnalyticsEvent.Z_AB_TEST_NEW_USERS, {
          ab_test_light_mode: {
            [firstTheme.toLowerCase()]: {
              first_deposit: theme.toLowerCase(),
            },
          },
        });
        ZetaDspService.getInstance().firstDeposit({ systemAmount, paymentId, userId });
      } else {
        GoogleService.getInstance().pushEvent('makeDeposit', googleMetricsData);
        ZetaDspService.getInstance().makeDeposit({ systemAmount, paymentId, userId });
      }
    },

    registrationStart(): void {
      GoogleService.getInstance().pushEvent('customerRegistrationStart', {});
    },

    registrationSuccess(registrationMetrics: RegistrationMetric): void {
      const {
        userId,
        registrationType,
        firstTheme,
        theme,
      } = registrationMetrics;

      // @ts-ignore
      GoogleService.getInstance().pushEvent('customerRegistered', registrationMetrics);

      push(AnalyticsEvent.Z_AB_TEST_NEW_USERS, {
        ab_test_light_mode: {
          [firstTheme.toLowerCase()]: {
            registration: theme.toLowerCase(),
          },
        },
      });

      if ((process.env.VUE_APP_FEATURE_SIMPLIFIED_REGISTRATION_ENABLED || process.env.VUE_APP_FEATURE_STEP_REGISTRATION_ENABLED) && registrationType) {
        push(AnalyticsEvent.Z_LI_REGISTRATION_OK, {
          registration: {
            [registrationTypeFormat(registrationType)]: 'ok',
          },
        });
      }

      if (process.env.VUE_APP_FEATURE_TSUPIS_ENABLED) {
        push(AnalyticsEvent.Z_REGISTRATION, {
          registration: {
            ok: true,
            ...(firstTheme ? { defaultMode: firstTheme } : {}),
            ...(theme ? { registrationMode: theme } : {}),
          },
        });
      }
      ZetaDspService.getInstance().customerRegistered({
        userId,
      });
    },

    loginSuccess(options: YandexMetrikaOnLoginOptions & GoogleMetric): void {
      GoogleService.getInstance().pushEvent('login', { options });
      push(AnalyticsEvent.Z_LOGGED_IN, {
        login: {
          ok: {
            [options.loginTab]: {
              captchaChallengeWasShown: window.sessionStorage.getItem(captchaChallengeWasShownKey),
              captcha: options.captchaType,
              loginType: options.loginMethod,
            },
          },
        },
      });
    },

    yandexZInit(initOptions: YandexMetrikaInitOptions): void {
      const platform = process.env.VUE_APP_PLATFORM;
      assert(platform, 'VUE_APP_PLATFORM env must be present');
      push(AnalyticsEvent.Z_INIT, {
        userId: initOptions.userId,
        customerType: initOptions.customerType || 'normal',
        country: initOptions.country,
        type: {
          [platform]: `${process.env.VUE_APP_LAYOUT}`,
        },
        webVersion: process.env.VUE_APP_VERSION,
        ...(initOptions.currentTheme ? { mode: initOptions.currentTheme } : {}),
      });
    },

    // eslint-disable-next-line sonarjs/cognitive-complexity
    visitSportEvent(options: SportEventMetric & { markets: Market[] }): void {
      const { markets, ...parameters } = options;
      const primaryMarket = markets.find((market) => market.isPrimary);
      if (primaryMarket) {
        const { runners } = primaryMarket;
        const isDrawPossible = runners.length > 2;
        for (const [index, runner] of runners.entries()) {
          const { value } = runner;
          if (index === 0) parameters.oddsHome = value;
          if (index === 1) {
            if (isDrawPossible) {
              parameters.oddsDraw = value;
            } else {
              parameters.oddsAway = value;
            }
          }
          if (index === 2) parameters.oddsAway = value;
        }
      }

      const sportEventOptions: SportEventOptions = {
        match_id: parameters.eventId,
        sr_match_id: '',
        team_home_name: parameters.home,
        team_home_name_short: parameters.homeShort || '',
        team_home_logo: parameters.homeLogo || '',
        team_away_name: parameters.away,
        team_away_name_short: parameters.awayShort || '',
        team_away_logo: parameters.awayLogo || '',
        scheduled: parameters.scheduled,
        status: parameters.status,
        tournament_name: parameters.tournamentName,
        sport: parameters.sport,
        odds_home: parameters.oddsHome || '',
        odds_draw: parameters.oddsDraw || '',
        odds_away: parameters.oddsAway || '',
        language: parameters.language,
      };

      GoogleService.getInstance().pushEvent('sportEvent', { sportEvent: sportEventOptions });
      SportRadarService.getInstance().visitSportEvent(sportEventOptions);
    },

    pushEgsGameEvent(options: CasinoActiveGameEventOptions): void {
      GoogleService.getInstance().pushEvent('game', { options });
    },
  };
}
