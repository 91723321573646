import { toRef, watch } from 'vue';

import { whenClientNetworkIdle } from '@leon-hub/idle';
import { ScriptElement } from '@leon-hub/utils';
import { ZetaDcpConfigType } from '@leon-hub/api-sdk';

import getObjectFromPairs from 'web/src/utils/getObjectFromPairs';
import ZetaDspService from 'web/src/modules/analytics/services/zetaDspService';
import { useMetricsStore } from 'web/src/modules/analytics/store';

export default function initZetaDSP(): void {
  const metricsStore = useMetricsStore();
  const zetaDSPisEnabled = toRef(metricsStore, 'zetaDSPisEnabled');
  const zetaDSPMainType = toRef(metricsStore, 'zetaDSPMainType');
  const zetaDspAll = toRef(metricsStore, 'zetaDspAll');
  const zetaDspDeposit = toRef(metricsStore, 'zetaDspDeposit');
  const zetaDspFTD = toRef(metricsStore, 'zetaDspFTD');
  const zetaDspRegistration = toRef(metricsStore, 'zetaDspRegistration');
  const zetaDspLanding = toRef(metricsStore, 'zetaDspLanding');
  let script: ScriptElement;

  const unwatch = watch(zetaDSPisEnabled, (newValue) => {
    if (newValue) {
      const mainType = zetaDSPMainType.value;
      if (script === undefined && mainType?.params.length) {
        const parameters = getObjectFromPairs(mainType.params);

        if (parameters.src) {
          script = new ScriptElement({
            async: true,
            src: parameters.src,
          });
          void whenClientNetworkIdle({ interval: 300 }).then(() => {
            script.addScript().then(() => {
              const zetaDspService = ZetaDspService.getInstance();
              zetaDspService.setDefaultOptions({
                [ZetaDcpConfigType.ALL]: getObjectFromPairs(zetaDspAll.value),
                [ZetaDcpConfigType.DEPOSIT]: getObjectFromPairs(zetaDspDeposit.value),
                [ZetaDcpConfigType.FIRST_TIME_DEPOSIT]: getObjectFromPairs(zetaDspFTD.value),
                [ZetaDcpConfigType.REGISTRATION]: getObjectFromPairs(zetaDspRegistration.value),
              });

              zetaDspService.setArgsAndTrack(
                getObjectFromPairs(zetaDspLanding.value),
              );

              unwatch();
            }, (error) => {
              // silent
              // eslint-disable-next-line no-console
              console.warn(`Script append error: ${error}`);
            });
          });
        }
      }
    }
  }, {
    immediate: true,
  });
}
