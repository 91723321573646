import SimpleRegistrationFormService
  from 'web/src/modules/registration/services/SimpleRegistrationFormService';
import FullRegistrationFormService
  from 'web/src/modules/registration/submodules/tsupis-registration/services/FullRegistrationFormService';
import type { RegistrationFormService } from 'web/src/modules/registration/types';

let service: RegistrationFormService | undefined;

export default function useRegistrationFormService(): RegistrationFormService {
  if (!service) {
    if (process.env.VUE_APP_FEATURE_TSUPIS_ENABLED) {
      service = new FullRegistrationFormService();
    } else {
      service = new SimpleRegistrationFormService();
    }
  }

  return service;
}
