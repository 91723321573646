<script lang="ts" setup>
import type { IconNameType, OptionalAnyIconName } from '@leon-hub/icons';

import { SwitchKind } from 'web/src/components/Switch/VSwitch/enums';

import VListItemInner from '../VListItemInner/VListItemInner.vue';
import {
  ListItemSuffixAlign,
} from './enums';
import { useVListItem } from './composables';

interface VListItemProps {
  iconName?: OptionalAnyIconName;
  countryCode?: string;
  isNew?: boolean;
  title?: string;
  isTitleCentered?: boolean;
  badgeLabel?: string;
  counter?: string;
  haveExpand?: boolean;
  haveSwitcher?: boolean;
  switchKind?: SwitchKind;
  isSwitchChecked?: boolean;
  isSwitchDisabled?: boolean;
  suffixAlign?: ListItemSuffixAlign;
  noHover?: boolean;
  iconSuffixName?: IconNameType;
  isActive?: boolean;
  href?: string;
  progressValue?: number;
  noWrap?: boolean;
  haveProgress?: boolean;
  iconCustomStyles?: string;
  suffixText?: string;
  isBigMargin?: boolean;
  iconColored?: boolean;
  buttonLabel?: string;
}

interface VListItemEmits {
  (e: 'click', event: MouseEvent): void;
  (e: 'change-switcher', value: boolean): void;
  (e: 'click-switcher', event: KeyboardEvent): void;
  (e: 'click-button', value: boolean): void;
}

const props = withDefaults(defineProps<VListItemProps>(), {
  switchKind: SwitchKind.DEFAULT,
  suffixAlign: ListItemSuffixAlign.CENTER,
  title: '',
  iconCustomStyles: '',
  counter: '',
  href: '',
  iconName: undefined,
  iconSuffixName: undefined,
  countryCode: '',
  badgeLabel: '',
  progressValue: 0,
  buttonLabel: '',
});

const emit = defineEmits<VListItemEmits>();

const {
  classObject,
} = useVListItem(props);

function onClick(event: MouseEvent): void {
  emit('click', event);
}

const emitChangeSwitcher = (value: boolean) => {
  emit('change-switcher', value);
};

const emitClickButton = () => {
  emit('click-button', true);
};

const emitClickSwitcher = (event: KeyboardEvent) => {
  emit('click-switcher', event);
};
</script>

<template>
  <li v-auto-id="'VListItem'"
    :class="classObject"
  >
    <slot>
      <VListItemInner
        :title="title"
        :badge-label="badgeLabel"
        :suffix-text="suffixText"
        :counter="counter"
        :have-expand="haveExpand"
        :have-switcher="haveSwitcher"
        :suffix-align="suffixAlign"
        :is-new="isNew"
        :icon-name="iconName"
        :country-code="countryCode"
        :is-switch-disabled="isSwitchDisabled"
        :switch-kind="switchKind"
        :is-switch-checked="isSwitchChecked"
        :is-title-centered="isTitleCentered"
        :icon-suffix-name="iconSuffixName"
        :href="href"
        :progress-value="progressValue"
        :no-wrap="noWrap"
        :have-progress="haveProgress"
        :icon-colored="iconColored"
        :icon-custom-styles="iconCustomStyles"
        :button-label="buttonLabel"
        @click="onClick"
        @change-switcher="emitChangeSwitcher"
        @click-button="emitClickButton"
        @click-switcher="emitClickSwitcher"
      >
        <template
          v-if="$slots.prefix"
          #prefix
        >
          <slot name="prefix" />
        </template>
        <template
          v-if="$slots.inner"
          #inner
        >
          <slot name="inner" />
        </template>
      </VListItemInner>
    </slot>
  </li>
</template>
