import type { FreeBet } from '@leon-hub/api-sdk';
import { TransferType } from '@leon-hub/api-sdk';

export default function getFreebetMaxPrize(freebet: FreeBet, odds: number): number {
  const { transferType, amount } = freebet;
  if (transferType === TransferType.WIN) {
    return (odds * amount) - amount;
  }
  if (transferType === TransferType.BONUS) {
    return amount;
  }
  if (transferType === TransferType.WIN_PLUS_BONUS) {
    return odds * amount;
  }
  return 0;
}
