import { defineStore } from 'pinia';
import { ref } from 'vue';

import type { BaseRouteNameType } from '@leon-hub/routing-config-names';

const useRootNavigationStore = defineStore('root-navigation', () => {
  const isRouteContentLoading = ref<Partial<Record<BaseRouteNameType, boolean>>>({});

  function setOnlyFirstRouteContentLoading(name: BaseRouteNameType): void {
    if (isRouteContentLoading.value[name] !== undefined) {
      return;
    }

    isRouteContentLoading.value[name] = true;
  }

  function setRouteContentLoaded(name: BaseRouteNameType): void {
    isRouteContentLoading.value[name] = false;
  }

  return {
    isRouteContentLoading,
    setOnlyFirstRouteContentLoading,
    setRouteContentLoaded,
  };
});

export default useRootNavigationStore;
