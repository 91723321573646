import type { IPriceChangePolicy } from '@leon-hub/api-sdk';
import {
  priceChangePolicyAllowChanges,
  priceChangePolicyUndefined,
} from '@leon-hub/api-sdk';

export function getSavePriceChangePolicy(value?: IPriceChangePolicy | null): IPriceChangePolicy {
  if (!value || value === priceChangePolicyUndefined) {
    return priceChangePolicyAllowChanges;
  }
  return value;
}
