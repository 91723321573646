<script lang="ts" setup>
import VIframe from 'web/src/components/Iframe/VIframe/VIframe.vue';
import VReCaptcha from 'web/src/modules/captcha/components/VReCaptcha/VReCaptcha.vue';
import { useVCaptcha } from 'web/src/modules/captcha/components/VCaptcha/composables';

import type { VCaptchaEmits, VCaptchaProps } from './types';

const props = defineProps<VCaptchaProps>();
const emits = defineEmits<VCaptchaEmits>();

const {
  vCaptchaElement,
  iframeElement,
  iframeWidgetSrc,
  expanded,
  isDefaultStrategy,
  iframeName,
  additionalQueryStringParameters,
  iframeMounted,
  listenPostMessage,
  emitVerify,
  emitExpired,
  emitError,
  emitChallengeClosed,
  emitChallengeOpened,
  mapCaptchaType,
} = useVCaptcha(props, emits);
</script>

<template>
  <div v-auto-id="'VCaptcha'"
    v-show="captchaEnabled"
    :class="{
      [$style['captcha']]: true,
      [$style['captcha--expanded']]: expanded,
      [$style['captcha--modal']]: isDefaultStrategy,
    }"
  >
    <template
      v-if="useIframeForCaptcha"
    >
      <VIframe
        v-if="iframeWidgetSrc"
        ref="iframeElement"
        :src="iframeWidgetSrc"
        auto-height
        :name="iframeName"
        :additional-query-string-parameters="additionalQueryStringParameters"
        @vue:mounted="iframeMounted"
        @iframe-application-ready="listenPostMessage"
      />
    </template>
    <VReCaptcha
      v-else
      ref="vCaptchaElement"
      :type="mapCaptchaType(captchaType)"
      :theme="reCaptchaTheme"
      :language="$lang"
      :site-key="siteKey"
      :auto-execute="autoExecute"
      :api-url="apiUrl"
      @verify="emitVerify"
      @expired="emitExpired"
      @error="emitError"
      @challenge-is-closed="emitChallengeClosed"
      @challenge-is-opened="emitChallengeOpened"
    />
    <div
      v-if="error"
      :class="$style['captcha__hint']"
    >
      {{ error }}
    </div>
  </div>
</template>

<style lang="scss" module>
.captcha {
  padding-top: 5px;

  &--expanded {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: get($zIndex, captcha);
    padding-top: 0;
    background-color: rgba(55, 55, 55, 0.5);
  }

  &__hint {
    @include regular\13\16;

    display: block;
    width: 100%;
    padding-top: 4px;
    color: var(--ErrorDefault);
    transition: opacity 0.15s ease-in-out;
  }
}
</style>
