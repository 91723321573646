import type { FooterPartner } from 'web/src/modules/core/types';

// eslint-disable-next-line sonarjs/cognitive-complexity
export default function getPartnershipLogos(): FooterPartner[] {
  if (process.env.VUE_APP_FEATURE_SPORT_PARTNERSHIPS_DISABLED) {
    return [];
  }

  return [{
    id: '1',
    name: 'Российская Федерация Баскетбола',
    width: process.env.VUE_APP_LAYOUT_DESKTOP ? 61 : 41,
    height: process.env.VUE_APP_LAYOUT_DESKTOP ? 30 : 20,
  },
  {
    id: '2',
    name: 'Союз Биатлонистов России',
    width: process.env.VUE_APP_LAYOUT_DESKTOP ? 96 : 64,
    height: process.env.VUE_APP_LAYOUT_DESKTOP ? 30 : 20,
  },
  {
    id: '3',
    name: 'Хоккей России',
    width: process.env.VUE_APP_LAYOUT_DESKTOP ? 34 : 22,
    height: process.env.VUE_APP_LAYOUT_DESKTOP ? 34 : 22,
  },
  {
    id: '4',
    name: 'Всероссийская Федерация Волейбола',
    width: process.env.VUE_APP_LAYOUT_DESKTOP ? 77 : 52,
    height: process.env.VUE_APP_LAYOUT_DESKTOP ? 30 : 20,
  },
  {
    id: '5',
    name: 'Российский футбольный союз',
    width: process.env.VUE_APP_LAYOUT_DESKTOP ? 35 : 22,
    height: process.env.VUE_APP_LAYOUT_DESKTOP ? 40 : 26,
  },
  {
    id: '7',
    name: 'Национальная Федерация Бадминтона России',
    width: process.env.VUE_APP_LAYOUT_DESKTOP ? 117 : 78,
    height: process.env.VUE_APP_LAYOUT_DESKTOP ? 30 : 20,
  },
  {
    id: '8',
    name: 'Федерация гандбола России',
    width: process.env.VUE_APP_LAYOUT_DESKTOP ? 34 : 22,
    height: process.env.VUE_APP_LAYOUT_DESKTOP ? 34 : 22,
  },
  {
    id: '9',
    name: 'Федерация хоккея с мячом России',
    width: process.env.VUE_APP_LAYOUT_DESKTOP ? 34 : 22,
    height: process.env.VUE_APP_LAYOUT_DESKTOP ? 34 : 22,
  }];
}
