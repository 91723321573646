import type { SandboxContext } from '@leon-hub/script-sandbox';
import { sandboxApi } from '@leon-hub/script-sandbox';

import type { ShieldSdkApi } from '../types';

export const createShieldApi = (scriptSdkPath: string): Promise<SandboxContext<ShieldSdkApi>> => sandboxApi<ShieldSdkApi>({
  id: 'shield-api',
  strict: false,
  scripts: [scriptSdkPath],
  origin: process.env.VUE_APP_PLATFORM_CORDOVA ? 'https://y2ec6ohodgs' : undefined,
});
