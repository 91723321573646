<script setup lang="ts">
import { toRef } from 'vue';

import { ExtendedEventBlock } from 'web/src/modules/sportline/submodules/extended-event/enums';
import { createSportlineFragmentBasisKeyValue } from 'web/src/modules/sportline/composables/list/utils';
import SportlineSportBlock from 'web/src/modules/sportline/views/SportBlock/SportlineSportBlock.vue';
import SportEventsOutrightHeadline from 'web/src/modules/sportline/components/SportlineOutrightHeadline/SportlineOutrightHeadline.vue';

import { useRelatedEventsTable } from './useRelatedEventsTable';
import type {
  RelatedEventsComponentProps,
  RelatedEventsComponentEmits,
} from './types';

interface RelatedEventsTableProps extends RelatedEventsComponentProps {
  withoutHeadline?: boolean;
}

const props = defineProps<RelatedEventsTableProps>();
const emit = defineEmits<RelatedEventsComponentEmits>();

const activeId = toRef(props, 'activeId', null);
const sportElement = toRef(props, 'sportElement', null);
const isOnlyOutrightShown = toRef(props, 'isOnlyOutrightShown', false);
const withoutHeadline = toRef(props, 'withoutHeadline', false);

const {
  outrightEvents,
  doShowOutrightEvents,
} = useRelatedEventsTable({ sportElement });
</script>

<template>
  <div v-auto-id="'RelatedEventsTable'"
    v-if="sportElement"
    :class="$style['related-events']"
  >
    <SportlineSportBlock
      v-if="!isOnlyOutrightShown"
      :sport-element="sportElement"
      :without-headline="withoutHeadline"
      :active-event-id="activeId"
      :can-add-to-favorite="!!'1'"
      :extended-block-type="ExtendedEventBlock.RelatedEvents"
      :basis-key="createSportlineFragmentBasisKeyValue({ sportId: sportElement.sport.id })"
      @click-event="emit('click-event')"
    />

    <template v-if="doShowOutrightEvents">
      <SportEventsOutrightHeadline v-if="!isOnlyOutrightShown" />

      <SportlineSportBlock
        v-for="outrightSportElement in outrightEvents"
        :key="`outright_${outrightSportElement.sport.id}`"
        :sport-element="outrightSportElement"
        :is-market-types-selection-enabled="false"
        is-hide-league-info
        is-column-names-display-disabled
        without-headline
        is-display-main-events-disabled
        is-display-outright-headline-disabled
        is-display-outright-events-enabled
        :can-add-to-favorite="!!'1'"
        :can-add-outrights-to-favorite="!!'1'"
        :extended-block-type="ExtendedEventBlock.RelatedEvents"
        :basis-key="createSportlineFragmentBasisKeyValue({ sportId: outrightSportElement.sport.id, salt: 'outrights' })"
      />
    </template>
  </div>
</template>

<style module lang="scss">
.related-events {
  background-color: var(--Layer0);
}
</style>
