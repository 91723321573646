import type { JSONSchema } from '@leon-hub/form-utils';
import { FormControlType } from '@leon-hub/form-utils';

import type {
  FormData,
  FormWidget,
  FormUiSchemaField,
  FormWidgetExtraProperties,
} from '../../../../types/common';

export default function getFormWidgetSchemaProperties(
  fieldSchema: JSONSchema = {},
  fieldUiSchema: FormUiSchemaField = {},
  formData: FormData = {},
): FormWidgetExtraProperties {
  const widgetsWithRelatedFields: FormWidget[] = [
    FormControlType.AutocompleteAddress,
    FormControlType.CurrencySelect,
  ];
  return {
    enum: fieldSchema.enum,
    maxLength: fieldSchema.maxLength,
    minLength: fieldSchema.minLength,
    pattern: 'pattern' in fieldSchema ? fieldSchema.pattern : undefined,
    labels: 'labels' in fieldUiSchema ? fieldUiSchema.labels : undefined,
    ...(fieldUiSchema.widget && widgetsWithRelatedFields.includes(fieldUiSchema.widget) ? { formData } : {}),
  };
}
