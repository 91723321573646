import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import { Theme } from '@leon-hub/api-sdk';

import { ProductSpecificName } from '../enums';
import type { VLogoProps } from '../types';

export interface VLogoComposable {
  alt: ComputedRef<string>;
  src: ComputedRef<string>;
}

export default function useVLogo(props: VLogoProps): VLogoComposable {
  const defaultName = ProductSpecificName.LOGO;

  const alt = computed(() => {
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    const logoName = props.productSpecificName || props.name || defaultName;
    return logoName.split('.')[0];
  });

  const src = computed(() => {
    const themeSuffix = props.theme === Theme.LIGHT ? '-light' : '';
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    const dynamicSrcLogoName = `${props.productSpecificName || defaultName}${themeSuffix}`;
    const dynamicSource = require(`web/src/assets/logotypes/${process.env.VUE_APP_PRODUCT}/${process.env.VUE_APP_SKIN}/brand-default/${dynamicSrcLogoName}.svg`);

    const srcByProductSpecificName = props.productSpecificName && dynamicSource;
    const srcByName = props.name && require(`web/src/assets/logotypes/common/${props.name}.svg`);
    const srcMascot = props.mascotName && require(`web/src/assets/mascot/${process.env.VUE_APP_PRODUCT}/${process.env.VUE_APP_SKIN}/${props.mascotName}.svg`);
    return srcByProductSpecificName || srcByName || srcMascot || dynamicSource;
  });

  return {
    src,
    alt,
  };
}
