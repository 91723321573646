import type { NavigationItem } from '@leon-hub/navigation-config';

export default function isActiveSubCategory(
  item: NavigationItem,
  actionUrl: string | string[],
  categoryId: string | string[],
): boolean {
  if (!item.parentId) {
    return actionUrl === item.id;
  }

  const isChildren = !!item.children?.find((child) => child.id === actionUrl);

  return isChildren || categoryId === item.id;
}
