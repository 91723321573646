import { ComponentKey, ComponentStatus } from '../types';
import type { ComponentResult } from '../types';
import AbstractFingerprintComponent from '../AbstractFingerprintComponent';

export default class HardwareConcurrencyComponent extends AbstractFingerprintComponent {
  constructor() {
    super(ComponentKey.HardwareConcurrency);
  }

  getComponentResult(): Promise<ComponentResult> {
    return this.result(
      window.navigator.hardwareConcurrency
        ? [window.navigator.hardwareConcurrency]
        : ComponentStatus.NotAvailable,
    );
  }
}
