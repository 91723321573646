import type { Ref } from 'vue';
import { computed } from 'vue';

import type { SportlineEvent } from 'web/src/modules/sportline/types';

interface UseSportlineEventChampionshipNameProps {
  sportlineEvent: Ref<SportlineEvent>;
}

interface UseSportlineEventChampionshipNameComposable {
  championshipName: Ref<Maybe<string>>;
  outrightName: Ref<Maybe<string>>;
}

export function useSportlineEventChampionshipName(
  props: UseSportlineEventChampionshipNameProps,
): UseSportlineEventChampionshipNameComposable {
  const { sportlineEvent } = props;

  const championshipName = computed(() => sportlineEvent.value.championshipName);
  const outrightName = computed(() => sportlineEvent.value.outrightName);

  return {
    championshipName,
    outrightName,
  };
}
