/**
 * @description use this enum in view to represent form state if form have more than just initial|pending states
 * This enum is not directly used by form itself
 * */
export enum FormState {
  INITIAL = 'initial',
  PENDING = 'pending',
  SUCCESS = 'success',
  ERROR = 'error',
}
