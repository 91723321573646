import { watch } from 'vue';

import { useElementSize } from '@leon-hub/vue-utils/src/element-size';

import type {
  RecycleScrollerResourcesContainer,
} from '../types';
import type {
  RecycleScrollerCalculateRange,
} from '../utils';

export function useWatchForSizes(
  listenerTargetRef: RecycleScrollerResourcesContainer,
  containerRef: RecycleScrollerResourcesContainer,
  calculateRange: RecycleScrollerCalculateRange,
): void {
  const size = useElementSize(listenerTargetRef);
  watch([size.width, size.height, containerRef], () => { calculateRange(); });
}
