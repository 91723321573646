import type { Ref, MaybeRef } from 'vue';
import {
  reactive,
  toRef,
  toValue,
  provide,
  readonly,
  computed,
} from 'vue';

import type {
  MarketColumnId,
  MarketType,
  MarketsColumn,
} from 'web/src/modules/sportline/types';
import type { MarketColumnsInfo } from 'web/src/modules/sportline/composables/markets/types';
import type { SportlineFragmentBasisKey } from 'web/src/modules/sportline/types/list';
import { selectMarketTypeByColumn } from 'web/src/modules/sportline/utils/markets';
import {
  getMarketColumnsFromMarketColumnsInfo,
  getMarketTypesFromMarketColumnsInfo,
} from 'web/src/modules/sportline/composables/markets/utils';

import type { ProvidedSelectedColumnMap, SelectColumnPayload } from '../types';
import { ProvideSelectedColumnKey, ProvideSelectColumnMethodKey } from '../constants';

export type SelectedColumnInfoElement = MarketColumnsInfo & { key: SportlineFragmentBasisKey };

type SelectedInfoMap<T> = Record<SportlineFragmentBasisKey, T>;

interface UseProvideSelectedColumnProps {
  sportlineElements: MaybeRef<SelectedColumnInfoElement[]>;
}

interface UseProvideSelectedColumnComposable {
  selectedMarketTypes: Ref<SelectedInfoMap<Maybe<MarketType>>>;
  selectedColumnMap: Ref<ProvidedSelectedColumnMap>;
  selectColumn(payload: SelectColumnPayload): void;
}

export function useProvideSelectedColumn(
  props: UseProvideSelectedColumnProps,
): UseProvideSelectedColumnComposable {
  const sportlineElements: Ref<SelectedColumnInfoElement[]> = toRef(props.sportlineElements);

  const selectedColumnIdMap = reactive<SelectedInfoMap<Maybe<MarketColumnId>>>({});

  const columnsMap = computed<SelectedInfoMap<MarketsColumn[]>>(() => {
    const elements = sportlineElements.value;
    const result: SelectedInfoMap<MarketsColumn[]> = {};

    for (const element of elements) {
      const { key } = element;
      if (!key) { continue; }

      result[key] = getMarketColumnsFromMarketColumnsInfo(element);
    }

    return result;
  });

  const marketTypesMap = computed<SelectedInfoMap<MarketType[]>>(() => {
    const elements = sportlineElements.value;
    const result: SelectedInfoMap<MarketType[]> = {};

    for (const element of elements) {
      const { key } = element;
      if (!key) { continue; }

      result[key] = getMarketTypesFromMarketColumnsInfo(element);
    }

    return result;
  });

  const selectedMarketTypes = computed<SelectedInfoMap<Maybe<MarketType>>>(() => {
    const elements = sportlineElements.value;
    const result: SelectedInfoMap<Maybe<MarketType>> = {};

    for (const element of elements) {
      const { key } = element;
      if (!key) { continue; }

      const columns = columnsMap.value[key] ?? [];
      const allMarketsTypes = marketTypesMap.value[key] ?? [];
      const selectedColumnId = selectedColumnIdMap[key] ?? null;

      result[key] = selectMarketTypeByColumn({ columns, allMarketsTypes, selectedColumnId });
    }

    return result;
  });

  const selectedColumnMap = computed<ProvidedSelectedColumnMap>(() => {
    const result: ProvidedSelectedColumnMap = {};
    const selectedTypesValue = selectedMarketTypes.value;

    for (const id of Object.keys(selectedTypesValue)) {
      const selectedColumnId = selectedColumnIdMap[id] ?? null;
      const columns = columnsMap.value[id] ?? [];
      const selected = selectedTypesValue[id] ?? null;

      result[id] = {
        selectedColumnId,
        selectedColumnSize: selected?.columns ?? null,
        selectedColumnSign: selected?.sign ?? null,
        columns,
      };
    }

    return result;
  });

  function selectColumn({ basisKey, columnId }: SelectColumnPayload): void {
    selectedColumnIdMap[toValue(basisKey)] = toValue(columnId);
  }

  provide(ProvideSelectedColumnKey, readonly(selectedColumnMap));
  provide(ProvideSelectColumnMethodKey, selectColumn);

  return {
    selectedMarketTypes,
    selectedColumnMap,
    selectColumn,
  };
}
