import {
  isArrayOfStrings,
  isBoolean,
  isObject,
} from '@leon-hub/guards';

import type {
  GetCasUserResponse,
  LoginResponse,
} from './types';

export function isCasUserResponse(argument: unknown): argument is GetCasUserResponse {
  return isObject(argument)
    && isBoolean(argument.cookiesOk)
    && isArrayOfStrings(argument.usernames);
}

export function isLoginResponse(argument: unknown): argument is LoginResponse {
  return isObject(argument) && typeof argument.username === 'string' && typeof argument.ticket === 'string';
}
