import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import type {
  CasinoSearchTabsSwitcherEmits,
} from 'web/src/modules/search/submodules/casino/components/CasinoSearchTabsSwitcher/types';
import { SearchTab } from 'web/src/modules/search/enums';
import { useI18n } from 'web/src/modules/i18n/composables';
import type { SwitcherOption } from 'web/src/components/Switcher/VSwitcher/types';

interface CasinoSearchTabsSwitcherComposable {
  switcherOptions: ComputedRef<SwitcherOption[]>;
  emitChange: (index: string) => void;
}

export default function useCasinoSearchTabsSwitcher(
  emits: CasinoSearchTabsSwitcherEmits,
): CasinoSearchTabsSwitcherComposable {
  const { $translate } = useI18n();

  const switcherOptions = computed<SwitcherOption[]>(() => {
    const options: SwitcherOption[] = [];

    if (process.env.VUE_APP_FEATURE_SPORTLINE_ENABLED) {
      options.push({
        id: SearchTab.SPORTS,
        label: $translate('JSPBET_SPORT_TYPES').value,
      });
    }

    if (process.env.VUE_APP_FEATURE_CASINO_ENABLED) {
      options.push({
        id: SearchTab.SLOTS,
        label: $translate('TABS_CASINO').value,
      });
    }

    return options;
  });

  function emitChange(index: string): void {
    emits('change', index);
  }

  return {
    switcherOptions,
    emitChange,
  };
}
