import throttle from 'lodash/throttle';

import { normalizeError } from '@leon-hub/errors';
import type { InnerModuleApi } from '@leon-hub/app-module';
import {
  SocketWrapper,
  SocketEmitter,
} from '@leon-hub/websocket';

interface CustomerActivityEvent {
  'type':'customer_activity';
  'payload':{
    'type': 'click' | 'keyup';
  };
}

export class ActivityReporter {
  private readonly socketWrapper: SocketWrapper;

  private readonly emitter : SocketEmitter<CustomerActivityEvent>;

  constructor(private options: {
    activityThrottle: number;
    url: string;
    api: InnerModuleApi;
  }) {
    this.socketWrapper = new SocketWrapper({
      openTimeout: 20_000,
      url: this.options.url,
    });
    this.emitter = new SocketEmitter(this.socketWrapper);
  }

  start(): void {
    const { bus } = this.options.api;
    const onClick = throttle(() => {
      this.emitter.emit('customer_activity', {
        type: 'click',
        // eslint-disable-next-line no-console
      }).catch((err: unknown) => bus.emit('log:error', normalizeError(err)));
    }, this.options.activityThrottle, { trailing: false });
    const onKeyUp = throttle(() => {
      this.emitter.emit('customer_activity', {
        type: 'keyup',
        // eslint-disable-next-line no-console
      }).catch((err: unknown) => bus.emit('log:error', normalizeError(err)));
    }, this.options.activityThrottle, { trailing: false });
    document.addEventListener('click', onClick, true);
    document.addEventListener('keyup', onKeyUp, true);
    window.addEventListener('beforeunload', () => {
      this.socketWrapper.detach();
    });
  }
}
