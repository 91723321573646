import type { ComputedRef } from 'vue';
import { computed, ref, watch } from 'vue';

import { assert, isObject } from '@leon-hub/guards';
import { FormControlType } from '@leon-hub/form-utils';

import type { VButtonProps } from 'web/src/components/Button/VButton/types';
import type { VCaptchaProperties } from 'web/src/components/Input';
import { isVCaptchaProperties } from 'web/src/components/Input/guards/isVCaptchaProperties';

import { isFormWrapperWidgetPropertiesByType } from '../../../guards/isFormWrapperWidgetProperties';
import type {
  FormWidgetType,
  FormFieldProps,
  StatelessFormField,
  FormWrapperWidgetProperties,
} from '../../../types';
import getWidgetTypeList from './utils/getWidgetTypeList';
import { getSubmitButtonProperties, getUiSchemaField, hasSubmitButton } from '../../../utils/uiSchema';
import mergeErrors from '../../../utils/mergeErrors';
import getFormWidgetSchemaProperties from './utils/getFormWidgetSchemaProperties';
import getFormWrapperWidgetProperties from './utils/getFormWrapperWidgetProperties';
import type { UseFormFieldsOutput, UseFormFieldsProps } from './types';

export default function useFormFields({
  schema,
  uiSchema,
  isFormReady,
  isPending,
  externalErrors,
  schemaErrors,
  formData,
}: UseFormFieldsProps): UseFormFieldsOutput {
  const regularWidgetList = ref<FormWidgetType[]>([]);
  const captchaWidgetList = ref<FormWidgetType[]>([]);

  const updateWidgetLists = (): void => {
    const { widgetTypeList, captchaWidgets } = getWidgetTypeList(uiSchema.value);
    regularWidgetList.value = widgetTypeList;
    captchaWidgetList.value = captchaWidgets;
  };

  watch(uiSchema, updateWidgetLists, { immediate: true });

  const fields = computed<StatelessFormField[]>(() => {
    const errors = mergeErrors(externalErrors.value, schemaErrors.value);
    return regularWidgetList.value.map(({ type, field }) => {
      const fieldUiSchema = getUiSchemaField(uiSchema.value, field);
      const widgetProps = getFormWrapperWidgetProperties({
        name: field,
        fieldUiSchema,
        value: formData.value[field],
        error: errors[field],
        extraProperties: getFormWidgetSchemaProperties(schema.value.properties[field], fieldUiSchema, formData.value),
      });
      const fieldProps: FormFieldProps = {
        hidden: fieldUiSchema.hidden ?? fieldUiSchema.widget === FormControlType.Hidden,
        shortWidth: fieldUiSchema.shortWidth,
      };
      return {
        widget: type, widgetProps, id: field, fieldProps,
      };
    });
  });

  const captchaProps = computed<FormWrapperWidgetProperties<VCaptchaProperties | {}>[]>(() => {
    const result = [];
    if (!captchaWidgetList.value.length) {
      return [];
    }
    for (const { field, type } of captchaWidgetList.value) {
      const fieldUiSchema = getUiSchemaField(uiSchema.value, field);
      const widgetProps = getFormWrapperWidgetProperties({
        name: field,
        fieldUiSchema,
        value: formData.value[field],
        error: '',
        extraProperties: {},
      });

      assert((type === FormControlType.Captcha && isFormWrapperWidgetPropertiesByType(widgetProps, isVCaptchaProperties))
          || (type === FormControlType.CaptchaV3 && isObject(widgetProps)));
      if ((type === FormControlType.Captcha && isFormWrapperWidgetPropertiesByType(widgetProps, isVCaptchaProperties))
          || type === FormControlType.CaptchaV3) {
        result.push(widgetProps);
      }
    }
    return result;
  });

  const buttonProps: ComputedRef<VButtonProps | null> = computed(() => {
    if (!hasSubmitButton(uiSchema.value)) {
      return null;
    }
    const properties = getSubmitButtonProperties(uiSchema.value);
    return {
      ...properties,
      isLoading: isPending.value,
      disabled: !isFormReady.value,
    };
  });

  return {
    fields,
    buttonProps,
    captchaProps,
    captchaWidgetList,
  };
}
