import type { I18nFunctionReactive } from 'web/src/modules/i18n/types';
import { PresetName } from 'web/src/modules/dialogs/enums';
import type { ShowDialogOptions } from 'web/src/modules/dialogs/types';

export default function getModalErrorHandlerProperties(
  message: string,
  $t: I18nFunctionReactive,
): ShowDialogOptions {
  return {
    presetName: PresetName.ALERT_WARNING,
    options: {
      confirmMessage: message,
      title: $t('WEB2_DEPOSIT_ERROR').value,
    },
  };
}
