import { doScheduleMeeting } from '@leon-hub/api-sdk';
import type { ScheduleMeetingResponse } from '@leon-hub/api-sdk';

import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';

export default function scheduleMeeting({
  meetingSlotId,
  rescheduledMeetingSlotId,
}: {
  meetingSlotId: number;
  rescheduledMeetingSlotId?: number;
}): Promise<ScheduleMeetingResponse> {
  const apiClient = useGraphqlClient();
  return doScheduleMeeting(apiClient,
    (node) => node.mutations.verification.scheduleMeeting,
    {
      options: {
        meetingSlotId,
        ...(rescheduledMeetingSlotId ? { rescheduledMeetingSlotId } : {}),
      },
    });
}
