<script setup lang="ts">
import { TextInputTypes } from 'web/src/components/Input/enums';

import type { BaseInputEmits, InputWrappedProps } from '../../types';
import TextInput from '../TextInput';
import useCardExpirationDateInput from './useCardExpirationDateInput';

withDefaults(defineProps<InputWrappedProps>(), {
  name: 'CardExpirationDate',
});
const emits = defineEmits<BaseInputEmits>();

defineOptions({
  inheritAttrs: false,
});

const {
  onFocus, onBlur, onChange, onInput, maskOptions,
} = useCardExpirationDateInput(emits);
</script>

<template>
  <TextInput v-auto-id="'CardExpirationDateInput'"
    v-data-test="{
      el: 'card-expiration-date-input',
      name: name,
    }"
    :autofocus="autofocus"
    :value="value"
    :name="name"
    :label="label"
    :disabled="disabled"
    :hint="hint"
    :hint-link="hintLink"
    :hint-right="hintRight"
    :hint-link-right="hintLinkRight"
    :error="error"
    :error-right="errorRight"
    :mask="maskOptions"
    :type="TextInputTypes.TEL"
    :is-large="isLarge"
    is-ym-disable-keys
    :placeholder="$t('WEB2_CARD_EXPIRATION_DATE_PLACEHOLDER')"
    autocomplete="cc-exp"
    @input="onInput"
    @change="onChange"
    @focus="onFocus"
    @blur="onBlur"
  />
</template>
