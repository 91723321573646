import { CustomerNotificationMessageSource } from 'web/src/modules/customer-notifications/enums';
import type {
  CustomerNotificationMessage,
} from 'web/src/modules/customer-notifications/types';

import type {
  CustomerNotificationDocument,
} from '../types';

export default function createMessageFromCms(notification: CustomerNotificationDocument): CustomerNotificationMessage {
  return {
    id: notification.id,
    key: `${notification.id}-${CustomerNotificationMessageSource.CMS}`,
    title: notification.title,
    message: notification.message,
    createdAt: notification.timestampCreation,
    isRead: notification.status === 1,
    isModal: false,
    source: CustomerNotificationMessageSource.CMS,
  };
}
