import { IconName, IconSize } from '@leon-hub/icons';

import type { CustomerSportListElement } from 'web/src/modules/sportline/submodules/favorites/types';
import { CustomFilter } from 'web/src/modules/sportline/enums';
import { resolveSportsPageLink } from 'web/src/modules/sportline/submodules/navigation/store/utils';
import { sortTopLeagueElementsASC } from 'web/src/modules/sportline/utils';

import type { FilterTab, ActiveTopLeagueListElement } from '../types';
import { prepareTopLeagueListElementsForFilterTab } from './prepareTopLeagueListElementsForFilterTab';

export function createFilterTabForFavoriteLeagues(
  favoriteSports: CustomerSportListElement[] | Readonly<CustomerSportListElement[]>,
  isActive = false,
): Maybe<FilterTab> {
  const favoritesLeagues: ActiveTopLeagueListElement[] = (favoriteSports ?? [])
    .flatMap(({ leagues }) => prepareTopLeagueListElementsForFilterTab(leagues));

  if (!favoritesLeagues.length) { return null; }

  return {
    key: CustomFilter.MyFavoriteLeagues,
    filterKey: CustomFilter.MyFavoriteLeagues,
    isActive,
    leagues: favoritesLeagues.sort(sortTopLeagueElementsASC),
    totalCount: favoritesLeagues.length,
    allLeaguesLocation: resolveSportsPageLink({ urlName: CustomFilter.Favorites }),
    tabItem: {
      id: CustomFilter.MyFavoriteLeagues,
      icon: {
        name: IconName.STAR_ALT,
        size: IconSize.SIZE_16,
      },
    },
  };
}
