import type { CustomProperties } from '../types';
import addToPropertiesMap from './addToPropertiesMap';

export default function updatePropertiesMap<KeyType = string>(
  currentMap: Map<KeyType, CustomProperties>,
  key: KeyType,
  properties: CustomProperties,
): Map<KeyType, CustomProperties> {
  const existingProperties = currentMap.get(key) ?? {};
  return addToPropertiesMap<KeyType>(currentMap, key, {
    ...existingProperties,
    ...properties,
  });
}
