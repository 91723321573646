import type { Ref } from 'vue';
import { computed } from 'vue';

import type {
  SportElement,
  OutrightSportlineEvent,
} from 'web/src/modules/sportline/types';
import { filterSportsListEvents } from 'web/src/modules/sportline/utils';

interface UseRelatedEventsTableProps {
  sportElement: Ref<Maybe<SportElement>>;
}

interface UseRelatedEventsTableComposable {
  outrightEvents: Ref<SportElement<OutrightSportlineEvent>[]>;
  doShowOutrightEvents: Ref<boolean>;
}

export function useRelatedEventsTable(
  props: UseRelatedEventsTableProps,
): UseRelatedEventsTableComposable {
  const { sportElement } = props;

  const outrightEvents = computed<SportElement<OutrightSportlineEvent>[]>(
    () => filterSportsListEvents<OutrightSportlineEvent>(
      (sportElement.value ? [sportElement.value as SportElement<OutrightSportlineEvent>] : []),
      (item) => item.sportEvent.isOutright,
    ),
  );
  const doShowOutrightEvents = computed<boolean>(() => outrightEvents.value.length > 0);

  return {
    outrightEvents,
    doShowOutrightEvents,
  };
}
