<script lang="ts" setup>
import { Tag } from '@leon-hub/tags';
import { RouteName } from '@leon-hub/routing-config-names';
import {
  ButtonHeight,
  ButtonKind,
} from '@leon-hub/module-buttons';

import SButton from 'web/src/components/Button/SButton/SButton.vue';
import LButton from 'web/src/components/Button/LButton/LButton.vue';

import { useHeaderRegistration } from './composables';

const {
  onLoginClick,
  onRegisterClick,
} = useHeaderRegistration();
</script>

<template>
  <div v-auto-id="'HeaderRegistration'" :class="$style['header-registration']">
    <template v-if="null">
      <SButton
        kind="primary"
        size="small"
        :label="$t('WEB2_DO_LOGIN')"
        :tag="Tag.ROUTER_LINK"
        :href="$router.resolve({ name: RouteName.LOGIN }).fullPath"
        @click="onLoginClick"
      />
      <SButton
        kind="quaternary-secondary"
        size="small"
        :label="$t('JSPNAV_USTAT_REGISTER')"
        :tag="Tag.ROUTER_LINK"
        :href="$router.resolve({ name: RouteName.REGISTRATION }).fullPath"
        @click="onRegisterClick"
      />
    </template>
    <template v-else>
      <LButton
        :label="$t('WEB2_DO_LOGIN')"
        :tag="Tag.ROUTER_LINK"
        :href="$router.resolve({ name: RouteName.LOGIN }).fullPath"
        :height="'1' ? ButtonHeight.SMALL : ButtonHeight.TINY"
        :kind="'1'? ButtonKind.TRANSPARENT_DARK_VERSION : ButtonKind.TRANSPARENT"
        :is-uppercase="false"
        @click="onLoginClick"
      />
      <LButton
        :label="$t('JSPNAV_USTAT_REGISTER')"
        :tag="Tag.ROUTER_LINK"
        :href="$router.resolve({ name: RouteName.REGISTRATION }).fullPath"
        :height="'1' ? ButtonHeight.SMALL : ButtonHeight.TINY"
        :kind="'1'? ButtonKind.PRIMARY_DARK_VERSION : ButtonKind.PRIMARY"
        :is-uppercase="false"
        @click="onRegisterClick"
      />
    </template>
  </div>
</template>

<style lang="scss" module>
.header-registration {
  display: flex;
  flex-direction: row;
  gap: $headerRegistrationSizeBetweenButtonsPhone;

  @include screen-desktop-small-min {
    gap: $headerRegistrationSizeBetweenButtonsDesktop;
  }
}
</style>
