import type { Ref } from 'vue';
import { ref } from 'vue';

import { Timer } from '@leon-hub/utils';

import type { DisplayedTimeLeft, ValueOfTimeLeft } from 'web/src/utils/countdown';
import { Countdown } from 'web/src/utils/countdown';
import { useI18n } from 'web/src/modules/i18n/composables';
import type { VCountdownEmits, VCountdownProps } from 'web/src/components/Countdown/VCountdown/types';
import {
  getDays, getHours, getMinutes, getSeconds,
} from 'web/src/components/Countdown/VCountdown/composable/utils';
import { CountdownType } from 'web/src/components/Countdown/VCountdown/enum';

interface VCountdownComposable {
  updateNow: (force?: true) => void;
  clearNow: () => void;
  clearTimer: () => void;
  timeLabel: Ref<string>;
}

export default function useVCountdown(
  props: VCountdownProps,
  emit: VCountdownEmits,
): VCountdownComposable {
  const { $translate } = useI18n();

  const timeLabel = ref('');
  const hasEmitted = ref(false);
  let now: number | null = null;
  let timer = 0;

  function getCountdown(): Countdown | null {
    if (!now || !props.expirationDate) {
      return null;
    }
    return new Countdown(props.expirationDate, now);
  }

  function getDisplayedTimeLeft(): DisplayedTimeLeft | null {
    const countdown = getCountdown();

    if (!countdown) return null;
    return countdown.getDisplayedTimeLeft();
  }

  function getTimeLeft(): ValueOfTimeLeft | null {
    const countdown = getCountdown();

    if (!countdown) return null;
    return countdown.getTimeLeft();
  }

  function checkIfTimerEnd(timeLeft: ValueOfTimeLeft | null): boolean | null {
    if (!timeLeft) {
      return null;
    }
    return Boolean(
      timeLeft.days === 0
      && timeLeft.hours === 0
      && timeLeft.minutes === 0
      && timeLeft.seconds === 0,
    );
  }
  function getTimeLabel(): string {
    const timeLeft = getTimeLeft();
    const displayedTimeLeft = getDisplayedTimeLeft();

    if (checkIfTimerEnd(timeLeft)) {
      if (!hasEmitted.value) {
        hasEmitted.value = true;
        emit('countdown-end');
        clearTimer();
      }
      return '';
    }
    let hours;
    let minutes;

    if (props.countdownType === CountdownType.HOURS_MINUTES) {
      hours = getHours(timeLeft, displayedTimeLeft, $translate, true);
      minutes = getMinutes(timeLeft, displayedTimeLeft, props.isShortNames, $translate);

      return `${hours} ${minutes}`;
    }

    const days = getDays(timeLeft, displayedTimeLeft, $translate);
    const seconds = getSeconds(timeLeft, displayedTimeLeft, $translate);
    hours = getHours(timeLeft, displayedTimeLeft, $translate);
    minutes = getMinutes(timeLeft, displayedTimeLeft, props.isShortNames, $translate);

    return `${days} ${hours} ${minutes} ${seconds}`;
  }

  function updateNow(force?: true): void {
    if (force || now) {
      now = Date.now();
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      timer = Timer.setTimeout(updateNow, 1000);
    }
    timeLabel.value = getTimeLabel();
    emit('countdown-change', timeLabel.value);
  }

  function clearNow(): void {
    now = null;
  }

  function clearTimer(): void {
    if (timer) {
      Timer.clearTimeout(timer);
      timer = 0;
    }
  }

  return {
    updateNow,
    clearNow,
    clearTimer,
    timeLabel,
  };
}
