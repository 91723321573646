import type { ComponentInternalInstance } from '@vue/runtime-core';
import { getCurrentInstance, nextTick, onMounted } from 'vue';

type Target = ComponentInternalInstance | null;

function getLifeCycleTarget(target?: Target): typeof target {
  return target ?? getCurrentInstance();
}

/**
 * Call onMounted() if it's inside a component lifecycle, if not, just call the function
 *
 * @param fn
 * @param sync if set to false, it will run in the nextTick() of Vue
 * @param target
 */
export function tryOnMounted(fn: () => void, sync = true, target?: Target): void {
  const instance = getLifeCycleTarget();
  if (instance) {
    onMounted(fn, target);
  } else if (sync) {
    fn();
  } else {
    void nextTick(fn);
  }
}
