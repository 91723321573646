import type {
  Ref,
  ComputedRef,
} from 'vue';
import {
  ref, computed, toRef, onMounted, watchEffect,
} from 'vue';
import { useRouter } from 'vue-router';

import { CustomerConfig, RefProgramNotificationType } from '@leon-hub/api-sdk';
import { RouteName } from '@leon-hub/routing-config-names';

import { useReferralProgramStore } from 'web/src/modules/referral-program/store';
import { useFormatMoney } from 'web/src/modules/money/composables';
import { useI18n } from 'web/src/modules/i18n/composables';
import { getBodyProps, getHeaderProps } from 'web/src/modules/referral-program/utils/referralProgramPopup';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import type { ReferralProgramHeaderProps } from 'web/src/modules/referral-program/components/ReferralProgramPopupHeader/types';
import type { ReferralProgramBodyProps } from 'web/src/modules/referral-program/views/ReferralProgramPopup';
import { useFrontNotificationsStore } from 'web/src/modules/front-notifications/store';
import { useUserStore } from 'web/src/modules/user/store';
import useCustomerDataStore from 'web/src/modules/customer/store/useCustomerDataStore';
import { useI18nStore } from 'web/src/modules/i18n/store';

export interface ReferralProgramPopupComposable {
  isOpen: Ref<boolean>;
  isReferral: ComputedRef<boolean>;
  toggleOpen: () => void;
  headerProps: ComputedRef<ReferralProgramHeaderProps>;
  bodyProps: ComputedRef<ReferralProgramBodyProps>;
  blockRef: Ref<HTMLDivElement | undefined>;
  blockStyle: ComputedRef<{ height: string | number }>;
  blockHeight: Ref<number>;
  isReferrerRequirementSatisfied: ComputedRef<boolean>;
  handleClose: () => void;
  isVisible: Ref<boolean>;
  handleRedirect: () => void;
}

export default function useReferralProgramPopup(): ReferralProgramPopupComposable {
  const referralProgramStore = useReferralProgramStore();
  const { $translate } = useI18n();
  const { locale } = useI18nStore();
  const formatMoney = useFormatMoney();
  const { isLoggedIn } = useIsLoggedIn();
  const { setAdditionalPropsUser } = useUserStore();
  const router = useRouter();

  const refFrontNotification = toRef(useFrontNotificationsStore(), 'refFrontNotification');

  const isProfileReferralProgramEnabled = toRef(useSiteConfigStore(), 'isProfileReferralProgramEnabled');
  const isReferralProgramMainBlockEnabled = toRef(useSiteConfigStore(), 'isReferralProgramMainBlockEnabled');
  const programReferral = toRef(referralProgramStore, 'programReferral');

  const isReferralProgramNotificationClicked = toRef(useCustomerDataStore(), 'isReferralProgramNotificationClicked');

  const isOpen = ref<boolean>(false);
  const isVisible = ref(true);
  const isNotificationClicked = ref(isReferralProgramNotificationClicked.value);

  const blockRef = ref<HTMLDivElement>();
  const blockHeight = ref<number>(0);

  // eslint-disable-next-line max-len
  const isAllRequirementsCompleted = computed<boolean>(() => !programReferral.value?.referralDetails?.requirements.filter(
    (item) => item.satisfied === false,
  ).length);

  const isReferrerRequirementSatisfied = computed<boolean>(() => {
    // eslint-disable-next-line max-len
    const isCorrectStatus = refFrontNotification.value?.find((message) => message?.payload.type === RefProgramNotificationType.REFERRER_REQUIREMENT_SATISFIED);
    return !!isCorrectStatus && isAllRequirementsCompleted.value && !isReferralProgramNotificationClicked.value;
  });

  const isReferral = computed<boolean>(() =>
    // don't show if user has completed all requirements;
    // eslint-disable-next-line implicit-arrow-linebreak
    (!!programReferral.value && !isReferralProgramNotificationClicked.value)
    || (!!programReferral.value && isReferrerRequirementSatisfied.value && isVisible.value));

  const bodyProps = computed<ReferralProgramBodyProps>(
    () => getBodyProps(programReferral.value, $translate, formatMoney, locale),
  );

  const headerProps = computed<ReferralProgramHeaderProps>(() => getHeaderProps(
    isOpen.value,
    programReferral.value,
    isAllRequirementsCompleted.value,
    bodyProps.value.rewards.rewardLabel,
  ));

  watchEffect(() => {
    if (isLoggedIn.value && isProfileReferralProgramEnabled.value) {
      void referralProgramStore.setProgramReferral();
    }
    if (isLoggedIn.value && !isProfileReferralProgramEnabled.value) {
      referralProgramStore.clearProgramReferral();
    }
    if (!isLoggedIn.value) {
      referralProgramStore.clearProgramReferral();
    }
    if (refFrontNotification.value && isLoggedIn.value && isProfileReferralProgramEnabled.value) {
      void referralProgramStore.setProgramReferral();
    }
  });

  async function hideNotification() {
    await setAdditionalPropsUser({
      value: {
        value: true,
      },
      config: CustomerConfig.IS_REFERRAL_PROGRAM_NOTIFICATION_CLICKED,
    });
    isNotificationClicked.value = true;
    isVisible.value = false;
  }

  function calculateBlockHeight() {
    blockHeight.value = blockRef.value?.clientHeight || 0;
  }

  onMounted(
    () => {
      if (!isReferralProgramMainBlockEnabled.value) {
        isVisible.value = false;
      } else {
        calculateBlockHeight();
      }
    },
  );

  const toggleOpen = () => {
    blockHeight.value = blockRef.value?.clientHeight || 0;
    isOpen.value = !isOpen.value;
  };

  const blockStyle = computed(() => ({
    height: isOpen.value ? `${blockHeight.value}px` : 0,
  }));

  const handleClose = (): void => {
    void hideNotification();
  };

  const handleRedirect = (): void => {
    void hideNotification()
      .then(() => {
        void router.push({
          name: RouteName.BONUSES,
        });
      });
  };

  return {
    isOpen,
    isReferral,
    toggleOpen,
    headerProps,
    bodyProps,
    blockRef,
    blockHeight,
    blockStyle,
    isReferrerRequirementSatisfied,
    handleClose,
    isVisible,
    handleRedirect,
  };
}
