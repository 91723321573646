import convertCommonClassesToCssModule from '../../../utils/convertCommonClassesToCssModule';
import type { VListItemInnerProps } from '../../types';

export default function getClassObject(
  props: VListItemInnerProps,
  havePrefix: boolean,
  haveSuffix: boolean,
): Dictionary<boolean> {
  return convertCommonClassesToCssModule({
    'list-item__inner': true,
    'list-item__inner--with-prefix': havePrefix,
    'list-item__inner--with-suffix': haveSuffix,
    'list-item__inner--colored': haveSuffix && !!props.iconColored,
    'list-item__inner--text-centered': !!props.isTitleCentered && !havePrefix,
    'list-item__inner--expand': !!props.haveExpand,
    'list-item__inner--nowrap': !!props.noWrap,
    'list-item__inner--disabled': !!props.haveSwitcher && !!props.isSwitchDisabled,
  });
}
