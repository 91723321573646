import type { KeyBoardInputPayload } from '../types';
import { KeyboardInputType } from '../../../enums';

export function getKeyBoardInputPayloadByKey(key: string, removeCurrentValue = false): KeyBoardInputPayload | null {
  let payload: KeyBoardInputPayload | null = null;

  if (!key) {
    return null;
  }

  if (/^\d*$/.test(key)) {
    payload = { action: KeyboardInputType.NUMBER, value: key, removeCurrentValue };
  }
  if (/[,.]/.test(key)) {
    payload = { action: KeyboardInputType.DECIMAL, removeCurrentValue };
  }
  if (key.toLowerCase() === 'backspace') {
    payload = { action: KeyboardInputType.BACKSPACE };
  }
  return payload;
}
