<script setup lang="ts">
import { ref } from 'vue';

import {
  ButtonHeight,
  ButtonKind,
} from '@leon-hub/module-buttons';
import {
  IconName,
  IconSize,
} from '@leon-hub/icons';
import { BusEvent, useBusSafeSubscribe } from '@leon-hub/event-bus';
import { useWindowResize } from '@leon-hub/browser-composables';

import { VScrollbar } from '@components/v-scrollbar';

import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { LayoutSizes } from 'web/src/modules/core/enums';
import vDataTestUnit from 'web/src/testing/directives/vDataTestUnit';

const isLeftSideBarOpen = ref(false);

function toggleMenu(): void {
  isLeftSideBarOpen.value = !isLeftSideBarOpen.value;
}

function setLeftSidebarClosed(): void {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
  if (document.documentElement.clientWidth > LayoutSizes.ShowLeftSidebarBreakpoint) {
    isLeftSideBarOpen.value = false;
  }
}

useBusSafeSubscribe(BusEvent.SIDE_MENU_SOFT_CLOSE, setLeftSidebarClosed);
useBusSafeSubscribe(BusEvent.SIDE_MENU_TOGGLE, toggleMenu);

useWindowResize(setLeftSidebarClosed);
</script>

<template>
  <aside v-auto-id="'LayoutDesktopLLeftSidebar'"
    v-data-test-unit="{el: 'left-sidebar'}"
    aria-label="left-sidebar"
    :class="{
      [$style['column__left']]: true,
      [$style['column__left--open']]: isLeftSideBarOpen
    }"
  >
    <div
      v-if="isLeftSideBarOpen"
      :class="$style['column__left-close']"
    >
      <VButton
        v-data-test-unit="{el: 'left-sidebar-close-btn'}"
        :class="$style['column__close-button']"
        :height="ButtonHeight.MEDIUM"
        :icon-name="IconName.CROSS"
        :kind="null ? ButtonKind.MONOCHROME : ButtonKind.TRANSPARENT"
        :icon-size="IconSize.SIZE_20"
        @click="toggleMenu"
      />
    </div>
    <VScrollbar
      test-el="left-side-bar"
      flex-fill
    >
      <div :class="$style['left-side-bar__content']">
        <router-view name="leftSideBar" />
      </div>
    </VScrollbar>
    <Teleport to="body">
      <Transition
        :enter-active-class="$style['opacity-enter-active']"
        :enter-from-class="$style['opacity-enter-from']"
        :leave-active-class="$style['opacity-leave-active']"
        :leave-to-class="$style['opacity-leave-to']"
        name="opacity"
      >
        <div
          v-if="isLeftSideBarOpen"
          :class="$style['column__main-mask']"
          @click="toggleMenu"
        />
      </Transition>
    </Teleport>
  </aside>
</template>

<style module lang="scss">
.column {
  &__left {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    order: 1;
    min-width: 252px;
    max-width: 252px;
    overflow-x: hidden;
    background-color: var(--BodyBackgroundColor);

    &::-webkit-scrollbar {
      display: none;
    }

    &--open {
      @include z-index(sidebar-menu);

      overflow-x: unset;
    }

    .content {
      padding: 0;
    }

    &-close {
      position: absolute;
      top: 0;
      right: -40px;
      width: 40px;
      height: 40px;
    }
  }

  &__main-mask {
    position: absolute;
    top: $headerDesktopHeight;
    right: $desktopRightSidebarWidth + $scrollWidth;
    bottom: 0;
    left: 0;
    z-index: get($zIndex, sidebar-menu) - 1;
    min-width: 688px;
    content: '';
    background-color: rgba(0, 0, 0, 0.6);
  }
}

@media (max-width: 1150px) {
  .column {
    &__left {
      position: absolute;
      top: $headerDesktopHeight;
      left: 0;
      z-index: get($zIndex, sidebar-menu);
      height: 100%;
      overflow-y: visible;
      transition: transform 0.15s ease-in-out;
      transform: translateX(calc(-100% - #{$scrollWidth}));

      &--open {
        transform: translateX(0);
      }
    }
  }
}

.left-side-bar {
  &__content {
    padding: 8px 16px 40px;
  }
}

.opacity-enter-active,
.opacity-leave-active {
  transition: opacity 0.25s $animationEaseInOut;
}

.opacity-enter-from,
.opacity-leave-to {
  opacity: 1;
}
</style>
