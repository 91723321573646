<script lang="ts" setup>
import type { LobbyPromotionTitlePanelProps } from 'web/src/modules/lobby/components/LobbyPromotionTitlePanel/types';
import { useLobbyPromotionTitlePanel } from 'web/src/modules/lobby/components/LobbyPromotionTitlePanel/composable';

const props = withDefaults(defineProps<LobbyPromotionTitlePanelProps>(), {
  description: '',
});

const { formattedText } = useLobbyPromotionTitlePanel(props);
</script>

<template>
  <div v-auto-id="'LobbyPromotionTitlePanel'" :class="$style['lobby-promotion-title-panel']">
    <p :class="$style['lobby-promotion-title-panel__title']">
      {{ title }}
    </p>
    <span :class="$style['lobby-promotion-title-panel__description']">{{ formattedText }}</span>
  </div>
</template>

<style lang="scss" module>
@include for-layout using($isDesktop) {
  .lobby-promotion-title-panel {
    height: 96px;
    padding: if($isDesktop, 12px 16px 16px, 12px 8px 16px);
    color: var(--TextDefault);

    &__title {
      @include medium\18\20;

      margin: 0 0 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__description {
      @include regular\13\20;
    }
  }
}
</style>
