import { FormControlType } from '@leon-hub/form-utils';

import { getUiSchemaField } from './uiSchema';
import type {
  FormUiSchema,
  FormWidget,
} from '../types';

export default function getWidget(
  uiSchema: FormUiSchema,
  field = '',
): FormWidget {
  const uiSchemaField = getUiSchemaField(uiSchema, field);
  return uiSchemaField.widget ?? FormControlType.Text;
}
