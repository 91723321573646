import type { Ref } from 'vue';
import { watch } from 'vue';

import { onComponentActivated, onComponentDeactivated } from '@leon-hub/vue-utils';

import { getListenerTarget, getScrollTarget } from '../utils';

interface UseOnScrollListenersProps {
  disableOwnScroll: Ref<boolean>;
  containerRef: Ref<Maybe<HTMLElement>>;
  listenerTargetRef: Ref<Maybe<Element>>;
  onScroll(): void;
}

export function useOnScrollListeners(
  props: UseOnScrollListenersProps,
): void {
  const {
    disableOwnScroll,
    containerRef,
    listenerTargetRef,
    onScroll,
  } = props;
  let target: Optional<Maybe<Element>>;
  // eslint-disable-next-line unicorn/prevent-abbreviations
  let removeListenerFn: Optional<() => void>;

  function removeListener(): void {
    removeListenerFn?.();
    removeListenerFn = undefined;
    listenerTargetRef.value = null;
  }

  function addListener(): void {
    removeListener();
    target = getListenerTarget(containerRef.value);
    target?.addEventListener('scroll', onScroll, { passive: true });
    listenerTargetRef.value = getScrollTarget(containerRef.value);
    removeListenerFn = () => { target?.removeEventListener('scroll', onScroll); };
  }

  onComponentActivated(addListener);
  onComponentDeactivated(removeListener);
  watch(disableOwnScroll, addListener);
}
