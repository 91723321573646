<script setup lang="ts">
import { TextInputTypes } from 'web/src/components/Input/enums';
import PasswordValidator from 'web/src/components/PasswordValidator';
import InputButton from 'web/src/components/Input/components/InputButton/InputButton.vue';

import TextInput from '../TextInput';
import { useBaseInputEvents } from '../../composables';
import usePasswordInput from './usePasswordInput';
import type { BaseInputEmits, PasswordInputProps } from '../../types';

withDefaults(defineProps<PasswordInputProps>(), {
  name: 'password',
});
const emits = defineEmits<BaseInputEmits>();

defineOptions({
  inheritAttrs: false,
});

const {
  toggleTextVisibility,
  isTextVisible,
  iconName,
} = usePasswordInput();
const {
  emitChange,
  emitInput,
  emitFocus,
  emitBlur,
} = useBaseInputEvents(emits);
</script>

<template>
  <TextInput v-auto-id="'PasswordInput'"
    v-data-test="{
      el: 'password-input',
      name: name,
    }"
    :autofocus="autofocus"
    :value="value"
    :label="label"
    :name="name"
    :placeholder="placeholder"
    :disabled="disabled"
    :hint="hint"
    :hint-right="hintRight"
    :error="error"
    :error-right="errorRight"
    :hint-link="hintLink"
    :hint-link-right="hintLinkRight"
    :type="isTextVisible ? TextInputTypes.TEXT : TextInputTypes.PASSWORD"
    :autocomplete="autocomplete"
    :is-large="isLarge"
    clickable-icon
    @change="emitChange"
    @input="emitInput"
    @blur="emitBlur"
    @focus="emitFocus"
  >
    <template #hint="{ isFocus, value: inputValue }">
      <PasswordValidator
        v-if="!hideValidation && !!inputValue"
        :groups="groups"
        :password="inputValue"
        :is-focused="isFocus"
        :error="error"
      />
    </template>
    <template #iconSuffix="{ isFocus, isEmpty }">
      <InputButton
        v-show="isFocus || !isEmpty"
        :icon-name="iconName"
        @click="toggleTextVisibility"
      />
    </template>
  </TextInput>
</template>
