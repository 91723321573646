import { Timer } from '@leon-hub/utils';

import getSlipEntryId from '../../../utils/getSlipEntryId';
import type { ClosedMarkets, OnClosedMarketsChangePayload, SlipEntryId } from '../types';

interface Payload extends OnClosedMarketsChangePayload {
  currentlyClosedMarkets: ClosedMarkets;
  timeout: number;
}

export default function handleClosedMarketsChange({
  changedEntries,
  currentlyClosedMarkets,
  unlockedEventsIds,
  timeout,
}: Payload,
removeFunction: (id: SlipEntryId) => Promise<void>): ClosedMarkets {
  const closedMarketsCopy = { ...currentlyClosedMarkets };
  const deadEventsIds = changedEntries.map((entry) => getSlipEntryId(entry));
  const recentlyDeadEvents = deadEventsIds.filter((id) => !closedMarketsCopy[id]);
  const resurrectedEvents = [...unlockedEventsIds].filter((id) => closedMarketsCopy[id]);
  for (const id of resurrectedEvents) {
    const event = closedMarketsCopy[id];
    if (!event) {
      continue;
    }
    Timer.clearTimeout(event);
    delete closedMarketsCopy[id];
  }
  for (const id of recentlyDeadEvents) {
    closedMarketsCopy[id] = Timer.setTimeout(() => {
      removeFunction(id);
    }, timeout);
  }
  return closedMarketsCopy;
}
