import type { RouteLocationRaw } from 'vue-router';

import type { RegionsSegmentPageListGroup } from 'web/src/modules/sportline/submodules/segment/types';
import { CustomFilter } from 'web/src/modules/sportline/enums';
import { resolveCybersportPageLink } from 'web/src/modules/sportline/submodules/navigation/store/utils';

export function resolveOptionLocation(group?: RegionsSegmentPageListGroup): RouteLocationRaw {
  if (!group) {
    return resolveCybersportPageLink({});
  }

  if (group.key === CustomFilter.Favorites || group.key === CustomFilter.Stream) {
    return resolveCybersportPageLink({ urlName: group.key });
  }

  return resolveCybersportPageLink(group.filterElement?.region.navigationParameters || {});
}
