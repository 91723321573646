import type { RouteLocationRaw } from 'vue-router';

import type { SportsSegmentPageListGroup } from 'web/src/modules/sportline/submodules/segment/types';
import { CustomFilter } from 'web/src/modules/sportline/enums';
import { resolveLivePageLink } from 'web/src/modules/sportline/submodules/navigation/store/utils';

export function resolveOptionLocation(group?: Maybe<SportsSegmentPageListGroup>): RouteLocationRaw {
  if (!group) {
    return resolveLivePageLink({});
  }

  if (group.key === CustomFilter.Favorites || group.key === CustomFilter.Stream) {
    return resolveLivePageLink({ urlName: group.key });
  }

  return resolveLivePageLink(group.filterElement?.sport.navigationParameters || {});
}
