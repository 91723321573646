import { defineStore } from 'pinia';
import { ref } from 'vue';

import type { SPORTLINE_EVENT_SUBSCRIPTION_REQUEST_TYPE } from './enums';

const useEventsSubscriptionsRequestStore = defineStore('sportline-events-subscriptions-request', () => {
  const showSubscriptionRequest = ref<SPORTLINE_EVENT_SUBSCRIPTION_REQUEST_TYPE>();

  function setShowSubscriptionRequest(value: SPORTLINE_EVENT_SUBSCRIPTION_REQUEST_TYPE | undefined): void {
    showSubscriptionRequest.value = value;
  }

  return {
    showSubscriptionRequest,
    setShowSubscriptionRequest,
  };
});

export default useEventsSubscriptionsRequestStore;
