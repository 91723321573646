export enum VirtualListElementType {
  Event = 'event',
  LeagueHeadline = 'league-headline',
  SportHeadline = 'sport-headline',
  NoEvents = 'no-events',
}

export const VirtualListBlockLayout = {
  Default: 'default',
  Live: 'live',
  Coming: 'coming',
} as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type VirtualListBlockLayout = typeof VirtualListBlockLayout[keyof typeof VirtualListBlockLayout];
