import type { getBetlineMatchStatisticsQueryVariables } from '@leon-hub/api-sdk';
import { getBetlineMatchStatistics } from '@leon-hub/api-sdk';
import { RequestGroupScore24 } from '@leon-hub/api';

import type { BetlinePostMatchStatistics } from 'web/src/modules/sportline/types/rest';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';

export default function getBMStatistics(
  options: getBetlineMatchStatisticsQueryVariables['options'],
  { silent }: { silent?: boolean },
): Promise<BetlinePostMatchStatistics> {
  return getBetlineMatchStatistics(useGraphqlClient(),
    (node) => node.queries.betLine.getMatchStatistics,
    { options },
    {
      silent,
      group: RequestGroupScore24,
    });
}
