import { defineStore } from 'pinia';
import { computed } from 'vue';

import { RouteName } from '@leon-hub/routing-config-names';

import { useSegmentStoreComposable } from 'web/src/modules/sportline/submodules/segment/store/composables';
import {
  useAllLiveLivePageUpdateInterval,
  useSelectedLiveLivePageUpdateInterval,
  useComingLiveLivePageUpdateInterval,
} from 'web/src/modules/sportline/submodules/update-timeout';
import type {
  SportElement,
  LiveSportlineEvent,
} from 'web/src/modules/sportline/types';
import {
  SportlineType,
} from 'web/src/modules/sportline/enums';
import { CustomerFavoritesIdsBackgroundUpdateKey } from 'web/src/modules/sportline/submodules/favorites/enums';
import {
  isCustomFilter,
} from 'web/src/modules/sportline/guards';
import {
  filterSportsListEvents,
  isSportFamilyEquals,
} from 'web/src/modules/sportline/utils';
import { checkMatchStreamIndicator, useMatchStreamAccess } from 'web/src/modules/sportline/submodules/streams';

const useSportlineLiveStore = defineStore('sportline-live-store', () => {
  const matchStreamAccess = useMatchStreamAccess();

  const {
    isPageEnabled,
    isReady,
    totalCount,
    isMarketTypesSelectionEnabled,
    isFilterTransitionFadeEffectEnabled,
    selectedFilter,
    rawLiveEventsResponse,
    rawComingEventsResponse,
    rawFullSportsListResponse,
    fullSportsList,
    fullEventsResponse,
    allowedSportFamily,
    hasFavoritesOptimistic,
    favoriteEventsFullList,
    favoriteEventsListForDisplay,
    favoriteSelectedRegionFamily,
    filterTransitionDuration,
    comingResponseWithoutLive,
    liveEventsSportsMap,
    comingEventsSportsMap,
    compiledComingSports,
    customerOptimisticFavoriteSportEventsIds,
    customerOptimisticFavoriteLeaguesIds,
    setSelectedFilter,
    initialRequests,
    syncBackgroundRequests,
    setBackgroundUpdateEnabled,
    setFavoriteSelectedRegionFamily,
  } = useSegmentStoreComposable({
    sportSegmentsSettingsId: RouteName.SPORTLINE_LIVE_EVENTS,
    backgroundFavoritesId: CustomerFavoritesIdsBackgroundUpdateKey.LIVE,
    filterFavoritesEventsListBySportlineType: SportlineType.Live,
    allLiveTabUpdateInterval: useAllLiveLivePageUpdateInterval().timeout,
    selectedLiveTabUpdateInterval: useSelectedLiveLivePageUpdateInterval().timeout,
    comingUpdateInterval: useComingLiveLivePageUpdateInterval().timeout,
  });

  /** Get actual live sports tree */
  const sportElementsForFilter = computed<SportElement<LiveSportlineEvent>[]>(
    () => fullSportsList.value.map((sportListElement): SportElement<LiveSportlineEvent> => ({
      key: sportListElement.sport.id,
      sport: sportListElement.sport,
      regions: [],
      marketTypes: [],
      marketsColumns: [],
      counters: sportListElement.counters,
    })),
  );
  const sportElementSelectedInFilter = computed(() => {
    const selectedName = selectedFilter.value;
    const isCustomFilterSelected = isCustomFilter(selectedName);

    if (isCustomFilterSelected) { return null; }

    const selectedSportItem = sportElementsForFilter.value
      .find((sportElement) => isSportFamilyEquals(sportElement.sport, selectedName));

    return selectedSportItem ?? null;
  });

  const customerStreamsSportEventsList = computed<SportElement<LiveSportlineEvent>[]>(
    () => (matchStreamAccess.value.length > 0
      ? filterSportsListEvents([...liveEventsSportsMap.value.values()],
        (sportEventElement) => checkMatchStreamIndicator(matchStreamAccess.value, sportEventElement.sportEvent))
      : []
    ),
  );

  return {
    isPageEnabled,
    isReady,
    totalCount,
    isMarketTypesSelectionEnabled,
    isFilterTransitionFadeEffectEnabled,
    selectedFilter,
    rawLiveEventsResponse,
    rawComingEventsResponse,
    rawFullSportsListResponse,
    sportElementsForFilter,
    sportElementSelectedInFilter,
    allowedSportFamily,
    fullEventsResponse,
    hasFavoritesOptimistic,
    favoriteEventsFullList,
    favoriteEventsListForDisplay,
    favoriteSelectedRegionFamily,
    filterTransitionDuration,
    comingResponseWithoutLive,
    liveEventsSportsMap,
    comingEventsSportsMap,
    compiledComingSports,
    customerStreamsSportEventsList,
    customerOptimisticFavoriteSportEventsIds,
    customerOptimisticFavoriteLeaguesIds,
    setSelectedFilter,
    initialRequests,
    syncBackgroundRequests,
    setBackgroundUpdateEnabled,
    setFavoriteSelectedRegionFamily,
  };
});

export default useSportlineLiveStore;
