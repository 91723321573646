import { useBannersStore } from 'web/src/modules/banners/store';

export interface UseInitialRequestsComposable {
  getInitRequests(): Promise<void>[];
}

export default function useInitialRequests(): UseInitialRequestsComposable {
  const { loadBanners } = useBannersStore();

  function getInitRequests(): Promise<void>[] {
    return [
      loadBanners(),
    ];
  }

  return {
    getInitRequests,
  };
}
