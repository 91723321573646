import type { SportEventOptions } from 'web/src/modules/analytics/services/types';

declare global {
  interface Window {
    srtmCommands: Array<unknown>;
  }
}

function visitSportEvent(sportsEventObject: SportEventOptions): void {
  window.srtmCommands?.push({
    event: 'srt.retargeting',
    payload: {
      sportsEvent: sportsEventObject,
    },
  });
}

export default class SportRadarService {
  private static instance: SportRadarService;

  private isInitiated = false;

  // eslint-disable-next-line class-methods-use-this
  private evalString = (command: string): void => {
    window.eval(command);
  };

  public static getInstance(): SportRadarService {
    if (!SportRadarService.instance) {
      SportRadarService.instance = new SportRadarService();
    }
    return SportRadarService.instance;
  }

  public setPixelManager(pixelManager: string | undefined): void {
    const isNotConfigured = pixelManager && !this.isInitiated;
    if (isNotConfigured && pixelManager) {
      this.evalString(pixelManager);
      this.isInitiated = true;
    }
  }

  public visitSportEvent = visitSportEvent;
}
