import type { Ref } from 'vue';
import { computed } from 'vue';

import { useSwiperNavigation } from 'web/src/components/Swiper/Navigation/composables';
import type { VSwiperSlideToProperties } from 'web/src/components/Swiper/VSwiper/types';

import type { SwiperNavigationPointsProps } from './types';

interface UseSwiperNavigationPointsComposable {
  finalActiveSlideIndex: Ref<number>;
  slidesCount: Ref<number>;
  slideToSlide: (index: number, options?: VSwiperSlideToProperties) => void;
  isPointSelected: (index: number) => boolean;
}

export function useSwiperNavigationPoints(props: SwiperNavigationPointsProps): UseSwiperNavigationPointsComposable {
  const { swiperState, slideToSlide } = useSwiperNavigation();

  const stateActiveSlideIndex = computed<Optional<number>>(() => (swiperState?.activeSlideIndex.value));

  const slidesCount = computed<number>(() => {
    const count = (swiperState?.slidesCounter.value ?? props.countSlides ?? 0);
    if (!props.maxSlidePointsCount) { return count; }
    return Math.min(props.maxSlidePointsCount, count);
  });

  const finalActiveSlideIndex = computed<number>(() => (
    props.activeSlideIndex
    ?? stateActiveSlideIndex.value
    ?? -1
  ));

  function isPointSelected(index: number) {
    const nativeIndex = index - 1;

    if (props.isFilledPreviousPoint) {
      return finalActiveSlideIndex.value >= nativeIndex;
    }

    if (props.maxSlidePointsCount && finalActiveSlideIndex.value >= props.maxSlidePointsCount) {
      return nativeIndex >= props.maxSlidePointsCount - 1;
    }

    return finalActiveSlideIndex.value === nativeIndex;
  }

  return {
    finalActiveSlideIndex,
    slidesCount,
    slideToSlide,
    isPointSelected,
  };
}
