import type { IconNameType } from '@leon-hub/icons';
import { IconName } from '@leon-hub/icons';

import type { BonusLoyaltyProgramCardProps } from 'web/src/modules/bonuses/components/BonusLoyaltyProgramCard/types';

export default function getIconName(
  props: Pick<BonusLoyaltyProgramCardProps, 'isLocked'>,
): IconNameType {
  return props.isLocked ? IconName.LOCK : IconName.COIN_L;
}
