import getCompletedProgramRequirements
  from 'web/src/modules/referral-program/utils/referralProgramPopup/getCompletedProgramRequirements';
import type { ReferralDataGetDataDocument } from 'web/src/modules/referral-program/types/ReferralProgramDocuments';
import type { ReferralProgramHeaderProps } from 'web/src/modules/referral-program/components/ReferralProgramPopupHeader/types';

export default function getHeaderProps(
  isOpen: boolean,
  programReferral: Maybe<ReferralDataGetDataDocument>,
  isLastStep: boolean,
  description?: string,
): ReferralProgramHeaderProps {
  const completedProgramRequirements = getCompletedProgramRequirements(programReferral);

  if (isLastStep) {
    return {
      isOpen: false,
      successMessage: description,
    };
  }

  return {
    isOpen,
    activeStep: completedProgramRequirements.length,
    stepCount: programReferral?.referralDetails?.requirements.length || 0,
  };
}
