<script setup lang="ts">
import { toRef } from 'vue';

import { useInfoCountdown } from './useInfoCountdown';

interface InfoCountdownProps {
  kickoff: number;
  isNarrow?: boolean;
}

const props = defineProps<InfoCountdownProps>();
const kickoff = toRef(props, 'kickoff');

const {
  displayedTimeLeft,
  daysLabel,
  hoursLabel,
  minutesLabel,
  secondsLabel,
} = useInfoCountdown({ kickoff });
</script>

<template>
  <div v-auto-id="'InfoCountdown'"
    v-if="displayedTimeLeft"
    :class="{
      [$style['prematch-info-countdown']]: true,
      [$style['prematch-info-countdown--narrow']]: isNarrow,
    }"
  >
    <div :class="$style['prematch-info-countdown__item']">
      <div :class="$style['prematch-info-countdown__counter']">
        {{ displayedTimeLeft.days }}
      </div>
      <div :class="$style['prematch-info-countdown__label']">
        {{ daysLabel }}
      </div>
    </div>

    <span :class="$style['prematch-info-countdown__delimiter']">:</span>

    <div :class="$style['prematch-info-countdown__item']">
      <div :class="$style['prematch-info-countdown__counter']">
        {{ displayedTimeLeft.hours }}
      </div>
      <div :class="$style['prematch-info-countdown__label']">
        {{ hoursLabel }}
      </div>
    </div>

    <span :class="$style['prematch-info-countdown__delimiter']">:</span>

    <div :class="$style['prematch-info-countdown__item']">
      <div :class="$style['prematch-info-countdown__counter']">
        {{ displayedTimeLeft.minutes }}
      </div>
      <div :class="$style['prematch-info-countdown__label']">
        {{ minutesLabel }}
      </div>
    </div>

    <template v-if="displayedTimeLeft.seconds !== false">
      <span :class="$style['prematch-info-countdown__delimiter']">:</span>

      <div :class="$style['prematch-info-countdown__item']">
        <div :class="$style['prematch-info-countdown__counter']">
          {{ displayedTimeLeft.seconds }}
        </div>
        <div :class="$style['prematch-info-countdown__label']">
          {{ secondsLabel }}
        </div>
      </div>
    </template>
  </div>
</template>

<style module lang="scss">
.prematch-info-countdown {
  $self: &;

  display: flex;
  align-items: flex-start;
  justify-content: center;

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  &__delimiter {
    @include medium\24\28;

    width: 7px;
    margin: 0 4px;
    color: var(--White);
    text-align: center;
    opacity: 0.5;
  }

  &__counter {
    @include medium\24\28;

    width: 40px;
    color: var(--White);
  }

  &__label {
    @include regular\10\12;

    color: var(--White);
    text-transform: uppercase;
    letter-spacing: 0.2px;
    opacity: 0.5;
  }

  &--narrow {
    #{$self}__delimiter {
      margin: 0 2px;
    }

    #{$self}__counter {
      width: 30px;
    }
  }
}
</style>
