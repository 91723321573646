import { PriceChangePolicy } from '@leon-hub/api-sdk';

interface Payload {
  originalOdds?: number;
  currentOdds: number;
  priceChangePolicy?: PriceChangePolicy | null;
}

export default function isPriceChangedMatters({ originalOdds, currentOdds, priceChangePolicy }: Payload): boolean {
  if (!originalOdds || !priceChangePolicy || priceChangePolicy === PriceChangePolicy.ALLOW_CHANGES) {
    return false;
  }
  if (priceChangePolicy === PriceChangePolicy.DENY_CHANGES) {
    return currentOdds !== originalOdds;
  }
  // PriceChangePolicy.ALLOW_INCREASE
  return currentOdds < originalOdds;
}
