import { defineStore } from 'pinia';
import { ref } from 'vue';

import type { CustomerBetType } from '@leon-hub/api-sdk';
import { useLocalStorageManager } from '@leon-hub/local-storage';

import isValidCustomerBetType from 'web/src/modules/customer/utils/isValidCustomerBetType';

const LOCAL_STORAGE_BET_TYPE_KEY = 'customer:betType';

const useCustomerBetTypeStore = defineStore('customer-bet-type', () => {
  const localStorage = useLocalStorageManager();

  const betType = ref<CustomerBetType>();

  function setBetType(type: CustomerBetType): void {
    betType.value = type;
    localStorage.setItem(LOCAL_STORAGE_BET_TYPE_KEY, type);
  }

  function onInit(): void {
    const type = localStorage.getItem(LOCAL_STORAGE_BET_TYPE_KEY);

    if (isValidCustomerBetType(type)) {
      setBetType(type);
    }
  }

  onInit();

  return {
    betType,
    setBetType,
  };
});

export default useCustomerBetTypeStore;
