<script lang="ts" setup>
import { IconSize } from '@leon-hub/icons';

import { VIcon } from '@components/v-icon';

import { getCyberSportIconName } from 'web/src/modules/sportline/utils/theme';
import { VCountryFlagSize } from 'web/src/components/CountryFlag/VCountryFlag/enums';
import VFlagIcon from 'web/src/components/CountryFlag/VFlagIcon/VFlagIcon.vue';

import type { RegionLogoProps } from './types';
import { useRegionLogoProps } from './composables';

const props = defineProps<RegionLogoProps>();

const {
  isCyberSport,
  countryCode,
} = useRegionLogoProps(props);
</script>

<template>
  <VIcon
    v-if="isCyberSport"
    :name="getCyberSportIconName({ icon: countryCode })"
  />
  <VFlagIcon
    v-else
    :size="IconSize.SIZE_20"
    :flag-size="VCountryFlagSize.SIZE_16"
    :country-code="countryCode"
  />
</template>
