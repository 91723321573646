import type { Ref } from 'vue';
import {
  onBeforeUnmount,
  ref,
  toRef,
  watch,
} from 'vue';

import { Timer } from '@leon-hub/utils';

import {
  useKeepAliveStore,
} from 'web/src/modules/core/store';

export interface KeepAliveComponentsComposable {
  keepAliveIncludes: Ref<string>;
}

export function useKeepAliveComponents(): KeepAliveComponentsComposable {
  const keepAliveComponents = toRef(useKeepAliveStore(), 'keepAliveComponents');

  const keepAliveIncludes = ref('none');
  let timeout = 0;

  function clearTimer(): void {
    if (timeout) {
      Timer.clearTimeout(timeout);
      timeout = 0;
    }
  }

  function updateKeepAliveComponents(components: string[]): void {
    clearTimer();
    timeout = Timer.setTimeout(() => {
      // LEONWEB-9603 KeepAlive includes prop triggers watch while array value given and clean vnodes cache
      keepAliveIncludes.value = components.length > 0 ? components.join(',') : 'none';
    }, 200);
  }

  watch(keepAliveComponents, (newValue: string[]) => {
    updateKeepAliveComponents(newValue);
  }, { immediate: true, deep: true });

  onBeforeUnmount(clearTimer);

  return {
    keepAliveIncludes,
  };
}
