import type { Ref } from 'vue';

import type { CoreSportEventResponse } from 'web/src/modules/sportline/types/rest';
import type { GetEventStatusID } from 'web/src/modules/sportline/submodules/event-details/utils/requests-counter/types';
import { BetlineSportEventStatus } from 'web/src/modules/sportline/enums/rest';
import {
  GetEventSubscriptionStatus,
  GetEventSubscriptionAction,
} from 'web/src/modules/sportline/submodules/event-details/utils/requests-counter/enums';

import type {
  RequestBehaviourActionsMap,
  UseSportlineEventDetailsRequestBehavioursComposable,
} from '../composables';

type StayingOnPageBehaviour = (id: GetEventStatusID, actions: RequestBehaviourActionsMap) => void;

interface StayingOnPageBehaviourProps {
  setDetailsStatus(status: BetlineSportEventStatus): void;
  clearDetailsMarketsList(): void;
  loadSportMatchStatistics(): void;
}

type OnInitBehaviour = (id: GetEventStatusID, actions: RequestBehaviourActionsMap) => void;

interface OnInitBehaviourProps {
  createStayingOnPageBehaviour: StayingOnPageBehaviour;
  awaitPostMatchStatisticIsLoaded(): Promise<boolean>;
  setRawSportEventDetails(value: Maybe<CoreSportEventResponse | false>): void;
  hasPostMatchStatistics: Ref<boolean>;
}

/**
 * This behaviour works after the first load is completed
 * @see createGetEventRequestOnPageBehaviour for the logic clarification
 */
export function createStayingOnPageBehaviourFactory(
  requestStatusBehaviours: UseSportlineEventDetailsRequestBehavioursComposable,
  props: StayingOnPageBehaviourProps,
): StayingOnPageBehaviour {
  const { setDetailsStatus, clearDetailsMarketsList, loadSportMatchStatistics } = props;
  return function createStayingOnPageBehaviour(id: GetEventStatusID, actions: RequestBehaviourActionsMap): void {
    requestStatusBehaviours.removeBehavioursExcept(id);
    requestStatusBehaviours.addOnPageBehaviour(id, {
      [GetEventSubscriptionAction.ShowError](): void {
        setDetailsStatus(BetlineSportEventStatus.SUSPENDED);
        actions[GetEventSubscriptionAction.ShowError]?.();
      },
      [GetEventSubscriptionAction.Redirect](): void {
        setDetailsStatus(BetlineSportEventStatus.CLOSED);
        clearDetailsMarketsList();
        actions[GetEventSubscriptionAction.Redirect]?.();
      },
      [GetEventSubscriptionAction.Finish](): void {
        requestStatusBehaviours.removeBehaviours(id);
        // try to reload post match statistic after the end of the game
        loadSportMatchStatistics();
        actions[GetEventSubscriptionAction.Finish]?.();
      },
    });
  };
}

/**
 * This behaviour works on the page loading from the prefetch.
 * @see createGetEventRequestOnInitBehaviour for the logic clarification
 */
export function createOnInitBehaviourFactory(
  requestStatusBehaviours: UseSportlineEventDetailsRequestBehavioursComposable,
  props: OnInitBehaviourProps,
): OnInitBehaviour {
  const {
    createStayingOnPageBehaviour,
    awaitPostMatchStatisticIsLoaded,
    setRawSportEventDetails,
    hasPostMatchStatistics,
  } = props;

  return function createOnInitBehaviour(id: GetEventStatusID, actions: RequestBehaviourActionsMap): void {
    // clear last status on init
    requestStatusBehaviours.fireRequestStatus(id, GetEventSubscriptionStatus.None);
    requestStatusBehaviours.removeBehavioursExcept(id);

    // extend behaviour from navigation
    requestStatusBehaviours.addOnInitBehaviour(id, {
      ...actions,
      [GetEventSubscriptionAction.ShowError](): void {
        requestStatusBehaviours.removeBehaviours(id);
        actions[GetEventSubscriptionAction.ShowError]?.();
      },
      async [GetEventSubscriptionAction.Redirect](): Promise<void> {
        requestStatusBehaviours.removeBehaviours(id);
        await awaitPostMatchStatisticIsLoaded().catch(() => {
          actions[GetEventSubscriptionAction.Redirect]?.();
        });

        if (hasPostMatchStatistics.value) {
          setRawSportEventDetails(false);
          // do call the Finish action
          // but do not subscribe to on page behaviour, we won't do event requests anyway
          actions[GetEventSubscriptionAction.Finish]?.();
          return;
        }

        actions[GetEventSubscriptionAction.Redirect]?.();
      },
      [GetEventSubscriptionAction.Finish](): void {
        requestStatusBehaviours.removeBehaviours(id);
        createStayingOnPageBehaviour(id, {});
        actions[GetEventSubscriptionAction.Finish]?.();
      },
    });
  };
}
