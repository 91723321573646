import type { Ref } from 'vue';
import memoize from 'lodash/memoize';

import {
  ruPatternLocaleDateTime as defaultPattern,
  createDateLocalizers,
} from '@leon-hub/utils';
import type { DateLocalizers } from '@leon-hub/utils';

import { getMonthFullNames, getMonthShortNames } from './utils';

const cache = new Map<string, DateLocalizers>();
type $T = (key: string) => Ref<string>;

export const getDateLocalizers = memoize((locale: string, $t: $T): DateLocalizers => {
  let localizers = cache.get(locale);
  if (!localizers) {
    const shortMonthNames = getMonthShortNames($t);
    const fullMonthNames = getMonthFullNames($t);
    const getMonth = ({ id, format }: { id: number; format : 'short' | 'long' }): string => {
      const list = format === 'short' ? shortMonthNames : fullMonthNames;
      if (id in list) {
        return list[id].value;
      }
      throw new RangeError(`Missing month number: ${id}`);
    };
    localizers = createDateLocalizers({ pattern: defaultPattern, getMonth });
    cache.set(locale, localizers);
  }
  return localizers;
}, (locale) => locale);
