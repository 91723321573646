var Locale = /* @__PURE__ */ ((Locale2) => {
  Locale2["RU_RU"] = "ru_RU";
  Locale2["RU_BY"] = "ru_BY";
  Locale2["RU_KZ"] = "ru_KZ";
  Locale2["RU_UZ"] = "ru_UZ";
  Locale2["RU_UA"] = "ru_UA";
  Locale2["EN_US"] = "en_US";
  Locale2["EN_GB"] = "en_GB";
  Locale2["EN_IN"] = "en_IN";
  Locale2["PT_PT"] = "pt_PT";
  Locale2["PT_BR"] = "pt_BR";
  Locale2["DE_DE"] = "de_DE";
  Locale2["HI_IN"] = "hi_IN";
  Locale2["FR_FR"] = "fr_FR";
  Locale2["FR_CA"] = "fr_CA";
  Locale2["ES_ES"] = "es_ES";
  Locale2["TR_TR"] = "tr_TR";
  Locale2["BE_BY"] = "be_BY";
  Locale2["UK_UA"] = "uk_UA";
  Locale2["RU_AZ"] = "ru_AZ";
  Locale2["AZ_AZ"] = "az_AZ";
  Locale2["UZ_UZ"] = "uz_UZ";
  Locale2["UC_UZ"] = "uc_UZ";
  Locale2["JA_JP"] = "ja_JP";
  Locale2["SV_SE"] = "sv_SE";
  Locale2["TH_TH"] = "th_TH";
  Locale2["VI_VN"] = "vi_VN";
  Locale2["EN_SE"] = "en_SE";
  Locale2["EL_GR"] = "el_GR";
  Locale2["ES_CL"] = "es_CL";
  Locale2["ES_MX"] = "es_MX";
  Locale2["ES_PE"] = "es_PE";
  Locale2["KO_KR"] = "ko_KR";
  Locale2["EN_NZ"] = "en_NZ";
  Locale2["EN_AU"] = "en_AU";
  Locale2["EN_CA"] = "en_CA";
  Locale2["LN_CF"] = "ln_CF";
  Locale2["SW_TZ"] = "sw_TZ";
  Locale2["IT_IT"] = "it_IT";
  return Locale2;
})(Locale || {});
const locales = /* @__PURE__ */ new Set(/* @__PURE__ */ Object.values(Locale));
function isLocale(value, locale) {
  return typeof value === "string" && locales.has(value) && locale ? value === locale : true;
}
const localeStorageKey = "lang";
export {
  Locale,
  isLocale,
  localeStorageKey
};
