import { getBootstrapTranslations } from '@leon-hub/bootstrap-translations';

import { ApiErrorCode } from './ApiErrorCode';
import type { ApiErrorOptions } from './ApiError';
import { ApiError } from './ApiError';

export class ApiServiceUnavailableError extends ApiError {
  constructor(options?: ApiErrorOptions) {
    super({
      ...options,
      message: options?.message ?? getBootstrapTranslations().WEB2_ERROR_503_TITLE_V2,
      code: ApiErrorCode.API_SERVICE_UNAVAILABLE_ERROR_503,
    });
  }
}
