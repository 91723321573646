import type { AccumulatorCoefficientBoostSettings } from '@leon-hub/api-sdk';

export default function getNextAccumulatorBoostSetting(
  slipEventsCount = 0,
  accumulatorBoostSettings: AccumulatorCoefficientBoostSettings[] = [],
)
  : AccumulatorCoefficientBoostSettings | null {
  let bestMatch: AccumulatorCoefficientBoostSettings | null = null;
  for (const item of accumulatorBoostSettings) {
    if (item.count > slipEventsCount && (!bestMatch || item.count < bestMatch.count)) {
      bestMatch = item;
    }
  }
  return bestMatch;
}
