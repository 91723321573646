import type { BonusMatrix } from '@leon-hub/api-sdk';

import { ProfileBonusesComplexType } from 'web/src/modules/profile/submodules/bonuses/enums';

const getComplexType = (bonus: BonusMatrix): ProfileBonusesComplexType => {
  if (bonus.freespin) {
    return ProfileBonusesComplexType.FREESPIN;
  }

  return ProfileBonusesComplexType.DEFAULT;
};

export default getComplexType;
