export enum SportIconName {
  SPORT_ALPINE_SKIING = 'sport-alpineskiing',
  SPORT_AMERICAN_FOOTBALL = 'sport-americanfootball',
  SPORT_ARCHERY = 'sport-archery',
  SPORT_ATHLETICS = 'sport-athletics',
  SPORT_AUSSIE_RULES = 'sport-aussierules',
  SPORT_AUSTRALIAN_RULES = 'sport-australianrules',
  SPORT_BADMINTON = 'sport-badminton',
  SPORT_BANDY = 'sport-bandy',
  SPORT_BASEBALL = 'sport-baseball',
  SPORT_BASKETBALL = 'sport-basketball',
  SPORT_BEACH_SOCCER = 'sport-beachsoccer',
  SPORT_BEACH_VOLLEYBALL = 'sport-beachvolleyball',
  SPORT_BIATHLON = 'sport-biathlon',
  SPORT_BOWLS = 'sport-bowls',
  SPORT_BOXING = 'sport-boxing',
  SPORT_CANOEING = 'sport-canoeing',
  SPORT_CHESS = 'sport-chess',
  SPORT_CRICKET = 'sport-cricket',
  SPORT_CROSS_COUNTRY = 'sport-crosscountry',
  SPORT_CROSS_COUNTRY_SKIING = 'sport-crosscountryskiing',
  SPORT_CURLING = 'sport-curling',
  SPORT_CYBER_SPORT = 'sport-esport',
  SPORT_CYCLING = 'sport-cycling',
  SPORT_DARTS = 'sport-darts',
  SPORT_DIVING = 'sport-diving',
  SPORT_EQUESTRIAN = 'sport-equestrian',
  SPORT_FENCING = 'sport-fencing',
  SPORT_FIELD_HOCKEY = 'sport-fieldhockey',
  SPORT_FLOORBALL = 'sport-floorball',
  SPORT_FORMULA = 'sport-formula',
  SPORT_FUTSAL = 'sport-futsal',
  SPORT_GAELIC_FOOTBALL = 'sport-gaelicfootball',
  SPORT_GAELIC_HURLING = 'sport-gaelichurling',
  SPORT_GOLF = 'sport-golf',
  SPORT_GYMNASTICS = 'sport-gymnastics',
  SPORT_HANDBALL = 'sport-handball',
  SPORT_ICE_HOCKEY = 'sport-icehockey',
  SPORT_JUDO = 'sport-judo',
  SPORT_LACROSSE = 'sport-lacrosse',
  SPORT_MARATHON_SWIMMING = 'sport-marathonswimming',
  SPORT_MMA = 'sport-mma',
  SPORT_MODERN_PENTATHLON = 'sport-modernpentathlon',
  SPORT_MOTOR_SPORT = 'sport-motorsport',
  SPORT_OLYMPICS = 'sport-olympics',
  SPORT_PADEL = 'sport-padel',
  SPORT_PESAPALLO = 'sport-pesapallo',
  SPORT_POLITICS = 'sport-politics',
  SPORT_ROWING = 'sport-rowing',
  SPORT_RUGBY = 'sport-rugby',
  SPORT_RUGBY_UNION = 'sport-rugbyunion',
  SPORT_BASKETBALL3X3 = 'sport-basketball3x3',
  SPORT_RUGBY_LEAGUE = 'sport-rugbyleague',
  SPORT_SAILING = 'sport-sailing',
  SPORT_SKATEBOARDING = 'sport-skateboarding',
  SPORT_SKI_JUMPING = 'sport-skijumping',
  SPORT_SPECIALS = 'sport-specials',
  SPORT_SNOOKER = 'sport-snooker',
  SPORT_SOCCER = 'sport-soccer',
  SPORT_SOCCER_OUTLINE = 'sport-soccer-outline',
  SPORT_SOFTBALL = 'sport-softball',
  SPORT_SOON = 'sport-soon',
  SPORT_SQUASH = 'sport-squash',
  SPORT_SURFING = 'sport-surfing',
  SPORT_SWIMMING = 'sport-swimming',
  SPORT_TABLE_TENNIS = 'sport-tabletennis',
  SPORT_TENNIS = 'sport-tennis',
  SPORT_TRIATHLON = 'sport-triathlon',
  SPORT_VOLLEYBALL = 'sport-volleyball',
  SPORT_WATERPOLO = 'sport-waterpolo',
  SPORT_WEIGHTLIFTING = 'sport-weightlifting',
  SPORT_WINTERSPORTS = 'sport-wintersports',
  SPORT_WRESTLING = 'sport-wrestling',
  SPORT_KABADDI = 'sport-kabaddi',
  SPORT = 'sport',
  SOCCER_CARD = 'soccer-card',
  STAR_ALT = 'star-alt',
  BUNDESLIGA = 'bundesliga',
  ZERO_MARGIN = 'zero-margin',
  LIVE_LABEL = 'live-label',
  SPORTLINE_TOP = 'sportline-top',
  SPORTLINE_HIGHEST_ODDS = 'sportline-highest-odds',
  SPORTLINE_HIGHEST_ODDS_TABS = 'sportline-highest-odds-tabs',
}
