import { AppErrorCode } from './AppErrorCode';
import { AppError } from './AppError';

export class AppIgnoredError extends AppError {
  constructor() {
    super({
      message: 'Ignored Error',
      code: AppErrorCode.APP_IGNORED_ERROR,
      silent: true,
    });
  }
}
