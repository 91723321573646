import type { FileRecord, InputFileTypeRecords } from 'web/src/components/Input';
import { isFileImage } from 'web/src/components/Input/utils';

import readFileContent from './readFileContent';

interface Options {
  file: File;
  fileTypes: InputFileTypeRecords;
  errorCallback: () => void;
}
export default async function getFileRecord({ file, fileTypes, errorCallback }: Options): Promise<FileRecord> {
  const record: FileRecord = {
    record: file,
    name: file.name,
    base64String: '',
  };

  if (isFileImage(fileTypes, file)) {
    try {
      const base64string = await readFileContent(file);
      if (base64string) {
        record.base64String = base64string;
      }
    } catch (error) {
      errorCallback();
      throw error;
    }
  }

  return record;
}
