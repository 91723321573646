import type { SportEventsLeaguePageNavigationService } from 'web/src/modules/sportline/submodules/league/types';

import { usePrerenderLeaguePageNavigationGuard } from './composables/usePrerenderLeaguePageNavigationGuard';
import { useDefaultLeaguePageNavigationGuard } from './composables/useDefaultLeaguePageNavigationGuard';

let service: SportEventsLeaguePageNavigationService;

export function useSportlineLeaguePageNavigationService(): SportEventsLeaguePageNavigationService {
  if (!service) {
    service = process.env.VUE_APP_PRERENDER
      ? usePrerenderLeaguePageNavigationGuard()
      : useDefaultLeaguePageNavigationGuard();
  }

  return service;
}
