<script lang="ts" setup>
import { toRef } from 'vue';

import type {
  BetlineLeaguePlayoffRound,
} from 'web/src/modules/sportline/types/rest';

import LeaguePlayoffMatchesBlock from './LeaguePlayoffMatchesBlock.vue';
import { useLeaguePlayoffRound } from './useLeaguePlayoffRound';

interface LeaguePlayoffRoundProps {
  round: BetlineLeaguePlayoffRound;
  isLastRound?: boolean;
}

const props = defineProps<LeaguePlayoffRoundProps>();

const {
  blockPairs,
} = useLeaguePlayoffRound({ round: toRef(props, 'round') });
</script>

<template>
  <div v-auto-id="'LeaguePlayoffRound'" :class="$style['league-playoff-round']">
    <div
      :class="$style['league-playoff-round__title']"
      :title="round.description ?? undefined"
    >
      {{ round.description }}
    </div>

    <template v-if="blockPairs.length > 0">
      <div
        v-for="(pair, pairIndex) in blockPairs"
        :key="pairIndex"
        :class="$style['league-playoff-round__block-pair']"
      >
        <div :class="$style['league-playoff-round__block-pair-content']">
          <div
            v-for="(block, blockIndex) in pair"
            :key="blockIndex"
            :class="$style['league-playoff-round__block-holder']"
          >
            <LeaguePlayoffMatchesBlock
              v-if="block"
              :block="block"
            />
          </div>
        </div>

        <div :class="$style['league-playoff-round__arrow']">
          <div
            v-if="!isLastRound"
            :class="$style['league-playoff-round__arrow-line']"
          />
        </div>
      </div>
    </template>
    <template v-else>
      <div :class="$style['league-playoff-round__block-pair']" />
    </template>
  </div>
</template>

<style lang="scss" module>
$roundsGap: 40px;

.league-playoff-round {
  position: relative;
  display: flex;
  flex: 0 0 220px;
  flex-direction: column;
  justify-content: center;
  width: 220px;

  &__title {
    @include medium\14\16\025;

    display: block;
    width: calc(100% - $roundsGap);
    padding: 8px;
    margin-bottom: 8px;
    overflow: hidden;
    color: var(--TextDefault);
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    background-color: var(--Layer2);
    border-radius: 5px;
  }

  &__block-pair {
    display: flex;
    flex: 1 1 0;
  }

  &__block-pair-content {
    display: flex;
    flex: 1 0 calc(100% - $roundsGap);
    flex-direction: column;
    justify-content: space-around;
    width: calc(100% - $roundsGap);
  }

  &__block-holder {
    padding: 8px 0;
  }

  &__arrow {
    display: flex;
    flex: 0 0 $roundsGap;
    align-items: center;
    width: $roundsGap;
  }

  &__arrow-line {
    position: relative;
    z-index: 2;
    width: 50%;
    height: 50%;
    border-top: 1px solid var(--Highlight);
    border-right: 1px solid var(--Highlight);
    border-bottom: 1px solid var(--Highlight);
    border-left: none;
    border-image: initial;

    &:before {
      position: absolute;
      top: 50%;
      left: 100%;
      width: 110%;
      height: 1px;
      content: '';
      background-color: var(--Highlight);
    }
  }
}
</style>
