import ApplicationRestClient from 'web/src/modules/core/services/api/ApplicationRestClient';

export default class FastTrackApiClient extends ApplicationRestClient {
  constructor() {
    super({
      baseUrl: '',
      method: 'POST',
      credentials: 'same-origin',
      origin: '',
    });
  }
}
