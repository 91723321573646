import type { AchievementsRequest } from '@leon-hub/api-sdk';
import { getAchievementsProfile } from '@leon-hub/api-sdk';

import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import type {
  GetAchievementsProfileDataDocument,
} from 'web/src/modules/profile/submodules/achievements/types/AchievementsDocuments';

export default function getAchievementsProfileRequest(publicId?: string): Promise<GetAchievementsProfileDataDocument> {
  const apiClient = useGraphqlClient();

  const options: AchievementsRequest = {};

  if (publicId) {
    options.publicId = publicId;
  }

  return getAchievementsProfile(
    apiClient,
    (node) => node.queries.achievements.getProfile,
    {
      options,
    },
  );
}
