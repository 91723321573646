import type {
  Plugin,
} from 'vue';

import type { Theme } from '@leon-hub/api-sdk';

import type { ThemeGetter } from 'web/src/modules/theme/types';

declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    $theme: {
      theme: Theme;
      isDark: boolean;
      isLight: boolean;
      isAuto: boolean;
    };
  }
}

export const ThemePlugin: Plugin = {
  install(app, {
    theme,
    isDark,
    isLight,
    isAuto,
  }: ThemeGetter) {
    Object.defineProperty(app.config.globalProperties, '$theme', {
      enumerable: true,
      get: () => ({
        theme: theme.value,
        isDark: isDark.value,
        isLight: isLight.value,
        isAuto: isAuto.value,
      }),
    });
  },
};
