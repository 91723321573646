import { defineStore } from 'pinia';

import ServerDate from 'web/src/utils/ServerDate';
import { useSyncState } from 'web/src/modules/core/store/composables';
import useCoreApi from 'web/src/modules/core/services/api/useCoreApi';

const useSyncDateTimeStore = defineStore('sync-date-time', () => {
  function setServerTime(time: number) {
    ServerDate.setServerTime(time);
  }

  async function syncServerTime(): Promise<void> {
    const result = await useCoreApi().syncServerTime();
    setServerTime(result);
  }

  useSyncState(syncServerTime, 'sync-date-time');

  return {
    setServerTime,
    syncServerTime,
  };
});

export default useSyncDateTimeStore;
