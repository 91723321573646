import type { Ref } from 'vue';
import { computed, ref } from 'vue';

import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';

import { PaymentsModalType } from 'web/src/modules/payments/enums';
import { useFormatMoney } from 'web/src/modules/money/composables';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import useWithdrawalStore from 'web/src/modules/payments/submodules/withdrawals/store/useWithdrawalStore';
import { useI18n } from 'web/src/modules/i18n/composables';

import type { PaymentsCancelWithdrawalProps } from '../types';

export interface PaymentsCancelWithdrawalComposable {
  showModal: Ref<boolean>;
  modalType: Ref<PaymentsModalType | null>;
  modalText: Ref<string>;
  secondaryText: Ref<string>;
  showCancelWithdrawalWindow: Ref<boolean>;
  formattedValue: Ref<string>;
  onCancelWithdrawalClick: () => void;
  onConfirmClick: () => void;
  hideModal: () => void;
}

// eslint-disable-next-line max-len
export default function usePaymentsCancelWithdrawal(props: PaymentsCancelWithdrawalProps): PaymentsCancelWithdrawalComposable {
  const { $translate } = useI18n();
  const analytics = useAnalytics();
  const formatMoney = useFormatMoney();
  const withdrawalStore = useWithdrawalStore();

  const showModal = ref(false);
  const modalType = ref<PaymentsModalType | null>(null);
  const modalText = ref('');
  const secondaryText = ref('');
  const showCancelWithdrawalWindow = ref(true);

  const formattedValue = computed<string>(() => `${formatMoney(props.value || 0)}`);

  function onCancelWithdrawalClick(): void {
    analytics.clickMap({ withdrawalCancel: 'true' });
    showModal.value = true;
    modalType.value = PaymentsModalType.WITHDRAWAL_CANCEL_MODAL;
    modalText.value = $translate('WEB2_RETURN_TO_ACCOUNT').value;
    secondaryText.value = formattedValue.value;
  }

  function showSuccessModal(amount: number): void {
    showModal.value = true;
    modalType.value = PaymentsModalType.WITHDRAWAL_CANCEL_SUCCESS_MODAL;
    modalText.value = $translate('WEB2_ADDED_TO_ACCOUNT').value;
    secondaryText.value = formatMoney(amount);
  }

  function hideModal(): void {
    showModal.value = false;
    modalType.value = null;
    modalText.value = '';
    secondaryText.value = '';
  }

  function onConfirmClick(): void {
    analytics.clickMap({ withdrawalCancelRequest: 'true' });
    hideModal();
    void withdrawalStore.cancelRequestedWithdrawals().then((response) => {
      if (response.totalCount) {
        analytics.push(AnalyticsEvent.Z_WITHDRAWAL_CANCEL_DEPOSIT_FORM, {
          withdrawals: {
            withdrawalCancelDepositForm: true,
          },
        });
        showSuccessModal(response.totalAmount);
        showCancelWithdrawalWindow.value = false;
      }
    });
  }

  return {
    showModal,
    modalType,
    modalText,
    secondaryText,
    showCancelWithdrawalWindow,
    formattedValue,
    onCancelWithdrawalClick,
    onConfirmClick,
    hideModal,
  };
}
