import type {
  League,
  LeagueElement,
  Region,
  RegionElement,
  Sport,
  SportElement,
  SportlineEventElement,
} from 'web/src/modules/sportline/types';

interface FlattedEventElement {
  key: string;
  eventElement: SportlineEventElement;
}

interface FlattedLeagueElement extends FlattedEventElement {
  league: League;
}

interface FlattedRegionElement extends FlattedLeagueElement {
  region: Region;
}

export interface FlattedSportElement extends FlattedRegionElement {
  sport: Sport;
}

interface DoFlattedOptions {
  doExcludeRegularEvents?: boolean;
  doExcludeOutrightEvents?: boolean;
}

function sportEventElementToFlatted(eventElement: SportlineEventElement): FlattedEventElement {
  return {
    key: eventElement.sportEvent.id,
    eventElement,
  };
}

function leagueElementToFlatted(leagueElement: LeagueElement, options?: DoFlattedOptions): FlattedLeagueElement[] {
  const regular = !options?.doExcludeRegularEvents
    ? leagueElement.sportEvents.map(sportEventElementToFlatted).map((sportEventElement) => ({
      ...sportEventElement,
      league: leagueElement.league,
    }))
    : [];
  const outright = !options?.doExcludeOutrightEvents
    ? leagueElement.outrightEvents.map(sportEventElementToFlatted).map((sportEventElement) => ({
      ...sportEventElement,
      league: leagueElement.league,
    }))
    : [];

  return [...regular, ...outright];
}

function regionElementToFlatted(regionElement: RegionElement, options?: DoFlattedOptions): FlattedRegionElement[] {
  return regionElement.leagues
    .flatMap((leagueElement) => leagueElementToFlatted(leagueElement, options))
    .map((leagueElement) => ({
      ...leagueElement,
      region: regionElement.region,
    }));
}

function sportElementToFlatted(sportElement: SportElement, options?: DoFlattedOptions): FlattedSportElement[] {
  return sportElement.regions
    .flatMap((regionElement) => regionElementToFlatted(regionElement, options))
    .map((regionElement) => ({
      ...regionElement,
      sport: sportElement.sport,
    }));
}

export function flatMapLeagueElementsList(
  sport: Sport,
  region: Region,
  leagueElements: LeagueElement[],
  options?: DoFlattedOptions,
): FlattedSportElement[] {
  return leagueElements
    .flatMap((leagueElement) => leagueElementToFlatted(leagueElement, options))
    .map((leagueElement) => ({ ...leagueElement, region, sport }));
}

export function flatMapRegionElementsList(
  sport: Sport,
  regionElements: RegionElement[],
  options?: DoFlattedOptions,
): FlattedSportElement[] {
  return regionElements
    .flatMap((regionElement) => regionElementToFlatted(regionElement, options))
    .map((regionElement) => ({ ...regionElement, sport }));
}

export function flatMapSportElementsList(
  sportElements: SportElement[], options?: DoFlattedOptions,
): FlattedSportElement[] {
  return sportElements.flatMap((sportElement) => sportElementToFlatted(sportElement, options));
}
