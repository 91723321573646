import { isArray, isString } from '@leon-hub/guards';

import type { CompetitorsTuple } from '../submodules/slip-info/types';

export default function isCompetitorsTuple(value: unknown): value is CompetitorsTuple {
  if (!isArray(value)) {
    return false;
  }
  return value.length === 2 && isString(value[0]) && isString(value[1]);
}
