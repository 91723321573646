import type { Ref } from 'vue';
import { ref, computed } from 'vue';

import { useCanShowSegmentGroup } from 'web/src/modules/sportline/submodules/segment/composables/useCanShowSegmentGroup';
import type {
  SegmentPageListGroup,
  SportEventsSegmentSelectedFilter,
  SportlineSegmentFilterActiveTransition,
} from 'web/src/modules/sportline/submodules/segment/types';
import type {
  SportEventsFilterTransitionDuration,
} from 'web/src/modules/sportline/types';
import { CustomFilter } from 'web/src/modules/sportline/enums';
import { SegmentPageTabType } from 'web/src/modules/sportline/submodules/segment/enums';

interface SegmentPageFiltersProps<Group extends SegmentPageListGroup = SegmentPageListGroup> {
  isFilterTransitionFadeEffectEnabled: Ref<boolean>;
  filterTransitionDuration: Ref<SportEventsFilterTransitionDuration>;
  totalCount: Ref<number>;
  hasStreamEvents: Ref<boolean>;
  hasFavoritesOptimistic: Ref<boolean>;
  selectedFilter: Ref<SportEventsSegmentSelectedFilter>;
  mainGroups: Ref<Group[]>;
}

interface SegmentPageFiltersComposable<Group extends SegmentPageListGroup = SegmentPageListGroup> {
  allGroupForFilter: Ref<Maybe<Group>>;
  mainGroupsForFilter: Ref<Group[]>;
  favoriteGroupForFilter: Ref<Maybe<Group>>;
  streamEventsGroupForFilter: Ref<Maybe<Group>>;
  isFilterTransitionFadeEffectEnabled: Ref<boolean>;
  filterTransitionDuration: Ref<SportEventsFilterTransitionDuration>;
  activeTransitionId: Ref<SportlineSegmentFilterActiveTransition>;

  setActiveTransitionId(id: string | null): void;
  canShowLiveForGroup(group: Group): boolean;
  canShowPrematchForGroup(group: Group): boolean;
}

export function useSegmentPageFilters<Group extends SegmentPageListGroup = SegmentPageListGroup>(
  props: SegmentPageFiltersProps<Group>,
): SegmentPageFiltersComposable<Group> {
  const activeTransitionId = ref<SportlineSegmentFilterActiveTransition>(null);

  const {
    isFilterTransitionFadeEffectEnabled,
    filterTransitionDuration,
    totalCount,
    hasStreamEvents,
    hasFavoritesOptimistic,
    selectedFilter,
    mainGroups,
  } = props;

  const {
    canShowLiveForGroup,
    canShowPrematchForGroup,
  } = useCanShowSegmentGroup({ activeTransitionId, selectedFilter });

  const allGroupForFilter = computed<Maybe<Group>>(() => ({
    transitionId: CustomFilter.AllEvents,
    allowedForTransitionIds: [CustomFilter.AllEvents],
    isActive: selectedFilter.value as CustomFilter === CustomFilter.AllEvents,
    isEmpty: totalCount.value === 0,
    isEmptyComing: false,
    key: CustomFilter.AllEvents,
    type: SegmentPageTabType.Default,
  } as Group));
  const mainGroupsForFilter = computed<Group[]>(
    () => [...mainGroups.value],
  );
  const favoriteGroupForFilter = computed<Maybe<Group>>(
    () => (hasFavoritesOptimistic.value
      ? ({
        transitionId: CustomFilter.Favorites,
        allowedForTransitionIds: [CustomFilter.Favorites],
        isActive: selectedFilter.value as CustomFilter === CustomFilter.Favorites,
        isEmpty: false,
        isEmptyComing: false,
        key: CustomFilter.Favorites,
        type: SegmentPageTabType.Default,
      } as Group)
      : null),
  );
  const streamEventsGroupForFilter = computed<Maybe<Group>>(
    () => (hasStreamEvents.value
      ? ({
        transitionId: CustomFilter.Stream,
        allowedForTransitionIds: [CustomFilter.Stream],
        isActive: selectedFilter.value as CustomFilter === CustomFilter.Stream,
        isEmpty: false,
        isEmptyComing: false,
        key: CustomFilter.Stream,
        type: SegmentPageTabType.Default,
      } as Group)
      : null),
  );

  function setActiveTransitionId(id: string | null): void {
    activeTransitionId.value = id;
  }

  return {
    allGroupForFilter,
    mainGroupsForFilter,
    favoriteGroupForFilter,
    streamEventsGroupForFilter,
    isFilterTransitionFadeEffectEnabled,
    filterTransitionDuration,
    activeTransitionId,

    setActiveTransitionId,
    canShowLiveForGroup,
    canShowPrematchForGroup,
  };
}
