import { ref } from 'vue';

import type { UseBiometricAuthSettings } from '../types';

export default function useWebBiometricAuthSettings(): UseBiometricAuthSettings {
  return {
    isFingerprintAuthenticationEnabled: ref(false),
    isFingerprintSupported: ref(false),
    changeFingerSettings() {},
  };
}
