import type { PhoneInput } from '@leon-hub/api-sdk';

import type { FormPhoneValue } from 'web/src/components/Form/types';

export default function formPhoneValueToApiPhoneInput(formPhone: FormPhoneValue | PhoneInput): PhoneInput {
  return {
    prefix: formPhone.prefix,
    suffix: formPhone.suffix,
  };
}
