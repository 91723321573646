import type { Ref } from 'vue';
import { ref } from 'vue';

import type {
  SportEventDetailsHeadlinePosition,
} from 'web/src/modules/sportline/submodules/event-details/views/headlline/types';

interface UseHeadlineDimensionExposeComposable extends SportEventDetailsHeadlinePosition {
  headlineElement: Ref<Optional<HTMLDivElement>>;
}

export function useHeadlineDimensionExpose(): UseHeadlineDimensionExposeComposable {
  const headlineElement = ref<Optional<HTMLDivElement>>();

  return {
    headlineElement,
    getPosition(): number {
      return headlineElement.value?.offsetTop ?? 0;
    },
    getHeight(): number {
      return headlineElement.value?.scrollHeight ?? 0;
    },
  };
}
