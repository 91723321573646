import type { Ref } from 'vue';
import { ref, computed } from 'vue';

import { Timer } from '@leon-hub/utils';

import type {
  TimeBusSubscription,
  TimeBusComposable as BaseTimeBusComposable,
} from './types';

interface TimeBusComposable extends BaseTimeBusComposable {
  isRunning: Ref<boolean>;
  forceUpdateNow(): void;
  getSubscriptionsCount(): number;
  start(): void;
  stop(): void;
}

export default function useTimeBus(updateEvery = 1000): TimeBusComposable {
  const now = ref(Date.now());
  let subscriptions: TimeBusSubscription[] = [];
  const timeout = ref<number>();

  const isRunning = computed(() => timeout.value !== undefined);

  function forceUpdateNow(): void {
    now.value = Date.now();
  }

  function fireSubscriptions(): void {
    const dateNow = now.value;
    for (const subscription of subscriptions) {
      subscription(dateNow);
    }
  }

  function unsubscribe(subscription: TimeBusSubscription): void {
    subscriptions = subscriptions.filter((item) => item !== subscription);
  }

  function subscribe(subscription: TimeBusSubscription): void {
    unsubscribe(subscription);
    subscriptions.push(subscription);
  }

  function stop(): void {
    if (!timeout.value) { return; }
    Timer.clearTimeout(timeout.value);
    timeout.value = undefined;
  }

  function start(): void {
    if (updateEvery <= 0) { throw new Error('Unexpected update interval'); }

    stop();
    timeout.value = Timer.setTimeout(() => {
      forceUpdateNow();
      fireSubscriptions();
      start();
    }, updateEvery);
  }

  function getSubscriptionsCount(): number {
    return subscriptions.length;
  }

  return {
    now,
    isRunning,
    start,
    stop,
    subscribe,
    unsubscribe,
    forceUpdateNow,
    getSubscriptionsCount,
  };
}
