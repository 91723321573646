import type { Ref } from 'vue';

import type { RecycleScrollerItemOptions } from '../types';
import { RecycleScrollerDirection } from '../enums';
import { getTotalItemOptionsSizeValue } from './getItemSizeValue';

export type RecycleScrollerGetDistance = (count: number) => number;

export function createGetDistance(
  source: Ref<RecycleScrollerItemOptions[]>,
  direction: RecycleScrollerDirection = RecycleScrollerDirection.VERTICAL,
): RecycleScrollerGetDistance {
  return (count: number): number => (
    getTotalItemOptionsSizeValue(source.value.slice(0, count), direction)
  );
}
