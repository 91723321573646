import type {
  RouteLocationRaw,
  RouteParams,
} from 'vue-router';

import { logger } from '@leon-hub/logging';

import type NavigationItem from '../types/NavigationItem';

export function getNavigationItemRouteLocation(
  navigationItem: NavigationItem,
  position: string,
): RouteLocationRaw {
  const { routeName, props } = navigationItem;
  let routerParameters: RouteParams | undefined;

  if (props?.routeParams) {
    try {
      routerParameters = JSON.parse(`${props.routeParams}`);
    } catch (error) {
      logger.error(`getNavigationItemRouteLocation at ${position}: wrong routeParams: ${props.routeParams}`, error);
    }
  }

  return { name: routeName, params: routerParameters };
}
