<script lang="ts" setup>
import { toRef, computed } from 'vue';

import type { OnResizeEventElementPayload } from 'web/src/modules/sportline/views/EventBlock/types';
import type { VirtualListEventElement } from 'web/src/modules/sportline/views/virtual-list-renderer/types';
import type { ExtendedEventBlock } from 'web/src/modules/sportline/submodules/extended-event/enums';
import { VirtualListBlockLayout } from 'web/src/modules/sportline/views/virtual-list-renderer/enums';
import { UpcomingLabelType } from 'web/src/modules/sportline/views/EventBlock/enums';
import SportlineEventBlock from 'web/src/modules/sportline/views/EventBlock/SportlineEventBlock.vue';

interface VirtualListRendererEventElementProps {
  element: VirtualListEventElement;
  canAddToFavorite?: boolean;
  extendedBlockType?: ExtendedEventBlock;
}

interface VirtualListRendererEventElementEmits {
  (e: 'resize', payload: OnResizeEventElementPayload): void;
}

const props = defineProps<VirtualListRendererEventElementProps>();
const emit = defineEmits<VirtualListRendererEventElementEmits>();

const element = toRef(props, 'element');

const isLastEvent = computed(() => element.value.isLastEventInLeague);
const isLastLeague = computed(() => element.value.isLastLeagueInRegion);
const isLastRegion = computed(() => element.value.isLastRegionInSport);
const isLastSport = computed(() => element.value.isLastSport);

const upcomingLabelType = computed(() => (
  element.value.blockLayout === VirtualListBlockLayout.Coming
    ? UpcomingLabelType.Timer
    : UpcomingLabelType.StaticLabel
));
</script>

<template>
  <SportlineEventBlock v-auto-id="'VirtualListRendererEventElement'"
    :class="{
      [$style['sportline-event']]: true,
      [$style['sportline-event--before-event-gap']]: !isLastEvent,
      [$style['sportline-event--before-league-gap']]: !isLastLeague && isLastEvent,
      [$style['sportline-event--before-region-gap']]: !isLastRegion && isLastLeague && isLastEvent,
      [$style['sportline-event--before-sport-gap']]: !isLastSport && isLastRegion && isLastLeague && isLastEvent,
    }"
    :sport-event-element="element.sportlineEvent"
    :sport="element.sport"
    :can-add-to-favorite="canAddToFavorite"
    :extended-block-type="extendedBlockType"
    :upcoming-label-type="upcomingLabelType"
    :basis-key="element.basisKey"
    @resize="emit('resize', $event)"
  />
</template>

<style module lang="scss">
.sportline-event {
  min-height: var(--list-event-height);

  &--before-event-gap {
    margin-bottom: var(--list-events-gap);
  }

  &--before-league-gap {
    margin-bottom: var(--list-leagues-gap);
  }

  &--before-region-gap {
    margin-bottom: var(--list-regions-gap);
  }

  &--before-sport-gap {
    margin-bottom: var(--list-sports-gap);
  }
}
</style>
