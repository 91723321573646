import type { EdgeLengthValue, RecycleScrollerItemOptions, SizeByDirection } from '../types';
import { RecycleScrollerDirection } from '../enums';

/**
 * Item size normalization
 * This could be a function before,
 * but it is a good idea to keep a method to convert options into an internal value
 */
export function convertEdgeToItemSize(itemSize: EdgeLengthValue): SizeByDirection {
  return itemSize;
}

export function getItemOptionsSizeValue(
  { width, height }: RecycleScrollerItemOptions,
  direction: RecycleScrollerDirection = RecycleScrollerDirection.VERTICAL,
): number {
  return direction === RecycleScrollerDirection.VERTICAL
    ? convertEdgeToItemSize(height ?? 0)
    : convertEdgeToItemSize(width ?? 0);
}

export function getTotalItemOptionsSizeValue(
  source: RecycleScrollerItemOptions[],
  direction: RecycleScrollerDirection = RecycleScrollerDirection.VERTICAL,
): number {
  return source.reduce(
    (sum, item) => (sum + getItemOptionsSizeValue(item, direction)),
    0,
  );
}
