<script lang="ts" setup>
import type { Theme } from '@leon-hub/api-sdk';

import { useVThemeSwitcher } from './composables';

interface VThemeSwitcherEmits {
  (e: 'change', theme: Theme): void;
}

const emit = defineEmits<VThemeSwitcherEmits>();

const {
  themeList,
} = useVThemeSwitcher();

function emitChange(theme: Theme) {
  emit('change', theme);
}
</script>

<template>
  <div v-auto-id="'VThemeSwitcher'" :class="$style['theme-switcher']">
    <div
      v-for="(theme, index) in themeList"
      :key="index"
      :class="{
        [$style['theme-switcher__button']]: true,
        [$style['theme-switcher__button--active']]: theme.isActive
      }"
      @click="emitChange(theme.theme)"
    >
      <img
        :class="$style['theme-switcher__button-image']"
        :src="require(`web/src/assets/images/theme-select-circle-${theme.theme.toLowerCase()}.svg`)"
        alt=""
      >
      <div :class="$style['theme-switcher__button-label']">
        {{ theme.name }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>
.theme-switcher {
  $self: &;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 32px;
  width: max-content;
  margin: 0 auto;

  &__button {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include for-hover {
      &:hover {
        cursor: pointer;
      }
    }

    &-image {
      width: 40px;
      height: 40px;
      margin-bottom: 8px;
      overflow: hidden;
      border: 2px solid var(--Highlight);
      border-radius: 50%;
    }

    &-label {
      @include medium\14\20\025;

      color: var(--TextSecondary);
      text-align: center;
    }

    &--active {
      #{$self}__button-label {
        color: var(--TextDefault);
      }

      #{$self}__button-image {
        border-color: var(--BrandDefault);
      }
    }
  }
}
</style>
