import {
  isNumber,
  isValidInterface,
} from '@leon-hub/guards';
import type { FieldGuards } from '@leon-hub/types';

import type {
  CoreLeagueReferenceResponse,
} from 'web/src/modules/sportline/types/rest';

export const isCoreLeagueReferenceResponseFieldGuards: FieldGuards<CoreLeagueReferenceResponse> = {
  id: isNumber,
};

export function isCoreLeagueReferenceResponse(value: unknown): value is CoreLeagueReferenceResponse {
  return isValidInterface<CoreLeagueReferenceResponse>(value, isCoreLeagueReferenceResponseFieldGuards)
    && Object.keys(value).length === 1;
}
