import type { NavigationGuardNext } from 'vue-router';

import {
  assert,
  isObject,
  isUndefined,
} from '@leon-hub/guards';

import type { AppVueRouter } from 'web/src/modules/core/services/router/types';
import { useRouterStore } from 'web/src/modules/core/store';

import processAfterRoute from './history-state/processAfterRoute';
import prefetchRouteComponents from './prefetchRouteComponents';

function getContentElement(): HTMLElement | null {
  if (process.env.VUE_APP_LAYOUT_DESKTOP) {
    return document.getElementById('js__content-scroll');
  }

  return window.document.documentElement;
}

export default function createBaseNavigationGuard(router: AppVueRouter): void {
  router.beforeEach(async (to, from, originalNext) => {
    useRouterStore().setNextRouteName(to.name);
    const next = ((nextTo) => {
      assert(isUndefined(nextTo) || isObject(nextTo));
      return router.next(originalNext, nextTo);
    }) as NavigationGuardNext;

    if (!router.isPopStateDetected()) {
      window.history.replaceState({
        ...window.history.state,
        scrollTop: from.meta.resetScrollPosition
          ? 0
          : (getContentElement()?.scrollTop || 0),
      }, '');
    }

    await prefetchRouteComponents(
      to,
      from,
      next,
      router,
    );
  });

  router.afterEach((to, from) => {
    const { isPopStateDetected } = router;

    if (process.env.VUE_APP_LAYOUT_PHONE
      || to.fullPath !== from.fullPath
      || !useRouterStore().currentRoute?.meta?.modalPreset
      || isPopStateDetected()
    ) {
      processAfterRoute(to, from, router);
    }

    useRouterStore().setRealCurrentRouteName(to.name);
    useRouterStore().setNextRouteName(undefined);

    if (process.env.VUE_APP_LAYOUT_DESKTOP
      && to.fullPath === from.fullPath
      && window.history.state?.current !== to.fullPath
      && !isPopStateDetected) {
      void router.closeModal();
    }

    if (to.name !== from.name && to.meta.transitionTo !== from.meta.transitionTo) {
      if (to.meta.transitionTo) {
        // eslint-disable-next-line no-param-reassign
        to.meta.transition = to.meta.transitionTo;
      }

      if (from.meta.transitionFrom) {
        // eslint-disable-next-line no-param-reassign
        to.meta.transition = from.meta.transitionFrom;
      }
    }
  });
}
