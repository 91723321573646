import type { SlipInfoFieldsFragment } from '@leon-hub/api-sdk';

import type { MakeBetResultItem } from '../types';
import type { SlipEntryId, SlipInfoArray, SlipEntry } from '../../slip-info/types';
import betResultHasLimitExceedError from './betResultHasLimitExceedError';
import getSlipEntryId from '../../../utils/getSlipEntryId';

export default function parseBetResults(results: MakeBetResultItem[], selectedEntries: SlipEntryId[])
  : {
    unsettledBetsIds: SlipEntryId[];
    acceptedBets: MakeBetResultItem[];
    updatedSlipEntries: SlipInfoArray | null;
    haveLimitsError: boolean;
    acceptedSingleEntries: SlipEntry[];
  } {
  const acceptedIds = new Set<SlipEntryId>();
  const acceptedBets: MakeBetResultItem[] = [];
  const slipEntries: SlipInfoFieldsFragment[] = [];
  const acceptedSingleEntries: SlipEntry[] = [];
  let haveItemWithCorruptedInfo = false;
  let haveLimitsError = false;
  for (const result of results) {
    if (result.slipInfo) {
      slipEntries.push(result.slipInfo);
      if (result.accepted) {
        acceptedBets.push(result);
        const entry = result.slipInfo.entries[0];
        acceptedSingleEntries.push(entry);
        acceptedIds.add(getSlipEntryId(entry));
      } else if (betResultHasLimitExceedError(result)) {
        haveLimitsError = true;
      }
    } else {
      haveItemWithCorruptedInfo = true;
    }
  }
  const updatedSlipEntries = haveItemWithCorruptedInfo ? null : slipEntries;
  if (!acceptedBets.length) {
    return {
      acceptedBets: [],
      unsettledBetsIds: selectedEntries,
      updatedSlipEntries,
      haveLimitsError,
      acceptedSingleEntries,
    };
  }
  const unsettledBetsIds = selectedEntries.filter((id) => !acceptedIds.has(id));
  return {
    acceptedBets,
    unsettledBetsIds,
    updatedSlipEntries: unsettledBetsIds.length ? null : updatedSlipEntries,
    haveLimitsError,
    acceptedSingleEntries,
  };
}
