<script lang="ts" setup>
import type { EgsJackpotAmountProps } from 'web/src/modules/egs/submodules/jackpots/components/EgsJackpotAmount/types';
import { useEgsJackpotAmount } from 'web/src/modules/egs/submodules/jackpots/components/EgsJackpotAmount/composables';

const props = defineProps<EgsJackpotAmountProps>();

const {
  currencySymbol,
  priceItems,
} = useEgsJackpotAmount(props);
</script>

<template>
  <div v-auto-id="'EgsJackpotAmount'"
    :class="{
      [$style['egs-jackpot-amount']]: true,
      [$style['egs-jackpot-amount--background']]: hasBackground
    }"
  >
    <span :class="$style['egs-jackpot-amount__currency']">{{ currencySymbol }}</span>
    <div
      v-data-test="{ el: 'egs-jackpot-amount-value-list'}"
      :class="$style['egs-jackpot-amount__value-list']"
    >
      <span
        v-for="(item, index) in priceItems"
        :key="index"
        :class="item === '.' ? $style['egs-jackpot-amount__dot'] : $style['egs-jackpot-amount__item-value']"
      >{{ item }}</span>
    </div>
  </div>
</template>

<style lang="scss" module>
.egs-jackpot-amount {
  @include medium\18\20;

  display: inline-flex;
  align-items: center;
  color: var(--TextDefault);

  &--background {
    padding: 4px 4px 0;
    background-color: var(--Layer0);
    border-radius: 5px 5px 0 0;
  }

  &__currency {
    min-width: 11px;
    margin: 0 8px;
  }

  &__value-list {
    display: flex;
  }

  &__item-value {
    width: 19px;
    padding: 4px;
    margin-left: 2px;
    background-color: var(--Layer1);
    border-radius: 5px;
  }

  &__dot {
    width: 6px;
    margin: 4px 0 4px 2px;
  }
}
</style>
