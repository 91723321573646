import { LimitName } from 'web/src/modules/profile/submodules/responsible-gambling/enums';
import type { I18nFunctionReactive } from 'web/src/modules/i18n/types';

export function getInputPlaceholder(
  $translate: I18nFunctionReactive,
  limit: LimitName,
): string {
  switch (limit) {
    case LimitName.DAILY:
      return $translate('WEB2_DAILY_LIMIT').value;
    case LimitName.WEEKLY:
      return $translate('WEB2_WEEKLY_LIMIT').value;
    default:
      return $translate('WEB2_MONTHLY_LIMIT').value;
  }
}
