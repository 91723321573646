import type { ExtendedDateTimeRange } from 'web/src/modules/sportline/types/services';
import type { SportFamily } from 'web/src/modules/sportline/enums';
import arrayEquals from 'web/src/utils/array/arrayEquals';

export interface ApiPendingParams {
  vTag?: string;
  sportId?: string;
  leagueId?: string;
  regionId?: string;
  sportFamily?: string | SportFamily;
  login?: string;
  filter?: ExtendedDateTimeRange;
  betlines: string[];
}

export interface ApiPending<Response = unknown> extends ApiPendingParams {
  request: Promise<Response>;
}

function isApiPendingParamsEquals(
  apiPending1: ApiPendingParams,
  apiPending2: ApiPendingParams,
): boolean {
  return apiPending1.filter?.to === apiPending2.filter?.to
    && apiPending1.filter?.from === apiPending2.filter?.from
    && apiPending1.sportId === apiPending2.sportId
    && apiPending1.leagueId === apiPending2.leagueId
    && apiPending1.regionId === apiPending2.regionId
    && apiPending1.sportFamily === apiPending2.sportFamily
    && apiPending1.login === apiPending2.login
    && apiPending1.vTag === apiPending2.vTag
    && arrayEquals(apiPending1.betlines, apiPending2.betlines);
}

interface SportlineApiPendingComposable<Response = unknown> {
  getApiPendingByIndex(index: number): ApiPending<Response>;
  getApiPendingIndexForFilter(filter: ApiPendingParams): number;
  addApiPending(apiPending: ApiPending<Response>): void;
  removeApiPendingForFilter(filter: ApiPendingParams): void;
}

export default function useSportlineApiPending<Response = unknown>(): SportlineApiPendingComposable<Response> {
  const apiPendings: ApiPending<Response>[] = [];

  function getApiPendingByIndex(index: number): ApiPending<Response> {
    if (!apiPendings[index]) { throw new Error('Api pending is not exist'); }
    return apiPendings[index];
  }

  function getApiPendingIndexForFilter(
    filter: ApiPendingParams,
  ): number {
    return apiPendings.findIndex((apiPending) => isApiPendingParamsEquals(apiPending, filter));
  }

  function addApiPending(apiPending: ApiPending<Response>): void {
    apiPendings.push(apiPending);
  }

  function removeApiPendingForFilter(filter: ApiPendingParams): void {
    const apiPendingIndex = getApiPendingIndexForFilter(filter);

    if (apiPendingIndex !== -1) {
      delete apiPendings[apiPendingIndex];
      apiPendings.splice(apiPendingIndex, 1);
    }
  }

  return {
    getApiPendingByIndex,
    getApiPendingIndexForFilter,
    removeApiPendingForFilter,
    addApiPending,
  };
}
