import { IconName } from '@leon-hub/icons';

import type { I18nFunctionReactive } from 'web/src/modules/i18n/types';
import { ModalWidth } from 'web/src/components/Modal/enums';
import { DialogAction } from 'web/src/modules/dialogs/enums';
import type { ConfirmDialog } from 'web/src/modules/dialogs/types';
import { JumbotronIconKind } from 'web/src/components/Jumbotron';

export default function getModalBonusProperties(
  $t: I18nFunctionReactive,
): Partial<ConfirmDialog> {
  return {
    iconName: IconName.CHECK_OUTLINE,
    iconKind: JumbotronIconKind.SUCCESS,
    width: ModalWidth.SMALL,
    title: $t('WEB2_BONUS_OUTPUT_SUCCESS').value,
    buttons: [
      {
        label: $t('JSP_PCL_FBOT_CLOSE').value,
        action: DialogAction.MODAL_CLOSE,
      },
    ],
  };
}
