<script setup lang="ts">
/**
 * @TODO reduce template complexity
 */
import { toRef } from 'vue';

import { useLiveStatus } from 'web/src/modules/sportline/composables/live-progress';
import { useLiveMatchProgress } from 'web/src/modules/sportline/composables/match-progress';
import { useDoShowMatchProgress } from 'web/src/modules/sportline/submodules/event-details/components/progress-bar/composables';
import type { LiveSportlineEvent, Sport } from 'web/src/modules/sportline/types';
import SportlineLiveEventProgress from 'web/src/modules/sportline/submodules/event-details/components/progress-bar/SportlineLiveEventProgress.vue';

import { useLiveInfoHeadline } from '../../composables';
import HeadlineLiveTimeInfo from '../HeadlineLiveTimeInfo.vue';
import HeadlineTeam from './components/HeadlineTeam.vue';
import HeadlineTeamScore from '../components/HeadlineTeamScore.vue';

interface LiveMatchInfoDesktopProps {
  sportlineEvent: LiveSportlineEvent;
  sport: Sport;
  backgroundColor?: Maybe<string>;
}

interface LiveMatchInfoSlots {
  'primary-market'(props: { hasTimeline: boolean }): void;
}

const props = defineProps<LiveMatchInfoDesktopProps>();
defineSlots<LiveMatchInfoSlots>();

const sportlineEvent = toRef(props, 'sportlineEvent');
const sport = toRef(props, 'sport');

const {
  matchProgress,
} = useLiveMatchProgress({ sportEvent: sportlineEvent });
const {
  innings,
  stage,
  scoreDetailsLine,
} = useLiveStatus(matchProgress);
const {
  hasLogos,
  date,
  time,
} = useLiveInfoHeadline({ sportlineEvent, sport });

const { doShowTimeline } = useDoShowMatchProgress({ sportlineEvent });
</script>

<template>
  <div v-auto-id="'LiveMatchInfo'"
    :class="[
      $style['headline-info'],
      $style['headline-info--live'],
    ]"
  >
    <div v-if="hasLogos">
      <div :class="$style['headline-info__container']">
        <HeadlineTeam
          position="center"
          role="host"
          :name="sportlineEvent.competitors[0]?.name"
          :logo="sportlineEvent.competitors[0]?.logo"
          :logo-meta="sportlineEvent.competitors[0]?.logoMeta"
          :power-play="sportlineEvent.incidentStatistics.host.powerPlay"
        />

        <div>
          <div :class="$style['headline-info__date']">
            <span :class="$style['headline-info__day']">{{ date }}</span>
            <br>
            <span :class="$style['headline-info__time']">{{ time }}</span>
            <div
              v-if="matchProgress"
              :class="$style['headline-info__score-holder']"
            >
              <HeadlineTeamScore
                role="host"
                :score="matchProgress.totalScore.host"
                :innings="innings?.host"
                size="32"
                gap="16"
              />

              <span :class="$style['headline-info__divider']" />

              <HeadlineTeamScore
                role="guest"
                :score="matchProgress.totalScore.guest"
                :innings="innings?.guest"
                size="32"
                gap="16"
              />
            </div>
          </div>
          <div
            :class="{
              [$style['headline-info__stage']]: true,
              [$style['headline-info__stage--alone']]: !doShowTimeline,
            }"
          >
            <label :class="$style['headline-info__stage-line']">
              {{ stage }}
              <HeadlineLiveTimeInfo
                :sportline-event="sportlineEvent"
              />
            </label>
            <label
              v-if="scoreDetailsLine"
              :class="$style['headline-info__stage-line']"
            >
              ({{ scoreDetailsLine }})
            </label>
          </div>
          <SportlineLiveEventProgress
            v-if="doShowTimeline"
            :class="$style['live-match-info-progress-bar']"
            :sportline-event="sportlineEvent"
          />
        </div>

        <HeadlineTeam
          position="center"
          role="guest"
          :name="sportlineEvent.competitors[1]?.name"
          :logo="sportlineEvent.competitors[1]?.logo"
          :logo-meta="sportlineEvent.competitors[1]?.logoMeta"
          :power-play="sportlineEvent.incidentStatistics.guest.powerPlay"
        />
      </div>

      <slot
        name="primary-market"
        :has-timeline="doShowTimeline"
      />
    </div>
    <div v-else>
      <div :class="$style['headline-info__date']">
        <span :class="$style['headline-info__day']">{{ date }}</span>
        <br>
        <span :class="$style['headline-info__time']">{{ time }}</span>
      </div>
      <div
        v-if="matchProgress"
        :class="$style['headline-info__score-line']"
      >
        <HeadlineTeam
          position="left"
          role="host"
          :name="sportlineEvent.competitors[0]?.name"
          :score="matchProgress.totalScore.host"
          :innings="innings?.host"
          :power-play="sportlineEvent.incidentStatistics.host.powerPlay"
        />

        <span :class="$style['headline-info__divider']" />

        <HeadlineTeam
          position="right"
          role="guest"
          :name="sportlineEvent.competitors[1]?.name"
          :score="matchProgress.totalScore.guest"
          :innings="innings?.guest"
          :power-play="sportlineEvent.incidentStatistics.guest.powerPlay"
        />
      </div>
      <div :class="$style['headline-info__stage']">
        <label :class="$style['headline-info__stage-line']">
          {{ stage }}
          <HeadlineLiveTimeInfo
            :sportline-event="sportlineEvent"
          />
        </label>
        <label
          v-if="scoreDetailsLine"
          :class="$style['headline-info__stage-line']"
        >
          ({{ scoreDetailsLine }})
        </label>
      </div>
      <SportlineLiveEventProgress
        v-if="doShowTimeline"
        :class="$style['live-match-info-progress-bar']"
        :sportline-event="sportlineEvent"
      />
      <slot
        name="primary-market"
        :has-timeline="doShowTimeline"
      />
    </div>
  </div>
</template>

<style module lang="scss">
@import 'web/src/modules/sportline/submodules/event-details/styles/headline-desktop';

.live-match-info-progress-bar {
  width: 160px;
  margin: 0 auto 16px;
}
</style>
