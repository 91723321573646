import type { OptionalAnyIconName } from '@leon-hub/icons';

import type { RegionRepresentation } from 'web/src/modules/sportline/types';
import { isCyberSportDiscipline } from 'web/src/modules/sportline/guards';
import { RegionFamilyToIconMapping, RegionFamilyToIconMappingDefault } from 'web/src/modules/sportline/constants';

export function getCyberSportIconName(representation: RegionRepresentation): OptionalAnyIconName {
  const { icon } = representation;

  return isCyberSportDiscipline(icon)
    ? RegionFamilyToIconMapping[icon]
    : RegionFamilyToIconMappingDefault;
}
