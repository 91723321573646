import type { ComputedRef, Ref } from 'vue';
import { computed, toRef } from 'vue';
import { useRouter } from 'vue-router';

import { RouteName } from '@leon-hub/routing-config-names';

import { useFormatMoney } from 'web/src/modules/money/composables';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useCashbackStore } from 'web/src/modules/promotions/store';

export interface CashbackBalanceComposable {
  formattedBalance: ComputedRef<string>;
  timestamp: Ref<number>;
  isShowBalance: ComputedRef<boolean>;
  isSuspended: Ref<boolean>;
  cashbackBalanceClick: () => void;
}

export default function useCashbackBalance(): CashbackBalanceComposable {
  const formatMoney = useFormatMoney();
  const router = useRouter();
  const isWeb2BonusCashbackWidgetEnabled = toRef(useSiteConfigStore(), 'isWeb2BonusCashbackWidgetEnabled');

  const cashbackStore = useCashbackStore();
  const cashbackAmount = toRef(cashbackStore, 'cashbackAmount');
  const timestamp = toRef(cashbackStore, 'timestampEndDate');
  const cashbackPromotionUrl = toRef(cashbackStore, 'cashbackPromotionUrl');
  const cashbackPromotionCategoryId = toRef(cashbackStore, 'cashbackPromotionCategoryId');
  const isCashbackRoundActiveNow = toRef(cashbackStore, 'isCashbackRoundActiveNow');
  const isCashbackParticipatingConfirmed = toRef(cashbackStore, 'isCashbackParticipatingConfirmed');
  const isSuspended = toRef(cashbackStore, 'isCashbackSuspended');

  const formattedBalance = computed<string>(() => formatMoney(cashbackAmount.value));

  const isLink = computed<boolean>(() => (!!cashbackPromotionCategoryId.value && !!cashbackPromotionUrl.value));
  // eslint-disable-next-line max-len
  const isShowBalance = computed(() => isWeb2BonusCashbackWidgetEnabled.value && isCashbackRoundActiveNow.value && isCashbackParticipatingConfirmed.value);

  function cashbackBalanceClick(): void {
    if (isLink.value) {
      void router.push({
        name: RouteName.PROMOTION_DETAILS,
        params: {
          categoryId: cashbackPromotionCategoryId.value,
          actionUrl: cashbackPromotionUrl.value,
        },
      });
    }
  }

  return {
    formattedBalance,
    isShowBalance,
    isSuspended,
    timestamp,
    cashbackBalanceClick,
  };
}
