export function onWindowVisibilityChanged(
  callback: (isVisible: boolean) => (void | Promise<void>),
): {
    addVisibilityChangeEventListener: () => void;
    removeVisibilityChangeEventListener: () => void;
  } {
  function visibleCallback(): void {
    void callback(true);
  }

  function hiddenCallback(): void {
    void callback(false);
  }

  function resizeCallback(): void {
    void callback(document.visibilityState === 'visible');
  }

  function addVisibilityChangeEventListener(): void {
    if (process.env.VUE_APP_PLATFORM_CORDOVA) {
      window.document.addEventListener('resume', visibleCallback);
      window.document.addEventListener('pause', hiddenCallback);
    } else {
      window.document.addEventListener('visibilitychange', resizeCallback);
    }
  }

  function removeVisibilityChangeEventListener(): void {
    if (process.env.VUE_APP_PLATFORM_CORDOVA) {
      window.document.removeEventListener('resume', visibleCallback);
      window.document.removeEventListener('pause', hiddenCallback);
    } else {
      window.document.removeEventListener('visibilitychange', resizeCallback);
    }
  }

  return {
    addVisibilityChangeEventListener,
    removeVisibilityChangeEventListener,
  };
}
