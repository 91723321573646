import type { RouteLocationNormalizedLoaded } from 'vue-router';

import {
  assert,
  isOptionalString,
  isString,
} from '@leon-hub/guards';

import type { RoutingSeoData } from 'web/src/modules/core/types';

export default function getCmsParams(
  routeName: RouteLocationNormalizedLoaded['name'],
  routeParams: RouteLocationNormalizedLoaded['params'],
  seoConfigs?: Record<string, RoutingSeoData>,
): Record<string, string> {
  if (!routeName || !seoConfigs) {
    return {};
  }

  assert(isString(routeName));

  const seoConfig = seoConfigs[routeName];

  const seoData = seoConfig?.seoData;

  if (!seoData) {
    return {};
  }

  const cmsParams: Record<string, string> = {};

  for (const key of Object.keys(seoData.params || {})) {
    if (seoData.params[key].startsWith(':')) {
      const routeKey = seoData.params[key].substring(1);
      const value = routeParams[routeKey];
      assert(isOptionalString(value));

      if (value) {
        cmsParams[key] = value;
      }
    } else {
      cmsParams[key] = seoData.params[key];
    }
  }

  if (seoData.place) {
    cmsParams.place = seoData.place;
  }

  return cmsParams;
}
