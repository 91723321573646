import { defineStore } from 'pinia';
import {
  computed, ref, toRef,
} from 'vue';
import { useRouter } from 'vue-router';

import type {
  PaymentType,
  BankCard,
  SubmitPaymentResponse,
  PaymentSystemOptions,
} from '@leon-hub/api-sdk';
import {
  doSubmitDeposit,
  doTsupisCardDelete,
  getDepositsSystemInfo,
  getDepositSystemsList,
} from '@leon-hub/api-sdk';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';
import { assert } from '@leon-hub/guards';
import { FormControlType } from '@leon-hub/form-utils';

import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import useCreditCardTokenization from 'web/src/modules/payments/composables/useCreditCardTokenization';
import usePaymentsStore from 'web/src/modules/payments/store/usePaymentsStore';
import { useErrorsConverter } from 'web/src/modules/errors/composables';
import type { FormExternalErrors } from 'web/src/components/Form/types';
import addCustomerFormPaymentsErrors from 'web/src/utils/forms/addCustomerFormPaymentsErrors';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import CustomerFormError from 'web/src/utils/forms/CustomerFormError';
import type {
  PaymentFormSchema, PaymentsSubmitPayload,
} from 'web/src/modules/payments/types/documentTypes';
import { usePaymentsCategories } from 'web/src/modules/payments/composables/usePaymentsCategories';
import { usePaymentSystemRequestTime } from 'web/src/modules/payments/composables/usePaymentSystemRequestTime';
import { usePaymentSystemData } from 'web/src/modules/payments/composables/usePaymentSystemData';
import { useBonusStore } from 'web/src/modules/bonuses/store';
import { isPaymentStatus } from 'web/src/modules/payments/guards/isPaymentStatus';
import type { ErrorCodeDeposit } from 'web/src/modules/payments/enums';
import {
  PaymentFormFieldName,
  PaymentsFormStep,
  PaymentsStatus,
} from 'web/src/modules/payments/enums';
import { useTheme } from 'web/src/modules/theme/composables';
import isEmptyCustBcodesField from 'web/src/modules/payments/submodules/deposits/utils/isEmptyCustBcodesField';
import paymentsCreditCardLogoMapper from 'web/src/modules/payments/utils/paymentsCreditCardLogoMapper';
import { useCardNumberError } from 'web/src/modules/payments/composables/useCardNumberError';
import { isErrorDepositCode } from 'web/src/modules/payments/guards/isErrorDepositCode';
import useFormDefaultPhoneValue from 'web/src/modules/phone/composables/useFormDefaultFormPhoneValue';
import { getUiFieldRawOptions } from 'web/src/utils/jsonSchemaAdapter';
import isFormWidget from 'web/src/components/Form/guards/isFormWidget';

const useDepositStore = defineStore('deposit', () => {
  const { theme } = useTheme();
  const gqlClient = useGraphqlClient();
  const router = useRouter();
  const {
    doTokenize,
    resetToken,
    tokenizePayload,
  } = useCreditCardTokenization();
  const analytics = useAnalytics();
  const errorConverter = useErrorsConverter();

  const paymentStore = usePaymentsStore();
  const bonusStore = useBonusStore();

  const isNoneSelected = toRef(() => bonusStore.isNoneSelected);
  const selectedBonus = toRef(() => bonusStore.selectedBonus);
  const isChoosingBonusBeforeDeposit = toRef(() => bonusStore.isChoosingBonusBeforeDeposit);

  const mobileRedirectUrlFormatted = toRef(() => paymentStore.mobileRedirectUrlFormatted);

  const lastVisitedDepositSystemId = ref<string>('');

  const errorCode = ref<Maybe<ErrorCodeDeposit | null>>(null);
  const errorMessage = ref<Maybe<string>>(null);
  const phoneLogo = ref('');

  const {
    paymentSystems,
    setCategories,
    categories,
  } = usePaymentsCategories<PaymentType.DEPOSIT>();

  const {
    currentPaymentSystem,
    paymentSystemData,
    name,
    submitPaymentResponse,
    setSubmitResponse,
    isAdditionalRegistrationNeeded,
    creditCards,
    nextSteps,
    paymentsProcedureStatus,
    paymentMessage,
    redirectUrl,
    htmlContent,
    htmlContentProperties,
    redirectCharset,
    redirectFormParams,
    redirectMethod,
    uiFormSchema,
    formStep,
    uiFormSchamAdapted,
    initialModalProperties,
    submitButton,
    amount,
    amountAsNumber,
    amountAsMoney,
    minAmount,
    creditCardToken,
    validatorErrorPatterns,
    isFormPending,
    creditCardType,
    cardExpirationDate,
    paymentSystemId,
    submittedAmountAsMoney,
    customErrors,
    setSelectedBonusModalStatus,
    clearCurrentStep,
    getPaymentSubmitCancelPayload,
    clearSubmitResponse,
    setSubmittedAmount,
    handlePaymentError,
    isCurrentValueFromSuggestedAmounts,
    setOpenIn,
  } = usePaymentSystemData<PaymentType.DEPOSIT>();

  const { cardNumberErrors } = useCardNumberError(
    [PaymentFormFieldName.CARDNUMBER],
    creditCardToken,
  );

  const {
    isReadyToRequest,
    setLastPaymentSystemRequestTime,
  } = usePaymentSystemRequestTime();

  async function loadDepositSystems(): Promise<void> {
    if (isReadyToRequest()) {
      const data = await getDepositSystemsList(gqlClient,
        (node) => node.queries.finance.deposit.getDepositSystemsList,
        { options: {} });
      setCategories(data.paymentCategories);
      if (data.errorCode && data.message) {
        errorCode.value = isErrorDepositCode(data.errorCode) ? data.errorCode : null;
        errorMessage.value = data.message;
      } else {
        errorCode.value = null;
        errorMessage.value = null;
      }
      setLastPaymentSystemRequestTime();
    }
  }

  async function loadDepositSystemInfo(systemId: string): Promise<void> {
    currentPaymentSystem.value = await getDepositsSystemInfo(gqlClient,
      (node) => node.queries.finance.deposit.getDepositSystemInfo,
      { options: { paymentSystemId: systemId } });
  }

  async function deleteCreditCard(bankCard: BankCard): Promise<void> {
    const response = await doTsupisCardDelete(gqlClient,
      (node) => node.mutations.finance.tsupisCardDelete,
      {
        options: {
          pan: bankCard.pan || '',
          token: bankCard.token || '',
        },
      });
    if (response?.result === 'OK' && paymentSystemId.value) {
      await loadDepositSystemInfo(paymentSystemId.value);
    }
  }

  const hasSelectedBonusModal = toRef(() => currentPaymentSystem.value?.hideNoSelectedBonusModal ?? false);

  const depositBonusList = computed(() => [...paymentSystemData.value?.bonuses ?? []]);

  const showDepositBonusIntro = computed(
    () => !depositBonusList.value.length || !hasSelectedBonusModal.value,
  );

  const selectedBonusCode = computed(() => depositBonusList.value
    .find((bonus) => bonus.campaignId === selectedBonus.value)?.bonusCode || '');

  const currentBonus = computed(() => depositBonusList.value.find(
    (item) => item.campaignId === selectedBonus.value,
  ));

  const selectedBonusMinAmount = computed(() => currentBonus.value?.minDepositAmount?.amount ?? 0);

  // eslint-disable-next-line max-len
  const depositPaymentBonuses = computed(() => depositBonusList.value.filter((item) => item.imgUrl || item?.image?.src));

  const defaultInputValue = computed(() => {
    const defaultValue = paymentSystemData.value?.defaultInputValue;
    // if there was a bonus selected on a deposit list page, use it's min amount in paymentsInput
    // in case it is higher than default amount for specific payment system
    // eslint-disable-next-line sonarjs/no-collapsible-if
    if (!isNoneSelected.value) {
      if (selectedBonusMinAmount.value
        && defaultValue
        && selectedBonusMinAmount.value > defaultValue
      ) {
        return selectedBonusMinAmount.value;
      }
    }

    return defaultValue || 0;
  });

  const hasDepostBonus = computed(() => {
    const uiSchemaFields = uiFormSchema.value?.fields ?? null;
    if (uiSchemaFields) {
      return uiSchemaFields.some((item) => {
        assert(isFormWidget(item.widget));
        return item.widget === FormControlType.DepositBonus;
      });
    }
    return false;
  });

  const depositTokenizeFormParams = computed(
    () => tokenizePayload.value.formParams.filter((item) => !isEmptyCustBcodesField(item)),
  );

  const depositSubmitButtonLabel = computed(() => {
    const buttonName = paymentSystemData.value?.buttonName;
    if (buttonName) {
      return buttonName;
    }
    return submitButton.value.label;
  });

  function pushOnSuccess(): void { // TODO: move to common logic
    if (process.env.VUE_APP_LAYOUT_PHONE) {
      void router.push(mobileRedirectUrlFormatted.value);
    } else {
      void router.closeModal();
    }
  }

  function processSubmitDeposit(data: PaymentsSubmitPayload) { // TODO: Pass data from the widget FormCryptoPaymentData to formData
    const result = { ...data };
    const fields = submitPaymentResponse.value?.uiFormSchema?.fields;
    const p2p_credentials = fields?.find((field) => field.id === 'p2p_credentials');
    const valueList = p2p_credentials?.options?.find((option) => option.key === 'fields')?.valueList as PaymentSystemOptions[];

    if (valueList) {
      for (const item of valueList) {
        if (item.name) {
          result.payload.formParams = [
            ...result.payload.formParams,
            {
              key: item.name,
              value: item.value || '',
            },
          ];
        }
      }
    }

    return result;
  }

  async function submitDeposit(data: PaymentsSubmitPayload): Promise<void> {
    if (submitButton.value.disabled) {
      return;
    }

    isFormPending.value = true;
    paymentsProcedureStatus.value = { status: PaymentsStatus.LOADING, action: data.action };
    try {
      const processData = processSubmitDeposit(data);
      await doTokenize(processData.payload, creditCards.value);
      const response = await doSubmitDeposit(gqlClient,
        (node) => node.mutations.finance.deposit.submit,
        {
          options: {
            paymentSystemId: data.payload.paymentSystemId,
            schemaId: data.payload.schemaId,
            formParams: depositTokenizeFormParams.value,
          },
        },
        {
          timeout: 60_000,
          retry: 0,
        });
      setSubmittedAmount();
      setSubmitResponse(response as Maybe<SubmitPaymentResponse>);

      if (response.web2OpenIn) {
        setOpenIn(response.web2OpenIn);
      }

      const result = isPaymentStatus(response.result) ? response.result : null;
      if (result === PaymentsStatus.OK || result === PaymentsStatus.SUCCESS) {
        paymentsProcedureStatus.value.status = PaymentsStatus.OK;
      }
    } catch (rawError) {
      const error = errorConverter.convertToBaseError(rawError);
      let formErrors: Maybe<FormExternalErrors> = null;

      if (submitPaymentResponse.value?.uiFormSchema) {
        formErrors = addCustomerFormPaymentsErrors(error, submitPaymentResponse.value?.uiFormSchema, data.payload);
      }

      analytics.push(AnalyticsEvent.Z_MAKE_DEPOSITE, {
        deposits: {
          error: {
            paymentSystemId: data.payload.paymentSystemId,
            message: error.message,
            code: `${error.code}`,
          },
        },
      });

      if (formErrors) {
        throw new CustomerFormError(formErrors);
      }

      if (paymentsProcedureStatus.value.action) {
        paymentsProcedureStatus.value.status = PaymentsStatus.ERROR;
      }
      throw rawError;
    } finally {
      resetToken();
      isFormPending.value = false;
    }
  }

  const depositSubmitButton = computed(() => ({
    ...submitButton.value,
    label: depositSubmitButtonLabel.value,
  }));

  const formProps = computed<Maybe<PaymentFormSchema>>(() => {
    if (uiFormSchamAdapted.value) {
      uiFormSchamAdapted.value
        .addPropertiesByWidget(FormControlType.FastSum, (field) => ({
          defaultInputValue: defaultInputValue.value,
          options: {
            ...(field.options ? getUiFieldRawOptions(field.options) : {}),
            isCurrentValueFromSuggestedAmounts: isCurrentValueFromSuggestedAmounts.value,
            isDeposit: true,
          },
        }))
        .addOptionsByWidget(FormControlType.DepositBonus, {
          depositBonusList: depositBonusList.value,
          selectedBonus: selectedBonus.value,
          selectedBonusCode: selectedBonusCode.value,
          isChoosingBonusBeforeDeposit: isChoosingBonusBeforeDeposit.value,
          isNoneSelected: isNoneSelected.value,
        })
        .addOptionsByWidget(FormControlType.CreditCardSelector, {
          allowNewCard: paymentSystemData.value?.allowNewCard ?? false,
          creditCards: creditCards.value,
          selectedCreditCardToken: creditCardToken.value,
        })
        .addOptionsById(PaymentFormFieldName.CARDNUMBER_SHOW, {
          selectedCardTypeLogo: paymentsCreditCardLogoMapper(creditCardType.value, theme.value),
        })
        .addPropertiesByWidget(FormControlType.DepositBonus, {
          default: selectedBonusCode.value,
          hidden: !depositPaymentBonuses.value.length,
        })
        .addPropertiesByWidgetGroup([
          FormControlType.Phone,
          FormControlType.PhoneInput,
          FormControlType.PhoneCountriesSelectorInput,
        ], (field) => ({
          default: useFormDefaultPhoneValue().parsePhoneNumberByUIField(field),
          options: {
            ...(field.options ? getUiFieldRawOptions(field.options) : {}),
            logo: phoneLogo.value,
          },
        }));

      return {
        schema: uiFormSchamAdapted.value?.getFormSchema(),
        uiSchema: {
          ...uiFormSchamAdapted.value?.getFormUiSchema(),
          submitButton: process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED ? null : depositSubmitButton.value,
          validatorErrorPatterns: validatorErrorPatterns.value,
        },
      };
    }
    return null;
  });

  const depositCustomErrors = computed(() => ({
    ...customErrors.value,
    ...(formStep.value === PaymentsFormStep.NEW_CARD ? cardNumberErrors.value : {}),
  }));

  const changePhoneLogo = (value: string) => {
    phoneLogo.value = value;
  };

  function setLastVisitedDepositSystemId(id: string): void {
    lastVisitedDepositSystemId.value = id;
  }

  return {
    loadDepositSystems,
    loadDepositSystemInfo,
    submitDeposit,
    setSelectedBonusModalStatus,
    deleteCreditCard,
    depositCategories: categories,
    paymentSystems,
    paymentSystemData,
    setLastVisitedDepositSystemId,
    isAdditionalRegistrationNeeded,
    currentPaymentSystem,
    submitPaymentResponse,
    creditCards,
    nextSteps,
    name,
    showDepositBonusIntro,
    paymentsProcedureStatus,
    paymentMessage,
    defaultInputValue,
    depositBonusList,
    redirectUrl,
    redirectMethod,
    htmlContent,
    htmlContentProperties,
    redirectCharset,
    redirectFormParams,
    formProps,
    initialModalProperties,
    formStep,
    amount,
    amountAsNumber,
    amountAsMoney,
    minAmount,
    hasDepostBonus,
    clearCurrentStep,
    paymentSystemId,
    creditCardToken,
    pushOnSuccess,
    getPaymentSubmitCancelPayload,
    isFormPending,
    clearSubmitResponse,
    cardExpirationDate,
    submittedAmountAsMoney,
    handlePaymentError,
    customErrors: depositCustomErrors,
    currentBonus,
    selectedBonusMinAmount,
    errorCode,
    errorMessage,
    submitButton: depositSubmitButton,
    changePhoneLogo,
  };
});

export default useDepositStore;
