import type { Ref } from 'vue';
import { toRef, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

import type { Web2FeedbackRequest } from '@leon-hub/api-sdk';
import { AccessDeniedRemoteApiExceptionCode, FeedbackChannel, Rating } from '@leon-hub/api-sdk';
import GqlApiBatchedSubRequestError from '@leon-hub/api/src/client/graphql/errors/GqlApiBatchedSubRequestError';
import { assert, isString } from '@leon-hub/guards';
import { Timer } from '@leon-hub/utils';
import { RouteName } from '@leon-hub/routing-config-names';
import { BusEvent, useBusSafeSubscribe } from '@leon-hub/event-bus';

import { DialogAction, PresetName } from 'web/src/modules/dialogs/enums';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
import { usePromotionsStore } from 'web/src/modules/promotions/store';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { ModalSelector } from 'web/src/modules/core/enums';
import { ModalWidth } from 'web/src/components/Modal/enums';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useDialogs } from 'web/src/modules/dialogs/composables';
import { useSupportFeedbackStore } from 'web/src/modules/support/submodules/feedback/store';
import { PromotionCustomerRatingMethodType } from 'web/src/modules/promotions/types';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import type VFeedbackCompositionEmitFormData
  from 'web/src/components/FeedbackComposition/VFeedbackComposition/types/VFeedbackCompositionEmitFormData';
import { useErrorsConverter } from 'web/src/modules/errors/composables';

export interface PromotionRateComposable {
  feedbackCountdown: Ref<number>;
  feedbackFormMaxChars: Ref<number>;
  haveValidEmailValue: Ref<boolean>;
  isApiErrorEmailMessage: Ref<string>;
  isDislikePressed: Ref<boolean>;
  isLikePressed: Ref<boolean>;
  isPromotionsLikeEnabled: Ref<boolean>;
  isRateButtonEnabled: Ref<boolean>;
  isRateFeedBackModal: Ref<boolean>;
  isRateFeedBackVisible: Ref<boolean>;
  modalSelector: ModalSelector;
  promotionLikes: Ref<number>;
  savedErrorDuplicateEmail: Ref<string>;
  submitting: Ref<boolean>;

  beforeMount(): void;

  handleLike(): Promise<void>;

  handleDislike(): Promise<void>;

  feedbackRate(): void;

  onRateFeedbackSubmit(data: VFeedbackCompositionEmitFormData): Promise<void>;

  onCloseRateFeedback(): void;
}

export default function usePromotionRate(): PromotionRateComposable {
  const { $translate } = useI18n();
  const { showDialog, closeDialog } = useDialogs();
  const router = useRouter();

  const scgStore = useSiteConfigStore();
  const isPromotionsLikeEnabled = toRef(scgStore, 'isPromotionsLikeEnabled');
  const promotionsLikeFeedbackCountdown = toRef(scgStore, 'promotionsLikeFeedbackCountdown');
  const feedbackFormMaxChars = toRef(scgStore, 'feedbackFormMaxChars');

  const customerDataStore = useCustomerDataStore();
  const haveValidEmailValue = toRef(customerDataStore, 'haveValidEmailValue');
  const customerEmail = toRef(customerDataStore, 'email');

  const promotionsStore = usePromotionsStore();
  const currentPromotion = toRef(promotionsStore, 'promotion');
  const promotionLikes = toRef(promotionsStore, 'promotionLikes');
  const isRateButtonEnabled = toRef(promotionsStore, 'isRateButtonEnabled');

  const { convertToBaseError } = useErrorsConverter();

  const supportFeedbackStore = useSupportFeedbackStore();

  const { isLoggedIn } = useIsLoggedIn();

  const isRateFeedBackModal = ref(false);
  const isLikePressed = ref(false);
  const isDislikePressed = ref(false);
  const isRateFeedBackVisible = ref(false);
  const feedbackCountdown = ref(0);
  const submitting = ref(false);
  const isApiErrorEmailMessage = ref('');
  const savedErrorDuplicateEmail = ref('');
  let updateRateFeedbackTimerId = 0;

  const modalSelector: ModalSelector = ModalSelector.BODY;

  function setRateButtonPressed(): void {
    if (currentPromotion.value?.customerRating === Rating.POSITIVE) {
      isLikePressed.value = true;
    }

    if (currentPromotion.value?.customerRating === Rating.NEGATIVE) {
      isDislikePressed.value = true;
    }
  }

  function resetRateButton(): void {
    isLikePressed.value = false;
    isDislikePressed.value = false;
    promotionsStore.setInitialLikes(0);
    stopRateFeedbackCountdown();
  }

  async function setRating(rating: Maybe<Rating>): Promise<void> {
    const id = currentPromotion.value?.id;

    assert(isString(id), 'id should be string');
    await promotionsStore.promotionRate({ id, rating });
  }

  function startRateFeedbackCountdown(): void {
    isRateFeedBackVisible.value = true;

    updateRateFeedbackTimerId = Timer.setInterval(() => {
      feedbackCountdown.value -= 1;
      if (feedbackCountdown.value <= 0) {
        stopRateFeedbackCountdown();
      }
    }, 1000);
  }

  function stopRateFeedbackCountdown(): void {
    isRateFeedBackVisible.value = false;
    feedbackCountdown.value = promotionsLikeFeedbackCountdown.value;
    Timer.clearInterval(updateRateFeedbackTimerId);
  }

  function onCloseRateFeedback(): void {
    isRateFeedBackModal.value = false;
  }

  async function handleLike(): Promise<void> {
    if (!isLoggedIn.value) {
      void router.push({ name: RouteName.LOGIN });
      return;
    }

    if (!isRateButtonEnabled.value) {
      return;
    }

    if (!isDislikePressed.value) {
      const rating: Maybe<Rating> = !isLikePressed.value ? Rating.POSITIVE : null;
      const method = !isLikePressed.value
        ? PromotionCustomerRatingMethodType.INCREASE : PromotionCustomerRatingMethodType.DECREASE;
      promotionsStore.setLikes(method);

      if (!isLikePressed.value) {
        startRateFeedbackCountdown();
      } else {
        stopRateFeedbackCountdown();
      }

      isLikePressed.value = !isLikePressed.value;
      await setRating(rating);
    } else {
      stopRateFeedbackCountdown();
      promotionsStore.setLikes(PromotionCustomerRatingMethodType.INCREASE);
      isDislikePressed.value = false;
      isLikePressed.value = !isLikePressed.value;
      await setRating(Rating.POSITIVE);
      startRateFeedbackCountdown();
    }
  }

  async function handleDislike(): Promise<void> {
    if (!isLoggedIn.value) {
      void router.push({ name: RouteName.LOGIN });
      return;
    }

    if (!isRateButtonEnabled.value) {
      return;
    }

    if (!isLikePressed.value) {
      const rating: Maybe<Rating> = !isDislikePressed.value ? Rating.NEGATIVE : null;

      if (!isDislikePressed.value) {
        startRateFeedbackCountdown();
      } else {
        stopRateFeedbackCountdown();
      }

      isDislikePressed.value = !isDislikePressed.value;
      await setRating(rating);
    } else {
      stopRateFeedbackCountdown();
      promotionsStore.setLikes(PromotionCustomerRatingMethodType.DECREASE);
      isLikePressed.value = false;
      isDislikePressed.value = !isDislikePressed.value;
      await setRating(Rating.NEGATIVE);
      startRateFeedbackCountdown();
    }
  }

  function feedbackRate(): void {
    if (isLoggedIn.value) {
      isRateFeedBackModal.value = true;
    } else {
      isLikePressed.value = false;
      isDislikePressed.value = false;
      void router.push({ name: RouteName.LOGIN });
    }
    stopRateFeedbackCountdown();
  }

  async function onRateFeedbackSubmit(data: VFeedbackCompositionEmitFormData): Promise<void> {
    try {
      submitting.value = true;
      isApiErrorEmailMessage.value = '';
      savedErrorDuplicateEmail.value = '';
      const userEmail = haveValidEmailValue.value ? customerEmail.value : data.email;

      const payload: Mutable<Web2FeedbackRequest> = {
        body: data.comment,
        channel: FeedbackChannel.PROMOACTION,
        actionId: String(currentPromotion.value?.actionId),
        lbType: currentPromotion.value?.promotionType,
        rating: isLikePressed.value ? Rating.POSITIVE : Rating.NEGATIVE,
        email: userEmail,
      };

      await supportFeedbackStore.sendUserResponse(payload);

      if (process.env.VUE_APP_LAYOUT_DESKTOP) {
        showDialog({
          presetName: PresetName.ALERT_SUCCESS,
          options: {
            confirmMessage: $translate('WEB2_THANK_YOU_FOR_FEEDBACK').value,
            title: $translate('WEB2_FEEDBACK_SENT').value,
            dataTestId: 'promo-feedback-success',
          },
        });
      } else if (process.env.VUE_APP_LAYOUT_PHONE) {
        showDialog({
          presetName: PresetName.ALERT_SUCCESS,
          options: {
            confirmMessage: $translate('WEB2_THANK_YOU_FOR_FEEDBACK').value,
            title: $translate('WEB2_FEEDBACK_SENT').value,
            dataTestId: 'promo-feedback-success-mobile',
          },
        });
      }
      isRateFeedBackModal.value = false;
    } catch (error) {
      if (error instanceof GqlApiBatchedSubRequestError
        && error.code.equals('EMAIL_ALREADY_EXISTS')) {
        isApiErrorEmailMessage.value = error.message;
        savedErrorDuplicateEmail.value = data.email;
        submitting.value = false;
        return;
      }
      isRateFeedBackModal.value = false;
      const { subscribe, id } = showDialog({
        presetName: PresetName.ALERT_WARNING,
        options: {
          title: $translate('JS_CAPTION_ATTENTION').value,
          confirmMessage: $translate('WEB2_USER_FEEDBACK_IS_NOT_SENT').value,
          width: process.env.VUE_APP_LAYOUT_DESKTOP ? ModalWidth.SMALL : ModalWidth.DEFAULT,
          dataTestId: 'promo-feedback-is-not-sent',
        },
      });

      subscribe({
        [DialogAction.MODAL_CLOSE]: () => {
          closeDialog(id);
        },
      });
    }
    submitting.value = false;
  }

  function updateLikesState(): void {
    resetRateButton();
    setRateButtonPressed();
    if (currentPromotion.value?.likes) {
      promotionsStore.setInitialLikes(currentPromotion.value.likes || 0);
    }
  }

  function beforeMount(): void {
    feedbackCountdown.value = promotionsLikeFeedbackCountdown.value;
    useBusSafeSubscribe(BusEvent.API_ERROR, ({ error }) => {
      const originalError = convertToBaseError(error);
      if (originalError.code.equals(AccessDeniedRemoteApiExceptionCode.ACCESS_DENIED) && !isLoggedIn.value) {
        isRateFeedBackModal.value = false;
      }
    });

    updateLikesState();
  }

  watch(currentPromotion, updateLikesState);

  return {
    isPromotionsLikeEnabled,
    isRateFeedBackVisible,
    isLikePressed,
    isDislikePressed,
    isRateButtonEnabled,
    isRateFeedBackModal,
    modalSelector,
    promotionLikes,
    feedbackCountdown,
    feedbackFormMaxChars,
    submitting,
    haveValidEmailValue,
    isApiErrorEmailMessage,
    savedErrorDuplicateEmail,
    beforeMount,
    handleLike,
    handleDislike,
    feedbackRate,
    onRateFeedbackSubmit,
    onCloseRateFeedback,
  };
}
