import {
  assert,
} from '@leon-hub/guards';

import type { InputHintProps, TextInputProps } from 'web/src/components/Input';
import type { FormValidatorErrorValue } from 'web/src/components/Form/types';
import isInputHintProps from 'web/src/components/Input/guards/isInputHintProps';

import getErrorFields from './getErrorFields';
import getHintFields from './getHintFields';
import getHintLinkFields from './getHintLinkFields';

interface Props<T = TextInputProps> {
  options?: T;
  error?: FormValidatorErrorValue;
  hintMessage?: string;
}

export default function getWrapperHintProperties<T>(
  { options, error, hintMessage }: Props<T>,
): InputHintProps {
  if (!options) {
    return {};
  }
  assert(isInputHintProps(options));
  return {
    ...getErrorFields(options, error),
    ...getHintFields(options, hintMessage),
    ...getHintLinkFields(options),
  };
}
