import { assert } from '@leon-hub/guards';
import { logger } from '@leon-hub/logging';

import type { SlipEntry, SlipEntryId } from '../types';
import isSlipEntry from '../../../guards/isSlipEntry';
import findSlipEntryById from '../../../utils/findSlipEntryById';

export default function logRemoveFromSlip(id: SlipEntryId, allBatchedItems: SlipEntry[]): void {
  const slipEntry = findSlipEntryById(id, allBatchedItems) ?? {};
  assert(isSlipEntry(slipEntry));
  const {
    betline, event, market, runner, odds,
  } = slipEntry;
  logger.info('Removing from betslip', {
    betline, event, market, runner, odds,
  });
}
