import type { Ref } from 'vue';
import { computed } from 'vue';

import {
  useSportlineElementMarketColumns,
  useSportlineElementMarketTypes,
} from 'web/src/modules/sportline/composables/markets';
import { useProvideMarketColumn } from 'web/src/modules/sportline/composables/list';
import {
  useFirstLeagueInSportElement,
  useFirstElementsInSportElement,
} from 'web/src/modules/sportline/submodules/breadcrumbs/composables';
import type {
  SportId,
  Sport,
  Region,
  League,
  SportElement,
  RegionElement,
  SportlineEventElement,
  OutrightSportlineEvent,
} from 'web/src/modules/sportline/types';
import {
  getSportEventElementsListCounters,
} from 'web/src/modules/sportline/utils';
import { createSportlineFragmentBasisKey } from 'web/src/modules/sportline/composables/list/utils';

interface UseBreadcrumbsRelatedEventsProps {
  relatedEvents: Ref<SportlineEventElement[]>;
  sportElement: Ref<SportElement>;
  isMarketTypesSelectionEnabled: Ref<boolean>;
}

interface UseBreadcrumbsRelatedEventsComposable {
  sport: Ref<Sport>;
  region: Ref<Region>;
  league: Ref<League>;
  regionElement: Ref<RegionElement>;
  doShowOutrightEvents: Ref<boolean>;
}

export function useBreadcrumbsRelatedEvents(
  props: UseBreadcrumbsRelatedEventsProps,
): UseBreadcrumbsRelatedEventsComposable {
  const {
    relatedEvents,
    sportElement,
    isMarketTypesSelectionEnabled,
  } = props;

  const { marketsColumns } = useSportlineElementMarketColumns({ sportlineElement: sportElement });
  const { marketsTypes } = useSportlineElementMarketTypes({ sportlineElement: sportElement });

  // @TODO check logic for Live Outrights
  const liveAndPreMatchEvents = computed<SportlineEventElement[]>(() => relatedEvents.value
    .filter((element) => !element.sportEvent.isOutright));
  const outrightEvents = computed<SportlineEventElement<OutrightSportlineEvent>[]>(() => relatedEvents.value
    .filter((element): element is SportlineEventElement<OutrightSportlineEvent> => element.sportEvent.isOutright));

  const doShowOutrightEvents = computed<boolean>(() => outrightEvents.value.length > 0);

  const { sport, region, league } = useFirstLeagueInSportElement({ sportElement });
  const sportId = computed<SportId>(() => sport.value.id);
  const basisKey = createSportlineFragmentBasisKey({ sportId });
  const {
    regionElement: defaultRegionElement,
    leagueElement: defaultLeagueElement,
  } = useFirstElementsInSportElement({ sportElement });

  const regionElement = computed<RegionElement>(() => {
    const counters = getSportEventElementsListCounters(relatedEvents.value);

    return {
      ...defaultRegionElement.value,
      leagues: [{
        ...defaultLeagueElement.value,
        sportEvents: liveAndPreMatchEvents.value,
        outrightEvents: outrightEvents.value,
        counters,
      }],
      counters,
    };
  });

  useProvideMarketColumn({
    basisKey,
    isMarketTypesSelectionEnabled,
    marketsColumns,
    marketsTypes,
  });

  return {
    sport,
    region,
    league,
    regionElement,
    doShowOutrightEvents,
  };
}
