export enum CasinoGameCloseMethod {
  SWIPE = 'swipe',
  ICON = 'icon',
}

export enum EgsPostMessageEvent {
  gameExit = 'game:exit',
  gameError = 'game:error',
  backToLobby = 'backToLobby',
}

export enum PanelLayout {
  PANEL = 'panel',
  SLOTS_PANEL = 'slots-panel',
  SLOTS_BAR = 'slots-bar',
}

export enum DigitainProviderPostMessageEvent {
  backToHome = 'rgs-backToHome',
  deposit = 'rgs-deposit',
}
