import type { SlipEntry } from '../../slip-info/types';

const enum EventType {
  LIVE = 'live',
  PREMATCH = 'prematch',
}

export default function checkBetPlaceType(betPlaceEvents: readonly SlipEntry[], liveEventsIds: string[]): EventType {
  const hasLiveEvent = betPlaceEvents.some((item) => liveEventsIds.includes(item.event.toString()));

  return hasLiveEvent ? EventType.LIVE : EventType.PREMATCH;
}
