<script lang="ts" setup>
import { toRef } from 'vue';

import type { BetlinePostMatchStatistics } from 'web/src/modules/sportline/types/rest';
import VList from 'web/src/components/List/VList/VList.vue';
import VListItemAccordion from 'web/src/components/List/VListItemAccordion/VListItemAccordion.vue';

import usePostMatchStatisticResults from './usePostMatchStatisticResults';
import PostMatchStatisticTimeline from './PostMatchStatisticTimeline.vue';
import PostMatchStatisticSimpleResultScore from './PostMatchStatisticSimpleResultScore.vue';
import PostMatchStatisticIncidentsList from './PostMatchStatisticIncidentsList.vue';

interface PostMatchStatisticResultsProps {
  postMatchStatistic: Maybe<BetlinePostMatchStatistics>;
}

const props = defineProps<PostMatchStatisticResultsProps>();
const postMatchStatistic = toRef(props, 'postMatchStatistic');

const {
  isDisplayTimeline,
  isDisplayScoresByTimes,
  isDisplayScoresBySets,
} = usePostMatchStatisticResults({ postMatchStatistic });
</script>

<template>
  <VList v-auto-id="'PostMatchStatisticResults'" independent-items>
    <VListItemAccordion
      id="results-of-the-match"
      :title="$t('WEB2_MATCH_STATISTIC_MATCH_RESULTS')"
      is-title-centered
      open
    >
      <template #content>
        <div v-if="isDisplayTimeline && postMatchStatistic">
          <PostMatchStatisticTimeline
            :class="$style['post-match-statistic-results__timeline']"
            :post-match-statistic="postMatchStatistic"
          />

          <PostMatchStatisticIncidentsList
            :post-match-statistic="postMatchStatistic"
          />
        </div>
        <div v-else-if="isDisplayScoresByTimes && postMatchStatistic">
          <PostMatchStatisticSimpleResultScore
            :post-match-statistic="postMatchStatistic"
            is-display-scores-by-times
          />
        </div>
        <div v-else-if="isDisplayScoresBySets && postMatchStatistic">
          <PostMatchStatisticSimpleResultScore
            :post-match-statistic="postMatchStatistic"
            is-display-scores-by-sets
          />
        </div>
        <div v-else-if="postMatchStatistic">
          <PostMatchStatisticSimpleResultScore
            :post-match-statistic="postMatchStatistic"
          />
        </div>
      </template>
    </VListItemAccordion>
  </VList>
</template>

<style lang="scss" module>
.post-match-statistic-results__timeline {
  margin-bottom: 16px;
}
</style>
