import type { BetlineSportEventMatchStatistics } from 'web/src/modules/sportline/types/rest';

export function isEmptyHead2Head(h2h?: Maybe<BetlineSportEventMatchStatistics['scores24Head2Head']>): boolean {
  if (!h2h) { return true; }
  // including zeros
  const isEmptyDraws = !h2h.draws;
  const isEmptyTotal = !h2h.total;
  const isEmptyGoals = !h2h.goals?.length || h2h.goals.every((score) => !score);
  const isEmptyWins = !h2h.wins?.length || h2h.wins.every((score) => !score);
  return isEmptyDraws
    && isEmptyTotal
    && isEmptyGoals
    && isEmptyWins;
}

export function isEmptyMatches(statistic: BetlineSportEventMatchStatistics): boolean {
  const isEmptyJoinMatches = !statistic.joinMatches?.length;
  const isEmptyHomeMatches = !statistic.homeMatches?.length;
  const isEmptyAwayMatches = !statistic.awayMatches?.length;
  return isEmptyJoinMatches
    && isEmptyHomeMatches
    && isEmptyAwayMatches;
}

export function isEmptyTeams(teams?: Maybe<BetlineSportEventMatchStatistics['teams']>): boolean {
  return !teams?.length;
}

export function isBetlineSportEventMatchStatisticsEmpty(statistic?: Maybe<BetlineSportEventMatchStatistics>): boolean {
  if (!statistic) { return true; }
  if (isEmptyTeams(statistic.teams)) { return true; }
  return isEmptyHead2Head(statistic.scores24Head2Head)
    && isEmptyMatches(statistic);
}
