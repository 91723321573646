import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import { assert } from '@leon-hub/guards';

import type { TabsItem } from 'web/src/components/Tabs/types';
import { useI18n } from 'web/src/modules/i18n/composables';

import { SettingsTabs } from '../../../enums';
import type {
  BetSlipSettingsViewEmits,
} from '../types';

interface UseBetSlipSettingsView {
  settingsTabs: ComputedRef<TabsItem[]>;
  emitSelectSettingsTab: (id: SettingsTabs) => void;
  emitSettingsBackClick: () => void;
  handleScroll: (event: Event) => void;
}

export default function useBetSlipSettingsView(
  emit: BetSlipSettingsViewEmits,
): UseBetSlipSettingsView {
  const { $translate } = useI18n();
  const settingsTabs = computed<TabsItem[]>(() => [
    {
      id: SettingsTabs.COMMON_SETTINGS,
      label: $translate('WEB2_SLIP_SETTINGS_TAB_COMMON').value,
    },
    {
      id: SettingsTabs.FAST_BETS,
      label: $translate('WEB2_SLIP_SETTINGS_TAB_FAST_BETS').value,
    },
  ]);

  const emitSelectSettingsTab = (id: SettingsTabs): void => {
    emit('select-settings-tab', id);
  };

  const emitSettingsBackClick = (): void => {
    emit('settings-back-click');
  };

  const handleScroll = (event: Event): void => {
    if (process.env.VUE_APP_LAYOUT_PHONE) {
      const { target } = event;
      assert(target instanceof HTMLElement);
      if (target?.scrollTop >= 0) {
        event.stopPropagation();
        emit('scroll', event);
      }
    }
  };

  return {
    settingsTabs,
    emitSelectSettingsTab,
    emitSettingsBackClick,
    handleScroll,
  };
}
