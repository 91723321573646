import type { Ref } from 'vue';
import {
  toRef,
  computed,
} from 'vue';

import { useFormatMoney } from 'web/src/modules/money/composables';
import { useCashbackStore } from 'web/src/modules/promotions/store';

import type { CasinoGameBonusProps, CasinoGameBonusEmits } from '../types';

export interface CasinoGameBonusBarComposable {
  bonusTitle: Ref<string>;
  balance: Ref<string>;
  isBonusActive: Ref<boolean>;
  bonusAutoTransferEnabled: Ref<boolean>;
  progress: Ref<number>;
  expiringThrough: Ref<string>;
  amount: Ref<string>;
  completedAmount: Ref<string>;
  requiredAmount: Ref<string>;
  isProgressComplete: Ref<boolean>;
  onClickBonusDetails(): void;
  onClickWithdrawalBonus(): void;
}

export default function useCasinoGameBonus(
  props: CasinoGameBonusProps,
  emit: CasinoGameBonusEmits,
): CasinoGameBonusBarComposable {
  const cashbackStore = useCashbackStore();
  const formatMoney = useFormatMoney();

  const bonusTitle = computed<string>(() => props.item?.campaignName || '');
  const balance = computed<string>(() => {
    if (!props.item) {
      return '';
    }

    const { walletBalanceNumber, currency } = props.item;
    return formatMoney(walletBalanceNumber, { currency });
  });
  const isBonusActive = computed<boolean>(() => !!props.item?.actionUrl);
  const progress = computed<number>(() => (props.item?.progress || 0) * 100);
  const expiringThrough = computed<string>(() => props.item?.expiringThrough || '');
  const amount = computed<string>(() => {
    if (!props.item) {
      return '';
    }

    return formatMoney(props.item.amountNumber, { hideCurrency: true });
  });

  const completedAmount = computed<string>(() => {
    if (!props.item) {
      return '';
    }

    const { currency } = props.item;
    return formatMoney(props.item.amountNumber, { currency });
  });

  const requiredAmount = computed<string>(() => {
    if (!props.item) {
      return '';
    }

    const { requiredAmountNumber, currency } = props.item;
    return formatMoney(requiredAmountNumber, { currency });
  });

  const isProgressComplete = computed<boolean>(() => (
    !!props.item && props.item.progress >= 1 && props.item.walletBalanceNumber !== 0
  ));

  const bonusAutoTransferEnabled = toRef(() => cashbackStore.bonusAutoTransferEnabled);

  function onClickBonusDetails(): void {
    if (process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED && !props.isClickable) {
      return;
    }
    emit('click-bonus-details', props.item);
  }

  function onClickWithdrawalBonus(): void {
    emit('withdraw-bonus');
  }

  return {
    bonusAutoTransferEnabled,
    bonusTitle,
    balance,
    isBonusActive,
    progress,
    expiringThrough,
    amount,
    completedAmount,
    requiredAmount,
    isProgressComplete,
    onClickBonusDetails,
    onClickWithdrawalBonus,
  };
}
