<script lang="ts" setup>
import VEmpty from 'web/src/components/Empty/components/VEmpty';

import useMainAppMetaInfo from './useMainAppMetaInfo';

useMainAppMetaInfo();
</script>

<template>
  <VEmpty v-auto-id="'TheMetaInfo'" />
</template>
