import { ref } from 'vue';

import type { UseTheHeaderComposable } from './useTheHeader';

export default function useTheHeaderDesktop(): UseTheHeaderComposable {
  return {
    headerStyles: ref({}),
    isCalculated: ref(true),
  };
}
