export const enum BetgeniusFramedWidgetProperties {
  EncodedUrl = 'url',
}

export const enum LSportsFramedWidgetProperties {
  EncodedUrl = 'url',
}

export const enum SportradarFramedWidgetProperties {
  MATCH_ID = 'matchId',
  TYPE = 'type',
  LANGUAGE = 'lang',
  ENCODED_URL = 'url',
  THEME = 'theme',
  NAME = 'name',
  SPORT_FAMILY = 'family',
}
