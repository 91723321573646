enum LogoName {
  ALFA = 'alfa',
  APPLE = 'deptsupisapplepay',
  APPSTORE = 'appstore',
  BANK_ACCOUNT = 'paytsupisbankacc',
  BEELINE = 'tsupisbeeline',
  CARD_AMEX = 'card-amex',
  CARD_DINERS = 'card-diners',
  CARD_DISCOVER = 'card-discover',
  CARD_JCB = 'card-jcb',
  CARD_MAESTRO = 'card-maestro',
  CARD_MASTERCARD = 'card-mastercard',
  CARD_MASTERCARD_SLOTT = 'card-mastercard-slott',
  CARD_MIR = 'card-mir',
  CARD_VISA_ELECTRON = 'card-visa-electron',
  CARD_VISA = 'card-visa',
  CARD_VISA_SLOTT = 'card-visa-slott',
  CARD_WALLET = 'card-wallet',
  CARD_WEBMONEY = 'card-webmoney',
  CARD_HUMO = 'card-humo',
  CARD_UZCARD_DARK = 'card-uzcard-dark',
  CARD_UZCARD_LIGHT = 'card-uzcard-light',
  CONTACT = 'contact',
  GOSUSLUGI = 'gosuslugi',
  GOSUSLUGI_WHITE = 'gosuslugi_white_bgr',
  MEGAFON = 'megafon',
  MTS = 'mts',
  PAYMENT = 'ppkripayment',
  QIWI = 'qiwi',
  SBERBANK = 'sberbank',
  SBER_ID = 'sber-id',
  TINKOFF = 'tinkoff',
  TINKOFF_ID = 'tinkoff-id',
  TELE2 = 'tele2',
  WEBMONEY = 'webmoney',
  YANDEX = 'yandex',
  INSTAGRAM = 'insta',
  RSS = 'rss',
  VK = 'vk',
  VK_PAY = 'vk-pay',
  QIWI_TERMINAL = 'qiwi-terminal',
  TERMINALS = 'terminals',
  CREDIT_CARD_UNKNOWN = 'credit-card-unknown',
}

export default LogoName;
