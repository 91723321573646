<script lang="ts" setup>
import { Tag } from '@leon-hub/tags';
import type { IconNameType } from '@leon-hub/icons';

import { VIcon } from '@components/v-icon';

import { useVSubHeader } from './composables';

interface VSubHeaderProps {
  tag?: Tag;
  text?: string;
  prefixIcon?: IconNameType;
  suffixIcon?: IconNameType;
  href?: string;
}

interface VSubHeaderEmits {
  (e: 'click'): void;
}

const props = withDefaults(defineProps<VSubHeaderProps>(), {
  tag: Tag.DIV,
});
const emit = defineEmits<VSubHeaderEmits>();
const {
  mainTag,
  mainProperties,
  prefixIconProperties,
  suffixIconProperties,
} = useVSubHeader(props);

function emitClick(): void {
  emit('click');
}
</script>

<template>
  <component v-auto-id="'VSubHeader'"
    :is="mainTag"
    v-bind="mainProperties"
    ref="header"
    :class="{
      [$style['sub-header']]: true,
      [$style['sub-header--link']]: !!href,
    }"
    @click.prevent="emitClick"
  >
    <VIcon
      v-if="prefixIcon"
      v-bind="prefixIconProperties"
      :class="$style['sub-header__icon sub-header__icon--prefix']"
    />
    <component
      :is="tag"
      :class="$style['sub-header__text']"
    >
      {{ text }}
    </component>
    <VIcon
      v-if="suffixIcon"
      v-bind="suffixIconProperties"
      :class="$style['sub-header__icon sub-header__icon--suffix']"
    />
  </component>
</template>

<style module lang="scss">
.sub-header {
  $self: &;

  display: flex;
  align-items: center;
  padding: 16px 0 12px 8px;
  color: var(--TextSecondary);

  &__text {
    @include medium\13\16;

    flex: 1;
    margin: 0;
    color: var(--TextSecondary);
  }

  &__icon {
    &--prefix {
      margin-right: 8px;
    }

    &--suffix {
      margin: 0 8px;
    }
  }

  &--link {
    text-decoration: none;

    @include is-app-layout-desktop {
      cursor: pointer;

      &:hover {
        color: var(--TextDefault);

        #{$self}__text {
          color: var(--TextDefault);
        }
      }
    }
  }
}
</style>
