import { SportFilterKickoffPeriod } from '@leon-hub/api-sdk';

import type { SportDateTimeFilter } from 'web/src/modules/sportline/submodules/navigation/store/types';
import type {
  SportElement,
  RegionElement,
  LeagueElement,
} from 'web/src/modules/sportline/types';

import {
  getSportEventElementsListCounters,
  reduceListWithSportEventsCounters,
} from '../getCounters';
import { getFiltersMap, splitSportEventsListByFilters } from './splitSportEventsListByFilters';

const otherEventsFilter: SportDateTimeFilter = {
  activeKickoffPeriod: SportFilterKickoffPeriod.ALL,
};

function splitLeagueElementByFilters(leagueElement: LeagueElement, filters: SportDateTimeFilter[]): LeagueElement[] {
  const baseLeagueKey = leagueElement.key;
  const filtersMap = getFiltersMap(baseLeagueKey, filters);
  const sportEventsMap = splitSportEventsListByFilters(leagueElement.sportEvents, baseLeagueKey, filters);
  const outrightEventsMap = splitSportEventsListByFilters(leagueElement.outrightEvents, baseLeagueKey, filters);
  const filtersKeys = Object.keys(filtersMap);

  const leaguesByFilters = filtersKeys.reduce((result, key) => {
    const sportEvents = sportEventsMap[key] ?? [];
    const outrightEvents = outrightEventsMap[key] ?? [];

    if (!sportEvents.length && !outrightEvents.length) { return result; }

    result.push({
      ...leagueElement,
      key,
      sportEvents,
      outrightEvents,
      counters: getSportEventElementsListCounters([...sportEvents, ...outrightEvents]),
      usedFilter: filtersMap[key],
    });

    return result;
  }, [] as LeagueElement[]);

  if (!filtersKeys.includes(baseLeagueKey)) {
    const key = baseLeagueKey;
    const sportEvents = sportEventsMap[key] ?? [];
    const outrightEvents = outrightEventsMap[key] ?? [];

    leaguesByFilters.push({
      ...leagueElement,
      key,
      sportEvents,
      outrightEvents,
      counters: getSportEventElementsListCounters([...sportEvents, ...outrightEvents]),
      usedFilter: leaguesByFilters.length > 0 ? otherEventsFilter : undefined,
    });
  }

  return leaguesByFilters;
}

function splitRegionElementLeaguesByFilters(
  regionElement: RegionElement,
  filters: SportDateTimeFilter[],
): RegionElement {
  const leagues = regionElement.leagues.flatMap((league) => splitLeagueElementByFilters(league, filters));

  return {
    ...regionElement,
    leagues,
    counters: reduceListWithSportEventsCounters(leagues),
  };
}

export function splitSportElementLeaguesByDateFilter(
  sportElement: Maybe<SportElement>,
  filters: SportDateTimeFilter[],
): Maybe<SportElement> {
  if (!sportElement) { return null; }

  const regions = sportElement.regions
    .map((regionElement) => splitRegionElementLeaguesByFilters(regionElement, filters));

  return {
    ...sportElement,
    regions,
    counters: reduceListWithSportEventsCounters(regions),
  };
}
