import type { AppVueRouter } from 'web/src/modules/core/services/router/types';

export interface AppModuleOptions {
  router: AppVueRouter;
}

export abstract class AppModule {
  abstract addRoutes(router: AppVueRouter): void;

  install({ router } : AppModuleOptions): void {
    this.addRoutes(router);
  }
}
