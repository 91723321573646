/**
 *
 * After adding new value into this enum, please also add it to the list in the guard here:
 * web/src/components/Form/guards/isFormWidget.ts
 *
 * */
export const enum FormControlType {
  Text = 'Text',
  Email = 'Email',
  Password = 'Password',
  /** @deprecated, is similar to Password under hood, use hideValidation to hide validation groups */
  PasswordValidator = 'PasswordValidator',
  Select = 'Select',
  DropListSelect = 'DropListSelect',
  Phone = 'Phone',
  /** @deprecated use FormControlType.Phone instead */
  PhoneInput = 'PhoneInput',
  /** @deprecated use FormControlType.Phone instead */
  PhoneCountriesSelectorInput = 'PhoneCountriesSelectorInput',
  Checkbox = 'Checkbox',
  Date = 'Date',
  BonusCode = 'BonusCode',
  SmsCode = 'SmsCode',
  Switch = 'Switch',
  Hidden = 'Hidden',
  FileSingle = 'FileSingle',
  FileMultiple = 'FileMultiple',
  FileDragAndDrop = 'FileDragAndDrop',
  FastSum = 'FastSum',
  CreditCardSelector = 'CreditCardSelector',
  PhoneDisabledInput = 'PhoneDisabledInput',
  WalletDisabledInput = 'WalletDisabledInput',
  WebmoneyDisabledInput = 'WebmoneyDisabledInput',
  Captcha = 'Captcha',
  CaptchaV3 = 'CaptchaV3',
  PhotoInput = 'PhotoInput',
  Fio = 'Fio',
  PieTimerInput = 'PieTimerInput',
  MultilineTextInput = 'MultilineTextInput',
  AutoheightTextInput = 'AutoheightTextInput',
  CardCvv = 'CardCVV',
  CardExpirationDate = 'CardExpirationDate',
  CardNumber = 'CardNumber',
  DepositBonus = 'DepositBonus',
  CurrencyInput = 'CurrencyInput',
  CardName = 'CardName',
  SuggestEmail = 'SuggestEmail',
  CPFNumber = 'CPFnumber',
  AutocompleteAddress = 'AutocompleteAddress',
  SelectWithSearch = 'SelectWithSearch',
  CurrencySelect = 'CurrencySelect',
  AsoTermsCheckbox = 'AsoTermsCheckbox',
  TermsAcceptionCheckbox = 'TermsAcceptionCheckbox',
  CryptoPaymentData = 'CryptoPaymentData',
}
