import memoize from 'lodash/memoize';

import { useAnalytics } from 'web/src/modules/analytics/composables';

import { reloadPageWithCurrentLang } from './utils';
import type { I18nFunctionReactive } from '../types';
import { useI18nStore } from '../store';

interface LanguageTagSetterParams {
  reload?: boolean;
  textMetrics?: string;
  languageTag: string;
}

export interface I18nComposable {
  $translate: I18nFunctionReactive;
  setLanguageTag(params: LanguageTagSetterParams): Promise<void>;
  setLanguageSelectorPlace(value: string): void;
  isLanguageChangeable(tag: string): boolean;
}

/**
 * Composable i18n service.
 */
const useI18n = memoize((): I18nComposable => {
  let languageSelectorPlace = '';

  function isLanguageChangeable(languageTag: string): boolean {
    if (!languageTag) return false;
    const userLanguageTag = languageTag.replace('-', '_');
    const i18nStore = useI18nStore();
    return i18nStore.locale !== userLanguageTag;
  }

  async function setLanguageTag({
    reload,
    textMetrics,
    languageTag,
  }: LanguageTagSetterParams): Promise<void> {
    if (!isLanguageChangeable(languageTag)) return;
    const i18nStore = useI18nStore();
    const prevUrlPrefix = i18nStore.currentUrlPrefix;
    await i18nStore.changeLanguage(languageTag);

    if (textMetrics) {
      const analytics = useAnalytics();
      analytics.clickMap({
        language: {
          [languageSelectorPlace]: textMetrics.toLowerCase(),
        },
      });
    }

    if (reload) {
      reloadPageWithCurrentLang(prevUrlPrefix, i18nStore.currentUrlPrefix);
    }
  }

  return {
    get $translate() {
      return useI18nStore().$t;
    },
    setLanguageTag,
    setLanguageSelectorPlace(value: string): void {
      languageSelectorPlace = value;
    },
    isLanguageChangeable,
  };
});

export default useI18n;
