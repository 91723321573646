import { assert } from '@leon-hub/guards';

import type { VInputInputEvent } from 'web/src/components/Input';

export default function nativeSelectItemToVInputInputEvent({ target }: Event, name = ''): VInputInputEvent {
  assert(target instanceof HTMLSelectElement);
  const { value } = target;
  return {
    target: {
      value,
      name,
    },
  };
}
