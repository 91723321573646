<script lang="ts" setup>
import { toRef, computed, useCssModule } from 'vue';

import { useLiveStatus } from 'web/src/modules/sportline/composables/live-progress';
import type { SportEventMatchProgress } from 'web/src/modules/sportline/types';
import { vTimer } from 'web/src/modules/sportline/directives';

interface SportlineEventGameProgressProps {
  matchProgress: Maybe<SportEventMatchProgress | undefined>;
  isTimeReversedIndicatorAvailable?: boolean;
  fontSize?: 10 | 12;
}

const props = defineProps<SportlineEventGameProgressProps>();
const matchProgress = toRef(props, 'matchProgress');
const isTimeReversedIndicatorAvailable = toRef(props, 'isTimeReversedIndicatorAvailable');
const fontSize = toRef(props, 'fontSize', 12);

const cssModule = useCssModule();

const {
  gameScoreProgress,
  additionalTime,
  isStaticLiveProgress,
  isTimeReversed,
  staticDirectTimeProgressLabel,
  staticReversedTimeProgressLabel,
  mainTimeDirectiveDirection,
  additionalTimeDirectiveDirection,
  mainTimeTotalSeconds,
  mainTimeProgress,
  mainTimeProgressShortFormat,
  additionalTimeTotalSeconds,
  additionalTimeProgress,
} = useLiveStatus(matchProgress);

const gameProgressClasses = computed(() => ({
  [cssModule['sportline-event-game-progress']]: true,
  [cssModule['sportline-event-game-progress--10']]: fontSize.value === 10,
  [cssModule['sportline-event-game-progress--12']]: fontSize.value === 12,
}));
const timeProgressClasses = computed(() => ({
  [cssModule['sportline-event-time-progress']]: true,
  [cssModule['sportline-event-time-progress--10']]: fontSize.value === 10,
  [cssModule['sportline-event-time-progress--12']]: fontSize.value === 12,
}));
</script>

<template>
  <span
    v-if="gameScoreProgress"
    :class="gameProgressClasses"
  >{{ gameScoreProgress }}</span>

  <template v-else-if="isStaticLiveProgress">
    <template v-if="isTimeReversedIndicatorAvailable && isTimeReversed">
      <span
        v-if="staticReversedTimeProgressLabel"
        :class="timeProgressClasses"
      >{{ staticReversedTimeProgressLabel }}</span>
    </template>
    <span
      v-else-if="staticDirectTimeProgressLabel"
      :class="timeProgressClasses"
    >{{ staticDirectTimeProgressLabel }}</span>
  </template>

  <span
    v-else
    :class="$style['sportline-event-time-progress__holder']"
  >
    <template v-if="!additionalTime">
      <span
        v-timer:[mainTimeDirectiveDirection]="mainTimeTotalSeconds"
        :class="timeProgressClasses"
      >{{ mainTimeProgress }}</span>
    </template>
    <template v-else>
      <span
        :class="timeProgressClasses"
      >{{ mainTimeProgressShortFormat }}</span>
      <span
        :class="timeProgressClasses"
      >+<span
        v-timer:[additionalTimeDirectiveDirection]="additionalTimeTotalSeconds"
      >{{ additionalTimeProgress }}</span></span>
    </template>
  </span>
</template>

<style module lang="scss">
.sportline-event-game-progress,
.sportline-event-time-progress {
  color: var(--TextDefault);

  &--10 {
    @include medium\10\12;
  }

  &--12 {
    @include medium\12\12;
  }

  &__holder {
    display: inline-flex;
  }
}
</style>
