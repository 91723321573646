import {
  isString,
  isObject,
  isOptionalString,
} from '@leon-hub/guards';

import type { FormPhoneValue } from '../types/common';

export default function isFormPhoneValue(value: unknown): value is FormPhoneValue {
  if (!isObject(value)) {
    return false;
  }
  return isString(value.suffix)
    && isOptionalString(value.prefix)
    && isOptionalString(value.countryCode);
}
