import { getPixelRatio } from '@leon-hub/utils';

import type { ImageSource } from 'web/src/components/Image/types';

import getImageKey from './getImageKey';

const defaultPixelRatio = 1;
const pixelRatio = getPixelRatio() ?? defaultPixelRatio;

export default function getImageFromImageSource(
  imageSource: ImageSource,
  isSupportWebp = false,
): string {
  let webImage;

  if (isSupportWebp) {
    webImage = imageSource[getImageKey(pixelRatio, true)];
  }

  return webImage || imageSource[getImageKey(pixelRatio)] || imageSource.src;
}
