import { RequestGroupScore24 } from '@leon-hub/api';
import { getLeagueData as getBetlineLeagueDataByLeagueId } from '@leon-hub/api-sdk';

import type { BetlineGetLeagueDataResponse } from 'web/src/modules/sportline/types/rest';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';

export default function getBetlineLeagueData(
  options: { leagueId: number },
  { silent }: { silent?: boolean },
): Promise<BetlineGetLeagueDataResponse> {
  const apiClient = useGraphqlClient();
  return getBetlineLeagueDataByLeagueId(apiClient,
    (node) => node.queries.betLine.getLeagueData,
    { options },
    {
      silent,
      group: RequestGroupScore24,
    });
}
