import type { SportFamilyMapping } from 'web/src/modules/sportline/types/settings';
import type { SportRouteLocationParameters } from 'web/src/modules/sportline/submodules/navigation/store/types';
import type { CoreSportResponse } from 'web/src/modules/sportline/types/rest';

import { matchSportFamily } from './matchSportFamily';

interface BuildNavigationParametersOptions {
  sportResponse: CoreSportResponse;
  sportFamilyMapping: Maybe<SportFamilyMapping>;
}

export function buildSportNavigationParameters(
  options: BuildNavigationParametersOptions,
): SportRouteLocationParameters {
  const {
    sportFamilyMapping,
    sportResponse,
  } = options;

  return {
    urlName: matchSportFamily(sportResponse, sportFamilyMapping),
  };
}
