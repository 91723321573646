import type { RouteLocationRaw, Router } from 'vue-router';

const navigateTo = (router: Router, target: RouteLocationRaw, replace = false): void => {
  if (replace) {
    router.replace(target);
  } else {
    router.push(target);
  }
};

export default navigateTo;
