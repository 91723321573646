import { computed, ref, useSlots } from 'vue';
import type { Ref } from 'vue';

import { Tag } from '@leon-hub/tags';
import { IconName, IconSize } from '@leon-hub/icons';

import type { VIconProps } from '@components/v-icon';

import { BadgeKind } from 'web/src/components/Badge/VBadge/enums';
import type { VBadgeProps } from 'web/src/components/Badge/VBadge/types';
import type { VSwitchProps } from 'web/src/components/Switch/VSwitch/types';
import type { VCircularProgressProps } from 'web/src/components/CircularProgress/VCircularProgress/types';
import { CircleSize } from 'web/src/components/CircularProgress/VCircularProgress/enums';
import type { VButtonProps } from 'web/src/components/Button/VButton/types';
import { ButtonHeight, ButtonKind } from 'web/src/components/Button/VButton/enums';
import hasSlot from 'web/src/utils/vue/hasSlot';

import type { VListItemInnerProps } from '../types';
import getClassObject from './utils/getClassObject';

export interface VListItemInnerComposable {
  tag: Ref<Tag>;
  badgeProperties: Ref<VBadgeProps>;
  switchProperties: Ref<VSwitchProps>;
  computedIconProperties: Ref<VIconProps>;
  progressProperties: Ref<VCircularProgressProps>;
  haveSuffix: Ref<boolean>;
  havePrefix: Ref<boolean>;
  classObject: Ref<Dictionary<boolean>>;
  iconSuffixProperties: Ref<VIconProps>;
  buttonProperties: Ref<VButtonProps>;
  listItemInnerRef: Ref<HTMLElement | undefined>;
  scrollIntoView(): void;
}

export default function useVListItemInner(props: VListItemInnerProps): VListItemInnerComposable {
  const listItemInnerRef = ref<HTMLElement>();
  const slots = useSlots();
  const havePrefix = computed(() => (!!props.iconName || hasSlot(slots, 'prefix')));
  const haveSuffix = computed(() => (!!props.badgeLabel
    || !!props.counter
    || !!props.suffixText
    || props.haveExpand
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    || props.haveSwitcher
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    || !!props.iconSuffixName
    || (props.progressValue || 0) > 0
    || !!props.buttonLabel
  ));

  function scrollIntoView(): void {
    listItemInnerRef.value?.scrollIntoView({ behavior: 'smooth' });
  }

  return {
    tag: computed(() => (props.href ? Tag.A : Tag.DIV)),
    badgeProperties: computed(() => ({ label: props.badgeLabel, kind: BadgeKind.DEFAULT })),
    switchProperties: computed(() => ({
      name: '',
      checked: props.isSwitchChecked,
      disabled: props.isSwitchDisabled,
      kind: props.switchKind,
    })),
    computedIconProperties: computed(() => ({ size: IconSize.SIZE_20, name: props.iconName })),
    progressProperties: computed(() => ({
      circleSize: CircleSize.MINI,
      percentage: props.progressValue,
      iconSize: IconSize.SIZE_20,
    })),
    haveSuffix,
    havePrefix,
    classObject: computed(() => getClassObject(props, havePrefix.value, haveSuffix.value)),
    iconSuffixProperties: computed(() => ({
      name: props.haveExpand || (props?.progressValue || 0) > 0 ? IconName.EXPAND_RIGHT : props.iconSuffixName,
      size: process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED ? IconSize.SIZE_20 : IconSize.SIZE_16,
    })),
    buttonProperties: computed(() => ({
      label: props.buttonLabel,
      kind: ButtonKind.CANCEL,
      height: ButtonHeight.TINY_EXTRA,
    })),
    listItemInnerRef,
    scrollIntoView,
  };
}
