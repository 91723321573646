export enum ButtonKind {
  PRIMARY = 'primary', // --BrandDefault
  PRIMARY_DARK_VERSION = 'primary-dark-version', // --DBrandDefault
  PRIMARY_OPACITY = 'primary-opacity', // --BrandDefault
  SECONDARY = 'secondary', // --Layer2
  SECONDARY_DARK = 'secondary-dark', // --Layer1
  BASE = 'base', // --Button
  BASE_OPACITY = 'base-opacity', // ----OpacityLayer1
  TRANSPARENT = 'transparent',
  TRANSPARENT_BLUE = 'transparent-blue',
  YELLOW = 'yellow', // --Alert
  RED = 'red', // --Red
  CANCEL = 'cancel', // --OpacityDefault
  NAV = 'nav',
  HEADER = 'header',
  HEADER_DARK_VERSION = 'header-dark-version',
  HEADER_ACTIVE = 'header-active',
  HEADER_ACTIVE_DARK_VERSION = 'header-active-dark-version',
  MONOCHROME = 'monochrome',
  DARK_OPACITY = 'dark-opacity', // --Layer0
  TRANSPARENT_DARK_VERSION = 'transparent-dark-version',
}
