import type {
  Sport,
} from 'web/src/modules/sportline/types';

export function isSportChanged(oldValue?: Sport | null, newValue?: Sport | null): boolean {
  if (!oldValue || !newValue) {
    return true;
  }

  // @TODO compare full list of properties
  return oldValue.id !== newValue.id
    || oldValue.name !== newValue.name
    || oldValue.segment.id !== newValue.segment.id
    || oldValue.representation.family !== newValue.representation.family
    || oldValue.navigationParameters.urlName !== newValue.navigationParameters.urlName;
}
