import type { RawEntryInput } from '@leon-hub/api-sdk';

import type { PaymentsSubmitAction } from 'web/src/modules/payments/enums';
import type { PaymentsSubmitPayload } from 'web/src/modules/payments/types/documentTypes';

export default function getSubmitPaymentPayload(
  {
    paymentSystemId,
    schemaId,
    formParams,
    action,
  }
  : {
    paymentSystemId: string;
    schemaId: string;
    formParams: RawEntryInput[];
    action: PaymentsSubmitAction;
  },
): PaymentsSubmitPayload {
  return {
    payload: {
      ts: Date.now(),
      paymentSystemId,
      schemaId,
      formParams,
    },
    action,
  };
}
