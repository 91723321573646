import type { Ref } from 'vue';
import { toRef } from 'vue';

import { prepareSiteConfigHtml } from '@leon-hub/html-utils';

import HighLighter from 'web/src/utils/HighLighter';
import { useSupportFaqStore } from 'web/src/modules/support/submodules/faq/store';

export interface SearchTextComposable {
  highlightedText(text: string): string;
  searchText: Ref<string>;
}
export default function useSearchText(): SearchTextComposable {
  const searchText = toRef(useSupportFaqStore(), 'searchText');
  const highlightedText = (text: string) => HighLighter.highlight(
    prepareSiteConfigHtml(text),
    searchText.value.trim(),
    'highlighted-text',
  );

  return {
    highlightedText,
    searchText,
  };
}
