<script lang="ts" setup>
import type { FooterFollowUsProps } from 'web/src/modules/core/views/FooterFollowUs/types';

import { useFooterFollowUs } from './composables';

withDefaults(defineProps<FooterFollowUsProps>(), {
  showTitle: true,
});

const {
  logos,
  getLogo,
} = useFooterFollowUs();
</script>

<template>
  <section v-auto-id="'FooterFollowUs'" :class="$style['footer__follow']">
    <div
      v-if="showTitle"
      :class="$style['footer__follow-title']"
    >
      {{ $t('WEB2_FOOTER_FOLLOW_US_TITLE') }}
    </div>

    <div :class="$style['footer__follow-list']">
      <template
        v-for="(logo, index) in logos"
        :key="index"
      >
        <a
          v-if="logo.href"
          :href="logo.href"
          target="_blank"
          :class="$style['footer__follow-item']"
        >
          <img
            :class="$style['footer__follow-item']"
            :width="logo.width || 32"
            :height="logo.height || 32"
            :src="getLogo(logo)"
            :alt="logo.alt"
            loading="lazy"
          >
        </a>
        <img
          v-else
          :class="$style['footer__follow-item']"
          :width="logo.width || 32"
          :height="logo.height || 32"
          :src="getLogo(logo)"
          :alt="logo.alt"
          loading="lazy"
        >
      </template>
    </div>
  </section>
</template>

<style lang="scss" module>
@include for-layout using($isDesktop) {
  .footer__follow {
    @include footerFollow($isDesktop);

    &-title {
      @include footerFollowTitle($isDesktop);
    }

    &-list {
      @include footerFollowList($isDesktop);
    }

    &-item {
      margin: 0;
      overflow: hidden;
    }
  }
}
</style>
