import { localStorageManager } from '@leon-hub/local-storage';
import { getLocationHref, getQueryParams } from '@leon-hub/service-locator-env';

import { featureKey } from '../constants';

export const disableAutomationPlugin = (): boolean => {
  let isChanged = false;
  if (localStorageManager.getItem(featureKey)) {
    localStorage.removeItem(featureKey);
    isChanged = true;
  }

  if (process.env.VUE_APP_PLATFORM_WEB && featureKey in getQueryParams()) {
    const url = new URL(getLocationHref());
    url.searchParams.delete(featureKey);
    window.history.replaceState(null, '', url.toString());
    isChanged = true;
  }

  return isChanged;
};
