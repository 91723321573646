// eslint-disable-next-line import/prefer-default-export
export enum LocalStorageKey {
  HINT_SUCCESSS = 'hintSuccess',
  HINT_REQUEST = 'hintRequest',
  HINT_SUCCESSS_HISTORY_DETAIL = 'hintSuccessHistoryDetail',
  HINT_REQUEST_HISTORY_DETAIL = 'hintRequestHistoryDetail',
}

export const enum CustomerFavoritesIdsBackgroundUpdateKey {
  HOME = 'home',
  SIDEBAR = 'sidebar',
  LIVE = 'live',
  PREMATCH = 'prematch',
  CYBERSPORT = 'cybersport',
  DETAILS = 'details',
  REGION = 'region',
  LEAGUE = 'league',
}

export const enum CustomerLiveMatchesApiErrorCode {
  LIMIT_EXCEEDED = 'LIMIT_EXCEEDED',
}
