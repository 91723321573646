import { ComponentKey } from '../types';
import type { ComponentResult } from '../types';
import AbstractFingerprintComponent from '../AbstractFingerprintComponent';

// Component source: https://github.com/fingerprintjs/fingerprintjs2/blob/master/fingerprint2.js#L886
declare global {
  export interface Navigator {
    msMaxTouchPoints?: number;
  }
}

export default class TouchSupportComponent extends AbstractFingerprintComponent {
  constructor() {
    super(ComponentKey.TouchSupport);
  }

  getComponentResult(): Promise<ComponentResult> {
    let maxTouchPoints = 0;
    let touchEvent;
    if (typeof navigator.maxTouchPoints !== 'undefined') {
      maxTouchPoints = navigator.maxTouchPoints;
    } else if (typeof navigator.msMaxTouchPoints !== 'undefined') {
      maxTouchPoints = navigator.msMaxTouchPoints;
    }
    try {
      document.createEvent('TouchEvent');
      touchEvent = true;
    } catch {
      touchEvent = false;
    }
    const touchStart = 'ontouchstart' in window;

    return this.result([maxTouchPoints, touchEvent, touchStart]);
  }
}
