import type { Ref } from 'vue';
import { computed } from 'vue';

import { TimerMode, WidgetType } from '@leon-hub/api-sdk';

import type { SiteConfigDocument } from 'web/src/modules/core/store/types';
import type {
  SportFamilyMapping,
  SportSegmentMapping,
  SportRepresentationSetting,
  SportRepresentationSettings,
} from 'web/src/modules/sportline/types/settings';
import type { BuildSportOptions } from 'web/src/modules/sportline/types/rest';
import { SportFamily } from 'web/src/modules/sportline/enums';
import { sportSegments } from 'web/src/modules/sportline/constants';
import { BetlineSportWidgetType } from 'web/src/modules/sportline/enums/rest';

type SportConfiguration = SiteConfigDocument['sportLine']['sportConfigurationV2'];

type SportUrlMapping = SiteConfigDocument['sportLine']['sportUrlMapping'];

interface UseBuildSportSettingsProps {
  sportlineBlock: Ref<Optional<SiteConfigDocument['sportLine']>>;
}

interface UseBuildSportSettingsComposable {
  sportFamilyMapping: Ref<SportFamilyMapping>;
  isZeroMarginEnabled: Ref<boolean>;
  isCybersportV2Enabled: Ref<boolean>;
  parseSportlineSettings: Ref<BuildSportOptions>;
}

function mapToBetlineWidgetType(widgetType?: Maybe<WidgetType>): Optional<BetlineSportWidgetType> {
  switch (widgetType) {
    case WidgetType.BETRADAR: return BetlineSportWidgetType.BETRADAR;
    case WidgetType.LSPORTS: return BetlineSportWidgetType.LSPORTS;
    case WidgetType.BETGENIUS: return BetlineSportWidgetType.BET_GENIUS;
    default: return undefined;
  }
}

function getDefaultSportRepresentationSettings(): Map<number | string, SportRepresentationSetting> {
  return new Map<number | string, SportRepresentationSetting>();
}

/** Core options for sportline adapters */
export function useBuildSportSettings(
  props: UseBuildSportSettingsProps,
): UseBuildSportSettingsComposable {
  const { sportlineBlock } = props;

  const isZeroMarginEnabled = computed(() => !!sportlineBlock.value?.zeroMarginEnabled);
  const isCybersportV2Enabled = computed(() => !!sportlineBlock.value?.cybersportV2Enabled);

  const sportConfiguration = computed<SportConfiguration>(() => sportlineBlock.value?.sportConfigurationV2 ?? []);
  const sportUrlMapping = computed<SportUrlMapping>(() => sportlineBlock.value?.sportUrlMapping ?? []);

  // Mapping for URLs
  const sportFamilyMapping = computed<SportFamilyMapping>(() => sportUrlMapping.value.reduce((result, config) => ({
    ...result,
    [config.sportId]: config.sportUrl?.toLowerCase(),
  }), {}));

  const sportSegmentMapping = computed<SportSegmentMapping>(() => {
    const result: SportSegmentMapping = {};

    if (isCybersportV2Enabled.value) {
      result[SportFamily.ESport] = sportSegments.cyberSport;
    }

    if (process.env.VUE_APP_FEATURE_VIRTUAL_SPORT_ENABLED && sportlineBlock.value?.virtualSportLiveEventsEnabled && sportSegments.virtual) {
      result[SportFamily.VirtualSoccer] = sportSegments.virtual;
      result[SportFamily.VirtualSoccerV2] = sportSegments.virtual;
      result[SportFamily.VirtualBasketball] = sportSegments.virtual;
      result[SportFamily.VirtualTennis] = sportSegments.virtual;
      result[SportFamily.VirtualTennisInplay] = sportSegments.virtual;
      result[SportFamily.VirtualCricket] = sportSegments.virtual;
    }

    return result;
  });

  const sportRepresentationSettings = computed<SportRepresentationSettings>(() => Object.fromEntries(sportConfiguration.value
    .reduce((result, config) => result.set(config.sportFamily, {
      useFullProgress: config.config.timerMode === TimerMode.SECONDS,
      defaultWidgetType: mapToBetlineWidgetType(config.config.widgetType),
    } as SportRepresentationSetting), getDefaultSportRepresentationSettings())
    .entries()));

  const parseSportlineSettings = computed<BuildSportOptions>(() => ({
    sportSegmentMapping: sportSegmentMapping.value,
    sportFamilyMapping: sportFamilyMapping.value,
    sportRepresentationSettings: sportRepresentationSettings.value,
    canUseZeroMargin: isZeroMarginEnabled.value,
  }));

  return {
    sportFamilyMapping,
    isZeroMarginEnabled,
    isCybersportV2Enabled,
    parseSportlineSettings,
  };
}
