export enum BetSlipMainButtonMode {
  READY_TO_PLACE_BET = 'ready_to_place_bet',
  PENDING_PLACE_BET = 'pending_place_bet',
  PLACE_BET_ERROR = 'place_bet_error',
  BET_SUCCESSFULLY_PLACED = 'bet_successfully_placed',
  MUST_ACCEPT_CHANGES = 'must_accept_changes',
  PENDING_CHANGES = 'pending_changes',
  NOT_ENOUGH_BALANCE = 'not_enough_balance',
  NOT_LOGGED_IN = 'not_logged_in',
  INCORRECT_RANGE = 'incorrect_range',
  UNABLE_TO_APPLY_FREEBET = 'unable_to_apply_freebet',
  NOT_ENOUGH_EVENTS = 'not_enough_events',
  IDENTIFICATION_REQUIRED = 'identification_required',
}
