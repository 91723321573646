import { logger } from '@leon-hub/logging';
import { normalizeError } from '@leon-hub/errors';
import type { SportFilterKickoffPeriod } from '@leon-hub/api-sdk';

import type { SportDateTimeFilter } from '../types';

/**
 * Normalize types for a date time filter for sports pages from the saved value
 * Used to navigate to a filtered page from the sidebar
 */
export default function normalizeSportlineNavigationBetweenPagesFilter(
  filters: Maybe<{
    period?: string;
    from?: string;
    to?: string;
  }>,
): SportDateTimeFilter | undefined {
  const {
    period,
    from,
    to,
  } = filters ?? {};
  let parsedFrom: number | undefined;
  let parsedTo: number | undefined;

  try {
    parsedFrom = from ? parseInt(String(from), 10) : undefined;
    parsedTo = to ? parseInt(String(to), 10) : undefined;
  } catch (rawError) {
    const error = normalizeError(rawError);

    logger.error(error);
  }

  return period
    ? {
      activeKickoffPeriod: period as SportFilterKickoffPeriod,
      customDateFrom: parsedFrom,
      customDateTo: parsedTo,
    }
    : undefined;
}
