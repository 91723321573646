import { onMounted } from 'vue';
import { useRouter } from 'vue-router';

import { isCookiesDisabled } from 'web/src/utils/browser';
import { DialogAction, PresetName } from 'web/src/modules/dialogs/enums';
import { useDialogs } from 'web/src/modules/dialogs/composables/index';
import { useI18n } from 'web/src/modules/i18n/composables';

export function useDisableCookiesGuard(): void {
  const { $translate } = useI18n();
  const { showDialog } = useDialogs();
  const router = useRouter();
  onMounted(() => {
    if (isCookiesDisabled()) {
      showDialog({
        presetName: PresetName.ALERT_WARNING,
        options: {
          title: $translate('WEB2_COOKIES_DISABLED_CAPTION').value,
          confirmMessage: $translate('WEB2_COOKIES_DISABLED_DESCRIPTION').value,
          dataTestId: 'cookies-disabled-description',
        },
      }).subscribe({
        [DialogAction.MODAL_CLOSE]: () => {
          if (process.env.VUE_APP_LAYOUT_DESKTOP) {
            void router.closeModal();
          } else {
            router.back();
          }
        },
      });
    }
  });
}
