import type { ComputedRef, Ref } from 'vue';
import { computed, ref } from 'vue';

import type { LobbySwiperSectionProps } from 'web/src/modules/lobby/components/LobbySwiperSection/types';
import type { LobbySectionBaseEmits, LobbyItemClickData } from 'web/src/modules/lobby/types';
import { useLobbySection } from 'web/src/modules/lobby/components/composables/useLobbySection';
import type { VSwiperScrollEvent } from 'web/src/components/Swiper/VSwiper/types';
import type { LobbySwiperProps, LobbySwiperRef } from 'web/src/modules/lobby/components/LobbySwiper/types';
import type { LobbySectionHeaderProps } from 'web/src/modules/lobby/components/LobbySectionHeader/types';

export interface LobbySwiperSectionComposable {
  swiper: Ref<LobbySwiperRef | undefined>;
  isHeaderVisible: Ref<boolean>;
  lobbySwiperProps: ComputedRef<LobbySwiperProps>;
  headerProps: ComputedRef<LobbySectionHeaderProps>;
  resetScrollPosition: () => void;
  emitActionClick: () => void;
  emitSwiperScroll: (event: VSwiperScrollEvent) => void;
  emitClickLobbyItem: (data: LobbyItemClickData) => void;
}

export default function useLobbySwiperSection(
  props: LobbySwiperSectionProps,
  emits: LobbySectionBaseEmits,
): LobbySwiperSectionComposable {
  const {
    lobbyGridBaseProps,
    headerProps,
    isHeaderVisible,
    emitActionClick,
    emitClickLobbyItem,
  } = useLobbySection(props, emits);

  const swiper = ref<LobbySwiperRef>();
  const lobbySwiperProps = computed<LobbySwiperProps>(() => ({
    ...lobbyGridBaseProps.value,
    isSwiperAuto: props.isSwiperAuto,
    rowsNumber: props.rowsNumber,
    isSwiperBlock: props.isSwiperBlock,
  }));

  function resetScrollPosition(): void {
    swiper.value?.resetScrollPosition();
  }

  function emitSwiperScroll(event: VSwiperScrollEvent): void {
    emits('swiper-scroll', event);
  }

  return {
    swiper,
    lobbySwiperProps,
    headerProps,
    isHeaderVisible,
    emitActionClick,
    emitClickLobbyItem,
    resetScrollPosition,
    emitSwiperScroll,
  };
}
