import { SportFilterKickoffPeriod } from '@leon-hub/api-sdk';

import type { SportSegmentSettings } from 'web/src/modules/sportline/types/settings';

const defaultSportSegmentsSettings: SportSegmentSettings = {
  isPageEnabled: true,
  liveUpdateInterval: 60 * 1000,
  liveActiveEntityUpdateInterval: 60 * 1000,
  isUpdateOnlyActiveEntityEnabled: false,
  isUpdateOnlyActiveEntityOnChangeEnabled: false,
  isComingEnabled: false,
  comingUpdateInterval: 60 * 1000,
  comingKickoffFilter: SportFilterKickoffPeriod.THREEHOURS,
  maximumLiveToShowCompiledComingBlock: null,
  maximumEntitiesInCompiledComingBlock: null,
  maximumComingEventsInEntityInCompiledBlock: null,
  maximumComingEventsInEntityTab: null,
  isUpcomingEventsEnabled: false,
};

export default defaultSportSegmentsSettings;
