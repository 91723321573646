enum SportlineRouteName {
  SPORTLINE_LIVE_EVENTS = 'sportline-live-events',
  SPORTLINE_PREMATCH_EVENTS = 'sportline-prematch-events',
  SPORTLINE_PREMATCH_TODAY = 'sportline-prematch-today',
  SPORTLINE_TOP_EVENTS = 'sportline-top-events',
  SPORTLINE_TOP_LEAGUE = 'sportline-top-league', // single league page in the top section
  SPORTLINE_LIVE_LEAGUE = 'sportline-live-league', // single league page in the live section
  SPORTLINE_VIRTUAL_SPORT = 'sportline-virtual-sport',
  SPORTLINE_CYBERSPORT = 'sportline-cybersport',
  SPORT_REGION = 'sport-region',
  SPORT_LEAGUE = 'sport-league',
  SPORT_EVENT_DETAILS = 'sport-event-details',
  SPORTLINE_SELECT_ODDS = 'sportline-select-odds',
  SLIP_SHARE = 'share-slip',
  GET_SHARED_SLIP = 'betslip',
  SLIP_WIN_BOOST_INFO = 'slip-win-boost-info',

  // Quick bets V2
  QUICK_BETS = 'quick-bets',
  QUICK_BETS_DETAILS = 'quick-bets-details',
}

export default SportlineRouteName;
const sportlineRouteNames = Object.values(SportlineRouteName);

export function isSportlineRouteName(value: unknown): value is SportlineRouteName {
  return sportlineRouteNames.includes(value as SportlineRouteName);
}
