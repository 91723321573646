import {
  isUndefined,
  bindIsArrayOf,
  isNumber,
  isString,
  isValidInterface,
} from '@leon-hub/guards';
import type { FieldGuards } from '@leon-hub/types';

import type {
  MarketGroupStyleResponse,
  MarketGroupSpecifierResponse,
  CoreSportMarketGroup,
} from 'web/src/modules/sportline/types/rest';

function isMarketGroupStyleResponse(value: unknown): value is MarketGroupStyleResponse {
  return isValidInterface<MarketGroupStyleResponse>(value, {
    type: [isUndefined, isString],
  });
}

function isMarketGroupSpecifierResponse(value: unknown): value is MarketGroupSpecifierResponse {
  return isValidInterface<MarketGroupSpecifierResponse>(value, {
    specifier: [isUndefined, isString],
  });
}

export const isCoreSportMarketGroupFieldGuards: FieldGuards<CoreSportMarketGroup> = {
  id: isNumber,
  name: isString,
  marketTypeIds: bindIsArrayOf(isNumber),
  style: [isUndefined, isMarketGroupStyleResponse],
  specifier: [isUndefined, isMarketGroupSpecifierResponse],
};

export function isCoreSportMarketGroup(value: unknown): value is CoreSportMarketGroup {
  return isValidInterface<CoreSportMarketGroup>(value, isCoreSportMarketGroupFieldGuards);
}
