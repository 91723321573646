import type { PaymentsInputProps } from 'web/src/modules/payments/form-widgets/FastSum/components/PaymentsInput/types';

export default function getParsedValue(value: string, props: Pick<PaymentsInputProps, 'deposit'>): string {
  let parsedValue = '';
  if (value) {
    parsedValue = props.deposit
      ? value.replace(/\D|(\D+\.+$|\D+,+$)/g, '')
      : value.replace(/[^\d,.]|(\D+\.+$|\D+,+$)/g, '');
    parsedValue = parsedValue.replace(/^0{2,}/g, '0');

    if (!props.deposit) {
      const matched = parsedValue.match(/^\d+[,.]\d{0,2}/g);
      parsedValue = matched?.length ? matched[0] : parsedValue;
    }
  }
  return parsedValue === '0' ? '' : parsedValue;
}
