<script lang="ts" setup>
import VDynamicContent from 'web/src/components/DynamicContent/VDynamicContent/VDynamicContent.vue';

import { usePromotionDetailsDescription } from './composables';
import type { PromotionDetailsDescriptionProps } from './types';

const props = defineProps<PromotionDetailsDescriptionProps>();

const {
  parsedDescription,
  openSharedInfo,
} = usePromotionDetailsDescription(props);
</script>

<template>
  <VDynamicContent v-auto-id="'PromotionDetailsDescription'"
    :class="$style['promotion-description__content']"
    :content="parsedDescription"
    @open-info="openSharedInfo"
  />
</template>

<style lang="scss" module>
.promotion-description {
  &__content {
    color: $promotionDescriptionColor;

    :global(p) {
      @include promotionDetailsDescriptionText;

      margin: 0 0 24px;
    }

    :global(p:last-child) {
      margin-bottom: 0;
    }

    :global(table) {
      text-align: left;
    }

    :global(table th) {
      @include regular\12\16;

      padding: 16px 16px 8px;
      color: var(--TextSecondary);
      background-color: var(--Layer2);
    }

    :global(table td) {
      @include regular\14\20\025;

      padding: 12px 16px;
      color: var(--TextPrimary);
      background-color: var(--Layer2);
    }
  }
}
</style>
