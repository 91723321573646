import type { RouteLocationNormalized } from 'vue-router';
import type { Component, Ref } from 'vue';
import {
  computed,
  ref,
  toRef,
  watch,
} from 'vue';
import { useRoute } from 'vue-router';

import { Timer } from '@leon-hub/utils';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';
import {
  onComponentActivated,
  onComponentDeactivated,
} from '@leon-hub/vue-utils';

import type { BannerGroup } from 'web/src/modules/banners/store/types';
import { useBannersStore } from 'web/src/modules/banners/store';
import { BannerCategory, BannerDisableClickArea, BannerLocation } from 'web/src/modules/banners/enums';
import { getFilteredBannerGroups } from 'web/src/modules/banners/utils';
import type { BannerItemClickEvent } from 'web/src/modules/banners/types';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import StaticBanner from 'web/src/modules/banners/components/StaticBanner/StaticBanner.vue';
import SwiperBanner from 'web/src/modules/banners/components/SwiperBanner/SwiperBanner.vue';
import FadeCarouselBanner from 'web/src/modules/banners/components/FadeCarouselBanner/FadeCarouselBanner.vue';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';

import type { MainBannerSectionRouteComponentProps } from '../types';

export interface MainBannerSectionRouteComponentComposable {
  showBannerSection: Ref<boolean>;
  bannerGroupComponent: Ref<Component>;
  currentGroup: Ref<BannerGroup | null>;
  isLoggedIn: Ref<boolean>;
  disableClickAreas: Ref<BannerDisableClickArea[]>;
  onRouterLinkClick: ({ path }: BannerItemClickEvent) => void;
}

// eslint-disable-next-line max-len
export default function useMainBannerSectionRouteComponent(props: MainBannerSectionRouteComponentProps): MainBannerSectionRouteComponentComposable {
  const route = useRoute();
  const analytics = useAnalytics();
  const { isLoggedIn } = useIsLoggedIn();

  const bannerGroupsStore = toRef(useBannersStore(), 'bannerGroups');

  const currentGroup = ref<BannerGroup | null>(null);
  let timeout = 0;
  let active = false;

  const banners = computed<BannerGroup['banners']>(() => currentGroup.value?.banners ?? []);
  const showBannerSection = computed<boolean>(() => banners.value.length > 0);
  const disableClickAreas = computed<BannerDisableClickArea[]>(
    () => (props.isStatic ? [] : [BannerDisableClickArea.LEFT, BannerDisableClickArea.TOP]),
  );
  const bannerGroupComponent = computed<Component>(() => {
    if (props.isStatic) {
      return StaticBanner;
    }

    if (props.isStoryBanner) {
      return SwiperBanner;
    }

    return FadeCarouselBanner;
  });

  function onActivate(): void {
    setupCurrentBanner(route);
    active = true;
  }

  function onDeactivate(): void {
    active = false;
  }

  function onRouteChanged(newRoute: RouteLocationNormalized): void {
    if (!active) {
      return;
    }

    timeout = 0;
    // TODO: Refactor - receive route path as prop after transition end
    if (newRoute.meta.transition === 'overlay-in') {
      timeout = 310;
    }

    if (timeout) {
      Timer.clearTimeout(timeout);
    }

    if (timeout > 0) {
      timeout = Timer.setTimeout(() => {
        setupCurrentBanner(newRoute);
      }, timeout);
    } else {
      setupCurrentBanner(newRoute);
    }
  }

  function setupCurrentBanner(value: RouteLocationNormalized): void {
    const { location, customLocationId } = props;
    const bannerGroups = getFilteredBannerGroups(bannerGroupsStore.value, {
      route: value,
      location: location ?? BannerLocation.TOP,
      customLocationId,
    });

    if (bannerGroups.length === 0) {
      currentGroup.value = null;
      return;
    }
    const totalWeight = bannerGroups
      .reduce((accumulator, currentValue) => accumulator + currentValue.weight, 0);
    const randNumber = Math.random() * totalWeight;
    let weightSum = 0;
    currentGroup.value = bannerGroups.find((campaign) => {
      weightSum += campaign.weight;
      return randNumber <= weightSum;
    }) ?? null;
  }

  function onRouterLinkClick({ path }: BannerItemClickEvent): void {
    analytics.clickMap({ promo: 'banners' });

    if (props.bannerCategory === BannerCategory.SUPER_LIVE) {
      if (process.env.VUE_APP_PRODUCT_LEONRU) {
        analytics.push(AnalyticsEvent.Z_SUPER_LIVE_BANNER, {
          superLiveBanner: 'click',
        });
      } else {
        analytics.push(AnalyticsEvent.Z_VIRTUAL_SPORT_BANNER, {
          virtualSportBanner: 'click',
        });
      }
    }

    analytics.push(AnalyticsEvent.Z_OPEN_PROMO_PAGE, {
      promoPage: {
        banners: path,
      },
    });
  }

  watch(() => route, onRouteChanged, { deep: true });

  watch(bannerGroupsStore, () => {
    setupCurrentBanner(route);
  }, { deep: true });

  onComponentActivated(onActivate);
  onComponentDeactivated(onDeactivate);

  return {
    showBannerSection,
    bannerGroupComponent,
    currentGroup,
    isLoggedIn,
    disableClickAreas,
    onRouterLinkClick,
  };
}
