<script lang="ts" setup>
import { useRouter } from 'vue-router';

import { IconName, IconSize } from '@leon-hub/icons';
import { RouteName } from '@leon-hub/routing-config-names';

import { VIcon } from '@components/v-icon';

const router = useRouter();

function openOddsPage(): void {
  void router.push({
    name: RouteName.SPORTLINE_SELECT_ODDS,
  });
}
</script>

<template>
  <button v-auto-id="'OddsSwitcherButton'"
    type="button"
    :class="$style['odds-switcher-button']"
    @click="openOddsPage"
  >
    <span :class="$style['odds-switcher-button__visual']">
      <VIcon
        :name="IconName.ODDS"
        :size="IconSize.SIZE_16"
        ::class="$style['odds-switcher-button__icon']"
      />
      <span :class="$style['odds-switcher-button__title']">{{ $t('WEB2_ODDS') }}</span>
      <VIcon
        :name="IconName.TRIANGLE_DOWN"
        :size="IconSize.SIZE_16"
        :class="$style['odds-switcher-button__arrow']"
      />
    </span>
  </button>
</template>

<style lang="scss" module>
.odds-switcher-button {
  $self: &;

  display: flex;
  flex: none;
  align-items: center;
  justify-content: center;
  padding: 0;
  line-height: inherit;
  color: var(--TextPrimary);
  background: none;
  border: none;

  @include for-hover {
    &:hover &__visual {
      color: var(--TextDefault);
      cursor: pointer;
      background-color: var(--Highlight);
    }

    &--is-header {
      &:hover .odds-switcher-button__visual {
        color: if($isEnvFeatureDarkHeader, var(--DTextDefault), var(--TextDefault));
        background-color: if($isEnvFeatureDarkHeader, var(--DHighlight), var(--Highlight));
      }
    }
  }

  &__visual {
    display: flex;
    gap: 6px;
    align-items: center;
    height: 36px;
    padding: 0 10px;
    background-color: var(--Button);
    border-radius: 18px;
    transition: background-color 0.15s $animationEaseInOut, color 0.15s $animationEaseInOut;

    #{$self}--no-background & {
      background-color: transparent;
    }
  }

  &__title {
    @include medium\12\16;
  }
}
</style>
