<script lang="ts" setup>
import { toRef } from 'vue';

import { VScrollbar } from '@components/v-scrollbar';

import type { BetlineLeaguePlayoff } from 'web/src/modules/sportline/types/rest';
import VList from 'web/src/components/List/VList/VList.vue';
import VListItemAccordion from 'web/src/components/List/VListItemAccordion/VListItemAccordion.vue';
import ExpandButton from 'web/src/modules/sportline/submodules/core-statistic/components/ExpandButton.vue';

import { useLeaguePlayoff } from './useLeaguePlayoff';
import LeaguePlayoffRound from './LeaguePlayoffRound.vue';

interface LeaguePlayoffProps {
  leaguePlayoff: BetlineLeaguePlayoff;
}

const props = defineProps<LeaguePlayoffProps>();
const playoff = toRef(props, 'leaguePlayoff');

const {
  isExpanded,
  canExpand,
  rounds,
} = useLeaguePlayoff({ playoff });
</script>

<template>
  <VList v-auto-id="'LeaguePlayoff'" independent-items>
    <VListItemAccordion
      id="league-playoff"
      :title="$t('WEB2_STATISTICS_PLAYOFF')"
      is-title-centered
      open
    >
      <template #content>
        <VScrollbar test-el="league-playoff">
          <div :class="$style['league-playoff__content']">
            <div
              v-if="playoff.name"
              :class="$style['league-playoff__title']"
            >
              {{ playoff.name }}
            </div>

            <div :class="$style['league-playoff__rounds']">
              <LeaguePlayoffRound
                v-for="(round, roundIndex) in rounds"
                :key="roundIndex"
                :round="round"
                :is-last-round="roundIndex + 1 >= rounds.length"
              />
            </div>
          </div>
        </VScrollbar>

        <ExpandButton
          v-if="canExpand"
          v-model:is-expanded="isExpanded"
          :opened-title="$t('WEB2_MATCH_STATISTIC_HIDE')"
          :closed-title="$t('WEB2_MATCH_STATISTIC_SHOW')"
        />
      </template>
    </VListItemAccordion>
  </VList>
</template>

<style module lang="scss">
.league-playoff {
  &__content {
    display: grid;
    grid-template-columns: 1fr;
    gap: 8px;
  }

  &__title {
    @include medium\14\16\025;

    box-sizing: border-box;
    width: 100%;
    padding: 14px 16px;
    color: var(--TextDefault);
    border: 1px solid var(--Highlight);
    border-radius: 5px;
  }

  &__rounds {
    position: relative;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    padding: 8px 0;
  }
}
</style>
