import { circleSizeMap } from 'web/src/components/CircularProgress/VCircularProgress/sizes/circularProgressSizes';
import { CircleSize } from 'web/src/components/CircularProgress/VCircularProgress/enums';

import type { VCircularProgressProps } from '../../types';

export default function getMatchedSizeValue(
  props: Pick<VCircularProgressProps, 'circleSize'>,
): number {
  return circleSizeMap[props.circleSize ?? CircleSize.DEFAULT];
}
