import type {
  EgsCategory,
} from 'web/src/modules/egs/types';
import type {
  LobbyItemUnit,
} from 'web/src/modules/lobby/types';

import isLobbyGamesItem from './isLobbyGamesItem';

export default function getLobbyGamesCategory(lobbyItem?: LobbyItemUnit): EgsCategory | undefined {
  if (!lobbyItem || !isLobbyGamesItem(lobbyItem)) {
    return undefined;
  }

  return lobbyItem.category;
}
