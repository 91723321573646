import { ComponentKey, ComponentStatus } from '../types';
import type { ComponentResult } from '../types';
import AbstractFingerprintComponent from '../AbstractFingerprintComponent';

declare global {
  interface Navigator {
    deviceMemory: number;
  }
}

export default class DeviceMemoryComponent extends AbstractFingerprintComponent {
  constructor() {
    super(ComponentKey.DeviceMemory);
  }

  getComponentResult(): Promise<ComponentResult> {
    return this.result([window.navigator.deviceMemory || ComponentStatus.NotAvailable]);
  }
}
