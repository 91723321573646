import { CustomInputEventType, InputEventType } from 'web/src/components/Input/enums';

import type { FormDataEvent, FormOutput } from '../../../types';
import type { VFormEmits } from '../types';

interface UseFormEmits {
  onChange(event: FormDataEvent): void;
  onInput(event: FormDataEvent): void;
  onFocus(event: FormDataEvent): void;
  onBlur(event: FormDataEvent): void;
  onSubmit(event?: Event): void;
  onFilled(): void;
  onIconPrefixClick(field: string): void;
  onIconSuffixClick(field: string): void;
  // LEONWEB-9747
  onDisabledButtonClick(): void;
}

type HandlerFunc = (event: FormDataEvent) => FormOutput;

interface Options {
  handleInput: HandlerFunc;
  handleFocus: HandlerFunc;
  handleBlur: HandlerFunc;
  handleSubmit(): FormOutput;
  getCurrentOutput(): FormOutput;
}

export default function useFormEmits(emit: VFormEmits, {
  handleInput,
  handleFocus,
  handleBlur,
  handleSubmit,
  getCurrentOutput,
}: Options): UseFormEmits {
  function onChange(event: FormDataEvent): void {
    const data = handleInput(event);
    emit(InputEventType.CHANGE, data);
  }

  function onInput(event: FormDataEvent): void {
    const data = handleInput(event);
    emit(InputEventType.INPUT, data);
  }

  function onFocus(event: FormDataEvent): void {
    const data = handleFocus(event);
    emit(InputEventType.FOCUS, data);
  }

  function onBlur(event: FormDataEvent): void {
    const data = handleBlur(event);
    emit(InputEventType.BLUR, data);
  }

  function onSubmit(event?: Event): void {
    if (event) {
      event.preventDefault();
    }
    const data = handleSubmit();
    emit(InputEventType.SUBMIT, data);
  }

  function onFilled() {
    emit(CustomInputEventType.FILLED);
  }

  function onIconPrefixClick(field: string): void {
    emit(CustomInputEventType.ICON_PREFIX_CLICK, field);
  }
  function onIconSuffixClick(field: string): void {
    emit(CustomInputEventType.ICON_SUFFIX_CLICK, field);
  }

  // LEONWEB-9747
  function onDisabledButtonClick(): void {
    emit('disabled-button-click', getCurrentOutput());
  }

  return {
    onChange,
    onInput,
    onFocus,
    onBlur,
    onSubmit,
    onFilled,
    onIconPrefixClick,
    onIconSuffixClick,
    onDisabledButtonClick,
  };
}
