<script lang="ts" setup>
import type { ActionLinkProps, ActionLinksEmits } from 'web/src/modules/two-factor-authentication/components/ActionLink/types';
import vDataTestUnit from 'web/src/testing/directives/vDataTestUnit';

defineProps<ActionLinkProps>();
const emit = defineEmits<ActionLinksEmits>();
</script>

<template>
  <a v-auto-id="'ActionLink'"
    v-data-test-unit="{ el: 'action-link' }"
    href="#"
    :class="$style['action-link']"
    @click.prevent="emit('click')"
  >
    {{ title }}
  </a>
</template>

<style lang="scss" module>
.action-link {
  @include regular\14\20\025;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 12px 0;
  text-align: center;
}
</style>
