<script lang="ts" setup>
import { SlottIconName } from '@leon-hub/icons';

import VSmsNumberCheck from 'web/src/components/SmsNumberCheck/VSmsNumberCheck/VSmsNumberCheck.vue';
import VCmsContent from 'web/src/modules/cms/components/VCmsContent/VCmsContent.vue';
import { JumbotronFooterButton, VJumbotron } from 'web/src/components/Jumbotron';
import VHintBlock from 'web/src/components/HintBlock/VHintBlock/VHintBlock.vue';
import type {
  RestorePasswordCheckPhoneFormEmits,
  RestorePasswordCheckPhoneFormProps,
} from 'web/src/modules/restore-password/components/RestorePasswordCheckPhoneForm/types';
import useRestorePasswordCheckPhoneForm
  from 'web/src/modules/restore-password/components/RestorePasswordCheckPhoneForm/composables/useRestorePasswordCheckPhoneForm';
import SHintBlock from 'web/src/components/HintBlock/SHintBlock/SHintBlock.vue';

const props = defineProps<RestorePasswordCheckPhoneFormProps>();
const emits = defineEmits<RestorePasswordCheckPhoneFormEmits>();

const {
  phoneAttemptsLimit,
  isMaxAttempts,
  isShowHintBlock,
  iconProperties,
  closeButtonProperties,
  cmsKey,
  handleRestoreButton,
  smsNumberCheckProperties,
  checkCode,
  sendSmsOrCallToPhone,
  setRetrySmsCodeTime,
  changeShowHintBlock,
  onInput,
  onBlur,
  goToRestorePasswordMainPage,
  goToPhoneCheckBySmsStage,
} = useRestorePasswordCheckPhoneForm(props, emits);
</script>

<template>
  <div v-auto-id="'RestorePasswordCheckPhoneForm'" :class="$style['restore-password']">
    <VJumbotron
      v-if="isMaxAttempts"
      v-bind="iconProperties"
      :heading="$t('WEB2_RESTORE_BYSMS_LIMIT', { limit: String(phoneAttemptsLimit) })"
    >
      {{ $t('WEB2_RESTORE_PASSWORD_CHECK_SMS_LIMIT_DESCRIPTION') }}
      <template #footer>
        <div>
          <JumbotronFooterButton
            v-bind="closeButtonProperties"
            :label="$t('WEB2_RESTORE_PASSWORD_CHECK_SMS_CLOSE')"
            @click="handleRestoreButton"
          />
        </div>
      </template>
    </VJumbotron>
    <div v-else>
      <VSmsNumberCheck
        v-bind="smsNumberCheckProperties"
        @submit="checkCode"
        @retry-button-click="sendSmsOrCallToPhone"
        @set-retry-sms-code-time="setRetrySmsCodeTime"
        @show-hint-with-email-redirect="changeShowHintBlock"
        @input="onInput"
        @blur="onBlur"
      />
      <VHintBlock
        v-if="'1' && isShowHintBlock"
        v-data-test="{ el: 'restore-password-phone-check-hint-block' }"
        :class="$style['restore-password__hint-block']"
      >
        <VCmsContent
          :cms-key="cmsKey"
          silent
          no-padding
          :class="$style['restore-password__hint-block-content']"
          @restore-password-phone-check-go-to-email="goToRestorePasswordMainPage"
          @restore-password-phone-check-go-to-phone-check-by-sms="goToPhoneCheckBySmsStage"
        />
      </VHintBlock>
      <SHintBlock
        v-else-if="null && !isShowHintBlock"
        v-data-test="{ el: 'restore-password-phone-check-hint-block' }"
        :class="$style['restore-password__hint-block']"
        type="tertiary"
        :icon-name="SlottIconName.SLOTT_EXCLAMATION"
        icon-color="secondary"
        :title="$t('WEB2_RESTORE_PIN_PHONE_CHECK_HINT_BLOCK_TIMEOUT_TITLE')"
      >
        <VCmsContent
          :cms-key="cmsKey"
          silent
          no-padding
          :class="$style['restore-password__hint-block-content']"
          @restore-password-phone-check-go-to-email="goToRestorePasswordMainPage"
          @restore-password-phone-check-go-to-phone-check-by-sms="goToPhoneCheckBySmsStage"
        />
      </SHintBlock>
    </div>
  </div>
</template>

<style lang="scss" module>
@include for-layout using($isDesktop) {
  .restore-password {
    padding: if($isDesktop, 16px 32px 32px, 16px);

    &__hint-block {
      margin-top: 20px;
      cursor: default;
    }
  }
}
</style>
