import { prefetchConsumer } from 'web/src/modules/core/apps/main/prefetch/prefetchConsumer';
import type { PrefetchConsumer } from 'web/src/modules/core/apps/main/prefetch/types/PrefetchConsumer';
import type { PrefetchConsumerKey } from 'web/src/modules/core/apps/main/prefetch/prefetchConsumer';

import type {
  PrefetchProducer,
} from './types/PrefetchProducer';

export function consumePrefetch(prefetchProducer: PrefetchProducer): Promise<void[]> {
  async function consume<K extends PrefetchConsumerKey>(
    [key, prefetchPromise]: [key: K, prefetchPromise: PrefetchProducer[K]],
  ): Promise<void> {
    const state = (await prefetchPromise) as Parameters<PrefetchConsumer[K]>[0];
    const handler = prefetchConsumer[key];
    handler(state);
  }

  return Promise.all(Object.entries(prefetchProducer).map(consume));
}
