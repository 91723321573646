import { useRouter } from 'vue-router';
import debounce from 'lodash/debounce';

import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';
import { useCustomMessageListener } from '@leon-hub/browser-composables';

import { useAnalytics } from 'web/src/modules/analytics/composables';
import {
  DigitainProviderPostMessageEvent,
  EgsPostMessageEvent,
} from 'web/src/modules/casino/submodules/game/enums';

import useCasinoGameExitUrls from './useCasinoGameExitUrls';

export default function useCasinoGameStartPostMessage(
  onClose: () => void,
  restartGame: () => void,
): void {
  const router = useRouter();
  const { getExitHref, getBankingHref, bankingLocation } = useCasinoGameExitUrls();

  const { push } = useAnalytics();

  function openDeposits() {
    if (process.env.VUE_APP_LAYOUT_DESKTOP) {
      restartGame();
    }
    void router.push(bankingLocation);
  }

  const debouncedClose = debounce(onClose, 100);
  const debouncedDeposits = debounce(openDeposits, 100);

  function onGameExitEvent(event: MessageEvent): void {
    const { url } = event.data;

    if (!url || url === getExitHref()) {
      onClose();
      return;
    }

    if (!url.match('^([a-z]+://|//)')) {
      if (url.includes(getBankingHref())) {
        openDeposits();
        return;
      }

      void router.replace(url);
      return;
    }

    window.location.href = url;
  }

  function onCustomPostMessage(event: MessageEvent): void {
    if (event.data.type === EgsPostMessageEvent.gameExit) {
      onGameExitEvent(event);
    }

    if (event.data.type === EgsPostMessageEvent.gameError) {
      push(AnalyticsEvent.MODAL_ERROR_MESSAGES, { warningEGS: event.data?.message });
    }

    if (
      event.data.type === EgsPostMessageEvent.backToLobby
      || event.data.type === DigitainProviderPostMessageEvent.backToHome
    ) {
      debouncedClose();
    }

    if (event.data.type === DigitainProviderPostMessageEvent.deposit) {
      debouncedDeposits();
    }
  }

  useCustomMessageListener(onCustomPostMessage);
}
