import type { Component } from 'vue';

import type { VBaseButtonProps } from '@leon-hub/module-buttons';

import { VIcon } from '@components/v-icon';
import { LSpinner, SSpinner } from '@components/spinner';

export default function getIconComponentForButton(
  props: Pick<VBaseButtonProps, 'isLoading' | 'iconName'>,
): Component | undefined {
  if (props.isLoading) {
    return process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED ? SSpinner : LSpinner;
  }

  return props.iconName ? VIcon : undefined;
}
