import type { Ref } from 'vue';
import {
  watch, nextTick, ref,
} from 'vue';

import {
  setInputCursorAfterValue,
} from '@leon-hub/utils';

import type { FocusableInputRef, FocusOptions } from '../types';

type AutofocusProps<T = {}> = T & {
  autofocus?: boolean;
};

interface UseAutofocus {
  focusable: Ref<HTMLElement | undefined>;
  focus: FocusableInputRef['focus'];
}

export function useAutofocus(props: AutofocusProps): UseAutofocus {
  const focusable = ref<HTMLElement | undefined>();

  async function focus({ forceIos, atTheEnd }: FocusOptions = {}) {
    if (!process.env.VUE_APP_OS_IOS || forceIos) {
      await nextTick();
      if (focusable.value) {
        if (atTheEnd && focusable.value instanceof HTMLInputElement) {
          setInputCursorAfterValue(focusable.value);
        }

        focusable.value.focus?.();
      }
    }
  }

  watch(() => props.autofocus, (value) => {
    if (value) {
      void focus();
    }
  }, { immediate: true });

  return {
    focusable,
    focus,
  };
}
