import {
  isObject,
} from '@leon-hub/guards';

import type {
  CreditCardTokenizationData,
} from './types';

export function isCreditCardTokenizationData(argument: unknown): argument is CreditCardTokenizationData {
  return isObject(argument)
    && typeof argument.token === 'string';
}
