import { DeviceFingerprintType } from 'web/src/modules/identity/enums';
import { useIdentityStore } from 'web/src/modules/identity/store';

interface DeviceFingerPrints {
  deviceFingerprint: string;
  deviceFingerprintJs: string;
}

export interface UseDeviceFingerprintsComposable {
  getDeviceFingerprints(): Promise<DeviceFingerPrints>;
}

export default function useDeviceFingerprints(): UseDeviceFingerprintsComposable {
  const { getDeviceFingerprint, getFingerPrintFromStorage } = useIdentityStore();

  async function getDeviceFingerprints(): Promise<DeviceFingerPrints> {
    let deviceFingerprint = '';
    let deviceFingerprintJs = '';

    if (process.env.VUE_APP_PLATFORM_CORDOVA) {
      deviceFingerprint = await getDeviceFingerprint();
    } else {
      deviceFingerprintJs = await getFingerPrintFromStorage(DeviceFingerprintType.DEVICE_FINGERPRINT_JS);

      if (!deviceFingerprintJs) {
        deviceFingerprint = await getDeviceFingerprint(DeviceFingerprintType.DEVICE_FINGERPRINT_BROWSER);
      }
    }

    return {
      deviceFingerprint,
      deviceFingerprintJs,
    };
  }

  return {
    getDeviceFingerprints,
  };
}
