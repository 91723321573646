import type { Ref } from 'vue';
import { ref, watch } from 'vue';

import { getLocationHref } from '@leon-hub/service-locator-env';

import type { ImageSource } from 'web/src/components/Image/types';
import { getImageFromImageSource, isWebpSupported } from 'web/src/components/Image/utils';
import normalizeImagePath from 'web/src/utils/normalizeImagePath';

interface UseRecalculateImageSourceProps {
  imageSource: Ref<Maybe<ImageSource>>;
}

interface UseRecalculateImageSourceComposable {
  normalizedSrc: Ref<Maybe<string>>;
  normalizeSrc(): Promise<void>;
}

export default function useRecalculateImageSource(
  props: UseRecalculateImageSourceProps,
): UseRecalculateImageSourceComposable {
  const { imageSource } = props;

  let isSupportWebp = process.env.VUE_APP_RENDERING_SSR ? false : undefined;

  const normalizedSrc = ref<Maybe<string>>(null);

  async function normalizeSrc(): Promise<void> {
    if (isSupportWebp === undefined) {
      isSupportWebp = await isWebpSupported;
    }

    normalizedSrc.value = imageSource.value
      ? normalizeImagePath(
        getLocationHref(),
        getImageFromImageSource(imageSource.value, isSupportWebp),
      )
      : null;
  }

  watch(imageSource, normalizeSrc);

  return {
    normalizedSrc,
    normalizeSrc,
  };
}
