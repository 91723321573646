import type { Ref } from 'vue';
import {
  toRef,
  computed,
} from 'vue';
import type { RouteLocationResolvedGeneric } from 'vue-router';
import {
  useRouter,
} from 'vue-router';

import { RouteName } from '@leon-hub/routing-config-names';

import type {
  Competitor,
  Market,
  SportEventMatchProgress,
  Score,
} from 'web/src/modules/sportline/types';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import { useLiveStatus } from 'web/src/modules/sportline/composables/live-progress';
import {
  useSportlineEventCompetitors,
  useSportlineEventDateTime,
  useSportlineEventSport,
  useSportlineEventType,
} from 'web/src/modules/sportline/composables/sportline-event';
import {
  useSportlineEventMarketsPriority,
} from 'web/src/modules/sportline/composables/markets';
import { useLiveMatchProgress } from 'web/src/modules/sportline/composables/match-progress';

import type { LobbySportTopMatchItemProps } from '../types';

export interface LobbySportTopMatchItemComposable {
  date: Ref<Maybe<string>>;
  time: Ref<Maybe<string>>;
  isLiveSportEvent: Ref<boolean>;
  isTimeReversedIndicatorAvailable: Ref<boolean>;
  isNoMarketsBlockedBtnEnabled: Ref<boolean>;
  primaryMarket: Ref<Maybe<Market>>;
  totalScore: Ref<Score<string>>;
  stage: Ref<string | undefined>;
  scoreDetailsLine: Ref<string | undefined>;
  backgroundColor: Ref<Maybe<string>>;
  homeTeam: Ref<Maybe<Competitor>>;
  awayTeam: Ref<Maybe<Competitor>>;
  matchProgress: Ref<Maybe<SportEventMatchProgress>>;
  sportlineEventLocation: Ref<RouteLocationResolvedGeneric | undefined>;
  redirectToSportlineItem(): void;
}

export default function useLobbySportTopMatchItem(
  props: LobbySportTopMatchItemProps,
): LobbySportTopMatchItemComposable {
  const router = useRouter();
  const settingsStore = useSportlineSettingsStore();
  const isTimeReversedIndicatorAvailable = toRef(settingsStore, 'isTimeReversedIndicatorAvailable');
  const isNoMarketsBlockedBtnEnabled = toRef(settingsStore, 'isNoMarketsBlockedBtnEnabled');
  const sportlineEvent = toRef(props, 'sportEventDetails');

  const sportlineEventLocation = computed(() => {
    if (!sportlineEvent.value) {
      return undefined;
    }

    return router.resolve({
      name: RouteName.SPORT_EVENT_DETAILS,
      params: { ...sportlineEvent.value.navigationParameters },
    });
  });

  const {
    matchProgress,
  } = useLiveMatchProgress({ sportEvent: sportlineEvent });

  const {
    date,
    time,
  } = useSportlineEventDateTime({
    sportlineEvent,
    isFullDate: true,
  });

  const {
    isLive: isLiveSportEvent,
  } = useSportlineEventType({
    sportEvent: sportlineEvent,
  });

  const {
    stage,
    totalScore,
    scoreDetailsLine,
  } = useLiveStatus(matchProgress);

  const {
    primaryMarket,
  } = useSportlineEventMarketsPriority({
    sportEvent: sportlineEvent,
  });

  const { backgroundColor } = useSportlineEventSport({
    sportlineEvent,
  });

  const {
    host: homeTeam,
    guest: awayTeam,
  } = useSportlineEventCompetitors({
    sportlineEvent,
  });

  function redirectToSportlineItem() {
    if (sportlineEventLocation.value) {
      void router.push(sportlineEventLocation.value);
    }
  }

  return {
    matchProgress,
    scoreDetailsLine,
    isLiveSportEvent,
    isNoMarketsBlockedBtnEnabled,
    isTimeReversedIndicatorAvailable,
    totalScore,
    stage,
    date,
    time,
    primaryMarket,
    backgroundColor,
    homeTeam,
    awayTeam,
    sportlineEventLocation,
    redirectToSportlineItem,
  };
}
