import { ModalDesktopPreset } from 'web/src/modules/core/enums';

interface GetModalPresetInput {
  isLoggedIn: boolean;
  preset?: ModalDesktopPreset;
}

export default function getModalPreset({ preset, isLoggedIn }: GetModalPresetInput): ModalDesktopPreset | undefined {
  if (preset === ModalDesktopPreset.ProfileDesktopModal && !isLoggedIn) {
    return ModalDesktopPreset.SmallDesktopModal;
  }

  return preset;
}
