import type { Ref } from 'vue';
import { toRef, computed } from 'vue';

import useModuleTimersStore from 'web/src/modules/core/store/useModuleTimersStore';

interface UseModuleTimeoutComposable {
  timeout: Ref<number>;
}

export function useModuleTimeout(
  timerName: Maybe<string>,
  timerFallback?: Ref<number>,
): UseModuleTimeoutComposable {
  const moduleTimersStore = useModuleTimersStore();
  const defaultTimeout = toRef(moduleTimersStore, 'defaultTimeout');
  const moduleTimeouts = toRef(moduleTimersStore, 'moduleTimeouts');

  const timeoutSettings = computed(() => (timerName ? moduleTimeouts.value[timerName] : undefined));
  const timeout = computed<number>(() => (
    timeoutSettings.value
    ?? timerFallback?.value
    ?? defaultTimeout.value
  ));

  return {
    timeout,
  };
}
