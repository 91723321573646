<script setup lang="ts">
import { toRef } from 'vue';

import type { LSportsWidgetProps } from 'web/src/modules/sportline/submodules/widgets/types';
import { VLazyIframe as VIframe } from 'web/src/components/Iframe';
import { IframeWidget } from 'web/src/modules/framed-app/enums';

import { useLSportsWidget } from './useLSportsWidget';
import WidgetWrapper from './WidgetWrapper.vue';

const props = defineProps<LSportsWidgetProps>();

const sportlineEvent = toRef(props, 'sportEventDetails');
const isFloatingModeEnabled = toRef(props, 'isFloatingModeEnabled');
const isFloatingModeAllowed = toRef(props, 'isFloatingModeAllowed');

const {
  iframeWidgetSrc,
  additionalQueryStringParameters,
} = useLSportsWidget({ sportlineEvent });
</script>

<template>
  <div v-auto-id="'SportsWidget'" :class="$style['live-widget-slide']">
    <WidgetWrapper
      :class="$style['live-widget-slide__component']"
      :is-floating-mode-allowed="isFloatingModeAllowed"
      :is-floating-mode-enabled="isFloatingModeEnabled"
    >
      <VIframe
        v-if="iframeWidgetSrc"
        :src="iframeWidgetSrc"
        :auto-height="!'1'"
        :full-height="!!'1'"
        full-width
        :name="IframeWidget.LSports"
        :additional-query-string-parameters="additionalQueryStringParameters"
      />
    </WidgetWrapper>
  </div>
</template>

<style module lang="scss">
@include for-layout using ($isDesktop) {
  .live-widget-slide {
    display: flex;
    align-items: baseline;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #0b1f39;

    &__component {
      @if $isDesktop {
        flex: none;
        width: 305px;
        height: 100%;
      } @else {
        flex: 1 0 auto;
      }
    }

    &-loader {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 200px;
    }
  }
}
</style>
