<script lang="ts" setup>
import { defineAsyncComponent } from 'vue';

import { VIcon } from '@components/v-icon';

import VButton from 'web/src/components/Button/VButton/VButton.vue';

import { useVFileInputPlaceholder } from './composables';
import type { VFileInputPlaceholderProps, VFileInputPlaceholderEmits } from './types';

const props = withDefaults(defineProps<VFileInputPlaceholderProps>(), {});
const emit = defineEmits<VFileInputPlaceholderEmits>();
const ImagePreview = defineAsyncComponent(() => import('web/src/components/ImagePreview/VImagePreview/VImagePreview'));
const {
  hasPreviewImage,
  iconProperties,
  previewButtonProps,
  fileNameText,
  isImageFileLayout,
  onImagePreview,
  onImagePreviewHide,
} = useVFileInputPlaceholder(props);
</script>

<template>
  <div v-auto-id="'VFileInputPlaceholder'"
    :class="{
      [$style['file-input-photo-placeholder']]: true,
      [$style['file-input-photo-placeholder__error']]: hasError,
      [$style['file-input-photo-placeholder__hovered']]: isImageFileLayout,
    }"
  >
    <img
      v-if="isImageFileLayout"
      :class="$style['file-input-photo-placeholder__frame']"
      :alt="name"
      :src="imageSrc"
    >
    <div
      :class="{
        [$style['file-input-photo-placeholder__controls']]: true,
        [$style['file-input-photo-placeholder__controls--obscured']]: isImageFileLayout,
      }"
    >
      <div
        :class="{
          [$style['file-input-photo-placeholder__icon-wrapper']]: true,
          [$style['file-input-photo-placeholder__icon-wrapper--error']]: hasError,
        }"
      >
        <VButton
          :class="$style['file-input-photo-placeholder__preview-button']"
          v-bind="previewButtonProps"
          @click="onImagePreview"
        />
        <VIcon
          :class="$style['file-input-photo-placeholder__icon']"
          v-bind="iconProperties"
        />
      </div>
      <div
        v-if="!hasError"
        :class="$style['file-input-photo-placeholder__file-name']"
      >
        {{ fileNameText }}
      </div>
      <template v-else>
        <div :class="$style['file-input-photo-placeholder__error-title']">
          {{ $t('WEB2_FAILED_TO_LOAD_FILE') }}
        </div>
        <div :class="$style['file-input-photo-placeholder__error-file-name']">
          {{ fileNameText }}
        </div>
      </template>
      <button
        type="button"
        :class="$style['file-input-photo-placeholder__clear']"
        @click="emit('clear')"
      >
        {{ $t('WEB2_IMAGE_DELETE') }}
      </button>
    </div>
    <ImagePreview
      v-if="hasPreviewImage"
      :src="imageSrc"
      :name="name"
      @close="onImagePreviewHide"
    />
  </div>
</template>

<style lang="scss" module>
.file-input-photo-placeholder {
  $self: &;

  position: relative;
  display: flex;
  justify-content: center;
  height: 100%;
  overflow: hidden;
  border-radius: 5px;

  &__hovered {
    &:hover {
      #{$self}__preview-button {
        display: block;
      }

      #{$self}__icon {
        display: none;
      }
    }
  }

  #{$self}__preview-button {
    display: none;
  }

  #{$self}__icon {
    display: block;
  }

  &__error {
    border: solid 2px;
    border-color: var(--ErrorDefault);
  }

  &__frame {
    object-fit: contain;
  }

  &__controls {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    border-radius: 5px;

    &--obscured {
      background-color: var(--OpacityLayer0);
    }
  }

  &__icon-wrapper {
    margin: 32px 0 16px;
    color: var(--BrandDefault);

    &--error {
      color: var(--ErrorText);
    }
  }

  &__file-name {
    @include regular\12\16;

    margin-bottom: 24px;
    color: var(--TextDefault);
  }

  &__error-title {
    @include medium\12\16;

    margin-bottom: 2px;
    color: var(--TextDefault);
  }

  &__error-file-name {
    @include regular\12\16;

    margin-bottom: 2px;
    color: var(--TextPrimary);
  }

  &__clear {
    @include regular\13\20;

    color: var(--ErrorText);
    cursor: pointer;
    background: none;
    border: none;
  }
}
</style>
