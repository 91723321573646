import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import type { PhoneInputProps, PhoneInputEmits, VInputInputEvent } from 'web/src/components/Input';
import { useBaseInputEvents } from 'web/src/components/Input/composables';
import { CustomInputEventType } from 'web/src/components/Input/enums';
import type { CountryData } from 'web/src/modules/country/store/types';
import { useCountryStore } from 'web/src/modules/country/store';
import matchPhoneNumberAndCountryCode from 'web/src/utils/phoneNumberUtils/matchPhoneNumberAndCountryCode';

interface UseInternationalPhoneInput {
  computedPrefixesData: ComputedRef<CountryData[]>;
  computedCountryCode: ComputedRef<string>;
  onChange: (event: VInputInputEvent) => void;
  onInput: (event: VInputInputEvent) => void;
  onFocus: (event: VInputInputEvent) => void;
  onBlur: (event: VInputInputEvent) => void;
  onFilled: () => void;
}

export default function useInternationalPhoneInput(
  props: PhoneInputProps,
  emit: PhoneInputEmits,
): UseInternationalPhoneInput {
  const {
    emitChange,
    emitInput,
    emitFocus,
    emitBlur,
  } = useBaseInputEvents(emit);

  const countryStore = useCountryStore();

  const onFilled = (): void => {
    emit(CustomInputEventType.FILLED);
  };

  const computedPrefixesData = computed<CountryData[]>(() => {
    if (props.prefixes?.length) {
      return props.prefixes;
    }
    return countryStore.countriesData;
  });

  const computedCountryCode = computed<string>(() => matchPhoneNumberAndCountryCode({
    phoneNumber: props.value,
    prefix: props.prefix ?? '',
    countries: computedPrefixesData.value,
    countryCode: props.countryCode || countryStore.countryCode,
  }));

  return {
    onChange: emitChange,
    onInput: emitInput,
    onFocus: emitFocus,
    onBlur: emitBlur,
    onFilled,
    computedPrefixesData,
    computedCountryCode,
  };
}
