import type { AbstractErrorOptions } from '@leon-hub/errors';
import { AbstractError } from '@leon-hub/errors';
import type { Without } from '@leon-hub/types';

import { AppErrorCode } from './AppErrorCode';

interface ScriptErrorOptions extends Without<AbstractErrorOptions, 'code'> {
  filename: string | undefined;
}

export class ScriptError extends AbstractError {
  constructor(options: ScriptErrorOptions) {
    super({
      ...options,
      message: `Script failed to load: filename=${options.filename}; message=${options.message}`,
      code: AppErrorCode.SCRIPT_ERROR,
    });
  }
}
