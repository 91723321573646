import type {
  NavigationGuardNext,
  RouteLocationNormalized,
} from 'vue-router';
import { toRef } from 'vue';

import { RouteName } from '@leon-hub/routing-config-names';

import { useRootNavigationStore } from 'web/src/modules/core/store';
import { useSportlinePersistLocation } from 'web/src/modules/sportline/submodules/persist-filters';
import useSportlineLiveStore from 'web/src/modules/sportline/submodules/live/store/useSportlineLiveStore';
import type { AppVueRouter } from 'web/src/modules/core/services/router/types';
import type { SportFamily } from 'web/src/modules/sportline/enums';
import { isOptionalLiveFilterSportFamily } from 'web/src/modules/sportline/submodules/segment/guards';
import { getSportlinePageNavigationParameters } from 'web/src/modules/sportline/utils/navigation';
import { AbstractPrefetch } from 'web/src/modules/core/services/router/prefetch';

export default class LivePagePrefetch extends AbstractPrefetch {
  // eslint-disable-next-line class-methods-use-this
  prefetch(
    router: AppVueRouter,
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ): Promise<void> {
    const liveStore = useSportlineLiveStore();

    const sportFamily = (to.params?.family as string | undefined)?.toLowerCase() as SportFamily;
    const isPageEnabled = toRef(liveStore, 'isPageEnabled');
    const allowedSportFamily = toRef(liveStore, 'allowedSportFamily');

    if (!isPageEnabled.value || !isOptionalLiveFilterSportFamily(sportFamily, allowedSportFamily.value)) {
      next({ name: RouteName.ERROR_NOT_FOUND_404 });
      return Promise.resolve();
    }

    const navigationStore = useRootNavigationStore();

    const { setPersistLocation } = useSportlinePersistLocation();
    const navigationParameters = getSportlinePageNavigationParameters(to);
    setPersistLocation('live', navigationParameters);

    if (!liveStore.isReady) {
      navigationStore.setOnlyFirstRouteContentLoading(RouteName.SPORTLINE_LIVE_EVENTS);
      void liveStore.initialRequests().then(() => {
        liveStore.setSelectedFilter(sportFamily);
        navigationStore.setRouteContentLoaded(RouteName.SPORTLINE_LIVE_EVENTS);
      });
    } else {
      liveStore.setSelectedFilter(sportFamily);
      liveStore.syncBackgroundRequests();
      navigationStore.setRouteContentLoaded(RouteName.SPORTLINE_LIVE_EVENTS);
    }

    next();
    return Promise.resolve();
  }
}
