import type { Ref } from 'vue';
import { computed, toRef } from 'vue';

import { useNavigationBackButtonStore } from 'web/src/modules/core/store/useNavigationBackButtonStore';

interface UseHeaderBarBackButtonComposable {
  doShowBackButton: Ref<boolean>;
}

export function useHeaderBarBackButton(): UseHeaderBarBackButtonComposable {
  const navigationBackButtonStore = useNavigationBackButtonStore();
  const isBackButtonAvailable = toRef(navigationBackButtonStore, 'isBackButtonAvailable');

  const doShowBackButton = computed<boolean>(() => !!process.env.VUE_APP_LAYOUT_PHONE
    && isBackButtonAvailable.value);

  return {
    doShowBackButton,
  };
}
