// eslint-disable-next-line max-classes-per-file
import type { RecycleScrollerItemByDirection, ItemOptionsId } from '../../types';

class Pointer {
  private currentIndex = 0;

  private maxIndex = -1;

  private ids = new Map<ItemOptionsId, number>();

  constructor(
    private list: RecycleScrollerItemByDirection[],
    maxIndex?: number,
  ) {
    this.maxIndex = maxIndex ?? list.length - 1;

    for (const [index, item] of list.entries()) {
      if (index > this.maxIndex) { break; }
      if (!item.id) { continue; }
      this.ids.set(item.id, index);
    }
  }

  get done(): boolean {
    return this.currentIndex > this.maxIndex;
  }

  get index(): number {
    return this.currentIndex;
  }

  get value(): Optional<RecycleScrollerItemByDirection> {
    return this.list[this.currentIndex];
  }

  get next(): Optional<RecycleScrollerItemByDirection> {
    return this.list[this.currentIndex + 1];
  }

  move(value = 1): void {
    this.currentIndex += value;
  }

  getIdIndex(id: ItemOptionsId): Optional<number> {
    return this.ids.get(id);
  }

  slice(from: number, to: number): RecycleScrollerItemByDirection[] {
    return this.list.slice(from, to);
  }
}

class Result {
  value = 0;

  add(value: number): void {
    this.value += value;
  }
}

function calculateTotalSize(list: RecycleScrollerItemByDirection[]): number {
  return list.reduce((sum, item) => (sum + (item?.id ? (item?.size ?? 0) : 0)), 0);
}

function skipBrokenPointers(pointer1: Pointer, pointer2: Pointer): boolean {
  if (pointer1.value?.size && pointer2.value?.size) {
    return false;
  }

  pointer1.move(Number(!pointer1.value?.size));
  pointer2.move(Number(!pointer2.value?.size));

  return true;
}

function comparePointerIds(pointer1: Pointer, pointer2: Pointer, result: Result): boolean {
  const id1 = pointer1.value?.id;
  const id2 = pointer2.value?.id;

  if (!id1 || !id2) {
    return false;
  }

  if (id1 === id2) {
    pointer1.move();
    pointer2.move();
    return true;
  }

  const id1in2 = pointer2.getIdIndex(id1);

  if (id1in2 && id1in2 > pointer2.index) {
    result.add(-calculateTotalSize(pointer2.slice(pointer2.index, id1in2)));
    pointer2.move(id1in2 - pointer2.index);

    return true;
  }

  const id2in1 = pointer1.getIdIndex(id2);

  if (id2in1 && id2in1 > pointer1.index) {
    result.add(calculateTotalSize(pointer1.slice(pointer1.index, id2in1)));
    pointer1.move(id2in1 - pointer1.index);

    return true;
  }

  // move them anyway
  pointer1.move();
  pointer2.move();

  return true;
}

export function findListByDimensionDiff(
  list1?: RecycleScrollerItemByDirection[],
  list2?: RecycleScrollerItemByDirection[],
  maxIndex1?: number,
  maxIndex2?: number,
): number {
  if (!list1 || !list2) { return 0; }

  const result = new Result();
  const pointer1 = new Pointer(list1, maxIndex2 ?? maxIndex1);
  const pointer2 = new Pointer(list2, maxIndex1);

  while (!pointer1.done && !pointer2.done) {
    if (skipBrokenPointers(pointer1, pointer2)) { continue; }
    if (comparePointerIds(pointer1, pointer2, result)) { continue; }
    if (!pointer1.value?.id) { pointer1.move(); }
    if (!pointer2.value?.id) { pointer2.move(); }
  }

  return result.value;
}
