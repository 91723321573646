import type { RouteRecordName } from 'vue-router';

import { RouteName } from '@leon-hub/routing-config-names';

export default function getIsPromoMainPage(
  currentRouteName: RouteRecordName | null | undefined,
  categoryId: string | string[],
): boolean {
  return currentRouteName === RouteName.PROMOTIONS && !categoryId;
}
