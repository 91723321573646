import type { Ref } from 'vue';

import type { MarketsGridFilterId } from 'web/src/modules/sportline/submodules/markets-grid/types';
import type { MarketsListTabsReference } from 'web/src/modules/sportline/views/markets-list/types';

interface MarketsListToolbarProps {
  isStatisticSelected?: Ref<Optional<boolean>>;
  tabsRef: Ref<Maybe<MarketsListTabsReference>>;
}

interface MarketsListToolbarEmits {
  (e: 'market-tab-click', payload: MarketsGridFilterId): void;
  (e: 'change-statistic-selected', payload: boolean): void;
}

interface MarketsListToolbarComposable {
  slideToActiveTab(): void;
  onMarketTabClick(value: MarketsGridFilterId): void;
  onChangeStatisticSelected(value?: boolean): void;
}

export default function useMarketsListToolbar(
  props: MarketsListToolbarProps,
  emit: MarketsListToolbarEmits,
): MarketsListToolbarComposable {
  function slideToActiveTab(): void {
    props.tabsRef.value?.slideToActive();
  }

  function onMarketTabClick(value: MarketsGridFilterId): void {
    emit('market-tab-click', value);
  }

  function onChangeStatisticSelected(value?: boolean): void {
    emit('change-statistic-selected', value ?? !props.isStatisticSelected?.value);
  }

  return {
    slideToActiveTab,
    onMarketTabClick,
    onChangeStatisticSelected,
  };
}
