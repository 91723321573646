export function isWindowLocationHistoryAvailable(): boolean {
  return typeof window !== 'undefined' && window.history && typeof document !== 'undefined';
}

/** function to change hash without trigger vue router handlers */
export function setHashWithoutRouter(hash = ''): void {
  if (!isWindowLocationHistoryAvailable()) { return; }

  const clearedUrl = document.location.href.split('#')[0];
  const desiredHash = hash ? `#${hash}` : '';
  window.history.pushState('', '', `${clearedUrl}${desiredHash}`);
}

export function getHash(): Optional<string> {
  return document.location.href.split('#')[1];
}
