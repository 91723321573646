import { toRef } from 'vue';
import type { RouteLocationNormalized, NavigationGuardNext } from 'vue-router';

import { RouteName } from '@leon-hub/routing-config-names';

import { useSportlineCybersportStore } from 'web/src/modules/sportline/submodules/cybersport/store';
import { useRootNavigationStore } from 'web/src/modules/core/store';
import { useSportlinePersistLocation } from 'web/src/modules/sportline/submodules/persist-filters';
import type { AppVueRouter } from 'web/src/modules/core/services/router/types';
import { getSportlinePageNavigationParameters } from 'web/src/modules/sportline/utils/navigation';
import { AbstractPrefetch } from 'web/src/modules/core/services/router/prefetch';

export default class CybersportPagePrefetch extends AbstractPrefetch {
  // eslint-disable-next-line class-methods-use-this
  prefetch(
    router: AppVueRouter,
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ): Promise<void> {
    const cybersportStore = useSportlineCybersportStore();
    const isPageEnabled = toRef(cybersportStore, 'isPageEnabled');
    const region = (to.params?.region as string | undefined)?.toLowerCase();

    if (!isPageEnabled.value) {
      next({ name: RouteName.ERROR_NOT_FOUND_404 });
      return Promise.resolve();
    }

    const navigationStore = useRootNavigationStore();

    const { setPersistLocation } = useSportlinePersistLocation();
    const navigationParameters = getSportlinePageNavigationParameters(to);
    setPersistLocation('cybersport', navigationParameters);

    if (!cybersportStore.isReady) {
      navigationStore.setOnlyFirstRouteContentLoading(RouteName.SPORTLINE_CYBERSPORT);
      void cybersportStore.initialRequests().then(() => {
        cybersportStore.setSelectedFilter(region);
        navigationStore.setRouteContentLoaded(RouteName.SPORTLINE_CYBERSPORT);
      });
    } else {
      cybersportStore.setSelectedFilter(region);
      cybersportStore.syncBackgroundRequests();
      navigationStore.setRouteContentLoaded(RouteName.SPORTLINE_CYBERSPORT);
    }

    next();
    return Promise.resolve();
  }
}
