import type { ComputedRef } from 'vue';
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import type { CustomerHistoryFilterOption } from '@leon-hub/api-sdk';
import { CustomerHistoryFilterType, HistoryFilter } from '@leon-hub/api-sdk';

import type { NativeSelectProps, VSelectOption } from 'web/src/components/Select';
import type { VInputInputEvent } from 'web/src/components/Input';
import { useI18n } from 'web/src/modules/i18n/composables';

import type { CustomerHistoryFilterSelectEmits, CustomerHistoryFilterSelectProps } from '../types';

export interface CustomerHistoryFilterSelectComposable {
  filterSelectOptions: ComputedRef<VSelectOption[]>;
  selectProperties: ComputedRef<NativeSelectProps>;
  onFilterChange: (event: VInputInputEvent) => void;
}

// eslint-disable-next-line max-len
export default function useCustomerHistoryFilterSelect(props: CustomerHistoryFilterSelectProps, emit: CustomerHistoryFilterSelectEmits): CustomerHistoryFilterSelectComposable {
  const { $translate } = useI18n();
  const route = useRoute();
  const router = useRouter();

  const SELECT_NAME = 'dateSelect';

  // filter corner case
  const pendingBetOption = computed<CustomerHistoryFilterOption>(() => ({
    dateRange: 365,
    dateType: CustomerHistoryFilterType.DAY,
    isDefault: false,
    order: 0.1,
    title: $translate('WEB2_PENDING_BETS').value,
  }));

  const filterOptions = computed<CustomerHistoryFilterOption[]>(() => {
    let options: CustomerHistoryFilterOption[] = [...props.historyFilterOptions];
    if (props.selectedHistoryFilter === HistoryFilter.ALL_BETS) {
      options = [pendingBetOption.value, ...options];
    }
    return options;
  });

  const filterSelectOptions = computed<VSelectOption[]>(() => filterOptions.value
    .sort((first, second) => first.order - second.order)
    .map((option) => ({ value: `${option.order}`, label: option.title })));

  const selectProperties = computed<NativeSelectProps>(() => {
    const activeOrderFilter = process.env.VUE_APP_LAYOUT_DESKTOP
      ? router.getQuery('activeOrderFilter')
      : route.query.activeOrderFilter;

    return {
      name: SELECT_NAME,
      options: filterSelectOptions.value,
      selectedValue: `${activeOrderFilter}`,
    };
  });

  function onFilterChange(event: VInputInputEvent): void {
    const option = filterOptions.value
      .find((value) => value.order === Number(event.target.value));
    emit('filter-change', option);
  }

  return {
    filterSelectOptions,
    selectProperties,
    onFilterChange,
  };
}
