import { VirtualListRendererElementSize } from 'web/src/modules/sportline/views/virtual-list-renderer/constants';

export function getRecycleScrollerCssVars(): Dictionary<string> {
  return {
    '--list-events-gap': `${VirtualListRendererElementSize.EventsGap}px`,
    '--list-leagues-gap': `${VirtualListRendererElementSize.LeaguesGap}px`,
    '--list-regions-gap': `${VirtualListRendererElementSize.RegionsGap}px`,
    '--list-sports-gap': `${VirtualListRendererElementSize.SportsGap}px`,
    '--list-no-events-height': `${VirtualListRendererElementSize.NoEventsHeight}px`,
    '--list-event-height': `${VirtualListRendererElementSize.EventHeight}px`,
    '--list-league-headline-height': `${VirtualListRendererElementSize.LeagueHeadlineHeight}px`,
    '--list-league-headline-gap': `${VirtualListRendererElementSize.LeagueHeadlineGap}px`,
    '--list-sport-headline-height': `${VirtualListRendererElementSize.SportHeadlineHeight}px`,
    '--list-sport-headline-gap': `${VirtualListRendererElementSize.SportHeadlineGap}px`,
  };
}
