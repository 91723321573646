import type { Ref } from 'vue';
import { computed, shallowRef, toRef } from 'vue';

import { useModuleTimeout } from 'web/src/modules/core/store/composables';
import { useExpectedIdLoading } from 'web/src/modules/sportline/composables/navigation';
import { useSportlineApiService } from 'web/src/modules/sportline/services';
import { useGetSportlineEntityFactory } from 'web/src/modules/sportline/composables/factories';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import { useSyncSportline } from 'web/src/modules/sportline/submodules/sync-sportline';
import useBackgroundRequestsLifeCycle from 'web/src/utils/store/composables/useBackgroundRequestsLifeCycle';
import type { LeagueElement, RegionElement, SportElement } from 'web/src/modules/sportline/types';
import type {
  GetLeagueResponse,
  CoreSportlineFetchOptions,
  BetlineGetLeagueDataResponse,
  BetlineLeagueStatusResponse,
} from 'web/src/modules/sportline/types/rest';
import type {
  BetlineLeagueData,
  BetlineLeagueRepresentationData,
} from 'web/src/modules/sportline/submodules/core-statistic/types';
import {
  getBetlineLeagueData,
  getBetlineLeagueStatisticStatus,
} from 'web/src/modules/sportline/utils/api';
import {
  parseBetlineLeagueDataResponse,
  parseBetlineLeagueRepresentationDataResponse,
} from 'web/src/modules/sportline/submodules/core-statistic/utils';
import { isStatisticNotFoundError } from 'web/src/modules/sportline/utils/statistic';
import { getSportFamilyBackground } from 'web/src/modules/sportline/utils';
import { BackgroundUpdateStopwatch } from 'web/src/utils/store';

interface UseLeagueHeaderDataStoreComposableProps {
  expectedId: Ref<Maybe<number>>;
}

interface UseLeagueHeaderDataStoreComposable {
  leagueData: Ref<Maybe<BetlineLeagueData>>;
  leagueRepresentationData: Ref<Maybe<BetlineLeagueRepresentationData>>;
  isReady: Ref<boolean>;
  isEmpty: Ref<boolean>;
  isLoading: Ref<boolean>;
  canLoadStatistics: Ref<boolean>;
  isBackgroundUpdateEnabled: Ref<boolean>;
  lastLoadedId: Ref<Maybe<number>>;
  sportElement: Ref<Maybe<SportElement>>;
  regionElement: Ref<Maybe<RegionElement>>;
  leagueElement: Ref<Maybe<LeagueElement>>;
  onInit(): void;
  initialRequests(): Promise<void>;
  syncBackgroundRequests(options: { force?: boolean; silent?: boolean }): Promise<void>;
  setBackgroundUpdateEnabled(value: boolean): Promise<void>;
  fetchLeagueData(options: CoreSportlineFetchOptions): Promise<void>;
}

export function useLeagueHeaderDataStoreComposable(
  props: UseLeagueHeaderDataStoreComposableProps,
): UseLeagueHeaderDataStoreComposable {
  const { expectedId } = props;

  const apiService = useSportlineApiService();
  const sportlineSettingsStore = useSportlineSettingsStore();

  const isLeagueHeaderDataRequestEnabled = toRef(sportlineSettingsStore, 'isLeagueHeaderDataRequestEnabled');
  const isLeagueStatisticEnabled = toRef(sportlineSettingsStore, 'isLeagueStatisticEnabled');
  const {
    timeout: updateInterval,
  } = useModuleTimeout('sportline-league-data', toRef(sportlineSettingsStore, 'sportLinePrematchUpdateInterval'));

  const rawLeagueResponse = shallowRef<Maybe<GetLeagueResponse> | false>(null);
  const leagueData = shallowRef<Maybe<BetlineLeagueData>>(null);
  const rawLeagueRepresentationData = shallowRef<Maybe<BetlineLeagueRepresentationData>>(null);
  const leagueStatisticBlockStatus = shallowRef<string[]>([]);

  const {
    isLoading,
    lastLoadedId,
    isExpectedIdLoaded,
    setIsLoading,
    setLastLoadedId,
  } = useExpectedIdLoading({ expectedId });
  const {
    sportElement,
    regionElement,
    leagueElement,
  } = useGetSportlineEntityFactory({ rawLeagueResponse });

  const canLoadStatistics = computed(() => (
    leagueStatisticBlockStatus.value.length > 0 && isExpectedIdLoaded.value
  ));
  const isReady = computed<boolean>(() => isExpectedIdLoaded.value);
  const isEmpty = computed(() => !leagueData.value && !leagueElement.value);

  const leagueRepresentationData = computed<Maybe<BetlineLeagueRepresentationData>>(() => {
    if (rawLeagueRepresentationData.value) { return rawLeagueRepresentationData.value; }

    const sport = sportElement.value?.sport;
    const league = leagueElement.value?.league;

    if (!sport || !league) { return null; }

    return {
      icon: sport.representation.icon,
      logoUrl: league.representation.logo ?? null,
      backgroundImage: league.representation.background ?? null,
      background: getSportFamilyBackground(sport.family),
    };
  });

  function clear(): void {
    leagueData.value = null;
    rawLeagueRepresentationData.value = null;
    leagueStatisticBlockStatus.value = [];
  }

  function fetchLeague({ leagueId, silent }: {
    leagueId: number;
    silent?: boolean;
  }): Promise<Maybe<GetLeagueResponse>> {
    return apiService.loadLeague({ id: String(leagueId), silent });
  }

  function fetchBetlineLeagueData({ leagueId, silent }: {
    leagueId: number;
    silent?: boolean;
  }): Promise<Maybe<BetlineGetLeagueDataResponse>> {
    return isLeagueHeaderDataRequestEnabled.value
      ? getBetlineLeagueData({ leagueId }, { silent })
      : Promise.resolve(null);
  }

  function fetchBetlineLeagueStatisticStatus({ leagueId, silent }: {
    leagueId: number;
    silent?: boolean;
  }): Promise<Maybe<BetlineLeagueStatusResponse>> {
    return isLeagueStatisticEnabled.value
      ? getBetlineLeagueStatisticStatus({ leagueId }, { silent })
      : Promise.resolve(null);
  }

  async function fetchLeagueData({ silent }: CoreSportlineFetchOptions): Promise<void> {
    const leagueId = expectedId.value;

    if (!leagueId) { return; }

    setIsLoading(true);

    try {
      const [
        leagueResponse, leagueDataResponse, leagueStatisticStatusResponse,
      ] = await Promise.all([
        fetchLeague({ leagueId, silent }),
        fetchBetlineLeagueData({ leagueId, silent }),
        fetchBetlineLeagueStatisticStatus({ leagueId, silent }),
      ]);

      rawLeagueResponse.value = leagueResponse;
      leagueStatisticBlockStatus.value = leagueStatisticStatusResponse?.enabledEntities ?? [];
      leagueData.value = parseBetlineLeagueDataResponse(leagueDataResponse);
      rawLeagueRepresentationData.value = parseBetlineLeagueRepresentationDataResponse(leagueDataResponse);
    } catch (error: unknown) {
      clear();
      rawLeagueResponse.value = false;
      if (!isStatisticNotFoundError(error)) { throw error; }
    } finally {
      setIsLoading(false);
      setLastLoadedId(leagueId);
    }
  }

  const lastUpdate = new BackgroundUpdateStopwatch<['leagueData']>({
    leagueData: fetchLeagueData,
  });
  const {
    initialRequests,
    syncState,
    syncBackgroundRequests,
    setBackgroundUpdateEnabled,
    isBackgroundUpdateEnabled,
  } = useBackgroundRequestsLifeCycle({
    lastUpdate,
    updateInterval,
  });

  function onInit(): void {
    useSyncSportline(async (silent: boolean): Promise<void> => {
      await syncState({ silent });
    }, updateInterval);
  }

  return {
    leagueData,
    leagueRepresentationData,
    canLoadStatistics,
    isReady,
    isEmpty,
    isLoading,
    isBackgroundUpdateEnabled,
    lastLoadedId,
    sportElement,
    regionElement,
    leagueElement,
    onInit,
    initialRequests,
    syncBackgroundRequests,
    setBackgroundUpdateEnabled,
    fetchLeagueData,
  };
}
