import type { ComputedRef } from 'vue';
import { computed, toRef } from 'vue';

import type { ILobbyType } from '@leon-hub/api-sdk';
import { lobbyTypeLoyaltyProgram } from '@leon-hub/api-sdk';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika/src/constants/index';

import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import type { LobbySkeletonItem } from 'web/src/modules/lobby/types';

interface CasinoLoyaltyLobbyComposable {
  lobbyType: ILobbyType;
  skeletons: ComputedRef<LobbySkeletonItem[]>;
  gameClick: () => void;
}

export default function useCasinoLoyaltyLobby(): CasinoLoyaltyLobbyComposable {
  const lobbyType = lobbyTypeLoyaltyProgram;

  const siteConfigStore = useSiteConfigStore();
  const analytics = useAnalytics();
  const egsSkeletons = toRef(siteConfigStore, 'egsSkeletons');

  const skeletons = computed<LobbySkeletonItem[]>(() => egsSkeletons.value?.loyalty ?? []);

  function gameClick(): void {
    analytics.push(AnalyticsEvent.CLICK_MAP, { clickCounter: { loyaltyProgram: 'openGame' } });
  }

  return {
    lobbyType,
    skeletons,
    gameClick,
  };
}
