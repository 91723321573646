import type { Ref } from 'vue';
import { computed } from 'vue';

import type { SportlineEvent } from 'web/src/modules/sportline/types';
import { SportlineType } from 'web/src/modules/sportline/enums';

import { HeadlineWideLayoutName, HeadlineWideLayoutType } from '../enums';

const HeadlineSportlineType = new Map<SportlineType, HeadlineWideLayoutType>();
HeadlineSportlineType.set(SportlineType.Live, HeadlineWideLayoutType.Live);
HeadlineSportlineType.set(SportlineType.Prematch, HeadlineWideLayoutType.PreMatch);

interface UseHeadlineWideLayoutProps {
  // unreactive prop
  autoLayoutAllowed?: boolean;
  sportlineEvent: Ref<SportlineEvent>;
  hasPrimaryMarket: Ref<boolean>;
  hasInplayComponent: Ref<boolean>;
}

interface UseHeadlineWideLayoutComposable {
  backgroundLineType: Ref<HeadlineWideLayoutType>;
  headlineLayoutName: Ref<Optional<HeadlineWideLayoutName>>;
}

export function useHeadlineWideLayout(
  props: UseHeadlineWideLayoutProps,
): UseHeadlineWideLayoutComposable {
  const {
    sportlineEvent,
    autoLayoutAllowed,
    hasPrimaryMarket,
    hasInplayComponent,
  } = props;

  const hasZeroMargin = computed<boolean>(() => (
    // LEONWEB-7696 do show ZeroMargin indicator only for PreMatch
    sportlineEvent.value.type === SportlineType.Prematch
    && !sportlineEvent.value.isOutright
    && sportlineEvent.value.hasZeroMargin
  ));
  const hasLargeContent = computed<boolean>(() => (
    hasPrimaryMarket.value || hasInplayComponent.value
  ));

  const backgroundLineType = computed<HeadlineWideLayoutType>(() => {
    if (sportlineEvent.value.isOutright) { return HeadlineWideLayoutType.Outright; }
    const { type } = sportlineEvent.value;
    return HeadlineSportlineType.get(type) ?? HeadlineWideLayoutType.Default;
  });

  const headlineLayoutName = computed<Optional<HeadlineWideLayoutName>>(() => {
    switch (true) {
      case (autoLayoutAllowed && hasInplayComponent.value):
        return HeadlineWideLayoutName.Auto;
      case (hasLargeContent.value && hasZeroMargin.value):
        return HeadlineWideLayoutName.Large;
      case (hasLargeContent.value && !hasZeroMargin.value):
        return HeadlineWideLayoutName.Default;
      case (!hasInplayComponent.value && !hasPrimaryMarket.value):
        return HeadlineWideLayoutName.Low;
      default:
        return undefined;
    }
  });

  return {
    backgroundLineType,
    headlineLayoutName,
  };
}
