import type {
  SportradarLMTDetailsHeadlineWidget,
  SportEventDetailsHeadlineWidget, LSportsDetailsHeadlineWidget, BetgeniusDetailsHeadlineWidget,
} from 'web/src/modules/sportline/submodules/event-details/types';
import { SportWidgetType } from 'web/src/modules/sportline/enums';

export function isSportradarLMTDetailsHeadlineWidget(
  value: SportEventDetailsHeadlineWidget,
): value is SportradarLMTDetailsHeadlineWidget {
  return value.type === SportWidgetType.SPORTRADAR;
}

export function isLSportsDetailsHeadlineWidget(
  value: SportEventDetailsHeadlineWidget,
): value is LSportsDetailsHeadlineWidget {
  return value.type === SportWidgetType.LSPORTS;
}

export function isBetgeniusDetailsHeadlineWidget(
  value: SportEventDetailsHeadlineWidget,
): value is BetgeniusDetailsHeadlineWidget {
  return value.type === SportWidgetType.BET_GENIUS;
}
