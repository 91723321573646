import searchSelectOptions from 'web/src/utils/search/searchSelectOptions';

import type { DropListMenuOptionGroup } from '../types';

export function searchInGroups(input: DropListMenuOptionGroup[], searchQuery: string): DropListMenuOptionGroup[] {
  const result: DropListMenuOptionGroup[] = [];
  for (const group of input) {
    const { options } = group;
    const matchedItems = searchSelectOptions(options, searchQuery);
    if (matchedItems.length) {
      result.push({
        ...group,
        options: matchedItems,
      });
    }
  }
  return result;
}
