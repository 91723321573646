export const enum StatisticColoredLabelType {
  TRANSPARENT = 'transparent',
  DEFAULT = 'default',
  HOME = 'home',
  AWAY = 'away',
}

export const enum StatisticColoredLabelSize {
  DEFAULT = 'default',
  MEDIUM = 'medium',
}
