<script lang="ts" setup>
import { IconName, IconSize } from '@leon-hub/icons';

import { VIcon } from '@components/v-icon';
import type { VIconProps } from '@components/v-icon';

import CasinoProviderSelect from 'web/src/modules/casino/submodules/lobby/components/CasinoProviderSelect/CasinoProviderSelect.vue';

import type { CasinoSidebarFilterBlockEmits, CasinoSidebarFilterBlockProps } from './types';

withDefaults(defineProps<CasinoSidebarFilterBlockProps>(), {});
const emit = defineEmits<CasinoSidebarFilterBlockEmits>();
function clickGroups(event: MouseEvent): void {
  emit('click-groups', event);
}

function clickSearch(event: MouseEvent): void {
  emit('click-search', event);
}

const iconSearchProperty: VIconProps = {
  name: IconName.SEARCH,
  size: IconSize.SIZE_20,
};
</script>

<template>
  <div v-auto-id="'CasinoSidebarFilterBlock'" :class="$style['casino-sidebar-filter-block']">
    <div
      v-data-test="{ el: 'casino-sidebar-search' }"
      :class="$style['casino-sidebar-filter-block__search']"
      @click="clickSearch"
    >
      <VIcon v-bind="iconSearchProperty" />
      <span>{{ $t('WEB2_CASINO_SIDEBAR_SEARCH') }}</span>
    </div>
    <CasinoProviderSelect
      :title="activeGroupName"
      :has-selected-group="hasGroupName"
      in-sidebar
      :class="$style['casino-sidebar-filter-block__provider']"
      @click="clickGroups"
    />
  </div>
</template>

<style lang="scss" module>
.casino-sidebar-filter-block {
  padding: $casinoSidebarFilterBlockPadding;
  background-color: $casinoSidebarFilterBackgroundColor;
  border-radius: var(--BorderRadius);

  &__search {
    @include medium\14\16\025;

    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 44px;
    padding: 0 16px;
    margin-bottom: $casinoSidebarFilterSearchMarginBottom;
    color: var(--TextPrimary);
    cursor: pointer;
    background-color: $casinoSidebarFilterSearchBackgroundColor;
    border-radius: $casinoSidebarFilterSearchBackgroundRadius;

    &:hover {
      color: $casinoSidebarFilterSearchHoverColor;
      background-color: var(--Highlight);
    }
  }

  &__provider {
    height: 44px;
    padding: $casinoSidebarFilterProviderPadding;
    border-radius: $casinoSidebarFilterProviderRadius;
  }
}
</style>
