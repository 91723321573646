import { defineStore } from 'pinia';
import {
  computed,
  ref,
  watch,
} from 'vue';

import {
  usePaymentsConfig,
} from 'web/src/modules/core/composables/site-config';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { useCurrency } from 'web/src/modules/money/composables';

const useCustomerFinanceStore = defineStore('customer-finance', () => {
  const { isLoggedIn } = useIsLoggedIn();
  const { currency } = useCurrency();

  const { balanceButtonCurrencies, lowBalanceAmounts } = usePaymentsConfig();

  let lastModifiedAt = 0;

  const balance = ref(0);

  const formattedBalance = computed(() => balance.value.toFixed(2));
  const isAddBalanceButtonAsCurrency = computed(() => balanceButtonCurrencies.value.includes(currency.value));
  const isLowBalance = computed(() => {
    const amount = lowBalanceAmounts.value.find((item) => currency.value === item.currency);
    return !!amount && amount.value > balance.value;
  });

  function setBalance(value: number, lastModifiedValue: number): void {
    if (lastModifiedValue > lastModifiedAt) {
      balance.value = value;
      lastModifiedAt = lastModifiedValue;
    }
  }

  function init(): void {
    watch(isLoggedIn, (newValue) => {
      if (!newValue) {
        lastModifiedAt = 0;
      }
    });
  }

  init();

  return {
    balance,
    formattedBalance,
    isAddBalanceButtonAsCurrency,
    isLowBalance,
    setBalance,
  };
});

export default useCustomerFinanceStore;
