<script setup lang="ts">
import { onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { RouteName } from '@leon-hub/routing-config-names';
import { isString } from '@leon-hub/guards';
import { HomePageType } from '@leon-hub/api-sdk';

import { useSiteConfigStore } from 'web/src/modules/core/store';

import { useGetSharedSlip } from '../submodules/get-shared-slip/composables';

const {
  isReceiveFromCodeEnabled,
  getSlipFromBookingCode,
  setSharedBookingCode,
} = useGetSharedSlip();

const route = useRoute();
const router = useRouter();
const { homePageType } = useSiteConfigStore();

onMounted(() => {
  const { code } = route.params;
  const { query } = route;
  if (isString(code) && isReceiveFromCodeEnabled.value) {
    setSharedBookingCode(code);
    void getSlipFromBookingCode(true);
  }

  const sportHome = homePageType === HomePageType.SPORTS
    ? RouteName.HOME
    : RouteName.SPORTLINE_TOP_EVENTS;
  void router.replace({ name: sportHome, query });
});
</script>

<template>
  <div v-auto-id="'GetSharedSlipByLinkRoutePage'" />
</template>
