<script lang="ts" setup>
import { useTemplateRef } from 'vue';

import { useIsLoggedIn } from 'web/src/modules/auth/composables';

import type { FooterLicenseProps } from './types';
import { useFooterLicense } from './composables';

const props = withDefaults(defineProps<FooterLicenseProps>(), {});

const wrapper = useTemplateRef('wrapper');
const {
  doShowLogos,
  doShowGenericLogos,
  isKwkLogoEnabled,
  isCrcLogoEnabled,
  isAnjouanLBZLogoEnabled,
  isAnjouanTBZLogoEnabled,
  kwkUrl,
  crcUrl,
  logos,
  getLogo,
  dynamicSize,
} = useFooterLicense(props, wrapper);

const { isLoggedIn } = useIsLoggedIn();

const kwkImage = '1'
  ? require('web/src/assets/logotypes/common/kahnawake.svg')
  : '';

const crcDisabledImage = '1'
  ? require('web/src/assets/logotypes/common/curacao-disabled.png')
  : '';
</script>

<template>
  <section v-auto-id="'FooterLicense'"
    v-if="doShowLogos"
    ref="wrapper"
    :class="{
      [$style['footer-license']]: true,
      [$style['footer-license--landing']]: isLanding,
      [$style['footer-license--panel']]: isPanel,
      [$style['footer-license--grayscale']]: isGrayscale,
    }"
  >
    <template v-if="doShowGenericLogos">
      <template
        v-for="(logo, index) in logos"
        :key="index"
      >
        <a
          v-if="logo.href"
          :href="logo.href"
          :class="{
            [$style['footer-license__item']]: true,
            [$style['footer-license__item--colored']]: logo.isDefaultColor,
          }"
        >
          <img
            :class="{
              [$style['footer-license__item']]: true,
              [$style['footer-license__item--colored']]: logo.isDefaultColor,
            }"
            :src="getLogo(logo)"
            :alt="logo.alt"
            loading="lazy"
            :width="dynamicSize(logo.width)"
            :height="dynamicSize(logo.height)"
          >
        </a>
        <img
          v-else
          :class="{
            [$style['footer-license__item']]: true,
            [$style['footer-license__item--colored']]: logo.isDefaultColor,
          }"
          :src="getLogo(logo)"
          :alt="logo.alt"
          loading="lazy"
          :width="dynamicSize(logo.width)"
          :height="dynamicSize(logo.height)"
        >
      </template>
    </template>
    <template v-if="'1' && isCrcLogoEnabled">
      <div
        v-if="crcUrl"
        v-show="!isLoggedIn"
        id="apg-seal-container"
        :class="{
          [$style['footer-license__item']]: true,
          [$style['footer-license__item--link']]: true,
          [$style['footer-license__item--crc']]: true,
        }"
        data-apg-seal-id="0dd241d4-88f7-4a0b-8325-0c40ac10fc44"
        data-apg-image-size="60"
        data-apg-image-type="basic-small"
      />
      <img
        v-else
        v-show="!isLoggedIn"
        :class="$style['footer-license__item']"
        :src="crcDisabledImage"
        alt="Curacao"
        loading="lazy"
        width="32"
        height="32"
      >
    </template>
    <template
      v-if="'1' && isKwkLogoEnabled"
    >
      <a
        v-if="kwkUrl"
        :class="{
          [$style['footer-license__item']]: true,
          [$style['footer-license__item--link']]: true,
        }"
        :href="kwkUrl"
        target="_blank"
      >
        <img
          :src="kwkImage"
          alt="Kahnawake"
          loading="lazy"
          width="106"
          height="26"
          :class="$style['footer-license__item--filter']"
        >
      </a>
      <img
        v-else
        :class="{
          [$style['footer-license__item']]: true,
          [$style['footer-license__item--filter']]: true,
        }"
        :src="kwkImage"
        alt="Kahnawake"
        loading="lazy"
        width="auto"
        height="26"
      >
    </template>
    <template v-if="'1' && isAnjouanLBZLogoEnabled">
      <div
        v-show="!isLoggedIn"
        id="anj-ffa489c6-d6eb-41e7-9e0f-ab25ac2dd6f5"
        :class="{
          [$style['footer-license__item']]: true,
          [$style['footer-license__item--link']]: true,
          [$style['footer-license__item--crc']]: true,
        }"
        data-anj-seal-id="ffa489c6-d6eb-41e7-9e0f-ab25ac2dd6f5"
        data-anj-image-size="128"
        data-anj-image-type="basic-small"
      />
    </template>
    <template v-if="'1' && isAnjouanTBZLogoEnabled">
      <div
        v-show="!isLoggedIn"
        id="anj-7ad78d18-350b-4099-bf1e-6ba20df24adf"
        :class="{
          [$style['footer-license__item']]: true,
          [$style['footer-license__item--link']]: true,
          [$style['footer-license__item--crc']]: true,
        }"
        data-anj-seal-id="7ad78d18-350b-4099-bf1e-6ba20df24adf"
        data-anj-image-size="128"
        data-anj-image-type="basic-small"
      />
    </template>
  </section>
</template>

<style lang="scss" module>
.footer-license {
  $self: &;

  display: flex;
  flex-wrap: wrap;
  gap: 8px 16px;
  align-items: center;
  justify-content: center;

  &__item {
    margin: 0;

    &--link {
      cursor: pointer;
    }

    &--filter {
      filter: var(--FooterIconFilter);
    }

    &--crc {
      width: 32px;
      height: 34px;
      overflow: hidden;
    }
  }

  &--landing {
    @include is-app-layout-desktop {
      gap: 8px 46px;
      padding: 32px 0;
    }
  }

  &--panel {
    padding: 16px;
    background-color: var(--OpacityLayer1);
    border-radius: var(--BorderRadius);

    @include is-app-layout-desktop {
      padding: 24px 16px;
    }
  }

  &--grayscale {
    #{$self}__item {
      mix-blend-mode: luminosity;

      &--colored {
        mix-blend-mode: normal;
      }
    }
  }
}
</style>
