import type {
  SportEventsSegmentSelectedFilter,
} from 'web/src/modules/sportline/submodules/segment/types';
import { CustomFilter } from 'web/src/modules/sportline/enums';

/**
 * Choose actual filter value for input
 * used to select actual filter by uri param value
 */
export function chooseFilterValue(
  filter: SportEventsSegmentSelectedFilter,
): SportEventsSegmentSelectedFilter {
  switch (filter.toLowerCase()) {
    case CustomFilter.AllEvents.toLowerCase():
      return CustomFilter.AllEvents;

    case CustomFilter.Favorites.toLowerCase():
      return CustomFilter.Favorites;

    case CustomFilter.Stream.toLowerCase():
      return CustomFilter.Stream;

    default:
      return filter;
  }
}
