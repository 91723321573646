<script lang="ts" setup>
import { IconName, IconSize } from '@leon-hub/icons';
import { ProgramParticipationStatus } from '@leon-hub/api-sdk';
import { RouteName } from '@leon-hub/routing-config-names';
import { ButtonKind } from '@leon-hub/module-buttons';

import { VIcon } from '@components/v-icon';

import ReferralProgramAddButton
  from 'web/src/modules/referral-program/components/ReferralProgramAddButton/ReferralProgramAddButton.vue';
import ReferralProgramAvatar
  from 'web/src/modules/referral-program/components/ReferralProgramAvatar/ReferralProgramAvatar.vue';
import ReferralProgramMemberList
  from 'web/src/modules/referral-program/components/ReferralProgramMemberList/ReferralProgramMemberList.vue';
import VSwiper from 'web/src/components/Swiper/VSwiper/VSwiper';
import type { ExtendedReferral } from 'web/src/modules/referral-program/types/ReferralProgramDocuments';
import { ReferralProgramViewMode } from 'web/src/modules/referral-program/types/ReferralProgramDocuments';
import ReferralProgramStatus
  from 'web/src/modules/referral-program/components/ReferralProgramStatus/ReferralProgramStatus.vue';
import VJumbotron from 'web/src/components/Jumbotron/VJumbotron/VJumbotron.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import VReferralProgramStep from 'web/src/modules/referral-program/components/ReferralProgramStep/VReferralProgramStep/VReferralProgramStep.vue';
import VReferralProgramSelectBonus
  from 'web/src/modules/referral-program/components/ReferralProgramSelectBonus/VReferralProgramSelectBonus.vue';
import { VLoaderDelayed } from 'web/src/components/Loader';
import ReferralProgramPopupListSteps
  from 'web/src/modules/referral-program/components/ReferralProgramPopupListSteps/ReferralProgramPopupListSteps.vue';
import VReferralProgramBanner
  from 'web/src/modules/referral-program/components/ReferralProgramBanner/VReferralProgramBanner.vue';
import VSocialSharing from 'web/src/components/SocialSharing/VSocialSharing/VSocialSharing.vue';
import VReferralProgramBlock
  from 'web/src/modules/referral-program/components/ReferralProgramBlock/VReferralProgramBlock.vue';
import SReferralProgramBlock
  from 'web/src/modules/referral-program/components/ReferralProgramBlock/SReferralProgramBlock.vue';
import SSocialSharingCopyLink from 'web/src/components/SocialSharing/SocialSharingCopyLink/SSocialSharingCopyLink.vue';
import SReferralProgramSelectBonus
  from 'web/src/modules/referral-program/components/ReferralProgramSelectBonus/SReferralProgramSelectBonus.vue';
import SButton from 'web/src/components/Button/SButton/SButton.vue';
import SReferralProgramStep
  from 'web/src/modules/referral-program/components/ReferralProgramStep/SReferralProgramStep/SReferralProgramStep.vue';
import SReferralProgramBanner
  from 'web/src/modules/referral-program/components/ReferralProgramBanner/SReferralProgramBanner.vue';
import LButton from 'web/src/components/Button/LButton/LButton.vue';

import { useReferralProgramRoutePage } from './composables';

const {
  pendingReferralCount,
  referrals,
  viewMode,
  setViewMode,
  completedReferrals,
  pendingReferrals,
  availableReferrals,
  selectedReferral,
  selectedColorId,
  jumbotronProps,
  requirements,
  isConditionsSatisfied,
  bonuses,
  isContentLoading,
  isAccessGranted,
  playerRequirementsCompleted,
  playerRequirementsCurrent,
  isSelectedReferralCompleted,
  referralUrl,
  socialSharingNetworks,
  goToTerms,
  goToBonuses,
  handleShowShareModal,
  closeShareModal,
  isShareModal,
  clickOnReward,
  referralFiendsProps,
  referralBonusProps,
  handleBlockClick,
  isBonusSelected,
  selectedBonus,
  changeSelectedBonus,
} = useReferralProgramRoutePage();

const showMoreIcon = null ? IconName.SLOTT_ANGLE_SMALL_RIGHT : IconName.EXPAND_RIGHT;
const showMoreIconSize = IconSize.SIZE_16;

function onReferralClick(item: ExtendedReferral, colorId: number): void {
  selectedReferral.value = item;
  selectedColorId.value = colorId;
  setViewMode(ReferralProgramViewMode.DETAILS);
}
</script>

<template>
  <VLoaderDelayed v-if="isContentLoading" />
  <template v-else>
    <div
      v-show="viewMode === ReferralProgramViewMode.MAIN"
      :class="$style['referral-program']"
    >
      <template v-if="null">
        <SReferralProgramBanner
          v-if="isAccessGranted"
          :title="$t('WEB2_REFERRAL_PROGRAM_BANNER_SHARE_TITLE')"
          :label="$t('WEB2_REFERRAL_PROGRAM_BANNER_SHARE_LABEL')"
          :image="require('web/src/modules/referral-program/image/li/default/banner-invite.png')"
          type="granted"
          @click="goToTerms('banner')"
        />
        <SReferralProgramBanner
          v-else
          :title="$t('WEB2_REFERRAL_PROGRAM_BANNER_TITLE')"
          :label="$t('WEB2_REFERRAL_PROGRAM_BANNER_LABEL')"
          :image="require('web/src/modules/referral-program/image/li/default/banner-main.png')"
          type="not-granted"
          @click="goToTerms('banner')"
        />
      </template>
      <VReferralProgramBanner
        v-else
        @click="goToTerms('banner')"
      />
      <div
        v-if="null && isAccessGranted"
        :class="$style['referral-program__info']"
      >
        <router-link
          :to="RouteName.REFERRAL_PROGRAM_INFO"
          :class="$style['referral-program__info-button']"
        >
          <span>{{ $t('WEB2_REFERRAL_PROGRAM_HOW_IT_WORKS') }}</span>
          <VIcon
            :name="IconName.SLOTT_INTERROGATION_1"
          />
        </router-link>
      </div>
      <template v-if="isAccessGranted">
        <div :class="$style['referral-program__content']">
          <div
            v-if="null"
            v-data-test="{el: 'referral-program-title'}"
            :class="$style['referral-program__title']"
          >
            {{ $t('WEB2_REFERRAL_PROGRAM_DATA_TITLE') }}
          </div>

          <SSocialSharingCopyLink
            v-if="null"
            :label="$t('WEB2_REFERRAL_PROGRAM_REFERRAL_CODE_LABEL')"
            :link="referralUrl"
          />

          <div
            v-data-test="{el: 'referral-program-blocks'}"
            :class="$style['referral-program__blocks']"
          >
            <SReferralProgramBlock
              v-if="null"
              v-bind="referralFiendsProps"
              @click="handleBlockClick(ReferralProgramViewMode.LIST)"
            />
            <VReferralProgramBlock
              v-else
              v-bind="referralFiendsProps"
              @click="handleBlockClick(ReferralProgramViewMode.LIST)"
            />
            <SReferralProgramBlock
              v-if="null"
              v-bind="referralBonusProps"
              @click="handleBlockClick(ReferralProgramViewMode.LIST_BONUSES)"
            />
            <VReferralProgramBlock
              v-else
              v-bind="referralBonusProps"
              @click="handleBlockClick(ReferralProgramViewMode.LIST_BONUSES)"
            />
          </div>

          <div
            v-if="null && viewMode === ReferralProgramViewMode.MAIN"
            v-data-test="{el: 'referral-program-social-sharing'}"
            :class="$style['referral-program__social-sharing']"
          >
            <VButton
              :label="$t('WEB2_SHARE_LINK')"
              :icon-name="null ? IconName.SLOTT_USER_ADD : IconName.SHARE"
              full-width
              @click="handleShowShareModal(true)"
            />
            <VButton
              v-if="referrals.length > 0"
              :label="$t('WEB2_REFERRAL_PROGRAM_CLAIM')"
              :kind="ButtonKind.SECONDARY"
              full-width
              @click="handleBlockClick(ReferralProgramViewMode.LIST)"
            />
          </div>
        </div>
        <div
          v-if="!null && referrals.length > 0"
          v-data-test="{el: 'referral-program-header'}"
          :class="$style['referral-program__header']"
        >
          <div :class="$style['referral-program__header-awaiting']">
            <span>{{ $t('WEB2_REFERRAL_PROGRAM_WAITING') }}:</span>
            <span :class="$style['referral-program__header-awaiting-count']">{{ pendingReferralCount }}</span>
          </div>
          <div
            :class="$style['referral-program__header-show']"
            @click="setViewMode(ReferralProgramViewMode.LIST)"
          >
            <span>{{ $t('WEB2_REFFERAL_SHOW_ALL') }}</span>
            <VIcon
              :name="showMoreIcon"
              :size="showMoreIconSize"
            />
          </div>
        </div>
        <div :class="$style['referral-program__friends']">
          <div
            v-if="null"
            v-data-test="{el: 'referral-program-friends-header'}"
            :class="$style['referral-program__friends-header']"
          >
            <div :class="$style['referral-program__friends-left']">
              <div
                :class="$style['referral-program__friends-title']"
              >
                {{ $t('WEB2_REFERRAL_PROGRAM_FRIENDS_TITLE') }}
              </div>

              <div :class="$style['referral-program__friends-awaiting']">
                <span>{{ $t('WEB2_REFERRAL_PROGRAM_WAITING') }}:</span>
                <span :class="$style['referral-program__friends-awaiting-count']"> {{ pendingReferralCount }}</span>
              </div>
            </div>

            <SButton
              :label="$t('WEB2_REFERRAL_PROGRAM_BUTTON_ALL')"
              kind="quaternary-secondary"
              @click="setViewMode(ReferralProgramViewMode.LIST)"
            />
          </div>
          <VSwiper>
            <ReferralProgramAddButton
              :class="$style['referral-program__friends-item']"
              @click="handleShowShareModal"
            />
            <ReferralProgramAvatar
              v-for="(referral, index) in referrals"
              :key="referral.customerId"
              :color-id="(index % 10) + 1"
              :class="$style['referral-program__friends-item']"
              :status="referral.status || undefined"
              :initials="referral.isIncognito ? undefined : referral.initials"
              :amount="referral.amount ? referral.formattedAmount : undefined"
              :name="referral.name"
              @click.stop="onReferralClick(referral, (index % 10) + 1)"
            />
          </VSwiper>
        </div>
        <div
          v-if="!null"
          v-data-test="{el: 'referral-program-terms'}"
          :class="$style['referral-program__terms']"
          @click="goToTerms('rules')"
        >
          <span>{{ $t('REFERRAL_PROGRAM_INFO_TITLE') }}</span>
          <div>
            <VIcon
              :name="showMoreIcon"
              :size="showMoreIconSize"
            />
          </div>
        </div>
      </template>
      <template v-else>
        <div :class="$style['referral-program__get-access']">
          <p
            v-if="!null"
            v-data-test="{el: 'referral-program-get-access-text'}"
            :class="$style['referral-program__get-access-text']"
          >
            {{ $t('WEB2_REFERRAL_PROGRAM_NO_ACCESS_MESSAGE') }}:
          </p>
          <ReferralProgramPopupListSteps
            v-if="playerRequirementsCompleted.length"
            :title="$t('WEB2_REFFERAL_PROGRAM_POPUP_DONE')"
            satisfied
            green
            :list="playerRequirementsCompleted"
          />
          <ReferralProgramPopupListSteps
            v-if="playerRequirementsCurrent.length"
            :title="$t('WEB2_REFFERAL_PROGRAM_POPUP_CURRENT')"
            :list="playerRequirementsCurrent"
            green
          />
        </div>
      </template>
    </div>
    <div
      v-if="viewMode === ReferralProgramViewMode.LIST || viewMode === ReferralProgramViewMode.LIST_BONUSES"
      :class="$style['referral-program']"
    >
      <ReferralProgramMemberList
        :pending-referrals="pendingReferrals"
        :available-referrals="availableReferrals"
        :completed-referrals="completedReferrals"
        :show-bonuses-list="viewMode === ReferralProgramViewMode.LIST_BONUSES"
        @click="onReferralClick"
      />
    </div>
    <div
      v-if="viewMode === ReferralProgramViewMode.DETAILS"
      :class="$style['referral-program__details-wrapper']"
    >
      <div :class="$style['referral-program__details']">
        <div :class="$style['referral-program__details-content']">
          <div :class="$style['referral-program__details-name']">
            {{ selectedReferral?.name }}
          </div>
          <ReferralProgramStatus
            v-if="selectedReferral?.status"
            :status="selectedReferral.status"
            is-badge-status
            is-label
          />

          <div :class="$style['referral-program__details-date']">
            {{ null ? selectedReferral?.date : selectedReferral?.longDate }}
          </div>
        </div>
        <ReferralProgramAvatar
          :class="$style['referral-program__details-avatar']"
          :color-id="selectedColorId"
          :initials="selectedReferral?.isIncognito ? undefined : selectedReferral?.initials"
          is-big
        />
      </div>
      <div
        v-if="selectedReferral?.status === ProgramParticipationStatus.AVAILABLE"
        :class="$style['referral-program__details-available']"
      >
        <div :class="$style['referral-program__details-available-inner']">
          <VJumbotron
            v-bind="jumbotronProps"
            :class="$style['referral-program__details-jumbotron']"
          />
        </div>
        <template v-if="null">
          <SReferralProgramSelectBonus
            v-for="(bonus, index) in bonuses"
            :key="`bonus-${index}`"
            :title="bonus.title"
            :label="bonus.label"
            :type="bonus.type"
            :info="bonus.info"
            :is-selected="selectedBonus === bonus.type || bonuses.length === 1"
            @select="changeSelectedBonus"
            @choose="clickOnReward(bonus.type, selectedReferral.customerId)"
          />
        </template>
        <template v-else>
          <VReferralProgramSelectBonus
            v-for="(bonus, index) in bonuses"
            :key="`bonus-${index}`"
            :class="$style['referral-program__select-bonus']"
            :title="bonus.title"
            :label="bonus.label"
            :type="bonus.type"
            :info="bonus.info"
            @click="clickOnReward(bonus.type, selectedReferral.customerId)"
          />
        </template>
      </div>
      <VJumbotron
        v-else
        v-bind="jumbotronProps"
        :class="$style['referral-program__details-jumbotron']"
      >
        <template #footer>
          <SButton
            v-if="null && isSelectedReferralCompleted && isBonusSelected"
            full-width
            size="large"
            @click="goToBonuses"
          >
            {{ $t('WEB2_BONUSES_TITLE') }}
          </SButton>
          <LButton
            v-else-if="!null && isSelectedReferralCompleted && isBonusSelected"
            full-width
            @click="goToBonuses"
          >
            {{ $t('WEB2_BONUSES_TITLE') }}
          </LButton>
          <div
            v-if="isSelectedReferralCompleted && isBonusSelected"
            :class="$style['referral-program__details-footer']"
          >
            {{ $t('WEB2_REFERRAL_GO_TO_BONUSES') }}
          </div>
        </template>
      </VJumbotron>
      <div :class="$style['referral-program__details-req-title']">
        {{ isConditionsSatisfied ? $t('WEB2_REFERRAL_CONDITIONS_MET') : $t('WEB2_REFERRAL_CONDITIONS_NOT_MET') }}
      </div>
      <div :class="$style['referral-program__details-req']">
        <template v-if="null">
          <SReferralProgramStep
            v-for="requirement in requirements"
            :key="requirement.label"
            :label="requirement.label"
            :satisfied="requirement.satisfied"
            :has-arrow-icon="false"
          />
        </template>
        <template v-else>
          <VReferralProgramStep
            v-for="requirement in requirements"
            :key="requirement.label"
            :label="requirement.label"
            :satisfied="requirement.satisfied"
            is-info
          />
        </template>
      </div>
    </div>
  </template>
  <div
    v-if="!null && isAccessGranted && viewMode === ReferralProgramViewMode.MAIN"
    v-data-test="{el: 'referral-program-social-sharing'}"
    :class="$style['referral-program__social-sharing']"
  >
    <VButton
      :label="$t('WEB2_SHARE_LINK')"
      :icon-name="IconName.SHARE"
      full-width
      @click="handleShowShareModal(true)"
    />
  </div>
  <VSocialSharing
    :shown="isShareModal"
    :networks="socialSharingNetworks"
    :url="referralUrl"
    @close="closeShareModal"
  />
</template>

<style lang="scss" module>
.referral-program {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  gap: 16px;
  padding: 16px;

  &__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    justify-content: center;
    height: 148px;
    padding: 32px 16px;
    overflow: hidden;
    background: #ffc13c;
    border-radius: 5px;
  }

  &__info {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: -12px;

    &-button {
      display: inline-flex;
      column-gap: 2px;
      color: var(--ColorContent1);

      span {
        @include font-underline-u2;
      }
    }
  }

  &__get-access {
    @include refProgramGetAccessPosition;

    &-text {
      @include regular\14\24\025;

      margin: 0;
      color: var(--TextPrimary);
      text-align: center;
    }
  }

  &__title {
    @include font-title3s;

    color: var(--ColorContent1);
  }

  &__content {
    @include contentStyles;
  }

  &__blocks {
    display: flex;
    gap: 12px;
    align-items: center;
    align-self: stretch;
  }

  &__header {
    @include regular\13\16;

    display: flex;
    gap: 12px;
    align-items: flex-start;
    align-self: stretch;
    margin-top: 4px;

    &-awaiting {
      display: flex;
      flex: 1 0 0;
      gap: 4px;
      align-items: flex-start;
      align-self: stretch;
      color: var(--Blue);

      &-count {
        font-weight: 500;
      }
    }

    &-show {
      display: flex;
      align-items: flex-start;
      align-self: stretch;
      color: var(--TextSecondary);
      cursor: pointer;
    }
  }

  &__friends {
    @include refProgramFriends;

    &-header {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 24px;
    }

    &-left {
      display: inline-flex;
      flex-direction: column;
    }

    &-title {
      @include font-title3s;

      color: var(--ColorContent1);
    }

    &-awaiting {
      @include font-body-b7;

      color: var(--ColorContent2);

      &-count {
        @include font-body-b8;
      }
    }

    &-item {
      margin-right: 12px;

      @include for-hover {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  &__select-bonus:last-of-type {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  &__terms {
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    padding: 0 12px;
    color: var(--TextPrimary);
    cursor: pointer;
    background: var(--Layer1);
    border-radius: 5px;

    span {
      @include medium\14\20\025;

      display: flex;
      flex: 1 0 0;
      gap: 10px;
      align-items: flex-start;
      padding: 12px 0;
    }

    div {
      display: flex;
      gap: 4px;
      align-items: center;
      align-self: stretch;
      justify-content: flex-end;
      padding: 14px 2px 14px 14px;
    }
  }

  &__details {
    display: flex;
    gap: 16px;
    align-items: flex-start;
    align-self: stretch;
    padding-bottom: 0;
    margin-bottom: 16px;

    &-wrapper {
      @include detailsStyles;

      display: flex;
      flex-direction: column;
      align-items: center;
      align-self: stretch;
      padding: 0 16px 64px;
    }

    &-jumbotron {
      @include refProgramJumbotron;

      width: 100%;
    }

    &-content {
      display: flex;
      flex: 1 0 0;
      flex-direction: column;
      gap: 8px;
      align-items: flex-start;
      justify-content: center;
    }

    &-available {
      @include refProgramAvailable;

      display: flex;
      flex-direction: column;
      align-items: center;
      align-self: stretch;
      justify-content: center;

      &-inner {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: flex-start;
        align-self: stretch;
      }
    }

    &-req {
      @include refProgramReq;

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      align-self: stretch;
      padding-right: 0;

      &-title {
        @include medium\13\16;

        display: flex;
        flex: 1 0 0;
        gap: 8px;
        align-items: center;
        align-self: stretch;
        padding: 16px 0 12px 8px;
        color: var(--TextSecondary);
      }
    }

    &-footer {
      @include regular\14\24\025;

      margin-top: 20px;
      color: var(--TextSecondary);
    }
  }

  &__social-sharing {
    @include refProgramSocialSharing;
  }

  @include is-app-layout-desktop {
    padding-right: 24px;
    padding-left: 24px;
  }
}
</style>
