import type {
  GetCountersResponse,
} from 'web/src/modules/sportline/types/rest';
import useSportlineApi from 'web/src/modules/sportline/services/api/useSportlineApi';
import type { GetEventsInput } from 'web/src/modules/sportline/services/api/types';

export default function getCounters(input: GetEventsInput): Promise<GetCountersResponse> {
  const apiClient = useSportlineApi();
  return apiClient.getCounters(input);
}
