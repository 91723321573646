import type {
  CategoryType,
  getBonusWalletPromotionQuery,
  getPromotionDetailsQuery,
  getPromotionsQuery,
} from '@leon-hub/api-sdk';

export interface PromotionScrollableElement {
  getOffsetTop(): number;
  scrollIntoView(options?: boolean | ScrollIntoViewOptions): void;
}

export type PromotionDataDocument =
  Exclude<getPromotionDetailsQuery['queries']['promotions']['getPromotion']['data'], null>;

export type PromotionFullDetailsDocument = Exclude<PromotionDataDocument['promotion'], null>;

export type PromotionsDataDocument =
  Exclude<getPromotionsQuery['queries']['promotions']['getPromotions']['data'], null>;

export type PromotionPartialDetailsDocument = Exclude<PromotionsDataDocument['promotions'][number], null>;

export type PromotionMergedDetailsDocument = Partial<PromotionFullDetailsDocument & PromotionPartialDetailsDocument>;

export type BonusWalletPromotionDataDocument =
  Exclude<getBonusWalletPromotionQuery['queries']['promotions']['getBonusWalletPromotion']['data'], null>;

export type BonusWalletPromotionDetailsDocument = Exclude<BonusWalletPromotionDataDocument['promotion'], null>;

export type CustomerSportBonusDataDocument = Exclude<PromotionFullDetailsDocument['sportBonus'], null>;

export type PromotionActionButtonDataDocument = Exclude<PromotionFullDetailsDocument['actionButton'], null>;

export type BonusRequirementDataDocument = Exclude<CustomerSportBonusDataDocument['bonusRequirements'][number], null>;

export type PromotionsHowDoesItWorkItemsDocument = Exclude<PromotionFullDetailsDocument['howWorksItems'], null>;

export type PromotionsHowToRecieveBonusItemsDocument = Exclude<PromotionFullDetailsDocument['bonusItems'], null>;

export interface PromotionsCategoryType { categoryType: CategoryType; categoryId: string | null }

export interface PromotionsByCategoryType {
  id: string;
  value: string[];
  name: string;
  parentId: string;
  hasCategory?: boolean;
}

export enum PromotionItemType {
  PROMOTION = 'PROMOTION',
}

export enum PromotionItemIds {
  BONUS_WALLET = 'bonuswallet',
}

export interface PromotionSeoMetaInfo {
  promotionName: string;
  promotionDescription: string;
  promotionImage: string;
  promotionUrl: string;
  promotionDateCreateISO: string;
  promotionLocale: string;
}

export enum PromotionCustomerRatingMethodType {
  INCREASE = 'increase',
  DECREASE = 'decrease',
}

export enum PromotionsType {
  PROMO = 'promo',
  TOURNAMENTS = 'tournaments',
}

export enum CategoriesTabs {
  ALL = 'all',
  ARCHIVE = 'archive',
}
