<script setup lang="ts">
import { ButtonKind } from '@leon-hub/module-buttons';
import { IconName } from '@leon-hub/icons';

import { VIcon } from '@components/v-icon';

import { VLoaderDelayed } from 'web/src/components/Loader';
import { InputButton, TextInput } from 'web/src/components/Input';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import VSwitch from 'web/src/components/Switch/VSwitch/VSwitch.vue';
import { VJumbotron } from 'web/src/components/Jumbotron';

import type { SharePreviewEmits, SharePreviewProps } from '../types';
import { useSharePreview } from '../composables';

const props = defineProps<SharePreviewProps>();
const emits = defineEmits<SharePreviewEmits>();

const {
  copyCode,
  onMainButtonClick,
  imageIsLoaded,
  imageSrc,
  buttonIsDisabled,
  isShareApiAvailable,
  additionalButtonText,
  onShowAmountToggle,
  downloadImage,
  onAdditionalButtonClick,
} = useSharePreview(props, emits);
</script>

<template>
  <div v-auto-id="'SharePreview'" :class="$style['share-preview']">
    <template v-if="errorMessage">
      <VJumbotron
        :icon="IconName.ATTENTION_OUTLINE"
        :heading="isSharingPlacedBet ? $t('WEB2_SHARE_BET_ERROR_HEADING') : $t('WEB2_SHARE_SLIP_ERROR_HEADING')"
        :text="errorMessage"
      >
        <template #footer>
          <VButton
            :label="$t('WEB2_CLOSE')"
            full-width
            :kind="ButtonKind.PRIMARY"
            @click="$emit('done')"
          />
        </template>
      </VJumbotron>
    </template>
    <template v-else-if="isLoading">
      <div
        :class="$style['share-preview__loading']"
      >
        <VLoaderDelayed />
      </div>
    </template>
    <template v-else>
      <template v-if="bookingCode">
        <p :class="$style['share-preview__intro']">
          {{ $t('WEB2_BET_SHARE_INTRO') }}
        </p>
        <div :class="$style['share-preview__input']">
          <TextInput
            :label="$t('WEB2_BET_SHARE_BOOKING_CODE')"
            :value="bookingCode"
            readonly
            clickable-icon
          >
            <template #iconSuffix>
              <InputButton
                :title="$t('WEB2_COPY')"
                :icon-name="IconName.FILES"
                :class="$style['share-preview__copy']"
                @click="copyCode"
              />
            </template>
          </TextInput>
        </div>
      </template>
      <div
        v-if="imageUrl || !imageIsLoaded"
        :class="$style['share-preview__frame']"
      >
        <div
          v-if="!imageIsLoaded"
          :class="$style['share-preview__loading']"
        >
          <VLoaderDelayed />
        </div>
        <template v-else>
          <template v-if="!isShareApiAvailable || '1'">
            <button
              type="button"
              :class="$style['share-preview__download']"
              @click="downloadImage"
            >
              <span :class="$style['share-preview__download-label']">
                <VIcon :name="IconName.DOWNLOAD_2" /> {{ $t('WEB2_DOWNLOAD') }}
              </span>
            </button>
          </template>
          <img
            :src="imageSrc ?? ''"
            alt="preview"
            :class="$style['share-preview__image']"
          >
        </template>
      </div>
      <div
        v-if="showStakeAmountSwitcher"
        :class="$style['share-preview__switcher']"
      >
        <VSwitch
          :label="$t('WEB2_BET_SHARE_SHOW_AMOUNT')"
          :checked="showAmountEnabled"
          :disabled="!imageIsLoaded"
          @change="onShowAmountToggle"
        />
      </div>
      <div
        v-if="isShareApiAvailable || !'1'"
        :class="$style['share-preview__button']"
      >
        <VButton
          :label="$t('WEB2_BET_SHARE_CTA_BUTTON')"
          full-width
          :kind="ButtonKind.PRIMARY"
          :disabled="buttonIsDisabled"
          @click="onMainButtonClick"
        />
      </div>
      <div
        v-if="'1'"
        :class="$style['share-preview__button']"
      >
        <VButton
          :label="additionalButtonText"
          full-width
          :kind="isShareApiAvailable ? ButtonKind.SECONDARY : ButtonKind.PRIMARY"
          :disabled="buttonIsDisabled"
          @click="onAdditionalButtonClick"
        />
      </div>
    </template>
  </div>
</template>

<style module lang="scss">
  .share-preview {
    color: var(--TextPrimary);

    &__intro {
      @include regular\14\24\025;

      padding: 0;
      margin: 0;
    }

    &__loading {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 200px;
    }

    &__image {
      width: 100%;
      object-fit: contain;
    }

    &__input {
      margin: 14px 0 20px;
    }

    &__copy {
      color: var(--BrandDefault);
    }

    &__frame {
      position: relative;
      margin: 0;
    }

    &__download {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background: transparent;
      border: none;
      opacity: 0;

      &:hover {
        opacity: 1;
      }
    }

    &__download-label {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px;
      color: var(--TextPrimary);
      background: var(--Layer1);
      border-radius: 10px;
    }

    &__switcher {
      margin: 10px 0 0;
    }

    &__button {
      margin: 20px 0 0;
    }
  }
</style>
