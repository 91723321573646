import 'allsettled-polyfill';
import 'element-scroll-polyfill';
import 'abort-controller/polyfill';
import smoothScroll from 'smoothscroll-polyfill';
import promisePrototypeFinally from 'promise.prototype.finally';

export type ProvidePolyfills = () => void;

// eslint-disable global-require
const providePolyfills: ProvidePolyfills = function providePolyfills() {
  // always for legacy build and for modern build in case if ddos-guard removes allSettled by accident
  smoothScroll.polyfill();
  // @ts-ignore
  promisePrototypeFinally.shim();
  // prevent overriding of Promise in 3rd-party scripts
  Object.defineProperty(window, 'Promise', { value: window.Promise, writable: false, configurable: false });
};

export default providePolyfills;
