export default function updatePreparedToActionList({ id, state, currentList } : {
  id: string;
  state: boolean;
  currentList: string[];
}): { isChanged: boolean; list: string[] } {
  const alreadyIncludes = currentList.includes(id);
  const result = {
    isChanged: false,
    list: currentList,
  };

  if (state) {
    if (alreadyIncludes) { return result; }

    result.isChanged = true;
    result.list = [...currentList, id];

    return result;
  }

  if (!alreadyIncludes) { return result; }

  result.isChanged = true;
  result.list = currentList.filter((preparedId) => preparedId !== id);

  return result;
}
