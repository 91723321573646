import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useUnreadMessagesStore = defineStore('unread-messages', () => {
  const unreadMessages = ref(0);

  function setUnreadMessages(messages: number): void {
    unreadMessages.value = messages;
  }

  return {
    unreadMessages,
    setUnreadMessages,
  };
});
