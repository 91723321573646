import type {
  MarketType,
  MarketTypeId,
  MarketsColumn,
  MarketColumnId,
} from 'web/src/modules/sportline/types';

export interface SelectMarketTypeByColumnProps {
  columns: MarketsColumn[];
  selectedColumnId: Maybe<MarketColumnId>;
  allMarketsTypes: MarketType[];
  doUseFullAlternativeMarketsList?: boolean;
}

function getOrderedColumns(
  columns: MarketsColumn[],
  selectedColumnIndex: number,
  doKeepUnselectedColumns?: boolean,
): MarketsColumn[] {
  if (doKeepUnselectedColumns) {
    const orderedColumns = [...columns];
    const selectedColumns = selectedColumnIndex > -1 ? orderedColumns.splice(selectedColumnIndex, 1) : [];
    if (selectedColumns[0]) { orderedColumns.unshift(selectedColumns[0]); }

    return orderedColumns;
  }

  return selectedColumnIndex > -1 ? [...columns].splice(selectedColumnIndex, 1) : [columns[0]];
}

/**
 * columns - All available to select columns in the dropdown
 * selectedColumnId - the selected by user column in the dropdown
 * allMarketsTypes - all available market (types) in an event
 * doUseFullAlternativeMarketsList - select first available market type for empty selected column
 */
export function selectMarketTypeByColumn(props: SelectMarketTypeByColumnProps): Maybe<MarketType> {
  const {
    columns,
    selectedColumnId,
    allMarketsTypes,
    doUseFullAlternativeMarketsList,
  } = props;

  if (columns.length === 0) {
    return allMarketsTypes[0] || null;
  }

  const marketTypeMap = new Map<MarketTypeId, MarketType>();

  for (const marketType of allMarketsTypes) {
    marketTypeMap.set(marketType.id, marketType);
  }

  const selectedColumnIndex = columns.findIndex((column) => column.id === selectedColumnId) ?? -1;

  const orderedColumns = getOrderedColumns(columns, selectedColumnIndex, doUseFullAlternativeMarketsList);
  const availableMarketType: Maybe<MarketType>[] = [];

  let priority = -1;
  for (const column of orderedColumns) {
    for (const id of column.marketsPriority) {
      const marketType = marketTypeMap.get(id);
      priority += 1;
      availableMarketType.push(marketType ? { ...marketType, priority } : null);
    }
  }

  return availableMarketType.find((marketType) => !!marketType) ?? null;
}
