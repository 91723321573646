import { isSomeGuard, isUndefined } from "@leon-hub/guards";
import { createEnvValueGetter, envPrefix as envPrefix$1, createEnvVarSetter, toEnvVar, composeEnvName, unTypeEnv } from "@leon-hub/environment-common";
const envPrefix = "VUE_APP_";
var EditionKey = /* @__PURE__ */ ((EditionKey2) => {
  EditionKey2["PRODUCT"] = "product";
  EditionKey2["PLATFORM"] = "platform";
  EditionKey2["RENDERING"] = "rendering";
  EditionKey2["MODERNITY"] = "modernity";
  EditionKey2["ENV"] = "env";
  EditionKey2["SKIN"] = "skin";
  EditionKey2["SKIN_FAMILY"] = "skin_family";
  EditionKey2["STYLE"] = "style";
  EditionKey2["OS"] = "os";
  EditionKey2["LAYOUT"] = "layout";
  EditionKey2["BROWSER"] = "browser";
  EditionKey2["THEME"] = "theme";
  EditionKey2["BUNDLER"] = "bundler";
  return EditionKey2;
})(EditionKey || {});
const EditionValueProductLi = "li";
const EditionValueProductLeonru = "leonru";
const EditionValueProductSunny = "sunny";
const EditionValueProductSlott = "slott";
const EditionValueRenderingCsr = "csr";
const EditionValueRenderingSsr = "ssr";
const EditionValueRenderingPrerender = "prerender";
const EditionValuePlatformWeb = "web";
const EditionValuePlatformPwa = "pwa";
const EditionValuePlatformCordova = "cordova";
const EditionValuePlatformNative = "native";
const EditionValueModernityLegacy = "legacy";
const EditionValueModernityModern = "modern";
const EditionValueEnvDev = "dev";
const EditionValueEnvProd = "prod";
const EditionValueSkinFamilyDefault = "default";
const EditionValueSkinFamilyCasino = "casino";
const EditionValueSkinDefault = "default";
const EditionValueSkinDefaultAso = "defaultaso";
const EditionValueSkinLeoncasino = "leoncasino";
const EditionValueSkinLeoncasinoAso = "leoncasinoaso";
const EditionValueSkinLeoncasino2 = "leoncasino2";
const EditionValueSkinLeoncasinoonly = "leoncasinoonly";
const EditionValueSkinLeoncasinobr = "leoncasinobr";
const EditionValueSkinTwin = "twin";
const EditionValueSkinTwinAso = "twinaso";
const EditionValueSkinSlott = "slott";
const EditionValueSkinSlottAso = "slottaso";
const EditionValueSkinSunny = "sunny";
const EditionValueSkinCustom1 = "custom1";
const EditionValueSkinCustom2 = "custom2";
const EditionValueStyleLeon = "leon";
const EditionValueStyleTwin = "twin";
const EditionValueStyleSlott = "slott";
const EditionValueOsAndroid = "android";
const EditionValueOsIos = "ios";
const EditionValueOsMacos = "macos";
const EditionValueOsWindows = "windows";
const EditionValueOsLinux = "linux";
const EditionValueOsOther = "other";
const EditionValueLayoutPhone = "phone";
const EditionValueLayoutTablet = "tablet";
const EditionValueLayoutDesktop = "desktop";
const EditionValueBrowserChrome = "chrome";
const EditionValueBrowserEdge = "edge";
const EditionValueBrowserSafari = "safari";
const EditionValueBrowserFirefox = "firefox";
const EditionValueBrowserYandex = "yandex";
const EditionValueBrowserOpera = "opera";
const EditionValueBrowserUnsupported = "unsupported";
const EditionValueBrowserNo = "no";
const EditionValueThemeDark = "dark";
const EditionValueThemeLight = "light";
const EditionValueBundlerWebpack = "webpack";
const EditionValueBundlerVite = "vite";
const EditionValueBundlerNuxt = "nuxt";
const osEditions = /* @__PURE__ */ Object.freeze([
  EditionValueOsAndroid,
  EditionValueOsIos,
  EditionValueOsWindows,
  EditionValueOsMacos,
  EditionValueOsLinux,
  EditionValueOsOther
]);
const themeEditions = /* @__PURE__ */ Object.freeze([
  EditionValueThemeDark,
  EditionValueThemeLight
]);
const layoutEditions = /* @__PURE__ */ Object.freeze([
  EditionValueLayoutPhone,
  EditionValueLayoutDesktop,
  EditionValueLayoutTablet
]);
const browserEditions = /* @__PURE__ */ Object.freeze([
  EditionValueBrowserChrome,
  EditionValueBrowserSafari,
  EditionValueBrowserEdge,
  EditionValueBrowserFirefox,
  EditionValueBrowserYandex,
  EditionValueBrowserOpera,
  EditionValueBrowserNo
]);
function check$1(config) {
  return config;
}
const runtimeEditionsConfig = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ check$1({
  [/* @__PURE__ */ (() => EditionKey.OS)()]: osEditions,
  [/* @__PURE__ */ (() => EditionKey.LAYOUT)()]: layoutEditions,
  [/* @__PURE__ */ (() => EditionKey.BROWSER)()]: browserEditions,
  [/* @__PURE__ */ (() => EditionKey.THEME)()]: themeEditions
}));
function check(config) {
  return config;
}
const editionsConfig = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ check(/* @__PURE__ */ (() => ({
  [/* @__PURE__ */ (() => EditionKey.PRODUCT)()]: [
    EditionValueProductLi,
    EditionValueProductLeonru,
    EditionValueProductSunny,
    EditionValueProductSlott
  ],
  [/* @__PURE__ */ (() => EditionKey.PLATFORM)()]: [
    EditionValuePlatformWeb,
    EditionValuePlatformPwa,
    EditionValuePlatformCordova,
    EditionValuePlatformNative
  ],
  [/* @__PURE__ */ (() => EditionKey.RENDERING)()]: [
    EditionValueRenderingCsr,
    EditionValueRenderingSsr,
    EditionValueRenderingPrerender
  ],
  [/* @__PURE__ */ (() => EditionKey.MODERNITY)()]: [
    EditionValueModernityLegacy,
    EditionValueModernityModern
  ],
  [/* @__PURE__ */ (() => EditionKey.ENV)()]: [
    EditionValueEnvProd,
    EditionValueEnvDev
  ],
  [/* @__PURE__ */ (() => EditionKey.SKIN_FAMILY)()]: [
    EditionValueSkinFamilyDefault,
    EditionValueSkinFamilyCasino
  ],
  [/* @__PURE__ */ (() => EditionKey.SKIN)()]: [
    EditionValueSkinDefault,
    EditionValueSkinDefaultAso,
    EditionValueSkinLeoncasino,
    EditionValueSkinLeoncasinoAso,
    EditionValueSkinLeoncasino2,
    EditionValueSkinLeoncasinobr,
    EditionValueSkinLeoncasinoonly,
    EditionValueSkinTwin,
    EditionValueSkinTwinAso,
    EditionValueSkinSlott,
    EditionValueSkinSlottAso,
    EditionValueSkinSunny,
    EditionValueSkinCustom1,
    EditionValueSkinCustom2
  ],
  [/* @__PURE__ */ (() => EditionKey.STYLE)()]: [
    EditionValueStyleLeon,
    EditionValueStyleTwin,
    EditionValueStyleSlott
  ],
  [/* @__PURE__ */ (() => EditionKey.BUNDLER)()]: [
    EditionValueBundlerWebpack,
    EditionValueBundlerVite,
    EditionValueBundlerNuxt
  ],
  ...runtimeEditionsConfig
}))()));
const editionValues = /* @__PURE__ */ new Set(/* @__PURE__ */ Object.values(editionsConfig).flat());
const isEditionValue = (value) => typeof value === "string" && editionValues.has(value);
function isOptionalEditionValue(value) {
  return isSomeGuard([isUndefined, isEditionValue], value);
}
const editionsKeys = /* @__PURE__ */ new Set(/* @__PURE__ */ Object.keys(editionsConfig));
const isEditionKey = (key) => typeof key === "string" && editionsKeys.has(key);
const getEditionEnvValue = /* @__PURE__ */ createEnvValueGetter(editionsConfig, envPrefix$1);
function getEditionsStateFromEnv(env) {
  return Object.keys(editionsConfig).reduce((state, key) => {
    const value = getEditionEnvValue(env, key);
    if (value) {
      return state.set(key, value);
    }
    state.set(key, void 0);
    return state;
  }, /* @__PURE__ */ new Map());
}
const setEditionEnvVar = /* @__PURE__ */ createEnvVarSetter(editionsConfig, envPrefix);
const setEditionEnv = (env, onlyDefined = false) => {
  const state = getEditionsStateFromEnv(env);
  return [...state.keys()].reduce((updatedEnv, key) => {
    const value = state.get(key);
    return onlyDefined && value === void 0 ? updatedEnv : setEditionEnvVar(updatedEnv, key, value);
  }, {});
};
function toEditionEnvName(key) {
  return toEnvVar(envPrefix$1, key);
}
function toEditionEnvNameComposed(key, value) {
  return composeEnvName(envPrefix$1, key, value);
}
const getEditionEnvKeys = () => {
  const keys = Object.keys(editionsConfig);
  return keys.flatMap((key) => [
    toEditionEnvName(key),
    ...editionsConfig[key].flatMap((value) => toEditionEnvNameComposed(key, value))
  ]);
};
const getEditionEnvValues = (inputValues) => {
  let update = {};
  for (const inputValue of inputValues) {
    const key = findKey(inputValue);
    update = {
      ...update,
      ...setEditionEnvVar({}, key, inputValue)
    };
  }
  return update;
};
const editionKeys = /* @__PURE__ */ Object.values(EditionKey);
function findKey(value) {
  for (const editionKey of editionKeys) {
    const values = editionsConfig[editionKey];
    if (values.includes(value)) return editionKey;
  }
  throw new Error(`Unknown value: ${value}`);
}
const setEditionEnvValues = (inputValues) => {
  process.env = {
    ...process.env,
    ...getEditionEnvValues(inputValues)
  };
};
function getEnvClassNames(env, prefix = "app__", delimiter = "--") {
  const classList = [];
  const editionsState = getEditionsStateFromEnv(unTypeEnv(env));
  for (const item of [
    EditionKey.PRODUCT,
    EditionKey.RENDERING,
    EditionKey.PLATFORM,
    EditionKey.MODERNITY,
    EditionKey.ENV,
    EditionKey.SKIN,
    EditionKey.STYLE,
    EditionKey.OS,
    EditionKey.LAYOUT,
    EditionKey.BROWSER,
    EditionKey.THEME
  ]) {
    const environmentValue = editionsState.get(item);
    if (environmentValue) {
      classList.push(`${prefix}${item}${delimiter}${environmentValue}`);
    }
  }
  return classList.join(" ");
}
export {
  EditionKey,
  EditionValueBrowserChrome,
  EditionValueBrowserEdge,
  EditionValueBrowserFirefox,
  EditionValueBrowserNo,
  EditionValueBrowserOpera,
  EditionValueBrowserSafari,
  EditionValueBrowserUnsupported,
  EditionValueBrowserYandex,
  EditionValueBundlerNuxt,
  EditionValueBundlerVite,
  EditionValueBundlerWebpack,
  EditionValueEnvDev,
  EditionValueEnvProd,
  EditionValueLayoutDesktop,
  EditionValueLayoutPhone,
  EditionValueLayoutTablet,
  EditionValueModernityLegacy,
  EditionValueModernityModern,
  EditionValueOsAndroid,
  EditionValueOsIos,
  EditionValueOsLinux,
  EditionValueOsMacos,
  EditionValueOsOther,
  EditionValueOsWindows,
  EditionValuePlatformCordova,
  EditionValuePlatformNative,
  EditionValuePlatformPwa,
  EditionValuePlatformWeb,
  EditionValueProductLeonru,
  EditionValueProductLi,
  EditionValueProductSlott,
  EditionValueProductSunny,
  EditionValueRenderingCsr,
  EditionValueRenderingPrerender,
  EditionValueRenderingSsr,
  EditionValueSkinCustom1,
  EditionValueSkinCustom2,
  EditionValueSkinDefault,
  EditionValueSkinDefaultAso,
  EditionValueSkinFamilyCasino,
  EditionValueSkinFamilyDefault,
  EditionValueSkinLeoncasino,
  EditionValueSkinLeoncasino2,
  EditionValueSkinLeoncasinoAso,
  EditionValueSkinLeoncasinobr,
  EditionValueSkinLeoncasinoonly,
  EditionValueSkinSlott,
  EditionValueSkinSlottAso,
  EditionValueSkinSunny,
  EditionValueSkinTwin,
  EditionValueSkinTwinAso,
  EditionValueStyleLeon,
  EditionValueStyleSlott,
  EditionValueStyleTwin,
  EditionValueThemeDark,
  EditionValueThemeLight,
  browserEditions,
  editionsConfig,
  envPrefix,
  getEditionEnvKeys,
  getEditionEnvValue,
  getEditionEnvValues,
  getEditionsStateFromEnv,
  getEnvClassNames,
  isEditionKey,
  isEditionValue,
  isOptionalEditionValue,
  layoutEditions,
  osEditions,
  runtimeEditionsConfig,
  setEditionEnv,
  setEditionEnvValues,
  setEditionEnvVar,
  themeEditions,
  toEditionEnvName
};
