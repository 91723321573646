import type { ComputedRef } from 'vue';
import { computed, ref } from 'vue';

import type { VButtonProps } from 'web/src/components/Button/VButton/types';
import { useI18n } from 'web/src/modules/i18n/composables';
import {
  getCustomerPositionIndex,
  getSecondPositionVisible,
  getShowMoreButtonProperties,
} from 'web/src/modules/promotions/components/LeaderBoard/composables/utils';

import type { LeaderBoardEmits, LeaderBoardProps } from '../types';

export interface LeaderBoardComposable {
  customerPositionIndex: ComputedRef<Optional<number>>;
  participantMounted: () => void;
  secondPositionVisible: ComputedRef<boolean>;
  showMoreButtonProperties: ComputedRef<VButtonProps>;
  handleLoadMore: () => void;
}

export default function useLeaderBoard(
  props: LeaderBoardProps,
  emit: LeaderBoardEmits,
): LeaderBoardComposable {
  const { $translate } = useI18n();

  const stickyPositionVisibility = ref(true);

  const showMoreButtonProperties = computed<VButtonProps>(() => getShowMoreButtonProperties(props, $translate));

  const customerPositionIndex = computed<Optional<number>>(() => getCustomerPositionIndex(props));

  // eslint-disable-next-line max-len
  const secondPositionVisible = computed<boolean>(() => getSecondPositionVisible(props, stickyPositionVisibility.value));

  const participantMounted = () => {
    stickyPositionVisibility.value = false;
  };

  const handleLoadMore = () => {
    emit('load-more');
  };

  return {
    customerPositionIndex,
    participantMounted,
    secondPositionVisible,
    showMoreButtonProperties,
    handleLoadMore,
  };
}
