import type { SportFamilyMapping } from 'web/src/modules/sportline/types/settings';
import type { SportEventDetailsRouteLocationParameters } from 'web/src/modules/sportline/submodules/navigation/store/types';
import type {
  CoreSportResponse,
  CoreRegionResponse,
  CoreLeagueResponse,
  CoreSportEventResponse,
} from 'web/src/modules/sportline/types/rest';

import { matchSportFamily } from './matchSportFamily';

interface BuildNavigationParametersOptions {
  sportResponse: CoreSportResponse;
  regionResponse: CoreRegionResponse;
  leagueResponse: CoreLeagueResponse;
  sportEventResponse: CoreSportEventResponse;
  sportFamilyMapping: Maybe<SportFamilyMapping>;
}

export function buildSportlineEventNavigationParameters(
  options: BuildNavigationParametersOptions,
): SportEventDetailsRouteLocationParameters {
  const {
    sportFamilyMapping,
    sportEventResponse,
    leagueResponse,
    regionResponse,
    sportResponse,
  } = options;

  return {
    id: String(sportEventResponse.id),
    urlName: sportEventResponse.url,
    leagueUrlName: leagueResponse.url,
    regionUrlName: regionResponse.url,
    sportFamily: matchSportFamily(sportResponse, sportFamilyMapping),
  };
}
