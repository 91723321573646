<script lang="ts" setup>
import EgsJackpotAmount from 'web/src/modules/egs/submodules/jackpots/components/EgsJackpotAmount/EgsJackpotAmount.vue';
import VSightAmountAnimator from 'web/src/components/AmountAnimator/VSightAmountAnimator/VSightAmountAnimator.vue';
import type { EgsJackpotAnimatedAmountProps } from 'web/src/modules/egs/submodules/jackpots/components/EgsJackpotAnimatedAmount/types';
import {
  useEgsJackpotAnimatedAmount,
} from 'web/src/modules/egs/submodules/jackpots/components/EgsJackpotAnimatedAmount/composables';
import { useFormatMoney } from 'web/src/modules/money/composables';

const props = defineProps<EgsJackpotAnimatedAmountProps>();

const {
  amountAnimatorProps,
} = useEgsJackpotAnimatedAmount(props);

const formatMoney = useFormatMoney();
</script>

<template>
  <VSightAmountAnimator v-auto-id="'EgsJackpotAnimatedAmount'"
    v-bind="amountAnimatorProps"
  >
    <template #default="{ animatedValue }">
      <template v-if="isPlainText">
        {{ formatMoney(animatedValue, { currency, reduceAmount: 1_000_000_000 }) }}
      </template>
      <EgsJackpotAmount
        v-else
        :currency="currency"
        :value="animatedValue"
        :has-background="hasBackground"
      />
    </template>
  </VSightAmountAnimator>
</template>
