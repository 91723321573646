import type {
  ComputedRef,
  Ref,
} from 'vue';
import {
  computed,
} from 'vue';
import {
  useRoute,
  useRouter,
} from 'vue-router';

import type { LobbyEgsGroupItemProps } from 'web/src/modules/lobby/components/LobbyEgsGroupItem/types';
import { useTheme } from 'web/src/modules/theme/composables';
import { BadgeKind } from 'web/src/components/Badge/VBadge/enums';
import type { VBadgeProps } from 'web/src/components/Badge/VBadge/types';
import { getLobbyLocation } from 'web/src/modules/egs/utils';

export interface LobbyEgsGroupItemComposable {
  src: ComputedRef<string>;
  ntBadgeProperties: ComputedRef<VBadgeProps>;
  href: Ref<string | undefined>;
}

export default function useLobbyEgsGroupItem(props: LobbyEgsGroupItemProps): LobbyEgsGroupItemComposable {
  const route = useRoute();
  const router = useRouter();

  const src = computed(() => {
    if (!props.item) {
      return '';
    }

    const { isLight } = useTheme();

    const link = isLight.value
      ? props.item.iconUrls.light
      : props.item.iconUrls.dark;
    return link || '';
  });

  const ntBadgeProperties = computed<VBadgeProps>(() => ({
    kind: BadgeKind.SQUARE_ERROR,
    label: props.item?.ntBadge || '',
  }));

  const href = computed(() => (
    props.item ? router.resolve(getLobbyLocation(
      route,
      props.item.url,
    )).href
      : undefined
  ));

  return {
    src,
    ntBadgeProperties,
    href,
  };
}
