<script lang="ts" setup>
import {
  computed,
} from 'vue';

import type { NavigationItem } from '@leon-hub/navigation-config';
import { getNavigationItemRouteLocation } from '@leon-hub/navigation-config';

import VRouterLink from 'web/src/components/Link/VRouterLink/VRouterLink.vue';
import { useAnalytics } from 'web/src/modules/analytics/composables';

import type { FooterNavigationListItemProps } from './types';

const props = withDefaults(defineProps<FooterNavigationListItemProps>(), {});

const { navigationItemClick } = useAnalytics();

function onClick(navigationItem: NavigationItem): void {
  navigationItemClick(navigationItem, props.isLoggedIn);
}

const to = computed(() => getNavigationItemRouteLocation(props.item, 'FooterNavigationItem'));
</script>

<template>
  <a
    v-if="item.url"
    :href="item.url"
    :class="$style['link']"
    target="_blank"
    rel="noopener"
  >
    <slot>{{ item.caption }}</slot>
  </a>
  <VRouterLink
    v-else-if="item.routeName"
    :to="to"
    is-hidden-decoration
    @click="onClick(item)"
  >
    {{ item.caption }}
  </VRouterLink>
  <span v-else>
    {{ item.caption }}
  </span>
</template>

<style lang="scss" module>
.link {
  text-decoration: none;
}
</style>
