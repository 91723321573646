<script lang="ts" setup>
import { computed } from 'vue';

import { InputEventType } from 'web/src/components/Input/enums';
import VCaptchaVerificationWatcher
  from 'web/src/modules/captcha/components/VCaptchaVerificationWatcher/VCaptchaVerificationWatcher.vue';
import type { CaptchaToken } from 'web/src/modules/captcha/store/types';

import type {
  FormWrapperWidgetProperties,
  FormDataEvent,
} from '../../types';
import useFormCaptcha from './useFormCaptcha';
import type VCaptchaProperties from '../../../Input/types/VCaptchaProperties';

interface Emits {
  (e: 'input', event: FormDataEvent): void;
}

const props = defineProps<FormWrapperWidgetProperties<VCaptchaProperties>>();

const emit = defineEmits<Emits>();

const { componentProps } = useFormCaptcha(props);

const shouldRenderComponent = computed<boolean>(
  () => !!('1' && componentProps.value.captchaEnabled),
);

const onVerify = (captchaToken: CaptchaToken): void => {
  emit(InputEventType.INPUT, {
    value: captchaToken,
    name: props.name,
  });
};
</script>

<template>
  <VCaptchaVerificationWatcher v-auto-id="'FormCaptcha'"
    v-if="shouldRenderComponent"
    v-bind="componentProps"
    @verify="onVerify"
  />
</template>
