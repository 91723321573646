import { isLocalStorageAvailable } from './isLocalStorageAvailable';
import { LocalStorageManager, MemoryStorageManager } from '../services';
import type { UniversalStorageManager } from '../types';

let storage: UniversalStorageManager | undefined;

export const getUniversalStorageSingleton = (): UniversalStorageManager => {
  if (!storage) {
    storage = isLocalStorageAvailable() ? new LocalStorageManager() : new MemoryStorageManager();
  }
  return storage;
};
