<script lang="ts" setup>
import { IconName, IconSize } from '@leon-hub/icons';

import type { VIconProps } from '@components/v-icon';
import { VIcon } from '@components/v-icon';

import type {
  BetlineStatisticsPlayer,
} from 'web/src/modules/sportline/types/rest';

interface StatisticPlayerLogoProps {
  player: BetlineStatisticsPlayer;
}

defineProps<StatisticPlayerLogoProps>();

const iconProperties: VIconProps = {
  name: IconName.BRAND_LOGO,
  size: IconSize.SIZE_16,
};
</script>

<template>
  <img v-auto-id="'StatisticPlayerLogo'"
    v-if="player.logo"
    :alt="player.playerName ?? undefined"
    :src="player.logo"
  >
  <VIcon
    v-else
    v-bind="iconProperties"
  />
</template>
