import type {
  NavigationGuardNext,
  RouteLocationNormalized,
} from 'vue-router';
import { toRef } from 'vue';

import { logger } from '@leon-hub/logging';
import { RouteName } from '@leon-hub/routing-config-names';

import { loadLobbyBlock } from 'web/src/modules/lobby/components/LobbyBlock/LazyLobbyBlock';
import { useEgsGroupsStore } from 'web/src/modules/egs/submodules/groups/store';
import { useEgsCategoriesStore } from 'web/src/modules/egs/submodules/categories/store';
import {
  getCategoryByRoute,
  getGroupByUrl,
  isFavoriteEgsRoute,
  getLobbyCategoryUrlFromRoute,
} from 'web/src/modules/egs/utils';
import {
  AllGamesCategoryUrl,
  AllGamesGroupUrl,
  HistoryGamesCategoryUrl,
} from 'web/src/modules/egs/constants';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { EgsGamesCategoryId } from 'web/src/modules/egs/enums';
import { useLobbyStore } from 'web/src/modules/lobby/store';
import useLobbyType from 'web/src/modules/lobby/composables/useLobbyType';
import type { EgsGroup } from 'web/src/modules/egs/types';

interface PrefetchResponse {
  groupId?: string;
  categoryId?: string;
}

export interface UseEgsBasePrefetchComposable {
  prefetch(
    to: RouteLocationNormalized,
    next: NavigationGuardNext,
  ): Promise<PrefetchResponse | false>;
}

export default function useEgsBasePrefetch(): UseEgsBasePrefetchComposable {
  const groupsStore = useEgsGroupsStore();
  const categoriesStore = useEgsCategoriesStore();
  const currentGroups = toRef(groupsStore, 'currentGroups');
  const { loadGroups } = groupsStore;
  const { loadCategories, getCurrentGroupsCategories } = categoriesStore;

  function isHistory(to: RouteLocationNormalized): boolean {
    return to.params.categoryUrl === HistoryGamesCategoryUrl;
  }

  async function fetchGroups(to: RouteLocationNormalized): Promise<EgsGroup | undefined> {
    if (process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED && to.name === RouteName.HOME) {
      return undefined;
    }

    if (currentGroups.value.length === 0) {
      if (to.params.groupUrl) {
        await loadGroups();
      } else {
        void loadGroups().then();
      }
    }

    return to.params.groupUrl
      ? getGroupByUrl(currentGroups.value, String(to.params.groupUrl))
      : undefined;
  }

  async function fetchCategories(to: RouteLocationNormalized, groupId?: string): Promise<string | undefined> {
    if (process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED && to.name === RouteName.HOME) {
      return undefined;
    }

    const currentCategories = getCurrentGroupsCategories(groupId);

    const categoryUrl = getLobbyCategoryUrlFromRoute(to);

    if (currentCategories.value.length === 0) {
      if (categoryUrl) {
        await loadCategories(groupId);
      } else {
        void loadCategories(groupId).then();
      }
    } else {
      void loadCategories(groupId, undefined, true).then();
    }

    if (isFavoriteEgsRoute(to)) {
      return EgsGamesCategoryId.MY_GAMES;
    }

    if (isHistory(to)) {
      return EgsGamesCategoryId.HISTORY_GAMES;
    }

    const category = categoryUrl
      ? getCategoryByRoute(currentCategories.value, to)
      : undefined;

    return category?.id;
  }

  async function prefetchComponents(): Promise<void> {
    await loadLobbyBlock().catch(logger.error);
  }

  async function prefetch(
    to: RouteLocationNormalized,
    next: NavigationGuardNext,
  ): Promise<PrefetchResponse | false> {
    const group = await fetchGroups(to);
    const groupId = group?.blockType ? undefined : group?.id;

    if (to.params.groupUrl && to.params.groupUrl !== AllGamesGroupUrl && groupId === undefined && !group?.blockType) {
      next({ name: RouteName.ERROR_NOT_FOUND_404 });
      return false;
    }

    const { isLoggedIn } = useIsLoggedIn();

    if ((isFavoriteEgsRoute(to) || isHistory(to)) && !isLoggedIn.value) {
      next({
        name: String(to.name),
      });
      return false;
    }

    if (process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED && to.params.categoryUrl === AllGamesCategoryUrl) {
      next({
        name: String(to.name),
        params: to.params.groupUrl !== AllGamesGroupUrl ? {
          groupUrl: to.params.groupUrl,
        } : {},
        query: to.query,
      });
    }

    const categoryId = await fetchCategories(to, groupId);

    if (to.params.categoryUrl && categoryId === undefined && !group?.blockType) {
      next({ name: RouteName.ERROR_NOT_FOUND_404 });
      return false;
    }

    const lobbyStore = useLobbyStore();
    const { lobbyType } = useLobbyType();

    if (lobbyStore.isLobbyChanged(lobbyType.value, groupId, categoryId)) {
      void lobbyStore.loadLobbyItems({
        type: lobbyType.value,
        group: groupId,
        category: categoryId,
      });
      lobbyStore.setActiveLobby(lobbyType.value, groupId, categoryId);
    }

    await prefetchComponents();

    return {
      groupId,
      categoryId,
    };
  }

  return {
    prefetch,
  };
}
