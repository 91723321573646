import type { Ref } from 'vue';
import { ref, computed } from 'vue';

import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';
import { BusEvent, useEventsBus } from '@leon-hub/event-bus';
import { onComponentDeactivated } from '@leon-hub/vue-utils';

import { useAnalytics } from 'web/src/modules/analytics/composables';
import {
  useInjectSelectedColumn,
  useInjectSelectColumnMethod,
} from 'web/src/modules/sportline/composables/select-main-market';
import type { MarketColumnId } from 'web/src/modules/sportline/types';
import type { SportlineFragmentBasisKey } from 'web/src/modules/sportline/types/list';
import type { VSelectOption } from 'web/src/components/Select';
import type { VPopperRef, VPopperProps } from 'web/src/components/Popper/VPopper/types';
import { PopperPosition } from 'web/src/components/Popper/VPopper/enums';

interface UseMarketsDropDownProps {
  basisKey: Ref<SportlineFragmentBasisKey>;
}

interface UseMarketsDropDownComposable {
  popperRef: Ref<Optional<VPopperRef>>;
  selectedColumnId: Ref<Maybe<string>>;
  dropdownOptions: Ref<VSelectOption[]>;
  activeOption: Ref<Optional<VSelectOption>>;
  title: Ref<string>;
  onPopperShow(): void;
  onActiveMarketTypeChange(value: string): void;
}

export const popoverProperties: VPopperProps = {
  position: PopperPosition.TOP_END,
  hasOffset: false,
  modifiers: [{
    name: 'minWidth',
    enabled: true,
    phase: 'beforeWrite',
    requires: ['computeStyles'],
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    fn: ({ state }) => {
      const result = { ...state };
      const referenceWidth = state.rects.reference.width;
      result.styles.popper.minWidth = `${referenceWidth}px`;
      return result;
    },
  },
  {
    name: 'offset',
    options: {
      offset: ({ popper }: { popper: { height: number } }) => [0, -popper.height],
    },
  }],
};

export function useMarketsDropDown({ basisKey }: UseMarketsDropDownProps): UseMarketsDropDownComposable {
  const analytics = useAnalytics();
  const bus = useEventsBus();

  const { selectColumn } = useInjectSelectColumnMethod({ basisKey });
  const { selectedColumnId, columns } = useInjectSelectedColumn({ basisKey });

  // eslint-disable-next-line unicorn/prevent-abbreviations
  const popperRef = ref<Optional<VPopperRef>>();

  const selectedColumnIdFinal = computed<Maybe<string>>(() => (
    selectedColumnId.value
    || columns.value[0]?.id
    || null
  ));

  const dropdownOptions = computed<VSelectOption[]>(() => columns.value.map((column) => ({
    label: column.name,
    value: column.id,
  })));
  const activeOption = computed<Optional<VSelectOption>>(() => {
    const selectedId = selectedColumnIdFinal.value;
    return dropdownOptions.value.find((item) => item.value === selectedId);
  });
  const title = computed<string>(() => activeOption.value?.label ?? '');

  let clearListeners: Optional<() => void>;

  function closeDropdown(): void {
    clearListeners?.();
    popperRef.value?.hide();
  }

  function onPopperShow(): void {
    if (!process.env.VUE_APP_RENDERING_SSR) {
      bus.once(BusEvent.LAYOUT_CONTENT_SCROLL, closeDropdown);
      window.addEventListener('resize', closeDropdown);

      clearListeners = () => {
        bus.off(BusEvent.LAYOUT_CONTENT_SCROLL, closeDropdown);
        window.removeEventListener('resize', closeDropdown);
        clearListeners = undefined;
      };
    }
  }

  function onActiveMarketTypeChange(value: MarketColumnId): void {
    analytics.push(AnalyticsEvent.CLICK_MAP, { clickCounter: 'lineViewDisplay' });
    selectColumn(value);
    closeDropdown();
  }

  onComponentDeactivated(closeDropdown);

  return {
    popperRef,
    selectedColumnId: selectedColumnIdFinal,
    dropdownOptions,
    activeOption,
    title,
    onPopperShow,
    onActiveMarketTypeChange,
  };
}
