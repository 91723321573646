import type {
  GetSportResponse,
  GetRegionResponse,
  GetLeagueResponse,
} from 'web/src/modules/sportline/types/rest';
import type {
  GetSportInput,
  GetRegionInput,
  GetLeagueInput,
} from 'web/src/modules/sportline/services/api/types';
import useSportlineApi from 'web/src/modules/sportline/services/api/useSportlineApi';

export function getSport(input: GetSportInput): Promise<GetSportResponse> {
  const apiClient = useSportlineApi();
  return apiClient.getSport(input);
}

export function getRegion(input: GetRegionInput): Promise<GetRegionResponse> {
  const apiClient = useSportlineApi();
  return apiClient.getRegion(input);
}

export function getLeague(input: GetLeagueInput): Promise<GetLeagueResponse> {
  const apiClient = useSportlineApi();
  return apiClient.getLeague(input);
}
