<script setup lang="ts">
import type {
  FormWrapperWidgetProperties,
} from 'web/src/components/Form/types';
import { useCommonFormWidgetProps } from 'web/src/components/Form/composables';

import type { CryptoPaymentDataProps } from '../../components/CryptoPaymentData';
import { CryptoPaymentData } from '../../components/CryptoPaymentData';

const props = defineProps<FormWrapperWidgetProperties<CryptoPaymentDataProps>>();
const { componentProps } = useCommonFormWidgetProps<CryptoPaymentDataProps>(props);
</script>

<template>
  <CryptoPaymentData v-auto-id="'FormCryptoPaymentData'" v-bind="componentProps" />
</template>
