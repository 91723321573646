import { FormControlType } from '@leon-hub/form-utils';

import type { FormWidgetList } from 'web/src/components/Form/utils/widgetList';
import FormRegistrationTermsCheckbox from 'web/src/components/TermsSpecialCheckboxes/FormRegistrationTermsCheckbox';
import FormAsoCheckbox from 'web/src/modules/registration/components/RegistrationFormWidgets/FormAsoCheckbox/FormAsoCheckbox.vue';

import FormBonusCode from './FormBonusCode';

const AsoTermsCheckbox = process.env.VUE_APP_FEATURE_ASO_ENABLED
  ? FormAsoCheckbox
  : () => false;

const commonRegistrationWidgetsWidgets: FormWidgetList = {
  [FormControlType.BonusCode]: FormBonusCode,
  [FormControlType.TermsAcceptionCheckbox]: FormRegistrationTermsCheckbox,
};

if (process.env.VUE_APP_FEATURE_ASO_ENABLED) {
  Object.assign(commonRegistrationWidgetsWidgets, {
    [FormControlType.AsoTermsCheckbox]: AsoTermsCheckbox,
  });
}

export default commonRegistrationWidgetsWidgets;
