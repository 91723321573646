import type {
  CoreSportlineFetchOptions,
  EventsChangesFetchOptions,
  GetSportEventsChangesResponse,
  GetSportEventsResponse,
} from 'web/src/modules/sportline/types/rest';
import type { GetEventsInput } from 'web/src/modules/sportline/services/api/types';
import {
  getEvents,
  getEventsChanges,
} from 'web/src/modules/sportline/utils/api';

import useSportlineApiPending from './useSportlineApiPending';
import type { TryRequestWithCTagMethod } from './types';
import type { ApiPendingParams, ApiPending } from './useSportlineApiPending';

export type PendingGetEventsInput = Omit<GetEventsInput, 'ctag'>;

interface UseSportlinePendingApiProps<Options extends CoreSportlineFetchOptions> {
  tryRequestWithCTag: TryRequestWithCTagMethod;
  getEventsInput(options: Options & EventsChangesFetchOptions): PendingGetEventsInput;
  getPendingParams(options: Options & EventsChangesFetchOptions): ApiPendingParams;
}

interface UseSportlinePendingApiComposable<Options extends CoreSportlineFetchOptions> {
  loadEvents(
    options: Options & EventsChangesFetchOptions,
  ): Promise<Maybe<GetSportEventsResponse | GetSportEventsChangesResponse>>;
}

export function useSportlinePendingApi<
  Options extends CoreSportlineFetchOptions = CoreSportlineFetchOptions,
>(
  props: UseSportlinePendingApiProps<Options>,
): UseSportlinePendingApiComposable<Options> {
  const {
    tryRequestWithCTag,
    getEventsInput,
    getPendingParams,
  } = props;

  const pendingController = useSportlineApiPending<
    Maybe<GetSportEventsResponse | GetSportEventsChangesResponse>
  >();

  // just fetch data
  async function fetchEvents(
    options: Options & EventsChangesFetchOptions,
  ): Promise<Maybe<GetSportEventsResponse | GetSportEventsChangesResponse>> {
    let result: Maybe<GetSportEventsResponse | GetSportEventsChangesResponse> = null;
    const { vTag, silent } = options;
    const input = getEventsInput(options);

    if (vTag) {
      result = await tryRequestWithCTag((ctag) => getEventsChanges({
        ...input,
        ctag,
        vtag: vTag,
        silent,
      }));
    }

    if (!vTag || result?.vtag === 'invalid') {
      result = await tryRequestWithCTag((ctag) => getEvents({ ...input, ctag }));
    }

    return result;
  }

  function loadEvents(
    options: Options & EventsChangesFetchOptions,
  ): Promise<Maybe<GetSportEventsResponse | GetSportEventsChangesResponse>> {
    const pendingFilter: ApiPendingParams = getPendingParams(options);
    const apiPendingIndex = pendingController.getApiPendingIndexForFilter(pendingFilter);

    if (apiPendingIndex > -1) {
      return pendingController.getApiPendingByIndex(apiPendingIndex).request;
    }

    const request = new Promise<Maybe<GetSportEventsResponse | GetSportEventsChangesResponse>>((resolve, reject) => {
      fetchEvents(options).then((result) => {
        pendingController.removeApiPendingForFilter(pendingFilter);
        resolve(result);
        return result;
      }).catch((error) => {
        pendingController.removeApiPendingForFilter(pendingFilter);
        reject(error);
      });
    });
    const apiPending: ApiPending<Maybe<GetSportEventsResponse | GetSportEventsChangesResponse>> = {
      ...pendingFilter,
      request,
    };

    pendingController.addApiPending(apiPending);

    return apiPending.request;
  }

  return {
    loadEvents,
  };
}
