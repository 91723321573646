<script setup lang="ts">
import { VIcon } from '@components/v-icon';
import { VSpinner } from '@components/spinner';

import type { BaseInputEmits } from 'web/src/components/Input';
import BetSlipDesktopDropdown from 'web/src/modules/slip/components/BetSlipSelect/BetSlipDesktopDropdown.vue';

import type { BetsSlipSelectProps } from './types';
import useBetSlipSelect from './composables/useBetSlipSelect';
import getOptionLabel from './utils/getOptionLabel';

const props = withDefaults(defineProps<BetsSlipSelectProps>(), {
  name: 'slipSelect',
  selectedValue: '',
  options: () => [],
});

const emits = defineEmits<BaseInputEmits>();

const {
  isSingleValue,
  isFocused,
  displayLabel,
  displayValue,
  placeHolderText,
  setFocused,
  returnFocus,
  arrowIconProps,
  selectChange,
  emitChange,
} = useBetSlipSelect(props, emits);
</script>

<template>
  <div v-auto-id="'BetSlipSelect'"
    :class="{
      [$style['bet-slip-select']]: true,
      [$style['bet-slip-select--freebet']]: props.isFreebet,
      [$style['bet-slip-select--focused']]: isFocused,
      [$style['bet-slip-select--single-value']]: isSingleValue,
    }"
  >
    <VSpinner
      v-show="!displayLabel"
      :class="$style['bet-slip-select__placeholder']"
    />
    <template v-if="displayLabel">
      <div :class="$style['bet-slip-select__display']">
        <span :class="$style['bet-slip-select__label']">
          {{ displayLabel }}
        </span>
        <span
          v-if="isFreebet && displayValue"
          :class="$style['bet-slip-select__value']"
        >
          {{ displayValue }}
        </span>
      </div>
      <template v-if="!isSingleValue">
        <BetSlipDesktopDropdown
          v-if="'1'"
          :name="name"
          :options="options"
          :selected-value="selectedValue"
          :is-freebet="isFreebet"
          @open="setFocused"
          @close="returnFocus"
          @change="emitChange"
        />
        <select
          v-else
          ref="select"
          :class="$style['bet-slip-select__select']"
          @change="selectChange"
        >
          <option
            v-if="isFreebet && !isSingleValue"
            selected
            disabled
            value=""
          >
            {{ placeHolderText }}
          </option>
          <option
            v-for="option in options"
            :key="option.value"
            :value="option.value"
            :selected="option.value === selectedValue"
          >
            {{ getOptionLabel(option, isFreebet) }}
          </option>
        </select>
      </template>
      <div :class="$style['bet-slip-select__arrow-icon']">
        <VIcon
          v-bind="arrowIconProps"
        />
      </div>
    </template>
  </div>
</template>

<style lang="scss" module>
.bet-slip-select {
  @include regular\14\16\025;

  position: relative;
  display: flex;
  flex: 0;
  align-items: center;
  min-width: 100px;
  height: 44px;
  padding: 0 4px 0 12px;
  color: var(--LTextDefault);
  white-space: nowrap;
  background-color: var(--LLayer2);
  border-radius: var(--BorderRadius);

  &--freebet {
    min-width: 100%;
    padding-right: 32px;
  }

  &--single-value {
    padding-right: 32px;
    color: var(--LTextSecondary);
    cursor: default;
  }

  &:focus-within,
  &--focused {
    box-shadow: inset 0 0 0 1px var(--LBlue);
  }

  &__placeholder {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__display {
    display: flex;
    flex: 1;
    align-items: center;
    overflow: hidden;
  }

  &__label {
    flex: 1;
    overflow: hidden;
  }

  &__value {
    @include medium\18\20;

    flex: none;
    padding-left: 8px;
  }

  &__select {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: currentcolor;
    appearance: none;
    cursor: pointer;
    background-color: transparent;
    border: none;
    opacity: 0;

    &::-ms-expand {
      display: none;
    }
  }

  &__arrow-icon {
    position: absolute;
    top: 50%;
    right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    color: var(--LTextSecondary);
    pointer-events: none;
    transform: translateY(-50%);
  }

  &--single-value &__arrow-icon {
    right: 12px;
    color: var(--LHighlight);
  }
}
</style>
