import { RouteName } from '@leon-hub/routing-config-names';

import { AppModule } from 'web/src/modules/core/plugins/AppModule';
import type { AppVueRouter } from 'web/src/modules/core/services/router/types';

export class DevToolsModule extends AppModule {
  // eslint-disable-next-line class-methods-use-this
  addRoutes(router: AppVueRouter): void {
    router.addDefaultRoute({
      name: RouteName.DEVTOOLS,
      path: '/dev-tools',
      component: () => import('web/src/modules/dev-tools/pages/DevtoolsRoutePage/DevtoolsRoutePage.vue'),
    });
  }
}
