import type {
  SlipMappingKey,
  VSBetInfo,
  VSWidgetInstance,
  VirtualSportBetsChangedEvent,
  VirtualSportWidgetInitConfig,
} from 'web/src/modules/framed-app/components/VirtualSportFramedWidget/types';
import { VirtualSportWidgetBetsChangedContext } from 'web/src/modules/framed-app/components/VirtualSportFramedWidget/enums';

import { isOldTypeWidget } from './isOldTypeWidget';

interface InstanceOptions {
  sport: Exclude<VirtualSportWidgetInitConfig['sport'], undefined>;
  clientId: Exclude<VirtualSportWidgetInitConfig['clientId'], undefined>;
}

interface InstanceCallbacks {
  onLoad?: (instance: VSWidgetInstance) => void;
  onStateChanged?: () => void;
  onBetsChanged?: (state: VirtualSportBetsChangedEvent) => void;
}

export default class VirtualSportInstance {
  protected instance?: VSWidgetInstance;

  protected initWidgetTimeout?: number;

  currentInitProcess?: Promise<void>;

  currentInitProcessOptions?: InstanceOptions;

  loadedOptions?: InstanceOptions;

  constructor(
    protected container?: HTMLElement,
    protected callbacks?: InstanceCallbacks,
  ) {}

  async init({ sport, clientId }: InstanceOptions): Promise<void> {
    await this.destroy();

    if (window.vsmobile?.instance) {
      // eslint-disable-next-line no-console
      console.warn(`External VS widget ${window.vsmobile?.instance.options.sport} already initialized.`);
      // @TODO find case of instance may stay loaded after fast changed sports filter
      await VirtualSportInstance.destroy(window.vsmobile?.instance);
    }

    this.currentInitProcess = new Promise((resolve, reject) => {
      this.clearInitTimeout();
      this.initWidgetTimeout = window.setTimeout(() => {
        reject(new Error(`External widget init timeout error for sport: ${sport}`));
      }, 10_000);

      if (!this.container) {
        throw new Error('Widget container not found');
      }

      // eslint-disable-next-line no-console
      console.log(`Init VS ${sport} instance`);
      this.currentInitProcessOptions = { sport, clientId };
      window.vsmobile?.init(this.container, {
        sport,
        clientId,
        betSelectionMode: 'all',
        useBrowserHistoryAPI: false,
        displayMode: 'inline',
        topHeaderHeight: 0,
        showUnsupportedDeviceWarning: false,
        onLoad: (instance: VSWidgetInstance) => {
          this.clearInitTimeout();
          this.emitLoad(instance);
          this.instance = instance;
          this.loadedOptions = { sport, clientId };
          this.emitStateChanged();
          this.currentInitProcess = undefined;
          this.currentInitProcessOptions = undefined;
          // eslint-disable-next-line no-console
          console.log(`Init VS ${sport} instance completed`);
          resolve();
        },
        setEvents: this.emitStateChanged.bind(this),
        setStatus: this.emitStateChanged.bind(this),
        betsChanged: this.emitBetsChanged.bind(this),
      });
    });

    return this.currentInitProcess;
  }

  destroy(): Promise<void> {
    this.clearInitTimeout();
    return this.instance
      ? new Promise((resolve) => {
        void VirtualSportInstance.destroy(this.instance).then(() => {
          this.instance = undefined;
          this.loadedOptions = undefined;
          resolve();
        });
      })
      : Promise.resolve();
  }

  isLoaded(): boolean {
    return this.instance?.loaded ?? false;
  }

  getInstance(): Optional<VSWidgetInstance> {
    return this.instance;
  }

  checkIsSelectedBets(mappings: SlipMappingKey[]): void {
    const selectedInSlipKeys = mappings.map((mapping) => mapping.selectionKey);
    const selectedInWidgetKeys = (this.instance?.getAllSelectedBets() ?? [])
      .map((bet) => bet.selectionKey);
    const addToWidget = mappings
      .filter((mapping) => !selectedInWidgetKeys.includes(mapping.selectionKey));
    const removeFromWidget = selectedInWidgetKeys.filter((key) => !selectedInSlipKeys.includes(key));

    if (addToWidget.length > 0) {
      this.instance?.selectBets(mappings);
    }

    for (const key of removeFromWidget) {
      this.instance?.deselectBet(key);
    }
  }

  clearBet(mapping: SlipMappingKey): void {
    this.instance?.deselectBet(mapping.selectionKey);
  }

  clearAllBets(): void {
    this.instance?.clearAllSelectedBets();
  }

  protected clearInitTimeout(): void {
    if (this.initWidgetTimeout) {
      window.clearTimeout(this.initWidgetTimeout);
      this.initWidgetTimeout = undefined;
    }
  }

  protected emitLoad(instance: VSWidgetInstance): void {
    this.callbacks?.onLoad?.(instance);
  }

  protected emitStateChanged(): void {
    this.callbacks?.onStateChanged?.();
  }

  protected emitBetsChanged(
    instance: VSWidgetInstance,
    currentBets: VSBetInfo[],
    betsAdded: (VSBetInfo | SlipMappingKey)[],
    betsRemoved: (VSBetInfo | SlipMappingKey)[],
    context: VirtualSportWidgetBetsChangedContext,
  ): void {
    if (isOldTypeWidget(this.loadedOptions?.sport)) {
      const adapterAdded = this.instance?.bwgAdapter?.ticketlines.added ?? {};
      const added = betsAdded.map((bet) => {
        const competitors = adapterAdded[bet.selectionKey]?.metaData.tournament.event.competitors;
        return {
          ...bet,
          competitors,
        };
      });

      this.callbacks?.onBetsChanged?.({
        added,
        removed: [], // TennisInplay has not selected bets
      });
      return;
    }

    if (context === VirtualSportWidgetBetsChangedContext.Clicked) {
      this.callbacks?.onBetsChanged?.({
        added: betsAdded,
        removed: betsRemoved,
      });
    }
  }

  static destroy(instance: Optional<Maybe<VSWidgetInstance>> = window.vsmobile?.instance): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!instance) {
        resolve();
        return;
      }

      // @TODO find case of instance may exist after destroy (loaded = false)
      if (!instance.loaded) {
        // eslint-disable-next-line no-console
        console.warn(`Try to destroy unloaded ${instance.options.sport} instance`);
      }

      try {
        // eslint-disable-next-line no-console
        console.log(`Destroy VS ${instance.options.sport} instance`);

        // @see LEONWEB-7997 TennisInplay don't call destroy in case of partial loaded content
        let destroyTimeout = window.setTimeout(() => {
          destroyTimeout = 0;
          document.location.reload();
          reject(new Error(`External widget ${instance.options.sport} destroy timeout error`));
        }, 3000);

        instance.destroy(() => {
          if (!destroyTimeout) {
            reject(new Error(`Timeout exception for widget ${instance.options.sport} destroy`));
            return;
          }

          // eslint-disable-next-line no-console
          console.log(`Destroy VS ${instance.options.sport} instance completed`);
          window.clearTimeout(destroyTimeout);
          resolve();
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Catch external error during destroy', error);
        reject(error);
      }
    });
  }
}
