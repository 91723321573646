import {
  isString,
  isBoolean,
  isValidInterface,
  isObjectValues, isOptionalNumber,
} from '@leon-hub/guards';

import type BuiltinConfig from '../types/BuiltinConfig';

export default function isBuiltinConfig(target: unknown): target is BuiltinConfig {
  return typeof target === 'undefined' || isValidInterface(target, {
    isNT: isBoolean,
    isQueryHashingEnabled: isBoolean,
    time: isOptionalNumber,
    docParseTime: isOptionalNumber,
    theme: (value: unknown): value is BuiltinConfig['theme'] => isObjectValues([isString], value),
    adPaths: (value: unknown): value is BuiltinConfig['adPaths'] => isObjectValues([isString], value),
  });
}
