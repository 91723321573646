<script lang="ts" setup>
import { computed } from 'vue';

import { IconName, IconSize } from '@leon-hub/icons';

import {
  ButtonHeight,
  ButtonKind,
} from 'web/src/components/Button/VButton/enums';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import useAppDownload from 'web/src/modules/app-download/composables/useAppDownload';
import { MobileAppOs } from 'web/src/modules/app-download/enums';
import { useAnalytics } from 'web/src/modules/analytics/composables';

import type { AppDownloadButtonsProps } from './types';

defineProps<AppDownloadButtonsProps>();

const analytics = useAnalytics();

const {
  isAppDownloadEnabled,
  onAppDownload,
} = useAppDownload();

function onDownloadAndroid(): void {
  onAppDownload(MobileAppOs.Android);
  analytics.clickMap({ downloadMobileAppIcons: MobileAppOs.Android });
}

function onDownloadIos(): void {
  onAppDownload(MobileAppOs.Ios);
  analytics.clickMap({ downloadMobileAppIcons: MobileAppOs.Ios });
}

const isIosDownloadEnabled = computed(() => isAppDownloadEnabled(MobileAppOs.Ios).value);
const isAndroidDownloadEnabled = computed(() => isAppDownloadEnabled(MobileAppOs.Android).value);
</script>

<template>
  <div v-auto-id="'AppDownloadButtons'"
    class="app-buttons"
    :class="{
      [$style['app-buttons']]: true,
      [$style['app-buttons--all']]: isIosDownloadEnabled && isAndroidDownloadEnabled,
    }"
  >
    <div :class="$style['app-buttons__buttons']">
      <VButton
        v-if="isIosDownloadEnabled"
        :kind="ButtonKind.BASE"
        :height="ButtonHeight.LARGE"
        :icon-size="IconSize.SIZE_24"
        :icon-name="IconName.OS_IOS"
        :class="$style['app-buttons__item']"
        @click="onDownloadIos"
      />
      <VButton
        v-if="isAndroidDownloadEnabled"
        :kind="ButtonKind.BASE"
        :height="ButtonHeight.LARGE"
        :icon-size="IconSize.SIZE_24"
        :icon-name="IconName.OS_ANDROID"
        :class="$style['app-buttons__item']"
        @click="onDownloadAndroid"
      />
    </div>
    <div
      v-if="text"
      :class="$style['app-buttons__text']"
    >
      {{ text }}
    </div>
  </div>
</template>

<style lang="scss" module>
.app-buttons {
  $self: &;

  display: flex;

  &--all {
    flex-direction: column;
  }

  &__buttons {
    display: flex;
  }

  &__item {
    width: 64px;

    &:first-child {
      margin-right: 8px;
    }
  }

  &__text {
    @include regular\14\20\025;

    display: flex;
    align-items: center;
    color: var(--TextPrimary);

    #{$self}--all & {
      margin-top: 8px;
    }
  }
}
</style>
