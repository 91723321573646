import type {
  GetApiSettingsReturnType,
} from '@leon-hub/api-sdk';
import {
  getApiSettings,
} from '@leon-hub/api-sdk';

import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';

export function fetchApiSettings(): Promise<GetApiSettingsReturnType> {
  return getApiSettings(useGraphqlClient(),
    (node) => node.queries.system.apiSettings.getApiSettings,
    undefined,
    {
      silent: true,
    });
}
