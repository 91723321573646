<script lang="ts" setup>
import { onMounted } from 'vue';

import { ApiConnectionError } from '@leon-hub/api';

import VEmpty from 'web/src/components/Empty/components/VEmpty';

interface ComponentLoadErrorEmits {
  (e: 'load-error', error: unknown): void;
  (e: 'loaded'): void;
}

const emit = defineEmits<ComponentLoadErrorEmits>();

onMounted(() => {
  emit('load-error', new ApiConnectionError({
    message: 'Failed to load async component',
  }));
  emit('loaded');
});
</script>

<template>
  <VEmpty v-auto-id="'ComponentLoadError'" />
</template>
