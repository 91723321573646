import { ComponentKey, ComponentStatus } from '../types';
import type { ComponentResult } from '../types';
import AbstractFingerprintComponent from '../AbstractFingerprintComponent';

declare global {
  interface Window {
    doNotTrack: unknown;
  }
}

export default class DoNotTrackComponent extends AbstractFingerprintComponent {
  constructor() {
    super(ComponentKey.DoNotTrack);
  }

  getComponentResult(): Promise<ComponentResult> {
    return this.result([window.navigator.doNotTrack || window.doNotTrack || ComponentStatus.NotAvailable]);
  }
}
