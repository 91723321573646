import type { NavigationGuardNext, RouteLocationNamedRaw, RouteLocationNormalized } from 'vue-router';

import {
  assert,
  isOptionalString,
} from '@leon-hub/guards';
import { RouteName } from '@leon-hub/routing-config-names';
import { Deferred } from '@leon-hub/utils';

import {
  useDesktopModalStore,
} from 'web/src/modules/core/store';
import type { DesktopModal } from 'web/src/modules/core/types';
import type { ModalDesktopPreset } from 'web/src/modules/core/enums';

import ModalOpenedRouteError from '../errors/ModalOpenedRouteError';
import type { AppVueRouter } from '../types';
import prefetchRouteComponents from './prefetchRouteComponents';
import processAfterRoute from './history-state/processAfterRoute';

let modalToOpen: RouteLocationNormalized | undefined;

function createBeforeEach(router: AppVueRouter): void {
  router.beforeEach(async (to, from, next) => {
    const { modalPreset } = to.meta;
    assert(isOptionalString(modalPreset));

    const desktopModalStore = useDesktopModalStore();
    const { setDesktopModal } = desktopModalStore;

    if (desktopModalStore.desktopModal && !modalPreset) {
      setDesktopModal(undefined);
      if (!router.isPopStateDetected()) {
        await router.closeModal();
        next(new ModalOpenedRouteError());
        if (from.fullPath !== to.fullPath) {
          void router.push(to);
        }
        return;
      }
    }

    if (modalPreset) {
      if (!from.name) {
        // direct link to modal
        router.next(next, {
          name: RouteName.HOME,
          replace: true,
        });

        modalToOpen = to;
        return;
      }

      const deferred = new Deferred<RouteLocationNamedRaw | undefined>();

      const modalsNext = ((route: RouteLocationNamedRaw | undefined) => {
        deferred.resolve(route);
      }) as unknown as NavigationGuardNext;

      await prefetchRouteComponents(
        to,
        from,
        modalsNext,
        router,
      );

      const resolvedPromise = await deferred.promise;

      if (resolvedPromise && resolvedPromise.name !== to.name) {
        router.next(next, resolvedPromise);
        return;
      }

      processAfterRoute(to, from, router);

      setDesktopModal({
        ...(to.meta.desktopModal as Pick<DesktopModal, 'default' | 'topBar' | 'isProfile' | 'isNoMinHeightLimit'>),
        preset: modalPreset as ModalDesktopPreset,
        route: to,
      });

      next(new ModalOpenedRouteError());
      return;
    }

    next();
  });
}

function createAfterEach(router: AppVueRouter): void {
  router.afterEach(() => {
    if (modalToOpen) {
      void router.push({
        name: String(modalToOpen.name),
        params: modalToOpen.params,
        query: modalToOpen.query,
      });
      modalToOpen = undefined;
    }
  });
}

export default function createDesktopModalNavigationGuard(
  router: AppVueRouter,
): void {
  if (!process.env.VUE_APP_LAYOUT_DESKTOP) {
    throw new Error('Only for DESKTOP layout');
  }

  createBeforeEach(router);
  createAfterEach(router);
}
