<script setup lang="ts">
import type { PromotionCashbackHistoryProps } from 'web/src/modules/promotions/components/PromotionCashbackHistory/types';
import usePromotionCashbackHistory
  from 'web/src/modules/promotions/components/PromotionCashbackHistory/composables/usePromotionCashbackHistory';
import SBadge from 'web/src/components/Badge/SBadge/SBadge.vue';

const props = defineProps<PromotionCashbackHistoryProps>();

const {
  formattedEndDate,
  isCurrentCashback,
  formattedItemCashbackAmount,
  currentCashbackFormatted,
} = usePromotionCashbackHistory(props);
</script>

<template>
  <div v-auto-id="'PromotionCashbackHistory'" :class="$style['promotion-cashback-history']">
    <h3 :class="$style['promotion-cashback-history__title']">
      {{ $t('WEB2_PROMOTION_CASHBACK_TITLE') }}
    </h3>
    <ul :class="$style['promotion-cashback-history__list']">
      <li
        v-for="(item, index) in list"
        :key="index"
        :class="{
          [$style['promotion-cashback-history__item']]: true,
          [$style['promotion-cashback-history__item--active']]: isCurrentCashback(item.roundEndDate),
        }"
      >
        <div :class="$style['promotion-cashback-history__left']">
          <p
            v-if="isCurrentCashback(item.roundEndDate)"
            :class="$style['promotion-cashback-history__label']"
          >
            {{ $t('WEB2_CURRENT_CASHBACK') }}
          </p>
          <span
            v-if="isCurrentCashback(item.roundEndDate)"
            :class="$style['promotion-cashback-history__add']"
          >{{ $t('WEB2_CASHBACK_ADD') }}</span>
          <span :class="$style['promotion-cashback-history__date']"> {{ formattedEndDate(item.roundEndDate) }}</span>
        </div>
        <SBadge
          v-if="null"
          :label="formattedItemCashbackAmount(item.cashbackAmount)"
          type="brand"
          size="large"
        />
        <div
          v-else
          :class="$style['promotion-cashback-history__amount']"
        >
          <template v-if="isCurrentCashback(item.roundEndDate)">
            {{ currentCashbackFormatted }}
          </template>
          <template v-else>
            {{ formattedItemCashbackAmount(item.cashbackAmount) }}
          </template>
        </div>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" module>
.promotion-cashback-history {
  @include promotionCashbackHistoryStyles;

  &__title {
    @include promotionCashbackHistoryTitle;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: $promotionCashbackHistoryListGap;
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__item {
    @include promotionCashbackHistoryListItemStyles;

    display: flex;
    align-items: center;
    justify-content: space-between;

    &--active {
      background-color: $promotionCashbackHistoryListItemBackgroundActive;

      & .promotion-cashback-history__date {
        @include medium\12\16;

        color: var(--TextPrimary);
      }
    }
  }

  &__amount {
    @include medium\14\16\025;

    color: var(--BrandDefault);
  }

  &__label {
    @include medium\14\16\025;

    margin: 0 0 4px;
    color: var(--TextDefault);
  }

  &__add {
    @include regular\12\16;

    color: var(--TextPrimary);
  }

  &__date {
    @include promotionCashbackHistoryDate;
  }
}
</style>
