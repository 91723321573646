import type { Ref } from 'vue';
import { toRef } from 'vue';

import { useI18nStore } from 'web/src/modules/i18n/store';

export interface UseI18nLocaleComposable {
  locale: Ref<string>;
  lang: Ref<string>;
}

export default function useI18nLocale(): UseI18nLocaleComposable {
  const store = useI18nStore();

  return {
    locale: toRef(store, 'locale'),
    lang: toRef(store, 'lang'),
  };
}
