<script lang="ts" setup>
import { IconName, IconSize } from '@leon-hub/icons';

import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import SportlineEventDetailsToolbarContent
  from 'web/src/modules/sportline/submodules/event-details/components/toolbar/SportlineEventDetailsToolbarContent.vue';

import { useSportlineEventDetailsToolbar } from './useSportlineEventDetailsToolbar';
import type { SportEventDetailsToolbarProps } from './types';

defineProps<SportEventDetailsToolbarProps>();

const {
  matchTitle,
  isFavorite,
  canFavorite,
  isSportlineEventExist,
  emitChangeFavoriteState,
  onGoBackClick,
} = useSportlineEventDetailsToolbar();
</script>

<template>
  <SportlineEventDetailsToolbarContent v-auto-id="'SportEventDetailsToolbarDesktop'"
    :name="matchTitle"
    :is-favorite="isFavorite"
    :has-favorite-button="isSportlineEventExist && canFavorite"
    :has-toggle-button="hasRelatedEvents"
    @toggle-favorite="emitChangeFavoriteState"
  >
    <template #backButton>
      <VButton
        class="sport-event-toolbar__back"
        :icon-name="IconName.ARROW_LEFT"
        :icon-size="IconSize.SIZE_24"
        :kind="ButtonKind.TRANSPARENT"
        @click="onGoBackClick"
      />
    </template>
    <template #relatedList="{ hide }">
      <slot
        name="relatedList"
        :hide="hide"
      />
    </template>
  </SportlineEventDetailsToolbarContent>
</template>
