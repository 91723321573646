import { RouteName } from '@leon-hub/routing-config-names';
import { assert } from '@leon-hub/guards';
import CustomerRouteAccessRole, { isCustomerRouteAccessRole } from '@leon-hub/routing-config/src/types/CustomerRouteAccessRole';

import type { AppVueRouter } from 'web/src/modules/core/services/router/types';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';

import { isRouteAllowed } from '../utils';

function isOptionalAccess(value: unknown): value is CustomerRouteAccessRole | undefined {
  return !value || isCustomerRouteAccessRole(value);
}

export default function createAccessRestrictionsNavigationGuard(router: AppVueRouter): void {
  router.beforeEach((to, from, next) => {
    if (!to.name) {
      router.next(next, {
        name: RouteName.ERROR_NOT_FOUND_404,
      });
      return;
    }

    const { access } = to.meta;
    const { access: fromAccess } = from.meta;
    assert(isOptionalAccess(access));
    assert(isOptionalAccess(fromAccess));

    if (access === CustomerRouteAccessRole.FORBIDDEN) {
      router.next(next, {
        name: RouteName.ERROR_FORBIDDEN_403,
      });
      return;
    }

    const { isLoggedIn } = useIsLoggedIn();

    if (!router.isModalReplaced() && !isRouteAllowed(access, isLoggedIn.value)) {
      if (!isLoggedIn.value) {
        router.next(next, {
          name: RouteName.LOGIN,
          state: {
            toRoute: to.fullPath,
          },
        });
      } else {
        router.next(next, {
          name: RouteName.HOME,
        });
      }
      return;
    }

    next();
  });
}
