/* eslint-disable @typescript-eslint/return-await */
import {
  isObject,
  isOptionalNumber,
  isString,
} from '@leon-hub/guards';
import {
  Json,
  mergeQueries,
} from '@leon-hub/utils';
import { getBootstrapTranslations } from '@leon-hub/bootstrap-translations';
import { normalizeError } from '@leon-hub/errors';
import { logger } from '@leon-hub/logging';
import { doFetch } from '@leon-hub/fetch-client';
import { RequestOptionsPriority } from '@leon-hub/api-sdk';

import { ApiError } from '../errors/ApiError';
import { ApiServiceUnavailableError } from '../errors/ApiServiceUnavailableError';
import { ApiIpBlockedError } from '../errors/ApiIpBlockedError';
import { ApiTechnicalError } from '../errors/ApiTechnicalError';
import { ApiErrorCode } from '../errors/ApiErrorCode';
import { ApiConnectionError } from '../errors/ApiConnectionError';
import { ApiRequestAbortedError } from '../errors/ApiRequestAbortedError';
import BaseClient from '../BaseClient';
import type {
  BaseClientOptions,
  RestClientRequestOptions,
} from '../types';

interface ResponseApiError {
  message: string;
  errorCode: string;
  code?: number;
}

function isResponseApiError(value: unknown): value is ResponseApiError {
  return isObject(value)
    && isString(value.message)
    && isString(value.errorCode)
    && isOptionalNumber(value.code);
}

export default abstract class RestClient extends BaseClient {
  public constructor(options: BaseClientOptions) {
    super(options);
  }

  private getRequestInit(data?: RestClientRequestOptions['data'],
    method?: string,
    headers?: Record<string, string>,
    notAddBaseHeaders?: boolean,
    priority?: RequestOptionsPriority): RequestInit {
    let requestPriority: RequestPriority = 'auto';
    if (priority === RequestOptionsPriority.LOW) {
      requestPriority = 'low';
    } else if (priority === RequestOptionsPriority.HIGH) {
      requestPriority = 'high';
    }

    const requestInit: RequestInit = {
      headers: notAddBaseHeaders
        ? (headers ?? {})
        : { ...this.getHeaders(), ...this.getCustomHeader(), ...headers },
      method: method ?? this.getDefaultMethod(),
      credentials: this.getCredentials(),
      priority: requestPriority,
    };

    if (data) {
      const body = data instanceof FormData || data instanceof URLSearchParams ? data : JSON.stringify(data);

      if (requestInit.method !== 'GET' && body) {
        requestInit.body = body;
      }
    }

    return requestInit;
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  public async request<T>({
    endpoint,
    guard,
    guardError,
    data,
    query,
    abortController,
    method,
    silent,

    requestTimeout,
    headers,
    notAddBaseHeaders,
    cacheTTL = this.defaultCacheTTL,
  }: RestClientRequestOptions): Promise<T> {
    const queryIndex = endpoint.indexOf('?');
    let fullQuery = '';
    let path = endpoint;
    if (queryIndex > -1) {
      path = endpoint.slice(0, queryIndex);
      fullQuery = endpoint.slice(queryIndex);
    }
    if (typeof query === 'string' || (typeof query === 'object' && query !== null)) {
      fullQuery = mergeQueries(fullQuery, query);
    }

    const requestInit = this.getRequestInit(data, method, headers, notAddBaseHeaders);

    const url = `${this.getOrigin()}${this.getBaseUrl()}${path}${fullQuery}`;

    if (cacheTTL) {
      const cacheKey = JSON.stringify([url, requestInit]);
      const cacheItem = await this.getCache(cacheKey);
      if (cacheItem) return cacheItem as T;
    }

    try {
      const response = await doFetch(
        url,
        requestInit,
        requestTimeout,
        (originalError, request) => new ApiConnectionError({ originalError, silent, request }),
        abortController,
      );

      if (response.ok) {
        const contentType = response.headers.get('content-type');

        let result: T;

        if (contentType?.startsWith('text/plain')) {
          result = (await response.text()) as T;
        } else {
          result = (await response.json()) as T;
        }
        const apiError = this.handleApiError(result, `${path}${fullQuery}`, Boolean(silent));
        if (apiError) {
          // eslint-disable-next-line unicorn/no-useless-promise-resolve-reject
          return Promise.reject(apiError);
        }

        if (guard(result)) {
          if (cacheTTL) {
            const cacheKey = JSON.stringify([url, requestInit]);
            void this.setCache(cacheKey, result, cacheTTL);
          }
          return result;
        }

        if (!guardError?.(result)) {
          logger.error(`Response guard failed: url=${url}; response=${Json.stringify(
            result, { defaultValue: 'Unable to parse response' },
          )}`);
        }

        // eslint-disable-next-line unicorn/no-useless-promise-resolve-reject
        return Promise.reject(new ApiTechnicalError({ silent }));
      }

      if (response.status === 502 || response.status === 503) {
        // eslint-disable-next-line unicorn/no-useless-promise-resolve-reject
        return Promise.reject(new ApiServiceUnavailableError({ silent }));
      }

      const badResult = await response.json() as { message?: string; errorCode?: string; code?: number };
      const apiError = this.handleApiError(badResult, `${path}${fullQuery}`, Boolean(silent));
      if (apiError) {
        // eslint-disable-next-line unicorn/no-useless-promise-resolve-reject
        return Promise.reject(apiError);
      }

      // In some cases message is empty
      if (badResult.message || badResult.errorCode) {
        // eslint-disable-next-line unicorn/no-useless-promise-resolve-reject
        return Promise.reject(
          new ApiError({
            message: badResult.message || '',
            code: new ApiErrorCode(badResult.errorCode || ApiErrorCode.API_UNEXPECTED_ERROR.toString()),
            silent,
          }),
        );
      }

      // eslint-disable-next-line unicorn/no-useless-promise-resolve-reject
      return Promise.reject(new ApiConnectionError({ silent, response }));
    } catch (rawError) {
      // eslint-disable-next-line unicorn/no-useless-promise-resolve-reject
      return Promise.reject(
        abortController?.signal.aborted
          ? new ApiRequestAbortedError({
            silent,
            operationName: url,
            originalError: normalizeError(rawError),
          })
          : new ApiConnectionError({
            silent,
            operationName: url,
            originalError: normalizeError(rawError),
          }),
      );
    }
  }

  // eslint-disable-next-line rulesdir/class-method-use-this-regex,class-methods-use-this
  handleApiError(result: unknown, path: string, silent: boolean): ApiError | null {
    if (isResponseApiError(result)) {
      if (ApiErrorCode.API_IP_BLOCKED_ERROR.equals(result.errorCode)) {
        return new ApiIpBlockedError();
      }

      if (result.code === 502 || result.code === 503) {
        return new ApiServiceUnavailableError();
      }

      return result.errorCode
        ? new ApiError({
          message: result.message || getBootstrapTranslations().WEB2_CONNECTION_ERROR_DESCRIPTION,
          code: new ApiErrorCode(result.errorCode),
          responseCode: result.code,
          operationName: path,
          silent,
        })
        : new ApiTechnicalError({ silent });
    }

    return null;
  }
}
