export enum CurrenciesSlottIconName {
  SLOTT_COIN_BNB = 'slott-coin-bnb',
  SLOTT_COIN_BONUS = 'slott-coin-bonus',
  SLOTT_COIN_BTC = 'slott-coin-btc',
  SLOTT_COIN_ETH = 'slott-coin-eth',
  SLOTT_COIN_EUR = 'slott-coin-eur',
  SLOTT_COIN_LTC = 'slott-coin-ltc',
  SLOTT_COIN_RUB = 'slott-coin-rub',
  SLOTT_COIN_TRX = 'slott-coin-trx',
  SLOTT_COIN_USD = 'slott-coin-usd',
  SLOTT_COIN_USDC = 'slott-coin-usdc',
  SLOTT_COIN_USDT = 'slott-coin-usdt',
}
