import type { BonusMatrixRequest, BonusMatrixResponse } from '@leon-hub/api-sdk';
import {
  getBonusMatrix as getSdkBonusMatrix,
} from '@leon-hub/api-sdk';

import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';

export default function fetchSdkBonusMatrix({
  bonusId,
  bonusCampaignType,
}: BonusMatrixRequest): Promise<BonusMatrixResponse> {
  const apiClient = useGraphqlClient();

  return getSdkBonusMatrix(
    apiClient,
    (node) => node.queries.bonuses.getBonusMatrix,
    {
      options: {
        bonusId,
        bonusCampaignType,
      },
    },
  );
}
