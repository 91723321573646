<script lang="ts" setup>
import VCmsContent from 'web/src/modules/cms/components/VCmsContent/VCmsContent.vue';
import VHintBlock from 'web/src/components/HintBlock/VHintBlock/VHintBlock.vue';
import VSmsNumberCheck from 'web/src/components/SmsNumberCheck/VSmsNumberCheck/VSmsNumberCheck.vue';
import {
  usePhoneCheckStage,
} from 'web/src/modules/registration/submodules/simplified-registration/components/Stages/composables/usePhoneCheckStage';
import type {
  RegistrationPhoneCheckStageEmits,
} from 'web/src/modules/registration/submodules/simplified-registration/components/Stages/types';

const emits = defineEmits<RegistrationPhoneCheckStageEmits>();

const {
  schema,
  uiSchema,
  isFormPending,
  submittedStarredPhone,
  resendSmsTimeOut,
  customErrors,
  suggestedEmailRegistrationTimeout,
  isPhoneCheckByCall,
  emitCheckSmsCode,
  resendPhoneCodeByType,
  setRetrySmsCodeTime,
  changeShowHintBlock,
  isShowHintBlockVisible,
  emitInput,
  goToChangePhone,
  goToEmailRegistration,
  goToPhoneCheckBySmsStage,
} = usePhoneCheckStage(emits);
</script>

<template>
  <div v-auto-id="'RegistrationPhoneCheck'" :class="$style['registration-phone-check']">
    <VSmsNumberCheck
      :schema="schema"
      :ui-schema="uiSchema"
      :is-pending="isFormPending"
      :phone="submittedStarredPhone"
      :time-left="resendSmsTimeOut"
      is-timer
      :custom-errors="customErrors"
      :hint-timeout="suggestedEmailRegistrationTimeout"
      :is-new-tel="isPhoneCheckByCall"
      @submit="emitCheckSmsCode"
      @retry-button-click="resendPhoneCodeByType"
      @set-retry-sms-code-time="setRetrySmsCodeTime"
      @show-hint-with-email-redirect="changeShowHintBlock"
      @input="emitInput"
    >
      <template #phone>
        (<span
          :class="$style['registration-phone-check__pseudo-link']"
          @click="goToChangePhone"
        >
          <span :class="$style['registration-phone-check__link']">{{ $t('WEB2_REGISTRATION_PHONE_CHANGE') }}</span>
        </span>)
      </template>
    </VSmsNumberCheck>
    <VHintBlock
      v-if="isShowHintBlockVisible"
      :class="$style['registration-phone-check__hint-block']"
    >
      <VCmsContent
        :cms-key="isPhoneCheckByCall ? 'WEB2_PHONE_CHECK_BY_CALL_HINT_BLOCK_AFTER_TIMEOUT' : 'WEB2_PHONE_CHECK_HINT_BLOCK_AFTER_TIMEOUT'"
        silent
        no-padding
        :class="$style['registration-phone-check__hint-block-content']"
        @registration-phone-check-go-to-email-registration="goToEmailRegistration"
        @registration-phone-check-go-to-phone-check-by-sms="goToPhoneCheckBySmsStage"
      />
    </VHintBlock>
  </div>
</template>

<style lang="scss" module>
.registration-phone-check {
  padding: 0 16px;

  &__hint-block {
    margin-top: 16px;
    cursor: default;
  }

  &__hint-block-content {
    margin-left: 10px;
  }

  &__pseudo-link {
    text-decoration: underline;
    white-space: nowrap;
    cursor: pointer;
  }

  &__check-sms-code {
    height: 24px;
    margin-bottom: 20px;
    color: var(--Layer1);
    text-decoration: underline;
    letter-spacing: normal;
  }

  @include is-app-layout-desktop {
    padding: 0 32px 32px;

    &__check-sms-code {
      &:hover {
        text-decoration: none;
      }
    }

    &__pseudo-link {
      &:hover {
        text-decoration: none;
      }
    }
  }
}
</style>
