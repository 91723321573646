<script setup lang="ts">
import { IconSize, SportIconName } from '@leon-hub/icons';
import { bannerVersionV3 } from '@leon-hub/api-sdk';

import { VIcon } from '@components/v-icon';

import PrimaryMarket from 'web/src/modules/sportline/views/primary-market/PrimaryMarket.vue';
import { useSportlineEventDateTime } from 'web/src/modules/sportline/composables/sportline-event';
import InfoCountdown
  from 'web/src/modules/sportline/submodules/event-details/components/info-countdown/InfoCountdown.vue';
import { useLiveMatchProgress } from 'web/src/modules/sportline/composables/match-progress';
import { useLiveStatus } from 'web/src/modules/sportline/composables/live-progress';
import HeadlineLiveTimeInfo
  from 'web/src/modules/sportline/submodules/event-details/views/headline-info/default/HeadlineLiveTimeInfo.vue';

import { useSportEventInfo } from '../composables/useSportEventInfo';
import type { SportEventInfoProps } from '../types';

const props = defineProps<SportEventInfoProps>();

const {
  sportlineEvent,
  market,
} = useSportEventInfo(props);

const {
  matchProgress,
} = useLiveMatchProgress({ sportEvent: sportlineEvent });
const { stage } = useLiveStatus(matchProgress);

const { kickoff } = useSportlineEventDateTime({ sportlineEvent });
</script>

<template>
  <div v-auto-id="'SportEventInfo'"
    v-if="sportlineEvent"
    :class="{
      [$style['headline-banner-sport']]: true,
      [$style['headline-banner-sport--v3']]:
        '1'
        && props.bannerVersion === bannerVersionV3,
    }"
  >
    <template
      v-if="
        null
          && (null || props.bannerVersion !== bannerVersionV3)
      "
    >
      <div :class="$style['headline-banner-sport__header']">
        <div
          :class="[
            $style['headline-banner-sport__competitor'],
            $style['headline-banner-sport__competitor--left']
          ]"
        >
          <span :class="$style['headline-banner-sport__competitor-text']">
            {{ sportlineEvent.competitors[0]?.name }}
          </span>
        </div>
        <span
          :class="$style['headline-banner-sport__competitor-corner']"
          role="presentation"
        />
        <div
          :class="[
            $style['headline-banner-sport__competitor'],
            $style['headline-banner-sport__competitor--right']
          ]"
        >
          <span :class="$style['headline-banner-sport__competitor-text']">
            {{ sportlineEvent.competitors[1]?.name }}
          </span>
        </div>
      </div>
    </template>
    <div
      v-if="'1' && props.bannerVersion === bannerVersionV3"
      :class="$style['headline-banner-sport__header']"
    >
      <div :class="$style['headline-banner-sport__competitor-text']">
        {{ sportlineEvent.competitors[0]?.name }} - {{ sportlineEvent.competitors[1]?.name }}
      </div>
    </div>
    <div
      :class="{
        [$style['headline-banner-sport__content']]: true,
        [$style['headline-banner-sport__content--v3']]:
          '1'
          && props.bannerVersion === bannerVersionV3,
      }"
    >
      <div
        v-if="matchProgress || kickoff"
        :class="$style['headline-banner-sport__details']"
      >
        <template v-if="matchProgress">
          <div :class="$style['headline-banner-sport__score']">
            {{ matchProgress.totalScore.host }}<span :class="$style['headline-banner-sport__divider']">:</span>{{ matchProgress.totalScore.guest }}
          </div>
          <div :class="$style['headline-banner-sport__stage']">
            {{ stage }} <HeadlineLiveTimeInfo :sportline-event="sportlineEvent" />
          </div>
        </template>
        <InfoCountdown
          v-else
          :class="$style['headline-banner-sport__countdown']"
          :kickoff="kickoff"
          :is-narrow="'1' && props.bannerVersion === bannerVersionV3"
        />
      </div>
      <div
        v-if="
          (null || props.bannerVersion !== bannerVersionV3)
            && sportlineEvent.hasZeroMargin
        "
        :class="{
          [$style['headline-banner-sport__special']]: true,
          [$style['headline-banner-sport__special--offer']]: !!matchProgress,
          [$style['headline-banner-sport__special--highest-odds']]: !matchProgress,
        }"
      >
        <template v-if="matchProgress">
          <span>{{ $t('WEB2_SPORT_BANNER_SPECIAL_OFFER') }}</span>
        </template>
        <template v-else>
          <VIcon
            :name="SportIconName.SPORTLINE_HIGHEST_ODDS"
            :size="IconSize.SIZE_10"
            :class="$style['headline-banner-sport__special-icon']"
          /><span>{{ $t('WEB2_SPORT_BANNER_HIGHEST_ODDS') }}</span>
        </template>
      </div>
      <div :class="$style['headline-banner-sport__footer']">
        <template
          v-if="
            '1'
              && (null || props.bannerVersion !== bannerVersionV3)
          "
        >
          <div
            :class="[
              $style['headline-banner-sport__competitor'],
              $style['headline-banner-sport__competitor--left']
            ]"
          >
            <span :class="$style['headline-banner-sport__competitor-text']">
              {{ sportlineEvent.competitors[0]?.name }}
            </span>
          </div>
        </template>
        <div :class="$style['headline-banner-sport__market']">
          <PrimaryMarket
            v-if="market"
            :market="market"
            :sport-event-details="sportlineEvent"
            double-gap
            :is-primary-market-v2="
              '1'
                && props.bannerVersion === bannerVersionV3
            "
            :class="$style['headline-banner-sport__primary-market']"
          />
        </div>
        <template
          v-if="
            '1'
              && (null || props.bannerVersion !== bannerVersionV3)
          "
        >
          <div
            :class="[
              $style['headline-banner-sport__competitor'],
              $style['headline-banner-sport__competitor--right']
            ]"
          >
            <span :class="$style['headline-banner-sport__competitor-text']">
              {{ sportlineEvent.competitors[1]?.name }}
            </span>
          </div>
        </template>
      </div>
      <div
        v-if="
          '1'
            && props.bannerVersion === bannerVersionV3
            && sportlineEvent.hasZeroMargin
        "
        :class="{
          [$style['headline-banner-sport__special']]: true,
          [$style['headline-banner-sport__special--offer']]: !!matchProgress,
          [$style['headline-banner-sport__special--highest-odds']]: !matchProgress,
        }"
      >
        <template v-if="matchProgress">
          <span>{{ $t('WEB2_SPORT_BANNER_SPECIAL_OFFER') }}</span>
        </template>
        <template v-else>
          <VIcon
            :name="SportIconName.SPORTLINE_HIGHEST_ODDS"
            :size="IconSize.SIZE_10"
            :class="$style['headline-banner-sport__special-icon']"
          /><span>{{ $t('WEB2_SPORT_BANNER_HIGHEST_ODDS') }}</span>
        </template>
      </div>
    </div>
  </div>
</template>

<style module lang="scss">
$backgroundColor: rgba(0, 0, 0, 0.5);
$backgroundTransparent: rgba(0, 0, 0, 0);
$headlineBannerSport: '.headline-banner-sport';

@include for-layout using($isDesktop) {
  .headline-banner-sport {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    color: var(--BrandText);

    @if $isDesktop {
      justify-content: flex-end;
      padding: 24px 12px;
    }

    @if not ($isDesktop) {
      justify-content: space-between;
      padding: 12px 8px 24px;
    }

    &__details {
      flex: 0 0 auto;
      padding: 8px 12px;
      background: $backgroundColor;
      border-radius: 8px;

      @if $isDesktop {
        margin: 8px;
      }
    }

    &__header {
      display: flex;
      flex: 0 0 auto;
      width: 100%;
      height: 48px;
      margin: if($isDesktop, 0 0 10px, 0 0 8px);
      overflow: hidden;
    }

    &__competitor {
      @include medium\16\20\025;

      display: flex;
      flex: 1;
      align-items: center;
      overflow: hidden;

      @if $isDesktop {
        padding: 0 24px;
        white-space: nowrap;
      }

      &--left {
        justify-content: flex-end;
        text-align: right;
        background: linear-gradient(90deg, $backgroundTransparent 0%, $backgroundColor 100%);

        @if not ($isDesktop) {
          padding-right: 8px;
        }
      }

      &--right {
        justify-content: flex-start;
        text-align: left;
        background: linear-gradient(90deg, $backgroundColor 0%, $backgroundTransparent 100%);

        @if not ($isDesktop) {
          padding-left: 8px;
        }
      }
    }

    &__competitor-corner {
      position: relative;
      flex: none;
      width: 16px;
      overflow: hidden;

      &:before,
      &:after {
        position: absolute;
        top: -15px;
        bottom: -15px;
        width: 8px;
        content: '';
        background: $backgroundColor;
        transform: skewX(-9deg);
      }

      &:before {
        left: -4px;
      }

      &:after {
        right: -4px;
      }
    }

    &__competitor-text {
      @if $isDesktop {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      @if not ($isDesktop) {
        // these webkit-prefixes also works fine in FF 🤯
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      @if not ($isDesktop) {
        gap: 4px;
        justify-content: space-between;
      }

      &--v3 {
        flex: 1 0 auto;
        justify-content: space-between;
        width: auto;
      }
    }

    &__stage {
      @include regular\13\16;

      text-align: center;
    }

    &__score {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 0 4px;
      font-size: 36px;
      font-weight: 700;
      line-height: 36px;
    }

    &__divider {
      flex-shrink: 0;
      margin: 0 4px;
      opacity: 0.6;
    }

    &__footer {
      display: flex;
      flex: 0 0 auto;
      justify-content: center;
      width: 100%;

      @if $isDesktop {
        margin: 22px 0 0;
        overflow: hidden;
      }

      @if not ($isDesktop) {
        margin: 0 -24px;
      }
    }

    &__market {
      flex: 0 0 auto;

      @if $isDesktop {
        flex: 0 0 auto;
        padding: 4px 0;
        background: $backgroundColor;
      }

      @if not $isDesktop {
        flex: 1;
      }
    }

    &__primary-market {
      display: block;
    }

    &__special {
      @include bold\9\12\02\caps;

      display: flex;
      align-items: center;
      margin: if($isDesktop, -4px 0 0, 0);
      font-family: 'Roboto Condensed', sans-serif;
      color: var(--BrandText);
      text-align: center;
      border-radius: 20px;

      &--offer {
        padding: 0 4px;
        background: var(--Blue);
      }

      &--highest-odds {
        display: inline-flex;
        align-items: center;
        padding: 0 4px 0 1px;
        color: var(--DAlertButtonText);
        background: var(--DBrandText);
      }
    }

    &__special-icon {
      margin: 0;
      fill: var(--ErrorHighlight);
    }

    &--v3 {
      @if not ($isDesktop) {
        padding: 16px;
      }

      @if $isDesktop {
        justify-content: space-between;
        padding: 16px;
      }

      #{$headlineBannerSport}__header {
        height: if($isDesktop, 48px, auto);
        margin: 0;
        overflow: if($isDesktop, hidden, visible);
      }

      #{$headlineBannerSport}__competitor-text {
        @include font-sport-event-title;
      }

      #{$headlineBannerSport}__content {
        position: absolute;
        bottom: 16px;
        left: 16px;
        gap: 8px;
        align-items: center;

        @if not $isDesktop {
          @media (min-width: 375px) {
            gap: 16px;
          }

          @media (min-width: 412px) {
            gap: 24px;
          }

          @media (min-width: 480px) {
            gap: 32px;
          }
        }

        @if $isDesktop {
          gap: 8px;
        }
      }

      #{$headlineBannerSport}__details {
        padding: 8px;

        @if $isDesktop {
          margin: 0;
        }
      }

      #{$headlineBannerSport}__footer {
        margin: 0;
      }

      #{$headlineBannerSport}__market {
        flex: 1 0 auto;

        @if $isDesktop {
          padding: 0;
          background: transparent;
        }
      }
    }
  }
}
</style>
