import type { AsyncComponentLoader } from 'vue';
import {
  defineAsyncComponent,
} from 'vue';

import ComponentLoadError from '../components/ComponentLoadError/ComponentLoadError.vue';
import importComponent from './importComponent';

export default function getAsyncComponent(
  loader: AsyncComponentLoader,
): ReturnType<typeof defineAsyncComponent> {
  return defineAsyncComponent({
    loader: () => importComponent(loader),
    errorComponent: ComponentLoadError,
    timeout: 30_000,
  });
}
