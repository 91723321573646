import type { RouteLocationNormalized, NavigationGuardNext, RouteLocationRaw } from 'vue-router';
import { toRef } from 'vue';

import { RouteName } from '@leon-hub/routing-config-names';

import { useRegionPageStore } from 'web/src/modules/sportline/submodules/region/store';
import { useSportlineBreadcrumbsStore } from 'web/src/modules/sportline/submodules/breadcrumbs/store';
import type { SportlinePageIdentifier as SportEventsPageIdentifier } from 'web/src/modules/sportline/types/navigation';
import { isValidSportEventsPageIdentifier } from 'web/src/modules/sportline/guards';
import { getSportPageNavigationParameters } from 'web/src/modules/sportline/utils';
import type { AppVueRouter } from 'web/src/modules/core/services/router/types';
import {
  resolveRegionPageLink,
} from 'web/src/modules/sportline/submodules/navigation/store/utils';

interface UseBaseSportEventsRegionPageNavigationGuardComposable {
  prefetch(
    router: AppVueRouter,
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ): void;
  getRedirectLocation(
    router: AppVueRouter,
    currentLocation: RouteLocationNormalized,
  ): Maybe<RouteLocationRaw>;
}

export function useBaseSportEventsRegionPageNavigationGuard(): UseBaseSportEventsRegionPageNavigationGuardComposable {
  const regionPageStore = useRegionPageStore();
  const region = toRef(regionPageStore, 'region');
  function prefetch(
    router: AppVueRouter,
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ): void {
    const navigationParameters: SportEventsPageIdentifier = getSportPageNavigationParameters(to);

    if (!isValidSportEventsPageIdentifier(navigationParameters)) {
      next({ name: RouteName.ERROR_NOT_FOUND_404 });
      return;
    }

    useRegionPageStore().setPageIdentifier(navigationParameters);
    useSportlineBreadcrumbsStore().setPageIdentifier(navigationParameters);

    next();
  }

  function getRedirectLocation(
    router: AppVueRouter,
    currentLocation: RouteLocationNormalized,
  ): Maybe<RouteLocationRaw> {
    const target = region.value?.navigationParameters;

    if (!target) { return null; }

    const currentTarget = getSportPageNavigationParameters(currentLocation);

    if (currentTarget.regionId !== target.id) {
      return null;
    }

    if (
      currentTarget.regionUrlName === target.urlName
      && currentTarget.sportFamily === target.sportFamily
    ) {
      return null;
    }

    return router.resolve301location(resolveRegionPageLink(target));
  }

  return {
    prefetch,
    getRedirectLocation,
  };
}
