import type { RouteLocationNamedRaw, RouteLocationNormalized } from 'vue-router';

import { RouteName } from '@leon-hub/routing-config-names';

import { CustomerCompletionAction } from 'web/src/modules/registration-completion/enums';

import { SimpleRegCompletionRouteParametersHandler } from './utils';

interface SimpleCompletionGuardOptions {
  isLoggedIn: boolean;
  isSimpleRegistrant: boolean;
  isRegistrationFinish: boolean;
  isExAllowedCountry: boolean;
  isCountryConfirmed: boolean;
  isForbiddenCountry: boolean;
  isAsoTermsConfirmationNeeded: boolean;
  to: RouteLocationNormalized;
}

export default function registrationCompletionGuard({
  isLoggedIn,
  isSimpleRegistrant,
  isRegistrationFinish,
  isExAllowedCountry,
  isCountryConfirmed,
  isForbiddenCountry,
  isAsoTermsConfirmationNeeded,
  to,
}: SimpleCompletionGuardOptions): RouteLocationNamedRaw | undefined {
  if (to.name === RouteName.HOME
    || to.name === RouteName.REGISTRATION_COMPLETION
    || to.name === RouteName.LOGIN
    || (/^\/registration(\/?)(.*)?$/i.test(to.fullPath) && to.name !== RouteName.REGISTRATION_COMPLETION)
    || !isLoggedIn) {
    return undefined;
  }

  if (!process.env.VUE_APP_FEATURE_STEP_REGISTRATION_ENABLED) {
    if (!isSimpleRegistrant || (isRegistrationFinish && !isExAllowedCountry)) {
      return undefined;
    }
    if ((!isCountryConfirmed || (!isExAllowedCountry && isForbiddenCountry))) {
      SimpleRegCompletionRouteParametersHandler.setRedirectLocation(to);
      return {
        name: RouteName.REGISTRATION_COMPLETION,
        params: {
          customerAction: CustomerCompletionAction.ForbiddenCountry,
        },
      };
    }
  }

  if (process.env.VUE_APP_FEATURE_ASO_ENABLED && isAsoTermsConfirmationNeeded) {
    SimpleRegCompletionRouteParametersHandler.setRedirectLocation(to);
    return {
      name: RouteName.REGISTRATION_COMPLETION,
      params: {
        customerAction: CustomerCompletionAction.AsoTerms,
      },
    };
  }

  return undefined;
}
