import type { Web2Image } from '@leon-hub/api-sdk';
import type { AlertIconName, OptionalAnyIconName } from '@leon-hub/icons';

import { getAlertIconWeb2Image } from 'web/src/modules/icons/getAlertIconWeb2Image';

/**
 * Returns optional either an image or an icon, depending on the environment and arguments.
 */
export function getImageOrIcon({
  alertIcon,
  image,
  icon,
}: {
  icon?: OptionalAnyIconName;
  alertIcon?: AlertIconName;
  image?: Web2Image;
}): { image?: Web2Image; iconName?: OptionalAnyIconName } {
  if (image) return { image };
  if (process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED && alertIcon) {
    return {
      image: getAlertIconWeb2Image(alertIcon),
    };
  }
  if (icon) return { iconName: icon };
  return {};
}
