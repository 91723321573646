import {
  computed,
} from 'vue';
import type {
  Slots,
} from 'vue';

import hasSlot from 'web/src/utils/vue/hasSlot';

import type { VHintBlockComposable } from './types';
import type { VHintBlockProps } from '../types';
import { getBaseClassNames, getPropsForIcon } from './utils';

export default function useVHintBlock(props: VHintBlockProps, slots: Slots): VHintBlockComposable {
  const iconProperties = computed(() => getPropsForIcon(props));
  const baseClassNames = computed(() => getBaseClassNames(props, hasSlot(slots, 'title')));

  return {
    iconProperties,
    baseClassNames,
  };
}
