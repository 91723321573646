<script lang="ts" setup>
import type { BaseFormWidgetEmits } from 'web/src/components/Form/types/common';
import { useFormCheckboxEvents, useFormCheckbox } from 'web/src/components/Form/composables';
import type { FormWrapperWidgetProperties } from 'web/src/components/Form/types';

import RegistrationTermsAcceptionCheckbox from '../RegistrationTermsAcceptionCheckbox';
import type { TermsCheckboxProps } from '../types';

const props = defineProps<FormWrapperWidgetProperties<TermsCheckboxProps>>();
const emit = defineEmits<BaseFormWidgetEmits>();

const {
  emitChange,
  emitInput,
  emitFocus,
  emitBlur,
} = useFormCheckboxEvents(emit, props.name);

const { componentProps } = useFormCheckbox(props);
</script>

<template>
  <RegistrationTermsAcceptionCheckbox v-auto-id="'FormRegistrationTermsCheckbox'"
    v-bind="componentProps"
    @input="emitInput"
    @change="emitChange"
    @focus="emitFocus"
    @blur="emitBlur"
  />
</template>
