export enum DateRangeDefaultDay {
  OneDay = '1',
  ThreeDays = '3',
  SevenDays = '7',
  FifteenDays = '15',
  ThirtyDays = '30',
}

export enum OperationTypeMappingType {
  BetId = 26,
  PartnerId = 27,
  SportBonusWithdrawalId = 237,
}
