<script lang="ts" setup>
import { useVLogo } from './composables';
import type { VLogoProps } from './types';

const props = withDefaults(defineProps<VLogoProps>(), {});
const { src, alt } = useVLogo(props);
</script>

<template>
  <img v-auto-id="'VLogo'"
    :class="$style['logo']"
    :alt="alt"
    :height="height"
    :width="width"
    :src="src"
  >
</template>

<style lang="scss" module>
.logo {
  display: inline-block;
  width: 100%;
  vertical-align: bottom;
}
</style>
