<script lang="ts" setup>
import CustomerHistoryListItemDetails
  from '../CustomerHistoryListItemDetails/CustomerHistoryListItemDetails.vue';
import type { CustomerHistoryListItemLayoutEmits } from './types';

const emit = defineEmits<CustomerHistoryListItemLayoutEmits>();

function emitClick(event: Event): void {
  emit('click', event);
}
</script>

<template>
  <CustomerHistoryListItemDetails v-auto-id="'CustomerHistoryListItemLayout'" @click="emitClick">
    <div :class="$style['history-list-item__container']">
      <div
        v-if="!!$slots.metaLeft || !!$slots.metaRight"
        v-data-test="{ el: 'history-list-item-row--with-meta'}"
        :class="$style['history-list-item__row']"
      >
        <div
          :class="{
            [$style['history-list-item__column']]: true,
            [$style['history-list-item__column--left']]: true,
          }"
        >
          <slot name="metaLeft" />
        </div>
        <div
          :class="{
            [$style['history-list-item__column']]: true,
            [$style['history-list-item__column--right']]: true,
          }"
        >
          <slot name="metaRight" />
        </div>
      </div>
      <div
        v-if="!!$slots.descriptionLeft || !!$slots.descriptionRight"
        v-data-test="{ el: 'history-list-item-row--with-description'}"
        :class="$style['history-list-item__row']"
      >
        <div
          :class="{
            [$style['history-list-item__column']]: true,
            [$style['history-list-item__column--left']]: true,
          }"
        >
          <slot name="descriptionLeft" />
        </div>
        <div
          :class="{
            [$style['history-list-item__column']]: true,
            [$style['history-list-item__column--right']]: true,
          }"
        >
          <slot name="descriptionRight" />
        </div>
      </div>
      <div
        v-if="!!$slots.date"
        v-data-test="{ el: 'history-list-item-row--with-date'}"
        :class="$style['history-list-item__row']"
      >
        <div :class="$style['history-list-item__column']">
          <slot name="date" />
        </div>
      </div>
      <div
        v-if="!!$slots.footerLeft || !!$slots.footerRight"
        v-data-test="{ el: 'history-list-item-row--with-footer'}"
        :class="$style['history-list-item__row']"
      >
        <div
          :class="{
            [$style['history-list-item__column']]: true,
            [$style['history-list-item__column--left']]: true,
          }"
        >
          <slot name="footerLeft" />
        </div>
        <div
          :class="{
            [$style['history-list-item__column']]: true,
            [$style['history-list-item__column--right']]: true,
          }"
        >
          <slot name="footerRight" />
        </div>
      </div>
    </div>
  </CustomerHistoryListItemDetails>
</template>

<style lang="scss" module>
.history-list-item {
  &__container {
    display: grid;
    grid-row-gap: 8px;
    width: 100%;
  }

  &__row {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0, max-content));
    column-gap: 4px;
    align-items: self-start;
    justify-content: space-between;
  }

  &__column {
    &--left,
    &--right {
      display: grid;
      grid-template-rows: repeat(auto-fit, minmax(0, max-content));
      row-gap: 4px;
    }

    &--left {
      text-align: left;
    }

    &--right {
      text-align: right;
    }
  }
}
</style>
