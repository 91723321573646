import { whenClientNetworkIdle } from '@leon-hub/idle';
import { ScriptElement } from '@leon-hub/utils';

declare global {
  interface Window {
    dataLayer: unknown[];
    gtag: (key: string, value: unknown) => void;
  }
}

export default class GoogleService {
  private static instance: GoogleService;

  private isInitiated = false;

  private constructor() {
    window.dataLayer = window.dataLayer || [];
  }

  // eslint-disable-next-line class-methods-use-this
  private async initGoogle(id = ''): Promise<void> {
    await whenClientNetworkIdle({ interval: 300 });
    const gtmContent = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${id}');`;
    await new ScriptElement({
      content: gtmContent,
      name: 'gtm0',
      appendTarget: 'head',
    }).addScript();
  }

  public static getInstance(): GoogleService {
    if (!GoogleService.instance) {
      GoogleService.instance = new GoogleService();
    }
    return GoogleService.instance;
  }

  public setCounterId(counterId: string | undefined): void {
    const isNotConfigured = !!counterId && !this.isInitiated;

    if (isNotConfigured) {
      void this.initGoogle(counterId);
      this.isInitiated = true;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  public pushEvent(eventName: string, data: Record<string, unknown>): void {
    /**
     * https://developers.google.com/tag-platform/devguides/datalayer?hl=ru#reset
     * otherwise, every log payload will be merged in one pointless object
     * */
    // eslint-disable-next-line func-names
    window.dataLayer?.push(function (): void {
      // @ts-ignore
      this.reset();
    });
    window.dataLayer?.push({ event: eventName, ...data });
  }
}
