import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';

import useCasAuthStore from 'web/src/modules/cas/store/useCasAuthStore';
import { AbstractPrefetch } from 'web/src/modules/core/services/router/prefetch';
import type { AppVueRouter } from 'web/src/modules/core/services/router/types';

export default class LoginModulePrefetch extends AbstractPrefetch {
  // eslint-disable-next-line class-methods-use-this
  prefetch(
    router: AppVueRouter,
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ): void {
    const { getSavedLogins } = useCasAuthStore();
    void getSavedLogins();
    next();
  }
}
