import type {
  GetSportEventsChangesResponse,
  GetSportEventsResponse,
} from 'web/src/modules/sportline/types/rest';
import type {
  SportFamily,
} from 'web/src/modules/sportline/enums';
import {
  BetlineReplaceResponse,
  SportEventsResponseChangeUtils,
} from 'web/src/modules/sportline/utils/rest';

/**
 * Replace one segment (sport, region or all) by new response
 */
export function updateSegmentEventsResponse(
  oldResponse: Maybe<Readonly<GetSportEventsResponse>>,
  newResponse: Maybe<GetSportEventsResponse | GetSportEventsChangesResponse>,
  options: {
    sportFamily?: SportFamily | string;
    regionId?: string;
  },
): Maybe<Readonly<GetSportEventsResponse>> {
  const { regionId, sportFamily } = options;
  let updated: Maybe<GetSportEventsResponse>;

  if (sportFamily) {
    updated = (new BetlineReplaceResponse(oldResponse))
      .replaceSportByResponse(newResponse ?? null, sportFamily);
  } else if (regionId) {
    updated = (new BetlineReplaceResponse(oldResponse))
      .replaceRegionByResponse(newResponse ?? null, Number(regionId));
  } else {
    updated = newResponse
      ? BetlineReplaceResponse.unknownResponseToSportEventsResponse(newResponse)
      : null;
  }

  const normalized = SportEventsResponseChangeUtils.normalizeDuplicatesResponse(updated);

  return normalized ? Object.freeze(normalized) : null;
}
