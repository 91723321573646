import type { Ref } from 'vue';
import { ref } from 'vue';

import type { Optional } from '@leon-hub/types';

import { ExtendedEventBlock } from 'web/src/modules/sportline/submodules/extended-event/enums';

import { useExtendedEventStore } from '../store';

interface UseIsEventExtendedProps {
  sportlineEventId: Ref<string>;
  blockType?: Ref<Optional<ExtendedEventBlock>>;
}

interface UseIsEventExtendedComposable {
  isEventExtended: Ref<boolean>;
}

export function useIsEventExtended(
  { sportlineEventId, blockType }: UseIsEventExtendedProps,
): UseIsEventExtendedComposable {
  const { createEventIsOpenedRef } = useExtendedEventStore();

  const type = blockType ?? ref(ExtendedEventBlock.Default);
  const isEventExtended = createEventIsOpenedRef(sportlineEventId, type);

  return { isEventExtended };
}
