import {
  getReferral,
  RequestOptionsPriority,
} from '@leon-hub/api-sdk';

import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import type { ReferralDataGetDataDocument } from 'web/src/modules/referral-program/types/ReferralProgramDocuments';

export default async function getReferralData(): Promise<ReferralDataGetDataDocument> {
  const apiClient = useGraphqlClient();

  return getReferral(
    apiClient,
    (node) => node.queries.refProgram.getReferral,
    {
      options: {},
    },
    {
      silent: true,
      priority: RequestOptionsPriority.LOW,
    },
  );
}
