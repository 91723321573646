import { MarketStatus } from '@leon-hub/api-sdk';

import type { SlipEntry } from '../types';

export default function getSlipEntriesWithChangedStatus(entries: SlipEntry[]): SlipEntry[] {
  return entries.filter((entry) => {
    if (!entry) return false;
    const { marketStatus } = entry;
    return marketStatus === MarketStatus.CLOSED
        || marketStatus === MarketStatus.SUSPENDED
        || marketStatus === MarketStatus.MISSING;
  });
}
