import type { Ref } from 'vue';
import { customRef } from 'vue';

import { useLocalStorageManager } from '@leon-hub/local-storage';

import type {
  ExpandedSportlineBlockMap,
  ExpandableSportlineBlockKey,
} from 'web/src/modules/sportline/composables/expandable-block/types';

export const localStorageKey = 'sport-element-expand-state';

export function useExpandedSportsLocalStorageRef(): Ref<ExpandedSportlineBlockMap> {
  const localStorageManager = useLocalStorageManager();

  function getStateFromStorage(): ExpandedSportlineBlockMap {
    const storedState = localStorageManager.getItem(localStorageKey);
    return storedState ? JSON.parse(storedState) : {};
  }

  let stateInStorage = getStateFromStorage();

  return customRef((track, trigger) => ({
    get(): ExpandedSportlineBlockMap {
      track();
      return stateInStorage;
    },
    set(newValue: ExpandedSportlineBlockMap) {
      stateInStorage = newValue;
      localStorageManager.setItem(localStorageKey, JSON.stringify(stateInStorage));
      trigger();
    },
  }));
}

export function useExpandedSportBlockLocalStorageRef(
  keyRef: Ref<Maybe<ExpandableSportlineBlockKey>>,
  defaultValueRef: Ref<boolean>,
): Ref<boolean> {
  const stateInStorage = useExpandedSportsLocalStorageRef();

  return customRef((track, trigger) => ({
    get(): boolean {
      track();
      const key = keyRef.value;
      const defaultValue = defaultValueRef.value;

      return key
        ? (stateInStorage.value[key] ?? defaultValue)
        : defaultValue;
    },
    set(newValue: boolean) {
      const key = keyRef.value;

      if (!key) { return; }

      const defaultValue = defaultValueRef.value;
      const currentState = stateInStorage.value;

      if (defaultValue === newValue) {
        delete currentState[key];
      } else {
        currentState[key] = newValue;
      }

      stateInStorage.value = currentState;
      trigger();
    },
  }));
}
