import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import { IconName, IconSize } from '@leon-hub/icons';

import type { VIconProps } from '@components/v-icon';

import type {
  SearchListItemEmits,
  SearchListItemProps,
} from 'web/src/modules/search/submodules/sportline/components/SearchListItem/types';
import DateTime from 'web/src/utils/DateTime';
import { getHighlightedText } from 'web/src/modules/search/submodules/sportline/components/SearchListItem/composables/utils';

interface SearchListItemComposable {
  onClick: () => void;
  date: ComputedRef<string>;
  time: ComputedRef<string>;
  formattedName: ComputedRef<string>;
  formattedLeagueName: ComputedRef<string>;
  formattedRegionName: ComputedRef<string>;
  formattedSportName: ComputedRef<string>;
  streamIconProps: VIconProps;
}

export default function useSearchListItem(
  props: RequiredField<SearchListItemProps, 'searchText'>,
  emits: SearchListItemEmits,
): SearchListItemComposable {
  const streamIconProps: VIconProps = {
    name: IconName.STREAM,
    size: IconSize.SIZE_16,
  };

  const date = computed(() => DateTime.withTimeStamp(props.kickoff).toDate());
  const time = computed(() => DateTime.withTimeStamp(props.kickoff).toTime());
  const formattedLeagueName = computed(() => getHighlightedText(props.searchText, props.leagueName, false));
  const formattedRegionName = computed(() => getHighlightedText(props.searchText, props.regionName));
  const formattedSportName = computed(() => getHighlightedText(props.searchText, props.sportName));
  const formattedName = computed(() => getHighlightedText(props.searchText, props.name, false));

  function onClick(): void {
    emits('click');
  }

  return {
    onClick,
    date,
    time,
    formattedName,
    formattedLeagueName,
    formattedRegionName,
    formattedSportName,
    streamIconProps,
  };
}
