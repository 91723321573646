import type { Ref, MaybeRef } from 'vue';
import { inject, toRef } from 'vue';

import type { MarketColumnId } from 'web/src/modules/sportline/types';
import type { SportlineFragmentBasisKey } from 'web/src/modules/sportline/types/list';

import type { SelectColumnMethod } from '../types';
import { ProvideSelectColumnMethodKey } from '../constants';

interface UseInjectSelectColumnMethodProps {
  basisKey: MaybeRef<SportlineFragmentBasisKey>;
}

interface UseInjectSelectColumnMethodComposable {
  selectColumn(columnId: MarketColumnId): void;
}

export function useInjectSelectColumnMethod(
  props: UseInjectSelectColumnMethodProps,
): UseInjectSelectColumnMethodComposable {
  // do not inject settings for blocks without sport info
  const basisKey: Ref<SportlineFragmentBasisKey> = toRef(props.basisKey);
  const injectedSelectColumn = inject<SelectColumnMethod>(ProvideSelectColumnMethodKey);

  function selectColumn(columnId: MarketColumnId) {
    if (!basisKey.value) { return; }
    injectedSelectColumn?.({ basisKey: basisKey.value, columnId });
  }

  return { selectColumn };
}
