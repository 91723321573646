import { IconSize } from '@leon-hub/icons';

import type { VIconProps } from '@components/v-icon';

import type { VStepDescriptionProps } from 'web/src/components/StepDescription/VStepDescription/types';

export default function getIconProperties(
  props: Pick<VStepDescriptionProps, 'iconName'>,
): VIconProps | null {
  if (props.iconName) {
    return {
      name: props.iconName,
      size: IconSize.SIZE_16,
    };
  }

  return null;
}
