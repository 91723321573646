import {
  assert,
  isObject,
} from '@leon-hub/guards';

const MAX_MESSAGE_LENGTH = 200;

export default function getObjectDescription(item: object, { prefix } :{ prefix?: string } = {}): string {
  assert(isObject(item));
  return Object.entries(item)
    .filter(([, value]) => value !== undefined)
    .map(([key, value]) => `${prefix || ''}${key}: ${isObject(value)
      // eslint-disable-next-line unicorn/prefer-spread
      ? JSON.stringify(value, null, 2).slice().slice(0, MAX_MESSAGE_LENGTH)
      : value}`)
    .join('\n');
}
