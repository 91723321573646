import type { Ref } from 'vue';
import { ref } from 'vue';

import { useIdentificationPhase } from 'web/src/modules/identification';

export interface UseIsIdentifiedUser {
  isIdentifiedUser: Ref<boolean>;
}

export default function useIsIdentifiedUser(): UseIsIdentifiedUser {
  if (!process.env.VUE_APP_FEATURE_IDENT_ENABLED) {
    return {
      isIdentifiedUser: ref(true),
    };
  }
  const { isIdentifiedUser } = useIdentificationPhase();
  return {
    isIdentifiedUser,
  };
}
