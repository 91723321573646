<script lang="ts" setup>
import { IconName, IconSize } from '@leon-hub/icons';

import type { VIconProps } from '@components/v-icon';
import { VIcon } from '@components/v-icon';

import { VCountryFlagSize } from 'web/src/components/CountryFlag/VCountryFlag/enums';
import type {
  LanguageSwitcherButtonProps,
  LanguageSwitcherButtonEmits,
} from 'web/src/modules/i18n/components/LanguageSelector/components/LanguageSwitcherButton/types';
import { useIsGlobeIcon } from 'web/src/modules/i18n/components/LanguageSelector/composables';
import VCountryFlag from 'web/src/components/CountryFlag/VCountryFlag/VCountryFlag.vue';
import VFlagIcon from 'web/src/components/CountryFlag/VFlagIcon/VFlagIcon.vue';

withDefaults(defineProps<LanguageSwitcherButtonProps>(), {
  name: 'RU',
});

const emit = defineEmits<LanguageSwitcherButtonEmits>();

const pointingProperties: VIconProps = {
  name: IconName.TRIANGLE_DOWN,
  size: IconSize.SIZE_16,
};

const iconProperties: VIconProps = {
  name: IconName.GLOBE,
  size: IconSize.SIZE_20,
};

const { isGlobeIcon } = useIsGlobeIcon();
</script>

<template>
  <button v-auto-id="'LanguageSwitcherButton'"
    type="button"
    :class="{
      [$style['language-switcher-button']]: true,
      [$style['language-switcher-button--touch-size']]: touchSize,
      [$style['language-switcher-button--no-background']]: noBackground,
      [$style['language-switcher-button--is-header']]: isHeader,
    }"
    @click="emit('open-language')"
  >
    <span :class="$style['language-switcher-button__visual']">
      <VIcon
        v-if="name && isGlobeIcon(name.toLowerCase())"
        v-bind="iconProperties"
        :class="$style['language-switcher-button__icon']"
      />
      <template v-else>
        <VFlagIcon
          v-if="null"
          :country-code="name"
          :size="IconSize.SIZE_20"
          :flag-size="VCountryFlagSize.SIZE_12"
          :class="$style['language-selector__country-flag']"
          rounded
        />
        <VCountryFlag
          v-else
          :class="$style['language-switcher-button__icon']"
          :name="name"
          :size="VCountryFlagSize.SIZE_16"
          rounded
        />
      </template>
      <span :class="$style['language-switcher-button__language']">{{ name }}</span>
      <VIcon
        v-bind="pointingProperties"
        :class="$style['language-switcher-button__arrow']"
      />
    </span>
  </button>
</template>

<style lang="scss" module>
.language-switcher-button {
  $self: &;

  display: flex;
  flex: none;
  align-items: center;
  justify-content: center;
  padding: 0;
  line-height: inherit;
  color: var(--TextPrimary);
  background: none;
  border: none;

  @include for-hover {
    &:hover &__visual {
      color: var(--TextDefault);
      cursor: pointer;
      background-color: var(--Highlight);
    }

    &--is-header {
      &:hover .language-switcher-button__visual {
        color: if($isEnvFeatureDarkHeader, var(--DTextDefault), var(--TextDefault));
        background-color: if($isEnvFeatureDarkHeader, var(--DHighlight), var(--Highlight));
      }
    }
  }

  &--touch-size {
    padding: 16px 8px 8px;
  }

  &__visual {
    display: flex;
    align-items: center;
    height: 36px;
    padding: 0 4px 0 8px;
    background-color: var(--Button);
    border-radius: 18px;
    transition: background-color 0.15s $animationEaseInOut, color 0.15s $animationEaseInOut;

    #{$self}--no-background & {
      background-color: transparent;
    }
  }

  &__icon {
    margin-right: 2px;
  }

  &__language {
    @include medium\12\16\04\caps;

    margin-right: 2px;
  }

  &--is-header {
    color: if($isEnvFeatureDarkHeader, var(--DTextPrimary), var(--TextPrimary));
  }
}
</style>
