<script lang="ts" setup>
import type {
  FormWrapperWidgetProperties,
  FormDataEvent,
} from 'web/src/components/Form/types';
import DepositsBonusSelectorComponent from 'web/src/modules/payments/form-widgets/DepositBonusSelector/components/DepositBonusSelector.vue';
import type { DepositBonusSelectorProps } from 'web/src/modules/payments/form-widgets/DepositBonusSelector/components/types';
import {
  DEPOSIT_BONUS_NONE_SELECTED,
} from 'web/src/modules/payments/constants';
import SPaymentBonuses
  from 'web/src/modules/payments/components/PaymentBonuses/components/SPaymentBonuses/SPaymentBonuses.vue';

interface Emits {
  (e: 'change', event: FormDataEvent): void;
}

const props = defineProps<FormWrapperWidgetProperties<DepositBonusSelectorProps>>();
const emit = defineEmits<Emits>();
const emitChange = (id: string): void => {
  emit('change', { name: props.name, value: props.options?.selectedBonusCode ?? id });
};
</script>

<template>
  <SPaymentBonuses
    v-if="null"
    :class="$style['deposit-bonus-selector']"
    :bonus-list="options?.depositBonusList ?? []"
    is-no-bonuses-switcher
    auto-select-first
  />
  <DepositsBonusSelectorComponent
    v-else
    :deposit-bonus-list="options?.depositBonusList ?? []"
    :selected-bonus="options?.selectedBonus ?? DEPOSIT_BONUS_NONE_SELECTED"
    :is-none-selected="options?.isNoneSelected ?? false"
    :is-choosing-bonus-before-deposit="options?.isChoosingBonusBeforeDeposit ?? false"
    @change="emitChange"
  />
</template>

<style lang="scss" module>
.deposit-bonus-selector {
  display: flex;
  margin-top: 16px;
}
</style>
