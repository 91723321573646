import type { VTabsButtonProps } from 'web/src/components/Tabs/VTabsButton/types';
import type { STabsButtonProps, STabsButtonSize } from 'web/src/components/Tabs/STabsButton/types';
import type { LTabsButtonProps } from 'web/src/components/Tabs/LTabsButton/types';
import { TabsType } from 'web/src/components/Tabs/VTabs/enums';

interface UseVTabsButtonComposable {
  remapProps(props: VTabsButtonProps): STabsButtonProps | LTabsButtonProps;
}

export function useVTabsButton(): UseVTabsButtonComposable {
  if (process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED) {
    return {
      remapProps: (p: VTabsButtonProps): STabsButtonProps => {
        let tabsButtonHeight: STabsButtonSize;

        switch (p.type) {
          case TabsType.LARGE:
            tabsButtonHeight = 'large';
            break;
          case TabsType.SMALL:
            tabsButtonHeight = 'small';
            break;
          case TabsType.NORMAL:
          default:
            tabsButtonHeight = 'medium';
            break;
        }

        return {
          kind: p.sTabButtonKind ?? 'primary',
          size: tabsButtonHeight,
          active: p.active,
          disabled: p.disabled ? p.disabled : undefined,
          href: p.href,
          flexible: p.flexible,
          id: p.id,
          isNavigation: p.isNavigation,
          single: p.single,
        };
      },
    };
  }

  return {
    remapProps: (p: VTabsButtonProps): LTabsButtonProps => ({
      type: p.type,
      flexible: p.flexible,
      active: p.active,
      single: p.single,
      disabled: p.disabled ? p.disabled : undefined,
      isNavigation: p.isNavigation,
      href: p.href,
      id: p.id,
      isBordered: p.isBordered,
      version: p.version,
    }),
  };
}
