import { updateEnvironment } from '@leon-hub/environment-client';
import { AppError } from '@leon-hub/app-errors';
import type { ExtendedEnv } from '@leon-hub/environment-types';

import { getDefinedEnvironment } from './getDefinedEnvironment';
import checkEnvironmentIsClean from './checkEnvironmentIsClean';

function preventChange(key: string | number | symbol): void {
  const error = new AppError({
    message: `EnvironmentPlugin has not been initialized, unable to get/access process.env['${String(key)}']`,
  });
  // eslint-disable-next-line no-console
  console.error(error);
}

export const bootstrapEnvironment = (): void => {
  if (process.env.VUE_APP_BUNDLER_WEBPACK) {
    checkEnvironmentIsClean(process.env as unknown as ExtendedEnv);
  }

  const rawProcessEnvironment = {
    ...process.env,
    ...getDefinedEnvironment(),
  };

  const environment = { ...rawProcessEnvironment } as Partial<Record<string, string>>;

  updateEnvironment({
    environment,
  });
  const rawKeys = Object.keys(rawProcessEnvironment);

  function isUpdateAllowed(key: string | symbol): boolean {
    if (rawKeys.includes(String(key))) {
      preventChange(key);
      return false;
    }

    return true;
  }

  if (rawProcessEnvironment.NODE_ENV === 'test') {
    // @ts-ignore
    process.env = rawProcessEnvironment;
  } else {
    const proxy = new Proxy(process.env, {
      get(target, rawKey) {
        return environment[String(rawKey)];
      },
      deleteProperty(target, key) {
        if (isUpdateAllowed(key)) {
          delete environment[String(key)];
          return true;
        }

        return false;
      },
      set(target, key, value) {
        if (isUpdateAllowed(key)) {
          Object.defineProperty(environment, String(key), { value, configurable: true });
          return true;
        }

        return false;
      },
    });

    process.env = proxy;
  }
};
