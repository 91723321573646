import { toRef, ref, computed } from 'vue';
import type { Ref, ComputedRef } from 'vue';

import { IconName, IconSize } from '@leon-hub/icons';

import type { VIconProps } from '@components/v-icon';
import type { VCheckboxProps } from '@components/checkbox';

import type { TermsCheckboxProps } from '../types';

interface UseTermsCheckbox {
  isExpanded: Ref<boolean>;
  chevronProperties: ComputedRef<VIconProps>;
  checkboxProps: ComputedRef<VCheckboxProps>;
  toggleExpand: () => void;
}

export default function useTermsAcceptionCheckbox(
  props: TermsCheckboxProps,
): UseTermsCheckbox {
  const isExpandable = toRef(props, 'expandable');

  const isExpanded = ref<boolean>(isExpandable.value ? !!props.open : true);

  const toggleExpand = (): void => {
    if (!isExpandable.value) { return; }
    isExpanded.value = !isExpanded.value;
  };

  const chevronProperties = computed<VIconProps>(() => ({
    name: isExpanded.value ? IconName.EXPAND_UP : IconName.EXPAND_DOWN,
    size: IconSize.SIZE_24,
  }));

  const checkboxProps = computed<VCheckboxProps>(() => ({
    ...props,
    id: props.name,
  }));

  return {
    isExpanded,
    toggleExpand,
    checkboxProps,
    chevronProperties,
  };
}
