import type { FieldGuards } from '@leon-hub/types';
import {
  isNumber,
  isString,
  isObject,
  isBoolean,
  isUndefined,
  bindIsArrayOf,
  isValidInterface,
} from '@leon-hub/guards';

import type { CoreMarketResponse, MarketSpecifiersResponse } from 'web/src/modules/sportline/types/rest';

import { isCoreMarketRunnerResponse } from './isCoreMarketRunnerResponse';
import { isBetlineSelectionTag } from './isBetlineSelectionTag';

function isMarketSpecifiersResponse(value: unknown): value is MarketSpecifiersResponse {
  if (!isObject(value)) { return false; }

  const isValidSpecifiers = (key: keyof typeof value) => isString(key)
    && (isString(value[key]) || isNumber(value[key]));

  return Object.keys(value).every(isValidSpecifiers);
}

export const isCoreMarketResponseFieldGuards: FieldGuards<CoreMarketResponse> = {
  id: isNumber,
  marketTypeId: isNumber,
  name: isString,
  runners: bindIsArrayOf(isCoreMarketRunnerResponse),
  open: isBoolean,
  primary: isBoolean,
  cols: isNumber,
  selectionTypes: [isUndefined, bindIsArrayOf(isBetlineSelectionTag)],
  handicap: [isUndefined, isString],
  hasZeroMargin: [isUndefined, isBoolean],
  specifiers: [isUndefined, isMarketSpecifiersResponse],
};

export function isCoreMarketResponse(value: unknown): value is CoreMarketResponse {
  return isValidInterface<CoreMarketResponse>(value, isCoreMarketResponseFieldGuards);
}
