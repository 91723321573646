import type { GetKeyType, ServiceLocatorKey } from './types';

interface Options<Key extends ServiceLocatorKey> { value: () => GetKeyType<Key>; required?: true }

export class ServiceLocator<Key extends ServiceLocatorKey = ServiceLocatorKey> {
  constructor(private readonly keys: Key[]) {}

  private services = new Map<Key, Options<Key>>();

  reset(): void {
    this.services.clear();
  }

  mockProvider<U extends Key>(key: U, options: Options<U>): () => void {
    if (process.env.NODE_ENV !== 'test') return () => undefined;
    const { services } = this;
    const prevOptions = services.get(key);
    services.set(key, options);
    return () => {
      if (prevOptions) {
        services.set(key, prevOptions);
      } else {
        services.delete(key);
      }
    };
  }

  registerProvider<U extends Key>(key: U, options: Options<U>): void {
    if (process.env.NODE_ENV !== 'test' && this.services.has(key)) {
      const errMsg = `Provider for key "${key.key}" is already registered`;
      if (process.env.NODE_ENV === 'development') {
        throw new Error(errMsg);
      } else {
        // eslint-disable-next-line no-console
        console.error(errMsg);
        return;
      }
    }
    this.services.set(key, options);
  }

  getProvider<U extends Key>(key: U): () => GetKeyType<U> {
    const options = this.services.get(key);
    if (options) {
      return () => {
        const value = options.value();
        if (options.required && !value) {
          throw new Error(`Invalid provided value for key ${key.key}: "${value}"`);
        }
        return value;
      };
    }
    throw new Error(`Service not found: ${key.key}`);
  }

  getNonConfiguredKeys(): Key[] {
    const loadedKeys: Key[] = [...this.services.keys()];
    return this.keys.filter((key) => !loadedKeys.includes(key));
  }

  isConfigured(): boolean {
    return !this.getNonConfiguredKeys().length;
  }
}
