import type { DirectiveBinding, ObjectDirective } from 'vue';

import { MatchProgressTimeDirection } from 'web/src/modules/sportline/enums';
import { useLiveProgressTimerController } from 'web/src/modules/sportline/composables/live-progress';
import { isStaticMatchProgressTime } from 'web/src/modules/sportline/utils';
import { totalSecondsToPretty } from 'web/src/modules/sportline/utils/live-progress';

export interface TimerDirectiveBinding {
  arg?: Maybe<MatchProgressTimeDirection | string>;
  value: number;
  oldValue?: number;
}

/**
 * Create brand-new timer directive
 * It is used for tests
 */
export function createTimerDirective(): ObjectDirective {
  const {
    beforeElementMounted,
    elementUpdated,
    elementUnmounted,
  } = useLiveProgressTimerController({
    updateEvery: 1000,
    isBindingChanged(binding: DirectiveBinding): boolean {
      return binding.value !== binding.oldValue;
    },
    updated(element: HTMLElement, binding: TimerDirectiveBinding, passedTime: number): void {
      if (isStaticMatchProgressTime(binding.arg)) { return; }
      if (binding.value === undefined) { return; }

      const isReversed = binding.arg === MatchProgressTimeDirection.Reversed;
      const passedSeconds = Math.trunc(passedTime / 1000);
      const totalSeconds = isReversed
        ? binding.value - passedSeconds
        : binding.value + passedSeconds;

      // eslint-disable-next-line no-param-reassign
      element.innerText = totalSecondsToPretty(totalSeconds);
    },
  });

  return {
    beforeMount: beforeElementMounted,
    updated: elementUpdated,
    unmounted: elementUnmounted,
  };
}

export const vTimer: ObjectDirective = createTimerDirective();
