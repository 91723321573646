import { mergeQueries, trim } from '@leon-hub/utils';

import type { GraphqlClientRequestOptions } from '../types/GraphqlClientRequestOptions';

export function getUrl(fullUrl: string, query?: GraphqlClientRequestOptions['query']): string {
  if (process.env.NODE_ENV === 'development') {
    const hasOriginalQuery = fullUrl.includes('?');
    const qIndex = fullUrl.indexOf('?');
    const originalQuery = hasOriginalQuery ? fullUrl.slice(qIndex) : '';
    if (!query || !originalQuery) return fullUrl;
    const finalQuery = mergeQueries(originalQuery, query);
    const hasQueryAfterMerge = trim(finalQuery, '?').length;
    if (hasQueryAfterMerge) {
      const fullUrlWithoutQuery = fullUrl.replace(/\?.+$/, '');
      return `${fullUrlWithoutQuery}?${finalQuery}`;
    }
  }
  return fullUrl;
}
