import type { RouteLocationNormalizedLoaded } from 'vue-router';

import { getLocationHref } from '@leon-hub/service-locator-env';

import type { FramedEnvironmentParameter } from '../enums';

export default function getSearchParameterValue<T = string>(
  name: FramedEnvironmentParameter | string,
  route?: RouteLocationNormalizedLoaded,
): T | undefined {
  if (route && process.env.VUE_APP_PLATFORM_CORDOVA) {
    return route.query[name] as T || undefined;
  }

  return new URL(getLocationHref()).searchParams.get(name) as T || undefined;
}
