export enum SlottSocialIconName {
  SLOTT_SOCIAL_DISCORD = 'slott-social-discord',
  SLOTT_SOCIAL_FB = 'slott-social-fb',
  SLOTT_SOCIAL_INSTAGRAM = 'slott-social-instagram',
  SLOTT_SOCIAL_MAIL = 'slott-social-mail',
  SLOTT_SOCIAL_TELEGRAM = 'slott-social-telegram',
  SLOTT_SOCIAL_TWITTER = 'slott-social-twitter',
  SLOTT_SOCIAL_VIBER = 'slott-social-viber',
  SLOTT_SOCIAL_VK = 'slott-social-vk',
  SLOTT_SOCIAL_WHATSAPP = 'slott-social-whatsapp',
  SLOTT_SOCIAL_X = 'slott-social-x',
  SLOTT_SOCIAL_YAHOO = 'slott-social-yahoo',
}
