<script lang="ts" setup>
import {

  watch,
} from 'vue';

import type { IconNameType } from '@leon-hub/icons';

import {
  convertCommonClassToCssModule as toModule,
} from 'web/src/components/List/utils/convertCommonClassesToCssModule';
import type { SwitchKind } from 'web/src/components/Switch/VSwitch/enums';
import { useVListItemAccordion } from 'web/src/components/List/VListItemAccordion/composables';
import type VListItemAccordionEmits from 'web/src/components/List/VListItemAccordion/types/VListItemAccordionEmits';
import type { VListItemAccordionExpose, VListItemAccordionSlots } from 'web/src/components/List/VListItemAccordion/types';

import VListItem from '../VListItem/VListItem.vue';
import VListItemInner from '../VListItemInner/VListItemInner.vue';
import { ListItemAccordionKind } from './enums';
import { ListItemSuffixAlign } from '../VListItem/enums';

interface VListItemAccordionProps {
  id: string;
  title: string;
  badgeLabel?: string;
  counter?: string;
  countryCode?: string;
  haveExpand?: boolean;
  haveProgress?: boolean;
  haveSwitcher?: boolean;
  href?: string;
  iconCustomStyles?: string;
  iconName?: IconNameType;
  iconSuffixName?: IconNameType;
  isActive?: boolean;
  isBigMargin?: boolean;
  isDone?: boolean;
  isNew?: boolean;
  isSwitchChecked?: boolean;
  isSwitchDisabled?: boolean;
  isTitleCentered?: boolean;
  noBackground?: boolean;
  noHover?: boolean;
  noWrap?: boolean;
  open?: boolean;
  progressValue?: number;
  suffixAlign?: ListItemSuffixAlign;
  suffixText?: string;
  switchKind?: SwitchKind;
  type?: ListItemAccordionKind;
  isDynamicOpen?: boolean;
}

const props = withDefaults(defineProps<VListItemAccordionProps>(), {
  iconName: undefined,
  countryCode: '',
  suffixText: '',
  counter: '',
  kind: ListItemAccordionKind.DEFAULT,
});

const emit = defineEmits<VListItemAccordionEmits>();

const {
  isOpen,
  classObject,
  iconSuffixProperties,
  iconNameAccordion,
  isIndependentlyOpen,
  $accordionSharedState,
  toggle,
  listItemRef,
  scrollIntoView,
} = useVListItemAccordion(props, emit);

watch(() => props.open, (newValue) => {
  if (props.isDynamicOpen) {
    toggle(newValue);
    return;
  }

  if (newValue && !props.haveSwitcher) {
    toggle(true);
    return;
  }

  if (props.haveSwitcher) {
    isIndependentlyOpen.value = Boolean(newValue);
    if (!$accordionSharedState.independentItems) {
      $accordionSharedState.updateOpenItem(isOpen.value ? null : props.id);
    }
  }
}, {
  immediate: !props.isDynamicOpen,
});

defineSlots<VListItemAccordionSlots>();

defineExpose<VListItemAccordionExpose>({
  scrollIntoView,
});
</script>

<template>
  <VListItem v-auto-id="'VListItemAccordion'"
    :id="id"
    :class="classObject"
  >
    <VListItemInner
      ref="listItemRef"
      :title="title"
      :have-switcher="haveSwitcher"
      :icon-name="iconNameAccordion"
      :country-code="countryCode"
      :icon-suffix-name="iconSuffixProperties"
      :is-switch-disabled="isSwitchDisabled"
      :is-switch-checked="isOpen"
      :is-title-centered="isTitleCentered"
      :suffix-text="suffixText"
      :counter="counter"
      :suffix-align="ListItemSuffixAlign.TOP"
      @click="toggle()"
    >
      <template
        v-if="$slots.inner"
        #inner
      >
        <slot name="inner" />
      </template>
    </VListItemInner>
    <div
      v-show="isOpen"
      :class="toModule('list-item-accordion__content')"
    >
      <slot
        :is-open="isOpen"
        :toggle="toggle"
        name="content"
      >
        No content provided for {{ id }}
      </slot>
    </div>
  </VListItem>
</template>
