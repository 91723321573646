import type { Ref, MaybeRef } from 'vue';
import { toRef, computed } from 'vue';

import { SportlineType } from 'web/src/modules/sportline/enums';

interface UseIsSportlineTypeComposable {
  isLive: Ref<boolean>;
  isPrematch: Ref<boolean>;
}

export function useIsSportlineType(sportlineType: MaybeRef<Maybe<SportlineType>>): UseIsSportlineTypeComposable {
  const sportlineTypeRef = toRef(sportlineType);

  const isLive = computed(() => sportlineTypeRef.value === SportlineType.Live);
  const isPrematch = computed(() => sportlineTypeRef.value === SportlineType.Prematch);

  return { isLive, isPrematch };
}
