import type { Ref, ComputedRef, MaybeRef } from 'vue';
import {
  toRef,
  inject,
  computed,
} from 'vue';

import type { MarketTypeSign } from 'web/src/modules/sportline/enums';
import type { MarketsColumn } from 'web/src/modules/sportline/types';
import type { SelectMarketTypeSettings, SportlineFragmentBasisKey } from 'web/src/modules/sportline/types/list';

import { ProvideSelectedColumnKey } from '../constants';
import type { ProvidedSelectedColumnMap } from '../types';

interface UseInjectSelectedColumnProps {
  basisKey: MaybeRef<SportlineFragmentBasisKey>;
}

interface UseInjectSelectedColumnComposable {
  sportColumnSettings: ComputedRef<SelectMarketTypeSettings>;
  selectedColumnId: ComputedRef<Maybe<string>>;
  selectedColumnSize: ComputedRef<Maybe<number>>;
  selectedColumnSign: ComputedRef<Maybe<MarketTypeSign>>;
  columns: ComputedRef<MarketsColumn[]>;
}

export function useInjectSelectedColumn(
  props: UseInjectSelectedColumnProps,
): UseInjectSelectedColumnComposable {
  // do not inject settings for blocks without sport info
  const basisKey: Ref<SportlineFragmentBasisKey> = toRef(props.basisKey);
  const columnMap = toRef(inject<ProvidedSelectedColumnMap>(ProvideSelectedColumnKey, {}));

  const providedSportColumnSettings = computed<Maybe<SelectMarketTypeSettings>>(() => {
    if (!basisKey.value) { return null; }
    return columnMap.value[basisKey.value] ?? null;
  });

  const sportColumnSettings = computed<SelectMarketTypeSettings>(() => ({
    selectedColumnId: providedSportColumnSettings.value?.selectedColumnId ?? null,
    selectedColumnSize: providedSportColumnSettings.value?.selectedColumnSize ?? null,
    selectedColumnSign: providedSportColumnSettings.value?.selectedColumnSign ?? null,
    columns: providedSportColumnSettings.value?.columns ?? [],
  }));

  const selectedColumnId = computed(() => sportColumnSettings.value.selectedColumnId);
  const selectedColumnSize = computed(() => sportColumnSettings.value.selectedColumnSize);
  const selectedColumnSign = computed(() => sportColumnSettings.value.selectedColumnSign);
  const columns = computed(() => sportColumnSettings.value.columns);

  return {
    sportColumnSettings,
    selectedColumnId,
    selectedColumnSize,
    selectedColumnSign,
    columns,
  };
}
