import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import { BalanceMode } from 'web/src/modules/bonuses/store/enums';

import type { BalanceCardProps } from '../types';

export interface BalanceCardComposable {
  isSingleMode: ComputedRef<boolean>;
}

export default function useBalanceCard(props: BalanceCardProps): BalanceCardComposable {
  const isSingleMode = computed<boolean>(() => props.mode === BalanceMode.Single);
  return {
    isSingleMode,
  };
}
