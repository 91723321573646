import { RouteName } from '@leon-hub/routing-config-names';

import type { AppVueRouter } from 'web/src/modules/core/services/router/types';

interface RedirectNavigationGuard {
  redirectUri: string;
}

export default function create301RedirectNavigationGuard(
  router: AppVueRouter,
  { redirectUri }: RedirectNavigationGuard,
): void {
  if (process.env.VUE_APP_PLATFORM_WEB && process.env.VUE_APP_PRERENDER && redirectUri) {
    let localRedirectUri = redirectUri;
    router.beforeEach((to, from, next) => {
      if (localRedirectUri) {
        next({
          name: RouteName.REDIRECT_SEO,
          state: {
            redirectCode: '301',
            redirectLocation: redirectUri,
          },
        });
        localRedirectUri = '';
        return;
      }

      next();
    });
  }
}
