<script lang="ts" setup>
import { toRef } from 'vue';

import type {
  BetlineLeagueStanding,
  BetlineMatchStatisticsTeam,
} from 'web/src/modules/sportline/types/rest';
import StatisticLeagueStandings
  from 'web/src/modules/sportline/submodules/core-statistic/components/StatisticLeagueStandings.vue';
import VList from 'web/src/components/List/VList/VList.vue';
import VListItemAccordion from 'web/src/components/List/VListItemAccordion/VListItemAccordion.vue';

interface StatisticLeagueStandingBlockProps {
  teams?: Maybe<[BetlineMatchStatisticsTeam, BetlineMatchStatisticsTeam]>;
  leagueStandings?: BetlineLeagueStanding[];
  alwaysExpanded?: boolean;
}

const props = defineProps<StatisticLeagueStandingBlockProps>();

const teams = toRef(props, 'teams', null);
const leagueStandings = toRef(props, 'leagueStandings', []);
</script>

<template>
  <VList v-auto-id="'StatisticLeagueStandingBlock'" independent-items>
    <VListItemAccordion
      v-if="leagueStandings.length > 0"
      id="league-standings"
      :title="$t('WEB2_MATCH_STATISTIC_TOURNAMENT_TABLE')"
      is-title-centered
      open
    >
      <template #content>
        <div>
          <StatisticLeagueStandings
            :league-standings="leagueStandings"
            :teams="teams"
            :always-expanded="alwaysExpanded"
          />
        </div>
      </template>
    </VListItemAccordion>
  </VList>
</template>
