import type { CashOut, BetCashOutRequest } from '@leon-hub/api-sdk';
import {
  getCashoutOffer,
} from '@leon-hub/api-sdk';

import type ApplicationGqlClient from 'web/src/modules/core/services/api/ApplicationGqlClient';

export default function requestCashoutOffer(payload: {
  graphqlClient: ApplicationGqlClient;
  options: Omit<BetCashOutRequest, 'ts'>;
})
  : Promise<Pick<CashOut, 'amount' | 'status' | 'msg'>> {
  const { graphqlClient, options } = payload;
  return getCashoutOffer(graphqlClient, (node) => node.queries.bet.getOffer, {
    options,
  });
}
