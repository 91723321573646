import type { RemoteApiErrorException } from '@leon-hub/api-sdk';

import GqlApiBatchedSubRequestError from './GqlApiBatchedSubRequestError';

export class GqlApiCustomerHistoryLimitExceededError extends GqlApiBatchedSubRequestError {
  constructor(options: { extensions: RemoteApiErrorException }) {
    super({
      ...options,
    });
  }
}
