<script lang="ts" setup>
import { VIcon } from '@components/v-icon';

import useSwiperNavigationButton from './composables/useSwiperNavigationButton';

interface SwiperNavigationButtonProps {
  isNext?: boolean;
  isRounded?: boolean;
}

interface SwiperNavigationButtonEmits {
  (e: 'click', event: MouseEvent): void;
}

const props = withDefaults(defineProps<SwiperNavigationButtonProps>(), {});
const emit = defineEmits<SwiperNavigationButtonEmits>();

const {
  iconName,
  iconSize,
} = useSwiperNavigationButton(props);

function emitClick(event: MouseEvent): void {
  emit('click', event);
}
</script>

<template>
  <div v-auto-id="'SwiperNavigationButton'"
    :class="{
      [$style['swiper-navigation-button']]: true,
      [$style['swiper-navigation-button--rounded']]: isRounded,
      [$style['swiper-navigation-button--next']]: isNext,
    }"
    @click="emitClick"
  >
    <VIcon
      :name="iconName"
      :size="iconSize"
    />
  </div>
</template>

<style lang="scss" module>
@include for-layout using($isDesktop) {
  .swiper-navigation-button {
    $self: &;

    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 10px 4px;
    color: var(--TextDefault);
    background: var(--Layer0);

    @if $isDesktop {
      cursor: pointer;

      &:hover {
        color: var(--BrandHighlight);
      }
    }

    &--rounded {
      align-items: flex-start;
      width: 34px;
      height: 44px;
      padding: 10px 0;
      margin: auto 0;
      border-radius: 0 24px 24px 0;

      &.swiper-navigation-button--next {
        align-items: flex-end;
        border-radius: 24px 0 0 24px;
      }
    }
  }
}
</style>
