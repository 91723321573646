import type { Without } from '@leon-hub/types';
import type { AbstractErrorOptions } from '@leon-hub/errors';
import { AbstractError } from '@leon-hub/errors';
import { isError } from '@leon-hub/guards';

import { AppErrorCode } from './AppErrorCode';

interface AppErrorOptions extends Without<AbstractErrorOptions, 'code'> {
  code?: AppErrorCode;
}

export class UnhandledRejection extends AbstractError {
  constructor({ event, ...options }: AppErrorOptions & { event: PromiseRejectionEvent }) {
    const { reason } = event;
    super({
      ...options,
      code: new AppErrorCode('UNHANDLED_REJECTION'),
      interactive: event.isTrusted,
      originalError: isError(reason) ? reason : undefined,
    });

    this.addLogMetaData('eventTimeStamp', event.timeStamp);
    if (typeof window !== 'undefined') {
      this.addLogMetaData('isRootTarget', event.target === window.event?.target);
    }
    if (!isError(reason)) {
      this.addLogMetaData('reason', reason);
    }
  }
}
