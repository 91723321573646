import { ZetaDcpConfigType } from '@leon-hub/api-sdk';
import { logger as loggerInstance } from '@leon-hub/logging';

import type { DepositMetric, ZetaDSPDefaults } from 'web/src/modules/analytics/services/types';

const logger = loggerInstance.createNamespace('ZetaDspService');

interface ZetaDSPFunctions {
  (): void;
  (command: string, key?: string, value?: string | number): void;
  commands?: unknown[];
}

declare global {
  interface Window {
    _rfi: ZetaDSPFunctions;
  }
}

export default class ZetaDspService {
  private static instance: ZetaDspService;

  private zetaDSPDefaultOptions: ZetaDSPDefaults = {};

  public setDefaultOptions(defaultOptions: ZetaDSPDefaults): void {
    this.zetaDSPDefaultOptions = defaultOptions;
  }

  public static getInstance(): ZetaDspService {
    if (!ZetaDspService.instance) {
      ZetaDspService.instance = new ZetaDspService();
    }
    return ZetaDspService.instance;
  }

  private sendDepositParams(
    metrics: Pick<DepositMetric, 'systemAmount' | 'paymentId' | 'userId'>,
    initArguments: Record<string, string> | undefined,
  ) {
    if (initArguments) {
      this.setArgsAndTrack({
        cur: 'usd',
        leadid: metrics.userId,
        price: metrics.systemAmount,
        revenue: metrics.systemAmount,
        transid: metrics.paymentId,
        ...initArguments,
      });
    }
  }

  public makeDeposit(metrics: Pick<DepositMetric, 'systemAmount' | 'paymentId' | 'userId'>): void {
    this.sendDepositParams(metrics, this.zetaDSPDefaultOptions[ZetaDcpConfigType.DEPOSIT]);
  }

  public firstDeposit(metrics: Pick<DepositMetric, 'systemAmount' | 'paymentId' | 'userId'>): void {
    this.sendDepositParams(metrics, this.zetaDSPDefaultOptions[ZetaDcpConfigType.FIRST_TIME_DEPOSIT]);
  }

  public customerRegistered(parameters: {}): void {
    this.setArgsAndTrack({
      ...this.zetaDSPDefaultOptions[ZetaDcpConfigType.REGISTRATION],
      ...parameters,
    });
  }

  public setArgsAndTrack(extraArguments: Record<string, string | number>): void {
    if (!this.zetaDSPDefaultOptions) {
      logger.error('ZetaDspService is trying to send metrics without default options', extraArguments);
      return;
    }
    const allArguments = { ...this.zetaDSPDefaultOptions[ZetaDcpConfigType.ALL], ...extraArguments };
    // eslint-disable-next-line no-underscore-dangle
    if (typeof window._rfi !== 'function') {
      // eslint-disable-next-line no-underscore-dangle
      window._rfi = function rfi() {
        // eslint-disable-next-line no-underscore-dangle, @typescript-eslint/prefer-nullish-coalescing
        window._rfi.commands = window._rfi.commands || [];
        // eslint-disable-next-line no-underscore-dangle,prefer-rest-params
        window._rfi.commands.push(arguments);
      };
    }
    const argumentsKey = Object.keys(allArguments);
    for (const key of argumentsKey) {
      // eslint-disable-next-line no-underscore-dangle
      window._rfi('setArgs', key, allArguments[key]);
    }

    // eslint-disable-next-line no-underscore-dangle
    window._rfi('track');
  }
}
