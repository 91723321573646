import type {
  VCoreDropdownProps,
  DropdownPositionMappingProps,
  CoreDropdownOptions,
} from '../types';

const getCoreDropdownProps = (
  input: DropdownPositionMappingProps,
  { matchParentWidth }: CoreDropdownOptions = {},
): VCoreDropdownProps => {
  const {
    x,
    y,
    width,
    height,
  } = input;
  let maxPossibleWidth = 0;
  if (typeof window !== 'undefined') {
    maxPossibleWidth = window.innerWidth - x - (width || 0);
  }
  if (matchParentWidth && width) {
    maxPossibleWidth = width;
  }
  return {
    appearZonePositionX: x,
    appearZonePositionY: y,
    appearZoneMinWidth: width,
    appearZoneMaxWidth: maxPossibleWidth,
    appearZoneOffsetY: height || 0,
  };
};

export default getCoreDropdownProps;
