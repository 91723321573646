import type { WatchStopHandle } from 'vue';
import {
  watch,
} from 'vue';

import type { WebSocketGraphQLMethod } from '@leon-hub/websocket/src/types';
import {
  isSubscriptionAllowed,
  WebSocketAccessRole,
  WebSocketSubscription,
} from '@leon-hub/websocket';

import useMockedWebSockets from 'web/src/modules/core/composables/web-sockets/useMockedWebSockets';
import useWebsocketSubscriptionsStore from 'web/src/modules/core/store/useWebsocketSubscriptionsStore';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';

import type {
  WebSocketSubscriptionOptions,
  WebSocketNoAccessOptions, UseWebSocketsComposable,
} from './types';

export interface WebSocketsSubscribeResult {
  unsubscribe(): void;
}

export default function useWebSockets(): UseWebSocketsComposable {
  if (process.env.VUE_APP_RENDERING_SSR) {
    return useMockedWebSockets();
  }

  const socketService = useWebsocketSubscriptionsStore();

  function subscribe<T extends WebSocketGraphQLMethod>({
    method,
    onMessage,
    isEnabled,
    variables,
    polling,
    access,
  }: WebSocketSubscriptionOptions<T>): WebSocketsSubscribeResult {
    const { isLoggedIn } = useIsLoggedIn();

    const subscription = new WebSocketSubscription({
      method,
      onMessage,
      variables,
      polling: polling ? {
        ...polling,
        timeout: polling.timeout.value,
      } : undefined,
      access,
      isWsEnabled: isEnabled.value,
    });

    function handlePolling(): void {
      if (isSubscriptionAllowed(isLoggedIn.value, access)) {
        subscription.startPollingRequest();
      } else {
        subscription.stopPollingRequest();
      }
    }

    let unwatchPollingTimeout: WatchStopHandle | undefined;

    if (polling?.timeout) {
      unwatchPollingTimeout = watch(polling.timeout, (newValue) => {
        subscription.setPollingTimeout(newValue);
      }, {
        immediate: true,
      });
    }

    let unwatchLoggedIn: WatchStopHandle | undefined = watch(isLoggedIn, handlePolling, { immediate: true });

    let unwatchEnabled: WatchStopHandle | undefined = watch(isEnabled, (newValue) => {
      subscription.setIsWsEnabled(newValue);
      socketService.unsubscribe(subscription);
      socketService.subscribe(subscription);

      if (!newValue) {
        handlePolling();
      }
    }, {
      immediate: true,
    });

    function unsubscribe(): void {
      subscription.stopPollingRequest();
      socketService.unsubscribe(subscription);

      unwatchPollingTimeout?.();
      unwatchLoggedIn?.();
      unwatchEnabled?.();

      unwatchPollingTimeout = undefined;
      unwatchLoggedIn = undefined;
      unwatchEnabled = undefined;
    }

    return {
      unsubscribe,
    };
  }

  // eslint-disable-next-line max-len
  function subscribeAuthorized<T extends WebSocketGraphQLMethod>(options: WebSocketNoAccessOptions<T>): WebSocketsSubscribeResult {
    return subscribe<T>({
      ...options,
      access: WebSocketAccessRole.AUTHORIZED,
    });
  }

  // eslint-disable-next-line max-len
  function subscribeAnonymous<T extends WebSocketGraphQLMethod>(options: WebSocketNoAccessOptions<T>): WebSocketsSubscribeResult {
    return subscribe<T>({
      ...options,
      access: WebSocketAccessRole.ANONYMOUS,
    });
  }

  return {
    subscribe,
    subscribeAuthorized,
    subscribeAnonymous,
  };
}
