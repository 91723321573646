<script lang="ts" setup>
import { ProgramParticipationStatus } from '@leon-hub/api-sdk';

import type {
  ReferralProgramMemberItemProps,
} from 'web/src/modules/referral-program/components/ReferralProgramMemberList/types';
import ReferralProgramAvatar
  from 'web/src/modules/referral-program/components/ReferralProgramAvatar/ReferralProgramAvatar.vue';

import { useReferralProgramMemberItem } from './composables';

const props = defineProps<ReferralProgramMemberItemProps>();

const { label } = useReferralProgramMemberItem(props);
</script>

<template>
  <div v-auto-id="'ReferralProgramMemberItem'" :class="$style['referral-program-member-item']">
    <div :class="$style['referral-program-member-item__left']">
      <ReferralProgramAvatar
        :status="status"
        :color-id="colorId"
        :initials="isIncognito ? undefined : initials"
      />
      <div :class="$style['referral-program-member-item__label']">
        <div :class="$style['referral-program-member-item__member-name']">
          <span>{{ referralMemberName }}</span>
        </div>
        <div
          :class="{
            [$style['referral-program-member-item__member-status']]: true,
            [$style['referral-program-member-item__member-status--available']]: status === ProgramParticipationStatus.AVAILABLE,
            [$style['referral-program-member-item__member-status--completed']]: status === ProgramParticipationStatus.COMPLETED,
          }"
        >
          {{ label }}
        </div>
      </div>
    </div>
    <div :class="$style['referral-program-member-item__right']">
      <div
        :class="{
          [$style['referral-program-member-item__amount']] : true,
          [$style['referral-program-member-item__amount--bonus']] : status === ProgramParticipationStatus.AVAILABLE,
        }"
      >
        <span v-if="amount">+ {{ formattedAmount }}</span>
      </div>
      <div :class="$style['referral-program-member-item__date']">
        {{ date }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>
.referral-program-member-item {
  $self: &;

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 12px;
  cursor: pointer;
  background-color: var(--Layer1);
  border-radius: $itemRadius;

  &__left {
    display: flex;
    gap: 12px;
    align-items: center;
  }

  &__right {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-end;
  }

  &__amount {
    @include amountStyles;

    color: var(--TextDefault);

    &--bonus {
      color: var(--BrandDefault);
    }
  }

  &__date {
    @include dateStyles;

    color: var(--TextSecondary);
  }

  &__label {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__member-name {
    @include nameStyles;
  }

  &__member-status {
    @include statusStyles;

    &--completed {
      color: $statusCompletedColor;
    }
  }
}
</style>
