import type { CombinedSlipEntryInfo } from '../../slip-info/types';

interface Payload {
  combinerSlipEntries: CombinedSlipEntryInfo[];
  onlyPrimaryMarkets: boolean;
  minOdds: number;
}

export function getSatisfyingBoostSettingsEventsCount({
  combinerSlipEntries,
  onlyPrimaryMarkets,
  minOdds,
}: Payload): number {
  return combinerSlipEntries
    .filter((item) => {
      if (item.zeroMargin || (onlyPrimaryMarkets && !item.isPrimaryMarket)) {
        return false;
      }
      return item.odds >= minOdds;
    })
    .length;
}
