import type {
  SlipEntry,
  SlipEntryId,
  SlipEntrySelectedIdentifier,
  SlipListItem,
} from '../submodules/slip-info/types';
import { slipEntryIdentifierSeparator } from '../constants';

export default function getSlipEntryId(input: SlipEntry | SlipListItem | SlipEntrySelectedIdentifier): SlipEntryId {
  const { event, market, runner } = input;
  return `${event}${slipEntryIdentifierSeparator}${market}${slipEntryIdentifierSeparator}${runner}`;
}
