import { Deferred } from '@leon-hub/utils';
import type { CaptchaType } from '@leon-hub/api-sdk';
import { BusEvent, useEventsBus } from '@leon-hub/event-bus';

import type { CaptchaToken } from 'web/src/modules/captcha/store/types';

interface CaptchaResolverComposable {
  resolveCaptcha(captchaType: CaptchaType): Promise<CaptchaToken>;
  setCaptchaToken(token: string): void;
}

export default function useCaptchaResolver(): CaptchaResolverComposable {
  let captchaProcess: Deferred<CaptchaToken>;

  const eventBus = useEventsBus();
  async function resolveCaptcha(captchaType: CaptchaType): Promise<CaptchaToken> {
    eventBus.emit(BusEvent.CAPTCHA_VERIFICATION, { captchaType });
    captchaProcess = new Deferred<CaptchaToken>();
    return captchaProcess.promise;
  }

  function setCaptchaToken(token: string): void {
    captchaProcess.resolve(token);
  }

  return {
    resolveCaptcha,
    setCaptchaToken,
  };
}
