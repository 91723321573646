import { defineStore } from 'pinia';
import {
  computed,
  ref,
} from 'vue';

import { Deferred, getUuid } from '@leon-hub/utils';
import { IconName } from '@leon-hub/icons';

import { JumbotronIconKind } from 'web/src/components/Jumbotron/VJumbotron/enums';
import { ModalWidth } from 'web/src/components/Modal/enums';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import { CaptchaRequesterStrategy } from 'web/src/modules/captcha/store/enums';

import type {
  DialogButtonProps,
  EventResolvers,
  ShowDialogFunc,
  ShowDialogOptions,
} from '../types';
import type { DialogsListItem } from './types';
import type { PresetName } from '../enums';
import {
  AnalyticsModalType,
  DialogAction,
  DialogComponent,
  DialogPriority,
} from '../enums';

const getUpdatedPresetButtons = (
  preset: ShowDialogOptions['options'],
  buttons: DialogButtonProps[],
): DialogButtonProps[] => {
  if (preset.buttons?.length) {
    const buttonCopy = [...preset.buttons];
    for (const [index, button] of buttonCopy.entries()) {
      const updatedFields = buttons[index];

      if (updatedFields) {
        buttonCopy[index] = {
          ...button,
          ...updatedFields,
        } as DialogButtonProps;
      }
    }
    return buttonCopy;
  }
  return buttons || [];
};

const useDialogsStore = defineStore('dialogs', () => {
  const { $translate } = useI18n();

  const analytics = useAnalytics();

  const dialogsList = ref<DialogsListItem[]>([]);

  const currentDialog = computed(() => dialogsList.value[0]);

  const hasInnerDialog = ref(false);

  const dialogPresets = computed<Record<PresetName, ShowDialogOptions['options']>>(() => {
    const baseCloseButton: Readonly<DialogButtonProps> = {
      action: DialogAction.MODAL_CLOSE,
      label: $translate('JSP_PCL_FBOT_CLOSE').value,
    };

    const baseAlertModal: ShowDialogOptions['options'] = {
      priority: DialogPriority.notification,
      modalComponent: DialogComponent.ConfirmModal,
      iconName: IconName.CHECK_OUTLINE,
      iconKind: JumbotronIconKind.SUCCESS,
      width: ModalWidth.SMALL,
      buttons: [baseCloseButton],
    };

    return {
      ALERT_SUCCESS: baseAlertModal,
      ALERT_SUCCESS_NO_BUTTONS: {
        ...baseAlertModal,
        buttons: [],
      },
      ALERT_WARNING: {
        ...baseAlertModal,
        priority: DialogPriority.error,
        iconKind: JumbotronIconKind.DEFAULT,
        iconName: IconName.ATTENTION_OUTLINE,
        analyticsModalType: AnalyticsModalType.WARNING,
      },
      ALERT_ERROR: {
        ...baseAlertModal,
        priority: DialogPriority.error,
        iconKind: JumbotronIconKind.DEFAULT,
        iconName: IconName.ATTENTION_OUTLINE,
        analyticsModalType: AnalyticsModalType.WARNING,
        title: $translate('JS_CAPTION_ATTENTION').value,
      },
      CONFIRM: {
        priority: DialogPriority.default,
        modalComponent: DialogComponent.ConfirmModal,
        iconName: IconName.ATTENTION_OUTLINE,
        buttons: [
          {
            action: DialogAction.CONFIRM,
            label: $translate('LIVECHAT_MODAL_CLOSE_BTN').value,
          },
          {
            action: DialogAction.MODAL_CLOSE,
            kind: process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED ? 'tertiary-primary' : ButtonKind.TRANSPARENT,
            label: $translate('JSPACC_SETT_CANCEL').value,
          },
        ],
      },
      CAPTCHA: {
        priority: DialogPriority.default,
        modalComponent: DialogComponent.CaptchaModal,
        captchaRequesterStrategy: CaptchaRequesterStrategy.DEFAULT,
        buttons: [],
      },
      CMS_MODAL: {
        priority: DialogPriority.default,
        fullHeight: true,
        width: ModalWidth.SMALL,
        modalComponent: DialogComponent.CmsContentModal,
        iconName: IconName.ATTENTION_OUTLINE,
        noPadding: true,
        buttons: [
          {
            ...baseCloseButton,
            label: $translate('WEB2_CLOSE_CUSTOMER_NOTIFICATION').value,
          },
        ],
      },
    };
  });

  function resolveDialogPromises(id?: string): void {
    for (const item of dialogsList.value) {
      if (!id || item.dialog.id === id) {
        item.promise.resolve();
      }
    }
  }

  function closeDialog(id: string): void {
    resolveDialogPromises(id);
    dialogsList.value = dialogsList.value.filter((item) => item.dialog.id !== id);
  }

  function closeAllDialogs(): void {
    resolveDialogPromises();
    dialogsList.value = [];
  }

  function sortDialogs(): void {
    dialogsList.value.sort((a, b) => {
      if (a.dialog.priority > b.dialog.priority) {
        return -1;
      }

      if (a.dialog.priority < b.dialog.priority) {
        return 1;
      }

      return a.createdAt - b.createdAt;
    });
  }

  const showDialog: ShowDialogFunc = (options: ShowDialogOptions) => {
    const id = options.id || getUuid();

    const preset = dialogPresets.value[options.presetName];

    let newButtons = preset.buttons ? [...preset.buttons] : [];
    const { buttons, ...dialogOptions } = options.options;
    if (buttons && preset) {
      newButtons = getUpdatedPresetButtons(preset, buttons);
    }

    const item: DialogsListItem = {
      dialog: {
        ...preset,
        ...dialogOptions,
        buttons: newButtons,
        id,
        priority: options.options.priority ?? DialogPriority.default,
      },
      resolvers: {
        [DialogAction.MODAL_DESTROY]: () => closeDialog(id),
      },
      promise: new Deferred<void>(),
      createdAt: Date.now(),
    };

    const subscribe = (resolvers: EventResolvers): void => {
      item.resolvers = {
        ...item.resolvers,
        ...resolvers,
      };
    };

    dialogsList.value.push(item);
    sortDialogs();

    if (item.dialog.analyticsModalType) {
      analytics.sendModalErrorMessage(item.dialog);
    }

    return {
      id,
      subscribe,
      promise: item.promise.promise,
    };
  };

  function callAction(modalId: string, action: DialogAction, value?: unknown): void {
    for (const dialog of dialogsList.value) {
      if (dialog.dialog.id === modalId) {
        const doDestroyDialog = dialog.resolvers[action]?.({
          modalId,
          value,
        });

        if (doDestroyDialog !== false) {
          void dialog.resolvers[DialogAction.MODAL_DESTROY]?.({
            modalId,
          });
        }
      }
    }
  }

  function setFullHeight(modalId: string, fullHeight: boolean): void {
    for (const dialog of dialogsList.value) {
      if (dialog.dialog.id === modalId) {
        dialog.dialog.fullHeight = fullHeight;
      }
    }
  }

  function updateDialogProps(modalId: string, newProps: ShowDialogOptions['options']): void {
    for (const dialog of dialogsList.value) {
      if (dialog.dialog.id === modalId) {
        dialog.dialog = {
          ...dialog.dialog,
          ...newProps,
        };
      }
    }
  }

  return {
    currentDialog,
    dialogPresets,
    showDialog,
    closeAllDialogs,
    closeDialog,
    callAction,
    setFullHeight,
    updateDialogProps,
    hasInnerDialog,
  };
});

export default useDialogsStore;
