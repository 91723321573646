import { toRef } from 'vue';

import {
  getEgsGames,
  getEgsGroups,
  getEgsCategories,
  getEgsGameById,
  getEgsGameByUrl,
  getLobby as getLobbyRequest,
  getEgsTopWinners,
  startEgsGame,
  lobbyTypeAll,
  lobbyTypeSlots,
  RequestOptionsPriority,
} from '@leon-hub/api-sdk';

import type {
  EgsGroup,
  EgsCategory,
  EgsGame,
  GetGroupsRequest,
  GetCategoriesRequest,
  GetGamesRequest,
  GetTopWinnersRequest,
  GetLobbyRequest,
  GetGameByUrlRequest,
  GetGamesResponse,
  GetTopWinnersResponse,
  StartGameRequest,
  StartGameData,
} from 'web/src/modules/egs/types';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import { parseLobbyItems } from 'web/src/modules/lobby/utils';
import type { ParsedLobbyItems } from 'web/src/modules/lobby/types';
import type { ParseLobbyOptions } from 'web/src/modules/lobby/utils/parseLobbyItems';
import { useSiteConfigStore } from 'web/src/modules/core/store';

export interface UseEgsApiComposable {
  getGroups(options: GetGroupsRequest, silent?: boolean): Promise<EgsGroup[]>;
  getCategories(options: GetCategoriesRequest, silent?: boolean): Promise<EgsCategory[]>;
  getGames(options: GetGamesRequest, silent?: boolean): Promise<GetGamesResponse>;
  getGameById(id: string): Promise<EgsGame>;
  getGameByUrl(options: GetGameByUrlRequest): Promise<EgsGame>;
  getLobby(options: GetLobbyRequest, parseOptions?: ParseLobbyOptions, silent?: boolean): Promise<ParsedLobbyItems>;
  getTopWinners(options: GetTopWinnersRequest): Promise<GetTopWinnersResponse>;
  startGame(options: StartGameRequest, isVpnGame?: boolean): Promise<StartGameData>;
}

export default function useEgsApi(): UseEgsApiComposable {
  const apiClient = useGraphqlClient();
  const externalGamesBlock = toRef(useSiteConfigStore(), 'externalGames');

  async function getGroups(options: GetGroupsRequest, silent?: boolean): Promise<EgsGroup[]> {
    const result = await getEgsGroups(
      apiClient,
      (node) => node.queries.externalGames.getGroups,
      {
        options: {
          ...options,
          type: options.type === lobbyTypeAll ? lobbyTypeSlots : options.type,
        },
      },
      {
        silent,
      },
    );

    return result.groups;
  }

  async function getCategories(options: GetCategoriesRequest, silent?: boolean): Promise<EgsCategory[]> {
    const result = await getEgsCategories(
      apiClient,
      (node) => node.queries.externalGames.getCategories,
      {
        options: {
          ...options,
          type: options.type === lobbyTypeAll ? lobbyTypeSlots : options.type,
        },
      },
      {
        silent,
      },
    );

    return result.categories;
  }

  async function getGameById(id: string): Promise<EgsGame> {
    const result = await getEgsGameById(
      apiClient,
      (node) => node.queries.externalGames.getGame,
      {
        options: {
          gameId: id,
        },
      },
    );

    return result.game;
  }

  async function getGameByUrl(options: GetGameByUrlRequest): Promise<EgsGame> {
    const result = await getEgsGameByUrl(
      apiClient,
      (node) => node.queries.externalGames.getGameByUrl,
      {
        options,
      },
    );

    return result.game;
  }

  function getGames(options: GetGamesRequest, silent?: boolean): Promise<GetGamesResponse> {
    return getEgsGames(
      apiClient,
      (node) => node.queries.externalGames.getGames,
      {
        options,
      },
      {
        silent,
        priority: silent ? RequestOptionsPriority.LOW : undefined,
      },
    );
  }

  // eslint-disable-next-line max-len
  async function getLobby(options: GetLobbyRequest, parseOptions?: ParseLobbyOptions, silent?: boolean): Promise<ParsedLobbyItems> {
    const items = await getLobbyRequest(
      apiClient,
      (node) => node.queries.externalGames.getLobby,
      {
        options,
      },
      {
        silent,
      },
    );

    return parseLobbyItems(items, parseOptions);
  }

  function getTopWinners(options: GetTopWinnersRequest): Promise<GetTopWinnersResponse> {
    return getEgsTopWinners(
      apiClient,
      (node) => node.queries.externalGames.getTopWinners,
      {
        options,
      },
    );
  }

  function startGame(options: StartGameRequest, isVpnGame = false): Promise<StartGameData> {
    const requestOptions = isVpnGame ? {
      timeout: externalGamesBlock.value?.vpnEgsStartGameRetryTimeout ?? 30_000,
      retry: externalGamesBlock.value?.vpnEgsStartGameRetryCounter ?? 5,
    } : {
      // By default client uses 15 sec timeout.
      timeout: externalGamesBlock.value?.egsStartGameRetryTimeout,
      // Disable retry by default.
      // @see https://jira.leoncorp.net/browse/LB-15070?focusedCommentId=360654&page=com.atlassian.jira.plugin.system.issuetabpanels%3Acomment-tabpanel#comment-360654
      retry: externalGamesBlock.value?.egsStartGameRetryCounter ?? 0,
    };

    return startEgsGame(
      apiClient,
      (node) => node.mutations.externalGames.startGame,
      {
        options,
      },
      requestOptions,
    );
  }

  return {
    getGroups,
    getCategories,
    getGames,
    getGameById,
    getGameByUrl,
    getLobby,
    getTopWinners,
    startGame,
  };
}
