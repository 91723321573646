import type { Ref } from 'vue';
import { computed, toRef } from 'vue';

import { replace } from '@leon-hub/utils';

import { useI18nStore } from 'web/src/modules/i18n/store';
import { useSportlineWidgetsStore } from 'web/src/modules/sportline/submodules/widgets/store';
import type { LiveSportlineEvent } from 'web/src/modules/sportline/types';
import { IframeWidget } from 'web/src/modules/framed-app/enums';
import { LSportsFramedWidgetProperties } from 'web/src/modules/sportline/submodules/widgets/enums';
import { useFramedWidgetUrl } from 'web/src/modules/widgets/composables/useFramedWidgetUrl';

interface UseLSportsWidgetProps {
  sportlineEvent: Ref<LiveSportlineEvent>;
}

interface UseLSportsWidgetComposable {
  iframeWidgetSrc: Ref<string | undefined>;
  additionalQueryStringParameters: Ref<Record<string, string>[]>;
}

export function useLSportsWidget(
  props: UseLSportsWidgetProps,
): UseLSportsWidgetComposable {
  const { sportlineEvent } = props;

  const lang = toRef(useI18nStore(), 'lang');
  const lSportsWidgetConfig = toRef(useSportlineWidgetsStore(), 'lsportsConfigFinal');

  const matchId = computed(() => sportlineEvent.value.liveWidget?.id ?? '');
  const isVirtual = computed(() => !!sportlineEvent.value.liveWidget?.isVirtual);

  const productionUrl = computed<string>(() => {
    const baseUrl = replace(`${lSportsWidgetConfig.value?.productionUrl}`, { eventId: matchId.value });
    const parameters = [`language=${(lSportsWidgetConfig.value?.customLang || lang.value)}`];

    if (isVirtual.value) {
      parameters.push('virtual=true');
    }

    return `${baseUrl}&${parameters.join('&')}`;
  });

  const iframeWidgetSrc = process.env.VUE_APP_LAYOUT_DESKTOP
    ? productionUrl
    : useFramedWidgetUrl(IframeWidget.LSports);

  const additionalQueryStringParameters = computed<Record<string, string>[]>(() => ([
    { [LSportsFramedWidgetProperties.EncodedUrl]: encodeURIComponent(productionUrl.value) },
  ]));

  return {
    iframeWidgetSrc,
    additionalQueryStringParameters,
  };
}
