import formUiSchemaFieldCommonValidationSchema from './formUiSchemaFieldCommonValidationSchema';
import phoneErrorValidationSchema from './phoneErrorValidationSchema';

// eslint-disable-next-line max-len
const emailPattern = '^(?!.*(?:\'\'|\\.\\.))[a-zA-Z0-9!#$%&*+/=?^_`{|}~-][a-zA-Z0-9!#$%&\'*+/=?^_`.{|}~-]*@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)+$';
export {
  formUiSchemaFieldCommonValidationSchema,
  phoneErrorValidationSchema,
  emailPattern,
};
