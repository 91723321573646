import type { LobbyBlockProps } from 'web/src/modules/lobby/components/LobbyBlock/types';
import type { LobbySectionContainerProps } from 'web/src/modules/lobby/components/LobbySectionContainer/types';

export default function getSkeletonItems(
  props: LobbyBlockProps,
  isLoadingLobby: boolean,
): LobbySectionContainerProps[] {
  if (!isLoadingLobby) {
    return [];
  }

  if (props.skeletons) {
    return props.skeletons.slice(0, 4).map((skeletonItem) => ({
      skeletonItem,
      isModal: props.isModal,
      noBackground: props.noBackground,
      isHeaderWithHorizontalMargin: props.isHeaderWithHorizontalMargin,
      isFiltersAllowed: props.isFiltersAllowed,
      isCustomHomepage: props.isCustomHomepage,
    }));
  }

  return [];
}
