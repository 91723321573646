<script lang="ts" setup>
import ReferralProgramPopupReward
  from 'web/src/modules/referral-program/components/ReferralProgramPopupReward/ReferralProgramPopupReward.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import VStepDescription from 'web/src/components/StepDescription/VStepDescription/VStepDescription.vue';

import type { ReferralProgramRegistrationBodyEmits, ReferralProgramRegistrationBodyProps } from './types';
import { useReferralProgramRegistrationBody } from './composables';

const props = withDefaults(defineProps<ReferralProgramRegistrationBodyProps>(), {
});
const emit = defineEmits<ReferralProgramRegistrationBodyEmits>();

const {
  stepDescriptionProps,
  buttonProps,
  referralProgramPopupRewardProps,
  registrationDescription,
  registrationRule,
  registrationTitle,
  handleClick,
} = useReferralProgramRegistrationBody(props, emit);
</script>

<template>
  <div v-auto-id="'ReferralProgramRegistrationBody'" :class="$style['referral-program-reg-body']">
    <span :class="$style['referral-program-reg-body__title']">
      {{ registrationDescription }}
    </span>

    <div :class="$style['referral-program-reg-body__column']">
      <ReferralProgramPopupReward
        v-bind="referralProgramPopupRewardProps"
      />
    </div>

    <span :class="$style['referral-program-reg-body__rule']">
      {{ registrationRule }}
    </span>

    <div :class="$style['referral-program-reg-body__how-to']">
      <div :class="$style['referral-program-reg-body__title']">
        {{ registrationTitle }}
      </div>

      <div :class="$style['referral-program-reg-body__steps']">
        <VStepDescription
          v-for="(step, index) in stepDescriptionProps"
          :key="index"
          v-bind="step"
        />
      </div>
    </div>

    <VButton
      :class="$style['referral-program-reg-body__button']"
      v-bind="buttonProps"
      @click="handleClick"
    />
  </div>
</template>

<style lang="scss" module>
.referral-program-reg-body {
  @include refBody;

  width: 100%;

  &__title {
    display: block;
    width: 100%;
  }

  &__rule {
    display: block;
    padding: 8px;
  }

  &__steps {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
  }

  &__button {
    margin-top: 20px;
  }

  &__how-to {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}
</style>
