import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';

import type { AnalyticsComposable } from 'web/src/modules/analytics/composables/useAnalytics';

import type { TrackBetErrorPayload } from '../types';

export default function betPlaceErrorYMTrigger(
  { errorCode, errorMessage }: TrackBetErrorPayload,
  service: AnalyticsComposable,
): void {
  service.push(AnalyticsEvent.Z_BET_ERROR, {
    submitSlipData: {
      error: {
        message: errorMessage,
        code: errorCode,
      },
    },
  });
}
