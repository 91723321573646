import type { Ref } from 'vue';
import {
  computed,
  toRef,
} from 'vue';

import { useDateLocalizers } from 'web/src/modules/i18n/composables/useDateLocalizers';
import type { SportlineEvent } from 'web/src/modules/sportline/types';
import DateTime from 'web/src/utils/DateTime';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';

interface UseSportlineEventDateTimeProps {
  sportlineEvent: Ref<Maybe<SportlineEvent> | undefined>;
  isFullDate?: boolean | Ref<boolean>;
}

interface UseSportlineEventDateTimeComposable {
  kickoff: Ref<number>;
  date: Ref<Maybe<string>>;
  time: Ref<Maybe<string>>;
}

export function useSportlineEventDateTime(
  props: UseSportlineEventDateTimeProps,
): UseSportlineEventDateTimeComposable {
  const { sportlineEvent } = props;

  const settingsStore = useSportlineSettingsStore();
  const datePattern = toRef(settingsStore, 'sportEventDetailsDatePattern');
  const { toMediumLocaleDate, toFullLocaleDate } = useDateLocalizers();
  const isFullDate = toRef(props.isFullDate);

  const kickoff = computed<number>(() => sportlineEvent.value?.kickoff ?? 0);
  const date = computed<Maybe<string>>(() => {
    if (!kickoff.value) {
      return null;
    }

    return isFullDate.value ? toFullLocaleDate(kickoff.value, datePattern.value) : toMediumLocaleDate(kickoff.value, datePattern.value);
  });
  const time = computed<Maybe<string>>(() => (kickoff.value ? DateTime.withTimeStamp(kickoff.value).toTime() : null));

  return {
    kickoff,
    date,
    time,
  };
}
