import type { UnwrapNestedRefs, MaybeRef } from 'vue';
import { reactive, watch, toRef } from 'vue';

import type { ExtendedEventsId } from 'web/src/modules/sportline/submodules/extended-event/store/types';
import type { OnResizeEventElementPayload } from 'web/src/modules/sportline/views/EventBlock/types';
import type { ExpandedEventsHeightMap } from 'web/src/modules/sportline/views/virtual-list-renderer/utils';

interface UseVirtualListEventElementResizeProps {
  expandedIds: MaybeRef<ExtendedEventsId[]>;
}

interface UseVirtualListEventElementResizeComposable {
  customEventHeightMap: UnwrapNestedRefs<ExpandedEventsHeightMap>;
  onResizeEventElement(payload: OnResizeEventElementPayload): void;
}

export function useVirtualListEventElementResize(
  props: UseVirtualListEventElementResizeProps,
): UseVirtualListEventElementResizeComposable {
  const expandedIds = toRef(props.expandedIds);

  const customEventHeightMap = reactive<ExpandedEventsHeightMap>({});

  function onResizeEventElement(payload: OnResizeEventElementPayload): void {
    customEventHeightMap[payload.sportlineEventId] = payload.height;
  }

  function clearCollapsedEventsHeights(): void {
    const idMap = new Set(expandedIds.value);

    for (const key of Object.keys(customEventHeightMap)) {
      if (idMap.has(key)) { continue; }
      delete customEventHeightMap[key];
    }
  }

  watch(expandedIds, clearCollapsedEventsHeights);

  return {
    customEventHeightMap,
    onResizeEventElement,
  };
}
