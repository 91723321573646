import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import type { FileMultipleInputProps } from '../../types';

interface UseErrorsArray {
  anySingleError: ComputedRef<string>;
  anySingleErrorRight: ComputedRef<string>;
}

export default function useErrorsArray(props: FileMultipleInputProps): UseErrorsArray {
  const anySingleError = computed<string>(() => props.error?.find((value) => value && value.length) || '');

  const anySingleErrorRight = computed<string>(() => props.errorRight?.find((value) => value && value.length) || '');

  return {
    anySingleError,
    anySingleErrorRight,
  };
}
