import { ComponentKey, ComponentStatus } from '../types';
import type { ComponentResult } from '../types';
import AbstractFingerprintComponent from '../AbstractFingerprintComponent';

export default class LanguageComponent extends AbstractFingerprintComponent {
  constructor() {
    super(ComponentKey.Language);
  }

  getComponentResult(): Promise<ComponentResult> {
    return this.result([window.navigator.language || ComponentStatus.NotAvailable]);
  }
}
