<script lang="ts" setup>
import { useCssModule } from 'vue';

import { IconName, IconSize } from '@leon-hub/icons';

import { VIcon } from '@components/v-icon';

import { useIsSportlineType } from 'web/src/modules/sportline/composables/core';
import createDisplayValueChangeDirective from 'web/src/utils/directives/createDisplayValueChangeDirective';

import type { RunnerProps } from './types';
import { runnerComponentPropsToRefs, useRunner } from './composables';
import { runnerDataTestElement } from './constants';

interface PrimaryMarketRunnerEmits {
  (e: 'click-runner', event: MouseEvent): void;
}

const props = defineProps<RunnerProps>();
const emit = defineEmits<PrimaryMarketRunnerEmits>();

const cssModule = useCssModule();

const vDirection = {
  updated: createDisplayValueChangeDirective({
    moveUp: cssModule['sportline-primary-market-runner--bet-up'],
    moveDown: cssModule['sportline-primary-market-runner--bet-down'],
    autoDirection: true,
  }),
};

const runnerProps = runnerComponentPropsToRefs(props);
const {
  value,
  price,
  name,
  isEmpty,
  isLocked,
  isSelected,
  addToSlip,
} = useRunner(runnerProps);
const { isLive, isPrematch } = useIsSportlineType(runnerProps.sportlineType);

function onRunnerClick(event: MouseEvent) {
  addToSlip({ event });
  emit('click-runner', event);
}
</script>

<template>
  <button v-auto-id="'PrimaryMarketRunner'"
    v-data-test="{
      el: runnerDataTestElement.runner,
      locked: isLocked,
      selected: isSelected,
      live: isLive,
      prematch: isPrematch,
    }"
    v-direction="{ value: price }"
    type="button"
    :class="{
      [$style['sportline-primary-market-runner']]: true,
      [$style['sportline-primary-market-runner--selected']]: isSelected,
      [$style['sportline-primary-market-runner--bet-up']]: isPermanentBetUp,
      [$style['sportline-primary-market-runner--bet-down']]: isPermanentBetDown,
      [$style['sportline-primary-market-runner--v2']]: isRunnerV2,
      [$style['sportline-primary-market-runner--locked']]: isLocked,
    }"
    :disabled="isLocked && !isSelected"
    @click.stop.prevent="onRunnerClick"
  >
    <span
      v-if="!isRunnerV2"
      v-data-test="{ el: runnerDataTestElement.name }"
      :class="[
        $style['sportline-primary-market-runner__coefficient'],
        $style['sportline-primary-market-runner__coefficient--left'],
      ]"
    >{{ name }}</span>

    <span
      v-data-test="{ el: runnerDataTestElement.price }"
      :class="[
        $style['sportline-primary-market-runner__coefficient'],
        $style['sportline-primary-market-runner__coefficient--right'],
      ]"
    >
      {{ value }}
    </span>

    <span
      v-if="isLocked && !isEmpty"
      v-data-test="{ el: runnerDataTestElement.mask }"
      :class="$style['sportline-primary-market-runner__locked-mask']"
    >
      <VIcon
        v-data-test="{ el: runnerDataTestElement.lockIcon }"
        :name="IconName.LOCK"
        :size="IconSize.SIZE_12"
        :class="$style['sportline-primary-market-runner__locked-icon']"
      />
    </span>
  </button>
</template>

<style module lang="scss">
@import '~web/src/modules/sportline/views/runner/mixins';

.sportline-primary-market-runner {
  $runner: '.sportline-primary-market-runner';
  $sportlineRunnerBreakpointSizeWidth: 440px;
  $sportlineRunnerBreakpointFontWidth: 375px;

  @include runnerDefaultColors;
  @include runnerBase;
  @include runnerOnClickScale;

  gap: 8px;
  justify-content: space-between;
  width: 100%;
  height: 44px;
  padding: 0 10px;
  overflow: hidden;
  line-height: 16px;

  @media (min-width: $sportlineRunnerBreakpointSizeWidth) {
    height: auto;
    padding: 14px 12px;
  }

  @include for-hover {
    &:hover:not([disabled]) {
      background-color: $sportlineRunnerBackgroundColorActive;

      #{$runner}__coefficient {
        &--left,
        &--right {
          color: $sportlineRunnerColorActive;
        }
      }
    }
  }

  &--selected {
    background-color: $sportlineRunnerBackgroundColorActive;

    #{$runner}__coefficient {
      &--left,
      &--right {
        color: $sportlineRunnerColorActive;
      }
    }
  }

  &--bet-up {
    @include runnerBetUp;
  }

  &--bet-down {
    @include runnerBetDown;
  }

  &__coefficient {
    @include lineLimit(2);

    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (min-width: $sportlineRunnerBreakpointSizeWidth) {
      white-space: nowrap;
    }

    &--left {
      @include regular\10\12;

      display: -webkit-box;
      flex: 0 1 auto;
      justify-content: flex-start;
      width: max-content;
      overflow: hidden;
      color: var(--TextPrimary);
      text-align: left;
      letter-spacing: 0.25px;
      word-break: auto-phrase;

      @media (min-width: $sportlineRunnerBreakpointFontWidth) {
        @include regular\14\16\025;
      }

      @media (min-width: $sportlineRunnerBreakpointSizeWidth) {
        display: block;
        width: auto;
        word-break: break-word;
      }
    }

    &--right {
      @include medium\10\12;

      flex: none;
      justify-content: flex-end;
      color: var(--TextDefault);
      letter-spacing: 0;

      @media (min-width: $sportlineRunnerBreakpointFontWidth) {
        @include medium\14\16\025;
      }
    }
  }

  &__locked-icon {
    color: var(--TextDefault);
    fill: var(--TextDefault);
  }

  &__locked-mask {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(var(--Layer2Base), 0.8);
  }

  &--v2 {
    justify-content: center;
    padding: 16px 0;
    color: $sportlineRunnerV2ColorDefault;
    background-color: $sportlineRunnerV2BackgroundColorDefault;

    @include for-hover {
      &:hover:not([disabled]) {
        background-color: $sportlineRunnerV2BackgroundColorActive;

        #{$runner}__coefficient {
          &--left,
          &--right {
            color: $sportlineRunnerV2ColorActive;
          }
        }
      }
    }

    #{$runner}__coefficient {
      &--left,
      &--right {
        @include medium\14\16\025;

        color: $sportlineRunnerV2ColorDefault;
      }
    }

    &#{$runner}--selected {
      background-color: $sportlineRunnerV2BackgroundColorActive;

      #{$runner}__coefficient {
        &--left,
        &--right {
          color: $sportlineRunnerV2ColorActive;
        }
      }
    }

    &#{$runner}--locked {
      #{$runner}__coefficient {
        &--left,
        &--right {
          color: $sportlineRunnerV2ColorLocked;
        }
      }

      #{$runner}__locked-mask {
        top: auto;
        bottom: 2px;
        background-color: transparent;
      }

      #{$runner}__locked-icon {
        color: $sportlineRunnerV2ColorLocked;
        fill: $sportlineRunnerV2ColorLocked;
      }
    }
  }
}
</style>
