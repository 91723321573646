import type { ComputedRef, Ref } from 'vue';
import {
  computed, inject, ref, watch,
} from 'vue';

import type { VFadeCarouselSlideProps } from 'web/src/components/FadeCarousel/VFadeCarouselSlide/types';
import type { FadeCarouselState } from 'web/src/components/FadeCarousel/types';

interface useVFadeCarouselSlideComposable {
  isSlideShown: Ref<boolean>;
  isSlideVisible: ComputedRef<boolean>;
}

export default function useVFadeCarouselSlide(props: VFadeCarouselSlideProps): useVFadeCarouselSlideComposable {
  const isSlideShown = ref<boolean>(false);
  const carouselOptions = inject<FadeCarouselState>('carouselOptions');

  // eslint-disable-next-line max-len
  const isSlideVisible = computed(() => props.currentSlideIndex !== null && props.currentSlideIndex === carouselOptions?.activeSlideIndex);

  watch(isSlideVisible, () => {
    isSlideShown.value = true;
  }, { immediate: true });

  return {
    isSlideShown,
    isSlideVisible,
  };
}
