<script lang="ts" setup>
import { useTheme } from 'web/src/modules/theme/composables';
import VSwitch from 'web/src/components/Switch/VSwitch/VSwitch.vue';
import { SwitchKind } from 'web/src/components/Switch/VSwitch/enums';

interface ThemeSwitcherEmits {
  (e: 'change'): void;
}

const emit = defineEmits<ThemeSwitcherEmits>();

const { isDark } = useTheme();
</script>

<template>
  <div v-auto-id="'ThemeSwitcher'" :class="$style['theme-switcher']">
    <VSwitch
      :kind="SwitchKind.THEME"
      :checked="isDark"
      @change="emit('change')"
    />
  </div>
</template>

<style lang="scss" module>
.theme-switcher {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
</style>
