<script lang="ts" setup>
import { toRef, computed } from 'vue';

import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import { useExtendedEventStore } from 'web/src/modules/sportline/submodules/extended-event/store';
import { createVirtualListGroups } from 'web/src/modules/sportline/submodules/live/utils/createVirtualListGroups';
import { useCanShowSegmentGroup } from 'web/src/modules/sportline/submodules/segment/composables/useCanShowSegmentGroup';
import { useProvideSelectedColumn } from 'web/src/modules/sportline/composables/select-main-market';
import { useProvideIsExpandedSportlineBlock } from 'web/src/modules/sportline/composables/expandable-block';
import type {
  SportsSegmentPageListGroup,
  SportEventsSegmentSelectedFilter,
  SportlineSegmentFilterActiveTransition,
} from 'web/src/modules/sportline/submodules/segment/types';
import type { SelectedColumnInfoElement } from 'web/src/modules/sportline/composables/select-main-market';
import type { ExtendedEventBlock } from 'web/src/modules/sportline/submodules/extended-event/enums';
import VirtualList from 'web/src/modules/sportline/views/virtual-list-renderer/VirtualList.vue';

import { createVirtualListRef } from '../utils';
import { useVirtualListEventElementResize } from './composables/virtual-list';

interface VirtualGroupsListProps {
  groups: SportsSegmentPageListGroup[];
  extendedBlockType?: ExtendedEventBlock;
  canCollapseSport?: boolean;
  isDefaultCollapsedSport?: boolean;
  selectedFilter: SportEventsSegmentSelectedFilter;
  activeTransitionId?: SportlineSegmentFilterActiveTransition;
}

const props = withDefaults(defineProps<VirtualGroupsListProps>(), {
  activeTransitionId: null,
});

const groups = toRef(() => props.groups);
const extendedBlockType = toRef(() => props.extendedBlockType);
const selectedFilter = toRef(() => props.selectedFilter);
const activeTransitionId = toRef(() => props.activeTransitionId);
const canCollapseSport = toRef(() => props.canCollapseSport);
const isDefaultCollapsedSport = toRef(() => props.isDefaultCollapsedSport);

const { createOpenedEventsListRef } = useExtendedEventStore();
const expandedIds = createOpenedEventsListRef(extendedBlockType);

const settingsStore = useSportlineSettingsStore();
const isMarketTypesSelectionEnabled = toRef(() => settingsStore.isMarketTypesSelectionEnabled);

const {
  canShowLiveForGroup,
  canShowPrematchForGroup,
} = useCanShowSegmentGroup({ selectedFilter, activeTransitionId });

const {
  customEventHeightMap,
  onResizeEventElement,
} = useVirtualListEventElementResize({ expandedIds });
const expandedHeights = toRef(() => customEventHeightMap);

const displayedGroups = createVirtualListGroups(groups, {
  canShowLiveForGroup,
  canShowPrematchForGroup,
});

const sportlineColumnInfoElements = computed<SelectedColumnInfoElement[]>(() => displayedGroups.value.map(({ columnInfo }) => columnInfo));

const { expandedStateMap: expandedSportStateMap } = useProvideIsExpandedSportlineBlock({
  defaultValueRef: computed(() => !isDefaultCollapsedSport.value),
});
useProvideSelectedColumn({ sportlineElements: sportlineColumnInfoElements });

const elements = createVirtualListRef(displayedGroups, {
  canCollapseSport,
  isDefaultCollapsedSport,
  expandedSportStateMap,
});
</script>

<template>
  <VirtualList v-auto-id="'VirtualGroupsList'"
    :elements="elements"
    :expanded-ids="expandedIds"
    :expanded-heights="expandedHeights"
    :extended-block-type="extendedBlockType"
    :is-market-types-selection-enabled="isMarketTypesSelectionEnabled"
    @event-resize="onResizeEventElement"
  />
</template>
