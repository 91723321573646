import type { RouteLocationNormalized } from 'vue-router';

import type { AppVueRouter } from 'web/src/modules/core/services/router/types';

export type GetRouteSeoConfig = Pick<RouteLocationNormalized, 'name' | 'params'>;

export abstract class AbstractPageSeoController {
  abstract getSeoConfig(
    router: AppVueRouter,
    from: RouteLocationNormalized,
    to: RouteLocationNormalized,
  ): Promise<GetRouteSeoConfig>;

  abstract loadSeoMetaDataFor(
    route: GetRouteSeoConfig,
  ): void;
}
