import type { Ref } from 'vue';
import {
  computed,
  ref,
  toRef,
} from 'vue';

import {
  getSpintaxMetaData,
  RequestOptionsPriority,
} from '@leon-hub/api-sdk';
import type MetaInfo from '@leon-hub/routing-config/src/types/MetaInfo';
import { RequestGroupScore24 } from '@leon-hub/api';

import type {
  GetSpintaxSeoMetaDataResponse,
  SpintaxSeoMetaData,
  SpintaxSeoMetaDataRouteLocation,
} from 'web/src/modules/seo/types';
import { getSpintaxSeoMetaDataRequestOptionsForRoute } from 'web/src/modules/seo/utils/spintax';
import { isSpintaxSeoMetaData } from 'web/src/modules/seo/guards/isSpintaxSeoMetaData';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';

import { useSpintaxSeoMetaDataCache } from './useSpintaxSeoMetaDataCache';

interface UseSpintaxMetaDataComposable {
  metaData: Ref<Maybe<SpintaxSeoMetaData>>;
  metaInfo: Ref<Optional<MetaInfo>>;
  updateActualMetaData(route: SpintaxSeoMetaDataRouteLocation): Promise<void>;
}

export function useSpintaxSeoMetaInfo(props: {
  metaData: Ref<Maybe<SpintaxSeoMetaData>>;
}): Ref<Optional<MetaInfo>> {
  const { metaData } = props;

  return computed<Optional<MetaInfo>>(() => {
    if (!metaData.value) { return undefined; }

    const titleConfig = metaData.value.title;
    const metaConfig = metaData.value.meta;

    return {
      title: titleConfig
        ? titleConfig.filter((value) => 'content' in value)
        : undefined,
      meta: metaConfig
        ? metaConfig.flatMap((config) => Object.keys(config)
          .map((name) => ({ name, content: config[name] })))
        : undefined,
    };
  });
}

export function useSpintaxSeoMetaData(): UseSpintaxMetaDataComposable {
  const siteConfigStore = useSiteConfigStore();
  const isMetaBetCmsSpintaxEnabled = toRef(siteConfigStore, 'isMetaBetCmsSpintaxEnabled');

  const api = useGraphqlClient();

  const cache = useSpintaxSeoMetaDataCache();

  const metaData = ref<Maybe<SpintaxSeoMetaData>>(null);

  const metaInfo = useSpintaxSeoMetaInfo({ metaData });

  async function fetchMetaData(
    route: SpintaxSeoMetaDataRouteLocation,
  ): Promise<Maybe<GetSpintaxSeoMetaDataResponse>> {
    const options = getSpintaxSeoMetaDataRequestOptionsForRoute(route);

    if (!options || !isMetaBetCmsSpintaxEnabled.value) {
      return null;
    }

    return getSpintaxMetaData(
      api,
      (node) => node.queries.system.cms.getSpintaxMetadata,
      { options },
      { silent: true, priority: RequestOptionsPriority.LOW, group: RequestGroupScore24 },
    );
  }

  async function updateActualMetaData(route: SpintaxSeoMetaDataRouteLocation): Promise<void> {
    let result = cache.getCacheForRoute(route);

    if (!result) {
      const response = await fetchMetaData(route);
      const metadata = response?.metadata;
      result = isSpintaxSeoMetaData(metadata) ? metadata : null;
      if (result) { cache.setCacheForRoute(route, result); }
    }

    metaData.value = result ?? null;
  }

  return {
    metaData,
    metaInfo,
    updateActualMetaData,
  };
}
