<script lang="ts" setup>
import useLeaderBoardParticipant
  from 'web/src/modules/promotions/components/LeaderBoardParticipant/composables/useLeaderBoardParticipant';

import type { LeaderBoardParticipantProps, LeaderBoardParticipantEmits } from './types/index';

const props = defineProps<LeaderBoardParticipantProps>();
const emits = defineEmits<LeaderBoardParticipantEmits>();

const { score } = useLeaderBoardParticipant(props, emits);
</script>

<template>
  <tr v-auto-id="'LeaderBoardParticipant'"
    v-if="participant"
    :class="{
      [$style['leader-board__row']]: true,
      [$style['leader-board__row--active']]: active,
      [$style['leader-board__row--sticky-bottom']]: sticky,
      [$style['leader-board__row--first-place']]: participant?.place === 1,
      [$style['leader-board__row--second-place']]: participant?.place === 2,
      [$style['leader-board__row--third-place']]: participant?.place === 3,
    }"
  >
    <td
      :class="{
        [$style['leader-board__cell']]: true,
        [$style['leader-board__cell--place']]: true,
      }"
    >
      {{ participant.place?.toString() }}
    </td>
    <td
      :class="{
        [$style['leader-board__cell']]: true,
        [$style['leader-board__cell--name']]: true,
      }"
    >
      {{ participant.name }}
    </td>
    <td
      :class="{
        [$style['leader-board__cell']]: true,
        [$style['leader-board__cell--prize']]: true,
      }"
    >
      {{ participant.prize }}
    </td>
    <td
      :class="{
        [$style['leader-board__cell']]: true,
        [$style['leader-board__cell--score']]: true,
      }"
    >
      {{ score }}
    </td>
  </tr>
</template>

<style lang="scss" module>
.leader-board {
  $self: &;

  &__row {
    @include regular\14\20\025;

    position: relative;
    display: grid;
    grid-template-columns: $leaderBoardRowTemplateColumns;
    grid-column-gap: 8px;
    padding: 12px;
    margin-bottom: 2px;
    color: var(--TextPrimary);
    background-color: var(--Layer1);
    border: 0;

    &:last-child {
      border-radius: 0 0 5px 5px;
    }

    &--first-place,
    &--second-place,
    &--third-place {
      &:after {
        @include z-index(base);

        position: absolute;
        top: 12px;
        right: 0;
        bottom: 0;
        left: 12px;
        width: 20px;
        height: 20px;
        content: '';
        background-size: 20px 20px;

        @include is-app-layout-desktop {
          left: 14px;
        }
      }

      #{$self}__cell {
        @include medium\14\20\025;

        color: var(--TextDefault);

        &--place {
          font-size: 0;
        }
      }
    }

    &--first-place {
      &:after {
        background-image: url('~web/src/assets/images/1st.svg');
      }
    }

    &--second-place {
      &:after {
        background-image: url('~web/src/assets/images/2nd.svg');
      }
    }

    &--third-place {
      &:after {
        background-image: url('~web/src/assets/images/3rd.svg');
      }
    }

    &--active {
      @include medium\14\20\025;

      color: var(--TextDefault);
      background-color: var(--Highlight);

      #{$self}__cell--name {
        color: var(--BrandDefault);
      }
    }

    &--sticky-bottom {
      @include z-index(above-base);

      position: sticky;
      right: 0;
      bottom: 0;
      left: 0;
    }

    @include is-app-layout-desktop {
      grid-column-gap: 16px;
      padding: 12px 16px;
    }
  }

  &__cell {
    @include z-index(above-base);

    display: block;
    padding: 0;
    hyphens: auto;
    text-align: center;
    word-break: break-word;
    word-wrap: break-word;
    overflow-wrap: break-word;

    &--place {
      text-align: left;
      white-space: nowrap;
    }

    &--score {
      text-align: right;
    }
  }
}
</style>
