import type {
  Ref,
  ShallowRef,
} from 'vue';
import {
  onMounted,
  onUnmounted,
  unref,
  watch,
} from 'vue';

import { isEmptyElementRef } from '../template-refs';
import type { ComponentIntersectiveInstance } from '../types';

type IntersectionElementTemplateRef = Element | ComponentIntersectiveInstance | undefined | null;
type IntersectionElement = Ref<IntersectionElementTemplateRef> | ShallowRef<IntersectionElementTemplateRef> | Element;

function getHtmlElement(element: Element | ComponentIntersectiveInstance | undefined | null): Element | undefined {
  if (!element) {
    return undefined;
  }

  if (element instanceof Element) {
    return element;
  }

  return element.$el;
}

export default function useIntersectionObserver(
  element: IntersectionElement,
  callback: (isIntersecting: boolean) => (void | Promise<void>),
  options?: IntersectionObserverInit,
  onceOnIntersecting?: boolean,
): void {
  if (typeof IntersectionObserver === 'undefined') {
    return;
  }

  let observer: IntersectionObserver | null = null;

  function disconnectObserver(): void {
    if (observer) {
      observer.disconnect();
      observer = null;
    }
  }

  function connectObserver(): void {
    const unrefElement = getHtmlElement(unref(element));
    if (!unrefElement) return;
    observer = new IntersectionObserver((entries) => {
      const isIntersecting = entries[0]?.isIntersecting;

      if (onceOnIntersecting && isIntersecting) {
        void callback(isIntersecting);
        disconnectObserver();
      } else {
        void callback(isIntersecting);
      }
    }, options);

    observer.observe(unrefElement);
  }

  if (isEmptyElementRef(element)) {
    const unwatchElement = watch(element, (newValue) => {
      if (newValue) {
        connectObserver();
        unwatchElement();
      }
    });
  } else {
    onMounted(connectObserver);
  }

  onUnmounted(disconnectObserver);
}
