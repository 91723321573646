import type { Ref } from 'vue';
import { computed } from 'vue';

import type { ValueOfTimeLeft, DisplayedTimeLeft } from './types';
import useTimeLeft from './useTimeLeft';

interface UseCountdownProps {
  displaySecondsOnMaxMinutes?: Ref<number | false>;
  useLeadingZero?: Ref<boolean>;
}

interface UseCountdownComposable {
  timeLeftValue: Ref<ValueOfTimeLeft>;
  displayedTimeLeft: Ref<DisplayedTimeLeft>;
}

export default function useCountdown(
  targetTime: Ref<number>,
  now: Ref<number>,
  props: UseCountdownProps = {},
): UseCountdownComposable {
  const {
    daysLeft,
    daysLeftPretty,
    hoursLeft,
    hoursLeftPretty,
    minutesLeft,
    minutesLeftPretty,
    secondsLeft,
    secondsLeftPretty,
  } = useTimeLeft(targetTime, now, {
    useLeadingZero: props.useLeadingZero,
  });

  const timeLeftValue = computed<ValueOfTimeLeft>(() => ({
    days: daysLeft.value,
    hours: hoursLeft.value,
    minutes: minutesLeft.value,
    seconds: secondsLeft.value,
  }));
  const displayedTimeLeft = computed<DisplayedTimeLeft>(() => {
    const displaySecondsOnMaxMinutes = props.displaySecondsOnMaxMinutes?.value ?? 5;
    const showSeconds = displaySecondsOnMaxMinutes === false
      ? true
      : (daysLeft.value === 0)
      && (hoursLeft.value === 0)
      && (minutesLeft.value < displaySecondsOnMaxMinutes);

    return {
      days: daysLeftPretty.value,
      hours: hoursLeftPretty.value,
      minutes: minutesLeftPretty.value,
      seconds: showSeconds ? secondsLeftPretty.value : false,
    };
  });

  return {
    timeLeftValue,
    displayedTimeLeft,
  };
}
