import random from 'lodash/random';
import round from 'lodash/round';

function generateTimeInterval(min: number, max: number, duration: number): number[] {
  let sum = 0;
  const intervals: number[] = [0];

  while (sum + max < duration) {
    sum += random(min, max);
    intervals.push(round(sum / duration, 2));
  }
  return intervals;
}

export default class RandomIntervals {
  private intervals: number[] = [];

  constructor(min: number, max: number, summ: number) {
    this.intervals = generateTimeInterval(min, max, summ);
  }

  query(value: number): number {
    for (let index = this.intervals.length - 1; index >= 0; index -= 1) {
      if (value >= this.intervals[index]) {
        return this.intervals[index];
      }
    }

    return 1;
  }
}
