export enum JumbotronIconKind {
  DEFAULT = 'default',
  SUCCESS = 'success',
  /** @deprecated only green (success) icon exists in design */
  ALERT = 'alert',
  /** @deprecated only green (success) icon exists in design */
  ERROR = 'error',
}

export default JumbotronIconKind;
