import type { ValidationError } from 'jsonschema';

import {
  FormErrorKeyword,
} from '@leon-hub/form-utils';

import type {
  FormUiSchema,
  FormValidatorErrorPattern,
  FormControlError,
  FormMessageHandlerList,
  FormMessageHandler,
  FormWidget,
} from 'web/src/components/Form/types';
import getWidget from 'web/src/components/Form/utils/getWidget';
import { getLabel } from 'web/src/components/Form/utils/uiSchema';

function hasRequiredParameter(error: ValidationError): boolean {
  return error.name === 'required';
}

function hasPatternParameter(error: ValidationError): boolean {
  return error.name === 'pattern';
}

function createErrorDataPath(parts: (string | number)[]): string {
  return parts.length > 0 ? `/${parts.join('/')}` : '';
}

function normalizeDataPath(error: ValidationError) {
  const path = createErrorDataPath(error.path).slice(1);
  if (hasRequiredParameter(error)) {
    const { argument } = error;

    if (!path) {
      return `${argument}`;
    }

    return `${path}/${argument}`;
  }

  return path;
}

function hasErrorKeyword(keyword: string): keyword is FormErrorKeyword {
  const key = (keyword.charAt(0).toUpperCase() + keyword.slice(1)) as keyof typeof FormErrorKeyword;
  return !!FormErrorKeyword[key];
}

function hasLimitParameter(error: ValidationError): boolean {
  return error.name === 'minLength' || error.name === 'maxLength';
}

function getErrorMessageByPattern(
  errorPattern: FormValidatorErrorPattern,
  keyword: string,
  widget: FormWidget,
): string {
  const { byPattern = {} } = errorPattern;

  const byPatternObject = byPattern[widget] ?? {};
  const byPatternError: string = byPatternObject[keyword] || '';

  return byPatternError;
}

function getErrorPattern(
  errorPattern: FormValidatorErrorPattern,
  keyword: FormErrorKeyword,
  widget?: FormWidget,
): string {
  const { common = {}, byWidget } = errorPattern;

  const commonPattern: string = common[keyword] || '';

  if (byWidget && widget) {
    const byWidgetKeyword = byWidget[widget] ?? {};
    const byWidgetError: string = byWidgetKeyword[keyword] || '';

    return byWidgetError || commonPattern;
  }

  return commonPattern;
}

const patternMinMaxLengthHandler: FormMessageHandler = (field, template, error) => {
  let result = template.replace(/%field%/g, field);
  if (hasLimitParameter(error)) {
    result = result.replace(/%limit%/g, `${error.argument}`);
  }
  return result;
};

const patternMessageHandlers: FormMessageHandlerList = {
  [FormErrorKeyword.Required]: (field, template) => template.replace(/%field%/g, field.toLowerCase()),

  [FormErrorKeyword.Pattern]: (field, template, error) => {
    let result = template.replace(/%field%/g, field.toLowerCase());
    if (hasPatternParameter(error)) {
      result = result.replace(/%pattern%/g, error.argument);
    }
    return result;
  },

  [FormErrorKeyword.MinLength]: patternMinMaxLengthHandler,

  [FormErrorKeyword.MaxLength]: patternMinMaxLengthHandler,

  [FormErrorKeyword.MinItems]: (field, template) => template.replace(/%field%/g, field.toLowerCase()),
};

function getErrorMessage(
  keyword: FormErrorKeyword,
  field: string,
  patternMessage: string,
  error: ValidationError,
): string {
  const patternMessageHandler = patternMessageHandlers[keyword];
  if (!patternMessageHandler) {
    return patternMessage;
  }

  return patternMessageHandler(field, patternMessage, error);
}

function getFieldFromDataPath(dataPath: string): string {
  return dataPath.split('/')[0];
}

function createErrorMapper(
  uiSchema: FormUiSchema,
  errorPattern: FormValidatorErrorPattern,
) {
  return (error: ValidationError): FormControlError => {
    const dataPath = normalizeDataPath(error);
    const field = dataPath.split('/')[0];
    const errorKeyword = error.name;
    const errorMessage = error.message.replace(error.property, 'field');

    // keyword is string but we need FormErrorKeyword
    // try to typecasting string -> FormErrorKeyword
    if (!hasErrorKeyword(errorKeyword)) {
      return {
        keyword: errorKeyword,
        message: errorMessage,
        field,
        dataPath,
      };
    }

    const fieldForMessage = getFieldFromDataPath(dataPath);
    // it returns widget type for root widget, since complex fields consist from simple fields,
    // for returning correct error message we need nested widget type
    const widget = getWidget(uiSchema, fieldForMessage);

    const errorMessageByPattern = getErrorMessageByPattern(errorPattern, error.argument, widget) || null;

    const patternMessage = errorMessageByPattern || getErrorPattern(errorPattern, errorKeyword, widget) || errorMessage;

    const label = getLabel(uiSchema, getFieldFromDataPath(dataPath));

    const message = getErrorMessage(errorKeyword, label || fieldForMessage, patternMessage, error);

    return {
      keyword: errorKeyword,
      message,
      field,
      dataPath,
    };
  };
}

export default createErrorMapper;
