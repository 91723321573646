import type { Ref } from 'vue';
import {
  nextTick,
  ref,
  watch,
} from 'vue';

import {
  onComponentActivated,
  useIntersectionObserver,
} from '@leon-hub/vue-utils';

import type { VSwiperRef } from 'web/src/components/Swiper/VSwiper/types';

export interface SwiperToActiveScrollComposable {
  swiper: Ref<VSwiperRef | undefined>;
  slideToActive(smooth?: boolean): Promise<void>;
  scrollToSlide(index?: number, smooth?: boolean): void;
}

export default function useSwiperToActiveScroll(
  activeIndex: Ref<number>,
  swiperRef?: Ref<VSwiperRef | undefined>,
): SwiperToActiveScrollComposable {
  const swiper = swiperRef ?? ref<VSwiperRef>();

  watch(activeIndex, () => {
    void slideToActive(true);
  });

  function scrollToSlide(index = 0, smooth = false): void {
    if (index < 0) {
      return;
    }

    swiper.value?.slideToSlide(index, {
      isCentered: true,
      smooth,
      speed: 300,
    });
  }

  async function slideToActive(smooth = false): Promise<void> {
    await nextTick();
    scrollToSlide(activeIndex.value, smooth);
  }

  onComponentActivated(slideToActive);

  useIntersectionObserver(swiper, () => {
    void slideToActive();
  }, undefined, true);

  return {
    swiper,
    slideToActive,
    scrollToSlide,
  };
}
