import { logger } from '@leon-hub/logging';

import copyTextToClipboard from 'web/src/utils/browser/copyTextToClipboard';
import { useTooltipStore } from 'web/src/modules/dialogs/store';
import type { Tooltip } from 'web/src/modules/dialogs/store/types';
import { useI18n } from 'web/src/modules/i18n/composables';

export interface CopyToClipBoardComposable {
  copy(tooltip: Tooltip): Promise<void>;
}

export default function useCopyToClipboard(): CopyToClipBoardComposable {
  async function copy(tooltip: Tooltip): Promise<void> {
    if (process.env.VUE_APP_RENDERING_SSR) {
      return;
    }

    const { $translate } = useI18n();

    try {
      await copyTextToClipboard(tooltip.data);
      useTooltipStore().showTooltip({
        ...tooltip,
        notificationText: tooltip.notificationText || $translate('WEB2_COPY_TEXT').value,
      });
    } catch (error) {
      logger.warn('Failed to copy text to clipboard', error);
    }
  }

  return {
    copy,
  };
}
