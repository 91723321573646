import {
  isOptionalNumber,
  isOptionalBoolean,
  isOptionalString,
  isObject,
} from '@leon-hub/guards';

import type { DateInputCommonProperties } from '../types';
import isInputHintProps from './isInputHintProps';

export default function isDateInputCommonProperties(value: unknown): value is DateInputCommonProperties {
  if (!isObject(value)) {
    return false;
  }
  return isOptionalString(value.name)
    && isOptionalString(value.label)
    && isOptionalString(value.placeholder)
    && isOptionalString(value.value)
    && isOptionalBoolean(value.isBirthday)
    && isOptionalBoolean(value.emitFilled)
    && isOptionalNumber(value.legalAge)
    && isInputHintProps(value);
}
