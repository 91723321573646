<script lang="ts" setup>
import { ref } from 'vue';

import { useIntersectionObserver } from '@leon-hub/vue-utils';

import { useSportlineLayoutSettings } from 'web/src/modules/sportline/composables/settings';

interface SportlineEventDetailsHeadlineHolderEmit {
  (e: 'visibility-changed', state: boolean): void;
}

const emit = defineEmits<SportlineEventDetailsHeadlineHolderEmit>();

const element = ref<Optional<HTMLDivElement>>();

let interactionEnabled = false;

function checkVisibility(isIntersecting: boolean): void {
  if (!interactionEnabled) { return; }
  emit('visibility-changed', isIntersecting);
}

useIntersectionObserver(element, checkVisibility, {
  rootMargin: '0px',
  threshold: 0.3,
});

// start to emit events only after first full render
useIntersectionObserver(element, (isIntersecting) => {
  if (!isIntersecting) { return; }
  interactionEnabled = true;
}, {
  rootMargin: '0px',
  threshold: 0.9,
}, true);

const { isBetSwipeEventDetailsLayoutEnabled } = useSportlineLayoutSettings();
</script>

<template>
  <div v-auto-id="'SportlineEventDetailsHeadlineHolder'"
    ref="element"
    :class="{
      [$style['sport-event-details-headline-holder']]: true,
      [$style['sport-event-details-headline-holder--large-gaps']]: null && isBetSwipeEventDetailsLayoutEnabled,
      [$style['sport-event-details-headline-holder--right-angle']]: null && isBetSwipeEventDetailsLayoutEnabled,
    }"
  >
    <slot />
  </div>
</template>

<style module lang="scss">
@include for-layout using ($isDesktop) {
  .sport-event-details-headline-holder {
    @if not $isDesktop {
      margin: 0 8px;
      overflow: hidden;
      border-radius: 5px;

      &--large-gaps {
        margin: 0 16px;
      }

      &--right-angle {
        border-radius: 0;
      }
    }
  }
}
</style>
