import RouteName from './RouteName';
import type { ICasinoRouteName } from './CasinoRouteName';
import type BaseRouteName from './BaseRouteName';
import type SportlineRouteName from './SportlineRouteName';
import type PromoRouteName from './PromoRouteName';
import type RegistrationRouteName from './RegistrationRouteName';
import type RegistrationCompletionRouteName from './RegistrationCompletionRouteName';

type BaseRouteNameType = typeof RouteName[keyof typeof RouteName];

export type SharedRouteNameType = ICasinoRouteName
| BaseRouteName
| SportlineRouteName
| PromoRouteName
| RegistrationRouteName
| RegistrationCompletionRouteName;

export type { BaseRouteNameType };
export type { ICasinoRouteName };

const baseRouteNameNames = Object.values(RouteName);
export function isBaseRouteName(value: unknown): value is BaseRouteNameType {
  return baseRouteNameNames.includes(value as BaseRouteNameType);
}

export { default as isEGSRoute } from './isEGSRoute';
export { default as CasinoRouteName } from './CasinoRouteName';
export { isPromoRouteName } from './PromoRouteName';
export { default as isSportlineRoute } from './isSportlineRoute';
export { RouteName };
