import { defineStore } from 'pinia';
import {
  ref,
  toRef,
  watch,
} from 'vue';

import { logger } from '@leon-hub/logging';
import { getUniqueDeviceID } from '@leon-hub/cordova';
import { DeviceFingerprintJsService } from '@leon-hub/device-fingerprint-js';
import { DeviceFingerprintBrowserService } from '@leon-hub/device-fingerprint-browser';
import {
  Deferred,
  murmurHash,
} from '@leon-hub/utils';
import { assert } from '@leon-hub/guards';

import {
  useRootStore,
  useSiteConfigStore,
} from 'web/src/modules/core/store';

import { DeviceFingerprintType } from '../enums';
import isFingerprintTypeEnabled from '../utils/isFingerprintTypeEnabled';

// eslint-disable-next-line sonarjs/cognitive-complexity
const useIdentityStore = defineStore('Identity', () => {
  const deviceFingerprints = ref<Partial<Record<DeviceFingerprintType, string>>>({});

  let getDeviceFingerprint: (fpType?: DeviceFingerprintType) => Promise<string>;
  let getFingerPrintFromStorage: (fpType: DeviceFingerprintType) => Promise<string>;

  if (process.env.VUE_APP_PLATFORM_CORDOVA) {
    getDeviceFingerprint = async (): Promise<string> => {
      const deviceFingerprintFromState = deviceFingerprints.value[DeviceFingerprintType.DEVICE_FINGERPRINT_CORDOVA];
      if (deviceFingerprintFromState) {
        return deviceFingerprintFromState;
      }

      const cordovaDeviceUUID = await getUniqueDeviceID();
      if (cordovaDeviceUUID) {
        deviceFingerprints.value[DeviceFingerprintType.DEVICE_FINGERPRINT_CORDOVA] = cordovaDeviceUUID;
      }

      return cordovaDeviceUUID || '';
    };

    getFingerPrintFromStorage = () => getDeviceFingerprint();
  } else {
    const deviceFingerprintJsService = new DeviceFingerprintJsService();
    const deviceFingerprintBrowserService = new DeviceFingerprintBrowserService();

    const siteConfigStore = useSiteConfigStore();
    const isFingerprintJsEnabled = toRef(siteConfigStore, 'isFingerprintJsEnabled');
    const isBrowserFingerprintEnabled = toRef(siteConfigStore, 'isBrowserFingerprintEnabled');
    const fingerprintJsBlock = toRef(siteConfigStore, 'fingerprintJsBlock');

    const rootStore = useRootStore();

    let deferredRequest: Deferred<void> | null = null;

    let isBrowserFingerprintInitialized = false;
    watch(isBrowserFingerprintEnabled, (newValue) => {
      if (newValue && !isBrowserFingerprintInitialized) {
        deviceFingerprintBrowserService.init({
          hasher: murmurHash,
          debug: rootStore.isDevIP,
          componentExecutingTimeoutMs: 1000,
          visitorIdTtl: 43200, // TODO: move to scg
        });
        isBrowserFingerprintInitialized = true;
      }
    }, {
      immediate: true,
    });

    let isJsFingerprintInitialized = false;
    watch(isFingerprintJsEnabled, (newValue) => {
      if (newValue && !isJsFingerprintInitialized) {
        assert(fingerprintJsBlock.value);

        deviceFingerprintJsService.init({
          apiKey: fingerprintJsBlock.value.apiKey,
          endpoint: fingerprintJsBlock.value.endpointSubdomain,
          visitorIdTtl: fingerprintJsBlock.value.visitorIdTtl,
        });
        isJsFingerprintInitialized = true;
      }
    }, {
      immediate: true,
    });

    getFingerPrintFromStorage = (fpType: DeviceFingerprintType): Promise<string> => {
      if (!isFingerprintTypeEnabled(
        isFingerprintJsEnabled.value,
        isBrowserFingerprintEnabled.value,
        fpType,
      )) {
        return Promise.resolve('');
      }

      const deviceFingerprintFromState = deviceFingerprints.value[fpType];
      if (deviceFingerprintFromState) {
        return Promise.resolve(deviceFingerprintFromState);
      }

      let storedFingerprint = null;
      if (fpType === DeviceFingerprintType.DEVICE_FINGERPRINT_JS) {
        try {
          storedFingerprint = deviceFingerprintJsService.getFingerprintFromStorage();
        } catch (error) {
          logger.error('DEVICE_FINGERPRINT_JS error', error);
        }
      } else if (fpType === DeviceFingerprintType.DEVICE_FINGERPRINT_BROWSER) {
        try {
          storedFingerprint = deviceFingerprintBrowserService.getFingerprintFromStorage();
        } catch (error) {
          logger.error('DEVICE_FINGERPRINT_BROWSER error', error);
        }
      }

      if (storedFingerprint) {
        deviceFingerprints.value[fpType] = storedFingerprint;
      }

      return Promise.resolve(storedFingerprint || '');
    };

    const getFingerPrintByType = async (fpType: DeviceFingerprintType): Promise<string> => {
      const fingerprint = await getFingerPrintFromStorage(fpType);

      if (fingerprint) {
        return fingerprint;
      }

      if (!isFingerprintTypeEnabled(
        isFingerprintJsEnabled.value,
        isBrowserFingerprintEnabled.value,
        fpType,
      )) {
        return Promise.resolve('');
      }

      let storedFingerprint = null;
      if (fpType === DeviceFingerprintType.DEVICE_FINGERPRINT_JS) {
        try {
          storedFingerprint = await deviceFingerprintJsService.generateFingerprint();
        } catch (error) {
          logger.error('DEVICE_FINGERPRINT_JS error', error);
        }
      } else if (fpType === DeviceFingerprintType.DEVICE_FINGERPRINT_BROWSER) {
        try {
          storedFingerprint = await deviceFingerprintBrowserService.generateFingerprint();
        } catch (error) {
          logger.error('DEVICE_FINGERPRINT_BROWSER error', error);
        }
      }

      if (storedFingerprint) {
        deviceFingerprints.value[fpType] = storedFingerprint;
      }

      return Promise.resolve(storedFingerprint || '');
    };

    const isOfFpType = (deviceType: DeviceFingerprintType, fpType?: DeviceFingerprintType): boolean => (
      !fpType || fpType === deviceType
    );

    getDeviceFingerprint = async (fpType?: DeviceFingerprintType): Promise<string> => {
      if (deferredRequest) {
        await deferredRequest.promise;
      }

      deferredRequest = new Deferred<void>();
      let fingerPrint = isOfFpType(DeviceFingerprintType.DEVICE_FINGERPRINT_JS, fpType)
        ? await getFingerPrintByType(DeviceFingerprintType.DEVICE_FINGERPRINT_JS)
        : '';

      if (!fingerPrint && isOfFpType(DeviceFingerprintType.DEVICE_FINGERPRINT_BROWSER, fpType)) {
        fingerPrint = await getFingerPrintByType(DeviceFingerprintType.DEVICE_FINGERPRINT_BROWSER);
      }

      deferredRequest?.resolve();
      deferredRequest = null;

      return fingerPrint;
    };
  }

  return {
    deviceFingerprints,
    getDeviceFingerprint,
    getFingerPrintFromStorage,
  };
});

export default useIdentityStore;
