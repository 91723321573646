import { circleStrokeMap } from 'web/src/components/CircularProgress/VCircularProgress/sizes/circularProgressSizes';
import { CircleSize } from 'web/src/components/CircularProgress/VCircularProgress/enums';

import type { VCircularProgressProps } from '../../types';

export default function getCircleStrokeWidth(
  props: Pick<VCircularProgressProps, 'circleSize'>,
): number {
  return circleStrokeMap[props.circleSize || CircleSize.DEFAULT];
}
