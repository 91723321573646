<script lang="ts" setup>
import { normalizeClass } from 'vue';

import { useVAnimationStarter } from './composables';

interface VAnimationStarterProps {
  rootMargin?: string;
  threshold?: number;
  animatedClass?: string | Record<string, boolean> | string[];
}

const props = withDefaults(defineProps<VAnimationStarterProps>(), {
  rootMargin: '',
  threshold: 0,
});
const {
  animationContainer,
  isAnimated,
} = useVAnimationStarter(props);
</script>

<template>
  <div v-auto-id="'VAnimationStarter'"
    ref="animationContainer"
    :class="{
      'anim-starter': true,
      [normalizeClass(animatedClass)]: isAnimated
    }"
  >
    <slot />
  </div>
</template>
