import type { Ref } from 'vue';
import { ref, computed } from 'vue';

import {
  promotionActionButtonStateInfo,
  promotionActionButtonTypeCustom,
  promotionActionButtonTypeDeposit,
  promotionActionButtonTypeExchange,
  promotionActionButtonTypeExchanged,
  promotionActionButtonTypeIdentificationNeeded,
  promotionActionButtonTypeLeaderboard,
  promotionActionButtonTypeLogin,
  promotionActionButtonTypeNone,
  promotionActionButtonTypeOnBonusWallet,
  promotionActionButtonTypeParticipate,
  promotionActionButtonTypeParticipating,
  promotionActionButtonTypeParticipationProcessing,
  promotionActionButtonTypePlay,
} from '@leon-hub/api-sdk';
import { BusEvent, useBusSafeSubscribe } from '@leon-hub/event-bus';

import type { PromotionButtonEmits, PromotionButtonProps } from 'web/src/modules/promotions/components/PromotionButton/types';
import type { VButtonProps } from 'web/src/components/Button/VButton/types';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { useFormatMoney } from 'web/src/modules/money/composables';
import { useI18n } from 'web/src/modules/i18n/composables';

export interface PromotionButtonComposable {
  onClick(): void;
  showButtonStatus: Ref<boolean>;
  buttonLabel: Ref<string>;
  isStatusIcon: Ref<boolean>;
  showButton: Ref<boolean>;
  buttonProperties: Ref<VButtonProps>;
  isStickied: Ref<boolean>;
  promotionButton: Ref<HTMLElement | undefined>;
  onComponentMounted: () => void;
}
export default function usePromotionButton(
  props: PromotionButtonProps,
  emit: PromotionButtonEmits,
): PromotionButtonComposable {
  const { $translate } = useI18n();
  const formatMoney = useFormatMoney();

  const isStickied = ref(true);
  const promotionButton = ref<HTMLElement>();
  function onClick(): void {
    switch (props.buttonItem.buttonType) {
      case promotionActionButtonTypeParticipate:
        emit('participate');
        break;
      case promotionActionButtonTypeLeaderboard:
        emit('leaderboard');
        break;
      case promotionActionButtonTypeExchange:
        emit('exchange');
        break;
      case promotionActionButtonTypeLogin:
        emit('login');
        break;
      case promotionActionButtonTypeOnBonusWallet:
        emit('on-bonus-wallet');
        break;
      case promotionActionButtonTypeCustom:
        emit('custom');
        break;
      case promotionActionButtonTypePlay:
        emit('play');
        break;
      case promotionActionButtonTypeIdentificationNeeded:
        emit('identification-needed');
        break;
      case promotionActionButtonTypeDeposit:
        emit('deposit');
        break;
      default:
        break;
    }
  }

  const buttonSharedProperties = computed<VButtonProps>(() => ({
    fullWidth: true,
    disabled: props.isDisabled,
    isUppercase: false,
  }));

  const buttonActive = computed<VButtonProps>(() => ({
    ...buttonSharedProperties.value,
    kind: ButtonKind.PRIMARY,
  }));

  const buttonLoading = computed<VButtonProps>(() => ({
    ...buttonSharedProperties.value,
    isLoading: true,
  }));

  const buttonProperties = computed<VButtonProps>(() => {
    if (props.buttonItem?.buttonType === promotionActionButtonTypeParticipationProcessing) {
      return buttonLoading.value;
    }
    return buttonActive.value;
  });

  const showButton = computed(() => {
    const { buttonType, state } = props.buttonItem;
    return buttonType !== promotionActionButtonTypeNone && state !== promotionActionButtonStateInfo;
  });

  const showButtonStatus = computed(() => {
    const { buttonType, state } = props.buttonItem;
    return buttonType !== promotionActionButtonTypeNone && state === promotionActionButtonStateInfo;
  });

  const isStatusIcon = computed(() => {
    const { buttonType } = props.buttonItem;
    return buttonType === promotionActionButtonTypeParticipating
      || buttonType === promotionActionButtonTypeLeaderboard
      || buttonType === promotionActionButtonTypeExchanged;
  });

  const buttonLabel = computed(() => {
    const {
      buttonType, title, value,
    } = props.buttonItem;
    switch (buttonType) {
      case promotionActionButtonTypeOnBonusWallet:
        return `${formatMoney(value)} ${title.toUpperCase()}`;
      case promotionActionButtonTypeExchange:
      case promotionActionButtonTypeExchanged:
        if (props.buttonIsLoading) {
          return $translate('WEB2_BONUS_EXCHANGE_LOADING').value.toUpperCase();
        }
        return `${title.toUpperCase()} ${formatMoney(value)}`;
      default:
        return title.toUpperCase();
    }
  });

  function scrollHandler(): void {
    if (promotionButton.value) {
      isStickied.value = promotionButton.value.getBoundingClientRect().bottom === window.innerHeight;
    }
  }
  useBusSafeSubscribe(BusEvent.LAYOUT_CONTENT_SCROLL, scrollHandler);
  function onComponentMounted(): void {
    scrollHandler();
    isStickied.value = true;
  }

  return {
    onClick,
    showButtonStatus,
    buttonLabel,
    isStatusIcon,
    showButton,
    buttonProperties,
    isStickied,
    promotionButton,
    onComponentMounted,
  };
}
