<script lang="ts" setup>
import { HistoryFilter } from '@leon-hub/api-sdk';

import { NativeSelect } from 'web/src/components/Select';

import type { CustomerHistoryFilterSelectProps, CustomerHistoryFilterSelectEmits } from './types';
import { useCustomerHistoryFilterSelect } from './composables';

const props = withDefaults(defineProps<CustomerHistoryFilterSelectProps>(), {
  selectedHistoryFilter: HistoryFilter.ALL,
});

const emit = defineEmits<CustomerHistoryFilterSelectEmits>();

const {
  filterSelectOptions,
  selectProperties,
  onFilterChange,
} = useCustomerHistoryFilterSelect(props, emit);
</script>

<template>
  <NativeSelect v-auto-id="'CustomerHistoryFilterSelect'"
    v-if="filterSelectOptions.length"
    :class="$style['history-filter-select']"
    v-bind="selectProperties"
    theme-bg-color
    @change="onFilterChange"
  />
</template>

<style lang="scss" module>
.history-filter-select {
  position: sticky;
}
</style>
