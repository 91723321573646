import type { CashOut, BetCashOutRequest } from '@leon-hub/api-sdk';
import { doCashOut } from '@leon-hub/api-sdk';

import type ApplicationGqlClient from 'web/src/modules/core/services/api/ApplicationGqlClient';

export default function doCashOutRequest(payload: {
  graphqlClient: ApplicationGqlClient;
  options: Omit<BetCashOutRequest, 'ts'>;
}): Promise<Pick<CashOut, 'status' | 'amount' | 'msg' | 'newAmount' | 'reason' | 'remainingDelay'>> {
  const { graphqlClient, options } = payload;
  return doCashOut(graphqlClient, (node) => node.mutations.bet.doCashOut, { options });
}
