<script lang="ts" setup>
import { IconName, IconSize } from '@leon-hub/icons';

import { VIcon } from '@components/v-icon';

import DefaultTopBarRouteComponent
  from 'web/src/modules/core/views/DefaultTopBarRouteComponent/DefaultTopBarRouteComponent.vue';
import type { ReferralTopBarProps } from 'web/src/modules/referral-program/layouts/types';
import useReferralTopBar from 'web/src/modules/referral-program/layouts/composables/useReferralTopBar';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import VButton from 'web/src/components/Button/VButton/VButton.vue';

const props = defineProps<ReferralTopBarProps>();

const prefixIcon = IconName.ARROW_LEFT;

const {
  computedTitle,
  showPrefix,
  onBackClick,
  onClose,
  slotName,
  goToTerms,
} = useReferralTopBar(props);
</script>

<template>
  <DefaultTopBarRouteComponent v-auto-id="'ReferralProgramTopBarRouteComponent'"
    :title="computedTitle"
    :custom-prefix-icon-name="prefixIcon"
    :hide-prefix="!showPrefix"
    only-emit-events
    @back="onBackClick"
    @close="onClose"
  >
    <template
      v-if="slotName"
      #[slotName]
    >
      <button
        v-if="null"
        :class="$style['referral-program-top-bar__info-button']"
        type="button"
        @click="goToTerms"
      >
        <VIcon
          :name="IconName.SLOTT_INTERROGATION_1"
          :size="IconSize.SIZE_32"
        />
      </button>
      <VButton
        v-else
        :icon-name="IconName.INFO_OUTLINE"
        :icon-size="IconSize.SIZE_24"
        :kind="ButtonKind.TRANSPARENT"
        @click="goToTerms"
      />
    </template>
  </DefaultTopBarRouteComponent>
</template>

<style lang="scss" module>
.referral-program-top-bar {
  &__info-button {
    width: 56px;
    height: 56px;
    color: var(--ColorContent2);
    background: transparent;
    border: none;

    @include is-app-layout-desktop {
      @include for-hover {
        cursor: pointer;
      }
    }
  }
}
</style>
