import type { RouteLocationNamedRaw } from 'vue-router';

import { RouteName } from '@leon-hub/routing-config-names';
import { EgsGameType } from '@leon-hub/api-sdk';
import { CasinoRouteName } from '@leon-hub/routing-config-names/src/CasinoRouteName';

import type { EgsGame } from 'web/src/modules/egs/types';

// eslint-disable-next-line max-len
export default function getEgsGameRouteLocation(game: EgsGame): RouteLocationNamedRaw {
  if (game.typeId === EgsGameType.FASTGAMES) {
    return {
      name: RouteName.QUICK_BETS_DETAILS,
      params: {
        gameUrl: game.url,
        groupUrl: game.group.url,
      },
    };
  }

  if (process.env.VUE_APP_PRODUCT_LEONRU) {
    return {
      name: RouteName.HOME,
    };
  }
  const parameters: Record<string, string> = {
    gameUrl: game.url,
    groupUrl: game.group.url,
  };

  return {
    name: game.typeId === EgsGameType.LIVE
      ? CasinoRouteName.CASINO_LIVE_GAME
      : CasinoRouteName.CASINO_GAME,
    params: parameters,
  };
}
