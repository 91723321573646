import { useRouter } from 'vue-router';
import type { Ref } from 'vue';
import {
  computed,
  toRef,
} from 'vue';

import type { IconNameType } from '@leon-hub/icons';
import {
  IconName,
} from '@leon-hub/icons';

import { useRouterStore } from 'web/src/modules/core/store';

import type {
  DefaultTopBarRouteComponentEmits,
  DefaultTopBarRouteComponentProps,
} from '../types';

export interface DefaultTopBarRouteComponentComposable {
  prefixIconName: Ref<IconNameType | undefined>;
  suffixIconName: Ref<IconNameType | undefined>;
  onPrefixClick(): void;
  close(): void;
}

export default function useDefaultTopBarRouteComponent(
  props: DefaultTopBarRouteComponentProps,
  emit: DefaultTopBarRouteComponentEmits,
): DefaultTopBarRouteComponentComposable {
  const router = useRouter();
  const isBackButtonInTopBar = toRef(useRouterStore(), 'isBackButtonInTopBar');

  const prefixIconName = computed<IconNameType | undefined>(() => {
    if (props.hidePrefix) {
      return undefined;
    }

    if (props.customPrefixIconName) {
      return props.customPrefixIconName;
    }

    if (process.env.VUE_APP_LAYOUT_PHONE) {
      return isBackButtonInTopBar.value ? IconName.ARROW_LEFT : IconName.CROSS;
    }

    if (process.env.VUE_APP_LAYOUT_DESKTOP && isBackButtonInTopBar.value) {
      return IconName.ARROW_LEFT;
    }

    return undefined;
  });

  const suffixIconName = computed<IconNameType | undefined>(() => (
    (process.env.VUE_APP_LAYOUT_DESKTOP
      && !process.env.VUE_APP_FEATURE_DESKTOP_PROFILE_OUTSIDE_CLOSE_BUTTON_ENABLED
      && !props.hideSuffix)
      ? IconName.CROSS
      : undefined
  ));

  function onPrefixClick(): void {
    if (props.onlyEmitEvents) {
      emit('back');
    } else {
      router.back();
    }
  }

  function close(): void {
    emit('close');

    if (!props.onlyEmitEvents) {
      router.closeModal();
    }
  }

  return {
    prefixIconName,
    suffixIconName,
    onPrefixClick,
    close,
  };
}
