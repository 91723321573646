import { logger } from '@leon-hub/logging';
import { isObject } from '@leon-hub/guards';

import type {
  CoreSportEventResponse,
  GetSportEventsResponse,
  UnknownGetEventsResponse,
  UnknownGetEventsChangesResponse,
  GetSportEventsChangesResponse,
} from 'web/src/modules/sportline/types/rest';
import {
  isCoreSportEventResponse,
} from 'web/src/modules/sportline/guards/rest';

export function logCorruptedEventsIds(
  events: unknown[],
  message = 'Event response error occurred and filtered',
): void {
  const ids = events.map((event) => (isObject(event) && 'id' in event ? event.id : null));
  logger.error(message, { ids });
}

export function filterCorruptedEvents(
  events: unknown[],
  corruptedEventsHandler?: (corruptedList: typeof events) => void,
): CoreSportEventResponse[] {
  const corruptedList: typeof events = [];
  const result = events.filter<CoreSportEventResponse>(
    (event): event is CoreSportEventResponse => {
      if (isCoreSportEventResponse(event, true)) { return true; }
      corruptedList.push(event);
      return false;
    },
  );
  if (corruptedList.length > 0) { corruptedEventsHandler?.(corruptedList); }
  return result;
}

export function filterCorruptedEventsInResponse(
  response: UnknownGetEventsResponse,
  corruptedEventsHandler?: (corruptedList: typeof response['events']) => void,
): GetSportEventsResponse {
  const events = filterCorruptedEvents(response.events, corruptedEventsHandler);
  return {
    ...response,
    totalCount: events.length,
    events,
  };
}

export function filterCorruptedEventsInChangesResponse(
  response: UnknownGetEventsChangesResponse,
  corruptedEventsHandler?: (corruptedList: typeof response['data']) => void,
): GetSportEventsChangesResponse {
  const data = filterCorruptedEvents(response.data, corruptedEventsHandler);
  return {
    ...response,
    totalCount: data.length,
    data,
  };
}
