export enum TargetParamWithdrawal {
  USER_PROFILE_HISTORY = 'userProfileHistoryWithdrawals',
  USER_PROFILE = 'userProfileWithdrawal',
  DEPOSIT_LINK = 'depositListWithdrawalLink',
}

export enum TargetParamHistory {
  BET_SLIP = 'betSlipHistory',
  REQUEST_WITHDRAWAL = 'requestWithdrawalHistory',
  USER_PROFILE = 'userProfileHistory',
}

export enum TargetParamEnter {
  HEADER = 'headerEnter',
  SIDE_MENU = 'sideMenuEnter',
  BOTTOM_LOW_PROFILE = 'bottomLowProfileEnter',
  BOTTOM_LOW_BALANCE = 'bottomLowBalanceEnter',
  BET_SLIP = 'betSlipLogIn',
  REGISTRATION = 'registrationEnter',
  SESSION_EXPIRED = 'sessionExpiredEnter',
  SESSION_EXPIRED_NOTIFICATION = 'sessionExpiredNotificationEnter',
  EMAIL_LOGIN_PAGE = 'emailLoginPage',
  FREE_BET_NEW_USER = 'freeBetNewUsersEnter',
  PROMO_PAGE = 'promoPageEnter',
}

export enum TargetParamNavigation {
  BUTTON_LOW_SLOTS = 'buttonLowSlots',
  SIDE_MENU_SLOTS = 'sideMenuSlots',
  SIDE_MENU_LIVE = 'sideMenuLive',
  SIDE_MENU_VIRTUAL_SPORTS = 'sideMenuVirtualSports',
  HEADER_VIRTUAL_SPORTS = 'headerVirtualSports',
  HEADER_LIVE = 'headerLive',
  HEADER_SLOTS = 'headerSlots',
  LINK_IN_PROFILE = 'linkInProfile',
}
