<script lang="ts" setup>
import { useGuestAppController } from './composables';

interface GuestAppControllerProps {
  isAppInit?: boolean;
}

interface GuestAppControllerEmits {
  (e: 'load-error', error: unknown): void;
  (e: 'loaded'): void;
}

const props = withDefaults(defineProps<GuestAppControllerProps>(), {});
const emit = defineEmits<GuestAppControllerEmits>();

useGuestAppController(props, emit);
</script>

<template>
  <div v-auto-id="'GuestAppController'" />
</template>
