import type {
  CoreLeagueResponse,
  CoreLeagueReferenceResponse,
} from 'web/src/modules/sportline/types/rest';

import { isFullResponse } from './isResponseReference';

export function isFullCoreLeagueResponse(
  response: CoreLeagueResponse | CoreLeagueReferenceResponse,
): response is CoreLeagueResponse {
  return isFullResponse<CoreLeagueResponse, CoreLeagueReferenceResponse>(response);
}
