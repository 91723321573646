import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import type { BetSlipTraceProps, FormattedTraceLine } from '../types';
import { getFormattedTime } from '../utils';

interface UseBetSlipTrace {
  formattedLines: ComputedRef<FormattedTraceLine[]>;
}

export default function useBetSlipTrace(props: BetSlipTraceProps): UseBetSlipTrace {
  const formattedLines = computed<FormattedTraceLine[]>(() => props.lines
    .map(({ timestamp, error, contents }) => ({
      formattedTime: getFormattedTime(timestamp),
      error,
      contents,
    })));

  return {
    formattedLines,
  };
}
