import type { Ref } from 'vue';
import { ref } from 'vue';

import type { DetailsLayoutType } from 'web/src/modules/sportline/views/markets-list/enums';

interface UseMarketsLayoutTypeStoreComposable {
  defaultLayoutType: Ref<Maybe<DetailsLayoutType>>;
  setDefaultLayoutType(layoutType: Maybe<DetailsLayoutType>): void;
}

export function useMarketsLayoutTypeStoreComposable(): UseMarketsLayoutTypeStoreComposable {
  const defaultLayoutType = ref<Maybe<DetailsLayoutType>>(null);

  function setDefaultLayoutType(layoutType: Maybe<DetailsLayoutType>): void {
    defaultLayoutType.value = layoutType ?? null;
  }

  return {
    defaultLayoutType,
    setDefaultLayoutType,
  };
}
