import type { Component } from 'vue';

import { ApiConnectionError, ApiIpBlockedError } from '@leon-hub/api';

import ConnectionError from '../components/ConnectionError/ConnectionError.vue';
import ServiceUnavailableError from '../components/ServiceUnavailableError/ServiceUnavailableError.vue';
import IpBlockedError from '../components/IpBlockedError/IpBlockedError.vue';

export default function getErrorAppComponentByError(error: unknown): Component {
  if (error instanceof ApiConnectionError) {
    return ConnectionError;
  }

  if (process.env.VUE_APP_PLATFORM_CORDOVA
    && process.env.VUE_APP_FEATURE_IP_BLOCK_ENABLED
    && error instanceof ApiIpBlockedError) {
    return IpBlockedError;
  }

  return ServiceUnavailableError;
}
