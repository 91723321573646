import type {
  ComputedRef,
  Ref,
} from 'vue';
import {
  computed,
  onActivated,
  onMounted,
  ref,
  watch,
} from 'vue';

import { LobbyItemType } from '@leon-hub/api-sdk';
import { useWindowResize } from '@leon-hub/browser-composables';

import type { LobbySwiperProps } from 'web/src/modules/lobby/components/LobbySwiper/types';
import type {
  VSwiperProps,
  VSwiperRef,
  VSwiperScrollEvent,
} from 'web/src/components/Swiper/VSwiper/types';
import type { LobbyItemClickData } from 'web/src/modules/lobby/types';
import { LobbyGridLayoutType } from 'web/src/modules/lobby/enums';
import type { LobbyGridEmits } from 'web/src/modules/lobby/components/LobbyGrid/types';
import useLobbyGrid from 'web/src/modules/lobby/components/composables/useLobbyGrid';
import type { LobbyItemProps } from 'web/src/modules/lobby/components/LobbyItem/types';
import type LobbyGridItem from 'web/src/modules/lobby/types/LobbyGridItem';

enum SlideGridType {
  COLUMN_SQUARE = 'column-square',
  COLUMN_1 = 'column-1',
  COLUMN_2 = 'column-2',
  COLUMN_3 = 'column-3',
  COLUMN_4 = 'column-4',
  COLUMN_5 = 'column-5',
  COLUMN_6 = 'column-6',
  COLUMN_8 = 'column-8',
}

function getColumnCount(gridType: SlideGridType): number {
  switch (gridType) {
    case SlideGridType.COLUMN_1:
      return 1;
    case SlideGridType.COLUMN_3:
      return 3;
    case SlideGridType.COLUMN_4:
      return 4;
    case SlideGridType.COLUMN_5:
      return 5;
    case SlideGridType.COLUMN_6:
      return 6;
    case SlideGridType.COLUMN_8:
      return 8;
    case SlideGridType.COLUMN_2:
    case SlideGridType.COLUMN_SQUARE:
    default:
      return 2;
  }
}

export interface LobbySwiperComposable {
  swiper: Ref<VSwiperRef | undefined>;
  gridType: ComputedRef<LobbyGridLayoutType>;
  slideGridType: Ref<SlideGridType>;
  isOverflowHidden: Ref<boolean>;
  swiperProps: ComputedRef<VSwiperProps>;
  emitSwiperScroll: (event: VSwiperScrollEvent) => void;
  swiperItems: ComputedRef<LobbyGridItem[][]>;
  getItemKey: (item: LobbyGridItem, index: number) => string;
  getLobbyItemProps: (item: LobbyGridItem, index: number) => LobbyItemProps;
  emitClickLobbyItem: (data: LobbyItemClickData) => void;
  gridSize: Ref<number>;
  onVisibilityChanged: (value: boolean) => void;
  resetScrollPosition(): void;
}

const swiperSlidesInitialLimit = process.env.VUE_APP_PRERENDER ? 10000 : 10;
const maxSlidesLimit = process.env.VUE_APP_PRERENDER ? 10000 : 1000;

export default function useLobbySwiper(
  props: LobbySwiperProps,
  emits: LobbyGridEmits,
): LobbySwiperComposable {
  const swiper = ref<VSwiperRef>();
  const gridSize = ref<number>(0);
  const slideGridType = ref<SlideGridType>(SlideGridType.COLUMN_1);
  const swiperSlidesLimit = ref<number>(swiperSlidesInitialLimit);

  function onVisibilityChanged(value: boolean) {
    if (value) {
      swiperSlidesLimit.value = maxSlidesLimit;
    }
  }

  const {
    gridType,
    getItemKey,
    getLobbyItemProps,
    emitClickLobbyItem,
  } = useLobbyGrid(props, emits);

  watch(() => props.rowsNumber, calculateGridSize);
  watch(() => props.isSwiperBlock, calculateGridSize);
  watch(isSquare, calculateGridSize);

  const isOverflowHidden = computed(() => (
    ![LobbyItemType.BANNERS, LobbyItemType.SPORTLINE_TOP_MATCHES].includes(props.lobbyItemType)
  ));

  const swiperProps = computed<VSwiperProps>(() => ({
    isAutoScroll: props.isSwiperAuto,
    isInfiniteLoop: props.isSwiperAuto,
    isBlock: props.isSwiperBlock,
    isScrollSnapEnabled: true,
    isOverflowVisible: !isOverflowHidden.value,
  }));

  const swiperItems = computed(() => {
    const result: LobbyGridItem[][] = [];

    let newArray: LobbyGridItem[] = [];

    for (const item of props.items) {
      newArray.push(item);

      if (newArray.length >= gridSize.value) {
        result.push(newArray);
        newArray = [];
      }
    }

    if (newArray.length > 0) {
      result.push(newArray);
    }

    return result.slice(0, swiperSlidesLimit.value);
  });

  function onResize({ deltaX }: { deltaX: number }): void {
    if (deltaX !== 0) {
      const currentBlockSize = gridSize.value;
      const currentIndex = swiper.value?.getActiveSlideIndex() || 0;

      calculateGridSize();

      if (currentBlockSize !== gridSize.value) {
        const oldItems = currentBlockSize * (currentIndex + 1);
        const newIndex = Math.floor(oldItems / gridSize.value) - 1;

        if (newIndex !== currentIndex) {
          swiper.value?.slideToSlide(newIndex);
        }
      }
    }
  }

  function calculateGridSize(): void {
    calculateGridType();

    const columnCount = props.isSwiperBlock ? getColumnCount(slideGridType.value) : 1;
    gridSize.value = props.rowsNumber ? props.rowsNumber * columnCount : 0;
  }

  function calculateGridType(): void {
    if (!props.isSwiperBlock) {
      slideGridType.value = SlideGridType.COLUMN_1;
      return;
    }

    const width = window.innerWidth;

    if (isSquare()) {
      slideGridType.value = SlideGridType.COLUMN_SQUARE;
      return;
    }

    if (props.isModal) {
      slideGridType.value = SlideGridType.COLUMN_3;
      return;
    }

    if (width >= 1200) {
      slideGridType.value = SlideGridType.COLUMN_8;
      return;
    }

    if (process.env.VUE_APP_LAYOUT_DESKTOP) {
      slideGridType.value = SlideGridType.COLUMN_6;
      return;
    }

    if (width > 800) {
      slideGridType.value = SlideGridType.COLUMN_6;
      return;
    }

    if (width > 600) {
      slideGridType.value = SlideGridType.COLUMN_5;
      return;
    }

    if (width > 440) {
      slideGridType.value = SlideGridType.COLUMN_4;
      return;
    }

    if (width > 330) {
      slideGridType.value = SlideGridType.COLUMN_3;
      return;
    }

    slideGridType.value = SlideGridType.COLUMN_2;
  }

  function isSquare(): boolean {
    return gridType.value === LobbyGridLayoutType.COLUMN_SQUARE_GAME_LARGE
      || gridType.value === LobbyGridLayoutType.COLUMN_SQUARE_GAME_SMALL
      || gridType.value === LobbyGridLayoutType.COLUMN_SQUARE_GAME_DEFAULT;
  }

  function emitSwiperScroll(event: VSwiperScrollEvent): void {
    emits('swiper-scroll', event);
  }

  function resetScrollPosition(): void {
    swiper.value?.resetScrollPosition();
  }

  useWindowResize(onResize);
  onMounted(calculateGridSize);
  onActivated(calculateGridSize);

  return {
    swiper,
    gridType,
    slideGridType,
    swiperProps,
    isOverflowHidden,
    onVisibilityChanged,
    emitSwiperScroll,
    swiperItems,
    getItemKey,
    getLobbyItemProps,
    emitClickLobbyItem,
    gridSize,
    resetScrollPosition,
  };
}
