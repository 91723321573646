import type { Ref } from 'vue';
import { watch } from 'vue';

import {
  onComponentActivated,
  onComponentDeactivated,
} from '@leon-hub/vue-utils';

import type { ModalHeaderType } from 'web/src/components/Modal/ModalWindowHeader/types';
import { useThemeColorStore } from 'web/src/modules/theme/store';

export function useCustomThemeColor(
  type: Ref<ModalHeaderType | undefined>,
  condition?: Ref<boolean>,
): void {
  const { startThemeColorWatcher, stopThemeColorWatcher } = useThemeColorStore();

  if (!condition) {
    onComponentActivated(() => { startThemeColorWatcher(type); });
  } else {
    watch(condition, (newValue) => {
      if (newValue) {
        startThemeColorWatcher(type);
      } else {
        stopThemeColorWatcher();
      }
    }, { immediate: true });
  }

  onComponentDeactivated(() => { stopThemeColorWatcher(); });
}
