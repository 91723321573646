import type { Ref } from 'vue';
import { toRef, computed } from 'vue';

import { RouteName } from '@leon-hub/routing-config-names';

import { useI18n } from 'web/src/modules/i18n/composables';
import { useSportlineRedirectInfoStore } from 'web/src/modules/sportline/submodules/redirects/store';

interface UseSportlineRedirectFromEmptyPageInfoComposable {
  isShown: Ref<boolean>;
  title: Ref<string>;
  description: Ref<string>;
  clearInfo(): void;
}

// could be passed through props
const allowedRedirectedRoutesList: string[] = [RouteName.SPORT_EVENT_DETAILS];

export function useSportlineRedirectFromEmptyPageInfo(): UseSportlineRedirectFromEmptyPageInfoComposable {
  const { $translate } = useI18n();

  const sportlineRedirectInfoStore = useSportlineRedirectInfoStore();
  const redirectedFromRoute = toRef(sportlineRedirectInfoStore, 'redirectedFromRoute');

  const isShown = computed<boolean>(() => !!redirectedFromRoute.value
    && allowedRedirectedRoutesList.includes(redirectedFromRoute.value));

  const title = computed<string>(() => {
    if (redirectedFromRoute.value === RouteName.SPORT_EVENT_DETAILS) {
      return $translate('WEB2_REDIRECT_TO_LEAGUE_PAGE_INFO_EVENT_NOT_FOUND_TITLE').value;
    }

    return '';
  });
  const description = computed<string>(() => {
    if (redirectedFromRoute.value === RouteName.SPORT_EVENT_DETAILS) {
      // here could be different texts for different pages
      return $translate('WEB2_REDIRECT_TO_LEAGUE_PAGE_INFO_EVENT_NOT_FOUND').value;
    }

    return '';
  });

  function clearInfo(): void {
    sportlineRedirectInfoStore.clearRedirectWasHandledInfo();
  }

  return {
    title,
    description,
    isShown,
    clearInfo,
  };
}
