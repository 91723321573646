enum SwitcherKind {
  DEFAULT = 'default',
  TRANSPARENT = 'transparent',
  TRANSPARENT_WHITE = 'transparent-white',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
}

export default SwitcherKind;
