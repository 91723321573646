import type { AbstractErrorOptions } from '@leon-hub/errors';
import { ApiErrorCode } from '@leon-hub/api';
import { AppError } from '@leon-hub/app-errors';

import type {
  BuildSportOptions,
  CoreSportEventResponse,
} from 'web/src/modules/sportline/types/rest';

interface ParseSportEventErrorOptions extends Without<AbstractErrorOptions, 'code'> {
  response?: CoreSportEventResponse;
  buildOptions?: BuildSportOptions;
}

export class ParseSportEventError extends AppError {
  response: Optional<CoreSportEventResponse>;

  buildOptions: Optional<BuildSportOptions>;

  constructor(options: ParseSportEventErrorOptions) {
    super({
      ...options,
      code: ApiErrorCode.API_UNEXPECTED_ERROR,
      message: `Sportline: ${options?.message || 'Unexpected parse sport event response error'}`,
    });
    this.response = options.response;
    this.buildOptions = options.buildOptions;
  }
}
