import type { ZetaDcpConfigType } from '@leon-hub/api-sdk';

import type {
  ZetaDspConfigParams,
  ZetaDspConfig,
} from '../types';

export default function findZetaDspConfigParamsByType(
  type: ZetaDcpConfigType,
  config: ReadonlyArray<ZetaDspConfig>,
): ZetaDspConfigParams {
  return config.find((item) => (
    item.type === type
  ))?.params || [];
}
