import type { Ref } from 'vue';
import { toRef } from 'vue';
import { useRouter } from 'vue-router';

import { logger } from '@leon-hub/logging';
import { IconName, IconSize, SlottIconName } from '@leon-hub/icons';

import type { VIconProps } from '@components/v-icon';

import type { Language } from 'web/src/modules/core/store/types/rootStore';
import { useRootStore } from 'web/src/modules/core/store';
import { useI18n } from 'web/src/modules/i18n/composables';

interface LanguageSelectorComposable {
  availableLanguages: Ref<Language[]>;
  iconGlobeProps: VIconProps;
  iconCheckProps: VIconProps;
  onChange: (value: Language) => void;
}

export default function useLanguageSelector(): LanguageSelectorComposable {
  const availableLanguages = toRef(useRootStore(), 'availableLanguages');
  const router = useRouter();

  const iconGlobeProps: VIconProps = {
    size: IconSize.SIZE_20,
    name: IconName.GLOBE,
  };

  const iconCheckProps: VIconProps = {
    size: IconSize.SIZE_16,
    name: process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED ? SlottIconName.SLOTT_CHECK : IconName.CHECK_FILLED,
  };

  function onLanguageItemClick(code: string, text: string): void {
    useI18n().setLanguageTag({
      languageTag: code,
      textMetrics: text,
      reload: true,
    }).catch((err) => logger.error(err));
  }

  function onChange(language: Language): void {
    const { code, text } = language;
    router.back();
    onLanguageItemClick(code, text);
  }

  return {
    availableLanguages,
    iconGlobeProps,
    iconCheckProps,
    onChange,
  };
}
