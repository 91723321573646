import { assert, isNumber } from '@leon-hub/guards';

interface Payload {
  event: ClipboardEvent;
  currentValue: string;
  regexp: RegExp;
}

export default function handleRegexpLimitedPaste({ event, currentValue, regexp }: Payload): string {
  event.preventDefault();
  const { target, clipboardData } = event;
  assert(target instanceof HTMLInputElement);
  const { selectionStart, selectionEnd } = target;
  assert(isNumber(selectionStart) && isNumber(selectionEnd));
  const data = clipboardData?.getData('text') ?? '';
  if (regexp.test(data)) {
    const valueBefore = currentValue.slice(0, selectionStart);
    const valueAfter = currentValue.slice(selectionEnd);
    const head = `${valueBefore}${data}`;
    const nextValue = `${head}${valueAfter}`;
    const newPosition = head.length;
    target.value = nextValue;
    target.selectionStart = newPosition;
    target.selectionEnd = newPosition;
    return nextValue;
  }
  target.value = currentValue;
  target.selectionStart = selectionStart;
  target.selectionEnd = selectionEnd;
  return currentValue;
}
