<script lang="ts" setup>
import { IconName, IconSize } from '@leon-hub/icons';

import { VIcon } from '@components/v-icon';

import VButton from 'web/src/components/Button/VButton/VButton.vue';
import type {
  BonusCodesOverviewEmits,
  BonusCodesOverviewProps,
} from 'web/src/modules/affiliates/components/BonusCodesOverview/types';
import { useBonusCodesOverview } from 'web/src/modules/affiliates/components/BonusCodesOverview/composables';

const props = withDefaults(defineProps<BonusCodesOverviewProps>(), {
  showHelp: true,
  addButtonText: '',
  currentCount: 0,
  maxCount: 100,
});
const emits = defineEmits<BonusCodesOverviewEmits>();

const {
  counterText,
  buttonDisabled,
  buttonKind,
  buttonLabel,
  emitButtonClick,
  emitHelp,
} = useBonusCodesOverview(props, emits);
</script>

<template>
  <div v-auto-id="'BonusCodesOverview'" :class="$style['bonus-codes-overview']">
    <div :class="$style['bonus-codes-overview__row']">
      <div :class="$style['bonus-codes-overview__counter']">
        <span :class="$style['bonus-codes-overview__counter-label']">
          {{ $t('WEB2_BONUS_CODES') }}
        </span>
        <strong :class="$style['bonus-codes-overview__counter-value']">
          {{ counterText }}
        </strong>
      </div>
      <div :class="$style['bonus-codes-overview__add-button']">
        <VButton
          :label="buttonLabel"
          :kind="buttonKind"
          :disabled="buttonDisabled"
          @click="emitButtonClick"
        />
      </div>
    </div>
    <div
      v-if="showHelp"
      :class="$style['bonus-codes-overview__footer']"
    >
      <button
        type="button"
        :class="$style['bonus-codes-overview__help']"
        @click="emitHelp"
      >
        <VIcon
          :name="IconName.QUESTION_OUTLINE"
          :size="IconSize.SIZE_16"
          :class="$style['bonus-codes-overview__help-icon']"
        />
        <span :class="$style['bonus-codes-overview__help-text']">
          {{ $t('WEB2_BONUS_CODE_USAGE') }}
        </span>
      </button>
    </div>
  </div>
</template>

<style lang="scss" module>
@include for-layout using($isDesktop) {
  .bonus-codes-overview {
    padding: if($isDesktop, 16px, 16px 12px 16px 16px);
    background: var(--Layer1);
    border-radius: 8px;

    &__row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      overflow: hidden;
    }

    &__counter-label {
      @include regular\14\20\025;

      display: block;
      margin-bottom: 4px;
      color: var(--TextSecondary);
    }

    &__counter-value {
      @include medium\18\24;

      display: block;
      color: var(--TextDefault);
    }

    &__footer {
      margin-top: 16px;
    }

    &__help {
      display: inline-flex;
      align-items: center;
      margin: -10px;
      color: var(--TextSecondary);
      cursor: pointer;
      background: none;
      border: none;
    }

    &__help-icon {
      margin-right: 5px;

      svg {
        fill: var(--TextSecondary);
      }
    }

    &__help-text {
      @include regular\14\20\025;

      text-decoration: underline;
    }
  }
}
</style>
