import type { Ref } from 'vue';
import { watch } from 'vue';

import type {
  RecycleScrollerItemByDirection,
  RecycleScrollerResourcesListState,
} from '../types';
import type { RecycleScrollerScrollBy } from '../utils';
import { findListByDimensionDiff } from '../utils/position-correction';

export function useWatchForFixStartPosition(
  itemsByDirection: Ref<RecycleScrollerItemByDirection[]>,
  visibleItemsState: RecycleScrollerResourcesListState,
  scrollBy: RecycleScrollerScrollBy,
): void {
  let last: Optional<number>;
  watch(itemsByDirection, (value, oldValue) => {
    const diff = findListByDimensionDiff(value, oldValue, visibleItemsState.value.end, last);
    last = visibleItemsState.value.end;
    if (!diff) { return; }
    scrollBy(diff);
  }, { immediate: true });
}
