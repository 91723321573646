import {
  onActivated,
  onBeforeMount,
  onBeforeUnmount,
  onDeactivated,
} from 'vue';

import type { TimeBusComposable } from './types';
import useTimeBus from './useTimeBus';

export default function useSafeTimeBus(updateEvery = 1000): TimeBusComposable {
  const {
    now,
    start,
    stop,
    subscribe,
    unsubscribe,
  } = useTimeBus(updateEvery);

  let isActive = false;

  const activated = () => {
    if (isActive) { return; }
    start();
    isActive = true;
  };
  const deactivated = () => {
    if (!isActive) { return; }
    stop();
    isActive = false;
  };

  onBeforeMount(activated);
  onActivated(activated);

  onBeforeUnmount(deactivated);
  onDeactivated(deactivated);

  return {
    now,
    subscribe,
    unsubscribe,
  };
}
