import template from 'lodash/template';

import { logger } from '@leon-hub/logging';
import { normalizeError } from '@leon-hub/errors';

import type { SeoMetaParameters } from 'web/src/modules/seo/types';

import hasMetaParametersPlaceholders from './hasMetaParametersPlaceholders';

/**
 * Replace parameters placeholders in meta content
 * Returns unchanged content if no one placeholder found
 * Returns undefined if has no one parameter provided
 *
 * @param {string} content
 * @param {SeoMetaParameters} parameters
 *
 * @return {string | undefined}
 */
export default function replaceMetaParametersPlaceholders(content?: string, parameters?: SeoMetaParameters): string {
  if (!hasMetaParametersPlaceholders(content)) {
    return content ?? '';
  }

  if (!parameters || Object.keys(parameters).length === 0) {
    return ''; // Loading parameters state | '\u200E' - for empty url
  }

  try {
    return template(content, {
      variable: 'data',
    })(parameters);
  } catch (error) {
    try {
      logger.error(
        'Meta template compilation failed',
        { content, parameters },
        normalizeError(error),
      );
    } catch (e) {
      logger.error('Meta template compilation log error', e);
    }
  }

  return '';
}
