import { windowEndpoint, wrap } from 'comlink';

import { logger } from '@leon-hub/logging';
import { normalizeError } from '@leon-hub/errors';
import { promiseTimeout } from '@leon-hub/utils';

import { DEFAULT_TIMEOUT, sandboxScriptErrorMessage } from '../constants';
import type {
  SandboxContext,
  SandboxApiOptions,
  UnresolvedSandboxContext,
} from '../types';
import { configureFrame } from './configureFrame';
import { getFrameWindow } from './getFrameWindow';
import { connectWindow } from './connectWindow';
import { debug } from './logger';
import { getFrameId } from './getFrameId';

export const getSandboxContext = <Api extends object>(
  options: SandboxApiOptions,
): UnresolvedSandboxContext<Api> => {
  let isStopped = false;
  if (!options.scripts.length) throw new Error('No scripts to sandbox');
  const log = debug.extend(options.id);
  const frameID = getFrameId(options.id);
  if (document.getElementById(frameID)) {
    throw new Error(`Unexpected sandbox frame with id=${frameID}`);
  }
  const iframe = document.createElement('iframe');

  function stop(): void {
    if (!isStopped) {
      isStopped = true;
      log('stopping');
      iframe.parentNode?.removeChild(iframe);
    }
  }

  try {
    iframe.addEventListener('beforeunload', () => {
      log('unloading');
      stop();
    });

    return {
      iframe,
      api: configureFrame(iframe, options)
        .then(() => {
          log('installing dom..');
          iframe.addEventListener('message', ({ data }: MessageEvent) => {
            if (data === sandboxScriptErrorMessage) {
              logger.error(`Sandbox "${options.id}" script error`);
              stop();
            }
          });
          document.body.appendChild(iframe);
        })
        .then(() => connectWindow(iframe, { id: options.id }))
        .then(() => wrap<Api>(windowEndpoint(getFrameWindow(iframe))))
        .catch((err) => {
          stop();
          const normalizedErr = normalizeError(err);
          logger.error('Unable to finish sandbox initialization', normalizedErr);
          return Promise.reject(normalizedErr);
        }),
      stop,
    };
  } catch (err) {
    stop();
    throw err;
  }
};

export const sandboxApi = async <Api extends object>(
  options: SandboxApiOptions,
): Promise<SandboxContext<Api>> => {
  const isResolved = false;
  const context = getSandboxContext<Api>(options);
  return promiseTimeout({
    promise: context.api,
    timeout: options.timeout ?? DEFAULT_TIMEOUT,
    onTimeout: () => {
      // Auto tear down resolved sandbox.
      if (!isResolved) {
        try {
          context.stop();
        } catch (err) {
          logger.warn(`Unable to tear down sandbox ${options.id} during timeout error`, normalizeError(err));
        }
      }
    },
  }).then((api) => ({ ...context, api } as SandboxContext<Api>));
};
