import {
  assert,
  isOptionalString,
} from '@leon-hub/guards';

import type { InputHintProps } from 'web/src/components/Input/types';

import type { FormValidatorErrorValue } from '../types';

type ErrorFields = Pick<InputHintProps, 'error' | 'errorRight'>;

function getErrorFields(
  options?: ErrorFields & { isHintRight?: boolean },
  errorText?: FormValidatorErrorValue,
): ErrorFields {
  const error = options?.isHintRight ? undefined : (options?.error || errorText);

  assert(isOptionalString(error), `Unexpected error type: ${typeof error}`);

  const errorRight = (options?.isHintRight && errorText) || options?.errorRight;

  assert(isOptionalString(errorRight), `Unexpected errorRight type: ${typeof errorRight}`);

  return {
    error,
    errorRight,
  };
}

export default getErrorFields;
