import { isLocalStorageAvailable } from '@leon-hub/local-storage';

import { getValidationControlKey } from './getValidationControlKey';

const key = getValidationControlKey();

export function enableFormValidation(): void {
  if (isLocalStorageAvailable()) {
    localStorage.removeItem(key);
  }
}
