import type { Without } from '@leon-hub/types';

import GqlApiResponseErrorCode from './GqlApiResponseErrorCode';
import type { GqlApiErrorOptions } from './GqlApiError';
import GqlApiError from './GqlApiError';

type GqlApiErrorOptionsExtensions = Without<Record<string, unknown>, 'errorCode'>
& Without<Record<string, unknown>, 'message'>
& {
  errorCode: string;
  message?: string | null;
};

export interface GqlApiBatchSubRequestErrorOptions extends GqlApiErrorOptions {
  extensions: GqlApiErrorOptionsExtensions;
}

export default class GqlApiBatchedSubRequestError extends GqlApiError {
  extensions: GqlApiErrorOptionsExtensions;

  constructor(options: GqlApiBatchSubRequestErrorOptions) {
    const { extensions } = options;
    const errorCode = options.code ?? new GqlApiResponseErrorCode(extensions.errorCode);

    super({
      ...options,
      code: errorCode,
      message: options.message || extensions.message || undefined,
    });

    this.extensions = extensions;
  }

  public isMutationRequest(): boolean {
    return !!this.subRequest?.mutation;
  }
}
