import type { FieldGuards } from '@leon-hub/types';
import {
  isValidInterface,
} from '@leon-hub/guards';

import type { GetSportsLeagueResponse } from 'web/src/modules/sportline/types/rest';

import { isCoreLeagueResponseFieldGuards } from './isCoreLeagueResponse';

export const isGetSportsLeagueResponseFieldGuards: FieldGuards<GetSportsLeagueResponse> = {
  ...isCoreLeagueResponseFieldGuards,
  sport: (value: unknown): value is never => !value,
  region: (value: unknown): value is never => !value,
};

export function isGetSportsLeagueResponse(value: unknown): value is GetSportsLeagueResponse {
  return isValidInterface<GetSportsLeagueResponse>(value, isGetSportsLeagueResponseFieldGuards);
}
