export enum IconSize {
  /** @deprecated */
  MINI = 'mini', // 8x8
  /** @deprecated */
  TINY = 'tiny', // 12x12
  /** @deprecated */
  SMALL = 'small', // 16x16
  /** @deprecated */
  DEFAULT = 'default', // 20x20
  /** @deprecated */
  MEDIUM = 'medium', // 24x24
  /** @deprecated */
  BIG = 'big', // 32x32
  /** @deprecated */
  LARGE = 'large', // 48x48
  /** @deprecated */
  HUGE = 'huge', // 60x60
  /** @deprecated */
  GIANT = 'giant', // 80x80
  /** @deprecated */
  ICON_130 = 'icon_130', // 130x130
  /** @deprecated */
  ICON_160 = 'icon_160', // 160x160
  SIZE_8 = 'size-8', // 8x8
  SIZE_10 = 'size-10', // 10x10
  SIZE_12 = 'size-12', // 12x12
  SIZE_14 = 'size-14', // 12x12
  SIZE_16 = 'size-16', // 16x16
  SIZE_18 = 'size-18', // 18x18
  SIZE_20 = 'size-20', // 20x20
  SIZE_24 = 'size-24', // 24x24
  SIZE_28 = 'size-28', // 24x24
  SIZE_32 = 'size-32', // 32x32
  SIZE_36 = 'size-36', // 36x36
  SIZE_40 = 'size-40', // 40x40
  SIZE_44 = 'size-44', // 44x44
  SIZE_48 = 'size-48', // 48x48
  SIZE_50 = 'size-50', // 50x50
  SIZE_52 = 'size-52', // 52x52
  SIZE_56 = 'size-56', // 56x56
  SIZE_60 = 'size-60', // 60x60
  SIZE_68 = 'size-68', // 68x68
  SIZE_80 = 'size-80', // 80x80
  SIZE_90 = 'size-90', // 90x90
  SIZE_96 = 'size-96', // 96х96
  SIZE_100 = 'size-100', // 100x100
  SIZE_130 = 'size-130', // 130x130
  SIZE_160 = 'size-160', // 160x160
  FULL_SIZE = 'full-size', // 100%x100%
}
