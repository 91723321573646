import { DeviceFingerprintType } from '../enums';

export default function isFingerprintTypeEnabled(
  isFingerprintJsEnabled: boolean,
  isBrowserFingerprintEnabled: boolean,
  fpType: DeviceFingerprintType,
): boolean {
  return (fpType === DeviceFingerprintType.DEVICE_FINGERPRINT_JS && isFingerprintJsEnabled)
    || (fpType === DeviceFingerprintType.DEVICE_FINGERPRINT_BROWSER && isBrowserFingerprintEnabled);
}
