import { getBootstrapTranslations } from '@leon-hub/bootstrap-translations';

import { ApiErrorCode } from './ApiErrorCode';
import type { ApiErrorOptions } from './ApiError';
import { ApiError } from './ApiError';

export class ApiTechnicalError extends ApiError {
  constructor(options?: ApiErrorOptions) {
    super({
      ...options,
      message: options?.message ?? getBootstrapTranslations().WEB2_TECHNICAL_ERROR,
      code: ApiErrorCode.API_TECHNICAL_ERROR,
    });
  }
}
