<script lang="ts" setup>
import VBadge from 'web/src/components/Badge/VBadge/VBadge.vue';
import vDataTestUnit from 'web/src/testing/directives/vDataTestUnit';

import { useProfileBonusesCardHeader } from './composables';

interface ProfileBonusesCardHeaderProps {
  title?: string;
  badgeText?: string;
  badgeSelect?: boolean;
  active?: boolean;
}

const props = withDefaults(defineProps<ProfileBonusesCardHeaderProps>(), {
  title: '',
  badgeText: '',
});

const {
  badgeProps,
  hasBadge,
} = useProfileBonusesCardHeader(props);
</script>

<template>
  <div v-auto-id="'ProfileBonusesCardHeader'" :class="$style['bonus-card-header']">
    <span :class="$style['bonus-card-header__title']">{{ title }}</span>
    <VBadge
      v-if="hasBadge"
      v-collect:click-counter="{ bonuses: 'cherry' }"
      v-data-test-unit="{ el: 'bonus-card-header-badge' }"
      v-bind="badgeProps"
      :class="$style['bonus-card-header__badge']"
    />
    <div
      v-else-if="badgeSelect"
      v-data-test-unit="{ el: 'bonus-card-header-badge-select' }"
      :class="{
        [$style['bonus-card-header__badge-select']]: true,
        [$style['bonus-card-header__badge-select-active']]: active
      }"
    >
      <span
        v-if="active"
        v-data-test-unit="{ el: 'bonus-card-header-badge-select-active-inner' }"
        :class="$style['bonus-card-header__badge-select-active__inner']"
      />
    </div>
  </div>
</template>

<style lang="scss" module>
.bonus-card-header {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-flow: column;
  grid-column-gap: 4px;
  justify-content: space-between;
  width: 100%;
  padding: 16px 16px 8px;
  word-break: break-word;
  background-color: var(--Layer1);
  border-radius: $bonusCardHeaderBorderRadius;

  &__title {
    @include bonusCardHeaderTitle;

    display: -webkit-box;
    overflow: hidden;
    text-align: left;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  &__badge {
    margin-top: 2px;

    &-select {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      border: solid 2px var(--TextSecondary);
      border-radius: 100%;

      &-active {
        border-color: var(--BrandDefault);

        &__inner {
          width: 10px;
          height: 10px;
          background-color: var(--BrandDefault);
          border-radius: 100%;
        }
      }
    }
  }
}
</style>
