<script lang="ts" setup>
import { InputEventType } from 'web/src/components/Input/enums';
import type { CaptchaToken } from 'web/src/modules/captcha/store/types';
import { VRecaptchaV3 } from 'web/src/modules/captcha/components/VRecaptchaV3';
import type { RecaptchaV3Props } from 'web/src/modules/captcha/composables/useRecaptchaV3Props';

import type {
  FormWrapperWidgetProperties,
  FormDataEvent,
} from '../../types';

interface Emits {
  (e: 'input', event: FormDataEvent): void;
}

const props = defineProps<FormWrapperWidgetProperties<RecaptchaV3Props>>();
const emit = defineEmits<Emits>();

const onVerify = (captchaToken: CaptchaToken): void => {
  emit(InputEventType.INPUT, {
    value: captchaToken,
    name: props.name,
  });
};
</script>

<template>
  <VRecaptchaV3 v-auto-id="'FormCaptchaV3'"
    v-if="!!'1'"
    :action="props.options?.action"
    @verify="onVerify"
  />
</template>
