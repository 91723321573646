import type { Ref, ToRefs } from 'vue';
import { computed } from 'vue';

import { useTheme } from 'web/src/modules/theme/composables';
import type { CompetitorsRolesInfo, Competitor } from 'web/src/modules/sportline/types';
import { SportFamily } from 'web/src/modules/sportline/enums';
import { BetlineStatisticScoresType } from 'web/src/modules/sportline/enums/rest';
import { SportBackgroundColorDark, SportBackgroundColorLight } from 'web/src/modules/sportline/constants';
import { statisticTeamToCompetitor } from 'web/src/modules/sportline/submodules/core-statistic/utils';
import { getBackgroundColorFromSportRepresentationBackground } from 'web/src/modules/sportline/utils/theme';

import type { PostMatchStatisticHeadlineProps } from '../types';

type UsePostMatchStatisticHeadlineProps = ToRefs<PostMatchStatisticHeadlineProps>;

interface UsePostMatchStatisticHeadlineComposable {
  kickoff: Ref<Maybe<number>>;
  competitors: Ref<Maybe<CompetitorsRolesInfo<Competitor>>>;
  finalScore: Ref<CompetitorsRolesInfo<string>>;
  penaltyScore: Ref<Maybe<CompetitorsRolesInfo<string>>>;
  backgroundColor: Ref<Optional<string>>;
  sportIcon: Ref<Maybe<string>>;
}

export function usePostMatchStatisticHeadline(
  props: UsePostMatchStatisticHeadlineProps,
): UsePostMatchStatisticHeadlineComposable {
  const { postMatchStatistic } = props;

  const { theme } = useTheme();

  const kickoff = computed<Maybe<number>>(() => postMatchStatistic.value.matchDate ?? null);

  const competitors = computed<Maybe<CompetitorsRolesInfo<Competitor>>>(() => {
    const rawTeams = postMatchStatistic.value.teams;
    const teams = rawTeams && rawTeams.length > 1
      ? [rawTeams[0], rawTeams[1]]
      : null;

    const host = statisticTeamToCompetitor(teams?.[0]);
    const guest = statisticTeamToCompetitor(teams?.[1]);

    return host && guest ? { host, guest } : null;
  });

  const finalScore = computed<CompetitorsRolesInfo<string>>(() => {
    const score = (postMatchStatistic.value.resultScore || '').split(':');
    return score.length > 1
      ? { host: score[0], guest: score[1] }
      : { host: '-', guest: '-' }; // resultScore may be empty if match was canceled
  });

  const penaltyScore = computed<Maybe<CompetitorsRolesInfo<string>>>(() => {
    const scores = postMatchStatistic.value.scores
      .find((score) => score.type === BetlineStatisticScoresType.AfterPenalty);
    const values = (scores?.value || '').split(':');

    return values.length > 1
      ? { host: values[0], guest: values[1] }
      : null;
  });

  const sportIcon = computed<Maybe<string>>(() => postMatchStatistic.value.family ?? null);

  const backgroundColor = computed<Optional<string>>(
    // @TODO get data about sport from statistic or meta data
    () => getBackgroundColorFromSportRepresentationBackground(theme.value, {
      dark: SportBackgroundColorDark[postMatchStatistic.value.family ?? SportFamily.Soccer] ?? SportBackgroundColorDark[SportFamily.Soccer],
      light: SportBackgroundColorLight[postMatchStatistic.value.family ?? SportFamily.Soccer] ?? SportBackgroundColorLight[SportFamily.Soccer],
    }),
  );

  return {
    kickoff,
    competitors,
    finalScore,
    penaltyScore,
    backgroundColor,
    sportIcon,
  };
}
