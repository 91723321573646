import type { Ref } from 'vue';
import { ref } from 'vue';

import { CasinoRouteName } from '@leon-hub/routing-config-names/src/CasinoRouteName';
import type { CustomerBonus } from '@leon-hub/api-sdk';

import { getRandomGameId } from 'web/src/modules/profile/submodules/bonuses/controllers/ProfileBonusesFreespinController/composables/utils';
import useEgsNavigation from 'web/src/modules/egs/submodules/navigation/composable/useEgsNavigation';
import type { AppVueRouter } from 'web/src/modules/core/services/router/types';

interface NavigationToGameDetailsComposable {
  routeToGameDetails: (bonus: CustomerBonus) => void;
  isGameDetailsLoading: Ref<boolean>;
}

export default function useNavigationToGameDetails(router: AppVueRouter): NavigationToGameDetailsComposable {
  const { navigateToGamePageById } = useEgsNavigation(router);

  const isGameDetailsLoading = ref(false);
  const routeToGameDetails = (bonus: CustomerBonus): void => {
    if (bonus.freespin?.gameIds) {
      const { gameIds } = bonus.freespin;
      const gameId = getRandomGameId(gameIds);

      isGameDetailsLoading.value = true;
      navigateToGamePageById(gameId).catch(() => {
        void router.push({ name: CasinoRouteName.CASINO_LOBBY });
      }).finally(() => {
        isGameDetailsLoading.value = false;
      });
    }
  };

  return {
    routeToGameDetails,
    isGameDetailsLoading,
  };
}
