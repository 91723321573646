import type { Ref } from 'vue';
import {
  computed,
} from 'vue';

import type { Web2Money } from '@leon-hub/api-sdk';
import { RouteName } from '@leon-hub/routing-config-names';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';

import { useFormatMoney } from 'web/src/modules/money/composables';
import type {
  BannerContent,
} from 'web/src/modules/banners/store/types';
import { useAnalytics } from 'web/src/modules/analytics/composables';

export interface BannerTextsComposable {
  headline: Ref<string | undefined>;
  secondaryText: Ref<string | undefined>;
  buttonText: Ref<string | undefined>;
  onClick: () => void;
}

export default function useBannerTexts(banner: Ref<BannerContent | undefined>): BannerTextsComposable {
  const formatMoney = useFormatMoney();
  const analytics = useAnalytics();

  function applyMoneyPlaceholder(string: string, money?: Maybe<Web2Money>): string {
    if (!money || !string.includes('{money}')) {
      return string;
    }

    const moneyPlaceholderValue = formatMoney(money.value, {
      currency: money.currency,
    });

    return string.replace(/{money}/g, moneyPlaceholderValue);
  }

  const headline = computed<string | undefined>(() => (banner.value?.headline
    ? applyMoneyPlaceholder(banner.value.headline, banner.value.money)
    : undefined));

  const secondaryText = computed<string | undefined>(() => (banner.value?.secondaryText
    ? applyMoneyPlaceholder(banner.value.secondaryText, banner.value.money)
    : undefined));

  const buttonText = computed<string | undefined>(() => (banner.value?.buttonText
    ? applyMoneyPlaceholder(banner.value.buttonText, banner.value.money)
    : undefined));

  function onClick(): void {
    if (banner.value && banner.value.url === `/${String(RouteName.VIP)}`) {
      analytics.push(AnalyticsEvent.Z_CLICK_TO_OPEN_VIP, {
        vip_program: {
          click_to_open: 'mini_banner',
        },
      });
    }
  }

  return {
    headline,
    secondaryText,
    buttonText,
    onClick,
  };
}
