<script lang="ts" setup>
import { IconName } from '@leon-hub/icons';
import { ButtonKind } from '@leon-hub/module-buttons';

import PaymentsModalsController
  from 'web/src/modules/payments/components/PaymnetsModalsController/PaymentsModalsController.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import VHintBlock from 'web/src/components/HintBlock/VHintBlock/VHintBlock.vue';
import SHintBlock from 'web/src/components/HintBlock/SHintBlock/SHintBlock.vue';

import { usePaymentsCancelWithdrawal } from './composables';
import type { PaymentsCancelWithdrawalProps } from './types';

const props = withDefaults(defineProps<PaymentsCancelWithdrawalProps>(), {
  value: 0,
});

const {
  showModal,
  modalType,
  modalText,
  secondaryText,
  showCancelWithdrawalWindow,
  formattedValue,
  onCancelWithdrawalClick,
  onConfirmClick,
  hideModal,
} = usePaymentsCancelWithdrawal(props);
</script>

<template>
  <div v-auto-id="'PaymentsCancelWithdrawal'" :class="$style['payments-cancel-withdrawal']">
    <template v-if="showCancelWithdrawalWindow">
      <SHintBlock
        v-if="null"
        type="secondary"
        :icon-name="IconName.SLOTT_INFO"
        :primary-button="{ label: $t('WEB2_CANCEL_WITHDRAWAL_HINT_BUTTON') }"
        is-buttons-at-top
        @click-primary="onCancelWithdrawalClick"
      >
        <template #default>
          <span>{{ $t('JSPACC_ACC_REQUESTED') }}&nbsp;</span>
          <span :class="$style['payments-cancel-withdrawal__money']">{{ formattedValue }}</span>
        </template>
      </SHintBlock>

      <VHintBlock
        v-else
        :icon="IconName.ATTENTION_OUTLINE"
        is-wide-footer
      >
        <template #label>
          <span>{{ $t('JSPACC_ACC_REQUESTED') }}&nbsp;</span>
          <span :class="$style['payments-cancel-withdrawal__money']">{{ formattedValue }}</span>
        </template>
        <template #footer>
          <VButton
            :label="$t('WEB2_CANCEL_WITHDRAWAL_AND_RECIEVE_TO_ACCOUNT')"
            :kind="ButtonKind.CANCEL"
            full-width
            :class="$style['payments-cancel-withdrawal__button']"
            @click="onCancelWithdrawalClick"
          />
        </template>
      </VHintBlock>
    </template>

    <PaymentsModalsController
      v-if="showModal"
      :modal-text="modalText"
      :secondary-text="secondaryText"
      :modal-type="modalType"
      is-in-profile
      @close="hideModal"
      @confirm-click="onConfirmClick"
    />
  </div>
</template>

<style lang="scss" module>
@include for-layout using($isDesktop) {
  .payments-cancel-withdrawal {
    $self: &;

    @include paymentsCancelWithdrawal;

    z-index: 1;

    @if $isDesktop {
      @include paymentsCancelWithdrawalDesktop;
    } @else {
      @include paymentsCancelWithdrawalPhone;
    }

    &__money {
      @include medium\14\24\025;
    }

    &__button {
      color: var(--BrandDefault);
      background-color: var(--OpacityBrandDefault);
    }

    @include is-app-layout-desktop {
      &:hover {
        cursor: pointer;

        #{$self}__button {
          color: var(--BrandText);
          background-color: var(--DBrandHighlight);
        }
      }
    }
  }
}
</style>
