import {
  isError, isObject, isString, isUndefined,
} from '@leon-hub/guards';
import { CaptchaType, RecaptchaTheme as ReCaptchaThemeSiteConfig, Theme } from '@leon-hub/api-sdk';

import { ReCaptchaTheme } from 'web/src/modules/captcha/store/enums';
import type { CaptchaToken, ReCaptchaResponse } from 'web/src/modules/captcha/store/types';

export function isReCaptchaTheme(value: unknown): value is ReCaptchaTheme {
  return value === ReCaptchaTheme.DARK
    || value === ReCaptchaTheme.LIGHT;
}

export function isReCaptchaResponse(value: unknown): value is ReCaptchaResponse {
  return isUndefined(value)
    || (isObject(value) && (isCaptchaToken(value.captchaResponse) || isError(value.captchaResponse)));
}

export function isCaptchaToken(value: unknown): value is CaptchaToken {
  return isString(value) && value.length > 0;
}

export function isCaptchaType(value: unknown): value is CaptchaType {
  return value === CaptchaType.RECAPTCHA
    || value === CaptchaType.INVISIBLE_RECAPTCHA
    || value === CaptchaType.ANDROID_RECAPTCHA;
}

function mapTheme(theme: Theme): ReCaptchaTheme {
  switch (theme) {
    case Theme.DARK:
    case Theme.LIGHT:
      return ReCaptchaTheme.LIGHT;
    default:
      throw new Error(`Unknown theme ${theme}`);
  }
}

export function reCaptchaThemeConfigMapper(
  configTheme: ReCaptchaThemeSiteConfig,
  currentTheme: Theme = Theme.DARK,
): ReCaptchaTheme {
  switch (configTheme) {
    case ReCaptchaThemeSiteConfig.DARK:
      return ReCaptchaTheme.DARK;
    case ReCaptchaThemeSiteConfig.LIGHT:
      return ReCaptchaTheme.LIGHT;
    case ReCaptchaThemeSiteConfig.UI_INVERT:
      return currentTheme === Theme.DARK ? ReCaptchaTheme.LIGHT : ReCaptchaTheme.DARK;
    case ReCaptchaThemeSiteConfig.UI_MATCH:
      return mapTheme(currentTheme);
    default:
      throw new Error(`ReCaptcha theme:${configTheme} is not implemented`);
  }
}
