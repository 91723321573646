import type { Ref } from 'vue';
import { computed, onMounted, onUnmounted } from 'vue';

import type { ModalWindowProps } from 'web/src/components/Modal/ModalWindow/types';

import type { DefaultModalProps, DefaultModalEmits } from '../types';

export interface DefaultModalComposable {
  outerModalContainerId: string;
  containerId: Ref<string | undefined>;
  modalWindowProps: Ref<ModalWindowProps>;
  classNames: Ref<Dictionary<boolean>>;
  onOverlayClick: () => void;
  onClose: () => void;
}

const outerModalContainerId = 'outerModalContainer';
export default function useDefaultModal(
  props: DefaultModalProps,
  emits: DefaultModalEmits,
): DefaultModalComposable {
  const containerId = computed(
    () => (props.isInnerModal ? undefined : outerModalContainerId),
  );

  const modalWindowProps = computed<ModalWindowProps>(() => ({
    image: props.image,
    alertIconSize: props.alertIconSize,
    icon: props.icon,
    iconSize: props.iconSize,
    header: props.header,
    content: props.content,
    topBarText: props.topBarText,
    buttons: props.buttons,
    isCloseAllowed: props.isCloseAllowed,
    isAlert: props.isAlert,
    isScrollBarEnabled: props.isScrollBarEnabled,
    isFullHeight: props.isFullHeight,
    isFullHeightCentered: props.isFullHeightCentered,
    isCloseAsBack: props.isCloseAsBack,
    isTopBarHidden: props.isTopBarHidden,
    iconKind: props.iconKind,
    hasInnerModal: props.hasInnerModal,
    isInnerModal: props.isInnerModal,
    modalKey: props.modalKey,
    useScrollListener: props.useScrollListener,
    hasNoScroll: props.hasNoScroll,
    isFullScreen: props.isFullScreen,
    isProfileLayout: props.isProfileLayout,
    isSmallWidthContent: props.isSmallWidthContent,
  }));

  const classNames = computed<Dictionary<boolean>>(() => ({
    'modal': true,
    'modal--alert': !!props.isAlert,
    'modal--centered': !!props.isCentered,
    'modal--min-height': !!props.hasMinHeight,
    'modal--has-inner': !!props.hasInnerModal && !props.hasInnerModalFullHeight,
    [`modal--width-${props.width}`]: true,
    [`modal--height-${props.height}`]: true,
    'modal--full-height': !!props.isFullHeight,
    'modal--profile': !!props.isProfileLayout,
    'modal--full-screen': !!props.isFullScreen,
    'modal--has-custom-bg': !!props.customBackground,
  }));

  function onClose(): void {
    if (props.isCloseAllowed) {
      emits('close');
    }
  }

  function onOverlayClick(): void {
    if (props.isOverlayCloseAllowed) {
      onClose();
    }
  }

  function handleKeydown(event: KeyboardEvent): void {
    const { key } = event;
    if (key === 'Escape' || key === 'Esc') {
      onClose();
    }
  }

  onMounted(() => {
    if (process.env.VUE_APP_LAYOUT_DESKTOP) {
      window.addEventListener('keydown', handleKeydown);
    }
  });

  onUnmounted(() => {
    if (process.env.VUE_APP_LAYOUT_DESKTOP) {
      window.removeEventListener('keydown', handleKeydown);
    }
  });

  return {
    outerModalContainerId,
    containerId,
    modalWindowProps,
    classNames,
    onOverlayClick,
    onClose,
  };
}
