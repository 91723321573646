import { isNumber, isValidInterface, assert } from '@leon-hub/guards';
import { addLeadingZero } from '@leon-hub/utils';
import type { AffiliateStats } from '@leon-hub/api-sdk';

import type { VSelectOption } from 'web/src/components/Select';
import { useI18n } from 'web/src/modules/i18n/composables';
import { DateRangeDefaultDay } from 'web/src/modules/affiliates/store/enums';

export function getLastMonths(fromDate: Date, monthsCount = 3) : Date[] {
  assert(
    monthsCount > 1 && monthsCount <= 12,
    'monthsCount should be greater-than 1 and less than or equal to 12',
  );
  const result: Date[] = [];
  let count = monthsCount;
  let isPreviousYear = false;
  let currentMonth = fromDate.getMonth() - monthsCount;
  while (count > 0) {
    if (currentMonth < 0) {
      isPreviousYear = true;
      currentMonth += 12;
    }
    if (currentMonth >= 12) {
      isPreviousYear = false;
      currentMonth -= 12;
    }

    const date = new Date();
    date.setMonth(currentMonth);

    if (isPreviousYear) {
      date.setFullYear(fromDate.getFullYear() - 1);
    }
    result.push(date);

    currentMonth += 1;
    count -= 1;
  }

  return result;
}

export const dateRangeFilterOptionsList: () => VSelectOption[] = () => {
  const { $translate } = useI18n();

  const defaultDateRange = [
    {
      label: $translate('WEB2_DATE_RANGE_FILTER_FORTODAY').value,
      value: DateRangeDefaultDay.OneDay as string,
    },
    {
      label: $translate('WEB2_DATE_RANGE_FILTER_LAST3DAYS').value,
      value: DateRangeDefaultDay.ThreeDays as string,
    },
    {
      label: $translate('WEB2_DATE_RANGE_FILTER_LAST7DAYS').value,
      value: DateRangeDefaultDay.SevenDays as string,
    },
    {
      label: $translate('WEB2_DATE_RANGE_FILTER_LAST15DAYS').value,
      value: DateRangeDefaultDay.FifteenDays as string,
    },
    {
      label: $translate('WEB2_DATE_RANGE_FILTER_LAST30DAYS').value,
      value: DateRangeDefaultDay.ThirtyDays as string,
    },
  ];

  const lastMonths = getLastMonths(new Date(), 3)
    .reverse()
    .map((date) => ({
      label: `${$translate('WEB2_DATE_RANGE_FILTER_PERMONTH').value} ${date.getMonth() + 1}/${date.getFullYear()}`,
      value: `m${addLeadingZero(date.getMonth() + 1, 2)}/${date.getFullYear()}`,
    }));

  return [
    ...defaultDateRange,
    ...lastMonths,
  ];
};

export const isAffiliateStats = (value: unknown) : value is AffiliateStats => isValidInterface<AffiliateStats>(value, {
  balance: isNumber,
  pendingBalance: isNumber,
  percent: isNumber,
  affiliatedCustomersCount: isNumber,
  profitForPeriod: isNumber,
  profit: isNumber,
});
