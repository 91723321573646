import { ComponentKey, ComponentStatus } from '../types';
import type { ComponentResult } from '../types';
import AbstractFingerprintComponent from '../AbstractFingerprintComponent';

export default class ColorDepthComponent extends AbstractFingerprintComponent {
  constructor() {
    super(ComponentKey.ColorDepth);
  }

  getComponentResult(): Promise<ComponentResult> {
    return this.result([window.screen.colorDepth || ComponentStatus.NotAvailable]);
  }
}
