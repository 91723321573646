import type { CombinedSlipEntryInfo } from '../types';
import { getTaxAmount } from '../../../utils';

interface Payload {
  availableEntries: CombinedSlipEntryInfo[];
  sameStakeForSingles: boolean;
  selectedStakeValue: number;
  taxPercent: number;
}

export function getMultiSinglesTotalTax({
  availableEntries,
  sameStakeForSingles,
  selectedStakeValue,
  taxPercent,
}: Payload): number {
  return availableEntries.reduce((accumulator, currentEntry) => {
    const stake = sameStakeForSingles ? selectedStakeValue : Number(currentEntry.metaInfo?.stakeValue || 0);
    const winValue = currentEntry.odds * stake;
    return accumulator + getTaxAmount({
      stake,
      winValue,
      taxPercent,
    });
  }, 0);
}
