import type { Ref } from 'vue';
import {
  computed,
  onMounted,
  ref,
  toRef,
} from 'vue';
import { useRouter } from 'vue-router';

import type { Bonus } from '@leon-hub/api-sdk';
import { CasinoRouteName } from '@leon-hub/routing-config-names/src/CasinoRouteName';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';
import { RouteName } from '@leon-hub/routing-config-names';
import { logger } from '@leon-hub/logging';

import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useCasinoGameStore } from 'web/src/modules/casino/submodules/game/store';
import type {
  CasinoLevelSettingWithStatus,
  CasinoLoyaltyProgramCurrentLevelCardProperties,
} from 'web/src/modules/casino-loyalty-program/store/types';
import type { Offer } from 'web/src/modules/bonuses/store/types';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import { TabsType } from 'web/src/components/Tabs/VTabs/enums';
import { useI18n } from 'web/src/modules/i18n/composables';
import type { VTabsProps } from 'web/src/components/Tabs/VTabs/types';
import useBonusLoyaltyStore from 'web/src/modules/bonuses/store/useBonusLoyaltyStore';
import { useBonusStore } from 'web/src/modules/bonuses/store';
import useLoyaltyShopStore from 'web/src/modules/bonuses/store/useLoyaltyShopStore';
import useCasinoLoyaltyStore from 'web/src/modules/casino-loyalty-program/store/useCasinoLoyaltyStore';

import type { CasinoGameSideBarContainerProps } from '../types';

const enum CasinoGameSideBarTabs {
  PROGRAM_LOYALTY = 'program-loyalty',
  MY_BONUSES = 'my-bonuses',
}

export interface CasinoGameSideBarContainerComposable {
  isLoggedIn: Ref<boolean>;
  isLoyaltySidebarOpened: Ref<boolean>;
  isLoyaltyProgramTab: Ref<boolean>;
  isShopOffersLoaded: Ref<boolean>;
  isBonusLoaded: Ref<boolean>;
  isLeonShopEnabled: Ref<boolean>;
  bonus: Ref<Bonus | undefined>;
  currentLevelCardProperties: Ref<CasinoLoyaltyProgramCurrentLevelCardProperties>;
  levelSettingsWithStatus: Ref<CasinoLevelSettingWithStatus[]>;
  amountNumber: Ref<number>;
  remainingAmountNumber: Ref<number>;
  progress: Ref<number>;
  progressPercentString: Ref<string>;
  isParticipating: Ref<boolean>;
  isMultiTab: Ref<boolean>;
  currentPoints: Ref<number>;
  offers: Ref<Offer[]>;
  tabsProperties: Ref<VTabsProps>;
  toggleSidebarOpened(): void;
  showLevelDescription(id: number): void;
  showCurrentLevelDescription(): void;
  onShopItemClick(id: number): void;
  handleExchange(): void;
  handleParticipate(): Promise<void>;
  onTabClick(tabName: CasinoGameSideBarTabs): void;
}

// eslint-disable-next-line max-len
export default function useCasinoGameSideBarContainer(props: CasinoGameSideBarContainerProps): CasinoGameSideBarContainerComposable {
  const router = useRouter();
  const { isLoggedIn } = useIsLoggedIn();
  const { $translate } = useI18n();

  const { push: pushAnalytics } = useAnalytics();
  const bonusStore = useBonusStore();
  const loyaltyShopStore = useLoyaltyShopStore();
  const casinoLoyaltyStore = useCasinoLoyaltyStore();

  const bonusLoyaltyStore = useBonusLoyaltyStore();
  const isBonusLoaded = toRef(bonusLoyaltyStore, 'bonusLoaded');
  const amountNumber = toRef(bonusLoyaltyStore, 'amountNumber');
  const remainingAmountNumber = toRef(bonusLoyaltyStore, 'remainingAmountNumber');
  const progress = toRef(bonusLoyaltyStore, 'progress');
  const progressPercentString = toRef(bonusLoyaltyStore, 'progressPercentString');
  const isParticipating = toRef(bonusLoyaltyStore, 'isParticipating');
  const bonus = toRef(bonusStore, 'bonusCbc');

  const currentLevelCardProperties = toRef(casinoLoyaltyStore, 'currentLevelCardProperties');
  const levelSettingsWithStatus = toRef(casinoLoyaltyStore, 'levelSettingsWithStatus');
  const currentPoints = toRef(casinoLoyaltyStore, 'currentPoints');
  const currentLevelId = toRef(casinoLoyaltyStore, 'currentLevelId');

  const isLeonShopEnabled = toRef(useSiteConfigStore(), 'isLeonShopEnabled');
  const offers = toRef(loyaltyShopStore, 'offers');

  const gamesStore = useCasinoGameStore();
  const isLoyaltySidebarOpened = toRef(gamesStore, 'isLoyaltySidebarOpened');
  const { toggleSidebarOpened } = gamesStore;

  const activeTab = ref<CasinoGameSideBarTabs>(CasinoGameSideBarTabs.PROGRAM_LOYALTY);
  const isShopOffersLoaded = ref(false);

  const isLoyaltyProgramTab = computed(() => activeTab.value === CasinoGameSideBarTabs.PROGRAM_LOYALTY);
  const isMultiTab = computed(() => isBonusLoaded.value && !props.isHideMyBonuses);

  const tabsProperties = computed<VTabsProps>(() => {
    const items = [
      {
        id: CasinoGameSideBarTabs.PROGRAM_LOYALTY,
        label: $translate('WEB2_GAME_SIDEBAR_LEVEL_TITLE').value,
      },
    ];

    if (isBonusLoaded.value && !props.isHideMyBonuses) {
      items.push({
        id: CasinoGameSideBarTabs.MY_BONUSES,
        label: $translate('WEB2_GAME_SIDEBAR_MY_POINTS').value,
      });
    }

    return {
      items,
      activeId: items.length > 1 ? activeTab.value : '',
      type: TabsType.NORMAL,
      fullWidth: true,
    };
  });

  function showLevelDescription(id: number): void {
    toggleSidebarOpened();
    void router.push({
      name: CasinoRouteName.CASINO_LOYALTY_PROGRAM_ID,
      params: { id: String(id) },
    });
  }

  function showCurrentLevelDescription(): void {
    showLevelDescription(currentLevelId.value);
  }

  function onShopItemClick(id: number): void {
    pushAnalytics(AnalyticsEvent.CLICK_MAP, { clickCounter: { leonshop: 'item' } });
    void router.push({ name: RouteName.SHOP_ITEM_DETAILS, params: { itemId: `${id}` } });
  }

  function handleExchange(): void {
    pushAnalytics(AnalyticsEvent.CLICK_MAP, { clickCounter: { leonshop: 'exchange' } });
    void router.push({ name: RouteName.BONUS_WITHDRAWALS });
  }

  async function handleParticipate(): Promise<void> {
    try {
      await bonusLoyaltyStore.confirmBonus();
      bonusLoyaltyStore.setIsParticipating(true);
    } catch {
      logger.warn('something went wrong with bonus confirmation');
    }
  }

  function onTabClick(tabName: CasinoGameSideBarTabs): void {
    activeTab.value = tabName;
  }

  onMounted(async () => {
    const promises: Promise<unknown>[] = [];

    if (isLoggedIn.value) {
      promises.push(
        casinoLoyaltyStore.fetchCasinoLoyaltyStatus(),
        bonusLoyaltyStore.fetchBonusLoyalty(),
      );
    }

    if (isLeonShopEnabled.value) {
      const loadCustomerOffers = async (): Promise<void> => {
        await loyaltyShopStore.fetchCustomerOffers();
        isShopOffersLoaded.value = true;
      };

      promises.push(loadCustomerOffers());
    }

    await Promise.all(promises);
  });

  return {
    isLoggedIn,
    isLoyaltySidebarOpened,
    bonus,
    currentLevelCardProperties,
    isLoyaltyProgramTab,
    isBonusLoaded,
    levelSettingsWithStatus,
    amountNumber,
    remainingAmountNumber,
    progress,
    progressPercentString,
    isParticipating,
    isLeonShopEnabled,
    isShopOffersLoaded,
    offers,
    isMultiTab,
    currentPoints,
    tabsProperties,
    toggleSidebarOpened,
    handleParticipate,
    showLevelDescription,
    showCurrentLevelDescription,
    onShopItemClick,
    handleExchange,
    onTabClick,
  };
}
