import type { Ref } from 'vue';
import {
  computed,
  nextTick,
  onMounted,
  ref,
  toRef,
} from 'vue';

import { assert } from '@leon-hub/guards';
import { nextAnimationFrame } from '@leon-hub/html-utils';

import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';

import useAuthorizedCordovaAppController from './useAuthorizedCordovaAppController';
import type {
  AuthorizedAppControllerEmits,
  AuthorizedAppControllerProps,
} from '../types';
import useAuthorizedHandler from './useAuthorizedHandler';
import { getEgsLoyaltyStore } from './utils';

export interface UseAuthorizedAppControllerComposable {
  isEgsLoyaltyDoneModalVisible: Ref<boolean>;
}

export default function useAuthorizedAppController(
  props: AuthorizedAppControllerProps,
  emit: AuthorizedAppControllerEmits,
): UseAuthorizedAppControllerComposable {
  let isEgsLoyaltyDoneModalVisible: Ref<boolean>;
  let afterMount = (): void => {};

  if (process.env.VUE_APP_PLATFORM_CORDOVA) {
    afterMount = useAuthorizedCordovaAppController(props).afterMount;
  }

  const api = useGraphqlClient();
  const { onLogin } = useAuthorizedHandler();
  const egsLoyaltyStore = getEgsLoyaltyStore();

  if (process.env.VUE_APP_FEATURE_CASINO_ENABLED) {
    isEgsLoyaltyDoneModalVisible = computed(() => {
      assert(egsLoyaltyStore);
      const isShowLevelDoneModal = toRef(egsLoyaltyStore, 'isShowLevelDoneModal');
      return isShowLevelDoneModal.value;
    });
  } else {
    isEgsLoyaltyDoneModalVisible = ref(false);
  }

  onMounted(async () => {
    try {
      await nextTick();
      await nextAnimationFrame();
      await Promise.all([
        onLogin(props.isAppInit),
      ].concat(props.isAppInit ? [api.flush()] : []));

      afterMount();
    } catch (error) {
      emit('load-error', error);
    }

    emit('loaded');
  });

  return {
    isEgsLoyaltyDoneModalVisible,
  };
}
