import type { SlipInfoFieldsFragment } from '@leon-hub/api-sdk';
import { SlipStatus } from '@leon-hub/api-sdk';

import isEntriesHasLimitStatus from './isEntriesHasLimitStatus';
import type { OnAddingErrorPayload } from '../types';
import { AddSlipEntriesError } from '../../../enums';

export default function checkSlipSyncErrors({ status, message, entries }: SlipInfoFieldsFragment)
  : OnAddingErrorPayload | null {
  if (status === SlipStatus.OK) {
    return null;
  }
  let entriesError: AddSlipEntriesError | null = null;
  const marketsWasChanged = status === SlipStatus.MARKETS_CHANGED;
  if (marketsWasChanged && isEntriesHasLimitStatus([...entries])) {
    entriesError = AddSlipEntriesError.LIMIT;
  }
  if (!marketsWasChanged || entriesError) {
    return { message: message || '', entriesError };
  }
  return null;
}
