<script lang="ts" setup>
import { RouteName } from '@leon-hub/routing-config-names';

import TheSidebarMenu from 'web/src/components/SidebarMenu/TheSidebarMenu/TheSidebarMenu.vue';
import VRouterLink from 'web/src/components/Link/VRouterLink/VRouterLink.vue';

import { usePromotionsSidebarRouteComponent } from './composables';

const {
  menu,
  isPromoMainPage,
} = usePromotionsSidebarRouteComponent();
</script>

<template>
  <TheSidebarMenu v-auto-id="'PromotionsSidebarRouteComponent'" :menu="menu">
    <template #top>
      <VRouterLink
        :class="{
          [$style['sidebar-caption']]: true,
          [$style['sidebar-caption--active']]: isPromoMainPage,
        }"
        :to="$to(RouteName.PROMOTIONS)"
      >
        {{ $t('WEB2_PROMO_SECTION_TITLE') }}
      </VRouterLink>
    </template>
  </TheSidebarMenu>
</template>

<style lang="scss" module>
.sidebar-caption {
  @include medium\12\16\04\caps;

  padding: 12px;
  margin-bottom: 4px;
  color: var(--TextPrimary);
  text-decoration: none;
  cursor: pointer;
  border-radius: 5px;

  &--active {
    background-color: var(--Layer1);
  }
}
</style>
