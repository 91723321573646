import type { Ref } from 'vue';
import { ref, computed } from 'vue';

import { useParseSportlineSettingsRef } from 'web/src/modules/sportline/composables/settings';
import {

  useSportlineEventId,
  useSportlineEventTypeRef,
} from 'web/src/modules/sportline/composables/sportline-event';
import useSportlineCacheStorage from 'web/src/modules/sportline/store/useSportlineCacheStorage';
import { useSportlineApiService } from 'web/src/modules/sportline/services';
import { useBroadcastSelected } from 'web/src/modules/sportline/composables/broadcast';
import { useSportlineEventsChangesListeners } from 'web/src/modules/sportline/store/composables';
import { useStreamUrlStoreComposable } from 'web/src/modules/sportline/submodules/streams/store/composables';
import type { UseStreamUrlStoreComposable } from 'web/src/modules/sportline/submodules/streams/store/composables';
import type { CoreSportEventResponse, CoreSportlineFetchOptions } from 'web/src/modules/sportline/types/rest';
import type {
  Sport,
  Region,
  League,
  SportElement,
  SportlineEvent,
  SportEventDetailsPreview,
} from 'web/src/modules/sportline/types';
import type { SportlinePageIdentifier as SportEventsPageIdentifier } from 'web/src/modules/sportline/types/navigation';
import type { BetlineSportEventStatus } from 'web/src/modules/sportline/enums/rest';
import type { SportlineEventDetails } from 'web/src/modules/sportline/submodules/event-details/types';
import {
  getSportEventDetailsFromPreviewData,
} from 'web/src/modules/sportline/submodules/event-details/utils';
import type {
  GetEventStatusID,
} from 'web/src/modules/sportline/submodules/event-details/utils/requests-counter/types';
import {
  GetEventSubscriptionStatus,
} from 'web/src/modules/sportline/submodules/event-details/utils/requests-counter/enums';
import { DetailsAdapter, buildSportlineSportElement } from 'web/src/modules/sportline/utils/rest';

interface UseSportlineEventDetailsDataStoreComposableProps {
  pageIdentifier: Ref<SportEventsPageIdentifier>;
  fireRequestStatus(id: GetEventStatusID, status: GetEventSubscriptionStatus): void;
}

interface UseSportlineEventDetailsDataStoreComposableEvents {
  sportEventDetailsUpdated(value: Maybe<CoreSportEventResponse | false>): void;
}

interface UseSportlineEventDetailsDataStoreComposable {
  isLoaded: Ref<boolean>;
  isShowingLoadingIndicator: Ref<boolean>;
  rawSportEventDetails: Ref<Maybe<CoreSportEventResponse | false>>;
  sportEventDetailsPreview: Ref<Maybe<SportEventDetailsPreview>>;
  sportEventDetails: Ref<Maybe<SportlineEventDetails>>;
  sportEventDetailsId: Ref<Maybe<string>>;
  sportEventDetailsLoadedId: Ref<Maybe<string>>;
  sportElement: Ref<Maybe<SportElement>>;
  sport: Ref<Maybe<Sport>>;
  region: Ref<Maybe<Region>>;
  league: Ref<Maybe<League>>;
  setDetailsStatus(status: BetlineSportEventStatus): void;
  clearDetailsMarketsList(): void;
  setRawSportEventDetails(value: Maybe<CoreSportEventResponse | false>): void;
  fetchDetails({ silent }: CoreSportlineFetchOptions): Promise<void>;
  onInit(): void;
  streamInfo: UseStreamUrlStoreComposable;
}

export function useSportlineEventDetailsDataStoreComposable(
  props: UseSportlineEventDetailsDataStoreComposableProps,
  events: UseSportlineEventDetailsDataStoreComposableEvents,
): UseSportlineEventDetailsDataStoreComposable {
  const { pageIdentifier, fireRequestStatus } = props;

  const {
    getSportEventCache,
    getLeagueCacheByKey,
    getRegionCacheByKey,
    getSportCacheByKey,
    getMarketCache,
  } = useSportlineCacheStorage();
  const parseSportlineSettings = useParseSportlineSettingsRef();
  const apiService = useSportlineApiService();
  const broadcastSelected = useBroadcastSelected();

  const rawSportEventDetails = ref<Maybe<CoreSportEventResponse | false>>(null);
  const isShowingLoadingIndicator = ref(false);

  const sportEventDetailsLoadedId = computed<Maybe<string>>(() => (
    rawSportEventDetails.value ? String(rawSportEventDetails.value.id) : null
  ));
  /** Return is load data for target id completed (have data or missing event) */
  const isLoaded = computed<boolean>(() => {
    if (rawSportEventDetails.value === null) { return false; }

    // load missing event
    if (rawSportEventDetails.value === false) { return true; }

    const targetId = pageIdentifier.value.sportEventId;

    return !!targetId && !!sportEventDetailsLoadedId.value
      && targetId === sportEventDetailsLoadedId.value;
  });
  const sportEventDetailsPreview = computed<Maybe<SportEventDetailsPreview>>(() => {
    const targetId = pageIdentifier.value.sportEventId;

    if (!targetId) { return null; }

    const sportEventCache = getSportEventCache(targetId);

    if (!sportEventCache.value) { return null; }

    const leagueCache = getLeagueCacheByKey(sportEventCache.value.leagueKey);
    const regionCache = getRegionCacheByKey(sportEventCache.value.regionKey);
    const sportCache = getSportCacheByKey(sportEventCache.value.sportKey);
    if (!leagueCache?.value || !regionCache?.value || !sportCache?.value) { return null; }

    const marketsCache = getMarketCache(targetId);
    const { markets, marketGroups, moreCount } = marketsCache?.value?.markets ?? {};

    return {
      sport: sportCache.value.sport,
      region: regionCache.value.region,
      league: leagueCache.value.league,
      sportEvent: {
        ...sportEventCache.value.sportEvent as SportlineEvent,
        markets: markets ?? [],
        marketGroups: marketGroups ?? [],
        moreCount: moreCount ?? {},
      },
    };
  });
  const sportEventDetails = computed<Maybe<SportlineEventDetails>>(() => {
    const loadedId = rawSportEventDetails.value ? String(rawSportEventDetails.value.id) : null;
    const targetId = pageIdentifier.value.sportEventId;

    if (rawSportEventDetails.value === null || loadedId !== targetId) {
      // return preview for unloaded event or mismatched id
      return getSportEventDetailsFromPreviewData(
        sportEventDetailsPreview.value,
        { actualSportEventId: targetId },
      );
    }

    if (rawSportEventDetails.value === false) {
      return null;
    }

    return (new DetailsAdapter(rawSportEventDetails.value, parseSportlineSettings.value))
      .sportEventDetails;
  });

  const sportEventDetailsId = useSportlineEventId(sportEventDetails);
  const sportlineType = useSportlineEventTypeRef(sportEventDetails);

  const streamInfo = useStreamUrlStoreComposable({ sportlineEventId: sportEventDetailsId, sportlineType });

  const sportElement = computed<Maybe<SportElement>>(() => (
    rawSportEventDetails.value
      ? buildSportlineSportElement(rawSportEventDetails.value, parseSportlineSettings.value)
      : null
  ));

  const sport = computed<Maybe<Sport>>(() => sportEventDetails.value?.sport ?? null);
  const region = computed<Maybe<Region>>(() => sportEventDetails.value?.region ?? null);
  const league = computed<Maybe<League>>(() => sportEventDetails.value?.league ?? null);

  function setRawSportEventDetails(value: Maybe<CoreSportEventResponse | false>): void {
    rawSportEventDetails.value = value;
    events.sportEventDetailsUpdated(value);
  }

  function setDetailsStatus(status: BetlineSportEventStatus): void {
    const details = rawSportEventDetails.value;
    if (!details) { return; }
    setRawSportEventDetails({ ...details, status });
  }

  function clearDetailsMarketsList(): void {
    const details = rawSportEventDetails.value;
    if (!details) { return; }
    setRawSportEventDetails({ ...details, markets: [], marketsCount: 0 });
  }

  async function fetchDetails({ silent }: CoreSportlineFetchOptions): Promise<void> {
    const eventId = pageIdentifier.value.sportEventId;

    if (!eventId) {
      return;
    }

    if (!isLoaded.value) {
      isShowingLoadingIndicator.value = true;
      streamInfo.clearStreamInfo();
    }

    let result: Maybe<CoreSportEventResponse>;

    try {
      result = await apiService.getEventDetails({ id: eventId, silent });
    } catch (error) {
      fireRequestStatus(eventId, GetEventSubscriptionStatus.Error);
      throw error;
    }

    if (!result) {
      fireRequestStatus(eventId, GetEventSubscriptionStatus.Missing);
      // MISSING event
      isShowingLoadingIndicator.value = false;
      broadcastSelected.removeEventsDataInStorage([eventId]);
      return;
    }

    const resultId = String(result.id);

    fireRequestStatus(resultId, GetEventSubscriptionStatus.OK);

    // old data
    if (eventId !== resultId) { return; }

    isShowingLoadingIndicator.value = false;
    setRawSportEventDetails(result);
    broadcastSelected.updateDataInStorageByResponse({
      response: { events: [result], totalCount: 1 },
    });
  }

  const {
    onInit: onSportlineEventsChangesListenersInit,
  } = useSportlineEventsChangesListeners({
    setResponse(response: CoreSportEventResponse): void {
      rawSportEventDetails.value = response;
    },
    getResponse(): Maybe<CoreSportEventResponse> {
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      return rawSportEventDetails.value || null;
    },
  });

  function onInit(): void {
    onSportlineEventsChangesListenersInit();
  }

  return {
    isLoaded,
    isShowingLoadingIndicator,
    rawSportEventDetails,
    sportEventDetailsPreview,
    sportEventDetails,
    sportEventDetailsId,
    sportEventDetailsLoadedId,
    sportElement,
    sport,
    region,
    league,
    setDetailsStatus,
    clearDetailsMarketsList,
    setRawSportEventDetails,
    fetchDetails,
    onInit,
    streamInfo,
  };
}
