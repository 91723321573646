import { RouteName } from '@leon-hub/routing-config-names';
import CustomerRouteAccessRole from '@leon-hub/routing-config/src/types/CustomerRouteAccessRole';
import { themeDark } from '@leon-hub/api-sdk';

import { AppModule } from 'web/src/modules/core/plugins/AppModule';
import type { AppVueRouter } from 'web/src/modules/core/services/router/types';

export class FortuneWheelModule extends AppModule {
  // eslint-disable-next-line class-methods-use-this
  addRoutes(router: AppVueRouter): void {
    if (process.env.VUE_APP_LAYOUT_DESKTOP) {
      router.addDefaultRoute({
        name: RouteName.FORTUNE_WHEEL,
        path: '/fortune-wheel',
        access: CustomerRouteAccessRole.AUTHORIZED,
        component: () => import('web/src/modules/fortune-wheel/pages/FortuneWheelRoutePage/FortuneWheelRoutePage.vue'),
        isFooterHidden: true,
        theme: themeDark,
      });
    }

    if (process.env.VUE_APP_LAYOUT_PHONE) {
      router.addModalRoute({
        name: RouteName.FORTUNE_WHEEL,
        path: '/fortune-wheel',
        access: CustomerRouteAccessRole.AUTHORIZED,
        component: () => import('web/src/modules/fortune-wheel/pages/FortuneWheelRoutePage/FortuneWheelRoutePage.vue'),
        topBar: () => import('web/src/modules/core/views/EmptyTopBarRouteComponent/EmptyTopBarRouteComponent.vue'),
        theme: themeDark,
      });
    }
  }
}
