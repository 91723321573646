import type { WatchStopHandle } from 'vue';
import { ref, watch } from 'vue';
import { defineStore } from 'pinia';
import { useRouter } from 'vue-router';

export const useSportlineRedirectInfoStore = defineStore('sportline-redirect-info', () => {
  const router = useRouter();

  const redirectedFromRoute = ref<Maybe<string>>(null);

  let unwatchRoute: Optional<WatchStopHandle>;

  function clearRedirectWasHandledInfo(): void {
    redirectedFromRoute.value = null;
    unwatchRoute?.();
    unwatchRoute = undefined;
  }

  function showRedirectWasHandledInfo({ from }: { from: string }) {
    redirectedFromRoute.value = from;
    unwatchRoute = watch(router.currentRoute, clearRedirectWasHandledInfo);
  }

  return {
    redirectedFromRoute,
    showRedirectWasHandledInfo,
    clearRedirectWasHandledInfo,
  };
});
