<script setup lang="ts">
import VLogoLoader from 'web/src/components/Loader/VLogoLoader/VLogoLoader.vue';
import VIframe from 'web/src/components/Iframe/VIframe/VIframe.vue';

import type { SportradarWidgetRoutePageProps } from './types';
import { useSportradarWidgetRoutePage } from './composables';

const props = defineProps<SportradarWidgetRoutePageProps>();

const {
  frame,
  internalWidgetLoaded,
  isIframeVisible,
  iframeWidgetSrc,
  iframeName,
  additionalQueryStringParameters,
  initPostMessageBus,
} = useSportradarWidgetRoutePage(props);
</script>

<template>
  <div v-auto-id="'SportradarWidgetRoutePage'" :class="$style['sportradar-statistics']">
    <div
      v-if="!internalWidgetLoaded"
      :class="$style['sportradar-statistics__loader']"
    >
      <VLogoLoader :class="$style['sportradar-statistics__loader-image']" />
    </div>

    <VIframe
      v-if="isIframeVisible && iframeWidgetSrc"
      ref="frame"
      :class="$style['sportradar-statistics__widget']"
      :src="iframeWidgetSrc"
      full-width
      :name="iframeName"
      :additional-query-string-parameters="additionalQueryStringParameters"
      @vue:mounted="initPostMessageBus"
    />
  </div>
</template>

<style lang="scss" module>
.sportradar-statistics {
  min-height: 100vh;

  &__title {
    @include medium\18\20;

    padding: 16px;
    margin: 0;
    color: var(--TextDefault);
  }

  &__widget {
    @include is-app-layout-phone {
      padding: 0 8px;
    }
  }

  &__loader {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 453px;
    padding: 50px 0;
    background: var(--Layer0);
  }

  &__loader-image {
    max-width: 255px;
  }
}
</style>
