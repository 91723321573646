<script lang="ts" setup>
import { useSportlineLayoutSettings } from 'web/src/modules/sportline/composables/settings';

import type { PostMatchStatisticHeadlineProps } from './types';
import DefaultPostMatchStatisticHeadline from './default/DefaultPostMatchStatisticHeadline.vue';
import BetSwipePostMatchStatisticHeadline from './bet-swipe/BetSwipePostMatchStatisticHeadline.vue';

defineProps<PostMatchStatisticHeadlineProps>();

const { isBetSwipeEventDetailsLayoutEnabled } = useSportlineLayoutSettings();
</script>

<template>
  <BetSwipePostMatchStatisticHeadline
    v-if="null && isBetSwipeEventDetailsLayoutEnabled"
    :post-match-statistic="postMatchStatistic"
  />
  <DefaultPostMatchStatisticHeadline
    v-else
    :post-match-statistic="postMatchStatistic"
  />
</template>
