import type {
  SportEventElement,
  LeagueElement,
  RegionElement,
  SportElement,
  SportEventsCounters,
  SportlineEvent,
} from 'web/src/modules/sportline/types';
import {
  SportlineType,
} from 'web/src/modules/sportline/enums';

export function createSportEventsCounters(
  live = 0,
  prematch = 0,
  liveOutright = 0,
  outright = 0,
): SportEventsCounters {
  return {
    live,
    prematch,
    liveOutright,
    outright,
    total: live + prematch + liveOutright + outright,
  };
}

export function addSportlineEventToCounters(
  counters: SportEventsCounters,
  sportEvent: Pick<SportlineEvent, 'type' | 'isOutright'>,
): SportEventsCounters {
  return createSportEventsCounters(
    counters.live + Number(sportEvent.type === SportlineType.Live && !sportEvent.isOutright),
    counters.prematch + Number(sportEvent.type === SportlineType.Prematch && !sportEvent.isOutright),
    counters.liveOutright + Number(sportEvent.type === SportlineType.Live && sportEvent.isOutright),
    counters.outright + Number(sportEvent.type === SportlineType.Prematch && sportEvent.isOutright),
  );
}

export function addCountersToCounters(first: SportEventsCounters, second: SportEventsCounters): SportEventsCounters {
  return createSportEventsCounters(
    first.live + second.live,
    first.prematch + second.prematch,
    first.liveOutright + second.liveOutright,
    first.outright + second.outright,
  );
}

export function getSportEventElementsListCounters(list: SportEventElement[]): SportEventsCounters {
  return list.reduce<SportEventsCounters>(
    (counters, sportEventElement) => addSportlineEventToCounters(counters, sportEventElement.sportEvent),
    createSportEventsCounters(),
  );
}

export function reduceListWithSportEventsCounters(elements: { counters: SportEventsCounters }[]): SportEventsCounters {
  return elements.reduce(
    (counters, element) => addCountersToCounters(counters, element.counters),
    createSportEventsCounters(),
  );
}

export function getLeagueElementEventsCounters(leagueElement: LeagueElement): SportEventsCounters {
  return getSportEventElementsListCounters([
    ...leagueElement.sportEvents,
    ...leagueElement.outrightEvents,
  ]);
}

export function getRegionElementEventsCounters(regionElement: RegionElement): SportEventsCounters {
  return regionElement.leagues.reduce((counters, leagueElement) => {
    const result = getLeagueElementEventsCounters(leagueElement);
    return addCountersToCounters(counters, result);
  }, createSportEventsCounters());
}

export function getSportElementEventsCounters(sportElement: SportElement): SportEventsCounters {
  return sportElement.regions.reduce((counters, regionElement) => {
    const result = getRegionElementEventsCounters(regionElement);
    return addCountersToCounters(counters, result);
  }, createSportEventsCounters());
}
