<script lang="ts" setup>
import { onMounted } from 'vue';

import { useVReCaptcha } from 'web/src/modules/captcha/components/VReCaptcha/composables';
import type { VReCaptchaExpose, VReCaptchaWidgetTheme } from 'web/src/modules/captcha/components/VReCaptcha/types';
import type { ReCaptchaType } from 'web/src/modules/captcha/store/enums';
import type { ReCaptchaResponse } from 'web/src/modules/captcha/store/types';

const props = withDefaults(defineProps<VReCaptchaProperties>(), {
  siteKey: '',
  language: '',
});

const emits = defineEmits<VReCaptchaEmits>();

interface VReCaptchaProperties {
  siteKey?: string;
  theme?: VReCaptchaWidgetTheme;
  badge?: string;
  type?: ReCaptchaType;
  tabindex?: number;
  apiUrl?: string;
  language?: string;
  autoExecute?: boolean;
}

interface VReCaptchaEmits {
  (e: 'verify', value: ReCaptchaResponse): void;

  (e: 'expired'): void;

  (e: 'error', error: unknown): void;

  (e: 'render', id: string): string;

  (e: 'challenge-is-closed'): void;

  (e: 'challenge-is-opened'): void;
}

const {
  root,
  execute,
  reset,
  onComponentCreated,
  onComponentMounted,
} = useVReCaptcha(props, emits);

onMounted(() => {
  onComponentCreated();
  void onComponentMounted();
});

defineExpose<VReCaptchaExpose>({
  reset,
  execute,
});
</script>

<template>
  <div v-auto-id="'VReCaptcha'"
    ref="root"
    :class="$style['the-re-captcha']"
  />
</template>

<style lang="scss" module>
.the-re-captcha {
  transform: scale(0.9);
}
</style>
