import type { CountryData } from 'web/src/modules/country/store/types';

const canadianAreaCodes = [
  '204', // Area Code Manitoba
  '226', // Area Code Ontario
  '236', // Area Code British Columbia
  '249', // Area Code Ontario
  '250', // Area Code British Columbia
  '289', // Area Code Ontario
  '306', // Area Code Saskatchewan
  '343', // Area Code Ontario
  '365', // Area Code Ontario
  '403', // Area Code Alberta
  '416', // Area Code Toronto, Ontario
  '418', // Area Code Quebec
  '431', // Area Code Manitoba
  '437', // Area Code Ontario
  '438', // Area Code Quebec
  '450', // Area Code Quebec
  '506', // Area Code New Brunswick
  '514', // Area Code Montreal, Quebec
  '519', // Area Code Ontario
  '579', // Area Code Quebec
  '581', // Area Code Quebec
  '587', // Area Code Alberta
  '604', // Area Code British Columbia
  '613', // Area Code Ontario
  '639', // Area Code Saskatchewan
  '647', // Area Code Ontario
  '705', // Area Code Ontario
  '709', // Area Code Newfoundland
  '778', // Area Code British Columbia
  '780', // Area Code Alberta
  '807', // Area Code Ontario
  '819', // Area Code Quebec
  '867', // Area Code Northwest Territories / Nunavut / Yukon
  '873', // Area Code Quebec
  '902', // Area Code Nova Scotia / Prince Edward Island
  '905', // Area Code Ontario
];

/**
 * example numbers from google maps:
 * +441624648000
 * +447624305308
 * +441624897311
 */
const isleOfManAreaCodes = [
  '1624',
  '7624',
  '7524',
  '7924',
];

/**
 * example numbers from google maps:
 * +441534614000
 * +441534860000
 * +441534487982
 * +447797744942
 * */
const jerseyAreaCodes = [
  '1534',
  '7509',
  '7797',
  '7937',
  '7700',
  '7829',
];

/**
 * example numbers from google maps:
 * +441481728469
 * +441481727793
 * +447839739144
 * */
const guernseyAreaCodes = [
  '1481',
  '7781',
  '7839',
  '7911',
];

export interface MatchPhoneNumberAndCountryCodeInput {
  prefix: string;
  phoneNumber?: string;
  countryCode: string;
  countries: CountryData[];
}

const matchPhoneNumberAndCountryCode = ({
  phoneNumber, countries, countryCode, prefix,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}: MatchPhoneNumberAndCountryCodeInput): string => {
  const countriesRelatedToPrefix = countries.filter((item) => `${item.phonePrefix}` === prefix);
  if (countriesRelatedToPrefix.length) {
    if (countriesRelatedToPrefix.length === 1) {
      return countriesRelatedToPrefix[0].code;
    }
    const matchedPrefix = countriesRelatedToPrefix.find((item) => item.code === countryCode);
    if (matchedPrefix) {
      return matchedPrefix.code;
    }
    if (phoneNumber && phoneNumber.length > 2) {
      if (prefix === '1') {
        // Sint-Maarten | Canada | USA
        const areaCode = phoneNumber.slice(0, 3);
        if (areaCode === '721') {
          // Sint-Maarten
          return 'SX';
        }
        if (canadianAreaCodes.includes(areaCode)) {
          return 'CA';
        }
        return 'US';
      }
      if (prefix === '7') {
        // KZ | RU
        if (phoneNumber.startsWith('6') || (phoneNumber.startsWith('7'))) {
          return 'KZ';
        }
        return 'RU';
      }
      if (prefix === '47') {
        // Svalbard and Jan Mayen | Bouvet island | Norway
        if (phoneNumber.startsWith('79')) {
          return 'SJ';
        }
        // no way to detect Bouvet island | BV by number pattern
        return 'NO';
      }
      if (prefix === '500') {
        // South Georgia and the South Sandwich Islands | Falkland Islands
        if (phoneNumber.startsWith('4')) {
          return 'GS';
        }
        return 'FK';
      }
      if (prefix === '61') {
        // Christmas Island, Australia, Cocos (Keeling) Islands
        if (phoneNumber.startsWith('89164')) {
          return 'CX'; // Christmas Island
        }
        if (phoneNumber.startsWith('89162')) {
          return 'CC'; // Cocos (Keeling) Islands
        }
        return 'AU';
      }
      if (prefix === '212') {
        // Western Sahara , Morocco
        if (phoneNumber.startsWith('5288') || phoneNumber.startsWith('5289')) {
          return 'EH'; // Western Sahara
        }
        return 'MA';
      }
      if (prefix === '246') {
        // British Indian Ocean Territory | US Underlying islands
        if (phoneNumber.startsWith('37') || phoneNumber.startsWith('38')) {
          // British Indian Ocean Territory
          return 'IO';
        }
        return 'UM';
      }
      if (prefix === '262') {
        // Réunion, Mayotte
        if (phoneNumber.startsWith('269') || phoneNumber.startsWith('639')) {
          return 'YT'; // Mayotte
        }
        return 'RE';
      }
      if (prefix === '358') {
        // Åland, Finland
        if (phoneNumber.startsWith('018')) {
          return 'AX';
        }
        return 'FI';
      }
      if (prefix === '44') {
        // Guernsey, Isle of Man, Jersey, United Kingdom
        const areaCode = phoneNumber.slice(0, 4);
        // Isle of Man
        if (isleOfManAreaCodes.includes(areaCode)) {
          return 'IM';
        }
        // Jersey
        if (jerseyAreaCodes.includes(areaCode)) {
          return 'JE';
        }
        // Guernsey
        if (guernseyAreaCodes.includes(areaCode)) {
          return 'GG';
        }
        return 'GB';
      }
      if (prefix === '672') {
        // Norfolk Island | Antarctica
        if (phoneNumber.startsWith('3')) {
          return 'NF';
        }
        return 'AQ';
      }
    }
    // first matched prefix
    return countriesRelatedToPrefix[0].code;
  }
  return countryCode;
};

export default matchPhoneNumberAndCountryCode;
