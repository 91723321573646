import type { MetricsToolType } from '@leon-hub/api-sdk';

import type {
  MetricsToolsSetup,
  MetricsToolsData,
} from '../types';

export default function findMetricsToolsByType(
  type: MetricsToolType,
  config?: MetricsToolsData,
): MetricsToolsSetup | undefined {
  return (config || []).find((item) => (
    item.type === type
  ))?.setup;
}
