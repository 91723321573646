import type { DefinedModule, DefinitionCallback } from './types';

export function defineModule<USAGE_ARGS extends unknown[]>(
  definitionCallback: DefinitionCallback<USAGE_ARGS, undefined>,
): DefinedModule<USAGE_ARGS, undefined>;
export function defineModule<USAGE_ARGS extends unknown[], R extends Promise<unknown>>(
  definitionCallback: DefinitionCallback<USAGE_ARGS, R>,
): DefinedModule<USAGE_ARGS, R>;
export function defineModule<USAGE_ARGS extends unknown[], R>(
  definitionCallback: DefinitionCallback<USAGE_ARGS, R>,
): DefinedModule<USAGE_ARGS, R>;
export function defineModule<USAGE_ARGS extends unknown[], R>(
  definitionCallback: DefinitionCallback<USAGE_ARGS, R>,
): DefinedModule<USAGE_ARGS, R> {
  return (...args: USAGE_ARGS) => definitionCallback(...args);
}
