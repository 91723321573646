<script setup lang="ts">
import { toRef } from 'vue';

import { useVirtualSportDebugStore } from 'web/src/modules/sportline/submodules/virtual-sport/store';
import VirtualSportFilter from 'web/src/modules/sportline/submodules/virtual-sport/components/Filter/SportlineVirtualSportFilter.vue';
import VirtualSportWidget from 'web/src/modules/sportline/submodules/virtual-sport/views/Widget/VirtualSportWidget.vue';

import { useVirtualSportRoutePage } from './useVirtualSportRoutePage';
import DebugIndicator from './views/DebugIndicator.vue';

const debugVirtualSportStore = useVirtualSportDebugStore();
const isDebugEnabled = toRef(debugVirtualSportStore, 'isDebugEnabled');
const {
  title,
  internalWidgetLoaded,
  widgetConfig,
  selectedFilter,
  filterOptions,
  selectedOption,
  selectedBetsMappings,
  onChangeSelectedSportFilter,
  onWidgetLoaded,
} = useVirtualSportRoutePage();
</script>

<template>
  <div v-auto-id="'VirtualSportRoutePage'"
    :class="[
      $style['virtual-sport'],
      $style['component-wrapper'],
    ]"
  >
    <VirtualSportFilter
      :filter="selectedFilter"
      :options="filterOptions"
      @change-filter="onChangeSelectedSportFilter"
    />

    <p :class="$style['virtual-sport__title']">
      {{ title }}

      <DebugIndicator v-if="isDebugEnabled" />
    </p>

    <VirtualSportWidget
      v-if="selectedOption && selectedOption.clientId"
      :client-id="selectedOption.clientId"
      :iframe-url="widgetConfig.iframeUrl"
      :provider-url="widgetConfig.providerUrl"
      :stylable="widgetConfig.stylable"
      :selected-virtual-sport="selectedOption.sport"
      :selected-bets-mappings="selectedBetsMappings"
      :internal-widget-loaded="internalWidgetLoaded"
      :class="$style['virtual-sport__widget']"
      @widget-loaded="onWidgetLoaded"
    />
  </div>
</template>

<style lang="scss" module>
@include for-layout using ($isDesktop) {
  .virtual-sport {
    min-height: 100vh;

    &__title {
      @include medium\18\20;

      position: relative;
      padding: 16px;
      margin: 0;
      color: var(--TextDefault);
    }

    &__widget {
      padding: if($isDesktop, 0, 0 8px);
    }
  }
}
</style>
