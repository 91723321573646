<script lang="ts" setup>
import { useHeaderLogo } from './composables';

const {
  tag,
  componentProperties,
  onClick,
} = useHeaderLogo();
</script>

<template>
  <component v-auto-id="'HeaderLogo'"
    :is="tag"
    v-bind="componentProperties"
    :class="{
      [$style['header__logo']]: true,
    }"
    @click="onClick"
  >
    <slot />
  </component>
</template>

<style lang="scss" module>
@include for-layout using ($isDesktop) {
  .header__logo {
    display: flex;
    align-items: center;

    @if $isDesktop {
      margin-right: $headerLogoMarginRightDesktopSmall;

      @include screen-desktop-medium-min {
        margin-right: $headerLogoMarginRightDesktopLarge;
      }
    }
  }
}
</style>
