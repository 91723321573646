import { defineStore } from 'pinia';
import { ref } from 'vue';

import { TopLevelTabs } from '../../../enums';

const useSlipViewSettingsStore = defineStore('slip-view-settings', () => {
  /** mobile-only props */
  const isHiddenOnMobile = ref<boolean>(true);

  /** true when enter animation is done */
  const isFullyVisibleOnMobile = ref<boolean>(false);

  const isLeaveEnterAnimationPlaying = ref<boolean>(false);

  const isFullHeight = ref<boolean>(false);

  /** mobile-only props end */

  const activeTopTabId = ref<TopLevelTabs>(TopLevelTabs.SLIP);

  /* ex showSlipSettings */
  const isSettingsVisible = ref<boolean>(false);

  const isClearBetListWarnVisible = ref<boolean>(false);

  const isSlipFromBookingCodeOverlayVisible = ref<boolean>(false);

  const setIsFullyVisibleOnMobile = (value: boolean): void => {
    isFullyVisibleOnMobile.value = value;
  };

  const setSlipVisibility = (isVisible: boolean): void => {
    isHiddenOnMobile.value = !isVisible;
  };

  const setLeaveEnterAnimationStatus = (isPlaying: boolean): void => {
    isLeaveEnterAnimationPlaying.value = isPlaying;
  };

  const setSlipFullHeight = (isNowFullHeight: boolean): void => {
    isFullHeight.value = isNowFullHeight;
  };

  const selectTopTab = (id: TopLevelTabs): void => {
    activeTopTabId.value = id;
  };

  const setSlipSettingsVisibility = (isVisible: boolean): void => {
    isSettingsVisible.value = isVisible;
  };

  const setClearBetListWarnVisibility = (isVisible: boolean): void => {
    isClearBetListWarnVisible.value = isVisible;
  };

  const setDefaultSlipView = (): void => {
    setSlipSettingsVisibility(false);
    selectTopTab(TopLevelTabs.SLIP);
  };

  const showSlipSettings = (): void => {
    setSlipSettingsVisibility(true);
    setSlipFullHeight(true);
  };

  const hideSlipSettings = (): void => {
    setSlipFullHeight(false);
    setSlipSettingsVisibility(false);
  };

  const handleSlipVisibilityChange = (isVisible: boolean): void => {
    setSlipVisibility(isVisible);
    setLeaveEnterAnimationStatus(true);
  };

  const stopLeaveEnterAnimation = (): void => {
    setLeaveEnterAnimationStatus(false);
  };

  const showSlipFromBookingCodeOverlay = (): void => {
    isSlipFromBookingCodeOverlayVisible.value = true;
  };

  const hideSlipFromBookingCodeOverlay = (): void => {
    isSlipFromBookingCodeOverlayVisible.value = false;
  };

  return {
    isHiddenOnMobile,
    isLeaveEnterAnimationPlaying,
    isFullHeight,
    activeTopTabId,
    isSettingsVisible,
    isClearBetListWarnVisible,
    isFullyVisibleOnMobile,
    isSlipFromBookingCodeOverlayVisible,
    setSlipVisibility,
    setIsFullyVisibleOnMobile,
    stopLeaveEnterAnimation,
    handleSlipVisibilityChange,
    setSlipFullHeight,
    selectTopTab,
    setClearBetListWarnVisibility,
    setDefaultSlipView,
    showSlipSettings,
    hideSlipSettings,
    setSlipSettingsVisibility,
    showSlipFromBookingCodeOverlay,
    hideSlipFromBookingCodeOverlay,
  };
});

export default useSlipViewSettingsStore;
