import type { ProgramRequirement } from '@leon-hub/api-sdk';

import type { ReferralDataGetDataDocument } from 'web/src/modules/referral-program/types/ReferralProgramDocuments';

export default function getCompletedProgramRequirements(
  programReferral: Maybe<ReferralDataGetDataDocument>,
): ProgramRequirement[] {
  if (programReferral?.referralDetails) {
    const { requirements } = programReferral.referralDetails;

    return requirements.filter((requirement) => !!requirement.satisfied);
  }

  return [];
}
