import { useRouter } from 'vue-router';
import type { Ref } from 'vue';
import { computed, toRef } from 'vue';

import { CasinoRouteName } from '@leon-hub/routing-config-names/src/CasinoRouteName';
import { RouteName } from '@leon-hub/routing-config-names';

import type { BalanceMode } from 'web/src/modules/bonuses/store/enums';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useBonusStore } from 'web/src/modules/bonuses/store';

import type { BalanceModalEmits } from '../types';

export interface BalanceModalComposable {
  listItemAccordionTitle: Ref<string>;
  balanceMode: Ref<BalanceMode.Single | BalanceMode.Multiple>;
  onClick: () => void;
  emitClose: () => void;
}

export default function useBalanceModal(emit: BalanceModalEmits): BalanceModalComposable {
  const bonusStore = useBonusStore();
  const router = useRouter();
  const { $translate } = useI18n();

  const bonusCbc = toRef(bonusStore, 'bonusCbc');
  const balanceMode = toRef(bonusStore, 'balanceMode');

  function emitClose(): void {
    emit('close');
  }

  function onClick(): void {
    if (process.env.VUE_APP_FEATURE_CBC_ENABLED) {
      if (bonusCbc.value) {
        const { actionUrl, category } = bonusCbc.value;
        if (!actionUrl) {
          void router.push({ name: CasinoRouteName.CASINO_LOBBY });
          return;
        }
        void router.push({
          name: RouteName.PROMOTION_DETAILS,
          params: {
            categoryId: category.toLowerCase(),
            actionUrl,
          },
        });
      } else {
        void router.push({ name: CasinoRouteName.CASINO_LOBBY });
      }
      emitClose();
    }
  }

  const listItemAccordionTitle = computed<string>(() => (
    process.env.VUE_APP_FEATURE_CBC_ENABLED ? $translate('WEB2_WHAT_IS_CBC_BALANCE').value : ''));

  return {
    listItemAccordionTitle,
    balanceMode,
    onClick,
    emitClose,
  };
}
