import type { RouteLocationRaw } from 'vue-router';

export default class RegCompletionRouteParametersHandler {
  private static redirectLocation?: RouteLocationRaw;

  static setRedirectLocation(redirectLocation: RouteLocationRaw | undefined): void {
    RegCompletionRouteParametersHandler.redirectLocation = redirectLocation;
  }

  static getRedirectLocation(): RouteLocationRaw | undefined {
    return RegCompletionRouteParametersHandler.redirectLocation;
  }
}
