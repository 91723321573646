import type { Ref } from 'vue';
import { computed, ref } from 'vue';

import { useTheme } from 'web/src/modules/theme/composables';
import useSafeTimeBus from 'web/src/modules/core/composables/time-bus/useSafeTimeBus';
import { useCountdownDateTimeLabels } from 'web/src/modules/sportline/composables/countdown';
import { CountdownLabelType } from 'web/src/modules/sportline/composables/countdown/enums';
import { ProgressKind } from 'web/src/components/LinearProgress/VLinearProgress/enums';
import { getSafePercentage } from 'web/src/modules/core/utils/math';
import DateTime from 'web/src/utils/DateTime';

interface UseLeagueProgressBarProps {
  currentSeasonStart: Ref<Maybe<number>>;
  currentSeasonEnd: Ref<Maybe<number>>;
  backgroundColor: Ref<Maybe<string>>;
}

interface UseLeagueProgressBarComposable {
  dateStart: Ref<Maybe<string>>;
  dateEnd: Ref<Maybe<string>>;
  isStarted: Ref<boolean>;
  isEnded: Ref<boolean>;
  startsInLabel: Ref<string>;
  progressBarPercentage: Ref<number>;
  progressBarKind: Ref<ProgressKind>;
}

export default function useLeagueProgressBar(
  props: UseLeagueProgressBarProps,
): UseLeagueProgressBarComposable {
  const {
    currentSeasonStart,
    currentSeasonEnd,
    backgroundColor,
  } = props;

  const { isLight } = useTheme();
  const { now } = useSafeTimeBus(60_000);

  const dateStartTimestamp = computed<number>(() => currentSeasonStart.value || 0);
  const dateEndTimestamp = computed<number>(() => currentSeasonEnd.value || 0);
  const dateStart = computed<Maybe<string>>(() => {
    if (!dateStartTimestamp.value) { return null; }
    return (new DateTime(dateStartTimestamp.value)).toFullDate();
  });
  const dateEnd = computed<Maybe<string>>(() => {
    if (!dateEndTimestamp.value) { return null; }
    return (new DateTime(dateEndTimestamp.value)).toFullDate();
  });
  const isStarted = computed<boolean>(() => !!dateStartTimestamp.value
    && now.value >= dateStartTimestamp.value);
  const isEnded = computed<boolean>(() => !!dateEndTimestamp.value
    && now.value >= dateEndTimestamp.value);
  const progressBarPercentage = computed<number>(() => {
    if (!dateStartTimestamp.value || !dateEndTimestamp.value) { return 0; }
    return Math.trunc(getSafePercentage(now.value, dateEndTimestamp.value, dateStartTimestamp.value));
  });
  const {
    startsInLabel,
  } = useCountdownDateTimeLabels({
    fromTimestamp: now,
    toTimestamp: dateStartTimestamp,
    displaySecondsOnMaxMinutes: ref(0),
    daysLabelType: ref(CountdownLabelType.ExtraShort),
    hoursLabelType: ref(CountdownLabelType.ExtraShort),
    minutesLabelType: ref(CountdownLabelType.ExtraShort),
  });
  const progressBarKind = computed<ProgressKind>(() => {
    if (backgroundColor.value) {
      return ProgressKind.WHITE;
    }

    return isLight.value
      ? ProgressKind.BLACK
      : ProgressKind.WHITE;
  });

  return {
    dateStart,
    dateEnd,
    isStarted,
    isEnded,
    startsInLabel,
    progressBarPercentage,
    progressBarKind,
  };
}
