<script setup lang="ts">
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { JumbotronFooterButton, VJumbotron } from 'web/src/components/Jumbotron';

interface SportNoResultsProps {
  canResetFilter?: boolean;
  sportlineIsEmpty?: boolean;
}

interface SportNoResultsEmits {
  (e: 'reset'): void;
}

defineProps<SportNoResultsProps>();
const emit = defineEmits<SportNoResultsEmits>();
</script>

<template>
  <VJumbotron v-auto-id="'SportNoResults'"
    :heading="$t('WEB2_SPORTLINE_NO_EVENTS')"
    :class="$style['filter-no-results']"
  >
    <template v-if="!sportlineIsEmpty">
      {{ $t('WEB2_SPORTLINE_NO_EVENTS_FILTER') }}
      {{ $t('WEB2_OR') }}
    </template>
    <template #footer>
      <div>
        <JumbotronFooterButton
          v-if="canResetFilter"
          :kind="ButtonKind.BASE"
          :label="$t('WEB2_RESET_FILTERS')"
          full-width
          @click="emit('reset')"
        />
      </div>
    </template>
  </VJumbotron>
</template>

<style module lang="scss">
.filter-no-results {
  max-width: 375px;
  padding: 32px;
}
</style>
