import type { Ref } from 'vue';
import {
  computed,
  toRef,
} from 'vue';

import type { NavigationItem } from '@leon-hub/navigation-config';

import { useNavigationItemsStore } from 'web/src/modules/core/store';
import { convertNavigationToSidebarMenu } from 'web/src/components/SidebarMenu/utils';
import { useI18n } from 'web/src/modules/i18n/composables';
import type { SidebarMenu } from 'web/src/components/SidebarMenu/types';

export interface UseSidebarMenuKnowHowRouteComponentComposable {
  menuBootstrap: Ref<SidebarMenu>;
  menuGeneric: Ref<SidebarMenu>;
}

export function useSidebarMenuKnowHowRouteComponent(): UseSidebarMenuKnowHowRouteComponentComposable {
  const { $translate } = useI18n();
  const navigationItemsStore = useNavigationItemsStore();
  const bootstrapNavigationItems = toRef(navigationItemsStore, 'bootstrapNavigationItems');
  const genericInfoNavigationItems = toRef(navigationItemsStore, 'genericInfoNavigationItems');

  const menuBootstrap = computed<SidebarMenu>(() => (
    convertNavigationToSidebarMenu([{
      id: '1',
      caption: $translate('WEB2_WHERE_TO_BEGIN').value,
      children: bootstrapNavigationItems.value,
    }], { active: () => true }, {
      location: (navItem: NavigationItem) => ({ name: navItem.routeName }),
      active: (navItem: NavigationItem) => !!navItem?.isActive,
    })
  ));

  const menuGeneric = computed<SidebarMenu>(() => (
    convertNavigationToSidebarMenu([{
      id: '2',
      caption: $translate('WEB2_INFORMATION').value,
      children: process.env.VUE_APP_PRODUCT_LEONRU ? genericInfoNavigationItems.value.filter(
        (item) => !(item.props && item.props.isFooterOnly),
      ) : genericInfoNavigationItems.value,
    }], { active: () => true }, {
      location: (navItem: NavigationItem) => ({ name: navItem.routeName }),
      active: (navItem: NavigationItem) => !!navItem?.isActive,
    })
  ));

  return {
    menuBootstrap,
    menuGeneric,
  };
}
