import { watch } from 'vue';
import { defineStore } from 'pinia';

import { BusEvent, useEventsBus } from '@leon-hub/event-bus';

import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { addFavoriteEventsRequest, removeFavoriteEventRequest } from 'web/src/modules/sportline/utils/api';

import { usePendingToFavoriteEventsStoreComposable } from './composable/usePendingToFavoriteEventsStoreComposable';
import { useFavoritesEventsStoreComposable } from './composable/useFavoritesEventsStoreComposable';
import { useOptimisticFavoritesEvents } from './composable/useOptimisticFavoritesEvents';
import {
  useFavoriteEventsBackgroundUpdatesStoreComposable,
} from './composable/useFavoriteEventsBackgroundUpdatesStoreComposable';

export const useFavoritesEventsStore = defineStore('sportline-favorite-events', () => {
  const { isLoggedIn } = useIsLoggedIn();
  const bus = useEventsBus();

  const {
    pendingToFavoritesList,
    preparedToAdd,
    preparedToRemove,
    setPrepareToAddState,
    setPrepareToRemoveState,
    setPreparedState,
    addPendingToFavoritesList,
    clearPendingToFavoritesList,
  } = usePendingToFavoriteEventsStoreComposable();
  const {
    favoritesIdsList,
    rawFavoriteEventsResponse,
    isFavoritesEventsListLoaded,
    setFavoritesIdsList,
    setRawFavoriteEventsResponse,
  } = useFavoritesEventsStoreComposable();
  const {
    optimisticFavoritesSportEventsIdsList,
  } = useOptimisticFavoritesEvents({
    preparedToAdd,
    preparedToRemove,
    pendingToFavoritesList,
    favoritesIdsList,
  });

  const {
    setIsEventsBackgroundUpdateAvailable,
    setIsBackgroundIdsUpdateAvailable,
    fetchFavoriteEventsIdsList,
  } = useFavoriteEventsBackgroundUpdatesStoreComposable({
    favoritesIdsList,
    setFavoritesIdsList,
    pendingToFavoritesList,
    clearPendingToFavoritesList,
    rawFavoriteEventsResponse,
    setRawFavoriteEventsResponse,
  });

  async function toggleCustomerSportEvent({ id, state }: { id: string; state?: boolean }): Promise<void> {
    if (!isLoggedIn.value) {
      addPendingToFavoritesList(id);
      return;
    }

    if (state) {
      setPrepareToAddState({ id, state: true });
      try {
        await addFavoriteEventsRequest({ eventIds: [parseInt(id, 10)] }, { silent: false });
        await fetchFavoriteEventsIdsList();
      } finally {
        setPrepareToAddState({ id, state: false });
      }
      return;
    }

    setPrepareToRemoveState({ id, state: true });
    try {
      await removeFavoriteEventRequest({ eventId: parseInt(id, 10) }, { silent: false });
      await fetchFavoriteEventsIdsList();
    } finally {
      setPrepareToRemoveState({ id, state: false });
    }
  }

  async function initialRequests(): Promise<void> {
    await fetchFavoriteEventsIdsList();
  }

  // init
  watch(isLoggedIn, (value: boolean) => {
    if (value) {
      void initialRequests();
      return;
    }

    clearPendingToFavoritesList();
    setFavoritesIdsList([]);
    setRawFavoriteEventsResponse(null);
  });
  bus.on(BusEvent.NEW_BET_HAS_BEEN_PLACED, () => {
    void fetchFavoriteEventsIdsList(true);
  });

  return {
    favoritesIdsList,
    isFavoritesEventsListLoaded,
    optimisticFavoritesSportEventsIdsList,
    initialRequests,
    setIsEventsBackgroundUpdateAvailable,
    setIsBackgroundIdsUpdateAvailable,
    fetchFavoriteEventsIdsList,
    toggleCustomerSportEvent,
    // seems it must be internal field
    rawFavoriteEventsResponse,
    // internal
    setRawFavoriteEventsResponse,
    setFavoritesIdsList,
    pendingToFavoritesList,
    preparedToAdd,
    preparedToRemove,
    setPrepareToAddState,
    setPrepareToRemoveState,
    setPreparedState,
    addPendingToFavoritesList,
    clearPendingToFavoritesList,
  };
});
