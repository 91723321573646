<script lang="ts" setup>
import {
  ref,
} from 'vue';

import { useTheHeader } from './composables';

const content = ref<HTMLDivElement>();
const { headerStyles, isCalculated } = useTheHeader(content);
</script>

<template>
  <header v-auto-id="'TheHeader'"
    :class="$style['header']"
    role="banner"
    :style="headerStyles"
  >
    <div
      ref="content"
      :class="{
        [$style['header__content']]: true,
        [$style['header__content--calculated']]: isCalculated,
      }"
    >
      <slot />
    </div>
  </header>
</template>

<style lang="scss" module>
.header {
  &__content {
    position: relative;
    top: 0;
    left: 0;
    z-index: get($zIndex, mobile-service-bars);
    order: 1;
    width: 100%;
    background-color: var(--Layer1);
    will-change: transform;

    &--calculated {
      @include is-app-layout-phone {
        position: fixed;
      }
    }

    @include is-app-layout-desktop {
      z-index: z-index(desktop-header);
      border-bottom-color: transparent;
    }
  }
}
</style>
