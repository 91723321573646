export enum SlottSportIconName {
  SLOTT_SPORT_BASKETBALL = 'slott-sport-basketball',
  SLOTT_SPORT_BIKING_MOUNTAIN = 'slott-sport-biking-mountain',
  SLOTT_SPORT_BOXING = 'slott-sport-boxing',
  SLOTT_SPORT_FOOTBALL = 'slott-sport-football',
  SLOTT_SPORT_RUGBY = 'slott-sport-rugby',
  SLOTT_SPORT_SHUTTLECOCK = 'slott-sport-shuttlecock',
  SLOTT_SPORT_SOCCER = 'slott-sport-soccer',
  SLOTT_SPORT_SWIMMING = 'slott-sport-swimming',
  SLOTT_SPORT_TENNIS = 'slott-sport-tennis',
  SLOTT_SPORT_VOLLEYBALL = 'slott-sport-volleyball',
}
