<script lang="ts" setup>
import {
  defineAsyncComponent,
} from 'vue';

import HeaderBarRouteComponent from 'web/src/modules/core/views/HeaderBarRouteComponent/HeaderBarRouteComponent.vue';
import FooterRouteComponent from 'web/src/modules/core/views/FooterRouteComponent/FooterRouteComponent.vue';
import { LazyMainBannerSectionRouteComponent } from 'web/src/modules/banners/components/MainBannerSectionRouteComponent';
import DesktopModal from 'web/src/modules/core/components/DesktopModal/DesktopModal.vue';
import vDataTestUnit from 'web/src/testing/directives/vDataTestUnit';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import TopBanners from 'web/src/modules/banners/components/TopBanners/TopBanners.vue';
import SnackbarHolder from 'web/src/modules/snackbars/components/SnackbarHolder/SnackbarHolder.vue';

import TheHeader from '../TheHeader/TheHeader.vue';
import {
  useKeepAliveComponents,
  usePostponedContentLoad,
} from '../composables';
import {
  useLayoutDesktopDefault,
} from './composables';

const TheBetSlip = defineAsyncComponent(() => import('web/src/modules/slip/views/TheBetsSlipRootView.vue'));
// eslint-disable-next-line max-len
const AuthorizedSnackbars = defineAsyncComponent(() => import('web/src/modules/core/components/AuthorizedSnackbars/AuthorizedSnackbars.vue'));

const LayoutDesktopSidebar = defineAsyncComponent(() => (
  null
    ? import('web/src/modules/core/components/LayoutDesktopSLeftSidebar/LayoutDesktopSLeftSidebar.vue')
    : import('web/src/modules/core/components/LayoutDesktopLLeftSidebar/LayoutDesktopLLeftSidebar.vue')
));

const {
  isLoggedIn,
} = useIsLoggedIn();
const { keepAliveIncludes } = useKeepAliveComponents();

const {
  content,
  hasFixedBar,
  hasLeftSideBar,
  hasRightSideBar,
  isFullWidth,
  isStoryBanner,
  isEgsLobby,
  isFooterVisible,
  isBannerVisible,
} = useLayoutDesktopDefault();

const {
  isMainContentLoaded,
  loadMainContent,
} = usePostponedContentLoad();
</script>

<template>
  <TheHeader>
    <HeaderBarRouteComponent />
  </TheHeader>
  <section :class="$style['content-wrap']">
    <div :class="$style['column']">
      <LayoutDesktopSidebar
        v-if="isMainContentLoaded && hasLeftSideBar"
        v-data-test-unit="{ el: 'left-sidebar' }"
      />
      <main :class="$style['column__main']">
        <div
          v-if="hasFixedBar"
          v-data-test-unit="{ el: 'fixed-bar' }"
          :class="$style['fixed-bar']"
        >
          <div :class="$style['fixed-bar__content']">
            <router-view name="fixedBar" />
          </div>
        </div>
        <div
          id="js__content-scroll"
          ref="content"
          :class="$style['content']"
          @vue:mounted="loadMainContent"
        >
          <router-view name="contentLoader" />
          <router-view
            v-if="isLoggedIn"
            name="topAuthorizedSlot"
          />
          <div
            v-if="isBannerVisible"
            :class="{
              [$style['content-min-max']]: isFullWidth,
            }"
          >
            <TopBanners
              v-if="null"
            />
            <LazyMainBannerSectionRouteComponent
              v-else
              :is-story-banner="isStoryBanner"
              :is-big-height="isEgsLobby"
            />
          </div>
          <router-view name="navigation" />
          <router-view name="title" />
          <div
            v-data-test="{ el: 'component-wrapper-height-holder'}"
            :class="[
              $style['main-component'],
              'popper-content-target'
            ]"
          >
            <template v-if="$route.meta.nuxt">
              <NuxtPage />
            </template>
            <router-view
              v-else
              v-slot="{ Component }"
            >
              <KeepAlive :include="keepAliveIncludes">
                <component :is="Component" />
              </KeepAlive>
              <FooterRouteComponent
                v-if="isFooterVisible"
                :is-full-width="!hasLeftSideBar"
                :class="$style['footer']"
              />
            </router-view>
          </div>
        </div>
      </main>
      <aside
        v-if="'1' && hasRightSideBar && isMainContentLoaded"
        v-data-test-unit="{el: 'right-sidebar'}"
        aria-label="right-sidebar"
        :class="$style['column__right']"
      >
        <TheBetSlip />
      </aside>
    </div>
  </section>
  <AuthorizedSnackbars
    v-if="isLoggedIn"
    show-layout
  />
  <SnackbarHolder />
  <Teleport to="body">
    <DesktopModal />
  </Teleport>
</template>

<style lang="scss" module>
.content-wrap {
  order: 2;
  width: 100%;
  min-width: $layoutDesktopMinWidth;
  max-width: $layoutDesktopMaxWidth;
  height: 100%;
  margin: 0 auto;
  overflow: hidden;

  .app__content--full-width & {
    min-width: $layoutDesktopMinWidthFullWidth;
    max-width: $layoutDesktopMaxWidthFullWidth;
  }

  @include is-app-layout-tablet {
    min-width: $tabletMin;
  }
}

.fixed-bar {
  @include z-index(fixed-bar);

  position: relative;

  &__content {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
  }
}

.column {
  display: flex;
  flex: 1;
  height: 100%;

  &__right {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    order: 3;
    width: $desktopRightSidebarWidth + $scrollWidth;
    overflow: hidden auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__close-button {
    @include is-app-layout-desktop {
      @if $isEnvFeatureTwinlikeStyle {
        border-radius: 5px;

        &:hover:not([disabled]) {
          color: var(--Layer1);
          background-color: var(--TextDefault);
        }
      }
    }
  }

  // DO NOT add padding here
  &__main {
    flex: 1;
    order: 2;
    overflow: hidden hidden;
  }
}

.content {
  @include customScrollbar;
  @include z-index(base);

  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: $layoutDesktopSizeBetweenContentAndHeaderDesktopSmall;
  padding-right: $layoutDesktopContentPaddingRightSmall;
  padding-left: $layoutDesktopContentPaddingLeftSmall;
  overflow: hidden scroll;
  background-color: var(--BodyBackgroundColor);
  scrollbar-width: thin;

  @include screen-desktop-large-min {
    padding-top: $layoutDesktopSizeBetweenContentAndHeaderDesktopBig;
    padding-right: $layoutDesktopContentPaddingRightBig;
    padding-left: $layoutDesktopContentPaddingLeftBig;
  }
}

/** win11 thin scrollbar issue LEONWEB-8913 */
html.app__browser--firefox.app__os--windows {
  .content {
    padding-right: 4px;
    margin-right: 4px;
  }
}

.main-component {
  position: relative;
}

.main-component > :global(.component-wrapper) {
  min-height: 100vh;
}

.content-min-max {
  @include content-min-max;
}

.footer {
  margin-top: $layoutDesktopFooterMarginTopSmall;

  @include screen-desktop-large-min {
    margin-top: $layoutDesktopFooterMarginTopBig;
  }
}

@include for-os-windows {
  .column {
    &__main {
      overflow-y: auto;
    }
  }

  .content {
    padding-right: 0;
    scrollbar-color: var(--Highlight) var(--Layer0);

    &::-webkit-scrollbar {
      width: $scrollWidth;
    }

    &::-webkit-scrollbar-track {
      background-color: var(--Layer0);
    }

    &::-webkit-scrollbar-thumb {
      min-height: 30px;
      background-color: var(--Highlight);
      border: 4px solid var(--Layer0);
      border-radius: 8px;
    }
  }
}

@include for-fullscreen {
  .content {
    z-index: auto;
    will-change: unset;
  }
}
</style>
