import round from 'lodash/round';
import random from 'lodash/random';

export default function createFakeValue({ amount, stepRange, duration }: {
  amount: number;
  duration: number;
  stepRange: { min: number; max: number };
}): number {
  return round(amount + random(stepRange.min, stepRange.max) * (duration / 1000), 2);
}
