import type { SportDateTimeFilter } from '../types';

export default function isDifferentSportDateTimeFilter(
  oldValue?: Maybe<SportDateTimeFilter>,
  newValue?: Maybe<SportDateTimeFilter>,
): boolean {
  if (!oldValue || !newValue) {
    return oldValue !== newValue;
  }

  return oldValue.activeKickoffPeriod !== newValue.activeKickoffPeriod
    || oldValue.customDateFrom !== newValue.customDateFrom
    || oldValue.customDateTo !== newValue.customDateTo
    || oldValue.sportlineType !== newValue.sportlineType;
}
