<script lang="ts" setup>
import { onBeforeMount, onBeforeUnmount } from 'vue';

import { VLoader } from '@components/loader';

import type { VLoaderDelayedProps } from './types';
import { useVLoaderDelayed } from './composables';

const props = withDefaults(defineProps<VLoaderDelayedProps>(), {});
const {
  loaderIsVisible,
  onAppear,
  onDisappear,
} = useVLoaderDelayed(props);

onBeforeMount(onAppear);
onBeforeUnmount(onDisappear);
</script>

<template>
  <div v-auto-id="'VLoaderMaskedDelayed'"
    v-if="loaderIsVisible"
    :class="$style['loader-masked']"
  >
    <VLoader
      :title="title"
      :hint="hint"
      :size="size"
      :color="color"
      :margin="margin"
    />
  </div>
</template>

<style lang="scss" module>
.loader-masked {
  @include z-index('loader-masked');

  position: absolute;
  top: 1px;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  padding-top: 150px;
  background-color: var(--OpacityLayer0);
}
</style>
