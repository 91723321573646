import type { SportFamilyMapping } from 'web/src/modules/sportline/types/settings';
import type { LeagueRouteLocationParameters } from 'web/src/modules/sportline/submodules/navigation/store/types';
import type {
  CoreRegionResponse,
  CoreLeagueResponse,
} from 'web/src/modules/sportline/types/rest';

import type { MatchSportFamilySource } from './matchSportFamily';
import { matchSportFamily } from './matchSportFamily';

interface BuildNavigationParametersOptions {
  sportResponse: MatchSportFamilySource;
  regionResponse: Pick<CoreRegionResponse, 'url'>;
  leagueResponse: Pick<CoreLeagueResponse, 'id' | 'url'>;
  sportFamilyMapping?: Maybe<SportFamilyMapping>;
}

export function buildLeagueNavigationParameters(
  options: BuildNavigationParametersOptions,
): LeagueRouteLocationParameters {
  const {
    sportFamilyMapping,
    leagueResponse,
    regionResponse,
    sportResponse,
  } = options;

  return {
    id: String(leagueResponse.id),
    urlName: leagueResponse.url,
    regionUrlName: regionResponse.url,
    sportFamily: matchSportFamily(sportResponse, sportFamilyMapping),
  };
}
