import type { AppVueRouter } from 'web/src/modules/core/services/router/types';
import { useThemeStore } from 'web/src/modules/theme/store';

export default function createThemeNavigationGuard(router: AppVueRouter): void {
  const { setCustomTheme } = useThemeStore();

  router.beforeEach((to) => {
    if (process.env.VUE_APP_LAYOUT_PHONE || !to.meta.modalPreset) {
      setCustomTheme(to.meta.theme);
    }
  });
}
