<script lang="ts" setup>
import type { PhoneInputProps } from 'web/src/components/Input';
import {
  useFilledEvent,
} from 'web/src/components/Input/composables';
import InternationalPhoneInput from 'web/src/modules/phone/components/InternationalPhoneInput/InternationalPhoneInput.vue';
import PhoneInputInternal from 'web/src/components/Input/components/PhoneInput/PhoneInput.vue';

import { useBaseFormWidgetEvents } from '../../composables';
import type {
  FormWrapperWidgetProperties,
  FormEmitsWithFilled,
} from '../../types';
import useFormPhone from './useFormPhone';

const PhoneInput = '1'
  ? InternationalPhoneInput
  : PhoneInputInternal;

// eslint-disable-next-line vue/define-macros-order
const props = defineProps<FormWrapperWidgetProperties<PhoneInputProps>>();

const emit = defineEmits<FormEmitsWithFilled>();

const {
  emitChange,
  emitInput,
  emitFocus,
  emitBlur,
} = useBaseFormWidgetEvents(emit);
const {
  emitFilled,
} = useFilledEvent(emit);

const { componentProps } = useFormPhone(props);
</script>

<template>
  <PhoneInput v-auto-id="'FormPhoneInput'"
    v-bind="componentProps"
    @input="emitInput"
    @change="emitChange"
    @focus="emitFocus"
    @blur="emitBlur"
    @filled="emitFilled"
  />
</template>
