import type { Ref } from 'vue';
import {
  onMounted,
  ref,
  watch,
} from 'vue';

import type { VAmountAnimatorProps } from 'web/src/components/AmountAnimator/types';
import { AmountAnimator, createFakeValue } from 'web/src/components/AmountAnimator/VAmountAnimator/utils';

interface useVAmountAnimatorComposable {
  animatedValue: Ref<number>;
}

export default function useVAmountAnimator(
  props: RequiredField<VAmountAnimatorProps, 'stepRange' | 'duration' | 'timeRange'>,
): useVAmountAnimatorComposable {
  const animatedValue = ref(0);
  let amountAnimator: AmountAnimator | null = null;

  function onValueChange(): void {
    animatedValue.value = props.amount;
    runAnimation(animatedValue.value, animatedValue.value);
  }

  function watchAnimationBehaviour(to: boolean | undefined): void {
    if (amountAnimator) {
      if (to) {
        amountAnimator.stop();
      } else {
        amountAnimator.start();
      }
    }
  }

  function runAnimation(newAmount: number, amount: number) {
    const {
      duration,
      timeRange,
      stepRange,
      isInfinite,
    } = props;

    if (newAmount === 0) {
      return;
    }

    if (amountAnimator) {
      amountAnimator.stop();
    }

    const isFakeAnimation = newAmount <= amount;

    amountAnimator = new AmountAnimator({
      duration,
      timeRange,
      callback: (value, isFinished) => {
        animatedValue.value = value;
        if (isFinished && isInfinite) {
          runAnimation(
            newAmount,
            createFakeValue({ duration, stepRange, amount: newAmount }),
          );
        }
      },
      values: {
        from: isFakeAnimation ? newAmount : amount,
        to: isFakeAnimation ? createFakeValue({ duration, stepRange, amount: newAmount }) : newAmount,
      },
    });

    if (!props.isHidden) {
      amountAnimator.start();
    }
  }

  watch(() => props.amount, onValueChange);
  watch(() => props.isHidden, watchAnimationBehaviour);
  onMounted(onValueChange);

  return {
    animatedValue,
  };
}
