import {
  isNull,
  isNumber,
  isString,
  isUndefined,
  isValidInterface,
} from '@leon-hub/guards';
import type { FieldGuards } from '@leon-hub/types';

import type { CoreRegionResponse } from 'web/src/modules/sportline/types/rest';

export const isCoreRegionResponseFieldGuards: FieldGuards<CoreRegionResponse> = {
  id: isNumber,
  name: isString,
  nameDefault: isString,
  url: isString,
  family: [isUndefined, isNull, isString],
};

export function isCoreRegionResponse(value: unknown): value is CoreRegionResponse {
  return isValidInterface<CoreRegionResponse>(value, isCoreRegionResponseFieldGuards);
}
