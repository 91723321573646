import { CustomInputEventType } from 'web/src/components/Input/enums';

interface UseFilledEvent {
  emitFilled: () => void;
}

const useFilledEvent = (emit: {
  (e: CustomInputEventType.FILLED): void;
}): UseFilledEvent => {
  const emitFilled = () => emit(CustomInputEventType.FILLED);
  return {
    emitFilled,
  };
};

export default useFilledEvent;
