<script lang="ts" setup>
import type {
  CasinoLoyaltyProgramListProperties,
  CasinoLoyaltyProgramProgressProperties,
} from 'web/src/modules/casino-loyalty-program/store/types';
import { CircleColor } from 'web/src/components/CircularProgress/VCircularProgress/enums';
import CasinoLoyaltyProgramProgress from 'web/src/modules/casino-loyalty-program/components/CasinoLoyaltyProgramProgress/CasinoLoyaltyProgramProgress.vue';
import CasinoLoyaltyProgramList from 'web/src/modules/casino-loyalty-program/components/CasinoLoyaltyProgramList/CasinoLoyaltyProgramList.vue';

interface CasinoLoyaltyProgramNextLevelInfoProps {
  levelTitle?: string;
  progress?: CasinoLoyaltyProgramProgressProperties;
  currentPoints?: string;
  remainingPoints?: string;
  levelDataList?: CasinoLoyaltyProgramListProperties;
}

withDefaults(defineProps<CasinoLoyaltyProgramNextLevelInfoProps>(), {
  levelTitle: '',
});

const circleColor = CircleColor.PURPLE;
const bemClass = 'casino_loyalty_program_next_level_info';
</script>

<template>
  <div v-auto-id="'CasinoLoyaltyProgramNextLevelInfo'" :class="$style[bemClass]">
    <div :class="$style[`${bemClass}__title`]">
      {{ $t('WEB2_LOYALTY_PROGRAM_NEXT_LEVEL') }}
    </div>
    <div :class="$style[`${bemClass}__wrapper`]">
      <div :class="$style[`${bemClass}__content`]">
        <div :class="$style[`${bemClass}__left`]">
          <CasinoLoyaltyProgramProgress
            v-bind="progress"
            :is-gradient="false"
            :circle-color="circleColor"
          />
          <div :class="$style[`${bemClass}__level-title`]">
            {{ levelTitle }}
          </div>
        </div>
        <div :class="$style[`${bemClass}__right`]">
          <span :class="$style[`${bemClass}__current`]"> {{ currentPoints }}</span>
          /
          <span>{{ remainingPoints }}</span>
        </div>
      </div>
      <CasinoLoyaltyProgramList
        v-bind="levelDataList"
        is-dark
      />
    </div>
  </div>
</template>

<style lang="scss" module>
.casino_loyalty_program_next_level_info {
  &__title {
    @include medium\18\20;

    padding: 18px 8px;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 8px;
    background-color: var(--Layer1);
    border-radius: 5px;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__left {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  &__level-title {
    @include medium\18\24;

    color: var(--TextDefault);
  }

  &__right {
    @include medium\14\20\025;

    color: var(--TextDefault);
  }
}
</style>
