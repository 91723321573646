import type {
  Sport,
} from 'web/src/modules/sportline/types';
import type {
  RawSport,
  BuildSportOptions,
  CoreSportResponse,
} from 'web/src/modules/sportline/types/rest';

import { createMarketColumns } from './createMarketColumns';
import createSport from './createSport';

export function parseSportResponse(
  response: CoreSportResponse,
  buildSportOptions: BuildSportOptions,
): RawSport {
  const sport: Sport = createSport({
    ...buildSportOptions,
    sport: response,
  });
  const marketsColumns = createMarketColumns({
    ...buildSportOptions,
    sport: response,
  });

  return {
    sport,
    marketsColumns,
    leagues: {},
    outrightEvents: [],
  };
}
