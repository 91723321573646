<script lang="ts" setup>
import type { VScrollbarRef, VScrollbarProps, VScrollbarEmits } from '../types';
import { useVScrollbarPhone } from './composables';

const props = defineProps<VScrollbarProps>();
const emit = defineEmits<VScrollbarEmits>();

const {
  testAttrs,
  element,
  scrollTo,
  scrollTop,
  scrollDown,
  scrollRight,
  scrollToElement,
} = useVScrollbarPhone(props, emit);

defineExpose<Omit<VScrollbarRef, 'scrollUpdate'>>({
  scrollTo,
  scrollTop,
  scrollDown,
  scrollRight,
  scrollToElement,
  recompose: () => Promise.resolve(),
});
</script>

<template>
  <div v-auto-id="'VScrollbarPhone'"
    ref="element"
    v-data-test="testAttrs"
    :class="{
      [$style['scrollbar-phone']]: true,
      [$style['scrollbar-phone--flex-fill']]: flexFill,
      [$style['scrollbar-phone--dropdown']]: isDropdown,
    }"
  >
    <slot />
  </div>
</template>

<style lang="scss" module>
.scrollbar-phone {
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  &--flex-fill {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  &--dropdown {
    max-height: 296px;
  }
}
</style>
