import type { SportFamily } from 'web/src/modules/sportline/enums';

import isSportFamilyEquals from './isSportFamilyEquals';

export default function isSportFamiliesIncludes(
  sportFamilies: Array<Maybe<string | SportFamily>>,
  sportFamily?: Maybe<string | SportFamily>,
): boolean {
  return !!sportFamily && sportFamilies.some((family) => isSportFamilyEquals(family, sportFamily));
}
