import { MarketStatus } from '@leon-hub/api-sdk';

import formatOdd from 'web/src/utils/formatOdd';

import { entryIsTotalHandicap } from './entryIsTotalHandicap';
import { SlipListBettingItemStatus } from '../../../components/SlipListItem/enums';
import type { ExtendedSlipListBettingItem } from '../../../components/SlipListItem/types';
import type {
  CombinedSlipEntryInfo,
  MapExtendedSlipListBettingItemOptions,
} from '../types';
import isUnavailableEntryStatus from '../../../utils/isUnavailableEntryStatus';
import { getSafeCompetitorsValue } from './getSafeCompetitorsValue';

interface GetOriginalOddsStringPayload {
  marketStatus: MarketStatus | null;
  originalValue: number;
  priceChangedMatters?: boolean;
  formattedValue?: string;
}

const getOriginalOddsString = ({
  originalValue,
  priceChangedMatters,
  formattedValue,
  marketStatus,
}: GetOriginalOddsStringPayload): string => {
  if (isUnavailableEntryStatus(marketStatus) || !originalValue || !priceChangedMatters) {
    return '';
  }
  return formattedValue ?? formatOdd(originalValue);
};

const getSlipListBettingItemStatus = (marketStatus?: MarketStatus | null): SlipListBettingItemStatus => {
  switch (marketStatus) {
    case MarketStatus.LIMIT:
      return SlipListBettingItemStatus.LIMIT;
    case MarketStatus.CLOSED:
    case MarketStatus.MISSING:
      return SlipListBettingItemStatus.BLOCKED;
    case MarketStatus.SUSPENDED:
      return SlipListBettingItemStatus.PAUSED;
    default:
      return SlipListBettingItemStatus.DEFAULT;
  }
};

const getMultiSingleMaxAvailableBet = ({
  restOfBalance, stakeValue, maxStake,
}: {
  restOfBalance: number;
  stakeValue: number;
  maxStake?: number | null;
}): number => {
  const maxViaBalance = restOfBalance + stakeValue;
  if (restOfBalance < 0) {
    return 0;
  }
  if (restOfBalance === 0) {
    return stakeValue;
  }
  if (maxStake && maxViaBalance > maxStake) {
    return maxStake;
  }
  return maxViaBalance;
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export default function mapCombinedSlipEntryToExtendedSlipListBettingItemProps(
  {
    id,
    competitors,
    eventName,
    odds,
    oddsStr,
    oddChangeVariant,
    zeroMargin,
    marketName,
    runnerName,
    banker,
    marketStatus,
    metaInfo,
    originalOdds,
    originalOddsStr,
    matchedPriceChangePolicy,
    oldRunnerName,
    replacedAt,
    marketTypeTag,
  }: CombinedSlipEntryInfo,
  {
    isZeroMarginEnabled,
    disableBankers,
    isLive,
    errorMessage,
    restOfBalance,
    showAcceptButton,
    autoAcceptTotalHandicapChanges,
  }: MapExtendedSlipListBettingItemOptions = {
    disableBankers: false,
    isLive: false,
    isZeroMarginEnabled: false,
    autoAcceptTotalHandicapChanges: false,
  },
): ExtendedSlipListBettingItem {
  const { minStake, maxStake, stakeValue } = metaInfo || {};
  return {
    id,
    competitors: getSafeCompetitorsValue(competitors),
    eventName: eventName ?? undefined,
    zeroMargin: zeroMargin && isZeroMarginEnabled,
    odd: oddsStr ?? formatOdd(odds),
    oddChangeVariant,
    marketName: marketName ?? '',
    runnerName: runnerName ?? '',
    bankerActive: !!banker,
    bankerDisabled: !banker && disableBankers,
    status: getSlipListBettingItemStatus(marketStatus),
    isLive,
    stakeValue: `${stakeValue || ''}`,
    errorMessage,
    minStake: minStake || 0,
    maxStake: maxStake || undefined,
    maxAvailableBet: getMultiSingleMaxAvailableBet({
      restOfBalance: restOfBalance || 0,
      stakeValue: Number(stakeValue || 0),
      maxStake,
    }),
    needToAcceptChanges: showAcceptButton,
    originalOdd: getOriginalOddsString({
      marketStatus: marketStatus ?? null,
      originalValue: originalOdds ?? 0,
      priceChangedMatters: !matchedPriceChangePolicy,
      formattedValue: originalOddsStr,
    }),
    isTotalHandicap: entryIsTotalHandicap({ marketTypeTag }),
    oldRunnerName: autoAcceptTotalHandicapChanges ? undefined : oldRunnerName,
    replacedAt: autoAcceptTotalHandicapChanges ? replacedAt : undefined,
  };
}
