<script lang="ts" setup>
import { toRef } from 'vue';

import { ProgressHeight } from 'web/src/components/LinearProgress/VLinearProgress/enums';
import { TextSlotPlaceholder } from 'web/src/components/TextSlotPlaceholder';
import VLinearProgress from 'web/src/components/LinearProgress/VLinearProgress/VLinearProgress.vue';

import useLeagueProgressBar from './useLeagueProgressBar';

interface LeagueProgressBarProps {
  currentSeasonStart?: Maybe<number>;
  currentSeasonEnd?: Maybe<number>;
  backgroundColor?: Maybe<string>;
}

const props = defineProps<LeagueProgressBarProps>();

const currentSeasonStart = toRef(props, 'currentSeasonStart', null);
const currentSeasonEnd = toRef(props, 'currentSeasonEnd', null);
const backgroundColor = toRef(props, 'backgroundColor', null);

const {
  isStarted,
  isEnded,
  dateStart,
  dateEnd,
  startsInLabel,
  progressBarPercentage,
  progressBarKind,
} = useLeagueProgressBar({ currentSeasonStart, currentSeasonEnd, backgroundColor });
</script>

<template>
  <div v-auto-id="'LeagueProgressBar'"
    :class="{
      [$style['league-progress-bar']]: true,
      [$style['league-progress-bar--default']]: !backgroundColor,
      [$style['league-progress-bar--colored']]: !!backgroundColor,
    }"
  >
    <div :class="$style['league-progress-bar__time-label-wrapper']">
      <div
        :style="{ 'flex-basis': `${progressBarPercentage}%` }"
        :class="$style['league-progress-bar__time-label-completed']"
      >
        <span
          v-if="isEnded"
          :class="$style['league-progress-bar__time-label']"
        >{{ $t('WEB2_LEAGUE_IS_OVER') }}</span>
        <span
          v-else-if="isStarted"
          :class="$style['league-progress-bar__time-label']"
        >{{ progressBarPercentage }}%</span>
        <TextSlotPlaceholder
          v-else
          :text="$t('WEB2_LEAGUE_WILL_COME_IN')"
          :class="$style['league-progress-bar__time-label-extra-info']"
        >
          <template #time>
            <span :class="$style['league-progress-bar__time-label']">{{ startsInLabel }}</span>
          </template>
        </TextSlotPlaceholder>
      </div>
      <div :style="{ 'flex-basis': `${100 - progressBarPercentage}%` }">
        &nbsp;
      </div>
    </div>

    <div :class="$style['league-progress-bar__bar']">
      <VLinearProgress
        :value="progressBarPercentage"
        :height="ProgressHeight.TINY_EXTRA"
        :kind="progressBarKind"
        :class="$style['league-progress-bar__filling']"
      />
    </div>

    <div :class="$style['league-progress-bar__dates']">
      <div>
        {{ dateStart }}
      </div>
      <div>
        {{ dateEnd }}
      </div>
    </div>
  </div>
</template>

<style module lang="scss">
.league-progress-bar {
  &--default {
    color: var(--BasedOnSportTextDefault);
  }

  &--colored {
    color: var(--BasedOnSportTextColored);
  }

  &__time-label-wrapper {
    @include regular\12\16;

    display: flex;
    white-space: nowrap;
  }

  &__time-label-completed {
    text-align: right;
  }

  &__time-label {
    @include medium\12\16;
  }

  &__time-label-extra-info {
    @include regular\12\16;
  }

  &__bar {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
    padding: 2px 0 4px;
  }

  &__filling {
    position: relative;
    width: 100%;
    height: 2px;
  }

  &__dates {
    @include regular\12\16;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
