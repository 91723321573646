import type { Ref, ComputedRef } from 'vue';
import { ref, computed, onMounted } from 'vue';

export interface ReferralProgramSelectBonusComposable {
  toggleOpen: () => void;
  blockHeight: Ref<number>;
  isOpen: Ref<boolean>;
  blockRef: Ref<HTMLDivElement | undefined>;
  blockStyle: ComputedRef<{ height: string | number }>;
}

export default function useReferralProgramSelectBonus(): ReferralProgramSelectBonusComposable {
  const isOpen = ref<boolean>(false);
  const blockRef = ref<HTMLDivElement>();
  const blockHeight = ref<number>(0);

  function calculateBlockHeight() {
    if (blockRef.value) {
      blockHeight.value = blockRef.value.clientHeight || 0;
    }
  }

  onMounted(calculateBlockHeight);

  function toggleOpen() {
    if (blockRef.value) {
      blockHeight.value = blockRef.value?.clientHeight || 0;
    }
    isOpen.value = !isOpen.value;
  }

  const blockStyle = computed(() => ({
    height: isOpen.value ? `${blockHeight.value}px` : 0,
  }));

  return {
    toggleOpen,
    isOpen,
    blockRef,
    blockHeight,
    blockStyle,
  };
}
