<script lang="ts" setup>
import {
  IconName,
  IconSize,
} from '@leon-hub/icons';

import { VIcon } from '@components/v-icon';

interface VStatusProps {
  text: string;
  isSuccess?: boolean;
  isLowerCase?: boolean;
  isIcon?: boolean;
}

interface VStatusEmits {
  (e: 'click', event: MouseEvent): void;
}

withDefaults(defineProps<VStatusProps>(), {});
const emit = defineEmits<VStatusEmits>();

function onClick(event: MouseEvent): void {
  emit('click', event);
}
</script>

<template>
  <div v-auto-id="'VStatus'"
    :class="{
      [$style['status']]: true,
      [$style['status--success']]: isSuccess,
      [$style['status--lowercase']]: isLowerCase,
    }"
    @click="onClick"
  >
    <VIcon
      v-if="isIcon"
      :name="IconName.CHECK_FILLED"
      :size="IconSize.SIZE_16"
      :class="$style['status__icon']"
    />
    <span :class="$style['status__text']">
      {{ text }}
    </span>
  </div>
</template>

<style lang="scss" module>
.status {
  @include statusStyles;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 40px;
  padding: 14px 16px;
  text-align: center;
  border-radius: $statusRadius;

  &--success {
    @include statusStylesSuccess;
  }

  &--lowercase {
    @include statusStylesLowerCase;

    text-transform: none;
  }

  &__icon {
    flex-shrink: 0;
    margin-right: 4px;
    fill: currentcolor;
  }
}
</style>
