import type {
  Region,
} from 'web/src/modules/sportline/types';

export function isRegionChanged(oldValue?: Region | null, newValue?: Region | null): boolean {
  if (!oldValue || !newValue) {
    return true;
  }

  return oldValue.id !== newValue.id
    || oldValue.name !== newValue.name
    || oldValue.regionFamily !== newValue.regionFamily;
}
