<script lang="ts" setup>
import {
  onBeforeMount,
  provide,
  reactive,
} from 'vue';

import VDynamicContent from 'web/src/components/DynamicContent/VDynamicContent/VDynamicContent.vue';

import type { PaymentsContentProps, PaymentsContentEmits } from './types';

const props = defineProps<PaymentsContentProps>();
const emit = defineEmits<PaymentsContentEmits>();

const cmsData = reactive<Record<string, unknown>>({
  payId: '',
});

provide('cmsData', cmsData);

onBeforeMount(() => {
  if (props.htmlContentProperties) {
    for (const { key, value } of props.htmlContentProperties) {
      if (key) {
        cmsData[key] = value;
      }
    }
  }
});
</script>

<template>
  <div v-auto-id="'PaymentsContent'" :class="$style['payments-content']">
    <VDynamicContent
      :content="htmlContent"
      @close="emit('close')"
    />
  </div>
</template>

<style lang="scss" module>
@include for-layout using ($isDesktop) {
  .payments-content {
    text-align: center;

    @if $isDesktop {
      padding: 16px 32px;
    }

    :global(.payments-content__icon) {
      color: var(--BrandDefault);
    }

    :global(.payments-content__title) {
      @include medium\18\28;

      padding: 8px 0;
    }

    :global(.payments-content__title--alt) {
      @include medium\18\28;

      padding: 0 0 20px;
    }

    :global(.payments-content__text) {
      @include regular\14\24\025;
    }

    :global(.payments-content__text--phone) {
      @include medium\24\28;

      color: var(--TextPrimary);
    }

    :global(.payments-content__text--pre-qr) {
      margin: 20px 0 36px;
      color: var(--TextDefault);
    }

    :global(.payments-content__text--pre-wallet) {
      color: var(--TextPrimary);
    }

    :global(.payments-content--without-paddings) {
      @if $isDesktop {
        margin-top: -38px;
      }
    }

    :global(.payments-content__qr-border) {
      position: relative;
      width: 180px;
      height: 180px;
      padding: 12px;
      margin: 0 auto 56px;
      border: 1px solid var(--TextSecondary);
      border-radius: 5px;
    }

    :global(.payments-content__qr-border:after) {
      position: absolute;
      top: -10px;
      left: 48px;
      display: block;
      width: 84px;
      height: 200px;
      content: '';
      background-color: var(--Layer0);
    }

    :global(.payments-content__qr-border:before) {
      position: absolute;
      top: 48px;
      left: -10px;
      display: block;
      width: 200px;
      height: 84px;
      content: '';
      background-color: var(--Layer0);
    }

    :global(.payments-content__qr-block) {
      display: flex;
      overflow: hidden;
      border-radius: 5px;
    }

    :global(.payments-content__qr-block img) {
      z-index: 2;
      width: 156px;
      height: 156px;
    }

    :global(.payments-content__network),
    :global(.payments-content__currency) {
      @include regular\14\24\025;

      color: var(--TextPrimary);
    }

    :global(.payments-content__payid) {
      @include medium\16\20\025;

      padding: 18px 0;
      word-break: break-word;
    }

    :global(.payments-content__button) {
      margin-bottom: 20px;
    }

    :global(.payments-content__amount) {
      @include medium\14\24\025;

      color: var(--TextDefault);
    }

    :global(.payments-content__wallet),
    :global(.payments-content__card) {
      @include medium\18\24;

      margin: 20px 0;
      color: var(--TextDefault);
    }

    :global(.payments-content__wallet) {
      word-break: break-all;
    }

    :global(.payments-content__hint) {
      text-align: left;
    }

    :global(.payments-content__hint--text) {
      @include regular\13\16;

      margin: 20px 0;
      color: var(--TextPrimary);
    }

    :global(.payments-content-copy) {
      display: flex;
      flex-direction: column;
      gap: 2px;
      padding-bottom: 14px;
      text-align: left;
    }

    :global(.payments-content-copy__title) {
      @include inputLabelFont;

      color: var(--TextDefault);
    }

    :global(.payments-content-copy__content) {
      display: flex;
      gap: 8px;
      align-items: center;
      justify-content: space-between;
      padding: 0 0 0 12px;
      background-color: var(--Layer1);
      border-radius: 5px;
    }

    :global(.payments-content-copy__text) {
      @include regular\14\16\025;

      color: var(--TextDefault);
    }

    :global(.payments-content__padding-top-big) {
      padding-top: 160px;
    }
  }
}
</style>
