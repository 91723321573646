import type { SportlineApi } from './createSportlineApi';
import createSportlineApi from './createSportlineApi';

let sportlineApi: SportlineApi | undefined;

export default function useSportlineApi(): SportlineApi {
  if (!sportlineApi) {
    sportlineApi = createSportlineApi();
  }

  return sportlineApi;
}
