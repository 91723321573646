import type { Ref } from 'vue';

import { RequestOptionsPriority } from '@leon-hub/api-sdk';

import useFastTrackApiClient from 'web/src/modules/fast-track/services/api/useFastTrackApiClient';

import type {
  FastTrackBaseEndpointsOptions,
  FastTrackBaseEndpointsResponse,
  FastTrackDeleteMessageOptions,
  FastTrackDeleteMessageResponse,
  FastTrackDoLoginOptions,
  FastTrackDoLoginResponse,
  FastTrackGetMessagesOptions,
  FastTrackGetMessagesResponse,
  FastTrackMarkMessageAsReadOptions,
  FastTrackMarkMessageAsReadResponse,
} from './types';
import {
  isFastTrackBaseEndpointsResponse,
  isFastTrackDeleteMessageResponse,
  isFastTrackDoLoginResponse,
  isFastTrackMarkMessageAsReadResponse,
  isFastTrackMessagesResponse,
} from './guards';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function createFastTrackApi(requestTimeout: Ref<number>) {
  const client = useFastTrackApiClient();

  return {
    async getFastTrackBaseEndpoints(options: FastTrackBaseEndpointsOptions) {
      return client.request<FastTrackBaseEndpointsResponse>({
        id: 'fast-track-api/get-saved-logins',
        endpoint: options.endpoint,
        method: 'GET',
        silent: true,
        priority: RequestOptionsPriority.LOW,
        notAddBaseHeaders: true,
        requestTimeout: requestTimeout.value,
        guard: isFastTrackBaseEndpointsResponse,
      });
    },

    async doFastTrackLogin(options: FastTrackDoLoginOptions) {
      return client.request<FastTrackDoLoginResponse>({
        id: 'fast-track-api/do-fast-track-login',
        endpoint: `${options.fusionUrl}/Platform/LoginAuthToken`,
        method: 'POST',
        headers: {
          authtoken: options.sessionId,
        },
        requestTimeout: requestTimeout.value,
        silent: true,
        priority: RequestOptionsPriority.LOW,
        notAddBaseHeaders: true,
        guard: isFastTrackDoLoginResponse,
      });
    },

    async getFastTrackMessages(options: FastTrackGetMessagesOptions) {
      return client.request<FastTrackGetMessagesResponse>({
        id: 'fast-track-api/get-fast-track-messages',
        endpoint: `${options.fusionUrl}/Notifications/v2/user-notifications`,
        method: 'GET',
        headers: {
          authtoken: options.authToken,
        },
        query: {
          'unread-only': options.unreadOnly,
          'inbox-only': options.inboxOnly,
        },
        requestTimeout: requestTimeout.value,
        silent: true,
        priority: RequestOptionsPriority.LOW,
        notAddBaseHeaders: true,
        guard: isFastTrackMessagesResponse,
      });
    },

    async markFastTrackMessageAsRead(options: FastTrackMarkMessageAsReadOptions) {
      return client.request<FastTrackMarkMessageAsReadResponse>({
        id: 'fast-track-api/mark-fast-track-message-as-read',
        endpoint: `${options.fusionUrl}/Notifications/MarkNotificationAsRead`,
        method: 'POST',
        headers: {
          authtoken: options.authToken,
        },
        data: {
          MessageId: options.messageId,
        },
        requestTimeout: requestTimeout.value,
        silent: true,
        priority: RequestOptionsPriority.LOW,
        notAddBaseHeaders: true,
        guard: isFastTrackMarkMessageAsReadResponse,
      });
    },

    async deleteFastTrackMessage(options: FastTrackDeleteMessageOptions) {
      return client.request<FastTrackDeleteMessageResponse>({
        id: 'fast-track-api/delete-fast-track-message',
        endpoint: `${options.fusionUrl}/Notifications/v2/user-notification/${options.messageId}`,
        method: 'DELETE',
        headers: {
          authtoken: options.authToken,
        },
        requestTimeout: requestTimeout.value,
        silent: true,
        priority: RequestOptionsPriority.LOW,
        notAddBaseHeaders: true,
        guard: isFastTrackDeleteMessageResponse,
      });
    },
  };
}
