<script lang="ts" setup>
import { ref } from 'vue';

import VCountdown from 'web/src/components/Countdown/VCountdown/VCountdown.vue';
import { CountdownType } from 'web/src/components/Countdown/VCountdown/enum';

interface CustomerVerificationCardTimerProps {
  expirationDate?: number;
}

interface CustomerVerificationCardTimerEmits {
  (e: 'timer-end'): void;
}

defineProps<CustomerVerificationCardTimerProps>();

const emit = defineEmits<CustomerVerificationCardTimerEmits>();

const timeLabel = ref<string>();

function setTimeLabel(value: string): void {
  timeLabel.value = value;
}
</script>

<template>
  <div v-auto-id="'CustomerVerificationCardTimer'" :class="$style['customer-verification-card-timer']">
    <span :class="$style['customer-verification-card-timer__date']">
      <VCountdown
        v-if="expirationDate"
        :expiration-date="expirationDate"
        :countdown-date-format="CountdownType.HOURS_MINUTES"
        :title="$t('WEB2_VV_CLOSE_TIME')"
        @countdown-end="emit('timer-end');"
        @countdown-change="setTimeLabel"
      />
    </span>
  </div>
</template>

<style lang="scss" module>
.customer-verification-card-timer {
  @include medium\12\16;

  display: flex;
  justify-content: center;
  padding: 12px 0 6px;

  &__date {
    overflow: hidden;
    color: var(--TextSecondary);
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
