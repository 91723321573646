import type { PriceChangePolicy } from '@leon-hub/api-sdk';

import type { FormatMoneyFunc } from 'web/src/modules/money/types';

import type {
  BatchedSlipInfoDocument,
  BetInfoObject,
  SlipEntryId,
} from '../../slip-info/types';
import type { MultiSinglesMetaInfo } from '../types';
import getSlipEntryId from '../../../utils/getSlipEntryId';
import isEntryAvailableForBetting from '../../../utils/isEntryAvailableForBetting';
import getMultiSinglesSlipEntry from './getMultiSinglesSlipEntry';
import validateMultiSinglesMetaStake from './validateMultiSinglesMetaStake';

interface Payload {
  batchedSlipInfo: BatchedSlipInfoDocument | null;
  previousValue: MultiSinglesMetaInfo;
  defaultBetAmount: number | null;
  betsInfo: BetInfoObject;
  balance: number | null;
  priceChangePolicy: PriceChangePolicy | null;
  formatMoney: FormatMoneyFunc;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export default function getMultiSinglesMetaInfo({
  previousValue,
  batchedSlipInfo,
  defaultBetAmount,
  betsInfo,
  balance,
  priceChangePolicy,
  formatMoney,
}: Payload) : MultiSinglesMetaInfo {
  if (!batchedSlipInfo || batchedSlipInfo.slipEntries.length < 2) {
    return {};
  }
  const result: MultiSinglesMetaInfo = {};
  let entryWithBalanceErrorId: SlipEntryId | null = null;
  const haveBalance = balance !== null;
  let totalBetAmount = 0;
  for (const entry of batchedSlipInfo.slipEntries) {
    const slipEntry = getMultiSinglesSlipEntry(entry);
    const id: SlipEntryId = getSlipEntryId(slipEntry);
    const relatedBetsInfo = betsInfo[id];
    const previousRecord = previousValue[id] || {};
    const previousStakeValue = previousRecord.stakeValue;
    if (haveBalance && previousRecord.stakeOverBalance) {
      entryWithBalanceErrorId = id;
    }
    const stakeWasEdited = !!previousRecord?.stakeWasEdited;
    const defaultValue = `${defaultBetAmount || entry.minStake || 0}`;
    const currentStake = stakeWasEdited ? previousStakeValue : defaultValue;
    const clearValueOnFocus = !!previousRecord.selectedFastBetValue || !stakeWasEdited;
    const selectedFastBetValue = previousRecord.selectedFastBetValue || null;
    if (haveBalance && isEntryAvailableForBetting({
      marketStatus: slipEntry.marketStatus,
      currentOdds: slipEntry.odds,
      originalOdds: relatedBetsInfo?.originalOdds,
      priceChangePolicy,
    })) {
      totalBetAmount += (Number(currentStake) || 0);
    }
    result[id] = validateMultiSinglesMetaStake({
      record: {
        maxStake: entry.maxStake,
        minStake: entry.minStake,
        stakeValue: currentStake,
        selectedFastBetValue,
        stakeWasEdited,
        clearValueOnFocus,
      },
      formatMoney,
    });
  }
  if (haveBalance && entryWithBalanceErrorId && totalBetAmount > balance) {
    result[entryWithBalanceErrorId].stakeOverBalance = true;
  }
  return result;
}
