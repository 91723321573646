import { logger } from '@leon-hub/logging';

import { currencySymbols } from '../consts';

export default function getCurrencySymbol(currency: string): string {
  const matchedSymbol = currencySymbols[currency];
  if (!matchedSymbol) {
    logger.warn(`getCurrencySymbol: unexpected currency value: ${currency}`);
  }
  return matchedSymbol || currency;
}
