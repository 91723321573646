import * as crc32 from 'crc-32';

import { memoize } from '@leon-hub/utils';

function hashContent(content: string): string {
  // eslint-disable-next-line no-bitwise
  const hash = (crc32.str(content) >>> 0)
    .toString(16)
    .padStart(8, '0');
  return `${hash}-${content.length}`;
}

export default memoize(hashContent);
