import { Theme } from '@leon-hub/api-sdk';

import type BuiltinConfig from './types/BuiltinConfig';

export const defaultConfig: BuiltinConfig = {
  isNT: process.env.NODE_ENV === 'development',
  isQueryHashingEnabled: false,
  theme: { default: Theme.DARK },
  adPaths: {},
};
