import { isString } from '@leon-hub/guards';
import type { StringEntry } from '@leon-hub/api-sdk';

export default function getObjectFromPairs(pairs: readonly StringEntry[]): Record<string, string> {
  return pairs.reduce<Record<string, string>>((accumulator, current) => {
    if (isString(current.key) && isString(current.value)) {
      accumulator[current.key] = current.value;
    }
    return accumulator;
  }, {});
}
