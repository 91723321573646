import {
  isNull,
  isArray,
  isString,
  isNumber,
  isUndefined,
  isValidInterface,
} from '@leon-hub/guards';

import type {
  UnknownGetEventsResponse,
  UnknownGetEventsChangesResponse,
  UnknownGetHeadlineEventsResponse,
  UnknownGetHeadlineChangesEventsResponse,
} from 'web/src/modules/sportline/types/rest';

function isUnknownGetEventsResponse(value: unknown): value is UnknownGetEventsResponse {
  return isValidInterface<UnknownGetEventsResponse>(value, {
    totalCount: isNumber,
    vtag: [isUndefined, isString],
    events: isArray,
  }, { silent: true });
}

function isUnknownGetEventsChangesResponse(value: unknown): value is UnknownGetEventsChangesResponse {
  return isValidInterface<UnknownGetEventsChangesResponse>(value, {
    totalCount: isNumber,
    vtag: [isUndefined, isString],
    data: isArray,
  }, { silent: true });
}

/** Asserts types before filter events */
export function assertUnknownGetEventsResponse(
  value: unknown,
): asserts value is UnknownGetEventsResponse {
  if (!isUnknownGetEventsResponse(value)) {
    throw new Error('UnknownGetEventsResponse assertion failed');
  }
}

/** Asserts types before filter changes */
export function assertUnknownGetEventsChangesResponse(
  value: unknown,
): asserts value is UnknownGetEventsChangesResponse {
  if (!isUnknownGetEventsChangesResponse(value)) {
    throw new Error('UnknownGetEventsChangesResponse assertion failed');
  }
}

export function assertUnknownGetHeadlineEventsResponse(
  value: unknown,
): asserts value is UnknownGetHeadlineEventsResponse {
  if (!isValidInterface<UnknownGetHeadlineEventsResponse>(value, {
    total: [isUndefined, isNull, isNumber],
    liveTotal: [isUndefined, isNull, isNumber],
    prematchTotal: [isUndefined, isNull, isNumber],
    events: [isUndefined, isNull, isUnknownGetEventsResponse],
    live: [isUndefined, isNull, isUnknownGetEventsResponse],
    prematch: [isUndefined, isNull, isUnknownGetEventsResponse],
  }, { silent: true })) {
    throw new Error('UnknownGetHeadlineEventsResponse assertion failed');
  }
}

export function assertUnknownGetHeadlineChangesEventsResponse(
  value: unknown,
): asserts value is UnknownGetHeadlineChangesEventsResponse {
  if (!isValidInterface<UnknownGetHeadlineChangesEventsResponse>(value, {
    total: [isUndefined, isNull, isNumber],
    liveTotal: [isUndefined, isNull, isNumber],
    prematchTotal: [isUndefined, isNull, isNumber],
    events: [isUndefined, isNull, isUnknownGetEventsChangesResponse],
    live: [isUndefined, isNull, isUnknownGetEventsChangesResponse],
    prematch: [isUndefined, isNull, isUnknownGetEventsChangesResponse],
  }, { silent: true })) {
    throw new Error('UnknownGetHeadlineChangesEventsResponse assertion failed');
  }
}
