import type { Ref } from 'vue';
import { computed } from 'vue';

import { Tag } from '@leon-hub/tags';

import type { BaseTabsButtonProps } from '../types';

export interface BaseTabsButtonComposable {
  isDisabled: Ref<boolean | undefined>;
  tag: Ref<Tag>;
  htmlTypeAttr: Ref<string | undefined>;
  role: Ref<string | undefined>;
}

export function useBaseTabsButton(props: BaseTabsButtonProps): BaseTabsButtonComposable {
  const isDisabled = computed<Optional<boolean>>(() => (props.disabled ? props.disabled : undefined));

  const tag = computed<Tag>(() => (props.isNavigation ? Tag.A : Tag.BUTTON));

  const htmlTypeAttr = computed<Optional<string>>(() => (props.isNavigation ? undefined : Tag.BUTTON));

  const role = computed<Optional<string>>(() => (props.isNavigation ? undefined : 'tab'));

  return {
    isDisabled,
    tag,
    htmlTypeAttr,
    role,
  };
}
