import type { Currency } from '@leon-hub/api-sdk';

import type { VSelectOption } from 'web/src/components/Select';

export default function mapApiCurrencyResponseToSelectOptions(input: Pick<Currency, 'text' | 'value'>[])
  : VSelectOption[] {
  return input.map(({ text, value }) => ({
    label: text,
    value,
  }));
}
