import { watch } from 'vue';

import { useIsLoggedIn } from 'web/src/modules/auth/composables';

import type { AppVueRouter } from '../types';
import performLogin from './performLogin';
import performLogout from './performLogout';

export default function syncAuthStateToRoute(router: AppVueRouter): void {
  const { isLoggedIn } = useIsLoggedIn();

  watch(isLoggedIn, (newValue) => {
    if (newValue) {
      performLogin(router);
    } else {
      performLogout(router);
    }
  });
}
