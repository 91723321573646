import { defineStore } from 'pinia';
import {
  computed,
  ref,
  toRef,
} from 'vue';

import { useSiteConfigStore } from 'web/src/modules/core/store';

const usePushNotificationsStore = defineStore('push-notifications', () => {
  const siteConfigStore = useSiteConfigStore();
  const pushNotificationsBlock = toRef(siteConfigStore, 'pushNotificationsBlock');

  const isSupported = ref(false);
  const isAllowed = ref(false);
  const token = ref('');
  const deviceUUID = ref('');

  const isPushesEnabled = computed(() => isSupported.value && !!pushNotificationsBlock.value?.enabled);
  const isPushOnBetsEnabled = computed(() => !!pushNotificationsBlock.value?.isPushOnBetsEnabled);

  function setToken(value: string): void {
    token.value = value;
  }

  function setDeviceUUID(value: string): void {
    deviceUUID.value = value;
  }

  function setIsAllowed(value: boolean): void {
    isAllowed.value = value;
  }

  function setIsSupported(value: boolean): void {
    isSupported.value = value;
  }

  return {
    isPushesEnabled,
    isPushOnBetsEnabled,
    isSupported,
    isAllowed,
    token,
    deviceUUID,
    setToken,
    setIsAllowed,
    setDeviceUUID,
    setIsSupported,
  };
});

export default usePushNotificationsStore;
