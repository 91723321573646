import { QueryParameterName } from '@leon-hub/query-manager';
import { getQueryParams } from '@leon-hub/service-locator-env';

import ApplicationGqlClient from './ApplicationGqlClient';

let gqlClient: ApplicationGqlClient | undefined;

export default function useGraphqlClient(): ApplicationGqlClient {
  if (process.env.NODE_ENV === 'test') {
    return new Proxy({} as unknown as ApplicationGqlClient, {
      get() {
        return () => new Promise(() => {});
      },
    });
  }

  if (!gqlClient) {
    const runtimePlatform = getQueryParams()[QueryParameterName.PLATFORM];
    gqlClient = new ApplicationGqlClient({
      headers: runtimePlatform ? {
        // @see LEONWEB-6203
        'X-APP-PLATFORM': runtimePlatform,
      } : {},
      // Set origin to same page url for prerender,
      // because default origin is defined with ?prhost query param.
      origin: process.env.VUE_APP_PRERENDER ? '' : undefined,
    });

    // TODO: mv condition to function.
    if (getQueryParams()[QueryParameterName.TEST_CASE] === 'performance') {
      gqlClient.setDefaultCacheTtl(Infinity);
      gqlClient.setMaxBatchQueueSize(1);
    }
  }

  return gqlClient;
}
