import { normalizeError } from '@leon-hub/errors';

import type { ClaimToUpdateSportEventStatusChanged } from 'web/src/modules/slip/types/сlaimToUpdate';
import type {
  CoreSportEventResponse,
  GetSportEventsResponse,
} from 'web/src/modules/sportline/types/rest';
import {
  applyEventsStatusChanges,
  isSportEventResponseIncludesChanges,
} from 'web/src/modules/sportline/utils/rest/claimToUpdate';
import { ApplyEventStatusChangesError } from 'web/src/modules/sportline/errors/core';

export default function createApplyEventsStatusChanges(
  getResponse: () => Maybe<GetSportEventsResponse | CoreSportEventResponse>,
  setResponse: (response: GetSportEventsResponse | CoreSportEventResponse) => void,
): (changes: ClaimToUpdateSportEventStatusChanged[]) => void {
  return function applyStatusChanges(changes: ClaimToUpdateSportEventStatusChanged[]): void {
    try {
      const rawResponse = getResponse();
      if (!rawResponse) { return; }
      const hasChanges = isSportEventResponseIncludesChanges(rawResponse, changes);
      if (!hasChanges) { return; }
      const responseWithChanges = applyEventsStatusChanges(rawResponse, changes);
      setResponse(responseWithChanges);
    } catch (rawError) {
      throw new ApplyEventStatusChangesError({ originalError: normalizeError(rawError) });
    }
  };
}
