import { EditionKey } from '@leon-hub/environment-editions';
import type { Conditions } from '@leon-hub/environment-client';
import { Web2FontFamily } from '@leon-hub/api-sdk';

import { useConditionValue } from 'web/src/modules/core/utils/useConditionValue';

export default function useAppClassList(
  theme?: string,
  isFullScreen?: boolean,
  fontFamily?: string,
): string[] {
  const classList: string[] = [];

  for (const item of [
    EditionKey.PRODUCT,
    EditionKey.RENDERING,
    EditionKey.PLATFORM,
    EditionKey.MODERNITY,
    EditionKey.ENV,
    EditionKey.SKIN,
    EditionKey.STYLE,
    EditionKey.OS,
    EditionKey.LAYOUT,
    EditionKey.BROWSER,
  ] as (keyof Conditions)[]) {
    const environmentValue = useConditionValue(item);
    if (environmentValue) {
      classList.push(`app__${item}--${environmentValue}`);
    }
  }

  if (theme) {
    classList.push(`app__theme--${theme.toLowerCase()}`);
  }

  if (isFullScreen) {
    classList.push('app__fullscreen');
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
  if (fontFamily && fontFamily !== Web2FontFamily.DEFAULT) {
    classList.push(`app__font--${fontFamily.toLowerCase()}`);
  }

  return classList;
}
