<script lang="ts" setup>
import { FormPaymentsInputType } from 'web/src/components/FormPaymentsInput/components/FormPaymentsInput/enums';
import FormPaymentsInput from 'web/src/components/FormPaymentsInput/components/FormPaymentsInput/FormPaymentsInput.vue';
import type {
  FormWrapperWidgetProperties,
} from 'web/src/components/Form/types';

import useFormPaymentsDisabledInput from '../composables/useFormPaymentsDisabledInput';

const props = defineProps<FormWrapperWidgetProperties>();

const { componentProps } = useFormPaymentsDisabledInput(props, FormPaymentsInputType.PHONE);
</script>

<template>
  <FormPaymentsInput v-auto-id="'PhoneDisabledInput'"
    v-bind="componentProps"
  />
</template>
