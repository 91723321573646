import type { App } from 'vue';
import createDebug from 'debug';

import {
  prepareApp,
  configureCordovaServiceLocator,
} from '@leon-hub/cordova';
import { bootstrapEnvironment } from '@leon-hub/plugins';

import { createPrefetchProducer } from 'web/src/modules/core/apps/main/prefetch/createPrefetchProducer';
import type { PrefetchProducer } from 'web/src/modules/core/apps/main/prefetch/types/PrefetchProducer';
import providePolyfills from 'web/src/utils/providePolyfills';
import { configureWebServiceLocator } from 'web/src/modules/core/ioc';

const debug = createDebug('nuxt');
debug('TODO: define/redefine vite public dir');
// eslint-disable-next-line no-underscore-dangle
if (!process.env.VUE_APP_BUNDLER_NUXT && typeof window.__webpack_public_path__ === 'string') {
  // eslint-disable-next-line no-underscore-dangle
  __webpack_public_path__ = window.__webpack_public_path__;
}

function whenCordovaReady() {
  return new Promise((resolve, reject) => {
    try {
      document.addEventListener('deviceready', resolve);
    } catch (e) {
      reject(e);
    }
  });
}

export async function init(app?: App<Element>): Promise<void> {
  if (process.env.VUE_APP_PLATFORM_CORDOVA) {
    await whenCordovaReady();
    configureCordovaServiceLocator();
    bootstrapEnvironment();
    await prepareApp();
  } else if (process.env.VUE_APP_RENDERING_CSR) {
    providePolyfills();
    configureWebServiceLocator();
    bootstrapEnvironment();
  }

  const prefetchProducer = createPrefetchProducer();
  const args = (
    app
      ? [prefetchProducer, app]
      : [prefetchProducer]
  ) as [Promise<PrefetchProducer>, App<Element> | undefined];
  return import(
    /* webpackMode: "lazy" */
    'web/src/modules/core/apps/main/startApp'
  ).then(({ startApp }) => startApp(...args))
    // eslint-disable-next-line no-console
    .catch((error) => console.error(error));
}

if (!process.env.VUE_APP_BUNDLER_NUXT) {
  void init();
}
