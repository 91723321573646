import { getTranslations } from '@leon-hub/api-sdk';

import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';

import type { TranslationDocument } from '../types';

export default async function fetchTranslations(
  keys: string[],
  silent?: boolean,
): Promise<readonly TranslationDocument[]> {
  const apiClient = useGraphqlClient();

  const response = await getTranslations(
    apiClient,
    (node) => node.queries.system.getTranslationBundleByKeys,
    {
      options: {
        keys,
        mobile: true,
      },
    },
    {
      silent,
    },
  );

  return response.translations;
}
