<script setup lang="ts">
import { onMounted } from 'vue';

import VStatus from 'web/src/components/Status/VStatus/VStatus.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import type { PromotionButtonEmits, PromotionButtonProps } from 'web/src/modules/promotions/components/PromotionButton/types';
import usePromotionButton from 'web/src/modules/promotions/components/PromotionButton/composables/usePromotionButton';

const props = defineProps<PromotionButtonProps>();
const emit = defineEmits<PromotionButtonEmits>();

const {
  isStickied,
  promotionButton,
  onClick,
  showButtonStatus,
  buttonLabel,
  isStatusIcon,
  showButton,
  buttonProperties,
  onComponentMounted,
} = usePromotionButton(props, emit);

onMounted(onComponentMounted);
</script>

<template>
  <div v-auto-id="'PromotionButton'"
    ref="promotionButton"
    :class="{
      [$style['promotion-details-button']]: true,
      [$style['promotion-details-button--is-stickied']]: isStickied,
    }"
  >
    <!--  just status button  -->
    <VStatus
      v-if="buttonItem?.hintMessage"
      v-data-test="{ el: 'promotion-button-status-hint'}"
      :class="$style['promotion-details-button__status']"
      :text="buttonItem?.hintMessage"
      is-lower-case
    />
    <!--  status button  -->
    <VStatus
      v-if="showButtonStatus"
      v-data-test="{ el: 'promotion-button-status-button'}"
      :class="$style['promotion-details-button__button']"
      :text="buttonLabel"
      :is-icon="isStatusIcon"
      is-success
      @click="onClick"
    />
    <VButton
      v-if="showButton"
      v-data-test="{ el: 'promotion-button'}"
      v-bind="buttonProperties"
      :class="$style['promotion-details-button__button']"
      :label="buttonLabel"
      :is-loading="buttonIsLoading"
      @click="onClick"
    />
  </div>
</template>

<style lang="scss" module>
.promotion-details-button {
  $self: &;

  @include promotionDetailsButton;

  z-index: 2;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  width: 343px;
  margin-right: auto;
  margin-left: auto;

  &__button:not([disabled]) {
    cursor: pointer;
  }

  &--is-stickied {
    @include promotionDetailsButtonSticky;

    #{$self}__button {
      box-shadow: $promotionDetailsButtonBoxShadow;
    }
  }
}
</style>
