<script setup lang="ts">
import { toRef } from 'vue';

import type { Optional } from '@leon-hub/types';
import type { OptionalAnyIconName } from '@leon-hub/icons';

import type { VIconProps } from '@components/v-icon';
import { VIcon } from '@components/v-icon';

import type { ButtonTextColor } from 'web/src/components/Button/VButton/enums';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import VButton from 'web/src/components/Button/VButton/VButton.vue';

import type { FavoriteIconColor } from './enums';
import { useVFavoriteToggle } from './composables';

export interface VFavoriteToggleProps {
  activeIcon?: VIconProps;
  activeIconColor?: Optional<FavoriteIconColor>;
  inactiveIcon?: VIconProps;
  isFavorite?: boolean;
  hasBackground?: boolean;
  isButton?: boolean;
  isOnlyPending?: boolean;
  hideColors?: boolean;
  hasOpacity?: boolean;
  textColor?: ButtonTextColor;
}

interface VFavoriteToggleEmits {
  (e: 'click', event: MouseEvent): void;
}

const props = defineProps<VFavoriteToggleProps>();
const emit = defineEmits<VFavoriteToggleEmits>();

const isButton = toRef(props, 'isButton', false);
const textColor = toRef(props, 'textColor');

const {
  isToggled,
  onClick,
  onMouseLeave,
} = useVFavoriteToggle(emit);

const format = (name?: OptionalAnyIconName, color?: Optional<FavoriteIconColor>): string => {
  if (!name) return '';
  return String(color ? `${name}-${color}` : name);
};
</script>

<template>
  <VButton
    v-if="isButton"
    :kind="ButtonKind.TRANSPARENT"
    :text-color="textColor"
    :class="{
      [$style['favorite-toggle']]: true,
      [$style['favorite-toggle--button']]: true,
      [$style['favorite-toggle--favorite']]: !!isFavorite,
      [$style['favorite-toggle--pending']]: !!isOnlyPending,
      [$style['favorite-toggle--background']]: !!hasBackground,
      [$style['favorite-toggle--colored']]: !hideColors,
      [$style['favorite-toggle--opacity']]: hasOpacity && !isFavorite,
      [$style['favorite-toggle--toggled']]: isToggled,
      [$style['favorite-toggle--no-default-color']]: !!textColor,
    }"
    @click="onClick"
    @mouseleave="onMouseLeave"
  >
    <template #icon>
      <VIcon
        v-bind="inactiveIcon"
        :class="$style['favorite-toggle__inactive-icon']"
      />
      <VIcon
        v-bind="activeIcon"
        :class="[
          $style[`favorite-toggle__active-icon-name-${format(activeIcon?.name, activeIconColor)}`],
          $style['favorite-toggle__active-icon'],
        ]"
      />
    </template>
  </VButton>
  <div
    v-else
    :class="{
      [$style['favorite-toggle']]: true,
      [$style['favorite-toggle--favorite']]: !!isFavorite,
      [$style['favorite-toggle--pending']]: !!isOnlyPending,
      [$style['favorite-toggle--background']]: !!hasBackground,
      [$style['favorite-toggle--colored']]: !hideColors,
      [$style['favorite-toggle--opacity']]: hasOpacity && !isFavorite,
      [$style['favorite-toggle--toggled']]: isToggled,
      [$style['favorite-toggle--no-default-color']]: !!textColor,
    }"
    @click="onClick"
    @mouseleave="onMouseLeave"
  >
    <VIcon
      v-bind="inactiveIcon"
      :class="$style['favorite-toggle__inactive-icon']"
    />
    <VIcon
      v-bind="activeIcon"
      :class="[
        $style[`favorite-toggle__active-icon-name-${format(activeIcon?.name, activeIconColor)}`],
        $style['favorite-toggle__active-icon'],
      ]"
    />
  </div>
</template>

<style lang="scss" module>
@import 'web/src/components/FavoriteToggle/VFavoriteToggle/styles/favorite-toggle';
</style>
