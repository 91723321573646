import type { Ref } from 'vue';
import { computed, toRef } from 'vue';

import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';

export interface UseSportlineExpandableEventsListItemProps {
  eventId: Ref<Maybe<string>>;
  isExtended: Ref<boolean>;
  hasPrimaryMarket: Ref<boolean>;
  isMoreRunnersExist: Ref<boolean>;
  isExtendedSportEventAllowed: Ref<boolean>;
}

export interface UseSportlineExpandableEventsListItemEmits {
  onLinkClick(mouseEvent: MouseEvent, checkLinkGuard: boolean): void;
  extendElementOpen(): void;
  extendElementClose(): void;
}

interface UseSportlineExpandableEventsListItemComposable {
  isMoreRunnersAllowed: Ref<boolean>;
  isExpandButtonAvailable: Ref<boolean>;
  isCollapseButtonAvailable: Ref<boolean>;
  isLockedMoreAvailable: Ref<boolean>;
  onMoreRunnersClick(mouseEvent: MouseEvent): void;
  onLessRunnersClick(): void;
}

export function useSportlineExpandableEventsListItem(
  props: UseSportlineExpandableEventsListItemProps,
  emit: UseSportlineExpandableEventsListItemEmits,
): UseSportlineExpandableEventsListItemComposable {
  const {
    eventId,
    isExtended,
    hasPrimaryMarket,
    isMoreRunnersExist,
    isExtendedSportEventAllowed,
  } = props;

  const settingsStore = useSportlineSettingsStore();
  const isListMarketsDropDownEnabled = toRef(settingsStore, 'isListMarketsDropDownEnabled');

  const canShowMoreBlock = computed<boolean>(() => (!!process.env.VUE_APP_LAYOUT_DESKTOP || !hasPrimaryMarket.value));
  const isMoreRunnersAllowed = computed<boolean>(() => (
    !!eventId.value
    && isMoreRunnersExist.value
    && canShowMoreBlock.value
  ));
  const isExpandButtonAvailable = computed<boolean>(() => isMoreRunnersAllowed.value && !isExtended.value);
  const isCollapseButtonAvailable = computed<boolean>(() => isExtended.value);
  const isLockedMoreAvailable = computed<boolean>(() => (
    !isMoreRunnersAllowed.value
    && !isExtended.value
    && canShowMoreBlock.value
  ));

  function onMoreRunnersClick(mouseEvent: MouseEvent): void {
    if (isExtendedSportEventAllowed.value && isListMarketsDropDownEnabled.value) {
      emit.extendElementOpen();
    } else {
      emit.onLinkClick(mouseEvent, false);
    }
  }

  function onLessRunnersClick(): void {
    if (isExtendedSportEventAllowed.value) {
      emit.extendElementClose();
    }
  }

  return {
    isMoreRunnersAllowed,
    isExpandButtonAvailable,
    isCollapseButtonAvailable,
    isLockedMoreAvailable,
    onMoreRunnersClick,
    onLessRunnersClick,
  };
}
