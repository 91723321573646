export const enum LeaguePageSectionId {
  None = 'none',
  EventsList = 'events-list',
  Promotions = 'promotions',
  Statistic = 'statistic',
}

export const enum LeagueStatisticBlockStatus {
  Statistics = 'statistics',
  Standings = 'standings',
  Playoff = 'playoff',
  TopPlayers = 'topPlayers',
}
