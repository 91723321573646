import useCustomerFavoritesService
  from 'web/src/modules/sportline/submodules/favorites/composables/useCustomerFavoritesService';
import type {
  ShowcaseNavigationItemReducer,
  ShowcaseNavigationItemReducersList,
} from 'web/src/modules/home/submodules/showcase/types';
import { ShowcaseNavigationItemId } from 'web/src/modules/home/submodules/showcase/enums';

interface ShowcaseNavigationFavoriteEventsComposable {
  addFavoriteEventsReducer(itemReducers: ShowcaseNavigationItemReducersList): ShowcaseNavigationItemReducersList;
}

export default function useShowcaseNavigationFavoriteEventsComposable(): ShowcaseNavigationFavoriteEventsComposable {
  const {
    hasFavoritesOptimistic,
  } = useCustomerFavoritesService();

  function isFavoriteEventsEnabledReducer(): boolean {
    return hasFavoritesOptimistic.value;
  }

  function addFavoriteEventsReducer(
    itemReducers: ShowcaseNavigationItemReducersList,
  ): ShowcaseNavigationItemReducersList {
    // eslint-disable-next-line max-len
    const reducers: ShowcaseNavigationItemReducer[] = itemReducers[ShowcaseNavigationItemId.SPORTLINE_FAVORITE_EVENTS] || [];
    return {
      ...itemReducers,
      [ShowcaseNavigationItemId.SPORTLINE_FAVORITE_EVENTS]: [...reducers, isFavoriteEventsEnabledReducer],
    };
  }

  return {
    addFavoriteEventsReducer,
  };
}
