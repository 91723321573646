import type { ComputedRef } from 'vue';
import { computed, toRef } from 'vue';

import type { ILobbyType } from '@leon-hub/api-sdk';
import { lobbyTypeSearch } from '@leon-hub/api-sdk';

import type {
  CasinoSearchLobbyEmits,
  CasinoSearchLobbyProps,
} from 'web/src/modules/search/submodules/casino/views/CasinoSearchLobby/types';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import type { LobbySkeletonItem } from 'web/src/modules/lobby/types';

interface CasinoSearchLobbyComposable {
  lobbyType: ILobbyType;
  skeletons: ComputedRef<LobbySkeletonItem[]>;
  emitGameClick: () => void;
}

export default function useCasinoSearchLobby(
  props: CasinoSearchLobbyProps,
  emits: CasinoSearchLobbyEmits,
): CasinoSearchLobbyComposable {
  const lobbyType = lobbyTypeSearch;

  const { isLoggedIn } = useIsLoggedIn();
  const siteConfigStore = useSiteConfigStore();
  const egsSkeletons = toRef(siteConfigStore, 'egsSkeletons');

  const skeletons = computed<LobbySkeletonItem[]>(() => {
    const searchSkeletons = egsSkeletons.value?.search ?? [];

    if (!isLoggedIn.value) {
      if (props.isSport) {
        return [];
      }

      return searchSkeletons.slice(1);
    }

    return props.isSport ? searchSkeletons.slice(0, 1) : searchSkeletons;
  });

  function emitGameClick(): void {
    emits('game-click');
  }

  return {
    lobbyType,
    skeletons,
    emitGameClick,
  };
}
