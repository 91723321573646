import type { ServiceLocator } from '@leon-hub/service-locator';
import { getBuiltinConfig } from '@leon-hub/builtin-config';
import {
  serviceLocator,
  HTTPS,
  BUILTIN_CONFIG,
  COOKIES,
  LOCATION_HOST,
  LOCATION_HREF,
  LOCATION_ORIGIN,
  LOCATION_PATHNAME,
  LOCATION_SEARCH,
  REFERRER,
  USER_AGENT,
  QUERY_PARAMS,
  LOCATION_HASH,
  ONLINE_STATUS,
} from '@leon-hub/service-locator-env';
import { QueryParameterName } from '@leon-hub/query-manager';

export function configureWebServiceLocator(): ServiceLocator {
  const {
    location, document, navigator,
  } = window;

  // Cache location.search to avoid loss of query parameters during runtime.
  const locationSearch = location.search;
  // Convert and cache the URL search parameters into a key-value object for easy access.
  const searchParams = Object.fromEntries(
    new URLSearchParams(locationSearch),
  );

  // Retrieve the prerender origin from the query parameters, essential for server-side rendering scenarios.
  function getPrerenderOrigin(): string {
    const origin = searchParams[QueryParameterName.PRERENDER_HOST];
    if (!origin) throw new Error(`Expected query parameter "${QueryParameterName.PRERENDER_HOST}" to be defined`);
    return origin;
  }

  // Determine if the current origin is using HTTPS, crucial for security checks.
  function isHttps() {
    const origin = process.env.VUE_APP_PRERENDER
      ? getPrerenderOrigin()
      : location.origin;
    return origin.startsWith('https:');
  }

  // Encapsulate retrieval of the cached location search string.
  function getLocationSearch(): string {
    return locationSearch;
  }

  function getLocationPath(): string {
    return location.pathname;
  }

  function getLocationHash(): string {
    return location.hash;
  }

  serviceLocator.registerProvider(HTTPS, {
    value: isHttps,
  });

  // Register a provider to get the full URL, considering real browser/prerender scenarios.
  serviceLocator.registerProvider(LOCATION_HREF, {
    value: () => {
      if (process.env.VUE_APP_PRERENDER) {
        const origin = getPrerenderOrigin();
        return `${origin}${getLocationPath()}${getLocationSearch()}${getLocationHash()}`;
      }
      return location.href;
    },
  });

  serviceLocator.registerProvider(LOCATION_HASH, {
    value: getLocationHash,
  });

  // Register a provider for the current origin, handling browser/prerender scenarios.
  serviceLocator.registerProvider(LOCATION_ORIGIN, {
    value: () => {
      if (process.env.VUE_APP_PRERENDER) {
        const host = searchParams[QueryParameterName.PRERENDER_HOST];
        if (!host) throw new Error(`Expected query parameter "${QueryParameterName.PRERENDER_HOST}" to be defined`);
        return new URL(host).origin;
      }
      return location.origin;
    },
  });

  serviceLocator.registerProvider(LOCATION_HOST, {
    // return host with port.
    value: () => {
      if (process.env.VUE_APP_PRERENDER) {
        return (new URL(getPrerenderOrigin())).host;
      }
      return location.host;
    },
  });

  serviceLocator.registerProvider(LOCATION_PATHNAME, {
    value: getLocationPath,
  });
  serviceLocator.registerProvider(LOCATION_SEARCH, {
    value: () => location.search,
  });
  serviceLocator.registerProvider(COOKIES, {
    value: () => document.cookie,
  });
  serviceLocator.registerProvider(REFERRER, {
    value: () => document.referrer,
  });

  serviceLocator.registerProvider(USER_AGENT, {
    value: () => navigator.userAgent,
  });

  serviceLocator.registerProvider(BUILTIN_CONFIG, {
    value: getBuiltinConfig,
  });

  serviceLocator.registerProvider(QUERY_PARAMS, {
    value: () => ({ ...searchParams }),
  });

  serviceLocator.registerProvider(ONLINE_STATUS, {
    value: () => window.navigator.onLine,
  });

  return serviceLocator;
}
