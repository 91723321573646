import { logger } from '@leon-hub/logging';
import { isArray } from '@leon-hub/guards';

import type { SearchEventsResponse } from 'web/src/modules/sportline/types/rest';
import {
  filterCorruptedEvents,
  logCorruptedEventsIds,
} from 'web/src/modules/sportline/utils/response';
import useSportlineApi from 'web/src/modules/sportline/services/api/useSportlineApi';
import type { SearchEventsInput } from 'web/src/modules/sportline/services/api/types';

export default async function searchEvents(input: SearchEventsInput): Promise<SearchEventsResponse> {
  const apiClient = useSportlineApi();
  const response = await apiClient.searchEvents(input);

  try {
    if (!isArray(response)) {
      logger.error('searchEvents response assertion failed');
      return [];
    }

    return filterCorruptedEvents(response, logCorruptedEventsIds);
  } catch (error) {
    logger.error('apiClient getEvents response corrupted', error);
    // always return response for user
    return [];
  }
}
