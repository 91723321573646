import type { Ref } from 'vue';
import { ref, computed } from 'vue';

import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import type {
  GetSportEventsResponse,
  GetSportEventsChangesResponse,
} from 'web/src/modules/sportline/types/rest';
import { normalizeEventsChangesResponseToDefaultResponse } from 'web/src/modules/sportline/utils/response';

interface UseFavoritesEventsStoreComposableComposable {
  favoritesIdsList: Ref<Maybe<string[]>>;
  rawFavoriteEventsResponse: Ref<Maybe<GetSportEventsResponse> | false>;
  isFavoritesEventsListLoaded: Ref<boolean>;
  setFavoritesIdsList(ids: Maybe<string[]>): void;
  setRawFavoriteEventsResponse(response: GetSportEventsResponse | GetSportEventsChangesResponse | null | false): void;
}

export function useFavoritesEventsStoreComposable(): UseFavoritesEventsStoreComposableComposable {
  const { isLoggedIn } = useIsLoggedIn();

  const favoritesIdsList = ref<Maybe<string[]>>(null);
  const rawFavoriteEventsResponse = ref<Maybe<GetSportEventsResponse> | false>(null);

  const isFavoritesEventsListLoaded = computed<boolean>(() => (
    !isLoggedIn.value || rawFavoriteEventsResponse.value !== null
  ));

  function setRawFavoriteEventsResponse(
    response: GetSportEventsResponse | GetSportEventsChangesResponse | null | false,
  ): void {
    rawFavoriteEventsResponse.value = response
      ? normalizeEventsChangesResponseToDefaultResponse(response)
      : response;
  }

  function setFavoritesIdsList(ids: Maybe<string[]>): void {
    favoritesIdsList.value = ids;
  }

  return {
    favoritesIdsList,
    rawFavoriteEventsResponse,
    isFavoritesEventsListLoaded,
    setFavoritesIdsList,
    setRawFavoriteEventsResponse,
  };
}
