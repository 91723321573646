import type { RouteLocationRaw } from 'vue-router';

import type { Sport } from 'web/src/modules/sportline/types';
import { SportSegmentId } from 'web/src/modules/sportline/enums';
import {
  resolveCybersportPageLink,
  resolveSportsPageLink,
} from 'web/src/modules/sportline/submodules/navigation/store/utils';

export function resolveSportPageLink(sport: Sport): RouteLocationRaw {
  if (sport.segment.id === SportSegmentId.CyberSport) {
    return resolveCybersportPageLink({});
  }

  return resolveSportsPageLink(sport.navigationParameters);
}
