import { assert } from '@leon-hub/guards';
import { setInputCursorAfterValue } from '@leon-hub/utils';

export const setInputCursorPositionOnClick = (event: Event, hasFocus: boolean): void => {
  const { target } = event;

  assert(target instanceof HTMLInputElement, 'target should be of type HTMLInputElement');

  event.preventDefault();

  if (!hasFocus) {
    target.focus();
  }

  setInputCursorAfterValue(target);
};
