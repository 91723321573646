import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import type { LobbyPromotionItemProps } from 'web/src/modules/lobby/components/LobbyPromotionItem/types';
import { useTheme } from 'web/src/modules/theme/composables';
import { rgbaColor } from 'web/src/modules/lobby/components/LobbyPromotionItem/composables/utils';

export interface LobbyPromotionItemComposable {
  backgroundString: ComputedRef<string | undefined>;
}

export default function useLobbyPromotionItem(
  props: LobbyPromotionItemProps,
): LobbyPromotionItemComposable {
  const { isLight } = useTheme();

  const backgroundString = computed(() => {
    if (!props.item) {
      return undefined;
    }

    const { bgColor } = props.item;
    return `linear-gradient(0deg, ${rgbaColor(bgColor)}, ${rgbaColor(bgColor)}), `
      + `rgba${isLight.value ? '(255, 255, 255, 0.9)' : '(0, 0, 0, 0.9)'}`;
  });

  return {
    backgroundString,
  };
}
