import {
  Deferred, getUuid, memoize,
} from '@leon-hub/utils';
import type { Optional } from '@leon-hub/types';
import {
  isNumber,
  assert,
} from '@leon-hub/guards';
import { RequestOptionsPriority } from '@leon-hub/api-sdk';
import type { AbstractDocumentResponse } from '@leon-hub/api-sdk';

import type { DataReturnType } from '../types';
import isMutation from './isMutation';
import hashContent from './utils/hashContent';

export default class GqlBatchedSubRequest<RawResult = unknown,
  ResolvedResult extends AbstractDocumentResponse = AbstractDocumentResponse,
  ResolvedDataResult extends DataReturnType<ResolvedResult> = DataReturnType<ResolvedResult>,
> {
  private ts = 0;

  private failedAttempts = 0;

  private readonly content: string;

  private readonly customId?: string;

  public readonly mutation: boolean;

  public readonly options: Readonly<Record<string, unknown>> = {};

  private readonly headers: Readonly<Record<string, string>>;

  public readonly silent: boolean;

  public readonly created: number;

  public readonly deferred: Deferred<ResolvedDataResult>;

  public readonly promise: Promise<ResolvedDataResult>;

  public readonly id: string;

  private readonly group?: string;

  public readonly resolver: (node: RawResult) => AbstractDocumentResponse;

  private priority: RequestOptionsPriority = RequestOptionsPriority.NORMAL;

  private readonly timeout: Optional<number>;

  private readonly retry: Optional<number>;

  constructor({
    content,
    resolver,
    options,
    silent,
    group,
    headers = {},
    id,
    priority,
    timeout = 0,
    retry,
  }: {
    content: string;
    resolver: (node: RawResult) => AbstractDocumentResponse;
    options?: Record<string, unknown>;
    silent?: boolean;
    headers?: Record<string, string>;
    id?: string extends '' ? never : string;
    priority?: RequestOptionsPriority;
    timeout?: number;
    retry?: number;
    group?: string;
  }) {
    this.priority = priority ?? this.priority;
    this.created = Date.now();
    this.content = content.trim();
    this.options = options ?? {};
    this.resolver = resolver;
    this.headers = Object.freeze(headers);
    this.silent = silent ?? false;
    this.mutation = isMutation(content);
    this.deferred = new Deferred();
    this.promise = this.deferred.promise;
    this.customId = id;
    this.id = id || getUuid();
    this.timeout = timeout;
    this.retry = retry;
    this.group = group;
  }

  public setPriority(priority: RequestOptionsPriority): void {
    this.priority = priority;
  }

  public getPriority(): RequestOptionsPriority {
    return this.priority;
  }

  incrementFailedAttempts(): void {
    this.failedAttempts += 1;
  }

  updateFailedAttempts(value = 0): void {
    this.failedAttempts = value;
  }

  getFailedAttempts(): number {
    return this.failedAttempts;
  }

  public getContent(): string {
    return GqlBatchedSubRequest.getCachedContent(this.content);
  }

  public getContentHash(): string {
    return hashContent(this.getContent());
  }

  public getOperationName(): string | undefined {
    const splittedRequestName = this.getName().split(':');
    return splittedRequestName[1] !== 'unknown' ? splittedRequestName[1] : undefined;
  }

  private static getCachedContent = memoize((content: string): string => content.trim()
    .replace(/\s{2,}/gm, ' ')
    .replace(/([\n\r])[\n\r]*\s+/gm, '$1'));

  public getCacheTimestamp(): number {
    if (this.mutation) {
      return 0;
    }
    return this.ts;
  }

  public setCacheTimestamp(ts: number): void {
    assert(isNumber(ts), `Invalid number: ${ts}`);
    this.ts = ts;
  }

  public isPossiblyCached(): boolean {
    if (this.mutation) {
      return false;
    }
    return this.getCacheTimestamp() > 0;
  }

  public resetCacheTimestamp(): void {
    this.ts = 0;
  }

  public getHeaders = memoize((): Record<string, string> => Object.keys(this.headers).reduce<Record<string, string>>((accumulator, headerKey) => {
    const headerValue = this.headers[headerKey];
    if (!headerValue) {
      return accumulator;
    }
    const headerKeyLowCase = headerKey.toLowerCase();
    accumulator[headerKeyLowCase] = headerValue;
    return accumulator;
  }, {}));

  public getCacheKey = memoize((): string => {
    const { ts, ...options } = this.options || {};
    const headers = this.getHeaders();
    const content = this.getContent();

    return JSON.stringify({
      customId: this.customId,
      content,
      options,
      headers,
    });
  });

  public getName = memoize((): string => GqlBatchedSubRequest.getCachedName(this.getContent()));

  private static getCachedName = memoize((content: string): string => {
    const match = /(query|mutation)\s+(\w+)\b/m.exec(content);

    if (!match) {
      if (process.env.VUE_APP_ENV_DEV) {
        // eslint-disable-next-line no-console
        console.groupCollapsed('Incompatible graphql document syntax');
        // eslint-disable-next-line no-console
        console.log('Raw query content', content);
        // eslint-disable-next-line no-console
        console.groupEnd();
      }
      return 'unknown:unknown';
    }
    return `${match[1]}:${match[2]}`;
  });

  toJSON(): Record<string, unknown> {
    return {
      id: this.id,
      customId: this.customId,
      priority: this.priority,
      ts: this.ts,
      created: this.created,
      failedAttempts: this.failedAttempts,
      options: this.options,
      name: this.getName(),
      headers: this.getHeaders(),
      timeout: this.timeout,
    };
  }

  getTimeout(): Optional<number> {
    return this.timeout;
  }

  getRetry(): Optional<number> {
    return this.retry;
  }

  getGroup(): Optional<string> {
    return this.group;
  }
}
