import { defineStore } from 'pinia';
import {
  computed, ref, toRef, watch,
} from 'vue';

import { whenClientNetworkIdle } from '@leon-hub/idle';
import { BusEvent, useEventsBus } from '@leon-hub/event-bus';
import { logoutIntercom, registerIntercomUser, updateIntercomUser } from '@leon-hub/cordova';
import { delay } from '@leon-hub/utils';

import { useIntercomEvents, useWebIntercom } from 'web/src/modules/intercom/composables';
import type { IntercomSettings } from 'web/src/modules/intercom/composables/types';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { IntercomWidgetChevron, IntercomWidgetPadding } from 'web/src/modules/intercom/enums';
import { fetchIntercomApiData, getPadding } from 'web/src/modules/intercom/store/utils';
import { useRouterStore } from 'web/src/modules/core/store';
import { useI18nLocale } from 'web/src/modules/i18n/composables';
import { IntercomShowSpace } from 'web/src/modules/intercom/enums/IntercomShowSpace';
import useSupport from 'web/src/modules/support/composables/useSupport';

const useIntercomStore = defineStore('intercom', () => {
  const currentRoute = toRef(useRouterStore(), 'currentRoute');

  const companyId = `${process.env.VUE_APP_PRODUCT}-${process.env.VUE_APP_SKIN}`.toUpperCase();
  // State
  const userHash = ref<string>();
  const intercomApiBase = ref<string>();
  const intercomAppId = ref<string>('');
  const { isLoggedIn } = useIsLoggedIn();
  const isIntercomStarted = ref(false);
  const customerStore = useCustomerDataStore();
  const userId = toRef(customerStore, 'login');
  const { locale } = useI18nLocale();
  const horizontalPadding = getPadding(IntercomWidgetPadding.HORIZONTAL);
  const verticalPadding = getPadding(IntercomWidgetPadding.VERTICAL);

  const intercomSettings = computed<IntercomSettings>(() => ({
    api_base: intercomApiBase.value ? intercomApiBase.value : undefined,
    app_id: intercomAppId.value ? intercomAppId.value : undefined,
    user_id: userHash.value ? userId.value : undefined,
    user_hash: userHash.value ? userHash.value : undefined,
    alignment: IntercomWidgetChevron.RIGHT,
    horizontal_padding: horizontalPadding,
    vertical_padding: verticalPadding,
    company_id: companyId,
    language_override: locale.value,
  }));

  const {
    openIntercomMenu,
    hideIntercomWidget,
    showIntercomWidget,
    resetIntercom,
    bootIntercomUser,
    showSpace,
    onUpdate,
  } = useIntercomEvents();
  const bus = useEventsBus();

  const {
    isChatMode,
    isHelpMode,
    isIntercomAvailable,
    isIntercomOptionsListFlow,
  } = useSupport();

  bus.on(BusEvent.OPEN_CHAT, () => {
    void openIntercom();
  });

  async function syncIntercomApiData(): Promise<void> {
    if (isChatMode.value || (isHelpMode.value && isIntercomAvailable.value)) {
      const data = await fetchIntercomApiData();
      intercomApiBase.value = data.apiUrl;
      intercomAppId.value = data.appId;
      userHash.value = data.userHash;
    }
  }

  // Main initialize function
  async function initIntercomLazy(): Promise<void> {
    await delay(2000);
    await whenClientNetworkIdle({ interval: 500 });
    await syncIntercomApiData();

    if (!intercomAppId.value) {
      return;
    }

    if (process.env.VUE_APP_PLATFORM_CORDOVA) {
      registerIntercomUserForCordova();
      isIntercomStarted.value = true;
      return;
    }

    if (
      (process.env.VUE_APP_LAYOUT_PHONE && !isIntercomStarted.value)
      || (process.env.VUE_APP_LAYOUT_DESKTOP && !isIntercomStarted.value && isIntercomOptionsListFlow.value)
    ) {
      hideIntercomWidget();
      isIntercomStarted.value = true;
    }

    await lazyLoadHandler();
  }

  async function lazyLoadHandler(): Promise<void> {
    try {
      await useWebIntercom().loadScript(intercomSettings.value);
    } catch (error) {
      throw new Error(`Error loading Intercom script: ${error}`);
    }
  }

  function registerIntercomUserForCordova(): void {
    if (process.env.VUE_APP_PLATFORM_CORDOVA) {
      registerIntercomUser({
        isLoggedIn: isLoggedIn.value,
        userId: userId.value ? userId.value : undefined,
        userHash: userHash.value ? userHash.value : undefined,
        custom_attributes: {
          language_override: locale.value,
          company_id: companyId,
        },
      });
    }
  }

  function openIntercom(): void {
    // For phone
    if (process.env.VUE_APP_LAYOUT_PHONE && isIntercomStarted.value) {
      openIntercomMenu();
    }

    // For desktop
    if (process.env.VUE_APP_LAYOUT_DESKTOP && isIntercomStarted.value) {
      if (isIntercomOptionsListFlow.value) {
        showSpace(IntercomShowSpace.MESSAGES);
      } else {
        openIntercomMenu();
      }
    }

    // For cordova
    if (process.env.VUE_APP_PLATFORM_CORDOVA && isIntercomStarted.value) {
      showIntercomWidget();
    }
  }

  watch(isIntercomStarted, (newValue) => {
    if (newValue) {
      if (process.env.VUE_APP_LAYOUT_DESKTOP && isIntercomOptionsListFlow.value) {
        hideIntercomWidget();
      }
      if (process.env.VUE_APP_LAYOUT_PHONE) {
        hideIntercomWidget();
      }
    }
  });

  watch(isLoggedIn, async (newValue) => {
    if (isHelpMode.value && !isIntercomOptionsListFlow.value) {
      return;
    }

    if (newValue) {
      await syncIntercomApiData();
    } else {
      userHash.value = '';
      resetIntercom();
      if (process.env.VUE_APP_PLATFORM_CORDOVA) {
        logoutIntercom();
      }
    }

    registerIntercomUserForCordova();
  });

  watch(intercomSettings, (newValue) => {
    bootIntercomUser(newValue);
    if (process.env.VUE_APP_LAYOUT_PHONE) {
      hideIntercomWidget();
    }

    if (newValue && process.env.VUE_APP_LAYOUT_DESKTOP && isIntercomOptionsListFlow.value) {
      hideIntercomWidget();
    }
  }, { deep: true });

  watch(intercomAppId, (newValue) => {
    if (newValue) {
      intercomAppId.value = newValue;
      bootIntercomUser(intercomSettings.value);
    }
  }, { immediate: true });

  watch(() => currentRoute.value?.fullPath, (newPath) => {
    if (process.env.VUE_APP_PLATFORM_CORDOVA) {
      updateIntercomUser({
        last_request_at: Math.floor(Date.now() / 1000),
        location: newPath,
      });
    } else {
      onUpdate({
        last_request_at: Math.floor(Date.now() / 1000),
        location: newPath,
      });
    }
  }, { immediate: true });

  return {
    initIntercom: initIntercomLazy,
    openIntercom,
    userHash,
    isIntercomStarted,
  };
});

export default useIntercomStore;
