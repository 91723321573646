<script lang="ts" setup>
import DefaultTopBarRouteComponent from 'web/src/modules/core/views/DefaultTopBarRouteComponent/DefaultTopBarRouteComponent.vue';
import useSimpleRegistrationTopBarRouteComponent
  from 'web/src/modules/registration/submodules/simplified-registration/composables/useSimpleRegistrationTopBarRouteComponent';

const {
  title,
  showModalOnLeaveEnabled,
  onClose,
  onBack,
} = useSimpleRegistrationTopBarRouteComponent();
</script>

<template>
  <DefaultTopBarRouteComponent v-auto-id="'SimpleRegistrationTopBarRouteComponent'"
    :title="title"
    :only-emit-events="showModalOnLeaveEnabled"
    @close="onClose"
    @back="onBack"
  />
</template>
