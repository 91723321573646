<script setup lang="ts">
import JackpotAnimatedAmount from 'web/src/components/AmountAnimator/JackpotAnimatedAmount/JackpotAnimatedAmount.vue';
import { JackpotAnimatedAmountKind } from 'web/src/components/AmountAnimator/types';

import type { JackpotBannerInfoProps } from './types';
import { useJackpotBannerInfo } from './composable';

const props = defineProps<JackpotBannerInfoProps>();

const {
  amountMax,
  amountMaxCurrency,
  amountMajor,
  amountMajorCurrency,
  amountMinor,
  amountMinorCurrency,
} = useJackpotBannerInfo(props);
</script>

<template>
  <div v-auto-id="'JackpotBannerInfo'"
    :class="{
      [$style['headline-banner-jackpot']]: true,
    }"
  >
    <div
      :class="{
        [$style['headline-banner-jackpot__wrapper']]: true,
        [$style['headline-banner-jackpot__wrapper--full']]: true,
      }"
    >
      <div
        v-if="amountMax > 0"
        :class="$style['headline-banner-jackpot__top']"
      >
        <div
          :class="{
            [$style['headline-banner-jackpot__amount']]: true,
            [$style['headline-banner-jackpot__amount--max']]: true,
          }"
        >
          <div :class="$style['headline-banner-jackpot__amount-title']">
            MEGA
          </div>
          <div :class="$style['headline-banner-jackpot__amount-wrapper']">
            <JackpotAnimatedAmount
              :amount="amountMax"
              :currency="amountMaxCurrency"
              :kind="JackpotAnimatedAmountKind.MAX"
              :is-infinite="false"
              :duration="0"
            />
          </div>
        </div>
      </div>
      <div
        v-if="amountMajor > 0"
        :class="{
          [$style['headline-banner-jackpot__amount']]: true,
          [$style['headline-banner-jackpot__amount--major']]: true,
        }"
      >
        <div :class="$style['headline-banner-jackpot__amount-title']">
          <span :class="$style['headline-banner-jackpot__amount-title-letter']">M</span>
          <span :class="$style['headline-banner-jackpot__amount-title-letter']">A</span>
          <span :class="$style['headline-banner-jackpot__amount-title-letter']">J</span>
          <span :class="$style['headline-banner-jackpot__amount-title-letter']">O</span>
          <span :class="$style['headline-banner-jackpot__amount-title-letter']">R</span>
        </div>
        <div :class="$style['headline-banner-jackpot__amount-wrapper']">
          <JackpotAnimatedAmount
            :amount="amountMajor"
            :currency="amountMajorCurrency"
            :kind="JackpotAnimatedAmountKind.MAJOR"
            :is-infinite="false"
            :duration="0"
          />
        </div>
      </div>
      <div
        v-if="amountMinor > 0"
        :class="{
          [$style['headline-banner-jackpot__amount']]: true,
          [$style['headline-banner-jackpot__amount--minor']]: true,
        }"
      >
        <div :class="$style['headline-banner-jackpot__amount-title']">
          <span :class="$style['headline-banner-jackpot__amount-title-letter']">M</span>
          <span :class="$style['headline-banner-jackpot__amount-title-letter']">I</span>
          <span :class="$style['headline-banner-jackpot__amount-title-letter']">N</span>
          <span :class="$style['headline-banner-jackpot__amount-title-letter']">O</span>
          <span :class="$style['headline-banner-jackpot__amount-title-letter']">R</span>
        </div>
        <div :class="$style['headline-banner-jackpot__amount-wrapper']">
          <JackpotAnimatedAmount
            :amount="amountMinor"
            :currency="amountMinorCurrency"
            :kind="JackpotAnimatedAmountKind.MINOR"
            :is-infinite="false"
            :duration="0"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style module lang="scss">
$headlineBannerJackpot: '.headline-banner-jackpot';

@include for-layout using($isDesktop) {
  .headline-banner-jackpot {
    position: absolute;
    top: 28px;
    left: 50%;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 160px;
    transform: translateX(-50%);

    @if not $isDesktop {
      width: 100%;

      @media (min-width: 360px) {
        top: 42px;
      }

      @media (min-width: 375px) {
        top: 50px;
      }

      @media (min-width: 412px) {
        top: 60px;
      }

      @media (min-width: 480px) {
        top: 90px;
      }
    }

    @if $isDesktop {
      top: 60px;
    }

    &__wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 8px 12px;
      align-items: flex-start;
      justify-content: center;

      @if not $isDesktop {
        @media (min-width: 360px) {
          row-gap: 16px;
        }

        @media (min-width: 480px) {
          gap: 24px;
        }
      }

      @if $isDesktop {
        gap: 16px 12px;
        align-items: flex-end;
      }

      &--full {
        @if $isDesktop {
          flex-wrap: nowrap;

          #{$headlineBannerJackpot}__top {
            flex-basis: inherit;
            order: 2;
            margin-bottom: 32px;
          }

          #{$headlineBannerJackpot}__amount--major {
            order: 1;
          }

          #{$headlineBannerJackpot}__amount--minor {
            order: 3;
          }
        }
      }
    }

    &__top {
      position: relative;
      display: flex;
      flex-basis: 100%;
      justify-content: center;

      &:before {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 240px;
        height: 240px;
        pointer-events: none;
        content: '';
        background: url('~web/src/modules/banners/components/JackpotBannerInfo/images/shine.svg') 50% 50% no-repeat;
        background-size: 240px;
        transform: translate(-50%, -50%);
      }
    }

    &__amount {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 2px;
      align-items: center;

      &-wrapper {
        position: relative;
        border-radius: 10px;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3), 0 2px 6px 0 rgba(0, 0, 0, 0.5);

        &:before,
        &:after {
          position: absolute;
          content: '';
        }
      }

      &--max {
        gap: 0;

        #{$headlineBannerJackpot}__amount-wrapper {
          padding: 4px;
          background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0) 100%), #d39800;

          &:before {
            top: 1px;
            right: 1px;
            bottom: 1px;
            left: 1px;
            background: rgba(255, 255, 255, 0.2);
            border-radius: 9px;
          }

          &:after {
            top: 3px;
            right: 3px;
            bottom: 3px;
            left: 3px;
            background: rgba(0, 0, 0, 0.2);
            border-radius: 7px;
          }
        }

        #{$headlineBannerJackpot}__amount-title {
          top: -23px;
          width: 64px;
          height: 24px;
          overflow: hidden;
          font-size: 14px;
          line-height: 14px;
          color: #0c002d;
          letter-spacing: 2px;
          background: url('~web/src/modules/banners/components/JackpotBannerInfo/images/mega.svg') 50% 0 no-repeat;
        }
      }

      &--major {
        #{$headlineBannerJackpot}__amount-wrapper {
          padding: 2px;
          background: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.3) 100%), #7d9cb1;
        }

        #{$headlineBannerJackpot}__amount-title {
          &-letter {
            color: #000325;
            background: linear-gradient(180deg, #fff 0%, #c7d7e2 50%, #aabfcd 50%, #7d9cb1 100%);
          }
        }
      }

      &--minor {
        #{$headlineBannerJackpot}__amount-wrapper {
          padding: 2px;
          background: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.3) 100%), #c28b63;
        }

        #{$headlineBannerJackpot}__amount-title {
          &-letter {
            color: #1e000f;
            background: linear-gradient(180deg, #fff 0%, #ebb085 50%, #d89d72 50%, #c28b63 100%);
          }
        }
      }
    }

    &__amount-title {
      display: flex;
      gap: 1px;
      align-items: flex-end;
      justify-content: center;
      font-size: 10px;
      font-weight: 900;
      line-height: 10px;
      text-align: center;
      text-shadow: 0 1px 0 rgba(255, 255, 255, 0.6);
      text-transform: uppercase;

      &-letter {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 14px;
        height: 14px;
        text-align: center;
        border-radius: 2px;
        box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.5);
      }
    }
  }
}
</style>
