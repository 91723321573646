import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import { IconSize } from '@leon-hub/icons';
import { Tag } from '@leon-hub/tags';

import type { VIconProps } from '@components/v-icon';

import type { VSubHeaderProps } from 'web/src/components/SubHeader/VSubHeader/types';

export interface VSubHeaderComposable {
  mainProperties: ComputedRef<Record<string, string>>;
  mainTag: ComputedRef<Tag>;
  prefixIconProperties: ComputedRef<VIconProps>;
  suffixIconProperties: ComputedRef<VIconProps>;
}

export default function useVSubHeader(props: VSubHeaderProps): VSubHeaderComposable {
  const mainProperties = computed(() => {
    const properties: Record<string, string> = {};

    if (props.href) {
      properties.href = props.href;
    }

    return properties;
  });

  const mainTag = computed<Tag>(() => (props.href ? Tag.A : Tag.DIV));

  const prefixIconProperties = computed<VIconProps>(() => ({
    name: props.prefixIcon,
    size: IconSize.SIZE_16,
  }));

  const suffixIconProperties = computed<VIconProps>(() => ({
    name: props.suffixIcon,
    size: IconSize.SIZE_16,
  }));

  return {
    mainProperties,
    mainTag,
    prefixIconProperties,
    suffixIconProperties,
  };
}
