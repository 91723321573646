<script setup lang="ts">
import { computed, toRef } from 'vue';

import type {
  BetlineMatchStatisticsMatch,
  BetlineMatchStatisticsTeam,
} from 'web/src/modules/sportline/types/rest';
import {
  BetlineStatisticMatchStatus,
  BetlineStatisticWinnerType,
} from 'web/src/modules/sportline/enums/rest';
import { getWinnerByType } from 'web/src/modules/sportline/utils/rest/statistic';
import vPopperHint from 'web/src/components/Popper/directives/popperHint';

interface MatchStatisticResultIndicatorProps {
  match: BetlineMatchStatisticsMatch;
  displayedForTeam?: Maybe<BetlineMatchStatisticsTeam>;
}

const props = defineProps<MatchStatisticResultIndicatorProps>();

const match = toRef(props, 'match');
const displayedForTeam = toRef(props, 'displayedForTeam', null);

// @TODO compare with current open details
const isLive = false;

const winner = computed<Maybe<BetlineMatchStatisticsTeam>>(
  () => getWinnerByType(match.value.teams, match.value.winner),
);
const isDraw = computed<boolean>(() => match.value.winner === BetlineStatisticWinnerType.Draw);
const isCompletedOnPenalty = computed<boolean>(() => (
  match.value.status === BetlineStatisticMatchStatus.COMPLETED_ON_PENALTY
));
</script>

<template>
  <div v-auto-id="'MatchStatisticResultIndicator'" v-if="displayedForTeam || isLive">
    <span
      v-if="isLive"
      v-popper-hint="$t('WEB2_LIVE_SPORTLINE')"
      :class="[
        $style['match-statistic-result-indicator'],
        $style['match-statistic-result-indicator--unknown'],
      ]"
    >?</span>
    <span
      v-else-if="isDraw"
      v-popper-hint="$t('WEB2_MATCH_STATISTIC_RESULT_INDICATOR_DRAW')"
      :class="[
        $style['match-statistic-result-indicator'],
        $style['match-statistic-result-indicator--draw'],
      ]"
    >{{ $t('WEB2_MATCH_STATISTIC_RESULT_INDICATOR_DRAW_SHORT') }}</span>
    <span
      v-else-if="winner && winner.id === displayedForTeam?.id"
      v-popper-hint="isCompletedOnPenalty
        ? $t('WEB2_MATCH_STATISTIC_RESULT_INDICATOR_WIN_ON_PENALTY')
        : $t('WEB2_MATCH_STATISTIC_RESULT_INDICATOR_WIN')"
      :class="[
        $style['match-statistic-result-indicator'],
        $style['match-statistic-result-indicator--win'],
      ]"
    >{{ $t('WEB2_MATCH_STATISTIC_RESULT_INDICATOR_WIN_SHORT') }}</span>
    <span
      v-else
      v-popper-hint="isCompletedOnPenalty
        ? $t('WEB2_MATCH_STATISTIC_RESULT_INDICATOR_LOSE_ON_PENALTY')
        : $t('WEB2_MATCH_STATISTIC_RESULT_INDICATOR_LOSE')"
      :class="[
        $style['match-statistic-result-indicator'],
        $style['match-statistic-result-indicator--lose'],
      ]"
    >{{ $t('WEB2_MATCH_STATISTIC_RESULT_INDICATOR_LOSE_SHORT') }}</span>
  </div>
</template>

<style module lang="scss">
.match-statistic-result-indicator {
  @include medium\12\12;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  padding: 0;
  cursor: pointer;
  border-radius: 16px;

  &--unknown {
    color: var(--BrandText);
    background: var(--TextSecondary);
  }

  &--win {
    color: var(--BrandText);
    background: var(--BrandDefault);
  }

  &--lose {
    color: var(--BrandText);
    background: var(--ErrorDefault);
  }

  &--draw {
    color: var(--BrandText);
    background: var(--AlertDefault);
  }
}
</style>
