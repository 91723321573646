import type {
  Component,
  ComputedRef,
} from 'vue';
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { Tag } from '@leon-hub/tags';
import { RouteName } from '@leon-hub/routing-config-names';
import { BusEvent, useEventsBus } from '@leon-hub/event-bus';

import VRouterLink from 'web/src/components/Link/VRouterLink/VRouterLink.vue';
import type { VRouterLinkProps } from 'web/src/components/Link/VRouterLink/types';

export interface HeaderLogoComposable {
  isHomePage: ComputedRef<boolean>;
  tag: ComputedRef<Tag | Component>;
  componentProperties: ComputedRef<VRouterLinkProps | {}>;
  onClick: () => void;
}

export default function useHeaderLogo(): HeaderLogoComposable {
  const route = useRoute();
  const router = useRouter();
  const bus = useEventsBus();

  const isHomePage = computed(() => route.name === RouteName.HOME);
  const tag = computed(() => (isHomePage.value ? Tag.DIV : VRouterLink));
  const componentProperties = computed(() => {
    if (!isHomePage.value) {
      return {
        to: router.resolve({ name: RouteName.HOME }),
      };
    }
    return {};
  });

  function onClick(): void {
    if (isHomePage.value) {
      bus.emit(BusEvent.LAYOUT_CONTENT_SCROLL_TOP, {});
    }
  }

  return {
    isHomePage,
    tag,
    componentProperties,
    onClick,
  };
}
