import type { AccessDeniedRemoteApiException } from '@leon-hub/api-sdk';
import { getBootstrapTranslations } from '@leon-hub/bootstrap-translations';

import { ApiErrorCode } from '../../errors/ApiErrorCode';
import type { GqlApiBatchSubRequestErrorOptions } from './GqlApiBatchedSubRequestError';
import GqlApiBatchedSubRequestError from './GqlApiBatchedSubRequestError';

export class GqlApiAccessDeniedError extends GqlApiBatchedSubRequestError {
  constructor(options: GqlApiBatchSubRequestErrorOptions & { extensions: AccessDeniedRemoteApiException }) {
    super({
      ...options,
      code: ApiErrorCode.API_ACCESS_DENIED,
      message: getBootstrapTranslations().WEB2_SESSION_EXPIRED,
    });
  }
}
