import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import { IconName, IconSize } from '@leon-hub/icons';

import { useDialogs } from 'web/src/modules/dialogs/composables';
import type { ShowDialogOptions } from 'web/src/modules/dialogs/types';
import { DialogAction, PresetName } from 'web/src/modules/dialogs/enums';
import { JumbotronIconKind } from 'web/src/components/Jumbotron';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { useI18n } from 'web/src/modules/i18n/composables';
import { getSafePercentage } from 'web/src/modules/core/utils/math';

import type { AccumulatorBoostInfoProps } from '../types';

interface UseAccumulatorBoostInfo {
  showInfoModal: () => void;
  progress: ComputedRef<number>;
  termsTranslateParams: ComputedRef<Record<string, string>>;
  boost: ComputedRef<Record<string, string>>;
}

export default function useAccumulatorBoostInfo(props: AccumulatorBoostInfoProps): UseAccumulatorBoostInfo {
  const { showDialog } = useDialogs();

  const { $translate } = useI18n();

  const oddsTranslateParams = computed<Record<string, string>>(() => ({
    minOdds: props.requiredOdds ?? '',
  }));

  const boost = computed<Record<string, string>>(() => ({
    boost: `${props.nextMultiplier}`,
  }));

  const termsTranslateParams = computed<Record<string, string>>(() => ({
    ...oddsTranslateParams.value,
    itemsToNextBoost: `${props.itemsToNextBoost ?? ''}`,
  }));

  const progress = computed<number>(() => getSafePercentage(props.currentMultiplier ?? 0, props.maxMultiplier ?? 0));

  const showInfoModal = (): void => {
    const modalProperties: ShowDialogOptions = {
      presetName: PresetName.ALERT_WARNING,
      options: {
        title: $translate('WEB2_ACCUMULATOR_BOOST_MODAL_CAPTION').value,
        confirmMessage: $translate('WEB2_ACCUMULATOR_BOOST_MODAL_TEXT', oddsTranslateParams).value,
        iconName: IconName.LIGHTNING,
        iconSize: process.env.VUE_APP_LAYOUT_PHONE ? IconSize.SIZE_80 : IconSize.SIZE_60,
        iconKind: JumbotronIconKind.SUCCESS,
        fullHeight: process.env.VUE_APP_LAYOUT_PHONE ? true : undefined,
        isOverlayCloseAllowed: true,
        buttons: [
          {
            label: $translate('WEB2_CLOSE').value,
            kind: ButtonKind.PRIMARY,
            action: DialogAction.MODAL_CLOSE,
          },
        ],
      },
      id: 'accumulatorBoostMoreInfo',
    };
    showDialog(modalProperties);
  };

  return {
    showInfoModal,
    progress,
    boost,
    termsTranslateParams,
  };
}
