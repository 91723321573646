import type { Ref } from 'vue';
import { toRef, ref, watch } from 'vue';

import { logger } from '@leon-hub/logging';

import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
import { useSportlineApiService } from 'web/src/modules/sportline/services';
import { useBroadcastSelected } from 'web/src/modules/sportline/composables/broadcast';
import { useErrorsConverter } from 'web/src/modules/errors/composables';
import { useSyncSportline } from 'web/src/modules/sportline/submodules/sync-sportline';
import { useFavoriteEventsSportlineUpdateTimeout } from 'web/src/modules/sportline/submodules/update-timeout';
import type {
  GetSportEventsResponse,
  CoreSportlineFetchOptions,
  GetSportEventsChangesResponse,
} from 'web/src/modules/sportline/types/rest';
import type { CustomerFavoritesIdsBackgroundUpdate } from 'web/src/modules/sportline/submodules/favorites/types';
import { CustomerLiveMatchesApiErrorCode } from 'web/src/modules/sportline/submodules/favorites/enums';
import { addFavoriteEventsRequest, getFavoritesEventsIdsRequest } from 'web/src/modules/sportline/utils/api';
import { BackgroundUpdateStopwatch } from 'web/src/utils/store';

interface UseFavoriteEventsBackgroundUpdatesStoreComposableProps {
  favoritesIdsList: Ref<Maybe<string[]>>;
  pendingToFavoritesList: Ref<string[]>;
  rawFavoriteEventsResponse: Ref<Maybe<GetSportEventsResponse> | false>;
  setFavoritesIdsList(ids: Maybe<string[]>): void;
  clearPendingToFavoritesList(): void;
  setRawFavoriteEventsResponse(response: Maybe<GetSportEventsResponse | GetSportEventsChangesResponse> | false): void;
}

interface UseFavoriteEventsBackgroundUpdatesStoreComposable {
  setIsEventsBackgroundUpdateAvailable(value: boolean): void;
  setIsBackgroundIdsUpdateAvailable(value: CustomerFavoritesIdsBackgroundUpdate): void;
  fetchFavoriteEventsIdsList(silent?: boolean): Promise<void>;
}

export function useFavoriteEventsBackgroundUpdatesStoreComposable(
  props: UseFavoriteEventsBackgroundUpdatesStoreComposableProps,
): UseFavoriteEventsBackgroundUpdatesStoreComposable {
  const {
    favoritesIdsList,
    pendingToFavoritesList,
    rawFavoriteEventsResponse,
    setFavoritesIdsList,
    clearPendingToFavoritesList,
    setRawFavoriteEventsResponse,
  } = props;

  const { isLoggedIn } = useIsLoggedIn();
  const customerDataStore = useCustomerDataStore();
  const customerLogin = toRef(customerDataStore, 'login');

  const { timeout: favoriteEventsUpdateInterval } = useFavoriteEventsSportlineUpdateTimeout();
  const broadcastSelected = useBroadcastSelected();
  const apiService = useSportlineApiService();
  const { convertToBaseError } = useErrorsConverter();

  const isEventsBackgroundUpdateAvailable = ref<boolean>(false);
  const isBackgroundIdsUpdateAvailable = ref<CustomerFavoritesIdsBackgroundUpdate>({});

  async function fetchFavoriteEvents({ silent }: CoreSportlineFetchOptions): Promise<void> {
    if (!isLoggedIn.value) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    const vTag = (rawFavoriteEventsResponse.value || null)?.vtag;
    const isUpdateRequest = !!vTag;
    const result = await apiService.loadFavoriteEvents({
      login: `${customerLogin.value}-${Date.now()}`,
      vTag: isUpdateRequest ? vTag : undefined,
      silent,
    });

    setRawFavoriteEventsResponse(result);
    broadcastSelected.updateDataInStorageByResponse({
      response: result,
    });
  }

  async function fetchFavoriteEventsIdsList(silent?: boolean): Promise<void> {
    if (!isLoggedIn.value) {
      setFavoritesIdsList([]);
      return;
    }

    const response = await getFavoritesEventsIdsRequest({ silent });
    // eslint-disable-next-line unicorn/prefer-native-coercion-functions
    const favorites = (response.ids || []).map((eventId) => String(eventId)).sort();
    setFavoritesIdsList(favorites);
  }

  async function synchronizeFavorites(silent?: boolean): Promise<void> {
    if (!isLoggedIn.value) {
      setFavoritesIdsList([]);
      return;
    }

    const pendingIds: number[] = pendingToFavoritesList.value.map((id) => Number.parseInt(id, 10));

    if (pendingIds.length) {
      try {
        await addFavoriteEventsRequest({ eventIds: pendingIds }, { silent });
        await fetchFavoriteEventsIdsList(silent);
      } catch (rawError) {
        const error = convertToBaseError(rawError);
        if (error.code.equals(CustomerLiveMatchesApiErrorCode.LIMIT_EXCEEDED)) {
          logger.warn('doCustomerLiveMatchesAdd limit exceeded', error);
        } else {
          logger.error(error);
        }
      } finally {
        clearPendingToFavoritesList();
      }
    } else {
      await fetchFavoriteEventsIdsList(silent);
    }
  }

  const lastUpdate = new BackgroundUpdateStopwatch<['favoriteEvents', 'favoriteEventsIds']>({
    favoriteEvents: async ({ silent }: CoreSportlineFetchOptions) => {
      await fetchFavoriteEvents({ silent });
      lastUpdate.update('favoriteEvents');
    },
    favoriteEventsIds: async ({ silent }: CoreSportlineFetchOptions) => {
      await synchronizeFavorites(silent);
      lastUpdate.update('favoriteEventsIds');
    },
  });

  async function synchronizeEventsIds({ force, silent }: { force?: boolean; silent?: boolean }): Promise<void> {
    await (
      force
        ? lastUpdate.forceCallSyncAction('favoriteEventsIds', { silent })
        : lastUpdate.callSyncAction('favoriteEventsIds', favoriteEventsUpdateInterval.value, { silent })
    );
  }

  async function synchronizeEvents({ force, silent }: { force?: boolean; silent?: boolean }): Promise<void> {
    await (
      force
        ? lastUpdate.forceCallSyncAction('favoriteEvents', { silent })
        : lastUpdate.callSyncAction('favoriteEvents', favoriteEventsUpdateInterval.value, { silent })
    );
  }

  function setIsEventsBackgroundUpdateAvailable(value: boolean): void {
    if (value === isEventsBackgroundUpdateAvailable.value) { return; }
    isEventsBackgroundUpdateAvailable.value = value;
    if (value) { void synchronizeEvents({ silent: true }); }
  }

  function setIsBackgroundIdsUpdateAvailable(value: CustomerFavoritesIdsBackgroundUpdate): void {
    isBackgroundIdsUpdateAvailable.value = { ...isBackgroundIdsUpdateAvailable.value, ...value };
    if (value) { void synchronizeEventsIds({ silent: true }); }
  }

  async function syncState(silent?: boolean): Promise<void> {
    const isSyncIdsAvailable = Object.values(isBackgroundIdsUpdateAvailable.value).some((enable) => enable);
    const isSyncListAvailable = isEventsBackgroundUpdateAvailable.value;

    // sync state
    await Promise.allSettled([
      isSyncIdsAvailable ? synchronizeEventsIds({ silent }) : Promise.resolve(),
      isSyncListAvailable ? synchronizeEvents({ silent }) : Promise.resolve(),
    ]);
  }

  useSyncSportline(syncState, favoriteEventsUpdateInterval);
  watch(favoritesIdsList, async (value, oldValue) => {
    if (JSON.stringify(value || []) === JSON.stringify(oldValue)) { return; }

    if (isEventsBackgroundUpdateAvailable.value) {
      await synchronizeEvents({ force: true, silent: true });
    } else {
      lastUpdate.invalidate('favoriteEvents');
    }
  });

  return {
    setIsEventsBackgroundUpdateAvailable,
    setIsBackgroundIdsUpdateAvailable,
    fetchFavoriteEventsIdsList,
  };
}
