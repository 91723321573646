<script lang="ts" setup>
import { toRef } from 'vue';

import { IconSize } from '@leon-hub/icons';
import { isString, isNull } from '@leon-hub/guards';

import type { VCountryFlagSize, CountryCode } from 'web/src/components/CountryFlag/VCountryFlag/enums';
import { VCountryFlag } from 'web/src/components/CountryFlag/VCountryFlag';

import { useVFlagIcon } from './useVFlagIcon';

interface VFlagIconProps {
  size?: IconSize;
  countryCode?: Maybe<CountryCode | string>;
  flagSize?: VCountryFlagSize;
  rounded?: boolean;
}

interface VFlagIconSlots {
  flag(props: { flagSize?: VCountryFlagSize }): void;
}

const props = defineProps<VFlagIconProps>();
defineSlots<VFlagIconSlots>();

const size = toRef(props, 'size', IconSize.SIZE_20);
const countryCode = toRef(props, 'countryCode', null);
const flagSize = toRef(props, 'flagSize');

const { finalFlagSize } = useVFlagIcon({ size, flagSize });

function isCountryCode(value?: Maybe<CountryCode | string>): value is Maybe<CountryCode> {
  return isNull(value) || isString(value);
}
</script>

<template>
  <span v-auto-id="'VFlagIcon'"
    :class="{
      [$style['flag-icon']]: true,
      [$style[`flag-icon--${size}`]]: true,
    }"
  >
    <slot
      name="flag"
      :flag-size="flagSize"
    >
      <VCountryFlag
        v-if="isCountryCode(countryCode)"
        :code="countryCode"
        :size="finalFlagSize"
        :rounded="rounded"
      />
    </slot>
  </span>
</template>

<style module lang="scss">
.flag-icon {
  display: inline-flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  &--size {
    $sizes: 20, 24, 28;

    @each $size in $sizes {
      &-#{$size} {
        width: #{$size}px;
        height: #{$size}px;
      }
    }
  }
}
</style>
