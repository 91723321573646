import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';

import { useAnalytics } from 'web/src/modules/analytics/composables';

interface UseSportlineAnalyticsComposable {
  zeroMarginTabClick(): void;
  zeroMarginSidebarTabClick(): void;
  searchClick(): void;
}

export function useSportlineAnalytics(): UseSportlineAnalyticsComposable {
  const analytics = useAnalytics();

  return {
    /** Sports zero margin tab click */
    zeroMarginTabClick(): void {
      analytics.push(AnalyticsEvent.CLICK_MAP, {
        clickCounter: { margin_0: 'shortcuts' },
      });
    },
    /** Sidebar zero margin tab click */
    zeroMarginSidebarTabClick(): void {
      analytics.push(AnalyticsEvent.CLICK_MAP, {
        clickCounter: { margin_0: 'button' },
      });
    },
    searchClick(): void {
      analytics.push(AnalyticsEvent.CLICK_MAP, {
        clickCounter: { search: 'headerSearch' },
      });
    },
  };
}
