import type { RouteLocationNamedRaw } from 'vue-router';

import { CasinoRouteName } from '@leon-hub/routing-config-names';

import {
  AllGamesCategoryUrl,
  AllGamesGroupUrl,
  FavoriteGamesCategoryUrl,
} from 'web/src/modules/egs/constants';

export default function getLobbyLocation(
  baseRouteName: string,
  categoryUrl = '',
  urlGroup = '',
): RouteLocationNamedRaw {
  if (process.env.VUE_APP_FEATURE_CASINO_MIXED_MY_GAMES_ENABLED && categoryUrl === FavoriteGamesCategoryUrl) {
    return {
      name: CasinoRouteName.CASINO_MY_GAMES,
    };
  }

  if (process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED && categoryUrl === AllGamesCategoryUrl && urlGroup === AllGamesGroupUrl) {
    return {
      name: baseRouteName,
    };
  }

  const params: Record<string, string> = {};

  if (categoryUrl) {
    params.categoryUrl = categoryUrl;
  }

  if (urlGroup) {
    params.groupUrl = urlGroup;
  }

  return {
    name: baseRouteName,
    params,
  };
}
