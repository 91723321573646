import type { Ref } from 'vue';
import { computed, ref } from 'vue';

import { assert } from '@leon-hub/guards';
import { isVisibleInDOM } from '@leon-hub/utils';
import { normalizeError } from '@leon-hub/errors';

import type {
  VReCaptchaEmits,
  VReCaptchaOptions,
  VReCaptchaProperties,
} from 'web/src/modules/captcha/components/VReCaptcha/types';
import CaptchaServiceError from 'web/src/modules/captcha/services/errors/CaptchaServiceError';
import {
  getConfiguredApiUrl,
  getSettledReCaptchaOptions,
  getVRecaptchaWidgetSize,
} from 'web/src/modules/captcha/components/VReCaptcha/composables/utils';
import DefaultReCaptchaService from 'web/src/modules/captcha/services/DefaultReCaptchaService';
import RECAPTCHA_CHALLENGE_SELECTOR from 'web/src/modules/captcha/components/VReCaptcha/constants/reCaptchaChallengeSelector';
import type { CaptchaToken, ReCaptchaResponse } from 'web/src/modules/captcha/store/types';

interface useVReCaptchaComposable {
  root: Ref<HTMLElement | undefined>;
  onComponentCreated: () => void;
  execute: () => Promise<unknown>;
  reset: () => void;
  onComponentMounted: () => Promise<void>;
}

export default function useVReCaptcha(
  props: VReCaptchaProperties,
  emit: VReCaptchaEmits,
): useVReCaptchaComposable {
  const root = ref<HTMLElement>();
  const size = computed(() => getVRecaptchaWidgetSize(props));
  const settledReCaptchaOptions = computed(() => getSettledReCaptchaOptions(props, size));
  const configuredApiUrl = computed(() => getConfiguredApiUrl(props));
  const CaptchaInstance = DefaultReCaptchaService.getInstance();
  const DOMObserverConfig: MutationObserverInit = {
    childList: true,
    attributes: true,
    attributeOldValue: true,
    characterData: false,
    characterDataOldValue: false,
    subtree: true,
  };
  let observer: MutationObserver;
  let isChallengeOpened = false;

  function onComponentCreated(): void {
    assert(window?.document?.body, 'This component should be used only in csr context');
    observer = new MutationObserver(domChangeCallback);
    observer.observe(window?.document?.body, DOMObserverConfig);
  }

  function domChangeCallback(mutationRecords: MutationRecord[] = []): void {
    for (const mutationRecord of mutationRecords) {
      if (mutationRecord.type === 'attributes') {
        const challengeIframe = (mutationRecord.target as Element)
          .querySelectorAll<HTMLIFrameElement>(RECAPTCHA_CHALLENGE_SELECTOR);

        const target = challengeIframe[0];
        challengeEmitToggle(challengeIframe.length, target);
      }
    }
  }

  function challengeEmitToggle(length: number, target: HTMLIFrameElement): void {
    if (length) {
      if (isVisibleInDOM(target)) {
        if (!isChallengeOpened) {
          emitChallengeOpened();
        }
      } else if (target && isChallengeOpened) {
        emitChallengeClosed();
      }
    }
  }

  function execute(): Promise<unknown> {
    return CaptchaInstance.execute();
  }

  function emitVerify(captchaToken: CaptchaToken): ReCaptchaResponse {
    CaptchaInstance.notifyExecuting({ captchaResponse: captchaToken });
    emit('verify', { captchaResponse: captchaToken });
    return { captchaResponse: captchaToken };
  }

  function reset(): void {
    CaptchaInstance.reset();
  }

  function emitExpired(): void {
    reset();
    emitError();
    emit('expired');
  }

  function emitError(error?: unknown): void {
    const payload = {
      reCaptchaSize: size.value,
      siteKey: props.siteKey,
    };

    let captchaError;
    if (error instanceof CaptchaServiceError) {
      captchaError = error;
      captchaError.payload = payload;
    } else {
      captchaError = new CaptchaServiceError({
        originalError: normalizeError(error),
        payload,
      });
    }

    emit('error', captchaError);
  }

  function emitRender(id: string): string {
    emit('render', id);
    return id;
  }

  function emitChallengeClosed(): void {
    isChallengeOpened = false;
    emit('challenge-is-closed');
  }

  function emitChallengeOpened(): void {
    isChallengeOpened = true;
    emit('challenge-is-opened');
  }

  async function onComponentMounted(): Promise<void> {
    try {
      await CaptchaInstance.tryLoadRecaptchaScript(configuredApiUrl.value);
      CaptchaInstance.checkRecaptchaLoad();

      const options: VReCaptchaOptions = {
        ...settledReCaptchaOptions.value,
        'callback': emitVerify,
        'expired-callback': emitExpired,
        'error-callback': emitError,
      };
      if (root.value instanceof HTMLElement) {
        CaptchaInstance.render(
          root.value,
          options,
          emitRender,
        );
      }

      if (props.autoExecute) {
        await execute();
      }
    } catch (rawError) {
      emitError(rawError);
    }
  }

  return {
    root,
    execute,
    reset,
    onComponentCreated,
    onComponentMounted,
  };
}
