<script lang="ts" setup>
/**
 * @TODO check VIcon usage. Seems we can use default.svg flag
 */
import { toRef, computed } from 'vue';

import { IconName, IconSize } from '@leon-hub/icons';

import { VIcon } from '@components/v-icon';

import type { CountryCode } from 'web/src/components/CountryFlag/VCountryFlag/enums';
import { VCountryFlagSize } from 'web/src/components/CountryFlag/VCountryFlag/enums';

import { useVCountryFlag } from './useVCountryFlag';

interface VCountryFlagProps {
  sprite?: boolean;
  /** @deprecated use code prop instead */
  name?: string;
  code?: Maybe<CountryCode>;
  size?: VCountryFlagSize;
  rounded?: boolean;
}

const props = defineProps<VCountryFlagProps>();

const name = toRef(props, 'name');
const isSprite = toRef(props, 'sprite', false);
const countryCode = toRef(props, 'code', 'MT' as CountryCode || null);
const size = toRef(props, 'size', VCountryFlagSize.SIZE_12);

const countryCodeFallback = computed(() => (
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
  ((name.value ?? countryCode.value)?.toUpperCase() || null) as Maybe<CountryCode>
));

const {
  position,
  imageUrl,
} = useVCountryFlag({ countryCode: countryCodeFallback });
</script>

<template>
  <span v-auto-id="'VCountryFlag'"
    :class="{
      [$style['country-flag']]: true,
      [$style[`country-flag-${size}`]]: !rounded,
      [$style['country-flag__rounded']]: rounded,
    }"
  >
    <span
      v-if="imageUrl"
      :class="$style['country-flag__img']"
      :style="isSprite
        ? { background: `url(${require('web/src/assets/flags/flags.png')}) ${position} / 255px no-repeat` }
        : { backgroundImage: `url(${imageUrl})` }"
    />
    <VIcon
      v-else
      :size="IconSize.SIZE_20"
      :name="IconName.GLOBE"
    />
  </span>
</template>

<style module lang="scss">
.country-flag {
  $self: &;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  &__img {
    display: inline-block;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: contain;
    border-radius: 2px;
  }

  &__rounded {
    width: 20px;
    height: 20px;
    padding: 1.6px;

    #{$self}__img {
      width: 100%;
      height: 100%;
      background-size: cover;
      border-radius: 100%;
    }
  }

  &-size {
    $sizes: (
      0: ('height': 0),
      10: ('height': 10px),
      12: ('height': 12px),
      16: ('height': 16px),
      18: ('height': 18px),
      20: ('height': 20px),
      22: ('height': 22px),
      24: ('height': 24px),
      26: ('height': 26px),
      28: ('height': 28px),
      40: ('height': 40px),
      54: ('height': 54px),
    );

    @each $name, $size in $sizes {
      &-#{$name} {
        width: calc(get($size, 'height') * 4 / 3);
        height: get($size, 'height');
      }
    }

    &-full-width {
      width: 100%;
      height: 75%;
    }
  }
}
</style>
