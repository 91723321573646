import {
  isOptionalNumber,
  isOptionalString,
  isOptionalArray,
  isUndefined,
  isObject,
} from '@leon-hub/guards';

import type { FileMultipleDragAndDropInputProps } from '../types/FileMultipleDragAndDropInputProps';
import isInputHintProps from './isInputHintProps';

export default function isFileMultipleDragAndDropInputProperties(value: unknown)
  : value is FileMultipleDragAndDropInputProps {
  if (!isObject(value)) {
    return false;
  }
  return isOptionalString(value.name)
    && isOptionalNumber(value.max)
    && (isOptionalArray(value.formErrors))
    && (isUndefined(value.fileTypes) || isObject(value.fileTypes))
    && isInputHintProps(value);
}
