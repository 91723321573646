import type { Ref } from 'vue';
import {
  ref,
} from 'vue';

import { useI18n } from 'web/src/modules/i18n/composables';

export interface FooterCopyrightComposable {
  copyright1: Ref<string>;
  copyright2: Ref<string>;
}

export default function useFooterCopyright(): FooterCopyrightComposable {
  const { $translate } = useI18n();
  const translationProps = ref<Record<string, string>>({ currentYear: String(new Date().getFullYear()) });

  const copyright1 = process.env.VUE_APP_LAYOUT_DESKTOP
    ? $translate('WEB2_COPYRIGHT_1_DYNAMIC_YEAR_V2', translationProps)
    : $translate('WEB2_COPYRIGHT_1_DYNAMIC_YEAR_V2_MOBILE', translationProps);

  const copyright2 = process.env.VUE_APP_LAYOUT_DESKTOP
    ? $translate('WEB2_COPYRIGHT_2', translationProps)
    : $translate('WEB2_COPYRIGHT_2_MOBILE', translationProps);

  return {
    copyright1,
    copyright2,
  };
}
