<script lang="ts" setup>
import VFadeCarouselPointsPagination
  from 'web/src/components/FadeCarousel/VFadeCarouselPointsPagination/VFadeCarouselPointsPagination.vue';
import { PaginationLocation } from 'web/src/components/FadeCarousel/enums';

import { useVFadeCarousel } from './composables';
import type {
  FadeCarouselProps,
  FadeCarouselEmits,
  FadeCarouselRef,
} from './types';

const props = withDefaults(defineProps<FadeCarouselProps>(), {
  initSlideIndex: 0,
  autoplayTimeout: 0,
  isDragInProgress: false,
  paginationLocation: PaginationLocation.CENTER,
});

const emits = defineEmits<FadeCarouselEmits>();

const {
  holder,
  onClick,
  onMouseEnter,
  onMouseLeave,
  carouselOptions,
  isPointsPagination,
  moveToSlide,
} = useVFadeCarousel(props, emits);

defineExpose<FadeCarouselRef>({ moveToSlide });
</script>

<template>
  <div v-auto-id="'VFadeCarousel'"
    ref="holder"
    class="swiper"
    :class="$style['fade-carousel']"
    @click="onClick"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <slot
      :options="carouselOptions"
    />
    <slot
      v-if="carouselOptions.slidesCount > 1"
      name="pagination"
    >
      <VFadeCarouselPointsPagination
        v-if="isPointsPagination"
        :pagination-location="paginationLocation"
      />
    </slot>
  </div>
</template>

<style lang="scss" module>
.fade-carousel {
  position: relative;
  width: 100%;
  will-change: transform;
}
</style>
