import { RouteName } from '@leon-hub/routing-config-names';
import { HomePageType } from '@leon-hub/api-sdk';

import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useSportlineLayoutSettings } from 'web/src/modules/sportline/composables/settings';
import type { AppVueRouter } from 'web/src/modules/core/services/router/types';
import { AppModule } from 'web/src/modules/core/plugins/AppModule';
import {
  getSportlineNavigationRouteComponent,
  getSportlineNavigationLoaderRouteComponent,
  getSportlineDetailsPageNavigationRouteComponent,
} from 'web/src/modules/sportline/submodules/navigation/SportlineNavigationSubmodule';
import {
  getSportlineNavigationLeftSidebarRouteComponent,
} from 'web/src/modules/sportline/submodules/sidebar/SportlineSidebarSubmodule';
import { ModalDesktopPreset } from 'web/src/modules/core/enums';

export { SportlineSportsModule } from './submodules/sports/SportlineSportsModule';
export { CybersportModule } from './submodules/cybersport/CybersportModule';

export class SportlineModule extends AppModule {
  // eslint-disable-next-line class-methods-use-this,sonarjs/cognitive-complexity
  addRoutes(router: AppVueRouter): void {
    const {
      isBetSwipeTopEventsLayoutEnabled,
      isBetSwipeNavigationLayoutEnabled,
    } = useSportlineLayoutSettings();

    // shared components
    const navigation = getSportlineNavigationRouteComponent();
    const leftSideBar = getSportlineNavigationLeftSidebarRouteComponent();
    const contentLoader = getSportlineNavigationLoaderRouteComponent();

    router.addDefaultRoute({
      name: RouteName.SPORTLINE_LIVE_EVENTS,
      path: '/live/:family?',
      prefetch: () => import('web/src/modules/sportline/submodules/live/prefetch/LivePagePrefetch'),
      component: () => import('web/src/modules/sportline/submodules/live/views/LiveRoutePage.vue'),
      navigation,
      leftSideBar,
      contentLoader,
      topAuthorizedSlot: () => import('web/src/modules/referral-program/views/ReferralProgramPopup/ReferralProgramPopup.vue'),
    });

    router.addDefaultRoute({
      name: RouteName.SPORT_REGION,
      path: '/bets/:sportFamily/:id-:urlName',
      prefetch: () => import('web/src/modules/sportline/submodules/region/prefetch/RegionPagePrefetch'),
      component: () => import('web/src/modules/sportline/submodules/region/views/RegionRoutePage.vue'),
      topAuthorizedSlot: () => import('web/src/modules/referral-program/views/ReferralProgramPopup/ReferralProgramPopup.vue'),
      navigation,
      leftSideBar,
      contentLoader,
    });

    router.addDefaultRoute({
      name: RouteName.SPORT_LEAGUE,
      path: '/bets/:sportFamily/:regionUrlName/:id-:urlName',
      prefetch: () => import('web/src/modules/sportline/submodules/league/prefetch/LeaguePagePrefetch'),
      component: () => import('web/src/modules/sportline/submodules/league/views/LeagueRoutePage.vue'),
      topAuthorizedSlot: () => import('web/src/modules/referral-program/views/ReferralProgramPopup/ReferralProgramPopup.vue'),
      navigation,
      leftSideBar,
      contentLoader,
    });

    if (process.env.VUE_APP_FEATURE_SPORTLINE_BET_SWIPE_LAYOUT_ENABLED && isBetSwipeNavigationLayoutEnabled) {
      // with RouteName.SPORT_LEAGUE canonical
      router.addDefaultRoute({
        name: RouteName.SPORTLINE_TOP_LEAGUE,
        path: '/top/:sportFamily/:regionUrlName/:id-:urlName',
        prefetch: () => import('web/src/modules/sportline/submodules/league/prefetch/LeaguePagePrefetch'),
        component: () => import('web/src/modules/sportline/submodules/league/views/LeagueRoutePage.vue'),
        topAuthorizedSlot: () => import('web/src/modules/referral-program/views/ReferralProgramPopup/ReferralProgramPopup.vue'),
        navigation,
        leftSideBar,
        contentLoader,
      });

      // with RouteName.SPORT_LEAGUE canonical
      router.addDefaultRoute({
        name: RouteName.SPORTLINE_LIVE_LEAGUE,
        path: '/live/:sportFamily/:regionUrlName/:id-:urlName',
        prefetch: () => import('web/src/modules/sportline/submodules/league/prefetch/LeaguePagePrefetch'),
        component: () => import('web/src/modules/sportline/submodules/league/views/LeagueRoutePage.vue'),
        topAuthorizedSlot: () => import('web/src/modules/referral-program/views/ReferralProgramPopup/ReferralProgramPopup.vue'),
        navigation,
        leftSideBar,
        contentLoader,
      });
    }

    if (process.env.VUE_APP_PLATFORM_CORDOVA) {
      router.addModalRoute({
        name: RouteName.SPORT_EVENT_DETAILS,
        path: '/bets/:sportFamily/:regionUrlName/:leagueUrlName/:id-:urlName',
        prefetch: () => import('web/src/modules/sportline/submodules/event-details/prefetch/SportEventDetailsPagePrefetch'),
        component: () => import('web/src/modules/sportline/submodules/event-details/views/SportlineEventDetailsRoutePage.vue'),
        topBar: () => import('web/src/modules/sportline/submodules/cordova/views/SportEventDetailsCordovaTopBarRouteComponent/SportEventDetailsCordovaTopBarRouteComponent.vue'),
        meta: {
          resetScrollPosition: true,
        },
        isTabBarVisible: true,
        isFooterVisible: true,
      });
    } else {
      router.addDefaultRoute({
        name: RouteName.SPORT_EVENT_DETAILS,
        path: '/bets/:sportFamily/:regionUrlName/:leagueUrlName/:id-:urlName',
        prefetch: () => import('web/src/modules/sportline/submodules/event-details/prefetch/SportEventDetailsPagePrefetch'),
        component: () => import('web/src/modules/sportline/submodules/event-details/views/SportlineEventDetailsRoutePage.vue'),
        fixedBar: () => import('web/src/modules/sportline/submodules/event-details/views/SportlineEventDetailsFixedBarRouteComponent.vue'),
        topAuthorizedSlot: () => import('web/src/modules/referral-program/views/ReferralProgramPopup/ReferralProgramPopup.vue'),
        navigation: getSportlineDetailsPageNavigationRouteComponent(),
        leftSideBar: undefined,
        contentLoader,
        meta: {
          resetScrollPosition: true,
        },
      });
    }

    router.addDefaultRoute({
      name: RouteName.SPORTRADAR_STATISTICS_WIDGET,
      path: '/statistics',
      prefetch: () => import('web/src/modules/sportline/submodules/widgets/prefetch/SportradarStatisticsWidgetPrefetch'),
      component: () => import('web/src/modules/sportline/submodules/widgets/pages/SportradarWidgetRoutePage/SportradarWidgetRoutePage.vue'),
      navigation,
      leftSideBar,
      contentLoader,
      props: {
        type: 'statistics',
      },
    });

    router.addDefaultRoute({
      name: RouteName.SPORTRADAR_LIVESCORE_WIDGET,
      path: '/livescore',
      prefetch: () => import('web/src/modules/sportline/submodules/widgets/prefetch/SportradarLiveScoreWidgetPrefetch'),
      component: () => import('web/src/modules/sportline/submodules/widgets/pages/SportradarWidgetRoutePage/SportradarWidgetRoutePage.vue'),
      navigation,
      leftSideBar,
      contentLoader,
      props: {
        type: 'livescore',
      },
    });

    if (process.env.VUE_APP_FEATURE_VIRTUAL_SPORT_ENABLED) {
      router.addDefaultRoute({
        name: RouteName.SPORTLINE_VIRTUAL_SPORT,
        path: '/virtual-sport/:sport?',
        prefetch: () => import('web/src/modules/sportline/submodules/virtual-sport/prefetch/VirtualSportPagePrefetch'),
        component: () => import('web/src/modules/sportline/submodules/virtual-sport/views/VirtualSportRoutePage.vue'),
        // eslint-disable-next-line max-len
        seoController: () => import('web/src/modules/sportline/submodules/virtual-sport/seo/VirtualSportPageSeoController'),
        navigation,
        leftSideBar,
        contentLoader,
      });
    }

    if (process.env.VUE_APP_FEATURE_CASINO_ENABLED) {
      const { homePageType } = useSiteConfigStore();

      if (homePageType !== HomePageType.SPORTS) {
        router.addDefaultRoute({
          name: RouteName.SPORTLINE_TOP_EVENTS,
          path: '/top',
          prefetch: () => import('web/src/modules/home/submodules/sportline/prefetch/HomePagePrefetch'),
          component: process.env.VUE_APP_FEATURE_SPORTLINE_BET_SWIPE_LAYOUT_ENABLED && isBetSwipeTopEventsLayoutEnabled.value
            ? () => import('web/src/modules/home/submodules/sportline/views/CardEventsHomeRoutePage.vue')
            : () => import('web/src/modules/home/submodules/sportline/views/HomeRoutePage.vue'),
          navigation,
          leftSideBar,
          contentLoader,
          topAuthorizedSlot: homePageType === HomePageType.CUSTOM
            ? () => import('web/src/modules/referral-program/views/ReferralProgramPopup/ReferralProgramPopup.vue')
            : undefined,
        });
      } else {
        router.addBlankRoute({
          name: RouteName.SPORTLINE_TOP_EVENTS,
          path: '/top/:family?',
          component: () => import('web/src/modules/errors/pages/RedirectSeoRouteComponent/RedirectSeoRouteComponent.vue'),
        });
      }
    } else {
      router.addBlankRoute({
        name: RouteName.SPORTLINE_TOP_EVENTS,
        path: '/top/:family?',
        component: () => import('web/src/modules/errors/pages/RedirectSeoRouteComponent/RedirectSeoRouteComponent.vue'),
      });
    }

    if (process.env.VUE_APP_FEATURE_SPORTLINE_ODDS_SELECTOR_ENABLED) {
      router.addModalRoute({
        name: RouteName.SPORTLINE_SELECT_ODDS,
        path: '/odds',
        title: '{{$t(\'WEB2_ODDS\')}}',
        component: () => import('web/src/modules/sportline/submodules/odds/views/OddsRoutePage.vue'),
        modalPreset: process.env.VUE_APP_LAYOUT_DESKTOP ? ModalDesktopPreset.SmallDesktopModal : undefined,
      });
    }
  }
}
