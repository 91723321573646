<script lang="ts" setup>
import { PieTimerSize } from './enums';

interface VPieTimerProps {
  percentage?: number;
  size?: PieTimerSize;
}

const props = withDefaults(defineProps<VPieTimerProps>(), {
  percentage: 0,
  size: PieTimerSize.DEFAULT,
});
</script>

<template>
  <svg v-auto-id="'VPieTimer'"
    :class="{
      [$style['timer__wrapper']]: true,
      [$style[`timer__wrapper--size-${props.size}`]]: size !== PieTimerSize.DEFAULT,
    }"
    viewBox="0 0 20 20"
  >
    <circle
      :class="{
        [$style['timer__counter']]: true,
        [$style['timer__counter--danger']]: props.percentage <= 20,
      }"
      :style="{ 'stroke-dasharray': ((percentage * 31.4) / 100) + ' 31.4' }"
      r="5"
      cx="10"
      cy="10"
      transform="rotate(-90) translate(-20)"
    />
  </svg>
</template>

<style lang="scss" module>
$sizes: (
  small: 16px,
  default: 18px,
);

.timer {
  &__wrapper {
    width: map-get($sizes, default);
    height: map-get($sizes, default);
    transform: scaleX(-1);

    @each $name, $size in $sizes {
      @if $name != default {
        &--size-#{$name} {
          width: $size;
          height: $size;
        }
      }
    }
  }

  &__counter {
    fill: transparent;
    stroke: var(--TextSecondary);
    stroke-width: 10;
    transition: stroke-dasharray 1s, stroke 1s;
    transition-timing-function: linear;

    &--danger {
      stroke: var(--ErrorText);
    }
  }
}
</style>
