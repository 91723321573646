<script lang="ts" setup>
import {
  onMounted,
  useSlots,
} from 'vue';

import VPopper from 'web/src/components/Popper/VPopper/VPopper.vue';
import { PopperPosition } from 'web/src/components/Popper/VPopper/enums';

import type {
  VBreadcrumbEmits,
  VBreadcrumbProps,
  VBreadcrumbSlots,
} from './types';
import { BreadcrumbType } from './enums';
import { useVBreadcrumb } from './composables';
import VCrumb from './components/VCrumb.vue';

const props = withDefaults(defineProps<VBreadcrumbProps>(), {
  type: BreadcrumbType.DEFAULT,
  position: 0,
  to: null,
});
const emit = defineEmits<VBreadcrumbEmits>();
defineSlots<VBreadcrumbSlots>();

const slots = useSlots();
const {
  popper,
  popperModifiers,
  isOpen,
  toggleOpen,
  forceHide,
} = useVBreadcrumb(props);

/**
 * @TODO Popper created for Breadcrumb without dropdown
 * @TODO Popper content does not recreated without key on Breadcrumb element
 */

function toggle(): void {
  if (props.controlled) {
    emit('toggle');
    return;
  }
  toggleOpen();
}

function onPopperHide(): void {
  if (isOpen.value) {
    toggle();
  }
}

onMounted((): void => {
  if (props.open) {
    toggle();
  }
});
</script>

<template>
  <li v-auto-id="'VBreadcrumb'"
    :class="{
      [$style['breadcrumb']]: true,
      [$style['breadcrumb--open']]: isOpen,
      [$style[`breadcrumb--type-${type}`]]: type !== BreadcrumbType.DEFAULT,
    }"
  >
    <template v-if="!canExpand">
      <VCrumb
        :to="to"
        :title="title"
        :is-prevented="!!slots.default"
        :icon-name="icon"
        :type="type"
      >
        <template
          v-if="slots.icon"
          #icon
        >
          <slot name="icon" />
        </template>
      </VCrumb>

      <meta
        v-if="position"
        itemprop="position"
        :content="String(position)"
      >
    </template>
    <VPopper
      v-else
      ref="popper"
      :position="PopperPosition.BOTTOM"
      is-full-width
      is-full-height
      :apply-target="applyTarget"
      :modifiers="popperModifiers"
      :has-offset="false"
      @hide="onPopperHide"
    >
      <template #reference>
        <VCrumb
          :to="to"
          :title="title"
          :is-prevented="!!slots.default"
          :is-open="isOpen"
          :can-expand="canExpand"
          :icon-name="icon"
          :type="type"
          @toggle="toggle"
        >
          <template
            v-if="slots.icon"
            #icon
          >
            <slot name="icon" />
          </template>
        </VCrumb>

        <meta
          v-if="position"
          itemprop="position"
          :content="String(position)"
        >
      </template>

      <div
        v-if="!!slots.default"
        v-show="isOpen"
        :class="{
          [$style['breadcrumb__content']]: true,
          [$style['breadcrumb__content--sub-menu']]: subMenu,
        }"
      >
        <slot
          :is-open="isOpen"
          :toggle="toggle"
          :force-hide="forceHide"
        />
      </div>
      <div v-else />
    </VPopper>
  </li>
</template>

<style module lang="scss">
.breadcrumb {
  $self: &;

  user-select: none;

  &__content {
    position: absolute;
    top: 8px;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: calc(100% + 40px);
    background-color: var(--OpacityLayer0);

    &--sub-menu {
      @include regular\13\16;
      @include z-index(absolute-item);

      position: absolute;
      top: 0;
      right: 0;
      bottom: auto;
      left: 0;
      width: 200px;
      height: auto;
      margin-top: 4px;
      overflow: hidden;
      color: var(--Layer1);
      background-color: var(--TextDefault);
      border-radius: 0 5px 5px;
    }
  }

  &--type-menu {
    position: relative;

    #{$self}__content {
      @include z-index(absolute-item);

      position: absolute;
      top: 0;
      bottom: auto;
      left: 0;
      width: 200px;
      height: auto;
      margin-top: 4px;
      overflow: hidden;
      background-color: var(--TextDefault);
      border-radius: 0 5px 5px;
    }
  }
}
</style>
