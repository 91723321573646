import type { GetLogoOptions } from './types';
import getLogoName from './getLogoName';

export default function getLogoUrl(options: GetLogoOptions = {}): string {
  if (process.env.VUE_APP_FEATURE_LOGO_ESPORT_ENABLED && options.product === 'leonesport') {
    return require(`web/src/assets/logotypes/${process.env.VUE_APP_PRODUCT}/${process.env.VUE_APP_SKIN}/brand-esport/${getLogoName(options)}.svg`);
  }

  if (process.env.VUE_APP_FEATURE_LOGO_NY_ENABLED) {
    return require(`web/src/assets/logotypes/${process.env.VUE_APP_PRODUCT}/${process.env.VUE_APP_SKIN}/brand-new-year/${getLogoName(options)}.svg`);
  }

  return require(`web/src/assets/logotypes/${process.env.VUE_APP_PRODUCT}/${process.env.VUE_APP_SKIN}/brand-default/${getLogoName(options)}.svg`);
}
