// TODO: Add TOP_LEFT, TOP_RIGHT, BOTTOM_LEFT and BOTTOM_RIGHT directions
// TODO: Add conventional NORTH_EAST, NORTH_WEST, SOUTH_EAST and SOUTH_WEST aliases
export const IconDirection = {
  NORTH: 'north',
  EAST: 'east',
  SOUTH: 'south',
  WEST: 'west',
  UP: 'north',
  RIGHT: 'east',
  DOWN: 'south',
  LEFT: 'west',
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type IconDirection = typeof IconDirection[keyof typeof IconDirection];
