type Listener<T extends unknown[]> = (...args: T) => void;

// Tuple-based event signature
type EventMap<T=unknown> = [T, unknown[]][];
type UnknownEvents = [unknown, unknown][];
type ExtractEventPayload<T extends UnknownEvents, K> = Extract<T[number], [K, unknown[]]>[1];
type ExtractEventListener<T extends UnknownEvents, K> =
    Extract<T[number], [K, unknown[]]>[1] extends []
      ? Listener<[]>
      : Listener<Extract<T[number], [K, unknown[]]>[1]>;

type ExtractEmitArgs<T extends UnknownEvents, K> = ExtractEventPayload<T, K> extends []
  ? [K]
  : [K, ...ExtractEventPayload<T, K>];

export class AppEmitter<T extends EventMap = EventMap> {
  private connections: AppEmitter<T>[] = [];

  private listeners = new Map<T[number][0], Listener<T[number][1]>[]>();

  on<K extends T[number][0]>(
    eventName: K,
    callback: ExtractEventListener<T, K>,
  ): void {
    if (!this.listeners.has(eventName)) {
      this.listeners.set(eventName, []);
    }
    const currentListeners = this.listeners.get(eventName);
    currentListeners?.push(callback);
  }

  once<K extends T[number][0]>(
    eventName: K,
    callback: ExtractEventListener<T, K>,
  ): void {
    const onceWrapper = (...args: Extract<T[number], [K, unknown[]]>[1]) => {
      callback(...args);
      this.off(eventName, onceWrapper);
    };
    this.on(eventName, onceWrapper);
  }

  off(): void;
  off<K extends T[number][0]>(eventName: K, callback?: Function): void;
  off<K extends T[number][0]>(eventName?: K, callback?: Function): void {
    if (eventName === undefined) {
      this.listeners.clear();
    } else if (callback) {
      const currentListeners = this.listeners.get(eventName);
      if (currentListeners) {
        this.listeners
          .set(eventName, currentListeners.filter((listener) => listener !== callback));
      }
    } else {
      this.listeners.delete(eventName);
    }
  }

  emit<K extends T[number][0]>(
    ...rest: ExtractEmitArgs<T, K>
  ): void {
    this.emitFrom([], rest[0], ...rest.slice(1));
  }

  protected emitFrom<K extends T[number][0]>(
    sourceEmitter: AppEmitter[],
    eventName: K,
    ...args: ExtractEventPayload<T, K>
  ): void {
    if (sourceEmitter.includes(this)) return;
    const currentListeners = this.listeners.get(eventName);
    if (currentListeners) {
      for (const listener of currentListeners) {
        listener(...args);
      }
    }
    for (const connection of this.connections) {
      connection.emitFrom([...sourceEmitter, this], eventName, ...args);
    }
  }

  detectCycle(node: AppEmitter, state: AppEmitter[]): boolean {
    const index = state.indexOf(node);

    // If node is found and it's not a direct connection
    if (index !== -1 && state.length - index > 2) {
      return true; // Cycle detected
    }

    if (index === -1) {
      state.push(node);

      for (const neighbor of node.connections) {
        if (this.detectCycle(neighbor, state)) {
          return true;
        }
      }

      // Backtrack
      state.pop();
    }

    return false;
  }

  connect(emitter: AppEmitter<T>): void {
    if (this === emitter) throw new Error('Unable to connect an emitter to itself');

    if (this !== emitter) {
      this.connections.push(emitter);
      emitter.connections.push(this);
    }

    if (this.detectCycle(this, [])) throw new Error('Cycle has been detected');
  }

  protected setConnections(connections: AppEmitter<T>[]): void {
    this.connections = connections;
  }

  disconnect(emitter: AppEmitter<T>): void {
    this.setConnections(
      this.connections.filter((item) => emitter !== item),
    );
    emitter.setConnections(
      emitter.connections.filter((item) => this !== item),
    );
  }
}
