import type { Ref } from 'vue';
import { computed, reactive } from 'vue';

import type { Optional } from '@leon-hub/types';

import type { SportlineEventDetails } from 'web/src/modules/sportline/types/details';

import type { ExtendedEventsId } from '../types';

type EventDetailsMap = Record<ExtendedEventsId, Maybe<SportlineEventDetails>>;

interface EventDetailsRefsMap {
  setEventDetailsData(id: ExtendedEventsId, value: Maybe<SportlineEventDetails>): void;
  createEventDetailsRef(id: Ref<ExtendedEventsId>): Ref<Maybe<SportlineEventDetails>>;
}

export function createEventDetailsRefsMap(): EventDetailsRefsMap {
  // @TODO clear cache or get from the Global storage
  const extendedEventsMap = reactive<EventDetailsMap>({});

  return {
    setEventDetailsData(id: ExtendedEventsId, value: Maybe<SportlineEventDetails>): void {
      extendedEventsMap[id] = value;
    },
    createEventDetailsRef(id: Ref<Optional<ExtendedEventsId>>): Ref<Maybe<SportlineEventDetails>> {
      return computed<Maybe<SportlineEventDetails>>(() => {
        const { value } = id;
        if (!value) return null;
        return extendedEventsMap[value] ?? null;
      });
    },
  };
}
