<script lang="ts" setup>
import type { AffiliateCustomerCardEmits } from 'web/src/modules/affiliates/components/AffiliateCustomerCard/types';

const emits = defineEmits<AffiliateCustomerCardEmits>();
</script>

<template>
  <div v-auto-id="'AffiliateCustomerCard'"
    :class="$style['affiliate-customer-card']"
    @click="emits('click')"
  >
    <div
      :class="$style['affiliate-customer-card__inner']"
    >
      <div :class="$style['general-info']">
        <span
          :class="{
            [$style['bonus-code__top-text']]: true,
            [$style['affiliate-customer-card__top-left']]: true,
          }"
        >
          <slot name="top-left" />
        </span>
        <span
          :class="{
            [$style['bonus-code__bottom-text']]: true,
            [$style['affiliate-customer-card__bottom-left']]: true,
          }"
        >
          <slot name="bottom-left" />
        </span>
      </div>
      <div :class="$style['bonus-code']">
        <span
          :class="{
            [$style['bonus-code__top-text']]: true,
            [$style['affiliate-customer-card__top-right']]: true,
          }"
        >
          <slot name="top-right" />
        </span>
        <span
          :class="{
            [$style['bonus-code__bottom-text']]: true,
            [$style['affiliate-customer-card__bottom-right']]: true,
          }"
        >
          <slot name="bottom-right" />
        </span>
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>
.affiliate-customer-card {
  width: 100%;
  padding: 12px;
  margin-bottom: 8px;
  vertical-align: middle;
  background-color: var(--Layer1);
  border-radius: 5px;

  &__bottom-left {
    @include medium\14\20\025;

    color: var(--TextDefault);
  }

  &__top-right {
    @include regular\12\16;

    color: var(--TextSecondary);
  }

  &__top-left {
    @include medium\12\16;

    color: var(--TextSecondary);
  }

  &__bottom-right {
    @include medium\14\20\025;

    color: var(--BrandDefault);
  }

  .with-margin {
    margin-right: 24px;
  }

  .with-space {
    margin-right: 2px;
  }

  &__inner {
    display: flex;
    justify-content: space-between;

    .general-info {
      display: flex;
      flex-direction: column;
    }

    .bonus-code {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      &__top-text {
        margin-bottom: 4px;
        line-height: 16px;
      }

      &__bottom-text {
        line-height: 16px;
        letter-spacing: 0.3px;
      }
    }
  }
}
</style>
