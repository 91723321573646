export default function getSafePercentage(
  value: number,
  max = 100,
  min = 0,
): number {
  if (max - min <= 0) { return 0; }
  const shift = value - min;
  if (shift <= 0) { return 0; }
  return Math.min((100 / (max - min)) * shift, 100);
}
