import type {
  VHeadlineBannerLinkProps,
  VHeadlineBannerProps,
  VHeadlineBannerWrapperProps,
} from '../../types';

export default function getWrapperProps(
  isButtonAvailable: boolean,
  props: VHeadlineBannerProps,
  linkProps: VHeadlineBannerLinkProps,
): VHeadlineBannerWrapperProps | object {
  if (isButtonAvailable || props.isDivContainer) {
    return {};
  }

  return {
    ...linkProps,
    draggable: false,
  };
}
