import type { Ref } from 'vue';
import { computed } from 'vue';

import { IconName, IconSize } from '@leon-hub/icons';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';

import type { VIconProps } from '@components/v-icon';

import { useAnalytics } from 'web/src/modules/analytics/composables';

import type { ProfileBonusesCardFooterProps } from '../types';

export interface ProfileBonusesCardFooterComposable {
  iconProps: Readonly<VIconProps>;
  stepNumberString: Ref<string>;
  stepCountString: Ref<string>;
  handleTermsLinkClick(): void;
}

export default function useProfileBonusesCardFooter(
  props: ProfileBonusesCardFooterProps,
): ProfileBonusesCardFooterComposable {
  const analytics = useAnalytics();

  const iconProps: VIconProps = {
    name: IconName.QUESTION_OUTLINE,
    size: IconSize.SIZE_16,
  };

  const stepNumberString = computed(() => (props.stepNumber ? props.stepNumber.toString() : ''));
  const stepCountString = computed(() => (props.stepCount ? props.stepCount.toString() : ''));

  function handleTermsLinkClick(): void {
    analytics.push(AnalyticsEvent.CLICK_MAP, {
      clickCounter: {
        bonuses: 'bonusConditions',
      },
    });
  }

  return {
    iconProps,
    stepNumberString,
    stepCountString,
    handleTermsLinkClick,
  };
}
