<script setup lang="ts">
import { VCoreDropdown } from '@components/core-dropdown';

import { DropdownMenu } from 'web/src/components/Select';
import { useDropdownSelect } from 'web/src/components/Select/composables';
import type { DropdownSelectEmits } from 'web/src/components/Select';

import type { BetSlipDesktopDropdownProps } from './types';

const props = withDefaults(defineProps<BetSlipDesktopDropdownProps>(), {
  name: 'SlipDesktopDropdown',
  options: () => [],
  dropdownMinWidth: 0,
  selectedValue: '',
});

const emits = defineEmits<DropdownSelectEmits>();

const {
  isDropdownShown,
  onFocus,
  onChange,
  onBlur,
  close,
  toggleOpen,
  focusOnDropdownButton,
  dropdownProps,
  dropdownButton,
  optionsToRender,
  handleKeyDown,
  setPreselectedIndex,
  preselectedListIndex,
} = useDropdownSelect(props, emits);

defineExpose({
  focus: focusOnDropdownButton,
});
</script>

<template>
  <div v-auto-id="'BetSlipDesktopDropdown'" class="bet-slip-desktop-system-dropdown">
    <button
      ref="dropdownButton"
      type="button"
      :class="$style['bet-slip-desktop-system-dropdown__button']"
      @focus="onFocus"
      @blur="onBlur"
      @click="toggleOpen"
      @keydown="handleKeyDown"
    />
    <template v-if="isDropdownShown">
      <div
        :class="$style['bet-slip-desktop-system-dropdown__mask']"
        @click.stop
      />
      <VCoreDropdown
        v-if="dropdownProps"
        v-bind="dropdownProps"
        match-parent-width
        :ignore-scroll="ignoreScroll"
        @close="close"
      >
        <template #default="{maxHeight, directionTop, mounted, onContentRedraw}">
          <DropdownMenu
            :name="name"
            :class="$style['bet-slip-desktop-system-dropdown__drop']"
            :max-height="maxHeight"
            :direction-top="directionTop"
            :is-freebet="isFreebet"
            :selected-value="selectedValue"
            :options="optionsToRender"
            :preselected-list-index="preselectedListIndex"
            forced-light-theme
            @change="onChange"
            @close="close"
            @mounted="mounted"
            @option-hover="setPreselectedIndex"
            @list-size-updated="onContentRedraw"
          />
        </template>
      </VCoreDropdown>
    </template>
  </div>
</template>

<style lang="scss" module>
.bet-slip-desktop-system-dropdown {
  &__button {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    appearance: none;
    cursor: pointer;
    background-color: transparent;
    border: none;
    opacity: 0;
  }

  &__mask {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    cursor: pointer;
  }
}
</style>
