<script setup lang="ts">
import { ref } from 'vue';

import { IconSize } from '@leon-hub/icons';
import { useIntersectionObserver } from '@leon-hub/vue-utils';

import type { SSpinnerProps } from '../../types';

withDefaults(defineProps<SSpinnerProps>(), {
  kind: 'primary',
  size: IconSize.SIZE_20,
});

const element = ref<HTMLElement>();
const isAnimated = ref(false);

useIntersectionObserver(element, (isVisible: boolean) => {
  isAnimated.value = isVisible;
});
</script>

<template>
  <svg v-auto-id="'SSpinner'"
    ref="element"
    viewBox="0 0 240 240"
    xmlns="http://www.w3.org/2000/svg"
    :class="{
      [$style['sspinner']]: true,
      [$style[`sspinner--${kind}`]]: kind,
      [$style[`sspinner--${size}`]]: size,
      [$style[`sspinner--animated`]]: isAnimated,
    }"
  >
    <circle
      :class="[
        $style['sspinner__circle'],
        $style['sspinner__circle--small'],
        $style['sspinner__circle--circle1'],
      ]"
    />
    <circle
      :class="[
        $style['sspinner__circle'],
        $style['sspinner__circle--small'],
        $style['sspinner__circle--circle2'],
      ]"
    />
    <circle
      :class="[
        $style['sspinner__circle'],
        $style['sspinner__circle--small'],
        $style['sspinner__circle--circle3'],
      ]"
    />
    <circle
      :class="[
        $style['sspinner__circle'],
        $style['sspinner__circle--big'],
        $style['sspinner__circle--circle4'],
      ]"
    />
    <circle
      :class="[
        $style['sspinner__circle'],
        $style['sspinner__circle--big'],
        $style['sspinner__circle--circle5'],
      ]"
    />
    <circle
      :class="[
        $style['sspinner__circle'],
        $style['sspinner__circle--big'],
        $style['sspinner__circle--circle6'],
      ]"
    />
  </svg>
</template>

<style module lang="scss">
.sspinner {
  @each $name, $size in $spinnerSizeProps {
    &--#{$name} {
      width: $size;
      height: $size;
    }
  }

  @each $kind, $typeFill in $spinnerKindProps {
    &--#{$kind} {
      fill: get($typeFill, 'fill');
    }
  }

  &--animated {
    .sspinner__circle {
      animation-duration: 1.5s;
      animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
      animation-iteration-count: infinite;
    }
  }

  &__circle {
    transform-origin: center center;

    &--small {
      r: 16px;
    }

    &--big {
      r: 23px;
    }

    &--circle1 {
      transform: translateX(52px) translateY(164px);
      animation-name: circle1, change-size-small-big;
    }

    &--circle2 {
      transform: translateX(122px) translateY(43px);
      animation-name: circle2, change-size-small-big;
    }

    &--circle3 {
      transform: translateX(192px) translateY(164px);
      animation-name: circle3, change-size-small-big;
    }

    &--circle4 {
      transform: translateX(52px) translateY(83px);
      animation-name: circle4, change-size-big-small;
    }

    &--circle5 {
      transform: translateX(122px) translateY(204px);
      animation-name: circle5, change-size-big-small;
    }

    &--circle6 {
      transform: translateX(192px) translateY(83px);
      animation-name: circle6, change-size-big-small;
    }
  }

  @keyframes change-size-small-big {
    0%,
    100% {
      r: 16px;
    }

    50% {
      r: 23px;
    }
  }

  @keyframes change-size-big-small {
    0%,
    100% {
      r: 23px;
    }

    50% {
      r: 16px;
    }
  }

  @keyframes circle1 {
    0% {
      transform: translateX(52px) translateY(164px);
    }

    50%,
    100% {
      transform: translateX(192px) translateY(164px);
    }
  }

  @keyframes circle2 {
    0% {
      transform: translateX(122px) translateY(43px);
    }

    50%,
    100% {
      transform: translateX(52px) translateY(164px);
    }
  }

  @keyframes circle3 {
    0% {
      transform: translateX(192px) translateY(164px);
    }

    50%,
    100% {
      transform: translateX(122px) translateY(43px);
    }
  }

  @keyframes circle4 {
    0%,
    50% {
      transform: translateX(52px) translateY(83px);
    }

    100% {
      transform: translateX(122px) translateY(204px);
    }
  }

  @keyframes circle5 {
    0%,
    50% {
      transform: translateX(122px) translateY(204px);
    }

    100% {
      transform: translateX(192px) translateY(83px);
    }
  }

  @keyframes circle6 {
    0%,
    50% {
      transform: translateX(192px) translateY(83px);
    }

    100% {
      transform: translateX(52px) translateY(83px);
    }
  }
}
</style>
