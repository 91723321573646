<script lang="ts" setup>
import { onActivated, onDeactivated, onBeforeMount } from 'vue';

import { RouteName } from '@leon-hub/routing-config-names';

import { useSeoMetaParameters } from 'web/src/modules/seo/composables';
import { useSportlineLayoutSettings } from 'web/src/modules/sportline/composables/settings';
import { LeaguePageSectionId } from 'web/src/modules/sportline/submodules/league/enums';
import { VLoaderMaskedDelayed } from 'web/src/components/Loader';
import SportlineRedirectFromEmptyPageInfo
  from 'web/src/modules/sportline/submodules/redirects/views/SportlineRedirectFromEmptyPageInfo.vue';
import LeagueBreadcrumbs from 'web/src/modules/sportline/submodules/league/views/LeagueBreadcrumbs.vue';
import EmptyLeaguePageContentInfo
  from 'web/src/modules/sportline/submodules/league/components/EmptyLeaguePageContentInfo.vue';
import { useFavoritesEventsStore } from 'web/src/modules/sportline/submodules/favorites/store/events';
import SlicedTreeNavigation from 'web/src/modules/sportline/submodules/navigation/views/bet-swipe-navigation/SlicedTreeNavigation.vue';
import { useKeepAlive } from 'web/src/modules/core/composables/keep-alive';

import useLeagueRoutePage from './useLeagueRoutePage';
import LeaguePageStatistic from './LeaguePageStatistic.vue';
import LeagueDefaultPageHeadline from './default/LeaguePageHeadline.vue';
import LeagueDefaultEventsList from './default/LeagueDefaultEventsList.vue';
import LeagueBetSwipePageHeadline from './bet-swipe/LeaguePageHeadline.vue';
import LeagueBetSwipeEventsList from './bet-swipe/LeagueBetSwipeEventsList.vue';
import LargeLeagueToolbar from './toolbar/LargeLeagueToolbar.vue';
import SmallLeagueToolbar from './toolbar/SmallLeagueToolbar.vue';

const {
  isBetSwipeNavigationLayoutEnabled,
  isBetSwipeLeagueLayoutEnabled,
} = useSportlineLayoutSettings();
const {
  isReady,
  canDisplayAnything,
  hasEvents,
  sportElement,
  outrightEvents,
  isMarketTypesSelectionEnabled,
  isLeagueStatisticEnabled,
  isEventsTabAvailable,
  isStatisticTabAvailable,
  isPromotionsTabAvailable,
  regionName,
  leagueId,
  leagueName,
  sportId,
  isEventsListEmpty,
  metaParameters,
  activeSectionId,
  clickBack,
  onActivate,
  onDeactivate,
  selectSection,
} = useLeagueRoutePage();
useSeoMetaParameters(metaParameters);
const {
  initialRequests: initFavoriteSportEvents,
} = useFavoritesEventsStore();

onBeforeMount(() => {
  void initFavoriteSportEvents();
});
onActivated(() => {
  onActivate();
});
onDeactivated(() => {
  onDeactivate();
});

useKeepAlive([
  RouteName.HOME,
  RouteName.SPORTLINE_LIVE_EVENTS,
  RouteName.SPORTLINE_PREMATCH_EVENTS,
  RouteName.SPORT_REGION,
  RouteName.SPORT_LEAGUE,
  RouteName.SPORT_EVENT_DETAILS,
  RouteName.SPORTLINE_CYBERSPORT,
]);
</script>

<template>
  <div v-auto-id="'LeagueRoutePage'" class="component-wrapper">
    <div
      :class="{
        [$style['sport-event-league']]: true,
        [$style['sport-event-league--extra-paddings']]: null && isBetSwipeNavigationLayoutEnabled,
      }"
    >
      <SlicedTreeNavigation
        v-if="null && isBetSwipeNavigationLayoutEnabled"
        :class="$style['sport-event-league__navigation']"
      />
      <LeagueBreadcrumbs
        v-else-if="'1'"
        :default-region-title="regionName"
        :default-league-title="leagueName"
      />

      <div :class="$style['sport-event-league__content']">
        <VLoaderMaskedDelayed
          v-if="!isReady"
        />

        <template v-if="canDisplayAnything">
          <template v-if="null && isBetSwipeLeagueLayoutEnabled">
            <LeagueBetSwipePageHeadline
              :class="[
                [$style['sport-event-league__headline']],
                [$style['sport-event-league__headline--extra-paddings']],
              ]"
              :has-matches="isEventsTabAvailable"
              :has-promotions="isPromotionsTabAvailable"
              :has-statistic="isStatisticTabAvailable"
              :active-section-id="activeSectionId"
              @active-section-id-switched="selectSection"
            />
          </template>
          <template v-else>
            <LargeLeagueToolbar
              v-if="'1'"
              :region="regionName"
              :name="leagueName"
              :sport-id="sportId"
              :league-id="leagueId"
              @click-back="clickBack"
            />
            <SmallLeagueToolbar
              v-else
              :region="regionName"
              :name="leagueName"
              :sport-id="sportId"
              :league-id="leagueId"
              @click-back="clickBack"
            />

            <LeagueDefaultPageHeadline
              :class="$style['sport-event-league__headline']"
              :has-matches="isEventsTabAvailable"
              :has-promotions="isPromotionsTabAvailable"
              :has-statistic="isStatisticTabAvailable"
              :active-section-id="activeSectionId"
              @active-section-id-switched="selectSection"
            />
          </template>

          <template
            v-if="activeSectionId === LeaguePageSectionId.EventsList || process.env.VUE_APP_PRERENDER"
          >
            <SportlineRedirectFromEmptyPageInfo
              :class="$style['sport-event-league__redirect-info']"
            />

            <template v-if="!isEventsListEmpty">
              <LeagueBetSwipeEventsList
                v-if="null && isBetSwipeLeagueLayoutEnabled"
                :sport-element="sportElement"
                :outright-events="outrightEvents"
                :has-events="hasEvents"
              />
              <LeagueDefaultEventsList
                v-else
                :sport-element="sportElement"
                :outright-events="outrightEvents"
                :has-events="hasEvents"
                :is-market-types-selection-enabled="isMarketTypesSelectionEnabled"
              />
            </template>
            <EmptyLeaguePageContentInfo
              v-else
              :class="$style['sport-event-league__no-events']"
              :section-id="LeaguePageSectionId.EventsList"
              :is-league-statistic-enabled="isLeagueStatisticEnabled"
            />
          </template>

          <LeaguePageStatistic
            v-if="activeSectionId === LeaguePageSectionId.Statistic || process.env.VUE_APP_PRERENDER"
          />

          <template v-if="activeSectionId === LeaguePageSectionId.None">
            <div
              v-if="isLeagueStatisticEnabled"
              :class="$style['sport-event-league__content-title']"
            >
              {{ $t('WEB2_SPORTLINE_PAGE_SECTION_STATISTIC') }}
            </div>

            <EmptyLeaguePageContentInfo
              :section-id="LeaguePageSectionId.None"
              :is-league-statistic-enabled="isLeagueStatisticEnabled"
            />
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<style module lang="scss">
@import 'web/src/modules/sportline/styles/no-events';
@import 'web/src/modules/sportline/styles/sportline-page';

@include for-layout using ($isDesktop) {
  .sport-event-league {
    position: relative;
    padding: if($isDesktop, 0, 0 8px);

    &--extra-paddings {
      padding: if($isDesktop, 0, 0 16px);
    }

    &__content {
      position: relative;
    }

    &__headline {
      margin: if($isDesktop, 8px 0, 0);

      &--extra-paddings {
        margin: 16px 0 8px;
      }
    }

    &__navigation {
      @include sportline-page-full-width-block($isDesktop);

      margin-bottom: 8px;
    }

    &__redirect-info {
      margin-top: 8px;
    }

    &__no-events {
      margin-top: 8px;
    }

    &__content-title {
      @include medium\18\20;

      padding: 18px 0 18px 16px;
      color: var(--TextDefault);
    }
  }
}
</style>
