import { isObject } from '@leon-hub/guards';

import type {
  SeoMetaParameterConfig,
  SeoMetaParameterValue,
} from 'web/src/modules/seo/types';

export default function isSeoMetaParametersConfig<
  V = SeoMetaParameterValue,
>(value: unknown): value is SeoMetaParameterConfig<V> {
  return isObject(value) && ('value' in value);
}
