<script lang="ts" setup>
import ConfirmModal from 'web/src/modules/dialogs/views/ConfirmModal/ConfirmModal.vue';
import type { BaseModalEmits } from 'web/src/modules/dialogs/types';
import { useBaseModalActions } from 'web/src/modules/dialogs/composables';

import type { ConfirmDepositModalProps } from './types';

withDefaults(defineProps<ConfirmDepositModalProps>(), {});
const emit = defineEmits<BaseModalEmits>();

const { onClose, onButtonClick } = useBaseModalActions(emit);
</script>

<template>
  <ConfirmModal v-auto-id="'ConfirmDepositModal'"
    :modal="{
      ...modal,
      confirmMessage: undefined,
    }"
    :is-alert="isAlert"
    @close="onClose"
    @button-click="onButtonClick"
  >
    <span :class="[$style['deposit-modal--label']]">
      {{ modal.confirmMessage }}
    </span>
  </ConfirmModal>
</template>

<style lang="scss" module>
.deposit-modal {
  &--label {
    @include medium\24\28;

    display: block;
    margin: 0 0 20px;
  }

  &--label-phone {
    margin-bottom: 0;
  }
}
</style>
