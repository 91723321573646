import { CaptchaStatus, CaptchaType, RecaptchaTheme } from '@leon-hub/api-sdk';

import type { SiteConfigDocument } from '../types';

type DefaultCaptchaConfig = Omit<SiteConfigDocument['captcha'], 'captchaIframeUrl'> & {
  captchaIframeUrl: string;
};

export default function getDefaultCaptchaConfig(): DefaultCaptchaConfig {
  return {
    loginCaptchaStatus: CaptchaStatus.OFF,
    passwordRecoveryCaptchaStatus: CaptchaStatus.OFF,
    registrationCaptchaStatus: CaptchaStatus.OFF,
    loginCaptchaType: CaptchaType.RECAPTCHA,
    passwordRecoveryCaptchaType: CaptchaType.RECAPTCHA,
    registrationCaptchaType: CaptchaType.RECAPTCHA,
    captchaSecuritySignEnabled: false,
    useIframeForCaptcha: false,
    captchaApiUrl: '',
    captchaIframeUrl: '',
    recaptcha: {
      theme: RecaptchaTheme.LIGHT,
      siteKeys: [],
    },
    recaptchaV3: {
      loginEnabled: false,
      passwordRecoveryEnabled: false,
      registrationEnabled: false,
      siteKey: undefined,
      timeout: 10_000,
    },
  };
}
