import type { Component } from 'vue';

import VRouterLink from 'web/src/components/Link/VRouterLink/VRouterLink.vue';

import type { HintLink, HintLinkProps } from '../../../types';

const defaultLinkTag = 'a';

export const isNativeAnchor = (hintLink?: Maybe<HintLink>)
: boolean => !!(hintLink && typeof hintLink.href === 'string');

export const getHintLinkComponent = (isNative: boolean)
: string | Component => (isNative ? defaultLinkTag : VRouterLink);

export const getHintLinkProps = (isNative: boolean, hintLink?: Maybe<HintLink>)
: Partial<HintLinkProps> => {
  if (isNative) {
    return {
      href: hintLink?.href,
      target: hintLink?.target,
    };
  }
  return {
    to: hintLink?.href,
    target: hintLink?.target,
  };
};
