import type { FormatMoneyFunc } from 'web/src/modules/money/types';

import type { SlipEntryId } from '../../slip-info/types';
import type { MultiSinglesMetaInfo } from '../types';
import validateMultiSinglesMetaStake from './validateMultiSinglesMetaStake';

interface Payload {
  value: string;
  id: SlipEntryId;
  source: MultiSinglesMetaInfo;
  isFastBetValue?: boolean;
  formatMoney: FormatMoneyFunc;
}

export default function getUpdatedMultiSinglesStakeValue({
  value, id, source, isFastBetValue, formatMoney,
}: Payload): MultiSinglesMetaInfo {
  const matchedRecord = source[id];
  if (!matchedRecord) {
    return { ...source };
  }
  const copy = { ...source };
  copy[id] = validateMultiSinglesMetaStake({
    record: matchedRecord,
    newStakeValue: value,
    fastBetValue: isFastBetValue ? value : null,
    formatMoney,
  });
  return copy;
}
