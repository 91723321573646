import { SlipStatus } from '@leon-hub/api-sdk';

import getCombiSlipInfo from './getCombiSlipInfo';
import getSlipEntryId from '../../../utils/getSlipEntryId';
import isUnavailableEntryStatus from '../../../utils/isUnavailableEntryStatus';
import type { BatchedSlipInfoDocument, SlipEntryId, SlipInfoArray } from '../types';

export default function getCombiClearedFromBetById(input: BatchedSlipInfoDocument, id: SlipEntryId): SlipInfoArray {
  const currentCombiInfo = getCombiSlipInfo(input);
  if (!currentCombiInfo) {
    throw new Error('Unexpected combi slipState');
  }
  const clearedEntries = (currentCombiInfo?.entries || [])
    .filter((item) => item && getSlipEntryId(item) !== id);
  if (!clearedEntries.length) {
    // all entries was deleted
    return [];
  }
  const status = clearedEntries.some(({ marketStatus }) => isUnavailableEntryStatus(marketStatus))
    ? {}
    : { status: SlipStatus.OK };
  return [
    {
      ...currentCombiInfo,
      entries: clearedEntries,
      ...status,
    },
  ];
}
