<script lang="ts" setup>
import { onMounted, onUpdated } from 'vue';

import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { MultilineTextInput, TextInput } from 'web/src/components/Input';
import useVFeedBackComposition
  from 'web/src/components/FeedbackComposition/VFeedbackComposition/composables/useVFeedbackComposition';

import type VFeedbackCompositionEmitFormData from './types/VFeedbackCompositionEmitFormData';

interface VFeedbackCompositionProps {
  description?: string;
  feedbackFormMaxChars?: number;
  haveValidEmailValue?: boolean;
  submitting?: boolean;
  isClearTextarea?: boolean;
  isApiErrorEmailMessage?: string;
  savedErrorDuplicateEmail?: string;
}

interface VFeedbackCompositionEmit {
  (e: 'submit', data: VFeedbackCompositionEmitFormData): Promise<void>;
}

const props = defineProps<VFeedbackCompositionProps>();

const emit = defineEmits<VFeedbackCompositionEmit>();

const {
  comment,
  commentTextarea,
  emailValue,
  emailFieldError,
  submitButtonProperties,
  rowsMaxValue,
  rowsMinValue,
  onComponentMounted,
  onComponentUpdated,
  onEmailBlur,
  onEmailChange,
  onTextareaChange,
  getSubmitFormData,
} = useVFeedBackComposition(props);

onMounted(onComponentMounted);

onUpdated(onComponentUpdated);

function submit(): void {
  void emit('submit', getSubmitFormData());
}
</script>

<template>
  <div v-auto-id="'VFeedbackComposition'" :class="$style['feedback-legacy']">
    <div :class="$style['feedback-legacy__description']">
      <slot name="default">
        {{ description }}
      </slot>
    </div>

    <div>
      <MultilineTextInput
        ref="commentTextarea"
        name="feedback"
        :placeholder="$t('WEB2_YOUR_FEEDBACK_PLACEHOLDER')"
        :rows-min="rowsMinValue"
        :rows-max="rowsMaxValue"
        :max-length="feedbackFormMaxChars"
        :value="comment"
        @input="onTextareaChange"
      />
      <TextInput
        v-if="!haveValidEmailValue && '1'"
        :value="emailValue"
        name="emailInput"
        :placeholder="$t('WEB2_YOUR_FEEDBACK_EMAIL_PLACEHOLDER')"
        :label="$t('WEB2_EMAIL_INPUT_LABEL')"
        :error="emailFieldError"
        @blur="onEmailBlur"
        @input="onEmailChange"
      />
      <VButton
        v-data-test="{ el: 'feedback-legacy-button'}"
        :class="$style['feedback-legacy__submit']"
        v-bind="submitButtonProperties"
        @click="submit"
      />
    </div>
  </div>
</template>

<style lang="scss" module>
.feedback-legacy {
  &__description {
    @include feedBackCompositionFont;

    margin-bottom: 16px;
    color: $feedBackCompositionFontColor;
  }

  &__submit {
    margin-top: 16px;
  }
}
</style>
