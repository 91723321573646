<script lang="ts" setup>
import type { NavigationItem } from '@leon-hub/navigation-config';
import type { BaseRouteNameType } from '@leon-hub/routing-config';

import HeaderNavigationButton from './components/HeaderNaviagtionButton/HeaderNavigationButton.vue';
import { useHeaderNavigation } from './composables';

interface HeaderNavigationProps {
  navigationItems?: NavigationItem[];
  balanceValue?: string;
  isLogged?: boolean;
  isPromotionsHighlighted?: boolean;
  isLandingFestivalEnabled?: boolean;
  menuOverflowHidden?: boolean;
}

interface HeaderNavigationEmits {
  (e: 'set-parent-overflow-visible', isVisible: boolean): boolean;
  (e: 'sportline-navigation', to: BaseRouteNameType): BaseRouteNameType;
}

const properties = withDefaults(defineProps<HeaderNavigationProps>(), {
  navigationItems: () => ([]),
  balanceValue: '',
  // eslint-disable-next-line vue/no-boolean-default
  menuOverflowHidden: true,
});

const emit = defineEmits<HeaderNavigationEmits>();

const {
  container,
  baseList,
  navigationList,
  dropList,
  handleClick,
} = useHeaderNavigation(properties, emit);
</script>

<template>
  <nav v-auto-id="'HeaderNavigation'"
    ref="container"
    :class="{
      [$style['horizontal-navigation']]: true,
      [$style['horizontal-navigation--hide-overflow']]: menuOverflowHidden,
    }"
  >
    <ul
      ref="baseList"
      :class="$style['horizontal-navigation__base']"
    >
      <li
        v-for="{id, routeName, props, icon, iconCdnSrc, ...buttonProps} in navigationList"
        :key="id"
        :class="$style['horizontal-navigation__list-item']"
      >
        <HeaderNavigationButton
          v-data-test="{ el: 'header-navigation-button', id }"
          v-bind="buttonProps"
          :class="id"
          :icon="icon"
          :icon-cdn-src="iconCdnSrc"
          :is-promotions-highlighted="!!(props && props.isPromo && isPromotionsHighlighted)"
          :is-landing-euro="!!(props && props.isLandingEuro && isLandingFestivalEnabled)"
          @click="handleClick({id, routeName, props})"
        />
      </li>
      <li
        v-if="dropList.length > 0"
        :class="[
          $style['horizontal-navigation__list-item'],
          $style['horizontal-navigation__list-item--dropdown']
        ]"
      >
        <div
          :class="$style['horizontal-navigation__more']"
          @mouseover="emit('set-parent-overflow-visible', true)"
          @mouseleave="emit('set-parent-overflow-visible', false)"
        >
          <span :class="$style['horizontal-navigation__dots']" />
          <ul :class="$style['horizontal-navigation__drop']">
            <li
              v-for="{ id, routeName, props, icon, iconCdnSrc, ...buttonProps } in dropList"
              :key="id"
              :class="$style['horizontal-navigation__list-item']"
            >
              <HeaderNavigationButton
                v-data-test="{ el: 'header-navigation-button', id }"
                v-bind="buttonProps"
                :class="id"
                inverted
                :icon="icon"
                :icon-cdn-src="iconCdnSrc"
                :is-promotions-highlighted="!!(props && props.isPromo && isPromotionsHighlighted)"
                :is-landing-euro="!!(props && props.isLandingEuro && isLandingFestivalEnabled)"
                @click="handleClick({id, routeName, props}); emit('set-parent-overflow-visible', false);"
              />
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </nav>
</template>

<style lang="scss" module>
@import 'web/src/assets/sass/misc/animations';

.horizontal-navigation {
  display: flex;
  flex: 1;
  height: $headerHeightDesktopSmall;

  @include screen-desktop-large-min {
    height: $headerHeightDesktopBig;
  }

  &--hide-overflow {
    overflow: hidden;
  }

  &__base {
    display: flex;
    flex: none;
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__list-item {
    flex: none;
    padding: 0;
    margin: 0;
  }

  &__more {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: $headerNavigationMoreWidth;
    height: calc($headerHeightDesktopSmall - 2 * $headerNavigationButtonMarginTop);
    margin: $headerNavigationMoreMargin;
    overflow: hidden;
    cursor: pointer;

    @include screen-desktop-large-min {
      height: calc($headerHeightDesktopBig - 2 * $headerNavigationButtonMarginTop);
    }

    &:before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 0;
      content: '';
      background-color: $headerNavigationMoreBgColor;
      border-radius: $headerNavigationButtonBorderRadius;
      opacity: 0;
    }
  }

  &__dots {
    position: relative;
    display: block;
    overflow: visible;

    &,
    &:before,
    &:after {
      width: 4px;
      height: 4px;
      background-color: $headerNavigationDotsColor;
      border-radius: 50%;
    }

    &:before,
    &:after {
      position: absolute;
      top: 0;
      content: '';
    }

    &:before {
      left: -7px;
    }

    &:after {
      right: -7px;
    }
  }

  &__more:hover &__dots,
  &__more:focus-within &__dots {
    &,
    &:before,
    &:after {
      background-color: $headerNavigationDotsHoverBgColor;
    }
  }

  &__drop {
    position: absolute;
    top: $headerNavigationDropTop;
    left: 0;
    padding: $headerNavigationDropPadding;
    margin: 0;
    list-style: none;
    background-color: $headerNavigationDropBgColor;
    border-radius: $headerNavigationDropBorderRadius;
    opacity: 0;

    &:before {
      @include headerNavigationDropBeforeStyles;
    }
  }

  &__more:hover,
  &__more:focus-within {
    overflow: visible;

    &:before {
      animation: opacity-in 0.3s ease forwards;
    }
  }

  &__more:hover &__drop,
  &__more:focus-within &__drop {
    animation: opacity-in 0.3s ease forwards;
  }
}
</style>
