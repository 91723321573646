import type { MaybeRef } from 'vue';
import { toValue } from 'vue';

import type { SportElement } from 'web/src/modules/sportline/types';
import type { SportlineFragmentBasisKey } from 'web/src/modules/sportline/types/list';

import type { VirtualListNoEventsElement, VirtualListSportHeadlineElement, VirtualListElement } from '../types';
import { VirtualListElementType, VirtualListBlockLayout } from '../enums';

interface GetNoEventsElementProps {
  basisKey: SportlineFragmentBasisKey;
  blockLayout?: VirtualListBlockLayout;
  isLastSport?: boolean;
  collapseSportKey?: MaybeRef<Maybe<string>>;
}

export function getNoEventsElement(sportElement: Maybe<SportElement>, props: GetNoEventsElementProps): VirtualListElement[] {
  const { basisKey, isLastSport, blockLayout } = props;

  const collapseSportKey = toValue(props.collapseSportKey ?? null);

  const sportHeadline: Maybe<VirtualListSportHeadlineElement> = sportElement
    ? {
      type: VirtualListElementType.SportHeadline,
      sport: sportElement.sport,
      isLastSport: isLastSport ?? false,
      blockLayout: blockLayout ?? VirtualListBlockLayout.Default,
      basisKey,
      collapseSportKey,
      eventsCount: 0,
      isMarketTypesSelectionEnabled: false,
      hideEventsCounter: true,
    }
    : null;

  const noEvents: VirtualListNoEventsElement = {
    type: VirtualListElementType.NoEvents,
    blockLayout: blockLayout ?? VirtualListBlockLayout.Default,
    basisKey,
  };

  return sportHeadline ? [sportHeadline, noEvents] : [noEvents];
}
