import { MarketStatus } from '@leon-hub/api-sdk';

import { SportEventStatus } from 'web/src/modules/sportline/enums';

export default function convertSportEventStatusToMarketStatus(
  status?: Maybe<SportEventStatus>,
): Maybe<MarketStatus> {
  if (status === SportEventStatus.Closed) {
    return MarketStatus.CLOSED;
  }

  if (status === SportEventStatus.Suspended) {
    return MarketStatus.SUSPENDED;
  }

  return null;
}
