import type { SportEventSeoMetaInfo } from 'web/src/modules/sportline/types';
import type { GetSportEventSeoMetaInfoOptions } from 'web/src/modules/sportline/utils';
import { getSportEventSeoMetaInfo } from 'web/src/modules/sportline/utils';
import type { SportEventDetails } from 'web/src/modules/sportline/submodules/event-details/types';

export function getSportEventDetailsSeoMetaInfo(
  sportEventDetails?: Maybe<SportEventDetails>,
  options?: GetSportEventSeoMetaInfoOptions,
): SportEventSeoMetaInfo | {} {
  if (!sportEventDetails || !options) {
    return {};
  }

  return getSportEventSeoMetaInfo({
    sportEvent: sportEventDetails,
    sport: sportEventDetails.sport,
    region: sportEventDetails.region,
    league: sportEventDetails.league,
    ...options,
  });
}
