import type { Ref } from 'vue';

import { PostMessageBus } from '@leon-hub/postmessage-bus';

import { IframeWidget } from 'web/src/modules/framed-app/enums';
import { requireContentWindowByIFrameName } from 'web/src/components/Iframe/VIframe/utils';
import EgsGameWidgetPostMessageEvent, { EgsGameWidgetPostMessageBusInitiator } from 'web/src/modules/framed-app/components/EGSGameFramedWidget/utils/EgsGameWidgetPostMessageEvent';
import { usePreventTitleChange } from 'web/src/modules/egs/components/composables';
import { useFramedWidgetUrl } from 'web/src/modules/widgets/composables/useFramedWidgetUrl';

import type { EgsInjectedGameIframeWidgetProps, EgsInjectedGameIframeWidgetEmits } from '../types';

export interface EgsInjectedGameIframeWidgetComposable {
  iframeName: string;
  gameUrl: Ref<string | undefined>;
  onIframeMounted(): void;
  onIframeLoaded(): void;
}

export default function useEgsInjectedGameIframeWidget(
  props: EgsInjectedGameIframeWidgetProps,
  emit: EgsInjectedGameIframeWidgetEmits,
): EgsInjectedGameIframeWidgetComposable {
  const iframeName = IframeWidget.EgsGame;
  const gameUrl = useFramedWidgetUrl(iframeName);
  let postMessageBus: PostMessageBus | undefined;

  function onIframeMounted(): void {
    const iframeContentWindow = requireContentWindowByIFrameName(iframeName);

    postMessageBus = new PostMessageBus({
      target: iframeContentWindow,
      targetOrigin: '*',
      initiator: EgsGameWidgetPostMessageBusInitiator,
    });
    postMessageBus.emit(EgsGameWidgetPostMessageEvent.init, {
      gameHtml: props.gameHtml,
    });

    postMessageBus.on(EgsGameWidgetPostMessageEvent.stateChanged, () => {
      postMessageBus?.emit(EgsGameWidgetPostMessageEvent.init, {
        gameHtml: props.gameHtml,
      });
    });
  }

  function onIframeLoaded(): void {
    emit('load');
  }

  usePreventTitleChange();

  return {
    iframeName,
    gameUrl,
    onIframeMounted,
    onIframeLoaded,
  };
}
