<script setup lang="ts">
import { IconDirection, IconName, IconSize } from '@leon-hub/icons';

import { VIcon } from '@components/v-icon';

import VTabs from 'web/src/components/Tabs/VTabs/VTabs.vue';

import { SettingsTabs } from '../../enums';
import type { BetSlipSettingsViewEmits, BetSlipSettingsViewProps } from './types';
import useBetSlipSettingsView from './composables/useBetSlipSettingsView';

withDefaults(defineProps<BetSlipSettingsViewProps>(), {
  activeSettingsTabId: SettingsTabs.COMMON_SETTINGS,
});

const emits = defineEmits<BetSlipSettingsViewEmits>();

const {
  settingsTabs,
  emitSelectSettingsTab,
  emitSettingsBackClick,
  handleScroll,
} = useBetSlipSettingsView(emits);
</script>

<template>
  <div v-auto-id="'BetSlipSettingsView'"
    :class="$style['bet-slip-settings-view']"
  >
    <header :class="$style['bet-slip-settings-view__caption']">
      <button
        type="button"
        :title="$t('WEB2_BACK_LABEL')"
        :class="$style['bet-slip-settings-view__caption-button']"
        data-test-id="settings-back"
        @click="emitSettingsBackClick"
      >
        <VIcon
          :name="IconName.ARROW"
          :direction="IconDirection.WEST"
          :size="IconSize.SIZE_24"
        />
      </button>
      <div :class="$style['bet-slip-settings-view__caption-text']">
        {{ $t('JS_SLIP_BET_SETTINGS') }}
      </div>
    </header>
    <div :class="$style['bet-slip-settings-view__tabs']">
      <VTabs
        :items="settingsTabs"
        :active-id="activeSettingsTabId"
        full-width
        data-test-id="settings-tabs"
        @tab-click="emitSelectSettingsTab"
      />
    </div>
    <div
      :class="$style['1'
        ? 'bet-slip-settings-view__main'
        : 'bet-slip-settings-view__scroll']"
      data-test-id="settings-scroll-component"
      @scroll="handleScroll"
    >
      <slot />
    </div>
  </div>
</template>

<style lang="scss" module>
.bet-slip-settings-view {
  position: relative;
  overflow: hidden;

  &,
  &__scroll {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  &__scroll {
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    overscroll-behavior: none;
    -webkit-overflow-scrolling: touch;
  }

  &__tabs {
    flex: none;
  }

  &__main {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  &__caption {
    @include medium\18\20;

    position: relative;
    display: flex;
    flex: 0 0 auto;
    height: 44px;
    padding: 0 44px;
    color: var(--TextDefault);
  }

  &__caption-button {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    padding: 0;
    color: var(--TextSecondary);
    cursor: pointer;
    background: transparent;
    border: none;

    @include for-hover {
      &:hover {
        color: var(--TextPrimary);
      }
    }
  }

  &__caption-text {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }
}
</style>
