import { isString } from '@leon-hub/guards';

import type { Sport } from 'web/src/modules/sportline/types';
import type { SportsListTab } from 'web/src/modules/sportline/submodules/sports/types';
import type { CustomFilter } from 'web/src/modules/sportline/enums';
import { isSportFamilyEquals } from 'web/src/modules/sportline/utils';

export function createSportsListTab(options: {
  sport: CustomFilter | Sport;
  selectedSportFamily?: Maybe<string>;
  shownSportFamily?: Maybe<string>;
  transitionId?: Maybe<string>;
}): SportsListTab {
  const {
    sport,
    selectedSportFamily,
    shownSportFamily,
    transitionId,
  } = options;
  const key = isString(sport) ? sport : sport.id;
  const isActive = isSportFamilyEquals(sport, selectedSportFamily);
  const isShown = transitionId
    ? transitionId === key
    : isSportFamilyEquals(sport, shownSportFamily);

  return { isActive, isShown, key };
}
