import { toRef } from 'vue';

import { PostMessageBus } from '@leon-hub/postmessage-bus';
import { assert } from '@leon-hub/guards';
import { logger } from '@leon-hub/logging';
import { Timer } from '@leon-hub/utils';

import RecaptchaV3PostMessageEvent, { RecaptchaV3PostMessageBusInitiator } from 'web/src/modules/framed-app/components/RecaptchaV3Widget/utils/RecaptchaV3PostMessageEvent';
import { IframeWidget } from 'web/src/modules/framed-app/enums';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useFramedWidgetUrl } from 'web/src/modules/widgets/composables/useFramedWidgetUrl';

export interface RecaptchaV3Composable {
  getToken(action?: string): Promise<string | undefined>;
}

export function useRecaptchaV3(): RecaptchaV3Composable {
  const siteConfigStore = useSiteConfigStore();
  const siteKey = toRef(() => siteConfigStore.captcha?.recaptchaV3?.siteKey);
  const timeout = toRef(() => siteConfigStore.captcha?.recaptchaV3?.timeout ?? 10_000);
  const iframeSrc = useFramedWidgetUrl(IframeWidget.ReCaptchaV3);

  async function getToken(action?: string): Promise<string | undefined> {
    const key = siteKey.value;
    if (process.env.VUE_APP_FEATURE_RECAPTCHA_V3_DISABLED || !key || !iframeSrc.value) {
      return undefined;
    }

    const iframe = document.createElement('iframe');
    iframe.src = iframeSrc.value;
    iframe.style.display = 'none';
    iframe.style.height = '0';
    iframe.style.width = '0';
    document.body.appendChild(iframe);

    const iframeContentWindow = iframe.contentWindow;
    assert(iframeContentWindow, 'Recaptcha3 iframe not loaded');

    const postMessageBus = new PostMessageBus({
      target: iframeContentWindow,
      targetOrigin: '*',
      initiator: RecaptchaV3PostMessageBusInitiator,
    });

    const initTokenRequest = (): void => {
      postMessageBus.emit(RecaptchaV3PostMessageEvent.init, {
        siteKey: key,
        action,
      });
    };

    const waitForToken = (): Promise<string> => (
      new Promise<string>((resolve, reject) => {
        const timer = Timer.setTimeout(() => {
          reject(new Error(`Failed to get ReCaptchaV3 token in ${timeout.value}ms`));
        }, timeout.value);

        initTokenRequest();
        postMessageBus.on(RecaptchaV3PostMessageEvent.mounted, initTokenRequest);
        postMessageBus.on(RecaptchaV3PostMessageEvent.sendToken, ({ token }) => {
          Timer.clearTimeout(timer);
          resolve(token);
        });
      })
    );

    try {
      return await waitForToken();
    } catch (error) {
      logger.warn('Failed to get RecaptchaV3 token', error);
      return undefined;
    } finally {
      postMessageBus.dispose();
      iframe.remove();
    }
  }

  return {
    getToken,
  };
}
