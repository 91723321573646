<script setup lang="ts">
import {
  computed, onBeforeMount,
  toRef,
  watch,
} from 'vue';
import { useRouter } from 'vue-router';

import {
  CustomerBetType, lobbyTypeHome,
} from '@leon-hub/api-sdk';
import { RouteName, CasinoRouteName } from '@leon-hub/routing-config-names';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';

import LobbyBlock from 'web/src/modules/lobby/components/LobbyBlock/LazyLobbyBlock';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import type { LobbySkeletonItem } from 'web/src/modules/lobby/types';
import ShowcaseNavigationRouteComponent from 'web/src/modules/home/submodules/showcase/ShowcaseNavigationRouteComponent.vue';
import CustomHomepageBanners from 'web/src/modules/banners/components/CustomHomepageBanners/CustomHomepageBanners.vue';
import { useCustomerBetTypeStore, useCustomerDataStore } from 'web/src/modules/customer/store';
import { useKeepAlive } from 'web/src/modules/core/composables/keep-alive';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';

const router = useRouter();
const customerDataStore = useCustomerDataStore();
const analytics = useAnalytics();
const { isLoggedIn } = useIsLoggedIn();

const betType = toRef(useCustomerBetTypeStore(), 'betType');
const egsSkeletons = toRef(useSiteConfigStore(), 'egsSkeletons');
const customerCountry = toRef(() => customerDataStore.customerData?.country);
const customerId = toRef(() => customerDataStore.customerData?.customerLogin);
const customerIsVip = toRef(() => customerDataStore.customerData?.isVip);
const customerVipStatus = toRef(() => customerDataStore.customerData?.vipStatus);
const skeletons = computed<LobbySkeletonItem[]>(() => (
  egsSkeletons.value?.home ?? []
));

if (null) {
  watch(betType, (newValue) => {
    if (newValue) {
      void router.replace({
        name: newValue === CustomerBetType.SPORT ? RouteName.SPORTLINE_TOP_EVENTS : CasinoRouteName.CASINO_LOBBY,
      });
    }
  }, { immediate: true });
}

useKeepAlive();

onBeforeMount(() => {
  analytics.pushGTM(AnalyticsEvent.NEW_HOMEPAGE_VIEW, {
    event: AnalyticsEvent.NEW_HOMEPAGE_VIEW,
    customer_status: isLoggedIn.value ? 'logged_in' : 'logged_out',
    customer_id: customerId.value,
    customer_type: customerVipStatus.value,
    vs_lw: customerIsVip.value,
    full_url: window.location.href,
    country: customerCountry.value,
    event_category: 'New homepage',
    event_action: 'Page view',
    event_timestamp: Date.now(),
  });
});
</script>

<template>
  <div v-auto-id="'CustomHomeRoutePage'" :class="$style['custom-home']">
    <CustomHomepageBanners
      :class="$style['custom-home__banners']"
    />
    <ShowcaseNavigationRouteComponent
      is-custom-homepage
      :class="$style['custom-home__showcase']"
    />
    <LobbyBlock
      :lobby-type="lobbyTypeHome"
      :skeletons="skeletons"
      prevent-items-fetch
      is-custom-homepage
      is-full-height
    />
  </div>
</template>

<style lang="scss" module>
@include for-layout using ($isDesktop) {
  .custom-home {
    @include limit-content-width($isDesktop);

    padding: if($isDesktop, 0 16px, 0);

    @if $isDesktop {
      overflow: hidden;
    }

    &__banners {
      margin-bottom: 16px;

      @if not $isDesktop {
        padding: 0 8px;
        margin-top: 8px;
      }
    }
  }
}
</style>
