import { Storage } from '@leon-hub/storage';

export class LexisNexisProfiledStorage extends Storage<boolean | undefined> {
  async isProfiled(): Promise<boolean> {
    const record = await this.get();
    if (record.exists) {
      return record.value !== undefined;
    }
    return false;
  }

  async isProfiledSuccessfully(): Promise<boolean> {
    const record = await this.get();
    if (record.exists) {
      return record.value === true;
    }
    return false;
  }

  setProfiledStatus(status: boolean): Promise<void> {
    return this.set(status);
  }
}
