<script lang="ts" setup>
import type { EgsGroup } from 'web/src/modules/egs/types';
import vDataTestUnit from 'web/src/testing/directives/vDataTestUnit';
import LobbyEgsGroupItem from 'web/src/modules/lobby/components/LobbyEgsGroupItem/LobbyEgsGroupItem.vue';

import { useCasinoGroupsList } from './composables';

interface CasinoGroupsListProps {
  groups: EgsGroup[];
  selectedId?: string;
  isSearchModal?: boolean;
}

interface CasinoGroupListEmits {
  (e: 'selected-loaded', el: HTMLElement): void;
  (e: 'click-group', group: EgsGroup): void;
}

withDefaults(defineProps<CasinoGroupsListProps>(), {});
const emit = defineEmits<CasinoGroupListEmits>();

const {
  onClick,
  onSelectedLoaded,
} = useCasinoGroupsList(emit);
</script>

<template>
  <div v-auto-id="'CasinoGroupsList'"
    :class="{
      [$style['groups-list']]: true,
      [$style['groups-list--search']]: !!isSearchModal,
    }"
  >
    <LobbyEgsGroupItem
      v-for="group in groups"
      :key="group.id"
      v-data-test-unit="{ el: 'groups-list-item', id: group.id }"
      :item="group"
      :is-active="group.id === selectedId"
      :class="$style['groups-list__item']"
      @click="onClick(group)"
      @selected-loaded="onSelectedLoaded"
    />
  </div>
</template>

<style lang="scss" module>
@include for-layout using($isDesktop) {
  .groups-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;

    @if $isDesktop {
      grid-template-columns: repeat(4, 1fr);
    }

    @media only screen and (min-width: 500px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media only screen and (min-width: 650px) {
      grid-template-columns: repeat(4, 1fr);
    }

    &--search {
      @if $isDesktop {
        grid-template-columns: $casinoGroupsTemplateColumnsSearch;
      }
    }
  }
}
</style>
