import { computed, ref, toRef } from 'vue';
import { defineStore } from 'pinia';

import { GqlApiAccessDeniedError } from '@leon-hub/api';
import { logger } from '@leon-hub/logging';

import { useSiteConfigStore } from 'web/src/modules/core/store';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import { useErrorsConverter } from 'web/src/modules/errors/composables';
import { getSlipEntryId } from 'web/src/modules/slip/utils';

import { requestGetBetSlip, convertSharedSlip } from '../utils';
import { useSlipInfoStore } from '../../slip-info/store';
import { useSlipViewSettingsStore } from '../../slip-view-settings/store';
import { getOnlyAvailableSlipEntries } from '../../slip-info/utils';
import { useShareEventLogging } from '../../../composable';

const useGetSharedSlipStore = defineStore('get-shared-slip-store', () => {
  const apiClient = useGraphqlClient();

  const siteConfigStore = useSiteConfigStore();

  const slipBlock = toRef(siteConfigStore, 'slipBlock');

  const { convertToBaseError } = useErrorsConverter();

  const { logGetSharedCouponSuccess, logGetSharedCouponError } = useShareEventLogging();

  const isReceiveFromCodeEnabled = computed<boolean>(() => slipBlock.value?.sharedBetSlipCodeInputEnabled ?? false);

  const slipInfoStore = useSlipInfoStore();

  const { setupSlipFromSharedData, removeBetEvents } = slipInfoStore;

  const sharedBookingCode = ref<string>('');

  const isBookingCodeFromLink = ref<boolean>(false);

  const setSharedBookingCode = (value: string): void => {
    sharedBookingCode.value = value;
  };

  const bookingCodeError = ref<string>('');

  const isWaitingToSlipFromCode = ref<boolean>(false);

  const isAllSharedEventsExpired = ref<boolean>(false);

  const {
    showSlipFromBookingCodeOverlay,
    hideSlipFromBookingCodeOverlay,
    setSlipVisibility,
    setSlipFullHeight,
  } = useSlipViewSettingsStore();

  const getSlipFromBookingCode = async (isCodeFromLink = false): Promise<void> => {
    isBookingCodeFromLink.value = isCodeFromLink;
    removeBetEvents();
    if (!process.env.VUE_APP_LAYOUT_DESKTOP) {
      setSlipFullHeight(false);
      setSlipVisibility(true);
    }
    try {
      hideSlipFromBookingCodeOverlay();
      isAllSharedEventsExpired.value = false;
      isWaitingToSlipFromCode.value = true;
      const sharedSlip = await requestGetBetSlip({ bookingCode: sharedBookingCode.value }, apiClient);
      if (sharedSlip) {
        const convertedSharedSlip = convertSharedSlip(sharedSlip.slipEntries);
        const initialEventsCount = sharedSlip.slipSize;
        const onlyAvailableSlipEntries = getOnlyAvailableSlipEntries(convertedSharedSlip.slipEntries);
        const availableEntriesCount = onlyAvailableSlipEntries.length;
        const selectedEntriesIds = onlyAvailableSlipEntries.map((entry) => getSlipEntryId(entry));
        if (availableEntriesCount < initialEventsCount) {
          showSlipFromBookingCodeOverlay();
        }
        if (!availableEntriesCount) {
          isAllSharedEventsExpired.value = true;
          isWaitingToSlipFromCode.value = false;
          return;
        }
        await setupSlipFromSharedData({
          betsInfo: convertedSharedSlip.betsInfo,
          selectedEntriesIds,
          slipEntries: onlyAvailableSlipEntries,
          slipType: sharedSlip.slipType,
        });
        isWaitingToSlipFromCode.value = false;
        logGetSharedCouponSuccess(sharedBookingCode.value);
        setSharedBookingCode('');
      }
    } catch (error) {
      isWaitingToSlipFromCode.value = false;
      if (error instanceof GqlApiAccessDeniedError) {
        throw error;
      }
      logger.info('Enable to get slip from booking code', { error, code: sharedBookingCode.value });
      removeBetEvents();
      const baseError = convertToBaseError(error);
      bookingCodeError.value = baseError.message;
      logGetSharedCouponError(sharedBookingCode.value, baseError.message);
      if (isCodeFromLink) {
        isAllSharedEventsExpired.value = true;
        showSlipFromBookingCodeOverlay();
      }
    }
  };

  const resetBookingCodeForm = (): void => {
    isAllSharedEventsExpired.value = false;
    bookingCodeError.value = '';
    sharedBookingCode.value = '';
  };

  const clearBonusCodeError = (): void => {
    bookingCodeError.value = '';
  };

  return {
    isReceiveFromCodeEnabled,
    isWaitingToSlipFromCode,
    bookingCodeError,
    showSlipFromBookingCodeOverlay,
    isAllSharedEventsExpired,
    getSlipFromBookingCode,
    clearBonusCodeError,
    sharedBookingCode,
    isBookingCodeFromLink,
    setSharedBookingCode,
    resetBookingCodeForm,
  };
});

export default useGetSharedSlipStore;
