import type { Ref } from 'vue';
import { computed } from 'vue';
import type { RouteLocationRaw } from 'vue-router';

import { IconName } from '@leon-hub/icons';

import { useI18n } from 'web/src/modules/i18n/composables';
import type { CoreSegmentPageListGroup } from 'web/src/modules/sportline/submodules/segment/types';
import type {
  SportlineFilterItem,
} from 'web/src/modules/sportline/components/navigation-filter/types';
import type {
  SportlineNavigationCommonIcon,
  SportlineNavigationTextIcon,
} from 'web/src/modules/sportline/components/navigation-indicator/types';
import type { ChangeFilterPayload } from 'web/src/modules/sportline/submodules/segment/composables/types';
import { SportlineNavigationItemType } from 'web/src/modules/sportline/enums/navigation';
import { CustomFilter } from 'web/src/modules/sportline/enums';

interface UseSportlineSegmentFilterProps<Group extends CoreSegmentPageListGroup> {
  totalCount: Ref<number>;
  allGroup: Ref<Maybe<Group>>;
  streamGroup: Ref<Maybe<Group>>;
  favoriteGroup: Ref<Maybe<Group>>;
  resolveOptionLocation(group?: Maybe<Group>): RouteLocationRaw;
  emitChangeFilter(payload: Maybe<ChangeFilterPayload<Group>>): void;
}

interface UseSportlineSegmentFilterComposable {
  allFilterItem: Ref<Maybe<SportlineFilterItem>>;
  favoriteFilterItem: Ref<Maybe<SportlineFilterItem>>;
  streamFilterItem: Ref<Maybe<SportlineFilterItem>>;
}

export function useSportlineSegmentFilter<Group extends CoreSegmentPageListGroup>(
  props: UseSportlineSegmentFilterProps<Group>,
): UseSportlineSegmentFilterComposable {
  const {
    totalCount,
    allGroup,
    streamGroup,
    favoriteGroup,
    emitChangeFilter,
    resolveOptionLocation,
  } = props;

  const { $translate } = useI18n();

  const allFilterItem = computed<Maybe<SportlineFilterItem>>(() => {
    const group = allGroup.value;

    if (!group) { return null; }

    const to = resolveOptionLocation(group);
    const icon: Maybe<SportlineNavigationTextIcon> = process.env.VUE_APP_STYLE_TWIN
      ? null
      : { type: SportlineNavigationItemType.Text, text: String(totalCount.value || 0) };

    return {
      key: group.key,
      icon,
      title: $translate('WEB2_SPORTS_QUICKFILTERS_ALL_MATHES').value,
      isActive: group.isActive,
      customFilter: CustomFilter.AllEvents,
      to,
      onClick: () => emitChangeFilter({ group, to }),
      dataTest: CustomFilter.AllEvents,
      transitionId: group.transitionId,
    };
  });

  const favoriteFilterItem = computed<Maybe<SportlineFilterItem>>(() => {
    const group = favoriteGroup.value;

    if (!group) { return null; }

    const to = resolveOptionLocation(group);
    const icon: SportlineNavigationCommonIcon = {
      type: SportlineNavigationItemType.Icon,
      props: { name: IconName.STAR_ALT },
    };

    return {
      key: group.key,
      icon,
      title: $translate('WEB2_SPORT_EVENTS_FILTER_FAVORITE').value,
      isActive: group.isActive,
      customFilter: CustomFilter.Favorites,
      to,
      onClick: () => emitChangeFilter({ group, to }),
      dataTest: CustomFilter.Favorites,
      transitionId: group.transitionId,
    };
  });

  const streamFilterItem = computed<Maybe<SportlineFilterItem>>(() => {
    const group = streamGroup.value;

    if (!group) {
      return null;
    }

    const to = resolveOptionLocation(group);
    const icon: SportlineNavigationCommonIcon = {
      type: SportlineNavigationItemType.Icon,
      props: { name: IconName.STREAM },
    };

    return {
      key: group.key,
      icon,
      title: $translate('WEB2_SPORT_EVENTS_FILTER_STREAM').value,
      isActive: group.isActive,
      customFilter: CustomFilter.Stream,
      to,
      onClick: () => emitChangeFilter({ group, to }),
      dataTest: CustomFilter.Stream,
      transitionId: group.transitionId,
    };
  });

  return {
    allFilterItem,
    favoriteFilterItem,
    streamFilterItem,
  };
}
