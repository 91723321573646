import type {
  RouteLocationNormalized,
  NavigationGuardNext,
} from 'vue-router';
import { toRef } from 'vue';

import { RouteName } from '@leon-hub/routing-config-names';

import { useSportlineSportsStore } from 'web/src/modules/sportline/submodules/sports/store';
import type { AppVueRouter } from 'web/src/modules/core/services/router/types';
import { getSportPageNavigationParameters } from 'web/src/modules/sportline/utils';

interface UsePrerenderSportsPageNavigationProps {
  router: AppVueRouter;
  to: RouteLocationNormalized;
  from: RouteLocationNormalized;
}

interface UsePrerenderSportsPageNavigationComposable {
  proceedRedirects(next: NavigationGuardNext): Promise<void>;
}

export function usePrerenderSportsPageNavigation(
  props: UsePrerenderSportsPageNavigationProps,
): UsePrerenderSportsPageNavigationComposable {
  const {
    router,
    to,
  } = props;

  const sportlineSportsStore = useSportlineSportsStore();
  const isZeroMarginEventsTabSelected = toRef(sportlineSportsStore, 'isZeroMarginEventsTabSelected');
  const isZeroMarginEventsTabAvailable = toRef(sportlineSportsStore, 'isZeroMarginEventsTabAvailable');
  const actualSportElement = toRef(sportlineSportsStore, 'actualSportElement');

  async function proceedRedirects(next: NavigationGuardNext): Promise<void> {
    await Promise.resolve();

    // redirects from zero margin events empty page
    if (isZeroMarginEventsTabSelected.value) {
      if (!isZeroMarginEventsTabAvailable.value) {
        next(router.resolve302location({ name: RouteName.HOME }));
        return;
      }

      // redirects if sport family is correct but have not got sport after load state
    } else if (!actualSportElement.value) {
      const navigationParameters = getSportPageNavigationParameters(to);

      // do redirect to bets only if we have a selected sportFamily
      if (navigationParameters.sportFamily) {
        next(router.resolve302location({ name: RouteName.SPORTLINE_PREMATCH_EVENTS }));
        return;
      }
    }

    next();
  }

  return { proceedRedirects };
}
