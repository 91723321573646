import {
  computed,
  ref,
  nextTick,
  toRef,
  watchEffect,
} from 'vue';
import type {
  Ref,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';

import type { NavigationItem } from '@leon-hub/navigation-config';
import {
  getNavigationItemRouteLocation,
} from '@leon-hub/navigation-config';
import type { BaseRouteNameType } from '@leon-hub/routing-config-names';
import { RouteName } from '@leon-hub/routing-config-names';
import { useIntersectionObserver } from '@leon-hub/vue-utils';
import { useDocumentDimensionSafeSubscribe } from '@leon-hub/browser-composables';

import { useAnalytics } from 'web/src/modules/analytics/composables';
import { useAuthStore } from 'web/src/modules/auth/store';
import { useTheme } from 'web/src/modules/theme/composables';
import { useRootStore } from 'web/src/modules/core/store';
import { getNavigationItemIconLocation } from 'web/src/modules/core/utils';
import { useCustomHomepageNavigationItems } from 'web/src/modules/core/composables/navigation/useCustomHomepageNavigationItems';

import getNavigationItemByKey from './utils/getActiveItem';
import type { HeaderNavigationProps, HeaderNavigationEmits } from '../types';
import type {
  HeaderNavigationItem,
} from '../enums';
import { binarySearchFirstUnfitElementIndex } from './utils';

export interface HeaderNavigationComposable {
  container: Ref<Optional<HTMLElement>>;
  baseList: Ref<Maybe<HTMLElement>>;
  navigationList: Ref<HeaderNavigationItem[]>;
  dropList: Ref<HeaderNavigationItem[]>;
  handleClick(options: NavigationItem): void;

  initialContainerWidth:Ref<number>;
  activeId:Ref<Optional<string>>;
  initialBaseListWidth:Ref<number>;
}

const SportlinePages: string[] = [
  RouteName.HOME,
  RouteName.SPORTLINE_LIVE_EVENTS,
  RouteName.SPORTLINE_PREMATCH_EVENTS,
  RouteName.SPORTLINE_CYBERSPORT,
];

export default function useHeaderNavigation(
  props: RequiredField<HeaderNavigationProps, 'navigationItems'>,
  emit: HeaderNavigationEmits,
): HeaderNavigationComposable {
  const container = ref<Optional<HTMLElement>>();
  const baseList = ref();
  const router = useRouter();
  const analytics = useAnalytics();
  const route = useRoute();
  const authStore = useAuthStore();
  const { theme } = useTheme();
  const { imageCdnUrl } = useRootStore();

  const isLoggedIn = toRef(authStore, 'isLoggedIn');

  const initialContainerWidth = ref(0);
  const initialBaseListWidth = ref(0);
  const indexToSplitBaseList = ref(props.navigationItems?.length || 0);
  const activeId = ref<Optional<string>>();

  const { clientDimension } = useDocumentDimensionSafeSubscribe({ debounce: 200 });

  function convertToHeaderNavigationItem(
    navigationItem: NavigationItem,
  ): HeaderNavigationItem | null {
    return {
      id: navigationItem.id,
      routeName: navigationItem.routeName,
      props: navigationItem.props,
      href: router.resolve(getNavigationItemRouteLocation(navigationItem, 'header')).href,
      label: navigationItem.caption || '',
      isActive: navigationItem.id === activeId.value,
      icon: navigationItem.icon,
      iconCdnSrc: getNavigationItemIconLocation(navigationItem.iconCdn?.src, theme.value, imageCdnUrl),
    };
  }

  const {
    filteredNavigationItems,
  } = useCustomHomepageNavigationItems(toRef(props, 'navigationItems'));

  const headerNavigationItems = computed(
    () => filteredNavigationItems.value
      .map((item) => convertToHeaderNavigationItem(item))
      .filter((item): item is NonNullable<typeof item> => item !== null),
  );

  const preparedNavigationList = computed(() => {
    if (initialContainerWidth.value > 0 && clientDimension.value && (isLoggedIn.value || !isLoggedIn.value)) {
      const activeItemIndex = headerNavigationItems.value.findIndex((item) => item.isActive);

      if (activeItemIndex >= indexToSplitBaseList.value && indexToSplitBaseList.value !== 0) {
        const lastFitElementIndex = indexToSplitBaseList.value - 1;
        return [
          ...headerNavigationItems.value.slice(0, lastFitElementIndex),
          headerNavigationItems.value[activeItemIndex],
          ...headerNavigationItems.value.slice(lastFitElementIndex, activeItemIndex),
          ...headerNavigationItems.value.slice(activeItemIndex + 1),
        ];
      }
    }
    return headerNavigationItems.value;
  });

  const navigationList = computed(() => (
    clientDimension.value.clientWidth
      ? (preparedNavigationList.value || []).slice(0, indexToSplitBaseList.value)
      : []
  ));

  const dropList = computed(() => (
    clientDimension.value.clientWidth
      ? (preparedNavigationList.value || []).slice(indexToSplitBaseList.value)
      : []
  ));

  function getBaseListWidth(): number {
    return baseList?.value?.offsetWidth || 0;
  }

  function getContainerWidth(): number {
    return container?.value?.offsetWidth || 0;
  }

  const handleInitialSize = () => {
    if (container.value) {
      initialContainerWidth.value = getContainerWidth();
      initialBaseListWidth.value = getBaseListWidth();
    }
  };

  const realContainerWidth = () => {
    const dropContainerWidth = 54;
    return getContainerWidth() - dropContainerWidth;
  };

  watchEffect(() => {
    if (initialContainerWidth.value > 0 && clientDimension.value.clientWidth) {
      indexToSplitBaseList.value = headerNavigationItems.value.length;
      void nextTick().then(() => {
        indexToSplitBaseList.value = binarySearchFirstUnfitElementIndex(
          baseList.value, realContainerWidth(),
        );
      });
    }
  });

  watchEffect(() => {
    if (activeId.value) {
      const activeIdTopParent = getNavigationItemByKey('id', activeId.value, props.navigationItems);
      const hasChildrenNavigation = !!getNavigationItemByKey(
        'routeName', route.name, activeIdTopParent?.children ?? [],
      );
      if (hasChildrenNavigation) {
        return;
      }
    }
    activeId.value = getNavigationItemByKey('routeName', route.name, props.navigationItems)?.id;
  });

  useIntersectionObserver(container, handleInitialSize, undefined, true);

  function handleClick(item: NavigationItem): void {
    const { id, routeName, props: properties } = item;
    if (routeName && SportlinePages.includes(routeName)) {
      emit('sportline-navigation', routeName as BaseRouteNameType);
    } else {
      analytics.navigationItemClick({ id, routeName, props: properties });
      void router.push(getNavigationItemRouteLocation(item, 'header'));
    }
  }

  return {
    container,
    baseList,
    navigationList,
    dropList,
    handleClick,

    // for testing
    initialContainerWidth,
    initialBaseListWidth,
    activeId,
  };
}
