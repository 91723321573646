const StringUtils = {

  escapeSpecialCharacters(string: string): string {
    if (!string) {
      return string;
    }

    const map = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      '"': '&quot;',
      '\'': '&#x27;',
      '/': '&#x2F;',
    };

    const reg = new RegExp(`[${Object.keys(map).join('')}]`, 'gi');
    return string.replace(reg, (match: keyof typeof map) => map[match]);
  },

  escapeSpecialCharactersForJSON(value: Optional<Maybe<string>>): string {
    if (!value) {
      return '';
    }

    return StringUtils.escapeSpecialCharacters(value
      .replace(/\b/g, '\\b')
      .replace(/\f/g, '\\f')
      .replace(/\n/g, '\\n')
      .replace(/\r/g, '\\r')
      .replace(/\t/g, '\\t'));
  },

  removeTrailingSlashFromUrl(string: string): string {
    if (!string || string.length <= 1) {
      return string;
    }

    if (string.endsWith('/')) {
      return string.slice(0, -1);
    }

    const result = string.replace('/?', '?');

    return result.startsWith('?') ? `/${result}` : result;
  },

  useUnbreakableCharacters(string: string): string {
    return string
      .replace(/ /g, '\u00A0')
      .replace(/-/g, '\u2011');
  },

  kebabCaseToCamelCase(string: string): string {
    return string.replace(/-./g, (x) => x[1].toUpperCase());
  },
};

export default StringUtils;
