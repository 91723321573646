import { logger as loggerInstance } from '@leon-hub/logging';
import type { UIFormSchema } from '@leon-hub/api-sdk';
import { CustomerFieldType } from '@leon-hub/api-sdk';

import { CustomCustomerFieldType } from 'web/src/modules/registration/enums';
import type { FormExternalErrors, FormData, FormDataValue } from 'web/src/components/Form/types';
import { isFormPhoneValue } from 'web/src/components/Form/guards';

const logger = loggerInstance.createNamespace('updateFieldErrorByFormError');

export default function updateFieldErrorByFormError(
  formData: FormData,
  fieldType: CustomerFieldType,
  uiFormSchema?: UIFormSchema,
  customerErrors: FormExternalErrors = {},
  message?: string,
): FormExternalErrors {
  let validationMessage: Maybe<Optional<string>>;

  if (!message) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison -- keep it for now
    const fieldInput = uiFormSchema?.fields.find((field) => field.id === fieldType);

    validationMessage = fieldInput?.error;

    if (!validationMessage) {
      return customerErrors;
    }
  } else {
    validationMessage = message;
  }

  const value: FormDataValue = formData[fieldType];

  if (fieldType === CustomerFieldType.PHONE_INPUT && !isFormPhoneValue(value)) {
    logger.warn('Unexpected phone value', value);
    return customerErrors;
  }

  const field = fieldType === CustomerFieldType.PHONE_INPUT ? CustomCustomerFieldType.PHONE_INPUT_SUFFIX : fieldType;
  const valueReturn = isFormPhoneValue(value) ? value?.suffix : formData[fieldType];

  return {
    ...customerErrors,
    [field]: [{
      message: validationMessage,
      value: valueReturn as string,
    }],
  };
}
