import type { RouteLocationNormalized } from 'vue-router';

import { lobbyTypeLiveCasino } from '@leon-hub/api-sdk';
import { CasinoRouteName } from '@leon-hub/routing-config-names/src/CasinoRouteName';

export default function isLiveEgsRoute(route: RouteLocationNormalized): boolean {
  if (process.env.VUE_APP_PRODUCT_LEONRU) {
    return false;
  }
  return route.meta?.routeLobbyType === lobbyTypeLiveCasino || (!!route.name && ([
    CasinoRouteName.CASINO_LIVE_LOBBY,
    CasinoRouteName.CASINO_LIVE_GROUPS,
    CasinoRouteName.CASINO_LIVE_GAME,
  ] as string[]).includes(String(route.name)));
}
