import type { Web2Image } from '@leon-hub/api-sdk';

import type { LobbyEgsGamesItemImageProps } from 'web/src/modules/lobby/components/LobbyEgsGamesItemImage/types';

const noImageSource = require(`web/src/assets/images/${process.env.VUE_APP_PRODUCT}/${process.env.VUE_APP_SKIN}/noimage-portrait.png`);
const noImageSquareSource = require(`web/src/assets/images/${process.env.VUE_APP_PRODUCT}/${process.env.VUE_APP_SKIN}/noimage-square.png`);

export default function getImageProperties(
  isImageLoadError: boolean,
  props: LobbyEgsGamesItemImageProps,
): Web2Image {
  const noImageSrc = props.isSquareImage ? noImageSquareSource : noImageSource;

  if (isImageLoadError || !props.item) {
    return {
      src: noImageSrc,
    };
  }

  if (props.isSquareImage && props.item.images.squareImage) {
    return props.item.images.squareImage;
  }

  if (props.isJackpot && props.item.images.jackpot) {
    return props.item.images.jackpot;
  }

  return props.item.images.preview ?? {
    src: noImageSrc,
  };
}
