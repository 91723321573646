import { defineStore } from 'pinia';
import { ref } from 'vue';

const useModuleTimersStore = defineStore('module-timers', () => {
  const defaultTimeout = ref(60_000);
  const moduleTimeouts = ref<Record<string, number>>({});

  function setDefaultTimeout(value: number): void {
    defaultTimeout.value = value;
  }

  function setModuleTimeouts(value: Record<string, number>): void {
    moduleTimeouts.value = value;
  }

  return {
    defaultTimeout,
    moduleTimeouts,
    setDefaultTimeout,
    setModuleTimeouts,
  };
});

export default useModuleTimersStore;
