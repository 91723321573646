import type { ComputedRef, Ref } from 'vue';
import { computed, ref, watch } from 'vue';

import { logger } from '@leon-hub/logging';

import type { SingleSelectProperties, VSelectOption } from 'web/src/components/Select';
import { useI18n } from 'web/src/modules/i18n/composables';
import type { BaseInputEmits, VInputInputEvent } from 'web/src/components/Input';
import { useBaseInputEvents } from 'web/src/components/Input/composables';

import { getCurrenciesFromApi, mapApiCurrencyResponseToSelectOptions } from '../utils';
import type { CurrencySelectProps } from '../types';

interface UseCurrencySelect {
  selectProps: ComputedRef<SingleSelectProperties>;
  isLoading: Ref<boolean>;
  emitChange: (event: VInputInputEvent) => void;
  emitFocus: (event: VInputInputEvent) => void;
  emitBlur: (event: VInputInputEvent) => void;
}

export default function useCurrencySelect(props: CurrencySelectProps, emits: BaseInputEmits): UseCurrencySelect {
  const { $translate } = useI18n();

  const { emitChange, emitFocus, emitBlur } = useBaseInputEvents(emits);

  const isLoading = ref<boolean>(false);

  const emitChangeValue = (value: string) => emitChange({
    target: {
      value,
      name: props.name || '',
    },
  });

  const options = ref<VSelectOption[]>([]);

  const getCurrencyOptions = async (countryCode: string): Promise<void> => {
    options.value = [];
    isLoading.value = true;
    const response = await getCurrenciesFromApi(countryCode);
    isLoading.value = false;
    if (response) {
      const allOptions = mapApiCurrencyResponseToSelectOptions(response.currencies);
      const onlyRealOptions = allOptions.filter((option) => option.value !== '-');
      if (!onlyRealOptions.length && allOptions.length) {
        logger.error(
          `No valid currency provided via getCurrenciesByCountry for ${countryCode.toUpperCase()}, edit regcountries!`,
        );
        return;
      }
      options.value = onlyRealOptions.length === 1 ? onlyRealOptions : allOptions;
      emitChangeValue(options.value[0].value);
    }
  };

  const selectProps = computed<SingleSelectProperties>(() => {
    const { countryCode, loaderDelay, ...singleSelectProps } = props;
    const disabledProps: Partial<SingleSelectProperties> = {
      disabled: true,
      placeholder: $translate('WEB2_CURRENCY_SELECTOR_HINT').value,
    };
    return {
      options: options.value,
      ...singleSelectProps,
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      disabled: singleSelectProps.disabled || options.value.length <= 1,
      ...(props.countryCode ? {} : disabledProps),
    };
  });

  watch(() => props.countryCode, (value, oldValue) => {
    if (value) {
      void getCurrencyOptions(value);
    }
    if (oldValue && !value) {
      // clear
      emitChangeValue('');
    }
  }, { immediate: true });

  return {
    selectProps,
    isLoading,
    emitChange,
    emitFocus,
    emitBlur,
  };
}
