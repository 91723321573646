import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import type { RegisterShopOrderRequest } from '@leon-hub/api-sdk';
import { doRegisterCustomerOrder, getCustomerOffers } from '@leon-hub/api-sdk';

import type { Offer } from 'web/src/modules/bonuses/store/types';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import { useFormatMoney } from 'web/src/modules/money/composables';
import { replacePlaceholders } from 'web/src/utils/replacePlaceholders';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { useErrorsConverter } from 'web/src/modules/errors/composables';
import { useI18n } from 'web/src/modules/i18n/composables';
import { DialogAction, PresetName } from 'web/src/modules/dialogs/enums';

import useDialogs from '../../dialogs/composables/useDialogs';

const useLoyaltyShopStore = defineStore('loyalty-shop', () => {
  const apiClient = useGraphqlClient();
  const formatMoney = useFormatMoney();
  const { $translate } = useI18n();
  const { convertToBaseError } = useErrorsConverter();
  const { closeDialog, showDialog } = useDialogs();
  const { isLoggedIn } = useIsLoggedIn();

  // state
  const offers = ref<Offer[]>([]);
  const selectedItemId = ref<Maybe<number>>(null);
  const orderCategory = ref('');
  const freespinGameIds = ref<string[]>([]);
  const hasError = ref(false);

  // getters
  const currentOffer = computed(() => {
    if (!selectedItemId.value || !offers.value.length) {
      return null;
    }
    return offers.value.find((item) => (item.id === selectedItemId.value));
  });

  // actions
  const setSelectedItemId = (itemId: Maybe<number>) => {
    selectedItemId.value = itemId;
  };
  const fetchCustomerOffers = async () => {
    if (isLoggedIn.value) {
      const response = await getCustomerOffers(
        apiClient,
        (node) => node.queries.leonShop.getCustomerOffers,
        { options: {} },
      );
      offers.value = response.offers.map((offer) => {
        if (offer.placeholders?.length) {
          return {
            ...offer,
            formattedPrice: formatMoney(offer.price, { currency: 'L', hideCurrency: true }),
            name: replacePlaceholders({
              text: offer.name,
              placeholders: offer.placeholders,
              formatMoney,
            }),
            description: replacePlaceholders({
              text: offer.description,
              placeholders: offer.placeholders,
              formatMoney,
            }),
          };
        }

        return offer;
      });
    }
  };

  function showError(message: string, errorCode: string): void {
    let title;

    switch (errorCode) {
      case 'bonus-points.not-enough':
        title = $translate('LEONSHOP_BONUS_POINTS_NOT_ENOUGH_TITLE').value;
        break;
      case 'offer.not-available':
        title = $translate('LEONSHOP_OFFER_NOT_AVAILABLE_TITLE').value;
        break;
      default:
        title = $translate('JS_CAPTION_ATTENTION').value;
    }

    const { subscribe, id } = showDialog({
      id: 'bonus-loyalty-error',
      presetName: PresetName.ALERT_ERROR,
      options: {
        title,
        confirmMessage: message,
        dataTestId: 'bonus-loyalty-error',
      },
    });

    subscribe({
      [DialogAction.MODAL_CLOSE]: () => {
        closeDialog(id);
      },
    });
  }

  const doCustomerOrder = async (options: RegisterShopOrderRequest) => {
    try {
      const response = await doRegisterCustomerOrder(apiClient,
        (node) => node.mutations.leonShop.registerCustomerOrder,
        { options });
      orderCategory.value = response.order.campaignType;
      freespinGameIds.value = response.order.freespinGameIds;
    } catch (rawError) {
      hasError.value = true;
      const error = convertToBaseError(rawError);
      const errorCode = error.code.toString();
      showError(error.message, errorCode);
    }
  };

  return {
    currentOffer,
    orderCategory,
    freespinGameIds,
    offers,
    hasError,
    setSelectedItemId,
    fetchCustomerOffers,
    doCustomerOrder,
  };
});

export default useLoyaltyShopStore;
