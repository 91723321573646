import { getBootstrapTranslations } from '@leon-hub/bootstrap-translations';

import type { ApiErrorOptions } from './ApiError';
import { ApiError } from './ApiError';
import { ApiErrorCode } from './ApiErrorCode';

export class ApiRequestAbortedError extends ApiError {
  constructor(options?: ApiErrorOptions) {
    super({
      ...options,
      message: options?.message ?? getBootstrapTranslations().WEB2_CONNECTION_ERROR_DESCRIPTION,
      code: ApiErrorCode.API_REQUEST_ABORTED_ERROR,
    });
  }
}
