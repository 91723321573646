<script lang="ts" setup>
import { computed } from 'vue';

import { IconName, IconSize } from '@leon-hub/icons';

import type { VIconProps } from '@components/v-icon';
import { VIcon } from '@components/v-icon';

import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import type { VButtonProps } from 'web/src/components/Button/VButton/types';
import VRadio from 'web/src/components/Radio/VRadio/VRadio.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import paymentsCreditCardLogoMapper from 'web/src/modules/payments/utils/paymentsCreditCardLogoMapper';
import VLogo from 'web/src/components/Logo/VLogo/VLogo.vue';
import type PaymentsCreditCardRadioSelectorItemProps
  from 'web/src/modules/payments/form-widgets/CreditCardSelector/components/PaymentsCreditCardRadioSelectorItem/types/PaymentsCreditCardRadioSelectorItemProps';
import { PaymentsCardTypes } from 'web/src/modules/payments/enums';

interface PaymentsCreditCardRadioSelectorItemEmits {
  (e: 'delete', value: string): void;
  (e: 'change', value: string): void;
}

const props = withDefaults(defineProps<PaymentsCreditCardRadioSelectorItemProps>(), {
  pan: '',
});
const emit = defineEmits<PaymentsCreditCardRadioSelectorItemEmits>();

const binProperties: VButtonProps = {
  iconName: IconName.BIN,
  iconSize: IconSize.SIZE_20,
  kind: ButtonKind.TRANSPARENT,
};

const iconProperties: VIconProps = {
  name: IconName.CREDIT_CARD,
};

const isPaymentsCardType = computed<boolean>(() => (props.type ? props.type in PaymentsCardTypes : false));

const vLogoName = computed(() => paymentsCreditCardLogoMapper(props.type ?? null));
function emitDelete(): void {
  emit('delete', props.token);
}
function emitChange(): void {
  emit('change', props.token);
}
</script>

<template>
  <div v-auto-id="'PaymentsCreditCardRadioSelectorItem'"
    v-data-test="{el:'credit-card-radio-selector-item'}"
    :class="{
      [$style['credit-card-radio-selector-item__holder']]: true,
      [$style['credit-card-radio-selector-item__holder--selected']]: isSelected
    }"
    @click="emitChange"
  >
    <div
      :class="$style['credit-card-radio-selector-item__item']"
    >
      <div :class="$style['credit-card-radio-selector-item__card-select']">
        <VRadio
          :input-id="token"
          :checked="isSelected"
          :value="token"
          :name="name"
          :class="$style['credit-card-radio-selector-item__radio']"
          @change="emitChange"
        />
        <div :class="$style['credit-card-radio-selector-item__card']">
          <VLogo
            v-if="isPaymentsCardType"
            :name="vLogoName"
          />
          <VIcon
            v-else
            v-bind="iconProperties"
            :class="$style['credit-card-radio-selector-item__logo']"
          />
        </div>
        <div
          :class="$style['credit-card-radio-selector-item__name']"
        >
          {{ label }}
        </div>
      </div>
      <VButton
        v-if="type && isDeletable && isSelected"
        :class="$style['credit-card-radio-selector-item__delete-button']"
        v-bind="binProperties"
        @click="emitDelete"
      />
    </div>
  </div>
</template>

<style lang="scss" module>
.credit-card-radio-selector-item {
  $self: &;

  &__holder {
    position: relative;
    margin-bottom: 2px;

    @include for-hover {
      &:hover {
        cursor: pointer;

        #{$self}__item {
          background-color: var(--Layer1);
        }

        #{$self}__name {
          color: var(--TextDefault);
        }

        :global(.radio__bg) {
          box-shadow: inset 0 0 0 2px var(--BrandDefault);
        }
      }
    }

    &--selected {
      #{$self}__item {
        user-select: none;
        background-color: var(--Layer1);
      }

      #{$self}__name {
        font-weight: 500;
        color: var(--TextDefault);
        user-select: none;
      }
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 44px;
    background-color: var(--OpacityLayer1);
    border-radius: 5px;
    transition: all 0.15s $animationEaseInOut;
  }

  &__delete-button {
    color: var(--TextSecondary);

    @include for-hover {
      &:hover {
        color: var(--TextDefault);
      }
    }
  }

  &__label {
    position: absolute;
    top: 0;
    right: 40px;
    bottom: 0;
    left: 0;
    cursor: pointer;
  }

  &__card {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 24px;
    margin-right: 12px;
    color: var(--TextSecondary);
  }

  &__logo {
    width: 38px;
    height: 24px;
  }

  &__name {
    @include regular\14\20\025;

    color: var(--TextSecondary);
  }

  &__card-select {
    display: flex;
    align-items: center;
  }

  &__radio {
    justify-content: center;
    min-width: 48px;
  }
}
</style>
