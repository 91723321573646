<script lang="ts" setup>
import { computed } from 'vue';

import { BusEvent, useEventsBus } from '@leon-hub/event-bus';

import { useMiniBoardState } from 'web/src/modules/sportline/submodules/event-details/views/composables';
import EventDetailsMiniScoreBoard from 'web/src/modules/sportline/submodules/event-details/views/EventDetailsMiniScoreBoard.vue';
import { isLiveSportlineEvent } from 'web/src/modules/sportline/utils/sportlineEvent/sportlineTypeCheck';

const bus = useEventsBus();
const {
  isMiniBoardAllowed,
  isMiniBoardShown,
  sportEventDetails,
} = useMiniBoardState();

function onMiniScoreBoardClick(): void {
  bus.emit(BusEvent.LAYOUT_CONTENT_SCROLL_TOP, {});
}

const miniBoardStyles = computed<Dictionary<string>>(() => ({
  'margin-top': isMiniBoardShown.value ? '0' : '-82px',
  'opacity': isMiniBoardShown.value ? '1' : '0',
}));
</script>

<template>
  <div v-auto-id="'SportlineEventDetailsFixedBarRouteComponent'" ref="container">
    <div
      v-if="isMiniBoardAllowed && sportEventDetails && isLiveSportlineEvent(sportEventDetails)"
      :class="$style['mini-board__container']"
    >
      <EventDetailsMiniScoreBoard
        :class="{
          [$style['mini-board']]: true,
          [$style['mini-board--animation-hide']]: !isMiniBoardShown,
        }"
        :style="miniBoardStyles"
        :sport-event-details="sportEventDetails"
        @click="onMiniScoreBoardClick"
      />
    </div>
  </div>
</template>

<style module lang="scss">
@include for-layout using ($isDesktop) {
  .mini-board {
    min-height: var(--sportline-mini-board-height);

    // @hint 82px is just more then header, it could be any value
    margin-top: -82px;
    opacity: 0;
    transition: margin-top 0.2s ease-in-out, opacity 0.2s ease-in-out;

    &--animation-hide {
      transition: margin-top 0.2s ease-in, opacity 0.2s ease-in;
    }

    &__container {
      padding: if($isDesktop, 0 16px, 0);
    }
  }
}
</style>
