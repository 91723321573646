import { defineStore } from 'pinia';
import {
  nextTick,
  ref,
} from 'vue';

import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { IS_CLEAR_PASSWORD_KEY } from 'web/src/modules/user/store/constants';

const useAuthStore = defineStore('auth', () => {
  const { isLoggedIn } = useIsLoggedIn();
  const isTemporarySession = ref(false);
  const isLoadedFromRoot = ref(false);

  function setLoggedIn(value: boolean, fromRoot = false): void {
    isLoggedIn.value = value;

    void nextTick().then(() => {
      if (fromRoot) {
        isLoadedFromRoot.value = true;
      }
    });
  }

  function setIsTemporarySession(value: boolean): void {
    isTemporarySession.value = value;
  }

  function forgotSession(): void {
    if (process.env.VUE_APP_PLATFORM_CORDOVA) {
      localStorage.setItem(IS_CLEAR_PASSWORD_KEY, 'true');
    }
    isLoggedIn.value = false;
  }

  return {
    isLoggedIn,
    isLoadedFromRoot,
    isTemporarySession,
    setLoggedIn,
    forgotSession,
    setIsTemporarySession,
  };
});

export default useAuthStore;
