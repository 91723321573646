import type { ComputedRef } from 'vue';

import type { VButtonProps } from 'web/src/components/Button/VButton/types';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import type { VFeedbackCompositionProps } from 'web/src/components/FeedbackComposition/VFeedbackComposition/types';
import type { I18nFunctionReactive } from 'web/src/modules/i18n/types';

export default function getSubmitButtonProps(
  props: VFeedbackCompositionProps,
  isSubmitButtonDisabled: ComputedRef<boolean>,
  $t: I18nFunctionReactive,
): VButtonProps {
  const processValue = $t('WEB2_PROCESSING').value;
  const sendValue = $t('WEB2_BUTTON_SEND').value;

  return {
    label: props.submitting ? processValue : sendValue,
    kind: ButtonKind.PRIMARY,
    isLoading: props.submitting,
    disabled: isSubmitButtonDisabled.value,
    fullWidth: true,
  };
}
