import type { SlipInfoCaption } from 'web/src/modules/sportline/views/runner/types';
import type { SportEvent } from 'web/src/modules/sportline/types';

export function getSlipCaption(sportEvent: Maybe<SportEvent>): SlipInfoCaption {
  const result: SlipInfoCaption = {};

  if (!sportEvent) {
    return result;
  }

  if (!sportEvent.isOutright) {
    result.competitors = [sportEvent.competitors[0]?.name || '', sportEvent.competitors[1]?.name || ''];
  } else {
    result.eventName = sportEvent.name;
  }

  return result;
}
