<script lang="ts" setup>
import { ButtonKind } from '@leon-hub/module-buttons';

import DefaultModal from 'web/src/components/Modal/DefaultModal/DefaultModal.vue';
import VSwiper from 'web/src/components/Swiper/VSwiper/VSwiper';
import { ModalSelector } from 'web/src/modules/core/enums';
import ModalWindowHeader from 'web/src/components/Modal/ModalWindowHeader/ModalWindowHeader.vue';
import VSocialSharingCopyLink from 'web/src/components/SocialSharing/SocialSharingCopyLink/VSocialSharingCopyLink.vue';
import VSocialSharingItem from 'web/src/components/SocialSharing/VSocialSharingItem/VSocialSharingItem.vue';
import VSwiperSlide from 'web/src/components/Swiper/VSwiperSlide/VSwiperSlide.vue';
import SSocialSharingCopyLink from 'web/src/components/SocialSharing/SocialSharingCopyLink/SSocialSharingCopyLink.vue';
import SButton from 'web/src/components/Button/SButton/SButton.vue';
import LButton from 'web/src/components/Button/LButton/LButton.vue';

import { useVSocialSharing } from './composables';
import type { VSocialSharingEmits, VSocialSharingProps } from './types';

const props = withDefaults(defineProps<VSocialSharingProps>(), {
  showCloseButton: true,
});
const emit = defineEmits<VSocialSharingEmits>();

const modalSelector: string = '1'
  ? ModalSelector.DESKTOP_INNER_MODAL
  : ModalSelector.BODY;

const {
  modalProps,
  headerProps,
  buttonProps,
  handleCopy,
  sendAnalytic,
  close,
} = useVSocialSharing(props, emit);
</script>

<template>
  <Teleport v-auto-id="'VSocialSharing'"
    v-if="shown"
    :to="modalSelector"
  >
    <DefaultModal
      v-bind="modalProps"
      :class="$style['social-sharing__modal']"
      @close="close"
      @button-click="close(false)"
    >
      <template #header>
        <ModalWindowHeader
          v-bind="headerProps"
          :class="$style['social-sharing__header']"
          @prefix-click="close(true)"
          @suffix-click="close(true)"
        />
      </template>
      <template #content>
        <div :class="$style['social-sharing__content']">
          <template v-if="networks.length">
            <div :class="$style['social-sharing__title']">
              {{ $t('WEB2_SHARE_ON_SOCIAL_NETWORKS') }}
            </div>
            <VSwiper
              ref="swiper"
              :class="$style['social-sharing__slider']"
            >
              <VSwiperSlide
                v-for="(network, index) in networks"
                :key="index"
                :class="$style['social-sharing__slide']"
              >
                <VSocialSharingItem
                  v-if="network.status"
                  :url="network.url"
                  :url-for-sharing="url"
                  :title="network.title || ''"
                  :icon="network.iconName"
                  :name="network.name"
                  :background-color="null ? 'var(--ColorBackground2)' : network.backgroundColor || ''"
                  :description="network.description || ''"
                  :hashtags="network.hashtags || ''"
                  :media="network.media || ''"
                  :quote="network.quote || ''"
                  :status="network.status"
                  :restricted-locales="network.restrictedLocales || []"
                  :class="$style['social-sharing__network-item']"
                  @click="sendAnalytic(network.name)"
                />
              </VSwiperSlide>
            </VSwiper>
          </template>
          <SSocialSharingCopyLink
            v-if="null"
            :link="url"
            :label="$t('WEB2_REFERRAL_PROGRAM_COPY_LINK')"
          />
          <VSocialSharingCopyLink
            v-else
            :link="url"
            :label="$t('WEB2_COPY_AND_SEND_LINK_TO_FRIEND')"
            @click="handleCopy"
          />
        </div>
      </template>

      <template
        v-if="showCloseButton"
        #footer
      >
        <div :class="$style['social-sharing__footer']">
          <SButton
            v-if="null"
            v-bind="buttonProps"
            kind="primary"
            @click="close(false)"
          />
          <LButton
            v-else
            v-bind="buttonProps"
            :kind="ButtonKind.SECONDARY"
            @click="close(false)"
          />
        </div>
      </template>
    </DefaultModal>
  </Teleport>
</template>

<style lang="scss" module>
@include for-layout using($isDesktop) {
  .social-sharing {
    &__content {
      display: flex;
      flex-direction: column;

      @if $isDesktop {
        margin: $socialSharingMarginDesktop;
      }
    }

    &__title {
      @include shareTitleFont;

      margin-bottom: $socialSharingTileMarginBottom;
      color: var(--TextDefault);
      text-align: center;

      @include is-app-layout-desktop {
        margin-top: $socialSharingTileMarginTop;
      }
    }

    &__header {
      border-top-left-radius: $modalWindowRadius;
      border-top-right-radius: $modalWindowRadius;
    }

    &__slider {
      margin-bottom: $socialSharingSliderMarginBottom;
    }

    &__network-item {
      margin: $socialSharingNetworkItemMargin;
    }

    &__slide {
      flex: 1;
    }

    &__slide:first-child {
      .social-sharing__network-item {
        margin-left: 0;
      }
    }

    &__footer {
      @if $isDesktop {
        margin: $socialSharingMarginDesktop;
      }
    }
  }
}
</style>
