<script setup lang="ts">
import { VIcon } from '@components/v-icon';
import { SSpinner } from '@components/spinner';
import { VScrollbar } from '@components/v-scrollbar';

import VImage from 'web/src/components/Image/VImage/VImage.vue';
import { TextInput } from 'web/src/components/Input';
import SButton from 'web/src/components/Button/SButton/SButton.vue';
import SBadge from 'web/src/components/Badge/SBadge/SBadge.vue';
import LBadge from 'web/src/components/Badge/LBadge/LBadge.vue';
import { BadgeKind } from 'web/src/components/Badge/VBadge/enums';
import InputHint from 'web/src/components/Input/components/InputHint';
import { useHintParentProps } from 'web/src/components/Input/composables';
import VLoadMoreObserver from 'web/src/components/LoadMoreObserver/VLoadMoreObserver/VLoadMoreObserver.vue';

import DropListSelectDropdown from './DropListSelectDropdown.vue';
import DropListSelectModal from './DropListSelectModal.vue';
import type {
  DropListSelectEmits,
  DropListSelectProps,
  DropListSelectSlots,
} from '../types';
import {
  arrowIconName,
  arrowIconSize,
  buttonIconSize,
  searchIconName,
} from '../constants/staticProps';
import DropListOption from './DropListOption.vue';
import { useDropListSelect } from '../composables/useDropListSelect';

const props = withDefaults(defineProps<DropListSelectProps>(), {
  options: () => [],
});
const emit = defineEmits<DropListSelectEmits>();

const {
  isSelected,
  // search
  searchQuery,
  searchInputRef,
  onSearchInput,
  clearSearchQuery,
  // options
  isSimpleList,
  optionGroups,
  plainOptions,
  setOptionRefs,
  isEmptySearch,
  // ui computed properties
  buttonIconName,
  buttonImgSrc,
  isButtonImageShown,
  selectedText,
  selectedSecondaryText,
  selectedSecondaryBadge,
  isButtonsBlockShown,
  // preselected
  preselectedId,
  primaryButtonRef,
  secondaryButtonRef,
  clearPreselected,
  // dropdown
  isDropdownShown,
  toggleOpen,
  onClose,
  focusOnDropdownButton,
  dropdownButton,
  // handlers
  scrollbarRef,
  onItemClick,
  onOptionHover,
  onPreselect,
  onInputKeydown,
  onDropdownOptionKeydown,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  onActionButtonKeydown,
  onPrimaryButtonFocus,
  onPrimaryButtonBlur,
  onSecondaryButtonFocus,
  onSecondaryButtonBlur,
  onFocus,
  onBlur,
  // container props
  dropContainerProps,
  // pagination
  increasePaginationCount,
  renderComplete,
  isRenderingMore,
  // recalculate scroll
  onDropdownUpdate,
} = useDropListSelect(props, emit);

const { hintProperties, hasError } = useHintParentProps(props);

// slots
defineSlots<DropListSelectSlots>();
</script>

<template>
  <div v-auto-id="'DropListSelect'" :class="$style['drop-list-container']">
    <button
      ref="dropdownButton"
      :class="{
        [$style['drop-list-button']]: true,
        [$style['drop-list-button--active']]: isDropdownShown,
        [$style['drop-list-button--error']]: hasError,
      }"
      type="button"
      @click="toggleOpen"
      @keydown="onDropdownOptionKeydown"
      @focus="onFocus"
      @blur="onBlur"
    >
      <span
        v-if="isButtonImageShown"
        :class="$style['drop-list-button__img-container']"
      >
        <VImage
          v-if="buttonImgSrc"
          :src="buttonImgSrc"
          :alt="selectedText"
          :class="$style['drop-list-button__img']"
        />
        <VIcon
          v-else-if="buttonIconName"
          :name="buttonIconName"
          :size="buttonIconSize"
        />
      </span>
      <span :class="$style['drop-list-button__text-container']">
        <span
          v-if="label"
          :class="$style['drop-list-button__label']"
        >{{ label }}</span>
        <span :class="$style['drop-list-button__value']">
          <template v-if="selectedSecondaryBadge">
            <SBadge
              v-if="null"
              :label="selectedSecondaryBadge"
              type="primary"
              is-counter
            />
            <LBadge
              v-else
              :label="selectedSecondaryBadge"
              :kind="BadgeKind.SQUARE_ERROR"
            />
          </template>
          {{ selectedText }}
          <span
            v-if="selectedSecondaryText"
            :class="$style['drop-list-button__value-secondary']"
          >{{ selectedSecondaryText }}</span>
        </span>
      </span>
      <span :class="$style['drop-list-button__icon-right']">
        <slot name="icon-right">
          <VIcon
            :name="arrowIconName"
            :size="arrowIconSize"
          />
        </slot>
      </span>
      <template v-if="isDropdownShown">
        <Component
          :is="modalDropdown ? DropListSelectModal : DropListSelectDropdown"
          v-bind="dropContainerProps"
          @preselect="onPreselect"
          @return-focus="focusOnDropdownButton"
          @close="onClose"
          @update-position="onDropdownUpdate"
        >
          <template #search>
            <TextInput
              ref="searchInputRef"
              :prefix-icon-name="modalDropdown ? undefined : searchIconName"
              :suffix-icon-name="modalDropdown ? searchIconName : undefined"
              is-hint-hidden
              is-label-hidden
              :placeholder="searchLabel ?? $t('WEB2_SEARCH_TITLE')"
              autocomplete="off"
              :value="searchQuery"
              @input="onSearchInput"
              @clear="clearSearchQuery"
              @blur="focusOnDropdownButton"
              @focus="clearPreselected"
              @keydown="onInputKeydown"
            />
          </template>
          <template #content="{ mounted, onContentRedraw }">
            <VScrollbar
              ref="scrollbarRef"
              :class="$style['drop-list-content__scrollbar']"
            >
              <ul
                :class="{
                  [$style['drop-list-content__list']]: true,
                  [$style['drop-list-content__list--modal']]: modalDropdown,
                  [$style['drop-list-content__list--with-search']]: searchEnabled,
                  [$style['drop-list-content__list--with-footer']]: isButtonsBlockShown,
                }"
                @vue:mounted="mounted"
              >
                <template v-if="isLoadingOptions">
                  <li :class="$style['drop-list-content__loader']">
                    <SSpinner />
                  </li>
                </template>
                <template v-else-if="isSimpleList">
                  <li
                    v-for="(item, index) in plainOptions"
                    :key="item.value"
                    :class="$style['drop-list-content__item']"
                  >
                    <DropListOption
                      :ref="(el) => setOptionRefs(el, 0, index)"
                      v-bind="item"
                      :with-background="modalDropdown"
                      :search-query="searchQuery"
                      :is-active="isSelected(item.value)"
                      :is-preselected="preselectedId === item.value"
                      :is-multiselect-mode="isMultiselectMode"
                      :disable-hover-filter="disableHoverFilter"
                      @click="onItemClick(item.value)"
                      @mouseenter="onOptionHover(item.value)"
                    />
                    <template v-if="itemsToRender">
                      <div
                        v-if="index === plainOptions.length - 1"
                        @vue:mounted="onContentRedraw"
                      />
                    </template>
                  </li>
                </template>
                <template v-else>
                  <li
                    v-for="(group, groupIndex) in optionGroups"
                    :key="groupIndex"
                  >
                    <p
                      v-if="group.caption"
                      :class="{
                        [$style['drop-list-content__caption']]: true,
                        [$style['drop-list-content__caption--modal']]: modalDropdown,
                      }"
                    >
                      {{ group.caption }}
                    </p>
                    <ul :class="$style['drop-list-content__inner-list']">
                      <li
                        v-for="(item, index) in group.options"
                        :key="item.value"
                        :class="$style['drop-list-content__item']"
                      >
                        <DropListOption
                          :ref="(el) => setOptionRefs(el, groupIndex, index)"
                          v-bind="item"
                          :with-background="modalDropdown"
                          :is-active="isSelected(item.value)"
                          :is-preselected="preselectedId === item.value"
                          :is-multiselect-mode="isMultiselectMode"
                          :disable-hover-filter="disableHoverFilter"
                          :search-query="searchQuery"
                          @click="onItemClick(item.value)"
                          @mouseenter="onOptionHover(item.value)"
                        />
                        <template v-if="itemsToRender">
                          <div
                            v-if="(groupIndex === optionGroups.length - 1) && (index === group.options.length - 1)"
                            @vue:mounted="onContentRedraw"
                          />
                        </template>
                      </li>
                    </ul>
                  </li>
                </template>
                <li v-if="isEmptySearch">
                  <DropListOption
                    :label="$t('WEB2_NOTHING_FOUND')"
                    value=""
                    :with-background="modalDropdown"
                    @click="clearSearchQuery"
                  />
                </li>
              </ul>
              <VLoadMoreObserver
                v-if="itemsToRender && !renderComplete && !isRenderingMore"
                @load-more="increasePaginationCount(onContentRedraw)"
              />
            </VScrollbar>
          </template>
          <template #footer>
            <SButton
              v-if="primaryButtonLabel"
              ref="primaryButtonRef"
              :class="$style['drop-list-content__button']"
              kind="primary"
              height="medium"
              :label="primaryButtonLabel"
              @focus="onPrimaryButtonFocus"
              @blur="onPrimaryButtonBlur"
              @click="onPrimaryButtonClick"
              @keydown="onActionButtonKeydown"
            />
            <SButton
              v-if="secondaryButtonLabel"
              ref="secondaryButtonRef"
              :class="$style['drop-list-content__button']"
              kind="quinary-primary"
              height="medium"
              :label="secondaryButtonLabel"
              @focus="onSecondaryButtonFocus"
              @blur="onSecondaryButtonBlur"
              @click="onSecondaryButtonClick"
              @keydown="onActionButtonKeydown"
            />
          </template>
        </Component>
      </template>
    </button>
    <InputHint
      v-if="!isHintHidden"
      v-bind="hintProperties"
    />
  </div>
</template>

<style module lang="scss">
.drop-list-container {
  display: flex;
  flex-flow: column wrap;
  flex-grow: 1;
}

.drop-list-button {
  @include dropListButton;

  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
  text-align: left;
  cursor: pointer;
  border: none;

  &:hover {
    @include for-hover {
      @include dropListButtonHover;
    }
  }

  &--active,
  &:focus {
    @include dropListButtonActive;
  }

  &--error {
    @include dropListButtonError;
  }

  &__img-container {
    display: flex;
    flex: none;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    margin-right: 8px;
    overflow: hidden;
  }

  &__img-container,
  &__icon-right {
    color: $inputValueErrorColorVar;
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__text-container {
    display: block;
    flex: 1 1 auto;
    overflow: hidden;
  }

  &__label,
  &__value {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__label {
    @include inputHintFont;

    color: $inputPlaceholderDefaultColorVar;
    user-select: none;
  }

  &__value {
    @include medium\14\20\025;

    color: $inputValueErrorColorVar;
  }

  &__value-secondary {
    @include regular\14\20\025;

    color: $inputPlaceholderFocusColorVar;
  }

  &__icon-right {
    flex: none;
  }
}

.drop-list-content {
  &__scrollbar {
    position: relative;
    flex: 0 1 100%;
    height: 100%;
  }

  &__list {
    padding: $dropMenuListPadding;

    &--modal {
      @include dropListModalSidePadding;
    }

    &--with-search {
      padding-top: 0;
    }

    &--with-footer {
      padding-bottom: 0;
    }
  }

  &__list,
  &__inner-list {
    margin: 0;
    list-style: none inside;
  }

  &__inner-list {
    padding: 0;
  }

  &__caption {
    @include medium\13\20;
    @include dropListCaption;

    &--modal {
      @include dropListCaptionModal;
    }
  }

  &__item {
    @include dropListItem;

    &:first-child {
      margin-top: 0;
    }
  }

  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__button {
    flex: 1 1 auto;

    &:not(:last-child) {
      margin-right: $dropMenuListPadding;
    }
  }
}
</style>
