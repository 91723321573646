import type { Ref } from 'vue';
import { computed, ref } from 'vue';

import type { SportFamily } from '@leon-hub/api-sdk';
import { useLocalStorageManager } from '@leon-hub/local-storage';

interface MarketsListOpenStateStorageProps {
  representationSportFamily?: Ref<Maybe<string | SportFamily>>;
}

interface MarketsListLocalStorageMethodsComposable {
  closedMarketsGroupsStorageKey: Ref<string>;
  saveClosedMarketGroupsToStorage(closedMarketGroups: string[]): void;
  loadClosedMarketGroupsFromStorage(): string[];
}

interface MarketsListOpenStateStorageComposable extends MarketsListLocalStorageMethodsComposable {
  closedMarketGroups: Ref<string[]>;
  toggleMarketGroupClosedStateInTheStorage(groupKey: string): void;
}

function useMarketsListLocalStorageMethods(
  props: { representationSportFamily?: Ref<Maybe<string | SportFamily>> },
): MarketsListLocalStorageMethodsComposable {
  const closedMarketsGroupsStorageKeyPrefix = 'sport-event-group-state';
  const localStorageManager = useLocalStorageManager();

  const closedMarketsGroupsStorageKey = computed<string>(
    // eslint-disable-next-line max-len
    () => `${closedMarketsGroupsStorageKeyPrefix}-${props.representationSportFamily?.value?.toLowerCase() || 'unknown'}`,
  );

  function saveClosedMarketGroupsToStorage(closedMarketGroups: string[]): void {
    localStorageManager.setItem(closedMarketsGroupsStorageKey.value, JSON.stringify(closedMarketGroups));
  }

  function loadClosedMarketGroupsFromStorage(): string[] {
    try {
      const items = localStorageManager.getItem(closedMarketsGroupsStorageKey.value);
      return items ? JSON.parse(items) : [];
    } catch {
      return [];
    }
  }

  return {
    closedMarketsGroupsStorageKey,
    saveClosedMarketGroupsToStorage,
    loadClosedMarketGroupsFromStorage,
  };
}

export default function useMarketsListOpenStateStorage(
  props: MarketsListOpenStateStorageProps,
): MarketsListOpenStateStorageComposable {
  const {
    closedMarketsGroupsStorageKey,
    saveClosedMarketGroupsToStorage,
    loadClosedMarketGroupsFromStorage,
  } = useMarketsListLocalStorageMethods(props);

  const closedMarketGroups = ref<string[]>(loadClosedMarketGroupsFromStorage());

  function toggleMarketGroupClosedStateInTheStorage(groupKey: string): void {
    const closedMarketGroupsUpdated = [...closedMarketGroups.value];
    const existingIndex = closedMarketGroupsUpdated.indexOf(groupKey);

    if (existingIndex > -1) {
      closedMarketGroupsUpdated.splice(existingIndex, 1);
    } else {
      closedMarketGroupsUpdated.push(groupKey);
    }

    closedMarketGroups.value = closedMarketGroupsUpdated;
    saveClosedMarketGroupsToStorage(closedMarketGroupsUpdated);
  }

  return {
    closedMarketGroups,
    closedMarketsGroupsStorageKey,
    toggleMarketGroupClosedStateInTheStorage,
    saveClosedMarketGroupsToStorage,
    loadClosedMarketGroupsFromStorage,
  };
}
