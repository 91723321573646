/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
import type { Router } from 'vue-router';
import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
} from 'vue-router';

import { useRouterStore } from 'web/src/modules/core/store';
import { useI18nStore } from 'web/src/modules/i18n/store';
import { getBaseUrl } from 'web/src/modules/core/utils';

import {
  createCordovaNavigationGuard,
  create301RedirectNavigationGuard,
  createQueryNavigationGuard,
  createAccessRestrictionsNavigationGuard,
  createRegistrationNavigationGuard,
  checkLanguagePrefix,
  createDesktopModalNavigationGuard,
  createBaseNavigationGuard,
  createSnackBarNavigationGuard,
  createThemeNavigationGuard,
  createSeoMetaDataNavigationGuard,
  createHeaderBackButtonNavigationGuard,
  createDevtoolsNavigationGuard,
} from './navigation-guards';
import createRoutes from './config/createRoutes';
import type {
  AppVueRouter,
} from './types';
import getAppVueRouter from './getAppVueRouter';
import ModalOpenedRouteError from './errors/ModalOpenedRouteError';
import { syncAuthStateToRoute } from './utils';

export default function createAppRouter(nuxtRouter?: Router): AppVueRouter {
  if (process.env.VUE_APP_RENDERING_CSR) {
    window.history.replaceState(null, '');
  }
  const i18nStore = useI18nStore();
  const {
    replacedUrlPrefix,
    currentUrlPrefix,
  } = i18nStore;

  const base = getBaseUrl(currentUrlPrefix) || process.env.BASE_URL || '/';

  const redirectUri = checkLanguagePrefix({
    replacedUrlPrefix,
    currentUrlPrefix,
  });

  let router;
  if (nuxtRouter) {
    router = { ...nuxtRouter, ...getAppVueRouter(nuxtRouter) };
  } else {
    router = getAppVueRouter(createRouter({
      history: process.env.VUE_APP_PLATFORM_CORDOVA && process.env.NODE_ENV === 'production'
        ? createWebHashHistory(base)
        : createWebHistory(base),
      routes: [],
    }));
  }

  const { seoConfigs } = useRouterStore();
  createRoutes(router, {
    seoConfigs,
  });

  syncAuthStateToRoute(router);

  router.onError((error) => {
    if (!(error instanceof ModalOpenedRouteError)) {
      throw error;
    }

    return false;
  });

  createDevtoolsNavigationGuard(router);
  create301RedirectNavigationGuard(router, { redirectUri });

  if (process.env.VUE_APP_PLATFORM_CORDOVA) {
    createCordovaNavigationGuard(router);
  }

  if (process.env.VUE_APP_RENDERING_CSR) {
    createSnackBarNavigationGuard(router);
  }
  createThemeNavigationGuard(router);
  createHeaderBackButtonNavigationGuard(router);

  createQueryNavigationGuard(router);
  createAccessRestrictionsNavigationGuard(router);

  if (process.env.VUE_APP_FEATURE_SIMPLIFIED_REGISTRATION_ENABLED || process.env.VUE_APP_FEATURE_STEP_REGISTRATION_ENABLED) {
    createRegistrationNavigationGuard(router);
  }

  if (process.env.VUE_APP_LAYOUT_DESKTOP) {
    createDesktopModalNavigationGuard(router);
  }

  if (!process.env.VUE_APP_PLATFORM_CORDOVA) {
    createSeoMetaDataNavigationGuard(router);
  }

  createBaseNavigationGuard(router);

  return router;
}
