import EventsBus from './EventsBus';

let eventsBus: EventsBus | undefined;

export const setBus = (bus: EventsBus): void => {
  eventsBus = bus;
};

export default function useEventsBus(): EventsBus {
  if (!eventsBus) {
    eventsBus = new EventsBus();
  }

  return eventsBus;
}
