import { isHistoryLobbyItem } from 'web/src/modules/lobby/utils';
import type { LobbyBlockProps } from 'web/src/modules/lobby/components/LobbyBlock/types';
import type { LobbyItemUnit } from 'web/src/modules/lobby/types';

export default function getLobbyItems(
  props: LobbyBlockProps,
  items: LobbyItemUnit[],
): LobbyItemUnit[] {
  if (props.isSport) {
    return items.filter((item) => isHistoryLobbyItem(item));
  }

  return items;
}
