import type { Ref } from 'vue';
import { ref } from 'vue';

import { RegistrationStage } from 'web/src/modules/registration/enums';

export interface GoToStageTarget {
  stage: RegistrationStage;
  clearHistory?: boolean;
}

interface UseRegistrationStageComposable {
  stage: Ref<RegistrationStage>;
  previousStages: Ref<RegistrationStage[]>;
  goToStage(target: GoToStageTarget): void;
  goToPreviousStage(): void;
  resetStages(): void;
}

export function useRegistrationStage(): UseRegistrationStageComposable {
  const stage = ref(RegistrationStage.Main);
  const previousStages = ref<RegistrationStage[]>([]);

  function goToStage(target: GoToStageTarget): void {
    const currentStage = stage.value;

    if (target.clearHistory) {
      previousStages.value = [];
    } else if (!previousStages.value.includes(currentStage)) {
      previousStages.value = [...previousStages.value, currentStage];
    }

    stage.value = target.stage;
  }

  function goToPreviousStage(): void {
    const stages = [...previousStages.value];
    const lastStage = stages.pop();

    previousStages.value = stages;

    if (lastStage) {
      stage.value = lastStage;
    }
  }

  return {
    stage,
    previousStages,
    goToStage,
    goToPreviousStage,
    resetStages(): void {
      stage.value = RegistrationStage.Main;
      previousStages.value = [];
    },
  };
}
