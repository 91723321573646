import StopIteration from 'web/src/modules/errors/errors/StopIteration';

export default class HighLighter {
  public static highlight(
    inputString: string,
    searchText: string,
    className = 'highlighted-text',
    exploded = false,
  ): string {
    if (!searchText) {
      return inputString;
    }
    const html = document.createElement('div');
    html.innerHTML = inputString;
    HighLighter.highlightInDom(html, searchText, className, exploded);
    return html.innerHTML;
  }

  private static highlightInDom(element: Node, searchText: string, className = 'highlighted-text', exploded = false) {
    if (element.hasChildNodes()) {
      for (const node of element.childNodes) {
        if (node.nodeType === Node.TEXT_NODE) {
          const mark = document.createElement('span');
          mark.innerHTML = node.nodeValue
            // eslint-disable-next-line max-len
            ? node.nodeValue.replace(new RegExp(`(${HighLighter.getSearchRegexp(searchText, exploded)})`, 'igm'), `<span class="${className}">$1</span>`)
            : '';
          if (mark.innerHTML !== node.nodeValue) {
            node.replaceWith(mark);
          }
        } else {
          HighLighter.highlightInDom(node, searchText, className);
        }
      }
    }
  }

  public static isHighlighted(inputString: string, searchText: string, exploded = false): boolean {
    if (!searchText) {
      return true;
    }
    const html = document.createElement('div');
    html.innerHTML = inputString;
    return HighLighter.isHighlighedInDom(html, searchText, exploded);
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  private static isHighlighedInDom(element: Node, searchText: string, exploded = false): boolean {
    let highlighted = false;
    if (element.hasChildNodes()) {
      try {
        for (const node of element.childNodes) {
          if (node.nodeType === Node.TEXT_NODE) {
            highlighted = node.nodeValue
              ? new RegExp(`(${HighLighter.getSearchRegexp(searchText, exploded)})`, 'igm').test(node.nodeValue)
              : false;
          } else {
            highlighted = HighLighter.isHighlighedInDom(node, searchText);
          }

          if (highlighted) {
            throw new StopIteration();
          }
        }
      } catch {
        //
      }
    }

    return highlighted;
  }

  private static getSearchRegexp(searchText: string, exploded = false): string {
    const escapedSearchText = searchText.replace(/[$()*+./?[\\\]^{|}-]/g, '\\$&');
    return exploded ? escapedSearchText.split(' ').join('|') : escapedSearchText;
  }
}
