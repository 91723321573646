import { useRouter } from 'vue-router';
import type { Ref } from 'vue';
import { computed, ref, toRef } from 'vue';

import type { AppError } from '@leon-hub/app-errors';
import { RouteName } from '@leon-hub/routing-config-names';
import { PinCodeRequiredExceptionCode } from '@leon-hub/api-sdk';

import { useDialogs } from 'web/src/modules/dialogs/composables';
import { AnalyticsModalType, DialogAction, PresetName } from 'web/src/modules/dialogs/enums';
import CustomerFormError from 'web/src/utils/forms/CustomerFormError';
import type { FormExternalErrors } from 'web/src/components/Form/types';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
import { useI18n } from 'web/src/modules/i18n/composables';

interface PaymentErrorCompasable {
  customErrors: Ref<Optional<FormExternalErrors>>;
  handlePaymentError(value: AppError | CustomerFormError, customHandler?: () => boolean): void;
}

export default function usePaymentError(): PaymentErrorCompasable {
  const { showDialog } = useDialogs();
  const router = useRouter();
  const { $translate } = useI18n();
  const customerDataStore = useCustomerDataStore();

  const customerData = toRef(customerDataStore, 'customerData');

  const isSelfExcluded = computed(() => customerData.value?.isSelfExcluded ?? false);

  const customErrors = ref<Optional<FormExternalErrors>>();

  function paymentErrorSelfExcludedModal(value: Error): void {
    showDialog({
      presetName: PresetName.CONFIRM,
      options: {
        confirmMessage: value?.message,
        title: $translate('WEB2_OPERATION_FAILED').value,
        analyticsModalType: AnalyticsModalType.WARNING,
        buttons: [
          {
            label: $translate('WEB2_LEARN_MORE').value,
          },
          {
            label: $translate('JSP_PCL_FBOT_CLOSE').value,
          },
        ],
      },
    }).subscribe({
      [DialogAction.CONFIRM]: () => {
        void router.push({ name: RouteName.RESPONSIBLE_GAMBLING_SELF_EXCLUSION });
      },
    });
  }

  function paymentErrorWithCloseButtonShowModal(value: AppError): void {
    showDialog({
      presetName: PresetName.ALERT_WARNING,
      options: {
        confirmMessage: value?.message,
        title: $translate('WEB2_OPERATION_FAILED').value,
      },
    });
  }

  function handlePaymentError(value: AppError | CustomerFormError, customHandler?: () => boolean): void {
    if (value instanceof CustomerFormError) {
      customErrors.value = value.customErrors;
      return;
    }

    if (isSelfExcluded.value) {
      paymentErrorSelfExcludedModal(value);
      return;
    }

    if (customHandler && customHandler()) {
      return;
    }

    if (!value.code.equals(PinCodeRequiredExceptionCode.PIN_CODE_VERIFICATION_NEEDED)) {
      paymentErrorWithCloseButtonShowModal(value);
    }
  }

  return {
    customErrors,
    handlePaymentError,
  };
}
