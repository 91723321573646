import type { Ref } from 'vue';
import { computed } from 'vue';

import { defaultDateTimeFormat } from 'web/src/modules/profile/submodules/customer-history/utils';
import { useFormatMoney } from 'web/src/modules/money/composables';

import type { CustomerHistoryListItemPaymentEmits, CustomerHistoryListItemPaymentProps } from '../types';

export interface CustomerHistoryListItemPaymentComposable {
  dateProperties: Ref<string>;
  isStatus: Ref<boolean>;
  isMoneyPositive: Ref<boolean>;
  money: Ref<string>;
  isWithdrawCancellable: Ref<boolean>;
  cancelWithdrawal: () => void;
}

// eslint-disable-next-line max-len
export default function useCustomerHistoryListItemPayment(props: CustomerHistoryListItemPaymentProps, emit: CustomerHistoryListItemPaymentEmits): CustomerHistoryListItemPaymentComposable {
  const $formatMoney = useFormatMoney();

  const dateProperties = computed<string>(() => defaultDateTimeFormat(props.item.date));
  const isStatus = computed<boolean>(() => !!props.item?.status);
  const isMoneyPositive = computed<boolean>(() => props.item.credit > 0);
  const money = computed<string>(() => {
    const { debit, credit } = props.item;
    let text = '';
    if (debit) {
      text = `-${$formatMoney(debit)}`;
    }
    if (credit) {
      text = `+${$formatMoney(credit)}`;
    }
    return text;
  });

  const isWithdrawCancellable = computed<boolean>(() => props.item.withdrawCancellable);

  function cancelWithdrawal(): void {
    if (isWithdrawCancellable.value) {
      emit('cancel-withdrawal', props.item);
    }
  }

  return {
    dateProperties,
    isStatus,
    isMoneyPositive,
    money,
    isWithdrawCancellable,
    cancelWithdrawal,
  };
}
