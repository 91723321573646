import { getDetails } from '@leon-hub/api-sdk';

import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import type { ProgramDetailsGetDataDocument } from 'web/src/modules/referral-program/types/ReferralProgramDocuments';

export default async function getDetailsData(): Promise<ProgramDetailsGetDataDocument> {
  const apiClient = useGraphqlClient();

  const response = await getDetails(
    apiClient,
    (node) => node.queries.refProgram.getDetails,
    {
      options: {},
    },
    {
      silent: true,
    },
  );

  return response || {};
}
