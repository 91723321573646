<script setup lang="ts">
import { onMounted } from 'vue';

import BetSlipMyBets from '../components/BetSlipMyBets';
import { useMyBetsView } from '../composable';

const {
  myBetsList,
  pendingBetsCount,
  loadPendingBets,
  loadMorePendingBets,
  isPendingBetsLoaded,
  isPendingBetsLoadingMore,
  cashoutClick,
  historyLinkClick,
  myBetsItemClick,
  historyUrl,
} = useMyBetsView();

onMounted(loadPendingBets);
</script>

<template>
  <BetSlipMyBets v-auto-id="'BetSlipMyBetsView'"
    :list="myBetsList"
    :is-loaded="isPendingBetsLoaded"
    :is-loading-more="isPendingBetsLoadingMore"
    :placed-bets-count="pendingBetsCount"
    :history-url="historyUrl"
    @load-more="loadMorePendingBets"
    @cash-out="cashoutClick"
    @history-link-click="historyLinkClick"
    @item-click="myBetsItemClick"
  />
</template>
