<script setup lang="ts">
import {
  computed, ref,
} from 'vue';

import { ButtonKind } from '@leon-hub/module-buttons';

import VSwitcher from 'web/src/components/Switcher/VSwitcher/VSwitcher.vue';
import VHintBlock from 'web/src/components/HintBlock/VHintBlock/VHintBlock.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import VCircularProgress from 'web/src/components/CircularProgress/VCircularProgress/VCircularProgress.vue';
import { CircleSize } from 'web/src/components/CircularProgress/VCircularProgress/enums';
import { useFormatMoney } from 'web/src/modules/money/composables';
import type {
  ResponsibleGamblingBetLimitInfoStepEmits,
  ResponsibleGamblingBetLimitInfoStepProps,
} from 'web/src/modules/profile/submodules/responsible-gambling/steps/ResponsibleGamblingInfoStep/types';
import { StepName } from 'web/src/modules/profile/submodules/responsible-gambling/enums';
import VDynamicContent from 'web/src/components/DynamicContent/VDynamicContent/VDynamicContent.vue';
import SHintBlock from 'web/src/components/HintBlock/SHintBlock/SHintBlock.vue';
import SButton from 'web/src/components/Button/SButton/SButton.vue';
import { SwitcherKind } from 'web/src/components/Switcher/VSwitcher/enums';

const props = withDefaults(defineProps<ResponsibleGamblingBetLimitInfoStepProps>(), {});
const emits = defineEmits<ResponsibleGamblingBetLimitInfoStepEmits>();

const formatMoney = useFormatMoney();

const circular = ref<{ circularCircle: HTMLElement } | null>(null);
const circularValue = ref<HTMLElement | null>(null);

const limitContainerRef = ref<HTMLElement | null>(null);
const limitValueRef = ref<HTMLElement | null>(null);

const limitValue = computed(() => formatMoney(Number(props.data?.limit ?? 0)));
const leftValue = computed(() => formatMoney(Number(props.data?.left ?? 0)));

const handleActiveTabChange = (value: string) => {
  emits('tab-change', value);
};

const handleActiveStepChange = () => {
  emits('step-change', StepName.EDIT);
};

const handleReset = () => {
  emits('reset');
};

const handleBack = () => {
  emits('back');
};
</script>

<template>
  <section v-auto-id="'ResponsibleGamblingInfoStep'" :class="$style['rg-bet-limit-info']">
    <VSwitcher
      :kind="null ? SwitcherKind.PRIMARY : SwitcherKind.DEFAULT"
      :active-id="limit"
      :options="switcherOptions"
      :show-divider="false"
      :class="$style['rg-bet-limit-info__switcher']"
      @change="handleActiveTabChange"
    />

    <SHintBlock
      v-if="null"
      :has-icon="false"
      type="quaternary"
      :class="$style['rg-bet-limit-info__hint']"
    >
      <template #title>
        <span :class="$style['rg-bet-limit-info__hint-title']">
          {{ !!Number(data.limit) ? $t('WEB2_RG_LIMIT_TITLE_ENABLED') : $t('WEB2_RG_LIMIT_TITLE_DISABLED') }}
        </span>
      </template>
      <VDynamicContent
        :content="!!Number(data.limit) ? $t('WEB2_RG_LIMIT_DESCRIPTION_ENABLED') : $t('WEB2_RG_LIMIT_DESCRIPTION_DISABLED')"
      />
    </SHintBlock>

    <VHintBlock
      v-else
      :has-icon="false"
      :class="$style['rg-bet-limit-info__hint']"
    >
      <template #title>
        <span>
          {{ !!Number(data.limit) ? $t('WEB2_RG_LIMIT_TITLE_ENABLED') : $t('WEB2_RG_LIMIT_TITLE_DISABLED') }}
        </span>
      </template>
      <VDynamicContent
        :content="!!Number(data.limit) ? $t('WEB2_RG_LIMIT_DESCRIPTION_ENABLED') : $t('WEB2_RG_LIMIT_DESCRIPTION_DISABLED')"
      />
    </VHintBlock>

    <div :class="$style['rg-bet-limit-info__content']">
      <div
        ref="limitContainerRef"
        :class="$style['rg-bet-limit-info__info']"
      >
        <div :class="$style['rg-bet-limit-info__title']">
          {{ $t('WEB2_RG_CURRENT_LIMIT') }}
        </div>
        <div
          ref="limitValueRef"
          v-auto-resize="{
            childRef: limitValueRef,
            parentRef: limitContainerRef,
            classOnOverflow: $style['rg-bet-limit-info__count-small']
          }"
          :class="$style['rg-bet-limit-info__count']"
        >
          {{ limitValue }}
        </div>

        <div
          v-if="!null"
          :class="$style['rg-bet-limit-info__buttons-wrapper']"
        >
          <div
            v-if="!!Number(data.limit)"
            :class="$style['rg-bet-limit-info__buttons']"
          >
            <VButton
              full-width
              :disabled="loading"
              @click="handleActiveStepChange"
            >
              {{ $t('WEB2_RG_BUTTON_CHANGE') }}
            </VButton>
            <VButton
              full-width
              :kind="ButtonKind.TRANSPARENT"
              :is-loading="loading"
              @click="handleReset"
            >
              {{ $t('WEB2_RG_BUTTON_REMOVE') }}
            </VButton>
          </div>
          <VButton
            v-else
            full-width
            @click="handleActiveStepChange"
          >
            {{ $t('WEB2_RG_BUTTON_SET') }}
          </VButton>
        </div>
      </div>

      <VCircularProgress
        ref="circular"
        :percentage="data.percent"
        :circle-size="null ? CircleSize.SIZE_218 : CircleSize.SIZE_152"
      >
        <div
          ref="circularValue"
          v-auto-resize="{
            childRef: circularValue,
            parentRef: circular?.circularCircle,
            minSpace: 30,
            classOnOverflow: $style['rg-bet-limit-info__set-container-small']
          }"
          :class="$style['rg-bet-limit-info__set-container']"
        >
          <div
            v-if="!!Number(data.limit)"
            :class="$style['rg-bet-limit-info__set']"
          >
            <span :class="$style['rg-bet-limit-info__set-label']">{{ $t('WEB2_RG_LEFT') }}</span>
            <span :class="$style['rg-bet-limit-info__set-value']">{{ leftValue }}</span>
          </div>
          <span
            v-else
            :class="$style['rg-bet-limit-info__not-set']"
          >
            {{ leftValue }}
          </span>
        </div>
      </VCircularProgress>

      <div
        v-if="null"
        :class="$style['rg-bet-limit-info__buttons-wrapper']"
      >
        <div
          v-if="!!Number(data.limit)"
          :class="$style['rg-bet-limit-info__buttons']"
        >
          <SButton
            full-width
            :disabled="loading"
            size="large"
            @click="handleActiveStepChange"
          >
            {{ $t('WEB2_RG_BUTTON_CHANGE') }}
          </SButton>
          <SButton
            full-width
            kind="quaternary-secondary"
            :is-loading="loading"
            size="large"
            @click="handleReset"
          >
            {{ $t('WEB2_RG_BUTTON_REMOVE') }}
          </SButton>
        </div>
        <SButton
          v-else
          full-width
          size="large"
          @click="handleActiveStepChange"
        >
          {{ $t('WEB2_RG_BUTTON_SET') }}
        </SButton>
      </div>
    </div>

    <SButton
      v-if="null"
      full-width
      kind="tertiary-primary"
      size="large"
      @click="handleBack"
    >
      {{ $t('WEB2_RG_BACK_TO_GAME') }}
    </SButton>

    <VButton
      v-else
      full-width
      :kind="ButtonKind.SECONDARY_DARK"
      @click="handleBack"
    >
      {{ $t('WEB2_RG_BACK_TO_GAME') }}
    </VButton>
  </section>
</template>

<style module lang="scss">
.rg-bet-limit-info {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: $rgInfoPaddingMobile;

  &__switcher {
    margin-bottom: 16px;
  }

  &__hint {
    margin-bottom: 12px;

    &-title {
      @include rgHintTitle;
    }
  }

  &__content {
    @include rgContent;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__info {
    @include rgInfo;

    display: inline-flex;
  }

  &__buttons {
    @include rgButtons;

    display: flex;

    &-wrapper {
      width: 100%;
    }
  }

  &__title {
    @include rgTitle;
  }

  &__count {
    @include rgCount;

    width: fit-content;
    white-space: nowrap;

    &-small {
      @include rgCountSmall;
    }
  }

  &__set {
    @include rgSet;

    display: inline-flex;
    flex-direction: column;
    align-items: center;

    &-label {
      @include rgSetLabel;
    }

    &-container {
      @include rgSetValue;

      &-small {
        @include rgSetValueSmall;
      }
    }

    &-value {
      white-space: nowrap;
    }
  }

  &__not-set {
    @include rgNotSet;
  }

  @include is-app-layout-desktop {
    padding: $rgInfoPaddingDesktop;
  }
}
</style>
