import type { RouteLocationNormalized, NavigationGuardNext } from 'vue-router';
import { toRef } from 'vue';

import { RouteName } from '@leon-hub/routing-config-names';

import { AbstractPrefetch } from 'web/src/modules/core/services/router/prefetch';
import type { AppVueRouter } from 'web/src/modules/core/services/router/types';
import { useSiteConfigStore } from 'web/src/modules/core/store';

export default class ReferralProgramRegistrationPrefetch extends AbstractPrefetch {
  // eslint-disable-next-line class-methods-use-this
  prefetch(
    router: AppVueRouter,
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ): Promise<void> {
    const isProfileReferralProgramEnabled = toRef(useSiteConfigStore(), 'isProfileReferralProgramEnabled');

    if (!isProfileReferralProgramEnabled.value || !to.params.code) {
      next({
        name: RouteName.HOME,
      });
      return Promise.resolve();
    }

    next();
    return Promise.resolve();
  }
}
