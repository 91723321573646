import type { JSONSchemaProperties } from '@leon-hub/form-utils';

import type { FormSchema } from 'web/src/components/Form/types';

export function phoneSchemaProperties(): JSONSchemaProperties {
  if (process.env.VUE_APP_FEATURE_PHONE_PATTERN_STARTS_WITH_9_ENABLED) {
    return {
      suffix: {
        type: 'string',
        pattern: '^9\\d{9}$',
      },
      prefix: {
        type: 'string',
        enum: ['7'],
      },
    };
  }
  return {
    suffix: {
      type: 'string',
      pattern: '^[1-9][0-9]{4,14}$',
    },
    prefix: {
      type: 'string',
    },
  };
}

const schema: FormSchema = {
  $schema: 'http://json-schema.org/draft-07/schema#',
  type: 'object',
  title: 'Callback',
  required: ['phone'],
  properties: {
    phone: {
      type: 'object',
      properties: phoneSchemaProperties(),
      required: ['suffix', 'prefix'],
    },
  },
};

export default schema;
