import {
  ErrorMessageUtils,
  normalizeError,
  AbstractError,
} from '@leon-hub/errors';
import { AppError } from '@leon-hub/app-errors';

import { useI18n } from 'web/src/modules/i18n/composables';

export interface UseErrorsConverterComposable {
  convertToBaseError(error: unknown, message?: string): AbstractError;
}

export default function useErrorsConverter(): UseErrorsConverterComposable {
  const { $translate } = useI18n();
  const defaultMessage = $translate('WEB2_TECHNICAL_ERROR');

  function convertToBaseError(error: unknown, message?: string): AbstractError {
    if (error instanceof AbstractError) {
      if (ErrorMessageUtils.isBtrKey(error.message)) {
        // eslint-disable-next-line no-param-reassign
        error.message = $translate(ErrorMessageUtils.normalizeBtrKey(error.message)).value;
      }

      return error;
    }

    return new AppError({
      message: message || defaultMessage.value,
      originalError: normalizeError(error, message),
    });
  }

  return {
    convertToBaseError,
  };
}
