<script setup lang="ts">
import { IconName, IconSize } from '@leon-hub/icons';

import { VIcon } from '@components/v-icon';

import { ModalSelector } from 'web/src/modules/core/enums';
import { VCountryFlag } from 'web/src/components/CountryFlag/VCountryFlag';
import VFlagIcon from 'web/src/components/CountryFlag/VFlagIcon/VFlagIcon.vue';
import VCountriesSelector from 'web/src/components/CountriesSelector/VCountriesSelector/VCountriesSelector.vue';
import ModalPortal from 'web/src/components/Modal/ModalPortal/ModalPortal.vue';

import InputButton from '../InputButton/InputButton.vue';
import type { PhoneInputEmits, PhoneInputProps } from '../../types';
import { InputMode, TextInputTypes } from '../../enums';
import InputWrapper from '../InputWrapper';
import InputBorderWrapper from '../InputBorderWrapper';
import TextFlatInput from '../TextFlatInput';
import usePhoneInput from './usePhoneInput';

// eslint-disable-next-line vue/define-macros-order
const props = withDefaults(defineProps<PhoneInputProps>(), {
  name: 'phone',
  countryCode: 'MT',
  phonePrefix: '356',
});
const emits = defineEmits<PhoneInputEmits>();

defineOptions({
  inheritAttrs: false,
});

const {
  uniqueId,
  isEmpty,
  isFocus,
  isHover,
  formattedValue,
  showMask,
  maskedValue,
  selectedPrefix,
  selectedCountryCode,
  onPrefixClick,
  toggleOpenPrefixModal,
  isPrefixModalShown,
  isMaskedPlaceholderHidden,
  showClearButton,
  onCountrySelect,
  currentPlaceholder,
  hasError,
  onBlur,
  onChange,
  onFocus,
  onPaste,
  onInput,
  onMouseLeave,
  onMouseOver,
  onKeyDown,
  onClear,
} = usePhoneInput(props, emits);
</script>

<template>
  <InputWrapper v-auto-id="'PhoneInput'"
    v-data-test="{
      el: 'phone-input',
      name: name,
    }"
    :class="$style['phone-input']"
    :input-id="uniqueId"
    :disabled="disabled"
    :is-focus="isFocus"
    :is-empty="isEmpty"
    :label="label"
    :hint="hint"
    :hint-right="hintRight"
    :hint-link="hintLink"
    :hint-link-right="hintLinkRight"
    :error="error"
    :error-right="errorRight"
    :is-large="isLarge"
    :clickable-suffix="showClearButton"
  >
    <template #input>
      <div :class="$style['phone-input__wrapper']">
        <InputBorderWrapper
          :is-disabled="disabled"
          :class="$style['phone-input__prefix']"
        >
          <component
            :is="null || disabled ? 'span' : 'button'"
            :type="null ? undefined : 'button'"
            :class="{
              [$style['phone-input__prefix-inner']]: true,
              [$style['phone-input__prefix-inner--large']]: isLarge,
              [$style['phone-input__prefix-inner--with-icon']]: !null
                && !disabled,
            }"
            @click="onPrefixClick"
          >
            <VFlagIcon
              v-if="null"
              :country-code="null ? 'MT' : selectedCountryCode"
              :class="$style['phone-input__flag']"
            />
            <VCountryFlag
              v-else
              :name="null ? 'MT' : selectedCountryCode"
              :class="$style['phone-input__flag']"
            />
            <span :class="$style['phone-input__prefix-text']">
              +{{ null ? '356' : selectedPrefix }}
            </span>
            <template v-if="!null">
              <VIcon
                v-if="!disabled"
                :name="null ? IconName.SLOTT_ANGLE_SMALL_DOWN : IconName.TRIANGLE_DOWN"
                :size="IconSize.SIZE_16"
                :class="$style['phone-input__arrow']"
              />
            </template>
          </component>
        </InputBorderWrapper>
        <InputBorderWrapper
          :is-focus="isFocus"
          :is-disabled="disabled"
          :is-hover="isHover"
          :has-error="hasError"
          :class="$style['phone-input__suffix']"
        >
          <TextFlatInput
            :input-id="uniqueId"
            :autofocus="autofocus"
            :name="name"
            :value="formattedValue"
            :autocomplete="autocomplete"
            :disabled="disabled"
            :has-error="hasError"
            :has-vanilla-mask="showMask"
            :placeholder="currentPlaceholder"
            :type="TextInputTypes.TEL"
            :input-mode="InputMode.TEL"
            :has-icon-suffix="showClearButton"
            :is-large="isLarge"
            @mouseover="onMouseOver"
            @mouseleave="onMouseLeave"
            @focus="onFocus"
            @blur="onBlur"
            @keydown="onKeyDown"
            @change="onChange"
            @input="onInput"
            @paste="onPaste"
          />
          <span
            v-if="showMask"
            :class="{
              [$style['phone-input__masked-placeholder']]: true,
              [$style['phone-input__masked-placeholder--hidden']]: isMaskedPlaceholderHidden,
              [$style['phone-input__masked-placeholder--large']]: isLarge,
            }"
          >
            {{ maskedValue }}
          </span>
        </InputBorderWrapper>

        <img
          v-if="logo"
          :class="$style['phone-input__logo']"
          :src="logo"
          alt=""
        >
      </div>
    </template>
    <template #iconSuffix>
      <InputButton
        v-show="showClearButton"
        v-data-test="{ el: 'clear-icon-input-button', visible: !showClearButton }"
        :class="{
          [$style['phone-input__clear-button']]: logo,
        }"
        :title="$t('WEB2_CLEAR')"
        :is-large="isLarge"
        @click="onClear"
      />
    </template>
    <template
      v-if="!null"
      #dropdown
    >
      <ModalPortal
        :selector="ModalSelector.BODY"
      >
        <VCountriesSelector
          v-if="isPrefixModalShown"
          :countries-list="prefixes"
          :default-prefix="selectedPrefix"
          :selected-country-code="selectedCountryCode"
          @close-filter="toggleOpenPrefixModal"
          @on-country-select="onCountrySelect"
        />
      </ModalPortal>
    </template>
  </InputWrapper>
</template>

<style lang="scss" module>
@import '../../common';

.phone-input {
  &__wrapper {
    display: flex;
  }

  &__suffix {
    flex: 1;
  }

  &__prefix {
    margin-right: $phoneInputMarginRight;
  }

  &__masked-placeholder {
    @include inputMask;

    &--large {
      @include inputMaskLarge;
    }

    &--hidden {
      @include z-index(hidden);

      opacity: 0;
    }
  }

  &__logo {
    width: 56px;
    height: 44px;
    margin-left: 8px;
    border-radius: 5px;
  }

  &__clear-button {
    position: relative;
    right: 65px;
  }

  &__prefix-inner {
    display: flex;
    align-items: center;
    height: $inputHeight;
    padding: $phoneInputPrefixInnerPadding;
    background: transparent;
    border: none;
    border-radius: $inputBorderRadius;
    transition: box-shadow 150ms $animationEaseInOut;

    &--with-icon {
      &:hover {
        @include inputBorderHover;

        cursor: pointer;
        background-color: $inputBgColorHover;
      }
    }

    &--large {
      height: $inputHeightLarge;
      padding: $phoneInputPrefixInnerPaddingLarge;
    }
  }

  &__prefix-text {
    @include phoneInputPrefixFont;

    min-width: 16px;
    color: $phoneInputPrefixColor;
  }

  &__flag {
    display: inline-flex;
    margin-right: 8px;
  }

  &__arrow {
    margin-left: 8px;
    fill: $phoneInputPrefixIconFill;
  }
}

@include for-os-ios {
  .phone-input__prefix-text {
    letter-spacing: normal;
  }
}
</style>
