import { computed } from 'vue';
import { useRouter } from 'vue-router';

import type { VRouterLinkComposable } from './types';
import type { VRouterLinkProps } from '../types';

export default function useVRouterLink(props: VRouterLinkProps): VRouterLinkComposable {
  const router = useRouter();

  const target = computed(() => router.resolve(props.to));

  const href = computed(() => target.value.href);

  return {
    href,
    target,
  };
}
