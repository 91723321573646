import { assert, isArray } from '@leon-hub/guards';

import type { CompetitorsTuple } from '../types';
import isCompetitorsTuple from '../../../guards/isCompetitorsTuple';

export function getSafeCompetitorsValue(value: unknown): CompetitorsTuple | undefined {
  if (isArray(value) && value.length) {
    assert(isCompetitorsTuple(value), 'unexpected competitors value');
    return value;
  }
  return undefined;
}
