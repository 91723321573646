import { logger } from '@leon-hub/logging';

export default function isSupportedLocale(locale: string): boolean {
  if (!locale) return false;
  try {
    // eslint-disable-next-line sonarjs/no-ignored-return
    (1).toLocaleString(locale);

    return true;
  } catch (error) {
    logger.error('Not supported locale, check formatMoneyLocale settings in language configuration.', error);
    return false;
  }
}
