import type { ComputedRef, Ref } from 'vue';
import { computed } from 'vue';

import { AlertIconName, getAlertIcon, IconName } from '@leon-hub/icons';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';
import type { VButtonProps } from '@leon-hub/module-buttons';
import type { Web2Image } from '@leon-hub/api-sdk';

import type { DefaultModalProps } from 'web/src/components/Modal/DefaultModal/types';
import type { ModalWindowHeaderProps } from 'web/src/components/Modal/ModalWindowHeader/types';
import { DialogAction } from 'web/src/modules/dialogs/enums';
import { useCopyToClipboard } from 'web/src/modules/dialogs/composables';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import type { SButtonProps } from 'web/src/components/Button/SButton/types';

import type { VSocialSharingProps, VSocialSharingEmits } from '../types';

export interface VSocialSharingComposable {
  modalProps: Ref<DefaultModalProps>;
  headerProps: ModalWindowHeaderProps;
  buttonProps: ComputedRef<VButtonProps | SButtonProps>;
  handleCopy: () => void;
  close: (isCross?: boolean) => void;
  sendAnalytic: (value: string) => void;
}

export default function useVSocialSharing(
  props: VSocialSharingProps,
  emit: VSocialSharingEmits,
): VSocialSharingComposable {
  const { $translate } = useI18n();
  const { copy } = useCopyToClipboard();
  const analytics = useAnalytics();

  const headerProps: ModalWindowHeaderProps = {
    title: '',
    prefixIconName: undefined,
    suffixIconName: IconName.CROSS,
    type: process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED ? 'default' : 'tertiary',
  };

  const shareSocialImage = computed<Web2Image | undefined>(() => {
    let src: string | undefined;
    if (process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED) {
      src = getAlertIcon(AlertIconName.Share);
    }
    return src ? { src } : undefined;
  });

  const modalProps: Ref<DefaultModalProps> = computed(
    () => ({
      ...props.modalProperties,
      isProfileLayout: !!process.env.VUE_APP_LAYOUT_DESKTOP,
      isInnerModal: !!process.env.VUE_APP_LAYOUT_DESKTOP,
      isOverlayCloseAllowed: true,
      image: shareSocialImage.value,
    }),
  );

  const buttonProps = computed(() => ({
    action: DialogAction.MODAL_CLOSE,
    label: $translate('JSP_PCL_FBOT_CLOSE').value,
    fullWidth: true,
  }));
  const handleCopy = (): void => {
    void copy({
      data: props.url,
      notificationText: $translate('WEB2_LINK_HAS_BEEN_COPIED').value,
      isProfileModalTooltip: !!process.env.VUE_APP_LAYOUT_DESKTOP,
    });

    analytics.push(AnalyticsEvent.Z_REFER_FRIEND, {
      referFriendProgram: {
        mainPage: {
          shareLinkForm: 'copyLink',
        },
      },
    });
  };

  function sendAnalytic(value: string): void {
    analytics.push(AnalyticsEvent.Z_REFER_FRIEND, {
      referFriendProgram: {
        mainPage: {
          shareLinkForm: {
            icons: value,
          },
        },
      },
    });
  }

  function close(isCross?: boolean): void {
    emit('close');
    analytics.push(AnalyticsEvent.Z_REFER_FRIEND, {
      referFriendProgram: {
        mainPage: {
          shareLinkForm: isCross ? 'cross' : 'close',
        },
      },
    });
  }

  return {
    modalProps,
    buttonProps,
    headerProps,
    handleCopy,
    sendAnalytic,
    close,
  };
}
