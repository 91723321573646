import type { VTermsStepProgressProps } from 'web/src/components/TermsSteps/VTermsStepProgress/types';
import type { VLinearProgressProps } from 'web/src/components/LinearProgress/VLinearProgress/types';
import { ProgressHeight } from 'web/src/components/LinearProgress/VLinearProgress/enums';

export default function getProgressProperties(props: VTermsStepProgressProps): VLinearProgressProps {
  return {
    height: ProgressHeight.TINY,
    kind: props.kind,
    value: props.progressBarValue,
  };
}
