<script lang="ts">
import { defineComponent, h, useCssModule } from 'vue';

import { IconName, IconSize } from '@leon-hub/icons';

import { VIcon } from '@components/v-icon';

export default defineComponent({
  name: 'DropdownSelectIcon',
  components: {
    VIcon,
  },
  props: {
    isOpen: {
      type: Boolean,
    },
    isLarge: {
      type: Boolean,
    },
  },
  setup(props, context) {
    const $style = useCssModule();
    const rootClass = 'dropdown-select-icon';

    return () => {
      const cssClasses: string[] = [];
      if ($style) {
        cssClasses.push($style[rootClass]);
        if (props.isOpen) {
          cssClasses.push($style[`${rootClass}--open`]);
        }
        if (props.isLarge) {
          cssClasses.push($style[`${rootClass}--large`]);
        }
      }
      return h(
        VIcon,
        {
          name: process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED ? IconName.SLOTT_ANGLE_SMALL_DOWN : IconName.TRIANGLE_DOWN,
          size: process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED ? IconSize.SIZE_20 : IconSize.SIZE_16,
          class: cssClasses,
          ...context.attrs,
        },
      );
    };
  },
});
</script>

<style lang="scss" module>
.dropdown-select-icon {
  @import 'web/src/components/Input/common';

  position: absolute;
  top: $inputIconPositionOffsetTop;
  right: $inputIconPositionOffsetRight;
  pointer-events: none;
  cursor: pointer;
  fill: $inputPlaceholderDefaultColor;
  transition: transform 0.15s ease-in-out;

  &--large {
    top: $inputIconPositionOffsetTopLarge;
    right: $inputIconPositionOffsetRightLarge;
  }

  &--open {
    transform: rotate(180deg);
  }
}
</style>
