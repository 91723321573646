import type { Ref } from 'vue';
import { watch } from 'vue';

interface AwaitConditionQueueProps<Value> {
  value: Ref<Value>;
  condition: (newValue: Value, oldValue?: Value) => boolean;
  source?: string;
  flush?: 'pre' | 'post' | 'sync';
}

interface AwaitConditionQueueComposable<Value> {
  awaitCondition(callback?: Maybe<Function>, callbackRejected?: Maybe<Function>, rejectAfter?: number): Promise<Value>;
  getQueueLength(): number;
}

/**
 * Util for await condition become true
 */
export default function useAwaitConditionQueue<Value = unknown>(
  props: AwaitConditionQueueProps<Value>,
): AwaitConditionQueueComposable<Value> {
  const {
    value,
    condition,
    source,
    flush = 'sync',
  } = props;
  let queue: Function[] = [];
  let savedOldValue: Optional<Value>;

  watch(value, (newValue: Value, oldValue?: Value) => {
    if (condition(newValue, oldValue)) {
      for (const method of queue) {
        method(newValue);
      }
      queue = [];
    }

    savedOldValue = newValue;
  }, { immediate: true, flush });

  function removeFromQueue(callback: Function) {
    queue = queue.filter((element) => element !== callback);
  }

  function awaitCondition(
    callback?: Maybe<Function>,
    callbackRejected?: Maybe<Function>,
    rejectAfter = 10000,
  ): Promise<Value> {
    return new Promise((resolve, reject) => {
      let timeout: Maybe<number> = null;
      const currentValue = value.value;
      const resolveCondition = (resolvedValue: Value) => {
        if (timeout) {
          window.clearTimeout(timeout);
          timeout = null;
        }

        callback?.(resolvedValue);
        resolve(resolvedValue);
      };

      if (condition(currentValue, savedOldValue)) {
        resolveCondition(currentValue);
        return;
      }

      // if state is not ready await for change
      queue.push(resolveCondition);

      if (rejectAfter === undefined) { return; }

      window.setTimeout(() => {
        removeFromQueue(resolveCondition);
        callbackRejected?.();
        const sourceInfo = source ? ` in ${source}` : '';
        reject(new Error(['Await condition failed by timeout', sourceInfo, '.'].join('')));
      }, rejectAfter);
    });
  }

  return {
    awaitCondition,
    getQueueLength(): number {
      // Used for specs
      return queue.length;
    },
  };
}
