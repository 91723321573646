import type { Ref } from 'vue';
import { computed } from 'vue';

import type {
  SportsSegmentPageListGroup,
  SegmentPageListGroup,
} from 'web/src/modules/sportline/submodules/segment/types';
import type { SelectedColumnInfoElement } from 'web/src/modules/sportline/composables/select-main-market';
import {
  getMarketColumnsFromMarketColumnsInfo,
  getMarketTypesFromMarketColumnsInfo,
} from 'web/src/modules/sportline/composables/markets/utils';
import { createSportlineFragmentBasisKeyValue } from 'web/src/modules/sportline/composables/list/utils';

import type { DisplayedVirtualListGroup } from '../types';

interface CreateVirtualListGroupsOptions {
  canShowLiveForGroup(group: SegmentPageListGroup): boolean;
  canShowPrematchForGroup(group: SegmentPageListGroup): boolean;
}

function mapGroup(
  source: SportsSegmentPageListGroup,
  { canShowLiveForGroup, canShowPrematchForGroup }: CreateVirtualListGroupsOptions,
): Maybe<DisplayedVirtualListGroup> {
  const liveElement = canShowLiveForGroup(source) ? source.liveElement : undefined;
  const prematchElement = canShowPrematchForGroup(source) ? source.prematchElement : undefined;

  const element = liveElement ?? prematchElement ?? null;

  if (!element) { return null; }

  const group: SportsSegmentPageListGroup = {
    ...source,
    liveElement,
    prematchElement,
  };

  const marketsColumns = getMarketColumnsFromMarketColumnsInfo(element);
  const marketsTypes = getMarketTypesFromMarketColumnsInfo(element);
  const key = createSportlineFragmentBasisKeyValue({ sportId: element?.sport.id, salt: liveElement ? 'live' : 'prematch' });
  const columnInfo = { marketsColumns, marketsTypes, key } as SelectedColumnInfoElement;

  return { group, columnInfo };
}

export function createVirtualListGroups(
  groups: Ref<SportsSegmentPageListGroup[]>,
  options: CreateVirtualListGroupsOptions,
): Ref<DisplayedVirtualListGroup[]> {
  return computed<DisplayedVirtualListGroup[]>(() => groups.value
    .map((group) => mapGroup(group, options))
    .filter((group): group is DisplayedVirtualListGroup => group !== null));
}
