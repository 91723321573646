import convertCommonClassesToCssModule from '../../../utils/convertCommonClassesToCssModule';
import type { VListItemExtendedProps } from '../../types';
import { ListItemExtendedKind } from '../../enums';

export default function getClassObject(props: VListItemExtendedProps): Dictionary<boolean> {
  return convertCommonClassesToCssModule({
    'list-item-extended': true,
    [`list-item-extended--kind-${props.kind}`]: props.kind !== ListItemExtendedKind.ICON,
    [`list-item-extended--icon-background__${props.iconBackground}`]: !!props.iconBackground,
    [`list-item-extended--subtitle-first-color__${props.subTitleFirstColor ?? 'default'}`]: true,
    'list-item-extended--disabled': !!props.isDisabled,
  });
}
