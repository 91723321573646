<script setup lang="ts">
import {
  nextTick,
  ref,
  watch,
} from 'vue';

import { nextAnimationFrame } from '@leon-hub/html-utils';

import JackpotAnimationItem from 'web/src/components/AmountAnimator/JackpotAnimation/JackpotAnimationItem.vue';
import type { JackpotAnimatedAmountKind } from 'web/src/components/AmountAnimator/types';

const props = defineProps<{
  amount: number;
  kind: JackpotAnimatedAmountKind;
}>();

const numbers = ref([props.amount]);
const isAnimated = ref(false);

watch(() => props.amount, async (newValue, oldValue) => {
  if (newValue < 10 && oldValue !== undefined && oldValue < 10) {
    const newNumbers = [];

    if (newValue > oldValue) {
      for (let i = oldValue; i <= newValue; i += 1) {
        newNumbers.push(i);
      }
    } else {
      for (let i = oldValue; i <= 9; i += 1) {
        newNumbers.push(i);
      }

      for (let i = 0; i <= newValue; i += 1) {
        newNumbers.push(i);
      }
    }

    isAnimated.value = false;
    numbers.value = newNumbers;
    await nextTick();
    await nextAnimationFrame();
    isAnimated.value = true;
  }
}, { immediate: true });
</script>

<template>
  <div v-auto-id="'JackpotAnimation'"
    :class="{
      [$style['jackpot-animation']]: true,
      [$style[`jackpot-animation--${kind}`]]: kind,
    }"
  >
    <div
      :class="{
        [$style['jackpot-animation__inner']]: true,
        [$style['jackpot-animation__inner--animate']]: isAnimated,
      }"
    >
      <JackpotAnimationItem
        v-for="number in numbers"
        :key="number"
        :text="number.toString()"
        :kind="kind"
      />
    </div>
  </div>
</template>

<style module lang="scss">
:root {
  --height: 22px;
}

.jackpot-animation {
  height: var(--height);
  overflow: hidden;

  &--major,
  &--minor {
    --height: 16px;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;

    &--animate {
      transition: transform 1s cubic-bezier(0.4, 0, 0.2, 1);
      transform: translate3d(0, calc(-100% + var(--height)), 0);
    }
  }
}
</style>
