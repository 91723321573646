import type { Ref, ComputedRef } from 'vue';
import {
  ref,
  computed,
  nextTick,
} from 'vue';

import { assert } from '@leon-hub/guards';
import { Timer } from '@leon-hub/utils';
import { useWindowResize } from '@leon-hub/browser-composables';
import { isElementInsideParent } from '@leon-hub/html-utils';

import { getContainerStyleProps, getSettingsForOpenDirection } from '../utils';
import type { VCoreDropdownProps, VCoreDropdownEmits, ContainerStyleProps } from '../types';
import { useExternalScrollWatcher } from './useExternalScrollWatcher';

export interface VCoreDropdownComposable {
  dropdownContainer: Ref<HTMLDivElement | undefined>;
  style: ComputedRef<Record<string, string>>;
  maxHeight: Ref<number | null>;
  stylesIsReady: Ref<boolean>;
  onAppear: () => void;
  onDisappear: () => void;
  onContentRedraw: () => Promise<void>;
  dropToTop: Ref<boolean>;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export default function useVCoreDropdown(
  props: VCoreDropdownProps,
  emit: VCoreDropdownEmits,
): VCoreDropdownComposable {
  const dropToTop = ref<boolean>(false);
  const stylesIsReady = ref<boolean>(false);
  const maxHeight = ref<number | null>(null);
  const dropdownContainer = ref<HTMLDivElement>();
  const styleProps = ref<ContainerStyleProps | null>(null);

  const style = computed<Record<string, string>>(() => {
    if (!styleProps.value) {
      return {};
    }
    const { translateTop, translateLeft, ...css } = styleProps.value;
    return {
      ...css,
      transform: `translate3d(${translateLeft}px, ${translateTop}px, 0)`,
    };
  });

  const emitUpdatePosition = (): void => {
    emit('update-position');
  };

  function calculateStyles(): void {
    styleProps.value = getContainerStyleProps(props, dropToTop, maxHeight, dropdownContainer);
    stylesIsReady.value = true;
  }

  function handleOutSideClick(event: Event): void {
    assert((event.target instanceof HTMLElement || event.target instanceof SVGElement) && dropdownContainer.value);
    if (props.ignoreActiveElementClick
      && document.activeElement !== document.body
      && event.target === document.activeElement) {
      return;
    }
    if (!isElementInsideParent(event.target, dropdownContainer.value)) {
      emit('close', event);
    }
  }

  function checkForFreeSpace(): void {
    const directionSettings = getSettingsForOpenDirection(props, dropdownContainer);
    dropToTop.value = directionSettings.betterOpenToTop;
    maxHeight.value = directionSettings.maxHeight;
  }

  function redraw(): void {
    checkForFreeSpace();
    calculateStyles();
    emitUpdatePosition();
  }

  let resizeTimeout = 0;

  function handleResize(): void {
    Timer.clearTimeout(resizeTimeout);
    resizeTimeout = Timer.setTimeout(() => {
      redraw();
    }, 50);
  }

  let scrollTimer = 0;

  let initialScrollTop: number | null = null;

  let initialTranslateTop: number | null = null;

  async function onScrollEnd(): Promise<void> {
    initialScrollTop = null;
    initialTranslateTop = null;
    maxHeight.value = null;
    styleProps.value = null;
    stylesIsReady.value = false;
    await nextTick();
    redraw();
  }

  function handleScroll(scrollTop: number): void {
    if (initialScrollTop === null) {
      initialScrollTop = scrollTop;
    }
    if (initialTranslateTop === null) {
      initialTranslateTop = styleProps.value?.translateTop || 0;
    }
    window.requestAnimationFrame(() => {
      if (initialScrollTop === null || initialTranslateTop === null) {
        return;
      }
      const offset = scrollTop - initialScrollTop;
      if (styleProps.value) {
        const newOffsetTop = initialTranslateTop - offset;
        styleProps.value = {
          ...styleProps.value,
          translateTop: newOffsetTop,
        };
      }
    });
    Timer.clearTimeout(scrollTimer);
    scrollTimer = Timer.setTimeout(onScrollEnd, 100);
  }

  function handleContentScroll(busEvent: {
    scrollTop: number;
    offsetHeight: number;
    scrollHeight: number;
  }) {
    handleScroll(busEvent.scrollTop);
  }

  function onAppear(): void {
    document.addEventListener('mousedown', handleOutSideClick);
    redraw();
  }

  function onDisappear(): void {
    document.removeEventListener('mousedown', handleOutSideClick);
    Timer.clearTimeout(scrollTimer);
    Timer.clearTimeout(resizeTimeout);
  }

  async function onContentRedraw(): Promise<void> {
    await nextTick();
    calculateStyles();
  }

  useWindowResize(handleResize);

  if (!props.ignoreScroll) {
    useExternalScrollWatcher(handleScroll, handleContentScroll);
  }

  return {
    dropdownContainer,
    style,
    stylesIsReady,
    onAppear,
    onDisappear,
    onContentRedraw,
    maxHeight,
    dropToTop,
  };
}
