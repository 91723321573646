enum RegistrationRouteName {
  // Full Registration
  REGISTRATION = 'registration',

  // Simple Registration
  SIMPLE_REGISTRATION_PHONE_CHECK = 'registration-phone-check',
  SIMPLE_REGISTRATION_CHANGE_PASSWORD = 'registration-change-password',
  SIMPLE_REGISTRATION_CHANGE_PASSWORD_SUCCESS = 'registration-change-password-success',
  SIMPLE_REGISTRATION_CHANGE_PHONE = 'registration-change-phone',
  SIMPLE_REGISTRATION_SUCCESS = 'registration-success',
}

export default RegistrationRouteName;
