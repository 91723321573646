<script lang="ts" setup>
import { toRef } from 'vue';

import { IconName, IconSize } from '@leon-hub/icons';

import { VIcon } from '@components/v-icon';

import type {
  BetlineLeagueStanding,
  BetlineMatchStatisticsTeam,
} from 'web/src/modules/sportline/types/rest';
import vPopperHint from 'web/src/components/Popper/directives/popperHint';
import StatisticLeagueStandingsPromotion
  from 'web/src/modules/sportline/submodules/core-statistic/components/StatisticLeagueStandingsPromotion.vue';
import StatisticTeamLogo from 'web/src/modules/sportline/submodules/event-statistic/components/StatisticTeamLogo.vue';
import ExpandButton from 'web/src/modules/sportline/submodules/core-statistic/components/ExpandButton.vue';

import useStatisticLeagueStandings from './useStatisticLeagueStandings';

interface StatisticLeagueStandingsProps {
  leagueStandings: BetlineLeagueStanding[];
  teams?: Maybe<[BetlineMatchStatisticsTeam, BetlineMatchStatisticsTeam]>;
  alwaysExpanded?: boolean;
}

const props = defineProps<StatisticLeagueStandingsProps>();

const leagueStandings = toRef(props, 'leagueStandings');
const teams = toRef(props, 'teams', null);
const alwaysExpanded = toRef(props, 'alwaysExpanded', false);

const {
  isExpanded,
  isDisplayFullTable,
  teamsIds,
  tournament,
  promotions,
} = useStatisticLeagueStandings({
  leagueStandings,
  teams,
  alwaysExpanded,
});
</script>

<template>
  <div v-auto-id="'StatisticLeagueStandings'"
    v-if="tournament"
    ref="standings"
  >
    <div
      v-for="season in tournament.seasons"
      :key="season.name ?? undefined"
    >
      <table
        v-for="group in season.groups"
        :key="`${season.name}_${group.id}_group`"
        :class="$style['standings-team-promotions-group']"
      >
        <thead>
          <tr
            :class="[
              $style['standings-team-promotion-item'],
              $style['standings-team-promotion-item--header']
            ]"
          >
            <td :class="$style['standings-team-promotion-item-cell']">
              <div :class="$style['standings-team-promotion-item__group-name']">
                {{ group.name }}
              </div>
            </td>
            <td
              v-popper-hint="$t('WEB2_MATCH_STATISTIC_RESULT_INDICATOR_TOTAL_GAMES')"
              :class="[
                $style['standings-team-promotion-item-cell'],
                $style['standings-team-promotion-team-matches-total']
              ]"
            >
              {{ $t('WEB2_MATCH_STATISTIC_RESULT_INDICATOR_TOTAL_GAMES_SHORT') }}
            </td>
            <td
              v-popper-hint="$t('WEB2_MATCH_STATISTIC_RESULT_INDICATOR_WIN')"
              :class="[
                $style['standings-team-promotion-item-cell'],
                $style['standings-team-promotion-team-win-total']
              ]"
            >
              {{ $t('WEB2_MATCH_STATISTIC_RESULT_INDICATOR_WIN_SHORT') }}
            </td>
            <td
              v-popper-hint="$t('WEB2_MATCH_STATISTIC_RESULT_INDICATOR_DRAW')"
              :class="[
                $style['standings-team-promotion-item-cell'],
                $style['standings-team-promotion-team-draw-total']
              ]"
            >
              {{ $t('WEB2_MATCH_STATISTIC_RESULT_INDICATOR_DRAW_SHORT') }}
            </td>
            <td
              v-popper-hint="$t('WEB2_MATCH_STATISTIC_RESULT_INDICATOR_LOSE')"
              :class="[
                $style['standings-team-promotion-item-cell'],
                $style['standings-team-promotion-team-loss-total']
              ]"
            >
              {{ $t('WEB2_MATCH_STATISTIC_RESULT_INDICATOR_LOSE_SHORT') }}
            </td>
            <td
              v-popper-hint="$t('WEB2_MATCH_STATISTIC_RESULT_INDICATOR_BALLS_DIFF')"
              :class="[
                $style['standings-team-promotion-item-cell'],
                $style['standings-team-promotion-team-goals-total']
              ]"
            >
              {{ $t('WEB2_MATCH_STATISTIC_RESULT_INDICATOR_BALLS_DIFF_SHORT') }}
            </td>
            <td
              v-popper-hint="$t('WEB2_MATCH_STATISTIC_RESULT_INDICATOR_TOTAL_POINTS')"
              :class="[
                $style['standings-team-promotion-item-cell'],
                $style['standings-team-promotion-team-points-total']
              ]"
            >
              {{ $t('WEB2_MATCH_STATISTIC_RESULT_INDICATOR_TOTAL_POINTS_SHORT') }}
            </td>
          </tr>
        </thead>
        <tbody>
          <template
            v-for="(team, index) in group.teams"
            :key="`${season.name}_${group.id}_${team.position}_item`"
          >
            <tr
              :class=" {
                [$style['standings-team-promotion-item']]: true,
                [$style['standings-team-promotion-item--highlighted']]: !!team.team?.id && teamsIds.has(team.team.id)
              }"
            >
              <td :class="$style['standings-team-promotion-item-cell']">
                <div :class="$style['standings-team-promotion-team-info']">
                  <StatisticLeagueStandingsPromotion
                    :class="$style['standings-team-promotion-team-info__indicator']"
                    :promotion="team.promotion"
                  />
                  <div :class="$style['standings-team-promotion-team-info__number']">
                    {{ team.position }}
                  </div>

                  <template v-if="team.team">
                    <StatisticTeamLogo
                      :class="$style['standings-team-promotion-team-info__icon']"
                      :team="team.team"
                    />
                    <div :class="$style['standings-team-promotion-team-info__name']">
                      {{ team.team.name }}
                    </div>
                  </template>
                </div>
              </td>
              <td
                :class="[
                  $style['standings-team-promotion-item-cell'],
                  $style['standings-team-promotion-team-matches-total'],
                ]"
              >
                {{ team.totals.matches }}
              </td>
              <td
                :class="[
                  $style['standings-team-promotion-item-cell'],
                  $style['standings-team-promotion-team-win-total'],
                ]"
              >
                {{ team.totals.win }}
              </td>
              <td
                :class="[
                  $style['standings-team-promotion-item-cell'],
                  $style['standings-team-promotion-team-draw-total'],
                ]"
              >
                {{ team.totals.draw }}
              </td>
              <td
                :class="[
                  $style['standings-team-promotion-item-cell'],
                  $style['standings-team-promotion-team-loss-total'],
                ]"
              >
                {{ team.totals.loss }}
              </td>
              <td
                :class="[
                  $style['standings-team-promotion-item-cell'],
                  $style['standings-team-promotion-team-goals-total'],
                ]"
              >
                {{ team.totals.goals[0] }}-{{ team.totals.goals[1] }}
              </td>
              <td
                :class="[
                  $style['standings-team-promotion-item-cell'],
                  $style['standings-team-promotion-team-points-total'],
                ]"
              >
                {{ team.totals.points }}
              </td>
            </tr>

            <tr
              v-if="!isDisplayFullTable
                && index + 1 < group.teams.length
                && (group.teams[index + 1].position - team.position > 1)"
              :key="`${season.name}_${group.id}_${team.position}_divider`"
              :class="[
                $style['standings-team-promotion-item'],
                $style['standings-team-promotion-item--divider'],
              ]"
            >
              <td
                colspan="7"
                :class="$style['statistic-league-standings-group-divider']"
              >
                <VIcon
                  :class="$style['statistic-league-standings-group-divider__icon']"
                  :name="IconName.MORE"
                  :size="IconSize.SIZE_16"
                />
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>

    <div
      v-if="isDisplayFullTable"
      :class="$style['standings-team-promotion-legend']"
    >
      <div
        v-for="promotion in promotions"
        :key="promotion.id ?? undefined"
        :class="$style['standings-team-promotion-legend-item']"
      >
        <StatisticLeagueStandingsPromotion
          :class="$style['statistic-league-standings-promotion-indicator']"
          :promotion="promotion"
        />

        <div :class="$style['standings-team-promotion-legend-item__label']">
          {{ promotion.name }}
        </div>
      </div>
    </div>

    <ExpandButton
      v-if="!alwaysExpanded"
      v-model:is-expanded="isExpanded"
      :opened-title="$t('WEB2_CLOSE')"
      :closed-title="$t('WEB2_MATCH_STATISTIC_MORE_TOURNAMENT_TABLE')"
      :class="$style['standings-team-promotion-list-toggle']"
    />
  </div>
</template>

<style lang="scss" module>
.statistic-league-standings-group-divider {
  height: 18px;
  line-height: 18px;

  &__icon {
    display: block;
    margin: auto;
    transform: rotate(90deg);
  }
}

.statistic-league-standings-promotion-indicator {
  margin: auto 8px auto 0;
  line-height: 16px;
}

.standings-team-promotion-legend {
  display: grid;
  grid-template-columns: auto auto;
  gap: 8px 4px;
  padding: 16px 0;

  @include for-phone-only {
    grid-template-columns: auto;
  }
}

.standings-team-promotion-legend-item {
  display: flex;
  flex-direction: row;

  &__label {
    @include regular\12\12;

    color: var(--TextSecondary);
  }
}

.standings-team-promotion-list-toggle {
  margin-top: 8px;
}

.standings-team-promotions-group {
  width: 100%;
  margin-bottom: 8px;
  border-collapse: collapse;
  border-radius: 5px;

  &:last-child {
    margin-bottom: 0;
  }

  & > thead {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    & > tr {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;

      & > td:first-child {
        border-top-left-radius: 5px;
      }

      & > td:last-child {
        border-top-right-radius: 5px;
      }
    }
  }

  & > tbody {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;

    & > tr:last-child {
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;

      & > td:first-child {
        border-bottom-left-radius: 5px;
      }

      & > td:last-child {
        border-bottom-right-radius: 5px;
      }
    }
  }
}

.standings-team-promotion-item {
  margin-bottom: 1px;
  color: var(--TextPrimary);
  background-color: var(--Layer2);
  border-bottom: 1px solid var(--Layer1);

  &:not(&--header):last-child {
    border-bottom: none;
  }

  &--highlighted {
    background-color: var(--Highlight);
  }

  &--divider {
    height: 18px;
    line-height: 18px;
    background-color: var(--Layer1);
    border-bottom: 0;
  }

  &__group-name {
    @include medium\13\16;

    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.standings-team-promotion-team-info {
  display: flex;

  &__indicator {
    margin: auto 8px auto 0;
    line-height: 16px;
  }

  &__number {
    @include medium\12\16;

    min-width: 32px;
    height: 16px;
    margin: auto 0;
    color: var(--TextSecondary);
  }

  &__icon {
    width: 16px;
    height: 16px;
    margin: auto 8px auto 0;
  }

  &__name {
    @include medium\13\16;

    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.standings-team-promotion-item-cell {
  min-width: 42px;
  height: 32px;
  padding: 0;
  margin: auto 0;
  line-height: 16px;
  text-align: center;
  white-space: nowrap;

  &:first-child {
    width: 90%;
    max-width: 0;
    padding-left: 8px;
    text-align: left;
  }

  &:last-child {
    padding-right: 8px;
  }
}

.standings-team-promotion-team-matches-total,
.standings-team-promotion-team-win-total,
.standings-team-promotion-team-draw-total,
.standings-team-promotion-team-loss-total,
.standings-team-promotion-team-goals-total,
.standings-team-promotion-team-points-total {
  @include medium\12\16;

  &[title] {
    cursor: pointer;
  }
}

.standings-team-promotion-team-win-total,
.standings-team-promotion-team-draw-total,
.standings-team-promotion-team-loss-total {
  @include for-phone-only {
    display: none;
  }
}
</style>
