import type { Tag } from '@leon-hub/tags';
import type { AnchorTarget, ButtonType } from '@leon-hub/module-buttons';
import type { OptionalAnyIconName } from '@leon-hub/icons';

export enum buttonKind {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  QUATERNARY = 'quaternary',
}

export interface LandingButtonProps {
  isDisabled?: boolean;
  fullWidth?: boolean;
  href?: string;
  id?: string;
  label?: string;
  role?: string;
  tag?: Tag;
  target?: AnchorTarget;
  type?: ButtonType;
  dataAttributeName?: string;
  dataAttributeValue?: string;
  iconName?: OptionalAnyIconName;
  kind?: buttonKind;
}

export interface LandingButtonSlots {
  default(props: Record<string, never>): void; // Label
}
