<script setup lang="ts">
import { IconName, IconSize } from '@leon-hub/icons';

import vDataTestUnit from 'web/src/testing/directives/vDataTestUnit';
import VListItem from 'web/src/components/List/VListItem/VListItem.vue';
import VList from 'web/src/components/List/VList/VList.vue';
import ModalPortal from 'web/src/components/Modal/ModalPortal/ModalPortal.vue';
import DepositBonusSelectorModal from 'web/src/modules/payments/form-widgets/DepositBonusSelector/components/DepositBonusSelectorModal.vue';
import type {
  DepositBonusSelectorEmits,
  DepositBonusSelectorProps,
} from 'web/src/modules/payments/form-widgets/DepositBonusSelector/components/types';
import useDepositBonusSelector
  from 'web/src/modules/payments/form-widgets/DepositBonusSelector/components/composables/useDepositBonusSelector';
import type { VButtonProps } from 'web/src/components/Button/VButton/types';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { ModalSelector } from 'web/src/modules/core/enums';
import { DEPOSIT_BONUS_NONE_SELECTED } from 'web/src/modules/payments/constants';
import SButton from 'web/src/components/Button/SButton/SButton.vue';
import LButton from 'web/src/components/Button/LButton/LButton.vue';

const props = defineProps<DepositBonusSelectorProps>();
const emit = defineEmits<DepositBonusSelectorEmits>();

const {
  selectorListItemProperties,
  isPaymentBonuses,
  isDetailsAvailable,
  openedBonusDetails,
  openListModal,
  openDetailsModal,
  onCloseModal,
  onCloseAllModal,
  emitChange,
} = useDepositBonusSelector(props, emit);

const buttonInfoProperties: VButtonProps = {
  iconName: IconName.INFO_OUTLINE,
  iconSize: IconSize.SIZE_24,
  kind: ButtonKind.BASE,
};

const modalSelector = '1' ? ModalSelector.DESKTOP_INNER_MODAL : ModalSelector.BODY;
</script>

<template>
  <div v-auto-id="'DepositBonusSelector'"
    v-if="!hidden"
    v-data-test-unit="{ el: 'deposit-bonus' }"
    :class="$style['deposit-bonus-selector']"
  >
    <div :class="$style['deposit-bonus-selector__selector']">
      <VList :class="$style['deposit-bonus-selector__selector-button']">
        <VListItem
          v-bind="selectorListItemProperties"
          :class="{
            [$style['deposit-bonus-selector__selector-button-inner']]: true,
          }"
          @click="openListModal"
        />
      </VList>

      <SButton
        v-if="null && selectedBonus !== DEPOSIT_BONUS_NONE_SELECTED && isDetailsAvailable"
        v-bind="buttonInfoProperties"
        kind="primary"
        size="medium"
        :icon-name="IconName.SLOTT_INFO"
        :class="$style['deposit-bonus-selector__button-info']"
        @click="openDetailsModal"
      />
      <LButton
        v-else-if="selectedBonus !== DEPOSIT_BONUS_NONE_SELECTED && isDetailsAvailable"
        v-bind="buttonInfoProperties"
        :class="$style['deposit-bonus-selector__button-info']"
        @click="openDetailsModal"
      />
    </div>
    <ModalPortal
      :selector="modalSelector"
    >
      <DepositBonusSelectorModal
        v-if="isPaymentBonuses"
        :bonus-list="depositBonusList"
        :selected-option-id="selectedBonus"
        :opened-bonus-details="openedBonusDetails"
        @back="onCloseModal"
        @close="onCloseAllModal"
        @change="emitChange"
      />
    </ModalPortal>
  </div>
</template>

<style lang="scss" module>
.deposit-bonus-selector {
  $self: &;

  margin-top: 20px;

  &__selector {
    display: flex;
  }

  &__selector-button {
    flex: 1;
    margin: 0;
  }

  & #{$self}__selector-button-inner {
    color: var(--BrandDefault);
  }

  &__button-info {
    flex-shrink: 0;
    margin-left: 8px;
  }
}
</style>
