import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import type {
  EgsJackpotAnimatedAmountProps,
  EgsJackpotAnimationOptions,
} from 'web/src/modules/egs/submodules/jackpots/components/EgsJackpotAnimatedAmount/types';
import type { SightAmountAnimatorProps } from 'web/src/components/AmountAnimator/types';

interface EgsJackpotAnimatedAmountComposable {
  amountAnimatorProps: ComputedRef<SightAmountAnimatorProps>;
}

const defaultAnimationProps: EgsJackpotAnimationOptions = {
  duration: 15_000,
  timeRange: {
    min: 900,
    max: 900,
  },
  stepRange: {
    min: 0.01,
    max: 0.1,
  },
  isInfinite: true,
};

export default function useEgsJackpotAnimatedAmount(
  props: EgsJackpotAnimatedAmountProps,
): EgsJackpotAnimatedAmountComposable {
  const amountAnimatorProps = computed<SightAmountAnimatorProps>(() => ({
    ...defaultAnimationProps,
    duration: props.jackpotAnimationDuration || defaultAnimationProps.duration,
    amount: props.value,
  }));

  return {
    amountAnimatorProps,
  };
}
