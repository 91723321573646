import type { ComputedRef, Ref } from 'vue';
import { computed, toRef } from 'vue';

import { useI18n } from 'web/src/modules/i18n/composables';

import type { WinBoostOptionsRow } from '../submodules/accumulator-boost/types';
import { useAccumulatorBoostStore } from '../submodules/accumulator-boost/store';
import { getWinBoostPercentageString } from '../submodules/accumulator-boost/utils';

interface UseWinBoostInfoPage {
  descriptionText: ComputedRef<string>;
  tableRows: Ref<WinBoostOptionsRow[]>;
}

export function useWinBoostInfoPage(): UseWinBoostInfoPage {
  const { $translate } = useI18n();

  const accumulatorBoostStore = useAccumulatorBoostStore();

  const tableRows = toRef(accumulatorBoostStore, 'winBoostInfoRows');
  const maxAccumulatorBoostMultiplier = toRef(accumulatorBoostStore, 'maxAccumulatorBoostMultiplier');
  const minAccumulatorBoostMultiplier = toRef(accumulatorBoostStore, 'minAccumulatorBoostMultiplier');
  const formattedAccumulatorBoostMinOdds = toRef(accumulatorBoostStore, 'formattedAccumulatorBoostMinOdds');
  const minCount = toRef(accumulatorBoostStore, 'minCount');

  const descriptionTranslateParams = computed<Record<string, string>>(() => ({
    minCount: `${minCount.value}`,
    minOdds: formattedAccumulatorBoostMinOdds.value,
    minBoost: getWinBoostPercentageString(minAccumulatorBoostMultiplier.value),
    maxBoost: getWinBoostPercentageString(maxAccumulatorBoostMultiplier.value),
  }));

  const descriptionText = computed<string>(() => $translate('WEB2_WIN_BOOST_INFO_MODAL_DESCRIPTION', descriptionTranslateParams).value);

  return {
    descriptionText,
    tableRows,
  };
}
