<script setup lang="ts">
/**
 * A block on the bottom of the page.
 * It contains a related events list.
 */
import { computed } from 'vue';

import { IconName } from '@leon-hub/icons';

import { useSportlineLayoutSettings } from 'web/src/modules/sportline/composables/settings';
import VLazyRender from 'web/src/components/LazyRender/VLazyRender/VLazyRender.vue';
import SportlineDefaultHeader from 'web/src/modules/sportline/components/header/SportlineHeader.vue';
import SportlineBetSwipeHeader from 'web/src/modules/sportline/views/bet-swipe/headline/CustomHeader.vue';
import SportlineComingHeader from 'web/src/modules/sportline/components/header/SportlineComingHeader.vue';
import RelatedSportEventsTable from 'web/src/modules/sportline/submodules/event-details/components/related-events/RelatedEventsTable.vue';
import SportSectionHorizontal from 'web/src/modules/sportline/views/bet-swipe/list/horizontal/EventsListSportSection.vue';
import SportSectionVertical from 'web/src/modules/sportline/views/bet-swipe/list/vertical/EventsListSportSection.vue';

import { useSimilarEventsSection } from './useSimilarEventsSection';

const { isBetSwipeEventDetailsLayoutEnabled } = useSportlineLayoutSettings();
const {
  sportElement,
  isDisplayBlock,
  isDisplayTitle,
  activeEventId,
  isOnlyOutrightShown,
} = useSimilarEventsSection({
  doNotSplitOutrightList: computed<boolean>(() => (
    !!null && isBetSwipeEventDetailsLayoutEnabled.value
  )),
});
</script>

<template>
  <VLazyRender v-auto-id="'SimilarEventsSection'"
    v-if="isDisplayBlock"
    :class="{
      [$style['similar-events-section']]: true,
      [$style['similar-events-section--cards']]: null && isBetSwipeEventDetailsLayoutEnabled,
    }"
  >
    <template v-if="null && isBetSwipeEventDetailsLayoutEnabled">
      <template v-if="'1'">
        <SportSectionHorizontal
          v-if="sportElement"
          :sport-element="sportElement"
          is-swiper-controls-enabled
          is-display-outright-events-enabled
        >
          <template
            v-if="isDisplayTitle"
            #title
          >
            <SportlineBetSwipeHeader
              level="2"
              :title="$t('JSP_EVENTS_SIMILAR')"
              :icon-name="IconName.HISTORY"
            />
          </template>
        </SportSectionHorizontal>
      </template>
      <template v-else>
        <SportlineDefaultHeader
          v-if="isDisplayTitle"
          :class="$style['similar-events-section-header']"
          :caption="$t('JSP_EVENTS_SIMILAR')"
          :icon-name="IconName.HISTORY"
        />

        <SportSectionVertical
          v-if="sportElement"
          :sport-element="sportElement"
          is-display-outright-events-enabled
        />
      </template>
    </template>
    <template v-else>
      <SportlineComingHeader
        v-if="isDisplayTitle"
        :caption="$t('JSP_EVENTS_SIMILAR')"
      />

      <RelatedSportEventsTable
        without-headline
        :active-id="activeEventId"
        :sport-element="sportElement"
        :is-only-outright-shown="isOnlyOutrightShown"
      />
    </template>
  </VLazyRender>
</template>

<style module lang="scss">
@include for-layout using ($isDesktop) {
  .similar-events-section {
    // @TODO move margin-top: 8px from SportlineComingHeader to components
    padding: 0 sportline-page-sides($isDesktop, sportline-version());
    margin-top: 16px;

    &--cards {
      padding: 0 sportline-page-sides($isDesktop, sportline-version('bet-swipe'));
    }
  }

  .similar-events-section-header {
    margin-bottom: 8px;
  }
}
</style>
