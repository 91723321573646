import { assert } from '@leon-hub/guards';

import {
  CasinoIconName, SlottIconName, SlottCasinoIconName, SlottSportIconName, VirtualSportIconName, CurrenciesSlottIconName,
} from '../enums';
import type { IconNameType } from '../types';
import { isIconNameKey } from './isIconNameKey';
import { IconName } from '../constants';

export function getIconName({
  name,
  prefix,
}: {
  name: string;
  prefix?: string;
}): IconNameType {
  let key = name;
  if (prefix) {
    key = `${prefix}_${name}`.toUpperCase();
  }
  if (process.env.VUE_APP_FEATURE_CASINO_ENABLED && key in CasinoIconName) {
    // @ts-ignore
    return CasinoIconName[key];
  }

  if (process.env.VUE_APP_STYLE_SLOTT) {
    if (process.env.VUE_APP_FEATURE_CASINO_ENABLED && key in SlottCasinoIconName) {
      // @ts-ignore
      return SlottCasinoIconName[key];
    }

    if (key in CurrenciesSlottIconName) {
      // @ts-ignore
      return CurrenciesSlottIconName[key];
    }

    if (key in SlottIconName) {
      // @ts-ignore
      return SlottIconName[key];
    }

    if (key in SlottSportIconName) {
      // @ts-ignore
      return SlottSportIconName[key];
    }
  }

  if (process.env.VUE_APP_FEATURE_VIRTUAL_SPORT_ENABLED && key in VirtualSportIconName) {
    // @ts-ignore
    return VirtualSportIconName[key];
  }

  assert(isIconNameKey(key), `Invalid icon name: ${key}`);

  return IconName[key];
}
