import type { SlipEntry } from '../types';
import getSlipEntryId from '../../../utils/getSlipEntryId';

export default function getUniqueSlipEntriesArray(entries: SlipEntry[]): SlipEntry[] {
  const dictionary: Record<string, SlipEntry> = {};
  for (const entry of entries) {
    const id = getSlipEntryId(entry);
    if (id) {
      dictionary[id] = entry;
    }
  }
  return Object.values(dictionary);
}
