import type { CustomerLimitsDataDocument } from 'web/src/modules/responsible-gambling/types';
import { LimitName } from 'web/src/modules/profile/submodules/responsible-gambling/enums';

export function getCircularProgressData(limit: LimitName, limits: CustomerLimitsDataDocument | null): {
  limit: string;
  percent: number;
  left: string;
} {
  switch (limit) {
    case LimitName.DAILY:
      return {
        limit: limits?.dailyLimit || '0',
        percent: limits?.dailyPercent || 0,
        left: limits?.dailyLeft || '0',
      };
    case LimitName.WEEKLY:
      return {
        limit: limits?.weeklyLimit || '0',
        percent: limits?.weeklyPercent || 0,
        left: limits?.weeklyLeft || '0',
      };
    default:
      return {
        limit: limits?.monthlyLimit || '0',
        percent: limits?.monthlyPercent || 0,
        left: limits?.monthlyLeft || '0',
      };
  }
}
