<script lang="ts" setup>
import { computed } from 'vue';

import {
  CasinoLoyaltyProgramIconSizes,
  CasinoLoyaltyProgramProgressSizes,
} from 'web/src/modules/casino-loyalty-program/store/types';
import { CircleSize } from 'web/src/components/CircularProgress/VCircularProgress/enums';
import VCircularProgress from 'web/src/components/CircularProgress/VCircularProgress/VCircularProgress.vue';
import CasinoLoyaltyProgramIcon
  from 'web/src/modules/casino-loyalty-program/components/CasinoLoyaltyProgramIcon/CasinoLoyaltyProgramIcon.vue';

import type { CasinoLoyaltyProgramProgressEmits, CasinoLoyaltyProgramProgressProps } from './types';

const props = withDefaults(defineProps<CasinoLoyaltyProgramProgressProps>(), {
  size: CasinoLoyaltyProgramProgressSizes.SIZE_42,
  iconSizes: CasinoLoyaltyProgramIconSizes.DEFAULT,
  isGradient: true,
});

const emit = defineEmits<CasinoLoyaltyProgramProgressEmits>();

const circleSize = computed(() => {
  switch (props.size) {
    case CasinoLoyaltyProgramProgressSizes.SIZE_92:
      return CircleSize.SIZE_92;
    case CasinoLoyaltyProgramProgressSizes.SIZE_94:
      return CircleSize.SIZE_94;
    case CasinoLoyaltyProgramProgressSizes.SIZE_112:
      return CircleSize.SIZE_112;
    default:
      return CircleSize.SIZE_42;
  }
});

function emitClick(): void {
  emit('click');
}
</script>

<template>
  <div v-auto-id="'CasinoLoyaltyProgramProgress'"
    :class="{
      [$style['casino-loyalty-program-progress']]: true,
      [$style[`casino-loyalty-program-progress--${size}`]]: size !== CasinoLoyaltyProgramProgressSizes.SIZE_42,
      [$style['casino-loyalty-program-progress--has-shadow']]: !!hasShadow,
      [$style['casino-loyalty-program-progress--has-no-image']]: !imageSrc,
      [$style['casino-loyalty-program-progress--hide-background']]: isHideProgress || isHideBackground,
    }"
    @click="emitClick"
  >
    <VCircularProgress
      v-if="!isHideProgress"
      class="casino-loyalty-program-progress__progress"
      :percentage="progress"
      :circle-size="circleSize"
      :circle-color="circleColor"
      is-simple-progress
      in-loyalty-program
    />
    <CasinoLoyaltyProgramIcon
      :image-src="showImageNextLevel && imageSrcNextLevel ? imageSrcNextLevel : imageSrc"
      :is-inactive="isInactive"
      :size="iconSizes"
      :image="image"
      :gradient-bg="isGradient"
      class="casino-loyalty-program-progress__logo"
    />
  </div>
</template>

<style lang="scss" module>
$sizes: (
  'size-42': 42px,
  'size-92': 92px,
  'size-94': 94px,
  'size-112': 112px,
);

.casino-loyalty-program-progress {
  $self: &;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: map-get($sizes, 'size-42');
  height: map-get($sizes, 'size-42');
  background-color: var(--OpacityLayer2);
  border-radius: 50%;

  &--size-92 {
    width: map-get($sizes, 'size-92');
    height: map-get($sizes, 'size-92');
  }

  &--size-94 {
    width: map-get($sizes, 'size-94');
    height: map-get($sizes, 'size-94');
  }

  &--size-112 {
    width: map-get($sizes, 'size-112');
    height: map-get($sizes, 'size-112');
  }

  &--has-shadow {
    @include filterModalShadow;

    @include for-hover {
      cursor: pointer;
    }
  }

  &--has-no-image {
    #{$self}__logo {
      max-width: 60%;
    }
  }

  &--hide-background {
    background-color: transparent;
  }
}
</style>
