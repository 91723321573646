import type { SlipInfoRequest, SlipEntryRequest } from '@leon-hub/api-sdk';
import { assert } from '@leon-hub/guards';

import type { SlipEntry } from '../types';
import isSlipEntryRequestArray from '../../../guards/isSlipEntryRequestArray';
import { SlipTypeSingle } from '../../../constants';

export default function getSlipInfoRequestEntries(entries: SlipEntry[], slipType: string | null): SlipInfoRequest[] {
  const clearEntries: SlipEntryRequest[] = entries.map(({
    // exact fields matters
    ts,
    event,
    market,
    runner,
    odds,
    banker,
    marketStatus,
    betline,
    zeroMargin,
    runnerTags,
    marketTypeIdentifier,
    marketTypeTag,
  }) => ({
    ts,
    event,
    market,
    runner,
    odds,
    banker,
    marketStatus,
    betline,
    zeroMargin,
    runnerTags,
    marketTypeIdentifier,
    marketTypeTag,
  }));
  assert(isSlipEntryRequestArray(clearEntries), 'Unexpected SlipInfoRequest[] passed');
  if (slipType === SlipTypeSingle) {
    return clearEntries.map((slipEntry) => ({
      strictChange: false,
      slipType,
      entries: [slipEntry],
    }));
  }
  return [
    {
      strictChange: false,
      slipType,
      entries: clearEntries,
    },
  ];
}
