import type { InjectionKey, Ref } from 'vue';

import {
  isBoolean, isFunction, isNull, isObject, isString,
} from '@leon-hub/guards';

import type VListAccordionSharedState from './VListAccordionSharedState';

type Refonly<T> = {
  [P in keyof T]: Ref<T[P]>;
};

export interface VListAccordionSharedStateProps
  extends Readonly<Refonly<Omit<VListAccordionSharedState, 'independentItems'>>> {
  independentItems: boolean;
  updateOpenItem(value: VListAccordionSharedState['openItem']): void;
}

export const isListAccordionSharedStateProps = (
  value: unknown,
): value is VListAccordionSharedStateProps => isObject(value) && isObject(value.openItem)
  && (isString(value.openItem.value) || isNull(value.openItem.value))
  && isBoolean(value.independentItems)
  && isFunction(value.updateOpenItem);

export type { default as VListProps } from './VListProps';
export type { default as VListAccordionSharedState } from './VListAccordionSharedState';
export const accordionSharedState = Symbol('accordion state') as InjectionKey<VListAccordionSharedStateProps>;
