import type { ComputedRef, Ref } from 'vue';
import { computed, nextTick, ref } from 'vue';

import type { MultilineTextInput, VInputInputEvent } from 'web/src/components/Input';
import { emailPattern } from 'web/src/components/Form/constants';
import type { VFeedbackCompositionProps } from 'web/src/components/FeedbackComposition/VFeedbackComposition/types';
import {
  getEmailFieldError,
  getIsSubmitButtonDisabled,
  getSubmitButtonProps,
} from 'web/src/components/FeedbackComposition/VFeedbackComposition/composables/utils';
import type { VButtonProps } from 'web/src/components/Button/VButton/types';
import { useI18n } from 'web/src/modules/i18n/composables';
import { FeedbackTextareaRowsSize } from 'web/src/components/FeedbackComposition/VFeedbackComposition/enums';

import type VFeedbackCompositionEmitFormData from '../types/VFeedbackCompositionEmitFormData';

export interface useVFeedbackCompositionComposable {
  comment: Ref<string>;
  commentTextarea: Ref<typeof MultilineTextInput | null>;
  emailValue: Ref<string>;
  emailFieldError: ComputedRef<string>;
  submitButtonProperties: ComputedRef<VButtonProps>;
  rowsMaxValue: FeedbackTextareaRowsSize | 0;
  rowsMinValue: FeedbackTextareaRowsSize;
  onComponentMounted: () => Promise<void>;
  onComponentUpdated: () => void;
  onEmailBlur: (e: VInputInputEvent) => void;
  onEmailChange: (e: VInputInputEvent) => void;
  onTextareaChange: (e: VInputInputEvent) => void;
  getSubmitFormData: () => VFeedbackCompositionEmitFormData;
}

export default function useVFeedBackComposition(
  props: VFeedbackCompositionProps,
): useVFeedbackCompositionComposable {
  const { $translate } = useI18n();

  const emailValue = ref('');
  const isEmailFieldError = ref(false);
  const comment = ref('');
  const commentTextarea = ref<typeof MultilineTextInput | null>(null);

  const emailFieldError = computed(() => getEmailFieldError(props, emailValue.value, isEmailFieldError.value, $translate));
  // eslint-disable-next-line max-len
  const isSubmitButtonDisabled = computed(() => getIsSubmitButtonDisabled(props, emailValue.value, comment.value, isEmailFieldError.value));
  const submitButtonProperties = computed(() => getSubmitButtonProps(props, isSubmitButtonDisabled, $translate));

  const rowsMaxValue = process.env.VUE_APP_LAYOUT_DESKTOP ? FeedbackTextareaRowsSize.MAX : 0;
  const rowsMinValue = FeedbackTextareaRowsSize.MIN;

  async function onComponentMounted(): Promise<void> {
    await nextTick();
    focusTextArea();
  }

  function onComponentUpdated(): void {
    if (props.isClearTextarea) {
      comment.value = '';
    }
  }

  function onTextareaChange(e: VInputInputEvent): void {
    comment.value = e.target.value;
  }

  function isEmailValid(e: VInputInputEvent): boolean {
    return !!e.target.value && !new RegExp(emailPattern).test(e.target.value);
  }

  function onEmailBlur(e: VInputInputEvent): void {
    isEmailFieldError.value = isEmailValid(e);
  }

  function onEmailChange(e: VInputInputEvent): void {
    isEmailFieldError.value = isEmailValid(e);
    emailValue.value = e.target.value;
  }

  function focusTextArea(): void {
    if (commentTextarea.value && ('focus' in commentTextarea.value)) {
      commentTextarea.value.focus();
    }
  }

  function getSubmitFormData(): VFeedbackCompositionEmitFormData {
    return {
      comment: comment.value,
      email: emailValue.value,
    };
  }

  return {
    comment,
    commentTextarea,
    emailValue,
    emailFieldError,
    submitButtonProperties,
    rowsMaxValue,
    rowsMinValue,
    onComponentMounted,
    onComponentUpdated,
    onEmailBlur,
    onEmailChange,
    onTextareaChange,
    getSubmitFormData,
  };
}
