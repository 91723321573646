import type { Channel } from 'pusher-js';
import type IPusher from 'pusher-js';
import type { Class } from 'utility-types';

import { onWindowVisibilityChanged } from '@leon-hub/browser-composables';

import type { FastTrackMessage } from 'web/src/modules/fast-track/services/api/types';

import type { PusherOptions } from './types/PusherOptions';

function getChannelName(brandId: number, userId: number): string {
  return `private-prisma-${brandId}-${userId}`;
}

async function loadPusher(): Promise<Class<IPusher>> {
  const { default: Pusher } = await import('pusher-js');
  return Pusher;
}

export default class PusherJsClient {
  private pusher: IPusher | undefined = undefined;

  private channel: Channel | undefined = undefined;

  private isSilentDisconnect = false;

  private options: PusherOptions | null = null;

  constructor() {
    window.addEventListener('beforeunload', () => {
      this.disconnect();
    });

    onWindowVisibilityChanged(this.onVisibilityChanged.bind(this)).addVisibilityChangeEventListener();
  }

  private onVisibilityChanged(isVisible: boolean): void {
    if (isVisible) {
      if (this.isSilentDisconnect && this.options) {
        void this.connect(this.options);
      }
    } else if (this.pusher) {
      this.disconnect(true);
    }
  }

  public async connect(options: PusherOptions): Promise<void> {
    this.options = options;
    this.disconnect();
    const pusher = Reflect.construct(await loadPusher(), [options.pusherKey, {
      authEndpoint: `${options.fusionUrl}/external/pusher/${options.brandName}?authToken=${options.sessionId}`,
      cluster: options.pusherRegion,
    }]);

    pusher.connection.bind('connected', () => {});
    pusher.connection.bind('disconnected', () => {});

    const channel = pusher.subscribe(getChannelName(options.brandId, options.userId));
    channel.bind('pusher:subscription_error', () => {});
    channel.bind('pusher:subscription_succeeded', () => {});
    channel.bind('message', (data: FastTrackMessage) => {
      options.onNewPusherMessage(data);
    });
    channel.bind('shoutout', (data: FastTrackMessage) => {
      options.onNewPusherMessage(data);
    });
    channel.bind('inbox', (data: FastTrackMessage) => {
      options.onNewPusherMessage(data);
    });

    this.pusher = pusher;
    this.channel = channel;
  }

  public disconnect(isSilent = false): void {
    this.isSilentDisconnect = isSilent;

    if (this.channel) {
      this.channel = undefined;
    }

    if (this.pusher) {
      this.pusher.connection.unbind();
      this.pusher.connection.disconnect();
      this.pusher = undefined;
    }
  }
}
