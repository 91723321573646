import { circleLengthMap } from 'web/src/components/CircularProgress/VCircularProgress/sizes/circularProgressSizes';
import { CircleSize } from 'web/src/components/CircularProgress/VCircularProgress/enums';

import type { VCircularProgressProps } from '../../types';

export default function getCircleLength(
  props: Pick<VCircularProgressProps, 'circleSize'>,
): number {
  return circleLengthMap[props.circleSize ?? CircleSize.DEFAULT];
}
