import { computed } from 'vue';
import type { ComputedRef } from 'vue';

import { assert } from '@leon-hub/guards';

import type { FileSingleInputProps } from 'web/src/components/Input';
import isFileSingleInputProps from 'web/src/components/Input/guards/isFileSingleInputProps';

import getWrapperHintProperties from '../../utils/getWrapperHintProperties';
import type {
  FormWrapperWidgetProperties,
} from '../../types';

interface UseFormFileSingleInput {
  componentProps: ComputedRef<FileSingleInputProps>;
}

export default function useFormFileSingleInput(props: FormWrapperWidgetProperties<FileSingleInputProps>)
  : UseFormFileSingleInput {
  const componentProps = computed<FileSingleInputProps>(
    () => {
      const {
        name,
        options,
        error,
        title,
        hintMessage,
        disabled,
        autofocus,
      } = props;

      const computedOptions = options ?? {};

      assert(isFileSingleInputProps(computedOptions));

      return {
        label: title,
        disabled,
        ...(options ? computedOptions : {}),
        ...getWrapperHintProperties({ options: computedOptions, error, hintMessage }),
        autofocus,
        name,
      };
    },
  );
  return {
    componentProps,
  };
}
