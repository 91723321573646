import { BetlineType } from 'web/src/modules/sportline/enums/rest';

export function isBetlineType(argument: unknown): argument is BetlineType {
  return [
    BetlineType.All,
    BetlineType.Live,
    BetlineType.Prematch,
    BetlineType.Outright,
    BetlineType.Planed,
    BetlineType.Virtual,
  ].includes(argument as BetlineType);
}
