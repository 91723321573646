import type { BrowserOptions } from '@sentry/browser';
import {
  init,
  setTag,
  makeBrowserOfflineTransport,
  makeFetchTransport,
} from '@sentry/browser';

export function initSentry(options: BrowserOptions): void {
  init({
    ...options,
    transport: makeBrowserOfflineTransport(makeFetchTransport),
    transportOptions: {
      maxQueueSize: 10,
    },
  });
  setTag('frame', 'sandbox');
}
