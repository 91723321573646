import { defineStore } from 'pinia';
import type { Ref } from 'vue';
import {
  nextTick,
  ref,
} from 'vue';

import type {
  EgsTopWinnersFilter,
  ILobbyType,
} from '@leon-hub/api-sdk';
import {
  lobbyTypeSlotsGamePage,
  lobbyTypeLiveGamePage,
  lobbyTypeAll,
  lobbyTypeSlots,
  EgsTopWinnersGameType,
  LobbyItemType,
  lobbyTypeLiveCasino,
} from '@leon-hub/api-sdk';
import { nextAnimationFrame } from '@leon-hub/html-utils';

import type {
  EgsGame,
  GetLobbyRequest,
} from 'web/src/modules/egs/types';
import type {
  LobbyGamesItem,
  LobbyItemUnit,
  LobbyTopWinnersItemLoading,
} from 'web/src/modules/lobby/types';
import { useEgsApi } from 'web/src/modules/egs/composables';
import { useLobbyType, useLobbySportlineEvents } from 'web/src/modules/lobby/composables';
import { useEgsGamesStore } from 'web/src/modules/egs/submodules/games/store';
import { EgsGamesCategoryId } from 'web/src/modules/egs/enums';
import {
  getLobbyTypeByCategoryGamesType,
  isFavoriteLobbyItem,
  isHistoryLobbyItem,
  isJackpotWidget,
  isLobbyGamesItem,
  isLobbyTopWinnersItem,
  isSquareImagesWidget,
} from 'web/src/modules/lobby/utils';
import { useEgsHistoryGamesStore } from 'web/src/modules/egs/submodules/history-games';

import {
  createLobbyCacheKey,
  isLobbyAndGameOfSameType,
} from './utils';

const useLobbyStore = defineStore('lobby', () => {
  const {
    sportlineFlags,
    parseSportlineSettings,
    onGlobalSportlineEventsInit,
  } = useLobbySportlineEvents();

  const gamesStore = useEgsGamesStore();

  const { lobbyType: currentLobbyType } = useLobbyType();
  const { getLobby, getGames, getTopWinners } = useEgsApi();
  const { updateHistoryGame } = useEgsHistoryGamesStore();

  const lobbyItemsByType: Record<string, Ref<LobbyItemUnit[]>> = {};
  const isLoading: Record<string, Ref<boolean>> = {};
  const activeType = ref(lobbyTypeSlots);
  const activeGroupId = ref<string>();
  const activeCategoryId = ref<string>();

  function checkLobbyItemsExists(type: ILobbyType, groupId?: Maybe<string>, categoryId?: Maybe<string>): boolean {
    const key = createLobbyCacheKey(type, groupId, categoryId);

    if (!lobbyItemsByType[key]) {
      lobbyItemsByType[key] = ref([]);
      return false;
    }

    return true;
  }

  function setLobbyItems(
    type: ILobbyType,
    items: LobbyItemUnit[],
    groupId?: Maybe<string>,
    categoryId?: Maybe<string>,
  ): void {
    checkLobbyItemsExists(type, groupId, categoryId);
    const key = createLobbyCacheKey(type, groupId, categoryId);

    lobbyItemsByType[key].value = items;

    if (items.some((item) => item.typeId === LobbyItemType.SPORTLINE_TOP_MATCHES)) {
      onGlobalSportlineEventsInit();
    }
  }

  function getLobbyItems(
    type?: Maybe<ILobbyType>,
    groupId?: Maybe<string>,
    categoryId?: Maybe<string>,
  ): Ref<LobbyItemUnit[]> {
    const innerType = type ?? currentLobbyType.value;

    checkLobbyItemsExists(innerType, groupId, categoryId);

    const key = createLobbyCacheKey(innerType, groupId, categoryId);

    return lobbyItemsByType[key];
  }

  function setIsLoading(
    value: boolean,
    type: ILobbyType,
    groupId?: Maybe<string>,
    categoryId?: Maybe<string>,
  ): void {
    const key = createLobbyCacheKey(type, groupId, categoryId);

    if (value && (
      type === lobbyTypeLiveGamePage
      || type === lobbyTypeSlotsGamePage
    )) {
      const lobbyItems = getLobbyItems(type, groupId, categoryId);
      if (lobbyItems.value.length) {
        return;
      }
    }

    if (!isLoading[key]) {
      isLoading[key] = ref(value);
    } else {
      isLoading[key].value = value;
    }
  }

  function getIsLoading(
    type: ILobbyType,
    groupId?: Maybe<string>,
    categoryId?: Maybe<string>,
  ): Ref<boolean> {
    const key = createLobbyCacheKey(type, groupId, categoryId);

    if (!isLoading[key]) {
      isLoading[key] = ref(false);
    }

    return isLoading[key];
  }

  async function loadLobbyItems(options: GetLobbyRequest): Promise<void> {
    const silent = getLobbyItems(options.type, options.group, options.category).value.length > 0;

    const type = options.type ?? currentLobbyType.value;

    setIsLoading(true, type, options.group, options.category);

    if (silent
      && options.category !== EgsGamesCategoryId.TOP_WINNERS_LIVE
      && options.category !== EgsGamesCategoryId.TOP_WINNERS_SLOTS
    ) {
      await nextTick();
      await nextAnimationFrame();
      setIsLoading(false, type, options.group, options.category);
    }

    try {
      const getLobbyRequestOptions: GetLobbyRequest = {
        ...options,
        sportlineFlags: sportlineFlags.value.join(',') || undefined,
        type,
      };

      if (process.env.VUE_APP_FEATURE_CASINO_MIXED_MY_GAMES_ENABLED && options.category === EgsGamesCategoryId.MY_GAMES) {
        getLobbyRequestOptions.type = lobbyTypeAll;
      }

      const lobbyItems = await getLobby(getLobbyRequestOptions, {
        sportEventDetails: parseSportlineSettings.value,
      }, silent);

      setLobbyItems(type, lobbyItems.items, options.group, options.category);

      if (process.env.VUE_APP_FEATURE_CASINO_MIXED_MY_GAMES_ENABLED && options.category === EgsGamesCategoryId.MY_GAMES) {
        setLobbyItems(lobbyTypeSlots, lobbyItems.items, options.group, options.category);
        setLobbyItems(lobbyTypeLiveCasino, lobbyItems.items, options.group, options.category);
      }

      gamesStore.updateGames(lobbyItems.games);
    } finally {
      setIsLoading(false, type, options.group, options.category);
    }
  }

  async function loadMoreLobbyItemGames({
    lobbyItem,
    groupId,
    isAllTab,
    excludeGameIds,
  }: {
    lobbyItem: LobbyGamesItem;
    groupId?: string;
    isAllTab?: boolean;
    excludeGameIds?: string[];
  }): Promise<void> {
    if (!lobbyItem.isLoading && lobbyItem.next) {
      // eslint-disable-next-line no-param-reassign
      lobbyItem.isLoading = true;
      // eslint-disable-next-line no-param-reassign
      lobbyItem.loadingCounter = (lobbyItem.loadingCounter || 0) + 1;
      const data = await getGames({
        type: getLobbyTypeByCategoryGamesType(lobbyItem.category.type),
        group: groupId,
        category: lobbyItem.category.id,
        isAllTab,
        onlySquare: isSquareImagesWidget(lobbyItem.widgetId),
        isJackpot: isJackpotWidget(lobbyItem.widgetId),
        limit: lobbyItem.next?.limit,
        offset: lobbyItem.next?.offset,
        ...excludeGameIds && { excludeGameIds },
      });

      // eslint-disable-next-line no-param-reassign
      lobbyItem.games = [...lobbyItem.games, ...data.games];
      // eslint-disable-next-line no-param-reassign
      lobbyItem.next = data.next;
      // eslint-disable-next-line no-param-reassign
      lobbyItem.isLoading = false;

      gamesStore.updateGames(data.games);
    }
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  async function loadLobbyItemTopWinners({ lobbyItem, filter, isReplace }: {
    lobbyItem: LobbyTopWinnersItemLoading;
    filter?: EgsTopWinnersFilter;
    isReplace?: boolean;
  }): Promise<void> {
    if (!lobbyItem.isLoading && (lobbyItem.next || filter)) {
      // eslint-disable-next-line no-param-reassign
      lobbyItem.isLoading = true;
      if (isReplace) {
        // eslint-disable-next-line no-param-reassign
        lobbyItem.showSkeleton = true;

        if (filter) {
          // eslint-disable-next-line no-param-reassign
          lobbyItem.filter = filter;
        }
      }

      const data = await getTopWinners({
        gameType: lobbyItem.gameType ?? EgsTopWinnersGameType.SLOTS,
        filter: filter ?? lobbyItem.filter,
        ...lobbyItem.next && {
          limit: lobbyItem.next?.limit,
          offset: isReplace ? 0 : lobbyItem.next?.offset,
        },
      });

      // eslint-disable-next-line no-param-reassign
      lobbyItem.winners = isReplace ? data.winners : [...lobbyItem.winners, ...data.winners];
      // eslint-disable-next-line no-param-reassign
      lobbyItem.filter = filter ?? lobbyItem.filter;
      // eslint-disable-next-line no-param-reassign
      lobbyItem.next = data.next;
      // eslint-disable-next-line no-param-reassign
      lobbyItem.isLoading = false;
      // eslint-disable-next-line no-param-reassign
      lobbyItem.showSkeleton = false;

      gamesStore.updateGames(data.winners.map((winner) => winner.egsGame));
    }
  }

  function setActiveLobby(
    type: ILobbyType,
    groupId?: string,
    categoryId?: string,
  ): void {
    activeType.value = type;
    activeGroupId.value = groupId;
    activeCategoryId.value = categoryId;
  }

  function isLobbyChanged(
    type: ILobbyType,
    groupId?: string,
    categoryId?: string,
  ): boolean {
    return !checkLobbyItemsExists(type, groupId, categoryId)
      || type !== activeType.value
      || groupId !== activeGroupId.value
      || categoryId !== activeCategoryId.value;
  }

  function setVisitedGame(game?: EgsGame): void {
    if (game) {
      for (const lobbyItems of Object.values(lobbyItemsByType)) {
        for (const lobbyItem of lobbyItems.value) {
          if (isLobbyGamesItem(lobbyItem) && isHistoryLobbyItem(lobbyItem)
            && isLobbyAndGameOfSameType(game, lobbyItem)) {
            lobbyItem.games = [
              game,
              ...(lobbyItem.games.filter((item) => item.id !== game.id)),
            ];
          }
        }
      }

      updateHistoryGame(game);
    }
  }

  function setFavoriteLobbyItem(lobbyItem: LobbyItemUnit, ids: string[], value: boolean): void {
    if (isLobbyGamesItem(lobbyItem) && isFavoriteLobbyItem(lobbyItem)) {
      const changeGames: EgsGame[] = [];
      const foundIds: string[] = [];

      for (const id of ids) {
        const game = gamesStore.getGameById(id);

        if (game && isLobbyAndGameOfSameType(game, lobbyItem)) {
          changeGames.push(game);
          foundIds.push(game.id);
        }
      }

      const filteredGames = lobbyItem.games.filter((game) => !foundIds.includes(game.id));

      if (value) {
        // eslint-disable-next-line no-param-reassign
        lobbyItem.games = [
          ...changeGames,
          ...filteredGames,
        ];
      } else {
        // eslint-disable-next-line no-param-reassign
        lobbyItem.games = filteredGames;
      }
    }
  }

  function setLobbyItemsFavorite(lobbyItem: LobbyItemUnit, ids: string[], value: boolean): void {
    if (isLobbyGamesItem(lobbyItem)) {
      for (const game of lobbyItem.games) {
        if (ids.includes(game.id)) {
          game.isFavorite = value;
        }
      }

      setFavoriteLobbyItem(lobbyItem, ids, value);
    }

    if (isLobbyTopWinnersItem(lobbyItem)) {
      for (const winner of lobbyItem.winners) {
        if (ids.includes(winner.egsGame.id)) {
          winner.egsGame.isFavorite = value;
        }
      }
    }
  }

  function setFavorite(ids: string[], value: boolean): void {
    for (const lobbyItems of Object.values(lobbyItemsByType)) {
      for (const lobbyItem of lobbyItems.value) {
        setLobbyItemsFavorite(lobbyItem, ids, value);
      }
    }
  }

  return {
    getIsLoading,
    loadLobbyItems,
    getLobbyItems,
    loadMoreLobbyItemGames,
    loadLobbyItemTopWinners,
    setActiveLobby,
    isLobbyChanged,
    activeType,
    activeGroupId,
    activeCategoryId,
    setFavorite,
    setVisitedGame,
  };
});

export default useLobbyStore;
