export enum BetSlipSummaryStatus {
  DEFAULT = 'default',
  BELOW_MINIMUM = 'below_minimum',
  ABOVE_MAXIMUM = 'above_maximum',
  NOT_ENOUGH_BALANCE = 'not_enough_balance',
  MARKETS_CHANGED = 'markets_changed',
  PRICES_CHANGED = 'prices_changed',
  ENTRIES_CHANGED = 'entries_changed',
  CHANGED_PRICES_AND_MARKETS = 'changed_prices_and_markets',
  CHANGED_PRICES_AND_ENTRIES = 'changed_prices_and_entries',
  UNABLE_TO_APPLY_FREEBET = 'unable_to_apply_freebet',
  LIMIT = 'limit',
  WAITING = 'waiting',
  DONE = 'done',
}
