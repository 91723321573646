<script setup lang="ts">
import type { VButtonEmits, VButtonProps, VButtonSlots } from '@leon-hub/module-buttons';
import { ButtonHeight, ButtonKind } from '@leon-hub/module-buttons';
import { Tag } from '@leon-hub/tags';

import { getVButtonDefaultProps } from './utils/getVButtonDefaultProps';
import type { SButtonKind, SButtonProps, SButtonSize } from '../SButton/types';
import { useButtonEmits } from './composables';
import SButton from '../SButton/SButton.vue';
import LButton from '../LButton/LButton.vue';

const props = withDefaults(defineProps<VButtonProps>(), getVButtonDefaultProps());
const emits = defineEmits<VButtonEmits>();
defineSlots<VButtonSlots>();

const remappedProps = (p: VButtonProps): SButtonProps => {
  let buttonHeight: SButtonSize;
  let buttonKind: SButtonKind;

  switch (p.height) {
    case ButtonHeight.LARGE:
      buttonHeight = 'large';
      break;
    case ButtonHeight.SMALL:
      buttonHeight = 'small';
      break;
    case ButtonHeight.TINY:
    case ButtonHeight.TINY_EXTRA:
      buttonHeight = 'xsmall';
      break;
    case ButtonHeight.MEDIUM:
    default:
      buttonHeight = 'medium';
      break;
  }

  switch (p.kind) {
    case ButtonKind.SECONDARY:
      buttonKind = 'secondary';
      break;
    case ButtonKind.TRANSPARENT:
    case ButtonKind.NAV:
      buttonKind = 'quinary-primary';
      break;
    case ButtonKind.PRIMARY:
    default:
      buttonKind = 'primary';
      break;
  }

  return {
    kind: buttonKind,
    size: buttonHeight,
    for: p.for,
    href: p.href,
    iconName: p.iconName,
    isAutofocus: p.isAutofocus,
    disabled: p.disabled,
    fullWidth: p.fullWidth ?? false,
    isLoading: p.isLoading,
    label: p.label,
    role: p.role,
    tag: p.tag ?? Tag.BUTTON,
    target: p.target,
    type: p.type,
  };
};

const {
  click,
  keydown,
  mousedown,
  focus,
  blur,
} = useButtonEmits(emits);
</script>

<template>
  <SButton
    v-if="null"
    v-bind="remappedProps(props)"
    @click="click"
    @keydown="keydown"
    @mousedown="mousedown"
    @focus="focus"
    @blur="blur"
  >
    <template
      v-if="$slots.default"
      #default
    >
      <slot />
    </template>
  </SButton>
  <LButton
    v-else
    v-bind="props"
    @click="click"
    @keydown="keydown"
    @mousedown="mousedown"
    @focus="focus"
    @blur="blur"
  >
    <template
      v-if="$slots.default"
      #default
    >
      <slot />
    </template>
    <template
      v-if="$slots.icon"
      #icon
    >
      <slot name="icon" />
    </template>
  </LButton>
</template>
