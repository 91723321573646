import { defineStore } from 'pinia';
import { ref, toRef, watch } from 'vue';

import { getUuid, Json } from '@leon-hub/utils';
import { bindIsArrayOf, isObject } from '@leon-hub/guards';
import type { ApiConnectionError, ApiRequestAbortedError } from '@leon-hub/api';
import { isDebugEnabled } from '@leon-hub/debug';
import { IconName, getAlertIcon } from '@leon-hub/icons';
import { getCordovaAppConfig } from '@leon-hub/cordova';
import { useLocalStorageManager } from '@leon-hub/local-storage';

import { AlertIconName } from 'web/src/components/Icon/AlertIcon/types';
import useI18n from 'web/src/modules/i18n/composables/useI18n';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import useBaseApiClient from 'web/src/modules/core/services/api/useBaseApiClient';
import { CONNECTION_LOST_SNACKBAR_ID } from 'web/src/modules/snackbars/constants';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';

import type { Snackbar, SnackbarOptions } from '../types';
import useRouterStore from '../../core/store/useRouterStore';

const useSnackbarsStore = defineStore('snackbars', () => {
  const { isLoggedIn } = useIsLoggedIn();
  const localStorageManager = useLocalStorageManager();
  const { $translate } = useI18n();

  const routerStore = useRouterStore();
  const currentRouteName = toRef(() => routerStore.currentRouteName);

  const gqlClient = useGraphqlClient();
  const baseApiClient = useBaseApiClient();

  const snackbars = ref<Snackbar[]>([]);
  const hiddenByRoute = new Set<string>();

  let connectionLostRoute = '';
  let connectionErrorResolver: null | Promise<void> = null;

  function setConnectionLostRoute(value: string): void {
    connectionLostRoute = value;
  }

  function clearAuthSnackbars(): void {
    snackbars.value = snackbars.value
      .filter((snackbar) => !snackbar.requiresAuth && snackbar.id !== CONNECTION_LOST_SNACKBAR_ID);
  }

  function update(options: SnackbarOptions & { id: string }): void {
    const { id } = options;
    const snackbarItem = snackbars.value.findIndex((item) => item.id === id);
    if (snackbarItem !== -1) {
      snackbars.value[snackbarItem] = {
        ...snackbars.value[snackbarItem],
        ...options,
      };
    }
  }

  function show(options: SnackbarOptions): Snackbar {
    const id = options.id || getUuid();

    let snackbar = snackbars.value.find((item) => item.id === id);
    if (!snackbar) {
      snackbar = {
        ...options,
        id,
        isHiddenByRoute: false,
      };

      if ((snackbar?.restrictedRoutes ?? []).includes(String(currentRouteName.value))) {
        snackbar.isHiddenByRoute = true;
        hiddenByRoute.add(id);
      }

      snackbars.value.push(snackbar);
    }

    return snackbar;
  }

  async function hide(id: Snackbar['id'], permanent = true): Promise<void> {
    const snackbar = snackbars.value.find((item) => item.id === id);
    if (snackbar) {
      if (permanent) {
        await snackbar.onHide?.();
        snackbars.value = snackbars.value.filter((item) => item.id !== id);
      } else {
        snackbar.isHiddenByRoute = true;
        hiddenByRoute.add(id);
      }
    }
  }

  function logConnectionError(error?: ApiConnectionError | ApiRequestAbortedError) {
    try {
      if (isDebugEnabled()) {
        const debugData = localStorageManager.getItem('ApiConnectionErrors');
        let parsedDebugData: Record<string, unknown>[] = [];
        if (debugData) {
          parsedDebugData = Json.parse(debugData, { validator: bindIsArrayOf(isObject) }) ?? [];
        }
        parsedDebugData.push(error
          ? {
            date: new Date().toISOString(),
            ...error.toJSON(),
          }
          : { date: new Date().toISOString(), name: 'Unknown error' });

        if (parsedDebugData.length > 10) {
          parsedDebugData.shift();
        }

        localStorageManager.setItem('ApiConnectionErrors', Json.stringify(parsedDebugData) || '[]');
      }
    } catch {
      // empty
    }
  }

  function reloadConnectionLostPage(): void {
    if (connectionLostRoute) {
      window.location.href = connectionLostRoute;
    } else {
      window.location.reload();
    }
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  async function showConnectionError(
    isCancelHidden = false,
    error?: ApiConnectionError | ApiRequestAbortedError,
  ): Promise<void> {
    logConnectionError(error);
    const id = CONNECTION_LOST_SNACKBAR_ID;
    if (!connectionErrorResolver) {
      connectionErrorResolver = new Promise<void>((resolve, reject) => {
        show({
          id,
          title: $translate('WEB2_CONNECTION_ERROR_CAPTION').value,
          text: $translate('WEB2_CONNECTION_ERROR_DESCRIPTION').value,
          iconName: process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED ? undefined : IconName.ATTENTION,
          image: process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED ? getAlertIcon(AlertIconName.Wifi) : undefined,
          type: 'error',
          accept: {
            label: $translate('WEB2_CONNECTION_ERROR_BTN_RETRY').value,
            action: () => {
              if (process.env.VUE_APP_FEATURE_SW_ENABLED
                || (process.env.VUE_APP_PLATFORM_CORDOVA && getCordovaAppConfig().backup?.isEnabled)
              ) {
                reloadConnectionLostPage();
                reject(new Error('Reload window from snackbar'));
                return Promise.resolve();
              }
              resolve();
              return Promise.resolve();
            },
          },
          cancel: {
            label: $translate('JSP_PCL_FBOT_CLOSE').value,
            action: () => {
              reject(new Error('Snackbar canceled'));
              return Promise.resolve();
            },
          },
          onHide: () => {
            connectionErrorResolver = null;
            return Promise.resolve();
          },
        });
      });
    }

    if (isCancelHidden) {
      const snackbar = snackbars.value.find((item) => item.id === id);
      if (snackbar) {
        snackbar.cancel = undefined;
      }
    }

    return connectionErrorResolver;
  }

  gqlClient.setConnectionErrorResolver((error) => showConnectionError(false, error));
  baseApiClient.setConnectionErrorResolver((error) => showConnectionError(false, error));

  watch(currentRouteName, (newRouteName) => {
    if (newRouteName) {
      for (const snackbar of snackbars.value) {
        if (snackbar.id === CONNECTION_LOST_SNACKBAR_ID) {
          continue;
        }
        if (snackbar.restrictedRoutes?.includes(String(newRouteName))) {
          void hide(snackbar.id, false);
        } else if (
          snackbar.isHiddenByRoute
          && hiddenByRoute.has(snackbar.id)
          && !snackbar.restrictedRoutes?.includes(String(newRouteName))
        ) {
          snackbar.isHiddenByRoute = false;
          hiddenByRoute.delete(snackbar.id);
        } else {
          void hide(snackbar.id, true);
          break;
        }
      }
    }
  });

  watch(isLoggedIn, (newValue) => {
    if (!newValue) {
      clearAuthSnackbars();
    }
  });

  return {
    snackbars,
    setConnectionLostRoute,
    showConnectionError,
    show,
    hide,
    update,
  };
});

export default useSnackbarsStore;
