<script lang="ts" setup>
import {
  onMounted,
  ref,
} from 'vue';

import type { FocusableInputRef } from '@leon-hub/focus';

import { VFastMoneyChoice, FastMoneyMaxPosition } from 'web/src/components/FastMoneyChoice';
import usePaymentsFastChoices
  // eslint-disable-next-line max-len
  from 'web/src/modules/payments/form-widgets/FastSum/components/PaymentsFastSumInput/composables/usePaymentsFastChoices';
import type { VInputInputEvent } from 'web/src/components/Input';
import usePaymentsMaxValueFastChoice
  // eslint-disable-next-line max-len
  from 'web/src/modules/payments/form-widgets/FastSum/components/PaymentsFastSumInput/composables/usePaymentsMaxValueFastChoice';
import { VLoaderDelayed } from 'web/src/components/Loader';
import PaymentsInput from 'web/src/modules/payments/form-widgets/FastSum/components/PaymentsInput/PaymentsInput.vue';
import { useCurrency } from 'web/src/modules/money/composables';
import { useBalance } from 'web/src/modules/customer/store/composables';
import type {
  PaymentsFastSumInputProps,
} from 'web/src/modules/payments/form-widgets/FastSum/components/PaymentsFastSumInput/types';

import usePaymentsFastSumInput from './composables/usePaymentsFastSumInput';

interface PaymentsFastSumInputEmits {
  (e: 'input', value: VInputInputEvent): void;
  (e: 'focus', value: VInputInputEvent): void;
  (e: 'change', value: VInputInputEvent): void;
  (e: 'blur', value: VInputInputEvent): void;
}

const props = withDefaults(defineProps<PaymentsFastSumInputProps>(), {
  error: '',
  name: '',
  // eslint-disable-next-line vue/no-boolean-default
  showSuggestedAmount: true,
});
const emit = defineEmits<PaymentsFastSumInputEmits>();

const {
  onFastSumInputMounted,
  fastChoiceValue,
  loading,
  handleInput,
  sendPredefinedSumDepositAnalytics,
} = usePaymentsFastSumInput(props, emit);

const { fastChoices } = usePaymentsFastChoices(props);
const { maxValueFastChoice } = usePaymentsMaxValueFastChoice(props);
const { balance } = useBalance();
const { currency } = useCurrency();
onMounted(onFastSumInputMounted);

const paymentsInput = ref<FocusableInputRef>();
function focusInputElement(): void {
  void paymentsInput.value?.focus({
    forceIos: true,
    atTheEnd: true,
  });
}
</script>

<template>
  <VLoaderDelayed
    v-if="loading"
  />
  <div
    v-else
    :class="{
      [$style['payments-sum']]: true,
      [$style['payments-sum--small']]: !null || isCryptoWithdrawal,
      [$style['payments-sum--large']]: null && !isCryptoWithdrawal,
    }"
  >
    <div
      :class="$style['payments-sum__input-container']"
      @click="focusInputElement"
    >
      <PaymentsInput
        ref="paymentsInput"
        :autofocus="autofocus"
        :name="name"
        :currency="currency"
        :balance="balance"
        :min-amount="minAmount"
        :max-amount="maxAmount"
        :input-value="`${amount || defaultInputValue}`"
        :error="!!error"
        :deposit="isDeposit ?? false"
        :bonus="false"
        :default-init-input-value="defaultInputValue"
        :suggested-amounts="suggestedAmounts ?? []"
        :allow-input-suggested-amounts-only="allowInputSuggestedAmountsOnly ?? false"
        :allowed-increment="allowedIncrement"
        :is-crypto-withdrawal="isCryptoWithdrawal"
        :is-current-value-from-suggested-amounts="isCurrentValueFromSuggestedAmounts"
        @focus="emit('focus', $event)"
        @change="emit('change', $event)"
        @blur="emit('blur', $event)"
        @input="handleInput($event.target.value)"
      />
    </div>

    <div
      v-if="showSuggestedAmount"
      :class="$style['payments-sum__fast-container']"
    >
      <VFastMoneyChoice
        v-if="isDeposit || balance > 0"
        :class="$style['payments-sum__fast']"
        :items="fastChoices || undefined"
        :balance="balance"
        :currency="currency"
        :show-balance="!isDeposit && !isCryptoWithdrawal"
        :current-value="fastChoiceValue"
        :max-value="maxValueFastChoice"
        :max-available-value="maxValueFastChoice"
        :min-available-value="minAmount"
        :max-enabled="!allowInputSuggestedAmountsOnly && isFastSumMaxEnabled"
        :max-position="FastMoneyMaxPosition.END"
        dense
        is-bordered
        :is-deposit="isDeposit"
        hide-unavailable-options
        @select-value="handleInput"
        @click-value="sendPredefinedSumDepositAnalytics"
      />
    </div>
    <div
      v-if="availableForWithdraw && !isCryptoWithdrawal"
      :class="$style['payments-sum__available-for-withdrawal']"
    >
      <span>{{ $t('WEB2_WITHDRAWAL_RESTRICTIONS_AVAILABLE') }}</span>
      <span>{{ availableForWithdraw }}</span>
    </div>
  </div>
</template>

<style lang="scss" module>
.payments-sum {
  @include paymentsSum;

  &--small {
    @include paymentsSumSmall;
  }

  &--large {
    @include paymentsSumLarge;
  }

  &__fast {
    &--centered {
      :deep(.swiper__items) {
        justify-content: center;
      }
    }
  }

  &__available-for-withdrawal {
    @include paymentsSumAvailableForWithdrawal;

    span:first-child {
      margin-right: 4px;
    }
  }
}
</style>
