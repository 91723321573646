import type {
  NavigationGuardNext,
  RouteLocationNormalized,
} from 'vue-router';
import { computed, toRef } from 'vue';

import { RouteName } from '@leon-hub/routing-config-names';

import { AbstractPrefetch } from 'web/src/modules/core/services/router/prefetch';
import type { AppVueRouter } from 'web/src/modules/core/services/router/types';
import { OnboardingIdsEnum } from 'web/src/modules/onboarding/pages/enums';
import useOnboarding from 'web/src/modules/onboarding/pages/composables/useOnboading';
import useCasinoLoyaltyStore from 'web/src/modules/casino-loyalty-program/store/useCasinoLoyaltyStore';

export default class CasinoLoyaltyProgramPagePrefetch extends AbstractPrefetch {
  // eslint-disable-next-line class-methods-use-this
  async prefetch(
    router: AppVueRouter,
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ): Promise<void> {
    const onboarding = useOnboarding();
    const casinoLoyaltyStore = useCasinoLoyaltyStore();

    const hasOnboarding = computed(() => !!onboarding.getOnboardingById(OnboardingIdsEnum.EGS_LOYALTY_PROGRAM));
    const currentLevelId = toRef(() => casinoLoyaltyStore.currentLevelId);
    const hideCasinoLoyaltyOnboardingPage = toRef(() => casinoLoyaltyStore.hideCasinoLoyaltyOnboardingPage);

    if (
      currentLevelId.value > 0
      && !hideCasinoLoyaltyOnboardingPage.value
      && hasOnboarding
    ) {
      casinoLoyaltyStore.setHideCasinoLoyaltyOnBoardingPageState(true);

      next({
        name: RouteName.ONBOARDING,
        params: { id: OnboardingIdsEnum.EGS_LOYALTY_PROGRAM },
      });
      return Promise.resolve();
    }

    next();
    return Promise.resolve();
  }
}
