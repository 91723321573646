import type { Ref } from 'vue';

import { useIsDevIP } from 'web/src/modules/core/composables/root';

interface UseIsDebugEnabledComposable {
  isDebugEnabled: Ref<boolean>;
}

export function useIsDebugEnabled(): UseIsDebugEnabledComposable {
  const { isDevIP: isDebugEnabled } = useIsDevIP();

  return { isDebugEnabled };
}
