import getInstanceClient from './instance/client';
import getInstanceServer from './instance/server';
import {
  Events,
  TargetParamEnter,
  TargetParamNavigation,
  TargetParamHistory,
  TargetParamWithdrawal,
} from './constants';
import type {
  Instance,
  MetricsToolConfigParsed,
} from './types';

const provideInstance = (): Instance => (
  process.env.VUE_APP_RENDERING === 'ssr' ? getInstanceServer() : getInstanceClient()
);

export {
  Events,
  TargetParamEnter,
  TargetParamNavigation,
  TargetParamHistory,
  TargetParamWithdrawal,
};

export type {
  MetricsToolConfigParsed,
};

let instance: Instance | null = null;
export function getYMInstance(): Instance {
  if (instance) return instance;
  instance = provideInstance();
  return instance;
}
