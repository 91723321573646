<script lang="ts" setup>
import { computed, toRef } from 'vue';

import { IconSize } from '@leon-hub/icons';

import { SportIcon } from '@components/sport-icon';

import type { BetlineMatchStatisticsTeam } from 'web/src/modules/sportline/types/rest';
import type { TeamsConfrontationStatisticBlockData } from 'web/src/modules/sportline/submodules/event-statistic/types';
import type { SportFamily } from 'web/src/modules/sportline/enums';
import StatisticTeamLogo from 'web/src/modules/sportline/submodules/event-statistic/components/StatisticTeamLogo.vue';

interface TeamBallPossessionStatisticBlockProps {
  confrontationBlock: TeamsConfrontationStatisticBlockData;
  teams?: Maybe<[BetlineMatchStatisticsTeam, BetlineMatchStatisticsTeam]>;
  sportFamily?: Maybe<SportFamily | string>;
}

const props = defineProps<TeamBallPossessionStatisticBlockProps>();
const teams = toRef(props, 'teams', null);
const sportFamily = toRef(props, 'sportFamily', null);

const host = computed<Maybe<BetlineMatchStatisticsTeam>>(() => teams.value?.[0] ?? null);
const guest = computed<Maybe<BetlineMatchStatisticsTeam>>(() => teams.value?.[1] ?? null);
</script>

<template>
  <div v-auto-id="'TeamBallPossessionStatisticBlock'" :class="$style['ball-possession-statistic-block']">
    <div :class="$style['ball-possession-statistic-block__heading']">
      <span :class="$style['ball-possession-chart-heading']">{{ confrontationBlock.name }}</span>
    </div>
    <div :class="$style['ball-possession-statistic-block__charts']">
      <div :class="$style['ball-possession-chart-bar']">
        <div
          :class="[
            $style['ball-possession-chart-bar__filling-borders'],
            $style['ball-possession-chart-bar__filling-borders--left']
          ]"
        >
          <div
            :class="[
              $style['ball-possession-chart-bar__filling'],
              $style['ball-possession-chart-bar__filling--left']
            ]"
            :style="{ width: `${confrontationBlock.host.percent}%` }"
          />
        </div>

        <div :class="$style['ball-possession-team__holder']">
          <div :class="$style['ball-possession-team']">
            <div>
              <StatisticTeamLogo
                v-if="host"
                :class="$style['ball-possession-team__logo']"
                :team="host"
              />
            </div>

            <div :class="$style['ball-possession-team__name']">
              <template v-if="host">
                {{ host.name }}
              </template>
            </div>

            <div :class="$style['ball-possession-team__score']">
              {{ confrontationBlock.host.value }}
            </div>
          </div>
        </div>
      </div>
      <div :class="$style['ball-possession-chart-bar']">
        <div
          :class="[
            $style['ball-possession-chart-bar__filling-borders'],
            $style['ball-possession-chart-bar__filling-borders--right']
          ]"
        >
          <div
            :class="[
              $style['ball-possession-chart-bar__filling'],
              $style['ball-possession-chart-bar__filling--right']
            ]"
            :style="{ width: `${confrontationBlock.guest.percent}%` }"
          />
        </div>

        <div :class="$style['ball-possession-team__holder']">
          <div
            :class="[
              $style['ball-possession-team'],
              $style['ball-possession-team--right']
            ]"
          >
            <div>
              <StatisticTeamLogo
                v-if="guest"
                :class="$style['ball-possession-team__logo']"
                :team="guest"
              />
            </div>

            <div
              :class="[
                $style['ball-possession-team__name'],
                $style['ball-possession-team__name--right']
              ]"
            >
              <template v-if="guest">
                {{ guest.name }}
              </template>
            </div>

            <div
              :class="[
                $style['ball-possession-team__score'],
                $style['ball-possession-team__score--right']
              ]"
            >
              {{ confrontationBlock.guest.value }}
            </div>
          </div>
        </div>
      </div>

      <div :class="$style['ball-possession-sport-icon__holder']">
        <div :class="$style['ball-possession-sport-icon']">
          <SportIcon
            v-if="sportFamily"
            :size="IconSize.SIZE_16"
            :sport-name="sportFamily"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>
.ball-possession-statistic-block {
  @include medium\12\12;

  padding: 4px;
  color: var(--TextPrimary);
  background-color: var(--Layer2);
  border-radius: 5px;

  &__heading {
    @include medium\13\16;

    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    margin: 4px 4px 8px;
  }

  &__charts {
    position: relative;
    box-sizing: content-box;
    display: flex;
    flex-wrap: nowrap;
    gap: 4px;
    justify-content: flex-start;
    margin: 4px;
    background-color: transparent;
    border: 1px var(--Highlight) solid;
    border-radius: 5px;
  }
}

.ball-possession-sport-icon {
  width: 28px;
  height: 28px;
  padding: 6px;
  color: var(--TextDefault);
  background-color: var(--Layer2);
  border-radius: 28px;

  &__holder {
    position: absolute;
    left: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 28px;
    height: 100%;
    margin-left: -14px;
    overflow: auto;
  }
}

.ball-possession-chart-heading {
  flex-grow: 1;
  text-align: center;

  &--left {
    text-align: left;
  }

  &--right {
    text-align: right;
  }
}

.ball-possession-chart-bar {
  position: relative;
  flex-grow: 1;
  height: 40px;
  padding: 4px;

  &__filling-borders {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    height: 100%;
    overflow: hidden;

    &--left {
      border-radius: 2px 0 0 2px;
    }

    &--right {
      flex-direction: row;
      border-radius: 0 2px 2px 0;
    }
  }

  &__filling {
    display: block;
    height: 100%;
    opacity: 0.5;

    &--left {
      background-color: var(--StatsA);
    }

    &--right {
      background-color: var(--StatsB);
    }
  }
}

.ball-possession-team {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 24px 0 8px;

  &--right {
    flex-direction: row-reverse;
    padding: 0 8px 0 24px;
  }

  &__holder {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  &__score {
    @include medium\14\16\025;

    flex-basis: 100px;
    color: var(--TextDefault);
    text-align: right;

    &--right {
      text-align: left;
    }
  }

  &__name {
    @include medium\14\16\025;

    flex-basis: 100%;
    padding: 0 8px;
    overflow: hidden;
    color: var(--TextDefault);
    text-overflow: ellipsis;
    white-space: nowrap;

    &--right {
      text-align: right;
    }
  }

  &__icon {
    flex-basis: 16px;
  }

  &__logo {
    width: 20px;
    height: 20px;
  }
}
</style>
