import type { RouteLocationRaw } from 'vue-router';

import type { NavigationItem } from '@leon-hub/navigation-config';
import { CategoryType } from '@leon-hub/api-sdk';
import { RouteName } from '@leon-hub/routing-config-names';

import type { AppVueRouter } from 'web/src/modules/core/services/router/types';

export default function resolveCategoryLink(
  item: NavigationItem,
  router: AppVueRouter,
): RouteLocationRaw {
  const options = {
    params: { categoryId: item.id },
  };

  if (item.props?.type === CategoryType.BONUS_WALLET) {
    return router.resolve({ name: RouteName.BONUS_WALLET });
  }

  return router.resolve({ name: RouteName.PROMOTIONS, ...options });
}
