import { RouteName } from '@leon-hub/routing-config-names';

import type { AppVueRouter } from 'web/src/modules/core/services/router/types';

export default function createPromotionsRoutes(router: AppVueRouter): void {
  const titlePromotionsPage = '{{$t(\'WEB2_PROMOTIONS_AND_BONUSES\')}}';
  const pathPromotionsPage = '/promo/:categoryId?';

  if (process.env.VUE_APP_LAYOUT_DESKTOP) {
    router.addDefaultRoute({
      name: RouteName.PROMOTIONS,
      path: pathPromotionsPage,
      isRightSidebarHidden: !!process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED,
      component: process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED
        ? () => import('web/src/modules/promotions/pages/SPromotionsRoutePage/SPromotionsRoutePage.vue')
        : () => import('web/src/modules/promotions/pages/PromotionsRoutePage/PromotionsRoutePage.vue'),
      // eslint-disable-next-line max-len
      leftSideBar: () => import('web/src/modules/promotions/layouts/PromotionsSidebarRouteComponent/PromotionsSidebarRouteComponent.vue'),
    });

    router.addDefaultRoute({
      name: RouteName.PROMOTION_DETAILS,
      path: '/promo/:categoryId/:actionUrl',
      // eslint-disable-next-line max-len
      component: process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED
        ? () => import('web/src/modules/promotions/pages/PromotionDetailsRoutePage/SPromotionDetailsRoutePage.vue')
        : () => import('web/src/modules/promotions/pages/PromotionDetailsRoutePage/PromotionDetailsRoutePage.vue'),
      // eslint-disable-next-line max-len
      leftSideBar: () => import('web/src/modules/promotions/layouts/PromotionsSidebarRouteComponent/PromotionsSidebarRouteComponent.vue'),
    });

    router.addDefaultRoute({
      name: RouteName.BONUS_WALLET,
      path: '/bonus-wallet',
      component: () => import('web/src/modules/promotions/pages/BonusWalletRoutePage/BonusWalletRoutePage.vue'),
      // eslint-disable-next-line max-len
      leftSideBar: () => import('web/src/modules/promotions/layouts/PromotionsSidebarRouteComponent/PromotionsSidebarRouteComponent.vue'),
    });

    if (process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED) {
      router.addDefaultRoute({
        name: RouteName.TOURNAMENTS,
        path: '/tournaments/:categoryId?',
        isRightSidebarHidden: true,
        component: () => import('web/src/modules/promotions/pages/SPromotionsRoutePage/SPromotionsRoutePage.vue'),
        // eslint-disable-next-line max-len
        leftSideBar: () => import('web/src/modules/promotions/layouts/PromotionsSidebarRouteComponent/PromotionsSidebarRouteComponent.vue'),
      });

      router.addDefaultRoute({
        name: RouteName.TOURNAMENT_DETAILS,
        path: '/tournaments/:categoryId/:actionUrl',
        // eslint-disable-next-line max-len
        component: () => import('web/src/modules/promotions/pages/PromotionDetailsRoutePage/SPromotionDetailsRoutePage.vue'),
        // eslint-disable-next-line max-len
        leftSideBar: () => import('web/src/modules/promotions/layouts/PromotionsSidebarRouteComponent/PromotionsSidebarRouteComponent.vue'),
      });
    }
  }

  if (process.env.VUE_APP_LAYOUT_PHONE) {
    router.addModalRoute({
      name: RouteName.PROMOTION_DETAILS,
      path: '/promo/:categoryId/:actionUrl',
      topBar: () => import('web/src/modules/promotions/layout/PromotionDetailsTopBarRouteComponent.vue'),
      // eslint-disable-next-line max-len
      component: process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED
        ? () => import('web/src/modules/promotions/pages/PromotionDetailsRoutePage/SPromotionDetailsRoutePage.vue')
        : () => import('web/src/modules/promotions/pages/PromotionDetailsRoutePage/PromotionDetailsRoutePage.vue'),
      isFooterVisible: true,
    });

    router.addModalRoute({
      name: RouteName.BONUS_WALLET,
      path: '/bonus-wallet',
      title: titlePromotionsPage,
      component: () => import('web/src/modules/promotions/pages/BonusWalletRoutePage/BonusWalletRoutePage.vue'),
      navigation: () => import('web/src/modules/promotions/layout/PromotionsTabsRouteComponent.vue'),
      isFooterVisible: true,
    });

    if (process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED) {
      router.addDefaultRoute({
        name: RouteName.PROMOTIONS,
        path: pathPromotionsPage,
        leftSideBar: undefined,
        component: () => import('web/src/modules/promotions/pages/SPromotionsRoutePage/SPromotionsRoutePage.vue'),
        navigation: () => import('web/src/modules/promotions/layout/PromotionsTabsRouteComponent.vue'),
      });

      router.addDefaultRoute({
        name: RouteName.TOURNAMENTS,
        path: '/tournaments/:categoryId?',
        component: () => import('web/src/modules/promotions/pages/SPromotionsRoutePage/SPromotionsRoutePage.vue'),
        navigation: () => import('web/src/modules/promotions/layout/PromotionsTabsRouteComponent.vue'),
      });

      router.addModalRoute({
        name: RouteName.TOURNAMENT_DETAILS,
        path: '/tournaments/:categoryId/:actionUrl',
        topBar: () => import('web/src/modules/promotions/layout/PromotionDetailsTopBarRouteComponent.vue'),
        // eslint-disable-next-line max-len
        component: () => import('web/src/modules/promotions/pages/PromotionDetailsRoutePage/SPromotionDetailsRoutePage.vue'),
        isFooterVisible: true,
      });
    } else {
      router.addModalRoute({
        name: RouteName.PROMOTIONS,
        path: pathPromotionsPage,
        title: titlePromotionsPage,
        component: () => import('web/src/modules/promotions/pages/PromotionsRoutePage/PromotionsRoutePage.vue'),
        navigation: () => import('web/src/modules/promotions/layout/PromotionsTabsRouteComponent.vue'),
        isFooterVisible: true,
      });
    }
  }
}
