import type { Ref } from 'vue';
import { computed, ref } from 'vue';

import { logger } from '@leon-hub/logging';
import { getLSportsWidgetConfiguration } from '@leon-hub/api-sdk';

import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';

import type {
  LSportsWidgetConfig,
  LSportsWidgetFrameConfig,
} from '../types';

interface UseLSportsConfigProps {
  iframeUrl: Ref<Optional<string>>;
}

interface UseLSportsConfigComposable {
  config: Ref<Maybe<LSportsWidgetConfig>>;
  configFinal: Ref<Maybe<LSportsWidgetFrameConfig>>;
  fetchConfig(): Promise<void>;
  getIsActiveWidgetRef(sport: string): Ref<boolean>;
}

export function useLSportsConfig(
  props: UseLSportsConfigProps,
): UseLSportsConfigComposable {
  const { iframeUrl } = props;

  const api = useGraphqlClient();

  const config = ref<Maybe<LSportsWidgetConfig>>(null);
  const isActiveSportsCache: Record<string, Ref<boolean>> = {};

  const configFinal = computed<Maybe<LSportsWidgetFrameConfig>>(() => (
    config.value
      ? {
        ...config.value,
        iframeUrl: iframeUrl.value,
      }
      : null
  ));

  async function fetchConfig(): Promise<void> {
    try {
      config.value = await getLSportsWidgetConfiguration(
        api,
        (node) => node.queries.configContent.getLSportsWidgetConfiguration,
        { options: {} },
      );
    } catch (error) {
      logger.error('An error occurred while updating LSportsConfig data', error);
    }
  }

  function getIsActiveWidgetRef(sport: string): Ref<boolean> {
    if (isActiveSportsCache[sport]) {
      return isActiveSportsCache[sport];
    }

    isActiveSportsCache[sport] = computed(() => (
      !!config.value?.activeSports?.map((item) => item.toLowerCase()).includes(sport)
    ));

    return isActiveSportsCache[sport];
  }

  return {
    config,
    configFinal,
    fetchConfig,
    getIsActiveWidgetRef,
  };
}
