import CustomerRouteAccessRole from '@leon-hub/routing-config/src/types/CustomerRouteAccessRole';
import { RouteName } from '@leon-hub/routing-config-names';

import { useRouterStore } from 'web/src/modules/core/store';

import type { AppVueRouter } from '../types';

export default function performLogout(router: AppVueRouter): void {
  const { currentRoute, getAnonymousRouteHistoryDelta } = useRouterStore();

  if (currentRoute?.meta.access === CustomerRouteAccessRole.AUTHORIZED) {
    const delta = getAnonymousRouteHistoryDelta();
    if (delta !== 0) {
      router.go(delta);
    } else {
      void router.replace({
        name: RouteName.HOME,
      });
    }
  }
}
