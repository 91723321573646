<script lang="ts" setup>
import {
  ref,
  toRef,
  onBeforeMount,
  onBeforeUnmount,
  normalizeClass,
} from 'vue';

import VPreloader from 'web/src/components/Preloader/VPreloader/VPreloader.vue';

import { useVIframe } from './composables';
import type {
  VIframeProps,
  VIframeEmits,
  VIframeRef,
} from './types';

const props = withDefaults(defineProps<VIframeProps>(), {
  crossorigin: 'anonymous',
  target: '_parent',
  timeout: 0,
});
const emit = defineEmits<VIframeEmits>();

const iframeElement = ref<HTMLIFrameElement>();

const {
  loading,
  reCreateIframeKey,
  styleObject,
  iframeVisible,
  title,
  height,
  parametersDecoratedSrc,
  onLoad,
  beforeMount,
  beforeUnmount,
  resizeIframe,
  postMessage,
  onError,
} = useVIframe({
  iframeElement,
  src: toRef(props, 'src'),
  name: toRef(props, 'name'),
  timeout: toRef(props, 'timeout'),
  additionalQueryStringParameters: toRef(props, 'additionalQueryStringParameters', []),
  isHideFullHeightInUrl: toRef(props, 'hideFullHeightInUrl'),
  isFullSize: toRef(props, 'fullSize', false),
  heightAspectRatio: toRef(props, 'heightAspectRatio'),
  isAutoHeight: toRef(props, 'autoHeight', false),
  isHideTitle: toRef(props, 'hideTitle', false),
}, emit);

onBeforeMount(beforeMount);
onBeforeUnmount(beforeUnmount);
defineExpose<VIframeRef>({
  resizeIframe,
  postMessage,
});
</script>

<template>
  <div v-auto-id="'VIframe'"
    :class="{
      [$style['v-iframe']]: true,
      [$style['v-iframe--full-height']]: fullHeight || fullSize,
      [$style['v-iframe--aspect-ratio-height']]: !!heightAspectRatio,
      [$style['v-iframe--full-width']]: fullWidth || fullSize,
      [$style['v-iframe--flex-layout']]: flexLayout,
    }"
    :style="styleObject"
  >
    <VPreloader
      v-if="showLoader && loading"
      :class="$style['v-iframe__loader']"
      hint=""
    />
    <iframe
      :key="reCreateIframeKey"
      ref="iframeElement"
      :src="parametersDecoratedSrc"
      :crossorigin="crossorigin"
      :target="target"
      :allow="allow"
      :allowfullscreen="allowfullscreen || undefined"
      :class="{
        [$style['v-iframe__iframe']]: true,
        [$style['v-iframe__iframe--hidden']]: !iframeVisible,
        [$style['v-iframe__iframe--dark']]: isDark,
        [normalizeClass(iframeClass)]: !!iframeClass,
      }"
      :height="height"
      :name="name"
      :title="title"
      :scrolling="scrolling"
      @load="onLoad"
      @error="onError"
    />
  </div>
</template>

<style lang="scss" module>
.v-iframe {
  $self: &;

  position: relative;
  display: flex;

  &--full-height {
    height: 100%;
  }

  &--aspect-ratio-height {
    position: relative;
    flex-basis: 100%;
    min-width: 100%;
    height: 0;
    padding-top: 100%; // default aspect ratio value
    overflow: hidden;

    & > iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &--full-width {
    width: 100%;
  }

  &--flex-layout {
    display: flex;
    flex-direction: column;
  }

  &__iframe {
    visibility: visible;
    border: none;

    #{$self}--full-height & {
      height: 100%;
    }

    #{$self}--full-width & {
      width: 100%;
    }

    &--hidden {
      position: absolute;
      top: -99999px;
      visibility: hidden;
    }

    &--dark {
      background-color: var(--Layer1);
    }
  }

  &--flex-layout &__iframe {
    flex: 1;
  }

  &__loader {
    z-index: 2;
  }

  &__loader-logo {
    width: 100%;
    max-width: 275px;
    margin: auto;
  }
}
</style>
