<script lang="ts" setup>
import { computed, ref, toRef } from 'vue';

import {
  useProvideMarketColumn,
  useExpandableSportlineBlock,
} from 'web/src/modules/sportline/composables/list';
import {
  useSportlineElementMarketColumns,
  useSportlineElementMarketTypes,
} from 'web/src/modules/sportline/composables/markets';
import type {
  LeagueElement,
  Sport,
  Region,
  SportEventElement,
  SportEventsCounters,
  SportId,
} from 'web/src/modules/sportline/types';
import { ExtendedEventBlock } from 'web/src/modules/sportline/submodules/extended-event/enums';
import type { HeadlineMatchesSportListElement } from 'web/src/modules/sportline/submodules/top/types';
import SportlineEventBlock from 'web/src/modules/sportline/views/EventBlock/SportlineEventBlock.vue';
import LeagueHeadline from 'web/src/modules/sportline/views/headline/LeagueHeadline.vue';
import SportHeadline from 'web/src/modules/sportline/views/headline/SportHeadline.vue';
import {
  addCountersToCounters,
  addSportlineEventToCounters,
  createSportEventsCounters,
} from 'web/src/modules/sportline/utils';
import { createSportlineFragmentBasisKey } from 'web/src/modules/sportline/composables/list/utils';

interface SportlineHomeSportElementProps {
  sportElement: HeadlineMatchesSportListElement;
  isShownLiveIndicator?: boolean;
  isShownLeaguesHeadline?: boolean;
  canShowLogos?: boolean;
  isFilterActive?: boolean;
  isMarketTypesSelectionEnabled?: boolean;
  expandKey?: Maybe<string>;
  isDefaultExpanded?: boolean;
}

const props = defineProps<SportlineHomeSportElementProps>();

const sportElement = toRef(props, 'sportElement');
const isMarketTypesSelectionEnabled = toRef(props, 'isMarketTypesSelectionEnabled', false);
const expandKey = toRef(props, 'expandKey', null);
const isDefaultExpanded = toRef(props, 'isDefaultExpanded', false);
const isShownLeaguesHeadline = toRef(props, 'isShownLeaguesHeadline', false);

const sport = computed<Sport>(() => sportElement.value.sport);
const sportId = computed<SportId>(() => sport.value.id);
const basisKey = createSportlineFragmentBasisKey({ sportId });

const { marketsColumns } = useSportlineElementMarketColumns({ sportlineElement: sportElement });
const { marketsTypes } = useSportlineElementMarketTypes({ sportlineElement: sportElement });

interface ListElement {
  region: Region;
  leagueElement: LeagueElement;
  sportEvents: SportEventElement[];
}

const content = ref<Optional<HTMLDivElement>>();

const list = computed<ListElement[]>(() => (
  sportElement.value.sportEvents.reduce((result, { sportEvent, league, region }) => {
    let currentElement: ListElement;
    const previousElement: Maybe<ListElement> = result[result.length - 1] ?? null;

    if (!previousElement || league.key !== previousElement.leagueElement.league.key) {
      const leagueElement = {
        key: league.key,
        league,
        marketTypes: marketsTypes.value,
        sportEvents: [],
        outrightEvents: [],
        counters: createSportEventsCounters(),
      };

      currentElement = {
        region,
        leagueElement,
        sportEvents: [],
      };

      result.push(currentElement);
    } else {
      currentElement = previousElement;
    }

    currentElement.sportEvents.push({ sportEvent });
    currentElement.leagueElement.counters = addSportlineEventToCounters(
      currentElement.leagueElement.counters,
      sportEvent,
    );

    return result;
  }, [] as ListElement[])
));
const counters = computed<SportEventsCounters>(() => list.value.reduce(
  (result, currentValue) => addCountersToCounters(result, currentValue.leagueElement.counters),
  createSportEventsCounters(),
));

const {
  isExpanded,
  isExpandable,
  expandAnimationActive,
  expandAnimationDuration,
  toggleExpandState,
} = useExpandableSportlineBlock({
  content,
  expandKey,
  isDefaultExpanded,
});
const {
  canSelectMarketType,
} = useProvideMarketColumn({
  basisKey,
  isMarketTypesSelectionEnabled,
  marketsColumns,
  marketsTypes,
});
</script>

<template>
  <div v-auto-id="'SportlineHomeSportElement'">
    <SportHeadline
      :sport-id="sport.id"
      :basis-key="basisKey"
      :is-market-types-selection-enabled="canSelectMarketType"
      :count-events="counters.total"
      :expand-key="expandKey"
      :is-expanded="expandAnimationActive ? !isExpanded : isExpanded"
      @toggle-expand-state="toggleExpandState"
    />

    <div
      ref="content"
      :class="{
        [$style['sportline-events-list__container']]: true,
        [$style['expandable-element-content']]: true,
        [$style['expandable-element-content--hidden']]: isExpandable && !isExpanded,
      }"
      :style="{ 'transition-duration': `${expandAnimationDuration}ms` }"
    >
      <div
        v-for="(element, index) in list"
        :key="`${element.leagueElement.league.key}_${index}`"
        :class="$style['sportline-events-list']"
      >
        <LeagueHeadline
          v-if="isShownLeaguesHeadline"
          :key="element.leagueElement.key"
          :class="$style['league-headline']"
          :sport="sport"
          :region="element.region"
          :league-element="element.leagueElement"
          :is-filter-active="isFilterActive"
          :basis-key="basisKey"
        />

        <SportlineEventBlock
          v-for="sportEventElement in element.sportEvents"
          :key="sportEventElement.sportEvent.id"
          :sport="sport"
          :sport-event-element="sportEventElement"
          :is-shown-live-indicator="isShownLiveIndicator"
          :can-show-logos="canShowLogos"
          :can-add-to-favorite="!!'1'"
          :can-add-outrights-to-favorite="!!'1'"
          :extended-block-type="ExtendedEventBlock.Top"
          :basis-key="basisKey"
          today-label-type="inline"
        />
      </div>
    </div>
  </div>
</template>

<style module lang="scss">
@import 'web/src/modules/sportline/styles/expandable-element';

.league-headline {
  margin-top: 12px;

  &:first-child {
    margin-top: 0;
  }
}

.sportline-events-list {
  display: flex;
  flex-direction: column;
  gap: 2px;

  &__container {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
</style>
