import { RouteName } from '@leon-hub/routing-config-names';

import { AppModule } from 'web/src/modules/core/plugins/AppModule';
import type { AppVueRouter } from 'web/src/modules/core/services/router/types';
import { ModalDesktopPreset } from 'web/src/modules/core/enums';

export class OnboardingModule extends AppModule {
  // eslint-disable-next-line class-methods-use-this
  addRoutes(router: AppVueRouter): void {
    const routeOptions = {
      name: RouteName.ONBOARDING,
      path: '/onboarding/:id',
      component: () => import('web/src/modules/onboarding/pages/OnboardingRoutePage.vue'),
      prefetch: () => import('web/src/modules/onboarding/prefetch/OnboardingPagePrefetch'),
      title: '',
      topBar: () => import('web/src/modules/onboarding/topbars/OnboardingTopbar/OnboardingTopbar.vue'),
    };
    const { addProfileRoute, addAnonymousProfileRoute, addModalRoute } = router;
    if (process.env.VUE_APP_FEATURE_ASO_ENABLED) {
      const topBar = () => import(
        'web/src/modules/core/views/SimpleBrandTopBarRouteComponent/SimpleBrandTopBarRouteComponent.vue'
      );
      if (process.env.VUE_APP_LAYOUT_DESKTOP) {
        addModalRoute({
          ...routeOptions,
          topBar,
          modalPreset: ModalDesktopPreset.PrivacyPolicyModal,
        });
      } else {
        addAnonymousProfileRoute({
          ...routeOptions,
          topBar,
        });
      }
    } else {
      addProfileRoute(routeOptions);
    }
  }
}
