import type { Cookie } from '@leon-hub/api-sdk';
import {
  clearCookies,
  getCookie,
  setCoookie,
} from '@leon-hub/api-sdk';

import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';

import type { CookieOptions } from './types';

export interface UseCookiesComposable {
  set(options: CookieOptions): Promise<void>;
  get(name: string): Promise<Maybe<string> | undefined>;
  getAll(): Promise<Cookie[]>;
  clear(): Promise<void>;
}

export default function useCookies(): UseCookiesComposable {
  const api = useGraphqlClient();

  async function set(options: CookieOptions): Promise<void> {
    await setCoookie(api,
      (node) => node.mutations.cookies.setCookie,
      {
        options: {
          cookie: {
            ...options,
            httpOnly: true,
          },
        },
      },
      { silent: true });
  }

  async function get(name: string): Promise<Maybe<string> | undefined> {
    const cookies = await getCookie(api,
      (node) => node.queries.cookies.getCookies,
      { options: {} },
      { silent: true });

    const cookie = cookies.cookies.find((item) => item.name === name);

    return cookie ? cookie.value : undefined;
  }

  async function getAll(): Promise<Cookie[]> {
    const cookies = await getCookie(api,
      (node) => node.queries.cookies.getCookies,
      { options: {} },
      { silent: true });

    return cookies.cookies;
  }

  async function clear(): Promise<void> {
    await clearCookies(api,
      (node) => node.mutations.cookies.clearCookies,
      { options: {} },
      { silent: true });
  }

  return {
    get,
    getAll,
    set,
    clear,
  };
}
