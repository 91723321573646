import getSlipEntryId from '../../../utils/getSlipEntryId';
import type { SlipEntry, SlipEntryId } from '../../slip-info/types';

export default function isFocusedIdBecameUnavailable(focusedId: SlipEntryId, unavailableEvents: SlipEntry[]): boolean {
  for (const event of unavailableEvents) {
    const id = getSlipEntryId(event);
    if (id === focusedId) {
      return true;
    }
  }
  return false;
}
