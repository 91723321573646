import type { Ref } from 'vue';
import { computed, ref } from 'vue';

import type {
  Market,
} from 'web/src/modules/sportline/types';
import type {
  MarketsGridCell,
  MarketsGridFilter,
  MarketsGridCellOpenStateKey,
} from 'web/src/modules/sportline/submodules/markets-grid/types';
import {
  groupMarketsIntoGridCells,
} from 'web/src/modules/sportline/submodules/markets-grid/utils';

export interface MarketsListGroupsProps {
  allMarketsList: Ref<Market[]>;
  isAllTabSelected: Ref<boolean>;
  activeGridFilter: Ref<Maybe<MarketsGridFilter>>;
  closedMarketGroups: Ref<MarketsGridCellOpenStateKey[]>;
  defaultMarketsLimit: Ref<number>;
}

interface MarketsListGroupsComposable {
  isMoreMarketsClicked: Ref<boolean>;
  isMoreMarketButtonVisible: Ref<boolean>;
  filteredGroups: Ref<MarketsGridCell[]>;
  oddGroups: Ref<MarketsGridCell[]>;
  evenGroups: Ref<MarketsGridCell[]>;
  hasAnyGroups: Ref<boolean>;
}

export function useMarketsListGroups(
  props: MarketsListGroupsProps,
): MarketsListGroupsComposable {
  const {
    allMarketsList,
    isAllTabSelected,
    activeGridFilter,
    closedMarketGroups,
    defaultMarketsLimit,
  } = props;

  const isMoreMarketsClicked = ref<boolean>(false);

  const groups = computed<MarketsGridCell[]>(() => groupMarketsIntoGridCells({
    doShowAll: isAllTabSelected.value,
    filter: activeGridFilter.value,
    markets: allMarketsList.value,
    collapsedCellKeys: closedMarketGroups.value,
  }));

  const isMoreMarketButtonVisible = computed<boolean>(() => (
    defaultMarketsLimit.value > 0
    && !isMoreMarketsClicked.value
    && defaultMarketsLimit.value < groups.value.length
  ));
  const filteredGroups = computed<MarketsGridCell[]>(() => {
    if (!isMoreMarketButtonVisible.value) { return groups.value; }
    return groups.value.slice(0, defaultMarketsLimit.value);
  });
  const oddGroups = computed<MarketsGridCell[]>(() => filteredGroups.value.filter((g, index) => !(index % 2)));
  const evenGroups = computed<MarketsGridCell[]>(() => filteredGroups.value.filter((g, index) => (index % 2)));
  const hasAnyGroups = computed<boolean>(() => groups.value.length > 0);

  return {
    isMoreMarketsClicked,
    isMoreMarketButtonVisible,
    filteredGroups,
    oddGroups,
    evenGroups,
    hasAnyGroups,
  };
}
