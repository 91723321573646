import type { Ref } from 'vue';
import { toRef, computed } from 'vue';

import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import { useSportlineEventLiveWidget } from 'web/src/modules/sportline/submodules/event-details/composables/widget';
import { useSportlineWidgetsStore } from 'web/src/modules/sportline/submodules/widgets/store';
import type {
  SportlineEventDetails,
  SportEventWidgetConfig,
} from 'web/src/modules/sportline/submodules/event-details/types';
import {
  SportWidgetType,
} from 'web/src/modules/sportline/enums';

interface UseSportEventWidgetConfigProps {
  sportEventDetails: Ref<Maybe<SportlineEventDetails>>;
}

interface UseSportEventWidgetConfigComposable {
  sportEventWidgetConfig: Ref<SportEventWidgetConfig>;
  liveWidgetType: Ref<Optional<SportWidgetType>>;
}

export function useSportEventWidgetConfig(
  props: UseSportEventWidgetConfigProps,
): UseSportEventWidgetConfigComposable {
  const { sportEventDetails } = props;

  const sportlineSettingsStore = useSportlineSettingsStore();
  const sportlineWidgetsStore = useSportlineWidgetsStore();
  const betgeniusConfigFinal = toRef(sportlineWidgetsStore, 'betgeniusConfigFinal');
  const lsportsConfigFinal = toRef(sportlineWidgetsStore, 'lsportsConfigFinal');
  const { isActiveLSportWidgetSport } = sportlineWidgetsStore;

  const isLiveStreamEnabled = toRef(sportlineSettingsStore, 'isLiveStreamEnabled');
  const isSportradarLiveMatchTrackerEnabled = toRef(sportlineSettingsStore, 'isSportradarLiveMatchTrackerEnabled');
  const isBetGeniusWidgetEnabled = toRef(sportlineSettingsStore, 'isBetGeniusWidgetEnabled');
  const isLSportsWidgetEnabled = toRef(sportlineSettingsStore, 'isLSportsWidgetEnabled');
  const isLiveStreamWidgetFloatingEnabled = toRef(sportlineSettingsStore, 'isLiveStreamWidgetFloatingEnabled');
  const isSportRadarWidgetFloatingEnabled = toRef(sportlineSettingsStore, 'isSportRadarWidgetFloatingEnabled');

  const {
    liveWidget: sportEventDetailsLiveWidget,
  } = useSportlineEventLiveWidget({ sportlineEvent: sportEventDetails });

  /** @deprecated use each setting directly */
  const sportEventWidgetConfig = computed<SportEventWidgetConfig>(() => ({
    liveStreamEnabled: isLiveStreamEnabled.value,
    sportradarLiveMatchTrackerEnabled: isSportradarLiveMatchTrackerEnabled.value,
    betGeniusWidgetEnabled: isBetGeniusWidgetEnabled.value,
    lsportsWidgetEnabled: isLSportsWidgetEnabled.value,
    liveStreamWidgetFloatingEnabled: isLiveStreamWidgetFloatingEnabled.value,
    sportRadarWidgetFloatingEnabled: isSportRadarWidgetFloatingEnabled.value,
  }));

  const isBetgeniusWidgetAvailable = computed<boolean>(() => (
    isBetGeniusWidgetEnabled.value
    && sportEventDetailsLiveWidget.value?.type === SportWidgetType.BET_GENIUS
    && !!betgeniusConfigFinal.value?.active
  ));
  const isLSportsWidgetAvailable = computed<boolean>(() => {
    if (
      !sportEventDetails.value
      || !lsportsConfigFinal.value?.active
      || !isLSportsWidgetEnabled.value
      || sportEventDetailsLiveWidget.value?.type !== SportWidgetType.LSPORTS
    ) { return false; }

    return isActiveLSportWidgetSport(
      // @TODO check is this really sportFamily from representation or must be from navigationParameters
      sportEventDetails.value.sport.representation.family,
    ).value;
  });
  const isSportradarLiveMatchTrackerAvailable = computed<boolean>(() => {
    if (sportEventDetailsLiveWidget.value?.type !== SportWidgetType.SPORTRADAR) {
      return false;
    }

    return isSportradarLiveMatchTrackerEnabled.value;
  });
  const liveWidgetType = computed<Optional<SportWidgetType>>(() => {
    if (!sportEventDetailsLiveWidget.value) { return undefined; }
    if (isBetgeniusWidgetAvailable.value) { return SportWidgetType.BET_GENIUS; }
    if (isLSportsWidgetAvailable.value) { return SportWidgetType.LSPORTS; }
    if (isSportradarLiveMatchTrackerAvailable.value) { return SportWidgetType.SPORTRADAR; }
    return undefined;
  });

  return {
    sportEventWidgetConfig,
    liveWidgetType,
  };
}
