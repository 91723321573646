import { getQueryParams, getBuiltinConfig } from '@leon-hub/service-locator-env';
import { localStorageManager } from '@leon-hub/local-storage';

import { featureKey } from '../constants';

function isForbiddenByDevIp(isRemoteDevIp?: boolean) {
  // We can't check inline(config is not inlined) dev-ip restrictions for cordova.
  if (process.env.VUE_APP_PLATFORM_CORDOVA) return false;
  if (isRemoteDevIp) return false;
  return !getBuiltinConfig().isNT;
}

export const isAutomationPluginEnabled = (isRemoteDevIp?: boolean): boolean => {
  if (process.env.VUE_APP_FEATURE_AUTOMATION_ID_DISABLED) return false;
  if (!process.env.VUE_APP_PLATFORM_CORDOVA && isForbiddenByDevIp(isRemoteDevIp)) return false;
  if (process.env.VUE_APP_PLATFORM_WEB && featureKey in getQueryParams()) {
    return true;
  }

  return !!localStorageManager.getItem(featureKey);
};
