export default function getDefaultInitValue(
  defaultInitInputValue: number | undefined,
  minAmount: number | undefined,
  maxAmount: number | undefined,
): number {
  if (!defaultInitInputValue) return minAmount || 0;
  if (maxAmount && defaultInitInputValue > maxAmount) {
    return maxAmount;
  }
  if (defaultInitInputValue >= (minAmount || 0)) {
    return defaultInitInputValue;
  }
  return minAmount || 0;
}
