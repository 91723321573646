<script setup lang="ts">
import { IconName } from '@leon-hub/icons';

import { VIcon } from '@components/v-icon';

import type { InputButtonEmits, InputButtonProps } from './types';
import getInputIconSize from '../../utils/getInputIconSize';

const props = withDefaults(defineProps<InputButtonProps>(), {
  iconName: null ? IconName.SLOTT_ERASE : IconName.CANCEL,
});
defineEmits<InputButtonEmits>();

// ok not to be reactive
const iconSize = getInputIconSize(props.isLarge);
</script>

<template>
  <button v-auto-id="'InputButton'"
    type="button"
    tabindex="-1"
    :title="title"
    :class="{
      [$style['input-button']]: true,
      [$style['input-button--large']]: isLarge,
    }"
    @mousedown.prevent="$emit('click')"
  >
    <VIcon
      :size="iconSize"
      :name="iconName"
    />
  </button>
</template>

<style lang="scss" module>
.input-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: $iconButtonWidth;
  height: $inputHeight;
  padding: 0;
  color: $iconButtonColor;
  cursor: pointer;
  background: transparent;
  border: none;

  @include for-hover {
    &:hover {
      color: $iconButtonColorHover;
    }
  }

  &--large {
    width: $iconButtonWidthLarge;
    height: $inputHeightLarge;
  }
}
</style>
