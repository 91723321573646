import type { Ref } from 'vue';
import { computed } from 'vue';

import { useScrollElementIntoView } from './useScrollElementIntoView';

export interface UseExpandableSportlineEventBlockProps {
  container: Ref<Optional<HTMLElement>>;
  isExtendedEventViewDisabled: Ref<boolean>;
}

interface UseExpandableSportlineEventBlockComposable {
  isExtendedSportEventAllowed: Ref<boolean>;
  beforeExtendedOpen(): void;
  afterExtendedOpen(): void;
  afterExtendedClose(): void;
}

export function useExpandableSportlineEventBlock(
  props: UseExpandableSportlineEventBlockProps,
): UseExpandableSportlineEventBlockComposable {
  const {
    container,
    isExtendedEventViewDisabled,
  } = props;

  const {
    stopScrollIntoView,
    startScrollIntoView,
    scrollIntoViewAndStop,
  } = useScrollElementIntoView({ container });

  const isExtendedSportEventAllowed = computed<boolean>(() => (
    process.env.VUE_APP_LAYOUT_DESKTOP
      ? !isExtendedEventViewDisabled.value
      : false
  ));

  function beforeExtendedOpen(): void {
    startScrollIntoView();
  }

  function afterExtendedOpen(): void {
    scrollIntoViewAndStop();
  }

  function afterExtendedClose(): void {
    stopScrollIntoView();
  }

  return {
    isExtendedSportEventAllowed,
    beforeExtendedOpen,
    afterExtendedOpen,
    afterExtendedClose,
  };
}
