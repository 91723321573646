import { normalizeError } from '@leon-hub/errors';
import { promiseTimeout } from '@leon-hub/utils';
import type { HttpApiResponse } from '@leon-hub/cordova';
import {
  isAdvancedHttpRequestsEnabled,
  makeCordovaHttpRequest,
} from '@leon-hub/cordova';

export default async function doFetch(
  url: string,
  options: RequestInit,
  timeout?: number,
  onError?: ((originalError?: Error, request?: Request) => Error),
  controller?: AbortController,
  preparedRequestCallback?: ((request: Request) => void),
): Promise<HttpApiResponse | Response> {
  const abortController = controller ?? new AbortController();
  let isAborted = false;

  abortController.signal.addEventListener('abort', () => {
    isAborted = true;
  });

  const request: Request = Object.assign(
    new Request(url, {
      ...options,
      signal: abortController.signal,
    }),
    {
      toJSON: () => ({
        url,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        headers: Object.fromEntries(request.headers.entries()),
        body: options.body,
      }),
    },
  );

  if (preparedRequestCallback) {
    preparedRequestCallback(request);
  }

  if (process.env.VUE_APP_PLATFORM_CORDOVA && await isAdvancedHttpRequestsEnabled()) {
    return makeCordovaHttpRequest(
      request.url,
      request.method,
      Object.fromEntries(request.headers.entries()),
      options.body,
      timeout,
      abortController,
      onError,
    );
  }

  return promiseTimeout({
    timeout: timeout ?? 15_000,
    promise: fetch(request),
    error: onError
      ? (originalError) => onError(normalizeError(originalError), request)
      : undefined,
    onTimeout: () => isAborted || abortController.abort(),
  });
}
