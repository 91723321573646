export interface PaymentsLogoProperties {
  alt: string;
  src: string;
}

export enum PaymentSystemName {
  DEPTSUPISSTEREETTERMINAL = 'deptsupisstreetterminal',
}

export const DEPOSIT_BONUS_FORM_FIELD = 'cust_bcodes';
