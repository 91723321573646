import type { Ref, ComputedRef } from 'vue';
import {
  ref,
  watch,
  onMounted,
  computed,
} from 'vue';

import { useI18n } from 'web/src/modules/i18n/composables';
import type { CasinoLevelSettingWithStatus } from 'web/src/modules/casino-loyalty-program/store/types';
import type { VSwiperRef } from 'web/src/components/Swiper/VSwiper/types';
import { useDateLocalizers } from 'web/src/modules/i18n/composables/useDateLocalizers';

import type { CasinoLoyaltyProgramSwiperEmits, CasinoLoyaltyProgramSwiperProps } from '../types';

export interface CasinoLoyaltyProgramSwiperComposable {
  swiper: Ref<VSwiperRef | undefined>;
  currentMonthProgressText: Ref<string>;
  indexCurrentLevel: ComputedRef<number>;
  emitClickCard: (item: CasinoLevelSettingWithStatus) => void;
}

export default function CasinoLoyaltyProgramSwiper(
  props: CasinoLoyaltyProgramSwiperProps,
  emit: CasinoLoyaltyProgramSwiperEmits,
): CasinoLoyaltyProgramSwiperComposable {
  const { $translate } = useI18n();

  const swiper = ref<VSwiperRef>();

  const currentMonthProgressText = $translate('WEB2_LOYALTY_PROGRAM_CURRENT_MONTH_PROGRESS', ref({
    currentMonth: useDateLocalizers().toMediumLocaleDate(new Date(), 'MMMM'),
  }));

  const indexCurrentLevel = computed(() => {
    const currentIndex = props.cards?.findIndex((item: CasinoLevelSettingWithStatus) => !!item?.progress);
    return currentIndex !== -1 ? currentIndex : 0;
  });

  function emitClickCard(item: CasinoLevelSettingWithStatus): void {
    emit('click-card', item.id);
  }

  function scrollToLevelWithProgress() {
    swiper.value?.slideToSlide(indexCurrentLevel.value);
  }

  watch(indexCurrentLevel, scrollToLevelWithProgress);

  onMounted(scrollToLevelWithProgress);

  return {
    swiper,
    currentMonthProgressText,
    indexCurrentLevel,
    emitClickCard,
  };
}
