import type { EgsGame } from 'web/src/modules/egs/types';

import { GameStartMethod } from '../enums';

export default function isDemoMethod(game: EgsGame, isLoggedIn: boolean, selectedMethod?: GameStartMethod): boolean {
  if (selectedMethod === GameStartMethod.DEMO) {
    return isLoggedIn || game.hasDemo;
  }

  return !isLoggedIn ? !selectedMethod && game.hasDemo : false;
}
