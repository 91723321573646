import type { LiveWidget } from 'web/src/modules/sportline/types';
import { SportWidgetType } from 'web/src/modules/sportline/enums';
import { BetlineSportWidgetType } from 'web/src/modules/sportline/enums/rest';
import type {
  CoreSportResponse,
  CoreSportEventResponse,
  BuildSportEventOptions,
} from 'web/src/modules/sportline/types/rest';

import { findSportRepresentationSetting } from '../pre-build';

const sportWidgetTypesMapping = {
  [BetlineSportWidgetType.BETRADAR]: SportWidgetType.SPORTRADAR,
  [BetlineSportWidgetType.LSPORTS]: SportWidgetType.LSPORTS,
  [BetlineSportWidgetType.BET_GENIUS]: SportWidgetType.BET_GENIUS,
  [BetlineSportWidgetType.NONE]: SportWidgetType.NONE,
};

function getWidgetTypeBySport(
  { id, family, virtual }: CoreSportResponse,
  { sportRepresentationSettings }: BuildSportEventOptions,
): BetlineSportWidgetType {
  if (process.env.VUE_APP_FEATURE_VIRTUAL_SPORT_ENABLED && virtual) {
    const item = findSportRepresentationSetting(sportRepresentationSettings, { id, family });
    return item?.defaultWidgetType ?? BetlineSportWidgetType.NONE;
  }

  return BetlineSportWidgetType.NONE;
}

function isEmptyWidgetType(widgetType?: Maybe<BetlineSportWidgetType>): widgetType is undefined {
  return !widgetType || widgetType === BetlineSportWidgetType.NONE;
}

export function createSportWidget({ response, sportResponse }: {
  response: CoreSportEventResponse;
  sportResponse: CoreSportResponse;
}, options: BuildSportEventOptions): LiveWidget | null {
  const { widgetId, widgetType, widgetVirtual } = response;
  const { family } = sportResponse;

  const finalType = isEmptyWidgetType(widgetType) ? getWidgetTypeBySport(sportResponse, options) : widgetType;

  if (!widgetId || !finalType) {
    return null;
  }

  return sportWidgetTypesMapping[finalType]
    ? {
      id: widgetId,
      type: sportWidgetTypesMapping[finalType],
      sportFamily: family,
      isVirtual: Boolean(widgetVirtual),
    }
    : null;
}
