import type { Ref } from 'vue';
import { toRef } from 'vue';

import { useSportlineEventDetailsStore } from 'web/src/modules/sportline/submodules/event-details/store';
import type {
  Sport,
  Region,
  League,
  SportElement,
} from 'web/src/modules/sportline/types';
import type { SportEventDetails, SportEventWidgetConfig } from 'web/src/modules/sportline/submodules/event-details/types';
import type { SportWidgetType } from 'web/src/modules/sportline/enums';
import type { HeadlineSlideName } from 'web/src/modules/sportline/submodules/event-details/enums';

interface UseSportlineEventDetailsHeadlineDataComposable {
  sportlineEvent: Ref<Maybe<SportEventDetails>>;
  sportElement: Ref<Maybe<SportElement>>;
  sport: Ref<Maybe<Sport>>;
  region: Ref<Maybe<Region>>;
  league: Ref<Maybe<League>>;
  doShowStreamWidget: Ref<boolean>;
  defaultSwiperSlideId: Ref<HeadlineSlideName>;
  widgetConfig: Ref<SportEventWidgetConfig>;
  liveWidgetType: Ref<Optional<SportWidgetType>>;
  reloadStreamInfo(): Promise<void>;
  onHeadlineSlideChanged(slideId: HeadlineSlideName): void;
}

export function useSportlineEventDetailsHeadlineData(): UseSportlineEventDetailsHeadlineDataComposable {
  const sportlineEventDetailsStore = useSportlineEventDetailsStore();

  const sportlineEvent = toRef(() => sportlineEventDetailsStore.sportEventDetails);
  const sportElement = toRef(() => sportlineEventDetailsStore.sportElement);
  const sport = toRef(() => sportlineEventDetailsStore.sport);
  const region = toRef(() => sportlineEventDetailsStore.region);
  const league = toRef(() => sportlineEventDetailsStore.league);
  const isStreamIndicatorAvailable = toRef(() => sportlineEventDetailsStore.isStreamIndicatorAvailable);
  const defaultSwiperSlideId = toRef(() => sportlineEventDetailsStore.defaultSwiperSlideId);
  const widgetConfig = toRef(() => sportlineEventDetailsStore.sportEventWidgetConfig);
  const liveWidgetType = toRef(() => sportlineEventDetailsStore.liveWidgetType);

  const {
    saveSwiperSlideId: onHeadlineSlideChanged,
    reloadStream: reloadStreamInfo,
  } = sportlineEventDetailsStore;

  return {
    sportlineEvent,
    sportElement,
    sport,
    region,
    league,
    doShowStreamWidget: isStreamIndicatorAvailable,
    defaultSwiperSlideId,
    widgetConfig,
    liveWidgetType,
    reloadStreamInfo,
    onHeadlineSlideChanged,
  };
}
