import { GqlBatchedClient, ApiConnectionError, ApiRequestAbortedError } from '@leon-hub/api';
import type { DataReturnType } from '@leon-hub/api/src/client/types';
import type { AbstractDocumentResponse, RequestOptions, ApiMethod } from '@leon-hub/api-sdk';
import type GqlQueryVariables from '@leon-hub/api/src/client/graphql/GqlQueryVariables';
import { logger } from '@leon-hub/logging';

export default class ApplicationGqlClient extends GqlBatchedClient {
  async requestGraphql<Node, ResponseData extends DataReturnType<AR>, AR extends AbstractDocumentResponse>(
    query: string,
    { options }: GqlQueryVariables,
    callback: (node: Node) => AR,
    requestOptions: RequestOptions,
    ref: ApiMethod,
  ): Promise<ResponseData> {
    try {
      return await super.requestGraphql(query, { options }, callback, requestOptions, ref);
    } catch (error) {
      if ((error instanceof ApiConnectionError || error instanceof ApiRequestAbortedError)
        && !error.silent && this.connectionErrorResolver) {
        try {
          logger.debug(`Connection error GraphQL: ${query}`);
          await this.connectionErrorResolver(error);
          return this.requestGraphql(query, { options }, callback, requestOptions, ref);
        } catch {}
      }

      throw error;
    }
  }
}
