import type { SportEventDetailsRouteLocationParameters } from 'web/src/modules/sportline/submodules/navigation/store/types';
import type {
  CoreSportlineEvent,
  SportlineEvent,
} from 'web/src/modules/sportline/types';
import type {
  ParseLiveStatusOptions,
  CoreSportEventResponse,
  BuildSportEventOptions,
} from 'web/src/modules/sportline/types/rest';
import {
  SportEventMatchPhase,
  SportlineType,
} from 'web/src/modules/sportline/enums';
import {
  buildSportlineEventNavigationParameters,
} from 'web/src/modules/sportline/utils/rest/navigation-parameters';

import { isLiveEventResponse, isOutrightEventResponse } from './isBetlineTypeEventResponse';
import {
  createSportEventLiveStatusInfo,
  createSportlineEventPeriodsInfo,
  createSportlineEventIncidentStatisticsInfo,
} from './createSportEventLiveStatusInfo';
import { createEventStatusBasedOnOpenState } from './createEventStatus';
import { createMarketGroups } from './createMarketGroups';
import { createCompetitorsPair } from './createCompetitorsPair';
import { createMarkets } from './createMarkets';
import { createSportWidget } from './createSportWidget';

function createOutrightNames(response: CoreSportEventResponse): {
  championshipName: Maybe<string>;
  outrightName: Maybe<string>;
} {
  const [championshipName, ...eventNameParts] = response.name.split(' - ');

  return {
    championshipName: championshipName ?? null,
    outrightName: eventNameParts.length
      ? eventNameParts.join(' - ')
      : null,
  };
}

function createCoreSportlineEvent(
  response: CoreSportEventResponse,
  options: BuildSportEventOptions & ParseLiveStatusOptions,
): CoreSportlineEvent {
  const { sportResponse } = options;
  const eventStatus = createEventStatusBasedOnOpenState(response);
  const eventId = String(response.id);
  const navigationParameters: SportEventDetailsRouteLocationParameters = buildSportlineEventNavigationParameters({
    ...options,
    sportEventResponse: response,
  });

  const isOutright = isOutrightEventResponse(response);
  const {
    championshipName,
    outrightName,
  } = isOutright
    ? createOutrightNames(response)
    : { championshipName: null, outrightName: null };

  return {
    id: eventId,
    name: response.name,
    status: eventStatus,
    competitors: createCompetitorsPair(response.competitors),
    markets: createMarkets(response.markets ?? [], { eventId, eventStatus }, options),
    moreCount: {
      markets: response.marketsCount,
      runners: response.runnersCount,
    },
    kickoff: response.kickoff,
    navigationParameters,
    lastUpdated: response.lastUpdated,
    marketGroups: createMarketGroups(sportResponse.marketGroups ?? []),
    matchPhase: SportEventMatchPhase.BreakInPlay, // will be updated by actual data further
    hasZeroMargin: options.canUseZeroMargin && (response.hasMarketWithZeroMargin ?? false),
    championshipName,
    outrightName,
    isOutright,
  };
}

export function createSportEvent(
  response: CoreSportEventResponse,
  options: BuildSportEventOptions & ParseLiveStatusOptions,
): SportlineEvent {
  const { liveStatus } = response;
  const { useFullProgress, sportResponse } = options;
  const isLive = isLiveEventResponse(response);
  const coreEventData = createCoreSportlineEvent(response, options);

  if (isLive && liveStatus) {
    const liveWidget = createSportWidget({ response, sportResponse }, options);
    return {
      ...coreEventData,
      type: SportlineType.Live,
      liveWidget,
      matchPhase: response.matchPhase ?? SportEventMatchPhase.InPlay,
      ...createSportEventLiveStatusInfo(liveStatus, { useFullProgress }),
      ...createSportlineEventPeriodsInfo(liveStatus),
      ...createSportlineEventIncidentStatisticsInfo(liveStatus),
    };
  }

  return {
    ...coreEventData,
    matchPhase: response.matchPhase ?? SportEventMatchPhase.PreGame,
    type: SportlineType.Prematch,
  };
}
