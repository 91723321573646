import type { Ref } from 'vue';
import { computed } from 'vue';

import type { VDatepickerEmits } from 'web/src/components/Datepicker/VDatepicker/types';
import type { DateInputCustomDate, DateInputRoundDateTo } from 'web/src/components/Input/enums';
import { applyLimitationsTo, getMaxDate, getMinDate } from 'web/src/components/Input/utils';

export interface UseVDatepickerProps {
  roundTo: Ref<Optional<DateInputRoundDateTo>>;
  min: Ref<Optional<number | DateInputCustomDate | string>>;
  max: Ref<Optional<number | DateInputCustomDate | string>>;
}

interface useVDatepickerComposable {
  minDate: Ref<Optional<Date>>;
  maxDate: Ref<Optional<Date>>;
  onChange(value: Date): void;
}

export function useVDatepicker(props: UseVDatepickerProps, emit: VDatepickerEmits): useVDatepickerComposable {
  const { min, max, roundTo } = props;

  const minDateTimestamp = computed<Optional<number>>(() => getMinDate(min.value));
  const minDate = computed<Optional<Date>>(() => (
    minDateTimestamp.value ? new Date(minDateTimestamp.value) : undefined
  ));
  const maxDateTimestamp = computed<Optional<number>>(() => getMaxDate(max.value));
  const maxDate = computed<Optional<Date>>(() => (
    maxDateTimestamp.value ? new Date(maxDateTimestamp.value) : undefined
  ));

  function onChange(value: Date): void {
    const timestamp = applyLimitationsTo(+value, minDateTimestamp.value, maxDateTimestamp.value, roundTo.value);
    emit('change', { timestamp });
  }

  return {
    minDate,
    maxDate,
    onChange,
  };
}
