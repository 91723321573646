import { InputEventType } from 'web/src/components/Input/enums';
import type { VInputInputEvent } from 'web/src/components/Input';
import { inputEventToFormDataEvent } from 'web/src/components/Form/utils';

import type { BaseFormWidgetEmits } from '../types';

interface UseBaseFormWidgetEvents {
  emitChange: (event: VInputInputEvent) => void;
  emitInput: (event: VInputInputEvent) => void;
  emitFocus: (event: VInputInputEvent) => void;
  emitBlur: (event: VInputInputEvent) => void;
}

const useBaseFormWidgetEvents = (emit: BaseFormWidgetEmits): UseBaseFormWidgetEvents => {
  const emitChange = (event: VInputInputEvent): void => {
    emit(InputEventType.CHANGE, inputEventToFormDataEvent(event));
  };

  const emitInput = (event: VInputInputEvent): void => {
    emit(InputEventType.INPUT, inputEventToFormDataEvent(event));
  };

  const emitFocus = (event: VInputInputEvent): void => {
    emit(InputEventType.FOCUS, inputEventToFormDataEvent(event));
  };

  const emitBlur = (event: VInputInputEvent): void => {
    emit(InputEventType.BLUR, inputEventToFormDataEvent(event));
  };

  return {
    emitChange,
    emitInput,
    emitFocus,
    emitBlur,
  };
};

export default useBaseFormWidgetEvents;
