enum QueryParameterName {
  UTM_SOURCE = 'utm_source',
  UTM_MEDIUM = 'utm_medium',
  UTM_CAMPAIGN = 'utm_campaign',
  UTM_TERM = 'utm_term',
  UTM_CONTENT = 'utm_content',
  RETENTION_ID = 'retentionId',
  APP_AD = 'ca',
  QTAG = 'qtag',
  REFTAG = 'reftag',
  PRERENDER = 'pr',
  PRERENDER_HOST = 'prhost',
  PRERENDER_IP = 'prip',
  BONUS_CODE = 'bcode',
  TEST_CASE = 'test-case',
  VUE_DEVTOOLS = 'vue-devtools',
  VUE_PERFORMANCE = 'vue-performance',
  VUE_SILENT = 'vue-silent',
  WM = 'wm',
  SUB_ID = 'subid',
  SUB_ID2 = 'subid2',
  SUB_ID3 = 'subid3',
  SUB_ID4 = 'subid4',
  SUB_ID5 = 'subid5',
  CLICK_ID = 'click_id',
  WM_ZONE = 'wmzone',
  BONUS_TAG = 'btag',
  IA_BONUS_TAG = 'iaBtag',
  LOCALE = 'locale',
  IA_LOCALE = 'iaLocale',
  PLATFORM = 'platform',
  FROM_PUSH = 'is-push',
  BANNER_ID = 'bid',
}

export default QueryParameterName;
