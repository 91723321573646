import { toRef } from 'vue';
import memoize from 'lodash/memoize';

import { normalizeError } from '@leon-hub/errors';
import { logger } from '@leon-hub/logging';

import type { UseShieldIntegrationComposable } from '../types';
import { getSessionID, getStorage } from '../utils';
import { useShieldIntegrationStore } from '../store';

async function syncStorage(): Promise<void> {
  const store = useShieldIntegrationStore();
  const { isEnabled, sdkUrl } = store;
  if (isEnabled && sdkUrl) {
    const record = await getStorage().get();
    if (record.exists) {
      const { value } = record;
      store.setSessionId(value.session_id);
    } else {
      try {
        const sessionId = await getSessionID(sdkUrl);
        store.setSessionId(sessionId);
      } catch (err) {
        logger.error(normalizeError(err, 'Unable to get shield session id'));
      }
    }
  }
}

// Requirement to sync external api once.
const syncStorageOnce = memoize(syncStorage);

const undefRef = toRef(() => undefined);

const useDefaultShieldIntegrationComposable: UseShieldIntegrationComposable = {
  sessionId: undefRef,
  load: () => Promise.resolve(undefined),
};

/**
 * Retrieves the session ID for shield integration.
 *
 * If shield integration is enabled (sdkUrl is defined), the following steps are taken:
 * 1. If the session ID is defined within the runtime, it is returned.
 * 2. If the session ID is stored within the cache, it is synced to the runtime and then returned.
 * 3. If no session ID is available, a new one will be fetched, synced and returned.
 */
export const useShieldIntegration = (): UseShieldIntegrationComposable => {
  if (process.env.VUE_APP_FEATURE_SHIELD_INTEGRATION_DISABLED) {
    // eslint-disable-next-line no-console
    console.warn('Shield integration is disabled');
    return useDefaultShieldIntegrationComposable;
  }
  const sessionId = toRef(() => useShieldIntegrationStore().sessionId);

  const startShieldIntegrationMainRoutine = async (): Promise<string | undefined> => {
    const store = useShieldIntegrationStore();
    if (!store.isEnabled) return;
    if (!store.sessionId) {
      await syncStorageOnce();
    }
  };

  let isShieldIntegrationCalled = false;
  async function load(): Promise<void> {
    if (!isShieldIntegrationCalled) {
      isShieldIntegrationCalled = true;
      await startShieldIntegrationMainRoutine();
    }
  }

  return {
    sessionId,
    load,
  };
};
