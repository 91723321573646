import {
  useRoute,
  useRouter,
} from 'vue-router';
import type {
  RouteLocation,
  RouteLocationRaw,
} from 'vue-router';

import type { MenuLevelClickPayload } from 'web/src/components/SidebarMenu/types';

import type { TheSidebarMenuEmits, TheSidebarMenuProps, TheSidebarMenuOptions } from '../types';

export interface TheSidebarMenuComposable {
  beforeLevelOneClick: (
    event: Event,
    fun: Function,
    location: RouteLocationRaw,
    payload?: MenuLevelClickPayload
  ) => void;
  doLevelClick: (
    event: Event,
    fun?: Function,
    payload?: MenuLevelClickPayload
  ) => void;
  emitBeforeNavigateClick: (
    event: Event,
    to: RouteLocationRaw,
    payload: MenuLevelClickPayload
  ) => void;
  emitMenuLevelClick: (
    event: Event,
    payload: MenuLevelClickPayload
  ) => void;
}

export default function useTheSidebarMenu(
  props: TheSidebarMenuProps,
  emits: TheSidebarMenuEmits,
  options: TheSidebarMenuOptions,
): TheSidebarMenuComposable {
  const router = useRouter();
  const route = useRoute();
  function beforeLevelOneClick(event: Event, fun: Function, location: RouteLocation, payload?: MenuLevelClickPayload) {
    const isModalOpening = route.name !== location.name;
    if (!isModalOpening) fun();
    if (payload) {
      emitMenuLevelClick(event, payload);
    }
  }

  function emitBeforeNavigateClick(
    event: Event,
    to: RouteLocationRaw,
    payload: MenuLevelClickPayload,
  ) {
    emits('before-navigate', { event, to, payload });
  }
  function emitMenuLevelClick(
    event: Event,
    payload: MenuLevelClickPayload,
  ) {
    if ((event.target as HTMLElement).closest(`.${options.defaultL1Class}`)
      || (event.target as HTMLElement).className === options.defaultL1InnerClass) {
      const element = props.menu.find(
        (item) => item.id === payload.id,
      );
      if (element?.location) {
        if (props.replaceRoutes) {
          router.replace(element.location);
        } else {
          router.push(element.location);
        }
      }
    }
    emits('level-click', { event, payload });
  }
  function doLevelClick(event: Event, fun?: Function, payload?: MenuLevelClickPayload): void {
    if (fun) {
      fun();
    }

    if (payload) {
      emitMenuLevelClick(event, payload);
    }
  }
  return {
    emitBeforeNavigateClick,
    emitMenuLevelClick,
    beforeLevelOneClick,
    doLevelClick,
  };
}
