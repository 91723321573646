import type {
  EventsChangesFetchOptions,
  GetSportEventsChangesResponse,
  GetSportEventsResponse,
  RegionEventsFetchOptions,
} from 'web/src/modules/sportline/types/rest';
import type { UseSportlineApiLoadEventsProps } from 'web/src/modules/sportline/services/composables/types';
import { BetlineType } from 'web/src/modules/sportline/enums/rest';

import { useSportlinePendingApi } from './useSportlinePendingApi';
import type { PendingGetEventsInput } from './useSportlinePendingApi';

interface UseSportlineApiLoadRegionEventsComposable {
  loadRegionEvents(
    options: RegionEventsFetchOptions & EventsChangesFetchOptions,
  ): Promise<Maybe<GetSportEventsResponse | GetSportEventsChangesResponse>>;
}

export function useSportlineApiLoadRegionEvents(
  props: UseSportlineApiLoadEventsProps,
): UseSportlineApiLoadRegionEventsComposable {
  const {
    tryRequestWithCTag,
    defaultRestApiFlags,
    isLiveOutrightsEnabled,
  } = props;

  const {
    loadEvents: loadRegionEvents,
  } = useSportlinePendingApi<RegionEventsFetchOptions>({
    tryRequestWithCTag,
    getEventsInput: ({ regionId, silent }): PendingGetEventsInput => ({
      betline: BetlineType.All,
      regionId,
      hideClosed: true,
      silent,
      flags: [...defaultRestApiFlags.value],
      LMCE: isLiveOutrightsEnabled.value,
    }),
    getPendingParams: ({ vTag, regionId }) => ({
      vTag,
      regionId,
      betlines: [BetlineType.All],
    }),
  });

  return {
    loadRegionEvents,
  };
}
