<script setup lang="ts">
import { CustomFilter } from 'web/src/modules/sportline/enums';
import { TabsType } from 'web/src/components/Tabs/VTabs/enums';
import { VLoaderMaskedDelayed } from 'web/src/components/Loader';
import SportNoResults from 'web/src/modules/sportline/components/NoEvents/SportNoResults.vue';
import FilterTransition from 'web/src/modules/sportline/components/FilterTransition/FilterTransition.vue';
import SportsListFilter from 'web/src/modules/sportline/submodules/sports/components/Filter/SportlineSportsFilter.vue';
import DateTimeFilter from 'web/src/modules/sportline/components/date-time-filter/DateTimeFilter.vue';
import { useKeepAlive } from 'web/src/modules/core/composables/keep-alive';

import { useSportsRoutePage } from './composables/useSportsRoutePage';
import { useSportsListFilterTransition } from './composables/useSportsListFilterTransition';
import { useSportsDateTimeFilter } from './composables/useSportsDateTimeFilter';
import { useSportsRoutePageMetaInfo } from './composables/useSportsRoutePageMetaInfo';
import SportsFavoritesTab from './default/SportsFavoritesTab.vue';
import SportsSportTab from './default/SportsSportTab.vue';
import SportsZeroMarginTab from './default/SportsZeroMarginTab.vue';

useSportsRoutePageMetaInfo();
const {
  listTransitionRef,
  activeTransitionId,
  lastShownSportFamily,
  isFilterTransitionFadeEffectEnabled,
  filterTransitionDuration,
  onChangeTransitionId,
} = useSportsListFilterTransition();
const {
  dateTimeFilterRef,
  isSportFiltersEnabled,
  datetimeActiveKickoffPeriod,
  datetimeActiveSportlineType,
  datetimeCustomFrom,
  datetimeCustomTo,
  onDateTimeFilterSelect,
  onDateTimeFilterSelectCustomRange,
} = useSportsDateTimeFilter();
const {
  listFilterRef,
  isZeroMarginEventsTabAvailable,
  isReadyToDisplayContent,
  doShowLoadingIndicator,
  hasFavoritesOptimistic,
  isPageEmpty,
  fullTabsList,
  selectedSportFilter,
  selectedSportFilterOptions,
  isChildRoute,
  reloadListForFilter,
  redirectFromTab,
  onChangeSelectedSportFilter,
} = useSportsRoutePage({
  activeTransitionId,
  lastShownSportFamily,
  listTransitionRef,
});

useKeepAlive();
</script>

<template>
  <div v-auto-id="'SportsRoutePage'" class="component-wrapper">
    <FilterTransition
      ref="listTransitionRef"
      key="sports-list-page-content"
      :class="$style['prematch']"
      :disabled="!isFilterTransitionFadeEffectEnabled"
      :fade-in-duration="filterTransitionDuration.fadeIn"
      :fade-out-duration="filterTransitionDuration.fadeOut"
      @change-id="onChangeTransitionId"
    >
      <template #filter="{ duration, afterEnter, afterLeave, beforeEnter, beforeLeave }">
        <SportsListFilter
          v-if="!isPageEmpty"
          ref="listFilterRef"
          :filter="selectedSportFilter"
          :options="selectedSportFilterOptions"
          :is-favorites-tab-enabled="hasFavoritesOptimistic"
          :is-zero-margin-tab-enabled="isZeroMarginEventsTabAvailable"
          @change-filter="onChangeSelectedSportFilter"
        >
          <template #afterItem="{ item }">
            <transition
              name="fade"
              :duration="duration"
              @before-leave="beforeLeave(item.key)"
              @after-leave="afterLeave"
              @before-enter="beforeEnter(item.key)"
              @after-enter="afterEnter"
            >
              <div
                v-if="item.isActive"
                :key="item.key"
              />
            </transition>
          </template>
        </SportsListFilter>

        <DateTimeFilter
          v-if="!isPageEmpty && isSportFiltersEnabled"
          ref="dateTimeFilterRef"
          :class="$style['prematch__date-filter']"
          :active-kickoff-period="datetimeActiveKickoffPeriod"
          :active-sportline-type="datetimeActiveSportlineType"
          :selected-range-from="datetimeCustomFrom"
          :selected-range-to="datetimeCustomTo"
          :tabs-type="TabsType.BORDERED"
          @select-filter="onDateTimeFilterSelect"
          @select-range="onDateTimeFilterSelectCustomRange"
        />
      </template>

      <template #content="{ isFadeOut, extraStyle }">
        <div
          v-show="!isFadeOut"
          :class="$style['prematch__list']"
          :style="extraStyle"
        >
          <VLoaderMaskedDelayed
            v-if="!isReadyToDisplayContent || doShowLoadingIndicator"
          />

          <template v-if="isReadyToDisplayContent">
            <div
              v-for="tab in fullTabsList"
              :key="tab.key"
              v-data-test="{ el: 'sports-tab', shown: true }"
              :class="{ 'group--shown': true }"
            >
              <SportsFavoritesTab
                v-if="tab.key === CustomFilter.Favorites"
                :is-active="tab.isActive"
                @reset-filter="reloadListForFilter(null)"
                @redirect-from-tab="redirectFromTab"
              />
              <SportsZeroMarginTab
                v-else-if="tab.key === CustomFilter.ZeroMargin"
                :is-active="tab.isActive"
                @reset-filter="reloadListForFilter(null)"
                @redirect-from-tab="redirectFromTab"
              />
              <SportsSportTab
                v-else
                :is-active="tab.isActive"
                :sport-id="tab.key"
                :is-child-route="isChildRoute"
                @reset-filter="reloadListForFilter(null)"
                @redirect-from-tab="redirectFromTab"
              />
            </div>
            <SportNoResults
              v-if="isPageEmpty"
              sportline-is-empty
            />
          </template>
        </div>
      </template>
    </FilterTransition>
  </div>
</template>

<style module lang="scss">
$filterDateHeight: 40px;

@include for-layout using ($isDesktop) {
  .prematch {
    width: 100%;
    min-height: 100%;

    &__date-filter {
      background-color: var(--Layer0);
    }

    &__list {
      position: relative;
      height: 100%;
      padding: if($isDesktop, 0, 0 8px);
    }
  }
}

// .group--shown used for LEONWEB-5553
</style>
