import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import usePaymentsSuggestedAmounts
  // eslint-disable-next-line max-len
  from 'web/src/modules/payments/form-widgets/FastSum/components/PaymentsFastSumInput/composables/usePaymentsSuggestedAmounts';
import type { FastMoneyChoiceOption } from 'web/src/components/FastMoneyChoice';
import suggestedAmountsToFastChoices
  // eslint-disable-next-line max-len
  from 'web/src/modules/payments/form-widgets/FastSum/components/PaymentsFastSumInput/utils/suggestedAmountsToFastChoices';
import { useFormatMoney } from 'web/src/modules/money/composables';
import usePaymentsMaxValueFastChoice
  // eslint-disable-next-line max-len
  from 'web/src/modules/payments/form-widgets/FastSum/components/PaymentsFastSumInput/composables/usePaymentsMaxValueFastChoice';
import type {
  PaymentsFastSumInputProps,
} from 'web/src/modules/payments/form-widgets/FastSum/components/PaymentsFastSumInput/types';

interface PaymentsFastChoicesComposable {
  fastChoices: ComputedRef<FastMoneyChoiceOption[] | null>;
}

export default function usePaymentsFastChoices(props: PaymentsFastSumInputProps): PaymentsFastChoicesComposable {
  const {
    filteredDefaultSuggestedAmounts,
    filteredCurrentPaymentSystemSuggestedAmounts,
  } = usePaymentsSuggestedAmounts(props);
  const formatMoney = useFormatMoney();
  const { maxValueFastChoice } = usePaymentsMaxValueFastChoice(props);

  const defaultFastChoices = computed(() => suggestedAmountsToFastChoices(
    filteredDefaultSuggestedAmounts.value,
    formatMoney,
  ));

  const currentPaymentSystemFastChoices = computed(() => suggestedAmountsToFastChoices(
    filteredCurrentPaymentSystemSuggestedAmounts.value,
    formatMoney,
  ));

  function removeMaxFastChoiceValue(choices: FastMoneyChoiceOption[]): FastMoneyChoiceOption[] {
    return choices.filter((item) => item.value !== maxValueFastChoice.value);
  }

  const fastChoices = computed(() => {
    if (currentPaymentSystemFastChoices.value) {
      return removeMaxFastChoiceValue(currentPaymentSystemFastChoices.value);
    }

    return defaultFastChoices.value ? removeMaxFastChoiceValue(defaultFastChoices.value) : null;
  });

  return {
    fastChoices,
  };
}
