import type { Ref } from 'vue';
import { ref } from 'vue';

export interface VSightObserverComposable {
  observeVisibilityOptions: { [key: string]: number | Function };
  isVisible: Ref<boolean>;
}

export default function useVSightObserver(): VSightObserverComposable {
  const isVisible = ref(false);

  function onVisibilityChange(value: boolean) {
    isVisible.value = value;
  }

  const observeVisibilityOptions = {
    callback: (value: boolean) => onVisibilityChange(value),
    throttle: 300,
  };

  return {
    observeVisibilityOptions,
    isVisible,
  };
}
