import { defineStore } from 'pinia';
import {
  computed,
  ref,
  toRef,
  watch,
} from 'vue';

import type { LiveChatDetailsResponse } from '@leon-hub/api-sdk';
import { getLiveChatSettings, helpButtonModeExtChat } from '@leon-hub/api-sdk';
import { logger } from '@leon-hub/logging';
import { hashString } from '@leon-hub/utils';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';
import { useLocalStorageManager } from '@leon-hub/local-storage';

import DefaultLiveChatObject from 'web/src/modules/live-chat/classes/DefaultLiveChatObject';
import { LiveChatMessageType } from 'web/src/modules/live-chat/services/api/enums';
import MediaUtils from 'web/src/utils/MediaUtils';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import type {
  LiveChatDataDocument,
  LiveChatSurveyFormData,
} from 'web/src/modules/live-chat/types';
import useCustomerDataStore from 'web/src/modules/customer/store/useCustomerDataStore';
import {
  LiveChatMode,
  LiveChatStatus,
} from 'web/src/modules/live-chat/enums';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import useLiveChatApi from 'web/src/modules/live-chat/services/api/useLiveChatApi';
import { useModuleTimeout } from 'web/src/modules/core/store/composables';
import useSupport from 'web/src/modules/support/composables/useSupport';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useWebSockets } from 'web/src/modules/core/composables';
import { useUnreadMessagesStore } from 'web/src/modules/intercom/store/useUnreadMessagesStore';

import type {
  LiveChatGetData,
  LiveChatObject,
  LiveChatOpenData,
  LiveChatUploadedFile,
} from '../services/api/types';

const STARTED_CHAT_STORAGE_KEY = 'startedChat';
const CHAT_MESSAGES_READ_LENGTH = 'chatMessagesReadLength';
const platform = process.env.VUE_APP_PLATFORM || 'MOBILE';

const useLiveChatStore = defineStore('live-chat', () => {
  const localStorageManager = useLocalStorageManager();
  const api = useLiveChatApi();
  const gqlApi = useGraphqlClient();
  const analytics = useAnalytics();
  const { isLoggedIn } = useIsLoggedIn();
  const customerDataStore = useCustomerDataStore();
  const unreadMessagesStore = useUnreadMessagesStore();
  const login = toRef(customerDataStore, 'login');
  const supportBlock = toRef(useSiteConfigStore(), 'supportBlock');
  const { isIntercomOptionsListFlow } = useSupport();
  const { subscribeAuthorized } = useWebSockets();
  const { timeout } = useModuleTimeout('live-chat');

  /** State */
  const startText = ref('');
  const loaded = ref(false);
  const enabled = ref(false);
  const muted = ref(false);
  const isOpened = ref(false);
  const code = ref('');
  const session = ref('');
  const endpoint = ref('');
  const mode = ref<LiveChatMode>(LiveChatMode.opened);
  const currentChat = ref<LiveChatObject>(new DefaultLiveChatObject());
  const lastChat = ref<LiveChatObject | null>(null);
  const userTypingDate = ref(Date.now());
  const tempFiles = ref<LiveChatUploadedFile[]>([]);
  const messagesSize = ref(0);
  const hidden = ref(true);
  const chatDataReceived = ref(false);
  const openOnCreated = ref(false);
  const loadingFilesCounter = ref(0);
  const chatWasStarted = ref(false);

  /** Getters */

  const staffTyping = computed(() => currentChat.value.typing && currentChat.value.status < LiveChatStatus.ENDED);

  const hasActiveChat = computed(() => currentChat.value.status === LiveChatStatus.IN_CHAT
      || currentChat.value.status === LiveChatStatus.INCOMING);

  const isChatActive = computed(() => (mode.value === LiveChatMode.started
      || messagesSize.value > 0
      || chatWasStarted.value));

  const newMessagesSize = computed(() => {
    if (messagesSize.value > 0) {
      return messagesSize.value;
    }

    return chatWasStarted.value ? 1 : 0;
  });

  const isOpenSyncStateEnabled = computed(() => !isOpened.value && isLoggedIn.value && !isChatActive.value && supportBlock.value?.helpButtonMode !== helpButtonModeExtChat);

  /** Mutations */

  function updateChatSettings(input: LiveChatDataDocument): void {
    endpoint.value = input.endpoint;
    code.value = input.code;
    session.value = input.session;

    if (!input.endpoint) {
      enabled.value = false;
    }
  }

  function setEnabled(isEnabled: boolean): void {
    enabled.value = isEnabled;
  }

  function setCurrentChat(chat: LiveChatObject): void {
    currentChat.value = chat;
  }

  function setQuestion(question: string): void {
    currentChat.value.question = question;
  }

  function setLoaded(isLoaded: boolean): void {
    loaded.value = isLoaded;
  }

  function setMode(liveChatMode: LiveChatMode): void {
    mode.value = liveChatMode;
  }

  function setStatus(status: number): void {
    currentChat.value.status = status;
  }

  function setLastChat(chat: LiveChatObject | null | undefined): void {
    if (chat) {
      lastChat.value = chat;

      if (chat.status === LiveChatStatus.ENDED) {
        checkNewMessages(chat);
      }
    }
  }

  function setMuted(isMuted: boolean): void {
    muted.value = isMuted;
  }

  function setUserTypingDate(date: number): void {
    userTypingDate.value = date;
  }

  function addUploadedFiles(files: LiveChatUploadedFile[]): void {
    for (const file of files) {
      tempFiles.value.push(file);
    }
  }

  function removeFile(id: number): void {
    const index = tempFiles.value.findIndex((item) => item.id === id);

    if (index > -1) {
      tempFiles.value.splice(index, 1);
    }
  }

  function clearFiles(): void {
    tempFiles.value = [];
  }

  function setReadMessagesStorage(response: LiveChatObject): void {
    if (response.status !== LiveChatStatus.ENDED) {
      localStorageManager.setItem(CHAT_MESSAGES_READ_LENGTH, String(response.conversation.length));
    } else {
      localStorageManager.removeItem(CHAT_MESSAGES_READ_LENGTH);
    }
  }

  function setHidden(isHidden: boolean): void {
    hidden.value = isHidden;

    if (!isHidden && lastChat.value) {
      setReadMessagesStorage(lastChat.value);
    }
  }

  function setNewMessagesSize(size: number): void {
    messagesSize.value = size;
  }

  function clearNewMessagesSize(): void {
    messagesSize.value = 0;
  }

  function setChatDataReceived(value: boolean): void {
    chatDataReceived.value = value;
  }

  function setOpenOnCreated(value: boolean): void {
    openOnCreated.value = value;
  }

  function resetState(): void {
    session.value = '';
    mode.value = LiveChatMode.opened;
    currentChat.value = new DefaultLiveChatObject();
    tempFiles.value = [];
    messagesSize.value = 0;
    chatWasStarted.value = false;
  }

  function setLoadingFilesCounter(value: number): void {
    loadingFilesCounter.value = value;
  }

  function setChatWasStarted(value: boolean): void {
    chatWasStarted.value = value;
  }

  function setIsOpened(value: boolean): void {
    isOpened.value = value;
  }

  /** Actions */

  async function loadSettings(silent = false): Promise<void> {
    if (isLoggedIn.value) {
      await Promise.all([
        fetchSettings(silent),
      ]);
    }
  }

  async function fetchSettings(silent = false): Promise<void> {
    if (!silent) {
      setLoaded(false);
    }
    let response: null | LiveChatDetailsResponse = null;
    try {
      response = (await getLiveChatSettings(
        gqlApi, (node) => node.queries.liveChat.getDetails, { options: {} }, {
          silent,
        },
      ));
    } catch (err) {
      logger.error('Unable to get live chat settings:', err);
      setEnabled(false);
      return;
    }

    if (response) {
      setEnabled(response.chatEnabled || false);
      updateChatSettings(response.chatData);
    }

    if (!silent) {
      setLoaded(true);
    }
  }

  async function openChat(silent?: boolean): Promise<void> {
    if (isIntercomOptionsListFlow.value) {
      return;
    }

    setLoaded(false);
    await loadSettings(true);
    const isPreopened = mode.value === LiveChatMode.preopened;
    try {
      setMode(isPreopened ? LiveChatMode.busy : LiveChatMode.preopened);
      startText.value = '';
      if (endpoint.value) {
        const response = await api.openLiveChat({
          code: code.value,
          session: session.value,
          login: login.value,
          platform,
          endpoint: endpoint.value,
          silent,
        });
        checkOpenedResponse(response, isPreopened);
      }
    } catch (err) {
      logger.error('Unable to open chat', err);
      setMode(LiveChatMode.busy);
    } finally {
      setLoaded(true);
    }
  }

  function checkOpenedResponse(response: LiveChatOpenData, isPreopened: boolean): void {
    if (response.result === 'OK') {
      if (isPreopened || !response.lastChat || response.chatActive) {
        if (!response.supportActive) {
          setMode(LiveChatMode.busy);
        } else if (response.chatActive) {
          setMode(LiveChatMode.started);
        } else {
          setMode(LiveChatMode.opened);
        }
      }

      setLastChat(response.lastChat);

      if (response.chatActive) {
        setStatus(response.chatStatus);
      }

      startText.value = response.startText || '';
    } else {
      setMode(LiveChatMode.busy);
    }
  }

  async function startChat({ question, betId }: { question: string; betId?: string }): Promise<boolean> {
    yandexMetrikaStartOnlineChat();
    if (endpoint.value) {
      const response = await api.startLiveChat({
        code: code.value,
        session: session.value,
        login: login.value,
        question,
        platform,
        endpoint: endpoint.value,
        version: 'WEB2',
        betId,
      });

      if (response.result === 'OK') {
        clearFiles();
        setStatus(LiveChatStatus.INCOMING);
        setCurrentChat(new DefaultLiveChatObject(LiveChatStatus.INCOMING));
        setQuestion(response.question);
        setMode(LiveChatMode.started);
        setChatDataReceived(false);
        localStorageManager.setItem(STARTED_CHAT_STORAGE_KEY, hashString(login.value));
        return true;
      }

      if (response.errorStatus === 'LIVECHAT_BUSY') {
        setMode(LiveChatMode.busy);
      }
    }

    return false;
  }

  async function handleGetChatResponse(response: LiveChatGetData): Promise<void> {
    checkNewMessages(response);
    await playSound(response);
    if (response.conversation.length > currentChat.value.conversation.length) {
      // eslint-disable-next-line no-param-reassign
      response.typing = false;
    }
    setCurrentChat(response);
    setChatDataReceived(true);

    if (response.status === LiveChatStatus.INCOMING || response.status === LiveChatStatus.IN_CHAT) {
      setMode(LiveChatMode.started);
    }

    if (response.status === LiveChatStatus.ENDED) {
      setLastChat(response);
      setMode(LiveChatMode.preopened);
    }
  }

  async function getChat(): Promise<boolean> {
    if (endpoint.value) {
      const typing = userTypingDate.value >= Date.now() - 1000;
      const response = await api.getLiveChat({
        code: code.value,
        session: session.value,
        login: login.value,
        typing,
        endpoint: endpoint.value,
        modalOpened: !hidden.value,
        silent: true,
      });

      if (response.result === 'OK') {
        await handleGetChatResponse(response);

        return true;
      }
    }

    return false;
  }

  function yandexMetrikaStartOnlineChat(): void {
    analytics.push(AnalyticsEvent.Z_OPEN_SUPPORT_PAGE, {
      supportDetails: {
        onlineChat: {
          startOnlineChat: true,
        },
      },
    });
  }

  function checkNewMessages(response: LiveChatObject) {
    let newMessagesLength = 0;
    const readLength = Number(localStorageManager.getItem(CHAT_MESSAGES_READ_LENGTH)) || 0;

    if (currentChat.value.status !== LiveChatStatus.ENDED) {
      newMessagesLength = response.conversation.length - readLength;
    }

    if (!hidden.value) {
      setReadMessagesStorage(response);
    } else {
      if ((currentChat.value.status < LiveChatStatus.ENDED || readLength > 0) && response.status >= LiveChatStatus.ENDED) {
        newMessagesLength += 1;
      }

      if (currentChat.value.isCreatedByStaff) {
        const staffMessagesCount = response.conversation.filter((msg) => msg.messageType === LiveChatMessageType.staff).length;

        if (staffMessagesCount <= 1) {
          newMessagesLength = response.conversation.length - readLength;
        }
      }

      setNewMessagesSize(newMessagesLength);
    }

    if (!hidden.value && response.status === LiveChatStatus.ENDED) {
      localStorageManager.removeItem(STARTED_CHAT_STORAGE_KEY);
    }
  }

  async function playSound(response: LiveChatObject) {
    let play = false;
    for (const chat of response.conversation) {
      let found = false;
      for (const currChat of currentChat.value.conversation) {
        if (chat.uid === currChat.uid) {
          found = true;
        }
      }

      if (!found && chat.messageType !== LiveChatMessageType.client) {
        play = true;
      }
    }

    if (play && !muted.value) {
      await MediaUtils.playSound('message.mp3');
    }
  }

  async function closeChat(): Promise<boolean> {
    if (endpoint.value) {
      const response = await api.closeLiveChat({
        code: code.value,
        session: session.value,
        login: login.value,
        endpoint: endpoint.value,
      });

      if (response.result === 'OK') {
        setStatus(LiveChatStatus.ENDED);
        clearFiles();
        return true;
      }
    }

    return false;
  }

  async function message(msg: string): Promise<boolean> {
    if (endpoint.value) {
      const files: number[] = [];
      for (const file of tempFiles.value) {
        files.push(file.id);
      }
      const response = await api.sendLiveChatMessage({
        code: code.value,
        session: session.value,
        login: login.value,
        message: msg,
        files,
        endpoint: endpoint.value,
      });

      if (response.result === 'OK') {
        for (const id of files) {
          removeFile(id);
        }

        await handleGetChatResponse(response);
        return true;
      }
    }

    return false;
  }

  async function doAction(actionPayload: string): Promise<boolean> {
    const currentActions = currentChat.value.actions;
    currentChat.value.actions = [];

    try {
      const response = await api.sendLiveChatAction({
        code: code.value,
        session: session.value,
        login: login.value,
        bot_payload: actionPayload,
        endpoint: endpoint.value,
      });

      if (response.result === 'OK') {
        await handleGetChatResponse(response);
        return true;
      }
    } catch (error) {
      currentChat.value.actions = currentActions;

      throw error;
    }

    return false;
  }

  async function surveySubmit(data: LiveChatSurveyFormData): Promise<boolean> {
    if (endpoint.value) {
      const response = await api.surveyLiveChat({
        code: code.value,
        session: session.value,
        login: login.value,
        rating: data.rating,
        comment: data.comment,
        endpoint: endpoint.value,
      });

      if (response.result === 'OK') {
        await handleGetChatResponse(response);
        return true;
      }
    }

    return false;
  }

  async function upload(files: File[]): Promise<void> {
    if (endpoint.value) {
      const counter = files.length;
      setLoadingFilesCounter(loadingFilesCounter.value + counter);

      try {
        const response = await api.uploadLiveChat({
          code: code.value,
          session: session.value,
          login: login.value,
          files,
          endpoint: endpoint.value,
        });

        if (response.result === 'OK') {
          addUploadedFiles(response.files);
        }
      } catch {
        //
      }

      setLoadingFilesCounter(loadingFilesCounter.value - counter);
    }
  }

  async function removeFileCallback(id: number): Promise<boolean> {
    if (endpoint.value) {
      const response = await api.removeFileLiveChat({
        code: code.value,
        session: session.value,
        login: login.value,
        id,
        endpoint: endpoint.value,
      });

      if (response.result === 'OK') {
        removeFile(id);
        return true;
      }
    }

    return false;
  }

  function setHiddenCallback(isHidden: boolean): void {
    setHidden(isHidden);
    if (!isHidden) {
      clearNewMessagesSize();
      setChatWasStarted(false);
      if (!hasActiveChat.value) {
        localStorageManager.removeItem(STARTED_CHAT_STORAGE_KEY);
      }
    }
  }

  function subscribeOnChatCreate(): void {
    subscribeAuthorized({
      method: 'onTicketChatEvent',
      onMessage: (data) => {
        if (data) {
          void openChat(true);
        }
      },
      isEnabled: isChatActive,
      polling: {
        timeout,
        callback: async () => {
          if (isOpenSyncStateEnabled.value) {
            await openChat(true);
          }
        },
      },
    });
  }

  subscribeOnChatCreate();

  watch(login, async (newValue) => {
    if (!newValue) {
      resetState();
    } else {
      await openChat(true);
    }
  }, {
    immediate: true,
  });

  watch(newMessagesSize, (newSize) => {
    unreadMessagesStore.setUnreadMessages(newSize);
  });

  return {
    /** State */
    loaded,
    enabled,
    muted,
    isOpened,
    code,
    session,
    endpoint,
    mode,
    currentChat,
    lastChat,
    userTypingDate,
    tempFiles,
    messagesSize,
    hidden,
    chatDataReceived,
    openOnCreated,
    loadingFilesCounter,
    chatWasStarted,
    /** Getters */
    newMessagesSize,
    staffTyping,
    isChatActive,
    /** Mutations */
    updateChatSettings,
    setEnabled,
    setCurrentChat,
    setQuestion,
    setLoaded,
    setMode,
    setStatus,
    addUploadedFiles,
    removeFile,
    clearFiles,
    setHidden,
    setNewMessagesSize,
    clearNewMessagesSize,
    setChatDataReceived,
    resetState,
    setLoadingFilesCounter,
    setChatWasStarted,
    startText,
    /** Actions */
    loadSettings,
    fetchSettings,
    setIsOpened,
    setUserTypingDate,
    setOpenOnCreated,
    setMuted,
    openChat,
    startChat,
    getChat,
    closeChat,
    message,
    surveySubmit,
    upload,
    removeFileCallback,
    setHiddenCallback,
    doAction,
  };
});

export default useLiveChatStore;
