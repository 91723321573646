export function concatUrlPathNames(...pathNames: (string | undefined | null)[]): string {
  const normalizedPathNames = pathNames
    .map((pathname, index) => (
      index
        ? (pathname ?? '').replace(/\/$/g, '').replace(/^\//g, '')
        : (pathname ?? '').replace(/\/$/g, '')
    ))
    .filter((pathname) => !!pathname);

  return normalizedPathNames.join('/');
}
