export enum EsportIconName {
  ESPORTLINE_LIVE = 'esport-live',
  ESPORTLINE_PREMATCH = 'esport-prematch',
  ESPORTLINE_BOOST = 'esport-boost',
  ESPORT_CALL_OF_DUTY = 'esport-cod',
  ESPORT_CALL_OF_DUTY_MOBILE = 'esport-codm',
  ESPORT_CS2_DUELS = 'esport-cs2-duels',
  ESPORT_CSGO = 'esport-csgo',
  ESPORT_DEADLOCK = 'esport-deadlock',
  ESPORT_DOTA = 'esport-dota',
  ESPORT_FIFA = 'esport-fifa',
  ESPORT_FORTNITE = 'esport-fortnite',
  ESPORT_FREE_FIRE = 'esport-free-fire',
  ESPORT_GEOGUESSR = 'esport-geoguessr',
  ESPORT_HEARTH_STONE = 'esport-hearth-stone',
  ESPORT_HEROES_OF_THE_STORM = 'esport-heroes-of-the-storm',
  ESPORT_KING_OF_GLORY = 'esport-kog',
  ESPORT_LEAGUE_OF_LEGENDS = 'esport-lol',
  ESPORT_OVERWATCH = 'esport-overwatch',
  ESPORT_RAINBOW_SIX = 'esport-rainbow-six',
  ESPORT_STARCRAFT = 'esport-star-craft',
  ESPORT_STARCRAFT_2 = 'esport-sc2',
  ESPORT_VALORANT = 'esport-valorant',
  ESPORT_WARCRAFT_3 = 'esport-wc3',
  ESPORT_WORLD_OF_TANKS = 'esport-wot',
  ESPORT_ROCKET_LEAGUE = 'esport-rocket-league',
  ESPORT_LOL_WILD_RIFT = 'esport-lol-wild-rift',
  ESPORT_MOBILE_LEGENDS_BANG_BANG = 'esport-mobile-legends-bang-bang',
  ESPORT_OSU = 'esport-osu',
  ESPORT_CROSSFIRE = 'esport-crossfire',
  ESPORT_BRAWL_STARS = 'esport-brawl-stars',
  ESPORT_ARENA_OF_VALOR = 'esport-arena-of-valor',
  ESPORT_HALO = 'esport-halo',
  ESPORT_AGE_OF_EMPIRES_2 = 'esport-age-of-empires-2',
  ESPORT_AGE_OF_EMPIRES_4 = 'esport-age-of-empires-4',
  ESPORT_APEX = 'esport-apex-legends',
  ESPORT_PUBG_MOBILE = 'esport-pubg-mobile',
  ESPORT_PUBG = 'esport-pubg',
  ESPORT_STREET_FIGHTER = 'esport-street-fighter',
  ESPORT_TEKKEN = 'esport-tekken',
}
