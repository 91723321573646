import type { IconNameType } from '@leon-hub/icons';
import { Tag } from '@leon-hub/tags';

import type { VIconProps } from '@components/v-icon';

import type { IndicatorProperty } from 'web/src/components/_shared';

export interface VAccordionProperties {
  tag?: Tag;
  panels?: boolean;
  indicators?: IndicatorProperty;
  independentItems?: boolean;
  controlled?: boolean;
}

export interface VAccordionItemProperties {
  id: string;
  open?: boolean;
  tag?: Tag;
  icon?: boolean | IconNameType | VIconProps;
  title?: string;
  indicator?: IndicatorProperty;
  styleType?: AccordionItemType;
  noBackground?: boolean;
  isCenteredTitle?: boolean;
}

export interface VAccordionSharedState {
  openItem: string | null;
  panels?: boolean;
  indicators: IndicatorProperty;
  independentItems: boolean;
  controlled: boolean;
  parentTag: Tag;
  hasIcon: boolean;
}

export const validAccordionTags: Tag[] = [
  Tag.DIV,
  Tag.SECTION,
  Tag.OL,
  Tag.UL,
  Tag.DL,
];

export const validAccordionItemTags: Tag[] = [
  Tag.DIV,
  Tag.ARTICLE,
  Tag.LI,
];

export enum AccordionItemType {
  BASIC = 'basic',
  HOW_TO = 'how-to',
}
