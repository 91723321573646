import type { Ref } from 'vue';
import {
  computed,
  ref,
  toRef,
} from 'vue';

import { Timer } from '@leon-hub/utils';

import { useSiteConfigStore } from 'web/src/modules/core/store';

import type { VLoaderDelayedProps } from '../types';

export interface VLoaderDelayedComposable {
  loaderIsVisible: Ref<boolean>;
  delayTime: Ref<number>;
  onAppear: () => void;
  onDisappear: () => void;
}

export default function useVLoaderDelayed(props: VLoaderDelayedProps): VLoaderDelayedComposable {
  const appVloaderDelayMs = toRef(useSiteConfigStore(), 'appVloaderDelayMs');
  const loaderIsVisible = ref(false);

  let timerId = 0;

  const delayTime = computed(() => props.delay || appVloaderDelayMs.value || 0);

  const onAppear = (): void => {
    timerId = Timer.setTimeout(() => {
      loaderIsVisible.value = true;
    }, delayTime.value);
  };

  const onDisappear = (): void => {
    if (timerId) {
      Timer.clearTimeout(timerId);
      timerId = 0;
    }
  };

  return {
    delayTime,
    loaderIsVisible,
    onAppear,
    onDisappear,
  };
}
