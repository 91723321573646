import { InputEventType } from 'web/src/components/Input/enums';

import type { BaseFormWidgetEmits } from '../../types';

interface UseFormSwitchEvents {
  emitChange: (value: boolean) => void;
  emitInput: (value: boolean) => void;
  emitFocus: (value: boolean) => void;
  emitBlur: (value: boolean) => void;
}

const useFormSwitchEvents = (emit: BaseFormWidgetEmits, name: string): UseFormSwitchEvents => {
  const emitInput = (value: boolean): void => {
    emit(InputEventType.INPUT, { value, name });
  };

  const emitChange = (value: boolean): void => {
    emit(InputEventType.CHANGE, { value, name });
  };

  const emitFocus = (value: boolean): void => {
    emit(InputEventType.FOCUS, { value, name });
  };

  const emitBlur = (value: boolean): void => {
    emit(InputEventType.BLUR, { value, name });
  };

  return {
    emitChange,
    emitInput,
    emitFocus,
    emitBlur,
  };
};

export default useFormSwitchEvents;
