<script lang="ts" setup>
import { ButtonKind, ButtonHeight } from 'web/src/components/Button/VButton/enums';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import CustomerHistoryListItemLayout
  // eslint-disable-next-line max-len
  from 'web/src/modules/profile/submodules/customer-history/components/CustomerHistoryListItemLayout/CustomerHistoryListItemLayout.vue';
import { TestKeysEnum } from 'web/src/modules/profile/submodules/customer-history/types';

import type { CustomerHistoryListItemPaymentEmits, CustomerHistoryListItemPaymentProps } from './types';
import { useCustomerHistoryListItemPayment } from './composables';

const props = defineProps<CustomerHistoryListItemPaymentProps>();
const emit = defineEmits<CustomerHistoryListItemPaymentEmits>();

const {
  dateProperties,
  isStatus,
  isMoneyPositive,
  money,
  isWithdrawCancellable,
  cancelWithdrawal,
} = useCustomerHistoryListItemPayment(props, emit);
</script>

<template>
  <CustomerHistoryListItemLayout v-auto-id="'CustomerHistoryListItemPayment'" @click="emit('item-click', item)">
    <template #metaLeft>
      <span
        :class="$style['payment-list-item__type-name']"
        :data-test-key="TestKeysEnum.TYPE_NAME"
      >{{ item.txTypeKind }}</span>
    </template>
    <template #metaRight>
      <span
        :class="$style['payment-list-item__date']"
        :data-test-key="TestKeysEnum.DATE"
      >{{ dateProperties }}</span>
    </template>
    <template #descriptionLeft>
      <div :class="$style['payment-list-item__type-description']">
        <span :data-test-key="TestKeysEnum.TYPE_DESCRIPTION">{{ item.txTypeName }}</span>
      </div>
    </template>
    <template #descriptionRight>
      <div v-if="isStatus">
        <span
          :class="$style['payment-list-item__status']"
          :data-test-key="TestKeysEnum.STATUS"
        >{{ item.statusName }}</span>
      </div>
    </template>
    <template #footerLeft>
      <span
        :class="$style['payment-list-item__id']"
        :data-test-key="TestKeysEnum.ID"
      >{{ `(ID: ${item.objectId})` }}</span>
    </template>
    <template #footerRight>
      <div :class="$style['payment-list-item__withdrawal-block']">
        <span
          :class="{
            [$style['payment-list-item__money']]: true,
            [$style['payment-list-item__money--positive']]: isMoneyPositive,
          }"
          :data-test-key="TestKeysEnum.MONEY"
        >
          {{ money }}
        </span>
        <VButton
          v-if="isWithdrawCancellable"
          :class="$style['payment-list-item__withdrawal-button']"
          :kind="ButtonKind.CANCEL"
          :height="ButtonHeight.TINY_EXTRA"
          :label="$t('WEB2_CUSTOMER_HISTORY_CANCEL')"
          :data-test-key="TestKeysEnum.WITHDRAW_CANCELLABLE"
          @click.stop="cancelWithdrawal"
        />
      </div>
    </template>
  </CustomerHistoryListItemLayout>
</template>

<style lang="scss" module>
.payment-list-item {
  &__type-name {
    @include medium\12\16;

    display: block;
    width: 100%;
    height: 16px;
    overflow: hidden;
    color: var(--TextSecondary);
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__date {
    @include regular\12\16;

    color: var(--TextSecondary);
  }

  &__type-description {
    @include medium\14\20\025;

    color: var(--TextDefault);
  }

  &__status {
    @include regular\14\20\025;

    color: var(--TextPrimary);
  }

  &__id {
    @include regular\13\16;

    color: var(--TextSecondary);
  }

  &__withdrawal {
    &-block {
      display: flex;
      align-items: center;
    }

    &-button {
      position: relative;
      margin-left: 8px;

      // extend click area
      &:after {
        position: absolute;
        top: -12px;
        right: -12px;
        bottom: -12px;
        left: -12px;
        display: block;
        content: '';
      }
    }
  }

  &__money {
    @include medium\14\16\025;

    color: var(--TextPrimary);

    &--positive {
      color: var(--BrandDefault);
    }
  }
}
</style>
