import { hasScheme } from './hasScheme';

export function normalizeUrl(url: string): string {
  if (hasScheme(url)) {
    return url;
  }
  return [
    process.env.BASE_URL,
    url,
  ].join('/').replaceAll(/\/+/g, '/');
}
