enum Steps {
  Days = 0,
  Hours = 1,
  Minutes = 2,
  Seconds = 3,
}

/** @deprecated @TODO use useTimeLeft instead */
export default class TimeLeft {
  static days(value: number, now?: number): TimeLeft {
    return new TimeLeft(value, now, Steps.Days);
  }

  static hours(value: number, now?: number): TimeLeft {
    return new TimeLeft(value, now, Steps.Hours);
  }

  static minutes(value: number, now?: number): TimeLeft {
    return new TimeLeft(value, now, Steps.Minutes);
  }

  static seconds(value: number, now?: number): TimeLeft {
    return new TimeLeft(value, now, Steps.Seconds);
  }

  constructor(
    private value: number,
    private now: number = Date.now(),
    private step: Steps = Steps.Seconds,
  ) {}

  private get distance(): undefined | number {
    if (!this.now) {
      return undefined;
    }

    return this.value > this.now
      ? this.value - this.now
      : 0;
  }

  /**
   * Value without formatting
   * @return {number | undefined}
   */
  get timeLeft(): number {
    const { distance } = this;
    if (distance === undefined) return 0;

    switch (this.step) {
      case Steps.Days: return Math.floor(distance / (1000 * 60 * 60 * 24));

      case Steps.Hours: return Math.floor((distance / (1000 * 60 * 60)) % 24);

      case Steps.Minutes: return Math.floor((distance / (1000 * 60)) % 60);

      case Steps.Seconds: return Math.floor((distance / (1000)) % 60);

      default: return 0;
    }
  }

  /**
   * To pretty two-digit style
   * @return {string}
   */
  toPretty(): string {
    const { timeLeft } = this;

    if (!timeLeft) {
      return '00';
    }

    const max = 99;

    if (max && timeLeft > max) {
      return `${max}`;
    }

    return timeLeft < 10 ? `0${timeLeft}` : `${timeLeft}`;
  }

  /**
   * used to convert in templates
   * @return {string}
   */
  toString(): string {
    return this.toPretty();
  }

  /**
   * used to convert to number by +value
   * @return {number | undefined}
   */
  valueOf(): number {
    return this.timeLeft;
  }
}
