import { isString } from '@leon-hub/guards';

import type { Sport, Region, League } from 'web/src/modules/sportline/types';

export function isSportIdEquals(sport?: string | Sport, id?: string): boolean {
  if (!sport || !id) {
    return false;
  }

  if (isString(sport)) {
    return sport === id;
  }

  return id === sport.id;
}

export function isRegionIdEquals(region?: Maybe<string | Region>, id?: Maybe<string>): boolean {
  if (!region || !id) {
    return false;
  }

  if (isString(region)) {
    return region === id;
  }

  return id === region.id || id === region.name;
}

export function isLeagueIdEquals(league?: Maybe<string | League>, id?: Maybe<string>): boolean {
  if (!league || !id) {
    return false;
  }

  if (isString(league)) {
    return league === id;
  }

  return id === league.id;
}
