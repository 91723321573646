<script lang="ts" setup>
import ModalPortal from 'web/src/components/Modal/ModalPortal/ModalPortal.vue';
import ConfirmModal from 'web/src/modules/dialogs/views/ConfirmModal/ConfirmModal.vue';
import { CurrentModal } from 'web/src/modules/bonuses/components/WithdrawalContainer/enums';

import { useWithdrawalContainer } from './composables';
import type { WithdrawalContainerProps, WithdrawalContainerEmits } from './types';

const props = withDefaults(defineProps<WithdrawalContainerProps>(), {
  money: '',
});

const emit = defineEmits<WithdrawalContainerEmits>();

const {
  modalSelector,
  currentModal,
  modalConfirmProperties,
  modalBonusProperties,
  onClickModal,
  onCloseModal,
  withdrawBonus,
} = useWithdrawalContainer(props, emit);
</script>

<template>
  <div v-auto-id="'WithdrawalContainer'">
    <slot :withdraw-bonus="withdrawBonus" />
    <ModalPortal
      :selector="modalSelector"
    >
      <ConfirmModal
        v-if="currentModal === CurrentModal.CONFIRM"
        :modal="modalConfirmProperties"
        :is-alert="!!'1'"
        @close="currentModal = CurrentModal.NONE"
        @button-click="onClickModal"
      />
      <ConfirmModal
        v-if="currentModal === CurrentModal.BONUS"
        :modal="modalBonusProperties"
        :is-alert="!!'1'"
        @close="onCloseModal"
        @button-click="onClickModal"
      >
        <div :class="$style['bonus-modal__money']">
          {{ money }}
        </div>
      </ConfirmModal>
    </ModalPortal>
  </div>
</template>

<style lang="scss" module>
.bonus-modal {
  &__money {
    @include withdrawalContainerMoneyFont;

    color: $withdrawalContainerMoneyColor;
    text-transform: uppercase;
  }
}
</style>
