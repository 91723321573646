import { addPageLifeCycleBreadcrumb } from './addPageLifeCycleBreadcrumb';

export const trackWebApiEvents = (): void => {
  window.addEventListener('pagehide', (event) => {
    addPageLifeCycleBreadcrumb('pagehide', { persisted: event.persisted });
  });
  window.addEventListener('pageshow', (event) => {
    addPageLifeCycleBreadcrumb('pageshow', { persisted: event.persisted });
  });
  window.addEventListener('visibilitychange', () => {
    addPageLifeCycleBreadcrumb('visibilitychange', { persisted: document.visibilityState });
  });
  window.addEventListener('beforeunload', () => {
    addPageLifeCycleBreadcrumb('beforeunload');
  });
  window.addEventListener('onbeforeunload', () => {
    addPageLifeCycleBreadcrumb('onbeforeunload');
  });
  window.addEventListener('online', () => {
    addPageLifeCycleBreadcrumb('online');
  });
  window.addEventListener('offline', () => {
    addPageLifeCycleBreadcrumb('offline');
  });
};
