import type {
  CasinoLoyaltyProgramProgressProperties,
} from 'web/src/modules/casino-loyalty-program/store/types';
import {
  CasinoLoyaltyProgramLevelStatus,
} from 'web/src/modules/casino-loyalty-program/store/types';

export default function getProgressProps(
  progress: number | undefined,
  imageUrl: string | undefined,
  status: string | undefined,
): CasinoLoyaltyProgramProgressProperties {
  return {
    progress,
    imageSrc: imageUrl,
    isInactive: status !== CasinoLoyaltyProgramLevelStatus.SUCCESS,
  };
}
