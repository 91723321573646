import type {
  FormUiSchemaField,
  FormWrapperWidgetProperties,
  FormWidgetExtraProperties,
  FormDataValue,
  FormValidatorErrorValue,
} from '../../../../types';

interface Payload {
  name: string;
  fieldUiSchema: FormUiSchemaField;
  error: FormValidatorErrorValue;
  extraProperties: FormWidgetExtraProperties;
  value: FormDataValue;
}

export default function getFormWrapperWidgetProperties({
  fieldUiSchema,
  error,
  extraProperties,
  value,
  name,
}: Payload): FormWrapperWidgetProperties {
  return {
    name,
    title: fieldUiSchema?.title || '',
    disabled: fieldUiSchema?.disabled,
    hintMessage: fieldUiSchema?.hintMessage || '',
    value,
    error,
    extraProperties,
    options: fieldUiSchema.options ?? {},
  };
}
