import type {
  CoreSportResponse,
  CoreSportReferenceResponse,
} from 'web/src/modules/sportline/types/rest';

import { isFullResponse } from './isResponseReference';

export function isFullCoreSportResponse(
  response: CoreSportResponse | CoreSportReferenceResponse,
): response is CoreSportResponse {
  return isFullResponse<CoreSportResponse, CoreSportReferenceResponse>(response);
}
