import type {
  NavigationGuardNext,
  RouteLocationNormalized,
} from 'vue-router';

export default function casinoExitRoutePageBeforeEnter(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
): void {
  try {
    const newUrl = to.query.url as string || '/';

    if (!window.top || window.top === window) {
      next({
        path: newUrl,
        replace: true,
      });
      return;
    }
  } catch {
    //
  }

  next();
}
