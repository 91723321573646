<script setup lang="ts">
import { IconName, IconSize } from '@leon-hub/icons';

import { VIcon } from '@components/v-icon';
import type { VIconProps } from '@components/v-icon';

import VCircularProgress from 'web/src/components/CircularProgress/VCircularProgress/VCircularProgress.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import useBonusPointsContainer
  from 'web/src/modules/bonuses/components/BonusPointsContainer/composables/useBonusPointsContainer';
import type { BonusPointsContainerProps } from 'web/src/modules/bonuses/components/BonusPointsContainer/types';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';

const props = defineProps<BonusPointsContainerProps>();

const {
  loyaltyProgramEgsStatus,
  bonusLoaded,
  openBonusLoyaltyPage,
  progressProperties,
  pointsLabelProperties,
  progress,
  requiredAmount,
  buttonProperties,
  handleExchange,
} = useBonusPointsContainer(props);
const { isLoggedIn } = useIsLoggedIn();

const iconProperties: VIconProps = {
  name: IconName.EXPAND_RIGHT,
  size: IconSize.SIZE_16,
};
</script>

<template>
  <div v-auto-id="'BonusPointsContainer'"
    v-if="isLoggedIn && loyaltyProgramEgsStatus && bonusLoaded"
    :class="{[$style['bonus-points']]: true,
             [$style['bonus-points--small']]: isSmall,
             [$style['bonus-points--short']]: isShort
    }"

    @click="openBonusLoyaltyPage"
  >
    <div :class="$style['bonus-points__left']">
      <VCircularProgress
        v-bind="progressProperties"
        :class="{[$style['bonus-points__progress']]: true,
        }"
      >
        <img
          :class="$style['bonus-points__progress-icon']"
          :src="require('web/src/assets/images/gift-leon.svg')"
          alt=""
        >
      </VCircularProgress>
      <div>
        <span :class="$style['bonus-points__title']">{{ $t('WEB2_LOYALTY_PROGRAM_BONUS_POINTS_TITLE') }}</span>
        <div :class="$style['bonus-points__quantity']">
          <span :class="$style['bonus-points__quantity-label']">
            {{ pointsLabelProperties }}
          </span>
          <VIcon
            :class="$style['bonus-points__quantity-icon']"
            :name="IconName.COIN_L"
          />
        </div>
      </div>
    </div>
    <div :class="$style['bonus-points__right']">
      <div
        v-if="progress < 1"
        :class="$style['bonus-points__required']"
      >
        <span :class="$style['bonus-points__required-text']">
          {{ requiredAmount }} {{ $t('WEB2_LOYALTY_PROGRAM_BEFORE_EXCHANGE') }}
        </span>
        <VIcon
          :class="$style['bonus-points__required-icon']"
          v-bind="iconProperties"
        />
      </div>
      <VButton
        v-else
        v-bind="buttonProperties"
        :class="$style['bonus-points__button']"
        @click="handleExchange"
      />
    </div>
  </div>
</template>

<style lang="scss" module>
.bonus-points {
  $self: &;

  @include z-index(absolute-item);

  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 16px;
  height: 56px;
  padding: 8px;
  background-color: var(--Layer1);
  border-radius: 5px;

  @include for-hover {
    &:hover {
      cursor: pointer;
      filter: drop-shadow(0 8px 16px var(--ModalShadow));

      #{$self}__required {
        color: var(--TextDefault);
      }

      #{$self}__button {
        color: var(--Layer2);
        background-color: var(--TextDefault);
      }

      &#{$self}--short {
        border-radius: 5px 0 0 5px;

        #{$self}__right {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }

  &__left {
    display: flex;
    align-items: center;
  }

  &__right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: var(--Layer1);
    border-radius: 0 5px 5px 0;
  }

  &__progress {
    flex-shrink: 0;
    margin-right: 12px;

    &-icon {
      width: 20px;
      height: 20px;
    }
  }

  &__title {
    @include regular\13\20;

    color: var(--TextSecondary);
    white-space: nowrap;
  }

  &__quantity {
    @include medium\16\20\025;

    display: flex;
    align-items: center;

    &-label {
      margin-right: 4px;
      color: var(--TextDefault);
      white-space: nowrap;
    }

    &-icon {
      fill: var(--BrandDefault);
    }
  }

  &__required {
    @include regular\12\16;

    display: flex;
    align-items: center;
    color: var(--TextSecondary);

    &-text {
      text-align: right;
      text-decoration: underline;
    }

    &-icon {
      margin-left: 4px;
    }
  }

  &--small {
    height: 40px;
    padding-top: 4px;
    padding-bottom: 4px;

    #{$self}__progress {
      width: 32px;
      height: 32px;
      margin-right: 8px;
    }

    #{$self}__title {
      display: none;
    }

    #{$self}__quantity {
      @include medium\14\20\025;
    }

    #{$self}__required {
      white-space: nowrap;
    }
  }

  &--short {
    display: inline-flex;

    #{$self}__right {
      position: absolute;
      top: 0;
      left: 100%;
      height: 100%;
      padding-right: 8px;
      padding-left: 8px;
      visibility: hidden;
      opacity: 0;
    }
  }
}
</style>
