<script lang="ts" setup>
import { IconName } from '@leon-hub/icons';

import { VForm } from 'web/src/components/Form';
import VHintBlock from 'web/src/components/HintBlock/VHintBlock/VHintBlock.vue';
import ModalPortal from 'web/src/components/Modal/ModalPortal/ModalPortal.vue';
import VCmsContent from 'web/src/modules/cms/components/VCmsContent/VCmsContent.vue';
import { ModalSelector } from 'web/src/modules/core/enums';
import PaymentsCancelWithdrawal from 'web/src/modules/payments/components/PaymentsCancelWithdrawal/PaymentsCancelWithdrawal.vue';
import PaymentsLogo from 'web/src/modules/payments/components/PaymentsLogo/PaymentsLogo.vue';
import ConfirmModal from 'web/src/modules/dialogs/views/ConfirmModal/ConfirmModal.vue';
import type { PaymentSystemProps, PaymentSystemEmits } from 'web/src/modules/payments/components/PaymentSystem/types';
import { usePaymentSystem } from 'web/src/modules/payments/components/PaymentSystem/composables/usePaymentSystem';
import PaymentsIframe from 'web/src/modules/payments/components/PaymentsIframe/PaymentsIframe.vue';
import PaymentsHintBlock from 'web/src/modules/payments/components/PaymentsHintBlock/PaymentsHintBlock.vue';
import PaymentsContent from 'web/src/modules/payments/components/PaymentsContent/PaymentsContent.vue';
import PaymentsModalsController
  from 'web/src/modules/payments/components/PaymnetsModalsController/PaymentsModalsController.vue';
import { PaymentsModalType } from 'web/src/modules/payments/enums';
import SHintBlock from 'web/src/components/HintBlock/SHintBlock/SHintBlock.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';

import customPaymentsFormWidgets from '../../form-widgets/customPaymentsFormWidgets';

const componentProps = withDefaults(defineProps<PaymentSystemProps>(), {
  withdrawalStatsData: null,
});

const emits = defineEmits<PaymentSystemEmits>();

const modalSelector = '1' ? ModalSelector.DESKTOP_INNER_MODAL : ModalSelector.BODY;

const {
  showPaymentsLogo,
  paymentsLogoProperties,
  isDeposit,
  labelPropertiesEnrollmentTime,
  isDeptsupisstreetterminal,
  paymentsHintBlockProperties,
  hasInitiateModal,
  onSubmit,
  onChange,
  onInput,
  pushToMainPaymentPage,
  openIn,
  onDisabledClick,
  onIconSuffixClick,
  manualSubmit,
  isYMHide,
  paymentForm,
  isCryptoWithdrawal,
  minAMount,
} = usePaymentSystem(componentProps, emits);
</script>

<template>
  <PaymentsContent
    v-if="htmlContent"
    :html-content="htmlContent"
    :html-content-properties="htmlContentProperties"
    @close="pushToMainPaymentPage"
  />
  <div
    v-else-if="!redirectUrl"
    :class="[$style['payments-system']]"
  >
    <PaymentsLogo
      v-if="showPaymentsLogo && !null"
      v-bind="paymentsLogoProperties"
      :class="[$style['payments-system__logo']]"
    />
    <PaymentsCancelWithdrawal
      v-if="withdrawalStatsData?.requestedCount"
      :value="withdrawalStatsData?.requestedAmount ?? 0"
    />
    <div
      :class="{
        [$style['payments-system__form']] : true,
      }"
    >
      <VForm
        v-if="schema && uiFormSchema && !(paymentSystem?.isTerminal ?? false)"
        ref="paymentForm"
        :ui-schema="uiFormSchema"
        :schema="schema"
        :custom-errors="paymentError ?? {}"
        :class="{ 'ym-hide-content': isYMHide }"
        :is-pending="isFormPending"
        @submit="onSubmit"
        @input="onInput"
        @change="onChange"
        @disabled-button-click="onDisabledClick"
        @icon-suffix-click="onIconSuffixClick"
      >
        <template
          v-for="(component, slotName) in customPaymentsFormWidgets"
          :key="slotName"
          #[slotName]="{ props, events, ref }"
        >
          <component
            :is="component"
            :ref="ref"
            v-bind="props"
            v-on="events"
          />
        </template>
      </VForm>
      <VButton
        v-if="null && isDeposit && !paymentSystem?.isTerminal"
        v-data-test="{el: submitButton?.id}"
        :class="[$style['payments-system__form__button']]"
        v-bind="submitButton"
        @click="manualSubmit"
      />
    </div>
    <span
      v-if="!isDeposit"
      :class="[$style['payments-system__label']]"
    >
      {{ labelPropertiesEnrollmentTime }}
    </span>

    <span
      v-if="isCryptoWithdrawal"
      :class="[$style['payments-system__label']]"
    >
      {{ minAMount }}
    </span>

    <VCmsContent
      v-if="isDeptsupisstreetterminal"
      cms-key="DEPTSUPISSTREETTERMINAL_WEB2"
      no-padding
    />

    <SHintBlock
      v-if="!isDeposit && companyFeeHintDescription && null"
      type="quaternary"
      icon-color="secondary"
      :icon-name="IconName.INFO"
      :class="[$style['payments-system__hint-block']]"
    >
      <template #default>
        {{ companyFeeHintDescription }}
      </template>
    </SHintBlock>
    <VHintBlock
      v-else-if="!isDeposit && companyFeeHintDescription"
      :class="[$style['payments-system__hint-block']]"
    >
      <template #label>
        {{ companyFeeHintDescription }}
      </template>
    </VHintBlock>

    <PaymentsHintBlock
      v-bind="paymentsHintBlockProperties"
      @click-other="pushToMainPaymentPage"
    />
    <ModalPortal :selector="modalSelector">
      <ConfirmModal
        v-if="hasInitiateModal && initialModalProperties"
        :modal="initialModalProperties"
        @close="hasInitiateModal = false"
        @button-click="hasInitiateModal = false"
      />
    </ModalPortal>
  </div>
  <PaymentsIframe
    v-else
    :is-deposit="isDeposit"
    :redirect-url="redirectUrl"
    :redirect-charset="redirectCharset"
    :redirect-form-params="redirectFormParams"
    :redirect-method="redirectMethod"
    :open-in="openIn"
    :mobile-redirect-url="mobileRedirectUrl"
    :is-deep-links-allowed="paymentSystem?.isDeepLinkAllowed ?? false"
  />
  <PaymentsModalsController
    v-if="showPaymentsModal"
    :modal-text="modalText"
    :modal-type="PaymentsModalType.DEPOSITS_OTHER_SUCCESS_MODAL"
    is-in-profile
    @close="emits('close')"
  />
</template>

<style lang="scss" module>
@include for-layout using ($isDesktop) {
  .payments-system {
    padding: if($isDesktop, 0 32px, 0);

    &__label {
      @include medium\12\16;

      display: block;
      margin: 12px 0 0;
      color: var(--TextSecondary);
      text-align: center;
    }

    &__cancel-withdrawal {
      position: if($isDesktop, absolute, fixed);
      bottom: if($isDesktop, -216px, 64px);
      left: 0;
      width: 100%;
      text-align: center;

      @if $isDesktop {
        margin-top: 10px;
      }
    }

    &__logo {
      display: block;
      max-width: 80px;
      margin: 0 auto 20px;
    }

    &__form {
      margin: 0 auto;

      &__button {
        @include paymentFormButton;
      }
    }

    &__hint-block {
      margin-top: 20px;
    }

    :deep(.finDescPar) {
      margin-top: 12px;
    }

    :deep(* + .single-select) {
      margin-top: 16px;
    }

    :deep(* + .checkbox),
    :deep(* + .checkbox__container) {
      margin-top: 16px;
    }
  }
}
</style>
