<script lang="ts" setup>
import { toRef, computed } from 'vue';

import { useInjectIsExpandedSportlineBlock } from 'web/src/modules/sportline/composables/expandable-block';
import type { SportElementHeadlineExpandState } from 'web/src/modules/sportline/types/list';
import type { VirtualListSportHeadlineElement } from 'web/src/modules/sportline/views/virtual-list-renderer/types';
import { VirtualListBlockLayout } from 'web/src/modules/sportline/views/virtual-list-renderer/enums';
import SportHeadline from 'web/src/modules/sportline/views/headline/SportHeadline.vue';

interface VirtualListRendererSportHeadlineProps {
  element: VirtualListSportHeadlineElement;
  isMarketTypesSelectionEnabled?: boolean;
}

const props = defineProps<VirtualListRendererSportHeadlineProps>();
const element = toRef(() => props.element);
const isMarketTypesSelectionEnabled = toRef(() => props.isMarketTypesSelectionEnabled);

const collapseSportKey = computed<Maybe<string>>(() => element.value.collapseSportKey ?? null);
const isMarketTypesSelectionEnabledFinal = computed<boolean>(() => (
  element.value?.isMarketTypesSelectionEnabled !== undefined
    ? element.value.isMarketTypesSelectionEnabled && isMarketTypesSelectionEnabled.value
    : isMarketTypesSelectionEnabled.value
));

const {
  isExpanded,
  setExpandedState,
} = useInjectIsExpandedSportlineBlock({ expandKey: collapseSportKey });

function onToggleExpandState(payload: SportElementHeadlineExpandState): void {
  setExpandedState(payload.value);
}
</script>

<template>
  <SportHeadline v-auto-id="'VirtualListRendererSportHeadline'"
    :sport-id="element.sport.id"
    :class="$style['sport-headline']"
    :is-coming="element.blockLayout === VirtualListBlockLayout.Coming"
    :count-events="element.eventsCount"
    :basis-key="element.basisKey"
    :is-market-types-selection-enabled="isMarketTypesSelectionEnabledFinal"
    :hide-events-counter="element.hideEventsCounter"
    :expand-key="element.collapseSportKey"
    :is-expanded="isExpanded"
    @toggle-expand-state="onToggleExpandState"
  />
</template>

<style module lang="scss">
.sport-headline {
  min-height: var(--list-sport-headline-height);
  margin-bottom: var(--list-sport-headline-gap);
}
</style>
