enum ProfileRouteName {
  ACHIEVEMENTS = 'achievements',
  AVATAR = 'avatar',
  ACHIEVEMENTS_NOTIFICATION = 'achievements-notification',
  ACHIEVEMENTS_SHARE = 'achievements-share',
  ACHIEVEMENTS_PUBLIC_PROFILE = 'achievements-public-profile',
  ACHIEVEMENTS_LIST = 'achievements-list',
  ACHIEVEMENTS_DETAILS = 'achievements-details',
  ACHIEVEMENTS_FILTER = 'achievements-filter',
  PRIVACY = 'privacy',
}

export default ProfileRouteName;
const profileRouteNames = Object.values(ProfileRouteName);

export function isProfileRouteName(value: unknown): value is ProfileRouteName {
  return profileRouteNames.includes(value as ProfileRouteName);
}
