<script lang="ts" setup>
import { ref, toRefs } from 'vue';

import type {
  MarketsListTabsReference,
  MarketListToolbarReference,
} from 'web/src/modules/sportline/views/markets-list/types';
import type {
  MarketsGridFilter,
  MarketsGridFilterId,
} from 'web/src/modules/sportline/submodules/markets-grid/types';
import MarketsTabs from 'web/src/modules/sportline/views/markets-list/components/MarketsTabs.vue';
import VSwitch from 'web/src/components/Switch/VSwitch/VSwitch.vue';

import useMarketsListToolbar from './composables/useMarketsListToolbar';

interface MarketsListToolbarProps {
  hasStatistic?: boolean;
  doShowInlineStatisticSwitch?: boolean;
  doShowAllMarketsTab?: boolean;
  isStatisticSelected?: boolean;
  gridFilters: MarketsGridFilter[];
  activeMarketGroupId: MarketsGridFilterId;
}

interface MarketsListToolbarEmits {
  (e: 'market-tab-click', payload: string): void;
  (e: 'change-statistic-selected', payload: boolean): void;
}

const props = defineProps<MarketsListToolbarProps>();
const emit = defineEmits<MarketsListToolbarEmits>();
const tabsRef = ref<Maybe<MarketsListTabsReference>>(null);

const {
  isStatisticSelected,
} = toRefs(props);

const {
  slideToActiveTab,
  onMarketTabClick,
  onChangeStatisticSelected,
} = useMarketsListToolbar({
  isStatisticSelected,
  tabsRef,
}, emit);

defineExpose<MarketListToolbarReference>({
  slideToActiveTab,
});
</script>

<template>
  <div v-auto-id="'MarketsListToolbar'" :class="$style['sport-event-details__controls']">
    <MarketsTabs
      ref="tabs"
      :grid-filters="gridFilters"
      :active-tab-id="activeMarketGroupId"
      :has-statistic="hasStatistic && !doShowInlineStatisticSwitch"
      :has-all-tab="doShowAllMarketsTab"
      @tab-click="onMarketTabClick"
    />
    <div
      v-if="hasStatistic && doShowInlineStatisticSwitch && '1'"
      :class="$style['sport-event-details__switch-block']"
    >
      <VSwitch
        :checked="isStatisticSelected"
        :label="$t('WEB2_MATCH_STATISTIC')"
        :class="$style['sport-event-details__switch']"
        @change="onChangeStatisticSelected"
      />
    </div>
  </div>
</template>

<style module lang="scss">
@include for-layout using ($isDesktop) {
  .sport-event-details {
    &__controls {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 44px;
    }

    &__switch-block {
      background-color: var(--OpacityLayer1);
      border-radius: 5px;
    }

    &__switch {
      padding: 10px 8px 10px 12px;
    }
  }
}
</style>
