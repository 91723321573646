import memoize from 'lodash/memoize';
import { effectScope } from 'vue';

/**
 * Defines a stateful composable with built-in memoization (pinia like behavior).
 *
 * This utility acts as an intermediate solution for refactoring existing
 * stateful logic in composables or stores without requiring immediate changes
 * across the application. It helps centralize state management temporarily
 * while enabling a gradual transition to a more robust solution like Pinia.
 *
 * Key points:
 * - Memoizes the provided stateful function (`callback`) to ensure the same
 *   instance of state is reused across multiple calls.
 * - Encapsulates state logic within a Vue effect scope, ensuring proper
 *   reactivity and isolation of dependencies.
 * - Reduces the risk of breaking changes during refactoring by maintaining
 *   backward compatibility with existing usage patterns.
 *
 * Usage notes:
 * - Designed as a short-term solution; all usages should eventually be
 *   migrated to a Pinia store or equivalent (requires manual refactoring).
 * - Each call to `defineStatefulComposable` memoizes its own state, so
 *   different composables remain isolated even if they use this utility.
 * - Ideal for scenarios where state logic needs to be centralized
 *   but full-fledged state management is unnecessary.
 */
export const defineStatefulComposable = <T extends () => unknown>(callback: T): T => {
  const memoizedScope = memoize(() => {
    const scope = effectScope(true);
    const result = scope.run(() => callback());
    if (!result) {
      throw new Error('Failed to initialize effect scope.');
    }
    return { result, scope };
  });

  return (() => {
    const { result } = memoizedScope();
    return result;
  }) as T;
};
