import type { Plugin } from 'vue';

import { createAutomationDirective } from '../directive';
import { DIRECTIVE_NAME } from '../constants';

export const AutomationPlugin: Plugin = {
  install(app, {
    elemAttrName,
    isEnabled = false,
  }: {
    elemAttrName: string;
    isEnabled: boolean;
  }) {
    app.directive(DIRECTIVE_NAME, isEnabled ? createAutomationDirective({
      elemAttrName,
    }) : {});
  },
};
