import GqlApiErrorCode from './GqlApiErrorCode';

export default class GqlApiResponseErrorCode extends GqlApiErrorCode {
  public constructor(code: string) {
    super(code);
  }

  static GQL_API_SERVICE_INVALID_SYNTAX = new GqlApiResponseErrorCode('InvalidSyntax');

  static GQL_API_SERVICE_VALIDATION_ERROR = new GqlApiResponseErrorCode('ValidationError');

  static GQL_API_SERVICE_DATA_FETCHING_EXCEPTION = new GqlApiResponseErrorCode('DataFetchingException');

  static GQL_API_SERVICE_OPERATION_NOT_SUPPORTED = new GqlApiResponseErrorCode('OperationNotSupported');

  static GQL_API_SERVICE_EXECUTION_ABORTED = new GqlApiResponseErrorCode('ExecutionAborted');
}
