import { assert } from '@leon-hub/guards';

import type { GetTrackingSlipEntriesPayload, TrackingPayload } from '../types';
import getSlipEntryId from '../../../utils/getSlipEntryId';

export default function getTrackingSlipEntries({
  acceptedBets,
  multiSinglesMetaInfo,
  sameStakeForSingles,
  isMultiSinglesMode,
  stakeValue,
  entriesCount,
  selectedFastMoneyValue,
  maxAvailableFastBetValue,
}: GetTrackingSlipEntriesPayload): TrackingPayload['slipEntries'] {
  return acceptedBets.map((item) => {
    assert(!!item?.slipInfo?.entries[0], 'Can\'t find related slip entry');
    const entryId = getSlipEntryId(item.slipInfo.entries[0]);

    const matchedRecord = entryId ? multiSinglesMetaInfo[entryId] : null;

    const price = isMultiSinglesMode && !sameStakeForSingles
      ? Number(matchedRecord?.stakeValue || 0) : stakeValue;
    const fastBets = isMultiSinglesMode && !sameStakeForSingles
      ? (Number(matchedRecord?.selectedFastBetValue)) : selectedFastMoneyValue;
    let isMaxStake = stakeValue === maxAvailableFastBetValue;
    if (isMultiSinglesMode && !sameStakeForSingles) {
      // almost impossible detect what is maxStake in this context
      isMaxStake = false;
    }
    return {
      result: item,
      isMaxStake,
      fastBets: fastBets || null,
      price,
      count: entriesCount,
    };
  });
}
