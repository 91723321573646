import type { Ref } from 'vue';
import { ref, toRef } from 'vue';

import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';

interface UseSportlineLayoutSettingsComposable {
  isBetSwipeTopEventsLayoutEnabled: Ref<boolean>;
  isBetSwipeNavigationLayoutEnabled: Ref<boolean>;
  isBetSwipeLeagueLayoutEnabled: Ref<boolean>;
  isBetSwipeRegionLayoutEnabled: Ref<boolean>;
  isBetSwipeLiveLayoutEnabled: Ref<boolean>;
  isBetSwipeEventDetailsLayoutEnabled: Ref<boolean>;
  isBetSwipeSportsLayoutEnabled: Ref<boolean>;
}

export function useSportlineLayoutSettings(): UseSportlineLayoutSettingsComposable {
  if (process.env.VUE_APP_FEATURE_SPORTLINE_BET_SWIPE_LAYOUT_DISABLED) {
    return {
      isBetSwipeTopEventsLayoutEnabled: ref(false),
      isBetSwipeNavigationLayoutEnabled: ref(false),
      isBetSwipeLeagueLayoutEnabled: ref(false),
      isBetSwipeRegionLayoutEnabled: ref(false),
      isBetSwipeLiveLayoutEnabled: ref(false),
      isBetSwipeEventDetailsLayoutEnabled: ref(false),
      isBetSwipeSportsLayoutEnabled: ref(false),
    };
  }

  const sportlineSettingsStore = useSportlineSettingsStore();

  const isBetSwipeTopEventsLayoutEnabled = toRef(sportlineSettingsStore, 'isBetSwipeTopEventsLayoutEnabled');
  const isBetSwipeNavigationLayoutEnabled = toRef(sportlineSettingsStore, 'isBetSwipeNavigationLayoutEnabled');
  const isBetSwipeLeagueLayoutEnabled = toRef(sportlineSettingsStore, 'isBetSwipeLeagueLayoutEnabled');
  const isBetSwipeRegionLayoutEnabled = toRef(sportlineSettingsStore, 'isBetSwipeRegionLayoutEnabled');
  const isBetSwipeLiveLayoutEnabled = toRef(sportlineSettingsStore, 'isBetSwipeLiveLayoutEnabled');
  const isBetSwipeEventDetailsLayoutEnabled = toRef(sportlineSettingsStore, 'isBetSwipeEventDetailsLayoutEnabled');
  const isBetSwipeSportsLayoutEnabled = toRef(sportlineSettingsStore, 'isBetSwipeSportsLayoutEnabled');

  return {
    isBetSwipeTopEventsLayoutEnabled,
    isBetSwipeNavigationLayoutEnabled,
    isBetSwipeLeagueLayoutEnabled,
    isBetSwipeRegionLayoutEnabled,
    isBetSwipeLiveLayoutEnabled,
    isBetSwipeEventDetailsLayoutEnabled,
    isBetSwipeSportsLayoutEnabled,
  };
}
