import type { Ref } from 'vue';
import { toRef } from 'vue';

import { useGetSharedSlipStore } from '../store';

interface UseGetSharedSlipRoute {
  isReceiveFromCodeEnabled: Ref<boolean>;
}

export function useGetSharedSlipRoute(): UseGetSharedSlipRoute {
  const isReceiveFromCodeEnabled = toRef(useGetSharedSlipStore(), 'isReceiveFromCodeEnabled');

  return {
    isReceiveFromCodeEnabled,
  };
}
