<script lang="ts" setup>
import { useCssModule } from 'vue';

import vPopperHint from 'web/src/components/Popper/directives/popperHint';
import type { TeamStandingPromotion } from 'web/src/modules/sportline/submodules/core-statistic/types';

interface StatisticLeagueStandingsPromotionProps {
  promotion: TeamStandingPromotion;
}

defineProps<StatisticLeagueStandingsPromotionProps>();

const $style = useCssModule();

const promotionIndicatorClasses = {
  [$style['standings-team-promotion--continental-league-playoff']]: new Set(['2', '45', '29', '44', '19', '15', '40', '10', '42', '39']),
  [$style['standings-team-promotion--continental-league']]: new Set(['1', '16', '25', '9', '14', '20', '35']),
  [$style['standings-team-promotion--second-continental-league']]: new Set(['18', '36', '26', '17', '37', '3', '11', '33', '38']),
  [$style['standings-team-promotion--second-continental-league-playoff']]: new Set(['27', '4', '24']),
  [$style['standings-team-promotion--relegation-play-off']]: new Set(['8', '23', '30']),
  [$style['standings-team-promotion--relegation-direct']]: new Set(['7', '31']),
  [$style['standings-team-promotion--promotion-direct']]: new Set(['5']),
  [$style['standings-team-promotion--promotion-playoff']]: new Set(['6']),
  [$style['standings-team-promotion--next-round-play-off']]: new Set(['28']),
};

function getPromotionIndicatorClasses(promotion: TeamStandingPromotion): Record<string, boolean> {
  const promotionId = promotion.id;

  if (!promotionId) { return {}; }

  const modifier = Object.keys(promotionIndicatorClasses).find((key) => promotionIndicatorClasses[key].has(promotionId));

  return { [modifier || $style['standings-team-promotion--filled']]: true };
}
</script>

<template>
  <div v-auto-id="'StatisticLeagueStandingsPromotion'"
    v-popper-hint="promotion.name"
    :class="[
      $style['standings-team-promotion'],
      getPromotionIndicatorClasses(promotion)
    ]"
  />
</template>

<style lang="scss" module>
.standings-team-promotion {
  width: 8px;
  min-width: 8px;
  height: 8px;
  min-height: 8px;
  border: 1px solid var(--TextSecondary);
  border-radius: 4px;

  &[title] {
    cursor: pointer;
  }

  &--filled {
    background: var(--TextSecondary);
    border-color: var(--TextSecondary);
  }

  &--continental-league {
    background: #1a237e;
    border-color: #1a237e;
  }

  &--continental-league-playoff {
    background: #2196f3;
    border-color: #2196f3;
  }

  &--second-continental-league {
    background: #7e8392;
    border-color: #7e8392;
  }

  &--second-continental-league-playoff {
    background: #cbcdd3;
    border-color: #cbcdd3;
  }

  &--relegation-play-off {
    background: #ff9800;
    border-color: #ff9800;
  }

  &--relegation-direct {
    background: #f14c45;
    border-color: #f14c45;
  }

  &--promotion-direct {
    background: #2e7d32;
    border-color: #2e7d32;
  }

  &--promotion-playoff {
    background: #00c853;
    border-color: #00c853;
  }

  &--next-round-play-off {
    background: #ffed00;
    border-color: #ffed00;
  }
}
</style>
