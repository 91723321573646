<script lang="ts" setup>
import {
  IconName,
  IconSize,
} from '@leon-hub/icons';

import { VScrollbar } from '@components/v-scrollbar';

import CasinoLoyaltyProgramCurrentLevelCard
  // eslint-disable-next-line max-len
  from 'web/src/modules/casino-loyalty-program/components/CasinoLoyaltyProgramCurrentLevelCard/CasinoLoyaltyProgramCurrentLevelCard.vue';
import CasinoLoyaltyProgramSwiper from 'web/src/modules/casino-loyalty-program/components/CasinoLoyaltyProgramSwiper/CasinoLoyaltyProgramSwiper.vue';
import VTabs from 'web/src/components/Tabs/VTabs/VTabs.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import BonusLoyaltyProgramPanel from 'web/src/modules/bonuses/components/BonusLoyaltyProgramPanel/BonusLoyaltyProgramPanel.vue';
import { VLoaderDelayed } from 'web/src/components/Loader';
import { CasinoLoyaltyProgramCardSizes } from 'web/src/modules/casino-loyalty-program/store/types';
import BonusHowDoesItWork from 'web/src/modules/bonuses/components/BonusHowDoesItWork/BonusHowDoesItWork.vue';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import CasinoGameSideBarAnonymous from 'web/src/modules/casino/submodules/game/components/CasinoGameSideBarAnonymous/CasinoGameSideBarAnonymous.vue';
import BonusLoyaltyProgramCard from 'web/src/modules/bonuses/components/BonusLoyaltyProgramCard/BonusLoyaltyProgramCard.vue';

import { useCasinoGameSideBarContainer } from './composables';
import type { CasinoGameSideBarContainerProps } from './types';

const props = withDefaults(defineProps<CasinoGameSideBarContainerProps>(), {});

const {
  isLoyaltySidebarOpened,
  bonus,
  isLoggedIn,
  tabsProperties,
  isBonusLoaded,
  isMultiTab,
  isLoyaltyProgramTab,
  currentLevelCardProperties,
  levelSettingsWithStatus,
  currentPoints,
  amountNumber,
  remainingAmountNumber,
  progress,
  progressPercentString,
  isParticipating,
  isLeonShopEnabled,
  offers,
  isShopOffersLoaded,
  handleExchange,
  handleParticipate,
  toggleSidebarOpened,
  onTabClick,
  showCurrentLevelDescription,
  showLevelDescription,
  onShopItemClick,
} = useCasinoGameSideBarContainer(props);
</script>

<template>
  <div v-auto-id="'CasinoGameSideBarContainer'"
    v-if="isLoyaltySidebarOpened"
    :class="{
      [$style['casino-game-sidebar']]: true,
      [$style['casino-game-sidebar--is-over-game']]: isOverGame,
    }"
  >
    <div
      :class="{
        [$style['casino-game-sidebar__inner']]: true,
        [$style['casino-game-sidebar__inner--has-bonus']]: !!bonus,
      }"
    >
      <CasinoGameSideBarAnonymous
        v-if="!isLoggedIn"
        :is-over-game="isOverGame"
        @click-close="toggleSidebarOpened"
      />
      <template v-else>
        <div
          :class="{
            [$style['casino-game-sidebar__header']]: true,
            [$style['casino-game-sidebar__header--is-over-game']]: isOverGame,
          }"
        >
          <VTabs
            v-bind="tabsProperties"
            :class="{
              [$style['casino-game-sidebar__tabs']]: true,
              [$style['casino-game-sidebar__tabs--multi']]: isMultiTab,
            }"
            :single="!isMultiTab"
            @tab-click="onTabClick"
          />
          <VButton
            v-if="isOverGame"
            :class="$style['casino-game-sidebar__button-close']"
            :icon-name="IconName.CROSS"
            :icon-size="IconSize.SIZE_24"
            :kind="ButtonKind.TRANSPARENT"
            @click="toggleSidebarOpened"
          />
        </div>
        <div :class="$style['casino-game-sidebar__content']">
          <div
            v-if="isLoyaltyProgramTab"
            :class="$style['casino-game-sidebar__content-inner']"
          >
            <CasinoLoyaltyProgramCurrentLevelCard
              v-bind="currentLevelCardProperties"
              :class="$style['casino-game-sidebar__current-level']"
              @show-description="showCurrentLevelDescription"
            />
            <CasinoLoyaltyProgramSwiper
              :cards="levelSettingsWithStatus"
              :current-points="currentPoints"
              :card-size="CasinoLoyaltyProgramCardSizes.SIZE_116"
              has-navigation
              no-padding
              no-header-title-padding
              :class="$style['casino-game-sidebar__progress-swiper']"
              @click-card="showLevelDescription"
            />
          </div>
          <VScrollbar
            v-else-if="!isHideMyBonuses"
            :class="$style['casino-game-sidebar__content-scroll']"
            flex-fill
          >
            <div :class="$style['casino-game-sidebar__content-inner']">
              <VLoaderDelayed
                v-if="!isBonusLoaded"
              />
              <template v-else>
                <div :class="$style['casino-game-sidebar__shop-heading']">
                  {{ $t('WEB2_EXCHANGE_FOR_MONEY') }}
                </div>
                <BonusLoyaltyProgramPanel
                  :amount-number="amountNumber"
                  :progress="progress"
                  :progress-text="progressPercentString"
                  :remaining-amount-number="remainingAmountNumber"
                  :is-participating="isParticipating"
                  @withdraw="handleExchange"
                  @participate="handleParticipate"
                />
                <BonusHowDoesItWork v-if="!isLeonShopEnabled" />
                <template v-else>
                  <div :class="$style['casino-game-sidebar__shop-heading']">
                    {{ $t('WEB2_EXCHANGE_FOR_BONUSES') }}
                  </div>
                  <VLoaderDelayed
                    v-if="!isShopOffersLoaded"
                  />
                  <template v-else>
                    <div :class="$style['casino-game-sidebar__shop-wrapper']">
                      <BonusLoyaltyProgramCard
                        v-for="item in offers"
                        :key="item.id"
                        :name="item.name"
                        :balance="item.price"
                        :formatted-balance="item.formattedPrice"
                        :image-src="item.imageUrl"
                        :is-locked="item.price > amountNumber"
                        @click="onShopItemClick(item.id)"
                      />
                    </div>
                  </template>
                </template>
              </template>
            </div>
          </VScrollbar>
        </div>
      </template>
    </div>
  </div>
</template>

<style lang="scss" module>
.casino-game-sidebar {
  $self: &;

  position: relative;
  max-height: 704px;

  &__inner {
    flex-shrink: 0;
    width: 280px;
    height: 100%;
    overflow-y: auto;
    background-color: var(--EgsOverlayBarColor);
    border-radius: 0 5px 5px 0;
  }

  &__header {
    display: flex;

    &--is-over-game {
      justify-content: space-between;
    }
  }

  &__tabs {
    flex: 1;
    width: 50%;

    &--multi {
      width: 100%;
    }
  }

  &__content {
    position: absolute;
    top: 44px;
    right: 0;
    bottom: 0;
    left: 0;
    max-height: calc(100% - 44px);
    overflow-y: auto;
  }

  &__content-scroll {
    height: 100%;
  }

  &__content-inner {
    padding: 12px 16px;
  }

  &__current-level {
    margin-bottom: 8px;
  }

  &__shop-heading {
    @include medium\18\20;

    margin-bottom: 18px;
    color: var(--TextDefault);
  }

  &__shop-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &--is-over-game {
    @include z-index('absolute-item');

    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    width: calc(100vw - 40px);
    min-width: 790px;
    max-width: 1200px;
    pointer-events: none;

    #{$self}__inner {
      pointer-events: all;
      background-color: var(--OpacityLayer1);
      backdrop-filter: blur(40px);
      border-radius: 5px 5px 0;

      &--has-bonus {
        border-bottom-right-radius: 5px;
      }
    }
  }
}

@include for-os-windows {
  .casino-game-sidebar__content {
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
</style>
