import { computed, toRef } from 'vue';
import type { Ref } from 'vue';

import usePaymentsSuggestedAmounts
  // eslint-disable-next-line max-len
  from 'web/src/modules/payments/form-widgets/FastSum/components/PaymentsFastSumInput/composables/usePaymentsSuggestedAmounts';
import type {
  PaymentsFastSumInputProps,
} from 'web/src/modules/payments/form-widgets/FastSum/components/PaymentsFastSumInput/types';

interface MaxValueFastChoiceDepositComposable {
  maxValueFastChoiceDeposit: Ref<number>;
}
export default function usePaymentsMaxValueFastChoiceDeposit(
  props: PaymentsFastSumInputProps,
): MaxValueFastChoiceDepositComposable {
  const maxAmount = toRef(props, 'maxAmount');
  const allowInputSuggestedAmountsOnly = toRef(props, 'allowInputSuggestedAmountsOnly');

  const {
    filteredDefaultSuggestedAmounts,
    filteredCurrentPaymentSystemSuggestedAmounts,
  } = usePaymentsSuggestedAmounts(props);
  const maxValueFastChoiceDeposit = computed(() => {
    if (allowInputSuggestedAmountsOnly.value) {
      return 0;
    }

    if (filteredCurrentPaymentSystemSuggestedAmounts.value) {
      const amountsList = filteredCurrentPaymentSystemSuggestedAmounts.value;

      if (maxAmount.value && (amountsList[amountsList.length - 1] >= maxAmount.value)) {
        return 0;
      }
    }

    if (filteredDefaultSuggestedAmounts.value) {
      const defaultAmountsList = filteredDefaultSuggestedAmounts.value;

      if (maxAmount.value
        && (defaultAmountsList[defaultAmountsList.length - 1] >= maxAmount.value)) {
        return 0;
      }
    }

    return maxAmount.value || 0;
  });

  return {
    maxValueFastChoiceDeposit,
  };
}
