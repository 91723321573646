import { isArray } from '@leon-hub/guards';

import type {
  GetSpintaxSeoMetaDataOptions,
  SpintaxSeoMetaDataRouteLocation,
} from 'web/src/modules/seo/types';

export function mapRouteParamsIntoSpintaxRequestOptions(
  rawRouteParams?: SpintaxSeoMetaDataRouteLocation['params'],
): GetSpintaxSeoMetaDataOptions['routeParams'] {
  if (!rawRouteParams) { return []; }
  return Object.keys(rawRouteParams)
    .map((key) => {
      const rawValue = rawRouteParams[key];
      const value = isArray(rawValue) ? rawValue.join('&') : rawValue;
      return { key: `:${key.toLowerCase()}`, value };
    });
}

export function getSpintaxSeoMetaDataRequestOptionsForRoute(
  route?: Maybe<SpintaxSeoMetaDataRouteLocation>,
): Maybe<GetSpintaxSeoMetaDataOptions> {
  const routeName = route?.name as Optional<GetSpintaxSeoMetaDataOptions['routeName']>;
  if (!routeName) { return null; }
  const routeParams = mapRouteParamsIntoSpintaxRequestOptions(route?.params);
  return { routeName, routeParams };
}
