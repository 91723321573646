import type { ComputedRef } from 'vue';
import { computed, toRef } from 'vue';

import { useI18nStore } from 'web/src/modules/i18n/store';

interface RuleDownloadComposable {
  fileHref: ComputedRef<string>;
}

export default function useRuleDownload(): RuleDownloadComposable {
  const locale = toRef(useI18nStore(), 'locale');
  const fileHref = computed(() => `https://slott3.gcdn.online/UUKJASJD/files/terms/Slott-TermsAndConditions-${locale.value.toLowerCase()}.pdf`);

  return {
    fileHref,
  };
}
