import { ref } from 'vue';

import type { ProgramReward } from '@leon-hub/api-sdk';
import {
  programRewardTypeFreebet,
  programRewardTypeFreespin,
} from '@leon-hub/api-sdk';

import LanguageMicroSettings from 'web/src/utils/LanguageMicroSettings';
import type { I18nFunctionReactive } from 'web/src/modules/i18n/types';

export default function getRewardTitle(
  reward: Pick<ProgramReward, 'count' | 'rewardType'>,
  $t: I18nFunctionReactive,
  locale: string,
): string {
  const rewardKeys = ref({
    count: reward.count.toString() || '',
  });

  switch (reward.rewardType) {
    case programRewardTypeFreespin:
      return reward.count === 0 ? '0' : LanguageMicroSettings.plural([
        $t('WEB2_BONUSES_COMPLEX_FREESPIN_ONE_TITLE', rewardKeys).value,
        $t('WEB2_BONUSES_COMPLEX_FREESPIN_TWO_TITLE', rewardKeys).value,
        $t('WEB2_BONUSES_COMPLEX_FREESPIN_MANY_TITLE', rewardKeys).value,
      ], reward.count || 0, locale);
    case programRewardTypeFreebet:
      return reward.count === 0 ? '0' : LanguageMicroSettings.plural([
        $t('WEB2_BONUSES_COMPLEX_FREEBET_ONE_TITLE', rewardKeys).value,
        $t('WEB2_BONUSES_COMPLEX_FREEBET_TWO_TITLE', rewardKeys).value,
        $t('WEB2_BONUSES_COMPLEX_FREEBET_MANY_TITLE', rewardKeys).value,
      ], reward.count || 0, locale);
    default:
      return '';
  }
}
