import type { DoRefuseMeetingReturnType } from '@leon-hub/api-sdk';
import { doRefuseMeeting } from '@leon-hub/api-sdk';

import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';

export default function refuseMeeting(): Promise<DoRefuseMeetingReturnType> {
  const apiClient = useGraphqlClient();
  return doRefuseMeeting(apiClient,
    (node) => node.mutations.verification.refuse,
    {
      options: {},
    });
}
