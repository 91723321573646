import type { RouteLocationNormalized } from 'vue-router';

import { LobbyGameType } from '@leon-hub/api-sdk';
import {
  assert,
  isString,
} from '@leon-hub/guards';

import type { EgsCategory } from 'web/src/modules/egs/types';
import {
  HistoryGamesCategoryUrl,
} from 'web/src/modules/egs/constants';
import { EgsGamesCategoryId } from 'web/src/modules/egs/enums';
import { useI18n } from 'web/src/modules/i18n/composables';

import isFavoriteEgsRoute from './isFavoriteEgsRoute';
import { getLobbyCategoryUrlFromRoute } from './getLobbyCategoryUrlFromRoute';

export default function getCategoryByRoute(categories: EgsCategory[], route: Pick<RouteLocationNormalized, 'name' | 'params'>): EgsCategory | undefined {
  const { $translate } = useI18n();

  const url = getLobbyCategoryUrlFromRoute(route);

  if (isFavoriteEgsRoute(route)) {
    assert(isString(url));
    return {
      id: EgsGamesCategoryId.MY_GAMES,
      type: LobbyGameType.SLOTS,
      url,
      iconUrl: '',
      nameEn: '',
      name: $translate('WEB2_MY_GAMES').value,
    };
  }

  if (url === HistoryGamesCategoryUrl) {
    return {
      id: EgsGamesCategoryId.HISTORY_GAMES,
      type: LobbyGameType.SLOTS,
      url,
      iconUrl: '',
      nameEn: '',
      name: $translate('WEB2_SIDEBAR_EGS_HISTORY_GAMES').value,
    };
  }

  return categories.find((category) => category.url === url);
}
