/* eslint-disable no-tabs */
/*
  vi_VN - Vietnam
    Language	vi
    Country	VN

  en_VI - US Virgin Islands
    Language	en
    Country	VI
*/
enum FlagValue {
  GREECE = 'el',
  KOREAN = 'ko',
  TECH = 'ln',
  UZBEK = 'uc',
  UKRAINIAN = 'uk',
  VIETNAMESE = 'vi',
}

export default FlagValue;
