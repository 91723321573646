<script lang="ts" setup>
import usePaymentsCryptoCurrencySelect
  from 'web/src/modules/payments/components/PaymentsCryptoCurrencySelect/composables/usePaymentsCryptoCurrencySelect';
import type {
  PaymentsCryptoCurrencySelectProps,
  PaymentsCryptoCurrencySelectEmits,
} from 'web/src/modules/payments/components/PaymentsCryptoCurrencySelect/types';
import { DropListSelect } from 'web/src/components/DropListSelect';

const props = withDefaults(defineProps<PaymentsCryptoCurrencySelectProps>(), {
});

const emit = defineEmits<PaymentsCryptoCurrencySelectEmits>();

const {
  selectedCurrency,
  currencyDropdownOptions,
  selectPaymentMethod,
} = usePaymentsCryptoCurrencySelect(props, emit);
</script>

<template>
  <div v-auto-id="'PaymentsCryptoCurrencySelect'"
    :class="$style['payments-crypto-currency-select']"
  >
    <div
      :class="$style['payments-crypto-currency-select__row']"
    >
      <DropListSelect
        v-if="null"
        :selected="selectedCurrency"
        :options="currencyDropdownOptions"
        :label="$t('WEB_2_CRYPTO_NETWORK_CURRENCY')"
        show-selected-icon
        disable-hover-filter
        search-enabled
        @item-click="selectPaymentMethod"
      />
    </div>
  </div>
</template>

<style lang="scss" module>
.payments-crypto-currency-select {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0;

  &__row {
    flex: none;
    width: 100%;
    padding-bottom: 16px;
    user-select: none;
  }
}
</style>
