import { defineStore } from 'pinia';

import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';

import type { CasinoLobbyMetricsData } from 'web/src/modules/casino/types';
import { useAnalytics } from 'web/src/modules/analytics/composables';

const useCasinoAnalyticsStore = defineStore('casino-analytics', () => {
  const analytics = useAnalytics();

  let lobbyMetricsData: CasinoLobbyMetricsData | undefined;

  function sendOpenGameLobbyMetrika(): void {
    if (!lobbyMetricsData) {
      return;
    }

    const {
      lobbyType,
      groupFilterName,
      categoryFilterName,
      section,
      vendorName,
      gameName,
    } = lobbyMetricsData;

    analytics.push(AnalyticsEvent.Z_OPEN_GAME_LOBBY, {
      gameVendorLobby: {
        [lobbyType]: {
          [groupFilterName]: {
            [categoryFilterName]: {
              [section]: {
                [vendorName]: gameName,
              },
            },
          },
        },
      },
    });

    lobbyMetricsData = undefined;
  }

  function setMetricsData(data: CasinoLobbyMetricsData): void {
    lobbyMetricsData = data;
  }

  return {
    setMetricsData,
    sendOpenGameLobbyMetrika,
  };
});

export default useCasinoAnalyticsStore;
