import type { Ref, MaybeRef } from 'vue';
import { toRef, ref, computed } from 'vue';

import { useI18n } from 'web/src/modules/i18n/composables';
import { useCountdownDateTimeLabels } from 'web/src/modules/sportline/composables/countdown';
import useSafeTimeBus from 'web/src/modules/core/composables/time-bus/useSafeTimeBus';
import { CountdownLabelType } from 'web/src/modules/sportline/composables/countdown/enums';
import { TodayLabelType } from 'web/src/modules/sportline/views/EventBlock/enums';
import DateTime, { isToday } from 'web/src/utils/DateTime';

interface UseSportlinePrematchMetaInfoProps {
  kickoff: Ref<number>;
  todayLabelType?: MaybeRef<Maybe<TodayLabelType>>;
}

interface UseSportlinePrematchMetaInfoComposable {
  willComeLabel: Ref<string>;
  startsInLabel: Ref<string>;
  date: Ref<string>;
  time: Ref<string>;
}

export function useSportlinePrematchMetaInfo(
  props: UseSportlinePrematchMetaInfoProps,
): UseSportlinePrematchMetaInfoComposable {
  const { kickoff } = props;
  const todayLabelType = toRef(props.todayLabelType ?? TodayLabelType.None);

  const { $translate } = useI18n();
  const { now } = useSafeTimeBus(1000);

  const timestamp = computed(() => (kickoff.value ? DateTime.withTimeStamp(kickoff.value) : undefined));
  const date = computed<string>(() => {
    if (
      todayLabelType.value === TodayLabelType.Inline
      && kickoff.value
      && isToday(kickoff.value)
    ) {
      return $translate('WEB2_SPORTS_QUICKFILTERS_TODAY_FULL').value;
    }

    return timestamp.value?.toDate() ?? '';
  });
  const time = computed<string>(() => timestamp.value?.toTime() ?? '');

  const {
    startsInLabel,
  } = useCountdownDateTimeLabels({
    fromTimestamp: now,
    toTimestamp: kickoff,
    displaySecondsOnMaxMinutes: ref(1),
    daysLabelType: ref(CountdownLabelType.Full),
    hoursLabelType: ref(CountdownLabelType.Full),
    minutesLabelType: ref(CountdownLabelType.Short),
    secondsLabelType: ref(CountdownLabelType.Short),
  });

  const emptyTimeRef = ref({ time: '' });
  const willComeLabel = $translate('WEB2_EVENT_WILL_COME_IN', emptyTimeRef);

  return {
    willComeLabel,
    startsInLabel,
    date,
    time,
  };
}
