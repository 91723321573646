import type { RouteLocationNormalized, NavigationGuardNext } from 'vue-router';

import { AbstractPrefetch } from 'web/src/modules/core/services/router/prefetch';
import type { AppVueRouter } from 'web/src/modules/core/services/router/types';
import {
  useSportlineRegionPageNavigationService,
} from 'web/src/modules/sportline/submodules/region/services/navigation/useSportlineRegionPageNavigationService';

export default class RegionPagePrefetch extends AbstractPrefetch {
  // eslint-disable-next-line class-methods-use-this
  prefetch(
    router: AppVueRouter,
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ): Promise<void> {
    useSportlineRegionPageNavigationService().prefetch(router, to, from, next);
    return Promise.resolve();
  }
}
