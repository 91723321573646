export default function isSlideVisibleInContainer(
  element: HTMLElement | null,
  scrollLeft: number,
  containerWidth: number,
  visibilityRate?: number,
): boolean {
  if (!element) {
    return false;
  }

  const wrapperRightMargin = scrollLeft + containerWidth;

  const { offsetWidth, offsetLeft } = element;
  const offsetRight = offsetLeft + (offsetWidth * (visibilityRate ?? 1));

  return offsetLeft < wrapperRightMargin && offsetRight > scrollLeft;
}
