import { assert } from '@leon-hub/guards';
import type { SentryOptions } from '@leon-hub/sentry';

import { useSiteConfigStore } from 'web/src/modules/core/store';

import { mergeIgnoredErrors } from './mergeIgnoredErrors';

export const getInitSentryOptions = (): SentryOptions => {
  const {
    errorHandlerSettings,
    ignoredErrorsCommon,
    sentryOptions,
  } = useSiteConfigStore();

  const version = process.env.VUE_APP_VERSION;
  assert(version, 'Expected name to be defined');
  const ignoredErrors = mergeIgnoredErrors(ignoredErrorsCommon, errorHandlerSettings?.ignoredErrors);

  return {
    release: version,
    product: process.env.VUE_APP_PRODUCT,
    skin: process.env.VUE_APP_SKIN,
    platform: process.env.VUE_APP_PLATFORM,
    layout: process.env.VUE_APP_LAYOUT,
    modernity: process.env.VUE_APP_MODERNITY,
    parameters: {
      ...sentryOptions,
      tracesSampleRate: sentryOptions.browserTracingEnabled && sentryOptions.tracesSampleRate
        ? Number(sentryOptions.tracesSampleRate)
        : undefined,
    },
    ignoredErrors,
    consoleLogLevels: errorHandlerSettings?.consoleLogLevels ?? [],
    // https://docs.sentry.io/platforms/javascript/configuration/filtering/
    denyUrls: [
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i,
    ],
    browserTracingEnabled: !!errorHandlerSettings?.browserTracingEnabled,
    sessionReplayEnabled: !!errorHandlerSettings?.sessionReplayEnabled,
    profilingEnabled: !!errorHandlerSettings?.profilingEnabled,
  };
};
