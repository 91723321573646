import useCordovaPushNotificationsStore
  from 'web/src/modules/push-notifications/store/useCordovaPushNotificationsStore';
import useWebPushNotificationsStore
  from 'web/src/modules/push-notifications/store/useWebPushNotificationsStore';

export default function usePushNotificationsConfigSyncState(): Promise<void> {
  if (process.env.VUE_APP_PLATFORM_CORDOVA) {
    return useCordovaPushNotificationsStore().syncConfig();
  }
  return useWebPushNotificationsStore().syncConfig();
}
