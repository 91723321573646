import { isNullOrUndefined, isObject } from '@leon-hub/guards';
import { Json } from '@leon-hub/utils';

import type { ComponentKey, ComponentResult } from './types';
import { ComponentStatus } from './types';

const theMaximumRecursionDepth = 6;
const stringJoinerSeparator = '~~~';

export default abstract class AbstractFingerprintComponent {
  public readonly key: ComponentKey;

  abstract getComponentResult(): Promise<ComponentResult>;

  protected constructor(key: ComponentKey) {
    this.key = key;
  }

  public result(value: unknown | unknown[]): Promise<ComponentResult> {
    let result = '';

    if (Array.isArray(value)) {
      result = value.flat(theMaximumRecursionDepth).join(stringJoinerSeparator);
    } else if (typeof value === 'string' && value.length > 0) {
      result = value;
    } else if (!isNullOrUndefined(value)) {
      result = String(value);
    } else if (isObject(value)) {
      const stringifiedResult = Json.stringify(value, {
        circular: true,
        circularPlaceholder: '[Circular]',
        defaultValue: ComponentStatus.NoData,
      });
      result = isNullOrUndefined(stringifiedResult) ? ComponentStatus.NoData : stringifiedResult;
    } else {
      result = ComponentStatus.NoData;
    }

    return Promise.resolve({
      key: this.key,
      value: result,
    });
  }
}
