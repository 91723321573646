/* eslint-disable @typescript-eslint/no-redeclare */

export const enum HeadlineSlideName {
  Info = 'info',
  LiveWidget = 'live-widget',
  LiveStream = 'live-stream',
}

export const enum HeadlineSlideType {
  OutrightInfo = 'OutrightInfo',
  LiveInfo = 'LiveInfo',
  PreMatchInfo = 'PreMatchInfo',
  LiveWidget = 'LiveWidget',
  LiveStream = 'LiveStream',
}

export const HeadlineExtendedHeight = {
  VirtualCricketWidget: 'virtual-cricket',
  BetGeniusWidget: 'bet-genius',
} as const;

export type HeadlineExtendedHeight = typeof HeadlineExtendedHeight[keyof typeof HeadlineExtendedHeight];

export const enum MetrikaCategory {
  SWIPER_NAVIGATION = 'SportEventDetailsHeadlineMobile',
  /** @see LEONWEB-13161 */
  BUTTONS_NAVIGATION = 'SportEventDetailsHeadline',
}

export const enum MetrikaEvents {
  EVENT_BUTTONS = 'button',
  EVENT_KNOBS = 'knobs',
  EVENT_SWIPE = 'swipe',
  EVENTS_CAROUSEL = 'carousel',
  EVENTS_CHANGE_SLIDE = 'changeSlide',
  EVENTS_CLICKS = 'clicks',
  EVENTS_IS_AVAILABLE = 'isAvailable',
}

export const enum MetrikaHeadlineNavigationType {
  All = 'all',
  Stream = 'stream',
  Widget = 'widget',
}
