export const enum PopperPosition {
  AUTO = 'auto',
  AUTO_START = 'auto-start',
  AUTO_END = 'auto-end',
  TOP = 'top',
  BOTTOM = 'bottom',
  RIGHT = 'right',
  LEFT = 'left',
  TOP_START = 'top-start',
  TOP_END = 'top-end',
  BOTTOM_START = 'bottom-start',
  BOTTOM_END = 'bottom-end',
  RIGHT_START = 'right-start',
  RIGHT_END = 'right-end',
  LEFT_START = 'left-start',
  LEFT_END = 'left-end',
}

export const enum PopperApplyTarget {
  Body = 'body',
  Content = '.popper-content-target',
}
