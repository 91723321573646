import type { CompetitorsRolesInfo } from 'web/src/modules/sportline/types';

export function scoreDetailsToString(score?: CompetitorsRolesInfo<string[]>): string {
  if (!score) { return ''; }

  const length = Math.max(score.host.length || 0, score.guest.length || 0);

  return Array.from({ length }, (_, index) => index)
    .map((index) => `${score.host[index] || 0}:${score.guest[index] || 0}`)
    .join('; ');
}
