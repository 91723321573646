import { HintBlockKind } from 'web/src/components/HintBlock/VHintBlock/enums';

import type { VHintBlockProps } from '../../types/VHintBlockProps';

const baseClass = 'hint-block';
export default function getBaseClassNames(
  props: Pick<VHintBlockProps, 'isCentered' | 'hasIcon' | 'kind'>,
  hasTitleSlot: boolean,
): string[] {
  const classNames = [baseClass];

  if (props.isCentered) {
    classNames.push(`${baseClass}--centered`);
  }

  if (hasTitleSlot) {
    classNames.push(`${baseClass}--titled`);
  }

  if (!props.hasIcon) {
    classNames.push(`${baseClass}--no-icon`);
  }

  if (props.kind !== HintBlockKind.DEFAULT) {
    classNames.push(`${baseClass}--kind-${props.kind}`);
  }

  return classNames;
}
