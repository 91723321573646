import type { Ref } from 'vue';
import type { RouteLocationRaw } from 'vue-router';
import { computed, watch } from 'vue';

import { useI18n } from 'web/src/modules/i18n/composables';
import { useSportlineAnalytics } from 'web/src/modules/sportline/composables/analytics';
import { useInnerActiveTabId } from 'web/src/modules/sportline/submodules/sidebar/composables';
import type { SportsTreeSportElement } from 'web/src/modules/sportline/types/rest';
import type {
  CustomerSportListElement,
} from 'web/src/modules/sportline/submodules/favorites/types';
import type {
  FilterTab,
  ActiveTopLeagueListElement,
  SidebarFilterTabId,
} from 'web/src/modules/sportline/submodules/sidebar/types';
import type { TabsItem } from 'web/src/components/Tabs';
import { CustomFilter } from 'web/src/modules/sportline/enums';

import {
  createFilterTabForSportElement,
  createFilterTabForZeroMargin,
  createFilterTabForFavoriteLeagues,
} from '../../utils';

interface UseSportsSidebarTopLeaguesProps {
  sports: Ref<Maybe<SportsTreeSportElement[]>>;
  favoritesSports: Ref<Maybe<CustomerSportListElement[]> | Readonly<CustomerSportListElement[]>>;
  maxTabsCount: Ref<number>;
  maxInList: Ref<number>;
  defaultActiveSportId: Ref<Maybe<string>>;
}

interface UseSportsSidebarTopLeaguesComposable {
  tabs: Ref<TabsItem[]>;
  isAvailable: Ref<boolean>;
  activeTabId: Ref<Optional<SidebarFilterTabId>>;
  activeFilterTabLeaguesList: Ref<ActiveTopLeagueListElement[]>;
  activeFilterTabLeaguesListSize: Ref<number>;
  activeFilterTabAllLeaguesPageLocation: Ref<Maybe<RouteLocationRaw>>;
  allLeaguesTitle: Ref<string>;
  activeFilterTabTotalCount: Ref<number>;
  onActivateTabClick(to: SidebarFilterTabId): void;
}

export function useSportsSidebarTopLeagues(
  props: UseSportsSidebarTopLeaguesProps,
): UseSportsSidebarTopLeaguesComposable {
  const {
    sports,
    favoritesSports,
    maxTabsCount,
    maxInList,
    defaultActiveSportId,
  } = props;

  const { $translate } = useI18n();
  const analytics = useSportlineAnalytics();

  const { innerActiveTabId, setInnerActiveTab } = useInnerActiveTabId();

  const allFilterTabs = computed<FilterTab[]>(() => {
    const result: FilterTab[] = [];

    const sportElements = sports.value ?? [];

    const isActiveFavoriteLeagues = innerActiveTabId.value === CustomFilter.MyFavoriteLeagues;
    const favoriteLeaguesTab = createFilterTabForFavoriteLeagues(favoritesSports.value ?? [], isActiveFavoriteLeagues);
    if (favoriteLeaguesTab) { result.push(favoriteLeaguesTab); }

    const isActiveZeroMargin = innerActiveTabId.value === CustomFilter.ZeroMargin;
    const zeroMarginTab = createFilterTabForZeroMargin(sportElements, isActiveZeroMargin);
    if (zeroMarginTab) { result.push(zeroMarginTab); }

    for (const [index, sportElement] of (sportElements).entries()) {
      const isActiveElement = innerActiveTabId.value ? sportElement.sport.id === innerActiveTabId.value : index === 0;
      const tab = createFilterTabForSportElement(sportElement, isActiveElement);
      if (tab) { result.push(tab); }
    }

    return result;
  });

  const tabs = computed<TabsItem[]>(() => allFilterTabs.value
    .map((filterTab: FilterTab): TabsItem => filterTab.tabItem)
    .slice(0, maxTabsCount.value));
  const isAvailable = computed<boolean>(() => !!tabs.value.length);
  const activeFilterTab = computed<Maybe<FilterTab>>(() => allFilterTabs.value
    .find((tab) => tab.isActive)
    ?? allFilterTabs.value[0]
    ?? null);
  const activeTabId = computed<Optional<SidebarFilterTabId>>(() => activeFilterTab.value?.key ?? undefined);
  const activeFilterTabTotalCount = computed<number>(() => activeFilterTab.value?.totalCount ?? 0);
  const allLeaguesTitle = computed<string>(() => (
    activeTabId.value === CustomFilter.MyFavoriteLeagues
      ? $translate('JSP_MY_LEAGUES').value
      : $translate('WEB2_ALL_LEAGUES').value
  ));
  const activeFilterTabLeaguesList = computed<ActiveTopLeagueListElement[]>(() => (
    (activeFilterTab.value?.leagues ?? []).slice(0, maxInList.value)
  ));
  const activeFilterTabAllLeaguesPageLocation = computed<Maybe<RouteLocationRaw>>(() => (
    activeFilterTab.value?.allLeaguesLocation ?? null
  ));
  const activeFilterTabLeaguesListSize = computed<number>(() => (
    (activeFilterTab.value?.leagues ?? []).length
  ));

  function onActivateTabClick(to: SidebarFilterTabId): void {
    setInnerActiveTab(to);

    if (to as CustomFilter === CustomFilter.ZeroMargin) {
      analytics.zeroMarginSidebarTabClick();
    }
  }

  watch(defaultActiveSportId, (value) => {
    setInnerActiveTab(value);
  }, { immediate: true });

  return {
    tabs,
    isAvailable,
    activeTabId,
    activeFilterTabLeaguesList,
    activeFilterTabLeaguesListSize,
    activeFilterTabAllLeaguesPageLocation,
    allLeaguesTitle,
    activeFilterTabTotalCount,
    onActivateTabClick,
  };
}
