<script lang="ts" setup>
import { toRef } from 'vue';

import { IconName, IconSize } from '@leon-hub/icons';

import { VIcon } from '@components/v-icon';

import { HeadlineSlideName } from 'web/src/modules/sportline/submodules/event-details/enums';

import { useWidgetSwitcher } from './useWidgetSwitcher';
import type { WidgetSwitcherProps, WidgetSwitcherEmits } from './types';

const props = defineProps<WidgetSwitcherProps>();
const emit = defineEmits<WidgetSwitcherEmits>();

const slideIds = toRef(props, 'slideIds');
const activeSlideId = toRef(props, 'activeSlideId');

function emitSetActiveSlide(value: HeadlineSlideName): void {
  emit('update:activeSlideId', value);
}

const {
  isStreamAvailable,
  isLiveWidgetAvailable,
  setActiveSlide,
} = useWidgetSwitcher({ slideIds, activeSlideId, emitSetActiveSlide });
</script>

<template>
  <button v-auto-id="'PinWidgetSwitcher'"
    v-if="isStreamAvailable && isLiveWidgetAvailable"
    type="button"
    :class="$style['pin-widget-switcher']"
    @click="setActiveSlide(activeSlideId === HeadlineSlideName.LiveStream ? HeadlineSlideName.LiveWidget : HeadlineSlideName.LiveStream)"
  >
    <VIcon
      v-if="activeSlideId === HeadlineSlideName.LiveStream"
      :name="IconName.FIELD"
      :size="IconSize.SIZE_16"
    />
    <VIcon
      v-else
      :name="IconName.STREAM"
      :size="IconSize.SIZE_16"
    />
  </button>
</template>

<style module lang="scss">
.pin-widget-switcher {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  padding: 0;
  color: var(--White);
  cursor: pointer;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 5px;

  @include for-hover {
    &:hover {
      background: var(--Black);
    }
  }
}
</style>
