import type { MetricsToolConfigParsed } from '@leon-hub/yandex-metrika';

import type { MetricsToolsConfig } from '../types';

const deserializeNumber = (value: string): number | string => {
  const number = parseInt(value, 10);
  return Number.isNaN(number) ? value : number;
};

const deserializeBoolean = (value: string): boolean | string => (
  value === 'false' || value === 'true' ? JSON.parse(value) : value
);

const deserializeValue = (value: string) => {
  const result = deserializeNumber(value);
  return typeof result === 'string' ? deserializeBoolean(result) : result;
};

export default function parseMetricsToolConfig(
  config: MetricsToolsConfig | undefined,
): MetricsToolConfigParsed {
  const result: MetricsToolConfigParsed = {};

  for (const { key, value } of config || []) {
    result[key] = deserializeValue(value);
  }

  return result;
}
