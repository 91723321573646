<script setup lang="ts">
import { toRef, watch, computed } from 'vue';

import { onComponentDeactivated } from '@leon-hub/vue-utils';

import type { StreamWidgetProps, StreamWidgetEmits } from 'web/src/modules/sportline/submodules/widgets/types';
import { VLazyIframe as VIframe } from 'web/src/components/Iframe';
import { VLoaderDelayed } from 'web/src/components/Loader';
import { IframeWidget } from 'web/src/modules/framed-app/enums';

import WidgetWrapper from './WidgetWrapper.vue';
import PleaseLoginWidget from './PleaseLoginWidget.vue';
import StreamErrorWidget from '../components/StreamErrorWidget.vue';

const props = withDefaults(defineProps<StreamWidgetProps>(), {
  sportlineEventId: null,
  isActive: false,
  doShowStreamPreviewInfo: false,
  // @TODO: blank.html must be aggressively cached on the clients.
  streamUrl: `${'/'}blank.html`,
});
const emit = defineEmits<StreamWidgetEmits>();

const sportlineEventId = toRef(() => props.sportlineEventId);
const doShowStreamPreviewInfo = toRef(() => props.doShowStreamPreviewInfo);
const streamUrl = toRef(() => props.streamUrl);
const isActive = toRef(() => props.isActive);

const isStreamShown = computed<boolean>(() => (
  !!sportlineEventId.value
    && isActive.value
    && !!streamUrl.value
    && !doShowStreamPreviewInfo.value
));

watch([sportlineEventId, isActive], ([id, value]) => {
  if (!id || !value) { return; }
  emit('stream-setup');
}, { immediate: true });

watch(isStreamShown, (value) => {
  if (!value) { return; }
  // @see LEONWEB-14247 save preview info after first time stream was shown
  emit('stream-started');
}, { immediate: true });

onComponentDeactivated(() => {
  emit('stream-release');
});
</script>

<template>
  <div v-auto-id="'StreamWidget'" :class="$style['live-stream-slide']">
    <template v-if="isActive">
      <PleaseLoginWidget
        v-if="doShowStreamPreviewInfo"
      />
      <StreamErrorWidget
        v-else-if="streamUrl === false"
        :stream-error="streamError"
      />
      <WidgetWrapper
        v-else-if="streamUrl"
        :class="$style['live-stream-slide__content']"
        :is-floating-mode-allowed="isFloatingModeAllowed"
        :is-floating-mode-enabled="isFloatingModeEnabled"
        is-stream
      >
        <VIframe
          :key="streamUrl"
          :src="streamUrl"
          full-height
          full-width
          :name="IframeWidget.LiveStream"
          frameborder="0"
          :allowfullscreen="!'1'"
        />
      </WidgetWrapper>
      <div
        v-else
        :class="$style['live-stream-slide__loader']"
      >
        <VLoaderDelayed />
      </div>
    </template>
  </div>
</template>

<style module lang="scss">
@include for-layout using ($isDesktop) {
  .live-stream-slide {
    display: flex;
    align-items: stretch;
    justify-content: center;
    width: 100%;
    height: if($isEnvPlatformCordova, 200px, 100%);

    &__content {
      width: 100%;
    }

    &__loader {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
  }
}
</style>
