import type { Ref } from 'vue';
import { toRef, computed } from 'vue';
import type { RouteLocationRaw } from 'vue-router';

import { useI18n } from 'web/src/modules/i18n/composables';
import { useSportlineBreadcrumbsStore } from 'web/src/modules/sportline/submodules/breadcrumbs/store';
import type { SportsTreeLeagueElement } from 'web/src/modules/sportline/types/rest';
import type { League } from 'web/src/modules/sportline/types';
import { resolveLeaguePageLink } from 'web/src/modules/sportline/submodules/navigation/store/utils';

interface UseSportlineBreadcrumbsLeagueItemProps {
  league?: Ref<Maybe<League>>;
}

interface UseSportlineBreadcrumbsLeagueItemComposable {
  isShown: Ref<boolean>;
  title: Ref<string>;
  leagueElement: Ref<Maybe<SportsTreeLeagueElement>>;
  relatedLeagues: Ref<SportsTreeLeagueElement[]>;
  pageLocation: Ref<Optional<RouteLocationRaw>>;
}

export function useSportlineBreadcrumbsLeagueItem(
  props: UseSportlineBreadcrumbsLeagueItemProps,
): UseSportlineBreadcrumbsLeagueItemComposable {
  const defaultLeague = toRef(props.league);

  const { $translate } = useI18n();
  const sportlineBreadcrumbsStore = useSportlineBreadcrumbsStore();
  const leagueElement = toRef(sportlineBreadcrumbsStore, 'leagueElement');
  const relatedLeagues = toRef(sportlineBreadcrumbsStore, 'relatedLeagues');

  const league = computed(() => (defaultLeague.value ?? leagueElement.value?.league ?? null));

  const isShown = computed(() => (
    !!league.value || relatedLeagues.value.length > 0
  ));
  const title = computed(() => (
    league.value?.name || $translate('JSP_SPORT_NAV_DEFAULT_LEAGUE').value
  ));
  const pageLocation = computed<Optional<RouteLocationRaw>>(() => (
    league.value ? resolveLeaguePageLink(league.value.navigationParameters) : undefined
  ));

  return {
    isShown,
    title,
    leagueElement,
    relatedLeagues,
    pageLocation,
  };
}
