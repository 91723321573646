<script lang="ts" setup>
import VSwitch from 'web/src/components/Switch/VSwitch/VSwitch.vue';

import useFormSwitchEvents from './useFormSwitchEvents';
import useFormSwitch from './useFormSwitch';
import type {
  FormWrapperWidgetProperties,
  BaseFormWidgetEmits,
} from '../../types';

const props = defineProps<FormWrapperWidgetProperties>();
const emit = defineEmits<BaseFormWidgetEmits>();

const {
  emitChange,
  emitInput,
  emitFocus,
  emitBlur,
} = useFormSwitchEvents(emit, props.name);

const { componentProps } = useFormSwitch(props);
</script>

<template>
  <VSwitch v-auto-id="'FormSwitch'"
    v-bind="componentProps"
    @input="emitInput"
    @change="emitChange"
    @focus="emitFocus"
    @blur="emitBlur"
  />
</template>
