<script lang="ts" setup>
import { useVSwiperSlide } from './composables';
import type { SwiperSlideRef } from './types';

interface SwiperSlideProps {
  originalSlidesIndex?: number;
}

interface SwiperSlideEmits {
  (e: 'click', event: Event): void;
}

const props = defineProps<SwiperSlideProps>();
const emit = defineEmits<SwiperSlideEmits>();

const {
  slide,
  isVisible,
  isOverflowVisible,
  setVisibility,
  getOffsetWidth,
  getOffsetLeft,
} = useVSwiperSlide();

function onClick(event: Event): void {
  emit('click', event);
}

defineExpose<SwiperSlideRef>({
  originalSlidesIndex: props.originalSlidesIndex,
  setVisibility,
  getOffsetWidth,
  getOffsetLeft,
});

defineOptions({
  name: 'VSwiperSlide',
});
</script>

<template>
  <div v-auto-id="'VSwiperSlide'"
    ref="slide"
    :class="{
      'swiper-slide': true,
      [$style['swiper-slide']]: true,
      [$style['swiper-slide--hidden']]: !process.env.VUE_APP_PRERENDER && !isVisible,
    }"
    @click="onClick"
  >
    <div
      v-if="'1' && isOverflowVisible"
      :class="$style['swiper-slide__overflow']"
    />
    <slot />
  </div>
</template>

<style module lang="scss">
.swiper-slide {
  position: relative;
  flex-shrink: 0;
  transform: translateZ(0);
  backface-visibility: hidden;

  :global(a),
  :global(img),
  :global(div) {
    user-select: none;
    -webkit-user-drag: none;
  }

  &--hidden {
    visibility: hidden;
  }

  &--full-width {
    width: 100%;
  }

  &__overflow {
    @include z-index(swiper-slide);

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: transparent;
  }
}
</style>
