<script lang="ts" setup>
import { TextInput } from 'web/src/components/Input';
import { TextInputTypes } from 'web/src/components/Input/enums';

import { useBaseFormWidgetEvents, useCommonFormWidgetProps } from '../../composables';
import type {
  FormWrapperWidgetProperties,
  BaseFormWidgetEmits,
} from '../../types';

/**
 * TODO: check is it was ever used, seems not
 * */

const props = defineProps<FormWrapperWidgetProperties>();
const emit = defineEmits<BaseFormWidgetEmits>();

const {
  emitChange,
  emitInput,
  emitFocus,
  emitBlur,
} = useBaseFormWidgetEvents(emit);

const { componentProps } = useCommonFormWidgetProps(props, TextInputTypes.HIDDEN);
</script>

<template>
  <TextInput v-auto-id="'FormHidden'"
    v-bind="componentProps"
    @input="emitInput"
    @change="emitChange"
    @focus="emitFocus"
    @blur="emitBlur"
  />
</template>
