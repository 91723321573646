import type { Ref } from 'vue';
import { computed, toRef } from 'vue';

import { useI18n } from 'web/src/modules/i18n/composables';
import { useI18nStore } from 'web/src/modules/i18n/store';
import type { SportElementHeadlineExpandState } from 'web/src/modules/sportline/types/list';
import LanguageMicroSettings from 'web/src/utils/LanguageMicroSettings';

interface UseExpandableHeadlineProps {
  expandKey: Ref<Maybe<string>>;
  isExpanded: Ref<boolean>;
  countEvents: Ref<number>;
}

interface UseExpandableHeadlineEmits {
  (e: 'toggle-expand-state', payload: SportElementHeadlineExpandState): void;
}

interface UseExpandableHeadlineComposable {
  isExpandable: Ref<boolean>;
  eventsCountLabel: Ref<string>;
  isShowEventsCounters: Ref<boolean>;
  toggleExpandState(): void;
}

export function useExpandableHeadline(
  props: UseExpandableHeadlineProps,
  emit: UseExpandableHeadlineEmits,
): UseExpandableHeadlineComposable {
  const {
    expandKey,
    isExpanded,
    countEvents,
  } = props;

  const { $translate } = useI18n();
  const locale = toRef(useI18nStore(), 'locale');

  const eventsCountOne = $translate('WEB2_SPORT_EVENTS_COUNT_ONE');
  const eventsCountTwo = $translate('WEB2_SPORT_EVENTS_COUNT_TWO');
  const eventsCountFew = $translate('WEB2_SPORT_EVENTS_COUNT_FEW');

  const isExpandable = computed<boolean>(() => expandKey.value !== null);
  const eventsCountLabel = computed<string>(() => LanguageMicroSettings.plural([
    eventsCountOne.value,
    eventsCountTwo.value,
    eventsCountFew.value,
  ], countEvents.value, locale.value));
  const isShowEventsCounters = computed<boolean>(() => (
    isExpandable.value
    && !isExpanded.value
    && !!countEvents.value
  ));

  function toggleExpandState(): void {
    if (!isExpandable.value) { return; }
    emit('toggle-expand-state', { value: !isExpanded.value });
  }

  return {
    isExpandable,
    eventsCountLabel,
    isShowEventsCounters,
    toggleExpandState,
  };
}
