import type { Ref } from 'vue';
import { watch } from 'vue';

interface UseNeedRedirectFromTabProps {
  isNeedRedirectFromTab: Ref<boolean>;
}

interface UseNeedRedirectFromTabEmits {
  emitRedirectFromTab(): void;
}

export function useNeedRedirectFromTab(
  props: UseNeedRedirectFromTabProps,
  emits: UseNeedRedirectFromTabEmits,
): void {
  const { isNeedRedirectFromTab } = props;
  const { emitRedirectFromTab } = emits;

  watch(isNeedRedirectFromTab, (value) => {
    if (value) { emitRedirectFromTab(); }
  });
}
