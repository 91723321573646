export enum CustomerNotificationMessageSource {
  CMS = 'cms',
  FAST_TRACK = 'fast-track',
  ALCR = 'alcr',
  REF = 'ref',
  TEXT = 'text',
}

export enum NotificationsType {
  ALL = 'all',
  UNREAD = 'unread',
  READ = 'read',
}
