import type {
  Ref,
  StyleValue,
} from 'vue';
import {
  computed,
  ref,
} from 'vue';

import type {
  ImageSource,
  VImageRef,
} from 'web/src/components/Image/types';
import useRecalculateImageSource from 'web/src/components/Image/composables/useRecalculateImageSource';

import type { ObjectPosition } from '../enums';
import {
  ObjectFitOption,
} from '../enums';

export interface VImageComposable {
  lazyImage: Ref<VImageRef | undefined>;
  generatedSrc: Ref<string>;
  prepareImageStyles: Ref<StyleValue>;
  forceShowImage: () => void;
  created: () => Promise<void>;
}

export default function useVImage(props: {
  src: Ref<string>;
  x1: Ref<Optional<Maybe<string>>>;
  x2: Ref<Optional<Maybe<string>>>;
  x3: Ref<Optional<Maybe<string>>>;
  x1webp: Ref<Optional<Maybe<string>>>;
  x2webp: Ref<Optional<Maybe<string>>>;
  x3webp: Ref<Optional<Maybe<string>>>;
  objectFit: Ref<Optional<ObjectFitOption>>;
  objectPosition: Ref<Optional<ObjectPosition>>;
}): VImageComposable {
  const {
    src,
    x1,
    x2,
    x3,
    x1webp,
    x2webp,
    x3webp,
    objectFit,
    objectPosition,
  } = props;

  const lazyImage = ref<VImageRef>();

  const imageSource: Ref<ImageSource> = computed(() => ({
    src: src.value,
    x1: x1.value,
    x2: x2.value,
    x3: x3.value,
    x1webp: x1webp.value,
    x2webp: x2webp.value,
    x3webp: x3webp.value,
  }));

  const prepareImageStyles: Ref<StyleValue> = computed(() => ({
    ...(objectFit.value === ObjectFitOption.FILL ? {} : { objectFit: objectFit.value }),
    objectPosition: objectPosition.value,
  }));

  const {
    normalizedSrc,
    normalizeSrc,
  } = useRecalculateImageSource({ imageSource });
  const generatedSrc = computed(() => normalizedSrc.value || '');

  function forceShowImage(): void {
    lazyImage.value?.forceShowImage();
  }

  return {
    lazyImage,
    generatedSrc,
    prepareImageStyles,
    forceShowImage,
    created: normalizeSrc,
  };
}
