import { defineStore } from 'pinia';
import { watch } from 'vue';

import {
  addFavoriteGame,
  removeFavoriteGame,
} from '@leon-hub/api-sdk';

import { useEgsGamesStore } from 'web/src/modules/egs/submodules/games/store';
import { useLobbyStore } from 'web/src/modules/lobby/store';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import type { EgsGame } from 'web/src/modules/egs/types';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';

const useEgsFavoriteGamesStore = defineStore('egs-favorite-games', () => {
  const { isLoggedIn } = useIsLoggedIn();
  const api = useGraphqlClient();

  const gamesStore = useEgsGamesStore();
  const lobbyStore = useLobbyStore();
  let favoriteGamesToAdd: string[] = [];

  async function addFavoriteGames(ids: string[]): Promise<void> {
    if (!isLoggedIn.value) {
      favoriteGamesToAdd.push(...ids);
      return;
    }

    gamesStore.setFavorite(ids, true);
    lobbyStore.setFavorite(ids, true);

    try {
      await addFavoriteGame(api,
        (node) => node.mutations.externalGames.favoriteGames.add,
        {
          options: {
            ids,
          },
        });
    } catch (error) {
      gamesStore.setFavorite(ids, false);
      lobbyStore.setFavorite(ids, false);

      throw error;
    }
  }

  async function removeFavoriteGames(ids: string[]): Promise<void> {
    gamesStore.setFavorite(ids, false);
    lobbyStore.setFavorite(ids, false);

    try {
      await removeFavoriteGame(api,
        (node) => node.mutations.externalGames.favoriteGames.remove,
        {
          options: {
            ids,
          },
        });
    } catch (error) {
      gamesStore.setFavorite(ids, true);
      lobbyStore.setFavorite(ids, true);

      throw error;
    }
  }

  async function toggleFavoriteGame(game: EgsGame): Promise<void> {
    if (game.isFavorite) {
      await removeFavoriteGames([game.id]);
    } else {
      await addFavoriteGames([game.id]);
    }
  }

  watch(isLoggedIn, (newValue) => {
    if (newValue && favoriteGamesToAdd.length) {
      addFavoriteGames(favoriteGamesToAdd);
      favoriteGamesToAdd = [];
    }
  });

  return {
    addFavoriteGames,
    removeFavoriteGames,
    toggleFavoriteGame,
  };
});

export default useEgsFavoriteGamesStore;
