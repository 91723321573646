import type { Ref } from 'vue';
import { nextTick, onMounted, ref } from 'vue';

import { normalizeError } from '@leon-hub/errors';
import { logger } from '@leon-hub/logging';

import type { VAnimationStarterProps } from '../types';

export interface VAnimationStarterComposable {
  animationContainer: Ref<HTMLElement | undefined>;
  isAnimated: Ref<boolean>;
  observe: () => void;
}

export default function useVAnimationStarter(props: VAnimationStarterProps): VAnimationStarterComposable {
  const animationContainer = ref<HTMLElement>();
  const isAnimated = ref(false);
  let intersectionObserver: IntersectionObserver;
  let previousY = 0;
  let previousRatio = 0;

  function handleIntersect(entries: IntersectionObserverEntry[]) {
    const target = entries[0];

    if (!target) return;

    const { isIntersecting } = target;
    const topBound = target.boundingClientRect.top;
    const currentY = target.boundingClientRect.y;
    const currentRatio = target.intersectionRatio;

    isAnimated.value = topBound > 0 ? isIntersecting : true;

    if (currentY > previousY && currentRatio < previousRatio && !isIntersecting) {
      isAnimated.value = true;
    }

    previousY = currentY;
    previousRatio = currentRatio;
  }

  function observe(): void {
    if (!animationContainer.value) return;
    try {
      const options = {
        rootMargin: props.rootMargin,
        threshold: props.rootMargin === '' ? props.threshold : 0,
      };

      intersectionObserver = new IntersectionObserver(handleIntersect, options);
      intersectionObserver.observe(animationContainer.value);
    } catch (rawError) {
      const error = normalizeError(rawError);

      logger.error(error);
    }
  }

  onMounted(() => {
    void nextTick(() => {
      requestAnimationFrame(() => {
        setTimeout(() => {
          observe();
        }, 1000);
      });
    });
  });

  return {
    animationContainer,
    isAnimated,
    observe,
  };
}
