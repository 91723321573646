/* eslint-disable class-methods-use-this */
import type { RouteLocationNormalized } from 'vue-router';

import { assert, isOptionalString } from '@leon-hub/guards';

import { findSelectedVirtualSportFilterOption } from 'web/src/modules/sportline/submodules/virtual-sport/utils';
import type { AppVueRouter } from 'web/src/modules/core/services/router/types';
import type { GetRouteSeoConfig } from 'web/src/modules/core/services/router/seo';
import DefaultPageSeoController from 'web/src/modules/seo/DefaultPageSeoController';
import { useVirtualSportStore } from 'web/src/modules/sportline/submodules/virtual-sport/store';
import type { VirtualSportSelectedFilter } from 'web/src/modules/sportline/submodules/virtual-sport/types';
import findSelectedByDefaultVirtualSportFilterOption
  from 'web/src/modules/sportline/submodules/virtual-sport/utils/findSelectedByDefaultVirtualSportFilterOption';

export default class VirtualSportPageSeoController extends DefaultPageSeoController {
  getSeoConfig(
    router: AppVueRouter,
    from: RouteLocationNormalized,
    to: RouteLocationNormalized,
  ): Promise<GetRouteSeoConfig> {
    return new Promise<GetRouteSeoConfig>((resolve) => {
      const virtualSportStore = useVirtualSportStore();

      assert(isOptionalString(to.params.sport));
      const filter: VirtualSportSelectedFilter = { urlName: to.params.sport };
      const optionForNavigation = findSelectedVirtualSportFilterOption(virtualSportStore.filterOptions, filter)
        || findSelectedByDefaultVirtualSportFilterOption(virtualSportStore.filterOptions);

      if (optionForNavigation) {
        const params = {
          ...(to.params || {}),
          id: optionForNavigation.id as string,
          sport: optionForNavigation.sport as string,
        };

        resolve({ ...to, params });
      }
    });
  }
}
