import type { CoreMarketResponse } from 'web/src/modules/sportline/types/rest';
import { SportEventStatus } from 'web/src/modules/sportline/enums';

export default function extractMarketStatus(
  eventStatus: SportEventStatus,
  marketResponse: CoreMarketResponse,
): SportEventStatus {
  if (eventStatus === SportEventStatus.Suspended) {
    return SportEventStatus.Suspended;
  }
  return marketResponse.open ? SportEventStatus.Open : SportEventStatus.Suspended;
}
