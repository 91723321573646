import type { EntryWithChangedStatus, SlipEntry } from '../types';

export default function sortSlipEntries(
  a: SlipEntry | EntryWithChangedStatus,
  b: SlipEntry | EntryWithChangedStatus,
): number {
  if (!a.event || !b.event || a.event === b.event) {
    return 0;
  }
  if (a.event < b.event) {
    return -1;
  }
  return 1;
}
