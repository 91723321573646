import { EditionKey } from '@leon-hub/environment-editions';
import { getUserAgent } from '@leon-hub/service-locator-env';

import { setRuntimeEditionEnvVar } from './setRuntimeEditionEnvVar';
import DeviceMeta from '../DeviceMeta';

export function createRuntimeEnvironment(): NodeJS.ProcessEnv {
  if (!process.env.VUE_APP_PLATFORM_CORDOVA) {
    const userAgent = getUserAgent();
    let env = {};
    env = setRuntimeEditionEnvVar(env, EditionKey.LAYOUT, DeviceMeta.getLayout(userAgent));
    env = setRuntimeEditionEnvVar(env, EditionKey.BROWSER, DeviceMeta.getBrowser(userAgent));
    env = setRuntimeEditionEnvVar(env, EditionKey.OS, DeviceMeta.getOs(userAgent));
    return env;
  }
  return {};
}
