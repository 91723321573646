import { FormControlType } from '@leon-hub/form-utils';

import type { FormWidgetList } from 'web/src/components/Form/utils/widgetList';
import FormAsoCheckbox from 'web/src/modules/registration/components/RegistrationFormWidgets/FormAsoCheckbox/FormAsoCheckbox.vue';

const AsoTermsCheckbox = process.env.VUE_APP_FEATURE_ASO_ENABLED
  ? FormAsoCheckbox
  : () => false;

const completionRegistrationWidgets: FormWidgetList = {};

if (process.env.VUE_APP_FEATURE_ASO_ENABLED) {
  Object.assign(completionRegistrationWidgets, {
    [FormControlType.AsoTermsCheckbox]: AsoTermsCheckbox,
  });
}

export default completionRegistrationWidgets;
