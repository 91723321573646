import { doAchievementReward } from '@leon-hub/api-sdk';

import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import type { GetAchievementDataDocument } from 'web/src/modules/profile/submodules/achievements/types/AchievementsDocuments';

export default function doAchievementRewardRequest(id: number): Promise<GetAchievementDataDocument> {
  const apiClient = useGraphqlClient();

  return doAchievementReward(
    apiClient,
    (node) => node.mutations.achievements.reward,
    {
      options: {
        id,
      },
    },
  );
}
