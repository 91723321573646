import type { Ref } from 'vue';
import { computed } from 'vue';

interface UseTimeLeftComposable {
  distance: Ref<number>;
  daysLeft: Ref<number>;
  daysLeftPretty: Ref<string>;
  hoursLeft: Ref<number>;
  hoursLeftPretty: Ref<string>;
  minutesLeft: Ref<number>;
  minutesLeftPretty: Ref<string>;
  secondsLeft: Ref<number>;
  secondsLeftPretty: Ref<string>;
}

function useTimeLeftPretty(timeLeft: Ref<number>, options: {
  useLeadingZero?: Ref<boolean>;
} = {}): Ref<string> {
  const max = 99;
  return computed<string>(() => {
    const value = timeLeft.value < 0 ? 0 : timeLeft.value;
    if (max && value > max) { return `${max}`; }
    if (!options.useLeadingZero?.value) { return `${value}`; }
    return value < 10 ? `0${value}` : `${value}`;
  });
}

export default function useTimeLeft(
  targetTime: Ref<number>,
  now: Ref<number>,
  options?: { useLeadingZero?: Ref<boolean> },
): UseTimeLeftComposable {
  const distance = computed<number>(() => (targetTime.value > now.value
    ? targetTime.value - now.value
    : 0));

  const daysLeft = computed<number>(() => Math.floor(distance.value / (1000 * 60 * 60 * 24)));
  const daysLeftPretty = useTimeLeftPretty(daysLeft, options ?? {});

  const hoursLeft = computed<number>(() => Math.floor((distance.value / (1000 * 60 * 60)) % 24));
  const hoursLeftPretty = useTimeLeftPretty(hoursLeft, options ?? {});

  const minutesLeft = computed<number>(() => Math.floor((distance.value / (1000 * 60)) % 60));
  const minutesLeftPretty = useTimeLeftPretty(minutesLeft, options ?? {});

  const secondsLeft = computed<number>(() => Math.floor((distance.value / (1000)) % 60));
  const secondsLeftPretty = useTimeLeftPretty(secondsLeft, options ?? {});

  return {
    distance,
    daysLeft,
    daysLeftPretty,
    hoursLeft,
    hoursLeftPretty,
    minutesLeft,
    minutesLeftPretty,
    secondsLeft,
    secondsLeftPretty,
  };
}
