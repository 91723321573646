import type { VFeedbackCompositionProps } from 'web/src/components/FeedbackComposition/VFeedbackComposition/types';
import type { I18nFunctionReactive } from 'web/src/modules/i18n/types';

export default function getEmailFieldError(
  props: VFeedbackCompositionProps,
  emailValue: string,
  isEmailFieldError: boolean,
  $t: I18nFunctionReactive,
): string {
  if (!isEmailFieldError && props.isApiErrorEmailMessage && emailValue === props.savedErrorDuplicateEmail) {
    return props.isApiErrorEmailMessage;
  }

  if (!isEmailFieldError) {
    return '';
  }

  return $t('WEB2_FORM_ERROR_PATTERN_DEFAULT').value.replace('%field%', $t('WEB2_RATE_PROMOTION_FEEDBACK_EMAIL').value);
}
