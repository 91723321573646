import type { Ref, MaybeRef } from 'vue';
import { computed, toRef } from 'vue';

import type {
  Sport,
  Market,
  MarketsColumn,
  MarketType,
  ColumnInfo,
  RunnerLike,
} from 'web/src/modules/sportline/types';
import {
  defaultSportsMarketColumns,
  defaultMarketColumnsOptions,
} from 'web/src/modules/sportline/constants';
import {
  selectMarketTypeByColumn,
  placeRunnersToColumns,
} from 'web/src/modules/sportline/utils/markets';

interface MarketColumnsProps {
  sport: Ref<Maybe<Sport>>;
  markets: Ref<Market[]>;
  selectedColumnSize: Ref<Maybe<number>>;
  selectedColumnId: Ref<Maybe<string>>;
  columns: Ref<MarketsColumn[]>;
  doUseFullAlternativeMarketsList?: MaybeRef<boolean>;
}

interface MarketColumnsComposable {
  actualMarket: Ref<Maybe<Market>>;
  actualMarketType: Ref<Maybe<MarketType>>;
  actualMarketTypePriority: Ref<number>;
  marketColumnsCount: Ref<number>;
  runnersPlaces: Ref<RunnerLike[]>;
}

export function useMarketColumns(props: MarketColumnsProps): MarketColumnsComposable {
  const {
    sport,
    markets,
    selectedColumnSize,
    selectedColumnId,
    columns,
  } = props;
  const doUseFullAlternativeMarketsList = toRef(props.doUseFullAlternativeMarketsList ?? false);

  const actualMarketType = computed(() => selectMarketTypeByColumn({
    columns: columns.value,
    selectedColumnId: selectedColumnId.value,
    allMarketsTypes: markets.value.map((market) => market.type),
    doUseFullAlternativeMarketsList: doUseFullAlternativeMarketsList.value,
  }));
  const actualMarketTypePriority = computed(() => actualMarketType.value?.priority ?? 0);
  const actualMarket = computed<Maybe<Market>>(() => {
    const marketType = actualMarketType.value;
    if (!marketType) { return null; }
    return markets.value.find((market) => market.type.id === marketType.id) ?? null;
  });
  const marketColumnsCount = computed(() => {
    if (selectedColumnSize.value) {
      return selectedColumnSize.value;
    }

    const defaultSportColumnInfo: Optional<ColumnInfo> = sport.value
      ? defaultSportsMarketColumns[sport.value.representation.family]
      : undefined;

    return defaultSportColumnInfo?.size || 3;
  });
  const runnersPlaces = computed(() => placeRunnersToColumns({
    market: actualMarket.value,
    marketType: actualMarketType.value,
    sportFamily: sport.value?.representation.family,
  }, defaultMarketColumnsOptions));

  return {
    actualMarket,
    actualMarketType,
    actualMarketTypePriority,
    marketColumnsCount,
    runnersPlaces,
  };
}
