export default (baseList: HTMLElement, containerWidth: number): number => {
  const baseOffset = baseList.offsetLeft;
  let startIndex = 0;
  let endIndex = baseList.children.length - 1;
  let lastUnfitElementIndex = baseList.children.length;
  while (startIndex <= endIndex) {
    const middleIndex = startIndex + Math.floor((endIndex - startIndex) / 2);
    const currentItem = baseList.children[middleIndex] as HTMLElement;
    const leftEdgePosition = currentItem.offsetLeft - baseOffset;
    const rightEdgePosition = leftEdgePosition + currentItem.offsetWidth;

    if (leftEdgePosition < containerWidth && rightEdgePosition > containerWidth) {
      // partially hidden element - perfect match
      lastUnfitElementIndex = middleIndex;
      startIndex = endIndex;
      return lastUnfitElementIndex;
    }
    if (leftEdgePosition >= containerWidth) {
      // completely hidden
      lastUnfitElementIndex = middleIndex;
      endIndex = middleIndex - 1;
    } else {
      // visible - go to the right half
      startIndex = middleIndex + 1;
    }
  }
  return lastUnfitElementIndex;
};
