import type { Ref } from 'vue';
import { computed } from 'vue';

import type { BetlinePostMatchStatistics } from 'web/src/modules/sportline/types/rest';
import { SportFamily } from 'web/src/modules/sportline/enums';
import { isSportFamiliesIncludes } from 'web/src/modules/sportline/utils';

interface UsePostMatchStatisticResultsProps {
  postMatchStatistic: Ref<Maybe<BetlinePostMatchStatistics>>;
}

interface UsePostMatchStatisticResultsComposable {
  isDisplayTimeline: Ref<boolean>;
  isDisplayScoresByTimes: Ref<boolean>;
  isDisplayScoresBySets: Ref<boolean>;
}

export default function usePostMatchStatisticResults(
  props: UsePostMatchStatisticResultsProps,
): UsePostMatchStatisticResultsComposable {
  const isDisplayTimeline = computed<boolean>(() => isSportFamiliesIncludes([
    SportFamily.Soccer,
  ], props.postMatchStatistic.value?.family));
  const isDisplayScoresByTimes = computed<boolean>(() => isSportFamiliesIncludes([
    SportFamily.IceHockey,
    SportFamily.Basketball,
    SportFamily.TableTennis,
    SportFamily.Volleyball,
    SportFamily.Handball,
    SportFamily.Baseball,
  ], props.postMatchStatistic.value?.family));
  const isDisplayScoresBySets = computed<boolean>(() => isSportFamiliesIncludes([
    SportFamily.Tennis,
  ], props.postMatchStatistic.value?.family));

  return {
    isDisplayTimeline,
    isDisplayScoresByTimes,
    isDisplayScoresBySets,
  };
}
