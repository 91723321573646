<script lang="ts" setup>
import LobbyBlock from 'web/src/modules/lobby/components/LobbyBlock/LazyLobbyBlock';

import { useCasinoGameListContainer } from './composables';

const { lobbyBlockProps } = useCasinoGameListContainer();
</script>

<template>
  <LobbyBlock v-auto-id="'CasinoGameListContainer'" v-bind="lobbyBlockProps" />
</template>
