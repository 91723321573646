<script lang="ts" setup>
import {
  computed,
  toRef,
} from 'vue';

import { Timer } from '@leon-hub/utils';
import { CaptchaType } from '@leon-hub/api-sdk';
import { BusEvent, useEventsBus } from '@leon-hub/event-bus';

import { VLinearLoader } from '@components/loader';

import {
  DialogAction,
  DialogComponent,
} from 'web/src/modules/dialogs/enums';
import { useDialogsStore } from 'web/src/modules/dialogs/store';
import VCaptcha from 'web/src/modules/captcha/components/VCaptcha/VCaptcha.vue';
import VReCaptchaBadge from 'web/src/modules/captcha/components/VReCaptchaBadge/VReCaptchaBadge.vue';
import { ReCaptchaFramedWrapperState } from 'web/src/modules/captcha/components/VReCaptcha/enums';
import type { CaptchaSettingsForStrategy, ReCaptchaResponse } from 'web/src/modules/captcha/store/types';
import { useCaptchaStore } from 'web/src/modules/captcha/store';
import DefaultReCaptchaService from 'web/src/modules/captcha/services/DefaultReCaptchaService';
import { useBaseModalActions } from 'web/src/modules/dialogs/composables';
import type { ConfirmModalProps } from 'web/src/modules/dialogs/views/ConfirmModal/types';
import type { VCaptchaProps } from 'web/src/modules/captcha/components/VCaptcha/types';

import ConfirmModal from '../ConfirmModal/ConfirmModal.vue';
import type { CaptchaModalProps, CaptchaModalEmits } from './types';

const props = withDefaults(defineProps<CaptchaModalProps>(), {});
const emit = defineEmits<CaptchaModalEmits>();

const bus = useEventsBus();
const captchaStore = useCaptchaStore();
const { captchaSettingsForStrategy } = captchaStore;
const empiricalEmitCloseDelayMs = 500;

const { onClose, onButtonClick } = useBaseModalActions(emit);
const { setFullHeight } = useDialogsStore();

const captchaForStrategy = computed<CaptchaSettingsForStrategy>(() => (
  captchaSettingsForStrategy(props.modal.captchaRequesterStrategy, props.modal.captchaType).value
));

const captchaSettings = computed<CaptchaSettingsForStrategy>(() => ({
  ...captchaForStrategy.value,
  captchaRequesterStrategy: props.modal.captchaRequesterStrategy,
}));

const isAutoExecute = computed(() => (
  captchaSettings.value.captchaType === CaptchaType.INVISIBLE_RECAPTCHA
  || captchaSettings.value.captchaType === CaptchaType.ANDROID_RECAPTCHA
));

const useIframeForCaptcha = toRef(captchaStore, 'useIframeForCaptcha');
const captchaIframeUrl = toRef(captchaStore, 'captchaIframeUrl');
const apiUrl = toRef(captchaStore, 'apiUrl');

const captchaProperties = computed<VCaptchaProps>(() => ({
  captchaRequesterStrategy: captchaSettings.value.captchaRequesterStrategy,
  captchaEnabled: true,
  siteKey: captchaSettings.value.siteKey,
  captchaType: captchaSettings.value.captchaType,
  reCaptchaTheme: captchaSettings.value.theme,
  autoExecute: isAutoExecute.value,
  useIframeForCaptcha: useIframeForCaptcha.value,
  captchaIframeUrl: captchaIframeUrl.value,
  apiUrl: apiUrl.value,
}));

const confirmModalProps = computed<ConfirmModalProps>(() => {
  const {
    id, captchaRequesterStrategy, captchaType, ...modalProps
  } = props.modal;

  return {
    modal: {
      ...modalProps,
      modalComponent: DialogComponent.ConfirmModal,
    },
    isAlert: props.isAlert,
  };
});

function closeModal(value: ReCaptchaResponse | null): void {
  /**
   * ReCaptcha challenge workaround for problematic/slow/buggy devices that didn't handle the challenge on time.
   */
  const emitClose = (isRetry: boolean) => {
    Timer.setTimeout(() => {
      if (!isRetry && DefaultReCaptchaService.getInstance().isChallengeVisible()) {
        emitClose(true);
      } else {
        onButtonClick({
          action: DialogAction.MODAL_CLOSE,
          value: value ?? undefined,
        });
      }
    }, empiricalEmitCloseDelayMs);
  };

  emitClose(false);
}

function onChallengeClose(): void {
  bus.emit(BusEvent.CAPTCHA_CHALLENGE_IS_CLOSED, {});
  closeModal(null);
  emit('challenge-is-closed');
}

function onVerify({ captchaResponse }: ReCaptchaResponse): void {
  closeModal({ captchaResponse });
}

function onError(error: Error): void {
  closeModal({ captchaResponse: error });
}

function onWrapperState(state: ReCaptchaFramedWrapperState): void {
  if (state === ReCaptchaFramedWrapperState.Expand) {
    setFullHeight(props.modal.id, true);
  } else if (state === ReCaptchaFramedWrapperState.Collapse) {
    setFullHeight(props.modal.id, false);
  }
}
</script>

<template>
  <ConfirmModal v-auto-id="'CaptchaModal'"
    v-bind="confirmModalProps"
    @close="onClose"
    @button-click="onButtonClick"
  >
    <div v-if="isAutoExecute">
      <div :class="$style['captcha-padding']">
        <VLinearLoader
          :hint="$t('WEB2_CAPTCHA_CHECK_MODAL_CAPTION')"
        />
      </div>
    </div>
    <VCaptcha
      :class="$style['captcha-margin-bottom']"
      v-bind="captchaProperties"
      @verify="onVerify"
      @wrapper-state="onWrapperState"
      @error="onError"
      @challenge-is-closed="onChallengeClose"
      @challenge-is-opened="emit('challenge-is-opened')"
    />
    <VReCaptchaBadge :lang="$lang" />
  </ConfirmModal>
</template>

<style lang="scss" module>
.captcha-margin-bottom {
  top: 50px;
  display: inline-flex;
  align-items: center;
  margin-bottom: 10px;
  overflow: hidden;
}

.captcha-padding {
  padding: 20px;
}
</style>
