import type { Ref } from 'vue';
import { toRef } from 'vue';

import { useSportlineEventStatisticStore } from 'web/src/modules/sportline/submodules/event-statistic/store';
import useMatchStatisticsTeams
  from 'web/src/modules/sportline/submodules/event-statistic/composables/useMatchStatisticsTeams';
import type {
  BetlineLeagueStanding,
  BetlineMatchStatisticsMatch,
  BetlineMatchStatisticsTeam,
} from 'web/src/modules/sportline/types/rest';
import type { TeamPlayTotalStatistic } from 'web/src/modules/sportline/submodules/core-statistic/types';

import { useLeagueStandings } from './composables/useLeagueStandings';
import { useH2hMatchStatistic } from './composables/useH2hMatchStatistic';
import { useLastGamesMatchStatistic } from './composables/useLastGamesMatchStatistic';

interface UseSportEventStatisticComposable {
  leagueStandings: Ref<BetlineLeagueStanding[]>;
  teams: Ref<Maybe<[BetlineMatchStatisticsTeam, BetlineMatchStatisticsTeam]>>;
  h2hMatchStatistic: Ref<Maybe<TeamPlayTotalStatistic>>;
  joinMatches: Ref<BetlineMatchStatisticsMatch[]>;
  hostMatches: Ref<BetlineMatchStatisticsMatch[]>;
  guestMatches: Ref<BetlineMatchStatisticsMatch[]>;
  statisticSportFamily: Ref<Maybe<string>>;
}

export default function useSportEventStatistic(): UseSportEventStatisticComposable {
  const sportlineEventStatisticStore = useSportlineEventStatisticStore();

  const betlineMatchStatistics = toRef(sportlineEventStatisticStore, 'betlineMatchStatistics');
  const betlineLeagueStandings = toRef(sportlineEventStatisticStore, 'betlineLeagueStandings');
  const statisticSportFamily = toRef(sportlineEventStatisticStore, 'statisticSportFamily');

  const { leagueStandings } = useLeagueStandings({ betlineLeagueStandings });
  const { h2hMatchStatistic, joinMatches } = useH2hMatchStatistic({ betlineMatchStatistics });
  const { hostMatches, guestMatches } = useLastGamesMatchStatistic({ betlineMatchStatistics });
  const { teams } = useMatchStatisticsTeams(betlineMatchStatistics);

  return {
    leagueStandings,
    teams,
    h2hMatchStatistic,
    joinMatches,
    hostMatches,
    guestMatches,
    statisticSportFamily,
  };
}
