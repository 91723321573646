import {
  isNumber,
  isString,
  bindIsArrayOf,
  isValidInterface,
} from '@leon-hub/guards';
import type { FieldGuards } from '@leon-hub/types';

import type { CoreSportMarketColumnResponse } from 'web/src/modules/sportline/types/rest';

export const isCoreSportMarketColumnResponseFieldGuards: FieldGuards<CoreSportMarketColumnResponse> = {
  name: isString,
  id: isNumber,
  altMarketTypeIds: bindIsArrayOf(isNumber),
  weight: isNumber,
};

export function isCoreSportMarketColumnResponse(value: unknown): value is CoreSportMarketColumnResponse {
  return isValidInterface<CoreSportMarketColumnResponse>(value, isCoreSportMarketColumnResponseFieldGuards);
}
