import { isArray } from '@leon-hub/guards';

import type { DropListSelectProps } from '../types';

export function getDefaultMultipleSelectedValues(props: DropListSelectProps): Set<string> {
  if (!props.isMultiselectMode || !props.selected) {
    return new Set();
  }
  return new Set((isArray(props.selected) ? props.selected : []));
}
