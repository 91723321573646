<script setup lang="ts">
import { ButtonKind } from '@leon-hub/module-buttons';

import { VLoader } from '@components/loader';
import { VScrollbarDesktop } from '@components/v-scrollbar';

import VButton from 'web/src/components/Button/VButton/VButton.vue';

import { BetSlipTabs } from '../BetSlipTabs';
import type { SlipLayoutEmits, SlipLayoutProps, SlipLayoutSlots } from './types';
import defaultSlipLayoutProps from './constants/DefaultSlipLayoutProps';
import useSlipLayoutCommon from './composables/useSlipLayoutCommon';
import useSlipLayoutDesktop from './composables/useSlipLayoutDesktop';

const props = withDefaults(defineProps<SlipLayoutProps>(), {
  ...defaultSlipLayoutProps,
});
const emits = defineEmits<SlipLayoutEmits>();
defineSlots<SlipLayoutSlots>();

const {
  selectTopTab,
  showMyBetsTab,
  isPendingResultState,
} = useSlipLayoutCommon(props, emits);
const {
  onKeydown,
  goToHelp,
  showResultOverlay,
  scrollDisabled,
  showBannerBlock,
} = useSlipLayoutDesktop(props);
</script>

<template>
  <div v-auto-id="'SlipLayoutDesktop'" :class="$style['slip-layout']">
    <VScrollbarDesktop
      :disabled="scrollDisabled"
      test-el="bet-slip"
      flex-fill
      @keydown="onKeydown"
    >
      <div :class="$style['slip-layout__content']">
        <div :class="$style['slip-layout__body']">
          <template v-if="isSettingsVisible">
            <slot name="settings" />
          </template>
          <div
            v-else
            :class="$style['slip-layout__main']"
          >
            <BetSlipTabs
              :is-logged="isLogged"
              :slip-size="slipSize"
              :my-bets-count="myBetsCount"
              :active-tab-id="activeTopTabId"
              @tab-click="selectTopTab"
              @settings-click="$emit('settings-click')"
            />
            <VLoader
              v-if="!!null && isLogged"
              :class="$style['slip-layout__ssr-placeholder']"
            />
            <template v-else-if="!showMyBetsTab">
              <slot name="main" />
            </template>
            <template v-else>
              <slot name="my-bets" />
            </template>
            <div
              v-if="isPendingResultState"
              :class="$style['slip-layout__pending-mask']"
            />
          </div>
        </div>
        <div
          v-if="showBannerBlock"
          :class="$style['slip-layout__banners']"
        >
          <slot name="banners" />
        </div>
      </div>
      <div
        v-if="showHelpButton"
        :class="$style['slip-layout__help']"
      >
        <VButton
          :class="$style['slip-layout__help-button']"
          :label="$t('JSP_HELP')"
          :kind="ButtonKind.BASE"
          rounded
          :is-uppercase="false"
          @click="goToHelp"
        />
      </div>
    </VScrollbarDesktop>
    <div
      v-if="clearBetListWarnVisible || cashoutInProgress || showResultOverlay"
      :class="$style['slip-layout__overlay']"
    >
      <div
        :class="{
          [$style['slip-layout__overlay-content']]: true,
          [$style['slip-layout__overlay-content--cashout']]: cashoutInProgress,
        }"
      >
        <template v-if="clearBetListWarnVisible">
          <slot name="confirm-overlay" />
        </template>
        <template v-if="cashoutInProgress">
          <slot name="cashout" />
        </template>
        <template v-if="showResultOverlay">
          <slot name="result" />
        </template>
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>
.slip-layout {
  position: relative;
  display: flex;
  flex: 1 0 100%;
  flex-direction: column;
  overflow: hidden auto;

  &__content {
    position: relative;
    display: block;
    flex: 1;
    padding-right: 16px;
    margin-top: 8px;
    overflow: hidden;
  }

  &__banners {
    flex: none;
    margin: 40px 0 0;

    &:empty {
      display: none;
    }
  }

  &__body {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-bottom: 8px;
    overflow: hidden;
    background-color: var(--Layer1);
    border-radius: 10px;
  }

  &__main {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  &__body,
  &__overlay-content {
    box-shadow: 0 0.66px 0 var(--Below);
  }

  &__overlay {
    position: absolute;
    top: 0;
    right: 16px;
    bottom: 0;
    left: 0;
    z-index: get($zIndex, slip-result-desktop);
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    background-color: var(--Layer0);
  }

  &__pending-mask {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 84px;
    left: 0;
    z-index: get($zIndex, absolute-item);
    background-color: var(--Layer1);
    opacity: 0.95;
  }

  &__overlay-content {
    margin-top: 8px;
    background-color: var(--Layer1);
    border-radius: 10px;

    &--cashout {
      padding: 16px;
    }
  }

  &__ssr-placeholder {
    padding: 40px 20px;
  }

  &__help {
    padding: 16px;
    margin: 0 auto;
  }

  &__help-button {
    min-width: 160px;
  }
}
</style>
