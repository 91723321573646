import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import type { IconNameType, IconSize } from '@leon-hub/icons';

import type { SwiperNavigationButtonProps } from 'web/src/components/Swiper/Navigation/SwiperNavigationButton/types';
import getIconName from 'web/src/components/Swiper/Navigation/SwiperNavigationButton/composables/utils/getIconName';
import { getIconSize } from 'web/src/components/Swiper/Navigation/SwiperNavigationButton/composables/utils';

interface SwiperNavigationButtonComposable {
  iconName: ComputedRef<IconNameType>;
  iconSize: ComputedRef<IconSize>;
}

export default function useSwiperNavigationButton(
  props: SwiperNavigationButtonProps,
): SwiperNavigationButtonComposable {
  const iconName = computed(() => getIconName(props));
  const iconSize = computed(() => getIconSize(props));

  return {
    iconName,
    iconSize,
  };
}
