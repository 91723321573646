import type { QueryManager } from '@leon-hub/query-manager';
import { getQueryManager } from '@leon-hub/query-manager';

let queryManager: QueryManager | undefined;

export default function useQueryManager(): QueryManager {
  if (!queryManager) {
    queryManager = getQueryManager();
  }
  return queryManager;
}
