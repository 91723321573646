<script setup lang="ts">
import { IconName, IconSize } from '@leon-hub/icons';

import { VIcon } from '@components/v-icon';

import type { BaseInputEmits, NumberStringInputProps } from '../../types';
import { useNumberStringInput } from '../../composables';
import { InputMode, TextInputTypes } from '../../enums';
import InputWrapper from '../InputWrapper';
import InputBorderWrapper from '../InputBorderWrapper';
import TextFlatInput from '../TextFlatInput';
import InputButton from '../InputButton/InputButton.vue';

const props = withDefaults(defineProps<NumberStringInputProps>(), {
  name: 'number-input',
  value: '',
  maximumFractionDigits: 0,
});

const emits = defineEmits<BaseInputEmits>();

defineOptions({
  inheritAttrs: false,
});

const {
  uniqueId,
  isFocus,
  isEmpty,
  isHover,
  showClearButton,
  inputValue,
  onBlur,
  onChange,
  onInput,
  onFocus,
  onClear,
  onPaste,
} = useNumberStringInput(props, emits);
</script>

<template>
  <InputWrapper v-auto-id="'NumberStringInput'"
    v-data-test="{
      el: 'number-string-input',
      name: name,
    }"
    :hint="!showHintOnFocus || isFocus ? hint : ''"
    :input-id="uniqueId"
    :label="label"
    :disabled="disabled"
    :is-focus="isFocus"
    :is-empty="isEmpty"
    :error="error"
    :clickable-suffix="showClearButton"
  >
    <template #input>
      <InputBorderWrapper
        :is-focus="isFocus"
        :is-disabled="disabled"
        :is-hover="isHover"
        :has-error="!!error"
        :is-from-modal="isSlipStyle"
      >
        <TextFlatInput
          :autofocus="autofocus"
          :name="name"
          :input-id="uniqueId"
          :type="TextInputTypes.TEL"
          :input-mode="InputMode.NUMERIC"
          :value="inputValue"
          :has-error="!!error"
          :has-icon-suffix="showClearButton || isSaved"
          :disabled="disabled"
          :placeholder="placeholder"
          @focus="onFocus"
          @blur="onBlur"
          @change="onChange"
          @input="onInput"
          @paste="onPaste"
        />
      </InputBorderWrapper>
    </template>
    <template #iconSuffix>
      <InputButton
        v-show="showClearButton"
        v-data-test="{ el: 'clear-icon-input-button', visible: !showClearButton }"
        :title="$t('WEB2_CLEAR')"
        @click="onClear"
      />
      <VIcon
        v-if="isSaved && !showClearButton"
        :name="IconName.CHECK"
        :size="IconSize.SIZE_16"
      />
    </template>
  </InputWrapper>
</template>
