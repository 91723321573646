import type {
  LeaderBoardParticipant,
  LeaderBoardWinnersResponse,
} from '@leon-hub/api-sdk';
import { isObject } from '@leon-hub/guards';

import type {
  LeaderBoardListMap,
  LeaderBoardOptions,
  LeaderBoardOptionsMapRecord,
} from '../types';

// Currently, all members in LeaderBoardWinnersResponse are nullable
export function isLeaderBoardWinnersResponse(value: unknown): value is LeaderBoardWinnersResponse {
  return isObject(value);
}

export function getLeaderBoardList(
  id: string,
  list: LeaderBoardListMap,
): LeaderBoardParticipant[] {
  return list[id] || [];
}

export function getLeaderBoardOptions(
  actionUrl: string,
  id: string,
  options: LeaderBoardOptionsMapRecord,
): Optional<LeaderBoardOptions> {
  return options[actionUrl]?.[id];
}

export default {
  isLeaderBoardWinnersResponse,
};
