import { defineStore } from 'pinia';
import {
  ref,
  toRef,
  watch,
  computed,
} from 'vue';

import { useLocalStorageManager } from '@leon-hub/local-storage';
import { CustomerConfig } from '@leon-hub/api-sdk';

import type { OddFormatItem } from 'web/src/modules/sportline/submodules/odds/types';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useUserStore } from 'web/src/modules/user/store';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { useCustomerDataStore } from 'web/src/modules/customer/store';

import type { CustomerConfigOddsType } from '../enums';
import type { OddsType } from '../enums/OddsType';
import { getStringValueFromOddTypeConfig } from '../utils/getStringValueFromOddTypeConfig';

const useOddsStore = defineStore('sport-odds-store', () => {
  const localStorageManager = useLocalStorageManager();
  const { $translate } = useI18n();
  const userStore = useUserStore();
  const customerDataStore = useCustomerDataStore();
  const { isLoggedIn } = useIsLoggedIn();
  const customerOddTypeConfig = toRef(() => customerDataStore.customerOddTypeConfig);

  const oddsList = computed<OddFormatItem[]>(() => [
    { id: 1, label: $translate('DECIMAL_ODDS_TYPE').value, oddType: CustomerConfig.IS_ODDS_TYPE_CLASSIC },
    { id: 2, label: $translate('AMERICAN_ODDS_TYPE').value, oddType: CustomerConfig.IS_ODDS_TYPE_AMERICAN },
  ]);
  const userOddTypeLocal = ref<CustomerConfigOddsType>();

  function setCustomerOdd(currentOdd: CustomerConfigOddsType): void {
    if (currentOdd === userOddTypeLocal.value) return;

    localStorageManager.setItem('odd-type', currentOdd);
    userOddTypeLocal.value = currentOdd;

    if (!isLoggedIn.value) return;

    if (currentOdd === CustomerConfig.IS_ODDS_TYPE_CLASSIC) {
      void updateOddTypeForUser(CustomerConfig.IS_ODDS_TYPE_CLASSIC);
    } else {
      void updateOddTypeForUser(CustomerConfig.IS_ODDS_TYPE_AMERICAN);
    }
  }

  async function updateOddTypeForUser(
    customerConfigOdd: CustomerConfig,
  ): Promise<void> {
    await userStore.setAdditionalPropsUser({
      config: customerConfigOdd,
      value: {
        value: getStringValueFromOddTypeConfig(customerConfigOdd),
      },
    });
  }

  const currentOddsType = computed<OddsType>(() => getStringValueFromOddTypeConfig(userOddTypeLocal.value));

  function initializeOddType(): void {
    if (!process.env.VUE_APP_FEATURE_SPORTLINE_ODDS_SELECTOR_ENABLED) {
      return;
    }
    const existingType = localStorageManager.getItem('odd-type');
    if (!existingType) {
      localStorageManager.setItem('odd-type', CustomerConfig.IS_ODDS_TYPE_CLASSIC);
      userOddTypeLocal.value = CustomerConfig.IS_ODDS_TYPE_CLASSIC;
    } else {
      userOddTypeLocal.value = existingType as CustomerConfigOddsType;
    }
  }

  initializeOddType();

  watch(isLoggedIn, (newValue) => {
    if (newValue) {
      userOddTypeLocal.value = customerOddTypeConfig.value;
    }
  });

  watch(customerOddTypeConfig, (newCustomerConfig) => {
    if (isLoggedIn.value) {
      userOddTypeLocal.value = newCustomerConfig;
    }
  });

  return {
    oddsList,
    userOddTypeLocal,
    currentOddsType,
    setCustomerOdd,
  };
});

export default useOddsStore;
