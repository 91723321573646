import type { SlipInfoFieldsFragment } from '@leon-hub/api-sdk';

import type { SlipEntry } from '../../slip-info/types';

export default function getMultiSinglesSlipEntry(input: SlipInfoFieldsFragment): SlipEntry {
  const firstElement = input.entries[0];
  if (!firstElement) {
    throw new Error('corrupted slip info');
  }
  return firstElement;
}
