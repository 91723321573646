import { computed, toRef } from 'vue';
import { defineStore } from 'pinia';

import { useSportlinePageIdentifier } from 'web/src/modules/sportline/store/composables';
import { useGlobalSportlineEvents } from 'web/src/modules/sportline/composables/core';
import useSportsTreeStore from 'web/src/modules/sportline/store/useSportsTreeStore';
import type {
  SportsTreeSportElement,
  SportsTreeRegionElement,
  SportsTreeLeagueElement,
} from 'web/src/modules/sportline/types/rest';
import {
  leagueElementNavigationEqualsTo,
  regionElementNavigationEqualsTo,
  sportElementEqualsTo,
} from 'web/src/modules/sportline/utils/rest/compare';

// LEONWEB-2631 for details page used urlNames from page link
export const useSportlineBreadcrumbsStore = defineStore('sportline-breadcrumbs', () => {
  const { onInit } = useGlobalSportlineEvents();
  const sportTreeStore = useSportsTreeStore();
  const fullSportsList = toRef(sportTreeStore, 'fullSportsList');

  const {
    pageIdentifier,
    setPageIdentifier,
  } = useSportlinePageIdentifier();

  /** Related sport elements in dropdown menu */
  const relatedSports = computed<SportsTreeSportElement[]>(() => (fullSportsList.value || []));
  /** Active sport element in breadcrumbs */
  const sportElement = computed<Maybe<SportsTreeSportElement>>(() => {
    const { sportFamily } = pageIdentifier.value;
    if (!sportFamily) { return null; }
    return relatedSports.value.find(sportElementEqualsTo(sportFamily, (sport) => (
      sportFamily.toLowerCase() === sport?.sport.representation.family.toLowerCase()
    ))) || null;
  });
  /** Related region elements in dropdown menu */
  const relatedRegions = computed<SportsTreeRegionElement[]>(() => (sportElement.value?.regions || []));
  /** Active region element in breadcrumbs */
  const regionElement = computed<Maybe<SportsTreeRegionElement>>(() => {
    const { regionId, regionUrlName } = pageIdentifier.value;
    if (!regionId && !regionUrlName) { return null; }
    return relatedRegions.value.find(regionElementNavigationEqualsTo(regionId || regionUrlName))
      || null;
  });
  /** Related league elements in dropdown menu */
  const relatedLeagues = computed<SportsTreeLeagueElement[]>(() => (regionElement.value?.leagues || []));
  /** Active league element in breadcrumbs */
  const leagueElement = computed<Maybe<SportsTreeLeagueElement>>(() => {
    const { leagueId, leagueUrlName } = pageIdentifier.value;
    if (!leagueId && !leagueUrlName) { return null; }
    return relatedLeagues.value.find(leagueElementNavigationEqualsTo(leagueId || leagueUrlName)) || null;
  });

  onInit();

  return {
    pageIdentifier,
    sportElement,
    relatedRegions,
    regionElement,
    relatedLeagues,
    leagueElement,
    setPageIdentifier,
  };
});
