import type { Ref } from 'vue';
import type { RouteLocationRaw } from 'vue-router';
import { computed, toRef } from 'vue';
import { useRouter } from 'vue-router';

import { RouteName } from '@leon-hub/routing-config-names';

import { usePromotionsTopStore } from 'web/src/modules/promotions/submodules/top/usePromotionsTopStore';
import type { Promotion } from 'web/src/modules/promotions/submodules/top/types';

interface UseHomePromotionsBlockComposable {
  promotions: Ref<Promotion[]>;
  allPromotionsRoute: RouteLocationRaw;
  openAllPromotions(): void;
  openPromotion(promotion: Promotion): void;
  getPromotionRoute(promotion: Promotion): RouteLocationRaw;
}

export function useHomePromotionsBlock(): UseHomePromotionsBlockComposable {
  const router = useRouter();
  const promotionsTopStore = usePromotionsTopStore();
  const rawPromotions = toRef(promotionsTopStore, 'promotions');

  const promotions = computed<Promotion[]>(() => (
    (rawPromotions.value ?? []).filter((el) => !el.isShowStartDateCountdown)
  ));

  const allPromotionsRoute: RouteLocationRaw = { name: RouteName.PROMOTIONS };

  function getPromotionRoute(promotion: Promotion): RouteLocationRaw {
    return {
      name: RouteName.PROMOTION_DETAILS,
      params: {
        categoryId: promotion.categoryId || '',
        actionUrl: promotion.actionUrl || '',
      },
    };
  }

  function openAllPromotions(): void {
    void router.push(allPromotionsRoute);
  }

  function openPromotion(promotion: Promotion): void {
    void router.push(getPromotionRoute(promotion));
  }

  return {
    promotions,
    allPromotionsRoute,
    openAllPromotions,
    openPromotion,
    getPromotionRoute,
  };
}
