import type { Ref, MaybeRef } from 'vue';
import { ref, toRef, shallowRef } from 'vue';

import { safeParseInt } from '@leon-hub/utils';

import {
  useStreamUrlAvailable,
  useStreamAvailableIndicator,
} from 'web/src/modules/sportline/submodules/streams/composables';
import type { SportlineEventId } from 'web/src/modules/sportline/types';
import type { SportlineType } from 'web/src/modules/sportline/enums';
import { StreamErrorCode } from 'web/src/modules/sportline/submodules/streams/errors';
import { getMatchStreamUrl } from 'web/src/modules/sportline/utils/api';

import { useStreamPreviewStoreComposable } from './useStreamPreviewStoreComposable';
import { useStreamErrorsConverter } from './useStreamErrorsConverter';
import type { StreamUrl, StreamErrorDescription } from '../../types';

interface UseStreamUrlStoreComposableProps {
  sportlineEventId: MaybeRef<Maybe<SportlineEventId>>;
  sportlineType: MaybeRef<Maybe<SportlineType>>;
}

export interface UseStreamUrlStoreComposable {
  streamUrl: Ref<StreamUrl>;
  streamError: Ref<Maybe<StreamErrorDescription>>;
  isStreamAvailable: Ref<boolean>;
  isStreamIndicatorAvailable: Ref<boolean>;
  isStreamPreviewOnly: Ref<boolean>;
  doShowStreamPreviewInfo: Ref<boolean>;
  clearStreamInfo(): void;
  reloadStream(): Promise<void>;
  releaseStream(): void;
  onStreamStarted(): void;
}

export function useStreamUrlStoreComposable(props: UseStreamUrlStoreComposableProps): UseStreamUrlStoreComposable {
  const sportlineEventId = toRef(props.sportlineEventId);
  const sportlineType = toRef(props.sportlineType);
  const streamUrl = ref<StreamUrl>(null);

  const streamError = shallowRef<Maybe<StreamErrorDescription>>(null);

  const { doShowStreamPreviewInfo, startPreview, stopPreview } = useStreamPreviewStoreComposable({ sportlineEventId });
  const { isStreamAvailable, isPreviewOnly } = useStreamUrlAvailable({ sportlineEventId, sportlineType });
  const { isStreamIndicatorAvailable } = useStreamAvailableIndicator({ sportEventId: sportlineEventId, sportlineType });
  const { convertToStreamError } = useStreamErrorsConverter();

  async function reloadStream(): Promise<void> {
    if (!sportlineEventId.value) { return; }
    const eventId = safeParseInt(sportlineEventId.value);
    if (!eventId) { return; }

    try {
      if (doShowStreamPreviewInfo.value) {
        // do not request stream url for enabled preview (mark url as error)
        streamUrl.value = false;
        return;
      }

      if (!isStreamAvailable.value) {
        streamUrl.value = null;
        return;
      }

      const response = await getMatchStreamUrl({ eventId }, { silent: true });

      // @see LEONWEB-8356 stream component still available with error status (streamUrl === false)
      streamUrl.value = response.result === 'OK' ? response.src : false;
    } catch (rawError) {
      streamUrl.value = false;

      const error = convertToStreamError(rawError);

      if (
        error.code.equals(StreamErrorCode.STREAM_NOT_AVAILABLE)
        || error.code.equals(StreamErrorCode.DISABLED)
      ) {
        streamError.value = error;
        return;
      }

      throw rawError;
    }
  }

  return {
    streamUrl,
    streamError,
    isStreamAvailable,
    isStreamIndicatorAvailable,
    isStreamPreviewOnly: isPreviewOnly,
    doShowStreamPreviewInfo,
    clearStreamInfo(): void {
      streamUrl.value = null;
      streamError.value = null;
    },
    releaseStream(): void {
      stopPreview();
    },
    onStreamStarted(): void {
      if (!isPreviewOnly.value) { return; }
      startPreview();
    },
    reloadStream,
  };
}
