<script lang="ts" setup>
import { onBeforeUnmount } from 'vue';

import { IconName, IconSize } from '@leon-hub/icons';

import { VIcon } from '@components/v-icon';

import { useSportlineRedirectFromEmptyPageInfo } from './useSportlineRedirectFromEmptyPageInfo';

const {
  isShown,
  title,
  description,
  clearInfo,
} = useSportlineRedirectFromEmptyPageInfo();

onBeforeUnmount(clearInfo);
</script>

<template>
  <div v-auto-id="'SportlineRedirectFromEmptyPageInfo'"
    v-if="isShown"
    :class="$style['redirect-from-empty-page-info']"
  >
    <div :class="$style['redirect-from-empty-page-info__title']">
      {{ title }}
    </div>

    <div :class="$style['redirect-from-empty-page-info__description']">
      {{ description }}
    </div>

    <div
      :class="$style['redirect-from-empty-page-info__close-button']"
      @click="clearInfo"
    >
      <VIcon
        :size="IconSize.SIZE_24"
        :name="IconName.CROSS"
      />
    </div>
  </div>
</template>

<style lang="scss" module>
.redirect-from-empty-page-info {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  text-align: center;
  background-color: var(--OpacityLayer1);
  border-radius: 5px;

  &__title {
    @include medium\18\28;

    padding-bottom: 8px;
  }

  &__description {
    @include regular\14\24\025;
  }

  &__close-button {
    position: absolute;
    top: 15px;
    right: 15px;
  }
}
</style>
