import type { Ref } from 'vue';
import { inject, ref, watch } from 'vue';

import { BusEvent, useBusSafeSubscribe } from '@leon-hub/event-bus';

import type { VScrollbarDesktopProvidableContext } from '@components/v-scrollbar';
import { ScrollbarProvidableContext } from '@components/v-scrollbar';

export function useExternalScrollWatcher(
  scrollbarContextHandler: Function,
  layoutScrollHandler: Function,
): void {
  const onBusEvent = (busEvent: {
    scrollTop: number;
    offsetHeight: number;
    scrollHeight: number;
  }) => layoutScrollHandler(busEvent);

  if (process.env.VUE_APP_LAYOUT_DESKTOP) {
    const scrollbarContext: Ref<VScrollbarDesktopProvidableContext | undefined> = inject(ScrollbarProvidableContext.ScrollbarContext, ref(undefined));

    if (scrollbarContext.value) {
      watch(scrollbarContext, (value) => {
        if (value) {
          scrollbarContextHandler(value.scrollTop);
        }
      });
    } else {
      useBusSafeSubscribe(BusEvent.LAYOUT_CONTENT_SCROLL, onBusEvent);
    }
  } else {
    useBusSafeSubscribe(BusEvent.LAYOUT_CONTENT_SCROLL, onBusEvent);
  }
}
