import getIFrameElementByName from './getIFrameElementByName';

function getContentWindowByIFrameName(iframeName: string): Window | null {
  const iFrameElement = getIFrameElementByName(iframeName);
  if (iFrameElement) {
    return iFrameElement.contentWindow;
  }
  return null;
}

export default getContentWindowByIFrameName;
