import type { FormSnapshot, FormTouched } from 'web/src/components/Form/types';
import type { RegistrationFormFilledFields } from 'web/src/modules/registration/types';

export default (saved: RegistrationFormFilledFields): FormSnapshot | null => {
  const keys = Object.keys(saved);
  if (!keys.length) {
    return null;
  }
  const formData = new Map();
  const touched: FormTouched = new Set();
  for (const key of keys) {
    formData.set(key, saved[key]);
    touched.add(key);
  }
  return { formData, touched };
};
