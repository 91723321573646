import type { Ref } from 'vue';
import { ref } from 'vue';
import { defineStore } from 'pinia';

import type {
  GetSportEventsChangesResponse,
  GetSportEventsResponse,
} from 'web/src/modules/sportline/types/rest';
import type {
  SportElementCacheStorageKey,
  SportElementCache,
  SportElementCacheStorageLastUpdate,
  LeagueElementCacheStorageLastUpdate,
  RegionElementCacheStorageLastUpdate,
  SportEventElementCacheStorageLastUpdate,
  SportEventMarketsCacheStorageLastUpdate,
  LeagueElementCache,
  LeagueElementCacheStorageKey,
  RegionElementCache,
  RegionElementCacheStorageKey,
  SportEventElementCache,
  SportEventElementCacheStorageKey,
  SportEventMarketsCache,
  SportEventMarketsCacheStorageKey,
} from 'web/src/modules/sportline/types/cache-storage';
import type {
  SportElement,
  SportEvent,
} from 'web/src/modules/sportline/types';
import {
  getSportElementCacheStorageKey,
  getRegionElementCacheStorageKey,
  getLeagueElementCacheStorageKey,
  getSportEventElementCacheStorageKey,
  getSportEventMarketsCacheStorageKey,
} from 'web/src/modules/sportline/utils/cache-storage';
import {
  isSportChanged,
  isRegionChanged,
  isLeagueChanged,
  isSportEventInfoChanged,
} from 'web/src/modules/sportline/guards';
import {
  BetlineReplaceResponse,
  SportlineFactory,
} from 'web/src/modules/sportline/utils/rest';
import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';

const INVALIDATE_STATE_AFTER = 60 * 60 * 1000;

function deleteInvalidatedCache<
  T extends Record<string, number>>(
  cacheLastActualize: T,
  state: Record<keyof T, Ref<unknown>>,
): boolean {
  const cacheKeys = Object.keys(cacheLastActualize);
  let hasActualized = false;
  const updated = Date.now();

  for (const storageKey of cacheKeys) {
    const dataInStorage = state[storageKey];
    const actualized = cacheLastActualize[storageKey];

    if (dataInStorage && updated - actualized > INVALIDATE_STATE_AFTER) {
      // trigger last clear state
      dataInStorage.value = null;
      // eslint-disable-next-line no-param-reassign
      delete state[storageKey];
      // eslint-disable-next-line no-param-reassign
      delete cacheLastActualize[storageKey];
      hasActualized = true;
    }
  }

  return hasActualized;
}

const useSportlineCacheStorage = defineStore('sportline-cache-storage', () => {
  const settingsStore = useSportlineSettingsStore();

  /**
   * Store for timestamp when we got last actual info
   * It is not date when something changed
   */
  let internalSportElementCacheLastActualize: Readonly<
    SportElementCacheStorageLastUpdate
  > = Object.freeze({});
  let internalRegionElementCacheLastActualize: Readonly<
    RegionElementCacheStorageLastUpdate
  > = Object.freeze({});
  let internalLeagueElementCacheLastActualize: Readonly<
    LeagueElementCacheStorageLastUpdate
  > = Object.freeze({});
  let internalSportEventElementCacheLastActualize: Readonly<
    SportEventElementCacheStorageLastUpdate
  > = Object.freeze({});
  let internalSportEventMarketsCacheLastActualize: Readonly<
    SportEventMarketsCacheStorageLastUpdate
  > = Object.freeze({});

  const sportsCache: Record<SportElementCacheStorageKey, Ref<Maybe<SportElementCache>>> = {};
  const regionsCache: Record<RegionElementCacheStorageKey, Ref<Maybe<RegionElementCache>>> = {};
  const leaguesCache: Record<LeagueElementCacheStorageKey, Ref<Maybe<LeagueElementCache>>> = {};
  const sportEventsCache: Record<SportEventElementCacheStorageKey, Ref<Maybe<SportEventElementCache>>> = {};
  const marketsCache: Record<SportEventMarketsCacheStorageKey, Ref<Maybe<SportEventMarketsCache>>> = {};

  function internalClearOldSportsDataInStorage(): void {
    const cacheLastActualize = { ...internalSportElementCacheLastActualize };
    const hasActualized = deleteInvalidatedCache(cacheLastActualize, sportsCache);
    if (!hasActualized) { return; }
    internalSportElementCacheLastActualize = Object.freeze(cacheLastActualize);
  }

  function internalClearOldRegionDataInStorage(): void {
    const cacheLastActualize = { ...internalRegionElementCacheLastActualize };
    const hasActualized = deleteInvalidatedCache(cacheLastActualize, regionsCache);
    if (!hasActualized) { return; }
    internalRegionElementCacheLastActualize = Object.freeze(cacheLastActualize);
  }

  function internalClearOldLeagueDataInStorage(): void {
    const cacheLastActualize = { ...internalLeagueElementCacheLastActualize };
    const hasActualized = deleteInvalidatedCache(cacheLastActualize, leaguesCache);
    if (!hasActualized) { return; }
    internalLeagueElementCacheLastActualize = Object.freeze(cacheLastActualize);
  }

  function internalClearOldSportEventDataInStorage(): void {
    const cacheLastActualize = { ...internalSportEventElementCacheLastActualize };
    const hasActualized = deleteInvalidatedCache(cacheLastActualize, sportEventsCache);
    if (!hasActualized) { return; }
    internalSportEventElementCacheLastActualize = Object.freeze(cacheLastActualize);
  }

  function internalClearOldSportEventMarketsDataInStorage(): void {
    const cacheLastActualize = { ...internalSportEventMarketsCacheLastActualize };
    const hasActualized = deleteInvalidatedCache(cacheLastActualize, marketsCache);
    if (!hasActualized) { return; }
    internalSportEventMarketsCacheLastActualize = Object.freeze(cacheLastActualize);
  }

  function clearInvalidCache(): void {
    internalClearOldSportsDataInStorage();
    internalClearOldRegionDataInStorage();
    internalClearOldLeagueDataInStorage();
    internalClearOldSportEventDataInStorage();
    internalClearOldSportEventMarketsDataInStorage();
  }

  function internalUpdateSportsInStorage({ parsedSportline }: { parsedSportline: SportElement[] }): void {
    const updated = Date.now();
    const lastActualizedCache = { ...internalSportElementCacheLastActualize };

    for (const sportElement of parsedSportline) {
      const storageKey: SportElementCacheStorageKey = getSportElementCacheStorageKey(sportElement.sport.id);
      const oldSportData = sportsCache[storageKey];

      // update an element in the storage only if we have a change
      if (isSportChanged(oldSportData?.value?.sport, sportElement.sport)) {
        const newSportData = Object.freeze({
          key: `${sportElement.key}_${updated}`,
          sport: sportElement.sport,
          updated,
        });

        if (oldSportData) {
          oldSportData.value = newSportData;
        } else {
          sportsCache[storageKey] = ref(newSportData);
        }
      }

      // actualize updated time
      lastActualizedCache[storageKey] = updated;
    }

    if (parsedSportline.length > 0) {
      // update state only if we have at least one updated element
      internalSportElementCacheLastActualize = Object.freeze(lastActualizedCache);
    }
  }

  function internalUpdateRegionsInStorage({ parsedSportline }: { parsedSportline: SportElement[] }): void {
    const updated = Date.now();
    const lastActualizedCache = { ...internalRegionElementCacheLastActualize };
    let hasUpdated = false;

    for (const sportElement of parsedSportline) {
      for (const regionElement of sportElement.regions) {
        const storageKey: RegionElementCacheStorageKey = getRegionElementCacheStorageKey(regionElement.region.id);
        const oldRegionData = regionsCache[storageKey];

        // update an element in the storage only if we have a change
        if (isRegionChanged(oldRegionData?.value?.region, regionElement.region)) {
          const newRegionData = Object.freeze({
            sportKey: getSportElementCacheStorageKey(sportElement.sport.id),
            key: `${regionElement.key}_${updated}`,
            region: regionElement.region,
            updated,
          });

          if (oldRegionData) {
            oldRegionData.value = newRegionData;
          } else {
            regionsCache[storageKey] = ref(newRegionData);
          }
        }

        // actualize updated time
        lastActualizedCache[storageKey] = updated;
        hasUpdated = true;
      }
    }

    if (hasUpdated) {
      // update state only if we have at least one updated element
      internalRegionElementCacheLastActualize = Object.freeze(lastActualizedCache);
    }
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  function internalUpdateLeaguesInStorage({ parsedSportline }: { parsedSportline: SportElement[] }): void {
    const updated = Date.now();
    const lastActualizedCache = { ...internalLeagueElementCacheLastActualize };
    let hasUpdated = false;

    for (const sportElement of parsedSportline) {
      for (const regionElement of sportElement.regions) {
        for (const leagueElement of regionElement.leagues) {
          const storageKey: LeagueElementCacheStorageKey = getLeagueElementCacheStorageKey(leagueElement.league.id);
          const oldLeagueData = leaguesCache[storageKey];

          // update an element in the storage only if we have a change
          if (isLeagueChanged(oldLeagueData?.value?.league, leagueElement.league)) {
            const newLeagueData = Object.freeze({
              sportKey: getSportElementCacheStorageKey(sportElement.sport.id),
              regionKey: getRegionElementCacheStorageKey(regionElement.region.id),
              key: `${leagueElement.key}_${updated}`,
              league: leagueElement.league,
              updated,
            });

            if (oldLeagueData) {
              oldLeagueData.value = newLeagueData;
            } else {
              leaguesCache[storageKey] = ref(newLeagueData);
            }
          }

          // actualize updated time
          lastActualizedCache[storageKey] = updated;
          hasUpdated = true;
        }
      }
    }

    if (hasUpdated) {
      // update state only if we have at least one updated element
      internalLeagueElementCacheLastActualize = Object.freeze(lastActualizedCache);
    }
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  function internalUpdateSportEventsInfoInStorage({ parsedSportline }: { parsedSportline: SportElement[] }): void {
    const updated = Date.now();
    const lastActualizedCache = { ...internalSportEventElementCacheLastActualize };
    const lastActualizedMarketsCache = { ...internalSportEventMarketsCacheLastActualize };
    let hasUpdated = false;

    for (const sportElement of parsedSportline) {
      for (const regionElement of sportElement.regions) {
        for (const leagueElement of regionElement.leagues) {
          const sportEventsList = leagueElement.sportEvents.concat(leagueElement.outrightEvents);

          for (const sportEventElement of sportEventsList) {
            const storageKey: SportEventElementCacheStorageKey = getSportEventElementCacheStorageKey(
              sportEventElement.sportEvent.id,
            );
            const oldSportEventData = sportEventsCache[storageKey];

            const {
              markets,
              marketGroups,
              moreCount,
              ...sportEventFields
            } = sportEventElement.sportEvent;

            // update an element in the storage only if we have a change
            if (isSportEventInfoChanged(oldSportEventData?.value?.sportEvent, sportEventElement.sportEvent)) {
              const newSportEventData = Object.freeze({
                sportKey: getSportElementCacheStorageKey(sportElement.sport.id),
                regionKey: getRegionElementCacheStorageKey(regionElement.region.id),
                leagueKey: getLeagueElementCacheStorageKey(leagueElement.league.id),
                key: `${sportEventElement.sportEvent.id}_${updated}`,
                sportEvent: { ...sportEventFields },
                updated,
              });

              if (oldSportEventData) {
                oldSportEventData.value = newSportEventData;
              } else {
                sportEventsCache[storageKey] = ref(newSportEventData);
              }
            }

            // Maybe we need deep compare markets
            // for now they will be updated every time
            const marketsStorageKey: SportEventMarketsCacheStorageKey = getSportEventMarketsCacheStorageKey(
              sportEventElement.sportEvent.id,
            );
            const oldMarketsData = marketsCache[marketsStorageKey];
            const newMarketsData = Object.freeze({
              sportKey: getSportElementCacheStorageKey(sportElement.sport.id),
              regionKey: getRegionElementCacheStorageKey(regionElement.region.id),
              leagueKey: getLeagueElementCacheStorageKey(leagueElement.league.id),
              sportEventKey: storageKey,
              key: `${sportEventElement.sportEvent.id}_${updated}`,
              markets: {
                markets,
                marketGroups,
                moreCount,
              },
              updated,
            });

            if (oldMarketsData) {
              oldMarketsData.value = newMarketsData;
            } else {
              marketsCache[marketsStorageKey] = ref(newMarketsData);
            }

            // actualize updated time
            lastActualizedMarketsCache[marketsStorageKey] = updated;
            lastActualizedCache[storageKey] = updated;
            hasUpdated = true;
          }
        }
      }
    }

    if (hasUpdated) {
      // update state only if we have at least one updated element
      internalSportEventElementCacheLastActualize = Object.freeze(lastActualizedCache);
      internalSportEventMarketsCacheLastActualize = Object.freeze(lastActualizedMarketsCache);
    }
  }

  function updateDataInStorageByResponse({ response }: {
    response: Maybe<GetSportEventsResponse | GetSportEventsChangesResponse> | false;
  }): void {
    if (response) {
      const normalizedResponse = BetlineReplaceResponse.unknownResponseToSportEventsResponse(response);
      const parsedSportline = (new SportlineFactory<SportEvent>(
        normalizedResponse,
        settingsStore.parseSportlineSettings,
      )).build();

      internalUpdateSportsInStorage({ parsedSportline });
      internalUpdateRegionsInStorage({ parsedSportline });
      internalUpdateLeaguesInStorage({ parsedSportline });
      internalUpdateSportEventsInfoInStorage({ parsedSportline });
      // markets will be updated with events
    }
  }

  function getSportCacheByKey(
    key: SportElementCacheStorageKey,
  ): Ref<Maybe<SportElementCache>> {
    if (!sportsCache[key]) { sportsCache[key] = ref(null); }
    return sportsCache[key];
  }

  function getRegionCacheByKey(
    key: RegionElementCacheStorageKey,
  ): Ref<Maybe<RegionElementCache>> {
    if (!regionsCache[key]) { regionsCache[key] = ref(null); }
    return regionsCache[key];
  }

  function getLeagueCacheByKey(
    key: LeagueElementCacheStorageKey,
  ): Ref<Maybe<LeagueElementCache>> {
    if (!leaguesCache[key]) { leaguesCache[key] = ref(null); }
    return leaguesCache[key];
  }

  function getSportEventCacheByKey(
    key: SportEventElementCacheStorageKey,
  ): Ref<Maybe<SportEventElementCache>> {
    if (!sportEventsCache[key]) { sportEventsCache[key] = ref(null); }
    return sportEventsCache[key];
  }

  function getMarketCacheByKey(
    key: SportEventMarketsCacheStorageKey,
  ): Ref<Maybe<SportEventMarketsCache>> {
    if (!marketsCache[key]) { marketsCache[key] = ref(null); }
    return marketsCache[key];
  }

  function getSportCache(id: string): Ref<Maybe<SportElementCache>> {
    return getSportCacheByKey(getSportElementCacheStorageKey(id));
  }

  function getRegionCache(id: string): Ref<Maybe<RegionElementCache>> {
    return getRegionCacheByKey(getRegionElementCacheStorageKey(id));
  }

  function getLeagueCache(id: string): Ref<Maybe<LeagueElementCache>> {
    return getLeagueCacheByKey(getLeagueElementCacheStorageKey(id));
  }

  function getSportEventCache(id: string): Ref<Maybe<SportEventElementCache>> {
    return getSportEventCacheByKey(getSportEventElementCacheStorageKey(id));
  }

  function getMarketCache(id: string): Ref<Maybe<SportEventMarketsCache>> {
    return getMarketCacheByKey(getSportEventMarketsCacheStorageKey(id));
  }

  function invalidateEventCache(id: string): void {
    const key = getSportEventElementCacheStorageKey(id);
    const cache = internalSportEventElementCacheLastActualize[key];
    if (!cache) { return; }
    internalSportEventElementCacheLastActualize = Object.freeze({
      ...internalSportEventElementCacheLastActualize,
      [key]: 0,
    });
  }

  function invalidateMarketCache(id: string): void {
    const key = getSportEventMarketsCacheStorageKey(id);
    const cache = internalSportEventMarketsCacheLastActualize[key];
    if (!cache) { return; }
    internalSportEventMarketsCacheLastActualize = Object.freeze({
      ...internalSportEventMarketsCacheLastActualize,
      [key]: 0,
    });
  }

  return {
    updateDataInStorageByResponse,
    clearInvalidCache,

    getSportCache,
    getRegionCache,
    getLeagueCache,
    getSportEventCache,
    getMarketCache,

    invalidateEventCache,
    invalidateMarketCache,

    getSportCacheByKey,
    getRegionCacheByKey,
    getLeagueCacheByKey,
    getSportEventCacheByKey,
    getMarketCacheByKey,
  };
});

export default useSportlineCacheStorage;
