import { logger } from '@leon-hub/logging';
import { assert } from '@leon-hub/guards';
import { Timer } from '@leon-hub/utils';

import { webSocketDocuments } from '../sdk-ws';
import type { WebSocketSdk } from '../sdk-ws';
import type WebSocketGraphQLMethod from '../types/WebSocketGraphQLMethod';
import type WsSubscriptionInput from '../types/WsSubscriptionInput';
import type WebSocketPollingFallback from '../types/WebSocketPollingFallback';
import type WebSocketAccessRole from '../enums/WebSocketAccessRole';
import isSubscriptionAllowed from '../helpers/isSubscriptionAllowed';

export default class WebSocketSubscription<T extends WebSocketGraphQLMethod> {
  public method: T;

  private readonly query: string;

  private readonly variables?: WebSocketSdk[T]['variables'];

  private readonly onMessage: WebSocketSdk[T]['onMessage'];

  private readonly polling?: WebSocketPollingFallback;

  private isWsEnabled?: boolean;

  private readonly access?: WebSocketAccessRole;

  private pollingInterval = 0;

  constructor({
    method,
    onMessage,
    variables,
    polling,
    access,
    isWsEnabled,
  }: {
    method: T;
    onMessage: WebSocketSdk[T]['onMessage'];
    variables?: WebSocketSdk[T]['variables'];
    polling?: WebSocketPollingFallback;
    access?: WebSocketAccessRole;
    isWsEnabled?: boolean;
  }) {
    this.method = method;
    this.query = webSocketDocuments[method];
    this.variables = variables;
    this.onMessage = onMessage;
    this.polling = polling;
    this.access = access;
    this.isWsEnabled = isWsEnabled;
  }

  setPollingTimeout(timeout: number): void {
    if (this.polling) {
      this.polling.timeout = timeout;

      if (this.pollingInterval) {
        this.startPollingRequestInternal();
      }
    }
  }

  setIsWsEnabled(isWsEnabled: boolean): void {
    this.isWsEnabled = isWsEnabled;
  }

  isAllowed({ isLoggedIn }: { isLoggedIn: boolean }): boolean {
    return isSubscriptionAllowed(isLoggedIn, this.access);
  }

  isWebSocketsEnabled(): boolean {
    return !!this.isWsEnabled;
  }

  isPollingMustBeCalledOnLogin(): boolean {
    return !!this.polling?.callOnLogin;
  }

  getWsSubscriptionInput(): WsSubscriptionInput {
    return {
      id: this.method,
      query: this.query,
      variables: this.variables,
      onMessage: this.onMessage,
      onError: (error) => {
        logger.error(`[WebSocket] Failed to subscribe to event: ${this.method}. Fallback to polling.`, error);
        this.startPollingRequest();
      },
    };
  }

  startPollingRequest(): void {
    if (this.pollingInterval) {
      return;
    }

    this.startPollingRequestInternal();
  }

  private startPollingRequestInternal() {
    if (this.polling) {
      this.stopPollingRequest();
      this.pollingInterval = Timer.setTimeout(() => {
        assert(this.polling);
        Promise.resolve(this.polling.callback())
          .then(() => this.startPollingRequestInternal());
      }, this.polling.timeout);
    }
  }

  callPollingRequest(): void {
    if (this.polling) {
      this.polling.callback();
      if (this.pollingInterval) {
        this.startPollingRequestInternal();
      }
    }
  }

  stopPollingRequest(): void {
    if (this.pollingInterval) {
      Timer.clearTimeout(this.pollingInterval);
      this.pollingInterval = 0;
    }
  }
}
