interface Payload {
  stake: number;
  winValue: number;
  taxPercent: number;
}

export function getTaxAmount({ stake, winValue, taxPercent }: Payload): number {
  /** https://confluence.leoncorp.net/display/SportCatalog/PROJ-453+Functional+Requirements
  * Tax (10%) = (Winnings – Stake)*0.1 */
  return (winValue - stake) * (taxPercent / 100);
}
