<script setup lang="ts">
import { BetCashoutMode } from 'web/src/modules/cashout/enums';
import { VLoaderDelayed } from 'web/src/components/Loader';

import { CashoutButton } from '../CashoutButton';
import useBetCashOutInline from './useBetCashOutInline';
import type { BetCashOutProps, BetCashOutInlineEmits } from '../../types';

const props = withDefaults(defineProps<BetCashOutProps>(), {
  price: 0,
  currency: 'USD',
  mode: BetCashoutMode.DEFAULT,
  errorMessage: '',
  betPrice: 0,
});

const emits = defineEmits<BetCashOutInlineEmits>();

const {
  isError,
  emitCashOut,
} = useBetCashOutInline(props, emits);
</script>

<template>
  <div v-auto-id="'BetCashOutInline'" :class="$style['cash-out-inline']">
    <VLoaderDelayed
      v-if="!isLoaded"
    />
    <CashoutButton
      v-else-if="!isError"
      :cashout-amount="price"
      :stake-amount="betPrice"
      @click="emitCashOut"
    />
  </div>
</template>

<style module lang="scss">
.cash-out-inline {
  display: block;
  width: 100%;
}
</style>
