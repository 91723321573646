import type { Ref } from 'vue';
import { ref } from 'vue';

import { defineStatefulComposable } from 'web/src/shared/store';

export interface UseIsLoggedIn {
  isLoggedIn: Ref<boolean>;
}

const useIsLoggedIn: () => UseIsLoggedIn = defineStatefulComposable((): UseIsLoggedIn => {
  const isLoggedIn = ref(false);

  return {
    isLoggedIn,
  };
});

export default useIsLoggedIn;
export { useIsLoggedIn };
