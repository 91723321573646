export enum ConfirmEmailStage {
  EMAIL_UNKNOWN = 'email_unknown',
  EMAIL_IS_NOT_CONFIRMED = 'email_is_not_confirmed',
  WAIT_FOR_CONFIRMATION = 'wait_for_confirmation',
  TOO_MANY_REQUESTS = 'too_many_requests',
  PENDING_CHECK = 'pending_check',
  CONFIRM_SUCCESS = 'confirm_success',
  ALREADY_CONFIRMED = 'already_confirmed',
  TECH_ERROR = 'tech_error',
  WRONG_TOKEN = 'wrong_token',
}
