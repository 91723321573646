<script lang="ts" setup>
import { onBeforeMount, onBeforeUnmount } from 'vue';

import { IconSize } from '@leon-hub/icons';

import { JumbotronFooterButton, JumbotronIconKind, VJumbotron } from 'web/src/components/Jumbotron';
import { ButtonHeight, ButtonKind } from 'web/src/components/Button/VButton/enums';
import { PaymentsDepositStatus } from 'web/src/modules/payments/enums';

import { usePaymentsDepositsOkFailController } from './composables';
import type { PaymentsDepositsOkFailControllerProps } from './types';

const props = withDefaults(defineProps<PaymentsDepositsOkFailControllerProps>(), {
  status: PaymentsDepositStatus.SUCCESS,
});

const {
  beforeMount,
  beforeUnmount,
  onClick,
} = usePaymentsDepositsOkFailController(props);

onBeforeMount(beforeMount);
onBeforeUnmount(beforeUnmount);
</script>

<template>
  <div v-auto-id="'PaymentsDepositsOkFailController'"
    :class="{
      [$style['payments-depositok']]: true,
      [$style['payments-depositok--margin-top']]: margin,
      [$style['payments-depositok--separate-page']]: closeWindow,
    }"
  >
    <VJumbotron
      :class="$style['payments-depositok--content']"
      :icon-size="status === PaymentsDepositStatus.FAIL ? IconSize.SIZE_60 : IconSize.SIZE_80"
      :icon="iconName ?? ''"
      :heading="title"
      :text="message"
      :icon-kind="status === PaymentsDepositStatus.FAIL ? JumbotronIconKind.DEFAULT : JumbotronIconKind.SUCCESS"
    >
      <template #footer>
        <JumbotronFooterButton
          :label="$t('JS_BETSLIP_CONFIRMATION_CLOSE')"
          :kind="null ? 'primary' : ButtonKind.BASE"
          :height="ButtonHeight.LARGE"
          full-width
          @click="onClick"
        />
      </template>
    </VJumbotron>
  </div>
</template>

<style lang="scss" module>
.payments-depositok {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px;
  padding: 16px 16px 20px;
  margin: 0 auto;
  text-align: center;

  &--margin-top {
    margin: 80px 20px;
  }

  &--separate-page {
    min-height: calc(100vh - 60px);
  }

  @include is-app-layout-desktop {
    @include paymentsDepositsOkFailControllerDesktop;

    &--separate-page {
      justify-content: center;
      min-height: 100vh;
      padding-top: 0;
    }
  }
}
</style>
