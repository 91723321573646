import type { Ref } from 'vue';
import { ref, computed } from 'vue';

import { logger } from '@leon-hub/logging';
import { getSportradarStatisticsConfiguration } from '@leon-hub/api-sdk';

import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';

import type {
  SportradarStatisticsWidgetConfig,
  SportradarStatisticsWidgetFrameConfig,
} from '../types';

interface UseSportradarStatisticsConfigProps {
  iframeUrl: Ref<Optional<string>>;
}

interface UseSportradarStatisticsConfigComposable {
  configFinal: Ref<Maybe<SportradarStatisticsWidgetFrameConfig>>;
  fetchConfig(): Promise<void>;
}

export function useSportradarStatisticsConfigStoreComposable(
  props: UseSportradarStatisticsConfigProps,
): UseSportradarStatisticsConfigComposable {
  const { iframeUrl } = props;

  const api = useGraphqlClient();

  const config = ref<Maybe<SportradarStatisticsWidgetConfig>>(null);

  const configFinal = computed<Maybe<SportradarStatisticsWidgetFrameConfig>>(() => (
    config.value
      ? {
        ...config.value,
        iframeUrl: iframeUrl.value,
      }
      : null
  ));

  async function fetchConfig(): Promise<void> {
    try {
      if (config.value) { return; }

      config.value = await getSportradarStatisticsConfiguration(
        api,
        (node) => node.queries.configContent.getSportRadarStatisticsConfiguration,
        { options: {} },
      );
    } catch (error) {
      logger.error('An error occurred while updating Sportradar Statistics data', error);
    }
  }

  return {
    configFinal,
    fetchConfig,
  };
}
