import type { Ref } from 'vue';
import { toRef, computed } from 'vue';

import { useSiteConfigStore } from 'web/src/modules/core/store';

interface UseResetPersistLocationSettingsComposable {
  isResetLocationEnabled: Ref<boolean>;
}

export function useResetPersistLocationSettings(): UseResetPersistLocationSettingsComposable {
  const siteConfigStore = useSiteConfigStore();
  const sportLineBlock = toRef(siteConfigStore, 'sportLineBlock');

  const isResetLocationEnabled = computed(() => !!sportLineBlock.value?.familyFilterResetEnabled);

  return { isResetLocationEnabled };
}
