import type { ComputedRef, Ref } from 'vue';
import {
  nextTick,
  computed,
  ref,
} from 'vue';

import { getLocationQueryParams } from '@leon-hub/service-locator-env';

import type { VLazyImageProps, VLazyImageEmits } from '../types';

export interface VLazyImageComposable {
  isNativeLazyMethod: () => boolean;
  imageLoadCompleted: Ref<boolean>;
  visibilityChanged: (isVisible: boolean) => void;
  forceShowImage: () => void;
  onImageLoad: () => Promise<void>;
  showImage: ComputedRef<boolean>;
}

function isNativeLazyMethod() {
  const value = getLocationQueryParams()['feature-lazy-img-type'];
  if (value === 'observer') return false;
  if (value === 'native') return true;
  return 'loading' in HTMLImageElement.prototype;
}

export default function useVLazyImage(props: VLazyImageProps, emit: VLazyImageEmits, options: { initial?: boolean } = {}): VLazyImageComposable {
  const initial = ref(!!options.initial);

  const imageLoadCompleted = ref(false);

  function visibilityChanged(isVisible: boolean): void {
    if (!process.env.VUE_APP_RENDERING_SSR && isVisible) {
      initial.value = true;
    }
  }

  function forceShowImage(): void {
    initial.value = true;
  }

  async function onImageLoad(): Promise<void> {
    if (isNativeLazyMethod()) {
      visibilityChanged(true);
    }
    await nextTick();
    // await this.$nextAnimationFrame();
    imageLoadCompleted.value = true;
    emit('image-loaded');
  }

  const showImage = computed<boolean>(() => isNativeLazyMethod() || (initial.value && !props.forceHideImage));

  return {
    isNativeLazyMethod,
    imageLoadCompleted,
    visibilityChanged,
    forceShowImage,
    onImageLoad,
    showImage,
  };
}
