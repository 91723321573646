import { ref, computed, toRef } from 'vue';
import { defineStore } from 'pinia';

import { isBoolean, isNumber } from '@leon-hub/guards';

import { BetInputErrorKind } from 'web/src/modules/fast-bets/enums';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useCurrency } from 'web/src/modules/money/composables';
import { getFastBetInputError } from 'web/src/modules/fast-bets/utils';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import { useUserStore } from 'web/src/modules/user/store';

import type { BetInputLimitations, SlipConfigurationPayload } from '../types';
import { SettingsTabs } from '../../../enums';
import { requestSaveBetSlipConfiguration } from '../utils';

const useSlipUserSettings = defineStore('slip-user-settings', () => {
  const graphqlClient = useGraphqlClient();

  const customerDataStore = useCustomerDataStore();

  const siteConfigStore = useSiteConfigStore();

  const { loadCustomerData } = useUserStore();

  const slipScgSettings = toRef(siteConfigStore, 'slipBlock');

  const standardBetAmount = toRef(customerDataStore, 'standardBetAmount');

  const useStandardBet = toRef(customerDataStore, 'useStandardBet');

  const customerData = toRef(customerDataStore, 'customerData');

  const showSlipOnFirstAdded = toRef(customerDataStore, 'showSlipOnFirstAdded');

  const { updateLocalCustomerData } = customerDataStore;

  const { isLoggedIn } = useIsLoggedIn();

  const { currency } = useCurrency();

  const activeSettingsTabId = ref<SettingsTabs>(SettingsTabs.COMMON_SETTINGS);

  const standardBetAmountIsSaved = ref<boolean>(false);

  const standardBetAmountValue = ref<string>('');

  const standardBetInputErrorKind = ref<BetInputErrorKind>(BetInputErrorKind.NONE);

  const selectSettingsTab = (id: SettingsTabs): void => {
    activeSettingsTabId.value = id;
  };

  const setStandardBetAmountIsSaved = (isSaved: boolean): void => {
    standardBetAmountIsSaved.value = isSaved;
  };

  const setStandardBetAmountValue = (value: string): void => {
    standardBetAmountValue.value = value;
  };

  const setStandardBetInputErrorKind = (errorKind: BetInputErrorKind): void => {
    standardBetInputErrorKind.value = errorKind;
  };

  const standardBetLimitations = computed<BetInputLimitations | null>(() => {
    const fullBlock = slipScgSettings.value?.standardBetLimits;
    if (!fullBlock) {
      return null;
    }
    const matchedLimits = fullBlock.find((item) => item.currency === currency.value);
    if (!matchedLimits) {
      return null;
    }
    return { min: matchedLimits.min, max: matchedLimits.max };
  });

  const validateStandardBetInput = (value: string): void => {
    const error = getFastBetInputError({
      input: value,
      max: standardBetLimitations.value?.max || null,
      min: standardBetLimitations.value?.min || 0,
      isRequired: false,
    });
    setStandardBetInputErrorKind(error);
  };

  const onStandardBetFocus = (): void => {
    if (standardBetAmount.value) {
      setStandardBetAmountValue(`${standardBetAmount.value}`);
    }
    setStandardBetAmountIsSaved(false);
  };

  const onStandardBetInput = (value: string): void => {
    setStandardBetAmountIsSaved(false);
    validateStandardBetInput(value);
    setStandardBetAmountValue(value);
  };

  const saveBetSlipConfiguration = async (payload: SlipConfigurationPayload): Promise<void> => {
    if (!isLoggedIn.value || !customerData.value) {
      return;
    }
    const standardBetAmountChanged = isNumber(payload.standardBetAmount)
      && payload.standardBetAmount !== customerData.value.standardBetAmount;

    const requestPayload = {
      showSlipOnFirstAdded: isBoolean(payload.showSlipOnFirstAdded)
        ? payload.showSlipOnFirstAdded : customerData.value.showSlipOnFirstAdded,
      sameStakeForSingleByDefault: isBoolean(payload.sameStakeForSingleByDefault)
        ? payload.sameStakeForSingleByDefault : customerData.value.sameStakeForSingleByDefault,
      useStandardBet: isBoolean(payload.useStandardBet)
        ? payload.useStandardBet : customerData.value.useStandardBet,
      standardBetAmount: isNumber(payload.standardBetAmount)
        ? payload.standardBetAmount : customerData.value.standardBetAmount,
    };
    try {
      updateLocalCustomerData(requestPayload);
      await requestSaveBetSlipConfiguration(graphqlClient, requestPayload);
      if (standardBetAmountChanged) {
        setStandardBetAmountIsSaved(true);
      }
    } finally {
      void loadCustomerData();
    }
  };

  const onStandardBetBlur = (): void => {
    validateStandardBetInput(standardBetAmountValue.value);
    if (standardBetInputErrorKind.value !== BetInputErrorKind.NONE) {
      return;
    }
    const nextStandardBetAmount = Number(standardBetAmountValue.value);
    if (!useStandardBet.value || nextStandardBetAmount === standardBetAmount.value) {
      return;
    }
    void saveBetSlipConfiguration({ standardBetAmount: nextStandardBetAmount });
  };

  const clearEditStandardBet = (): void => {
    setStandardBetInputErrorKind(BetInputErrorKind.NONE);
    setStandardBetAmountValue('');
  };

  return {
    activeSettingsTabId,
    standardBetAmountIsSaved,
    standardBetAmountValue,
    standardBetInputErrorKind,
    useStandardBet,
    standardBetAmount,
    showSlipOnFirstAdded,
    standardBetLimitations,
    selectSettingsTab,
    onStandardBetFocus,
    onStandardBetInput,
    onStandardBetBlur,
    clearEditStandardBet,
    saveBetSlipConfiguration,
  };
});

export default useSlipUserSettings;
