import type { Ref, ComputedRef } from 'vue';
import { computed, ref, watch } from 'vue';

import { assert } from '@leon-hub/guards';

import createUniqueId from 'web/src/utils/createUniqueId';
import { InputEventType } from 'web/src/components/Input/enums';

import type { FileSingleInputProps, VInputFileEvent, FileSingleInputEmits } from '../../types';

interface UseFileSingleInput {
  uniqueId: ComputedRef<string>;
  inputFileName: Ref<string>;
  onFocus: () => void;
  onBlur: () => void;
  onClear: () => void;
  onChange: (event: Event) => void;
}

export default function useFileSingleInput(
  props: FileSingleInputProps,
  emit: FileSingleInputEmits,
): UseFileSingleInput {
  const uniqueId = computed<string>(() => createUniqueId(props.name || 'input'));

  const inputFileName = ref<string>(props.fileName || '');

  const file = ref<FileList | null>(null);

  const eventToEmit = computed<VInputFileEvent>(() => ({
    target: {
      files: file.value,
      name: props.name || '',
    },
  }));

  const onFocus = (): void => {
    emit(InputEventType.FOCUS, eventToEmit.value);
  };

  const onBlur = (): void => {
    emit(InputEventType.BLUR, eventToEmit.value);
  };

  const emitChange = (): void => {
    emit(InputEventType.CHANGE, eventToEmit.value);
  };

  const onClear = (): void => {
    inputFileName.value = '';
    file.value = null;
    emitChange();
  };

  const onChange = ({ target }: Event): void => {
    assert(target instanceof HTMLInputElement);
    const files = target.files || [];
    if (files.length) {
      const newFile = files[0] || {};
      if (newFile.name !== inputFileName.value) {
        file.value = target.files;
        inputFileName.value = newFile.name;

        emitChange();
      }
    }
  };

  const onFileNameChange = (value = '') => {
    inputFileName.value = value || '';
  };

  watch(() => props.fileName, onFileNameChange);

  return {
    uniqueId,
    inputFileName,
    onFocus,
    onBlur,
    onClear,
    onChange,
  };
}
