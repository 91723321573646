import { ApiErrorCode } from '../../errors/ApiErrorCode';
import GqlApiBatchError from './GqlApiBatchError';
import type { GqlApiErrorOptions } from './GqlApiError';

export interface GqlApiBatchUnknownHashErrorOptions extends GqlApiErrorOptions {
  requestId: string;
}

export default class GqlApiBatchUnknownHashError extends GqlApiBatchError {
  public requestId: string;

  constructor(options: GqlApiBatchUnknownHashErrorOptions) {
    super({
      ...options,
      code: ApiErrorCode.API_UNKNOWN_HASH,
    });

    this.requestId = options.requestId;
  }
}
