import isNavigationItemActive from './isNavigationItemActive';
import type NavigationItem from '../types/NavigationItem';

const setNavigationBreadcrumbs = (
  navigationItems: NavigationItem[],
  routeName?: string | null | symbol,
): NavigationItem[] => navigationItems.map((navigationItem: NavigationItem) => {
  const isActive = isNavigationItemActive(navigationItem, routeName);
  if (isActive) {
    let children;

    if (navigationItem.children?.length) {
      children = setNavigationBreadcrumbs(navigationItem.children, routeName);
    }
    return { ...navigationItem, isActive, children } as Readonly<NavigationItem>;
  }

  return { ...navigationItem } as Readonly<NavigationItem>;
});

export default setNavigationBreadcrumbs;
