import { computed } from 'vue';
import { useRouter } from 'vue-router';

import type { VLinkPreventedProps } from '../types';
import type { VLinkPreventedComposable } from './types';

export default function useVLinkPrevented(props: VLinkPreventedProps): VLinkPreventedComposable {
  const router = useRouter();

  const target = computed(() => router.resolve(props.to));

  const href = computed(() => target.value.href);

  return { href };
}
