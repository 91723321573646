import { InputEventType } from 'web/src/components/Input/enums';

import type { VCheckboxEmits } from '../types';

interface UseCheckboxEvents {
  onChange: (event: Event) => void;
  onInput: (event: Event) => void;
  onFocus: (event: FocusEvent) => void;
  onBlur: (event: FocusEvent) => void;
}

export default function useCheckboxEvents(emit: VCheckboxEmits): UseCheckboxEvents {
  const onChange = (event: Event): void => {
    emit(InputEventType.CHANGE, event);
  };
  const onInput = (event: Event): void => {
    emit(InputEventType.INPUT, event);
  };
  const onFocus = (event: FocusEvent): void => {
    emit(InputEventType.FOCUS, event);
  };
  const onBlur = (event: FocusEvent): void => {
    emit(InputEventType.BLUR, event);
  };
  return {
    onInput,
    onChange,
    onFocus,
    onBlur,
  };
}
