import { logger } from '@leon-hub/logging';

import type { TrackBetErrorPayload } from '../types';

export default function logPlaceBetResultError({
  errorCode,
  errorMessage,
  slipStatus,
  slipMessage,
}: TrackBetErrorPayload): void {
  const payload = {
    errorMessage,
    errorCode,
    slipStatus,
    slipMessage,
  };
  if (!errorCode && slipStatus) {
    logger.info('Place bet slipStatus', payload);
    return;
  }
  if (errorCode && errorCode.includes('freebet')) {
    logger.error('Place bet freebet', payload);
    return;
  }
  logger.error('Place bet error', payload);
}
