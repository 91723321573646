import type { BannerGroup, Banner } from '../types';

interface Payload {
  bannerGroups: BannerGroup[];
  groupId: string;
  updatedBanner: Banner;
}

export function updateSingleBannerInGroups({
  groupId, bannerGroups, updatedBanner,
}: Payload): BannerGroup[] {
  const { bannerId } = updatedBanner;
  const matchedGroupIndex = bannerGroups.findIndex((group) => group.id === groupId);
  if (matchedGroupIndex < 0) {
    return bannerGroups;
  }
  const matchedBannersGroup = [...bannerGroups[matchedGroupIndex].banners];
  const matchedBannersIndex = matchedBannersGroup.findIndex((banner) => banner.bannerId === bannerId);
  if (matchedBannersIndex < 0) {
    return bannerGroups;
  }
  matchedBannersGroup[matchedBannersIndex] = updatedBanner;
  const result: BannerGroup[] = [...bannerGroups];
  result[matchedGroupIndex].banners = matchedBannersGroup;

  return result;
}
