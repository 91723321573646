import { LobbyItemType } from '@leon-hub/api-sdk';

import type {
  LobbyItemSdk,
  LobbyPromotionsItem,
} from 'web/src/modules/lobby/types';

export default function isLobbyPromotionsItem(input: LobbyItemSdk): input is LobbyPromotionsItem {
  return input.typeId === LobbyItemType.PROMOTIONS;
}
