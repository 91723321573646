import { checkWebpFeature } from 'web/src/utils/checkWebpFeature';

const webpFeatures = ['alpha', 'lossy', 'lossless'];

const isWebpSupported = process.env.VUE_APP_RENDERING_SSR
  ? Promise.resolve(false)
  : Promise.all(webpFeatures.map((item) => checkWebpFeature(item)))
    .then((result) => !result.includes(false));

export default isWebpSupported;
