<script lang="ts" setup>
import { toRef } from 'vue';

import { usePostMatchHeadlineInfo } from '../../composables';
import type { PostMatchHeadlineInfoProps } from '../../types';
import HeadlineTeam from './components/HeadlineTeam.vue';

const props = defineProps<PostMatchHeadlineInfoProps>();
const kickoff = toRef(props, 'kickoff', null);
const { date } = usePostMatchHeadlineInfo({ kickoff });
</script>

<template>
  <div v-auto-id="'PostMatchInfo'" :class="$style['headline-info']">
    <div
      :class="[
        $style['headline-info__stage'],
        $style['headline-info__stage--post-match'],
      ]"
    >
      <label :class="$style['headline-info__stage-completed-line']">
        {{ $t('WEB2_SPORT_EVENT_FINISHED') }}
      </label>
    </div>

    <div
      v-if="date"
      :class="[
        $style['headline-info__date'],
        $style['headline-info__date--gap-after'],
      ]"
    >
      <span :class="$style['headline-info__day']">{{ date }}</span>
    </div>

    <div :class="$style['headline-info__score-line']">
      <HeadlineTeam
        position="left"
        role="host"
        :name="competitors.host.name"
        :score="totalScore.host"
      />

      <span :class="$style['headline-info__divider']" />

      <HeadlineTeam
        position="right"
        role="guest"
        :name="competitors.guest.name"
        :score="totalScore.guest"
      />
    </div>

    <div
      v-if="penaltyScore"
      :class="[
        $style['headline-info__stage'],
        $style['headline-info__stage--penalty-score'],
      ]"
    >
      <label
        :class="$style['headline-info__penalty-label']"
      >{{ $t('WEB2_SPORT_EVENT_PENALTY_SHOOTOUT_SCORE') }}:</label>
      <label
        :class="$style['headline-info__penalty-score-line']"
      >{{ penaltyScore.host }} : {{ penaltyScore.guest }}</label>
    </div>
  </div>
</template>

<style module lang="scss">
@import 'web/src/modules/sportline/submodules/event-details/styles/headline-desktop';
</style>
