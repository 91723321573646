/* eslint-disable class-methods-use-this */
import type {
  RouteLocationNormalized,
  NavigationGuardNext,
  RouteLocationRaw,
} from 'vue-router';

import { RouteName } from '@leon-hub/routing-config-names';

import { useSportlinePersistLocation } from 'web/src/modules/sportline/submodules/persist-filters';
import { useSportlineSportsStore } from 'web/src/modules/sportline/submodules/sports/store';
import type { SportDateTimeFilter } from 'web/src/modules/sportline/submodules/navigation/store/types';
import type { SportlinePageIdentifier as SportEventsPageIdentifier } from 'web/src/modules/sportline/types/navigation';
import { isSportEventsSportsDateTimeFilterChanged } from 'web/src/modules/sportline/submodules/sports/utils';
import { isOptionalFilterSportFamily } from 'web/src/modules/sportline/guards';
import { getSportPageNavigationParameters } from 'web/src/modules/sportline/utils';
import type { AppVueRouter } from 'web/src/modules/core/services/router/types';
import { useRootNavigationStore } from 'web/src/modules/core/store';

import BaseSportsPageNavigationService from './BaseSportsPageNavigationService';

export default class DefaultSportsPageNavigationService extends BaseSportsPageNavigationService {
  prefetch(
    router: AppVueRouter,
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ): void {
    super.prefetch(router, to, from, (superResult?: unknown) => {
      if (superResult !== undefined) {
        next(superResult as RouteLocationRaw);
        return;
      }

      const dateTimeFilter = this.getDateTimeFilter(to);
      const isFilterChanged = this.isFilterChanged(dateTimeFilter);
      const navigationStore = useRootNavigationStore();
      const sportlineSportsStore = useSportlineSportsStore();

      const { setPersistLocation } = useSportlinePersistLocation();
      setPersistLocation('sports', sportlineSportsStore.pageIdentifier);

      if (sportlineSportsStore.isLoaded && !isFilterChanged) {
        sportlineSportsStore.syncBackgroundRequests();
        navigationStore.setRouteContentLoaded(RouteName.SPORTLINE_PREMATCH_EVENTS);
        next();
        return;
      }

      navigationStore.setOnlyFirstRouteContentLoading(RouteName.SPORTLINE_PREMATCH_EVENTS);
      void sportlineSportsStore.initialRequests({ silent: false, dateTimeFilter }).then(() => {
        navigationStore.setRouteContentLoaded(RouteName.SPORTLINE_PREMATCH_EVENTS);
      });
      void sportlineSportsStore.awaitStateIsLoaded().then(() => {
        const { sportFamily } = getSportPageNavigationParameters(to);
        const selectedSport = useSportlineSportsStore().actualSportElement;

        // LEONWEB-3298 redirects for alias
        if (selectedSport && sportFamily && selectedSport.sport.navigationParameters.urlName !== sportFamily) {
          void router.push301({
            name: router.currentRoute.value.name || '',
            params: {
              family: selectedSport.sport.navigationParameters.urlName,
            },
          });
        }
      });

      next();
    });
  }

  checkAllowedFilter(navigationParameters: SportEventsPageIdentifier): RouteLocationRaw | undefined {
    const { sportFamily } = navigationParameters;
    const { allowedSportFamily, customFilters } = useSportlineSportsStore();

    return !isOptionalFilterSportFamily(sportFamily, allowedSportFamily, customFilters)
      ? { name: RouteName.SPORTLINE_PREMATCH_EVENTS }
      : undefined;
  }

  isFilterChanged(dateTimeFilter?: SportDateTimeFilter): boolean {
    return !!dateTimeFilter
      && isSportEventsSportsDateTimeFilterChanged(dateTimeFilter, useSportlineSportsStore().sportsListFilter);
  }
}
