import type { ComputedRef, Ref } from 'vue';
import { computed, toRef } from 'vue';
import { useRouter } from 'vue-router';

import {
  Events as AnalyticsEvent,
  TargetParamHistory,
} from '@leon-hub/yandex-metrika';
import { RouteName } from '@leon-hub/routing-config-names';
import { historyFilterAllBets } from '@leon-hub/api-sdk';

import { useCashoutStore } from 'web/src/modules/cashout/store';
import type { CashoutPayload } from 'web/src/modules/cashout/types';
import { ActiveOrderFilterEnum } from 'web/src/modules/profile/submodules/customer-history/types';
import { useAnalytics } from 'web/src/modules/analytics/composables';

import { getHistoryDetailsRoute } from '../utils';
import { TopLevelTabs } from '../enums';
import { usePendingBetsStore } from '../submodules/pending-bets/store';
import { useSlipViewSettingsStore } from '../submodules/slip-view-settings/store';
import { useSlipRootStore } from '../submodules/slip-root/store';
import type { SlipListHistoryItemData } from '../components/SlipListItem/types';

interface UseMyBetsView {
  myBetsList: Ref<SlipListHistoryItemData[]>;
  isPendingBetsLoaded: Ref<boolean>;
  isPendingBetsLoadingMore: Ref<boolean>;
  pendingBetsCount: Ref<number>;
  loadPendingBets: () => Promise<void>;
  loadMorePendingBets: () => Promise<void>;
  cashoutClick: (payload: CashoutPayload) => void;
  historyLinkClick: () => void;
  myBetsItemClick: (itemId: number) => void;
  historyUrl: ComputedRef<string>;
}

export default function useMyBetsView(): UseMyBetsView {
  const { handleSlipClose } = useSlipRootStore();

  const analytics = useAnalytics();

  const pendingBetsStore = usePendingBetsStore();

  const router = useRouter();

  const { loadPendingBets, loadMorePendingBets } = pendingBetsStore;

  const {
    selectTopTab,
    setSlipFullHeight,
  } = useSlipViewSettingsStore();

  const pendingBetsCount = toRef(pendingBetsStore, 'pendingBetsCount');

  const myBetsList = toRef(pendingBetsStore, 'myBets');

  const isPendingBetsLoaded = toRef(pendingBetsStore, 'isLoaded');

  const isPendingBetsLoadingMore = toRef(pendingBetsStore, 'isLoadingMore');

  const cashoutClick = (payload: CashoutPayload): void => {
    const { setSlipCashoutState, initCashout } = useCashoutStore();
    setSlipFullHeight(true);
    setSlipCashoutState(true);
    void initCashout(payload);
  };

  const historyLinkClick = () => {
    handleSlipClose();
    selectTopTab(TopLevelTabs.SLIP);
    analytics.push(AnalyticsEvent.CLICK_MAP, {
      clickCounter: {
        history: TargetParamHistory.BET_SLIP,
      },
    });
    void router.push({
      name: RouteName.CUSTOMER_TRANSACTIONS_HISTORY,
      query: {
        filter: historyFilterAllBets,
        activeOrderFilter: ActiveOrderFilterEnum.PENDING_BETS,
      },
    });
  };

  const myBetsItemClick = (itemId: number): void => {
    handleSlipClose();
    void router.push(getHistoryDetailsRoute(`${itemId}`));
  };

  const historyUrl = computed<string>(() => router.resolve({ name: RouteName.CUSTOMER_TRANSACTIONS_HISTORY }).href);

  return {
    myBetsList,
    pendingBetsCount,
    loadPendingBets,
    loadMorePendingBets,
    isPendingBetsLoaded,
    isPendingBetsLoadingMore,
    cashoutClick,
    historyLinkClick,
    myBetsItemClick,
    historyUrl,
  };
}
