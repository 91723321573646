<script setup lang="ts">
import type { FocusableInputRef } from '@leon-hub/focus';

import { VCoreDropdown } from '@components/core-dropdown';

import DropdownMenu from './DropdownMenu.vue';
import type { DropdownSelectProperties, DropdownSelectEmits } from '../types';
import { useDropdownSelect } from '../composables';
import DropdownWrapper from './DropdownWrapper.vue';

const props = withDefaults(defineProps<DropdownSelectProperties>(), {
  name: '',
  options: () => [],
  dropdownMinWidth: 0,
  selectedValue: '',
  selectedLabel: '',
  hideSelectedOption: true,
  itemsPerPage: 20,
});

const emits = defineEmits<DropdownSelectEmits>();

const {
  isDropdownShown,
  onFocus,
  onChange,
  onBlur,
  onButtonBlur,
  close,
  toggleOpen,
  focusOnDropdownButton,
  dropdownProps,
  dropdownSearchInput,
  optionsToRender,
  handleKeyDown,
  handleSearchInput,
  isSearchActive,
  searchQuery,
  clearSearchQuery,
  preselectedListIndex,
  setPreselectedIndex,
  renderMoreItems,
  setRefs,
} = useDropdownSelect(props, emits);

defineExpose<FocusableInputRef>({
  focus: focusOnDropdownButton,
});
</script>

<template>
  <DropdownWrapper v-auto-id="'DropdownSelect'"
    :ref="setRefs"
    :disabled="disabled"
    :has-icon-prefix="hasIconPrefix"
    :is-country-selector="isCountrySelector"
    :selected-label="selectedLabel"
    :selected-value="selectedValue"
    :placeholder="placeholder"
    :is-empty="isEmpty"
    :has-error="hasError"
    :is-search-active="isSearchActive"
    :is-large="isLarge"
    :is-dropdown-shown="isDropdownShown"
    @focus="onFocus"
    @blur="onButtonBlur"
    @click="toggleOpen"
    @keydown="handleKeyDown"
  >
    <template #search>
      <template v-if="isSearchActive">
        <input
          ref="dropdownSearchInput"
          type="text"
          autocomplete="off"
          inputmode="text"
          :class="{
            [$style['dropdown-select__input']]: true,
            [$style['dropdown-select__input--icon-prefix']]: hasIconPrefix,
            [$style['dropdown-select__input--large']]: isLarge,
          }"
          :placeholder="placeholder"
          :value="searchQuery"
          @blur="onBlur"
          @input="handleSearchInput"
          @keydown="handleKeyDown"
        >
        <button
          type="button"
          :title="$t('WEB2_CLOSE')"
          :class="$style['dropdown-select__close']"
          @click.stop="close"
        />
      </template>
    </template>
    <template #default>
      <template v-if="isDropdownShown">
        <VCoreDropdown
          v-if="dropdownProps"
          v-bind="dropdownProps"
          match-parent-width
          ignore-active-element-click
          :height-limit="296"
          @close="close"
        >
          <template #default="{maxHeight, directionTop, mounted, onContentRedraw}">
            <DropdownMenu
              ref="dropdownMenu"
              :name="name"
              :max-height="maxHeight"
              :direction-top="directionTop"
              :selected-value="selectedValue"
              :options="optionsToRender"
              :search-query="searchQuery"
              :preselected-list-index="preselectedListIndex"
              :is-country-selector="isCountrySelector"
              @change="onChange"
              @mounted="mounted"
              @clear-search-query="clearSearchQuery"
              @list-size-updated="onContentRedraw"
              @option-hover="setPreselectedIndex"
              @reached-bottom="renderMoreItems"
            />
          </template>
        </VCoreDropdown>
      </template>
    </template>
  </DropdownWrapper>
</template>

<style lang="scss" module>
@import 'web/src/components/Input/common';

.dropdown-select {
  &__input {
    $self: &;

    @include inputCommon;

    position: absolute;
    top: 0;
    left: 0;
    cursor: text;

    &--large {
      @include inputLarge;
    }

    &::placeholder {
      padding-right: $inputIconWidth;
      color: $inputPlaceholderDefaultColor;
    }

    &--large::placeholder {
      padding-right: $inputIconWidthLarge;
    }

    &--icon-prefix {
      padding-left: $inputIconWidth;
    }

    &--icon-prefix#{$self}--large {
      padding-left: $inputIconWidthLarge;
    }

    &--hidden {
      visibility: hidden;
    }
  }

  &__close {
    @include z-index(absolute-item);

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 44px;
    cursor: pointer;
    background: transparent;
    border: none;
    opacity: 0;
  }
}
</style>
