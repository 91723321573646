import { AbstractErrorCode } from '@leon-hub/errors';

export class AppErrorCode extends AbstractErrorCode {
  public constructor(code: string) {
    super(code);
  }

  static APP_UNEXPECTED_ERROR = new AppErrorCode('UNEXPECTED_ERROR');

  static APP_IGNORED_ERROR = new AppErrorCode('APP_IGNORED_ERROR');

  static SCRIPT_ERROR = new AppErrorCode('SCRIPT_ERROR');
}
