import type { NavigationItem } from '@leon-hub/navigation-config';

export default function getNavigationItemByKey(
  key: keyof NavigationItem,
  value: unknown,
  navigationItems?: NavigationItem[],
): NavigationItem | undefined {
  return (navigationItems || []).find((item) => item[key] === value
    || getNavigationItemByKey(key, value, item.children));
}
