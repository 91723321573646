<script lang="ts" setup>
import TheSidebarMenu from 'web/src/components/SidebarMenu/TheSidebarMenu/TheSidebarMenu.vue';
import PartnersMenuComponent from 'web/src/modules/sidebar/views/PartnersMenuComponent/PartnersMenuComponent.vue';

import { useSidebarMenuKnowHowRouteComponent } from './composables';

const { menuBootstrap, menuGeneric } = useSidebarMenuKnowHowRouteComponent();
</script>

<template>
  <div v-auto-id="'SidebarMenuKnowHowRouteComponent'">
    <TheSidebarMenu :menu="menuBootstrap" />
    <TheSidebarMenu :menu="menuGeneric" />
    <PartnersMenuComponent
      v-if="null"
    />
  </div>
</template>
