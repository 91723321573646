import { getCurrencySymbol } from 'web/src/modules/money/utils';
import type { EgsGameLimits } from 'web/src/modules/egs/types';

export function getBetRange(limits?: Maybe<EgsGameLimits>): string | undefined {
  if (!limits) {
    return undefined;
  }

  const { min, max } = limits;
  const minCurrencySymbol = getCurrencySymbol(min.currency);

  return `${minCurrencySymbol}${min.value} - ${max.value}`;
}
