import type { Ref } from 'vue';
import { computed, ref, toRef } from 'vue';

import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import type { DateTimeRange, SportEventsSportsDateTimeFilter } from 'web/src/modules/sportline/types';
import type { SportDateTimeFilter } from 'web/src/modules/sportline/submodules/navigation/store/types';
import { defaultListFilter } from 'web/src/modules/sportline/constants';
import { getFilterDateTimeInterval, getSportDateTimeFilterByValue } from 'web/src/modules/sportline/utils';

interface UseSportsListDateFilterStoreComposable {
  dateTimeFilter: Ref<SportDateTimeFilter>;
  dateTimeFilterInterval: Ref<DateTimeRange>;
  dateTimeFilterAbsoluteInterval: Ref<DateTimeRange>;
  setDateTimeFilter(value?: Maybe<SportEventsSportsDateTimeFilter>): void;
}

export function useSportsListDateFilterStoreComposable(): UseSportsListDateFilterStoreComposable {
  const sportlineSettingsStore = useSportlineSettingsStore();
  const doUseRelativeTimeForSportline = toRef(sportlineSettingsStore, 'doUseRelativeTimeForSportline');

  const dateTimeFilter = ref<SportDateTimeFilter>(defaultListFilter);

  /**
   * Get actual filters for requests
   * Used function because getter will cache return value
   * https://jira.leoncorp.net/browse/LEONWEB-1921
   */
  const dateTimeFilterInterval = computed<DateTimeRange>(() => getFilterDateTimeInterval({
    activeKickoffPeriod: dateTimeFilter.value.activeKickoffPeriod,
    sportsListFilter: dateTimeFilter.value,
    doUseRelativeTimeForSportline: doUseRelativeTimeForSportline.value,
  }));
  const dateTimeFilterAbsoluteInterval = computed<DateTimeRange>(() => getFilterDateTimeInterval({
    activeKickoffPeriod: dateTimeFilter.value.activeKickoffPeriod,
    sportsListFilter: dateTimeFilter.value,
    doUseRelativeTimeForSportline: false,
  }));

  function setDateTimeFilter(value?: Maybe<SportEventsSportsDateTimeFilter>): void {
    dateTimeFilter.value = getSportDateTimeFilterByValue(value) ?? defaultListFilter;
  }

  return {
    dateTimeFilter,
    dateTimeFilterInterval,
    dateTimeFilterAbsoluteInterval,
    setDateTimeFilter,
  };
}
