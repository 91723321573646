import { MobileAppOs } from '../enums';

export default function getMobileAppOs(): MobileAppOs | undefined {
  if (process.env.VUE_APP_OS_IOS) {
    return MobileAppOs.Ios;
  }

  if (process.env.VUE_APP_OS_ANDROID) {
    return MobileAppOs.Android;
  }

  return undefined;
}
