<script setup lang="ts">
import { toRef } from 'vue';

import { IconName } from '@leon-hub/icons';

import type { SportlineFragmentBasisKey } from 'web/src/modules/sportline/types/list';
import { PopperApplyTarget } from 'web/src/components/Popper/VPopper/enums';
import {
  ButtonHeight,
  ButtonKind,
} from 'web/src/components/Button/VButton/enums';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import VPopper from 'web/src/components/Popper/VPopper/VPopper.vue';

import {
  popoverProperties,
  useMarketsDropDown,
} from './useMarketsDropDown';

interface MarketsDropDownProps {
  basisKey: SportlineFragmentBasisKey;
}

const props = defineProps<MarketsDropDownProps>();
const basisKey = toRef(props, 'basisKey');

const {
  popperRef,
  selectedColumnId,
  dropdownOptions,
  title,
  onPopperShow,
  onActiveMarketTypeChange,
} = useMarketsDropDown({ basisKey });
</script>

<template>
  <div v-auto-id="'MarketsDropDown'" :class="$style['sport-event-list-markets-dropdown']">
    <VPopper
      v-bind="popoverProperties"
      ref="popperRef"
      :apply-target="PopperApplyTarget.Body"
      @show="onPopperShow"
    >
      <template #reference>
        <VButton
          :height="ButtonHeight.MEDIUM"
          :kind="ButtonKind.BASE"
          :icon-name="IconName.DROPDOWN"
          :is-uppercase="false"
          icon-right
          :label="title"
        />
      </template>
      <ul :class="$style['sport-event-list-markets-dropdown__list']">
        <li
          v-for="item in dropdownOptions"
          :key="item.value"
          :class="{
            [$style['sport-event-list-markets-dropdown__list-item']]: true,
            [$style['sport-event-list-markets-dropdown__list-item--selected']]: item.value === selectedColumnId,
          }"
          @click="onActiveMarketTypeChange(item.value)"
        >
          {{ item.label }}
        </li>
      </ul>
    </VPopper>
  </div>
</template>

<style module lang="scss">
.sport-event-list-markets-dropdown {
  @include z-index(sticky-sport);

  position: relative;
  height: 100%;
  cursor: pointer;
  user-select: none;

  &__list {
    display: flex;
    flex-direction: column;
    padding: 2px;
    margin: 0;
    list-style: none;
    background-color: var(--Layer2);
    border-radius: if($isEnvFeatureTwinlikeStyle, 15px, 5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);

    &-item {
      @include medium\14\20\025;

      display: flex;
      align-items: center;
      padding: 12px;
      color: var(--TextDefault);

      &--selected,
      &:hover {
        color: var(--Layer2);
        cursor: pointer;
        background-color: var(--TextDefault);
        border-radius: if($isEnvFeatureTwinlikeStyle, 13px, 3px);
      }
    }
  }
}
</style>
