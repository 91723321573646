import {
  ref,
  toRef,
  watch,
  computed,
} from 'vue';
import { defineStore } from 'pinia';

import {
  CategoryType,
  getPromotions,
} from '@leon-hub/api-sdk';

import { useSiteConfigStore } from 'web/src/modules/core/store';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { useModuleTimeout } from 'web/src/modules/core/store/composables';
import type { Promotion } from 'web/src/modules/promotions/submodules/top/types';
import { BackgroundUpdateStopwatch } from 'web/src/utils/store';

const defaultHomePromotionsCategoryId = 'offers';
const defaultHomePromotionsCategoryType = CategoryType.CUSTOM;

export const usePromotionsTopStore = defineStore('promotions-top', () => {
  const siteConfigStore = useSiteConfigStore();
  const promotionsBlock = toRef(siteConfigStore, 'promotionsBlock');
  const { isLoggedIn } = useIsLoggedIn();
  const { timeout: updateInterval } = useModuleTimeout('promotions-top');

  const promotions = ref<Maybe<Promotion[]>>(null);

  const isReady = computed<boolean>(() => promotions.value !== null);

  function setPromotions(value: Promotion[]): void {
    promotions.value = value;
  }

  const lastUpdate = new BackgroundUpdateStopwatch<['promotions']>({
    promotions: async () => {
      try {
        const data = await getPromotions(useGraphqlClient(),
          (node) => node.queries.promotions.getPromotions,
          {
            options: {
              categoryId: promotionsBlock.value?.promotionsHomepageCategoryId
                || defaultHomePromotionsCategoryId,
              categoryType: defaultHomePromotionsCategoryType,
            },
          });

        setPromotions(data.promotions);
      } catch (error) {
        if (promotions.value === null) {
          setPromotions([]);
        }

        throw error;
      } finally {
        lastUpdate.update('promotions');
      }
    },
  });

  async function initialRequests(): Promise<void> {
    await lastUpdate.forceCallSyncAction('promotions', { silent: true });
  }

  async function syncBackgroundRequests(): Promise<void> {
    await lastUpdate.callSyncAction('promotions', updateInterval.value);
  }

  // init
  // promotions are not fetched in background (syncState), but with syncs on page enter
  watch(isLoggedIn, () => {
    initialRequests();
  });

  return {
    isReady,
    promotions,
    setPromotions,
    initialRequests,
    syncBackgroundRequests,
  };
});
