import { addLeadingZero } from '@leon-hub/utils';

export function totalSecondsToPretty(time?: number, shortFormat?: boolean): string {
  const totalSeconds = time ?? 0;

  let seconds = totalSeconds % 60;
  seconds = seconds < 0 ? 0 : seconds;

  let minutes = Math.floor((totalSeconds - seconds) / 60);
  minutes = minutes < 0 ? 0 : minutes;

  return shortFormat
    ? `${addLeadingZero(minutes, 2)}`
    : `${addLeadingZero(minutes, 2)}:${addLeadingZero(seconds, 2)}`;
}
