import type { Ref } from 'vue';
import { toRef, computed } from 'vue';

import { IconSize } from '@leon-hub/icons';
import { AccountHistoryStatus, BetType } from '@leon-hub/api-sdk';

import type { VIconProps } from '@components/v-icon';

import {
  defaultDateTimeFormat,
  getBetEventDescription,
} from 'web/src/modules/profile/submodules/customer-history/utils';
import { useFormatMoney } from 'web/src/modules/money/composables';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useBetPrice } from 'web/src/modules/profile/submodules/customer-history/composables';
import { getCurrencyIcon } from 'web/src/modules/money/utils';

import type { CustomerHistoryListItemBetProps } from '../types';

export interface CustomerHistoryListItemBetComposable {
  isLive: Ref<boolean>;
  isCombi: Ref<boolean>;
  isCalculated: Ref<boolean>;
  iconProperties: Ref<VIconProps>;
  predictionLabel: Ref<string>;
  statusProperties: Ref<string>;
  dateProperties: Ref<string>;
  eventDescription: Ref<Maybe<string>[]>;
  betPrice: Ref<string | number>;
  debitProperties: Ref<string>;
  creditValue: Ref<string>;
  predictionChoice: Ref<string>;
}

// eslint-disable-next-line max-len
export default function useCustomerHistoryListItemBet(props: CustomerHistoryListItemBetProps): CustomerHistoryListItemBetComposable {
  const { $translate } = useI18n();
  const $formatMoney = useFormatMoney();
  const { betPrice } = useBetPrice(toRef(props, 'item'));

  const iconProperties = computed<VIconProps>(() => {
    const name = getCurrencyIcon(props.currency);
    return {
      size: IconSize.SIZE_16,
      name,
    };
  });

  const isLive = computed<boolean>(() => props.item.status === AccountHistoryStatus.BET_WAITING);

  const isCombi = computed<boolean>(() => {
    switch (props.item.betType) {
      case BetType.EXPRESS:
      case BetType.SYSTEM:
        return true;
      default:
        return false;
    }
  });

  const isCalculated = computed<boolean>(
    () => props.item.status === AccountHistoryStatus.BET_CALCULATED
      || props.item.status === AccountHistoryStatus.BET_CALCULATED_CASHOUT,
  );

  const statusProperties = computed<string>(
    () => (isLive.value ? $translate('WEB2_CUSTOMER_HISTORY_IN_GAME').value : props.item.statusName),
  );

  const dateProperties = computed<string>(() => defaultDateTimeFormat(props.item.date));

  const eventDescription = computed<Maybe<string>[]>(() => {
    const { betType, betEventParts } = props.item;
    if (betEventParts) {
      return getBetEventDescription(betType, $translate, [...betEventParts]);
    }
    return [];
  });

  const debitProperties = computed<string>(() => $formatMoney(props.item.debit || 0));

  const predictionLabelBtrParams = computed(() => ({ COUNT: `${props.item.countItems}` }));

  const predictionLabel = computed<string>(() => {
    const {
      betType,
      combiStatusText,
      betMarket,
    } = props.item;
    let text = '';
    if (betType === BetType.SINGLE) {
      text = betMarket || '';
    }
    if (betType === BetType.SYSTEM) {
      text = combiStatusText || '';
    }
    if (betType === BetType.EXPRESS) {
      text = $translate('WEB2_BET_EXPRESS_MULTIPLICITY', predictionLabelBtrParams).value;
    }

    return text;
  });

  const predictionChoice = computed<string>(() => (props.item?.betOutcome ? props.item.betOutcome : ''));

  const creditValue = computed<string>(() => {
    const { credit, debit } = props.item;
    return credit > 0 ? `+${$formatMoney(credit)}` : `-${$formatMoney(debit)}`;
  });

  return {
    isLive,
    isCombi,
    isCalculated,
    iconProperties,
    predictionLabel,
    statusProperties,
    dateProperties,
    eventDescription,
    betPrice,
    debitProperties,
    creditValue,
    predictionChoice,
  };
}
