import type { SportDateTimeFilter } from 'web/src/modules/sportline/submodules/navigation/store/types';
import type { SportEvent, SportEventElement } from 'web/src/modules/sportline/types';

import { getDateTimeRangeForFilter } from './getDateTimeRangeForFilter';
import { getLeagueElementKeyForFilter } from './getLeagueElementKeyForFilter';

function isSportEventElementFitsTheFilter(sportEventElement: SportEventElement, filter: SportDateTimeFilter): boolean {
  const { sportlineType } = filter;
  const { type, kickoff } = sportEventElement.sportEvent;

  if (type === sportlineType) { return true; }

  const dateTimeRange = getDateTimeRangeForFilter(filter);
  if (!dateTimeRange?.from || !dateTimeRange?.to) { return false; }

  return (kickoff >= dateTimeRange.from && kickoff <= dateTimeRange.to);
}

function findFilterKeyForSportEventElement(
  sportEventElement: SportEventElement,
  filtersMap: Dictionary<SportDateTimeFilter>,
): Optional<string> {
  return Object.keys(filtersMap).find((key) => isSportEventElementFitsTheFilter(sportEventElement, filtersMap[key]));
}

export function getFiltersMap(baseKey: string, filters: SportDateTimeFilter[]): Dictionary<SportDateTimeFilter> {
  return filters.reduce((result, filter) => ({
    ...result,
    [getLeagueElementKeyForFilter(baseKey, filter)]: filter,
  }), {} as Dictionary<SportDateTimeFilter>);
}

export function splitSportEventsListByFilters<T extends SportEvent = SportEvent>(
  list: SportEventElement<T>[],
  baseKey: string,
  filters: SportDateTimeFilter[],
): Dictionary<SportEventElement<T>[]> {
  const filtersMap = getFiltersMap(baseKey, filters);
  const elementsMap = Object.keys(filtersMap)
    .reduce((result, key) => ({ ...result, [key]: [] }), { [baseKey]: [] } as Dictionary<SportEventElement<T>[]>);

  return list.reduce((result, sportEventElement) => {
    const actualFilter = findFilterKeyForSportEventElement(sportEventElement, filtersMap);
    result[actualFilter ?? baseKey].push(sportEventElement);
    return result;
  }, elementsMap);
}
