import type { RouteLocationRaw } from 'vue-router';

import { RouteName } from '@leon-hub/routing-config-names';
import { TransactionGroup } from '@leon-hub/api-sdk';

export default function getHistoryDetailsRoute(id: string): RouteLocationRaw {
  return {
    name: RouteName.CUSTOMER_HISTORY_TRANSACTION_DETAILS,
    params: {
      id, group: TransactionGroup.BET.toLowerCase(),
    },
  };
}
