import type { Ref } from 'vue';
import { ref } from 'vue';

import { usePaymentsConfig } from 'web/src/modules/core/composables/site-config';

interface PaymentSystemRequestComposable {
  lastPaymentSystemRequestTime: Ref<number | null>;
  isReadyToRequest: () => boolean;
  setLastPaymentSystemRequestTime: (value?: number | null) => void;
}
export function usePaymentSystemRequestTime(): PaymentSystemRequestComposable {
  const paymentConfig = usePaymentsConfig();

  const lastPaymentSystemRequestTime = ref<number | null>(null);

  function setLastPaymentSystemRequestTime(value: number | null = Date.now()): void {
    lastPaymentSystemRequestTime.value = value;
  }

  function isReadyToRequest(): boolean {
    return !lastPaymentSystemRequestTime.value
    || (Date.now() - lastPaymentSystemRequestTime.value) > paymentConfig.paymentsListsReloadTimeout.value;
  }

  return {
    lastPaymentSystemRequestTime,
    isReadyToRequest,
    setLastPaymentSystemRequestTime,
  };
}
