import type { Ref } from 'vue';
import { computed, ref, watch } from 'vue';

import type { VButtonProps } from '@leon-hub/module-buttons';
import { IconSize } from '@leon-hub/icons';
import { Timer } from '@leon-hub/utils';

import type { VFormRef } from 'web/src/components/Form/components/VForm/types';
import type { FormOutput, FormUiSchema } from 'web/src/components/Form';
import type { FormUiSchemaFieldText } from 'web/src/components/Form/types';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import {
  ResponsibleGamblingLimitsToRange,
  ResponsibleGamblingLimitsType,
} from 'web/src/modules/responsible-gambling/pages/types';
import { AnalyticsModalType, DialogAction, PresetName } from 'web/src/modules/dialogs/enums';
import { ModalWidth } from 'web/src/components/Modal/enums';
import LanguageMicroSettings from 'web/src/utils/LanguageMicroSettings';
import { useI18n, useI18nLocale } from 'web/src/modules/i18n/composables';
import { useDialogs } from 'web/src/modules/dialogs/composables';

import type { ResponsibleGamblingFormEmits, ResponsibleGamblingFormProps } from '../types';

export interface ResponsibleGamblingFormComposable {
  form: Ref<VFormRef | undefined>;
  timeName: Ref<Record<string, string> | null>;
  dynamicUiSchema: Ref<FormUiSchema>;
  buttonProperties: Ref<VButtonProps>;
  handleSubmit: (output: FormOutput) => void;
  handleInput: (output: FormOutput) => void;
  handleButtonClick: () => void;
  beforeUnmount: () => void;
}

export default function useResponsibleGamblingForm(
  props: ResponsibleGamblingFormProps,
  emits: ResponsibleGamblingFormEmits,
): ResponsibleGamblingFormComposable {
  const { $translate } = useI18n();
  const { locale } = useI18nLocale();
  const { showDialog } = useDialogs();

  const dayInSecs = 86_399;
  let interval: Maybe<number> = null;
  const disabled = ref(true);
  const changedLimits = ref(false);
  const form = ref<VFormRef | undefined>();
  const minutes = ref(0);
  const hours = ref(0);

  const validLimitNames: (keyof typeof ResponsibleGamblingLimitsToRange)[] = [
    'dailyLimit',
    'monthlyLimit',
    'weeklyLimit',
  ];

  const secondsLeft = computed<number>(() => Math.floor((props.timestamp - Date.now()) / 1000));

  const buttonProperties = computed<VButtonProps>(() => {
    const generalButtonProperties = {
      fullWidth: true,
      isLoading: !!props.loading,
    };

    if (!changedLimits.value && !!props.limit) {
      return {
        ...generalButtonProperties,
        label: $translate('JSPACC_AFFBCODES_DEL').value,
        kind: ButtonKind.PRIMARY,
      };
    }

    return {
      ...generalButtonProperties,
      label: $translate('JS_SLIP_BET_SETTING_FAST_SAVE').value,
      kind: ButtonKind.BASE,
      disabled: disabled.value,
    };
  });

  const formConfirmMessage = computed<string>(() => {
    switch (props.formType) {
      case ResponsibleGamblingLimitsType.DEPOSIT:
        return $translate('WEB2_RG_CHANGE_DEPOSIT_LIMIT_ALERT').value;
      case ResponsibleGamblingLimitsType.BET:
        return $translate('WEB2_RG_CHANGE_BET_LIMIT_ALERT').value;
      default:
        return $translate('WEB2_RG_CHANGE_LIMIT_ALERT').value;
    }
  });

  const hoursLangOptions = computed(() => ({ count: String(hours.value) }));
  const minutesLangOptions = computed(() => ({ count: String(minutes.value) }));

  const hoursOne = $translate('WEB2_PLURAL_HOURS_ONE', hoursLangOptions);
  const hoursTwo = $translate('WEB2_PLURAL_HOURS_TWO', hoursLangOptions);
  const hoursMany = $translate('WEB2_PLURAL_HOURS_MANY', hoursLangOptions);

  const minutesOne = $translate('WEB2_PLURAL_MINUTES_ONE', minutesLangOptions);
  const minutesTwo = $translate('WEB2_PLURAL_MINUTES_TWO', minutesLangOptions);
  const minutesMany = $translate('WEB2_PLURAL_MINUTES_MANY', minutesLangOptions);

  const timeName = computed<Record<string, string> | null>(() => (secondsLeft.value >= 0 ? {
    hours: hours.value === 0 ? '' : LanguageMicroSettings.plural([
      hoursOne.value,
      hoursTwo.value,
      hoursMany.value,
    ], hours.value, locale.value),
    minutes: LanguageMicroSettings.plural([
      minutesOne.value,
      minutesTwo.value,
      minutesMany.value,
    ], minutes.value, locale.value),
  } : null));

  const dynamicUiSchema = computed<FormUiSchema>(() => {
    const { uiSchema, limit } = props;
    if (uiSchema.fields) {
      (uiSchema.fields.limit as FormUiSchemaFieldText).default = limit ? String(limit) : '';
      uiSchema.fields.limit.disabled = !!timeName.value;
    }
    return uiSchema;
  });

  function handleSubmit(output: FormOutput<{ limit: string }>): void {
    const limitName = props.limitName as keyof typeof ResponsibleGamblingLimitsToRange;

    if (!output.customErrors && !output.errors && output.formData.limit && validLimitNames.includes(limitName)) {
      const type = { type: ResponsibleGamblingLimitsToRange[limitName] };
      showDialog({
        presetName: PresetName.CONFIRM,
        options: {
          width: ModalWidth.SMALL,
          confirmMessage: formConfirmMessage.value,
          analyticsModalType: AnalyticsModalType.WARNING,
          title: $translate('JS_SELF_EXCLUSION_CONFIRM_TITLE').value,
          iconSize: process.env.VUE_APP_LAYOUT_DESKTOP ? IconSize.SIZE_80 : IconSize.SIZE_60,
          buttons: [
            {
              label: $translate('JSP_CCGATEWAY_BTN_CONFIRM').value,
              kind: process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED ? 'primary' : ButtonKind.RED,
            },
          ],
        },
      }).subscribe({
        [DialogAction.CONFIRM]: () => {
          emits('form-output', { ...output.formData, ...type });
        },
      });
    }
  }

  function handleInput(output: FormOutput): void {
    if (!output.formData) {
      return;
    }
    disabled.value = (!!output.customErrors && !!output.errors) || !output.formData.limit;
    changedLimits.value = +props.limit !== +output.formData.limit;
  }

  function handleButtonClick(): void {
    const limitName = props.limitName as keyof typeof ResponsibleGamblingLimitsToRange;

    if (!changedLimits.value && !!props.limit && validLimitNames.includes(limitName)) {
      emits('delete-limit', {
        type: ResponsibleGamblingLimitsToRange[limitName],
        limit: null,
      });
    } else {
      form.value?.submit();
    }
  }

  function setTimeName(): void {
    minutes.value = Math.floor(secondsLeft.value / 60);
    hours.value = 0;
    if (minutes.value > 59) {
      hours.value = Math.floor(minutes.value / 60);
      minutes.value -= (hours.value * 60);
    }
  }

  function startCountDownInit(): void {
    setTimeName();
    if (secondsLeft.value > 0 && secondsLeft.value <= dayInSecs) {
      interval = Timer.setInterval(() => setTimeName(), 60_000);
    }
  }

  function beforeUnmount(): void {
    if (interval) {
      Timer.clearInterval(interval);
      interval = 0;
    }
  }

  watch(() => props.timestamp, () => {
    startCountDownInit();
  }, { immediate: true });

  return {
    form,
    timeName,
    dynamicUiSchema,
    buttonProperties,
    handleSubmit,
    handleInput,
    handleButtonClick,
    beforeUnmount,
  };
}
