<script lang="ts" setup>
import { computed } from 'vue';

import StatisticColoredLabel
  from 'web/src/modules/sportline/submodules/core-statistic/components/StatisticColoredLabel.vue';
import StatisticChartBar
  from 'web/src/modules/sportline/submodules/core-statistic/components/StatisticChartBar.vue';
import {
  StatisticChartBarType,
  StatisticColoredLabelSize,
  StatisticColoredLabelType,
} from 'web/src/modules/sportline/submodules/core-statistic/enums';

interface LeagueMatchesStatisticBlockProps {
  doUseProgressBar?: boolean;
  title: string;
  homeLabel?: string;
  overallLabel?: string;
  awayLabel?: string;
  homeValue?: number;
  overallValue?: number;
  awayValue?: number;
}

const props = defineProps<LeagueMatchesStatisticBlockProps>();

const overallLabelSize = computed(() => (props.doUseProgressBar
  ? StatisticColoredLabelSize.MEDIUM
  : StatisticColoredLabelSize.DEFAULT));
</script>

<template>
  <div v-auto-id="'LeagueMatchesStatisticBlock'" :class="$style['league-matches-statistic-block']">
    <div
      :class="[
        $style['league-matches-statistic-block__item'],
        $style['league-matches-statistic-block-title']
      ]"
    >
      <div :class="$style['league-matches-statistic-block-title__label']">
        <div :class="$style['matches-statistic-block-title']">
          {{ title }}
        </div>
      </div>

      <div :class="$style['league-matches-statistic-block-title__total-count']">
        <StatisticColoredLabel
          v-if="overallLabel"
          :label="overallLabel"
          :size="overallLabelSize"
        />
      </div>
    </div>

    <div
      :class="[
        $style['league-matches-statistic-block__item'],
        $style['league-matches-statistic-block-values']
      ]"
    >
      <div :class="$style['league-matches-statistic-block-values__labels']">
        <div
          :class=" {
            [$style['league-matches-statistic-block-values__label']]: true,
            [$style['league-matches-statistic-block-values__label--tall']]: !doUseProgressBar
          }"
        >
          <span v-if="doUseProgressBar">
            {{ homeLabel }}
          </span>
          <StatisticColoredLabel
            v-else-if="homeLabel"
            :label="homeLabel"
            :size="StatisticColoredLabelSize.DEFAULT"
            :type="StatisticColoredLabelType.HOME"
          />
        </div>

        <div
          :class=" {
            [$style['league-matches-statistic-block-values__label']]: true,
            [$style['league-matches-statistic-block-values__label--tall']]: !doUseProgressBar
          }"
        >
          <span>
            {{ $t('WEB2_STATISTICS_HOME_TEAM') }} / {{ $t('WEB2_STATISTICS_AWAY_TEAM') }}
          </span>
        </div>

        <div
          :class=" {
            [$style['league-matches-statistic-block-values__label']]: true,
            [$style['league-matches-statistic-block-values__label--tall']]: !doUseProgressBar
          }"
        >
          <span v-if="doUseProgressBar">
            {{ awayLabel }}
          </span>
          <StatisticColoredLabel
            v-else-if="awayLabel"
            :label="awayLabel"
            :size="StatisticColoredLabelSize.DEFAULT"
            :type="StatisticColoredLabelType.AWAY"
          />
        </div>
      </div>

      <StatisticChartBar
        v-if="doUseProgressBar"
        :type="StatisticChartBarType.NARROW"
        :home-percentage="homeValue"
        :away-percentage="awayValue"
      />
    </div>
  </div>
</template>

<style lang="scss" module>
.league-matches-statistic-block {
  padding: 0 8px;
  background-color: var(--Layer2);
  border-radius: 5px;

  &__item {
    padding: 12px 0;

    &:not(:last-child) {
      border-bottom: 1px solid var(--Layer1);
    }
  }
}

.league-matches-statistic-block-title {
  display: grid;
  grid-template-columns: 1fr 60px;
  column-gap: 8px;

  &__label {
    text-align: left;
  }

  &__total-count {
    text-align: right;
  }
}

.league-matches-statistic-block-values {
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;

  &__labels {
    display: grid;
    grid-template-columns: 60px 1fr 60px;
    column-gap: 8px;
  }

  &__label {
    @include medium\13\16;

    color: var(--TextPrimary);
    text-align: center;
    vertical-align: middle;

    &--tall {
      line-height: 20px;
    }

    &:first-child {
      text-align: left;
    }

    &:last-child {
      text-align: right;
    }
  }
}

.matches-statistic-block-title {
  @include medium\14\16\025;

  display: inline-block;
  color: var(--TextDefault);
}
</style>
