import { getAvailableMeetingSlots } from '@leon-hub/api-sdk';
import type { AvailableMeetingSlotsResponse } from '@leon-hub/api-sdk';

import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';

// eslint-disable-next-line max-len
export default function fetchAvailableMeetingSlots(toChangeTime = false): Promise<Partial<AvailableMeetingSlotsResponse>> {
  return getAvailableMeetingSlots(
    useGraphqlClient(),
    (node) => node.queries.verification.getAvailableMeetingSlots,
    {
      options: {
        toChangeTime,
      },
    },
  );
}
