export default function getNavigationItemIconLocation(
  iconSource: string | undefined,
  theme: string,
  imageCdnUrl: string,
): string | undefined {
  if (iconSource !== undefined) {
    return /^https?/.test(iconSource)
      ? iconSource
      : `${imageCdnUrl}${iconSource
        .replace(/^\//, '')
        .replace('{theme}', theme.toLowerCase())}`;
  }
  return undefined;
}
