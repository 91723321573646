import type { Ref } from 'vue';
import { toRef, computed } from 'vue';

import type { CountryCode } from 'web/src/components/CountryFlag/VCountryFlag/enums';
import { SportSegmentId } from 'web/src/modules/sportline/enums';

import type { RegionFlagIconSource } from '../types';

interface UseRegionLogoPropsComposable {
  countryCode: Ref<Maybe<CountryCode>>;
  sportSegmentId: Ref<Optional<SportSegmentId>>;
  isCyberSport: Ref<boolean>;
}

export function useRegionLogoProps(
  props: RegionFlagIconSource,
): UseRegionLogoPropsComposable {
  const rawCode = toRef(props, 'countryCode');
  const sportSegmentId = toRef(props, 'sportSegmentId');

  const countryCode = computed(() => (rawCode.value ?? null) as Maybe<CountryCode>);
  const isCyberSport = computed(() => sportSegmentId.value === SportSegmentId.CyberSport);

  return {
    countryCode,
    sportSegmentId,
    isCyberSport,
  };
}
