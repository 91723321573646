import type { Ref } from 'vue';
import { computed } from 'vue';

import type { SportlineEvent } from 'web/src/modules/sportline/types';
import type { SlipInfoCaption } from 'web/src/modules/sportline/views/runner/types';
import { getSlipCaption } from 'web/src/modules/sportline/views/runner/utils';

interface UseSlipCaptionProps {
  sportlineEvent: Ref<SportlineEvent>;
}

interface UseSlipCaptionComposable {
  slipCaption: Ref<SlipInfoCaption>;
}

export function useSlipCaption(
  props: UseSlipCaptionProps,
): UseSlipCaptionComposable {
  const { sportlineEvent } = props;

  const slipCaption = computed(() => getSlipCaption(sportlineEvent.value));

  return { slipCaption };
}
