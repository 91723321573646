import { FormControlType } from '@leon-hub/form-utils';

import type { FormUiSchema, FormWidgetType } from '../../../../types';
import getWidget from '../../../../utils/getWidget';

interface Output {
  widgetTypeList: FormWidgetType[];
  captchaWidgets: FormWidgetType[];
}

export default function getWidgetTypeList(
  uiSchema: FormUiSchema,
): Output {
  // all properties should be defined in ui.order
  const propertyNames = uiSchema.order ? uiSchema.order : Object.keys(uiSchema.fields ?? {});
  const widgetTypeList: FormWidgetType[] = [];
  const captchaWidgets: FormWidgetType[] = [];
  for (const name of propertyNames) {
    const widget = getWidget(uiSchema, name);
    if (widget === FormControlType.Captcha || widget === FormControlType.CaptchaV3) {
      captchaWidgets.push({
        type: widget,
        field: name,
      });
    } else {
      widgetTypeList.push({
        type: widget,
        field: name,
      });
    }
  }
  return {
    widgetTypeList,
    captchaWidgets,
  };
}
