/* eslint-disable class-methods-use-this */
import type { ICasinoRouteName } from '@leon-hub/routing-config-names/src/CasinoRouteName';
import { CasinoRouteName } from '@leon-hub/routing-config-names/src/CasinoRouteName';
import CustomerRouteAccessRole from '@leon-hub/routing-config/src/types/CustomerRouteAccessRole';

import { AppModule } from 'web/src/modules/core/plugins/AppModule';
import type {
  AppVueRouter,
  DefaultRouteConfig,
} from 'web/src/modules/core/services/router/types';

import { getCasinoLobbyRoutePage } from './utils';

function getLobbyRoute(name: ICasinoRouteName, path: string, access?: CustomerRouteAccessRole): DefaultRouteConfig {
  return getCasinoLobbyRoutePage({
    name,
    path,
    access,
    seoController: () => import('web/src/modules/egs/submodules/seo/EgsPageSeoController'),
  });
}

function getCasinoLobbyRoute(): DefaultRouteConfig {
  return getLobbyRoute(CasinoRouteName.CASINO_LOBBY, '/casino/:groupUrl?/:categoryUrl?');
}

function getCasinoLiveLobbyRoute(): DefaultRouteConfig {
  return getLobbyRoute(CasinoRouteName.CASINO_LIVE_LOBBY, '/live-casino/:groupUrl?/:categoryUrl?');
}

export class CasinoLobbyModule extends AppModule {
  addRoutes(router: AppVueRouter): void {
    if (process.env.VUE_APP_FEATURE_CASINO_MIXED_MY_GAMES_ENABLED) {
      router.addDefaultRoute(getLobbyRoute(CasinoRouteName.CASINO_MY_GAMES, '/casino/my-games', CustomerRouteAccessRole.AUTHORIZED));
    }

    router
      .addDefaultRoute(getCasinoLobbyRoute())
      .addDefaultRoute(getCasinoLiveLobbyRoute());

    router.addPrefetchRoute({
      name: 'old lobby',
      path: '/slots/:groupUrl?/:categoryUrl?',
      prefetch: () => import('./prefetch/CasinoSlotsPrefetchOld'),
    });
  }
}
