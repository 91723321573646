import type { ServiceLocatorKey } from '../types';

const createToString = (key: string) => () => key;

type ServiceLocatorKeyCreator<Key extends string> = {
  withType<Type>(): ServiceLocatorKey<Key, Type>;
};

export function createServiceLocatorKey<Key extends string>(key: Key): ServiceLocatorKeyCreator<Key> {
  return {
    withType<Type>() {
      return {
        key,
        toString: createToString(key),
      } as ServiceLocatorKey<Key, Type>;
    },
  };
}
