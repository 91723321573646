import {
  getIntercomUserHash,
  RequestOptionsPriority,
} from '@leon-hub/api-sdk';

import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';

interface IntercomConfigResponse {
  userHash: string;
  appId: string;
  apiUrl: string;
}

export default function fetchIntercomApiData(): Promise<IntercomConfigResponse> {
  const apiClient = useGraphqlClient();
  return getIntercomUserHash(
    apiClient,
    (node) => node.queries.faq.getIntercomConfig,
    { options: {} },
    {
      silent: true,
      priority: RequestOptionsPriority.LOW,
    },
  );
}
