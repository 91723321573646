import type { Ref } from 'vue';
import { computed, toRef } from 'vue';
import { storeToRefs } from 'pinia';

import type { ExtendedAccountBetHistoryItem } from '@leon-hub/api-sdk';

import type { BetCashOutProps } from 'web/src/components/BetCashOut/types';
import {
  useDesktopModalStore,
} from 'web/src/modules/core/store';
import { usePendingBetsStore } from 'web/src/modules/slip/submodules/pending-bets/store';
import { useI18n } from 'web/src/modules/i18n/composables';
import {
  useCustomerHistoryDetailsScoreBoard,
} from 'web/src/modules/profile/submodules/customer-history/components/CustomerHistoryDetailsScoreBoard/composables';

import { useCashoutStore } from '../store';
import { BetCashoutMode } from '../enums';
import type { CashoutModalEmits, CashoutModalProps } from '../types';

interface UseCashoutModal {
  mainButtonClick: () => void;
  cancelClick: () => void;
  closeModal: () => void;
  coreComponentProps: Ref<BetCashOutProps>;
  hasDesktopModal: Ref<boolean>;
  isProfileLayout: Ref<boolean>;
  betPrice: Ref<number>;
  betName: Ref<string>;
}

export default function useCashoutModal(emit: CashoutModalEmits, props: CashoutModalProps): UseCashoutModal {
  const cashoutStore = useCashoutStore();
  const { updatePendingBetsOnCashout } = usePendingBetsStore();
  const desktopModalStore = useDesktopModalStore();
  const { $translate } = useI18n();

  const {
    betKind,
    competitors,
    isSingle,
    isExpress,
  } = useCustomerHistoryDetailsScoreBoard({
    transaction: props.transaction as ExtendedAccountBetHistoryItem,
  });

  const {
    coreComponentProps,
    currentBetId,
    mode,
  } = storeToRefs(cashoutStore);

  const hasDesktopModal = toRef(desktopModalStore, 'hasDesktopModal');

  const isProfileLayout = toRef(desktopModalStore, 'isProfileLayout');

  const betPrice = computed<number>(
    () => (mode.value === BetCashoutMode.SUCCESS ? 0 : Number(props.transaction?.debit)),
  );

  const betName = computed<string>(() => {
    if (!isSingle.value) {
      return `${isExpress.value
        ? $translate('WEB2_TRANSACTION_HISTORY_EXPRESS_BET').value
        : $translate('WEB2_TRANSACTION_HISTORY_SYSTEM_BET').value} ${betKind.value}`;
    }
    return competitors.value;
  });

  const resolveCashout = (): void => {
    updatePendingBetsOnCashout({
      betId: currentBetId.value,
      result: mode.value,
    });
  };

  const emitCancel = (): void => {
    emit('cancel');
  };

  const emitConfirm = (): void => {
    emit('confirm');
  };

  const mainButtonClick = (): void => {
    if (mode.value === BetCashoutMode.SUCCESS) {
      resolveCashout();
      emitConfirm();
    } else {
      void cashoutStore.doCashout();
    }
  };

  const cancelClick = () => {
    if (mode.value === BetCashoutMode.ERROR) {
      resolveCashout();
    }
    emitCancel();
  };

  return {
    coreComponentProps,
    hasDesktopModal,
    isProfileLayout,
    betPrice,
    betName,
    mainButtonClick,
    cancelClick,
    closeModal: emitCancel,
  };
}
