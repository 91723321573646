<script lang="ts" setup>
import { JumbotronFooterButton, VJumbotron } from 'web/src/components/Jumbotron';
import type { LobbyEgsNoGamesProps } from 'web/src/modules/lobby/components/LobbyEgsNoGames/types';
import { useLobbyEgsNoGames } from 'web/src/modules/lobby/components/LobbyEgsNoGames/composables';

const props = defineProps<LobbyEgsNoGamesProps>();

const {
  noGamesButtonProperties,
  onClick,
} = useLobbyEgsNoGames(props);
</script>

<template>
  <VJumbotron v-auto-id="'LobbyEgsNoGames'"
    :heading="heading || $t('WEB2_GAMES_NO_RESULT_TITLE')"
    :text="text || $t('WEB2_GAMES_NO_RESULT_TEXT')"
    :class="$style['lobby-egs-no-games']"
  >
    <template
      v-if="!isButtonHidden"
      #footer
    >
      <JumbotronFooterButton
        v-bind="noGamesButtonProperties"
        :label="buttonLabel || $t('WEB2_GOHOME')"
        @click="onClick"
      />
    </template>
  </VJumbotron>
</template>

<style lang="scss" module>
@include for-layout using($isDesktop) {
  .lobby-egs-no-games {
    max-width: 375px;
    padding: if($isDesktop, 32px, 16px);
    margin: 0 auto;
  }
}
</style>
