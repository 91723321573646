import { useRouter } from 'vue-router';
import type { ComputedRef, Ref } from 'vue';
import { computed, ref, toRef } from 'vue';

import { logger } from '@leon-hub/logging';
import { CustomerConfig, VideoVerificationStatus } from '@leon-hub/api-sdk';
import { RouteName } from '@leon-hub/routing-config-names';
import { IconName } from '@leon-hub/icons';

import { DialogAction, PresetName } from 'web/src/modules/dialogs/enums';
import { useDialogs } from 'web/src/modules/dialogs/composables';
import { useVideoVerificationStore } from 'web/src/modules/verification/submodules/video-verification/store';
import { useUserStore } from 'web/src/modules/user/store';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import DateTime, { TimeFormats } from 'web/src/utils/DateTime';
import type { CustomerVerificationConstructorProps } from 'web/src/modules/profile/submodules/verification/components/Video/types';
import { JumbotronIconKind } from 'web/src/components/Jumbotron/VJumbotron/enums';
import { useTheme } from 'web/src/modules/theme/composables';

interface useCustomerVerificationVideoComposable {
  isLoading: Ref<boolean>;
  isShowStartScreen: Ref<boolean>;
  customerVerificationStateProps: ComputedRef<CustomerVerificationConstructorProps>;
  onRefuse: () => void;
  onClickAction: () => Promise<void>;
  onClickActionSecond: () => void;
  onHideStartScreen: () => Promise<void>;
  onClickButtonInvited: () => Promise<void>;
  onCloseVideoVerificationOnCall: () => Promise<void>;
  beforeMount: () => Promise<void>;
}

export default function useCustomerVerificationVideo(): useCustomerVerificationVideoComposable {
  const { $translate } = useI18n();
  const router = useRouter();
  const { showDialog, closeDialog } = useDialogs();
  const verificationStore = useVideoVerificationStore();
  const { loadUserData } = useUserStore();
  const { isDark } = useTheme();

  const isLoading = ref(false);
  const customerDataStore = useCustomerDataStore();
  const videoVerificationStatus = toRef(customerDataStore, 'videoVerificationStatus');
  const videoVerificationStatusExpiration = toRef(customerDataStore, 'videoVerificationStatusExpiration');
  const isVideoVerificationOnboardingShown = toRef(customerDataStore, 'isVideoVerificationOnboardingShown');
  const isTimeChangeAvailable = toRef(verificationStore, 'isTimeChangeAvailable');
  const upcomingMeetingDate = toRef(verificationStore, 'getUpcomingMeetingDate');
  const upcomingMeetingTime = toRef(verificationStore, 'getUpcomingMeetingTime');
  const isTimeToMeetingLessThirtyMinutes = toRef(verificationStore, 'isTimeToMeetingLessThirtyMinutes');
  const isMeetingTimeStillAvailable = toRef(verificationStore, 'isMeetingTimeStillAvailable');
  const isShowStartScreen = ref(false);

  const hintInvitedBtrProps = computed(() => ({
    date: DateTime.formatTimeStamp(videoVerificationStatusExpiration.value || 0, TimeFormats.dayMonthYear),
  }));

  const scheduledDateTime = computed(() => ({
    date: upcomingMeetingDate.value,
    time: upcomingMeetingTime.value,
  }));
  function onRefuse() {
    const { subscribe } = showDialog({
      presetName: PresetName.CONFIRM,
      options: {
        title: $translate('WEB2_VV_REFUSE_MODAL_TITLE').value,
        confirmMessage: $translate('WEB2_VV_REFUSE_MODAL_TEXT').value,
        buttons: [
          {
            label: $translate('WEB2_VV_REFUSE_BUTTON').value,
          },
        ],
        dataTestId: 'vv-refuse',
      },
    });
    subscribe({
      [DialogAction.CONFIRM]: async () => {
        try {
          isLoading.value = true;
          await verificationStore.refuseMeeting();
          await loadUserData();
          isLoading.value = false;
        } catch (error) {
          logger.error('refuseMeeting error:', error);
        }
      },
    });
  }

  async function onClickButtonInvited() {
    isLoading.value = true;
    await verificationStore.syncSlots();
    isLoading.value = false;
    if (!verificationStore.availableSlots?.size) {
      const { subscribe } = showDialog({
        presetName: PresetName.CONFIRM,
        options: {
          title: $translate('WEB2_VV_NOT_TIME_MODAL_TITLE').value,
          confirmMessage: $translate('WEB2_VV_NOT_TIME_MODAL_TEXT').value,
          buttons: [
            { label: $translate('JSP_HELP').value },
            { label: $translate('WEB2_CLOSE').value },
          ],
          dataTestId: 'vv-not-time',
        },
      });
      subscribe({
        [DialogAction.CONFIRM]: () => {
          void router.push(RouteName.SUPPORT);
        },
      });
    } else {
      void router.push({ name: RouteName.CUSTOMER_VERIFICATION_SELECT_TIME });
    }
  }

  async function onHideStartScreen() {
    isShowStartScreen.value = false;
    await useUserStore().setAdditionalPropsUser({
      value: {
        value: true,
      },
      config: CustomerConfig.IS_VIDEO_VERIFICATION_ONBOARDING_SHOWN,
    });
  }

  function checkAndRequestPermissionsVideoVerification() {
    if (navigator?.mediaDevices?.getUserMedia) {
      navigator.mediaDevices.enumerateDevices()
        .then(async (devices) => {
          const hasAudioPermission = devices.some((device) => device.kind === 'audioinput');
          if (!hasAudioPermission) {
            try {
              await navigator.mediaDevices.getUserMedia({
                audio: true,
                video: { width: 1280, height: 720 },
              });
            } catch (error) {
              logger.error('Error on get permission for video verification', error);
            }
          }
        })
        .catch((error) => {
          logger.error('Error on get permission for video verification', error);
        });
    } else {
      logger.error('getUserMedia not supported in this browser');
    }
  }

  function showModalReloadVideoVerification() {
    const { subscribe, id } = showDialog({
      presetName: PresetName.CONFIRM,
      options: {
        title: $translate('WEB2_VV_MODAL_RELOAD_CALL_TITLE').value,
        confirmMessage: $translate('WEB2_VV_MODAL_RELOAD_CALL_TEXT').value,
        isFullHeightCentered: true,
        isSmallWidthContent: true,
        isCentered: true,
        fullHeight: true,
        buttons: [
          {
            label: $translate('WEB2_VV_MODAL_RELOAD_CALL_BUTTON').value,
          },
          { label: $translate('WEB2_CLOSE').value },
        ],
        dataTestId: 'vv-reload-call',
      },
    });
    subscribe({
      [DialogAction.CONFIRM]: () => {
        checkAndRequestPermissionsVideoVerification();
        closeDialog(id);
        void router.push({ name: RouteName.CUSTOMER_VERIFICATION_CALL });
      },
      [DialogAction.MODAL_CLOSE]: () => {
        void router.push({ name: RouteName.HOME });
      },
    });
  }

  async function onCloseVideoVerificationOnCall() {
    await loadUserData();
    if (videoVerificationStatus.value === VideoVerificationStatus.SCHEDULED && isMeetingTimeStillAvailable.value) {
      const { subscribe, id } = showDialog({
        presetName: PresetName.CONFIRM,
        options: {
          title: $translate('WEB2_VV_MODAL_CANCEL_CALL_TITLE').value,
          confirmMessage: $translate('WEB2_VV_MODAL_CANCEL_CALL_TEXT').value,
          isFullHeightCentered: true,
          isCentered: true,
          fullHeight: true,
          customBackground: isDark.value ? 'rgba(0,0,0,.7)' : 'rgba(241,242,244,.8)',
          isCloseAllowed: false,
          isSmallWidthContent: true,
          buttons: [
            {
              label: $translate('WEB2_VV_MODAL_CANCEL_CALL_BUTTON').value,
              kind: ButtonKind.RED,
            },
            { label: $translate('WEB2_MODAL_CANCEL').value },
          ],
          dataTestId: 'vv-cancel-call',
        },
      });
      subscribe({
        [DialogAction.CONFIRM]: () => {
          closeDialog(id);
          showModalReloadVideoVerification();
        },
      });
    } else {
      router.back();
    }
  }

  // @ts-ignore
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const customerVerificationStateProps = computed<CustomerVerificationConstructorProps>(() => {
    switch (videoVerificationStatus.value) {
      case VideoVerificationStatus.INVITED:
        return {
          jumbotron: {
            heading: $translate('WEB2_VV_TITLE_INVITED').value,
          },
          text: $translate('WEB2_VV_TEXT_INVITED').value,
          buttonFirst: {
            label: $translate('WEB2_VV_BUTTON_SELECT_TIME').value,
          },
          buttonSecond: {
            label: $translate('WEB2_BONUSES_REFUSE_BUTTON').value,
          },
          hintText: $translate('WEB2_VV_HINT_INVITED', hintInvitedBtrProps).value,
          timeEnd: videoVerificationStatusExpiration.value,
        };
      case VideoVerificationStatus.NO_ANSWER:
        return {
          jumbotron: {
            icon: IconName.CHECK_OUTLINE,
            heading: $translate('WEB2_VV_TITLE_NO_ANSWER').value,
            text: $translate('WEB2_VV_TEXT_NO_ANSWER').value,
          },
          buttonFirst: {
            label: $translate('WEB2_VV_BUTTON_SELECT_TIME').value,
          },
          buttonSecond: {
            label: $translate('WEB2_BONUSES_REFUSE_BUTTON').value,
          },
          hintText: $translate('WEB2_VV_HINT_NO_ANSWER').value,
          timeEnd: videoVerificationStatusExpiration.value,
        };
      case VideoVerificationStatus.SCHEDULED:
        return {
          isCentered: true,
          isTimeChangeAvailable: isTimeChangeAvailable.value,
          jumbotron: {
            icon: isTimeToMeetingLessThirtyMinutes.value ? IconName.VIDEO_CALL : IconName.CHECK_OUTLINE,
            heading: isTimeToMeetingLessThirtyMinutes.value
              ? $translate('WEB2_VV_TITLE_SCHEDULED_SOON').value
              : $translate('WEB2_VV_TITLE_SCHEDULED').value,
          },
          text: isTimeToMeetingLessThirtyMinutes.value
            ? $translate('WEB2_VV_TEXT_SCHEDULED_SOON').value
            : $translate('WEB2_VV_TEXT_SCHEDULED').value,
          time: $translate('WEB2_VV_SCHEDULED_DATE_TIME', scheduledDateTime).value,
          buttonFirst: {
            label: isTimeToMeetingLessThirtyMinutes.value
              ? $translate('WEB2_VV_TITLE_SCHEDULED_BTN').value
              : $translate('WEB2_CLOSE').value,
            kind: isTimeToMeetingLessThirtyMinutes.value ? ButtonKind.PRIMARY : ButtonKind.SECONDARY_DARK,
          },
          // eslint-disable-next-line no-nested-ternary,max-len
          buttonSecond: isTimeToMeetingLessThirtyMinutes.value ? { label: $translate('WEB2_CLOSE').value } : isTimeChangeAvailable.value ? null : {
            label: $translate('WEB2_SUPPORT').value,
          },
        };
      case VideoVerificationStatus.ON_REVIEW:
        return {
          isCentered: true,
          jumbotron: {
            icon: IconName.TIMER_SAND,
            heading: $translate('WEB2_VV_TITLE_ON_REVIEW').value,
          },
          cmsKey: 'WEB2_VV_TEXT_ON_REVIEW',
          buttonFirst: {
            label: $translate('WEB2_CLOSE').value,
            kind: ButtonKind.SECONDARY_DARK,
          },
        };
      case VideoVerificationStatus.PASSED:
        return {
          isCentered: true,
          jumbotron: {
            icon: IconName.CHECK_OUTLINE,
            heading: $translate('WEB2_VV_TITLE_PASSED').value,
            text: $translate('WEB2_VV_TEXT_PASSED').value,
          },
          buttonFirst: {
            label: $translate('WEB2_CLOSE').value,
            kind: ButtonKind.SECONDARY_DARK,
          },
        };
      case VideoVerificationStatus.NOT_PASSED:
        return {
          jumbotron: {
            icon: IconName.INFO_OUTLINE,
            iconKind: JumbotronIconKind.ALERT,
            heading: $translate('WEB2_VV_TITLE_NOT_PASSED').value,
          },
          cmsKey: 'WEB2_VV_TEXT_NOT_PASSED',
          buttonFirst: {
            label: $translate('WEB2_CLOSE').value,
            kind: ButtonKind.SECONDARY_DARK,
          },
          buttonSecond: isTimeChangeAvailable.value ? null : {
            label: $translate('WEB2_SUPPORT').value,
          },
        };
      case VideoVerificationStatus.IGNORED:
      case VideoVerificationStatus.ACCEPT_IGNORE:
        return {
          isCentered: true,
          jumbotron: {
            icon: IconName.INFO_OUTLINE,
            iconKind: JumbotronIconKind.ALERT,
            heading: $translate('WEB2_VV_TITLE_IGNORED').value,
          },
          cmsKey: 'WEB2_VV_TEXT_IGNORED',
          buttonFirst: {
            label: $translate('WEB2_CLOSE').value,
          },
          buttonSecond: isTimeChangeAvailable.value ? null : {
            label: $translate('WEB2_SUPPORT').value,
          },
        };
      case VideoVerificationStatus.MISSED:
        return {
          jumbotron: {
            icon: IconName.INFO_OUTLINE,
            iconKind: JumbotronIconKind.ALERT,
            // eslint-disable-next-line max-len
            heading: isTimeChangeAvailable.value ? $translate('WEB2_VV_TITLE_MISSED').value : $translate('WEB2_VV_TITLE_NOT_PASSED').value,
            ...(isTimeChangeAvailable.value ? { text: $translate('WEB2_VV_TEXT_MISSED').value } : null),
          },
          ...(!isTimeChangeAvailable.value ? { cmsKey: 'WEB2_VV_TEXT_NOT_PASSED' } : null),
          ...(isTimeChangeAvailable.value ? {
            timeEnd: videoVerificationStatusExpiration.value,
          } : {}),
          buttonFirst: {
            label: isTimeChangeAvailable.value ? $translate('WEB2_VV_BUTTON_SELECT_TIME').value : $translate('WEB2_CLOSE').value,
          },
          buttonSecond: {
            label: isTimeChangeAvailable.value ? $translate('WEB2_BONUSES_REFUSE_BUTTON').value : $translate('WEB2_SUPPORT').value,
          },
        };
      case VideoVerificationStatus.REFUSED:
      case VideoVerificationStatus.ACCEPT_REFUSAL:
        return {
          isCentered: true,
          jumbotron: {
            icon: IconName.CHECK_OUTLINE,
            heading: $translate('WEB2_VV_TITLE_REFUSED').value,
          },
          cmsKey: 'WEB2_VV_TEXT_REFUSED',
          buttonFirst: {
            label: $translate('WEB2_CLOSE').value,
            kind: ButtonKind.SECONDARY_DARK,
          },
        };
      default:
        return {
          buttonFirst: {
            label: $translate('WEB2_CLOSE').value,
            kind: ButtonKind.SECONDARY_DARK,
          },
        };
    }
  });

  async function onClickAction() {
    switch (videoVerificationStatus.value) {
      case VideoVerificationStatus.INVITED:
      case VideoVerificationStatus.NO_ANSWER:
        await onClickButtonInvited();
        break;
      case VideoVerificationStatus.SCHEDULED:
        if (isTimeToMeetingLessThirtyMinutes.value) {
          void router.push({ name: RouteName.CUSTOMER_VERIFICATION_CALL });
        } else {
          router.back();
        }
        break;
      case VideoVerificationStatus.MISSED:
        if (isTimeChangeAvailable.value) {
          void router.push({ name: RouteName.CUSTOMER_VERIFICATION_SELECT_TIME });
        } else {
          router.back();
        }
        break;
      default:
        router.back();
    }
  }

  function onClickActionSecond() {
    switch (videoVerificationStatus.value) {
      case VideoVerificationStatus.INVITED:
      case VideoVerificationStatus.NO_ANSWER:
        onRefuse();
        break;
      case VideoVerificationStatus.SCHEDULED:
        if (isTimeToMeetingLessThirtyMinutes.value) {
          router.back();
        } else {
          void router.push({ name: RouteName.SUPPORT });
        }
        break;
      case VideoVerificationStatus.NOT_PASSED:
      case VideoVerificationStatus.IGNORED:
      case VideoVerificationStatus.ACCEPT_IGNORE:
        void router.push({ name: RouteName.SUPPORT });
        break;
      case VideoVerificationStatus.MISSED:
        if (isTimeChangeAvailable.value) {
          onRefuse();
        } else {
          void router.push({ name: RouteName.SUPPORT });
        }
        break;
      default:
        break;
    }
  }

  async function beforeMount(): Promise<void> {
    isShowStartScreen.value = !isVideoVerificationOnboardingShown.value;
    isLoading.value = true;
    await loadUserData();
    await verificationStore.syncUpcomingMeeting();
    isLoading.value = false;
  }

  return {
    isLoading,
    isShowStartScreen,
    customerVerificationStateProps,
    onRefuse,
    onClickButtonInvited,
    onHideStartScreen,
    onCloseVideoVerificationOnCall,
    onClickAction,
    onClickActionSecond,
    beforeMount,
  };
}
