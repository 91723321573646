enum Events {
  SERVICE_WORKER_REGISTERED = 'swRegistered',
  Z_APP_ERROR = 'zAppError',
  Z_BET_ERROR = 'zBetError',
  Z_TRY_DEPOSITE = 'zTryDeposite',
  Z_FAST_BETS_CLICKED = 'zFastBetClicked',
  Z_OPEN_LIVE_CHAT = 'zOpenLiveChat',
  Z_DEP_ICON_CLICK = 'zDepIconClick',
  Z_DEP_ICON_NEW_CLICK = 'zDepIconNewClick',
  Z_LIVE_TRACKER = 'zLivetracker',
  Z_LIVE_SCORE_BOARD_CLICK = 'zLiveScoreboardClick',
  Z_MAKE_DEPOSITE = 'zMakeDeposite',
  Z_MAKE_DEP_DEPOSITE = 'zMakeDepConfirm',
  Z_MAKE_INT_DEPOSITE = 'zMakeInitDep',
  Z_SIMPLE_IND_OPEN = 'zSimpleIndopen',
  Z_SIMPLE_IND_SENT = 'zSimpleIndsent',
  Z_SIMPLE_IND_OK = 'zSimpleIndOK',
  Z_WITHDRAWAL_REQUEST = 'zWithdrawalRequest',
  Z_WITHDRAWAL_CANCEL_DEPOSIT_FORM = 'zWithdrawalCancelDepositForm',
  Z_SUBMIT_SLIP_OK = 'zSubmitSlipOK',
  Z_SUBMIT_SLIP = 'zSubmitSlip',
  Z_FAIL_REGISTER = 'zFailRegister',
  Z_DO_REGISTER_OK = 'zDoRegisterOK',
  Z_DO_REGISTER = 'zDoRegister',
  Z_LOGGED_IN = 'zLoggedIn',
  Z_SESSION_EXPIRED = 'zSessionExpired',
  Z_OPEN_GAME = 'zOpenGame',
  Z_OPEN_DEMO_GAME = 'zOpenDemoGame',
  Z_MAKE_GAME_DEPOSIT_OK = 'zMakeGameDepositOK',
  Z_MAKE_GAME_WITHDRAW_OK = 'zMakeGameWithdrawOK',
  Z_GET_NATIVE_APP = 'zGetNativeApp',
  Z_GET_PWA = 'zGetPWA',
  Z_CLOSE_GET_NATIVE_APP = 'zCloseGetNativeApp',
  Z_PUSH_OPEN = 'zPushOpen',
  Z_OPEN_NEW_TICKET = 'zOpenNewTicket',
  Z_OPEN_SUPPORT_PAGE = 'zOpenSupportPage',
  Z_INDEX_VIEW_TAP = 'zIndexviewtap',
  Z_GO_TO_MOBILE_4_FROM_MOBILE_5 = 'zGoToMobile4FromMobile5',
  Z_PAYMENT_VIEW_TAP = 'zPaymentviewtap',
  Z_FTD_FOR_USER = 'zFTDforUser',
  Z_LI_REGISTRATION_OK = 'zLIRegistrationOk',
  Z_LI_REGISTRATION_ERROR = 'zLIRegistrationError',
  Z_REGISTRATION = 'zRegistration',
  Z_REGISTRATION_PROCESS = 'zRegistrationProcess',
  Z_REGISTRATION_SUCCESS = 'zRegistrationSuccess',
  Z_REGISTRATION_ERROR = 'zRegistrationError',
  Z_USER_FEEDBACK = 'zUserFeedback',
  USER_ACTIONS = 'userActions',
  Z_INIT = 'zInit',
  Z_OPEN_PROMO_PAGE = 'zOpenPromoPage',
  Z_IDENT_SUCCESS = 'zIdentSuccess',
  Z_IDENT_FAIL = 'zIdentFail',
  Z_CASH_OUT = 'zCashOut',
  MODAL_ERROR_MESSAGES = 'ModalErrorMessages',
  CLICK_MAP = 'zClickMap',
  Z_CLICK_JACKPOT_BANNER = 'zClickJackpotBanner',
  Z_PARTICIPATION_IN_PROMO = 'zParticipationInPromo',
  Z_PUSH_CLICK = 'zPushClick',
  Z_PUSH_DISPLAY = 'zPushDisplay',
  Z_PHONE_CHECK = 'zPhoneCheck',
  Z_BONUS_CODE = 'zBonusCode',
  Z_IFRAME_CLICK = 'ziFrameClick',
  Z_SEARCH = 'zSearch',
  Z_SEARCH_SPORT = 'zSearchSport',
  Z_SEARCH_BY_PROVIDER = 'zSearchByProvider',
  Z_SEARCH_GAME = 'zSearchGame',
  Z_OPEN_GAME_LOBBY = 'zOpenGameLobby',
  Z_CLICK_DEPOSIT = 'zСlickDeposit',
  Z_OPEN_DEPOSIT_METHOD = 'zOpenDepositMethod',
  Z_DURATION_REGISTRATION = 'zDurationRegistration',
  Z_CLOSE_PREVIEW_GAME = 'zClosePreviewGame',
  Z_BONUS_REJECTION = 'zBonusRejection',
  Z_AB_TEST_NEW_USERS = 'zABTestNewUsers',
  Z_VISIT_BONUSES_PAGE = 'zVisitBonusesPage',
  Z_SWIPE_GAMES = 'zSwipeGames',
  Z_CAMPAIGN_REJECTION = 'zCampaignRejection',
  Z_SUPER_LIVE_BANNER = 'zSuperLiveBanner',
  Z_VIRTUAL_SPORT_BANNER = 'zVirtualSportBanner',
  Z_PROGRESS_LOYALTY_PROGRAM = 'zProgressLoyaltyProgram',
  Z_LOYALTY_PROGRAM_ONBOARDING = 'zLoyaltyProgramOnboarding',
  Z_LOYALTY_PROGRAM_INFO = 'zLoyaltyProgramInfo',
  Z_ONBOARDING = 'zOnboarding',
  Z_HIDE_BALANCE = 'zHideBalance',
  Z_LOADER_LOADING = 'zLoaderLoading',
  Z_LOADER_CLOSE = 'zLoaderClose',
  Z_STATISTICS_BUTTON = 'zStatisticsButton',
  Z_CLICK_NON_ACTIVE_DEPOSIT_BUTTON = 'zClickNonActiveDepositButton',
  Z_REFER_FRIEND = 'zReferFriend',
  Z_UPDATE_CORDOVA_APP = 'zUpdateCordovaApp',
  Z_WITHDRAWAL_WAGER_NO = 'zWithdrawalWagerNo',
  Z_WITHDRAWAL_WAGER_YES = 'zWithdrawalWagerYes',
  Z_MODAL_WITHDRAWAL_WAGER_CLICKS = 'zModalWithdrawalWagerClicks',
  Z_OPEN_CORDOVA_FIRST_TIME = 'zOpenCordovaFirstTime',
  Z_PREDEFINED_SUM_DEPOSIT = 'zPredefinedSumDeposit',
  Z_AUTH_VISIT_PAGE_EURO = 'zAuthVisitPageEuro',
  Z_NON_AUTH_VISIT_PAGE_EURO = 'zNonAuthVisitPageEuro',
  Z_GET_CARD_EURO = 'zGetCardEuro',
  Z_MISCLICK_BATTLE_PASS_EURO = 'zMisclickBattlePassEuro',
  Z_PROMO_CONDITIONS_EURO = 'zPromoConditionsEuro',
  Z_MY_BONUSES_EURO = 'zMyBonusesEuro',
  Z_MISCLICK_CARD_EURO = 'zMisclickCardEuro',
  Z_AUTH_VIEW_OFFERS_EURO = 'zAuthViewOffersEuro',
  Z_AUTH_PARTICIPATE_EURO = 'zAuthParticipateEuro',
  Z_MISCLICK_LEADER_BOARD = 'zMisclickLeaderBoard',
  Z_CLICK_ARROW_TOURNAMENT = 'zClickArrowTournament',
  Z_LOGIN_MAIN_EURO = 'zLogInMainEuro',
  Z_LOGIN_BATTLE_PASS_EURO = 'zLogInBattlePassEuro',
  Z_LOGIN_TOURNAMENT_EURO = 'zLogInTournamentEuro',
  Z_GET_PRIZE_BATTLE_PASS_EURO = 'zGetPrizeBattlePassEuro',
  Z_NON_AUTH_NO_CLICKS = 'zNonAuthNoClicks',
  Z_AUTH_NO_CLICKS = 'zAuthNoClicks',
  Z_BONUS_USAGE_EURO = 'zBonusUsageEuro',
  Z_MY_COLLECTION_EURO = 'zMyCollectionEuro',
  Z_MISCLICK_MY_COLLECTION_EURO = 'zMisclickMyCollectionEuro',
  Z_MY_COLLECTION_DURATION_EURO = 'zMyCollectionDurationEuro',
  Z_MY_COUPONS_EURO = 'zMyСouponsEuro',
  Z_MISCLICK_MY_COUPONS_EURO = 'zMisclickMyСouponsEuro',
  Z_MISCLICK_PRIZE_DRAWING_EURO = 'zMisclickPrizeDrawingEuro',
  Z_DETAILS_RAFFLE_PRIZES_EURO = 'zDetailsRafflePrizesEuro',
  Z_FOLLOW_BONUSES = 'zFollowBonuses',
  Z_CLICK_TO_OPEN_VIP = 'zClickToOpenVIP',
  Z_AUTH_VISIT_VIP = 'zAuthVisitVIP',
  Z_NON_AUTH_VISIT_VIP = 'zNonAuthVisitVIP',
  Z_AUTH_BECOME_VIP_BUTTON = 'zAuthBecomeVIPButton',
  Z_NON_AUTH_BECOME_VIP_BUTTON = 'zNonAuthBecomeVIPButton',
  Z_AUTH_CONTACT_US_BUTTON = 'zAuthContactUsButton',
  Z_NON_AUTH_CONTACT_US_BUTTON = 'zNonAuthContactUsButton',
  Z_FAQ_ARTICLE_VIP = 'zFAQarticleVIP',
  Z_ADVANTAGES_TAB_VIP = 'zAdvantagesTabVIP',
  Z_MISCLICK_ADVANTAGES = 'zMisClickAdvantages',
  ADVENT_RECEIVE = 'advent_receive',
  ADVENT_OBTAIN_REWARD = 'advent_obtain_reward',
  ADVENT_APPLIED_REWARD = 'advent_applied_reward',
  BET_SHARE = 'bet_share',
  BET_SHARE_COPY_LINK = 'bet_copy',
  BET_SHARE_ERROR = 'bet_share_error',
  SHARED_BET_LOAD = 'bet_load',
  SHARED_BET_ERROR = 'bet_load_error',
  NEW_HOMEPAGE_VIEW = 'new_homepage_view',
}

export default Events;
