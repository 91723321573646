import { SportFilterKickoffPeriod } from '@leon-hub/api-sdk';

import type { SportDateTimeFilter } from 'web/src/modules/sportline/submodules/navigation/store/types';
import type {
  DateTimeRange,
  EmptyDateTimeRange,
} from 'web/src/modules/sportline/types';

import KickoffPeriodUtils from './KickoffPeriodUtils';

export default function getFilterDateTimeInterval({
  activeKickoffPeriod,
  sportsListFilter,
  doUseRelativeTimeForSportline,
}: {
  activeKickoffPeriod: SportFilterKickoffPeriod;
  sportsListFilter: SportDateTimeFilter;
  doUseRelativeTimeForSportline: boolean;
}): DateTimeRange {
  const defaultInterval: EmptyDateTimeRange = { from: undefined, to: undefined };

  if (activeKickoffPeriod === SportFilterKickoffPeriod.CUSTOM) {
    const { customDateFrom, customDateTo } = sportsListFilter;
    return customDateFrom && customDateTo
      ? {
        from: customDateFrom,
        to: customDateTo,
      }
      : defaultInterval;
  }

  return doUseRelativeTimeForSportline
    ? KickoffPeriodUtils.optionalRelativeDateTimeRangeInMinutes(sportsListFilter.activeKickoffPeriod)
    : KickoffPeriodUtils.dateTimeRangeFromKickoffPeriod(sportsListFilter.activeKickoffPeriod);
}
