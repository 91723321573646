import { logger } from '@leon-hub/logging';

import type { TrackingPayload } from '../types';

export default function logPlaceBetResultSuccess({
  slipEntries,
  liveEventsIds,
  priceChangePolicy,
  slipType,
}: TrackingPayload): void {
  for (const { result, isMaxStake, fastBets } of slipEntries) {
    const payload = {
      response: result,
      liveEventsIds,
      slipType,
      isMaxStake,
      fastBets,
      priceChangePolicy,
    };
    logger.info('Place bet success', payload);
  }
}
