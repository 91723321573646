export enum TitleKind {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
}

export interface LandingTitleProps {
  content: string;
  kind?: TitleKind;
  isInverse?: boolean;
  isBorderedAccent?: boolean;
}
