<script lang="ts" setup>
import { toRef } from 'vue';

import { IconName, IconSize } from '@leon-hub/icons';
import type { Web2Image } from '@leon-hub/api-sdk';
import { ButtonHeight, ButtonKind } from '@leon-hub/module-buttons';

import { VIcon } from '@components/v-icon';

import LButton from 'web/src/components/Button/LButton/LButton.vue';
import { ObjectFitOption } from 'web/src/components/Image/VImage/enums';
import VImage from 'web/src/components/Image/VImage/VImage.vue';

import type { VSnackBarEmits, VSnackBarProps } from '../../types';

const props = withDefaults(defineProps<VSnackBarProps>(), {
  type: 'default',
});

const emit = defineEmits<VSnackBarEmits>();

const imageSrc = toRef<() => Web2Image | undefined>(() => {
  if (props.image) {
    return props.image;
  }

  return undefined;
});

const isSingleRow = toRef(() => (!!props.title && !props.text) || (!!props.text && !props.title));
const hasButtons = toRef(() => !!props.buttonLabel || !!props.primaryButton?.label || !!props.secondaryButton?.label || !!props.linkButton?.label);
</script>

<template>
  <div v-auto-id="'SnackbarContent'"
    :class="{
      [$style['snack-bar']]: true,
      [$style['snack-bar--multiline']]: !isSingleRow,
      [$style['snack-bar--with-close-button']]: hasClose,
      [$style['snack-bar--with-graphics']]: iconName || imageSrc,
      [$style['snack-bar--with-progress']]: duration && duration > 0,
    }"
  >
    <div
      v-if="iconName || image"
      :class="$style['snack-bar__graphics']"
    >
      <VImage
        v-if="imageSrc"
        v-data-test="{el:'snack-bar__image'}"
        v-bind="imageSrc"
        :class="$style['snack-bar__image']"
        :object-fit="ObjectFitOption.CONTAIN"
      />
      <VIcon
        v-if="iconName"
        v-data-test="{el:'snack-bar__icon'}"
        :name="iconName"
        :class="[
          $style['snack-bar__icon'],
          $style[`snack-bar__icon--type-${type}`],
        ]"
        :size="IconSize.SIZE_24"
      />
    </div>
    <div :class="$style['snack-bar__content']">
      <p
        v-if="isSingleRow"
        v-data-test="{el:'snack-bar__single-label'}"
        :class="$style['snack-bar__single-label']"
      >
        {{ title || text }}
      </p>
      <template v-if="!isSingleRow">
        <div
          :class="$style['snack-bar__labels']"
        >
          <h3
            v-data-test="{el:'snack-bar__title'}"
            :class="$style['snack-bar__title']"
          >
            {{ title }}
          </h3>
          <p
            v-data-test="{el:'snack-bar__text'}"
            :class="{
              [$style['snack-bar__text']]: true,
              [$style['snack-bar__text--with-padding']]: !!buttonLabel,
            }"
          >
            {{ text }}
          </p>
        </div>
      </template>
      <LButton
        v-if="hasClose && ((isSingleRow && !hasButtons) || (!isSingleRow && hasClose))"
        v-data-test="{el:'snack-bar__close-button'}"
        :class="$style['snack-bar__close-button']"
        :icon-name="IconName.CROSS"
        :icon-size="IconSize.SIZE_24"
        @click="emit('click-close', $event)"
      />
    </div>
    <div
      v-if="hasButtons"
      :class="$style['snack-bar__buttons-container']"
    >
      <LButton
        v-if="!!buttonLabel || !!primaryButton?.label"
        v-data-test="{el:'snack-bar__button'}"
        v-bind="primaryButton"
        :class="$style['snack-bar__button']"
        :height="ButtonHeight.TINY"
        :label="buttonLabel || primaryButton?.label"
        :kind="ButtonKind.PRIMARY"
        :icon-name="isPrimaryButtonDone ? IconName.CHECK_FILLED : primaryButton?.iconName"
        rounded
        :is-uppercase="false"
        @click="emit('click', $event)"
      />
      <LButton
        v-if="!!secondaryButton?.label"
        v-data-test="{el:'snack-bar__button'}"
        v-bind="secondaryButton"
        :class="$style['snack-bar__button']"
        :height="ButtonHeight.TINY"
        :label="secondaryButton?.label"
        :disabled="secondaryButton?.disabled"
        :kind="secondaryButton?.kind || ButtonKind.SECONDARY"
        :icon-name="isSecondaryButtonDone ? IconName.CHECK_FILLED : secondaryButton?.iconName"
        rounded
        :is-uppercase="false"
        @click="emit('click-secondary', $event)"
      />
      <div
        v-if="!!linkButton?.label"
        :class="$style['snack-bar__buttons-container-bottom']"
      >
        <LButton
          v-data-test="{el:'snack-bar__button'}"
          v-bind="linkButton"
          :class="{
            [$style['snack-bar__button']]: true,
            [$style['snack-bar__button--link']]: true,
          }"
          :height="ButtonHeight.TINY"
          :label="linkButton?.label"
          :kind="ButtonKind.TRANSPARENT"
          rounded
          :is-uppercase="false"
          @click="emit('click-link', $event)"
        />
      </div>
    </div>
    <div
      v-if="duration && duration > 0"
      :class="$style['snack-bar__progress-container']"
    >
      <div
        :class="$style['snack-bar__progress-bar']"
        :style="{ animationDuration: `${duration}s` }"
      />
    </div>
  </div>
</template>

<style lang="scss" module>
$types: (
  default: var(--Layer1),
  warning: var(--SnackbarYellow),
  success: var(--SnackbarBrand),
  error: var(--SnackbarRed),
);
$horizontalPadding: 10px;
$horizontalPaddingWithoutIcon: 42px;

.snack-bar {
  $self: &;

  display: flex;
  align-items: center;
  overflow: hidden auto;
  background-color: var(--SnackbarBackground);
  backdrop-filter: blur(25px);
  border-radius: 8px;

  &--multiline {
    flex-wrap: wrap;
    align-items: flex-start;

    #{$self}__buttons-container {
      width: 100%;
      padding-bottom: 8px;
    }
  }

  &--with-graphics {
    display: grid;
    grid-template-areas:
      'graphics content'
      'graphics buttons';
    grid-template-columns: auto 1fr;
    align-items: flex-start;
  }

  &--with-graphics:not(#{$self}--multiline) {
    display: flex;
    align-items: center;
  }

  &--with-progress {
    padding-bottom: 8px;
  }

  &--with-graphics#{$self}--multiline {
    #{$self}__buttons-container {
      padding-bottom: 12px;
    }
  }

  &--multiline#{$self}--with-progress {
    padding-bottom: 4px;
  }

  &__graphics {
    flex-shrink: 0;
    grid-area: graphics;
    padding: 12px 0 12px 12px;
  }

  &__image {
    width: 96px;
    height: 96px;
    pointer-events: none;
    user-select: none;
  }

  &__content {
    display: flex;
    flex: 1;
    grid-area: content;
    padding: 4px 0 4px 12px;
  }

  &__labels {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    width: 100%;
    padding: 8px 8px 4px 0;
  }

  &__title {
    @include medium\16\24\025;

    margin: 0;
    color: var(--Layer1);
    word-break: break-word;
  }

  &__text {
    @include regular\14\20\025;

    margin: 0;
    color: var(--Layer1);
    word-break: break-word;
  }

  &__single-label {
    @include medium\14\24\025;

    flex: 1;
    padding: 8px 0;
    margin: 0;
    color: var(--Layer1);
  }

  &__icon {
    @each $name, $type in $types {
      &--type-#{$name} {
        color: $type;
      }
    }
  }

  &__buttons-container {
    display: flex;
    flex-wrap: wrap;
    grid-area: buttons;
    padding: 0 4px 0 8px;

    &-bottom {
      display: flex;
      width: 100%;
    }
  }

  & &__button {
    @include medium\14\16\025;

    width: fit-content;
    padding: 0 12px;
    margin: 4px;

    &[disabled] {
      pointer-events: none;

      &[active] {
        transform: none;
      }
    }

    &--is-right {
      margin: 0 0 0 8px;
    }

    &--link {
      padding: 0;
      color: var(--Layer1);
      text-decoration: underline;
    }
  }

  & &__close-button {
    position: sticky;
    top: 0;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    padding: 0;
    margin: 0 4px 0 0;
    color: var(--Highlight);
    cursor: pointer;
    background-color: transparent;
    border: none;

    @include is-app-layout-desktop {
      @include for-hover {
        &:hover:not([disabled]) {
          color: var(--Layer1);
          background-color: transparent;
        }
      }
    }
  }

  &__progress-container {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 4px;
    background-color: var(--OpacityLayer2);
  }

  &__progress-bar {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background-color: var(--SnackbarBrand);
    border-radius: 8px;
    animation-name: animate-progress;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
  }

  @keyframes animate-progress {
    0% {
      width: 0;
    }

    100% {
      width: 100%;
    }
  }
}
</style>
