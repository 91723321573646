<script setup lang="ts">
import type { VBadgeProps, VBadgeEmits } from 'web/src/components/Badge/VBadge/types';
import type { SBadgeProps } from 'web/src/components/Badge/SBadge/types';
import SBadge from 'web/src/components/Badge/SBadge/SBadge.vue';
import LBadge from 'web/src/components/Badge/LBadge/LBadge.vue';

const props = defineProps<VBadgeProps>();
const emit = defineEmits<VBadgeEmits>();
const remappedProps = (p: VBadgeProps): SBadgeProps => ({
  size: 'small',
  type: 'primary',
  iconName: p.iconName,
  label: p.label,
  isCounter: p.isCounter,
});
</script>

<template>
  <SBadge
    v-if="null"
    v-bind="remappedProps(props)"
    @click="emit('click', $event)"
  />
  <LBadge
    v-else
    v-bind="props"
    @click="emit('click', $event)"
  />
</template>
