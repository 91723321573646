import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import type { LobbyPromotionTitlePanelProps } from 'web/src/modules/lobby/components/LobbyPromotionTitlePanel/types';

export interface LobbyPromotionTitlePanelComposable {
  formattedText: ComputedRef<string>;
}

export default function useLobbyPromotionTitlePanel(
  props: RequiredField<LobbyPromotionTitlePanelProps, 'description'>,
): LobbyPromotionTitlePanelComposable {
  const formattedText = computed(() => props.description.slice(0, 80) + (props.description.length > 80 ? '...' : ''));

  return {
    formattedText,
  };
}
