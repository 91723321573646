<script setup lang="ts">
import { computed } from 'vue';

import { useTheme } from 'web/src/modules/theme/composables';
import { useSportlineLayoutSettings } from 'web/src/modules/sportline/composables/settings';
import { useBaseSportEventDetailsHeadline } from 'web/src/modules/sportline/submodules/event-details/composables/headline';
import { useSportlineEventMarketsPriority } from 'web/src/modules/sportline/composables/markets';
import TrackerWidget from 'web/src/modules/sportline/submodules/widgets/views/TrackerWidget.vue';
import SportlineEventStream from 'web/src/modules/sportline/submodules/event-details/views/widgets/SportlineEventStream.vue';
import WideHeadlineLayout from 'web/src/modules/sportline/submodules/event-details/views/headline-layout/WideHeadlineLayout.vue';
import WideInfo from 'web/src/modules/sportline/submodules/event-details/views/headline-info/WideInfo.vue';
import FloatingWidgetBox from 'web/src/modules/sportline/submodules/widgets/components/FloatingWidgetBox.vue';
import PrimaryMarket from 'web/src/modules/sportline/views/primary-market/PrimaryMarket.vue';
import PrimaryMarketName from 'web/src/modules/sportline/views/primary-market/PrimaryMarketName.vue';
import HighestOddsBadge from 'web/src/modules/sportline/submodules/highest-odds/components/HighestOddsBadge.vue';

import type { SportEventDetailsHeadlinePosition } from './types';
import { useHeadlineDimensionExpose, useSportlineEventDetailsHeadlineData } from './composables';
import { useWideSportlineEventDetailsHeadline } from './useWideSportlineEventDetailsHeadline';
import PinWidgetSwitcher from '../../components/widget-switcher/PinWidgetSwitcher.vue';
import HorizontalBetSwipeInfo from './bet-swipe/HorizontalBetSwipeInfo.vue';

const { isLight } = useTheme();
const {
  sportlineEvent,
  sportElement,
  sport,
  region,
  league,
  doShowStreamWidget: isStreamAvailable,
  widgetConfig,
  liveWidgetType,
  defaultSwiperSlideId,
  onHeadlineSlideChanged,
} = useSportlineEventDetailsHeadlineData();

const {
  activeSlideId,
  isLiveWidgetAvailable,
  widgetProperties,
  switcherSlidesIds,
} = useBaseSportEventDetailsHeadline({
  defaultSlideId: defaultSwiperSlideId,
  sportEventDetails: sportlineEvent,
  isStreamAvailable,
  widgetConfig,
  liveWidgetType,
});
const { isBetSwipeEventDetailsLayoutEnabled } = useSportlineLayoutSettings();
const {
  primaryMarket,
} = useSportlineEventMarketsPriority({
  sportEvent: sportlineEvent,
  isPrimaryMarketFiltrationDisabled: computed<boolean>(() => (
    !!null && isBetSwipeEventDetailsLayoutEnabled.value
  )),
});
const {
  isStreamFloatingEnabled,
  isLiveWidgetFloatingEnabled,
  isLiveWidgetShown,
  isStreamShown,
} = useWideSportlineEventDetailsHeadline({
  activeSlideId,
  isStreamAvailable,
  isLiveWidgetAvailable,
  widgetConfig,
  doForceShowInplayComponent: computed<boolean>(() => (
    null
      ? !isBetSwipeEventDetailsLayoutEnabled.value
      : true
  )),
});
const { headlineElement, getPosition, getHeight } = useHeadlineDimensionExpose();

defineExpose<SportEventDetailsHeadlinePosition>({ getPosition, getHeight });
</script>

<template>
  <div v-auto-id="'WideSportlineEventDetailsHeadline'"
    v-if="sportlineEvent && sport && region && league"
    ref="headlineElement"
    :class="{
      [$style['sport-event-details-headline']]: true,
      [$style['sport-event-details-headline--light']]: isLight,
      [$style['sport-event-details-headline--right-angle']]: null && isBetSwipeEventDetailsLayoutEnabled,
    }"
  >
    <HorizontalBetSwipeInfo
      v-if="null && isBetSwipeEventDetailsLayoutEnabled"
      :sportline-event="sportlineEvent"
      :sport="sport"
      :region="region"
      :league="league"
      :switcher-slides-ids="switcherSlidesIds"
      :active-slide-id="activeSlideId"
      :is-stream-available="isStreamAvailable"
      :is-live-widget-available="isLiveWidgetAvailable"
      @slide-changed="onHeadlineSlideChanged"
    >
      <template
        v-if="isStreamShown && isStreamAvailable"
        #stream
      >
        <FloatingWidgetBox
          :is-floating-mode-enabled="isStreamFloatingEnabled"
          is-full-size
          is-stream
        >
          <SportlineEventStream
            v-show="isStreamShown"
            :is-active="isStreamShown"
            :is-floating-mode-allowed="false"
          />
        </FloatingWidgetBox>
      </template>

      <template
        v-if="isLiveWidgetShown && !!widgetProperties"
        #live-widget
      >
        <FloatingWidgetBox
          :is-floating-mode-enabled="isLiveWidgetFloatingEnabled"
          is-full-size
        >
          <TrackerWidget
            :widget="widgetProperties"
            :is-active="isLiveWidgetShown"
            :is-floating-mode-allowed="false"
          />
        </FloatingWidgetBox>
      </template>
    </HorizontalBetSwipeInfo>
    <WideHeadlineLayout
      v-else
      :segment-id="sport.segment.id"
      :sport-event-details="sportlineEvent"
      :has-inplay-component="isStreamAvailable || isLiveWidgetAvailable"
      :has-primary-market="!!primaryMarket"
    >
      <template #info="{ backgroundColor }">
        <WideInfo
          :sportline-event="sportlineEvent"
          :background-color="backgroundColor"
        >
          <template
            v-if="primaryMarket"
            #primary-market="{ hasTimeline, hasCountdown }"
          >
            <PrimaryMarket
              :market="primaryMarket"
              :sport-element="sportElement"
              :sport-event-details="sportlineEvent"
            >
              <template #top="topScope">
                <PrimaryMarketName
                  :class="{
                    [$style['primary-market__name--after-timeline']]: hasTimeline,
                    [$style['primary-market__name--after-countdown']]: hasCountdown,
                  }"
                  :label="topScope.marketName"
                />
              </template>

              <template
                v-if="sportlineEvent.hasZeroMargin"
                #bottom
              >
                <HighestOddsBadge
                  highest-odds-icon-color="highlight"
                  :badge-background="backgroundColor ? 'dark' : 'default'"
                  badge-height="16"
                />
              </template>
            </PrimaryMarket>
          </template>
        </WideInfo>
      </template>

      <template #inplay-component="{ setHeight, backgroundColor }">
        <PinWidgetSwitcher
          v-if="isStreamAvailable && isLiveWidgetAvailable"
          :class="$style['sport-event-details-headline__widget-button']"
          :active-slide-id="activeSlideId"
          :slide-ids="switcherSlidesIds"
          @update:active-slide-id="onHeadlineSlideChanged"
        />

        <FloatingWidgetBox
          v-if="widgetProperties && isLiveWidgetShown"
          :is-floating-mode-enabled="isLiveWidgetFloatingEnabled"
          is-full-size
        >
          <template #default>
            <TrackerWidget
              :widget="widgetProperties"
              :is-active="isLiveWidgetShown"
              :is-floating-mode-allowed="false"
              :background-color="backgroundColor"
              @size-changed="setHeight?.($event.height)"
            />
          </template>
        </FloatingWidgetBox>

        <FloatingWidgetBox
          v-if="isStreamAvailable && isStreamShown"
          :is-floating-mode-enabled="isStreamFloatingEnabled"
          is-full-size
          is-stream
        >
          <template #default>
            <SportlineEventStream
              :is-active="isStreamShown"
              :is-floating-mode-allowed="false"
              @size-changed="setHeight?.($event.height)"
            />
          </template>
        </FloatingWidgetBox>
      </template>
    </WideHeadlineLayout>
  </div>
</template>

<style module lang="scss">
.primary-market {
  &__name {
    &--after-countdown,
    &--after-timeline {
      margin-top: -8px;
    }
  }
}

.sport-event-details-headline {
  margin-bottom: 8px;
  overflow: hidden;
  color: var(--White);
  border-radius: 5px;

  &__widget-button {
    @include z-index('absolute-item');

    position: absolute;
    top: 4px;
    left: 4px;
  }

  &--light {
    color: var(--Black);
  }

  &--right-angle {
    border-radius: 0;
  }
}
</style>
