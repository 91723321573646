import type { SharedSlipEntry } from '@leon-hub/api-sdk';

import type { SlipEntry } from '../../slip-info/types';
import { getSafeCompetitorsValue } from '../../slip-info/utils';

export function getSlipEntryFromSharedSlipEntry(input: SharedSlipEntry): SlipEntry {
  return {
    ts: input.ts,
    event: input.event,
    market: input.market,
    runner: input.runner,
    odds: input.odds,
    oddsStr: input.oddsStr,
    banker: input.banker,
    marketStatus: input.marketStatus,
    betline: input.betline,
    zeroMargin: input.zeroMargin,
    competitors: getSafeCompetitorsValue(input.competitors),
    runnerTags: input.runnerTags,
    marketTypeIdentifier: input.marketTypeIdentifier,
    marketTypeTag: input.marketTypeTag,
  };
}
