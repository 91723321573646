<script lang="ts" setup>
import type { BonusRequirement } from '@leon-hub/api-sdk';

import { VIcon } from '@components/v-icon';

import VList from 'web/src/components/List/VList/VList.vue';
import { ListType } from 'web/src/components/List/enums';

import VTermsStepProgress from '../VTermsStepProgress/VTermsStepProgress.vue';
import { useVTermsSteps } from './composables';

const props = withDefaults(defineProps<VTermsStepsProps>(), {
  items: () => ([]),
});

const emit = defineEmits<VTermsStepsEmits>();

interface VTermsStepsProps {
  items?: BonusRequirement[];
  showReadMoreButton?: boolean;
}

interface VTermsStepsEmits {
  (e: 'click', event: MouseEvent): void;
}

const {
  iconCheckProps,
  iconMoreProps,
  listItemControl,
} = useVTermsSteps(props);

function emitClick(event: MouseEvent): void {
  emit('click', event);
}
</script>

<template>
  <div v-auto-id="'VTermsSteps'" :class="$style['terms-steps']">
    <VList
      :class="$style['terms-steps__list']"
      :list-type="ListType.GROUP"
    >
      <component
        :is="listItemControl(index)"
        v-for="(item, index) in items"
        :id="index.toString()"
        :key="index"
        :class="{
          [$style['terms-steps__accordion-item']]: true,
          [$style['terms-steps__accordion-item--finished']]: item.satisfied
        }"
      >
        <template #inner>
          <div
            :class="$style['terms-steps__accordion-item__header']"
          >
            <div
              :class="{
                [$style['terms-steps__icon-wrapper']]: true,
                [$style['terms-steps__icon-wrapper--checked']]: item.satisfied}"
            >
              <VIcon
                v-if="item.satisfied"
                v-bind="iconCheckProps"
                :class="$style['terms-steps__icon']"
              />
            </div>
            <span :class="$style['terms-steps__title']">
              {{ item.name }}
            </span>
          </div>
        </template>

        <template #content>
          <div :class="$style['terms-steps__progress']">
            <VTermsStepProgress
              :progress-bar-value="item.progressBarValue ?? undefined"
              :value-type="item.valueType ?? undefined"
              :current-value="item.currentValue ?? undefined"
              :required-value="item.requiredValue ?? undefined"
            />
          </div>
        </template>
      </component>
    </VList>
    <div
      v-if="showReadMoreButton"
      :class="$style['terms-steps__read-more']"
      @click="emitClick"
    >
      {{ $t('WEB2_READ_MORE') }}
      <VIcon
        v-bind="iconMoreProps"
        :class="$style['terms-steps__read-more-icon']"
      />
    </div>
  </div>
</template>

<style lang="scss" module>
@include for-layout using($isDesktop) {
  .terms-steps {
    &__list {
      background-color: transparent;
      border-radius: 5px 5px 0 0;
    }

    &__accordion-item {
      margin-bottom: 2px;
      background-color: var(--Layer1);

      @if $isDesktop {
        cursor: default;
      }

      &:first-child {
        overflow: hidden;
        border-radius: 5px 5px 0 0;
      }

      &__header {
        display: inline-flex;
        align-items: center;
      }

      &--finished {
        position: relative;
        background: var(--OpacityBrandDefault);
      }
    }

    &__icon-wrapper {
      position: relative;
      flex-shrink: 0;
      width: 36px;
      color: var(--BrandDefault);
      text-align: center;

      &:after {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 20px;
        height: 20px;
        content: '';
        background-color: var(--Layer0);
        border-radius: 50%;
        transform: translate(-50%, -50%);
      }

      &--checked {
        &:after {
          background-color: transparent;
        }
      }
    }

    &__icon {
      @include z-index(base);

      position: relative;
      fill: currentcolor;
    }

    &__title {
      @include medium\14\20\025;

      color: var(--TextPrimary);
    }

    &__read-more {
      @include medium\14\20\025;

      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 44px;
      padding: if($isDesktop, 0 16px, 0 12px);
      color: var(--TextPrimary);
      cursor: pointer;
      background-color: var(--Layer1);
      border-radius: 5px;
      transition: background-color 0.15s $animationEaseInOut;

      &-icon {
        fill: var(--TextDefault);
      }

      &:hover {
        @if $isDesktop {
          background-color: var(--Highlight);
        }
      }
    }

    &__progress {
      flex-grow: 1;
    }
  }
}
</style>
