import ChunkLoadError from '@leon-hub/routing-config/src/services/ChunkLoadError';

import { ApiConnectionError } from './ApiConnectionError';
import { ApiRequestAbortedError } from './ApiRequestAbortedError';
import { ApiServiceUnavailableError } from './ApiServiceUnavailableError';
import { GqlApiAccessDeniedError } from '../graphql/errors/GqlApiAccessDeniedError';

export default function isGeneralApiError(error: unknown): boolean {
  return error instanceof ChunkLoadError
    || error instanceof ApiConnectionError
    || error instanceof ApiServiceUnavailableError
    || error instanceof ApiRequestAbortedError
    || error instanceof GqlApiAccessDeniedError;
}
