import { ref } from 'vue';

import { localeDateTimeSpecMonthFullNames } from './mocks';
import type { $T, MonthsRefs } from '../types';

export function getMonthFullNames($t: $T): MonthsRefs {
  return [
    process.env.NODE_ENV === 'test' ? ref(localeDateTimeSpecMonthFullNames[0]) : $t('WEB2_MONTH_JANUARY_GENITIVE'),
    process.env.NODE_ENV === 'test' ? ref(localeDateTimeSpecMonthFullNames[1]) : $t('WEB2_MONTH_FEBRUARY_GENITIVE'),
    process.env.NODE_ENV === 'test' ? ref(localeDateTimeSpecMonthFullNames[2]) : $t('WEB2_MONTH_MARCH_GENITIVE'),
    process.env.NODE_ENV === 'test' ? ref(localeDateTimeSpecMonthFullNames[3]) : $t('WEB2_MONTH_APRIL_GENITIVE'),
    process.env.NODE_ENV === 'test' ? ref(localeDateTimeSpecMonthFullNames[4]) : $t('WEB2_MONTH_MAY_GENITIVE'),
    process.env.NODE_ENV === 'test' ? ref(localeDateTimeSpecMonthFullNames[5]) : $t('WEB2_MONTH_JUNE_GENITIVE'),
    process.env.NODE_ENV === 'test' ? ref(localeDateTimeSpecMonthFullNames[6]) : $t('WEB2_MONTH_JULY_GENITIVE'),
    process.env.NODE_ENV === 'test' ? ref(localeDateTimeSpecMonthFullNames[7]) : $t('WEB2_MONTH_AUGUST_GENITIVE'),
    process.env.NODE_ENV === 'test' ? ref(localeDateTimeSpecMonthFullNames[8]) : $t('WEB2_MONTH_SEPTEMBER_GENITIVE'),
    process.env.NODE_ENV === 'test' ? ref(localeDateTimeSpecMonthFullNames[9]) : $t('WEB2_MONTH_OCTOBER_GENITIVE'),
    process.env.NODE_ENV === 'test' ? ref(localeDateTimeSpecMonthFullNames[10]) : $t('WEB2_MONTH_NOVEMBER_GENITIVE'),
    process.env.NODE_ENV === 'test' ? ref(localeDateTimeSpecMonthFullNames[11]) : $t('WEB2_MONTH_DECEMBER_GENITIVE'),
  ] as const;
}
