<script lang="ts" setup>
import { useRouter } from 'vue-router';
import { ref } from 'vue';

import { RouteName } from '@leon-hub/routing-config-names';
import { IconName } from '@leon-hub/icons';

import { JumbotronFooterButton, VJumbotron } from 'web/src/components/Jumbotron';
import {
  ButtonKind,
} from 'web/src/components/Button/VButton/enums';
import SHintBlock from 'web/src/components/HintBlock/SHintBlock/SHintBlock.vue';

import type { ErrorContentProps } from './types';

withDefaults(defineProps<ErrorContentProps>(), {});

const router = useRouter();
const isShow = ref(true);

function onClickHelp(): void {
  void router.push({ name: RouteName.SUPPORT });
}

function hideSupport() {
  isShow.value = false;
}
</script>

<template>
  <div v-auto-id="'ErrorContent'"
    :class="{
      [$style['error-page']]: true,
      [$style[`error-page--${errorType}`]]: !!errorType,
    }"
  >
    <div
      :class="{
        [$style['error-page__holder']]: true,
        [$style['error-page__holder--plain']]: null && !!errorNumber,
        [$style['error-page__holder--slott']]: null
      }"
    >
      <VJumbotron
        v-bind="errorIcon"
        :image="image"
        :heading="errorTitle"
        :text="errorMessage"
        :alert-icon-size="alertIconSize"
        :alert-icon="alertIcon"
      >
        <template
          v-if="!!errorNumber"
          #icon
        >
          <div :class="$style['error-page__error-number']">
            {{ errorNumber }}
          </div>
        </template>
        <div
          v-if="!!$slots.description"
          :class="$style['error-page__description']"
        >
          <slot name="description" />
        </div>
        <template #footer>
          <slot name="footer" />
        </template>
      </VJumbotron>
    </div>
    <div
      v-if="hasSupport && isShow"
      :class="$style['error-page__holder']"
    >
      <SHintBlock
        v-if="null"
        :class="$style['error-page__hint']"
        type="tertiary"
        :icon-name="IconName.SLOTT_INTERROGATION"
        icon-color="secondary"
        :title="$t('WEB2_ERROR_TITLE_SUPPORT')"
        :text="$t('WEB2_ERROR_TEXT_SUPPORT_2')"
        :primary-button="{
          kind: 'primary',
          size: 'small',
          label: $t('WEB2_ERROR_BTN_SUPPORT')
        }"
        show-close
        @click-close="hideSupport"
        @click-primary="onClickHelp"
      />
      <VJumbotron
        v-else
        :text="$t('WEB2_ERROR_TEXT_SUPPORT')"
      >
        <template #footer>
          <div
            :class="$style['error-page__footer']"
          >
            <slot name="contacts-button">
              <JumbotronFooterButton
                :kind="ButtonKind.BASE"
                rounded
                :is-uppercase="false"
                :label="$t('WEB2_ERROR_BTN_SUPPORT')"
                @click="onClickHelp"
              />
            </slot>
          </div>
        </template>
      </VJumbotron>
    </div>
  </div>
</template>

<style lang="scss" module>
@import 'web/src/modules/errors/styles/error-page';
</style>
