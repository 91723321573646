import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import {
  assert,
  isOptionalString,
  isString,
} from '@leon-hub/guards';

import type { AutocompleteAddressProps } from 'web/src/modules/address/components';

import type { FormWrapperWidgetProperties, FormAutocompleteAddressRelatedFields } from '../../types';
import { getOptionsRelatedToSiblingFields, getWrapperHintProperties } from '../../utils';
import { isAutocompleteAddressProps } from '../../guards';

interface UseFormAutocompleteAddress {
  componentProps: ComputedRef<AutocompleteAddressProps>;
}

export default function useFormAutocompleteAddress(
  props: FormWrapperWidgetProperties<AutocompleteAddressProps & FormAutocompleteAddressRelatedFields>,
): UseFormAutocompleteAddress {
  const componentProps = computed<AutocompleteAddressProps>(() => {
    const {
      name,
      value,
      options,
      title,
      error,
      disabled,
      hintMessage,
      extraProperties,
      autofocus,
    } = props;

    assert(isOptionalString(value), `Unexpected value type: ${typeof value} at field ${name}`);

    const computedOptions: Partial<AutocompleteAddressProps> & FormAutocompleteAddressRelatedFields = options ?? {};
    const { relatedCountryFieldId, relatedCityFieldId, ...restOptions } = computedOptions;
    let combinedOptions: Partial<AutocompleteAddressProps> = { ...restOptions };
    combinedOptions = getOptionsRelatedToSiblingFields<AutocompleteAddressProps>('countryCode', relatedCountryFieldId, extraProperties?.formData, combinedOptions);
    combinedOptions = getOptionsRelatedToSiblingFields<AutocompleteAddressProps>('selectedCity', relatedCityFieldId, extraProperties?.formData, combinedOptions);

    assert(isString(computedOptions.countryCode), `required prop countryCode is not provided to field ${name}`);

    const allProps = {
      name,
      value,
      label: title,
      disabled,
      ...combinedOptions,
      ...getWrapperHintProperties({ options, error, hintMessage }),
      autofocus,
    };

    assert(isAutocompleteAddressProps(allProps));

    return allProps;
  });

  return {
    componentProps,
  };
}
