export enum SlottIconName {
  SLOTT_ADD = 'slott-add',
  SLOTT_ALARM_CLOCK = 'slott-alarm-clock',
  SLOTT_ANGLE_SMALL_DOWN = 'slott-angle-small-down',
  SLOTT_ANGLE_SMALL_RIGHT = 'slott-angle-small-right',
  SLOTT_ANGLE_SMALL_LEFT = 'slott-angle-small-left',
  SLOTT_APPS = 'slott-apps',
  SLOTT_BACKSPACE = 'slott-backspace',
  SLOTT_BADGE_PERCENT = 'slott-badge-percent',
  SLOTT_BALLOT_CHECK = 'slott-ballot-check',
  SLOTT_BELL = 'slott-bell',
  SLOTT_BELL_FILLED = 'slott-bell-filled',
  SLOTT_BELL_RING = 'slott-bell-ring',
  SLOTT_BELL_SLASH = 'slott-bell-slash',
  SLOTT_BOLT = 'slott-bolt',
  SLOTT_BOX = 'slott-box',
  SLOTT_BULLET = 'slott-bullet',
  SLOTT_BULLSEYE = 'slott-bullseye',
  SLOTT_CALENDAR_CLOCK = 'slott-calendar-clock',
  SLOTT_CALENDAR_LINES = 'slott-calendar-lines',
  SLOTT_CALL_INCOMING = 'slott-call-incoming',
  SLOTT_CALCULATOR_MONEY_1 = 'slott-calculator-money-1',
  SLOTT_CAMERA_VIEWFINDER = 'slott-camera-viewfinder',
  SLOTT_CHAT_ARROW_GROW = 'slott-chat-arrow-grow',
  SLOTT_CHAT_BUBBLE = 'slott-chatbuble',
  SLOTT_CHECK = 'slott-check',
  SLOTT_CHECK_CIRCLE = 'slott-check-circle',
  SLOTT_CIRCLE = 'slott-circle',
  SLOTT_CLIP = 'slott-clip',
  SLOTT_CLOCK = 'slott-clock',
  SLOTT_CLOCK_THREE = 'slott-clock-three',
  SLOTT_CLOUD_DOWNLOAD_ALT = 'slott-cloud-download-alt',
  SLOTT_COINS = 'slott-coins',
  SLOTT_COMMENT_ALT = 'slott-comment-alt',
  SLOTT_CREDIT_CARD = 'slott-credit-card',
  SLOTT_CREDIT_CARD2 = 'slott-credit-card2',
  SLOTT_CROSS_FILLED = 'slott-cross-filled',
  SLOTT_CROSS = 'slott-cross',
  SLOTT_CROSS_SMALL_BOLD = 'slott-cross-small-bold',
  SLOTT_CROWN = 'slott-crown',
  SLOTT_DEP_PROGRESS = 'slott-dep-progress',
  SLOTT_DISCORD = 'slott-discord',
  SLOTT_DOCUMENT = 'slott-document',
  SLOTT_DURATION_ALT = 'slott-duration-alt',
  SLOTT_DUPLICATE = 'slott-duplicate',
  SLOTT_ENVELOPE = 'slott-envelope',
  SLOTT_ERASE = 'slott-erase',
  SLOTT_EXCLAMATION = 'slott-exclamation',
  SLOTT_EYE = 'slott-eye',
  SLOTT_EYE_CROSSED = 'slott-eye-crossed',
  SLOTT_FILTER = 'slott-filter',
  SLOTT_FILTER_SLIDERS = 'slott-filter-sliders',
  SLOTT_FLAME = 'slott-flame',
  SLOTT_GEARS = 'slott-gears',
  SLOTT_GIFT2 = 'slott-gift2',
  SLOTT_GIFT_MINUS = 'slott-gift-minus',
  SLOTT_GIFT_NO = 'slott-gift-no',
  SLOTT_GIFT_PLUS = 'slott-gift-plus',
  SLOTT_GLOBE = 'slott-globe',
  SLOTT_HEART = 'slott-heart',
  SLOTT_HEART_RATE = 'slott-heart-rate',
  SLOTT_HEART_OUTLINE = 'slott-heart-outline',
  SLOTT_HISTORY = 'slott-history',
  SLOTT_HOME = 'slott-home',
  SLOTT_HOT = 'slott-hot',
  SLOTT_INFO = 'slott-info',
  SLOTT_INFO_1 = 'slott-info-1',
  SLOTT_INFO_OUTLINED = 'slott-info-outlined',
  SLOTT_INTERFACE = 'slott-interface',
  SLOTT_INTERROGATION = 'slott-interrogation',
  SLOTT_INTERROGATION_1 = 'slott-interrogation-1',
  SLOTT_KEY = 'slott-key',
  SLOTT_LIFE_RING = 'slott-life-ring',
  SLOTT_LIKE = 'slott-like',
  SLOTT_LIKE_OUTLINE = 'slott-like-outline',
  SLOTT_LOG_IN = 'slott-log-in',
  SLOTT_DISLIKE = 'slott-dislike',
  SLOTT_DISLIKE_OUTLINE = 'slott-dislike-outline',
  SLOTT_LOCK = 'slott-lock',
  SLOTT_MEDAL = 'slott-medal',
  SLOTT_MEGAPHONE = 'slott-megaphone',
  SLOTT_MEMO_CIRCLE_CHECK = 'slott-memo-circle-check',
  SLOTT_MENU_BURGER = 'slott-menu-burger',
  SLOTT_MESSAGES_QUESTION = 'slott-messages-question',
  SLOTT_MINUS_CIRCLE_1 = 'slott-minus-circle-1',
  SLOTT_MINUS_SMALL = 'slott-minus-small',
  SLOTT_MOVE = 'slott-move',
  SLOTT_MUG_HOT_ALT = 'slott-mug-hot-alt',
  SLOTT_NEW = 'slott-new',
  SLOTT_PAUSE_CIRCLE = 'slott-pause-circle',
  SLOTT_PAPER_PLANE = 'slott-paper-plane',
  SLOTT_PENCIL = 'slott-pencil',
  SLOTT_PHONE_CALL = 'slott-phone-call',
  SLOTT_PIGGY_BANK = 'slott-piggy-bank',
  SLOTT_PIN = 'slott-pin',
  SLOTT_PLAY = 'slott-play',
  SLOTT_PLAY_CIRCLE = 'slott-play-circle',
  SLOTT_PLAY_FILL = 'slott-play-fill',
  SLOTT_PLUS = 'slott-plus',
  SLOTT_PLUS_1 = 'slott-plus-1',
  SLOTT_PLUS_HEXAGON_1 = 'slott-plus-hexagon-1',
  SLOTT_PLUS_SMALL = 'slott-plus-small',
  SLOTT_QR = 'slott-qr',
  SLOTT_RECEIPT = 'slott-receipt',
  SLOTT_ROCKET = 'slott-rocket',
  SLOTT_SACK_DOLLAR = 'slott-sack-dollar',
  SLOTT_SEARCH = 'slott-search',
  SLOTT_SEPARATE = 'slott-separate',
  SLOTT_SETTINGS = 'slott-settings',
  SLOTT_SHARE = 'slott-share',
  SLOTT_SHIELD_CHECK = 'slott-shield-check',
  SLOTT_SHIELD_EXCLAMATION = 'slott-shield-exclamation',
  SLOTT_SPARKLES = 'slott-sparkles',
  SLOTT_SOUND_ON = 'slott-sound-on',
  SLOTT_SOUND_OFF = 'slott-sound-off',
  SLOTT_STAR = 'slott-star',
  SLOTT_STAR_1 = 'slott-star-1',
  SLOTT_SUPPORT = 'slott-support',
  SLOTT_SYMBOL = 'slott-symbol',
  SLOTT_TACHOMETER = 'slott-tachometer',
  SLOTT_TELEGRAM = 'slott-telegram',
  SLOTT_TICKET = 'slott-ticket',
  SLOTT_TRASH = 'slott-trash',
  SLOTT_TROPHY = 'slott-trophy',
  SLOTT_USD_CIRCLE = 'slott-usd-circle',
  SLOTT_USER = 'slott-user',
  SLOTT_USER_ADD = 'slott-user-add',
  SLOTT_USER_LOCK = 'slott-user-lock',
  SLOTT_USER_TIME = 'slott-user-time',
  SLOTT_USERS = 'slott-users',
  SLOTT_WALLET = 'slott-wallet',
  SLOTT_WALLET_DOWN = 'slott-wallet-down',
  SLOTT_WALLET_MINUS = 'slott-wallet-minus',
  SLOTT_WALLET_PLUS = 'slott-wallet-plus',
  SLOTT_WITHDRAWAL_PROGRESS = 'slott-withdrawal-progress',
  SLOTT_EXPAND = 'slott-expand',
  SLOTT_REFRESH = 'slott-refresh',
  SLOTT_HOME1 = 'slott-home1',
  SLOTT_CHEVRON_UP_CHEVRON_DOWN = 'slott-chevron-up-chevron-down',
  SLOTT_BROOM = 'slott-broom',
  SLOTT_WEBSITE_ACCESS = 'slott-website-access',
  SLOTT_WIFI_SLASH = 'slott-wifi-slash',
  SLOTT_X = 'slott-x',
  SLOTT_USER_SHIELD = 'slott-user-shield',
  SLOTT_PASSWORD = 'slott-password',
  SLOTT_SPY = 'slott-spy',
}
