<script lang="ts" setup>
import { computed } from 'vue';

import type {
  BaseModalEmits,
  ConfirmDialog,
} from 'web/src/modules/dialogs/types';
import { DialogComponent } from 'web/src/modules/dialogs/enums';
import ConfirmDepositModal
  from 'web/src/modules/dialogs/views/ConfirmDepositModal/ConfirmDepositModal.vue';
import BonusWithdrawalModal
  from 'web/src/modules/dialogs/views/BonusWithdrawalModal/BonusWithdrawalModal.vue';
import CmsContentModal from 'web/src/modules/dialogs/views/CmsContentModal/CmsContenModal.vue';
import CaptchaModal from 'web/src/modules/dialogs/views/CaptchaModal/CaptchaModal.vue';
import { useBaseModalActions } from 'web/src/modules/dialogs/composables';
import ConfirmWithdrawalModal
  from 'web/src/modules/dialogs/views/ConfirmWithdrawalModal/ConfirmWithdrawalModal.vue';
import CountDownModal from 'web/src/modules/dialogs/views/CountDownModal/CountDownModal.vue';
import TfaCodesModal from 'web/src/modules/dialogs/views/TfaCodesModal/TfaCodesModal.vue';
import ConfirmModal from 'web/src/modules/dialogs/views/ConfirmModal/ConfirmModal.vue';

import type { UniversalModalViewProps } from './types';

const props = withDefaults(defineProps<UniversalModalViewProps>(), {});

const emit = defineEmits<BaseModalEmits>();

const { onClose, onButtonClick } = useBaseModalActions(emit);

const component = computed(() => {
  switch (props.modal.modalComponent) {
    case DialogComponent.BonusWithdrawalModal:
      return BonusWithdrawalModal;
    case DialogComponent.CaptchaModal:
      return CaptchaModal;
    case DialogComponent.CmsContentModal:
      return CmsContentModal;
    case DialogComponent.ConfirmDepositModal:
      return ConfirmDepositModal;
    case DialogComponent.ConfirmWithdrawalModal:
      return ConfirmWithdrawalModal;
    case DialogComponent.CountDownModal:
      return CountDownModal;
    case DialogComponent.TfaCodesModal:
      return TfaCodesModal;
    default:
      return ConfirmModal;
  }
});

const modalProps = computed<ConfirmDialog>(() => ({
  ...props.modal,
  isInnerModal: props.isInnerModal,
  width: !props.isInnerModal ? props.modal.width : undefined,
  isProfileLayout: props.isProfileLayout || props.modal.isProfileLayout,
} as ConfirmDialog));
</script>

<template>
  <component v-auto-id="'UniversalModalView'"
    :is="component"
    :modal="modalProps"
    :is-alert="!!'1' && !isInnerModal"
    @close="onClose"
    @button-click="onButtonClick"
  />
</template>
