import { getAchievementsAvatarsAndBackgrounds } from '@leon-hub/api-sdk';

import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import type {
  GetAvatarsAndBackgroundsDataDocument,
} from 'web/src/modules/profile/submodules/achievements/types/AchievementsDocuments';

export default function getAvatarsAndBackgroundsRequest(): Promise<GetAvatarsAndBackgroundsDataDocument> {
  const apiClient = useGraphqlClient();

  return getAchievementsAvatarsAndBackgrounds(
    apiClient,
    (node) => node.queries.achievements.getAvatarsAndBackgrounds,
    undefined,
  );
}
