<script lang="ts" setup>
import { useCssModule } from 'vue';

import { IconName, IconSize } from '@leon-hub/icons';

import { VIcon } from '@components/v-icon';

import { useIsSportlineType } from 'web/src/modules/sportline/composables/core';
import createDisplayValueChangeDirective from 'web/src/utils/directives/createDisplayValueChangeDirective';

import type { RunnerProps } from './types';
import { runnerComponentPropsToRefs, useRunner } from './composables';
import { runnerDataTestElement } from './constants';

const props = defineProps<RunnerProps>();

const cssModule = useCssModule();

const vDirection = {
  // @TODO review add class logic
  updated: createDisplayValueChangeDirective({
    moveUp: cssModule['sportline-runner-value--bet-up'],
    moveDown: cssModule['sportline-runner-value--bet-down'],
    autoDirection: true,
  }),
};

const runnerProps = runnerComponentPropsToRefs(props);
const {
  value,
  price,
  label,
  isEmpty,
  isLocked,
  isSelected,
  addToSlip,
} = useRunner(runnerProps);
const { isLive, isPrematch } = useIsSportlineType(runnerProps.sportlineType);
</script>

<template>
  <button v-auto-id="'InListRunner'"
    v-data-test="{
      el: runnerDataTestElement.runner,
      locked: isLocked,
      selected: isSelected,
      live: isLive,
      prematch: isPrematch,
    }"
    v-direction="{ value: price }"
    type="button"
    :class="{
      [$style['sportline-runner']]: true,
      [$style['sportline-runner--locked']]: isLocked && !isSelected,
      [$style['sportline-runner--selected']]: isSelected,
    }"
    :disabled="isLocked && !isSelected"
    @click="addToSlip({ event: $event })"
  >
    <span
      v-if="label"
      v-data-test="{ el: runnerDataTestElement.handicap }"
      :class="{
        [$style['sportline-runner__handicap']]: true,
        [$style['sportline-runner__handicap--locked']]: isLocked && !isSelected,
      }"
    >{{ label }}</span>

    <span v-data-test="{ el: runnerDataTestElement.price }">{{ value }}</span>

    <VIcon
      v-if="isLocked && !isEmpty"
      v-data-test="{ el: runnerDataTestElement.lockIcon }"
      :name="IconName.LOCK"
      :size="IconSize.SIZE_12"
      :class="$style['sportline-runner__icon']"
    />
  </button>
</template>

<style module lang="scss">
@import '~web/src/modules/sportline/views/runner/styles';
</style>
