<script setup lang="ts">
import {
  toRef,
  computed,
} from 'vue';

import { IconName, IconSize } from '@leon-hub/icons';

import { VIcon } from '@components/v-icon';

import {
  useColumnsNames,
  useExpandableHeadline,
} from 'web/src/modules/sportline/composables/list';
import { useInjectSelectedColumn } from 'web/src/modules/sportline/composables/select-main-market';
import type {
  Sport,
  Region,
  ColumnInfo,
  LeagueElement,
} from 'web/src/modules/sportline/types';
import { defaultSportsMarketColumns, defaultMarketColumnsOptions } from 'web/src/modules/sportline/constants';
import type { SportElementHeadlineExpandState, SportlineFragmentBasisKey } from 'web/src/modules/sportline/types/list';
import FavoriteLeagueIndicator from 'web/src/modules/sportline/submodules/favorites/views/FavoriteLeagueIndicator.vue';
import { useLeagueHeadlineTitle } from 'web/src/modules/sportline/composables/list/useLeagueHeadlineTitle';
import { useSportlineCatalogClick } from 'web/src/modules/sportline/composables/catalog';
import { selectMarketTypeByColumn, placeRunnersToColumns } from 'web/src/modules/sportline/utils/markets';

interface LeagueHeadlineProps {
  sport?: Maybe<Sport>;
  region?: Maybe<Region>;
  leagueElement?: Maybe<LeagueElement>;
  isFilterActive?: boolean;
  isShowAddLeagueToFavorite?: boolean;
  isColumnNamesDisplayDisabled?: boolean;
  expandKey?: Maybe<string>;
  isExpanded?: boolean;
  countEvents?: number;
  basisKey: SportlineFragmentBasisKey;
}

interface LeagueHeadlineEmits {
  (e: 'toggle-expand-state', payload: SportElementHeadlineExpandState): void;
}

const props = defineProps<LeagueHeadlineProps>();
const emit = defineEmits<LeagueHeadlineEmits>();

const sport = toRef(props, 'sport', null);
const region = toRef(props, 'region', null);
const leagueElement = toRef(props, 'leagueElement', null);
const isColumnNamesDisplayDisabled = toRef(props, 'isColumnNamesDisplayDisabled', false);
const expandKey = toRef(props, 'expandKey', null);
const isExpanded = toRef(props, 'isExpanded', false);
const isFilterActive = toRef(props, 'isFilterActive', false);
const countEvents = toRef(props, 'countEvents', 0);
const basisKey = toRef(props, 'basisKey');

const leagueId = computed(() => leagueElement.value?.league.id);

const {
  selectedColumnId,
  selectedColumnSize,
  selectedColumnSign,
  columns,
} = useInjectSelectedColumn({ basisKey });

const actualMarketType = computed(() => selectMarketTypeByColumn({
  columns: columns.value,
  selectedColumnId: selectedColumnId.value,
  allMarketsTypes: leagueElement.value?.marketTypes ?? [],
}));
const runnersPlaces = computed(() => placeRunnersToColumns({
  market: null,
  marketType: actualMarketType.value,
  sportFamily: sport.value?.representation.family,
}, defaultMarketColumnsOptions));

const {
  isExpandable,
  isShowEventsCounters,
  toggleExpandState,
} = useExpandableHeadline({
  expandKey,
  isExpanded,
  countEvents,
}, emit);
const {
  leagueTitle,
  regionTitle,
} = useLeagueHeadlineTitle({
  doUseFilter: isFilterActive,
  region,
  league: computed(() => leagueElement.value?.league ?? null),
  filter: computed(() => leagueElement.value?.usedFilter ?? null),
});

const defaultSportColumnInfo = computed<Optional<ColumnInfo>>(() => (
  sport.value ? defaultSportsMarketColumns[sport.value.representation.family] : undefined
));
const defaultColumnSize = computed<number>(() => (
  selectedColumnSize.value
  ?? defaultSportColumnInfo.value?.size
  ?? 3
));

const {
  mainMarketColumnNames,
} = useColumnsNames({
  isColumnNamesDisplayDisabled,
  runnersPlaces,
  actualMarketType,
  defaultColumnSign: selectedColumnSign,
  defaultColumnSize,
});
const { onCatalogClick } = useSportlineCatalogClick({ leagueId });

function onHeadlineClick(event: MouseEvent): void {
  // click on favorites icon
  if (event.defaultPrevented) { return; }
  toggleExpandState();
}
</script>

<template>
  <div v-auto-id="'LeagueHeadline'"
    :class="{
      [$style['sport-event-list-league-headline']]: true,
      [$style['sport-event-list-league-headline--expandable']]: isExpandable,
      [$style['sport-event-list-league-headline--collapsed']]: isExpandable && !isExpanded,
    }"
    @click="onHeadlineClick"
  >
    <FavoriteLeagueIndicator
      v-if="isShowAddLeagueToFavorite && leagueElement && sport"
      :sport-id="sport.id"
      :league-id="leagueElement.league.id"
      :class="$style['sport-event-list-league-headline__favorite-indicator']"
    />

    <div
      :class="{
        [$style['sport-event-list-league-headline__label']]: true,
        [$style['sport-event-list-league-headline__label--can-favorite']]: isShowAddLeagueToFavorite
      }"
      @click="onCatalogClick"
    >
      <template v-if="regionTitle && leagueTitle">
        <span :class="$style['sport-event-list-league-headline__region']">{{ regionTitle }}</span>
        <span :class="$style['sport-event-list-league-headline__league-name']">{{ leagueTitle }}</span>
      </template>
      <template v-else>
        <span :class="$style['sport-event-list-league-headline__region']">{{ leagueTitle }}</span>
      </template>
    </div>

    <div
      v-if="isShowEventsCounters"
      :class="$style['sport-event-list-league-headline__events-counter']"
    >
      {{ countEvents }}

      <VIcon
        :class="$style['sport-event-list-league-headline__expand-icon']"
        :size="'1' ? IconSize.SIZE_20 : IconSize.SIZE_16"
        :name="isExpanded ? IconName.EXPAND_UP : IconName.EXPAND_DOWN"
      />
    </div>
    <template v-else>
      <div :class="$style['sport-event-list-league-headline__market-labels']">
        <div
          v-for="(runner, idx) in mainMarketColumnNames"
          :key="idx"
          :class="$style['sport-event-list-league-headline__market-label']"
        >
          <span v-if="runner">{{ runner }}</span>
        </div>
      </div>
      <div
        :class="[
          $style['sport-event-list-league-headline__market-label'],
          $style['sport-event-list-league-headline__market-label--placeholder']
        ]"
      >
        <VIcon
          v-if="'1' && isExpandable"
          :class="$style['sport-event-list-league-headline__expand-icon']"
          :size="'1' ? IconSize.SIZE_20 : IconSize.SIZE_16"
          :name="isExpanded ? IconName.EXPAND_UP : IconName.EXPAND_DOWN"
        />
        <span v-else>&nbsp;</span>
      </div>
    </template>
  </div>
</template>

<style lang="scss" module>
@import '~web/src/modules/sportline/styles/sport-event-list-columns';

.sport-event-list-league-headline {
  display: flex;
  align-items: center;
  height: 40px;
  background-color: var(--Layer1);
  border-radius: var(--BorderRadius) var(--BorderRadius) 0 0;

  &--collapsed {
    border-radius: var(--BorderRadius) var(--BorderRadius) var(--BorderRadius) var(--BorderRadius);
  }

  &--expandable {
    @include is-app-layout-desktop {
      cursor: pointer;
    }
  }

  &__favorite-indicator {
    min-width: 44px;
  }

  &__label {
    position: relative;
    display: flex;
    flex: 1;
    min-width: 164px;
    padding: 0 8px;
    overflow: hidden;
    white-space: nowrap;

    &--can-favorite {
      padding: 0 8px 0 0;
    }

    &:after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 20px;
      content: '';
      background: linear-gradient(270deg, var(--Layer1) 0%, var(--FullOpacityLayer1) 100%);
    }

    &-text {
      display: block;
      overflow: hidden;
      white-space: nowrap;
    }

    @include is-app-layout-desktop {
      padding: 0 16px;

      &--can-favorite {
        padding: 0 16px 0 0;
      }
    }
  }

  &__market-labels {
    display: flex;
    margin-right: 4px;

    @include is-app-layout-desktop {
      margin-right: 0;
    }
  }

  &__market-label {
    @include regular\12\16;

    flex-shrink: 0;
    width: get($runnerColumnWidth, mobile);
    margin-right: 4px;
    color: var(--TextSecondary);
    text-align: center;

    &--placeholder {
      display: none;
    }

    span {
      color: var(--TextPrimary);
      text-align: inherit;
    }

    @include is-app-layout-desktop {
      width: get($runnerColumnWidth, desktop);
      padding-right: 8px;
      margin-right: 0;
      margin-left: 8px;

      &--placeholder {
        display: flex;
        justify-content: flex-end;
        width: get($runnerColumnWidth, more);
        padding-right: 12px;
        margin-left: 12px;
        color: var(--TextPrimary);
      }
    }
  }

  &__events-counter {
    @include medium\14\16\025;

    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
    justify-content: flex-end;
    padding-right: 8px;
    margin-right: 0;
    margin-left: 8px;
    color: var(--TextPrimary);

    @include is-app-layout-desktop {
      gap: 12px;
      padding-right: 12px;
      margin-left: 12px;
    }
  }

  &__expand-icon {
    min-width: 16px;
    cursor: pointer;
  }

  &__region {
    @include regular\13\16;

    color: var(--TextPrimary);

    @include for-phone-middle-down {
      @include regular\12\16;
    }

    @include is-app-layout-desktop {
      @include medium\14\16\025;
    }
  }

  &__league-name {
    @include medium\13\16;

    margin-left: 4px;
    overflow: hidden;
    color: var(--TextDefault);

    @include for-phone-middle-down {
      @include medium\12\16;
    }

    @include is-app-layout-desktop {
      @include medium\14\16\025;
    }
  }
}
</style>
