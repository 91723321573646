enum CustomerRouteAccessRole {
  AUTHORIZED = 'authorized',
  ANONYMOUS = 'anonymous',
  FORBIDDEN = 'forbidden',
}

export function isCustomerRouteAccessRole(value: unknown): value is CustomerRouteAccessRole {
  return Object.values(CustomerRouteAccessRole).some((role) => role === value);
}

export default CustomerRouteAccessRole;
