import type {
  AbstractErrorOptions,
} from '@leon-hub/errors';
import { AbstractError } from '@leon-hub/errors';

import ChunkLoadErrorCode from './ChunkLoadErrorCode';

interface ChunkLoadErrorOptions extends Omit<AbstractErrorOptions, 'code'> {
  chunkPath: string;
}

const chunkLoadErrorCode = new ChunkLoadErrorCode();

export default class ChunkLoadError extends AbstractError {
  public readonly chunkPath: string;

  constructor(options: ChunkLoadErrorOptions) {
    super({
      ...options,
      code: chunkLoadErrorCode,
    });

    this.chunkPath = options.chunkPath;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  protected getSerializable() {
    return {
      ...super.getSerializable(),
      chunkPath: this.chunkPath,
    };
  }
}
