import type { IconNameType } from '@leon-hub/icons';
import { IconName, isIconNameKey } from '@leon-hub/icons';
import { assert } from '@leon-hub/guards';

import getCurrencyAbbrForIcon from './getCurrencyAbbrForIcon';

export default function getCurrencyIcon(currency?: string): IconNameType {
  const fallbackIcon = IconName.COIN_USD;
  if (!currency) {
    return fallbackIcon;
  }
  const nameByCurrency = `COIN_${getCurrencyAbbrForIcon(currency)}`;
  if (nameByCurrency in IconName) {
    assert(isIconNameKey(nameByCurrency));
    return IconName[nameByCurrency];
  }
  return fallbackIcon;
}
