import { safeCall, Deferred, promiseTimeout } from "@leon-hub/utils";
import { assert } from "@leon-hub/guards";
const DEFAULT_INTERVAL = 200;
const DEFAULT_TIMEOUT = Infinity;
function mergeAbortSignals(...signals) {
  assert(signals.length > 0, "At least one signal is required");
  const controller = new AbortController();
  function onAbort() {
    controller.abort();
  }
  for (const signal of signals) {
    signal.addEventListener("abort", onAbort);
  }
  return controller.signal;
}
function normalizeSignal(signal) {
  return signal ?? new AbortController().signal;
}
function setupOptions({
  interval = DEFAULT_INTERVAL,
  timeout = DEFAULT_TIMEOUT,
  eager = false,
  idlers = [],
  signal
} = {}) {
  assert(timeout >= interval, "timeout must be greater than interval");
  const isTimeoutDefined = timeout > 0 && Number.isFinite(timeout);
  return {
    interval,
    timeout,
    eager,
    idlers,
    signal: isTimeoutDefined ? mergeAbortSignals(
      normalizeSignal(signal),
      createAbortSignalTimeout(timeout)
    ) : normalizeSignal(signal)
  };
}
function createAbortSignalTimeout(timeout) {
  const controller = new AbortController();
  setTimeout(() => {
    controller.abort();
  }, timeout);
  return controller.signal;
}
const supportsPerformanceAPI = () => typeof window !== "undefined" && "performance" in window && "getEntriesByType" in window.performance;
function isTrackableResourceType(type) {
  return type === "link" || type === "script" || type === "img";
}
function isTrackableResource(resource) {
  return "initiatorType" in resource && typeof resource.initiatorType === "string" && isTrackableResourceType(resource.initiatorType);
}
function isAnyResourceLoading() {
  if (!supportsPerformanceAPI()) return false;
  const resources = window.performance.getEntriesByType("resource");
  if (!resources.length) return true;
  return resources.some((resource) => resource.duration === 0 && isTrackableResource(resource));
}
function watchFetch({
  onBefore,
  onAfter
} = {
  onBefore: () => void 0,
  onAfter: () => void 0
}) {
  if (process.env.VUE_APP_RENDERING_SSR) return () => {
  };
  let isWatched = true;
  const prevFetch = window.fetch;
  window.fetch = async function wrappedFetch(...rest) {
    if (isWatched) safeCall(onBefore);
    try {
      return await Reflect.apply(prevFetch, this, rest);
    } finally {
      if (isWatched) safeCall(onAfter);
    }
  };
  return () => {
    isWatched = false;
    window.fetch = prevFetch;
  };
}
const whenClientNetworkIdle = async (options = {}) => {
  const {
    timeout,
    interval,
    eager
  } = setupOptions(options);
  assert(timeout >= interval, "timeout must be greater than interval");
  const startTime = Date.now();
  const timeoutTime = startTime + timeout;
  const d = new Deferred();
  let timeoutID;
  let lastConnectionChange = 0;
  function isIdleEnough(now) {
    return lastConnectionChange > 0 && now - lastConnectionChange > interval;
  }
  function update() {
    lastConnectionChange = Date.now();
  }
  const unwatchFetch = watchFetch({
    onBefore: update,
    onAfter: update
  });
  function tearDown(result) {
    unwatchFetch();
    clearTimeout(timeoutID);
    d.resolve(result);
  }
  function getRemainingTime(now) {
    return Math.max(0, now - startTime);
  }
  function runIdleCheck() {
    const now = Date.now();
    try {
      if (timeoutTime > now) {
        tearDown(false);
      } else if (isIdleEnough(now) && !isAnyResourceLoading()) {
        tearDown(true);
      } else if (eager) {
        tearDown(false);
      } else {
        timeoutID = setTimeout(runIdleCheck, interval);
      }
    } catch (err) {
      console.error(err);
      tearDown(false);
    }
  }
  void Promise.resolve().then(runIdleCheck);
  return d.promise.catch((reason) => {
    console.error(reason);
    return false;
  }).then((result) => result ? 0 : getRemainingTime(Date.now()));
};
const getTrackableEvents = () => /* @__PURE__ */ new Set([
  "resize",
  "orientationchange",
  "keydown",
  "keyup",
  "scroll",
  "pointermove",
  "pointerdown",
  "pointerup",
  "visibilitychange"
]);
function removeActivityListeners(trackableEvents, listener, listenerOptions) {
  for (const event of trackableEvents) {
    window.removeEventListener(event, listener, listenerOptions);
  }
}
function addActivityListeners(trackableEvents, listener, listenerOptions) {
  for (const event of trackableEvents) {
    window.addEventListener(event, listener, listenerOptions);
  }
}
const whenClientActivityIdle = async (options = {}) => {
  const {
    interval,
    eager,
    signal
  } = setupOptions(options);
  const d = new Deferred();
  const startTime = Date.now();
  const trackableEvents = getTrackableEvents();
  const listenerOptions = { capture: true, passive: true, signal };
  let timeoutID;
  let lastActivityTime = startTime;
  function tearDown(result) {
    removeActivityListeners(trackableEvents, updateActivity, listenerOptions);
    clearInterval(timeoutID);
    d.resolve(result);
  }
  function isStableEnough(now) {
    return lastActivityTime > 0 && now - lastActivityTime > interval;
  }
  function updateActivity() {
    const now = Date.now();
    const timeSpent = startTime - now;
    if (eager) {
      tearDown(timeSpent >= interval);
    } else {
      lastActivityTime = now;
    }
  }
  function checkStable({ skipEager } = {}) {
    const now = Date.now();
    if (isStableEnough(now)) {
      tearDown(true);
    } else if (!skipEager && eager) {
      tearDown(false);
    } else {
      timeoutID = setTimeout(checkStable, interval);
    }
  }
  addActivityListeners(trackableEvents, updateActivity, listenerOptions);
  signal.addEventListener("abort", () => tearDown(false));
  queueMicrotask(() => checkStable({ skipEager: true }));
  return d.promise.catch((reason) => {
    console.error(reason);
    return false;
  }).then((result) => result ? 0 : Date.now() - lastActivityTime);
};
function getDefaultExportFromCjs(x) {
  return x && x.__esModule && Object.prototype.hasOwnProperty.call(x, "default") ? x["default"] : x;
}
var browser = { exports: {} };
var ms;
var hasRequiredMs;
function requireMs() {
  if (hasRequiredMs) return ms;
  hasRequiredMs = 1;
  var s = 1e3;
  var m = s * 60;
  var h = m * 60;
  var d = h * 24;
  var w = d * 7;
  var y = d * 365.25;
  ms = function(val, options) {
    options = options || {};
    var type = typeof val;
    if (type === "string" && val.length > 0) {
      return parse(val);
    } else if (type === "number" && isFinite(val)) {
      return options.long ? fmtLong(val) : fmtShort(val);
    }
    throw new Error(
      "val is not a non-empty string or a valid number. val=" + JSON.stringify(val)
    );
  };
  function parse(str) {
    str = String(str);
    if (str.length > 100) {
      return;
    }
    var match = /^(-?(?:\d+)?\.?\d+) *(milliseconds?|msecs?|ms|seconds?|secs?|s|minutes?|mins?|m|hours?|hrs?|h|days?|d|weeks?|w|years?|yrs?|y)?$/i.exec(
      str
    );
    if (!match) {
      return;
    }
    var n = parseFloat(match[1]);
    var type = (match[2] || "ms").toLowerCase();
    switch (type) {
      case "years":
      case "year":
      case "yrs":
      case "yr":
      case "y":
        return n * y;
      case "weeks":
      case "week":
      case "w":
        return n * w;
      case "days":
      case "day":
      case "d":
        return n * d;
      case "hours":
      case "hour":
      case "hrs":
      case "hr":
      case "h":
        return n * h;
      case "minutes":
      case "minute":
      case "mins":
      case "min":
      case "m":
        return n * m;
      case "seconds":
      case "second":
      case "secs":
      case "sec":
      case "s":
        return n * s;
      case "milliseconds":
      case "millisecond":
      case "msecs":
      case "msec":
      case "ms":
        return n;
      default:
        return void 0;
    }
  }
  function fmtShort(ms2) {
    var msAbs = Math.abs(ms2);
    if (msAbs >= d) {
      return Math.round(ms2 / d) + "d";
    }
    if (msAbs >= h) {
      return Math.round(ms2 / h) + "h";
    }
    if (msAbs >= m) {
      return Math.round(ms2 / m) + "m";
    }
    if (msAbs >= s) {
      return Math.round(ms2 / s) + "s";
    }
    return ms2 + "ms";
  }
  function fmtLong(ms2) {
    var msAbs = Math.abs(ms2);
    if (msAbs >= d) {
      return plural(ms2, msAbs, d, "day");
    }
    if (msAbs >= h) {
      return plural(ms2, msAbs, h, "hour");
    }
    if (msAbs >= m) {
      return plural(ms2, msAbs, m, "minute");
    }
    if (msAbs >= s) {
      return plural(ms2, msAbs, s, "second");
    }
    return ms2 + " ms";
  }
  function plural(ms2, msAbs, n, name) {
    var isPlural = msAbs >= n * 1.5;
    return Math.round(ms2 / n) + " " + name + (isPlural ? "s" : "");
  }
  return ms;
}
function setup(env) {
  createDebug.debug = createDebug;
  createDebug.default = createDebug;
  createDebug.coerce = coerce;
  createDebug.disable = disable;
  createDebug.enable = enable;
  createDebug.enabled = enabled;
  createDebug.humanize = requireMs();
  createDebug.destroy = destroy;
  Object.keys(env).forEach((key) => {
    createDebug[key] = env[key];
  });
  createDebug.names = [];
  createDebug.skips = [];
  createDebug.formatters = {};
  function selectColor(namespace) {
    let hash = 0;
    for (let i = 0; i < namespace.length; i++) {
      hash = (hash << 5) - hash + namespace.charCodeAt(i);
      hash |= 0;
    }
    return createDebug.colors[Math.abs(hash) % createDebug.colors.length];
  }
  createDebug.selectColor = selectColor;
  function createDebug(namespace) {
    let prevTime;
    let enableOverride = null;
    let namespacesCache;
    let enabledCache;
    function debug2(...args) {
      if (!debug2.enabled) {
        return;
      }
      const self = debug2;
      const curr = Number(/* @__PURE__ */ new Date());
      const ms2 = curr - (prevTime || curr);
      self.diff = ms2;
      self.prev = prevTime;
      self.curr = curr;
      prevTime = curr;
      args[0] = createDebug.coerce(args[0]);
      if (typeof args[0] !== "string") {
        args.unshift("%O");
      }
      let index = 0;
      args[0] = args[0].replace(/%([a-zA-Z%])/g, (match, format) => {
        if (match === "%%") {
          return "%";
        }
        index++;
        const formatter = createDebug.formatters[format];
        if (typeof formatter === "function") {
          const val = args[index];
          match = formatter.call(self, val);
          args.splice(index, 1);
          index--;
        }
        return match;
      });
      createDebug.formatArgs.call(self, args);
      const logFn = self.log || createDebug.log;
      logFn.apply(self, args);
    }
    debug2.namespace = namespace;
    debug2.useColors = createDebug.useColors();
    debug2.color = createDebug.selectColor(namespace);
    debug2.extend = extend;
    debug2.destroy = createDebug.destroy;
    Object.defineProperty(debug2, "enabled", {
      enumerable: true,
      configurable: false,
      get: () => {
        if (enableOverride !== null) {
          return enableOverride;
        }
        if (namespacesCache !== createDebug.namespaces) {
          namespacesCache = createDebug.namespaces;
          enabledCache = createDebug.enabled(namespace);
        }
        return enabledCache;
      },
      set: (v) => {
        enableOverride = v;
      }
    });
    if (typeof createDebug.init === "function") {
      createDebug.init(debug2);
    }
    return debug2;
  }
  function extend(namespace, delimiter) {
    const newDebug = createDebug(this.namespace + (typeof delimiter === "undefined" ? ":" : delimiter) + namespace);
    newDebug.log = this.log;
    return newDebug;
  }
  function enable(namespaces) {
    createDebug.save(namespaces);
    createDebug.namespaces = namespaces;
    createDebug.names = [];
    createDebug.skips = [];
    let i;
    const split = (typeof namespaces === "string" ? namespaces : "").split(/[\s,]+/);
    const len = split.length;
    for (i = 0; i < len; i++) {
      if (!split[i]) {
        continue;
      }
      namespaces = split[i].replace(/\*/g, ".*?");
      if (namespaces[0] === "-") {
        createDebug.skips.push(new RegExp("^" + namespaces.slice(1) + "$"));
      } else {
        createDebug.names.push(new RegExp("^" + namespaces + "$"));
      }
    }
  }
  function disable() {
    const namespaces = [
      ...createDebug.names.map(toNamespace),
      ...createDebug.skips.map(toNamespace).map((namespace) => "-" + namespace)
    ].join(",");
    createDebug.enable("");
    return namespaces;
  }
  function enabled(name) {
    if (name[name.length - 1] === "*") {
      return true;
    }
    let i;
    let len;
    for (i = 0, len = createDebug.skips.length; i < len; i++) {
      if (createDebug.skips[i].test(name)) {
        return false;
      }
    }
    for (i = 0, len = createDebug.names.length; i < len; i++) {
      if (createDebug.names[i].test(name)) {
        return true;
      }
    }
    return false;
  }
  function toNamespace(regexp) {
    return regexp.toString().substring(2, regexp.toString().length - 2).replace(/\.\*\?$/, "*");
  }
  function coerce(val) {
    if (val instanceof Error) {
      return val.stack || val.message;
    }
    return val;
  }
  function destroy() {
    console.warn("Instance method `debug.destroy()` is deprecated and no longer does anything. It will be removed in the next major version of `debug`.");
  }
  createDebug.enable(createDebug.load());
  return createDebug;
}
var common = setup;
/* @__PURE__ */ (function(module, exports) {
  exports.formatArgs = formatArgs;
  exports.save = save;
  exports.load = load;
  exports.useColors = useColors;
  exports.storage = localstorage();
  exports.destroy = /* @__PURE__ */ (() => {
    let warned = false;
    return () => {
      if (!warned) {
        warned = true;
        console.warn("Instance method `debug.destroy()` is deprecated and no longer does anything. It will be removed in the next major version of `debug`.");
      }
    };
  })();
  exports.colors = [
    "#0000CC",
    "#0000FF",
    "#0033CC",
    "#0033FF",
    "#0066CC",
    "#0066FF",
    "#0099CC",
    "#0099FF",
    "#00CC00",
    "#00CC33",
    "#00CC66",
    "#00CC99",
    "#00CCCC",
    "#00CCFF",
    "#3300CC",
    "#3300FF",
    "#3333CC",
    "#3333FF",
    "#3366CC",
    "#3366FF",
    "#3399CC",
    "#3399FF",
    "#33CC00",
    "#33CC33",
    "#33CC66",
    "#33CC99",
    "#33CCCC",
    "#33CCFF",
    "#6600CC",
    "#6600FF",
    "#6633CC",
    "#6633FF",
    "#66CC00",
    "#66CC33",
    "#9900CC",
    "#9900FF",
    "#9933CC",
    "#9933FF",
    "#99CC00",
    "#99CC33",
    "#CC0000",
    "#CC0033",
    "#CC0066",
    "#CC0099",
    "#CC00CC",
    "#CC00FF",
    "#CC3300",
    "#CC3333",
    "#CC3366",
    "#CC3399",
    "#CC33CC",
    "#CC33FF",
    "#CC6600",
    "#CC6633",
    "#CC9900",
    "#CC9933",
    "#CCCC00",
    "#CCCC33",
    "#FF0000",
    "#FF0033",
    "#FF0066",
    "#FF0099",
    "#FF00CC",
    "#FF00FF",
    "#FF3300",
    "#FF3333",
    "#FF3366",
    "#FF3399",
    "#FF33CC",
    "#FF33FF",
    "#FF6600",
    "#FF6633",
    "#FF9900",
    "#FF9933",
    "#FFCC00",
    "#FFCC33"
  ];
  function useColors() {
    if (typeof window !== "undefined" && window.process && (window.process.type === "renderer" || window.process.__nwjs)) {
      return true;
    }
    if (typeof navigator !== "undefined" && navigator.userAgent && navigator.userAgent.toLowerCase().match(/(edge|trident)\/(\d+)/)) {
      return false;
    }
    let m;
    return typeof document !== "undefined" && document.documentElement && document.documentElement.style && document.documentElement.style.WebkitAppearance || // Is firebug? http://stackoverflow.com/a/398120/376773
    typeof window !== "undefined" && window.console && (window.console.firebug || window.console.exception && window.console.table) || // Is firefox >= v31?
    // https://developer.mozilla.org/en-US/docs/Tools/Web_Console#Styling_messages
    typeof navigator !== "undefined" && navigator.userAgent && (m = navigator.userAgent.toLowerCase().match(/firefox\/(\d+)/)) && parseInt(m[1], 10) >= 31 || // Double check webkit in userAgent just in case we are in a worker
    typeof navigator !== "undefined" && navigator.userAgent && navigator.userAgent.toLowerCase().match(/applewebkit\/(\d+)/);
  }
  function formatArgs(args) {
    args[0] = (this.useColors ? "%c" : "") + this.namespace + (this.useColors ? " %c" : " ") + args[0] + (this.useColors ? "%c " : " ") + "+" + module.exports.humanize(this.diff);
    if (!this.useColors) {
      return;
    }
    const c = "color: " + this.color;
    args.splice(1, 0, c, "color: inherit");
    let index = 0;
    let lastC = 0;
    args[0].replace(/%[a-zA-Z%]/g, (match) => {
      if (match === "%%") {
        return;
      }
      index++;
      if (match === "%c") {
        lastC = index;
      }
    });
    args.splice(lastC, 0, c);
  }
  exports.log = console.debug || console.log || (() => {
  });
  function save(namespaces) {
    try {
      if (namespaces) {
        exports.storage.setItem("debug", namespaces);
      } else {
        exports.storage.removeItem("debug");
      }
    } catch (error) {
    }
  }
  function load() {
    let r;
    try {
      r = exports.storage.getItem("debug");
    } catch (error) {
    }
    if (!r && typeof process !== "undefined" && "env" in process) {
      r = process.env.DEBUG;
    }
    return r;
  }
  function localstorage() {
    try {
      return localStorage;
    } catch (error) {
    }
  }
  module.exports = common(exports);
  const { formatters } = module.exports;
  formatters.j = function(v) {
    try {
      return JSON.stringify(v);
    } catch (error) {
      return "[UnexpectedJSONParseError]: " + error.message;
    }
  };
})(browser, /* @__PURE__ */ (() => browser.exports)());
var browserExports = /* @__PURE__ */ (() => browser.exports)();
const debug = /* @__PURE__ */ getDefaultExportFromCjs(browserExports);
const log = /* @__PURE__ */ debug("@leon-hub/idle");
const whenMultipleIdle = async (options) => {
  const {
    idlers,
    timeout,
    interval
  } = setupOptions(options);
  assert(idlers.length > 1, "At least two idlers are required");
  const hasTimeout = timeout > 0 && Number.isFinite(timeout);
  const startTime = Date.now();
  const deferred = new Deferred();
  const state = idlers.map(() => false);
  const isListOfIdlersResolved = () => state.every(Boolean);
  const isTimeout = (now) => hasTimeout && now - startTime > timeout;
  const runIdlerWithEagerMode = async (idler) => {
    const index = idlers.indexOf(idler);
    const remains = await idler({ interval, timeout, eager: true });
    const isIdle = remains === 0;
    state[index] = isIdle;
    if (isIdle && isListOfIdlersResolved()) {
      deferred.resolve();
    }
    return remains;
  };
  const waitForIdlers = async () => {
    const remainsList = await Promise.all(idlers.map(async (idler) => {
      let remains = interval;
      while (!deferred.finished) {
        if (isTimeout(Date.now())) {
          break;
        }
        remains = await runIdlerWithEagerMode(idler);
      }
      return remains;
    }));
    return Math.max(...remainsList);
  };
  log(`Started waiting for ${idlers.length} idlers`);
  return promiseTimeout({
    timeout,
    promise: waitForIdlers(),
    onTimeout: deferred.resolve
  }).catch((reason) => {
    console.warn(reason);
    return interval;
  });
};
export {
  whenClientActivityIdle,
  whenClientNetworkIdle,
  whenMultipleIdle
};
