import type { ComponentPublicInstance } from 'vue';

import type { Without } from '@leon-hub/types';
import type { AbstractErrorOptions } from '@leon-hub/errors';
import { AbstractError } from '@leon-hub/errors';

import { AppErrorCode } from './AppErrorCode';

interface AppErrorOptions extends Without<AbstractErrorOptions, 'code'> {
  code?: AppErrorCode;
}

export class ComponentWarning extends AbstractError {
  constructor(options: AppErrorOptions & { prefix?: string; trace: string; vm: null | ComponentPublicInstance }) {
    super({
      ...options,
      code: new AppErrorCode('COMPONENT_WARNING'),
    });
    const vm = options.vm ?? null;
    if (vm) {
      this.addLogMetaData('props', vm.$props);
      this.addLogMetaData('name', vm.$options.name);
    }
    this.addLogMetaData('trace', options.trace);
  }
}
