import type { Ref } from 'vue';
import { ref } from 'vue';

interface UseMainMarketSettingsComposable {
  doUseFullAlternativeMarketsList: Ref<boolean>;
}

export function useMainMarketSettings(): UseMainMarketSettingsComposable {
  return {
    // @see LEONWEB-14075 | at the moment that setting is not presented in the site config
    doUseFullAlternativeMarketsList: ref(true),
  };
}
