import type { Ref } from 'vue';
import { toRef } from 'vue';

import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';

interface UseRunnersSettingsComposable {
  isUsedMoreRunnersCount: Ref<boolean>;
}

export function useRunnersSettings(): UseRunnersSettingsComposable {
  const settingsStore = useSportlineSettingsStore();
  const isUsedMoreRunnersCount = toRef(settingsStore, 'isUsedMoreRunnersCount');

  return {
    isUsedMoreRunnersCount,
  };
}
