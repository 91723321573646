import { assert } from '@leon-hub/guards';

import type { FreeBetOption } from 'web/src/modules/slip/submodules/freebet/types';

import type { VSelectOption } from '../types';

export default function getFreeBetAmount(option: VSelectOption | FreeBetOption, isFreebet?: boolean): string {
  if (!isFreebet) {
    return '';
  }
  assert('freeBetAmount' in option);
  return option.freeBetAmount;
}
