/* eslint-disable no-param-reassign */
import type { Plugin } from 'vue';

import { useDialogsStore } from '../store';
import type { ShowDialogFunc } from '../types';

type CloseDialog = (modalId: string) => void;
type CloseAllDialogs = () => void;

declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    $showModal: ShowDialogFunc;
    $closeModal: CloseDialog;
    $destroyModals: CloseAllDialogs;
  }
}

const ModalsPlugin: Plugin = {
  install(app) {
    const dialogsStore = useDialogsStore();

    app.config.globalProperties.$showModal = dialogsStore.showDialog;
    app.config.globalProperties.$closeModal = dialogsStore.closeDialog;
    app.config.globalProperties.$destroyModals = dialogsStore.closeAllDialogs;
  },
};

export default ModalsPlugin;
