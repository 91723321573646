export enum VirtualSportFramedWidgetProperties {
  IsDesktop = 'd',
  Url = 'url',
  Sport = 'sport',
  ClientId = 'clientId',
  Lang = 'lang',
  Style = 'style',
  Theme = 'theme',
}

export enum FramedVirtualSportWidgetSport {
  FootballLeague = 'vflm',
  Basketball = 'vbl',
  FootballEurocup = 'vfc',
  FootballChampionsCup = 'vfcc',
  FootballNationsCup = 'vfnc',
  TennisInplay = 'vti',
  FootballWorldCup = 'vfwc',
  FootballBundesliga = 'vfb',
  Cricket = 'vci',
}

export enum VirtualSportWidgetInstanceStatus {
  Initializing = 'INITIALIZING',
  Initialized = 'INITIALIZED',
  Destroyed = 'DESTROYED',
}

export enum VirtualSportWidgetBetsChangedContext {
  Restored = 'selectionsRestored',
  Clicked = 'selectionsClicked',
  ConfirmedViaAPI = 'selectionsConfirmedViaAPI',
  DismissedViaAPI = 'selectionsDismissedViaAPI',
}
