import type { Ref, MaybeRef } from 'vue';
import { computed, toValue } from 'vue';

import type { ExpandedSportlineBlockMap } from 'web/src/modules/sportline/composables/expandable-block/types';
import type { VirtualListElement } from 'web/src/modules/sportline/views/virtual-list-renderer/types';
import { VirtualListBlockLayout } from 'web/src/modules/sportline/views/virtual-list-renderer/enums';
import {
  getGroupsForSportElement,
  getNoEventsElement,
} from 'web/src/modules/sportline/views/virtual-list-renderer/utils';

import type { DisplayedVirtualListGroup } from '../types';

interface CreateVirtualListRefOptions {
  canCollapseSport?: MaybeRef<boolean>;
  isDefaultCollapsedSport?: MaybeRef<boolean>;
  expandedSportStateMap?: MaybeRef<ExpandedSportlineBlockMap>;
}

export function reduceLiveElements(
  displayedGroups: MaybeRef<DisplayedVirtualListGroup[]>,
  options: CreateVirtualListRefOptions,
  result: VirtualListElement[],
): VirtualListElement[] {
  let prematchGroupsCount = 0;

  const groups = toValue(displayedGroups);
  const canCollapseSport = toValue(options.canCollapseSport ?? false);
  const isDefaultCollapsedSport = toValue(options.isDefaultCollapsedSport ?? false);
  const expandedSportStateMap = toValue(options.expandedSportStateMap ?? {});

  for (const [index, { group, columnInfo }] of groups.entries()) {
    const { liveElement, prematchElement, filterElement } = group;

    if (prematchElement) { prematchGroupsCount += 1; }

    if (!liveElement) {
      result.push(...getNoEventsElement(filterElement ?? null, {
        blockLayout: VirtualListBlockLayout.Live,
        basisKey: columnInfo.key,
      }));

      continue;
    }

    result.push(...getGroupsForSportElement(
      group.liveElement ?? null,
      {
        isLastSport: (groups.length === index + 1) && !prematchGroupsCount,
        blockLayout: VirtualListBlockLayout.Live,
        basisKey: columnInfo.key,
        collapseSportKey: canCollapseSport ? group.expandKey : null,
        isDefaultCollapsedSport,
        expandedSportStateMap,
      },
    ));
  }

  return result;
}

export function reducePrematchElements(
  displayedGroups: MaybeRef<DisplayedVirtualListGroup[]>,
  options: CreateVirtualListRefOptions,
  result: VirtualListElement[],
): VirtualListElement[] {
  const groups = toValue(displayedGroups);
  const canCollapseSport = toValue(options.canCollapseSport ?? false);
  const isDefaultCollapsedSport = toValue(options.isDefaultCollapsedSport ?? false);
  const expandedSportStateMap = toValue(options.expandedSportStateMap ?? {});

  for (const [index, { group, columnInfo }] of groups.entries()) {
    const { prematchElement } = group;

    if (!prematchElement) { continue; }

    result.push(...getGroupsForSportElement(
      group.prematchElement ?? null,
      {
        isLastSport: (groups.length === index + 1),
        blockLayout: VirtualListBlockLayout.Coming,
        basisKey: columnInfo.key,
        collapseSportKey: canCollapseSport ? group.expandKey : null,
        isDefaultCollapsedSport,
        expandedSportStateMap,
      },
    ));
  }

  return result;
}

export function createVirtualListRef(
  displayedGroups: Ref<DisplayedVirtualListGroup[]>,
  options: CreateVirtualListRefOptions,
): Ref<VirtualListElement[]> {
  return computed<VirtualListElement[]>(() => reducePrematchElements(
    displayedGroups,
    options,
    reduceLiveElements(
      displayedGroups,
      options,
      [],
    ),
  ));
}
