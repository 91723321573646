import { isBoolean, isFunction } from '@leon-hub/guards';

export function matchTagHref(substring: string): { hasHref: boolean; isAnchor: boolean } {
  const href = substring.match(/href="(.+)"/gm);
  if (!href || !href[0]) { return { hasHref: false, isAnchor: false }; }
  if (href[0].match(/^href="#/gm)) { return { hasHref: true, isAnchor: true }; }
  return { hasHref: true, isAnchor: false };
}

function getBooleanPropValue(key: string, value: boolean): string {
  return value ? key : '';
}

function getStringPropValue(key: string, value: string): string {
  return `${key}="${value}"`;
}

export type ReplaceContentTagsProps = Record<string, Optional<string | boolean>>;

export type ReplaceContentTagsPropsFactory = (substring: string) => ReplaceContentTagsProps;

function tagPropsStringify(tagProps: ReplaceContentTagsProps): string {
  return Object.keys(tagProps)
    .map((key) => {
      const value = tagProps[key];
      if (value === undefined) { return ''; }
      return isBoolean(value)
        ? getBooleanPropValue(key, value)
        : getStringPropValue(key, value);
    })
    .filter((prop) => !!prop)
    .join(' ');
}

function fixFirstSpace(value: string): string {
  return value ? ` ${value}` : '';
}

export function replaceContentTags(
  content: string,
  oldTag: string,
  newTag: string,
  tagProps: ReplaceContentTagsProps | ReplaceContentTagsPropsFactory,
): string {
  let getProps: (substring: string) => string;

  if (!isFunction(tagProps)) {
    const constProps = fixFirstSpace(tagPropsStringify(tagProps));
    getProps = () => constProps;
  } else {
    getProps = (substring: string) => fixFirstSpace(tagPropsStringify(tagProps(substring)));
  }

  return (content || '')
    .replace(
      new RegExp(`<(${oldTag})((\\s+((?!>).)*)|\\s*)>`, 'gm'),
      (substring, ...args) => (`<${newTag}${args[1]}${getProps(substring)}>`),
    )
    .replace(
      new RegExp(`<\\/((((?!>).)*)\\s+|\\s*)(${oldTag})>`, 'gm'),
      (substring, ...args) => (`</${args[0]}${newTag}>`),
    );
}
