import type { ComputedRef, Ref } from 'vue';
import { computed } from 'vue';

import type { AccountBetHistoryItem } from '@leon-hub/api-sdk';
import { assert, isNumber } from '@leon-hub/guards';

import { numberToDouble } from 'web/src/modules/profile/submodules/customer-history/utils';

interface UseBetPriceComposable {
  betPrice: ComputedRef<string | number>;
}

export default function useBetPrice(historyItem: Ref<AccountBetHistoryItem>): UseBetPriceComposable {
  const betPrice = computed<string | number>(() => {
    assert(isNumber(historyItem.value.betClientPrice));
    return historyItem.value.betClientPriceStr ?? numberToDouble(historyItem.value.betClientPrice);
  });

  return {
    betPrice,
  };
}
