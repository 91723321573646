import type { Ref, MaybeRef } from 'vue';
import { toRef, computed } from 'vue';

import useSportlineSettingsStore from 'web/src/modules/sportline/store/useSportlineSettingsStore';
import type { SportSegmentSettings } from 'web/src/modules/sportline/types/settings';

export function useSportSegmentsSettings(sportSegmentsSettingsId: MaybeRef<string>): Ref<SportSegmentSettings> {
  const id = toRef(sportSegmentsSettingsId);
  const settings = toRef(useSportlineSettingsStore(), 'sportSegmentsSettings');
  return computed<SportSegmentSettings>(() => settings.value[id.value] || settings.value.default);
}
