import { isObject } from '@leon-hub/guards';

import type { LexisNexisApiProfileOptions } from '../types';

type AddOptional<T> = {
  [P in keyof T]: T[P] | undefined;
};

type RemoveOptional<T> = {
  [P in keyof T]: Exclude<T[P], undefined>;
};

export function isConfiguredOptions<T extends AddOptional<LexisNexisApiProfileOptions>>(value: T): value is RemoveOptional<T> {
  return isObject(value)
    && Object.values(value).every(Boolean);
}
