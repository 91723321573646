import { reactive, toRef, computed } from 'vue';

import { Theme } from '@leon-hub/api-sdk';

import type { ThemeGetter } from 'web/src/modules/theme/types';

interface SimpleTheme {
  themeGetter: ThemeGetter;
  setTheme(value: Theme): void;
}

export function createSimpleThemeGetter(defaultThemeValue = Theme.DARK): SimpleTheme {
  const theme = reactive({
    theme: defaultThemeValue,
    isAuto: false,
  });

  return {
    themeGetter: {
      theme: toRef(() => theme.theme),
      isDark: computed(() => theme.theme === Theme.DARK),
      isLight: computed(() => theme.theme === Theme.LIGHT),
      isAuto: toRef(() => theme.isAuto),
    },
    setTheme(value: Theme) {
      theme.theme = value;
    },
  };
}
