import { ref } from 'vue';
import { defineStore } from 'pinia';
import type { RouteLocationRaw } from 'vue-router';

import type { NavigationBackLocationGetter } from 'web/src/modules/core/types';

/**
 * @hint It shouldn't use any module directly. It just stores getters for navigation
 * Unfortunately we can't directly use router here
 * because we have to init store before router is created
 */
export const useNavigationBackButtonStore = defineStore('navigation-back-button-store', () => {
  let navigationBackLocationGetter: Maybe<NavigationBackLocationGetter> = null;

  const isBackButtonAvailable = ref(false);

  function setNavigationBackLocationGetter(value: Maybe<NavigationBackLocationGetter>): void {
    navigationBackLocationGetter = value;
    isBackButtonAvailable.value = !!value;
  }

  function getBackLocation(): Optional<RouteLocationRaw> {
    return navigationBackLocationGetter?.();
  }

  return {
    isBackButtonAvailable,
    getBackLocation,
    setNavigationBackLocationGetter,
  };
});
