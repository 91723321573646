import type {
  ProfileBonusesFreespinControllerProps,
} from 'web/src/modules/profile/submodules/bonuses/controllers/ProfileBonusesFreespinController/types';
import type { VButtonPropsWithId } from 'web/src/modules/profile/submodules/bonuses/types';
import { buttonDisabledLoadingState } from 'web/src/modules/profile/submodules/bonuses/utils';
import type { I18nFunctionReactive } from 'web/src/modules/i18n/types';

import getPlayButton from './getPlayButton';
import getCollectButton from './getCollectButton';
import getRefuseButton from './getRefuseButton';

export default function getBonusButtons(
  props: ProfileBonusesFreespinControllerProps,
  $t: I18nFunctionReactive,
  isGameDetailsLoading: boolean,
): VButtonPropsWithId[] {
  let buttons: VButtonPropsWithId[] = [];

  if (props.bonus?.freespin?.nominal) {
    buttons = [getPlayButton(isGameDetailsLoading, $t)];
  }
  if (props.bonus?.transferAmount) {
    buttons = [...buttons, getCollectButton($t)];
  }
  if (!props.bonus?.transferAmount && !!props.bonus?.refuseAvailable) {
    buttons = [...buttons, getRefuseButton($t)];
  }

  if (props.activeButton) {
    buttons = buttons.map((button) => ({
      ...button,
      ...buttonDisabledLoadingState(props.activeButton, { id: props.bonus.bonusId, type: button.id || '' }),
    }));
  }

  return buttons;
}
