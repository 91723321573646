<script lang="ts" setup>
import { SingleSelect } from 'web/src/components/Select';
import type { SingleSelectProperties } from 'web/src/components/Select';

import { useBaseFormWidgetEvents, useFormSelect } from '../../composables';
import type {
  FormWrapperWidgetProperties,
  BaseFormWidgetEmits,
} from '../../types';

const props = defineProps<FormWrapperWidgetProperties<SingleSelectProperties>>();
const emit = defineEmits<BaseFormWidgetEmits>();

const {
  emitChange,
  emitFocus,
  emitBlur,
} = useBaseFormWidgetEvents(emit);

const { componentProps } = useFormSelect(props);
</script>

<template>
  <SingleSelect v-auto-id="'FormSingleSelect'"
    v-bind="componentProps"
    @change="emitChange"
    @focus="emitFocus"
    @blur="emitBlur"
  />
</template>
