<script setup lang="ts">
import { CurrencySelect } from 'web/src/modules/money/components';
import type { CurrencySelectProps } from 'web/src/modules/money/components/CurrencySelect/types';
import { useBaseFormWidgetEvents } from 'web/src/components/Form/composables';

import type {
  FormWrapperWidgetProperties,
  BaseFormWidgetEmits,
} from '../../../types';
import useFormCurrencySelect from './useFormCurrencySelect';

const props = defineProps<FormWrapperWidgetProperties<CurrencySelectProps>>();
const emit = defineEmits<BaseFormWidgetEmits>();

const {
  emitChange,
  emitFocus,
  emitBlur,
} = useBaseFormWidgetEvents(emit);

const { componentProps } = useFormCurrencySelect(props);
</script>

<template>
  <CurrencySelect v-auto-id="'FormCurrencySelect'"
    v-bind="componentProps"
    @change="emitChange"
    @focus="emitFocus"
    @blur="emitBlur"
  />
</template>
