/* eslint-disable import/no-named-as-default-member */
import type { GetResult } from '@fingerprintjs/fingerprintjs-pro';
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';

import { logger } from '@leon-hub/logging';
import { localStorageManager } from '@leon-hub/local-storage';
import { isNumber, isObject, isString } from '@leon-hub/guards';
import { getLocationHost, isHttps } from '@leon-hub/service-locator-env';
import { Json } from '@leon-hub/utils';

import type { StoredFingerprintJs, FingerprintJsConfig } from './types';

const FJS_STORAGE_KEY = 'fjsVisitorId';

function isFingerprintJsConfig(value: unknown): value is FingerprintJsConfig {
  return isObject(value) && isString(value.apiKey) && isString(value.endpoint) && isNumber(value.visitorIdTtl);
}

function isStoredFingerprintJs(value: unknown): value is StoredFingerprintJs {
  return isObject(value) && isString(value.visitorId) && isNumber(value.timestamp);
}

export default class DeviceFingerprintJsService {
  private instance: GetResult | null = null;

  private config: FingerprintJsConfig | null = null;

  public init(config: FingerprintJsConfig): void {
    this.config = config;
  }

  public async generateFingerprint(): Promise<string | null> {
    if (!isFingerprintJsConfig(this.config)) {
      logger.error(`FingerpintJS config is invalid: config=${Json.stringify(this.config)}`);
      return null;
    }

    if (!this.instance) {
      const endpoint = this.config.endpoint.endsWith('*')
        ? `${isHttps() ? 'https:' : 'http:'}//${this.config.endpoint.slice(0, -1)}${getLocationHost()}`
        : this.config.endpoint;

      const fpPromise = FingerprintJS.load({
        apiKey: this.config.apiKey,
        endpoint,
      });

      const agent = await fpPromise;
      this.instance = await agent.get();
    }

    localStorageManager.setItem(FJS_STORAGE_KEY, Json.stringify({
      visitorId: this.instance.visitorId,
      timestamp: Date.now(),
    }) || '{}');

    return this.instance.visitorId;
  }

  public getFingerprintFromStorage(): string | null {
    if (!isFingerprintJsConfig(this.config)) {
      logger.error(`FingerpintJS config is invalid: config=${Json.stringify(this.config)}`);
      return null;
    }

    const storedFjs = Json.parse(
      localStorageManager.getItem(FJS_STORAGE_KEY) || '{}',
      { defaultValue: {} },
    );

    if (isStoredFingerprintJs(storedFjs)) {
      if (this.config.visitorIdTtl > 0 && (Date.now() - storedFjs.timestamp) / 1000 / 60 < this.config.visitorIdTtl) {
        return storedFjs.visitorId;
      }

      localStorageManager.removeItem(FJS_STORAGE_KEY);
    }

    return null;
  }
}
