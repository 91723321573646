import type { AccumulatorCoefficientBoostSettings } from '@leon-hub/api-sdk';
import { logger } from '@leon-hub/logging';

export function getSortedBoostSettings(input: AccumulatorCoefficientBoostSettings[]): AccumulatorCoefficientBoostSettings[] {
  const sortFunction = (itemA: AccumulatorCoefficientBoostSettings, itemB: AccumulatorCoefficientBoostSettings): number => {
    if (itemA.count === itemB.count) {
      logger.error('Accumulator boost settings are incorrect, duplicate items with same count');
    }
    if (itemA.count < itemB.count) {
      return -1;
    }
    return 1;
  };
  return input.sort(sortFunction);
}
