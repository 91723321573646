import type {
  NavigationGuardNext,
  RouteLocationNormalized,
} from 'vue-router';

import {
  assert,
  isFunction,
} from '@leon-hub/guards';
import { Deferred } from '@leon-hub/utils';

import type { AppVueRouter } from 'web/src/modules/core/services/router/types';
import { importComponent } from 'web/src/modules/core/utils';

import AbstractPrefetch from '../prefetch/AbstractPrefetch';

function isPrefetchFunction(value: unknown): value is () => Promise<{
  default: new() => unknown;
}> {
  return isFunction(value);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type NextResult = any;

export default async function prefetchRouteComponents(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
  router: AppVueRouter,
): Promise<void> {
  if (!to.meta?.prefetch) {
    next();
    return;
  }

  const { prefetch } = to.meta;
  assert(isPrefetchFunction(prefetch));

  const AbstractPrefetchClass = await importComponent(prefetch);
  const newClass = new AbstractPrefetchClass();
  if (!(newClass instanceof AbstractPrefetch)) {
    throw new Error('Imported prefetch is not of AbstractPrefetch class');
  }

  const deferred = new Deferred<NextResult>();

  await newClass.prefetch(router, to, from, (result?: NextResult): void => {
    deferred.resolve(result);
  });

  const resolvedPromise = await deferred.promise;

  next(resolvedPromise);
}
