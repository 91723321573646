<script setup lang="ts">
import type { BetSlipTraceContentProps } from '../types';
import useBetSlipTraceContent from '../composables/useBetSlipTraceContent';
import { KnownTraceTypes } from '../enums/KnownTraceTypes';

const props = defineProps<BetSlipTraceContentProps>();
const {
  isList,
  isTable,
  listSource,
  rootTag,
  contentTag,
  formattedText,
  title,
  typeId,
} = useBetSlipTraceContent(props);
</script>

<template>
  <component v-auto-id="'BetSlipTraceContent'" :is="rootTag">
    <div v-if="isList">
      <BetSlipTraceContent
        v-for="(item, index) in listSource"
        :key="index"
        :contents="item"
      />
    </div>
    <!-- //NOSONAR --><table
      v-else-if="isTable"
      :class="$style['slip-trace-table']"
    >
      <tbody>
        <tr
          v-for="(row, rowIndex) in listSource"
          :key="rowIndex"
        >
          <td
            v-for="(cell, cellIndex) in row"
            :key="cellIndex"
            :class="$style['slip-trace-table__cell']"
          >
            <BetSlipTraceContent :contents="cell ?? undefined" />
          </td>
        </tr>
      </tbody>
    </table>
    <component
      :is="contentTag"
      v-else
      :title="title"
      :class="{
        [$style['slip-trace-abbr']]: typeId === KnownTraceTypes.LABEL_WITH_TITLE,
        [$style['slip-trace-label']]: typeId === KnownTraceTypes.LABEL,
      }"
    >
      {{ formattedText }}
    </component>
  </component>
</template>

<style lang="scss" module>
.slip-trace-abbr {
  color: var(--BrandDefault);
  text-decoration-color: var(--TextSecondary);
  cursor: help;
}

.slip-trace-label {
  &:before {
    content: '\0020';
  }
}

.slip-trace-table {
  width: 100%;
  margin: 10px 0;
  border-spacing: 0;
  border-collapse: collapse;

  &__cell {
    padding: 5px;
    vertical-align: top;
    border: 1px solid;
  }
}
</style>
