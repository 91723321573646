import type { Ref } from 'vue';
import { toRef, computed } from 'vue';

import { useSportlineTopStore } from 'web/src/modules/sportline/submodules/top/store';
import { usePromotionsTopStore } from 'web/src/modules/promotions/submodules/top/usePromotionsTopStore';
import { useSportlineCardsData } from 'web/src/modules/home/submodules/sportline/composables';

interface UseSportlineHomeComposable {
  isReady: Ref<boolean>;
  initialRequests(): Promise<void>;
  syncBackgroundRequests(): Promise<void>;
  setBackgroundUpdateEnabled(value: boolean): Promise<void>;
}

export function useSportlineHome(): UseSportlineHomeComposable {
  const sportlineTopStore = useSportlineTopStore();
  const isSportlineTopReady = toRef(sportlineTopStore, 'isReady');

  const promotionsTopStore = usePromotionsTopStore();
  const isPromotionsTopReady = toRef(promotionsTopStore, 'isReady');

  const sportlineCardsData = useSportlineCardsData();
  const { isReady: isCardsDataReady } = sportlineCardsData;

  const isReady = computed<boolean>(() => (
    isSportlineTopReady.value
    && isPromotionsTopReady.value
    && isCardsDataReady.value
  ));

  async function initialRequests(): Promise<void> {
    await Promise.allSettled([
      sportlineTopStore.initialRequests(),
      promotionsTopStore.initialRequests(),
      sportlineCardsData.initialRequests(),
    ]);
  }

  async function syncBackgroundRequests(): Promise<void> {
    await Promise.allSettled([
      sportlineTopStore.syncBackgroundRequests({ silent: true }),
      promotionsTopStore.syncBackgroundRequests(),
      sportlineCardsData.syncBackgroundRequests(),
    ]);
  }

  async function setBackgroundUpdateEnabled(value: boolean): Promise<void> {
    await Promise.all([
      sportlineTopStore.setBackgroundUpdateEnabled(value),
      sportlineCardsData.setBackgroundUpdateEnabled(value),
    ]);
  }

  return {
    isReady,
    initialRequests,
    syncBackgroundRequests,
    setBackgroundUpdateEnabled,
  };
}
