import { Theme } from '@leon-hub/api-sdk';

import { LogoName } from 'web/src/components/Logo/VLogo/enums';
import { PaymentsCardTypes } from 'web/src/modules/payments/enums';

export default function paymentsCreditCardLogoMapper(cardName: Maybe<PaymentsCardTypes> | undefined, theme?: Theme): LogoName {
  let result: LogoName = LogoName.CREDIT_CARD_UNKNOWN;
  switch (cardName) {
    case PaymentsCardTypes.MASTER_CARD_NEW:
    case PaymentsCardTypes.MASTER_CARD:
      result = process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED ? LogoName.CARD_MASTERCARD_SLOTT : LogoName.CARD_MASTERCARD; break;
    case PaymentsCardTypes.VISA_ELECTRON:
      result = LogoName.CARD_VISA_ELECTRON; break;
    case PaymentsCardTypes.VISA:
      result = process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED ? LogoName.CARD_VISA_SLOTT : LogoName.CARD_VISA; break;
    case PaymentsCardTypes.MAESTRO:
      result = LogoName.CARD_MAESTRO; break;
    case PaymentsCardTypes.AMEX:
      result = LogoName.CARD_AMEX; break;
    case PaymentsCardTypes.DINERS:
      result = LogoName.CARD_DINERS; break;
    case PaymentsCardTypes.DISCOVER:
      result = LogoName.CARD_DISCOVER; break;
    case PaymentsCardTypes.JCB:
      result = LogoName.CARD_JCB; break;
    case PaymentsCardTypes.MIR:
      result = LogoName.CARD_MIR; break;
    case PaymentsCardTypes.HUMO:
      result = LogoName.CARD_HUMO; break;
    case PaymentsCardTypes.UZCARD:
      if (theme === Theme.LIGHT) {
        result = LogoName.CARD_UZCARD_LIGHT;
      } else {
        result = LogoName.CARD_UZCARD_DARK;
      }
      break;
    default: break;
  }
  return result;
}
