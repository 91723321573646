<script setup lang="ts">
import { onMounted } from 'vue';

import { VLoaderDelayed } from 'web/src/components/Loader';

import BetSlipMessageBlock from '../../BetSlipMessageBlock';
import { SlipListHistoryItem } from '../../SlipListItem';
import useBetSlipMyBets from '../composables/useBetSlipMyBets';
import type { BetSlipMyBetsProps, BetSlipMyBetsEmits } from '../types';

const props = withDefaults(defineProps<BetSlipMyBetsProps>(), {
  list: () => [],
  historyUrl: '',
});

const emits = defineEmits<BetSlipMyBetsEmits>();

const {
  isLoaderVisible,
  isEmpty,
  isFooterHidden,
  isFooterSticky,
  isIntersectionObserverAvailable,
  loadMoreObserverTarget,
  emitCashout,
  emitLoadMore,
  onAppear,
  emitHistoryClick,
  emitItemClick,
} = useBetSlipMyBets(props, emits);

onMounted(onAppear);
</script>

<template>
  <div v-auto-id="'BetSlipMyBets'"
    :class="{
      [$style['bet-slip-my-bets--desktop']]: !!'1',
      [$style['bet-slip-my-bets--phone']]: !!null,
    }"
  >
    <div
      v-if="isLoaderVisible"
      :class="$style['bet-slip-my-bets__loader']"
    >
      <VLoaderDelayed />
    </div>
    <template v-else>
      <header
        v-if="placedBetsCount"
        :class="$style['bet-slip-my-bets__header']"
      >
        <span
          :class="$style['bet-slip-my-bets__caption']"
        >
          {{ $t('WEB2_CASHOUT_HELP_MODAL_UNPLAYED_BETS') }}
        </span>
        <a
          v-if="!isIntersectionObserverAvailable"
          :class="[
            $style['bet-slip-my-bets__details-link'],
            $style['bet-slip-my-bets__details-link--fallback']
          ]"
          :href="historyUrl"
          data-test-id="list-history"
          @click.prevent="emitHistoryClick"
        >
          {{ $t('TABS_HISTORY') }}
        </a>
      </header>
      <BetSlipMessageBlock
        v-if="isEmpty"
        :class="$style['bet-slip-my-bets__empty-message']"
        :caption="$t('JS_BETSLIP_HISTORY_EMPTY_CAPTION')"
        :description="$t('JS_BETSLIP_HISTORY_EMPTY_MESSAGE')"
      >
        <div :class="$style['bet-slip-my-bets__empty-link']">
          <a
            :href="historyUrl"
            :class="$style['bet-slip-my-bets__details-link']"
            data-test-id="empty-history"
            @click.prevent="emitHistoryClick"
          >
            {{ $t('WEB2_SLIP_ALL_HISTORY') }}
          </a>
        </div>
      </BetSlipMessageBlock>
      <div v-else>
        <ul :class="$style['bet-slip-my-bets__list']">
          <li
            v-for="item in list"
            :key="item.id"
            :class="$style['bet-slip-my-bets__row']"
          >
            <SlipListHistoryItem
              v-bind="item"
              :id="item.id"
              :bet-type="item.betType"
              @cash-out-click="emitCashout(item)"
              @item-click="emitItemClick(item.id)"
            />
          </li>
        </ul>
        <div ref="loadMoreObserverTarget" />
        <VLoaderDelayed
          v-show="isLoadingMore"
        />
        <div
          v-show="!isFooterHidden"
          :class="{
            [$style['bet-slip-my-bets__footer']]: true,
            [$style['bet-slip-my-bets__footer--sticky']]: isFooterSticky
          }"
        >
          <a
            v-if="isIntersectionObserverAvailable"
            :href="historyUrl"
            :class="$style['bet-slip-my-bets__details-link']"
            data-test-id="list-history"
            @click.prevent="emitHistoryClick"
          >
            {{ $t('WEB2_SLIP_ALL_HISTORY') }}
          </a>
          <span
            v-else
            :class="$style['bet-slip-my-bets__details-link']"
            @click="emitLoadMore"
          >
            {{ $t('JS_PROMOTIONS_TITLE_SHOWMORE') }}
          </span>
        </div>
      </div>
    </template>
  </div>
</template>

<style lang="scss" module>
.bet-slip-my-bets {
  &--desktop {
    display: flex;
    flex: 0 1 auto;
    flex-direction: column;
    overflow: hidden;
  }

  &--phone {
    flex: 1 0 auto;
    min-height: 184px;
  }

  &__loader {
    margin: 20px;
  }

  &__header {
    @include medium\14\16\025;

    position: relative;
    display: flex;
    flex: none;
    align-items: center;
    justify-content: center;
    height: 44px;
    color: var(--TextSecondary);
  }

  &__caption {
    margin: 0 4px;
  }

  &__footer {
    flex: none;
    margin: -1px 0 0;
    text-align: center;

    &--sticky {
      @include z-index(absolute-item);

      position: sticky;
      bottom: 0;
      margin-top: 0;
      background-color: var(--Layer1); // to work with sticky position
      box-shadow: inset 0 1px 0 0 var(--Layer0);
    }
  }

  &__details-link {
    @include regular\14\16\025;

    display: block;
    justify-content: center;
    padding: 14px;
    color: var(--TextPrimary);
    text-align: center;
    text-decoration: underline;
    white-space: nowrap;

    &:hover {
      color: var(--TextDefault);
    }

    &--fallback {
      position: absolute;
      right: 16px;
    }
  }

  &__list {
    flex: none;
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__row {
    padding: 0;
    margin: 0;
  }

  &__empty-link {
    margin-top: 16px;
  }
}
</style>
