import type { Ref } from 'vue';
import { computed, ref } from 'vue';

import { BusEvent, useBusSafeSubscribe } from '@leon-hub/event-bus';

import type { VScrollbarRef } from '@components/v-scrollbar';

import type { ModalContentWrapperProps } from '../types';

export interface ModalContentWrapperComposable {
  scrollbar: Ref<VScrollbarRef | undefined>;
  content: Ref<HTMLElement | undefined>;
}

export default function useModalContentWrapper(
  props: ModalContentWrapperProps,
): ModalContentWrapperComposable {
  const scrollbar = ref<VScrollbarRef>();
  const content = ref<HTMLElement>();

  const innerScrollElement = computed(() => {
    if (!props.isScrollBarEnabled) {
      return null;
    }
    return scrollbar.value;
  });

  function scrollToElementId(id: string): void {
    const matchedElement = document.getElementById(id);
    if (matchedElement && innerScrollElement.value) {
      innerScrollElement.value?.scrollTo(matchedElement.offsetTop);
    }
  }

  useBusSafeSubscribe(BusEvent.SCROLL_TO_ELEMENT_ID, ({ id, inModal }) => {
    if (!inModal) {
      return;
    }
    scrollToElementId(id);
  });
  useBusSafeSubscribe(BusEvent.MODAL_CONTENT_SCROLL_TOP, () => {
    innerScrollElement.value?.scrollTo(0);
  });

  return {
    scrollbar,
    content,
  };
}
