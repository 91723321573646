export function isElementContains(target: Element, element?: Element): boolean {
  if (typeof element?.contains === 'function') {
    return element.contains(target);
  }

  return false;
}

export function isClickOnElement(e: MouseEvent, element?: Element): boolean {
  const { target } = e;
  if (!target || !(target instanceof Element)) { return false; }
  return isElementContains(target, element);
}
