import type { LobbyItemUnit } from 'web/src/modules/lobby/types';
import { isLobbyGamesItem } from 'web/src/modules/lobby/utils';

export function getJackpotGameIdsFromLobbyItems(lobbyItems: LobbyItemUnit[]): string[] {
  return lobbyItems.reduce<string[]>((accumulator, item) => {
    if (isLobbyGamesItem(item)) {
      for (const game of item.games) {
        if (game.jackpot && !accumulator.includes(game.id)) {
          accumulator.push(game.id);
        }
      }
    }

    return accumulator;
  }, []);
}
