<script lang="ts" setup>
import OverlayType from './enums/OverlayType';

interface VOverlayProps {
  type?: OverlayType;
  isFixed?: boolean;
}

interface VOverlayEmits {
  (e: 'click'): void;
}

const props = withDefaults(defineProps<VOverlayProps>(), {
  type: OverlayType.BLUR_L0,
});
const emit = defineEmits<VOverlayEmits>();
</script>

<template>
  <div v-auto-id="'VOverlay'"
    :class="{
      [$style['overlay']]: true,
      [$style['overlay--fixed']]: props.isFixed,
      [$style[`overlay--type-${props.type}`]]: props.type,
    }"
    @click="emit('click')"
  />
</template>

<style lang="scss" module>
.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  &--type-blur-l0 {
    background-color: var(--OverlayL0Color);
  }

  &--type-blur-l1 {
    backdrop-filter: $overlayBlur;

    @include is-app-layout-phone {
      background-color: rgba(0, 0, 0, 0.4);
    }

    @include is-app-layout-desktop {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }

  &--type-opacity-l0 {
    background-color: var(--Layer0);
    opacity: 0.7;
  }

  &--type-opacity-l1 {
    background-color: var(--Layer1);
    opacity: 0.7;
  }

  &--fixed {
    position: fixed;
  }
}
</style>
