import type { Ref, WatchStopHandle } from 'vue';
import { computed, ref, watch } from 'vue';

import type { SportFamily } from '@leon-hub/api-sdk';
import type { Optional } from '@leon-hub/types';

import { useExtendedEventStore } from 'web/src/modules/sportline/submodules/extended-event/store';
import { useExtendedEventSettings } from 'web/src/modules/sportline/submodules/extended-event/store/composables';
import { useIsEventExtended } from 'web/src/modules/sportline/submodules/extended-event/composbles';
import type { SportlineEventDetails } from 'web/src/modules/sportline/types/details';
import { ExtendedEventBlock } from 'web/src/modules/sportline/submodules/extended-event/enums';

interface ExtendedSportEventInfoProps {
  sportlineEventId: Ref<string>;
  extendedBlockType?: Ref<Optional<ExtendedEventBlock>>;
}

interface ExtendedSportEventInfoEmits {
  afterClose(): void;
}

interface ExtendedSportEventInfoComposable {
  isEventExtended: Ref<boolean>;
  extendedSportEvent: Ref<Maybe<SportlineEventDetails>>;
  representationSportFamily: Ref<Maybe<string | SportFamily>>;
  extendedSportEventDetailsMarketLimit: Ref<number>;
  isMarketGroupsTabsEnabled: Ref<boolean>;

  openExtendedSportlineEvent(): Promise<void>;
  closeExtendedSportlineEvent(): void;
  pauseBackgroundUpdated(): void;
  resumeBackgroundUpdated(): void;
}

export function useExtendedSportEventInfo(
  props: ExtendedSportEventInfoProps,
  emits: ExtendedSportEventInfoEmits,
): ExtendedSportEventInfoComposable {
  const { sportlineEventId, extendedBlockType } = props;

  const {
    extendedEventDetailsMarketLimit: extendedSportEventDetailsMarketLimit,
    isMarketGroupsTabsEnabled,
  } = useExtendedEventSettings();

  const extendedEventStore = useExtendedEventStore();
  const {
    createEventDetailsRef,
    loadSportlineEventDetails,
    addEventIdToOpened,
    removeEventIdFromOpened,
    addEventIdToBackgroundRequests,
    removeEventIdFromBackgroundRequests,
  } = extendedEventStore;

  const blockType = extendedBlockType ?? ref(ExtendedEventBlock.Default);
  const extendedSportEvent = createEventDetailsRef(sportlineEventId, blockType);
  const { isEventExtended } = useIsEventExtended({ sportlineEventId, blockType });

  let stopWatchEventIsClosedHandle: Optional<WatchStopHandle>;

  const representationSportFamily = computed<Maybe<string | SportFamily>>(() => (
    extendedSportEvent.value?.sport.representation.family ?? null
  ));

  function stopWatchEventIsClosed(): void {
    stopWatchEventIsClosedHandle?.();
    stopWatchEventIsClosedHandle = undefined;
  }

  function closeExtendedSportlineEvent(): void {
    stopWatchEventIsClosed();
    removeEventIdFromBackgroundRequests(sportlineEventId.value, blockType.value);
    removeEventIdFromOpened(sportlineEventId.value, blockType.value);
    emits.afterClose();
  }

  function onIsEventExtendedValueChanged(value: boolean): void {
    if (value) { return; }

    stopWatchEventIsClosed();
    closeExtendedSportlineEvent();
  }

  /**
   * Watch the extended state to stop background requests if user extends too many events
   */
  function startWatchEventIsClosed(): void {
    stopWatchEventIsClosedHandle = watch(isEventExtended, onIsEventExtendedValueChanged);
  }

  async function loadExtendedSportEvent(silent = false): Promise<void> {
    await loadSportlineEventDetails(sportlineEventId.value, silent);
    const event = extendedSportEvent.value;
    if (!event && !silent) { closeExtendedSportlineEvent(); }
  }

  /** Open the block after button clicked */
  async function openExtendedSportlineEvent(): Promise<void> {
    if (isEventExtended.value) { return; }
    addEventIdToOpened(sportlineEventId.value, blockType.value);
    await loadExtendedSportEvent();
    if (!extendedSportEvent.value) { closeExtendedSportlineEvent(); }
    // start background requests for this id after the first loading
    addEventIdToBackgroundRequests(sportlineEventId.value, blockType.value);
    startWatchEventIsClosed();
  }

  function resumeBackgroundUpdated(): void {
    if (!isEventExtended.value) { return; }
    addEventIdToBackgroundRequests(sportlineEventId.value, blockType.value);
  }

  function pauseBackgroundUpdated(): void {
    removeEventIdFromBackgroundRequests(sportlineEventId.value, blockType.value);
    // @TODO check. Seems it is just used to stop scrolling
    emits.afterClose();
  }

  return {
    openExtendedSportlineEvent,
    closeExtendedSportlineEvent,
    pauseBackgroundUpdated,
    resumeBackgroundUpdated,

    isEventExtended,
    extendedSportEvent,
    representationSportFamily,
    extendedSportEventDetailsMarketLimit,
    isMarketGroupsTabsEnabled,
  };
}
