import type { Ref } from 'vue';
import { computed, toRef } from 'vue';
import type { RouteLocationNamedRaw } from 'vue-router';
import { useRouter } from 'vue-router';

import type { SportFilterKickoffPeriod } from '@leon-hub/api-sdk';
import type { BaseRouteNameType } from '@leon-hub/routing-config-names';
import { RouteName } from '@leon-hub/routing-config-names';

import { SportlineNavigationSection } from 'web/src/modules/sportline/submodules/navigation/enums';
import useSportlineSidebarStore
  from 'web/src/modules/sportline/submodules/sidebar/store/useSportlineSidebarStore';
import { useSportlineNavigationStore } from 'web/src/modules/sportline/submodules/navigation/store';
import {
  useSportsListDateFilter,
} from 'web/src/modules/sportline/submodules/sports-list/components/composables';
import type {
  DateTimeRange,
  SportEventsSportsDateTimeFilter,
} from 'web/src/modules/sportline/types';
import type { SportlinePageIdentifier as SportEventsPageIdentifier } from 'web/src/modules/sportline/types/navigation';
import type {
  SportsTreeSportElement,
  SportsTreeLeagueElement,
  SportsTreeRegionElement,
} from 'web/src/modules/sportline/types/rest';
import type {
  CustomerSportListElement,
} from 'web/src/modules/sportline/submodules/favorites/types';
import type {
  SportlineType,
} from 'web/src/modules/sportline/enums';
import {
  SportSegmentId,
} from 'web/src/modules/sportline/enums';
import {
  getSportPageNavigationParameters,
  isSportFamilyEquals,
} from 'web/src/modules/sportline/utils';
import {
  resolveLeaguePageLink,
  resolveRegionPageLink,
} from 'web/src/modules/sportline/submodules/navigation/store/utils';
import {
  resolveSportPageLink,
} from 'web/src/modules/sportline/submodules/sidebar/utils';
import type {
  SidebarMenu,
  SidebarMenuL1,
  SidebarMenuL2,
  SidebarMenuL3,
  MenuLevelClickPayload,
} from 'web/src/components/SidebarMenu/types';
import { prepareNavigationBetweenPagesSportsFilter } from 'web/src/modules/sportline/submodules/navigation/utils';

import { useSidebarClick } from './useSidebarClick';

interface SidebarComponentComposable {
  isSportFiltersEnabled: Ref<boolean>;
  maxTopLeaguesInTab: Ref<number>;
  maxTopLeaguesTabsInSidebar: Ref<number>;
  fullSportsList: Ref<SportsTreeSportElement[]>;
  fullFavoriteLeagues: Ref<Readonly<CustomerSportListElement[]>>;
  topLeaguesDefaultSportId: Ref<Maybe<string>>;
  datetimeActiveSportlineType: Ref<Optional<SportlineType>>;
  datetimeActiveKickoffPeriod: Ref<SportFilterKickoffPeriod>;
  datetimeCustomFrom: Ref<Optional<number>>;
  datetimeCustomTo: Ref<Optional<number>>;
  menu: Ref<SidebarMenu>;
  onDateTimeFilterSelect(filter: Maybe<SportEventsSportsDateTimeFilter>): void;
  setDateTimeFilterCustomRange(range: DateTimeRange): void;
  setBackgroundUpdateEnabled(value: boolean): Promise<void>;
  catalogClick({ event, payload }: { event: MouseEvent; payload?: MenuLevelClickPayload }): void;
  beforeSidebarNavigate({ event, to, payload }: {
    event: MouseEvent;
    to: RouteLocationNamedRaw;
    payload?: MenuLevelClickPayload;
  }): void;
}

export function useSidebarComponent(): SidebarComponentComposable {
  const router = useRouter();
  const sportlineNavigationStore = useSportlineNavigationStore();

  const { catalogClick } = useSidebarClick();

  const sportlineNavigationSection = toRef(sportlineNavigationStore, 'sportlineNavigationSection');
  const {
    setSportlineNavigationBetweenPagesFilter,
    setSportlineReloadFiltersLocation,
  } = sportlineNavigationStore;

  const sidebarStore = useSportlineSidebarStore();
  const isSportFiltersEnabled = toRef(() => sidebarStore.isSportFiltersEnabled);
  const maxTopLeaguesInTab = toRef(() => sidebarStore.maxTopLeaguesInTab);
  const maxTopLeaguesTabsInSidebar = toRef(() => sidebarStore.maxTopLeaguesTabsInSidebar);
  const fullSportsList = toRef(() => sidebarStore.fullSportsList);
  const actualSportsList = toRef(() => sidebarStore.actualSportsList);
  const sportsListFilter = toRef(() => sidebarStore.sportsListFilter);
  const fullFavoriteLeagues = toRef(() => sidebarStore.fullFavoriteLeagues);
  const {
    setSportsListFilter,
    setBackgroundUpdateEnabled,
  } = sidebarStore;

  const {
    dateTimeFilterCustomFrom: datetimeCustomFrom,
    dateTimeFilterCustomTo: datetimeCustomTo,
    dateTimeFilterActiveKickoffPeriod: datetimeActiveKickoffPeriod,
    dateTimeFilterActiveSportlineType: datetimeActiveSportlineType,
  } = useSportsListDateFilter({ dateTimeFilter: sportsListFilter });

  const defaultState = computed<Readonly<SportEventsPageIdentifier>>(() => {
    const {
      sportFamily,
      leagueId,
      regionId,
    } = getSportPageNavigationParameters(router.currentRoute.value);

    return Object.freeze<SportEventsPageIdentifier>({
      sportFamily,
      regionId,
      leagueId,
    });
  });

  function isActiveLeagueElement(element: SportsTreeLeagueElement): boolean {
    const league = element?.league;
    const id = league?.id;

    return !!id && defaultState.value.leagueId === id;
  }

  function isActiveRegionElement(element: SportsTreeRegionElement): boolean {
    const region = element?.region;
    const id = region?.id;
    const isRegionSelected = !!id && defaultState.value.regionId === id;

    return isRegionSelected || (element.leagues || []).some((league) => isActiveLeagueElement(league));
  }

  function isActiveSportElement(element: SportsTreeSportElement): boolean {
    const isSportSelected = isSportFamilyEquals(element?.sport, defaultState.value.sportFamily);

    if (isSportSelected) { return true; }

    return (element.regions ?? []).some((region) => isActiveRegionElement(region));
  }

  function isActiveCyberSportRegionElement(element: SportsTreeRegionElement): boolean {
    const currentRouteValue = router.currentRoute.value;
    if (currentRouteValue.name !== RouteName.SPORTLINE_CYBERSPORT) { return false; }
    const { region } = currentRouteValue.params;
    return region === element.region.navigationParameters.urlName;
  }

  function isActiveCyberSportElement(): boolean {
    return router.currentRoute.value.name === RouteName.SPORTLINE_CYBERSPORT;
  }

  function isCyberSportElement(element: SportsTreeSportElement): boolean {
    return element.sport.segment.id === SportSegmentId.CyberSport;
  }

  function onDateTimeFilterSelect(filter: Maybe<SportEventsSportsDateTimeFilter>): void {
    void setSportsListFilter(filter);
  }

  function setDateTimeFilterCustomRange(range: DateTimeRange): void {
    void setSportsListFilter(range);
  }

  function beforeSidebarNavigate({ event, to, payload }: {
    event: MouseEvent;
    to: RouteLocationNamedRaw;
    payload?: MenuLevelClickPayload;
  }): void {
    const selectedDateTimeFilter = prepareNavigationBetweenPagesSportsFilter(sportsListFilter.value);

    if (router.canNavigateTo(to)) {
      setSportlineNavigationBetweenPagesFilter(selectedDateTimeFilter);
    } else {
      setSportlineNavigationBetweenPagesFilter(selectedDateTimeFilter);
      setSportlineReloadFiltersLocation(to.name as BaseRouteNameType);
    }

    catalogClick({ event, payload });
  }

  const menu = computed<SidebarMenu>(() => {
    // https://jira.leoncorp.net/browse/LEONWEB-2140
    const isOpenByDefault = !!process.env.VUE_APP_PRERENDER;

    return actualSportsList.value?.map((sportElement): SidebarMenuL1 => {
      const L1Key = `level1-spoiler-${sportElement.sport.id}-${defaultState.value.sportFamily || 'default'}`;

      const isCyberSport = isCyberSportElement(sportElement);

      return {
        key: L1Key,
        id: sportElement.sport.id,
        location: resolveSportPageLink(sportElement.sport),
        name: sportElement.sport.name,
        canOpen: true,
        active: isOpenByDefault || isActiveSportElement(sportElement) || (isCyberSport && isActiveCyberSportElement()),
        menu: sportElement.regions?.map((regionElement): SidebarMenuL2 => ({
          active: isOpenByDefault || isActiveRegionElement(regionElement) || (isCyberSport && isActiveCyberSportRegionElement(regionElement)),
          key: `level2-spoiler-${regionElement.region.id}-${defaultState.value.regionId || 'default'}`,
          location: resolveRegionPageLink(regionElement.region.navigationParameters),
          name: regionElement.region.name,
          menu: regionElement.leagues?.map((leagueElement): SidebarMenuL3 => ({
            active: isActiveLeagueElement(leagueElement),
            id: leagueElement.league.id,
            key: `level3-${leagueElement.league.id}`,
            location: resolveLeaguePageLink(leagueElement.league.navigationParameters),
            name: leagueElement.league.name,
          })) ?? [],
        })) ?? [],
      };
    }) ?? [];
  });

  const topLeaguesDefaultSportId = computed<Maybe<string>>(() => {
    if (sportlineNavigationSection.value === SportlineNavigationSection.CyberSport) {
      const cyberSport = fullSportsList.value.find((sportElement) => sportElement.sport.segment.id === SportSegmentId.CyberSport);
      return cyberSport?.sport.id ?? null;
    }

    return null;
  });

  return {
    isSportFiltersEnabled,
    maxTopLeaguesInTab,
    maxTopLeaguesTabsInSidebar,
    fullSportsList,
    fullFavoriteLeagues,
    topLeaguesDefaultSportId,

    datetimeActiveKickoffPeriod,
    datetimeActiveSportlineType,
    datetimeCustomFrom,
    datetimeCustomTo,
    menu,

    onDateTimeFilterSelect,
    setDateTimeFilterCustomRange,
    setBackgroundUpdateEnabled,
    beforeSidebarNavigate,
    catalogClick,
  };
}
