import { defineStore } from 'pinia';
import { ref } from 'vue';

import type { GetApiSettingsReturnType } from '@leon-hub/api-sdk';

import { fetchApiSettings } from 'web/src/modules/core/store/utils/fetchApiSettings';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import { useSyncState } from 'web/src/modules/core/submodules/sync-state';

const useApiSettingsStore = defineStore('api-settings', () => {
  const api = useGraphqlClient();

  const graphqlBatchRequestsMaxSize = ref(0);

  function handleApiSettings(settings: GetApiSettingsReturnType): void {
    if (settings && graphqlBatchRequestsMaxSize.value !== settings.graphqlBatchRequestsMaxSize) {
      graphqlBatchRequestsMaxSize.value = settings.graphqlBatchRequestsMaxSize;

      api.setMaxBatchQueueSize(graphqlBatchRequestsMaxSize.value);
    }
  }

  async function updateApiSettings(): Promise<void> {
    handleApiSettings(await fetchApiSettings());
  }

  useSyncState(updateApiSettings, 'api-settings');

  return {
    graphqlBatchRequestsMaxSize,
    handleApiSettings,
    updateApiSettings,
  };
});

export default useApiSettingsStore;
