<script lang="ts" setup>
import { IconName, IconSize } from '@leon-hub/icons';

import { VIcon } from '@components/v-icon';
import type { VIconProps } from '@components/v-icon';

const iconProperty: VIconProps = {
  size: IconSize.SIZE_24,
  name: null ? IconName.SLOTT_PLUS : IconName.PLUS_SMALL,
};
</script>

<template>
  <div v-auto-id="'ReferralProgramAddButton'" :class="$style['referral-program-add-button']">
    <div :class="$style['referral-program-add-button__circle']">
      <VIcon
        :class="$style['referral-program-add-button__icon']"
        v-bind="iconProperty"
      />
    </div>
    <span :class="$style['referral-program-add-button__label']"> {{ $t('WEB2_REFFERAL_PROGRAM_INVITE') }} </span>
  </div>
</template>

<style lang="scss" module>
.referral-program-add-button {
  $self: &;

  display: flex;
  flex-direction: column;
  gap: $buttonGap;
  align-items: center;
  width: 60px;

  &__circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    background-color: $circleColor;
    border-radius: 50%;
  }

  &__icon {
    color: $iconColor;
  }

  &__label {
    @include labelStyles;

    width: 60px;
    text-align: center;
  }

  &:hover {
    @include for-hover {
      cursor: pointer;
      #{$self}__circle {
        background-color: $circleColorHover;
      }

      #{$self}__icon {
        color: $iconColorHover;
      }

      #{$self}__label {
        color: $labelColorHover;
      }
    }
  }
}
</style>
