import type { Ref } from 'vue';
import { ref, computed, watch } from 'vue';

import { logger } from '@leon-hub/logging';
import { isString } from '@leon-hub/guards';

import { useCopyToClipboard } from 'web/src/modules/dialogs/composables';
import { useI18n } from 'web/src/modules/i18n/composables';

import type { SharePreviewProps, SharePreviewEmits, SavedImageData } from '../types';
import { handleImageBlob, isSupportShareApi, base64ToBlob } from '../utils';

interface UseSharePreview {
  copyCode(): void;
  imageIsLoaded: Ref<boolean>;
  buttonIsDisabled: Ref<boolean>;
  isShareApiAvailable: Ref<boolean>;
  additionalButtonText: Ref<string>;
  imageSrc: Ref<string | null>;
  onMainButtonClick(): void;
  onShowAmountToggle(): void;
  downloadImage(): void;
  onAdditionalButtonClick(): void;
}

export function useSharePreview(props: SharePreviewProps, emit: SharePreviewEmits): UseSharePreview {
  const { copy } = useCopyToClipboard();
  const { $translate } = useI18n();

  const copyCode = (): void => {
    void copy({
      data: props.bookingCode ?? '',
      notificationText: $translate('WEB2_BET_SHARE_CODE_COPIED').value,
    });
  };

  const savedImages = ref<Record<string, SavedImageData>>({});

  const updateSavedImages = (key: string, data: SavedImageData): void => {
    savedImages.value[key] = data;
  };

  const imageFile = computed<File | null>(() => {
    if (props.imageUrl) {
      return savedImages.value[props.imageUrl]?.file ?? null;
    }
    return null;
  });

  const dataImageSrc = computed<string>(() => {
    if (props.imageUrl) {
      return savedImages.value[props.imageUrl]?.dataUrl ?? '';
    }
    return '';
  });

  const imageIsLoaded = ref<boolean>(true);

  const imageName = computed<string>(() => {
    if (props.bookingCode) {
      return `${props.bookingCode}.png`;
    }
    return 'myBet.png';
  });

  const getImage = async (): Promise<void> => {
    if (props.imageUrl) {
      const key = props.imageUrl;
      const imageBlob = base64ToBlob(props.imageUrl, 'image/png');
      if (imageBlob) {
        const file = new File([imageBlob], imageName.value, { type: imageBlob.type });
        const dataUrl = await handleImageBlob(imageBlob);
        if (dataUrl && isString(dataUrl)) {
          updateSavedImages(key, { file, dataUrl });
          imageIsLoaded.value = true;
        }
      }
    }
  };

  const imageSrc = computed<string | null>(() => {
    if (!imageIsLoaded.value) {
      return null;
    }
    return dataImageSrc.value;
  });

  const reloadImage = (): void => {
    imageIsLoaded.value = false;
    void getImage();
  };

  watch(() => props.imageUrl, (value) => {
    if (value) {
      reloadImage();
    }
  }, { immediate: true });

  const emitDone = (): void => {
    emit('done');
  };

  const shareText = computed<string>(() => {
    if (props.isSharingPlacedBet) {
      return $translate('WEB2_BET_SHARE_PLACED_BET_TEXT').value;
    }
    if (!props.bookingCode) {
      return $translate('WEB2_BET_SHARE_MESSAGE_TEXT').value;
    }
    const codeTranslationProps = ref<Record<string, string>>({ code: props.bookingCode });
    return props.shareLink
      ? $translate('WEB2_BET_SHARE_MESSAGE_TEXT').value
      : $translate('WEB2_BET_SHARE_MESSAGE_TEXT_ONLY_CODE', codeTranslationProps).value;
  });

  const shareViaCopyLink = async (): Promise<void> => {
    const linkStr = props.shareLink ? `\n${props.shareLink}` : '';
    await copy({
      data: `${shareText.value}${linkStr}`,
      notificationText: $translate('WEB2_LINK_HAS_BEEN_COPIED').value,
    });
    emitDone();
  };

  const downloadImage = (): void => {
    if (imageSrc.value) {
      const downloadLink = document.createElement('a');
      downloadLink.target = '_blank';
      downloadLink.href = imageSrc.value;
      downloadLink.download = imageName.value;
      downloadLink.click();
    }
  };

  const copyLinkOrSaveImage = (): void => {
    if (!props.shareLink && !props.imageUrl) {
      logger.error('Nothing to share in share preview', { ...props });
      return;
    }
    if (props.shareLink) {
      void shareViaCopyLink();
    } else {
      downloadImage();
    }
  };

  const shareData = computed<ShareData>(() => ({
    text: shareText.value,
    files: imageFile.value ? [imageFile.value] : undefined,
    ...(props.shareLink ? { url: props.shareLink } : {}),
  }));

  const isShareApiAvailable = computed<boolean>(() => isSupportShareApi() && navigator.canShare(shareData.value));

  const onMainButtonClick = (): void => {
    if (isShareApiAvailable.value) {
      navigator.share(shareData.value)
        .then(() => {
          emitDone();
          emit('share-success', props.bookingCode ?? '');
        })
        .catch((error) => {
          logger.error('Bet share failed', error);
          copyLinkOrSaveImage();
        });
    } else {
      copyLinkOrSaveImage();
    }
  };

  const onShowAmountToggle = (): void => {
    const nextValue = !props.showAmountEnabled;
    if (Object.keys(savedImages.value).length < 2) {
      // not image from cache
      imageIsLoaded.value = false;
    }
    emit('show-amount-click', nextValue);
  };

  const buttonIsDisabled = computed<boolean>(() => {
    if (props.isLoading) {
      return true;
    }
    return !!props.imageUrl && !imageIsLoaded.value;
  });

  const additionalButtonText = computed<string>(() => {
    if (props.shareLink) {
      return $translate('WEB2_COPY_LINK').value;
    }
    return $translate('WEB2_DOWNLOAD').value;
  });

  const onAdditionalButtonClick = (): void => {
    emit('copy-link', props.bookingCode ?? '');
    copyLinkOrSaveImage();
  };

  return {
    copyCode,
    onMainButtonClick,
    imageIsLoaded,
    imageSrc,
    buttonIsDisabled,
    isShareApiAvailable,
    additionalButtonText,
    onShowAmountToggle,
    downloadImage,
    onAdditionalButtonClick,
  };
}
