import type { Ref } from 'vue';
import { toRef } from 'vue';

import { useSportlinePersistFilterStore } from '../store';
import type {
  SportlinePersistentLocation,
  SportlinePersistentLocationKey,
  SportlinePersistentLocationValue,
} from '../types';

interface UseSportlinePersistLocationComposable {
  persistLocation: Ref<SportlinePersistentLocation>;
  setPersistLocation(
    key: SportlinePersistentLocationKey,
    value?: Maybe<SportlinePersistentLocationValue>,
    permissionKey?: SportlinePersistentLocationKey,
  ): void;
}

export function useSportlinePersistLocation(): UseSportlinePersistLocationComposable {
  const sportlinePersistFilterStore = useSportlinePersistFilterStore();

  const persistLocation = toRef(sportlinePersistFilterStore, 'persistLocation');
  const { setPersistLocation } = sportlinePersistFilterStore;

  return {
    persistLocation,
    setPersistLocation,
  };
}
