import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useKeepAliveStore = defineStore('keep-alive', () => {
  const keepAliveComponents = ref<string[]>([]);

  function addKeepAliveComponent(componentName: string): void {
    if (!keepAliveComponents.value.includes(componentName)) {
      keepAliveComponents.value.push(componentName);
    }
  }

  function removeKeepAliveComponent(componentName: string): void {
    keepAliveComponents.value = keepAliveComponents.value.filter((c) => c !== componentName);
  }

  return {
    keepAliveComponents,
    addKeepAliveComponent,
    removeKeepAliveComponent,
  };
});
