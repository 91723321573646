import { defineStore } from 'pinia';
import { ref } from 'vue';

import type { NavigationConfig } from '@leon-hub/navigation-config';
import { NavigationGroup } from '@leon-hub/navigation-config';
import {
  getPromotionNavigationConfig,
} from '@leon-hub/api-sdk';
import { assert } from '@leon-hub/guards';
import { isNavigationConfig } from '@leon-hub/navigation-config/src/types/NavigationConfig';

import { fetchDefaultNavigationConfig } from 'web/src/modules/core/store/utils/fetchDefaultNavigationConfig';
import { useSyncState } from 'web/src/modules/core/submodules/sync-state';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';

const useNavigationStore = defineStore('navigation', () => {
  const api = useGraphqlClient();
  const navigationConfig = ref<NavigationConfig>({});

  function handleDefaultNavigationConfig(config: NavigationConfig): void {
    navigationConfig.value = config;
  }

  async function updateDefaultNavigationConfig(silent = false): Promise<void> {
    handleDefaultNavigationConfig(await fetchDefaultNavigationConfig(silent));
  }

  async function fetchPromotionsNavigationConfig(silent = false): Promise<void> {
    const result = await getPromotionNavigationConfig(api,
      (node) => node.queries.system.getNavigation,
      {},
      { silent });

    const parsedNavigationConfig = JSON.parse(result.navigation);
    assert(isNavigationConfig(parsedNavigationConfig));

    const key = NavigationGroup.PROMOTION_CATEGORIES_NAVIGATION;
    navigationConfig.value[key] = parsedNavigationConfig[key];
  }

  useSyncState(updateDefaultNavigationConfig, 'navigation');

  return {
    updateDefaultNavigationConfig,
    handleDefaultNavigationConfig,
    fetchPromotionsNavigationConfig,
    navigationConfig,
  };
});

export default useNavigationStore;
