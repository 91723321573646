import type {
  GqlApiBatchSubRequestErrorOptions,
} from '@leon-hub/api/src/client/graphql/errors/GqlApiBatchedSubRequestError';
import GqlApiBatchedSubRequestError from '@leon-hub/api/src/client/graphql/errors/GqlApiBatchedSubRequestError';
import type { PreviousRequestHasNotExpiredException } from '@leon-hub/api-sdk';

import { ApiErrorCode } from '../../errors/ApiErrorCode';

export class GqlApiPreviousRequestHasNotExpired extends GqlApiBatchedSubRequestError {
  constructor(options: GqlApiBatchSubRequestErrorOptions & { extensions: PreviousRequestHasNotExpiredException }) {
    super({
      ...options,
      code: ApiErrorCode.API_PREVIOUS_REQUEST_HAS_NOT_EXPIRED,
    });
  }
}
