export const isQuotaExceededError = (error: unknown): boolean => error instanceof DOMException && (
  // everything except Firefox
  error.code === 22
    // Firefox
    || error.code === 1014
    // test name field too, because code might not be present
    // everything except Firefox
    || error.name === 'QuotaExceededError'
    // Firefox
    || error.name === 'NS_ERROR_DOM_QUOTA_REACHED');
