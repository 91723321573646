import { LobbyItemType } from '@leon-hub/api-sdk';

import type { LobbyItemProps, LobbyItemTestType } from 'web/src/modules/lobby/components/LobbyItem/types';

export default function getTestType(props: LobbyItemProps): LobbyItemTestType {
  switch (props.lobbyItemType) {
    case LobbyItemType.PROMOTIONS:
      return 'promotion';
    case LobbyItemType.GROUPS:
      return 'group';
    case LobbyItemType.TOP_WINNERS:
      return 'top-winner';
    case LobbyItemType.GAMES_CATEGORY:
      return 'game';
    default:
      return props.lobbyItemType;
  }
}
