import {
  ref,
  nextTick,
  onBeforeUnmount,
  watch,
} from 'vue';
import type { Ref } from 'vue';

import { Timer } from '@leon-hub/utils';
import { useWindowResize } from '@leon-hub/browser-composables';

import type { VCoreDropdownProps } from '../types';
import { getCoreDropdownPropsFromElement } from '../utils';
import { useExternalScrollWatcher } from './useExternalScrollWatcher';

type DropdownProps<T = {}> = T & { autofocus?: boolean };

interface Options {
  ignoreScroll?: boolean;
  ignoreResize?: boolean;
}

interface UseDropdownButton {
  isDropdownShown: Ref<boolean>;
  focusOnDropdownButton: () => void;
  openDropdown: () => void;
  closeDropdown: () => Promise<void>;
  setDropdownButton: (element?: HTMLButtonElement) => void;
  dropdownButton: Ref<HTMLButtonElement | undefined>;
  dropdownProps: Ref<VCoreDropdownProps | null>;
}

export default function useDropdownButton(props: DropdownProps, options?: Options): UseDropdownButton {
  const isDropdownShown = ref<boolean>(false);

  const dropdownButton = ref<HTMLButtonElement | undefined>();

  const dropdownProps = ref<VCoreDropdownProps | null>(null);

  const setDropdownButton = (element?: HTMLButtonElement): void => {
    dropdownButton.value = element;
  };

  const focusOnDropdownButton = (): void => {
    dropdownButton.value?.focus();
  };

  const updateDropdownProps = (): void => {
    if (!dropdownButton.value) {
      return;
    }
    dropdownProps.value = getCoreDropdownPropsFromElement(dropdownButton.value, { matchParentWidth: true });
  };

  const closeDropdown = async (): Promise<void> => {
    isDropdownShown.value = false;
    await nextTick();
    focusOnDropdownButton();
  };

  const openDropdown = () => {
    if (isDropdownShown.value) {
      return;
    }
    updateDropdownProps();
    isDropdownShown.value = true;
  };

  let updatePositionTimer = 0;

  const clearUpdatePositionTimer = (): void => {
    Timer.clearTimeout(updatePositionTimer);
  };

  onBeforeUnmount(clearUpdatePositionTimer);

  const updateContainerPositionProps = () => {
    clearUpdatePositionTimer();
    if (!isDropdownShown.value) {
      return;
    }
    updatePositionTimer = Timer.setTimeout(() => {
      updateDropdownProps();
    }, 40);
  };

  if (!options?.ignoreScroll) {
    useExternalScrollWatcher(updateContainerPositionProps, updateContainerPositionProps);
  }

  if (!options?.ignoreResize) {
    useWindowResize(updateContainerPositionProps);
  }

  watch(() => props.autofocus, (value) => {
    if (value) {
      void nextTick().then(() => {
        focusOnDropdownButton();
      });
    }
  }, { immediate: true });

  return {
    isDropdownShown,
    closeDropdown,
    openDropdown,
    focusOnDropdownButton,
    setDropdownButton,
    dropdownProps,
    dropdownButton,
  };
}
