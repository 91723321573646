<script lang="ts" setup>
import { IconName } from '@leon-hub/icons';

import VButton from 'web/src/components/Button/VButton/VButton.vue';
import type {
  LobbyEgsGamesItemButtonsHoverEmits,
  LobbyEgsGamesItemButtonsHoverProps,
} from 'web/src/modules/lobby/components/LobbyEgsGamesItemButtonsHover/types';
import { useLobbyEgsGamesItemButtonsHover } from 'web/src/modules/lobby/components/LobbyEgsGamesItemButtonsHover/composables';
import SButton from 'web/src/components/Button/SButton/SButton.vue';

defineProps<LobbyEgsGamesItemButtonsHoverProps>();
const emits = defineEmits<LobbyEgsGamesItemButtonsHoverEmits>();

const {
  playButtonProperties,
  demoButtonProperties,
} = useLobbyEgsGamesItemButtonsHover();
</script>

<template>
  <div v-auto-id="'LobbyEgsGamesItemButtonsHover'"
    :class="$style['lobby-egs-games-item-buttons-hover']"
  >
    <template v-if="null">
      <SButton
        kind="primary"
        size="large"
        :icon-name="IconName.SLOTT_PLAY"
        :class="$style['lobby-egs-games-item-buttons-hover__btn']"
        @click="emits('click', $event)"
      />
    </template>
    <template v-else>
      <VButton
        v-bind="playButtonProperties"
        :class="$style['lobby-egs-games-item-buttons-hover__btn']"
        @click="emits('click-play', $event)"
      />
      <div
        :class="{
          [$style['lobby-egs-games-item-buttons-hover__btn']]: true,
          [$style['lobby-egs-games-item-buttons-hover__btn--demo']]: true,
        }"
      >
        <VButton
          v-if="isDemoAvailable"
          v-bind="demoButtonProperties"
          @click="emits('click-demo', $event)"
        />
      </div>
    </template>
  </div>
</template>

<style lang="scss" module>
.lobby-egs-games-item-buttons-hover {
  $self: &;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.2s ease;

  &__btn {
    @include z-index(base);

    &--demo {
      position: absolute;
      bottom: 12px;
    }
  }

  &:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
    background-color: $lobbyEgsGamesItemButtonsHoverBackground;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:hover {
    opacity: 1;

    &:after {
      opacity: 1;
    }
  }
}
</style>
