import type { Ref } from 'vue';

import {
  onComponentActivated,
  onComponentDeactivated,
} from '@leon-hub/vue-utils';

import type {
  SyncStateCallback,
  SyncStateOptions,
  SyncStateTimerName,
} from 'web/src/modules/core/submodules/sync-state/types';
import { useSyncState } from 'web/src/modules/core/submodules/sync-state';

export interface ComponentSyncStateComposable {
  timeout: Ref<number>;
}

export function useComponentSyncState(
  callback: SyncStateCallback,
  timerName: SyncStateTimerName,
  options?: SyncStateOptions,
): ComponentSyncStateComposable {
  const {
    timeout,
    pauseSyncState,
    resumeSyncState,
  } = useSyncState(callback, timerName, options);

  onComponentActivated(resumeSyncState);
  onComponentDeactivated(pauseSyncState);

  return {
    timeout,
  };
}
