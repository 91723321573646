import { isNumber } from '@leon-hub/guards';

export function compareAsc(a: number | string, b: number | string): -1 | 0 | 1 {
  if (a === b) { return 0; }
  if (a < b) { return -1; }
  return 1;
}

export function compareDesc(a: number | string, b: number | string): -1 | 0 | 1 {
  if (a === b) { return 0; }
  if (a < b) { return 1; }
  return -1;
}

export function compare(
  a: Optional<Maybe<number>>,
  b: Optional<Maybe<number>>,
  options?: {
    desc?: boolean;
    nullsFirst?: boolean;
  },
): -1 | 0 | 1 {
  if (!isNumber(a)) {
    if (!isNumber(b)) { return 0; }
    return options?.nullsFirst ? -1 : 1;
  }

  if (!isNumber(b)) {
    if (!isNumber(a)) { return 0; }
    return options?.nullsFirst ? 1 : -1;
  }

  if (a === b) { return 0; }
  if (a < b) { return options?.desc ? 1 : -1; }
  return options?.desc ? -1 : 1;
}
