import { computed } from 'vue';
import type { ComputedRef } from 'vue';

import { useFormatMoney } from 'web/src/modules/money/composables';

import type { PaymentInputProps, BaseInputEmits } from '../../types';
import { setInputCursorPositionOnClick } from '../../utils';
import type { UseNumberStringInput } from '../../composables/types';
import { useNumberStringInput } from '../../composables';

interface UsePaymentInput extends Omit<UseNumberStringInput, 'inputValue'> {
  setFocusPosition: (event: MouseEvent) => void;
  displayedValue: ComputedRef<string>;
  inputMaxLength: number;
}

export default function usePaymentInput(
  props: PaymentInputProps,
  emits: BaseInputEmits,
): UsePaymentInput {
  const formatMoney = useFormatMoney();

  const paymentInputMaxLength = 12;

  const {
    uniqueId,
    isFocus,
    isEmpty,
    isHover,
    showClearButton,
    inputValue,
    onBlur,
    onChange,
    onInput,
    onFocus,
    onClear,
    onPaste,
    onMouseOver,
    onMouseLeave,
  } = useNumberStringInput(props, emits, paymentInputMaxLength);

  const displayedValue = computed<string>(() => {
    if (isFocus.value) {
      return inputValue.value;
    }
    return inputValue.value ? formatMoney(parseFloat(inputValue.value), props.formatMoneyOptions) : '';
  });

  const setFocusPosition = (event: MouseEvent): void => {
    if (isFocus.value) {
      return;
    }
    setInputCursorPositionOnClick(event, isFocus.value);
  };

  return {
    uniqueId,
    isFocus,
    isEmpty,
    isHover,
    showClearButton,
    onBlur,
    onChange,
    onInput,
    onFocus,
    onClear,
    onPaste,
    onMouseOver,
    onMouseLeave,
    displayedValue,
    setFocusPosition,
    inputMaxLength: paymentInputMaxLength,
  };
}
