import type { Optional } from '@leon-hub/types';
import { isWSSupported } from '@leon-hub/websocket';
import { defineAppModule } from '@leon-hub/app-module';
import { getLocationOrigin } from '@leon-hub/service-locator-env';

import { ActivityReporter } from './ActivityReporter';

export const useActivityReporterModule = defineAppModule('activity-reporter', (api) => {
  const urlPath = process.env.VUE_APP_WEB_SOCKET_PUBLIC_URL;
  let reporter: Optional<ActivityReporter>;
  if (urlPath && isWSSupported()) {
    const url = `${getLocationOrigin().replace(/http(s)?:/, 'ws$1:')}${urlPath}`;
    reporter = new ActivityReporter({
      activityThrottle: 10_000,
      url,
      api,
    });
  }
  return {
    async start() {
      return Promise.resolve(reporter?.start());
    },
  };
});
