import type { OptionalAnyIconName } from '@leon-hub/icons';
import { IconName } from '@leon-hub/icons';

import { ListItemAccordionKind } from '../../enums';
import type { VListItemAccordionProps } from '../../types';

export default function getAccordionIconName(props: VListItemAccordionProps): OptionalAnyIconName {
  if (props.type === ListItemAccordionKind.PROMO) {
    return props.isDone ? IconName.CHECK_FILLED : IconName.FAKE_CHECKBOX;
  }
  return props.iconName;
}
