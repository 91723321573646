import { DateInputCustomDate } from 'web/src/components/Input/enums';
import DateTime from 'web/src/utils/DateTime';

export function getMinDate(min: string | number | DateInputCustomDate | undefined): number | undefined {
  if (typeof min === 'string') {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
    if (min === DateInputCustomDate.Now) {
      return DateTime.now().toTimestamp();
    }

    return undefined;
  }

  return min;
}
