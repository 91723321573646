import createDebug from 'debug';

import {
  fetchApiSettings,
  fetchRoutingConfigs,
  fetchSiteConfigSettings,
  makeRootSettingsRequest,
  fetchDefaultNavigationConfig,
} from 'web/src/modules/core/store/utils';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import useCoreApi from 'web/src/modules/core/services/api/useCoreApi';
import { primaryKeys } from 'web/src/modules/i18n/store/primaryKeys';
import { fetchTranslations } from 'web/src/modules/i18n/store/utils';

import type { PrefetchProducer } from './types/PrefetchProducer';

let promise: undefined | Promise<PrefetchProducer>;
const debug = createDebug('nuxt');
debug('TODO: reset memoized createPrefetchProducer per ssr request');
export const createPrefetchProducer = async (): Promise<PrefetchProducer> => {
  // Memoized result.
  if (promise) return promise;
  let resolve: (value: PrefetchProducer | PromiseLike<PrefetchProducer>) => void;
  promise = new Promise((r) => { resolve = r; });
  const data: PrefetchProducer = {
    root: makeRootSettingsRequest(),
    api: fetchApiSettings(),
    siteConfig: fetchSiteConfigSettings(),
    routing: fetchRoutingConfigs(),
    navigation: fetchDefaultNavigationConfig(),
    i18n: fetchTranslations(primaryKeys),
    serverTime: useCoreApi().syncServerTime(),
  };

  // Make sure to flush all pending requests.
  await useGraphqlClient().flush({ inParallel: true });
  // Resolve only after result is resolved for next caller.
  queueMicrotask(() => resolve(data));
  return data;
};
