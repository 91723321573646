import {
  isNumber,
  isString,
  isUndefined,
  isValidInterface,
} from '@leon-hub/guards';
import type { FieldGuards } from '@leon-hub/types';

import type { CoreCompetitorResponse } from 'web/src/modules/sportline/types/rest';

import { isCompetitorType } from './isCompetitorType';

export const isCoreCompetitorResponseFieldGuards: FieldGuards<CoreCompetitorResponse> = {
  id: isNumber,
  name: isString,
  homeAway: isCompetitorType,
  type: [isUndefined, isString],
  logo: [isUndefined, isString],
};

export function isCoreCompetitorResponse(value: unknown): value is CoreCompetitorResponse {
  return isValidInterface<CoreCompetitorResponse>(value, isCoreCompetitorResponseFieldGuards);
}
