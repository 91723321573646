import { PostMessageEvent } from '@leon-hub/postmessage-bus';

export const BetgamesWidgetPostMessageBusInitiator = 'BetgamesWidget';

export default class BetgamesWidgetPostMessageEvent extends PostMessageEvent {
  static readonly init = new PostMessageEvent<{ gameHtml: string }>('betgames-widget-init');

  static readonly stateChanged = new PostMessageEvent<{ state: string }>('betgames-widget-state-changed');

  static readonly changeHeight = new PostMessageEvent<{ height: number }>('betgames-widget-change-height');

  static readonly scrollTop = new PostMessageEvent<{ scrollTop: number }>('betgames-widget-scroll-top');
}
