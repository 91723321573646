export const enum NavigationSliceType {
  FullTree = 'full-tree',
  Sport = 'sport',
  TopLeagues = 'top-leagues',
  LiveLeagues = 'live-leagues',
  Region = 'region',
  TopLeague = 'top-league',
  LiveLeague = 'live-league',
  League = 'league',
  RelatedEvents = 'related-events',
  Event = 'event',
  None = 'none',
}

export const enum SportlineNavigationSection {
  Default = 'default',
  CyberSport = 'cyber-sport',
}
