import { loadDefinedAppModule } from '../loadDefinedAppModule';

export const useActivityReporter = loadDefinedAppModule(
  () => import('@leon-hub/module-activity-reporter'),
  isActivityReporterAllowed(),
);

export function isActivityReporterAllowed(): boolean {
  if (process.env.VUE_APP_RENDERING_SSR) return false;
  if (process.env.VUE_APP_FEATURE_ACTIVITY_REPORTER_DISABLED) return false;
  return !!process.env.VUE_APP_WEB_SOCKET_PUBLIC_URL;
}
