import type {
  GetElementSize,
  VirtualListLeagueHeadlineElement,
} from 'web/src/modules/sportline/views/virtual-list-renderer/types';
import { VirtualListRendererElementSize } from 'web/src/modules/sportline/views/virtual-list-renderer/constants';

import { getLeagueVirtualElementId } from './getElementId';

export function createGetLeagueHeadlineElementSize(): GetElementSize<VirtualListLeagueHeadlineElement> {
  return (element) => ({
    id: getLeagueVirtualElementId(element.leagueElement),
    height: VirtualListRendererElementSize.LeagueHeadlineHeight
      + VirtualListRendererElementSize.LeagueHeadlineGap,
  });
}
