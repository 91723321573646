<script lang="ts" setup>
import SearchNoResultsPlaceholder
  from 'web/src/modules/search/components/SearchNoResultsPlaceholder/SearchNoResultsPlaceholder.vue';
import SearchSportResults
  from 'web/src/modules/search/submodules/sportline/components/SearchSportResults/SearchSportResults.vue';
import SearchTags from 'web/src/modules/search/components/SearchTags/SearchTags.vue';
import { VLoaderDelayed } from 'web/src/components/Loader';
import type { SearchSportlineContentEmits } from 'web/src/modules/search/submodules/sportline/views/SearchSportlineContent/types';
import {
  useSearchSportlineContent,
} from 'web/src/modules/search/submodules/sportline/views/SearchSportlineContent/composables';
import type { SearchResultContentRef } from 'web/src/modules/search/submodules/types';
import LobbyContent from 'web/src/modules/search/submodules/casino/views/CasinoSearchLobby/CasinoSearchLobby.vue';

const emits = defineEmits<SearchSportlineContentEmits>();

const {
  isNoResultsBlockVisible,
  noResultsText,
  isTagsVisible,
  searchTags,
  onTagClick,
  isLoading,
  hasSearchResults,
  liveEvents,
  prematchEvents,
  searchText,
  onEventClick,
  onInputClear,
  onInput,
} = useSearchSportlineContent(emits);

defineExpose<SearchResultContentRef>({
  onInputClear,
  onInput,
});
</script>

<template>
  <div v-auto-id="'SearchSportlineContent'">
    <SearchNoResultsPlaceholder
      v-if="isNoResultsBlockVisible"
      :text="noResultsText"
    />
    <SearchTags
      v-if="isTagsVisible"
      :tags="searchTags"
      @click="onTagClick"
    />
    <LobbyContent
      v-if="'1' && isNoResultsBlockVisible"
      is-sport
    />
    <div
      v-if="isLoading"
      :class="$style['search__loader']"
    >
      <VLoaderDelayed />
    </div>
    <SearchSportResults
      v-if="hasSearchResults"
      :live-search-results="liveEvents"
      :prematch-search-results="prematchEvents"
      :search-text="searchText"
      @click-event="onEventClick"
    />
  </div>
</template>

<style lang="scss" module>
.search {
  &__loader {
    margin-top: 36px;
  }
}
</style>
