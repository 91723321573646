import type { Ref } from 'vue';
import { computed } from 'vue';

import { useSportlineTopEventsSettings } from 'web/src/modules/sportline/composables/settings';
import type { GetSplitHeadlineEventsResponse } from 'web/src/modules/sportline/types/rest';
import type { HeadlineMatchesSportListElement } from 'web/src/modules/sportline/submodules/top/types';
import { HeadlineFactory } from 'web/src/modules/sportline/submodules/top/utils';

interface UseSeparateHeadlineListsStoreProps {
  rawResponse: Ref<Maybe<GetSplitHeadlineEventsResponse>>;
}

interface UseSeparateHeadlineListsStoreComposable {
  isReady: Ref<boolean>;
  totalLiveCount: Ref<number>;
  totalPrematchCount: Ref<number>;
  liveHeadlineElements: Ref<HeadlineMatchesSportListElement[]>;
  prematchHeadlineElements: Ref<HeadlineMatchesSportListElement[]>;
}

export function useSeparateHeadlineListsStore(
  props: UseSeparateHeadlineListsStoreProps,
): UseSeparateHeadlineListsStoreComposable {
  const { rawResponse } = props;

  const {
    parseSportlineSettings,
  } = useSportlineTopEventsSettings();

  const isReady = computed(() => rawResponse.value !== null);

  const totalLiveCount = computed<number>(() => (rawResponse.value?.liveTotal ?? 0));
  const totalPrematchCount = computed<number>(() => (rawResponse.value?.prematchTotal ?? 0));

  const liveHeadlineElements = computed<HeadlineMatchesSportListElement[]>(() => {
    const response = rawResponse.value?.live ?? null;
    if (!response) { return []; }
    return (new HeadlineFactory(response, parseSportlineSettings.value)).build();
  });
  const prematchHeadlineElements = computed<HeadlineMatchesSportListElement[]>(() => {
    const response = rawResponse.value?.prematch ?? null;
    if (!response) { return []; }
    return (new HeadlineFactory(response, parseSportlineSettings.value)).build();
  });

  return {
    isReady,
    totalLiveCount,
    totalPrematchCount,
    liveHeadlineElements,
    prematchHeadlineElements,
  };
}
