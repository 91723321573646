import type { VNode, DirectiveBinding } from 'vue';

import { isBoolean, isObject } from '@leon-hub/guards';
/* eslint-disable no-param-reassign */

interface VNodeExtended extends VNode {
  directionUpdateTimeout?: number;
}

interface CoreCreateDisplayDirectiveOptions {
  moveUp: string;
  moveDown: string;
  timeout?: number;
}

interface TargetCreateDisplayDirectiveOptions extends CoreCreateDisplayDirectiveOptions {
  upDirection: unknown;
  downDirection: unknown;
}

interface AutoCreateDisplayDirectiveOptions extends CoreCreateDisplayDirectiveOptions {
  autoDirection: true;
}

type CreateDisplayDirectiveOptions = TargetCreateDisplayDirectiveOptions | AutoCreateDisplayDirectiveOptions;

function isAutoDirectionOptions(value: unknown): value is AutoCreateDisplayDirectiveOptions {
  return isObject(value)
    && isBoolean(value.autoDirection)
    && value.autoDirection;
}

function isEqualsValues(binding: DirectiveBinding): boolean {
  return binding.value?.value === binding.oldValue?.value;
}

function isMovedUpAuto(binding: DirectiveBinding): boolean {
  const to = binding.value?.value || 0;
  const from = binding.oldValue?.value || 0;
  return to > from;
}

function isMovedDownAuto(binding: DirectiveBinding): boolean {
  const to = binding.value?.value || 0;
  const from = binding.oldValue?.value || 0;
  return to < from;
}

function createDisplayValueChangeDirective(
  options: CreateDisplayDirectiveOptions,
): (element: HTMLElement, binding: DirectiveBinding, vnode: VNodeExtended) => void {
  const { moveUp, moveDown, timeout } = options;
  const isMovedUp = isAutoDirectionOptions(options)
    ? isMovedUpAuto
    : function isMovedUpTarget(binding: DirectiveBinding): boolean {
      return binding.value?.moveTo === options.upDirection;
    };
  const isMovedDown = isAutoDirectionOptions(options)
    ? isMovedDownAuto
    : function isMovedDownTarget(binding: DirectiveBinding): boolean {
      return binding.value?.moveTo === options.downDirection;
    };

  return function componentUpdated(element: HTMLElement, binding: DirectiveBinding, vnode: VNodeExtended) {
    let changed = false;

    if (isEqualsValues(binding)) {
      return;
    }

    if (isMovedUp(binding)) {
      element.classList.add(moveUp);
      changed = true;
    } else if (isMovedDown(binding)) {
      element.classList.add(moveDown);
      changed = true;
    }

    if (changed) {
      if (vnode.directionUpdateTimeout) {
        clearTimeout(vnode.directionUpdateTimeout);
        delete vnode.directionUpdateTimeout;
      }
      vnode.directionUpdateTimeout = +setTimeout(() => {
        element.classList.remove(moveUp);
        element.classList.remove(moveDown);
      }, timeout ?? 3000);
    }
  };
}

export default createDisplayValueChangeDirective;
