import { SportFilterKickoffPeriod } from '@leon-hub/api-sdk';

import type { SportEventsSportsDateTimeFilter } from 'web/src/modules/sportline/types';
import { getSportDateTimeFilterByValue } from 'web/src/modules/sportline/utils';

export function isSportEventsSportsDateTimeFilterChanged(
  oldValue?: Maybe<SportEventsSportsDateTimeFilter>,
  newValue?: Maybe<SportEventsSportsDateTimeFilter>,
): boolean {
  const oldFilter = getSportDateTimeFilterByValue(oldValue);
  const newFilter = getSportDateTimeFilterByValue(newValue);

  if (
    oldFilter?.activeKickoffPeriod !== newFilter?.activeKickoffPeriod
    || oldFilter?.sportlineType !== newFilter?.sportlineType
  ) {
    return true;
  }

  if (oldFilter?.activeKickoffPeriod === SportFilterKickoffPeriod.CUSTOM) {
    return newFilter?.activeKickoffPeriod !== SportFilterKickoffPeriod.CUSTOM
      || oldFilter.customDateTo !== newFilter.customDateTo
      || oldFilter.customDateFrom !== newFilter.customDateFrom;
  }

  return false;
}
