import type {
  CoreSportlineFetchOptions,
  EventsChangesFetchOptions,
  GetSportEventsResponse,
  GetSportEventsChangesResponse,
} from 'web/src/modules/sportline/types/rest';
import type { UseSportlineApiLoadEventsProps } from 'web/src/modules/sportline/services/composables/types';
import { BetlineType } from 'web/src/modules/sportline/enums/rest';
import { getEvents, getEventsChanges } from 'web/src/modules/sportline/utils/api';

interface UseSportlineApiLoadZeroMarginEventsComposable {
  loadZeroMarginEvents(
    options: CoreSportlineFetchOptions & EventsChangesFetchOptions,
  ): Promise<Maybe<GetSportEventsResponse | GetSportEventsChangesResponse>>;
}

export function useSportlineApiLoadZeroMarginEvents(
  props: UseSportlineApiLoadEventsProps,
): UseSportlineApiLoadZeroMarginEventsComposable {
  const {
    tryRequestWithCTag,
    defaultRestApiFlags,
    isZeroMarginEnabled,
    isLiveOutrightsEnabled,
  } = props;

  // @TODO make single solution for merge betline events requests
  let loadZeroMarginEventsPending: Maybe<Promise<Maybe<GetSportEventsResponse>>> = null;
  let loadZeroMarginEventsChangesPending: Maybe<Promise<Maybe<GetSportEventsChangesResponse>>> = null;

  async function loadZeroMarginEvents(
    options: CoreSportlineFetchOptions & EventsChangesFetchOptions,
  ): Promise<Maybe<GetSportEventsResponse | GetSportEventsChangesResponse>> {
    if (!isZeroMarginEnabled.value) {
      return null;
    }

    const {
      vTag,
      silent,
      limit,
    } = options;
    let result: Maybe<GetSportEventsResponse | GetSportEventsChangesResponse> = null;

    // Make update request
    if (vTag) {
      if (!loadZeroMarginEventsChangesPending) {
        loadZeroMarginEventsChangesPending = new Promise((resolve, reject) => {
          tryRequestWithCTag((ctag) => getEventsChanges({
            betline: BetlineType.All,
            ctag,
            vtag: vTag,
            silent,
            limit,
            hideClosed: true,
            zeroMarginMarkets: true,
            flags: [...defaultRestApiFlags.value],
            LMCE: isLiveOutrightsEnabled.value,
          })).finally(() => {
            loadZeroMarginEventsChangesPending = null;
          }).then(resolve).catch(reject);
        });
      }

      result = await loadZeroMarginEventsChangesPending;
    }

    // get data if updates is not loaded
    if (!vTag || result?.vtag === 'invalid') {
      if (!loadZeroMarginEventsPending) {
        loadZeroMarginEventsPending = new Promise((resolve, reject) => {
          tryRequestWithCTag((ctag) => getEvents({
            betline: BetlineType.All,
            ctag,
            silent,
            limit,
            hideClosed: true,
            zeroMarginMarkets: true,
            flags: [...defaultRestApiFlags.value],
            LMCE: isLiveOutrightsEnabled.value,
          })).finally(() => {
            loadZeroMarginEventsPending = null;
          }).then(resolve).catch(reject);
        });
      }

      result = await loadZeroMarginEventsPending;
    }

    return result;
  }

  return { loadZeroMarginEvents };
}
