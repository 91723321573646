import type { Ref } from 'vue';
import { computed } from 'vue';

import type { RecycleScrollerItemOptions } from '../types';
import { RecycleScrollerDirection } from '../enums';
import { getTotalItemOptionsSizeValue } from './getItemSizeValue';

export function createComputedTotalSize(
  source: Ref<RecycleScrollerItemOptions[]>,
  direction: RecycleScrollerDirection = RecycleScrollerDirection.VERTICAL,
): Ref<number> {
  return computed(() => getTotalItemOptionsSizeValue(source.value, direction));
}
