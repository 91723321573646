import type { Ref } from 'vue';
import {
  computed,
} from 'vue';

import type { VButtonProps } from 'web/src/components/Button/VButton/types';
import {
  ButtonHeight,
  ButtonKind,
} from 'web/src/components/Button/VButton/enums';
import { useI18n } from 'web/src/modules/i18n/composables';

import type { CasinoGameDescriptionProps } from '../types';

const maxDescriptionLength = 120;

export interface CasinoGameDescriptionComposable {
  isButtonVisible: Ref<boolean>;
  gameDescription: Ref<string>;
  buttonProperties: Ref<VButtonProps>;
}

export default function useCasinoGameDescription(props: CasinoGameDescriptionProps): CasinoGameDescriptionComposable {
  const { $translate } = useI18n();

  const isButtonVisible = computed(
    () => !process.env.VUE_APP_LAYOUT_DESKTOP && props.game.description.length > maxDescriptionLength,
  );

  const gameDescription = computed(() => {
    if (process.env.VUE_APP_LAYOUT_DESKTOP || props.isOpen || !isButtonVisible.value) {
      return props.game.description;
    }
    return `${props.game.description.slice(0, maxDescriptionLength)}...`;
  });

  const buttonProperties = computed<VButtonProps>(() => ({
    isUppercase: false,
    kind: ButtonKind.BASE,
    height: ButtonHeight.MEDIUM,
    label: props.isOpen ? $translate('WEB2_LABEL_HIDE').value : $translate('WEB2_SHOW_MORE').value,
  }));

  return {
    isButtonVisible,
    gameDescription,
    buttonProperties,
  };
}
