import type { Ref } from 'vue';
import { computed } from 'vue';

import type { LeagueElement, RegionElement } from 'web/src/modules/sportline/types';

interface UseSportlineRegionLeagueElementsProps {
  regionElement: Ref<RegionElement>;
  isDisplayOutrightEventsEnabled: Ref<boolean>;
}

interface UseSportlineRegionLeagueElementsComposable {
  leagueElements: Ref<LeagueElement[]>;
}

export function useSportlineRegionLeagueElements(
  props: UseSportlineRegionLeagueElementsProps,
): UseSportlineRegionLeagueElementsComposable {
  const { regionElement, isDisplayOutrightEventsEnabled } = props;

  const leagueElements = computed<LeagueElement[]>(() => (isDisplayOutrightEventsEnabled.value
    ? regionElement.value.leagues
    : regionElement.value.leagues.filter((element) => element.sportEvents.length > 0)));

  return {
    leagueElements,
  };
}
