export default function windowOpen(url: string, target = '_blank'): Maybe<Window> {
  if (!process.env.VUE_APP_RENDERING_SSR && url) {
    if (process.env.VUE_APP_PLATFORM === 'cordova') {
      return window.open(url, target);
    }

    if (process.env.VUE_APP_LAYOUT === 'desktop') {
      const width = 880;
      const height = 900;
      const leftPos = (window.screen.width - width) / 2;
      const topPos = (window.screen.height - height) / 2 - 60;
      return window.open(
        url,
        target,
        `left=${leftPos},top=${topPos},location=1,scrollbars=0,resizable=1,width=${width},height=${height}`,
      );
    }
    return window.open(url);
  }

  return null;
}
