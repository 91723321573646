import type SocketWrapper from './SocketWrapper';

export default class SocketEmitter<T extends { type: string; payload: object }> {
  constructor(private socketWrapper: SocketWrapper) {}

  async emit(type: T['type'], payload: T['payload']): Promise<void> {
    const data = {
      type,
      payload,
    };

    await this.socketWrapper.send(data);
  }
}
