import type { Ref } from 'vue';
import {
  onActivated,
  onBeforeUnmount,
  onDeactivated,
  onMounted,
  watch,
} from 'vue';

import { useDebounce } from '@leon-hub/debounce';
import { getViewportDimension } from '@leon-hub/utils';

export interface WindowResizeDelta {
  deltaX: number;
  deltaY: number;
  viewportWidth: number;
  viewportHeight: number;
}

interface WindowResizeOptions {
  debounce?: number | null;
  condition?: Ref<boolean | undefined>;
}

type WindowResizeCallback = (delta: WindowResizeDelta) => (void | Promise<void>);

export function useWindowResize(callback: WindowResizeCallback, options: WindowResizeOptions = {}): void {
  let hasActiveListener = false;
  let dimensions = getViewportDimension();

  function resizeCallbackInner(): void {
    const newDimensions = getViewportDimension();
    void callback({
      deltaX: dimensions.viewportWidth - newDimensions.viewportWidth,
      deltaY: dimensions.viewportHeight - newDimensions.viewportHeight,
      viewportHeight: newDimensions.viewportHeight,
      viewportWidth: newDimensions.viewportWidth,
    });
    dimensions = newDimensions;
  }

  const resizeCallback = options.debounce ? useDebounce(resizeCallbackInner) : resizeCallbackInner;

  function addEventListener(): void {
    if (!hasActiveListener) {
      dimensions = getViewportDimension();
      window.addEventListener('resize', resizeCallback);
      hasActiveListener = true;
    }
  }

  function removeEventListener(): void {
    if (hasActiveListener) {
      window.removeEventListener('resize', resizeCallback);
      hasActiveListener = false;
    }
  }

  if (options.condition) {
    watch(options.condition, (newValue) => {
      if (newValue) {
        addEventListener();
      } else {
        removeEventListener();
      }
    }, { immediate: true });
  } else {
    onMounted(addEventListener);
    onActivated(addEventListener);
  }

  onBeforeUnmount(removeEventListener);
  onDeactivated(removeEventListener);
}
