import type { OpenInCatalogParameters } from '../types';

const idToParamMapping: Record<keyof OpenInCatalogParameters, string> = {
  marketId: 'market',
  eventId: 'event',
  leagueId: 'league',
  regionId: 'region',
  sportId: 'sport',
};

export function buildOpenInCatalogParameters(params: OpenInCatalogParameters): string {
  const result = Object.keys(params)
    .map((id: keyof OpenInCatalogParameters): Maybe<string> => (idToParamMapping[id] ? `${idToParamMapping[id]}=${params[id]}` : null))
    .filter((value: Maybe<string>): value is string => !!value)
    .join('&');

  return result ? `?${result}` : '';
}
