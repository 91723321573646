export enum SystemIconName {
  // ACCESS_TIME = 'access-time',
  ANGLE_SMALL_DOWN = 'angle-small-down',
  ANGLE_SMALL_UP = 'angle-small-up',
  ANGLE_SMALL_LEFT = 'angle-small-left',
  ANGLE_SMALL_RIGHT = 'angle-small-right',
  ACCOUNT_BOX = 'account-box',
  ADD = 'add',
  ADD_PHOTO = 'add-photo',
  ADULT = 'adult',
  ALARM = 'alarm',
  ALARM_FILL = 'alarm-fill',
  ARROW_DOWNWARD = 'arrow-downward',
  ARROW_LEFT = 'arrow-left',
  ARROW_ROUNDED_LEFT = 'arrow-rounded-left',
  ARROW_ROUNDED_RIGHT = 'arrow-rounded-right',
  ARROW_SQUARE_LEFT = 'arrow-square-left',
  ARROW_SQUARE_RIGHT = 'arrow-square-right',
  ARROW_UPWARD = 'arrow-upward',
  ASSIGNMENT = 'assignment',
  ATTENTION = 'attention',
  ATTENTION_OUTLINE = 'attention-outline',
  BACKSPACE = 'backspace',
  BANKER = 'banker',
  BELL = 'bell',
  BELL_ON = 'bell-on',
  BELL_OFF = 'bell-off',
  BIN = 'bin',
  BLOCKING = 'blocking',
  BOT = 'bot',
  BONUSES = 'bonuses',
  BOOK_TURN_PHONE = 'book-turn-phone',
  BRAND_LOGO = 'brand-logo',
  BROWSER = 'browser',
  BRIGHTNESS_AUTO = 'brightness-auto',
  CAMERA = 'camera',
  CANCEL = 'cancel',
  CHAT = 'chat',
  CHAT_PROCESSING = 'chat-processing',
  CHAT_PROCESSING_OUTLINE = 'chat-processing-outline',
  CHECK = 'check',
  CHECK_ROUNDED = 'check-rounded',
  CHECK_OUTLINE = 'check-outline',
  CHECK_FILLED = 'check-filled',
  CHECKBOX_MULTIPLE = 'checkbox-multiple',
  CHIP = 'chip',
  CLEAR_CACHE = 'clear-cache',
  CLIP = 'clip',
  CLIPBOARD_CHECK = 'clipboard-check',
  CLOCK = 'clock',
  COG = 'cog',
  COIN = 'coin',
  CONNECTION_LOST = 'connection-lost',
  CROSS = 'cross',
  CROSS_SMALL_BOLD = 'cross-small-bold',
  CUP = 'cup',
  DATABASE_LOCK = 'database-lock',
  DATE_RANGE = 'date-range',
  DAY = 'day',
  DISLIKE = 'dislike',
  DOCUMENT = 'document',
  DOCUMENT_ADD = 'document-add',
  DOT = 'dot',
  DURATION_ALT = 'duration-alt',
  DROPDOWN = 'dropdown',
  DROPDOWN_ACTIVE = 'dropdown-active',
  DOWNLOAD_2 = 'download-2',
  EMOTICON_OUTLINE = 'emoticon-outline',
  EMOTICON_NEUTRAL_OUTLINE = 'emoticon-neutral-outline',
  EMOTICON_HAPPY_OUTLINE = 'emoticon-happy-outline',
  EMOTICON_CRY_OUTLINE = 'emoticon-cry-outline',
  EXPAND_DOWN = 'expand-down',
  EXPAND_LEFT = 'expand-left',
  EXPAND_RIGHT = 'expand-right',
  EXPAND_UP = 'expand-up',
  EURO_CUP = 'euro-cup',
  FAKE_CHECKBOX = 'fake-checkbox',
  FAST_GAMES = 'fastgames',
  FB_CIRCLE = 'fb-circle',
  FIELD = 'field',
  FILES = 'files',
  FILTER = 'filter',
  FILE_PDF = 'file-pdf',
  FILE_DOWNLOAD = 'file-download',
  FULLSCREEN = 'fullscreen',
  FULLSCREEN_ON = 'fullscreen-on',
  GEARS = 'gears',
  GLOBE = 'globe',
  HAMBURGER = 'hamburger',
  HAMBURGER_FULL = 'hamburger-full',
  HEART = 'heart',
  HEART_OUTLINE = 'heart-outline',
  HEART_PULSE = 'heart-pulse',
  HELP = 'help',
  HELP_BUBBLE = 'help-bubble',
  HISTORY = 'history',
  HOME = 'home',
  HOME_ROUNDED = 'home-rounded',
  INFO = 'info',
  INFO_OUTLINE = 'info-outline',
  KEY = 'key',
  KEYBOARD_HIDE = 'keyboard-hide',
  LEONS = 'leons',
  LIKE = 'like',
  LOCK = 'lock',
  MAIL = 'mail',
  MONEY_ON = 'money-on',
  MONEY_OFF = 'money-off',
  MORE = 'more',
  MINUS_SMALL = 'minus-small',
  NEWS = 'news',
  NIGHT = 'night',
  NOTIFICATION_DOT = 'notification-dot',
  NOTIFICATION_NEW = 'notification-new',
  NOTIFICATION_NO = 'notification-no',
  NOTIFICATION_PUSH = 'notification-push',
  OK_CIRCLE = 'ok-circle',
  OPEN_IN_NEW = 'open-in-new',
  OS_ANDROID = 'os-android',
  OS_IOS = 'os-ios',
  ODDS = 'odds',
  PAPERPLANE = 'paperplane',
  PARTIAL_SELF_RESTRAINT = 'partial-self-restraint',
  PARTNERS = 'partners',
  PAUSE_CIRCLE = 'pause-circle',
  PAYING = 'paying',
  PAYOUT = 'payout',
  PENCIL = 'pencil',
  PEOPLE = 'people',
  PERSON = 'person',
  PERSON_FILL = 'person-fill',
  PERSON_FILL_LEFT = 'person-fill-left',
  PHONE = 'phone',
  PLATE_SCORE = 'plate-score',
  PLUS = 'plus',
  PLUS_SMALL = 'plus-small',
  PROMOS = 'promos',
  PROMOS_NO = 'promos-no',
  QUESTION_OUTLINE = 'question-outline',
  REDEEM = 'redeem',
  SCORE = 'score',
  SEARCH = 'search',
  SELF_EXCLUSION = 'self-exclusion',
  SELF_EXCLUSION_6 = 'self-exclusion-6',
  SHARE = 'share',
  SHIELD = 'shield',
  SHIELD_ACCOUNT_VARIANT = 'shield-account-variant',
  SHIELD_OUTLINE = 'shield-outline',
  SHIELD_SYNC = 'shield-sync',
  SHIELD_LOCK = 'shield-lock',
  SHOP = 'shop',
  SMS = 'sms',
  SPEEDOMETER = 'speedometer',
  SPORTLINE = 'sportline',
  STAR = 'star',
  STAR_OUTLINE = 'star-outline',
  STAR_SHOOTING_OUTLINE = 'star-shooting-outline',
  STATISTIC = 'statistic',
  STREAM = 'stream',
  STREAM_ROUNDED = 'stream-rounded',
  SUPPORT = 'support',
  SUPPORT_OUTLINE = 'support-outline',
  TELEGRAM = 'telegram',
  TELEGRAM_CIRCLE = 'telegram-circle',
  TICKET_CONFIRMATION = 'ticket-confirmation',
  TIMER = 'timer',
  TIMER_SAND = 'timer-sand',
  TIMER_2_STEP = 'timer2step',
  TOP = 'top',
  TRIANGLE_DOWN = 'triangle-down',
  TRIANGLE_UP = 'triangle-up',
  VERIFICATION = 'verification',
  VIDEO_CALL = 'video-call',
  VIDEO_CAMERA = 'videocamera',
  VIDEO_CAMERA_OFF = 'videocamera-off',
  VIEW_BOX = 'view-box',
  VIEW_LIST = 'view-list',
  VISIBILITY_OFF = 'visibility-off',
  VISIBILITY_OFF_OUTLINE = 'visibility-off-outline',
  VISIBILITY_ON = 'visibility-on',
  VISIBILITY_ON_OUTLINE = 'visibility-on-outline',
  VK_CIRCLE = 'vk-circle',
  VOLUME_ON = 'volume-on',
  VOLUME_OFF = 'volume-off',
  WALLET = 'wallet',
  WARNING = 'warning',
  WEBSITE_NOT_AVAILABLE = 'website-not-available',
  ZOOM_IN = 'zoom-in',
  FIRE = 'fire',
  FINGERPRINT = 'fingerprint',
  FIELD_CORDOVA = 'field-cordova',
  STREAM_CORDOVA = 'stream-cordova',
  SCORE_CORDOVA = 'score-cordova',
  LIGHTNING = 'lightning',
  QR_CODE = 'qr-code',
  LOCATION_RESTRICTED = 'location-restricted',
  GAME_ERROR = 'game-error',
  CASHBACK = 'cashback',
}
