import type { ComputedRef, Ref } from 'vue';
import { computed, toRef } from 'vue';

import type { VButtonProps } from 'web/src/components/Button/VButton/types';
import type { VStepDescriptionProps } from 'web/src/components/StepDescription/VStepDescription/types';
import type {
  ReferralProgramPopupRewardProps,
} from 'web/src/modules/referral-program/components/ReferralProgramPopupReward/types';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useI18nStore } from 'web/src/modules/i18n/store';
import { useCurrency, useFormatMoney } from 'web/src/modules/money/composables';
import { StepDescriptionKind, StepDescriptionSize } from 'web/src/components/StepDescription/VStepDescription/enums';

import getPlayerReward from '../../../utils/referralProgramPopup/getPlayerReward';
import type { ReferralProgramRegistrationBodyProps, ReferralProgramRegistrationBodyEmits } from '../types';

export interface ReferralProgramRegistrationBodyComposable {
  stepDescriptionProps: ComputedRef<VStepDescriptionProps[]>;
  buttonProps: ComputedRef<VButtonProps>;
  referralProgramPopupRewardProps: ComputedRef<ReferralProgramPopupRewardProps>;
  registrationDescription: Ref<string>;
  registrationRule: Ref<string>;
  registrationTitle: Ref<string>;
  handleClick: () => void;
}

export default function useReferralProgramRegistrationBody(
  props: ReferralProgramRegistrationBodyProps,
  emit: ReferralProgramRegistrationBodyEmits,
): ReferralProgramRegistrationBodyComposable {
  const { $translate } = useI18n();
  const i18nStore = useI18nStore();
  const formatMoney = useFormatMoney();

  const currency = toRef(useCurrency(), 'currency');

  const depositKeys = computed<Record<string, string>>(() => ({
    count: String(formatMoney(Number(props.rewards?.[0].depositAmount) || 0, {
      currency: props.currency || currency.value,
    })),
  }));

  const referralProgramPopupRewardProps = computed<ReferralProgramPopupRewardProps>(() => ({
    referralReward: true,
    table: getPlayerReward(
      props.rewards ?? null,
      $translate,
      formatMoney,
      i18nStore.locale,
      props.currency || currency.value,
    ),
  }));

  const stepSize = toRef(() => {
    if (process.env.VUE_APP_STYLE_TWIN) {
      return StepDescriptionSize.SIZE_32;
    }

    if (process.env.VUE_APP_FEATURE_SLOTT_STYLE_COMPONENTS_ENABLED) {
      return StepDescriptionSize.SIZE_40;
    }
    return StepDescriptionSize.SIZE_24;
  });

  const stepDescriptionProps = computed<VStepDescriptionProps[]>(() => ([
    {
      step: '1',
      label: $translate('WEB2_REFERRAL_REGISTRATION_STEP_1').value,
      kind: process.env.VUE_APP_SKIN_TWIN ? StepDescriptionKind.BLUE : StepDescriptionKind.HIGHLIGHT,
      size: stepSize.value,
    },
    {
      step: '2',
      label: $translate('WEB2_REFERRAL_REGISTRATION_STEP_2', depositKeys).value,
      kind: process.env.VUE_APP_SKIN_TWIN ? StepDescriptionKind.BLUE : StepDescriptionKind.HIGHLIGHT,
      size: stepSize.value,
    },
    {
      step: '3',
      label: $translate('WEB2_REFERRAL_REGISTRATION_STEP_3').value,
      kind: process.env.VUE_APP_SKIN_TWIN ? StepDescriptionKind.BLUE : StepDescriptionKind.HIGHLIGHT,
      size: stepSize.value,
    },
  ]));

  const buttonProps = computed<VButtonProps>(() => ({
    label: $translate('WEB2_REFERRAL_REGISTRATION_CONTINUE').value,
    fullWidth: true,
  }));

  const registrationDescription = $translate('WEB2_REFERRAL_REGISTRATION_DESCRIPTION');
  const registrationRule = $translate('WEB2_REFERRAL_REGISTRATION_RULE');
  const registrationTitle = $translate('WEB2_REFERRAL_REGISTRATION_STEP_TITLE');

  const handleClick = (): void => {
    emit('click');
  };

  return {
    stepDescriptionProps,
    buttonProps,
    referralProgramPopupRewardProps,
    registrationDescription,
    registrationRule,
    registrationTitle,
    handleClick,
  };
}
