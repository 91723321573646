import type { Ref, MaybeRef, WatchStopHandle } from 'vue';
import {
  computed,
  ref,
  toRef,
  customRef,
  watch,
} from 'vue';

import { useLocalStorageManager } from '@leon-hub/local-storage';

import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import useTimeBus from 'web/src/modules/core/composables/time-bus/useTimeBus';
import type { SportlineEventId } from 'web/src/modules/sportline/types';

type VisitTime = Optional<number>;

type ClearAfter = number;

type PreviewTimestampMap = Record<SportlineEventId, Exclude<VisitTime, undefined>>;

interface UseStreamPreviewStoreComposableProps {
  sportlineEventId: MaybeRef<Maybe<SportlineEventId>>;
  /** How long is the preview in seconds. default is 15 */
  previewDuration?: MaybeRef<number>;
  /** Kill old data after seconds. Default is a week (604800 = 60 * 60 * 24 * 7) */
  clearAfter?: MaybeRef<ClearAfter>;
}

interface UseStreamPreviewStoreComposable {
  visitTime: Ref<VisitTime>;
  isRunningPreviewTimeout: Ref<boolean>;
  doShowStreamPreviewInfo: Ref<boolean>;
  startPreview(): void;
  stopPreview(): void;
}

export const localStorageKey = 'sportline-stream-preview';

function createVisitTimestampRef(
  sportlineEventId: Ref<Maybe<SportlineEventId>>,
  now: Ref<number>,
  clearAfter: Ref<ClearAfter>,
): Ref<VisitTime> {
  const localStorageManager = useLocalStorageManager();

  function getPreviewTimestampMapFromStorage(): PreviewTimestampMap {
    const storedState = localStorageManager.getItem(localStorageKey);
    return storedState ? JSON.parse(storedState) : {};
  }

  const previewTimestampMap = ref(getPreviewTimestampMapFromStorage());

  function setValueToMap(key: SportlineEventId, value: VisitTime, map: PreviewTimestampMap): PreviewTimestampMap {
    const newMap = { ...map };

    if (value !== undefined) {
      newMap[key] = value;
    } else {
      delete newMap[key];
    }

    return newMap;
  }

  function clearOldValuesInMap(map: PreviewTimestampMap): PreviewTimestampMap {
    const newMap = { ...map };

    for (const key of Object.keys(newMap)) {
      const value = newMap[key];

      if (now.value - value >= clearAfter.value * 1000) {
        delete newMap[key];
      }
    }

    return newMap;
  }

  return customRef((track, trigger) => ({
    get(): VisitTime {
      track();
      const id = sportlineEventId.value;
      if (!id) { return undefined; }
      return previewTimestampMap.value[id];
    },
    set(value: VisitTime): void {
      const id = sportlineEventId.value;

      if (!id) { return; }

      // get value from the local storage again to keep synchronized several composables at once
      previewTimestampMap.value = clearOldValuesInMap(setValueToMap(id, value, getPreviewTimestampMapFromStorage()));
      localStorageManager.setItem(localStorageKey, JSON.stringify(previewTimestampMap.value));

      trigger();
    },
  }));
}

export function useStreamPreviewStoreComposable(
  props: UseStreamPreviewStoreComposableProps,
): UseStreamPreviewStoreComposable {
  const sportlineEventId = toRef(props.sportlineEventId);
  const previewDuration = toRef(props.previewDuration ?? 15);
  const clearAfter = toRef(props.clearAfter ?? 60 * 60 * 24 * 7);

  const { isLoggedIn } = useIsLoggedIn();

  const {
    now,
    isRunning,
    start,
    stop,
    forceUpdateNow,
  } = useTimeBus();

  const visitTime = createVisitTimestampRef(sportlineEventId, now, clearAfter);

  const doShowStreamPreviewInfo = computed(() => (
    !isLoggedIn.value && visitTime.value !== undefined
      ? now.value - visitTime.value >= (previewDuration.value * 1000)
      : false
  ));

  let stopWatch: Optional<WatchStopHandle>;

  function stopWatchPreviewShown(): void {
    stop();
    stopWatch?.();
    stopWatch = undefined;
  }

  function startWatchPreviewShown(): void {
    if (!isRunning.value) {
      start();
    }

    stopWatch = stopWatch ?? watch([doShowStreamPreviewInfo, sportlineEventId], ([isShown, id], [, oldId]) => {
      if (oldId && id !== oldId) {
        stopWatchPreviewShown();
        return;
      }

      if (isShown) {
        stopWatchPreviewShown();
      }
    }, { immediate: true });
  }

  return {
    visitTime,
    isRunningPreviewTimeout: isRunning,
    doShowStreamPreviewInfo,
    startPreview(): void {
      forceUpdateNow();

      // trigger clearing old data
      visitTime.value = visitTime.value ?? now.value;

      if (!doShowStreamPreviewInfo.value) {
        startWatchPreviewShown();
      }
    },
    stopPreview(): void {
      stop();
    },
  };
}
