import { defineStore } from 'pinia';
import { ref } from 'vue';

import type { PushNotificationSettingsRequest } from '@leon-hub/api-sdk';
import {
  getPushSettings,
  savePushSettings,
} from '@leon-hub/api-sdk';
import { useLocalStorageManager } from '@leon-hub/local-storage';

import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';

const PUSH_BETS_SETTING_SAVED_STORAGE_KEY = 'push-bets-setting-saved';

const usePushNotificationSettingsStore = defineStore('push-notification-settings', () => {
  const api = useGraphqlClient();
  const localStorage = useLocalStorageManager();

  const isBetOutcomePushEnabled = ref(false);
  const isWinOnlyBetOutcomePushEnabled = ref(false);

  async function fetchSettings(): Promise<void> {
    const data = await getPushSettings(api, (node) => node.queries.customer.getPushNotificationSettings);

    isBetOutcomePushEnabled.value = data.isBetOutcomePushEnabled;
    isWinOnlyBetOutcomePushEnabled.value = data.isWinOnlyBetOutcomePushEnabled;
  }

  async function saveSettings(options: PushNotificationSettingsRequest): Promise<void> {
    isBetOutcomePushEnabled.value = options.isBetOutcomePushEnabled;
    isWinOnlyBetOutcomePushEnabled.value = options.isWinOnlyBetOutcomePushEnabled;

    await savePushSettings(api,
      (node) => node.mutations.customer.savePushNotificationSettings,
      {
        options,
      });

    if (options.isBetOutcomePushEnabled) {
      localStorage.setItem(PUSH_BETS_SETTING_SAVED_STORAGE_KEY, '1');
    }
  }

  function wasSaved(): boolean {
    return !!localStorage.getItem(PUSH_BETS_SETTING_SAVED_STORAGE_KEY);
  }

  function toggleBetOutcomePush(): Promise<void> {
    return saveSettings({
      isWinOnlyBetOutcomePushEnabled: isWinOnlyBetOutcomePushEnabled.value,
      isBetOutcomePushEnabled: !isBetOutcomePushEnabled.value,
    });
  }

  function saveIsWinOnlyBetOutcomePushEnabled(value: boolean): Promise<void> {
    return saveSettings({
      isWinOnlyBetOutcomePushEnabled: value,
      isBetOutcomePushEnabled: isBetOutcomePushEnabled.value,
    });
  }

  return {
    isBetOutcomePushEnabled,
    isWinOnlyBetOutcomePushEnabled,
    fetchSettings,
    saveSettings,
    wasSaved,
    toggleBetOutcomePush,
    saveIsWinOnlyBetOutcomePushEnabled,
  };
});

export default usePushNotificationSettingsStore;
