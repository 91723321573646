import type { Ref } from 'vue';
import { computed } from 'vue';
import { useRouter } from 'vue-router';

import { useSeoMetaParameters } from 'web/src/modules/seo/composables';
import type { SeoMetaParametersConfig } from 'web/src/modules/seo/types';
import type { SportEventSeoMetaInfo } from 'web/src/modules/sportline/types';
import type { HeadlineMatchesSportListElement } from 'web/src/modules/sportline/submodules/top/types';
import { getSportEventSeoMetaInfo } from 'web/src/modules/sportline/utils';

interface UseHomeSportlineElementSeoMetaProps {
  sportElements: Ref<HeadlineMatchesSportListElement[]>;
}

export function useHomeSportlineElementSeoMeta(
  props: UseHomeSportlineElementSeoMetaProps,
): void {
  const { sportElements } = props;

  const router = useRouter();

  const metaParameters = computed<SeoMetaParametersConfig>(() => ({
    sportEvents: {
      value: sportElements.value.flatMap((sportElement: HeadlineMatchesSportListElement) => sportElement
        .sportEvents.map((sportEventElement) => getSportEventSeoMetaInfo({
          sportEvent: sportEventElement.sportEvent,
          sport: sportElement.sport,
          region: sportEventElement.region,
          league: sportEventElement.league,
          vm: { $router: router },
        }))),
      merge(oldValue: SportEventSeoMetaInfo[], newValue: SportEventSeoMetaInfo[]) {
        return [...oldValue, ...newValue];
      },
    },
  }));

  useSeoMetaParameters(metaParameters);
}
