import { defineStore } from 'pinia';
import {
  ref,
  toRef,
} from 'vue';

import { isTheme } from '@leon-hub/api-guards';
import { isEmpty } from '@leon-hub/guards';
import { Theme } from '@leon-hub/api-sdk';
import { getBuiltinConfig } from '@leon-hub/service-locator-env';
import { useLocalStorageManager } from '@leon-hub/local-storage';

import { useSiteConfigStore } from './useSiteConfigStore';
import { StorageKeys } from './enums';
import type { ThemeConfiguration } from './types';

// eslint-disable-next-line sonarjs/cognitive-complexity
const useInitAppConfigStore = defineStore('init-app-config', () => {
  const themeConfig = ref<ThemeConfiguration>({
    default: Theme.DARK,
    isAuto: false,
  });
  const localStorageManager = useLocalStorageManager();

  if (process.env.VUE_APP_PLATFORM_CORDOVA) {
    const siteConfigStore = useSiteConfigStore();
    const themes = toRef(siteConfigStore, 'themes');
    const { id: defaultTheme } = themes.value
      .find((theme) => theme.isDefault) ?? {};

    themeConfig.value.default = defaultTheme ?? (process.env.VUE_APP_STYLE_SLOTT ? Theme.LIGHT : Theme.DARK);
    themeConfig.value.first = localStorageManager.getItem(StorageKeys.FIRST_THEME)?.toUpperCase();
    themeConfig.value.current = localStorageManager.getItem(StorageKeys.THEME)?.toUpperCase();
    themeConfig.value.isAuto = isEmpty(localStorageManager.getItem(StorageKeys.IS_AUTO_THEME))
      ? false
      : localStorageManager.getItem(StorageKeys.IS_AUTO_THEME) === 'true';
  } else {
    const initConfig = getBuiltinConfig();

    const defaultTheme = initConfig?.theme.default.toUpperCase();
    if (isTheme(defaultTheme)) {
      themeConfig.value.default = defaultTheme;
    }

    themeConfig.value.first = initConfig?.theme.first?.toUpperCase()
      || localStorageManager.getItem(StorageKeys.FIRST_THEME)?.toUpperCase();
    if (process.env.NODE_ENV === 'production') {
      themeConfig.value.current = localStorageManager.getItem(StorageKeys.THEME)
        || initConfig?.theme.current?.toUpperCase()
        || themeConfig.value.default;
    } else {
      themeConfig.value.current = localStorageManager.getItem(StorageKeys.THEME)?.toUpperCase()
        || themeConfig.value.default;
    }
    themeConfig.value.isAuto = isEmpty(localStorageManager.getItem(StorageKeys.IS_AUTO_THEME))
      ? false
      : localStorageManager.getItem(StorageKeys.IS_AUTO_THEME) === 'true';
  }

  return {
    themeConfig,
  };
});

export default useInitAppConfigStore;
