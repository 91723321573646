<script lang="ts" setup>
import { ObserveVisibility } from 'vue3-observe-visibility';

import VSwiper from 'web/src/components/Swiper/VSwiper/VSwiper';
import LobbyItem from 'web/src/modules/lobby/components/LobbyItem/LobbyItem.vue';
import VSwiperSlide from 'web/src/components/Swiper/VSwiperSlide/VSwiperSlide.vue';
import type { LobbySwiperProps, LobbySwiperRef } from 'web/src/modules/lobby/components/LobbySwiper/types';
import { useLobbySwiper } from 'web/src/modules/lobby/components/LobbySwiper/composables';
import type { LobbyGridEmits } from 'web/src/modules/lobby/components/LobbyGrid/types';

const props = defineProps<LobbySwiperProps>();
const emits = defineEmits<LobbyGridEmits>();

const {
  swiper,
  gridType,
  slideGridType,
  swiperProps,
  emitSwiperScroll,
  onVisibilityChanged,
  swiperItems,
  isOverflowHidden,
  getItemKey,
  getLobbyItemProps,
  emitClickLobbyItem,
  gridSize,
  resetScrollPosition,
} = useLobbySwiper(props, emits);

defineExpose<LobbySwiperRef>({
  resetScrollPosition,
});

const vObserveVisibility = ObserveVisibility;
</script>

<template>
  <div v-auto-id="'LobbySwiper'"
    v-observe-visibility="{
      callback: onVisibilityChanged,
      once: true,
      rootMargin: '100px 100px 100px 100px',
    }"
    :class="{
      [$style['lobby-swiper']]: true,
      [$style[`lobby-swiper--type-${gridType}`]]: !isSwiperBlock,
      [$style[`lobby-swiper--grid-type-${slideGridType}`]]: true,
      [$style['lobby-swiper--block']]: isSwiperBlock,
      [$style['lobby-swiper--overflow-hidden']]: isOverflowHidden,
    }"
  >
    <VSwiper
      ref="swiper"
      v-bind="swiperProps"
      :wrapper-class="{
        [$style['lobby-swiper__swiper-wrapper']]: true,
        [$style['lobby-swiper__swiper-wrapper--modal']]: isModal,
        [$style['lobby-swiper__swiper-wrapper--no-background']]: noBackground,
      }"
      @scroll="emitSwiperScroll"
    >
      <template #pagination-header>
        <slot name="header" />
      </template>
      <VSwiperSlide
        v-for="(slideItem, slideIndex) in swiperItems"
        :key="slideIndex"
        :class="{
          [$style['lobby-swiper__slide']]: true,
          [$style['lobby-swiper__slide--modal']]: isModal,
          [$style['lobby-swiper__slide--no-background']]: noBackground,
        }"
      >
        <div :class="$style['lobby-swiper__grid']">
          <LobbyItem
            v-for="(item, index) in slideItem"
            :key="getItemKey(item, index)"
            v-bind="getLobbyItemProps(item, index)"
            :has-image-force-load="isSwiperBlock"
            :class="$style['lobby-swiper__item']"
            @click-lobby-item="emitClickLobbyItem"
          />
        </div>
      </VSwiperSlide>
      <VSwiperSlide
        v-if="hasNext"
        :class="$style['lobby-swiper__slide']"
      >
        <div :class="$style['lobby-swiper__grid']">
          <LobbyItem
            v-for="index in gridSize"
            :key="index"
            v-bind="getLobbyItemProps(undefined, 0)"
            :class="$style['lobby-swiper__item']"
          />
        </div>
      </VSwiperSlide>
    </VSwiper>
  </div>
</template>

<style lang="scss" module>
@use 'sass:math';
@import './vars/mixins';

$mobileScreenDefault: 375;
$screenLandscapeBig: 667;
$screenLandscapeSmall: 568;
$screenTabletSmall: 820;
$screenTabletBig: 1024;
$slidePadding: 16;
$mobileLandscapeSmallWidth: 500px;
$mobileLandscapeBigWidth: 600px;
$mobileTabletSmallWidth: 800px;
$mobileTabletBigWidth: 1000px;

@mixin generateItemWidth($aspectRatio) {
  width: calc(100vw / #{$aspectRatio} + #{$slidePadding}px);
}

@mixin generateSlideWidth($defaultWidth, $landscapeWidth: false, $landscapeSmallWidth: false) {
  $widthMobileLandscapeBig: $landscapeWidth or $defaultWidth;
  $widthMobileLandscapeSmall: $landscapeSmallWidth or $landscapeWidth or $defaultWidth;
  $widthMobileTabletSmall: $landscapeWidth or $defaultWidth;
  $widthMobileTabletBig: $landscapeWidth or $defaultWidth;

  @include generateItemWidth(math.div($mobileScreenDefault, $defaultWidth));

  @media only screen and (min-width: $mobileLandscapeSmallWidth) {
    @include generateItemWidth(
      math.div($screenLandscapeSmall, $widthMobileLandscapeSmall)
    );
  }

  @media only screen and (min-width: $mobileLandscapeBigWidth) {
    @include generateItemWidth(
      math.div($screenLandscapeBig, $widthMobileLandscapeBig)
    );
  }

  @media only screen and (min-width: $mobileTabletSmallWidth) {
    @include generateItemWidth(
      math.div($screenTabletSmall, $widthMobileTabletSmall)
    );
  }

  @media only screen and (min-width: $mobileTabletBigWidth) {
    @include generateItemWidth(
      math.div($screenTabletBig, $widthMobileTabletBig)
    );
  }
}

@include for-layout using($isDesktop) {
  .lobby-swiper {
    &__swiper-wrapper {
      gap: $lobbySwiperGapSmall;

      @if not $isDesktop {
        padding: $lobbySwiperWrapperPhonePaddings;
      } @else {
        padding: $lobbySwiperWrapperDesktopPaddingsSmall;

        @include screen-desktop-large-min {
          padding: $lobbySwiperWrapperDesktopPaddingsBig;
        }

        &:after {
          @if $lobbySwiperWrapperDesktopLastSlidePaddingFix {
            min-width: 1px;
            margin-left: -1px;
            content: '';
          }
        }
      }

      @include screen-desktop-large-min {
        gap: $lobbySwiperGapBig;
      }

      &--modal {
        gap: $lobbySwiperGapSmall;

        @if not $isDesktop {
          padding: $lobbySwiperWrapperModalPaddings;
        }

        @include screen-desktop-large-min {
          gap: $lobbySwiperGapSmall;
        }
      }

      &--no-background {
        @if $isDesktop {
          padding: 0;

          @include screen-desktop-large-min {
            padding: 0;
          }

          &:after {
            min-width: 0;
            margin-left: 0;
            content: '';
          }
        }
      }
    }

    &__grid {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 16px;
    }

    &--overflow-hidden {
      @if $isDesktop {
        overflow: hidden;
      }
    }

    &--grid-type {
      &-column-1 {
        .lobby-swiper__grid {
          grid-template-columns: repeat(1, 1fr);
        }
      }

      &-column-2 {
        .lobby-swiper__grid {
          grid-template-columns: repeat(2, 1fr);
        }
      }

      &-column-3 {
        .lobby-swiper__grid {
          grid-template-columns: repeat(3, 1fr);
        }
      }

      &-column-4 {
        .lobby-swiper__grid {
          grid-template-columns: repeat(4, 1fr);
        }
      }

      &-column-5 {
        .lobby-swiper__grid {
          grid-template-columns: repeat(5, 1fr);
        }
      }

      &-column-6 {
        .lobby-swiper__grid {
          grid-template-columns: repeat(6, 1fr);
        }
      }

      &-column-8 {
        .lobby-swiper__grid {
          grid-template-columns: repeat(8, 1fr);
        }
      }

      &-column-square {
        .lobby-swiper__grid {
          grid-template-columns: repeat(2, 1fr);

          @media only screen and (min-width: $mobileLandscapeBigWidth) {
            grid-template-columns: repeat(4, 1fr);
          }
        }
      }
    }

    &--type {
      &-column-1 {
        .lobby-swiper__slide {
          @include generateItemsCounterWidth(1);
        }
      }

      &-column-2 {
        .lobby-swiper__slide {
          @include generateItemsCounterWidth(2);
        }
      }

      &-column-3 {
        .lobby-swiper__slide {
          @include generateItemsCounterWidth(3);
        }
      }

      &-column-4 {
        .lobby-swiper__slide {
          @include generateItemsCounterWidth(4);
        }
      }

      &-column-modal-game-default {
        .lobby-swiper__slide {
          @include lobby-swiper-slide-width-modal-game-default;
        }
      }

      &-column-modal-game-small {
        .lobby-swiper__slide {
          @include lobby-swiper-slide-width-modal-game-small;
        }
      }

      &-column-modal-game-square-small {
        .lobby-swiper__slide {
          @include lobby-swiper-slide-width-modal-game-square-small;
        }
      }

      &-column-no-bg-game-default {
        .lobby-swiper__slide {
          @include lobby-swiper-slide-width-no-bg-game-default;
        }
      }

      &-column-no-bg-game-small {
        .lobby-swiper__slide {
          @include lobby-swiper-slide-width-no-bg-game-small;
        }
      }

      &-column-no-bg-game-large {
        .lobby-swiper__slide {
          @include lobby-swiper-slide-width-no-bg-game-large;
        }
      }

      &-column-no-bg-game-square-small {
        .lobby-swiper__slide {
          @include lobby-swiper-slide-width-no-bg-game-square-small;
        }
      }

      &-column-game-small {
        .lobby-swiper__slide {
          @include lobby-swiper-slide-width-game-small;
        }
      }

      &-column-game-default {
        .lobby-swiper__slide {
          @include lobby-swiper-slide-width-game-default;
        }
      }

      &-column-game-large {
        .lobby-swiper__slide {
          @include lobby-swiper-slide-width-game-large;
        }
      }

      &-column-square-game-small {
        .lobby-swiper__slide {
          @include lobby-swiper-slide-width-game-square-small;
        }
      }

      &-column-square-game-default {
        .lobby-swiper__slide {
          @include lobby-swiper-slide-width-game-square-default;
        }
      }

      &-column-square-game-large {
        .lobby-swiper__slide {
          @include lobby-swiper-slide-width-game-square-large;
        }
      }

      &-column-promotion {
        .lobby-swiper__slide {
          @include lobby-swiper-slide-width-promotion;
        }
      }

      &-column-jackpot {
        .lobby-swiper__slide {
          @include lobby-swiper-slide-width-jackpot;
        }
      }

      &-column-sportline-top-match {
        .lobby-swiper__slide {
          @if $isDesktop {
            @include generateItemsCounterWidth(3);

            min-width: 456px;
          } @else {
            @include generateSlideWidth(307);
          }
        }
      }

      &-column-top-winner {
        .lobby-swiper__slide {
          @include lobby-swiper-slide-width-egs-top-winner;
        }
      }

      &-column-group {
        .lobby-swiper__slide {
          @include lobby-swiper-slide-width-group;
        }
      }
    }
  }
}
</style>
