<script setup lang="ts">
import { toRef } from 'vue';

import type { BannerContent } from 'web/src/modules/banners/store/types';
import VImage from 'web/src/components/Image/VImage/VImage.vue';
import {
  ObjectFitOption,
  ObjectPosition,
} from 'web/src/components/Image/VImage/enums';
import {
  useBannerLocation,
  useBannerTexts,
} from 'web/src/modules/banners/components/composables';
import CustomBannerButton from 'web/src/components/Button/CustomBannerButton/CustomBannerButton.vue';

const props = defineProps<{
  banner: BannerContent;
}>();
const bannerRef = toRef(props, 'banner');

const {
  buttonText,
} = useBannerTexts(bannerRef);
const {
  href,
  onClick,
} = useBannerLocation(bannerRef);
</script>

<template>
  <div v-auto-id="'CustomHomepageBanner'"
    :class="$style['custom-home-banner']"
  >
    <VImage
      v-if="banner.image"
      v-bind="banner.image"
      :object-fit="ObjectFitOption.COVER"
      :object-position="ObjectPosition.LEFT"
      :class="$style['custom-home-banner__image']"
    />
    <div
      :class="$style['custom-home-banner__info']"
    >
      <div
        v-if="buttonText"
        :class="$style['custom-home-banner__button']"
      >
        <CustomBannerButton
          v-if="buttonText"
          :text="buttonText"
          :href="href"
          is-full-width
          is-big
          :class="$style['custom-home-banner__button-btn']"
          @click.stop.prevent="onClick"
        />
      </div>
    </div>
  </div>
</template>

<style module lang="scss">
@include for-layout using($isDesktop) {
  .custom-home-banner {
    position: relative;
    height: if($isDesktop, 232px, 222px);
    overflow: hidden;
    border-radius: 5px;

    &__image {
      width: 100%;
      height: 100%;
    }

    &__info {
      position: absolute;
      top: 32px;
      bottom: if($isDesktop, 16px, 32px);
      display: flex;
      flex-direction: column;
      gap: if($isDesktop, 12px, 4px);
      width: if($isDesktop, 274px, 100%);
      color: var(--DTextDefault);
      text-align: center;

      @if $isDesktop {
        left: 32px;
      }
    }

    &__button {
      width: if($isDesktop, 100%, 275px);
      margin: auto auto 0;
    }
  }
}
</style>
