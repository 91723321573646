<script lang="ts" setup>
import { Tag } from '@leon-hub/tags';

import { JumbotronFooterButton, VJumbotron } from 'web/src/components/Jumbotron';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';

import { useCustomerHistoryListEmpty } from './composables';
import type { CustomerHistoryListEmptyProps } from './types';

const props = defineProps<CustomerHistoryListEmptyProps>();

const {
  buttonLabel,
  emptyText,
  doShowActionButton,
  buttonClick,
} = useCustomerHistoryListEmpty(props);
</script>

<template>
  <VJumbotron v-auto-id="'CustomerHistoryListEmpty'"
    :heading="$t('WEB2_NOTHING_FOUND')"
    :text="emptyText"
    class="history-list__jumbotron"
  >
    <template #footer>
      <JumbotronFooterButton
        v-if="!isButtonHidden && doShowActionButton"
        class="history-list__button"
        :tag="Tag.BUTTON"
        :label="buttonLabel"
        :kind="null ? 'tertiary-secondary' : ButtonKind.BASE"
        :full-width="!!null"
        :is-uppercase="false"
        rounded
        @click="buttonClick"
      />
    </template>
  </VJumbotron>
</template>
