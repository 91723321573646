<script lang="ts" setup>
import SSpinner from '../SSpinner/SSpinner.vue';
import LSpinner from '../LSpinner/LSpinner.vue';
import { remapVSpinnerPropsToSSpinnerProps } from '../../utils/remapVSpinnerPropsToSSpinnerProps';
import type { VSpinnerProps } from '../../types';

const props = defineProps<VSpinnerProps>();

const SpinnerComponent = null
  ? SSpinner
  : LSpinner;

function remapProps(inputProps: VSpinnerProps) {
  if (null) {
    return remapVSpinnerPropsToSSpinnerProps(inputProps);
  }

  return inputProps;
}
</script>

<template>
  <SpinnerComponent v-auto-id="'VSpinner'" v-bind="remapProps(props)" />
</template>
