import { getLeaguePlayoffByLeagueId as getBetlineLeaguePlayoffByLeagueId } from '@leon-hub/api-sdk';
import { RequestGroupScore24 } from '@leon-hub/api';

import type { BetlineLeaguePlayoffResponse } from 'web/src/modules/sportline/types/rest';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';

export default function getBetlineLeaguePlayoff(
  options: { leagueId: number },
  { silent }: { silent?: boolean },
): Promise<BetlineLeaguePlayoffResponse> {
  const apiClient = useGraphqlClient();
  return getBetlineLeaguePlayoffByLeagueId(apiClient,
    (node) => node.queries.betLine.getLeaguePlayoffByLeagueId,
    { options },
    {
      silent,
      group: RequestGroupScore24,
    });
}
