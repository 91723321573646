import type {
  CustomerHistoryFilterOption,
  CustomerHistoryRequest,
  DataResponse,
  getAccountTransactionQuery,
  getCustomerHistoryListQuery,
  getCustomerHistoryItemQuery,
  HistoryFilter,
  Score,
} from '@leon-hub/api-sdk';

type RequiredAndNotNull<T> = { [P in keyof T]-?: Exclude<T[P], null | undefined> };

export type RequiredCustomerHistoryRequest = RequiredAndNotNull<
  Pick<CustomerHistoryRequest, 'dateType' | 'dateRange' | 'page' | 'filter' | 'rows'>
>;

export type DateOrderFilter = Pick<CustomerHistoryFilterOption, 'dateType' | 'dateRange'>;

export type CurrentScoreType = Maybe<Pick<Score, 'home' | 'away'>>;

export interface HistoryFilterState {
  readonly filter: HistoryFilter;
  readonly page: number;
  readonly isLoading: boolean;
  readonly isLastPage: boolean;
  readonly entries: string[];
}

export enum ActiveOrderFilterEnum {
  PENDING_BETS = '0.1',
  DEFAULT = '1',
}

export type TransactionResponse = DataResponse & getAccountTransactionQuery[
  'queries']['accountTransaction']['getTransactionInfo']['data'];

export type HistoryListItem = Exclude<getCustomerHistoryListQuery[
  'queries']['account']['getHistory']['data'], null>['items'][number];

export type ExtendedHistoryItem = Exclude<getCustomerHistoryItemQuery[
  'queries']['account']['getHistoryItemV2']['data'], null>['item'];

export type HistoryItem = ExtendedHistoryItem & HistoryListItem;

export type HistoryListResponse =
  DataResponse &
  getCustomerHistoryListQuery['queries']['account']['getHistory']['data'] & { items: HistoryItem[] };

// for QA
export enum TestKeysEnum {
  ID = 'id',
  STATUS = 'status',
  PLACED_DATE = 'placedDate',
  MARKET_NAME = 'marketName',
  RUNNER_NAME = 'runnerName',
  ODDS = 'odds',
  STAKE = 'stake',
  MAX_WIN = 'maxWin',
  BONUS = 'bonus',
  WIN_AMOUNT = 'winAmount',
  LEAGUE = 'league',
  EVENT_DESCRIPTION = 'competitors',
  SCORE = 'score',
  DATE = 'date',
  TIME = 'time',
  DEBIT = 'debit',
  CREDIT = 'credit',
  TYPE_NAME = 'typeName',
  TYPE_KIND = 'typeKind',
  TYPE_DESCRIPTION = 'typeDescription',
  MONEY = 'money',
  PLACE_BET_SCORE = 'placeBetScore',
  WITHDRAW_CANCELLABLE = 'withdrawCancellable',
  CASH_OUT_AVAILABLE = 'cashOutAvailable',
  WIN_BOOST = 'win-boost',
  TAX = 'tax',
}

export interface CustomerHistoryListProperties {
  isLazyLoadingEnabled: boolean;
  isHiddenTabs: boolean;
  isHiddenSelectFilter: boolean;
  filterParamsFromProps: Maybe<RequiredCustomerHistoryRequest>;
}

export interface CustomerHistoryRouterParameters { group?: string; id?: string; accountType?: string }
