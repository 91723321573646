import type { RouteLocationNormalized, NavigationGuardNext } from 'vue-router';

import { AbstractPrefetch } from 'web/src/modules/core/services/router/prefetch';
import type { AppVueRouter } from 'web/src/modules/core/services/router/types';
import {
  useSportlineSportEventDetailsNavigationService,
} from 'web/src/modules/sportline/submodules/event-details/services/navigation';

export default class SportEventDetailsPagePrefetch extends AbstractPrefetch {
  // eslint-disable-next-line class-methods-use-this
  prefetch(
    router: AppVueRouter,
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ): Promise<void> {
    useSportlineSportEventDetailsNavigationService().prefetch(router, to, from, next);
    return Promise.resolve();
  }
}
