<script setup lang="ts">
import type { PromotionsListItemProps } from 'web/src/modules/promotions/components/PromotionsListItem/types';

import SPromotionListItem
  from './SPromotionListItem/SPromotionListItem.vue';
import LPromotionsListItem
  from './LPromotionListItem/LPromotionsListItem.vue';

const props = defineProps<PromotionsListItemProps>();
</script>

<template>
  <SPromotionListItem
    v-if="null"
    v-bind="props"
  />
  <LPromotionsListItem
    v-else
    v-bind="props"
  />
</template>
