import type {
  Region,
} from 'web/src/modules/sportline/types';
import type {
  RawRegion,
  BuildSportOptions,
  CoreRegionResponse,
  CoreSportResponse,
} from 'web/src/modules/sportline/types/rest';
import {
  buildRegionNavigationParameters,
} from 'web/src/modules/sportline/utils/rest/navigation-parameters';

export default function parseRegionResponse(
  options: BuildSportOptions & {
    sportResponse: CoreSportResponse;
    regionResponse: CoreRegionResponse;
  },
): RawRegion {
  const navigationParameters = buildRegionNavigationParameters(options);
  const id = String(options.regionResponse.id);
  const region: Region = {
    id,
    name: options.regionResponse.name,
    // group by family or use id like single family group
    regionFamily: options.regionResponse.family || id,
    representation: {
      icon: options.regionResponse.family || null,
    },
    navigationParameters,
  };

  return { region };
}
