import type { IgnoredError } from '@leon-hub/api-sdk';

export function mergeIgnoredErrors(
  ignoredErrors1: IgnoredError[],
  ignoredErrors2: IgnoredError[] = [],
): IgnoredError[] {
  return [...new Map([...ignoredErrors1, ...ignoredErrors2]
    .map((e) => [JSON.stringify([e.error, e.type]), e]))
    .values(),
  ];
}
