/* eslint-disable no-param-reassign */
import { createPopper } from '@popperjs/core';
import type { DirectiveBinding, Directive } from 'vue';

import type { PopperInstance, PopperOptions } from '../VPopper/types';
import { PopperPosition } from '../VPopper/enums';

class PopperHint {
  protected content?: HTMLElement;

  protected instance?: PopperInstance;

  protected popperOptions: PopperOptions = {
    placement: PopperPosition.AUTO,
    modifiers: [{
      name: 'offset',
      options: {
        offset: [0, 4],
      },
    }],
  };

  constructor(
    protected element: HTMLElement,
    protected title: string,
  ) {
    this.element.title = title;
    this.element.addEventListener('click', this.createPopper.bind(this));
  }

  updateTitle(title: string): void {
    this.title = title;
  }

  destroy(): void {
    this.destroyPopper();
    this.element.title = '';
    this.element.removeEventListener('click', this.createPopper.bind(this));
  }

  protected createPopper(): void {
    if (this.instance) { return; }
    if (this.content) { return; }

    this.content = document.createElement('div');
    this.content.innerHTML = this.title;
    this.content.classList.add('app-hint-popup');
    document.body.appendChild(this.content);
    this.instance = createPopper(this.element, this.content, this.popperOptions);

    window.setTimeout(() => {
      document.addEventListener('click', this.destroyPopper.bind(this), {
        once: true,
      });
    }, 0);
  }

  protected destroyPopper(): void {
    document.removeEventListener('click', this.destroyPopper.bind(this));
    this.instance?.destroy();
    this.instance = undefined;
    if (this.content) {
      document.body.removeChild(this.content);
      this.content = undefined;
    }
  }
}

interface PopperHintHTMLElement extends HTMLElement {
  popperHint?: PopperHint;
}

function addPopperHint(element: PopperHintHTMLElement, binding: DirectiveBinding): void {
  if (binding.oldValue === binding.value) {
    return;
  }

  if (!binding.value) {
    element.popperHint?.destroy();
    return;
  }

  if (element.popperHint) {
    element.popperHint.updateTitle(binding.value);
    return;
  }

  element.popperHint = new PopperHint(element, binding.value);
}

function removePopperHint(element: PopperHintHTMLElement): void {
  element.popperHint?.destroy();
  element.popperHint = undefined;
}

const popperHint: Directive = {
  beforeMount: addPopperHint,
  updated: addPopperHint,
  beforeUnmount: removePopperHint,
};

export default popperHint;
