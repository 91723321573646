import type { Ref } from 'vue';

import type { VCoreDropdownProps } from '../types';
import { DropdownPreferredDirection } from '../enums/DropdownPreferredDirection';

interface SettingsForOpenDirection {
  betterOpenToTop: boolean;
  maxHeight: number | null;
}

export default function getSettingsForOpenDirection(
  props: VCoreDropdownProps,
  dropdownContainer: Ref<HTMLElement | undefined>,
): SettingsForOpenDirection {
  const preferredDirection: DropdownPreferredDirection = props.dropDirection ?? DropdownPreferredDirection.AUTO;

  const result: SettingsForOpenDirection = {
    betterOpenToTop: false,
    maxHeight: null,
  };
  if (typeof window === 'undefined') {
    return result;
  }
  const { appearZonePositionY, appearZoneOffsetY } = props;
  const winHeight = window.innerHeight;
  const contentSize = dropdownContainer.value?.offsetHeight || 0;
  const verticalOffset = props.verticalOffset ?? 0;
  const downAppearPoint = appearZonePositionY + appearZoneOffsetY;
  const spaceBelow = winHeight - downAppearPoint;
  const maxContentSize = props.heightLimit ? Math.min(props.heightLimit, contentSize) : contentSize;
  if (spaceBelow >= maxContentSize && preferredDirection !== DropdownPreferredDirection.TOP) {
    if (props.heightLimit) {
      result.maxHeight = props.heightLimit;
    } else {
      result.maxHeight = spaceBelow - verticalOffset;
    }
    return result;
  }
  switch (preferredDirection) {
    case DropdownPreferredDirection.TOP:
      result.betterOpenToTop = true;
      break;
    case DropdownPreferredDirection.DOWN:
      result.betterOpenToTop = false;
      break;
    case DropdownPreferredDirection.AUTO: // is default
    default:
      result.betterOpenToTop = appearZonePositionY * 0.7 > spaceBelow;
      break;
  }
  let maxHeightByWindowSize: number;
  if (!result.betterOpenToTop) {
    maxHeightByWindowSize = winHeight - appearZonePositionY - appearZoneOffsetY - verticalOffset;
  } else {
    maxHeightByWindowSize = appearZonePositionY - verticalOffset;
  }
  result.maxHeight = props.heightLimit ? Math.min(maxHeightByWindowSize, props.heightLimit) : maxHeightByWindowSize;
  return result;
}
