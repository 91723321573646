import {
  onActivated,
  onBeforeUnmount,
  onDeactivated,
  onMounted,
} from 'vue';

export function useCustomMessageListener(callback: (event: MessageEvent) => void): void {
  let isSubscribed = false;

  function subscribe(): void {
    if (!isSubscribed) {
      window.addEventListener('message', callback);
      isSubscribed = true;
    }
  }

  function unsubscribe(): void {
    window.removeEventListener('message', callback);
    isSubscribed = false;
  }

  if (!process.env.VUE_APP_PRERENDER) {
    onMounted(subscribe);
    onActivated(subscribe);
    onBeforeUnmount(unsubscribe);
    onDeactivated(unsubscribe);
  }
}
