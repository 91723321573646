import type AbstractFingerprintComponent from '../AbstractFingerprintComponent';

export type ComponentResult = { key: ComponentKey; value: string };

export type ComponentsResult = Map<ComponentKey, ComponentResult>;

export interface ComputeComponentsBaseOptions {
  components?: Set<{ new(): AbstractFingerprintComponent }>;
  componentExecutingTimeoutMs: number;
  debug: boolean;
  runInIdleTimeQueue?: boolean;
}

export type ComputeComponentsOptions = ComputeComponentsBaseOptions;

export interface CompoundFingerprintHashOptions extends ComputeComponentsBaseOptions {
  hasher: (input: string) => string;
  doNotAllowComponentsList?: (() => ComponentStatus | unknown)[];
}

export interface FingerprintBrowserConfig extends CompoundFingerprintHashOptions {
  visitorIdTtl: number;
}

export type StoredFingerprintBrowser = {
  visitorId: string;
  timestamp: number;
};

export enum ComponentKey {

  // https://wiki.mozilla.org/Fingerprinting
  Plugins = 'plugins',
  Fonts = 'fonts',
  UserAgent = 'userAgent',
  ScreenResolution = 'screenResolution',
  Timezone = 'timezone',

  // Other
  TimezoneOffset = 'timezoneOffset',
  Language = 'language',
  ColorDepth = 'colorDepth',
  DeviceMemory = 'deviceMemory',
  PixelRatio = 'pixelRatio',
  HardwareConcurrency = 'hardwareConcurrency',
  Platform = 'platform',
  DoNotTrack = 'doNotTrack',
  WebglVendorAndRenderer = 'webglVendorAndRenderer',
  TouchSupport = 'touchSupport',
  Canvas = 'canvas',
  Audio = 'audio',
  EnumerateDevices = 'enumerateDevices',

  // TODO:
  // HttpAccept = 'httpAccept',
  // SuperCookies = 'superCookies',
  // CookiesEnabled = 'cookiesEnabled',
  // ServiceWorker = 'serviceWorker',
  // AdBlock = 'adBlock',
  // Detect lied signatures

}

export enum ComponentStatus {
  NotAvailable = 'NotAvailable',
  NoData = 'NoData',
  Error = 'Error',
  Timeout = 'TimeoutDFB',
  Applicable = 'Applicable',
  NotApplicable = 'NotApplicable',
}
