import type { PushNotificationPermission } from '@leon-hub/app-types';

import useCordovaPushNotificationsStore
  from 'web/src/modules/push-notifications/store/useCordovaPushNotificationsStore';
import useWebPushNotificationsStore
  from 'web/src/modules/push-notifications/store/useWebPushNotificationsStore';
import type { SubscribeOptions } from 'web/src/modules/push-notifications/store/types';

// eslint-disable-next-line max-len
export default async function usePushNotificationsSubscribe(options?: SubscribeOptions): Promise<PushNotificationPermission> {
  if (process.env.VUE_APP_PLATFORM_CORDOVA) {
    return useCordovaPushNotificationsStore().subscribe(options);
  }
  return useWebPushNotificationsStore().subscribe(options?.forceUpdate);
}
