import {
  assert, isArray, isNumber, isValidInterface,
} from '@leon-hub/guards';
import type { AffiliateOperation } from '@leon-hub/api-sdk';

import DateTime, { TimeFormats } from 'web/src/utils/DateTime';
import type { I18nFunctionReactive } from 'web/src/modules/i18n/types';
import { OperationTypeMappingType } from 'web/src/modules/affiliates/store/enums';

export const isAffiliateOperation = (value: unknown): value is AffiliateOperation => isValidInterface(value, {
  date: isNumber,
  debit: isNumber,
  credit: isNumber,
  pendingDebit: isNumber,
  pendingCredit: isNumber,
  balance: isNumber,
  pendingBalance: isNumber,
  typeId: isNumber,
});

export const isAffiliateOperations = (value: unknown): value is AffiliateOperation[] => isArray(value)
  && value.every((item) => isAffiliateOperation(item));

export function mapOperationDateTime(value?: number): string {
  assert(isNumber(value));
  return DateTime.formatTimeStamp(
    DateTime.timestampSecondsToDate(value, true).getTime(),
    TimeFormats.dayMonthYearHoursMinutes,
  );
}

export function mapOperationDate(value?: number): string {
  assert(isNumber(value));
  return DateTime.formatTimeStamp(
    DateTime.timestampSecondsToDate(value, true).getTime(),
    TimeFormats.dayMonthYear,
  );
}

export function mapOperationTime(value?: number): string {
  assert(isNumber(value));
  return DateTime.formatTimeStamp(
    DateTime.timestampSecondsToDate(value, true).getTime(),
    TimeFormats.hoursMinutes,
  );
}

export function mapOperationTypeId($t: I18nFunctionReactive, value?: number): string {
  assert(isNumber(value));
  switch (value) {
    case OperationTypeMappingType.BetId:
      return $t('WEB2_AFFILIATE_OPERATION_TYPE_BET').value;
    case OperationTypeMappingType.PartnerId:
      return $t('WEB2_AFFILIATE_OPERATION_TYPE_PARTNER').value;
    case OperationTypeMappingType.SportBonusWithdrawalId:
      return $t('JAVA_AHI_AFFPBONUSCHARGE').value;
    default:
      return 'Unexpected operation type';
  }
}

export function mapZeroToEmptyString(value?: number): string {
  assert(isNumber(value));
  return (value === 0 ? '' : String(value));
}

export function mapOperationBalance(value?: number): string {
  assert(isNumber(value));
  return Number(value).toFixed(2);
}
