import { RouteName } from '@leon-hub/routing-config-names';

import { AppModule } from 'web/src/modules/core/plugins/AppModule';
import type { AppVueRouter } from 'web/src/modules/core/services/router/types';

export class CustomerNotificationsModule extends AppModule {
  // eslint-disable-next-line class-methods-use-this
  addRoutes(router: AppVueRouter): void {
    router.addProfileRoute({
      name: RouteName.CUSTOMER_NOTIFICATIONS,
      path: '/notifications',
      // eslint-disable-next-line max-len
      component: () => import('web/src/modules/customer-notifications/pages/CustomerNotificationsRoutePage/CustomerNotificationsRoutePage.vue'),
      title: '{{$t(\'JS_OPERATOR_MESSAGES_TITLE\')}}',
      // eslint-disable-next-line max-len
      topBar: () => import('web/src/modules/customer-notifications/views/CustomerNotificationsTopBarRouteComponent/CustomerNotificationsTopBarRouteComponent.vue'),
    });

    router.addProfileRoute({
      name: RouteName.CUSTOMER_NOTIFICATION_DETAILS,
      path: '/notifications/:source/:id',
      // eslint-disable-next-line max-len
      component: () => import('web/src/modules/customer-notifications/pages/CustomerNotificationDetailsRoutePage/CustomerNotificationDetailsRoutePage.vue'),
      title: '{{$t(\'JS_OPERATOR_MESSAGES_TITLE\')}}',
      // eslint-disable-next-line max-len
      topBar: () => import('web/src/modules/customer-notifications/views/CustomerNotificationDetailsTopBarRouteComponent/CustomerNotificationDetailsTopBarRouteComponent.vue'),
    });
  }
}
