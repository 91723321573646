<script lang="ts" setup>
import { IconName } from '@leon-hub/icons';

import VList from 'web/src/components/List/VList/VList.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import VDynamicContent from 'web/src/components/DynamicContent/VDynamicContent/VDynamicContent.vue';
import VListItemAccordion from 'web/src/components/List/VListItemAccordion/VListItemAccordion.vue';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import SHintBlockExpanded from 'web/src/components/HintBlock/SHintBlockExpanded/SHintBlockExpanded.vue';

import type { PaymentsHintBlockProps, PaymentsHintBlockEmits } from './types';

withDefaults(defineProps<PaymentsHintBlockProps>(), {
  message: '',
});
const emit = defineEmits<PaymentsHintBlockEmits>();
</script>

<template>
  <div v-auto-id="'PaymentsHintBlock'"
    :class="{
      [$style['payments-hint-block']]: true,
      [$style['payments-hint-block--terminal']]: isTerminal,
    }"
  >
    <template v-if="isTerminal && !null">
      <VDynamicContent
        v-if="!isDeptsupisstreetterminal"
        :class="$style['payments-hint-block__text']"
        :content="message"
      />
      <VButton
        :kind="ButtonKind.CANCEL"
        :label="$t('WEB2_CHOOSE_ANOTHER_METHOD')"
        full-width
        :class="$style['payments-hint-block__button']"
        @click="emit('click-other')"
      />
    </template>
    <template v-else>
      <VDynamicContent
        v-if="null && isTerminal"
        :class="$style['payments-hint-block__text']"
        :content="message"
      />
      <SHintBlockExpanded
        v-else-if="null && showAdditionalInformation && message"
        :title="$t('WEB2_PAYMENT_SYSTEM_DESCRIPTION_EXPANDED_TITLE')"
        :text="message"
        type="quaternary"
        icon-color="secondary"
        :icon-name="IconName.INFO"
        :opened-button-text="$t('WEB2_PAYMENT_SYSTEM_DESCRIPTION_EXPANDED_OPENED')"
        :closed-button-text="$t('WEB2_PAYMENT_SYSTEM_DESCRIPTION_EXPANDED_CLOSED')"
      />
      <template v-else-if="!null">
        <VButton
          v-if="showOtherMethodButton"
          :kind="ButtonKind.CANCEL"
          :label="$t('WEB2_CHOOSE_ANOTHER_METHOD')"
          full-width
          :class="$style['payments-hint-block__button']"
          @click="emit('click-other')"
        />
        <VDynamicContent
          v-if="showNoSpoiler && showAdditionalInformation && message"
          v-data-test="{el: 'hint-without-spoiler'}"
          :class="[
            $style['payments-hint-block__text-without-spoiler'],
            $style['payments-hint-block__text']
          ]"
          :content="message"
        />
        <VList
          v-else-if="showAdditionalInformation && message"
          v-data-test="{el: 'hint-spoiler'}"
          :class="$style['payments-hint-block__accordion']"
        >
          <VListItemAccordion
            id="hint"
            :title="$t('WEB2_IMPORTANT_INFORMATION')"
            no-background
            :class="$style['payments-hint-block__content']"
            :open="open"
          >
            <template #content>
              <VDynamicContent
                :class="$style['payments-hint-block__text']"
                :content="message"
              />
            </template>
          </VListItemAccordion>
        </VList>
      </template>
    </template>
  </div>
</template>

<style lang="scss" module>
@include for-layout using($isDesktop) {
  .payments-hint-block {
    margin-top: 20px;

    &--terminal {
      margin-top: 0;
    }

    &__text-without-spoiler {
      padding: 0 4px;
      margin-top: 20px;

      @if $isDesktop {
        max-width: 311px;
        margin: 20px auto 0;
      }
    }

    &__text {
      @include regular\14\20\025;

      color: var(--TextPrimary);
      text-transform: none;

      :global(h1) {
        @include medium\24\28;

        margin: 0 0 12px;
        letter-spacing: normal;
      }

      :global(h2) {
        @include medium\18\24;

        margin: 0 0 12px;
        letter-spacing: normal;
      }

      :global(h4) {
        @include medium\12\16\04\caps;

        margin: 0 0 12px;
      }

      :global(img) {
        max-width: 100%;
      }

      :global(p) {
        @include paymentHintParagraph;

        margin: 0 0 $paymentHintParagraphBottomMargin;
      }

      :global(.payments-instruction-list-item) {
        margin: 0 0 12px;
      }

      :global(.payments-instruction-list-item:last-child) {
        padding-bottom: 32px;
      }

      :global(.payments-instruction-list-item span) {
        color: var(--TextPrimary);
      }

      :global(.payments-instruction-list-item i) {
        color: var(--TextPrimary);
      }

      :global(.payments-instruction-list-item i a) {
        color: var(--TextPrimary);
      }
    }

    &__button {
      @if $isDesktop {
        max-width: 311px;
        margin: 0 auto;
      }
    }

    &__accordion {
      margin-top: 20px;
    }
  }
}
</style>
