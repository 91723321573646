import { defineStore } from 'pinia';
import {
  computed,
  ref,
  toRef,
  watch,
} from 'vue';

import type {
  CustomerHistoryFilterOption,
  FastBetsSettings,
  PhoneInput,
  UpdateUserDataRequest,
  CustomerVerificationStatus,
  CustomerVerificationType,
  VideoVerificationStatus,
  CustomerConfigBooleanValue,
  ResponsibleGamingLimit,
  IResponsibleGamingLimitType,
  IPriceChangePolicy,
} from '@leon-hub/api-sdk';
import {
  AffiliatePartnerStatus,
  doSavePriceChangePolicyV2,
  doUpdateCustomerData,
  getUserDataPhone,
  CustomerConfig,
} from '@leon-hub/api-sdk';
import { getCordovaAppConfig, handleDomainUpdates } from '@leon-hub/cordova';
import { useLocalStorageManager } from '@leon-hub/local-storage';

import type { CustomerDataDocument, CustomerConfigDocument } from 'web/src/modules/user/store/types';
import { DecodeValue } from 'web/src/utils/DecodeValue';
import { emailPattern } from 'web/src/components/Form/constants';
import { useAuthStore } from 'web/src/modules/auth/store';
import { useCountryStore } from 'web/src/modules/country/store';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import isValidCustomerBetType from 'web/src/modules/customer/utils/isValidCustomerBetType';
import type { CustomerConfigOddsType } from 'web/src/modules/sportline/submodules/odds/enums';

import type { CustomerConfigCache, SavePriceChangePolicyPayload } from '../types';
import phoneStringToPhoneInput from '../utils/phoneStringToPhoneInput';
import useCustomerBetTypeStore from './useCustomerBetTypeStore';
import { getSavePriceChangePolicy } from '../utils/getSavePriceChangePolicy';

const useCustomerDataStore = defineStore('customer-data', () => {
  const api = useGraphqlClient();
  const authStore = useAuthStore();
  const { setLoggedIn } = authStore;
  const isLoggedIn = toRef(authStore, 'isLoggedIn');
  const customerConfigCache = ref<CustomerConfigCache>(null);
  const { setBetType } = useCustomerBetTypeStore();
  const localStorageManager = useLocalStorageManager();

  /** state */

  const customerData = ref<CustomerDataDocument | null>(null);
  const forcedVerificationType = ref<CustomerVerificationType | null>(null);
  const isShowTurnOnTfaStep = ref<boolean>(!customerData.value?.g2svCustomerStatus);
  const customerOddTypeConfig = ref<CustomerConfigOddsType>();

  /** getters */

  const isLoaded = computed<boolean>(() => customerData.value !== null);

  const isAsoTermsConfirmationNeeded = computed<boolean>(
    () => customerData.value?.isAsoTermsConfirmationNeeded ?? false,
  );

  const email = computed<string>(() => customerData.value?.email || '');

  const emailSubscription = computed<number>(() => customerData.value?.emailSubscription || 0);

  const login = computed<string>(() => customerData.value?.customerLogin || '');

  const phone = computed<PhoneInput>(() => phoneStringToPhoneInput(customerData.value?.phone || ''));

  const phoneDecodedString = computed<string>(() => {
    let phoneString = customerData.value?.phone === 'N/A' ? '' : phone.value.suffix;
    if (!!phoneString && !phoneString.startsWith('+')) {
      phoneString = `+${phone.value.suffix}`;
    }
    return new DecodeValue(phoneString || '').toDecodedString();
  });

  const phoneDecodedStringFull = computed(() => {
    let phoneString = '';
    if (customerData.value?.phone !== 'N/A') {
      const prefix = phone.value.prefix.startsWith('+') ? phone.value.prefix.slice(1) : phone.value.prefix;
      const suffix = phone.value.suffix.startsWith('+') ? phone.value.suffix.slice(1) : phone.value.suffix;
      phoneString = `+${prefix}${suffix}`;
    }
    return new DecodeValue(phoneString).toDecodedString();
  });

  const phoneDecoded = computed<PhoneInput>(
    () => phoneStringToPhoneInput(new DecodeValue(customerData.value?.phone || '')
      .toDecodedString()),
  );

  const fullName = computed<string>(() => customerData.value?.fullName || '');

  const verificationType = computed<CustomerVerificationType | null>(
    () => customerData.value?.verificationType ?? null,
  );

  const isCustomerVerificationAsDefaultAvailable = computed<boolean>(
    () => !!customerData.value?.isCustomerVerificationAsDefaultAvailable,
  );

  const verificationStatus = computed<CustomerVerificationStatus | null | undefined>(
    () => customerData.value?.verificationStatus,
  );

  const isAdditionalVerificationNeeded = computed(() => !!customerData.value?.additionalVerificationNeeded);

  const isVerificationPurseCheck = computed<boolean>(() => customerData.value?.verificationLevel === 'PURSE_CHECK');

  const isCountryConfirmed = computed<boolean>(() => customerData.value?.isCountryConfirmed ?? false);

  const city = computed<string>(() => customerData.value?.city ?? '');

  const address = computed<string>(() => customerData.value?.address ?? '');

  const country = computed<string>(() => customerData.value?.country ?? '');

  const countryName = computed<string>(() => customerData.value?.countryName ?? '');

  const vipStatus = computed<number>(() => customerData.value?.vipStatus ?? 0);

  const customerBetType = computed(() => customerData.value?.customerBetType);

  const shortComments = computed<string[]>(() => customerData.value?.shortComments ?? []);

  const priceChangePolicy = computed<IPriceChangePolicy>(() => getSavePriceChangePolicy(customerData.value?.priceChangePolicy));

  const totalHandicapPriceChangePolicy = computed<IPriceChangePolicy>(() => getSavePriceChangePolicy(customerData.value?.totalHandicapPriceChangePolicy));

  const historyFilterOptions = computed<CustomerHistoryFilterOption[]>(() => {
    const options = customerData.value?.historyFilterOptions;
    return options ? [...options] : [];
  });

  const defaultHistoryFilterOption = computed<CustomerHistoryFilterOption | null>(() => historyFilterOptions.value
    .find((item) => item.isDefault) ?? null);

  const g2svFeatureStatus = computed<boolean>(() => customerData.value?.g2svFeatureStatus ?? false);

  const g2svCustomerStatus = computed<boolean>(() => customerData.value?.g2svCustomerStatus ?? false);

  const lastDepositedSystemId = computed<string | null>(() => customerData.value?.lastDepositedSystemId || null);

  const affiliatePartnerStatus = computed<AffiliatePartnerStatus>(
    () => customerData.value?.affiliatePartnerStatus ?? AffiliatePartnerStatus.NO,
  );

  const isExAllowedCountry = computed<boolean>(() => customerData.value?.exAllowedCountry ?? false);

  const isForbiddenCountry = computed<boolean>(() => customerData.value?.forbiddenCountry ?? false);

  const isRegistrationFinish = computed<boolean>(() => customerData.value?.isRegistrationFinish ?? false);

  const isSimpleRegistrant = computed<boolean>(() => customerData.value?.isSimpleRegistrant ?? false);

  const personalLink = computed<string>(() => {
    const personalDomainLink = customerData.value?.personalDomainData?.name;

    if (personalDomainLink !== undefined) {
      if (!personalDomainLink.startsWith('http')) {
        return `https://${personalDomainLink}`;
      }
      return personalDomainLink;
    }
    return '';
  });

  const isEmailConfirmed = computed<boolean>(() => customerData.value?.isEmailConfirmed ?? false);

  /** not an 'N/A' string value */
  const haveValidEmailValue = computed<boolean>(() => new RegExp(emailPattern).test(email.value));

  const isAppsFlyerEnabled = computed<boolean>(() => customerData.value?.isAppsFlyerEnabled ?? false);

  const isAppsFlyerDepositQualified = computed<boolean>(
    () => customerData.value?.isAppsFlyerDepositQualified ?? false,
  );

  const isHiddenBalance = computed<boolean>(() => customerData.value?.isHiddenBalance ?? false);

  const nickname = computed<string>(() => customerData.value?.nickname || '');

  const userLanguageTag = computed<string>(() => customerData.value?.userLanguageTag || '');

  const createPinCodeModalCounter = computed<number>(() => customerData.value?.createPinCodeModalCounter || 0);

  const isPinCodeRequestedOnLogin = computed<boolean>(() => customerData.value?.isPinCodeRequestedOnLogin ?? false);

  const showSlipOnFirstAdded = computed<boolean>(() => {
    if (!isLoggedIn.value || !customerData.value) {
      return true;
    }
    return customerData.value.showSlipOnFirstAdded;
  });

  const useStandardBet = computed<boolean>(() => customerData.value?.useStandardBet ?? false);

  const standardBetAmount = computed<number | null>(() => customerData.value?.standardBetAmount || null);

  const isEkycRequestable = toRef(() => customerData.value?.isEkycRequestable ?? false);

  const sameStakeForSingleByDefault = computed<boolean>(() => customerData.value?.sameStakeForSingleByDefault ?? false);

  const fastBets = computed<FastBetsSettings | null>(() => customerData.value?.fastBets ?? null);

  const videoVerificationStatus = computed<VideoVerificationStatus | null>(
    () => customerData.value?.videoVerificationStatus ?? null,
  );

  const videoVerificationStatusExpiration = computed<number | null>(
    () => {
      if (customerData.value?.videoVerificationStatusExpiration) {
        return customerData.value.videoVerificationStatusExpiration;
      }
      return null;
    },
  );

  const isVideoVerificationOnboardingShown = computed<boolean>(
    () => customerData.value?.isVideoVerificationOnboardingShown ?? false,
  );

  const latestRequestedVerificationType = computed<CustomerVerificationType | string>(
    () => customerData.value?.latestRequestedVerificationType ?? '',
  );

  const showVerification = computed(() => !!customerData.value?.showVerification);

  // eslint-disable-next-line max-len
  const isReferralProgramNotificationClicked = computed<boolean>(() => customerData.value?.customerConfig.isReferralProgramNotificationClicked.value ?? false);

  const isResponsibleGamblingOnboardingShown = computed<boolean>(
    () => customerData.value?.customerConfig.isResponsibleGamblingOnboardingShown.value ?? false,
  );

  const isAchievementsMainOnboardingShown = computed<boolean | undefined>(
    () => customerData.value?.customerConfig.isAchievementsMainOnboardingShown.value,
  );

  const isPrivateProfile = computed<boolean | undefined>(
    () => customerData.value?.customerConfig.isPrivateProfile.value,
  );

  const isHiddenNickname = computed<boolean | undefined>(
    () => customerData.value?.customerConfig.isHiddenNickname.value,
  );

  const responsibleGamingLimits = computed<ResponsibleGamingLimit[]>(
    () => customerData.value?.responsibleGamingLimits ?? [],
  );

  const isVip = computed<boolean>(() => customerData.value?.isVip ?? false);

  const hasFomoBonus = computed<boolean>(() => customerData.value?.hasFomoBonus ?? false);

  const fomoBonusActionUrl = computed<string>(() => customerData.value?.fomoBonusActionUrl ?? '');

  const fomoBonusCampaignId = computed(() => customerData.value?.fomoBonusCampaignId ?? 0);

  const fomoBonusCategoryId = computed(() => customerData.value?.fomoBonusCategoryId ?? '');

  /** mutations */

  const setIsShowTurnOnTfaStep = (value: boolean): void => {
    isShowTurnOnTfaStep.value = value;
  };

  const setCustomerData = (data: CustomerDataDocument | null): void => {
    customerData.value = data;
  };

  function setCustomerConfig(config: CustomerConfigDocument): void {
    customerConfigCache.value = Object.keys(config)
      .reduce((acc: Exclude<CustomerConfigCache, null>, key: keyof CustomerConfigDocument) => {
        const item = config[key];
        acc[item.key] = item.value;
        return acc;
      }, {});
  }

  function setCustomerOddTypeConfig(
    isOddsTypeClassic: Pick<CustomerConfigBooleanValue, 'value' | 'key'>,
    isOddsTypeAmerican: Pick<CustomerConfigBooleanValue, 'value' | 'key'>,
  ): void {
    if (!process.env.VUE_APP_FEATURE_SPORTLINE_ODDS_SELECTOR_ENABLED) {
      return;
    }

    if (isOddsTypeAmerican.value) {
      customerOddTypeConfig.value = isOddsTypeAmerican.key as CustomerConfigOddsType;
      localStorageManager.setItem('odd-type', CustomerConfig.IS_ODDS_TYPE_AMERICAN);
    } else {
      customerOddTypeConfig.value = isOddsTypeClassic.key as CustomerConfigOddsType;
      localStorageManager.setItem('odd-type', CustomerConfig.IS_ODDS_TYPE_CLASSIC);
    }
  }

  const updateLocalCustomerData = (data: Partial<CustomerDataDocument>): void => {
    if (!isLoggedIn.value || !customerData.value) {
      return;
    }
    customerData.value = {
      ...customerData.value,
      ...data,
    };
  };

  /** actions */

  const setupCustomerData = (response: CustomerDataDocument): void => {
    const code: string = response.phoneCountryIsoCode || response.country || '';

    useCountryStore().setCountryCode(code);

    if (process.env.VUE_APP_PLATFORM_CORDOVA) {
      const { backup } = getCordovaAppConfig();

      if (backup.isEnabled && response.personalAppDomain) {
        void handleDomainUpdates({ personalDomain: response.personalAppDomain });
      }
    }

    setCustomerData(response);
    setCustomerConfig(response.customerConfig);
    const { isOddsTypeClassic, isOddsTypeAmerican } = response.customerConfig;
    setCustomerOddTypeConfig(isOddsTypeClassic, isOddsTypeAmerican);
    setLoggedIn(true);
  };

  const updateCustomerData = async (payload: UpdateUserDataRequest): Promise<void> => {
    // TODO: discus with leonapi team about required fields (c) 11.05.2020
    const defaults = {
      address: address.value,
      city: city.value,
      phone: phone.value,
      subscribe: String(emailSubscription.value),
      nickname: nickname.value,
    };

    await doUpdateCustomerData(
      api,
      (node) => node.mutations.customer.updateUserData,
      {
        options: {
          ...defaults,
          ...payload,
        },
      },
    );
  };

  /**
   * Reload only user's phone data or full data if we did not get it yet
   */
  const loadCustomerPhone = async ({ silent }: { silent?: boolean }): Promise<void> => {
    const response = await getUserDataPhone(
      api,
      (node) => node.queries.customer.getUserData,
      {
        options: {},
      },
      { silent },
    );
    if (response.customerData) {
      updateLocalCustomerData(response.customerData);
    }
  };

  const savePriceChangePolicy = async (payload: SavePriceChangePolicyPayload = {}): Promise<void> => {
    if (!Object.keys(payload).length) {
      return;
    }
    const oldValue: Required<SavePriceChangePolicyPayload> = {
      priceChangePolicy: priceChangePolicy.value,
      totalHandicapPriceChangePolicy: totalHandicapPriceChangePolicy.value,
    };
    const nextValue = { ...oldValue, ...payload };
    try {
      updateLocalCustomerData(nextValue);
      await doSavePriceChangePolicyV2(
        api,
        (node) => node.mutations.customer.savePriceChangePolicyV2,
        { options: nextValue },
      );
    } catch {
      updateLocalCustomerData(oldValue);
    }
  };

  const setCustomerConfigCache = (key: CustomerConfig, value: unknown) => {
    if (!customerConfigCache.value) {
      return;
    }
    customerConfigCache.value[key] = value;
  };

  const setForcedVerificationType = (value: CustomerVerificationType | null) => {
    forcedVerificationType.value = value;
  };

  const setResponsibleGamingLimits = (type: IResponsibleGamingLimitType, isSet: boolean) => {
    const limit = responsibleGamingLimits.value.find((item) => item.type === type);

    if (limit) {
      limit.isSet = isSet;
    }
  };

  watch(customerBetType, (newValue) => {
    if (isValidCustomerBetType(newValue)) {
      setBetType(newValue);
    }
  }, { immediate: true });

  return {
    customerOddTypeConfig,
    forcedVerificationType,
    isLoaded,
    customerData,
    email,
    emailSubscription,
    login,
    phone,
    phoneDecodedString,
    phoneDecoded,
    fullName,
    verificationType,
    verificationStatus,
    isVerificationPurseCheck,
    isCountryConfirmed,
    city,
    address,
    country,
    countryName,
    vipStatus,
    priceChangePolicy,
    totalHandicapPriceChangePolicy,
    historyFilterOptions,
    defaultHistoryFilterOption,
    g2svFeatureStatus,
    g2svCustomerStatus,
    lastDepositedSystemId,
    affiliatePartnerStatus,
    isExAllowedCountry,
    isForbiddenCountry,
    isRegistrationFinish,
    isSimpleRegistrant,
    personalLink,
    customerBetType,
    isEmailConfirmed,
    haveValidEmailValue,
    isAppsFlyerEnabled,
    isAppsFlyerDepositQualified,
    isHiddenBalance,
    nickname,
    userLanguageTag,
    shortComments,
    createPinCodeModalCounter,
    isPinCodeRequestedOnLogin,
    showSlipOnFirstAdded,
    useStandardBet,
    standardBetAmount,
    sameStakeForSingleByDefault,
    fastBets,
    videoVerificationStatus,
    videoVerificationStatusExpiration,
    isVideoVerificationOnboardingShown,
    latestRequestedVerificationType,
    isAdditionalVerificationNeeded,
    isAsoTermsConfirmationNeeded,
    isReferralProgramNotificationClicked,
    isResponsibleGamblingOnboardingShown,
    isAchievementsMainOnboardingShown,
    isCustomerVerificationAsDefaultAvailable,
    showVerification,
    customerConfig: customerConfigCache,
    phoneDecodedStringFull,
    isShowTurnOnTfaStep,
    isVip,
    hasFomoBonus,
    fomoBonusActionUrl,
    fomoBonusCampaignId,
    fomoBonusCategoryId,
    setCustomerData,
    setForcedVerificationType,
    updateLocalCustomerData,
    setupCustomerData,
    updateCustomerData,
    loadCustomerPhone,
    savePriceChangePolicy,
    setCustomerConfigCache,
    setIsShowTurnOnTfaStep,
    isEkycRequestable,
    isPrivateProfile,
    isHiddenNickname,
    responsibleGamingLimits,
    setResponsibleGamingLimits,
  };
});

export default useCustomerDataStore;
