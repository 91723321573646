<script lang="ts" setup>
import { toRef } from 'vue';

import type { BetlineLeagueMatchesResults } from 'web/src/modules/sportline/types/rest';
import { ProgressHeight, ProgressKind } from 'web/src/components/LinearProgress/VLinearProgress/enums';
import VList from 'web/src/components/List/VList/VList.vue';
import VListItemAccordion from 'web/src/components/List/VListItemAccordion/VListItemAccordion.vue';
import VLinearProgress from 'web/src/components/LinearProgress/VLinearProgress/VLinearProgress.vue';
import ExpandButton from 'web/src/modules/sportline/submodules/core-statistic/components/ExpandButton.vue';

import { useLeagueMatchesResults } from './useLeagueMatchesResults';

interface LeagueMatchesResultsProps {
  leagueMatchesResults: BetlineLeagueMatchesResults;
}

const props = defineProps<LeagueMatchesResultsProps>();

const {
  resultsList,
  goalsList,
  isExpanded,
  canExpand,
} = useLeagueMatchesResults({
  leagueMatchesResults: toRef(props, 'leagueMatchesResults'),
});
</script>

<template>
  <VList v-auto-id="'LeagueMatchesResults'" independent-items>
    <VListItemAccordion
      id="league-matches-results"
      :title="$t('WEB2_STATISTICS_MATCHES_RESULTS')"
      is-title-centered
      open
    >
      <template #content>
        <div :class="$style['league-matches-results__content']">
          <div :class="$style['matches-results-list']">
            <div :class="$style['matches-results-list__title']">
              {{ $t('WEB2_STATISTICS_ACCURATE_SCORE') }}
            </div>
            <div
              v-for="result in resultsList"
              :key="result.key"
              :class="[
                $style['matches-results-list__item'],
                $style['match-result-item']
              ]"
            >
              <div :class="$style['match-result-item__values']">
                <div :class="$style['match-result-item__value']">
                  <div
                    :class="[
                      $style['match-result-label'],
                      $style['match-result-label--light-background']
                    ]"
                  >
                    {{ result.label }}
                  </div>
                </div>
                <div :class="$style['match-result-item__value']">
                  <div :class="$style['match-result-label']">
                    {{ result.valueLabel }}
                  </div>
                </div>
                <div :class="$style['match-result-item__value']">
                  <div :class="$style['match-result-label']">
                    {{ result.percentageFixed }}%
                  </div>
                </div>
              </div>

              <div :class="$style['match-result-item__bar']">
                <VLinearProgress
                  :value="result.percentage"
                  :height="ProgressHeight.SIZE_4"
                  :kind="ProgressKind.HIGHLIGHT"
                />
              </div>
            </div>
          </div>

          <div :class="$style['matches-results-list']">
            <div :class="$style['matches-results-list__title']">
              {{ $t('WEB2_STATISTICS_ACCURATE_GOALS') }}
            </div>
            <div
              v-for="goals in goalsList"
              :key="goals.key"
              :class="[
                $style['matches-results-list__item'],
                $style['match-result-item']
              ]"
            >
              <div :class="$style['match-result-item__values']">
                <div :class="$style['match-result-item__value']">
                  <div
                    :class="[
                      $style['match-result-label'],
                      $style['match-result-label--dark-background']
                    ]"
                  >
                    {{ goals.label }}
                  </div>
                </div>
                <div :class="$style['match-result-item__value']">
                  <div :class="$style['match-result-label']">
                    {{ goals.valueLabel }}
                  </div>
                </div>
                <div :class="$style['match-result-item__value']">
                  <div :class="$style['match-result-label']">
                    {{ goals.percentageFixed }}%
                  </div>
                </div>
              </div>

              <div :class="$style['match-result-item__bar']">
                <VLinearProgress
                  :value="goals.percentage"
                  :height="ProgressHeight.SIZE_4"
                  :kind="ProgressKind.HIGHLIGHT"
                />
              </div>
            </div>
          </div>

          <ExpandButton
            v-if="canExpand"
            v-model:is-expanded="isExpanded"
            :opened-title="$t('WEB2_MATCH_STATISTIC_HIDE')"
            :closed-title="$t('WEB2_MATCH_STATISTIC_SHOW')"
            :class="$style['match-result-expand']"
          />
        </div>
      </template>
    </VListItemAccordion>
  </VList>
</template>

<style lang="scss" module>
.league-matches-results__content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;

  @include for-phone-only {
    grid-template-columns: 1fr;
  }
}

.matches-results-list {
  padding: 0 8px 8px;
  background-color: var(--Layer2);
  border-radius: 5px;

  &__title {
    @include medium\14\16\025;

    padding: 15px 0;
    color: var(--TextDefault);
    text-align: left;
  }

  &__item {
    padding: 8px 0;
  }
}

.match-result-item {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 8px;
  border-top: 1px solid var(--Layer1);

  &__values {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  &__value {
    @include medium\14\16\025;

    text-align: center;

    &:first-child {
      text-align: left;
    }

    &:last-child {
      text-align: right;
    }
  }
}

.match-result-label {
  @include medium\14\16\025;

  display: inline-block;
  padding: 2px 4px;
  color: var(--TextPrimary);
  border-radius: 2px;

  &--light-background {
    color: var(--TextDefault);
    background-color: var(--OpacityBrandDefault);
  }

  &--dark-background {
    color: var(--TextDefault);
    background-color: var(--OpacityLayer1);
  }
}

.match-result-expand {
  grid-column: 1 / -1;
}
</style>
