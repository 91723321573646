import type { Ref } from 'vue';
import { ref } from 'vue';

import type { UsePendingToFavoriteStoreComposableComposable } from 'web/src/modules/sportline/submodules/favorites/store/composables';
import { usePendingToFavoriteStoreComposable } from 'web/src/modules/sportline/submodules/favorites/store/composables';

interface UsePendingToFavoriteEventsStoreComposable extends UsePendingToFavoriteStoreComposableComposable {
  pendingToFavoritesList: Ref<string[]>;
  addPendingToFavoritesList(eventId: string): void;
  clearPendingToFavoritesList(): void;
}

export function usePendingToFavoriteEventsStoreComposable(): UsePendingToFavoriteEventsStoreComposable {
  const {
    preparedToAdd,
    preparedToRemove,
    setPrepareToAddState,
    setPrepareToRemoveState,
    setPreparedState,
  } = usePendingToFavoriteStoreComposable();

  const pendingToFavoritesList = ref<string[]>([]);

  function addPendingToFavoritesList(eventId: string): void {
    pendingToFavoritesList.value = [...pendingToFavoritesList.value, eventId];
  }

  function clearPendingToFavoritesList(): void {
    pendingToFavoritesList.value = [];
  }

  return {
    pendingToFavoritesList,
    preparedToAdd,
    preparedToRemove,
    setPrepareToAddState,
    setPrepareToRemoveState,
    setPreparedState,
    addPendingToFavoritesList,
    clearPendingToFavoritesList,
  };
}
