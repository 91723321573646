import { RouteName } from '@leon-hub/routing-config-names';

export const sportlinePrematchChildRoutes = new Set<string>([
  RouteName.SPORTLINE_PREMATCH_EVENTS,
  RouteName.SPORTLINE_PREMATCH_TODAY,
  RouteName.SPORTLINE_TOP_LEAGUE,
  RouteName.SPORTLINE_LIVE_LEAGUE,
  RouteName.SPORT_REGION,
  RouteName.SPORT_LEAGUE,
  RouteName.SPORT_EVENT_DETAILS,
]);
