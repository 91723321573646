<script lang="ts" setup>
import VSearchInput from 'web/src/components/SearchInput/VSearchInput/VSearchInput.vue';
import CasinoGroupsList from 'web/src/modules/casino/components/CasinoGroupsList/CasinoGroupsList.vue';
import { VLoaderDelayed } from 'web/src/components/Loader';
import { useCasinoGroupsRoutePage } from 'web/src/modules/casino/submodules/groups/pages/CasinoGroupsRoutePage/composables';

const {
  list,
  selectedId,
  searchText,
  isSearchInProgress,
  isNoSearchResults,
  onSearchInput,
  onProviderSelect,
  onSelectedItemLoaded,
  onSearchClear,
} = useCasinoGroupsRoutePage();
</script>

<template>
  <div v-auto-id="'CasinoGroupsRoutePage'" :class="$style['casino-slots-provider-page']">
    <VSearchInput
      :value="searchText"
      :is-autofocus="!!'1'"
      :placeholder="$t('WEB2_EGS_GROUPS_SEARCH_PLACEHOLDER')"
      @input="onSearchInput"
      @clear="onSearchClear"
    />

    <CasinoGroupsList
      :class="$style['casino-slots-provider-page__list']"
      :groups="list"
      :selected-id="selectedId"
      @click-group="onProviderSelect"
      @selected-loaded="onSelectedItemLoaded"
    />

    <VLoaderDelayed
      v-if="isSearchInProgress"
    />
    <div
      v-if="isNoSearchResults"
      :class="$style['casino-slots-provider-page__no-results']"
    >
      {{ $t('JAVA_SEARCH_NORESULTS') }}
    </div>
  </div>
</template>

<style lang="scss" module>
@include for-layout using($isDesktop) {
  .casino-slots-provider-page {
    padding: if($isDesktop, 0 32px 32px, 0 20px 32px);

    &__list {
      margin-top: 16px;
    }

    &__no-results {
      @include regular\14\24;

      padding: 12px 0;
      color: var(--TextSecondary);
      text-align: center;
    }
  }
}
</style>
