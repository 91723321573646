/* eslint-disable sonarjs/prefer-single-boolean-return */
import { isCommonWhitelistedError } from './isCommonWhitelistedError';

export function isOpenSearchLoggableError(error: unknown): boolean {
  if (isCommonWhitelistedError(error)) {
    return false;
  }

  return true;
}
