import { isString } from '@leon-hub/guards';

import type { Region } from 'web/src/modules/sportline/types';

export default function isRegionNavigationParameterEquals(
  region?: Maybe<string | Region>,
  parameter?: Maybe<string>,
): boolean {
  if (!region || !parameter) {
    return false;
  }

  if (isString(region)) {
    return region === parameter;
  }

  return parameter === region.navigationParameters.id
    || parameter === region.navigationParameters.urlName;
}
