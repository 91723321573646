import type { Ref } from 'vue';
import { ref, computed } from 'vue';

interface UseExpectedIdLoadingProps {
  expectedId: Ref<Maybe<number>>;
}

interface UseExpectedIdLoadingComposable {
  isLoading: Ref<boolean>;
  lastLoadedId: Ref<Maybe<number>>;
  isExpectedIdLoaded: Ref<boolean>;
  setIsLoading(value: boolean): void;
  setLastLoadedId(value: Maybe<number>): void;
}

export function useExpectedIdLoading(
  props: UseExpectedIdLoadingProps,
): UseExpectedIdLoadingComposable {
  const { expectedId } = props;

  const isLoading = ref(false);
  const lastLoadedId = ref<Maybe<number>>(null);
  const isExpectedIdLoaded = computed(() => (
    !!lastLoadedId.value && expectedId.value === lastLoadedId.value
  ));

  return {
    isLoading,
    lastLoadedId,
    isExpectedIdLoaded,
    setIsLoading(value: boolean): void {
      isLoading.value = value;
    },
    setLastLoadedId(value: Maybe<number>): void {
      lastLoadedId.value = value;
    },
  };
}
