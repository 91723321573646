import { getUpcomingMeeting } from '@leon-hub/api-sdk';
import type { GetUpcomingMeetingReturnType } from '@leon-hub/api-sdk';

import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';

export default function fetchUpcomingMeeting(): Promise<GetUpcomingMeetingReturnType> {
  return getUpcomingMeeting(
    useGraphqlClient(),
    (node) => node.queries.verification.getUpcomingMeeting,
    {},
  );
}
