<script lang="ts" setup>
import { IconName, IconSize } from '@leon-hub/icons';

import VButton from 'web/src/components/Button/VButton/VButton.vue';
import type { VButtonProps } from 'web/src/components/Button/VButton/types';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';

import { useReferralProgramSelectBonus } from './composables';
import type { ReferralProgramSelectBonusProps } from './types';

defineProps<ReferralProgramSelectBonusProps>();

const emit = defineEmits<SelectBonusEmits>();
interface SelectBonusEmits {
  (e: 'click', event: MouseEvent): void;
}
const {
  toggleOpen,
  isOpen,
  blockRef,
  blockStyle,
} = useReferralProgramSelectBonus();

function emitClick(event: MouseEvent): void {
  emit('click', event);
}

const infoButtonProperty: VButtonProps = {
  kind: ButtonKind.TRANSPARENT,
  iconName: IconName.INFO_OUTLINE,
  iconSize: IconSize.SIZE_24,
};
</script>

<template>
  <div v-auto-id="'VReferralProgramSelectBonus'"
    :class="{
      [$style['referral-program-select-bonus']] : true,
      [$style['referral-program-select-bonus--open']] : isOpen,
    }"
  >
    <div :class="$style['referral-program-select-bonus__top-block']">
      <div :class="$style['referral-program-select-bonus__column']">
        <VButton
          v-bind="infoButtonProperty"
          @click="toggleOpen"
        />
        <div>
          <p :class="$style['referral-program-select-bonus__label']">
            {{ title }}
          </p>
          <span :class="$style['referral-program-select-bonus__count']">{{ label }}</span>
        </div>
      </div>
      <VButton
        :label="$t('WEB2_REFERRAL_PROGRAM_CHOOSE_BONUS_2')"
        @click="emitClick"
      />
    </div>
    <div
      :class="$style['referral-program-select-bonus__bottom-container']"
      :style="blockStyle"
    >
      <div
        ref="blockRef"
        :class="$style['referral-program-select-bonus__bottom-block']"
      >
        <span :class="$style['referral-program-select-bonus__bottom-label']">{{ info }}</span>
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>
.referral-program-select-bonus {
  $self: &;

  width: 100%;
  background-color: var(--Layer1);

  &__top-block {
    display: flex;
    justify-content: space-between;
    padding: 12px 12px 12px 0;
  }

  &__column {
    display: flex;
    color: var(--TextDefault);
  }

  &__label {
    @include regular\12\16;

    margin: 0 0 4px;
  }

  &__count {
    @include medium\18\24;
  }

  &__bottom-block {
    @include regular\14\20\025;

    padding: 0 12px 12px;
    color: var(--TextPrimary);
  }

  #{$self}__bottom-container {
    overflow: hidden;
    transition: height 0.4s ease-out;
  }

  &__bottom-label {
    opacity: 0;
    transition: opacity 0.4s ease-out;
  }

  &--open {
    background-color: var(--Layer1);

    #{$self}__bottom-container {
      transition: height 0.6s ease-out;
    }

    #{$self}__bottom-label {
      opacity: 1;
      transition: opacity 0.4s ease-out;
    }
  }
}
</style>
