import { AppEmitter } from '@leon-hub/app-emitter';
import { assert } from '@leon-hub/guards';
import type { Optional } from '@leon-hub/types';

import type {
  AppModuleEvents,
  InnerModuleApi,
} from '../types';
import type { Tupleize } from '../types/Tupleize';

export class AppModuleApiProvider {
  private static api: Optional<InnerModuleApi>;

  static reset(): void {
    AppModuleApiProvider.api = {
      bus: new AppEmitter<Tupleize<AppModuleEvents>>(),
    };
  }

  static getApi(): InnerModuleApi {
    const { api } = AppModuleApiProvider;
    assert(api, `Call explicitly ${AppModuleApiProvider.name}.reset() before using.`);
    return api;
  }
}
