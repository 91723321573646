<script lang="ts" setup>
import { VCheckbox } from 'web/src/components/Checkbox';

import { useFormCheckboxEvents, useFormCheckbox } from '../../composables';
import type {
  FormWrapperWidgetProperties,
  BaseFormWidgetEmits,
} from '../../types';

const props = defineProps<FormWrapperWidgetProperties>();
const emit = defineEmits<BaseFormWidgetEmits>();

const {
  emitChange,
  emitInput,
  emitFocus,
  emitBlur,
} = useFormCheckboxEvents(emit, props.name);

const { componentProps } = useFormCheckbox(props);
</script>

<template>
  <VCheckbox v-auto-id="'FormCheckbox'"
    v-bind="componentProps"
    :label="props.title"
    @input="emitInput"
    @change="emitChange"
    @focus="emitFocus"
    @blur="emitBlur"
  />
</template>
